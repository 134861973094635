/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../../shared/pipes/is-set/is-set.pipe";
import * as i3 from "./sidebar-list-separator.component";
var styles_SidebarListSeparatorComponent = [];
var RenderType_SidebarListSeparatorComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SidebarListSeparatorComponent, data: {} });
export { RenderType_SidebarListSeparatorComponent as RenderType_SidebarListSeparatorComponent };
function View_SidebarListSeparatorComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "sidebar-list-separator__title-icon"]], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "sidebar-list-separator__title-icon"; var currVal_1 = ("icon-" + _co.icon); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_SidebarListSeparatorComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i2.IsSetPipe, []), (_l()(), i0.ɵeld(1, 0, null, null, 7, "div", [["class", "sidebar-list-separator"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "div", [["class", "sidebar-list-separator__line"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 4, "div", [["class", "sidebar-list-separator__title"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_SidebarListSeparatorComponent_1)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵppd(6, 1), (_l()(), i0.ɵted(7, null, [" ", " "])), (_l()(), i0.ɵeld(8, 0, null, null, 0, "div", [["class", "sidebar-list-separator__line"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i0.ɵnov(_v, 0), _co.icon)); _ck(_v, 5, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.title; _ck(_v, 7, 0, currVal_1); }); }
export function View_SidebarListSeparatorComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-sidebar-list-separator", [], null, null, null, View_SidebarListSeparatorComponent_0, RenderType_SidebarListSeparatorComponent)), i0.ɵdid(1, 114688, null, 0, i3.SidebarListSeparatorComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SidebarListSeparatorComponentNgFactory = i0.ɵccf("app-sidebar-list-separator", i3.SidebarListSeparatorComponent, View_SidebarListSeparatorComponent_Host_0, { title: "title", icon: "icon" }, {}, []);
export { SidebarListSeparatorComponentNgFactory as SidebarListSeparatorComponentNgFactory };
