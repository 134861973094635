<tr class="table__heading-row">
  <th
    *ngFor="let item of displayColumns; let first = first"
    class="table__heading-column table__stub-column"
    [attr.colspan]="first ? 2 : null"
    [class.table__heading-column_left-padding]="first"
  >
    <span [class.loading-animation]="animating">
      <span [class.stub-text]="textStub">{{ item }}</span>
    </span>
  </th>
</tr>
