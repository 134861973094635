var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { getColorHexStr, getColorVariable } from '@modules/colors';
import { isSet } from '@shared';
import { Frame } from './frame';
import { Gradient } from './gradient';
export var FillType;
(function (FillType) {
    FillType["Color"] = "color";
    FillType["Gradient"] = "gradient";
})(FillType || (FillType = {}));
var Fill = /** @class */ (function () {
    function Fill(options) {
        if (options === void 0) { options = {}; }
        this.type = FillType.Color;
        Object.assign(this, options);
    }
    Fill.prototype.deserialize = function (data) {
        if (data['type']) {
            this.type = data['type'];
        }
        if (data['color']) {
            this.color = data['color'];
        }
        if (data['gradient']) {
            this.gradient = new Gradient().deserialize(data['gradient']);
        }
        return this;
    };
    Fill.prototype.serialize = function () {
        return {
            type: this.type,
            color: this.color,
            gradient: this.gradient ? this.gradient.serialize() : undefined
        };
    };
    Fill.prototype.isSet = function () {
        if (this.type == FillType.Color) {
            return isSet(this.color);
        }
        else if (this.type == FillType.Gradient) {
            return this.gradient && this.gradient.isSet();
        }
        else {
            return false;
        }
    };
    Fill.prototype.cssColor = function () {
        if (!isSet(this.color)) {
            return;
        }
        var variable = getColorVariable(this.color);
        if (isSet(variable)) {
            return "var(--" + variable + ")";
        }
        return getColorHexStr(this.color);
    };
    Fill.prototype.css = function (options) {
        if (options === void 0) { options = {}; }
        options = __assign({ frame: new Frame({ width: 1, height: 1 }) }, options);
        if (this.type == FillType.Color && isSet(this.color)) {
            var color = this.cssColor();
            return {
                background: color,
                accentColor: color
            };
        }
        else if (this.type == FillType.Gradient && this.gradient) {
            return this.gradient.css({ frame: options.frame });
        }
        return {};
    };
    return Fill;
}());
export { Fill };
