import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, ActivationEnd, Router } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, merge, Observable, of, timer } from 'rxjs';
import { debounce, debounceTime, filter, map } from 'rxjs/operators';

import { AnalyticsEvent, AnalyticsEventType, UniversalAnalyticsService } from '@modules/analytics';
import { TaskQueueStore } from '@modules/collaboration';
import { CustomizeService } from '@modules/customize';
import { FeatureService } from '@modules/features';
import { MenuCustomSection, MenuService } from '@modules/menu';
import { NavigationService } from '@modules/navigation';
import { CurrentProjectStore } from '@modules/projects';
import { RoutingService } from '@modules/routing';

interface MenuItem {
  icon: string;
  label: string;
  section: MenuCustomSection;
  dropdown?: boolean;
  requireTaskQueues?: boolean;
  onClick?: () => void;
  clickEventType?: AnalyticsEventType;
}

@Component({
  selector: 'app-customize-menu',
  templateUrl: './customize-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeMenuComponent implements OnInit, OnDestroy {
  sectionMenuItemActive$ = new BehaviorSubject<MenuCustomSection>(undefined);
  sectionInteracting$ = new BehaviorSubject<MenuCustomSection>(undefined);
  menuItemHover$ = new BehaviorSubject<MenuCustomSection>(undefined);
  menuItemDropdownHover$ = new BehaviorSubject<boolean>(false);
  currentSection: MenuCustomSection;
  dropdownSection$ = new BehaviorSubject<MenuCustomSection>(undefined);

  hasTaskQueues = false;
  menuCustomSection = MenuCustomSection;
  analyticsEvents = AnalyticsEvent;

  menuItems: MenuItem[] = [
    {
      icon: 'design',
      label: 'Pages',
      section: MenuCustomSection.Pages,
      dropdown: true
    },
    {
      icon: 'data',
      label: 'Data',
      section: MenuCustomSection.Data,
      dropdown: true
    },
    {
      icon: 'dashboard',
      label: 'App Settings',
      section: MenuCustomSection.Layout,
      onClick: () => {
        this.routingService.navigateApp(this.currentProjectStore.instance.settingsLayoutLink('general'));
      },
      clickEventType: AnalyticsEvent.BuilderMenu.LayoutClicked
    },
    {
      icon: 'signin',
      label: 'Sign In & Sign Up',
      section: MenuCustomSection.SignUp,
      onClick: () => {
        this.routingService.navigateApp(this.currentProjectStore.instance.settingsSignUpLink('appearance'));
      },
      clickEventType: AnalyticsEvent.BuilderMenu.SignUpClicked
    },
    {
      icon: 'workflow',
      label: 'Automations',
      section: MenuCustomSection.Automations,
      onClick: () => {
        this.routingService.navigateApp(this.currentProjectStore.instance.automationsLink);
      },
      clickEventType: AnalyticsEvent.BuilderMenu.AutomationsClicked
    },
    {
      icon: 'teams',
      label: 'Collaboration',
      section: MenuCustomSection.Collaboration,
      dropdown: true,
      requireTaskQueues: true,
      onClick: () => this.openCollaboration(),
      clickEventType: AnalyticsEvent.BuilderMenu.CollaborationClicked
    }
  ];

  constructor(
    public menuService: MenuService,
    private customizeService: CustomizeService,
    private navigationService: NavigationService,
    private featureService: FeatureService,
    private taskQueueStore: TaskQueueStore,
    private routingService: RoutingService,
    private router: Router,
    private cd: ChangeDetectorRef,
    private currentProjectStore: CurrentProjectStore,
    private analyticsService: UniversalAnalyticsService
  ) {}

  ngOnInit() {
    this.taskQueueStore
      .get()
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        this.hasTaskQueues = result && result.length > 0;
        this.cd.markForCheck();
      });

    this.initMenuInteractions();
  }

  ngOnDestroy(): void {}

  onSectionMenuItemMouseEnter(item: MenuItem) {
    if (this.sectionInteracting$.value) {
      return;
    }

    this.sectionMenuItemActive$.next(item.section);
    this.menuItemHover$.next(item.section);
  }

  onSectionMenuItemMouseLeave(item: MenuItem) {
    if (this.sectionInteracting$.value) {
      return;
    }

    if (this.menuItemHover$.value == item.section) {
      this.menuItemHover$.next(undefined);
    }
  }

  initMenuInteractions() {
    combineLatest(
      this.sectionMenuItemActive$,
      this.sectionInteracting$,
      this.menuItemHover$.pipe(debounce(section => timer(section ? 0 : 100))),
      this.menuItemDropdownHover$.pipe(debounce(hover => timer(hover ? 0 : 100)))
    )
      .pipe(untilDestroyed(this))
      .subscribe(([sectionMenuItemActive, sectionInteracting, menuItemHover, menuItemDropdownHover]) => {
        const section = sectionInteracting || sectionMenuItemActive;
        const menuItem = section ? this.menuItems.find(item => item.section == section) : undefined;

        if (menuItem && menuItem.dropdown && (menuItemHover || menuItemDropdownHover || sectionInteracting)) {
          this.dropdownSection$.next(section);
        } else {
          this.dropdownSection$.next(undefined);
        }

        this.cd.markForCheck();
      });

    merge(of({}), this.router.events.pipe(filter(item => item instanceof ActivationEnd)))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.dropdownSection$.next(undefined);
        this.cd.markForCheck();
      });

    combineLatest(this.dropdownSection$, this.getRouteSection$())
      .pipe(debounceTime(10), untilDestroyed(this))
      .subscribe(([selectedSection, routeSection]) => {
        if (selectedSection) {
          this.currentSection = selectedSection;
        } else {
          this.currentSection = routeSection;
        }

        this.cd.markForCheck();
      });
  }

  getRouteSection$(): Observable<MenuCustomSection> {
    return this.navigationService.routeSnapshot$.pipe(
      map(snapshot => {
        const getSection = (item: ActivatedRouteSnapshot): MenuCustomSection => {
          if (item.data.hasOwnProperty('section')) {
            return item.data['section'];
          } else if (item.parent) {
            return getSection(item.parent);
          }
        };

        return getSection(snapshot);
      })
    );
  }

  openCollaboration() {
    if (!this.currentProjectStore.instance.features.isTasksEnabled()) {
      this.featureService.showFeatureOverview({
        subtitle: 'Paid Feature',
        title: 'Collaborate on a project with <strong>Collaboration</strong>',
        description: `
          Assign tasks, leave notes, and write comments on a specific record page. Chat with your teammates directly
          through Jet and make sure everyone is up-to-date.
        `
      });
      return;
    }

    this.customizeService.offMenu();
  }

  closeAll() {
    this.dropdownSection$.next(undefined);
    this.cd.markForCheck();
    this.customizeService.offMenu();
  }

  startInteracting(section: MenuCustomSection) {
    this.sectionInteracting$.next(section);
  }

  finishInteracting(section: MenuCustomSection) {
    if (this.sectionInteracting$.value === section) {
      this.sectionInteracting$.next(undefined);
    }
  }

  onItemClick(item: MenuItem) {
    if (this.sectionInteracting$.value) {
      return;
    }

    if (item.onClick) {
      item.onClick();
    }

    if (item.clickEventType) {
      this.analyticsService.sendSimpleEvent(item.clickEventType);
    }
  }
}
