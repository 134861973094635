var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { FormBuilder, FormControl } from '@angular/forms';
import { FileOutputFormat, Input, Input as FieldInput } from '@modules/fields';
import { FieldInputControl } from '@modules/parameters';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
var FileFieldViewParamsForm = /** @class */ (function () {
    function FileFieldViewParamsForm(fb, currentProjectStore, currentEnvironmentStore) {
        this.fb = fb;
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.outputFormatOptions = [
            { value: FileOutputFormat.URL, name: 'Specify URL' },
            { value: FileOutputFormat.Storage, name: 'Save to Storage' }
        ];
        this.form = this.fb.group({
            output_format: new FormControl(FileOutputFormat.Storage),
            storage: new FormControl(undefined),
            path: new FieldInputControl({ path: ['value'] })
        });
    }
    FileFieldViewParamsForm.prototype.init = function (control, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        this.control = control;
        var params = control.value || {};
        var storage = this.currentProjectStore.instance.getStorage(this.currentEnvironmentStore.instance.uniqueName, params['storage_resource'], params['storage_name'], { defaultFirst: true, contextResource: options.context ? options.context.resource : undefined });
        var value = {
            storage: storage ? { resource: storage.resource.uniqueName, name: storage.storage.uniqueName } : undefined
        };
        if (control.value) {
            value['output_format'] = params['output_format'] || FileOutputFormat.Storage;
            // Backward compatibility
            if (typeof params['path'] == 'string') {
                value['path'] = new Input().deserializeFromStatic('value', params['path']).serializeWithoutPath();
            }
            else if (params['path']) {
                value['path'] = new Input().deserialize(params['path']).serializeWithoutPath();
            }
        }
        this.form.patchValue(value, { emitEvent: false });
        this.form.valueChanges.subscribe(function () { return _this.submit(); });
    };
    FileFieldViewParamsForm.prototype.submit = function () {
        var value = this.form.value;
        var params = {
            output_format: value['output_format'],
            path: value['path'] ? new FieldInput().deserialize(value['path']).serialize() : undefined
        };
        if (value['storage']) {
            params['storage_resource'] = value['storage']['resource'];
            params['storage_name'] = value['storage']['name'];
        }
        this.control.patchValue(__assign({}, this.control.value, params));
    };
    return FileFieldViewParamsForm;
}());
export { FileFieldViewParamsForm };
