import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';
import range from 'lodash/range';

import { TintStyle } from '@modules/actions';
import { ActionElementStyles } from '@modules/customize';
import { TypedChanges } from '@shared';

import { TableStubData } from '../table-stub/table-stub-data';

@Component({
  selector: 'app-table-stub-content-body, [app-table-stub-content-body]',
  templateUrl: './table-stub-content-body.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableStubContentBodyComponent implements OnInit, OnChanges {
  @Input() columns = 5;
  @Input() rows = 4;
  @Input() data: TableStubData;
  @Input() leftPaddingColumn = true;
  @Input() newColumn = false;
  @Input() rowActions = false;
  @Input() rowActionsButtons: string[];
  @Input() selection = false;
  @Input() textStub = true;
  @Input() animating = false;
  @Input() scrollable = false;
  @Input() theme = false;
  @Input() actionElementStylesPrimary: ActionElementStyles;
  @Input() actionElementStylesDefault: ActionElementStyles;
  @Input() stylesApplyGlobal = true;

  tintStyles = TintStyle;
  displayRows: string[][] = [];

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: TypedChanges<TableStubContentBodyComponent>): void {
    if (changes.data || changes.rows || changes.columns || changes.rowActions) {
      if (this.data) {
        this.displayRows = this.data.rows;
      } else if (this.scrollable) {
        this.displayRows = range(8).map(() => {
          return range(8).map(() => 'Column');
        });
      } else {
        this.displayRows = range(1, this.rows + 1).map(() => {
          return range(1, this.columns + (this.rowActions ? 1 : 0) + 1).map(() => 'Column');
        });
      }
    }
  }
}
