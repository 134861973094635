/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../menu-components/components/sidebar-empty/sidebar-empty.component.ngfactory";
import * as i2 from "../../../menu-components/components/sidebar-empty/sidebar-empty.component";
import * as i3 from "../../../../ui/components/section/section.component.ngfactory";
import * as i4 from "../../../../ui/components/section/section.component";
import * as i5 from "../../../table/components/table-stub/table-stub.component.ngfactory";
import * as i6 from "../../../table/components/table-stub/table-stub.component";
import * as i7 from "../../../../core/services/document/document.service";
import * as i8 from "@angular/common";
import * as i9 from "../../../customize-utils/data/global.context";
import * as i10 from "@angular/router";
import * as i11 from "../../../users/stores/current-user.store";
import * as i12 from "../../../projects/stores/project-property.store";
import * as i13 from "../../../customize-bar/services/project-property-edit-controller/project-property-edit.controller";
import * as i14 from "../../../api/services/api/api.service";
import * as i15 from "../../../project-api/services/project-api/project-api.service";
import * as i16 from "../../../projects/stores/current-project.store";
import * as i17 from "../../../projects/stores/current-environment.store";
import * as i18 from "./default.component";
import * as i19 from "../../../model-queries/stores/model-description.store";
import * as i20 from "../../../customize/stores/view-settings.store";
import * as i21 from "../../../all-project-settings/stores/project-settings.store";
import * as i22 from "../../../action-queries/services/action/action.service";
import * as i23 from "../../../workflow/services/workflow-backend-run/workflow-backend-run.service";
import * as i24 from "../../../menu/stores/menu-settings/menu-settings.store";
import * as i25 from "../../../../common/dialogs/services/dialog/dialog.service";
import * as i26 from "../../../navigation/services/navigation/navigation.service";
import * as i27 from "../../../customize/services/customize/customize.service";
import * as i28 from "../../../routing/services/routing/routing.service";
import * as i29 from "../../../template/services/template/template.service";
import * as i30 from "../../../template-components/services/choose-template/choose-template.service";
import * as i31 from "../../../template-components/services/template-apply-controller/template-apply.controller";
import * as i32 from "../../../menu/services/menu-pages/menu-pages.service";
import * as i33 from "../../../../common/popups/services/popup/popup.service";
import * as i34 from "../../../analytics/services/universal-analytics/universal-analytics.service";
var styles_DefaultComponent = [];
var RenderType_DefaultComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DefaultComponent, data: {} });
export { RenderType_DefaultComponent as RenderType_DefaultComponent };
function View_DefaultComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-sidebar-empty", [], null, null, null, i1.View_SidebarEmptyComponent_0, i1.RenderType_SidebarEmptyComponent)), i0.ɵdid(1, 114688, null, 0, i2.SidebarEmptyComponent, [], { animating: [0, "animating"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loading; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_DefaultComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "app-section", [], [[2, "section", null], [2, "section_top", null], [2, "section_no-top-margin", null], [2, "section_no-bottom-margin", null], [2, "section_screen", null], [2, "section_disabled", null], [2, "section_full-border-radius", null], [2, "section_small-border-radius", null]], null, null, i3.View_SectionComponent_0, i3.RenderType_SectionComponent)), i0.ɵdid(1, 114688, null, 0, i4.SectionComponent, [], { screen: [0, "screen"] }, null), (_l()(), i0.ɵeld(2, 0, null, 1, 1, "app-table-stub", [], null, null, null, i5.View_TableStubComponent_0, i5.RenderType_TableStubComponent)), i0.ɵdid(3, 4440064, null, 0, i6.TableStubComponent, [i7.DocumentService, i0.ChangeDetectorRef], { animating: [0, "animating"], scrollable: [1, "scrollable"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 3, "div", [["class", "admin__sidebar"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_DefaultComponent_1)), i0.ɵdid(6, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i8.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_8 = true; _ck(_v, 1, 0, currVal_8); var currVal_9 = _co.loading; var currVal_10 = true; _ck(_v, 3, 0, currVal_9, currVal_10); var currVal_11 = i0.ɵunv(_v, 6, 0, i0.ɵnov(_v, 7).transform(_co.customizeService.enabled$)); _ck(_v, 6, 0, currVal_11); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).cls; var currVal_1 = i0.ɵnov(_v, 1).top; var currVal_2 = i0.ɵnov(_v, 1).noTopMargin; var currVal_3 = i0.ɵnov(_v, 1).noBottomMargin; var currVal_4 = i0.ɵnov(_v, 1).screen; var currVal_5 = i0.ɵnov(_v, 1).disabled; var currVal_6 = i0.ɵnov(_v, 1).fullBorderRadius; var currVal_7 = i0.ɵnov(_v, 1).smallBorderRadius; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
export function View_DefaultComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-default", [], null, null, null, View_DefaultComponent_0, RenderType_DefaultComponent)), i0.ɵprd(131584, null, i9.GlobalContext, i9.GlobalContext, [i10.Router, i0.Injector, i11.CurrentUserStore, i12.ProjectPropertyStore, i13.ProjectPropertyEditController, i14.ApiService, i15.ProjectApiService, i16.CurrentProjectStore, i7.DocumentService, i17.CurrentEnvironmentStore]), i0.ɵdid(2, 245760, null, 0, i18.DefaultComponent, [i9.GlobalContext, i19.ModelDescriptionStore, i20.ViewSettingsStore, i16.CurrentProjectStore, i17.CurrentEnvironmentStore, i21.ProjectSettingsStore, i22.ActionService, i23.WorkflowBackendRunService, i24.MenuSettingsStore, i10.ActivatedRoute, i25.DialogService, i26.NavigationService, i27.CustomizeService, i28.RoutingService, i29.TemplateService, i30.ChooseTemplateService, i31.TemplateApplyController, i32.MenuPagesService, i33.PopupService, i34.UniversalAnalyticsService, i0.Injector], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var DefaultComponentNgFactory = i0.ɵccf("app-default", i18.DefaultComponent, View_DefaultComponent_Host_0, {}, {}, []);
export { DefaultComponentNgFactory as DefaultComponentNgFactory };
