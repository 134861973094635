var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { AppConfigService } from '@core';
import { ProjectSettingsStore } from '@modules/all-project-settings';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { CustomizeService } from '@modules/customize';
import { CustomizeBarContext } from '@modules/customize-bar';
import { MenuSection, MenuService } from '@modules/menu';
import { MetaService } from '@modules/meta';
import { CurrentEnvironmentStore, CurrentProjectStore, Field, hasEnvironmentPermission, ProjectPermissions, ProjectPropertyStore } from '@modules/projects';
import { RoutingService } from '@modules/routing';
import { isSet, scrollTo } from '@shared';
import { CustomizeBarAuthEditComponent } from '../customize-bar-auth-edit/customize-bar-auth-edit.component';
import { CustomizeBarSignUpCustomCodeEditComponent } from '../customize-bar-sign-up-custom-code-edit/customize-bar-sign-up-custom-code-edit.component';
import { CustomizeBarSigninEditComponent } from '../customize-bar-signin-edit/customize-bar-signin-edit.component';
import { CustomizeBarSignupAppearanceEditComponent } from '../customize-bar-signup-appearance-edit/customize-bar-signup-appearance-edit.component';
import { CustomizeBarSignupEditComponent } from '../customize-bar-signup-edit/customize-bar-signup-edit.component';
import { ProjectSettingsUpdateForm } from '../project-settings/project-settings-update.form';
import { ProjectUpdateForm } from '../project-settings/project-update.form';
import { ProjectDomainUpdateForm } from './project-domain-update.form';
import { ProjectSignUpForm } from './project-sign-up.form';
var MenuItemType;
(function (MenuItemType) {
    MenuItemType["Appearance"] = "appearance";
    MenuItemType["SignIn"] = "sign_in";
    MenuItemType["SignUp"] = "sign_up";
    MenuItemType["Auth"] = "auth";
    MenuItemType["CustomCode"] = "custom_code";
})(MenuItemType || (MenuItemType = {}));
var DisplayComponent;
(function (DisplayComponent) {
    DisplayComponent["SignUp"] = "SignUp";
    DisplayComponent["SignIn"] = "SignIn";
})(DisplayComponent || (DisplayComponent = {}));
var SignUpBuilderComponent = /** @class */ (function () {
    function SignUpBuilderComponent(currentEnvironmentStore, activatedRoute, projectForm, settingsForm, projectSignUpForm, projectDomainUpdateForm, menuService, customizeBarContext, currentProjectStore, projectSettingsStore, projectPropertyStore, customizeService, routing, appConfigService, metaService, analyticsService, cd) {
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.activatedRoute = activatedRoute;
        this.projectForm = projectForm;
        this.settingsForm = settingsForm;
        this.projectSignUpForm = projectSignUpForm;
        this.projectDomainUpdateForm = projectDomainUpdateForm;
        this.menuService = menuService;
        this.customizeBarContext = customizeBarContext;
        this.currentProjectStore = currentProjectStore;
        this.projectSettingsStore = projectSettingsStore;
        this.projectPropertyStore = projectPropertyStore;
        this.customizeService = customizeService;
        this.routing = routing;
        this.appConfigService = appConfigService;
        this.metaService = metaService;
        this.analyticsService = analyticsService;
        this.cd = cd;
        this.menuItems = [
            {
                type: MenuItemType.Appearance,
                title: 'Appearance',
                subtitle: 'App name, layout and colors',
                icon: 'palette',
                component: CustomizeBarSignupAppearanceEditComponent,
                inputs: {
                    projectSignUpForm: this.projectSignUpForm,
                    projectDomainUpdateForm: this.projectDomainUpdateForm
                },
                displayComponent: DisplayComponent.SignIn
            },
            {
                type: MenuItemType.SignIn,
                title: 'Sign In',
                subtitle: 'Customize Sign In page',
                icon: 'signin',
                component: CustomizeBarSigninEditComponent,
                inputs: {
                    projectSignUpForm: this.projectSignUpForm,
                    projectDomainUpdateForm: this.projectDomainUpdateForm
                },
                displayComponent: DisplayComponent.SignIn
            },
            {
                type: MenuItemType.SignUp,
                title: 'Sign Up',
                subtitle: 'Customize Sign Up page',
                icon: 'signup',
                component: CustomizeBarSignupEditComponent,
                inputs: {
                    projectSignUpForm: this.projectSignUpForm,
                    projectDomainUpdateForm: this.projectDomainUpdateForm,
                    settingsForm: this.settingsForm
                },
                displayComponent: DisplayComponent.SignUp
            },
            {
                type: MenuItemType.Auth,
                title: 'Authentication',
                subtitle: 'Email, SSO, socials',
                icon: 'lock_open',
                component: CustomizeBarAuthEditComponent,
                inputs: {
                    projectDomainUpdateForm: this.projectDomainUpdateForm
                },
                displayComponent: DisplayComponent.SignIn
            },
            {
                type: MenuItemType.CustomCode,
                title: 'Scripts & Styles',
                subtitle: 'Set Sign In/Sign Up JS and CSS',
                icon: 'console',
                component: CustomizeBarSignUpCustomCodeEditComponent,
                inputs: {
                    projectDomainUpdateForm: this.projectDomainUpdateForm
                },
                displayComponent: DisplayComponent.SignIn
            }
        ];
        this.displayComponents = DisplayComponent;
        this.settingsComponents = [];
        this.signUpFields = [];
        this.loading = false;
    }
    SignUpBuilderComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (!hasEnvironmentPermission(this.currentEnvironmentStore.instance, ProjectPermissions.ProjectCustomization)) {
            this.routing.navigateApp(['not-allowed'], { skipLocationChange: true });
            return;
        }
        this.menuService.section = MenuSection.None;
        this.loading = true;
        this.cd.markForCheck();
        this.activatedRoute.params
            .pipe(map(function (params) { return params['tab']; }), untilDestroyed(this))
            .subscribe(function (tab) {
            if (isSet(tab)) {
                _this.setSelectedMenuItemType(tab);
            }
            else {
                var link = _this.currentProjectStore.instance.settingsSignUpLink(_this.menuItems[0].type);
                _this.routing.navigateApp(link, { replaceUrl: true });
            }
        });
        this.customizeService.setHandler(this);
        this.customizeService.setHandlerInfo(this, {
            breadcrumbs: [],
            inAppDisabled: true
        });
        this.customizeBarContext.settingsComponents$.pipe(untilDestroyed(this)).subscribe(function (item) {
            _this.settingsComponents = item;
            _this.cd.detectChanges();
            _this.scrollToTop();
        });
        combineLatest(this.settingsForm.getInstance$(), this.projectPropertyStore.getUser())
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var projectSettings = _a[0], userProperties = _a[1];
            _this.signUpFields = projectSettings
                ? projectSettings.signUpFields
                    .map(function (field) {
                    var property = userProperties.find(function (item) { return item.uid == field.property; });
                    if (!property) {
                        return;
                    }
                    return new Field(__assign({ name: property.uid, verboseName: property.name }, (property.field && {
                        field: property.field.field,
                        params: property.field.params
                    }), (isSet(field.required) && { required: field.required }), (isSet(field.placeholder) && { placeholder: field.placeholder })));
                })
                    .filter(function (item) { return item; })
                : [];
            _this.cd.markForCheck();
        });
        this.projectDomainUpdateForm
            .getInstance$()
            .pipe(untilDestroyed(this))
            .subscribe(function (domain) {
            _this.domain = domain;
            _this.currentSSO =
                domain && domain.ssoSettings.length == 1 && domain.disableEmailSignIn ? domain.ssoSettings[0] : undefined;
            _this.cd.markForCheck();
        });
        combineLatest(this.currentProjectStore.getFirst(), this.projectSettingsStore.getAllSettingsFirst$())
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var project = _a[0], projectSettings = _a[1];
            _this.project = project;
            _this.domain = project ? project.domain : undefined;
            _this.signUpLink = _this.getSignUpLink();
            _this.loading = false;
            _this.cd.markForCheck();
            _this.projectForm.init(project);
            _this.settingsForm.init(projectSettings);
            _this.projectSignUpForm.init(_this.project);
            _this.projectForm.valueChanges.pipe(debounceTime(200), untilDestroyed(_this)).subscribe(function () {
                _this.projectForm.submit().subscribe(function () { return _this.onSave(); });
            });
            _this.settingsForm.valueChanges.pipe(debounceTime(200), untilDestroyed(_this)).subscribe(function () {
                _this.settingsForm.submit().subscribe(function () { return _this.onSave(); });
            });
            _this.projectSignUpForm.valueChanges.pipe(debounceTime(200), untilDestroyed(_this)).subscribe(function () {
                _this.projectSignUpForm.submit().subscribe(function () { return _this.onSave(); });
            });
        }, function () {
            _this.loading = false;
            _this.cd.markForCheck();
        });
        this.currentProjectStore
            .get()
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            _this.project = result;
            _this.domain = result ? result.domain : undefined;
            _this.signUpLink = _this.getSignUpLink();
            _this.cd.markForCheck();
            _this.projectForm.project = _this.project;
            _this.projectSignUpForm.project = _this.project;
            _this.initDomainForm();
        });
    };
    SignUpBuilderComponent.prototype.ngOnDestroy = function () {
        this.menuService.section = MenuSection.Default;
        this.customizeService.unsetHandler(this);
    };
    SignUpBuilderComponent.prototype.initDomainForm = function () {
        var _this = this;
        if (this.domainFormSubscription) {
            this.domainFormSubscription.unsubscribe();
            this.domainFormSubscription = undefined;
        }
        this.projectDomainUpdateForm.init(this.domain);
        this.domainFormSubscription = this.projectDomainUpdateForm.valueChanges
            .pipe(debounceTime(200), untilDestroyed(this))
            .subscribe(function () {
            _this.projectDomainUpdateForm.submit().subscribe(function () { return _this.onSave(); });
        });
    };
    Object.defineProperty(SignUpBuilderComponent.prototype, "selectedMenuItem", {
        get: function () {
            var _this = this;
            return this.menuItems.find(function (item) { return item.type == _this.selectedMenuItemType; });
        },
        enumerable: true,
        configurable: true
    });
    SignUpBuilderComponent.prototype.setSelectedMenuItemType = function (type) {
        if (this.selectedMenuItemType === type) {
            return;
        }
        this.selectedMenuItemType = type;
        this.cd.markForCheck();
        var selectedMenuItem = this.selectedMenuItem;
        if (!selectedMenuItem) {
            return;
        }
        this.displayComponent = selectedMenuItem.displayComponent;
        this.cd.markForCheck();
        if (selectedMenuItem.component) {
            this.customizeBarContext.setSettingsComponent({
                component: selectedMenuItem.component,
                inputs: selectedMenuItem.inputs || {}
            });
        }
    };
    SignUpBuilderComponent.prototype.scrollToTop = function (animated) {
        if (animated === void 0) { animated = true; }
        if (!this.root) {
            return;
        }
        var duration = animated && this.root.nativeElement.scrollTop > 0 ? 0.4 : 0;
        scrollTo(this.root.nativeElement, 0, duration);
    };
    SignUpBuilderComponent.prototype.onMenuItemClicked = function (name) {
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.SignUpSettings.MenuItemClicked, {
            Name: name
        });
    };
    SignUpBuilderComponent.prototype.getSignUpLink = function () {
        var project = this.currentProjectStore.instance;
        var defaultDomainProject = project.domain && project.domain.defaultProject == this.currentProjectStore.instance.uniqueName;
        var webBaseUrl = project.domain ? "https://" + project.domain.actualDomain : this.appConfigService.webBaseUrl;
        return [
            webBaseUrl,
            'register'
        ].concat((defaultDomainProject ? [] : [this.currentProjectStore.instance.uniqueName])).join('/');
    };
    SignUpBuilderComponent.prototype.onSave = function () {
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.Project.BuilderChange, {
            Type: 'signup_settings'
        });
    };
    // TODO: Fix empty handler
    SignUpBuilderComponent.prototype.closePopup = function (uid) { };
    return SignUpBuilderComponent;
}());
export { SignUpBuilderComponent };
