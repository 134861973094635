import { AbstractControlOptions, AsyncValidatorFn, FormControl, ValidatorFn } from '@angular/forms';

import { controlValue } from '@shared';

export class NumberControl extends FormControl {
  constructor(
    formState?: any,
    validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null
  ) {
    super(formState, validatorOrOpts, asyncValidator);
    controlValue(this).subscribe(rawValue => {
      const cleanValue = this.cleanValue(rawValue);

      if (cleanValue !== rawValue) {
        this.setValue(cleanValue);
      }
    });
  }

  cleanValue(value: any) {
    if (value === undefined || value === null) {
      return value;
    }

    if (value === '') {
      return undefined;
    }

    value = parseInt(value, 10);

    if (isNaN(value)) {
      value = 0;
    } else if (typeof value !== 'number') {
      value = undefined;
    } else if (value < 0) {
      value = 0;
    }

    return value;
  }
}
