import { ComponentPortal, DomPortalHost } from '@angular/cdk/portal';
import {
  ApplicationRef,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  DoCheck,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  Type,
  ViewContainerRef
} from '@angular/core';
import keys from 'lodash/keys';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable, Subscription } from 'rxjs';

import { DynamicComponentArguments } from '../../services/dynamic-component/dynamic-component.service';

@Component({
  selector: 'app-dynamic-component',
  template: ''
})
export class DynamicComponent<T = any> implements OnDestroy, DoCheck {
  @Input() set componentData(data: DynamicComponentArguments<T>) {
    this.deinitComponent();
    this.initComponent(data);
  }

  currentComponent: ComponentRef<T>;
  subscriptions: Subscription[] = [];

  constructor(
    private el: ElementRef,
    private injector: Injector,
    private resolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private vcr: ViewContainerRef
  ) {}

  ngOnDestroy(): void {
    this.deinitComponent();
  }

  ngDoCheck(): void {
    if (this.currentComponent) {
      try {
        const cd = this.currentComponent.injector.get<ChangeDetectorRef>(ChangeDetectorRef as Type<ChangeDetectorRef>);
        cd.markForCheck();
      } catch (e) {}
    }
  }

  initComponent(data: DynamicComponentArguments) {
    const injector = Injector.create({
      providers: data.providers || [],
      parent: data.injector || this.injector
    });
    const portalHost = new DomPortalHost(
      this.el.nativeElement,
      data.resolver || this.resolver,
      this.appRef,
      this.injector
    );
    const portal = new ComponentPortal<T>(data.component, this.vcr, injector);

    const componentRef = portalHost.attach(portal);
    const component = componentRef.instance;
    const componentName = component.constructor ? component.constructor.name : component.toString();

    if (data.inputs) {
      for (const key of keys(data.inputs)) {
        component[key] = data.inputs[key];
      }
    }

    const subscriptions: Subscription[] = [];

    if (data.outputs) {
      for (const key of keys(data.outputs)) {
        if (!component.hasOwnProperty(key)) {
          console.error(`No such @Output "${key}" in "${componentName}"`);
          continue;
        } else if (!(component[key] instanceof Observable)) {
          console.error(`"${key}" is not a valid @Output in ${componentName}`);
          continue;
        } else if (!component[key]) {
          console.error(`"${key}" @Output is set to ${component[key]} in ${componentName}`);
          continue;
        }

        const output: EventEmitter<any> = component[key];

        subscriptions.push(
          output.pipe(untilDestroyed(this)).subscribe((...args) => {
            data.outputs[key].forEach(item => item(...args));
          })
        );
      }
    }

    this.currentComponent = componentRef;
    this.subscriptions = subscriptions;
  }

  deinitComponent() {
    if (!this.currentComponent) {
      return;
    }

    this.subscriptions.forEach(item => item.unsubscribe());
    this.currentComponent.destroy();
    this.currentComponent = undefined;
  }
}
