import { Injectable } from '@angular/core';
import { AsyncValidatorFn, FormControl, FormGroup } from '@angular/forms';
import clone from 'lodash/clone';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BackElementItem, MarginControl, ViewContext } from '@modules/customize';
import { ElementConfigurationService } from '@modules/customize-configuration';
import { FieldsEditConfigurable } from '@modules/field-components';
import { Input } from '@modules/fields';
import { FieldInputControl, FieldInputRequiredValidator } from '@modules/parameters';

@Injectable()
export class CustomizeBarBackEditForm {
  element: BackElementItem;
  configurable: FieldsEditConfigurable;
  form = new FormGroup({
    title_input: new FieldInputControl({ path: ['value'] }, FieldInputRequiredValidator),
    previous_page_title_input: new FieldInputControl({ path: ['value'] }),
    previous_page_action: new FormControl(undefined, undefined, this.validateAction()),
    visible_input: new FieldInputControl({ path: ['value'] }),
    margin: new MarginControl()
  });
  context: ViewContext;

  constructor(private elementConfigurationService: ElementConfigurationService) {}

  init(element: BackElementItem, context: ViewContext, firstInit = false) {
    this.element = element;
    this.context = context;

    const value = {
      previous_page_action: element.previousPageAction,
      margin: element.margin
    };

    if (element.titleInput) {
      value['title_input'] = element.titleInput.serializeWithoutPath();
    }

    if (element.previousPageTitleInput) {
      value['previous_page_title_input'] = element.previousPageTitleInput.serializeWithoutPath();
    }

    if (element.visibleInput) {
      value['visible_input'] = element.visibleInput.serializeWithoutPath();
    }

    this.form.patchValue(value);

    if (!firstInit) {
      this.form.markAsDirty();
    }
  }

  isConfigured(instance: BackElementItem): Observable<boolean> {
    return this.elementConfigurationService.isBackConfigured(instance, { restrictDemo: true });
  }

  validateAction(): AsyncValidatorFn {
    return control => {
      return this.elementConfigurationService.isActionConfigured(control.value).pipe(
        map(configured => {
          if (!configured) {
            return { required: true };
          }
        })
      );
    };
  }

  submit(): BackElementItem {
    const value = this.form.value;
    const instance = clone(this.element);

    instance.titleInput = value['title_input'] ? new Input().deserialize(value['title_input']) : undefined;

    if (value['previous_page_action']) {
      instance.previousPageAction = value['previous_page_action'];
    } else {
      instance.previousPageAction = undefined;
    }

    instance.previousPageTitleInput = value['previous_page_title_input']
      ? new Input().deserialize(value['previous_page_title_input'])
      : undefined;
    instance.visibleInput = value['visible_input'] ? new Input().deserialize(value['visible_input']) : undefined;
    instance.margin = value['margin'];

    return instance;
  }
}
