import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { map } from 'rxjs/operators';

import { ViewContext, ViewContextElement } from '@modules/customize';
import {
  ChartWidgetDataSource,
  ListModelDescriptionDataSource,
  ModelBasedDataSource,
  ModelDescriptionDataSource,
  ValueWidgetDataSource
} from '@modules/data-sources';
import { FieldInputsEditComponent } from '@modules/parameters-components';
import { controlValue } from '@shared';

import { DisplayFieldArray } from '../display-fields-edit/display-field.array';
import { DataSourceControl } from '../model-description-data-source-edit/data-source.control';

@Component({
  selector: 'app-data-source-inputs-edit',
  templateUrl: './data-source-inputs-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataSourceInputsEditComponent implements OnInit, OnDestroy {
  @Input() control: DataSourceControl;
  @Input() context: ViewContext;
  @Input() nestedFieldsEnabled = true;
  @Input() relationsEnabled = true;
  @Input() fieldsControl: DisplayFieldArray;
  @Input() editable = false;
  @Input() contextElement: ViewContextElement;
  @Input() contextElementPath: (string | number)[];
  @Input() contextElementPaths: (string | number)[][];
  @Input() userInput = false;
  @Input() classes: string | string[];
  @Input() itemClasses: string | string[];
  @Input() addClasses: string | string[];
  @Input() analyticsSource: string;
  @Output() addInput = new EventEmitter<void>();

  @ViewChild(FieldInputsEditComponent) inputsEditComponent: FieldInputsEditComponent;

  dataSource: ModelBasedDataSource;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {
    controlValue(this.control)
      .pipe(
        map(() => this.control.serialize()),
        untilDestroyed(this)
      )
      .subscribe(dataSource => {
        if (
          dataSource instanceof ModelDescriptionDataSource ||
          dataSource instanceof ListModelDescriptionDataSource ||
          dataSource instanceof ChartWidgetDataSource ||
          dataSource instanceof ValueWidgetDataSource
        ) {
          this.dataSource = dataSource;
          this.cd.markForCheck();
        }
      });
  }

  ngOnDestroy(): void {}

  openAddInput() {
    if (this.inputsEditComponent) {
      this.inputsEditComponent.openAddInput();
    }
  }
}
