<app-customize-bar-header [title]="'Integrations'" [subtitle]="'App settings'" [backEnabled]="false">
</app-customize-bar-header>

<div class="sidebar__content">
  <app-sidebar-section>
    <div class="sidebar__list">
      <div *ngIf="loading" class="sidebar-list">
        <div *ngFor="let item of [1, 2, 3]" cdkDrag class="sidebar-list__item">
          <div class="sidebar-list-item">
            <div class="sidebar-list-item__main">
              <div class="sidebar-list-item__title">
                <span [class.loading-animation]="true"><span class="stub-text">Google</span></span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ng-container *ngIf="!loading && enabledItems && disabledItems">
        <div class="sidebar-list">
          <div *ngFor="let item of enabledItems" class="sidebar-list__item">
            <div class="sidebar-list-item sidebar-list-item_clickable" (click)="item.editValue()">
              <div class="sidebar-list-item__left">
                <span *ngIf="item.icon" class="sidebar-list-item__icon" [ngClass]="'icon-' + item.icon"></span>

                <span
                  *ngIf="item.image && !item.icon"
                  class="sidebar-list-item__image sidebar-list-item__image_size_xl"
                  [style.background-image]="'url(' + item.image + ')'"
                ></span>
              </div>
              <div class="sidebar-list-item__main">
                <div class="sidebar-list-item__title">{{ item.name }}</div>
                <div class="sidebar-list-item__description">
                  <ng-container *ngIf="item.value | appIsSet">
                    {{ item.value }}
                  </ng-container>

                  <ng-container *ngIf="!(item.value | appIsSet)">
                    Not configured
                  </ng-container>
                </div>
              </div>
              <div class="sidebar-list-item__right">
                <span class="sidebar-list-item__action sidebar-list-item__action_button icon-gear_2"></span>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="enabledItems.length && disabledItems.length" class="sidebar-list__separator">
          <app-sidebar-list-separator [title]="'Available integrations'"></app-sidebar-list-separator>
        </div>

        <div class="sidebar-list">
          <div *ngFor="let item of disabledItems" class="sidebar-list__item">
            <div class="sidebar-list-item sidebar-list-item_clickable" (click)="item.editValue()">
              <div class="sidebar-list-item__left">
                <span *ngIf="item.icon" class="sidebar-list-item__icon" [ngClass]="'icon-' + item.icon"></span>

                <span
                  *ngIf="item.image && !item.icon"
                  class="sidebar-list-item__image sidebar-list-item__image_size_xl"
                  [style.background-image]="'url(' + item.image + ')'"
                ></span>
              </div>
              <div class="sidebar-list-item__main">
                <div class="sidebar-list-item__title">{{ item.name }}</div>
                <div class="sidebar-list-item__description">
                  <ng-container *ngIf="item.subtitle | appIsSet">
                    {{ item.subtitle }}
                  </ng-container>

                  <ng-container *ngIf="!(item.subtitle | appIsSet)">
                    Not configured
                  </ng-container>
                </div>
              </div>
              <div class="sidebar-list-item__right">
                <app-toggle [selected]="false" [orange]="true"></app-toggle>
              </div>
            </div>
          </div>

          <div class="sidebar-list__item">
            <a
              [appLink]="currentProjectStore.instance.settingsLayoutLink('custom_code')"
              class="sidebar-list-item sidebar-list-item_background sidebar-list-item_clickable"
            >
              <div class="sidebar-list-item__left">
                <span class="sidebar-list-item__icon icon-console"></span>
              </div>
              <div class="sidebar-list-item__main">
                <div class="sidebar-list-item__title">Add Custom integration</div>
                <div class="sidebar-list-item__description">Using Global JS & CSS</div>
              </div>
              <div class="sidebar-list-item__right">
                <div
                  class="sidebar-list-item__action sidebar-list-item__action_button-orange-alternative icon-plus"
                ></div>
              </div>
            </a>
          </div>
        </div>
      </ng-container>
    </div>
  </app-sidebar-section>
</div>
