<div class="status-page">
  <div class="status-page__main">
    <div class="status-page__content">
      <h1 class="status-page__title">Page not found</h1>
      <div class="status-page__description">
        Looks like there is nothing here. Maybe you have mistyped the URL or this page does not longer exist. Please go
        the the home page to find what you was looking for.
      </div>
      <div class="status-page__actions">
        <a
          *ngIf="!isWhiteLabel"
          [routerLink]="['/']"
          class="status-page__button button button_primary button_shadow button_bigger"
          >Go to Home page</a
        >
      </div>
    </div>
  </div>
  <div class="status-page__right">
    <div class="status-page__image">
      <div class="status-page__image-inner status-page__image-inner_type_not-found"></div>
    </div>
  </div>
</div>
