<div class="token-popup__item">
  <a
    href="javascript:void(0)"
    class="token-popup-list-item token-popup-list-item_orange"
    style="margin-bottom: 4px;"
    (click)="back.emit()"
  >
    <div class="token-popup-list-item__inner token-popup-list-item__line" style="padding: 7px 4px;">
      <span class="token-popup-list-item__left"><span class="icon-arrow_backward_2"></span></span>
      <span class="token-popup-list-item__main">
        <ng-container *ngIf="fieldSelection.modelDescription">
          {{ 'Back to {0} fields' | localize: [fieldSelection.modelDescription.verboseName | appCapitalize] }}
        </ng-container>

        <ng-container *ngIf="!fieldSelection.modelDescription">
          {{ 'Back' | localize }}
        </ng-container>
      </span>
    </div>
  </a>

  <div *ngIf="lookups.length" class="token-popup-search">
    <input
      type="text"
      class="token-popup-search__input"
      [formControl]="searchControl"
      [appAutofocus]="true"
      [placeholder]="'Filter by...'"
    />
    <span
      *ngIf="searchControl.value | appIsSet"
      class="token-popup-search__close icon-close"
      (click)="clearSearch()"
      [appTip]="'Clear value' | localize"
      [appTipOptions]="{ side: 'top' }"
    ></span>
  </div>
</div>

<div *ngIf="lookups.length" class="token-popup__item" [style.margin-top.px]="true ? 10 : null">
  <div *ngIf="!filteredLookups.length" class="token-popup-section">
    <div class="token-popup-section__content">
      <div class="token-popup-list-item">
        <div class="token-popup-list-item__inner">
          <div class="token-popup-list-item__tip">
            {{ 'Nothing found' | localize }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="filteredLookups.length" class="token-popup-section">
    <div class="token-popup-section__title">
      <div class="token-popup-section__title-main">
        {{ '{0} lookups' | localize: [fieldSelection.verboseName] }}
      </div>
    </div>

    <div class="token-popup-section__content">
      <ng-container *ngFor="let item of filteredLookups">
        <div
          *ngFor="let exclude of excludeLookupsSupported ? [false, true] : [false]"
          class="token-popup-list-item token-popup-list-item_hoverable token-popup-list-item_clickable"
          (click)="selectedLookup.emit({ lookup: item, exclude: exclude })"
        >
          <div class="token-popup-list-item__inner">
            <div class="token-popup-list-item__line">
              <span *ngIf="item.type?.icon || item.type.symbol" class="token-popup-list-item__left">
                <span
                  *ngIf="item.type.icon"
                  class="token-popup-list-item__icon"
                  [ngClass]="'icon-' + item.type.icon"
                ></span>
                <span *ngIf="!item.type.icon" class="token-popup-list-item__icon">{{ item.type.symbol }}</span>
              </span>
              <span class="token-popup-list-item__main token-popup-list-item__main_wrap">
                {{ item.type.str(undefined, undefined, exclude) }}
              </span>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
