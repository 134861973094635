import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-sidebar-tab',
  templateUrl: './sidebar-tab.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarTabComponent implements OnInit, OnChanges {
  @Input() label: string;
  @Input() error: string;
  @Input() disabled = false;
  @Input() contentDisabled = false;

  hidden = true;

  private _label$ = new BehaviorSubject<string>(this.label);
  private _error$ = new BehaviorSubject<string>(this.error);
  private _disabled$ = new BehaviorSubject<boolean>(this.disabled);

  public label$ = this._label$.asObservable();
  public error$ = this._error$.asObservable();
  public disabled$ = this._disabled$.asObservable();

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['label']) {
      this._label$.next(this.label);
    }

    if (changes['error']) {
      this._error$.next(this.error);
    }

    if (changes['disabled']) {
      this._disabled$.next(this.disabled);
    }
  }

  setVisible(visible: boolean) {
    this.hidden = !visible;
    this.cd.detectChanges();
  }
}
