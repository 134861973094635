<app-sidebar-section [title]="title">
  <div class="sidebar__list">
    <app-display-fields-edit
      [form]="form"
      [fieldActionsControl]="fieldActionsControl"
      [resource]="resource"
      [modelDescription]="modelDescription"
      [itemName]="itemName"
      [componentName]="componentName"
      [element]="element"
      [context]="context"
      [contextElement]="contextElement"
      [contextElementPath]="contextElementPath"
      [contextElementPaths]="contextElementPaths"
      [searchEnabled]="columnsSearch"
      [searchFocus]="columnsSearch"
      [visibleEditable]="visibleEditable"
      [customViewEnabled]="customViewEnabled"
      [customViewStateSelectedEnabled]="customViewStateSelectedEnabled"
      [actionsLabels]="actionsLabels"
      [firstInit]="firstInit"
      [analyticsSource]="analyticsSource"
      (searchCleared)="setColumnsSearch(false)"
      #fieldsComponent
    >
    </app-display-fields-edit>
  </div>

  <ng-container header-left>
    <div
      class="sidebar-section__title-icon sidebar-section__title-icon_clickable sidebar-section__title-action icon-search"
      [class.sidebar-section__title-icon_active]="columnsSearch"
      (mousedown)="toggleSearch()"
      [appTip]="'Search'"
      [appTipOptions]="{ side: 'top' }"
    ></div>
  </ng-container>

  <ng-container header-right>
    <div class="sidebar-section__header-links">
      <a *ngIf="resetEnabled" class="sidebar__link" href="javascript:void(0)" (click)="resetClick.emit()">
        Reset
      </a>

      <ng-container *ngIf="fieldsComponent.hasMultipleItems()">
        <span *ngIf="resetEnabled" class="sidebar-section__header-separator"></span>

        <a class="sidebar__link" href="javascript:void(0)" (click)="fieldsComponent.toggleAll()">
          <span *ngIf="fieldsComponent.isToggledAll()" class="button__label">Deselect All</span>
          <span *ngIf="!fieldsComponent.isToggledAll()" class="button__label">Select All</span>
        </a>
      </ng-container>
    </div>
  </ng-container>
</app-sidebar-section>
