import { Injectable, Injector } from '@angular/core';
import { AbstractControl, FormControl, ValidatorFn, Validators } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { catchError, debounceTime, delay, delayWhen, map, switchMap, tap } from 'rxjs/operators';

import { NotificationService } from '@common/notifications';
import { ActionService } from '@modules/action-queries';
import { ServerRequestError } from '@modules/api';
import { CustomView, CustomViewService, CustomViewsStore, CustomViewType } from '@modules/custom-views';
import { MapLocationStorage, MapSettings } from '@modules/customize';
import { ElementConfigurationService } from '@modules/customize-configuration';
import { DisplayField, Input, Input as FieldInput } from '@modules/fields';
import { ModelOptionsSource } from '@modules/filters-components';
import { ModelDescriptionStore } from '@modules/model-queries';
import { FieldInputControl } from '@modules/parameters';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { ResourceControllerService } from '@modules/resources';
import { View } from '@modules/views';
import { controlValue, generateAlphanumeric, isSet } from '@shared';

import { ActionOutputFormGroup } from '../../../forms/action-output.form-group';
import { FieldActionsArray } from '../../display-fields-edit/field-actions.array';
import { ListModelDescriptionDataSourceControl } from '../../model-description-data-source-edit/list-model-description-data-source';
import { OptionEnabledArray } from '../../option-enabled-edit/option-enabled.array';
import { ListElementStylesControl } from '../../styles-list-element-edit/list-element-styles.control';
import {
  CustomizeBarListLayoutSettingsForm,
  CustomizeListOptions,
  CustomizeListResult,
  validateAction,
  validateActions
} from '../customize-bar-list-layout-settings/customize-bar-list-layout-settings.form';

export const validateMapLocationField: ValidatorFn = control => {
  const parent = control.parent as CustomizeBarMapSettingsForm;

  if (!parent) {
    return;
  }

  const storage = parent.controls.map_location_storage.value as MapLocationStorage;

  if ([MapLocationStorage.PostgreSQL, MapLocationStorage.Object].includes(storage)) {
    if (!isSet(control.value)) {
      return { required: true };
    }
  }
};

export const validateMapTwoFieldsStorage: ValidatorFn = control => {
  const parent = control.parent as CustomizeBarMapSettingsForm;

  if (!parent) {
    return;
  }

  const storage = parent.controls.map_location_storage.value as MapLocationStorage;

  if ([MapLocationStorage.TwoFields].includes(storage)) {
    if (!isSet(control.value)) {
      return { required: true };
    }
  }
};

@Injectable()
export class CustomizeBarMapSettingsForm extends CustomizeBarListLayoutSettingsForm<MapSettings> {
  controls: {
    header: FormControl;
    title: FieldInputControl;
    map_location_storage: FormControl;
    map_location_field: FormControl;
    map_location_latitude_field: FormControl;
    map_location_longitude_field: FormControl;
    location_input: FieldInputControl;
    zoom_input: FieldInputControl;
    marker_color: FormControl;
    marker_color_input_enabled: FormControl;
    marker_color_input: FieldInputControl;
    marker_size: FormControl;
    marker_size_input_enabled: FormControl;
    marker_size_input: FieldInputControl;
    markers_fit_on_change: FormControl;
    data_source: ListModelDescriptionDataSourceControl;
    card_view_unique_name: FormControl;
    card_view: FormControl;
    card_view_mappings: FormControl;
    search_enabled: FormControl;
    search_live: FormControl;
    card_click_action: FormControl;
    actions: FormControl;
    model_actions: FormControl;
    columns_actions: FieldActionsArray;
    custom_actions: ActionOutputFormGroup;
    sorting_field: FormControl;
    sorting_asc: FormControl;
    per_page: FormControl;
    display_filters: FormControl;
    filter_fields: OptionEnabledArray;
    visible_input: FieldInputControl;
    name: FormControl;
    tooltip: FormControl;

    element_styles: ListElementStylesControl;
  };

  ignoreSubmitControls: AbstractControl[] = [this.controls.card_view];
  submitLoading$ = new BehaviorSubject<boolean>(false);

  locationStorageOptions = [
    {
      name: 'Latitude/Longitude fields',
      value: MapLocationStorage.TwoFields
    },
    {
      name: 'Latitude & Longitude object',
      value: MapLocationStorage.Object
    },
    {
      name: 'PostgreSQL format',
      value: MapLocationStorage.PostgreSQL
    }
  ];

  constructor(
    private modelOptionsSource: ModelOptionsSource,
    private customViewService: CustomViewService,
    private customViewsStore: CustomViewsStore,
    private notificationService: NotificationService,
    private injector: Injector,
    currentProjectStore: CurrentProjectStore,
    currentEnvironmentStore: CurrentEnvironmentStore,
    resourceControllerService: ResourceControllerService,
    modelDescriptionStore: ModelDescriptionStore,
    actionService: ActionService,
    elementConfigurationService: ElementConfigurationService,
    dataSourceControl: ListModelDescriptionDataSourceControl
  ) {
    super(
      currentProjectStore,
      currentEnvironmentStore,
      resourceControllerService,
      modelDescriptionStore,
      actionService,
      elementConfigurationService,
      {
        header: new FormControl(true),
        title: new FieldInputControl({ path: ['value'] }),
        map_location_storage: new FormControl('', Validators.required),
        map_location_field: new FormControl('', validateMapLocationField),
        map_location_latitude_field: new FormControl('', validateMapTwoFieldsStorage),
        map_location_longitude_field: new FormControl('', validateMapTwoFieldsStorage),
        marker_color: new FormControl(''),
        marker_color_input_enabled: new FormControl(false),
        marker_color_input: new FieldInputControl({ path: ['value'] }),
        location_input: new FieldInputControl({ path: ['value'] }),
        zoom_input: new FieldInputControl({ path: ['value'] }),
        marker_size: new FormControl(26),
        marker_size_input_enabled: new FormControl(false),
        marker_size_input: new FieldInputControl({ path: ['value'] }),
        markers_fit_on_change: new FormControl(false),
        data_source: dataSourceControl,
        card_view_unique_name: new FormControl(null),
        card_view: new FormControl(null),
        card_view_mappings: new FormControl([]),
        search_enabled: new FormControl(true),
        search_live: new FormControl(true),
        card_click_action: new FormControl(undefined, undefined, validateAction),
        actions: new FormControl([], undefined, validateActions),
        model_actions: new FormControl([], undefined, validateActions),
        columns_actions: new FieldActionsArray([]),
        custom_actions: new ActionOutputFormGroup(elementConfigurationService),
        sorting_field: new FormControl(undefined),
        sorting_asc: new FormControl(true),
        per_page: new FormControl(undefined),
        display_filters: new FormControl(true),
        filter_fields: new OptionEnabledArray([]),
        visible_input: new FieldInputControl({ path: ['value'] }),
        name: new FormControl(''),
        tooltip: new FormControl(''),

        element_styles: new ListElementStylesControl(injector)
      }
    );
    dataSourceControl.setRequired(true);

    this.controls.map_location_storage.valueChanges.pipe(delay(0)).subscribe(() => {
      this.controls.map_location_field.updateValueAndValidity();
      this.controls.map_location_latitude_field.updateValueAndValidity();
      this.controls.map_location_longitude_field.updateValueAndValidity();
    });
  }

  init(options: CustomizeListOptions<MapSettings>): Observable<void> {
    this.settings = options.settings;
    this.pageUid = options.pageUid;
    this.elementUid = options.elementUid;
    this.options = options;

    const customView$ = isSet(options.settings.cardCustomView)
      ? this.customViewsStore.getDetailFirst(options.settings.cardCustomView)
      : of(undefined);

    return customView$.pipe(
      tap(customView => {
        const value = {
          header: options.settings.header,
          title: options.settings.titleInput ? options.settings.titleInput.serializeWithoutPath() : {},
          card_view_unique_name: options.settings.cardCustomView,
          card_view: customView ? customView.view : null,
          card_view_mappings: options.settings.cardCustomViewMappings,
          map_location_storage: options.settings.locationStorage,
          map_location_field: options.settings.locationField,
          map_location_latitude_field: options.settings.locationLatitudeField,
          map_location_longitude_field: options.settings.locationLongitudeField,
          location_input: options.settings.locationInput ? options.settings.locationInput.serializeWithoutPath() : {},
          zoom_input: options.settings.zoomInput ? options.settings.zoomInput.serializeWithoutPath() : {},
          marker_color: options.settings.markerColor,
          marker_color_input_enabled: !!options.settings.markerColorInput,
          marker_color_input: options.settings.markerColorInput
            ? options.settings.markerColorInput.serializeWithoutPath()
            : {},
          marker_size: options.settings.markerSize || 26,
          marker_size_input_enabled: !!options.settings.markerSizeInput,
          marker_size_input: options.settings.markerSizeInput
            ? options.settings.markerSizeInput.serializeWithoutPath()
            : {},
          markers_fit_on_change: options.settings.markersFitOnChange,
          search_enabled: options.settings.searchEnabled,
          search_live: options.settings.searchLive,
          card_click_action: options.settings.cardClickAction,
          actions: options.settings.actions,
          model_actions: options.settings.modelActions,
          columns_actions: options.settings.columnActions,
          sorting_field: options.settings.sortingField || null,
          sorting_asc: options.settings.sortingAsc,
          per_page: options.settings.perPage,
          display_filters: options.settings.displayFilters,
          filter_fields: options.settings.filterFields.map(item => {
            return {
              name: item.name,
              enabled: true
            };
          }),
          visible_input: options.visibleInput ? options.visibleInput.serializeWithoutPath() : {},
          tooltip: options.settings.tooltip
        };

        if (options.nameEditable) {
          value['name'] = options.name;
        }

        this.patchValue(value, { emitEvent: false });

        this.controls.data_source.deserialize(options.settings.dataSource);
        this.controls.custom_actions.deserialize(
          customView && customView.view ? customView.view.actions : [],
          options.settings.customActions
        );

        if (options.elementStylesEditable && options.elementStyles) {
          this.controls.element_styles.deserialize(options.elementStyles);
        }

        this.trackChanges(options);
        this.initPerPageClean(this.controls.per_page);

        if (!options.firstInit) {
          this.markAsDirty();
        }
      })
    );
  }

  trackChanges(options: CustomizeListOptions<MapSettings>) {
    super.trackChanges(options);

    combineLatest(
      controlValue<DisplayField[]>(this.controls.data_source.controls.columns),
      this.controls.data_source.getModelDescription$(),
      this.controls.data_source.getModelDescription$().pipe(
        switchMap(modelDescription => {
          if (!modelDescription) {
            return of([]);
          }

          return this.modelOptionsSource.getOptions$(modelDescription, {
            relationsEnabled: true
          });
        })
      )
    )
      .pipe(debounceTime(60), untilDestroyed(this))
      .subscribe(([columns, modelDescription, modelOptions]) => {
        const columnNames = modelDescription ? modelOptions.map(item => item.name) : columns.map(item => item.name);
        const modelId = modelDescription ? modelDescription.modelId : null;
        const filterFieldsSourceChanged = this.controls.filter_fields.isSourceSet()
          ? !this.controls.filter_fields.isSource(modelId)
          : false;

        this.controls.filter_fields.syncControls(columnNames, { source: modelId });

        if (this.controls.display_filters.value && this.controls.filter_fields.isAllDisabled()) {
          this.controls.filter_fields.enableDefault();
        } else if (this.controls.display_filters.value && filterFieldsSourceChanged) {
          this.controls.filter_fields.enableDefault();
        }
      });

    this.controls.card_view.valueChanges
      .pipe(
        debounceTime(60),
        switchMap(value => {
          this.submitLoading$.next(true);

          return this.submitCardView(this.controls.card_view_unique_name.value, value);
        }),
        tap(uniqueName => {
          this.controls.card_view_unique_name.patchValue(uniqueName);

          this.submitLoading$.next(false);
        }),
        catchError(error => {
          this.submitLoading$.next(false);

          if (error instanceof ServerRequestError && error.errors.length) {
            this.notificationService.error('Error', error.errors[0]);
          } else {
            this.notificationService.error('Error', error);
          }

          return of(undefined);
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  getCardView(): View {
    return this.controls.card_view.value;
  }

  isConfigured(instance: MapSettings): Observable<boolean> {
    return this.elementConfigurationService.isListMapConfigured(instance, { restrictDemo: true });
  }

  submitCardView(uniqueName: string, view?: View): Observable<string> {
    if (!view) {
      return of(undefined);
    }

    const customView$ = isSet(uniqueName) ? this.customViewsStore.getDetailFirst(uniqueName) : of(undefined);

    return customView$.pipe(
      switchMap(customView => {
        if (customView) {
          const instance = cloneDeep(customView);
          const fields = ['unique_name', 'view_type', 'view', 'params'];

          instance.view = view;
          instance.pageUid = this.pageUid;
          instance.elementUid = this.elementUid;

          return this.customViewService.update(
            this.currentProjectStore.instance.uniqueName,
            this.currentEnvironmentStore.instance.uniqueName,
            instance,
            { draft: true, fields: fields }
          );
        } else {
          const instance = new CustomView();
          const fields = ['unique_name', 'view_type', 'view', 'params'];

          instance.uniqueName =
            isSet(this.pageUid) && isSet(this.elementUid)
              ? [CustomViewType.ListItem, this.pageUid, this.elementUid].join('.')
              : [CustomViewType.ListItem, generateAlphanumeric(8, { letterFirst: true })].join('.');
          instance.viewType = CustomViewType.ListItem;
          instance.view = view;
          instance.pageUid = this.pageUid;
          instance.elementUid = this.elementUid;

          return this.customViewService.create(
            this.currentProjectStore.instance.uniqueName,
            this.currentEnvironmentStore.instance.uniqueName,
            instance,
            { draft: true, fields: fields }
          );
        }
      }),
      delayWhen(() => this.customViewsStore.getFirst(true)),
      map(result => result.uniqueName)
    );
  }

  submit(): CustomizeListResult<MapSettings> {
    const instance: MapSettings = cloneDeep(this.settings);

    instance.locationStorage = this.controls.map_location_storage.value;
    instance.locationField = this.controls.map_location_field.value;
    instance.locationLatitudeField = this.controls.map_location_latitude_field.value;
    instance.locationLongitudeField = this.controls.map_location_longitude_field.value;
    instance.locationInput = this.controls.location_input.value
      ? new Input().deserialize(this.controls.location_input.value)
      : undefined;
    instance.zoomInput = this.controls.zoom_input.value
      ? new Input().deserialize(this.controls.zoom_input.value)
      : undefined;

    if (this.controls.marker_color_input_enabled.value) {
      instance.markerColor = undefined;
      instance.markerColorInput = this.controls.marker_color_input.value
        ? new Input().deserialize(this.controls.marker_color_input.value)
        : undefined;
    } else {
      instance.markerColor = this.controls.marker_color.value;
      instance.markerColorInput = undefined;
    }

    if (this.controls.marker_size_input_enabled.value) {
      instance.markerSize = undefined;
      instance.markerSizeInput = this.controls.marker_size_input.value
        ? new Input().deserialize(this.controls.marker_size_input.value)
        : undefined;
    } else {
      instance.markerSize = this.controls.marker_size.value;
      instance.markerSizeInput = undefined;
    }

    instance.markersFitOnChange = this.controls.markers_fit_on_change.value;

    instance.titleInput = this.controls.title ? new FieldInput().deserialize(this.controls.title.value) : undefined;
    instance.dataSource = this.controls.data_source.serialize();
    instance.cardCustomView = this.controls.card_view_unique_name.value;
    instance.cardCustomViewMappings = this.controls.card_view_mappings.value;
    instance.searchEnabled = this.controls.search_enabled.value;
    instance.searchLive = this.controls.search_live.value;

    if (this.controls.card_click_action.value) {
      instance.cardClickAction = this.controls.card_click_action.value;
    } else {
      instance.cardClickAction = undefined;
    }

    instance.actions = this.controls.actions.value;
    instance.modelActions = this.controls.model_actions.value;
    instance.columnActions = this.controls.columns_actions.value;
    instance.customActions = this.controls.custom_actions.serialize();

    if (isSet(this.controls.sorting_field.value)) {
      instance.sortingField = this.controls.sorting_field.value;
    } else {
      instance.sortingField = undefined;
    }

    instance.sortingAsc = this.controls.sorting_asc.value;
    instance.perPage = this.controls.per_page.value;
    instance.filterFields = this.controls.filter_fields.value
      .filter(item => item.enabled)
      .map(item => {
        return {
          name: item.name
        };
      });
    instance.displayFilters = instance.filterFields.length && this.controls.display_filters.value;
    instance.header =
      (instance.titleInput && instance.titleInput.isSet()) ||
      !!instance.searchEnabled ||
      !!instance.displayFilters ||
      (instance.actions && instance.actions.length > 0);
    instance.tooltip = isSet(this.controls.tooltip.value) ? this.controls.tooltip.value.trim() : undefined;

    return {
      settings: instance,
      visibleInput: this.controls.visible_input.value
        ? new Input().deserialize(this.controls.visible_input.value)
        : undefined,
      name: this.controls.name.value,
      ...(this.options.elementStylesEditable && {
        elementStyles: this.controls.element_styles.serialize()
      })
    };
  }
}
