<div
  class="slider"
  [class.slider_orange]="orange"
  [class.slider_dark]="dark"
  [class.slider_compact]="compact"
  [class.slider_active]="draggingState"
  [class.slider_thumb_inside]="thumbInside"
  [class.slider_single]="!range"
  [class.slider_disabled]="baseControl?.disabled || disabled"
  [class.slider_theme]="theme"
>
  <div class="slider__track-wrapper">
    <div class="slider__track" [style.background]="trackBackgroundSafe" (mousedown)="dragStart($event)" #track>
      <div *ngIf="!range" class="slider__track-fill-start" [style.background]="fillBackgroundSafe"></div>

      <div
        class="slider__track-fill"
        [style.background]="fillBackgroundSafe"
        [style.margin-left.%]="leftPercentage * 100"
        [style.width.%]="widthPercentage * 100"
      >
        <div
          class="slider__thumb slider__value-origin"
          [class.slider__thumb_position_right]="!range"
          [class.slider__thumb_position_left]="range"
          [class.slider__value-origin_active]="isDraggingThumb(true)"
          [style.background-color]="thumbBackgroundColorSafe"
          [style.border-color]="thumbBorderColorSafe"
          #base_thumb
        >
          <div
            *ngIf="valueLabel"
            class="slider__value"
            [class.slider__value_position_top]="true"
            [class.slider__value_position_bottom]="false"
          >
            {{ baseValueClean }}
          </div>
        </div>

        <div
          *ngIf="range"
          class="slider__thumb slider__value-origin"
          [class.slider__thumb_position_right]="true"
          [class.slider__value-origin_active]="isDraggingThumb(false)"
          [style.background-color]="thumbBackgroundColorSafe"
          [style.border-color]="thumbBorderColorSafe"
          #extent_thumb
        >
          <div
            *ngIf="valueLabel"
            class="slider__value"
            [class.slider__value_position_top]="true"
            [class.slider__value_position_bottom]="false"
          >
            {{ extentValueClean }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="labels" class="slider__labels slider__labels_bottom">
    <div class="slider__labels-item">
      <a href="javascript:void(0)" (click)="baseControl?.setValue(this.min)">{{ minLabel }}</a>
    </div>
    <div class="slider__labels-item slider__labels-item_fill">
      <a *ngIf="normal | appIsSet" href="javascript:void(0)" (click)="baseControl?.setValue(this.normal)">{{
        normalLabel
      }}</a>
    </div>
    <div class="slider__labels-item">
      <a href="javascript:void(0)" (click)="(extentControl || baseControl)?.setValue(this.max)">{{ maxLabel }}</a>
    </div>
  </div>
</div>
