import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgGxSelectModule } from 'ng-gxselect';

import { LocalizeModule } from '@common/localize';
import { SelectModule } from '@common/select';
import { ColorsSharedModule } from '@modules/colors-shared';
import { ModelQueriesModule } from '@modules/model-queries';
import { RoutingModule } from '@modules/routing';
import { ThemeComponentsModule } from '@modules/theme-components';
import { SharedModule } from '@shared';
import { UiModule } from '@ui';

import { ExportComponent } from './components/export/export.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RoutingModule,
    ReactiveFormsModule,
    NgGxSelectModule,
    ModelQueriesModule,
    SharedModule,
    LocalizeModule,
    UiModule,
    SelectModule,
    ColorsSharedModule,
    ThemeComponentsModule
  ],
  declarations: [ExportComponent],
  exports: [ExportComponent],
  entryComponents: [ExportComponent]
})
export class ExportModule {}
