import { Injectable, Injector, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import isEqual from 'lodash/isEqual';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';

import { FormUtils } from '@common/form-utils';
import { TintStyle } from '@modules/actions';
import { AllProjectSettings, ProjectSettingsStore } from '@modules/all-project-settings';
import { CHART_COLORS } from '@modules/charts';
import { MarginControl } from '@modules/customize';
import {
  ActionElementStylesControl,
  ElementWrapperStylesControl,
  FieldElementStylesControl
} from '@modules/customize-bar';
import { Option } from '@modules/field-components';
import {
  CurrentEnvironmentStore,
  CurrentProjectStore,
  ProjectPropertyStore,
  ProjectSettingsName,
  ProjectSettingsService
} from '@modules/projects';
import { BorderRadius, defaultFontName } from '@modules/theme';
import { controlValue, isColorHex, isSet, setControlEnabled } from '@shared';

import { ColorSetArray } from './color-set.array';

@Injectable()
export class ProjectAppearanceForm extends FormGroup implements OnDestroy {
  projectSettings: AllProjectSettings;

  controls: {
    accent_color: FormControl;
    accent_color_custom_enabled: FormControl;
    accent_color_custom: FormControl;
    accent_color_dark: FormControl;
    accent_color_dark_custom_enabled: FormControl;
    accent_color_dark_custom: FormControl;
    background_color: FormControl;
    background_color_dark: FormControl;
    background_color_2: FormControl;
    background_color_2_dark: FormControl;
    background_color_3: FormControl;
    background_color_3_dark: FormControl;
    background_color_4: FormControl;
    background_color_4_dark: FormControl;
    background_color_5: FormControl;
    background_color_5_dark: FormControl;
    text_color: FormControl;
    text_color_dark: FormControl;
    text_color_2: FormControl;
    text_color_2_dark: FormControl;
    text_color_3: FormControl;
    text_color_3_dark: FormControl;
    border_color: FormControl;
    border_color_dark: FormControl;
    border_color_2: FormControl;
    border_color_2_dark: FormControl;
    border_color_3: FormControl;
    border_color_3_dark: FormControl;
    border_radius: FormControl;
    auto_colors: ColorSetArray;
    auto_colors_dark: ColorSetArray;
    max_width: FormControl;
    padding: MarginControl;
    font_regular: FormControl;
    font_heading: FormControl;
    action_element_styles_primary: ActionElementStylesControl;
    action_element_styles_default: ActionElementStylesControl;
    action_element_styles_transparent: ActionElementStylesControl;
    field_element_styles: FieldElementStylesControl;
    element_wrapper_styles: ElementWrapperStylesControl;
  };

  borderRadiusOptions: Option<BorderRadius>[] = [
    { value: BorderRadius.None, name: 'None' },
    { value: BorderRadius.S, name: 'Small' },
    { value: BorderRadius.M, name: 'Normal' },
    { value: BorderRadius.L, name: 'Big' },
    { value: BorderRadius.XL, name: 'Large' }
  ];

  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private projectSettingsService: ProjectSettingsService,
    private projectSettingsStore: ProjectSettingsStore,
    private projectPropertyStore: ProjectPropertyStore,
    private formUtils: FormUtils,
    private injector: Injector
  ) {
    super({
      accent_color: new FormControl(''),
      accent_color_custom_enabled: new FormControl(false),
      accent_color_custom: new FormControl('#2B50ED'),
      accent_color_dark: new FormControl(''),
      accent_color_dark_custom_enabled: new FormControl(false),
      accent_color_dark_custom: new FormControl('#2B50ED'),
      background_color: new FormControl(),
      background_color_dark: new FormControl(),
      background_color_2: new FormControl(),
      background_color_2_dark: new FormControl(),
      background_color_3: new FormControl(),
      background_color_3_dark: new FormControl(),
      background_color_4: new FormControl(),
      background_color_4_dark: new FormControl(),
      background_color_5: new FormControl(),
      background_color_5_dark: new FormControl(),
      text_color: new FormControl(),
      text_color_dark: new FormControl(),
      text_color_2: new FormControl(),
      text_color_2_dark: new FormControl(),
      text_color_3: new FormControl(),
      text_color_3_dark: new FormControl(),
      border_color: new FormControl(),
      border_color_dark: new FormControl(),
      border_color_2: new FormControl(),
      border_color_2_dark: new FormControl(),
      border_color_3: new FormControl(),
      border_color_3_dark: new FormControl(),
      border_radius: new FormControl(BorderRadius.M),
      auto_colors: new ColorSetArray(CHART_COLORS),
      auto_colors_dark: new ColorSetArray(CHART_COLORS),
      max_width: new FormControl(),
      padding: new MarginControl(),
      font_regular: new FormControl(defaultFontName),
      font_heading: new FormControl(defaultFontName),
      action_element_styles_primary: new ActionElementStylesControl(injector, {
        textStyleGlobalParams: { tint: TintStyle.Primary }
      }),
      action_element_styles_default: new ActionElementStylesControl(injector, {
        textStyleGlobalParams: { tint: TintStyle.Default }
      }),
      action_element_styles_transparent: new ActionElementStylesControl(injector, {
        textStyleGlobalParams: { tint: TintStyle.Transparent }
      }),
      field_element_styles: new FieldElementStylesControl(injector),
      element_wrapper_styles: new ElementWrapperStylesControl()
    });

    this.currentProjectStore
      .get()
      .pipe(untilDestroyed(this))
      .subscribe(instance => {
        const enabled = instance && instance.features.isStylesEnabled();
        setControlEnabled(this.controls.max_width, enabled);
      });
  }

  static inject(injector: Injector): ProjectAppearanceForm {
    return Injector.create({
      providers: [
        {
          provide: ProjectAppearanceForm,
          useFactory: (
            currentProjectStore: CurrentProjectStore,
            currentEnvironmentStore: CurrentEnvironmentStore,
            projectSettingsService: ProjectSettingsService,
            projectSettingsStore: ProjectSettingsStore,
            projectPropertyStore: ProjectPropertyStore,
            formUtils: FormUtils
          ) => {
            return new ProjectAppearanceForm(
              currentProjectStore,
              currentEnvironmentStore,
              projectSettingsService,
              projectSettingsStore,
              projectPropertyStore,
              formUtils,
              injector
            );
          },
          deps: [
            CurrentProjectStore,
            CurrentEnvironmentStore,
            ProjectSettingsService,
            ProjectSettingsStore,
            ProjectPropertyStore,
            FormUtils
          ]
        }
      ],
      parent: injector
    }).get(ProjectAppearanceForm);
  }

  ngOnDestroy() {}

  init(projectSettings: AllProjectSettings) {
    this.projectSettings = projectSettings;

    if (projectSettings) {
      if (isColorHex(projectSettings.accentColor)) {
        this.controls.accent_color.patchValue('');
        this.controls.accent_color_custom_enabled.patchValue(true);
        this.controls.accent_color_custom.patchValue(projectSettings.accentColor);
      } else {
        this.controls.accent_color.patchValue(isSet(projectSettings.accentColor) ? projectSettings.accentColor : '');
        this.controls.accent_color_custom_enabled.patchValue(false);
        this.controls.accent_color_custom.patchValue('#2B50ED');
      }

      if (isColorHex(projectSettings.accentColorDark)) {
        this.controls.accent_color_dark.patchValue('');
        this.controls.accent_color_dark_custom_enabled.patchValue(true);
        this.controls.accent_color_dark_custom.patchValue(projectSettings.accentColorDark);
      } else {
        this.controls.accent_color_dark.patchValue(
          isSet(projectSettings.accentColorDark) ? projectSettings.accentColorDark : ''
        );
        this.controls.accent_color_dark_custom_enabled.patchValue(false);
        this.controls.accent_color_dark_custom.patchValue('#2B50ED');
      }

      this.controls.background_color.patchValue(projectSettings.backgroundColor);
      this.controls.background_color_dark.patchValue(projectSettings.backgroundColorDark);
      this.controls.background_color_2.patchValue(projectSettings.backgroundColor2);
      this.controls.background_color_2_dark.patchValue(projectSettings.backgroundColor2Dark);
      this.controls.background_color_3.patchValue(projectSettings.backgroundColor3);
      this.controls.background_color_3_dark.patchValue(projectSettings.backgroundColor3Dark);
      this.controls.background_color_4.patchValue(projectSettings.backgroundColor4);
      this.controls.background_color_4_dark.patchValue(projectSettings.backgroundColor4Dark);
      this.controls.background_color_5.patchValue(projectSettings.backgroundColor5);
      this.controls.background_color_5_dark.patchValue(projectSettings.backgroundColor5Dark);
      this.controls.text_color.patchValue(projectSettings.textColor);
      this.controls.text_color_dark.patchValue(projectSettings.textColorDark);
      this.controls.text_color_2.patchValue(projectSettings.textColor2);
      this.controls.text_color_2_dark.patchValue(projectSettings.textColor2Dark);
      this.controls.text_color_3.patchValue(projectSettings.textColor3);
      this.controls.text_color_3_dark.patchValue(projectSettings.textColor3Dark);
      this.controls.border_color.patchValue(projectSettings.borderColor);
      this.controls.border_color_dark.patchValue(projectSettings.borderColorDark);
      this.controls.border_color_2.patchValue(projectSettings.borderColor2);
      this.controls.border_color_2_dark.patchValue(projectSettings.borderColor2Dark);
      this.controls.border_color_3.patchValue(projectSettings.borderColor3);
      this.controls.border_color_3_dark.patchValue(projectSettings.borderColor3Dark);
      this.controls.border_radius.patchValue(projectSettings.borderRadius || BorderRadius.M);
      this.controls.auto_colors.deserialize(projectSettings.autoColors);
      this.controls.auto_colors_dark.deserialize(projectSettings.autoColorsDark);
      this.controls.max_width.patchValue(projectSettings.maxWidth);
      this.controls.padding.deserialize(projectSettings.padding);

      this.controls.font_regular.patchValue(projectSettings.fontRegular || defaultFontName);
      this.controls.font_heading.patchValue(projectSettings.fontHeading || defaultFontName);

      this.controls.action_element_styles_primary.deserialize(projectSettings.actionElementStylesPrimary);
      this.controls.action_element_styles_default.deserialize(projectSettings.actionElementStylesDefault);
      this.controls.action_element_styles_transparent.deserialize(projectSettings.actionElementStylesTransparent);
      this.controls.field_element_styles.deserialize(projectSettings.fieldElementStyles);
      this.controls.element_wrapper_styles.deserialize(projectSettings.elementWrapperStyles);
    }

    this.markAsPristine();
  }

  getAccentColor(): string {
    if (this.controls.accent_color_custom_enabled.value) {
      return isSet(this.controls.accent_color_custom.value) ? this.controls.accent_color_custom.value : undefined;
    } else {
      return isSet(this.controls.accent_color.value) ? this.controls.accent_color.value : undefined;
    }
  }

  getAccentColorDark(): string {
    if (this.controls.accent_color_dark_custom_enabled.value) {
      return isSet(this.controls.accent_color_dark_custom.value)
        ? this.controls.accent_color_dark_custom.value
        : undefined;
    } else {
      return isSet(this.controls.accent_color_dark.value) ? this.controls.accent_color_dark.value : undefined;
    }
  }

  getInstance(): AllProjectSettings {
    const instance = new AllProjectSettings();

    instance.accentColor = this.getAccentColor();
    instance.accentColorDark = this.getAccentColorDark();
    instance.backgroundColor = this.controls.background_color.value;
    instance.backgroundColorDark = this.controls.background_color_dark.value;
    instance.backgroundColor2 = this.controls.background_color_2.value;
    instance.backgroundColor2Dark = this.controls.background_color_2_dark.value;
    instance.backgroundColor3 = this.controls.background_color_3.value;
    instance.backgroundColor3Dark = this.controls.background_color_3_dark.value;
    instance.backgroundColor4 = this.controls.background_color_4.value;
    instance.backgroundColor4Dark = this.controls.background_color_4_dark.value;
    instance.backgroundColor5 = this.controls.background_color_5.value;
    instance.backgroundColor5Dark = this.controls.background_color_5_dark.value;
    instance.textColor = this.controls.text_color.value;
    instance.textColorDark = this.controls.text_color_dark.value;
    instance.textColor2 = this.controls.text_color_2.value;
    instance.textColor2Dark = this.controls.text_color_2_dark.value;
    instance.textColor3 = this.controls.text_color_3.value;
    instance.textColor3Dark = this.controls.text_color_3_dark.value;
    instance.borderColor = this.controls.border_color.value;
    instance.borderColorDark = this.controls.border_color_dark.value;
    instance.borderColor2 = this.controls.border_color_2.value;
    instance.borderColor2Dark = this.controls.border_color_2_dark.value;
    instance.borderColor3 = this.controls.border_color_3.value;
    instance.borderColor3Dark = this.controls.border_color_3_dark.value;
    instance.borderRadius = this.controls.border_radius.value;
    instance.autoColors = this.controls.auto_colors.serialize();
    instance.autoColorsDark = this.controls.auto_colors_dark.serialize();
    instance.maxWidth = isSet(this.controls.max_width.value) ? this.controls.max_width.value : undefined;
    instance.padding = this.controls.padding.serialize();

    if (this.controls.font_regular.value != defaultFontName) {
      instance.fontRegular = this.controls.font_regular.value;
    } else {
      instance.fontRegular = undefined;
    }

    if (this.controls.font_heading.value != defaultFontName) {
      instance.fontHeading = this.controls.font_heading.value;
    } else {
      instance.fontHeading = undefined;
    }

    instance.actionElementStylesPrimary = this.controls.action_element_styles_primary.serialize();
    instance.actionElementStylesDefault = this.controls.action_element_styles_default.serialize();
    instance.actionElementStylesTransparent = this.controls.action_element_styles_transparent.serialize();
    instance.fieldElementStyles = this.controls.field_element_styles.serialize();
    instance.elementWrapperStyles = this.controls.element_wrapper_styles.serialize();

    return instance;
  }

  getInstance$(): Observable<AllProjectSettings> {
    return controlValue(this).pipe(map(() => this.getInstance()));
  }

  getSerializeSettingNames(): ProjectSettingsName[] {
    return [
      ProjectSettingsName.AccentColor,
      ProjectSettingsName.AccentColorDark,
      ProjectSettingsName.BackgroundColor,
      ProjectSettingsName.BackgroundColorDark,
      ProjectSettingsName.BackgroundColor2,
      ProjectSettingsName.BackgroundColor2Dark,
      ProjectSettingsName.BackgroundColor3,
      ProjectSettingsName.BackgroundColor3Dark,
      ProjectSettingsName.BackgroundColor4,
      ProjectSettingsName.BackgroundColor4Dark,
      ProjectSettingsName.BackgroundColor5,
      ProjectSettingsName.BackgroundColor5Dark,
      ProjectSettingsName.TextColor,
      ProjectSettingsName.TextColorDark,
      ProjectSettingsName.TextColor2,
      ProjectSettingsName.TextColor2Dark,
      ProjectSettingsName.TextColor3,
      ProjectSettingsName.TextColor3Dark,
      ProjectSettingsName.BorderColor,
      ProjectSettingsName.BorderColorDark,
      ProjectSettingsName.BorderColor2,
      ProjectSettingsName.BorderColor2Dark,
      ProjectSettingsName.BorderColor3,
      ProjectSettingsName.BorderColor3Dark,
      ProjectSettingsName.BorderRadius,
      ProjectSettingsName.AutoColors,
      ProjectSettingsName.AutoColorsDark,
      ProjectSettingsName.MaxWidth,
      ProjectSettingsName.Padding,
      ProjectSettingsName.FontRegular,
      ProjectSettingsName.FontHeading,
      ProjectSettingsName.ActionElementStylesPrimary,
      ProjectSettingsName.ActionElementStylesDefault,
      ProjectSettingsName.ActionElementStylesTransparent,
      ProjectSettingsName.FieldElementStyles,
      ProjectSettingsName.ElementWrapperStyles
    ];
  }

  serializeInstance(instance: AllProjectSettings) {
    return instance.serialize(this.getSerializeSettingNames());
  }

  submit(): Observable<AllProjectSettings> {
    return this.projectSettingsStore.getAllSettingsFirst$().pipe(
      switchMap(instancePrev => {
        const instanceCurrent = this.getInstance();

        const prevProjectSettings = this.serializeInstance(instancePrev || new AllProjectSettings());
        const newProjectSettings = this.serializeInstance(instanceCurrent);
        const updateProjectSettings = newProjectSettings
          .filter(newItem => {
            const prevItem = prevProjectSettings.find(item => item.name == newItem.name);
            return prevItem ? !isEqual(newItem.value, prevItem.value) : true;
          })
          .map(item => {
            if (!isSet(item.value)) {
              item.deleted = true;
            }

            return item;
          });

        if (!updateProjectSettings.length) {
          return of(undefined);
        }

        return this.projectSettingsService.createBulk(
          this.currentProjectStore.instance.uniqueName,
          this.currentEnvironmentStore.instance.uniqueName,
          updateProjectSettings
        );
      }),
      filter(item => !!item),
      switchMap(() => this.projectSettingsStore.getFirst(true)),
      map(result => new AllProjectSettings().deserialize(result)),
      tap(result => {
        this.projectSettings = result;
      }),
      catchError(error => {
        this.formUtils.showFormErrors(this, error);
        return throwError(error);
      })
    );
  }
}
