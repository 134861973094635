import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import assign from 'lodash/assign';
import cloneDeep from 'lodash/cloneDeep';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fromEvent, Observable } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';

import { copyTextToClipboard } from '@common/code';
import { DialogService } from '@common/dialogs';
import { NotificationService } from '@common/notifications';
import { AppConfigService } from '@core';
import { ActionType } from '@modules/actions';
import { AdminMode, ROUTE_ADMIN_MODE } from '@modules/admin-mode';
import { ApiService } from '@modules/api';
import { createFormFieldFactory, FieldOutput, FieldType } from '@modules/fields';
import { ProjectApiService } from '@modules/project-api';
import {
  auth0BackendName,
  azureADTenantBackendName,
  azureADV2TenantBackendName,
  cognitoBackendName,
  CurrentEnvironmentStore,
  CurrentProjectStore,
  customOAuth2BackendName,
  ProjectPropertyStore,
  Resource,
  SecretToken,
  socialBackends
} from '@modules/projects';
import { HttpMethod, submitForm } from '@modules/queries';
import { Workflow } from '@modules/workflow';
import { capitalize, controlValue, generateUUID, isSet } from '@shared';

// TODO: Refactor imports
import { WorkflowSaveEvent } from '../../../customize-bar/components/workflow/workflow/workflow-save-event';
import { WorkflowEditController } from '../../../customize-bar/services/workflow-edit-controller/workflow-edit.controller';

import { OAuth2ParamsForm } from './oauth2-params.form';

@Component({
  selector: 'app-oauth2-params',
  templateUrl: './oauth2-params.component.html',
  providers: [OAuth2ParamsForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OAuth2ParamsComponent implements OnInit, OnDestroy {
  @Input() control: AbstractControl;
  @Input() userDetailsWorkflowControl: AbstractControl;
  @Input() accessTokenName: string;
  @Input() accessTokenForResource: Resource;
  @Input() createOauthTokenRedirectUrl = true;
  @Input() fill = false;
  @Output() resourceAccessToken = new EventEmitter<SecretToken>();

  createField = createFormFieldFactory();
  userDetailsWorkflow: Workflow;
  customOAuth2Backend = customOAuth2BackendName;

  constructor(
    @Inject(ROUTE_ADMIN_MODE) private mode: AdminMode,
    private appConfigService: AppConfigService,
    private apiService: ApiService,
    private projectApiService: ProjectApiService,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private workflowEditController: WorkflowEditController,
    private projectPropertyStore: ProjectPropertyStore,
    private notificationService: NotificationService,
    private dialogService: DialogService,
    public form: OAuth2ParamsForm,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.form.init(this.control);

    if (this.userDetailsWorkflowControl) {
      controlValue<Workflow>(this.userDetailsWorkflowControl)
        .pipe(untilDestroyed(this))
        .subscribe(userDetailsWorkflow => {
          this.userDetailsWorkflow = userDetailsWorkflow;
          this.cd.markForCheck();
        });
    }
  }

  ngOnDestroy(): void {}

  getResourceAccessToken(resource: Resource) {
    const tokenRequestUID = generateUUID();
    const backend = socialBackends.find(item => item.name == this.form.form.value['backend']);
    const redirect = this.redirectUrl(tokenRequestUID);
    let params = {
      KEY: this.form.form.value['key'],
      SECRET: this.form.form.value['secret'],
      SCOPE: this.form.form.value['scope'].split(','),
      SCOPE_SEPARATOR: this.form.form.value['scope_separator']
    };

    if (backend) {
      if (backend.name == 'google-oauth2') {
        params = assign(params, {
          AUTH_EXTRA_ARGUMENTS: {
            access_type: 'offline',
            include_granted_scopes: 'true',
            prompt: 'consent'
          }
        });
      } else if (backend.name == cognitoBackendName) {
        params = assign(params, {
          POOL_DOMAIN: this.form.cognitoOAuth2Form.value['pool_domain']
        });
      } else if (backend.name == azureADTenantBackendName || backend.name == azureADV2TenantBackendName) {
        params = assign(params, {
          TENANT_ID: this.form.azureADTenantOAuth2Form.value['tenant_id']
        });
      } else if (backend.name == auth0BackendName) {
        params = assign(params, {
          DOMAIN: this.form.auth0OAuth2Form.value['domain']
        });
      } else if (backend.name == customOAuth2BackendName) {
        params = assign(params, {
          AUTHORIZATION_URL: this.form.customOAuth2Form.value['authorization_url'],
          ACCESS_TOKEN_URL: this.form.customOAuth2Form.value['access_token_url']
        });
      }
    }

    this.projectApiService
      .refreshToken()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        const data = [
          {
            name: 'name',
            value: 'oauth_access_token'
          },
          {
            name: 'backend',
            value: backend ? backend.path : ''
          },
          {
            name: 'params',
            value: JSON.stringify(params)
          },
          // {
          //   name: 'access_token',
          //   value: this.apiService.getAccessToken()
          // },
          {
            name: 'access_token',
            value: this.projectApiService.getAccessToken()
          },
          {
            name: 'redirect_uri',
            value: redirect
          }
        ];

        if (this.mode == AdminMode.Builder) {
          data.push({
            name: 'draft',
            value: '1'
          });
        }

        const w = open('', '', 'height=300,width=300');
        if (!w) {
          this.notificationService.error(
            'Failed to open popup',
            'Your browser has blocked opening a new window. Please check your browser settings to allow opening pop-ups'
          );
          return;
        }

        submitForm(HttpMethod.POST, this.authUrl(resource), data, w.document);

        fromEvent<MessageEvent>(window, 'message')
          .pipe(
            filter(message => {
              return (
                message.data &&
                message.data['type'] == 'oauth_response' &&
                message.data['params'] &&
                message.data['uid'] == tokenRequestUID
              );
            }),
            take(1),
            untilDestroyed(this)
          )
          .subscribe(message => {
            const secretToken = new SecretToken().deserialize(message.data['params']);
            this.form.form.patchValue({
              access_token: secretToken.value
            });
            this.resourceAccessToken.emit(secretToken);
          });
      });
  }

  authUrl(resource: Resource) {
    const projectName = this.currentProjectStore.instance.uniqueName;
    const environmentName = this.currentEnvironmentStore.instance.uniqueName;
    return `${this.appConfigService.serverBaseUrl}/api/projects/${projectName}/${environmentName}/resources/${resource.uniqueName}/create_oauth_token/`;
  }

  get completeUrl() {
    return this.apiService.createOAuthTokenCompleteUrl;
  }

  redirectUrl(uid: string) {
    return `${window.location.origin}/oauth_response?uid=${uid}`;
  }

  copy(text: string, contentLabel?: string) {
    copyTextToClipboard(text)
      .pipe(untilDestroyed(this))
      .subscribe(success => {
        if (!success) {
          return;
        }

        const description = isSet(contentLabel) ? `${capitalize(contentLabel)} was copied to clipboard` : undefined;
        this.notificationService.info('Copied', description);
      });
  }

  customizeUserDetailsWorkflow() {
    this.projectPropertyStore
      .getUser()
      .pipe(
        switchMap(projectProperties => {
          const triggerOutputItems: {
            name: string;
            verboseName: string;
            field: FieldType;
            description?: string;
            icon?: string;
          }[] = [
            {
              name: 'client_id',
              verboseName: 'Client ID',
              field: FieldType.Text,
              icon: 'email'
            },
            {
              name: 'client_secret',
              verboseName: 'Client Secret',
              field: FieldType.Text,
              icon: 'key'
            },
            {
              name: 'id_token',
              verboseName: 'ID token',
              field: FieldType.Text,
              icon: 'key'
            },
            {
              name: 'access_token',
              verboseName: 'Access token',
              field: FieldType.Text,
              icon: 'key'
            }
          ];

          const triggerOutputs = triggerOutputItems.map(item => {
            const output = new FieldOutput();

            output.name = item.name;
            output.verboseName = item.verboseName;
            output.field = item.field;
            output.icon = item.icon;
            output.updateFieldDescription();

            return output;
          });

          const resultOutputs = [
            {
              name: 'email',
              verboseName: 'Email',
              field: FieldType.Text,
              icon: 'email'
            },
            {
              name: 'first_name',
              verboseName: 'First name',
              field: FieldType.Text,
              icon: 'user'
            },
            {
              name: 'last_name',
              verboseName: 'Last name',
              field: FieldType.Text,
              icon: 'user'
            },
            {
              name: 'photo',
              verboseName: 'Photo URL',
              field: FieldType.Text,
              icon: 'image'
            },
            ...(projectProperties || []).map(item => {
              return {
                name: item.uid,
                verboseName: item.name,
                ...(item.field && {
                  field: item.field.field,
                  params: item.field.params
                }),
                icon: item.fieldDescription.icon
              };
            })
          ].map(item => {
            const output = new FieldOutput();

            output.name = item.name;
            output.verboseName = item.verboseName;
            output.field = item.field;
            output.icon = item.icon;
            output.updateFieldDescription();

            return output;
          });

          return this.customizeWorkflow({
            triggerLabel: 'Fetch user details',
            triggerIcon: 'user',
            workflow: this.userDetailsWorkflow,
            triggerOutputs: triggerOutputs,
            resultOutputs: resultOutputs
          });
        }),
        filter(result => !result.cancelled),
        untilDestroyed(this)
      )
      .subscribe(result => {
        result.workflow.testRun = result.workflowRun;

        this.userDetailsWorkflowControl.patchValue(result.workflow);
        this.cd.markForCheck();
      });
  }

  deleteUserDetailsWorkflow() {
    this.dialogService
      .warning({
        title: 'Delete workflow',
        description: `Are you sure want to delete <strong>User details workflow</strong>?`,
        style: 'orange'
      })
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        if (result) {
          this.userDetailsWorkflowControl.patchValue(undefined);
          this.cd.markForCheck();
        }
      });
  }

  customizeWorkflow(options: {
    triggerLabel?: string;
    triggerIcon?: string;
    workflow?: Workflow;
    triggerOutputs?: FieldOutput[];
    resultOutputs?: FieldOutput[];
  }): Observable<WorkflowSaveEvent> {
    let workflow: Workflow;

    if (options.workflow) {
      workflow = cloneDeep(options.workflow);
    } else {
      workflow = new Workflow();
      workflow.generateUid();
    }

    return this.workflowEditController.open({
      create: !this.userDetailsWorkflow,
      workflow: workflow,
      workflowRun: workflow.testRun,
      workflowEditable: true,
      parameters: [],
      triggerOutputs: options.triggerOutputs,
      triggerLabel: options.triggerLabel,
      triggerIcon: options.triggerIcon,
      customizeTrigger: false,
      actionTypesEnabled: [
        ActionType.Query
        // ActionType.Link,
        // ActionType.ExternalLink
      ],
      historyEnabled: true,
      resultEnabled: options.resultOutputs && options.resultOutputs.length > 0,
      resultOutputs: options.resultOutputs,
      analyticsSource: ['oauth2_params', 'auth_workflow'].join('_')
    });
  }
}
