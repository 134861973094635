<app-text-style-edit-overlay [control]="control" [origin]="origin" #text_style_edit></app-text-style-edit-overlay>

<span
  class="sidebar__field-action sidebar__field-action_button icon-text_2"
  [class.sidebar__field-action_active]="(styleIsDefault | async) === false"
  [class.sidebar__field-action_disabled]="text_style_edit.isOpened()"
  [appTip]="tip"
  [appTipOptions]="{ side: 'top' }"
  cdkOverlayOrigin
  #origin="cdkOverlayOrigin"
></span>
