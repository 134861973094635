import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';

import { UniversalAnalyticsService } from '@modules/analytics';
import {
  FieldElementStyles,
  FilterElementItem,
  ListElementItem,
  TableSettings,
  VALUE_OUTPUT,
  ViewContext,
  ViewContextElement
} from '@modules/customize';
import { AutoElementComponent } from '@modules/customize-elements';
import { CustomSelectItem, Option } from '@modules/field-components';
import { BaseField, Input as FieldInput, InputValueType, parseFilterName } from '@modules/fields';
import { InputFieldProviderItem } from '@modules/parameters';
import { isSet } from '@shared';

// TODO: Refactor import
import { ElementComponentsService } from '../../../customize-elements/services/element-components/element-components.service';

import { CustomizeBarEditEventType } from '../../data/customize-bar-edit-event-type';
import { CustomizeBarContext } from '../../services/customize-bar-context/customize-bar.context';
import { CustomizeBarService } from '../../services/customize-bar/customize-bar.service';
import {
  CustomizeBarFilterEditForm,
  CustomizeBarFilterElementInputControl
} from '../customize-bar-filter-edit/customize-bar-filter-edit.form';

@Component({
  selector: 'app-customize-bar-filter-edit-items',
  templateUrl: './customize-bar-filter-edit-items.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeBarFilterEditItemsComponent implements OnInit, OnDestroy {
  @Input() element: FilterElementItem;
  @Input() form: CustomizeBarFilterEditForm;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;

  filterElement: ListElementItem;
  addItems: CustomSelectItem<InputFieldProviderItem>[] = [];

  constructor(
    private customizeBarService: CustomizeBarService,
    private customizeBarContext: CustomizeBarContext,
    private elementComponentsService: ElementComponentsService,
    private cd: ChangeDetectorRef,
    private analyticsService: UniversalAnalyticsService
  ) {}

  ngOnInit() {
    combineLatest(this.form.filterElement$(), this.form.inputFieldProvider.getItems$())
      .pipe(untilDestroyed(this))
      .subscribe(([element, providerItems]) => {
        this.filterElement = element;
        this.updateAddItems(providerItems);
        this.cd.markForCheck();
      });
  }

  ngOnDestroy(): void {}

  filterNotUsed(providerItems: InputFieldProviderItem[]): InputFieldProviderItem[] {
    if (!this.filterElement || !this.filterElement.layouts[0] || !this.filterElement.layouts[0].dataSource) {
      return providerItems;
    }

    const elementInputs = this.filterElement.layouts[0].dataSource.queryInputs;

    return providerItems
      .filter(providerItem => {
        if (!providerItem.field) {
          return true;
        }

        return !elementInputs.find(
          input =>
            input.getName() == providerItem.field.name && input.contextValueStartsWith(['elements', this.element.uid])
        );
      })
      .map(providerItem => {
        if (providerItem.children) {
          providerItem.children = this.filterNotUsed(providerItem.children);
        }

        return providerItem;
      });
  }

  updateAddItems(providerItems: InputFieldProviderItem[]) {
    const mapItem = (item: InputFieldProviderItem): CustomSelectItem<InputFieldProviderItem> => {
      if (item.children) {
        return {
          button: {
            label: item.label,
            icon: item.icon
          },
          children: item.children
            .map(i => mapItem(i))
            .sort((lhs, rhs) => {
              const lhsSubtitle = lhs.subtitle ? 1 : 0;
              const rhsSubtitle = rhs.subtitle ? 1 : 0;
              return lhsSubtitle - rhsSubtitle;
            })
        };
      } else if (item.field) {
        return {
          option: {
            value: item,
            name: item.label,
            icon: item.icon
          },
          subtitle: item.field.name.startsWith('exclude__') ? 'Exclude' : undefined
        };
      }
    };

    this.addItems = this.filterNotUsed(providerItems).map(item => mapItem(item));
    this.cd.markForCheck();
  }

  getFilterElementComponent(): AutoElementComponent {
    return this.filterElement ? this.elementComponentsService.get(this.filterElement) : undefined;
  }

  addItem(providerItem: InputFieldProviderItem) {
    const component = this.getFilterElementComponent();

    if (!component) {
      return;
    }

    const element = cloneDeep(this.filterElement) as ListElementItem;
    const settings = element.layouts[0] as TableSettings;
    const input = new FieldInput();
    const { field, lookup, exclude } = parseFilterName(providerItem.field.name);

    input.path = [field];
    input.lookup = lookup;
    input.exclude = exclude;
    input.valueType = InputValueType.Context;
    input.contextValue = ['elements', this.element.uid, providerItem.field.name, VALUE_OUTPUT];

    settings.dataSource.queryInputs = [
      ...settings.dataSource.queryInputs.filter(item => !item.isName(providerItem.field.name)),
      input
    ];

    component.onCustomized(element);
  }

  removeItem(name: string) {
    const component = this.getFilterElementComponent();

    if (!component) {
      return;
    }

    const element = cloneDeep(this.filterElement) as ListElementItem;
    const settings = element.layouts[0] as TableSettings;

    settings.dataSource.queryInputs = settings.dataSource.queryInputs.filter(item => item.getName() != name);

    component.onCustomized(element);
  }

  onAddOptionClick(option: Option<InputFieldProviderItem>) {
    this.addItem(option.value);
  }

  editItem(control: CustomizeBarFilterElementInputControl) {
    const initialValue = cloneDeep(control.controls.settings.value);

    this.customizeBarService
      .customizeColumn({
        context: this.customizeBarContext,
        column: {
          ...control.controls.settings.value,
          field: control.field.field,
          verboseName:
            control.controls.settings.value && isSet(control.controls.settings.value['verboseName'])
              ? control.controls.settings.value['verboseName']
              : control.field.verboseName,
          editable: true,
          // TODO: Refactor workaround for default label additional
          required: true
        },
        configurable: {
          verboseName: true,
          labelAdditional: true,
          field: false,
          editable: true,
          editableOnly: true,
          visible: true,
          disable: true,
          params: true,
          value: true,
          elementStyles: true
        },
        title: control.field.verboseName,
        labelAdditional: control.controls.label_additional.value,
        elementStyles: control.controls.element_styles.serialize(),
        tooltip: control.controls.tooltip.value,
        tooltipEditable: true,
        viewContext: this.context,
        viewContextElement: this.contextElement,
        append: true
      })
      .pipe(untilDestroyed(this))
      .subscribe(e => {
        if (e.type == CustomizeBarEditEventType.Updated) {
          const field = e.args['result'] as BaseField;
          const labelAdditional = e.args['label_additional'] as string;
          const tooltip = e.args['tooltip'] as string;
          const elementStyles = e.args['element_styles'] as FieldElementStyles;

          if (field.verboseName == control.field.verboseName) {
            field.verboseName = undefined;
          }

          control.controls.settings.patchValue(field);
          control.controls.label_additional.patchValue(labelAdditional);
          control.controls.tooltip.patchValue(tooltip);
          control.controls.element_styles.deserialize(elementStyles);
          control.markAsDirty();
        } else if (e.type == CustomizeBarEditEventType.Canceled) {
          control.controls.settings.patchValue(initialValue);
          control.markAsDirty();
        }
      });
  }

  dragDropOption(event: CdkDragDrop<CustomizeBarFilterElementInputControl[]>) {
    if (event.previousIndex !== event.currentIndex) {
      moveItemInArray(this.form.controls.element_inputs.controls, event.previousIndex, event.currentIndex);
      this.form.controls.element_inputs.updateValueAndValidity();
    }
  }
}
