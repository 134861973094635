import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import last from 'lodash/last';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, fromEvent, merge, Observable, of, ReplaySubject, Subject, timer } from 'rxjs';
import { debounce, debounceTime, filter, first, map, switchMap, take, takeUntil } from 'rxjs/operators';

import { ViewContext, ViewContextElement } from '@modules/customize';
import {
  applyParamInput$,
  Input as FieldInput,
  InputError,
  InputValueType,
  mathjs,
  mathJsIsSingleToken
} from '@modules/fields';
import {
  contextToFormulaValue,
  singleTokenFormulaToContextValue,
  transformFormulaElementAccessors
} from '@modules/parameters';
import { ascComparator, cleanWrapSpaces, controlValue, EMPTY, isSet, KeyboardEventKeyCode, toggleClass } from '@shared';

import { FormulaSection } from '../../data/formula-section';
import { FormulaInsert } from '../../data/formula-token';
import { viewContextTokenProviderFunctions } from '../../services/view-context-token-provider/view-context-token-provider-functions.stub';
import { getRootFormulaToken, renderFormulaElementDescriptors, renderFormulaNode } from '../../utils/formula';
import { FieldFilterInputTokenData, InputTokenType } from '../input-edit/input-token';

function getElementDepth(el: HTMLElement, relativeTo?: HTMLElement) {
  let depth = 0;

  while (el.parentElement && (!relativeTo || el.parentElement !== relativeTo)) {
    el = el.parentElement;
    depth++;
  }

  return depth;
}

interface TextSelection {
  type: 'Caret' | 'Range';
  anchorNode: Node;
  anchorOffset: number;
  baseNode: Node;
  baseOffset: number;
  extentNode: Node;
  extentOffset: number;
  focusNode: Node;
  focusOffset: number;
  rangeCount: number;
  range?: Range;
}

function serializeSelection(selection: Selection): TextSelection {
  if (selection.type == 'None') {
    return;
  }

  return {
    type: selection.type as 'Caret' | 'Range',
    anchorNode: selection.anchorNode,
    anchorOffset: selection.anchorOffset,
    baseNode: selection.baseNode,
    baseOffset: selection.baseOffset,
    extentNode: selection.extentNode,
    extentOffset: selection.extentOffset,
    focusNode: selection.focusNode,
    focusOffset: selection.focusOffset,
    rangeCount: selection.rangeCount,
    range: selection.rangeCount ? selection.getRangeAt(0) : undefined
  };
}

class ErrorResult {
  constructor(public readonly error: Error) {}
}

@Component({
  selector: 'app-input-edit-formula-value',
  templateUrl: './input-edit-formula-value.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputEditFormulaValueComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() control: FormControl;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  // If need to specify inner path inside contextElement (relative to contextElement)
  @Input() contextElementPath: (string | number)[];
  // If need multiple path inside contextElement (relative to contextElement + contextElementPath)
  @Input() contextElementPaths: (string | number)[][];
  @Input() contextValueEnabled = true;
  @Input() focusedInitial = false;
  @Input() placeholder = 'Formula';
  @Input() displayJsType = false;
  @Input() extraSections: FormulaSection[] = [];
  @Input() fill = false;
  @Input() fillVertical = false;
  @Input() header = false;
  @Input() inline = false;
  @Input() small = false;
  @Input() darker = false;
  @Input() resultShowOnFocus = true;
  @Input() popoverOrigin: CdkOverlayOrigin;
  @Input() popoverForcedOpened: boolean;
  @Output() popoverOpened = new EventEmitter<void>();
  @Output() switchToDefault = new EventEmitter<string>();
  @Output() switchToContext = new EventEmitter<string>();
  @Output() switchToTextInputs = new EventEmitter<void>();
  @Output() switchToJs = new EventEmitter<void>();
  @Output() switchToEmptyString = new EventEmitter<void>();
  @Output() switchToNull = new EventEmitter<void>();
  @Output() switchToPrompt = new EventEmitter<void>();
  @Output() switchToFieldFilter = new EventEmitter<FieldFilterInputTokenData>();

  popoverOpened$ = new BehaviorSubject<boolean>(false);
  focus$ = new BehaviorSubject<boolean>(false);
  hoverElements$ = new BehaviorSubject<{ node; element: HTMLElement }[]>([]);
  hoverLastElement$ = this.hoverElements$.pipe(map(value => last(value)));
  hoverFunction$ = this.hoverLastElement$.pipe(
    map(value => {
      if (value && value.node.isFunctionNode) {
        return value.node.name.toUpperCase();
      }
    })
  );
  activeElements$ = new BehaviorSubject<{ node; element: HTMLElement }[]>([]);
  activeLastElement$ = this.activeElements$.pipe(map(value => last(value)));
  activeFunction$ = this.activeLastElement$.pipe(
    map(value => {
      if (value && value.node.isFunctionNode) {
        return value.node.name.toUpperCase();
      }
    })
  );
  saveValue$ = new Subject<void>();
  inputSelection$ = new BehaviorSubject<TextSelection>(undefined);
  inputSelectionLast$ = new BehaviorSubject<TextSelection>(undefined);
  search$ = new BehaviorSubject<string>('');
  formulaSaveValue: string;
  savedCaretPosition: number;
  destroyed$: ReplaySubject<void>;
  empty = false;
  inputValueLoading = true;
  inputSet = false;
  inputValue: any;
  inputSyntaxError: string;
  inputExecuteError: string;
  inputExecuteErrorDescription: string;
  inputSaveError: string;
  history: { value: string; position: number }[] = [];
  historyIndex = -1;
  historyChange = false;

  @ViewChild('input_element') inputElement: ElementRef<HTMLElement>;

  constructor(private zone: NgZone, private cd: ChangeDetectorRef) {}

  ngOnInit() {}

  ngOnDestroy() {
    if (this.destroyed$) {
      this.destroyed$.next();
      this.destroyed$ = undefined;
    }
  }

  ngAfterViewInit(): void {
    this.init();
  }

  init() {
    merge<{ addText?: string; forceSave?: boolean }>(
      merge(
        fromEvent<TextEvent>(this.inputElement.nativeElement, 'input'),
        fromEvent<TextEvent>(this.inputElement.nativeElement, 'textinput')
      ).pipe(
        map(e => ({ addText: e.data })),
        debounceTime(60)
      ),
      this.saveValue$.pipe(map(() => ({ forceSave: true })))
    )
      .pipe(untilDestroyed(this))
      .subscribe(e => {
        const selection = serializeSelection(window.getSelection());
        const input = this.inputElement.nativeElement;
        const inputSelection =
          selection && selection.type == 'Caret' && input.contains(selection.baseNode) ? selection : undefined;
        let savedCaretPosition: number;

        if (
          e.addText == '(' &&
          inputSelection &&
          inputSelection.baseNode &&
          inputSelection.baseOffset == inputSelection.baseNode.textContent.length &&
          inputSelection.baseNode.parentElement.classList.contains('formula-symbol') &&
          inputSelection.baseNode.parentElement.innerText.match(/^\w+\($/)
        ) {
          const root = inputSelection.baseNode.parentElement;
          const match = root.innerText.match(/^(\w+)\($/);

          if (match) {
            savedCaretPosition = this.getCaretPosition();

            root.innerText = root.innerText.toUpperCase() + ')';

            const func = viewContextTokenProviderFunctions.find(item => item.function.name == match[1].toUpperCase());
            if (func && (!func.function.arguments || !func.function.arguments.length)) {
              savedCaretPosition += 1;
            }
          }

          this.search$.next('');
        } else if (
          isSet(e.addText) &&
          inputSelection &&
          inputSelection.baseNode &&
          inputSelection.baseOffset == inputSelection.baseNode.textContent.length &&
          inputSelection.baseNode.parentElement.classList.contains('formula-symbol') &&
          inputSelection.baseNode.parentElement.innerText.match(/^\w+$/)
        ) {
          this.search$.next(inputSelection.baseNode.parentElement.innerText);
        } else {
          this.search$.next('');
        }

        this.inputSaveError = undefined;
        this.savedCaretPosition = undefined;
        this.cd.markForCheck();

        const formulaValue = input.innerText;

        try {
          const rootNode = isSet(formulaValue.trim()) ? this.parseFormula(formulaValue) : undefined;
          const cleanFormulaValue = rootNode ? rootNode.toString() : undefined;

          if (cleanFormulaValue != this.control.value || e.forceSave) {
            this.savedCaretPosition = savedCaretPosition;
            this.formulaSaveValue = formulaValue;
            this.control.patchValue(cleanFormulaValue);
          }
        } catch (e) {
          console.error('Save failed', e.message);

          const match = /^(\w+:\s)?(.+)(?:\s\(char\s(\d+)\))$/.exec(String(e));

          if (match) {
            this.inputSaveError = `Position ${match[3]}: ${match[2]}`;
          } else {
            this.inputSaveError = String(e);
          }

          this.cd.markForCheck();
        }
      });

    merge(of(undefined), fromEvent(document, 'selectionchange'))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        const selection = serializeSelection(window.getSelection());
        const input = this.inputElement.nativeElement;
        const inputSelection = selection && input.contains(selection.baseNode) ? selection : undefined;

        this.inputSelection$.next(inputSelection);

        if (inputSelection) {
          this.inputSelectionLast$.next(inputSelection);
        }
      });

    controlValue(this.control)
      .pipe(
        map((value, index) => [value, index]),
        untilDestroyed(this)
      )
      .subscribe(([formulaValue, index]) => {
        this.empty = formulaValue === undefined || (typeof formulaValue === 'string' && formulaValue.trim() === '');
        this.inputSyntaxError = undefined;
        this.inputSaveError = undefined;
        this.cd.markForCheck();

        try {
          let rootNode;

          try {
            rootNode = !this.empty ? this.parseFormula(formulaValue) : undefined;
          } catch (e) {
            const match = /^(\w+:\s)?(.+)(?:\s\(char\s(\d+)\))$/.exec(String(e));

            if (match) {
              this.inputSyntaxError = `Position ${match[3]}: ${match[2]}`;
            } else {
              this.inputSyntaxError = String(e);
            }

            this.cd.markForCheck();
            return;
          }

          const destroyed$ = new ReplaySubject<void>(1);
          const element = rootNode ? this.renderNode(rootNode, destroyed$) : undefined;

          let position = isSet(this.savedCaretPosition) ? this.savedCaretPosition : this.getCaretPosition();
          this.savedCaretPosition = undefined;

          if (this.destroyed$) {
            this.destroyed$.next();
            this.destroyed$ = undefined;
          }

          this.hoverElements$.next([]);
          this.activeElements$.next([]);

          renderFormulaElementDescriptors(this.inputElement.nativeElement, element ? [element] : []);

          const formulaActualValue = this.inputElement.nativeElement.innerText;
          if (isSet(this.formulaSaveValue) && formulaActualValue.length < this.formulaSaveValue.length) {
            const afterCaret = this.formulaSaveValue.slice(position);
            if (formulaActualValue.endsWith(afterCaret)) {
              position = formulaActualValue.length - afterCaret.length;
            }
          }

          this.destroyed$ = destroyed$;
          this.formulaSaveValue = undefined;

          if (this.inputElement.nativeElement === document.activeElement && isSet(position)) {
            this.setCaretPosition(position);
          }
        } catch (e) {
          console.error('Parse failed', e.message);
        }

        if (index == 0 && this.focusedInitial) {
          this.focusValueOnStable();
          this.focusedInitial = false;
        }
      });

    controlValue(this.control)
      .pipe(
        map((value, index) => ({ value, index })),
        debounce(({ index }) => timer(index == 0 ? 0 : 600)),
        switchMap<
          { value: any; index: number },
          { isSet: boolean; result?: any; error?: string; errorDescription?: string }
        >(({ value }) => {
          return of({ isSet: isSet(value), result: undefined });
          // const input = new FieldInput();
          //
          // input.path = ['value'];
          // input.valueType = InputValueType.Formula;
          // input.formulaValue = value;
          //
          // return applyParamInput$(input, {
          //   context: this.context,
          //   contextElement: this.contextElement,
          //   raiseErrors: true,
          //   errorValueFn: error => new ErrorResult(error),
          //   debounce: 200
          // }).pipe(
          //   map(result => {
          //     if (result instanceof ErrorResult) {
          //       const errorDescription = result.error instanceof InputError ? result.error.error.message : undefined;
          //       return { isSet: isSet(value), error: result.error.message, errorDescription: errorDescription };
          //     } else {
          //       return { isSet: isSet(value), result: result !== EMPTY ? result : undefined };
          //     }
          //   })
          // );
        }),

        untilDestroyed(this)
      )
      .subscribe(result => {
        this.inputValueLoading = false;
        this.inputSet = result.isSet;
        this.inputValue = result.result;
        this.inputExecuteError = result.error;
        this.inputExecuteErrorDescription = result.errorDescription;
        this.cd.markForCheck();
      });

    controlValue(this.control, { debounce: 200 })
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        if (this.historyChange) {
          this.historyChange = false;
          return;
        }

        this.saveHistory();
      });

    this.focus$
      .pipe(
        switchMap(focus => {
          if (focus) {
            return fromEvent<KeyboardEvent>(document, 'keydown');
          } else {
            return of(undefined);
          }
        }),
        filter(e => e),
        untilDestroyed(this)
      )
      .subscribe(e => {
        if ((e.metaKey || e.ctrlKey) && !e.shiftKey && e.keyCode == KeyboardEventKeyCode.Z) {
          e.preventDefault();
          e.stopPropagation();

          this.undoHistory();
        } else if ((e.metaKey || e.ctrlKey) && e.shiftKey && e.keyCode == KeyboardEventKeyCode.Z) {
          e.preventDefault();
          e.stopPropagation();

          this.redoHistory();
        }
      });

    fromEvent<ClipboardEvent>(this.inputElement.nativeElement, 'paste')
      .pipe(untilDestroyed(this))
      .subscribe(e => {
        e.preventDefault();
        const text = e.clipboardData.getData('text/plain');
        document.execCommand('insertText', false, text);
      });
  }

  getCaretPosition(): number {
    const selection = window.getSelection();
    if (!selection.rangeCount) {
      return;
    }

    const range = selection.getRangeAt(0).cloneRange();
    range.setStart(this.inputElement.nativeElement, 0);
    return range.toString().length;
  }

  setCaretPosition(index: number) {
    const selection = window.getSelection();
    const position = this.getTextNodeAtPosition(this.inputElement.nativeElement, index);
    const range = new Range();

    range.setStart(position.node, position.position);
    selection.removeAllRanges();
    selection.addRange(range);
  }

  moveCaretToEnd() {
    const selection = window.getSelection();
    const range = new Range();

    range.setStart(this.inputElement.nativeElement, this.inputElement.nativeElement.childNodes.length);
    selection.removeAllRanges();
    selection.addRange(range);
  }

  focus() {
    this.inputElement.nativeElement.focus();
    this.moveCaretToEnd();
  }

  focusValueOnStable() {
    this.zone.onStable
      .pipe(take(1))
      .pipe(untilDestroyed(this))
      .subscribe(() => this.focus());
  }

  getTextNodeAtPosition(root: Node, index: number): { node: Node; position: number } {
    const NODE_TYPE = NodeFilter.SHOW_TEXT;
    const treeWalker = document.createTreeWalker(root, NODE_TYPE, {
      acceptNode: elem => {
        if (index > elem.textContent.length) {
          index -= elem.textContent.length;
          return NodeFilter.FILTER_REJECT;
        }
        return NodeFilter.FILTER_ACCEPT;
      }
    });
    const c = treeWalker.nextNode();
    return {
      node: c ? c : root,
      position: index
    };
  }

  parseFormula(formulaValue: string) {
    return mathjs.parse(cleanWrapSpaces(formulaValue)).transform(node => {
      if (node.isFunctionNode) {
        const name = node.name.toUpperCase();
        const func = viewContextTokenProviderFunctions.find(item => item.function.name == name);
        if (!func) {
          throw new Error(`Unknown function "${name}"`);
        }
      }

      // if (node['isIndexNode']) {
      //   const dimension = node.dimensions[0];
      //
      //   if (!node.isObjectProperty() && dimension && typeof dimension.value == 'number') {
      //     dimension.value = dimension.value + 1;
      //   }
      // }
      return node;
    });
  }

  addHoverElement(node, element: HTMLElement) {
    const value = this.hoverElements$.value;
    if (!value.find(item => item.element === element)) {
      this.hoverElements$.next([...value, { node: node, element: element }]);
    }
  }

  removeHoverElementByElement(element: HTMLElement) {
    const value = this.hoverElements$.value;
    if (value.find(item => item.element === element)) {
      this.hoverElements$.next(value.filter(item => item.element !== element));
    }
  }

  addActiveElement(node, element: HTMLElement) {
    const value = this.activeElements$.value;
    if (!value.find(item => item.element === element)) {
      this.activeElements$.next(
        [...value, { node: node, element: element }].sort((lhs, rhs) => {
          const lhsDepth = getElementDepth(lhs.element, this.inputElement.nativeElement);
          const rhsDepth = getElementDepth(rhs.element, this.inputElement.nativeElement);
          return ascComparator(lhsDepth, rhsDepth);
        })
      );
    }
  }

  removeActiveElementByElement(element: HTMLElement) {
    const value = this.activeElements$.value;
    if (value.find(item => item.element === element)) {
      this.activeElements$.next(value.filter(item => item.element !== element));
    }
  }

  renderNode(rootNode, destroyed$: Observable<void>) {
    return renderFormulaNode(rootNode, {
      setupDescriptor: (descriptor, node) => {
        if (descriptor.classes.has('formula-func')) {
          descriptor.onCreate(root => {
            fromEvent(root, 'mouseenter')
              .pipe(takeUntil(destroyed$))
              .subscribe(() => this.addHoverElement(node, root));

            fromEvent(root, 'mouseleave')
              .pipe(takeUntil(destroyed$))
              .subscribe(() => this.removeHoverElementByElement(root));

            combineLatest(this.inputSelection$, this.inputSelectionLast$, this.popoverOpened$)
              .pipe(takeUntil(destroyed$))
              .subscribe(([selection, selectionLast, popoverOpened]) => {
                if (!selection && popoverOpened) {
                  selection = selectionLast;
                }

                if (selection && root.contains(selection.baseNode) && !selection.baseNode.textContent.startsWith(')')) {
                  this.addActiveElement(node, root);
                } else {
                  this.removeActiveElementByElement(root);
                }
              });

            combineLatest(this.hoverLastElement$, this.activeLastElement$)
              .pipe(takeUntil(destroyed$))
              .subscribe(([hover, active]) => {
                toggleClass(root, 'formula-func_hover', hover && hover.element === root);
                toggleClass(root, 'formula-func_active', !hover && active && active.element === root);
              });
          });
        }
      }
    });
  }

  get inputEffectiveError(): string {
    if (isSet(this.inputSaveError)) {
      return this.inputSaveError;
    } else if (isSet(this.inputExecuteError)) {
      return this.inputExecuteError;
    } else {
      return this.inputSyntaxError;
    }
  }

  saveHistory() {
    this.history = [
      ...this.history.slice(0, this.historyIndex + 1).slice(-50),
      {
        value: this.control.value,
        position: this.getCaretPosition()
      }
    ];
    this.historyIndex = this.history.length - 1;
  }

  moveHistory(delta: number) {
    const newIndex = this.historyIndex + delta;
    const newItem = this.history[newIndex];

    if (!newItem) {
      return;
    }

    this.historyIndex = newIndex;
    this.historyChange = true;

    this.control.patchValue(newItem.value);

    try {
      this.setCaretPosition(newItem.position);
    } catch (e) {}
  }

  undoHistory() {
    this.moveHistory(-1);
  }

  redoHistory() {
    this.moveHistory(1);
  }

  insertFormulaToken(item: FormulaInsert) {
    let insert: string;

    if (item.formula) {
      insert = item.formula;
    } else if (isSet(item.caretIndex)) {
      insert = `${contextToFormulaValue(item.insert)}()`;
    } else {
      insert = contextToFormulaValue(item.insert);
    }

    const caretIndex = isSet(item.caretIndex) ? item.caretIndex : insert.length;
    const selection = this.inputSelectionLast$.value;

    if (
      this.contextValueEnabled &&
      !isSet(this.inputElement.nativeElement.textContent) &&
      !item.caretIndex &&
      !isSet(item.formula)
    ) {
      const toExternalValue = transformFormulaElementAccessors(insert, this.context, false);
      this.switchToContext.emit(toExternalValue);
    } else if (selection) {
      let selectionNode: Node;
      let selectionIndex: number;

      if (selection.type == 'Range' && selection.baseNode === selection.extentNode) {
        const [start, end] = [selection.baseOffset, selection.extentOffset].sort();
        selection.baseNode.textContent = [
          selection.baseNode.textContent.slice(0, start),
          insert,
          selection.baseNode.textContent.slice(end)
        ].join('');
        selectionIndex = start + caretIndex;
      } else {
        const textContent = selection.baseNode.textContent;
        const symbolSuffix = isSet(textContent) ? textContent.match(/\w+$/) : undefined;
        const formulaToken =
          symbolSuffix && selection.baseNode.parentElement
            ? getRootFormulaToken(selection.baseNode.parentElement)
            : undefined;

        if (formulaToken) {
          formulaToken.textContent = insert;
          selectionNode = formulaToken.childNodes[0];
          selectionIndex = caretIndex;
        } else if (symbolSuffix) {
          const prefix = textContent.slice(0, textContent.length - symbolSuffix[0].length);
          selection.baseNode.textContent = prefix + insert;
          selectionIndex = prefix.length + caretIndex;
        } else {
          selection.baseNode.textContent = textContent + insert;
          selectionIndex = textContent.length + caretIndex;
        }
      }

      const newSelection = document.getSelection();
      const range = new Range();

      if (!selectionNode) {
        selectionNode =
          selection.baseNode.nodeType != Node.TEXT_NODE ? selection.baseNode.childNodes[0] : selection.baseNode;
      }

      range.setStart(selectionNode, selectionIndex);
      newSelection.removeAllRanges();
      newSelection.addRange(range);

      this.saveValue$.next();
    } else {
      const node = document.createTextNode(insert);

      this.inputElement.nativeElement.appendChild(node);

      const newSelection = document.getSelection();
      const range = new Range();

      range.setStart(node, selection && isSet(selection.baseOffset) ? selection.baseOffset + caretIndex : 0);
      newSelection.removeAllRanges();
      newSelection.addRange(range);

      this.saveValue$.next();
    }
  }

  onKeydown(e: KeyboardEvent) {
    if (e.keyCode == KeyboardEventKeyCode.Enter) {
      e.preventDefault();
    }
  }

  onFocus() {
    this.inputSelectionLast$.next(undefined);
  }

  onTokenSelected(item: FormulaInsert) {
    this.search$.next('');

    if (isSet(item.insert) || isSet(item.formula)) {
      this.insertFormulaToken(item);
    } else if (item.token[0] == InputTokenType.TextInputs) {
      this.switchToTextInputs.emit();
    } else if (item.token[0] == InputTokenType.Js) {
      this.switchToJs.emit();
    } else if (item.token[0] == InputTokenType.EmptyString) {
      this.switchToEmptyString.emit();
    } else if (item.token[0] == InputTokenType.Null) {
      this.switchToNull.emit();
    } else if (item.token[0] == InputTokenType.Prompt) {
      this.switchToPrompt.emit();
    } else if (item.token[0] == InputTokenType.Filter) {
      const filterItemData = item.data as FieldFilterInputTokenData;
      this.switchToFieldFilter.emit(filterItemData);
    }
  }

  checkIfContextValue() {
    if (!this.contextValueEnabled || !this.context) {
      return;
    }

    const formulaValueHuman = this.control.value;
    const formulaValue = transformFormulaElementAccessors(formulaValueHuman, this.context, false);

    try {
      const rootNode = mathjs.parse(cleanWrapSpaces(formulaValue));

      if (!mathJsIsSingleToken(rootNode)) {
        return;
      }
    } catch (e) {
      return;
    }

    const contextValue = singleTokenFormulaToContextValue(formulaValue);

    this.context
      .tokenPath$(contextValue, this.contextElement, this.contextElementPath, this.contextElementPaths)
      .pipe(first(), untilDestroyed(this))
      .subscribe(tokenPath => {
        if (!tokenPath) {
          return;
        }

        this.switchToContext.emit(formulaValue);
      });
  }
}
