import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';

import { NotificationService } from '@common/notifications';
import { BasePopupComponent } from '@common/popups';
import { ServerRequestError } from '@modules/api';
import { FirebaseParamsOptions } from '@modules/resource-generators';
import { FirebaseFunctionsRequirement, FirebaseRealTime, FirebaseService } from '@modules/resources';
import { controlValue, isSet, TypedChanges } from '@shared';

@Component({
  selector: 'app-sync-mode-firebase',
  templateUrl: './sync-mode-firebase.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SyncModeFirebaseComponent implements OnInit, OnDestroy, OnChanges {
  @Input() label = 'Real-time updates';
  @Input() enableSync = false;
  @Input() paramsOptions: FirebaseParamsOptions;
  @Input() submitLabel = 'Finish';
  @Input() submitLoader = false;
  @Input() cancelLabel = 'Cancel';
  @Output() submit = new EventEmitter<{ paramsOptions?: FirebaseParamsOptions }>();
  @Output() cancel = new EventEmitter<void>();

  enableSync$ = new BehaviorSubject<boolean>(this.enableSync);
  loading = false;
  disabled = false;
  firebaseRealTimeControl = new FormControl(FirebaseRealTime.Functions);
  firebaseRequirements: FirebaseFunctionsRequirement[];
  firebaseRequirementsLoading = false;
  firebaseRequirementsError: string;
  firebaseRequirementsSubscription: Subscription;
  realTimes = FirebaseRealTime;

  constructor(
    @Optional() protected popupComponent: BasePopupComponent,
    private firebaseService: FirebaseService,
    private notificationService: NotificationService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    combineLatest(this.enableSync$, controlValue<FirebaseRealTime>(this.firebaseRealTimeControl))
      .pipe(untilDestroyed(this))
      .subscribe(() => this.updateFirebaseRequirements());
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<SyncModeFirebaseComponent>): void {
    if (changes.enableSync) {
      this.enableSync$.next(this.enableSync);
    }

    if (changes.paramsOptions) {
      const realTime =
        this.paramsOptions && isSet(this.paramsOptions.realTime)
          ? this.paramsOptions.realTime
          : FirebaseRealTime.Functions;
      this.firebaseRealTimeControl.setValue(realTime);
    }
  }

  updateSubmitDisabled() {
    this.updateFirebaseRequirements();
  }

  updateFirebaseRequirements() {
    if (this.firebaseRequirementsSubscription) {
      this.firebaseRequirementsSubscription.unsubscribe();
      this.firebaseRequirementsSubscription = undefined;
    }

    if (this.enableSync && this.firebaseRealTimeControl.value == FirebaseRealTime.Functions) {
      this.loading = true;
      this.disabled = false;
      this.firebaseRequirements = undefined;
      this.firebaseRequirementsLoading = true;
      this.firebaseRequirementsError = undefined;
      this.cd.markForCheck();

      this.firebaseRequirementsSubscription = this.firebaseService
        .functionsCheckConfiguration(this.paramsOptions.projectId, JSON.parse(this.paramsOptions.serviceToken))
        .pipe(untilDestroyed(this))
        .subscribe(
          result => {
            this.loading = false;
            this.disabled = !result.result;
            this.firebaseRequirements = result.requirements;
            this.firebaseRequirementsLoading = false;
            this.cd.markForCheck();

            if (isSet(result.error) && result.requirements.every(item => item.status !== false)) {
              this.firebaseRequirementsError = result.error;
              this.notificationService.error('Firebase configuration needed', result.error);
            }
          },
          error => {
            this.loading = false;
            this.disabled = true;
            this.firebaseRequirementsLoading = false;

            if (error instanceof ServerRequestError && error.errors.length) {
              this.firebaseRequirementsError = error.errors[0];
              this.notificationService.error('Check configuration error', error.errors[0]);
            } else {
              this.firebaseRequirementsError = error;
              this.notificationService.error('Check configuration error', error);
            }

            this.cd.markForCheck();
          }
        );
    } else {
      this.loading = false;
      this.disabled = false;
      this.cd.markForCheck();
    }
  }

  submitResult() {
    this.submit.emit({
      paramsOptions: this.paramsOptions
        ? {
            ...this.paramsOptions,
            realTime: this.firebaseRealTimeControl.value
          }
        : undefined
    });
  }
}
