<div class="custom-page-popup__header">
  <div class="custom-page-popup__header-main">
    <div class="custom-page-popup__title">Draft changes</div>
  </div>
  <div class="custom-page-popup__header-right">
    <div class="custom-page-popup__close icon-close" (click)="close()"></div>
  </div>
</div>

<ng-template let-size="size" #table_rows_stub_template>
  <tr *ngFor="let item of [1, 2, 3, 4, 5, 6, 7, 8].slice(0, size || 8)" class="card-table__row">
    <td class="card-table__column">
      <div class="card-table__column-inner">
        <span [class.loading-animation]="true"><span class="stub-text">Collections</span></span>
      </div>
    </td>
    <td class="card-table__column">
      <div class="card-table__column-inner">
        <span [class.loading-animation]="true"><span class="stub-text">Page1, PageName2</span></span>
      </div>
    </td>
    <td class="card-table__column">
      <div class="card-table__column-inner"></div>
    </td>
    <td class="card-table__column">
      <div class="card-table__column-inner"></div>
    </td>
  </tr>
</ng-template>

<ng-template #table_stub_template>
  <table class="card-table">
    <tbody>
      <ng-container *ngTemplateOutlet="table_rows_stub_template"></ng-container>
    </tbody>
  </table>
</ng-template>

<div style="padding: 20px;">
  <ng-container *ngIf="!loading">
    <table class="card-table">
      <tbody>
        <tr
          *ngFor="let item of changes"
          class="card-table__row"
          [class.card-table__row_clickable]="!item.singleItem"
          app-draft-changes-item
          [item]="item"
          [disabled]="publishDraftLoading || deleteDraftLoading"
          (publish)="publishDraft(item, $event)"
          (discard)="deleteDraft(item, $event)"
        ></tr>
      </tbody>
    </table>
  </ng-container>

  <ng-container *ngIf="loading">
    <ng-container *ngTemplateOutlet="table_stub_template"></ng-container>
  </ng-container>
</div>
