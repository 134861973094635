import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { debounceTime, map, skip, switchMap } from 'rxjs/operators';

import { localize } from '@common/localize';
import { SelectSource } from '@common/select';
import { Option } from '@modules/field-components';
import { controlValue, isSet, TypedChanges } from '@shared';

export const defaultDropdownOptionsEmptyPlaceholder = () => localize('No options');

interface DisplayOption extends Option {
  selected: boolean;
}

@Component({
  selector: 'app-dropdown-options',
  templateUrl: './dropdown-options.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropdownOptionsComponent implements OnInit, OnDestroy, OnChanges {
  @Input() source: SelectSource;
  @Input() options: Option[] = [];
  @Input() selectedValues: any[] = [];
  @Input() compareWith: (o1: any, o2: any) => boolean;
  @Input() displaySelected = false;
  @Input() emptyPlaceholder: string = defaultDropdownOptionsEmptyPlaceholder();
  @Input() searchEnabled = false;
  @Input() searchAutofocus = true;
  @Input() searchExternalControl: FormControl;
  @Input() searchDebounce = 0;
  @Input() searchMinimumLength = 1;
  @Input() addValueEnabled = false;
  @Input() theme = false;
  @Output() valueSelected = new EventEmitter<Option>();
  @Output() addValue = new EventEmitter<any>();
  @Output() rootClick = new EventEmitter<MouseEvent>();

  @ViewChild('scrollable') scrollable: ElementRef<HTMLElement>;

  source$ = new BehaviorSubject<SelectSource>(undefined);
  options$ = new BehaviorSubject<Option[]>([]);
  selectedValues$ = new BehaviorSubject<any[]>([]);
  searchControl = new FormControl('');
  searchQuery$ = new BehaviorSubject<string>(undefined);
  displayOptions: DisplayOption[] = [];

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.initOptions();
    this.initSearch();
  }

  ngOnDestroy() {}

  ngOnChanges(changes: TypedChanges<DropdownOptionsComponent>): void {
    if (changes.source) {
      this.source$.next(this.source);
    }

    if (changes.options) {
      this.options$.next(this.options);
    }

    if (changes.selectedValues) {
      this.selectedValues$.next(this.selectedValues);
    }

    if (changes.emptyPlaceholder) {
      this.emptyPlaceholder = isSet(this.emptyPlaceholder)
        ? this.emptyPlaceholder
        : defaultDropdownOptionsEmptyPlaceholder();
    }
  }

  initOptions() {
    const options$ = combineLatest(this.source$, this.options$, this.searchQuery$).pipe(
      switchMap(([source, options, searchQuery]) => {
        if (isSet(searchQuery) && searchQuery.length < this.searchMinimumLength) {
          return of([]);
        }

        if (source) {
          source.reset();
          source.search(searchQuery);

          return source.options$;
        } else {
          options = this.searchOptions(options, searchQuery);
          return of(options);
        }
      })
    );

    combineLatest(options$, this.selectedValues$)
      .pipe(untilDestroyed(this))
      .subscribe(([options, selectedValues]) => {
        this.displayOptions = options
          .map(option => {
            return {
              ...option,
              selected: selectedValues.some(item => this.compareWith(item, option.value))
            };
          })
          .filter(option => this.displaySelected || !option.selected);
        this.cd.markForCheck();
      });
  }

  initSearch() {
    const search$ = controlValue<string>(this.searchControl).pipe(
      map(value => (isSet(value) ? String(value).trim() : undefined))
    );
    const searchExternal$ = this.searchExternalControl
      ? controlValue<string>(this.searchExternalControl).pipe(
          map(value => (isSet(value) ? String(value).trim() : undefined))
        )
      : of(undefined);

    combineLatest(search$, searchExternal$)
      .pipe(
        map(([search, searchExternal]) => {
          if (isSet(search)) {
            return search;
          } else if (isSet(searchExternal)) {
            return searchExternal;
          }
        }),
        debounceTime(this.searchDebounce),
        untilDestroyed(this)
      )
      .subscribe(value => {
        const searchQuery = isSet(value) ? value : undefined;
        this.searchQuery$.next(searchQuery);
      });
  }

  searchOptions(options: Option[], searchQuery: string): Option[] {
    if (isSet(searchQuery)) {
      return this.options.filter(item => {
        return isSet(item.name) && String(item.name).toLowerCase().includes(searchQuery.toLowerCase());
      });
    } else {
      return options;
    }
  }

  select(option: Option) {
    this.valueSelected.emit(option);
  }

  onScroll() {
    const viewport = this.scrollable.nativeElement;
    const scrollBottom = viewport.scrollTop + viewport.offsetHeight;
    const endDistance = viewport.scrollHeight - scrollBottom;

    if (endDistance <= Math.max(viewport.offsetHeight * 0.5, 200)) {
      this.onScrollFinished();
    }
  }

  onScrollFinished() {
    if (this.source && this.source.isFetchAvailable()) {
      this.source.loadMore();
    }
  }
}
