var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HttpClient } from '@angular/common/http';
import { Injector } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import { combineLatest, of, throwError } from 'rxjs';
import { catchError, delayWhen, map, share, switchMap } from 'rxjs/operators';
import { DataSyncService } from '@modules/data-sync';
import { applyFiltersComputedLookups, applyParamsComputedLookups } from '@modules/filter-utils';
import { ModelAction, PAGE_PARAM } from '@modules/models';
import { isResourceTypeItem3rdParty, ResourceDeploy, ResourceName, ResourceType } from '@modules/projects';
import { ListModelDescriptionQuery, QueryType } from '@modules/queries';
import { QueryTokensService } from '@modules/queries-tokens';
import { FIREBASE_ITEM_PRIMARY_KEY, FIREBASE_PARENT, getQueryOptionsToParams, JetAppResourceController, JetBridgeResourceController, paramsToGetQueryOptions, ResourceControllerService } from '@modules/resources';
import { AutomationService } from '@modules/workflow';
import { AppError, isSet } from '@shared';
// TODO: Refactor import
import { UserActivityService } from '../../../activities/services/user-activity/user-activity.service';
import { ModelDescriptionStore } from '../../stores/model-description.store';
var ModelService = /** @class */ (function () {
    function ModelService(http, injector, modelDescriptionStore, queryTokensService, resourceControllerService, dataSyncService, automationService, userActivityService) {
        this.http = http;
        this.injector = injector;
        this.modelDescriptionStore = modelDescriptionStore;
        this.queryTokensService = queryTokensService;
        this.resourceControllerService = resourceControllerService;
        this.dataSyncService = dataSyncService;
        this.automationService = automationService;
        this.userActivityService = userActivityService;
    }
    ModelService.prototype.getForModel = function (resources, modelId, query, useSync) {
        var _this = this;
        if (useSync === void 0) { useSync = true; }
        return this.modelDescriptionStore.getDetailFirst(modelId).pipe(map(function (modelDescription) {
            if (!modelDescription) {
                return;
            }
            var resource = resources && modelDescription
                ? resources.find(function (item) { return item.uniqueName == modelDescription.resource; })
                : undefined;
            if (!resource) {
                return;
            }
            if (useSync && (resource.isSynced(modelDescription.model) || modelDescription.isSynced())) {
                var controller = resource ? _this.resourceControllerService.get(ResourceType.JetBridge) : undefined;
                if (!controller) {
                    return;
                }
                if (!modelDescription.virtual || modelDescription.isSynced()) {
                    var newModel = cloneDeep(modelDescription);
                    newModel.getQuery = new ListModelDescriptionQuery();
                    newModel.getQuery.queryType = QueryType.Simple;
                    newModel.getQuery.simpleQuery = new newModel.getQuery.simpleQueryClass();
                    newModel.getQuery.simpleQuery.model = modelDescription.model;
                    newModel.getDetailQuery = undefined;
                    newModel.syncResource = true;
                    modelDescription = newModel;
                }
                return [resource, modelDescription, controller];
            }
            else {
                var resourceController = resource ? _this.resourceControllerService.get(resource.type) : undefined;
                var controller = void 0;
                if (query && modelDescription[query] && modelDescription[query].queryType == QueryType.Http) {
                    controller = _this.resourceControllerService.get(ResourceType.RestAPI);
                }
                else if (query && modelDescription[query] && modelDescription[query].queryType == QueryType.SQL) {
                    controller = _this.resourceControllerService.get(ResourceType.JetBridge);
                }
                else {
                    controller = resourceController;
                }
                if (!controller) {
                    return;
                }
                return [resource, modelDescription, controller];
            }
        }));
    };
    ModelService.prototype.get = function (project, environment, modelId, params, body) {
        var queryOptions = params ? paramsToGetQueryOptions(params) : undefined;
        return this.getAdv(project, environment, modelId, queryOptions, body);
    };
    ModelService.prototype.isGetAdvSupported = function (resource, modelDescription) {
        if (!resource) {
            return false;
        }
        var model = modelDescription ? modelDescription.model : undefined;
        var resourceType = resource.isSynced(model) || (modelDescription && modelDescription.isSynced())
            ? ResourceType.JetBridge
            : resource.type;
        var controller = this.resourceControllerService.get(resourceType);
        return controller.isGetAdvSupported && controller.isGetAdvSupported(resource);
    };
    ModelService.prototype.getAdv = function (project, environment, modelId, options, body) {
        var _this = this;
        if (options === void 0) { options = {}; }
        return this.getForModel(project.getEnvironmentResources(environment.uniqueName), modelId, 'getQuery').pipe(switchMap(function (modelParams) {
            var resource = modelParams[0], modelDescription = modelParams[1], controller = modelParams[2];
            if ((!options.sort || !options.sort.length) && isSet(modelDescription.defaultOrderBy)) {
                var _a = modelDescription.defaultOrderBy.startsWith('-')
                    ? [modelDescription.defaultOrderBy.slice(1), false]
                    : [modelDescription.defaultOrderBy, true], field = _a[0], asc = _a[1];
                options.sort = [{ field: field, desc: !asc }];
            }
            options.modelDescriptions = _this.modelDescriptionStore.instance;
            var advSupported = _this.isGetAdvSupported(resource, modelDescription);
            if (controller.modelGetAdv && advSupported) {
                return controller.modelGetAdv(resource, modelDescription, options);
            }
            else {
                var params = getQueryOptionsToParams(options);
                return controller.modelGet(resource, modelDescription, params, body);
            }
        }), share());
    };
    ModelService.prototype.getAll = function (project, environment, modelId, params, body) {
        var _this = this;
        var iterate = function (page) {
            var _a;
            var iterParams = __assign({}, params, (_a = {}, _a[PAGE_PARAM] = page, _a));
            return _this.get(project, environment, modelId, iterParams, body).pipe(switchMap(function (result) {
                if (result.hasMore) {
                    return iterate(page + 1).pipe(map(function (nextResult) {
                        return result.results.concat(nextResult);
                    }));
                }
                else {
                    return of(result.results);
                }
            }));
        };
        return iterate(1);
    };
    ModelService.prototype.getQuery = function (project, environment, resource, query, parameters, params, columns) {
        if (parameters === void 0) { parameters = []; }
        if (columns === void 0) { columns = []; }
        var queryOptions = params ? paramsToGetQueryOptions(params) : undefined;
        return this.getQueryAdv(project, environment, resource, query, parameters, queryOptions, columns);
    };
    ModelService.prototype.getQueryAdv = function (project, environment, resource, query, parameters, options, columns) {
        var _this = this;
        if (parameters === void 0) { parameters = []; }
        if (options === void 0) { options = {}; }
        if (columns === void 0) { columns = []; }
        if (!resource) {
            return throwError(new AppError('Resource not found'));
        }
        if (!query) {
            return throwError(new AppError('Query not specified'));
        }
        var params = getQueryOptionsToParams(options);
        if (isResourceTypeItem3rdParty(resource.typeItem) &&
            !project.features.isThirdPartyResourcesEnabled() &&
            !resource.demo) {
            return throwError(new AppError('Business Apps feature is not enabled in your Plan'));
        }
        if (query.queryType == QueryType.Simple && query.simpleQuery) {
            var modelId = [resource.uniqueName, query.simpleQuery.model].join('.');
            return this.modelDescriptionStore.getDetailFirst(modelId).pipe(switchMap(function (modelDescription) {
                if (!modelDescription) {
                    throw new AppError('Collection not found');
                }
                options = __assign({}, options, { filters: applyFiltersComputedLookups(options.filters || []) });
                return _this.getAdv(project, environment, modelDescription.modelId, options);
            }));
        }
        else if (query.queryType == QueryType.Http && query.httpQuery) {
            if (!project.features.isCustomResourcesEnabled() && !resource.demo) {
                return throwError(new AppError('Custom Queries feature is not enabled in your Plan'));
            }
            // TODO: Rename RestApiResourceControllerService to RestApiResourceController
            var controller = this.resourceControllerService.get(ResourceType.RestAPI);
            return controller.getHttp(resource, query, parameters, params, {}, columns);
        }
        else if (query.queryType == QueryType.SQL && query.sqlQuery) {
            if (!project.features.isCustomResourcesEnabled() && !resource.demo) {
                return throwError(new AppError('Custom Queries feature is not enabled in your Plan'));
            }
            params = applyParamsComputedLookups(params);
            var controller = this.resourceControllerService.getForResource(resource, true);
            return controller.getSql(resource, query, parameters, params, undefined, columns);
        }
        else if (query.queryType == QueryType.Object && query.objectQuery) {
            if (!project.features.isCustomResourcesEnabled() && !resource.demo) {
                return throwError(new AppError('Custom Queries feature is not enabled in your Plan'));
            }
            var controller = this.resourceControllerService.get(resource.type);
            return controller.getQueryObject(resource, query, parameters, params, undefined, columns);
        }
        else {
            return of(undefined);
        }
    };
    ModelService.prototype.getDetail = function (project, environment, modelId, idField, id, params) {
        var queryOptions = params ? paramsToGetQueryOptions(params) : undefined;
        return this.getDetailAdv(project, environment, modelId, idField, id, queryOptions);
    };
    ModelService.prototype.getDetailAdv = function (project, environment, modelId, idField, id, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        return this.getForModel(project.getEnvironmentResources(environment.uniqueName), modelId, 'getDetailQuery').pipe(switchMap(function (modelParams) {
            var resource = modelParams[0], modelDescription = modelParams[1], controller = modelParams[2];
            options.modelDescriptions = _this.modelDescriptionStore.instance;
            var advSupported = _this.isGetAdvSupported(resource, modelDescription);
            if (controller.modelGetDetailAdv && advSupported) {
                return controller.modelGetDetailAdv(resource, modelDescription, idField, id, options);
            }
            else {
                var params = getQueryOptionsToParams(options);
                return controller.modelGetDetail(resource, modelDescription, idField, id, params);
            }
        }), map(function (result) {
            if (!result) {
                throw new AppError('Not found');
            }
            return result;
        }), share());
    };
    ModelService.prototype.getDetailQuery = function (project, environment, resource, query, parameters, params, columns) {
        if (parameters === void 0) { parameters = []; }
        if (columns === void 0) { columns = []; }
        var queryOptions = params ? paramsToGetQueryOptions(params) : undefined;
        return this.getDetailQueryAdv(project, environment, resource, query, parameters, queryOptions, columns);
    };
    ModelService.prototype.getDetailQueryAdv = function (project, environment, resource, query, parameters, options, columns) {
        var _this = this;
        if (parameters === void 0) { parameters = []; }
        if (options === void 0) { options = {}; }
        if (columns === void 0) { columns = []; }
        if (!resource) {
            return throwError(new AppError('Resource not found'));
        }
        if (!query) {
            return throwError(new AppError('Query not specified'));
        }
        var params = getQueryOptionsToParams(options);
        if (isResourceTypeItem3rdParty(resource.typeItem) &&
            !project.features.isThirdPartyResourcesEnabled() &&
            !resource.demo) {
            return throwError(new AppError('Business Apps feature is not enabled in your Plan'));
        }
        if (query.queryType == QueryType.Simple && query.simpleQuery) {
            var modelId = [resource.uniqueName, query.simpleQuery.model].join('.');
            return this.modelDescriptionStore.getDetailFirst(modelId).pipe(switchMap(function (modelDescription) {
                if (!modelDescription) {
                    throw new AppError('Collection not found');
                }
                options = __assign({}, options, { filters: applyFiltersComputedLookups(options.filters || []) });
                return _this.getDetailAdv(project, environment, modelDescription.modelId, modelDescription.primaryKeyField, params[modelDescription.primaryKeyField], options);
            }));
        }
        else if (query.queryType == QueryType.Http && query.httpQuery) {
            if (!project.features.isCustomResourcesEnabled() && !resource.demo) {
                return throwError(new AppError('Custom Queries feature is not enabled in your Plan'));
            }
            var controller = this.resourceControllerService.get(ResourceType.RestAPI);
            return controller.getDetail(resource, query, parameters, params, columns).pipe(map(function (result) {
                if (!result) {
                    throw new AppError('Not found');
                }
                return result;
            }));
        }
        else if (query.queryType == QueryType.SQL && query.sqlQuery) {
            if (!project.features.isCustomResourcesEnabled() && !resource.demo) {
                return throwError(new AppError('Custom Queries feature is not enabled in your Plan'));
            }
            params = applyParamsComputedLookups(params);
            var controller = this.resourceControllerService.getForResource(resource, true);
            return controller.getDetailSql(resource, query, parameters, params, columns).pipe(map(function (result) {
                if (!result) {
                    throw new AppError('Not found');
                }
                return result;
            }));
        }
        else if (query.queryType == QueryType.Object && query.objectQuery) {
            if (!project.features.isCustomResourcesEnabled() && !resource.demo) {
                return throwError(new AppError('Custom Queries feature is not enabled in your Plan'));
            }
            var controller = this.resourceControllerService.get(resource.type);
            return controller.getDetailQueryObject(resource, query, params, columns).pipe(map(function (result) {
                if (!result) {
                    throw new AppError('Not found');
                }
                return result;
            }));
        }
        else {
            return of(undefined);
        }
    };
    ModelService.prototype.onModelAction = function (project, environment, resource, controller, modelDescription, instance, action, options) {
        if (options === void 0) { options = {}; }
        return combineLatest(this.syncOnUpdate(project, environment, resource, modelDescription, instance).pipe(catchError(function () { return of(false); })), this.triggerAutomationOnUpdate(project, environment, resource, controller, modelDescription, instance, action).pipe(catchError(function () { return of(false); })), this.logModelActionOnUpdate(project, environment, resource, controller, modelDescription, instance, action, options).pipe(catchError(function () { return of(false); }))).pipe(map(function () { return true; }));
    };
    ModelService.prototype.syncOnUpdate = function (project, environment, resource, modelDescription, instance) {
        var _a;
        if (!resource.isSynced(modelDescription.model) && !modelDescription.isSynced()) {
            return of(true);
        }
        var pk;
        var params;
        if (resource.typeItem.name == ResourceName.Firebase) {
            pk = instance.getAttribute(FIREBASE_ITEM_PRIMARY_KEY);
            params = (_a = {}, _a[FIREBASE_PARENT] = instance.getAttribute(FIREBASE_PARENT), _a);
        }
        else {
            pk = instance.primaryKey;
        }
        if (!isSet(pk)) {
            return of(true);
        }
        return this.dataSyncService
            .updateRecord(project, environment, resource.uniqueName, modelDescription.model, pk, params)
            .pipe(catchError(function () { return of(false); }));
    };
    ModelService.prototype.triggerAutomationOnUpdate = function (project, environment, resource, controller, modelDescription, instance, action) {
        if ((controller instanceof JetBridgeResourceController && resource.params['deploy'] == ResourceDeploy.Direct) ||
            controller instanceof JetAppResourceController) {
            return of(true);
        }
        var pk;
        if (resource.typeItem.name == ResourceName.Firebase) {
            pk = instance.getAttribute(FIREBASE_ITEM_PRIMARY_KEY);
        }
        else {
            pk = instance.primaryKey;
        }
        return this.automationService
            .trackModelChange(project, environment, resource, modelDescription.model, action, pk, instance.serialize())
            .pipe(catchError(function () { return of(false); }));
    };
    ModelService.prototype.logModelActionOnUpdate = function (project, environment, resource, controller, modelDescription, instance, action, options) {
        if (options === void 0) { options = {}; }
        if (action == ModelAction.Create) {
            return this.userActivityService.logModelCreate(project, environment, modelDescription, {
                id: instance.primaryKey,
                data: instance.serialize(),
                viewSettings: options.viewSettings
            });
        }
        else if (action == ModelAction.Update) {
            return this.userActivityService.logModelUpdate(project, environment, modelDescription, instance.primaryKey, {
                // changes: form.changes,
                viewSettings: options.viewSettings
            });
        }
        else if (action == ModelAction.Delete) {
            return this.userActivityService.logModelDelete(project, environment, modelDescription, {
                id: instance.primaryKey,
                data: instance.serialize(),
                viewSettings: options.viewSettings
            });
        }
        else {
            return of(undefined);
        }
    };
    ModelService.prototype.create = function (project, environment, modelId, modelInstance, fields) {
        var _this = this;
        return this.getForModel(project.getEnvironmentResources(environment.uniqueName), modelId, 'createQuery', false).pipe(switchMap(function (modelParams) {
            var resource = modelParams[0], modelDescription = modelParams[1], controller = modelParams[2];
            return controller
                .modelCreate(resource, modelDescription, modelInstance, fields)
                .pipe(delayWhen(function (result) {
                return _this.onModelAction(project, environment, resource, controller, modelDescription, result, ModelAction.Create);
            }));
        }), share());
    };
    ModelService.prototype.update = function (project, environment, modelId, modelInstance, fields) {
        var _this = this;
        return this.getForModel(project.getEnvironmentResources(environment.uniqueName), modelId, 'updateQuery', false).pipe(switchMap(function (modelParams) {
            var resource = modelParams[0], modelDescription = modelParams[1], controller = modelParams[2];
            return controller
                .modelUpdate(resource, modelDescription, modelInstance, fields)
                .pipe(delayWhen(function () {
                return _this.onModelAction(project, environment, resource, controller, modelDescription, modelInstance, ModelAction.Update);
            }));
        }), share());
    };
    ModelService.prototype.delete = function (project, environment, modelId, modelInstance) {
        var _this = this;
        return this.getForModel(project.getEnvironmentResources(environment.uniqueName), modelId, 'deleteQuery', false).pipe(switchMap(function (modelParams) {
            var resource = modelParams[0], modelDescription = modelParams[1], controller = modelParams[2];
            return controller
                .modelDelete(resource, modelDescription, modelInstance)
                .pipe(delayWhen(function () {
                return _this.onModelAction(project, environment, resource, controller, modelDescription, modelInstance, ModelAction.Delete);
            }));
        }), share());
    };
    ModelService.prototype.reorder = function (project, environment, modelId, forward, segmentFrom, segmentTo, item, segmentByOrderingField) {
        if (segmentByOrderingField === void 0) { segmentByOrderingField = false; }
        return this.getForModel(project.getEnvironmentResources(environment.uniqueName), modelId, undefined, false).pipe(switchMap(function (modelParams) {
            var resource = modelParams[0], modelDescription = modelParams[1], controller = modelParams[2];
            return controller.modelReorder(resource, modelDescription, forward, segmentFrom, segmentTo, item, segmentByOrderingField);
        }), share());
    };
    ModelService.prototype.resetOrder = function (project, environment, modelId, ordering, valueOrdering) {
        return this.getForModel(project.getEnvironmentResources(environment.uniqueName), modelId, undefined, false).pipe(switchMap(function (modelParams) {
            var resource = modelParams[0], modelDescription = modelParams[1], controller = modelParams[2];
            return controller.modelResetOrder(resource, modelDescription, ordering, valueOrdering);
        }), share());
    };
    ModelService.prototype.aggregate = function (project, environment, modelId, yFunc, yColumn, params) {
        return this.getForModel(project.getEnvironmentResources(environment.uniqueName), modelId).pipe(switchMap(function (modelParams) {
            var resource = modelParams[0], modelDescription = modelParams[1], controller = modelParams[2];
            if (!controller.modelAggregate) {
                return of(null);
            }
            return controller.modelAggregate(resource, modelDescription, yFunc, yColumn, params);
        }), share());
    };
    ModelService.prototype.group = function (project, environment, modelId, xColumns, yFunc, yColumn, params) {
        return this.getForModel(project.getEnvironmentResources(environment.uniqueName), modelId).pipe(switchMap(function (modelParams) {
            var resource = modelParams[0], modelDescription = modelParams[1], controller = modelParams[2];
            if (!controller.modelGroup) {
                return of([]);
            }
            return controller.modelGroup(resource, modelDescription, xColumns, yFunc, yColumn, params);
        }), share());
    };
    ModelService.prototype.groupQuery = function (project, environment, resource, query, xColumns, yFunc, yColumn, parameters, params) {
        var _this = this;
        if (parameters === void 0) { parameters = []; }
        if (!resource) {
            return throwError(new AppError('Resource not found'));
        }
        if (!query) {
            return throwError(new AppError('Query not specified'));
        }
        params = params || {};
        if (query.queryType == QueryType.Simple && query.simpleQuery) {
            var modelId = [resource.uniqueName, query.simpleQuery.model].join('.');
            return this.modelDescriptionStore.getDetailFirst(modelId).pipe(switchMap(function (modelDescription) {
                if (!modelDescription) {
                    throw new AppError('Collection not found');
                }
                return _this.group(project, environment, modelDescription.modelId, xColumns, yFunc, yColumn, params);
            }));
        }
        else if (query.queryType == QueryType.Http && query.httpQuery) {
            var controller = this.resourceControllerService.get(ResourceType.RestAPI);
            return controller.group(resource, query.httpQuery, xColumns, yFunc, yColumn, parameters, params);
        }
        else {
            return of(undefined);
        }
    };
    ModelService.prototype.getSiblings = function (project, environment, modelId, id, params, body) {
        return this.getForModel(project.getEnvironmentResources(environment.uniqueName), modelId).pipe(switchMap(function (modelParams) {
            var resource = modelParams[0], modelDescription = modelParams[1], controller = modelParams[2];
            return controller.modelGetSiblings(resource, modelDescription, id, params, body);
        }), share());
    };
    ModelService.prototype.sql = function (resource, query, tokens, version) {
        var controller = this.resourceControllerService.get(resource.type);
        return controller.sql(resource, query, { tokens: tokens, version: version }).pipe(share());
    };
    ModelService.prototype.sqls = function (resource, queries) {
        var controller = this.resourceControllerService.get(resource.type);
        return controller.sqls(resource, queries).pipe(share());
    };
    return ModelService;
}());
export { ModelService };
