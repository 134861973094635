import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Power4, TimelineMax } from 'gsap';
import clamp from 'lodash/clamp';
import uniq from 'lodash/uniq';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { delay, filter, map, switchMap } from 'rxjs/operators';

import { CurrentEnvironmentStore } from '@modules/projects';
import { defaultFontName } from '@modules/theme';
import { CurrentUserStore } from '@modules/users';
import { isColorHex, isSet, toggleClass } from '@shared';

import { MenuUpdateForm } from '../project-settings/menu-update.form';
import { ProjectAppearanceForm } from '../project-settings/project-appearance.form';
import { ThemeTemplate } from './theme-template';
import {
  applyThemeActionElementStyles,
  applyThemeElementWrapperStyles,
  applyThemeFieldElementStyles,
  applyThemeMenuBlockStyles
} from './theme-template-apply.utils';
import {
  serializeActionElementStyles,
  serializeElementWrapperStyles,
  serializeFieldElementStyles,
  serializeMenuBlock
} from './theme-template-serialize.utils';
import { themeTemplates } from './theme-templates';

@Component({
  selector: 'app-theme-gallery',
  templateUrl: './theme-gallery.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ThemeGalleryComponent implements OnInit, OnDestroy {
  @Input() settingsForm: ProjectAppearanceForm;
  @Input() menuForm: MenuUpdateForm;
  @Input() applyEnabled = true;
  @Output() applyClick = new EventEmitter<ThemeTemplate>();

  @ViewChild('viewport_element') viewportElement: ElementRef;
  @ViewChildren('item_element', { read: ElementRef }) itemElements = new QueryList<ElementRef<HTMLElement>>();

  items: ThemeTemplate[] = themeTemplates;
  firstIndex = -1;
  lastIndex = -1;
  scrollPosition$ = new BehaviorSubject<number>(0);
  scrollingToPosition$ = new BehaviorSubject<number>(undefined);
  scrollTl = new TimelineMax();
  externalFonts: string[] = [];

  constructor(
    public currentUserStore: CurrentUserStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.updateExternalFonts();
    this.initCarouselScroll();
  }

  ngOnDestroy(): void {}

  updateExternalFonts() {
    this.externalFonts = this.getExternalFonts(this.items);
    this.cd.markForCheck();
  }

  getExternalFonts(themes: ThemeTemplate[]): string[] {
    return uniq(
      themes.reduce((acc, theme) => {
        acc.push(...[theme.fontHeading, theme.fontRegular].filter(item => isSet(item)));
        return acc;
      }, [])
    );
  }

  initCarouselScroll() {
    combineLatest(this.scrollPosition$, this.scrollingToPosition$)
      .pipe(untilDestroyed(this))
      .subscribe(([]) => {
        const firstIndex = this.getVisibleIndexFirst();
        const lastIndex = this.getVisibleIndexLast();

        if (this.firstIndex !== firstIndex) {
          this.firstIndex = firstIndex;
          this.cd.markForCheck();
        }

        if (this.lastIndex !== lastIndex) {
          this.lastIndex = lastIndex;
          this.cd.markForCheck();
        }
      });

    this.scrollingToPosition$
      .pipe(
        filter(() => !!this.viewportElement),
        map(scrollingToPosition => isSet(scrollingToPosition)),
        switchMap(manualScroll => {
          const result = of(manualScroll);
          return manualScroll ? result : result.pipe(delay(10));
        }),
        untilDestroyed(this)
      )
      .subscribe(manualScroll => {
        toggleClass(this.viewportElement.nativeElement, 'sidebar-gallery__viewport_manual-scroll', manualScroll);
      });
  }

  isScrollPrevAvailable(): boolean {
    return this.items && this.firstIndex > 0;
  }

  isScrollNextAvailable(): boolean {
    return this.items && this.lastIndex < this.items.length - 1;
  }

  getVisibleIndexFirst(): number {
    if (!this.viewportElement) {
      return -1;
    }

    const scrollLeftActual = this.viewportElement.nativeElement.scrollLeft;
    const scrollLeft = isSet(this.scrollingToPosition$.value) ? this.scrollingToPosition$.value : scrollLeftActual;
    const viewportBounds = this.viewportElement.nativeElement.getBoundingClientRect();
    const itemElements = this.itemElements.toArray();

    const index = itemElements.findIndex((item, i) => {
      const itemBounds = item.nativeElement.getBoundingClientRect();
      return (
        Math.floor(itemBounds.left + itemBounds.width * 0.5) - Math.floor(viewportBounds.left) + scrollLeftActual >
        scrollLeft
      );
    });

    if (index === -1) {
      return itemElements.length - 1;
    }

    return index;
  }

  getVisibleIndexLast(): number {
    if (!this.viewportElement) {
      return -1;
    }

    const scrollLeftActual = this.viewportElement.nativeElement.scrollLeft;
    const scrollLeft = isSet(this.scrollingToPosition$.value) ? this.scrollingToPosition$.value : scrollLeftActual;
    const viewportBounds = this.viewportElement.nativeElement.getBoundingClientRect();
    const itemElements = this.itemElements.toArray();

    const index = [...itemElements].reverse().findIndex((item, i) => {
      const itemBounds = item.nativeElement.getBoundingClientRect();
      return (
        Math.floor(itemBounds.left + itemBounds.width * 0.5) - Math.floor(viewportBounds.left) + scrollLeftActual <
        scrollLeft + viewportBounds.width
      );
    });

    if (index === -1) {
      return itemElements.length - 1;
    }

    return itemElements.length - 1 - index;
  }

  scrollToIndexWithDelta(delta: number) {
    if (delta === 0) {
      return;
    }
    const currentIndex = this.getVisibleIndexFirst();
    if (currentIndex === -1) {
      return;
    }

    const itemElements = this.itemElements.toArray();
    const newIndex = clamp(currentIndex + delta, 0, itemElements.length - 1);

    this.scrollToIndex(newIndex);
  }

  scrollToIndex(newIndex: number, force = false) {
    const currentIndex = this.getVisibleIndexFirst();
    if (currentIndex === newIndex && !force) {
      return;
    }

    const itemElements = this.itemElements.toArray();
    const item = itemElements[newIndex];
    if (!item) {
      return;
    }

    const viewportBounds = this.viewportElement.nativeElement.getBoundingClientRect();
    const scrollLeft = this.viewportElement.nativeElement.scrollLeft;
    const itemBounds = item.nativeElement.getBoundingClientRect();
    const padding = 15;
    const x = itemBounds.left - viewportBounds.left + scrollLeft - padding;

    this.scrollToPosition(x);
  }

  scrollToPosition(x: number) {
    this.scrollingToPosition$.next(x);

    const finish = (complete: boolean) => {
      this.scrollingToPosition$.next(undefined);
    };

    this.scrollTl.clear().to(this.viewportElement.nativeElement, 0.6, {
      scrollTo: {
        x: x,
        onAutoKill: () => finish(false)
      },
      ease: Power4.easeOut,
      onOverwrite: () => finish(false),
      onComplete: () => finish(true)
    });
  }

  scrollToPrev() {
    this.scrollToIndexWithDelta(-1);
  }

  scrollToNext() {
    this.scrollToIndexWithDelta(1);
  }

  onScroll() {
    const position = this.viewportElement.nativeElement.scrollLeft;
    this.scrollPosition$.next(position);
  }

  onMouseWheel(e: WheelEvent) {
    const viewport = this.viewportElement.nativeElement;
    const horizontalScroll = Math.abs(e.deltaX) > Math.abs(e.deltaY);
    if (!horizontalScroll) {
      return;
    }

    if (viewport.scrollLeft == 0 && e.deltaX < 0) {
      e.preventDefault();
    } else if (viewport.scrollLeft + viewport.offsetWidth >= viewport.scrollWidth && e.deltaX > 0) {
      e.preventDefault();
    }
  }

  patchValue(control: AbstractControl, value: any) {
    control.patchValue(value);
  }

  patchValueIfSet(control: AbstractControl, value: any) {
    if (isSet(value)) {
      control.patchValue(value);
    }
  }

  applyThemeTemplate(theme: ThemeTemplate) {
    this.applyClick.emit(theme);

    if (!this.applyEnabled) {
      return;
    }

    const settingsControls = this.settingsForm.controls;

    if (isColorHex(theme.accentColor)) {
      settingsControls.accent_color.patchValue('');
      settingsControls.accent_color_custom_enabled.patchValue(true);
      settingsControls.accent_color_custom.patchValue(theme.accentColor);
    } else {
      settingsControls.accent_color.patchValue(isSet(theme.accentColor) ? theme.accentColor : '');
      settingsControls.accent_color_custom_enabled.patchValue(false);
      settingsControls.accent_color_custom.patchValue('#2B50ED');
    }

    if (isColorHex(theme.accentColorDark)) {
      settingsControls.accent_color_dark.patchValue('');
      settingsControls.accent_color_dark_custom_enabled.patchValue(true);
      settingsControls.accent_color_dark_custom.patchValue(theme.accentColorDark);
    } else {
      settingsControls.accent_color_dark.patchValue(isSet(theme.accentColorDark) ? theme.accentColorDark : '');
      settingsControls.accent_color_dark_custom_enabled.patchValue(false);
      settingsControls.accent_color_dark_custom.patchValue('#2B50ED');
    }

    this.patchValue(settingsControls.background_color, theme.backgroundColor);
    this.patchValue(settingsControls.background_color_dark, theme.backgroundColorDark);
    this.patchValue(settingsControls.background_color_2, theme.backgroundColor2);
    this.patchValue(settingsControls.background_color_2_dark, theme.backgroundColor2Dark);
    this.patchValue(settingsControls.background_color_3, theme.backgroundColor3);
    this.patchValue(settingsControls.background_color_3_dark, theme.backgroundColor3Dark);
    this.patchValue(settingsControls.background_color_4, theme.backgroundColor4);
    this.patchValue(settingsControls.background_color_4_dark, theme.backgroundColor4Dark);
    this.patchValue(settingsControls.background_color_5, theme.backgroundColor5);
    this.patchValue(settingsControls.background_color_5_dark, theme.backgroundColor5Dark);
    this.patchValue(settingsControls.text_color, theme.textColor);
    this.patchValue(settingsControls.text_color_dark, theme.textColorDark);
    this.patchValue(settingsControls.text_color_2, theme.textColor2);
    this.patchValue(settingsControls.text_color_2_dark, theme.textColor2Dark);
    this.patchValue(settingsControls.text_color_3, theme.textColor3);
    this.patchValue(settingsControls.text_color_3_dark, theme.textColor3Dark);
    this.patchValue(settingsControls.border_color, theme.borderColor);
    this.patchValue(settingsControls.border_color_dark, theme.borderColorDark);
    this.patchValue(settingsControls.border_color_2, theme.borderColor2);
    this.patchValue(settingsControls.border_color_2_dark, theme.borderColor2Dark);
    this.patchValue(settingsControls.border_color_3, theme.borderColor3);
    this.patchValue(settingsControls.border_color_3_dark, theme.borderColor3Dark);

    settingsControls.auto_colors.deserialize(theme.autoColors || []);
    settingsControls.auto_colors_dark.deserialize(theme.autoColorsDark || []);

    this.patchValue(settingsControls.border_radius, theme.borderRadius);
    this.patchValueIfSet(settingsControls.max_width, theme.maxWidth);
    this.patchValueIfSet(settingsControls.padding, theme.padding);
    this.patchValue(settingsControls.font_regular, theme.fontRegular || defaultFontName);
    this.patchValue(settingsControls.font_heading, theme.fontHeading || defaultFontName);

    applyThemeActionElementStyles(settingsControls.action_element_styles_primary, theme.actionElementStylesPrimary);
    applyThemeActionElementStyles(settingsControls.action_element_styles_default, theme.actionElementStylesDefault);
    applyThemeActionElementStyles(
      settingsControls.action_element_styles_transparent,
      theme.actionElementStylesTransparent
    );
    applyThemeFieldElementStyles(settingsControls.field_element_styles, theme.fieldElementStyles);
    applyThemeElementWrapperStyles(settingsControls.element_wrapper_styles, theme.elementWrapperStyles);

    const blocksControl = this.menuForm.controls.blocks;
    const blockControlsEnabled = blocksControl.controls.filter(item => {
      return item.controls.enabled.value && !item.controls.enabled_input_enabled.value;
    });
    const blockControlsDisabled = blocksControl.controls.filter(item => !blockControlsEnabled.includes(item));
    const blockControlsTouched = [];

    (theme.menuBlocks || []).forEach((block, i) => {
      const control = [...blockControlsEnabled, ...blockControlsDisabled][i] || blocksControl.appendControl();

      applyThemeMenuBlockStyles(control, block);

      blockControlsTouched.push(control);
    });

    blocksControl.controls
      .filter(item => !blockControlsTouched.includes(item))
      .forEach(control => {
        control.controls.enabled.patchValue(false);
        control.controls.enabled_input_enabled.patchValue(false);
      });
  }

  getThemeTemplate(): ThemeTemplate {
    const result: ThemeTemplate = { name: this.currentEnvironmentStore.instance.name };
    const settingsControls = this.settingsForm.controls;

    result.accentColor = this.settingsForm.getAccentColor();
    result.accentColorDark = this.settingsForm.getAccentColorDark();
    result.backgroundColor = settingsControls.background_color.value;
    result.backgroundColorDark = settingsControls.background_color_dark.value;
    result.backgroundColor2 = settingsControls.background_color_2.value;
    result.backgroundColor2Dark = settingsControls.background_color_2_dark.value;
    result.backgroundColor3 = settingsControls.background_color_3.value;
    result.backgroundColor3Dark = settingsControls.background_color_3_dark.value;
    result.backgroundColor4 = settingsControls.background_color_4.value;
    result.backgroundColor4Dark = settingsControls.background_color_4_dark.value;
    result.backgroundColor5 = settingsControls.background_color_5.value;
    result.backgroundColor5Dark = settingsControls.background_color_5_dark.value;
    result.textColor = settingsControls.text_color.value;
    result.textColorDark = settingsControls.text_color_dark.value;
    result.textColor2 = settingsControls.text_color_2.value;
    result.textColor2Dark = settingsControls.text_color_2_dark.value;
    result.textColor3 = settingsControls.text_color_3.value;
    result.textColor3Dark = settingsControls.text_color_3_dark.value;
    result.borderColor = settingsControls.border_color.value;
    result.borderColorDark = settingsControls.border_color_dark.value;
    result.borderColor2 = settingsControls.border_color_2.value;
    result.borderColor2Dark = settingsControls.border_color_2_dark.value;
    result.borderColor3 = settingsControls.border_color_3.value;
    result.borderColor3Dark = settingsControls.border_color_3_dark.value;
    result.borderRadius = settingsControls.border_radius.value;
    result.autoColors = settingsControls.auto_colors.serialize();
    result.autoColorsDark = settingsControls.auto_colors_dark.serialize();
    result.maxWidth = isSet(settingsControls.max_width.value) ? settingsControls.max_width.value : undefined;
    result.padding = settingsControls.padding.serialize();

    if (settingsControls.font_regular.value != defaultFontName) {
      result.fontRegular = settingsControls.font_regular.value;
    } else {
      result.fontRegular = undefined;
    }

    if (settingsControls.font_heading.value != defaultFontName) {
      result.fontHeading = settingsControls.font_heading.value;
    } else {
      result.fontHeading = undefined;
    }

    result.actionElementStylesPrimary = serializeActionElementStyles(settingsControls.action_element_styles_primary);
    result.actionElementStylesDefault = serializeActionElementStyles(settingsControls.action_element_styles_default);
    result.actionElementStylesTransparent = serializeActionElementStyles(
      settingsControls.action_element_styles_transparent
    );
    result.fieldElementStyles = serializeFieldElementStyles(settingsControls.field_element_styles);
    result.elementWrapperStyles = serializeElementWrapperStyles(settingsControls.element_wrapper_styles);

    const blocks = this.menuForm.controls.blocks.controls
      .map(control => serializeMenuBlock(control))
      .filter(item => item);

    if (blocks.length) {
      result.menuBlocks = blocks;
    }

    return result;
  }

  dumpThemeTemplate() {
    const theme = this.getThemeTemplate();
    console.log('theme', theme);
  }
}
