<div class="sidebar__element">
  <div class="sidebar-cols">
    <div class="sidebar-cols__item">
      <app-customize-bar-input-edit
        [label]="'Min value'"
        [itemForm]="form.controls.min_value_input"
        [context]="context"
        [placeholder]="'1'"
        [resetEnabled]="false"
        [fill]="true"
        [analyticsSource]="analyticsSource + '_min_value'"
      ></app-customize-bar-input-edit>
    </div>

    <div class="sidebar-cols__item">
      <app-customize-bar-input-edit
        [label]="'Max value'"
        [itemForm]="form.controls.max_value_input"
        [context]="context"
        [placeholder]="'10'"
        [resetEnabled]="false"
        [fill]="true"
        [analyticsSource]="analyticsSource + '_max_value'"
      ></app-customize-bar-input-edit>
    </div>
  </div>
</div>

<div class="sidebar__element">
  <app-customize-bar-input-edit
    [label]="'Step size'"
    [itemForm]="form.controls.step_size_input"
    [context]="context"
    [placeholder]="'10'"
    [resetEnabled]="false"
    [fill]="true"
    [analyticsSource]="analyticsSource + '_step_size'"
  ></app-customize-bar-input-edit>
</div>
