/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../../shared/pipes/deploy-css-url/deploy-css-url.pipe";
import * as i3 from "@angular/cdk/overlay";
import * as i4 from "@angular/cdk/bidi";
import * as i5 from "./menu-block-layout-overlay.component";
var styles_MenuBlockLayoutOverlayComponent = [];
var RenderType_MenuBlockLayoutOverlayComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MenuBlockLayoutOverlayComponent, data: {} });
export { RenderType_MenuBlockLayoutOverlayComponent as RenderType_MenuBlockLayoutOverlayComponent };
function View_MenuBlockLayoutOverlayComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "a", [["class", "token-popup-list-item token-popup-list-item_hoverable token-popup-list-item_clickable"], ["href", "javascript:void(0)"]], [[2, "token-popup-list-item_active", null], [4, "margin-top", "px"]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.selectLayoutOption.emit(_v.context.$implicit);
        var pd_0 = (_co.close.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 9, "div", [["class", "token-popup-list-item__inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 8, "div", [["class", "token-popup-list-item__line"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "token-popup-list-item__left token-popup-list-item__left_margin_l"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "div", [["class", "token-popup-list-item__image token-popup-list-item__image_border token-popup-list-item__image_size_xl"]], [[4, "background-image", null]], null, null, null, null)), i0.ɵppd(5, 1), (_l()(), i0.ɵeld(6, 0, null, null, 4, "div", [["class", "token-popup-list-item__main token-popup-list-item__main_wrap"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "div", [["class", "token-popup-list-item__label"]], null, null, null, null, null)), (_l()(), i0.ɵted(8, null, ["", ""])), (_l()(), i0.ɵeld(9, 0, null, null, 1, "div", [["class", "token-popup-list-item__description"]], null, null, null, null, null)), (_l()(), i0.ɵted(10, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.layoutSelected === _v.context.$implicit); var currVal_1 = (_v.context.first ? 0 : 5); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = i0.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i0.ɵnov(_v.parent.parent.parent, 0), (("/assets/images/project-layouts/" + _v.context.$implicit.image) + ".svg"))); _ck(_v, 4, 0, currVal_2); var currVal_3 = _v.context.$implicit.title; _ck(_v, 8, 0, currVal_3); var currVal_4 = _v.context.$implicit.subtitle; _ck(_v, 10, 0, currVal_4); }); }
function View_MenuBlockLayoutOverlayComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "div", [["class", "token-popup__item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 7, "div", [["class", "token-popup-section"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [["class", "token-popup-section__title"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "div", [["class", "token-popup-section__title-main"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""])), (_l()(), i0.ɵeld(5, 0, null, null, 3, "div", [["class", "token-popup-section__content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 2, "div", [["class", "token-popup__item"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MenuBlockLayoutOverlayComponent_3)), i0.ɵdid(8, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_1 = _v.context.$implicit.options; _ck(_v, 8, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.label; _ck(_v, 4, 0, currVal_0); }); }
function View_MenuBlockLayoutOverlayComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "popover2 overlay-content overlay-content_animated"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "popover2__inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 3, "div", [["class", "popover2__viewport"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "popover2__content"], ["style", "max-width: 300px;"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MenuBlockLayoutOverlayComponent_2)), i0.ɵdid(5, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.layoutGroups; _ck(_v, 5, 0, currVal_0); }, null); }
export function View_MenuBlockLayoutOverlayComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i2.DeployCssUrlPipe, []), (_l()(), i0.ɵand(16777216, null, null, 2, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("backdropClick" === en)) {
        var pd_0 = (_co.close.emit() !== false);
        ad = (pd_0 && ad);
    } if (("detach" === en)) {
        var pd_1 = (_co.close.emit() !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_MenuBlockLayoutOverlayComponent_1)), i0.ɵdid(2, 671744, null, 0, i3.CdkConnectedOverlay, [i3.Overlay, i0.TemplateRef, i0.ViewContainerRef, i3.ɵc, [2, i4.Directionality]], { origin: [0, "origin"], positions: [1, "positions"], backdropClass: [2, "backdropClass"], panelClass: [3, "panelClass"], open: [4, "open"], hasBackdrop: [5, "hasBackdrop"], growAfterOpen: [6, "growAfterOpen"], push: [7, "push"] }, { backdropClick: "backdropClick", detach: "detach" }), i0.ɵpad(3, 2)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.origin; var currVal_1 = _co.overlayPositions; var currVal_2 = "popover2-backdrop"; var currVal_3 = _ck(_v, 3, 0, "overlay", "overlay_horizontal_center"); var currVal_4 = _co.opened; var currVal_5 = true; var currVal_6 = true; var currVal_7 = true; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, null); }
export function View_MenuBlockLayoutOverlayComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-menu-block-layout-overlay", [], null, null, null, View_MenuBlockLayoutOverlayComponent_0, RenderType_MenuBlockLayoutOverlayComponent)), i0.ɵdid(1, 114688, null, 0, i5.MenuBlockLayoutOverlayComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MenuBlockLayoutOverlayComponentNgFactory = i0.ɵccf("app-menu-block-layout-overlay", i5.MenuBlockLayoutOverlayComponent, View_MenuBlockLayoutOverlayComponent_Host_0, { layoutGroups: "layoutGroups", layoutSelected: "layoutSelected", opened: "opened", origin: "origin" }, { selectLayoutOption: "selectLayoutOption", close: "close" }, []);
export { MenuBlockLayoutOverlayComponentNgFactory as MenuBlockLayoutOverlayComponentNgFactory };
