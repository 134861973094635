/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/cdk/drag-drop";
import * as i2 from "@angular/common";
import * as i3 from "@angular/cdk/scrolling";
import * as i4 from "@angular/cdk/bidi";
import * as i5 from "./actions-edit-item/actions-edit-item.component.ngfactory";
import * as i6 from "./actions-edit-item/actions-edit-item.component";
import * as i7 from "../../../action-queries/services/action/action.service";
import * as i8 from "../../../projects/stores/current-environment.store";
import * as i9 from "../../../../common/form-utils/pipes/form-field-errors/form-field-errors.pipe";
import * as i10 from "../../../../common/form-utils/services/form-utils";
import * as i11 from "./actions-edit.form";
import * as i12 from "../../../customize-configuration/services/element-configuration/element-configuration.service";
import * as i13 from "./actions-edit.component";
import * as i14 from "../../services/customize-bar/customize-bar.service";
import * as i15 from "../../services/customize-bar-context/customize-bar.context";
var styles_ActionsEditComponent = [];
var RenderType_ActionsEditComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ActionsEditComponent, data: {} });
export { RenderType_ActionsEditComponent as RenderType_ActionsEditComponent };
function View_ActionsEditComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 8, "div", [["cdkDrag", ""], ["class", "sidebar-list__item cdk-drag"]], [[2, "cdk-drag-disabled", null], [2, "cdk-drag-dragging", null]], null, null, null, null)), i0.ɵprd(6144, null, i1.ɵb, null, [i1.CdkDrag]), i0.ɵdid(2, 4866048, [[1, 4]], 3, i1.CdkDrag, [i0.ElementRef, [3, i1.CDK_DROP_LIST], i2.DOCUMENT, i0.NgZone, i0.ViewContainerRef, i3.ViewportRuler, i1.DragDropRegistry, i1.CDK_DRAG_CONFIG, [2, i4.Directionality], i1.DragDrop, i0.ChangeDetectorRef], null, null), i0.ɵqud(603979776, 2, { _handles: 1 }), i0.ɵqud(335544320, 3, { _previewTemplate: 0 }), i0.ɵqud(335544320, 4, { _placeholderTemplate: 0 }), (_l()(), i0.ɵeld(6, 0, null, null, 2, "app-actions-edit-item", [], null, [[null, "open"], [null, "pinClick"], [null, "removeClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("open" === en)) {
        var pd_0 = (_co.editItem(_v.context.$implicit, !_v.context.$implicit.value["protected"]) !== false);
        ad = (pd_0 && ad);
    } if (("pinClick" === en)) {
        var pd_1 = (_co.toggleItemPinned(_v.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } if (("removeClick" === en)) {
        var pd_2 = (_co.removeItem(_v.context.$implicit) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i5.View_ActionsEditItemComponent_0, i5.RenderType_ActionsEditItemComponent)), i0.ɵdid(7, 770048, null, 0, i6.ActionsEditItemComponent, [i7.ActionService, i8.CurrentEnvironmentStore, i0.ChangeDetectorRef], { actionItemClass: [0, "actionItemClass"], action: [1, "action"], context: [2, "context"], contextElement: [3, "contextElement"], defaultTitle: [4, "defaultTitle"], draggable: [5, "draggable"], remove: [6, "remove"], pin: [7, "pin"], pinned: [8, "pinned"], error: [9, "error"], classes: [10, "classes"] }, { open: "open", removeClick: "removeClick", pinClick: "pinClick" }), i0.ɵpid(0, i9.FormFieldErrorsPipe, [i10.FormUtils]), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.options.actionItemClass; var currVal_3 = _v.context.$implicit.value; var currVal_4 = _co.context; var currVal_5 = _co.contextElement; var currVal_6 = ("Action " + (_v.context.index + 1)); var currVal_7 = true; var currVal_8 = true; var currVal_9 = _co.pin; var currVal_10 = _v.context.$implicit.value["pinned"]; var currVal_11 = (i0.ɵunv(_v, 7, 9, i0.ɵnov(_v, 8).transform(_co.form.form, _v.context.index)).length ? "Not configured" : undefined); var currVal_12 = _co.classes; _ck(_v, 7, 1, [currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12]); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).disabled; var currVal_1 = i0.ɵnov(_v, 2)._dragRef.isDragging(); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_ActionsEditComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [["class", "sidebar-list-item__drag"]], null, null, null, null, null))], null, null); }
function View_ActionsEditComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [["class", "icon button__icon"]], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i2.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpad(2, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "icon button__icon"; var currVal_1 = _ck(_v, 2, 0, ("icon-" + _co.emptyIcon)); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_ActionsEditComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "sidebar-list-item__left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "span", [["class", "button button_icon button_orange button_extra-small"], ["style", "margin: 0;"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ActionsEditComponent_5)), i0.ɵdid(3, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.emptyIcon; _ck(_v, 3, 0, currVal_0); }, null); }
function View_ActionsEditComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "sidebar-list-item__description"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.emptyActionValue; _ck(_v, 1, 0, currVal_0); }); }
function View_ActionsEditComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [["class", "sidebar-list__item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 10, "div", [["class", "sidebar-list-item sidebar-list-item_clickable sidebar-list-item_disabled"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addItem() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 278528, null, 0, i2.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ActionsEditComponent_3)), i0.ɵdid(4, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ActionsEditComponent_4)), i0.ɵdid(6, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(7, 0, null, null, 4, "div", [["class", "sidebar-list-item__main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 1, "div", [["class", "sidebar-list-item__title"]], null, null, null, null, null)), (_l()(), i0.ɵted(9, null, ["", ""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ActionsEditComponent_6)), i0.ɵdid(11, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "sidebar-list-item sidebar-list-item_clickable sidebar-list-item_disabled"; var currVal_1 = _co.classes; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = true; _ck(_v, 4, 0, currVal_2); var currVal_3 = _co.emptyIcon; _ck(_v, 6, 0, currVal_3); var currVal_5 = _co.emptyActionValue; _ck(_v, 11, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.emptyAction; _ck(_v, 9, 0, currVal_4); }); }
export function View_ActionsEditComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 13, "div", [["cdkDropList", ""], ["class", "sidebar-list cdk-drop-list"]], [[8, "id", 0], [2, "cdk-drop-list-disabled", null], [2, "cdk-drop-list-dragging", null], [2, "cdk-drop-list-receiving", null]], [[null, "cdkDropListDropped"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("cdkDropListDropped" === en)) {
        var pd_0 = (_co.dragDrop($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(6144, null, i1.CDK_DROP_LIST, null, [i1.CdkDropList]), i0.ɵdid(2, 1196032, null, 1, i1.CdkDropList, [i0.ElementRef, i1.DragDropRegistry, i0.ChangeDetectorRef, [2, i4.Directionality], [3, i1.CdkDropListGroup], [2, i2.DOCUMENT], i1.DragDrop], { lockAxis: [0, "lockAxis"] }, { dropped: "cdkDropListDropped" }), i0.ɵqud(603979776, 1, { _draggables: 1 }), i0.ɵprd(256, null, i1.CdkDropListGroup, undefined, []), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ActionsEditComponent_1)), i0.ɵdid(6, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ActionsEditComponent_2)), i0.ɵdid(8, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(9, 0, null, null, 4, "div", [["class", "sidebar-list__buttons"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 3, "a", [["class", "button button_orange-transparent button_small sidebar-list__buttons-item"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addItem() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 0, "span", [["class", "icon icon-plus button__icon button__icon_left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 1, "span", [["class", "button__label"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Add Action"]))], function (_ck, _v) { var _co = _v.component; var currVal_4 = "y"; _ck(_v, 2, 0, currVal_4); var currVal_5 = _co.form.form.controls; _ck(_v, 6, 0, currVal_5); var currVal_6 = (!_co.form.form.controls.length && _co.emptyAction); _ck(_v, 8, 0, currVal_6); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).id; var currVal_1 = i0.ɵnov(_v, 2).disabled; var currVal_2 = i0.ɵnov(_v, 2)._dropListRef.isDragging(); var currVal_3 = i0.ɵnov(_v, 2)._dropListRef.isReceiving(); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
export function View_ActionsEditComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-actions-edit", [], null, null, null, View_ActionsEditComponent_0, RenderType_ActionsEditComponent)), i0.ɵprd(512, null, i11.ActionsEditForm, i11.ActionsEditForm, [i12.ElementConfigurationService]), i0.ɵdid(2, 245760, null, 0, i13.ActionsEditComponent, [i11.ActionsEditForm, i14.CustomizeBarService, i15.CustomizeBarContext, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var ActionsEditComponentNgFactory = i0.ɵccf("app-actions-edit", i13.ActionsEditComponent, View_ActionsEditComponent_Host_0, { options: "options", control: "control", pin: "pin", context: "context", contextElement: "contextElement", contextElementPath: "contextElementPath", contextElementPaths: "contextElementPaths", contextPass: "contextPass", emptyAction: "emptyAction", emptyActionValue: "emptyActionValue", emptyIcon: "emptyIcon", actionLabel: "actionLabel", object: "object", classes: "classes", firstInit: "firstInit" }, {}, []);
export { ActionsEditComponentNgFactory as ActionsEditComponentNgFactory };
