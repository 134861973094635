<div *ngIf="control" [formGroup]="control" class="margin-control">
  <div class="margin-control__element">
    <div
      class="margin-control__element-border"
      [class.margin-control__element-border_highlight_top]="focused == sides.Top"
      [class.margin-control__element-border_highlight_right]="focused == sides.Right"
      [class.margin-control__element-border_highlight_bottom]="focused == sides.Bottom"
      [class.margin-control__element-border_highlight_left]="focused == sides.Left"
      [class.margin-control__element-border_active_top]="control.controls.top.value | appIsSet"
      [class.margin-control__element-border_active_right]="control.controls.right.value | appIsSet"
      [class.margin-control__element-border_active_bottom]="control.controls.bottom.value | appIsSet"
      [class.margin-control__element-border_active_left]="control.controls.left.value | appIsSet"
      [style.border-top-width.px]="control.controls.top.value | appMarginControlBorderWidth"
      [style.border-right-width.px]="control.controls.right.value | appMarginControlBorderWidth"
      [style.border-bottom-width.px]="control.controls.bottom.value | appMarginControlBorderWidth"
      [style.border-left-width.px]="control.controls.left.value | appMarginControlBorderWidth"
    ></div>
    <div class="margin-control-input margin-control__input margin-control__input_position_left">
      <input
        formControlName="left"
        type="text"
        class="margin-control-input__input"
        placeholder="auto"
        [appAutofocus]="focus"
        (focus)="setFocused(sides.Left); selectValue($event)"
        (blur)="setFocused(undefined)"
        (keydown)="processArrows($event, control.controls.left, left_input)"
        (keypress)="validateKey($event)"
        #left_input
      />
      <div class="margin-control-input__right">px</div>
    </div>
    <div class="margin-control-input margin-control__input margin-control__input_position_top">
      <input
        formControlName="top"
        type="text"
        class="margin-control-input__input"
        placeholder="auto"
        (focus)="setFocused(sides.Top); selectValue($event)"
        (blur)="setFocused(undefined)"
        (keydown)="processArrows($event, control.controls.top, top_input)"
        (keypress)="validateKey($event)"
        #top_input
      />
      <div class="margin-control-input__right">px</div>
    </div>
    <div class="margin-control-input margin-control__input margin-control__input_position_right">
      <input
        formControlName="right"
        type="text"
        class="margin-control-input__input"
        placeholder="auto"
        (focus)="setFocused(sides.Right); selectValue($event)"
        (blur)="setFocused(undefined)"
        (keydown)="processArrows($event, control.controls.right, right_input)"
        (keypress)="validateKey($event)"
        #right_input
      />
      <div class="margin-control-input__right">px</div>
    </div>
    <div class="margin-control-input margin-control__input margin-control__input_position_bottom">
      <input
        formControlName="bottom"
        type="text"
        class="margin-control-input__input"
        placeholder="auto"
        (focus)="setFocused(sides.Bottom); selectValue($event)"
        (blur)="setFocused(undefined)"
        (keydown)="processArrows($event, control.controls.bottom, bottom_input)"
        (keypress)="validateKey($event)"
        #bottom_input
      />
      <div class="margin-control-input__right">px</div>
    </div>
  </div>
</div>
