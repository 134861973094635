import { Optional, Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ThemeService } from '@modules/theme';

import { ThemeContext } from '../../data/theme-context';
import { ThemeOptions } from '../../data/theme-options';

@Pipe({
  name: 'appThemeColorHexOption'
})
export class ThemeColorHexOptionPipe implements PipeTransform {
  constructor(@Optional() private themeContext: ThemeContext, @Optional() private themeService: ThemeService) {}

  transform(value: keyof ThemeOptions, applyTheme = false): Observable<any> {
    if (!this.themeContext || !this.themeService) {
      return of(undefined);
    }

    if (applyTheme) {
      return this.themeService.isDarkTheme$.pipe(
        switchMap(dark => {
          if (dark) {
            return this.themeContext.getColorHex$(`${value}Dark` as keyof ThemeOptions);
          } else {
            return this.themeContext.getColorHex$(value);
          }
        })
      );
    } else {
      return this.themeContext.getColorHex$(value);
    }
  }
}
