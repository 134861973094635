import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import { AfterViewInit, ChangeDetectorRef, ElementRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import round from 'lodash/round';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fromEvent } from 'rxjs';
import { filter } from 'rxjs/operators';
import { controlValue, isSet, MouseButton, pointsDistance } from '@shared';
import { GradientStopControl } from '../../forms/gradient-stop.control';
var GradientPositionPointComponent = /** @class */ (function () {
    function GradientPositionPointComponent(el, parentCd) {
        this.el = el;
        this.parentCd = parentCd;
        this.active = false;
        this.snap = false;
        this.positionChange = new EventEmitter();
        this.cls = true;
        this.moveThreshold = 5;
    }
    Object.defineProperty(GradientPositionPointComponent.prototype, "activeCls", {
        get: function () {
            return this.active;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GradientPositionPointComponent.prototype, "colorCls", {
        get: function () {
            return !!this.control;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GradientPositionPointComponent.prototype, "left", {
        get: function () {
            if (isSet(this.positionPx)) {
                return this.positionPx + "px";
            }
            else if (isSet(this.positionEm)) {
                return this.positionEm + "em";
            }
            else if (isSet(this.positionPercent)) {
                return this.positionPercent * 100 + "%";
            }
            else {
                return null;
            }
        },
        enumerable: true,
        configurable: true
    });
    GradientPositionPointComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.control) {
            controlValue(this.control.controls.color)
                .pipe(untilDestroyed(this))
                .subscribe(function (background) {
                _this.backgroundColor = background;
                _this.parentCd.markForCheck();
            });
        }
    };
    GradientPositionPointComponent.prototype.ngOnDestroy = function () { };
    GradientPositionPointComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        fromEvent(this.el.nativeElement, 'mousedown')
            .pipe(filter(function (e) { return e.button == MouseButton.Main; }), untilDestroyed(this))
            .subscribe(function (downEvent) {
            downEvent.preventDefault();
            var subscriptions = [];
            var thresholdPassed = false;
            subscriptions.push(fromEvent(document, 'mousemove')
                .pipe(untilDestroyed(_this))
                .subscribe(function (moveEvent) {
                moveEvent.preventDefault();
                if (!thresholdPassed) {
                    var originDistance = pointsDistance(downEvent.clientX, downEvent.clientY, moveEvent.clientX, moveEvent.clientY);
                    if (originDistance >= _this.moveThreshold) {
                        thresholdPassed = true;
                        if (_this.snap) {
                            // this.editorContext.removeHorizontalGuides();
                            // this.editorContext.removeVerticalGuides();
                        }
                    }
                }
                if (thresholdPassed) {
                    var bounds = _this.origin.elementRef.nativeElement.getBoundingClientRect();
                    var positionX = round((moveEvent.clientX - bounds.left) / bounds.width, 2);
                    var positionY = round((moveEvent.clientY - bounds.top) / bounds.height, 2);
                    // if (this.snap) {
                    //   // const scale = this.editorContext.viewportScale$.value;
                    //   const scale = 1;
                    //   const deltaX = (moveEvent.clientX - bounds.left) / scale;
                    //   const deltaY = (moveEvent.clientY - bounds.top) / scale;
                    //   const x = this.frame.x + deltaX;
                    //   const y = this.frame.y + deltaY;
                    //   const snap = snapPoint({
                    //     point: new PointTranslate({
                    //       point: { x, y },
                    //       translate: this.translate
                    //     }),
                    //     otherFrames: [
                    //       new FrameTranslate({
                    //         frame: this.frame,
                    //         translate: this.translate
                    //       })
                    //     ]
                    //   });
                    //
                    //   if (isSet(snap.updatePoint.x)) {
                    //     positionX = round((snap.updatePoint.x - this.frame.x) / this.frame.width, 2);
                    //   }
                    //
                    //   if (isSet(snap.updatePoint.y)) {
                    //     positionY = round((snap.updatePoint.y - this.frame.y) / this.frame.height, 2);
                    //   }
                    //
                    //   this.editorContext.setHorizontalGuides(snap.horizontalGuides);
                    //   this.editorContext.setVerticalGuides(snap.verticalGuides);
                    // }
                    _this.positionChange.emit({ x: positionX, y: positionY });
                }
            }));
            subscriptions.push(fromEvent(document, 'mouseup')
                .pipe(filter(function (e) { return e.button == MouseButton.Main; }), untilDestroyed(_this))
                .subscribe(function (upEvent) {
                upEvent.preventDefault();
                subscriptions.forEach(function (item) { return item.unsubscribe(); });
                if (_this.snap) {
                    // this.editorContext.removeHorizontalGuides();
                    // this.editorContext.removeVerticalGuides();
                }
            }));
        });
    };
    return GradientPositionPointComponent;
}());
export { GradientPositionPointComponent };
