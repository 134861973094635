<div
  class="popup2"
  [class.popup2_side]="side"
  [class.popup2_theme]="theme"
  [ngClass]="'popup2_size_' + size"
  [style.max-width.px]="maxWidth"
>
  <ng-content></ng-content>

  <div class="popup2__footer">
    <ng-content select="app-popup2-footer"></ng-content>
  </div>
</div>
