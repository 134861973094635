/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../modules/colors-components/components/color-picker/color-picker.component.ngfactory";
import * as i2 from "../../../../modules/colors-components/components/color-picker/color-picker.component";
import * as i3 from "../../../../modules/colors/services/pick-color/pick-color.service";
import * as i4 from "@angular/common";
import * as i5 from "./text-editor-color-picker.component";
import * as i6 from "../../../../modules/theme/services/theme/theme.service";
var styles_TextEditorColorPickerComponent = [];
var RenderType_TextEditorColorPickerComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TextEditorColorPickerComponent, data: {} });
export { RenderType_TextEditorColorPickerComponent as RenderType_TextEditorColorPickerComponent };
function View_TextEditorColorPickerComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-color-picker", [], null, null, null, i1.View_ColorPickerComponent_0, i1.RenderType_ColorPickerComponent)), i0.ɵdid(1, 245760, null, 0, i2.ColorPickerComponent, [i0.ElementRef, i3.PickColorService, i0.ChangeDetectorRef], { hexControl: [0, "hexControl"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.colorDefaultControl; _ck(_v, 1, 0, currVal_0); }, null); }
function View_TextEditorColorPickerComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-color-picker", [], null, null, null, i1.View_ColorPickerComponent_0, i1.RenderType_ColorPickerComponent)), i0.ɵdid(1, 245760, null, 0, i2.ColorPickerComponent, [i0.ElementRef, i3.PickColorService, i0.ChangeDetectorRef], { hexControl: [0, "hexControl"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.colorDarkControl; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_TextEditorColorPickerComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 18, "div", [["class", "popover2 overlay-content overlay-content_animated"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 17, "div", [["class", "popover2__inner popover2__inner_no-margin-collapse"]], [[4, "width", "px"]], null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 12, "div", [["class", "sidebar__element sidebar__element_margin_s"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 11, "div", [["class", "sidebar-cols"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 5, "div", [["class", "sidebar-cols__item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 4, "div", [["class", "button-group"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "div", [["class", "button-group__item"]], [[2, "button-group__item_active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.themeService.theme = "default") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 0, "span", [["class", "button-group__item-icon icon-sun"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 1, "div", [["class", "button-group__item"]], [[2, "button-group__item_active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.themeService.theme = "dark") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 0, "span", [["class", "button-group__item-icon icon-moon"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 4, "div", [["class", "sidebar-cols__item sidebar-cols__item_fit"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 3, "button", [["class", "button button_extra-small button_no-margin"], ["type", "submit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.resetCurrentColor() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 0, "span", [["class", "button__icon button__icon_left icon-close"]], null, null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 1, "span", [["class", "button__label"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Reset"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TextEditorColorPickerComponent_1)), i0.ɵdid(16, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TextEditorColorPickerComponent_2)), i0.ɵdid(18, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.themeService.isDefaultTheme; _ck(_v, 16, 0, currVal_3); var currVal_4 = _co.themeService.isDarkTheme; _ck(_v, 18, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = 295; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.themeService.isDefaultTheme; _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.themeService.isDarkTheme; _ck(_v, 8, 0, currVal_2); }); }
export function View_TextEditorColorPickerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-text-editor-color-picker", [], null, null, null, View_TextEditorColorPickerComponent_0, RenderType_TextEditorColorPickerComponent)), i0.ɵdid(1, 245760, null, 0, i5.TextEditorColorPickerComponent, [i6.ThemeService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TextEditorColorPickerComponentNgFactory = i0.ɵccf("app-text-editor-color-picker", i5.TextEditorColorPickerComponent, View_TextEditorColorPickerComponent_Host_0, { value: "value" }, { valueChange: "valueChange" }, []);
export { TextEditorColorPickerComponentNgFactory as TextEditorColorPickerComponentNgFactory };
