import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import { Observable } from 'rxjs';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';

import { AppConfigService } from '@core';
import { ApiService } from '@modules/api';

import { ViewSettings, ViewSettingsType } from '../../data/view-settings/base';
import { ChangeViewSettings } from '../../data/view-settings/change';
import { CustomViewSettings } from '../../data/view-settings/custom';
import { ListViewSettings } from '../../data/view-settings/list';
import { validateElementNames } from '../../utils/traverse';

@Injectable({
  providedIn: 'root'
})
export class ViewSettingsService {
  constructor(private http: HttpClient, private apiService: ApiService, private appConfigService: AppConfigService) {}

  mapItem(item: Object): ViewSettings {
    if (item['view'] == ViewSettingsType.Custom) {
      const result = new CustomViewSettings().deserialize(item);
      validateElementNames(result.elements);
      return result;
    } else if (item['view'] == ViewSettingsType.List) {
      return new ListViewSettings().deserialize(item);
    } else if (item['view'] == ViewSettingsType.Change) {
      const result = new ChangeViewSettings().deserialize(item);
      validateElementNames(result.elements);
      return result;
    } else {
      return new ViewSettings().deserialize(item);
    }
  }

  get(projectName: string, environmentName: string, draft = false): Observable<ViewSettings[]> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.environmentMethodURL(projectName, environmentName, 'view_settings/');
        let headers = new HttpHeaders();
        const params = {
          project: projectName,
          ...(draft && { draft: '1' }),
          v: this.appConfigService.version
        };

        headers = this.apiService.setHeadersToken(headers);

        return this.http.get<Object[]>(url, { headers: headers, params: params });
      }),
      map(viewSettings => {
        // Backward compatibility
        if (!window['legacyModels']) {
          const remove = [];

          viewSettings.forEach(item => {
            const parts = item['model'].split(';', 2);

            if (parts.length != 2) {
              return;
            }

            if (!viewSettings.find(newItem => newItem['model'] == `${parts[0]}_${parts[1]}`)) {
              return;
            }

            remove.push(item['model']);
          });

          viewSettings = viewSettings.filter(item => !remove.includes(item['model']));
        }

        return viewSettings;
      }),
      map(result => {
        return result.map(item => this.mapItem(item));
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  createBulk(
    projectName: string,
    environmentName: string,
    instances: ViewSettings[],
    fields?: string[]
  ): Observable<ViewSettings[]> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.environmentMethodURL(projectName, environmentName, 'view_settings/');
        let headers = new HttpHeaders();
        const params = {
          draft: '1',
          v: this.appConfigService.version
        };
        const data = instances.map(item => item.serialize(fields));

        headers = this.apiService.setHeadersToken(headers);

        return this.http.post<Object[]>(url, data, { headers: headers, params: params });
      }),
      map(result => {
        return result.map(item => this.mapItem(item));
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  create(
    projectName: string,
    environmentName: string,
    instance: ViewSettings,
    fields?: string[]
  ): Observable<ViewSettings> {
    return this.createBulk(projectName, environmentName, [instance], fields).pipe(map(result => result[0]));
    // return this.apiService.refreshToken().pipe(
    //   switchMap(() => {
    //     const url = this.apiService.environmentMethodURL(projectName, environmentName, 'view_settings/');
    //     let headers = new HttpHeaders();
    //     const params = {
    //       view: instance.view
    //     };
    //
    //     if (instance.uniqueName) {
    //       params['unique_name'] = instance.uniqueName;
    //     }
    //
    //     if (instance.resource) {
    //       params['resource'] = instance.resource;
    //     }
    //
    //     if (instance.model) {
    //       params['model'] = instance.model;
    //     }
    //
    //     const httpParams = new HttpParams({
    //       fromObject: params,
    //       encoder: this.encoder,
    //       v: this.appConfigService.version
    //     });
    //
    //     headers = this.apiService.setHeadersToken(headers);
    //
    //     return this.http.post(url, instance.serialize(fields), { headers: headers, params: httpParams });
    //   }),
    //   map(result => new ViewSettings().deserialize(result)),
    //   this.apiService.catchApiError(),
    //   publishLast(),
    //   refCount()
    // );
  }

  update(
    projectName: string,
    environmentName: string,
    instance: ViewSettings,
    fields?: string[]
  ): Observable<ViewSettings> {
    return this.createBulk(projectName, environmentName, [instance], fields).pipe(map(result => result[0]));
    // return this.apiService.refreshToken().pipe(
    //   switchMap(() => {
    //     const url = this.apiService.projectMethodURL(projectName, `view_settings/${instance.uid}/`);
    //     const httpParams = { v: this.appConfigService.version };
    //     let headers = new HttpHeaders();
    //
    //     headers = this.apiService.setHeadersToken(headers);
    //
    //     return this.http.patch(url, instance.serialize(fields), { headers: headers, params: httpParams });
    //   }),
    //   map(result => new ViewSettings().deserialize(result)),
    //   this.apiService.catchApiError(),
    //   publishLast(),
    //   refCount()
    // );
  }

  delete(projectName: string, environmentName: string, instance: ViewSettings): Observable<boolean> {
    instance = cloneDeep(instance);
    instance.deleted = true;
    return this.createBulk(projectName, environmentName, [instance]).pipe(map(() => true));
    // return this.apiService.refreshToken().pipe(
    //   switchMap(() => {
    //     const url = this.apiService.projectMethodURL(projectName, `view_settings/${instance.uid}/`);
    //     const httpParams = { v: this.appConfigService.version };
    //     let headers = new HttpHeaders();
    //
    //     headers = this.apiService.setHeadersToken(headers);
    //
    //     return this.http.delete(url, { headers: headers, params: httpParams });
    //   }),
    //   map(() => true),
    //   this.apiService.catchApiError(),
    //   publishLast(),
    //   refCount()
    // );
  }
}
