var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, Injector, OnDestroy, OnInit } from '@angular/core';
import range from 'lodash/range';
import * as moment from 'moment';
import { NotificationService } from '@common/notifications';
import { ActionControllerService } from '@modules/action-queries';
import { TintStyle } from '@modules/actions';
import { gteFieldLookup, lteFieldLookup } from '@modules/field-lookups';
import { FilterItem2 } from '@modules/filters';
import { ColumnsModelListStore } from '@modules/list';
import { DATE_PARAM, TYPE_PARAM } from '@modules/models';
import { paramsToGetQueryOptions } from '@modules/resources';
import { CalendarType } from '../../data/calendar-type';
import { CalendarBaseComponent } from '../calendar-base/calendar-base.component';
var CalendarMonthComponent = /** @class */ (function (_super) {
    __extends(CalendarMonthComponent, _super);
    function CalendarMonthComponent(injector, actionControllerService, listStore, notificationService, cd) {
        var _this = _super.call(this, injector, actionControllerService, listStore, notificationService, cd) || this;
        _this.weekDays = [];
        _this.weeks = [];
        _this.tintStyles = TintStyle;
        return _this;
    }
    CalendarMonthComponent.prototype.fetch = function (state) {
        var queryOptions = paramsToGetQueryOptions(state.dataSourceParams);
        var dateFilters = this.dateFilterFilters(state);
        queryOptions.filters = (queryOptions.filters ? queryOptions.filters : []).concat((state.filters ? state.filters : []), (dateFilters ? dateFilters : []));
        queryOptions.search = state.search;
        queryOptions.sort = state.sort;
        this.listStore.dataSource = state.dataSource;
        this.listStore.useDataSourceColumns = true;
        this.listStore.staticData = state.dataSourceStaticData;
        this.listStore.queryOptions = queryOptions;
        this.listStore.context = this.context;
        this.listStore.contextElement = this.contextElement;
        this.getItems();
        this.updateVisibleColumns(state);
        this.updateWeeks(state);
        this.prevDate = state.date;
    };
    Object.defineProperty(CalendarMonthComponent.prototype, "now", {
        get: function () {
            return moment();
        },
        enumerable: true,
        configurable: true
    });
    CalendarMonthComponent.prototype.updateWeeks = function (state) {
        var _this = this;
        if (!state.date) {
            this.weekDays = [];
            this.weeks = [];
            this.cd.markForCheck();
            return;
        }
        var month = state.date.clone().startOf('month');
        var firstDay = month.clone().startOf('isoWeek');
        var lastDay = month.clone().endOf('month').endOf('isoWeek');
        var weeks = Math.ceil(lastDay.diff(firstDay, 'weeks', true));
        this.weekDays = range(0, 7).map(function (day) {
            var date = firstDay.clone().add(day, 'days');
            return {
                date: date,
                weekend: [6, 0].includes(date.day()),
                today: date.isSame(_this.now, 'day') && date.isSame(_this.now, 'month') && date.isSame(_this.now, 'year')
            };
        });
        this.weeks = range(0, weeks).map(function (week) {
            return range(0, 7).map(function (day) {
                var date = firstDay.clone().add(week, 'weeks').add(day, 'days');
                return {
                    date: date,
                    today: date.isSame(_this.now, 'day') && date.isSame(_this.now, 'month') && date.isSame(_this.now, 'year'),
                    currentMonth: date.isSame(month, 'month') && date.isSame(month, 'year'),
                    weekend: [6, 0].includes(date.day()),
                    future: date.isAfter(_this.now),
                    key: date.format(_this.itemsGroupBy())
                };
            });
        });
        this.cd.markForCheck();
    };
    CalendarMonthComponent.prototype.dayParams = function (date) {
        var params = {};
        params[TYPE_PARAM] = CalendarType.Day;
        params[DATE_PARAM] = date.toISOString();
        return this.applyParams(params);
    };
    CalendarMonthComponent.prototype.dateFilterFilters = function (state) {
        var firstDay = state.date.clone().startOf('month').startOf('isoWeek');
        var lastDay = state.date.clone().endOf('month').endOf('isoWeek');
        return [
            new FilterItem2({
                field: [state.dateField],
                lookup: gteFieldLookup,
                value: firstDay.toISOString()
            }),
            new FilterItem2({
                field: [state.dateField],
                lookup: lteFieldLookup,
                value: lastDay.toISOString()
            })
        ];
    };
    CalendarMonthComponent.prototype.itemsGroupBy = function () {
        return 'DD.MM.YYYY';
    };
    return CalendarMonthComponent;
}(CalendarBaseComponent));
export { CalendarMonthComponent };
