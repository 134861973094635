var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormControl, FormGroup } from '@angular/forms';
import { Input } from '@modules/fields';
import { FieldInputControl } from '@modules/parameters';
import { defaultIconFillIcon, IconFill } from '@modules/views';
import { isSet } from '@shared';
import { ColorControl } from './color.control';
var IconFillControl = /** @class */ (function (_super) {
    __extends(IconFillControl, _super);
    function IconFillControl(state) {
        if (state === void 0) { state = {}; }
        return _super.call(this, {
            icon: new FormControl(isSet(state.icon) ? state.icon : defaultIconFillIcon),
            icon_input: new FieldInputControl({ path: ['value'] }),
            icon_input_enabled: new FormControl(isSet(state.iconInput) ? !!state.iconInput : false),
            color: new ColorControl(isSet(state.color) ? state.color : {}),
            color_input_enabled: new FormControl(isSet(state.colorInput) ? !!state.colorInput : false),
            color_input: new FieldInputControl({ path: ['value'] }),
            size: new FormControl(isSet(state.size) ? state.size : undefined)
        }) || this;
    }
    IconFillControl.prototype.deserialize = function (value, options) {
        if (options === void 0) { options = {}; }
        this.instance = value;
        this.controls.icon.patchValue(value.icon || defaultIconFillIcon, { emitEvent: options.emitEvent });
        this.controls.icon_input.patchValue(value.iconInput ? value.iconInput.serializeWithoutPath() : {}, {
            emitEvent: options.emitEvent
        });
        this.controls.icon_input_enabled.patchValue(!!value.iconInput, { emitEvent: options.emitEvent });
        if (value.color) {
            this.controls.color.deserialize(value.color);
        }
        this.controls.color_input_enabled.patchValue(!!value.colorInput, { emitEvent: options.emitEvent });
        this.controls.color_input.patchValue(value.colorInput ? value.colorInput.serializeWithoutPath() : {}, {
            emitEvent: options.emitEvent
        });
        this.controls.size.patchValue(value.size, { emitEvent: options.emitEvent });
    };
    IconFillControl.prototype.getInstance = function (instance) {
        if (!instance) {
            instance = new IconFill();
        }
        if (this.controls.icon_input_enabled.value) {
            instance.icon = this.controls.icon.value;
            instance.iconInput = this.controls.icon_input.value
                ? new Input().deserialize(this.controls.icon_input.value)
                : undefined;
        }
        else {
            instance.icon = this.controls.icon.value;
            instance.iconInput = undefined;
        }
        if (this.controls.color_input_enabled.value) {
            instance.color = this.controls.color.getInstance(instance.color);
            instance.colorInput = this.controls.color_input.value
                ? new Input().deserialize(this.controls.color_input.value)
                : undefined;
        }
        else {
            instance.color = this.controls.color.getInstance(instance.color);
            instance.colorInput = undefined;
        }
        instance.size = this.controls.size.value;
        return instance;
    };
    IconFillControl.prototype.serialize = function () {
        return this.getInstance(this.instance);
    };
    return IconFillControl;
}(FormGroup));
export { IconFillControl };
