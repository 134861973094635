import { AbstractControlOptions, FormArray, FormControl } from '@angular/forms';
import isArray from 'lodash/isArray';
import range from 'lodash/range';

export class ValueArray extends FormArray {
  controls: FormControl[];

  constructor(private childOpts?: AbstractControlOptions) {
    super([]);
  }

  pushNew(): FormControl {
    const control = new FormControl(undefined, this.childOpts);
    this.push(control);
    return control;
  }

  removeLast() {
    this.removeAt(this.controls.length - 1);
  }

  prepareControls(value: any[]) {
    const addControls = value.length > this.controls.length ? value.length - this.controls.length : 0;
    const removeControls = value.length < this.controls.length ? this.controls.length - value.length : 0;
    range(addControls).forEach(() => this.pushNew());
    range(removeControls).forEach(() => this.removeLast());
  }

  cleanValue(value: any): any[] {
    if (!isArray(value)) {
      value = [value];
    }

    return value;
  }

  patchValue(value: any, options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    value = this.cleanValue(value);

    this.prepareControls(value);
    super.patchValue(value, options);
  }

  setValue(value: any, options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    value = this.cleanValue(value);

    this.prepareControls(value);
    super.setValue(value, options);
  }

  get value(): any[] {
    return this.controls.map(item => item.value);
  }

  set value(value: any[]) {}
}
