var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import keys from 'lodash/keys';
import pickBy from 'lodash/pickBy';
import { isSet } from '@shared';
import { TextStyle } from './text-style';
var ListElementStyles = /** @class */ (function () {
    function ListElementStyles(options) {
        if (options === void 0) { options = {}; }
        Object.assign(this, options);
    }
    ListElementStyles.prototype.deserialize = function (data) {
        if (data['title_style']) {
            this.titleStyle = new TextStyle().deserialize(data['title_style']);
        }
        else {
            this.titleStyle = undefined;
        }
        if (data['margin']) {
            this.margin = data['margin'];
        }
        else {
            this.margin = undefined;
        }
        return this;
    };
    ListElementStyles.prototype.serialize = function () {
        return {
            title_style: this.titleStyle ? this.titleStyle.serialize() : undefined,
            margin: this.margin
        };
    };
    ListElementStyles.prototype.apply = function (other) {
        var _this = this;
        var properties = ['titleStyle', 'margin'];
        properties.forEach(function (property) {
            if (isSet(other[property])) {
                _this[property] = other[property];
            }
        });
        return this;
    };
    return ListElementStyles;
}());
export { ListElementStyles };
export function getListElementStyles(element, layout) {
    var options = pickBy(__assign({ titleStyle: layout.titleStyle }, (keys(element.margin).length && {
        margin: element.margin
    })), function (v) { return isSet(v); });
    if (!keys(options).length) {
        return;
    }
    return new ListElementStyles(options);
}
export function applyListElementStyles(element, layout, styles) {
    if (!styles) {
        styles = new ListElementStyles();
    }
    layout.titleStyle = styles.titleStyle;
    element.margin = styles.margin;
}
