<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayPanelClass]="['overlay', 'overlay_horizontal_center']"
  [cdkConnectedOverlayPositions]="popoverPositions"
  [cdkConnectedOverlayOrigin]="origin"
  [cdkConnectedOverlayOpen]="dropdownOpened"
  [cdkConnectedOverlayPush]="true"
  [cdkConnectedOverlayGrowAfterOpen]="true"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayBackdropClass]="'popover2-backdrop'"
  (backdropClick)="setDropdownOpened(false)"
  (detach)="setDropdownOpened(false)"
>
  <div class="popover2 overlay-content overlay-content_animated">
    <div class="popover2__inner">
      <div class="popover2__viewport popover2__viewport_scrollable">
        <div class="popover2__content" style="min-width: 300px;">
          <app-select-data-source-field
            [dataSource]="dataSource"
            [searchPlaceholder]="'Filter by...'"
            [nestedFieldsEnabled]="nestedFieldsEnabled"
            [relationsEnabled]="relationsEnabled"
            [relationsSelectEnabled]="false"
            [lookupsSelect]="true"
            [parameterSelect]="true"
            [optionsFilter]="optionsFilter"
            (selected)="added.emit($event); close()"
          ></app-select-data-source-field>
        </div>
      </div>
    </div>
  </div>
</ng-template>
