var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormControl, FormGroup } from '@angular/forms';
import { map } from 'rxjs/operators';
import { controlValue, isSet } from '@shared';
import { BorderSettings } from './border-settings';
import { BorderControl } from './border.control';
var BorderSettingsControl = /** @class */ (function (_super) {
    __extends(BorderSettingsControl, _super);
    function BorderSettingsControl(state) {
        if (state === void 0) { state = {}; }
        var _this = _super.call(this, {
            per_sides: new FormControl([state.borderTop, state.borderRight, state.borderBottom, state.borderLeft].some(function (item) { return isSet(item); })),
            border: new BorderControl(isSet(state.border) ? state.border : undefined),
            border_top: new BorderControl(isSet(state.borderTop) ? state.borderTop : undefined),
            border_right: new BorderControl(isSet(state.borderRight) ? state.borderRight : undefined),
            border_bottom: new BorderControl(isSet(state.borderBottom) ? state.borderBottom : undefined),
            border_left: new BorderControl(isSet(state.borderLeft) ? state.borderLeft : undefined)
        }) || this;
        _this.borderControls = [
            _this.controls.border,
            _this.controls.border_top,
            _this.controls.border_right,
            _this.controls.border_bottom,
            _this.controls.border_left
        ];
        return _this;
    }
    BorderSettingsControl.prototype.deserialize = function (value, options) {
        if (options === void 0) { options = {}; }
        this.instance = value;
        this.controls.per_sides.patchValue(value ? value.isSidesSet() : false, { emitEvent: options.emitEvent });
        this.controls.border.deserialize(value ? value.border : undefined, { emitEvent: options.emitEvent });
        this.controls.border_top.deserialize(value ? value.borderTop : undefined, { emitEvent: options.emitEvent });
        this.controls.border_right.deserialize(value ? value.borderRight : undefined, { emitEvent: options.emitEvent });
        this.controls.border_bottom.deserialize(value ? value.borderBottom : undefined, { emitEvent: options.emitEvent });
        this.controls.border_left.deserialize(value ? value.borderLeft : undefined, { emitEvent: options.emitEvent });
    };
    BorderSettingsControl.prototype.isSet = function () {
        return this.borderControls.some(function (item) { return item.isSet(); });
    };
    BorderSettingsControl.prototype.isSet$ = function () {
        var _this = this;
        return controlValue(this).pipe(map(function () { return _this.isSet(); }));
    };
    BorderSettingsControl.prototype.resetDefaults = function (options) {
        this.borderControls.forEach(function (item) {
            item.resetDefaults(options);
        });
    };
    BorderSettingsControl.prototype.reset = function (options) {
        this.borderControls.forEach(function (item) {
            item.reset(options);
        });
    };
    BorderSettingsControl.prototype.getInstance = function (instance) {
        if (!instance) {
            instance = new BorderSettings();
        }
        if (this.controls.per_sides.value) {
            instance.border = undefined;
            instance.borderTop = this.controls.border_top.getInstance(instance.borderTop);
            instance.borderRight = this.controls.border_right.getInstance(instance.borderRight);
            instance.borderBottom = this.controls.border_bottom.getInstance(instance.borderBottom);
            instance.borderLeft = this.controls.border_left.getInstance(instance.borderLeft);
        }
        else {
            instance.border = this.controls.border.getInstance(instance.border);
            instance.borderTop = undefined;
            instance.borderRight = undefined;
            instance.borderBottom = undefined;
            instance.borderLeft = undefined;
        }
        return instance;
    };
    BorderSettingsControl.prototype.serialize = function (reuseInstance) {
        if (reuseInstance === void 0) { reuseInstance = true; }
        if (!this.isSet()) {
            return;
        }
        return this.getInstance(reuseInstance ? this.instance : undefined);
    };
    BorderSettingsControl.prototype.serialize$ = function () {
        var _this = this;
        return controlValue(this, { debounce: 200 }).pipe(map(function () { return _this.serialize(); }));
    };
    return BorderSettingsControl;
}(FormGroup));
export { BorderSettingsControl };
