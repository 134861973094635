<app-project-group-dropdown
  [control]="control"
  [uidValue]="uidValue"
  [origin]="dropdownTrigger"
  [allowNull]="allowNull"
  [environmentName]="environmentName"
  #dropdown="appProjectGroupDropdown"
></app-project-group-dropdown>

<div class="select select_theme_jet" [class.select_fill]="fill" [class.select_small]="small">
  <div
    class="select__input"
    [class.select__input_disabled]="control.disabled"
    [class.select__input_opened]="dropdown.isOpened()"
    cdkOverlayOrigin
    #dropdownTrigger="cdkOverlayOrigin"
    (click)="dropdown.open()"
  >
    <ng-container *ngIf="!dropdown.isLoading()">
      <span
        *ngIf="dropdown.currentOption"
        class="select__input-icon"
        [ngClass]="['icon-' + (dropdown.currentOption.icon || 'users_teams')]"
      >
      </span>
      <span class="select__input-label">
        <ng-container *ngIf="dropdown.currentOption">{{ dropdown.currentOption.name | appCapitalize }}</ng-container>
        <ng-container *ngIf="!dropdown.currentOption">{{ placeholder }}</ng-container>
      </span>
      <span class="select__input-arrow"></span>
    </ng-container>

    <ng-container *ngIf="dropdown.isLoading()">
      <span class="select__input-label">
        <span [class.loading-animation]="true">
          <span class="stub-text">value</span>
        </span>
      </span>
    </ng-container>
  </div>
</div>
