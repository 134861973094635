import { CdkConnectedOverlay, ConnectedOverlayPositionChange } from '@angular/cdk/overlay';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, Subscription } from 'rxjs';

import { Shadow, ShadowControl } from '@modules/customize';
import { createFormFieldFactory, NumberFieldType } from '@modules/fields';
import { ThemeService } from '@modules/theme';
import { firstSet, isSet } from '@shared';

import { stylesEditPopoverPositions } from '../styles-edit/styles-edit-popover-positions';

@Component({
  selector: 'app-styles-edit-shadow',
  templateUrl: './styles-edit-shadow.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StylesEditShadowComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() control: ShadowControl;
  @Input() subtitle = 'Shadow';
  @Input() additional: string;
  @Input() contrast = false;
  @Input() disabled = false;
  @Input() added = false;
  @Input() removeEnabled = true;
  @Output() remove = new EventEmitter<void>();

  @ViewChild(CdkConnectedOverlay) cdkConnectedOverlay: CdkConnectedOverlay;

  createField = createFormFieldFactory();
  color: SafeStyle;
  valueStr: string;
  dropdownOpened = false;
  popoverPositions = stylesEditPopoverPositions;
  popoverPositionsSubscription: Subscription;
  numberFieldTypes = NumberFieldType;

  constructor(public themeService: ThemeService, private sanitizer: DomSanitizer, private cd: ChangeDetectorRef) {}

  ngOnInit() {
    const defaultShadow = new Shadow();

    combineLatest(this.control.serialize$(), this.themeService.isDarkTheme$)
      .pipe(untilDestroyed(this))
      .subscribe(([shadow, isDarkTheme]) => {
        if (shadow) {
          const color = firstSet(shadow.cssColor(isDarkTheme), defaultShadow.cssColor(isDarkTheme));
          const offsetX = firstSet(shadow.offsetX, defaultShadow.offsetX, 0);
          const offsetY = firstSet(shadow.offsetY, defaultShadow.offsetY, 0);
          const blurRadius = firstSet(shadow.blurRadius, defaultShadow.blurRadius, 0);
          const spreadRadius = firstSet(shadow.spreadRadius, defaultShadow.spreadRadius, 0);

          this.color = isSet(color) ? this.sanitizer.bypassSecurityTrustStyle(color) : undefined;
          this.valueStr = `${offsetX}px ${offsetY}px ${blurRadius}px ${spreadRadius}px`;
        } else {
          this.color = undefined;
          this.valueStr = undefined;
        }

        this.cd.markForCheck();
      });

    if (this.added) {
      this.setDropdownOpened(true);
    }
  }

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    this.setPositionObserver();
  }

  setDropdownOpened(value: boolean) {
    this.dropdownOpened = value;
    this.cd.markForCheck();
  }

  setPositionObserver() {
    if (this.popoverPositionsSubscription) {
      this.popoverPositionsSubscription.unsubscribe();
    }

    if (!this.cdkConnectedOverlay) {
      return;
    }

    this.popoverPositionsSubscription = this.cdkConnectedOverlay.positionChange
      .pipe(untilDestroyed(this))
      .subscribe((e: ConnectedOverlayPositionChange) => {
        const propsEqual = ['offsetX', 'offsetY', 'originX', 'originY', 'overlayX', 'overlayY'];
        const position = this.popoverPositions.find(item =>
          propsEqual.every(prop => (item[prop] || undefined) == e.connectionPair[prop])
        );
        const otherPosition = this.popoverPositions.filter(item => item !== position);

        if (position) {
          this.cdkConnectedOverlay.overlayRef.addPanelClass(position.panelClass);
        }

        otherPosition.forEach(item => this.cdkConnectedOverlay.overlayRef.removePanelClass(item.panelClass));
      });
  }
}
