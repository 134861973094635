var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, ElementRef, OnChanges, OnDestroy, OnInit, QueryList } from '@angular/core';
import clamp from 'lodash/clamp';
import isEqual from 'lodash/isEqual';
import pickBy from 'lodash/pickBy';
import range from 'lodash/range';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fromEvent, merge, of } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { CustomizeService, ElementType, FilterStyle, getFieldElementStyles, ListElementItem, registerElementComponent, ViewContextElement } from '@modules/customize';
import { BaseElementComponent } from '@modules/customize-elements';
import { ModelDescriptionStore } from '@modules/model-queries';
import { listModelDescriptionInputFieldProvider } from '@modules/parameters';
import { CurrentEnvironmentStore } from '@modules/projects';
import { addClass, isSet, MouseButton, removeClass } from '@shared';
import { COLUMN_RESIZING_CLASS } from '../columns-layout/columns-layout.component';
import { CustomPagePopupComponent } from '../custom-page-popup/custom-page-popup.component';
import { FilterElementItemComponent } from './filter-element-item/filter-element-item.component';
function getElementStateList(state) {
    return {
        element: state.element,
        elementInputs: state.elementInputs ? state.elementInputs.map(function (item) { return item.serialize(); }) : []
    };
}
function getElementStateName(state) {
    return {
        name: state.name
    };
}
var FilterElementComponent = /** @class */ (function (_super) {
    __extends(FilterElementComponent, _super);
    function FilterElementComponent(currentEnvironmentStore, modelDescriptionStore, customizeService, viewContextElement, popup, cd) {
        var _this = _super.call(this) || this;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        _this.modelDescriptionStore = modelDescriptionStore;
        _this.customizeService = customizeService;
        _this.viewContextElement = viewContextElement;
        _this.popup = popup;
        _this.cd = cd;
        _this.columnElements = new QueryList();
        _this.itemComponents = new QueryList();
        _this.state = {};
        _this.filterStyles = FilterStyle;
        _this.filters = [];
        _this.draggingSubscriptions = [];
        _this.stickDistance = 8;
        _this.wrapperPadding = 15;
        _this.trackColumn = (function () {
            return function (i, item) {
                return isSet(item.field.name) ? "field_" + item.field.name : i;
            };
        })();
        return _this;
    }
    FilterElementComponent.prototype.ngOnInit = function () {
        this.customizeEnabled$ = this.customizeService.enabled$.pipe(map(function (item) { return !!item; }));
        this.initContext();
        this.elementOnChange(this.element);
        this.trackChanges();
    };
    FilterElementComponent.prototype.ngOnDestroy = function () {
        this.deinitHandles();
    };
    FilterElementComponent.prototype.ngOnChanges = function (changes) {
        if (changes.element && !changes.element.firstChange) {
            this.viewContextElement.initInfo({ name: this.element.name, element: this.element }, true);
        }
        if (changes.element) {
            this.elementOnChange(this.element);
        }
    };
    FilterElementComponent.prototype.trackChanges = function () {
        var _this = this;
        this.element$
            .pipe(map(function (element) { return _this.getElementState(element); }), untilDestroyed(this))
            .subscribe(function (state) {
            _this.onStateUpdated(state);
            _this.state = state;
        });
    };
    FilterElementComponent.prototype.getElementState = function (element) {
        return {
            element: element.elements[0],
            elementInputs: element.elementInputs,
            name: element.name
        };
    };
    FilterElementComponent.prototype.onStateUpdated = function (state) {
        if (!isEqual(getElementStateList(state), getElementStateList(this.state))) {
            this.initFilters(state);
        }
        if (!isEqual(getElementStateName(state), getElementStateName(this.state))) {
            this.updateContextInfo(state);
        }
    };
    FilterElementComponent.prototype.initFilters = function (state) {
        var _this = this;
        if (this.filtersSubscription) {
            this.filtersSubscription.unsubscribe();
            this.filtersSubscription = undefined;
        }
        this.filtersSubscription = this.filterElement$(state)
            .pipe(switchMap(function (element) {
            if (!element) {
                return of([]);
            }
            var dataSource = element.layouts[0] ? element.layouts[0].dataSource : undefined;
            var resource = _this.currentEnvironmentStore.resources.find(function (item) { return item.uniqueName == dataSource.queryResource; });
            var modelId = dataSource.query && dataSource.query.simpleQuery
                ? [dataSource.queryResource, dataSource.query.simpleQuery.model].join('.')
                : undefined;
            return _this.modelDescriptionStore.getDetailFirst(modelId).pipe(map(function (modelDescription) {
                return listModelDescriptionInputFieldProvider(dataSource.type, resource, modelDescription, dataSource.queryParameters, dataSource.query, dataSource.columns);
            }), map(function (value) {
                var getFields = function (items) {
                    return items.reduce(function (prev, item) {
                        if (item.field) {
                            prev.push(item.field);
                        }
                        if (item.children) {
                            prev.push.apply(prev, getFields(item.children));
                        }
                        return prev;
                    }, []);
                };
                return getFields(value);
            }), map(function (fields) {
                return dataSource.queryInputs
                    .map(function (input) {
                    return {
                        input: input,
                        field: fields.find(function (item) { return item.name == input.getName(); })
                    };
                })
                    .filter(function (item) { return item.field && item.input.contextValueStartsWith(['elements', _this.element.uid]); })
                    .map(function (item) { return item.field; });
            }), map(function (fields) {
                return fields
                    .map(function (field) {
                    var elementInputIndex = state.elementInputs.findIndex(function (item) { return item.name == field.name; });
                    var elementInput = elementInputIndex !== -1 ? state.elementInputs[elementInputIndex] : undefined;
                    var overrides = elementInput && elementInput.settings
                        ? {
                            verboseName: elementInput.settings.verboseName,
                            params: elementInput.settings.params,
                            resetEnabled: elementInput.settings.resetEnabled,
                            placeholder: elementInput.settings.placeholder,
                            validatorType: elementInput.settings.validatorType,
                            validatorParams: elementInput.settings.validatorParams,
                            valueInput: elementInput.settings.valueInput
                        }
                        : {};
                    return {
                        field: __assign({}, field, pickBy(overrides, function (v, k) { return isSet(v, true); })),
                        elementInput: elementInput,
                        elementInputIndex: elementInputIndex
                    };
                })
                    .sort(function (lhs, rhs) {
                    if (lhs.elementInputIndex !== -1 && rhs.elementInputIndex !== -1) {
                        return lhs.elementInputIndex - rhs.elementInputIndex;
                    }
                    else if (lhs.elementInputIndex !== -1 && rhs.elementInputIndex === -1) {
                        return -1;
                    }
                    else if (lhs.elementInputIndex === -1 && rhs.elementInputIndex !== -1) {
                        return 1;
                    }
                    else {
                        return 0;
                    }
                })
                    .map(function (filterItem) {
                    return {
                        field: filterItem.field,
                        labelAdditional: filterItem.elementInput ? filterItem.elementInput.labelAdditional : undefined,
                        elementStyles: filterItem.elementInput ? getFieldElementStyles(filterItem.elementInput) : undefined,
                        tooltip: filterItem.elementInput ? filterItem.elementInput.tooltip : undefined,
                        weight: filterItem.elementInput ? filterItem.elementInput.weight : undefined
                    };
                });
            }));
        }), untilDestroyed(this))
            .subscribe(function (result) {
            _this.filters = result;
            _this.cd.markForCheck();
        });
    };
    FilterElementComponent.prototype.filterElement$ = function (state) {
        var _this = this;
        return this.context.elements$.pipe(switchMap(function () {
            var contextElement = _this.context.getElement(state.element);
            var element = contextElement && contextElement.element instanceof ListElementItem ? contextElement.element : undefined;
            if (!element) {
                return of({
                    element: undefined,
                    serialized: undefined
                });
            }
            return merge(of({}), _this.customizeService.changed$).pipe(map(function () {
                return {
                    element: element,
                    serialized: element ? element.serialize() : undefined
                };
            }));
        }), distinctUntilChanged(function (lhs, rhs) { return isEqual(lhs.serialized, rhs.serialized); }), map(function (item) { return item.element; }));
    };
    FilterElementComponent.prototype.clearFilters = function () {
        this.itemComponents.forEach(function (item) { return item.control.setValue(undefined); });
    };
    FilterElementComponent.prototype.initContext = function () {
        var _this = this;
        this.viewContextElement.initElement({
            uniqueName: this.element.uid,
            name: this.element.name,
            icon: 'windows',
            element: this.element,
            popup: this.popup ? this.popup.popup : undefined
        });
        this.viewContextElement.setActions([
            {
                uniqueName: 'clear_filters',
                name: 'Reset All filters',
                icon: 'delete',
                parameters: [],
                handler: function () { return _this.clearFilters(); }
            }
        ]);
    };
    FilterElementComponent.prototype.updateContextInfo = function (state) {
        this.viewContextElement.initInfo({
            name: state.name
        });
    };
    FilterElementComponent.prototype.getStickPositions = function (bounds) {
        return [1 / 2, 1 / 3, 1 / 4, 1 / 5, 1 / 6, 1 / 8]
            .filter(function (multiplier) { return bounds.width * multiplier >= 120; })
            .reduce(function (acc, multiplier) {
            var parts = 1 / multiplier;
            acc.push.apply(acc, range(parts)
                .slice(1)
                .map(function (i) {
                var pos = bounds.left + multiplier * bounds.width * i;
                return {
                    label: i + "/" + parts,
                    position: pos
                };
            }));
            return acc;
        }, []);
    };
    FilterElementComponent.prototype.getFluidTotalWidth = function () {
        return this.columnElements.reduce(function (sum, current, i) {
            return sum + current.nativeElement.getBoundingClientRect().width;
        }, 0);
    };
    FilterElementComponent.prototype.onHandleDragStarted = function (e, index) {
        event.preventDefault();
        event.stopPropagation();
        this.draggingIndex = index;
        this.cd.markForCheck();
        addClass(document.body, COLUMN_RESIZING_CLASS);
        this.deinitHandles();
        this.initHandles();
    };
    FilterElementComponent.prototype.initHandles = function () {
        var _this = this;
        this.draggingSubscriptions.push(fromEvent(window.document, 'mousemove')
            .pipe(untilDestroyed(this))
            .subscribe(function (e) {
            var index = _this.draggingIndex;
            var bounds = _this.columnsElement.nativeElement.getBoundingClientRect();
            var lhs = _this.columnElements.toArray()[index].nativeElement;
            var lhsBounds = lhs.getBoundingClientRect();
            var rhs = _this.columnElements.toArray()[index + 1].nativeElement;
            var rhsBounds = rhs.getBoundingClientRect();
            var minWidth = 40;
            var position = clamp(e.clientX, lhsBounds.left + _this.wrapperPadding + minWidth, rhsBounds.right - _this.wrapperPadding - minWidth);
            var stickPosition = _this.getStickPositions(bounds).find(function (item) {
                return position >= item.position - _this.stickDistance && position <= item.position + _this.stickDistance;
            });
            if (stickPosition) {
                _this.stick = stickPosition;
                position = stickPosition.position;
            }
            else {
                _this.stick = undefined;
            }
            var totalWidth = _this.getFluidTotalWidth();
            var leftWidth = position - lhsBounds.left + _this.wrapperPadding * 0.5;
            var rightWidth = rhsBounds.right - (position - _this.wrapperPadding) - _this.wrapperPadding * 0.5;
            var lhsWeight = leftWidth / totalWidth;
            var rhsWeight = rightWidth / totalWidth;
            _this.columnElements.forEach(function (columnElement, i) {
                var weight;
                if (i === index) {
                    weight = lhsWeight;
                }
                else if (i === index + 1) {
                    weight = rhsWeight;
                }
                else {
                    weight = columnElement.nativeElement.getBoundingClientRect().width / totalWidth;
                }
                _this.filters[i].weight = weight;
                var elementInput = _this.element.elementInputs.find(function (item) { return item.name == _this.filters[i].field.name; });
                if (elementInput) {
                    elementInput.weight = weight;
                }
            });
            _this.cd.markForCheck();
        }));
        this.draggingSubscriptions.push(fromEvent(window.document, 'mouseup')
            .pipe(filter(function (e) { return e.button == MouseButton.Main; }), untilDestroyed(this))
            .subscribe(function () {
            _this.deinitHandles();
            _this.draggingIndex = undefined;
            _this.stick = undefined;
            removeClass(document.body, COLUMN_RESIZING_CLASS);
            _this.customizeService.markChanged();
            _this.cd.markForCheck();
        }));
    };
    FilterElementComponent.prototype.deinitHandles = function () {
        this.draggingSubscriptions.forEach(function (item) { return item.unsubscribe(); });
        this.draggingSubscriptions = [];
    };
    return FilterElementComponent;
}(BaseElementComponent));
export { FilterElementComponent };
registerElementComponent({
    type: ElementType.Filter,
    component: FilterElementComponent,
    label: 'Image',
    actions: []
});
