<form *ngIf="!loading" [formGroup]="form.form">
  <div *ngIf="!filesEditing" class="popup-content resource-popup">
    <div
      *ngIf="environment"
      class="resource-popup__tag"
      [ngClass]="['color_' + environment.color + '_1', 'background-color_' + environment.color + '_2']"
    >
      {{ environment.name }}
    </div>
    <div class="resource-popup__image">
      <div
        class="resource-popup__image-inner"
        [style.background-image]="'/assets/images/resources/' + typeItem.image + '-color.svg' | appDeployCssUrl"
      ></div>
    </div>
    <div class="resource-popup__title">
      Allow Jet Admin to access <br />
      <strong [style.color]="typeItem.color">{{ typeItem.label }}</strong>
    </div>
    <div class="resource-popup__description">
      Sign in to Google Sheets to create internal tools on top of your spreadsheet data.
    </div>

    <div class="resource-popup__content">
      <app-page-block *ngIf="resourceNameEditing">
        <app-auto-field
          [form]="form.resourceForm"
          [field]="
            createField({
              name: 'name',
              label: 'Resource Name',
              field: 'CharField',
              description: 'This is how you and your team will see it',
              required: true,
              params: { classes: ['input_fill'] }
            })
          "
        ></app-auto-field>
      </app-page-block>

      <app-page-block>
        <div *ngIf="accountLoading" class="field">
          <div class="field__label">Google Account</div>
          <div class="field__value">
            <div class="input input_fill">
              <span [class.loading-animation]="true"><span class="stub-text">user@example.com</span></span>
            </div>
          </div>
        </div>
        <div *ngIf="!accountLoading" class="field">
          <div *ngIf="account" class="field__label">
            <span class="field__label-text">Google Account</span>
            <span class="field__label-right">
              <a href="javascript:void(0)" class="field__label-link" (click)="getOAuthToken()">
                <span class="field__label-link-icon icon-pen"></span>
                Change Account
              </a>
            </span>
          </div>
          <div *ngIf="account" class="field__value">
            <div class="input input_fill">{{ account }}</div>
          </div>
          <div *ngIf="!account" class="field__value">
            <button type="button" class="button button_primary button_shadow button_fill" (click)="getOAuthToken()">
              <span class="button__label">Sign In to Google Sheets</span>
            </button>
          </div>
          <div class="field__description">
            Google Sheets is a secure partner with Jet Admin. Your credentials are stored on Google side.
          </div>
        </div>

        <app-page-block *ngIf="accountPermissionsError" [paddingSize]="'none'">
          <app-alert [title]="'Not enough permissions'" [danger]="true" [border]="true">
            You haven't granted all required permissions, please <strong>Sign In to Google Sheets</strong> again and
            grant all permissions.
          </app-alert>
        </app-page-block>
      </app-page-block>

      <app-page-block *ngIf="(form.form | appFormErrors).length">
        <app-resource-settings-submit-error [errors]="form.form | appFormErrors"></app-resource-settings-submit-error>
      </app-page-block>
    </div>

    <div class="popup-content__submit">
      <button
        *ngIf="resourceDelete && resource"
        type="button"
        class="button button_danger popup-content__delete"
        (click)="requestDelete()"
      >
        Delete
      </button>
      <button type="button" class="button popup-content__submit-item" (click)="cancel()">
        Cancel
      </button>

      <button
        type="button"
        class="button button_primary button_shadow popup-content__submit-item"
        [class.button_disabled]="!form.isResourceFormValid() || loadingSubmit || !account"
        (click)="setFilesEditing(true)"
      >
        <app-loader-small *ngIf="loadingSubmit" class="button__icon button__icon_left"></app-loader-small>
        <span class="button__label">
          <ng-container *ngIf="!account">
            Authorize
          </ng-container>
          <ng-container *ngIf="account">
            <ng-container *ngIf="resource">Manage Files</ng-container>
            <ng-container *ngIf="!resource">Continue to Files</ng-container>
          </ng-container>
        </span>
      </button>
    </div>
  </div>

  <div *ngIf="filesEditing" class="popup-content resource-popup resource-popup_wide">
    <div class="resource-popup__content">
      <app-google-sheets-resource-settings-files
        [form]="form"
        (back)="setFilesEditing(false)"
      ></app-google-sheets-resource-settings-files>

      <!--      <app-page-block>-->
      <!--        <ng-template #tip>-->
      <!--          <ul style="margin: 0; padding: 0 0 0 10px;">-->
      <!--            <li>Working with large amounts of data can be slow</li>-->
      <!--            <li>Not possible to run SQL queries</li>-->
      <!--            <li>Not possible to write JOIN queries with other resources</li>-->
      <!--            <li>Sync is charged upon number of queries</li>-->
      <!--          </ul>-->
      <!--        </ng-template>-->

      <!--        <div class="toggle-button" (click)="form.form.controls['sync'].setValue(!form.form.controls['sync'].value)">-->
      <!--          <div class="toggle-button__left">-->
      <!--            <app-toggle [orange]="true" [selected]="!form.form.controls['sync'].value"> </app-toggle>-->
      <!--          </div>-->
      <!--          <div class="toggle-button__main">-->
      <!--            <div class="toggle-button__title">Work directly with {{ typeItem.label }}</div>-->
      <!--            <div class="toggle-button__subtitle">-->
      <!--              Has fetch data limitations-->

      <!--              <a class="help-icon help-icon_clickable icon-help" [appTip]="tip" [appTipOptions]="{ side: 'top' }"></a>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </app-page-block>-->

      <app-page-block *ngIf="(form.form | appFormErrors).length">
        <app-resource-settings-submit-error [errors]="form.form | appFormErrors"></app-resource-settings-submit-error>
      </app-page-block>
    </div>

    <app-alert
      *ngIf="slowLoading"
      [title]="'Import in progress'"
      [warning]="true"
      [animateShow]="true"
      class="popup-content__content-alert"
    >
      <p>
        It can take 2 minutes or more depending on the size of files
      </p>
    </app-alert>

    <div class="popup-content__submit" [class.popup-content__submit_no-border]="slowLoading">
      <button type="button" class="button popup-content__submit-item" (click)="setFilesEditing(false)">
        Back
      </button>

      <button
        type="button"
        class="button button_primary button_shadow popup-content__submit-item"
        [class.button_disabled]="!form.isValid() || loadingSubmit"
        (click)="resource ? submit() : chooseSync()"
      >
        <app-loader-small *ngIf="loadingSubmit" class="button__icon button__icon_left"></app-loader-small>
        <span class="button__label">
          <ng-container *ngIf="resource">Update resource</ng-container>
          <ng-container *ngIf="!resource">Add resource</ng-container>
        </span>
      </button>
    </div>
  </div>
</form>

<app-resource-settings-stub *ngIf="loading" [typeItem]="typeItem"></app-resource-settings-stub>
