import {
  ChangeDetectorRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest } from 'rxjs';

import { UniqueIdToken } from '@common/unique-id';
import { ApiType, ServerRequestError } from '@modules/api';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { applyParamInput, deserializeSingleFileValue, ImageOutputFormat, Input as FieldInput } from '@modules/fields';
import { CurrentEnvironmentStore, CurrentProjectStore, Resource } from '@modules/projects';
import { ResourceControllerService } from '@modules/resources';
import { Storage } from '@modules/storages';
import { StorageService } from '@modules/storages-queries';
import { controlValue, getExtension, getFilename, getFilenameWithExtension, isSet, openUrl } from '@shared';

import { UploadedFile } from '../file-field/upload-field.component';
import { cropFormats } from '../image-field/image-field.crop';

export const VALUE_OUTPUT = 'value';
export const UPLOAD_RESULT_OUTPUT = 'upload_result';
export const FILE_NAME_OUTPUT = 'file_name';
export const FILE_EXTENSION_OUTPUT = 'file_extension';

export abstract class BaseUploadComponent implements OnInit, OnDestroy, OnChanges {
  @Input() name: string;
  @Input() control: FormControl;
  @Input() outputFormat: ImageOutputFormat;
  @Input() storageResourceName: string;
  @Input() storageName: string;
  @Input() path: FieldInput;
  @Input() autofocus = false;
  @Input() disabled = false;
  @Input() background = false;
  @Input() fit: string;
  @Input() resize = false;
  @Input() editor = true;
  @Input() cropFormat: cropFormats;
  @Input() cropFormatCustom: string;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() compact = false;
  @Input() accentColor: string;
  @Input() theme = false;
  @Output() uploadedUpdated = new EventEmitter<void>();

  uploadedFile: UploadedFile;
  uploadFileSize: number;
  idToken = new UniqueIdToken();
  uploadProgress: number;
  uploadError: string;

  storage$ = new BehaviorSubject<Storage>(undefined);
  storageResource$ = new BehaviorSubject<Resource>(undefined);

  constructor(
    protected currentProjectStore: CurrentProjectStore,
    protected currentEnvironmentStore: CurrentEnvironmentStore,
    protected resourceControllerService: ResourceControllerService,
    protected storageService: StorageService,
    protected cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    combineLatest(this.storageResource$, controlValue(this.control))
      .pipe(untilDestroyed(this))
      .subscribe(([storageResource, value]) => {
        value = deserializeSingleFileValue(value);
        value = this.getUploadUrl(value, storageResource);

        this.uploadedFile = this.getUploadedFileFromValue(value);

        this.cd.markForCheck();
        this.onUploadedUpdated();
      });
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['storageResourceName'] || changes['storageName']) {
      this.updateStorage();
    }
  }

  getUploadUrl(value: string, storageResource: Resource): string {
    if (!isSet(value)) {
      return;
    }

    // const apiInfo = this.getApiInfo();
    const apiInfo = storageResource ? storageResource.apiInfo : undefined;

    if (
      apiInfo &&
      apiInfo.type == ApiType.JetDjango &&
      apiInfo.versionLessThan('0.7.7')
      // !this.field.params['ignore_old_django_format']
    ) {
      value = value['value'];
    }

    if (this.outputFormat == ImageOutputFormat.Storage) {
      const controller = storageResource ? this.resourceControllerService.get(storageResource.type) : undefined;

      if (controller && controller.fileUrl) {
        return controller.fileUrl(storageResource, value);
      }
    }

    return value;
  }

  updateStorage() {
    const storage = this.currentProjectStore.instance.getStorage(
      this.currentEnvironmentStore.instance.uniqueName,
      this.storageResourceName,
      this.storageName,
      { defaultFirst: true }
    );

    this.storageResource$.next(storage ? storage.resource : undefined);
    this.storage$.next(storage ? storage.storage : undefined);
  }

  getUploadedFileFromValue(value: string) {
    if (!value) {
      return;
    }

    return {
      url: value,
      filename: getFilenameWithExtension(value),
      extension: getExtension(value)
    };

    // if (this.contextElement) {
    //   this.contextElement.setOutputValue(FILE_NAME_OUTPUT, getFilename(this.preview));
    //   this.contextElement.setOutputValue(FILE_EXTENSION_OUTPUT, this.previewExtension);
    // }
    //   },
    //   () => {
    //     this.preview = undefined;
    //     this.previewFilename = undefined;
    //     this.previewExtension = undefined;
    //     this.cd.markForCheck();
    //     this.onPreviewUpdate(this.preview);
    //
    //     if (this.contextElement) {
    //       this.contextElement.setOutputValue(FILE_NAME_OUTPUT, this.previewFilename);
    //       this.contextElement.setOutputValue(FILE_EXTENSION_OUTPUT, this.previewExtension);
    //     }
    //   }
    // );
  }

  onUploadedUpdated() {}

  // isUploadable() {
  //   return (
  //     (!this.field.params['output_format'] || this.field.params['output_format'] == ImageOutputFormat.Storage) &&
  //     !this.field.params['external']
  //   );
  // }

  isUploadable() {
    return this.outputFormat != ImageOutputFormat.URL;
  }

  clearCurrentValue() {
    if (this.control) {
      this.control.patchValue(null);
    }
  }

  onFileChange(el: HTMLInputElement) {
    if (!el.files.length) {
      return;
    }

    const file = el.files[0];
    el.value = null;
    this.onFileSelected(file);
  }

  getUploadPath(file: File) {
    if (this.path) {
      try {
        let result = applyParamInput(this.path, {
          context: this.context,
          contextElement: this.contextElement,
          localContext: {
            [FILE_NAME_OUTPUT]: getFilename(file.name),
            [FILE_EXTENSION_OUTPUT]: getExtension(file.name)
          },
          defaultValue: ''
        });

        if (isSet(result)) {
          result = String(result);
        }

        return result;
      } catch (e) {
        return '';
      }
      // } else if (this.context['modelDescription']) {
      //   return [this.context['modelDescription'].model, this.field.name].join('/');
      // } else if (this.context['action']) {
      //   return ['actions', this.context['action'], this.field.name].join('/');
    } else {
      return '';
    }
  }

  onFileSelected(file: File) {
    const path = this.getUploadPath(file);
    this.uploadFile(file, path);
  }

  uploadFile(file: File, path: string) {
    this.uploadedFile = undefined;
    this.uploadFileSize = file.size;
    this.uploadProgress = 0;
    this.uploadError = undefined;
    this.cd.markForCheck();
    this.onUploadedUpdated();

    const storageResource = this.storageResource$.value;
    const storage = this.storage$.value;

    if (!storageResource || !storage) {
      return;
    }

    this.storageService
      .upload(storageResource, storage, storage.uploadQuery, file, path)
      .pipe(untilDestroyed(this))
      .subscribe(
        response => {
          if (!response) {
            this.uploadProgress = undefined;
            this.cd.markForCheck();

            if (this.contextElement) {
              this.contextElement.setOutputValue(UPLOAD_RESULT_OUTPUT, undefined);
            }

            return;
          }

          if (response.result) {
            let value: any;

            // if (this.form) {
            // const params = {};
            // const apiInfo = this.getApiInfo();
            const apiInfo = storageResource ? storageResource.apiInfo : undefined;

            if (
              apiInfo &&
              apiInfo.type == ApiType.JetDjango &&
              apiInfo.versionLessThan('0.7.7')
              // !this.field.params['ignore_old_django_format']
            ) {
              value = {
                value: response.result.uploadedPath,
                url: response.result.uploadedUrl
              };
            } else {
              value = response.result.uploadedUrl;
            }

            if (this.control) {
              this.control.patchValue(value);
            }
            // }

            this.uploadProgress = undefined;
            this.cd.markForCheck();

            this.uploadedFile = this.getUploadedFileFromValue(value);
            this.cd.markForCheck();
            this.onUploadedUpdated();

            this.uploadedUpdated.next();

            if (this.contextElement) {
              this.contextElement.setOutputValue(
                UPLOAD_RESULT_OUTPUT,
                response.result.response ? response.result.response.body : undefined
              );
            }
          } else {
            this.uploadProgress = response.state.uploadProgress * 0.95 + response.state.downloadProgress * 0.05;
            this.cd.markForCheck();

            if (this.contextElement) {
              this.contextElement.setOutputValue(UPLOAD_RESULT_OUTPUT, undefined);
            }
          }
        },
        error => {
          this.uploadProgress = undefined;

          if (error instanceof ServerRequestError && error.nonFieldErrors.length) {
            this.uploadError = error.nonFieldErrors[0];
          } else {
            this.uploadError = 'Unknown error';
          }

          this.cd.markForCheck();

          if (this.contextElement) {
            this.contextElement.setOutputValue(UPLOAD_RESULT_OUTPUT, undefined);
          }
        }
      );
  }

  download() {
    openUrl(this.uploadedFile.url, true);
  }
}
