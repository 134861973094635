/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./share-menu-item.component";
import * as i3 from "../../../../projects/stores/current-project.store";
import * as i4 from "../../../../projects/stores/current-environment.store";
import * as i5 from "../../../../projects/services/project-user/project-user.service";
import * as i6 from "../../../../share/services/share/share.controller";
import * as i7 from "../../../../theme/services/theme/theme.service";
import * as i8 from "@angular/platform-browser";
var styles_ShareMenuItemComponent = [];
var RenderType_ShareMenuItemComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ShareMenuItemComponent, data: {} });
export { RenderType_ShareMenuItemComponent as RenderType_ShareMenuItemComponent };
function View_ShareMenuItemComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "span", [["class", "user-photos__item"]], [[4, "background-image", null]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = (((_v.context.$implicit == null) ? null : _v.context.$implicit.photo) ? (("url(" + _v.context.$implicit.photo) + ")") : ""); _ck(_v, 0, 0, currVal_0); }); }
function View_ShareMenuItemComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "user-photos__item user-photos__item_text"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" +", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.otherUsersCount > 99) ? 99 : _co.otherUsersCount); _ck(_v, 1, 0, currVal_0); }); }
function View_ShareMenuItemComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "span", [["class", "user-photos__add user-photos__add_menu icon-plus"]], null, null, null, null, null))], null, null); }
function View_ShareMenuItemComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "user-photos user-photos_small"]], [[2, "user-photos_clickable", null], [2, "user-photos_background_menu-light", null], [2, "user-photos_background_menu-dark", null], [2, "user-photos_background_menu-unset", null], [2, "user-photos_background_content", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.share() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ShareMenuItemComponent_2)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ShareMenuItemComponent_3)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ShareMenuItemComponent_4)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_5 = _co.displayUsers; _ck(_v, 2, 0, currVal_5); var currVal_6 = _co.otherUsersCount; _ck(_v, 4, 0, currVal_6); var currVal_7 = (_co.editAccess && !(!_co.horizontal && _co.primary)); _ck(_v, 6, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.editAccess; var currVal_1 = ((_co.colorLight === true) && !_co.dropdown); var currVal_2 = ((_co.colorLight === false) && !_co.dropdown); var currVal_3 = (_co.colorUnset && !_co.dropdown); var currVal_4 = _co.dropdown; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }); }
function View_ShareMenuItemComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "span", [["class", "user-photos__item"]], null, null, null, null, null))], null, null); }
function View_ShareMenuItemComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "user-photos user-photos_small loading-animation"]], [[2, "user-photos_background_menu-light", null], [2, "user-photos_background_menu-dark", null], [2, "user-photos_background_menu-unset", null], [2, "user-photos_background_content", null]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_ShareMenuItemComponent_6)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i0.ɵpad(3, 3)], function (_ck, _v) { var currVal_4 = _ck(_v, 3, 0, 1, 2, 3); _ck(_v, 2, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.colorLight === true) && !_co.dropdown); var currVal_1 = ((_co.colorLight === false) && !_co.dropdown); var currVal_2 = (_co.colorUnset && !_co.dropdown); var currVal_3 = _co.dropdown; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
export function View_ShareMenuItemComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "menu2-share"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ShareMenuItemComponent_1)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ShareMenuItemComponent_5)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.loading; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.loading; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_ShareMenuItemComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-share-menu-item", [], [[8, "style", 2]], null, null, View_ShareMenuItemComponent_0, RenderType_ShareMenuItemComponent)), i0.ɵdid(1, 770048, null, 0, i2.ShareMenuItemComponent, [i3.CurrentProjectStore, i4.CurrentEnvironmentStore, i5.ProjectUserService, i6.ShareController, i7.ThemeService, i8.DomSanitizer, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).style; _ck(_v, 0, 0, currVal_0); }); }
var ShareMenuItemComponentNgFactory = i0.ɵccf("app-share-menu-item", i2.ShareMenuItemComponent, View_ShareMenuItemComponent_Host_0, { menuItem: "menuItem", menuBlock: "menuBlock", childrenVertical: "childrenVertical", primary: "primary", dropdown: "dropdown", horizontal: "horizontal", forceActive: "forceActive", forceOpened: "forceOpened", context: "context" }, { dropdownOpen: "dropdownOpen", dropdownClose: "dropdownClose" }, []);
export { ShareMenuItemComponentNgFactory as ShareMenuItemComponentNgFactory };
