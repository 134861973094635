import { AfterViewInit, ChangeDetectorRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { interval } from 'rxjs';
import { LocalStorage } from '@core';
import { TintStyle } from '@modules/actions';
var RefreshDataComponent = /** @class */ (function () {
    function RefreshDataComponent(storageService, cd) {
        this.storageService = storageService;
        this.cd = cd;
        this.theme = false;
        this.updateData = new EventEmitter();
        this.intervalSec = 10;
        this.runAutomatically = false;
        this.tintStyles = TintStyle;
    }
    RefreshDataComponent.prototype.ngOnInit = function () {
        this.runAutomatically = this.getSavedRunAutomatically();
        this.cd.markForCheck();
        if (this.runAutomatically) {
            this.startInterval();
        }
    };
    RefreshDataComponent.prototype.ngOnDestroy = function () { };
    RefreshDataComponent.prototype.ngAfterViewInit = function () { };
    Object.defineProperty(RefreshDataComponent.prototype, "storageKey", {
        get: function () {
            return ['auto_refresh', this.viewId].join('_');
        },
        enumerable: true,
        configurable: true
    });
    RefreshDataComponent.prototype.getSavedRunAutomatically = function () {
        if (!this.viewId) {
            return false;
        }
        return this.storageService.get(this.storageKey) == '1';
    };
    RefreshDataComponent.prototype.saveRunAutomatically = function (value) {
        if (!this.viewId) {
            return;
        }
        this.storageService.set(this.storageKey, value ? '1' : '0');
    };
    RefreshDataComponent.prototype.toggleRepeatUpdateData = function () {
        this.runAutomatically = !this.runAutomatically;
        this.cd.markForCheck();
        this.saveRunAutomatically(this.runAutomatically);
        if (this.runAutomatically) {
            this.startInterval();
        }
        else {
            this.stopInterval();
        }
    };
    RefreshDataComponent.prototype.startInterval = function () {
        var _this = this;
        this.timerSubscription = interval(this.intervalSec * 1000)
            .pipe(untilDestroyed(this))
            .subscribe(function () { return _this.updateData.next(); });
    };
    RefreshDataComponent.prototype.stopInterval = function () {
        if (this.timerSubscription) {
            this.timerSubscription.unsubscribe();
            this.timerSubscription = undefined;
        }
    };
    return RefreshDataComponent;
}());
export { RefreshDataComponent };
