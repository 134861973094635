<ng-container *ngIf="!control">
  No control found
</ng-container>

<mat-select
  *ngIf="control"
  [multiple]="multiple"
  [formControl]="control"
  [class.mat-select-fill]="fill"
  [class.mat-select-small]="small"
  [class.mat-select-orange]="orange"
  [class.mat-select-segment-top]="segment == selectSegments.Top"
  [class.mat-select-segment]="segment == selectSegments.Middle"
  [class.mat-select-segment-bottom]="segment == selectSegments.Bottom"
  [ngClass]="classes"
  [placeholder]="isControlSet && sourceValueLoading ? 'Loading...' : placeholder"
  [compareWith]="compareWith"
  [disableRipple]="true"
  [disableOptionCentering]="true"
  [panelClass]="{
    'mat-select-panel-expand': expand,
    'mat-select-panel-theme': theme,
    'mat-select-panel-orange': orange
  }"
  [id]="id"
  (opened)="onOpened()"
  (closed)="onClosed()"
  (valueChange)="valueChange.emit($event)"
>
  <mat-select-trigger>
    <span class="mat-select-trigger-combo">
      <span *ngIf="triggerIcon as icon" class="mat-select-trigger-combo-icon" [appIcon]="icon"></span>

      <span
        *ngIf="colorEnabled && triggerColor as color"
        class="mat-select-trigger-combo-color"
        [style.background]="(color | appIsSet) ? sanitizer.bypassSecurityTrustStyle(color) : null"
      ></span>

      <span class="mat-select-trigger-combo-label">{{ triggerValue }}</span>

      <span
        *ngIf="resetEnabled"
        class="mat-select-trigger-combo-action icon-close"
        [class.mat-select-trigger-combo-action_disabled]="control.disabled"
        [appTip]="'Clear value' | localize"
        [appTipOptions]="{ side: 'top', observeRemoveFromDom: true }"
        (click)="$event.stopPropagation(); control.patchValue(undefined)"
      ></span>
    </span>
  </mat-select-trigger>

  <div *ngIf="searchEnabled" class="mat-select-panel-search-container">
    <input
      class="mat-select-panel-search"
      [placeholder]="'Search...' | localize"
      [(ngModel)]="search"
      [ngModelOptions]="{ standalone: true }"
      appAutofocus
      (keydown)="ignoreSpaceSelect($event)"
      (keyup)="searchChanged.next()"
      tabindex="0"
    />
  </div>

  <ng-content
    *ngIf="(!(source?.loading$ | async) && source?.loaded !== false) || (source?.options$ | async)?.length"
    select="ng-container[dropdown-top]"
  ></ng-content>

  <ng-container *ngIf="source">
    <mat-option
      *ngFor="let item of source.options$ | async"
      [value]="item.value"
      (onSelectionChange)="onOptionSelectionChange(item, $event)"
    >
      <span
        *ngIf="item.image"
        class="mat-menu-item-icon"
        [class.mat-menu-item-icon_opaque]="item.image | appIsAbsoluteUrl"
        [appIcon]="item.image"
      ></span>

      <div class="mat-option-text-main">
        <div class="mat-option-text-title">{{ item.name }}</div>
        <div *ngIf="item.data && item.data['subtitle'] | appIsSet" class="mat-option-text-subtitle">
          {{ item.data['subtitle'] }}
        </div>
      </div>
    </mat-option>

    <mat-option *ngIf="!multiple && sourceCurrentOption" [value]="sourceCurrentOption.value" [style.display]="'none'">
      <span
        *ngIf="sourceCurrentOption.image"
        class="mat-menu-item-icon"
        [class.mat-menu-item-icon_opaque]="sourceCurrentOption.image | appIsAbsoluteUrl"
        [appIcon]="sourceCurrentOption.image"
      ></span>

      <div class="mat-option-text-main">
        <div class="mat-option-text-title">{{ sourceCurrentOption.name }}</div>
        <div
          *ngIf="sourceCurrentOption.data && sourceCurrentOption.data['subtitle'] | appIsSet"
          class="mat-option-text-subtitle"
        >
          {{ sourceCurrentOption.data['subtitle'] }}
        </div>
      </div>
    </mat-option>
    <ng-container *ngIf="multiple && sourceCurrentOption">
      <mat-option *ngFor="let item of sourceCurrentOption" [value]="item.value" [style.display]="'none'">
        <span
          *ngIf="item.image"
          class="mat-menu-item-icon"
          [class.mat-menu-item-icon_opaque]="item.image | appIsAbsoluteUrl"
          [appIcon]="item.image"
        ></span>

        <div class="mat-option-text-main">
          <div class="mat-option-text-title">{{ item.name }}</div>
          <div *ngIf="item.data && item.data['subtitle'] | appIsSet" class="mat-option-text-subtitle">
            {{ item.data['subtitle'] }}
          </div>
        </div>
      </mat-option>
    </ng-container>

    <div *ngIf="(source.loading$ | async) || !source.loaded" class="mat-panel-empty">
      {{ 'Loading...' | localize }}
    </div>
    <div
      *ngIf="!(source.loading$ | async) && source.loaded && !(source.options$ | async).length"
      class="mat-panel-empty"
    >
      <ng-container *ngIf="search">
        <ng-container *ngIf="search.length >= searchMinimumLength">{{ emptyPlaceholder }}</ng-container>
        <ng-container *ngIf="search.length < searchMinimumLength">{{
          'Enter at least {0} characters' | localize: searchMinimumLength
        }}</ng-container>
      </ng-container>
      <ng-container *ngIf="!search">{{ emptyPlaceholder }}</ng-container>
    </div>
  </ng-container>

  <ng-container *ngIf="!source">
    <mat-option
      *ngFor="let item of staticOptionsFiltered"
      [value]="item.value"
      [disabled]="item.disabled"
      [appAttr]="item.attrs"
    >
      <span
        *ngIf="item.icon"
        class="mat-menu-item-icon"
        [class.mat-menu-item-icon_opaque]="item.icon | appIsAbsoluteUrl"
        [appIcon]="item.icon"
      ></span>

      <span
        *ngIf="colorEnabled && item.color"
        class="mat-menu-item-color"
        [style.background]="(item.color | appIsSet) ? sanitizer.bypassSecurityTrustStyle(item.color) : null"
      ></span>

      <div class="mat-option-text-main">
        <div class="mat-option-text-title">
          {{ item.name }}
          <span *ngIf="item.nameAdditional | appIsSet" class="mat-option-text-title-additional">{{
            item.nameAdditional
          }}</span>
        </div>
        <div *ngIf="item.subtitle | appIsSet" class="mat-option-text-subtitle">{{ item.subtitle }}</div>
      </div>
    </mat-option>

    <div *ngIf="!staticOptionsFiltered?.length" class="mat-panel-empty">
      <ng-container *ngIf="search">{{ emptyPlaceholder }}</ng-container>
      <ng-container *ngIf="!search">{{ emptyPlaceholder }}</ng-container>
    </div>
  </ng-container>

  <ng-content
    *ngIf="(!(source?.loading$ | async) && source?.loaded !== false) || (source?.options$ | async)?.length"
    select="ng-container[dropdown-bottom]"
  ></ng-content>
</mat-select>
