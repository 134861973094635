<div
  class="theme-preview"
  [class.theme-preview_dark]="dark"
  appColorThemeOptions
  [theme]="theme"
  [applyElementStyle]="true"
  [applyGlobalStyle]="false"
>
  <div class="theme-preview__menu" [class.theme-preview__menu_light]="menuBlocks[0]?.isLight">
    <div
      *ngIf="menuBlocks[0] as block"
      class="theme-preview__menu-background"
      [style.background]="block.background"
      [style.width]="block.backgroundWidth"
      [style.height]="block.backgroundHeight"
      [style.transform]="block.backgroundTransform"
    ></div>

    <div class="theme-preview__menu-stub" [style.width.px]="16"></div>
    <div class="theme-preview__menu-stub" [style.width.px]="14"></div>
  </div>

  <div class="theme-preview__menu" [class.theme-preview__menu_light]="menuBlocks[1]?.isLight">
    <div
      *ngIf="menuBlocks[1] as block"
      class="theme-preview__menu-background"
      [style.background]="block.background"
      [style.width]="block.backgroundWidth"
      [style.height]="block.backgroundHeight"
      [style.transform]="block.backgroundTransform"
    ></div>

    <div class="theme-preview__menu-stub" [style.width.px]="10"></div>
    <div class="theme-preview__menu-stub" [style.width.px]="8"></div>
  </div>

  <div class="theme-preview__card">
    <div class="theme-preview__title">
      Heading
    </div>

    <div class="theme-preview__border"></div>

    <div class="theme-preview__text">
      Lorem ipsum dolor sit amet, consectetur
    </div>

    <div class="theme-preview__input">
      <div class="theme-preview__input-inner" [appFieldElementStyles]="fieldElementStyles">
        Type text...
      </div>
    </div>

    <div class="theme-preview__button">
      <div
        class="theme-preview__button-inner"
        [appActionElementStyles]="actionElementStyles"
        [appActionElementStylesTintColorStyle]="tintStyles.Primary"
      >
        Button
      </div>
    </div>
  </div>
</div>
