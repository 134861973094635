<div *ngIf="previewFilename" class="file-icon" [class.file-icon_compact]="compact" [class.file-icon_theme]="theme">
  <div class="file-icon__image">
    <div class="file-icon__image-text">{{ previewExtension }}</div>
  </div>
  <div class="file-icon__title">{{ previewFilename }}</div>
  <div *ngIf="additional && previewSize" class="file-icon__subtitle">
    {{ additional }} ({{ previewSize | appDomainFormatNumber: '0.00b' | async }})
  </div>
  <div *ngIf="!additional && previewSize" class="file-icon__subtitle">
    {{ previewSize | appDomainFormatNumber: '0.00b' | async }}
  </div>
</div>
