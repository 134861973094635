/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../grid-stub-item/grid-stub-item.component.ngfactory";
import * as i2 from "../grid-stub-item/grid-stub-item.component";
import * as i3 from "@angular/common";
import * as i4 from "./carousel-stub.component";
var styles_CarouselStubComponent = [];
var RenderType_CarouselStubComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CarouselStubComponent, data: {} });
export { RenderType_CarouselStubComponent as RenderType_CarouselStubComponent };
function View_CarouselStubComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-grid-stub-item", [], [[2, "grid__item", null], [2, "grid__item_snap", null], [2, "grid__item_snap-margin", null], [1, "data-card-width", 0], [4, "padding-left", "px"], [4, "padding-right", "px"], [4, "padding-top", "px"], [4, "padding-bottom", "px"]], null, null, i1.View_GridStubItemComponent_0, i1.RenderType_GridStubItemComponent)), i0.ɵdid(1, 638976, null, 0, i2.GridStubItemComponent, [i0.ElementRef], { index: [0, "index"], columns: [1, "columns"], rowCards: [2, "rowCards"], height: [3, "height"], gapHorizontal: [4, "gapHorizontal"], animating: [5, "animating"], theme: [6, "theme"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_8 = _v.context.index; var currVal_9 = _co.columns; var currVal_10 = _co.rowCards; var currVal_11 = _co.cardHeight; var currVal_12 = _co.gapHorizontal; var currVal_13 = _co.animating; var currVal_14 = _co.theme; _ck(_v, 1, 0, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).cls; var currVal_1 = i0.ɵnov(_v, 1).snapCls; var currVal_2 = i0.ɵnov(_v, 1).snapMarginCls; var currVal_3 = i0.ɵnov(_v, 1).dataGridCards; var currVal_4 = i0.ɵnov(_v, 1).paddingLeftPx; var currVal_5 = i0.ɵnov(_v, 1).paddingRightPx; var currVal_6 = i0.ɵnov(_v, 1).paddingTopPx; var currVal_7 = i0.ɵnov(_v, 1).paddingBottomPx; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
export function View_CarouselStubComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "carousel"]], [[4, "height", "px"]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [["class", "carousel__viewport"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [["class", "carousel__layout"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CarouselStubComponent_1)), i0.ɵdid(4, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.rowsRange; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.height; _ck(_v, 0, 0, currVal_0); }); }
export function View_CarouselStubComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-carousel-stub", [], null, null, null, View_CarouselStubComponent_0, RenderType_CarouselStubComponent)), i0.ɵdid(1, 638976, null, 0, i4.CarouselStubComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CarouselStubComponentNgFactory = i0.ɵccf("app-carousel-stub", i4.CarouselStubComponent, View_CarouselStubComponent_Host_0, { header: "header", columns: "columns", rows: "rows", rowCards: "rowCards", cardHeight: "cardHeight", gapHorizontal: "gapHorizontal", animating: "animating", scrollable: "scrollable", height: "height", theme: "theme" }, {}, []);
export { CarouselStubComponentNgFactory as CarouselStubComponentNgFactory };
