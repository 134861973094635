/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../color-selector-advanced/color-selector-advanced.component.ngfactory";
import * as i2 from "../color-selector-advanced/color-selector-advanced.component";
import * as i3 from "../../../../colors/services/pick-color/pick-color.service";
import * as i4 from "../../../../../core/services/local-storage/local.storage";
import * as i5 from "../../../../all-project-settings/stores/project-settings.store";
import * as i6 from "@angular/forms";
import * as i7 from "../../../../../shared/directives/form-control-selector/form-control-selector.directive";
import * as i8 from "../color-with-input-selector/color-with-input-selector.component.ngfactory";
import * as i9 from "../color-with-input-selector/color-with-input-selector.component";
import * as i10 from "@angular/common";
import * as i11 from "@angular/cdk/overlay";
import * as i12 from "@angular/cdk/bidi";
import * as i13 from "./color-button.component";
import * as i14 from "@angular/platform-browser";
var styles_ColorButtonComponent = [];
var RenderType_ColorButtonComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ColorButtonComponent, data: {} });
export { RenderType_ColorButtonComponent as RenderType_ColorButtonComponent };
function View_ColorButtonComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "app-color-selector-advanced", [], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], null, null, i1.View_ColorSelectorAdvancedComponent_0, i1.RenderType_ColorSelectorAdvancedComponent)), i0.ɵdid(1, 4440064, null, 0, i2.ColorSelectorAdvancedComponent, [i0.ElementRef, i3.PickColorService, i4.LocalStorage, i5.ProjectSettingsStore, i0.ChangeDetectorRef], { formControl: [0, "formControl"] }, null), i0.ɵprd(1024, null, i6.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.ColorSelectorAdvancedComponent]), i0.ɵdid(3, 540672, null, 0, i6.FormControlDirective, [[8, null], [8, null], [6, i6.NG_VALUE_ACCESSOR], [2, i6.ɵangular_packages_forms_forms_k]], { form: [0, "form"] }, null), i0.ɵdid(4, 81920, null, 0, i7.FormControlSelectorDirective, [i0.ElementRef, [2, i6.FormControlDirective], [2, i6.FormControlName]], null, null), i0.ɵprd(2048, null, i6.NgControl, null, [i6.FormControlDirective]), i0.ɵdid(6, 16384, null, 0, i6.NgControlStatus, [[4, i6.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.colorControl; _ck(_v, 1, 0, currVal_7); var currVal_8 = _co.colorControl; _ck(_v, 3, 0, currVal_8); _ck(_v, 4, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 6).ngClassUntouched; var currVal_1 = i0.ɵnov(_v, 6).ngClassTouched; var currVal_2 = i0.ɵnov(_v, 6).ngClassPristine; var currVal_3 = i0.ɵnov(_v, 6).ngClassDirty; var currVal_4 = i0.ɵnov(_v, 6).ngClassValid; var currVal_5 = i0.ɵnov(_v, 6).ngClassInvalid; var currVal_6 = i0.ɵnov(_v, 6).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_ColorButtonComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-color-with-input-selector", [], null, null, null, i8.View_ColorWithInputSelectorComponent_0, i8.RenderType_ColorWithInputSelectorComponent)), i0.ɵdid(1, 114688, null, 0, i9.ColorWithInputSelectorComponent, [i0.ChangeDetectorRef], { colorControl: [0, "colorControl"], colorInputEnabledControl: [1, "colorInputEnabledControl"], colorInputControl: [2, "colorInputControl"], viewContext: [3, "viewContext"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.colorControl; var currVal_1 = _co.colorInputEnabledControl; var currVal_2 = _co.colorInputControl; var currVal_3 = _co.viewContext; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_ColorButtonComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "compact-inputs-overlay overlay-content overlay-content_animated theme_dark"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ColorButtonComponent_2)), i0.ɵdid(2, 16384, null, 0, i10.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ColorButtonComponent_3)), i0.ɵdid(4, 16384, null, 0, i10.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.colorInputControl; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.colorInputControl; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_ColorButtonComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 2, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("backdropClick" === en)) {
        var pd_0 = (_co.setOpened(false) !== false);
        ad = (pd_0 && ad);
    } if (("detach" === en)) {
        var pd_1 = (_co.setOpened(false) !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_ColorButtonComponent_1)), i0.ɵdid(1, 671744, null, 0, i11.CdkConnectedOverlay, [i11.Overlay, i0.TemplateRef, i0.ViewContainerRef, i11.ɵc, [2, i12.Directionality]], { origin: [0, "origin"], positions: [1, "positions"], backdropClass: [2, "backdropClass"], panelClass: [3, "panelClass"], open: [4, "open"], hasBackdrop: [5, "hasBackdrop"], growAfterOpen: [6, "growAfterOpen"], push: [7, "push"] }, { backdropClick: "backdropClick", detach: "detach" }), i0.ɵpad(2, 2), (_l()(), i0.ɵeld(3, 0, null, null, 3, "div", [["cdkOverlayOrigin", ""], ["class", "compact-color-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setOpened(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(4, 16384, [["color_trigger", 4]], 0, i11.CdkOverlayOrigin, [i0.ElementRef], null, null), (_l()(), i0.ɵeld(5, 0, null, null, 0, "div", [["class", "compact-color-button__fill compact-color-button__fill_transparent"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 0, "div", [["class", "compact-color-button__fill"]], [[4, "background", null]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 4); var currVal_1 = _co.colorPositions; var currVal_2 = "popover2-backdrop"; var currVal_3 = _ck(_v, 2, 0, "overlay", "overlay_horizontal_center"); var currVal_4 = _co.colorOpened; var currVal_5 = true; var currVal_6 = true; var currVal_7 = true; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_8 = _co.colorBackground; _ck(_v, 6, 0, currVal_8); }); }
export function View_ColorButtonComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-color-button", [], null, null, null, View_ColorButtonComponent_0, RenderType_ColorButtonComponent)), i0.ɵdid(1, 245760, null, 0, i13.ColorButtonComponent, [i14.DomSanitizer, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ColorButtonComponentNgFactory = i0.ɵccf("app-color-button", i13.ColorButtonComponent, View_ColorButtonComponent_Host_0, { colorControl: "colorControl", colorInputEnabledControl: "colorInputEnabledControl", colorInputControl: "colorInputControl", viewContext: "viewContext" }, {}, []);
export { ColorButtonComponentNgFactory as ColorButtonComponentNgFactory };
