var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, ElementRef, EventEmitter, Injector, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import keys from 'lodash/keys';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { PopupService } from '@common/popups';
import { TableSettings, ViewContext, ViewContextElement } from '@modules/customize';
import { Model } from '@modules/models';
import { CurrentProjectStore } from '@modules/projects';
var TableActionsComponent = /** @class */ (function () {
    function TableActionsComponent(currentProjectStore, 
    // private actionStore: ActionStore,
    injector, activatedRoute, popupService, cd) {
        this.currentProjectStore = currentProjectStore;
        this.injector = injector;
        this.activatedRoute = activatedRoute;
        this.popupService = popupService;
        this.cd = cd;
        this.selectedItems = {};
        this.selectedInverse = false;
        this.displayItems = 0;
        this.totalItems = 0;
        this.theme = false;
        this.selectAllRequested = new EventEmitter();
        this.deselectRequested = new EventEmitter();
        this.reordered = new EventEmitter();
    }
    TableActionsComponent.prototype.createModel = function () {
        return Injector.create({
            providers: [{ provide: Model, deps: [Injector] }],
            parent: this.injector
        }).get(Model);
    };
    TableActionsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.activatedRoute.queryParams
            .pipe(untilDestroyed(this))
            .subscribe(function (queryParams) { return _this.updateQueryParams(queryParams); });
    };
    TableActionsComponent.prototype.ngOnDestroy = function () { };
    TableActionsComponent.prototype.ngOnChanges = function (changes) {
        this.updateQueryParams(this.activatedRoute.snapshot.queryParams);
        if (changes['selectedItems']) {
            this.selectedItemsLength = this.selectedItemKeys.length;
        }
    };
    Object.defineProperty(TableActionsComponent.prototype, "selectedMultiple", {
        get: function () {
            return ((!this.selectedInverse && this.selectedItemsLength > 1) ||
                (this.selectedInverse && this.selectedItemsLength <= this.totalItems - 1));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TableActionsComponent.prototype, "ids", {
        get: function () {
            return this.selectedItemKeys.map(function (item) { return item; }).join(',');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TableActionsComponent.prototype, "selectedItemKeys", {
        get: function () {
            return keys(this.selectedItems);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TableActionsComponent.prototype, "selectedCountOfTotal", {
        get: function () {
            if (this.selectedInverse) {
                return this.totalItems ? this.totalItems - this.selectedItemsLength : undefined;
            }
            else {
                return this.selectedItemsLength;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TableActionsComponent.prototype, "selectedAllOfTotal", {
        get: function () {
            return this.totalItems && this.totalItems == this.selectedCountOfTotal;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TableActionsComponent.prototype, "selectedAll", {
        get: function () {
            return this.displayItems && this.displayItems == this.selectedItemsLength;
        },
        enumerable: true,
        configurable: true
    });
    TableActionsComponent.prototype.updateQueryParams = function (queryParams) {
        this.params = queryParams;
        this.duplicateQueryParams = this.getDuplicateQueryParams();
        this.massChangeQueryParams = this.getMassChangeQueryParams();
        this.actionQueryParams = this.getActionQueryParams();
        this.cd.markForCheck();
    };
    TableActionsComponent.prototype.getDuplicateQueryParams = function () {
        if (this.selectedInverse || this.selectedItemsLength != 1) {
            return;
        }
        return __assign({}, this.params, { _duplicate: this.selectedItemKeys[0] });
    };
    TableActionsComponent.prototype.getMassChangeQueryParams = function () {
        return __assign({}, this.params, { ids: this.ids, inverseIds: this.selectedInverse ? this.selectedInverse : undefined });
    };
    TableActionsComponent.prototype.getActionQueryParams = function () {
        return __assign({}, this.params, { ids: this.ids });
    };
    TableActionsComponent.prototype.move = function () {
        // this.popupService.push({
        //   component: MoveComponent,
        //   popupComponent: ThinDialogPopupComponent,
        //   inputs: {
        //     modelDescription: this.modelDescription,
        //     queryParams: this.queryParams,
        //     ids: this.selectedItemKeys,
        //     inverseIds: this.selectedInverse ? this.selectedInverse : undefined
        //   },
        //   outputs: {
        //     reordered: [() => this.reordered.next()]
        //   },
        //   injector: this.injector
        // });
    };
    TableActionsComponent.prototype.onSelectAllClick = function () {
        if (this.selectedAll) {
            this.deselectRequested.emit();
        }
        else {
            this.selectAllRequested.emit();
        }
    };
    return TableActionsComponent;
}());
export { TableActionsComponent };
