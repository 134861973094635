import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable, of } from 'rxjs';
import { map, shareReplay, startWith, switchMap } from 'rxjs/operators';

import { applyValueFormatWithContext, applyValueFormatWithContext$ } from '@modules/customize';
import { Domain, DomainStore } from '@modules/domain';
import { FieldType, NumberFieldType, NumberValueFormat, registerFieldComponent, ValueFormat } from '@modules/fields';
import { CurrentProjectStore } from '@modules/projects';
import { isSet, TypedChanges } from '@shared';

import { FieldComponent } from '../field/field.component';

@Component({
  selector: 'app-number-field',
  templateUrl: 'number-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NumberFieldComponent extends FieldComponent implements OnInit, OnDestroy, OnChanges {
  valueFormat: ValueFormat;
  displayValue$: Observable<string>;
  defaultNumberFractionSeparator: string;
  defaultNumberThousandsSeparator: string;
  numberFieldTypes = NumberFieldType;

  constructor(
    @Optional() private domainStore: DomainStore,
    @Optional() private currentProjectStore: CurrentProjectStore,
    private cd: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.valueFormat = this.getValueFormat();
    this.displayValue$ = this.getDisplayValue$(this.valueFormat);

    this.getDomain$()
      .pipe(untilDestroyed(this))
      .subscribe(domain => {
        this.defaultNumberFractionSeparator = domain ? domain.numberFractionSeparator : undefined;
        this.defaultNumberThousandsSeparator = domain ? domain.numberThousandsSeparator : undefined;
        this.valueFormat = this.getValueFormat();
        this.displayValue$ = this.getDisplayValue$(this.valueFormat);
        this.cd.markForCheck();
      });
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<NumberFieldComponent>): void {
    if (changes.field) {
      this.valueFormat = this.getValueFormat();
    }

    if (changes.value || changes.form) {
      this.displayValue$ = this.getDisplayValue$(this.valueFormat);
    }
  }

  getDomain$(): Observable<Domain> {
    if (this.currentProjectStore) {
      return this.currentProjectStore.get().pipe(map(project => (project ? project.domain : undefined)));
    } else if (this.domainStore) {
      return this.domainStore.get();
    } else {
      return of(undefined);
    }
  }

  getValueFormat(): ValueFormat {
    const result = this.field.params['value_format']
      ? new ValueFormat().deserialize(this.field.params['value_format'], { editable: !this.readonly })
      : new ValueFormat({ numberFormat: NumberValueFormat.Default });

    if (isSet(this.field.params['thousands_separator'], true)) {
      result.numberThousandsSeparator = this.field.params['thousands_separator'];
    }

    this.applyValueFormatDefaults(result);

    return result;
  }

  applyValueFormatDefaults(valueFormat: ValueFormat) {
    if (!isSet(valueFormat.numberFractionSeparator) && isSet(this.defaultNumberFractionSeparator)) {
      valueFormat.numberFractionSeparator = this.defaultNumberFractionSeparator;
    }

    if (!isSet(valueFormat.numberThousandsSeparator, true) && isSet(this.defaultNumberThousandsSeparator, true)) {
      valueFormat.numberThousandsSeparator = this.defaultNumberThousandsSeparator;
    }
  }

  getDisplayValue(valueFormat: ValueFormat): string {
    let value = this.currentValue;

    if (value === null || value === undefined) {
      return value;
    }

    value = String(value);

    return applyValueFormatWithContext(value, valueFormat, {
      context: this.context,
      contextElement: this.contextElement
    });
  }

  getDisplayValue$(valueFormat: ValueFormat): Observable<string> {
    return this.currentValue$.pipe(
      switchMap(value => {
        if (value === null || value === undefined) {
          return of(value);
        }

        value = String(value);

        return applyValueFormatWithContext$(value, valueFormat, {
          context: this.context,
          contextElement: this.contextElement
        });
      }),
      startWith(this.getDisplayValue(valueFormat)),
      shareReplay(1)
    );
  }

  // TODO: Move to separate params option
  get inputSmall() {
    return this.field.params['classes'] && this.field.params['classes'].indexOf('input_small') != -1;
  }
}

registerFieldComponent(FieldType.Number, NumberFieldComponent);
