<form *ngIf="!loading" [formGroup]="form.form">
  <div class="popup-content resource-popup resource-popup_side">
    <app-popup2-side>
      <div
        *ngIf="environment"
        class="resource-popup__tag"
        [ngClass]="['color_' + environment.color + '_1', 'background-color_' + environment.color + '_2']"
      >
        {{ environment.name }}
      </div>
      <div class="resource-popup__image">
        <div
          class="resource-popup__image-inner"
          [style.background-image]="'/assets/images/resources/' + typeItem.image + '-color.svg' | appDeployCssUrl"
        ></div>
      </div>
      <div class="resource-popup__title">
        Allow Jet Admin to access <br />
        <strong [style.color]="typeItem.color">{{ typeItem.label }}</strong>
      </div>
      <div class="resource-popup__description">
        Connect Firebase to create internal tools on top of your data.
      </div>

      <div class="resource-popup__content">
        <app-page-block *ngIf="resourceNameEditing">
          <app-auto-field
            [form]="form.resourceForm"
            [field]="
              createField({
                name: 'name',
                label: 'Resource Name',
                field: 'CharField',
                description: 'This is how you and your team will see it',
                required: true,
                params: { classes: ['input_fill'] }
              })
            "
          ></app-auto-field>
        </app-page-block>

        <app-page-block>
          <div class="field">
            <label class="field__label" for="dist">Paste Service Token</label>
            <div class="field__value">
              <textarea
                class="input input_textarea input_fill"
                rows="5"
                [class.input_error]="(form.form | appFormFieldErrors: 'service_token')?.length"
                [formControlName]="'service_token'"
                [placeholder]="defaultPlaceholder || 'Insert JSON formatted service token'"
                [appAutofocus]="true"
                id="dist"
              >
              </textarea>
            </div>
            <app-field-errors [form]="form.form" [fieldName]="'service_token'"></app-field-errors>

            <input
              class="input input_file"
              type="file"
              (change)="onFileChange('service_token', $event.target)"
              id="dist_upload"
            />

            <label for="dist_upload" class="button button_small" style="margin: 10px 0 0 0;">
              <span class="icon icon-cloud_upload button__icon"></span>&nbsp;
              <span class="button__label">
                <ng-container *ngIf="form.form.value['service_token']">or Upload new file (.json)</ng-container>
                <ng-container *ngIf="!form.form.value['service_token']">or Upload file</ng-container>
              </span>
            </label>
          </div>
        </app-page-block>

        <app-page-block>
          <div class="field">
            <div class="field__label">
              Database
            </div>

            <div class="field__value">
              <div
                *ngIf="form.form.controls['service_token'].pending || databaseOptionsLoading || databaseOptionsError"
                class="input input_fill input_disabled"
              >
                <span [class.loading-animation]="form.form.controls['service_token'].pending || databaseOptionsLoading">
                  <span class="stub-text">Database 1</span>
                </span>
              </div>

              <div
                *ngIf="
                  !form.form.controls['service_token'].pending &&
                  !databaseOptionsLoading &&
                  !databaseOptionsError &&
                  !form.form.controls['service_token'].valid
                "
                class="input input_fill input_disabled"
              >
                Enter valid Service Token first
              </div>

              <app-select
                *ngIf="
                  !form.form.controls['service_token'].pending &&
                  !databaseOptionsLoading &&
                  !databaseOptionsError &&
                  form.form.controls['service_token'].valid
                "
                [placeholder]="'choose' | localize | appCapitalize"
                [control]="form.form.controls['database_option']"
                [fill]="true"
                [searchDebounce]="200"
                [options]="databaseOptions"
                [compareWith]="form.databaseOptionEquals"
              >
              </app-select>
            </div>

            <div *ngIf="databaseOptionsError" class="field__errors">
              <div class="field__error">{{ databaseOptionsError }}</div>
            </div>
          </div>
        </app-page-block>

        <!--      <div *ngIf="form.form.value['service_token']" style="padding-top: 20px">-->
        <!--        <app-auto-field [form]="form.form" [readonly]="true" [field]="createField({ name: 'project_id', label: 'Project ID', field: 'CharField' })"></app-auto-field>-->
        <!--        <app-auto-field [form]="form.form" [readonly]="true" [field]="createField({ name: 'access_token', label: 'Access Token', field: 'CharField' })"></app-auto-field>-->
        <!--      </div>-->

        <app-page-block>
          <a [appDocumentation]="'integrations/firebase'" class="button button_small" style="margin: 0;">
            Read documentation
          </a>
        </app-page-block>

        <!--          <app-page-block>-->
        <!--            <ng-template #tip>-->
        <!--              <ul style="margin: 0; padding: 0 0 0 10px;">-->
        <!--                <li>Working with large amounts of data can be slow</li>-->
        <!--                <li>Not possible to run SQL queries</li>-->
        <!--                <li>Not possible to write JOIN queries with other resources</li>-->
        <!--                <li>Sync is charged upon number of queries</li>-->
        <!--              </ul>-->
        <!--            </ng-template>-->

        <!--            <div class="toggle-button" (click)="form.form.controls['sync'].setValue(!form.form.controls['sync'].value)">-->
        <!--              <div class="toggle-button__left">-->
        <!--                <app-toggle [orange]="true" [selected]="!form.form.controls['sync'].value"> </app-toggle>-->
        <!--              </div>-->
        <!--              <div class="toggle-button__main">-->
        <!--                <div class="toggle-button__title">Work directly with {{ typeItem.label }}</div>-->
        <!--                <div class="toggle-button__subtitle">-->
        <!--                  Has fetch data limitations-->

        <!--                  <a-->
        <!--                    class="help-icon help-icon_clickable icon-help"-->
        <!--                    [appTip]="tip"-->
        <!--                    [appTipOptions]="{ side: 'top' }"-->
        <!--                  ></a>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </app-page-block>-->

        <app-page-block *ngIf="(form.form | appFormErrors).length">
          <app-resource-settings-submit-error [errors]="form.form | appFormErrors"></app-resource-settings-submit-error>
        </app-page-block>
      </div>

      <app-alert
        *ngIf="slowLoading"
        [title]="'Import in progress'"
        [warning]="true"
        [animateShow]="true"
        class="popup-content__content-alert"
      >
        <p>
          It can take 2 minutes or more depending on the size of database
        </p>
      </app-alert>

      <div class="popup-content__submit" [class.popup-content__submit_no-border]="slowLoading">
        <button
          *ngIf="resourceDelete && resource"
          type="button"
          class="button button_danger popup-content__delete"
          (click)="requestDelete()"
        >
          Delete
        </button>
        <button type="button" class="button popup-content__submit-item" (click)="cancel()">
          Cancel
        </button>

        <button
          type="button"
          class="button button_primary button_shadow popup-content__submit-item"
          [class.button_disabled]="!form.isValid() || loadingSubmit"
          (click)="resource ? submit() : chooseSync()"
        >
          <app-loader-small *ngIf="loadingSubmit" class="button__icon button__icon_left"></app-loader-small>
          <span class="button__label">
            <ng-container *ngIf="resource">Update resource</ng-container>
            <ng-container *ngIf="!resource">Add resource</ng-container>
          </span>
        </button>
      </div>

      <ng-container data-side>
        <p class="resource-popup__banner-description">
          The API Key allows us to connect to your Firebase database. Firebase > Project Settings > Service accounts >
          Generate a new private key.
        </p>
        <div
          class="resource-popup__banner-image resource-popup__banner-image_zoom"
          [appLightbox]="'/assets/images/firebase-resource-banner.gif' | appDeployUrl"
          [appLightboxOrigin]="self"
          #self
        >
          <div
            class="resource-popup__banner-image-inner"
            [style.background-image]="'/assets/images/firebase-resource-banner.gif' | appDeployCssUrl"
          ></div>
        </div>
      </ng-container>
    </app-popup2-side>
  </div>
</form>

<app-resource-settings-stub *ngIf="loading" [typeItem]="typeItem" [sideInfo]="true"></app-resource-settings-stub>
