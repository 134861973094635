import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';
import { combineLatest } from 'rxjs';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';
import { ProjectsStore } from '@modules/projects';
import { CurrentUserStore } from '@modules/users';
import { ApiService } from '../../../api/services/api/api.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../projects/stores/projects.store";
import * as i3 from "../../../users/stores/current-user.store";
import * as i4 from "../../../api/services/api/api.service";
var AuthService = /** @class */ (function () {
    function AuthService(http, projectsStore, currentUserStore, apiService) {
        this.http = http;
        this.projectsStore = projectsStore;
        this.currentUserStore = currentUserStore;
        this.apiService = apiService;
    }
    AuthService.prototype.login = function (username, password, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        var url = this.apiService.methodURL('token/');
        var data = {
            username: username,
            password: password
        };
        if (options.projectName) {
            data['project'] = options.projectName;
        }
        if (options.projectToken) {
            data['project_token'] = options.projectToken;
        }
        if (options.transferDemoProjects) {
            data['transfer_projects'] = options.transferDemoProjects.join(',');
        }
        return this.http.post(url, data).pipe(switchMap(function (result) {
            _this.apiService.saveToken({
                token: result['token'],
                accessToken: result['access_token'],
                accessTokenExpires: moment(result['access_token_expires']),
                refreshToken: result['refresh_token'],
                refreshTokenExpires: moment(result['refresh_token_expires']),
                serverTime: moment(result['server_time'])
            });
            return combineLatest([_this.currentUserStore.getFirst(true), _this.projectsStore.updateIfLoaded()]);
        }), map(function () { return true; }), this.apiService.catchApiError(false), publishLast(), refCount());
    };
    AuthService.prototype.customLogin = function (sso, username, password, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        var url = this.apiService.methodURL("custom_login/" + sso.uid + "/");
        var data = {
            username: username,
            password: password
        };
        if (options.projectName) {
            data['project'] = options.projectName;
        }
        if (options.projectToken) {
            data['project_token'] = options.projectToken;
        }
        if (options.transferDemoProjects) {
            data['transfer_projects'] = options.transferDemoProjects.join(',');
        }
        return this.http.post(url, data).pipe(switchMap(function (result) {
            _this.apiService.saveToken({
                token: result['token'],
                accessToken: result['access_token'],
                accessTokenExpires: moment(result['access_token_expires']),
                refreshToken: result['refresh_token'],
                refreshTokenExpires: moment(result['refresh_token_expires']),
                serverTime: moment(result['server_time'])
            });
            return combineLatest([_this.currentUserStore.getFirst(true), _this.projectsStore.updateIfLoaded()]);
        }), map(function () { return true; }), this.apiService.catchApiError(false), publishLast(), refCount());
    };
    AuthService.prototype.generateToken = function () {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.methodURL('token/');
            var params = { json: '1' };
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.get(url, { headers: headers, params: params });
        }), map(function (result) {
            return {
                token: result['token'],
                accessToken: result['access_token'],
                accessTokenExpires: moment(result['access_token_expires']),
                refreshToken: result['refresh_token'],
                refreshTokenExpires: moment(result['refresh_token_expires']),
                serverTime: moment(result['server_time']),
                incognito: _this.apiService.getIncognito()
            };
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    AuthService.prototype.logout = function () {
        this.apiService.deleteToken();
        this.apiService.deleteProjectToken();
        this.apiService.deleteProjectAccessTokens();
        this.currentUserStore.reset();
        this.projectsStore.updateIfLoaded();
    };
    AuthService.prototype.tokenLogin = function (tokenOptions) {
        this.saveToken(tokenOptions);
        return combineLatest([this.currentUserStore.getFirst(true), this.projectsStore.updateIfLoaded()]).pipe(map(function () { return true; }));
    };
    AuthService.prototype.saveToken = function (tokenOptions) {
        this.apiService.saveToken(tokenOptions);
    };
    AuthService.prototype.isAuthorized = function () {
        return this.currentUserStore.get().pipe(map(function (user) { return user !== undefined; }));
    };
    AuthService.prototype.isUserSelfAuthorized = function () {
        return this.currentUserStore.get().pipe(map(function (user) { return user !== undefined && !user.isAnonymous(); }));
    };
    AuthService.prototype.isUserAuthorized = function () {
        var _this = this;
        return this.isUserSelfAuthorized().pipe(map(function (authorized) { return authorized && _this.apiService.isUserToken(); }));
    };
    AuthService.prototype.isProjectAuthorized = function () {
        var _this = this;
        return this.isAuthorized().pipe(map(function (authorized) { return authorized && _this.apiService.isProjectToken(); }));
    };
    AuthService.ngInjectableDef = i0.defineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.inject(i1.HttpClient), i0.inject(i2.ProjectsStore), i0.inject(i3.CurrentUserStore), i0.inject(i4.ApiService)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
