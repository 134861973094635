import { CdkConnectedOverlay, ConnectedOverlayPositionChange, ConnectedPosition } from '@angular/cdk/overlay';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, Subscription, timer } from 'rxjs';
import { debounce, debounceTime } from 'rxjs/operators';

import { TintStyle, ViewSettingsAction } from '@modules/actions';
import { UniversalAnalyticsService } from '@modules/analytics';
import { CustomViewsStore } from '@modules/custom-views';
import { FieldName, RawListViewSettingsColumn, ViewContext, ViewContextElement } from '@modules/customize';
import { ListModelDescriptionDataSource } from '@modules/data-sources';
import { Input as FieldInput, ParameterField } from '@modules/fields';
import { FilterItem2 } from '@modules/filters';
import { ModelOption } from '@modules/filters-components';
import { CurrentProjectStore } from '@modules/projects';
import { TypedChanges } from '@shared';

export const dropdownPositions: ConnectedPosition[] = [
  {
    panelClass: ['overlay_position_bottom-right'],
    originX: 'end',
    overlayX: 'end',
    originY: 'bottom',
    overlayY: 'top',
    offsetX: 8
  },
  {
    panelClass: ['overlay_position_bottom-left'],
    originX: 'start',
    overlayX: 'start',
    originY: 'bottom',
    overlayY: 'top',
    offsetX: -8
  },
  {
    panelClass: ['overlay_position_top-right'],
    originX: 'end',
    overlayX: 'end',
    originY: 'top',
    overlayY: 'bottom',
    offsetX: 8
  },
  {
    panelClass: ['overlay_position_top-left'],
    originX: 'start',
    overlayX: 'start',
    originY: 'top',
    overlayY: 'bottom',
    offsetX: -8
  },
  {
    panelClass: ['overlay_position_left-center'],
    originX: 'start',
    overlayX: 'end',
    originY: 'center',
    overlayY: 'center'
  },
  {
    panelClass: ['overlay_position_right-center'],
    originX: 'end',
    overlayX: 'start',
    originY: 'center',
    overlayY: 'center'
  }
];

@Component({
  selector: 'app-list-actions',
  templateUrl: './list-actions.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListActionsComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() listTitle: string;
  @Input() dataSource: ListModelDescriptionDataSource;
  @Input() queryParams: Object;
  @Input() filtersEnabled = false;
  @Input() filters: FilterItem2[] = [];
  @Input() filterFields: FieldName[] = [];
  @Input() layouts = false;
  @Input() searchEnabled = false;
  @Input() search: string;
  @Input() searchLive = false;
  @Input() inputs: FieldInput[] = [];
  @Input() parameters: ParameterField[] = [];
  @Input() focus = false;
  @Input() columns: RawListViewSettingsColumn[];
  @Input() actions: ViewSettingsAction[] = [];
  @Input() compactActions = false;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() accentColor: string;
  @Input() theme = false;
  @Output() layoutAdd = new EventEmitter<void>();
  @Output() layoutCustomize = new EventEmitter<number>();
  @Output() paramsChanged = new EventEmitter<Object>();
  @Output() filtersUpdated = new EventEmitter<FilterItem2[]>();
  @Output() searchChanged = new EventEmitter<string>();

  @ViewChild(CdkConnectedOverlay) cdkConnectedOverlay: CdkConnectedOverlay;

  optionsFilter: (option: ModelOption, path: ModelOption[]) => boolean;
  pinnedActions: ViewSettingsAction[] = [];
  otherActions: ViewSettingsAction[] = [];
  otherActionsHover$ = new BehaviorSubject<boolean>(false);
  otherActionsDropdownHover$ = new BehaviorSubject<boolean>(false);
  otherActionsDropdownOpened = false;
  otherActionsDropdownPositions = dropdownPositions;
  otherActionsDropdownSubscription: Subscription;
  tintStyles = TintStyle;

  constructor(
    private analyticsService: UniversalAnalyticsService,
    private cd: ChangeDetectorRef,
    public currentProjectStore: CurrentProjectStore,
    public customViewsStore: CustomViewsStore
  ) {}

  ngOnInit(): void {
    combineLatest(
      this.otherActionsHover$.pipe(debounce(origin => timer(origin ? 0 : 100))),
      this.otherActionsDropdownHover$
    )
      .pipe(debounceTime(10), untilDestroyed(this))
      .subscribe(([hover, dropdownHovered]) => {
        if (hover && !this.otherActionsDropdownOpened) {
          this.openOtherActionsDropdown();
        } else if (!hover && !dropdownHovered && this.otherActionsDropdownOpened) {
          this.closeOtherActionsDropdown();
        }
      });
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<ListActionsComponent>): void {
    if (changes.filterFields) {
      this.optionsFilter =
        this.filterFields && this.filterFields.length
          ? option => !!this.filterFields.find(item => item.name == option.name)
          : undefined;
    }

    if (changes.actions) {
      this.updateActions();
    }
  }

  ngAfterViewInit(): void {
    this.setPositionObserver();
  }

  updateActions() {
    this.pinnedActions = this.actions.filter(item => item.pinned);
    this.otherActions = this.actions.filter(item => !item.pinned);
    this.cd.markForCheck();
    this.setPositionObserver();
  }

  openOtherActionsDropdown() {
    this.otherActionsDropdownOpened = true;
    this.cd.markForCheck();
  }

  closeOtherActionsDropdown() {
    this.otherActionsDropdownOpened = false;
    this.cd.markForCheck();
    this.otherActionsDropdownHover$.next(false);
  }

  setPositionObserver() {
    if (this.otherActionsDropdownSubscription) {
      this.otherActionsDropdownSubscription.unsubscribe();
    }

    if (!this.cdkConnectedOverlay) {
      return;
    }

    this.otherActionsDropdownSubscription = this.cdkConnectedOverlay.positionChange
      .pipe(untilDestroyed(this))
      .subscribe((e: ConnectedOverlayPositionChange) => {
        const propsEqual = ['offsetX', 'offsetY', 'originX', 'originY', 'overlayX', 'overlayY'];
        const position = this.otherActionsDropdownPositions.find(item =>
          propsEqual.every(prop => (item[prop] || undefined) == e.connectionPair[prop])
        );
        const otherPosition = this.otherActionsDropdownPositions.filter(item => item !== position);

        if (position) {
          this.cdkConnectedOverlay.overlayRef.addPanelClass(position.panelClass);
        }

        otherPosition.forEach(item => this.cdkConnectedOverlay.overlayRef.removePanelClass(item.panelClass));
      });
  }
}
