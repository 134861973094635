import { FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { controlValue, isSet } from '@shared';

import { Shadow } from './shadow';

export class ShadowControl extends FormGroup {
  instance: Shadow;

  controls: {
    color: FormControl;
    color_dark: FormControl;
    offset_x: FormControl;
    offset_y: FormControl;
    blur_radius: FormControl;
    spread_radius: FormControl;
  };

  constructor(state: Partial<Shadow> = {}) {
    super({
      color: new FormControl(isSet(state.color) ? state.color : ''),
      color_dark: new FormControl(isSet(state.colorDark) ? state.colorDark : ''),
      offset_x: new FormControl(isSet(state.offsetX) ? state.offsetX : undefined),
      offset_y: new FormControl(isSet(state.offsetY) ? state.offsetY : undefined),
      blur_radius: new FormControl(isSet(state.blurRadius) ? state.blurRadius : undefined),
      spread_radius: new FormControl(isSet(state.spreadRadius) ? state.spreadRadius : undefined)
    });
  }

  deserialize(value?: Shadow, options: { emitEvent?: boolean } = {}) {
    this.instance = value;

    this.controls.color.patchValue(value ? value.color : '', { emitEvent: options.emitEvent });
    this.controls.color_dark.patchValue(value ? value.colorDark : '', { emitEvent: options.emitEvent });
    this.controls.offset_x.patchValue(value ? value.offsetX : undefined, { emitEvent: options.emitEvent });
    this.controls.offset_y.patchValue(value ? value.offsetY : undefined, { emitEvent: options.emitEvent });
    this.controls.blur_radius.patchValue(value ? value.blurRadius : undefined, { emitEvent: options.emitEvent });
    this.controls.spread_radius.patchValue(value ? value.spreadRadius : undefined, { emitEvent: options.emitEvent });
  }

  isSet(): boolean {
    return (
      isSet(this.controls.color.value) ||
      isSet(this.controls.color_dark.value) ||
      isSet(this.controls.offset_x.value) ||
      isSet(this.controls.offset_y.value) ||
      isSet(this.controls.blur_radius.value) ||
      isSet(this.controls.spread_radius.value)
    );
  }

  isSet$(): Observable<boolean> {
    return controlValue(this).pipe(map(() => this.isSet()));
  }

  reset(options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    this.controls.color.patchValue(undefined, options);
    this.controls.color_dark.patchValue(undefined, options);
    this.controls.offset_x.patchValue(undefined, options);
    this.controls.offset_y.patchValue(undefined, options);
    this.controls.blur_radius.patchValue(undefined, options);
    this.controls.spread_radius.patchValue(undefined, options);
  }

  resetDefaults(options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    this.controls.color.patchValue('#00000080', options);
    this.controls.color_dark.patchValue('#00000080', options);
    this.controls.offset_x.patchValue(0, options);
    this.controls.offset_y.patchValue(2, options);
    this.controls.blur_radius.patchValue(4, options);
    this.controls.spread_radius.patchValue(0, options);
  }

  getInstance(instance?: Shadow): Shadow {
    if (!instance) {
      instance = new Shadow();
    }

    instance.color = isSet(this.controls.color.value) ? this.controls.color.value : undefined;
    instance.colorDark = isSet(this.controls.color_dark.value) ? this.controls.color_dark.value : undefined;
    instance.offsetX = this.controls.offset_x.value || 0;
    instance.offsetY = this.controls.offset_y.value || 0;
    instance.blurRadius = this.controls.blur_radius.value || 0;
    instance.spreadRadius = this.controls.spread_radius.value || 0;

    return instance;
  }

  serialize(reuseInstance = true): Shadow {
    if (!this.isSet()) {
      return;
    }

    return this.getInstance(reuseInstance ? this.instance : undefined);
  }

  serialize$(): Observable<Shadow> {
    return controlValue(this, { debounce: 200 }).pipe(map(() => this.serialize()));
  }
}
