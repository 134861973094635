/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./grid-stub-item.component";
var styles_GridStubItemComponent = [];
var RenderType_GridStubItemComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_GridStubItemComponent, data: {} });
export { RenderType_GridStubItemComponent as RenderType_GridStubItemComponent };
export function View_GridStubItemComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "stub-card"]], [[2, "stub-card_theme", null], [2, "loading-animation", null], [4, "height", "px"]], null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = "stub-card"; var currVal_4 = ("loading-animation_delay_" + (_co.index % 3)); _ck(_v, 1, 0, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.theme; var currVal_1 = _co.animating; var currVal_2 = _co.effectiveHeight; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_GridStubItemComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-grid-stub-item", [], [[2, "grid__item", null], [2, "grid__item_snap", null], [2, "grid__item_snap-margin", null], [1, "data-card-width", 0], [4, "padding-left", "px"], [4, "padding-right", "px"], [4, "padding-top", "px"], [4, "padding-bottom", "px"]], null, null, View_GridStubItemComponent_0, RenderType_GridStubItemComponent)), i0.ɵdid(1, 638976, null, 0, i2.GridStubItemComponent, [i0.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).cls; var currVal_1 = i0.ɵnov(_v, 1).snapCls; var currVal_2 = i0.ɵnov(_v, 1).snapMarginCls; var currVal_3 = i0.ɵnov(_v, 1).dataGridCards; var currVal_4 = i0.ɵnov(_v, 1).paddingLeftPx; var currVal_5 = i0.ɵnov(_v, 1).paddingRightPx; var currVal_6 = i0.ɵnov(_v, 1).paddingTopPx; var currVal_7 = i0.ɵnov(_v, 1).paddingBottomPx; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
var GridStubItemComponentNgFactory = i0.ɵccf("app-grid-stub-item", i2.GridStubItemComponent, View_GridStubItemComponent_Host_0, { index: "index", columns: "columns", rowCards: "rowCards", height: "height", gapHorizontal: "gapHorizontal", gapVertical: "gapVertical", animating: "animating", snap: "snap", snapMargin: "snapMargin", theme: "theme" }, {}, []);
export { GridStubItemComponentNgFactory as GridStubItemComponentNgFactory };
