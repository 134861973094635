var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormControl, FormGroup } from '@angular/forms';
import { map } from 'rxjs/operators';
import { controlValue, isSet } from '@shared';
import { Border, BorderStyle } from './border';
var BorderControl = /** @class */ (function (_super) {
    __extends(BorderControl, _super);
    function BorderControl(state) {
        if (state === void 0) { state = {}; }
        var _this = _super.call(this, {
            color: new FormControl(isSet(state.color) ? state.color : undefined),
            color_dark: new FormControl(isSet(state.colorDark) ? state.colorDark : undefined),
            thickness: new FormControl(isSet(state.thickness) ? state.thickness : undefined),
            style: new FormControl(isSet(state.style) ? state.style : BorderStyle.Solid)
        }) || this;
        _this.styleOptions = [
            {
                value: BorderStyle.Solid,
                name: 'Solid'
            },
            {
                value: BorderStyle.Dashed,
                name: 'Dashed'
            },
            {
                value: BorderStyle.Dotted,
                name: 'Dotted'
            },
            {
                value: BorderStyle.Double,
                name: 'Double'
            },
            {
                value: BorderStyle.Groove,
                name: 'Groove'
            },
            {
                value: BorderStyle.Ridge,
                name: 'Ridge'
            },
            {
                value: BorderStyle.Inset,
                name: 'Inset'
            },
            {
                value: BorderStyle.Outset,
                name: 'Outset'
            }
        ];
        return _this;
    }
    BorderControl.prototype.deserialize = function (value, options) {
        if (options === void 0) { options = {}; }
        this.instance = value;
        this.controls.color.patchValue(value ? value.color : undefined, { emitEvent: options.emitEvent });
        this.controls.color_dark.patchValue(value ? value.colorDark : undefined, { emitEvent: options.emitEvent });
        this.controls.thickness.patchValue(value ? value.thickness : undefined, { emitEvent: options.emitEvent });
        this.controls.style.patchValue((value ? value.style : undefined) || BorderStyle.Solid, {
            emitEvent: options.emitEvent
        });
    };
    BorderControl.prototype.isSet = function () {
        return (isSet(this.controls.color.value) || isSet(this.controls.color_dark.value) || isSet(this.controls.thickness.value));
    };
    BorderControl.prototype.resetDefaults = function (options) {
        this.controls.color.patchValue('#2B50ED', options);
        this.controls.color_dark.patchValue('#2B50ED', options);
        this.controls.thickness.patchValue(1, options);
        this.controls.style.patchValue(BorderStyle.Solid, options);
    };
    BorderControl.prototype.reset = function (options) {
        this.controls.color.reset(undefined, options);
        this.controls.color_dark.reset(undefined, options);
        this.controls.thickness.reset(undefined, options);
        this.controls.style.reset(BorderStyle.Solid, options);
    };
    BorderControl.prototype.getInstance = function (instance) {
        if (!instance) {
            instance = new Border();
        }
        instance.color = isSet(this.controls.color.value) ? this.controls.color.value : undefined;
        instance.colorDark = isSet(this.controls.color_dark.value) ? this.controls.color_dark.value : undefined;
        instance.thickness = this.controls.thickness.value || 0;
        instance.style = this.controls.style.value || BorderStyle.Solid;
        return instance;
    };
    BorderControl.prototype.serialize = function () {
        return this.getInstance(this.instance);
    };
    BorderControl.prototype.serialize$ = function () {
        var _this = this;
        return controlValue(this, { debounce: 200 }).pipe(map(function () { return _this.serialize(); }));
    };
    return BorderControl;
}(FormGroup));
export { BorderControl };
