/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../../charts-components/components/line-chart2/line-chart2.component.ngfactory";
import * as i3 from "../../../../charts-components/services/data-tooltip-controller/data-tooltip.controller";
import * as i4 from "../../../../charts-components/components/line-chart2/line-chart2.component";
import * as i5 from "@angular/cdk/overlay";
import * as i6 from "./value-widget-counter/value-widget-counter.component.ngfactory";
import * as i7 from "./value-widget-counter/value-widget-counter.component";
import * as i8 from "../../../../customize-shared/pipes/domain-format-number/domain-format-number.pipe";
import * as i9 from "../../../../domain/stores/domain.store";
import * as i10 from "../../../../projects/stores/current-project.store";
import * as i11 from "../../../../../common/localize/pipes/localize/localize.pipe";
import * as i12 from "../../../../../shared/directives/element/element.directive";
import * as i13 from "../../../../../core/services/document/document.service";
import * as i14 from "./value-widget.component";
import * as i15 from "../../../../projects/stores/current-environment.store";
import * as i16 from "../../../../dashboard-queries/services/widget-data-source/widget-data-source.service";
import * as i17 from "../../../../customize-generators/services/data-source-generator/data-source-generator.service";
import * as i18 from "../../../../action-queries/services/action-controller/action-controller.service";
import * as i19 from "../../../../../common/notifications/services/notification/notification.service";
var styles_ValueWidgetComponent = [];
var RenderType_ValueWidgetComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ValueWidgetComponent, data: {} });
export { RenderType_ValueWidgetComponent as RenderType_ValueWidgetComponent };
function View_ValueWidgetComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "value-widget__chart-tooltip"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", ":\u00A0", " "])), i0.ɵppd(2, 2), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.ngIf.group; var currVal_1 = i0.ɵunv(_v, 1, 1, i0.ɵnov(_v, 3).transform(i0.ɵunv(_v, 1, 1, _ck(_v, 2, 0, i0.ɵnov(_v.parent.parent.parent, 0), _v.context.ngIf.value, _co.widget.chartDataset.format)))); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_ValueWidgetComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "value-widget__chart-container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "app-line-chart2", [["class", "value-widget__chart"]], null, [[null, "groupHover"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("groupHover" === en)) {
        var pd_0 = (_co.hoverItem$.next($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_LineChart2Component_0, i2.RenderType_LineChart2Component)), i0.ɵprd(131584, null, i3.DataTooltipController, i3.DataTooltipController, [i0.Injector]), i0.ɵdid(3, 4964352, null, 0, i4.LineChart2Component, [i0.ElementRef, i5.Overlay, i3.DataTooltipController, i0.ChangeDetectorRef], { datasets: [0, "datasets"], yFormat: [1, "yFormat"], animate: [2, "animate"], xAxisVisible: [3, "xAxisVisible"], yAxisVisible: [4, "yAxisVisible"], legend: [5, "legend"], tooltips: [6, "tooltips"], lineWidth: [7, "lineWidth"] }, { groupHover: "groupHover" }), i0.ɵpad(4, 1), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_ValueWidgetComponent_3)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 4, 0, _co.chartValue); var currVal_1 = _co.widget.format; var currVal_2 = false; var currVal_3 = false; var currVal_4 = false; var currVal_5 = false; var currVal_6 = false; var currVal_7 = 2; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_8 = i0.ɵunv(_v, 6, 0, i0.ɵnov(_v, 7).transform(_co.hoverItem$)); _ck(_v, 6, 0, currVal_8); }, null); }
function View_ValueWidgetComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "value-widget dashboard-item__draggable"]], [[2, "value-widget_theme", null]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-value-widget-counter", [["class", "value-widget__counter"]], null, [[null, "reload"], [null, "valueClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("reload" === en)) {
        var pd_0 = (_co.reloadData() !== false);
        ad = (pd_0 && ad);
    } if (("valueClick" === en)) {
        var pd_1 = (_co.onClick($event.element) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i6.View_ValueWidgetCounterComponent_0, i6.RenderType_ValueWidgetCounterComponent)), i0.ɵdid(2, 770048, null, 0, i7.ValueWidgetCounterComponent, [i0.ChangeDetectorRef], { widget: [0, "widget"], value: [1, "value"], icon: [2, "icon"], compareValue: [3, "compareValue"], compareLabel: [4, "compareLabel"], growthNegative: [5, "growthNegative"], reloadEnabled: [6, "reloadEnabled"], context: [7, "context"], accentColor: [8, "accentColor"], valueClickEnabled: [9, "valueClickEnabled"] }, { valueClick: "valueClick", reload: "reload" }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ValueWidgetComponent_2)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.widget; var currVal_2 = _co.value; var currVal_3 = _co.widget.icon; var currVal_4 = _co.compareValue; var currVal_5 = _co.widget.compareName; var currVal_6 = _co.widget.compareGrowthNegative; var currVal_7 = _co.widget.showReload; var currVal_8 = _co.context; var currVal_9 = _co.accentColor; var currVal_10 = !!_co.widget.clickAction; _ck(_v, 2, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10); var currVal_11 = _co.chartValue; _ck(_v, 4, 0, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.theme; _ck(_v, 0, 0, currVal_0); }); }
function View_ValueWidgetComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "widget__error"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "div", [["class", "widget-error"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [["class", "widget-error__title"]], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), i0.ɵppd(4, 1), (_l()(), i0.ɵeld(5, 0, null, null, 1, "div", [["class", "widget-error__description"]], [[8, "title", 0]], null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i0.ɵnov(_v.parent.parent, 1), "Loading failed")); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.error; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.error; _ck(_v, 6, 0, currVal_2); }); }
function View_ValueWidgetComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "widget__error"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [["class", "widget-error"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [["class", "widget-error__title"]], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), i0.ɵppd(4, 1)], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i0.ɵnov(_v.parent.parent, 1), "Not configured")); _ck(_v, 3, 0, currVal_0); }); }
function View_ValueWidgetComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 14, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 9, "div", [["class", "value-widget dashboard-item__draggable"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 8, "div", [["class", "value-widget__counter"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 3, "div", [["class", "value-widget__label"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 2, "span", [], [[2, "loading-animation", null]], null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "span", [["class", "stub-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Value"])), (_l()(), i0.ɵeld(7, 0, null, null, 3, "div", [["class", "value-widget__value"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 2, "span", [], [[2, "loading-animation", null]], null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 1, "span", [["class", "stub-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["45"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ValueWidgetComponent_5)), i0.ɵdid(12, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ValueWidgetComponent_6)), i0.ɵdid(14, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.error; _ck(_v, 12, 0, currVal_2); var currVal_3 = (!_co.error && !_co.configured); _ck(_v, 14, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loading; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.loading; _ck(_v, 8, 0, currVal_1); }); }
export function View_ValueWidgetComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i8.DomainFormatNumberPipe, [[2, i9.DomainStore], [2, i10.CurrentProjectStore]]), i0.ɵpid(0, i11.LocalizePipe, []), (_l()(), i0.ɵeld(2, 0, null, null, 6, "div", [["appElement", ""], ["class", "widget"]], null, [[null, "appElementFirstVisible"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("appElementFirstVisible" === en)) {
        var pd_0 = (_co.firstVisible$.next(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(3, 8601600, null, 0, i12.ElementDirective, [i0.ElementRef, i13.DocumentService], { appElementDetectVisible: [0, "appElementDetectVisible"] }, { appElementFirstVisible: "appElementFirstVisible" }), (_l()(), i0.ɵeld(4, 0, null, null, 4, "div", [["class", "widget__body"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ValueWidgetComponent_1)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ValueWidgetComponent_4)), i0.ɵdid(8, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = true; _ck(_v, 3, 0, currVal_0); var currVal_1 = ((_co.configured && !_co.error) && !_co.loading); _ck(_v, 6, 0, currVal_1); var currVal_2 = ((!_co.configured || _co.error) || _co.loading); _ck(_v, 8, 0, currVal_2); }, null); }
export function View_ValueWidgetComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-value-widget", [], null, null, null, View_ValueWidgetComponent_0, RenderType_ValueWidgetComponent)), i0.ɵdid(1, 770048, null, 0, i14.ValueWidgetComponent, [i10.CurrentProjectStore, i15.CurrentEnvironmentStore, i16.WidgetDataSourceService, i17.DataSourceGeneratorService, i18.ActionControllerService, i19.NotificationService, i0.Injector, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ValueWidgetComponentNgFactory = i0.ɵccf("app-value-widget", i14.ValueWidgetComponent, View_ValueWidgetComponent_Host_0, { dashboard: "dashboard", widget: "widget", context: "context", contextElement: "contextElement", fill: "fill", accentColor: "accentColor", theme: "theme" }, {}, []);
export { ValueWidgetComponentNgFactory as ValueWidgetComponentNgFactory };
