<div class="timeline" [class.timeline_theme]="theme" [style.height.px]="height" style="overflow: hidden;">
  <div class="timeline__line-container">
    <div class="timeline__line"></div>
    <div
      *ngFor="let item of rowsRange.slice(0, -1); let first = first"
      class="timeline__item"
      [class.timeline__item_clickable]="false"
      [class.timeline__item_selected]="false"
    >
      <div class="timeline__item-inner">
        <div
          class="model-card"
          [class.model-card_vertical]="false"
          [class.model-card_compact]="false"
          [class.model-card_theme]="theme"
        >
          <div *ngFor="let column of columnsRange; let i = index">
            <div class="model-card__item">
              <label *ngIf="true" class="model-card__item-label">
                <span [class.loading-animation]="animating"><span class="stub-text">Label</span></span>
              </label>
              <div class="model-card__item-value">
                <span [class.loading-animation]="animating"><span class="stub-text">Value</span></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="timeline__item-title">
        <div class="model-card__item-value">
          <span [class.loading-animation]="animating"><span class="stub-text">date</span></span>
        </div>
      </div>
      <div class="timeline__item-dot" [class.timeline__item-dot_icon]="first" [class.icon-time]="first"></div>
    </div>
  </div>
  <div
    *ngFor="let item of rowsRange.slice(-1)"
    class="timeline__item"
    [class.timeline__item_clickable]="false"
    [class.timeline__item_selected]="false"
  >
    <div class="timeline__item-inner">
      <div
        class="model-card"
        [class.model-card_vertical]="false"
        [class.model-card_compact]="false"
        [class.model-card_theme]="theme"
      >
        <div *ngFor="let column of columnsRange; let i = index">
          <div class="model-card__item">
            <label *ngIf="true" class="model-card__item-label">
              <span [class.loading-animation]="animating"><span class="stub-text">Label</span></span>
            </label>
            <div class="model-card__item-value">
              <span [class.loading-animation]="animating"><span class="stub-text">Value</span></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="timeline__item-title">
      <div class="model-card__item-value">
        <span [class.loading-animation]="animating"><span class="stub-text">date</span></span>
      </div>
    </div>
    <div class="timeline__item-dot" [class.timeline__item-dot_icon]="false" [class.icon-time]="false"></div>
  </div>
</div>
