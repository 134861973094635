<app-popup2 [size]="'m'">
  <div class="custom-page-popup custom-page-popup_style_background custom-page-popup_border-radius_s">
    <div class="custom-page-popup__header">
      <div class="custom-page-popup__header-main">
        <div class="custom-page-popup__title">Billing users</div>
        <div class="custom-page-popup__description">
          The following users are considered when calculating plan payments. If a user appears in multiple environments,
          they will only be charged only once.
        </div>
      </div>
      <div class="custom-page-popup__header-right">
        <div class="custom-page-popup__close icon-close" (click)="close()"></div>
      </div>
    </div>

    <div class="custom-page-popup__content">
      <ng-container *ngIf="loading">
        <app-page-block *ngFor="let item of [1, 2]">
          <div class="card-table-title">
            <div class="card-table-title__main">
              <div class="card-table-title__title">
                <span [class.loading-animation]="true"><span class="stub-text">Environment</span></span>
              </div>
            </div>
          </div>

          <table class="card-table card-table_compact">
            <tbody>
              <tr *ngFor="let item of [1, 2]" class="card-table__row">
                <td class="card-table__column">
                  <div class="card-table__column-inner">
                    <span [class.loading-animation]="true"><span class="stub-text">First name</span></span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </app-page-block>
      </ng-container>

      <app-page-block *ngIf="!loading && error">
        <div *ngIf="!error" class="table__empty">
          <app-error [title]="'Loading failed' | localize">
            {{ error }}
          </app-error>
        </div>
      </app-page-block>

      <ng-container *ngIf="!loading && !error && response">
        <app-page-block *ngFor="let item of response.environments">
          <app-project-billing-details-popup-environment
            [environmentData]="item"
          ></app-project-billing-details-popup-environment>
        </app-page-block>

        <app-page-block>
          <app-stack [align]="'space-between'">
            <span>Total unique:</span>
            <strong>{{ response.uniqueCount | i18nPlural: { '=1': '# member', other: '# members' } }}</strong>
          </app-stack>
        </app-page-block>
      </ng-container>
    </div>
  </div>
</app-popup2>
