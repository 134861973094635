/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../select-model-field/select-model-field.component.ngfactory";
import * as i2 from "../select-model-field/select-model-field.component";
import * as i3 from "../../../model-queries/stores/model-description.store";
import * as i4 from "../../services/model-options-source";
import * as i5 from "../select-model-field-stub/select-model-field-stub.component.ngfactory";
import * as i6 from "../select-model-field-stub/select-model-field-stub.component";
import * as i7 from "@angular/common";
import * as i8 from "./select-data-source-field.component";
import * as i9 from "../../../projects/stores/current-environment.store";
var styles_SelectDataSourceFieldComponent = [];
var RenderType_SelectDataSourceFieldComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SelectDataSourceFieldComponent, data: {} });
export { RenderType_SelectDataSourceFieldComponent as RenderType_SelectDataSourceFieldComponent };
function View_SelectDataSourceFieldComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-select-model-field", [], null, [[null, "nameSelected"], [null, "selected"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("nameSelected" === en)) {
        var pd_0 = (_co.nameSelected.emit($event) !== false);
        ad = (pd_0 && ad);
    } if (("selected" === en)) {
        var pd_1 = (_co.selected.emit($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_SelectModelFieldComponent_0, i1.RenderType_SelectModelFieldComponent)), i0.ɵdid(2, 770048, null, 0, i2.SelectModelFieldComponent, [i3.ModelDescriptionStore, i4.ModelOptionsSource, i0.ChangeDetectorRef], { modelDescription: [0, "modelDescription"], fields: [1, "fields"], onlyFields: [2, "onlyFields"], searchPlaceholder: [3, "searchPlaceholder"], nestedFieldsEnabled: [4, "nestedFieldsEnabled"], relationsEnabled: [5, "relationsEnabled"], onlyNestedFields: [6, "onlyNestedFields"], fieldsSelectEnabled: [7, "fieldsSelectEnabled"], relationsSelectEnabled: [8, "relationsSelectEnabled"], aggregationsEnabled: [9, "aggregationsEnabled"], lookupsEnabled: [10, "lookupsEnabled"], excludeLookupsEnabled: [11, "excludeLookupsEnabled"], lookupsSelect: [12, "lookupsSelect"], optionsFilter: [13, "optionsFilter"] }, { nameSelected: "nameSelected", selected: "selected" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.modelDescription; var currVal_1 = _co.fields; var currVal_2 = _co.onlyFields; var currVal_3 = _co.searchPlaceholder; var currVal_4 = _co.nestedFieldsEnabled; var currVal_5 = _co.relationsEnabled; var currVal_6 = _co.onlyNestedFields; var currVal_7 = _co.fieldsSelectEnabled; var currVal_8 = _co.relationsSelectEnabled; var currVal_9 = _co.aggregationsEnabled; var currVal_10 = _co.lookupsEnabled; var currVal_11 = _co.excludeLookupsEnabled; var currVal_12 = _co.lookupsSelect; var currVal_13 = _co.optionsFilter; _ck(_v, 2, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13]); }, null); }
function View_SelectDataSourceFieldComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-select-model-field-stub", [], null, null, null, i5.View_SelectModelFieldStubComponent_0, i5.RenderType_SelectModelFieldStubComponent)), i0.ɵdid(2, 114688, null, 0, i6.SelectModelFieldStubComponent, [], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_SelectDataSourceFieldComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_SelectDataSourceFieldComponent_1)), i0.ɵdid(1, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SelectDataSourceFieldComponent_2)), i0.ɵdid(3, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.loading; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.loading; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_SelectDataSourceFieldComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-select-data-source-field", [], null, null, null, View_SelectDataSourceFieldComponent_0, RenderType_SelectDataSourceFieldComponent)), i0.ɵdid(1, 770048, null, 0, i8.SelectDataSourceFieldComponent, [i9.CurrentEnvironmentStore, i3.ModelDescriptionStore, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SelectDataSourceFieldComponentNgFactory = i0.ɵccf("app-select-data-source-field", i8.SelectDataSourceFieldComponent, View_SelectDataSourceFieldComponent_Host_0, { dataSource: "dataSource", searchPlaceholder: "searchPlaceholder", nestedFieldsEnabled: "nestedFieldsEnabled", relationsEnabled: "relationsEnabled", onlyNestedFields: "onlyNestedFields", fieldsSelectEnabled: "fieldsSelectEnabled", relationsSelectEnabled: "relationsSelectEnabled", aggregationsEnabled: "aggregationsEnabled", lookupsSelect: "lookupsSelect", parameterSelect: "parameterSelect", onlyVisible: "onlyVisible", optionsFilter: "optionsFilter" }, { nameSelected: "nameSelected", selected: "selected" }, []);
export { SelectDataSourceFieldComponentNgFactory as SelectDataSourceFieldComponentNgFactory };
