import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';

import { UniversalAnalyticsService } from '@modules/analytics';
import { KanbanBoardSettings } from '@modules/customize';
import { ResourceGeneratorResolver } from '@modules/resource-generators';
import { SidebarCollapseContext } from '@modules/sidebar';

import { CustomizeBarListLayoutSettingsDataSourceComponent } from '../../customize-bar-list-layout-settings/customize-bar-list-layout-settings-data-source.component';
import { CustomizeBarKanbanBoardSettingsForm } from '../customize-bar-kanban-board-settings.form';

@Component({
  selector: 'app-customize-bar-kanban-board-settings-data-source',
  templateUrl: './customize-bar-kanban-board-settings-data-source.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeBarKanbanBoardSettingsDataSourceComponent
  extends CustomizeBarListLayoutSettingsDataSourceComponent<KanbanBoardSettings>
  implements OnInit, OnDestroy {
  @Input() form: CustomizeBarKanbanBoardSettingsForm;

  collapseContext = new SidebarCollapseContext();

  constructor(
    resourceGeneratorResolver: ResourceGeneratorResolver,
    analyticsService: UniversalAnalyticsService,
    cd: ChangeDetectorRef
  ) {
    super(resourceGeneratorResolver, analyticsService, cd);
  }
}
