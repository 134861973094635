export enum ResourceName {
  JetApp = 'jet_app',
  PostgreSQL = 'postgresql',
  MySQL = 'mysql',
  MariaDB = 'mariadb',
  MicrosoftSQL = 'mssql',
  Oracle = 'oracle',
  BigQuery = 'bigquery',
  Snowflake = 'snowflake',
  Redshift = 'redshift',
  Databricks = 'databricks',
  ClickHouse = 'clickhouse',
  AlloyDB = 'alloydb',
  CockroachDB = 'cockroachdb',
  AmazonAthena = 'amazon_athena',
  Supabase = 'supabase',
  SupabaseStorage = 'supabase_storage',
  Xano = 'xano',
  SQLite = 'sqlite',
  JetDatabase = 'jet_database',
  DynamoDB = 'dynamodb',
  Firebase = 'firebase',
  Airtable = 'airtable',
  SmartSuite = 'smart_suite',
  MongoDB = 'mongo',
  Django = 'django',
  Stripe = 'stripe',
  GoogleSheets = 'googlesheets',
  Twilio = 'twilio',
  SendGrid = 'send_grid',
  OpenAI = 'openai',
  Zendesk = 'zendesk',
  HubSpot = 'hubspot',
  Pipedrive = 'pipedrive',
  Slack = 'slack',
  Mixpanel = 'mixpanel',
  Amplitude = 'amplitude',
  Intercom = 'intercom',
  Mailchimp = 'mailchimp',
  Mailgun = 'mailgun',
  Salesforce = 'salesforce',
  AmazonS3 = 'amazon_s3',
  DigitalOceanSpaces = 'do_spaces',
  S3Compatible = 's3',
  GoogleCloudStorage = 'google_cloud_storage',
  AzureBlob = 'azure_blob',
  RestApi = 'rest_api',
  GraphQL = 'graphql',
  Zapier = 'zapier',
  Integromat = 'integromat',
  MessagesApi = 'messages_api'
}
