var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { getGradientCss, GradientType } from '@modules/colors';
import { isSet } from '@shared';
import { Frame } from './frame';
import { GradientStop } from './gradient-stop';
import { Point } from './point';
var Gradient = /** @class */ (function () {
    function Gradient(options) {
        if (options === void 0) { options = {}; }
        this.type = GradientType.Linear;
        this.stops = [];
        this.aspectRatio = 1;
        Object.assign(this, options);
    }
    Gradient.prototype.deserialize = function (data) {
        if (data['type']) {
            this.type = data['type'];
        }
        if (data['from']) {
            this.from = new Point().deserialize(data['from']);
        }
        if (data['to']) {
            this.to = new Point().deserialize(data['to']);
        }
        if (data['stops']) {
            this.stops = data['stops'].map(function (item) { return new GradientStop().deserialize(item); });
        }
        if (isSet(data['aspect_ratio'])) {
            this.aspectRatio = data['aspect_ratio'];
        }
        return this;
    };
    Gradient.prototype.serialize = function () {
        return {
            type: this.type,
            from: this.from ? this.from.serialize() : undefined,
            to: this.to ? this.to.serialize() : undefined,
            stops: this.stops.map(function (item) { return item.serialize(); }),
            aspect_ratio: this.aspectRatio
        };
    };
    Gradient.prototype.css$ = function (options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        options = __assign({ frame: new Frame({ width: 1, height: 1 }) }, options);
        var stops$ = this.stops.length
            ? combineLatest(this.stops.map(function (item) {
                return item
                    .cssColor$({
                    context: options.context,
                    contextElement: options.contextElement,
                    localContext: options.localContext
                })
                    .pipe(map(function (color) {
                    return {
                        stop: item,
                        color: color
                    };
                }));
            }))
            : of([]);
        return combineLatest(stops$).pipe(map(function (_a) {
            var stops = _a[0];
            return getGradientCss({
                type: _this.type,
                from: _this.from,
                to: _this.to,
                stops: stops,
                aspectRatio: _this.aspectRatio,
                frame: options.frame
            });
        }));
    };
    return Gradient;
}());
export { Gradient };
