<app-customize-workflow-step-header
  [step]="step"
  [nameControl]="workflowEditable ? form.controls.name : undefined"
  [nameValue]="form.controls.name.value"
  [deleteEnabled]="workflowEditable"
  [executeEnabled]="workflowEditable"
  [executeLoading]="status == statuses.Executing"
  (close)="closeCustomize.emit()"
  (delete)="stepDelete.emit()"
  (execute)="execute()"
></app-customize-workflow-step-header>

<div class="sidebar__content">
  <app-sidebar-tabs [initialTabIndex]="workflowEditable ? 0 : 1">
    <app-sidebar-tab [label]="'Settings'" [contentDisabled]="!workflowEditable">
      <app-sidebar-section [disabled]="!workflowEditable">
        <app-model-description-data-source-edit
          [control]="form.controls.data_source"
          [label]="'choose collection'"
          [typeOptions]="typeOptions"
          [typeIconButton]="true"
          [typeOrange]="true"
          [queryOptionEmptyLabel]="'No collections'"
          [context]="context"
          [contextElement]="contextElement"
          [resourceClasses]="'step-data-resource'"
          [queryOptionClasses]="'step-data-collection'"
          [analyticsSource]="step.analyticsName"
        ></app-model-description-data-source-edit>

        <ng-container *ngIf="(dataConfigured$ | async) && (sortableColumnOptions$ | async) as sortableColumnOptions">
          <div *ngIf="sortableColumnOptions.length" class="sidebar__element">
            <app-sidebar-field [label]="'Sorting'" [classes]="'component-data-sorting'">
              <app-select-field
                [form]="form"
                [label]="false"
                [field]="
                  createField({
                    name: 'sorting_field',
                    field: 'SelectField',
                    params: {
                      options: sortableColumnOptions,
                      allow_empty: true,
                      empty_name: 'Not specified',
                      classes: ['select_fill']
                    }
                  })
                "
                #sorting_field
              >
              </app-select-field>
              <app-field-errors [form]="form" [fieldName]="'sorting_field'"></app-field-errors>
              <app-field-errors [form]="form" [fieldName]="'sorting_asc'"></app-field-errors>

              <ng-container right>
                <a
                  href="javascript:void(0)"
                  class="button button_bigger button_icon button_orange-alternative button_primary sidebar-field__button"
                  [class.button_disabled]="!form.controls.sorting_field.value"
                  [appTip]="'Change sorting order'"
                  (click)="form.toggleDefaultSorting()"
                >
                  <span
                    class="button__icon"
                    [class.icon-filter_down]="form.controls.sorting_asc.value"
                    [class.icon-filter_up]="!form.controls.sorting_asc.value"
                  ></span>
                </a>
              </ng-container>
            </app-sidebar-field>
          </div>
        </ng-container>
      </app-sidebar-section>

      <app-sidebar-section
        *ngIf="
          (form.controls.data_source.controls.type | appControlValue | async) == dataSourceTypes.Input ||
          (form.controls.data_source.controls.type | appControlValue | async) == dataSourceTypes.Workflow ||
          ((form.controls.data_source.controls.type | appControlValue | async) == dataSourceTypes.Query &&
            form.controls.data_source.controls.query.valid)
        "
        [title]="(form.controls.data_source.getQueryEditable$() | async) ? 'Specify Inputs' : 'Apply Filters'"
        [description]="
          (form.controls.data_source.getQueryEditable$() | async)
            ? 'Manually or use value from other components'
            : 'Static or use value from other components'
        "
      >
        <app-data-source-inputs-edit
          [control]="form.controls.data_source"
          [fieldsControl]="form.controls.data_source.controls.columns"
          [editable]="form.controls.data_source.getQueryEditable$() | async"
          [context]="context"
          [contextElement]="contextElement"
          [classes]="'step-data-inputs'"
          [itemClasses]="'step-data-inputs-item'"
          [addClasses]="'step-data-inputs-add'"
          [analyticsSource]="step.analyticsName"
          (addInput)="addQueryInput()"
        ></app-data-source-inputs-edit>
      </app-sidebar-section>
    </app-sidebar-tab>

    <app-sidebar-tab
      [label]="workflowEditable ? 'Test result' : 'Run result'"
      [error]="stepRun?.error ? (workflowEditable ? 'Test failed' : 'Run failed') : undefined"
    >
      <div *ngIf="status == statuses.Executing" class="sidebar__element">
        <app-alert [title]="'Step is executing'" [secondary]="true">
          Please wait until execution is finished
        </app-alert>
      </div>

      <ng-container *ngIf="status != statuses.Executing">
        <div *ngIf="stepRun?.params && stepRun.params['params']" class="sidebar__element">
          <app-sidebar-field [label]="'inputs used'">
            <app-json-viewer [content]="stepRun.params['params']"></app-json-viewer>
          </app-sidebar-field>
        </div>

        <div *ngIf="stepRun?.params && stepRun.params['iterate']" class="sidebar__element">
          <app-sidebar-field [label]="'items used'">
            <app-json-viewer [content]="stepRun.params['iterate']"></app-json-viewer>
          </app-sidebar-field>
        </div>

        <ng-container *ngIf="stepRun?.error | appIsSet">
          <div class="sidebar__element">
            <app-alert [title]="'Step failed with the following error'" [danger]="true" [border]="true">
              Please check if the step and all inputs are configured correctly
            </app-alert>
          </div>

          <div class="sidebar__element">
            <app-json-viewer [content]="stepRun.error"></app-json-viewer>
          </div>
        </ng-container>

        <div *ngIf="!(stepRun?.result | appIsSet) && !(stepRun?.error | appIsSet)" class="sidebar__element">
          <app-alert [title]="'Click Test Step to get result'" [secondary]="true">
            <div>You can test step to check whether your Workflow is working correctly</div>
            <div style="margin-top: 4px;">
              <a href="javascript:void(0)" (click)="execute()">Test Step</a>
            </div>
          </app-alert>
        </div>
      </ng-container>
    </app-sidebar-tab>
  </app-sidebar-tabs>
</div>
