import {
  ActionElementStyles,
  Border,
  BorderSettings,
  ElementWrapperStyles,
  FieldElementStyles,
  Fill,
  FillSettings,
  Gradient,
  GradientStop,
  IconSettings,
  Point,
  Shadow,
  TextStyle
} from '@modules/customize';
import { PartialDeep } from '@shared';

export function deserializeTextStyle(instance: PartialDeep<TextStyle> | undefined): TextStyle {
  if (!instance) {
    return;
  }

  return new TextStyle({
    fontFamily: instance.fontFamily,
    fontStyle: instance.fontStyle,
    fontSize: instance.fontSize,
    color: instance.color,
    colorDark: instance.colorDark,
    backgroundColor: instance.backgroundColor,
    backgroundColorDark: instance.backgroundColorDark,
    letterSpacing: instance.letterSpacing,
    transform: instance.transform,
    decoration: instance.decoration
  });
}

export function deserializeIconSettings(instance: PartialDeep<IconSettings> | undefined): IconSettings {
  if (!instance) {
    return;
  }

  return new IconSettings({
    color: instance.color,
    colorDark: instance.colorDark,
    size: instance.size
  });
}

export function deserializeFill(instance: PartialDeep<Fill> | undefined): Fill {
  if (!instance) {
    return;
  }

  return new Fill({
    type: instance.type,
    color: instance.color,
    ...(instance.gradient && {
      gradient: new Gradient({
        type: instance.gradient.type,
        from: new Point({
          x: instance.gradient.from.x,
          y: instance.gradient.from.y
        }),
        to: new Point({
          x: instance.gradient.to.x,
          y: instance.gradient.to.y
        }),
        stops: instance.gradient.stops.map(item => {
          const result = new GradientStop({
            id: item.id,
            position: item.position,
            color: item.color
          });

          if (!result.id) {
            result.generateId();
          }

          return result;
        }),
        aspectRatio: instance.gradient.aspectRatio
      })
    })
  });
}

export function deserializeFillSettings(instance: PartialDeep<FillSettings> | undefined): FillSettings {
  if (!instance) {
    return;
  }

  return new FillSettings({
    fill: deserializeFill(instance.fill),
    fillDark: deserializeFill(instance.fillDark)
  });
}

export function deserializeBorder(instance: PartialDeep<Border> | undefined): Border {
  if (!instance) {
    return;
  }

  return new Border({
    color: instance.color,
    colorDark: instance.colorDark,
    thickness: instance.thickness,
    style: instance.style
  });
}

export function deserializeBorderSettings(instance: PartialDeep<BorderSettings> | undefined): BorderSettings {
  if (!instance) {
    return;
  }

  const result = new BorderSettings();

  if (instance.border) {
    result.border = deserializeBorder(instance.border);
  }

  if (instance.borderTop) {
    result.borderTop = deserializeBorder(instance.borderTop);
  }

  if (instance.borderRight) {
    result.borderRight = deserializeBorder(instance.borderRight);
  }

  if (instance.borderBottom) {
    result.borderBottom = deserializeBorder(instance.borderBottom);
  }

  if (instance.borderLeft) {
    result.borderLeft = deserializeBorder(instance.borderLeft);
  }

  return result;
}

export function deserializeShadow(instance: PartialDeep<Shadow> | undefined): Shadow {
  if (!instance) {
    return;
  }

  return new Shadow({
    color: instance.color,
    colorDark: instance.colorDark,
    offsetX: instance.offsetX,
    offsetY: instance.offsetY,
    blurRadius: instance.blurRadius,
    spreadRadius: instance.spreadRadius
  });
}

export function deserializeActionElementStyles(
  instance: PartialDeep<ActionElementStyles> | undefined
): ActionElementStyles {
  if (!instance) {
    return;
  }

  const styles = new ActionElementStyles();

  styles.textStyle = deserializeTextStyle(instance.textStyle);
  styles.iconSettings = deserializeIconSettings(instance.iconSettings);
  styles.fillSettings = deserializeFillSettings(instance.fillSettings);
  styles.borderSettings = deserializeBorderSettings(instance.borderSettings);
  styles.borderRadius = instance.borderRadius;
  styles.shadow = deserializeShadow(instance.shadow);
  styles.padding = instance.padding;
  styles.margin = instance.margin;

  styles.hoverTextStyle = deserializeTextStyle(instance.hoverTextStyle);
  styles.hoverIconSettings = deserializeIconSettings(instance.hoverIconSettings);
  styles.hoverFillSettings = deserializeFillSettings(instance.hoverFillSettings);
  styles.hoverBorderSettings = deserializeBorderSettings(instance.hoverBorderSettings);
  styles.hoverShadow = deserializeShadow(instance.hoverShadow);

  styles.activeTextStyle = deserializeTextStyle(instance.activeTextStyle);
  styles.activeIconSettings = deserializeIconSettings(instance.activeIconSettings);
  styles.activeFillSettings = deserializeFillSettings(instance.activeFillSettings);
  styles.activeBorderSettings = deserializeBorderSettings(instance.activeBorderSettings);
  styles.activeShadow = deserializeShadow(instance.activeShadow);

  return styles;
}

export function deserializeFieldElementStyles(
  instance: PartialDeep<FieldElementStyles> | undefined
): FieldElementStyles {
  if (!instance) {
    return;
  }

  const styles = new FieldElementStyles();

  styles.textStyle = deserializeTextStyle(instance.textStyle);
  styles.placeholderStyle = deserializeTextStyle(instance.placeholderStyle);
  styles.labelStyle = deserializeTextStyle(instance.labelStyle);
  styles.labelAdditionalStyle = deserializeTextStyle(instance.labelAdditionalStyle);
  styles.fillSettings = deserializeFillSettings(instance.fillSettings);
  styles.borderSettings = deserializeBorderSettings(instance.borderSettings);
  styles.borderRadius = instance.borderRadius;
  styles.shadow = deserializeShadow(instance.shadow);
  styles.padding = instance.padding;
  styles.margin = instance.margin;

  styles.hoverTextStyle = deserializeTextStyle(instance.hoverTextStyle);
  styles.hoverPlaceholderStyle = deserializeTextStyle(instance.hoverPlaceholderStyle);
  styles.hoverFillSettings = deserializeFillSettings(instance.hoverFillSettings);
  styles.hoverBorderSettings = deserializeBorderSettings(instance.hoverBorderSettings);
  styles.hoverShadow = deserializeShadow(instance.hoverShadow);

  styles.focusTextStyle = deserializeTextStyle(instance.focusTextStyle);
  styles.focusPlaceholderStyle = deserializeTextStyle(instance.focusPlaceholderStyle);
  styles.focusFillSettings = deserializeFillSettings(instance.focusFillSettings);
  styles.focusBorderSettings = deserializeBorderSettings(instance.focusBorderSettings);
  styles.focusShadow = deserializeShadow(instance.focusShadow);

  styles.errorTextStyle = deserializeTextStyle(instance.errorTextStyle);
  styles.errorPlaceholderStyle = deserializeTextStyle(instance.errorPlaceholderStyle);
  styles.errorFillSettings = deserializeFillSettings(instance.errorFillSettings);
  styles.errorBorderSettings = deserializeBorderSettings(instance.errorBorderSettings);
  styles.errorShadow = deserializeShadow(instance.errorShadow);

  return styles;
}

export function deserializeElementWrapperStyles(
  instance: PartialDeep<ElementWrapperStyles> | undefined
): ElementWrapperStyles {
  if (!instance) {
    return;
  }

  const styles = new ElementWrapperStyles();

  styles.borderSettings = deserializeBorderSettings(instance.borderSettings);
  styles.borderRadius = instance.borderRadius;
  styles.shadow = deserializeShadow(instance.shadow);
  styles.margin = instance.margin;

  return styles;
}
