import { AfterViewInit, ElementRef, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Power1, TweenMax } from 'gsap';
import { isSet } from '../../utils/common/common';
var CircleProgressComponent = /** @class */ (function () {
    function CircleProgressComponent() {
        this.progress = 0;
        this.inline = false;
        this.theme = false;
        this._circleProgress = 0;
    }
    CircleProgressComponent.prototype.ngOnInit = function () {
        this.circleProgress = this.progress;
    };
    CircleProgressComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        TweenMax.to(this, 0.6, {
            circleProgress: isSet(this.progress) ? this.progress : 0.15,
            ease: Power1.easeOut,
            onUpdate: function () { return _this.updatePercentElement(); }
        });
    };
    CircleProgressComponent.prototype.ngAfterViewInit = function () {
        this.updatePercentElement();
        this.updateStroke();
    };
    Object.defineProperty(CircleProgressComponent.prototype, "circleProgress", {
        get: function () {
            return this._circleProgress;
        },
        set: function (progress) {
            if (!isSet(progress)) {
                this._circleProgress = undefined;
                return;
            }
            this._circleProgress = progress;
            this.updateStroke();
        },
        enumerable: true,
        configurable: true
    });
    CircleProgressComponent.prototype.updatePercentElement = function () {
        if (!this.percent) {
            return;
        }
        this.percent.nativeElement.innerHTML =
            isSet(this.progress) && isSet(this.circleProgress)
                ? Math.round(this.circleProgress * 100) + "%"
                : "<span class=\"stub-text\">X%</span>";
    };
    CircleProgressComponent.prototype.updateStroke = function () {
        if (!this.circle) {
            return;
        }
        var from = 0;
        var to = this._circleProgress || 0;
        var el = this.circle.nativeElement;
        var length = el.getTotalLength();
        el.style['stroke-dasharray'] = [length * (to - from), length * (1 - (to - from))].join(' ');
        el.style['stroke-dashoffset'] = -length * from;
    };
    return CircleProgressComponent;
}());
export { CircleProgressComponent };
