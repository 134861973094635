var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormControl, FormGroup, Validators } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { MarginControl } from '@modules/customize';
import { ElementConfigurationService } from '@modules/customize-configuration';
import { Input, Input as FieldInput } from '@modules/fields';
import { FieldInputControl } from '@modules/parameters';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
var CustomizeBarQrCodeEditForm = /** @class */ (function (_super) {
    __extends(CustomizeBarQrCodeEditForm, _super);
    function CustomizeBarQrCodeEditForm(currentProjectStore, currentEnvironmentStore, elementConfigurationService) {
        var _this = _super.call(this, {
            value: new FieldInputControl({ path: ['value'] }, Validators.required),
            fill_color: new FormControl(''),
            background_color: new FormControl('white'),
            visible_input: new FieldInputControl({ path: ['value'] }),
            margin: new MarginControl()
        }) || this;
        _this.currentProjectStore = currentProjectStore;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        _this.elementConfigurationService = elementConfigurationService;
        return _this;
    }
    CustomizeBarQrCodeEditForm.prototype.init = function (element, firstInit) {
        if (firstInit === void 0) { firstInit = false; }
        this.element = element;
        var value = {
            value: element.value ? element.value.serializeWithoutPath() : {},
            fill_color: element.fillColor,
            background_color: element.backgroundColor,
            visible_input: element.visibleInput ? element.visibleInput.serializeWithoutPath() : {},
            margin: element.margin
        };
        this.patchValue(value, { emitEvent: false });
        if (!firstInit) {
            this.markAsDirty();
        }
    };
    CustomizeBarQrCodeEditForm.prototype.isConfigured = function (instance) {
        return this.elementConfigurationService.isQrCodeConfigured(instance);
    };
    CustomizeBarQrCodeEditForm.prototype.submit = function () {
        var value = this.value;
        var instance = cloneDeep(this.element);
        instance.value = value['value'] ? new FieldInput().deserialize(value['value']) : undefined;
        instance.fillColor = value['fill_color'];
        instance.backgroundColor = value['background_color'];
        instance.margin = value['margin'];
        if (value['visible_input']) {
            instance.visibleInput = new Input().deserialize(value['visible_input']);
        }
        else {
            instance.visibleInput = undefined;
        }
        return instance;
    };
    return CustomizeBarQrCodeEditForm;
}(FormGroup));
export { CustomizeBarQrCodeEditForm };
