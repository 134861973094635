var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import isEqual from 'lodash/isEqual';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, of } from 'rxjs';
import { auditTime, debounceTime, filter, first, map, skip, switchMap } from 'rxjs/operators';
import { DialogService } from '@common/dialogs';
import { ActionItem, ActionType } from '@modules/actions';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { cleanElementName, ContextItem, CustomizeService, ElementType, FieldElementItem, FormElementItem, FormSubmitElementItem, ITEM_OUTPUT, SUBMIT_RESULT_OUTPUT, VALUE_OUTPUT, ViewContext, ViewContextElement, ViewContextElementType } from '@modules/customize';
import { DataSourceType } from '@modules/data-sources';
import { BooleanFieldStyle } from '@modules/field-components';
import { createFormFieldFactory, getFieldDescriptionByType, Input as FieldInput, InputValueType } from '@modules/fields';
import { QueryType } from '@modules/queries';
import { HttpResultsSection, SqlResultsSection } from '@modules/queries-components';
import { SidebarCollapseContext } from '@modules/sidebar';
import { controlValid, controlValue } from '@shared';
// TODO: Refactor import
import { ElementComponentsService } from '../../../customize-elements/services/element-components/element-components.service';
import { ElementContainerService } from '../../../customize-elements/services/element-container/element-container.service';
import { CustomizeBarEditEventType } from '../../data/customize-bar-edit-event-type';
import { CustomizeBarContext } from '../../services/customize-bar-context/customize-bar.context';
import { ElementConfiguration, trackConfigured } from '../../utils/analytics';
import { ModelDescriptionInContext } from '../customize-bar-action-edit/customize-bar-action-edit.form';
import { ModelDescriptionDataSourceEditComponent } from '../model-description-data-source-edit/model-description-data-source-edit.component';
import { CustomizeBarFormEditGetQueryComponent } from './customize-bar-form-edit-get-query/customize-bar-form-edit-get-query.component';
import { CustomizeBarFormEditForm, validateAction } from './customize-bar-form-edit.form';
var ɵ0 = ActionType.Query, ɵ1 = validateAction;
var CustomizeBarFormEditComponent = /** @class */ (function () {
    function CustomizeBarFormEditComponent(form, customizeBarContext, customizeService, elementContainerService, elementComponentsService, dialogService, cd, analyticsService) {
        var _this = this;
        this.form = form;
        this.customizeBarContext = customizeBarContext;
        this.customizeService = customizeService;
        this.elementContainerService = elementContainerService;
        this.elementComponentsService = elementComponentsService;
        this.dialogService = dialogService;
        this.cd = cd;
        this.analyticsService = analyticsService;
        this.titleEnabled = true;
        this.backLabel = 'Back';
        this.deleteEnabled = false;
        this.firstInit = false;
        this.setupOnCreate = false;
        this.event = new EventEmitter();
        this.createField = createFormFieldFactory();
        this.collapseContext = new SidebarCollapseContext();
        this.submitActionClass = ActionItem;
        this.configurationStarted = false;
        this.actionOperationConfigured = true;
        this.actionConfigured = true;
        this.operationContextElementPaths = [[ITEM_OUTPUT], [VALUE_OUTPUT]];
        this.visibleContextElementPaths = [[ITEM_OUTPUT]];
        this.completionContextElementPaths = [[ITEM_OUTPUT], [VALUE_OUTPUT], [SUBMIT_RESULT_OUTPUT]];
        this.approvalContextElementPaths = [[ITEM_OUTPUT], [VALUE_OUTPUT]];
        this.customAction = false;
        this.itemContextElementPath = [ITEM_OUTPUT];
        this.fields = [];
        this.missingFields = [];
        this.dataSourceTypes = DataSourceType;
        this.booleanFieldStyle = BooleanFieldStyle;
        this.actionTypes = ActionType;
        this.titleCleanValue = (function () {
            return function (value) {
                return cleanElementName(value, _this.element, _this.context.getElementItems());
            };
        })();
    }
    CustomizeBarFormEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.form.init(this.element, this.context, this.firstInit);
        var resultObs = this.form.valueChanges.pipe(debounceTime(200), map(function () { return _this.form.submit(); }));
        resultObs.pipe(untilDestroyed(this)).subscribe(function (result) {
            _this.result = result;
            _this.emitUpdate();
        });
        resultObs
            .pipe(switchMap(function (result) { return _this.form.isConfigured(result); }), trackConfigured(), first(function (configuration) { return configuration == ElementConfiguration.Started; }), untilDestroyed(this))
            .subscribe(function () {
            _this.configurationStarted = true;
            _this.analyticsService.sendSimpleEvent(AnalyticsEvent.Component.StartedConfiguration, {
                ComponentTypeID: _this.element.analyticsName
            });
        });
        controlValue(this.form.controls.submit_action.controls.query)
            .pipe(untilDestroyed(this))
            .subscribe(function (query) {
            _this.customAction = query && query.queryType && query.queryType != QueryType.Simple;
            _this.cd.markForCheck();
        });
        this.form.controls.submit_action
            .getActionDescription$()
            .pipe(untilDestroyed(this))
            .subscribe(function (actionDescription) {
            _this.modelAction = actionDescription ? actionDescription.modelAction : undefined;
            _this.cd.markForCheck();
        });
        this.form.controls.get_data_source
            .getModelDescription$()
            .pipe(auditTime(1000 / 60), untilDestroyed(this))
            .subscribe(function (modelDescription) {
            _this.modelDescription = modelDescription;
            _this.modelDescriptionInContext = modelDescription
                ? {
                    modelDescription: modelDescription,
                    fieldToken: function (field) {
                        if (!modelDescription.fields.find(function (item) { return item.name == field; })) {
                            return;
                        }
                        return ['item', field];
                    }
                }
                : undefined;
            _this.cd.markForCheck();
        });
        this.form.controls.get_data_source
            .getResource$()
            .pipe(skip(1), untilDestroyed(this))
            .subscribe(function (resource) {
            if (resource) {
                _this.analyticsService.sendSimpleEvent(AnalyticsEvent.ComponentData.ResourceSelected, {
                    ComponentTypeID: _this.element.analyticsName,
                    ResourceID: resource ? resource.typeItem.name : undefined,
                    ResourceDemo: resource ? resource.demo : false
                });
            }
        });
        combineLatest(this.form.controls.get_data_source.getResource$().pipe(skip(1)), this.form.controls.get_data_source.getModelDescription$().pipe(skip(1)))
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var resource = _a[0], modelDescription = _a[1];
            if (modelDescription) {
                _this.analyticsService.sendSimpleEvent(AnalyticsEvent.ComponentData.ResourceItemSelected, {
                    ComponentTypeID: _this.element.analyticsName,
                    ResourceID: resource ? resource.typeItem.name : undefined,
                    ResourceDemo: resource ? resource.demo : false
                });
            }
        });
        this.dataConfigured$ = controlValid(this.form.controls.get_data_source);
        this.dataQueryConfigured$ = this.form.controls.get_data_source.getDataConfigured$();
        this.operationValid$ = this.form.controls.submit_action.operationValid$();
        this.actionsValid$ = this.form.controls.submit_action.actionsValid$();
        this.operationValid$.pipe(untilDestroyed(this)).subscribe(function () {
            // TODO: Fix for change detection
            _this.cd.markForCheck();
        });
        this.form
            .submitActionOperationValid$()
            .pipe(untilDestroyed(this))
            .subscribe(function (configured) {
            _this.actionOperationConfigured = configured;
            _this.cd.markForCheck();
        });
        this.form
            .submitActionValid$()
            .pipe(untilDestroyed(this))
            .subscribe(function (configured) {
            _this.actionConfigured = configured;
            _this.cd.markForCheck();
        });
        controlValid(this.form.controls.get_data_source.controls.query_resource)
            .pipe(filter(function (configured, i) { return configured && i > 0; }), untilDestroyed(this))
            .subscribe(function () {
            _this.analyticsService.sendSimpleEvent(AnalyticsEvent.ComponentData.ResourceItemSuccessfullySetUp, {
                ComponentTypeID: _this.element.analyticsName
            });
        });
        controlValid(this.form.controls.get_data_source.controls.query_inputs)
            .pipe(filter(function (configured, i) { return configured && i > 0; }), untilDestroyed(this))
            .subscribe(function () {
            _this.analyticsService.sendSimpleEvent(AnalyticsEvent.ComponentData.InputsSuccessfullySetUp, {
                ComponentTypeID: _this.element.analyticsName
            });
        });
        this.form.statusChanges.pipe(untilDestroyed(this)).subscribe(function () { return _this.cd.markForCheck(); });
        this.initFields();
    };
    CustomizeBarFormEditComponent.prototype.ngOnDestroy = function () { };
    CustomizeBarFormEditComponent.prototype.openGetQuery = function (options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        this.form.enableGetQuery();
        this.customizeBarContext.appendSettingsComponent({
            component: CustomizeBarFormEditGetQueryComponent,
            inputs: {
                element: this.element,
                name: this.form.controls.name.value,
                form: this.form,
                context: this.context,
                contextElement: this.contextElement,
                contextElementPath: this.contextElementPath,
                contextElementPaths: this.contextElementPaths,
                setupOnCreate: options.setupOnCreate,
                addInput: options.addInput
            },
            outputs: {
                event: [
                    function () {
                        _this.customizeBarContext.popSettingsComponent();
                    }
                ]
            }
        });
    };
    CustomizeBarFormEditComponent.prototype.close = function () {
        var _this = this;
        (this.configurationStarted ? this.form.isConfigured(this.result) : of(false))
            .pipe(untilDestroyed(this))
            .subscribe(function (configured) {
            if (configured) {
                _this.analyticsService.sendSimpleEvent(AnalyticsEvent.Component.SuccessfullyConfigured, {
                    ComponentTypeID: _this.element.analyticsName
                });
            }
            _this.customizeBarContext.popSettingsComponent();
        });
    };
    CustomizeBarFormEditComponent.prototype.submit = function () {
        this.result = this.form.submit();
        this.emitUpdate(true);
        this.close();
    };
    CustomizeBarFormEditComponent.prototype.onTitleChanged = function (name) {
        this.form.controls.name.setValue(name);
    };
    CustomizeBarFormEditComponent.prototype.onApprovalOpenedChanged = function (opened) {
        if (opened) {
            this.form.controls.submit_action.controls.approve_enabled.patchValue(true);
            this.form.markAsDirty();
        }
    };
    CustomizeBarFormEditComponent.prototype.emitUpdate = function (submit) {
        if (submit === void 0) { submit = false; }
        // TODO: Add args interface
        var args = { result: this.result, submit: submit };
        if (this.element) {
            this.event.emit({ type: CustomizeBarEditEventType.Updated, args: args });
        }
        else {
            this.event.emit({ type: CustomizeBarEditEventType.Created, args: args });
        }
    };
    CustomizeBarFormEditComponent.prototype.cancel = function () {
        this.event.emit({ type: CustomizeBarEditEventType.Canceled });
        this.close();
    };
    CustomizeBarFormEditComponent.prototype.delete = function () {
        this.event.emit({ type: CustomizeBarEditEventType.Deleted });
        this.close();
    };
    CustomizeBarFormEditComponent.prototype.initFields = function () {
        var _this = this;
        combineLatest(this.context.elements$, controlValue(this.form.controls.submit_action.controls.inputs), this.form.controls.submit_action.inputFieldProvider.getFields$())
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var elements = _a[0], inputs = _a[1], actionFields = _a[2];
            _this.fields = _this.getFields(elements, inputs);
            _this.missingFields = _this.getMissingFields(inputs, actionFields);
            _this.cd.markForCheck();
        });
    };
    CustomizeBarFormEditComponent.prototype.getFields = function (elements, inputs) {
        var _this = this;
        var fieldElements = elements.reduce(function (acc, contextItem) {
            if (contextItem.element.type == ViewContextElementType.Element &&
                contextItem.element.element instanceof FieldElementItem) {
                var path = _this.context.getElementPath(contextItem.element);
                var contextValue = path.concat([VALUE_OUTPUT]);
                acc[JSON.stringify(contextValue)] = contextItem.element.element;
            }
            return acc;
        }, {});
        return inputs.reduce(function (acc, input) {
            var fieldElement = input && input.valueType == InputValueType.Context
                ? fieldElements[JSON.stringify(input.contextValue)]
                : undefined;
            if (fieldElement && fieldElement instanceof FieldElementItem) {
                acc.push(fieldElement);
            }
            return acc;
        }, []);
    };
    CustomizeBarFormEditComponent.prototype.getMissingFields = function (inputs, actionFields) {
        var _this = this;
        var selfPath = this.context.getElementPath(this.contextElement);
        if (!selfPath) {
            return [];
        }
        return actionFields
            .filter(function (actionField) {
            return !_this.fields.find(function (element) {
                var contextElement = _this.context.elements.find(function (item) { return item.element.element === element; });
                if (!contextElement) {
                    return false;
                }
                var path = _this.context.getElementPath(contextElement.element);
                var contextValue = path.concat([VALUE_OUTPUT]);
                var input = inputs.find(function (item) { return item.valueType == InputValueType.Context && isEqual(item.contextValue, contextValue); });
                return input && input.isName(actionField.name);
            });
        })
            .map(function (field) {
            var fieldDescription = getFieldDescriptionByType(field.field);
            var valueInput;
            if (_this.form.controls.get_enabled.value) {
                valueInput = new FieldInput();
                valueInput.path = ['value'];
                valueInput.valueType = InputValueType.Context;
                valueInput.contextValue = selfPath.concat([ITEM_OUTPUT, field.name]);
            }
            return {
                title: field.verboseName || field.name,
                icon: fieldDescription.icon,
                image: fieldDescription.image,
                type: ElementType.Field,
                defaultName: field.verboseName || field.name,
                defaultParams: __assign({ settings: {
                        field: field.field,
                        verboseName: field.verboseName || field.name,
                        editable: true,
                        params: field.params,
                        valueInput: valueInput ? valueInput.serialize() : undefined
                    } }, (field.params || {})),
                postCreate: function (element) {
                    var currentInputs = _this.form.controls.submit_action.controls.inputs.value;
                    var input = new FieldInput();
                    var path = ['elements', element.uid];
                    input.path = [field.name];
                    input.valueType = InputValueType.Context;
                    input.contextValue = path.concat([VALUE_OUTPUT]);
                    var newInputs = currentInputs.filter(function (item) { return !item.isName(field.name); }).concat([input]);
                    _this.form.controls.submit_action.controls.inputs.patchValue(newInputs);
                    return element;
                }
            };
        });
    };
    CustomizeBarFormEditComponent.prototype.customizeField = function (element) {
        var component = this.elementComponentsService.get(element);
        if (component) {
            component.customize({ append: true });
        }
    };
    CustomizeBarFormEditComponent.prototype.deleteField = function (element) {
        var _this = this;
        return this.dialogService
            .warning({
            title: 'Deleting',
            description: "Are you sure want to delete Field <strong>" + (element.formField.label || element.name) + "</strong>?"
        })
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            if (!result) {
                return of(false);
            }
            _this.deleteFieldProcess(element);
        });
    };
    CustomizeBarFormEditComponent.prototype.deleteFieldProcess = function (element) {
        this.result = this.form.submit();
        var children = this.result.children.filter(function (item) { return item.uid != element.uid; });
        this.form.controls.children.patchValue(children);
        var contextElement = this.context.elements.find(function (item) { return item.element.element === element; });
        if (!contextElement) {
            return;
        }
        var path = this.context.getElementPath(contextElement.element);
        var contextValue = path.concat([VALUE_OUTPUT]);
        var inputs = this.form.controls.submit_action.controls.inputs.value;
        var input = inputs.find(function (item) { return item.valueType == InputValueType.Context && isEqual(item.contextValue, contextValue); });
        if (!input) {
            return;
        }
        var currentInputs = this.form.controls.submit_action.controls.inputs.value;
        var parameters = this.form.getFormParameters().map(function (item) { return item.parameter; });
        var parameter = parameters.find(function (item) { return input.isName(item.name); });
        var isRequired = parameter ? parameter.required : false;
        if (isRequired && this.form.controls.get_enabled.value) {
            var newInput = new FieldInput();
            newInput.path = input.path.slice();
            newInput.valueType = InputValueType.Context;
            newInput.contextValue = [ITEM_OUTPUT].concat(input.path);
            var newInputs = currentInputs.filter(function (item) { return !item.isPath(input.path); }).concat([newInput]);
            this.form.controls.submit_action.controls.inputs.patchValue(newInputs);
        }
        else {
            var newInputs = currentInputs.filter(function (item) { return !item.isPath(input.path); });
            this.form.controls.submit_action.controls.inputs.patchValue(newInputs);
        }
    };
    CustomizeBarFormEditComponent.prototype.addField = function (barItem) {
        this.result = this.form.submit();
        var submitIndex = this.result.children.findIndex(function (item) { return item instanceof FormSubmitElementItem; });
        var children = this.result.children.slice();
        var element = this.elementContainerService.insertElementItem(barItem, children, this.context, submitIndex !== -1 ? submitIndex : children.length);
        this.customizeService.registerCreatedElement(element, barItem);
        this.form.controls.children.patchValue(children);
    };
    CustomizeBarFormEditComponent.prototype.addQueryInput = function () {
        if (this.dataSourceEditComponent) {
            this.dataSourceEditComponent.editQuery({
                initialHttpResultsSection: HttpResultsSection.Parameters,
                initialSqlResultsSection: SqlResultsSection.Parameters
            });
        }
    };
    return CustomizeBarFormEditComponent;
}());
export { CustomizeBarFormEditComponent };
export { ɵ0, ɵ1 };
