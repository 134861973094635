import { FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { controlValue, isSet } from '@shared';

import { IconSettings } from './icon-settings';

export class IconSettingsControl extends FormGroup {
  instance: IconSettings;

  controls: {
    color: FormControl;
    color_dark: FormControl;
    size: FormControl;
  };

  constructor(state: Partial<IconSettings> = {}) {
    super({
      size: new FormControl(isSet(state.size) ? state.size : undefined),
      color: new FormControl(isSet(state.color) ? state.color : ''),
      color_dark: new FormControl(isSet(state.colorDark) ? state.colorDark : '')
    });
  }

  deserialize(value?: IconSettings, options: { emitEvent?: boolean } = {}) {
    this.instance = value;

    this.controls.size.patchValue(value ? value.size : undefined, { emitEvent: options.emitEvent });
    this.controls.color.patchValue(value ? value.color : '', { emitEvent: options.emitEvent });
    this.controls.color_dark.patchValue(value ? value.colorDark : '', { emitEvent: options.emitEvent });
  }

  isSet(): boolean {
    return isSet(this.controls.size.value) || isSet(this.controls.color.value) || isSet(this.controls.color_dark.value);
  }

  isSet$(): Observable<boolean> {
    return controlValue(this).pipe(map(() => this.isSet()));
  }

  reset(options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    this.controls.size.patchValue(undefined, options);
    this.controls.color.patchValue(undefined, options);
    this.controls.color_dark.patchValue(undefined, options);
  }

  getInstance(instance?: IconSettings): IconSettings {
    if (!instance) {
      instance = new IconSettings();
    }

    instance.size = isSet(this.controls.size.value) ? this.controls.size.value : undefined;
    instance.color = isSet(this.controls.color.value) ? this.controls.color.value : undefined;
    instance.colorDark = isSet(this.controls.color_dark.value) ? this.controls.color_dark.value : undefined;

    return instance;
  }

  serialize(reuseInstance = true): IconSettings {
    if (!this.isSet()) {
      return;
    }

    return this.getInstance(reuseInstance ? this.instance : undefined);
  }

  serialize$(): Observable<IconSettings> {
    return controlValue(this, { debounce: 200 }).pipe(map(() => this.serialize()));
  }
}
