/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../common/dynamic-component/components/dynamic/dynamic.component.ngfactory";
import * as i2 from "../../../../../common/dynamic-component/components/dynamic/dynamic.component";
import * as i3 from "@angular/common";
import * as i4 from "ng-gxscrollable";
import * as i5 from "./workflow-sidebar.component";
import * as i6 from "../../../services/customize-bar-context/customize-bar.context";
var styles_WorkflowSidebarComponent = [];
var RenderType_WorkflowSidebarComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_WorkflowSidebarComponent, data: {} });
export { RenderType_WorkflowSidebarComponent as RenderType_WorkflowSidebarComponent };
function View_WorkflowSidebarComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [["class", "sidebar-backdrop"]], null, null, null, null, null))], null, null); }
function View_WorkflowSidebarComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i0.ɵeld(1, 16777216, null, null, 1, "app-dynamic-component", [], null, null, null, i1.View_DynamicComponent_0, i1.RenderType_DynamicComponent)), i0.ɵdid(2, 442368, null, 0, i2.DynamicComponent, [i0.ElementRef, i0.Injector, i0.ComponentFactoryResolver, i0.ApplicationRef, i0.ViewContainerRef], { componentData: [0, "componentData"] }, null)], function (_ck, _v) { var currVal_1 = _v.context.$implicit; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = !_v.context.last; _ck(_v, 0, 0, currVal_0); }); }
export function View_WorkflowSidebarComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(402653184, 1, { root: 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_WorkflowSidebarComponent_1)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 5, "div", [["class", "sidebar"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, [[1, 0], ["root", 1]], null, 4, "div", [["class", "sidebar__scrollable"], ["xsScrollable", ""]], null, null, null, null, null)), i0.ɵdid(5, 12664832, null, 0, i4.ScrollableDirective, [i0.ElementRef], { xsScrollableOptions: [0, "xsScrollableOptions"] }, null), i0.ɵpod(6, { stopPropagationAlways: 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_WorkflowSidebarComponent_2)), i0.ɵdid(8, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.lastSettingsComponent == null) ? null : _co.lastSettingsComponent.modal); _ck(_v, 2, 0, currVal_0); var currVal_1 = _ck(_v, 6, 0, true); _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.settingsComponents; _ck(_v, 8, 0, currVal_2); }, null); }
export function View_WorkflowSidebarComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-workflow-sidebar", [], null, null, null, View_WorkflowSidebarComponent_0, RenderType_WorkflowSidebarComponent)), i0.ɵdid(1, 245760, null, 0, i5.WorkflowSidebarComponent, [i6.CustomizeBarContext, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WorkflowSidebarComponentNgFactory = i0.ɵccf("app-workflow-sidebar", i5.WorkflowSidebarComponent, View_WorkflowSidebarComponent_Host_0, {}, {}, []);
export { WorkflowSidebarComponentNgFactory as WorkflowSidebarComponentNgFactory };
