var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormControl, FormGroup } from '@angular/forms';
import { Input } from '@modules/fields';
import { FieldInputControl } from '@modules/parameters';
import { Shadow, ShadowPosition } from '@modules/views';
import { isSet } from '@shared';
import { ColorControl } from './color.control';
var ShadowControl = /** @class */ (function (_super) {
    __extends(ShadowControl, _super);
    function ShadowControl(state) {
        if (state === void 0) { state = {}; }
        var _this = _super.call(this, {
            color: new ColorControl(isSet(state.color) ? state.color : {}),
            color_input_enabled: new FormControl(isSet(state.colorInput) ? !!state.colorInput : false),
            color_input: new FieldInputControl({ path: ['value'] }),
            position: new FormControl(isSet(state.position) ? state.position : ShadowPosition.Outside),
            offset_x: new FormControl(isSet(state.offsetX) ? state.offsetX : 0),
            offset_y: new FormControl(isSet(state.offsetY) ? state.offsetY : 2),
            blur_radius: new FormControl(isSet(state.blurRadius) ? state.blurRadius : 4),
            spread_radius: new FormControl(isSet(state.spreadRadius) ? state.spreadRadius : 0),
            enabled: new FormControl(isSet(state.enabled) ? state.enabled : true),
            enabled_input: new FieldInputControl({ path: ['value'] })
        }) || this;
        _this.positionOptions = [
            {
                value: ShadowPosition.Outside,
                name: 'Outside'
            },
            {
                value: ShadowPosition.Inside,
                name: 'Inside'
            }
        ];
        return _this;
    }
    ShadowControl.prototype.deserialize = function (value, options) {
        if (options === void 0) { options = {}; }
        this.instance = value;
        if (value.color) {
            this.controls.color.deserialize(value.color, { emitEvent: options.emitEvent });
        }
        this.controls.color_input_enabled.patchValue(!!value.colorInput, { emitEvent: options.emitEvent });
        this.controls.color_input.patchValue(value.colorInput ? value.colorInput.serializeWithoutPath() : {}, {
            emitEvent: options.emitEvent
        });
        this.controls.position.patchValue(value.position || ShadowPosition.Outside, { emitEvent: options.emitEvent });
        this.controls.offset_x.patchValue(value.offsetX, { emitEvent: options.emitEvent });
        this.controls.offset_y.patchValue(value.offsetY, { emitEvent: options.emitEvent });
        this.controls.blur_radius.patchValue(value.blurRadius, { emitEvent: options.emitEvent });
        this.controls.spread_radius.patchValue(value.spreadRadius, { emitEvent: options.emitEvent });
        this.controls.enabled.patchValue(value.enabled, { emitEvent: options.emitEvent });
        this.controls.enabled_input.patchValue(value.enabledInput ? value.enabledInput.serializeWithoutPath() : {});
    };
    ShadowControl.prototype.getInstance = function (instance) {
        if (!instance) {
            instance = new Shadow();
        }
        if (this.controls.color_input_enabled.value) {
            instance.color = this.controls.color.getInstance(instance.color);
            instance.colorInput = this.controls.color_input.value
                ? new Input().deserialize(this.controls.color_input.value)
                : undefined;
        }
        else {
            instance.color = this.controls.color.getInstance(instance.color);
            instance.colorInput = undefined;
        }
        instance.color = this.controls.color.getInstance(instance.color);
        instance.position = this.controls.position.value;
        instance.offsetX = this.controls.offset_x.value;
        instance.offsetY = this.controls.offset_y.value;
        instance.blurRadius = this.controls.blur_radius.value;
        instance.spreadRadius = this.controls.spread_radius.value;
        instance.enabled = this.controls.enabled.value;
        instance.enabledInput = this.controls.enabled_input.value
            ? new Input().deserialize(this.controls.enabled_input.value)
            : undefined;
        return instance;
    };
    ShadowControl.prototype.serialize = function () {
        return this.getInstance(this.instance);
    };
    return ShadowControl;
}(FormGroup));
export { ShadowControl };
