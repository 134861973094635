var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { registerElementForType } from '../element-items';
import { ElementType } from '../element-type';
import { ElementItem } from './base';
import { TabsLayoutItem } from './tabs-layout-item';
export var TabsOrientation;
(function (TabsOrientation) {
    TabsOrientation["Horizontal"] = "horizontal";
    TabsOrientation["Vertical"] = "vertical";
})(TabsOrientation || (TabsOrientation = {}));
export var TabsAppearance;
(function (TabsAppearance) {
    TabsAppearance["Link"] = "link";
    TabsAppearance["Button"] = "button";
})(TabsAppearance || (TabsAppearance = {}));
export var TabsStyle;
(function (TabsStyle) {
    TabsStyle["Wrap"] = "wrap";
    TabsStyle["Background"] = "background";
})(TabsStyle || (TabsStyle = {}));
var TabsLayoutElementItem = /** @class */ (function (_super) {
    __extends(TabsLayoutElementItem, _super);
    function TabsLayoutElementItem() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.type = ElementType.Tabs;
        _this.items = [];
        _this.orientation = TabsOrientation.Horizontal;
        _this.appearance = TabsAppearance.Link;
        _this.style = TabsStyle.Wrap;
        _this.loadInvisible = false;
        _this.loadOnShow = false;
        return _this;
    }
    TabsLayoutElementItem.prototype.deserialize = function (data) {
        _super.prototype.deserialize.call(this, data);
        if (this.params['items']) {
            this.items = this.params['items'].map(function (item) { return new TabsLayoutItem().deserialize(item); });
        }
        if (this.params['orientation']) {
            this.orientation = this.params['orientation'];
        }
        if (this.params['appearance']) {
            this.appearance = this.params['appearance'];
        }
        if (this.params['style']) {
            this.style = this.params['style'];
        }
        if (this.params['load_invisible'] != undefined) {
            this.loadInvisible = this.params['load_invisible'];
        }
        if (this.params['load_on_show'] != undefined) {
            this.loadOnShow = this.params['load_on_show'];
        }
        return this;
    };
    TabsLayoutElementItem.prototype.serialize = function () {
        var data = _super.prototype.serialize.call(this);
        data['params']['items'] = this.items.map(function (item) { return item.serialize(); });
        data['params']['orientation'] = this.orientation;
        data['params']['appearance'] = this.appearance;
        data['params']['style'] = this.style;
        data['params']['load_invisible'] = this.loadInvisible;
        data['params']['load_on_show'] = this.loadOnShow;
        return data;
    };
    Object.defineProperty(TabsLayoutElementItem.prototype, "analyticsName", {
        get: function () {
            return 'tabs';
        },
        enumerable: true,
        configurable: true
    });
    TabsLayoutElementItem.prototype.defaultName = function () {
        return 'Tabs';
    };
    TabsLayoutElementItem.prototype.childrenCount = function () {
        return this.items.reduce(function (acc, item) { return acc + item.children.length; }, 0);
    };
    return TabsLayoutElementItem;
}(ElementItem));
export { TabsLayoutElementItem };
registerElementForType(ElementType.Tabs, TabsLayoutElementItem);
