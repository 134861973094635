var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AfterViewInit, ChangeDetectorRef, Injector, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import isEqual from 'lodash/isEqual';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { of } from 'rxjs';
import { delay, distinctUntilChanged, map } from 'rxjs/operators';
import { SelectComponent, SelectSegment } from '@common/select';
import { ElementActionsPosition, modelFieldToDisplayField } from '@modules/customize';
import { applyParamInputs$, FieldType, Input, registerFieldComponent, transformFieldForeignKey } from '@modules/fields';
import { EMPTY_FILTER_VALUES } from '@modules/filters';
import { ModelDescriptionStore, ModelUtilsService, ReducedModelService } from '@modules/model-queries';
import { fromLegacyModel, Model } from '@modules/models';
import { ModelSelectSource } from '@modules/models-list';
import { CurrentEnvironmentStore } from '@modules/projects';
import { ListModelDescriptionQuery, ModelDescriptionQuery, QueryType } from '@modules/queries';
import { prepareDataSourceColumnForGet } from '@modules/resources';
import { isSet } from '@shared';
import { FieldComponent } from '../field/field.component';
var ForeignKeyFieldComponent = /** @class */ (function (_super) {
    __extends(ForeignKeyFieldComponent, _super);
    function ForeignKeyFieldComponent(currentEnvironmentStore, source, injector, reducedModelService, modelDescriptionStore, modelUtilsService, cd) {
        var _this = _super.call(this) || this;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        _this.source = source;
        _this.injector = injector;
        _this.reducedModelService = reducedModelService;
        _this.modelDescriptionStore = modelDescriptionStore;
        _this.modelUtilsService = modelUtilsService;
        _this.cd = cd;
        _this.valueStrLoading = false;
        _this.topActions = [];
        _this.bottomActions = [];
        return _this;
    }
    ForeignKeyFieldComponent.prototype.createModel = function () {
        return Injector.create({
            providers: [{ provide: Model, deps: [Injector] }],
            parent: this.injector
        }).get(Model);
    };
    ForeignKeyFieldComponent.prototype.ngOnInit = function () {
        this.initSource();
        this.initValue();
        this.initModelDescription();
        this.initForm();
        this.updateButtons();
        this.updateActions();
    };
    ForeignKeyFieldComponent.prototype.ngOnDestroy = function () { };
    ForeignKeyFieldComponent.prototype.ngOnChanges = function (changes) {
        if (changes['field']) {
            this.initSource();
            this.initModelDescription();
        }
        if (changes['value']) {
            this.initValue();
        }
        if (changes['form']) {
            this.initForm();
        }
        this.updateButtons();
        this.updateActions();
    };
    ForeignKeyFieldComponent.prototype.ngAfterViewInit = function () {
        if (this.autofocus) {
            this.open();
        }
    };
    ForeignKeyFieldComponent.prototype.initForm = function () {
        var _this = this;
        if (this.formSubscription) {
            this.formSubscription.unsubscribe();
        }
        if (this.form && this.field && this.form.controls[this.field.name]) {
            this.formSubscription = this.form.controls[this.field.name].valueChanges
                .pipe(delay(0), untilDestroyed(this))
                .subscribe(function () {
                _this.updateButtons();
                _this.initValue();
            });
        }
    };
    ForeignKeyFieldComponent.prototype.initSource = function () {
        var _this = this;
        if (this.updateFieldSubscription) {
            this.updateFieldSubscription.unsubscribe();
        }
        if (!this.field) {
            return;
        }
        var modelId = this.field.params['related_model']
            ? fromLegacyModel(this.field.params['related_model']['model'])
            : undefined;
        this.modelDescriptionStore
            .getDetailFirst(modelId)
            .pipe(untilDestroyed(this))
            .subscribe(function (modelDescription) {
            if (!modelDescription) {
                return;
            }
            var resource = _this.currentEnvironmentStore.resources.find(function (item) { return item.uniqueName == modelDescription.resource; });
            if (!resource) {
                return;
            }
            var listQuery = new ListModelDescriptionQuery();
            listQuery.queryType = QueryType.Simple;
            listQuery.simpleQuery = new listQuery.simpleQueryClass();
            listQuery.simpleQuery.model = modelDescription.model;
            var detailQuery;
            if (modelDescription.getDetailQuery) {
                detailQuery = new ModelDescriptionQuery();
                detailQuery.queryType = QueryType.Simple;
                detailQuery.simpleQuery = new detailQuery.simpleQueryClass();
                detailQuery.simpleQuery.model = modelDescription.model;
            }
            var columns = modelDescription
                ? modelDescription.fields
                    .map(function (item) { return modelFieldToDisplayField(item, false); })
                    .map(function (item) { return prepareDataSourceColumnForGet(resource, modelDescription, item); })
                : [];
            var inputs = _this.field.params['inputs']
                ? _this.field.params['inputs'].map(function (item) { return new Input().deserialize(item); })
                : [];
            var nameInput = _this.field.params['custom_display_field_input']
                ? new Input().deserialize(_this.field.params['custom_display_field_input'])
                : undefined;
            var subtitleInput = _this.field.params['subtitle_input']
                ? new Input().deserialize(_this.field.params['subtitle_input'])
                : undefined;
            var iconInput = _this.field.params['icon_input']
                ? new Input().deserialize(_this.field.params['icon_input'])
                : undefined;
            _this.updateFieldSubscription = applyParamInputs$({}, inputs, {
                context: _this.context,
                ignoreEmpty: true,
                emptyValues: EMPTY_FILTER_VALUES
            })
                .pipe(distinctUntilChanged(function (lhs, rhs) { return isEqual(lhs, rhs); }))
                .pipe(untilDestroyed(_this))
                .subscribe(function (params) {
                _this.source.init({
                    resource: modelDescription.resource,
                    query: listQuery,
                    queryParameters: modelDescription.getParameters,
                    detailQuery: detailQuery,
                    columns: columns,
                    params: params,
                    sortingField: _this.field.params['sorting_field'],
                    sortingAsc: _this.field.params['sorting_asc'],
                    valueField: _this.field.params['custom_primary_key'] || modelDescription.primaryKeyField,
                    nameField: _this.field.params['custom_display_field'] || modelDescription.displayField,
                    nameInput: nameInput,
                    subtitleField: _this.field.params['subtitle_field'],
                    subtitleInput: subtitleInput,
                    iconField: _this.field.params['icon_field'],
                    iconInput: iconInput,
                    context: _this.context,
                    contextElement: _this.contextElement
                });
                _this.source.reset();
                _this.cd.markForCheck();
            });
        });
    };
    ForeignKeyFieldComponent.prototype.initValue = function () {
        if (!this.readonly) {
            return;
        }
        // if (this.context['related_model'] && this.context['related_model'].modelDescription.displayField) {
        //   const model = this.context['related_model'];
        //   const modelDescription = model.modelDescription;
        //
        //   this.relatedFormField = modelDescription.field(modelDescription.displayField).formField;
        //   this.relatedValue = model.getAttribute(model.modelDescription.displayField);
        //   this.cd.markForCheck();
        // } else {
        this.initValueStr();
        // }
    };
    ForeignKeyFieldComponent.prototype.initValueStr = function () {
        var _this = this;
        if (!isSet(this.currentValue)) {
            this.valueStr = undefined;
            this.cd.markForCheck();
            // } else if (this.context['related_model']) {
            //   this.valueStr = this.modelUtilsService.str(this.context['related_model']);
            //   this.cd.markForCheck();
        }
        else if (this.source.hasName()) {
            this.valueStr = undefined;
            this.valueStrLoading = true;
            this.cd.markForCheck();
            this.source
                .fetchByValue(this.currentValue)
                .pipe(untilDestroyed(this))
                .subscribe(function (result) {
                if (result) {
                    _this.valueStr = result.name;
                }
                _this.valueStrLoading = false;
                _this.cd.markForCheck();
            }, function () {
                _this.valueStrLoading = false;
                _this.cd.markForCheck();
            });
        }
        else {
            this.valueStr = "ID: " + this.currentValue;
            this.valueStrLoading = false;
            this.cd.markForCheck();
        }
    };
    ForeignKeyFieldComponent.prototype.initModelDescription = function () {
        var _this = this;
        var model = this.field.params['related_model']
            ? fromLegacyModel(this.field.params['related_model']['model'])
            : undefined;
        this.modelDescriptionStore
            .getDetailFirst(model)
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            _this.modelDescription = result;
            _this.cd.markForCheck();
        });
    };
    Object.defineProperty(ForeignKeyFieldComponent.prototype, "createLink", {
        get: function () {
            if (!this.modelDescription) {
                return;
            }
            return this.modelDescription.createLink;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ForeignKeyFieldComponent.prototype, "changeLink", {
        get: function () {
            if (!this.currentValue || !this.modelDescription || !this.modelDescription.getDetailEnabled) {
                return of(undefined);
            }
            var idValue = transformFieldForeignKey(this.field.params, this.currentValue);
            if (this.field.params['custom_primary_key']) {
                return this.reducedModelService
                    .getDetail(this.modelDescription.modelId, this.field.params['custom_primary_key'], idValue)
                    .pipe(map(function (model) { return (model ? model.getLink() : undefined); }));
            }
            else {
                var data = {};
                var instance = this.createModel().deserialize(this.modelDescription.model, data);
                instance.setUp(this.modelDescription);
                instance.setPrimaryKey(idValue);
                return of(instance.getLink());
            }
        },
        enumerable: true,
        configurable: true
    });
    ForeignKeyFieldComponent.prototype.updateButtons = function () {
        // this.changeLink.pipe(untilDestroyed(this)).subscribe(changeLink => {
        //   const buttons = [...this.labelButtons];
        //
        //   if (this.field) {
        //     if (changeLink && this.field.params['open_button'] !== false) {
        //       buttons.push({ link: changeLink, icon: 'eye', tip: 'Open ' + this.field.label });
        //     }
        //
        //     if (!this.readonly && this.field.params['create_button'] !== false) {
        //       buttons.push({ link: this.createLink, icon: 'plus', tip: 'Create ' + this.field.label });
        //     }
        //   }
        //
        //   this.buttons = buttons;
        //   this.cd.markForCheck();
        // });
    };
    ForeignKeyFieldComponent.prototype.updateActions = function () {
        var topActions = this.elementActions.find(function (item) { return item.position == ElementActionsPosition.Top; });
        var bottomActions = this.elementActions.find(function (item) { return item.position == ElementActionsPosition.Bottom; });
        this.topActions = topActions ? topActions.actions : [];
        this.bottomActions = bottomActions ? bottomActions.actions : [];
        this.cd.markForCheck();
    };
    Object.defineProperty(ForeignKeyFieldComponent.prototype, "selectFill", {
        // TODO: Move to separate params option
        get: function () {
            return this.field.params['classes'] && this.field.params['classes'].indexOf('select_fill') != -1;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ForeignKeyFieldComponent.prototype, "selectSmall", {
        // TODO: Move to separate params option
        get: function () {
            return this.field.params['classes'] && this.field.params['classes'].indexOf('select_small') != -1;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ForeignKeyFieldComponent.prototype, "selectSegment", {
        // TODO: Move to separate params option
        get: function () {
            if (!this.field.params['classes']) {
                return;
            }
            if (this.field.params['classes'].indexOf('select_segment-top') != -1) {
                return SelectSegment.Top;
            }
            else if (this.field.params['classes'].indexOf('select_segment') != -1) {
                return SelectSegment.Middle;
            }
            else if (this.field.params['classes'].indexOf('select_segment-bottom') != -1) {
                return SelectSegment.Bottom;
            }
        },
        enumerable: true,
        configurable: true
    });
    ForeignKeyFieldComponent.prototype.open = function () {
        if (this.selectComponent) {
            this.selectComponent.open();
        }
    };
    ForeignKeyFieldComponent.prototype.asActions = function (value) {
        return value;
    };
    ForeignKeyFieldComponent.prototype.asSelectComponent = function (value) {
        return value;
    };
    return ForeignKeyFieldComponent;
}(FieldComponent));
export { ForeignKeyFieldComponent };
registerFieldComponent(FieldType.RelatedModel, ForeignKeyFieldComponent);
