<div class="custom-page-popup__header">
  <div class="custom-page-popup__header-main">
    <div class="custom-page-popup__title">Sync events</div>
  </div>
  <div class="custom-page-popup__header-right">
    <div class="custom-page-popup__close icon-close" (click)="close()"></div>
  </div>
</div>

<ng-template let-size="size" #table_rows_stub_template>
  <tr *ngFor="let item of [1, 2, 3, 4, 5, 6, 7, 8].slice(0, size || 8)" class="card-table__row">
    <td class="card-table__column">
      <div class="card-table__column-inner">
        <span [class.loading-animation]="true"><span class="stub-text">February 21</span></span>
      </div>
    </td>
    <td class="card-table__column">
      <div class="card-table__column-inner">
        <span [class.loading-animation]="true"><span class="stub-text">SYNC_DATA</span></span>
      </div>
    </td>
    <td class="card-table__column">
      <div class="card-table__column-inner">
        <span [class.loading-animation]="true"><span class="stub-text">Success</span></span>
      </div>
    </td>
    <td class="card-table__column">
      <div class="card-table__column-inner">
        <span [class.loading-animation]="true"><span class="stub-text">12:53:01</span></span>
      </div>
    </td>
    <td class="card-table__column">
      <div class="card-table__column-inner"></div>
    </td>
  </tr>
</ng-template>

<ng-template #table_stub_template>
  <table class="card-table">
    <thead>
      <tr class="card-table__head-row">
        <th class="card-table__head-column">
          <span [class.loading-animation]="true"><span class="stub-text">Date</span></span>
        </th>
        <th class="card-table__head-column">
          <span [class.loading-animation]="true"><span class="stub-text">Event</span></span>
        </th>
        <th class="card-table__head-column">
          <span [class.loading-animation]="true"><span class="stub-text">Status</span></span>
        </th>
        <th class="card-table__head-column">
          <span [class.loading-animation]="true"><span class="stub-text">Time</span></span>
        </th>
        <th class="card-table__head-column"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngTemplateOutlet="table_rows_stub_template"></ng-container>
    </tbody>
  </table>
</ng-template>

<div style="padding: 20px;">
  <ng-container *ngIf="(!(loading$ | async) || (items$ | async)?.length) && !error">
    <table class="card-table">
      <thead>
        <tr class="card-table__head-row">
          <th class="card-table__head-column">Date</th>
          <th class="card-table__head-column">Event</th>
          <th class="card-table__head-column">Status</th>
          <th class="card-table__head-column">Time</th>
          <th class="card-table__head-column"></th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let item of items$ | async"
          app-data-sync-job-tasks-item
          [resource]="resource"
          [job]="job"
          [item]="item"
          class="card-table__row card-table__row_clickable"
        ></tr>

        <ng-container *ngIf="loading$ | async">
          <ng-container *ngTemplateOutlet="table_rows_stub_template; context: { size: 3 }"></ng-container>
        </ng-container>
      </tbody>
    </table>

    <div *ngIf="(hasMore$ | async) && !(loading$ | async)" style="margin-top: 20px; text-align: center;">
      <a href="javascript:void(0)" class="button button_background button_small" (click)="getNext()">
        <span class="button__label">Load more</span>
      </a>
    </div>
  </ng-container>

  <ng-container *ngIf="(loading$ | async) && !(items$ | async)?.length">
    <ng-container *ngTemplateOutlet="table_stub_template"></ng-container>
  </ng-container>

  <div *ngIf="error" class="table__empty">
    <app-error [title]="'Loading failed' | localize">
      {{ error }}
    </app-error>
  </div>
</div>
