<app-font-menu-overlay
  [origin]="overlay_origin"
  [value]="control.value"
  [globalFonts]="globalFonts"
  (fontSelect)="onFontSelect($event)"
  #overlay="appFontMenuOverlay"
></app-font-menu-overlay>

<div class="select select_theme_jet select__input_opened" [class.select_fill]="fill" [class.select_small]="small">
  <div
    class="select__input"
    [class.select__input_disabled]="control.disabled"
    [class.select__input_opened]="overlay.isOpened()"
    cdkOverlayOrigin
    #overlay_origin="cdkOverlayOrigin"
    (click)="overlay.open()"
  >
    <div *ngIf="prefix | appIsSet" class="select__input-label-title">
      {{ prefix }}
    </div>
    <span class="select__input-label">
      <ng-container *ngIf="valueStr | appIsSet">{{ valueStr }}</ng-container>
      <ng-container *ngIf="!(valueStr | appIsSet)">{{ placeholder }}</ng-container>
    </span>
    <span class="select__input-arrow"></span>
  </div>
</div>
