import { Observable, of } from 'rxjs';

import { ValueFormat } from '../data/value-format';
import { applyParamInput, applyParamInput$ } from './inputs';
import { applyStaticValueFormat } from './value-format-static';

export function applyValueFormat$<T>(value: T, valueFormat: ValueFormat): Observable<string> {
  if (!valueFormat) {
    return of(value as any);
  }

  if (valueFormat.formatInput) {
    return applyParamInput$(valueFormat.formatInput, {
      localContext: {
        value: value
      },
      defaultValue: ''
    });
  } else {
    return of(applyStaticValueFormat(value, valueFormat));
  }
}

export function applyValueFormat<T>(value: T, valueFormat: ValueFormat): string {
  if (!valueFormat) {
    return value as any;
  }

  if (valueFormat.formatInput) {
    try {
      return applyParamInput(valueFormat.formatInput, {
        localContext: {
          value: value
        },
        defaultValue: ''
      });
    } catch (e) {
      return '';
    }
  } else {
    return applyStaticValueFormat(value, valueFormat);
  }
}
