var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnDestroy } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { elementOutputMeta } from '@modules/fields';
import { ViewContext } from './view-context';
import { ViewContextElementType } from './view-context-element-type';
import { VIEW_CONTEXT_OUTPUTS_KEY } from './view-context-property-output';
var ViewContextElement = /** @class */ (function () {
    function ViewContextElement(context) {
        this.context = context;
        this.allowSkip = false;
        this.insert = false;
        this.fieldFilters = false;
        this._outputs = new BehaviorSubject([]);
        this._actions = new BehaviorSubject([]);
        this._outputsValue = new BehaviorSubject({});
    }
    ViewContextElement.prototype.ngOnDestroy = function () {
        this.unregister();
    };
    Object.defineProperty(ViewContextElement.prototype, "actions", {
        get: function () {
            return this._actions.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ViewContextElement.prototype, "actions$", {
        get: function () {
            return this._actions.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ViewContextElement.prototype, "outputs", {
        get: function () {
            return this._outputs.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ViewContextElement.prototype, "outputs$", {
        get: function () {
            return this._outputs.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ViewContextElement.prototype, "outputsValue", {
        get: function () {
            return this._outputsValue.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ViewContextElement.prototype, "outputsValue$", {
        get: function () {
            return this._outputsValue.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    ViewContextElement.prototype.initInfo = function (info, markHasChanges) {
        if (markHasChanges === void 0) { markHasChanges = false; }
        this.uniqueName = info.uniqueName || this.uniqueName;
        this.name = info.name || this.name;
        this.icon = info.icon || this.icon;
        this.type = info.type || this.type;
        this.allowSkip = info.allowSkip || this.allowSkip;
        this.insert = info.insert || this.insert;
        this.fieldFilters = info.fieldFilters || this.fieldFilters;
        this.getFieldValueFn = info.getFieldValue || this.getFieldValueFn;
        this.getQueryOptionsFn = info.getQueryOptions || this.getQueryOptionsFn;
        this.element = info.element || this.element;
        this.popup = info.popup || this.popup;
        this.documentation = info.documentation || this.documentation;
        this.action = info.action || this.action;
        if (markHasChanges && this.context) {
            this.context.markElementsHasChanges();
        }
    };
    ViewContextElement.prototype.initGlobal = function (info, parent) {
        this.initInfo(__assign({}, info, { type: ViewContextElementType.Global }));
        this.register(parent);
    };
    ViewContextElement.prototype.initElement = function (info, parent) {
        this.initInfo(__assign({}, info, { type: ViewContextElementType.Element }));
        this.register(parent);
    };
    ViewContextElement.prototype.isRegistered = function () {
        if (this.context) {
            return this.context.isRegisteredElement(this);
        }
        else {
            return false;
        }
    };
    ViewContextElement.prototype.register = function (parent) {
        if (this.context) {
            this.context.registerElement(this, parent);
        }
    };
    ViewContextElement.prototype.unregister = function () {
        if (this.context) {
            this.context.unregisterElement(this);
        }
        this.uniqueName = undefined;
        this.name = undefined;
        if (this.propertyOutputsSubscription) {
            this.propertyOutputsSubscription.unsubscribe();
        }
    };
    ViewContextElement.prototype.setActions = function (actions) {
        this._actions.next(actions);
        this.markHasChanges();
    };
    ViewContextElement.prototype.setOutputs = function (outputs) {
        this._outputs.next(outputs);
        this._outputsValue.next(__assign({}, outputs.reduce(function (acc, item) {
            acc[item.uniqueName] = item.defaultValue;
            return acc;
        }, {}), this._outputsValue.value));
        this.markHasChanges();
    };
    ViewContextElement.prototype.applyOutputValue = function (path, handler) {
        var outputsValue = cloneDeep(this._outputsValue.value);
        var pathParts = path.split('.');
        pathParts.reduce(function (prev, current, i) {
            if (i == pathParts.length - 1) {
                handler(prev, current);
            }
            return prev[current];
        }, outputsValue);
        if (isEqual(outputsValue, this._outputsValue.value)) {
            return;
        }
        this._outputsValue.next(outputsValue);
        this.markHasChanges();
    };
    ViewContextElement.prototype.setOutputValue = function (path, value, meta) {
        this.applyOutputValue(path, function (obj, key) {
            obj[key] = value;
            var metaKey = elementOutputMeta(key);
            if (meta) {
                obj[metaKey] = meta;
            }
            else if (!meta && obj.hasOwnProperty(metaKey)) {
                delete obj[metaKey];
            }
        });
    };
    ViewContextElement.prototype.patchOutputValueMeta = function (path, meta) {
        this.applyOutputValue(path, function (obj, key) {
            obj[elementOutputMeta(key)] = __assign({}, obj[elementOutputMeta(key)], meta);
        });
    };
    ViewContextElement.prototype.setOutputValues = function (values) {
        if (isEqual(values, this._outputsValue.value)) {
            return;
        }
        this._outputsValue.next(values);
        this.markHasChanges();
    };
    ViewContextElement.prototype.patchOutputValues = function (values) {
        var newValue = __assign({}, this._outputsValue.value, values);
        if (isEqual(newValue, this._outputsValue.value)) {
            return;
        }
        this._outputsValue.next(newValue);
        this.markHasChanges();
    };
    ViewContextElement.prototype.markHasChanges = function () {
        if (this.context) {
            this.context.markHasChanges();
        }
    };
    ViewContextElement.prototype.getPropertyOutputs = function (instance) {
        var outputs = instance.__proto__ && instance.__proto__[VIEW_CONTEXT_OUTPUTS_KEY]
            ? instance.__proto__[VIEW_CONTEXT_OUTPUTS_KEY]
            : [];
        return outputs.map(function (output) {
            var emitter = instance[output.property];
            return {
                uniqueName: output.uniqueName,
                name: output.name,
                emitter: emitter
            };
        });
    };
    ViewContextElement.prototype.getFieldValue = function (field) {
        if (!this.getFieldValueFn) {
            return;
        }
        return this.getFieldValueFn(field, this.outputsValue);
    };
    ViewContextElement.prototype.getQueryOptions = function () {
        if (!this.getQueryOptionsFn) {
            return;
        }
        return this.getQueryOptionsFn();
    };
    ViewContextElement.prototype.registerPropertyOutputs = function (instance) {
        var _this = this;
        var propertyOutputs = this.getPropertyOutputs(instance);
        this._outputs.next(this.outputs.filter(function (item) { return !propertyOutputs.find(function (i) { return i.uniqueName == item.uniqueName; }); }).concat(propertyOutputs.map(function (item) {
            return {
                uniqueName: item.uniqueName,
                name: item.name
            };
        })));
        this.propertyOutputsSubscription = combineLatest(propertyOutputs.map(function (item) { return item.emitter.pipe(map(function (value) { return [item.uniqueName, value]; })); })).subscribe(function (values) {
            values.reduce(function (prev, _a) {
                var name = _a[0], value = _a[1];
                prev[name] = value;
                return prev;
            }, cloneDeep(_this._outputsValue.value));
        });
    };
    ViewContextElement.prototype.unregisterPropertyOutputs = function (instance) {
        var propertyOutputs = this.getPropertyOutputs(instance);
        this._outputs.next(this.outputs.filter(function (item) { return !propertyOutputs.find(function (i) { return i.uniqueName === item.uniqueName; }); }).slice());
        if (this.propertyOutputsSubscription) {
            this.propertyOutputsSubscription.unsubscribe();
        }
    };
    return ViewContextElement;
}());
export { ViewContextElement };
