var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ThemeOptionsDirective } from '@modules/theme-components';
import { deserializeElementWrapperStyles } from '../../components/theme-gallery/theme-template-deserialize.utils';
var ColorThemeOptionsDirective = /** @class */ (function (_super) {
    __extends(ColorThemeOptionsDirective, _super);
    function ColorThemeOptionsDirective() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ColorThemeOptionsDirective.prototype.getThemeOptions = function () {
        if (this.theme) {
            var result = {
                accentColor: this.theme.accentColor,
                accentColorDark: this.theme.accentColorDark,
                backgroundColor: this.theme.backgroundColor,
                backgroundColorDark: this.theme.backgroundColorDark,
                backgroundColor2: this.theme.backgroundColor2,
                backgroundColor2Dark: this.theme.backgroundColor2Dark,
                backgroundColor3: this.theme.backgroundColor3,
                backgroundColor3Dark: this.theme.backgroundColor3Dark,
                backgroundColor4: this.theme.backgroundColor4,
                backgroundColor4Dark: this.theme.backgroundColor4Dark,
                backgroundColor5: this.theme.backgroundColor5,
                backgroundColor5Dark: this.theme.backgroundColor5Dark,
                textColor: this.theme.textColor,
                textColorDark: this.theme.textColorDark,
                textColor2: this.theme.textColor2,
                textColor2Dark: this.theme.textColor2Dark,
                textColor3: this.theme.textColor3,
                textColor3Dark: this.theme.textColor3Dark,
                borderColor: this.theme.borderColor,
                borderColorDark: this.theme.borderColorDark,
                borderColor2: this.theme.borderColor2,
                borderColor2Dark: this.theme.borderColor2Dark,
                borderColor3: this.theme.borderColor3,
                borderColor3Dark: this.theme.borderColor3Dark,
                borderRadius: this.theme.borderRadius,
                fontRegular: this.theme.fontRegular,
                fontHeading: this.theme.fontHeading
            };
            if (this.theme.elementWrapperStyles) {
                var elementWrapperStyles = deserializeElementWrapperStyles(this.theme.elementWrapperStyles);
                result.elementWrapperBorder = elementWrapperStyles.borderSettings;
                result.elementWrapperBorderRadius = elementWrapperStyles.borderRadius;
                result.elementWrapperShadow = elementWrapperStyles.shadow;
                result.elementWrapperMargin = elementWrapperStyles.margin;
            }
            return result;
        }
        else {
            return _super.prototype.getThemeOptions.call(this);
        }
    };
    return ColorThemeOptionsDirective;
}(ThemeOptionsDirective));
export { ColorThemeOptionsDirective };
