import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { getGradientCss, GradientType } from '@modules/colors';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { isSet } from '@shared';

import { Frame } from './frame';
import { GradientStop } from './gradient-stop';
import { Point } from './point';

export class Gradient {
  type: GradientType = GradientType.Linear;
  from: Point;
  to: Point;
  stops: GradientStop[] = [];
  aspectRatio = 1;

  constructor(options: Partial<Gradient> = {}) {
    Object.assign(this, options);
  }

  deserialize(data: Object): this {
    if (data['type']) {
      this.type = data['type'];
    }

    if (data['from']) {
      this.from = new Point().deserialize(data['from']);
    }

    if (data['to']) {
      this.to = new Point().deserialize(data['to']);
    }

    if (data['stops']) {
      this.stops = data['stops'].map(item => new GradientStop().deserialize(item));
    }

    if (isSet(data['aspect_ratio'])) {
      this.aspectRatio = data['aspect_ratio'];
    }

    return this;
  }

  serialize(): Object {
    return {
      type: this.type,
      from: this.from ? this.from.serialize() : undefined,
      to: this.to ? this.to.serialize() : undefined,
      stops: this.stops.map(item => item.serialize()),
      aspect_ratio: this.aspectRatio
    };
  }

  css$(
    options: {
      frame?: Frame;
      context?: ViewContext;
      contextElement?: ViewContextElement;
      localContext?: Object;
    } = {}
  ): Observable<{ background?: string; width?: string; height?: string; transform?: string }> {
    options = {
      frame: new Frame({ width: 1, height: 1 }),
      ...options
    };

    const stops$ = this.stops.length
      ? combineLatest(
          this.stops.map(item => {
            return item
              .cssColor$({
                context: options.context,
                contextElement: options.contextElement,
                localContext: options.localContext
              })
              .pipe(
                map(color => {
                  return {
                    stop: item,
                    color: color
                  };
                })
              );
          })
        )
      : of([]);

    return combineLatest(stops$).pipe(
      map(([stops]) => {
        return getGradientCss({
          type: this.type,
          from: this.from,
          to: this.to,
          stops: stops,
          aspectRatio: this.aspectRatio,
          frame: options.frame
        });
      })
    );
  }
}
