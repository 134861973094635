var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormControl, FormGroup } from '@angular/forms';
import { GradientStop } from '@modules/customize';
import { isSet } from '@shared';
var GradientStopControl = /** @class */ (function (_super) {
    __extends(GradientStopControl, _super);
    function GradientStopControl(state) {
        if (state === void 0) { state = {}; }
        return _super.call(this, {
            position: new FormControl(isSet(state.position) ? state.position : 0),
            color: new FormControl(isSet(state.color) ? state.color : '')
        }) || this;
    }
    GradientStopControl.prototype.deserialize = function (value, options) {
        if (options === void 0) { options = {}; }
        this.instance = value;
        this.controls.position.patchValue(value.position, { emitEvent: options.emitEvent });
        this.controls.color.patchValue(value.color, { emitEvent: options.emitEvent });
    };
    GradientStopControl.prototype.getInstance = function (instance) {
        if (!instance) {
            instance = new GradientStop();
            instance.generateId();
        }
        instance.position = this.controls.position.value;
        instance.color = isSet(this.controls.color.value) ? this.controls.color.value : undefined;
        return instance;
    };
    GradientStopControl.prototype.getId = function () {
        return this.instance ? this.instance.id : undefined;
    };
    GradientStopControl.prototype.serialize = function () {
        return this.getInstance(this.instance);
    };
    return GradientStopControl;
}(FormGroup));
export { GradientStopControl };
