import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { of, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { ViewContext, ViewContextElement } from '@modules/customize';
import { applyParamInput$, createFormFieldFactory } from '@modules/fields';
import { SidebarCollapseContext } from '@modules/sidebar';
import { controlValue, TypedChanges } from '@shared';

import { TabsLayoutItemControl } from '../tabs-layout-item.control';

@Component({
  selector: 'app-customize-bar-tabs-edit-item',
  templateUrl: './customize-bar-tabs-edit-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeBarTabsEditItemComponent implements OnInit, OnDestroy, OnChanges {
  @Input() control: TabsLayoutItemControl;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() collapseContext: SidebarCollapseContext;
  @Input() openedInitial = false;
  @Input() removeEnabled = false;
  @Input() analyticsSource: string;
  @Output() remove = new EventEmitter<void>();

  createField = createFormFieldFactory();
  title: string;
  titleSubscription: Subscription;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<CustomizeBarTabsEditItemComponent>): void {
    if (changes.control || changes.context) {
      this.initTitle();
    }
  }

  initTitle() {
    if (this.titleSubscription) {
      this.titleSubscription.unsubscribe();
      this.titleSubscription = undefined;
    }

    this.titleSubscription = controlValue(this.control.controls.title)
      .pipe(
        map(() => this.control.controls.title.serialize()),
        switchMap(titleInput => {
          if (!titleInput) {
            return of(undefined);
          }

          return applyParamInput$<string>(titleInput, {
            context: this.context,
            contextElement: this.contextElement,
            defaultValue: ''
          });
        }),
        untilDestroyed(this)
      )
      .subscribe(value => {
        this.title = value;
        this.cd.markForCheck();
      });
  }
}
