import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fromEvent } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

import { GradientType } from '@modules/colors';
import { ViewContext } from '@modules/customize';
import { FillType, Layer, View } from '@modules/views';
import { isElementHasChild, MouseButton, nodeListToArray } from '@shared';

import { FillControl } from '../../controls/fill.control';
import { isCustomizeGradientMouseDown } from '../gradient-position/gradient-position.component';

const markFillColorOverlayMouseDownProperty = '_markFillColorOverlayMouseDownProperty';

export function markFillColorOverlayMouseDown(mouseDownEvent: MouseEvent) {
  mouseDownEvent[markFillColorOverlayMouseDownProperty] = true;
}

export function isFillColorOverlayMouseDown(mouseDownEvent: MouseEvent) {
  return !!mouseDownEvent[markFillColorOverlayMouseDownProperty];
}

@Component({
  selector: 'app-fill-color-overlay',
  templateUrl: './fill-color-overlay.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FillColorOverlayComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() control: FillControl;
  @Input() viewContext: ViewContext;
  @Input() view: View;
  @Input() layer: Layer;
  @Input() fillTypesEnabled: FillType[];
  @Output() blur = new EventEmitter<void>();

  fillTypes = FillType;
  gradientTypes = GradientType;

  constructor(private el: ElementRef) {}

  ngOnInit() {
    this.initBlur();
  }

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    fromEvent<MouseEvent>(this.el.nativeElement, 'mousedown')
      .pipe(
        filter(e => e.button == MouseButton.Main),
        untilDestroyed(this)
      )
      .subscribe(e => markFillColorOverlayMouseDown(e));
  }

  initBlur() {
    fromEvent<MouseEvent>(window.document, 'mousedown')
      .pipe(
        filter(e => e.button == MouseButton.Main),
        filter(e => !isFillColorOverlayMouseDown(e)),
        filter(e => !isCustomizeGradientMouseDown(e)),
        filter(e => {
          const overlays = nodeListToArray(document.querySelectorAll('.cdk-overlay-container'));
          return !overlays.some(overlay => isElementHasChild(overlay, e.target as Element, true));
        }),
        switchMap(() => {
          return fromEvent<MouseEvent>(window.document, 'mouseup').pipe(filter(e => e.button == MouseButton.Main));
        }),
        untilDestroyed(this)
      )
      .subscribe(() => this.blur.emit());
  }
}
