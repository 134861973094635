import { ChangeDetectorRef, Directive, ElementRef, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import toPairs from 'lodash/toPairs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';

import { ProjectSettingsStore } from '@modules/all-project-settings';
import { ThemeService } from '@modules/theme';
import { isSet } from '@shared';

import { MenuSecondary } from '../../components/menu-secondary/menu-secondary.component';

@Directive({
  selector: '[appMenuBlockDropdownStyle]'
})
export class MenuBlockDropdownStyleDirective implements OnInit, OnDestroy {
  menuIsUnset = false;
  menuIsLight = false;

  @HostBinding('style') menuStyle: SafeStyle;
  @HostBinding('class.menu2-block_unset') get unsetCls() {
    return this.menuIsUnset;
  }
  @HostBinding('class.menu2-block_light') get lightCls() {
    return this.menuIsLight === true;
  }
  @HostBinding('class.menu2-block_dark') get darkCls() {
    return this.menuIsLight === false;
  }

  constructor(
    private projectSettingsStore: ProjectSettingsStore,
    private themeService: ThemeService,
    private sanitizer: DomSanitizer,
    private el: ElementRef,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    combineLatest(this.projectSettingsStore.getAllSettings$(), this.themeService.isDarkTheme$)
      .pipe(untilDestroyed(this))
      .subscribe(([projectSettings, isDarkTheme]) => {
        const backgroundColor = isDarkTheme ? projectSettings.backgroundColor2Dark : projectSettings.backgroundColor2;

        this.menuIsUnset = !isSet(backgroundColor);
        this.menuIsLight = MenuSecondary.isLight(backgroundColor);
        this.menuStyle = this.getStyleVars(undefined, backgroundColor);
        this.cd.markForCheck();
      });
  }

  ngOnDestroy(): void {}

  getStyleVars(accentColor: string, backgroundColor: string): SafeStyle {
    const vars = MenuSecondary.getVars(accentColor, backgroundColor);
    const styles = toPairs(vars)
      .map(([k, v]) => `--${k}: ${v}`)
      .join(';');

    return this.sanitizer.bypassSecurityTrustStyle(styles);
  }
}
