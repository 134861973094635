/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../collapse-section/collapse-section.component.ngfactory";
import * as i2 from "../collapse-section/collapse-section.component";
import * as i3 from "../../../customize/services/customize/customize.service";
import * as i4 from "../../../customize-elements/services/element-container/element-container.service";
import * as i5 from "../../../routing/services/routing/routing.service";
import * as i6 from "../form-element/form-element.component";
import * as i7 from "../../../customize/data/view-context-element";
import * as i8 from "../../../customize/data/view-context";
import * as i9 from "./collapse.component";
import * as i10 from "../custom-page-popup/custom-page-popup.component";
var styles_CollapseComponent = [];
var RenderType_CollapseComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CollapseComponent, data: {} });
export { RenderType_CollapseComponent as RenderType_CollapseComponent };
export function View_CollapseComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "collapse-container collapse-container_theme"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-collapse-section", [["class", "collapse-container__item"]], null, [[null, "toggleOpened"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("toggleOpened" === en)) {
        var pd_0 = (_co.toggleOpened() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_CollapseSectionComponent_0, i1.RenderType_CollapseSectionComponent)), i0.ɵdid(2, 770048, null, 0, i2.CollapseSectionComponent, [i3.CustomizeService, i4.ElementContainerService, i5.RoutingService, [2, i6.FormElementComponent], i0.ChangeDetectorRef], { titleInput: [0, "titleInput"], titleStyle: [1, "titleStyle"], icon: [2, "icon"], children: [3, "children"], tooltip: [4, "tooltip"], collapseIndicatorPosition: [5, "collapseIndicatorPosition"], element: [6, "element"], opened: [7, "opened"], first: [8, "first"], last: [9, "last"], theme: [10, "theme"], context: [11, "context"] }, { toggleOpened: "toggleOpened" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.element.titleInput; var currVal_1 = _co.element.titleStyle; var currVal_2 = _co.element.icon; var currVal_3 = _co.element.children; var currVal_4 = _co.element.tooltip; var currVal_5 = _co.element.collapseIndicatorPosition; var currVal_6 = _co.element; var currVal_7 = _co.opened; var currVal_8 = true; var currVal_9 = true; var currVal_10 = true; var currVal_11 = _co.context; _ck(_v, 2, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11]); }, null); }
export function View_CollapseComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-collapse", [], null, null, null, View_CollapseComponent_0, RenderType_CollapseComponent)), i0.ɵprd(131584, null, i7.ViewContextElement, i7.ViewContextElement, [i8.ViewContext]), i0.ɵdid(2, 770048, null, 0, i9.CollapseComponent, [i3.CustomizeService, i7.ViewContextElement, i0.ChangeDetectorRef, [2, i10.CustomPagePopupComponent]], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var CollapseComponentNgFactory = i0.ɵccf("app-collapse", i9.CollapseComponent, View_CollapseComponent_Host_0, { element: "element", elementActive: "elementActive", context: "context", visible: "visible", actionClicked: "actionClicked", accentColor: "accentColor" }, { updated: "updated", replaceRequested: "replaceRequested", deleteRequested: "deleteRequested" }, []);
export { CollapseComponentNgFactory as CollapseComponentNgFactory };
