import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

import { TintStyle } from '@modules/actions';
import { ActionElementStyles, FieldElementStyles, Frame } from '@modules/customize';
import { MenuPrimary } from '@modules/menu-components';
import { TypedChanges } from '@shared';

import { ThemeTemplate } from '../theme-gallery/theme-template';
import {
  deserializeActionElementStyles,
  deserializeFieldElementStyles,
  deserializeFillSettings
} from '../theme-gallery/theme-template-deserialize.utils';

@Component({
  selector: 'app-theme-gallery-item-preview',
  templateUrl: './theme-gallery-item-preview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ThemeGalleryItemPreviewComponent implements OnInit, OnChanges {
  @Input() theme: ThemeTemplate;
  @Input() dark = false;

  menuBlocks: {
    background: SafeStyle;
    backgroundWidth: string;
    backgroundHeight: string;
    backgroundTransform: SafeStyle;
    isLight: boolean;
  }[] = [];
  actionElementStyles: ActionElementStyles;
  fieldElementStyles: FieldElementStyles;
  tintStyles = TintStyle;

  constructor(private sanitizer: DomSanitizer, private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.updateMenuBlocks();
  }

  ngOnChanges(changes: TypedChanges<ThemeGalleryItemPreviewComponent>): void {
    if (changes.dark && !changes.dark.firstChange) {
      this.updateMenuBlocks();
    }
  }

  updateMenuBlocks() {
    this.menuBlocks = [0, 1].map(index => {
      const block = this.theme.menuBlocks ? this.theme.menuBlocks[index] : undefined;
      const fillSettings = block ? deserializeFillSettings(block.fillSettings) : undefined;
      const fill = fillSettings ? (this.dark ? fillSettings.fillDark : fillSettings.fill) : undefined;
      const css = fill ? fill.css({ frame: new Frame({ width: 66, height: 10 }) }) : {};
      const defaultBackground = this.dark ? '#1D2329' : '#F3F5F8';

      return {
        background: this.sanitizer.bypassSecurityTrustStyle(css.background),
        backgroundWidth: css.width,
        backgroundHeight: css.height,
        backgroundTransform: this.sanitizer.bypassSecurityTrustStyle(css.transform),
        isLight: MenuPrimary.isLight(css.accentColor || this.theme.backgroundColor || defaultBackground)
      };
    });

    const actionElementStyles = deserializeActionElementStyles(this.theme.actionElementStylesPrimary);
    const fieldElementStyles = deserializeFieldElementStyles(this.theme.fieldElementStyles);

    this.actionElementStyles = actionElementStyles || new ActionElementStyles();
    this.fieldElementStyles = fieldElementStyles || new FieldElementStyles();
    this.cd.markForCheck();
  }
}
