<ng-template let-menuBlocks="menuBlocks" let-horizontal="horizontal" #menu>
  <div
    *ngIf="asMenuBlockItems(menuBlocks) as menuBlocks"
    class="menu2 theme-enabled"
    [class.menu2_horizontal]="horizontal"
    appThemeOptions
    [applyElementStyle]="true"
    [applyGlobalStyle]="false"
    [accentColor]="appearanceForm.getAccentColor()"
    [accentColorDark]="appearanceForm.getAccentColorDark()"
    [backgroundColor]="appearanceForm.controls.background_color.value"
    [backgroundColorDark]="appearanceForm.controls.background_color_dark.value"
    [backgroundColor2]="appearanceForm.controls.background_color_2.value"
    [backgroundColor2Dark]="appearanceForm.controls.background_color_2_dark.value"
    [backgroundColor3]="appearanceForm.controls.background_color_3.value"
    [backgroundColor3Dark]="appearanceForm.controls.background_color_3_dark.value"
    [backgroundColor4]="appearanceForm.controls.background_color_4.value"
    [backgroundColor4Dark]="appearanceForm.controls.background_color_4_dark.value"
    [backgroundColor5]="appearanceForm.controls.background_color_5.value"
    [backgroundColor5Dark]="appearanceForm.controls.background_color_5_dark.value"
    [textColor]="appearanceForm.controls.text_color.value"
    [textColorDark]="appearanceForm.controls.text_color_dark.value"
    [textColor2]="appearanceForm.controls.text_color_2.value"
    [textColor2Dark]="appearanceForm.controls.text_color_2_dark.value"
    [textColor3]="appearanceForm.controls.text_color_3.value"
    [textColor3Dark]="appearanceForm.controls.text_color_3_dark.value"
    [borderColor]="appearanceForm.controls.border_color.value"
    [borderColorDark]="appearanceForm.controls.border_color_dark.value"
    [borderColor2]="appearanceForm.controls.border_color_2.value"
    [borderColor2Dark]="appearanceForm.controls.border_color_2_dark.value"
    [borderColor3]="appearanceForm.controls.border_color_3.value"
    [borderColor3Dark]="appearanceForm.controls.border_color_3_dark.value"
    [borderRadius]="appearanceForm.controls.border_radius.value"
    [fontRegular]="appearanceForm.controls.font_regular.value"
    [fontHeading]="appearanceForm.controls.font_heading.value"
  >
    <ng-container *ngFor="let block of menuBlocks; trackBy: trackMenuBlockItemFn">
      <div
        *ngIf="block.primary"
        class="menu2__side"
        [class.menu2__side_clickable]="blockControlActive !== block.control"
        [style]="block.style"
        (mouseenter)="blockControlPreviewHover$.next(block.control)"
        (mouseleave)="blockControlPreviewHover$.next(undefined)"
        (click)="onBlockControlPreviewClick(block, $event)"
        cdkOverlayOrigin
        #menu_origin="cdkOverlayOrigin"
      >
        <div
          class="menu2-side menu2-block"
          [class.menu2-side_light]="block.isLight === true"
          [class.menu2-side_dark]="block.isLight === false"
          [class.menu2-side_unset]="!(block.color | appIsSet) && !(block.backgroundColor | appIsSet)"
          [class.menu2-block_light]="block.isLight === true"
          [class.menu2-block_dark]="block.isLight === false"
          [class.menu2-block_unset]="!(block.color | appIsSet) && !(block.backgroundColor | appIsSet)"
          [class.menu2-side_disabled]="blockControlFocus && blockControlFocus !== block.control"
          [class.menu2-side_non-interactive]="blockControlActive !== block.control"
          [style.background]="(block.background | appIsSet) && !block.backgroundTransform ? block.background : null"
          [style.border-top]="block.borderTop"
          [style.border-right]="block.borderRight"
          [style.border-bottom]="block.borderBottom"
          [style.border-left]="block.borderLeft"
          [style.border-top-left-radius.px]="block.borderRadius?.topLeft || null"
          [style.border-top-right-radius.px]="block.borderRadius?.topRight || null"
          [style.border-bottom-right-radius.px]="block.borderRadius?.bottomRight || null"
          [style.border-bottom-left-radius.px]="block.borderRadius?.bottomLeft || null"
        >
          <div *ngIf="block.backgroundTransform" class="menu2-side__background">
            <div
              class="menu2-side__background-inner"
              [style.background]="block.background"
              [style.width]="block.backgroundWidth"
              [style.height]="block.backgroundHeight"
              [style.transform]="block.backgroundTransform"
              [style.border-top-left-radius.px]="block.borderRadius?.topLeft || null"
              [style.border-top-right-radius.px]="block.borderRadius?.topRight || null"
              [style.border-bottom-right-radius.px]="block.borderRadius?.bottomRight || null"
              [style.border-bottom-left-radius.px]="block.borderRadius?.bottomLeft || null"
            ></div>
          </div>

          <div class="menu2-side__start">
            <app-auto-menu-item
              *ngFor="let item of block.block.startItems; trackBy: trackMenuItemFn"
              [menuItem]="item"
              [menuBlock]="block.block"
              [primary]="true"
              [horizontal]="horizontal"
              [forceActive]="item === block.firstMenuItem"
            >
            </app-auto-menu-item>
          </div>

          <div class="menu2-side__main">
            <app-auto-menu-item
              *ngFor="let item of block.block.centerItems; trackBy: trackMenuItemFn"
              [menuItem]="item"
              [menuBlock]="block.block"
              [primary]="true"
              [horizontal]="horizontal"
              [forceActive]="item === block.firstMenuItem"
            >
            </app-auto-menu-item>
          </div>

          <div class="menu2-side__end">
            <app-auto-menu-item
              *ngFor="let item of block.block.endItems; trackBy: trackMenuItemFn"
              [menuItem]="item"
              [menuBlock]="block.block"
              [primary]="true"
              [horizontal]="horizontal"
              [forceActive]="item === block.firstMenuItem"
            >
            </app-auto-menu-item>
          </div>
        </div>

        <app-gradient-position
          *ngIf="customizeGradient && customizeGradient.source === block.control"
          [control]="customizeGradient.control"
          [activeStop]="customizeGradient.activeStop"
          [origin]="menu_origin"
        ></app-gradient-position>
      </div>

      <div
        *ngIf="!block.primary"
        class="menu2__main"
        [class.menu2__main_clickable]="blockControlActive !== block.control"
        [style]="block.style"
        (mouseenter)="blockControlPreviewHover$.next(block.control)"
        (mouseleave)="blockControlPreviewHover$.next(undefined)"
        (click)="onBlockControlPreviewClick(block, $event)"
        cdkOverlayOrigin
        #menu_origin="cdkOverlayOrigin"
      >
        <div
          class="menu2-main menu2-main_theme menu2-block"
          [class.menu2-main_light]="block.isLight === true"
          [class.menu2-main_dark]="block.isLight === false"
          [class.menu2-main_unset]="!(block.color | appIsSet) && !(block.backgroundColor | appIsSet)"
          [class.menu2-block_light]="block.isLight === true"
          [class.menu2-block_dark]="block.isLight === false"
          [class.menu2-block_unset]="!(block.color | appIsSet) && !(block.backgroundColor | appIsSet)"
          [class.menu2-side_disabled]="blockControlFocus && blockControlFocus !== block.control"
          [class.menu2-main_non-interactive]="blockControlActive !== block.control"
          [style.background]="(block.background | appIsSet) && !block.backgroundTransform ? block.background : null"
          [style.border-top]="block.borderTop"
          [style.border-right]="block.borderRight"
          [style.border-bottom]="block.borderBottom"
          [style.border-left]="block.borderLeft"
          [style.border-top-left-radius.px]="block.borderRadius?.topLeft || block.borderRadius?.all || null"
          [style.border-top-right-radius.px]="block.borderRadius?.topRight || block.borderRadius?.all || null"
          [style.border-bottom-right-radius.px]="block.borderRadius?.bottomRight || block.borderRadius?.all || null"
          [style.border-bottom-left-radius.px]="block.borderRadius?.bottomLeft || block.borderRadius?.all || null"
        >
          <div *ngIf="block.backgroundTransform" class="menu2-main__background">
            <div
              class="menu2-main__background-inner"
              [style.background]="block.background"
              [style.width]="block.backgroundWidth"
              [style.height]="block.backgroundHeight"
              [style.transform]="block.backgroundTransform"
              [style.border-top-left-radius.px]="block.borderRadius?.topLeft || block.borderRadius?.all || null"
              [style.border-top-right-radius.px]="block.borderRadius?.topRight || block.borderRadius?.all || null"
              [style.border-bottom-right-radius.px]="block.borderRadius?.bottomRight || block.borderRadius?.all || null"
              [style.border-bottom-left-radius.px]="block.borderRadius?.bottomLeft || block.borderRadius?.all || null"
            ></div>
          </div>

          <div
            *ngIf="!horizontal"
            class="menu2-main__viewport"
            xsScrollable
            [xsScrollableOptions]="{ stopPropagationAlways: true }"
          >
            <div class="menu2-main__content menu2-main__containers">
              <div class="menu2-main__start">
                <app-auto-menu-item
                  *ngFor="let item of block.block.startItems; trackBy: trackMenuItemFn"
                  [menuItem]="item"
                  [menuBlock]="block.block"
                  [primary]="false"
                  [horizontal]="horizontal"
                  [forceActive]="item === block.firstMenuItem"
                >
                </app-auto-menu-item>
              </div>

              <div class="menu2-main__main">
                <app-auto-menu-item
                  *ngFor="let item of block.block.centerItems; trackBy: trackMenuItemFn"
                  [menuItem]="item"
                  [menuBlock]="block.block"
                  [primary]="false"
                  [horizontal]="horizontal"
                  [forceActive]="item === block.firstMenuItem"
                >
                </app-auto-menu-item>
              </div>

              <div class="menu2-main__end">
                <app-auto-menu-item
                  *ngFor="let item of block.block.endItems; trackBy: trackMenuItemFn"
                  [menuItem]="item"
                  [menuBlock]="block.block"
                  [primary]="false"
                  [horizontal]="horizontal"
                  [forceActive]="item === block.firstMenuItem"
                >
                </app-auto-menu-item>
              </div>
            </div>
          </div>

          <div *ngIf="horizontal" class="menu2-main__content menu2-main__containers">
            <div class="menu2-main__start">
              <app-auto-menu-item
                *ngFor="let item of block.block.startItems; trackBy: trackMenuItemFn"
                [menuItem]="item"
                [menuBlock]="block.block"
                [primary]="false"
                [horizontal]="horizontal"
                [forceActive]="item === block.firstMenuItem"
              >
              </app-auto-menu-item>
            </div>

            <div class="menu2-main__main">
              <app-auto-menu-item
                *ngFor="let item of block.block.centerItems; trackBy: trackMenuItemFn"
                [menuItem]="item"
                [menuBlock]="block.block"
                [primary]="false"
                [horizontal]="horizontal"
                [forceActive]="item === block.firstMenuItem"
              >
              </app-auto-menu-item>
            </div>

            <div class="menu2-main__end">
              <app-auto-menu-item
                *ngFor="let item of block.block.endItems; trackBy: trackMenuItemFn"
                [menuItem]="item"
                [menuBlock]="block.block"
                [primary]="false"
                [horizontal]="horizontal"
                [forceActive]="item === block.firstMenuItem"
              >
              </app-auto-menu-item>
            </div>
          </div>
        </div>

        <app-gradient-position
          *ngIf="customizeGradient && customizeGradient.source === block.control"
          [control]="customizeGradient.control"
          [activeStop]="customizeGradient.activeStop"
          [origin]="menu_origin"
        ></app-gradient-position>
      </div>
    </ng-container>
  </div>
</ng-template>

<div
  class="admin-template"
  [style.background]="
    (themeService.isDefaultTheme$ | async)
      ? appearanceForm.controls.background_color.value || null
      : appearanceForm.controls.background_color_dark.value || null
  "
>
  <div class="admin-template__vertical">
    <div class="admin-template__vertical-top" #top_menu>
      <ng-container *ngIf="topMenuBlocks.length">
        <ng-container *ngTemplateOutlet="menu; context: { menuBlocks: topMenuBlocks, horizontal: true }"></ng-container>
      </ng-container>
    </div>

    <div class="admin-template__vertical-main">
      <div class="admin-template__horizontal">
        <div
          *ngIf="leftMenuBlocks.length"
          class="admin-template__horizontal-left"
          [style.height.px]="containerHeight ? containerHeight - top_menu.offsetHeight : null"
          [style.top.px]="containerHeight ? top_menu.offsetHeight : null"
        >
          <ng-container
            *ngTemplateOutlet="menu; context: { menuBlocks: leftMenuBlocks, horizontal: false }"
          ></ng-container>
        </div>

        <div class="admin-template__horizontal-main">
          <div class="admin-template__vertical">
            <div *ngIf="topContentMenuBlocks.length" class="admin-template__vertical-top">
              <ng-container
                *ngTemplateOutlet="menu; context: { menuBlocks: topContentMenuBlocks, horizontal: true }"
              ></ng-container>
            </div>

            <div
              class="admin-template__vertical-main admin-template__main"
              [class.admin-template__main_disabled]="contentDisabled"
            >
              <!--              <div *ngIf="!topMenuBlocks.length" class="admin-header">-->
              <!--                <div class="admin-header__inner">-->
              <!--                  <div class="admin-header__main">-->
              <!--                    <span class="admin-header__title admin-header__item">Page Title</span>-->
              <!--                  </div>-->
              <!--  -->
              <!--                  <div class="admin-header__right">-->
              <!--                    <ng-container *ngIf="settingsForm.controls.collaboration_enabled.value">-->
              <!--                      <div class="user-photos user-photos_small user-photos_clickable user-photos_background_body admin-header__user-photos">-->
              <!--                        <a class="user-photos__item"></a>-->
              <!--                        <a class="user-photos__item"></a>-->
              <!--                        <a class="user-photos__item"></a>-->
              <!--                        <a class="user-photos__item"></a>-->
              <!--                        <a class="user-photos__item"></a>-->
              <!--                        <a class="user-photos__add icon-plus"></a>-->
              <!--                      </div>-->
              <!--  -->
              <!--                      <a href="javascript:void(0)" class="admin-header-link admin-header__item">-->
              <!--                        <span class="admin-header-link__icon icon-chat"></span> Collaborations-->
              <!--                      </a>-->
              <!--                    </ng-container>-->
              <!--  -->
              <!--                    <a-->
              <!--                      *ngIf="settingsForm.controls.activity_log_enabled.value"-->
              <!--                      href="javascript:void(0)"-->
              <!--                      class="admin-header-link admin-header__item"-->
              <!--                    >-->
              <!--                      <span class="admin-header-link__icon icon-book"></span>Activity Log-->
              <!--                    </a>-->
              <!--  -->
              <!--                    &lt;!&ndash;                <a&ndash;&gt;-->
              <!--                    &lt;!&ndash;                  *ngIf="!projectLayouts.hasMenuPrimary(settingsForm.controls.layout.value)"&ndash;&gt;-->
              <!--                    &lt;!&ndash;                  href="javascript:void(0)"&ndash;&gt;-->
              <!--                    &lt;!&ndash;                  class="admin-header__item"&ndash;&gt;-->
              <!--                    &lt;!&ndash;                >&ndash;&gt;-->
              <!--                    &lt;!&ndash;                  <div class="admin-header__button admin-header__button_orange icon-brush"></div>&ndash;&gt;-->
              <!--                    &lt;!&ndash;                </a>&ndash;&gt;-->
              <!--  -->
              <!--                    &lt;!&ndash;                <a&ndash;&gt;-->
              <!--                    &lt;!&ndash;                  *ngIf="!projectLayouts.hasMenuPrimary(settingsForm.controls.layout.value)"&ndash;&gt;-->
              <!--                    &lt;!&ndash;                  href="javascript:void(0)"&ndash;&gt;-->
              <!--                    &lt;!&ndash;                  class="admin-header__item admin-header__profile"&ndash;&gt;-->
              <!--                    &lt;!&ndash;                  [style.background-image]="&ndash;&gt;-->
              <!--                    &lt;!&ndash;              (currentUserStore.instance$ | async)?.photo&ndash;&gt;-->
              <!--                    &lt;!&ndash;                ? 'url(' + (currentUserStore.instance$ | async)?.photo + ')'&ndash;&gt;-->
              <!--                    &lt;!&ndash;                : ''&ndash;&gt;-->
              <!--                    &lt;!&ndash;            "&ndash;&gt;-->
              <!--                    &lt;!&ndash;                ></a>&ndash;&gt;-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--              </div>-->

              <app-external-fonts [fonts]="externalFonts"></app-external-fonts>

              <div
                class="app-page theme-enabled"
                appThemeOptions
                [applyElementStyle]="true"
                [applyGlobalStyle]="false"
                [accentColor]="appearanceForm.getAccentColor()"
                [accentColorDark]="appearanceForm.getAccentColorDark()"
                [backgroundColor]="appearanceForm.controls.background_color.value"
                [backgroundColorDark]="appearanceForm.controls.background_color_dark.value"
                [backgroundColor2]="appearanceForm.controls.background_color_2.value"
                [backgroundColor2Dark]="appearanceForm.controls.background_color_2_dark.value"
                [backgroundColor3]="appearanceForm.controls.background_color_3.value"
                [backgroundColor3Dark]="appearanceForm.controls.background_color_3_dark.value"
                [backgroundColor4]="appearanceForm.controls.background_color_4.value"
                [backgroundColor4Dark]="appearanceForm.controls.background_color_4_dark.value"
                [backgroundColor5]="appearanceForm.controls.background_color_5.value"
                [backgroundColor5Dark]="appearanceForm.controls.background_color_5_dark.value"
                [textColor]="appearanceForm.controls.text_color.value"
                [textColorDark]="appearanceForm.controls.text_color_dark.value"
                [textColor2]="appearanceForm.controls.text_color_2.value"
                [textColor2Dark]="appearanceForm.controls.text_color_2_dark.value"
                [textColor3]="appearanceForm.controls.text_color_3.value"
                [textColor3Dark]="appearanceForm.controls.text_color_3_dark.value"
                [borderColor]="appearanceForm.controls.border_color.value"
                [borderColorDark]="appearanceForm.controls.border_color_dark.value"
                [borderColor2]="appearanceForm.controls.border_color_2.value"
                [borderColor2Dark]="appearanceForm.controls.border_color_2_dark.value"
                [borderColor3]="appearanceForm.controls.border_color_3.value"
                [borderColor3Dark]="appearanceForm.controls.border_color_3_dark.value"
                [borderRadius]="appearanceForm.controls.border_radius.value"
                [fontRegular]="appearanceForm.controls.font_regular.value"
                [fontHeading]="appearanceForm.controls.font_heading.value"
                [elementWrapperBorder]="elementWrapperStyles?.borderSettings"
                [elementWrapperBorderRadius]="elementWrapperStyles?.borderRadius"
                [elementWrapperShadow]="elementWrapperStyles?.shadow"
                [elementWrapperMargin]="elementWrapperStyles?.margin"
              >
                <div
                  class="app-page__bounds"
                  [style.max-width.px]="
                    (preview == previewTypes.Default ? [appearanceForm.controls.max_width.value] : [])
                      | appFirstSet: null
                  "
                  [style.padding-top.px]="[appearanceForm.controls.padding.controls.top.value] | appFirstSet: null"
                  [style.padding-right.px]="[appearanceForm.controls.padding.controls.right.value] | appFirstSet: null"
                  [style.padding-bottom.px]="
                    [appearanceForm.controls.padding.controls.bottom.value] | appFirstSet: null
                  "
                  [style.padding-left.px]="[appearanceForm.controls.padding.controls.left.value] | appFirstSet: null"
                >
                  <app-admin-template-page
                    *ngIf="preview == previewTypes.Default"
                    [appearanceForm]="appearanceForm"
                    [fieldElementStyles]="fieldElementStyles"
                    [actionElementStylesPrimary]="actionElementStylesPrimary"
                    [actionElementStylesDefault]="actionElementStylesDefault"
                  ></app-admin-template-page>

                  <app-styles-preview-button
                    *ngIf="preview == previewTypes.Buttons"
                    [stylesControlPrimary]="appearanceForm.controls.action_element_styles_primary"
                    [stylesControlDefault]="appearanceForm.controls.action_element_styles_default"
                    [stylesControlTransparent]="appearanceForm.controls.action_element_styles_transparent"
                    [accentColor]="
                      themeService.isDarkTheme ? appearanceForm.getAccentColorDark() : appearanceForm.getAccentColor()
                    "
                  ></app-styles-preview-button>

                  <app-styles-preview-field
                    *ngIf="preview == previewTypes.Fields"
                    [control]="appearanceForm.controls.field_element_styles"
                    [accentColor]="
                      themeService.isDarkTheme ? appearanceForm.getAccentColorDark() : appearanceForm.getAccentColor()
                    "
                  ></app-styles-preview-field>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
