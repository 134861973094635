export const FIREBASE_PRIMARY_KEY = '__jet_pk__';
export const FIREBASE_ITEM_PRIMARY_KEY = '__jet_item_pk__';
export const FIREBASE_CREATED_TIME = '__created_time__';
export const FIREBASE_UPDATED_TIME = '__updated_time__';
export const FIREBASE_PARENT = '__parent__';

export enum FirebaseDatabaseInstanceType {
  Unspecified = 'DATABASE_INSTANCE_TYPE_UNSPECIFIED',
  Default = 'DEFAULT_DATABASE',
  User = 'USER_DATABASE'
}

export enum FirebaseDatabaseState {
  Unspecified = 'LIFECYCLE_STATE_UNSPECIFIED',
  Active = 'ACTIVE',
  Disabled = 'DISABLED',
  Deleted = 'DELETED'
}

export interface FirebaseDatabase {
  name: string;
  project: string;
  databaseUrl: string;
  type: FirebaseDatabaseInstanceType;
  state: FirebaseDatabaseState;
}

export interface FirebaseDatabasesResponse {
  instances?: FirebaseDatabase[];
}

export interface FirebaseFirestoreCollectionIdsResponse {
  collectionIds: string[];
}

export enum FirebaseDatabaseType {
  Firestore = 'firestore',
  Realtime = 'realtime'
}

export interface FirebaseDatabaseOption {
  type: FirebaseDatabaseType;
  id?: string;
}

export enum FirebaseRealTime {
  Disabled = 'disabled',
  Functions = 'functions',
  Snapshots = 'snapshots'
}

export interface FirebaseFunctionsRequirement {
  title: string;
  description?: string;
  link?: string;
  status?: boolean;
}

export interface FirebaseFunctionsCheckConfigurationResponse {
  result: boolean;
  error?: string;
  requirements: FirebaseFunctionsRequirement[];
}
