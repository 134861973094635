import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { PopupRef } from '@common/popups';
import { ServerRequestError } from '@modules/api';
import { DraftChangesService } from '@modules/customize-utils';
import {
  CurrentEnvironmentStore,
  CurrentProjectStore,
  Resource,
  ResourceName,
  ResourceType,
  ResourceTypeItem
} from '@modules/projects';
import { FirebaseParamsOptions, ResourceGeneratorResolver } from '@modules/resource-generators';
import { isResourceCustom } from '@modules/resources';

@Component({
  selector: 'app-confirm-sync-mode',
  templateUrl: './confirm-sync-mode.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmSyncModeComponent implements OnInit, OnDestroy {
  @Input() object: string;
  @Input() sync: boolean;
  @Input() resource: Resource;
  @Input() typeItem: ResourceTypeItem;
  @Input() closeOnConfirm = true;
  @Output() confirm = new EventEmitter<{ paramsOptions?: Object }>();

  loading = false;
  paramsOptions: Object;
  paramsOptionsLoading = false;
  paramsOptionsError: string;
  isPublishRequired = false;
  hasDraftChanges = false;
  resourceNames = ResourceName;

  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private resourceGeneratorResolver: ResourceGeneratorResolver,
    private draftChangesService: DraftChangesService,
    @Optional() private popupRef: PopupRef,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.isPublishRequired = this.typeItem.resourceType == ResourceType.RestAPI && isResourceCustom(this.resource);
    this.cd.markForCheck();

    this.draftChangesService
      .getDraftChanges$({
        resource: this.resource ? this.resource.uniqueName : undefined
      })
      .pipe(untilDestroyed(this))
      .subscribe(changes => {
        this.hasDraftChanges = changes.filter(item => item.count).length > 0;
        this.cd.markForCheck();
      });

    if (this.typeItem.name == ResourceName.Firebase && this.sync) {
      this.paramsOptionsLoading = true;
      this.paramsOptionsError = undefined;
      this.cd.markForCheck();

      const generator = this.resourceGeneratorResolver.get(this.resource.typeItem.name);
      return generator
        .getParamsOptions(this.currentProjectStore.instance, this.currentEnvironmentStore.instance, this.resource)
        .pipe(untilDestroyed(this))
        .subscribe(
          options => {
            this.paramsOptions = options;
            this.paramsOptionsLoading = false;
            this.cd.markForCheck();
          },
          error => {
            if (error instanceof ServerRequestError && error.errors.length) {
              this.paramsOptionsError = error.errors[0];
            } else {
              this.paramsOptionsError = error;
            }

            this.paramsOptionsLoading = false;
            this.cd.markForCheck();
          }
        );
    }
  }

  ngOnDestroy(): void {}

  setLoading(loading: boolean) {
    this.loading = loading;
    this.cd.markForCheck();
  }

  submit(options: { paramsOptions?: Object } = {}) {
    this.confirm.emit(options);

    if (this.closeOnConfirm) {
      this.close();
    }
  }

  close() {
    if (this.popupRef) {
      this.popupRef.close();
    }
  }

  asFirebaseParamsOptions(options: Object): FirebaseParamsOptions {
    return options as FirebaseParamsOptions;
  }
}
