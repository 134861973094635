import Delta from 'quill-delta';

import { TextDecoration, TextTransform } from '@modules/styles';

import { Border } from '../border';
import { Color } from '../color';
import { Fill } from '../fill';
import { Font } from '../font';
import { Layer } from '../layer';
import { LayerType } from '../layer-type';
import { registerLayerType } from '../layer-types';
import { Shadow } from '../shadow';

export enum AlignHorizontal {
  Justify = 'justify',
  Left = 'left',
  Center = 'center',
  Right = 'right'
}

export enum AlignVertical {
  Top = 'top',
  Center = 'center',
  Bottom = 'bottom'
}

export enum TextTruncate {
  SingleLine = 'single_line',
  Custom = 'custom'
}

export class TextLayer extends Layer {
  public type = LayerType.Text;
  public icon = 'text_2';
  public defaultName = 'text';
  public quillDelta: Delta;
  public font: Font;
  public fills: Fill[] = [];
  public borders: Border[] = [];
  public shadows: Shadow[] = [];
  public lineHeight: number;
  public letterSpacing: number;
  public alignHorizontal: AlignHorizontal = AlignHorizontal.Left;
  public alignVertical: AlignVertical = AlignVertical.Top;
  public transform: TextTransform;
  public decoration: TextDecoration;
  public truncate: TextTruncate;
  public truncateMaxLines: number;

  constructor(options: Partial<TextLayer> = {}) {
    super(options);
  }

  deserialize(data: Object): this {
    super.deserialize(data);
    this.lineHeight = data['line_height'];
    this.letterSpacing = data['letter_spacing'];
    this.alignHorizontal = data['align_horizontal'];
    this.alignVertical = data['align_vertical'];
    this.transform = data['transform'];
    this.decoration = data['decoration'];
    this.truncate = data['truncate'];
    this.truncateMaxLines = data['truncate_max_lines'];

    if (data['width_fluid']) {
      this.widthFluid = data['width_fluid'];
    }

    if (data['height_fluid']) {
      this.heightFluid = data['height_fluid'];
    }

    if (data['quill_delta']) {
      this.quillDelta = data['quill_delta'];
    }

    if (data['font']) {
      this.font = new Font().deserialize(data['font']);
    }

    if (data['fills']) {
      this.fills = data['fills'].map(item => new Fill().deserialize(item));
    } else if (data['color']) {
      // Backward compatibility
      const fill = new Fill();

      fill.generateId();
      fill.color = new Color().deserialize(data['color']);

      this.fills = [fill];
    }

    if (data['borders']) {
      this.borders = data['borders'].map(item => new Border().deserialize(item));
    } else if (data['border']) {
      // Backward compatibility
      this.borders = [new Border().deserialize(data['border'])];
    }

    if (data['shadows']) {
      this.shadows = data['shadows'].map(item => new Shadow().deserialize(item));
    }

    return this;
  }

  serialize(): Object {
    return {
      ...super.serialize(),
      width_fluid: this.widthFluid,
      height_fluid: this.heightFluid,
      quill_delta: this.quillDelta,
      font: this.font ? this.font.serialize() : undefined,
      fills: this.fills.map(item => item.serialize()),
      borders: this.borders.map(item => item.serialize()),
      shadows: this.shadows.map(item => item.serialize()),
      line_height: this.lineHeight,
      letter_spacing: this.letterSpacing,
      align_horizontal: this.alignHorizontal,
      align_vertical: this.alignVertical,
      transform: this.transform,
      decoration: this.decoration,
      truncate: this.truncate,
      truncate_max_lines: this.truncateMaxLines
    };
  }

  setText(text: string) {
    this.quillDelta = { ops: [{ insert: text }] };
  }

  cssTextAlign(): string {
    if (this.alignHorizontal == AlignHorizontal.Left) {
      return 'left';
    } else if (this.alignHorizontal == AlignHorizontal.Center) {
      return 'center';
    } else if (this.alignHorizontal == AlignHorizontal.Right) {
      return 'right';
    } else if (this.alignHorizontal == AlignHorizontal.Justify) {
      return 'justify';
    }
  }

  cssTextTransform(): string {
    if (this.transform == TextTransform.Lowercase) {
      return 'lowercase';
    } else if (this.transform == TextTransform.Uppercase) {
      return 'uppercase';
    } else if (this.transform == TextTransform.Capitalize) {
      return 'capitalize';
    }
  }

  cssTextDecoration(): string {
    if (this.decoration == TextDecoration.Underline) {
      return 'underline';
    } else if (this.decoration == TextDecoration.Strikethrough) {
      return 'line-through';
    }
  }
}

registerLayerType(LayerType.Text, TextLayer);
