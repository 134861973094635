import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  BorderSettingsControl,
  CornersControl,
  ElementWrapperStyles,
  MarginControl,
  ShadowControl
} from '@modules/customize';
import { controlValue } from '@shared';

export class ElementWrapperStylesControl extends FormGroup {
  controls: {
    border_settings: BorderSettingsControl;
    border_radius: CornersControl;
    shadow: ShadowControl;
    margin: MarginControl;
  };

  constructor() {
    super({
      border_settings: new BorderSettingsControl(),
      border_radius: new CornersControl(),
      shadow: new ShadowControl(),
      margin: new MarginControl()
    });
  }

  deserialize(instance?: ElementWrapperStyles) {
    this.controls.border_settings.deserialize(instance ? instance.borderSettings : undefined);
    this.controls.border_radius.deserialize(instance ? instance.borderRadius : undefined);
    this.controls.shadow.deserialize(instance ? instance.shadow : undefined);
    this.controls.margin.deserialize(instance ? instance.margin : undefined);
  }

  isSet(): boolean {
    return [
      this.controls.border_settings,
      this.controls.border_radius,
      this.controls.shadow,
      this.controls.margin
    ].some(control => control.isSet());
  }

  serialize(): ElementWrapperStyles {
    if (!this.isSet()) {
      return;
    }

    return new ElementWrapperStyles({
      borderSettings: this.controls.border_settings.serialize(false),
      borderRadius: this.controls.border_radius.serialize(),
      shadow: this.controls.shadow.serialize(false),
      margin: this.controls.margin.serialize()
    });
  }

  serialize$(): Observable<ElementWrapperStyles> {
    return controlValue(this, { debounce: 200 }).pipe(map(() => this.serialize()));
  }
}
