import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import {
  FieldType,
  formatTimeField,
  getTimeFieldMomentFormat,
  isTimeFieldClock12Format,
  registerFieldComponent
} from '@modules/fields';

import { FieldComponent } from '../field/field.component';

@Component({
  selector: 'app-time-field',
  templateUrl: 'time-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimeFieldComponent extends FieldComponent implements OnInit {
  editFormat: string;
  editFormatClock12 = false;
  datepickerClasses: string[] = [];

  ngOnInit(): void {
    this.editFormat = getTimeFieldMomentFormat(this.field, true);
    this.editFormatClock12 = isTimeFieldClock12Format(this.field, true);
    this.datepickerClasses = this.field.params['theme'] ? ['datepicker_theme'] : [];
  }

  // TODO: Move to separate params option
  get inputSmall() {
    return this.field.params['classes'] && this.field.params['classes'].indexOf('input_small') != -1;
  }

  get valueStr() {
    if (!this.currentValue) {
      return this.formatEmpty(this.currentValue);
    }

    return formatTimeField(this.currentValue, this.field);
  }
}

registerFieldComponent(FieldType.Time, TimeFieldComponent);
