var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';
import { ThemeService } from '@modules/theme';
import { controlValue } from '@shared';
import { TextEditorSelectionOverlay } from '../text-editor/text-editor-selection-overlay';
var TextEditorBackgroundPickerComponent = /** @class */ (function (_super) {
    __extends(TextEditorBackgroundPickerComponent, _super);
    function TextEditorBackgroundPickerComponent(themeService) {
        var _this = _super.call(this) || this;
        _this.themeService = themeService;
        _this.backgroundDefaultControl = new FormControl();
        _this.backgroundDarkControl = new FormControl();
        return _this;
    }
    TextEditorBackgroundPickerComponent.prototype.ngOnInit = function () { };
    TextEditorBackgroundPickerComponent.prototype.ngOnDestroy = function () { };
    TextEditorBackgroundPickerComponent.prototype.initValue = function () {
        var _this = this;
        if (this.value) {
            this.backgroundDefaultControl.patchValue(this.value.backgroundDefault);
            this.backgroundDarkControl.patchValue(this.value.backgroundDark);
        }
        combineLatest(controlValue(this.backgroundDefaultControl), controlValue(this.backgroundDarkControl))
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var backgroundDefault = _a[0], backgroundDark = _a[1];
            _this.valueChange.emit(__assign({}, _this.value, { backgroundDefault: backgroundDefault, backgroundDark: backgroundDark }));
        });
    };
    TextEditorBackgroundPickerComponent.prototype.resetCurrentColor = function () {
        if (this.themeService.isDefaultTheme) {
            this.backgroundDefaultControl.patchValue(undefined);
        }
        if (this.themeService.isDarkTheme) {
            this.backgroundDarkControl.patchValue(undefined);
        }
    };
    return TextEditorBackgroundPickerComponent;
}(TextEditorSelectionOverlay));
export { TextEditorBackgroundPickerComponent };
