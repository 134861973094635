import { ChangeDetectorRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';
import { TextStyle } from '@modules/customize';
import { getFontFamilyVariable, weightOptions } from '@modules/styles';
import { globalFontOptions, ThemeService } from '@modules/theme';
import { firstSet, isSet } from '@shared';
import { stylesEditPopoverPositions } from '../styles-edit/styles-edit-popover-positions';
import { TextStyleControl } from '../text-style-edit/text-style.control';
var StylesEditTextStyleComponent = /** @class */ (function () {
    function StylesEditTextStyleComponent(themeService, sanitizer, cd) {
        this.themeService = themeService;
        this.sanitizer = sanitizer;
        this.cd = cd;
        this.subtitle = 'Text style';
        this.contrast = false;
        this.disabled = false;
        this.added = false;
        this.removeEnabled = true;
        this.remove = new EventEmitter();
        this.popoverPositions = stylesEditPopoverPositions;
    }
    StylesEditTextStyleComponent.prototype.ngOnInit = function () {
        var _this = this;
        var defaultStyle = new TextStyle();
        combineLatest(this.control.serialize$(), this.themeService.isDarkTheme$)
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var textStyle = _a[0], isDarkTheme = _a[1];
            var fontFamily = firstSet(textStyle ? textStyle.fontFamily : undefined, defaultStyle ? defaultStyle.fontFamily : undefined);
            var fontWeight = firstSet(textStyle ? textStyle.fontWeight : undefined, defaultStyle ? defaultStyle.fontWeight : undefined);
            var fontWeightName = weightOptions.filter(function (item) { return item.value == fontWeight; }).map(function (item) { return item.name; })[0];
            var fontSize = firstSet(textStyle ? textStyle.fontSize : undefined, defaultStyle ? defaultStyle.fontSize : undefined);
            var color = firstSet(textStyle ? textStyle.cssColor(isDarkTheme) : undefined, defaultStyle ? defaultStyle.cssColor(isDarkTheme) : undefined);
            _this.color = isSet(color) ? _this.sanitizer.bypassSecurityTrustStyle(color) : undefined;
            var valueStrParts = [];
            if (fontSize) {
                valueStrParts.push(fontSize + "px");
            }
            if (fontFamily) {
                var fontFamilyVariable_1 = getFontFamilyVariable(fontFamily);
                var fontFamilyGlobal = isSet(fontFamilyVariable_1)
                    ? globalFontOptions.find(function (item) { return item.value == "--" + fontFamilyVariable_1; })
                    : undefined;
                var fontName = fontFamilyGlobal ? fontFamilyGlobal.name : fontFamily;
                valueStrParts.push(valueStrParts.length ? "- " + fontName : fontName);
            }
            if (fontWeightName) {
                valueStrParts.push(valueStrParts.length ? "(" + fontWeightName + ")" : fontWeightName);
            }
            if (!textStyle) {
                _this.valueStr = undefined;
            }
            else if (valueStrParts.length) {
                _this.valueStr = valueStrParts.join(' ');
            }
            else {
                _this.valueStr = undefined;
            }
            _this.cd.markForCheck();
        });
    };
    StylesEditTextStyleComponent.prototype.ngOnDestroy = function () { };
    return StylesEditTextStyleComponent;
}());
export { StylesEditTextStyleComponent };
