import { Option } from '@modules/field-components';

import { FontStyle } from './font-style';
import { FontType } from './font-type';
import { weightOptions } from './weight-optionts';

export const fontTypeOptions: Option<FontType>[] = weightOptions.reduce((acc, weight) => {
  acc.push(
    {
      name: weight.name,
      value: { weight: weight.value, style: FontStyle.Normal },
      style: `font-weight: ${weight.value}`
    },
    {
      name: `${weight.name} Italic`,
      value: { weight: weight.value, style: FontStyle.Italic },
      style: [`font-weight: ${weight.value}`, 'font-style: italic'].join(';')
    }
  );

  return acc;
}, []);
