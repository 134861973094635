import { Injectable, Injector } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

import { TintStyle } from '@modules/actions';
import { Input } from '@modules/fields';
import { ButtonMenuItem } from '@modules/menu';
import { FieldInputControl } from '@modules/parameters';

import { MenuItemActionControl } from './menu-item-action.control';

import {
  CustomizeBarPagesEditMenuItemArray,
  CustomizeBarPagesEditMenuItemControl,
  menuItemControls
} from './customize-bar-pages-edit.form';

@Injectable()
export class CustomizeBarPagesEditButtonControl extends CustomizeBarPagesEditMenuItemControl {
  controls: {
    icon: FormControl;
    title: FormControl;
    action: MenuItemActionControl;
    style: FormControl;
    tint: FormControl;
    children: CustomizeBarPagesEditMenuItemArray;
    visible_input: FieldInputControl;
  };

  styleOptions = [
    {
      value: TintStyle.Primary,
      image: 'button-primary'
    },
    {
      value: TintStyle.Default,
      image: 'button-default'
    },
    {
      value: TintStyle.Transparent,
      image: 'button-transparent'
    }
  ];

  constructor(private injector: Injector) {
    super({
      icon: new FormControl(''),
      title: new FormControl('New Page', Validators.required),
      action: MenuItemActionControl.inject(injector),
      style: new FormControl(TintStyle.Primary),
      tint: new FormControl(null),
      children: CustomizeBarPagesEditMenuItemArray.inject(injector),
      visible_input: new FieldInputControl({ path: ['value'] })
    });
  }

  static inject(injector: Injector): CustomizeBarPagesEditButtonControl {
    return Injector.create({
      providers: [
        {
          provide: CustomizeBarPagesEditButtonControl,
          useFactory: () => {
            return new CustomizeBarPagesEditButtonControl(injector);
          },
          deps: []
        }
      ],
      parent: injector
    }).get(CustomizeBarPagesEditButtonControl);
  }

  get children() {
    return this.controls['children'] as CustomizeBarPagesEditMenuItemArray;
  }

  deserialize(item: ButtonMenuItem) {
    this.menuItem = item;

    this.controls.icon.patchValue(item.icon);
    this.controls.title.patchValue(item.title);
    this.controls.action.deserialize(item.action);
    this.controls.style.patchValue(item.style);
    this.controls.tint.patchValue(item.tint);
    this.children.deserialize(item.children);
    this.controls.visible_input.patchValue(item.visibleInput ? item.visibleInput.serializeWithoutPath() : {});

    this.markAsPristine();
  }

  serialize(): ButtonMenuItem {
    const instance = new ButtonMenuItem().deserialize(this.menuItem.serialize());

    instance.icon = this.controls.icon.value;
    instance.title = this.controls.title.value;
    instance.action = this.controls.action.serialize();
    instance.style = this.controls.style.value;
    instance.tint = this.controls.tint.value;
    instance.children = this.children.serialize();
    instance.visibleInput = this.controls.visible_input.value
      ? new Input().deserialize(this.controls.visible_input.value)
      : undefined;

    return instance;
  }
}

menuItemControls.push({ menuItem: ButtonMenuItem, control: CustomizeBarPagesEditButtonControl });
