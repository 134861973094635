var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormControl, FormGroup } from '@angular/forms';
import { map } from 'rxjs/operators';
import { controlValue, isSet } from '@shared';
import { Shadow } from './shadow';
var ShadowControl = /** @class */ (function (_super) {
    __extends(ShadowControl, _super);
    function ShadowControl(state) {
        if (state === void 0) { state = {}; }
        return _super.call(this, {
            color: new FormControl(isSet(state.color) ? state.color : ''),
            color_dark: new FormControl(isSet(state.colorDark) ? state.colorDark : ''),
            offset_x: new FormControl(isSet(state.offsetX) ? state.offsetX : undefined),
            offset_y: new FormControl(isSet(state.offsetY) ? state.offsetY : undefined),
            blur_radius: new FormControl(isSet(state.blurRadius) ? state.blurRadius : undefined),
            spread_radius: new FormControl(isSet(state.spreadRadius) ? state.spreadRadius : undefined)
        }) || this;
    }
    ShadowControl.prototype.deserialize = function (value, options) {
        if (options === void 0) { options = {}; }
        this.instance = value;
        this.controls.color.patchValue(value ? value.color : '', { emitEvent: options.emitEvent });
        this.controls.color_dark.patchValue(value ? value.colorDark : '', { emitEvent: options.emitEvent });
        this.controls.offset_x.patchValue(value ? value.offsetX : undefined, { emitEvent: options.emitEvent });
        this.controls.offset_y.patchValue(value ? value.offsetY : undefined, { emitEvent: options.emitEvent });
        this.controls.blur_radius.patchValue(value ? value.blurRadius : undefined, { emitEvent: options.emitEvent });
        this.controls.spread_radius.patchValue(value ? value.spreadRadius : undefined, { emitEvent: options.emitEvent });
    };
    ShadowControl.prototype.isSet = function () {
        return (isSet(this.controls.color.value) ||
            isSet(this.controls.color_dark.value) ||
            isSet(this.controls.offset_x.value) ||
            isSet(this.controls.offset_y.value) ||
            isSet(this.controls.blur_radius.value) ||
            isSet(this.controls.spread_radius.value));
    };
    ShadowControl.prototype.isSet$ = function () {
        var _this = this;
        return controlValue(this).pipe(map(function () { return _this.isSet(); }));
    };
    ShadowControl.prototype.reset = function (options) {
        this.controls.color.patchValue(undefined, options);
        this.controls.color_dark.patchValue(undefined, options);
        this.controls.offset_x.patchValue(undefined, options);
        this.controls.offset_y.patchValue(undefined, options);
        this.controls.blur_radius.patchValue(undefined, options);
        this.controls.spread_radius.patchValue(undefined, options);
    };
    ShadowControl.prototype.resetDefaults = function (options) {
        this.controls.color.patchValue('#00000080', options);
        this.controls.color_dark.patchValue('#00000080', options);
        this.controls.offset_x.patchValue(0, options);
        this.controls.offset_y.patchValue(2, options);
        this.controls.blur_radius.patchValue(4, options);
        this.controls.spread_radius.patchValue(0, options);
    };
    ShadowControl.prototype.getInstance = function (instance) {
        if (!instance) {
            instance = new Shadow();
        }
        instance.color = isSet(this.controls.color.value) ? this.controls.color.value : undefined;
        instance.colorDark = isSet(this.controls.color_dark.value) ? this.controls.color_dark.value : undefined;
        instance.offsetX = this.controls.offset_x.value || 0;
        instance.offsetY = this.controls.offset_y.value || 0;
        instance.blurRadius = this.controls.blur_radius.value || 0;
        instance.spreadRadius = this.controls.spread_radius.value || 0;
        return instance;
    };
    ShadowControl.prototype.serialize = function (reuseInstance) {
        if (reuseInstance === void 0) { reuseInstance = true; }
        if (!this.isSet()) {
            return;
        }
        return this.getInstance(reuseInstance ? this.instance : undefined);
    };
    ShadowControl.prototype.serialize$ = function () {
        var _this = this;
        return controlValue(this, { debounce: 200 }).pipe(map(function () { return _this.serialize(); }));
    };
    return ShadowControl;
}(FormGroup));
export { ShadowControl };
