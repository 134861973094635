import { FormArray, FormControl } from '@angular/forms';
import isEqual from 'lodash/isEqual';
import range from 'lodash/range';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { controlValue, isSet } from '@shared';

export class ColorSetArray extends FormArray {
  controls: FormControl[];

  constructor(public readonly defaultColors: string[] = []) {
    super([]);
    this.defaultColors = this.defaultColors.map(item => item.toUpperCase());
    this.deserialize(defaultColors);
  }

  deserialize(value: string[] = []) {
    this.defaultColors.forEach((defaultColor, i) => {
      const control = this.controls[i] || this.appendControl();
      const itemValue = isSet(value[i]) && value[i] != defaultColor ? value[i] : undefined;

      control.patchValue(itemValue);
      control.markAsPristine();
    });

    this.controls.slice(this.defaultColors.length).forEach(item => this.removeControl(item));
  }

  serialize(): string[] {
    if (isEqual(this.value, this.defaultColors) || !this.value.length || this.value.every(item => !isSet(item))) {
      return;
    }

    return this.value;
  }

  setControls(controls: FormControl[]) {
    this.removeControls();
    controls.forEach(item => this.push(item));
  }

  removeControls() {
    range(this.controls.length).forEach(() => this.removeAt(0));
  }

  removeControl(control: FormControl) {
    const newControls = this.controls.filter(item => item !== control);
    this.setControls(newControls);
  }

  appendControl(value?: string): FormControl {
    const control = new FormControl(value);
    this.push(control);
    return control;
  }

  getEffectiveValue(): string[] {
    return this.defaultColors.map((defaultColor, i) => {
      return isSet(this.value[i]) ? this.value[i] : defaultColor;
    });
  }

  getEffectiveValue$(): Observable<string[]> {
    return controlValue<string[]>(this).pipe(
      map(() => {
        return this.getEffectiveValue();
      })
    );
  }
}
