<app-popup2 [size]="additionalStep ? 'm' : 'ml'">
  <ng-container *ngIf="!additionalStep">
    <app-popup2-header>
      <app-popup2-title>Choose operation mode</app-popup2-title>
    </app-popup2-header>

    <app-page-block>
      <div class="field">
        <div class="button-selector button-selector_size_l">
          <div class="button-selector__item">
            <div
              class="button-selector-item button-selector-item_color_orange button-selector-item_interactive"
              [class.button-selector-item_active]="control.value"
              (click)="control.patchValue(true)"
            >
              <app-sync-mode-image
                class="button-selector-item__image-block"
                [typeItem]="typeItem"
              ></app-sync-mode-image>
              <div class="button-selector-item__tag background-color_orange_1">
                <span class="button-selector-item__tag-raw">β</span>eta
              </div>
              <div class="text-status-list button-selector-item__list">
                <div class="text-status-list__item text-status-list__item_margin_s">
                  <div class="text-status-list__item-left">
                    <span class="text-status-list__circle text-status-list__circle_green icon-check_2"></span>
                  </div>
                  <div class="text-status-list__item-main">
                    Allow <strong>SQL queries</strong> with {{ typeItem?.label }}
                  </div>
                </div>

                <div class="text-status-list__item text-status-list__item_margin_s">
                  <div class="text-status-list__item-left">
                    <span class="text-status-list__circle text-status-list__circle_green icon-check_2"></span>
                  </div>
                  <div class="text-status-list__item-main">
                    <strong>Blend and join</strong> data from over 30 data sources
                  </div>
                </div>

                <div
                  *ngIf="
                    [
                      resourceNames.Airtable,
                      resourceNames.SmartSuite,
                      resourceNames.Firebase,
                      resourceNames.GoogleSheets
                    ].includes($any(typeItem.name))
                  "
                  class="text-status-list__item text-status-list__item_margin_s"
                >
                  <div class="text-status-list__item-left">
                    <span class="text-status-list__circle text-status-list__circle_green icon-check_2"></span>
                  </div>
                  <div class="text-status-list__item-main">
                    Suitable for <strong>large volume data</strong> (100k+ records)
                  </div>
                </div>

                <div
                  *ngIf="typeItem.name == resourceNames.Xano"
                  class="text-status-list__item text-status-list__item_margin_s"
                >
                  <div class="text-status-list__item-left">
                    <span class="text-status-list__circle text-status-list__circle_green icon-check_2"></span>
                  </div>
                  <div class="text-status-list__item-main">
                    Suitable for <strong>large volume data</strong> (10+ records)
                  </div>
                </div>

                <div class="text-status-list__item text-status-list__item_margin_s">
                  <div class="text-status-list__item-left">
                    <span class="text-status-list__circle text-status-list__circle_green icon-check_2"></span>
                  </div>
                  <div class="text-status-list__item-main">
                    Data is synced to <strong>Jet Tables</strong>
                    <ng-container
                      *ngIf="[resourceNames.Airtable, resourceNames.SmartSuite].includes($any(typeItem.name))"
                    >
                      every 15 minutes
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="button-selector__item">
            <div
              class="button-selector-item button-selector-item_color_teal-blue button-selector-item_interactive"
              [class.button-selector-item_active]="!control.value"
              (click)="control.patchValue(false)"
            >
              <app-direct-mode-image
                class="button-selector-item__image-block"
                [typeItem]="typeItem"
              ></app-direct-mode-image>
              <!--            <div class="button-selector-item__tag button-selector-item__tag_position_top background-color_blue_1">-->
              <!--              has limitations-->
              <!--            </div>-->
              <div class="text-status-list button-selector-item__list">
                <div
                  *ngIf="[resourceNames.Airtable, resourceNames.SmartSuite].includes($any(typeItem.name))"
                  class="text-status-list__item text-status-list__item_margin_s"
                >
                  <div class="text-status-list__item-left">
                    <span
                      class="text-status-list__circle text-status-list__circle_red text-status-list__circle_size_s icon-close"
                    ></span>
                  </div>
                  <div class="text-status-list__item-main">
                    Charts not supported
                  </div>
                </div>

                <div
                  *ngIf="
                    [
                      resourceNames.Airtable,
                      resourceNames.SmartSuite,
                      resourceNames.Firebase,
                      resourceNames.GoogleSheets
                    ].includes($any(typeItem.name))
                  "
                  class="text-status-list__item text-status-list__item_margin_s"
                >
                  <div class="text-status-list__item-left">
                    <span
                      class="text-status-list__circle text-status-list__circle_red text-status-list__circle_size_s icon-close"
                    ></span>
                  </div>
                  <div class="text-status-list__item-main">
                    Not suitable for <strong>large volume data</strong> (100k+ records)
                  </div>
                </div>

                <div
                  *ngIf="typeItem.name == resourceNames.Xano"
                  class="text-status-list__item text-status-list__item_margin_s"
                >
                  <div class="text-status-list__item-left">
                    <span
                      class="text-status-list__circle text-status-list__circle_red text-status-list__circle_size_s icon-close"
                    ></span>
                  </div>
                  <div class="text-status-list__item-main">
                    Not suitable for <strong>large volume data</strong> (10k+ records)
                  </div>
                </div>

                <div class="text-status-list__item text-status-list__item_margin_s">
                  <div class="text-status-list__item-left">
                    <span class="text-status-list__circle text-status-list__circle_green icon-check_2"></span>
                  </div>
                  <div class="text-status-list__item-main">
                    No sync delays
                  </div>
                </div>

                <div class="text-status-list__item text-status-list__item_margin_s">
                  <div class="text-status-list__item-left">
                    <span class="text-status-list__circle text-status-list__circle_green icon-check_2"></span>
                  </div>
                  <div class="text-status-list__item-main">
                    <strong>Direct requests</strong> to {{ typeItem?.label }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </app-page-block>

    <app-popup2-footer *ngIf="typeItem.name == resourceNames.Firebase">
      <app-page-block>
        <app-stack [align]="'right'">
          <button type="button" class="button popup-content__submit-item" (click)="close()">
            Cancel
          </button>

          <button
            *ngIf="control.value"
            type="button"
            class="button button_primary button_shadow popup-content__submit-item"
            (click)="setAdditionalStep(true)"
          >
            <span class="button__label">Continue</span>
          </button>

          <button
            *ngIf="!control.value"
            type="button"
            class="button button_primary button_shadow popup-content__submit-item"
            (click)="submit()"
          >
            <span class="button__label">Continue</span>
          </button>
        </app-stack>
      </app-page-block>
    </app-popup2-footer>

    <app-popup2-footer *ngIf="typeItem.name != resourceNames.Firebase">
      <app-page-block>
        <app-stack [align]="'right'">
          <button type="button" class="button popup-content__submit-item" (click)="close()">
            Cancel
          </button>

          <button
            type="button"
            class="button button_primary button_shadow popup-content__submit-item"
            (click)="submit()"
          >
            <span class="button__label">Continue</span>
          </button>
        </app-stack>
      </app-page-block>
    </app-popup2-footer>
  </ng-container>

  <ng-container *ngIf="additionalStep && typeItem.name == resourceNames.Firebase">
    <app-popup2-header>
      <app-popup2-title>Choose Real-time updates</app-popup2-title>
    </app-popup2-header>

    <app-sync-mode-firebase
      [label]="undefined"
      [enableSync]="control | appControlValue | async"
      [paramsOptions]="asFirebaseParamsOptions(paramsOptions)"
      [cancelLabel]="'Back'"
      (submit)="submit($event)"
      (cancel)="setAdditionalStep(false)"
    ></app-sync-mode-firebase>
  </ng-container>
</app-popup2>
