<ng-template let-active="active" #menu_item>
  <div
    class="menu2-button-item"
    [class.menu2-button-item_small]="!(primary && !horizontal)"
    [class.menu2-button-item_icon]="isIconButton()"
    [class.menu2-button-item_fill]="!horizontal"
    [appTintColor]="menuItem.tint || ('accentColor' | appThemeColorHexOption: true | async)"
    [appTintColorStyle]="menuItem.style"
    (mouseenter)="menuItemHovered$.next(item_origin)"
    (mouseleave)="menuItemHovered$.next(undefined)"
    cdkOverlayOrigin
    #item_origin="cdkOverlayOrigin"
  >
    <div class="menu2-button-item__inner">
      <span
        *ngIf="menuItem.icon"
        class="menu2-button-item__icon"
        [class.menu2-button-item__icon_left]="!isIconButton()"
        [appIcon]="menuItem.icon"
      ></span>

      <span *ngIf="!isIconButton()" class="menu2-button-item__label">
        {{ menuItem.title | appCapitalize }}
      </span>

      <span
        *ngIf="children.length && (!isIconButton() || !menuItem.icon)"
        class="menu2-button-item__icon icon-arrow_down_2"
        [class.menu2-button-item__icon_right]="!isIconButton()"
      ></span>
    </div>
  </div>

  <div
    *ngIf="primary && !horizontal && (menuItem.title | appIsSet) && !menuItem.children.length"
    class="menu2-primary-item-tip"
  >
    {{ menuItem.title | appCapitalize }}
  </div>
</ng-template>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayPanelClass]="['overlay', 'overlay_horizontal_center']"
  [cdkConnectedOverlayPositions]="popoverPositions"
  [cdkConnectedOverlayOrigin]="origin"
  [cdkConnectedOverlayOpen]="dropdownOpened"
  [cdkConnectedOverlayPush]="true"
  [cdkConnectedOverlayGrowAfterOpen]="true"
  [cdkConnectedOverlayHasBackdrop]="false"
  [cdkConnectedOverlayBackdropClass]="'popover2-backdrop'"
  (backdropClick)="closeDropdown()"
  (detach)="closeDropdown()"
>
  <div
    class="popover2 popover2_theme overlay-content overlay-content_animated"
    (mouseenter)="menuDropdownHovered$.next(true)"
    (mouseleave)="menuDropdownHovered$.next(false)"
  >
    <div class="popover2__inner">
      <div class="popover2__viewport popover2__viewport_scrollable" style="min-width: 220px;">
        <div
          class="popover2__content popover2__content_padding_sm menu2-dropdown menu2-block menu2-block_unset"
          appMenuBlockDropdownStyle
        >
          <app-auto-menu-item
            *ngFor="let item of children; trackBy: trackMenuItemFn"
            [menuItem]="item"
            [menuBlock]="menuBlock"
            [dropdown]="true"
            (dropdownOpen)="onMenuChildDropdownOpened(item)"
            (dropdownClose)="onMenuChildDropdownClosed(item)"
          ></app-auto-menu-item>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<a
  *ngIf="link"
  [routerLink]="link"
  [queryParams]="queryParams"
  [appIsLinkActiveAppLink]="link"
  [appIsLinkActiveQueryParams]="queryParams"
  #app_is_link_active="appIsLinkActive"
  class="menu2-link menu2-item-wrapper"
  [class.menu2-link_active]="app_is_link_active.active"
  (click)="onClick()"
>
  <ng-container *ngTemplateOutlet="menu_item; context: { active: app_is_link_active.active }"></ng-container>
</a>

<a
  *ngIf="!link && (href | appIsSet)"
  [href]="href"
  [appIsLinkActiveHref]="href"
  #app_is_link_active="appIsLinkActive"
  class="menu2-link menu2-item-wrapper"
  [class.menu2-link_active]="app_is_link_active.active"
  target="_blank"
  (click)="onClick()"
>
  <ng-container *ngTemplateOutlet="menu_item; context: { active: app_is_link_active.active }"></ng-container>
</a>

<span *ngIf="!link && !(href | appIsSet)" class="menu2-item-wrapper" [class.menu2-link]="handler" (click)="onClick()">
  <ng-container *ngTemplateOutlet="menu_item"></ng-container>
</span>
