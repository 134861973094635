<form (ngSubmit)="submit()">
  <app-customize-bar-header [title]="'Single Value'" [subtitle]="'Data'"></app-customize-bar-header>

  <div class="sidebar__content">
    <app-sidebar-section>
      <app-value-widget-data-source-edit
        [control]="control"
        [label]="'choose collection'"
        [typeOrange]="true"
        [queryOptionEmptyLabel]="'No collections'"
        [context]="context"
        [contextElement]="contextElement"
        [resourceClasses]="'component-data-resource'"
        [queryOptionClasses]="'component-data-collection'"
        [analyticsSource]="widget.analyticsGenericName"
      ></app-value-widget-data-source-edit>
    </app-sidebar-section>

    <app-sidebar-section
      *ngIf="
        (control.controls.type | appControlValue | async) == dataSourceTypes.Input ||
        (control.controls.type | appControlValue | async) == dataSourceTypes.Workflow ||
        ((control.controls.type | appControlValue | async) == dataSourceTypes.Query && control.controls.query.valid)
      "
      [title]="(control.getQueryEditable$() | async) ? 'Specify Inputs' : 'Apply Filters'"
      [description]="
        (control.getQueryEditable$() | async)
          ? 'Manually or use value from other components'
          : 'Static or use value from other components'
      "
    >
      <app-data-source-inputs-edit
        [control]="control"
        [editable]="control.getQueryEditable$() | async"
        [nestedFieldsEnabled]="false"
        [relationsEnabled]="false"
        [context]="context"
        [contextElement]="contextElement"
        [classes]="'component-data-inputs'"
        [itemClasses]="'component-data-inputs-item'"
        [addClasses]="'component-data-inputs-add'"
        [analyticsSource]="widget.analyticsGenericName"
        (addInput)="addQueryInput()"
      ></app-data-source-inputs-edit>
    </app-sidebar-section>

    <app-sidebar-submit
      [saveEnabled]="false"
      [cancelEnabled]="false"
      [backEnabled]="true"
      [errors]="chartForm | appFormErrors"
      (cancelClick)="cancel()"
    >
    </app-sidebar-submit>
  </div>
</form>
