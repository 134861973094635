var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { AfterViewInit, ChangeDetectorRef, ElementRef, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import * as Color from 'color';
import toPairs from 'lodash/toPairs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { AdminMode } from '@modules/admin-mode';
import { ProjectSettingsStore } from '@modules/all-project-settings';
import { AnalyticsEvent, IntercomService } from '@modules/analytics';
import { ApiService } from '@modules/api';
import { AuthService } from '@modules/auth';
import { TaskQueueStore } from '@modules/collaboration';
import { getColorHex, isLightColor } from '@modules/colors';
import { Frame, routeHasBuilderMode } from '@modules/customize';
import { MenuBlock, MenuGeneratorService } from '@modules/menu';
import { CurrentEnvironmentStore, CurrentProjectStore, ProjectService, ProjectsStore } from '@modules/projects';
import { RoutingService } from '@modules/routing';
import { ThemeService } from '@modules/theme';
import { CurrentUserStore } from '@modules/users';
import { elementSize$, isSet, openUrl } from '@shared';
export var MenuPrimary;
(function (MenuPrimary) {
    function isLight(color) {
        if (!isSet(color)) {
            return;
        }
        return isLightColor(color);
    }
    MenuPrimary.isLight = isLight;
    function getLightColor(color) {
        try {
            var colorHex = getColorHex(color);
            var clr = Color(colorHex);
            return clr.lighten(0.2).string();
        }
        catch (e) { }
    }
    MenuPrimary.getLightColor = getLightColor;
    function getDarkColor(color) {
        try {
            var colorHex = getColorHex(color);
            var clr = Color(colorHex);
            return clr.darken(0.05).string();
        }
        catch (e) { }
    }
    MenuPrimary.getDarkColor = getDarkColor;
    function getDark2Color(color) {
        try {
            var colorHex = getColorHex(color);
            var clr = Color(colorHex);
            return clr.darken(0.45).desaturate(0.35).string();
        }
        catch (e) { }
    }
    MenuPrimary.getDark2Color = getDark2Color;
    function getDark3Color(color) {
        try {
            var colorHex = getColorHex(color);
            var clr = Color(colorHex);
            return clr.darken(0.7).desaturate(0.25).string();
        }
        catch (e) { }
    }
    MenuPrimary.getDark3Color = getDark3Color;
    function getVars(accentColor, backgroundColor) {
        if (!isSet(accentColor)) {
            accentColor = isSet(backgroundColor) ? backgroundColor : '#f3f5f8';
        }
        var lightColor = getLightColor(accentColor);
        var darkColor = getDarkColor(accentColor);
        var dark2Color = getDark2Color(accentColor);
        var dark3Color = getDark3Color(accentColor);
        return __assign({}, (lightColor && { 'light-color': lightColor }), (darkColor && { 'dark-color': darkColor }), (dark2Color && { 'dark2-color': dark2Color }), (dark3Color && { 'dark3-color': dark3Color }));
    }
    MenuPrimary.getVars = getVars;
    function defaultWidth() {
        return 70;
    }
    MenuPrimary.defaultWidth = defaultWidth;
    function defaultHeight() {
        return 50;
    }
    MenuPrimary.defaultHeight = defaultHeight;
})(MenuPrimary || (MenuPrimary = {}));
var MenuPrimaryComponent = /** @class */ (function () {
    function MenuPrimaryComponent(el, mode$, themeService, authService, routing, currentProjectStore, currentEnvironmentStore, projectSettingsStore, projectsStore, projectService, currentUserStore, menuGeneratorService, apiService, intercomService, taskQueueStore, sanitizer, cd) {
        this.el = el;
        this.mode$ = mode$;
        this.themeService = themeService;
        this.authService = authService;
        this.routing = routing;
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.projectSettingsStore = projectSettingsStore;
        this.projectsStore = projectsStore;
        this.projectService = projectService;
        this.currentUserStore = currentUserStore;
        this.menuGeneratorService = menuGeneratorService;
        this.apiService = apiService;
        this.intercomService = intercomService;
        this.taskQueueStore = taskQueueStore;
        this.sanitizer = sanitizer;
        this.cd = cd;
        this.horizontal = false;
        this.isDarkTheme = false;
        this.frame$ = new BehaviorSubject(undefined);
        this.startItems = [];
        this.centerItems = [];
        this.endItems = [];
        this.demoUser = false;
        this.hasTaskQueues = false;
        this.adminModes = AdminMode;
        this.menuIsLight = false;
        this.analyticsEvents = AnalyticsEvent;
    }
    MenuPrimaryComponent.prototype.trackMenuItemFn = function (i, item) {
        return item.id;
    };
    MenuPrimaryComponent.prototype.ngOnInit = function () {
        var _this = this;
        combineLatest(this.currentProjectStore.instance$, this.projectsStore.instance$)
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var currentProject = _a[0], projects = _a[1];
            _this.currentProject = currentProject;
            if (_this.currentProject) {
                _this.projects = projects ? projects.filter(function (item) { return item.uniqueName != _this.currentProject.uniqueName; }) : [];
                _this.projects = _this.projectService.sortProjectsLastUsed(_this.projects);
                if (_this.isWhiteLabel) {
                    _this.projects = _this.projects.filter(function (item) { return item.isDomain(_this.currentProject.domain); });
                }
            }
            _this.cd.markForCheck();
        }, function () {
            _this.cd.markForCheck();
        });
        this.projectSettingsStore
            .getAllSettings$()
            .pipe(untilDestroyed(this))
            .subscribe(function (projectSettings) {
            _this.projectSettings = projectSettings;
            _this.cd.markForCheck();
        });
        this.currentUserStore
            .get()
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            _this.demoUser = !!_this.apiService.getProjectToken();
            _this.cd.detectChanges();
        });
        this.taskQueueStore
            .get()
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            _this.hasTaskQueues = result && result.length > 0;
            _this.cd.markForCheck();
        });
    };
    MenuPrimaryComponent.prototype.ngOnDestroy = function () { };
    MenuPrimaryComponent.prototype.ngOnChanges = function (changes) {
        if (changes.block) {
            this.updateItems();
        }
        if (changes.block || changes.isDarkTheme || changes.backgroundColor) {
            this.updateBackground();
        }
        if (changes.block || changes.isDarkTheme) {
            this.updateBorder();
        }
    };
    MenuPrimaryComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        elementSize$(this.el.nativeElement)
            .pipe(untilDestroyed(this))
            .subscribe(function (size) {
            _this.frame$.next(size);
            _this.updateBackground();
        });
    };
    MenuPrimaryComponent.prototype.updateItems = function () {
        this.startItems = this.menuGeneratorService.cleanMenuItemsAppMode(this.block.startItems);
        this.centerItems = this.menuGeneratorService.cleanMenuItemsAppMode(this.block.centerItems);
        this.endItems = this.menuGeneratorService.cleanMenuItemsAppMode(this.block.endItems);
        this.cd.markForCheck();
    };
    MenuPrimaryComponent.prototype.getBuilderUrl = function () {
        if (routeHasBuilderMode(this.routing.routerState.snapshot)) {
            return window.location.href.replace('/app/', '/builder/');
        }
        else {
            var urlTree = this.routing.createUrlTreeApp(this.currentProjectStore.instance.homeLink, undefined, {
                environmentName: this.currentEnvironmentStore.instance.uniqueName,
                modeName: AdminMode.Builder
            });
            return this.routing.serializeUrl(urlTree);
        }
    };
    MenuPrimaryComponent.prototype.openBuilderUrl = function () {
        var url = this.getBuilderUrl();
        openUrl(url, true);
    };
    MenuPrimaryComponent.prototype.toggleTheme = function () {
        this.themeService.toggleTheme();
    };
    MenuPrimaryComponent.prototype.logout = function () {
        this.authService.logout();
        this.routing.navigate(['/login']);
    };
    MenuPrimaryComponent.prototype.openChat = function () {
        this.intercomService.openChat();
    };
    Object.defineProperty(MenuPrimaryComponent.prototype, "isWhiteLabel", {
        get: function () {
            return this.currentProject && this.currentProject.domain && this.currentProject.domain.whiteLabel;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MenuPrimaryComponent.prototype, "showProjectsDropdown", {
        get: function () {
            return !this.isWhiteLabel || (this.isWhiteLabel && this.projects.length > 1);
        },
        enumerable: true,
        configurable: true
    });
    MenuPrimaryComponent.prototype.updateBackground = function () {
        var fill = this.getFill();
        if (fill) {
            var frame = new Frame(__assign({ width: 1, height: 1 }, this.frame$.value));
            var css = fill.css({ frame: frame });
            this.menuBackground = this.sanitizer.bypassSecurityTrustStyle(css.background);
            this.menuBackgroundWidth = css.width;
            this.menuBackgroundHeight = css.height;
            this.menuBackgroundTransform = this.sanitizer.bypassSecurityTrustStyle(css.transform);
            this.menuIsLight = MenuPrimary.isLight(css.accentColor || this.backgroundColor);
            this.menuStyle = this.getStyleVars(css.accentColor, this.backgroundColor);
        }
        else {
            this.menuBackground = undefined;
            this.menuBackgroundWidth = undefined;
            this.menuBackgroundHeight = undefined;
            this.menuBackgroundTransform = undefined;
            this.menuIsLight = MenuPrimary.isLight(this.backgroundColor);
            this.menuStyle = this.getStyleVars(undefined, this.backgroundColor);
        }
    };
    MenuPrimaryComponent.prototype.updateBorder = function () {
        if (this.block.borderSettings && this.block.borderSettings.isSidesSet()) {
            this.menuBorderTop =
                this.block.borderSettings && this.block.borderSettings.borderTop
                    ? this.sanitizer.bypassSecurityTrustStyle(this.block.borderSettings.borderTop.cssBorder(this.isDarkTheme))
                    : undefined;
            this.menuBorderRight =
                this.block.borderSettings && this.block.borderSettings.borderRight
                    ? this.sanitizer.bypassSecurityTrustStyle(this.block.borderSettings.borderRight.cssBorder(this.isDarkTheme))
                    : undefined;
            this.menuBorderBottom =
                this.block.borderSettings && this.block.borderSettings.borderBottom
                    ? this.sanitizer.bypassSecurityTrustStyle(this.block.borderSettings.borderBottom.cssBorder(this.isDarkTheme))
                    : undefined;
            this.menuBorderLeft =
                this.block.borderSettings && this.block.borderSettings.borderLeft
                    ? this.sanitizer.bypassSecurityTrustStyle(this.block.borderSettings.borderLeft.cssBorder(this.isDarkTheme))
                    : undefined;
        }
        else {
            this.menuBorderTop = this.menuBorderRight = this.menuBorderBottom = this.menuBorderLeft =
                this.block.borderSettings && this.block.borderSettings.border
                    ? this.sanitizer.bypassSecurityTrustStyle(this.block.borderSettings.border.cssBorder(this.isDarkTheme))
                    : undefined;
        }
    };
    MenuPrimaryComponent.prototype.getFill = function () {
        if (!this.block.fillSettings) {
            return;
        }
        if (this.isDarkTheme) {
            return this.block.fillSettings.fillDark;
        }
        else {
            return this.block.fillSettings.fill;
        }
    };
    MenuPrimaryComponent.prototype.getStyleVars = function (accentColor, backgroundColor) {
        var vars = MenuPrimary.getVars(accentColor, backgroundColor);
        var styles = toPairs(vars)
            .map(function (_a) {
            var k = _a[0], v = _a[1];
            return "--" + k + ": " + v;
        })
            .join(';');
        return this.sanitizer.bypassSecurityTrustStyle(styles);
    };
    return MenuPrimaryComponent;
}());
export { MenuPrimaryComponent };
