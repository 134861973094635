var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import pickBy from 'lodash/pickBy';
import { TintStyle } from '@modules/actions';
import { Input } from '@modules/fields';
import { AlignHorizontal } from '../../align-horizontal';
import { BorderSettings } from '../../border-settings';
import { FillSettings } from '../../fill-settings';
import { IconSettings } from '../../icon-settings';
import { Shadow } from '../../shadow';
import { TextStyle } from '../../text-style';
import { registerElementForType } from '../element-items';
import { ElementType } from '../element-type';
import { ElementItem } from './base';
var FormSubmitElementItem = /** @class */ (function (_super) {
    __extends(FormSubmitElementItem, _super);
    function FormSubmitElementItem() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.type = ElementType.FormSubmit;
        _this.alignHorizontalDefault = AlignHorizontal.Left;
        _this.style = TintStyle.Primary;
        return _this;
    }
    FormSubmitElementItem.prototype.deserialize = function (data) {
        _super.prototype.deserialize.call(this, data);
        this.icon = this.params['icon'];
        this.style = this.params['style'];
        this.tint = this.params['tint'];
        if (this.params['verbose_name_input']) {
            this.verboseNameInput = new Input().deserialize(this.params['verbose_name_input']);
        }
        else if (this.params['verbose_name']) {
            // Backward compatibility
            this.verboseNameInput = new Input().deserializeFromStatic('value', this.params['verbose_name']);
        }
        if (this.params['disabled_input']) {
            this.disabledInput = new Input().deserialize(this.params['disabled_input']);
        }
        if (this.params['text_style']) {
            this.textStyle = new TextStyle().deserialize(this.params['text_style']);
        }
        else {
            this.textStyle = undefined;
        }
        if (this.params['icon_settings']) {
            this.iconSettings = new IconSettings().deserialize(this.params['icon_settings']);
        }
        else {
            this.iconSettings = undefined;
        }
        if (this.params['fill_settings']) {
            this.fillSettings = new FillSettings().deserialize(this.params['fill_settings']);
        }
        else {
            this.fillSettings = undefined;
        }
        if (this.params['border_settings']) {
            this.borderSettings = new BorderSettings().deserialize(this.params['border_settings']);
        }
        else {
            this.borderSettings = undefined;
        }
        if (this.params['border_radius']) {
            this.borderRadius = this.params['border_radius'];
        }
        else {
            this.borderRadius = undefined;
        }
        if (this.params['shadow']) {
            this.shadow = new Shadow().deserialize(this.params['shadow']);
        }
        else {
            this.shadow = undefined;
        }
        this.padding = this.params['padding'];
        if (this.params['hover_text_style']) {
            this.hoverTextStyle = new TextStyle().deserialize(this.params['hover_text_style']);
        }
        else {
            this.hoverTextStyle = undefined;
        }
        if (this.params['hover_icon_settings']) {
            this.hoverIconSettings = new IconSettings().deserialize(this.params['hover_icon_settings']);
        }
        else {
            this.hoverIconSettings = undefined;
        }
        if (this.params['hover_fill_settings']) {
            this.hoverFillSettings = new FillSettings().deserialize(this.params['hover_fill_settings']);
        }
        else {
            this.hoverFillSettings = undefined;
        }
        if (this.params['hover_border_settings']) {
            this.hoverBorderSettings = new BorderSettings().deserialize(this.params['hover_border_settings']);
        }
        else {
            this.hoverBorderSettings = undefined;
        }
        if (this.params['hover_shadow']) {
            this.hoverShadow = new Shadow().deserialize(this.params['hover_shadow']);
        }
        else {
            this.hoverShadow = undefined;
        }
        if (this.params['active_text_style']) {
            this.activeTextStyle = new TextStyle().deserialize(this.params['active_text_style']);
        }
        else {
            this.activeTextStyle = undefined;
        }
        if (this.params['active_icon_settings']) {
            this.activeIconSettings = new IconSettings().deserialize(this.params['active_icon_settings']);
        }
        else {
            this.activeIconSettings = undefined;
        }
        if (this.params['active_fill_settings']) {
            this.activeFillSettings = new FillSettings().deserialize(this.params['active_fill_settings']);
        }
        else {
            this.activeFillSettings = undefined;
        }
        if (this.params['active_border_settings']) {
            this.activeBorderSettings = new BorderSettings().deserialize(this.params['active_border_settings']);
        }
        else {
            this.activeBorderSettings = undefined;
        }
        if (this.params['active_shadow']) {
            this.activeShadow = new Shadow().deserialize(this.params['active_shadow']);
        }
        else {
            this.activeShadow = undefined;
        }
        return this;
    };
    FormSubmitElementItem.prototype.serialize = function (fields) {
        this.params = {
            verbose_name_input: this.verboseNameInput ? this.verboseNameInput.serialize() : null,
            icon: this.icon,
            style: this.style,
            tint: this.tint,
            disabled_input: this.disabledInput ? this.disabledInput.serialize() : null,
            text_style: this.textStyle ? this.textStyle.serialize() : undefined,
            icon_settings: this.iconSettings ? this.iconSettings.serialize() : undefined,
            fill_settings: this.fillSettings ? this.fillSettings.serialize() : undefined,
            border_settings: this.borderSettings ? this.borderSettings.serialize() : undefined,
            border_radius: this.borderRadius,
            shadow: this.shadow ? this.shadow.serialize() : undefined,
            padding: this.padding,
            hover_text_style: this.hoverTextStyle ? this.hoverTextStyle.serialize() : undefined,
            hover_icon_settings: this.hoverIconSettings ? this.hoverIconSettings.serialize() : undefined,
            hover_fill_settings: this.hoverFillSettings ? this.hoverFillSettings.serialize() : undefined,
            hover_border_settings: this.hoverBorderSettings ? this.hoverBorderSettings.serialize() : undefined,
            hover_shadow: this.hoverShadow ? this.hoverShadow.serialize() : undefined,
            active_text_style: this.activeTextStyle ? this.activeTextStyle.serialize() : undefined,
            active_icon_settings: this.activeIconSettings ? this.activeIconSettings.serialize() : undefined,
            active_fill_settings: this.activeFillSettings ? this.activeFillSettings.serialize() : undefined,
            active_border_settings: this.activeBorderSettings ? this.activeBorderSettings.serialize() : undefined,
            active_shadow: this.activeShadow ? this.activeShadow.serialize() : undefined
        };
        var data = _super.prototype.serialize.call(this);
        if (fields) {
            data = pickBy(data, function (v, k) { return fields.includes(k); });
        }
        return data;
    };
    Object.defineProperty(FormSubmitElementItem.prototype, "typeStr", {
        get: function () {
            return 'form submit button';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormSubmitElementItem.prototype, "analyticsName", {
        get: function () {
            return 'form_submit';
        },
        enumerable: true,
        configurable: true
    });
    FormSubmitElementItem.prototype.defaultName = function () {
        return 'Form Submit';
    };
    return FormSubmitElementItem;
}(ElementItem));
export { FormSubmitElementItem };
registerElementForType(ElementType.FormSubmit, FormSubmitElementItem);
