<form [formGroup]="form">
  <div class="popup-content resource-popup">
    <div class="popup-content__header">
      <div class="popup-content__title">Import File</div>
    </div>
    <div class="resource-popup__content">
      <ng-container *ngIf="loading">
        <app-page-block>
          <div class="field">
            <div class="field__label">
              spreadsheet
            </div>
            <div class="input input_fill input_disabled">
              <span [class.loading-animation]="loading"><span class="stub-text">Filename</span></span>
            </div>
          </div>
        </app-page-block>

        <app-page-block>
          <div class="field">
            <div class="field__label">name</div>
            <div class="field__value">
              <div class="input input_fill input_disabled">
                <span [class.loading-animation]="loading"><span class="stub-text">Filename</span></span>
              </div>
            </div>
          </div>
        </app-page-block>

        <app-page-block>
          <div class="field">
            <div class="field__label">sheet</div>
            <div class="field__value">
              <div class="input input_fill input_disabled">
                <span [class.loading-animation]="loading"><span class="stub-text">Sheet 1</span></span>
              </div>
            </div>
          </div>
        </app-page-block>

        <app-page-block>
          <div class="field">
            <div class="field__label">
              range
            </div>
            <div class="field__value">
              <div class="input input_fill input_disabled">
                <span [class.loading-animation]="loading"><span class="stub-text">A1:B999</span></span>
              </div>
            </div>
            <div class="field__description">
              <span [class.loading-animation]="loading"><span class="stub-text">e.g. A:D or A1:B99</span></span>
            </div>
          </div>
        </app-page-block>
      </ng-container>

      <app-google-sheets-resource-settings-file
        *ngIf="!loading"
        [form]="form.controls.file"
        [accessToken]="form.controls.access_token.value"
      >
      </app-google-sheets-resource-settings-file>

      <app-page-block>
        <app-form-errors [form]="form"></app-form-errors>
      </app-page-block>
    </div>

    <div class="popup-content__submit">
      <button type="button" class="button popup-content__submit-item" (click)="close()">
        Cancel
      </button>

      <button
        type="button"
        class="button button_primary button_shadow popup-content__submit-item"
        [class.button_disabled]="!form.valid || loading || submitLoading"
        (click)="submit()"
      >
        <app-loader-small *ngIf="submitLoading" class="button__icon button__icon_left"></app-loader-small>
        <span class="button__label">Add file</span>
      </button>
    </div>
  </div>
</form>
