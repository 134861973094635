/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../common/dynamic-component/components/dynamic/dynamic.component.ngfactory";
import * as i2 from "../../../../../common/dynamic-component/components/dynamic/dynamic.component";
import * as i3 from "@angular/common";
import * as i4 from "../../../../customize-bar/components/customize-bar-header/customize-bar-header.component.ngfactory";
import * as i5 from "../../../../customize-bar/components/customize-bar-header/customize-bar-header.component";
import * as i6 from "../../../../customize-bar/services/customize-bar-context/customize-bar.context";
import * as i7 from "../../../../routing/directives/app-link/app-link.directive";
import * as i8 from "../../../../routing/services/routing/routing.service";
import * as i9 from "@angular/router";
import * as i10 from "ng-gxscrollable";
import * as i11 from "./dashboard-customize-bar.component";
import * as i12 from "../../../../customize/services/customize/customize.service";
import * as i13 from "../../../../projects/stores/current-project.store";
import * as i14 from "../../../../dashboard/services/widget/widget.service";
var styles_DashboardCustomizeBarComponent = [];
var RenderType_DashboardCustomizeBarComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DashboardCustomizeBarComponent, data: {} });
export { RenderType_DashboardCustomizeBarComponent as RenderType_DashboardCustomizeBarComponent };
function View_DashboardCustomizeBarComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i0.ɵeld(1, 16777216, null, null, 1, "app-dynamic-component", [], null, null, null, i1.View_DynamicComponent_0, i1.RenderType_DynamicComponent)), i0.ɵdid(2, 442368, null, 0, i2.DynamicComponent, [i0.ElementRef, i0.Injector, i0.ComponentFactoryResolver, i0.ApplicationRef, i0.ViewContainerRef], { componentData: [0, "componentData"] }, null)], function (_ck, _v) { var currVal_1 = _v.context.$implicit; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = !_v.context.last; _ck(_v, 0, 0, currVal_0); }); }
function View_DashboardCustomizeBarComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [["class", "sidebar-list__item sidebar-list__item_half"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 10, "div", [["class", "sidebar-image-card"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.add(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [["class", "sidebar-image-card__title"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "div", [["class", "sidebar-image-card__line"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""])), (_l()(), i0.ɵeld(5, 0, null, null, 2, "div", [["class", "sidebar-image-card__image"]], null, null, null, null, null)), i0.ɵdid(6, 278528, null, 0, i3.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpad(7, 1), (_l()(), i0.ɵeld(8, 0, null, null, 3, "div", [["class", "sidebar-image-card__bottom"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 0, "span", [["class", "sidebar-image-card__action sidebar-image-card__action_button sidebar-image-card__bottom-right icon-plus"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 1, "div", [["class", "sidebar-image-card__line"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Add Chart"]))], function (_ck, _v) { var currVal_1 = "sidebar-image-card__image"; var currVal_2 = _ck(_v, 7, 0, ("sidebar-image-card__image_type_" + _v.context.$implicit.context.icon)); _ck(_v, 6, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.context.heading; _ck(_v, 4, 0, currVal_0); }); }
function View_DashboardCustomizeBarComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 19, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-customize-bar-header", [], null, null, null, i4.View_CustomizeBarHeaderComponent_0, i4.RenderType_CustomizeBarHeaderComponent)), i0.ɵdid(2, 180224, null, 0, i5.CustomizeBarHeaderComponent, [[2, i6.CustomizeBarContext]], { title: [0, "title"], subtitle: [1, "subtitle"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 16, "div", [["class", "sidebar__content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 15, "div", [["class", "sidebar__list"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 14, "div", [["class", "sidebar-list"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DashboardCustomizeBarComponent_3)), i0.ɵdid(7, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(8, 0, null, null, 11, "div", [["class", "sidebar-list__item sidebar-list__item_half"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 10, "a", [["class", "sidebar-image-card"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 10).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(10, 737280, null, 0, i7.AppLinkDirective, [i8.RoutingService, i9.Router, i9.ActivatedRoute, i3.LocationStrategy], { appLink: [0, "appLink"] }, null), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(12, 0, null, null, 2, "div", [["class", "sidebar-image-card__title"]], null, null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 1, "div", [["class", "sidebar-image-card__line"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["FlexView"])), (_l()(), i0.ɵeld(15, 0, null, null, 0, "div", [["class", "sidebar-image-card__image sidebar-image-card__image_type_flex-view"]], null, null, null, null, null)), (_l()(), i0.ɵeld(16, 0, null, null, 3, "div", [["class", "sidebar-image-card__bottom"]], null, null, null, null, null)), (_l()(), i0.ɵeld(17, 0, null, null, 0, "span", [["class", "sidebar-image-card__action sidebar-image-card__action_button sidebar-image-card__action_flex sidebar-image-card__bottom-right icon-plus"]], null, null, null, null, null)), (_l()(), i0.ɵeld(18, 0, null, null, 1, "div", [["class", "sidebar-image-card__line"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Add Create"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Add Components"; var currVal_1 = "Dashboard"; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.items; _ck(_v, 7, 0, currVal_2); var tmp_5_0 = null; var currVal_5 = (((tmp_5_0 = i0.ɵunv(_v, 10, 0, i0.ɵnov(_v, 11).transform(_co.currentProjectStore.instance$))) == null) ? null : tmp_5_0.settingsCustomViewsLink); _ck(_v, 10, 0, currVal_5); }, function (_ck, _v) { var currVal_3 = i0.ɵnov(_v, 10).target; var currVal_4 = i0.ɵnov(_v, 10).href; _ck(_v, 9, 0, currVal_3, currVal_4); }); }
export function View_DashboardCustomizeBarComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(402653184, 1, { root: 0 }), (_l()(), i0.ɵeld(1, 0, null, null, 7, "div", [["class", "sidebar"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, [[1, 0], ["root", 1]], null, 6, "div", [["class", "sidebar__scrollable"], ["xsScrollable", ""]], null, null, null, null, null)), i0.ɵdid(3, 12664832, null, 0, i10.ScrollableDirective, [i0.ElementRef], { xsScrollableOptions: [0, "xsScrollableOptions"] }, null), i0.ɵpod(4, { stopPropagationAlways: 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DashboardCustomizeBarComponent_1)), i0.ɵdid(6, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DashboardCustomizeBarComponent_2)), i0.ɵdid(8, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 4, 0, true); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.settingsComponents; _ck(_v, 6, 0, currVal_1); var currVal_2 = !_co.settingsComponents.length; _ck(_v, 8, 0, currVal_2); }, null); }
export function View_DashboardCustomizeBarComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-dashboard-customize-bar", [], null, null, null, View_DashboardCustomizeBarComponent_0, RenderType_DashboardCustomizeBarComponent)), i0.ɵdid(1, 245760, null, 0, i11.DashboardCustomizeBarComponent, [i12.CustomizeService, i13.CurrentProjectStore, i14.WidgetService, i0.ChangeDetectorRef, i6.CustomizeBarContext], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DashboardCustomizeBarComponentNgFactory = i0.ɵccf("app-dashboard-customize-bar", i11.DashboardCustomizeBarComponent, View_DashboardCustomizeBarComponent_Host_0, { dashboard: "dashboard", widgetPosition: "widgetPosition" }, { widgetAdded: "widgetAdded" }, []);
export { DashboardCustomizeBarComponentNgFactory as DashboardCustomizeBarComponentNgFactory };
