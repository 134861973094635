import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { controlValue, isSet } from '@shared';

import { Margin } from './elements/items/base';
import { NumberControl } from './number.control';

export class MarginControl extends FormGroup {
  controls: {
    top: NumberControl;
    right: NumberControl;
    bottom: NumberControl;
    left: NumberControl;
  };

  constructor(value: Margin = {}) {
    super({
      top: new NumberControl(value.top),
      right: new NumberControl(value.right),
      bottom: new NumberControl(value.bottom),
      left: new NumberControl(value.left)
    });
  }

  deserialize(value?: Margin) {
    if (value) {
      this.patchValue({
        top: value.top,
        right: value.right,
        bottom: value.bottom,
        left: value.left
      });
    } else {
      this.patchValue({
        top: undefined,
        right: undefined,
        bottom: undefined,
        left: undefined
      });
    }
  }

  isSet(): boolean {
    return [this.controls.top, this.controls.right, this.controls.bottom, this.controls.left].some(control =>
      isSet(control.value)
    );
  }

  isSet$(): Observable<boolean> {
    return controlValue(this).pipe(map(() => this.isSet()));
  }

  reset(options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    this.controls.top.reset(undefined, options);
    this.controls.right.reset(undefined, options);
    this.controls.bottom.reset(undefined, options);
    this.controls.left.reset(undefined, options);
  }

  serialize(): Margin {
    if (!this.isSet()) {
      return;
    }

    return {
      top: this.controls.top.value,
      right: this.controls.right.value,
      bottom: this.controls.bottom.value,
      left: this.controls.left.value
    };
  }

  serialize$(): Observable<Margin> {
    return controlValue(this, { debounce: 200 }).pipe(map(() => this.serialize()));
  }
}
