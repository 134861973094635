/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../sidebar/components/sidebar-field/sidebar-field.component.ngfactory";
import * as i2 from "../../../sidebar/components/sidebar-field/sidebar-field.component";
import * as i3 from "../margin-control/margin-control.component.ngfactory";
import * as i4 from "../margin-control/margin-control.component";
import * as i5 from "@angular/common";
import * as i6 from "../../../../common/tips/directives/tip/tip.directive";
import * as i7 from "../../../../shared/pipes/is-set/is-set.pipe";
import * as i8 from "@angular/cdk/overlay";
import * as i9 from "@angular/cdk/bidi";
import * as i10 from "./styles-edit-margin.component";
var styles_StylesEditMarginComponent = [];
var RenderType_StylesEditMarginComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_StylesEditMarginComponent, data: {} });
export { RenderType_StylesEditMarginComponent as RenderType_StylesEditMarginComponent };
function View_StylesEditMarginComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "div", [["class", "popover2 overlay-content overlay-content_animated"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 7, "div", [["class", "popover2__inner popover2__inner_sidebar"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 6, "div", [["class", "popover2__viewport popover2__viewport_scrollable"]], [[4, "max-height", "vh"]], null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 5, "div", [], [[4, "width", "px"]], null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 4, "div", [["class", "sidebar__element sidebar__element_margin_s"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 3, "app-sidebar-field", [], null, null, null, i1.View_SidebarFieldComponent_0, i1.RenderType_SidebarFieldComponent)), i0.ɵdid(6, 114688, null, 0, i2.SidebarFieldComponent, [], { label: [0, "label"] }, null), (_l()(), i0.ɵeld(7, 0, null, 3, 1, "app-margin-control", [], null, null, null, i3.View_MarginControlComponent_0, i3.RenderType_MarginControlComponent)), i0.ɵdid(8, 114688, null, 0, i4.MarginControlComponent, [], { control: [0, "control"], focus: [1, "focus"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.subtitle; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.control; var currVal_4 = _co.added; _ck(_v, 8, 0, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_0 = 80; _ck(_v, 2, 0, currVal_0); var currVal_1 = 300; _ck(_v, 3, 0, currVal_1); }); }
function View_StylesEditMarginComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "sidebar-list-item__left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "sidebar-list-item__icon sidebar-list-item__icon_size_l"]], null, null, null, null, null)), i0.ɵdid(2, 278528, null, 0, i5.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "sidebar-list-item__icon sidebar-list-item__icon_size_l"; var currVal_1 = ("icon-" + _co.icon); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_StylesEditMarginComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.additional; _ck(_v, 1, 0, currVal_0); }); }
function View_StylesEditMarginComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "sidebar-list-item__title"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), (_l()(), i0.ɵeld(3, 0, null, null, 4, "div", [["class", "sidebar-list-item__description"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, [" ", " "])), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_StylesEditMarginComponent_4)), i0.ɵdid(6, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵppd(7, 1)], function (_ck, _v) { var _co = _v.component; var currVal_2 = i0.ɵunv(_v, 6, 0, _ck(_v, 7, 0, i0.ɵnov(_v.parent, 0), _co.additional)); _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.valueStr; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.subtitle; _ck(_v, 4, 0, currVal_1); }); }
function View_StylesEditMarginComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.additional; _ck(_v, 1, 0, currVal_0); }); }
function View_StylesEditMarginComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "sidebar-list-item__title sidebar-list-item__title_secondary"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, [" ", " "])), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_StylesEditMarginComponent_6)), i0.ɵdid(4, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵppd(5, 1)], function (_ck, _v) { var _co = _v.component; var currVal_1 = i0.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i0.ɵnov(_v.parent, 0), _co.additional)); _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.subtitle; _ck(_v, 2, 0, currVal_0); }); }
function View_StylesEditMarginComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "sidebar-list-item__right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "sidebar-list-item__action sidebar-list-item__action_hover-only icon-bin"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        $event.stopPropagation();
        var pd_0 = (_co.remove.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 737280, null, 0, i6.TipDirective, [i0.ElementRef, i0.ComponentFactoryResolver, i0.Injector, i0.ApplicationRef], { content: [0, "content"], optionsRaw: [1, "optionsRaw"] }, null), i0.ɵpod(3, { side: 0 })], function (_ck, _v) { var currVal_0 = "Remove"; var currVal_1 = _ck(_v, 3, 0, "top"); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_StylesEditMarginComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i7.IsSetPipe, []), i0.ɵqud(402653184, 1, { cdkConnectedOverlay: 0 }), (_l()(), i0.ɵand(16777216, null, null, 2, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("backdropClick" === en)) {
        var pd_0 = (_co.setDropdownOpened(false) !== false);
        ad = (pd_0 && ad);
    } if (("detach" === en)) {
        var pd_1 = (_co.setDropdownOpened(false) !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_StylesEditMarginComponent_1)), i0.ɵdid(3, 671744, [[1, 4]], 0, i8.CdkConnectedOverlay, [i8.Overlay, i0.TemplateRef, i0.ViewContainerRef, i8.ɵc, [2, i9.Directionality]], { origin: [0, "origin"], positions: [1, "positions"], backdropClass: [2, "backdropClass"], panelClass: [3, "panelClass"], open: [4, "open"], hasBackdrop: [5, "hasBackdrop"], growAfterOpen: [6, "growAfterOpen"], push: [7, "push"] }, { backdropClick: "backdropClick", detach: "detach" }), i0.ɵpad(4, 2), (_l()(), i0.ɵeld(5, 0, null, null, 12, "div", [["cdkOverlayOrigin", ""], ["class", "sidebar-list-item sidebar-list-item_compact sidebar-list-item_clickable"]], [[2, "sidebar-list-item_contrast", null], [2, "sidebar-list-item_disabled", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setDropdownOpened(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(6, 16384, [["origin", 4]], 0, i8.CdkOverlayOrigin, [i0.ElementRef], null, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_StylesEditMarginComponent_2)), i0.ɵdid(8, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(9, 0, null, null, 6, "div", [["class", "sidebar-list-item__main"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_StylesEditMarginComponent_3)), i0.ɵdid(11, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵppd(12, 1), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_StylesEditMarginComponent_5)), i0.ɵdid(14, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵppd(15, 1), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_StylesEditMarginComponent_7)), i0.ɵdid(17, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 6); var currVal_1 = _co.popoverPositions; var currVal_2 = "popover2-backdrop"; var currVal_3 = _ck(_v, 4, 0, "overlay", "overlay_horizontal_center"); var currVal_4 = _co.dropdownOpened; var currVal_5 = true; var currVal_6 = true; var currVal_7 = true; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_10 = _co.icon; _ck(_v, 8, 0, currVal_10); var currVal_11 = i0.ɵunv(_v, 11, 0, _ck(_v, 12, 0, i0.ɵnov(_v, 0), _co.valueStr)); _ck(_v, 11, 0, currVal_11); var currVal_12 = !i0.ɵunv(_v, 14, 0, _ck(_v, 15, 0, i0.ɵnov(_v, 0), _co.valueStr)); _ck(_v, 14, 0, currVal_12); var currVal_13 = _co.removeEnabled; _ck(_v, 17, 0, currVal_13); }, function (_ck, _v) { var _co = _v.component; var currVal_8 = _co.contrast; var currVal_9 = (_co.disabled || _co.dropdownOpened); _ck(_v, 5, 0, currVal_8, currVal_9); }); }
export function View_StylesEditMarginComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-styles-edit-margin", [], null, null, null, View_StylesEditMarginComponent_0, RenderType_StylesEditMarginComponent)), i0.ɵdid(1, 4440064, null, 0, i10.StylesEditMarginComponent, [i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StylesEditMarginComponentNgFactory = i0.ɵccf("app-styles-edit-margin", i10.StylesEditMarginComponent, View_StylesEditMarginComponent_Host_0, { control: "control", subtitle: "subtitle", additional: "additional", icon: "icon", contrast: "contrast", disabled: "disabled", added: "added", removeEnabled: "removeEnabled" }, { remove: "remove" }, []);
export { StylesEditMarginComponentNgFactory as StylesEditMarginComponentNgFactory };
