import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';

import { ThemeService } from '@modules/theme';
import { controlValue } from '@shared';

import { ColorSetValue } from '../text-editor/quill-color-set';
import { TextEditorSelectionOverlay } from '../text-editor/text-editor-selection-overlay';

@Component({
  selector: 'app-text-editor-color-picker',
  templateUrl: './text-editor-color-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextEditorColorPickerComponent extends TextEditorSelectionOverlay<ColorSetValue>
  implements OnInit, OnDestroy {
  colorDefaultControl = new FormControl();
  colorDarkControl = new FormControl();

  constructor(public themeService: ThemeService) {
    super();
  }

  ngOnInit() {}

  ngOnDestroy(): void {}

  initValue() {
    if (this.value) {
      this.colorDefaultControl.patchValue(this.value.colorDefault);
      this.colorDarkControl.patchValue(this.value.colorDark);
    }

    combineLatest(controlValue<string>(this.colorDefaultControl), controlValue<string>(this.colorDarkControl))
      .pipe(untilDestroyed(this))
      .subscribe(([colorDefault, colorDark]) => {
        this.valueChange.emit({
          ...this.value,
          colorDefault: colorDefault,
          colorDark: colorDark
        });
      });
  }

  resetCurrentColor() {
    if (this.themeService.isDefaultTheme) {
      this.colorDefaultControl.patchValue(undefined);
    }

    if (this.themeService.isDarkTheme) {
      this.colorDarkControl.patchValue(undefined);
    }
  }
}
