import {
  CdkConnectedOverlay,
  CdkOverlayOrigin,
  ConnectedOverlayPositionChange,
  ConnectedPosition
} from '@angular/cdk/overlay';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fromEvent, Subscription } from 'rxjs';

import { ModelBasedDataSource } from '@modules/data-sources';
import { ModelOption, ModelOptionSelectedEvent } from '@modules/filters-components';

@Component({
  selector: 'app-field-inputs-add-overlay',
  templateUrl: './field-inputs-add-overlay.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'appFieldInputsAddOverlay'
})
export class FieldInputsAddOverlayComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() dataSource: ModelBasedDataSource;
  @Input() origin: CdkOverlayOrigin;
  @Input() trigger: HTMLElement;
  @Input() triggerManual = false;
  @Input() nestedFieldsEnabled = true;
  @Input() relationsEnabled = true;
  @Input() optionsFilter: (option: ModelOption, path: ModelOption[]) => boolean;
  @Output() added = new EventEmitter<ModelOptionSelectedEvent>();

  @ViewChild(CdkConnectedOverlay) cdkConnectedOverlay: CdkConnectedOverlay;

  dropdownOpened = false;
  popoverPositions: ConnectedPosition[] = [
    {
      panelClass: ['overlay_position_bottom-left'],
      originX: 'start',
      overlayX: 'start',
      originY: 'bottom',
      overlayY: 'top',
      offsetX: -8 - 16,
      offsetY: -8 + 8
    },
    {
      panelClass: ['overlay_position_bottom-right'],
      originX: 'end',
      overlayX: 'end',
      originY: 'bottom',
      overlayY: 'top',
      offsetX: 8 + 16,
      offsetY: -8 + 8
    },
    {
      panelClass: ['overlay_position_top-left'],
      originX: 'start',
      overlayX: 'start',
      originY: 'top',
      overlayY: 'bottom',
      offsetX: -8 - 16,
      offsetY: 8 - 8
    },
    {
      panelClass: ['overlay_position_top-right'],
      originX: 'end',
      overlayX: 'end',
      originY: 'top',
      overlayY: 'bottom',
      offsetX: 8 + 16,
      offsetY: 8 - 8
    },
    {
      panelClass: ['overlay_position_left-center'],
      originX: 'start',
      overlayX: 'end',
      originY: 'center',
      overlayY: 'center',
      offsetX: -8
    },
    {
      panelClass: ['overlay_position_right-center'],
      originX: 'end',
      overlayX: 'start',
      originY: 'center',
      overlayY: 'center',
      offsetX: 8
    }
  ];
  popoverPositionsSubscription: Subscription;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {
    if (!this.triggerManual) {
      let trigger: HTMLElement;

      if (this.trigger) {
        trigger = this.trigger;
      } else if (this.origin) {
        trigger = this.origin.elementRef.nativeElement;
      }

      if (trigger) {
        fromEvent(trigger, 'click')
          .pipe(untilDestroyed(this))
          .subscribe(() => {
            this.open();
          });
      }
    }
  }

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    this.setPositionObserver();
  }

  setDropdownOpened(value: boolean) {
    this.dropdownOpened = value;
    this.cd.markForCheck();
  }

  public isOpened(): boolean {
    return this.dropdownOpened;
  }

  public open() {
    this.setDropdownOpened(true);
  }

  public close() {
    this.setDropdownOpened(false);
  }

  setPositionObserver() {
    if (this.popoverPositionsSubscription) {
      this.popoverPositionsSubscription.unsubscribe();
    }

    if (!this.cdkConnectedOverlay) {
      return;
    }

    this.popoverPositionsSubscription = this.cdkConnectedOverlay.positionChange
      .pipe(untilDestroyed(this))
      .subscribe((e: ConnectedOverlayPositionChange) => {
        const propsEqual = ['offsetX', 'offsetY', 'originX', 'originY', 'overlayX', 'overlayY'];
        const position = this.popoverPositions.find(item =>
          propsEqual.every(prop => (item[prop] || undefined) == e.connectionPair[prop])
        );
        const otherPosition = this.popoverPositions.filter(item => item !== position);

        if (position) {
          this.cdkConnectedOverlay.overlayRef.addPanelClass(position.panelClass);
        }

        otherPosition.forEach(item => this.cdkConnectedOverlay.overlayRef.removePanelClass(item.panelClass));
      });
  }
}
