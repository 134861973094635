import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import * as moment from 'moment';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, of } from 'rxjs';
import { auditTime, delay, distinctUntilChanged, filter, map, skip, switchMap } from 'rxjs/operators';

import { ActionDescription, DownloadActionType } from '@modules/actions';
import { ActionEditController, ActionQueryEditController } from '@modules/actions-components';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { DraftChangesService } from '@modules/customize-utils';
import { CustomSelectComponent, CustomSelectItem, Option } from '@modules/field-components';
import { createFormFieldFactory, getFieldDescriptionByType } from '@modules/fields';
import { ModelQueryEditController } from '@modules/model-components';
import { ModelDescriptionStore } from '@modules/model-queries';
import { Resource } from '@modules/projects';
import { ActionQuery, editableQueryTypes, HttpQuery, ModelDescriptionQuery, QueryType } from '@modules/queries';
import {
  HttpResultsSection,
  QueryBuilderContext,
  QueryBuilderSaveEvent,
  QueryBuilderService,
  SqlResultsSection
} from '@modules/queries-components';
import { isResourceActionCustom, ResourceControllerService } from '@modules/resources';
import { Token } from '@modules/tokens';
import { controlValue, isSet, tapOnce } from '@shared';

import { ActionOption, CustomizeBarActionEditForm } from '../../customize-bar-action-edit.form';

@Component({
  selector: 'app-customize-bar-action-edit-type-download',
  templateUrl: './customize-bar-action-edit-type-download.component.html',
  providers: [QueryBuilderContext],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeBarActionEditTypeDownloadComponent implements OnInit, OnDestroy {
  @Input() form: CustomizeBarActionEditForm;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() contextElementPath: (string | number)[];
  @Input() contextElementPaths: (string | number)[][];
  @Input() setupOnCreate = false;
  @Input() showDraftChanges = false;
  @Input() analyticsSource: string;

  @ViewChild(CustomSelectComponent) customSelectComponent: CustomSelectComponent;

  createField = createFormFieldFactory();
  editableQueryTypes = editableQueryTypes;
  resource: Resource;
  resourceActionItems: CustomSelectItem<ActionOption>[];
  resourceBaseHttpQuery: HttpQuery;
  actionDescription: ActionDescription;
  actionDescriptionQuery = false;
  hasDraftChanges = false;
  typeOptions: Option<DownloadActionType>[] = [
    {
      value: DownloadActionType.Query,
      name: 'Load file',
      icon: 'cloud_download'
    },
    {
      value: DownloadActionType.Input,
      name: 'Specify file',
      icon: 'input'
    }
  ];
  fileColumnOptions: Option<string>[] = [];
  downloadActionTypes = DownloadActionType;
  queryTypes = QueryType;

  constructor(
    private queryContext: QueryBuilderContext,
    private queryBuilderService: QueryBuilderService,
    private actionEditController: ActionEditController,
    private actionQueryEditController: ActionQueryEditController,
    private modelQueryEditController: ModelQueryEditController,
    private modelDescriptionStore: ModelDescriptionStore,
    private resourceControllerService: ResourceControllerService,
    private draftChangesService: DraftChangesService,
    private injector: Injector,
    private analyticsService: UniversalAnalyticsService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.queryContext.parametersControl = this.form.controls.action_params;
    this.updateTokens();

    this.form.controls.action_params.valueChanges
      .pipe(delay(0), untilDestroyed(this))
      .subscribe(() => this.updateTokens());

    combineLatest(
      this.form.resource$(this.form.controls.resource),
      this.form.resourceActionDownloadItems$(this.form.controls.resource),
      this.form.resourceBaseHttpQuery$(this.form.controls.resource),
      this.form.actionDescription$(this.form.controls.resource, this.form.controls.action)
    )
      .pipe(
        auditTime(1000 / 60),
        tapOnce(() => {
          if (
            this.setupOnCreate &&
            this.form.value['action'] &&
            this.form.value['action'].queryType &&
            this.form.value['action'].queryType != QueryType.Simple
          ) {
            this.editCustomQuery();
          }
        }, true),
        untilDestroyed(this)
      )
      .subscribe(result => {
        [this.resource, this.resourceActionItems, this.resourceBaseHttpQuery, this.actionDescription] = result;
        this.actionDescriptionQuery = isResourceActionCustom(this.resource, this.actionDescription);
        this.cd.markForCheck();
      });

    this.form
      .getOutputs$()
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        this.fileColumnOptions = [
          {
            value: null,
            name: 'Use response as file',
            icon: 'document'
          },
          ...value.outputs.map(item => {
            return {
              value: item.name,
              name: item.name,
              icon: item.fieldDescription ? item.fieldDescription.icon : undefined
            };
          })
        ];
        this.cd.markForCheck();
      });

    controlValue<ActionQuery>(this.form.controls.query)
      .pipe(
        delay(0),
        map(query => (query ? query.queryType : undefined)),
        distinctUntilChanged(),
        skip(1),
        untilDestroyed(this)
      )
      .subscribe(queryType => {
        if (editableQueryTypes.includes(queryType)) {
          this.editCustomQuery();
          this.customSelectComponent.closeDropdown();
        }
      });

    this.form
      .resource$(this.form.controls.resource, true)
      .pipe(skip(1), untilDestroyed(this))
      .subscribe(resource => {
        if (resource) {
          if (this.customSelectComponent) {
            this.customSelectComponent.openDropdown();
          }

          this.analyticsService.sendSimpleEvent(AnalyticsEvent.ComponentData.ResourceSelected, {
            ComponentTypeID: this.analyticsSource,
            ResourceID: resource ? resource.typeItem.name : undefined,
            ResourceDemo: resource ? resource.demo : false
          });
        }
      });

    combineLatest(
      this.form.resource$(this.form.controls.resource, true).pipe(skip(1)),
      this.form.controls.action.valueChanges
    )
      .pipe(untilDestroyed(this))
      .subscribe(([resource, action]) => {
        if (action) {
          this.analyticsService.sendSimpleEvent(AnalyticsEvent.ComponentData.ResourceItemSelected, {
            ComponentTypeID: this.analyticsSource,
            ResourceID: resource ? resource.typeItem.name : undefined,
            ResourceDemo: resource ? resource.demo : false
          });
        }
      });

    if (this.showDraftChanges) {
      combineLatest(
        this.form.resource$(this.form.controls.resource),
        this.form.actionDescription$(this.form.controls.resource, this.form.controls.action)
      )
        .pipe(
          switchMap(([resource, actionDescription]) => {
            const modelId =
              actionDescription && isSet(actionDescription.resource) && isSet(actionDescription.model)
                ? [actionDescription.resource, actionDescription.model].join('.')
                : undefined;
            const action =
              actionDescription && isSet(actionDescription.resource) && isSet(actionDescription.name)
                ? [actionDescription.resource, actionDescription.name].join('.')
                : undefined;
            return this.draftChangesService.getDraftChanges$({
              resource: resource ? resource.uniqueName : undefined,
              modelId: modelId,
              action: action
            });
          }),
          untilDestroyed(this)
        )
        .subscribe(changes => {
          this.hasDraftChanges = changes.filter(item => item.count).length > 0;
          this.cd.markForCheck();
        });
    }
  }

  ngOnDestroy(): void {}

  updateTokens() {
    let tokens: Token[] = [];

    tokens = tokens.concat([
      {
        name: undefined,
        label: 'Inputs',
        data: {
          parameter_tokens: true
        },
        children: this.form.value['action_params']
          .filter(item => item.name)
          .map(item => {
            const fieldDescription = getFieldDescriptionByType(item.field);

            return {
              name: ['params', item.name].join('.'),
              field: item.field,
              params: item.params,
              label: item.verboseName || item.name,
              icon: fieldDescription.icon
            };
          })
      }
    ]);

    this.queryContext.tokens = tokens;
  }

  editCustomQuery(
    options: { initialHttpResultsSection?: HttpResultsSection; initialSqlResultsSection?: SqlResultsSection } = {}
  ) {
    if (!this.resource) {
      return;
    }

    const query = this.form.controls.query.value;

    this.queryBuilderService
      .edit({
        injector: this.injector,
        queryClass: this.form.queryClass,
        queryTypes: [query.queryType],
        resource: this.resource,
        resourceType: this.resource.type,
        context: this.queryContext,
        query: query,
        parametersControl: this.form.controls.action_params,
        httpOptions: {
          baseQuery: this.resourceBaseHttpQuery,
          initialResultsSection: options.initialHttpResultsSection
        },
        sqlOptions: {
          initialResultsSection: options.initialSqlResultsSection
        },
        source: this.analyticsSource
      })
      .pipe(untilDestroyed(this))
      .subscribe(e => {
        if (e.saved) {
          this.onCustomQuerySaved(e.saved);
        }
      });
  }

  onCustomQuerySaved(event: QueryBuilderSaveEvent) {
    this.form.setQuery(event.query);
    this.form.setAutoDetectOutputs(event.query, { markAsDirty: true });
  }

  editSavedQuery(
    options: { initialHttpResultsSection?: HttpResultsSection; initialSqlResultsSection?: SqlResultsSection } = {}
  ) {
    if (this.actionDescription.model) {
      return this.modelDescriptionStore
        .getDetailFirst(this.actionDescription.model)
        .pipe(
          switchMap(modelDescription => {
            if (!modelDescription) {
              return of(undefined);
            }

            const controller = this.resourceControllerService.get(this.resource.type);
            const querySettings = controller.getModelDescriptionQuerySettings(
              this.resource,
              modelDescription,
              this.actionDescription.modelAction
            );

            if (!querySettings) {
              return of(undefined);
            }

            return this.modelQueryEditController.editAutoAction({
              resource: this.resource,
              modelDescription: modelDescription,
              name: this.actionDescription.modelAction,
              query: querySettings.query,
              queryClass: ModelDescriptionQuery,
              queryContext: this.queryContext,
              parametersControl: this.queryContext.parametersControl,
              httpOptions: {
                baseQuery: this.resourceBaseHttpQuery,
                initialResultsSection: options.initialHttpResultsSection
              },
              sqlOptions: {
                initialResultsSection: options.initialSqlResultsSection
              },
              analyticsSource: this.analyticsSource
            });
          }),
          filter(value => value),
          untilDestroyed(this)
        )
        .subscribe(
          model => {
            const instance: ActionQuery = cloneDeep(this.form.controls.query.value);
            instance.updated = moment();
            this.form.setQuery(instance);
          },
          e => {
            console.error(e);
          }
        );
    } else {
      const query = this.actionDescription.queryAction ? this.actionDescription.queryAction.query : undefined;
      const controller = this.resourceControllerService.get(this.resource.type);
      const queryType = query
        ? query.queryType
        : controller
            .supportedQueryTypes(this.resource.typeItem, this.form.queryClass)
            .filter(item => item != QueryType.Simple)[0];

      return this.actionQueryEditController
        .edit({
          resource: this.resource,
          actionType: this.form.controls.type.value,
          action: this.actionDescription,
          queryType: queryType,
          queryContext: this.queryContext,
          parametersControl: this.queryContext.parametersControl,
          baseQuery: this.resourceBaseHttpQuery,
          analyticsSource: this.analyticsSource
        })
        .pipe(untilDestroyed(this))
        .subscribe(model => {
          const instance: ActionQuery = cloneDeep(this.form.controls.query.value);
          instance.updated = moment();
          this.form.setQuery(instance);
        });
    }
  }

  editQuery(
    options: { initialHttpResultsSection?: HttpResultsSection; initialSqlResultsSection?: SqlResultsSection } = {}
  ) {
    const option = this.form.value['action'] as ActionOption;

    if (option && editableQueryTypes.includes(option.queryType)) {
      this.editCustomQuery(options);
    } else if (option && option.queryType == QueryType.Simple && this.actionDescriptionQuery) {
      this.editSavedQuery(options);
    }
  }

  createAction() {
    this.actionEditController
      .createAction(this.resource, {
        type: this.form.controls.type.value,
        query: this.form.controls.query.value,
        parameters: this.form.controls.action_params.value
      })
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        this.form.controls.action.patchValue({
          queryType: QueryType.Simple,
          action: result.name
        });
      });
  }
}
