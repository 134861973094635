var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AfterViewInit, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import 'brace/mode/html';
import 'brace/mode/javascript';
import 'brace/snippets/text';
import 'brace/theme/github';
import { AceEditorDirective } from 'ng2-ace-editor';
import { Observable } from 'rxjs';
import { FieldType, registerFieldComponent } from '@modules/fields';
import { ThemeService } from '@modules/theme';
import { FieldComponent } from '../field/field.component';
var CodeFieldComponent = /** @class */ (function (_super) {
    __extends(CodeFieldComponent, _super);
    function CodeFieldComponent(themeService) {
        var _this = _super.call(this) || this;
        _this.themeService = themeService;
        return _this;
    }
    CodeFieldComponent.prototype.ngOnDestroy = function () { };
    CodeFieldComponent.prototype.ngOnChanges = function (changes) {
        var modelDescriptionRoot = this.field.params['modelDescription'];
        if (!modelDescriptionRoot) {
            return;
        }
    };
    CodeFieldComponent.prototype.ngAfterViewInit = function () {
        this.ngOnChanges({});
        if (this.field.params['wrap']) {
            this.ace.editor.session.setUseWrapMode(true);
        }
    };
    Object.defineProperty(CodeFieldComponent.prototype, "theme", {
        get: function () {
            if (this.themeService.theme == 'dark' || this.field.params['dark']) {
                return 'dracula';
            }
            else {
                return this.field.params['field_contrast'] ? 'xcode-contrast' : 'xcode';
            }
        },
        enumerable: true,
        configurable: true
    });
    CodeFieldComponent.prototype.editorInsert = function (text) {
        this.ace.editor.session.replace(this.ace.editor.selection.getRange(), text);
    };
    CodeFieldComponent.prototype.getFocus$ = function () {
        var _this = this;
        return new Observable(function (observer) {
            observer.next(_this.ace.editor.isFocused());
            var focusListener = _this.ace.editor.addEventListener('focus', function () { return observer.next(true); });
            var blurListener = _this.ace.editor.addEventListener('blur', function () { return observer.next(false); });
            return function () {
                if (_this.ace && _this.ace.editor) {
                    _this.ace.editor.removeEventListener('focus', focusListener);
                    _this.ace.editor.removeEventListener('blur', blurListener);
                }
            };
        });
    };
    CodeFieldComponent.prototype.focus = function () {
        if (!this.ace) {
            return;
        }
        this.ace.editor.focus();
    };
    return CodeFieldComponent;
}(FieldComponent));
export { CodeFieldComponent };
registerFieldComponent(FieldType.Code, CodeFieldComponent);
