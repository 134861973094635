var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormGroup } from '@angular/forms';
import { map } from 'rxjs/operators';
import { controlValue, isSet } from '@shared';
import { NumberControl } from './number.control';
var MarginControl = /** @class */ (function (_super) {
    __extends(MarginControl, _super);
    function MarginControl(value) {
        if (value === void 0) { value = {}; }
        return _super.call(this, {
            top: new NumberControl(value.top),
            right: new NumberControl(value.right),
            bottom: new NumberControl(value.bottom),
            left: new NumberControl(value.left)
        }) || this;
    }
    MarginControl.prototype.deserialize = function (value) {
        if (value) {
            this.patchValue({
                top: value.top,
                right: value.right,
                bottom: value.bottom,
                left: value.left
            });
        }
        else {
            this.patchValue({
                top: undefined,
                right: undefined,
                bottom: undefined,
                left: undefined
            });
        }
    };
    MarginControl.prototype.isSet = function () {
        return [this.controls.top, this.controls.right, this.controls.bottom, this.controls.left].some(function (control) {
            return isSet(control.value);
        });
    };
    MarginControl.prototype.isSet$ = function () {
        var _this = this;
        return controlValue(this).pipe(map(function () { return _this.isSet(); }));
    };
    MarginControl.prototype.reset = function (options) {
        this.controls.top.reset(undefined, options);
        this.controls.right.reset(undefined, options);
        this.controls.bottom.reset(undefined, options);
        this.controls.left.reset(undefined, options);
    };
    MarginControl.prototype.serialize = function () {
        if (!this.isSet()) {
            return;
        }
        return {
            top: this.controls.top.value,
            right: this.controls.right.value,
            bottom: this.controls.bottom.value,
            left: this.controls.left.value
        };
    };
    MarginControl.prototype.serialize$ = function () {
        var _this = this;
        return controlValue(this, { debounce: 200 }).pipe(map(function () { return _this.serialize(); }));
    };
    return MarginControl;
}(FormGroup));
export { MarginControl };
