import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Optional,
  ViewChild
} from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import isEqual from 'lodash/isEqual';
import last from 'lodash/last';
import { untilDestroyed } from 'ngx-take-until-destroy';
import Delta from 'quill-delta';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { quillDeltaToHtml$, TextEditorComponent } from '@common/text-editor';
import { ViewContextElement, ViewContextOutput } from '@modules/customize';
import { applyParamInput, FieldType } from '@modules/fields';
import { HOVER_OUTPUT, PRESSED_OUTPUT } from '@modules/list';
import { ThemeService } from '@modules/theme';
import {
  AlignHorizontal,
  AlignVertical,
  FillType,
  LayerInteractionType,
  LayerType,
  TextLayer,
  TextTruncate
} from '@modules/views';
import { isSet, KeyboardEventKeyCode } from '@shared';

import { registerLayerComponent } from '../../../data/layer-components';
import {
  CreatedLayerSource,
  ViewEditorContext,
  ViewEditorCustomizeSource
} from '../../../services/view-editor-context/view-editor.context';
import { markViewLayerClick } from '../../auto-layer/auto-layer.component';
import { LayerComponent } from '../base/layer.component';

@Component({
  selector: 'app-text-layer',
  templateUrl: './text-layer.component.html',
  providers: [ViewContextElement],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextLayerComponent extends LayerComponent<TextLayer> implements OnInit, OnDestroy {
  @ViewChild('text_element') textElement: ElementRef;
  @ViewChild('toolbar_element') toolbarElement: ElementRef<HTMLElement>;
  @ViewChild(TextEditorComponent) textEditorComponent: TextEditorComponent;

  hover$: Observable<boolean>;
  customizing$: Observable<boolean>;
  customizingMultiple$: Observable<boolean>;
  html$: Observable<string>;
  editing = false;
  editingOnCreate = false;
  color: SafeStyle;
  colorSubscription: Subscription;
  textStroke: SafeStyle;
  textStrokeSubscription: Subscription;
  textShadow: SafeStyle;
  textShadowSubscription: Subscription;
  fontFamily: SafeStyle;
  fontStyle: SafeStyle;
  textTransform: SafeStyle;
  textDecoration: SafeStyle;
  alignHorizontal = AlignHorizontal;
  alignVertical = AlignVertical;
  textTruncates = TextTruncate;

  constructor(
    @Optional() editorContext: ViewEditorContext,
    public contextElement: ViewContextElement,
    public themeService: ThemeService,
    private el: ElementRef,
    private sanitizer: DomSanitizer,
    private cd: ChangeDetectorRef
  ) {
    super(editorContext);
  }

  ngOnInit(): void {
    super.ngOnInit();

    if (this.editorContext) {
      this.hover$ = this.editorContext.isTopHoverLayer$(this.layer);
      this.customizing$ = this.editorContext.isCustomizingLayer$(this.layer);
      this.customizingMultiple$ = this.editorContext.isCustomizingMultipleLayers$();
      this.updating$ = this.getLayerUpdating$(() => !this.editorContext.isCreateTool());

      if (this.createdLayer && this.createdLayer.source == CreatedLayerSource.Tool) {
        setTimeout(() => this.startEditing({ onCreate: true }), 0);
      }

      this.editorContext
        .isCustomizingLayer$(this.layer)
        .pipe(untilDestroyed(this))
        .subscribe(customizing => {
          if (!customizing) {
            this.finishEditing();
          }
        });

      this.editorContext
        .trackKeydown()
        .pipe(
          filter(e => this.editorContext.isCustomizingLayer(this.layer)),
          untilDestroyed(this)
        )
        .subscribe(e => {
          if (e.keyCode == KeyboardEventKeyCode.Enter && (e.metaKey || e.ctrlKey) && !this.editing) {
            e.stopPropagation();
            this.toggleEditing();
          } else if (e.keyCode == KeyboardEventKeyCode.Escape && this.editing) {
            e.stopPropagation();
            this.finishEditing();
          }
        });

      this.trackLayerFluidSize(this.textElement.nativeElement)
        .pipe(untilDestroyed(this))
        .subscribe(() => this.cd.markForCheck());
    } else {
      this.html$ = this.layer$.pipe(
        switchMap(layer => {
          return quillDeltaToHtml$(layer.quillDelta, {
            applyContextFormula: input => applyParamInput(input, { context: this.viewContext, defaultValue: '' }),
            addContextFormulaClass: true,
            context: this.viewContext
          });
        })
      );
    }

    this.getLayer$()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.updateColor();
        this.updateTextStroke();
        this.updateTextShadow();
        this.updateFontFamily();
        this.updateFontStyle();
        this.updateTextTransform();
        this.updateTextDecoration();
        this.updateLayerContext();
      });
  }

  ngOnDestroy(): void {}

  saveQuillDelta(value: Delta) {
    this.layer.quillDelta = value;
    this.editorContext.markLayersChanged([this.layer], ViewEditorCustomizeSource.Layer);
  }

  updateColor() {
    if (this.colorSubscription) {
      this.colorSubscription.unsubscribe();
      this.colorSubscription = undefined;
    }

    const fills$ = [...this.layer.fills]
      .reverse()
      .filter(item => item.enabled)
      .map(item => {
        const css$ =
          item.type == FillType.Color
            ? item.css$({ frame: this.layer.frame, context: this.viewContext })
            : of(undefined);
        const enabled$ = item.enabledInput ? item.enabled$({ context: this.viewContext }) : of(true);

        return combineLatest(css$, enabled$).pipe(
          map(([css, enabled]) => {
            return {
              id: item.id,
              color: isSet(css.background) ? this.sanitizer.bypassSecurityTrustStyle(css.background) : undefined,
              enabled: enabled
            };
          })
        );
      });

    if (!fills$.length) {
      this.color = undefined;
      this.cd.markForCheck();
      return;
    }

    this.colorSubscription = combineLatest(fills$)
      .pipe(untilDestroyed(this))
      .subscribe(fills => {
        this.color = last(fills.filter(item => item.enabled).map(item => item.color));
        this.cd.markForCheck();
      });
  }

  updateTextStroke() {
    if (this.textStrokeSubscription) {
      this.textStrokeSubscription.unsubscribe();
      this.textStrokeSubscription = undefined;
    }

    const borders$ = [...this.layer.borders]
      .reverse()
      .filter(item => item.enabled)
      .map(item => {
        const textStroke$ = item.cssTextStroke$({ context: this.viewContext });
        const enabled$ = item.enabledInput ? item.enabled$({ context: this.viewContext }) : of(true);

        return combineLatest(textStroke$, enabled$).pipe(
          map(([textStroke, enabled]) => {
            return {
              textStroke: isSet(textStroke) ? this.sanitizer.bypassSecurityTrustStyle(textStroke) : undefined,
              enabled: enabled
            };
          })
        );
      });

    if (!borders$.length) {
      this.textStroke = undefined;
      this.cd.markForCheck();
      return;
    }

    this.textStrokeSubscription = combineLatest(borders$)
      .pipe(untilDestroyed(this))
      .subscribe(borders => {
        this.textStroke = last(
          borders.filter(item => item.enabled && isSet(item.textStroke)).map(item => item.textStroke)
        );
        this.cd.markForCheck();
      });
  }

  updateTextShadow() {
    if (this.textShadowSubscription) {
      this.textShadowSubscription.unsubscribe();
      this.textShadowSubscription = undefined;
    }

    const shadows$ = this.layer.shadows
      .filter(item => item.enabled)
      .map(item => {
        const textShadow$ = item.cssTextShadow$({ context: this.viewContext });
        const enabled$ = item.enabledInput ? item.enabled$({ context: this.viewContext }) : of(true);

        return combineLatest(textShadow$, enabled$).pipe(
          map(([textShadow, enabled]) => {
            return {
              textShadow: textShadow,
              enabled: enabled
            };
          })
        );
      });

    if (!shadows$.length) {
      this.textShadow = undefined;
      this.cd.markForCheck();
      return;
    }

    this.textShadowSubscription = combineLatest(shadows$)
      .pipe(untilDestroyed(this))
      .subscribe(shadows => {
        this.textShadow = this.sanitizer.bypassSecurityTrustStyle(
          shadows
            .filter(item => item.enabled)
            .map(item => item.textShadow)
            .join(',')
        );
        this.cd.markForCheck();
      });
  }

  updateFontFamily() {
    this.fontFamily = this.layer.font
      ? this.sanitizer.bypassSecurityTrustStyle(this.layer.font.cssFontFamily())
      : undefined;
    this.cd.markForCheck();
  }

  updateFontStyle() {
    this.fontStyle = this.layer.font
      ? this.sanitizer.bypassSecurityTrustStyle(this.layer.font.cssFontStyle())
      : undefined;
    this.cd.markForCheck();
  }

  updateTextTransform() {
    this.textTransform = this.sanitizer.bypassSecurityTrustStyle(this.layer.cssTextTransform());
    this.cd.markForCheck();
  }

  updateTextDecoration() {
    this.textDecoration = this.sanitizer.bypassSecurityTrustStyle(this.layer.cssTextDecoration());
    this.cd.markForCheck();
  }

  updateLayerContext() {
    const hoverOutput = this.layer.interactions.some(item => item.type == LayerInteractionType.HoverOutput);
    const pressedOutput = this.layer.interactions.some(item => item.type == LayerInteractionType.PressedOutput);
    const anyOutputs = hoverOutput || pressedOutput;
    const registered = this.contextElement.isRegistered();

    if (anyOutputs && !registered) {
      this.contextElement.initElement({
        uniqueName: this.layer.id,
        name: this.layer.name,
        icon: this.layer.icon
      });
    } else if (anyOutputs && registered) {
      this.contextElement.initInfo(
        {
          name: this.layer.name,
          icon: this.layer.icon
        },
        true
      );
    } else if (!anyOutputs && registered) {
      this.contextElement.unregister();
    }

    if (anyOutputs) {
      const outputs: ViewContextOutput[] = [];

      if (hoverOutput) {
        outputs.push({
          uniqueName: HOVER_OUTPUT,
          name: `Layer is hovered`,
          icon: 'target',
          fieldType: FieldType.Boolean,
          defaultValue: false,
          external: true
        });
      }

      if (pressedOutput) {
        outputs.push({
          uniqueName: PRESSED_OUTPUT,
          name: `Layer is pressed`,
          icon: 'select_all',
          fieldType: FieldType.Boolean,
          defaultValue: false,
          external: true
        });
      }

      if (
        !isEqual(
          this.contextElement.outputs.map(item => item.uniqueName),
          outputs.map(item => item.uniqueName)
        )
      ) {
        this.contextElement.setOutputs(outputs);
      }
    }
  }

  startEditing(options: { onCreate?: boolean } = {}) {
    if (this.editing || !this.customizeEnabled) {
      return;
    }

    this.editing = true;
    this.editingOnCreate = !!options.onCreate;
    this.cd.detectChanges();
    this.layerCustomize.emit();

    if (this.textEditorComponent) {
      this.textEditorComponent.selectAll();
    }
  }

  finishEditing() {
    if (!this.editing) {
      return;
    }

    this.editing = false;
    this.editingOnCreate = false;
    this.cd.markForCheck();

    if (this.textEditorComponent && this.textEditorComponent.isEmpty()) {
      this.layerDelete.emit();
    }
  }

  toggleEditing() {
    if (this.editing) {
      this.finishEditing();
    } else {
      this.startEditing();
    }
  }

  onTextEditorMouseDown(event: MouseEvent) {
    if (this.editorContext.isCustomizingLayer(this.layer) && this.editing) {
      event.stopPropagation();
    }
  }

  onTextEditorEscapeClick() {
    setTimeout(() => this.finishEditing());
  }

  onToolbarClick(event: MouseEvent) {
    markViewLayerClick(event);
  }
}

registerLayerComponent(LayerType.Text, TextLayerComponent);
