import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnDestroy, OnInit } from '@angular/core';
import range from 'lodash/range';
import * as moment from 'moment';

import { NotificationService } from '@common/notifications';
import { ActionControllerService } from '@modules/action-queries';
import { TintStyle } from '@modules/actions';
import { gteFieldLookup, lteFieldLookup } from '@modules/field-lookups';
import { FilterItem2 } from '@modules/filters';
import { ColumnsModelListStore } from '@modules/list';
import { DATE_PARAM, TYPE_PARAM } from '@modules/models';
import { paramsToGetQueryOptions } from '@modules/resources';

import { CalendarType } from '../../data/calendar-type';
import { CalendarBaseComponent } from '../calendar-base/calendar-base.component';
import { CalendarState } from '../calendar/calendar-state';

export interface WeeksDay {
  date: moment.Moment;
  today: boolean;
  current?: boolean;
  currentMonth: boolean;
  weekend: boolean;
  future: boolean;
  key?: string;
}

export interface DayOfWeek {
  date: moment.Moment;
  today: boolean;
  weekend: boolean;
}

@Component({
  selector: 'app-calendar-month',
  templateUrl: './calendar-month.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ColumnsModelListStore]
})
export class CalendarMonthComponent extends CalendarBaseComponent implements OnInit, OnDestroy {
  weekDays: DayOfWeek[] = [];
  weeks: WeeksDay[][] = [];
  tintStyles = TintStyle;

  constructor(
    injector: Injector,
    actionControllerService: ActionControllerService,
    listStore: ColumnsModelListStore,
    notificationService: NotificationService,
    cd: ChangeDetectorRef
  ) {
    super(injector, actionControllerService, listStore, notificationService, cd);
  }

  fetch(state: CalendarState) {
    const queryOptions = paramsToGetQueryOptions(state.dataSourceParams);
    const dateFilters = this.dateFilterFilters(state);

    queryOptions.filters = [
      ...(queryOptions.filters ? queryOptions.filters : []),
      ...(state.filters ? state.filters : []),
      ...(dateFilters ? dateFilters : [])
    ];
    queryOptions.search = state.search;
    queryOptions.sort = state.sort;

    this.listStore.dataSource = state.dataSource;
    this.listStore.useDataSourceColumns = true;
    this.listStore.staticData = state.dataSourceStaticData;
    this.listStore.queryOptions = queryOptions;
    this.listStore.context = this.context;
    this.listStore.contextElement = this.contextElement;

    this.getItems();
    this.updateVisibleColumns(state);
    this.updateWeeks(state);

    this.prevDate = state.date;
  }

  get now() {
    return moment();
  }

  updateWeeks(state: CalendarState) {
    if (!state.date) {
      this.weekDays = [];
      this.weeks = [];
      this.cd.markForCheck();
      return;
    }

    const month = state.date.clone().startOf('month');
    const firstDay = month.clone().startOf('isoWeek');
    const lastDay = month.clone().endOf('month').endOf('isoWeek');
    const weeks = Math.ceil(lastDay.diff(firstDay, 'weeks', true));

    this.weekDays = range(0, 7).map(day => {
      const date = firstDay.clone().add(day, 'days');

      return {
        date: date,
        weekend: [6, 0].includes(date.day()),
        today: date.isSame(this.now, 'day') && date.isSame(this.now, 'month') && date.isSame(this.now, 'year')
      };
    });

    this.weeks = range(0, weeks).map(week => {
      return range(0, 7).map(day => {
        const date = firstDay.clone().add(week, 'weeks').add(day, 'days');

        return {
          date: date,
          today: date.isSame(this.now, 'day') && date.isSame(this.now, 'month') && date.isSame(this.now, 'year'),
          currentMonth: date.isSame(month, 'month') && date.isSame(month, 'year'),
          weekend: [6, 0].includes(date.day()),
          future: date.isAfter(this.now),
          key: date.format(this.itemsGroupBy())
        };
      });
    });

    this.cd.markForCheck();
  }

  dayParams(date) {
    const params = {};
    params[TYPE_PARAM] = CalendarType.Day;
    params[DATE_PARAM] = date.toISOString();
    return this.applyParams(params);
  }

  dateFilterFilters(state: CalendarState): FilterItem2[] {
    const firstDay = state.date.clone().startOf('month').startOf('isoWeek');
    const lastDay = state.date.clone().endOf('month').endOf('isoWeek');

    return [
      new FilterItem2({
        field: [state.dateField],
        lookup: gteFieldLookup,
        value: firstDay.toISOString()
      }),
      new FilterItem2({
        field: [state.dateField],
        lookup: lteFieldLookup,
        value: lastDay.toISOString()
      })
    ];
  }

  itemsGroupBy(): string {
    return 'DD.MM.YYYY';
  }
}
