import pickBy from 'lodash/pickBy';

import { localize } from '@common/localize';
import { ActionItem } from '@modules/actions';
import { DateFormat, defaultDateFormat, defaultTimeFormat, Input, TimeFormat } from '@modules/fields';
import { isSet } from '@shared';

import { TextStyle } from '../../text-style';
import { registerElementForType } from '../element-items';
import { ElementType } from '../element-type';
import { ElementItem } from './base';
import { ResizableElementItem } from './widget';

export class DateRangeElementItem extends ElementItem implements ResizableElementItem {
  public type = ElementType.DateRange;
  public width: number;
  public verboseName: string;
  public labelStyle: TextStyle;
  public labelAdditional: string;
  public labelAdditionalStyle: TextStyle;
  public from: Input;
  public to: Input;
  public time = true;
  public dateFormat: DateFormat | string = defaultDateFormat;
  public timeFormat: TimeFormat | string = defaultTimeFormat;
  public required = false;
  public fromPlaceholder = '';
  public toPlaceholder = '';
  public resetEnabled = false;
  public disableInput: Input;
  public tooltip: string;
  public onChangeActions: ActionItem[] = [];

  deserialize(data: Object): DateRangeElementItem {
    super.deserialize(data);
    this.width = this.params['width'];
    this.verboseName = this.params['verbose_name'];
    this.required = this.params['required'];
    this.tooltip = this.params['tooltip'];

    if (this.params['label_style']) {
      this.labelStyle = new TextStyle().deserialize(this.params['label_style']);
    } else {
      this.labelStyle = undefined;
    }

    if (this.params['label_additional'] === undefined && this.required) {
      // Backward compatibility
      this.labelAdditional = `(${localize('optional')})`;
    } else {
      this.labelAdditional = this.params['label_additional'];
    }

    if (this.params['label_additional_style']) {
      this.labelAdditionalStyle = new TextStyle().deserialize(this.params['label_additional_style']);
    } else {
      this.labelAdditionalStyle = undefined;
    }

    if (this.params['from']) {
      this.from = new Input().deserialize(this.params['from']);
    }

    if (this.params['to']) {
      this.to = new Input().deserialize(this.params['to']);
    }

    if (isSet(this.params['time'])) {
      this.time = this.params['time'];
    }

    if (isSet(this.params['date_format'], true)) {
      this.dateFormat = this.params['date_format'];
    }

    if (isSet(this.params['time_format'], true)) {
      this.timeFormat = this.params['time_format'];
    }

    if (isSet(this.params['from_placeholder'], true)) {
      this.fromPlaceholder = this.params['from_placeholder'];
    }

    if (isSet(this.params['to_placeholder'], true)) {
      this.toPlaceholder = this.params['to_placeholder'];
    }

    if (isSet(this.params['reset_enabled'])) {
      this.resetEnabled = this.params['reset_enabled'];
    }

    if (this.params['disable_input']) {
      this.disableInput = new Input().deserialize(this.params['disable_input']);
    }

    if (this.params['on_change_actions']) {
      this.onChangeActions = this.params['on_change_actions'].map(item => new ActionItem().deserialize(item));
    }

    return this;
  }

  serialize(fields?: string[]): Object {
    this.params = {
      width: this.width,
      verbose_name: this.verboseName,
      label_style: this.labelStyle ? this.labelStyle.serialize() : undefined,
      label_additional: this.labelAdditional,
      label_additional_style: this.labelAdditionalStyle ? this.labelAdditionalStyle.serialize() : undefined,
      from: this.from ? this.from.serialize() : null,
      to: this.to ? this.to.serialize() : null,
      time: this.time,
      date_format: this.dateFormat,
      time_format: this.timeFormat,
      required: this.required,
      from_placeholder: this.fromPlaceholder,
      to_placeholder: this.toPlaceholder,
      reset_enabled: this.resetEnabled,
      disable_input: this.disableInput ? this.disableInput.serialize() : null,
      tooltip: this.tooltip,
      on_change_actions: this.onChangeActions.map(item => item.serialize())
    };

    let data = super.serialize();
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }

  get typeStr(): string {
    return 'date range';
  }

  get analyticsName(): string {
    return 'date_range';
  }

  defaultName() {
    return 'Date range';
  }
}

registerElementForType(ElementType.DateRange, DateRangeElementItem);
