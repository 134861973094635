<form [formGroup]="form" (ngSubmit)="submit()">
  <ng-container *ngIf="!form.value['generated']">
    <app-customize-bar-header
      [title]="'Generate Form'"
      [titleEnabled]="titleEnabled"
      [backLabel]="'All components'"
      [parentLabel]="parentElement?.displayName || parentPopup?.displayName"
      (parentClick)="parentElement?.customize({ event: $event }) || parentPopup?.customize({ event: $event })"
    >
      <ng-container actions>
        <a
          [appDocumentation]="'components/form'"
          [appDocumentationLeft]="true"
          [appDocumentationSource]="element.analyticsGenericName + '_general'"
          class="button button_background button_icon button_no-margin sidebar-header__delete"
          target="_blank"
        >
          <span class="button__icon icon-help"></span>
        </a>
      </ng-container>
    </app-customize-bar-header>

    <div class="sidebar__content">
      <app-customize-bar-action-edit-type
        [options]="{
          actionItemClass: submitActionClass,
          modelDescriptionInContext: modelDescriptionInContext,
          parametersEditable: false,
          typesOnly: [actionTypes.Query, actionTypes.Link, actionTypes.ExternalLink, actionTypes.Workflow]
        }"
        [form]="form.controls.submit_action"
        [label]="'Submit action'"
        [typeFieldLabel]="'What form should do'"
        [typeFieldRequired]="true"
        [context]="context"
        [contextElement]="contextElement"
        [contextElementPaths]="operationContextElementPaths"
        [firstInit]="firstInit"
        [setupOnCreate]="setupOnCreate"
        [analyticsSource]="element.analyticsGenericName"
      ></app-customize-bar-action-edit-type>

      <ng-container *ngIf="actionOperationConfigured">
        <app-sidebar-section *ngIf="customAction">
          <div class="sidebar__element" [class.sidebar__element_disabled]="!actionOperationConfigured">
            <app-model-description-data-source-button
              [title]="'Fill form with data'"
              [additional]="'(optional)'"
              [control]="form.controls.get_data_source"
              [context]="context"
              [contextElement]="contextElement"
              [remove]="form.value['get_enabled'] && form.controls.get_data_source.controls.query_resource.value"
              [error]="(dataConfigured$ | async) !== false ? undefined : 'Not configured'"
              [classes]="'component-data-button'"
              (editClick)="openGetQuery()"
              (removeClick)="form.resetGetQuery()"
              (addInputClick)="openGetQuery({ addInput: true })"
            >
            </app-model-description-data-source-button>
          </div>
        </app-sidebar-section>

        <ng-container *ngIf="['update', 'delete'].includes(modelAction) && (dataQueryConfigured$ | async)">
          <div class="sidebar__element">
            <app-data-source-bind-component
              [control]="form.controls.get_data_source"
              [context]="context"
              [element]="element"
              [targetBindPrimaryKey]="false"
            ></app-data-source-bind-component>
          </div>

          <app-sidebar-section
            *ngIf="
              (form.controls.get_data_source.controls.type | appControlValue | async) == dataSourceTypes.Input ||
              (form.controls.get_data_source.controls.type | appControlValue | async) == dataSourceTypes.Workflow ||
              ((form.controls.get_data_source.controls.type | appControlValue | async) == dataSourceTypes.Query &&
                form.controls.get_data_source.controls.query.valid)
            "
            [title]="(form.controls.get_data_source.getQueryEditable$() | async) ? 'Specify Inputs' : 'Apply Filters'"
            [description]="
              (form.controls.get_data_source.getQueryEditable$() | async)
                ? 'Manually or use value from other components'
                : 'Static or use value from other components'
            "
          >
            <app-data-source-inputs-edit
              [control]="form.controls.get_data_source"
              [editable]="form.controls.get_data_source.getQueryEditable$() | async"
              [context]="context"
              [contextElement]="contextElement"
              [contextElementPath]="contextElementPath"
              [contextElementPaths]="contextElementPaths"
              [classes]="'component-data-inputs'"
              [itemClasses]="'component-data-inputs-item'"
              [addClasses]="'component-data-inputs-add'"
              [analyticsSource]="element.analyticsGenericName"
              (addInput)="addQueryInput()"
            ></app-data-source-inputs-edit>
          </app-sidebar-section>
        </ng-container>
      </ng-container>

      <app-sidebar-section [border]="false">
        <div class="sidebar__element">
          <a
            href="javascript:void(0)"
            class="button button_orange button_primary button_fill"
            [class.button_disabled]="!actionOperationConfigured"
            (click)="form.generateForm()"
          >
            <span class="button__icon button__icon_left icon-magic_wand"></span>
            <span class="button__label">Generate</span>
            <span class="button__icon button__icon_right icon-arrow_forward_2"></span>
          </a>
        </div>
      </app-sidebar-section>
    </div>
  </ng-container>

  <ng-container *ngIf="form.value['generated']">
    <app-customize-bar-header
      [title]="form.value['name'] || (titleEditable ? 'form' : 'Form')"
      [titleEnabled]="titleEnabled"
      [titleEditable]="titleEditable"
      [titleCleanValue]="titleCleanValue"
      [backLabel]="backLabel"
      (titleChanged)="onTitleChanged($event)"
      [deleteEnabled]="deleteEnabled"
      [parentLabel]="parentElement?.displayName || parentPopup?.displayName"
      (parentClick)="parentElement?.customize({ event: $event }) || parentPopup?.customize({ event: $event })"
      (deleteClick)="delete()"
    >
    </app-customize-bar-header>

    <div class="sidebar__content">
      <app-sidebar-tabs>
        <app-sidebar-tab
          [label]="'General'"
          [error]="(operationValid$ | async) !== false ? undefined : 'Not configured'"
        >
          <app-sidebar-section>
            <div class="sidebar__element">
              <app-sidebar-field [label]="'What form will do'" [required]="true">
                <app-action-form-single-edit
                  [options]="{
                    actionItemClass: submitActionClass,
                    modelDescriptionInContext: modelDescriptionInContext
                  }"
                  [label]="'Submit action'"
                  [title]="'Submit action'"
                  [form]="form.controls.submit_action"
                  [context]="context"
                  [contextElement]="contextElement"
                  [contextElementPaths]="operationContextElementPaths"
                  [defaultSubtitle]="'Not specified'"
                  [defaultDisabled]="false"
                  [icon]="'cursor'"
                  [object]="element.analyticsGenericName"
                  [customError]="!actionConfigured && form.dirty ? 'Not configured' : null"
                  [classes]="'component-action-button'"
                  [firstInit]="firstInit"
                  [setupOnCreate]="setupOnCreate"
                >
                </app-action-form-single-edit>
              </app-sidebar-field>
            </div>

            <div class="sidebar__element">
              <app-model-description-data-source-button
                [title]="'Fill form with data'"
                [additional]="'(optional)'"
                [control]="form.controls.get_data_source"
                [context]="context"
                [contextElement]="contextElement"
                [remove]="form.value['get_enabled'] && form.controls.get_data_source.controls.query_resource.value"
                [error]="(dataConfigured$ | async) !== false ? undefined : 'Not configured'"
                [classes]="'component-data-button'"
                (editClick)="openGetQuery()"
                (removeClick)="form.resetGetQuery()"
                (addInputClick)="openGetQuery({ addInput: true })"
              >
              </app-model-description-data-source-button>
            </div>
          </app-sidebar-section>

          <app-sidebar-section *ngIf="fields.length || missingFields.length" [title]="'Form fields'" [border]="false">
            <div class="sidebar__list">
              <div class="sidebar-list">
                <div *ngFor="let item of fields" class="sidebar-list__item">
                  <div
                    class="sidebar-list-item sidebar-list-item_clickable"
                    [class.sidebar-list-item_draggable]="false"
                    [class.sidebar-list-item_disabled]="false"
                    (click)="customizeField(item)"
                  >
                    <div class="sidebar-list-item__left">
                      <span class="button button_icon button_orange button_extra-small" style="margin: 0;">
                        <span class="icon button__icon" [ngClass]="['icon-' + item.fieldDescription.icon]"></span>
                      </span>
                    </div>
                    <div class="sidebar-list-item__main">
                      <div class="sidebar-list-item__title">
                        {{ item.settings?.verboseName || item.settings?.name || item.name }}
                      </div>
                    </div>
                    <div class="sidebar-list-item__right">
                      <span
                        class="sidebar-list-item__action sidebar-list-item__action_button icon-bin"
                        (click)="$event.stopPropagation(); deleteFieldProcess(item)"
                      ></span>
                    </div>
                  </div>
                </div>

                <div *ngFor="let item of missingFields" class="sidebar-list__item">
                  <div
                    class="sidebar-list-item sidebar-list-item_clickable"
                    [class.sidebar-list-item_draggable]="false"
                    [class.sidebar-list-item_disabled2]="true"
                    (click)="addField(item)"
                  >
                    <div class="sidebar-list-item__left">
                      <span class="sidebar-list-item__icon" [ngClass]="['icon-' + item.icon]"></span>
                    </div>
                    <div class="sidebar-list-item__main">
                      {{ item.title }}
                    </div>
                    <div class="sidebar-list-item__right">
                      <span class="sidebar-list-item__action sidebar-list-item__action_button icon-plus"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </app-sidebar-section>

          <app-related-model-components
            *ngIf="modelDescription"
            [modelDescription]="modelDescription"
            [context]="context"
            [contextElement]="contextElement"
            [contextElementModelOutput]="itemContextElementPath"
            [collapseContext]="collapseContext"
          ></app-related-model-components>
        </app-sidebar-tab>

        <app-sidebar-tab [label]="'Display'">
          <div class="sidebar__element">
            <app-sidebar-field [label]="'style'">
              <div class="sidebar-image-toggle sidebar-image-toggle_cols_3">
                <div *ngFor="let item of form.styleOptions" class="sidebar-image-toggle__col">
                  <div
                    class="sidebar-image-toggle__item"
                    [class.sidebar-image-toggle__item_active]="item.value === form.controls.style.value"
                    (click)="form.controls.style.patchValue(item.value)"
                  >
                    <div
                      class="sidebar-image-toggle__item-inner"
                      [style.padding-top.%]="(49 / 70) * 100"
                      [style.background-image]="'/assets/images/forms/' + item.image + '.svg' | appDeployCssUrl"
                    ></div>
                  </div>
                </div>
              </div>
            </app-sidebar-field>
          </div>

          <div class="sidebar__element">
            <app-sidebar-field
              [label]="'Conditional Visibility'"
              [documentation]="'conditional-visibility'"
              [documentationLabel]="'See how it works'"
              [documentationSource]="element.analyticsGenericName + '_visible'"
            >
              <app-input-edit
                [itemForm]="form.controls.visible_input"
                [context]="context"
                [contextElement]="contextElement"
                [contextElementPaths]="visibleContextElementPaths"
                [staticValueDisabled]="true"
                [placeholder]="'Value'"
                [fill]="true"
                [analyticsSource]="element.analyticsGenericName + '_visible'"
              ></app-input-edit>

              <ng-container description>
                Example: elements.field.value >= 10
              </ng-container>
            </app-sidebar-field>
          </div>

          <div class="sidebar__element">
            <app-sidebar-field [label]="'load content when hidden'" [layoutLeft]="true">
              <app-auto-field
                [form]="form"
                [label]="false"
                [field]="
                  createField({
                    name: 'load_invisible',
                    field: 'BooleanField',
                    params: { style: booleanFieldStyle.Toggle, orange: true }
                  })
                "
              ></app-auto-field>

              <ng-container description>This can decrease performance</ng-container>
            </app-sidebar-field>
          </div>

          <div class="sidebar__element">
            <app-sidebar-field [label]="'Spacing'">
              <app-margin-control [control]="form.controls.margin"></app-margin-control>
            </app-sidebar-field>
          </div>
        </app-sidebar-tab>

        <app-sidebar-tab [label]="'Actions'" [error]="(actionsValid$ | async) !== false ? undefined : 'Not configured'">
          <div *ngIf="'form_element__actions' | appBannerVisible" class="sidebar__element">
            <a
              [appDocumentation]="'completion-actions'"
              [appDocumentationLeft]="true"
              [appDocumentationSource]="element.analyticsGenericName + '_actions'"
              class="sidebar-float-button"
              target="_blank"
            >
              <div class="sidebar-float-button__icon icon-help"></div>
              <div class="sidebar-float-button__label">See how it works</div>
              <div
                [appHideBanner]="'form_element__actions'"
                class="sidebar-float-button__close icon-close"
                (click)="$event.stopPropagation(); $event.preventDefault()"
              ></div>
            </a>
          </div>

          <app-customize-bar-action-edit-completion
            [form]="form.controls.submit_action"
            [context]="context"
            [contextElement]="contextElement"
            [contextElementPath]="contextElementPath"
            [contextElementPaths]="completionContextElementPaths"
            [object]="element.analyticsGenericName"
            [firstInit]="firstInit"
          ></app-customize-bar-action-edit-completion>

          <app-sidebar-collapse
            [title]="'Approval'"
            [description]="'Create a new approval task'"
            [descriptionWhenOpened]="'Create a new approval task'"
            [context]="collapseContext"
            (openedChanged)="onApprovalOpenedChanged($event)"
            #collapse
          >
            <ng-container right>
              <span class="sidebar-collapse__action">
                <app-toggle
                  [orange]="true"
                  [selected]="form.controls.submit_action.value['approve_enabled']"
                  (click)="
                    form.controls.submit_action.controls.approve_enabled.patchValue(
                      !form.controls.submit_action.value['approve_enabled']
                    );
                    form.controls.submit_action.markAsDirty();
                    $event.stopPropagation()
                  "
                >
                </app-toggle>
              </span>
            </ng-container>

            <app-customize-bar-approve-edit
              *ngIf="collapse.opened"
              [control]="form.controls.submit_action.controls.approve"
              [context]="context"
              [contextElement]="contextElement"
              [contextElementPaths]="approvalContextElementPaths"
              [firstInit]="firstInit"
            ></app-customize-bar-approve-edit>
          </app-sidebar-collapse>
        </app-sidebar-tab>
      </app-sidebar-tabs>
    </div>
  </ng-container>
</form>
