<app-font-menu-overlay
  [origin]="overlay_origin"
  [value]="control.value"
  [dark]="true"
  [globalFonts]="globalFonts"
  (fontSelect)="onFontSelect($event)"
  #overlay="appFontMenuOverlay"
></app-font-menu-overlay>

<div
  class="compact-select"
  [class.compact-select_focus]="overlay.isOpened()"
  cdkOverlayOrigin
  #overlay_origin="cdkOverlayOrigin"
  (click)="overlay.open()"
>
  <div class="compact-select__main">
    <div *ngIf="valueStr | appIsSet" class="compact-select__value">
      {{ valueStr }}
    </div>

    <div *ngIf="!(valueStr | appIsSet) && (placeholder | appIsSet)" class="compact-select__placeholder">
      {{ placeholder }}
    </div>
  </div>

  <div class="compact-select__right">
    <div class="compact-select__arrow icon-arrow_down_2"></div>
  </div>
</div>
