var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import clone from 'lodash/clone';
import flatMap from 'lodash/flatMap';
import flatten from 'lodash/flatten';
import flow from 'lodash/flow';
import fromPairs from 'lodash/fromPairs';
import map from 'lodash/map';
import toPairs from 'lodash/toPairs';
import { deserializeFieldParamName, lookups, serializeFieldParamName } from '@modules/field-lookups';
import { parseFilterPath } from '@modules/fields';
import { FilterItem, FilterItem2 } from '@modules/filters';
import { ORDER_BY_PARAM, PAGE_PARAM, SEARCH_PARAM, SEGMENT_PARAM } from '@modules/models';
export function addFilterItemToQueryParams(queryParams, item) {
    var _a;
    var params = __assign({}, queryParams, (_a = {}, _a[item.getName()] = item.value, _a));
    delete params[SEARCH_PARAM];
    delete params[PAGE_PARAM];
    return params;
}
export function removeFilterItemFromQueryParams(queryParams, item) {
    var params = __assign({}, queryParams);
    delete params[item.getName()];
    delete params[PAGE_PARAM];
    return params;
}
export function filterItemsToQueryParams(items) {
    if (!items) {
        return {};
    }
    return items.reduce(function (obj, item) {
        obj[item.getName()] = item.value;
        return obj;
    }, {});
}
export function filterItemsFromQueryParams(queryParams) {
    return toPairs(queryParams)
        .map(function (queryParam) {
        var result = new FilterItem();
        var value = queryParam[1];
        var _a = deserializeFieldParamName(queryParam[0]), name = _a.name, lookup = _a.lookup, exclude = _a.exclude;
        if ([PAGE_PARAM, ORDER_BY_PARAM].includes(name)) {
            return;
        }
        if (name == SEARCH_PARAM) {
            result.field = SEARCH_PARAM;
            result.value = value;
            result.exclude = exclude;
            return result;
        }
        result.field = name;
        result.value = value;
        result.lookup = lookups.find(function (item) { return item.lookup == lookup; });
        result.exclude = exclude;
        return result;
    })
        .filter(function (item) { return item; });
}
export function applyParamsComputedLookups(params) {
    return flow([
        toPairs,
        function (items) {
            return map(items, function (_a) {
                var key = _a[0], value = _a[1];
                var _b = deserializeFieldParamName(key), name = _b.name, lookupName = _b.lookup, exclude = _b.exclude;
                var lookup = lookups.find(function (item) { return item.lookup == lookupName; });
                if (!lookup || !lookup.compute) {
                    return {
                        key: key,
                        value: value
                    };
                }
                return lookup.compute(parseFilterPath(name), value, exclude).map(function (item) {
                    var itemFieldName = item.field.join('__');
                    var itemLookup = item.lookup ? item.lookup.lookup : undefined;
                    var itemName = serializeFieldParamName(itemFieldName, itemLookup, false);
                    return { key: itemName, value: item.value, exclude: item.exclude };
                });
            });
        },
        flatten,
        function (items) {
            return map(items, function (item) {
                if (item.exclude) {
                    return ["exclude__" + item.key, item.value];
                }
                else {
                    return [item.key, item.value];
                }
            });
        },
        fromPairs
    ])(params);
}
export function applyFiltersComputedLookups(filters) {
    return filters.reduce(function (acc, filterItem) {
        var lookup = lookups.find(function (item) { return item === filterItem.lookup; });
        if (lookup && lookup.compute) {
            var computedFilterItems = lookup.compute(filterItem.field, filterItem.value, filterItem.exclude).map(function (item) {
                return new FilterItem2({
                    field: item.field,
                    lookup: item.lookup,
                    value: item.value,
                    exclude: item.exclude
                });
            });
            acc.push.apply(acc, computedFilterItems);
        }
        else {
            acc.push(filterItem);
        }
        return acc;
    }, []);
}
export function applySegments(params, body, modelDescription) {
    var innerSegments = [];
    var segmentQueries = [];
    params = flow([
        toPairs,
        function (items) {
            return map(items, function (_a) {
                var key = _a[0], value = _a[1];
                if (key == SEGMENT_PARAM) {
                    var segment_1 = modelDescription.segments.find(function (i) { return i.label == value; });
                    if (!segment_1) {
                        return [];
                    }
                    var result_1 = [];
                    if (segment_1.sqlQuery) {
                        segmentQueries.push({
                            name: segment_1.label,
                            query: segment_1.sqlQuery
                        });
                        result_1.push({ key: key, value: value });
                    }
                    segment_1.filterItems.forEach(function (item) {
                        if (item.field == SEGMENT_PARAM) {
                            innerSegments.push(segment_1);
                        }
                        result_1.push({
                            key: item.field,
                            value: item.value
                        });
                    });
                    return result_1;
                }
                else {
                    return { key: key, value: value };
                }
            });
        },
        flatMap,
        function (items) { return map(items, function (item) { return [item.key, item.value]; }); },
        fromPairs
    ])(params);
    if (segmentQueries.length) {
        body = clone(body);
        if (body['segments'] == undefined) {
            body['segments'] = [];
        }
        body['segments'] = body['segments'].concat(segmentQueries);
    }
    if (innerSegments.length) {
        var inner = applySegments(params, body, modelDescription);
        params = inner.params;
        body = inner.body;
    }
    return {
        params: params,
        body: body
    };
}
