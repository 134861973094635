var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { NgZone } from '@angular/core';
import { FormControl } from '@angular/forms';
import { combineLatest } from 'rxjs';
import { debounceTime, delay, map } from 'rxjs/operators';
import { NotificationService } from '@common/notifications';
import { ActionService } from '@modules/action-queries';
import { AggregateFunc, DatasetGroupLookup } from '@modules/charts';
import { ValueWidgetDataSource } from '@modules/data-sources';
import { ParameterField } from '@modules/fields';
import { ModelDescriptionStore, ModelService } from '@modules/model-queries';
import { InputFieldProviderItem, inputFieldProviderItemsFromModelGet, parametersToProviderItems } from '@modules/parameters';
import { ProjectSettingsService } from '@modules/project-settings';
import { CurrentEnvironmentStore } from '@modules/projects';
import { QueryService } from '@modules/queries';
import { ResourceGeneratorResolver } from '@modules/resource-generators';
import { ResourceControllerService } from '@modules/resources';
import { controlValue, isSet } from '@shared';
import { ModelDescriptionDataSourceControl } from './model-description-data-source';
export var validateQueryYFunc = function (queryColumnControl) {
    return function (control) {
        if (!control.parent) {
            return;
        }
        var column = control.parent.controls[queryColumnControl].value;
        if (isSet(control.value) && control.value != AggregateFunc.Count && !isSet(column)) {
            return { required: true };
        }
        else if (!isSet(control.value) && !isSet(column)) {
            return { required: true };
        }
    };
};
var ValueWidgetDataSourceControl = /** @class */ (function (_super) {
    __extends(ValueWidgetDataSourceControl, _super);
    function ValueWidgetDataSourceControl(modelDescriptionStore, currentEnvironmentStore, projectSettingsService, resourceControllerService, resourceGeneratorResolver, modelService, actionService, queryService, notificationService, zone) {
        var _this = _super.call(this, modelDescriptionStore, currentEnvironmentStore, projectSettingsService, resourceControllerService, resourceGeneratorResolver, modelService, actionService, queryService, notificationService, zone, {
            func: new FormControl('', validateQueryYFunc('column')),
            column: new FormControl('')
        }) || this;
        _this.resourceFieldParams = { charts_resources: true };
        _this.groupCommonOptions = [
            { value: DatasetGroupLookup.Auto, name: 'Auto', valueDisplay: function (field) { return field + " - Auto"; } },
            { value: DatasetGroupLookup.Plain, name: 'Plain value', valueDisplay: function (field) { return field + " - Plain"; } }
        ];
        _this.groupDateOptions = [
            { value: DatasetGroupLookup.DateDay, name: 'By Day', valueDisplay: function (field) { return field + " - Day"; } },
            { value: DatasetGroupLookup.DateWeek, name: 'By Week', valueDisplay: function (field) { return field + " - Week"; } },
            { value: DatasetGroupLookup.DateMonth, name: 'By Month', valueDisplay: function (field) { return field + " - Month"; } },
            { value: DatasetGroupLookup.DateQuarter, name: 'By Quarter', valueDisplay: function (field) { return field + " - Quarter"; } },
            { value: DatasetGroupLookup.DateYear, name: 'By Year', valueDisplay: function (field) { return field + " - Year"; } }
        ];
        _this.groupTimeOptions = [
            { value: DatasetGroupLookup.DateHour, name: 'By Hour', valueDisplay: function (field) { return field + " - Hour"; } },
            { value: DatasetGroupLookup.DateMinute, name: 'By Minute', valueDisplay: function (field) { return field + " - Minute"; } },
            { value: DatasetGroupLookup.DateSecond, name: 'By Second', valueDisplay: function (field) { return field + " - Second"; } }
        ];
        _this.xColumnLookupAllOptions = _this.groupCommonOptions.concat(_this.groupDateOptions, _this.groupTimeOptions);
        _this.xColumnLookupDefaultOptions = _this.groupCommonOptions.slice();
        _this.xColumnLookupDateOptions = _this.groupCommonOptions.concat(_this.groupDateOptions, _this.groupTimeOptions);
        _this.xColumnLookupTimeOptions = _this.groupCommonOptions.concat(_this.groupTimeOptions);
        _this.yColumnFuncOptions = [
            { value: AggregateFunc.Count, name: 'Number of records', valueDisplay: function () { return "Number of records"; } },
            { value: AggregateFunc.Sum, name: 'Sum of field', hasField: true, valueDisplay: function (field) { return "Sum of " + field; } },
            {
                value: AggregateFunc.Min,
                name: 'Minimum of field',
                hasField: true,
                valueDisplay: function (field) { return "Minimum of " + field; }
            },
            {
                value: AggregateFunc.Max,
                name: 'Maximum of field',
                hasField: true,
                valueDisplay: function (field) { return "Maximum of " + field; }
            },
            { value: AggregateFunc.Avg, name: 'Average of field', hasField: true, valueDisplay: function (field) { return "Average of " + field; } }
        ];
        _this.controls.column.valueChanges.pipe(delay(0)).subscribe(function () {
            _this.controls.func.updateValueAndValidity();
        });
        _this.inputFieldProvider.getFields$().subscribe(function () {
            _this.controls.query_inputs.updateValueAndValidity();
        });
        return _this;
    }
    ValueWidgetDataSourceControl.prototype.clearExtraControls = function () {
        this.controls.func.patchValue('');
        this.controls.column.patchValue('');
    };
    ValueWidgetDataSourceControl.prototype.deserializeExtraControls = function (instance) {
        if (instance) {
            this.controls.func.patchValue(instance.func);
            this.controls.column.patchValue(instance.column);
        }
        else {
            this.clearExtraControls();
        }
    };
    ValueWidgetDataSourceControl.prototype.onModelDescriptionChange = function (modelDescription) {
        _super.prototype.onModelDescriptionChange.call(this, modelDescription);
        this.clearExtraControls();
    };
    ValueWidgetDataSourceControl.prototype.serialize = function () {
        var result = _super.prototype.serialize.call(this);
        if (!result) {
            return;
        }
        result.func = this.controls.func.value;
        result.column = this.controls.column.value;
        return result;
    };
    ValueWidgetDataSourceControl.prototype.getInputFieldProvider$ = function () {
        return combineLatest(controlValue(this.controls.type), this.getResource$(), this.getModelDescription$(), controlValue(this.controls.query_parameters), controlValue(this.controls.query), controlValue(this.controls.columns)).pipe(debounceTime(60), map(function (_a) {
            var type = _a[0], resource = _a[1], modelDescription = _a[2], parameters = _a[3], getQuery = _a[4], columns = _a[5];
            return parametersToProviderItems(parameters).concat(inputFieldProviderItemsFromModelGet(resource, modelDescription, getQuery, columns, type));
        }));
    };
    ValueWidgetDataSourceControl.prototype.isListQuery = function () {
        return true;
    };
    ValueWidgetDataSourceControl.instanceCls = ValueWidgetDataSource;
    return ValueWidgetDataSourceControl;
}(ModelDescriptionDataSourceControl));
export { ValueWidgetDataSourceControl };
