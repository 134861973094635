import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';

import {
  ElementItem,
  getListLayoutSettingsClass,
  ListLayoutSettings,
  ViewContext,
  ViewContextElement
} from '@modules/customize';
import { DataSourceType, ListModelDescriptionDataSource } from '@modules/data-sources';
import { Input as FieldInput } from '@modules/fields';
import { listLayouts, ListLayoutType } from '@modules/layouts';

import { CustomizeBarEditComponent } from '../../data/customize-bar-edit-component';
import { CustomizeBarEditEvent } from '../../data/customize-bar-edit-event';
import { CustomizeBarEditEventType } from '../../data/customize-bar-edit-event-type';
import { CustomizeBarContext } from '../../services/customize-bar-context/customize-bar.context';

@Component({
  selector: 'app-customize-bar-list-edit',
  templateUrl: './customize-bar-list-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeBarListEditComponent implements OnInit, CustomizeBarEditComponent {
  @Input() title: string;
  @Input() titleEnabled = true;
  @Input() titleEditable = false;
  @Input() settings: ListLayoutSettings;
  @Input() visibleInput: FieldInput;
  @Input() visibleEditable: boolean;
  @Input() elementStyles: any;
  @Input() elementStylesEditable = false;
  @Input() element: ElementItem;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() contextElementPath: (string | number)[];
  @Input() contextElementPaths: (string | number)[][];
  @Input() submitEnabled = true;
  @Input() backEnabled: boolean;
  @Input() deleteEnabled = false;
  @Input() parentElement: any;
  @Input() parentPopup: any;
  @Input() firstInit = false;
  @Input() setupOnCreate = false;
  @Output() event = new EventEmitter<CustomizeBarEditEvent>();

  layouts = ListLayoutType;
  listLayouts = listLayouts;
  temporarySettings: { [k: string]: ListLayoutSettings } = {};

  constructor(private cd: ChangeDetectorRef, protected customizeBarContext: CustomizeBarContext) {}

  ngOnInit() {}

  changeLayout(layout: ListLayoutType) {
    const cls = getListLayoutSettingsClass(layout);
    let settings: ListLayoutSettings;

    if (this.temporarySettings[layout]) {
      settings = this.temporarySettings[layout];
    } else {
      settings = new cls();

      settings.dataSource = new ListModelDescriptionDataSource();

      if (this.settings.dataSource) {
        settings.dataSource.type = this.settings.dataSource.type;
        settings.dataSource.queryResource = this.settings.dataSource.queryResource;
        settings.dataSource.query = this.settings.dataSource.query;
        settings.dataSource.queryParameters = this.settings.dataSource.queryParameters;
        settings.dataSource.columns = this.settings.dataSource.columns;
        settings.dataSource.queryInputs = this.settings.dataSource.queryInputs;
      }

      settings.actions = this.settings.actions;
    }

    this.settings = settings;
    this.cd.markForCheck();

    this.event.emit({
      type: CustomizeBarEditEventType.Updated,
      args: {
        result: settings
      }
    });
  }

  onSaveEvent(layout: ListLayoutType, e: CustomizeBarEditEvent) {
    if (e.type == CustomizeBarEditEventType.Created || e.type == CustomizeBarEditEventType.Updated) {
      const instance = e.args['result'] as ListLayoutSettings;
      const visibleInput = e.args['visible_input'] as FieldInput;
      const title = e.args['name'] as string;
      const elementStyles = e.args['element_styles'];
      this.temporarySettings[instance.type] = cloneDeep(instance);
      this.visibleInput = visibleInput;
      this.title = title;
      this.elementStyles = elementStyles;
      this.emitEvent(e.type);
    } else if (e.type == CustomizeBarEditEventType.Deleted) {
      this.event.emit(e);
    } else if (e.type == CustomizeBarEditEventType.Canceled) {
      this.temporarySettings[this.settings.type] = cloneDeep(this.settings);
      this.event.emit(e);
    }
  }

  emitEvent(type: CustomizeBarEditEventType) {
    this.event.emit({
      type: type,
      args: {
        result: this.temporarySettings[this.settings.type] || this.settings,
        visible_input: this.visibleInput,
        element_styles: this.elementStyles,
        title: this.title
      }
    });
  }

  delete() {
    this.event.emit({ type: CustomizeBarEditEventType.Deleted });
    this.customizeBarContext.popSettingsComponent();
  }
}
