import { Injectable, Injector } from '@angular/core';

import { Input } from '@modules/fields';
import { SeparatorMenuItem } from '@modules/menu';
import { FieldInputControl } from '@modules/parameters';

import { CustomizeBarPagesEditMenuItemControl, menuItemControls } from './customize-bar-pages-edit.form';

@Injectable()
export class CustomizeBarPagesEditSeparatorControl extends CustomizeBarPagesEditMenuItemControl {
  controls: {
    visible_input: FieldInputControl;
  };

  constructor(private injector: Injector) {
    super({
      visible_input: new FieldInputControl({ path: ['value'] })
    });
  }

  static inject(injector: Injector): CustomizeBarPagesEditSeparatorControl {
    return Injector.create({
      providers: [
        {
          provide: CustomizeBarPagesEditSeparatorControl,
          useFactory: () => {
            return new CustomizeBarPagesEditSeparatorControl(injector);
          },
          deps: []
        }
      ],
      parent: injector
    }).get(CustomizeBarPagesEditSeparatorControl);
  }

  deserialize(item: SeparatorMenuItem) {
    this.menuItem = item;

    this.controls.visible_input.patchValue(item.visibleInput ? item.visibleInput.serializeWithoutPath() : {});

    this.markAsPristine();
  }

  serialize(): SeparatorMenuItem {
    const instance = new SeparatorMenuItem().deserialize(this.menuItem.serialize());

    instance.visibleInput = this.controls.visible_input.value
      ? new Input().deserialize(this.controls.visible_input.value)
      : undefined;

    return instance;
  }
}

menuItemControls.push({ menuItem: SeparatorMenuItem, control: CustomizeBarPagesEditSeparatorControl });
