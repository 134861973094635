var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ActionElementStyles, Border, BorderSettings, ElementWrapperStyles, FieldElementStyles, Fill, FillSettings, Gradient, GradientStop, IconSettings, Point, Shadow, TextStyle } from '@modules/customize';
export function deserializeTextStyle(instance) {
    if (!instance) {
        return;
    }
    return new TextStyle({
        fontFamily: instance.fontFamily,
        fontStyle: instance.fontStyle,
        fontSize: instance.fontSize,
        color: instance.color,
        colorDark: instance.colorDark,
        backgroundColor: instance.backgroundColor,
        backgroundColorDark: instance.backgroundColorDark,
        letterSpacing: instance.letterSpacing,
        transform: instance.transform,
        decoration: instance.decoration
    });
}
export function deserializeIconSettings(instance) {
    if (!instance) {
        return;
    }
    return new IconSettings({
        color: instance.color,
        colorDark: instance.colorDark,
        size: instance.size
    });
}
export function deserializeFill(instance) {
    if (!instance) {
        return;
    }
    return new Fill(__assign({ type: instance.type, color: instance.color }, (instance.gradient && {
        gradient: new Gradient({
            type: instance.gradient.type,
            from: new Point({
                x: instance.gradient.from.x,
                y: instance.gradient.from.y
            }),
            to: new Point({
                x: instance.gradient.to.x,
                y: instance.gradient.to.y
            }),
            stops: instance.gradient.stops.map(function (item) {
                var result = new GradientStop({
                    id: item.id,
                    position: item.position,
                    color: item.color
                });
                if (!result.id) {
                    result.generateId();
                }
                return result;
            }),
            aspectRatio: instance.gradient.aspectRatio
        })
    })));
}
export function deserializeFillSettings(instance) {
    if (!instance) {
        return;
    }
    return new FillSettings({
        fill: deserializeFill(instance.fill),
        fillDark: deserializeFill(instance.fillDark)
    });
}
export function deserializeBorder(instance) {
    if (!instance) {
        return;
    }
    return new Border({
        color: instance.color,
        colorDark: instance.colorDark,
        thickness: instance.thickness,
        style: instance.style
    });
}
export function deserializeBorderSettings(instance) {
    if (!instance) {
        return;
    }
    var result = new BorderSettings();
    if (instance.border) {
        result.border = deserializeBorder(instance.border);
    }
    if (instance.borderTop) {
        result.borderTop = deserializeBorder(instance.borderTop);
    }
    if (instance.borderRight) {
        result.borderRight = deserializeBorder(instance.borderRight);
    }
    if (instance.borderBottom) {
        result.borderBottom = deserializeBorder(instance.borderBottom);
    }
    if (instance.borderLeft) {
        result.borderLeft = deserializeBorder(instance.borderLeft);
    }
    return result;
}
export function deserializeShadow(instance) {
    if (!instance) {
        return;
    }
    return new Shadow({
        color: instance.color,
        colorDark: instance.colorDark,
        offsetX: instance.offsetX,
        offsetY: instance.offsetY,
        blurRadius: instance.blurRadius,
        spreadRadius: instance.spreadRadius
    });
}
export function deserializeActionElementStyles(instance) {
    if (!instance) {
        return;
    }
    var styles = new ActionElementStyles();
    styles.textStyle = deserializeTextStyle(instance.textStyle);
    styles.iconSettings = deserializeIconSettings(instance.iconSettings);
    styles.fillSettings = deserializeFillSettings(instance.fillSettings);
    styles.borderSettings = deserializeBorderSettings(instance.borderSettings);
    styles.borderRadius = instance.borderRadius;
    styles.shadow = deserializeShadow(instance.shadow);
    styles.padding = instance.padding;
    styles.margin = instance.margin;
    styles.hoverTextStyle = deserializeTextStyle(instance.hoverTextStyle);
    styles.hoverIconSettings = deserializeIconSettings(instance.hoverIconSettings);
    styles.hoverFillSettings = deserializeFillSettings(instance.hoverFillSettings);
    styles.hoverBorderSettings = deserializeBorderSettings(instance.hoverBorderSettings);
    styles.hoverShadow = deserializeShadow(instance.hoverShadow);
    styles.activeTextStyle = deserializeTextStyle(instance.activeTextStyle);
    styles.activeIconSettings = deserializeIconSettings(instance.activeIconSettings);
    styles.activeFillSettings = deserializeFillSettings(instance.activeFillSettings);
    styles.activeBorderSettings = deserializeBorderSettings(instance.activeBorderSettings);
    styles.activeShadow = deserializeShadow(instance.activeShadow);
    return styles;
}
export function deserializeFieldElementStyles(instance) {
    if (!instance) {
        return;
    }
    var styles = new FieldElementStyles();
    styles.textStyle = deserializeTextStyle(instance.textStyle);
    styles.placeholderStyle = deserializeTextStyle(instance.placeholderStyle);
    styles.labelStyle = deserializeTextStyle(instance.labelStyle);
    styles.labelAdditionalStyle = deserializeTextStyle(instance.labelAdditionalStyle);
    styles.fillSettings = deserializeFillSettings(instance.fillSettings);
    styles.borderSettings = deserializeBorderSettings(instance.borderSettings);
    styles.borderRadius = instance.borderRadius;
    styles.shadow = deserializeShadow(instance.shadow);
    styles.padding = instance.padding;
    styles.margin = instance.margin;
    styles.hoverTextStyle = deserializeTextStyle(instance.hoverTextStyle);
    styles.hoverPlaceholderStyle = deserializeTextStyle(instance.hoverPlaceholderStyle);
    styles.hoverFillSettings = deserializeFillSettings(instance.hoverFillSettings);
    styles.hoverBorderSettings = deserializeBorderSettings(instance.hoverBorderSettings);
    styles.hoverShadow = deserializeShadow(instance.hoverShadow);
    styles.focusTextStyle = deserializeTextStyle(instance.focusTextStyle);
    styles.focusPlaceholderStyle = deserializeTextStyle(instance.focusPlaceholderStyle);
    styles.focusFillSettings = deserializeFillSettings(instance.focusFillSettings);
    styles.focusBorderSettings = deserializeBorderSettings(instance.focusBorderSettings);
    styles.focusShadow = deserializeShadow(instance.focusShadow);
    styles.errorTextStyle = deserializeTextStyle(instance.errorTextStyle);
    styles.errorPlaceholderStyle = deserializeTextStyle(instance.errorPlaceholderStyle);
    styles.errorFillSettings = deserializeFillSettings(instance.errorFillSettings);
    styles.errorBorderSettings = deserializeBorderSettings(instance.errorBorderSettings);
    styles.errorShadow = deserializeShadow(instance.errorShadow);
    return styles;
}
export function deserializeElementWrapperStyles(instance) {
    if (!instance) {
        return;
    }
    var styles = new ElementWrapperStyles();
    styles.borderSettings = deserializeBorderSettings(instance.borderSettings);
    styles.borderRadius = instance.borderRadius;
    styles.shadow = deserializeShadow(instance.shadow);
    styles.margin = instance.margin;
    return styles;
}
