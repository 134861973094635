var _a, _b, _c, _d, _e, _f;
import * as Color from 'color';
import toPairs from 'lodash/toPairs';
import { getColorHex, parseColor } from '@modules/colors';
import { BorderRadius, fonts, getFontFamily } from '@modules/theme';
import { isSet } from '@shared';
import { setBorderRadiusVars, setBorderVars, setMarginVars, setShadowVars } from './styles';
export var ThemeVar;
(function (ThemeVar) {
    ThemeVar["AccentColor"] = "accent-color";
    ThemeVar["AccentColorContrast"] = "accent-color-contrast";
    ThemeVar["AccentColor2"] = "accent-color-2";
    ThemeVar["AccentColor3"] = "accent-color-3";
    ThemeVar["AccentColorDark"] = "accent-color-dark";
    ThemeVar["AccentColorDarkContrast"] = "accent-color-dark-contrast";
    ThemeVar["AccentColor2Dark"] = "accent-color-2-dark";
    ThemeVar["AccentColor3Dark"] = "accent-color-3-dark";
    ThemeVar["BackgroundColor"] = "background-color";
    ThemeVar["BackgroundColorDark"] = "background-color-dark";
    ThemeVar["BackgroundColor2"] = "background-color-2";
    ThemeVar["BackgroundColor2AddDark"] = "background-color-2-add-dark";
    ThemeVar["BackgroundColor2Dark"] = "background-color-2-dark";
    ThemeVar["BackgroundColor2DarkAddDark"] = "background-color-2-dark-add-dark";
    ThemeVar["BackgroundColor3"] = "background-color-3";
    ThemeVar["BackgroundColor3Contrast"] = "background-color-3-contrast";
    ThemeVar["BackgroundColor3Dark"] = "background-color-3-dark";
    ThemeVar["BackgroundColor3DarkContrast"] = "background-color-3-dark-contrast";
    ThemeVar["BackgroundColor4"] = "background-color-4";
    ThemeVar["BackgroundColor4AddDark"] = "background-color-4-add-dark";
    ThemeVar["BackgroundColor4AddAlpha"] = "background-color-4-add-alpha";
    ThemeVar["BackgroundColor4Dark"] = "background-color-4-dark";
    ThemeVar["BackgroundColor4DarkAddDark"] = "background-color-4-dark-add-dark";
    ThemeVar["BackgroundColor4DarkAddAlpha"] = "background-color-4-dark-add-alpha";
    ThemeVar["BackgroundColor5"] = "background-color-5";
    ThemeVar["BackgroundColor5Dark"] = "background-color-5-dark";
    ThemeVar["TextColor"] = "text-color";
    ThemeVar["TextColorDark"] = "text-color-dark";
    ThemeVar["TextColor2"] = "text-color-2";
    ThemeVar["TextColor2Dark"] = "text-color-2-dark";
    ThemeVar["TextColor3"] = "text-color-3";
    ThemeVar["TextColor3Dark"] = "text-color-3-dark";
    ThemeVar["BorderColor"] = "border-color";
    ThemeVar["BorderColorDark"] = "border-color-dark";
    ThemeVar["BorderColor2"] = "border-color-2";
    ThemeVar["BorderColor2Dark"] = "border-color-2-dark";
    ThemeVar["BorderColor3"] = "border-color-3";
    ThemeVar["BorderColor3Dark"] = "border-color-3-dark";
    ThemeVar["BorderRadiusXXXS"] = "border-radius-xxxs";
    ThemeVar["BorderRadiusXXS"] = "border-radius-xxs";
    ThemeVar["BorderRadiusS"] = "border-radius-s";
    ThemeVar["BorderRadiusM"] = "border-radius-m";
    ThemeVar["BorderRadiusL"] = "border-radius-l";
    ThemeVar["BorderRadiusXL"] = "border-radius-xl";
    ThemeVar["BorderRadiusXXL"] = "border-radius-xxl";
    ThemeVar["FontRegular"] = "regular-font";
    ThemeVar["FontHeading"] = "heading-font";
})(ThemeVar || (ThemeVar = {}));
export var themeVars = [
    ThemeVar.AccentColor,
    ThemeVar.AccentColorContrast,
    ThemeVar.AccentColor2,
    ThemeVar.AccentColor3,
    ThemeVar.AccentColorDark,
    ThemeVar.AccentColorDarkContrast,
    ThemeVar.AccentColor2Dark,
    ThemeVar.AccentColor3Dark,
    ThemeVar.BackgroundColor,
    ThemeVar.BackgroundColorDark,
    ThemeVar.BackgroundColor2,
    ThemeVar.BackgroundColor2AddDark,
    ThemeVar.BackgroundColor2Dark,
    ThemeVar.BackgroundColor2DarkAddDark,
    ThemeVar.BackgroundColor3,
    ThemeVar.BackgroundColor3Contrast,
    ThemeVar.BackgroundColor3Dark,
    ThemeVar.BackgroundColor3DarkContrast,
    ThemeVar.BackgroundColor4,
    ThemeVar.BackgroundColor4AddDark,
    ThemeVar.BackgroundColor4AddAlpha,
    ThemeVar.BackgroundColor4Dark,
    ThemeVar.BackgroundColor4DarkAddDark,
    ThemeVar.BackgroundColor4DarkAddAlpha,
    ThemeVar.BackgroundColor5,
    ThemeVar.BackgroundColor5Dark,
    ThemeVar.TextColor,
    ThemeVar.TextColorDark,
    ThemeVar.TextColor2,
    ThemeVar.TextColor2Dark,
    ThemeVar.TextColor3,
    ThemeVar.TextColor3Dark,
    ThemeVar.BorderColor,
    ThemeVar.BorderColorDark,
    ThemeVar.BorderColor2,
    ThemeVar.BorderColor2Dark,
    ThemeVar.BorderColor3,
    ThemeVar.BorderColor3Dark,
    ThemeVar.BorderRadiusXXXS,
    ThemeVar.BorderRadiusXXS,
    ThemeVar.BorderRadiusS,
    ThemeVar.BorderRadiusM,
    ThemeVar.BorderRadiusL,
    ThemeVar.BorderRadiusXL,
    ThemeVar.BorderRadiusXXL,
    ThemeVar.FontRegular,
    ThemeVar.FontHeading
];
export var themeVarBorderRadiusValues = (_a = {},
    _a[BorderRadius.None] = (_b = {},
        _b[ThemeVar.BorderRadiusXXXS] = 0,
        _b[ThemeVar.BorderRadiusXXS] = 0,
        _b[ThemeVar.BorderRadiusS] = 0,
        _b[ThemeVar.BorderRadiusM] = 0,
        _b[ThemeVar.BorderRadiusL] = 0,
        _b[ThemeVar.BorderRadiusXL] = 0,
        _b[ThemeVar.BorderRadiusXXL] = 0,
        _b),
    _a[BorderRadius.S] = (_c = {},
        _c[ThemeVar.BorderRadiusXXXS] = 1,
        _c[ThemeVar.BorderRadiusXXS] = 1,
        _c[ThemeVar.BorderRadiusS] = 2,
        _c[ThemeVar.BorderRadiusM] = 3,
        _c[ThemeVar.BorderRadiusL] = 4,
        _c[ThemeVar.BorderRadiusXL] = 5,
        _c[ThemeVar.BorderRadiusXXL] = 6,
        _c),
    _a[BorderRadius.M] = (_d = {},
        _d[ThemeVar.BorderRadiusXXXS] = 1,
        _d[ThemeVar.BorderRadiusXXS] = 2,
        _d[ThemeVar.BorderRadiusS] = 4,
        _d[ThemeVar.BorderRadiusM] = 6,
        _d[ThemeVar.BorderRadiusL] = 8,
        _d[ThemeVar.BorderRadiusXL] = 10,
        _d[ThemeVar.BorderRadiusXXL] = 12,
        _d),
    _a[BorderRadius.L] = (_e = {},
        _e[ThemeVar.BorderRadiusXXXS] = 1,
        _e[ThemeVar.BorderRadiusXXS] = 4,
        _e[ThemeVar.BorderRadiusS] = 6,
        _e[ThemeVar.BorderRadiusM] = 10,
        _e[ThemeVar.BorderRadiusL] = 12,
        _e[ThemeVar.BorderRadiusXL] = 14,
        _e[ThemeVar.BorderRadiusXXL] = 16,
        _e),
    _a[BorderRadius.XL] = (_f = {},
        _f[ThemeVar.BorderRadiusXXXS] = 3,
        _f[ThemeVar.BorderRadiusXXS] = 6,
        _f[ThemeVar.BorderRadiusS] = 12,
        _f[ThemeVar.BorderRadiusM] = 18,
        _f[ThemeVar.BorderRadiusL] = 20,
        _f[ThemeVar.BorderRadiusXL] = 22,
        _f[ThemeVar.BorderRadiusXXL] = 24,
        _f),
    _a);
export function getThemeVars(options) {
    if (options === void 0) { options = {}; }
    var vars = {};
    var fontRegular = fonts.find(function (item) { return item.name == options.fontRegular; });
    var fontHeading = fonts.find(function (item) { return item.name == options.fontHeading; });
    if (isSet(options.accentColor)) {
        var hex = getColorHex(options.accentColor);
        var clr = isSet(hex) ? parseColor(hex) : undefined;
        if (clr) {
            var isDark = clr.contrast(Color('white')) >= 2;
            vars[ThemeVar.AccentColor] = clr.string();
            vars[ThemeVar.AccentColor2] = clr.lighten(0.15).string();
            vars[ThemeVar.AccentColor3] = clr.lighten(0.2).string();
            vars[ThemeVar.AccentColorContrast] = isDark ? '#fff' : clr.darken(0.6).string();
        }
    }
    if (isSet(options.accentColorDark)) {
        var hex = getColorHex(options.accentColorDark);
        var clr = isSet(hex) ? parseColor(hex) : undefined;
        if (clr) {
            var isDark = clr.contrast(Color('white')) >= 2;
            vars[ThemeVar.AccentColorDark] = clr.string();
            vars[ThemeVar.AccentColor2Dark] = clr.lighten(0.15).string();
            vars[ThemeVar.AccentColor3Dark] = clr.lighten(0.2).string();
            vars[ThemeVar.AccentColorDarkContrast] = isDark ? '#fff' : clr.darken(0.6).string();
        }
    }
    if (isSet(options.backgroundColor)) {
        var hex = getColorHex(options.backgroundColor);
        var clr = isSet(hex) ? parseColor(hex) : undefined;
        if (clr) {
            vars[ThemeVar.BackgroundColor] = clr.string();
        }
    }
    if (isSet(options.backgroundColorDark)) {
        var hex = getColorHex(options.backgroundColorDark);
        var clr = isSet(hex) ? parseColor(hex) : undefined;
        if (clr) {
            vars[ThemeVar.BackgroundColorDark] = clr.string();
        }
    }
    if (isSet(options.backgroundColor2)) {
        var hex = getColorHex(options.backgroundColor2);
        var clr = isSet(hex) ? parseColor(hex) : undefined;
        if (clr) {
            vars[ThemeVar.BackgroundColor2] = clr.string();
            vars[ThemeVar.BackgroundColor2AddDark] = clr.darken(0.05).string();
        }
    }
    if (isSet(options.backgroundColor2Dark)) {
        var hex = getColorHex(options.backgroundColor2Dark);
        var clr = isSet(hex) ? parseColor(hex) : undefined;
        if (clr) {
            vars[ThemeVar.BackgroundColor2Dark] = clr.string();
            vars[ThemeVar.BackgroundColor2DarkAddDark] = clr.darken(0.05).string();
        }
    }
    if (isSet(options.backgroundColor3)) {
        var hex = getColorHex(options.backgroundColor3);
        var clr = isSet(hex) ? parseColor(hex) : undefined;
        if (clr) {
            vars[ThemeVar.BackgroundColor3] = clr.string();
        }
    }
    if (isSet(options.backgroundColor3) && isSet(options.backgroundColor2) && isSet(options.backgroundColor4)) {
        var hex3 = getColorHex(options.backgroundColor3);
        var hex2 = getColorHex(options.backgroundColor2);
        var hex4 = getColorHex(options.backgroundColor4);
        var clr3 = isSet(hex3) ? parseColor(hex3) : undefined;
        var clr2 = isSet(hex2) ? parseColor(hex2) : undefined;
        var clr4 = isSet(hex4) ? parseColor(hex4) : undefined;
        if (clr3 && clr2 && clr4) {
            if (clr3.contrast(clr4) > clr3.contrast(clr2)) {
                vars[ThemeVar.BackgroundColor3Contrast] = clr4.string();
            }
            else {
                vars[ThemeVar.BackgroundColor3Contrast] = clr2.string();
            }
        }
    }
    if (isSet(options.backgroundColor3Dark)) {
        var hex = getColorHex(options.backgroundColor3Dark);
        var clr = isSet(hex) ? parseColor(hex) : undefined;
        if (clr) {
            vars[ThemeVar.BackgroundColor3Dark] = clr.string();
        }
    }
    if (isSet(options.backgroundColor3Dark) &&
        isSet(options.backgroundColor2Dark) &&
        isSet(options.backgroundColor4Dark)) {
        var hex3 = getColorHex(options.backgroundColor3Dark);
        var hex2 = getColorHex(options.backgroundColor2Dark);
        var hex4 = getColorHex(options.backgroundColor4Dark);
        var clr3 = isSet(hex3) ? parseColor(hex3) : undefined;
        var clr2 = isSet(hex2) ? parseColor(hex2) : undefined;
        var clr4 = isSet(hex4) ? parseColor(hex4) : undefined;
        if (clr3 && clr2 && clr4) {
            if (clr3.contrast(clr4) > clr3.contrast(clr2)) {
                vars[ThemeVar.BackgroundColor3DarkContrast] = clr4.string();
            }
            else {
                vars[ThemeVar.BackgroundColor3DarkContrast] = clr2.string();
            }
        }
    }
    if (isSet(options.backgroundColor4)) {
        var hex = getColorHex(options.backgroundColor4);
        var clr = isSet(hex) ? parseColor(hex) : undefined;
        if (clr) {
            vars[ThemeVar.BackgroundColor4] = clr.string();
            vars[ThemeVar.BackgroundColor4AddDark] = clr.darken(0.05).string();
            vars[ThemeVar.BackgroundColor4AddAlpha] = clr.fade(0.5).string();
        }
    }
    if (isSet(options.backgroundColor4Dark)) {
        var hex = getColorHex(options.backgroundColor4Dark);
        var clr = isSet(hex) ? parseColor(hex) : undefined;
        if (clr) {
            vars[ThemeVar.BackgroundColor4Dark] = clr.string();
            vars[ThemeVar.BackgroundColor4DarkAddDark] = clr.darken(0.05).string();
            vars[ThemeVar.BackgroundColor4DarkAddAlpha] = clr.fade(0.5).string();
        }
    }
    if (isSet(options.backgroundColor5)) {
        var hex = getColorHex(options.backgroundColor5);
        var clr = isSet(hex) ? parseColor(hex) : undefined;
        if (clr) {
            vars[ThemeVar.BackgroundColor5] = clr.string();
        }
    }
    if (isSet(options.backgroundColor5Dark)) {
        var hex = getColorHex(options.backgroundColor5Dark);
        var clr = isSet(hex) ? parseColor(hex) : undefined;
        if (clr) {
            vars[ThemeVar.BackgroundColor5Dark] = clr.string();
        }
    }
    if (isSet(options.textColor)) {
        var hex = getColorHex(options.textColor);
        var clr = isSet(hex) ? parseColor(hex) : undefined;
        if (clr) {
            vars[ThemeVar.TextColor] = clr.string();
        }
    }
    if (isSet(options.textColorDark)) {
        var hex = getColorHex(options.textColorDark);
        var clr = isSet(hex) ? parseColor(hex) : undefined;
        if (clr) {
            vars[ThemeVar.TextColorDark] = clr.string();
        }
    }
    if (isSet(options.textColor2)) {
        var hex = getColorHex(options.textColor2);
        var clr = isSet(hex) ? parseColor(hex) : undefined;
        if (clr) {
            vars[ThemeVar.TextColor2] = clr.string();
        }
    }
    if (isSet(options.textColor2Dark)) {
        var hex = getColorHex(options.textColor2Dark);
        var clr = isSet(hex) ? parseColor(hex) : undefined;
        if (clr) {
            vars[ThemeVar.TextColor2Dark] = clr.string();
        }
    }
    if (isSet(options.textColor3)) {
        var hex = getColorHex(options.textColor3);
        var clr = isSet(hex) ? parseColor(hex) : undefined;
        if (clr) {
            vars[ThemeVar.TextColor3] = clr.string();
        }
    }
    if (isSet(options.textColor3Dark)) {
        var hex = getColorHex(options.textColor3Dark);
        var clr = isSet(hex) ? parseColor(hex) : undefined;
        if (clr) {
            vars[ThemeVar.TextColor3Dark] = clr.string();
        }
    }
    if (isSet(options.borderColor)) {
        var hex = getColorHex(options.borderColor);
        var clr = isSet(hex) ? parseColor(hex) : undefined;
        if (clr) {
            vars[ThemeVar.BorderColor] = clr.string();
        }
    }
    if (isSet(options.borderColorDark)) {
        var hex = getColorHex(options.borderColorDark);
        var clr = isSet(hex) ? parseColor(hex) : undefined;
        if (clr) {
            vars[ThemeVar.BorderColorDark] = clr.string();
        }
    }
    if (isSet(options.borderColor2)) {
        var hex = getColorHex(options.borderColor2);
        var clr = isSet(hex) ? parseColor(hex) : undefined;
        if (clr) {
            vars[ThemeVar.BorderColor2] = clr.string();
        }
    }
    if (isSet(options.borderColor2Dark)) {
        var hex = getColorHex(options.borderColor2Dark);
        var clr = isSet(hex) ? parseColor(hex) : undefined;
        if (clr) {
            vars[ThemeVar.BorderColor2Dark] = clr.string();
        }
    }
    if (isSet(options.borderColor3)) {
        var hex = getColorHex(options.borderColor3);
        var clr = isSet(hex) ? parseColor(hex) : undefined;
        if (clr) {
            vars[ThemeVar.BorderColor3] = clr.string();
        }
    }
    if (isSet(options.borderColor3Dark)) {
        var hex = getColorHex(options.borderColor3Dark);
        var clr = isSet(hex) ? parseColor(hex) : undefined;
        if (clr) {
            vars[ThemeVar.BorderColor3Dark] = clr.string();
        }
    }
    if (isSet(options.borderRadius)) {
        toPairs(themeVarBorderRadiusValues[options.borderRadius]).forEach(function (_a) {
            var k = _a[0], v = _a[1];
            vars[k] = v > 0 ? v + "px" : v;
        });
    }
    if (fontRegular && !fontRegular.default) {
        vars[ThemeVar.FontRegular] = getFontFamily(fontRegular);
    }
    if (fontHeading && !fontHeading.default) {
        vars[ThemeVar.FontHeading] = getFontFamily(fontHeading);
    }
    setBorderVars(vars, options.elementWrapperBorder, function (key) { return "element-wrapper-" + key; });
    setBorderRadiusVars(vars, options.elementWrapperBorderRadius, 'element-wrapper-border-radius');
    setShadowVars(vars, options.elementWrapperShadow, function (key) { return "element-wrapper-" + key; });
    setMarginVars(vars, options.elementWrapperMargin, function (key) { return "element-wrapper-margin-" + key; });
    return vars;
}
export function getThemeVarBorderRadiusValue(borderRadius, variable) {
    borderRadius = borderRadius || BorderRadius.M;
    if (themeVarBorderRadiusValues[borderRadius] && isSet(themeVarBorderRadiusValues[borderRadius][variable])) {
        return themeVarBorderRadiusValues[borderRadius][variable];
    }
    else {
        return 0;
    }
}
