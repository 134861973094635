import keys from 'lodash/keys';
import pickBy from 'lodash/pickBy';

import { ValueWidget } from '@modules/dashboard';
import { isSet } from '@shared';

import { Margin } from './elements/items/base';
import { WidgetElementItem } from './elements/items/widget';
import { TextStyle } from './text-style';

export class ValueWidgetStyles {
  titleStyle?: TextStyle;
  valueStyle?: TextStyle;
  margin?: Margin;

  constructor(options: Partial<ValueWidgetStyles> = {}) {
    Object.assign(this, options);
  }

  deserialize(data: Object): this {
    if (data['title_style']) {
      this.titleStyle = new TextStyle().deserialize(data['title_style']);
    } else {
      this.titleStyle = undefined;
    }

    if (data['value_style']) {
      this.valueStyle = new TextStyle().deserialize(data['value_style']);
    } else {
      this.valueStyle = undefined;
    }

    if (data['margin']) {
      this.margin = data['margin'];
    } else {
      this.margin = undefined;
    }

    return this;
  }

  serialize(): Object {
    return {
      title_style: this.titleStyle ? this.titleStyle.serialize() : undefined,
      value_style: this.valueStyle ? this.valueStyle.serialize() : undefined,
      margin: this.margin
    };
  }

  apply(other: this): this {
    const properties: (keyof ValueWidgetStyles)[] = ['titleStyle', 'valueStyle', 'margin'];

    properties.forEach(property => {
      if (isSet(other[property])) {
        this[property] = other[property];
      }
    });

    return this;
  }
}

export function getValueWidgetStyles(element: WidgetElementItem, widget: ValueWidget): ValueWidgetStyles {
  const options: Partial<ValueWidgetStyles> = pickBy(
    {
      titleStyle: widget.titleStyle,
      valueStyle: widget.valueStyle,
      ...(keys(element.margin).length && {
        margin: element.margin
      })
    },
    v => isSet(v)
  );

  if (!keys(options).length) {
    return;
  }

  return new ValueWidgetStyles(options);
}

export function applyValueWidgetStyles(element: WidgetElementItem, widget: ValueWidget, styles?: ValueWidgetStyles) {
  if (!styles) {
    styles = new ValueWidgetStyles();
  }

  widget.titleStyle = styles.titleStyle;
  widget.valueStyle = styles.valueStyle;
  element.margin = styles.margin;
}
