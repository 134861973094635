import { Directive, Input } from '@angular/core';

import { ThemeOptions, ThemeOptionsDirective } from '@modules/theme-components';

import { ThemeTemplate } from '../../components/theme-gallery/theme-template';
import { deserializeElementWrapperStyles } from '../../components/theme-gallery/theme-template-deserialize.utils';

@Directive({
  selector: '[appColorThemeOptions]'
})
export class ColorThemeOptionsDirective extends ThemeOptionsDirective {
  @Input() theme: ThemeTemplate;

  getThemeOptions(): ThemeOptions {
    if (this.theme) {
      const result: ThemeOptions = {
        accentColor: this.theme.accentColor,
        accentColorDark: this.theme.accentColorDark,
        backgroundColor: this.theme.backgroundColor,
        backgroundColorDark: this.theme.backgroundColorDark,
        backgroundColor2: this.theme.backgroundColor2,
        backgroundColor2Dark: this.theme.backgroundColor2Dark,
        backgroundColor3: this.theme.backgroundColor3,
        backgroundColor3Dark: this.theme.backgroundColor3Dark,
        backgroundColor4: this.theme.backgroundColor4,
        backgroundColor4Dark: this.theme.backgroundColor4Dark,
        backgroundColor5: this.theme.backgroundColor5,
        backgroundColor5Dark: this.theme.backgroundColor5Dark,
        textColor: this.theme.textColor,
        textColorDark: this.theme.textColorDark,
        textColor2: this.theme.textColor2,
        textColor2Dark: this.theme.textColor2Dark,
        textColor3: this.theme.textColor3,
        textColor3Dark: this.theme.textColor3Dark,
        borderColor: this.theme.borderColor,
        borderColorDark: this.theme.borderColorDark,
        borderColor2: this.theme.borderColor2,
        borderColor2Dark: this.theme.borderColor2Dark,
        borderColor3: this.theme.borderColor3,
        borderColor3Dark: this.theme.borderColor3Dark,
        borderRadius: this.theme.borderRadius,
        fontRegular: this.theme.fontRegular,
        fontHeading: this.theme.fontHeading
      };

      if (this.theme.elementWrapperStyles) {
        const elementWrapperStyles = deserializeElementWrapperStyles(this.theme.elementWrapperStyles);

        result.elementWrapperBorder = elementWrapperStyles.borderSettings;
        result.elementWrapperBorderRadius = elementWrapperStyles.borderRadius;
        result.elementWrapperShadow = elementWrapperStyles.shadow;
        result.elementWrapperMargin = elementWrapperStyles.margin;
      }

      return result;
    } else {
      return super.getThemeOptions();
    }
  }
}
