import { GradientType } from '@modules/colors';
import { BorderStyle, FillType } from '@modules/customize';
import { MenuBlockLayout, MenuBlockLayouts } from '@modules/menu';
import { BorderRadius } from '@modules/theme';

import { ThemeTemplate } from './theme-template';

export const themeTemplates: ThemeTemplate[] = [
  {
    name: 'Basic',
    menuBlocks: [
      {
        layout: MenuBlockLayout.LeftThin,
        fillSettings: {
          fill: {
            type: FillType.Color,
            color: MenuBlockLayouts.getDefaultColor(MenuBlockLayout.LeftThin)
          },
          fillDark: {
            type: FillType.Color,
            color: MenuBlockLayouts.getDefaultColor(MenuBlockLayout.LeftThin)
          }
        }
      },
      {
        layout: MenuBlockLayout.LeftWide,
        fillSettings: {
          fill: {
            type: FillType.Color,
            color: MenuBlockLayouts.getDefaultColor(MenuBlockLayout.LeftWide)
          },
          fillDark: {
            type: FillType.Color,
            color: MenuBlockLayouts.getDefaultColor(MenuBlockLayout.LeftWide)
          }
        }
      }
    ]
  },
  {
    name: 'Purple',
    accentColor: '#8C3CA9',
    accentColorDark: '#B26CCB',
    backgroundColor: '#EDF0F7',
    backgroundColorDark: '#1F142E',
    backgroundColor2: '#FFFFFF',
    backgroundColor2Dark: '#33234E',
    backgroundColor3: 'white',
    backgroundColor3Dark: '#120F14',
    backgroundColor4Dark: '#442F6A',
    backgroundColor5: '#FEBEEE',
    textColor: 'black',
    textColor2: '#616161',
    textColor2Dark: '#A796C5',
    textColor3: '#A6A6A6',
    textColor3Dark: '#9A90AD',
    borderColor2Dark: '#50377B',
    borderRadius: BorderRadius.XL,
    padding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0
    },
    fontRegular: 'Inter',
    fontHeading: 'Inter',
    actionElementStylesPrimary: {
      fillSettings: {
        fill: {
          type: FillType.Gradient,
          gradient: {
            type: GradientType.Linear,
            stops: [
              {
                position: 0,
                id: 'l3lc',
                color: '#B964D8'
              },
              {
                position: 1,
                id: 'u09q',
                color: '#4A328F'
              }
            ],
            aspectRatio: 1,
            from: {
              x: 0,
              y: 0.5
            },
            to: {
              x: 1,
              y: 0.5
            }
          }
        },
        fillDark: {
          type: FillType.Gradient,
          gradient: {
            type: GradientType.Linear,
            stops: [
              {
                position: 0,
                id: 'sxl4',
                color: '#B964D8'
              },
              {
                position: 1,
                id: 'prnp',
                color: '#4A328F'
              }
            ],
            aspectRatio: 1,
            from: {
              x: 0,
              y: 0.5
            },
            to: {
              x: 1,
              y: 0.5
            }
          }
        }
      },
      hoverFillSettings: {
        fill: {
          type: FillType.Color,
          color: '#703087'
        },
        fillDark: {
          type: FillType.Color,
          color: '#9941B8'
        }
      },
      activeFillSettings: {
        fill: {
          type: FillType.Color,
          color: '#461E55'
        },
        fillDark: {
          type: FillType.Color,
          color: '#5F2973'
        }
      }
    },
    menuBlocks: [
      {
        layout: MenuBlockLayout.LeftWide,
        fillSettings: {
          fill: {
            type: FillType.Gradient,
            gradient: {
              type: GradientType.Linear,
              stops: [
                {
                  position: 0,
                  id: 'jwzc',
                  color: '#B964D8'
                },
                {
                  position: 1,
                  id: 'wmr2',
                  color: '#4B328F'
                }
              ],
              aspectRatio: 1,
              from: {
                x: 0.09,
                y: 0.05
              },
              to: {
                x: 0.97,
                y: 0.95
              }
            }
          },
          fillDark: {
            type: FillType.Gradient,
            gradient: {
              type: GradientType.Linear,
              stops: [
                {
                  position: 0.25,
                  id: 'syrk',
                  color: '#1F142F'
                },
                {
                  position: 1,
                  id: 'zx7q',
                  color: '#3F1943'
                }
              ],
              aspectRatio: 1,
              from: {
                x: 0.99,
                y: 0
              },
              to: {
                x: 0.05,
                y: 0.99
              }
            }
          }
        },
        borderRadius: {
          all: 24
        },
        padding: {
          top: 10,
          right: 5,
          bottom: 15,
          left: 20
        }
      },
      {
        layout: MenuBlockLayout.TopThin
      }
    ]
  },
  {
    name: 'Red',
    accentColor: '#C8405B',
    accentColorDark: '#8C2C3F',
    backgroundColor: '#F2EEEE',
    backgroundColorDark: '#000000',
    backgroundColor2: '#FBFBFB',
    backgroundColor2Dark: '#200E0E',
    backgroundColor3: '#F0F0F0',
    backgroundColor3Dark: '#000000',
    backgroundColor4: '#FBF4F2',
    backgroundColor4Dark: '#2E1414',
    backgroundColor5: '#D8D2D2',
    textColor: '#272727',
    textColor2: '#424242',
    textColor2Dark: '#C59696',
    textColor3: '#3D3D3D',
    textColor3Dark: '#876464',
    borderColor: '#F3EEEE00',
    borderColorDark: '#30262B',
    borderColor2: '#F2EDED',
    borderColor2Dark: '#392323',
    borderColor3: '#BEA2A2',
    borderRadius: BorderRadius.XL,
    padding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0
    },
    fontRegular: 'Archivo',
    fontHeading: 'Archivo',
    fieldElementStyles: {
      hoverBorderSettings: {
        border: {
          thickness: 1,
          style: BorderStyle.Solid,
          color: '#C7405B80'
        }
      },
      focusBorderSettings: {
        border: {
          thickness: 1,
          style: BorderStyle.Solid,
          color: '#C7405B59'
        }
      },
      focusShadow: {
        offsetX: 0,
        offsetY: 0,
        blurRadius: 0,
        spreadRadius: 4,
        color: '#C7405B1A'
      }
    },
    elementWrapperStyles: {
      borderSettings: {
        border: {
          thickness: 1,
          style: BorderStyle.Solid,
          color: '#EEE2E4',
          colorDark: '#3B262600'
        }
      }
    },
    menuBlocks: [
      {
        layout: MenuBlockLayout.TopThin,
        fillSettings: {
          fill: {
            type: FillType.Color,
            color: '#C8405B'
          },
          fillDark: {
            type: FillType.Color,
            color: '#8C2C3F'
          }
        }
      },
      {
        layout: MenuBlockLayout.TopThin
      }
    ]
  },
  {
    name: 'Copper',
    accentColor: '#F37623',
    accentColorDark: '#D16C29',
    backgroundColor: 'white',
    backgroundColorDark: '#382319',
    backgroundColor2: '#F1EDE7',
    backgroundColor2Dark: '#281A15',
    backgroundColor3: 'white',
    backgroundColor3Dark: '#2C1C17',
    backgroundColor4: '#FBFAF9',
    backgroundColor4Dark: '#4D3228',
    backgroundColor5: '#E5F4E2',
    textColor: 'black',
    textColor2: '#7D6D6D',
    textColor2Dark: '#AFA49C',
    textColor3: '#8D7C7C',
    textColor3Dark: '#78695F',
    borderColorDark: '#413434',
    borderColor2: '#E3DBCF',
    borderColor2Dark: '#534241',
    borderColor3Dark: '#865846',
    borderRadius: BorderRadius.XL,
    padding: {
      top: 10,
      right: 10,
      bottom: 10,
      left: 10
    },
    fontRegular: 'Inter',
    fontHeading: 'Inter',
    fieldElementStyles: {
      focusBorderSettings: {
        border: {
          thickness: 1,
          style: BorderStyle.Solid,
          color: '#CA9E81',
          colorDark: '#785040'
        }
      }
    },
    menuBlocks: [
      {
        layout: MenuBlockLayout.LeftWide,
        fillSettings: {
          fill: {
            type: FillType.Color,
            color: '#F5F2F0'
          },
          fillDark: {
            type: FillType.Color,
            color: '#2E1D14'
          }
        },
        borderSettings: {
          border: {
            thickness: 0,
            style: BorderStyle.Solid
          }
        }
      },
      {
        layout: MenuBlockLayout.TopThin,
        fillSettings: {
          fill: {
            type: FillType.Gradient,
            gradient: {
              type: GradientType.Radial,
              stops: [
                {
                  position: 0,
                  id: 'i13p',
                  color: '#935F3E'
                },
                {
                  position: 1,
                  id: 'ptvq',
                  color: '#BD8661'
                }
              ],
              aspectRatio: 4,
              from: {
                x: 0.5,
                y: 1
              },
              to: {
                x: 0.5,
                y: 0
              }
            }
          },
          fillDark: {
            type: FillType.Gradient,
            gradient: {
              type: GradientType.Radial,
              stops: [
                {
                  position: 0,
                  id: 'bafp',
                  color: '#684436'
                },
                {
                  position: 1,
                  id: 'v0tc',
                  color: '#281A15'
                }
              ],
              aspectRatio: 4,
              from: {
                x: 0.5,
                y: 1
              },
              to: {
                x: 0.5,
                y: 0
              }
            }
          }
        }
      }
    ]
  },
  {
    name: 'Green',
    accentColor: '#74A81A',
    accentColorDark: '#EDFD95',
    backgroundColor: '#FCFDFB',
    backgroundColorDark: '#10231F',
    backgroundColor2: '#ECF4EE',
    backgroundColor2Dark: '#1D3F39',
    backgroundColor3: 'white',
    backgroundColor3Dark: '#062320',
    backgroundColor4: '#94BD9F8F',
    backgroundColor4Dark: '#062320',
    backgroundColor5: '#78BE04',
    backgroundColor5Dark: '#0D0F1A',
    textColor: 'black',
    textColorDark: 'white',
    textColor2: '#2E4233',
    textColor2Dark: '#F7FFD08F',
    textColor3: '#759F80',
    textColor3Dark: '#F7FFD0',
    borderColor: '#C6DDCC',
    borderColorDark: '#062320',
    borderColor2: '#9EC7A9',
    borderColor2Dark: '#062320',
    borderColor3: '#89B895',
    borderColor3Dark: '#687C36',
    borderRadius: BorderRadius.M,
    padding: {
      top: 10,
      right: 10,
      bottom: 10,
      left: 10
    },
    fontRegular: 'DM Sans',
    fontHeading: 'DM Sans',
    actionElementStylesPrimary: {
      borderRadius: {
        all: 16
      }
    },
    actionElementStylesDefault: {
      borderRadius: {
        all: 16
      }
    },
    actionElementStylesTransparent: {
      borderRadius: {
        all: 16
      }
    },
    fieldElementStyles: {
      borderRadius: {
        all: 24
      }
    },
    menuBlocks: [
      {
        layout: MenuBlockLayout.LeftWide,
        fillSettings: {
          fill: {
            type: FillType.Color,
            color: '#F0F3EC'
          },
          fillDark: {
            type: FillType.Color,
            color: '#102826'
          }
        }
      },
      {
        layout: MenuBlockLayout.TopThin,
        fillSettings: {
          fill: {
            type: FillType.Gradient,
            gradient: {
              type: GradientType.Radial,
              stops: [
                {
                  position: 0,
                  id: 'suja',
                  color: '#51815D'
                },
                {
                  position: 1,
                  id: 'aoli',
                  color: '#75A982'
                }
              ],
              aspectRatio: 4.99,
              from: {
                x: 0.51,
                y: 1.01
              },
              to: {
                x: 0.51,
                y: -0.05
              }
            }
          },
          fillDark: {
            type: FillType.Gradient,
            gradient: {
              type: GradientType.Radial,
              stops: [
                {
                  position: 0,
                  id: 'o0i8',
                  color: '#1B3B36'
                },
                {
                  position: 1,
                  id: 'gayy',
                  color: '#132A26'
                }
              ],
              aspectRatio: 4,
              from: {
                x: 0.5,
                y: 1
              },
              to: {
                x: 0.5,
                y: 0
              }
            }
          }
        }
      }
    ]
  },
  {
    name: 'Teal',
    accentColor: '#3898A7',
    accentColorDark: '#256A5E',
    backgroundColor: '#98BDBD',
    backgroundColorDark: '#142A2A',
    backgroundColor2: '#ACCACA',
    backgroundColor2Dark: '#193837',
    backgroundColor3: '#CDDFDF',
    backgroundColor3Dark: '#162726',
    backgroundColor4: '#9BBFBF',
    backgroundColor4Dark: '#0F1F1F',
    backgroundColor5: '#6FA3A4',
    textColor: '#0E7781',
    textColor2: '#399698',
    textColor2Dark: '#9FA8B2',
    textColor3: '#20545587',
    textColor3Dark: '#7F8C9A',
    borderColor: '#82AFB0',
    borderColorDark: '#3D5251',
    borderColor2: '#98BDBE',
    borderColor2Dark: '#3D5251',
    borderColor3: '#4B7677',
    borderRadius: BorderRadius.XL,
    actionElementStylesPrimary: {
      fillSettings: {
        fill: {
          type: FillType.Color,
          color: '#3696A6'
        },
        fillDark: {
          type: FillType.Color,
          color: '#2B50ED'
        }
      }
    },
    actionElementStylesDefault: {
      textStyle: {
        color: '#0A475A',
        colorDark: '#35A184'
      },
      iconSettings: {
        colorDark: '#35A184'
      },
      hoverTextStyle: {
        color: '#0A475A',
        colorDark: '#FFFFFF3'
      },
      activeTextStyle: {
        color: '#0A475A',
        colorDark: '#35A184'
      }
    },
    actionElementStylesTransparent: {
      textStyle: {
        color: '#0A475A',
        colorDark: '#35A184'
      },
      iconSettings: {
        colorDark: '#35A184'
      },
      hoverTextStyle: {
        color: '#0A475A',
        colorDark: '#35A184'
      },
      activeTextStyle: {
        color: '#0A475A',
        colorDark: '#35A184'
      }
    },
    fieldElementStyles: {
      textStyle: {
        color: '#4C8485',
        colorDark: '#9CA5AF'
      },
      placeholderStyle: {
        color: '#34505080',
        colorDark: '#9CA5AF80'
      },
      labelStyle: {
        colorDark: '#9CA5AF'
      },
      labelAdditionalStyle: {
        color: '#39969887',
        colorDark: '#5F6B78'
      },
      hoverTextStyle: {
        colorDark: '#9CA5AF'
      },
      hoverPlaceholderStyle: {
        color: '#20545580',
        colorDark: '#9CA5AF80'
      },
      focusTextStyle: {
        colorDark: '#9CA5AF'
      },
      focusPlaceholderStyle: {
        color: '#1D4D4E80',
        colorDark: '#9CA5AF80'
      },
      errorTextStyle: {
        colorDark: '#9CA5AF'
      },
      errorPlaceholderStyle: {
        color: '#27585980',
        colorDark: '#9CA5AF80'
      }
    },
    menuBlocks: [
      {
        layout: MenuBlockLayout.LeftWide,
        fillSettings: {
          fill: {
            type: FillType.Gradient,
            gradient: {
              type: GradientType.Linear,
              stops: [
                {
                  position: 0,
                  id: 'qr9d',
                  color: '#41C3AD'
                },
                {
                  position: 1,
                  id: 'lexd',
                  color: '#3696A6'
                }
              ],
              aspectRatio: 0.66,
              from: {
                x: 0.75,
                y: 0.75
              },
              to: {
                x: 0,
                y: 0
              }
            }
          },
          fillDark: {
            type: FillType.Gradient,
            gradient: {
              type: GradientType.Linear,
              stops: [
                {
                  position: 0,
                  id: 'kzg1',
                  color: '#1E7656'
                },
                {
                  position: 1,
                  id: 'o26y',
                  color: '#1B5374'
                }
              ],
              aspectRatio: 1,
              from: {
                x: 0.75,
                y: 0.75
              },
              to: {
                x: 0,
                y: 0
              }
            }
          }
        },
        borderRadius: {
          topLeft: 24,
          topRight: 24,
          bottomRight: 24,
          bottomLeft: 24
        },
        padding: {
          top: 15,
          right: null,
          bottom: 15,
          left: 15
        }
      },
      {
        layout: MenuBlockLayout.TopThin,
        fillSettings: {
          fill: {
            type: FillType.Gradient,
            gradient: {
              type: GradientType.Linear,
              stops: [
                {
                  position: 0,
                  id: 'g73s',
                  color: '#0F6681'
                },
                {
                  position: 1,
                  id: 'vi6s',
                  color: '#328B71'
                }
              ],
              aspectRatio: 1,
              from: {
                x: 0,
                y: 0
              },
              to: {
                x: 0.5,
                y: 1
              }
            }
          },
          fillDark: {
            type: FillType.Gradient,
            gradient: {
              type: GradientType.Linear,
              stops: [
                {
                  position: 0,
                  id: 'tbc9',
                  color: '#23727B'
                },
                {
                  position: 1,
                  id: 'k9x0',
                  color: '#285749'
                }
              ],
              aspectRatio: 1,
              from: {
                x: 0,
                y: 0
              },
              to: {
                x: 0.5,
                y: 1
              }
            }
          }
        },
        borderRadius: {
          topLeft: 12,
          topRight: 12,
          bottomRight: 12,
          bottomLeft: 12
        },
        padding: {
          top: 15,
          right: 15,
          bottom: null,
          left: 15
        }
      }
    ]
  },
  {
    name: 'Black & White',
    accentColor: 'black',
    accentColorDark: 'white',
    backgroundColor: 'white',
    backgroundColorDark: 'black',
    backgroundColor2: 'white',
    backgroundColor2Dark: 'black',
    backgroundColor3: 'white',
    backgroundColor3Dark: 'black',
    backgroundColor4: 'white',
    backgroundColor4Dark: 'black',
    backgroundColor5: 'white',
    backgroundColor5Dark: 'black',
    textColor: '#272727',
    textColorDark: 'white',
    textColor2: '#272727',
    textColor2Dark: 'white',
    textColor3: '#0000008C',
    textColor3Dark: '#C4C4C4',
    borderColor: 'black',
    borderColor2: '#00000080',
    borderColor3: 'black',
    borderRadius: BorderRadius.XL,
    padding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0
    },
    fontRegular: 'Roboto',
    fontHeading: 'Angkor',
    elementWrapperStyles: {
      margin: {
        top: 10,
        right: 10,
        bottom: 0,
        left: 10
      }
    },
    menuBlocks: [
      {
        layout: MenuBlockLayout.TopThin,
        fillSettings: {
          fill: {
            type: FillType.Color,
            color: '#000000'
          },
          fillDark: {
            type: FillType.Color,
            color: '#FFFFFF'
          }
        },
        borderRadius: {
          topLeft: 0,
          topRight: 0,
          bottomRight: 0,
          bottomLeft: 0
        },
        padding: {
          top: 0,
          right: 0,
          bottom: null,
          left: 0
        }
      }
    ]
  }
];
