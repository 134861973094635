import { Inject, Injectable, InjectionToken, OnDestroy, OnInit } from '@angular/core';
import isEqual from 'lodash/isEqual';
import values from 'lodash/values';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from 'rxjs';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';

import { ActionService, isModelUpdateEventMatch, patchModel } from '@modules/action-queries';
import {
  displayFieldsToViewContextOutputs,
  getModelAttributesByColumns,
  ModelData,
  ModelDataType,
  RawListViewSettingsColumn,
  ViewContext,
  ViewContextElement
} from '@modules/customize';
import { DataSourceGeneratorService } from '@modules/customize-generators';
import { DataSourceType, ListModelDescriptionDataSource, ModelDescriptionDataSource } from '@modules/data-sources';
import { ModelDescriptionDataSourceService } from '@modules/data-sources-queries';
import { applyParamInput$, applyParamInputs$, DisplayFieldType, LOADING_VALUE, NOT_SET_VALUE } from '@modules/fields';
import { ModelDescriptionStore } from '@modules/model-queries';
import { Model, ModelDescription } from '@modules/models';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { QueryType } from '@modules/queries';
import { paramsToGetQueryOptions } from '@modules/resources';
import { ascComparator, isSet } from '@shared';

interface State {
  query?: ModelData;
  detailDataSource?: ModelDescriptionDataSource;
  listDataSource?: ListModelDescriptionDataSource;
  params?: Object;
  detailStaticData?: Object;
  listStaticData?: Object[];
  modelDescription?: ModelDescription;
  inputsLoading?: boolean;
  inputsNotSet?: boolean;
  perPage?: number;
  sortingField?: string;
  sortingAsc?: boolean;
}

export function serializeDataSourceColumns(columns: RawListViewSettingsColumn[]): Object[] {
  return columns
    .filter(item => !item.flex)
    .map(item => {
      return {
        name: item.name
      };
    })
    .sort((lhs, rhs) => ascComparator(lhs.name, rhs.name));
}

function getElementStateFetch(state: State): Object {
  return {
    detailDataSource: state.detailDataSource
      ? {
          ...state.detailDataSource.serialize(),
          columns: serializeDataSourceColumns(state.detailDataSource.columns)
        }
      : undefined,
    detailStaticData: state.detailStaticData,
    listDataSource: state.listDataSource
      ? {
          ...state.listDataSource.serialize(),
          columns: serializeDataSourceColumns(state.listDataSource.columns)
        }
      : undefined,
    listStaticData: state.listStaticData,
    params: state.params,
    inputsLoading: state.inputsLoading,
    inputsNotSet: state.inputsNotSet,
    perPage: state.perPage,
    sortingField: state.sortingField,
    sortingAsc: state.sortingAsc
  };
}

function getElementStateColumns(state: State): Object {
  return {
    columns: state.detailDataSource ? state.detailDataSource.columns : undefined
  };
}

function getElementStateName(state: State): Object {
  return {
    name: state.query ? state.query.name : undefined
  };
}

export const queryControllerQueryToken = new InjectionToken<ViewContextElement>('queryToken');
export const queryControllerContextElementToken = new InjectionToken<ViewContextElement>('contextElementToken');
export const queryControllerParentElementToken = new InjectionToken<ViewContextElement>('parentElementToken');

@Injectable()
export class CustomPageQueryController implements OnInit, OnDestroy {
  query$ = new BehaviorSubject<ModelData>(undefined);
  state: State = {};

  loadingSubscription: Subscription;
  detailResult: Model;
  listResult: Model[];

  constructor(
    @Inject(queryControllerQueryToken) public query: ModelData,
    @Inject(queryControllerContextElementToken) public contextElement: ViewContextElement,
    @Inject(queryControllerParentElementToken) public parentElement: ViewContextElement,
    private context: ViewContext,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private modelDescriptionStore: ModelDescriptionStore,
    private actionService: ActionService,
    private modelDescriptionDataSourceService: ModelDescriptionDataSourceService,
    private dataSourceGeneratorService: DataSourceGeneratorService
  ) {}

  ngOnInit(): void {
    this.initContext();
    this.trackModelUpdates();

    this.queryOnChange(this.query);
    this.trackChanges();
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  destroy() {
    this.contextElement.unregister();
  }

  setQuery(query: ModelData) {
    this.query = query;
    this.queryOnChange(this.query);
    this.initContext();
  }

  queryOnChange(value: ModelData) {
    this.query$.next(value);
  }

  trackChanges() {
    this.query$
      .pipe(
        switchMap(query => {
          if (query.type == ModelDataType.Detail) {
            return this.dataSourceGeneratorService
              .applyDataSourceDefaults<ModelDescriptionDataSource>(ModelDescriptionDataSource, query.detailDataSource)
              .pipe(
                map(dataSource => {
                  query.detailDataSource = dataSource;
                  return query;
                })
              );
          } else if (query.type == ModelDataType.List) {
            return this.dataSourceGeneratorService
              .applyDataSourceDefaults<ListModelDescriptionDataSource>(
                ListModelDescriptionDataSource,
                query.listDataSource
              )
              .pipe(
                map(dataSource => {
                  query.listDataSource = dataSource;
                  return query;
                })
              );
          } else {
            return of(query);
          }
        }),
        switchMap(query => this.getState(query)),
        untilDestroyed(this)
      )
      .subscribe(state => {
        this.onStateUpdated(state);
        this.state = state;
      });
  }

  getState(query: ModelData): Observable<State> {
    if (query.type == ModelDataType.Detail) {
      const staticData$ =
        query.detailDataSource && query.detailDataSource.type == DataSourceType.Input && query.detailDataSource.input
          ? applyParamInput$<Object>(query.detailDataSource.input, {
              context: this.context,
              defaultValue: {},
              handleLoading: true,
              ignoreEmpty: true
            }).pipe(distinctUntilChanged((lhs, rhs) => isEqual(lhs, rhs)))
          : of({});
      const dataSourceParams$ = query.detailDataSource
        ? applyParamInputs$({}, query.detailDataSource.queryInputs, {
            context: this.context,
            parameters: query.detailDataSource.queryParameters,
            handleLoading: true,
            ignoreEmpty: true
          }).pipe(distinctUntilChanged((lhs, rhs) => isEqual(lhs, rhs)))
        : of({});

      return combineLatest(staticData$, dataSourceParams$, this.getQueryModelDescription(query.detailDataSource)).pipe(
        map(([staticData, dataSourceParams, modelDescription]) => {
          return {
            query: query,
            detailDataSource: query.detailDataSource,
            detailStaticData: staticData,
            params: dataSourceParams,
            modelDescription: modelDescription,
            inputsLoading: [dataSourceParams, staticData].some(obj => {
              return obj == LOADING_VALUE || values(obj).some(item => item === LOADING_VALUE);
            }),
            inputsNotSet: [dataSourceParams, staticData].some(obj => {
              return obj == NOT_SET_VALUE || values(obj).some(item => item === NOT_SET_VALUE);
            })
          };
        })
      );
    } else if (query.type == ModelDataType.List) {
      const staticData$ =
        query.listDataSource && query.listDataSource.type == DataSourceType.Input && query.listDataSource.input
          ? applyParamInput$<Object[]>(query.listDataSource.input, {
              context: this.context,
              defaultValue: [],
              handleLoading: true,
              ignoreEmpty: true
            }).pipe(distinctUntilChanged((lhs, rhs) => isEqual(lhs, rhs)))
          : of([]);
      const dataSourceParams$ = query.listDataSource
        ? applyParamInputs$({}, query.listDataSource.queryInputs, {
            context: this.context,
            parameters: query.listDataSource.queryParameters,
            handleLoading: true,
            ignoreEmpty: true
          }).pipe(distinctUntilChanged((lhs, rhs) => isEqual(lhs, rhs)))
        : of({});

      return combineLatest(staticData$, dataSourceParams$, this.getQueryModelDescription(query.listDataSource)).pipe(
        map(([staticData, dataSourceParams, modelDescription]) => {
          return {
            query: query,
            listDataSource: query.listDataSource,
            listStaticData: staticData,
            params: dataSourceParams,
            modelDescription: modelDescription,
            inputsLoading: [dataSourceParams, staticData].some(obj => {
              return obj == LOADING_VALUE || values(obj).some(item => item === LOADING_VALUE);
            }),
            inputsNotSet: [dataSourceParams, staticData].some(obj => {
              return obj == NOT_SET_VALUE || values(obj).some(item => item === NOT_SET_VALUE);
            }),
            perPage: query.perPage,
            sortingField: query.sortingField,
            sortingAsc: query.sortingAsc
          };
        })
      );
    }
  }

  onStateUpdated(state: State) {
    if (!isEqual(getElementStateColumns(state), getElementStateColumns(this.state))) {
      this.updateContextOutputs(state);
    }

    if (!isEqual(getElementStateName(state), getElementStateName(this.state))) {
      this.updateContextInfo(state);
    }

    if (!isEqual(getElementStateFetch(state), getElementStateFetch(this.state))) {
      this.fetch(state);
    }
  }

  fetch(state: State) {
    if (this.loadingSubscription) {
      this.loadingSubscription.unsubscribe();
      this.loadingSubscription = undefined;
    }

    if (state.detailDataSource) {
      this.parentElement.patchOutputValueMeta(this.contextElement.uniqueName, { loading: true });

      const configured = state.detailDataSource && state.detailDataSource.isConfigured();

      if (!configured || state.inputsLoading || state.inputsNotSet) {
        return;
      }

      const queryOptions = paramsToGetQueryOptions(state.params);

      queryOptions.columns = state.detailDataSource ? state.detailDataSource.columns : undefined;

      this.loadingSubscription = this.modelDescriptionDataSourceService
        .getDetailAdv({
          project: this.currentProjectStore.instance,
          environment: this.currentEnvironmentStore.instance,
          dataSource: state.detailDataSource,
          queryOptions: queryOptions,
          staticData: state.detailStaticData,
          context: this.context
        })
        .pipe(untilDestroyed(this))
        .subscribe(
          model => {
            this.detailResult = model;
            this.updateContextValue();

            this.parentElement.patchOutputValueMeta(this.contextElement.uniqueName, { loading: false });
          },
          () => {
            this.detailResult = undefined;
            this.updateContextValue();

            this.parentElement.patchOutputValueMeta(this.contextElement.uniqueName, { loading: false });
          }
        );
    } else if (state.listDataSource) {
      this.parentElement.patchOutputValueMeta(this.contextElement.uniqueName, { loading: true });

      const configured = state.listDataSource && state.listDataSource.isConfigured();

      if (!configured || state.inputsLoading || state.inputsNotSet) {
        return;
      }

      const queryOptions = paramsToGetQueryOptions(state.params);

      queryOptions.columns = state.listDataSource ? state.listDataSource.columns : undefined;
      queryOptions.paging = queryOptions.paging || {};

      if (state.perPage) {
        queryOptions.paging.limit = state.perPage;
      }

      if (isSet(state.sortingField)) {
        queryOptions.sort = [{ field: state.sortingField, desc: !state.sortingAsc }];
      }

      this.loadingSubscription = this.modelDescriptionDataSourceService
        .getAdv({
          project: this.currentProjectStore.instance,
          environment: this.currentEnvironmentStore.instance,
          dataSource: state.listDataSource,
          queryOptions: queryOptions,
          staticData: state.listStaticData,
          context: this.context
        })
        .pipe(untilDestroyed(this))
        .subscribe(
          result => {
            this.listResult = result ? result.results : undefined;
            this.updateContextOutputs(state);
            this.updateContextValue();

            this.parentElement.patchOutputValueMeta(this.contextElement.uniqueName, { loading: false });
          },
          () => {
            this.listResult = undefined;
            this.updateContextOutputs(state);
            this.updateContextValue();

            this.parentElement.patchOutputValueMeta(this.contextElement.uniqueName, { loading: false });
          }
        );
    } else {
      this.detailResult = undefined;
      this.listResult = undefined;
      this.updateContextValue();
    }
  }

  getQueryModelDescription(dataSource: ModelDescriptionDataSource) {
    if (
      !dataSource ||
      !dataSource.query ||
      dataSource.query.queryType != QueryType.Simple ||
      !dataSource.query.simpleQuery
    ) {
      return of(undefined);
    }

    const modelId = [dataSource.queryResource, dataSource.query.simpleQuery.model].join('.');
    return this.modelDescriptionStore.getDetailFirst(modelId);
  }

  initContext() {
    this.contextElement.initGlobal(
      {
        uniqueName: this.query.uid,
        name: this.query.name,
        icon: 'pages',
        insert: true
      },
      this.parentElement
    );
    this.contextElement.setActions([
      {
        uniqueName: 'update_data',
        name: 'Update Data',
        icon: 'repeat',
        parameters: [],
        handler: () => this.reloadData()
      }
    ]);
  }

  updateContextInfo(state: State) {
    this.contextElement.initInfo(
      {
        name: state.query.name,
        getFieldValue: (field, outputs) => {
          return outputs[field];
        }
      },
      true
    );
  }

  updateContextOutputs(state: State) {
    if (state.detailDataSource) {
      const children = state.detailDataSource
        ? displayFieldsToViewContextOutputs(
            state.detailDataSource.columns.filter(item => item.type != DisplayFieldType.Computed),
            state.modelDescription
          )
        : [];
      this.contextElement.setOutputs(children);
    } else if (state.listDataSource) {
      const children = state.listDataSource
        ? displayFieldsToViewContextOutputs(
            state.listDataSource.columns.filter(item => item.type != DisplayFieldType.Computed),
            state.modelDescription
          )
        : [];
      this.contextElement.setOutputs(
        (this.listResult || []).map((item, i) => {
          return {
            uniqueName: String(i),
            name: `Item #${i + 1}`,
            children: children
          };
        })
      );
    } else {
      this.contextElement.setOutputs([]);
    }
  }

  updateContextValue() {
    if (this.state.detailDataSource) {
      const output =
        this.detailResult && this.state.detailDataSource
          ? getModelAttributesByColumns(this.detailResult, this.state.detailDataSource.columns)
          : undefined;
      this.contextElement.setOutputValues(output);
    } else if (this.state.listDataSource) {
      const output =
        this.listResult && this.state.listDataSource
          ? this.listResult.map(item => {
              return getModelAttributesByColumns(item, this.state.listDataSource.columns);
            })
          : undefined;
      this.contextElement.setOutputValues(output);
    } else {
      this.contextElement.setOutputValues({});
    }
  }

  trackModelUpdates() {
    this.actionService.modelUpdated$.pipe(untilDestroyed(this)).subscribe(e => {
      if (this.detailResult && isModelUpdateEventMatch(e, this.state.modelDescription, this.detailResult)) {
        this.detailResult = patchModel(this.detailResult, e.model);
        this.updateContextValue();
      } else if (this.listResult && isModelUpdateEventMatch(e, this.state.modelDescription, this.detailResult)) {
        let updated = false;

        this.listResult.map(item => {
          if (isModelUpdateEventMatch(e, this.state.modelDescription, item)) {
            updated = true;
            return patchModel(item, e.model);
          } else {
            return item;
          }
        });

        if (updated) {
          this.updateContextValue();
        }
      }
    });
  }

  reloadData(): void {
    this.fetch(this.state);
  }
}
