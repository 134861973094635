import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import fromPairs from 'lodash/fromPairs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { NotificationService } from '@common/notifications';
import { ActionControllerService } from '@modules/action-queries';
import { UserActivityCountService, UserActivityType } from '@modules/activities';
import { TimelineController } from '@modules/activities-components';
import { CustomViewsStore } from '@modules/custom-views';
import {
  getModelAttributesByColumns,
  KanbanBoardSettings,
  ListItemViewContext,
  ViewContext,
  ViewContextElement
} from '@modules/customize';
import { ListModelDescriptionDataSource } from '@modules/data-sources';
import { applyParamInput, applyParamInput$, DisplayField, FieldType, ParameterField } from '@modules/fields';
import { ITEM_OUTPUT, ListItem } from '@modules/list';
import { Model, ModelDescription } from '@modules/models';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { View } from '@modules/views';
import { EMPTY, isSet, TypedChanges } from '@shared';

@Component({
  selector: 'app-kanban-board-item',
  templateUrl: './kanban-board-item.component.html',
  providers: [ListItemViewContext],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KanbanBoardItemComponent implements OnInit, OnDestroy, OnChanges {
  @Input() item: ListItem;
  @Input() modelDescription: ModelDescription;
  @Input() settings: KanbanBoardSettings;
  @Input() dataSource: ListModelDescriptionDataSource;
  @Input() visibleColumns: DisplayField[] = [];
  @Input() draggable = false;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() selected = false;
  @Input() accentColor: string;
  @Input() theme = false;
  @Output() select = new EventEmitter<ListItem>();
  @Output() modelUpdated = new EventEmitter<Model>();

  settings$ = new BehaviorSubject<KanbanBoardSettings>(undefined);
  view: View;
  viewParameters: ParameterField[] = [];
  viewParams$: Observable<Object> = of({});
  localContext: Object = {};
  commentsSubscription: Subscription;
  commentsCountSubscription: Subscription;
  commentsConfigured = false;
  commentsObjectType: string;
  commentsObjectId: any;
  commentsCount: number;

  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private listItemViewContext: ListItemViewContext,
    private actionControllerService: ActionControllerService,
    private customViewsStore: CustomViewsStore,
    private timelineController: TimelineController,
    private userActivityCountService: UserActivityCountService,
    private notificationService: NotificationService,
    private injector: Injector,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.settings$
      .pipe(
        filter(settings => !!settings),
        switchMap(settings => {
          if (!isSet(settings.cardCustomView)) {
            return of(undefined);
          }

          return this.customViewsStore.getDetail(settings.cardCustomView);
        }),
        untilDestroyed(this)
      )
      .subscribe(customView => {
        this.view = customView ? customView.view : undefined;
        this.cd.markForCheck();
        this.updateViewParameters();
        this.updateViewParams();
      });
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<KanbanBoardItemComponent>): void {
    if (changes.item) {
      // this.context.model = this.item.model;
      this.localContext = {
        [ITEM_OUTPUT]: this.item.model.getAttributes()
      };
      this.initComments();
    }

    if (changes.settings) {
      this.settings$.next(this.settings);
      this.updateViewParameters();
      this.initComments();
    }

    if (changes.settings || changes.item) {
      this.updateViewParams();
    }
  }

  updateViewParameters() {
    if (!this.view) {
      this.viewParameters = [];
      this.cd.markForCheck();
      return;
    }

    const columns = this.settings.dataSource ? this.settings.dataSource.columns : [];

    this.viewParameters = columns.map(item => {
      const parameter = new ParameterField();

      parameter.name = item.name;
      parameter.verboseName = item.verboseName;
      parameter.field = item.field;
      parameter.params = item.params;

      return parameter;
    });
    this.cd.markForCheck();
  }

  updateViewParams() {
    if (!this.view) {
      this.viewParams$ = of({});
      this.cd.markForCheck();
      return;
    }

    const columns = this.settings.dataSource ? this.settings.dataSource.columns : [];
    const modelParams = getModelAttributesByColumns(this.item.model, columns);

    this.viewParams$ = combineLatest(
      this.settings.cardCustomViewMappings.map(mapping => {
        if (mapping.sourceParameterInput) {
          return applyParamInput$(mapping.sourceParameterInput, {
            context: this.context,
            contextElement: this.contextElement,
            localContext: {
              [ITEM_OUTPUT]: modelParams
            }
          }).pipe(
            map(value => {
              return {
                name: mapping.targetParameter,
                value: value !== EMPTY ? value : undefined
              };
            })
          );
        } else {
          const value = modelParams[mapping.sourceParameter];
          return of({
            name: mapping.targetParameter,
            value: value
          });
        }
      })
    ).pipe(
      map(values => {
        return fromPairs(values.map(item => [item.name, item.value]));
      })
    );
    this.cd.markForCheck();
  }

  open(e: MouseEvent, element: HTMLElement) {
    const select = !this.selected;
    this.contextElement.context.clickEvent = e;

    this.select.emit(this.item);

    if (this.settings.cardClickAction && select) {
      this.actionControllerService
        .execute(this.settings.cardClickAction, {
          context: this.contextElement.context,
          contextElement: this.contextElement,
          localContext: {
            [ITEM_OUTPUT]: this.item.model.getAttributes()
          },
          injector: this.injector,
          origin: element
        })
        .subscribe();
    }
  }

  initComments() {
    if (this.commentsSubscription) {
      this.commentsSubscription.unsubscribe();
    }

    if (!this.settings.commentsEnabled || !this.settings.commentsObjectType) {
      this.commentsConfigured = false;
      this.cd.markForCheck();
      this.updateCount();
      return;
    }

    const objectTypeField = { name: 'value', field: FieldType.Text };
    const objectTypeId = { name: 'value' };

    this.commentsObjectType = applyParamInput(this.settings.commentsObjectType, {
      context: this.context,
      contextElement: this.contextElement,
      localContext: {
        [ITEM_OUTPUT]: this.item.model.getAttributes()
      },
      // field: objectTypeField,
      defaultValue: undefined
    });

    if (!isSet(this.commentsObjectType)) {
      this.commentsConfigured = false;
      this.cd.markForCheck();
      return;
    }

    this.commentsObjectId = this.settings.commentsObjectId
      ? applyParamInput(this.settings.commentsObjectId, {
          context: this.context,
          contextElement: this.contextElement,
          localContext: {
            [ITEM_OUTPUT]: this.item.model.getAttributes()
          },
          // field: objectTypeId,
          defaultValue: undefined
        })
      : undefined;
    this.commentsConfigured = true;
    this.cd.markForCheck();
    this.updateCount();

    // this.commentsSubscription = this.context.outputs$
    //   .pipe(debounceTime(10), distinctUntilChanged(), untilDestroyed(this))
    //   .subscribe(() => {
    //     this.commentsObjectType = applyParamInput(
    //       this.settings.commentsObjectType,
    //       undefined,
    //       this.context,
    //       this.contextElement,
    //       undefined
    //     );
    //     this.commentsObjectId = this.settings.commentsObjectId
    //       ? applyParamInput(this.settings.commentsObjectId, undefined, this.context, this.contextElement, undefined)
    //       : undefined;
    //     this.cd.markForCheck();
    //   });
  }

  updateCount() {
    if (this.commentsCountSubscription) {
      this.commentsCountSubscription.unsubscribe();
    }

    if (!this.commentsConfigured) {
      this.commentsCount = undefined;
      this.cd.markForCheck();
      return;
    }

    this.commentsCountSubscription = this.userActivityCountService
      .subscribeCountObjectId(
        this.currentProjectStore.instance.uniqueName,
        this.currentEnvironmentStore.instance.uniqueName,
        UserActivityType.Message,
        this.commentsObjectType,
        this.commentsObjectId
      )
      .pipe(untilDestroyed(this))
      .subscribe(
        count => {
          this.commentsCount = count;
          this.cd.markForCheck();
        },
        () => {
          this.commentsCount = undefined;
          this.cd.markForCheck();
        }
      );
  }

  openTimeline() {
    this.timelineController.open(this.commentsObjectType, this.commentsObjectId);
  }
}
