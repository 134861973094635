import { ChangeDetectorRef, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import clamp from 'lodash/clamp';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, fromEvent, of } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { getColorHexStr, getColorVariable } from '@modules/colors';
import { GradientStop } from '@modules/customize';
import { ascComparator, controlValue, deployUrl, isSet, KeyboardEventKeyCode } from '@shared';
import { FillEditContext } from '../../data/fill-edit.context';
import { GradientControl } from '../../forms/gradient.control';
var GradientSelectorComponent = /** @class */ (function () {
    function GradientSelectorComponent(fillEditContext, sanitizer, cd) {
        this.fillEditContext = fillEditContext;
        this.sanitizer = sanitizer;
        this.cd = cd;
        this.overlayClick = false;
    }
    GradientSelectorComponent.prototype.trackStopControlFn = function (i, item) {
        return item.getId() || "index_" + i;
    };
    GradientSelectorComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.fillEditContext.startCustomizingGradient({
            source: this.source,
            control: this.control
        });
        this.fillEditContext.customizingGradient$.pipe(untilDestroyed(this)).subscribe(function (value) {
            _this.activeStopId = value ? value.activeStop : undefined;
            _this.cd.markForCheck();
        });
        combineLatest(this.fillEditContext.customizingGradient$, controlValue(this.control.controls.stops))
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var customizingGradient = _a[0];
            var activeStop = customizingGradient && isSet(customizingGradient.activeStop)
                ? _this.control.controls.stops.controls.find(function (item) { return item.getId() == customizingGradient.activeStop; })
                : undefined;
            var firstStop = _this.control.controls.stops.controls[0];
            if (!activeStop && firstStop) {
                _this.setCurrentStopControl(firstStop);
            }
            else if (!activeStop && customizingGradient && customizingGradient.activeStop) {
                _this.setCurrentStopControl(undefined);
            }
        });
        controlValue(this.control.controls.stops)
            .pipe(switchMap(function () {
            if (!_this.control.controls.stops.controls.length) {
                return of([]);
            }
            return combineLatest(_this.control.controls.stops.controls
                .sort(function (lhs, rhs) { return ascComparator(lhs.controls.position.value, rhs.controls.position.value); })
                .map(function (control) {
                var color = _this.cssColor(control.controls.color.value);
                var position = control.controls.position.value * 100 + "%";
                return of(color + " " + position);
            }));
        }), untilDestroyed(this))
            .subscribe(function (stopsCss) {
            var backgrounds = [];
            if (stopsCss.length) {
                backgrounds.push("linear-gradient(to right, " + stopsCss.join(', ') + ")");
            }
            backgrounds.push("#fff center center / 10px url(" + deployUrl('/assets/images/transparent.svg') + ")");
            _this.trackBackgroundSafe = _this.sanitizer.bypassSecurityTrustStyle(backgrounds.join(', '));
            _this.cd.markForCheck();
        });
        fromEvent(document, 'keydown')
            .pipe(filter(function (e) { return e.keyCode == KeyboardEventKeyCode.Backspace; }), untilDestroyed(this))
            .subscribe(function (e) {
            e.stopPropagation();
            var index = _this.control.controls.stops.controls.findIndex(function (item) { return item.getId() === _this.activeStopId; });
            if (_this.control.controls.stops.controls.length > 2 && !!_this.control.controls.stops.controls[index]) {
                var newCurrentStop = index > 0 ? _this.control.controls.stops.controls[index - 1] : undefined;
                _this.control.controls.stops.removeAt(index);
                _this.setCurrentStopControl(newCurrentStop);
            }
        });
    };
    GradientSelectorComponent.prototype.ngOnDestroy = function () {
        this.fillEditContext.finishCustomizingGradient();
    };
    GradientSelectorComponent.prototype.cssColor = function (color) {
        if (!isSet(color)) {
            return;
        }
        var variable = getColorVariable(color);
        if (variable) {
            return "var(--" + variable + ")";
        }
        return getColorHexStr(color);
    };
    GradientSelectorComponent.prototype.setCurrentStopControl = function (control) {
        this.fillEditContext.updateCustomizingGradient({
            activeStop: control ? control.getId() : undefined
        });
    };
    GradientSelectorComponent.prototype.createGradientStop = function (event) {
        var bounds = this.gradientStopTrack.nativeElement.getBoundingClientRect();
        var position = clamp((event.clientX - bounds.left) / bounds.width, 0, 1);
        var stop = new GradientStop({ position: position, color: '#FFFFFF' });
        stop.generateId();
        var control = this.control.controls.stops.appendControl(stop);
        this.setCurrentStopControl(control);
    };
    return GradientSelectorComponent;
}());
export { GradientSelectorComponent };
