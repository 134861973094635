var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import pickBy from 'lodash/pickBy';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { debounceTime } from 'rxjs/operators';
import { ProjectSettingsStore } from '@modules/all-project-settings';
import { CustomViewSettings } from '@modules/customize';
import { colorSchemaDefaults } from '@modules/customize-shared';
import { createFormFieldFactory, NumberFieldType } from '@modules/fields';
import { CurrentProjectStore } from '@modules/projects';
import { ThemeService } from '@modules/theme';
import { isSet } from '@shared';
import { PageEditForm } from './page-edit.form';
var PageEditComponent = /** @class */ (function () {
    function PageEditComponent(form, currentProjectStore, themeService, projectSettingsStore, cd) {
        this.form = form;
        this.currentProjectStore = currentProjectStore;
        this.themeService = themeService;
        this.projectSettingsStore = projectSettingsStore;
        this.cd = cd;
        this.viewSettingsUpdated = new EventEmitter();
        this.createField = createFormFieldFactory();
        this.placeholders = {};
        this.numberFieldTypes = NumberFieldType;
    }
    PageEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.form.init(this.viewSettings);
        this.form.valueChanges.pipe(debounceTime(200), untilDestroyed(this)).subscribe(function () {
            var result = _this.form.submit();
            _this.viewSettingsUpdated.emit(result);
        });
        this.projectSettingsStore
            .getAllSettings$()
            .pipe(untilDestroyed(this))
            .subscribe(function (settings) {
            _this.placeholders = __assign({}, colorSchemaDefaults, { maxWidth: '', paddingTop: 0, paddingRight: 0, paddingBottom: 0, paddingLeft: 0 }, (settings &&
                pickBy({
                    backgroundColor: settings.backgroundColor,
                    backgroundColorDark: settings.backgroundColorDark,
                    backgroundColor2: settings.backgroundColor2,
                    backgroundColor2Dark: settings.backgroundColor2Dark,
                    backgroundColor3: settings.backgroundColor3,
                    backgroundColor3Dark: settings.backgroundColor3Dark,
                    backgroundColor4: settings.backgroundColor4,
                    backgroundColor4Dark: settings.backgroundColor4Dark,
                    backgroundColor5: settings.backgroundColor5,
                    backgroundColor5Dark: settings.backgroundColor5Dark,
                    textColor: settings.textColor,
                    textColorDark: settings.textColorDark,
                    textColor2: settings.textColor2,
                    textColor2Dark: settings.textColor2Dark,
                    textColor3: settings.textColor3,
                    textColor3Dark: settings.textColor3Dark,
                    borderColor: settings.borderColor,
                    borderColorDark: settings.borderColorDark,
                    borderColor2: settings.borderColor2,
                    borderColor2Dark: settings.borderColor2Dark,
                    borderColor3: settings.borderColor3,
                    borderColor3Dark: settings.borderColor3Dark,
                    maxWidth: isSet(settings.maxWidth, true) ? String(settings.maxWidth) : undefined,
                    paddingTop: settings.padding.top,
                    paddingRight: settings.padding.right,
                    paddingBottom: settings.padding.bottom,
                    paddingLeft: settings.padding.left
                }, function (v) { return isSet(v, true); })));
            _this.cd.markForCheck();
        });
    };
    PageEditComponent.prototype.ngOnDestroy = function () { };
    return PageEditComponent;
}());
export { PageEditComponent };
