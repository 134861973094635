<app-view-context-token-popover-overlay
  [context]="context"
  [contextElement]="contextElement"
  [contextElementPath]="contextElementPath"
  [contextElementPaths]="contextElementPaths"
  [origin]="popoverOrigin || textarea_origin"
  [openOnFocus]="input_element"
  [forceOpened]="popoverForcedOpened"
  [extraSections]="extraSections"
  [search]="search$ | async"
  [selectFunction]="(hoverFunction$ | async) || (activeFunction$ | async)"
  (selected)="onTokenSelected($event)"
  (opened)="popoverOpened$.next(true); popoverOpened.emit()"
  (closed)="popoverOpened$.next(false); checkIfContextValue()"
></app-view-context-token-popover-overlay>

<div
  class="input input_textarea input_formula input_fill"
  [class.input_fill]="fill"
  [class.input_fill-vertical]="fillVertical"
  [class.input_header]="header"
  [class.input_footer]="displayJsType"
  [class.input_inline]="inline && false"
  [class.input_small]="small"
  contenteditable="true"
  role="textbox"
  spellcheck="false"
  [attr.autocorrect]="'off'"
  autocapitalize="off"
  [attr.placeholder]="placeholder"
  (keydown)="onKeydown($event)"
  (focus)="focus$.next(true); onFocus()"
  (blur)="focus$.next(false)"
  cdkOverlayOrigin
  #textarea_origin="cdkOverlayOrigin"
  #input_element
></div>

<div *ngIf="displayJsType" class="button-tags" [class.button-tags_border]="true" [class.button-tags_footer]="true">
  <div
    class="button-tag button-tags__item"
    [class.button-tags__item_fill]="false"
    [class.button-tag_darker]="darker"
    (click)="switchToJs.emit()"
  >
    <span class="button-tag__icon icon-console"></span>
    <span class="button-tag__label">JavaScript</span>
  </div>
</div>

<div *ngIf="!inputValueLoading && inputSet" class="input-footer" [class.input-footer_overlay]="true">
  <div class="input-footer__content">
    <app-input-edit-result
      [value]="inputValue"
      [error]="inputEffectiveError"
      [errorDescription]="inputExecuteErrorDescription"
      [focus]="focus$ | async"
      [showOnFocus]="resultShowOnFocus"
      (inputFocus)="focus()"
    ></app-input-edit-result>
  </div>
</div>
