import * as moment from 'moment';

import {
  DefaultType,
  EditableField,
  EditableFlexField,
  FieldType,
  getFieldDescriptionByType,
  ParameterField
} from '@modules/fields';
import { generateUUID, isSet } from '@shared';

import { ModelDbField } from '../../data/model-db-field';
import { ModelDescription, ModelField, ModelRelation } from '../../data/model-description';
import { ModelFlexField } from '../../data/model-flex-field';

// TODO: make common for actions and dbFields
export function getDefaultValue(field: EditableField): any {
  const fieldDescription = getFieldDescriptionByType(field.field);
  let result = fieldDescription.defaultValue;

  if (field.defaultType == DefaultType.Value) {
    result = field.defaultValue;
  } else if (field.defaultType == DefaultType.DatetimeNow) {
    result = moment().toISOString();
  } else if (field.defaultType == DefaultType.UUID) {
    result = generateUUID();
  }

  // if (field.null === false && result === null) {
  //   result = undefined;
  // }

  return result;
}

export function detectPrimaryKey(fields: string[]) {
  const idField = fields.find(item => item.toLowerCase() == 'id');

  if (idField) {
    return idField;
  }

  return fields[0];
}

export function modelDbFieldToParameterField(item: ModelDbField): ParameterField {
  const parameter = new ParameterField();

  parameter.name = item.name;
  parameter.verboseName = item.verboseName;
  parameter.description = item.description;
  parameter.field = item.field;
  parameter.required = item.required;
  parameter.defaultType = item.defaultType;
  parameter.defaultValue = item.defaultValue;
  parameter.placeholder = item.placeholder;
  parameter.validatorType = item.validatorType;
  parameter.validatorParams = item.validatorParams;
  parameter.params = item.params;
  parameter.updateFieldDescription();

  return parameter;
}

export function modelFieldToField(modelField: ModelField): EditableFlexField {
  return {
    name: modelField.item.name,
    verboseName: modelField.item.verboseName,
    description: modelField.item.description,
    field: modelField.item.field,
    params: modelField.item.params,

    ...(modelField.item instanceof ModelDbField
      ? {
          required: modelField.item.required,
          editable: modelField.item.editable,
          null: modelField.item.null,
          defaultType: modelField.item.defaultType,
          defaultValue: modelField.item.defaultValue
        }
      : {}),

    ...(modelField.item instanceof ModelFlexField
      ? {
          flex: true,
          query: modelField.item.query,
          code: modelField.item.code
        }
      : {})
  };
}

export function forceModelId(modelId: string, resource: string): string {
  if (!isSet(modelId)) {
    return modelId;
  }

  if (modelId.startsWith(`${resource}.`)) {
    return modelId;
  } else if (modelId.indexOf('.') != -1) {
    return modelId;
  } else {
    return [resource, modelId].join('.');
  }
}

export interface TraverseModelPathItem {
  modelDescription: ModelDescription;
  name: string;
  verboseName: string;
  field?: ModelDbField;
  relation?: ModelRelation;
  relatedModel?: ModelDescription;
}

export function traverseModelPath(
  modelDescription: ModelDescription,
  path: string[],
  modelDescriptions: ModelDescription[],
  acc: TraverseModelPathItem[] = []
): TraverseModelPathItem[] {
  if (!modelDescription || !path || !path.length) {
    return;
  }

  const name = path[0];
  const field = modelDescription.dbField(name);
  const relation = modelDescription.relation(name);

  if (field) {
    const pathResult: TraverseModelPathItem = {
      modelDescription: modelDescription,
      name: field.name,
      verboseName: field.verboseName || field.name,
      field: field
    };

    if (path.length == 1) {
      return [...acc, pathResult];
    } else if (field.field == FieldType.RelatedModel) {
      const modelId = field.params ? field.params['related_model']['model'] : undefined;
      const relatedModel = modelDescriptions.find(item => item.isSame(modelId));

      pathResult.relatedModel = relatedModel;

      return traverseModelPath(relatedModel, path.slice(1), modelDescriptions, [...acc, pathResult]);
    }
  } else if (relation) {
    const relatedModel = modelDescriptions.find(item => item.isSame(relation.relatedModel));
    const pathResult = {
      modelDescription: modelDescription,
      name: relation.name,
      verboseName: relatedModel.verboseNamePlural,
      relation: relation,
      relatedModel: relatedModel
    };

    if (path.length == 1) {
      return [...acc, pathResult];
    } else {
      return traverseModelPath(relatedModel, path.slice(1), modelDescriptions, [...acc, pathResult]);
    }
  }
}
