import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Subscription } from 'rxjs';

import { DataSourceType, ModelBasedDataSource } from '@modules/data-sources';
import { BaseField, InputValueType } from '@modules/fields';
import { ModelDescriptionStore } from '@modules/model-queries';
import { ModelDescription } from '@modules/models';
import { queryHasFrontendAutoParameters, queryHasResourceAutoParameters } from '@modules/parameters';
import { CurrentEnvironmentStore, Resource } from '@modules/projects';
import { ModelDescriptionQuery, QueryType } from '@modules/queries';
import { isSet, TypedChanges } from '@shared';

import { ModelOption } from '../../data/model-option';
import { ModelOptionSelectedEvent } from '../select-model-field/select-model-field.component';

@Component({
  selector: 'app-select-data-source-field',
  templateUrl: './select-data-source-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectDataSourceFieldComponent implements OnInit, OnDestroy, OnChanges {
  @Input() dataSource: ModelBasedDataSource;
  @Input() searchPlaceholder: string;
  @Input() nestedFieldsEnabled = true;
  @Input() relationsEnabled = true;
  @Input() onlyNestedFields = false;
  @Input() fieldsSelectEnabled = true;
  @Input() relationsSelectEnabled = true;
  @Input() aggregationsEnabled = false;
  @Input() lookupsSelect = false;
  @Input() parameterSelect = false;
  @Input() onlyVisible = false;
  @Input() optionsFilter: (option: ModelOption, path: ModelOption[]) => boolean;
  @Output() nameSelected = new EventEmitter<string>();
  @Output() selected = new EventEmitter<ModelOptionSelectedEvent>();

  modelDescription: ModelDescription;
  fields: BaseField[];
  onlyFields: BaseField[];
  lookupsEnabled = false;
  excludeLookupsEnabled = false;
  loading = true;
  fieldOptionsSubscription: Subscription;

  constructor(
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private modelDescriptionStore: ModelDescriptionStore,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<SelectDataSourceFieldComponent>): void {
    if (changes.dataSource) {
      this.initFieldOptions();
    }
  }

  getDataSourceParams(): {
    type: DataSourceType;
    query: ModelDescriptionQuery;
    resource: Resource;
    modelId: string;
  } {
    const type = this.dataSource ? this.dataSource.type : undefined;
    const query = type == DataSourceType.Query ? this.dataSource.query : undefined;
    const resource =
      type == DataSourceType.Query
        ? this.currentEnvironmentStore.resources.find(item => item.uniqueName == this.dataSource.queryResource)
        : undefined;
    const modelId =
      resource && query && query.queryType == QueryType.Simple && query.simpleQuery
        ? [resource.uniqueName, query.simpleQuery.model].join('.')
        : undefined;

    return {
      type: type,
      query: query,
      resource: resource,
      modelId: modelId
    };
  }

  initFieldOptions() {
    if (this.fieldOptionsSubscription) {
      this.fieldOptionsSubscription.unsubscribe();
      this.fieldOptionsSubscription = undefined;
    }

    this.modelDescription = undefined;
    this.fields = undefined;
    this.onlyFields = this.onlyVisible && this.dataSource ? this.dataSource.getVisibleColumns() : undefined;
    this.loading = true;
    this.cd.markForCheck();

    const { type, query, resource, modelId } = this.getDataSourceParams();

    if (isSet(modelId)) {
      this.fieldOptionsSubscription = this.modelDescriptionStore
        .getDetail(modelId)
        .pipe(untilDestroyed(this))
        .subscribe(
          modelDescription => {
            const getQuery = modelDescription ? modelDescription.getQuery : undefined;

            if (
              getQuery &&
              (queryHasResourceAutoParameters(resource, getQuery, modelDescription) ||
                queryHasFrontendAutoParameters(resource, getQuery, modelDescription))
            ) {
              this.modelDescription = modelDescription;
              this.fields = this.parameterSelect ? this.dataSource.queryParameters : undefined;
              this.lookupsEnabled = true;
              this.excludeLookupsEnabled = true;
            } else {
              this.modelDescription = undefined;
              this.fields = this.parameterSelect ? this.dataSource.queryParameters : this.getManualFilterFields();
              this.lookupsEnabled = false;
              this.excludeLookupsEnabled = false;
            }

            this.loading = false;
            this.cd.markForCheck();
          },
          () => {
            this.loading = false;
            this.cd.markForCheck();
          }
        );
    } else if (type == DataSourceType.Input || type == DataSourceType.Workflow) {
      this.modelDescription = undefined;
      this.fields = this.parameterSelect ? this.dataSource.queryParameters : this.dataSource.columns;
      this.lookupsEnabled = false;
      this.excludeLookupsEnabled = false;
      this.loading = false;
      this.cd.markForCheck();
    } else if (this.dataSource) {
      this.modelDescription = undefined;

      if (
        query &&
        (queryHasResourceAutoParameters(resource, query, undefined) ||
          queryHasFrontendAutoParameters(resource, query, undefined))
      ) {
        this.fields = this.parameterSelect
          ? [...this.dataSource.columns, ...this.dataSource.queryParameters]
          : this.dataSource.columns;
        this.lookupsEnabled = true;
        this.excludeLookupsEnabled = true;
      } else {
        this.fields = this.parameterSelect ? this.dataSource.queryParameters : this.getManualFilterFields();
        this.lookupsEnabled = false;
        this.excludeLookupsEnabled = false;
      }

      this.loading = false;
      this.cd.markForCheck();
    } else {
      this.modelDescription = undefined;
      this.fields = undefined;
      this.lookupsEnabled = false;
      this.excludeLookupsEnabled = false;
      this.loading = false;
      this.cd.markForCheck();
    }
  }

  getManualFilterFields() {
    return this.dataSource.columns.filter(column => {
      return this.dataSource.queryInputs.find(
        item => item.valueType == InputValueType.Filter && item.filterField == column.name
      );
    });
  }
}
