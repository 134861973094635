import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';

import { MarginControl, QrCodeElementItem } from '@modules/customize';
import { ElementConfigurationService } from '@modules/customize-configuration';
import { Input, Input as FieldInput } from '@modules/fields';
import { FieldInputControl } from '@modules/parameters';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';

@Injectable()
export class CustomizeBarQrCodeEditForm extends FormGroup {
  element: QrCodeElementItem;

  controls: {
    value: FieldInputControl;
    fill_color: FormControl;
    background_color: FormControl;
    visible_input: FieldInputControl;
    margin: MarginControl;
  };

  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private elementConfigurationService: ElementConfigurationService
  ) {
    super({
      value: new FieldInputControl({ path: ['value'] }, Validators.required),
      fill_color: new FormControl(''),
      background_color: new FormControl('white'),
      visible_input: new FieldInputControl({ path: ['value'] }),
      margin: new MarginControl()
    });
  }

  init(element: QrCodeElementItem, firstInit = false) {
    this.element = element;

    const value = {
      value: element.value ? element.value.serializeWithoutPath() : {},
      fill_color: element.fillColor,
      background_color: element.backgroundColor,
      visible_input: element.visibleInput ? element.visibleInput.serializeWithoutPath() : {},
      margin: element.margin
    };

    this.patchValue(value, { emitEvent: false });

    if (!firstInit) {
      this.markAsDirty();
    }
  }

  isConfigured(instance: QrCodeElementItem): boolean {
    return this.elementConfigurationService.isQrCodeConfigured(instance);
  }

  submit(): QrCodeElementItem {
    const value = this.value;
    const instance = cloneDeep(this.element) as QrCodeElementItem;

    instance.value = value['value'] ? new FieldInput().deserialize(value['value']) : undefined;
    instance.fillColor = value['fill_color'];
    instance.backgroundColor = value['background_color'];
    instance.margin = value['margin'];

    if (value['visible_input']) {
      instance.visibleInput = new Input().deserialize(value['visible_input']);
    } else {
      instance.visibleInput = undefined;
    }

    return instance;
  }
}
