import { Directive, Input } from '@angular/core';

import { AllProjectSettings } from '@modules/all-project-settings';
import { isSet } from '@shared';

import { ThemeOptions } from '../../data/theme-options';
import { ThemeOptionsDirective } from '../theme-options/theme-options.directive';

@Directive({
  selector: '[appProjectThemeOptions]'
})
export class ProjectThemeOptionsDirective extends ThemeOptionsDirective {
  @Input() projectSettings: AllProjectSettings;
  @Input('forceBackgroundColor2') backgroundColor2: string;
  @Input('forceBackgroundColor2Dark') backgroundColor2Dark: string;
  @Input('forceBackgroundColor3') backgroundColor3: string;
  @Input('forceBackgroundColor3Dark') backgroundColor3Dark: string;
  @Input('forceBackgroundColor4') backgroundColor4: string;
  @Input('forceBackgroundColor4Dark') backgroundColor4Dark: string;
  @Input('forceBackgroundColor5') backgroundColor5: string;
  @Input('forceBackgroundColor5Dark') backgroundColor5Dark: string;
  @Input('forceTextColor') textColor: string;
  @Input('forceTextColorDark') textColorDark: string;
  @Input('forceTextColor2') textColor2: string;
  @Input('forceTextColor2Dark') textColor2Dark: string;
  @Input('forceTextColor3') textColor3: string;
  @Input('forceTextColor3Dark') textColor3Dark: string;
  @Input('forceBorderColor') borderColor: string;
  @Input('forceBorderColorDark') borderColorDark: string;
  @Input('forceBorderColor2') borderColor2: string;
  @Input('forceBorderColor2Dark') borderColor2Dark: string;
  @Input('forceBorderColor3') borderColor3: string;
  @Input('forceBorderColor3Dark') borderColor3Dark: string;

  getThemeOptions(): ThemeOptions {
    if (this.projectSettings) {
      return {
        accentColor: this.projectSettings.accentColor,
        accentColorDark: this.projectSettings.accentColorDark,
        backgroundColor: this.projectSettings.backgroundColor,
        backgroundColorDark: this.projectSettings.backgroundColorDark,
        backgroundColor2: isSet(this.backgroundColor2) ? this.backgroundColor2 : this.projectSettings.backgroundColor2,
        backgroundColor2Dark: isSet(this.backgroundColor2Dark)
          ? this.backgroundColor2Dark
          : this.projectSettings.backgroundColor2Dark,
        backgroundColor3: isSet(this.backgroundColor3) ? this.backgroundColor3 : this.projectSettings.backgroundColor3,
        backgroundColor3Dark: isSet(this.backgroundColor3Dark)
          ? this.backgroundColor3Dark
          : this.projectSettings.backgroundColor3Dark,
        backgroundColor4: isSet(this.backgroundColor4) ? this.backgroundColor4 : this.projectSettings.backgroundColor4,
        backgroundColor4Dark: isSet(this.backgroundColor4Dark)
          ? this.backgroundColor4Dark
          : this.projectSettings.backgroundColor4Dark,
        backgroundColor5: isSet(this.backgroundColor5) ? this.backgroundColor5 : this.projectSettings.backgroundColor5,
        backgroundColor5Dark: isSet(this.backgroundColor5Dark)
          ? this.backgroundColor5Dark
          : this.projectSettings.backgroundColor5Dark,
        textColor: isSet(this.textColor) ? this.textColor : this.projectSettings.textColor,
        textColorDark: isSet(this.textColorDark) ? this.textColorDark : this.projectSettings.textColorDark,
        textColor2: isSet(this.textColor2) ? this.textColor2 : this.projectSettings.textColor2,
        textColor2Dark: isSet(this.textColor2Dark) ? this.textColor2Dark : this.projectSettings.textColor2Dark,
        textColor3: isSet(this.textColor3) ? this.textColor3 : this.projectSettings.textColor3,
        textColor3Dark: isSet(this.textColor3Dark) ? this.textColor3Dark : this.projectSettings.textColor3Dark,
        borderColor: isSet(this.borderColor) ? this.borderColor : this.projectSettings.borderColor,
        borderColorDark: isSet(this.borderColorDark) ? this.borderColorDark : this.projectSettings.borderColorDark,
        borderColor2: isSet(this.borderColor2) ? this.borderColor2 : this.projectSettings.borderColor2,
        borderColor2Dark: isSet(this.borderColor2Dark) ? this.borderColor2Dark : this.projectSettings.borderColor2Dark,
        borderColor3: isSet(this.borderColor3) ? this.borderColor3 : this.projectSettings.borderColor3,
        borderColor3Dark: isSet(this.borderColor3Dark) ? this.borderColor3Dark : this.projectSettings.borderColor3Dark,
        borderRadius: this.projectSettings.borderRadius,
        fontRegular: this.projectSettings.fontRegular,
        fontHeading: this.projectSettings.fontHeading,
        ...(this.projectSettings.elementWrapperStyles && {
          elementWrapperBorder: this.projectSettings.elementWrapperStyles.borderSettings,
          elementWrapperBorderRadius: this.projectSettings.elementWrapperStyles.borderRadius,
          elementWrapperShadow: this.projectSettings.elementWrapperStyles.shadow,
          elementWrapperMargin: this.projectSettings.elementWrapperStyles.margin
        })
      };
    } else {
      return super.getThemeOptions();
    }
  }
}
