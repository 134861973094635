import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import fromPairs from 'lodash/fromPairs';
import isArray from 'lodash/isArray';
import toPairs from 'lodash/toPairs';
import { Observable } from 'rxjs';
import { map, publishLast, refCount, switchMap, tap } from 'rxjs/operators';

import { ApiService } from '@modules/api';
import { filterItemsFromQueryParams } from '@modules/filter-utils';
import { Model, ModelDescription, ORDER_BY_PARAM, PAGE_PARAM, PER_PAGE_PARAM } from '@modules/models';
import { Resource, ResourceTypeItem } from '@modules/projects';
import { HttpMethod, HttpQuery, HttpQueryOptions, HttpQueryService, QueryType } from '@modules/queries';
import { isSet } from '@shared';

import { ModelResponse } from '../../data/model-response';
import { ResourceController } from '../../data/resource-controller';
import { SOURCE_FIELD_TYPE } from '../airtable-resource-controller/airtable-constants';
import {
  SMART_SUITE_ACCOUNT_ID_PARAM,
  SMART_SUITE_APPLICATION_ID_PARAM,
  SMART_SUITE_SYSTEM_SOLUTION_SLUG,
  SOURCE_FIELD_PARAMS
} from './smart-suite-constants';
import { SmartSuiteRecordListFilter, SmartSuiteRecordListSort } from './smart-suite-requests';
import {
  SmartSuiteApplicationResponse,
  SmartSuiteRecordListResponse,
  SmartSuiteSolutionResponse
} from './smart-suite-responses';
import { SmartSuiteApplicationFieldType } from './smart-suite-types';

@Injectable()
export class SmartSuiteResourceController extends ResourceController {
  private apiService: ApiService;
  private httpQueryService: HttpQueryService;
  private http: HttpClient;

  tokenName = 'api_key';

  init() {
    this.apiService = this.initService<ApiService>(ApiService);
    this.httpQueryService = this.initService<HttpQueryService>(HttpQueryService);
    this.http = this.initService<HttpClient>(HttpClient);
  }

  supportedQueryTypes(resource: ResourceTypeItem, queryClass: any): QueryType[] {
    return [QueryType.Simple];
  }

  getHeaders(options: { accountId: string; key: string }): { name: string; value: string }[] {
    return [
      { name: 'Authorization', value: `Token ${options.key}` },
      { name: 'Account-Id', value: options.accountId },
      { name: 'Content-Type', value: 'application/json' }
    ];
  }

  getSolutions(options: {
    accountId: string;
    key: string;
    includeHidden?: boolean;
  }): Observable<SmartSuiteSolutionResponse[]> {
    const query = new HttpQuery();

    query.method = HttpMethod.GET;
    query.url = `https://app.smartsuite.com/api/v1/solutions/`;
    query.headers = this.getHeaders(options);
    query.queryParams = [];

    return this.httpQueryService
      .requestBody<SmartSuiteSolutionResponse[]>(query)
      .pipe(map(result => result.filter(item => options.includeHidden || !item.hidden)));
  }

  getApplications(options: {
    accountId: string;
    key: string;
    solutionId: string;
  }): Observable<SmartSuiteApplicationResponse[]> {
    return this.getSolutions({ ...options, includeHidden: true }).pipe(
      switchMap(solutions => {
        const systemSolution = solutions.find(item => item.slug == SMART_SUITE_SYSTEM_SOLUTION_SLUG);
        const query = new HttpQuery();

        query.method = HttpMethod.GET;
        query.url = `https://app.smartsuite.com/api/v1/applications/`;
        query.headers = this.getHeaders(options);
        query.queryParams = [];

        return this.httpQueryService.requestBody<SmartSuiteApplicationResponse[]>(query).pipe(
          map(result => {
            return result.filter(item => {
              return [options.solutionId, ...(systemSolution ? [systemSolution.id] : [])].includes(item.solution);
            });
          })
        );
      })
    );
  }

  deserializeModel(record: Record<string, any>, collection: ModelDescription): Record<string, unknown> {
    const parseValue = (value: unknown | unknown[], array: boolean): unknown | unknown[] => {
      if (isArray(value)) {
        const result = (value as unknown[]).filter(item => isSet(item));
        return array ? result : result[0];
      } else {
        return array ? [value] : value;
      }
    };
    const parseValueKey = (
      value: Record<string, unknown> | Record<string, unknown>[],
      key: string,
      array: boolean
    ): unknown | unknown[] => {
      if (isArray(value)) {
        const result = (value as Record<string, unknown>[])
          .map(item => (item ? item[key] : null))
          .filter(item => isSet(item));
        return array ? result : result[0];
      } else {
        const result = value ? (value as Record<string, unknown>)[key] : null;
        return array ? [result] : result;
      }
    };
    const fields = toPairs(record).map(([name, value]) => {
      const field = collection.fields.find(i => i.name == name);
      const sourceType: SmartSuiteApplicationFieldType = field
        ? (field.item.params[SOURCE_FIELD_TYPE] as SmartSuiteApplicationFieldType)
        : undefined;

      if (
        [SmartSuiteApplicationFieldType.SingleSelectField, SmartSuiteApplicationFieldType.StatusField].includes(
          sourceType
        )
      ) {
        value = parseValueKey(value, 'value', false);
      } else if (
        [
          SmartSuiteApplicationFieldType.MultipleSelectField,
          SmartSuiteApplicationFieldType.TagsField,
          SmartSuiteApplicationFieldType.ChecklistField,
          SmartSuiteApplicationFieldType.ColorPickerField
        ].includes(sourceType)
      ) {
        const array =
          field.item.params && field.item.params[SOURCE_FIELD_PARAMS]
            ? field.item.params[SOURCE_FIELD_PARAMS]['entries_allowed'] == 'multiple'
            : false;
        value = parseValueKey(value, 'value', array);
      } else if (
        [
          SmartSuiteApplicationFieldType.LinkedRecordField,
          SmartSuiteApplicationFieldType.UserField,
          SmartSuiteApplicationFieldType.LinkField
        ].includes(sourceType)
      ) {
        const array =
          field.item.params && field.item.params[SOURCE_FIELD_PARAMS]
            ? field.item.params[SOURCE_FIELD_PARAMS]['entries_allowed'] == 'multiple'
            : false;
        value = parseValue(value, array);
      } else if (sourceType == SmartSuiteApplicationFieldType.RichTextAreaField) {
        value = parseValueKey(value, 'html', false);
      } else if (sourceType == SmartSuiteApplicationFieldType.FullNameField) {
        value = parseValueKey(value, 'sys_root', false);
      } else if (sourceType == SmartSuiteApplicationFieldType.IpAddressField) {
        value = parseValueKey(value, 'address', false);
      } else if (sourceType == SmartSuiteApplicationFieldType.PhoneField) {
        value = parseValueKey(value, 'sys_title', false);
      }

      return [name, value];
    });

    const missingFields = collection.fields
      .filter(item => !fields.find(([k]) => k == item.name))
      .map(item => [item.name, null]);

    return {
      ...fromPairs(fields),
      ...fromPairs(missingFields)
    };
  }

  serializeModel(instance: Model, collection: ModelDescription, fields?: string[]): Record<string, unknown> {
    const data = toPairs(instance.serialize(fields));
    // .filter(([k, v]) => ![AIRTABLE_PRIMARY_KEY, AIRTABLE_CREATED_TIME].includes(k))
    // .filter(([k, v]) => {
    //   const field = collection.fields.find((i) => i.uniqueName == k);
    //   return field && field.editable;
    // })
    // .filter(([k, v]) => v !== undefined)
    // .map(([name, value]) => {
    //   const field = collection.fields.find((i) => i.uniqueName == name);
    //   const sourceType: AirtableFieldType = field
    //     ? (field.params[SOURCE_FIELD_TYPE] as AirtableFieldType)
    //     : undefined;
    //
    //   if (sourceType && sourceType == AirtableFieldType.MultipleAttachments) {
    //     if (isSet(value)) {
    //       value = isArray(value)
    //         ? value.filter((item) => item !== null).map((item) => ({ url: item }))
    //         : [{ url: value }];
    //     } else {
    //       value = [];
    //     }
    //   } else if (sourceType && sourceType == AirtableFieldType.MultipleSelects) {
    //     value = isArray(value) ? value : [value];
    //     value = value.filter((item) => item !== null);
    //   } else if (sourceType && sourceType == AirtableFieldType.MultipleRecordLinks) {
    //     value = isArray(value) ? value : [value];
    //     value = value.filter((item) => item !== null);
    //   }
    //
    //   return [name, value];
    // });

    return fromPairs(data);
  }

  modelGet(
    resource: Resource,
    modelDescription: ModelDescription,
    params?: {},
    body?: {}
  ): Observable<ModelResponse.GetResponse> {
    const options: HttpQueryOptions = { resource: resource.uniqueName };
    const accountId = resource.params[SMART_SUITE_ACCOUNT_ID_PARAM] as string;
    const applicationId = modelDescription.params[SMART_SUITE_APPLICATION_ID_PARAM] as string;
    const defaultPageSizeLimit = 100;
    const page = params[PAGE_PARAM];
    const perPage = isSet(params[PER_PAGE_PARAM]) ? Math.min(params[PER_PAGE_PARAM], 100) : defaultPageSizeLimit;
    const queryParams: { name: string; value: string }[] = [];

    if (page) {
      queryParams.push({ name: 'offset', value: `${(page - 1) * perPage}` });
    }

    if (perPage) {
      queryParams.push({ name: 'limit', value: `${perPage}` });
    }

    const filter: SmartSuiteRecordListFilter = {
      operator: 'and',
      fields: []
    };
    const filterItems = filterItemsFromQueryParams(params);

    filterItems.forEach(filterItem => {
      const field = modelDescription.dbFields
        .filter(i => i.name != modelDescription.primaryKeyField)
        .find(i => i.name == filterItem.field);

      if (!field) {
        return;
      }

      filter.fields.push({
        comparison: 'is',
        field: field.name,
        value: filterItem.value
      });
    });

    let orderField: string = params[ORDER_BY_PARAM];
    let orderAscending = true;

    if (isSet(orderField) && orderField.startsWith('-')) {
      orderField = orderField.substring(1);
      orderAscending = false;
    }

    const sort: SmartSuiteRecordListSort = [];

    if (isSet(orderField)) {
      sort.push({
        direction: orderAscending ? 'asc' : 'desc',
        field: orderField
      });
    }

    const query = new HttpQuery();

    query.method = HttpMethod.POST;
    query.url = `https://app.smartsuite.com/api/v1/applications/${applicationId}/records/list/`;
    query.headers = this.getHeaders({ accountId: accountId, key: `{-${this.tokenName}-}` });
    query.queryParams = queryParams;
    query.body = { filter: filter, sort: sort };

    return this.httpQueryService.requestBody<SmartSuiteRecordListResponse>(query, options).pipe(
      map(result => {
        const limit = !result.limit ? defaultPageSizeLimit : result.limit;
        const data = {
          results: result.items.map(item => this.deserializeModel(item, modelDescription)),
          has_more: result.offset + limit < result.total,
          offset: result.offset,
          count: result.total,
          per_page: result.limit
        };

        return this.createGetResponse().deserialize(data, modelDescription.model, undefined);
      }),
      tap(response => {
        response.results.forEach(item => {
          item.deserializeAttributes(modelDescription.dbFields);
          item.setUp(modelDescription);
        });

        return response;
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  modelGetDetail(
    resource: Resource,
    modelDescription: ModelDescription,
    idField: string,
    id: number,
    params?: {}
  ): Observable<Model> {
    const options: HttpQueryOptions = { resource: resource.uniqueName };
    const accountId = resource.params[SMART_SUITE_ACCOUNT_ID_PARAM] as string;
    const applicationId = modelDescription.params[SMART_SUITE_APPLICATION_ID_PARAM] as string;
    const query = new HttpQuery();

    query.method = HttpMethod.GET;
    query.url = `https://app.smartsuite.com/api/v1/applications/${applicationId}/records/${id}/`;
    query.headers = this.getHeaders({ accountId: accountId, key: `{-${this.tokenName}-}` });

    return this.httpQueryService.requestBody(query, options).pipe(
      map(result => {
        const data = this.deserializeModel(result, modelDescription);
        const instance = this.createModel().deserialize(modelDescription.model, data);
        instance.setUp(modelDescription);
        instance.deserializeAttributes(modelDescription.dbFields);
        return instance;
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  modelCreate(
    resource: Resource,
    modelDescription: ModelDescription,
    modelInstance: Model,
    fields?: string[]
  ): Observable<Model> {
    const options: HttpQueryOptions = { resource: resource.uniqueName };
    const accountId = resource.params[SMART_SUITE_ACCOUNT_ID_PARAM] as string;
    const applicationId = modelDescription.params[SMART_SUITE_APPLICATION_ID_PARAM] as string;
    const query = new HttpQuery();

    query.method = HttpMethod.POST;
    query.url = `https://app.smartsuite.com/api/v1/applications/${applicationId}/records/`;
    query.headers = this.getHeaders({ accountId: accountId, key: `{-${this.tokenName}-}` });
    query.body = this.serializeModel(modelInstance, modelDescription, fields);

    return this.httpQueryService.requestBody(query, options).pipe(
      map(result => {
        const data = this.deserializeModel(result, modelDescription);
        const instance = this.createModel().deserialize(modelDescription.model, data);
        instance.setUp(modelDescription);
        instance.deserializeAttributes(modelDescription.dbFields);
        return instance;
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  modelUpdate(
    resource: Resource,
    modelDescription: ModelDescription,
    modelInstance: Model,
    fields?: string[]
  ): Observable<Model> {
    const options: HttpQueryOptions = { resource: resource.uniqueName };
    const accountId = resource.params[SMART_SUITE_ACCOUNT_ID_PARAM] as string;
    const applicationId = modelDescription.params[SMART_SUITE_APPLICATION_ID_PARAM] as string;
    const query = new HttpQuery();

    query.method = HttpMethod.PATCH;
    query.url = `https://app.smartsuite.com/api/v1/applications/${applicationId}/records/${modelInstance.primaryKey}/`;
    query.headers = this.getHeaders({ accountId: accountId, key: `{-${this.tokenName}-}` });
    query.body = this.serializeModel(modelInstance, modelDescription, fields);

    return this.httpQueryService.requestBody(query, options).pipe(
      map(result => {
        const data = this.deserializeModel(result, modelDescription);
        const instance = this.createModel().deserialize(modelDescription.model, data);
        instance.setUp(modelDescription);
        instance.deserializeAttributes(modelDescription.dbFields);
        return instance;
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  modelDelete(resource: Resource, modelDescription: ModelDescription, modelInstance: Model): Observable<Object> {
    const options: HttpQueryOptions = { resource: resource.uniqueName };
    const accountId = resource.params[SMART_SUITE_ACCOUNT_ID_PARAM] as string;
    const applicationId = modelDescription.params[SMART_SUITE_APPLICATION_ID_PARAM] as string;
    const query = new HttpQuery();

    query.method = HttpMethod.DELETE;
    query.url = `https://app.smartsuite.com/api/v1/applications/${applicationId}/records/${modelInstance.primaryKey}/`;
    query.headers = this.getHeaders({ accountId: accountId, key: `{-${this.tokenName}-}` });

    return this.httpQueryService
      .requestBody(query, options)
      .pipe(this.apiService.catchApiError(), publishLast(), refCount());
  }
}
