import { Injectable, NgZone } from '@angular/core';
import { FormControl, ValidatorFn } from '@angular/forms';
import { combineLatest, Observable } from 'rxjs';
import { debounceTime, delay, map } from 'rxjs/operators';

import { NotificationService } from '@common/notifications';
import { ActionService } from '@modules/action-queries';
import { AggregateFunc, DatasetGroupLookup } from '@modules/charts';
import { RawListViewSettingsColumn } from '@modules/customize';
import { ChartType } from '@modules/dashboard';
import { ChartWidgetDataSource, DataSourceType } from '@modules/data-sources';
import { Option } from '@modules/field-components';
import { DisplayField, DisplayFieldType, FieldType, ParameterArray, ParameterField } from '@modules/fields';
import { ModelDescriptionStore, ModelService } from '@modules/model-queries';
import { ModelDescription } from '@modules/models';
import {
  FieldInputControl,
  InputFieldProviderItem,
  inputFieldProviderItemsFromModelGet,
  parametersToProviderItems
} from '@modules/parameters';
import { ProjectSettingsService } from '@modules/project-settings';
import { CurrentEnvironmentStore } from '@modules/projects';
import { ListModelDescriptionQuery, QueryService } from '@modules/queries';
import { ResourceGeneratorResolver } from '@modules/resource-generators';
import { ResourceControllerService } from '@modules/resources';
import { controlValue, isSet } from '@shared';

import { DataSourceControls } from './data-source.control';
import { ModelDescriptionDataSourceControl } from './model-description-data-source';

export interface ChartColumnOption<T = any> extends Option<T> {
  date: boolean;
  time: boolean;
}

export const validateQueryXColumn = (queryXColumnLookupControl: string, required = true): ValidatorFn => {
  return control => {
    if (!control.parent) {
      return;
    }

    if (!isSet(control.value)) {
      if (required) {
        return { required: true };
      } else {
        return;
      }
    }

    const lookup = control.parent.controls[queryXColumnLookupControl].value as DatasetGroupLookup;

    if (!isSet(lookup)) {
      return { required: true };
    }
  };
};

export const validateQueryYFunc = (queryColumnControl: string): ValidatorFn => {
  return control => {
    if (!control.parent) {
      return;
    }

    const column = control.parent.controls[queryColumnControl].value as string;

    if (isSet(control.value) && control.value != AggregateFunc.Count && !isSet(column)) {
      return { required: true };
    } else if (!isSet(control.value) && !isSet(column)) {
      return { required: true };
    }
  };
};

@Injectable()
export class ChartWidgetDataSourceControl extends ModelDescriptionDataSourceControl<ChartWidgetDataSource> {
  public static instanceCls = ChartWidgetDataSource;
  resourceFieldParams: Object = { charts_resources: true };

  controls: DataSourceControls & {
    x_column: FormControl;
    x_column_lookup: FormControl;
    x_column_2: FormControl;
    x_column_2_lookup: FormControl;
    y_func: FormControl;
    y_column: FormControl;
  };

  groupCommonOptions = [
    { value: DatasetGroupLookup.Auto, name: 'Auto', valueDisplay: field => `${field} - Auto` },
    { value: DatasetGroupLookup.Plain, name: 'Plain value', valueDisplay: field => `${field} - Plain` }
  ];

  groupDateOptions = [
    { value: DatasetGroupLookup.DateDay, name: 'By Day', valueDisplay: field => `${field} - Day` },
    { value: DatasetGroupLookup.DateWeek, name: 'By Week', valueDisplay: field => `${field} - Week` },
    { value: DatasetGroupLookup.DateMonth, name: 'By Month', valueDisplay: field => `${field} - Month` },
    { value: DatasetGroupLookup.DateQuarter, name: 'By Quarter', valueDisplay: field => `${field} - Quarter` },
    { value: DatasetGroupLookup.DateYear, name: 'By Year', valueDisplay: field => `${field} - Year` }
  ];

  groupTimeOptions = [
    { value: DatasetGroupLookup.DateHour, name: 'By Hour', valueDisplay: field => `${field} - Hour` },
    { value: DatasetGroupLookup.DateMinute, name: 'By Minute', valueDisplay: field => `${field} - Minute` },
    { value: DatasetGroupLookup.DateSecond, name: 'By Second', valueDisplay: field => `${field} - Second` }
  ];

  xColumnLookupAllOptions = [...this.groupCommonOptions, ...this.groupDateOptions, ...this.groupTimeOptions];
  xColumnLookupDefaultOptions = [...this.groupCommonOptions];
  xColumnLookupDateOptions = [...this.groupCommonOptions, ...this.groupDateOptions, ...this.groupTimeOptions];
  xColumnLookupTimeOptions = [...this.groupCommonOptions, ...this.groupTimeOptions];

  yColumnFuncOptions: {
    value: AggregateFunc;
    name: string;
    hasField?: boolean;
    valueDisplay: (field: string) => string;
  }[] = [
    { value: AggregateFunc.Count, name: 'Number of records', valueDisplay: () => `Number of records` },
    { value: AggregateFunc.Sum, name: 'Sum of field', hasField: true, valueDisplay: field => `Sum of ${field}` },
    {
      value: AggregateFunc.Min,
      name: 'Minimum of field',
      hasField: true,
      valueDisplay: field => `Minimum of ${field}`
    },
    {
      value: AggregateFunc.Max,
      name: 'Maximum of field',
      hasField: true,
      valueDisplay: field => `Maximum of ${field}`
    },
    { value: AggregateFunc.Avg, name: 'Average of field', hasField: true, valueDisplay: field => `Average of ${field}` }
  ];

  // yFormatChartTypes = [
  //   ChartType.Bar,
  //   ChartType.StackedBar,
  //   ChartType.Line,
  //   ChartType.Radar,
  //   ChartType.PolarArea,
  //   ChartType.Scatter
  // ];

  axisChartTypes = [ChartType.Line, ChartType.Bar, ChartType.StackedBar, ChartType.Scatter, ChartType.Bubble];

  xColumn2ChartTypes = [ChartType.Bubble];

  // minMaxChartTypes = [ChartType.Bar, ChartType.StackedBar, ChartType.Line, ChartType.Scatter];
  //
  // percentageChartTypes = [ChartType.Bar, ChartType.StackedBar];
  //
  // smoothChartTypes = [ChartType.Line];
  //
  // datasetColorChartTypes = [
  //   ChartType.Bar,
  //   ChartType.StackedBar,
  //   ChartType.Line,
  //   ChartType.Radar,
  //   ChartType.PolarArea,
  //   ChartType.Scatter,
  //   ChartType.Bubble
  // ];

  constructor(
    modelDescriptionStore: ModelDescriptionStore,
    currentEnvironmentStore: CurrentEnvironmentStore,
    projectSettingsService: ProjectSettingsService,
    resourceControllerService: ResourceControllerService,
    resourceGeneratorResolver: ResourceGeneratorResolver,
    modelService: ModelService,
    actionService: ActionService,
    queryService: QueryService,
    notificationService: NotificationService,
    zone: NgZone
  ) {
    super(
      modelDescriptionStore,
      currentEnvironmentStore,
      projectSettingsService,
      resourceControllerService,
      resourceGeneratorResolver,
      modelService,
      actionService,
      queryService,
      notificationService,
      zone,
      {
        x_column: new FormControl('', validateQueryXColumn('x_column_lookup')),
        x_column_lookup: new FormControl(DatasetGroupLookup.Auto),
        x_column_2: new FormControl('', validateQueryXColumn('x_column_2_lookup', false)),
        x_column_2_lookup: new FormControl(DatasetGroupLookup.Auto),
        y_func: new FormControl('', validateQueryYFunc('y_column')),
        y_column: new FormControl('')
      }
    );

    this.controls.y_column.valueChanges.pipe(delay(0)).subscribe(() => {
      this.controls.y_func.updateValueAndValidity();
    });

    this.controls.y_func.updateValueAndValidity();

    this.controls.x_column_lookup.valueChanges.pipe(delay(0)).subscribe(() => {
      this.controls.x_column.updateValueAndValidity();
    });

    this.controls.x_column_2_lookup.valueChanges.pipe(delay(0)).subscribe(() => {
      this.controls.x_column_2.updateValueAndValidity();
    });

    this.inputFieldProvider.getFields$().subscribe(() => {
      this.controls.query_inputs.updateValueAndValidity();
    });
  }

  clearExtraControls() {
    this.controls.x_column.patchValue('');
    this.controls.x_column_lookup.patchValue('');
    this.controls.x_column_2.patchValue('');
    this.controls.x_column_2_lookup.patchValue('');
    this.controls.y_func.patchValue('');
    this.controls.y_column.patchValue('');
  }

  deserializeExtraControls(instance: ChartWidgetDataSource) {
    if (instance) {
      this.controls.x_column.patchValue(instance.xColumn);
      this.controls.x_column_lookup.patchValue(instance.xLookup);
      this.controls.x_column_2.patchValue(instance.xColumn2);
      this.controls.x_column_2_lookup.patchValue(instance.xLookup2);
      this.controls.y_func.patchValue(instance.yFunc);
      this.controls.y_column.patchValue(instance.yColumn);
    } else {
      this.clearExtraControls();
    }
  }

  onModelDescriptionChange(modelDescription: ModelDescription) {
    super.onModelDescriptionChange(modelDescription);

    this.clearExtraControls();
  }

  serialize(): ChartWidgetDataSource {
    const result = super.serialize();

    if (!result) {
      return;
    }

    result.xColumn = this.controls.x_column.value;
    result.xLookup = this.controls.x_column_lookup.value;
    result.xColumn2 = this.controls.x_column_2.value;
    result.xLookup2 = this.controls.x_column_2_lookup.value;
    result.yFunc = this.controls.y_func.value;
    result.yColumn = this.controls.y_column.value;

    return result;
  }

  getInputFieldProvider$(): Observable<InputFieldProviderItem[]> {
    return combineLatest(
      controlValue<DataSourceType>(this.controls.type),
      this.getResource$(),
      this.getModelDescription$(),
      controlValue<ParameterField[]>(this.controls.query_parameters),
      controlValue<ListModelDescriptionQuery>(this.controls.query),
      controlValue<RawListViewSettingsColumn[]>(this.controls.columns)
    ).pipe(
      debounceTime(60),
      map(([type, resource, modelDescription, parameters, getQuery, columns]): InputFieldProviderItem[] => {
        return [
          ...parametersToProviderItems(parameters),
          ...inputFieldProviderItemsFromModelGet(resource, modelDescription, getQuery, columns, type)
        ];
      })
    );
  }

  isListQuery() {
    return true;
  }

  getChartColumnOptions$(): Observable<ChartColumnOption<string>[]> {
    return controlValue<DisplayField[]>(this.controls.columns).pipe(
      map(columnsValue => {
        if (!columnsValue) {
          return [];
        }

        return columnsValue
          .filter(item => item.type == DisplayFieldType.Base)
          .map(item => {
            return {
              value: item.name,
              name: item.verboseName || item.name,
              date: item.field == FieldType.DateTime,
              time: item.field == FieldType.Time
            };
          });
      })
    );
  }
}
