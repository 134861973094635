import { FormControl, FormGroup } from '@angular/forms';

import { Option } from '@modules/field-components';
import { Input } from '@modules/fields';
import { FieldInputControl } from '@modules/parameters';
import { Border, BorderPosition, BorderStyle, FillType } from '@modules/views';
import { isSet } from '@shared';

import { ColorControl } from './color.control';

export class BorderControl extends FormGroup {
  instance: Border;

  controls: {
    color: ColorControl;
    color_input_enabled: FormControl;
    color_input: FieldInputControl;
    thickness: FormControl;
    style: FormControl;
    position: FormControl;
    enabled: FormControl;
    enabled_input: FieldInputControl;
  };

  styleOptions: Option<BorderStyle>[] = [
    {
      value: BorderStyle.Solid,
      name: 'Solid'
    },
    {
      value: BorderStyle.Dashed,
      name: 'Dashed'
    },
    {
      value: BorderStyle.Dotted,
      name: 'Dotted'
    },
    {
      value: BorderStyle.Double,
      name: 'Double'
    },
    {
      value: BorderStyle.Groove,
      name: 'Groove'
    },
    {
      value: BorderStyle.Ridge,
      name: 'Ridge'
    },
    {
      value: BorderStyle.Inset,
      name: 'Inset'
    },
    {
      value: BorderStyle.Outset,
      name: 'Outset'
    }
  ];

  positionOptions: Option<BorderPosition>[] = [
    {
      value: BorderPosition.Inside,
      name: 'Inside'
    },
    {
      value: BorderPosition.Center,
      name: 'Center'
    },
    {
      value: BorderPosition.Outside,
      name: 'Outside'
    }
  ];

  constructor(state: Partial<Border> = {}) {
    super({
      color: new ColorControl(isSet(state.color) ? state.color : {}),
      color_input_enabled: new FormControl(isSet(state.colorInput) ? !!state.colorInput : false),
      color_input: new FieldInputControl({ path: ['value'] }),
      thickness: new FormControl(isSet(state.thickness) ? state.thickness : 1),
      style: new FormControl(isSet(state.style) ? state.style : BorderStyle.Solid),
      position: new FormControl(isSet(state.position) ? state.position : BorderPosition.Inside),
      enabled: new FormControl(isSet(state.enabled) ? state.enabled : true),
      enabled_input: new FieldInputControl({ path: ['value'] })
    });
  }

  deserialize(value: Border, options: { emitEvent?: boolean } = {}) {
    this.instance = value;

    if (value.color) {
      this.controls.color.deserialize(value.color, { emitEvent: options.emitEvent });
    }

    this.controls.color_input_enabled.patchValue(!!value.colorInput, { emitEvent: options.emitEvent });
    this.controls.color_input.patchValue(value.colorInput ? value.colorInput.serializeWithoutPath() : {}, {
      emitEvent: options.emitEvent
    });

    this.controls.thickness.patchValue(value.thickness, { emitEvent: options.emitEvent });
    this.controls.style.patchValue(value.style, { emitEvent: options.emitEvent });
    this.controls.position.patchValue(value.position, { emitEvent: options.emitEvent });
    this.controls.enabled.patchValue(value.enabled, { emitEvent: options.emitEvent });
    this.controls.enabled_input.patchValue(value.enabledInput ? value.enabledInput.serializeWithoutPath() : {});
  }

  getInstance(instance?: Border): Border {
    if (!instance) {
      instance = new Border();
    }

    if (this.controls.color_input_enabled.value) {
      instance.color = this.controls.color.getInstance(instance.color);
      instance.colorInput = this.controls.color_input.value
        ? new Input().deserialize(this.controls.color_input.value)
        : undefined;
    } else {
      instance.color = this.controls.color.getInstance(instance.color);
      instance.colorInput = undefined;
    }

    instance.thickness = this.controls.thickness.value;
    instance.style = this.controls.style.value;
    instance.position = this.controls.position.value;
    instance.enabled = this.controls.enabled.value;
    instance.enabledInput = this.controls.enabled_input.value
      ? new Input().deserialize(this.controls.enabled_input.value)
      : undefined;

    return instance;
  }

  serialize(): Border {
    return this.getInstance(this.instance);
  }
}
