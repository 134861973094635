<app-field-wrapper
  *ngIf="!readonly"
  [formGroup]="form"
  [form]="form"
  [field]="field"
  [readonly]="readonly"
  [requiredAsterisk]="requiredAsterisk"
  [label]="label"
  [labelStyle]="elementStyles?.labelStyle"
  [labelStyleGlobal]="labelStyleGlobal"
  [labelAdditional]="labelAdditional"
  [labelAdditionalStyle]="elementStyles?.labelAdditionalStyle"
  [labelAdditionalStyleGlobal]="labelAdditionalStyleGlobal"
  [errors]="errors"
  [truncate]="truncate"
  [manualMargin]="manualMargin"
  [labelButtons]="labelButtons"
  [tooltip]="tooltip"
  [idToken]="idToken"
  [theme]="field.params['theme']"
>
  <input [formControlName]="field.name" hidden />

  <div class="tags">
    <a
      *ngFor="let item of languages"
      href="javascript:void(0)"
      class="tags__item"
      [class.tags__item_selected]="currentLanguageCode == item.code"
      (click)="selectLanguage(item.code)"
    >
      {{ item.name }}
    </a>
  </div>
  <div *ngFor="let item of languages">
    <input
      class="input"
      type="text"
      [class.input_error]="(form | appFormFieldErrors: field.name)?.length"
      [id]="(currentLanguageCode == item.code ? field.name : '') | appUniqueId: idToken"
      [value]="values[item.code] || ''"
      [hidden]="currentLanguageCode != item.code"
      (keyup)="updateValue(item.code, $event.srcElement.value)"
      (change)="updateValue(item.code, $event.srcElement.value)"
    />
  </div>
</app-field-wrapper>
<app-field-wrapper
  *ngIf="readonly"
  [form]="form"
  [field]="field"
  [readonly]="readonly"
  [requiredAsterisk]="requiredAsterisk"
  [label]="label"
  [labelStyle]="elementStyles?.labelStyle"
  [labelStyleGlobal]="labelStyleGlobal"
  [labelAdditional]="labelAdditional"
  [labelAdditionalStyle]="elementStyles?.labelAdditionalStyle"
  [labelAdditionalStyleGlobal]="labelAdditionalStyleGlobal"
  [errors]="errors"
  [truncate]="truncate"
  [manualMargin]="manualMargin"
  [labelButtons]="labelButtons"
  [tooltip]="tooltip"
  [idToken]="idToken"
  [theme]="field.params['theme']"
>
  {{ formatEmpty(str) }}
</app-field-wrapper>
