import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import camelCase from 'lodash/camelCase';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { of, timer } from 'rxjs';
import { debounce, map, switchMap } from 'rxjs/operators';

import { ViewContext, ViewContextElement } from '@modules/customize';
import { CodeFieldComponent } from '@modules/field-components';
import {
  applyParamInput$,
  AutoFieldComponent,
  createFormFieldFactory,
  getJavaScriptSyntaxError,
  Input as FieldInput,
  InputError,
  InputValueType
} from '@modules/fields';
import { contextToFormulaValue, FieldInputControl, transformFormulaElementAccessors } from '@modules/parameters';
import { controlValue, EMPTY, isSet } from '@shared';

import { FormulaSection } from '../../data/formula-section';
import { FormulaInsert } from '../../data/formula-token';
import { FormulaToken } from '../../data/formula-token';
import { ViewContextTokenPopoverOverlayComponent } from '../view-context-token-popover-overlay/view-context-token-popover-overlay.component';

class ErrorResult {
  constructor(public readonly error: Error) {}
}

@Component({
  selector: 'app-input-edit-js-value',
  templateUrl: './input-edit-js-value.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputEditJsValueComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() itemForm: FieldInputControl;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  // If need to specify inner path inside contextElement (relative to contextElement)
  @Input() contextElementPath: (string | number)[];
  // If need multiple path inside contextElement (relative to contextElement + contextElementPath)
  @Input() contextElementPaths: (string | number)[][];
  @Input() staticValueDisabled = false;
  @Input() placeholder = 'return 2 * 3;';
  @Input() displayValueTypesEnabled = true;
  @Input() extraSections: FormulaSection[] = [];
  @Input() classes: string[] = [];
  @Input() fill = false;
  @Input() fillVertical = false;
  @Input() header = false;
  @Input() inline = false;
  @Input() dark = false;
  @Input() darker = false;
  @Input() resultShowOnFocus = true;
  @Input() popoverOrigin: CdkOverlayOrigin;
  @Input() popoverForcedOpened: boolean;
  @Output() switchToDefault = new EventEmitter<string>();
  @Output() switchToFormula = new EventEmitter<string>();

  @ViewChild('js_field') jsField: AutoFieldComponent;
  @ViewChild(ViewContextTokenPopoverOverlayComponent)
  tokenPopoverOverlayComponent: ViewContextTokenPopoverOverlayComponent;

  createField = createFormFieldFactory();
  focused = false;
  inputValueLoading = true;
  inputSet = false;
  inputValue: any;
  inputError: string;
  inputErrorDescription: string;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {
    controlValue(this.itemForm.controls.js_value)
      .pipe(
        map((value, index) => ({ value, index })),
        debounce(({ index }) => timer(index == 0 ? 0 : 600)),
        switchMap<
          { value: any; index: number },
          { isSet: boolean; result?: any; error?: string; errorDescription?: string }
        >(({ value }) => {
          if (isSet(value)) {
            const syntaxError = getJavaScriptSyntaxError(value);
            if (syntaxError) {
              return of({ isSet: isSet(value), error: syntaxError });
            }
          }

          return of({ isSet: isSet(value), result: undefined });
          // const input = new FieldInput();
          //
          // input.path = ['value'];
          // input.valueType = InputValueType.Js;
          // input.jsValue = value;
          //
          // return applyParamInput$(input, {
          //   context: this.context,
          //   contextElement: this.contextElement,
          //   raiseErrors: true,
          //   errorValueFn: error => new ErrorResult(error),
          //   debounce: 200
          // }).pipe(
          //   map(result => {
          //     if (result instanceof ErrorResult) {
          //       const errorDescription = result.error instanceof InputError ? result.error.error.message : undefined;
          //       return { isSet: isSet(value), error: result.error.message, errorDescription: errorDescription };
          //     } else {
          //       return { isSet: isSet(value), result: result !== EMPTY ? result : undefined };
          //     }
          //   })
          // );
        }),

        untilDestroyed(this)
      )
      .subscribe(result => {
        this.inputValueLoading = false;
        this.inputSet = result.isSet;
        this.inputValue = result.result;
        this.inputError = result.error;
        this.inputErrorDescription = result.errorDescription;
        this.cd.markForCheck();
      });
  }

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    if (
      this.jsField &&
      this.jsField.dynamicComponent.currentComponent &&
      this.jsField.dynamicComponent.currentComponent.instance instanceof CodeFieldComponent
    ) {
      this.jsField.dynamicComponent.currentComponent.instance
        .getFocus$()
        .pipe(untilDestroyed(this))
        .subscribe(value => {
          this.focused = value;
          this.cd.markForCheck();
        });
    }
  }

  focus() {
    if (this.jsField) {
      this.jsField.focus();
    }
  }

  openTokenPopover() {
    if (this.tokenPopoverOverlayComponent) {
      this.tokenPopoverOverlayComponent.open();
    }
  }

  closeTokenPopover() {
    if (this.tokenPopoverOverlayComponent) {
      this.tokenPopoverOverlayComponent.close();
    }
  }

  insertJsToken(item: FormulaInsert | FormulaToken) {
    if (!isSet(item.insert) && !isSet(item.formula)) {
      return;
    }

    if (
      this.jsField &&
      this.jsField.dynamicComponent.currentComponent &&
      this.jsField.dynamicComponent.currentComponent.instance instanceof CodeFieldComponent
    ) {
      const ace = this.jsField.dynamicComponent.currentComponent.instance.ace;
      const selectionRange = ace.editor.selection.getRange();
      let insert: string;

      if (isSet(item.formula)) {
        insert = item.formula;
      } else {
        const formulaValue = contextToFormulaValue(item.insert);
        insert = transformFormulaElementAccessors(formulaValue, this.context, false);
      }

      const insertRow = selectionRange.start.row;
      const insertLast = isSet(item['label']) ? (item as FormulaToken).label : item.insert[item.insert.length - 1];
      const insertRowValue = ace.editor.session.getLine(insertRow);
      const varName = typeof insertLast == 'number' ? `item${insertLast}` : camelCase(String(insertLast));
      const prefixMatch = insertRowValue.match(/^([\s\t]+)/);
      const prefix = prefixMatch ? prefixMatch[1] : '';

      ace.editor.session.insert({ row: insertRow, column: 0 }, `${prefix}let ${varName} = ${insert};\n`);
    }
  }

  onJsTokenSelected(item: FormulaInsert | FormulaToken) {
    if (isSet(item.insert) || isSet(item.formula)) {
      this.insertJsToken(item);
    }
  }
}
