<app-customize-bar-header
  [title]="'Buttons'"
  [subtitle]="'Appearance'"
  [backEnabled]="true"
  [backPopSettingsComponent]="false"
  (backClick)="back()"
>
  <ng-container actions>
    <div class="button-group" [style.margin]="'-5px 0'">
      <div
        class="button-group__item"
        [class.button-group__item_active]="themeService.isDefaultTheme"
        (click)="themeService.theme = 'default'"
      >
        <span class="button-group__item-icon icon-sun"></span>
      </div>

      <div
        class="button-group__item"
        [class.button-group__item_active]="themeService.isDarkTheme"
        (click)="themeService.theme = 'dark'"
      >
        <span class="button-group__item-icon icon-moon"></span>
      </div>
    </div>
  </ng-container>
</app-customize-bar-header>

<div class="sidebar__content">
  <app-sidebar-section>
    <div class="sidebar__element">
      <app-sidebar-field [label]="'style'">
        <div class="sidebar-image-toggle sidebar-image-toggle_cols_3">
          <div *ngFor="let item of styleOptions" class="sidebar-image-toggle__col">
            <div
              class="sidebar-image-toggle__item"
              [class.sidebar-image-toggle__item_active]="item.value === styleControl.value"
              (click)="styleControl.patchValue(item.value)"
            >
              <div
                class="sidebar-image-toggle__item-inner"
                [style.background-image]="'/assets/images/buttons/' + item.image + '.svg' | appDeployCssUrl"
              ></div>
              <div class="sidebar-image-toggle__item-label">
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
      </app-sidebar-field>
    </div>

    <div class="sidebar__element">
      <app-styles-action-element
        *ngIf="styleControl.value == tintStyles.Primary"
        [control]="context.controls.appearance.controls.action_element_styles_primary"
      ></app-styles-action-element>

      <app-styles-action-element
        *ngIf="styleControl.value == tintStyles.Default"
        [control]="context.controls.appearance.controls.action_element_styles_default"
      ></app-styles-action-element>

      <app-styles-action-element
        *ngIf="styleControl.value == tintStyles.Transparent"
        [control]="context.controls.appearance.controls.action_element_styles_transparent"
      ></app-styles-action-element>
    </div>
  </app-sidebar-section>
</div>

<app-sidebar-submit
  [saveDisabled]="!(hasChanges$ | async)"
  [saveLoading]="submitLoading"
  [saveLabel]="'Save changes'"
  [cancelDisabled]="!(hasChanges$ | async)"
  [cancelLabel]="'Reset changes'"
  [hidden]="!(hasChanges$ | async)"
  (cancelClick)="cancel()"
  (submitClick)="submit()"
>
</app-sidebar-submit>
