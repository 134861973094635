import { ChangeDetectorRef, EventEmitter, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import * as Color from 'color';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { debounceTime } from 'rxjs/operators';
import { AppConfigService } from '@core';
import { colors, parseColor } from '@modules/colors';
import { MapSettings, ViewContext, ViewContextElement } from '@modules/customize';
import { applyParamInput } from '@modules/fields';
import { ITEM_OUTPUT } from '@modules/list';
import { ModelDescription } from '@modules/models';
import { ThemeService } from '@modules/theme';
import { EMPTY, isColorHex, numberToHex } from '@shared';
var MapMarkerComponent = /** @class */ (function () {
    function MapMarkerComponent(themeService, appConfigService, cd) {
        this.themeService = themeService;
        this.appConfigService = appConfigService;
        this.cd = cd;
        this.windowOpen = false;
        this.visibleColumns = [];
        this.theme = false;
        this.modelUpdated = new EventEmitter();
        this.openWindow = new EventEmitter();
        this.closeWindow = new EventEmitter();
    }
    MapMarkerComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.themeService.theme$.pipe(untilDestroyed(this)).subscribe(function () { return _this.updateStyles(); });
        this.context.outputValues$.pipe(debounceTime(10), untilDestroyed(this)).subscribe(function () { return _this.updateStyles(); });
    };
    MapMarkerComponent.prototype.ngOnDestroy = function () { };
    MapMarkerComponent.prototype.ngOnChanges = function (changes) {
        if (changes['settings']) {
            this.updateStyles();
        }
    };
    MapMarkerComponent.prototype.updateStyles = function () {
        this.icon = this.getIcon();
        this.cd.markForCheck();
    };
    MapMarkerComponent.prototype.getColor = function () {
        var _a;
        if (this.settings.markerColorInput) {
            try {
                var value = applyParamInput(this.settings.markerColorInput, {
                    context: this.context,
                    contextElement: this.contextElement,
                    localContext: (_a = {},
                        _a[ITEM_OUTPUT] = this.item.item.model.getAttributes(),
                        _a)
                });
                if (value !== EMPTY) {
                    return value;
                }
            }
            catch (e) { }
        }
        else {
            return this.settings.markerColor;
        }
    };
    MapMarkerComponent.prototype.getSize = function () {
        var _a;
        if (this.settings.markerSizeInput) {
            try {
                var value = applyParamInput(this.settings.markerSizeInput, {
                    context: this.context,
                    contextElement: this.contextElement,
                    localContext: (_a = {},
                        _a[ITEM_OUTPUT] = this.item.item.model.getAttributes(),
                        _a)
                });
                if (value !== EMPTY) {
                    return value;
                }
            }
            catch (e) { }
        }
        else {
            return this.settings.markerSize;
        }
    };
    MapMarkerComponent.prototype.getIcon = function () {
        var markerColor = this.getColor() || this.accentColor;
        var markerSize = this.getSize();
        var theme = this.themeService.theme;
        var colorHex = isColorHex(markerColor)
            ? markerColor.substring(1)
            : colors.filter(function (item) { return item.name == markerColor; }).map(function (item) { return numberToHex(item.hex); })[0];
        var clr = colorHex ? parseColor('#' + colorHex) : undefined;
        var isDark = !clr || clr.contrast(Color('white')) >= 2;
        var contrastColor = !clr || isDark ? 'FFF' : clr.darken(0.8).hex().toUpperCase().substring(1);
        var size = markerSize || 26;
        var url = new URL(this.appConfigService.serverBaseUrl + "/assets/marker.svg");
        if (colorHex) {
            url.searchParams.append('color', colorHex);
        }
        if (contrastColor) {
            url.searchParams.append('contrast_color', contrastColor);
        }
        if (theme == 'dark') {
            url.searchParams.append('dark', '1');
        }
        if (size) {
            url.searchParams.append('size', size.toString());
        }
        return {
            url: url.toString(),
            anchor: {
                x: size * 0.5,
                y: size * 0.5
            }
        };
    };
    return MapMarkerComponent;
}());
export { MapMarkerComponent };
