import cloneDeep from 'lodash/cloneDeep';
import pickBy from 'lodash/pickBy';

import { BorderSettings, Corners, FillSettings, FillType, Margin } from '@modules/customize';
import { Input } from '@modules/fields';
import { generateAlphanumeric, isSet } from '@shared';

import { MenuItem } from './menu-item';
import { deserializeMenuItem } from './menu-items';

export enum MenuBlockLayout {
  LeftThin = 'left_thin',
  LeftWide = 'left_wide',
  TopThin = 'top_thin',
  TopContentThin = 'top_content_thin'
}

export namespace MenuBlockLayouts {
  export const types = MenuBlockLayout;

  export function isHorizontal(layout: MenuBlockLayout): boolean {
    return [MenuBlockLayout.TopThin, MenuBlockLayout.TopContentThin].includes(layout);
  }

  export function isVertical(layout: MenuBlockLayout): boolean {
    return !isHorizontal(layout);
  }

  export function isLeft(layout: MenuBlockLayout) {
    return [MenuBlockLayout.LeftThin, MenuBlockLayout.LeftWide].includes(layout);
  }

  export function isTop(layout: MenuBlockLayout) {
    return [MenuBlockLayout.TopThin, MenuBlockLayout.TopContentThin].includes(layout);
  }

  export function isPrimary(layout: MenuBlockLayout) {
    return [MenuBlockLayout.LeftThin, MenuBlockLayout.TopThin, MenuBlockLayout.TopContentThin].includes(layout);
  }

  export function getDefaultColor(layout: MenuBlockLayout): string {
    if ([MenuBlockLayout.LeftThin, MenuBlockLayout.TopThin].includes(layout)) {
      return '#2b50ed';
    }
  }

  export function getImageMaxSize(layout: MenuBlockLayout): number {
    if (isTop(layout)) {
      return 40;
    } else {
      return 50;
    }
  }
}

export class MenuBlock {
  public uid: string;
  public enabled = true;
  public enabledInput: Input;
  public layout: MenuBlockLayout;
  public startItems: MenuItem[] = [];
  public centerItems: MenuItem[] = [];
  public endItems: MenuItem[] = [];
  public fillSettings: FillSettings;
  public borderSettings: BorderSettings;
  public width: number;
  public height: number;
  public borderRadius: Corners = {};
  public padding: Margin = {};

  deserialize(data: Object): MenuBlock {
    this.uid = data['uid'];
    this.layout = data['layout'];
    this.width = data['width'];
    this.height = data['height'];

    if (data['enabled'] !== undefined) {
      this.enabled = data['enabled'];
    }

    if (data['enabled_input']) {
      this.enabledInput = new Input().deserialize(data['enabled_input']);
    }

    if (data['start_items']) {
      this.startItems = data['start_items'].map(item => deserializeMenuItem(item)).filter(item => item != undefined);
    }

    if (data['center_items']) {
      this.centerItems = data['center_items'].map(item => deserializeMenuItem(item)).filter(item => item != undefined);
    }

    if (data['end_items']) {
      this.endItems = data['end_items'].map(item => deserializeMenuItem(item)).filter(item => item != undefined);
    }

    if (data['fill_settings']) {
      this.fillSettings = new FillSettings().deserialize(data['fill_settings']);
    } else if (data['fill'] || data['fill_dark']) {
      this.fillSettings = new FillSettings().deserialize({
        fill: data['fill'],
        fill_dark: data['fill_dark']
      });
    } else if (isSet(data['accent_color'])) {
      // Backward compatibility
      this.fillSettings = new FillSettings().deserialize({
        fill: {
          type: FillType.Color,
          color: data['accent_color']
        },
        fill_dark: {
          type: FillType.Color,
          color: data['accent_color']
        }
      });
    }

    if (data['border_settings']) {
      this.borderSettings = new BorderSettings().deserialize(data['border_settings']);
    }

    if (data['border_radius']) {
      this.borderRadius = data['border_radius'];
    }

    if (data['padding']) {
      this.padding = data['padding'];
    }

    if (!this.uid) {
      this.generateUid();
    }

    return this;
  }

  serialize(fields?: string[]): Object {
    let data: Object = {
      uid: this.uid,
      enabled: this.enabled,
      enabled_input: this.enabledInput ? this.enabledInput.serialize() : null,
      layout: this.layout,
      start_items: this.startItems.map(item => item.serialize()),
      center_items: this.centerItems.map(item => item.serialize()),
      end_items: this.endItems.map(item => item.serialize()),
      fill_settings: this.fillSettings ? this.fillSettings.serialize() : null,
      border_settings: this.borderSettings ? this.borderSettings.serialize() : null,
      width: this.width,
      height: this.height,
      border_radius: this.borderRadius,
      padding: this.padding
    };
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }

  generateUid() {
    this.uid = generateAlphanumeric(8, { letterFirst: true });
  }

  clone() {
    const instance = new MenuBlock();
    instance.uid = this.uid;
    instance.layout = this.layout;
    instance.enabled = this.enabled;
    instance.enabledInput = cloneDeep(this.enabledInput);
    instance.startItems = this.startItems.map(item => item.clone());
    instance.centerItems = this.centerItems.map(item => item.clone());
    instance.endItems = this.endItems.map(item => item.clone());
    instance.fillSettings = cloneDeep(this.fillSettings);
    instance.borderSettings = cloneDeep(this.borderSettings);
    instance.width = this.width;
    instance.height = this.height;
    instance.borderRadius = cloneDeep(this.borderRadius);
    instance.padding = cloneDeep(this.padding);
    return instance;
  }

  getAllItems(): MenuItem[] {
    return [...this.startItems, ...this.centerItems, ...this.endItems];
  }
}

export class MenuSettings {
  public project: string;
  public blocks: MenuBlock[] = [];
  public draft = false;
  public deleted = false;

  deserialize(data: Object): MenuSettings {
    this.project = data['project'];

    if (data['items']) {
      const items = JSON.parse(data['items']);

      if (items['blocks']) {
        this.blocks = items['blocks'].map(item => new MenuBlock().deserialize(item));
      } else {
        const blocks: MenuBlock[] = [];

        if (items['secondary_items']) {
          const block = new MenuBlock();

          block.generateUid();
          block.layout = MenuBlockLayout.LeftWide;
          block.startItems = items['secondary_items']
            .map(item => deserializeMenuItem(item))
            .filter(item => item != undefined);

          blocks.push(block);
        }

        this.blocks = blocks;
      }
    }

    if (data['draft'] !== undefined) {
      this.draft = data['draft'];
    }

    if (data['deleted'] !== undefined) {
      this.deleted = data['deleted'];
    }

    return this;
  }

  serialize(fields?: string[]): Object {
    let data: Object = {
      project: this.project,
      items: JSON.stringify({
        blocks: this.blocks.map(item => item.serialize())
      }),
      draft: this.draft,
      deleted: this.deleted
    };
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }

  clone() {
    const instance = new MenuSettings();
    instance.project = this.project;
    instance.blocks = this.blocks.map(item => item.clone());
    return instance;
  }

  getAllItems(): MenuItem[] {
    return this.blocks
      .filter(item => item.enabled)
      .reduce((acc, item) => {
        acc.push(...item.getAllItems());
        return acc;
      }, []);
  }

  hasTopBlocks(): boolean {
    return this.blocks.some(item => MenuBlockLayouts.isTop(item.layout) && item.enabled);
  }
}
