export type AnalyticsEventType = string;

export namespace AnalyticsEvent {
  export enum GA {
    UserLogin = 'user_login',
    UserRegistration = 'user_registration',
    ProjectRegistration = 'project_registration',
    Model = 'model',
    DashboardWidget = 'dashboard_widget',
    TableFilter = 'table_filter',
    MenuItem = 'menu_item',
    ModelDetailLayout = 'model_detail_layout',
    ModelListLayout = 'model_list_layout',
    ProjectInvitation = 'project_invitation',
    DemoBanner = 'DemoBanner',
    CurrentUser = 'current_user',
    ApiError = 'api_error',
    ProjectApiError = 'project_api_error',
    Overview = 'overview'
  }

  export enum SignIn {
    UserSignedIn = 'Sign In - User signed in'
  }

  export enum SignUp {
    ClickedCreateAccount = 'Sign Up - Clicked create account',
    AccountCreated = 'Sign Up - Account created',
    AccountVerified = 'Sign Up - Account verified'
  }

  export enum Logout {
    UserLoggedOut = 'Logout – User logged out'
  }

  export enum Share {
    ClickedShare = 'Share – Clicked share',
    OpenTab = 'Share – Open tab',
    MemberInvited = 'Share – Member invited',
    MemberInviteDeleted = 'Share – Member invite deleted',
    MemberDeleted = 'Share – Member deleted',
    PublicLinkCreated = 'Share – Public link created',
    PublicLinkDeleted = 'Share – Public link deleted',
    PublicAccessCreated = 'Share – Public access created',
    PublicAccessUpdated = 'Share – Public access updated',
    PublicAccessDeleted = 'Share – Public access deleted'
  }

  export enum Invitation {
    Opened = 'Invitation – Opened',
    OpenedFailed = 'Invitation – Opened failed',
    Accepted = 'Invitation – Accepted',
    AcceptedFailed = 'Invitation – Accepted failed',
    Rejected = 'Invitation – Rejected',
    RejectedFailed = 'Invitation – Rejected failed'
  }

  export enum PublicInvitation {
    Opened = 'Invitation – Opened',
    OpenedFailed = 'Invitation – Opened failed',
    Accepted = 'Invitation – Accepted',
    AcceptedFailed = 'Invitation – Accepted failed'
  }

  export enum Project {
    Opened = 'Project - Opened'
  }

  export enum User {
    ListOpened = 'User - List opened'
  }

  export enum Team {
    ListOpened = 'Team - List opened',
    StartedToSetUp = 'Team – Started to set up',
    Created = 'Team – Team created',
    Updated = 'Team – Team updated',
    Deleted = 'Team – Deleted'
  }

  export enum Permission {
    StartedToSetUp = 'Permission - Started to set up',
    PageAdded = 'Permission - Page added',
    PageRemoved = 'Permission - Page removed',
    ProjectAdded = 'Permission - Project added',
    ProjectRemoved = 'Permission - Project removed',
    Saved = 'Permission – Saved'
  }

  export enum Property {
    StartedToSetUp = 'Property - Started to set up',
    AddStarted = 'Property - Add started',
    AddCancelled = 'Property – Add cancelled',
    Added = 'Property – Added',
    UpdateStarted = 'Property - Update started',
    UpdateCancelled = 'Property – Update cancelled',
    Updated = 'Property – Updated',
    DeleteStarted = 'Property - Delete started',
    DeleteCancelled = 'Property – Delete cancelled',
    Deleted = 'Property – Deleted',
    Saved = 'Property – Saved',
    Cancelled = 'Property – Cancelled'
  }

  export enum SSO {
    AddStarted = 'SSO - Add started',
    AddCancelled = 'SSO – Add cancelled',
    Added = 'SSO – Added',
    UpdateStarted = 'SSO - Update started',
    UpdateCancelled = 'SSO – Update cancelled',
    Updated = 'SSO – Updated',
    DeleteStarted = 'SSO - Delete started',
    DeleteCancelled = 'SSO – Delete cancelled',
    Deleted = 'SSO – Deleted',
    Saved = 'SSO – Saved',
    Cancelled = 'SSO – Cancelled'
  }

  export enum EmailTemplate {
    AddStarted = 'Email Template - Add started',
    AddCancelled = 'Email Template – Add cancelled',
    Added = 'Email Template – Added',
    UpdateStarted = 'Email Template - Update started',
    UpdateCancelled = 'Email Template – Update cancelled',
    Updated = 'Email Template – Updated',
    DeleteStarted = 'Email Template - Delete started',
    DeleteCancelled = 'Email Template – Delete cancelled',
    Deleted = 'Email Template – Deleted',
    Saved = 'Email Template – Saved',
    Cancelled = 'Email Template – Cancelled'
  }

  export enum Project {
    ProjectCreated = 'Project – Project created',
    UsersChanged = 'Project – Users changed',
    UserInfoEntered = 'Project – User info entered',
    BuilderChange = 'Project – Builder change'
  }

  export enum ProjectCreate {
    ResourceCreated = 'Project Create – Resource created',
    TemplateApplied = 'Project Create – Template applied',
    AdminPanelTemplateApplied = 'Project Create – Admin Panel Template applied',
    StartBuildingApp = 'Project Create – Start building app'
  }

  export enum Page {
    PageCreateClicked = 'Page – Page create clicked',
    PageCreated = 'Page – Page created',
    Renamed = 'Page – Renamed',
    StartedSetUp = 'Page – Started set up',
    Deleted = 'Page – Deleted',
    SuccessfullySetUp = 'Page – Successfully set up'
  }

  export enum Template {
    ClickedChooseTemplate = 'Template – Clicked Choose Template',
    Viewed = 'Template – Viewed',
    Apply = 'Template – Apply',
    Applied = 'Template – Applied',
    ResourceCreate = 'Template – Resource Create',
    BlankApp = 'Template – Blank App',
    ConnectResourcesClicked = 'Template – Connect resources clicked',
    StartedToAddMultipleResource = 'Template – Started to add multiple resource',
    MultipleResourceSuccessfullyAdded = 'Template – Multiple resource successfully Added',
    StartedToChooseResources = 'Template – Started to choose resources',
    ResourcesChooseCompleted = 'Template – Resources choose completed',
    DemoResources = 'Template – Demo Resources',
    ChooseTemplateOpened = 'Template – Choose template opened',
    ChooseTemplateDismissed = 'Template – Choose template dismissed',
    ChooseTemplateCompleted = 'Template – Choose template completed',
    ChooseAdminPanelTemplateOpened = 'Template – Choose admin panel template opened'
  }

  export enum PageTemplates {
    Opened = 'Page Templates - Opened',
    LayoutSelected = 'Page Templates - Layout selected',
    ResourceSelected = 'Page Templates - Resource selected',
    CollectionSelected = 'Page Templates - Collection selected',
    StartedCustomizeFields = 'Page Templates - Started customize fields',
    FinishedCustomizeFields = 'Page Templates - Finished customize fields',
    Cancelled = 'Page Templates - Cancelled',
    Applied = 'Page Templates - Applied'
  }

  export enum StorageFileViewer {
    UploadClick = 'Storage File Viewer – Upload click',
    CreateFolderClick = 'Storage File Viewer – Create folder click',
    DeleteFileClick = 'Storage File Viewer – Delete file click',
    GetFileUrlClick = 'Storage File Viewer – Get file URL click',
    ItemClick = 'Storage File Viewer – Item click',
    ItemLinkClick = 'Storage File Viewer – Item link click'
  }

  export enum Resource {
    ClickedCreateResource = 'Resource – Clicked create resource',
    ResourceAdded = 'Resource – Resource added',
    ResourceAddFailed = 'Resource – Resource add failed',
    ResourceUpdated = 'Resource – Resource updated',
    ResourceUpdateFailed = 'Resource – Resource update failed',
    ResourceAddFailedDiagnostics = 'Resource – Resource add failed diagnostics',
    ResourceMethodSelected = 'Resource – Resource method selected'
  }

  export enum Formula {
    StartedToSetUp = 'Formula – Started to set up',
    Updated = 'Formula – Updated',
    FinishedToSetUp = 'Formula – Finished to set up',
    SuccessfullySetUp = 'Formula – Successfully set up'
  }

  export enum ComponentData {
    StartedToSetUp = 'Component Data – Started to set up',
    ResourceItemSuccessfullySetUp = 'Component Data – Resource item successfully set up',
    InputsSuccessfullySetUp = 'Component Data – Inputs successfully set up',
    SuccessfullySetUp = 'Component Data – Successfully set up',
    ResourceSelected = 'Component Data – Resource selected',
    ResourceItemSelected = 'Component Data – Resource item selected'
  }

  export enum Component {
    DraggedToPage = 'Component – Dragged to page',
    StartedSetUp = 'Component – Started set up',
    Deleted = 'Component – Deleted',
    SuccessfullySetUp = 'Component – Successfully set up',
    StartedConfiguration = 'Component – Started configuration',
    SuccessfullyConfigured = 'Component – Successfully configured',
    Duplicated = 'Component – Duplicated',
    MovedToPage = 'Component – Move to page',
    Copy = 'Component – Copy',
    Cut = 'Component – Cut',
    Paste = 'Component – Paste',
    IntegrationsViewed = 'Component – Integrations viewed',
    IntegrationsResourceViewed = 'Component – Integrations resource viewed',
    ApprovalStartedToSetUp = 'Component – Approval started to set up',
    ApprovalSuccessfullySetUp = 'Component – Approval successfully set up',
    BindStarted = 'Component – Bind started',
    BindCancelled = 'Component – Bind cancelled',
    BindChooseField = 'Component – Bind choose field',
    BindSuccessfullySetUp = 'Component – Bind successfully set up'
  }

  export enum WorkflowBuilder {
    StartedToSetUp = 'Workflow Builder - Started to set up',
    StepTest = 'Workflow Builder – Step tested',
    WorkflowTest = 'Workflow Builder – Workflow tested',
    Saved = 'Workflow Builder – Saved',
    SetUpCancelled = 'Workflow Builder – Set up cancelled'
  }

  export enum ViewEditor {
    StartedToSetUp = 'View Editor - Started to set up',
    Saved = 'View Editor – Saved',
    SetUpCancelled = 'View Editor – Set up cancelled',
    ImportFigmaOpened = 'View Editor – Import Figma opened',
    ImportFigmaApplied = 'View Editor – Import Figma applied',
    ImportFigmaCancelled = 'View Editor – Import Figma cancelled',
    ImportSketchOpened = 'View Editor – Import Sketch opened',
    ImportSketchApplied = 'View Editor – Import Sketch applied',
    ImportSketchCancelled = 'View Editor – Import Sketch cancelled'
  }

  export enum ViewTemplates {
    Opened = 'View Templates - Opened',
    ViewOpened = 'View Templates – View opened',
    ViewCustomize = 'View Templates – View customize',
    ViewApplied = 'View Templates – View applied',
    Cancelled = 'View Templates – Cancelled'
  }

  export enum Modal {
    Created = 'Modal – Created',
    Duplicated = 'Modal – Duplicated',
    MovedToPage = 'Modal – Moved to page',
    Copy = 'Modal – Copy',
    Cut = 'Modal – Cut',
    Paste = 'Modal – Paste'
  }

  export enum Approval {
    TaskQueueCreated = 'Approval – Task queue created',
    TaskQueueEdited = 'Approval – Task queue edited',
    TaskQueueDeleted = 'Approval – Task queue deleted',
    TaskCreated = 'Approval – Task created'
  }

  export enum HTTPBuilder {
    StartToSetUp = 'HTTP Builder – Start to set up',
    SuccessfullyPerformed = 'HTTP Builder – Successfully performed',
    UnsuccessfullyPerformed = 'HTTP Builder – Unsuccessfully performed',
    SuccessfullySetUp = 'HTTP Builder – Successfully set up'
  }

  export enum SQLBuilder {
    StartToSetUp = 'SQL Builder – Start to set up',
    SuccessfullyPerformed = 'SQL Builder – Successfully performed',
    UnsuccessfullyPerformed = 'SQL Builder – Unsuccessfully performed',
    SuccessfullySetUp = 'SQL Builder – Successfully set up'
  }

  export enum ObjectBuilder {
    StartToSetUp = 'Object Builder – Start to set up',
    SuccessfullyPerformed = 'Object Builder – Successfully performed',
    UnsuccessfullyPerformed = 'Object Builder – Unsuccessfully performed',
    SuccessfullySetUp = 'Object Builder – Successfully set up'
  }

  export enum Subscription {
    PlanSelected = 'Subscription - Plan selected',
    PlanSelectedConfirmed = 'Subscription - Plan selected confirmed',
    SuccessfulSubscribed = 'Subscription - Successful subscribed',
    FailedSubscription = 'Subscription - Failed subscription',
    CancelPayment = 'Subscription - Cancel payment',
    CancelSubscription = 'Subscription - Cancel subscription'
  }

  export enum Environment {
    StartedToCreate = 'Environment - Started to create',
    Created = 'Environment - Created',
    StartedToMerge = 'Environment - Started to merge',
    MergedSuccessfully = 'Environment - Merged successfully',
    MergeFailed = 'Environment - Merge failed'
  }

  export enum Menu {
    MenuItemAdded = 'Menu – Menu item added',
    MenuItemUpdated = 'Menu – Menu item updated',
    MenuItemDeleted = 'Menu – Menu item deleted'
  }

  export enum Publish {
    ClickPublish = 'Publish – Click publish',
    Published = 'Publish – Published',
    PublishFailed = 'Publish – Publish failed',
    ClickDiscard = 'Publish – Click discard',
    Discarded = 'Publish – Discarded',
    DiscardFailed = 'Publish – Discard failed',
    DiscardCancelled = 'Publish – Discard cancelled',
    DiscardReviewChanges = 'Publish – Discard review changes',
    OpenPublishOptions = 'Publish – Open publish options',
    OpenApp = 'Publish – Open app',
    ClickPreview = 'Publish – Click preview',
    ExitPreview = 'Publish – Exit preview'
  }

  export enum Snapshots {
    ClickSnapshots = 'Publish – Click snapshots',
    RenameSnapshot = 'Publish – Rename snapshot',
    ClickApplySnapshot = 'Publish – Click apply snapshot',
    ClickCreateSnapshot = 'Publish – Click create snapshot'
  }

  export enum Parameter {
    SuccessfullySetUp = 'Parameter – Successfully set up',
    Added = 'Parameter – Added',
    Deleted = 'Parameter – Deleted'
  }

  export enum SetParameter {
    SuccessfullySetUp = 'Set Parameter – Successfully set up',
    Added = 'Set Parameter – Added',
    Deleted = 'Set Parameter – Deleted'
  }

  export enum UserMode {
    OpenCustomization = 'User Mode – Open customization'
  }

  export enum BuilderMenu {
    PagesClicked = 'Builder Menu – Pages clicked',
    DataClicked = 'Builder Menu – Data clicked',
    CollaborationClicked = 'Builder Menu – Collaboration clicked',
    AutomationsClicked = 'Builder Menu – Automations clicked',
    LayoutClicked = 'Builder Menu – Layout clicked',
    SignUpClicked = 'Builder Menu – Sign up clicked',
    EditMenuClicked = 'Builder Menu – Edit menu clicked',
    DataStartAddResource = 'Builder Menu – Data – Start add resource',
    DataOpenResource = 'Builder Menu – Data – Open resource',
    DataOpenUsers = 'Builder Menu – Data – Open users',
    DataOpenGroups = 'Builder Menu – Data – Open groups',
    DataOpenStorage = 'Builder Menu – Data – Open storage',
    DataOpenLogs = 'Builder Menu – Data – Open logs',
    ClickedCreatePage = 'Builder Menu – Clicked create page'
  }

  export enum Data {
    CollectionOpened = 'Data - Collection opened',
    AddCollectionClicked = 'Data - Add collection clicked',
    CollectionCreated = 'Data - Collection created',
    ActionOpened = 'Data - Action opened',
    AddActionClicked = 'Data - Add action clicked',
    ActionCreated = 'Data - Action created',
    StorageOpened = 'Data - Storage opened',
    AddStorageClicked = 'Data - Add storage clicked',
    StorageCreated = 'Data - Storage created',
    CollectionStartToAdd = 'Data - Collection – Start to add',
    CollectionSuccessfullyAdd = 'Data - Collection – Successfully add',
    CollectionStartToBuildQuery = 'Data - Collection – Start to build query',
    CollectionQuerySuccessfullyBuilt = 'Data - Collection – Query successfully built',
    CollectionQueryBuildCancelled = 'Data - Collection – Query build cancelled',
    CollectionFieldStartedToSetUp = 'Data - Collection Field – Started to set up',
    CollectionFieldSuccessfullySetUp = 'Data - Collection Field – Successfully set up',
    CollectionFieldSetUpCancelled = 'Data - Collection Field – Set up cancelled',
    CollectionRecordsStartedToCreate = 'Data - Collection Records – Started to create',
    CollectionRecordsCreated = 'Data - Collection Records – Created',
    CollectionRecordsCreateFailed = 'Data - Collection Records – Create failed',
    CollectionRecordsStartedToUpdate = 'Data - Collection Records – Started to update',
    CollectionRecordsUpdated = 'Data - Collection Records – Updated',
    CollectionRecordsUpdateFailed = 'Data - Collection Records – Update failed',
    CollectionRecordsStartedToDelete = 'Data - Collection Records – Started to delete',
    CollectionRecordsDeleted = 'Data - Collection Records – Deleted',
    CollectionRecordsDeleteFailed = 'Data - Collection Records – Delete failed',
    ActionStartToBuildQuery = 'Data - Action – Start to build query',
    ActionQuerySuccessfullyBuilt = 'Data - Action – Query successfully built',
    ActionQueryBuildCancelled = 'Data - Action – Query build cancelled',
    StorageStartToBuildQuery = 'Data - Storage – Start to build query',
    StorageQuerySuccessfullyBuilt = 'Data - Storage – Query successfully built',
    StorageQueryBuildCancelled = 'Data - Storage – Query build cancelled',
    AddToPage = 'Data - Add to page'
  }

  export enum ImportRecords {
    ImportOpened = 'Import Records – Import opened',
    ImportStarted = 'Import Records – Import started',
    ImportFinished = 'Import Records – Import finished',
    ImportCancelled = 'Import Records – Import cancelled',
    ImportFailed = 'Import Records – Import failed'
  }

  export enum CustomDomain {
    AddClicked = 'Custom Domain – Add clicked',
    EditClicked = 'Custom Domain – Edit clicked',
    DomainCreated = 'Custom Domain – Domain created',
    DomainDeleted = 'Custom Domain – Domain deleted'
  }

  export enum CustomEmail {
    AddClicked = 'Custom Email – Add clicked',
    EditClicked = 'Custom Email – Edit clicked',
    CustomEmailAdded = 'Custom Email – Custom Email added',
    CustomEmailDeleted = 'Custom Email – Custom Email deleted'
  }

  export enum UserMenu {
    ItemClicked = 'User Menu - Item clicked'
  }

  export enum AppSettings {
    MenuItemClicked = 'App Settings - Menu item clicked'
  }

  export enum Appearance {
    ThemeSelected = 'Appearance - Theme selected',
    SectionClicked = 'Appearance - Section clicked',
    AppearanceSaved = 'Appearance - Appearance saved'
  }

  export enum SignUpSettings {
    MenuItemClicked = 'Sign Up Settings - Menu item clicked'
  }

  export enum Help {
    Opened = 'Help - Opened',
    Closed = 'Help - Closed',
    ItemClicked = 'Help - Item clicked'
  }

  export enum ProjectSettings {
    Opened = 'ProjectSettings - Opened'
  }

  export enum Billing {
    Opened = 'Billing - Opened'
  }

  export enum Documentation {
    Opened = 'Documentation - Opened',
    Rated = 'Documentation - Rated'
  }

  export enum AI {
    StartedTranslation = 'AI - Started translation',
    Translated = 'AI - Translated',
    TranslationFailed = 'AI - Translation failed'
  }

  export enum Undocumented {
    AppOpen = 'App Open',
    UserSet = 'User Set',

    ProjectUsersSendUserInvitationFailed = 'Project Users - Send User Invitation Failed',

    MenuCustomizeInterfaceSave = 'Menu Customize Interface - Save',
    MenuCustomizeInterfaceCancel = 'Menu Customize Interface - Cancel',

    LiveDemoClickIndustry = 'Live demo - Click Industry',
    LiveDemoClickIndustryJob = 'Live demo - Click Industry Job',

    TutorialStartTask = 'Tutorial - Start task',
    TutorialFinishTask = 'Tutorial - Finish task',
    TutorialCancelTask = 'Tutorial - Cancel Task',

    FeaturePreviewShow = 'Feature Preview - Show',
    FeaturePreviewActivate = 'Feature Preview - Activate',

    ProtocolRedirectShow = 'Protocol Redirect - Show',
    ProtocolRedirectConfirm = 'Protocol Redirect - Confirm',
    ProtocolRedirectCancel = 'Protocol Redirect - Cancel',

    InstallCompanyInfo = 'Install - Company Info'
  }

  export enum Deprecated {
    ProjectUsersClickToInviteUser = 'Project Users - Click to Invite User',

    MenuCustomizeInterfaceRenameMenuItem = 'Menu Customize Interface - Rename Menu Item',
    MenuCustomizeInterfaceChangeMenuItemIcon = 'Menu Customize Interface - Change Menu Item Icon',
    MenuCustomizeInterfaceCreateCustomLink = 'Menu Customize Interface - Create Custom Link',
    MenuCustomizeInterfaceCreateSection = 'Menu Customize Interface - Create Section',

    CustomizeInterfaceChooseDashboard = 'Customize Interface - Choose Dashboard',
    CustomizeInterfaceChooseNavigationView = 'Customize Interface - Choose Navigation View',
    CustomizeInterfaceChooseCollectionView = 'Customize Interface - Choose Collection View',
    CustomizeInterfaceChooseRecordView = 'Customize Interface - Choose Record View',

    DashboardCustomizeInterfaceCreateWidget = 'Dashboard Customize Interface - Create Widget',
    DashboardCustomizeInterfaceCreateWidgetQueryType = 'Dashboard Customize Interface - Create Widget Query Type',
    DashboardCustomizeInterfaceCreateWidgetSqlError = 'Dashboard Customize Interface - Create Widget SQL Error',
    DashboardCustomizeInterfaceCreateWidgetSuccess = 'Dashboard Customize Interface - Create Widget Success',
    DashboardCustomizeInterfaceCreateWidgetError = 'Dashboard Customize Interface - Create Widget Error',
    DashboardCustomizeInterfaceEditWidget = 'Dashboard Customize Interface - Edit Widget',
    DashboardCustomizeInterfaceEditWidgetQueryType = 'Dashboard Customize Interface - Edit Widget Query Type',
    DashboardCustomizeInterfaceEditWidgetSqlError = 'Dashboard Customize Interface - Edit Widget SQL Error',
    DashboardCustomizeInterfaceEditWidgetSuccess = 'Dashboard Customize Interface - Edit Widget Success',
    DashboardCustomizeInterfaceEditWidgetError = 'Dashboard Customize Interface - Edit Widget Error',
    DashboardCustomizeInterfaceDeleteWidget = 'Dashboard Customize Interface - Delete Widget',
    DashboardCustomizeInterfaceDeleteWidgetSuccess = 'Dashboard Customize Interface - Delete Widget Success',
    DashboardCustomizeInterfaceDeleteWidgetError = 'Dashboard Customize Interface - Delete Widget Error',
    DashboardCustomizeInterfaceSave = 'Dashboard Customize Interface - Save',
    DashboardCustomizeInterfaceCancel = 'Dashboard Customize Interface - Cancel',

    CollectionViewCustomizeInterfaceEnableLayout = 'Collection View Customize Interface - Enable Layout',
    CollectionViewCustomizeInterfaceDisableLayout = 'Collection View Customize Interface - Disable Layout',
    CollectionViewCustomizeInterfaceMakeDefaultLayout = 'Collection View Customize Interface - Make Default Layout',
    CollectionViewCustomizeInterfaceStartSetupLayout = 'Collection View Customize Interface - Start Setup Layout',
    CollectionViewCustomizeInterfaceOpenSetupLayout = 'Collection View Customize Interface - Open Setup Layout',
    CollectionViewCustomizeInterfaceSaveSetupLayout = 'Collection View Customize Interface - Save Setup Layout',
    CollectionViewCustomizeInterfaceCustomizeFieldType = 'Collection View Customize Interface - Customize Field Type',
    CollectionViewCustomizeInterfaceCustomizeFieldTypeSuccess = 'Collection View Customize Interface - Customize Field Type Success',
    CollectionViewCustomizeInterfaceCustomizeFieldTypeError = 'Collection View Customize Interface - Customize Field Type Error',
    CollectionViewCustomizeInterfaceCreateFlexField = 'Collection View Customize Interface - Create Flex Field',
    CollectionViewCustomizeInterfaceCreateFlexFieldTypeSuccess = 'Collection View Customize Interface - Create Flex Field Type Success',
    CollectionViewCustomizeInterfaceCreateFlexFieldTypeError = 'Collection View Customize Interface - Create Flex Field Type Error',
    CollectionViewCustomizeInterfaceEditFlexField = 'Collection View Customize Interface - Edit Flex Field',
    CollectionViewCustomizeInterfaceEditFlexFieldTypeSuccess = 'Collection View Customize Interface - Edit Flex Field Type Success',
    CollectionViewCustomizeInterfaceEditFlexFieldTypeError = 'Collection View Customize Interface - Edit Flex Field Type Error',
    CollectionViewCustomizeInterfaceDeleteFlexField = 'Collection View Customize Interface - Delete Flex Field',
    CollectionViewCustomizeInterfaceSave = 'Collection View Customize Interface - Save',
    CollectionViewCustomizeInterfaceCancel = 'Collection View Customize Interface - Cancel',

    RecordViewCustomizeInterfaceAddLayout = 'Record View Customize Interface - Add Layout',
    RecordViewCustomizeInterfaceAddField = 'Record View Customize Interface - Add Field',
    RecordViewCustomizeInterfaceCustomizeFieldType = 'Record View Customize Interface - Customize Field Type',
    RecordViewCustomizeInterfaceCustomizeFieldTypeSuccess = 'Record View Customize Interface - Customize Field Type Success',
    RecordViewCustomizeInterfaceCustomizeFieldTypeError = 'Record View Customize Interface - Customize Field Type Error',
    RecordViewCustomizeInterfaceAddRelatedCollection = 'Record View Customize Interface - Add Related Collection',
    RecordViewCustomizeInterfaceAddWidget = 'Record View Customize Interface - Add Widget',
    RecordViewCustomizeInterfaceWidgetQueryType = 'Record View Customize Interface - Widget Query Type',
    RecordViewCustomizeInterfaceCreateWidgetSuccess = 'Record View Customize Interface - Create Widget Success',
    RecordViewCustomizeInterfaceCreateWidgetError = 'Record View Customize Interface - Create Widget Error',
    RecordViewCustomizeInterfaceAddAction = 'Record View Customize Interface - Add Action',
    RecordViewCustomizeInterfaceSave = 'Record View Customize Interface - Save',
    RecordViewCustomizeInterfaceCancel = 'Record View Customize Interface - Cancel',

    CollectionViewCreateFilter = 'Collection View - Create Filter',
    CollectionViewCreateSegment = 'Collection View - Create Segment',

    InstallWelcome = 'Install - Welcome',
    InstallProjectCreate = 'Install - Project Create',
    InstallTypeInstallMyself = 'Install - Install Myself',
    InstallTypeInstallMyselfOther = 'Install - Install Myself Other',
    InstallTypeSendTeammate = 'Install - Send Teammate',
    InstallTypeSendTeammateSuccess = 'Install - Send Teammate Success',
    InstallTypeHaveToken = 'Install - Have Token',
    InstallTypeParameters = 'Install - Type Parameters',
    InstallCreateEnvironmentSuccess = 'Install - Create Environment Success',
    InstallCreateEnvironmentError = 'Install - Create Environment Error',

    OnboardingCompanyInfo = 'Onboarding - Company Info',
    OnboardingCompanyInfoSuccess = 'Onboarding - Company Info Success',
    OnboardingCompanyInfoSkip = 'Onboarding - Company Info Skip',
    OnboardingCompanyInfoContact = 'Onboarding - Company Info Contact',
    OnboardingSkipOnboarding = 'Onboarding - Skip Onboarding',
    OnboardingWelcomeScreen = 'Onboarding - Welcome Screen',

    ViewPreviewFlexAction = 'View Preview Flex Action',
    ViewPreviewFlexView = 'View Preview Flex View',
    CreateFlexView = 'Create Flex View',
    ViewPreviewFlexField = 'View Preview Flex Field'
  }
}

export enum AnalyticsEventAction {
  Success = 'success',
  Created = 'created',
  Updated = 'updated',
  Deleted = 'deleted',
  Clicked = 'clicked',
  Init = 'init',
  Emit = 'emit',
  Open = 'open',
  Close = 'close',
  Seek = 'seek'
}
