import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { publishLast, refCount, switchMap } from 'rxjs/operators';

import { FirebaseFunctionsCheckConfigurationResponse } from '@modules/resources';

import { ApiService } from '@modules/api';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {
  constructor(private apiService: ApiService, private http: HttpClient) {}

  functionsCheckConfiguration(
    projectId: string,
    serviceToken: Object
  ): Observable<FirebaseFunctionsCheckConfigurationResponse> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.syncDataMethodURL('firebase/functions_check_configuration');
        let headers = new HttpHeaders();
        const data = {
          project_id: projectId,
          service_token: serviceToken
        };

        headers = this.apiService.setHeadersToken(headers);

        return this.http.post(url, data, { headers: headers });
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }
}
