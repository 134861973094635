/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../previews/video-preview/video-preview.component.ngfactory";
import * as i2 from "../../previews/video-preview/video-preview.component";
import * as i3 from "@angular/platform-browser";
import * as i4 from "../../previews/file-preview/file-preview.component.ngfactory";
import * as i5 from "../../previews/file-preview/file-preview.component";
import * as i6 from "@angular/common";
import * as i7 from "../upload-template/upload-template.component.ngfactory";
import * as i8 from "../upload-template/upload-template.component";
import * as i9 from "../../../../../common/tips/directives/tip/tip.directive";
import * as i10 from "./video-upload.component";
import * as i11 from "../../../../projects/stores/current-project.store";
import * as i12 from "../../../../projects/stores/current-environment.store";
import * as i13 from "../../../../resources/services/resource-controller/resource-controller.service";
import * as i14 from "../../../../storages-queries/services/storage/storage.service";
var styles_VideoUploadComponent = [];
var RenderType_VideoUploadComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_VideoUploadComponent, data: {} });
export { RenderType_VideoUploadComponent as RenderType_VideoUploadComponent };
function View_VideoUploadComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-video-preview", [], null, null, null, i1.View_VideoPreviewComponent_0, i1.RenderType_VideoPreviewComponent)), i0.ɵdid(1, 573440, null, 0, i2.VideoPreviewComponent, [i3.DomSanitizer, i0.ChangeDetectorRef], { preview: [0, "preview"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.uploadedFile == null) ? null : _co.uploadedFile.url); _ck(_v, 1, 0, currVal_0); }, null); }
function View_VideoUploadComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-file-preview", [], null, null, null, i4.View_FilePreviewComponent_0, i4.RenderType_FilePreviewComponent)), i0.ɵdid(1, 114688, null, 0, i5.FilePreviewComponent, [], { previewFilename: [0, "previewFilename"], previewExtension: [1, "previewExtension"], previewSize: [2, "previewSize"], theme: [3, "theme"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.uploadedFile == null) ? null : _co.uploadedFile.filename); var currVal_1 = ((_co.uploadedFile == null) ? null : _co.uploadedFile.extension); var currVal_2 = _co.uploadFileSize; var currVal_3 = _co.theme; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_VideoUploadComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "upload__background upload__background_video upload__background_interactive"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_VideoUploadComponent_2)), i0.ɵdid(3, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_VideoUploadComponent_3)), i0.ɵdid(5, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.compact; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.compact; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_VideoUploadComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "app-upload-template", [], null, [[null, "clearValue"], [null, "fileSelected"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("clearValue" === en)) {
        var pd_0 = (_co.clearCurrentValue() !== false);
        ad = (pd_0 && ad);
    } if (("fileSelected" === en)) {
        var pd_1 = (_co.onFileSelected($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i7.View_UploadTemplateComponent_0, i7.RenderType_UploadTemplateComponent)), i0.ɵdid(1, 245760, null, 0, i8.UploadTemplateComponent, [i0.ChangeDetectorRef], { upload: [0, "upload"], accept: [1, "accept"], name: [2, "name"], control: [3, "control"], autofocus: [4, "autofocus"], disabled: [5, "disabled"], background: [6, "background"], fit: [7, "fit"], cropFormat: [8, "cropFormat"], cropFormatCustom: [9, "cropFormatCustom"], preview: [10, "preview"], previewInteractive: [11, "previewInteractive"], uploadProgress: [12, "uploadProgress"], uploadError: [13, "uploadError"], accentColor: [14, "accentColor"], theme: [15, "theme"] }, { clearValue: "clearValue", fileSelected: "fileSelected" }), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_VideoUploadComponent_1)), i0.ɵdid(3, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(4, 0, null, 1, 3, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 2, "a", [["class", "upload__button icon-cloud_download"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        $event.stopPropagation();
        var pd_0 = (_co.download() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(6, 737280, null, 0, i9.TipDirective, [i0.ElementRef, i0.ComponentFactoryResolver, i0.Injector, i0.ApplicationRef], { content: [0, "content"], optionsRaw: [1, "optionsRaw"] }, null), i0.ɵpod(7, { side: 0, margin: 1 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isUploadable(); var currVal_1 = "video/*"; var currVal_2 = _co.name; var currVal_3 = _co.control; var currVal_4 = _co.autofocus; var currVal_5 = !_co.storage$.value; var currVal_6 = _co.background; var currVal_7 = _co.fit; var currVal_8 = _co.cropFormat; var currVal_9 = _co.cropFormatCustom; var currVal_10 = ((_co.uploadedFile == null) ? null : _co.uploadedFile.url); var currVal_11 = true; var currVal_12 = _co.uploadProgress; var currVal_13 = _co.uploadError; var currVal_14 = _co.accentColor; var currVal_15 = _co.theme; _ck(_v, 1, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15]); var currVal_16 = _co.uploadedFile; _ck(_v, 3, 0, currVal_16); var currVal_17 = "Download"; var currVal_18 = _ck(_v, 7, 0, "top", (0 - 4)); _ck(_v, 6, 0, currVal_17, currVal_18); }, null); }
export function View_VideoUploadComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-video-upload", [], null, null, null, View_VideoUploadComponent_0, RenderType_VideoUploadComponent)), i0.ɵdid(1, 770048, null, 0, i10.VideoUploadComponent, [i3.DomSanitizer, i11.CurrentProjectStore, i12.CurrentEnvironmentStore, i13.ResourceControllerService, i14.StorageService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var VideoUploadComponentNgFactory = i0.ɵccf("app-video-upload", i10.VideoUploadComponent, View_VideoUploadComponent_Host_0, { name: "name", control: "control", outputFormat: "outputFormat", storageResourceName: "storageResourceName", storageName: "storageName", path: "path", autofocus: "autofocus", disabled: "disabled", background: "background", fit: "fit", resize: "resize", editor: "editor", cropFormat: "cropFormat", cropFormatCustom: "cropFormatCustom", context: "context", contextElement: "contextElement", compact: "compact", accentColor: "accentColor", theme: "theme" }, { uploadedUpdated: "uploadedUpdated" }, []);
export { VideoUploadComponentNgFactory as VideoUploadComponentNgFactory };
