import { FocusMonitor } from '@angular/cdk/a11y';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { merge, Subscription } from 'rxjs';
import { debounceTime, take } from 'rxjs/operators';

import { ResizeType } from '@common/resizable';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ViewContext, ViewContextElement } from '@modules/customize';

// Refactor imports
import {
  createFormFieldFactory,
  FieldType,
  getJavaScriptSyntaxError,
  InputFilterField,
  InputFilterFieldLookup,
  InputValueType,
  ValueArray
} from '@modules/fields';
import {
  contextToFormulaValue,
  FieldInputControl,
  singleTokenFormulaToContextValue,
  transformFormulaElementAccessors
} from '@modules/parameters';
import { TokenPopupItem } from '@modules/tokens-components';
import { controlValue, isSet, TypedChanges } from '@shared';

import { FormulaSection, FormulaSectionItem } from '../../data/formula-section';
import { InputOverlayDirective } from '../../directives/input-overlay/input-overlay.directive';
import { getFormulaStateAnalyticsParams } from '../../utils/analytics';
import { InputEditFormulaValueComponent } from '../input-edit-formula-value/input-edit-formula-value.component';
import { InputEditJsValueComponent } from '../input-edit-js-value/input-edit-js-value.component';
import { ViewContextTokenPopoverOverlayComponent } from '../view-context-token-popover-overlay/view-context-token-popover-overlay.component';
import { FieldFilterInputTokenData, InputTokenData, InputTokenType } from './input-token';

@Component({
  selector: 'app-input-edit',
  templateUrl: './input-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputEditComponent implements OnInit, OnDestroy, OnChanges {
  @Input() itemForm: FieldInputControl;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  // If need to specify inner path inside contextElement (relative to contextElement)
  @Input() contextElementPath: (string | number)[];
  // If need multiple path inside contextElement (relative to contextElement + contextElementPath)
  @Input() contextElementPaths: (string | number)[][];
  @Input() staticValueField: FieldType;
  @Input() staticValueParams: Object;
  @Input() staticValueArray = false;
  @Input() staticValueDisabled = false;
  @Input() filterFields: InputFilterField[] = [];
  @Input() userInput = false;
  @Input() focusedInitial = false;
  @Input() placeholder = 'Text';
  @Input() formulaPlaceholder = 'Formula';
  @Input() jsPlaceholder = 'return 2 * 3;';
  @Input() resetEnabled = true;
  @Input() displayValueTypesEnabled = true;
  @Input() displayValueTypes = [InputValueType.Formula];
  @Input() overlay = false;
  @Input() builtInItemsEnabled = true;
  @Input() classes: string[] = [];
  @Input() fill = false;
  @Input() fillVertical = false;
  @Input() header = false;
  @Input() small = false;
  @Input() resultShowOnFocus = true;
  @Input() dark = false;
  @Input() darker = false;
  @Input() analyticsSource: string;

  @ViewChild(InputEditFormulaValueComponent) formulaValueComponent: InputEditFormulaValueComponent;
  @ViewChild(InputEditJsValueComponent) jsValueComponent: InputEditJsValueComponent;
  @ViewChild(ViewContextTokenPopoverOverlayComponent)
  tokenPopoverOverlayComponent: ViewContextTokenPopoverOverlayComponent;
  @ViewChild(InputOverlayDirective) inputOverlayDirective: InputOverlayDirective;

  createField = createFormFieldFactory();
  control: FormControl;
  controlArray: ValueArray;
  addedValueControl: FormControl;
  initialValue: Object;
  jsError: string;
  filterField: InputFilterField;
  filterFieldLookup: InputFilterFieldLookup;
  staticValueDisabledClean = false;
  extraSections: FormulaSection[] = [];
  inputValueTypes = InputValueType;
  fieldTypes = FieldType;
  resizeTypes = ResizeType;
  popoverPositionsSubscription: Subscription;
  startedFormulaEditing = false;
  booleanValueEquals = (lhs, rhs) => lhs === rhs;

  constructor(
    private focusMonitor: FocusMonitor,
    private analyticsService: UniversalAnalyticsService,
    private zone: NgZone,
    private cd: ChangeDetectorRef
  ) {}

  get effectiveControl(): AbstractControl {
    return this.staticValueArray ? this.controlArray : this.control;
  }

  ngOnInit() {
    this.control = new FormControl(undefined, { updateOn: this.itemForm ? this.itemForm.updateOn : undefined });
    this.controlArray = new ValueArray({ updateOn: this.itemForm ? this.itemForm.updateOn : undefined });

    controlValue<Object>(this.itemForm)
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        if (value['value_type'] == InputValueType.Filter) {
          this.updateFilterField();
        }
      });

    this.setInitialValue();
    this.updateExtraSections();

    merge(this.control.valueChanges, this.controlArray.valueChanges)
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        if (this.itemForm.controls.value_type.value == InputValueType.StaticValue && value === '') {
          if (isSet(this.itemForm.controls.value_type.value)) {
            this.itemForm.controls.value_type.patchValue('');
            this.itemForm.controls.static_value.patchValue('');
          }
        } else if (
          (!this.itemForm.controls.value_type.value ||
            this.itemForm.controls.value_type.value == InputValueType.StaticValue) &&
          !this.staticValueDisabledClean
        ) {
          if (this.itemForm.controls.value_type.value != InputValueType.StaticValue) {
            this.itemForm.controls.value_type.patchValue(InputValueType.StaticValue);
          }

          this.itemForm.controls.static_value.patchValue(value);
        } else if (this.itemForm.controls.value_type.value == InputValueType.Formula || this.staticValueDisabledClean) {
          if (this.itemForm.controls.value_type.value != InputValueType.Formula) {
            this.itemForm.controls.value_type.patchValue(InputValueType.Formula);
          }

          const toExternalValue = transformFormulaElementAccessors(value, this.context, false);
          this.itemForm.controls.formula_value.patchValue(toExternalValue);
        }

        this.itemForm.markAsDirty();
      });

    this.itemForm.valueChanges.pipe(debounceTime(200), untilDestroyed(this)).subscribe(value => {
      this.updateInputErrors();
      this.onInteracting();

      this.analyticsService.sendSimpleEvent(AnalyticsEvent.Formula.Updated, {
        ValueType: this.itemForm.controls.value_type.value,
        Source: this.analyticsSource
      });
    });
  }

  ngOnDestroy(): void {
    if (this.startedFormulaEditing) {
      this.analyticsService.sendSimpleEvent(AnalyticsEvent.Formula.FinishedToSetUp, {
        ...getFormulaStateAnalyticsParams(this.itemForm, this.context, this.initialValue),
        Source: this.analyticsSource
      });
    }
  }

  ngOnChanges(changes: TypedChanges<InputEditComponent>): void {
    if (changes.staticValueField || changes.staticValueDisabled) {
      if ([FieldType.JSON, FieldType.Location].includes(this.staticValueField)) {
        this.staticValueDisabledClean = true;
      } else {
        this.staticValueDisabledClean = this.staticValueDisabled;
      }
    }

    if (
      [changes.builtInItemsEnabled, changes.userInput, changes.contextElement, changes.filterFields].some(
        item => item && !item.firstChange
      )
    ) {
      this.updateExtraSections();
    }
  }

  addValueControl() {
    this.addedValueControl = this.controlArray.pushNew();
  }

  removeValueControl(index: number) {
    this.controlArray.removeAt(index);
  }

  clearInputErrors() {
    this.jsError = undefined;
    this.cd.markForCheck();
  }

  updateInputErrors() {
    const value = this.itemForm.value;

    this.clearInputErrors();

    if (value['value_type'] == InputValueType.Js) {
      if (isSet(value['js_value'])) {
        this.jsError = getJavaScriptSyntaxError(value['js_value']);
        this.cd.markForCheck();
      }
    }
  }

  updateFilterField() {
    this.filterField = this.filterFields.find(item => item.name == this.itemForm.controls.filter_field.value);
    this.filterFieldLookup = this.filterField
      ? this.filterField.lookups.find(item => item.name === this.itemForm.controls.filter_lookup.value)
      : undefined;
    this.cd.markForCheck();
  }

  getFilterTokenItems(): TokenPopupItem<InputTokenData> {
    if (!this.contextElement || !this.contextElement.fieldFilters) {
      return;
    }

    return {
      name: 'Current Component Filters',
      data: {
        type: InputTokenType.Filter
      },
      items: this.filterFields.map(field => {
        return {
          name: field.label,
          items: field.lookups.map(lookup => {
            return {
              name: lookup.label,
              data: {
                field: field.name,
                lookup: lookup.name
              } as FieldFilterInputTokenData
            };
          })
        };
      })
    };
  }

  setInitialValue() {
    const formValue = this.itemForm.value;

    if (!formValue['value_type'] || formValue['value_type'] == InputValueType.StaticValue) {
      this.effectiveControl.patchValue(formValue['static_value'], { emitEvent: false });
    } else if (formValue['value_type'] == InputValueType.Formula) {
      const fromExternalValue = transformFormulaElementAccessors(formValue['formula_value'], this.context, true);
      this.effectiveControl.patchValue(fromExternalValue, { emitEvent: false });
    } else if (formValue['value_type'] == InputValueType.Filter) {
      this.updateFilterField();
    }

    this.initialValue = formValue;
    this.itemForm.markAsPristine();
    this.updateInputErrors();
  }

  updateExtraSections() {
    const extraSections: FormulaSection[] = [];

    if (this.builtInItemsEnabled) {
      const builtInItems: FormulaSectionItem[] = [
        {
          path: ['built_in', InputTokenType.TextInputs],
          item: {
            token: [InputTokenType.TextInputs],
            label: 'Text with Inputs',
            icon: 'variable',
            tip: 'Apply format and Insert inputs in Text, HTML or Markdown',
            iconOrange: true,
            orange: true
          }
        },
        {
          path: ['built_in', InputTokenType.Js],
          item: {
            token: [InputTokenType.Js],
            label: 'JavaScript',
            icon: 'console',
            tip: 'Use JavaScript',
            iconOrange: true,
            orange: true
          }
        },
        ...(this.userInput
          ? [
              {
                path: ['built_in', InputTokenType.Prompt],
                item: {
                  token: [InputTokenType.Prompt],
                  label: 'Ask user',
                  icon: 'edit',
                  tip: 'Ask user to enter value in popup window'
                }
              }
            ]
          : []),
        {
          path: ['built_in', InputTokenType.EmptyString],
          item: {
            token: [InputTokenType.EmptyString],
            label: 'Empty String',
            icon: 'delete',
            tip: 'Send empty string'
          }
        },
        {
          path: ['built_in', InputTokenType.Null],
          item: {
            token: [InputTokenType.Null],
            label: 'Null',
            icon: 'deselect',
            tip: 'Send null value'
          }
        }
      ];

      extraSections.push({
        name: 'built_in',
        pinned: true,
        horizontal: true,
        items: builtInItems
      });
    }

    const filterItems = this.getFilterTokenItems();

    if (filterItems) {
      extraSections.push({
        label: filterItems.name,
        icon: 'filter',
        items: filterItems.items.reduce<FormulaSectionItem[]>((acc, fieldItem) => {
          fieldItem.items.forEach(lookupItem => {
            acc.push({
              path: ['filter', fieldItem.name, lookupItem.name],
              item: {
                token: [InputTokenType.Filter],
                label: [fieldItem.name, lookupItem.name].join(' '),
                data: lookupItem.data
              }
            });
          }, []);
          return acc;
        }, [])
      });
    }

    this.extraSections = extraSections;
    this.cd.markForCheck();
  }

  onEditorOpened() {
    this.onInteracting();
  }

  onInteracting() {
    if (!this.startedFormulaEditing) {
      this.startedFormulaEditing = true;

      this.analyticsService.sendSimpleEvent(AnalyticsEvent.Formula.StartedToSetUp, {
        ValueType: this.itemForm.controls.value_type.value,
        Source: this.analyticsSource
      });
    }
  }

  switchToFormula(empty = false) {
    const currentValueType = this.itemForm.controls.value_type.value;
    const contextValue = this.itemForm.controls.context_value.value;
    const staticValue = this.itemForm.controls.static_value.value;

    this.itemForm.controls.value_type.patchValue(InputValueType.Formula);

    if (empty) {
      this.control.patchValue('');
    } else if (currentValueType == InputValueType.Context && isSet(contextValue)) {
      let formulaValue = contextToFormulaValue(contextValue);
      formulaValue = transformFormulaElementAccessors(formulaValue, this.context, true);
      this.control.patchValue(formulaValue);
    } else if (currentValueType == InputValueType.StaticValue && isSet(staticValue)) {
      this.control.patchValue(JSON.stringify(staticValue));
    }

    this.focusValueOnStable();
  }

  focusValue() {
    if (this.formulaValueComponent) {
      this.formulaValueComponent.focus();
    }

    if (this.jsValueComponent) {
      this.jsValueComponent.focus();
    }
  }

  focusValueOnStable() {
    this.zone.onStable
      .pipe(take(1))
      .pipe(untilDestroyed(this))
      .subscribe(() => this.focusValue());
  }

  switchToJs() {
    this.itemForm.controls.value_type.patchValue(InputValueType.Js);
    this.itemForm.markAsDirty();
  }

  switchToTextInputs() {
    this.itemForm.controls.value_type.patchValue(InputValueType.TextInputs);
    this.itemForm.markAsDirty();
  }

  switchToEmptyString() {
    this.itemForm.controls.value_type.patchValue(InputValueType.EmptyString);
    this.itemForm.markAsDirty();
  }

  switchToNull() {
    this.itemForm.controls.value_type.patchValue(InputValueType.Null);
    this.itemForm.markAsDirty();
  }

  switchToPrompt() {
    this.itemForm.controls.value_type.patchValue(InputValueType.Prompt);
    this.itemForm.markAsDirty();
  }

  switchToContext(token: string) {
    const contextValue = singleTokenFormulaToContextValue(token);
    this.itemForm.controls.value_type.patchValue(InputValueType.Context);
    this.itemForm.controls.context_value.patchValue(contextValue);
    this.itemForm.markAsDirty();
  }

  switchToFieldFilter(field: string, lookup: string) {
    this.itemForm.controls.value_type.patchValue(InputValueType.Filter);
    this.itemForm.controls.filter_field.patchValue(field);
    this.itemForm.controls.filter_lookup.patchValue(lookup);
    this.itemForm.markAsDirty();
  }

  switchToDefault() {
    if (this.staticValueDisabledClean) {
      this.itemForm.controls.value_type.patchValue(InputValueType.Formula);
      this.itemForm.markAsDirty();

      this.control.patchValue('');

      this.focusValueOnStable();
    } else {
      this.itemForm.controls.value_type.patchValue('');
      this.itemForm.markAsDirty();

      this.effectiveControl.patchValue(this.itemForm.controls.static_value.value);
    }
  }

  isInputOverlayOpened(): boolean {
    if (this.overlay || !this.inputOverlayDirective) {
      return false;
    }

    return this.inputOverlayDirective.isOpened();
  }

  onInputOverlayFinished() {
    this.setInitialValue();
  }
}
