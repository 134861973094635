var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ElementRef, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import isPlainObject from 'lodash/isPlainObject';
import truncate from 'lodash/truncate';
import { of } from 'rxjs';
import { map, shareReplay, startWith, switchMap } from 'rxjs/operators';
import { applyValueFormatWithContext, applyValueFormatWithContext$, CustomizeService } from '@modules/customize';
import { FieldType, registerFieldComponent, ValueFormat } from '@modules/fields';
import { isSet } from '@shared';
import { FieldComponent } from '../field/field.component';
var CharFieldWidget;
(function (CharFieldWidget) {
    CharFieldWidget[CharFieldWidget["Default"] = 0] = "Default";
    CharFieldWidget[CharFieldWidget["Multiline"] = 1] = "Multiline";
})(CharFieldWidget || (CharFieldWidget = {}));
var CharFieldComponent = /** @class */ (function (_super) {
    __extends(CharFieldComponent, _super);
    function CharFieldComponent(customizeService, sanitizer) {
        var _this = _super.call(this) || this;
        _this.customizeService = customizeService;
        _this.sanitizer = sanitizer;
        _this.widgets = CharFieldWidget;
        return _this;
    }
    CharFieldComponent.prototype.ngOnInit = function () {
        this.updateWidget();
        this.updateMultiline();
        this.displayValue$ = this.getDisplayValue$();
    };
    CharFieldComponent.prototype.ngOnChanges = function (changes) {
        this.updateWidget();
        this.updateMultiline();
        if (changes['value'] || changes['form'] || changes['field']) {
            this.displayValue$ = this.getDisplayValue$();
        }
    };
    CharFieldComponent.prototype.truncateJson = function (value) {
        if (isPlainObject(value)) {
            try {
                return truncate(JSON.stringify(value), { length: 160 });
            }
            catch (e) { }
        }
        else {
            return value;
        }
    };
    CharFieldComponent.prototype.getDisplayValue = function () {
        var value = this.currentValue;
        value = this.truncateJson(value);
        if (this.field.params['value_format']) {
            var valueFormat = new ValueFormat().deserialize(this.field.params['value_format']);
            return applyValueFormatWithContext(value, valueFormat, {
                context: this.context,
                contextElement: this.contextElement
            });
        }
        else {
            return value;
        }
    };
    CharFieldComponent.prototype.getDisplayValue$ = function () {
        var _this = this;
        return this.currentValue$.pipe(map(function (value) {
            return _this.truncateJson(value);
        }), switchMap(function (value) {
            if (_this.field.params['value_format']) {
                var valueFormat = new ValueFormat().deserialize(_this.field.params['value_format']);
                return applyValueFormatWithContext$(value, valueFormat, {
                    context: _this.context,
                    contextElement: _this.contextElement
                });
            }
            else {
                return of(value);
            }
        }), startWith(this.getDisplayValue()), shareReplay(1));
    };
    CharFieldComponent.prototype.updateWidget = function () {
        var widget;
        if (this.field.params['multiline']) {
            widget = CharFieldWidget.Multiline;
        }
        else {
            widget = CharFieldWidget.Default;
        }
        if (this.widget == widget) {
            return;
        }
        this.widget = widget;
    };
    CharFieldComponent.prototype.updateMultiline = function () {
        this.rows = this.field.params['rows'] || 8;
        this.multilineStyles = this.getMultilineStyles();
    };
    CharFieldComponent.prototype.getMultilineStyles = function () {
        var clamp = this.field.params['multiline'] && isSet(this.field.params['rows']);
        if (!clamp) {
            return;
        }
        var lines = this.field.params['rows'];
        var styles = [['-webkit-box-orient', 'vertical']];
        if (lines) {
            styles.push(['-webkit-line-clamp', lines]);
        }
        var stylesStr = styles.map(function (item) { return item.join(':'); }).join(';');
        return this.sanitizer.bypassSecurityTrustStyle(stylesStr);
    };
    Object.defineProperty(CharFieldComponent.prototype, "inputSmall", {
        // TODO: Move to separate params option
        get: function () {
            return this.field.params['classes'] && this.field.params['classes'].indexOf('input_small') != -1;
        },
        enumerable: true,
        configurable: true
    });
    return CharFieldComponent;
}(FieldComponent));
export { CharFieldComponent };
registerFieldComponent(FieldType.Text, CharFieldComponent);
