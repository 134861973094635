var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { untilDestroyed } from 'ngx-take-until-destroy';
import Quill from 'quill';
import { merge, of } from 'rxjs';
import { delay, first } from 'rxjs/operators';
import { isSet } from '@shared';
var Inline = Quill.import('blots/inline');
export var BACKGROUND_SET_DEFAULT_VALUE_ATTR = 'data-background-default';
export var BACKGROUND_SET_DARK_VALUE_ATTR = 'data-background-dark';
var BackgroundSetBlot = /** @class */ (function (_super) {
    __extends(BackgroundSetBlot, _super);
    function BackgroundSetBlot() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    BackgroundSetBlot.create = function (value) {
        if (!value) {
            return _super.create.call(this, false);
        }
        var node = _super.create.call(this, value);
        if (isSet(value.backgroundDefault)) {
            node.setAttribute(BACKGROUND_SET_DEFAULT_VALUE_ATTR, value.backgroundDefault);
        }
        if (isSet(value.backgroundDark)) {
            node.setAttribute(BACKGROUND_SET_DARK_VALUE_ATTR, value.backgroundDark);
        }
        return node;
    };
    // Overriding this method, in this particular case,
    // is what causes the Delta returned as content by
    // Quill to have the desired information.
    BackgroundSetBlot.formats = function (domNode) {
        var backgroundDefault = domNode.getAttribute(BACKGROUND_SET_DEFAULT_VALUE_ATTR);
        var backgroundDark = domNode.getAttribute(BACKGROUND_SET_DARK_VALUE_ATTR);
        if (isSet(backgroundDefault) || isSet(backgroundDark)) {
            return {
                backgroundDefault: backgroundDefault,
                backgroundDark: backgroundDark
            };
        }
        else {
            return _super.formats.call(this, domNode);
        }
    };
    BackgroundSetBlot.prototype.ngOnDestroy = function () { };
    BackgroundSetBlot.prototype.getQuill = function () {
        var node = this.domNode;
        while (node) {
            var quill = Quill.find(node);
            if (quill instanceof Quill) {
                return quill;
            }
            node = node.parentElement;
        }
    };
    BackgroundSetBlot.prototype.getOptions = function () {
        var quill = this.getQuill();
        if (quill) {
            var toolbar_1 = quill.getModule('toolbar');
            if (toolbar_1) {
                var options = toolbar_1.options.formatsOptions || {};
                return options['background-set'] || {};
            }
        }
        return {};
    };
    BackgroundSetBlot.prototype.attach = function () {
        var _this = this;
        _super.prototype.attach.call(this);
        var options = this.getOptions();
        var theme$ = options['theme$'] || of(undefined);
        // Workaround for value update
        theme$ = merge(theme$.pipe(first()), theme$.pipe(delay(0)));
        theme$.pipe(untilDestroyed(this)).subscribe(function (theme) {
            var node = _this.domNode;
            var backgroundDefault = node.getAttribute(BACKGROUND_SET_DEFAULT_VALUE_ATTR);
            var backgroundDark = node.getAttribute(BACKGROUND_SET_DARK_VALUE_ATTR);
            var backgroundEffective = theme == 'dark' ? backgroundDark : backgroundDefault;
            if (isSet(backgroundEffective)) {
                node.setAttribute('style', "--background-value: " + backgroundEffective);
            }
            else {
                node.removeAttribute('style');
            }
        });
    };
    BackgroundSetBlot.prototype.detach = function () {
        _super.prototype.detach.call(this);
        this.ngOnDestroy();
    };
    BackgroundSetBlot.prototype.formats = function () {
        // Returns the formats list this class (this format).
        var formats = _super.prototype.formats.call(this);
        // Inline has the domNode reference, which in this
        // case represents the SPAN, result defined in tagName.
        formats['background-set'] = BackgroundSetBlot.formats(this.domNode);
        // In the code above, it is as if we are adding this new format.
        return formats;
    };
    return BackgroundSetBlot;
}(Inline));
BackgroundSetBlot.tagName = 'SPAN';
BackgroundSetBlot.blotName = 'background-set';
Quill.register('formats/background-set', BackgroundSetBlot);
