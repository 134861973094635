export enum TextStyleGlobalType {
  regularTitleTextStyle = 'regularTitleTextStyle',
  fieldLabelTextStyle = 'fieldLabelTextStyle',
  fieldLabelAdditionalTextStyle = 'fieldLabelAdditionalTextStyle',
  listGroupTitleTextStyle = 'listGroupTitleTextStyle',
  tableGroupTitleTextStyle = 'tableGroupTitleTextStyle',
  kanbanStageTitleTextStyle = 'kanbanStageTitleTextStyle',
  valueWidgetTitleTextStyle = 'valueWidgetTitleTextStyle',
  valueWidgetValueTextStyle = 'valueWidgetValueTextStyle',
  collapseTitleTextStyle = 'collapseTitleTextStyle',
  buttonTextStyle = 'buttonTextStyle',
  hoverButtonTextStyle = 'hoverButtonTextStyle',
  activeButtonTextStyle = 'activeButtonTextStyle',
  fieldTextStyle = 'fieldTextStyle',
  fieldPlaceholderTextStyle = 'fieldPlaceholderTextStyle',
  hoverFieldTextStyle = 'hoverFieldTextStyle',
  hoverFieldPlaceholderTextStyle = 'hoverFieldPlaceholderTextStyle',
  focusFieldTextStyle = 'focusFieldTextStyle',
  focusFieldPlaceholderTextStyle = 'focusFieldPlaceholderTextStyle',
  errorFieldTextStyle = 'errorFieldTextStyle',
  errorFieldPlaceholderTextStyle = 'errorFieldPlaceholderTextStyle'
}

export type TextStyleGlobalName = TextStyleGlobalType | string;
