<app-customize-bar-header [title]="'General'" [subtitle]="'App settings'" [backEnabled]="false">
</app-customize-bar-header>

<div class="sidebar__content">
  <app-sidebar-section>
    <div class="sidebar__element">
      <app-sidebar-field [label]="'App Name'">
        <app-auto-field
          [form]="projectForm"
          [label]="false"
          [field]="createField({ name: 'name', field: 'CharField', params: { classes: ['input_fill'] } })"
        ></app-auto-field>
      </app-sidebar-field>
    </div>

    <div class="sidebar__element">
      <app-sidebar-field [label]="'Icon'">
        <app-logo-uploader
          [logoUrlControl]="projectForm.controls.logo"
          [logoFileControl]="projectForm.controls.logo_file"
          [logoFillControl]="projectForm.controls.logo_fill"
          [colorControl]="projectForm.controls.logo_color"
          [colorCustomEnabledControl]="projectForm.controls.logo_color_custom_enabled"
          [colorCustomControl]="projectForm.controls.logo_color_custom"
          [defaultColor]="'blue'"
          [defaultColorBackground]="'bright-blue'"
          [defaultColorBorder]="'bright-blue'"
          [initials]="(currentProjectStore.instance$ | async)?.initials"
        ></app-logo-uploader>
      </app-sidebar-field>
    </div>

    <div class="sidebar__element">
      <app-sidebar-field [label]="'App URL'">
        <div class="input-icon-wrapper">
          <div
            class="input input_fill input_template input_clickable input_icon-right"
            (mouseenter)="appUrlHover.next(true)"
            (mouseleave)="appUrlHover.next(false)"
            (click)="editUniqueName()"
          >
            {{ appBaseUrl }}{{ appProjectUrl }}
          </div>
          <div class="input-icon input-icon_right icon-pen" [class.input-icon_bright]="appUrlHover | async"></div>
        </div>
      </app-sidebar-field>
    </div>

    <div class="sidebar__element">
      <app-sidebar-field [label]="'Default theme'">
        <ng-container actions>
          <span class="sidebar-field__label-help">
            <span
              class="help-icon help-icon_clickable icon-help"
              [appTip]="
                'Default theme is used when opening App for the first time. Each user can switch theme after load if there is a corresponding button in Menu.'
              "
              [appTipOptions]="{ side: 'top' }"
            ></span>
          </span>

          <a
            [appLink]="currentProjectStore.instance.settingsLayoutLink('appearance')"
            class="sidebar__field-action sidebar__field-action_button icon-palette"
            [appTip]="'Customize themes'"
            [appTipOptions]="{ side: 'top' }"
          ></a>
        </ng-container>

        <div class="sidebar-image-toggle sidebar-image-toggle_cols_3" style="margin-top: 2px;">
          <div *ngFor="let theme of ['default', 'dark']" class="sidebar-image-toggle__col">
            <a
              href="javascript:void(0)"
              class="sidebar-image-toggle__item"
              [ngClass]="'sidebar-image-toggle__item-' + theme"
              [class.sidebar-image-toggle__item_active]="theme === settingsForm.controls.default_theme.value"
              (click)="changeTheme(theme)"
            >
              <div
                class="sidebar-image-toggle__item-inner"
                [style.padding-top.%]="(110 / 195) * 100"
                [style.background-image]="'/assets/images/theme-' + theme + '.svg' | appDeployCssUrl"
              ></div>
            </a>
          </div>
        </div>
      </app-sidebar-field>
    </div>
  </app-sidebar-section>

  <app-sidebar-section [title]="'Custom Domain'" [description]="'App will be available under this domain'">
    <div class="sidebar__element">
      <app-sidebar-field>
        <div
          class="sidebar-list-item sidebar-list-item_clickable"
          [class.sidebar-list-item_error]="false"
          (click)="editDomain()"
        >
          <ng-container *ngIf="domain">
            <div class="sidebar-list-item__left">
              <span class="sidebar-list-item__icon icon-earth_planet"></span>
            </div>

            <div class="sidebar-list-item__main">
              <div class="sidebar-list-item__title">{{ domain.domain }}</div>
              <div class="sidebar-list-item__description">Domain is configured</div>
            </div>

            <div class="sidebar-list-item__right">
              <a href="javascript:void(0)" class="sidebar-list-item__action icon-edit"> </a>
            </div>
          </ng-container>

          <ng-container *ngIf="!domain">
            <div class="sidebar-list-item__left">
              <span class="sidebar-list-item__icon icon-earth_planet"></span>
            </div>
            <div class="sidebar-list-item__main">
              <div class="sidebar-list-item__title sidebar-list-item__title_secondary">Add custom domain</div>
            </div>

            <div class="sidebar-list-item__right">
              <span class="sidebar-list-item__action icon-arrow_forward_2"></span>
            </div>
          </ng-container>
        </div>
      </app-sidebar-field>
    </div>
  </app-sidebar-section>
</div>
