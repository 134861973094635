<agm-marker
  [latitude]="item.latitude"
  [longitude]="item.longitude"
  [iconUrl]="icon"
  (markerClick)="openWindow.emit()"
  (infoWindowClose)="closeWindow.emit()"
>
  <agm-snazzy-info-window
    [isOpen]="windowOpen"
    [pointer]="'8px'"
    [placement]="'right'"
    [maxWidth]="'none'"
    [maxHeight]="'none'"
    [showCloseButton]="false"
    [wrapperClass]="theme ? ' si-wrapper-theme' : ''"
  >
    <ng-template>
      <app-map-popup
        *ngIf="windowOpen"
        [item]="item.item"
        [modelDescription]="modelDescription"
        [settings]="settings"
        [visibleColumns]="visibleColumns"
        [context]="context"
        [contextElement]="contextElement"
        [accentColor]="accentColor"
        [theme]="theme"
        (modelUpdated)="modelUpdated.next($event)"
        (close)="closeWindow.emit()"
      >
      </app-map-popup>
    </ng-template>
  </agm-snazzy-info-window>
</agm-marker>
