import { FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { GradientType } from '@modules/colors';
import { Fill, FillType } from '@modules/customize';
import { controlValue, isSet } from '@shared';

import { GradientControl } from './gradient.control';

export class FillControl extends FormGroup {
  instance: Fill;

  controls: {
    type: FormControl;
    color: FormControl;
    gradient: GradientControl;
  };

  constructor(state: Partial<Fill> = {}) {
    super({
      type: new FormControl(isSet(state.type) ? state.type : FillType.Color),
      color: new FormControl(isSet(state.color) ? state.color : ''),
      gradient: new GradientControl(isSet(state.gradient) ? state.gradient : {})
    });
  }

  deserialize(value?: Fill, options: { emitEvent?: boolean } = {}) {
    this.instance = value;

    this.controls.type.patchValue(value ? value.type : FillType.Color, { emitEvent: options.emitEvent });
    this.controls.color.patchValue(value ? value.color : '', { emitEvent: options.emitEvent });
    this.controls.gradient.deserialize(value ? value.gradient : undefined, { emitEvent: options.emitEvent });
  }

  isSet(): boolean {
    if (this.controls.type.value == FillType.Color) {
      return isSet(this.controls.color.value);
    } else if (this.controls.type.value == FillType.Gradient) {
      return this.controls.gradient.isSet();
    } else {
      return false;
    }
  }

  resetDefaults(options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    this.controls.type.patchValue(FillType.Color, options);
    this.controls.color.patchValue('#2B50ED', options);
  }

  reset(options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    this.controls.type.patchValue(undefined, options);
    this.controls.color.patchValue(undefined, options);
  }

  setColor(color: string) {
    this.patchValue({
      type: FillType.Color,
      color: color
    });
  }

  setGradientType(type: GradientType) {
    const prevType = this.controls.type.value;
    const defaultColor = prevType == FillType.Color ? this.controls.color.value : undefined;

    this.patchValue({
      type: FillType.Gradient,
      gradient: {
        type: type
      }
    });
    this.controls.gradient.validateStops(defaultColor);
  }

  getInstance(instance?: Fill): Fill {
    if (!instance) {
      instance = new Fill();
    }

    instance.type = this.controls.type.value;

    if (instance.type == FillType.Color || instance.color) {
      instance.color = isSet(this.controls.color.value) ? this.controls.color.value : undefined;
    }

    if (instance.type == FillType.Gradient || instance.gradient) {
      instance.gradient = this.controls.gradient.getInstance(instance.gradient);
    }

    return instance;
  }

  serialize(): Fill {
    return this.getInstance(this.instance);
  }

  serialize$(): Observable<Fill> {
    return controlValue(this, { debounce: 200 }).pipe(map(() => this.serialize()));
  }
}
