import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ColorsComponentsModule } from '@modules/colors-components';

import { TextEditorBackgroundPickerComponent } from './components/text-editor-background-picker/text-editor-background-picker.component';
import { TextEditorColorPickerComponent } from './components/text-editor-color-picker/text-editor-color-picker.component';
import { TextEditorComponent } from './components/text-editor/text-editor.component';
import { JsonToHtmlPipe } from './pipes/json-to-html/json-to-html.pipe';

@NgModule({
  imports: [CommonModule, ColorsComponentsModule],
  declarations: [
    TextEditorComponent,
    JsonToHtmlPipe,
    TextEditorColorPickerComponent,
    TextEditorBackgroundPickerComponent
  ],
  exports: [TextEditorComponent, JsonToHtmlPipe],
  entryComponents: [TextEditorComponent, TextEditorColorPickerComponent, TextEditorBackgroundPickerComponent]
})
export class TextEditorModule {}
