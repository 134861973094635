var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { merge } from 'rxjs';
import { debounceTime, delay, filter, map, shareReplay, startWith } from 'rxjs/operators';
import { NotificationService } from '@common/notifications';
import { modelFieldItemToRawListViewSettingsColumn, ViewContext, ViewContextElement } from '@modules/customize';
import { BooleanFieldStyle } from '@modules/field-components';
import { createFormFieldFactory, DefaultType, FieldType, GeographyOutputFormat, getFieldComponentsByName, getFieldDescriptionByType, ValidatorType } from '@modules/fields';
import { ModelDbField, ModelDescription } from '@modules/models';
import { CurrentEnvironmentStore, Resource } from '@modules/projects';
import { controlValue, Singleton } from '@shared';
import { CustomizeBarContext } from '../../services/customize-bar-context/customize-bar.context';
import { CustomizeBarColumnEditForm } from '../customize-bar-column-edit/customize-bar-column-edit.form';
import { CustomizeBarResourceFieldEditForm } from './customize-bar-resource-field-edit.form';
var CustomizeBarResourceFieldEditComponent = /** @class */ (function () {
    function CustomizeBarResourceFieldEditComponent(context, contextElement, customizeBarContext, resourceForm, overrideForm, currentEnvironmentStore, notificationService, cd) {
        var _this = this;
        this.context = context;
        this.contextElement = contextElement;
        this.customizeBarContext = customizeBarContext;
        this.resourceForm = resourceForm;
        this.overrideForm = overrideForm;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.notificationService = notificationService;
        this.cd = cd;
        this.saved = new EventEmitter();
        this.cancelled = new EventEmitter();
        this.createField = createFormFieldFactory();
        this.booleanFieldStyle = BooleanFieldStyle;
        this.fieldTypes = FieldType;
        this.defaultTypes = DefaultType;
        this.loading = false;
        this.validatorTypes = ValidatorType;
        this.configurable = {
            name: true,
            field: false,
            required: true,
            editable: true,
            params: true
        };
        this.defaultValueParams = {};
        this.contextNew = new Singleton(function () { return new ViewContext(_this.currentEnvironmentStore); });
    }
    CustomizeBarResourceFieldEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.resourceForm.init({
            resource: this.resource,
            modelDescription: this.modelDescription,
            field: this.field,
            defaults: this.defaults
        });
        if (this.isPrimaryKey()) {
            this.resourceForm.controls.default_type.disable();
        }
        this.overrideForm.init({
            field: this.field
                ? __assign({}, modelFieldItemToRawListViewSettingsColumn(this.field), { required: this.field.required, placeholder: this.field.placeholder, validatorType: this.field.validatorType, validatorParams: this.field.validatorParams }, this.defaults) : __assign({ editable: true }, this.defaults),
            configurable: this.configurable
        });
        this.resourceModelItems$ = this.resourceForm.resourceModelItems$();
        this.defaultTypeOptions$ = this.resourceForm.getDefaultTypeOptions$();
        this.field$ = merge(this.resourceForm.valueChanges, this.overrideForm.valueChanges).pipe(debounceTime(200), map(function () { return _this.getCurrentField(); }), startWith(this.getCurrentField()), shareReplay(1));
        controlValue(this.resourceForm.controls.field)
            .pipe(untilDestroyed(this))
            .subscribe(function (type) {
            if (type == FieldType.Location) {
                _this.overrideForm.controls.params.patchValue(__assign({}, _this.overrideForm.controls.params.value, { output_format: GeographyOutputFormat.PostgreSQL }));
            }
        });
        this.resourceForm.controls.field.valueChanges
            .pipe(delay(0), untilDestroyed(this))
            .subscribe(function () { return _this.updateParamsComponentData(); });
        this.updateParamsComponentData();
        var cleanName = function (value) { return value.replace(/[^\w]/g, '_').replace(/^\d/, '_').toLowerCase(); };
        this.resourceForm.controls.name.valueChanges.pipe(untilDestroyed(this)).subscribe(function (value) {
            if (typeof value == 'string') {
                var cleanedValue = cleanName(value);
                if (cleanedValue != value) {
                    _this.resourceForm.controls.name.patchValue(cleanedValue);
                }
            }
        });
        this.overrideForm.controls.verbose_name.valueChanges
            .pipe(filter(function () { return !_this.resourceForm.controls.name_enabled.value; }), untilDestroyed(this))
            .subscribe(function (value) {
            var cleanedValue = cleanName(value);
            _this.resourceForm.controls.name.patchValue(cleanedValue);
        });
        this.field$.pipe(untilDestroyed(this)).subscribe(function (field) {
            var classes = field.params && field.params['classes'] ? field.params['classes'] : [];
            _this.defaultValueParams = __assign({}, field.params, { classes: ['input_fill', 'select_fill', 'input_segment-bottom', 'select_segment-bottom'].concat(classes) });
            _this.cd.markForCheck();
        });
    };
    CustomizeBarResourceFieldEditComponent.prototype.ngOnDestroy = function () { };
    CustomizeBarResourceFieldEditComponent.prototype.isPrimaryKey = function () {
        return this.field && this.modelDescription.primaryKeyField == this.field.name;
    };
    CustomizeBarResourceFieldEditComponent.prototype.getContextProviders = function () {
        var _this = this;
        return [
            {
                provide: ViewContext,
                useFactory: function () {
                    if (_this.context) {
                        return _this.context;
                    }
                    else {
                        return _this.contextNew.get();
                    }
                },
                deps: []
            }
        ];
    };
    CustomizeBarResourceFieldEditComponent.prototype.updateParamsComponentData = function () {
        var item = getFieldDescriptionByType(this.resourceForm.controls.field.value);
        var components = getFieldComponentsByName(item.name);
        if (!components || !components.viewParamsComponent) {
            this.viewParamsComponentData = undefined;
        }
        else {
            this.viewParamsComponentData = {
                component: components.viewParamsComponent,
                inputs: {
                    field: this.field ? modelFieldItemToRawListViewSettingsColumn(this.field) : undefined,
                    field$: this.field$,
                    configurable: this.configurable,
                    control: this.overrideForm.controls.params,
                    context: this.context || this.contextNew.get(),
                    contextElement: this.contextElement,
                    resourceField: true,
                    analyticsSource: this.analyticsSource
                },
                providers: this.getContextProviders()
            };
        }
        if (!components || !components.dataParamsComponent) {
            this.dataParamsComponentData = undefined;
        }
        else {
            this.dataParamsComponentData = {
                component: components.dataParamsComponent,
                inputs: {
                    field: this.field ? modelFieldItemToRawListViewSettingsColumn(this.field) : undefined,
                    field$: this.field$,
                    configurable: this.configurable,
                    control: this.overrideForm.controls.params,
                    context: this.context || this.contextNew.get(),
                    contextElement: this.contextElement,
                    resourceField: true,
                    analyticsSource: this.analyticsSource
                },
                providers: this.getContextProviders()
            };
        }
        this.cd.detectChanges();
    };
    CustomizeBarResourceFieldEditComponent.prototype.delete = function () {
        // this.event.emit({ type: CustomizeBarEditEventType.Deleted });
        // this.close();
    };
    CustomizeBarResourceFieldEditComponent.prototype.close = function () {
        this.customizeBarContext.popSettingsComponent();
    };
    CustomizeBarResourceFieldEditComponent.prototype.getCurrentField = function () {
        var field = this.resourceForm.serialize();
        var overrideField = this.overrideForm.submit().field;
        field.applyOverrides(overrideField);
        return modelFieldItemToRawListViewSettingsColumn(field);
    };
    CustomizeBarResourceFieldEditComponent.prototype.submit = function () {
        var _this = this;
        this.loading = true;
        this.cd.markForCheck();
        var overrideField = this.overrideForm.submit().field;
        this.resourceForm
            .submit(overrideField)
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            _this.saved.emit(result.modelDescription);
            _this.close();
            var field = result.field || overrideField;
            if (_this.field) {
                _this.notificationService.success('Updated', "Column <strong>" + field.verboseName + "</strong> was successfully updated");
            }
            else {
                _this.notificationService.success('Created', "Column <strong>" + field.verboseName + "</strong> was successfully updated");
            }
        }, function () {
            _this.loading = false;
            _this.cd.markForCheck();
        });
    };
    CustomizeBarResourceFieldEditComponent.prototype.cancel = function () {
        this.cancelled.emit();
        this.close();
    };
    return CustomizeBarResourceFieldEditComponent;
}());
export { CustomizeBarResourceFieldEditComponent };
