import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, Injector, OnDestroy, Type } from '@angular/core';
import defaults from 'lodash/defaults';
import last from 'lodash/last';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, fromEvent, Observable, Subject, Subscription } from 'rxjs';
import { first, takeUntil, tap } from 'rxjs/operators';

import { DynamicComponentArguments } from '@common/dynamic-component';
import { ScrollService } from '@common/scroll';

import { PopupRef } from '../../data/popup-ref';
import { markPopupMouseEvent } from '../../utils/events';

export interface PopupDynamicComponentArguments extends DynamicComponentArguments {
  popupComponent?: any;
  popupComponentDisableClose?: boolean;
  popupComponentCloseWithoutConfirm?: boolean;
  popupComponentCloseTitle?: string;
  popupComponentCloseDescription?: string;
  popupCloseOnDeactivate?: boolean;
  popupRequestClose?: Subject<void>;
  popupComponentOrange?: boolean;
  popupComponentDark?: boolean;
  popupComponentTheme?: boolean;
  popupClosed?: (data?: any) => void;
  enableWindowScroll?: boolean;
}

export function untilPopupClosed(popupRef: PopupRef, handler?: () => void) {
  return function <T>(source: Observable<T>) {
    return source.pipe(
      takeUntil(
        popupRef.closed$.pipe(
          tap(() => {
            if (handler) {
              handler();
            }
          })
        )
      )
    );
  };
}

@Injectable({
  providedIn: 'root'
})
export class PopupService implements OnDestroy {
  private _items = new BehaviorSubject<PopupDynamicComponentArguments[]>([]);
  private _overlays: OverlayRef[] = [];

  constructor(private scrollService: ScrollService, private overlay: Overlay) {
    this._items.subscribe(items => {
      this.scrollService.setWindowBackScrollEnabled(!items.length);

      if (items.length) {
        if (last(items).enableWindowScroll) {
          this.scrollService.enableWindowScroll();
        } else {
          this.scrollService.disableWindowScroll();
        }
      } else {
        this.scrollService.enableWindowScroll();
      }
    });
  }

  ngOnDestroy(): void {}

  push(data: PopupDynamicComponentArguments): PopupDynamicComponentArguments {
    data.popupRequestClose = new Subject();
    const value = this.items.concat([data]);
    this._items.next(value);

    return data;
  }

  last(): PopupDynamicComponentArguments {
    return this.items[this.items.length - 1];
  }

  pop() {
    const value = this.items.slice(0, this.items.length - 1);
    this._items.next(value);
  }

  remove(data: DynamicComponentArguments) {
    const value = this.items.filter(item => item !== data);
    this._items.next(value);
  }

  clear() {
    this._items.next([]);

    this._overlays.forEach(item => item.dispose());
    this._overlays = [];
  }

  get items(): PopupDynamicComponentArguments[] {
    return this._items.value;
  }

  get items$(): Observable<PopupDynamicComponentArguments[]> {
    return this._items.asObservable();
  }

  showComponent<T>(options: {
    component: Type<T>;
    injector: Injector;
    inputs?: Partial<T>;
    init?: (instance: T) => void;
    animate?: boolean;
    scrollable?: boolean;
    allowBodyScroll?: boolean;
    backdrop?: boolean;
    centerHorizontally?: boolean;
    centerVertically?: boolean;
  }): PopupRef<T> {
    options = defaults(options, { animate: true, backdrop: true, centerHorizontally: true, centerVertically: true });

    const subscriptions: Subscription[] = [];
    const position = this.overlay.position().global();

    if (options.centerHorizontally) {
      position.centerHorizontally();
    }

    if (options.centerVertically) {
      position.centerVertically();
    }

    const overlayRef = this.overlay.create({
      positionStrategy: position,
      scrollStrategy: options.allowBodyScroll
        ? this.overlay.scrollStrategies.reposition()
        : this.overlay.scrollStrategies.block(),
      hasBackdrop: options.backdrop,
      backdropClass: 'popup-background',
      panelClass: options.scrollable ? 'cdk-overlay-pane_scrollable' : undefined
    });

    this._overlays.push(overlayRef);

    const popupRef = new PopupRef({
      overlayRef: overlayRef,
      animate: options.animate
    });

    const injector = Injector.create([{ provide: PopupRef, useValue: popupRef }], options.injector);
    const portal = new ComponentPortal<T>(options.component, null, injector);
    const componentRef = overlayRef.attach(portal);

    if (options.inputs) {
      Object.assign(componentRef.instance, options.inputs);
    }

    if (options.init) {
      options.init(componentRef.instance);
    }

    popupRef.popup = componentRef.location.nativeElement;
    popupRef.backdrop = overlayRef.backdropElement;
    popupRef.instance = componentRef.instance;

    subscriptions.push(
      fromEvent<MouseEvent>(componentRef.location.nativeElement, 'mousedown').subscribe(e => {
        markPopupMouseEvent(e);
      })
    );

    if (overlayRef.backdropElement) {
      subscriptions.push(
        fromEvent<MouseEvent>(overlayRef.backdropElement, 'mousedown').subscribe(e => {
          markPopupMouseEvent(e);
        })
      );
    }

    overlayRef
      .detachments()
      .pipe(first(), untilDestroyed(this))
      .subscribe(() => {
        this._overlays = this._overlays.filter(item => item !== overlayRef);
        subscriptions.forEach(item => item.unsubscribe());
      });

    popupRef.show();

    return popupRef;
  }
}
