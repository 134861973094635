import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-disabled',
  templateUrl: './disabled.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DisabledComponent implements OnInit {
  @Input() @HostBinding('class.disabled_active') active = true;
  @Input() @HostBinding('class.disabled_transparent') transparent = false;
  @Input() @HostBinding('class.disabled_clickable') clickable = false;

  @HostBinding('class.disabled') cls = true;

  constructor() {}

  ngOnInit() {}
}
