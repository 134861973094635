<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayPanelClass]="classes"
  [cdkConnectedOverlayPositions]="popoverPositions"
  [cdkConnectedOverlayOrigin]="origin"
  [cdkConnectedOverlayOpen]="dropdownOpened"
  [cdkConnectedOverlayPush]="true"
  [cdkConnectedOverlayGrowAfterOpen]="true"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayBackdropClass]="'popover2-backdrop'"
  (backdropClick)="setDropdownOpened(false)"
  (detach)="setDropdownOpened(false)"
>
  <div class="popover2 overlay-content overlay-content_animated">
    <div class="popover2__inner">
      <app-font-menu
        [value]="value"
        [globalFonts]="globalFonts"
        (fontSelect)="fontSelect.emit($event); close()"
      ></app-font-menu>

      <div class="popover2__arrow"></div>
    </div>
  </div>
</ng-template>
