import { Injectable, OnDestroy } from '@angular/core';
import isEqual from 'lodash/isEqual';
import last from 'lodash/last';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { DynamicComponentArguments } from '@common/dynamic-component';
import { CustomizeService } from '@modules/customize';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';

@Injectable()
export class CustomizeBarContext implements OnDestroy {
  _settingsComponents = new BehaviorSubject<DynamicComponentArguments[]>([]);
  _highlighted = new Subject<void>();

  constructor(
    private customizeService: CustomizeService,
    public currentProjectStore: CurrentProjectStore,
    public currentEnvironmentStore: CurrentEnvironmentStore
  ) {
    this.customizeService.enabled$
      .pipe(
        filter(enabled => !enabled),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.resetSettingsComponent();
      });
  }

  ngOnDestroy(): void {}

  setSettingsComponent(component: DynamicComponentArguments, options: { append?: boolean; modal?: boolean } = {}) {
    // const lastComponent = this.settingsComponent;
    //
    // if (isEqual(
    //   lastComponent ? [lastComponent.component, lastComponent.inputs] : undefined,
    //   [component.component, component.inputs]
    // )) {
    //   return;
    // }

    if (options.append) {
      this.appendSettingsComponent(component);
    } else {
      this._settingsComponents.next([component]);
    }
  }

  setSettingsComponents(components: DynamicComponentArguments[]) {
    this._settingsComponents.next(components);
  }

  appendSettingsComponent(component: DynamicComponentArguments) {
    const value = this._settingsComponents.value.concat([component]);
    this._settingsComponents.next(value);
  }

  popSettingsComponent() {
    const value = this._settingsComponents.value;
    value.pop();
    this._settingsComponents.next(value);
  }

  resetSettingsComponent() {
    this._settingsComponents.next([]);
  }

  closeSettingsComponent(component: DynamicComponentArguments) {
    const value = this._settingsComponents.value;
    const index = value.indexOf(component);

    if (index == -1) {
      return;
    }

    this._settingsComponents.next(value.slice(0, index));
  }

  get settingsComponents$(): Observable<DynamicComponentArguments[]> {
    return this._settingsComponents.asObservable();
  }

  get settingsComponents(): DynamicComponentArguments[] {
    return this._settingsComponents.value;
  }

  get settingsComponent(): DynamicComponentArguments {
    return last(this._settingsComponents.value);
  }

  get settingsComponent$(): Observable<DynamicComponentArguments> {
    return this._settingsComponents.pipe(map(item => last(item)));
  }

  highlight() {
    this._highlighted.next();
  }

  get highlighted$(): Observable<void> {
    return this._highlighted.asObservable();
  }
}
