import { Injectable } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup } from '@angular/forms';

import { ViewContext } from '@modules/customize';
import { imageFieldFits, Input, Input as FieldInput, VideoOutputFormat } from '@modules/fields';
import { FieldInputControl } from '@modules/parameters';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';

@Injectable()
export class VideoFieldViewParamsForm {
  form: FormGroup;
  control: AbstractControl;
  outputFormatOptions = [
    { value: VideoOutputFormat.URL, name: 'Specify URL' },
    { value: VideoOutputFormat.Storage, name: 'Save to Storage' },
    { value: VideoOutputFormat.YoutubeID, name: 'Youtube Video ID' },
    { value: VideoOutputFormat.VimeoID, name: 'Vimeo Video ID' }
  ];
  fitOptions = imageFieldFits;

  constructor(
    private fb: FormBuilder,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore
  ) {
    this.form = this.fb.group({
      output_format: new FormControl(VideoOutputFormat.Storage),
      storage: new FormControl(undefined),
      path: new FieldInputControl({ path: ['value'] }),
      fit: new FormControl(imageFieldFits[0].value)
    });
  }

  init(control: AbstractControl, options: { context?: ViewContext } = {}) {
    this.control = control;

    const params = control.value || {};
    const storage = this.currentProjectStore.instance.getStorage(
      this.currentEnvironmentStore.instance.uniqueName,
      params['storage_resource'],
      params['storage_name'],
      { defaultFirst: true, contextResource: options.context ? options.context.resource : undefined }
    );
    const value = {
      storage: storage ? { resource: storage.resource.uniqueName, name: storage.storage.uniqueName } : undefined
    };

    if (control.value) {
      value['output_format'] = params['output_format'] || VideoOutputFormat.Storage;
      value['fit'] = params['fit'];

      // Backward compatibility
      if (typeof params['path'] == 'string') {
        value['path'] = new Input().deserializeFromStatic('value', params['path']).serializeWithoutPath();
      } else if (params['path']) {
        value['path'] = new Input().deserialize(params['path']).serializeWithoutPath();
      }
    }

    this.form.patchValue(value, { emitEvent: false });
    this.form.valueChanges.subscribe(() => this.submit());
  }

  submit() {
    const value = this.form.value;
    const params = {
      output_format: value['output_format'],
      path: value['path'] ? new FieldInput().deserialize(value['path']).serialize() : undefined,
      fit: value['fit']
    };

    if (value['storage']) {
      params['storage_resource'] = value['storage']['resource'];
      params['storage_name'] = value['storage']['name'];
    }

    this.control.patchValue({
      ...this.control.value,
      ...params
    });
  }
}
