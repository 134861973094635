var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormGroup } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { FieldInputControl } from '@modules/parameters';
import { ForkWorkflowStepItem } from '@modules/workflow';
var ForkCustomizeWorkflowStepItemControl = /** @class */ (function (_super) {
    __extends(ForkCustomizeWorkflowStepItemControl, _super);
    function ForkCustomizeWorkflowStepItemControl() {
        return _super.call(this, {
            condition: new FieldInputControl({ path: ['value'] })
        }) || this;
    }
    ForkCustomizeWorkflowStepItemControl.prototype.init = function (instance, options) {
        if (options === void 0) { options = {}; }
        this.instance = instance;
        if (!options.firstInit) {
            this.markAsDirty();
        }
    };
    ForkCustomizeWorkflowStepItemControl.prototype.submit = function () {
        var instance = this.instance ? cloneDeep(this.instance) : new ForkWorkflowStepItem();
        if (!instance.uid) {
            instance.generateUid();
        }
        return instance;
    };
    return ForkCustomizeWorkflowStepItemControl;
}(FormGroup));
export { ForkCustomizeWorkflowStepItemControl };
