import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import isEqual from 'lodash/isEqual';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, Observable } from 'rxjs';
import { first } from 'rxjs/operators';

import { ProjectSettingsStore } from '@modules/all-project-settings';
import { Option } from '@modules/field-components';
import { createFormFieldFactory, NumberFieldType } from '@modules/fields';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { FontType, getFontFamilyVariable } from '@modules/styles';
import { globalFontOptions, ThemeService } from '@modules/theme';
import { isSet } from '@shared';

import { TextStyleControl } from './text-style.control';

@Component({
  selector: 'app-text-style-edit',
  templateUrl: './text-style-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextStyleEditComponent implements OnInit, OnDestroy {
  @Input() control: TextStyleControl;
  @Input() resetEnabled = true;

  createField = createFormFieldFactory();
  loadingDefault = true;
  loadingDefaultSave = false;
  fontTypeOptions$: Observable<Option<FontType>[]>;
  isStyleDefault = false;
  defaultPlaceholders = {
    color: undefined,
    colorDark: undefined,
    fontFamily: '',
    fontType: '',
    fontSize: '',
    letterSpacing: ''
  };
  numberFieldTypes = NumberFieldType;

  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private projectSettingsStore: ProjectSettingsStore,
    public themeService: ThemeService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.fontTypeOptions$ = this.control.getFontTypeOptions$();

    this.control
      .isDefaultStyle$()
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        this.isStyleDefault = value;
        this.loadingDefault = false;
        this.cd.markForCheck();
      });

    combineLatest(this.control.getStyleDefault$(false), this.projectSettingsStore.getAllSettingsFirst$())
      .pipe(untilDestroyed(this))
      .subscribe(([value, projectSettings]) => {
        const defaultStyle = value || this.control.defaultValues;
        const defaultFontFamilyVariable = getFontFamilyVariable(defaultStyle.fontFamily);
        const defaultFontFamilyGlobal = isSet(defaultFontFamilyVariable)
          ? globalFontOptions.find(item => item.value == `--${defaultFontFamilyVariable}`)
          : undefined;
        const defaultFontName = defaultFontFamilyGlobal ? defaultFontFamilyGlobal.name : defaultStyle.fontFamily;
        const defaultFontTypes = this.control.getFontTypeOptionsForValue(defaultFontName, projectSettings);
        const defaultFontTypeValue: FontType = { weight: defaultStyle.fontWeight, style: defaultStyle.fontStyle };
        const defaultFontType = defaultFontTypes.find(item =>
          this.control.fontTypeEquals(item.value, defaultFontTypeValue)
        );

        this.defaultPlaceholders = {
          color: defaultStyle.color || undefined,
          colorDark: defaultStyle.colorDark || undefined,
          fontFamily: defaultFontName || '',
          fontType: defaultFontType ? defaultFontType.name : '',
          fontSize: String(defaultStyle.fontSize || 12),
          letterSpacing: String(defaultStyle.letterSpacing || 0)
        };
      });
  }

  ngOnDestroy(): void {}

  // saveAsDefaults() {
  //   const style = this.control.getInstance();
  //   const updateProjectSettings = new AllProjectSettings({ [this.control.options.globalSetting]: style });
  //   const settingName = updateProjectSettings.settingsTypes.find(item => {
  //     return item.properties.includes(this.control.options.globalSetting);
  //   });
  //
  //   if (!settingName) {
  //     return;
  //   }
  //
  //   this.loadingDefaultSave = true;
  //   this.cd.markForCheck();
  //
  //   this.projectSettingsService
  //     .create(
  //       this.currentProjectStore.instance.uniqueName,
  //       this.currentEnvironmentStore.instance.uniqueName,
  //       updateProjectSettings.serialize([settingName.name])[0]
  //     )
  //     .pipe(
  //       tap(result => this.projectSettingsStore.updateOrAddItem(result)),
  //       untilDestroyed(this)
  //     )
  //     .subscribe(
  //       () => {
  //         this.control.resetDefaults();
  //
  //         this.loadingDefaultSave = false;
  //         this.cd.markForCheck();
  //       },
  //       () => {
  //         this.loadingDefaultSave = false;
  //         this.cd.markForCheck();
  //       }
  //     );
  // }

  resetToDefaults() {
    this.control.resetDefaults();
  }

  onFontSelect(value: string) {
    this.projectSettingsStore
      .getAllSettingsFirst$()
      .pipe(first(), untilDestroyed(this))
      .subscribe(projectSettings => {
        const fontTypes = this.control.getFontTypeOptionsForValue(value, projectSettings);
        const fontTypeValue: FontType = this.control.controls.font_type.value;
        const fontType = fontTypes.find(item => this.control.fontTypeEquals(item.value, fontTypeValue));

        if (!fontType && fontTypes.length) {
          this.control.controls.font_type.patchValue(fontTypes[0].value);
        }
      });
  }
}
