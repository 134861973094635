import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { ViewContext, ViewContextElement } from '@modules/customize';
import { BaseField, createFormFieldFactory } from '@modules/fields';
import { TypedChanges } from '@shared';

@Component({
  selector: 'app-filter-edit-value',
  templateUrl: './filter-edit-value.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterEditValueComponent implements OnInit, OnChanges {
  @Input() control: FormControl;
  @Input() valueField: BaseField;
  @Input() autoFocus = false;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;

  createField = createFormFieldFactory();
  form: FormGroup;

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: TypedChanges<FilterEditValueComponent>): void {
    this.form = new FormGroup({ control: this.control });
  }
}
