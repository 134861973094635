var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { fadeColorStr, getColorHexAStr } from '@modules/colors';
import { getTintColors } from '@modules/colors-shared';
import { TextStyleGlobalType } from '@modules/customize';
import { TextTransform } from '@modules/styles';
import { headingFontName, regularFontName } from '@modules/theme';
import { isSet } from '@shared';
export var textStyleGlobals = [
    {
        name: TextStyleGlobalType.regularTitleTextStyle,
        defaults: function (_a) {
            var settings = _a.settings;
            return {
                color: settings && isSet(settings.textColor) ? settings.textColor : '#1D2329',
                colorDark: settings && isSet(settings.textColorDark) ? settings.textColorDark : '#FFFFFF',
                fontFamily: headingFontName,
                fontSize: 18,
                fontWeight: 400
            };
        }
    },
    {
        name: TextStyleGlobalType.listGroupTitleTextStyle,
        defaults: function (_a) {
            var settings = _a.settings;
            return {
                color: settings && isSet(settings.textColor2) ? settings.textColor2 : '#768191',
                colorDark: settings && isSet(settings.textColor2Dark) ? settings.textColor2Dark : '#9CA5AF',
                backgroundColor: '#0000000D',
                backgroundColorDark: '#FFFFFF0D',
                fontFamily: regularFontName,
                fontSize: 10,
                fontWeight: 500,
                transform: TextTransform.Uppercase
            };
        }
    },
    {
        name: TextStyleGlobalType.tableGroupTitleTextStyle,
        defaults: function (_a) {
            var settings = _a.settings;
            return {
                color: settings && isSet(settings.textColor2) ? settings.textColor2 : '#768191',
                colorDark: settings && isSet(settings.textColor2Dark) ? settings.textColor2Dark : '#9CA5AF',
                backgroundColor: '#0000000D',
                backgroundColorDark: '#FFFFFF0D',
                fontFamily: regularFontName,
                fontSize: 10,
                fontWeight: 500,
                transform: TextTransform.Uppercase
            };
        }
    },
    {
        name: TextStyleGlobalType.kanbanStageTitleTextStyle,
        defaults: function (_a) {
            var settings = _a.settings;
            return {
                color: settings && isSet(settings.textColor2) ? settings.textColor2 : '#768191',
                colorDark: settings && isSet(settings.textColor2Dark) ? settings.textColor2Dark : '#9CA5AF',
                backgroundColor: '#0000000D',
                backgroundColorDark: '#FFFFFF0D',
                fontFamily: regularFontName,
                fontSize: 10,
                fontWeight: 500,
                transform: TextTransform.Uppercase
            };
        }
    },
    {
        name: TextStyleGlobalType.fieldLabelTextStyle,
        defaults: function (_a) {
            var settings = _a.settings;
            return {
                color: settings && isSet(settings.textColor2) ? settings.textColor2 : '#768191',
                colorDark: settings && isSet(settings.textColor2Dark) ? settings.textColor2Dark : '#9CA5AF',
                fontFamily: regularFontName,
                fontSize: 11,
                fontWeight: 500,
                transform: TextTransform.Uppercase
            };
        }
    },
    {
        name: TextStyleGlobalType.fieldLabelAdditionalTextStyle,
        defaults: function (_a) {
            var settings = _a.settings;
            return {
                color: settings && isSet(settings.textColor3) ? settings.textColor3 : '#BAC3D0',
                colorDark: settings && isSet(settings.textColor3Dark) ? settings.textColor3Dark : '#5F6B78',
                fontFamily: regularFontName,
                fontSize: 11,
                fontWeight: 500,
                transform: TextTransform.Uppercase
            };
        }
    },
    {
        name: TextStyleGlobalType.valueWidgetTitleTextStyle,
        defaults: function (_a) {
            var settings = _a.settings;
            return {
                color: settings && isSet(settings.textColor2) ? settings.textColor2 : '#768191',
                colorDark: settings && isSet(settings.textColor2Dark) ? settings.textColor2Dark : '#616A75',
                fontFamily: settings && isSet(settings.fontRegular) ? settings.fontRegular : undefined,
                fontSize: 14,
                fontWeight: 400
            };
        }
    },
    {
        name: TextStyleGlobalType.valueWidgetValueTextStyle,
        defaults: function (_a) {
            var settings = _a.settings;
            return {
                color: settings && isSet(settings.textColor) ? settings.textColor : '#1E1F22',
                colorDark: settings && isSet(settings.textColorDark) ? settings.textColorDark : '#FFFFFF',
                fontFamily: regularFontName,
                fontSize: 30
            };
        }
    },
    {
        name: TextStyleGlobalType.collapseTitleTextStyle,
        defaults: function (_a) {
            var settings = _a.settings;
            return {
                color: settings && isSet(settings.textColor2) ? settings.textColor2 : '#768191',
                colorDark: settings && isSet(settings.textColor2Dark) ? settings.textColor2Dark : '#9CA5AF',
                fontFamily: regularFontName,
                fontSize: 14,
                fontWeight: 500
            };
        }
    }
].concat([
    {
        name: TextStyleGlobalType.buttonTextStyle,
        defaults: function (_a) {
            var settings = _a.settings, tint = _a.tint;
            var colors = getTintColors(settings.accentColor || '#2B50ED', tint);
            var colorsDark = getTintColors(settings.accentColorDark || '#2B50ED', tint);
            return __assign({}, (colors &&
                colors.textColor && {
                color: getColorHexAStr(colors.textColor)
            }), (colorsDark &&
                colorsDark.textColor && {
                colorDark: getColorHexAStr(colorsDark.textColor)
            }), { fontFamily: regularFontName, fontSize: 12, fontWeight: 500 });
        }
    },
    {
        name: TextStyleGlobalType.hoverButtonTextStyle,
        defaults: function (_a) {
            var settings = _a.settings, tint = _a.tint;
            var colors = getTintColors(settings.accentColor || '#2B50ED', tint);
            var colorsDark = getTintColors(settings.accentColorDark || '#2B50ED', tint);
            return __assign({}, (colors &&
                colors.textHoverColor && {
                color: getColorHexAStr(colors.textHoverColor)
            }), (colorsDark &&
                colorsDark.textHoverColor && {
                colorDark: getColorHexAStr(colorsDark.textHoverColor)
            }), { fontFamily: regularFontName, fontSize: 12, fontWeight: 500 });
        }
    },
    {
        name: TextStyleGlobalType.activeButtonTextStyle,
        defaults: function (_a) {
            var settings = _a.settings, tint = _a.tint;
            var colors = getTintColors(settings.accentColor || '#2B50ED', tint);
            var colorsDark = getTintColors(settings.accentColorDark || '#2B50ED', tint);
            return __assign({}, (colors &&
                colors.textActiveColor && {
                color: getColorHexAStr(colors.textActiveColor)
            }), (colorsDark &&
                colorsDark.textActiveColor && {
                colorDark: getColorHexAStr(colorsDark.textActiveColor)
            }), { fontFamily: regularFontName, fontSize: 12, fontWeight: 500 });
        }
    }
], [
    TextStyleGlobalType.fieldTextStyle,
    TextStyleGlobalType.hoverFieldTextStyle,
    TextStyleGlobalType.focusFieldTextStyle,
    TextStyleGlobalType.errorFieldTextStyle
].map(function (item) {
    return {
        name: item,
        defaults: function (_a) {
            var settings = _a.settings;
            return {
                color: settings && isSet(settings.textColor2) ? settings.textColor2 : '#768191',
                colorDark: settings && isSet(settings.textColor2Dark) ? settings.textColor2Dark : '#9CA5AF',
                fontFamily: regularFontName,
                fontSize: 14,
                fontWeight: 400
            };
        }
    };
}), [
    TextStyleGlobalType.fieldPlaceholderTextStyle,
    TextStyleGlobalType.hoverFieldPlaceholderTextStyle,
    TextStyleGlobalType.focusFieldPlaceholderTextStyle,
    TextStyleGlobalType.errorFieldPlaceholderTextStyle
].map(function (item) {
    return {
        name: item,
        defaults: function (_a) {
            var settings = _a.settings;
            return {
                color: settings && isSet(settings.textColor2) ? fadeColorStr(settings.textColor2, 0.5) : '#76819180',
                colorDark: settings && isSet(settings.textColor2Dark) ? fadeColorStr(settings.textColor2Dark, 0.5) : '#9CA5AF80',
                fontFamily: regularFontName,
                fontSize: 14,
                fontWeight: 400
            };
        }
    };
}));
