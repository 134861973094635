import { ChangeDetectorRef, EventEmitter, OnDestroy, OnInit, Provider } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';
import { debounceTime, filter, first, map, skip } from 'rxjs/operators';
import { DialogService } from '@common/dialogs';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { cleanElementName, ITEM_OUTPUT, ModelElementItem, ViewContext, ViewContextElement } from '@modules/customize';
import { createFormFieldFactory } from '@modules/fields';
import { SidebarCollapseContext } from '@modules/sidebar';
import { controlValid } from '@shared';
import { CustomizeBarEditEventType } from '../../data/customize-bar-edit-event-type';
import { CustomizeBarContext } from '../../services/customize-bar-context/customize-bar.context';
import { CUSTOMIZE_MODEL_COMPONENT } from '../../services/customize-bar/customize-bar.service';
import { ElementConfiguration, trackConfigured } from '../../utils/analytics';
import { DisplayFieldsEditComponent } from '../display-fields-edit/display-fields-edit.component';
import { CustomizeBarModelEditDataComponent } from './customize-bar-model-edit-data/customize-bar-model-edit-data.component';
import { CustomizeBarModelEditForm } from './customize-bar-model-edit.form';
export var CUSTOMIZE_MODEL_COMPONENT_PROVIDER = {
    provide: CUSTOMIZE_MODEL_COMPONENT,
    useFactory: customizeModelComponentFactory
};
export function customizeModelComponentFactory() {
    return CustomizeBarModelEditComponent;
}
var CustomizeBarModelEditComponent = /** @class */ (function () {
    function CustomizeBarModelEditComponent(form, customizeBarContext, dialogService, cd, analyticsService) {
        var _this = this;
        this.form = form;
        this.customizeBarContext = customizeBarContext;
        this.dialogService = dialogService;
        this.cd = cd;
        this.analyticsService = analyticsService;
        this.titleEnabled = true;
        this.deleteEnabled = false;
        this.firstInit = false;
        this.setupOnCreate = false;
        this.event = new EventEmitter();
        this.createField = createFormFieldFactory();
        this.loading = false;
        this.collapseContext = new SidebarCollapseContext();
        this.configurationStarted = false;
        this.columnsContextElementPaths = [[ITEM_OUTPUT]];
        this.visibleContextElementPaths = [[ITEM_OUTPUT]];
        this.itemContextElementPath = [ITEM_OUTPUT];
        this.titleCleanValue = (function () {
            return function (value) {
                return cleanElementName(value, _this.element, _this.context.getElementItems());
            };
        })();
    }
    CustomizeBarModelEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.form.init(this.element, this.firstInit);
        var resultObs = this.form.valueChanges.pipe(debounceTime(200), map(function () { return _this.form.submit(); }));
        resultObs.pipe(untilDestroyed(this)).subscribe(function (result) {
            _this.result = result;
            _this.emitUpdate();
        });
        resultObs
            .pipe(map(function (result) { return _this.form.isConfigured(result); }), trackConfigured(), first(function (configuration) { return configuration == ElementConfiguration.Started; }), untilDestroyed(this))
            .subscribe(function () {
            _this.configurationStarted = true;
            _this.analyticsService.sendSimpleEvent(AnalyticsEvent.Component.StartedConfiguration, {
                ComponentTypeID: _this.element.analyticsName,
                ResourceType: _this.resource ? _this.resource.typeItem.name : undefined,
                ResourceDemo: _this.resource ? _this.resource.demo : false
            });
        });
        if (this.setupOnCreate) {
            this.openGetQuery();
        }
        this.form.controls.data_source
            .getResource$()
            .pipe(untilDestroyed(this))
            .subscribe(function (resource) {
            _this.resource = resource;
        });
        this.form.controls.data_source
            .getModelDescription$()
            .pipe(untilDestroyed(this))
            .subscribe(function (modelDescription) {
            _this.modelDescription = modelDescription;
        });
        this.form.controls.data_source
            .getResource$()
            .pipe(skip(1), untilDestroyed(this))
            .subscribe(function (resource) {
            if (resource) {
                _this.analyticsService.sendSimpleEvent(AnalyticsEvent.ComponentData.ResourceSelected, {
                    ComponentTypeID: _this.element.analyticsName,
                    ResourceID: resource ? resource.typeItem.name : undefined,
                    ResourceDemo: resource ? resource.demo : false
                });
            }
        });
        combineLatest(this.form.controls.data_source.getResource$().pipe(skip(1)), this.form.controls.data_source.getModelDescription$().pipe(skip(1)))
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var resource = _a[0], modelDescription = _a[1];
            if (modelDescription) {
                _this.analyticsService.sendSimpleEvent(AnalyticsEvent.ComponentData.ResourceItemSelected, {
                    ComponentTypeID: _this.element.analyticsName,
                    ResourceID: resource ? resource.typeItem.name : undefined,
                    ResourceDemo: resource ? resource.demo : false
                });
            }
        });
        this.queryConfigured$ = this.form.controls.data_source.getQueryConfigured$();
        this.dataConfigured$ = controlValid(this.form.controls.data_source);
        controlValid(this.form.controls.data_source)
            .pipe(filter(function (configured, i) { return configured && i > 0; }), untilDestroyed(this))
            .subscribe(function () {
            _this.analyticsService.sendSimpleEvent(AnalyticsEvent.ComponentData.SuccessfullySetUp, {
                ComponentTypeID: _this.element.analyticsName
            });
        });
        controlValid(this.form.controls.data_source.controls.query_resource)
            .pipe(filter(function (configured, i) { return configured && i > 0; }), untilDestroyed(this))
            .subscribe(function () {
            _this.analyticsService.sendSimpleEvent(AnalyticsEvent.ComponentData.ResourceItemSuccessfullySetUp, {
                ComponentTypeID: _this.element.analyticsName
            });
        });
        controlValid(this.form.controls.data_source.controls.query_inputs)
            .pipe(filter(function (configured, i) { return configured && i > 0; }), untilDestroyed(this))
            .subscribe(function () {
            _this.analyticsService.sendSimpleEvent(AnalyticsEvent.ComponentData.InputsSuccessfullySetUp, {
                ComponentTypeID: _this.element.analyticsName
            });
        });
        if (this.setupOnCreate) {
            this.openGetQuery();
        }
    };
    CustomizeBarModelEditComponent.prototype.ngOnDestroy = function () { };
    CustomizeBarModelEditComponent.prototype.serializeColumn = function (value) {
        return value;
    };
    CustomizeBarModelEditComponent.prototype.deserializeColumn = function (value) {
        return value;
    };
    CustomizeBarModelEditComponent.prototype.resetColumns = function () {
        var _this = this;
        this.dialogService
            .warning({
            title: "Are you sure want to reset component columns?",
            description: "\n          The component columns will be reset to their default values.\n        ",
            style: 'orange'
        })
            .pipe(filter(function (result) { return !!result; }), untilDestroyed(this))
            .subscribe(function () {
            _this.form.controls.data_source.resetInputColumns({
                context: _this.context,
                contextElement: _this.contextElement,
                markAsDirty: true
            });
        });
    };
    CustomizeBarModelEditComponent.prototype.openGetQuery = function (options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        this.customizeBarContext.appendSettingsComponent({
            component: CustomizeBarModelEditDataComponent,
            inputs: {
                element: this.element,
                name: this.form.controls.name.value,
                form: this.form,
                context: this.context,
                contextElement: this.contextElement,
                contextElementPath: this.contextElementPath,
                contextElementPaths: this.contextElementPaths,
                setupOnCreate: options.setupOnCreate,
                addInput: options.addInput
            },
            outputs: {
                event: [
                    function () {
                        _this.customizeBarContext.popSettingsComponent();
                    }
                ]
            }
        });
    };
    CustomizeBarModelEditComponent.prototype.close = function () {
        if (this.configurationStarted && this.form.isConfigured(this.result)) {
            this.analyticsService.sendSimpleEvent(AnalyticsEvent.Component.SuccessfullyConfigured, {
                ComponentTypeID: this.element.analyticsName,
                ResourceDemo: this.resource ? this.resource.demo : false
            });
        }
        this.customizeBarContext.popSettingsComponent();
    };
    CustomizeBarModelEditComponent.prototype.submit = function () {
        this.result = this.form.submit();
        this.emitUpdate(true);
        this.close();
    };
    CustomizeBarModelEditComponent.prototype.onTitleChanged = function (name) {
        this.form.controls.name.setValue(name);
    };
    CustomizeBarModelEditComponent.prototype.emitUpdate = function (submit) {
        if (submit === void 0) { submit = false; }
        // TODO: Add args interface
        var args = { result: this.result, submit: submit };
        if (this.element) {
            this.event.emit({ type: CustomizeBarEditEventType.Updated, args: args });
        }
        else {
            this.event.emit({ type: CustomizeBarEditEventType.Created, args: args });
        }
    };
    CustomizeBarModelEditComponent.prototype.cancel = function () {
        this.event.emit({ type: CustomizeBarEditEventType.Canceled });
        this.close();
    };
    CustomizeBarModelEditComponent.prototype.delete = function () {
        this.event.emit({ type: CustomizeBarEditEventType.Deleted });
        this.close();
    };
    return CustomizeBarModelEditComponent;
}());
export { CustomizeBarModelEditComponent };
