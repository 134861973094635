<div class="circle-progress" [class.circle-progress_inline]="inline" [class.circle-progress_theme]="theme">
  <span class="circle-progress__inner">
    <span class="circle-progress__progress">
      <span class="circle-progress__background"></span>
      <span class="circle-progress__circle">
        <svg
          [class.loading-animation]="!(progress | appIsSet)"
          xmlns="http://www.w3.org/2000/svg"
          width="102"
          height="102"
          viewBox="0 0 102 102"
        >
          <g id="Desktop-Design" fill="none" fill-rule="evenodd">
            <g transform="translate(-6 -7)">
              <g id="Group-52" transform="rotate(3 -3.783 112.783)">
                <path
                  [hidden]="!(progress | appIsSet)"
                  id="active"
                  d="
                M 9.11126596,57.2490757
                C 10.4458328,82.7141288 30.9396599,101.166854 56.4047131,99.8322867
                C 81.8697662,98.4977198 100.322491,78.0038927 98.9879241,52.5388396
                C 97.6533572,27.0737865 77.1595301,8.62106167 51.694477,9.95562856
                C 26.2294239,11.2901954 7.77669908,31.7840225 9.11126596,57.2490757
                Z"
                  stroke="currentColor"
                  stroke-width="12"
                  stroke-linecap="round"
                  #circle
                />
              </g>
            </g>
          </g>
        </svg>
      </span>
      <span class="circle-progress__percent" [class.loading-animation]="!(progress | appIsSet)" #percent> </span>
    </span>
    <span *ngIf="label" class="circle-progress__label">
      {{ label }}
    </span>
  </span>
</div>
