import isEqual from 'lodash/isEqual';
import keys from 'lodash/keys';
import { combineLatest, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { traverseElementItemFonts, ViewSettingsStore } from '@modules/customize';
import { CurrentEnvironmentStore, CurrentProjectStore, ProjectSettingsName, ProjectSettingsService } from '@modules/projects';
import { defaultFontName } from '@modules/theme';
import { AllProjectSettings } from '../../data/all-project-settings';
import { ProjectSettingsStore } from '../../stores/project-settings.store';
var PageFontsService = /** @class */ (function () {
    function PageFontsService(currentProjectStore, currentEnvironmentStore, viewSettingsStore, projectSettingsService, projectSettingsStore) {
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.viewSettingsStore = viewSettingsStore;
        this.projectSettingsService = projectSettingsService;
        this.projectSettingsStore = projectSettingsStore;
    }
    PageFontsService.prototype.updateFontsUsed = function () {
        var _this = this;
        return combineLatest(this.viewSettingsStore.getFirst(), this.projectSettingsStore.getAllSettingsFirst$()).pipe(switchMap(function (_a) {
            var viewSettings = _a[0], existingProjectSettings = _a[1];
            var fontsObject = {};
            traverseElementItemFonts(viewSettings, function (font) {
                fontsObject[font] = true;
            });
            var globalFonts = [defaultFontName];
            if (existingProjectSettings && existingProjectSettings.fontHeading) {
                globalFonts.push(existingProjectSettings.fontHeading);
            }
            if (existingProjectSettings && existingProjectSettings.fontRegular) {
                globalFonts.push(existingProjectSettings.fontRegular);
            }
            var fonts = keys(fontsObject).filter(function (item) { return !globalFonts.includes(item); });
            if (existingProjectSettings && isEqual(existingProjectSettings.fontsUsed, fonts)) {
                return of(false);
            }
            var projectSettings = new AllProjectSettings({ fontsUsed: fonts });
            return _this.projectSettingsService
                .create(_this.currentProjectStore.instance.uniqueName, _this.currentEnvironmentStore.instance.uniqueName, projectSettings.serialize([ProjectSettingsName.FontsUsed])[0])
                .pipe(tap(function (result) { return _this.projectSettingsStore.updateOrAddItem(result); }), map(function () { return true; }));
        }));
    };
    return PageFontsService;
}());
export { PageFontsService };
