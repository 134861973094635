import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { MarginControl, ViewContext, ViewContextElement } from '@modules/customize';
import { applyParamInput$ } from '@modules/fields';
import { controlValue } from '@shared';

import { AccordionItemControl } from '../customize-bar-accordion-edit/accordion-item.control';
import { CustomizeBarAccordionEditForm } from '../customize-bar-accordion-edit/customize-bar-accordion-edit.form';
import { Style, StyleItem, StylesEditComponent, StyleType } from '../styles-edit/styles-edit.component';
import { TextStyleControl } from '../text-style-edit/text-style.control';

@Component({
  selector: 'app-styles-accordion-element',
  templateUrl: './styles-accordion-element-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StylesAccordionElementEditComponent implements OnInit, OnDestroy {
  @Input() control: CustomizeBarAccordionEditForm;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;

  @ViewChild(StylesEditComponent) stylesEditComponent: StylesEditComponent;

  styles: StyleItem[] = [];

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.updateStyles();
  }

  ngOnDestroy(): void {}

  updateStyles() {
    controlValue(this.control.controls.items)
      .pipe(
        switchMap<any, { control: AccordionItemControl; title: string }[]>(() => {
          const obs$ = this.control.controls.items.controls.map(itemControl => {
            return controlValue(itemControl.controls.title).pipe(
              map(() => itemControl.controls.title.serialize()),
              switchMap(titleInput => {
                if (!titleInput) {
                  return of(undefined);
                }

                return applyParamInput$<string>(titleInput, {
                  context: this.context,
                  contextElement: this.contextElement,
                  defaultValue: ''
                });
              }),
              map(title => {
                return {
                  control: itemControl,
                  title: title
                };
              })
            );
          });

          if (!obs$.length) {
            return of([]);
          }

          return combineLatest(obs$);
        }),
        untilDestroyed(this)
      )
      .subscribe(sections => {
        this.styles = [
          ...sections.map(section => {
            return {
              style: {
                label: `${section.title} Title`,
                icon: 'text',
                type: StyleType.TextStyle,
                control: section.control.controls.title_style,
                isSet$: control => control.isSet$(),
                onRemove: style => style.control.resetDefaults()
              } as Style<TextStyleControl>
            };
          }),
          {
            style: {
              label: 'Outer spacing',
              icon: 'align_horizontal_center',
              type: StyleType.Margin,
              control: this.control.controls.margin,
              isSet$: control => control.isSet$(),
              onRemove: style => style.control.reset()
            } as Style<MarginControl>
          }
        ];
        this.cd.markForCheck();
      });
  }
}
