<ng-container *ngIf="visible || (customizeEnabled$ | async)">
  <div class="steps steps_theme">
    <div class="steps__progress steps-progress steps-progress_theme">
      <div class="steps-progress__track">
        <div *ngFor="let item of state.items; trackBy: trackStepItem" class="steps-progress__track-item"></div>
      </div>
      <div class="steps-progress__fill-container">
        <div class="steps-progress__fill-start" [style.background-color]="state.currentItemColor || accentColor"></div>
        <div
          class="steps-progress__fill"
          [class.steps-progress__fill_first]="progress === 0"
          [class.steps-progress__fill_last]="progress === 1"
          [style.width.%]="progress * 100"
          [style.background-color]="state.currentItemColor || accentColor"
        >
          <div class="steps-progress__fill-thumb" [style.border-color]="state.currentItemColor || accentColor"></div>
        </div>
      </div>
    </div>

    <div class="steps__items">
      <ng-container *ngFor="let item of state.items; let i = index; trackBy: trackStepItem">
        <div
          class="steps__item"
          [class.steps__item_active]="state.currentItemIndex < i"
          [class.steps__item_current]="state.currentItemIndex >= i"
        >
          <div class="steps__item-info">
            <div *ngIf="item.item.name | appIsSet" class="steps__item-title">
              {{ item.item.name }}
            </div>

            <div *ngIf="item.item.description | appIsSet" class="steps__item-subtitle">
              {{ item.item.description }}
            </div>

            <span
              *ngIf="!item.visible && (customizeService.enabled$ | async)"
              class="steps__item-indicator icon-eye"
              [appTip]="'Step is hidden'"
            ></span>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>
