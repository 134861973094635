<div class="error" [class.error_fill]="fill" [class.error_compact]="compact" [class.error_theme]="theme">
  <div class="error__inner">
    <div class="error__icon"></div>
    <div *ngIf="title" class="error__title">{{ title }}</div>
    <div *ngIf="description" class="error__description">{{ description }}</div>
    <div class="error__description"><ng-content></ng-content></div>

    <div *ngIf="button" class="error__button">
      <a
        *ngIf="buttonLink"
        [routerLink]="buttonLink"
        class="button"
        [class.button_primary]="buttonPrimary"
        [class.button_disabled]="buttonDisabled"
        (click)="buttonClicked.emit()"
      >
        <span *ngIf="buttonIcon" class="button__icon button__icon_left" [ngClass]="'icon-' + buttonIcon"></span>
        <span class="button__label">{{ button }}</span>
      </a>
      <a
        *ngIf="!buttonLink"
        href="javascript:void(0)"
        class="button"
        [class.button_primary]="buttonPrimary"
        [class.button_disabled]="buttonDisabled"
        (click)="buttonClicked.emit()"
      >
        <span *ngIf="buttonIcon" class="button__icon button__icon_left" [ngClass]="'icon-' + buttonIcon"></span>
        <span class="button__label">{{ button }}</span>
      </a>
    </div>
  </div>
</div>
