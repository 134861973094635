var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { AfterViewInit, ChangeDetectorRef, ElementRef, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import * as Color from 'color';
import toPairs from 'lodash/toPairs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject } from 'rxjs';
import { getColorHex, isLightColor } from '@modules/colors';
import { Frame } from '@modules/customize';
import { MenuBlock, MenuGeneratorService } from '@modules/menu';
import { elementSize$, isSet } from '@shared';
export var MenuSecondary;
(function (MenuSecondary) {
    function isLight(color) {
        if (!isSet(color)) {
            return;
        }
        return isLightColor(color);
    }
    MenuSecondary.isLight = isLight;
    function getLightColor(color) {
        try {
            var colorHex = getColorHex(color);
            var clr = Color(colorHex);
            return clr.lighten(0.2).string();
        }
        catch (e) { }
    }
    MenuSecondary.getLightColor = getLightColor;
    function getDarkColor(color) {
        try {
            var colorHex = getColorHex(color);
            var clr = Color(colorHex);
            return clr.darken(0.05).string();
        }
        catch (e) { }
    }
    MenuSecondary.getDarkColor = getDarkColor;
    function getDark2Color(color) {
        try {
            var colorHex = getColorHex(color);
            var clr = Color(colorHex);
            return clr.darken(0.45).desaturate(0.35).string();
        }
        catch (e) { }
    }
    MenuSecondary.getDark2Color = getDark2Color;
    function getDark3Color(color) {
        try {
            var colorHex = getColorHex(color);
            var clr = Color(colorHex);
            return clr.darken(0.7).desaturate(0.25).string();
        }
        catch (e) { }
    }
    MenuSecondary.getDark3Color = getDark3Color;
    function getVars(accentColor, backgroundColor) {
        if (!isSet(accentColor)) {
            accentColor = isSet(backgroundColor) ? backgroundColor : '#f3f5f8';
        }
        var lightColor = getLightColor(accentColor);
        var darkColor = getDarkColor(accentColor);
        var dark2Color = getDark2Color(accentColor);
        var dark3Color = getDark3Color(accentColor);
        return __assign({}, (lightColor && { 'light-color': lightColor }), (darkColor && { 'dark-color': darkColor }), (dark2Color && { 'dark2-color': dark2Color }), (dark3Color && { 'dark3-color': dark3Color }));
    }
    MenuSecondary.getVars = getVars;
    function defaultWidth() {
        return 250;
    }
    MenuSecondary.defaultWidth = defaultWidth;
})(MenuSecondary || (MenuSecondary = {}));
var MenuSecondaryComponent = /** @class */ (function () {
    function MenuSecondaryComponent(el, menuGeneratorService, sanitizer, cd) {
        this.el = el;
        this.menuGeneratorService = menuGeneratorService;
        this.sanitizer = sanitizer;
        this.cd = cd;
        this.horizontal = false;
        this.isDarkTheme = false;
        this.frame$ = new BehaviorSubject(undefined);
        this.startItems = [];
        this.centerItems = [];
        this.endItems = [];
    }
    MenuSecondaryComponent.prototype.trackMenuItemFn = function (i, item) {
        return item.id;
    };
    MenuSecondaryComponent.prototype.ngOnInit = function () { };
    MenuSecondaryComponent.prototype.ngOnDestroy = function () { };
    MenuSecondaryComponent.prototype.ngOnChanges = function (changes) {
        if (changes.block) {
            this.updateItems();
        }
        if (changes.block || changes.isDarkTheme || changes.backgroundColor) {
            this.updateBackground();
        }
        if (changes.block || changes.isDarkTheme) {
            this.updateBorder();
        }
    };
    MenuSecondaryComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        elementSize$(this.el.nativeElement)
            .pipe(untilDestroyed(this))
            .subscribe(function (size) {
            _this.frame$.next(size);
            _this.updateBackground();
        });
    };
    MenuSecondaryComponent.prototype.updateItems = function () {
        this.startItems = this.menuGeneratorService.cleanMenuItemsAppMode(this.block.startItems);
        this.centerItems = this.menuGeneratorService.cleanMenuItemsAppMode(this.block.centerItems);
        this.endItems = this.menuGeneratorService.cleanMenuItemsAppMode(this.block.endItems);
        this.cd.markForCheck();
    };
    MenuSecondaryComponent.prototype.updateBackground = function () {
        var fill = this.getFill();
        if (fill) {
            var css = fill.css({ frame: new Frame({ width: 320, height: 240 }) });
            this.menuBackground = this.sanitizer.bypassSecurityTrustStyle(css.background);
            this.menuBackgroundWidth = css.width;
            this.menuBackgroundHeight = css.height;
            this.menuBackgroundTransform = this.sanitizer.bypassSecurityTrustStyle(css.transform);
            this.menuIsLight = MenuSecondary.isLight(css.accentColor || this.backgroundColor);
            this.menuStyle = this.getStyleVars(css.accentColor, this.backgroundColor);
        }
        else {
            this.menuBackground = undefined;
            this.menuBackgroundWidth = undefined;
            this.menuBackgroundHeight = undefined;
            this.menuBackgroundTransform = undefined;
            this.menuIsLight = MenuSecondary.isLight(this.backgroundColor);
            this.menuStyle = this.getStyleVars(undefined, this.backgroundColor);
        }
    };
    MenuSecondaryComponent.prototype.updateBorder = function () {
        if (this.block.borderSettings && this.block.borderSettings.isSidesSet()) {
            this.menuBorderTop =
                this.block.borderSettings && this.block.borderSettings.borderTop
                    ? this.sanitizer.bypassSecurityTrustStyle(this.block.borderSettings.borderTop.cssBorder(this.isDarkTheme))
                    : undefined;
            this.menuBorderRight =
                this.block.borderSettings && this.block.borderSettings.borderRight
                    ? this.sanitizer.bypassSecurityTrustStyle(this.block.borderSettings.borderRight.cssBorder(this.isDarkTheme))
                    : undefined;
            this.menuBorderBottom =
                this.block.borderSettings && this.block.borderSettings.borderBottom
                    ? this.sanitizer.bypassSecurityTrustStyle(this.block.borderSettings.borderBottom.cssBorder(this.isDarkTheme))
                    : undefined;
            this.menuBorderLeft =
                this.block.borderSettings && this.block.borderSettings.borderLeft
                    ? this.sanitizer.bypassSecurityTrustStyle(this.block.borderSettings.borderLeft.cssBorder(this.isDarkTheme))
                    : undefined;
        }
        else {
            this.menuBorderTop = this.menuBorderRight = this.menuBorderBottom = this.menuBorderLeft =
                this.block.borderSettings && this.block.borderSettings.border
                    ? this.sanitizer.bypassSecurityTrustStyle(this.block.borderSettings.border.cssBorder(this.isDarkTheme))
                    : undefined;
        }
    };
    MenuSecondaryComponent.prototype.getFill = function () {
        if (!this.block.fillSettings) {
            return;
        }
        if (this.isDarkTheme) {
            return this.block.fillSettings.fillDark;
        }
        else {
            return this.block.fillSettings.fill;
        }
    };
    MenuSecondaryComponent.prototype.getStyleVars = function (accentColor, backgroundColor) {
        var vars = MenuSecondary.getVars(accentColor, backgroundColor);
        var styles = toPairs(vars)
            .map(function (_a) {
            var k = _a[0], v = _a[1];
            return "--" + k + ": " + v;
        })
            .join(';');
        return this.sanitizer.bypassSecurityTrustStyle(styles);
    };
    return MenuSecondaryComponent;
}());
export { MenuSecondaryComponent };
