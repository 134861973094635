<ng-template #tip>
  <div style="text-align: center;">
    {{ theme.name }}<br />
    <small>Light & Dark</small>
  </div>
</ng-template>

<div
  class="sidebar-gallery-item sidebar-gallery-item_clickable"
  [appTip]="tip"
  [appTipOptions]="{ side: 'bottom', showDelay: 300 }"
  (mouseenter)="mouseEnter$.next()"
  (mousemove)="mouseMove$.next($event)"
  (mouseleave)="mouseLeave$.next()"
  #image
>
  <div class="sidebar-gallery-item__image">
    <div class="sidebar-gallery-item__image-segment">
      <div class="sidebar-gallery-item__image-segment-inner">
        <app-theme-gallery-item-preview [theme]="theme" [dark]="isDarkTheme"></app-theme-gallery-item-preview>
      </div>
    </div>

    <div
      class="sidebar-gallery-item__image-segment"
      [class.sidebar-gallery-item__image-segment_position_right]="!isDarkTheme"
      [class.sidebar-gallery-item__image-segment_position_left]="isDarkTheme"
      #segment
    >
      <div class="sidebar-gallery-item__image-segment-inner">
        <app-theme-gallery-item-preview [theme]="theme" [dark]="!isDarkTheme"></app-theme-gallery-item-preview>
      </div>
    </div>
  </div>
</div>
