import { Injectable, OnDestroy } from '@angular/core';
import { AbstractControl, FormArray } from '@angular/forms';
import fromPairs from 'lodash/fromPairs';
import isEqual from 'lodash/isEqual';
import range from 'lodash/range';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

import { ViewContext, ViewContextElement } from '@modules/customize';
import {
  BaseField,
  EditableField,
  getFieldDescriptionByType,
  Input,
  InputFilterField,
  InputValueType,
  isInputSet,
  parseFilterName
} from '@modules/fields';
import {
  FieldInputControl,
  FieldInputControlsValue,
  FieldInputRequiredValidator,
  InputFieldProvider,
  InputFieldProviderItem
} from '@modules/parameters';
import { controlValue, isSet } from '@shared';

class InputsArray extends FormArray {
  controls: FieldInputControl[];
}

@Injectable()
export class InputsEditForm implements OnDestroy {
  control: AbstractControl;
  parameterProvider: InputFieldProvider;
  fieldsControl: AbstractControl;
  context: ViewContext;
  contextElement: ViewContextElement;

  form = new InputsArray([]);
  updatedFromControl = new Subject();

  init(
    control: AbstractControl,
    parameterProvider: InputFieldProvider,
    fieldsControl: AbstractControl,
    context?: ViewContext,
    contextElement?: ViewContextElement
  ) {
    this.control = control;
    this.parameterProvider = parameterProvider;
    this.fieldsControl = fieldsControl;
    this.context = context;
    this.contextElement = contextElement;

    this.control.valueChanges.pipe(debounceTime(10), untilDestroyed(this)).subscribe(() => {
      if (this.updateValueFromControl()) {
        this.updatedFromControl.next();
      }
    });

    this.updateValueFromControl();

    this.form.valueChanges.pipe(debounceTime(60)).subscribe(value => this.updateValueToControl(value));
  }

  ngOnDestroy(): void {}

  serializeValueItem(item: FieldInputControlsValue): Input {
    const input = new Input();
    const field = this.parameterProvider.getFields().find(i => isEqual([i.name], item.path));

    if (!field) {
      return;
    }

    input.path = item.path;
    input.lookup = item.lookup;
    input.exclude = item.exclude;

    if (!field.field) {
      input.valueType = InputValueType.StaticValue;
      input.staticValue = true;
    } else {
      input.valueType = item.value_type;
      input.staticValue = item.static_value;

      if (input.valueType == InputValueType.StaticValue && field) {
        const fieldDescription = getFieldDescriptionByType(field.field);
        if (fieldDescription.serializeValue) {
          input.staticValue = fieldDescription.serializeValue(input.staticValue, {
            ...field,
            params: {
              ...field.params,
              output_format: undefined
            }
          });
        }
      }

      input.contextValue = item.context_value;
      input.filterField = item.filter_field;
      input.filterLookup = item.filter_lookup;
      input.formulaValue = item.formula_value;
      input.textInputsType = item.text_inputs_type;
      input.textInputsValue = item.text_inputs_value;
      input.jsValue = item.js_value;
      input.required = item.required;
    }

    return input;
  }

  serializeValue(value: FieldInputControlsValue[]): Input[] {
    return value
      .filter(item => item.path && item.path.length)
      .map(item => this.serializeValueItem(item))
      .filter(item => item);
  }

  deserializeValue(value: Input[]): FieldInputControlsValue[] {
    return value.map(item => {
      return {
        path: item.path,
        lookup: item.lookup,
        exclude: item.exclude,
        value_type: item.valueType,
        static_value: item.staticValue,
        context_value: item.contextValue,
        filter_field: item.filterField,
        filter_lookup: item.filterLookup,
        formula_value: item.formulaValue,
        text_inputs_type: item.textInputsType,
        text_inputs_value: item.textInputsValue,
        js_value: item.jsValue,
        required: item.required
      };
    });
  }

  updateValueFromControl() {
    const value = this.control.value as Input[];
    // TODO: Add value equals check
    const createItems = [
      ...this.parameterProvider
        .getFields()
        .filter(item => item.required === true)
        .filter(field => value.find(item => isEqual(item.path, [field.name])) == undefined)
        .map(field => {
          return {
            path: [field.name],
            field: field.field
          };
        }),
      ...this.deserializeValue(value).map(item => {
        const field = this.parameterProvider.getFields().find(i => isEqual([i.name], item.path));
        return {
          field: field ? field.field : null,
          ...item
        };
      })
    ];

    if (isEqual(createItems, this.form.value)) {
      return false;
    }

    this.arrayUpdate(createItems);

    return true;
  }

  updateValueToControl(value: any[]) {
    const currentValue = this.control.value as Input[];
    const controlValueObj = this.deserializeValue(currentValue);
    if (isEqual(value, controlValueObj)) {
      return;
    }

    this.control.patchValue(this.serializeValue(value));
  }

  parameter$(form: FieldInputControl): Observable<EditableField> {
    return combineLatest(
      controlValue<string[]>(form.controls.path),
      controlValue<string>(form.controls.lookup),
      controlValue<boolean>(form.controls.exclude),
      this.parameterProvider.getFields$()
    ).pipe(
      map(([path, lookup, exclude, fields]) => {
        if (!path) {
          return;
        }

        if (this.parameterProvider.lookupsEnabled) {
          const prefix = exclude ? 'exclude' : '';
          const name = [prefix, ...path, lookup].filter((str, i) => isSet(str) || i == 1).join('__');
          return fields.find(item => item.name == name);
        } else {
          return fields.find(item => isEqual([item.name], path));
        }
      })
    );
  }

  providerItem$(form: FieldInputControl): Observable<InputFieldProviderItem> {
    return combineLatest(
      controlValue<string[]>(form.controls.path),
      controlValue<string>(form.controls.lookup),
      controlValue<boolean>(form.controls.exclude),
      this.parameterProvider.getItems$()
    ).pipe(
      map(([path, lookup, exclude, values]) => {
        if (!path) {
          return;
        }

        if (this.parameterProvider.lookupsEnabled) {
          const prefix = exclude ? 'exclude' : '';
          const name = [prefix, ...path, lookup].filter((str, i) => isSet(str) || i == 1).join('__');
          return values.find(item => item.field && item.field.name == name);
        } else {
          return values.find(item => item.field && isEqual([item.field.name], path));
        }
      })
    );
  }

  value$(form: FieldInputControl): Observable<FieldInputControlsValue> {
    return controlValue<FieldInputControlsValue>(form);
  }

  isSet$(form: FieldInputControl): Observable<boolean> {
    return this.value$(form).pipe(map(value => isInputSet(value)));
  }

  filterFields$(): Observable<InputFilterField[]> {
    if (!this.fieldsControl) {
      return of([]);
    }

    return controlValue<BaseField[]>(this.fieldsControl).pipe(
      map(fields => {
        return fields.map(item => {
          const fieldDescription = getFieldDescriptionByType(item.field);

          return {
            name: item.name,
            label: item.verboseName || item.name,
            lookups: fieldDescription
              ? fieldDescription.lookups.map(lookup => {
                  return {
                    name: lookup.type.lookup,
                    label: lookup.type.verboseName || lookup.type.name
                  };
                })
              : []
          };
        });
      })
    );
  }

  createItem(value?: FieldInputControlsValue): FieldInputControl {
    return new FieldInputControl(value, FieldInputRequiredValidator);
  }

  addItem(item: InputFieldProviderItem) {
    let form: FieldInputControl;

    if (this.parameterProvider.lookupsEnabled) {
      const { field, lookup, exclude } = parseFilterName(item.field.name);
      form = this.createItem({ path: [field], lookup: lookup, exclude: exclude });
    } else {
      form = this.createItem({ path: [item.field.name] });
    }

    this.arrayAppend(form);
    return form;
  }

  isItemPristine(item: FieldInputControl) {
    return item.controls.value_type.value == InputValueType.StaticValue && item.controls.static_value.value === '';
  }

  groupTrackBy(item: FieldInputControlsValue): any {
    return JSON.stringify(item.path);
  }

  arraySet(groups: AbstractControl[]) {
    range(this.form.controls.length).forEach(() => this.form.removeAt(0));
    groups.forEach(item => this.form.push(item));
    this.form.updateValueAndValidity();
  }

  arrayUpdate(value: FieldInputControlsValue[]) {
    const existingControls = fromPairs(this.form.controls.map(item => [this.groupTrackBy(item.value), item]));
    const preserveControls = [];

    value
      .sort((lhs, rhs) => {
        const isInputSetLhs = isInputSet(lhs) ? 1 : 0;
        const isInputSetRhs = isInputSet(rhs) ? 1 : 0;
        const fields = this.parameterProvider.getFields();
        const parameterIndexLhs = fields.findIndex(item => isEqual([item.name], lhs.path));
        const parameterIndexRhs = fields.findIndex(item => isEqual([item.name], rhs.path));
        const parameterLhs = parameterIndexLhs != -1 ? fields[parameterIndexLhs] : undefined;
        const parameterRhs = parameterIndexRhs != -1 ? fields[parameterIndexRhs] : undefined;
        const requiredLhs = parameterLhs && parameterLhs.required ? 1 : 0;
        const requiredRhs = parameterRhs && parameterRhs.required ? 1 : 0;

        return (
          (requiredLhs - requiredRhs) * 10 * -1 +
          (isInputSetLhs - isInputSetRhs) * -1 +
          (parameterIndexLhs - parameterIndexRhs) / 10
        );
      })
      .forEach(item => {
        const id = this.groupTrackBy(item);
        let control = existingControls[id];

        if (control) {
          control.patchValue(item);
        } else {
          control = this.createItem(item);
          this.form.push(control);
        }

        preserveControls.push(control);
      });

    this.form.controls
      .map<[AbstractControl, number]>((item, i) => [item, i])
      .filter(([item, index]) => !preserveControls.includes(item))
      .reverse()
      .forEach(([item, index]) => {
        this.form.removeAt(index);
      });

    this.form.updateValueAndValidity();
  }

  arrayAppend(...groups: FieldInputControl[]) {
    groups.forEach(item => this.form.push(item));
    this.form.updateValueAndValidity();
  }

  arrayRemove(...groups: FieldInputControl[]) {
    groups.forEach(item => {
      const index = this.form.controls.findIndex(i => i === item);

      if (index == -1) {
        return;
      }

      this.form.removeAt(index);
    });

    this.form.updateValueAndValidity();
  }
}
