<mat-menu #choose_page_dropdown="matMenu">
  <ng-template matMenuContent>
    <button
      *ngFor="let page of pages"
      mat-menu-item
      class="mat-menu-item-odd"
      [class.mat-menu-item-active]="
        control.controls.type.value == actionTypes.Page && control.controls.page_uid.value == page.uid
      "
      [disableRipple]="true"
      (click)="setPageValue(page)"
    >
      {{ page.name }}
    </button>

    <div *ngIf="!pages.length" class="mat-menu-item-message">
      No pages yet
    </div>
  </ng-template>
</mat-menu>

<mat-menu #choose_property_dropdown="matMenu">
  <ng-template matMenuContent>
    <ng-container *ngFor="let group of propertyGroups; let first = first">
      <div class="mat-menu-item-subtitle" [class.mat-menu-item-subtitle_first]="first">
        {{ group.label }}
      </div>

      <button
        *ngFor="let property of group.properties"
        mat-menu-item
        class="mat-menu-item-odd"
        [class.mat-menu-item-active]="
          control.controls.type.value == actionTypes.SetProperty &&
          control.controls.set_property_uid.value == property.uid
        "
        [disableRipple]="true"
        (click)="setPropertyValue(property)"
      >
        <span
          *ngIf="property.fieldDescription"
          class="mat-menu-item-icon"
          [ngClass]="'icon-' + property.fieldDescription.icon"
        ></span>

        {{ property.name }}
      </button>

      <button
        mat-menu-item
        class="mat-menu-item-odd mat-menu-item-orange"
        [disableRipple]="true"
        (click)="addProperty(group.type)"
      >
        <span class="mat-menu-item-icon icon-plus"></span>
        {{ group.addLabel }}
      </button>
    </ng-container>
  </ng-template>
</mat-menu>

<mat-menu #choose_type_dropdown="matMenu">
  <ng-template matMenuContent>
    <button
      mat-menu-item
      class="mat-menu-item-odd"
      [class.mat-menu-item-active]="!control.controls.type.value"
      [disableRipple]="true"
      (click)="control.controls.type.patchValue(undefined)"
    >
      <span class="mat-menu-item-icon icon-close"></span>
      No action
    </button>

    <button
      mat-menu-item
      class="mat-menu-item-odd"
      [class.mat-menu-item-active]="control.controls.type.value == actionTypes.Page"
      [disableRipple]="true"
      [matMenuTriggerFor]="choose_page_dropdown"
    >
      <span class="mat-menu-item-icon icon-document"></span>
      Open page
    </button>

    <button
      mat-menu-item
      class="mat-menu-item-odd"
      [class.mat-menu-item-active]="control.controls.type.value == actionTypes.URL"
      [disableRipple]="true"
      (click)="control.controls.type.patchValue(actionTypes.URL)"
    >
      <span class="mat-menu-item-icon icon-external_link"></span>
      Open URL
    </button>

    <button
      mat-menu-item
      class="mat-menu-item-odd"
      [class.mat-menu-item-active]="control.controls.type.value == actionTypes.SetProperty"
      [disableRipple]="true"
      [matMenuTriggerFor]="choose_property_dropdown"
    >
      <span class="mat-menu-item-icon icon-variable"></span>
      Set Variable
    </button>

    <button
      *ngFor="let item of systemOptions"
      mat-menu-item
      class="mat-menu-item-odd"
      [class.mat-menu-item-active]="
        control.controls.type.value == actionTypes.System && control.controls.system_type.value == item.type
      "
      [disableRipple]="true"
      (click)="control.setSystemValue(item.type); selectSystem.emit(item)"
    >
      <span *ngIf="item.icon" class="mat-menu-item-icon" [ngClass]="'icon-' + item.icon"></span>
      {{ item.actionLabel }}
    </button>
  </ng-template>
</mat-menu>

<div class="sidebar__element">
  <app-sidebar-field [label]="label">
    <ng-container actions>
      <a
        *ngIf="selectedPage"
        [appLink]="selectedPage.link"
        class="sidebar__field-action sidebar__field-action_button icon-external_link"
        [class.sidebar__field-action_active]="false"
        [class.sidebar__field-action_bright-active]="false"
        [appTip]="'Open page'"
        [appTipOptions]="{ side: 'top' }"
        target="_blank"
      ></a>
    </ng-container>

    <div
      class="select select_theme_jet select_fill"
      [class.select_segment-top]="[actionTypes.URL].includes(control.controls.type.value)"
      [matMenuTriggerFor]="choose_type_dropdown"
      #trigger="matMenuTrigger"
    >
      <a href="javascript:void(0)" class="select__input" [class.select__input_opened]="trigger.menuOpen">
        <ng-container *ngIf="actionValueDisplay | appIsSet">
          <span
            *ngIf="actionValueDisplay.icon"
            class="select__input-icon"
            [ngClass]="'icon-' + actionValueDisplay.icon"
          >
          </span>
          <span class="select__input-label">
            {{ actionValueDisplay.label }}
          </span>
        </ng-container>

        <ng-container *ngIf="!(actionValueDisplay | appIsSet)">
          <span class="select__input-label">Choose action</span>
        </ng-container>

        <span class="select__input-arrow"></span>
      </a>
    </div>
  </app-sidebar-field>

  <app-sidebar-field *ngIf="control.controls.type.value == actionTypes.URL">
    <app-auto-field
      [form]="control"
      [label]="false"
      [field]="
        createField({
          name: 'url',
          field: 'CharField',
          placeholder: 'https://example.com',
          params: { classes: ['input_fill', 'input_segment-bottom'] }
        })
      "
      [autofocus]="true"
    ></app-auto-field>
  </app-sidebar-field>
</div>

<div
  *ngIf="control.controls.type.value == actionTypes.Page && (control.inputFieldProvider.getItems$() | async).length"
  class="sidebar__element"
>
  <app-sidebar-field [label]="'Specify Inputs'">
    <app-inputs-edit
      [control]="control.controls.inputs"
      [parameterProvider]="control.inputFieldProvider"
      [context]="context"
      [sortInputs]="undefined"
      [requiredEditable]="false"
      [listWrapper]="false"
      [classes]="'menu-item-action-inputs-item'"
      [analyticsSource]="analyticsSource"
    ></app-inputs-edit>
  </app-sidebar-field>
</div>

<div *ngIf="control.controls.type.value == actionTypes.SetProperty && currentProperty" class="sidebar__element">
  <app-customize-bar-input-edit
    [label]="currentProperty.name + ' value'"
    [itemForm]="control.controls.set_property_value"
    [context]="context"
    [staticValueField]="currentProperty.field?.field"
    [staticValueParams]="currentProperty.field?.params"
    [fill]="true"
    [analyticsSource]="analyticsSource + '_set_property_value'"
  >
    <div *ngIf="control.controls.set_property_value.invalid && control.dirty" class="field__errors">
      <div class="field__error">required</div>
    </div>
  </app-customize-bar-input-edit>
</div>
