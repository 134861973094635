/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../../customize-shared/pipes/domain-format-number/domain-format-number.pipe";
import * as i3 from "../../../../domain/stores/domain.store";
import * as i4 from "../../../../projects/stores/current-project.store";
import * as i5 from "./file-preview.component";
var styles_FilePreviewComponent = [];
var RenderType_FilePreviewComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FilePreviewComponent, data: {} });
export { RenderType_FilePreviewComponent as RenderType_FilePreviewComponent };
function View_FilePreviewComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "file-icon__subtitle"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " (", ") "])), i0.ɵppd(2, 2), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.additional; var currVal_1 = i0.ɵunv(_v, 1, 1, i0.ɵnov(_v, 3).transform(i0.ɵunv(_v, 1, 1, _ck(_v, 2, 0, i0.ɵnov(_v.parent.parent, 0), _co.previewSize, "0.00b")))); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_FilePreviewComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "file-icon__subtitle"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "])), i0.ɵppd(2, 2), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 3).transform(i0.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent.parent, 0), _co.previewSize, "0.00b")))); _ck(_v, 1, 0, currVal_0); }); }
function View_FilePreviewComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [["class", "file-icon"]], [[2, "file-icon_compact", null], [2, "file-icon_theme", null]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "file-icon__image"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "div", [["class", "file-icon__image-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), (_l()(), i0.ɵeld(4, 0, null, null, 1, "div", [["class", "file-icon__title"]], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, ["", ""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FilePreviewComponent_2)), i0.ɵdid(7, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FilePreviewComponent_3)), i0.ɵdid(9, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_4 = (_co.additional && _co.previewSize); _ck(_v, 7, 0, currVal_4); var currVal_5 = (!_co.additional && _co.previewSize); _ck(_v, 9, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.compact; var currVal_1 = _co.theme; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _co.previewExtension; _ck(_v, 3, 0, currVal_2); var currVal_3 = _co.previewFilename; _ck(_v, 5, 0, currVal_3); }); }
export function View_FilePreviewComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i2.DomainFormatNumberPipe, [[2, i3.DomainStore], [2, i4.CurrentProjectStore]]), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FilePreviewComponent_1)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.previewFilename; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_FilePreviewComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-file-preview", [], null, null, null, View_FilePreviewComponent_0, RenderType_FilePreviewComponent)), i0.ɵdid(1, 114688, null, 0, i5.FilePreviewComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FilePreviewComponentNgFactory = i0.ɵccf("app-file-preview", i5.FilePreviewComponent, View_FilePreviewComponent_Host_0, { previewFilename: "previewFilename", previewExtension: "previewExtension", previewSize: "previewSize", additional: "additional", compact: "compact", theme: "theme" }, {}, []);
export { FilePreviewComponentNgFactory as FilePreviewComponentNgFactory };
