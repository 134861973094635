/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../styles-edit/styles-edit.component.ngfactory";
import * as i2 from "../styles-edit/styles-edit.component";
import * as i3 from "../../../projects/stores/current-project.store";
import * as i4 from "../../../features/services/feature/feature.service";
import * as i5 from "../../../../common/dialogs/services/dialog/dialog.service";
import * as i6 from "./styles-date-range-element-edit.component";
var styles_StylesDateRangeElementEditComponent = [];
var RenderType_StylesDateRangeElementEditComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_StylesDateRangeElementEditComponent, data: {} });
export { RenderType_StylesDateRangeElementEditComponent as RenderType_StylesDateRangeElementEditComponent };
export function View_StylesDateRangeElementEditComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(402653184, 1, { stylesEditComponent: 0 }), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-styles-edit", [], null, null, null, i1.View_StylesEditComponent_0, i1.RenderType_StylesEditComponent)), i0.ɵdid(2, 770048, [[1, 4]], 0, i2.StylesEditComponent, [i3.CurrentProjectStore, i4.FeatureService, i5.DialogService, i0.NgZone, i0.ChangeDetectorRef], { styles: [0, "styles"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.styles; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_StylesDateRangeElementEditComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-styles-date-range-element", [], null, null, null, View_StylesDateRangeElementEditComponent_0, RenderType_StylesDateRangeElementEditComponent)), i0.ɵdid(1, 245760, null, 0, i6.StylesDateRangeElementEditComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StylesDateRangeElementEditComponentNgFactory = i0.ɵccf("app-styles-date-range-element", i6.StylesDateRangeElementEditComponent, View_StylesDateRangeElementEditComponent_Host_0, { control: "control" }, {}, []);
export { StylesDateRangeElementEditComponentNgFactory as StylesDateRangeElementEditComponentNgFactory };
