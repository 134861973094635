import { Injectable, Injector, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { ActionDescriptionService } from '@modules/action-queries';
import { SegueType } from '@modules/actions';
import { ViewSettings, ViewSettingsStore } from '@modules/customize';
import { Input, Input as FieldInput, isRequiredInputsSet } from '@modules/fields';
import { MenuItemAction, MenuItemActionType, MenuItemSystemActionType } from '@modules/menu';
import { FieldInputControl, InputFieldProvider } from '@modules/parameters';
import { ProjectProperty } from '@modules/projects';
import { controlValue, isSet } from '@shared';

export const validateUrl: ValidatorFn = control => {
  if (!isSet(control.value)) {
    return;
  }

  if (
    ['http://', 'https://', 'ftp://', '//', '/'].every(item => !String(control.value).toLowerCase().startsWith(item))
  ) {
    return { local: ['Should start with https://, http:// or /'] };
  }
};

export const validateInputs: ValidatorFn = control => {
  const parent = control.parent as MenuItemActionControl;
  if (!parent) {
    return;
  }

  if (parent.controls.type.value != MenuItemActionType.Page) {
    return;
  }

  const fields = parent.inputFieldProvider.getFields();
  const inputs: Input[] = control.value;

  if (!isRequiredInputsSet(fields, inputs)) {
    return { required: true };
  }
};

export const validateSetPropertyRequired: ValidatorFn = control => {
  const parent = control.parent as MenuItemActionControl;
  if (!parent) {
    return;
  }

  if (parent.controls.type.value != MenuItemActionType.SetProperty) {
    return;
  }

  if (!isSet(control.value)) {
    return { required: true };
  }
};

@Injectable()
export class MenuItemActionControl extends FormGroup implements OnDestroy {
  controls: {
    type: FormControl;
    page_uid: FormControl;
    page_unique_name: FormControl;
    url: FormControl;
    system_type: FormControl;
    inputs: FormControl;
    set_property_uid: FormControl;
    set_property_value: FieldInputControl;
  };

  instance: MenuItemAction;
  inputFieldProvider = new InputFieldProvider();

  constructor(
    private injector: Injector,
    private actionDescriptionService: ActionDescriptionService,
    private viewSettingsStore: ViewSettingsStore
  ) {
    super({
      type: new FormControl(''),
      page_uid: new FormControl(''),
      page_unique_name: new FormControl(''),
      url: new FormControl('', validateUrl),
      system_type: new FormControl(''),
      inputs: new FormControl([], validateInputs),
      set_property_uid: new FormControl(undefined, validateSetPropertyRequired),
      set_property_value: new FieldInputControl({ path: ['value'] })
    });

    this.updateInputFieldProvider().pipe(untilDestroyed(this)).subscribe();

    this.inputFieldProvider.getFields$().subscribe(() => {
      this.controls.inputs.updateValueAndValidity();
    });
  }

  static inject(injector: Injector): MenuItemActionControl {
    return Injector.create({
      providers: [
        {
          provide: MenuItemActionControl,
          useFactory: (actionDescriptionService: ActionDescriptionService, viewSettingsStore: ViewSettingsStore) => {
            return new MenuItemActionControl(injector, actionDescriptionService, viewSettingsStore);
          },
          deps: [ActionDescriptionService, ViewSettingsStore]
        }
      ],
      parent: injector
    }).get(MenuItemActionControl);
  }

  ngOnDestroy(): void {
    this.inputFieldProvider.clearProvider();
  }

  deserialize(item: MenuItemAction) {
    this.instance = item;

    if (item) {
      this.controls.type.patchValue(item.type);
      this.controls.page_uid.patchValue(item.pageUid);
      this.controls.page_unique_name.patchValue(item.pageUniqueName);
      this.controls.url.patchValue(item.url);
      this.controls.system_type.patchValue(item.systemType);
      this.controls.inputs.patchValue(item.inputs);
      this.controls.set_property_uid.patchValue(item.setPropertyUid);
      this.controls.set_property_value.patchValue(
        item.setPropertyValue ? item.setPropertyValue.serializeWithoutPath() : {}
      );
    }

    this.markAsPristine();
  }

  updateInputFieldProvider() {
    return combineLatest(
      controlValue<MenuItemActionType>(this.controls.type),
      controlValue<string>(this.controls.page_uid).pipe(
        switchMap(pageUid => {
          if (isSet(pageUid)) {
            return this.viewSettingsStore.getDetailByUid(pageUid);
          } else {
            return of(undefined);
          }
        })
      )
    ).pipe(
      map(([type, page]) => {
        if (type == MenuItemActionType.Page) {
          if (!page) {
            return of([]);
          }

          return this.actionDescriptionService.getLinkActionParameters({ type: SegueType.Page }, page.parameters);
        } else {
          return of([]);
        }
      }),
      tap(items => {
        this.inputFieldProvider.setProvider(items);
      })
    );
  }

  serialize(): MenuItemAction {
    const instance = new MenuItemAction();

    if (this.instance) {
      instance.deserialize(this.instance.serialize());
    }

    instance.type = this.controls.type.value;
    instance.pageUid = this.controls.page_uid.value;
    instance.pageUniqueName = this.controls.page_unique_name.value;
    instance.url = this.controls.url.value;
    instance.systemType = this.controls.system_type.value;
    instance.inputs = this.controls.inputs.value;
    instance.setPropertyUid = this.controls.set_property_uid.value;
    instance.setPropertyValue = this.controls.set_property_value.value
      ? new FieldInput().deserialize(this.controls.set_property_value.value)
      : undefined;

    return instance;
  }

  getPageUid$(): Observable<string> {
    return combineLatest(
      controlValue<MenuItemActionType>(this.controls.type),
      controlValue<string>(this.controls.page_uid)
    ).pipe(
      map(([type, pageUid]) => {
        if (type == MenuItemActionType.Page) {
          return pageUid;
        }
      })
    );
  }

  setPageValue(page: ViewSettings) {
    this.controls.type.patchValue(MenuItemActionType.Page);

    if (page) {
      this.controls.page_uid.patchValue(page.uid);
      this.controls.page_unique_name.patchValue(page.uniqueName);
    } else {
      this.controls.page_unique_name.patchValue(page.uniqueName);
    }
  }

  setUrlValue(url: string) {
    this.controls.type.patchValue(MenuItemActionType.URL);
    this.controls.url.patchValue(url);
  }

  setSystemValue(type: MenuItemSystemActionType) {
    this.controls.type.patchValue(MenuItemActionType.System);
    this.controls.system_type.patchValue(type);
  }

  setPropertyValue(property: ProjectProperty) {
    this.controls.type.patchValue(MenuItemActionType.SetProperty);
    this.controls.set_property_uid.patchValue(property.uid);
  }
}
