<div
  class="menu2-main menu2-main_theme menu2-block"
  [class.menu2-main_light]="menuIsLight === true"
  [class.menu2-main_dark]="menuIsLight === false"
  [class.menu2-main_unset]="!(menuBackground | appIsSet) && !(backgroundColor | appIsSet)"
  [class.menu2-block_light]="menuIsLight === true"
  [class.menu2-block_dark]="menuIsLight === false"
  [class.menu2-block_unset]="!(menuBackground | appIsSet)"
  [style.background]="(menuBackground | appIsSet) && !menuBackgroundTransform ? menuBackground : null"
  [style.border-top-left-radius.px]="block.borderRadius?.topLeft || block.borderRadius?.all || null"
  [style.border-top-right-radius.px]="block.borderRadius?.topRight || block.borderRadius?.all || null"
  [style.border-bottom-right-radius.px]="block.borderRadius?.bottomRight || block.borderRadius?.all || null"
  [style.border-bottom-left-radius.px]="block.borderRadius?.bottomLeft || block.borderRadius?.all || null"
>
  <div *ngIf="menuBackgroundTransform" class="menu2-side__background">
    <div
      class="menu2-side__background-inner"
      [style.background]="menuBackground"
      [style.width]="menuBackgroundWidth"
      [style.height]="menuBackgroundHeight"
      [style.transform]="menuBackgroundTransform"
      [style.border-top]="menuBorderTop"
      [style.border-right]="menuBorderRight"
      [style.border-bottom]="menuBorderBottom"
      [style.border-left]="menuBorderLeft"
      [style.border-top-left-radius.px]="block.borderRadius?.topLeft || block.borderRadius?.all || null"
      [style.border-top-right-radius.px]="block.borderRadius?.topRight || block.borderRadius?.all || null"
      [style.border-bottom-right-radius.px]="block.borderRadius?.bottomRight || block.borderRadius?.all || null"
      [style.border-bottom-left-radius.px]="block.borderRadius?.bottomLeft || block.borderRadius?.all || null"
    ></div>
  </div>

  <div
    *ngIf="!horizontal"
    class="menu2-main__viewport"
    xsScrollable
    [xsScrollableOptions]="{ stopPropagationAlways: true }"
  >
    <div class="menu2-main__content menu2-main__containers">
      <div class="menu2-main__start">
        <app-auto-menu-item
          *ngFor="let item of startItems; trackBy: trackMenuItemFn"
          [menuItem]="item"
          [menuBlock]="block"
          [primary]="false"
          [horizontal]="horizontal"
          [childrenVertical]="horizontal"
        >
        </app-auto-menu-item>
      </div>

      <div class="menu2-main__main">
        <app-auto-menu-item
          *ngFor="let item of centerItems; trackBy: trackMenuItemFn"
          [menuItem]="item"
          [menuBlock]="block"
          [primary]="false"
          [horizontal]="horizontal"
          [childrenVertical]="horizontal"
        >
        </app-auto-menu-item>
      </div>

      <div class="menu2-main__end">
        <app-auto-menu-item
          *ngFor="let item of endItems; trackBy: trackMenuItemFn"
          [menuItem]="item"
          [menuBlock]="block"
          [primary]="false"
          [horizontal]="horizontal"
          [childrenVertical]="horizontal"
        >
        </app-auto-menu-item>
      </div>
    </div>
  </div>

  <div *ngIf="horizontal" class="menu2-main__content menu2-main__containers">
    <div class="menu2-main__start">
      <app-auto-menu-item
        *ngFor="let item of startItems; trackBy: trackMenuItemFn"
        [menuItem]="item"
        [menuBlock]="block"
        [primary]="false"
        [horizontal]="horizontal"
        [childrenVertical]="horizontal"
      >
      </app-auto-menu-item>
    </div>

    <div class="menu2-main__main">
      <app-auto-menu-item
        *ngFor="let item of centerItems; trackBy: trackMenuItemFn"
        [menuItem]="item"
        [menuBlock]="block"
        [primary]="false"
        [horizontal]="horizontal"
        [childrenVertical]="horizontal"
      >
      </app-auto-menu-item>
    </div>

    <div class="menu2-main__end">
      <app-auto-menu-item
        *ngFor="let item of endItems; trackBy: trackMenuItemFn"
        [menuItem]="item"
        [menuBlock]="block"
        [primary]="false"
        [horizontal]="horizontal"
        [childrenVertical]="horizontal"
      >
      </app-auto-menu-item>
    </div>
  </div>
</div>
