import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  CustomViewSettings,
  PopupSettings,
  validateElementNames,
  VALUE_OUTPUT,
  ViewSettingsStore
} from '@modules/customize';
import { Input, InputValueType } from '@modules/fields';
import { SELECTED_ITEM_OUTPUT } from '@modules/list';
import { ModelDescription } from '@modules/models';
import { Project, Resource } from '@modules/projects';
import { Template } from '@modules/template';
import { capitalize } from '@shared';

import { PersistentIdGenerator } from '../../utils/elements';
import { BackGenerator } from '../back-generator/back-generator.service';
import { CreateFormGenerator } from '../create-form-generator/create-form-generator.service';
import { DeleteButtonGenerator } from '../delete-button-generator/delete-button-generator.service';
import { DetailGenerator } from '../detail-generator/detail-generator.service';
import { FilterGenerator } from '../filter-generator/filter-generator.service';
import { GeneratorUtils } from '../generator-utils/generator-utils.service';
import { ListGenerator } from '../list-generator/list-generator.service';
import { TextGenerator } from '../text-generator/text-generator.service';
import { UpdateFormGenerator } from '../update-form-generator/update-form-generator.service';
import { ViewSettingsGeneratorService } from '../view-settings-generator/view-settings-generator.service';

@Injectable()
export class AdminPanelPopupsGenerator {
  constructor(
    private viewSettingsStore: ViewSettingsStore,
    private viewSettingsGeneratorService: ViewSettingsGeneratorService,
    private createFormGenerator: CreateFormGenerator,
    private updateFormGenerator: UpdateFormGenerator,
    private detailGenerator: DetailGenerator,
    private deleteButtonGenerator: DeleteButtonGenerator,
    private backGenerator: BackGenerator,
    private listGenerator: ListGenerator,
    private filterGenerator: FilterGenerator,
    private textGenerator: TextGenerator,
    private generatorUtils: GeneratorUtils
  ) {}

  getModelViewPage(
    project: Project,
    resource: Resource,
    modelDescription: ModelDescription,
    uniqueName: string,
    options: {
      templates: Template[];
      listFields?: string[];
      detailFields?: string[];
      idGenerator?: PersistentIdGenerator;
    }
  ): CustomViewSettings {
    const viewSettings = new CustomViewSettings();
    const popup = new PopupSettings();

    viewSettings.project = project.uniqueName;
    viewSettings.uniqueName = uniqueName;
    viewSettings.name = modelDescription.verboseNamePlural;
    viewSettings.resource = resource.uniqueName;
    viewSettings.model = modelDescription.model;
    viewSettings.configuredElements = 1;
    viewSettings.configuredModelElements = 1;
    viewSettings.popups = [popup];
    viewSettings.newlyCreated = true;

    if (options.idGenerator) {
      popup.uid = options.idGenerator ? options.idGenerator.elementId('popup') : undefined;
    } else {
      popup.generateUid();
    }

    popup.name = 'Modal';

    const listElement = this.listGenerator.getTableElement(resource, modelDescription, uniqueName, {
      templates: options.templates,
      fields: options.listFields,
      detailPopup: popup.uid,
      perPage: 15,
      uid: options.idGenerator ? options.idGenerator.elementId('listElement') : undefined
    });

    const filterElement = this.filterGenerator.getElement(modelDescription, listElement.uid, {
      uid: options.idGenerator ? options.idGenerator.elementId('filterElement') : undefined
    });

    listElement.layouts[0].dataSource.queryInputs = filterElement.elementInputs.map(item => {
      const input = new Input();

      input.path = [item.name];
      input.valueType = InputValueType.Context;
      input.contextValue = ['elements', filterElement.uid, item.name, VALUE_OUTPUT];

      return input;
    });

    viewSettings.elements = [filterElement, listElement];

    const titleElement = this.textGenerator.getElement({
      h3: [capitalize(modelDescription.verboseName), 'Details'].join(' '),
      uid: options.idGenerator ? options.idGenerator.elementId('titleElement') : undefined
    });

    popup.elements.push(titleElement);

    const modelElement = this.detailGenerator.getElement(resource, modelDescription, {
      pkContextValue: ['elements', listElement.uid, '0', SELECTED_ITEM_OUTPUT, modelDescription.primaryKeyField],
      fields: options.detailFields,
      uid: options.idGenerator ? options.idGenerator.elementId('modelElement') : undefined
    });

    popup.elements.push(modelElement);

    validateElementNames(viewSettings.elements);

    return viewSettings;
  }

  getModelUpdatePage(
    project: Project,
    resource: Resource,
    modelDescription: ModelDescription,
    uniqueName: string,
    options: {
      templates: Template[];
      listFields?: string[];
      updateFields?: string[];
      idGenerator?: PersistentIdGenerator;
    }
  ): CustomViewSettings {
    const viewSettings = new CustomViewSettings();
    const popup = new PopupSettings();

    viewSettings.project = project.uniqueName;
    viewSettings.uniqueName = uniqueName;
    viewSettings.name = modelDescription.verboseNamePlural;
    viewSettings.resource = resource.uniqueName;
    viewSettings.model = modelDescription.model;
    viewSettings.configuredElements = 1;
    viewSettings.configuredModelElements = 1;
    viewSettings.popups = [popup];
    viewSettings.newlyCreated = true;

    if (options.idGenerator) {
      popup.uid = options.idGenerator ? options.idGenerator.elementId('popup') : undefined;
    } else {
      popup.generateUid();
    }

    popup.name = 'Modal';

    const listElement = this.listGenerator.getTableElement(resource, modelDescription, uniqueName, {
      templates: options.templates,
      fields: options.listFields,
      detailPopup: popup.uid,
      perPage: 15,
      uid: options.idGenerator ? options.idGenerator.elementId('listElement') : undefined
    });

    const filterElement = this.filterGenerator.getElement(modelDescription, listElement.uid, {
      uid: options.idGenerator ? options.idGenerator.elementId('filterElement') : undefined
    });

    listElement.layouts[0].dataSource.queryInputs = filterElement.elementInputs.map(item => {
      const input = new Input();

      input.path = [item.name];
      input.valueType = InputValueType.Context;
      input.contextValue = ['elements', filterElement.uid, item.name, VALUE_OUTPUT];

      return input;
    });

    viewSettings.elements = [filterElement, listElement];

    const titleElement = this.textGenerator.getElement({
      h3: ['Update', capitalize(modelDescription.verboseName)].join(' '),
      uid: options.idGenerator ? options.idGenerator.elementId('titleElement') : undefined
    });

    popup.elements.push(titleElement);

    const formElement = this.updateFormGenerator.getElement(resource, modelDescription, {
      templates: options.templates,
      pkContextValue: ['elements', listElement.uid, '0', SELECTED_ITEM_OUTPUT, modelDescription.primaryKeyField],
      fields: options.updateFields,
      closePopupOnSuccess: popup.uid,
      idGenerator: options.idGenerator,
      idUniqueName: 'formElement'
    });

    popup.elements.push(formElement);

    validateElementNames(viewSettings.elements);

    return viewSettings;
  }

  getPage(
    project: Project,
    resource: Resource,
    modelDescription: ModelDescription,
    updateEnabled: boolean,
    options: {
      listFields?: string[];
      createFields?: string[];
      updateFields?: string[];
      detailFields?: string[];
      idGenerator?: PersistentIdGenerator;
    } = {}
  ): Observable<CustomViewSettings> {
    const baseUniqueName = this.generatorUtils.getModelPageUniqueName(resource, modelDescription);

    return combineLatest(
      this.viewSettingsStore.getDistinctUniqueName(baseUniqueName),
      this.generatorUtils.getDefaultComponentTemplates()
    ).pipe(
      map(([uniqueName, templates]) => {
        if (updateEnabled) {
          return this.getModelUpdatePage(project, resource, modelDescription, uniqueName, {
            templates: templates,
            listFields: options.listFields,
            updateFields: options.updateFields,
            idGenerator: options.idGenerator
          });
        } else {
          return this.getModelViewPage(project, resource, modelDescription, uniqueName, {
            templates: templates,
            listFields: options.listFields,
            detailFields: options.detailFields,
            idGenerator: options.idGenerator
          });
        }
      })
    );
  }
}
