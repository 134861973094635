var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import last from 'lodash/last';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { isSet } from '@shared';
import { PopupService } from '../../services/popup/popup.service';
import { BasePopupComponent } from '../base-popup/base-popup.component';
var PopupsComponent = /** @class */ (function () {
    function PopupsComponent(popupService, cd) {
        this.popupService = popupService;
        this.cd = cd;
        this.items = [];
        this.disablePointerEvents = false;
    }
    PopupsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.popupService.items$.pipe(untilDestroyed(this)).subscribe(function (items) {
            _this.items = items.map(function (item) {
                var instance = _this.items.find(function (i) { return i.data == item; });
                if (instance) {
                    return { data: item, popupData: instance.popupData };
                }
                return {
                    data: item,
                    popupData: {
                        component: item.popupComponent || BasePopupComponent,
                        inputs: __assign({ data: item, disableClose: item.popupComponentDisableClose, closeWithoutConfirm: item.popupComponentCloseWithoutConfirm }, (isSet(item.popupComponentCloseTitle) && { closeTitle: item.popupComponentCloseTitle }), (isSet(item.popupComponentCloseDescription) && {
                            closeDescription: item.popupComponentCloseDescription
                        }), { requestClose: item.popupRequestClose, orange: item.popupComponentOrange, dark: item.popupComponentDark, theme: item.popupComponentTheme }),
                        outputs: {
                            closed: [
                                function (data) {
                                    if (item.popupClosed) {
                                        item.popupClosed(data);
                                    }
                                }
                            ]
                        }
                    }
                };
            });
            if (_this.items.length) {
                _this.disablePointerEvents = !!last(_this.items).data.disablePointerEvents;
            }
            else {
                _this.disablePointerEvents = false;
            }
            _this.cd.markForCheck();
        });
    };
    PopupsComponent.prototype.trackByFn = function (i, item) {
        return item.popupData;
    };
    PopupsComponent.prototype.ngOnDestroy = function () { };
    return PopupsComponent;
}());
export { PopupsComponent };
