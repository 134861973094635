import { OnDestroy, Renderer2 } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { LocalStorage } from '@core';
import { addClass, removeClass } from '@shared';
import { projectColors } from '../../data/colors';
var ThemeService = /** @class */ (function () {
    function ThemeService(storageService, renderer) {
        var _this = this;
        this.storageService = storageService;
        this.renderer = renderer;
        this._theme = new BehaviorSubject('default');
        this._color = new BehaviorSubject('blue');
        this._backgroundColor = new BehaviorSubject(undefined);
        this._backgroundColorDark = new BehaviorSubject(undefined);
        this._staffEnabled = new BehaviorSubject(false);
        if (this.storageService.get('theme')) {
            this._theme.next(this.storageService.get('theme'));
        }
        if (this.storageService.get('staff_enabled')) {
            this._staffEnabled.next(this.storageService.get('staff_enabled') == '1');
        }
        this.theme$.subscribe(function () { return _this.onUpdated(); });
        this.color$.subscribe(function () { return _this.onUpdated(); });
        this.staffEnabled$.subscribe(function (value) {
            if (value) {
                addClass(document.body, 'staff-enabled');
            }
            else {
                removeClass(document.body, 'staff-enabled');
            }
        });
        this.backgroundColorEffective$.subscribe(function (value) {
            if (value) {
                _this.renderer.setStyle(document.body, 'background', value);
            }
            else {
                _this.renderer.removeStyle(document.body, 'background');
            }
        });
    }
    ThemeService.prototype.ngOnDestroy = function () {
        this.clearClasses();
    };
    Object.defineProperty(ThemeService.prototype, "theme", {
        get: function () {
            return this._theme.value;
        },
        set: function (value) {
            this._theme.next(value);
            this.storageService.set('theme', value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ThemeService.prototype, "theme$", {
        get: function () {
            return this._theme.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ThemeService.prototype, "staffEnabled", {
        get: function () {
            return this._staffEnabled.value;
        },
        set: function (value) {
            this._staffEnabled.next(value);
            this.storageService.set('staff_enabled', value ? '1' : '0');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ThemeService.prototype, "staffEnabled$", {
        get: function () {
            return this._staffEnabled.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    ThemeService.prototype.setDefaultTheme = function (value) {
        if (!this.storageService.get('theme')) {
            this.theme = value;
        }
    };
    Object.defineProperty(ThemeService.prototype, "isDefaultTheme", {
        get: function () {
            return this._theme.value == 'default';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ThemeService.prototype, "isDefaultTheme$", {
        get: function () {
            return this._theme.pipe(map(function (theme) { return theme == 'default'; }));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ThemeService.prototype, "isDarkTheme", {
        get: function () {
            return this._theme.value == 'dark';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ThemeService.prototype, "isDarkTheme$", {
        get: function () {
            return this._theme.pipe(map(function (theme) { return theme == 'dark'; }));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ThemeService.prototype, "color", {
        get: function () {
            return this._color.value;
        },
        set: function (value) {
            this._color.next(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ThemeService.prototype, "color$", {
        get: function () {
            return this._color.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ThemeService.prototype, "backgroundColor", {
        get: function () {
            return this._backgroundColor.value;
        },
        set: function (value) {
            this._backgroundColor.next(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ThemeService.prototype, "backgroundColor$", {
        get: function () {
            return this._backgroundColor.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ThemeService.prototype, "backgroundColorDark", {
        get: function () {
            return this._backgroundColorDark.value;
        },
        set: function (value) {
            this._backgroundColorDark.next(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ThemeService.prototype, "backgroundColorDark$", {
        get: function () {
            return this._backgroundColorDark.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ThemeService.prototype, "backgroundColorEffective$", {
        get: function () {
            return combineLatest(this.isDefaultTheme$, this.backgroundColor$, this.backgroundColorDark$).pipe(map(function (_a) {
                var isDefaultTheme = _a[0], backgroundColor = _a[1], backgroundColorDark = _a[2];
                return isDefaultTheme ? backgroundColor : backgroundColorDark;
            }));
        },
        enumerable: true,
        configurable: true
    });
    ThemeService.prototype.toggleTheme = function () {
        this.theme = this.theme != 'default' ? 'default' : 'dark';
    };
    ThemeService.prototype.toggleStaffEnabled = function () {
        this.staffEnabled = !this.staffEnabled;
    };
    ThemeService.prototype.clearClasses = function () {
        removeClass(document.body, "theme_default");
        removeClass(document.body, "theme_dark");
    };
    ThemeService.prototype.addClasses = function () {
        addClass(document.body, "theme_" + this.theme);
        projectColors.forEach(function (item) { return removeClass(document.body, "body_color_" + item); });
        addClass(document.body, "body_color_" + this.color);
    };
    ThemeService.prototype.onUpdated = function () {
        this.clearClasses();
        this.addClasses();
    };
    return ThemeService;
}());
export { ThemeService };
