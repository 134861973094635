var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import cloneDeep from 'lodash/cloneDeep';
import pickBy from 'lodash/pickBy';
import { combineLatest, concat, of } from 'rxjs';
import { delayWhen, map, publishLast, refCount, switchMap, tap, toArray } from 'rxjs/operators';
import { ActionDescriptionService, ActionStore } from '@modules/action-queries';
import { ActionDescription } from '@modules/actions';
import { DEMO_RESOURCES_PROJECT } from '@modules/api';
import { ViewSettings, ViewSettingsService, ViewSettingsStore } from '@modules/customize';
import { DataSyncJob, DataSyncService } from '@modules/data-sync';
import { MenuSettingsService, MenuSettingsStore } from '@modules/menu';
import { ModelDescriptionService, ModelDescriptionStore } from '@modules/model-queries';
import { ModelDescription, ModelFieldType } from '@modules/models';
import { CurrentEnvironmentStore, CurrentProjectStore, isResourceTypeItemReplicable, ProjectTokenService, Resource, ResourceDeploy, ResourceName, ResourceService, resourceTypeItems, SecretToken, SecretTokenService } from '@modules/projects';
import { RegionService } from '@modules/regions';
import { DatabaseGeneratorService } from '@modules/resource-generators/services/database-generator/database-generator.service';
import { ResourceControllerService, RestAPIResourceParams, SOURCE_FIELD_TYPE } from '@modules/resources';
import { Storage } from '@modules/storages';
import { StorageService } from '@modules/storages-queries';
import { generateAlphanumeric, generateUUID, interpolateSimple, isSet, mapValuesDeep } from '@shared';
var ProjectSettingsService = /** @class */ (function () {
    function ProjectSettingsService(currentProjectStore, currentEnvironmentStore, viewSettingsService, modelDescriptionService, modelDescriptionStore, actionDescriptionService, actionStore, menuSettingsService, menuSettingsStore, viewSettingsStore, resourceService, projectTokenService, resourceControllerService, secretTokenService, storageService, databaseGeneratorService, regionService, dataSyncService) {
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.viewSettingsService = viewSettingsService;
        this.modelDescriptionService = modelDescriptionService;
        this.modelDescriptionStore = modelDescriptionStore;
        this.actionDescriptionService = actionDescriptionService;
        this.actionStore = actionStore;
        this.menuSettingsService = menuSettingsService;
        this.menuSettingsStore = menuSettingsStore;
        this.viewSettingsStore = viewSettingsStore;
        this.resourceService = resourceService;
        this.projectTokenService = projectTokenService;
        this.resourceControllerService = resourceControllerService;
        this.secretTokenService = secretTokenService;
        this.storageService = storageService;
        this.databaseGeneratorService = databaseGeneratorService;
        this.regionService = regionService;
        this.dataSyncService = dataSyncService;
    }
    ProjectSettingsService.prototype.saveResource = function (project, environment, instance, create, params, reloadStores) {
        var _this = this;
        if (params === void 0) { params = {}; }
        if (reloadStores === void 0) { reloadStores = true; }
        var savedResource;
        var check;
        if (this.resourceControllerService.get(instance.type).checkResource) {
            check = this.resourceControllerService
                .get(instance.type)
                .checkResource(instance.typeItem, params.resourceParams || instance.params || {});
        }
        else {
            check = of(true);
        }
        return check.pipe(switchMap(function () {
            if (!params.token) {
                return of(undefined);
            }
            var fields = ['resource', 'resource_type', 'resource_type_item', 'resource_deploy', 'activated'];
            return _this.projectTokenService.update(project, params.token, fields);
        }), switchMap(function () {
            var tokens = __assign({ project: project.uniqueName }, (params.extraTokens || {}));
            if (params.resourceParams) {
                params.resourceParams = mapValuesDeep(params.resourceParams, function (value) { return interpolateSimple(value, tokens); });
                instance.params = __assign({}, instance.params, params.resourceParams);
                instance.parseParams(RestAPIResourceParams, true);
            }
            if (!create) {
                return _this.resourceService.update(project.uniqueName, environment.uniqueName, instance, [
                    'unique_name',
                    'name',
                    'type',
                    'type_item',
                    'deploy',
                    'token',
                    'params'
                ]);
            }
            else {
                return _this.resourceService.create(project.uniqueName, environment.uniqueName, instance);
            }
        }), tap(function (result) {
            savedResource = result;
            var tokens = __assign({ project: project.uniqueName, resource: savedResource.uniqueName, resource_name: savedResource.name }, (params.extraTokens || {}));
            params.modelDescriptions = mapValuesDeep(params.modelDescriptions, function (value) { return interpolateSimple(value, tokens); });
            params.actionDescriptions = mapValuesDeep(params.actionDescriptions, function (value) { return interpolateSimple(value, tokens); });
            params.viewSettings = mapValuesDeep(params.viewSettings, function (value) { return interpolateSimple(value, tokens); });
            params.menuSettings = mapValuesDeep(params.menuSettings, function (value) { return interpolateSimple(value, tokens); });
            params.secretTokens = mapValuesDeep(params.secretTokens, function (value) { return interpolateSimple(value, tokens); });
            params.storages = mapValuesDeep(params.storages, function (value) { return interpolateSimple(value, tokens); });
        }), switchMap(function () {
            if (!params.modelDescriptions ||
                !params.modelDescriptions.length ||
                (params.resourceModelDescriptions && !isResourceTypeItemReplicable(savedResource.typeItem))) {
                return of([]);
            }
            var createItems$;
            var newItems = params.modelDescriptions.map(function (item) { return new ModelDescription().deserialize(item); });
            if (params.replicateModelDescriptions && isResourceTypeItemReplicable(savedResource.typeItem)) {
                var controller_1 = _this.resourceControllerService.get(savedResource.type);
                createItems$ = combineLatest(newItems.map(function (newItem) {
                    return controller_1.modelDescriptionCreate(savedResource, newItem);
                })).pipe(switchMap(function () {
                    if (controller_1.reload) {
                        return controller_1.reload(savedResource);
                    }
                    else {
                        return of(true);
                    }
                }), switchMap(function () { return _this.modelDescriptionStore.getFirst(true); }));
            }
            else {
                createItems$ = of([]);
            }
            var existingItems$ = create ? of([]) : _this.modelDescriptionStore.getFirst();
            return combineLatest(createItems$, existingItems$).pipe(switchMap(function (_a) {
                var _createResult = _a[0], modelDescriptions = _a[1];
                var existingItems = modelDescriptions.filter(function (item) { return item.resource == instance.uniqueName; });
                if (params.mergeExisting) {
                    newItems.forEach(function (newModelDescription) {
                        var _a;
                        var sync = savedResource.isSynced(newModelDescription.model);
                        var existingModelDescription = existingItems.find(function (item) { return item.isSame(newModelDescription); });
                        if (existingModelDescription) {
                            if (!params.mergeExistingRename) {
                                newModelDescription.verboseName = existingModelDescription.verboseName;
                                newModelDescription.verboseNamePlural = existingModelDescription.verboseNamePlural;
                            }
                            newModelDescription.orderingField = existingModelDescription.orderingField;
                            newModelDescription.defaultOrderBy = existingModelDescription.defaultOrderBy;
                            newModelDescription.displayField = existingModelDescription.displayField;
                            newModelDescription.description = existingModelDescription.description;
                            newModelDescription.featured = existingModelDescription.featured;
                            newModelDescription.hidden = existingModelDescription.hidden;
                            newModelDescription.orderAfter = existingModelDescription.orderAfter;
                            // newModelDescription.deleted = existingModelDescription.deleted;
                            if (sync) {
                                newModelDescription.fields = newModelDescription.fields.filter(function (newField) {
                                    return existingModelDescription.field(newField.name);
                                });
                                newModelDescription.getParameters = [];
                                newModelDescription.getDetailQuery = undefined;
                                newModelDescription.getDetailParameters = [];
                                newModelDescription.getDetailParametersUseDefaults = true;
                            }
                            newModelDescription.fields.forEach(function (newField) {
                                var existingField = existingModelDescription.field(newField.name);
                                if (existingField) {
                                    newField.orderAfter = existingField.orderAfter;
                                    newField.visible = existingField.visible;
                                }
                            });
                            newModelDescription.dbFields.forEach(function (newField) {
                                var existingField = existingModelDescription.dbField(newField.name);
                                if (existingField) {
                                    newField.verboseName = existingField.verboseName;
                                    newField.description = existingField.description;
                                    newField.field = existingField.field;
                                    newField.required = existingField.required;
                                    newField.null = existingField.null;
                                    newField.editable = existingField.editable;
                                    // newField.filterable = existingField.filterable;
                                    // newField.sortable = existingField.sortable;
                                    newField.defaultType = existingField.defaultType;
                                    newField.defaultValue = existingField.defaultValue;
                                    var forceUpdateParamsKeys_1 = [SOURCE_FIELD_TYPE];
                                    newField.params = __assign({}, pickBy(existingField.params || {}, function (v, k) {
                                        return !forceUpdateParamsKeys_1.includes(k);
                                    }), pickBy(newField.params || {}, function (v, k) {
                                        return forceUpdateParamsKeys_1.includes(k);
                                    }));
                                    newField.resetFormField();
                                    newField.updateFieldDescription();
                                }
                            });
                            (_a = newModelDescription.fields).push.apply(_a, existingModelDescription.fields.filter(function (item) { return item.type == ModelFieldType.Custom; }));
                            [
                                {
                                    newParameters: newModelDescription.createParameters,
                                    existingParameters: existingModelDescription.createParameters
                                },
                                {
                                    newParameters: newModelDescription.updateParameters,
                                    existingParameters: existingModelDescription.updateParameters
                                }
                            ].forEach(function (options) {
                                options.newParameters.forEach(function (newParameter) {
                                    var existingParameter = options.existingParameters.find(function (item) { return item.name == newParameter.name; });
                                    if (existingParameter && existingParameter.params) {
                                        newParameter.params = __assign({}, newParameter.params, (isSet(existingParameter.params['options']) && {
                                            options: existingParameter.params['options']
                                        }), (isSet(existingParameter.params['structure']) && {
                                            structure: existingParameter.params['structure']
                                        }), (isSet(existingParameter.params['display_fields']) && {
                                            display_fields: existingParameter.params['display_fields']
                                        }));
                                    }
                                });
                            });
                        }
                        else {
                            if (sync) {
                                newModelDescription.fields = [];
                                newModelDescription.getParameters = [];
                                newModelDescription.getDetailQuery = undefined;
                                newModelDescription.getDetailParameters = [];
                                newModelDescription.getDetailParametersUseDefaults = true;
                            }
                        }
                    });
                }
                else {
                    newItems.forEach(function (newModelDescription) {
                        var sync = savedResource.isSynced(newModelDescription.model);
                        if (sync) {
                            newModelDescription.fields = [];
                            newModelDescription.getParameters = [];
                            newModelDescription.getDetailQuery = undefined;
                            newModelDescription.getDetailParameters = [];
                            newModelDescription.getDetailParametersUseDefaults = true;
                        }
                    });
                }
                if (params.deleteNonExisting) {
                    existingItems
                        .filter(function (existingItem) { return !existingItem.virtual; })
                        .filter(function (existingItem) { return !newItems.find(function (item) { return item.isSame(existingItem); }); })
                        .forEach(function (existingItem) {
                        var deleteItem = cloneDeep(existingItem);
                        deleteItem.deleted = true;
                        newItems.push(deleteItem);
                    });
                }
                return _this.modelDescriptionService.createBulk(project.uniqueName, environment.uniqueName, newItems);
            }));
        }), delayWhen(function (newItems) {
            if (create) {
                return of(undefined);
            }
            return _this.modelDescriptionStore.getFirst().pipe(switchMap(function (modelDescriptions) {
                var existingItems = modelDescriptions.filter(function (item) { return item.resource == savedResource.uniqueName; });
                var newSyncItem = newItems.find(function (newItem) {
                    return !existingItems.find(function (item) { return item.isSame(newItem); }) && savedResource.isSynced(newItem.model);
                });
                if (!newSyncItem) {
                    return of(undefined);
                }
                return _this.dataSyncService
                    .getJobs(_this.currentProjectStore.instance, _this.currentEnvironmentStore.instance, {
                    sourceResourceUniqueName: savedResource.uniqueName
                })
                    .pipe(map(function (jobs) { return jobs[0]; }), switchMap(function (job) {
                    if (!job) {
                        return of(undefined);
                    }
                    return _this.dataSyncService.runJob(_this.currentProjectStore.instance, _this.currentEnvironmentStore.instance, job.id);
                }));
            }));
        }), switchMap(function (modelDescriptions) {
            if (!params.models || !params.models.length) {
                return of([]);
            }
            var controller = _this.resourceControllerService.get(savedResource.type);
            var models$ = params.models.reduce(function (acc, data) {
                var modelDescription = modelDescriptions.find(function (item) { return item.model == data.model; });
                if (modelDescription) {
                    var models = data.items.map(function (item) {
                        var model = controller.createModel().deserialize(modelDescription.modelId, item);
                        model.setUp(modelDescription);
                        model.deserializeAttributes(modelDescription.dbFields);
                        return model;
                    });
                    if (models.length) {
                        acc.push(controller.modelCreateBulk(savedResource, modelDescription, models));
                    }
                }
                return acc;
            }, []);
            return concat.apply(void 0, models$).pipe(toArray());
        }), switchMap(function () {
            if (!params.actionDescriptions || !params.actionDescriptions.length) {
                return of([]);
            }
            return (create ? of([]) : _this.actionStore.getFirst()).pipe(switchMap(function (actionDescriptions) {
                var existingItems = actionDescriptions.filter(function (item) { return item.resource == instance.uniqueName; });
                var newItems = params.actionDescriptions.map(function (item) { return new ActionDescription().deserialize(item); });
                if (params.mergeExisting) {
                    newItems.forEach(function (newActionDescription) {
                        var existingActionDescription = existingItems.find(function (item) { return item.isSame(newActionDescription); });
                        if (existingActionDescription) {
                            newActionDescription.verboseName = existingActionDescription.verboseName;
                            // newActionDescription.icon = existingActionDescription.icon;
                            newActionDescription.description = existingActionDescription.description;
                            newActionDescription.featured = existingActionDescription.featured;
                            // newModelDescription.deleted = existingModelDescription.deleted;
                        }
                    });
                }
                if (params.deleteNonExisting) {
                    existingItems
                        .filter(function (existingItem) { return !existingItem.virtual; })
                        .filter(function (existingItem) { return !newItems.find(function (item) { return item.isSame(existingItem); }); })
                        .forEach(function (existingItem) {
                        var deleteItem = cloneDeep(existingItem);
                        deleteItem.deleted = true;
                        newItems.push(deleteItem);
                    });
                }
                return _this.actionDescriptionService.createBulk(project.uniqueName, environment.uniqueName, newItems);
            }));
        }), switchMap(function () {
            if (!params.viewSettings || !params.viewSettings.length) {
                return of([]);
            }
            return _this.viewSettingsService.createBulk(project.uniqueName, environment.uniqueName, params.viewSettings.map(function (item) { return new ViewSettings().deserialize(item); }));
        }), switchMap(function () {
            if (!params.secretTokens || !params.secretTokens.length) {
                return of(undefined);
            }
            return combineLatest.apply(void 0, params.secretTokens.map(function (item) {
                var secretToken = new SecretToken().deserialize(item);
                return _this.secretTokenService.create(project.uniqueName, environment.uniqueName, savedResource.uniqueName, secretToken);
            }));
        }), switchMap(function () {
            if (!params.storages || !params.storages.length) {
                return of(undefined);
            }
            var existingItems = _this.currentProjectStore.instance
                .getStorages(_this.currentEnvironmentStore.instance.uniqueName)
                .filter(function (item) {
                return item.resource.uniqueName == instance.uniqueName;
            })
                .map(function (item) { return item.storage; });
            var newItems = params.storages.map(function (storage) {
                var newStorage = new Storage().deserialize(storage);
                if (params.mergeExisting) {
                    var existingStorage = !create
                        ? existingItems.find(function (item) { return item.uniqueName == newStorage.uniqueName; })
                        : undefined;
                    if (existingStorage) {
                        newStorage.name = existingStorage.name;
                    }
                }
                return newStorage;
            });
            if (params.deleteNonExisting) {
                existingItems
                    .filter(function (existingItem) { return !newItems.find(function (item) { return item.isSame(existingItem); }); })
                    .forEach(function (existingItem) {
                    var deleteItem = cloneDeep(existingItem);
                    deleteItem.deleted = true;
                    newItems.push(deleteItem);
                });
            }
            return combineLatest.apply(void 0, newItems.map(function (storage) {
                return _this.storageService.create(project.uniqueName, environment.uniqueName, savedResource.uniqueName, storage);
            }));
        }), delayWhen(function () { return _this.currentProjectStore.getFirst(true); }), 
        // switchMap(() => {
        //   if (!params.menuSettings) {
        //     return of(undefined);
        //   }
        //
        //   return this.menuSettingsStore.getFirst().pipe(
        //     switchMap(original => {
        //       const override = new MenuSettings().deserialize(params.menuSettings);
        //
        //       original.primaryCenterItems = [...original.primaryCenterItems, ...override.primaryCenterItems];
        //       original.secondaryStartItems = [...original.secondaryStartItems, ...override.secondaryStartItems];
        //
        //       return this.menuSettingsService.create(project.uniqueName, environment.uniqueName, original);
        //     })
        //   );
        // }),
        delayWhen(function () {
            var stores = [];
            if (reloadStores) {
                stores.push(_this.modelDescriptionStore.getFirst(true));
                stores.push(_this.actionStore.getFirst(true));
                if (params.viewSettings) {
                    stores.push(_this.viewSettingsStore.getFirst(true));
                }
                // if (params.menuSettings) {
                //   stores.push(this.menuSettingsStore.getFirst(true));
                // }
            }
            if (!stores.length) {
                return of(undefined);
            }
            return combineLatest.apply(void 0, stores);
        }), map(function () { return savedResource; }), publishLast(), refCount());
    };
    ProjectSettingsService.prototype.reloadAllowed = function (resource) {
        var supported = resource &&
            resource.apiInfo &&
            resource.apiInfo.isCompatibleJetBridge({ jetBridge: '0.6.9', jetDjango: '0.9.6' });
        if (resource && resource.isSynced()) {
            return true;
        }
        else {
            var resourceController = this.resourceControllerService.get(resource.type);
            return supported && resourceController && resourceController.reload != undefined;
        }
    };
    ProjectSettingsService.prototype.reloadResource = function (resource) {
        var resourceController = this.resourceControllerService.getForResource(resource, true);
        return resourceController.reload ? resourceController.reload(resource) : of(undefined);
    };
    ProjectSettingsService.prototype.syncResource = function (resource, generator, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        var resourceController = this.resourceControllerService.getForResource(resource, true);
        var project = this.currentProjectStore.instance;
        var environment = this.currentEnvironmentStore.instance;
        var resourceReload = resourceController.reload ? resourceController.reload(resource) : of(undefined);
        return resourceReload.pipe(switchMap(function () { return generator.getParamsOptions(project, environment, resource); }), switchMap(function (result) { return generator.generateParams(project, environment, resource.typeItem, result); }), switchMap(function (params) {
            return _this.saveResource(project, environment, resource, false, {
                resourceParams: __assign({}, resource.params, params.resourceParams),
                modelDescriptions: params.modelDescriptions,
                actionDescriptions: params.actionDescriptions,
                secretTokens: params.secretTokens,
                storages: params.storages,
                extraTokens: params.extraTokens,
                mergeExisting: options.mergeExisting,
                deleteNonExisting: options.deleteNonExisting,
                sync: resource.isSynced()
            });
        }));
    };
    ProjectSettingsService.prototype.syncAllowed = function (resource, generator) {
        if (generator) {
            return true;
        }
        else {
            var supported = resource &&
                resource.apiInfo &&
                resource.apiInfo.isCompatibleJetBridge({ jetBridge: '0.6.9', jetDjango: '0.9.6' });
            var resourceController = this.resourceControllerService.get(resource.type);
            return supported && resourceController && resourceController.reload != undefined;
        }
    };
    ProjectSettingsService.prototype.createResourceJetDatabase = function (options) {
        var _this = this;
        return this.databaseGeneratorService.createJetDatabase(options.project, options.environment, options.resource).pipe(switchMap(function (result) {
            return _this.regionService.getDefaultJetBridge().pipe(switchMap(function (jetBridge) {
                var address = result.url.split(':', 2);
                var databaseOptions = {
                    deploy: ResourceDeploy.Direct,
                    region: jetBridge.region ? jetBridge.region.uid : undefined,
                    url: jetBridge.url,
                    database_host: address[0],
                    database_port: address[1],
                    database_name: result.databaseName,
                    database_user: result.userName,
                    database_password: result.password,
                    database_schema: options.resource.uniqueName,
                    token: options.resource.token || generateUUID()
                };
                var typeItem = resourceTypeItems.find(function (item) { return item.name == ResourceName.JetDatabase; });
                return _this.databaseGeneratorService.generateGeneralParams(options.project, options.environment, typeItem, databaseOptions);
            }));
        }));
    };
    ProjectSettingsService.prototype.applySyncParams = function (options) {
        var _this = this;
        if (!options.params.sync) {
            var params = __assign({}, options.params, { resourceParams: __assign({}, options.params.resourceParams, { sync: false }) });
            return of({ params: params });
        }
        var createdResource;
        var resource$;
        if (options.existingResource) {
            resource$ = of(options.existingResource);
        }
        else {
            var instance = new Resource();
            instance.name = options.params.resourceName;
            instance.type = options.typeItem.resourceType;
            instance.typeItem = options.typeItem;
            if (options.project.uniqueName == DEMO_RESOURCES_PROJECT) {
                instance.uniqueName = ['demo', options.typeItem.name].join('_');
            }
            else if (!options.existingResource) {
                instance.uniqueName = [options.typeItem.name, generateAlphanumeric(4)].join('_');
            }
            resource$ = this.resourceService
                .create(options.project.uniqueName, options.environment.uniqueName, instance)
                .pipe(tap(function (result) {
                createdResource = result;
            }));
        }
        return resource$.pipe(switchMap(function (resource) {
            return _this.createResourceJetDatabase({
                project: options.project,
                environment: options.environment,
                resource: resource
            });
        }), map(function (databaseParams) {
            var params = __assign({}, options.params, { resourceToken: databaseParams.resourceToken, resourceParams: __assign({}, options.params.resourceParams, { sync: true, sync_model_descriptions: options.params.syncModelDescriptions, sync_finished: options.existingResource ? options.existingResource.isSyncedFinished() : false }, databaseParams.resourceParams) });
            return { params: params, createdResource: createdResource };
        }));
    };
    ProjectSettingsService.prototype.applySyncJob = function (options) {
        var _this = this;
        if (!options.resource.isSynced()) {
            return of(undefined);
        }
        return this.dataSyncService
            .getJobs(options.project, options.environment, {
            sourceResourceUniqueName: options.resource.uniqueName
        })
            .pipe(map(function (result) { return result[0]; }), switchMap(function (existingJob) {
            if (existingJob) {
                return of(existingJob);
            }
            else {
                var job = new DataSyncJob();
                job.sourceResource = options.resource;
                job.runInterval = options.runInterval || 60;
                // if (options.modelDescriptions) {
                //   job.useAllModelDescriptions = false;
                //   job.modelDescriptions = options.modelDescriptions.map(item => {
                //     return {
                //       name: item
                //     };
                //   });
                // } else {
                job.useAllModelDescriptions = true;
                // }
                return _this.dataSyncService.createJob(options.project, options.environment, job);
            }
        }));
    };
    return ProjectSettingsService;
}());
export { ProjectSettingsService };
