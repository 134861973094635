import keys from 'lodash/keys';
import pickBy from 'lodash/pickBy';

import { isSet } from '@shared';

import { BorderSettings } from './border-settings';
import { Corners } from './corners';
import { Margin } from './elements/items/base';
import { CardLayoutElementItem } from './elements/items/card-layout';
import { Shadow } from './shadow';

export class ElementWrapperStyles {
  borderSettings?: BorderSettings;
  borderRadius?: Corners;
  shadow?: Shadow;
  margin?: Margin;

  constructor(options: Partial<ElementWrapperStyles> = {}) {
    Object.assign(this, options);
  }

  deserialize(data: Object): this {
    if (data['border_settings']) {
      this.borderSettings = new BorderSettings().deserialize(data['border_settings']);
    } else {
      this.borderSettings = undefined;
    }

    if (data['border_radius']) {
      this.borderRadius = data['border_radius'];
    } else {
      this.borderRadius = undefined;
    }

    if (data['shadow']) {
      this.shadow = new Shadow().deserialize(data['shadow']);
    } else {
      this.shadow = undefined;
    }

    if (data['margin']) {
      this.margin = data['margin'];
    } else {
      this.margin = undefined;
    }

    return this;
  }

  serialize(): Object {
    return {
      border_settings: this.borderSettings ? this.borderSettings.serialize() : undefined,
      border_radius: this.borderRadius,
      shadow: this.shadow ? this.shadow.serialize() : undefined,
      margin: this.margin
    };
  }

  apply(other?: Partial<this>): this {
    const properties = ['borderSettings', 'borderRadius', 'shadow', 'margin'];

    properties.forEach(property => {
      if (other && isSet(other[property])) {
        this[property] = other[property];
      }
    });

    return this;
  }
}

export function getElementWrapperStyles(element: CardLayoutElementItem): ElementWrapperStyles {
  const options: Partial<ElementWrapperStyles> = pickBy(
    {
      borderSettings: element.borderSettings,
      borderRadius: element.borderRadius,
      shadow: element.shadow,
      ...(keys(element.margin).length && {
        margin: element.margin
      })
    },
    v => isSet(v)
  );

  if (!keys(options).length) {
    return;
  }

  return new ElementWrapperStyles(options);
}

export function applyElementWrapperStyles(element: CardLayoutElementItem, styles?: ElementWrapperStyles) {
  if (!styles) {
    styles = new ElementWrapperStyles();
  }

  element.borderSettings = styles.borderSettings;
  element.borderRadius = styles.borderRadius;
  element.shadow = styles.shadow;
  element.margin = styles.margin;
}
