<ng-container [formGroup]="form">
  <div style="display: flex; align-items: center; padding: 30px;">
    <div style="width: 130px; padding-right: 4px;">
      <gxs-select
        [formControlName]="'method'"
        [options]="{
          theme: 'jet',
          search: true,
          searchMinimumLength: 1,
          searchDebounce: 0,
          classes: ['select_fill'],
          labels: {} | localizeSelect
        }"
      >
        <gxs-option *ngFor="let item of methodOptions" [name]="item" [value]="item"> </gxs-option>
      </gxs-select>
    </div>
    <div style="flex-grow: 1; flex-basis: 0;" class="input-icon-wrapper">
      <ng-container *ngIf="form.controls.base_url_enabled.value">
        <span
          class="input-icon input-icon_bright input-icon_interactive input-icon_left icon-edit"
          [appTip]="'Customize full URL'"
          [appTipOptions]="{ side: 'top' }"
          (click)="form.disableBaseURL()"
        ></span>
        <div
          class="input input_fill input_template input_icon-left"
          [class.input_focus]="urlPathFocused"
          (click)="url_path_element.focus()"
        >
          <div class="input__template-prefix">{{ baseHttpQuery?.url }}</div>
          <input
            formControlName="url_path"
            class="input__template-value input__template-value_fill"
            type="text"
            placeholder="..."
            [appTokenInput]="{ context: context, control: form.controls.url_path }"
            (appTokenEvent)="inputTokensEvent.emit($event)"
            (click)="$event.stopPropagation()"
            #url_path_element
          />
        </div>
      </ng-container>

      <ng-container *ngIf="!form.controls.base_url_enabled.value">
        <span
          *ngIf="form.baseUrlAllowed"
          class="input-icon input-icon_bright input-icon_interactive input-icon_left icon-starts_with"
          [appTip]="'Use base URL'"
          [appTipOptions]="{ side: 'top' }"
          (click)="form.enableBaseURL()"
        ></span>
        <input
          formControlName="url"
          class="input"
          [class.input_icon-left]="form.baseUrlAllowed"
          type="text"
          style="width: 100%; max-width: none;"
          [placeholder]="urlPlaceholder"
          [appTokenInput]="{ context: context, control: form.controls.url }"
          (appTokenEvent)="inputTokensEvent.emit($event)"
        />
      </ng-container>
    </div>
    <!--    <div style="padding-left: 10px;">-->
    <!--      <button-->
    <!--        type="button"-->
    <!--        class="button button_primary button_orange-alternative button_bigger"-->
    <!--        style="margin: 0; height: 38px; line-height: 38px;"-->
    <!--        (click)="execute.next()"-->
    <!--        [disabled]="form.invalid || form.disabled"-->
    <!--      >-->
    <!--        <span class="button__label">Send</span>-->
    <!--      </button>-->
    <!--    </div>-->
  </div>

  <div class="http-query__tabs">
    <div class="http-query-tabs http-query-tabs_orange">
      <a
        *ngFor="let item of tabs"
        href="javascript:void(0)"
        class="http-query-tabs__item"
        [class.http-query-tabs__item_active]="currentTabName == item.name"
        (click)="setCurrentTabName(item.name)"
      >
        <span class="http-query-tabs__item-inner">
          <ng-container *ngIf="item.takeBodyType && bodyTypes.length == 1">{{ bodyTypes[0].label }}</ng-container>
          <ng-container *ngIf="!item.takeBodyType || bodyTypes.length != 1">{{ item.label }}</ng-container>
        </span>
      </a>
    </div>
  </div>

  <div class="http-query__tabs-content">
    <app-query-builder-parameters
      *ngIf="currentTabName == 'queryParams'"
      [arrayControl]="form.controls.query_params"
      [context]="context"
      (inputTokensEvent)="inputTokensEvent.emit($event)"
    ></app-query-builder-parameters>

    <app-query-builder-parameters
      *ngIf="currentTabName == 'headers'"
      [arrayControl]="form.controls.headers"
      [context]="context"
      (inputTokensEvent)="inputTokensEvent.emit($event)"
    ></app-query-builder-parameters>

    <ng-container *ngIf="currentTabName == 'body'">
      <div class="http-query-selector http-query-selector_orange">
        <div *ngIf="bodyTypes.length > 1" class="http-query-selector__main">
          <a
            *ngFor="let item of bodyTypes"
            href="javascript:void(0)"
            class="http-query-selector__item"
            [class.http-query-selector__item_active]="form.controls.body_type.value == item.type"
            (click)="setBodyType(item.type)"
          >
            {{ item.label }}
          </a>
        </div>
        <div class="http-query-selector__right">
          <app-toggle
            [label]="'Use JavaScript'"
            [selected]="form.controls.body_transformer_visible.value"
            [orange]="true"
            (click)="form.toggleBodyTransformerVisible()"
          ></app-toggle>
        </div>
      </div>

      <ng-container *ngIf="form.controls.body_transformer_visible.value">
        <app-auto-field
          [form]="form"
          [label]="false"
          [field]="
            createField({
              name: 'body_transformer',
              field: 'CodeField',
              params: { flexible: true }
            })
          "
          [appTokenInput]="{
            context: context,
            control: form.controls.body_transformer,
            origin: button,
            trigger: button,
            quote: { open: '', close: '' }
          }"
          (appTokenEvent)="inputTokensEvent.emit($event)"
        ></app-auto-field>

        <div class="http-query__actions">
          <a href="javascript:void(0)" class="http-query__actions-item" #button>
            <span class="http-query__actions-item-icon http-query__actions-item-icon_text">{{ '{' }}..{{ '}' }}</span>
            Insert Input
          </a>
        </div>
      </ng-container>

      <ng-container *ngIf="!form.controls.body_transformer_visible.value">
        <ng-container *ngIf="form.controls.body_type.value == httpContentTypes.JSON">
          <div class="http-query__fill">
            <app-auto-field
              [form]="form"
              [label]="false"
              [field]="
                createField({
                  name: 'body_json',
                  label: 'body',
                  field: 'CodeField',
                  placeholder: jsonPlaceholder,
                  params: { flexible: true }
                })
              "
              [appTokenInput]="{
                context: context,
                control: form.controls.body_json,
                origin: button,
                trigger: button,
                quote: { open: '', close: '' }
              }"
              (appTokenEvent)="inputTokensEvent.emit($event)"
            ></app-auto-field>
          </div>

          <div class="http-query__actions">
            <a href="javascript:void(0)" class="http-query__actions-item" #button>
              <span class="http-query__actions-item-icon http-query__actions-item-icon_text">{{ '{' }}..{{ '}' }}</span>
              Insert Input
            </a>

            <ng-container *ngIf="form.parametersControl">
              <a
                *ngIf="form.parametersControl.value?.length"
                href="javascript:void(0)"
                class="http-query__actions-item"
                [class.http-query__actions-item_disabled]="form.isBodyJsonDefault$ | async"
                [appTip]="
                  (form.isBodyJsonDefault$ | async) ? 'JSON is updated' : 'Generate JSON body based on specified Inputs'
                "
                [appTipOptions]="{ side: 'top' }"
                (click)="form.setDefaultBodyJson()"
              >
                <span
                  class="http-query__actions-item-icon"
                  [class.icon-check_2]="form.isBodyJsonDefault$ | async"
                  [class.icon-magic_wand]="!(form.isBodyJsonDefault$ | async)"
                ></span>
                <span class="http-query__actions-item-label">JSON from Inputs</span>
              </a>

              <span
                *ngIf="!form.parametersControl.value?.length"
                class="http-query__actions-item http-query__actions-item_disabled http-query__actions-item_help"
                [appTip]="'Add Inputs first'"
                [appTipOptions]="{ side: 'top' }"
              >
                <span class="http-query__actions-item-icon icon-magic_wand"></span>
                <span class="http-query__actions-item-label">JSON from Inputs</span>
              </span>
            </ng-container>
          </div>
        </ng-container>

        <ng-container
          *ngIf="
            form.controls.body_type.value == httpContentTypes.FormUrlEncoded ||
            form.controls.body_type.value == httpContentTypes.FormData
          "
        >
          <app-query-builder-parameters
            [arrayControl]="form.controls.form_data"
            [context]="context"
            [typeEnabled]="form.controls.body_type.value == httpContentTypes.FormData && !context.useFileObjects"
            (inputTokensEvent)="inputTokensEvent.emit($event)"
          ></app-query-builder-parameters>
        </ng-container>

        <ng-container *ngIf="form.controls.body_type.value == httpContentTypes.Raw">
          <div class="http-query__fill">
            <app-auto-field
              [form]="form"
              [label]="false"
              [field]="
                createField({
                  name: 'body_raw',
                  label: 'body',
                  field: 'CodeField',
                  placeholder: rawPlaceholder,
                  params: { flexible: true }
                })
              "
              [appTokenInput]="{
                context: context,
                control: form.controls.body_raw,
                origin: button,
                trigger: button
              }"
              (appTokenEvent)="inputTokensEvent.emit($event)"
            ></app-auto-field>
          </div>

          <div class="http-query__actions">
            <a href="javascript:void(0)" class="http-query__actions-item" #button>
              <span class="http-query__actions-item-icon http-query__actions-item-icon_text">{{ '{' }}..{{ '}' }}</span>
              Insert Input
            </a>
          </div>
        </ng-container>

        <ng-container *ngIf="form.controls.body_type.value == httpContentTypes.GraphQL">
          <div class="http-query__fill">
            <app-auto-field
              [form]="form"
              [label]="false"
              [field]="createField({ name: 'body_raw', label: 'body', field: 'CodeField', params: { flexible: true } })"
              [appTokenInput]="{
                context: context,
                control: form.controls.body_raw,
                origin: button,
                trigger: button
              }"
              (appTokenEvent)="inputTokensEvent.emit($event)"
            ></app-auto-field>
          </div>

          <div class="http-query__actions">
            <a href="javascript:void(0)" class="http-query__actions-item" #button>
              <span class="http-query__actions-item-icon http-query__actions-item-icon_text">{{ '{' }}..{{ '}' }}</span>
              Insert Input
            </a>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
