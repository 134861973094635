var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormGroup } from '@angular/forms';
import { map } from 'rxjs/operators';
import { FillSettings } from '@modules/customize';
import { controlValue, isSet } from '@shared';
import { FillControl } from './fill.control';
var FillSettingsControl = /** @class */ (function (_super) {
    __extends(FillSettingsControl, _super);
    function FillSettingsControl(state) {
        if (state === void 0) { state = {}; }
        return _super.call(this, {
            fill: new FillControl(isSet(state.fill) ? state.fill : undefined),
            fill_dark: new FillControl(isSet(state.fillDark) ? state.fillDark : undefined)
        }) || this;
    }
    FillSettingsControl.prototype.deserialize = function (value, options) {
        if (options === void 0) { options = {}; }
        this.instance = value;
        this.controls.fill.deserialize(value ? value.fill : undefined, { emitEvent: options.emitEvent });
        this.controls.fill_dark.deserialize(value ? value.fillDark : undefined, { emitEvent: options.emitEvent });
    };
    FillSettingsControl.prototype.isSet = function () {
        return this.controls.fill.isSet() || this.controls.fill_dark.isSet();
    };
    FillSettingsControl.prototype.isSet$ = function () {
        var _this = this;
        return controlValue(this).pipe(map(function () { return _this.isSet(); }));
    };
    FillSettingsControl.prototype.reset = function (options) {
        this.controls.fill.reset(options);
        this.controls.fill_dark.reset(options);
    };
    FillSettingsControl.prototype.resetDefaults = function (options) {
        this.controls.fill.resetDefaults(options);
        this.controls.fill_dark.resetDefaults(options);
    };
    FillSettingsControl.prototype.getInstance = function (instance) {
        if (!instance) {
            instance = new FillSettings();
        }
        instance.fill = this.controls.fill.getInstance(instance.fill);
        instance.fillDark = this.controls.fill_dark.getInstance(instance.fillDark);
        return instance;
    };
    FillSettingsControl.prototype.serialize = function (reuseInstance) {
        if (reuseInstance === void 0) { reuseInstance = true; }
        if (!this.isSet()) {
            return;
        }
        return this.getInstance(reuseInstance ? this.instance : undefined);
    };
    FillSettingsControl.prototype.serialize$ = function () {
        var _this = this;
        return controlValue(this, { debounce: 200 }).pipe(map(function () { return _this.serialize(); }));
    };
    return FillSettingsControl;
}(FormGroup));
export { FillSettingsControl };
