import { FillSettingsControl } from '@modules/colors-components';
import {
  ActionElementStyles,
  BorderSettings,
  BorderSettingsControl,
  ElementWrapperStyles,
  FieldElementStyles,
  FillSettings,
  IconSettings,
  IconSettingsControl,
  Shadow,
  ShadowControl,
  TextStyle
} from '@modules/customize';
import {
  ActionElementStylesControl,
  ElementWrapperStylesControl,
  FieldElementStylesControl,
  TextStyleControl
} from '@modules/customize-bar';
import { MenuBlock } from '@modules/menu';
import { PartialDeep } from '@shared';

import { MenuBlockControl } from '../project-settings/menu-block.control';
import {
  deserializeBorderSettings,
  deserializeFillSettings,
  deserializeIconSettings,
  deserializeShadow,
  deserializeTextStyle
} from './theme-template-deserialize.utils';

export function applyThemeTextStyle(control: TextStyleControl, styles: PartialDeep<TextStyle> | undefined) {
  const instance = deserializeTextStyle(styles);
  control.deserialize(instance);
}

export function applyThemeIconSettings(control: IconSettingsControl, styles: PartialDeep<IconSettings> | undefined) {
  const instance = deserializeIconSettings(styles);
  control.deserialize(instance);
}

export function applyThemeFillSettings(control: FillSettingsControl, styles: PartialDeep<FillSettings> | undefined) {
  const instance = deserializeFillSettings(styles);
  control.deserialize(instance);
}

export function applyThemeBorderSettings(
  control: BorderSettingsControl,
  styles: PartialDeep<BorderSettings> | undefined
) {
  const instance = deserializeBorderSettings(styles);
  control.deserialize(instance);
}

export function applyThemeShadow(control: ShadowControl, styles: PartialDeep<Shadow> | undefined) {
  const instance = deserializeShadow(styles);
  control.deserialize(instance);
}

export function applyThemeActionElementStyles(
  control: ActionElementStylesControl,
  style: PartialDeep<ActionElementStyles> | undefined
) {
  if (!style) {
    style = {};
  }

  applyThemeTextStyle(control.controls.text_style, style.textStyle);
  applyThemeIconSettings(control.controls.icon_settings, style.iconSettings);
  applyThemeFillSettings(control.controls.fill_settings, style.fillSettings);
  applyThemeBorderSettings(control.controls.border_settings, style.borderSettings);
  control.controls.border_radius.deserialize(style.borderRadius);
  applyThemeShadow(control.controls.shadow, style.shadow);
  control.controls.padding.deserialize(style.padding);
  control.controls.margin.deserialize(style.margin);

  applyThemeTextStyle(control.controls.hover_text_style, style.hoverTextStyle);
  applyThemeIconSettings(control.controls.hover_icon_settings, style.hoverIconSettings);
  applyThemeFillSettings(control.controls.hover_fill_settings, style.hoverFillSettings);
  applyThemeBorderSettings(control.controls.hover_border_settings, style.hoverBorderSettings);
  applyThemeShadow(control.controls.hover_shadow, style.hoverShadow);

  applyThemeTextStyle(control.controls.active_text_style, style.activeTextStyle);
  applyThemeIconSettings(control.controls.active_icon_settings, style.activeIconSettings);
  applyThemeFillSettings(control.controls.active_fill_settings, style.activeFillSettings);
  applyThemeBorderSettings(control.controls.active_border_settings, style.activeBorderSettings);
  applyThemeShadow(control.controls.active_shadow, style.activeShadow);
}

export function applyThemeFieldElementStyles(
  control: FieldElementStylesControl,
  style: PartialDeep<FieldElementStyles> | undefined
) {
  if (!style) {
    style = {};
  }

  applyThemeTextStyle(control.controls.text_style, style.textStyle);
  applyThemeTextStyle(control.controls.placeholder_style, style.placeholderStyle);
  applyThemeTextStyle(control.controls.label_style, style.labelStyle);
  applyThemeTextStyle(control.controls.label_additional_style, style.labelAdditionalStyle);
  applyThemeFillSettings(control.controls.fill_settings, style.fillSettings);
  applyThemeBorderSettings(control.controls.border_settings, style.borderSettings);
  control.controls.border_radius.deserialize(style.borderRadius);
  applyThemeShadow(control.controls.shadow, style.shadow);
  control.controls.padding.deserialize(style.padding);
  control.controls.margin.deserialize(style.margin);

  applyThemeTextStyle(control.controls.hover_text_style, style.hoverTextStyle);
  applyThemeTextStyle(control.controls.hover_placeholder_style, style.hoverPlaceholderStyle);
  applyThemeFillSettings(control.controls.hover_fill_settings, style.hoverFillSettings);
  applyThemeBorderSettings(control.controls.hover_border_settings, style.hoverBorderSettings);
  applyThemeShadow(control.controls.hover_shadow, style.hoverShadow);

  applyThemeTextStyle(control.controls.focus_text_style, style.focusTextStyle);
  applyThemeTextStyle(control.controls.focus_placeholder_style, style.focusPlaceholderStyle);
  applyThemeFillSettings(control.controls.focus_fill_settings, style.focusFillSettings);
  applyThemeBorderSettings(control.controls.focus_border_settings, style.focusBorderSettings);
  applyThemeShadow(control.controls.focus_shadow, style.focusShadow);

  applyThemeTextStyle(control.controls.error_text_style, style.errorTextStyle);
  applyThemeTextStyle(control.controls.error_placeholder_style, style.errorPlaceholderStyle);
  applyThemeFillSettings(control.controls.error_fill_settings, style.errorFillSettings);
  applyThemeBorderSettings(control.controls.error_border_settings, style.errorBorderSettings);
  applyThemeShadow(control.controls.error_shadow, style.errorShadow);
}

export function applyThemeElementWrapperStyles(
  control: ElementWrapperStylesControl,
  style: PartialDeep<ElementWrapperStyles> | undefined
) {
  if (!style) {
    style = {};
  }

  applyThemeBorderSettings(control.controls.border_settings, style.borderSettings);
  control.controls.border_radius.deserialize(style.borderRadius);
  applyThemeShadow(control.controls.shadow, style.shadow);
  control.controls.margin.deserialize(style.margin);
}

export function applyThemeMenuBlockStyles(control: MenuBlockControl, style: PartialDeep<MenuBlock> | undefined) {
  if (!style) {
    style = {};
  }

  control.controls.layout.patchValue(style.layout);
  applyThemeFillSettings(control.controls.fill_settings, style.fillSettings);
  applyThemeBorderSettings(control.controls.border_settings, style.borderSettings);
  control.controls.width.patchValue(style.width);
  control.controls.height.patchValue(style.height);
  control.controls.border_radius.deserialize(style.borderRadius);
  control.controls.padding.deserialize(style.padding);
}
