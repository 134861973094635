<div class="sidebar__input">
  <div class="input-icon-wrapper">
    <span class="input-icon input-icon_toolbar input-icon_left icon-search"></span>
    <span
      *ngIf="search"
      class="input-icon input-icon_toolbar input-icon_interactive input-icon_right icon-close"
      (click)="clearSearch()"
    ></span>
    <input
      class="input input_toolbar input_background input_icon-left input_icon-right sidebar__input-inner"
      type="text"
      [placeholder]="'Search ' + section.title + '...'"
      [appAutofocus]="focus"
      [(ngModel)]="search"
      (keyup)="searchUpdated.next()"
    />
  </div>
</div>

<app-sidebar-section *ngFor="let group of groupsFiltered" [title]="group.title" [border]="false">
  <div class="sidebar__list">
    <app-change-customize-bar-templates-group-section-item
      [title]="group.title"
      [items]="group.items"
      [display]="group.display"
      [dropListGroups]="dropListGroups"
      [context]="context"
      [collapsible]="!group.showAll && !search"
      (clearSearch)="clearSearch()"
    >
    </app-change-customize-bar-templates-group-section-item>
  </div>
</app-sidebar-section>

<div *ngIf="emptyLabel" class="sidebar__message">
  {{ emptyLabel }}
</div>
