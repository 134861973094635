import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ElementRef, Inject, Injectable, Injector } from '@angular/core';
import { UrlTree } from '@angular/router';
import saveAs from 'file-saver';
import cloneDeep from 'lodash/cloneDeep';
import flatten from 'lodash/flatten';
import fromPairs from 'lodash/fromPairs';
import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';
import last from 'lodash/last';
import round from 'lodash/round';
import toPairs from 'lodash/toPairs';
import truncate from 'lodash/truncate';
import * as moment from 'moment';
import { combineLatest, merge, Observable, of, ReplaySubject, Subject, Subscription, throwError, timer } from 'rxjs';
import { catchError, debounceTime, delay, delayWhen, filter, first, map, share, switchMap, tap } from 'rxjs/operators';

import { copyTextToClipboard } from '@common/code';
import { ThinDialogPopupComponent } from '@common/dialog-popup';
import { DialogButtonHotkey, DialogButtonType, DialogOptions, DialogService } from '@common/dialogs';
import { NotificationService } from '@common/notifications';
import { PopupService } from '@common/popups';
import { SessionStorage } from '@core';
import {
  ActionDescription,
  ActionItem,
  ActionResponse,
  ActionType,
  DownloadActionType,
  ExportDataType,
  SegueType,
  WorkflowAction
} from '@modules/actions';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ServerRequestError } from '@modules/api';
import { Task, TaskQueueStore, TaskService } from '@modules/collaboration';
import {
  CustomizeService,
  CustomViewSettings,
  HTTP_BODY_OUTPUT,
  HTTP_CODE_OUTPUT,
  HTTP_STATUS_OUTPUT,
  ITEM_OUTPUT,
  ListElementItem,
  modelFieldToRawListViewSettingsColumn,
  rawListViewSettingsColumnsToDisplayField,
  SUBMIT_ERROR_OUTPUT,
  SUBMIT_RESULT_OUTPUT,
  ViewContext,
  ViewContextElement
} from '@modules/customize';
import { DataSourceType, ListModelDescriptionDataSource } from '@modules/data-sources';
import {
  applyParamInput,
  applyParamInputs,
  BaseField,
  DisplayFieldType,
  EditableField,
  executeJavaScript,
  FieldOutput,
  FieldType,
  getFieldDescriptionByType,
  Input,
  JsonOutputFormat,
  ParameterField
} from '@modules/fields';
import { EMPTY_FILTER_VALUES } from '@modules/filters';
import { ScannerPopupController } from '@modules/image-codes';
import { ModelImportFinishedEvent } from '@modules/import';
import { MessageName, MessageService } from '@modules/messages';
import { ModelDescriptionStore, ModelService } from '@modules/model-queries';
import { Model, ModelAction, ModelDescription, ModelFieldType, NO_KEY_ATTRIBUTE, PAGE_PARAM } from '@modules/models';
import { InputFieldProviderItem } from '@modules/parameters';
import {
  CurrentEnvironmentStore,
  CurrentProjectStore,
  Environment,
  isResourceTypeItem3rdParty,
  Project,
  ProjectPropertyStore,
  ProjectPropertyType,
  Resource,
  ResourceType
} from '@modules/projects';
import { ActionQuery, HttpQuery, HttpQueryService, HttpResponseType, QueryService, QueryType } from '@modules/queries';
import {
  applyFrontendFiltering,
  applyFrontendPagination,
  applyFrontendSorting,
  applyQueryOptionsFilterInputs,
  FirebaseResourceController,
  GetQueryOptions,
  GetQueryOptionsPaging,
  getQueryOptionsToParams,
  JetBridgeResourceController,
  ModelResponse,
  paramsToGetQueryOptions,
  ResourceControllerService,
  RestApiResourceControllerService
} from '@modules/resources';
import { RoutingService } from '@modules/routing';
import { StorageService } from '@modules/storages-queries';
import { CurrentUserStore, User } from '@modules/users';
import {
  ActionWorkflowStep,
  ConditionWorkflowStep,
  ConditionWorkflowStepType,
  DelayWorkflowStep,
  ExitWorkflowStep,
  ForkWorkflowStep,
  IteratorWorkflowStep,
  TransformWorkflowStep,
  Workflow,
  WorkflowBackendRunService,
  WorkflowRun,
  WorkflowStep,
  WorkflowStepRun,
  WorkflowStepType
} from '@modules/workflow';
import {
  AppError,
  coerceArray,
  forceObservable,
  getExtension,
  getFilenameWithExtension,
  getMimeExtension,
  isAbsoluteUrl,
  isSet,
  limitObjectLength,
  mapWithError,
  openUrl,
  splitmax,
  strip
} from '@shared';

import { ViewSettingsStore } from '../../../customize/stores/view-settings.store';
import { CancelledError } from '../../data/cancelled.error';
import {
  ACTION_SERVICE_ACTION_MENU_COMPONENT,
  ACTION_SERVICE_EXPORT_COMPONENT,
  ACTION_SERVICE_IMPORT_COMPONENT,
  ActionServiceActionMenuComponent
} from '../../data/injection-tokens';
import {
  WorkflowExecuteEvent,
  WorkflowExecuteEventType,
  WorkflowExecuteStepError,
  WorkflowExecuteWorkflowFinishedEvent
} from '../../data/workflow-execute';
import { ActionStore } from '../../stores/action.store';
import { ActionDescriptionService } from '../action-description/action-description.service';
import { ExportController } from '../export-controller/export.controller';
import { storageGetObjectsStructure } from './storage-get-objects-structure';

export interface ElementStatus {
  disabled?: boolean;
  hidden?: boolean;
}

export const ActionItemExecuteSessionKey = 'action_item_execute';
export const ActionDescriptionExecuteSessionKey = 'action_description_execute';
export const ActionDescriptionParamsExecuteSessionKey = 'action_description_params_execute';

export interface ModelUpdatedEvent {
  modelDescription: ModelDescription;
  model: Model;
}

export function isModelUpdateEventMatch(
  e: ModelUpdatedEvent,
  modelDescription: ModelDescription,
  model: Model
): boolean {
  return e.modelDescription.isSame(modelDescription) && e.model.isSame(model);
}

export function patchModel(instance: Model, newModel: Model): Model {
  const newInstance = instance.clone();
  toPairs(newModel.getAttributes()).forEach(([k, v]) => {
    if (v !== undefined) {
      newInstance.setAttribute(k, v);
    }
  });
  toPairs(newModel.getRawAttributes()).forEach(([k, v]) => {
    if (v !== undefined) {
      newInstance.setRawAttribute(k, v);
    }
  });
  return newInstance;
}

export interface WorkflowStepInfo {
  icon?: string;
  image?: string;
  labels?: string[];
}

export interface WorkflowStepResult {
  params?: Object;
  result?: any;
  error?: any;
}

export interface OutputsInfo {
  outputs: FieldOutput[];
  arrayOutput?: boolean;
}

export type ActionOrigin =
  | ElementRef
  | HTMLElement
  | {
      x: number;
      y: number;
    };

@Injectable()
export class ActionService {
  private _modelUpdated$ = new Subject<ModelUpdatedEvent>();

  constructor(
    @Inject(ACTION_SERVICE_EXPORT_COMPONENT) private exportComponent: any,
    @Inject(ACTION_SERVICE_IMPORT_COMPONENT) private importComponent: any,
    @Inject(ACTION_SERVICE_ACTION_MENU_COMPONENT) private actionMenuComponent: any,
    private messageService: MessageService,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private currentUserStore: CurrentUserStore,
    private actionDescriptionService: ActionDescriptionService,
    private modelDescriptionStore: ModelDescriptionStore,
    private modelService: ModelService,
    private storageService: StorageService,
    private queryService: QueryService,
    private workflowBackendRunService: WorkflowBackendRunService,
    private actionStore: ActionStore,
    private exportController: ExportController,
    private resourceControllerService: ResourceControllerService,
    private httpQueryService: HttpQueryService,
    private routing: RoutingService,
    private popupService: PopupService,
    private sessionStorage: SessionStorage,
    private taskService: TaskService,
    private taskQueueStore: TaskQueueStore,
    private dialogService: DialogService,
    private viewSettingsStore: ViewSettingsStore,
    private projectPropertyStore: ProjectPropertyStore,
    private scannerPopupController: ScannerPopupController,
    private notificationService: NotificationService,
    private customizeService: CustomizeService,
    private overlay: Overlay,
    private analyticsService: UniversalAnalyticsService,
    private injector: Injector
  ) {}

  createGetResponse(): ModelResponse.GetResponse {
    return Injector.create({
      providers: [{ provide: ModelResponse.GetResponse, deps: [Injector] }],
      parent: this.injector
    }).get<ModelResponse.GetResponse>(ModelResponse.GetResponse);
  }

  getActionDescriptionParams(
    actionDescription: ActionDescription,
    options: { context?: ViewContext } = {}
  ): Observable<ParameterField[]> {
    if (!actionDescription) {
      return of([]);
    }

    const itemsToFields = (value: InputFieldProviderItem[]): BaseField[] => {
      const getFields = (items: InputFieldProviderItem[]): BaseField[] => {
        return items.reduce((prev, item) => {
          if (item.field) {
            prev.push(item.field);
          }

          if (item.children) {
            prev.push(...getFields(item.children));
          }

          return prev;
        }, []);
      };

      return getFields(value);
    };

    const fieldToParameter = (item: EditableField): ParameterField => {
      const result = new ParameterField();

      result.name = item.name;
      result.verboseName = item.verboseName;
      result.description = item.description;
      result.field = item.field;
      result.required = item['required'];
      result.defaultType = item['defaultType'];
      result.defaultValue = item['defaultValue'];
      result.params = item.params;
      result.updateFieldDescription();

      return result;
    };

    if (actionDescription.type == ActionType.Link) {
      const page = actionDescription.linkAction ? actionDescription.linkAction.page : undefined;

      if (!page) {
        return of([]);
      }

      return this.viewSettingsStore.getDetailFirst(page).pipe(
        map(viewSettings => {
          const parameters = viewSettings ? viewSettings.parameters : [];
          const items = this.actionDescriptionService.getLinkActionParameters(actionDescription.linkAction, parameters);
          return itemsToFields(items).map(item => fieldToParameter(item));
        })
      );
    } else if (actionDescription.type == ActionType.ExternalLink) {
      return of(
        this.actionDescriptionService
          .getExternalLinkActionParameters()
          .filter(item => item.name !== 'new_tab')
          .map(item => fieldToParameter(item))
      );
    } else if (actionDescription.type == ActionType.Export) {
      if (!actionDescription.exportAction) {
        return of([]);
      }

      if (actionDescription.exportAction.dataType == ExportDataType.CurrentComponent) {
        return of([]);
      }

      const modelId = actionDescription.exportAction.getModelId();

      return this.modelDescriptionStore.getDetailFirst(modelId).pipe(
        map(modelDescription => {
          if (!modelDescription) {
            return [];
          }

          const resource = this.currentEnvironmentStore.resources.find(
            item => item.uniqueName == modelDescription.resource
          );
          const items = this.actionDescriptionService.getExportActionParameters(resource, modelDescription, []);

          return itemsToFields(items).map(item => fieldToParameter(item));
        })
      );
    } else if (actionDescription.type == ActionType.OpenPopup) {
      const uid = actionDescription.openPopupAction ? actionDescription.openPopupAction.popup : undefined;

      if (
        !isSet(uid) ||
        !options.context ||
        !options.context.viewSettings ||
        !(options.context.viewSettings instanceof CustomViewSettings)
      ) {
        return of([]);
      }

      const page = options.context.viewSettings;

      return this.viewSettingsStore.getDetailFirst<CustomViewSettings>(page.uniqueName).pipe(
        map(viewSettings => {
          const popup = viewSettings ? viewSettings.popups.find(item => item.uid == uid) : undefined;
          const parameters = popup ? popup.parameters : [];
          const items = this.actionDescriptionService.getOpenPopupActionParameters(parameters);
          return itemsToFields(items).map(item => fieldToParameter(item));
        })
      );
    } else if (actionDescription.type == ActionType.ElementAction) {
      const items = this.actionDescriptionService.getElementActionParameters(
        actionDescription.elementAction,
        options.context
      );

      return of(itemsToFields(items).map(item => fieldToParameter(item)));
    } else {
      return of(actionDescription.actionParams);
    }
  }

  getActionDescription(action: ActionItem, options: { context?: ViewContext } = {}): Observable<ActionDescription> {
    if (!action) {
      return of(undefined);
    }

    if (action.sharedActionDescription) {
      return this.actionStore.getDetailFirst(action.sharedActionDescription);
    } else if (action.actionDescription) {
      return this.getActionDescriptionParams(action.actionDescription, { context: options.context }).pipe(
        map(actionParams => {
          const actionDescription = cloneDeep(action.actionDescription);

          actionDescription.actionParams = actionParams;

          return actionDescription;
        })
      );
    } else {
      return of(undefined);
    }
  }

  getActionDescriptionModel$(actionDescription: ActionDescription): Observable<ModelDescription> {
    if (!actionDescription || !actionDescription.model || !actionDescription.modelAction) {
      return of(undefined);
    }

    if (![ActionType.Query, ActionType.Download].includes(actionDescription.type)) {
      return of(undefined);
    }

    const modelId = [actionDescription.resource, actionDescription.model].join('.');
    return this.modelDescriptionStore.getDetailFirst(modelId);
  }

  getActionModel$(action: ActionItem): Observable<ModelDescription> {
    return this.getActionDescription(action).pipe(
      switchMap(actionDescription => this.getActionDescriptionModel$(actionDescription))
    );
  }

  getActionDescriptionOutputs(actionDescription: ActionDescription): Observable<OutputsInfo> {
    const defaultOutputs = { outputs: [] };

    return this.getActionDescriptionModel$(actionDescription).pipe(
      switchMap(modelDescription => {
        const type: ActionType = actionDescription ? actionDescription.type : undefined;
        const specifiedOutputs = actionDescription
          ? { outputs: actionDescription.outputs, arrayOutput: actionDescription.arrayOutput }
          : defaultOutputs;

        if (type == ActionType.Query || type == ActionType.Download) {
          if (specifiedOutputs.outputs.length) {
            return of(specifiedOutputs);
          }

          if (modelDescription) {
            if (['get', 'get_detail', 'create', 'update'].includes(actionDescription.modelAction)) {
              const outputs = modelDescription.dbFields.map(item => {
                const output = new FieldOutput();

                output.name = item.name;
                output.verboseName = item.verboseName;
                output.field = item.field;
                output.params = item.params;
                output.updateFieldDescription();

                return output;
              });

              return of({
                outputs: outputs,
                arrayOutput: actionDescription.modelAction == 'get'
              });
            } else if (actionDescription.modelAction == 'delete') {
              const output = new FieldOutput();

              output.name = NO_KEY_ATTRIBUTE;
              output.verboseName = 'result';
              output.field = FieldType.Boolean;
              output.updateFieldDescription();

              return of({
                outputs: [output]
              });
            }
          } else if (actionDescription.storageAction == 'get_object_url') {
            const output = new FieldOutput();

            output.name = 'url';
            output.verboseName = 'URL';
            output.field = FieldType.URL;
            output.updateFieldDescription();

            return of({
              outputs: [output]
            });
          } else if (actionDescription.storageAction == 'upload') {
            const urlOutput = new FieldOutput();

            urlOutput.name = 'url';
            urlOutput.verboseName = 'URL';
            urlOutput.field = FieldType.URL;
            urlOutput.updateFieldDescription();

            const pathOutput = new FieldOutput();

            pathOutput.name = 'path';
            pathOutput.verboseName = 'path';
            pathOutput.field = FieldType.Text;
            pathOutput.updateFieldDescription();

            return of({
              outputs: [urlOutput, pathOutput]
            });
          } else if (actionDescription.storageAction == 'get') {
            const objectsOutput = new FieldOutput();

            objectsOutput.name = 'objects';
            objectsOutput.verboseName = 'objects';
            objectsOutput.field = FieldType.JSON;
            objectsOutput.params = {
              structure: storageGetObjectsStructure
            };
            objectsOutput.updateFieldDescription();

            return of({
              outputs: [objectsOutput]
            });
          } else if (['create_directory', 'remove'].includes(actionDescription.storageAction)) {
            const output = new FieldOutput();

            output.name = NO_KEY_ATTRIBUTE;
            output.verboseName = 'result';
            output.field = FieldType.Boolean;
            output.updateFieldDescription();

            return of({
              outputs: [output]
            });
          }

          return of(defaultOutputs);
        } else if (type == ActionType.Workflow) {
          if (!actionDescription.workflowAction || !actionDescription.workflowAction.workflow) {
            return of(defaultOutputs);
          }

          if (actionDescription.workflowAction.workflow.result) {
            const resultOutputs = actionDescription.workflowAction.workflow.result.parameters.map(item => {
              const output = new FieldOutput();

              output.name = item.name;
              output.verboseName = item.verboseName || item.name;
              output.field = item.field;
              output.params = item.params;
              output.updateFieldDescription();

              return output;
            });

            return of({
              outputs: resultOutputs,
              array: actionDescription.workflowAction.workflow.result.array
            });
          }

          const lastStep = last(actionDescription.workflowAction.workflow.steps);

          if (!lastStep) {
            return of(defaultOutputs);
          }

          if (lastStep.type == WorkflowStepType.Action) {
            const actionStep = lastStep as ActionWorkflowStep;

            if (!actionStep || !actionStep.action) {
              return of(defaultOutputs);
            }

            return this.getActionOutputs(actionStep.action);
          }

          return of(defaultOutputs);
        } else if (
          [
            ActionType.Link,
            ActionType.ExternalLink,
            ActionType.ShowNotification,
            ActionType.CopyToClipboard,
            ActionType.Export,
            ActionType.OpenPopup,
            ActionType.ClosePopup,
            ActionType.OpenActionMenu
          ].includes(type)
        ) {
          const output = new FieldOutput();

          output.name = NO_KEY_ATTRIBUTE;
          output.verboseName = 'result';
          output.field = FieldType.Boolean;
          output.updateFieldDescription();

          return of({
            outputs: [output]
          });
        } else if (type == ActionType.Import) {
          const outputs = [
            { name: 'processedCount', field: FieldType.Number },
            { name: 'successCount', field: FieldType.Number },
            { name: 'failedCount', field: FieldType.Number },
            { name: 'totalCount', field: FieldType.Number },
            { name: 'objectResults', field: FieldType.JSON }
          ].map(item => {
            const output = new FieldOutput();

            output.name = item.name;
            output.verboseName = item.name;
            output.field = item.field;
            output.updateFieldDescription();

            return output;
          });

          return of({
            outputs: outputs
          });
        } else if ([ActionType.RunJavaScript].includes(type)) {
          const output = new FieldOutput();

          output.name = NO_KEY_ATTRIBUTE;
          output.verboseName = 'result';
          output.field = FieldType.JSON;
          output.updateFieldDescription();

          return of({
            outputs: [output]
          });
        } else if ([ActionType.ScanCode].includes(type)) {
          const output = new FieldOutput();

          output.name = 'value';
          output.verboseName = 'value';
          output.field = FieldType.Text;
          output.updateFieldDescription();

          return of({
            outputs: [output]
          });
        } else {
          return of(defaultOutputs);
        }
      })
    );
  }

  getActionOutputs(action: ActionItem): Observable<OutputsInfo> {
    const defaultOutputs = { outputs: [] };

    if (!action) {
      return of(defaultOutputs);
    }

    return this.getActionDescription(action).pipe(
      switchMap(actionDescription => this.getActionDescriptionOutputs(actionDescription))
    );
  }

  getWorkflowOutputs(workflow: Workflow): Observable<OutputsInfo> {
    const action = new ActionItem();

    action.actionDescription = new ActionDescription();
    action.actionDescription.type = ActionType.Workflow;
    action.actionDescription.workflowAction = new WorkflowAction();
    action.actionDescription.workflowAction.workflow = workflow;

    return this.getActionOutputs(action);
  }

  getElementStatus(element: string, action: ActionItem, context = {}): Observable<ElementStatus> {
    const name = action.actionDescription ? action.actionDescription.name : action.sharedActionDescription;
    const params = {
      element: element,
      name: name,
      context: context
    };

    return this.messageService.send(undefined, MessageName.GetElementStatus, params).pipe(
      map(result => {
        if (!result.json) {
          throw new ServerRequestError('No status specified');
        }

        return result.json as ElementStatus;
      })
    );
  }

  serializeParamsToQueryParams(parameters: ParameterField[], params: Object): Object {
    return parameters.reduce((acc, parameter) => {
      let value = params[parameter.name];

      if (value !== undefined) {
        const fieldDescription = getFieldDescriptionByType(parameter.field);

        if (fieldDescription.serializeValue) {
          if (parameter.field == FieldType.JSON) {
            parameter = cloneDeep(parameter);
            parameter.params['output_format'] = JsonOutputFormat.String;
          }

          value = fieldDescription.serializeValue(value, parameter);
        }

        acc[parameter.name] = value;
      }

      return acc;
    }, {});
  }

  getActionResponseBlob(
    response: ActionResponse,
    options: { fileName?: string; type?: string } = {}
  ): Observable<{
    file: File;
    response?: HttpResponse<any>;
  }> {
    if (response.blob) {
      return this.coerceFile(response.blob, { ...options, response: response.response });
    } else if (isSet(response.json)) {
      return this.coerceFile(response.json, { ...options, response: response.response });
    } else if (isSet(response.text)) {
      return this.coerceFile(response.text, { ...options, response: response.response });
    }
  }

  coerceFile(
    data: any,
    options: { fileName?: string; type?: string; response?: HttpResponse<any> } = {}
  ): Observable<{
    file: File;
    response?: HttpResponse<any>;
  }> {
    if (typeof data == 'string' && isAbsoluteUrl(data)) {
      const query = new HttpQuery();

      query.url = data;
      query.responseType = HttpResponseType.Blob;

      return this.httpQueryService.request<Blob>(query).pipe(
        map(response => {
          const fileName = getFilenameWithExtension(data);
          const file = new File([response.body], isSet(fileName) ? fileName : '', { type: response.body.type });
          return {
            file: file,
            response: response
          };
        })
      );
    } else if (data instanceof File) {
      return of({
        file: data,
        response: options.response
      });
    } else if (data instanceof Blob) {
      const file = new File([data], isSet(options.fileName) ? options.fileName : '', { type: options.type });
      return of({
        file: file,
        response: options.response
      });
    } else {
      let dataStr: string;
      let type: string;

      if (isSet(data) && typeof data === 'object') {
        try {
          dataStr = JSON.stringify(data);
          type = 'application/json';
        } catch (e) {}
      }

      if (!isSet(type)) {
        dataStr = String(data);
        type = 'text/plain';
      }

      const file = new File([dataStr], '', { type: type });
      return of({
        file: file,
        response: options.response
      });
    }
  }

  executeActionDescription(
    actionDescription: ActionDescription,
    params: Object = {},
    options: {
      context?: ViewContext;
      contextElement?: ViewContextElement;
      localContext?: Object;
      disablePopups?: boolean;
      disableRouting?: boolean;
      confirmRouting?: boolean;
      replaceUrl?: boolean;
      injector?: Injector;
      analyticsSource?: string;
      origin?: ActionOrigin;
    } = {}
  ): Observable<any> {
    const openLink = (url: UrlTree, newTab = false) => {
      if (
        newTab ||
        (options.context &&
          options.context['clickEvent'] &&
          (options.context['clickEvent'].shiftKey ||
            options.context['clickEvent'].ctrlKey ||
            options.context['clickEvent'].metaKey))
      ) {
        openUrl(this.routing.serializeUrl(url), true);
      } else {
        this.routing.navigateByUrl(url, { replaceUrl: !!options.replaceUrl });
      }
    };

    // const params = {};
    // const bulkParams = [];
    // console.log('output', options.context.outputs, action.inputs);
    // action.inputs
    //   .filter(item => item.valueType != InputValueType.Prompt)
    //   .forEach(item => {
    //     if (item.valueType == InputValueType.StaticValue) {
    //       params[item.name] = item.staticValue;
    //     } else if (item.valueType == InputValueType.Context) {
    //       const getValue = (ctx, query) => {
    //         const actionCtx = options.context ? options.context.outputs : {};
    //
    //         if (ctx && ctx.models) {
    //           actionCtx['record'] = ctx.models.reduce((prev, current) => {
    //             const attrs = current.getAttributes();
    //             toPairs(attrs).forEach(([k, v]) => {
    //               if (!prev.hasOwnProperty(k)) {
    //                 prev[k] = [];
    //               }
    //               prev[k].push(v);
    //             });
    //             return prev;
    //           }, {});
    //         }
    //
    //         const result = objectGet(actionCtx, query);
    //
    //         if (result !== EMPTY) {
    //           return result;
    //         }
    //       };
    //
    //       const value = getValue(options.context, item.contextValue);
    //
    //       if (value !== undefined) {
    //         if (isArray(value)) {
    //           params[item.name] = JSON.stringify(value);
    //           bulkParams.push(item.name);
    //         } else {
    //           params[item.name] = value;
    //         }
    //       }
    //     }
    //   });
    //
    // if (bulkParams.length) {
    //   params[ACTION_BULK_INPUTS_PARAM] = bulkParams.join(',');
    // }

    if (actionDescription.type == ActionType.Query) {
      if (!actionDescription.queryAction) {
        return throwError(new AppError('No query specified'));
      }

      const resource = this.currentEnvironmentStore.resources.find(
        item => item.uniqueName == actionDescription.resource
      );
      return this.executeQuery(resource, actionDescription.queryAction.query, actionDescription.actionParams, params, {
        context: options.context
      }).pipe(map(response => (response ? response.blob || response.json || response.text : undefined)));
    } else if (actionDescription.type == ActionType.Download) {
      if (!actionDescription.downloadAction) {
        return throwError(new AppError('No query specified'));
      }

      const resource = this.currentEnvironmentStore.resources.find(
        item => item.uniqueName == actionDescription.resource
      );

      const file$: Observable<{
        file: File;
        response?: HttpResponse<any>;
      }> =
        actionDescription.downloadAction && actionDescription.downloadAction.type == DownloadActionType.Input
          ? of(
              applyParamInput(actionDescription.downloadAction.input, {
                context: options.context,
                contextElement: options.contextElement,
                localContext: options.localContext,
                defaultValue: ''
              })
            ).pipe(switchMap(url => this.coerceFile(url)))
          : this.executeQuery(
              resource,
              actionDescription.downloadAction.query,
              actionDescription.actionParams,
              params,
              { context: options.context }
            ).pipe(
              switchMap(result => {
                if (isSet(actionDescription.downloadAction.fileColumn)) {
                  if (result && isArray(result.json)) {
                    const url = result.json[0]
                      ? result.json[0][actionDescription.downloadAction.fileColumn]
                      : undefined;
                    return this.coerceFile(url);
                  } else if (result && isPlainObject(result.json)) {
                    const url = result.json[actionDescription.downloadAction.fileColumn];
                    return this.coerceFile(url);
                  }
                }

                return this.getActionResponseBlob(result);
              })
            );

      return file$.pipe(
        map(result => {
          if (!result) {
            throw new AppError('No data to download');
          }

          return {
            response: result.response,
            blob: result.file
          } as ActionResponse;
        }),
        tap(response => {
          const contentDisposition = response.response
            ? response.response.headers.get('content-disposition')
            : undefined;
          const meta = contentDisposition
            ? fromPairs(
                contentDisposition.split(';').map(item => {
                  const parts = splitmax(item.trim(), '=', 2);
                  return parts.length == 2 ? [parts[0], strip(parts[1], '"')] : [parts[0], true];
                })
              )
            : {};
          let filename: string;

          if (meta['filename']) {
            filename = meta['filename'];
          } else {
            const name =
              response.blob instanceof File && isSet(response.blob.name)
                ? response.blob.name
                : ['file', moment().format('YYYY-MM-DD'), moment().format('HH-mm-ss')].join('_');
            const nameExtension = getExtension(name);
            const fileTypeExtension = getMimeExtension(response.blob.type);
            filename = !isSet(nameExtension) && isSet(fileTypeExtension) ? [name, fileTypeExtension].join('.') : name;
          }

          saveAs(response.blob, filename);
        }),
        map(response => (response ? response.blob : undefined))
      );
    } else if (actionDescription.type == ActionType.Link) {
      if (!actionDescription.linkAction) {
        return throwError(new AppError('No link specified'));
      }

      const newTab =
        isSet(params['new_tab']) && params['new_tab'] && params['new_tab'] !== '0' && params['new_tab'] !== 'false';

      if (actionDescription.linkAction.type == SegueType.Page) {
        if (!actionDescription.linkAction.page) {
          return throwError(new AppError('No page specified'));
        }

        return this.viewSettingsStore.getDetailFirst(actionDescription.linkAction.page).pipe(
          switchMap(viewSettings => {
            if (!viewSettings) {
              throw new AppError('Page not found');
            }

            if (options.disableRouting) {
              return of(undefined);
            }

            const queryParams = this.serializeParamsToQueryParams(viewSettings.parameters, params);
            const url = this.routing.createUrlTreeApp(viewSettings.link, { queryParams: queryParams });

            return this.runOnRedirectConfirm(
              String(url),
              () => {
                openLink(url, newTab);
              },
              options.confirmRouting
            );
          })
        );
      } else if (actionDescription.linkAction.type == SegueType.PreviousPage) {
        if (options.disableRouting) {
          return of(undefined);
        }

        return this.runOnRedirectConfirm(
          'Previous URL',
          () => {
            window.history.back();
          },
          options.confirmRouting
        );
      } else if (actionDescription.linkAction.type == SegueType.ModelCreate) {
        return this.modelDescriptionStore.getDetailFirst(actionDescription.linkAction.model).pipe(
          switchMap(modelDescription => {
            if (options.disableRouting) {
              return of(undefined);
            }

            const url = this.routing.createUrlTreeApp(modelDescription.createLink, { queryParams: params });

            return this.runOnRedirectConfirm(
              String(url),
              () => {
                openLink(url, newTab);
              },
              options.confirmRouting
            );
          })
        );
      } else if (actionDescription.linkAction.type == SegueType.ModelChange) {
        return this.modelDescriptionStore.getDetailFirst(actionDescription.linkAction.model).pipe(
          switchMap(modelDescription => {
            const pageParams = cloneDeep(params);

            if (pageParams.hasOwnProperty('id')) {
              delete pageParams['id'];
            }

            const link = modelDescription.changeLink(params['id']);

            if (!link) {
              throw new AppError('No page found');
            }

            if (options.disableRouting) {
              return of(undefined);
            }

            const url = this.routing.createUrlTreeApp(link, { queryParams: params });

            return this.runOnRedirectConfirm(
              String(url),
              () => {
                openLink(url, newTab);
              },
              options.confirmRouting
            );
          })
        );
      } else if (actionDescription.linkAction.type == SegueType.ModelMassEdit) {
        return this.modelDescriptionStore.getDetailFirst(actionDescription.linkAction.model).pipe(
          switchMap(modelDescription => {
            if (isArray(params['ids'])) {
              params['ids'] = JSON.stringify(params['ids']);
            }

            if (options.disableRouting) {
              return of(undefined);
            }

            const url = this.routing.createUrlTreeApp(modelDescription.massEditLink, { queryParams: params });

            return this.runOnRedirectConfirm(
              String(url),
              () => {
                openLink(url, newTab);
              },
              options.confirmRouting
            );
          })
        );
      } else if (actionDescription.linkAction.type == SegueType.ModelDelete) {
        return this.modelDescriptionStore.getDetailFirst(actionDescription.linkAction.model).pipe(
          switchMap(modelDescription => {
            this.sessionStorage.set(ActionDescriptionParamsExecuteSessionKey, JSON.stringify(params));

            if (options.disableRouting) {
              return of(undefined);
            }

            const url = this.routing.createUrlTreeApp(modelDescription.deleteLink, { queryParamsHandling: 'merge' });

            return this.runOnRedirectConfirm(
              String(url),
              () => {
                openLink(url, newTab);
              },
              options.confirmRouting
            );
          })
        );
      } else if (actionDescription.linkAction.type == SegueType.ModelActivityLog) {
        return this.modelDescriptionStore.getDetailFirst(actionDescription.linkAction.model).pipe(
          switchMap(modelDescription => {
            if (options.disableRouting) {
              return of(undefined);
            }

            const url = this.routing.createUrlTreeApp(modelDescription.userActivityLink, { queryParams: params });

            return this.runOnRedirectConfirm(
              String(url),
              () => {
                openLink(url, newTab);
              },
              options.confirmRouting
            );
          })
        );
      }
    } else if (actionDescription.type == ActionType.ExternalLink) {
      if (!params['href']) {
        return throwError(new AppError('No URL specified'));
      }

      if (options.disableRouting) {
        return of(undefined);
      }

      const url = params['href'];
      const newTab =
        isSet(params['new_tab']) && params['new_tab'] && params['new_tab'] !== '0' && params['new_tab'] !== 'false';

      return this.runOnRedirectConfirm(
        String(url),
        () => {
          openUrl(url, newTab);
        },
        options.confirmRouting
      );
    } else if (actionDescription.type == ActionType.ElementAction) {
      const path = actionDescription.elementAction;
      return this.executeElement(path, options.context, params);
    } else if (actionDescription.type == ActionType.ShowNotification) {
      if (!actionDescription.notificationAction) {
        return throwError(new AppError('No notification specified'));
      }

      const title = applyParamInput(actionDescription.notificationAction.title, {
        context: options.context,
        contextElement: options.contextElement,
        localContext: options.localContext,
        defaultValue: ''
      });

      const description = applyParamInput(actionDescription.notificationAction.description, {
        context: options.context,
        contextElement: options.contextElement,
        localContext: options.localContext,
        defaultValue: ''
      });

      const newlineToBr = (value: string): string => {
        if (typeof value == 'string') {
          value = value.replace(/(\r\n|\n|\r)/gm, '<br>');
        }
        return value;
      };

      this.notificationService.show({
        title: title,
        description: isSet(description) ? newlineToBr(description) : undefined,
        icon: actionDescription.notificationAction.icon,
        type: actionDescription.notificationAction.type,
        color: actionDescription.notificationAction.color,
        theme: true,
        closeTimeout: actionDescription.notificationAction.closeTimeoutEnabled
          ? actionDescription.notificationAction.closeTimeout
          : null
      });

      return of(undefined);
    } else if (actionDescription.type == ActionType.SetProperty) {
      if (
        !actionDescription.setPropertyAction ||
        !isSet(actionDescription.setPropertyAction.property) ||
        !actionDescription.setPropertyAction.value
      ) {
        return throwError(new AppError('No property value specified'));
      }

      return this.projectPropertyStore.getDetail(actionDescription.setPropertyAction.property).pipe(
        map(property => {
          if (!property) {
            return;
          }

          const value = applyParamInput(actionDescription.setPropertyAction.value, {
            context: options.context,
            contextElement: options.contextElement,
            localContext: options.localContext,
            defaultValue: ''
          });

          if (property.type == ProjectPropertyType.Global && this.currentEnvironmentStore.globalStorage) {
            this.currentEnvironmentStore.globalStorage.setValue(property, value);
          } else if (property.type == ProjectPropertyType.Page && options.context && options.context.pageStorage) {
            options.context.pageStorage.setValue(property, value);
          }

          return value;
        })
      );
    } else if (actionDescription.type == ActionType.RunJavaScript) {
      if (!actionDescription.runJavaScriptAction || !isSet(actionDescription.runJavaScriptAction.js)) {
        return throwError(new AppError('No code specified'));
      }

      const result = executeJavaScript(actionDescription.runJavaScriptAction.js, {
        context: options.context,
        contextElement: options.contextElement,
        localContext: options.localContext
      });

      return of({
        [NO_KEY_ATTRIBUTE]: result
      });
    } else if (actionDescription.type == ActionType.CopyToClipboard) {
      if (!actionDescription.copyToClipboardAction || !actionDescription.copyToClipboardAction.value) {
        return throwError(new AppError('No value specified'));
      }

      const value = applyParamInput(actionDescription.copyToClipboardAction.value, {
        context: options.context,
        contextElement: options.contextElement,
        localContext: options.localContext,
        defaultValue: ''
      });

      return copyTextToClipboard(value);
    } else if (actionDescription.type == ActionType.Export) {
      if (!actionDescription.exportAction) {
        return throwError(new AppError('No data specified'));
      }

      let dataSource = actionDescription.exportAction.dataSource;
      let baseQueryOptions: GetQueryOptions = {};

      if (actionDescription.exportAction.dataType == ExportDataType.CurrentComponent) {
        const contextItem =
          options.context && options.contextElement
            ? options.context.elements.find(item => item.element === options.contextElement)
            : undefined;
        const parentContextElement = contextItem ? contextItem && contextItem.parent : undefined;
        const listElement =
          parentContextElement && parentContextElement.element instanceof ListElementItem
            ? parentContextElement.element
            : undefined;

        if (listElement && listElement.layouts[0]) {
          dataSource = cloneDeep(listElement.layouts[0].dataSource);
          dataSource.columns = actionDescription.exportAction.dataSource.columns;
          baseQueryOptions = parentContextElement.getQueryOptions() || {};
        }
      }

      const queryOptions: GetQueryOptions = {
        filters: [
          ...(baseQueryOptions.filters ? baseQueryOptions.filters : []),
          ...(actionDescription.exportAction.filters ? actionDescription.exportAction.filters : [])
        ],
        ...(isSet(baseQueryOptions.search) && {
          search: baseQueryOptions.search
        }),
        ...(isSet(actionDescription.exportAction.search) && {
          search: actionDescription.exportAction.search
        }),
        ...(baseQueryOptions.sort && {
          sort: baseQueryOptions.sort
        }),
        ...(actionDescription.exportAction.sort && {
          sort: actionDescription.exportAction.sort
        })
      };

      if (!dataSource) {
        return throwError(new AppError('No data specified'));
      }

      const modelId = actionDescription.exportAction.getModelId();
      return this.modelDescriptionStore.getDetailFirst(modelId).pipe(
        map(modelDescription => {
          this.exportController.openPopup({
            dataSource: dataSource,
            queryOptions: queryOptions,
            modelDescription: modelDescription,
            context: options.context,
            contextElement: options.contextElement,
            localContext: options.localContext,
            params: params,
            ids: isSet(params['ids']) ? [params['ids']] : undefined,
            theme: true
          });

          return undefined;
        })
      );
    } else if (actionDescription.type == ActionType.Import) {
      if (!actionDescription.importAction || !actionDescription.importAction.getModelId()) {
        return throwError(new AppError('No collection specified'));
      }

      const modelId = actionDescription.importAction.getModelId();
      return this.modelDescriptionStore.getDetailFirst(modelId).pipe(
        switchMap(modelDescription => {
          const result = new ReplaySubject<ModelImportFinishedEvent>();
          const resource = this.currentEnvironmentStore.resources.find(
            item => item.uniqueName == actionDescription.importAction.resource
          );

          this.popupService.push({
            component: this.importComponent,
            popupComponent: ThinDialogPopupComponent,
            inputs: {
              resource: resource,
              modelDescription: modelDescription,
              analyticsSource: options.analyticsSource
            },
            outputs: {
              imported: [event => result.next(event)],
              cancelled: [event => result.next(event)]
            },
            injector: this.injector
          });

          return result.asObservable();
        })
      );
    } else if (actionDescription.type == ActionType.OpenPopup) {
      if (!actionDescription.openPopupAction || !actionDescription.openPopupAction.popup) {
        return throwError(new AppError('No modal specified'));
      }

      if (!this.customizeService.handler || !this.customizeService.handler.openPopup) {
        return throwError(new AppError('Incorrect handler'));
      }

      if (!options.disablePopups) {
        this.customizeService.handler.openPopup(actionDescription.openPopupAction.popup, {
          params: params,
          togglePopup: actionDescription.openPopupAction.togglePopup,
          origin: options.origin
        });
      }

      return of(undefined);
    } else if (actionDescription.type == ActionType.ClosePopup) {
      if (!actionDescription.closePopupAction) {
        return throwError(new AppError('No modal specified'));
      }

      if (!this.customizeService.handler || !this.customizeService.handler.closePopup) {
        return throwError(new AppError('Incorrect handler'));
      }

      if (!options.disablePopups) {
        this.customizeService.handler.closePopup(actionDescription.closePopupAction.popup);
      }

      return of(undefined);
    } else if (actionDescription.type == ActionType.OpenActionMenu) {
      if (!actionDescription.openActionMenuAction) {
        return throwError(new AppError('No action specified'));
      }

      if (!options.origin) {
        return throwError(new AppError('No origin specified'));
      }

      return new Observable<boolean>(observer => {
        const positionStrategy = this.overlay
          .position()
          .flexibleConnectedTo(options.origin)
          .withPositions([
            {
              panelClass: ['overlay_position_bottom-left'],
              originX: 'start',
              overlayX: 'start',
              originY: 'bottom',
              overlayY: 'top',
              offsetX: -8
            },
            {
              panelClass: ['overlay_position_bottom-right'],
              originX: 'end',
              overlayX: 'end',
              originY: 'bottom',
              overlayY: 'top',
              offsetX: 8
            },
            {
              panelClass: ['overlay_position_top-left'],
              originX: 'start',
              overlayX: 'start',
              originY: 'top',
              overlayY: 'bottom',
              offsetX: -8
            },
            {
              panelClass: ['overlay_position_top-right'],
              originX: 'end',
              overlayX: 'end',
              originY: 'top',
              overlayY: 'bottom',
              offsetX: 8
            },
            {
              panelClass: ['overlay_position_left-center'],
              originX: 'start',
              overlayX: 'end',
              originY: 'center',
              overlayY: 'center'
            },
            {
              panelClass: ['overlay_position_right-center'],
              originX: 'end',
              overlayX: 'start',
              originY: 'center',
              overlayY: 'center'
            }
          ])
          .withPush(true)
          .withGrowAfterOpen(true);

        const overlayRef = this.overlay.create({
          panelClass: ['overlay'],
          positionStrategy: positionStrategy,
          hasBackdrop: true,
          backdropClass: 'popover2-backdrop'
        });

        const injector = Injector.create({
          providers: [
            {
              provide: OverlayRef,
              useValue: overlayRef
            }
          ],
          parent: options.injector || this.injector
        });

        const portal = new ComponentPortal<ActionServiceActionMenuComponent>(
          this.actionMenuComponent,
          undefined,
          injector
        );
        const componentRef = overlayRef.attach(portal);

        componentRef.instance.actions = actionDescription.openActionMenuAction.actions;
        componentRef.instance.context = options.context;
        componentRef.instance.contextElement = options.contextElement;
        componentRef.instance.localContext = options.localContext;
        componentRef.instance.analyticsSource = options.analyticsSource;

        const subscriptions: Subscription[] = [];

        subscriptions.push(
          overlayRef.backdropClick().subscribe(() => {
            overlayRef.dispose();
          })
        );

        observer.next(true);

        return () => {
          subscriptions.forEach(item => item.unsubscribe());
          componentRef.destroy();
          overlayRef.dispose();
        };
      });
    } else if (actionDescription.type == ActionType.ScanCode) {
      return this.scannerPopupController.scan().pipe(
        map(result => {
          if (result.cancelled) {
            throw new CancelledError();
          }

          return {
            value: result.result ? result.result.text : undefined
          };
        })
      );
    } else if (actionDescription.type == ActionType.Workflow) {
      if (!actionDescription.workflowAction || !actionDescription.workflowAction.workflow) {
        return throwError(new AppError('No workflow specified'));
      }

      const dateRun = moment();

      return this.executeWorkflow(actionDescription.workflowAction.workflow, params, {
        context: options.context,
        contextElement: options.contextElement,
        localContext: options.localContext,
        disablePopups: options.disablePopups,
        disableRouting: options.disableRouting,
        confirmRouting: options.confirmRouting,
        injector: options.injector,
        analyticsSource: options.analyticsSource
      }).pipe(
        filter(item => item.type == WorkflowExecuteEventType.WorkflowFinished),
        map((item: WorkflowExecuteWorkflowFinishedEvent) => {
          const project = window['project'];
          const environment = window['project_environment'];
          const dateFinished = moment();

          const pageUid =
            options.context && options.context.viewSettings ? options.context.viewSettings.uid : undefined;
          const elementUid =
            options.contextElement && options.contextElement.element ? options.contextElement.element.uid : undefined;
          const elementType =
            options.contextElement && options.contextElement.element ? options.contextElement.element.type : undefined;
          const elementLabel =
            options.contextElement && options.contextElement.element ? options.contextElement.element.name : undefined;
          const userUid = this.currentUserStore.instance.uid;

          this.workflowBackendRunService
            .createForWorkflow(
              project,
              environment,
              actionDescription.workflowAction.workflow,
              item.success,
              item.run,
              dateRun,
              dateFinished,
              {
                pageUid: pageUid,
                elementUid: elementUid,
                elementType: elementType,
                elementLabel: elementLabel,
                userUid: userUid
              }
            )
            .subscribe();

          if (!item.success) {
            throw item.error;
          }

          return item.result;
        })
      );
    }

    return throwError(new AppError('No action specified'));
  }

  requestApproval(
    action: ActionItem,
    actionParams: Object = {},
    options: {
      context?: ViewContext;
      contextElement?: ViewContextElement;
      localContext?: Object;
    } = {}
  ): Observable<Task> {
    return this.taskQueueStore.getDetailFirst(action.approve.taskQueue).pipe(
      tap(queue => {
        if (!this.currentProjectStore.instance.features.isTasksEnabled()) {
          throw new AppError('Tasks feature is not enabled in your Plan');
        }

        if (!queue) {
          throw new AppError('Task queue does not exist');
        }
      }),
      switchMap(queue => {
        return this.dialogService
          .confirm({
            title: 'Approval required',
            description: `This action requires approval. It will be executed only after your teammate approval. The appropriate task will be created in task queue <strong>${queue.name}</strong>.`
          })
          .pipe(
            switchMap(confirm => {
              if (!confirm) {
                return of(undefined);
              }

              const task = new Task();
              const parameter = new ParameterField();

              parameter.name = 'name';
              parameter.field = FieldType.Text;

              const nameParams = applyParamInputs({}, [action.approve.taskName], {
                context: options.context,
                contextElement: options.contextElement,
                localContext: options.localContext,
                parameters: [parameter]
              });
              const taskParams = applyParamInputs({}, action.approve.taskInputs, {
                context: options.context,
                contextElement: options.contextElement,
                localContext: options.localContext,
                parameters: queue.parameters
              });
              let assigned: User;

              if (action.approve.taskAssignee) {
                assigned = new User();
                assigned.uid = action.approve.taskAssignee;
              }

              const status = queue.statuses.find(item => item.uid == action.approve.taskCreateStatus);

              if (!isSet(action.approve.taskQueue)) {
                throw new AppError('Task queue is not specified');
              }

              if (!isSet(nameParams['value'])) {
                throw new AppError('Task name is not specified');
              }

              if (!status) {
                throw new AppError('Task status is not specified');
              }

              task.queue = action.approve.taskQueue;
              task.name = nameParams['value'];
              task.assigned = assigned;
              task.approveAction = action;
              task.approveActionParams = actionParams;
              task.status = status;
              task.objectType = options.context ? options.context.objectType : undefined;
              task.objectId = options.context ? options.context.objectId : undefined;
              task.parameterValues = taskParams;

              return this.taskService
                .create(
                  this.currentProjectStore.instance.uniqueName,
                  this.currentEnvironmentStore.instance.uniqueName,
                  task
                )
                .pipe(
                  tap(result => {
                    this.notificationService.success(
                      'Approval task created',
                      `Task ${result.name} was successfully created`
                    );

                    this.analyticsService.sendSimpleEvent(AnalyticsEvent.Approval.TaskCreated, {
                      TaskQueueId: result.queue
                    });
                  })
                );
            }),
            catchError(error => {
              if (error instanceof ServerRequestError && error.nonFieldErrors.length) {
                this.notificationService.error('Approval failed', error.nonFieldErrors[0]);
              } else {
                this.notificationService.error('Approval Failed', error);
              }

              return throwError(error);
            })
          );
      })
    );
  }

  executeQuery(
    resource: Resource,
    query: ActionQuery,
    parameters: ParameterField[],
    params?: Object,
    options: {
      context?: ViewContext;
      rawErrors?: boolean;
    } = {}
  ): Observable<ActionResponse> {
    const data = {
      actionParams: params
    };

    if (!resource || !query) {
      return throwError(new AppError('No query specified'));
    }

    params = params || {};

    if (
      isResourceTypeItem3rdParty(resource.typeItem) &&
      !this.currentProjectStore.instance.features.isThirdPartyResourcesEnabled() &&
      !resource.demo
    ) {
      return throwError(new AppError('Business Apps feature is not enabled in your Plan'));
    }

    const throwOnPermissionError = (action: string) => {
      if (
        options.context &&
        options.context.viewSettings &&
        options.context.viewSettings instanceof CustomViewSettings
      ) {
        const project = this.currentProjectStore.instance;
        const environment = this.currentEnvironmentStore.instance;

        if (!project.hasEnvironmentPagePermission(environment, options.context.viewSettings.uid, action)) {
          throw new AppError('You dont have permission for run this operation');
        }
      }
    };

    const getPermissionError = (action: string): Observable<never> => {
      try {
        throwOnPermissionError(action);
      } catch (e) {
        return throwError(e);
      }
    };

    if (query.queryType == QueryType.Http && query.httpQuery) {
      if (!this.currentProjectStore.instance.features.isCustomResourcesEnabled() && !resource.demo) {
        return throwError(new AppError('Custom Queries feature is not enabled in your Plan'));
      }

      const error = getPermissionError('w');
      if (error) {
        return error;
      }

      const controller = this.resourceControllerService.get<RestApiResourceControllerService>(ResourceType.RestAPI);
      return controller.actionExecute(resource, query, parameters, data, options.rawErrors);
    } else if (query.queryType == QueryType.SQL && query.sqlQuery) {
      if (!this.currentProjectStore.instance.features.isCustomResourcesEnabled() && !resource.demo) {
        return throwError(new AppError('Custom Queries feature is not enabled in your Plan'));
      }

      const error = getPermissionError('w');
      if (error) {
        return error;
      }

      const controller = this.resourceControllerService.getForResource<JetBridgeResourceController>(resource, true);
      return controller.actionExecuteSql(resource, query, parameters, { actionParams: params });
    } else if (query.queryType == QueryType.Object && query.objectQuery) {
      if (!this.currentProjectStore.instance.features.isCustomResourcesEnabled() && !resource.demo) {
        return throwError(new AppError('Custom Queries feature is not enabled in your Plan'));
      }

      const error = getPermissionError('w');
      if (error) {
        return error;
      }

      const controller = this.resourceControllerService.get<FirebaseResourceController>(resource.type);
      return controller.objectQuery(resource, query.objectQuery, params);
    } else if (query.queryType == QueryType.Simple) {
      return combineLatest(this.modelDescriptionStore.getFirst(), this.actionStore.getFirst()).pipe(
        switchMap(([modelDescriptions, actionDescriptions]) => {
          const controller = this.resourceControllerService.get(resource.type);
          const project = this.currentProjectStore.instance;
          const environment = this.currentEnvironmentStore.instance;

          const actionDescription = actionDescriptions.find(item => {
            return item.resource == resource.uniqueName && item.name == query.simpleQuery.name;
          });

          if (actionDescription && !isSet(actionDescription.modelAction) && !isSet(actionDescription.storageAction)) {
            throwOnPermissionError('w');

            // if (!project.hasEnvironmentModelPermission(environment, model.modelId, 'w')) {
            //   throw new AppError('You dont have permission for run this operation');
            // }

            return controller.actionExecute(resource, query, parameters, data);
          }

          const modelDescription = actionDescription.modelAction
            ? modelDescriptions.find(
                item => item.resource == actionDescription.resource && item.model == actionDescription.model
              )
            : undefined;
          const modelAction = modelDescription
            ? modelDescription.autoActions().find(item => item.name == actionDescription.modelAction)
            : undefined;
          const storage = actionDescription.storageAction
            ? resource.storages.find(item => item.isSame(actionDescription.storage))
            : undefined;
          const storageAction = storage
            ? storage.autoActions().find(item => item.name == actionDescription.storageAction)
            : undefined;

          if (modelAction) {
            const model = new Model(this.injector).deserialize(modelDescription.model, params);
            const viewSettingsUid =
              options.context &&
              options.context.viewSettings &&
              options.context.viewSettings instanceof CustomViewSettings
                ? options.context.viewSettings.uid
                : undefined;

            model.setAttributes(params);
            model.setUp(modelDescription);

            if (modelAction.name == 'get') {
              throwOnPermissionError('r');

              if (!project.hasEnvironmentModelPermission(environment, model.modelId, 'r')) {
                throw new AppError(
                  `You dont have permission for run this operation: ${actionDescription.anyName} (${resource.name})`
                );
              }

              const columns = modelDescription.fields
                .filter(item => item.type == ModelFieldType.Db)
                .map(item => modelFieldToRawListViewSettingsColumn(item));

              return this.modelService
                .getQuery(
                  project,
                  environment,
                  resource,
                  modelDescription.getQuery,
                  modelDescription.getParameters,
                  params,
                  columns || []
                )
                .pipe(
                  map(result => {
                    return {
                      json: result.results.map(item => item.serialize())
                    };
                  })
                );
            } else if (modelAction.name == 'get_detail') {
              throwOnPermissionError('r');

              if (!project.hasEnvironmentModelPermission(environment, model.modelId, 'r')) {
                throw new AppError(
                  `You dont have permission for run this operation: ${actionDescription.anyName} (${resource.name})`
                );
              }

              const columns = modelDescription.fields
                .filter(item => item.type == ModelFieldType.Db)
                .map(item => modelFieldToRawListViewSettingsColumn(item));

              const modelParameters = controller.getDetailParametersOrDefaults(resource, modelDescription);

              return this.modelService
                .getDetailQuery(
                  project,
                  environment,
                  resource,
                  modelDescription.getDetailQuery,
                  modelParameters,
                  params,
                  columns || []
                )
                .pipe(
                  map(result => {
                    return {
                      json: result.serialize()
                    };
                  })
                );
            } else if (modelAction.name == 'create') {
              throwOnPermissionError('w');

              if (!project.hasEnvironmentModelPermission(environment, model.modelId, 'w')) {
                throw new AppError(
                  `You dont have permission for run this operation: ${actionDescription.anyName} (${resource.name})`
                );
              }

              return controller.modelCreate(resource, modelDescription, model).pipe(
                delayWhen(result => {
                  return this.modelService.onModelAction(
                    project,
                    environment,
                    resource,
                    controller,
                    modelDescription,
                    result,
                    ModelAction.Create,
                    {
                      viewSettings: viewSettingsUid
                    }
                  );
                }),
                map(result => {
                  return {
                    json: result.serialize()
                  };
                })
              );
            } else if (modelAction.name == 'update') {
              throwOnPermissionError('w');

              if (!project.hasEnvironmentModelPermission(environment, model.modelId, 'w')) {
                throw new AppError(
                  `You dont have permission for run this operation: ${actionDescription.anyName} (${resource.name})`
                );
              }

              const fields = modelDescription.dbFields
                .map(item => item.name)
                .filter(name => params[name] !== undefined);
              return controller.modelUpdate(resource, modelDescription, model, fields).pipe(
                delayWhen(() => {
                  return this.modelService.onModelAction(
                    project,
                    environment,
                    resource,
                    controller,
                    modelDescription,
                    model,
                    ModelAction.Update,
                    {
                      viewSettings: viewSettingsUid
                    }
                  );
                }),
                map(result => {
                  if (result) {
                    this._modelUpdated$.next({ modelDescription: modelDescription, model: result });
                  }

                  return {
                    json: result.serialize()
                  };
                })
              );
            } else if (modelAction.name == 'delete') {
              throwOnPermissionError('d');

              if (!project.hasEnvironmentModelPermission(environment, model.modelId, 'd')) {
                throw new AppError(
                  `You dont have permission for run this operation: ${actionDescription.anyName} (${resource.name})`
                );
              }

              return controller.modelDelete(resource, modelDescription, model).pipe(
                delayWhen(() => {
                  return this.modelService.onModelAction(
                    project,
                    environment,
                    resource,
                    controller,
                    modelDescription,
                    model,
                    ModelAction.Delete,
                    {
                      viewSettings: viewSettingsUid
                    }
                  );
                }),
                map(result => {
                  return {
                    json: result
                  };
                })
              );
            }
          } else if (storageAction) {
            if (storageAction.name == 'get_object_url') {
              // checkPagePermission('r');

              // if (!project.hasEnvironmentModelPermission(environment, model.modelId, 'r')) {
              //   throw new AppError(
              //     `You dont have permission for run this operation: ${actionDescription.anyName} (${resource.name})`
              //   );
              // }

              const path: string = params ? params['path'] : undefined;
              const expiresInSec: number = params ? params['expires'] : undefined;

              return this.storageService
                .getObjectUrl(resource, storage, storage.getObjectUrlQuery, path, expiresInSec)
                .pipe(
                  map(result => {
                    return {
                      json: result
                    };
                  })
                );
            } else if (storageAction.name == 'upload') {
              // checkPagePermission('r');

              // if (!project.hasEnvironmentModelPermission(environment, model.modelId, 'r')) {
              //   throw new AppError(
              //     `You dont have permission for run this operation: ${actionDescription.anyName} (${resource.name})`
              //   );
              // }

              const fileData: string | File = params ? params['file'] : undefined;
              const path: string = params ? params['path'] : undefined;

              return this.coerceFile(fileData).pipe(
                switchMap(file => {
                  return this.storageService.upload(resource, storage, storage.getObjectUrlQuery, file.file, path).pipe(
                    filter(event => !!event.result),
                    map(result => {
                      return {
                        json:
                          result && result.result
                            ? {
                                path: result.result.uploadedPath,
                                url: result.result.uploadedUrl
                              }
                            : undefined
                      };
                    })
                  );
                })
              );
            } else if (storageAction.name == 'get') {
              // checkPagePermission('r');

              // if (!project.hasEnvironmentModelPermission(environment, model.modelId, 'r')) {
              //   throw new AppError(
              //     `You dont have permission for run this operation: ${actionDescription.anyName} (${resource.name})`
              //   );
              // }

              const path: string = params ? params['path'] : undefined;

              return this.storageService.getStorageObjects(resource, storage, storage.getObjectUrlQuery, path).pipe(
                map(result => {
                  return {
                    json: result
                  };
                })
              );
            } else if (storageAction.name == 'create_directory') {
              // checkPagePermission('r');

              // if (!project.hasEnvironmentModelPermission(environment, model.modelId, 'r')) {
              //   throw new AppError(
              //     `You dont have permission for run this operation: ${actionDescription.anyName} (${resource.name})`
              //   );
              // }

              const path: string = params ? params['path'] : undefined;

              return this.storageService.createStorageFolder(resource, storage, storage.getObjectUrlQuery, path).pipe(
                map(result => {
                  return {
                    json: result
                  };
                })
              );
            } else if (storageAction.name == 'remove') {
              // checkPagePermission('r');

              // if (!project.hasEnvironmentModelPermission(environment, model.modelId, 'r')) {
              //   throw new AppError(
              //     `You dont have permission for run this operation: ${actionDescription.anyName} (${resource.name})`
              //   );
              // }

              const path: string = params ? params['path'] : undefined;

              return this.storageService.deleteStorageObject(resource, storage, storage.getObjectUrlQuery, path).pipe(
                map(result => {
                  return {
                    json: result
                  };
                })
              );
            }
          }

          throw new AppError('No action found');
        })
      );
    } else {
      return throwError(new AppError('No query found'));
    }
  }

  executeElement(path: (string | number)[], context: ViewContext, params?: Object): Observable<any> {
    if (!context) {
      return throwError(new AppError('No context'));
    }

    const action = context.getElementAction(path);

    if (!action) {
      return throwError(new AppError(`Action not found: ${path ? path.join('.') : path}`));
    }

    const result = action.handler(params);
    return forceObservable(result);
  }

  getActionDescriptionLabel(
    actionDescription: ActionDescription,
    inputs: Input[],
    context?: ViewContext,
    element?: ViewContextElement
  ): Observable<string[]> {
    if (!actionDescription) {
      return of(undefined);
    }

    if (actionDescription.type == ActionType.Query || actionDescription.type == ActionType.Download) {
      const resource = this.currentEnvironmentStore.resources.find(
        item => item.uniqueName == actionDescription.resource
      );
      let query: ActionQuery;

      if (actionDescription.type == ActionType.Query && actionDescription.queryAction) {
        query = actionDescription.queryAction.query;
      } else if (actionDescription.type == ActionType.Download && actionDescription.downloadAction) {
        query = actionDescription.downloadAction.query;
      }

      if (!resource || !query || !query.queryType) {
        return of([actionDescription.typeStr]);
      }

      const prefix = actionDescription.type == ActionType.Download ? [actionDescription.typeStr] : [];

      if (query.queryType == QueryType.Http) {
        return of([...prefix, 'HTTP query', resource.name]);
      } else if (query.queryType == QueryType.SQL) {
        return of([...prefix, 'SQL query', resource.name]);
      } else if (query.queryType == QueryType.Object) {
        return of([...prefix, 'Database query', resource.name]);
      } else if (query.queryType == QueryType.Simple) {
        return this.modelDescriptionStore.getFirst().pipe(
          map(modelDescriptions => {
            const modelDescription = actionDescription.modelAction
              ? modelDescriptions.find(
                  item => item.resource == actionDescription.resource && item.model == actionDescription.model
                )
              : undefined;
            const modelAction = modelDescription
              ? modelDescription.autoActions().find(item => item.name == actionDescription.modelAction)
              : undefined;
            const storage = actionDescription.storageAction
              ? resource.storages.find(item => item.isSame(actionDescription.storage))
              : undefined;
            const storageAction = storage
              ? storage.autoActions().find(item => item.name == actionDescription.storageAction)
              : undefined;

            if (modelAction) {
              return [
                ...prefix,
                `${modelDescription.verboseNamePlural || modelDescription.model} - ${modelAction.label}`,
                resource.name
              ];
            } else if (storageAction) {
              return [...prefix, `${storage.name || storage.uniqueName} - ${storageAction.label}`, resource.name];
            } else {
              return [...prefix, `${resource.name} query`];
            }
          })
        );
      }
    } else if (actionDescription.type == ActionType.Link) {
      if (!actionDescription.linkAction) {
        return of([actionDescription.typeStr]);
      }

      if (actionDescription.linkAction.type == SegueType.Page) {
        if (!actionDescription.linkAction.page) {
          return of([actionDescription.typeStr]);
        }

        return this.viewSettingsStore.getDetailFirst(actionDescription.linkAction.page).pipe(
          map(viewSettings => {
            if (!viewSettings) {
              return [actionDescription.typeStr];
            }

            return [viewSettings.name, actionDescription.typeStr];
          })
        );
      } else if (actionDescription.linkAction.type == SegueType.PreviousPage) {
        return of(['Previous Page', actionDescription.typeStr]);
      } else if (actionDescription.linkAction.type == SegueType.ModelCreate) {
        return this.modelDescriptionStore.getDetailFirst(actionDescription.linkAction.model).pipe(
          map(modelDescription => {
            if (!modelDescription) {
              return [actionDescription.typeStr];
            }

            return [
              `${modelDescription.verboseNamePlural || modelDescription.model} - Create`,
              actionDescription.typeStr
            ];
          })
        );
      } else if (actionDescription.linkAction.type == SegueType.ModelChange) {
        return this.modelDescriptionStore.getDetailFirst(actionDescription.linkAction.model).pipe(
          map(modelDescription => {
            if (!modelDescription) {
              return [actionDescription.typeStr];
            }

            return [
              `${modelDescription.verboseNamePlural || modelDescription.model} - Change`,
              actionDescription.typeStr
            ];
          })
        );
      } else if (actionDescription.linkAction.type == SegueType.ModelMassEdit) {
        return this.modelDescriptionStore.getDetailFirst(actionDescription.linkAction.model).pipe(
          map(modelDescription => {
            if (!modelDescription) {
              return [actionDescription.typeStr];
            }

            return [
              `${modelDescription.verboseNamePlural || modelDescription.model} - Mass Edit`,
              actionDescription.typeStr
            ];
          })
        );
      } else if (actionDescription.linkAction.type == SegueType.ModelDelete) {
        return this.modelDescriptionStore.getDetailFirst(actionDescription.linkAction.model).pipe(
          map(modelDescription => {
            if (!modelDescription) {
              return [actionDescription.typeStr];
            }

            return [
              `${modelDescription.verboseNamePlural || modelDescription.model} - Delete`,
              actionDescription.typeStr
            ];
          })
        );
      } else if (actionDescription.linkAction.type == SegueType.ModelActivityLog) {
        return this.modelDescriptionStore.getDetailFirst(actionDescription.linkAction.model).pipe(
          map(modelDescription => {
            if (!modelDescription) {
              return [actionDescription.typeStr];
            }

            return [
              `${modelDescription.verboseNamePlural || modelDescription.model} - Activity Log`,
              actionDescription.typeStr
            ];
          })
        );
      }
    } else if (actionDescription.type == ActionType.ExternalLink) {
      return merge([
        ...(context.outputValues ? [of(context.outputValues)] : []),
        context ? context.outputValues$ : of({})
      ]).pipe(
        debounceTime(600),
        map(() => {
          const params = applyParamInputs({}, inputs, {
            context: context,
            contextElement: element,
            parameters: actionDescription.actionParams
          });

          if (!params['href']) {
            return [actionDescription.typeStr];
          }

          return [params['href'], actionDescription.typeStr];
        })
      );
    } else if (actionDescription.type == ActionType.ElementAction) {
      if (!actionDescription.elementAction || !context) {
        return of([actionDescription.typeStr]);
      }

      return context.elementActionPath$(actionDescription.elementAction).pipe(
        map(result => {
          return [
            [...result]
              .reverse()
              .map(item => item.name)
              .join(' · '),
            actionDescription.typeStr
          ];
        })
      );
    } else if (actionDescription.type == ActionType.ShowNotification) {
      if (!actionDescription.notificationAction) {
        return of([actionDescription.typeStr]);
      }

      if (actionDescription.notificationAction && actionDescription.notificationAction.title) {
        let title: string;

        try {
          title = applyParamInput(actionDescription.notificationAction.title, {
            context: context,
            contextElement: element,
            defaultValue: ''
          });
        } catch (e) {}

        return of([title, actionDescription.typeStr]);
      } else {
        return of([actionDescription.typeStr]);
      }
    } else if (actionDescription.type == ActionType.SetProperty) {
      if (!actionDescription.setPropertyAction || !isSet(!actionDescription.setPropertyAction.property)) {
        return of([actionDescription.typeStr]);
      }

      return this.projectPropertyStore.getDetail(actionDescription.setPropertyAction.property).pipe(
        map(property => {
          if (!property) {
            return [actionDescription.typeStr];
          }

          return [`${actionDescription.typeStr} "${property.name}"`];
        })
      );
    } else if (actionDescription.type == ActionType.RunJavaScript) {
      if (actionDescription.runJavaScriptAction && actionDescription.runJavaScriptAction.js) {
        const code = truncate(actionDescription.runJavaScriptAction.js, { length: 64 });
        return of([code, actionDescription.typeStr]);
      } else {
        return of([actionDescription.typeStr]);
      }
    } else if (actionDescription.type == ActionType.CopyToClipboard) {
      if (!actionDescription.copyToClipboardAction) {
        return of([actionDescription.typeStr]);
      }

      if (actionDescription.copyToClipboardAction && actionDescription.copyToClipboardAction.value) {
        let value: string;

        try {
          value = applyParamInput(actionDescription.copyToClipboardAction.value, {
            context: context,
            contextElement: element,
            defaultValue: ''
          });
        } catch (e) {}

        return of([value, actionDescription.typeStr]);
      } else {
        return of([actionDescription.typeStr]);
      }
    } else if (actionDescription.type == ActionType.Export) {
      if (!actionDescription.exportAction) {
        return of([actionDescription.typeStr]);
      }

      if (actionDescription.exportAction.dataType == ExportDataType.CurrentComponent) {
        return of([actionDescription.typeStr]);
      }

      const modelId = actionDescription.exportAction.getModelId();
      return this.modelDescriptionStore.getDetailFirst(modelId).pipe(
        map(modelDescription => {
          if (!modelDescription) {
            return [actionDescription.typeStr];
          }

          return [`${modelDescription.verboseNamePlural || modelDescription.model}`, actionDescription.typeStr];
        })
      );
    } else if (actionDescription.type == ActionType.Import) {
      if (!actionDescription.importAction) {
        return of([actionDescription.typeStr]);
      }

      const modelId = actionDescription.importAction.getModelId();
      return this.modelDescriptionStore.getDetailFirst(modelId).pipe(
        map(modelDescription => {
          if (!modelDescription) {
            return [actionDescription.typeStr];
          }

          return [`${modelDescription.verboseNamePlural || modelDescription.model}`, actionDescription.typeStr];
        })
      );
    } else if (actionDescription.type == ActionType.OpenPopup) {
      return of([actionDescription.typeStr]);
    } else if (actionDescription.type == ActionType.ClosePopup) {
      return of([actionDescription.typeStr]);
    } else if (actionDescription.type == ActionType.OpenActionMenu) {
      return of([actionDescription.typeStr]);
    } else if (actionDescription.type == ActionType.ScanCode) {
      return of([actionDescription.typeStr]);
    } else if (actionDescription.type == ActionType.Workflow) {
      if (!actionDescription.workflowAction || !actionDescription.workflowAction.workflow) {
        return of([actionDescription.typeStr]);
      }

      const steps = actionDescription.workflowAction.workflow.getStepsCount();
      return of([actionDescription.typeStr, steps == 1 ? `${steps} step` : `${steps} steps`]);
    }

    return of(undefined);
  }

  executeWorkflow(
    workflow: Workflow,
    params: Object = {},
    options: {
      context?: ViewContext;
      contextElement?: ViewContextElement;
      localContext?: Object;
      saveResultTo?: string;
      showSuccess?: boolean;
      showError?: boolean;
      bulk?: boolean;
      delayBefore?: number;
      disablePopups?: boolean;
      disableRouting?: boolean;
      confirmRouting?: boolean;
      replaceUrl?: boolean;
      injector?: Injector;
      analyticsSource?: string;
    } = {}
  ): Observable<WorkflowExecuteEvent> {
    return Observable.create(observer => {
      const workflowRun = new WorkflowRun();
      const localContext = {
        ...options.localContext,
        workflow: params,
        steps: {}
      };
      const event$ = new Subject<WorkflowExecuteEvent>();
      const eventsSubscription = event$.subscribe(
        result => {
          observer.next(result);

          if (result.type == WorkflowExecuteEventType.StepFinished) {
            const stepRun = new WorkflowStepRun();

            stepRun.uid = result.step.uid;
            stepRun.params = limitObjectLength(result.params, 20);
            stepRun.result = limitObjectLength(result.result, 20);
            stepRun.error = result.error;

            workflowRun.stepRuns.push(stepRun);
          } else if (result.type == WorkflowExecuteEventType.WorkflowFinished && workflow.result) {
            const stepRun = new WorkflowStepRun();

            stepRun.result = limitObjectLength(result.result, 20);
            stepRun.error = result.error;

            workflowRun.resultStepRun = stepRun;
          }
        },
        error => {
          observer.error(error);
        },
        () => observer.complete()
      );

      event$.next({
        type: WorkflowExecuteEventType.WorkflowStarted
      });

      const executionSubscription = this.executeWorkflowSteps(workflow.steps, event$, {
        ...options,
        localContext: localContext
      }).subscribe(
        result => {
          if (workflow.result && workflow.result.array) {
            result = applyParamInput(workflow.result.arrayInput, {
              context: options.context,
              contextElement: options.contextElement,
              localContext: localContext
            });
          } else if (workflow.result && !workflow.result.array) {
            result = applyParamInputs({}, workflow.result.inputs, {
              context: options.context,
              contextElement: options.contextElement,
              localContext: localContext,
              parameters: workflow.result.parameters
            });
          }

          event$.next({
            type: WorkflowExecuteEventType.WorkflowFinished,
            success: true,
            result: result,
            run: workflowRun
          });
          event$.complete();
        },
        error => {
          event$.next({
            type: WorkflowExecuteEventType.WorkflowFinished,
            success: false,
            error: error,
            run: workflowRun
          });
          event$.complete();
        }
      );

      return () => {
        executionSubscription.unsubscribe();
        eventsSubscription.unsubscribe();
      };
    }).pipe(share());
  }

  executeWorkflowSteps(
    steps: WorkflowStep[],
    event$: Subject<WorkflowExecuteEvent>,
    options: {
      context?: ViewContext;
      contextElement?: ViewContextElement;
      localContext?: Object;
      saveResultTo?: string;
      showSuccess?: boolean;
      showError?: boolean;
      bulk?: boolean;
      delayBefore?: number;
      disablePopups?: boolean;
      disableRouting?: boolean;
      confirmRouting?: boolean;
      replaceUrl?: boolean;
      injector?: Injector;
      analyticsSource?: string;
    } = {}
  ): Observable<any> {
    if (!steps.length) {
      return of(undefined);
    }

    const iterate = (index: number) => {
      const step = steps[index];

      event$.next({
        type: WorkflowExecuteEventType.StepStarted,
        step: step
      });

      return timer(options.delayBefore || 0).pipe(
        switchMap(() => this.executeWorkflowStep(step, event$, options)),
        catchError(error => {
          return of<WorkflowStepResult>({ error: error });
        }),
        switchMap(result => {
          if (result.error && result.error instanceof WorkflowExecuteStepError) {
            return throwError(result.error);
          } else if (result.error) {
            event$.next({
              type: WorkflowExecuteEventType.StepFinished,
              step: step,
              success: false,
              params: result.params,
              error: result.error
            });

            return throwError(new WorkflowExecuteStepError(step, result.error));
          }

          if (options.localContext) {
            options.localContext['steps'][step.uid] = {
              ...options.localContext['steps'][step.uid],
              [SUBMIT_RESULT_OUTPUT]: result.result
            };
          }

          event$.next({
            type: WorkflowExecuteEventType.StepFinished,
            step: step,
            success: true,
            params: result.params,
            result: result.result
          });

          const nextIndex = index + 1;
          const nextStep = steps[nextIndex];

          if (nextStep) {
            return iterate(nextIndex);
          } else {
            return of(result.result);
          }
        })
      );
    };

    return iterate(0);
  }

  executeWorkflowStep(
    step: WorkflowStep,
    event$: Subject<WorkflowExecuteEvent>,
    options: {
      context?: ViewContext;
      contextElement?: ViewContextElement;
      localContext?: Object;
      saveResultTo?: string;
      showSuccess?: boolean;
      showError?: boolean;
      bulk?: boolean;
      disablePopups?: boolean;
      disableRouting?: boolean;
      confirmRouting?: boolean;
      replaceUrl?: boolean;
      injector?: Injector;
      analyticsSource?: string;
    } = {}
  ): Observable<WorkflowStepResult> {
    if (step instanceof ActionWorkflowStep) {
      if (!step.action) {
        return throwError(new ServerRequestError('No step action specified'));
      }

      return this.getActionDescription(step.action).pipe(
        switchMap<ActionDescription, WorkflowStepResult>(actionDescription => {
          if (!actionDescription) {
            throw new ServerRequestError('Action not found specified');
          }

          const inputs = [...step.action.inputs];

          if (
            actionDescription.type == ActionType.Export &&
            actionDescription.exportAction &&
            actionDescription.exportAction.dataSource
          ) {
            inputs.push(...actionDescription.exportAction.dataSource.queryInputs);
          }

          const params = applyParamInputs({}, inputs, {
            context: options.context,
            contextElement: options.contextElement,
            localContext: options.localContext,
            parameters: actionDescription.actionParams
          });

          return this.executeActionDescription(actionDescription, params, options).pipe(
            mapWithError((result, error) => {
              return {
                params: params,
                result: result,
                error: error
              };
            }),
            switchMap(parentResult => {
              if (step.resultSteps && parentResult.result && step.successSteps.length) {
                options.localContext['steps'][step.uid] = {
                  ...options.localContext['steps'][step.uid],
                  [SUBMIT_RESULT_OUTPUT]: parentResult.result
                };

                return this.executeWorkflowSteps(step.successSteps, event$, options).pipe(
                  mapWithError((result, error) => {
                    return {
                      params: params,
                      result: result,
                      error: error
                    };
                  })
                );
              } else if (step.resultSteps && parentResult.error) {
                const outputs = {};

                if (parentResult.error instanceof ServerRequestError && parentResult.error.errors.length) {
                  outputs[SUBMIT_ERROR_OUTPUT] = parentResult.error.errors[0];
                } else {
                  outputs[SUBMIT_ERROR_OUTPUT] = String(parentResult.error);
                }

                if (
                  parentResult.error instanceof ServerRequestError &&
                  parentResult.error.response instanceof HttpErrorResponse
                ) {
                  outputs[HTTP_BODY_OUTPUT] = parentResult.error.response.error;
                  outputs[HTTP_CODE_OUTPUT] = parentResult.error.response.status;
                  outputs[HTTP_STATUS_OUTPUT] = parentResult.error.response.statusText;
                }

                options.localContext['steps'][step.uid] = {
                  ...options.localContext['steps'][step.uid],
                  ...outputs
                };

                return this.executeWorkflowSteps(step.errorSteps, event$, options).pipe(
                  mapWithError((result, error) => {
                    return {
                      params: params,
                      result: result,
                      error: error
                    };
                  })
                );
              } else {
                return of(parentResult);
              }
            })
          );
        })
      );
    } else if (step instanceof ConditionWorkflowStep) {
      if (step.conditionType == ConditionWorkflowStepType.Boolean) {
        if (!step.items.length) {
          return throwError(new ServerRequestError('No conditions found'));
        }

        const conditionIsTrue = applyParamInput(step.items[0].condition, {
          context: options.context,
          contextElement: options.contextElement,
          localContext: options.localContext,
          defaultValue: false
        });
        const params = {
          conditions: [conditionIsTrue]
        };

        if (conditionIsTrue) {
          const steps = step.items[0].steps;
          return this.executeWorkflowSteps(steps, event$, options).pipe(
            mapWithError((result, error) => {
              return {
                params: params,
                result: result,
                error: error
              };
            })
          );
        } else {
          const steps = step.items[1] ? step.items[1].steps : [];
          return this.executeWorkflowSteps(steps, event$, options).pipe(
            mapWithError((result, error) => {
              return {
                params: params,
                result: result,
                error: error
              };
            })
          );
        }
      } else if (step.conditionType == ConditionWorkflowStepType.Switch) {
        const params = {
          conditions: step.items.map(() => false)
        };

        for (let i = 0; i < step.items.length; ++i) {
          const item = step.items[i];
          const conditionIsTrue = applyParamInput(item.condition, {
            context: options.context,
            contextElement: options.contextElement,
            localContext: options.localContext,
            defaultValue: false
          });

          params.conditions[i] = conditionIsTrue;

          if (conditionIsTrue) {
            return this.executeWorkflowSteps(item.steps, event$, options).pipe(
              mapWithError((result, error) => {
                return {
                  params: params,
                  result: result,
                  error: error
                };
              })
            );
          }
        }

        return this.executeWorkflowSteps(step.elseSteps, event$, options).pipe(
          mapWithError((result, error) => {
            return {
              params: params,
              result: result,
              error: error
            };
          })
        );
      } else {
        return throwError(new ServerRequestError('Unknown condition type'));
      }
    } else if (step instanceof IteratorWorkflowStep) {
      if (!step.dataSource) {
        return of({
          result: []
        });
      }

      const dataSourceParams = applyParamInputs({}, step.dataSource.queryInputs, {
        context: options.context,
        contextElement: options.contextElement,
        localContext: options.localContext,
        parameters: step.dataSource.queryParameters,
        handleLoading: true,
        ignoreEmpty: true,
        emptyValues: EMPTY_FILTER_VALUES
      });

      let staticData =
        step.dataSource.type == DataSourceType.Input && step.dataSource.input
          ? applyParamInput(step.dataSource.input, {
              context: options.context,
              contextElement: options.contextElement,
              localContext: options.localContext,
              defaultValue: [],
              handleLoading: true,
              ignoreEmpty: true
            })
          : [];

      if (isSet(staticData)) {
        staticData = coerceArray(staticData).map(item => {
          if (isArray(item) || isPlainObject(item)) {
            return item;
          } else {
            return {
              [NO_KEY_ATTRIBUTE]: item
            };
          }
        });
      } else {
        staticData = [];
      }

      const stepResultParams = {
        params: dataSourceParams,
        iterate: []
      };

      const itemsIteration = (items: Object[], index: number, iterationAcc: any[] = []): Observable<any[]> => {
        if (index + 1 > items.length) {
          return of(iterationAcc);
        }

        const item = items[index];
        return this.executeWorkflowSteps(step.steps, event$, {
          ...options,
          localContext: {
            ...options.localContext,
            steps: {
              ...(options.localContext && options.localContext['steps']),
              [step.uid]: {
                ...(options.localContext && options.localContext['steps'] && options.localContext['steps'][step.uid]),
                [ITEM_OUTPUT]: item
              }
            }
          }
        }).pipe(
          switchMap(result => {
            const nextIndex = index + 1;
            const nextItem = items[nextIndex];

            iterationAcc.push(result);

            if (nextItem) {
              return itemsIteration(items, nextIndex, iterationAcc);
            } else {
              return of(iterationAcc);
            }
          })
        );
      };

      const getIteration = (paging: GetQueryOptionsPaging, resultAcc = []) => {
        let queryOptions = paramsToGetQueryOptions(dataSourceParams);

        queryOptions.paging = paging;
        queryOptions.columns = step.dataSource.columns;

        if (isSet(step.sortingField)) {
          queryOptions.sort = [{ field: step.sortingField, desc: !step.sortingAsc }];
        }

        queryOptions = applyQueryOptionsFilterInputs(step.dataSource, queryOptions);

        return this.getDataSourceDataAdv({
          project: this.currentProjectStore.instance,
          environment: this.currentEnvironmentStore.instance,
          dataSource: step.dataSource,
          queryOptions: queryOptions,
          staticData: staticData,
          context: options.context,
          contextElement: options.contextElement
        }).pipe(
          switchMap(response => {
            const iterate = response.results.map(item => item.serialize());

            stepResultParams.iterate.push(...iterate);

            return itemsIteration(iterate, 0).pipe(
              switchMap(iterationResult => {
                resultAcc.push(...iterationResult);

                if (response.hasMore) {
                  const newPaging: GetQueryOptionsPaging = {
                    ...paging,
                    ...(isSet(paging.page) && { page: paging.page + 1 }),
                    cursorNext: response.cursorNext,
                    cursorPrev: response.cursorPrev
                  };

                  return getIteration(newPaging, resultAcc);
                } else {
                  return of(resultAcc);
                }
              })
            );
          })
        );
      };

      return getIteration({ page: 1 }).pipe(
        mapWithError((result, error) => {
          return {
            params: stepResultParams,
            result: result,
            error: error
          };
        })
      );
    } else if (step instanceof ForkWorkflowStep) {
      if (!step.items.length) {
        return of({
          result: []
        });
      }

      return combineLatest(
        step.items.map(item => {
          return this.executeWorkflowSteps(item.steps, event$, options);
        })
      ).pipe(
        first(),
        mapWithError((result, error) => {
          return {
            result: result,
            error: error
          };
        })
      );
    } else if (step instanceof DelayWorkflowStep) {
      if (!step.delay || step.delay < 1 || step.delay > 300) {
        return throwError(new ServerRequestError('Delay should be between 1 and 300 seconds'));
      }

      return timer(step.delay * 1000).pipe(
        mapWithError((result, error) => {
          return {
            result: result,
            error: error
          };
        })
      );
    } else if (step instanceof TransformWorkflowStep) {
      if (!step.value || !step.value.isSet()) {
        return throwError(new ServerRequestError('Transformation is not set'));
      }

      const value = applyParamInput(step.value, {
        context: options.context,
        contextElement: options.contextElement,
        localContext: options.localContext,
        defaultValue: {}
      });

      return of(value).pipe(
        mapWithError((result, error) => {
          return {
            result: result,
            error: error
          };
        })
      );
    } else if (step instanceof ExitWorkflowStep) {
      const errorText = step.errorText
        ? applyParamInput(step.errorText, {
            context: options.context,
            contextElement: options.contextElement,
            localContext: options.localContext,
            defaultValue: ''
          })
        : '';
      const error = new WorkflowExecuteStepError(step, errorText, !isSet(errorText));

      event$.next({
        type: WorkflowExecuteEventType.StepFinished,
        step: step,
        success: false,
        error: error
      });

      return throwError(error);
    } else {
      return throwError(new ServerRequestError('Unknown step type'));
    }
  }

  getDataSourceDataAdv(options: {
    project: Project;
    environment: Environment;
    dataSource: ListModelDescriptionDataSource;
    staticData?: Object[];
    queryOptions?: GetQueryOptions;
    context?: ViewContext;
    contextElement?: ViewContextElement;
    localContext?: Object;
  }): Observable<ModelResponse.GetResponse> {
    const params = getQueryOptionsToParams(options.queryOptions);

    if (options.dataSource.type == DataSourceType.Query) {
      const resource = options.project
        .getEnvironmentResources(options.environment.uniqueName)
        .find(item => item.uniqueName == options.dataSource.queryResource);

      return this.modelService.getQueryAdv(
        options.project,
        options.environment,
        resource,
        options.dataSource.query,
        options.dataSource.queryParameters,
        options.queryOptions,
        (options.dataSource.columns || []).filter(item => item.type != DisplayFieldType.Computed)
      );
    } else if (options.dataSource.type == DataSourceType.Input) {
      let result: Object[] = isArray(options.staticData) ? options.staticData : [options.staticData];

      result = applyFrontendFiltering(result, params, options.dataSource.columns);

      const data = {
        results: result,
        count: result.length
      };
      const response = this.createGetResponse().deserialize(data, undefined, undefined);

      // Backward compatibility
      if (!options.dataSource.columns.length) {
        const columns = this.queryService.autoDetectGetFields(result);
        if (columns) {
          options.dataSource.columns = columns.map(item => rawListViewSettingsColumnsToDisplayField(item));
        }
      }

      response.results.forEach(item => {
        item.deserializeAttributes(options.dataSource.columns);
      });

      applyFrontendSorting(response, params);
      applyFrontendPagination(response, params, true);

      return of(response);
    } else {
      return of(undefined);
    }
  }

  getStepsInfo(
    steps: WorkflowStep[],
    options: {
      context?: ViewContext;
      contextElement?: ViewContextElement;
    } = {}
  ): Observable<WorkflowStepInfo[]> {
    if (!steps.length) {
      return of([]);
    }

    return combineLatest(...steps.map(item => this.getStepInfo(item)));
  }

  getStepInfo(
    step: WorkflowStep,
    options: {
      context?: ViewContext;
      contextElement?: ViewContextElement;
    } = {}
  ): Observable<WorkflowStepInfo> {
    if (step instanceof ActionWorkflowStep) {
      return this.getActionDescription(step.action).pipe(
        switchMap(actionDescription => {
          return this.getActionDescriptionLabel(
            actionDescription,
            step.action ? step.action.inputs : [],
            options.context,
            options.contextElement
          ).pipe(
            map<string[], [ActionDescription, string[]]>(labels => [actionDescription, labels])
          );
        }),
        map(([actionDescription, labels]) => {
          const resource = actionDescription
            ? this.currentEnvironmentStore.resources.find(i => i.uniqueName == actionDescription.resource)
            : undefined;
          let image: string;
          let icon: string;

          if (resource) {
            image = resource.icon;
          } else {
            icon = step.getIcon();
          }

          return {
            icon: icon,
            image: image,
            labels: labels
          };
        })
      );
    } else if (step instanceof ConditionWorkflowStep) {
      const labels = [];

      if (step.conditionType == ConditionWorkflowStepType.Boolean) {
        labels.push('Yes/No');
      } else if (step.conditionType == ConditionWorkflowStepType.Switch) {
        labels.push('Switch');
      }

      return of({
        icon: step.getIcon(),
        labels: labels
      });
    } else if (step instanceof IteratorWorkflowStep) {
      return of({
        icon: step.getIcon(),
        labels: ['Iterator']
      });
    } else if (step instanceof ForkWorkflowStep) {
      const labels = [];

      if (step.name) {
        labels.push(step.name);
      }

      return of({
        icon: step.getIcon(),
        labels: labels
      });
    } else if (step instanceof DelayWorkflowStep) {
      const labels = [];
      const seconds = step.delay;
      const minutes = seconds / 60;
      const hours = minutes / 60;

      if (hours >= 1) {
        labels.push(`${round(hours, 1)} ${hours == 1 ? 'hour' : 'hours'}`);
      } else if (minutes >= 1) {
        labels.push(`${round(minutes, 1)} ${minutes == 1 ? 'minute' : 'minutes'}`);
      } else {
        labels.push(`${round(seconds, 1)} ${seconds == 1 ? 'second' : 'seconds'}`);
      }

      if (step.name) {
        labels.push(step.name);
      }

      return of({
        icon: step.getIcon(),
        labels: labels
      });
    } else if (step instanceof TransformWorkflowStep) {
      return of({
        icon: step.getIcon(),
        labels: ['Transform']
      });
    } else {
      return of({});
    }
  }

  processResponse(response: ActionResponse) {
    if (!response) {
      return;
    }

    if (response.json) {
      const body = response.json as Object;

      if (body['message']) {
        this.notificationService.success('Action Executed', body['message']);
      }
    } else if (response.blob) {
      const contentDisposition = response.response.headers.get('Content-Disposition') || '';
      const matches = /filename=(?:(?:"([^;]+)")|([^;]+))/gi.exec(contentDisposition);
      const defaultFilename = 'action_result';
      const fileName = ((matches ? matches[1] || matches[2] : undefined) || defaultFilename).trim();

      saveAs(response.blob, fileName);
    }
  }

  runOnConfirm(handler: (...arg: any) => any, confirm?: DialogOptions): Observable<any> {
    if (confirm) {
      return this.dialogService.confirm(confirm).pipe(
        map(confirmed => {
          if (confirmed) {
            return handler();
          } else {
            return;
          }
        })
      );
    } else {
      return of(handler());
    }
  }

  runOnRedirectConfirm(url: string, handler: (...arg: any) => any, confirm: boolean): Observable<any> {
    const dialogOptions: DialogOptions = confirm
      ? {
          title: 'Redirect on "page opens" action',
          description: `
            You should be redirected to the following URL:<br>
            <strong>${url}</strong><br>
            <small>(This message is displayed in Builder mode only)</small>
          `,
          style: 'orange',
          buttons: [
            {
              name: 'cancel',
              label: 'Cancel',
              type: DialogButtonType.Default,
              hotkey: DialogButtonHotkey.Cancel
            },
            {
              name: 'ok',
              label: 'Redirect',
              type: DialogButtonType.Submit,
              hotkey: DialogButtonHotkey.Submit
            }
          ]
        }
      : undefined;

    return this.runOnConfirm(handler, dialogOptions);
  }

  get modelUpdated$(): Observable<ModelUpdatedEvent> {
    return this._modelUpdated$.asObservable();
  }
}
