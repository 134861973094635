import pickBy from 'lodash/pickBy';

import { BorderSettings } from '../../border-settings';
import { Corners } from '../../corners';
import { Shadow } from '../../shadow';
import { getElementByType, registerElementForType } from '../element-items';
import { ElementType } from '../element-type';
import { ElementItem } from './base';

export class CardLayoutElementItem extends ElementItem {
  public type = ElementType.Card;
  public children: ElementItem[] = [];
  public loadInvisible = false;
  public borderSettings: BorderSettings;
  public borderRadius: Corners;
  public shadow: Shadow;

  deserialize(data: Object): CardLayoutElementItem {
    super.deserialize(data);

    if (this.params['children']) {
      this.children = this.params['children']
        .map(item => {
          const element = getElementByType(item['type']);
          if (!element) {
            console.error(`Unsupported element type: ${item['type']}`);
            return;
          }
          return new element().deserialize(item);
        })
        .filter(item => item != undefined);
    }

    if (this.params['load_invisible'] != undefined) {
      this.loadInvisible = this.params['load_invisible'];
    }

    if (this.params['border_settings']) {
      this.borderSettings = new BorderSettings().deserialize(this.params['border_settings']);
    } else {
      this.borderSettings = undefined;
    }

    if (this.params['border_radius']) {
      this.borderRadius = this.params['border_radius'];
    } else {
      this.borderRadius = undefined;
    }

    if (this.params['shadow']) {
      this.shadow = new Shadow().deserialize(this.params['shadow']);
    } else {
      this.shadow = undefined;
    }

    return this;
  }

  serialize(fields?: string[]): Object {
    this.params = {
      children: this.children.map(item => item.serialize()),
      load_invisible: this.loadInvisible,
      border_settings: this.borderSettings ? this.borderSettings.serialize() : undefined,
      border_radius: this.borderRadius,
      shadow: this.shadow ? this.shadow.serialize() : undefined
    };

    let data = super.serialize();
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }

  get analyticsName(): string {
    return 'card';
  }

  defaultName() {
    return 'Card';
  }

  childrenCount() {
    return this.children.length;
  }
}

registerElementForType(ElementType.Card, CardLayoutElementItem);
