import { ModelDescription } from '@modules/models';
import { Resource, ResourceType } from '@modules/projects';
import { ListModelDescriptionQuery, ModelDescriptionQuery, QueryType } from '@modules/queries';

export function modelDescriptionHasAutoParameters(resource: Resource, modelDescription: ModelDescription): boolean {
  if (!resource || !modelDescription) {
    return false;
  }

  return (
    [ResourceType.JetApp, ResourceType.JetBridge, ResourceType.Airtable, ResourceType.Xano].includes(resource.type) ||
    resource.isSynced(modelDescription.model) ||
    modelDescription.isSynced()
  );
}

export function queryHasResourceAutoParameters(
  resource: Resource,
  query: ModelDescriptionQuery,
  modelDescription: ModelDescription
): boolean {
  if (resource && modelDescription && (resource.isSynced(modelDescription.model) || modelDescription.isSynced())) {
    return true;
  }

  return (
    modelDescriptionHasAutoParameters(resource, modelDescription) &&
    query &&
    query.queryType == QueryType.Simple &&
    !!modelDescription &&
    !modelDescription.virtual
  );
}

export function queryHasFrontendAutoParameters(
  resource: Resource,
  query: ModelDescriptionQuery,
  modelDescription: ModelDescription
): boolean {
  if (resource && resource.type == ResourceType.JetBridge) {
    return true;
  }

  if (!query) {
    return false;
  }

  if (!query.simpleQuery && query instanceof ListModelDescriptionQuery && query.frontendFiltering) {
    return true;
  }

  if (
    query.simpleQuery &&
    modelDescription &&
    modelDescription.getQuery &&
    modelDescription.getQuery.frontendFiltering
  ) {
    return true;
  }

  return false;
}

export function queryHasAutoParameters(
  resource: Resource,
  query: ModelDescriptionQuery,
  modelDescription: ModelDescription
): boolean {
  return (
    queryHasResourceAutoParameters(resource, query, modelDescription) ||
    queryHasFrontendAutoParameters(resource, query, modelDescription)
  );
}
