/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../parameters-components/components/field-inputs-edit/field-inputs-edit.component.ngfactory";
import * as i3 from "../../../parameters-components/components/field-inputs-edit/field-inputs-edit.component";
import * as i4 from "../../../analytics/services/universal-analytics/universal-analytics.service";
import * as i5 from "./data-source-inputs-edit.component";
var styles_DataSourceInputsEditComponent = [];
var RenderType_DataSourceInputsEditComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DataSourceInputsEditComponent, data: {} });
export { RenderType_DataSourceInputsEditComponent as RenderType_DataSourceInputsEditComponent };
export function View_DataSourceInputsEditComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(402653184, 1, { inputsEditComponent: 0 }), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [["class", "sidebar__list"]], null, null, null, null, null)), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 1, "app-field-inputs-edit", [], null, [[null, "addInput"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("addInput" === en)) {
        var pd_0 = (_co.addInput.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_FieldInputsEditComponent_0, i2.RenderType_FieldInputsEditComponent)), i0.ɵdid(4, 245760, [[1, 4]], 0, i3.FieldInputsEditComponent, [i4.UniversalAnalyticsService, i0.ChangeDetectorRef], { control: [0, "control"], dataSource: [1, "dataSource"], parameterProvider: [2, "parameterProvider"], fieldsControl: [3, "fieldsControl"], addInputEnabled: [4, "addInputEnabled"], nestedFieldsEnabled: [5, "nestedFieldsEnabled"], relationsEnabled: [6, "relationsEnabled"], context: [7, "context"], contextElement: [8, "contextElement"], contextElementPath: [9, "contextElementPath"], contextElementPaths: [10, "contextElementPaths"], userInput: [11, "userInput"], classes: [12, "classes"], addClasses: [13, "addClasses"], analyticsSource: [14, "analyticsSource"] }, { addInput: "addInput" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "sidebar__list"; var currVal_1 = _co.classes; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.control.controls.query_inputs; var currVal_3 = _co.dataSource; var currVal_4 = _co.control.inputFieldProvider; var currVal_5 = _co.fieldsControl; var currVal_6 = _co.editable; var currVal_7 = _co.nestedFieldsEnabled; var currVal_8 = _co.relationsEnabled; var currVal_9 = _co.context; var currVal_10 = _co.contextElement; var currVal_11 = _co.contextElementPath; var currVal_12 = _co.contextElementPaths; var currVal_13 = _co.userInput; var currVal_14 = _co.itemClasses; var currVal_15 = _co.addClasses; var currVal_16 = _co.analyticsSource; _ck(_v, 4, 1, [currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15, currVal_16]); }, null); }
export function View_DataSourceInputsEditComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-data-source-inputs-edit", [], null, null, null, View_DataSourceInputsEditComponent_0, RenderType_DataSourceInputsEditComponent)), i0.ɵdid(1, 245760, null, 0, i5.DataSourceInputsEditComponent, [i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DataSourceInputsEditComponentNgFactory = i0.ɵccf("app-data-source-inputs-edit", i5.DataSourceInputsEditComponent, View_DataSourceInputsEditComponent_Host_0, { control: "control", context: "context", nestedFieldsEnabled: "nestedFieldsEnabled", relationsEnabled: "relationsEnabled", fieldsControl: "fieldsControl", editable: "editable", contextElement: "contextElement", contextElementPath: "contextElementPath", contextElementPaths: "contextElementPaths", userInput: "userInput", classes: "classes", itemClasses: "itemClasses", addClasses: "addClasses", analyticsSource: "analyticsSource" }, { addInput: "addInput" }, []);
export { DataSourceInputsEditComponentNgFactory as DataSourceInputsEditComponentNgFactory };
