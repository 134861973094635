import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ColumnsModelListStore } from '@modules/list';

@Component({
  selector: 'app-list-store-footer',
  templateUrl: './list-store-footer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListStoreFooterComponent implements OnInit {
  @Input() title: string;
  @Input() pagination = true;
  @Input() listStore: ColumnsModelListStore;
  @Input() viewId: string;
  @Input() accentColor: string;
  @Input() theme = false;
  @Output() pageSelected = new EventEmitter<number>();
  @Output() reload = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {}
}
