import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-kanban-board-stub',
  templateUrl: './kanban-board-stub.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KanbanBoardStubComponent implements OnInit {
  @Input() animating = false;
  @Input() theme = false;

  constructor() {}

  ngOnInit() {}
}
