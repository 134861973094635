import { localize } from '@common/localize';
import { ActionItem, ViewSettingsAction } from '@modules/actions';
import {
  EditableFlexField,
  FieldDescription,
  FormField,
  getFieldDescriptionByType,
  Input,
  parseFieldType
} from '@modules/fields';
import { ModelDbField, ModelField, modelFieldToField, ModelFieldType } from '@modules/models';

import { AlignHorizontal } from '../../align-horizontal';
import { BorderSettings } from '../../border-settings';
import { Corners } from '../../corners';
import { FillSettings } from '../../fill-settings';
import { Shadow } from '../../shadow';
import { TextStyle } from '../../text-style';
import { registerElementForType } from '../element-items';
import { ElementType } from '../element-type';
import { ElementItem, Margin } from './base';
import { ResizableElementItem } from './widget';

export const FIELD_ELEMENT_VERSION = 2;

export enum ElementActionsPosition {
  Top = 'top',
  Bottom = 'bottom'
}

export class ElementActions {
  public position: ElementActionsPosition;
  public actions: ViewSettingsAction[] = [];

  constructor(options: Partial<ElementActions> = {}) {
    this.position = options.position;
    this.actions = options.actions || [];
  }

  deserialize(data: Object): ElementActions {
    this.position = data['position'];

    if (data['actions']) {
      this.actions = data['actions'].map(item => new ViewSettingsAction().deserialize(item));
    }

    return this;
  }

  serialize(): Object {
    return {
      position: this.position,
      actions: this.actions.map(item => item.serialize())
    };
  }
}

export class FieldElementItem extends ElementItem implements ResizableElementItem {
  public type = ElementType.Field;
  public alignHorizontalDefault = AlignHorizontal.Left;
  public width: number;
  public height: number;

  public settings: EditableFlexField;
  public relatedModelFromField: string;
  public labelAdditional: string;

  public textStyle: TextStyle;
  public placeholderStyle: TextStyle;
  public labelStyle: TextStyle;
  public labelAdditionalStyle: TextStyle;
  public fillSettings: FillSettings;
  public borderSettings: BorderSettings;
  public borderRadius: Corners;
  public shadow: Shadow;
  public padding: Margin;

  public hoverTextStyle: TextStyle;
  public hoverPlaceholderStyle: TextStyle;
  public hoverFillSettings: FillSettings;
  public hoverBorderSettings: BorderSettings;
  public hoverShadow: Shadow;

  public focusTextStyle: TextStyle;
  public focusPlaceholderStyle: TextStyle;
  public focusFillSettings: FillSettings;
  public focusBorderSettings: BorderSettings;
  public focusShadow: Shadow;

  public errorTextStyle: TextStyle;
  public errorPlaceholderStyle: TextStyle;
  public errorFillSettings: FillSettings;
  public errorBorderSettings: BorderSettings;
  public errorShadow: Shadow;

  public disableInput: Input;
  public tooltip: string;
  public elementActions: ElementActions[] = [];
  public onChangeActions: ActionItem[] = [];
  public version = 1;
  private _formField: FormField;
  private _fieldDescription: FieldDescription;

  deserialize(data: Object): FieldElementItem {
    super.deserialize(data);
    this.version = this.params['v'] || 1;
    this.height = this.params['height'];
    this.tooltip = this.params['tooltip'];

    if (this.version >= 2) {
      this.width = this.params['width'];
    } else {
      // Backward compatibility
      this.width = 220;
      this.version = FIELD_ELEMENT_VERSION;
    }

    if (this.params['settings']) {
      this.settings = this.params['settings'];
      this.settings.field = parseFieldType(this.settings.field);
      this.settings.valueInput = this.params['settings']['valueInput']
        ? new Input().deserialize(this.params['settings']['valueInput'])
        : undefined;
      this._formField = undefined;
    }

    if (this.params['field']) {
      let modelField: ModelField;

      if (this.params['field']['type'] != undefined) {
        modelField = new ModelField().deserialize(this.params['field']);
      } else {
        modelField = new ModelField();
        modelField.type = ModelFieldType.Db;
        modelField.item = new ModelDbField().deserialize(this.params['field']);
        modelField.name = modelField.item.name;
      }

      this.settings = modelFieldToField(modelField);
      this._formField = undefined;
    }

    this.relatedModelFromField = this.params['related_model_from_field'];

    if (this.params['label_additional'] === undefined && this.settings && !this.settings.required) {
      // Backward compatibility
      this.labelAdditional = `(${localize('optional')})`;
    } else {
      this.labelAdditional = this.params['label_additional'];
    }

    if (this.params['text_style']) {
      this.textStyle = new TextStyle().deserialize(this.params['text_style']);
    } else {
      this.textStyle = undefined;
    }

    if (this.params['placeholder_style']) {
      this.placeholderStyle = new TextStyle().deserialize(this.params['placeholder_style']);
    } else {
      this.placeholderStyle = undefined;
    }

    if (this.params['label_style']) {
      this.labelStyle = new TextStyle().deserialize(this.params['label_style']);
    } else {
      this.labelStyle = undefined;
    }

    if (this.params['label_additional_style']) {
      this.labelAdditionalStyle = new TextStyle().deserialize(this.params['label_additional_style']);
    } else {
      this.labelAdditionalStyle = undefined;
    }

    if (this.params['fill_settings']) {
      this.fillSettings = new FillSettings().deserialize(this.params['fill_settings']);
    } else {
      this.fillSettings = undefined;
    }

    if (this.params['border_settings']) {
      this.borderSettings = new BorderSettings().deserialize(this.params['border_settings']);
    } else {
      this.borderSettings = undefined;
    }

    if (this.params['border_radius']) {
      this.borderRadius = this.params['border_radius'];
    } else {
      this.borderRadius = undefined;
    }

    if (this.params['shadow']) {
      this.shadow = new Shadow().deserialize(this.params['shadow']);
    } else {
      this.shadow = undefined;
    }

    if (this.params['padding']) {
      this.padding = this.params['padding'];
    } else {
      this.padding = undefined;
    }

    if (this.params['hover_text_style']) {
      this.hoverTextStyle = new TextStyle().deserialize(this.params['hover_text_style']);
    } else {
      this.hoverTextStyle = undefined;
    }

    if (this.params['hover_placeholder_style']) {
      this.hoverPlaceholderStyle = new TextStyle().deserialize(this.params['hover_placeholder_style']);
    } else {
      this.hoverPlaceholderStyle = undefined;
    }

    if (this.params['hover_fill_settings']) {
      this.hoverFillSettings = new FillSettings().deserialize(this.params['hover_fill_settings']);
    } else {
      this.hoverFillSettings = undefined;
    }

    if (this.params['hover_border_settings']) {
      this.hoverBorderSettings = new BorderSettings().deserialize(this.params['hover_border_settings']);
    } else {
      this.hoverBorderSettings = undefined;
    }

    if (this.params['hover_shadow']) {
      this.hoverShadow = new Shadow().deserialize(this.params['hover_shadow']);
    } else {
      this.hoverShadow = undefined;
    }

    if (this.params['focus_text_style']) {
      this.focusTextStyle = new TextStyle().deserialize(this.params['focus_text_style']);
    } else {
      this.focusTextStyle = undefined;
    }

    if (this.params['focus_placeholder_style']) {
      this.focusPlaceholderStyle = new TextStyle().deserialize(this.params['focus_placeholder_style']);
    } else {
      this.focusPlaceholderStyle = undefined;
    }

    if (this.params['focus_fill_settings']) {
      this.focusFillSettings = new FillSettings().deserialize(this.params['focus_fill_settings']);
    } else {
      this.focusFillSettings = undefined;
    }

    if (this.params['focus_border_settings']) {
      this.focusBorderSettings = new BorderSettings().deserialize(this.params['focus_border_settings']);
    } else {
      this.focusBorderSettings = undefined;
    }

    if (this.params['focus_shadow']) {
      this.focusShadow = new Shadow().deserialize(this.params['focus_shadow']);
    } else {
      this.focusShadow = undefined;
    }

    if (this.params['error_text_style']) {
      this.errorTextStyle = new TextStyle().deserialize(this.params['error_text_style']);
    } else {
      this.errorTextStyle = undefined;
    }

    if (this.params['error_placeholder_style']) {
      this.errorPlaceholderStyle = new TextStyle().deserialize(this.params['error_placeholder_style']);
    } else {
      this.errorPlaceholderStyle = undefined;
    }

    if (this.params['error_fill_settings']) {
      this.errorFillSettings = new FillSettings().deserialize(this.params['error_fill_settings']);
    } else {
      this.errorFillSettings = undefined;
    }

    if (this.params['error_border_settings']) {
      this.errorBorderSettings = new BorderSettings().deserialize(this.params['error_border_settings']);
    } else {
      this.errorBorderSettings = undefined;
    }

    if (this.params['error_shadow']) {
      this.errorShadow = new Shadow().deserialize(this.params['error_shadow']);
    } else {
      this.errorShadow = undefined;
    }

    if (this.params['disable_input']) {
      this.disableInput = new Input().deserialize(this.params['disable_input']);
    }

    if (this.params['element_actions']) {
      this.elementActions = this.params['element_actions'].map(item => new ElementActions().deserialize(item));
    }

    if (this.params['on_change_actions']) {
      this.onChangeActions = this.params['on_change_actions'].map(item => new ActionItem().deserialize(item));
    }

    return this;
  }

  serialize(): Object {
    this.params = {
      settings: {
        ...this.settings,
        valueInput: this.settings.valueInput ? this.settings.valueInput.serialize() : undefined
      },
      // field: this.modelField ? this.modelField.serialize() : undefined,
      related_model_from_field: this.relatedModelFromField,
      width: this.width,
      height: this.height,
      label_additional: this.labelAdditional,

      text_style: this.textStyle ? this.textStyle.serialize() : undefined,
      placeholder_style: this.placeholderStyle ? this.placeholderStyle.serialize() : undefined,
      label_style: this.labelStyle ? this.labelStyle.serialize() : undefined,
      label_additional_style: this.labelAdditionalStyle ? this.labelAdditionalStyle.serialize() : undefined,
      fill_settings: this.fillSettings ? this.fillSettings.serialize() : undefined,
      border_settings: this.borderSettings ? this.borderSettings.serialize() : undefined,
      border_radius: this.borderRadius,
      shadow: this.shadow ? this.shadow.serialize() : undefined,
      padding: this.padding,

      hover_text_style: this.hoverTextStyle ? this.hoverTextStyle.serialize() : undefined,
      hover_placeholder_style: this.hoverPlaceholderStyle ? this.hoverPlaceholderStyle.serialize() : undefined,
      hover_fill_settings: this.hoverFillSettings ? this.hoverFillSettings.serialize() : undefined,
      hover_border_settings: this.hoverBorderSettings ? this.hoverBorderSettings.serialize() : undefined,
      hover_shadow: this.hoverShadow ? this.hoverShadow.serialize() : undefined,

      focus_text_style: this.focusTextStyle ? this.focusTextStyle.serialize() : undefined,
      focus_placeholder_style: this.focusPlaceholderStyle ? this.focusPlaceholderStyle.serialize() : undefined,
      focus_fill_settings: this.focusFillSettings ? this.focusFillSettings.serialize() : undefined,
      focus_border_settings: this.focusBorderSettings ? this.focusBorderSettings.serialize() : undefined,
      focus_shadow: this.focusShadow ? this.focusShadow.serialize() : undefined,

      error_text_style: this.errorTextStyle ? this.errorTextStyle.serialize() : undefined,
      error_placeholder_style: this.errorPlaceholderStyle ? this.errorPlaceholderStyle.serialize() : undefined,
      error_fill_settings: this.errorFillSettings ? this.errorFillSettings.serialize() : undefined,
      error_border_settings: this.errorBorderSettings ? this.errorBorderSettings.serialize() : undefined,
      error_shadow: this.errorShadow ? this.errorShadow.serialize() : undefined,

      disable_input: this.disableInput ? this.disableInput.serialize() : null,
      tooltip: this.tooltip,
      element_actions: this.elementActions.map(item => item.serialize()),
      on_change_actions: this.onChangeActions.map(item => item.serialize()),
      v: this.version
    };

    return super.serialize();
  }

  get formField(): FormField {
    if (!this._formField) {
      this.updateFormField();
    }

    return this._formField;
  }

  get fieldDescription(): FieldDescription {
    if (!this._fieldDescription) {
      this.updateFieldDescription();
    }

    return this._fieldDescription;
  }

  updateFieldDescription() {
    this._fieldDescription = getFieldDescriptionByType(this.formField.field);
  }

  updateFormField() {
    this._formField = new FormField().deserialize({
      name: this.settings.name,
      label: this.settings.verboseName,
      field: this.settings.field,
      required: this.settings.required,
      reset_enabled: this.settings.resetEnabled,
      editable: this.settings.editable,
      placeholder: this.settings.placeholder,
      validator_type: this.settings.validatorType,
      validator_params: this.settings.validatorParams,
      params: this.settings.params,
      description: this.settings.description
    });
  }

  get analyticsName(): string {
    return this.settings ? `field_${this.settings.field}` : 'field';
  }

  defaultName() {
    return 'field';
  }
}

registerElementForType(ElementType.Field, FieldElementItem);
