var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { CdkDropList, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, EventEmitter, OnChanges, OnDestroy, OnInit } from '@angular/core';
import * as Color from 'color';
import isEqual from 'lodash/isEqual';
import isPlainObject from 'lodash/isPlainObject';
import pickBy from 'lodash/pickBy';
import values from 'lodash/values';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { NotificationService } from '@common/notifications';
import { ServerRequestError } from '@modules/api';
import { CustomizeService, KanbanBoardSettings, KanbanBoardStage, ListDefaultSelection, ViewContext, ViewContextElement } from '@modules/customize';
import { ListModelDescriptionDataSource } from '@modules/data-sources';
import { applyBooleanInput } from '@modules/fields';
import { ColumnsModelListStore, EMPTY_OUTPUT, listItemEquals, SELECTED_ITEM_OUTPUT } from '@modules/list';
import { ModelDescription } from '@modules/models';
import { paramsToGetQueryOptions } from '@modules/resources';
import { getBaseStateFetch, getListStateColumns } from '../kanban-board/kanban-board-state';
export function getKanbanBoardGroupStateVisible(state) {
    return {
        visible: state.visibleInput ? state.visibleInput.serialize() : undefined
    };
}
export function getKanbanBoardGroupStateFetch(state) {
    return __assign({}, getBaseStateFetch(state), { stageValue: state.stageValue });
}
var KanbanBoardColumnComponent = /** @class */ (function () {
    function KanbanBoardColumnComponent(listStore, customizeService, notificationService, cd) {
        this.listStore = listStore;
        this.customizeService = customizeService;
        this.notificationService = notificationService;
        this.cd = cd;
        this.visibleColumns = [];
        this.checkedItems = {};
        this.theme = false;
        this.fetchStarted = new EventEmitter();
        this.fetchFinished = new EventEmitter();
        this.updateSelected = new EventEmitter();
        this.updateChecked = new EventEmitter();
        this.dragStarted = new EventEmitter();
        this.dragFinished = new EventEmitter();
        this.itemAdded = new EventEmitter();
        this.modelUpdated = new EventEmitter();
        this.isHidden = false;
        this.stage$ = new BehaviorSubject(undefined);
        this.listState$ = new BehaviorSubject({});
        this.state = {};
        this.prevState = {};
        this.isVisible = false;
        this.items = [];
        this.loading = true;
        this.loadingMore = false;
    }
    KanbanBoardColumnComponent.prototype.ngOnInit = function () {
        var _this = this;
        combineLatest(this.stage$, this.listState$)
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var stage = _a[0], listState = _a[1];
            var state = __assign({}, listState, { stageValue: stage ? stage.value : undefined, visibleInput: stage ? stage.visibleInput : undefined });
            _this.prevState = _this.state;
            _this.state = state;
            _this.onStateUpdated(state);
        });
        this.customizeService.enabled$.pipe(untilDestroyed(this)).subscribe(function () { return _this.updateIsHidden(); });
        this.updateIsHidden();
    };
    KanbanBoardColumnComponent.prototype.ngOnDestroy = function () { };
    KanbanBoardColumnComponent.prototype.ngOnChanges = function (changes) {
        if (changes.stage) {
            this.stage$.next(this.stage);
        }
        if (changes.listState) {
            this.listState$.next(this.listState);
        }
    };
    KanbanBoardColumnComponent.prototype.getItems = function () {
        return this.items;
    };
    KanbanBoardColumnComponent.prototype.setItems = function (value) {
        this.items = value;
        this.cd.markForCheck();
    };
    KanbanBoardColumnComponent.prototype.getCount = function () {
        return this.listStore.count;
    };
    KanbanBoardColumnComponent.prototype.setCount = function (value) {
        this.listStore.count = value;
    };
    KanbanBoardColumnComponent.prototype.onStateUpdated = function (state) {
        if (!isEqual(getKanbanBoardGroupStateVisible(state), getKanbanBoardGroupStateVisible(this.prevState))) {
            this.initVisibleObserver(state);
        }
        if (!isEqual(getKanbanBoardGroupStateFetch(state), getKanbanBoardGroupStateFetch(this.prevState))) {
            this.fetch(state);
        }
        else {
            if (!isEqual(getListStateColumns(state), getListStateColumns(this.listState))) {
                if (this.listStore.dataSource) {
                    this.listStore.dataSource.columns = state.dataSource ? state.dataSource.columns : [];
                    this.listStore.deserializeModelAttributes();
                }
            }
        }
    };
    KanbanBoardColumnComponent.prototype.initVisibleObserver = function (state) {
        var _this = this;
        if (this.isVisibleSubscription) {
            this.isVisibleSubscription.unsubscribe();
        }
        if (!state.visibleInput) {
            this.isVisible = true;
            this.updateIsHidden();
            this.cd.markForCheck();
            return;
        }
        this.isVisible = applyBooleanInput(state.visibleInput, this.context);
        this.updateIsHidden();
        this.cd.markForCheck();
        this.isVisibleSubscription = this.context.outputValues$
            .pipe(debounceTime(10), distinctUntilChanged(), untilDestroyed(this))
            .subscribe(function () {
            _this.isVisible = applyBooleanInput(state.visibleInput, _this.context);
            _this.updateIsHidden();
            _this.cd.markForCheck();
        });
    };
    KanbanBoardColumnComponent.prototype.updateIsHidden = function () {
        this.isHidden = !this.isVisible && !this.customizeService.enabled;
        this.cd.markForCheck();
    };
    KanbanBoardColumnComponent.prototype.getStateQueryOptions = function (state, extraParams) {
        if (extraParams === void 0) { extraParams = {}; }
        var queryOptions = paramsToGetQueryOptions(__assign({}, state.dataSourceParams, extraParams));
        queryOptions.filters = (queryOptions.filters ? queryOptions.filters : []).concat((state.filters ? state.filters : []));
        queryOptions.search = state.search;
        queryOptions.sort = state.sort;
        return queryOptions;
    };
    KanbanBoardColumnComponent.prototype.getBaseStateQueryOptions = function () {
        return this.getStateQueryOptions(this.state);
    };
    KanbanBoardColumnComponent.prototype.fetch = function (state) {
        var _this = this;
        var _a;
        if (!state.dataSource || !state.dataSource.isConfigured()) {
            return;
        }
        if (this.fetchSubscription) {
            this.fetchSubscription.unsubscribe();
            this.fetchSubscription = undefined;
        }
        if (this.fetchMoreSubscription) {
            this.fetchMoreSubscription.unsubscribe();
            this.fetchMoreSubscription = undefined;
        }
        this.items = undefined;
        this.loading = true;
        this.loadingMore = false;
        this.error = undefined;
        this.cd.markForCheck();
        this.fetchStarted.emit();
        if (this.settings.defaultSelection && !this.selectedItem) {
            this.contextElement.patchOutputValueMeta(SELECTED_ITEM_OUTPUT, { loading: true });
        }
        if (state.inputsLoading) {
            this.listStore.reset();
            return;
        }
        this.listStore.dataSource = state.dataSource;
        this.listStore.useDataSourceColumns = true;
        this.listStore.staticData = state.dataSourceStaticData;
        this.listStore.queryOptions = this.getStateQueryOptions(state, (_a = {},
            _a[this.settings.stageField] = state.stageValue,
            _a));
        this.listStore.context = this.context;
        this.listStore.contextElement = this.contextElement;
        this.listStore.perPage = state.settings && state.settings.perPage ? state.settings.perPage : 10;
        this.listStore.reset(1);
        this.items = [];
        this.cd.markForCheck();
        this.fetchSubscription = this.listStore
            .getNext()
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            _this.loading = false;
            _this.items = result;
            _this.cd.markForCheck();
            if (_this.settings.defaultSelection && !_this.selectedItem) {
                _this.contextElement.patchOutputValueMeta(SELECTED_ITEM_OUTPUT, { loading: false });
                if (_this.settings.defaultSelection == ListDefaultSelection.First && _this.stageIndex == 0) {
                    var first = _this.items ? _this.items[0] : undefined;
                    _this.updateSelected.emit(first);
                    _this.updateChecked.emit(first ? [first.model] : []);
                }
            }
            _this.fetchFinished.emit(_this.items);
        }, function (error) {
            if (error instanceof ServerRequestError && error.errors.length) {
                _this.error = error.errors[0];
            }
            else if (isPlainObject(error)) {
                _this.error = JSON.stringify(error);
            }
            else if (error.hasOwnProperty('message')) {
                console.error(error);
                _this.error = error.message;
            }
            else {
                console.error(error);
                _this.error = error;
            }
            _this.loading = false;
            _this.cd.markForCheck();
            _this.contextElement.setOutputValue(EMPTY_OUTPUT, false, { loading: false, error: true });
            if (_this.settings.defaultSelection && !_this.selectedItem) {
                _this.contextElement.patchOutputValueMeta(SELECTED_ITEM_OUTPUT, { loading: false });
            }
        });
    };
    KanbanBoardColumnComponent.prototype.fetchMore = function () {
        var _this = this;
        if (this.loadingMore) {
            return;
        }
        this.loadingMore = true;
        this.cd.markForCheck();
        this.fetchMoreSubscription = this.listStore
            .getNext()
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            _this.loadingMore = false;
            _this.items = _this.listStore.items;
            _this.cd.markForCheck();
        }, function (error) {
            _this.loadingMore = false;
            _this.cd.markForCheck();
            if (error instanceof ServerRequestError && error.errors.length) {
                _this.notificationService.error('Loading failed', error.errors[0]);
            }
            else {
                _this.notificationService.error('Loading failed', error);
            }
        });
    };
    KanbanBoardColumnComponent.prototype.toggleSelectedItem = function (item, index) {
        if (this.selectedItem === item) {
            this.updateSelected.emit(undefined);
        }
        else {
            this.updateSelected.emit(item);
        }
        if (this.settings.multipleSelection) {
            var pk_1 = item.model.primaryKey || index;
            var checked = this.isItemChecked(item, index);
            if (!checked) {
                var checkedItems = values(this.checkedItems).concat([item.model]);
                this.updateChecked.emit(checkedItems);
            }
            else {
                var checkedItems = values(pickBy(this.checkedItems, function (v, k) { return k != pk_1; }));
                this.updateChecked.emit(checkedItems);
            }
        }
    };
    KanbanBoardColumnComponent.prototype.reloadData = function () {
        this.fetch(this.state);
    };
    KanbanBoardColumnComponent.prototype.listEntered = function (event) {
        this.itemAdded.emit(event.item.data);
    };
    KanbanBoardColumnComponent.prototype.listDropped = function (event) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        }
        else {
            transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
        }
        this.dragFinished.emit(event.item.data);
    };
    KanbanBoardColumnComponent.prototype.listDragStarted = function (event) {
        this.dragStarted.emit(event.source.data);
    };
    KanbanBoardColumnComponent.prototype.trackItemFn = function (i, item) {
        return item.model.primaryKey || i;
    };
    KanbanBoardColumnComponent.prototype.itemEquals = function (lhs, rhs) {
        return listItemEquals(lhs, rhs);
    };
    KanbanBoardColumnComponent.prototype.isItemSelected = function (item, index) {
        if (this.settings.multipleSelection) {
            return this.isItemChecked(item, index);
        }
        else {
            return this.itemEquals(this.selectedItem, item);
        }
    };
    KanbanBoardColumnComponent.prototype.isItemChecked = function (item, index) {
        var pk = item.model.primaryKey || index + "_" + index;
        return this.checkedItems[pk];
    };
    KanbanBoardColumnComponent.prototype.backgroundCustomColor = function (color) {
        try {
            var clr = Color(color);
            return clr.alpha(0.1).string();
        }
        catch (e) {
            return null;
        }
    };
    return KanbanBoardColumnComponent;
}());
export { KanbanBoardColumnComponent };
