import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { switchMap } from 'rxjs/operators';

import { CustomViewsStore } from '@modules/custom-views';
import { DisplayFieldType } from '@modules/fields';
import { ModelDescription } from '@modules/models';
import { CurrentUserStore } from '@modules/users';
import { View } from '@modules/views';
import { controlValue, isSet, KeyboardEventKeyCode, TypedChanges } from '@shared';

import { DisplayFieldArray } from '../display-fields-edit/display-field.array';
import { DisplayFieldControl } from '../display-fields-edit/display-field.control';

@Component({
  selector: 'app-display-fields-edit-item',
  templateUrl: './display-fields-edit-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DisplayFieldsEditItemComponent implements OnInit, OnDestroy, OnChanges {
  @Input() form: DisplayFieldArray;
  @Input() control: DisplayFieldControl;
  @Input() index: number;
  @Input() modelDescription: ModelDescription;
  @Input() search: string;
  @Input() loading = false;
  @Input() visibleEditable = true;
  @Output() customize = new EventEmitter<void>();
  @Output() moveToTop = new EventEmitter<void>();
  @Output() removeControl = new EventEmitter<void>();
  @Output() openViewEditor = new EventEmitter<View>();
  @Output() openCustomViewTemplates = new EventEmitter<void>();
  @Output() changeMapping = new EventEmitter<View>();
  @Output() updateCustomViewTemplate = new EventEmitter<View>();
  @Output() rename = new EventEmitter<string>();

  @ViewChild('input') inputElement: ElementRef;

  queryField = false;
  view: View;
  nameValue: string;
  nameEditing = false;
  displayFieldTypes = DisplayFieldType;

  constructor(
    public currentUserStore: CurrentUserStore,
    private customViewsStore: CustomViewsStore,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.control.valueChanges.pipe(untilDestroyed(this)).subscribe(() => this.cd.markForCheck());

    if (this.control.type == DisplayFieldType.CustomView) {
      controlValue(this.control.controls.customViewUniqueName)
        .pipe(
          switchMap(uniqueName => this.customViewsStore.getDetailFirst(uniqueName)),
          untilDestroyed(this)
        )
        .subscribe(customView => {
          this.view = customView ? customView.view : undefined;
          this.cd.markForCheck();
        });
    }
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<DisplayFieldsEditItemComponent>): void {
    if (changes.control || changes.modelDescription) {
      this.queryField = this.isQueryField(this.control, this.modelDescription);
    }
  }

  isQueryField(control: DisplayFieldControl, modelDescription: ModelDescription): boolean {
    if (modelDescription) {
      return modelDescription.field(control.controls.name.value) !== undefined;
    } else {
      return control.type == DisplayFieldType.Base;
    }
  }

  startEditing() {
    this.nameValue = isSet(this.control.controls.verboseName.value) ? this.control.controls.verboseName.value : '';
    this.nameEditing = true;
    this.cd.detectChanges();

    this.inputElement.nativeElement.focus();
    setTimeout(() => this.inputElement.nativeElement.select(), 0);
  }

  finishEditing(save = true) {
    const currentValue = isSet(this.control.controls.verboseName.value) ? this.control.controls.verboseName.value : '';

    this.nameEditing = false;
    this.cd.markForCheck();

    if (save && this.nameValue != currentValue) {
      this.rename.emit(this.nameValue);
    }

    this.nameValue = undefined;
  }

  onKeyDown(e: KeyboardEvent) {
    if (e.keyCode == KeyboardEventKeyCode.Enter) {
      e.preventDefault();
    }
  }

  onKeyUp(e: KeyboardEvent) {
    if (e.keyCode == KeyboardEventKeyCode.Enter) {
      this.finishEditing();
    } else if (e.keyCode == KeyboardEventKeyCode.Escape) {
      this.finishEditing(false);
    }
  }
}
