import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, publishLast, refCount, switchMap, tap } from 'rxjs/operators';

import { AppConfigService } from '@core';
import { ApiService, ServerRequestError } from '@modules/api';
import { MenuGeneratorService } from '@modules/menu';
import { ModelDescriptionService } from '@modules/model-queries';
import {
  CryptService,
  databaseResourcesEngines,
  Environment,
  Project,
  Resource,
  ResourceDeploy,
  ResourceName,
  ResourceTypeItem
} from '@modules/projects';
import { ResourceParamsResult } from '@modules/resources';

import { ResourceGeneratorService } from '../resource-generator/resource-generator.service';

export interface DatabaseParamsOptions {
  deploy: ResourceDeploy;
  url?: string;
  token?: string;
  project?: string;
  region?: string;
  database_url?: string;
  database_host?: string;
  database_port?: string;
  database_name?: string;
  database_user?: string;
  database_password?: string;
  database_schema?: string;
  database_extra?: string;
  database_only?: string[];
  database_ssl_ca?: string;
  database_ssl_cert?: string;
  database_ssl_key?: string;
  database_ssh_host?: string;
  database_ssh_port?: number;
  database_ssh_user?: string;
  database_ssh_public_key?: string;
  database_ssh_private_key?: string;
}

@Injectable()
export class DatabaseGeneratorService extends ResourceGeneratorService<DatabaseParamsOptions> {
  tokenRequired = true;

  constructor(
    private menuGeneratorService: MenuGeneratorService,
    private cryptService: CryptService,
    private modelDescriptionService: ModelDescriptionService,
    private appConfigService: AppConfigService,
    private apiService: ApiService,
    private http: HttpClient,
    private injector: Injector
  ) {
    super();
  }

  getParamsOptions(project: Project, environment: Environment, resource: Resource): Observable<DatabaseParamsOptions> {
    if (!resource.params['bridge_settings']) {
      return of({
        deploy: resource.params['deploy'],
        url: resource.params['url'],
        region: resource.params['region']
      });
    }

    // TODO: Add JetBridge params class
    return this.cryptService.decrypt(project, environment, undefined, resource.params['bridge_settings']).pipe(
      map(result => {
        const dbSettings = JSON.parse(result);

        return {
          deploy: resource.params['deploy'],
          url: resource.params['url'],
          token: dbSettings['token'],
          project: dbSettings['project'],
          region: resource.params['region'],
          database_url: dbSettings['database_url'],
          database_host: dbSettings['database_host'],
          database_port: dbSettings['database_port'],
          database_name: dbSettings['database_name'],
          database_user: dbSettings['database_user'],
          database_password: dbSettings['database_password'],
          database_schema: dbSettings['database_schema'],
          database_extra: dbSettings['database_extra'],
          database_only: dbSettings['database_only'],
          database_ssl_ca: dbSettings['database_ssl_ca'],
          database_ssl_cert: dbSettings['database_ssl_cert'],
          database_ssl_key: dbSettings['database_ssl_key'],
          database_ssh_host: dbSettings['database_ssh_host'],
          database_ssh_port: dbSettings['database_ssh_port'],
          database_ssh_user: dbSettings['database_ssh_user'],
          database_ssh_public_key: resource.params['database_ssh_public_key'],
          database_ssh_private_key: dbSettings['database_ssh_private_key']
        };
      })
    );
  }

  generateGeneralParams(
    project: Project,
    environment: Environment,
    typeItem: ResourceTypeItem,
    options: DatabaseParamsOptions
  ): Observable<ResourceParamsResult> {
    if (options.deploy == ResourceDeploy.Direct) {
      const engine = databaseResourcesEngines.find(item => item.name == typeItem.name);
      const bridgeSettings = JSON.stringify({
        token: options.token,
        project: project.uniqueName,
        database_engine: engine ? engine.engines[0] : undefined,
        database_url: options.database_url,
        database_host: options.database_host,
        database_port: options.database_port,
        database_name: options.database_name,
        database_user: options.database_user,
        database_password: options.database_password,
        database_schema: options.database_schema,
        database_extra: options.database_extra,
        database_only: options.database_only,
        database_ssl_ca: options.database_ssl_ca,
        database_ssl_cert: options.database_ssl_cert,
        database_ssl_key: options.database_ssl_key,
        database_ssh_host: options.database_ssh_host,
        database_ssh_port: options.database_ssh_port,
        database_ssh_user: options.database_ssh_user,
        database_ssh_private_key: options.database_ssh_private_key
      });

      return this.cryptService.encrypt(project, environment, undefined, bridgeSettings).pipe(
        map(result => {
          return {
            resourceToken: options.token,
            resourceParams: {
              deploy: options.deploy,
              url: options.url,
              region: options.region,
              bridge_settings: result,
              schema: options.database_schema,
              database_ssh_public_key: options.database_ssh_public_key
            }
          };
        })
      );
    } else {
      return of({
        resourceToken: options.token,
        resourceParams: {
          deploy: options.deploy,
          url: options.url
        }
      });
    }
  }

  generateParams(
    project: Project,
    environment: Environment,
    typeItem: ResourceTypeItem,
    options: DatabaseParamsOptions
  ): Observable<ResourceParamsResult> {
    return this.generateGeneralParams(project, environment, typeItem, options).pipe(
      switchMap(result => {
        const instance = new Resource();

        instance.type = typeItem.resourceType;
        instance.typeItem = typeItem;
        instance.deploy = options.deploy;
        instance.token = result.resourceToken;
        instance.params = result.resourceParams || {};

        return this.modelDescriptionService.getFromResource(instance).pipe(
          tap(modelDescriptions => {
            if (!modelDescriptions) {
              throw new ServerRequestError('Database structured failed to load');
            }

            if (typeItem.name != ResourceName.JetDatabase && !modelDescriptions.length) {
              throw new ServerRequestError('Database does not have any valid Tables');
            }
          }),
          map(modelDescriptions => {
            return {
              ...result,
              modelDescriptions: modelDescriptions.map(item => {
                item.resource = '{{resource}}';
                return item.serialize();
              }),
              resourceModelDescriptions: true
            };
          })
        );
      })
    );
  }

  createJetDatabase(
    project: Project,
    environment: Environment,
    resource: Resource
  ): Observable<{
    databaseName: string;
    userName: string;
    password: string;
    url: string;
  }> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.nodeMethodURL('project_databases/create');
        let headers = new HttpHeaders();
        const data = {
          projectUniqueName: project.uniqueName,
          environmentUniqueName: environment.uniqueName,
          resourceUniqueName: resource.uniqueName
        };

        headers = this.apiService.setHeadersToken(headers);

        return this.http.post<Object[]>(url, data, { headers: headers });
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }
}
