import { AbstractControl } from '@angular/forms';
import { Observable, timer } from 'rxjs';
import { debounce, debounceTime, distinctUntilChanged, map, shareReplay, startWith } from 'rxjs/operators';

export function controlValue<T = any>(
  control: AbstractControl,
  options: { debounce?: number | (() => number) } = {}
): Observable<T> {
  let valueChanges$ = control.valueChanges as Observable<T>;

  if (options.debounce && typeof options.debounce === 'number') {
    valueChanges$ = valueChanges$.pipe(debounceTime(options.debounce));
  } else if (options.debounce && typeof options.debounce === 'function') {
    const getDelay = options.debounce;
    valueChanges$ = valueChanges$.pipe(debounce(() => timer(getDelay())));
  }

  return valueChanges$.pipe(startWith<T>(control.value), shareReplay(1));
}

export function controlStatus(control: AbstractControl): Observable<string> {
  return control.statusChanges.pipe(startWith(control.status), distinctUntilChanged(), shareReplay(1));
}

export function controlValid(control: AbstractControl): Observable<boolean> {
  return control.statusChanges.pipe(
    map(() => control.valid || control.pending),
    startWith(control.valid || control.pending),
    distinctUntilChanged(),
    shareReplay(1)
  );
}

export function controlDisabled(control: AbstractControl): Observable<boolean> {
  return control.statusChanges.pipe(
    map(() => control.disabled),
    startWith(control.disabled),
    distinctUntilChanged(),
    shareReplay(1)
  );
}

export function setControlEnabled(control: AbstractControl, enabled: boolean) {
  if (control.disabled && enabled) {
    control.enable();
  } else if (control.enabled && !enabled) {
    control.disable();
  }
}

export function setControlDisabled(control: AbstractControl, disabled: boolean) {
  setControlEnabled(control, !disabled);
}
