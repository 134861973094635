<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayPanelClass]="['overlay', 'overlay_horizontal_center']"
  [cdkConnectedOverlayPositions]="overlayPositions"
  [cdkConnectedOverlayOrigin]="origin"
  [cdkConnectedOverlayOpen]="true"
  [cdkConnectedOverlayPush]="true"
  [cdkConnectedOverlayGrowAfterOpen]="true"
  [cdkConnectedOverlayHasBackdrop]="false"
  [cdkConnectedOverlayBackdropClass]="'popover2-backdrop'"
>
  <div class="view-editor-direction-wrapper" [style.width.px]="frame.width" [style.height.px]="frame.height">
    <div
      class="view-editor-direction view-editor-direction_active"
      [style.top.%]="fromY * 100"
      [style.left.%]="fromX * 100"
      [style.width.px]="length"
      [style.transform]="transform"
      #root
    >
      <ng-container
        *ngIf="
          [gradientTypes.Linear, gradientTypes.Radial, gradientTypes.Diamond].includes(control.controls.type.value)
        "
      >
        <div class="view-editor-direction__line"></div>
        <div class="view-editor-direction__line-bounds" (click)="addStop($event)"></div>
      </ng-container>

      <ng-container *ngIf="[gradientTypes.Angular].includes(control.controls.type.value)">
        <div
          class="view-editor-direction__ellipse"
          [style.height.em]="(length * control.controls.aspect_ratio.value * 2) / 10"
        >
          <!--          <div class="view-editor-direction__ellipse-bounds" (click)="addEllipseStop($event)">-->
          <!--            <div class="view-editor-direction__ellipse-bounds-inner" (click)="$event.stopPropagation()"></div>-->
          <!--          </div>-->
        </div>
      </ng-container>

      <ng-container
        *ngFor="
          let stopControl of control.controls.stops.controls;
          trackBy: trackStopControlFn;
          let first = first;
          let last = last
        "
      >
        <app-gradient-position-point
          *ngIf="
            first ||
            last ||
            [gradientTypes.Linear, gradientTypes.Radial, gradientTypes.Diamond].includes(control.controls.type.value)
          "
          [positionPercent]="stopControl.controls.position | appControlValue | async"
          [active]="stopControl.getId() == activeStop"
          [control]="stopControl"
          [origin]="origin"
          [snap]="first || last"
          (click)="$event.stopPropagation(); setActiveStop(stopControl)"
          (positionChange)="
            onStopPositionChange({ control: stopControl, first: first, last: last, x: $event.x, y: $event.y })
          "
        ></app-gradient-position-point>
      </ng-container>

      <div
        *ngIf="
          [gradientTypes.Radial, gradientTypes.Angular, gradientTypes.Diamond].includes(control.controls.type.value)
        "
        class="view-editor-direction__width"
      >
        <app-gradient-position-point
          [positionPx]="length * control.controls.aspect_ratio.value"
          [active]="false"
          [origin]="origin"
          (click)="$event.stopPropagation()"
          (positionChange)="onAspectRatioStopPositionChange($event.x, $event.y)"
        ></app-gradient-position-point>
      </div>
    </div>
  </div>
</ng-template>
