import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import { AfterViewInit, ChangeDetectorRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import camelCase from 'lodash/camelCase';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { of, timer } from 'rxjs';
import { debounce, map, switchMap } from 'rxjs/operators';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { CodeFieldComponent } from '@modules/field-components';
import { AutoFieldComponent, createFormFieldFactory, getJavaScriptSyntaxError } from '@modules/fields';
import { contextToFormulaValue, FieldInputControl, transformFormulaElementAccessors } from '@modules/parameters';
import { controlValue, isSet } from '@shared';
import { ViewContextTokenPopoverOverlayComponent } from '../view-context-token-popover-overlay/view-context-token-popover-overlay.component';
var ErrorResult = /** @class */ (function () {
    function ErrorResult(error) {
        this.error = error;
    }
    return ErrorResult;
}());
var InputEditJsValueComponent = /** @class */ (function () {
    function InputEditJsValueComponent(cd) {
        this.cd = cd;
        this.staticValueDisabled = false;
        this.placeholder = 'return 2 * 3;';
        this.displayValueTypesEnabled = true;
        this.extraSections = [];
        this.classes = [];
        this.fill = false;
        this.fillVertical = false;
        this.header = false;
        this.inline = false;
        this.dark = false;
        this.darker = false;
        this.resultShowOnFocus = true;
        this.switchToDefault = new EventEmitter();
        this.switchToFormula = new EventEmitter();
        this.createField = createFormFieldFactory();
        this.focused = false;
        this.inputValueLoading = true;
        this.inputSet = false;
    }
    InputEditJsValueComponent.prototype.ngOnInit = function () {
        var _this = this;
        controlValue(this.itemForm.controls.js_value)
            .pipe(map(function (value, index) { return ({ value: value, index: index }); }), debounce(function (_a) {
            var index = _a.index;
            return timer(index == 0 ? 0 : 600);
        }), switchMap(function (_a) {
            var value = _a.value;
            if (isSet(value)) {
                var syntaxError = getJavaScriptSyntaxError(value);
                if (syntaxError) {
                    return of({ isSet: isSet(value), error: syntaxError });
                }
            }
            return of({ isSet: isSet(value), result: undefined });
            // const input = new FieldInput();
            //
            // input.path = ['value'];
            // input.valueType = InputValueType.Js;
            // input.jsValue = value;
            //
            // return applyParamInput$(input, {
            //   context: this.context,
            //   contextElement: this.contextElement,
            //   raiseErrors: true,
            //   errorValueFn: error => new ErrorResult(error),
            //   debounce: 200
            // }).pipe(
            //   map(result => {
            //     if (result instanceof ErrorResult) {
            //       const errorDescription = result.error instanceof InputError ? result.error.error.message : undefined;
            //       return { isSet: isSet(value), error: result.error.message, errorDescription: errorDescription };
            //     } else {
            //       return { isSet: isSet(value), result: result !== EMPTY ? result : undefined };
            //     }
            //   })
            // );
        }), untilDestroyed(this))
            .subscribe(function (result) {
            _this.inputValueLoading = false;
            _this.inputSet = result.isSet;
            _this.inputValue = result.result;
            _this.inputError = result.error;
            _this.inputErrorDescription = result.errorDescription;
            _this.cd.markForCheck();
        });
    };
    InputEditJsValueComponent.prototype.ngOnDestroy = function () { };
    InputEditJsValueComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        if (this.jsField &&
            this.jsField.dynamicComponent.currentComponent &&
            this.jsField.dynamicComponent.currentComponent.instance instanceof CodeFieldComponent) {
            this.jsField.dynamicComponent.currentComponent.instance
                .getFocus$()
                .pipe(untilDestroyed(this))
                .subscribe(function (value) {
                _this.focused = value;
                _this.cd.markForCheck();
            });
        }
    };
    InputEditJsValueComponent.prototype.focus = function () {
        if (this.jsField) {
            this.jsField.focus();
        }
    };
    InputEditJsValueComponent.prototype.openTokenPopover = function () {
        if (this.tokenPopoverOverlayComponent) {
            this.tokenPopoverOverlayComponent.open();
        }
    };
    InputEditJsValueComponent.prototype.closeTokenPopover = function () {
        if (this.tokenPopoverOverlayComponent) {
            this.tokenPopoverOverlayComponent.close();
        }
    };
    InputEditJsValueComponent.prototype.insertJsToken = function (item) {
        if (!isSet(item.insert) && !isSet(item.formula)) {
            return;
        }
        if (this.jsField &&
            this.jsField.dynamicComponent.currentComponent &&
            this.jsField.dynamicComponent.currentComponent.instance instanceof CodeFieldComponent) {
            var ace = this.jsField.dynamicComponent.currentComponent.instance.ace;
            var selectionRange = ace.editor.selection.getRange();
            var insert = void 0;
            if (isSet(item.formula)) {
                insert = item.formula;
            }
            else {
                var formulaValue = contextToFormulaValue(item.insert);
                insert = transformFormulaElementAccessors(formulaValue, this.context, false);
            }
            var insertRow = selectionRange.start.row;
            var insertLast = isSet(item['label']) ? item.label : item.insert[item.insert.length - 1];
            var insertRowValue = ace.editor.session.getLine(insertRow);
            var varName = typeof insertLast == 'number' ? "item" + insertLast : camelCase(String(insertLast));
            var prefixMatch = insertRowValue.match(/^([\s\t]+)/);
            var prefix = prefixMatch ? prefixMatch[1] : '';
            ace.editor.session.insert({ row: insertRow, column: 0 }, prefix + "let " + varName + " = " + insert + ";\n");
        }
    };
    InputEditJsValueComponent.prototype.onJsTokenSelected = function (item) {
        if (isSet(item.insert) || isSet(item.formula)) {
            this.insertJsToken(item);
        }
    };
    return InputEditJsValueComponent;
}());
export { InputEditJsValueComponent };
