<app-customize-bar-header
  [title]="'Cards'"
  [subtitle]="'Appearance'"
  [backEnabled]="true"
  [backPopSettingsComponent]="false"
  (backClick)="back()"
>
  <ng-container actions>
    <div class="button-group" [style.margin]="'-5px 0'">
      <div
        class="button-group__item"
        [class.button-group__item_active]="themeService.isDefaultTheme"
        (click)="themeService.theme = 'default'"
      >
        <span class="button-group__item-icon icon-sun"></span>
      </div>

      <div
        class="button-group__item"
        [class.button-group__item_active]="themeService.isDarkTheme"
        (click)="themeService.theme = 'dark'"
      >
        <span class="button-group__item-icon icon-moon"></span>
      </div>
    </div>
  </ng-container>
</app-customize-bar-header>

<div class="sidebar__content">
  <app-sidebar-section>
    <div class="sidebar__element">
      <app-styles-element-wrapper
        [control]="context.controls.appearance.controls.element_wrapper_styles"
      ></app-styles-element-wrapper>
    </div>
  </app-sidebar-section>
</div>

<app-sidebar-submit
  [saveDisabled]="!(hasChanges$ | async)"
  [saveLoading]="submitLoading"
  [saveLabel]="'Save changes'"
  [cancelDisabled]="!(hasChanges$ | async)"
  [cancelLabel]="'Reset changes'"
  [hidden]="!(hasChanges$ | async)"
  (cancelClick)="cancel()"
  (submitClick)="submit()"
>
</app-sidebar-submit>
