<ng-container *ngIf="visible || (customizeEnabled$ | async)">
  <ng-container *ngIf="element">
    <app-table
      *ngIf="layout?.type == layoutTypes.Table"
      [params]="params"
      [context]="context"
      [contextElement]="contextElements[layoutIndex]?.element"
      [hideParams]="hideParams"
      [settings]="element.layouts[layoutIndex]"
      [scrollable]="false"
      [moreLink]="moreLink"
      [createSegmentAllowed]="false"
      [layoutIndex]="layoutIndex"
      [viewId]="viewId + '_' + layoutIndex"
      [accentColor]="accentColor"
      [theme]="true"
      (paramsChanged)="onParamsChanged($event)"
      (settingsChanged)="onSettingsChanged(layoutIndex, $event)"
      (layoutAdd)="addLayout()"
      (layoutCustomize)="customizeLayout($event)"
    >
    </app-table>
    <app-kanban-board
      *ngIf="layout?.type == layoutTypes.KanbanBoard"
      [params]="params"
      [context]="context"
      [contextElement]="contextElements[layoutIndex]?.element"
      [hideParams]="hideParams"
      [settings]="element.layouts[layoutIndex]"
      [scrollable]="false"
      [moreLink]="moreLink"
      [layoutIndex]="layoutIndex"
      [viewId]="viewId + '_' + layoutIndex"
      [accentColor]="accentColor"
      [theme]="true"
      (paramsChanged)="onParamsChanged($event)"
      (settingsChanged)="onSettingsChanged(layoutIndex, $event)"
      (layoutAdd)="addLayout()"
      (layoutCustomize)="customizeLayout($event)"
    >
    </app-kanban-board>
    <app-map
      *ngIf="layout?.type == layoutTypes.Map"
      [params]="params"
      [context]="context"
      [contextElement]="contextElements[layoutIndex]?.element"
      [hideParams]="hideParams"
      [settings]="element.layouts[layoutIndex]"
      [scrollable]="false"
      [moreLink]="moreLink"
      [createSegmentAllowed]="false"
      [layoutIndex]="layoutIndex"
      [viewId]="viewId + '_' + layoutIndex"
      [accentColor]="accentColor"
      [theme]="true"
      (paramsChanged)="onParamsChanged($event)"
      (settingsChanged)="onSettingsChanged(layoutIndex, $event)"
      (layoutAdd)="addLayout()"
      (layoutCustomize)="customizeLayout($event)"
    >
    </app-map>
    <app-calendar
      *ngIf="layout?.type == layoutTypes.Calendar"
      [params]="params"
      [context]="context"
      [contextElement]="contextElements[layoutIndex]?.element"
      [hideParams]="hideParams"
      [settings]="element.layouts[layoutIndex]"
      [scrollable]="false"
      [moreLink]="moreLink"
      [createSegmentAllowed]="false"
      [layoutIndex]="layoutIndex"
      [viewId]="viewId + '_' + layoutIndex"
      [accentColor]="accentColor"
      [theme]="true"
      (paramsChanged)="onParamsChanged($event)"
      (settingsChanged)="onSettingsChanged(layoutIndex, $event)"
      (layoutAdd)="addLayout()"
      (layoutCustomize)="customizeLayout($event)"
    >
    </app-calendar>
    <app-grid
      *ngIf="layout?.type == layoutTypes.Grid"
      [params]="params"
      [context]="context"
      [contextElement]="contextElements[layoutIndex]?.element"
      [hideParams]="hideParams"
      [settings]="element.layouts[layoutIndex]"
      [scrollable]="false"
      [moreLink]="moreLink"
      [createSegmentAllowed]="false"
      [layoutIndex]="layoutIndex"
      [viewId]="viewId + '_' + layoutIndex"
      [accentColor]="accentColor"
      [theme]="true"
      (paramsChanged)="onParamsChanged($event)"
      (settingsChanged)="onSettingsChanged(layoutIndex, $event)"
      (layoutAdd)="addLayout()"
      (layoutCustomize)="customizeLayout($event)"
    >
    </app-grid>
    <app-carousel
      *ngIf="layout?.type == layoutTypes.Carousel"
      [params]="params"
      [context]="context"
      [contextElement]="contextElements[layoutIndex]?.element"
      [hideParams]="hideParams"
      [settings]="element.layouts[layoutIndex]"
      [scrollable]="false"
      [moreLink]="moreLink"
      [createSegmentAllowed]="false"
      [layoutIndex]="layoutIndex"
      [viewId]="viewId + '_' + layoutIndex"
      [accentColor]="accentColor"
      [theme]="true"
      (paramsChanged)="onParamsChanged($event)"
      (settingsChanged)="onSettingsChanged(layoutIndex, $event)"
      (layoutAdd)="addLayout()"
      (layoutCustomize)="customizeLayout($event)"
    >
    </app-carousel>
    <app-timeline
      *ngIf="layout?.type == layoutTypes.Timeline"
      [params]="params"
      [context]="context"
      [contextElement]="contextElements[layoutIndex]?.element"
      [hideParams]="hideParams"
      [settings]="element.layouts[layoutIndex]"
      [scrollable]="false"
      [moreLink]="moreLink"
      [createSegmentAllowed]="false"
      [layoutIndex]="layoutIndex"
      [viewId]="viewId + '_' + layoutIndex"
      [accentColor]="accentColor"
      [theme]="true"
      (paramsChanged)="onParamsChanged($event)"
      (settingsChanged)="onSettingsChanged(layoutIndex, $event)"
      (layoutAdd)="addLayout()"
      (layoutCustomize)="customizeLayout($event)"
    >
    </app-timeline>
  </ng-container>
</ng-container>
