<form *ngIf="form.form" [formGroup]="form.form" (ngSubmit)="execute()">
  <app-popup2 [size]="'m'" [theme]="theme">
    <app-popup2-header>
      <app-popup2-title>
        <ng-container *ngIf="actionParams.length">Specify parameters</ng-container>
        <ng-container *ngIf="!actionParams.length">Executing action</ng-container>
      </app-popup2-title>

      <app-popup2-description>
        You are executing
        <strong>{{ verboseName || actionDescription.verboseName || actionDescription.name || 'action' }}</strong>
        <ng-container *ngIf="executeTotalCount > 1">
          for <strong>{{ executeTotalCount | i18nPlural: { '=1': '# item', other: '# items' } }}</strong></ng-container
        >.
        <ng-container *ngIf="actionParams.length"> <br />Please fill in the parameters below. </ng-container>
      </app-popup2-description>
    </app-popup2-header>

    <ng-container *ngIf="!executeStarted">
      <app-page-block *ngFor="let item of actionParams">
        <app-auto-field
          [form]="form.form"
          [field]="
            createField({
              name: item.name,
              label: item.verboseName || item.name,
              required: item.required,
              field: item.field,
              placeholder: item.placeholder,
              params: item.params
            })
          "
          [context]="fieldContext"
          [accentColor]="'accentColor' | appThemeColorHexOption: true | async"
        >
        </app-auto-field>
      </app-page-block>

      <app-page-block>
        <app-form-errors [form]="form.form" [manualMargin]="true"></app-form-errors>
      </app-page-block>

      <app-popup2-footer>
        <app-page-block>
          <app-stack [align]="'right'">
            <a
              href="javascript:void(0)"
              class="button popup2__footer-item"
              [class.button_disabled]="executeStarted"
              [class.button_theme]="theme"
              [appButtonTintColor]="theme ? ('accentColor' | appThemeColorHexOption: true | async) : undefined"
              [appButtonTintColorStyle]="tintStyles.Default"
              (click)="cancel()"
            >
              <span class="button__label">Cancel</span>
            </a>

            <a
              href="javascript:void(0)"
              class="button button_primary popup2__footer-item"
              [class.button_disabled]="
                form.form.invalid || executeStarted || executeProcessedCount == executeTotalCount
              "
              [class.button_theme]="theme"
              [appButtonTintColor]="theme ? ('accentColor' | appThemeColorHexOption: true | async) : undefined"
              [appButtonTintColorStyle]="tintStyles.Primary"
              style="margin-right: 10px;"
              (click)="execute()"
            >
              <app-loader-small
                *ngIf="executeStarted && executeProcessedCount < executeTotalCount"
                class="button__icon button__icon_left"
              ></app-loader-small>
              <span class="button__label">{{ submitLabel }}</span>
            </a>
          </app-stack>
        </app-page-block>
      </app-popup2-footer>
    </ng-container>

    <ng-container *ngIf="executeStarted">
      <app-page-block>
        <app-bar-progress
          *ngIf="actionDescription.queryAction"
          [label]="executeProcessedCount == executeTotalCount ? 'Finished' : 'Executing...'"
          [progress]="executeProcessedCount / executeTotalCount"
          [processed]="executeProcessedCount"
          [total]="executeTotalCount"
          [accentColor]="'accentColor' | appThemeColorHexOption: true | async"
        >
        </app-bar-progress>
      </app-page-block>

      <app-popup2-footer>
        <app-page-block>
          <app-stack [align]="'right'">
            <a
              href="javascript:void(0)"
              class="button button_primary popup2__footer-item"
              [class.button_disabled]="executeProcessedCount != executeTotalCount"
              [class.button_theme]="theme"
              [appButtonTintColor]="theme ? ('accentColor' | appThemeColorHexOption: true | async) : undefined"
              [appButtonTintColorStyle]="tintStyles.Primary"
              (click)="close()"
            >
              <span class="button__label">
                <ng-container *ngIf="executeProcessedCount == executeTotalCount">Done</ng-container>
                <ng-container *ngIf="executeProcessedCount != executeTotalCount">Executing</ng-container>
              </span>
            </a>
          </app-stack>
        </app-page-block>
      </app-popup2-footer>
    </ng-container>
  </app-popup2>
</form>
