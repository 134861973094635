import { ChangeDetectorRef, EventEmitter, Input, OnDestroy, OnInit, Optional, Output } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Subscription, timer } from 'rxjs';

import { BasePopupComponent } from '@common/popups';
import { AnalyticsEvent, IntercomService, UniversalAnalyticsService } from '@modules/analytics';
import { ServerRequestError } from '@modules/api';
import { createFormFieldFactory } from '@modules/fields';
import { Environment, Project, ProjectToken, ProjectTokenService, Resource, ResourceTypeItem } from '@modules/projects';

import { BaseResourceSettingsForm } from './base-resource-settings.form';

export abstract class BaseResourceSettingsComponent<O = Object> implements OnInit, OnDestroy {
  @Input() project: Project;
  @Input() environment: Environment;
  @Input() resource: Resource;
  @Input() resourceNameEditing = false;
  @Input() resourceDelete = false;
  @Input() typeItem: ResourceTypeItem;
  @Input() params = {};
  @Input() analyticsSource: string;
  @Output() saved = new EventEmitter<Resource>();
  @Output() canceled = new EventEmitter<void>();
  @Output() deleteRequested = new EventEmitter<void>();

  createField = createFormFieldFactory();
  loading = false;
  loadingSubmit = false;
  slowLoading = false;
  slowLoadingSubscription: Subscription;
  slowLoadingDuration = 3000;
  optionsHidden = false;

  constructor(
    public form: BaseResourceSettingsForm,
    @Optional() protected popupComponent: BasePopupComponent,
    protected projectTokenService: ProjectTokenService,
    protected intercomService: IntercomService,
    protected analyticsService: UniversalAnalyticsService,
    protected cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.optionsHidden = this.resource && this.resource.isStub();

    const existingToken = this.project.tokens.find(item => !item.activated);
    const token = existingToken ? existingToken.clone() : new ProjectToken();

    token.resourceType = this.typeItem.resourceType;
    token.resourceTypeItem = this.typeItem.name;

    this.loading = true;
    this.cd.markForCheck();

    this.form
      .init(
        this.project,
        this.environment,
        this.resource,
        this.typeItem,
        this.resourceNameEditing,
        this.optionsHidden,
        this.params
      )
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.loading = false;
        this.cd.markForCheck();

        this.onFormInit();
      });
  }

  ngOnDestroy(): void {}

  onFormInit(): void {}

  get defaultPlaceholder() {
    return this.optionsHidden ? '••••••••••••••••' : '';
  }

  close() {
    if (this.popupComponent) {
      this.popupComponent.close();
    }
  }

  submit(paramsOptions?: O) {
    if (this.slowLoadingSubscription) {
      this.slowLoadingSubscription.unsubscribe();
    }

    this.loadingSubmit = true;
    this.cd.markForCheck();

    this.slowLoadingSubscription = timer(this.slowLoadingDuration)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.slowLoading = true;
        this.cd.markForCheck();
      });

    this.form
      .submit(paramsOptions)
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          this.close();
          this.saved.emit(result);

          if (this.slowLoadingSubscription) {
            this.slowLoadingSubscription.unsubscribe();
          }
        },
        error => {
          console.error(error);
          this.loadingSubmit = false;
          this.slowLoading = false;
          this.cd.markForCheck();

          if (this.slowLoadingSubscription) {
            this.slowLoadingSubscription.unsubscribe();
          }

          const errorMessage = error instanceof ServerRequestError && error.errors.length ? error.errors[0] : error;

          if (this.resource) {
            this.analyticsService.sendSimpleEvent(AnalyticsEvent.Resource.ResourceUpdateFailed, {
              Source: this.analyticsSource,
              ResourceID: this.typeItem.name,
              Error: errorMessage
            });
          } else {
            this.analyticsService.sendSimpleEvent(AnalyticsEvent.Resource.ResourceAddFailed, {
              Source: this.analyticsSource,
              ResourceID: this.typeItem.name,
              Error: errorMessage
            });
          }
        }
      );
  }

  openChat() {
    this.intercomService.openChat();
  }

  cancel() {
    this.canceled.emit();
    this.close();
  }

  requestDelete() {
    this.deleteRequested.emit();
    this.close();
  }
}
