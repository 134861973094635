<div class="kanban-board">
  <div
    *ngFor="
      let items of [
        [1, 2, 3],
        [1, 2, 3],
        [1, 2]
      ]
    "
    [style.width.%]="100 / 3"
    class="kanban-board__column"
  >
    <div class="kanban-board-column" [class.kanban-board-column_theme]="theme">
      <div class="kanban-board-column__header">
        <div class="kanban-board-column__header-main">
          <div class="kanban-board-column__title">
            <span [class.loading-animation]="animating"><span class="stub-text">Column</span></span>
          </div>
        </div>
      </div>
      <div class="kanban-board-column__scrollable">
        <div class="kanban-board-column__content">
          <app-kanban-board-stub-content
            [items]="items"
            [animating]="animating"
            [theme]="theme"
          ></app-kanban-board-stub-content>
        </div>
      </div>
    </div>
  </div>
</div>
