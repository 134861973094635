import { ActionElementStyles, ElementWrapperStyles, FieldElementStyles, Margin, TextStyle } from '@modules/customize';
import { ProjectSettings, ProjectSettingsName } from '@modules/projects';
import { BorderRadius } from '@modules/theme';
import { Workflow } from '@modules/workflow';
import { isSet } from '@shared';

import { HomeType } from './home-type';
import { SignUpField } from './sign-up-field';

export interface CrispIntegration {
  id?: string;
}

export interface DriftIntegration {
  id?: string;
}

export interface FacebookPixelIntegration {
  id?: string;
}

export interface GoogleAnalyticsIntegration {
  id?: string;
}

export interface GoogleTagManagerIntegration {
  id?: string;
}

export interface HotjarIntegration {
  site_id?: string;
}

export interface HubspotIntegration {
  id?: string;
}

export interface IntercomIntegration {
  workspace_id?: string;
}

export interface SettingType {
  name: ProjectSettingsName;
  properties: (keyof AllProjectSettings)[];
  deserializeValue: (value: Object) => void;
  serializeValue: () => Object;
}

export class AllProjectSettings {
  public defaultTheme: string;
  public accentColor: string;
  public accentColorDark: string;
  public backgroundColor: string;
  public backgroundColorDark: string;
  public backgroundColor2: string;
  public backgroundColor2Dark: string;
  public backgroundColor3: string;
  public backgroundColor3Dark: string;
  public backgroundColor4: string;
  public backgroundColor4Dark: string;
  public backgroundColor5: string;
  public backgroundColor5Dark: string;
  public textColor: string;
  public textColorDark: string;
  public textColor2: string;
  public textColor2Dark: string;
  public textColor3: string;
  public textColor3Dark: string;
  public borderColor: string;
  public borderColorDark: string;
  public borderColor2: string;
  public borderColor2Dark: string;
  public borderColor3: string;
  public borderColor3Dark: string;
  public borderRadius: BorderRadius;
  public autoColors: string[] = [];
  public autoColorsDark: string[] = [];
  public maxWidth: number;
  public padding: Margin = {};
  public fontRegular: string;
  public fontHeading: string;
  public fontsUsed: string[] = [];
  public appendScripts: string;
  public appendStyles: string;
  public collaborationEnabled = true; // TODO: Remove unused
  public activityLogEnabled = true; // TODO: Remove unused
  public signUpFields: SignUpField[] = [];
  public homeType: HomeType = HomeType.FirstMenuItem;
  public homePageUid: string;
  public homeWorkflow: Workflow;
  public crisp: CrispIntegration;
  public facebookPixel: FacebookPixelIntegration;
  public drift: DriftIntegration;
  public googleAnalytics: GoogleAnalyticsIntegration;
  public googleTagManager: GoogleTagManagerIntegration;
  public hubspot: HubspotIntegration;
  public intercom: IntercomIntegration;
  public hotjar: HotjarIntegration;
  // public regularTitleTextStyle: TextStyle;
  // public fieldLabelTextStyle: TextStyle;
  // public fieldLabelAdditionalTextStyle: TextStyle;
  // public listGroupTitleTextStyle: TextStyle;
  // public tableGroupTitleTextStyle: TextStyle;
  // public kanbanStageTitleTextStyle: TextStyle;
  // public valueWidgetTitleTextStyle: TextStyle;
  // public valueWidgetValueTextStyle: TextStyle;
  // public collapseTitleTextStyle: TextStyle;
  public actionElementStylesPrimary: ActionElementStyles;
  public actionElementStylesDefault: ActionElementStyles;
  public actionElementStylesTransparent: ActionElementStyles;
  public fieldElementStyles: FieldElementStyles;
  public elementWrapperStyles: ElementWrapperStyles;

  settingsTypes: SettingType[] = [
    {
      name: ProjectSettingsName.DefaultTheme,
      properties: ['defaultTheme'],
      deserializeValue: value => {
        if (value) {
          this.defaultTheme = value['value'];
        }
      },
      serializeValue: () => (isSet(this.defaultTheme) ? { value: this.defaultTheme } : undefined)
    },
    {
      name: ProjectSettingsName.AccentColor,
      properties: ['accentColor'],
      deserializeValue: value => {
        if (value) {
          this.accentColor = value['value'];
        }
      },
      serializeValue: () => (isSet(this.accentColor) ? { value: this.accentColor } : undefined)
    },
    {
      name: ProjectSettingsName.AccentColorDark,
      properties: ['accentColorDark'],
      deserializeValue: value => {
        if (value) {
          this.accentColorDark = value['value'];
        }
      },
      serializeValue: () => (isSet(this.accentColorDark) ? { value: this.accentColorDark } : undefined)
    },
    {
      name: ProjectSettingsName.BackgroundColor,
      properties: ['backgroundColor'],
      deserializeValue: value => {
        if (value) {
          this.backgroundColor = value['value'];
        }
      },
      serializeValue: () => (isSet(this.backgroundColor) ? { value: this.backgroundColor } : undefined)
    },
    {
      name: ProjectSettingsName.BackgroundColorDark,
      properties: ['backgroundColorDark'],
      deserializeValue: value => {
        if (value) {
          this.backgroundColorDark = value['value'];
        }
      },
      serializeValue: () => (isSet(this.backgroundColorDark) ? { value: this.backgroundColorDark } : undefined)
    },
    {
      name: ProjectSettingsName.BackgroundColor2,
      properties: ['backgroundColor2'],
      deserializeValue: value => {
        if (value) {
          this.backgroundColor2 = value['value'];
        }
      },
      serializeValue: () => (isSet(this.backgroundColor2) ? { value: this.backgroundColor2 } : undefined)
    },
    {
      name: ProjectSettingsName.BackgroundColor2Dark,
      properties: ['backgroundColor2Dark'],
      deserializeValue: value => {
        if (value) {
          this.backgroundColor2Dark = value['value'];
        }
      },
      serializeValue: () => (isSet(this.backgroundColor2Dark) ? { value: this.backgroundColor2Dark } : undefined)
    },
    {
      name: ProjectSettingsName.BackgroundColor3,
      properties: ['backgroundColor3'],
      deserializeValue: value => {
        if (value) {
          this.backgroundColor3 = value['value'];
        }
      },
      serializeValue: () => (isSet(this.backgroundColor3) ? { value: this.backgroundColor3 } : undefined)
    },
    {
      name: ProjectSettingsName.BackgroundColor3Dark,
      properties: ['backgroundColor3Dark'],
      deserializeValue: value => {
        if (value) {
          this.backgroundColor3Dark = value['value'];
        }
      },
      serializeValue: () => (isSet(this.backgroundColor3Dark) ? { value: this.backgroundColor3Dark } : undefined)
    },
    {
      name: ProjectSettingsName.BackgroundColor4,
      properties: ['backgroundColor4'],
      deserializeValue: value => {
        if (value) {
          this.backgroundColor4 = value['value'];
        }
      },
      serializeValue: () => (isSet(this.backgroundColor4) ? { value: this.backgroundColor4 } : undefined)
    },
    {
      name: ProjectSettingsName.BackgroundColor4Dark,
      properties: ['backgroundColor4Dark'],
      deserializeValue: value => {
        if (value) {
          this.backgroundColor4Dark = value['value'];
        }
      },
      serializeValue: () => (isSet(this.backgroundColor4Dark) ? { value: this.backgroundColor4Dark } : undefined)
    },
    {
      name: ProjectSettingsName.BackgroundColor5,
      properties: ['backgroundColor5'],
      deserializeValue: value => {
        if (value) {
          this.backgroundColor5 = value['value'];
        }
      },
      serializeValue: () => (isSet(this.backgroundColor5) ? { value: this.backgroundColor5 } : undefined)
    },
    {
      name: ProjectSettingsName.BackgroundColor5Dark,
      properties: ['backgroundColor5Dark'],
      deserializeValue: value => {
        if (value) {
          this.backgroundColor5Dark = value['value'];
        }
      },
      serializeValue: () => (isSet(this.backgroundColor5Dark) ? { value: this.backgroundColor5Dark } : undefined)
    },
    {
      name: ProjectSettingsName.TextColor,
      properties: ['textColor'],
      deserializeValue: value => {
        if (value) {
          this.textColor = value['value'];
        }
      },
      serializeValue: () => (isSet(this.textColor) ? { value: this.textColor } : undefined)
    },
    {
      name: ProjectSettingsName.TextColorDark,
      properties: ['textColorDark'],
      deserializeValue: value => {
        if (value) {
          this.textColorDark = value['value'];
        }
      },
      serializeValue: () => (isSet(this.textColorDark) ? { value: this.textColorDark } : undefined)
    },
    {
      name: ProjectSettingsName.TextColor2,
      properties: ['textColor2'],
      deserializeValue: value => {
        if (value) {
          this.textColor2 = value['value'];
        }
      },
      serializeValue: () => (isSet(this.textColor2) ? { value: this.textColor2 } : undefined)
    },
    {
      name: ProjectSettingsName.TextColor2Dark,
      properties: ['textColor2Dark'],
      deserializeValue: value => {
        if (value) {
          this.textColor2Dark = value['value'];
        }
      },
      serializeValue: () => (isSet(this.textColor2Dark) ? { value: this.textColor2Dark } : undefined)
    },
    {
      name: ProjectSettingsName.TextColor3,
      properties: ['textColor3'],
      deserializeValue: value => {
        if (value) {
          this.textColor3 = value['value'];
        }
      },
      serializeValue: () => (isSet(this.textColor3) ? { value: this.textColor3 } : undefined)
    },
    {
      name: ProjectSettingsName.TextColor3Dark,
      properties: ['textColor3Dark'],
      deserializeValue: value => {
        if (value) {
          this.textColor3Dark = value['value'];
        }
      },
      serializeValue: () => (isSet(this.textColor3Dark) ? { value: this.textColor3Dark } : undefined)
    },
    {
      name: ProjectSettingsName.BorderColor,
      properties: ['borderColor'],
      deserializeValue: value => {
        if (value) {
          this.borderColor = value['value'];
        }
      },
      serializeValue: () => (isSet(this.borderColor) ? { value: this.borderColor } : undefined)
    },
    {
      name: ProjectSettingsName.BorderColorDark,
      properties: ['borderColorDark'],
      deserializeValue: value => {
        if (value) {
          this.borderColorDark = value['value'];
        }
      },
      serializeValue: () => (isSet(this.borderColorDark) ? { value: this.borderColorDark } : undefined)
    },
    {
      name: ProjectSettingsName.BorderColor2,
      properties: ['borderColor2'],
      deserializeValue: value => {
        if (value) {
          this.borderColor2 = value['value'];
        }
      },
      serializeValue: () => (isSet(this.borderColor2) ? { value: this.borderColor2 } : undefined)
    },
    {
      name: ProjectSettingsName.BorderColor2Dark,
      properties: ['borderColor2Dark'],
      deserializeValue: value => {
        if (value) {
          this.borderColor2Dark = value['value'];
        }
      },
      serializeValue: () => (isSet(this.borderColor2Dark) ? { value: this.borderColor2Dark } : undefined)
    },
    {
      name: ProjectSettingsName.BorderColor3,
      properties: ['borderColor3'],
      deserializeValue: value => {
        if (value) {
          this.borderColor3 = value['value'];
        }
      },
      serializeValue: () => (isSet(this.borderColor3) ? { value: this.borderColor3 } : undefined)
    },
    {
      name: ProjectSettingsName.BorderColor3Dark,
      properties: ['borderColor3Dark'],
      deserializeValue: value => {
        if (value) {
          this.borderColor3Dark = value['value'];
        }
      },
      serializeValue: () => (isSet(this.borderColor3Dark) ? { value: this.borderColor3Dark } : undefined)
    },
    {
      name: ProjectSettingsName.BorderRadius,
      properties: ['borderRadius'],
      deserializeValue: value => {
        if (value) {
          this.borderRadius = value['value'];
        }
      },
      serializeValue: () => (isSet(this.borderRadius) ? { value: this.borderRadius } : undefined)
    },
    {
      name: ProjectSettingsName.AutoColors,
      properties: ['autoColors'],
      deserializeValue: value => {
        if (value) {
          this.autoColors = value['value'];
        }
      },
      serializeValue: () => (isSet(this.autoColors) ? { value: this.autoColors } : undefined)
    },
    {
      name: ProjectSettingsName.AutoColorsDark,
      properties: ['autoColorsDark'],
      deserializeValue: value => {
        if (value) {
          this.autoColorsDark = value['value'];
        }
      },
      serializeValue: () => (isSet(this.autoColorsDark) ? { value: this.autoColorsDark } : undefined)
    },
    {
      name: ProjectSettingsName.MaxWidth,
      properties: ['maxWidth'],
      deserializeValue: value => {
        if (value) {
          this.maxWidth = value['value'];
        }
      },
      serializeValue: () => (isSet(this.maxWidth) ? { value: this.maxWidth } : undefined)
    },
    {
      name: ProjectSettingsName.Padding,
      properties: ['padding'],
      deserializeValue: value => {
        if (value) {
          this.padding = value['value'];
        }
      },
      serializeValue: () => (isSet(this.padding) ? { value: this.padding } : undefined)
    },
    {
      name: ProjectSettingsName.FontRegular,
      properties: ['fontRegular'],
      deserializeValue: value => {
        if (value) {
          this.fontRegular = value['value'];
        }
      },
      serializeValue: () => (isSet(this.fontRegular) ? { value: this.fontRegular } : undefined)
    },
    {
      name: ProjectSettingsName.FontHeading,
      properties: ['fontHeading'],
      deserializeValue: value => {
        if (value) {
          this.fontHeading = value['value'];
        }
      },
      serializeValue: () => (isSet(this.fontHeading) ? { value: this.fontHeading } : undefined)
    },
    {
      name: ProjectSettingsName.FontsUsed,
      properties: ['fontsUsed'],
      deserializeValue: value => {
        if (value) {
          this.fontsUsed = value['value'] || [];
        }
      },
      serializeValue: () => ({ value: this.fontsUsed })
    },
    {
      name: ProjectSettingsName.AppendScripts,
      properties: ['appendScripts'],
      deserializeValue: value => {
        if (value) {
          this.appendScripts = value['value'];
        }
      },
      serializeValue: () => (isSet(this.appendScripts) ? { value: this.appendScripts } : undefined)
    },
    {
      name: ProjectSettingsName.AppendStyles,
      properties: ['appendStyles'],
      deserializeValue: value => {
        if (value) {
          this.appendStyles = value['value'];
        }
      },
      serializeValue: () => (isSet(this.appendStyles) ? { value: this.appendStyles } : undefined)
    },
    {
      name: ProjectSettingsName.CollaborationEnabled,
      properties: ['collaborationEnabled'],
      deserializeValue: value => {
        if (value) {
          this.collaborationEnabled = value['value'];
        }
      },
      serializeValue: () => (isSet(this.collaborationEnabled) ? { value: this.collaborationEnabled } : undefined)
    },
    {
      name: ProjectSettingsName.ActivityLogEnabled,
      properties: ['activityLogEnabled'],
      deserializeValue: value => {
        if (value) {
          this.activityLogEnabled = value['value'];
        }
      },
      serializeValue: () => (isSet(this.activityLogEnabled) ? { value: this.activityLogEnabled } : undefined)
    },
    {
      name: ProjectSettingsName.SignUpFields,
      properties: ['signUpFields'],
      deserializeValue: value => {
        if (value) {
          this.signUpFields = value['fields'] ? value['fields'].map(item => new SignUpField().deserialize(item)) : [];
        }
      },
      serializeValue: () => ({ fields: this.signUpFields.map(item => item.serialize()) })
    },
    {
      name: ProjectSettingsName.Home,
      properties: ['homeType', 'homePageUid', 'homeWorkflow'],
      deserializeValue: value => {
        if (value) {
          this.homeType = value['type'] ? value['type'] : HomeType.FirstMenuItem;
          this.homePageUid = value['page'];
          this.homeWorkflow = value['workflow'] ? new Workflow().deserialize(value['workflow']) : undefined;
        }
      },
      serializeValue: () => ({
        type: this.homeType,
        page: this.homePageUid,
        workflow: this.homeWorkflow ? this.homeWorkflow.serialize() : undefined
      })
    },
    {
      name: ProjectSettingsName.Crisp,
      properties: ['crisp'],
      deserializeValue: value => {
        if (value) {
          this.crisp = value as CrispIntegration;
        }
      },
      serializeValue: () => this.crisp
    },
    {
      name: ProjectSettingsName.Drift,
      properties: ['drift'],
      deserializeValue: value => {
        if (value) {
          this.drift = value as DriftIntegration;
        }
      },
      serializeValue: () => this.drift
    },
    {
      name: ProjectSettingsName.FacebookPixel,
      properties: ['facebookPixel'],
      deserializeValue: value => {
        if (value) {
          this.facebookPixel = value as FacebookPixelIntegration;
        }
      },
      serializeValue: () => this.facebookPixel
    },
    {
      name: ProjectSettingsName.GoogleAnalytics,
      properties: ['googleAnalytics'],
      deserializeValue: value => {
        if (value) {
          this.googleAnalytics = value as GoogleAnalyticsIntegration;
        }
      },
      serializeValue: () => this.googleAnalytics
    },
    {
      name: ProjectSettingsName.GoogleTagManager,
      properties: ['googleTagManager'],
      deserializeValue: value => {
        if (value) {
          this.googleTagManager = value as GoogleTagManagerIntegration;
        }
      },
      serializeValue: () => this.googleTagManager
    },
    {
      name: ProjectSettingsName.Hotjar,
      properties: ['hotjar'],
      deserializeValue: value => {
        if (value) {
          this.hotjar = value as HotjarIntegration;
        }
      },
      serializeValue: () => this.hotjar
    },
    {
      name: ProjectSettingsName.Hubspot,
      properties: ['hubspot'],
      deserializeValue: value => {
        if (value) {
          this.hubspot = value as HubspotIntegration;
        }
      },
      serializeValue: () => this.hubspot
    },
    {
      name: ProjectSettingsName.Intercom,
      properties: ['intercom'],
      deserializeValue: value => {
        if (value) {
          this.intercom = value as IntercomIntegration;
        }
      },
      serializeValue: () => this.intercom
    },
    // {
    //   name: ProjectSettingsName.RegularTitleTextStyle,
    //   properties: ['regularTitleTextStyle'],
    //   deserializeValue: value => {
    //     if (value) {
    //       this.regularTitleTextStyle = new TextStyle().deserialize(value);
    //     }
    //   },
    //   serializeValue: () => {
    //     return this.regularTitleTextStyle ? this.regularTitleTextStyle.serialize() : undefined;
    //   }
    // },
    // {
    //   name: ProjectSettingsName.FieldLabelTextStyle,
    //   properties: ['fieldLabelTextStyle'],
    //   deserializeValue: value => {
    //     if (value) {
    //       this.fieldLabelTextStyle = new TextStyle().deserialize(value);
    //     }
    //   },
    //   serializeValue: () => {
    //     return this.fieldLabelTextStyle ? this.fieldLabelTextStyle.serialize() : undefined;
    //   }
    // },
    // {
    //   name: ProjectSettingsName.FieldLabelAdditionalTextStyle,
    //   properties: ['fieldLabelAdditionalTextStyle'],
    //   deserializeValue: value => {
    //     if (value) {
    //       this.fieldLabelAdditionalTextStyle = new TextStyle().deserialize(value);
    //     }
    //   },
    //   serializeValue: () => {
    //     return this.fieldLabelAdditionalTextStyle ? this.fieldLabelAdditionalTextStyle.serialize() : undefined;
    //   }
    // },
    // {
    //   name: ProjectSettingsName.ListGroupTitleTextStyle,
    //   properties: ['listGroupTitleTextStyle'],
    //   deserializeValue: value => {
    //     if (value) {
    //       this.listGroupTitleTextStyle = new TextStyle().deserialize(value);
    //     }
    //   },
    //   serializeValue: () => {
    //     return this.listGroupTitleTextStyle ? this.listGroupTitleTextStyle.serialize() : undefined;
    //   }
    // },
    // {
    //   name: ProjectSettingsName.TableGroupTitleTextStyle,
    //   properties: ['tableGroupTitleTextStyle'],
    //   deserializeValue: value => {
    //     if (value) {
    //       this.tableGroupTitleTextStyle = new TextStyle().deserialize(value);
    //     }
    //   },
    //   serializeValue: () => {
    //     return this.tableGroupTitleTextStyle ? this.tableGroupTitleTextStyle.serialize() : undefined;
    //   }
    // },
    // {
    //   name: ProjectSettingsName.KanbanStageTitleTextStyle,
    //   properties: ['kanbanStageTitleTextStyle'],
    //   deserializeValue: value => {
    //     if (value) {
    //       this.kanbanStageTitleTextStyle = new TextStyle().deserialize(value);
    //     }
    //   },
    //   serializeValue: () => {
    //     return this.kanbanStageTitleTextStyle ? this.kanbanStageTitleTextStyle.serialize() : undefined;
    //   }
    // },
    // {
    //   name: ProjectSettingsName.ValueWidgetTitleTextStyle,
    //   properties: ['valueWidgetTitleTextStyle'],
    //   deserializeValue: value => {
    //     if (value) {
    //       this.valueWidgetTitleTextStyle = new TextStyle().deserialize(value);
    //     }
    //   },
    //   serializeValue: () => {
    //     return this.valueWidgetTitleTextStyle ? this.valueWidgetTitleTextStyle.serialize() : undefined;
    //   }
    // },
    // {
    //   name: ProjectSettingsName.ValueWidgetValueTextStyle,
    //   properties: ['valueWidgetValueTextStyle'],
    //   deserializeValue: value => {
    //     if (value) {
    //       this.valueWidgetValueTextStyle = new TextStyle().deserialize(value);
    //     }
    //   },
    //   serializeValue: () => {
    //     return this.valueWidgetValueTextStyle ? this.valueWidgetValueTextStyle.serialize() : undefined;
    //   }
    // },
    // {
    //   name: ProjectSettingsName.CollapseTitleTextStyle,
    //   properties: ['collapseTitleTextStyle'],
    //   deserializeValue: value => {
    //     if (value) {
    //       this.collapseTitleTextStyle = new TextStyle().deserialize(value);
    //     }
    //   },
    //   serializeValue: () => {
    //     return this.collapseTitleTextStyle ? this.collapseTitleTextStyle.serialize() : undefined;
    //   }
    // },
    {
      name: ProjectSettingsName.ActionElementStylesPrimary,
      properties: ['actionElementStylesPrimary'],
      deserializeValue: value => {
        this.actionElementStylesPrimary = value ? new ActionElementStyles().deserialize(value) : undefined;
      },
      serializeValue: () => {
        return this.actionElementStylesPrimary ? this.actionElementStylesPrimary.serialize() : undefined;
      }
    },
    {
      name: ProjectSettingsName.ActionElementStylesDefault,
      properties: ['actionElementStylesDefault'],
      deserializeValue: value => {
        this.actionElementStylesDefault = value ? new ActionElementStyles().deserialize(value) : undefined;
      },
      serializeValue: () => {
        return this.actionElementStylesDefault ? this.actionElementStylesDefault.serialize() : undefined;
      }
    },
    {
      name: ProjectSettingsName.ActionElementStylesTransparent,
      properties: ['actionElementStylesTransparent'],
      deserializeValue: value => {
        this.actionElementStylesTransparent = value ? new ActionElementStyles().deserialize(value) : undefined;
      },
      serializeValue: () => {
        return this.actionElementStylesTransparent ? this.actionElementStylesTransparent.serialize() : undefined;
      }
    },
    {
      name: ProjectSettingsName.FieldElementStyles,
      properties: ['fieldElementStyles'],
      deserializeValue: value => {
        this.fieldElementStyles = value ? new FieldElementStyles().deserialize(value) : undefined;
      },
      serializeValue: () => {
        return this.fieldElementStyles ? this.fieldElementStyles.serialize() : undefined;
      }
    },
    {
      name: ProjectSettingsName.ElementWrapperStyles,
      properties: ['elementWrapperStyles'],
      deserializeValue: value => {
        this.elementWrapperStyles = value ? new ElementWrapperStyles().deserialize(value) : undefined;
      },
      serializeValue: () => {
        return this.elementWrapperStyles ? this.elementWrapperStyles.serialize() : undefined;
      }
    }
  ];

  constructor(options: Partial<AllProjectSettings> = {}) {
    Object.assign(this, options);
  }

  getProjectSettingsValue<T = { value: any }>(settings: ProjectSettings[], name: ProjectSettingsName): T {
    const setting = settings.find(item => item.name == name);
    return setting ? (setting.value as T) : undefined;
  }

  deserialize(settings: ProjectSettings[]): AllProjectSettings {
    this.settingsTypes.forEach(item => {
      const value = this.getProjectSettingsValue(settings, item.name);
      item.deserializeValue(value);
    });

    // Backward compatibility
    if (!isSet(this.accentColorDark) && isSet(this.accentColor)) {
      this.accentColorDark = this.accentColor;
    }

    return this;
  }

  serialize(names?: ProjectSettingsName[]): ProjectSettings[] {
    let settingsTypes = this.settingsTypes;

    if (names) {
      const settingsTypesByName = this.settingsTypes.reduce<{ [k: string]: SettingType }>((acc, item) => {
        if (!acc[item.name]) {
          acc[item.name] = item;
        }
        return acc;
      }, {});

      settingsTypes = names.map(item => settingsTypesByName[item]).filter(item => item);
    }

    return settingsTypes.map(item => {
      const value = item.serializeValue();

      return new ProjectSettings({
        name: item.name,
        value: value
      });
    });
  }
}
