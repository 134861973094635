/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../dropdown-options/dropdown-options.component.ngfactory";
import * as i2 from "@angular/cdk/observers";
import * as i3 from "../dropdown-options/dropdown-options.component";
import * as i4 from "@angular/cdk/overlay";
import * as i5 from "@angular/cdk/bidi";
import * as i6 from "./dropdown.component";
import * as i7 from "@angular/cdk/a11y";
var styles_DropdownComponent = [];
var RenderType_DropdownComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DropdownComponent, data: {} });
export { RenderType_DropdownComponent as RenderType_DropdownComponent };
function View_DropdownComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "overlay-content overlay-content_animated"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "app-dropdown-options", [], null, [[null, "valueSelected"], [null, "addValue"], [null, "rootClick"], [null, "cdkObserveContent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueSelected" === en)) {
        var pd_0 = (_co.onSelected($event) !== false);
        ad = (pd_0 && ad);
    } if (("addValue" === en)) {
        var pd_1 = (_co.addValue.emit($event) !== false);
        ad = (pd_1 && ad);
    } if (("rootClick" === en)) {
        var pd_2 = (_co.markDropdownMouseEvent($event) !== false);
        ad = (pd_2 && ad);
    } if (("cdkObserveContent" === en)) {
        var pd_3 = (_co.onContentChanged() !== false);
        ad = (pd_3 && ad);
    } return ad; }, i1.View_DropdownOptionsComponent_0, i1.RenderType_DropdownOptionsComponent)), i0.ɵdid(2, 1196032, null, 0, i2.CdkObserveContent, [i2.ContentObserver, i0.ElementRef, i0.NgZone], null, { event: "cdkObserveContent" }), i0.ɵdid(3, 770048, null, 0, i3.DropdownOptionsComponent, [i0.ChangeDetectorRef], { source: [0, "source"], options: [1, "options"], selectedValues: [2, "selectedValues"], compareWith: [3, "compareWith"], displaySelected: [4, "displaySelected"], emptyPlaceholder: [5, "emptyPlaceholder"], searchEnabled: [6, "searchEnabled"], searchAutofocus: [7, "searchAutofocus"], searchExternalControl: [8, "searchExternalControl"], searchDebounce: [9, "searchDebounce"], addValueEnabled: [10, "addValueEnabled"], theme: [11, "theme"] }, { valueSelected: "valueSelected", addValue: "addValue", rootClick: "rootClick" }), (_l()(), i0.ɵeld(4, 0, null, 0, 1, null, null, null, null, null, null, null)), i0.ɵncd(null, 0), (_l()(), i0.ɵeld(6, 0, null, 1, 1, null, null, null, null, null, null, null)), i0.ɵncd(null, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.source; var currVal_1 = _co.options; var currVal_2 = _co.selectedValues; var currVal_3 = _co.compareWith; var currVal_4 = _co.displaySelected; var currVal_5 = _co.emptyPlaceholder; var currVal_6 = _co.searchEnabled; var currVal_7 = _co.searchAutofocus; var currVal_8 = _co.searchExternalControl; var currVal_9 = _co.searchDebounce; var currVal_10 = _co.addValueEnabled; var currVal_11 = _co.theme; _ck(_v, 3, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11]); }, null); }
export function View_DropdownComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(402653184, 1, { overlay: 0 }), i0.ɵqud(402653184, 2, { overlayElement: 0 }), (_l()(), i0.ɵand(16777216, null, null, 2, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("backdropClick" === en)) {
        var pd_0 = (_co.setDropdownOpened(false) !== false);
        ad = (pd_0 && ad);
    } if (("detach" === en)) {
        var pd_1 = (_co.setDropdownOpened(false) !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_DropdownComponent_1)), i0.ɵdid(3, 671744, [[1, 4]], 0, i4.CdkConnectedOverlay, [i4.Overlay, i0.TemplateRef, i0.ViewContainerRef, i4.ɵc, [2, i5.Directionality]], { origin: [0, "origin"], positions: [1, "positions"], backdropClass: [2, "backdropClass"], panelClass: [3, "panelClass"], open: [4, "open"], hasBackdrop: [5, "hasBackdrop"], growAfterOpen: [6, "growAfterOpen"], push: [7, "push"] }, { backdropClick: "backdropClick", detach: "detach" }), i0.ɵpad(4, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.origin; var currVal_1 = _co.dropdownPositions; var currVal_2 = "popover2-backdrop"; var currVal_3 = _ck(_v, 4, 0, "overlay"); var currVal_4 = _co.dropdownOpened; var currVal_5 = _co.backdrop; var currVal_6 = true; var currVal_7 = true; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, null); }
export function View_DropdownComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-dropdown", [], null, null, null, View_DropdownComponent_0, RenderType_DropdownComponent)), i0.ɵdid(1, 4964352, null, 0, i6.DropdownComponent, [i7.FocusMonitor, i0.ChangeDetectorRef, i0.NgZone], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DropdownComponentNgFactory = i0.ɵccf("app-dropdown", i6.DropdownComponent, View_DropdownComponent_Host_0, { origin: "origin", openOnFocus: "openOnFocus", closeOnMouseDown: "closeOnMouseDown", source: "source", options: "options", selectedValues: "selectedValues", displaySelected: "displaySelected", compareWith: "compareWith", emptyPlaceholder: "emptyPlaceholder", searchEnabled: "searchEnabled", searchAutofocus: "searchAutofocus", searchExternalControl: "searchExternalControl", searchDebounce: "searchDebounce", addValueEnabled: "addValueEnabled", backdrop: "backdrop", closeOnSelect: "closeOnSelect", theme: "theme" }, { valueSelected: "valueSelected", addValue: "addValue" }, ["ng-container[dropdown-top]", "ng-container[dropdown-bottom]"]);
export { DropdownComponentNgFactory as DropdownComponentNgFactory };
