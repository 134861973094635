<div
  class="placeholder"
  [class.placeholder_root]="root"
  [class.placeholder_active]="customizeService.layoutEnabled$ | async"
  [class.placeholder_empty]="!elements.length"
  appDropList
  [appDropListDisabled]="!(customizeService.layoutEnabled$ | async)"
  [appDropListEnterPredicate]="canEnter"
  [appDropListData]="elements"
  [appDropListSwapDistance]="20"
  [appDropListAreaMargin]="[0, 0, dropListBottomMargin, 0]"
  [appDropListPreviewMissPointerStartDelta]="{ x: 20, y: -20 }"
  [appDropListOutsideIndicator]="true"
  (appDropListDragStarted)="updateElementStates()"
  (appDropListEntered)="updateElementStates()"
  (appDropListExited)="updateElementStates()"
  (appDropListSorted)="updateElementStates()"
  (appDropListDragged)="updateElementStatesOnStable()"
  (appDropListDropped)="dragDrop($event)"
  appElementGroupsContainer
>
  <app-auto-element
    *ngFor="let item of elements; let i = index; trackBy: trackElement"
    appDrag
    [appDragData]="item"
    [element]="item"
    [context]="context"
    [customizing]="customizeService.layoutEnabled$ | async"
    [accentColor]="'accentColor' | appThemeColorHexOption: true | async"
    (duplicatedRequested)="duplicateItem(i)"
    (replaceRequested)="replaceItem(i, $event)"
    (deleteRequested)="deleteItem(item)"
    (moveToRequested)="moveItemTo(item, $event)"
    (visibleUpdated)="updateElementStates()"
  >
    <app-element-placeholder-stub
      *appDragPlaceholder
      [elementType]="item.type"
      class="element-placeholder"
    ></app-element-placeholder-stub>

    <app-element-drag-preview *appDragPreview [element]="item"></app-element-drag-preview>
  </app-auto-element>

  <div class="placeholder__empty">
    <app-container-builder-stub
      *ngIf="customizeService.enabled$ | async"
      [fill]="fill"
      [templates]="templates"
      (templatesClick)="openPageTemplates()"
    ></app-container-builder-stub>

    <app-container-app-stub
      *ngIf="
        !(customizeService.enabled$ | async) &&
        (currentProjectStore.instance$ | async)?.hasEnvironmentCustomizationPermission(
          currentEnvironmentStore.instance$ | async
        )
      "
      (customizeClick)="openAppUrl()"
    ></app-container-app-stub>
  </div>
</div>
