import { Injectable, Injector } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

import { Input } from '@modules/fields';
import { SimpleMenuItem } from '@modules/menu';
import { FieldInputControl } from '@modules/parameters';

import { MenuItemActionControl } from './menu-item-action.control';

import {
  CustomizeBarPagesEditMenuItemArray,
  CustomizeBarPagesEditMenuItemControl,
  menuItemControls
} from './customize-bar-pages-edit.form';

@Injectable()
export class CustomizeBarPagesEditSimpleControl extends CustomizeBarPagesEditMenuItemControl {
  controls: {
    icon: FormControl;
    title: FormControl;
    action: MenuItemActionControl;
    children: CustomizeBarPagesEditMenuItemArray;
    visible_input: FieldInputControl;
  };

  constructor(private injector: Injector) {
    super({
      icon: new FormControl(''),
      title: new FormControl('New Page', Validators.required),
      action: MenuItemActionControl.inject(injector),
      children: CustomizeBarPagesEditMenuItemArray.inject(injector),
      visible_input: new FieldInputControl({ path: ['value'] })
    });
  }

  static inject(injector: Injector): CustomizeBarPagesEditSimpleControl {
    return Injector.create({
      providers: [
        {
          provide: CustomizeBarPagesEditSimpleControl,
          useFactory: () => {
            return new CustomizeBarPagesEditSimpleControl(injector);
          },
          deps: []
        }
      ],
      parent: injector
    }).get(CustomizeBarPagesEditSimpleControl);
  }

  get children() {
    return this.controls['children'] as CustomizeBarPagesEditMenuItemArray;
  }

  deserialize(item: SimpleMenuItem) {
    this.menuItem = item;

    this.controls.icon.patchValue(item.icon);
    this.controls.title.patchValue(item.title);
    this.controls.action.deserialize(item.action);
    this.children.deserialize(item.children);
    this.controls.visible_input.patchValue(item.visibleInput ? item.visibleInput.serializeWithoutPath() : {});

    this.markAsPristine();
  }

  serialize(): SimpleMenuItem {
    const instance = new SimpleMenuItem().deserialize(this.menuItem.serialize());

    instance.icon = this.controls.icon.value;
    instance.title = this.controls.title.value;
    instance.action = this.controls.action.serialize();
    instance.children = this.children.serialize();
    instance.visibleInput = this.controls.visible_input.value
      ? new Input().deserialize(this.controls.visible_input.value)
      : undefined;

    return instance;
  }
}

menuItemControls.push({ menuItem: SimpleMenuItem, control: CustomizeBarPagesEditSimpleControl });
