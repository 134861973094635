import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrorComponent implements OnInit {
  @Input() title: string;
  @Input() description: string;
  @Input() fill = false;
  @Input() compact = false;
  @Input() theme = false;
  @Input() button: string;
  @Input() buttonPrimary = true;
  @Input() buttonIcon: string;
  @Input() buttonDisabled = false;
  @Input() buttonLink: any[];
  @Output() buttonClicked = new EventEmitter<void>();

  constructor() {}

  ngOnInit() {}
}
