var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormGroup } from '@angular/forms';
import { CreateFormGenerator, DataSourceGeneratorService, UpdateFormGenerator, ViewSettingsGeneratorService } from '@modules/customize-generators';
import { ListLayoutType } from '@modules/layouts';
import { PageTemplatesGeneratorService } from '@modules/template-generators';
import { PageTemplatesItemFieldArray } from './page-templates-item-field.array';
export var validateFields = function (control) {
    if (!control.parent) {
        return;
    }
    if (!control.controls.some(function (fieldControl) { return fieldControl.controls.enabled.value; })) {
        return { required: true };
    }
};
var PageTemplatesItemSettingsForm = /** @class */ (function (_super) {
    __extends(PageTemplatesItemSettingsForm, _super);
    function PageTemplatesItemSettingsForm(pageTemplatesGeneratorService, dataSourceGeneratorService, viewSettingsGeneratorService, createFormGenerator, updateFormGenerator) {
        var _this = _super.call(this, {
            listFields: new PageTemplatesItemFieldArray([], validateFields),
            createFields: new PageTemplatesItemFieldArray([]),
            updateFields: new PageTemplatesItemFieldArray([]),
            detailFields: new PageTemplatesItemFieldArray([])
        }) || this;
        _this.pageTemplatesGeneratorService = pageTemplatesGeneratorService;
        _this.dataSourceGeneratorService = dataSourceGeneratorService;
        _this.viewSettingsGeneratorService = viewSettingsGeneratorService;
        _this.createFormGenerator = createFormGenerator;
        _this.updateFormGenerator = updateFormGenerator;
        return _this;
    }
    PageTemplatesItemSettingsForm.prototype.init = function (template, templateVariant, resource, modelDescription) {
        this.template = template;
        this.templateVariant = templateVariant;
        this.modelDescription = modelDescription;
        var layoutSettings = this.viewSettingsGeneratorService.layoutSettings.find(function (item) { return item.layout == ListLayoutType.Table; });
        var listFields = this.dataSourceGeneratorService.getModelColumns(modelDescription, layoutSettings.columns);
        var detailFields = this.dataSourceGeneratorService.getModelColumns(modelDescription);
        var createFields = this.createFormGenerator.getCreateParameters(resource, modelDescription).map(function (item) {
            return {
                name: item.name,
                verboseName: item.verboseName,
                field: item.field,
                params: item.params,
                visible: true
            };
        });
        var updateFields = this.updateFormGenerator.getUpdateParameters(resource, modelDescription).map(function (item) {
            return {
                name: item.name,
                verboseName: item.verboseName,
                field: item.field,
                params: item.params,
                visible: true
            };
        });
        this.controls.listFields.deserialize(listFields);
        this.controls.createFields.deserialize(createFields);
        this.controls.updateFields.deserialize(updateFields);
        this.controls.detailFields.deserialize(detailFields);
    };
    PageTemplatesItemSettingsForm.prototype.getTemplateSettings = function () {
        return {
            modelDescription: this.modelDescription,
            templateVariant: this.templateVariant,
            listFields: this.controls.listFields.getVisibleNames(),
            createFields: this.controls.createFields.getVisibleNames(),
            updateFields: this.controls.updateFields.getVisibleNames(),
            detailFields: this.controls.detailFields.getVisibleNames()
        };
    };
    PageTemplatesItemSettingsForm.prototype.createTemplatePages = function (idGenerator) {
        var settings = this.getTemplateSettings();
        return this.pageTemplatesGeneratorService.createTemplatePages(this.template, settings, {
            idGenerator: idGenerator
        });
    };
    PageTemplatesItemSettingsForm.prototype.submit = function () {
        var settings = this.getTemplateSettings();
        return this.pageTemplatesGeneratorService.applyTemplate(this.template, settings);
    };
    return PageTemplatesItemSettingsForm;
}(FormGroup));
export { PageTemplatesItemSettingsForm };
