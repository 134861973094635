/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./common/popups/components/popups/popups.component.ngfactory";
import * as i3 from "./common/popups/components/popups/popups.component";
import * as i4 from "./common/popups/services/popup/popup.service";
import * as i5 from "./common/notifications/components/notifications/notifications.component.ngfactory";
import * as i6 from "./common/notifications/components/notifications/notifications.component";
import * as i7 from "./common/notifications/services/notification/notification.service";
import * as i8 from "./common/page-loading/components/page-loader/page-loader.component.ngfactory";
import * as i9 from "./common/page-loading/components/page-loader/page-loader.component";
import * as i10 from "./common/page-loading/services/page-loading/page-loading.service";
import * as i11 from "./app.component";
import * as i12 from "@angular/service-worker";
import * as i13 from "./common/dialogs/services/dialog/dialog.service";
import * as i14 from "./modules/auth/services/auth/auth.service";
var styles_AppComponent = [];
var RenderType_AppComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [["class", "body__content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(2, 212992, null, 0, i1.RouterOutlet, [i1.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null), (_l()(), i0.ɵeld(3, 0, null, null, 1, "app-popups", [], null, null, null, i2.View_PopupsComponent_0, i2.RenderType_PopupsComponent)), i0.ɵdid(4, 245760, null, 0, i3.PopupsComponent, [i4.PopupService, i0.ChangeDetectorRef], null, null), (_l()(), i0.ɵeld(5, 0, null, null, 0, "div", [["class", "app-overlay-container cdk-overlay-container theme-enabled"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 2, "div", [["class", "app-notifications-container theme-enabled"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "app-notifications", [], null, null, null, i5.View_NotificationsComponent_0, i5.RenderType_NotificationsComponent)), i0.ɵdid(8, 4440064, null, 0, i6.NotificationsComponent, [i7.NotificationService, i0.ChangeDetectorRef], null, null), (_l()(), i0.ɵeld(9, 0, null, null, 1, "app-page-loader", [], null, null, null, i8.View_PageLoaderComponent_0, i8.RenderType_PageLoaderComponent)), i0.ɵdid(10, 4440064, null, 0, i9.PageLoaderComponent, [i10.PageLoadingService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); _ck(_v, 4, 0); _ck(_v, 8, 0); _ck(_v, 10, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i0.ɵdid(1, 4440064, null, 0, i11.AppComponent, [i0.ApplicationRef, i1.Router, [2, i12.SwUpdate], i10.PageLoadingService, i13.DialogService, i14.AuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i0.ɵccf("app-root", i11.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
