import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';

import { ThemeService } from '@modules/theme';
import { controlValue } from '@shared';

import { BackgroundSetValue } from '../text-editor/quill-background-set';
import { TextEditorSelectionOverlay } from '../text-editor/text-editor-selection-overlay';

@Component({
  selector: 'app-text-editor-background-picker',
  templateUrl: './text-editor-background-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextEditorBackgroundPickerComponent extends TextEditorSelectionOverlay<BackgroundSetValue>
  implements OnInit, OnDestroy {
  backgroundDefaultControl = new FormControl();
  backgroundDarkControl = new FormControl();

  constructor(public themeService: ThemeService) {
    super();
  }

  ngOnInit() {}

  ngOnDestroy(): void {}

  initValue() {
    if (this.value) {
      this.backgroundDefaultControl.patchValue(this.value.backgroundDefault);
      this.backgroundDarkControl.patchValue(this.value.backgroundDark);
    }

    combineLatest(controlValue<string>(this.backgroundDefaultControl), controlValue<string>(this.backgroundDarkControl))
      .pipe(untilDestroyed(this))
      .subscribe(([backgroundDefault, backgroundDark]) => {
        this.valueChange.emit({
          ...this.value,
          backgroundDefault: backgroundDefault,
          backgroundDark: backgroundDark
        });
      });
  }

  resetCurrentColor() {
    if (this.themeService.isDefaultTheme) {
      this.backgroundDefaultControl.patchValue(undefined);
    }

    if (this.themeService.isDarkTheme) {
      this.backgroundDarkControl.patchValue(undefined);
    }
  }
}
