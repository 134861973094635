var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { ParameterControl } from '@modules/fields';
import { ResourceControllerService } from '@modules/resources';
import { isSet } from '@shared';
var ImportModelsFieldControl = /** @class */ (function (_super) {
    __extends(ImportModelsFieldControl, _super);
    function ImportModelsFieldControl(resourceControllerService) {
        var _this = _super.call(this, {
            field: new ParameterControl(),
            source: new FormControl(null),
            active: new FormControl(true)
        }) || this;
        _this.resourceControllerService = resourceControllerService;
        _this.controls.source.valueChanges.pipe(untilDestroyed(_this)).subscribe(function (value) {
            if (!_this.modelDescription) {
                return;
            }
            var controller = _this.resourceControllerService.get(_this.resource.type);
            var modelCreateParameters = controller.getCreateParametersOrDefaults(_this.resource, _this.modelDescription);
            var parameter = isSet(value) ? modelCreateParameters.find(function (item) { return item.name == value; }) : undefined;
            if (parameter) {
                var params = {};
                if (parameter.params['options']) {
                    params['options'] = parameter.params['options'];
                }
                if (parameter.params['structure']) {
                    params['display_fields'] = parameter.params['display_fields'];
                    params['structure'] = parameter.params['structure'];
                }
                _this.controls.field.controls.field.patchValue(parameter.field);
                _this.controls.field.controls.params.patchValue(params);
            }
        });
        return _this;
    }
    ImportModelsFieldControl.prototype.ngOnDestroy = function () { };
    ImportModelsFieldControl.prototype.init = function (resource, modelDescription) {
        this.resource = resource;
        this.modelDescription = modelDescription;
        if (this.modelDescription) {
            this.controls.source.enable();
        }
        else {
            this.controls.source.disable();
        }
    };
    return ImportModelsFieldControl;
}(FormGroup));
export { ImportModelsFieldControl };
