<app-external-fonts [fonts]="externalFonts"></app-external-fonts>

<app-layout
  *ngIf="!domainLoading"
  class="theme-enabled"
  appDomainThemeOptions
  [domain]="domain"
  [layout]="layout"
  [backgroundImage]="domain?.signUpBackgroundImage"
  [backgroundColor]="domain?.signUpBackgroundColor"
  [fillScreen]="true"
>
  <app-page-header [marginSize]="'l'" [first]="layout.startsWith('poster_')">
    <app-page-logo [align]="'center'">
      <app-project-logo
        *ngIf="isWhiteLabel && domain && (domain.logo || domain.initials)"
        [color]="domain.logoColor ? domain.logoColor : ''"
        [logo]="domain.logo ? domain.logo : ''"
        [initials]="domain.initials ? domain.initials : ''"
      ></app-project-logo>

      <app-project-logo
        *ngIf="!isWhiteLabel"
        [logo]="'/assets/images/logo.svg' | appDeployUrl"
        [size]="'sm'"
      ></app-project-logo>
    </app-page-logo>

    <app-page-title [align]="'center'" [strong]="true">
      Email Verification
    </app-page-title>
  </app-page-header>

  <app-page-block *ngIf="loading">
    <app-loader [title]="'Loading'">Please wait...</app-loader>
  </app-page-block>

  <ng-container *ngIf="!loading && correctRequest">
    <ng-container *ngIf="!emailVerified && verifyError">
      <app-page-block>
        <div class="auth-form__text auth-form__text_bigger">
          {{ verifyError }}
        </div>
      </app-page-block>

      <app-page-block *ngIf="authorized">
        <button
          *ngIf="!resent"
          type="button"
          class="button button_primary button_shadow button_bigger button_fill button_no-margin"
          (click)="resend()"
        >
          <span class="button__label">Resend verification code</span>
        </button>

        <button
          *ngIf="resent"
          type="button"
          class="button button_primary button_shadow button_bigger button_fill button_disabled button_no-margin"
        >
          <span class="button__label">Verification email sent</span>
        </button>
      </app-page-block>
    </ng-container>

    <app-page-block *ngIf="emailVerified && verifyError">
      <div class="auth-form__text auth-form__text_bigger">
        {{ verifyError }}
      </div>
    </app-page-block>

    <app-page-block *ngIf="emailVerified && !verifyError">
      <div class="auth-form__text auth-form__text_bigger">
        Your Email is now verified
      </div>
    </app-page-block>
  </ng-container>

  <app-page-block *ngIf="!loading && !correctRequest">
    <div class="auth-form__text auth-form__text_bigger">
      Request is incorrect
    </div>
  </app-page-block>

  <ng-template #home_label>
    <ng-container *ngIf="isWhiteLabel">
      <ng-container *ngIf="domain?.name">Go to {{ domain.name }}</ng-container>
      <ng-container *ngIf="!domain?.name">Home</ng-container>
    </ng-container>
    <ng-container *ngIf="!isWhiteLabel || !domain?.name">Go to Jet Admin</ng-container>
  </ng-template>

  <ng-container *ngIf="layout == 'form'" data-layout-footer>
    <a [routerLink]="['/projects']" class="link-block form-layout__footer-item">
      <ng-container *ngTemplateOutlet="home_label"></ng-container>
    </a>
  </ng-container>

  <app-page-block *ngIf="layout != 'form'" [marginSize]="'l'" data-layout-footer>
    <a [routerLink]="['/projects']" class="link-block">
      <ng-container *ngTemplateOutlet="home_label"></ng-container>
    </a>
  </app-page-block>
</app-layout>

<app-custom-code
  *ngIf="domain"
  [appendScripts]="domain.appendScripts"
  [appendStyles]="domain.appendStyles"
></app-custom-code>
