import { Overlay } from '@angular/cdk/overlay';
import { CdkPortalOutlet } from '@angular/cdk/portal';
import { AfterViewInit, ChangeDetectorRef, ElementRef, EventEmitter, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { scaleBand } from 'd3-scale';
import { pointer, select } from 'd3-selection';
import { arc, pie } from 'd3-shape';
import clamp from 'lodash/clamp';
import range from 'lodash/range';
import * as moment from 'moment';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { skip } from 'rxjs/operators';
import { CHART_COLORS, getDatasetsGroupLookup, getDateFormatByLookup, prepareDataset, sortDatasetsByValue } from '@modules/charts';
import { degToRad, elementSize$, generateAlphanumeric, getCircleIndex, isSet, TypedChanges } from '@shared';
// TODO: Refactor import
import { getColorHex, getColorHexStr, parseColor } from '../../../colors/utils/colors';
import { DataTooltipController } from '../../services/data-tooltip-controller/data-tooltip.controller';
function isDatasetGroupEqual(lhs, rhs) {
    return lhs.datasetIndex == rhs.datasetIndex && lhs.groupIndex == rhs.groupIndex;
}
var PieChart2Component = /** @class */ (function () {
    function PieChart2Component(el, overlay, dataTooltip, cd) {
        this.el = el;
        this.overlay = overlay;
        this.dataTooltip = dataTooltip;
        this.cd = cd;
        this.datasets = [];
        this.doughnut = false;
        this.defaultColors = CHART_COLORS;
        this.animate = true;
        this.legend = true;
        this.gradient = true;
        this.interactive = true;
        this.datasetBackground = true;
        this.dataClickEnabled = false;
        // @Input() width = 3;
        this.trackItem = false;
        this.theme = false;
        this.itemEnter = new EventEmitter();
        this.itemLeave = new EventEmitter();
        this.dataClick = new EventEmitter();
        this.data = [];
        this.margin = { top: 0, right: 0, bottom: 0, left: 0 };
        this.hoverDatasetGroup$ = new BehaviorSubject(undefined);
        this.hoverLegendDatasetGroup$ = new BehaviorSubject(undefined);
        this.uid = generateAlphanumeric(8);
    }
    PieChart2Component.prototype.getId = function (name) {
        return name + "-" + this.uid;
    };
    PieChart2Component.prototype.ngOnInit = function () { };
    PieChart2Component.prototype.ngOnDestroy = function () { };
    PieChart2Component.prototype.ngOnChanges = function (changes) {
        if (changes.datasets) {
            this.data = this.datasets.map(function (dataset) { return prepareDataset(dataset); });
            this.dataGroupLookup = getDatasetsGroupLookup(this.data);
            sortDatasetsByValue(this.data);
        }
        if (this.svg) {
            this.rerender();
        }
    };
    PieChart2Component.prototype.ngAfterViewInit = function () {
        var _this = this;
        setTimeout(function () {
            _this.init();
            elementSize$(_this.canvasElement.nativeElement)
                .pipe(skip(1), untilDestroyed(_this))
                .subscribe(function () { return _this.onResize(); });
        }, 0);
    };
    PieChart2Component.prototype.init = function () {
        this.initBounds();
        this.initSvg();
        this.renderPie();
        this.renderGradients();
        this.initDatasetHover();
    };
    PieChart2Component.prototype.initBounds = function () {
        var width = this.canvasElement.nativeElement.offsetWidth;
        var height = this.canvasElement.nativeElement.offsetHeight;
        this.width = width - this.margin.left - this.margin.right;
        this.height = height - this.margin.top - this.margin.bottom;
    };
    PieChart2Component.prototype.initSvg = function () {
        this.svg = select(this.svgElement.nativeElement)
            .attr('width', '100%')
            .attr('height', '100%')
            .append('g')
            .attr('transform', "translate(" + this.margin.left + "," + this.margin.top + ")");
    };
    PieChart2Component.prototype.renderPie = function () {
        var _this = this;
        var maxRadius = Math.min(this.width, this.height) / 2;
        var pieGenerator = pie().value(function (d) { return d.group.value; });
        var innerRadius = this.doughnut ? maxRadius * 0.5 : 3;
        var xScale = scaleBand()
            .domain(this.data.map(function (item, i) { return i.toString(); }))
            .range([0, maxRadius - innerRadius])
            .paddingInner(0.05)
            .align(0);
        this.svg
            .selectAll('.chart-pie')
            .data(this.data)
            .join('g')
            .attr('class', 'chart-pie')
            .attr('transform', "translate(" + this.width / 2 + ", " + this.height / 2 + ")")
            .selectAll('.chart-pie-group')
            .data(function (dataset, datasetIndex) {
            var groups = dataset.dataset
                .map(function (item, i) { return ({ group: item, groupIndex: i }); })
                .filter(function (item, groupIndex) {
                return _this.isVisibleDatasetGroup({ datasetIndex: datasetIndex, groupIndex: groupIndex });
            });
            return pieGenerator(groups).map(function (item) {
                return {
                    datasetIndex: datasetIndex,
                    groupIndex: item.data.groupIndex,
                    pieData: item
                };
            });
        })
            .join('path')
            .attr('class', function (d) {
            var classes = ['chart-pie-group', "chart-pie-group_index-" + d.datasetIndex + "-" + d.groupIndex];
            if (_this.dataClickEnabled) {
                classes.push('chart-pie-group_clickable');
            }
            return classes.join(' ');
        })
            .attr('d', function (d) {
            var x = xScale(d.datasetIndex.toString());
            var arcGenerator = arc()
                .innerRadius(innerRadius + x)
                .outerRadius(innerRadius + x + xScale.bandwidth())
                .cornerRadius(4)
                .padAngle(function (item) {
                var arcAngle = item.endAngle - item.startAngle;
                return clamp(degToRad(1.5), 0, arcAngle * 0.5);
            });
            return arcGenerator(d.pieData);
        })
            .attr('fill', function (d) {
            var pieGradient = _this.getId("pie-gradient-" + d.groupIndex);
            return "url(#" + pieGradient + ")";
        })
            .on('mouseenter', function (e, d) {
            return _this.onMouseEnter({
                datasetIndex: d.datasetIndex,
                group: _this.data[d.datasetIndex].dataset[d.groupIndex].group,
                groupIndex: d.groupIndex,
                value: _this.data[d.datasetIndex].dataset[d.groupIndex].value,
                event: e
            });
        })
            .on('mousemove', function (e) { return _this.onMouseMove(e); })
            .on('mouseleave', function () { return _this.onMouseLeave(); })
            .on('click', function (e, d) {
            var group = _this.data[d.datasetIndex].dataset[d.groupIndex].group;
            var group2 = _this.data[d.datasetIndex].dataset[d.groupIndex].group2;
            var group3 = _this.data[d.datasetIndex].dataset[d.groupIndex].group3;
            var value = _this.data[d.datasetIndex].dataset[d.groupIndex].value;
            _this.onClick({
                datasetIndex: d.datasetIndex,
                groupIndex: d.groupIndex,
                group: group,
                group2: group2,
                group3: group3,
                value: value,
                element: e.target
            });
        });
    };
    PieChart2Component.prototype.renderGradients = function () {
        var _this = this;
        var maxGroups = this.data.length ? Math.max.apply(Math, this.data.map(function (item) { return item.dataset.length; })) : 0;
        var gradients = this.svg
            .selectAll('.chart-pie-gradient')
            .data(range(maxGroups))
            .join('linearGradient')
            .attr('id', function (d, i) { return _this.getId("pie-gradient-" + i); })
            .attr('class', 'chart-pie-gradient')
            .attr('x1', '0%')
            .attr('y1', '100%')
            .attr('x2', '0%')
            .attr('y2', '0%');
        gradients
            .selectAll('stop')
            .data(function (d, i) {
            var colorHex = getColorHex(_this.color(0, i));
            var clr = parseColor(colorHex, '#000');
            if (!_this.gradient) {
                return [
                    { offset: '0%', color: clr },
                    { offset: '100%', color: clr }
                ];
            }
            return [
                { offset: '0%', color: clr.lighten(0.2) },
                { offset: '100%', color: clr.darken(0.2) }
            ];
        })
            .join('stop')
            .attr('offset', function (d) { return d.offset; })
            .attr('stop-color', function (d) { return d.color; });
    };
    PieChart2Component.prototype.initDatasetHover = function () {
        var _this = this;
        combineLatest(this.hoverDatasetGroup$, this.hoverLegendDatasetGroup$)
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var hoverDatasetGroup$ = _a[0], hoverLegendDatasetGroup = _a[1];
            var hoverGroup = [hoverDatasetGroup$, hoverLegendDatasetGroup].find(function (item) { return isSet(item); });
            _this.getAllDatasetGroups().forEach(function (item) {
                var selector = ".chart-pie-group_index-" + item.datasetIndex + "-" + item.groupIndex;
                var nodes = _this.svg.selectAll(selector).nodes();
                if (!isSet(hoverGroup) || isDatasetGroupEqual(item, hoverGroup)) {
                    nodes.forEach(function (node) { return node.classList.remove('chart-pie-group_disabled'); });
                }
                else {
                    nodes.forEach(function (node) { return node.classList.add('chart-pie-group_disabled'); });
                }
            });
        });
        this.hoverLegendDatasetGroup$.pipe(untilDestroyed(this)).subscribe(function (item) {
            if (item) {
                _this.showDatasetGroupTooltip(item);
            }
            else {
                _this.dataTooltip.close();
            }
        });
    };
    PieChart2Component.prototype.onMouseEnter = function (options) {
        if (!this.interactive) {
            return;
        }
        if (event.target.classList.contains('chart-bar_hidden')) {
            return;
        }
        this.dataTooltip.close();
        this.hoverDatasetGroup$.next({ datasetIndex: options.datasetIndex, groupIndex: options.groupIndex });
        var _a = pointer(options.event, this.el.nativeElement), pointerX = _a[0], pointerY = _a[1];
        var dataset = this.data[options.datasetIndex];
        this.showDatasetTooltip({
            dataset: dataset,
            datasetIndex: options.datasetIndex,
            group: options.group,
            groupIndex: options.groupIndex,
            x: pointerX,
            y: pointerY
        });
    };
    PieChart2Component.prototype.showDatasetTooltip = function (options) {
        var value = options.dataset.dataset[options.groupIndex].value;
        var totalValue = options.dataset.dataset.reduce(function (acc, item) { return acc + item.value; }, 0);
        var group;
        if (options.group instanceof moment) {
            var format = getDateFormatByLookup(this.dataGroupLookup) || 'lll';
            group = options.group.format(format);
        }
        else {
            group = options.group;
        }
        var defaultLabel = this.data.length > 1 ? "Dataset " + (options.datasetIndex + 1) : undefined;
        this.dataTooltip.show({
            group: group,
            datasets: [
                {
                    value: value,
                    valueFormat: options.dataset.format,
                    percentage: totalValue > 0 ? value / totalValue : undefined,
                    label: isSet(options.dataset.name) ? options.dataset.name : defaultLabel,
                    color: this.color(0, options.groupIndex)
                }
            ],
            valueFormat: this.yFormat,
            theme: this.theme,
            x: options.x,
            y: options.y,
            portalOutlet: this.portalOutlet,
            reuse: true
        });
    };
    PieChart2Component.prototype.showDatasetGroupTooltip = function (groupItem) {
        var selector = ".chart-pie-group_index-" + groupItem.datasetIndex + "-" + groupItem.groupIndex;
        var rootBounds = this.el.nativeElement.getBoundingClientRect();
        var node = this.svg.select(selector).node();
        if (!node) {
            this.dataTooltip.close();
            return;
        }
        var dataset = this.data[groupItem.datasetIndex];
        var group = dataset.dataset[groupItem.groupIndex].group;
        var nodeBounds = node.getBoundingClientRect();
        var x = nodeBounds.left - rootBounds.left + nodeBounds.width * 0.5;
        var y = nodeBounds.top - rootBounds.top + 10;
        this.showDatasetTooltip({
            dataset: dataset,
            datasetIndex: groupItem.datasetIndex,
            group: group,
            groupIndex: groupItem.groupIndex,
            x: x,
            y: y
        });
    };
    PieChart2Component.prototype.onMouseMove = function (e) {
        if (!this.interactive) {
            return;
        }
        var _a = pointer(e, this.el.nativeElement), pointerX = _a[0], pointerY = _a[1];
        this.dataTooltip.move(pointerX, pointerY, true);
    };
    PieChart2Component.prototype.onMouseLeave = function () {
        if (!this.interactive) {
            return;
        }
        this.hoverDatasetGroup$.next(undefined);
        this.dataTooltip.close();
    };
    PieChart2Component.prototype.onClick = function (options) {
        if (!this.dataClickEnabled) {
            return;
        }
        this.dataClick.emit(options);
    };
    PieChart2Component.prototype.isVisibleDatasetGroup = function (group) {
        return !this.selectedDatasetGroups || this.isSelectedDatasetGroup(group);
    };
    PieChart2Component.prototype.isSelectedDatasetGroup = function (group) {
        return this.selectedDatasetGroups.find(function (item) { return item.datasetIndex == group.datasetIndex && item.groupIndex == group.groupIndex; });
    };
    PieChart2Component.prototype.getAllDatasetGroups = function () {
        return this.data.reduce(function (acc, dataset, d) {
            dataset.dataset.forEach(function (group, g) {
                acc.push({ datasetIndex: d, groupIndex: g });
            });
            return acc;
        }, []);
    };
    PieChart2Component.prototype.toggleSelectedDatasetGroup = function (group) {
        if (!this.interactive) {
            return;
        }
        if (!this.selectedDatasetGroups) {
            this.selectedDatasetGroups = this.getAllDatasetGroups().filter(function (item) { return !isDatasetGroupEqual(item, group); });
        }
        else if (this.isSelectedDatasetGroup(group)) {
            this.selectedDatasetGroups = this.selectedDatasetGroups.filter(function (item) { return !isDatasetGroupEqual(item, group); });
        }
        else {
            this.selectedDatasetGroups.push(group);
            if (this.selectedDatasetGroups.length === this.getAllDatasetGroups().length) {
                this.selectedDatasetGroups = undefined;
            }
        }
        this.cd.markForCheck();
        this.renderPie();
        if (this.isVisibleDatasetGroup(group)) {
            this.showDatasetGroupTooltip(group);
            this.onLegendDatasetGroupMouseEnter(group);
        }
        else {
            this.dataTooltip.close();
            this.onLegendDatasetGroupMouseLeave();
        }
    };
    PieChart2Component.prototype.onLegendDatasetGroupMouseEnter = function (group) {
        if (!this.interactive) {
            return;
        }
        this.hoverLegendDatasetGroup$.next(group);
    };
    PieChart2Component.prototype.onLegendDatasetGroupMouseLeave = function () {
        if (!this.interactive) {
            return;
        }
        this.hoverLegendDatasetGroup$.next(undefined);
    };
    PieChart2Component.prototype.rerender = function () {
        this.initBounds();
        this.renderPie();
        this.renderGradients();
    };
    PieChart2Component.prototype.onResize = function () {
        this.rerender();
    };
    PieChart2Component.prototype.color = function (datasetIndex, index) {
        if (this.data[datasetIndex] &&
            this.data[datasetIndex].dataset[index] &&
            isSet(this.data[datasetIndex].dataset[index].color)) {
            return this.data[datasetIndex].dataset[index].color;
        }
        else {
            return getCircleIndex(this.defaultColors, index);
        }
    };
    PieChart2Component.prototype.colorDisplay = function (datasetIndex, index) {
        var value = this.color(datasetIndex, index);
        return getColorHexStr(value);
    };
    PieChart2Component.prototype.groupDisplay = function (value) {
        if (value instanceof moment) {
            var format = getDateFormatByLookup(this.dataGroupLookup) || 'lll';
            return value.format(format);
        }
        else {
            return value;
        }
    };
    return PieChart2Component;
}());
export { PieChart2Component };
