var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import clone from 'lodash/clone';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { TintStyle } from '@modules/actions';
import { applyActionElementStyles, getActionElementStyles } from '@modules/customize';
import { ElementConfigurationService } from '@modules/customize-configuration';
import { Input, Input as FieldInput, InputValueType } from '@modules/fields';
import { FieldInputControl } from '@modules/parameters';
import { controlValue } from '@shared';
import { ActionElementStylesControl } from '../styles-action-element-edit/action-element-styles.control';
var defaultVerboseName = {
    path: ['value'],
    value_type: InputValueType.StaticValue,
    static_value: 'Button'
};
var CustomizeBarFormSubmitEditForm = /** @class */ (function (_super) {
    __extends(CustomizeBarFormSubmitEditForm, _super);
    function CustomizeBarFormSubmitEditForm(elementConfigurationService, injector) {
        var _this = _super.call(this, (function () {
            var controls = {
                icon: new FormControl(null),
                style: new FormControl(TintStyle.Primary),
                tint: new FormControl(null),
                verbose_name: new FieldInputControl(defaultVerboseName),
                disabled_input: new FieldInputControl({ path: ['value'] }),
                visible_input: new FieldInputControl({ path: ['value'] })
            };
            controls['element_styles'] = new ActionElementStylesControl(injector, {
                textStyleGlobalParams: controlValue(controls.style).pipe(map(function (style) {
                    return { tint: style };
                }))
            });
            return controls;
        })()) || this;
        _this.elementConfigurationService = elementConfigurationService;
        _this.injector = injector;
        _this.styleOptions = [
            {
                value: TintStyle.Primary,
                image: 'button-primary'
            },
            {
                value: TintStyle.Default,
                image: 'button-default'
            },
            {
                value: TintStyle.Transparent,
                image: 'button-transparent'
            }
        ];
        return _this;
    }
    CustomizeBarFormSubmitEditForm.prototype.init = function (element, firstInit) {
        if (firstInit === void 0) { firstInit = false; }
        this.element = element;
        var value = {
            icon: element.icon,
            style: element.style,
            tint: element.tint,
            verbose_name: element.verboseNameInput ? element.verboseNameInput.serializeWithoutPath() : defaultVerboseName,
            disabled_input: element.disabledInput ? element.disabledInput.serializeWithoutPath() : {},
            visible_input: element.visibleInput ? element.visibleInput.serializeWithoutPath() : {},
            margin: element.margin
        };
        this.patchValue(value, { emitEvent: false });
        var elementStyles = getActionElementStyles(element);
        this.controls.element_styles.deserialize(elementStyles);
        if (!firstInit) {
            this.markAsDirty();
        }
    };
    CustomizeBarFormSubmitEditForm.prototype.isConfigured = function (instance) {
        //   return this.elementConfigurationService.isActionDropdownConfigured(instance, { restrictDemo: true });
        return of(true);
    };
    CustomizeBarFormSubmitEditForm.prototype.submit = function () {
        var instance = clone(this.element);
        instance.icon = this.controls.icon.value;
        instance.style = this.controls.style.value;
        instance.tint = this.controls.tint.value;
        instance.verboseNameInput = this.controls.verbose_name.value
            ? new FieldInput().deserialize(this.controls.verbose_name.value)
            : undefined;
        if (this.controls.disabled_input.value) {
            instance.disabledInput = new Input().deserialize(this.controls.disabled_input.value);
        }
        else {
            instance.disabledInput = undefined;
        }
        if (this.controls.visible_input.value) {
            instance.visibleInput = new Input().deserialize(this.controls.visible_input.value);
        }
        else {
            instance.visibleInput = undefined;
        }
        var elementStyles = this.controls.element_styles.serialize();
        applyActionElementStyles(instance, elementStyles);
        return instance;
    };
    return CustomizeBarFormSubmitEditForm;
}(FormGroup));
export { CustomizeBarFormSubmitEditForm };
