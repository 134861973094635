/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../map-popup/map-popup.component.ngfactory";
import * as i2 from "../map-popup/map-popup.component";
import * as i3 from "../../../projects/stores/current-project.store";
import * as i4 from "../../../action-queries/services/action-controller/action-controller.service";
import * as i5 from "../../../custom-views/stores/custom-views.store";
import * as i6 from "../../../../common/notifications/services/notification/notification.service";
import * as i7 from "@angular/common";
import * as i8 from "@agm/core/services/fit-bounds";
import * as i9 from "@agm/core/directives/marker";
import * as i10 from "@agm/core/services/managers/marker-manager";
import * as i11 from "../../../../../../node_modules/@agm/snazzy-info-window/directives/snazzy-info-window.ngfactory";
import * as i12 from "@agm/snazzy-info-window/directives/snazzy-info-window";
import * as i13 from "@agm/core/services/google-maps-api-wrapper";
import * as i14 from "@agm/core/services/maps-api-loader/maps-api-loader";
import * as i15 from "./map-marker.component";
import * as i16 from "../../../theme/services/theme/theme.service";
import * as i17 from "../../../../core/services/app-config/app-config.service";
var styles_MapMarkerComponent = [];
var RenderType_MapMarkerComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MapMarkerComponent, data: {} });
export { RenderType_MapMarkerComponent as RenderType_MapMarkerComponent };
function View_MapMarkerComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-map-popup", [], null, [[null, "modelUpdated"], [null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("modelUpdated" === en)) {
        var pd_0 = (_co.modelUpdated.next($event) !== false);
        ad = (pd_0 && ad);
    } if (("close" === en)) {
        var pd_1 = (_co.closeWindow.emit() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_MapPopupComponent_0, i1.RenderType_MapPopupComponent)), i0.ɵdid(1, 770048, null, 0, i2.MapPopupComponent, [i3.CurrentProjectStore, i4.ActionControllerService, i5.CustomViewsStore, i6.NotificationService, i0.Injector, i0.ChangeDetectorRef], { item: [0, "item"], modelDescription: [1, "modelDescription"], settings: [2, "settings"], visibleColumns: [3, "visibleColumns"], context: [4, "context"], contextElement: [5, "contextElement"], accentColor: [6, "accentColor"], theme: [7, "theme"] }, { modelUpdated: "modelUpdated", close: "close" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.item.item; var currVal_1 = _co.modelDescription; var currVal_2 = _co.settings; var currVal_3 = _co.visibleColumns; var currVal_4 = _co.context; var currVal_5 = _co.contextElement; var currVal_6 = _co.accentColor; var currVal_7 = _co.theme; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, null); }
function View_MapMarkerComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, [[2, 2]], null, 1, null, View_MapMarkerComponent_2)), i0.ɵdid(1, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.windowOpen; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_MapMarkerComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "agm-marker", [], null, [[null, "markerClick"], [null, "infoWindowClose"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("markerClick" === en)) {
        var pd_0 = (_co.openWindow.emit() !== false);
        ad = (pd_0 && ad);
    } if (("infoWindowClose" === en)) {
        var pd_1 = (_co.closeWindow.emit() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵprd(6144, null, i8.FitBoundsAccessor, null, [i9.AgmMarker]), i0.ɵdid(2, 1720320, null, 1, i9.AgmMarker, [i10.MarkerManager], { latitude: [0, "latitude"], longitude: [1, "longitude"], iconUrl: [2, "iconUrl"] }, { markerClick: "markerClick" }), i0.ɵqud(603979776, 1, { infoWindow: 1 }), (_l()(), i0.ɵeld(4, 0, null, null, 3, "agm-snazzy-info-window", [], null, null, null, i11.View_AgmSnazzyInfoWindow_0, i11.RenderType_AgmSnazzyInfoWindow)), i0.ɵdid(5, 4898816, null, 1, i12.AgmSnazzyInfoWindow, [[3, i9.AgmMarker], i13.GoogleMapsAPIWrapper, i10.MarkerManager, i14.MapsAPILoader], { isOpen: [0, "isOpen"], placement: [1, "placement"], maxWidth: [2, "maxWidth"], maxHeight: [3, "maxHeight"], pointer: [4, "pointer"], wrapperClass: [5, "wrapperClass"], showCloseButton: [6, "showCloseButton"] }, null), i0.ɵqud(603979776, 2, { _templateRef: 0 }), (_l()(), i0.ɵand(0, [[2, 2]], 0, 0, null, View_MapMarkerComponent_1))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.item.latitude; var currVal_1 = _co.item.longitude; var currVal_2 = _co.icon; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.windowOpen; var currVal_4 = "right"; var currVal_5 = "none"; var currVal_6 = "none"; var currVal_7 = "8px"; var currVal_8 = (_co.theme ? " si-wrapper-theme" : ""); var currVal_9 = false; _ck(_v, 5, 0, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }, null); }
export function View_MapMarkerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-map-marker", [], null, null, null, View_MapMarkerComponent_0, RenderType_MapMarkerComponent)), i0.ɵdid(1, 770048, null, 0, i15.MapMarkerComponent, [i16.ThemeService, i17.AppConfigService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MapMarkerComponentNgFactory = i0.ɵccf("app-map-marker", i15.MapMarkerComponent, View_MapMarkerComponent_Host_0, { settings: "settings", item: "item", modelDescription: "modelDescription", windowOpen: "windowOpen", visibleColumns: "visibleColumns", context: "context", contextElement: "contextElement", accentColor: "accentColor", theme: "theme" }, { modelUpdated: "modelUpdated", openWindow: "openWindow", closeWindow: "closeWindow" }, []);
export { MapMarkerComponentNgFactory as MapMarkerComponentNgFactory };
