var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ComponentFactoryResolver, Injector } from '@angular/core';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ThinDialogPopupComponent } from '@common/dialog-popup';
import { PopupService } from '@common/popups';
import { ResourceGeneratorResolver } from '@modules/resource-generators';
import { isSet } from '@shared';
import { ChooseSyncModeComponent } from '../../components/choose-sync-mode/choose-sync-mode.component';
import { ConfirmSyncModeComponent } from '../../components/confirm-sync-mode/confirm-sync-mode.component';
var ChooseSyncController = /** @class */ (function () {
    function ChooseSyncController(resourceGeneratorResolver, injector, popupService, resolver) {
        this.resourceGeneratorResolver = resourceGeneratorResolver;
        this.injector = injector;
        this.popupService = popupService;
        this.resolver = resolver;
    }
    ChooseSyncController.prototype.chooseSyncMode = function (control, options) {
        var result = new Subject();
        this.popupService.push({
            component: ChooseSyncModeComponent,
            popupComponent: ThinDialogPopupComponent,
            inputs: {
                control: control,
                typeItem: options.typeItem,
                paramsOptions: options.paramsOptions
            },
            outputs: {
                chosen: [
                    function (value) {
                        result.next(value);
                    }
                ]
            },
            resolver: this.resolver,
            injector: this.injector
        });
        return result.asObservable();
    };
    ChooseSyncController.prototype.showConfirmSyncMode = function (object, sync, options) {
        return this.popupService.showComponent({
            component: ConfirmSyncModeComponent,
            injector: this.injector,
            inputs: __assign({ object: object, sync: sync, resource: options.resource, typeItem: options.typeItem }, (isSet(options.closeOnConfirm) && { closeOnConfirm: options.closeOnConfirm })),
            scrollable: true
        });
    };
    ChooseSyncController.prototype.confirmSyncMode = function (object, sync, options) {
        var _this = this;
        var popupRef = this.showConfirmSyncMode(object, sync, __assign({}, options, { closeOnConfirm: true }));
        return popupRef.instance.confirm.pipe(map(function (result) {
            return {
                resourceParams: _this.getConfirmSyncModeResourceParams(options.resource, result),
                syncInterval: _this.getConfirmSyncModeSyncInterval(options.resource, result)
            };
        }));
    };
    ChooseSyncController.prototype.getConfirmSyncModeResourceParams = function (resource, result) {
        var generator = this.resourceGeneratorResolver.get(resource.typeItem.name);
        if (result && result.paramsOptions && generator.generateResourceParams) {
            return generator.generateResourceParams(result.paramsOptions);
        }
    };
    ChooseSyncController.prototype.getConfirmSyncModeSyncInterval = function (resource, result) {
        var generator = this.resourceGeneratorResolver.get(resource.typeItem.name);
        if (result && result.paramsOptions && generator.generateSyncInterval) {
            return generator.generateSyncInterval(result.paramsOptions);
        }
    };
    return ChooseSyncController;
}());
export { ChooseSyncController };
