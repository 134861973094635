import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';

import { applyElementWrapperStyles, CardLayoutElementItem, getElementWrapperStyles } from '@modules/customize';
import { Input } from '@modules/fields';
import { FieldInputControl } from '@modules/parameters';

import { ElementWrapperStylesControl } from '../styles-element-wrapper-edit/element-wrapper-styles.control';

@Injectable()
export class CustomizeBarCardEditForm extends FormGroup {
  element: CardLayoutElementItem;

  controls: {
    title: FormControl;
    visible_input: FieldInputControl;
    load_invisible: FormControl;

    element_styles: ElementWrapperStylesControl;
  };

  constructor() {
    super({
      title: new FormControl(''),
      visible_input: new FieldInputControl({ path: ['value'] }),
      load_invisible: new FormControl(false),

      element_styles: new ElementWrapperStylesControl()
    });
  }

  init(element: CardLayoutElementItem) {
    this.element = element;

    this.controls.title.patchValue(element.name ? element.name : 'Container');
    this.controls.visible_input.patchValue(element.visibleInput ? element.visibleInput.serializeWithoutPath() : {});
    this.controls.load_invisible.patchValue(element.loadInvisible);

    const elementStyles = getElementWrapperStyles(element);
    this.controls.element_styles.deserialize(elementStyles);
  }

  submit(): CardLayoutElementItem {
    const instance = cloneDeep(this.element) as CardLayoutElementItem;

    instance.name = this.controls.title.value;

    if (this.controls.visible_input.value) {
      instance.visibleInput = new Input().deserialize(this.controls.visible_input.value);
    } else {
      instance.visibleInput = undefined;
    }

    instance.loadInvisible = this.controls.load_invisible.value;

    const elementStyles = this.controls.element_styles.serialize();
    applyElementWrapperStyles(instance, elementStyles);

    return instance;
  }
}
