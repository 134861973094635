import { Injector, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import pickBy from 'lodash/pickBy';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';

import { AllProjectSettings, ProjectSettingsStore } from '@modules/all-project-settings';
import { TextStyle, TextStyleGlobalName } from '@modules/customize';
import { TextStyleDefaults, TextStyleGlobalParams, textStyleGlobals } from '@modules/customize-shared';
import { Option } from '@modules/field-components';
import {
  FontStyle,
  FontType,
  fontTypeOptions,
  getFontFamilyVariable,
  TextDecoration,
  TextTransform
} from '@modules/styles';
import { fonts, headingFontName, regularFontName } from '@modules/theme';
import { controlValue, isSet } from '@shared';

export interface TextStyleControlOptions<P extends TextStyleGlobalParams = TextStyleGlobalParams> {
  global?: TextStyleGlobalName;
  globalParams?: Observable<Partial<P>>;
  defaults?: TextStyleDefaults;
  colorAlphaEnabled?: boolean;
  backgroundEnabled?: boolean;
}

export class TextStyleControl<P extends TextStyleGlobalParams = TextStyleGlobalParams> extends FormGroup
  implements OnDestroy {
  instance: TextStyle;
  instanceSet = false;

  controls: {
    font_family: FormControl;
    font_type: FormControl;
    font_size: FormControl;
    color: FormControl;
    color_dark: FormControl;
    background_color: FormControl;
    background_color_dark: FormControl;
    letter_spacing: FormControl;
    transform: FormControl;
    decoration: FormControl;
  };

  transformOptions: Option<TextTransform>[] = [
    {
      value: TextTransform.None,
      icon: 'close',
      name: '',
      subtitle: 'No transform'
    },
    {
      value: TextTransform.Uppercase,
      icon: 'uppercase',
      name: '',
      subtitle: 'Uppercase'
    },
    {
      value: TextTransform.Lowercase,
      icon: 'lowercase',
      name: '',
      subtitle: 'Lowercase'
    },
    {
      value: TextTransform.Capitalize,
      icon: 'capitalize',
      name: '',
      subtitle: 'Capitalize'
    }
  ];

  decorationOptions: Option<TextDecoration>[] = [
    {
      value: TextDecoration.None,
      icon: 'close',
      name: '',
      subtitle: 'No decoration'
    },
    {
      value: TextDecoration.Underline,
      icon: 'underline',
      name: '',
      subtitle: 'Underline'
    },
    {
      value: TextDecoration.Strikethrough,
      icon: 'strikethrough',
      name: '',
      subtitle: 'Strikethrough'
    }
  ];

  defaultValues: Partial<TextStyle> = {
    fontFamily: regularFontName,
    fontWeight: 400,
    fontStyle: FontStyle.Normal,
    fontSize: 14,
    color: '#768191',
    colorDark: '#768191',
    transform: TextTransform.None,
    decoration: TextDecoration.None
  };

  private styleDefault$ = new BehaviorSubject<TextStyle>(undefined);
  private deserializeSubscription: Subscription;

  constructor(
    private projectSettingsStore: ProjectSettingsStore,
    public readonly options: TextStyleControlOptions<P> = {}
  ) {
    super({
      font_family: new FormControl(),
      font_type: new FormControl({}),
      font_size: new FormControl(),
      color: new FormControl(),
      color_dark: new FormControl(),
      background_color: new FormControl(),
      background_color_dark: new FormControl(),
      letter_spacing: new FormControl(),
      transform: new FormControl(TextTransform.None),
      decoration: new FormControl(TextDecoration.None)
    });

    combineLatest(this.projectSettingsStore.getAllSettings$(), options.globalParams ? options.globalParams : of({}))
      .pipe(untilDestroyed(this))
      .subscribe(([settings, globalParams]) => {
        const baseDefaults: Partial<TextStyle> = {
          fontFamily: regularFontName,
          fontWeight: 400,
          fontStyle: FontStyle.Normal,
          fontSize: 14,
          color: '#768191',
          colorDark: '#768191'
        };
        const textStyleGlobal = isSet(this.options.global)
          ? textStyleGlobals.find(item => item.name == this.options.global)
          : undefined;
        const defaults: Partial<TextStyle> = {
          ...baseDefaults,
          ...(textStyleGlobal &&
            pickBy(textStyleGlobal.defaults({ settings: settings, ...(globalParams as Object) }), v => isSet(v))),
          ...(this.options.defaults && pickBy(this.options.defaults, v => isSet(v)))
          // ...(settings && pickBy(settings[options.globalSetting] as TextStyle, v => isSet(v)))
        };
        this.styleDefault$.next(new TextStyle(defaults));

        if (!this.instanceSet) {
          this.deserialize(this.instance);
        }
      });
  }

  static inject<P extends TextStyleGlobalParams = TextStyleGlobalParams>(
    injector: Injector,
    options: TextStyleControlOptions<P> = {}
  ): TextStyleControl<P> {
    return Injector.create({
      providers: [
        {
          provide: TextStyleControl,
          useFactory: (projectSettingsStore: ProjectSettingsStore) => {
            return new TextStyleControl<P>(projectSettingsStore, options);
          },
          deps: [ProjectSettingsStore]
        }
      ],
      parent: injector
    }).get<TextStyleControl<P>>(TextStyleControl);
  }

  fontTypeEquals = (lhs: FontType, rhs: FontType) => {
    if (lhs && rhs) {
      return lhs.weight == rhs.weight && lhs.style == rhs.style;
    } else {
      return lhs == rhs;
    }
  };

  ngOnDestroy(): void {}

  deserialize(instance?: TextStyle) {
    this.instance = instance;
    this.instanceSet = false;

    if (this.deserializeSubscription) {
      this.deserializeSubscription.unsubscribe();
      this.deserializeSubscription = undefined;
    }

    this.deserializeSubscription = this.getStyleDefault$()
      .pipe(first(), untilDestroyed(this))
      .subscribe(styleDefault => {
        const style: TextStyle = instance || styleDefault;

        this.controls.font_family.patchValue(style ? style.fontFamily : undefined);
        this.controls.font_type.patchValue(
          style && isSet(style.fontWeight) ? { weight: style.fontWeight, style: style.fontStyle } : undefined
        );
        this.controls.font_size.patchValue(style ? style.fontSize : undefined);
        this.controls.color.patchValue(style ? style.color : undefined);
        this.controls.color_dark.patchValue(style ? style.colorDark : undefined);
        this.controls.background_color.patchValue(style ? style.backgroundColor : undefined);
        this.controls.background_color_dark.patchValue(style ? style.backgroundColorDark : undefined);
        this.controls.letter_spacing.patchValue(style ? style.letterSpacing : undefined);
        this.controls.transform.patchValue(style ? style.transform : TextTransform.None);
        this.controls.decoration.patchValue(style ? style.decoration : TextDecoration.None);

        this.instanceSet = true;
      });
  }

  getFontTypeOptionsForValue(fontFamily: string, projectSettings: AllProjectSettings): Option<FontType>[] {
    const fontFamilyVariable = getFontFamilyVariable(fontFamily);

    if (fontFamilyVariable == regularFontName) {
      fontFamily = projectSettings.fontRegular;
    } else if (fontFamilyVariable == headingFontName) {
      fontFamily = projectSettings.fontHeading;
    }

    const font = fonts.find(item => item.name == fontFamily);

    return fontTypeOptions.filter(item => !font || font.weights.includes(item.value.weight));
  }

  getFontTypeOptions$(): Observable<Option<FontType>[]> {
    return combineLatest(controlValue(this.controls.font_family), this.projectSettingsStore.getAllSettings$()).pipe(
      map(([fontFamily, projectSettings]) => this.getFontTypeOptionsForValue(fontFamily, projectSettings))
    );
  }

  getInstance(instance?: TextStyle): TextStyle {
    if (!instance) {
      instance = new TextStyle();
    }

    instance.fontFamily = this.controls.font_family.value;

    const fontType: FontType = this.controls.font_type.value;
    if (fontType) {
      instance.fontWeight = fontType.weight;
      instance.fontStyle = fontType.style;
    } else {
      instance.fontWeight = undefined;
      instance.fontStyle = undefined;
    }

    instance.fontSize = isSet(this.controls.font_size.value) ? this.controls.font_size.value : undefined;
    instance.color = isSet(this.controls.color.value) ? this.controls.color.value : undefined;
    instance.colorDark = isSet(this.controls.color_dark.value) ? this.controls.color_dark.value : undefined;
    instance.backgroundColor = this.controls.background_color.value;
    instance.backgroundColorDark = this.controls.background_color_dark.value;
    instance.letterSpacing = this.controls.letter_spacing.value;
    instance.transform = this.controls.transform.value;
    instance.decoration = this.controls.decoration.value;

    return instance;
  }

  getStyleDefault(): TextStyle {
    return this.styleDefault$.value;
  }

  getStyleDefault$(skipFirst = true): Observable<TextStyle> {
    if (skipFirst) {
      return this.styleDefault$.pipe(filter(style => !!style));
    } else {
      return this.styleDefault$.asObservable();
    }
  }

  isDefaultStyle(): boolean {
    const style = this.serialize();
    const defaultStyle = this.getStyleDefault();

    if (!style) {
      return true;
    }

    if (!defaultStyle) {
      return false;
    }

    return style.equals(defaultStyle);
  }

  isDefaultStyle$(): Observable<boolean> {
    return combineLatest(this.serialize$(), this.getStyleDefault$()).pipe(
      map(([style, defaultStyle]) => {
        if (!style) {
          return true;
        }

        if (!defaultStyle) {
          return false;
        }

        return style.equals(defaultStyle);
      })
    );
  }

  isSet(): boolean {
    return !this.isDefaultStyle();
  }

  isSet$(): Observable<boolean> {
    return this.isDefaultStyle$().pipe(map(isDefaultStyle => !isDefaultStyle));
  }

  resetDefaults() {
    this.deserialize(undefined);
  }

  serialize(reuseInstance = true, onlyChanges = false): TextStyle {
    if (!this.instanceSet) {
      return this.instance;
    }

    const style = this.getInstance(reuseInstance ? this.instance : undefined);
    const defaultStyle = this.getStyleDefault();

    if (!defaultStyle) {
      return style;
    }

    const diff = style.getDiff(defaultStyle);
    if (!diff) {
      return;
    }

    if (onlyChanges) {
      Object.assign(
        style,
        {
          fontFamily: undefined,
          fontWeight: undefined,
          fontStyle: undefined,
          fontSize: undefined,
          color: undefined,
          colorDark: undefined,
          backgroundColor: undefined,
          backgroundColorDark: undefined,
          letterSpacing: undefined,
          transform: undefined,
          decoration: undefined
        },
        diff
      );
    }

    return style;
  }

  serialize$(): Observable<TextStyle> {
    return controlValue(this, { debounce: 200 }).pipe(map(() => this.serialize()));
  }
}
