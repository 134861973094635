import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  ViewChild
} from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import isEqual from 'lodash/isEqual';
import * as moment from 'moment';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, skip, tap } from 'rxjs/operators';

import { UniqueIdToken } from '@common/unique-id';
import { ActionControllerService } from '@modules/action-queries';
import {
  CustomizeService,
  DateRangeElementItem,
  ElementType,
  FROM_OUTPUT,
  registerElementComponent,
  TO_OUTPUT,
  ViewContextElement
} from '@modules/customize';
import { BaseElementComponent } from '@modules/customize-elements';
import {
  applyParamInput$,
  BaseField,
  createFormFieldFactory,
  FieldType,
  getDateTimeFieldMomentFormat,
  isDateTimeFieldClock12Format,
  LOADING_VALUE,
  ParameterField
} from '@modules/fields';
import { controlValue, isSet, KeyboardEventKeyCode, setControlDisabled, TypedChanges } from '@shared';

import { CustomPagePopupComponent } from '../custom-page-popup/custom-page-popup.component';

@Component({
  selector: 'app-date-range-element',
  templateUrl: './date-range-element.component.html',
  providers: [ViewContextElement],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateRangeElementComponent extends BaseElementComponent implements OnInit, OnDestroy, OnChanges {
  @Input() element: DateRangeElementItem;

  @ViewChild('dp_from') fromElement: ElementRef<HTMLInputElement>;
  @ViewChild('dp_to') toElement: ElementRef<HTMLInputElement>;

  idToken = new UniqueIdToken();
  createField = createFormFieldFactory();
  customizeEnabled$: Observable<boolean>;
  contextSubscription: Subscription;
  fromControl = new FormControl(null);
  toControl = new FormControl(null);
  form = new FormGroup({ from: this.fromControl, to: this.toControl });
  editFormat: string;
  editFormatPlaceholder: string;
  editFormatClock12 = false;

  constructor(
    private customizeService: CustomizeService,
    public viewContextElement: ViewContextElement,
    private actionControllerService: ActionControllerService,
    private injector: Injector,
    private cd: ChangeDetectorRef,
    @Optional() private popup: CustomPagePopupComponent
  ) {
    super();
  }

  ngOnInit() {
    this.customizeEnabled$ = this.customizeService.enabled$.pipe(map(item => !!item));
    this.updateParams();
    this.initContext();
    this.updateContextOutputs();
    this.updateContextActions();
    this.initContextObserver();

    controlValue<number>(this.fromControl)
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        const error = !!this.fromControl.errors;
        this.viewContextElement.setOutputValue(FROM_OUTPUT, value, { error: error });
      });

    controlValue<number>(this.toControl)
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        const error = !!this.toControl.errors;
        this.viewContextElement.setOutputValue(TO_OUTPUT, value, { error: error });
      });

    combineLatest(
      controlValue<number>(this.fromControl, { debounce: 200 }),
      controlValue<number>(this.toControl, { debounce: 200 })
    )
      .pipe(
        distinctUntilChanged((lhs, rhs) => isEqual(lhs, rhs)),
        skip(1),
        untilDestroyed(this)
      )
      .subscribe(([from, to]) => {
        this.element.onChangeActions.forEach(action => {
          this.actionControllerService
            .execute(action, {
              context: this.context,
              contextElement: this.viewContextElement,
              localContext: {
                [FROM_OUTPUT]: from,
                [TO_OUTPUT]: to
              },
              injector: this.injector
            })
            .subscribe();
        });
      });
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<DateRangeElementComponent>): void {
    if (changes.element) {
      this.updateParams();
      this.initContextObserver();
    }

    if (changes.element && !changes.element.firstChange) {
      this.viewContextElement.initInfo({ name: this.element.name, element: this.element }, true);
    }
  }

  getField(): BaseField {
    return {
      field: FieldType.DateTime,
      params: { time: this.element.time, date_format: this.element.dateFormat, time_format: this.element.timeFormat }
    };
  }

  updateParams() {
    const validators: ValidatorFn[] = this.element.required ? [Validators.required] : [];

    this.fromControl.setValidators(validators);
    this.fromControl.updateValueAndValidity();

    this.toControl.setValidators(validators);
    this.toControl.updateValueAndValidity();

    const field = this.getField();
    this.editFormat = getDateTimeFieldMomentFormat(field, true);
    this.editFormatPlaceholder = getDateTimeFieldMomentFormat(field, true, true);
    this.editFormatClock12 = isDateTimeFieldClock12Format(field, true);
    this.cd.markForCheck();
  }

  initContext() {
    this.viewContextElement.initElement({
      uniqueName: this.element.uid,
      name: this.element.name,
      icon: 'after_date',
      allowSkip: true,
      element: this.element,
      popup: this.popup ? this.popup.popup : undefined
    });
  }

  updateContextOutputs() {
    this.viewContextElement.setOutputs([
      {
        uniqueName: FROM_OUTPUT,
        name: 'From',
        icon: 'after_date',
        fieldType: FieldType.DateTime,
        external: true
      },
      {
        uniqueName: TO_OUTPUT,
        name: 'To',
        icon: 'before_date',
        fieldType: FieldType.DateTime,
        external: true
      }
    ]);
  }

  updateContextActions() {
    const valueParameter = new ParameterField();

    valueParameter.name = 'value';
    valueParameter.field = FieldType.DateTime;

    this.viewContextElement.setActions([
      {
        uniqueName: 'set_from_value',
        name: 'Set From value',
        icon: 'after_date',
        parameters: [valueParameter],
        handler: params => {
          this.fromControl.setValue(params['value']);
        }
      },
      {
        uniqueName: 'set_to_value',
        name: 'Set To value',
        icon: 'before_date',
        parameters: [valueParameter],
        handler: params => {
          this.toControl.setValue(params['value']);
        }
      }
    ]);
  }

  initContextObserver() {
    if (this.contextSubscription) {
      this.contextSubscription.unsubscribe();
    }

    const from$ = this.element.from
      ? applyParamInput$(this.element.from, {
          context: this.context,
          defaultValue: null
        }).pipe(distinctUntilChanged())
      : of(null);
    const to$ = this.element.to
      ? applyParamInput$(this.element.to, {
          context: this.context,
          defaultValue: null
        }).pipe(distinctUntilChanged())
      : of(null);
    const disabled$ = this.element.disableInput
      ? applyParamInput$<boolean>(this.element.disableInput, {
          context: this.context,
          defaultValue: false,
          handleLoading: true
        }).pipe(
          distinctUntilChanged(),
          map(disabled => {
            if (disabled === LOADING_VALUE) {
              return true;
            } else {
              return disabled;
            }
          })
        )
      : of(false);

    this.contextSubscription = combineLatest(
      from$.pipe(
        filter(value => isSet(value) && this.fromControl.value != value),
        tap(value => this.fromControl.patchValue(value))
      ),
      to$.pipe(
        filter(value => isSet(value) && this.toControl.value != value),
        tap(value => this.toControl.patchValue(value))
      ),
      disabled$.pipe(
        tap(disabled => {
          setControlDisabled(this.fromControl, disabled);
          setControlDisabled(this.toControl, disabled);
        })
      )
    )
      .pipe(untilDestroyed(this))
      .subscribe();
  }

  resetCurrentValue() {
    this.fromControl.patchValue(null);
    this.toControl.patchValue(null);
  }

  onFromSelected(date: moment.Moment) {
    const toDate = isSet(this.toControl.value) ? moment(this.toControl.value) : undefined;

    if (toDate && toDate.isValid() && date.isAfter(toDate)) {
      this.toControl.patchValue(null);
    }

    this.toElement.nativeElement.focus();
  }

  onToSelected(date: moment.Moment) {
    const fromDate = isSet(this.fromControl.value) ? moment(this.fromControl.value) : undefined;

    if (fromDate && fromDate.isValid() && date.isBefore(fromDate)) {
      this.fromControl.patchValue(date.format(this.editFormat));
      this.toControl.patchValue(fromDate.format(this.editFormat));
    }

    this.toElement.nativeElement.focus();
  }

  onKeydown(e: KeyboardEvent, element: HTMLInputElement) {
    const anotherElement =
      this.fromElement.nativeElement === element ? this.toElement.nativeElement : this.fromElement.nativeElement;

    if (e.keyCode == KeyboardEventKeyCode.ArrowLeft) {
      if (element.selectionStart == 0 && element.selectionStart == element.selectionEnd) {
        const anotherElementPositionEnd = anotherElement.value.length;

        e.preventDefault();
        anotherElement.focus();
        anotherElement.setSelectionRange(anotherElementPositionEnd, anotherElementPositionEnd);
      }
    } else if (e.keyCode == KeyboardEventKeyCode.ArrowRight) {
      const elementPositionEnd = element.value.length;
      if (element.selectionStart == elementPositionEnd && element.selectionStart == element.selectionEnd) {
        e.preventDefault();
        anotherElement.focus();
        anotherElement.setSelectionRange(0, 0);
      }
    }
  }
}

registerElementComponent({
  type: ElementType.DateRange,
  component: DateRangeElementComponent,
  label: 'Date Range',
  actions: []
});
