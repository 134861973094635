<form *ngIf="!loading" [formGroup]="form.form">
  <div class="popup-content resource-popup">
    <div
      *ngIf="environment"
      class="resource-popup__tag"
      [ngClass]="['color_' + environment.color + '_1', 'background-color_' + environment.color + '_2']"
    >
      {{ environment.name }}
    </div>
    <div class="resource-popup__image">
      <div
        class="resource-popup__image-inner"
        [style.background-image]="'/assets/images/resources/' + typeItem.image + '-color.svg' | appDeployCssUrl"
      ></div>
    </div>
    <div class="resource-popup__title">
      Allow Jet Admin to access <br />
      <strong [style.color]="typeItem.color">{{ typeItem.label }}</strong>
    </div>
    <div class="resource-popup__description">
      Connect Google Cloud Storage to create internal tools on top of your data.
    </div>

    <div class="resource-popup__content">
      <app-page-block *ngIf="resourceNameEditing">
        <app-auto-field
          [form]="form.resourceForm"
          [field]="
            createField({
              name: 'name',
              label: 'Resource Name',
              field: 'CharField',
              description: 'This is how you and your team will see it',
              required: true,
              params: { classes: ['input_fill'] }
            })
          "
        ></app-auto-field>
      </app-page-block>

      <app-page-block>
        <div class="field">
          <label class="field__label" for="dist">Paste Service Token</label>
          <div class="field__value">
            <textarea
              class="input input_textarea input_fill"
              rows="10"
              [class.input_error]="(form.form | appFormFieldErrors: 'service_token')?.length"
              [formControlName]="'service_token'"
              [placeholder]="defaultPlaceholder || 'Insert JSON formatted service token'"
              [appAutofocus]="true"
              id="dist"
            >
            </textarea>
          </div>
          <app-field-errors [form]="form.form" [fieldName]="'service_token'"></app-field-errors>

          <input
            class="input input_file"
            type="file"
            (change)="onFileChange('service_token', $event.target)"
            id="dist_upload"
          />

          <label for="dist_upload" class="button button_small" style="margin: 10px 0 0 0;">
            <span class="icon icon-cloud_upload button__icon"></span>&nbsp;
            <span class="button__label">
              <ng-container *ngIf="form.form.value['service_token']">or Upload new file (.json)</ng-container>
              <ng-container *ngIf="!form.form.value['service_token']">or Upload file</ng-container>
            </span>
          </label>
        </div>
      </app-page-block>

      <!--      <div *ngIf="form.form.value['service_token']" style="padding-top: 20px">-->
      <!--        <app-auto-field [form]="form.form" [readonly]="true" [field]="createField({ name: 'project_id', label: 'App ID', field: 'CharField' })"></app-auto-field>-->
      <!--        <app-auto-field [form]="form.form" [readonly]="true" [field]="createField({ name: 'access_token', label: 'Access Token', field: 'CharField' })"></app-auto-field>-->
      <!--      </div>-->

      <app-page-block>
        <a
          [appDocumentation]="
            'https://cloud.google.com/iam/docs/creating-managing-service-accounts#creating_a_service_account'
          "
          class="button button_small"
          style="margin: 0;"
        >
          Read documentation
        </a>
      </app-page-block>

      <app-page-block *ngIf="(form.form | appFormErrors).length">
        <app-resource-settings-submit-error [errors]="form.form | appFormErrors"></app-resource-settings-submit-error>
      </app-page-block>
    </div>

    <div class="popup-content__submit">
      <button
        *ngIf="resourceDelete && resource"
        type="button"
        class="button button_danger popup-content__delete"
        (click)="requestDelete()"
      >
        Delete
      </button>
      <button type="button" class="button popup-content__submit-item" (click)="cancel()">
        Cancel
      </button>

      <button
        type="button"
        class="button button_primary button_shadow popup-content__submit-item"
        [class.button_disabled]="!form.isValid() || loadingSubmit"
        (click)="submit()"
      >
        <app-loader-small *ngIf="loadingSubmit" class="button__icon button__icon_left"></app-loader-small>
        <span class="button__label">
          <ng-container *ngIf="resource">Update resource</ng-container>
          <ng-container *ngIf="!resource">Add resource</ng-container>
        </span>
      </button>
    </div>
  </div>
</form>

<app-resource-settings-stub *ngIf="loading" [typeItem]="typeItem"></app-resource-settings-stub>
