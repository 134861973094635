<div class="pagination" [class.pagination_theme]="theme">
  <span
    class="pagination__control icon-arrow_backward_2"
    [class.pagination__control_disabled]="!prevPage"
    (click)="openPage(prevPage)"
  >
  </span>

  <div class="pagination__pages">
    <ng-container *ngFor="let item of items; trackBy: trackByFn">
      <span
        *ngIf="item.type == 'page'"
        class="pagination__pages-item"
        [class.pagination__pages-item_selected]="current == item.page"
        (click)="openPage(item.page)"
      >
        {{ item.page }}
      </span>
      <span *ngIf="item.type == 'separator'" class="pagination__pages-item pagination__pages-item_disabled">
        ...
      </span>
    </ng-container>
  </div>

  <span
    class="pagination__control icon-arrow_forward_2"
    [class.pagination__control_disabled]="!nextPage"
    (click)="openPage(nextPage)"
  >
  </span>
</div>
