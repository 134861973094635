var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormControl, FormGroup, Validators } from '@angular/forms';
import shuffle from 'lodash/shuffle';
import * as moment from 'moment';
import { throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { FormUtils } from '@common/form-utils';
import { LocalStorage } from '@core';
import { Fill, FillSettings, FillType } from '@modules/customize';
import { MenuBlock, MenuBlockLayout, MenuBlockLayouts, MenuGeneratorService, MenuItemSystemActionType } from '@modules/menu';
import { Project, ProjectDeployment, ProjectService, ProjectsStore } from '@modules/projects';
import { isSet } from '@shared';
export var ProductTypeValue;
(function (ProductTypeValue) {
    ProductTypeValue["CRM"] = "crm";
    ProductTypeValue["Dashboard"] = "dashboard";
    ProductTypeValue["InternalTool"] = "internal tool";
    ProductTypeValue["CustomPortal"] = "custom portal";
    ProductTypeValue["Other"] = "other";
})(ProductTypeValue || (ProductTypeValue = {}));
var ProjectCreateProjectForm = /** @class */ (function (_super) {
    __extends(ProjectCreateProjectForm, _super);
    function ProjectCreateProjectForm(formUtils, projectService, localStorage, projectsStore, menuGeneratorService) {
        var _this = _super.call(this, {
            name: new FormControl('', Validators.required),
            deployment: new FormControl(ProjectDeployment.Cloud, Validators.required),
            product_type: new FormControl('', Validators.required),
            product_type_custom: new FormControl('')
            // type: new FormControl(ProjectType.InternalTool)
        }) || this;
        _this.formUtils = formUtils;
        _this.projectService = projectService;
        _this.localStorage = localStorage;
        _this.projectsStore = projectsStore;
        _this.menuGeneratorService = menuGeneratorService;
        _this.productTypeOptions = shuffle([
            {
                value: ProductTypeValue.CRM,
                name: 'Custom CRM for internal use',
                icon: 'human_being'
            },
            {
                value: ProductTypeValue.Dashboard,
                name: 'Dashboard with business KPIs',
                icon: 'chart'
            },
            {
                value: ProductTypeValue.InternalTool,
                name: 'Internal tool to help manage my business',
                icon: 'dashboard'
            },
            {
                value: ProductTypeValue.CustomPortal,
                name: 'Portal for my existing customers or partners',
                icon: 'versions'
            }
        ]).concat([
            {
                value: ProductTypeValue.Other,
                name: 'Other',
                icon: 'blocks'
            }
        ]);
        _this.markAsSubmit(false);
        return _this;
    }
    ProjectCreateProjectForm.prototype.markAsSubmit = function (submit) {
        this['submit'] = submit;
        if (submit) {
            this.markAsDirty();
        }
    };
    ProjectCreateProjectForm.prototype.getAbout = function () {
        return this.controls.product_type.value == ProductTypeValue.Other && isSet(this.controls.product_type_custom.value)
            ? this.controls.product_type_custom.value
            : this.controls.product_type.value;
    };
    ProjectCreateProjectForm.prototype.create = function () {
        var _this = this;
        this.markAsSubmit(true);
        // const value = this.form.value;
        var instance = new Project();
        var name = this.projectService.popNewProjectName();
        instance.name = isSet(name) ? name : this.controls.name.value;
        // instance.type = value['type'];
        instance.deployment = this.controls.deployment.value;
        instance.about = this.getAbout();
        var primaryBlock = new MenuBlock();
        var menuDefaultColor = MenuBlockLayouts.getDefaultColor(MenuBlockLayout.TopThin);
        primaryBlock.generateUid();
        primaryBlock.layout = MenuBlockLayout.TopThin;
        primaryBlock.fillSettings = new FillSettings({
            fill: new Fill({ type: FillType.Color, color: menuDefaultColor }),
            fillDark: new Fill({ type: FillType.Color, color: menuDefaultColor })
        });
        primaryBlock.startItems = [this.menuGeneratorService.createProjectMenuItem(primaryBlock.layout)];
        primaryBlock.centerItems = [this.menuGeneratorService.createSystemMenuItem(MenuItemSystemActionType.Home)];
        primaryBlock.endItems = [this.menuGeneratorService.createUserMenuItem(primaryBlock.layout)];
        var secondaryBlock = new MenuBlock();
        secondaryBlock.generateUid();
        secondaryBlock.layout = MenuBlockLayout.TopThin;
        var secondaryState = this.menuGeneratorService.getDefaultBlockState();
        if (secondaryState.startItems) {
            secondaryBlock.startItems = secondaryState.startItems;
        }
        if (secondaryState.centerItems) {
            secondaryBlock.centerItems = secondaryState.centerItems;
        }
        if (secondaryState.endItems) {
            secondaryBlock.endItems = secondaryState.endItems;
        }
        instance.menuSettings = {
            blocks: [primaryBlock, secondaryBlock]
        };
        return this.projectService.create(instance, undefined, ['name', 'params', 'menu_settings']).pipe(tap(function (result) { return _this.projectsStore.addItem(result); }), map(function (project) {
            return {
                project: project
            };
        }), 
        // switchMap(project => {
        //   if (!value['add_page']) {
        //     return of({
        //       project: project
        //     });
        //   }
        //
        //   return this.viewSettingsQueries
        //     .createPage({
        //       project: project,
        //       view: ViewSettingsType.Custom,
        //       name: value['add_page'],
        //       addToMenu: true
        //     })
        //     .pipe(
        //       map(page => {
        //         return {
        //           project: project,
        //           page: page
        //         };
        //       })
        //     );
        // }),
        tap(function (result) {
            _this.localStorage.set(result.project.dateCreatedKey, moment().toISOString());
        }), catchError(function (error) {
            _this.formUtils.showFormErrors(_this, error);
            return throwError(error);
        }));
    };
    return ProjectCreateProjectForm;
}(FormGroup));
export { ProjectCreateProjectForm };
