var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AdminMode } from '@modules/admin-mode';
import { CurrentEnvironmentStore, CurrentProjectStore, ProjectSettingsService } from '@modules/projects';
import { SingletonStore } from '@shared';
import { AllProjectSettings } from '../data/all-project-settings';
var ProjectSettingsStore = /** @class */ (function (_super) {
    __extends(ProjectSettingsStore, _super);
    function ProjectSettingsStore(mode$, projectSettingsService, currentProjectStore, currentEnvironmentStore) {
        var _this = _super.call(this) || this;
        _this.mode$ = mode$;
        _this.projectSettingsService = projectSettingsService;
        _this.currentProjectStore = currentProjectStore;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        return _this;
    }
    ProjectSettingsStore.prototype.fetch = function () {
        var _this = this;
        if (!this.currentProjectStore.instance) {
            return of(undefined);
        }
        return this.mode$.pipe(switchMap(function (mode) {
            return _this.projectSettingsService.get(_this.currentProjectStore.instance.uniqueName, _this.currentEnvironmentStore.instance.uniqueName, mode == AdminMode.Builder);
        }));
    };
    ProjectSettingsStore.prototype.get = function (forceUpdate, options) {
        if (forceUpdate === void 0) { forceUpdate = false; }
        if (options === void 0) { options = {}; }
        return _super.prototype.get.call(this, forceUpdate, options).pipe(map(function (result) {
            if (!result) {
                return result;
            }
            return result.filter(function (item) { return options.includeDeleted || !item.deleted; });
        }));
    };
    ProjectSettingsStore.prototype.getFirst = function (forceUpdate, options) {
        if (forceUpdate === void 0) { forceUpdate = false; }
        if (options === void 0) { options = {}; }
        return _super.prototype.getFirst.call(this, forceUpdate, options).pipe(map(function (result) {
            if (!result) {
                return result;
            }
            return result.filter(function (item) { return options.includeDeleted || !item.deleted; });
        }));
    };
    ProjectSettingsStore.prototype.createAllProjectSettings = function (settings) {
        return new AllProjectSettings().deserialize(settings);
    };
    ProjectSettingsStore.prototype.getAllSettings = function () {
        var result = this.instance ? this.instance.filter(function (item) { return !item.deleted; }) : undefined;
        return result ? this.createAllProjectSettings(result) : undefined;
    };
    ProjectSettingsStore.prototype.getAllSettings$ = function (forceUpdate) {
        var _this = this;
        if (forceUpdate === void 0) { forceUpdate = false; }
        return this.get(forceUpdate).pipe(map(function (result) {
            return result ? _this.createAllProjectSettings(result) : undefined;
        }));
    };
    ProjectSettingsStore.prototype.getAllSettingsFirst$ = function (forceUpdate) {
        var _this = this;
        if (forceUpdate === void 0) { forceUpdate = false; }
        return this.getFirst(forceUpdate).pipe(map(function (result) {
            return result ? _this.createAllProjectSettings(result) : undefined;
        }));
    };
    ProjectSettingsStore.prototype.addItem = function (settings) {
        this.instance = this.instance.concat([settings]);
    };
    ProjectSettingsStore.prototype.updateItem = function (settings) {
        var index = this.instance.findIndex(function (item) { return item.name == settings.name; });
        if (index !== -1) {
            this.instance = this.instance.slice(0, index).concat([settings], this.instance.slice(index + 1));
        }
    };
    ProjectSettingsStore.prototype.updateOrAddItem = function (settings) {
        var index = this.instance.findIndex(function (item) { return item.name == settings.name; });
        if (index !== -1) {
            this.instance = this.instance.slice(0, index).concat([settings], this.instance.slice(index + 1));
        }
        else {
            this.addItem(settings);
        }
    };
    return ProjectSettingsStore;
}(SingletonStore));
export { ProjectSettingsStore };
