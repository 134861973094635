import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { FillSettings } from '@modules/customize';
import { controlValue, isSet } from '@shared';

import { FillControl } from './fill.control';

export class FillSettingsControl extends FormGroup {
  instance: FillSettings;

  controls: {
    fill: FillControl;
    fill_dark: FillControl;
  };

  constructor(state: Partial<FillSettings> = {}) {
    super({
      fill: new FillControl(isSet(state.fill) ? state.fill : undefined),
      fill_dark: new FillControl(isSet(state.fillDark) ? state.fillDark : undefined)
    });
  }

  deserialize(value?: FillSettings, options: { emitEvent?: boolean } = {}) {
    this.instance = value;

    this.controls.fill.deserialize(value ? value.fill : undefined, { emitEvent: options.emitEvent });
    this.controls.fill_dark.deserialize(value ? value.fillDark : undefined, { emitEvent: options.emitEvent });
  }

  isSet(): boolean {
    return this.controls.fill.isSet() || this.controls.fill_dark.isSet();
  }

  isSet$(): Observable<boolean> {
    return controlValue(this).pipe(map(() => this.isSet()));
  }

  reset(options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    this.controls.fill.reset(options);
    this.controls.fill_dark.reset(options);
  }

  resetDefaults(options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    this.controls.fill.resetDefaults(options);
    this.controls.fill_dark.resetDefaults(options);
  }

  getInstance(instance?: FillSettings): FillSettings {
    if (!instance) {
      instance = new FillSettings();
    }

    instance.fill = this.controls.fill.getInstance(instance.fill);
    instance.fillDark = this.controls.fill_dark.getInstance(instance.fillDark);

    return instance;
  }

  serialize(reuseInstance = true): FillSettings {
    if (!this.isSet()) {
      return;
    }

    return this.getInstance(reuseInstance ? this.instance : undefined);
  }

  serialize$(): Observable<FillSettings> {
    return controlValue(this, { debounce: 200 }).pipe(map(() => this.serialize()));
  }
}
