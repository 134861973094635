import { ComponentFactoryResolver, Injectable, Injector } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { ThinDialogPopupComponent } from '@common/dialog-popup';
import { PopupRef, PopupService } from '@common/popups';
import { Environment, Project, Resource, ResourceTypeItem } from '@modules/projects';
import { ResourceGeneratorResolver } from '@modules/resource-generators';
import { isSet } from '@shared';

import { ChooseSyncModeComponent } from '../../components/choose-sync-mode/choose-sync-mode.component';
import { ConfirmSyncModeComponent } from '../../components/confirm-sync-mode/confirm-sync-mode.component';

@Injectable()
export class ChooseSyncController {
  constructor(
    private resourceGeneratorResolver: ResourceGeneratorResolver,
    private injector: Injector,
    private popupService: PopupService,
    private resolver: ComponentFactoryResolver
  ) {}

  chooseSyncMode(
    control: AbstractControl,
    options: {
      typeItem: ResourceTypeItem;
      paramsOptions?: Object;
    }
  ): Observable<{ paramsOptions?: Object }> {
    const result = new Subject<{ paramsOptions?: Object }>();

    this.popupService.push({
      component: ChooseSyncModeComponent,
      popupComponent: ThinDialogPopupComponent,
      inputs: {
        control: control,
        typeItem: options.typeItem,
        paramsOptions: options.paramsOptions
      },
      outputs: {
        chosen: [
          value => {
            result.next(value);
          }
        ]
      },
      resolver: this.resolver,
      injector: this.injector
    });

    return result.asObservable();
  }

  showConfirmSyncMode(
    object: string,
    sync: boolean,
    options: {
      project: Project;
      environment: Environment;
      resource: Resource;
      typeItem: ResourceTypeItem;
      closeOnConfirm?: boolean;
    }
  ): PopupRef<ConfirmSyncModeComponent> {
    return this.popupService.showComponent<ConfirmSyncModeComponent>({
      component: ConfirmSyncModeComponent,
      injector: this.injector,
      inputs: {
        object: object,
        sync: sync,
        resource: options.resource,
        typeItem: options.typeItem,
        ...(isSet(options.closeOnConfirm) && { closeOnConfirm: options.closeOnConfirm })
      },
      scrollable: true
    });
  }

  confirmSyncMode(
    object: string,
    sync: boolean,
    options: { project: Project; environment: Environment; resource: Resource; typeItem: ResourceTypeItem }
  ): Observable<{ resourceParams?: Object; syncInterval?: number }> {
    const popupRef = this.showConfirmSyncMode(object, sync, { ...options, closeOnConfirm: true });

    return popupRef.instance.confirm.pipe(
      map(result => {
        return {
          resourceParams: this.getConfirmSyncModeResourceParams(options.resource, result),
          syncInterval: this.getConfirmSyncModeSyncInterval(options.resource, result)
        };
      })
    );
  }

  getConfirmSyncModeResourceParams(resource: Resource, result: { paramsOptions?: Object }): Object {
    const generator = this.resourceGeneratorResolver.get(resource.typeItem.name);

    if (result && result.paramsOptions && generator.generateResourceParams) {
      return generator.generateResourceParams(result.paramsOptions);
    }
  }

  getConfirmSyncModeSyncInterval(resource: Resource, result: { paramsOptions?: Object }): number {
    const generator = this.resourceGeneratorResolver.get(resource.typeItem.name);

    if (result && result.paramsOptions && generator.generateSyncInterval) {
      return generator.generateSyncInterval(result.paramsOptions);
    }
  }
}
