<td class="card-table__column">
  <div class="card-table__column-inner">
    <strong>{{ item.label }}</strong>
    <span *ngIf="!item.singleItem" class="card-table__additional">({{ item.count }})</span>
  </div>
</td>

<td class="card-table__column">
  <div class="card-table__column-inner card-table__column-inner_truncate" style="max-width: 340px;">
    <div>
      <ng-container *ngFor="let child of item.items; let last = last">
        {{ child.name }}<ng-container *ngIf="!last">, </ng-container>
      </ng-container>
    </div>
  </div>
</td>

<td class="card-table__column">
  <div class="card-table__column-inner card-table__column-inner_right">
    <button
      type="button"
      class="button button_small button_orange-border"
      [disabled]="disabled"
      (click)="$event.stopPropagation(); publishAll()"
    >
      <span class="button__icon button__icon_left icon-cloud_upload"></span>
      <span class="button__label">Publish</span>
    </button>

    <button
      type="button"
      class="button button_small button_orange-border"
      [disabled]="disabled"
      (click)="$event.stopPropagation(); discardAll()"
    >
      <span class="button__icon button__icon_left icon-bin"></span>
      <span class="button__label">Discard</span>
    </button>
  </div>
</td>

<td class="card-table__column" style="width: 0;">
  <div class="card-table__column-inner card-table__column-inner_right">
    <div
      *ngIf="!item.singleItem"
      class="button-icon2 button-icon2_size_s button-icon2_circle"
      [class.button-icon2_hover]="hover"
      [class.button-icon2_active]="expanded"
      [class.icon-arrow_forward_2]="!expanded"
      [class.icon-arrow_down_2]="expanded"
    ></div>
  </div>
</td>

<ng-template #secondRow>
  <tr *ngIf="expanded">
    <td colspan="4">
      <div class="card-table__expand">
        <div *ngFor="let child of item.items" class="card-table__expand-item">
          <div class="card-table__expand-item-left">
            <!--            <span-->
            <!--              *ngIf="child.type == changeTypes.Created"-->
            <!--              class="status color_white_1 background-color_green_1"-->
            <!--            >-->
            <!--              Created-->
            <!--            </span>-->
            <span
              *ngIf="child.type == changeTypes.Created || child.type == changeTypes.Updated"
              class="status color_white_1 background-color_green_1"
            >
              Updated
            </span>
            <span *ngIf="child.type == changeTypes.Deleted" class="status color_white_1 background-color_red_1">
              Deleted
            </span>
          </div>

          <div class="card-table__expand-item-main">
            <span class="card-table__expand-item-label">{{ child.name }}</span>
          </div>

          <div class="card-table__expand-item-right card-table__expand-item-right_hover">
            <button
              type="button"
              class="button button_extra-small button_orange-border card-table__expand-item-action"
              [disabled]="disabled"
              (click)="$event.stopPropagation(); publishItem(child.id)"
            >
              <span class="button__icon button__icon_left icon-cloud_upload"></span>
              <span class="button__label">Publish</span>
            </button>

            <button
              type="button"
              class="button button_extra-small button_orange-border card-table__expand-item-action"
              [disabled]="disabled"
              (click)="$event.stopPropagation(); discardItem(child.id)"
            >
              <span class="button__icon button__icon_left icon-bin"></span>
              <span class="button__label">Discard</span>
            </button>
          </div>
        </div>
      </div>
    </td>
  </tr>
</ng-template>
