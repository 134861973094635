var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import pickBy from 'lodash/pickBy';
import { localize } from '@common/localize';
import { ActionItem } from '@modules/actions';
import { defaultDateFormat, defaultTimeFormat, Input } from '@modules/fields';
import { isSet } from '@shared';
import { TextStyle } from '../../text-style';
import { registerElementForType } from '../element-items';
import { ElementType } from '../element-type';
import { ElementItem } from './base';
var DateRangeElementItem = /** @class */ (function (_super) {
    __extends(DateRangeElementItem, _super);
    function DateRangeElementItem() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.type = ElementType.DateRange;
        _this.time = true;
        _this.dateFormat = defaultDateFormat;
        _this.timeFormat = defaultTimeFormat;
        _this.required = false;
        _this.fromPlaceholder = '';
        _this.toPlaceholder = '';
        _this.resetEnabled = false;
        _this.onChangeActions = [];
        return _this;
    }
    DateRangeElementItem.prototype.deserialize = function (data) {
        _super.prototype.deserialize.call(this, data);
        this.width = this.params['width'];
        this.verboseName = this.params['verbose_name'];
        this.required = this.params['required'];
        this.tooltip = this.params['tooltip'];
        if (this.params['label_style']) {
            this.labelStyle = new TextStyle().deserialize(this.params['label_style']);
        }
        else {
            this.labelStyle = undefined;
        }
        if (this.params['label_additional'] === undefined && this.required) {
            // Backward compatibility
            this.labelAdditional = "(" + localize('optional') + ")";
        }
        else {
            this.labelAdditional = this.params['label_additional'];
        }
        if (this.params['label_additional_style']) {
            this.labelAdditionalStyle = new TextStyle().deserialize(this.params['label_additional_style']);
        }
        else {
            this.labelAdditionalStyle = undefined;
        }
        if (this.params['from']) {
            this.from = new Input().deserialize(this.params['from']);
        }
        if (this.params['to']) {
            this.to = new Input().deserialize(this.params['to']);
        }
        if (isSet(this.params['time'])) {
            this.time = this.params['time'];
        }
        if (isSet(this.params['date_format'], true)) {
            this.dateFormat = this.params['date_format'];
        }
        if (isSet(this.params['time_format'], true)) {
            this.timeFormat = this.params['time_format'];
        }
        if (isSet(this.params['from_placeholder'], true)) {
            this.fromPlaceholder = this.params['from_placeholder'];
        }
        if (isSet(this.params['to_placeholder'], true)) {
            this.toPlaceholder = this.params['to_placeholder'];
        }
        if (isSet(this.params['reset_enabled'])) {
            this.resetEnabled = this.params['reset_enabled'];
        }
        if (this.params['disable_input']) {
            this.disableInput = new Input().deserialize(this.params['disable_input']);
        }
        if (this.params['on_change_actions']) {
            this.onChangeActions = this.params['on_change_actions'].map(function (item) { return new ActionItem().deserialize(item); });
        }
        return this;
    };
    DateRangeElementItem.prototype.serialize = function (fields) {
        this.params = {
            width: this.width,
            verbose_name: this.verboseName,
            label_style: this.labelStyle ? this.labelStyle.serialize() : undefined,
            label_additional: this.labelAdditional,
            label_additional_style: this.labelAdditionalStyle ? this.labelAdditionalStyle.serialize() : undefined,
            from: this.from ? this.from.serialize() : null,
            to: this.to ? this.to.serialize() : null,
            time: this.time,
            date_format: this.dateFormat,
            time_format: this.timeFormat,
            required: this.required,
            from_placeholder: this.fromPlaceholder,
            to_placeholder: this.toPlaceholder,
            reset_enabled: this.resetEnabled,
            disable_input: this.disableInput ? this.disableInput.serialize() : null,
            tooltip: this.tooltip,
            on_change_actions: this.onChangeActions.map(function (item) { return item.serialize(); })
        };
        var data = _super.prototype.serialize.call(this);
        if (fields) {
            data = pickBy(data, function (v, k) { return fields.includes(k); });
        }
        return data;
    };
    Object.defineProperty(DateRangeElementItem.prototype, "typeStr", {
        get: function () {
            return 'date range';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateRangeElementItem.prototype, "analyticsName", {
        get: function () {
            return 'date_range';
        },
        enumerable: true,
        configurable: true
    });
    DateRangeElementItem.prototype.defaultName = function () {
        return 'Date range';
    };
    return DateRangeElementItem;
}(ElementItem));
export { DateRangeElementItem };
registerElementForType(ElementType.DateRange, DateRangeElementItem);
