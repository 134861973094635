<section class="layout-editor">
  <div class="layout-editor__inner">
    <div class="layout-editor__content">
      <div class="layout-editor__layout browser-window">
        <div class="browser-window__header">
          <app-browser-header
            [title]="(domain?.name | appIsSet) ? domain?.name : metaService.jetMetaData.titleShort"
            [address]="signUpLink"
            [addressIcon]="domain?.icon"
          ></app-browser-header>
        </div>
        <div class="browser-window__content browser-window__content_scrollable" #container>
          <div class="browser-window__content-inner">
            <app-register-template
              *ngIf="displayComponent == displayComponents.SignUp"
              [domain]="domain"
              [customFields]="signUpFields"
              [preview]="true"
            ></app-register-template>

            <app-login-template
              *ngIf="displayComponent == displayComponents.SignIn"
              [domain]="domain"
              [currentSSO]="currentSSO"
              [preview]="true"
            ></app-login-template>
          </div>
        </div>
      </div>
    </div>

    <div class="layout-editor__sidebar-navigation">
      <div class="sidebar-navigation" [class.sidebar-navigation_interactive]="!loading">
        <div class="sidebar-navigation__background"></div>
        <div class="sidebar-navigation__active-background"></div>

        <div *ngIf="!loading" class="sidebar-navigation__items">
          <div *ngFor="let item of menuItems" class="sidebar-navigation__item">
            <a
              [appLink]="project?.settingsSignUpLink(item.type)"
              class="sidebar-navigation-item"
              [class.sidebar-navigation-item_active]="item.type == selectedMenuItemType"
              (click)="onMenuItemClicked(item.type)"
            >
              <div class="sidebar-navigation-item__active-background"></div>
              <div class="sidebar-navigation-item__additional">
                <div class="sidebar-navigation-item__title">{{ item.title }}</div>
                <div class="sidebar-navigation-item__subtitle">{{ item.subtitle }}</div>
              </div>
              <div class="sidebar-navigation-item__content">
                <div class="sidebar-navigation-item__icon" [ngClass]="['icon-' + item.icon]"></div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="layout-editor__sidebar">
      <div *ngIf="!loading" class="sidebar">
        <div class="sidebar__scrollable sidebar__scrollable_native" #root>
          <div *ngFor="let item of settingsComponents; let last = last" [hidden]="!last">
            <app-dynamic-component [componentData]="item"></app-dynamic-component>
          </div>
        </div>
      </div>

      <app-sidebar-empty *ngIf="loading" [animating]="true"></app-sidebar-empty>
    </div>
  </div>
</section>
