var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, EventEmitter, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import groupBy from 'lodash/groupBy';
import range from 'lodash/range';
import * as moment from 'moment';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { ActionService } from '@modules/action-queries';
import { TintStyle } from '@modules/actions';
import { AggregateFunc, DatasetGroupLookup } from '@modules/charts';
import { CalendarSettings } from '@modules/customize';
import { DataSourceType } from '@modules/data-sources';
import { gteFieldLookup, lteFieldLookup } from '@modules/field-lookups';
import { FilterItem2 } from '@modules/filters';
import { ModelService } from '@modules/model-queries';
import { DATE_PARAM, TYPE_PARAM } from '@modules/models';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { ChartWidgetQuery } from '@modules/queries';
import { getQueryOptionsToParams, paramsToGetQueryOptions } from '@modules/resources';
import { CalendarType } from '../../data/calendar-type';
var CalendarYearComponent = /** @class */ (function () {
    function CalendarYearComponent(modelService, actionService, currentProjectStore, currentEnvironmentStore, cd) {
        this.modelService = modelService;
        this.actionService = actionService;
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.cd = cd;
        this.queryParamsChanged = new EventEmitter();
        this.loading = false;
        this.months = [];
        this.items = {};
        this.itemsGroupBy = 'DD.MM.YYYY';
        this.tintStyles = TintStyle;
    }
    CalendarYearComponent.prototype.ngOnInit = function () { };
    CalendarYearComponent.prototype.ngOnDestroy = function () { };
    CalendarYearComponent.prototype.ngOnChanges = function (changes) {
        this.updateMonths();
        this.updateItems(this.listState);
    };
    Object.defineProperty(CalendarYearComponent.prototype, "now", {
        get: function () {
            return moment();
        },
        enumerable: true,
        configurable: true
    });
    CalendarYearComponent.prototype.updateMonths = function () {
        var _this = this;
        this.months = range(0, 12).map(function (month) {
            var monthStart = _this.date.clone().startOf('year').add(month, 'months');
            var firstDay = monthStart.clone().startOf('isoWeek');
            var lastDay = monthStart.clone().endOf('month').endOf('isoWeek');
            var weeks = Math.ceil(lastDay.diff(firstDay, 'weeks', true));
            return {
                date: monthStart,
                weekDays: range(0, 7).map(function (day) {
                    var date = firstDay.clone().add(day, 'days');
                    return {
                        date: date,
                        weekend: [6, 0].includes(date.day()),
                        today: date.isSame(_this.now, 'day') && date.isSame(_this.now, 'month') && date.isSame(_this.now, 'year')
                    };
                }),
                weeks: range(0, weeks).map(function (week) {
                    return range(0, 7).map(function (day) {
                        var date = firstDay.clone().add(week, 'weeks').add(day, 'days');
                        return {
                            date: date,
                            today: date.isSame(_this.now, 'day') && date.isSame(_this.now, 'month') && date.isSame(_this.now, 'year'),
                            currentMonth: date.isSame(monthStart, 'month') && date.isSame(monthStart, 'year'),
                            weekend: [6, 0].includes(date.day()),
                            future: date.isAfter(_this.now)
                        };
                    });
                })
            };
        });
        this.cd.markForCheck();
    };
    CalendarYearComponent.prototype.updateItems = function (state) {
        var _this = this;
        if (this.fetchSubscription) {
            this.fetchSubscription.unsubscribe();
            this.fetchSubscription = undefined;
        }
        this.items = {};
        this.cd.markForCheck();
        if (!this.settings.dataSource || !this.settings.dataSource.query) {
            return;
        }
        var resource = this.settings.dataSource && this.settings.dataSource.type == DataSourceType.Query
            ? this.currentEnvironmentStore.resources.find(function (item) { return item.uniqueName == _this.settings.dataSource.queryResource; })
            : undefined;
        var modelQuery = this.settings.dataSource.query;
        var query = new ChartWidgetQuery();
        query.queryType = modelQuery.queryType;
        query.simpleQuery = modelQuery.simpleQuery;
        query.httpQuery = modelQuery.httpQuery;
        query.sqlQuery = modelQuery.sqlQuery;
        var queryOptions = paramsToGetQueryOptions(state.dataSourceParams);
        var firstDay = this.date.clone().startOf('year');
        var lastDay = this.date.clone().endOf('year');
        queryOptions.filters = (queryOptions.filters ? queryOptions.filters : []).concat((state.filters ? state.filters : []), [
            new FilterItem2({
                field: [state.dateField],
                lookup: gteFieldLookup,
                value: firstDay.toISOString()
            }),
            new FilterItem2({
                field: [state.dateField],
                lookup: lteFieldLookup,
                value: lastDay.toISOString()
            })
        ]);
        var params = getQueryOptionsToParams(queryOptions);
        this.loading = true;
        this.cd.markForCheck();
        this.fetchSubscription = this.modelService
            .groupQuery(this.currentProjectStore.instance, this.currentEnvironmentStore.instance, resource, query, [{ xColumn: this.settings.dateField, xLookup: DatasetGroupLookup.DateDay }], AggregateFunc.Count, undefined, state.dataSource.queryParameters, this.applyParams(params))
            .pipe(untilDestroyed(this))
            .subscribe(function (items) {
            _this.items = items ? groupBy(items, function (item) { return moment(item['group']).format(_this.itemsGroupBy); }) : {};
            _this.loading = false;
            _this.cd.markForCheck();
        }, function () {
            _this.loading = false;
            _this.cd.markForCheck();
        });
    };
    CalendarYearComponent.prototype.monthParams = function (date) {
        var params = {};
        params[TYPE_PARAM] = CalendarType.Month;
        params[DATE_PARAM] = date.toISOString();
        return this.applyParams(params);
    };
    CalendarYearComponent.prototype.weekParams = function (date) {
        var params = {};
        params[TYPE_PARAM] = CalendarType.Week;
        params[DATE_PARAM] = date.toISOString();
        return this.applyParams(params);
    };
    CalendarYearComponent.prototype.applyParams = function (params) {
        if (params === void 0) { params = {}; }
        //   return {
        //     ...applyParamInputs(this.params, this.settings.inputs, {
        //       context: this.contextElement.context,
        //       contextElement: this.contextElement,
        //       parameters: this.settings.parameters
        //     }),
        //     ...params
        //   };
        return params;
    };
    CalendarYearComponent.prototype.setParams = function (params) {
        this.queryParamsChanged.emit(__assign({}, this.params, params));
    };
    return CalendarYearComponent;
}());
export { CalendarYearComponent };
