import { Injectable, Injector } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import { combineLatest, Observable, of } from 'rxjs';
import { delayWhen, filter, switchMap } from 'rxjs/operators';

import { DialogButtonHotkey, DialogButtonType, DialogService } from '@common/dialogs';
import { ApproveResult, ApproveResultState } from '@modules/actions';
import { Task, TaskQueueStore, TaskService } from '@modules/collaboration';
import { ViewContext } from '@modules/customize';
import { FieldType } from '@modules/fields';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';

import { ActionControllerService } from '../action-controller/action-controller.service';

@Injectable()
export class ActionApproveService {
  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private actionControllerService: ActionControllerService,
    private taskService: TaskService,
    private taskQueueStore: TaskQueueStore,
    private dialogService: DialogService
  ) {}

  setApproveResult(
    task: Task,
    state: ApproveResultState,
    status: string,
    result?: any,
    reason?: string
  ): Observable<Task> {
    return this.taskQueueStore.getDetailFirst(task.queue).pipe(
      switchMap(queue => {
        const instance = cloneDeep(task);
        const approveResult = new ApproveResult();
        const fields = ['params'];

        approveResult.state = state;
        approveResult.result = result;
        approveResult.reason = reason;

        instance.approveActionResult = approveResult;

        if (status) {
          instance.status = queue.statuses.find(item => item.uid == status);
          fields.push('status');
        }

        return this.taskService.update(
          this.currentProjectStore.instance.uniqueName,
          this.currentEnvironmentStore.instance.uniqueName,
          instance,
          ['status', 'params']
        );
      })
    );
  }

  approve(task: Task, options: { context?: ViewContext; injector?: Injector } = {}): Observable<Task> {
    const approve = task.approveAction.approve;
    const params = task.approveActionParams || {};

    return this.actionControllerService
      .executeAction(task.approveAction, params, {
        context: options.context,
        injector: options.injector,
        analyticsSource: 'task_approve'
      })
      .pipe(
        switchMap(result => {
          return this.setApproveResult(task, ApproveResultState.Approved, approve.taskApproveStatus, result);
        })
      );
  }

  reject(task: Task, options: { context?: ViewContext; injector?: Injector } = {}): Observable<Task> {
    const approve = task.approveAction.approve;
    return this.dialogService
      .prompt({
        title: 'Reason',
        description: 'Please specify rejection reason',
        inputs: [
          {
            name: 'reason',
            label: 'Reason',
            type: FieldType.Text,
            placeholder: 'Enter a reason'
          }
        ],
        buttons: [
          {
            name: 'cancel',
            label: 'Cancel',
            type: DialogButtonType.Default,
            hotkey: DialogButtonHotkey.Cancel
          },
          {
            name: 'reject',
            label: 'Reject',
            type: DialogButtonType.Danger,
            hotkey: DialogButtonHotkey.Submit
          }
        ]
      })
      .pipe(
        filter(result => result.button == 'reject'),
        switchMap(result => {
          return this.setApproveResult(
            task,
            ApproveResultState.Rejected,
            approve.taskRejectStatus,
            undefined,
            result.form['reason']
          );
        }),
        delayWhen(() => {
          if (approve.onRejectActions.length) {
            return combineLatest(
              approve.onRejectActions.map(item =>
                this.actionControllerService.execute(item, {
                  context: options.context,
                  // contextElement: options.contextElement,
                  // localContext: options.localContext,
                  injector: options.injector,
                  showSuccess: false
                })
              )
            );
          } else {
            return of(undefined);
          }
        })
      );
  }
}
