var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { AfterViewInit, ChangeDetectorRef, ElementRef, EventEmitter, Injector, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NavigationStart, Router } from '@angular/router';
import { Power2, TimelineMax } from 'gsap';
import cloneDeep from 'lodash/cloneDeep';
import { SelectComponent } from 'ng-gxselect';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fromEvent, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { NotificationService } from '@common/notifications';
import { BasePopupComponent } from '@common/popups';
import { ActionApproveService } from '@modules/action-queries';
import { ApproveResultState } from '@modules/actions';
import { UserActivityListStore, UserActivityService } from '@modules/activities';
import { TimelinePopupFooterComponent } from '@modules/activities-components/components/timeline-popup-footer/timeline-popup-footer.component';
import { ServerRequestError } from '@modules/api';
import { TaskPriority, TaskQueueStore, TaskService } from '@modules/collaboration';
import { CustomizeService, ViewContext, ViewSettingsStore } from '@modules/customize';
import { createFormFieldFactory } from '@modules/fields';
import { CurrentEnvironmentStore, CurrentProjectStore, ProjectUserSelectSource } from '@modules/projects';
import { CurrentUserStore, User } from '@modules/users';
import { isSet } from '@shared';
var TaskPopupComponent = /** @class */ (function () {
    function TaskPopupComponent(userActivityService, userActivityListStore, taskQueueStore, projectUserSelectSource, router, customizeService, popupComponent, currentProjectStore, currentEnvironmentStore, taskService, currentUserStore, notificationService, actionApproveService, viewSettingsStore, injector, cd) {
        this.userActivityService = userActivityService;
        this.userActivityListStore = userActivityListStore;
        this.taskQueueStore = taskQueueStore;
        this.projectUserSelectSource = projectUserSelectSource;
        this.router = router;
        this.customizeService = customizeService;
        this.popupComponent = popupComponent;
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.taskService = taskService;
        this.currentUserStore = currentUserStore;
        this.notificationService = notificationService;
        this.actionApproveService = actionApproveService;
        this.viewSettingsStore = viewSettingsStore;
        this.injector = injector;
        this.cd = cd;
        this.updated = new EventEmitter();
        this.closeObsEvent = new EventEmitter();
        this.params = {
            order_by: '-date_add',
            object_type: 'task'
        };
        this.assignedToMe = false;
        this.approveResultStates = ApproveResultState;
        this.tlVisible = new TimelineMax();
        this.userOptions = [];
        this.statusOptions = [];
        this.priorityOptions = [
            { name: 'Lowest', value: TaskPriority.Lowest },
            { name: 'Low', value: TaskPriority.Low },
            { name: 'Medium', value: TaskPriority.Medium },
            { name: 'High', value: TaskPriority.High },
            { name: 'Highest', value: TaskPriority.Highest }
        ];
        this.createField = createFormFieldFactory();
        this.assignedInputVisible = false;
        this.statusInputVisible = false;
        this.priorityInputVisible = false;
        this.taskForm = new FormGroup({
            assigned: new FormControl(null, [Validators.required]),
            status: new FormControl(null, [Validators.required]),
            priority: new FormControl(null, [Validators.required])
        });
        this.paramsList = [];
        this.sendMessage = false;
    }
    TaskPopupComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.projectUserSelectSource.allowEmpty = true;
        this.projectUserSelectSource.userValue = true;
        this.taskService
            .getDetail(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, this.itemUid)
            .pipe(untilDestroyed(this))
            .subscribe(function (task) {
            _this.task = task;
            _this.params = __assign({}, _this.params, { object_id: task.uid });
            _this.getComments();
            _this.getQueue();
            _this.setForm();
            _this.cd.markForCheck();
            _this.updateAssigned();
            _this.updateRelatedObject();
        });
        if (this.closeObs) {
            this.closeObs.pipe(untilDestroyed(this)).subscribe(function () {
                _this.close();
            });
        }
    };
    TaskPopupComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.router.events
            .pipe(filter(function (event) { return event instanceof NavigationStart; }), untilDestroyed(this))
            .subscribe(function () {
            _this.close();
        });
        fromEvent(this.sidePopup.nativeElement, 'wheel')
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            var sidePopupHeight = _this.sidePopup.nativeElement.offsetHeight;
            var sidePopupScrollHeight = _this.sidePopup.nativeElement.scrollHeight;
            var scrollTop = _this.sidePopup.nativeElement.scrollTop;
            var scrollMax = sidePopupScrollHeight - sidePopupHeight;
            if (scrollTop >= scrollMax) {
                _this.userActivityListStore.getNext();
            }
        });
        this.show();
    };
    TaskPopupComponent.prototype.trackByFn = function (index, item) {
        return item.dateAdd;
    };
    TaskPopupComponent.prototype.setFormBottomPadding = function () {
        if (this.formHeight === undefined) {
            this.formHeight = this.footerElement.nativeElement.offsetHeight;
            this.cd.detectChanges();
        }
        else if (this.footerElement.nativeElement.offsetHeight !== this.formHeight) {
            this.formHeight = this.footerElement.nativeElement.offsetHeight;
            this.cd.detectChanges();
        }
    };
    TaskPopupComponent.prototype.getComments = function () {
        var _this = this;
        this.userActivityListStore.projectName = this.currentProjectStore.instance.uniqueName;
        this.userActivityListStore.environmentName = this.currentEnvironmentStore.instance.uniqueName;
        this.userActivityListStore.params = this.params;
        this.userActivityListStore.reset();
        this.userActivityListStore.getNext();
        this.userActivityListStore.items$.pipe(untilDestroyed(this)).subscribe(function (value) {
            _this.userActivities = value;
            _this.cd.markForCheck();
        });
        this.userActivityService
            .subscribeUserActivities(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, this.params['object_type'], this.params['object_id'])
            .pipe(untilDestroyed(this))
            .subscribe(function (value) {
            _this.userActivityListStore.prependItems([value]);
            _this.cd.markForCheck();
        });
    };
    TaskPopupComponent.prototype.updateAssigned = function () {
        this.assignedToMe = this.task && this.task.assigned && this.currentUserStore.instance.uid == this.task.assigned.uid;
        this.cd.detectChanges();
        this.setFormBottomPadding();
    };
    TaskPopupComponent.prototype.updateRelatedObject = function () {
        var _this = this;
        if (!this.task) {
            this.relatedObject = undefined;
            this.cd.markForCheck();
            return;
        }
        if (this.task.objectType == 'custom_page') {
            this.viewSettingsStore
                .getDetailFirst(this.task.objectId)
                .pipe(untilDestroyed(this))
                .subscribe(function (viewSettings) {
                if (!viewSettings) {
                    _this.relatedObject = undefined;
                    _this.cd.markForCheck();
                    return;
                }
                _this.relatedObject = { name: viewSettings.name, link: viewSettings.link };
                _this.cd.markForCheck();
            });
        }
        else {
            this.relatedObject = undefined;
            this.cd.markForCheck();
        }
    };
    TaskPopupComponent.prototype.setForm = function () {
        this.taskForm.patchValue({
            assigned: this.task.assigned ? this.task.assigned.uid : null,
            status: this.task.status.uid,
            priority: this.task.priority
        });
    };
    TaskPopupComponent.prototype.submit = function (event) {
        var _this = this;
        this.sendMessage = true;
        this.cd.markForCheck();
        this.userActivityService
            .createInstance(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, event.activity, event.mentions)
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            _this.footer.reset();
            _this.footer.focus();
            _this.cd.markForCheck();
            _this.sendMessage = false;
            var commentsPosition = _this.sidePopup.nativeElement.scrollTop +
                _this.comments.nativeElement.getBoundingClientRect().top -
                _this.header.nativeElement.getBoundingClientRect().height -
                5;
            _this.sidePopup.nativeElement.scrollTo(0, commentsPosition);
        }, function () {
            _this.sendMessage = false;
            _this.footer.focus();
            _this.cd.markForCheck();
        });
    };
    TaskPopupComponent.prototype.openSelect = function (input) {
        var _this = this;
        if (input === 'assigned') {
            this.assignedInputVisible = true;
        }
        if (input === 'status') {
            this.statusInputVisible = true;
        }
        if (input === 'priority') {
            this.priorityInputVisible = true;
        }
        this.cd.detectChanges();
        setTimeout(function () {
            _this[input].optionsComponent.open();
            _this[input].optionsComponent.touch.pipe(untilDestroyed(_this)).subscribe(function () {
                if (!_this[input].optionsComponent.opened) {
                    setTimeout(function () {
                        if (input === 'assigned') {
                            _this.assignedInputVisible = false;
                        }
                        if (input === 'status') {
                            _this.statusInputVisible = false;
                        }
                        if (input === 'priority') {
                            _this.priorityInputVisible = false;
                        }
                        _this.cd.markForCheck();
                    }, 600);
                }
            });
        }, 100);
    };
    TaskPopupComponent.prototype.inputChanged = function (input) {
        var _this = this;
        var uid = this.taskForm.value[input];
        var taskClone = cloneDeep(this.task);
        if (input === 'assigned') {
            this.assignedInputVisible = false;
            var user = new User();
            user.uid = uid;
            taskClone[input] = user;
        }
        else {
            taskClone[input] = null;
        }
        if (input === 'status') {
            this.statusInputVisible = false;
            taskClone[input] = this.taskStatuses.find(function (status) { return status.uid === uid; });
        }
        if (input === 'priority') {
            this.priorityInputVisible = false;
            taskClone[input] = this.taskForm.value[input];
        }
        this.cd.markForCheck();
        this.taskService
            .update(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, taskClone, [input])
            .pipe(untilDestroyed(this))
            .subscribe(function (task) {
            _this.task = task;
            _this.updated.emit(_this.task);
            _this.cd.detectChanges();
            _this.updateAssigned();
        });
    };
    TaskPopupComponent.prototype.getQueue = function () {
        var _this = this;
        this.taskQueueStore
            .getFirst()
            .pipe(untilDestroyed(this))
            .subscribe(function (queues) {
            _this.currentQueue = queues.find(function (queue) { return queue.uid === _this.task.queue; });
            _this.paramsList = _this.currentQueue.parameters
                .map(function (item) {
                return {
                    type: item.field,
                    name: item.name,
                    value: _this.task.parameterValues[item.name]
                };
            })
                .filter(function (item) { return isSet(item.value); });
            _this.taskStatuses = _this.currentQueue.statuses;
            _this.statusOptions = _this.currentQueue.statuses.map(function (status) {
                return {
                    name: status.name,
                    value: status.uid
                };
            });
        });
    };
    TaskPopupComponent.prototype.show = function () {
        var _this = this;
        this.tlVisible
            .fromTo(this.sidePopupWrap.nativeElement, 0.6, {
            xPercent: 100
        }, {
            xPercent: 0,
            ease: Power2.easeOut
        })
            .add(function () {
            _this.footer.focus();
        });
    };
    TaskPopupComponent.prototype.close = function () {
        var _this = this;
        this.closeObsEvent.emit();
        this.tlVisible
            .clear()
            .set(this.closeButton.nativeElement, {
            pointerEvents: 'none'
        }, 0)
            .to(this.sidePopupWrap.nativeElement, 0.4, {
            xPercent: 100,
            ease: Power2.easeOut
        }, 0)
            .add(function () {
            _this.popupComponent.close();
        });
    };
    TaskPopupComponent.prototype.approve = function () {
        var _this = this;
        this.actionApproveService
            .approve(this.task, { context: this.context, injector: this.injector })
            .pipe(untilDestroyed(this))
            .subscribe(function (task) {
            _this.task = task;
            _this.cd.markForCheck();
            _this.updated.emit(task);
            _this.notificationService.success('Approved', 'Action was successfully approved');
        }, function (error) {
            if (error instanceof ServerRequestError && error.errors.length) {
                _this.notificationService.error('Error', "<strong>Approve</strong> failed: " + error.errors[0]);
            }
            else {
                _this.notificationService.error('Error', error);
            }
        });
    };
    TaskPopupComponent.prototype.reject = function () {
        var _this = this;
        this.actionApproveService
            .reject(this.task, { context: this.context, injector: this.injector })
            .pipe(untilDestroyed(this))
            .subscribe(function (task) {
            _this.task = task;
            _this.cd.markForCheck();
            _this.updated.emit(task);
            _this.notificationService.success('Rejected', 'Action was successfully rejected');
        }, function (error) {
            if (error instanceof ServerRequestError && error.errors.length) {
                _this.notificationService.error('Error', "<strong>Reject</strong> failed: " + error.errors[0]);
            }
            else {
                _this.notificationService.error('Error', error);
            }
        });
    };
    TaskPopupComponent.prototype.ngOnDestroy = function () { };
    return TaskPopupComponent;
}());
export { TaskPopupComponent };
