<div class="view-editor-sidebar" [class.view-editor-sidebar_hidden]="settingsComponents.length">
  <div class="compact-inputs-section">
    <div *ngIf="containerLayer?.flexLayout" class="compact-inputs-element">
      <app-container-layer-control
        [containerLayer]="containerLayer"
        [absoluteControl]="form.controls.absolute_layout"
      ></app-container-layer-control>
    </div>

    <div class="compact-inputs-element">
      <div class="compact-inputs-group">
        <div class="compact-inputs-group__item">
          <app-compact-input
            [formControl]="form.controls.frame.controls.x"
            [postfix]="'X'"
            [number]="true"
          ></app-compact-input>
        </div>

        <div class="compact-inputs-group__item">
          <app-compact-input
            [formControl]="form.controls.frame.controls.y"
            [postfix]="'Y'"
            [number]="true"
          ></app-compact-input>
        </div>

        <div class="compact-inputs-group__item">
          <app-compact-input
            [formControl]="form.controls.frame.controls.rotation"
            [postfix]="'°'"
            [number]="true"
          ></app-compact-input>
        </div>
      </div>
    </div>

    <div class="compact-inputs-element">
      <div class="compact-inputs-group">
        <div class="compact-inputs-group__item">
          <app-compact-input
            [formControl]="form.controls.frame.controls.width"
            [postfix]="'W'"
            [number]="true"
            [numberOptions]="{ min: 0 }"
            (change)="form.updateHeightProportional()"
          ></app-compact-input>
        </div>

        <div class="compact-inputs-group__item">
          <app-compact-input
            [formControl]="form.controls.frame.controls.height"
            [postfix]="'H'"
            [number]="true"
            [numberOptions]="{ min: 0 }"
            (change)="form.updateWidthProportional()"
          ></app-compact-input>
        </div>

        <div class="compact-inputs-group__item compact-inputs-group__item-row">
          <div class="compact-inputs-group__item-row-item">
            <div
              class="compact-button compact-button_fluid"
              [class.compact-button_primary]="form.controls.constrain_proportion.value"
              [class.compact-button_hover]="form.controls.constrain_proportion.value"
              [class.icon-lock_open]="!form.controls.constrain_proportion.value"
              [class.icon-lock_close]="form.controls.constrain_proportion.value"
              (click)="form.toggleConstrainProportion()"
              [appTip]="'Constrain proportions'"
              [appTipOptions]="{ side: 'top' }"
            ></div>
          </div>

          <div class="compact-inputs-group__item-row-item">
            <div
              class="compact-button compact-button_fluid"
              [class.compact-button_primary]="form.controls.reflect_horizontal.value"
              [class.compact-button_hover]="form.controls.reflect_horizontal.value"
              (click)="form.controls.reflect_horizontal.patchValue(!form.controls.reflect_horizontal.value)"
              [appTip]="'Flip horizontal'"
              [appTipOptions]="{ side: 'top' }"
            >
              <span class="icon-reflect"></span>
            </div>
          </div>

          <div class="compact-inputs-group__item-row-item">
            <div
              class="compact-button compact-button_fluid"
              [class.compact-button_primary]="form.controls.reflect_vertical.value"
              [class.compact-button_hover]="form.controls.reflect_vertical.value"
              (click)="form.controls.reflect_vertical.patchValue(!form.controls.reflect_vertical.value)"
              [appTip]="'Flip vertical'"
              [appTipOptions]="{ side: 'top' }"
            >
              <span class="icon-reflect" style="transform: rotate(90deg);"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="compact-inputs-section">
    <div class="compact-inputs-element">
      <app-opacity-control [formControl]="form.controls.opacity"></app-opacity-control>
    </div>

    <div class="compact-inputs-element">
      <app-compact-select
        [formControl]="form.controls.blending_mode"
        [options]="form.blendingModeOptions"
      ></app-compact-select>
    </div>

    <div class="compact-inputs-element">
      <app-compact-border-button
        *ngIf="!form.controls.visible_input_enabled.value"
        [label]="'Conditional Visibility'"
        [interactive]="true"
        [leftIcon]="'eye_2'"
        [leftBackground]="true"
        [rightIcon]="'plus'"
        (click)="form.controls.visible_input_enabled.patchValue(true)"
      ></app-compact-border-button>

      <ng-container *ngIf="form.controls.visible_input_enabled.value">
        <div class="compact-heading">
          Conditional Visibility
        </div>

        <app-input-edit
          [itemForm]="form.controls.visible_input"
          [context]="viewContext"
          [staticValueDisabled]="true"
          [focusedInitial]="form.visibleInputEnabledInitial === false"
          [placeholder]="'Value'"
          [fill]="true"
          [dark]="true"
          [darker]="true"
        ></app-input-edit>
      </ng-container>
    </div>
  </div>

  <div class="compact-inputs-section">
    <div class="compact-inputs-section__header">
      <div class="compact-inputs-section__header-main">
        <div class="compact-inputs-section__header-title">Resizing</div>
      </div>
    </div>

    <div class="compact-inputs-element">
      <app-constraints-control [control]="form.controls.constraints"></app-constraints-control>
    </div>
  </div>

  <div class="compact-inputs-section">
    <div class="compact-inputs-section__header">
      <div class="compact-inputs-section__header-main">
        <div class="compact-inputs-section__header-title">Text</div>
      </div>
    </div>

    <div class="compact-inputs-element">
      <app-compact-font-control
        [control]="form.controls.font.controls.family"
        [globalFonts]="true"
        (fontSelect)="onFontSelect($event)"
      ></app-compact-font-control>
    </div>

    <div class="compact-inputs-element">
      <div class="compact-inputs-row">
        <div class="compact-inputs-row__item compact-inputs-row__item_fill">
          <app-compact-input
            [formControl]="form.controls.font.controls.size"
            [postfix]="'px'"
            [placeholder]="'14'"
            [number]="true"
            [numberOptions]="{ min: 1 }"
            [numberDefault]="14"
          ></app-compact-input>
        </div>

        <div class="compact-inputs-row__item compact-inputs-row__item_fill">
          <app-compact-select
            [formControl]="form.controls.font.controls.type"
            [options]="fontTypeOptions$ | async"
            [widthPx]="118"
            [compareWith]="typeCompareWith"
          ></app-compact-select>
        </div>
      </div>
    </div>

    <div class="compact-inputs-element">
      <div class="compact-inputs-row">
        <div class="compact-inputs-row__item compact-inputs-row__item_fill">
          <app-compact-segmented-control
            [formControl]="form.controls.align_horizontal"
            [options]="form.alignHorizontalOptions"
          ></app-compact-segmented-control>
        </div>

        <div class="compact-inputs-row__item compact-inputs-row__item_fill">
          <div class="compact-label">
            <div class="compact-label__icon compact-label__icon_left icon-text_height"></div>
            <div class="compact-label__content">
              <app-compact-input
                [formControl]="form.controls.line_height"
                [postfix]="'px'"
                [placeholder]="(defaultLineHeight | appIsSet) ? defaultLineHeight.toString() : 'auto'"
                [number]="true"
                [numberOptions]="{ min: 0 }"
                [numberDefault]="(defaultLineHeight | appIsSet) ? defaultLineHeight : 0"
              ></app-compact-input>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="compact-inputs-element">
      <div class="compact-inputs-row">
        <div class="compact-inputs-row__item compact-inputs-row__item_fill">
          <app-compact-segmented-control
            [formControl]="form.controls.align_vertical"
            [options]="form.alignVerticalOptions"
          ></app-compact-segmented-control>
        </div>

        <div class="compact-inputs-row__item compact-inputs-row__item_fill">
          <div class="compact-label">
            <div class="compact-label__icon compact-label__icon_left icon-text_width"></div>
            <div class="compact-label__content">
              <app-compact-input
                [formControl]="letterSpacingControl"
                [postfix]="'%'"
                [placeholder]="'0'"
                [number]="true"
                (change)="setLetterSpacing(letterSpacingControl.value)"
              ></app-compact-input>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="compact-inputs-element">
      <div class="compact-heading">
        Options
      </div>

      <div class="compact-inputs-row">
        <div class="compact-inputs-row__item">
          <app-compact-segmented-control
            [formControl]="form.controls.transform"
            [options]="form.transformOptions"
          ></app-compact-segmented-control>
        </div>

        <div class="compact-inputs-row__item">
          <app-compact-segmented-control
            [formControl]="form.controls.decoration"
            [options]="form.decorationOptions"
          ></app-compact-segmented-control>
        </div>
      </div>
    </div>

    <div class="compact-inputs-element">
      <div class="compact-inputs-row compact-inputs-row_vertical-align_top">
        <div class="compact-inputs-row__item">
          <app-compact-segmented-control
            [formControl]="form.controls.text_bounds"
            [options]="form.textBoundsOptions"
          ></app-compact-segmented-control>

          <div class="compact-inputs-row__subtitle">Resizing</div>
        </div>

        <div class="compact-inputs-row__item">
          <app-compact-segmented-control
            [formControl]="form.controls.truncate"
            [options]="form.textTruncateOptions"
          ></app-compact-segmented-control>

          <div class="compact-inputs-row__subtitle">Truncate text</div>
        </div>

        <div class="compact-inputs-row__item">
          <app-compact-input
            *ngIf="form.controls.truncate.value == textTruncates.Custom"
            [formControl]="form.controls.truncate_max_lines"
            [postfix]="'lines'"
            [placeholder]="'2'"
            [widthPx]="80"
            [number]="true"
            [numberOptions]="{ min: 1 }"
            [numberDefault]="2"
          ></app-compact-input>
        </div>
      </div>
    </div>
  </div>

  <div class="compact-inputs-section">
    <div class="compact-inputs-section__header">
      <div class="compact-inputs-section__header-main">
        <div class="compact-inputs-section__header-title">Fills</div>
      </div>
      <div class="compact-inputs-section__header-right">
        <div
          class="compact-button icon-plus"
          [class.compact-button_primary]="!form.controls.fills.controls.length"
          (click)="form.controls.fills.prependControl()"
        ></div>
      </div>
    </div>

    <div cdkDropList [cdkDropListLockAxis]="'y'" (cdkDropListDropped)="fillsDragDrop($event)">
      <div
        *ngFor="let control of form.controls.fills.controls"
        class="compact-inputs-element"
        cdkDrag
        [cdkDragDisabled]="!!fillControlOpened"
      >
        <app-fill-control
          [control]="control"
          [viewContext]="viewContext"
          [layer]="layer"
          [fillTypesEnabled]="[fillTypes.Color]"
          (colorOpenedChange)="updateFillControlOpened()"
          (remove)="form.controls.fills.removeControl(control)"
        ></app-fill-control>
      </div>
    </div>
  </div>

  <div class="compact-inputs-section">
    <div class="compact-inputs-section__header">
      <div class="compact-inputs-section__header-main">
        <div class="compact-inputs-section__header-title">Borders</div>
      </div>
      <div class="compact-inputs-section__header-right">
        <div
          class="compact-button icon-plus"
          [class.compact-button_primary]="!form.controls.borders.controls.length"
          (click)="form.controls.borders.prependControl()"
        ></div>
      </div>
    </div>

    <div cdkDropList [cdkDropListLockAxis]="'y'" (cdkDropListDropped)="bordersDragDrop($event)">
      <div *ngFor="let control of form.controls.borders.controls" class="compact-inputs-element" cdkDrag>
        <app-border-control
          [control]="control"
          [viewContext]="viewContext"
          (remove)="form.controls.borders.removeControl(control)"
        ></app-border-control>
      </div>
    </div>
  </div>

  <div class="compact-inputs-section">
    <div class="compact-inputs-section__header">
      <div class="compact-inputs-section__header-main">
        <div class="compact-inputs-section__header-title">Shadows</div>
      </div>
      <div class="compact-inputs-section__header-right">
        <div
          class="compact-button icon-plus"
          [class.compact-button_primary]="!form.controls.shadows.controls.length"
          (click)="form.controls.shadows.prependControl()"
        ></div>
      </div>
    </div>

    <div cdkDropList [cdkDropListLockAxis]="'y'" (cdkDropListDropped)="shadowsDragDrop($event)">
      <div *ngFor="let control of form.controls.shadows.controls" class="compact-inputs-element" cdkDrag>
        <app-shadow-control
          [control]="control"
          [viewContext]="viewContext"
          [spreadEnabled]="false"
          [positionEnabled]="false"
          (remove)="form.controls.shadows.removeControl(control)"
        ></app-shadow-control>
      </div>
    </div>
  </div>

  <app-interactions-section
    [control]="form.controls.interactions"
    [viewContext]="viewContext"
    [textInteractions]="true"
  ></app-interactions-section>
</div>

<div
  *ngFor="let item of settingsComponents; let last = last"
  class="view-editor-sidebar view-editor-sidebar_dark view-editor-sidebar_expanded"
  [class.view-editor-sidebar_hidden]="!last"
>
  <app-dynamic-component [componentData]="item"></app-dynamic-component>
</div>
