import { Directive, ElementRef, HostBinding, Input, OnChanges, OnDestroy, OnInit, Optional } from '@angular/core';
import toPairs from 'lodash/toPairs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { of } from 'rxjs';

import { AllProjectSettings, ProjectSettingsStore } from '@modules/all-project-settings';
import { FieldElementStyles } from '@modules/customize';
import {
  setBorderRadiusVars,
  setBorderVars,
  setFillVars,
  setMarginVars,
  setShadowVars,
  setTextStyle
} from '@modules/theme-components';
import { isSet, TypedChanges } from '@shared';

@Directive({
  selector: '[appFieldElementStyles]'
})
export class FieldElementStylesDirective implements OnInit, OnDestroy, OnChanges {
  @Input('appFieldElementStyles') customElementStyles: FieldElementStyles;
  @Input('appFieldElementStylesEnabled') enabled = true;
  @Input('appFieldElementStylesApplyGlobal') applyGlobal = true;

  @HostBinding('attr.data-style-vars') attrDataStyleVars: string;

  globalStyles: FieldElementStyles;
  styleVars: string[] = [];

  constructor(@Optional() private projectSettingsStore: ProjectSettingsStore, private el: ElementRef) {}

  ngOnInit(): void {
    if (this.applyGlobal) {
      const settings$ = this.projectSettingsStore
        ? this.projectSettingsStore.getAllSettings$()
        : of<AllProjectSettings>(undefined);

      settings$.pipe(untilDestroyed(this)).subscribe(settings => {
        this.globalStyles = settings ? settings.fieldElementStyles : undefined;
        this.updateStyles();
      });
    } else {
      this.updateStyles();
    }

    if (!this.enabled) {
      return;
    }
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<FieldElementStylesDirective>): void {
    if (changes.customElementStyles && !changes.customElementStyles.firstChange) {
      this.updateStyles();
    }
  }

  updateStyles() {
    if (!this.enabled) {
      return;
    }

    let elementStyles: FieldElementStyles;
    const vars: Object = {};

    if (this.customElementStyles && this.globalStyles) {
      elementStyles = new FieldElementStyles().apply(this.globalStyles).apply(this.customElementStyles);
    } else if (this.customElementStyles || this.globalStyles) {
      elementStyles = this.customElementStyles || this.globalStyles;
    } else {
      elementStyles = undefined;
    }

    if (elementStyles) {
      setTextStyle(vars, elementStyles.textStyle, key => `field-text-${key}`);
      setTextStyle(vars, elementStyles.placeholderStyle, key => `field-placeholder-${key}`);
      setFillVars(vars, elementStyles.fillSettings, key => `field-${key}`);
      setBorderVars(vars, elementStyles.borderSettings, key => `field-${key}`);
      setBorderRadiusVars(vars, elementStyles.borderRadius, 'field-border-radius');
      setShadowVars(vars, elementStyles.shadow, key => `field-${key}`);
      setMarginVars(vars, elementStyles.padding, key => `field-padding-${key}`);

      setTextStyle(vars, elementStyles.hoverTextStyle, key => `field-hover-text-${key}`);
      setTextStyle(vars, elementStyles.hoverPlaceholderStyle, key => `field-hover-placeholder-${key}`);
      setFillVars(vars, elementStyles.hoverFillSettings, key => `field-hover-${key}`);
      setBorderVars(vars, elementStyles.hoverBorderSettings, key => `field-hover-${key}`);
      setShadowVars(vars, elementStyles.hoverShadow, key => `field-hover-${key}`);

      setTextStyle(vars, elementStyles.focusTextStyle, key => `field-focus-text-${key}`);
      setTextStyle(vars, elementStyles.focusPlaceholderStyle, key => `field-focus-placeholder-${key}`);
      setFillVars(vars, elementStyles.focusFillSettings, key => `field-focus-${key}`);
      setBorderVars(vars, elementStyles.focusBorderSettings, key => `field-focus-${key}`);
      setShadowVars(vars, elementStyles.focusShadow, key => `field-focus-${key}`);

      setTextStyle(vars, elementStyles.errorTextStyle, key => `field-error-text-${key}`);
      setTextStyle(vars, elementStyles.errorPlaceholderStyle, key => `field-error-placeholder-${key}`);
      setFillVars(vars, elementStyles.errorFillSettings, key => `field-error-${key}`);
      setBorderVars(vars, elementStyles.errorBorderSettings, key => `field-error-${key}`);
      setShadowVars(vars, elementStyles.errorShadow, key => `field-error-${key}`);
    }

    toPairs(vars).forEach(([k, v]) => this.el.nativeElement.style.setProperty(`--${k}`, v));
    this.styleVars.filter(k => !isSet(vars[k])).forEach(k => this.el.nativeElement.style.removeProperty(`--${k}`));
    this.styleVars = toPairs(vars).map(([k]) => k);

    this.attrDataStyleVars = this.styleVars.join(' ');
  }
}
