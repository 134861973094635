/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../option-enabled-edit/option-enabled-edit.component.ngfactory";
import * as i2 from "../option-enabled-edit/option-enabled-edit.component";
import * as i3 from "../../../projects/stores/current-environment.store";
import * as i4 from "../../../model-queries/stores/model-description.store";
import * as i5 from "../../../filters-components/services/model-options-source";
import * as i6 from "../../../resources/services/resource-controller/resource-controller.service";
import * as i7 from "@angular/cdk/overlay";
import * as i8 from "@angular/cdk/bidi";
import * as i9 from "./option-enabled-edit-overlay.component";
var styles_OptionEnabledEditOverlayComponent = [];
var RenderType_OptionEnabledEditOverlayComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_OptionEnabledEditOverlayComponent, data: {} });
export { RenderType_OptionEnabledEditOverlayComponent as RenderType_OptionEnabledEditOverlayComponent };
function View_OptionEnabledEditOverlayComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "popover2 overlay-content overlay-content_animated"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "popover2__inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 3, "div", [["class", "popover2__viewport popover2__viewport_scrollable"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["style", "min-width: 300px;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "app-option-enabled-edit", [], null, null, null, i1.View_OptionEnabledEditComponent_0, i1.RenderType_OptionEnabledEditComponent)), i0.ɵdid(5, 245760, null, 0, i2.OptionEnabledEditComponent, [i3.CurrentEnvironmentStore, i4.ModelDescriptionStore, i5.ModelOptionsSource, i6.ResourceControllerService, i0.ChangeDetectorRef], { control: [0, "control"], dataSourceControl: [1, "dataSourceControl"], onlyEditableAllowed: [2, "onlyEditableAllowed"], emptyMessage: [3, "emptyMessage"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.control; var currVal_1 = _co.dataSourceControl; var currVal_2 = _co.onlyEditableAllowed; var currVal_3 = _co.emptyMessage; _ck(_v, 5, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_OptionEnabledEditOverlayComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(402653184, 1, { cdkConnectedOverlay: 0 }), (_l()(), i0.ɵand(16777216, null, null, 2, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("backdropClick" === en)) {
        var pd_0 = (_co.setDropdownOpened(false) !== false);
        ad = (pd_0 && ad);
    } if (("detach" === en)) {
        var pd_1 = (_co.setDropdownOpened(false) !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_OptionEnabledEditOverlayComponent_1)), i0.ɵdid(2, 671744, [[1, 4]], 0, i7.CdkConnectedOverlay, [i7.Overlay, i0.TemplateRef, i0.ViewContainerRef, i7.ɵc, [2, i8.Directionality]], { origin: [0, "origin"], positions: [1, "positions"], backdropClass: [2, "backdropClass"], panelClass: [3, "panelClass"], open: [4, "open"], hasBackdrop: [5, "hasBackdrop"], growAfterOpen: [6, "growAfterOpen"], push: [7, "push"] }, { backdropClick: "backdropClick", detach: "detach" }), i0.ɵpad(3, 2)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.origin; var currVal_1 = _co.popoverPositions; var currVal_2 = "popover2-backdrop"; var currVal_3 = _ck(_v, 3, 0, "overlay", "overlay_horizontal_center"); var currVal_4 = _co.dropdownOpened; var currVal_5 = true; var currVal_6 = true; var currVal_7 = true; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, null); }
export function View_OptionEnabledEditOverlayComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-option-enabled-edit-overlay", [], null, null, null, View_OptionEnabledEditOverlayComponent_0, RenderType_OptionEnabledEditOverlayComponent)), i0.ɵdid(1, 4440064, null, 0, i9.OptionEnabledEditOverlayComponent, [i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OptionEnabledEditOverlayComponentNgFactory = i0.ɵccf("app-option-enabled-edit-overlay", i9.OptionEnabledEditOverlayComponent, View_OptionEnabledEditOverlayComponent_Host_0, { control: "control", dataSourceControl: "dataSourceControl", onlyEditableAllowed: "onlyEditableAllowed", emptyMessage: "emptyMessage", origin: "origin", trigger: "trigger", triggerManual: "triggerManual" }, {}, []);
export { OptionEnabledEditOverlayComponentNgFactory as OptionEnabledEditOverlayComponentNgFactory };
