var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { KanbanBoardStage } from '@modules/customize';
import { Input } from '@modules/fields';
import { FieldInputControl } from '@modules/parameters';
import { isSet } from '@shared';
var KanbanStageControl = /** @class */ (function (_super) {
    __extends(KanbanStageControl, _super);
    function KanbanStageControl(value) {
        return _super.call(this, {
            value: new FormControl(value && isSet(value.value) ? value.value : ''),
            name: new FormControl(value && isSet(value.name) ? value.name : '', Validators.required),
            color: new FormControl(value && isSet(value.color) ? value.color : ''),
            visible_input: new FieldInputControl(value && value.visibleInput ? value.visibleInput.serialize() : { path: ['value'] })
        }) || this;
    }
    KanbanStageControl.prototype.deserialize = function (value) {
        this.instance = value;
        this.controls.value.patchValue(value.value);
        this.controls.name.patchValue(value.name);
        this.controls.color.patchValue(value.color);
        this.controls.visible_input.patchValue(value.visibleInput ? value.visibleInput.serializeWithoutPath() : {});
    };
    KanbanStageControl.prototype.serialize = function () {
        if (!this.instance) {
            this.instance = new KanbanBoardStage();
            this.instance.generateUid();
        }
        var result = this.instance;
        result.value = this.controls.value.value;
        result.name = this.controls.name.value;
        result.color = this.controls.color.value;
        if (this.controls.visible_input.value) {
            result.visibleInput = new Input().deserialize(this.controls.visible_input.value);
        }
        else {
            result.visibleInput = undefined;
        }
        return result;
    };
    return KanbanStageControl;
}(FormGroup));
export { KanbanStageControl };
