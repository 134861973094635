import { HttpClient } from '@angular/common/http';
import { Injector } from '@angular/core';
import highlightjs from 'highlight.js/lib/highlight';
import bash from 'highlight.js/lib/languages/bash';
import javascript from 'highlight.js/lib/languages/javascript';
import typescript from 'highlight.js/lib/languages/typescript';
import fromPairs from 'lodash/fromPairs';
import * as marked from 'marked';
import { Observable, of } from 'rxjs';
import { map, publishLast, refCount } from 'rxjs/operators';
import { DocsService } from '@modules/docs';
import { isSet, splitmax, stripStart } from '@shared';
import { TaskType } from '../../data/task-type';
import { AddChartForChangeViewTask } from '../../data/tasks/add-chart-for-change-view';
import { AddChartWidgetTask } from '../../data/tasks/add-chart-widget';
import { AddChartWidgetSqlTask } from '../../data/tasks/add-chart-widget-sql';
import { AddNewProjectTask } from '../../data/tasks/add-new-project';
import { AddRelatedDataTask } from '../../data/tasks/add-related-data';
import { ChangeChartTypeTask } from '../../data/tasks/change-chart-type';
import { CheckOutSourcesTask } from '../../data/tasks/check-out-sources';
import { CreateFlexFieldTask } from '../../data/tasks/create-flex-field';
import { CreateFlexViewTask } from '../../data/tasks/create-flex-view';
import { CreateGroupTask } from '../../data/tasks/create-group';
import { CreateSegmentTask } from '../../data/tasks/create-segment';
import { CreateSegmentSqlTask } from '../../data/tasks/create-segment-sql';
import { CustomizeChangeViewTask } from '../../data/tasks/customize-change-view';
import { CustomizeListViewTask } from '../../data/tasks/customize-list-view';
import { CustomizeMenuTask } from '../../data/tasks/customize-menu';
import { CustomizeModelTask } from '../../data/tasks/customize-model';
import { CustomizeModelDbFieldTask } from '../../data/tasks/customize-model-field';
import { CustomizeProjectTask } from '../../data/tasks/customize-project';
import { DuplicateRecordTask } from '../../data/tasks/duplicate-record';
import { ExportRecordsTask } from '../../data/tasks/export-records';
import { FindRecordTask } from '../../data/tasks/find-record';
import { InviteUserTask } from '../../data/tasks/invite-user';
import { SwitchToDarkThemeTask } from '../../data/tasks/switch-to-dark-theme';
import { GuideUtilsService } from '../guide-utils/guide-utils.service';
var languages = [
    { name: 'bash', value: bash },
    { name: 'javascript', value: javascript },
    { name: 'typescript', value: typescript }
];
languages.forEach(function (item) { return highlightjs.registerLanguage(item.name, item.value); });
var TaskService = /** @class */ (function () {
    function TaskService(docsService, parentInjector, http) {
        this.docsService = docsService;
        this.parentInjector = parentInjector;
        this.http = http;
        this.tasks = [
            { type: TaskType.InviteUser, cls: InviteUserTask },
            { type: TaskType.CreateGroup, cls: CreateGroupTask },
            { type: TaskType.CustomizeMenu, cls: CustomizeMenuTask },
            { type: TaskType.CustomizeModel, cls: CustomizeModelTask },
            { type: TaskType.CustomizeModelDbField, cls: CustomizeModelDbFieldTask },
            { type: TaskType.CustomizeListView, cls: CustomizeListViewTask },
            { type: TaskType.CustomizeChangeView, cls: CustomizeChangeViewTask },
            { type: TaskType.AddRelatedData, cls: AddRelatedDataTask },
            { type: TaskType.FindRecord, cls: FindRecordTask },
            { type: TaskType.CreateSegment, cls: CreateSegmentTask },
            { type: TaskType.CreateSegmentSql, cls: CreateSegmentSqlTask },
            { type: TaskType.DuplicateRecord, cls: DuplicateRecordTask },
            { type: TaskType.ExportRecords, cls: ExportRecordsTask },
            { type: TaskType.AddChartWidget, cls: AddChartWidgetTask },
            { type: TaskType.AddChartWidgetSql, cls: AddChartWidgetSqlTask },
            { type: TaskType.ChangeChartType, cls: ChangeChartTypeTask },
            { type: TaskType.AddChartForChangeView, cls: AddChartForChangeViewTask },
            { type: TaskType.CustomizeProject, cls: CustomizeProjectTask },
            { type: TaskType.CreateFlexView, cls: CreateFlexViewTask },
            { type: TaskType.CreateFlexField, cls: CreateFlexFieldTask },
            { type: TaskType.AddNewProject, cls: AddNewProjectTask },
            { type: TaskType.SwitchToDarkTheme, cls: SwitchToDarkThemeTask },
            { type: TaskType.CheckOutSources, cls: CheckOutSourcesTask }
        ];
        this.injector = Injector.create({
            providers: this.tasks.map(function (item) {
                return { provide: item.cls, deps: [GuideUtilsService] };
            }),
            parent: this.parentInjector
        });
    }
    TaskService.prototype.get = function (type) {
        var instance = this.tasks.find(function (item) { return item.type == type; });
        if (!instance) {
            return;
        }
        return this.injector.get(instance.cls);
    };
    TaskService.prototype.getScenario = function (type) {
        var instance = this.get(type);
        if (!instance) {
            return of(undefined);
        }
        return instance.scenario$;
    };
    TaskService.prototype.parseDocumentationData = function (srcContent) {
        var title;
        var description;
        var content = srcContent;
        var descriptionRegex = /---\ndescription:\s(.+)\n---\n/m.exec(content);
        var descriptionMultilineRegex = /---\ndescription:\s>-\n([\s\S]+)\n---\n/m.exec(content);
        if (descriptionRegex) {
            description = descriptionRegex[1];
            content = content.replace(descriptionRegex[0], '');
        }
        else if (descriptionMultilineRegex) {
            description = descriptionMultilineRegex[1];
            content = content.replace(descriptionMultilineRegex[0], '');
        }
        var titleRegex = /#\s(.+)\n/m.exec(content);
        if (titleRegex) {
            title = titleRegex[1];
            content = content.replace(titleRegex[0], '');
        }
        return {
            title: title,
            description: description,
            content: content
        };
    };
    TaskService.prototype.renderDocumentationContent = function (markdown) {
        markdown = markdown.replace(/(\.\.\/)*\.gitbook\/assets\//g, 'https://raw.githubusercontent.com/jet-admin/docs/master/.gitbook/assets/');
        markdown = markdown.replace(/(!?)(\[.*\])\(<(.+)>\)/g, function (substring) {
            var args = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args[_i - 1] = arguments[_i];
            }
            return "" + args[0] + args[1] + "(" + encodeURI(args[2]) + ")";
        });
        var renderer = new marked.Renderer();
        var options = {
            gfm: true,
            headerIds: true,
            headerPrefix: '',
            highlight: function (code, lang) {
                if (!languages.find(function (item) { return item.name == lang; })) {
                    return code;
                }
                return highlightjs.highlight(lang, code).value;
            },
            langPrefix: 'language-',
            renderer: renderer,
            tables: true,
            customRules: {
                description: /^ *---(?:(?:\s|\n)*([^ ]+): >-(?:\s|\n)*)?((?:.|\n)+)--- *(?:\n+|$)/,
                hint: /^ *{% ?hint(?: (.*)) ?%}(((?!{% ?endhint ?%})(?:.|\n))+){% ?endhint ?%} *(?:\n+|$)/,
                pageRef: /^ *{% ?page\-ref(?: (.*)) ?%} *(?:\n+|$)/,
                contentRef: /^ *{% ?content\-ref(?: (.*)) ?%}(((?!{% ?endcontent-ref ?%})(?:.|\n))+){% ?endcontent-ref ?%} *(?:\n+|$)/,
                embed: /^ *{% ?embed(?: (.*)) ?%} *(?:\n+|$)/,
                '@arcade/embed': /^ *{% ?@arcade\/embed(?: (.*)) ?%} *(?:\n+|$)/
            },
            customTok: function () {
                if (this.token.type == 'description') {
                    return this.renderer.description(this.token.text, this.token.exec);
                }
                else if (this.token.type == 'hint') {
                    return this.renderer.hint(this.token.text, this.token.exec);
                }
                else if (this.token.type == 'pageRef') {
                    return this.renderer.pageRef(this.token.text, this.token.exec);
                }
                else if (this.token.type == 'contentRef') {
                    return this.renderer.contentRef(this.token.text, this.token.exec);
                }
                else if (this.token.type == 'embed') {
                    return this.renderer.embed(this.token.text, this.token.exec);
                }
                else if (this.token.type == '@arcade/embed') {
                    return this.renderer['@arcade/embed'](this.token.text, this.token.exec);
                }
            }
        };
        var lexer = new marked.Lexer(options);
        var parser = new marked.Parser(options);
        var tokens = lexer.lex(markdown);
        var cleanQuotes = function (item) {
            if (item[0] == '"' && item[item.length - 1] == '"') {
                item = item.substring(1);
                item = item.substring(0, item.length - 1);
            }
            return item;
        };
        var parseAttrs = function (value) {
            return fromPairs(value
                .trim()
                .split(' ')
                .map(function (item) { return splitmax(item, '=', 2); })
                .map(function (_a) {
                var k = _a[0], v = _a[1];
                return [k, cleanQuotes(v)];
            }));
        };
        renderer.description = function (text, exec) {
            return "<p class=\"description\">" + parser.inline.output(exec[2]) + "</p>";
        };
        renderer.hint = function (text, exec) {
            var attrs = parseAttrs(exec[1]);
            return "<p class=\"hint hint_style_" + attrs['style'] + "\">" + parser.inline.output(exec[2]) + "</p>";
        };
        renderer.pageRef = function (text, exec) {
            var attrs = parseAttrs(exec[1]);
            var page = attrs['page'];
            return "<a href=\"javascript:void(0)\" class=\"ref ref_styled\" data-page=\"" + page + "\">\n        <span class=\"ref__icon icon icon-link\"></span>\n        <span class=\"ref__label\">" + page + "</span>\n      </a>";
        };
        renderer.contentRef = function (text, exec) {
            var attrs = parseAttrs(exec[1]);
            var page = attrs['url'];
            return "<a href=\"javascript:void(0)\" class=\"ref ref_styled\" data-page=\"" + page + "\">\n        <span class=\"ref__icon icon icon-link\"></span>\n        <span class=\"ref__label\">" + page + "</span>\n      </a>";
        };
        renderer.embed = function (text, exec) {
            var attrs = parseAttrs(exec[1]);
            var embedUrl = attrs['url'].replace(/\\_/, '_');
            var youtubeMatch = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/.exec(embedUrl);
            if (youtubeMatch) {
                var embed = "https://www.youtube.com/embed/" + youtubeMatch[5];
                return "<iframe class=\"embed\" src=\"" + embed + "\" allowfullscreen allowtransparency></iframe>";
            }
            else {
                var url = new URL(embedUrl);
                var urlPath = stripStart(url.pathname, '/');
                return "<a href=\"" + embedUrl + "\" class=\"ref ref_styled\" target=\"_blank\">\n          <span class=\"ref__icon icon icon-external_link\"></span>\n          <span class=\"ref__info\">\n            <span class=\"ref__title\">" + url.host + "</span>\n            " + (isSet(urlPath) ? "<span class=\"ref__subtitle\">" + urlPath + "</span>" : '') + "\n          </span>\n        </a>";
            }
        };
        renderer['@arcade/embed'] = function (text, exec) {
            var attrs = parseAttrs(exec[1]);
            var flowId = attrs['flowId'];
            if (!flowId) {
                return '';
            }
            var embed = "https://demo.arcade.software/" + flowId + "?embed";
            return "<iframe class=\"embed\" src=\"" + embed + "\" allowfullscreen allowtransparency></iframe>";
        };
        renderer.link = function (href, title, text) {
            var appMatch = /https:\/\/app\.gitbook\.com\/@jetadmin\/s\/doc\/[^/]+\/drafts\/[^/]+\/(.+)/.exec(href);
            if (appMatch) {
                var page = appMatch[1];
                return "<a href=\"javascript:void(0)\" class=\"ref\" data-page=\"" + page + "\">" + text + "</a>";
            }
            else if (['http://', 'https://', 'file://'].every(function (prefix) { return !href.toLowerCase().startsWith(prefix); })) {
                return "<a href=\"javascript:void(0)\" class=\"ref\" data-page=\"" + href + "\">" + text + "</a>";
            }
            else {
                var link = marked.Renderer.prototype.link.call(this, href, title, text);
                return link.replace('<a', '<a target="_blank" ');
            }
        };
        renderer.image = function (href, title, text) {
            var image = marked.Renderer.prototype.image.call(this, href, title, text);
            var regex = /<img[^>]+src="([^"]+)".*>/;
            var match = regex.exec(image);
            if (match) {
                image = "<a href=\"" + match[1] + "\" class=\"img-ref\" target=\"_blank\">" + image + "</a>";
            }
            return image;
        };
        return parser.parse(tokens);
    };
    TaskService.prototype.getDocumentationContent = function (selector, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        return this.docsService.getDocument(selector).pipe(map(function (result) {
            var data = _this.parseDocumentationData(result.markdown);
            var content = !options.noContent ? _this.renderDocumentationContent(data.content) : undefined;
            return {
                url: result.url,
                filePath: result.filePath,
                title: data.title,
                description: data.description,
                content: content
            };
        }), publishLast(), refCount());
    };
    return TaskService;
}());
export { TaskService };
