import { OnDestroy } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import Quill from 'quill';
import { merge, Observable, of } from 'rxjs';
import { delay, first } from 'rxjs/operators';

import { isSet } from '@shared';

const Inline = Quill.import('blots/inline');

export const BACKGROUND_SET_DEFAULT_VALUE_ATTR = 'data-background-default';
export const BACKGROUND_SET_DARK_VALUE_ATTR = 'data-background-dark';

export interface BackgroundSetValue {
  backgroundDefault?: string;
  backgroundDark?: string;
}

class BackgroundSetBlot extends Inline implements OnDestroy {
  static create(value: BackgroundSetValue) {
    if (!value) {
      return super.create(false);
    }

    const node = super.create(value);

    if (isSet(value.backgroundDefault)) {
      node.setAttribute(BACKGROUND_SET_DEFAULT_VALUE_ATTR, value.backgroundDefault);
    }

    if (isSet(value.backgroundDark)) {
      node.setAttribute(BACKGROUND_SET_DARK_VALUE_ATTR, value.backgroundDark);
    }

    return node;
  }

  // Overriding this method, in this particular case,
  // is what causes the Delta returned as content by
  // Quill to have the desired information.
  static formats(domNode): BackgroundSetValue {
    const backgroundDefault = domNode.getAttribute(BACKGROUND_SET_DEFAULT_VALUE_ATTR);
    const backgroundDark = domNode.getAttribute(BACKGROUND_SET_DARK_VALUE_ATTR);

    if (isSet(backgroundDefault) || isSet(backgroundDark)) {
      return {
        backgroundDefault: backgroundDefault,
        backgroundDark: backgroundDark
      };
    } else {
      return super.formats(domNode);
    }
  }

  ngOnDestroy(): void {}

  getQuill() {
    let node = this.domNode;

    while (node) {
      const quill = Quill.find(node);
      if (quill instanceof Quill) {
        return quill;
      }

      node = node.parentElement;
    }
  }

  getOptions() {
    const quill = this.getQuill();
    if (quill) {
      const toolbar = quill.getModule('toolbar');
      if (toolbar) {
        const options = toolbar.options.formatsOptions || {};
        return options['background-set'] || {};
      }
    }

    return {};
  }

  attach(): void {
    super.attach();

    const options = this.getOptions();
    let theme$: Observable<string> = options['theme$'] || of(undefined);

    // Workaround for value update
    theme$ = merge(theme$.pipe(first()), theme$.pipe(delay(0)));

    theme$.pipe(untilDestroyed(this)).subscribe(theme => {
      const node = this.domNode as HTMLElement;
      const backgroundDefault = node.getAttribute(BACKGROUND_SET_DEFAULT_VALUE_ATTR);
      const backgroundDark = node.getAttribute(BACKGROUND_SET_DARK_VALUE_ATTR);
      const backgroundEffective = theme == 'dark' ? backgroundDark : backgroundDefault;

      if (isSet(backgroundEffective)) {
        node.setAttribute('style', `--background-value: ${backgroundEffective}`);
      } else {
        node.removeAttribute('style');
      }
    });
  }

  detach(): void {
    super.detach();
    this.ngOnDestroy();
  }

  formats() {
    // Returns the formats list this class (this format).
    const formats = super.formats();

    // Inline has the domNode reference, which in this
    // case represents the SPAN, result defined in tagName.
    formats['background-set'] = BackgroundSetBlot.formats(this.domNode);

    // In the code above, it is as if we are adding this new format.
    return formats;
  }
}

BackgroundSetBlot.tagName = 'SPAN';
BackgroundSetBlot.blotName = 'background-set';
Quill.register('formats/background-set', BackgroundSetBlot);
