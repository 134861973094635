var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';
import { auditTime } from 'rxjs/operators';
import { ActionType } from '@modules/actions';
import { elementImage, getElementComponentByType } from '@modules/customize';
import { CustomizeBarContext, CustomizeBarEditEventType, CustomizeBarService } from '@modules/customize-bar';
import { LayerType } from '@modules/views';
import { registerCustomizeLayerComponent } from '../../../data/customize-layer-components';
import { ViewEditorContext } from '../../../services/view-editor-context/view-editor.context';
import { CustomizeLayerComponent } from '../base/base-customize-layer.component';
import { ElementCustomizeLayerForm } from './element-customize-layer.form';
var ElementCustomizeLayerComponent = /** @class */ (function (_super) {
    __extends(ElementCustomizeLayerComponent, _super);
    function ElementCustomizeLayerComponent(form, editorContext, customizeBarService, customizeBarContext, cd) {
        var _this = _super.call(this) || this;
        _this.form = form;
        _this.editorContext = editorContext;
        _this.customizeBarService = customizeBarService;
        _this.customizeBarContext = customizeBarContext;
        _this.cd = cd;
        _this.settingsComponents = [];
        return _this;
    }
    ElementCustomizeLayerComponent.prototype.ngOnInit = function () {
        var _this = this;
        var layerContainer = this.editorContext.getLayerContainer(this.layer);
        this.name = this.getElementName();
        this.image = this.getElementImage();
        this.cd.markForCheck();
        this.form.init(this.layer, { firstInit: true, container: layerContainer });
        this.trackFormUpdates(this.form, function () { return _this.form.submit(); });
        combineLatest(this.layerUpdated$, this.editorContext.getLayerContainer$(this.layer))
            .pipe(auditTime(10), untilDestroyed(this))
            .subscribe(function (_a) {
            var layer = _a[0], container = _a[1];
            _this.pauseFormUpdates();
            _this.form.init(layer, {
                firstInit: false,
                container: container
            });
            _this.resumeFormUpdates();
        });
        this.form.containerLayer$.pipe(untilDestroyed(this)).subscribe(function (layer) {
            _this.containerLayer = layer;
            _this.cd.markForCheck();
        });
        this.customizeBarContext.settingsComponents$.pipe(untilDestroyed(this)).subscribe(function (item) {
            _this.settingsComponents = item;
            _this.cd.markForCheck();
        });
    };
    ElementCustomizeLayerComponent.prototype.ngOnDestroy = function () { };
    ElementCustomizeLayerComponent.prototype.getElementName = function () {
        var elementComponent = getElementComponentByType(this.layer.element.type);
        return elementComponent.label;
    };
    ElementCustomizeLayerComponent.prototype.getElementImage = function () {
        return elementImage(this.layer.element) || 'table_list';
    };
    ElementCustomizeLayerComponent.prototype.customize = function () {
        var _this = this;
        this.customizeBarService
            .customize(this.customizeBarContext, this.layer.element, this.viewContext, undefined, undefined, {
            titleEnabled: false,
            backLabel: 'Layer settings',
            tooltipEditable: false,
            fieldLabelEditable: false,
            fieldReadonlyEditable: false,
            actionTypesOnly: [
                ActionType.Query,
                ActionType.Workflow,
                ActionType.Link,
                ActionType.ExternalLink,
                ActionType.ElementAction,
                ActionType.ShowNotification,
                ActionType.SetProperty,
                ActionType.RunJavaScript,
                ActionType.CopyToClipboard,
                ActionType.Export,
                ActionType.Import,
                ActionType.Download,
                ActionType.ScanCode
            ]
        })
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            if (!result.event || result.event !== CustomizeBarEditEventType.Deleted) {
                _this.onCustomized(result.el);
            }
        });
    };
    ElementCustomizeLayerComponent.prototype.onCustomized = function (element) {
        this.layer.element.copy(element);
        this.layerChange.emit(this.layer);
    };
    return ElementCustomizeLayerComponent;
}(CustomizeLayerComponent));
export { ElementCustomizeLayerComponent };
registerCustomizeLayerComponent(LayerType.Element, ElementCustomizeLayerComponent);
