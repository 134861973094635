var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, EventEmitter, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { NotificationService } from '@common/notifications';
import { BasePopupComponent } from '@common/popups';
import { ServerRequestError } from '@modules/api';
import { FirebaseRealTime, FirebaseService } from '@modules/resources';
import { controlValue, isSet } from '@shared';
var SyncModeFirebaseComponent = /** @class */ (function () {
    function SyncModeFirebaseComponent(popupComponent, firebaseService, notificationService, cd) {
        this.popupComponent = popupComponent;
        this.firebaseService = firebaseService;
        this.notificationService = notificationService;
        this.cd = cd;
        this.label = 'Real-time updates';
        this.enableSync = false;
        this.submitLabel = 'Finish';
        this.submitLoader = false;
        this.cancelLabel = 'Cancel';
        this.submit = new EventEmitter();
        this.cancel = new EventEmitter();
        this.enableSync$ = new BehaviorSubject(this.enableSync);
        this.loading = false;
        this.disabled = false;
        this.firebaseRealTimeControl = new FormControl(FirebaseRealTime.Functions);
        this.firebaseRequirementsLoading = false;
        this.realTimes = FirebaseRealTime;
    }
    SyncModeFirebaseComponent.prototype.ngOnInit = function () {
        var _this = this;
        combineLatest(this.enableSync$, controlValue(this.firebaseRealTimeControl))
            .pipe(untilDestroyed(this))
            .subscribe(function () { return _this.updateFirebaseRequirements(); });
    };
    SyncModeFirebaseComponent.prototype.ngOnDestroy = function () { };
    SyncModeFirebaseComponent.prototype.ngOnChanges = function (changes) {
        if (changes.enableSync) {
            this.enableSync$.next(this.enableSync);
        }
        if (changes.paramsOptions) {
            var realTime = this.paramsOptions && isSet(this.paramsOptions.realTime)
                ? this.paramsOptions.realTime
                : FirebaseRealTime.Functions;
            this.firebaseRealTimeControl.setValue(realTime);
        }
    };
    SyncModeFirebaseComponent.prototype.updateSubmitDisabled = function () {
        this.updateFirebaseRequirements();
    };
    SyncModeFirebaseComponent.prototype.updateFirebaseRequirements = function () {
        var _this = this;
        if (this.firebaseRequirementsSubscription) {
            this.firebaseRequirementsSubscription.unsubscribe();
            this.firebaseRequirementsSubscription = undefined;
        }
        if (this.enableSync && this.firebaseRealTimeControl.value == FirebaseRealTime.Functions) {
            this.loading = true;
            this.disabled = false;
            this.firebaseRequirements = undefined;
            this.firebaseRequirementsLoading = true;
            this.firebaseRequirementsError = undefined;
            this.cd.markForCheck();
            this.firebaseRequirementsSubscription = this.firebaseService
                .functionsCheckConfiguration(this.paramsOptions.projectId, JSON.parse(this.paramsOptions.serviceToken))
                .pipe(untilDestroyed(this))
                .subscribe(function (result) {
                _this.loading = false;
                _this.disabled = !result.result;
                _this.firebaseRequirements = result.requirements;
                _this.firebaseRequirementsLoading = false;
                _this.cd.markForCheck();
                if (isSet(result.error) && result.requirements.every(function (item) { return item.status !== false; })) {
                    _this.firebaseRequirementsError = result.error;
                    _this.notificationService.error('Firebase configuration needed', result.error);
                }
            }, function (error) {
                _this.loading = false;
                _this.disabled = true;
                _this.firebaseRequirementsLoading = false;
                if (error instanceof ServerRequestError && error.errors.length) {
                    _this.firebaseRequirementsError = error.errors[0];
                    _this.notificationService.error('Check configuration error', error.errors[0]);
                }
                else {
                    _this.firebaseRequirementsError = error;
                    _this.notificationService.error('Check configuration error', error);
                }
                _this.cd.markForCheck();
            });
        }
        else {
            this.loading = false;
            this.disabled = false;
            this.cd.markForCheck();
        }
    };
    SyncModeFirebaseComponent.prototype.submitResult = function () {
        this.submit.emit({
            paramsOptions: this.paramsOptions
                ? __assign({}, this.paramsOptions, { realTime: this.firebaseRealTimeControl.value }) : undefined
        });
    };
    return SyncModeFirebaseComponent;
}());
export { SyncModeFirebaseComponent };
