import { Injectable, OnDestroy } from '@angular/core';
import { AsyncValidatorFn, FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import keys from 'lodash/keys';
import { combineLatest, Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, skip } from 'rxjs/operators';

import { ActionItem, ViewSettingsAction } from '@modules/actions';
import {
  editorJsOutputDataToQuillDelta,
  FieldElementItem,
  FormElementItem,
  FormStyle,
  FormSubmitElementItem,
  generateElementName,
  getContextElementNames,
  ITEM_OUTPUT,
  MarginControl,
  TextElementItem,
  VALUE_OUTPUT,
  ViewContext
} from '@modules/customize';
import { ElementConfigurationService } from '@modules/customize-configuration';
import { DataSourceType } from '@modules/data-sources';
import { fieldsEditItemFromParameterField } from '@modules/field-components';
import { Input, Input as FieldInput, InputValueType, ParameterField } from '@modules/fields';
import { ModelDescriptionStore } from '@modules/model-queries';
import { FieldInputControl, InputFieldProvider } from '@modules/parameters';
import { ModelDescriptionQuery, QueryType } from '@modules/queries';
import { controlValid, isSet } from '@shared';

import { ActionOption, CustomizeBarActionEditForm } from '../customize-bar-action-edit/customize-bar-action-edit.form';
import { ModelDescriptionDataSourceControl } from '../model-description-data-source-edit/model-description-data-source';

export const validateColumns: ValidatorFn = control => {
  if (!control.value || !control.value.length) {
    return { required: true };
  }
};

export const validateAction: AsyncValidatorFn = (control: CustomizeBarActionEditForm) => {
  const parent = control.parent as CustomizeBarFormEditForm;

  if (!parent) {
    return of(null);
  }

  return parent.elementConfigurationService.isActionConfigured(control.submit().action).pipe(
    map(configured => {
      if (!configured) {
        return { required: true };
      }
    })
  );
};

@Injectable()
export class CustomizeBarFormEditForm extends FormGroup implements OnDestroy {
  element: FormElementItem;
  context: ViewContext;
  inputFieldProvider = new InputFieldProvider();
  styleOptions = [
    {
      value: FormStyle.Wrap,
      name: 'Wrap',
      image: 'forms-style-wrap'
    },
    {
      value: FormStyle.Background,
      name: 'Background',
      image: 'forms-style-background'
    }
  ];

  controls: {
    generated: FormControl;
    children: FormControl;
    name: FormControl;
    get_enabled: FormControl;
    get_data_source: ModelDescriptionDataSourceControl;
    submit_action: CustomizeBarActionEditForm;
    columns: FormControl;
    style: FormControl;
    visible_input: FieldInputControl;
    margin: MarginControl;
    load_invisible: FormControl;
  };

  constructor(
    private modelDescriptionStore: ModelDescriptionStore,
    public elementConfigurationService: ElementConfigurationService,
    getDataSourceControl: ModelDescriptionDataSourceControl,
    submitActionForm: CustomizeBarActionEditForm
  ) {
    super({
      generated: new FormControl(false),
      children: new FormControl(undefined),
      name: new FormControl(''),
      get_enabled: new FormControl(false),
      get_data_source: getDataSourceControl,
      submit_action: submitActionForm,
      columns: new FormControl([]),
      style: new FormControl(FormStyle.Wrap),
      visible_input: new FieldInputControl({ path: ['value'] }),
      margin: new MarginControl(),
      load_invisible: new FormControl(false)
    });
  }

  ngOnDestroy(): void {
    this.inputFieldProvider.clearProvider();
  }

  init(element: FormElementItem, context: ViewContext, firstInit = false) {
    this.element = element;
    this.context = context;

    const value = {
      generated: element.generated,
      name: element.name ? element.name : 'Form',
      get_enabled: !!element.getDataSource,
      columns: element.columns,
      style: element.style || FormStyle.Wrap,
      visible_input: element.visibleInput ? element.visibleInput.serializeWithoutPath() : {},
      margin: element.margin,
      load_invisible: element.loadInvisible
    };

    this.patchValue(value, { emitEvent: false });

    this.controls.get_data_source.deserialize(element.getDataSource ? element.getDataSource : undefined);

    this.controls.submit_action.init(
      {
        actionItem: element.submitAction,
        actionLabel: 'Form submit',
        actionItemClass: ActionItem,
        // modelDescriptionInContext: modelDescriptionInContext,
        approveEnabled: true,
        confirmationEnabled: true,
        completionEditable: true,
        context: this.context
      },
      firstInit
    );

    if (!firstInit) {
      this.markAsDirty();
    }

    this.controls.submit_action
      .getActionDescription$()
      .pipe(
        distinctUntilChanged((lhs, rhs) => {
          const lhsId = lhs ? lhs.id : undefined;
          const rhsId = rhs ? rhs.id : undefined;
          return lhsId === rhsId;
        }),
        skip(1)
      )
      .subscribe(actionDescription => {
        if (
          actionDescription &&
          isSet(actionDescription.model) &&
          ['update', 'delete'].includes(actionDescription.modelAction)
        ) {
          const query = new ModelDescriptionQuery();

          query.queryType = QueryType.Simple;
          query.simpleQuery = new query.simpleQueryClass();
          query.simpleQuery.model = actionDescription.model;

          this.controls.get_enabled.patchValue(true);
          this.controls.get_data_source.controls.type.patchValue(DataSourceType.Query);
          this.controls.get_data_source.controls.query_resource.patchValue(actionDescription.resource);
          this.controls.get_data_source.controls.query.patchValue(query);
        } else {
          this.controls.get_enabled.patchValue(false);
          this.controls.get_data_source.controls.query_resource.patchValue(null);
          this.controls.get_data_source.controls.query.patchValue(null);
        }
      });
  }

  enableGetQuery() {
    if (this.controls.get_enabled.value) {
      return;
    }

    this.controls.get_enabled.patchValue(true);

    const actionResourceName = this.controls.submit_action.controls.resource.value;
    const actionOption = this.controls.submit_action.controls.action.value as ActionOption;
    const actionModelDescription = this.modelDescriptionStore.instance
      .filter(item => item.resource == actionResourceName)
      .find(item => actionOption && item.autoActions().some(action => action.uniqueName == actionOption.action));

    if (actionResourceName) {
      this.controls.get_data_source.controls.query_resource.patchValue(actionResourceName);
    }

    if (actionModelDescription) {
      const query = new ModelDescriptionQuery();

      query.queryType = QueryType.Simple;
      query.simpleQuery = new query.simpleQueryClass();
      query.simpleQuery.model = actionModelDescription.model;

      this.controls.get_data_source.controls.query.patchValue(query);
    }
  }

  getFormParameters(): { parameter: ParameterField; enabled: boolean; getQueryInput: boolean }[] {
    const actionOption = this.controls.submit_action.controls.action.value as ActionOption;
    const actionResourceName: string = this.controls.submit_action.controls.resource.value;
    const actionQueryType = actionOption ? actionOption.queryType : undefined;
    const actionQueryName = actionOption ? actionOption.action : undefined;
    const actionParameters: ParameterField[] = this.controls.submit_action.controls.action_params.value;
    const getDataSource = this.controls.get_data_source.serialize();

    const actionUpdateModelDescription = this.modelDescriptionStore.instance
      .filter(item => item.resource == actionResourceName)
      .find(item => item.autoActionUniqueName('update') == actionQueryName);

    const updateModelAction =
      getDataSource &&
      getDataSource.queryResource == actionResourceName &&
      actionQueryType == QueryType.Simple &&
      getDataSource.query &&
      getDataSource.query.queryType == QueryType.Simple &&
      getDataSource.query.simpleQuery &&
      actionUpdateModelDescription &&
      actionUpdateModelDescription.model == getDataSource.query.simpleQuery.model;

    return actionParameters.map(parameter => {
      const getQueryInput =
        updateModelAction && getDataSource.queryInputs.find(item => item.isName(parameter.name) && item.isSet());

      return {
        parameter: parameter,
        enabled: !getQueryInput,
        getQueryInput: !!getQueryInput
      };
    });
  }

  generateForm() {
    const names = getContextElementNames(this.context);
    const children = [];
    const titleElement = new TextElementItem();

    titleElement.generateUid();
    titleElement.name = generateElementName(titleElement, names);
    titleElement.quillDelta = editorJsOutputDataToQuillDelta({
      blocks: [
        {
          type: 'header',
          data: {
            text: 'Form',
            level: 1
          }
        }
      ]
    });

    children.push(titleElement);

    const formParameters = this.getFormParameters();

    const fieldElements = formParameters
      .filter(item => item.enabled)
      .map(item => {
        const element = new FieldElementItem();

        element.generateUid();
        element.settings = {
          ...fieldsEditItemFromParameterField(item.parameter),
          verboseName: item.parameter.verboseName || item.parameter.name,
          editable: true
        };
        element.updateFormField();
        element.name = generateElementName(element, names);

        if (
          this.controls.get_enabled.value &&
          this.controls.get_data_source.controls.columns.value.find(i => i.name == item.parameter.name)
        ) {
          const input = new Input();

          input.path = ['value'];
          input.valueType = InputValueType.Context;
          input.contextValue = ['elements', this.element.uid, ITEM_OUTPUT, item.parameter.name];

          element.settings.valueInput = input;
        }

        return element;
      });

    children.push(...fieldElements);

    const submitElement = new FormSubmitElementItem();

    submitElement.generateUid();
    submitElement.verboseNameInput = this.controls.submit_action.controls.verbose_name.value
      ? new FieldInput().deserialize(this.controls.submit_action.controls.verbose_name.value)
      : undefined;

    children.push(submitElement);

    const submitInputs: Input[] = [];

    submitInputs.push(
      ...formParameters
        .filter(item => item.getQueryInput)
        .map(item => {
          const input = new Input();

          input.path = [item.parameter.name];
          input.valueType = InputValueType.Context;
          input.contextValue = [ITEM_OUTPUT, item.parameter.name];

          return input;
        })
    );

    submitInputs.push(
      ...fieldElements.map(element => {
        const input = new Input();

        input.path = [element.settings.name];
        input.valueType = InputValueType.Context;
        input.contextValue = ['elements', element.uid, VALUE_OUTPUT];

        return input;
      })
    );

    this.controls.generated.patchValue(true);
    this.controls.submit_action.controls.inputs.patchValue(submitInputs);
    this.controls.children.patchValue(children);
  }

  controlsValid$(form: FormGroup, controls: string[]): Observable<boolean> {
    return combineLatest(controls.map(item => controlValid(form.controls[item]))).pipe(
      map(result => result.every(item => item)),
      debounceTime(60)
    );
  }

  submitActionOperationValid$(): Observable<boolean> {
    return this.controlsValid$(
      this.controls.submit_action,
      keys(this.controls.submit_action.controls).filter(key => key != 'inputs')
    );
  }

  submitActionValid$(): Observable<boolean> {
    return this.controlsValid$(this.controls.submit_action, keys(this.controls.submit_action.controls));
  }

  resetGetQuery() {
    this.controls.get_enabled.patchValue(false);
    this.controls.get_data_source.reset();
    this.markAsDirty();
  }

  isConfigured(instance: FormElementItem): Observable<boolean> {
    return this.elementConfigurationService.isFormConfigured(instance, { restrictDemo: true });
  }

  submit(): FormElementItem {
    const value = this.value;
    const instance = cloneDeep(this.element) as FormElementItem;

    instance.generated = value['generated'];
    instance.name = value['name'];
    instance.getDataSource = this.controls.get_enabled.value ? this.controls.get_data_source.serialize() : undefined;
    instance.columns = value['columns'];
    instance.style = value['style'];

    const action = this.controls.submit_action.submit().action as ViewSettingsAction;

    if (action) {
      instance.submitAction = action;
    } else {
      instance.submitAction = undefined;
    }

    if (value['visible_input']) {
      instance.visibleInput = new Input().deserialize(value['visible_input']);
    } else {
      instance.visibleInput = undefined;
    }

    instance.loadInvisible = value['load_invisible'];
    instance.margin = value['margin'];

    if (value['children']) {
      instance.children = value['children'];
      this.controls.children.patchValue(null, { emitEvent: false });
    }

    return instance;
  }
}
