import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { ViewContext, ViewContextElement } from '@modules/customize';
import { createFormFieldFactory, FieldType } from '@modules/fields';
import { TypedChanges } from '@shared';

@Component({
  selector: 'app-input-edit-static-value',
  templateUrl: './input-edit-static-value.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputEditStaticValueComponent implements OnInit, OnChanges {
  @Input() control: FormControl;
  @Input() staticValueField: FieldType;
  @Input() staticValueParams: Object;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() focusedInitial = false;
  @Input() placeholder = 'Text';
  @Input() resetEnabled = true;
  @Input() classes: string[] = [];
  @Input() fill = false;

  createField = createFormFieldFactory();
  form: FormGroup;
  staticValueParamsDisplay: Object;
  fieldTypes = FieldType;
  booleanValueEquals = (lhs, rhs) => lhs === rhs;

  constructor() {}

  ngOnInit() {
    // TODO: Temporary solution without changing parent
    this.form = new FormGroup({});
    this.form.controls['control'] = this.control;
  }

  ngOnChanges(changes: TypedChanges<InputEditStaticValueComponent>): void {
    if (changes.staticValueParams || changes.fill || changes.classes) {
      this.staticValueParamsDisplay = this.getStaticValueParams();
    }
  }

  getStaticValueParams(): Object {
    return {
      ...this.staticValueParams,
      output_format: undefined,
      classes: [...(this.fill ? ['select_fill', 'input_fill'] : []), ...this.classes],
      background: true
    };
  }
}
