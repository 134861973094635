import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { combineLatest, Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

import { Widget } from '@modules/dashboard';
import { Input } from '@modules/fields';
import { FieldInputControl } from '@modules/parameters';
import { controlValid } from '@shared';

export interface CustomizeBarWidgetEditFormOptions<S = any> {
  name?: string;
  visibleInput?: Input;
  elementStyles?: S;
  elementStylesEditable?: boolean;
  firstInit?: boolean;
  component?: any;
}

export interface CustomizeBarWidgetEditFormResult<T extends Widget, S = any> {
  widget: T;
  name?: string;
  visibleInput?: Input;
  elementStyles?: S;
}

export abstract class CustomizeBarBaseWidgetEditForm<T extends Widget, S> extends FormGroup {
  controls: {
    name: FormControl;
    title: FieldInputControl;
    visible_input: FieldInputControl;
  };

  widget: T;
  options: CustomizeBarWidgetEditFormOptions<S>;

  abstract init(widget?: T, options?: CustomizeBarWidgetEditFormOptions<S>);

  controlsValid$(controls: AbstractControl[]): Observable<boolean> {
    return combineLatest(controls.map(control => controlValid(control))).pipe(
      map(result => result.every(item => item)),
      debounceTime(60)
    );
  }

  abstract isConfigured(instance: T): Observable<boolean>;

  abstract submit(): CustomizeBarWidgetEditFormResult<T, S>;
}
