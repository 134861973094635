import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import isEqual from 'lodash/isEqual';
import toPairs from 'lodash/toPairs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { DynamicComponent, DynamicComponentArguments } from '@common/dynamic-component';
import { AppConfigService } from '@core';
import { ProjectSettingsStore } from '@modules/all-project-settings';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { FillEditContext } from '@modules/colors-components';
import { CustomizeHandler, CustomizeService, UnsavedChangesHandler } from '@modules/customize';
import { CustomizeBarContext } from '@modules/customize-bar';
import { Domain } from '@modules/domain';
import { MenuSection, MenuService, MenuSettingsStore } from '@modules/menu';
import { MetaService } from '@modules/meta';
import {
  CurrentEnvironmentStore,
  CurrentProjectStore,
  hasEnvironmentPermission,
  Project,
  ProjectPermissions
} from '@modules/projects';
import { RoutingService } from '@modules/routing';
import { isSet, parseNumber, scrollTo } from '@shared';

import { AdminPreviewType } from '../admin-template/admin-template.component';
import { CustomizeBarAppearanceEditButtonsComponent } from '../customize-bar-appearance-edit-buttons/customize-bar-appearance-edit-buttons.component';
import { CustomizeBarAppearanceEditElementWrappersComponent } from '../customize-bar-appearance-edit-element-wrappers/customize-bar-appearance-edit-element-wrappers.component';
import { CustomizeBarAppearanceEditFieldsComponent } from '../customize-bar-appearance-edit-fields/customize-bar-appearance-edit-fields.component';
import { CustomizeBarAppearanceEditGeneralComponent } from '../customize-bar-appearance-edit-general/customize-bar-appearance-edit-general.component';
import { CustomizeBarAppearanceEditComponent } from '../customize-bar-appearance-edit/customize-bar-appearance-edit.component';
import { CustomizeBarCustomCodeEditComponent } from '../customize-bar-custom-code-edit/customize-bar-custom-code-edit.component';
import { CustomizeBarEmailsEditComponent } from '../customize-bar-emails-edit/customize-bar-emails-edit.component';
import { CustomizeBarGeneralEditComponent } from '../customize-bar-general-edit/customize-bar-general-edit.component';
import { CustomizeBarIntegrationsEditComponent } from '../customize-bar-integrations-edit/customize-bar-integrations-edit.component';
import { CustomizeBarLanguageRegionEditComponent } from '../customize-bar-language-region-edit/customize-bar-language-region-edit.component';
import { CustomizeBarMenuEditComponent } from '../customize-bar-menu-edit/customize-bar-menu-edit.component';
import { CustomizeBarPagesEditComponent } from '../customize-bar-pages-edit/customize-bar-pages-edit.component';
import { CustomizeBarSharingEditComponent } from '../customize-bar-sharing-edit/customize-bar-sharing-edit.component';
import { ProjectDomainUpdateForm } from '../sign-up-builder/project-domain-update.form';
import { ProjectSignUpForm } from '../sign-up-builder/project-sign-up.form';
import { MenuBlockControl } from './menu-block.control';
import { MenuUpdateForm } from './menu-update.form';
import { ProjectAppearanceContext } from './project-appearance.context';
import { ProjectSettingsUpdateForm } from './project-settings-update.form';
import { ProjectUpdateForm } from './project-update.form';

interface MenuItemChild {
  path: string;
  component: any;
  inputs: Object;
  displayComponent?: DisplayComponent;
  adminPreview?: AdminPreviewType;
  children?: MenuItemChild[];
}

interface MenuItem extends MenuItemChild {
  title: string;
  subtitle: string;
  icon: string;
}

enum DisplayComponent {
  Default = 'default'
}

@Component({
  selector: 'app-project-settings',
  templateUrl: './project-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    CustomizeBarContext,
    FillEditContext,
    ProjectUpdateForm,
    MenuUpdateForm,
    ProjectSettingsUpdateForm,
    ProjectAppearanceContext,
    ProjectSignUpForm,
    ProjectDomainUpdateForm
  ]
})
export class ProjectSettingsComponent implements OnInit, OnDestroy, CustomizeHandler, UnsavedChangesHandler {
  @ViewChild('root') root: ElementRef;
  @ViewChildren(DynamicComponent) dynamicComponents = new QueryList<DynamicComponent>();

  menuItems: MenuItem[] = [
    {
      path: 'general',
      title: 'General',
      subtitle: 'App name, URL and logo',
      icon: 'gear_2',
      component: CustomizeBarGeneralEditComponent,
      inputs: {
        projectForm: this.projectForm,
        settingsForm: this.settingsForm
      }
    },
    {
      path: 'appearance',
      title: 'Appearance',
      subtitle: 'Layout, colors, fonts',
      icon: 'palette',
      component: CustomizeBarAppearanceEditComponent,
      inputs: {
        context: this.appearanceContext
      },
      children: [
        {
          path: 'general',
          component: CustomizeBarAppearanceEditGeneralComponent,
          inputs: {
            context: this.appearanceContext,
            settingsForm: this.settingsForm
          }
        },
        {
          path: 'menu',
          component: CustomizeBarMenuEditComponent,
          inputs: {
            context: this.appearanceContext
          },
          children: [
            {
              path: ':uid',
              component: CustomizeBarPagesEditComponent,
              inputs: {
                context: this.appearanceContext
              }
            }
          ]
        },
        {
          path: 'buttons',
          component: CustomizeBarAppearanceEditButtonsComponent,
          inputs: {
            context: this.appearanceContext
          },
          adminPreview: AdminPreviewType.Buttons
        },
        {
          path: 'fields',
          component: CustomizeBarAppearanceEditFieldsComponent,
          inputs: {
            context: this.appearanceContext
          },
          adminPreview: AdminPreviewType.Fields
        },
        {
          path: 'cards',
          component: CustomizeBarAppearanceEditElementWrappersComponent,
          inputs: {
            context: this.appearanceContext
          }
        }
      ]
    },
    // {
    //   path: 'menu',
    //   title: 'Menu',
    //   subtitle: 'Pages navigation',
    //   icon: 'fileds',
    //   component: CustomizeBarMenuEditComponent,
    //   inputs: {
    //     context: this.appearanceContext
    //   },
    //   children: [
    //     {
    //       path: 'items',
    //       component: CustomizeBarPagesEditComponent,
    //       inputs: {
    //         context: this.appearanceContext
    //       }
    //     }
    //   ]
    // },
    // {
    //   type: 'domain',
    //   title: 'Custom Domain',
    //   subtitle: 'Host app under your domain',
    //   icon: 'external_link',
    //   component: CustomizeBarDomainEditComponent,
    //   inputs: {
    //     projectForm: this.projectForm
    //   }
    // },
    {
      path: 'language_region',
      title: 'Language & Region',
      subtitle: 'Localization, timezone and formats',
      icon: 'earth_planet',
      component: CustomizeBarLanguageRegionEditComponent,
      inputs: {
        projectDomainUpdateForm: this.projectDomainUpdateForm
      }
    },
    {
      path: 'emails',
      title: 'Emails',
      subtitle: 'Settings, templates',
      icon: 'email',
      component: CustomizeBarEmailsEditComponent,
      inputs: {
        projectSignUpForm: this.projectSignUpForm,
        projectDomainUpdateForm: this.projectDomainUpdateForm
      }
    },
    // {
    //   path: 'features',
    //   title: 'Features',
    //   subtitle: 'Advanced functionality',
    //   icon: 'switch',
    //   component: CustomizeBarFeaturesEditComponent,
    //   inputs: {
    //     settingsForm: this.settingsForm
    //   }
    // },
    {
      path: 'sharing',
      title: 'Sharing',
      subtitle: 'Meta info for URL sharing',
      icon: 'redo',
      component: CustomizeBarSharingEditComponent,
      inputs: {
        projectForm: this.projectForm,
        projectDomainUpdateForm: this.projectDomainUpdateForm
      }
    },
    {
      path: 'integrations',
      title: 'Integrations',
      subtitle: '3rd party services',
      icon: 'chip',
      component: CustomizeBarIntegrationsEditComponent,
      inputs: {
        settingsForm: this.settingsForm
      }
    },
    {
      path: 'custom_code',
      title: 'Scripts & Styles',
      subtitle: 'Set global JS and CSS',
      icon: 'console',
      component: CustomizeBarCustomCodeEditComponent,
      inputs: {
        settingsForm: this.settingsForm
      }
    }
  ];
  selectedMenuItemPath: string[] = [];
  displayComponent: DisplayComponent = DisplayComponent.Default;
  displayComponents = DisplayComponent;
  adminPreview: AdminPreviewType = AdminPreviewType.Default;
  settingsComponents: DynamicComponentArguments[] = [];
  project: Project;
  projectHome: string;
  domain: Domain;
  loading = false;
  domainFormSubscription: Subscription;

  constructor(
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private activatedRoute: ActivatedRoute,
    public projectForm: ProjectUpdateForm,
    public settingsForm: ProjectSettingsUpdateForm,
    public appearanceContext: ProjectAppearanceContext,
    private projectSignUpForm: ProjectSignUpForm,
    public projectDomainUpdateForm: ProjectDomainUpdateForm,
    private menuService: MenuService,
    private customizeBarContext: CustomizeBarContext,
    private currentProjectStore: CurrentProjectStore,
    private projectSettingsStore: ProjectSettingsStore,
    private customizeService: CustomizeService,
    private menuSettingsStore: MenuSettingsStore,
    private routing: RoutingService,
    private appConfigService: AppConfigService,
    public metaService: MetaService,
    private analyticsService: UniversalAnalyticsService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    if (!hasEnvironmentPermission(this.currentEnvironmentStore.instance, ProjectPermissions.ProjectCustomization)) {
      this.routing.navigateApp(['not-allowed'], { skipLocationChange: true });
      return;
    }

    this.menuService.section = MenuSection.None;
    this.loading = true;
    this.cd.markForCheck();

    this.activatedRoute.params.pipe(untilDestroyed(this)).subscribe(params => {
      const path = toPairs(params).reduce((acc, [k, v]) => {
        const match = k.match(/path(\d+)/);
        if (match) {
          const index = parseNumber(match[1]);
          if (index !== null) {
            acc[index - 1] = v;
          }
        }
        return acc;
      }, []);

      if (path.length) {
        this.setSelectedMenuItemPath(path);
      } else {
        const link = this.currentProjectStore.instance.settingsLayoutLink(this.menuItems[0].path);
        this.routing.navigateApp(link, { replaceUrl: true });
      }
    });

    this.customizeService.setHandler(this);
    this.customizeService.setHandlerInfo(this, {
      breadcrumbs: [],
      inAppDisabled: true
    });

    this.customizeBarContext.settingsComponents$.pipe(untilDestroyed(this)).subscribe(item => {
      this.settingsComponents = item;
      this.cd.detectChanges();
      this.scrollToTop();
    });

    this.projectDomainUpdateForm
      .getInstance$()
      .pipe(untilDestroyed(this))
      .subscribe(domain => {
        this.domain = domain;
        this.cd.markForCheck();
      });

    combineLatest(this.currentProjectStore.getFirst(), this.projectSettingsStore.getAllSettingsFirst$())
      .pipe(untilDestroyed(this))
      .subscribe(
        ([project, projectSettings]) => {
          const webBaseUrl =
            project && project.domain ? `https://${project.domain.actualDomain}` : this.appConfigService.webBaseUrl;

          this.project = project;
          this.domain = project ? project.domain : undefined;
          this.projectHome = [webBaseUrl, 'app', project.uniqueName].join('/');
          this.loading = false;
          this.cd.markForCheck();

          this.projectForm.init(project);
          this.settingsForm.init(projectSettings);
          this.projectSignUpForm.init(project);

          this.projectForm.valueChanges.pipe(debounceTime(200), untilDestroyed(this)).subscribe(() => {
            this.projectForm.submit().subscribe(() => this.onSave());
          });

          this.settingsForm.valueChanges.pipe(debounceTime(200), untilDestroyed(this)).subscribe(() => {
            this.settingsForm.submit().subscribe(() => this.onSave());
          });

          this.projectSignUpForm.valueChanges.pipe(debounceTime(200), untilDestroyed(this)).subscribe(() => {
            this.projectSignUpForm.submit().subscribe(() => this.onSave());
          });
        },
        () => {
          this.loading = false;
          this.cd.markForCheck();
        }
      );

    this.currentProjectStore
      .get()
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        const webBaseUrl =
          result && result.domain ? `https://${result.domain.actualDomain}` : this.appConfigService.webBaseUrl;

        this.project = result;
        this.domain = result ? result.domain : undefined;
        this.projectHome = [webBaseUrl, 'app', result.uniqueName].join('/');
        this.cd.markForCheck();

        this.projectForm.project = this.project;
        this.projectSignUpForm.project = this.project;
        this.initDomainForm();
      });
  }

  ngOnDestroy(): void {
    this.menuService.section = MenuSection.Default;
    this.customizeService.unsetHandler(this);
  }

  initDomainForm() {
    if (this.domainFormSubscription) {
      this.domainFormSubscription.unsubscribe();
      this.domainFormSubscription = undefined;
    }

    this.projectDomainUpdateForm.init(this.domain);

    this.domainFormSubscription = this.projectDomainUpdateForm.valueChanges
      .pipe(debounceTime(200), untilDestroyed(this))
      .subscribe(() => {
        this.projectDomainUpdateForm.submit().subscribe(() => this.onSave());
      });
  }

  setSelectedMenuItemPath(path: string[]) {
    if (isEqual(this.selectedMenuItemPath, path)) {
      return;
    }

    this.selectedMenuItemPath = path;
    this.cd.markForCheck();

    const components: {
      component: any;
      inputs: Object;
    }[] = [];
    let displayComponent: DisplayComponent;
    let adminPreview: AdminPreviewType;

    const iterate = (array: MenuItemChild[], index: number) => {
      let menuItem: MenuItemChild;
      let pathInput: string;

      for (const item of array) {
        const variablePath = item.path.match(/^:(\w+)$/);

        if (variablePath) {
          menuItem = item;
          pathInput = variablePath[1];
          break;
        } else if (item.path == path[index]) {
          menuItem = item;
          break;
        }
      }

      if (!menuItem) {
        return;
      }

      if (menuItem.component) {
        components.push({
          component: menuItem.component,
          inputs: {
            ...menuItem.inputs,
            ...(isSet(pathInput) && {
              [pathInput]: path[index]
            })
          }
        });
      }

      if (menuItem.displayComponent) {
        displayComponent = menuItem.displayComponent;
      }

      if (menuItem.adminPreview) {
        adminPreview = menuItem.adminPreview;
      }

      if (menuItem.children && isSet(path[index + 1])) {
        iterate(menuItem.children, index + 1);
      }
    };

    iterate(this.menuItems, 0);

    this.displayComponent = displayComponent ? displayComponent : DisplayComponent.Default;
    this.adminPreview = adminPreview ? adminPreview : AdminPreviewType.Default;
    this.cd.markForCheck();

    const currentComponents = this.customizeBarContext.settingsComponents;
    const newComponents: DynamicComponentArguments[] = components.map((component, i) => {
      const currentComponent = currentComponents[i] ? currentComponents[i].component : undefined;
      if (currentComponent !== component.component) {
        return {
          component: component.component,
          inputs: component.inputs || {}
        };
      } else {
        return currentComponents[i];
      }
    });
    this.customizeBarContext.setSettingsComponents(newComponents);
  }

  hasUnsavedChanges(currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): boolean {
    const currentComponent =
      this.dynamicComponents.first && this.dynamicComponents.first.currentComponent
        ? this.dynamicComponents.first.currentComponent.instance
        : undefined;

    if (currentComponent instanceof CustomizeBarAppearanceEditComponent) {
      const getCurrentComponentRoute = (route: ActivatedRouteSnapshot): ActivatedRouteSnapshot => {
        if (route.component === ProjectSettingsComponent) {
          return route;
        } else if (route.firstChild) {
          return getCurrentComponentRoute(route.firstChild);
        }
      };
      const nextRouteSameComponent = nextState ? getCurrentComponentRoute(nextState.root) : undefined;

      if (!nextRouteSameComponent || nextRouteSameComponent.params['path1'] !== 'appearance') {
        return currentComponent.context.getHasChanges();
      }
    }
  }

  scrollToTop(animated = true) {
    if (!this.root) {
      return;
    }

    const duration = animated && this.root.nativeElement.scrollTop > 0 ? 0.4 : 0;
    scrollTo(this.root.nativeElement, 0, duration);
  }

  onBlockControlPreviewClick(block: MenuBlockControl) {
    const uid = block.instance ? block.instance.uid : undefined;

    if (isSet(uid)) {
      const link = this.currentProjectStore.instance.settingsLayoutLink('appearance', 'menu', uid);
      this.routing.navigateApp(link);
    }
  }

  onMenuItemClicked(name: string) {
    this.analyticsService.sendSimpleEvent(AnalyticsEvent.AppSettings.MenuItemClicked, {
      Name: name
    });
  }

  onSave() {
    this.analyticsService.sendSimpleEvent(AnalyticsEvent.Project.BuilderChange, {
      Type: 'app_settings'
    });
  }

  // TODO: Fix empty handler
  closePopup(uid?: string): void {}
}
