import { Injectable, Injector, OnDestroy } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import range from 'lodash/range';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable, throwError } from 'rxjs';
import { catchError, delay, distinctUntilChanged, filter, map } from 'rxjs/operators';

import { AppFormGroup, FormUtils } from '@common/form-utils';
import { PopupService } from '@common/popups';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ServerRequestError } from '@modules/api';
import { MenuGeneratorService } from '@modules/menu';
import { ProjectSettingsService } from '@modules/project-settings';
import { ProjectTokenService, SecretTokenService } from '@modules/projects';
import { GoogleSheetsGeneratorService, GoogleSheetsParamsOptions } from '@modules/resource-generators';
import { ResourceParamsResult } from '@modules/resources';
import { controlValue, generateUUID, isSet, setControlDisabled } from '@shared';

import { GoogleDriveFile } from '../../../data/google-sheets-resource-params.data';
import { BaseResourceSettingsForm } from '../base-resource-settings/base-resource-settings.form';

export const parseSheetUrl = (fileUrl: string): { id: string } => {
  if (!isSet(fileUrl)) {
    return;
  }

  const fileMatch = String(fileUrl)
    .trim()
    .match(/^https:\/\/docs\.google\.com\/spreadsheets\/d\/([^/?#]+)/);

  return fileMatch ? { id: fileMatch[1] } : undefined;
};

export const validateFileUrl: ValidatorFn = control => {
  if (!control.value) {
    return null;
  }

  const sheet = parseSheetUrl(control.value);

  if (!sheet) {
    return { local: ['Wrong Sheet URL'] };
  }

  return null;
};

export const validateRange: ValidatorFn = control => {
  if (!control.value) {
    return null;
  }

  const match1 = control.value.match(/^[A-Z]+:[A-Z]+$/);
  const match2 = control.value.match(/^[A-Z]+(\d+):[A-Z]+(\d+)$/);

  if (!match1 && !match2) {
    return { local: ['Wrong range format'] };
  }

  return null;
};

export class GoogleSheetsFileControl extends FormGroup {
  controls: {
    uid: FormControl;
    file_url: FormControl;
    file: FormControl;
    unique_name: FormControl;
    verbose_name: FormControl;
    sheet: FormControl;
    range: FormControl;
  };

  constructor(options: { verboseNameValidator?: ValidatorFn } = {}) {
    super({
      uid: new FormControl(generateUUID(), Validators.required),
      file_url: new FormControl('', validateFileUrl),
      file: new FormControl(undefined, Validators.required),
      unique_name: new FormControl(''),
      verbose_name: new FormControl('', [
        Validators.required,
        ...(options.verboseNameValidator && [options.verboseNameValidator])
      ]),
      sheet: new FormControl('', Validators.required),
      range: new FormControl('', [Validators.required, validateRange])
    });

    setControlDisabled(this.controls.file_url, true);
  }
}

export class GoogleSheetsFileArray extends FormArray {
  controls: GoogleSheetsFileControl[];
}

export class Form extends AppFormGroup {
  controls: {
    access_token: FormControl;
    params: FormControl;
    files: GoogleSheetsFileArray;
    sync: FormControl;
  };

  constructor() {
    super({
      access_token: new FormControl(null, Validators.required),
      params: new FormControl(null, Validators.required),
      files: new GoogleSheetsFileArray([]),
      sync: new FormControl(true)
    });
  }
}

@Injectable()
export class GoogleSheetsResourceSettingsForm extends BaseResourceSettingsForm<GoogleSheetsParamsOptions>
  implements OnDestroy {
  tokenName = 'oauth_access_token';
  modelDescriptionNameEditing = true;

  form = new Form();

  constructor(
    private googleSheetsGeneratorService: GoogleSheetsGeneratorService,
    private analyticsService: UniversalAnalyticsService,
    protected secretTokenService: SecretTokenService,
    protected formUtils: FormUtils,
    protected projectSettingsService: ProjectSettingsService,
    protected projectTokenService: ProjectTokenService,
    protected popupService: PopupService,
    protected menuGeneratorService: MenuGeneratorService,
    protected injector: Injector
  ) {
    super(
      secretTokenService,
      formUtils,
      projectSettingsService,
      projectTokenService,
      popupService,
      menuGeneratorService,
      injector
    );
  }

  validateExisting(): ValidatorFn {
    return (control: FormControl) => {
      const parent = control.parent as GoogleSheetsFileControl;
      if (!parent) {
        return;
      }

      const array = parent.parent as GoogleSheetsFileArray;
      if (!array) {
        return;
      }

      const group = array.controls.find(item => item.controls['verbose_name'] === control);
      const uniqueName = this.googleSheetsGeneratorService.getUniqueName(control.value);

      if (
        uniqueName &&
        array.controls
          .filter(item => item !== group)
          .find(item => this.googleSheetsGeneratorService.getUniqueName(item.value['verbose_name']) == uniqueName)
      ) {
        return { local: ['File with such name already added'] };
      }
    };
  }

  createItem(state?: Object): GoogleSheetsFileControl {
    const group = new GoogleSheetsFileControl({ verboseNameValidator: this.validateExisting() });

    if (state) {
      const file = state['file'] as GoogleDriveFile;
      group.patchValue({
        ...state,
        ...(file && { file_url: `https://docs.google.com/spreadsheets/d/${file.id}` })
      });
    }

    controlValue(group.controls.verbose_name)
      .pipe(
        distinctUntilChanged(),
        filter(value => typeof value === 'string'),
        untilDestroyed(this)
      )
      .subscribe(value => {
        const cleanValue = value.replace(/\./g, '_');
        if (cleanValue !== value) {
          group.controls.verbose_name.patchValue(cleanValue);
        }
      });

    controlValue(group.controls.unique_name)
      .pipe(
        distinctUntilChanged(),
        filter(value => typeof value === 'string'),
        untilDestroyed(this)
      )
      .subscribe(value => {
        const cleanValue = value.replace(/\./g, '_');
        if (cleanValue !== value) {
          group.controls.unique_name.patchValue(cleanValue);
        }
      });

    group.controls.verbose_name.valueChanges
      .pipe(distinctUntilChanged(), delay(0), untilDestroyed(this))
      .subscribe(() => {
        this.form.controls.files.controls
          .filter(item => item !== group)
          .forEach((otherGroup: GoogleSheetsFileControl) => {
            otherGroup.controls.verbose_name.updateValueAndValidity();
          });
      });

    return group;
  }

  itemsSet(controls: GoogleSheetsFileControl[]) {
    const array = this.form.controls['files'] as FormArray;
    range(array.controls.length).forEach(() => array.removeAt(0));
    controls.forEach(item => array.push(item));
    this.form.updateValueAndValidity();
  }

  itemsAppend(control: GoogleSheetsFileControl) {
    const array = this.form.controls['files'] as FormArray;
    array.push(control);
    array.updateValueAndValidity();
  }

  itemsRemove(control: GoogleSheetsFileControl) {
    const array = this.form.controls['files'] as FormArray;
    const index = array.controls.findIndex(item => item === control);
    array.removeAt(index);
    array.updateValueAndValidity();
  }

  initResourceValue(): Observable<void> {
    return this.googleSheetsGeneratorService.getParamsOptions(this.project, this.environment, this.resource).pipe(
      map(result => {
        this.form.patchValue({
          access_token: result.access_token,
          params: result.token_params,
          sync: !!this.resource.isSynced()
        });

        this.itemsSet(result.files.map(item => this.createItem(item)));
      })
    );
  }

  getOptions(): GoogleSheetsParamsOptions {
    return {
      access_token: this.form.value['access_token'],
      token_params: this.form.value['params'],
      files: this.form.value['files']
    };
  }

  getParams(): ResourceParamsResult | Observable<ResourceParamsResult> {
    const options = this.getOptions();
    return this.googleSheetsGeneratorService
      .generateParams(this.project, this.environment, this.typeItem, options)
      .pipe(
        map(result => {
          return {
            ...result,
            resourceName: this.resourceForm.value['name'],
            sync: this.form.value['sync'],
            syncModelDescriptions: this.getParamsSyncModelDescriptions(result)
          };
        }),
        catchError(error => {
          if (!this.resource) {
            const errorMessage = error instanceof ServerRequestError && error.errors.length ? error.errors[0] : error;

            this.analyticsService.sendSimpleEvent(AnalyticsEvent.Resource.ResourceAddFailedDiagnostics, {
              ResourceID: this.typeItem.name,
              Error: errorMessage,
              Options: options,
              ResourceContents: this.googleSheetsGeneratorService.latestFileContents
            });
          }

          return throwError(error);
        })
      );
  }
}
