var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { ElementItem, ViewContext, ViewContextElement } from '@modules/customize';
import { createFormFieldFactory, FieldType, InputValueType } from '@modules/fields';
import { FieldInputControl } from '@modules/parameters';
import { controlValue, isSet } from '@shared';
import { TextStyleControl } from '../text-style-edit/text-style.control';
var CustomizeBarInputEditComponent = /** @class */ (function () {
    function CustomizeBarInputEditComponent(cd) {
        this.cd = cd;
        this.required = false;
        this.textStyleTip = 'Customize Text style';
        this.bindComponents = false;
        this.staticValueArray = false;
        this.filterFields = [];
        this.userInput = false;
        this.focusedInitial = false;
        this.placeholder = 'Text';
        this.formulaPlaceholder = 'Formula';
        this.jsPlaceholder = 'return 2 * 3;';
        this.resetEnabled = true;
        this.classes = [];
        this.fill = false;
        this.createField = createFormFieldFactory();
        this.inputValueTypes = InputValueType;
        this.overrideFocusedInitial = false;
        this.itemForm$ = new BehaviorSubject(undefined);
        this.staticValueType = false;
        this.fieldTypes = FieldType;
        this.booleanValueEquals = function (lhs, rhs) { return lhs === rhs; };
    }
    CustomizeBarInputEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.itemForm$
            .pipe(switchMap(function (form) { return controlValue(form.controls.value_type); }), untilDestroyed(this))
            .subscribe(function (valueType) {
            _this.staticValueType = _this.isStaticValueType(valueType);
            _this.cd.markForCheck();
        });
        this.itemForm$
            .pipe(switchMap(function (form) { return form.controls.static_value.valueChanges.pipe(map(function () { return form; })); }), filter(function () { return _this.staticValueType; }), untilDestroyed(this))
            .subscribe(function (form) {
            if (!isSet(form.controls.value_type.value)) {
                form.controls.value_type.patchValue(InputValueType.StaticValue);
            }
        });
    };
    CustomizeBarInputEditComponent.prototype.ngOnDestroy = function () { };
    CustomizeBarInputEditComponent.prototype.ngOnChanges = function (changes) {
        if (changes.staticValueParams || changes.fill || changes.classes) {
            this.staticValueParamsDisplay = this.getStaticValueParams();
        }
        if (changes.itemForm) {
            this.itemForm$.next(this.itemForm);
        }
    };
    CustomizeBarInputEditComponent.prototype.getStaticValueParams = function () {
        return __assign({ background: true }, this.staticValueParams, { output_format: undefined, classes: (this.fill ? ['select_fill', 'input_fill'] : []).concat(this.classes), thumb_inside: true, fixed_height: true, map_height: 280 });
    };
    CustomizeBarInputEditComponent.prototype.isStaticValueType = function (valueType) {
        return !isSet(valueType) || valueType == InputValueType.StaticValue;
    };
    CustomizeBarInputEditComponent.prototype.toggleFormula = function () {
        var currentValueType = this.itemForm.controls.value_type.value;
        if (this.isStaticValueType(currentValueType)) {
            this.itemForm.controls.value_type.patchValue(InputValueType.Formula);
            var staticValue = this.itemForm.controls.static_value.value;
            if (isSet(staticValue)) {
                this.itemForm.controls.formula_value.patchValue(JSON.stringify(staticValue));
            }
            this.overrideFocusedInitial = true;
        }
        else {
            this.itemForm.controls.value_type.patchValue(InputValueType.StaticValue);
        }
    };
    return CustomizeBarInputEditComponent;
}());
export { CustomizeBarInputEditComponent };
