import { AsyncValidatorFn, ValidatorFn } from '@angular/forms';
import { Observable } from 'rxjs';

import { DialogResult } from './dialog-result';

export enum DialogButtonType {
  Default,
  Primary,
  Danger,
  DangerSecondary,
  Submit
}

export enum DialogButtonPosition {
  Left = 'left'
}

export enum DialogButtonHotkey {
  Cancel = 'cancel',
  Submit = 'Submit'
}

// TODO: make name any type
export interface DialogButton<R = any> {
  name: string;
  label: string;
  tint?: string;
  icon?: string;
  tip?: string;
  type: DialogButtonType;
  hotkey?: DialogButtonHotkey;
  position?: DialogButtonPosition;
  executor?: (result: DialogResult) => Observable<R> | R;
}

export interface DialogInput {
  name: string;
  label?: string;
  labelAdditional?: string;
  type: string;
  placeholder?: string;
  default?: string;
  description?: string;
  validators?: ValidatorFn[];
  asyncValidators?: AsyncValidatorFn[];
}

export interface DialogOptions<R = any> {
  title: string;
  description?: string;
  subtitle?: string;
  inputs?: DialogInput[];
  buttons?: DialogButton<R>[];
  style?: string;
  dark?: boolean;
  theme?: boolean;
}
