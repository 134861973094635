<tr
  *ngFor="let row of displayRows; let first = first"
  class="table__row"
  [class.table__row_selected]="selection && first"
  [class.inverse]="selection && first"
>
  <td *ngIf="leftPaddingColumn" class="table__column table__column_left-padding table__stub-column"></td>

  <td *ngFor="let column of row; let last = last" class="table__column table__stub-column">
    <span *ngIf="!rowActionsButtons || !last" [class.loading-animation]="animating">
      <span [class.stub-text]="textStub">{{ column }}</span>
    </span>

    <div *ngIf="rowActionsButtons && last" class="table__column-actions">
      <div *ngFor="let item of rowActionsButtons" class="table__column-action">
        <div
          class="button button_small button_tint-color"
          [class.button_theme]="theme"
          [class.button_styles]="theme"
          [appButtonTintColor]="'accentColor' | appThemeColorHexOption: true | async"
          [appButtonTintColorStyle]="tintStyles.Default"
          [appActionElementStyles]="actionElementStylesDefault"
          [appActionElementStylesApplyGlobal]="stylesApplyGlobal"
          [appActionElementStylesTintColorStyle]="tintStyles.Default"
        >
          <span class="button__label">
            <span [class.loading-animation]="animating">
              <span [class.stub-text]="textStub">{{ item }}</span>
            </span>
          </span>
        </div>
      </div>
    </div>
  </td>

  <td *ngIf="newColumn" class="table__column table__column_disabled"></td>
</tr>
