import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Task } from '../task';
import { TaskScenario } from '../task-scenario';

@Injectable()
export class CreateFlexViewTask extends Task {
  init(): Observable<TaskScenario> | TaskScenario {
    return new TaskScenario([
      {
        link: ['project', 'custom_views'],
        items: []
      }
    ]);
  }
}
