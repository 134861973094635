import { Injectable } from '@angular/core';
import isEqual from 'lodash/isEqual';
import keys from 'lodash/keys';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { traverseElementItemFonts, ViewSettingsStore } from '@modules/customize';
import {
  CurrentEnvironmentStore,
  CurrentProjectStore,
  ProjectSettingsName,
  ProjectSettingsService
} from '@modules/projects';
import { defaultFontName } from '@modules/theme';

import { AllProjectSettings } from '../../data/all-project-settings';
import { ProjectSettingsStore } from '../../stores/project-settings.store';

@Injectable()
export class PageFontsService {
  constructor(
    public currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private viewSettingsStore: ViewSettingsStore,
    private projectSettingsService: ProjectSettingsService,
    private projectSettingsStore: ProjectSettingsStore
  ) {}

  updateFontsUsed(): Observable<boolean> {
    return combineLatest(this.viewSettingsStore.getFirst(), this.projectSettingsStore.getAllSettingsFirst$()).pipe(
      switchMap(([viewSettings, existingProjectSettings]) => {
        const fontsObject: { [k: string]: boolean } = {};

        traverseElementItemFonts(viewSettings, font => {
          fontsObject[font] = true;
        });

        const globalFonts = [defaultFontName];

        if (existingProjectSettings && existingProjectSettings.fontHeading) {
          globalFonts.push(existingProjectSettings.fontHeading);
        }

        if (existingProjectSettings && existingProjectSettings.fontRegular) {
          globalFonts.push(existingProjectSettings.fontRegular);
        }

        const fonts = keys(fontsObject).filter(item => !globalFonts.includes(item));

        if (existingProjectSettings && isEqual(existingProjectSettings.fontsUsed, fonts)) {
          return of(false);
        }

        const projectSettings = new AllProjectSettings({ fontsUsed: fonts });

        return this.projectSettingsService
          .create(
            this.currentProjectStore.instance.uniqueName,
            this.currentEnvironmentStore.instance.uniqueName,
            projectSettings.serialize([ProjectSettingsName.FontsUsed])[0]
          )
          .pipe(
            tap(result => this.projectSettingsStore.updateOrAddItem(result)),
            map(() => true)
          );
      })
    );
  }
}
