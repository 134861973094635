import { Inject, Injectable, Injector, Input } from '@angular/core';

import { ThinDialogPopupComponent } from '@common/dialog-popup';
import { PopupService } from '@common/popups';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { ListModelDescriptionDataSource } from '@modules/data-sources';
import { FilterItem2, Sort } from '@modules/filters';
import { ModelDescription } from '@modules/models';
import { isSet } from '@shared';

import { ACTION_SERVICE_EXPORT_COMPONENT } from '../../data/injection-tokens';

@Injectable()
export class ExportController {
  constructor(
    @Inject(ACTION_SERVICE_EXPORT_COMPONENT) private exportComponent: any,
    private popupService: PopupService,
    private injector: Injector
  ) {}

  openPopup(options: {
    dataSource: ListModelDescriptionDataSource;
    queryOptions?: {
      filters?: FilterItem2[];
      search?: string;
      sort?: Sort[];
    };
    modelDescription?: ModelDescription;
    context?: ViewContext;
    contextElement?: ViewContextElement;
    localContext?: Object;
    params?: Object;
    ids?: number[];
    theme?: boolean;
  }) {
    const title = options.modelDescription ? options.modelDescription.verboseNamePlural : 'Records';
    const primaryKey = options.modelDescription ? options.modelDescription.primaryKeyField : undefined;

    this.popupService.push({
      component: this.exportComponent,
      popupComponent: ThinDialogPopupComponent,
      popupComponentTheme: options.theme,
      inputs: {
        title: title,
        dataSource: options.dataSource,
        queryOptions: options.queryOptions,
        context: options.context,
        contextElement: options.contextElement,
        localContext: options.localContext,
        params: options.params,
        primaryKey: primaryKey,
        ids: options.ids,
        theme: options.theme
      },
      injector: this.injector
    });

    return true;
  }
}
