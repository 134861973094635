<form [formGroup]="form" (ngSubmit)="submit()">
  <app-popup2 [size]="'m'">
    <app-popup2-header>
      <app-popup2-title>Create an Environment</app-popup2-title>
      <app-popup2-description>
        Test your app against a staging resource (database or API) and seamlessly switch it to production resources when
        in user mode.
      </app-popup2-description>
    </app-popup2-header>

    <app-page-block>
      <app-auto-field
        [form]="form"
        [autofocus]="true"
        [field]="
          createField({
            name: 'name',
            label: 'Environment Name',
            field: 'CharField',
            required: true,
            params: { classes: ['input_fill'] }
          })
        "
      >
      </app-auto-field>
    </app-page-block>

    <app-page-block>
      <app-auto-field
        [form]="form"
        [field]="
          createField({
            name: 'color',
            label: 'color',
            field: 'ColorField',
            params: { classes: ['select_fill'], custom_colors: false }
          })
        "
      >
      </app-auto-field>
    </app-page-block>

    <app-popup2-footer>
      <app-page-block>
        <app-stack [align]="'right'">
          <a
            href="javascript:void(0)"
            class="button popup2__footer-item"
            [class.button_disabled]="loading"
            (click)="close()"
          >
            Cancel
          </a>

          <button
            type="submit"
            class="button button_primary button_shadow popup2__footer-item"
            [class.button_disabled]="form.invalid || loading"
          >
            <app-loader-small *ngIf="loading" class="button__icon button__icon_left"></app-loader-small>
            Create
          </button>
        </app-stack>
      </app-page-block>
    </app-popup2-footer>
  </app-popup2>
</form>
