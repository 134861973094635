<app-workflow-step-card
  *ngIf="triggerEditable"
  [title]="trigger ? (trigger.getTypeStr() | appCapitalize) : 'Choose trigger'"
  [subtitle]="trigger ? (triggerDescription | appCapitalize) : 'not specified'"
  [icon]="trigger?.getTypeIcon()"
  [active]="customizing$ | async"
  [contrast2]="true"
  [interactive]="true"
  [connectorTop]="false"
  (click)="customize()"
></app-workflow-step-card>

<app-workflow-step-card
  *ngIf="!triggerEditable"
  [title]="label || 'Trigger'"
  [subtitle]="triggerDescription | appCapitalize"
  [icon]="icon || 'cursor'"
  [active]="customizing$ | async"
  [contrast2]="true"
  [interactive]="true"
  [connectorTop]="false"
  (click)="customize()"
></app-workflow-step-card>

<div class="workflow-connection">
  <div class="workflow-connection__bounds">
    <div class="workflow-connection__dash"></div>
  </div>
</div>
