/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../workflow-step-card/workflow-step-card.component.ngfactory";
import * as i2 from "../workflow-step-card/workflow-step-card.component";
import * as i3 from "@angular/common";
import * as i4 from "../../../../../shared/pipes/capitalize/capitalize.pipe";
import * as i5 from "../../../../customize/data/view-context-element";
import * as i6 from "../../../../customize/data/view-context";
import * as i7 from "./workflow-trigger.component";
import * as i8 from "../../../services/customize-bar-context/customize-bar.context";
import * as i9 from "../../../../projects/stores/current-project.store";
import * as i10 from "../../../../projects/stores/current-environment.store";
import * as i11 from "../../../../workflow/services/automation/automation.service";
import * as i12 from "../../../../model-queries/stores/model-description.store";
import * as i13 from "../../../services/workflow-edit-context/workflow-edit.context";
import * as i14 from "../../../../action-queries/services/action/action.service";
import * as i15 from "../../../../api/services/api/api.service";
var styles_WorkflowTriggerComponent = [];
var RenderType_WorkflowTriggerComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_WorkflowTriggerComponent, data: {} });
export { RenderType_WorkflowTriggerComponent as RenderType_WorkflowTriggerComponent };
function View_WorkflowTriggerComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "app-workflow-step-card", [], [[2, "workflow-step", null], [2, "workflow-step_active", null], [2, "workflow-step_contrast", null], [2, "workflow-step_contrast2", null], [2, "workflow-step_disabled", null], [2, "workflow-step_interactive", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.customize() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_WorkflowStepCardComponent_0, i1.RenderType_WorkflowStepCardComponent)), i0.ɵdid(1, 114688, null, 0, i2.WorkflowStepCardComponent, [], { title: [0, "title"], subtitle: [1, "subtitle"], icon: [2, "icon"], active: [3, "active"], contrast2: [4, "contrast2"], interactive: [5, "interactive"], connectorTop: [6, "connectorTop"] }, null), i0.ɵppd(2, 1), i0.ɵppd(3, 1), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_6 = (_co.trigger ? i0.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent, 0), _co.trigger.getTypeStr())) : "Choose trigger"); var currVal_7 = (_co.trigger ? i0.ɵunv(_v, 1, 1, _ck(_v, 3, 0, i0.ɵnov(_v.parent, 0), _co.triggerDescription)) : "not specified"); var currVal_8 = ((_co.trigger == null) ? null : _co.trigger.getTypeIcon()); var currVal_9 = i0.ɵunv(_v, 1, 3, i0.ɵnov(_v, 4).transform(_co.customizing$)); var currVal_10 = true; var currVal_11 = true; var currVal_12 = false; _ck(_v, 1, 0, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).cls; var currVal_1 = i0.ɵnov(_v, 1).activeClass; var currVal_2 = i0.ɵnov(_v, 1).contrastClass; var currVal_3 = i0.ɵnov(_v, 1).contrast2Class; var currVal_4 = i0.ɵnov(_v, 1).disabledClass; var currVal_5 = i0.ɵnov(_v, 1).interactiveClass; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }); }
function View_WorkflowTriggerComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "app-workflow-step-card", [], [[2, "workflow-step", null], [2, "workflow-step_active", null], [2, "workflow-step_contrast", null], [2, "workflow-step_contrast2", null], [2, "workflow-step_disabled", null], [2, "workflow-step_interactive", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.customize() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_WorkflowStepCardComponent_0, i1.RenderType_WorkflowStepCardComponent)), i0.ɵdid(1, 114688, null, 0, i2.WorkflowStepCardComponent, [], { title: [0, "title"], subtitle: [1, "subtitle"], icon: [2, "icon"], active: [3, "active"], contrast2: [4, "contrast2"], interactive: [5, "interactive"], connectorTop: [6, "connectorTop"] }, null), i0.ɵppd(2, 1), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_6 = (_co.label || "Trigger"); var currVal_7 = i0.ɵunv(_v, 1, 1, _ck(_v, 2, 0, i0.ɵnov(_v.parent, 0), _co.triggerDescription)); var currVal_8 = (_co.icon || "cursor"); var currVal_9 = i0.ɵunv(_v, 1, 3, i0.ɵnov(_v, 3).transform(_co.customizing$)); var currVal_10 = true; var currVal_11 = true; var currVal_12 = false; _ck(_v, 1, 0, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).cls; var currVal_1 = i0.ɵnov(_v, 1).activeClass; var currVal_2 = i0.ɵnov(_v, 1).contrastClass; var currVal_3 = i0.ɵnov(_v, 1).contrast2Class; var currVal_4 = i0.ɵnov(_v, 1).disabledClass; var currVal_5 = i0.ɵnov(_v, 1).interactiveClass; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }); }
export function View_WorkflowTriggerComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i4.CapitalizePipe, []), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_WorkflowTriggerComponent_1)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_WorkflowTriggerComponent_2)), i0.ɵdid(4, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 2, "div", [["class", "workflow-connection"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "div", [["class", "workflow-connection__bounds"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 0, "div", [["class", "workflow-connection__dash"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.triggerEditable; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.triggerEditable; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_WorkflowTriggerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-workflow-trigger", [], null, null, null, View_WorkflowTriggerComponent_0, RenderType_WorkflowTriggerComponent)), i0.ɵprd(131584, null, i5.ViewContextElement, i5.ViewContextElement, [i6.ViewContext]), i0.ɵdid(2, 770048, null, 0, i7.WorkflowTriggerComponent, [i8.CustomizeBarContext, i9.CurrentProjectStore, i10.CurrentEnvironmentStore, i11.AutomationService, i12.ModelDescriptionStore, i13.WorkflowEditContext, i14.ActionService, i5.ViewContextElement, i15.ApiService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var WorkflowTriggerComponentNgFactory = i0.ɵccf("app-workflow-trigger", i7.WorkflowTriggerComponent, View_WorkflowTriggerComponent_Host_0, { triggerEditable: "triggerEditable", trigger: "trigger", triggerOutputs: "triggerOutputs", triggerData: "triggerData", workflow: "workflow", workflowEditable: "workflowEditable", parametersEnabled: "parametersEnabled", parameters: "parameters", automation: "automation", label: "label", icon: "icon", customizeInitial: "customizeInitial" }, { triggerChanged: "triggerChanged" }, []);
export { WorkflowTriggerComponentNgFactory as WorkflowTriggerComponentNgFactory };
