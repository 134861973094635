<form (ngSubmit)="continueSetupOrSubmit()">
  <app-popup2 [size]="'m'">
    <ng-container *ngIf="domain">
      <app-popup2-header>
        <app-popup2-title>Connected domain</app-popup2-title>
        <app-popup2-description>
          This is the exact domain name people see when they visit current App. It should be a subdomain
          (app.yourdomain.com)
        </app-popup2-description>
      </app-popup2-header>
    </ng-container>

    <ng-container *ngIf="!domain">
      <app-popup2-header>
        <app-popup2-title>Connect a domain</app-popup2-title>
        <app-popup2-description>
          Enter the exact domain name you want people to see when they visit current App. It should be a subdomain
          (app.yourdomain.com)
        </app-popup2-description>
      </app-popup2-header>
    </ng-container>

    <app-page-block>
      <div *ngIf="domain" class="field">
        <label class="field__label field__label_bright">Domain</label>
        <input class="input input_fill" type="text" [value]="domain.domain" disabled />
      </div>

      <div *ngIf="!domain" class="field">
        <label class="field__label field__label_bright">Domain</label>
        <div style="display: flex; align-items: center;">
          <input
            class="input input_fill"
            style="flex-grow: 1; flex-basis: 0;"
            type="text"
            [formControl]="form.controls.domain"
            placeholder="e.g app.yourdomain.com (without https://)"
          />

          <button
            type="button"
            class="button button_primary button_shadow"
            style="margin: 0 0 0 8px;"
            [disabled]="form.controls.domain.invalid || form.controls.domain_entered.value"
            (click)="continueSetup()"
          >
            Continue
          </button>
        </div>
        <app-field-errors [form]="form" [fieldName]="'domain'"></app-field-errors>
      </div>
    </app-page-block>

    <app-page-block *ngIf="form.controls.domain_entered.value">
      <app-alert [title]="'Add CNAME record'" [warning]="true">
        Add the following configuration record in your domain DNS settings:

        <ul *ngIf="subdomain | appIsSet">
          <li>Type: <strong>CNAME</strong></li>
          <li>
            Name (Host/Domain):
            <strong *ngIf="subdomain | appIsSet">{{ subdomain }}</strong>
            <strong *ngIf="!(subdomain | appIsSet)">SUBDOMAIN</strong>
          </li>
          <li>Destination (Target/Value): <strong>app.jetadmin.io</strong></li>
        </ul>
      </app-alert>
    </app-page-block>

    <app-page-block>
      <app-form-errors [form]="form" [manualMargin]="true"></app-form-errors>
    </app-page-block>

    <app-popup2-footer>
      <app-page-block>
        <app-stack [align]="'right'">
          <a href="javascript:void(0)" class="button popup2__footer-item" (click)="close()">Cancel</a>

          <a
            *ngIf="domain"
            href="javascript:void(0)"
            class="button button_danger popup2__footer-item"
            [class.button_disabled]="loading"
            (click)="submit()"
          >
            <app-loader-small *ngIf="loading" class="button__icon button__icon_left"></app-loader-small>
            Disconnect domain
          </a>

          <button
            *ngIf="!domain"
            type="submit"
            class="button popup2__footer-item"
            [class.button_primary]="form.controls.domain.valid && form.controls.domain_entered.value"
            [class.button_disabled]="form.controls.domain.invalid || !form.controls.domain_entered.value || loading"
          >
            <app-loader-small *ngIf="loading" class="button__icon button__icon_left"></app-loader-small>
            <span class="button__label">Add Domain</span>
          </button>
        </app-stack>
      </app-page-block>
    </app-popup2-footer>
  </app-popup2>
</form>
