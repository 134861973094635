var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Input } from '@modules/fields';
import { isSet } from '@shared';
import { BorderSettings } from '../../border-settings';
import { FillSettings } from '../../fill-settings';
import { Shadow } from '../../shadow';
import { TextStyle } from '../../text-style';
import { registerElementForType } from '../element-items';
import { ElementType } from '../element-type';
import { ElementItem } from './base';
var FilterElementInput = /** @class */ (function () {
    function FilterElementInput() {
        this.margin = {};
    }
    FilterElementInput.prototype.deserialize = function (data) {
        this.name = data['name'];
        this.tooltip = data['tooltip'];
        this.weight = data['weight'];
        if (data['settings']) {
            this.settings = data['settings'];
            this.settings.valueInput = data['settings']['valueInput']
                ? new Input().deserialize(data['settings']['valueInput'])
                : undefined;
        }
        else {
            this.settings = undefined;
        }
        if (isSet(data['label_additional'])) {
            this.labelAdditional = data['label_additional'];
        }
        if (data['margin']) {
            this.margin = data['margin'];
        }
        if (data['text_style']) {
            this.textStyle = new TextStyle().deserialize(data['text_style']);
        }
        else {
            this.textStyle = undefined;
        }
        if (data['placeholder_style']) {
            this.placeholderStyle = new TextStyle().deserialize(data['placeholder_style']);
        }
        else {
            this.placeholderStyle = undefined;
        }
        if (data['label_style']) {
            this.labelStyle = new TextStyle().deserialize(data['label_style']);
        }
        else {
            this.labelStyle = undefined;
        }
        if (data['label_additional_style']) {
            this.labelAdditionalStyle = new TextStyle().deserialize(data['label_additional_style']);
        }
        else {
            this.labelAdditionalStyle = undefined;
        }
        if (data['fill_settings']) {
            this.fillSettings = new FillSettings().deserialize(data['fill_settings']);
        }
        else {
            this.fillSettings = undefined;
        }
        if (data['border_settings']) {
            this.borderSettings = new BorderSettings().deserialize(data['border_settings']);
        }
        else {
            this.borderSettings = undefined;
        }
        if (data['border_radius']) {
            this.borderRadius = data['border_radius'];
        }
        else {
            this.borderRadius = undefined;
        }
        if (data['shadow']) {
            this.shadow = new Shadow().deserialize(data['shadow']);
        }
        else {
            this.shadow = undefined;
        }
        if (data['padding']) {
            this.padding = data['padding'];
        }
        else {
            this.padding = undefined;
        }
        if (data['hover_text_style']) {
            this.hoverTextStyle = new TextStyle().deserialize(data['hover_text_style']);
        }
        else {
            this.hoverTextStyle = undefined;
        }
        if (data['hover_placeholder_style']) {
            this.hoverPlaceholderStyle = new TextStyle().deserialize(data['hover_placeholder_style']);
        }
        else {
            this.hoverPlaceholderStyle = undefined;
        }
        if (data['hover_fill_settings']) {
            this.hoverFillSettings = new FillSettings().deserialize(data['hover_fill_settings']);
        }
        else {
            this.hoverFillSettings = undefined;
        }
        if (data['hover_border_settings']) {
            this.hoverBorderSettings = new BorderSettings().deserialize(data['hover_border_settings']);
        }
        else {
            this.hoverBorderSettings = undefined;
        }
        if (data['hover_shadow']) {
            this.hoverShadow = new Shadow().deserialize(data['hover_shadow']);
        }
        else {
            this.hoverShadow = undefined;
        }
        if (data['focus_text_style']) {
            this.focusTextStyle = new TextStyle().deserialize(data['focus_text_style']);
        }
        else {
            this.focusTextStyle = undefined;
        }
        if (data['focus_placeholder_style']) {
            this.focusPlaceholderStyle = new TextStyle().deserialize(data['focus_placeholder_style']);
        }
        else {
            this.focusPlaceholderStyle = undefined;
        }
        if (data['focus_fill_settings']) {
            this.focusFillSettings = new FillSettings().deserialize(data['focus_fill_settings']);
        }
        else {
            this.focusFillSettings = undefined;
        }
        if (data['focus_border_settings']) {
            this.focusBorderSettings = new BorderSettings().deserialize(data['focus_border_settings']);
        }
        else {
            this.focusBorderSettings = undefined;
        }
        if (data['focus_shadow']) {
            this.focusShadow = new Shadow().deserialize(data['focus_shadow']);
        }
        else {
            this.focusShadow = undefined;
        }
        if (data['error_text_style']) {
            this.errorTextStyle = new TextStyle().deserialize(data['error_text_style']);
        }
        else {
            this.errorTextStyle = undefined;
        }
        if (data['error_placeholder_style']) {
            this.errorPlaceholderStyle = new TextStyle().deserialize(data['error_placeholder_style']);
        }
        else {
            this.errorPlaceholderStyle = undefined;
        }
        if (data['error_fill_settings']) {
            this.errorFillSettings = new FillSettings().deserialize(data['error_fill_settings']);
        }
        else {
            this.errorFillSettings = undefined;
        }
        if (data['error_border_settings']) {
            this.errorBorderSettings = new BorderSettings().deserialize(data['error_border_settings']);
        }
        else {
            this.errorBorderSettings = undefined;
        }
        if (data['error_shadow']) {
            this.errorShadow = new Shadow().deserialize(data['error_shadow']);
        }
        else {
            this.errorShadow = undefined;
        }
        return this;
    };
    FilterElementInput.prototype.serialize = function () {
        return {
            name: this.name,
            settings: this.settings
                ? __assign({}, this.settings, (this.settings.valueInput && { valueInput: this.settings.valueInput.serialize() })) : undefined,
            label_additional: this.labelAdditional,
            tooltip: this.tooltip,
            weight: this.weight,
            margin: this.margin ? this.margin : {},
            text_style: this.textStyle ? this.textStyle.serialize() : undefined,
            placeholder_style: this.placeholderStyle ? this.placeholderStyle.serialize() : undefined,
            label_style: this.labelStyle ? this.labelStyle.serialize() : undefined,
            label_additional_style: this.labelAdditionalStyle ? this.labelAdditionalStyle.serialize() : undefined,
            fill_settings: this.fillSettings ? this.fillSettings.serialize() : undefined,
            border_settings: this.borderSettings ? this.borderSettings.serialize() : undefined,
            border_radius: this.borderRadius,
            shadow: this.shadow ? this.shadow.serialize() : undefined,
            padding: this.padding,
            hover_text_style: this.hoverTextStyle ? this.hoverTextStyle.serialize() : undefined,
            hover_placeholder_style: this.hoverPlaceholderStyle ? this.hoverPlaceholderStyle.serialize() : undefined,
            hover_fill_settings: this.hoverFillSettings ? this.hoverFillSettings.serialize() : undefined,
            hover_border_settings: this.hoverBorderSettings ? this.hoverBorderSettings.serialize() : undefined,
            hover_shadow: this.hoverShadow ? this.hoverShadow.serialize() : undefined,
            focus_text_style: this.focusTextStyle ? this.focusTextStyle.serialize() : undefined,
            focus_placeholder_style: this.focusPlaceholderStyle ? this.focusPlaceholderStyle.serialize() : undefined,
            focus_fill_settings: this.focusFillSettings ? this.focusFillSettings.serialize() : undefined,
            focus_border_settings: this.focusBorderSettings ? this.focusBorderSettings.serialize() : undefined,
            focus_shadow: this.focusShadow ? this.focusShadow.serialize() : undefined,
            error_text_style: this.errorTextStyle ? this.errorTextStyle.serialize() : undefined,
            error_placeholder_style: this.errorPlaceholderStyle ? this.errorPlaceholderStyle.serialize() : undefined,
            error_fill_settings: this.errorFillSettings ? this.errorFillSettings.serialize() : undefined,
            error_border_settings: this.errorBorderSettings ? this.errorBorderSettings.serialize() : undefined,
            error_shadow: this.errorShadow ? this.errorShadow.serialize() : undefined
        };
    };
    return FilterElementInput;
}());
export { FilterElementInput };
export var FilterStyle;
(function (FilterStyle) {
    FilterStyle["Wrap"] = "wrap";
    FilterStyle["Background"] = "background";
})(FilterStyle || (FilterStyle = {}));
var FilterElementItem = /** @class */ (function (_super) {
    __extends(FilterElementItem, _super);
    function FilterElementItem() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.type = ElementType.Filter;
        _this.elements = [];
        _this.elementInputs = [];
        _this.style = FilterStyle.Background;
        return _this;
    }
    FilterElementItem.prototype.deserialize = function (data) {
        _super.prototype.deserialize.call(this, data);
        if (this.params['elements']) {
            this.elements = this.params['elements'];
        }
        if (this.params['element_inputs']) {
            this.elementInputs = this.params['element_inputs'].map(function (item) { return new FilterElementInput().deserialize(item); });
        }
        if (this.params['style']) {
            this.style = this.params['style'];
        }
        return this;
    };
    FilterElementItem.prototype.serialize = function () {
        this.params = {
            elements: this.elements,
            element_inputs: this.elementInputs.map(function (item) { return item.serialize(); }),
            style: this.style
        };
        return _super.prototype.serialize.call(this);
    };
    Object.defineProperty(FilterElementItem.prototype, "analyticsName", {
        get: function () {
            return 'filter';
        },
        enumerable: true,
        configurable: true
    });
    FilterElementItem.prototype.defaultName = function () {
        return 'Filter';
    };
    return FilterElementItem;
}(ElementItem));
export { FilterElementItem };
registerElementForType(ElementType.Filter, FilterElementItem);
