/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../previews/image-preview/image-preview.component.ngfactory";
import * as i2 from "../../previews/image-preview/image-preview.component";
import * as i3 from "../../../../../common/popups/services/popup/popup.service";
import * as i4 from "@angular/platform-browser";
import * as i5 from "../../../../project-api/services/project-api/project-api.service";
import * as i6 from "../../../../../common/tips/directives/tip/tip.directive";
import * as i7 from "../upload-template/upload-template.component.ngfactory";
import * as i8 from "../upload-template/upload-template.component";
import * as i9 from "@angular/common";
import * as i10 from "./image-upload.component";
import * as i11 from "../../../../projects/stores/current-project.store";
import * as i12 from "../../../../projects/stores/current-environment.store";
import * as i13 from "../../../../resources/services/resource-controller/resource-controller.service";
import * as i14 from "../../../../storages-queries/services/storage/storage.service";
var styles_ImageUploadComponent = [];
var RenderType_ImageUploadComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ImageUploadComponent, data: {} });
export { RenderType_ImageUploadComponent as RenderType_ImageUploadComponent };
function View_ImageUploadComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-image-preview", [["class", "upload__background"]], null, null, null, i1.View_ImagePreviewComponent_0, i1.RenderType_ImagePreviewComponent)), i0.ɵdid(2, 4964352, [[1, 4]], 0, i2.ImagePreviewComponent, [i3.PopupService, i4.DomSanitizer, i5.ProjectApiService, i0.ChangeDetectorRef, i0.Injector], { value: [0, "value"], url: [1, "url"], background: [2, "background"], fit: [3, "fit"], resize: [4, "resize"], compact: [5, "compact"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.control.value; var currVal_1 = ((_co.uploadedFile == null) ? null : _co.uploadedFile.url); var currVal_2 = _co.background; var currVal_3 = _co.fit; var currVal_4 = _co.resize; var currVal_5 = _co.compact; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
function View_ImageUploadComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "a", [["class", "upload__button upload__button_type_zoom-in"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        $event.stopPropagation();
        var pd_0 = (_co.imagePreviewComponent.openLightbox($event, true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 737280, null, 0, i6.TipDirective, [i0.ElementRef, i0.ComponentFactoryResolver, i0.Injector, i0.ApplicationRef], { content: [0, "content"], optionsRaw: [1, "optionsRaw"] }, null), i0.ɵpod(3, { side: 0, margin: 1 })], function (_ck, _v) { var currVal_0 = "Open"; var currVal_1 = _ck(_v, 3, 0, "top", (0 - 4)); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_ImageUploadComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(671088640, 1, { imagePreviewComponent: 0 }), (_l()(), i0.ɵeld(1, 0, null, null, 5, "app-upload-template", [], null, [[null, "clearValue"], [null, "fileSelected"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("clearValue" === en)) {
        var pd_0 = (_co.clearCurrentValue() !== false);
        ad = (pd_0 && ad);
    } if (("fileSelected" === en)) {
        var pd_1 = (_co.onFileSelected($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i7.View_UploadTemplateComponent_0, i7.RenderType_UploadTemplateComponent)), i0.ɵdid(2, 245760, null, 0, i8.UploadTemplateComponent, [i0.ChangeDetectorRef], { upload: [0, "upload"], accept: [1, "accept"], name: [2, "name"], control: [3, "control"], autofocus: [4, "autofocus"], disabled: [5, "disabled"], background: [6, "background"], fit: [7, "fit"], cropFormat: [8, "cropFormat"], cropFormatCustom: [9, "cropFormatCustom"], preview: [10, "preview"], uploadProgress: [11, "uploadProgress"], uploadError: [12, "uploadError"], accentColor: [13, "accentColor"], theme: [14, "theme"] }, { clearValue: "clearValue", fileSelected: "fileSelected" }), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_ImageUploadComponent_1)), i0.ɵdid(4, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, 1, 1, null, View_ImageUploadComponent_2)), i0.ɵdid(6, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isUploadable(); var currVal_1 = "image/*"; var currVal_2 = _co.name; var currVal_3 = _co.control; var currVal_4 = _co.autofocus; var currVal_5 = !_co.storage$.value; var currVal_6 = _co.background; var currVal_7 = _co.fit; var currVal_8 = _co.cropFormat; var currVal_9 = _co.cropFormatCustom; var currVal_10 = ((_co.uploadedFile == null) ? null : _co.uploadedFile.url); var currVal_11 = _co.uploadProgress; var currVal_12 = _co.uploadError; var currVal_13 = _co.accentColor; var currVal_14 = _co.theme; _ck(_v, 2, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14]); var currVal_15 = _co.uploadedFile; _ck(_v, 4, 0, currVal_15); var currVal_16 = _co.imagePreviewComponent; _ck(_v, 6, 0, currVal_16); }, null); }
export function View_ImageUploadComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-image-upload", [], null, null, null, View_ImageUploadComponent_0, RenderType_ImageUploadComponent)), i0.ɵdid(1, 770048, null, 0, i10.ImageUploadComponent, [i3.PopupService, i0.Injector, i11.CurrentProjectStore, i12.CurrentEnvironmentStore, i13.ResourceControllerService, i14.StorageService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ImageUploadComponentNgFactory = i0.ɵccf("app-image-upload", i10.ImageUploadComponent, View_ImageUploadComponent_Host_0, { name: "name", control: "control", outputFormat: "outputFormat", storageResourceName: "storageResourceName", storageName: "storageName", path: "path", autofocus: "autofocus", disabled: "disabled", background: "background", fit: "fit", resize: "resize", editor: "editor", cropFormat: "cropFormat", cropFormatCustom: "cropFormatCustom", context: "context", contextElement: "contextElement", compact: "compact", accentColor: "accentColor", theme: "theme" }, { uploadedUpdated: "uploadedUpdated" }, []);
export { ImageUploadComponentNgFactory as ImageUploadComponentNgFactory };
