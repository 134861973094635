<div class="collapse-container collapse-container_theme">
  <app-collapse-section
    *ngFor="let item of items; let first = first; let last = last; trackBy: trackItem"
    class="collapse-container__item"
    [titleInput]="item.item.titleInput"
    [titleStyle]="item.item.titleStyle"
    [icon]="item.item.icon"
    [children]="item.item.children"
    [tooltip]="item.item.tooltip"
    [collapseIndicatorPosition]="element.collapseIndicatorPosition"
    [element]="element"
    [opened]="openedItems.includes(item.item.uid)"
    [visible]="item.visible"
    [first]="first"
    [last]="last"
    [theme]="true"
    [context]="context"
    (toggleOpened)="toggleOpenedItem(item.item.uid)"
  ></app-collapse-section>
</div>
