<div class="sidebar__element">
  <div class="sidebar-cols">
    <div class="sidebar-cols__item">
      <div class="input-group input-icon-wrapper">
        <div
          class="input-group__item input-group__item_first input input_small input_disabled"
          [class.input_theme]="theme"
        >
          HEX
        </div>

        <input
          type="text"
          class="input-group__item input-group__item_last input-group__fill input input_small input_icon-left"
          [class.input_theme]="theme"
          [formControl]="hexControlInternal"
          [appAutofocus]="true"
          placeholder="#FF0000"
          maxlength="9"
        />

        <span class="input-icon input-icon_small input-icon_left" [style.left.px]="45">
          <span
            class="input-icon-color input-icon-color_small"
            [ngClass]="
              !(hexControlInternal.value | appIsColorHex) ? 'background-color_' + hexControlInternal.value + '_1' : ''
            "
            [style.background-color]="(hexControlInternal.value | appIsColorHex) ? hexControlInternal.value : null"
          ></span>
        </span>
      </div>
    </div>

    <div class="sidebar-cols__item sidebar-cols__item_fit">
      <div
        class="button button_small button_icon button_no-margin"
        [class.button_theme]="theme"
        [appButtonTintColor]="accentColor"
        [appButtonTintColorStyle]="tintStyles.Default"
        (click)="pickColor()"
      >
        <div class="button__icon icon-dropper"></div>
      </div>
    </div>
  </div>
</div>

<div class="sidebar__element sidebar__element_margin_s">
  <app-sidebar-field [label]="'hue'">
    <app-slider
      [baseControl]="hueControl"
      [min]="0"
      [max]="360"
      [debounce]="0"
      [valueLabel]="false"
      [labels]="false"
      [trackBackground]="hueBackground"
      [fillBackground]="'transparent'"
      [thumbBorderColor]="hueThumbBorder"
      [thumbInside]="true"
      (baseChange)="setHue($event)"
    ></app-slider>

    <ng-container actions-right>
      <input
        type="text"
        class="input input_tiny input_center"
        [class.input_theme]="theme"
        [formControl]="hueControl"
        [style.width.px]="38"
        [style.margin-top.px]="-6"
        [style.margin-bottom.px]="-6"
        [appInputFormat]="{ type: numberFieldTypes.Integer, min: 0, max: 360 }"
        (keydown)="onControlKey($event, hueControl, hue_input)"
        (input)="updateCurrentValue()"
        #hue_input
      />
    </ng-container>
  </app-sidebar-field>
</div>

<div class="sidebar__element sidebar__element_margin_s">
  <app-sidebar-field [label]="'saturation'">
    <app-slider
      [baseControl]="saturationControl"
      [min]="0"
      [max]="100"
      [debounce]="0"
      [valueLabel]="false"
      [labels]="false"
      [trackBackground]="saturationBackground"
      [fillBackground]="'transparent'"
      [thumbBorderColor]="hueThumbBorder"
      [thumbInside]="true"
      (baseChange)="setSaturation($event)"
      #input
    ></app-slider>

    <ng-container actions-right>
      <input
        type="text"
        class="input input_tiny input_center"
        [class.input_theme]="theme"
        [formControl]="saturationControl"
        [style.width.px]="38"
        [style.margin-top.px]="-6"
        [style.margin-bottom.px]="-6"
        [appInputFormat]="{ type: numberFieldTypes.Integer, min: 0, max: 100 }"
        (keydown)="onControlKey($event, saturationControl, saturation_input)"
        (input)="updateCurrentValue()"
        #saturation_input
      />
    </ng-container>
  </app-sidebar-field>
</div>

<div class="sidebar__element sidebar__element_margin_s">
  <app-sidebar-field [label]="'luminosity'">
    <app-slider
      [baseControl]="luminosityControl"
      [min]="0"
      [max]="100"
      [debounce]="0"
      [valueLabel]="false"
      [labels]="false"
      [trackBackground]="luminosityBackground"
      [fillBackground]="'transparent'"
      [thumbBorderColor]="hueThumbBorder"
      [thumbInside]="true"
      (baseChange)="setLuminosity($event)"
    ></app-slider>

    <ng-container actions-right>
      <input
        type="text"
        class="input input_tiny input_center"
        [class.input_theme]="theme"
        [formControl]="luminosityControl"
        [style.width.px]="38"
        [style.margin-top.px]="-6"
        [style.margin-bottom.px]="-6"
        [appInputFormat]="{ type: numberFieldTypes.Integer, min: 0, max: 100 }"
        (keydown)="onControlKey($event, luminosityControl, luminosity_input)"
        (input)="updateCurrentValue()"
        #luminosity_input
      />
    </ng-container>
  </app-sidebar-field>
</div>

<div *ngIf="alphaEnabled" class="sidebar__element sidebar__element_margin_s">
  <app-sidebar-field [label]="'opacity'">
    <app-slider
      [baseControl]="alphaControl"
      [min]="0"
      [max]="100"
      [debounce]="0"
      [valueLabel]="false"
      [labels]="false"
      [trackBackground]="alphaBackground"
      [fillBackground]="'transparent'"
      [thumbBorderColor]="hueThumbBorder"
      [thumbInside]="true"
      (baseChange)="setAlpha($event)"
    ></app-slider>

    <ng-container actions-right>
      <input
        type="text"
        class="input input_tiny input_center"
        [class.input_theme]="theme"
        [formControl]="alphaControl"
        [style.width.px]="38"
        [style.margin-top.px]="-6"
        [style.margin-bottom.px]="-6"
        [appInputFormat]="{ type: numberFieldTypes.Integer, min: 0, max: 100 }"
        (keydown)="onControlKey($event, alphaControl, alpha_input)"
        (input)="updateCurrentValue()"
        #alpha_input
      />
    </ng-container>
  </app-sidebar-field>
</div>
