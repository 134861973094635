import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SkipSelf
} from '@angular/core';
import round from 'lodash/round';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fromEvent } from 'rxjs';
import { filter } from 'rxjs/operators';

import { controlValue, isSet, MouseButton, pointsDistance } from '@shared';

import { GradientStopControl } from '../../forms/gradient-stop.control';

@Component({
  selector: 'app-gradient-position-point',
  templateUrl: './gradient-position-point.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GradientPositionPointComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() positionPx: number;
  @Input() positionEm: number;
  @Input() positionPercent: number;
  @Input() active = false;
  @Input() control: GradientStopControl;
  @Input() origin: CdkOverlayOrigin;
  @Input() snap = false;
  @Output() positionChange = new EventEmitter<{ x: number; y: number }>();

  @HostBinding('class.view-editor-direction__point') cls = true;
  @HostBinding('class.view-editor-direction__point_active') get activeCls() {
    return this.active;
  }
  @HostBinding('class.view-editor-direction__point_color') get colorCls() {
    return !!this.control;
  }
  @HostBinding('style.background-color') backgroundColor: string;
  @HostBinding('style.left') get left() {
    if (isSet(this.positionPx)) {
      return `${this.positionPx}px`;
    } else if (isSet(this.positionEm)) {
      return `${this.positionEm}em`;
    } else if (isSet(this.positionPercent)) {
      return `${this.positionPercent * 100}%`;
    } else {
      return null;
    }
  }

  moveThreshold = 5;

  constructor(private el: ElementRef, @SkipSelf() private parentCd: ChangeDetectorRef) {}

  ngOnInit() {
    if (this.control) {
      controlValue<string>(this.control.controls.color)
        .pipe(untilDestroyed(this))
        .subscribe(background => {
          this.backgroundColor = background;
          this.parentCd.markForCheck();
        });
    }
  }

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    fromEvent<MouseEvent>(this.el.nativeElement, 'mousedown')
      .pipe(
        filter(e => e.button == MouseButton.Main),
        untilDestroyed(this)
      )
      .subscribe(downEvent => {
        downEvent.preventDefault();

        const subscriptions = [];
        let thresholdPassed = false;

        subscriptions.push(
          fromEvent<MouseEvent>(document, 'mousemove')
            .pipe(untilDestroyed(this))
            .subscribe(moveEvent => {
              moveEvent.preventDefault();

              if (!thresholdPassed) {
                const originDistance = pointsDistance(
                  downEvent.clientX,
                  downEvent.clientY,
                  moveEvent.clientX,
                  moveEvent.clientY
                );
                if (originDistance >= this.moveThreshold) {
                  thresholdPassed = true;

                  if (this.snap) {
                    // this.editorContext.removeHorizontalGuides();
                    // this.editorContext.removeVerticalGuides();
                  }
                }
              }

              if (thresholdPassed) {
                const bounds = this.origin.elementRef.nativeElement.getBoundingClientRect();
                const positionX = round((moveEvent.clientX - bounds.left) / bounds.width, 2);
                const positionY = round((moveEvent.clientY - bounds.top) / bounds.height, 2);

                // if (this.snap) {
                //   // const scale = this.editorContext.viewportScale$.value;
                //   const scale = 1;
                //   const deltaX = (moveEvent.clientX - bounds.left) / scale;
                //   const deltaY = (moveEvent.clientY - bounds.top) / scale;
                //   const x = this.frame.x + deltaX;
                //   const y = this.frame.y + deltaY;
                //   const snap = snapPoint({
                //     point: new PointTranslate({
                //       point: { x, y },
                //       translate: this.translate
                //     }),
                //     otherFrames: [
                //       new FrameTranslate({
                //         frame: this.frame,
                //         translate: this.translate
                //       })
                //     ]
                //   });
                //
                //   if (isSet(snap.updatePoint.x)) {
                //     positionX = round((snap.updatePoint.x - this.frame.x) / this.frame.width, 2);
                //   }
                //
                //   if (isSet(snap.updatePoint.y)) {
                //     positionY = round((snap.updatePoint.y - this.frame.y) / this.frame.height, 2);
                //   }
                //
                //   this.editorContext.setHorizontalGuides(snap.horizontalGuides);
                //   this.editorContext.setVerticalGuides(snap.verticalGuides);
                // }

                this.positionChange.emit({ x: positionX, y: positionY });
              }
            })
        );

        subscriptions.push(
          fromEvent<MouseEvent>(document, 'mouseup')
            .pipe(
              filter(e => e.button == MouseButton.Main),
              untilDestroyed(this)
            )
            .subscribe(upEvent => {
              upEvent.preventDefault();
              subscriptions.forEach(item => item.unsubscribe());

              if (this.snap) {
                // this.editorContext.removeHorizontalGuides();
                // this.editorContext.removeVerticalGuides();
              }
            })
        );
      });
  }
}
