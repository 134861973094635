import { Injectable, OnDestroy } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { elementOutputMeta } from '@modules/fields';
import { GetQueryOptions } from '@modules/resources';

import { ElementItem } from './elements/items/base';
import { PopupSettings } from './popup';
import { ViewContext } from './view-context';
import { ViewContextAction } from './view-context-action';
import { ViewContextElementType } from './view-context-element-type';
import { ViewContextEmitter } from './view-context-emitter';
import { ViewContextOutput } from './view-context-output';
import { VIEW_CONTEXT_OUTPUTS_KEY } from './view-context-property-output';

interface PropertyOutput {
  uniqueName: string;
  name: string;
  emitter: ViewContextEmitter<any>;
}

interface ElementInfo {
  uniqueName?: string;
  name?: string;
  icon?: string;
  allowSkip?: boolean;
  insert?: boolean;
  type?: ViewContextElementType;
  fieldFilters?: boolean;
  getFieldValue?: (field: string, value: Object) => any;
  getQueryOptions?: () => GetQueryOptions;
  element?: ElementItem;
  popup?: PopupSettings;
  documentation?: string;
  action?: {
    label: string;
    icon?: string;
    handler: () => Observable<{ insertToken?: string[] }>;
  };
}

export interface ElementOutputMeta {
  error?: boolean;
  loading?: boolean;
}

@Injectable()
export class ViewContextElement implements OnDestroy {
  uniqueName: string;
  name: string;
  icon: string;
  allowSkip = false;
  insert = false;
  type: ViewContextElementType;
  fieldFilters = false;
  getFieldValueFn: (field: string, outputs: Object) => any;
  getQueryOptionsFn: () => GetQueryOptions;
  element: ElementItem;
  popup: PopupSettings;
  documentation: string;
  action: {
    label: string;
    icon?: string;
    handler: () => Observable<{ insertToken?: string[] }>;
  };

  private _outputs = new BehaviorSubject<ViewContextOutput[]>([]);
  private _actions = new BehaviorSubject<ViewContextAction[]>([]);
  private _outputsValue = new BehaviorSubject<Object>({});
  private propertyOutputsSubscription: Subscription;

  constructor(public context: ViewContext) {}

  ngOnDestroy(): void {
    this.unregister();
  }

  get actions(): ViewContextAction[] {
    return this._actions.value;
  }

  get actions$(): Observable<ViewContextAction[]> {
    return this._actions.asObservable();
  }

  get outputs(): ViewContextOutput[] {
    return this._outputs.value;
  }

  get outputs$(): Observable<ViewContextOutput[]> {
    return this._outputs.asObservable();
  }

  get outputsValue(): Object {
    return this._outputsValue.value;
  }

  get outputsValue$(): Observable<Object> {
    return this._outputsValue.asObservable();
  }

  initInfo(info: ElementInfo, markHasChanges = false) {
    this.uniqueName = info.uniqueName || this.uniqueName;
    this.name = info.name || this.name;
    this.icon = info.icon || this.icon;
    this.type = info.type || this.type;
    this.allowSkip = info.allowSkip || this.allowSkip;
    this.insert = info.insert || this.insert;
    this.fieldFilters = info.fieldFilters || this.fieldFilters;
    this.getFieldValueFn = info.getFieldValue || this.getFieldValueFn;
    this.getQueryOptionsFn = info.getQueryOptions || this.getQueryOptionsFn;
    this.element = info.element || this.element;
    this.popup = info.popup || this.popup;
    this.documentation = info.documentation || this.documentation;
    this.action = info.action || this.action;

    if (markHasChanges && this.context) {
      this.context.markElementsHasChanges();
    }
  }

  initGlobal(info: ElementInfo, parent?: ViewContextElement) {
    this.initInfo({ ...info, type: ViewContextElementType.Global });
    this.register(parent);
  }

  initElement(info: ElementInfo, parent?: ViewContextElement) {
    this.initInfo({ ...info, type: ViewContextElementType.Element });
    this.register(parent);
  }

  isRegistered(): boolean {
    if (this.context) {
      return this.context.isRegisteredElement(this);
    } else {
      return false;
    }
  }

  register(parent?: ViewContextElement) {
    if (this.context) {
      this.context.registerElement(this, parent);
    }
  }

  unregister() {
    if (this.context) {
      this.context.unregisterElement(this);
    }

    this.uniqueName = undefined;
    this.name = undefined;

    if (this.propertyOutputsSubscription) {
      this.propertyOutputsSubscription.unsubscribe();
    }
  }

  setActions(actions: ViewContextAction[]) {
    this._actions.next(actions);
    this.markHasChanges();
  }

  setOutputs(outputs: ViewContextOutput[]) {
    this._outputs.next(outputs);
    this._outputsValue.next({
      ...outputs.reduce((acc, item) => {
        acc[item.uniqueName] = item.defaultValue;
        return acc;
      }, {}),
      ...this._outputsValue.value
    });
    this.markHasChanges();
  }

  applyOutputValue(path: string, handler: (obj: Object, key: string) => void) {
    const outputsValue = cloneDeep(this._outputsValue.value);
    const pathParts = path.split('.');

    pathParts.reduce((prev, current, i) => {
      if (i == pathParts.length - 1) {
        handler(prev, current);
      }

      return prev[current];
    }, outputsValue);

    if (isEqual(outputsValue, this._outputsValue.value)) {
      return;
    }

    this._outputsValue.next(outputsValue);
    this.markHasChanges();
  }

  setOutputValue(path: string, value: any, meta?: ElementOutputMeta) {
    this.applyOutputValue(path, (obj, key) => {
      obj[key] = value;

      const metaKey = elementOutputMeta(key);
      if (meta) {
        obj[metaKey] = meta;
      } else if (!meta && obj.hasOwnProperty(metaKey)) {
        delete obj[metaKey];
      }
    });
  }

  patchOutputValueMeta(path: string, meta: ElementOutputMeta) {
    this.applyOutputValue(path, (obj, key) => {
      obj[elementOutputMeta(key)] = {
        ...obj[elementOutputMeta(key)],
        ...meta
      };
    });
  }

  setOutputValues(values: Object | Object[]) {
    if (isEqual(values, this._outputsValue.value)) {
      return;
    }

    this._outputsValue.next(values);
    this.markHasChanges();
  }

  patchOutputValues(values: Object | Object[]) {
    const newValue = {
      ...this._outputsValue.value,
      ...values
    };

    if (isEqual(newValue, this._outputsValue.value)) {
      return;
    }

    this._outputsValue.next(newValue);
    this.markHasChanges();
  }

  markHasChanges() {
    if (this.context) {
      this.context.markHasChanges();
    }
  }

  getPropertyOutputs(instance: any): PropertyOutput[] {
    const outputs: {
      property: string;
      uniqueName: string;
      name: string;
    }[] =
      instance.__proto__ && instance.__proto__[VIEW_CONTEXT_OUTPUTS_KEY]
        ? instance.__proto__[VIEW_CONTEXT_OUTPUTS_KEY]
        : [];

    return outputs.map(output => {
      const emitter = instance[output.property];

      return {
        uniqueName: output.uniqueName,
        name: output.name,
        emitter: emitter
      };
    });
  }

  getFieldValue(field: string): any {
    if (!this.getFieldValueFn) {
      return;
    }

    return this.getFieldValueFn(field, this.outputsValue);
  }

  getQueryOptions(): GetQueryOptions {
    if (!this.getQueryOptionsFn) {
      return;
    }

    return this.getQueryOptionsFn();
  }

  registerPropertyOutputs(instance: any) {
    const propertyOutputs = this.getPropertyOutputs(instance);
    this._outputs.next([
      ...this.outputs.filter(item => !propertyOutputs.find(i => i.uniqueName == item.uniqueName)),
      ...propertyOutputs.map(item => {
        return {
          uniqueName: item.uniqueName,
          name: item.name
        };
      })
    ]);

    this.propertyOutputsSubscription = combineLatest(
      propertyOutputs.map(item => item.emitter.pipe(map(value => [item.uniqueName, value])))
    ).subscribe(values => {
      values.reduce((prev, [name, value]) => {
        prev[name] = value;
        return prev;
      }, cloneDeep(this._outputsValue.value));
    });
  }

  unregisterPropertyOutputs(instance: any) {
    const propertyOutputs = this.getPropertyOutputs(instance);
    this._outputs.next([...this.outputs.filter(item => !propertyOutputs.find(i => i.uniqueName === item.uniqueName))]);

    if (this.propertyOutputsSubscription) {
      this.propertyOutputsSubscription.unsubscribe();
    }
  }
}
