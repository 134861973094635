<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayPanelClass]="['overlay', 'overlay_horizontal_center']"
  [cdkConnectedOverlayPositions]="popoverPositions"
  [cdkConnectedOverlayOrigin]="origin"
  [cdkConnectedOverlayOpen]="dropdownOpened"
  [cdkConnectedOverlayPush]="true"
  [cdkConnectedOverlayGrowAfterOpen]="true"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayBackdropClass]="'popover2-backdrop'"
  (backdropClick)="setDropdownOpened(false)"
  (detach)="setDropdownOpened(false)"
>
  <div class="popover2 overlay-content overlay-content_animated">
    <div class="popover2__inner popover2__inner_sidebar">
      <div class="popover2__viewport popover2__viewport_scrollable" [style.max-height.vh]="80">
        <div [style.width.px]="300">
          <div class="sidebar__element sidebar__element_margin_s">
            <div class="sidebar-cols">
              <div class="sidebar-cols__item">
                <div class="button-group">
                  <div
                    class="button-group__item"
                    [class.button-group__item_active]="!control.controls.per_sides.value"
                    (click)="control.controls.per_sides.patchValue(false)"
                  >
                    All sides
                  </div>

                  <div
                    class="button-group__item"
                    [class.button-group__item_active]="control.controls.per_sides.value"
                    (click)="control.controls.per_sides.patchValue(true)"
                  >
                    Per side
                  </div>
                </div>
              </div>

              <div class="sidebar-cols__item sidebar-cols__item_fit">
                <div class="button-group">
                  <div
                    class="button-group__item"
                    [class.button-group__item_active]="themeService.isDefaultTheme"
                    (click)="themeService.theme = 'default'"
                  >
                    <span class="button-group__item-icon icon-sun"></span>
                  </div>

                  <div
                    class="button-group__item"
                    [class.button-group__item_active]="themeService.isDarkTheme"
                    (click)="themeService.theme = 'dark'"
                  >
                    <span class="button-group__item-icon icon-moon"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="sidebar__element sidebar__element_margin_s">
            <div class="sidebar-cols">
              <div *ngIf="control.controls.per_sides.value" class="sidebar-cols__item sidebar-cols__item_fit">
                <div class="side-selector">
                  <div
                    class="side-selector__side side-selector__side_position_top"
                    [class.side-selector__side_active]="side == sides.Top"
                    [class.side-selector__side_unset]="!control.controls.border_top.isSet()"
                    (click)="setSide(sides.Top)"
                  >
                    <div class="side-selector__side-inner"></div>
                  </div>

                  <div
                    class="side-selector__side side-selector__side_position_right"
                    [class.side-selector__side_active]="side == sides.Right"
                    [class.side-selector__side_unset]="!control.controls.border_right.isSet()"
                    (click)="setSide(sides.Right)"
                  >
                    <div class="side-selector__side-inner"></div>
                  </div>

                  <div
                    class="side-selector__side side-selector__side_position_bottom"
                    [class.side-selector__side_active]="side == sides.Bottom"
                    [class.side-selector__side_unset]="!control.controls.border_bottom.isSet()"
                    (click)="setSide(sides.Bottom)"
                  >
                    <div class="side-selector__side-inner"></div>
                  </div>

                  <div
                    class="side-selector__side side-selector__side_position_left"
                    [class.side-selector__side_active]="side == sides.Left"
                    [class.side-selector__side_unset]="!control.controls.border_left.isSet()"
                    (click)="setSide(sides.Left)"
                  >
                    <div class="side-selector__side-inner"></div>
                  </div>
                </div>
              </div>

              <ng-container *ngFor="let borderControl of control.borderControls">
                <div *ngIf="borderControl === getBorderControl()" class="sidebar-cols__item">
                  <div
                    class="sidebar__element sidebar__element_margin_xs sidebar__element_padding_none sidebar__element_first"
                  >
                    <app-auto-field
                      *ngIf="themeService.isDefaultTheme"
                      [field]="
                        createField({
                          name: 'color',
                          field: 'ColorField',
                          params: {
                            classes: ['select_fill', 'select_small'],
                            allow_empty: true,
                            empty_color: undefined,
                            custom_colors: true,
                            global_colors: true,
                            alpha_enabled: true
                          }
                        })
                      "
                      [form]="borderControl"
                      [label]="false"
                    ></app-auto-field>

                    <app-auto-field
                      *ngIf="themeService.isDarkTheme"
                      [field]="
                        createField({
                          name: 'color_dark',
                          field: 'ColorField',
                          params: {
                            classes: ['select_fill', 'select_small'],
                            allow_empty: true,
                            empty_color: undefined,
                            custom_colors: true,
                            global_colors: true,
                            alpha_enabled: true
                          }
                        })
                      "
                      [form]="borderControl"
                      [label]="false"
                    ></app-auto-field>
                  </div>

                  <div
                    class="sidebar__element sidebar__element_margin_xs sidebar__element_padding_none sidebar__element_last"
                  >
                    <div class="sidebar-cols sidebar-cols_margin_s">
                      <div class="sidebar-cols__item">
                        <div class="input-group">
                          <input
                            type="text"
                            class="input-group__item input-group__item_first input-group__fill input input_small"
                            [formControl]="borderControl.controls.thickness"
                            [appInputFormat]="{ type: numberFieldTypes.Integer, min: 0, numberKeyboardControl: true }"
                          />

                          <div
                            class="input-group__item input-group__item_last input input_small input_disabled"
                            [style.padding-left.px]="10"
                            [style.padding-right.px]="10"
                          >
                            px
                          </div>
                        </div>
                      </div>

                      <div class="sidebar-cols__item">
                        <app-auto-field
                          [form]="borderControl"
                          [label]="false"
                          [field]="
                            createField({
                              name: 'style',
                              field: 'SelectField',
                              placeholder: undefined,
                              params: {
                                options: borderControl.styleOptions,
                                classes: ['select_fill', 'select_small']
                              }
                            })
                          "
                        ></app-auto-field>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<div
  class="sidebar-list-item sidebar-list-item_compact sidebar-list-item_clickable"
  [class.sidebar-list-item_contrast]="contrast"
  [class.sidebar-list-item_disabled]="disabled || dropdownOpened"
  (click)="setDropdownOpened(true)"
  cdkOverlayOrigin
  #origin="cdkOverlayOrigin"
>
  <div class="sidebar-list-item__left">
    <div
      *ngIf="colors"
      class="sidebar-list-item__color sidebar-list-item__color_size_l sidebar-list-item__color_square sidebar-list-item__color_transparent"
    >
      <div class="sidebar-list-item__color-segments">
        <div class="sidebar-list-item__color-segment" [style.background]="colors[0]"></div>
        <div class="sidebar-list-item__color-segment" [style.background]="colors[1]"></div>
        <div class="sidebar-list-item__color-segment" [style.background]="colors[3]"></div>
        <div class="sidebar-list-item__color-segment" [style.background]="colors[2]"></div>
      </div>
    </div>

    <div
      *ngIf="!colors"
      class="sidebar-list-item__color sidebar-list-item__color_size_l sidebar-list-item__color_square"
      [style.background]="color"
    ></div>
  </div>

  <div class="sidebar-list-item__main">
    <ng-container *ngIf="valueStr | appIsSet">
      <div class="sidebar-list-item__title">{{ valueStr }}</div>
      <div class="sidebar-list-item__description">
        {{ subtitle }}
        <ng-container *ngIf="additional | appIsSet"> {{ additional }}</ng-container>
      </div>
    </ng-container>

    <ng-container *ngIf="!(valueStr | appIsSet)">
      <div class="sidebar-list-item__title sidebar-list-item__title_secondary">
        {{ subtitle }}
        <ng-container *ngIf="additional | appIsSet"> {{ additional }}</ng-container>
      </div>
    </ng-container>
  </div>

  <div *ngIf="removeEnabled" class="sidebar-list-item__right">
    <div
      class="sidebar-list-item__action sidebar-list-item__action_hover-only icon-bin"
      [appTip]="'Remove'"
      [appTipOptions]="{ side: 'top' }"
      (click)="$event.stopPropagation(); remove.emit()"
    ></div>
  </div>
</div>
