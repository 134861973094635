import { AfterViewInit, ChangeDetectorRef, EventEmitter, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { DomSanitizer } from '@angular/platform-browser';
import isArray from 'lodash/isArray';
import { SelectSource } from 'ng-gxselect';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { EMPTY, fromEvent, Subject, timer } from 'rxjs';
import { debounceTime, filter, switchMap } from 'rxjs/operators';
import { localize } from '@common/localize';
import { AutofocusDirective, coerceArray, controlValue, isSet, KeyboardEventKeyCode } from '@shared';
import '../../utils/mat-select-override';
export var SelectSegment;
(function (SelectSegment) {
    SelectSegment["Top"] = "top";
    SelectSegment["Middle"] = "middle";
    SelectSegment["Bottom"] = "bottom";
})(SelectSegment || (SelectSegment = {}));
var SelectComponent = /** @class */ (function () {
    function SelectComponent(sanitizer, cd) {
        this.sanitizer = sanitizer;
        this.cd = cd;
        this.multiple = false;
        this.error = false;
        this.orange = false;
        this.theme = false;
        this.styles = false;
        this.fill = false;
        this.small = false;
        this.expand = false;
        this.options = [];
        this.resetEnabled = false;
        this.colorEnabled = false;
        this.searchEnabled = true;
        this.searchDebounce = 0;
        this.searchMinimumLength = 1;
        this.valueChange = new EventEmitter();
        this.search = '';
        this.searchChanged = new Subject();
        this.selectSegments = SelectSegment;
        this.sourceValueLoading = false;
    }
    SelectComponent.prototype.ngOnInit = function () {
        this.initSearch();
        this.initClasses();
    };
    SelectComponent.prototype.ngOnDestroy = function () { };
    SelectComponent.prototype.ngOnChanges = function (changes) {
        this.placeholder = this.placeholder || localize('Choose');
        this.emptyPlaceholder = this.emptyPlaceholder || localize('Nothing found');
        this.compareWith = this.compareWith || this.defaultCompare;
        if (changes.options) {
            this.updateStaticOptionsFiltered();
        }
        if (changes.searchDebounce) {
            this.initSearch();
        }
        if (changes.source) {
            this.initSource();
        }
        if ([changes.classes, changes.error, changes.theme, changes.styles].some(function (item) { return item && !item.firstChange; })) {
            this.initClasses();
        }
    };
    SelectComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.initScrollFix();
        // Fix for multiple select initial value display
        timer(0)
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            if (_this.matSelect) {
                _this.matSelect['_initializeSelection']();
            }
        });
    };
    SelectComponent.prototype.initSearch = function () {
        var _this = this;
        if (this.searchChangedSubscription) {
            this.searchChangedSubscription.unsubscribe();
        }
        this.searchChangedSubscription = this.searchChanged
            .pipe(debounceTime(this.searchDebounce), untilDestroyed(this))
            .subscribe(function () { return _this.onSearch(); });
    };
    SelectComponent.prototype.initSource = function () {
        var _this = this;
        if (!this.source) {
            return;
        }
        if (this.source) {
            this.source.config = { searchMinimumLength: this.searchMinimumLength };
        }
        if (this.sourceValueSubscription) {
            this.sourceValueSubscription.unsubscribe();
        }
        if (this.control) {
            var isSameOption_1 = function (currentOption, value) {
                if (!currentOption) {
                    return false;
                }
                if (_this.multiple) {
                    var currentOptions = currentOption;
                    return (currentOptions.length == value.length &&
                        currentOptions.every(function (item, i) { return _this.compareWith(item.value, value[i]); }));
                }
                else {
                    var currentSingleOption = currentOption;
                    return _this.compareWith(currentSingleOption.value, value);
                }
            };
            this.sourceValueSubscription = controlValue(this.control)
                .pipe(filter(function (value) { return isSet(value) && !isSameOption_1(_this.sourceCurrentOption, value); }), switchMap(function (value) {
                _this.sourceValueLoading = true;
                _this.cd.markForCheck();
                return _this.source.loadValue(value);
            }), untilDestroyed(this))
                .subscribe(function () {
                _this.sourceValueLoading = false;
                _this.cd.markForCheck();
            }, function () {
                _this.sourceValueLoading = false;
                _this.cd.markForCheck();
            });
        }
        if (this.sourceCurrentOptionSubscription) {
            this.sourceCurrentOptionSubscription.unsubscribe();
        }
        this.sourceCurrentOptionSubscription = this.source.valueOption$
            .pipe(untilDestroyed(this))
            .subscribe(function (valueOption) {
            _this.sourceCurrentOption = _this.multiple ? [] : undefined;
            _this.cd.detectChanges();
            _this.sourceCurrentOption = valueOption;
            _this.cd.markForCheck();
        });
    };
    SelectComponent.prototype.initScroll = function () {
        if (!this.matSelect || !this.matSelect.panel) {
            return;
        }
        if (this.scrollSubscription) {
            this.scrollSubscription.unsubscribe();
        }
        if (this.source) {
            this.initSourceScroll();
        }
    };
    SelectComponent.prototype.initSourceScroll = function () {
        var _this = this;
        this.scrollSubscription = fromEvent(this.matSelect.panel.nativeElement, 'scroll')
            .pipe(untilDestroyed(this))
            .subscribe(function (e) {
            var srcElement = e.srcElement;
            if (srcElement.scrollTop + srcElement.offsetHeight >= srcElement.scrollHeight - 20) {
                _this.source.loadMore();
            }
        });
    };
    SelectComponent.prototype.initClasses = function () {
        this.classes = coerceArray(this.classes).filter(function (item) {
            return isSet(item) && !['mat-select-error', 'mat-select-theme', 'mat-select-styles'].includes(item);
        }).concat((this.error ? ['mat-select-error'] : []), (this.theme ? ['mat-select-theme'] : []), (this.styles ? ['mat-select-styles'] : []));
    };
    SelectComponent.prototype.defaultCompare = function (o1, o2) {
        return o1 == o2;
    };
    SelectComponent.prototype.resetSearch = function () {
        if (!isSet(this.search)) {
            return;
        }
        this.search = '';
        this.cd.markForCheck();
        if (this.source) {
            this.source.setSearch(this.search);
        }
        else {
            this.onSearch();
        }
    };
    SelectComponent.prototype.onSearch = function () {
        if (this.source) {
            this.source.search(this.search);
        }
        else {
            this.updateStaticOptionsFiltered();
        }
    };
    SelectComponent.prototype.updateStaticOptionsFiltered = function () {
        var _this = this;
        if (!this.search) {
            this.staticOptionsFiltered = this.options;
            this.cd.markForCheck();
            return;
        }
        else if (!this.options) {
            this.staticOptionsFiltered = [];
            this.cd.markForCheck();
            return;
        }
        this.staticOptionsFiltered = this.options.filter(function (item) {
            return isSet(item.name) && String(item.name).toLowerCase().includes(_this.search.toLowerCase());
        });
        this.cd.markForCheck();
    };
    SelectComponent.prototype.close = function () {
        if (this.matSelect) {
            this.matSelect.close();
        }
    };
    SelectComponent.prototype.onOpened = function () {
        if (this.searchFocus) {
            this.searchFocus.focus();
        }
        this.initScroll();
        if (this.source && !this.source.loaded && !this.source.loading) {
            this.source.loadMore();
        }
    };
    SelectComponent.prototype.onClosed = function () {
        if (this.scrollSubscription) {
            this.scrollSubscription.unsubscribe();
        }
        this.resetSearch();
    };
    SelectComponent.prototype.onOptionSelectionChange = function (option, e) {
        if (e.source.selected) {
            if (this.multiple) {
                this.sourceCurrentOption = [
                    option
                ].concat((this.sourceCurrentOption || []).filter(function (item) { return item !== option; }));
            }
            else {
                this.sourceCurrentOption = option;
            }
            this.cd.markForCheck();
        }
    };
    SelectComponent.prototype.ignoreSpaceSelect = function (e) {
        if (e.keyCode == KeyboardEventKeyCode.Space) {
            e.stopPropagation();
        }
    };
    Object.defineProperty(SelectComponent.prototype, "empty", {
        get: function () {
            return this.matSelect ? this.matSelect.empty : undefined;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelectComponent.prototype, "triggerOption", {
        get: function () {
            var _this = this;
            // TODO: Optimize computed property
            if (this.source) {
                if (this.multiple) {
                    return this.sourceCurrentOption
                        ? this.sourceCurrentOption.reduce(function (acc, option) {
                            if (!acc.find(function (item) { return _this.compareWith(item.value, option.value); })) {
                                acc.push(option);
                            }
                            return acc;
                        }, [])
                        : undefined;
                }
                else {
                    return this.sourceCurrentOption ? this.sourceCurrentOption : undefined;
                }
            }
            else {
                if (this.multiple) {
                    var currentValue_1 = isSet(this.control.value) ? coerceArray(this.control.value) : [];
                    return this.options.filter(function (item) { return currentValue_1.some(function (value) { return _this.compareWith(item.value, value); }); });
                }
                else {
                    return this.options.find(function (item) { return _this.compareWith(item.value, _this.control.value); });
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelectComponent.prototype, "triggerIcon", {
        get: function () {
            var triggerOption = this.triggerOption;
            if (!triggerOption) {
                return;
            }
            else if (isArray(triggerOption)) {
                return;
            }
            else {
                return triggerOption.icon;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelectComponent.prototype, "triggerColor", {
        get: function () {
            var triggerOption = this.triggerOption;
            if (!triggerOption) {
                return;
            }
            else if (isArray(triggerOption)) {
                return;
            }
            else {
                return triggerOption.color;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelectComponent.prototype, "triggerValue", {
        get: function () {
            // return this.matSelect ? this.matSelect.triggerValue : undefined;
            var triggerOption = this.triggerOption;
            if (!triggerOption) {
                return;
            }
            else if (isArray(triggerOption)) {
                return triggerOption.map(function (item) { return item.name; }).join(',');
            }
            else {
                return triggerOption.name;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelectComponent.prototype, "isControlSet", {
        get: function () {
            if (!this.control) {
                return false;
            }
            return this.control.value !== null && this.control.value !== undefined;
        },
        enumerable: true,
        configurable: true
    });
    SelectComponent.prototype.open = function () {
        if (this.matSelect) {
            this.matSelect.open();
        }
    };
    SelectComponent.prototype.initScrollFix = function () {
        var _this = this;
        if (!this.matSelect) {
            return;
        }
        this.matSelect.openedChange
            .pipe(switchMap(function (open) {
            if (open && _this.matSelect.panel) {
                return fromEvent(_this.matSelect.panel.nativeElement, 'scroll');
            }
            else {
                _this.panelScrollTop = undefined;
                return EMPTY;
            }
        }), untilDestroyed(this))
            .subscribe(function (event) {
            _this.panelScrollTop = event.target.scrollTop;
        });
        this.matSelect.selectionChange.pipe(untilDestroyed(this)).subscribe(function () {
            if (_this.matSelect.panel && isSet(_this.panelScrollTop)) {
                _this.matSelect.panel.nativeElement.scrollTop = _this.panelScrollTop;
            }
        });
    };
    return SelectComponent;
}());
export { SelectComponent };
