<form *ngIf="!loading" [formGroup]="form.form">
  <div class="popup-content resource-popup">
    <div
      *ngIf="environment"
      class="resource-popup__tag"
      [ngClass]="['color_' + environment.color + '_1', 'background-color_' + environment.color + '_2']"
    >
      {{ environment.name }}
    </div>
    <div class="resource-popup__image">
      <div
        class="resource-popup__image-inner"
        [style.background-image]="'/assets/images/resources/' + typeItem.image + '-color.svg' | appDeployCssUrl"
      ></div>
    </div>
    <div class="resource-popup__title">
      Allow Jet Admin to access <br />
      <strong [style.color]="typeItem.color">{{ typeItem.label }}</strong>
    </div>
    <div class="resource-popup__description">
      Enter your credentials to create internal tools on top of your data.
    </div>

    <div class="resource-popup__content">
      <app-page-block *ngIf="resourceNameEditing">
        <app-auto-field
          [form]="form.resourceForm"
          [field]="
            createField({
              name: 'name',
              label: 'Resource Name',
              field: 'CharField',
              description: 'This is how you and your team will see it',
              required: true,
              params: { classes: ['input_fill'] }
            })
          "
        ></app-auto-field>
      </app-page-block>

      <app-page-block>
        <ng-template #key_description>
          You can obtain it at SmartSuite:<br />
          User Menu -> API Key
        </ng-template>

        <app-auto-field
          [form]="form.form"
          [field]="
            createField({
              name: 'key',
              label: 'API Key',
              field: 'CharField',
              description: key_description,
              placeholder: defaultPlaceholder,
              required: true,
              params: { classes: ['input_fill'] }
            })
          "
          [autofocus]="true"
        ></app-auto-field>
      </app-page-block>

      <app-page-block>
        <ng-template #account_id_description>
          You can obtain it from SmartSuite URL:<br />
          https://app.smartsuite.com/[ACCOUNT_ID]/home
        </ng-template>

        <app-auto-field
          [form]="form.form"
          [field]="
            createField({
              name: 'account_id',
              label: 'Account ID',
              field: 'CharField',
              description: account_id_description,
              placeholder: defaultPlaceholder,
              required: true,
              params: { classes: ['input_fill'] }
            })
          "
        ></app-auto-field>
      </app-page-block>

      <app-page-block>
        <div class="field">
          <div class="field__label">
            Solution
          </div>
          <div class="field__value">
            <app-select
              *ngIf="form.form.controls['account_id'].valid && form.form.controls['key'].valid"
              [placeholder]="'choose' | localize | appCapitalize"
              [control]="form.form.controls['solution_id']"
              [fill]="true"
              [source]="solutionSource"
              [searchDebounce]="200"
            >
            </app-select>
            <div
              *ngIf="!form.form.controls['account_id'].valid || !form.form.controls['key'].valid"
              class="input input_fill input_disabled"
            >
              Specify Account ID and API key
            </div>
          </div>
        </div>
      </app-page-block>

      <app-page-block *ngIf="(form.form | appFormErrors).length">
        <app-resource-settings-submit-error [errors]="form.form | appFormErrors"></app-resource-settings-submit-error>
      </app-page-block>
    </div>

    <div class="popup-content__submit">
      <button
        *ngIf="resourceDelete && resource"
        type="button"
        class="button button_danger popup-content__delete"
        (click)="requestDelete()"
      >
        Delete
      </button>
      <button type="button" class="button popup-content__submit-item" (click)="cancel()">
        Cancel
      </button>
      <button
        type="button"
        class="button button_primary button_shadow popup-content__submit-item"
        [class.button_disabled]="!form.isValid() || loadingSubmit"
        (click)="resource ? submit() : chooseSync()"
      >
        <app-loader-small *ngIf="loadingSubmit" class="button__icon button__icon_left"></app-loader-small>
        <ng-container *ngIf="resource">Update resource</ng-container>
        <ng-container *ngIf="!resource">Add resource</ng-container>
      </button>
    </div>
  </div>
</form>

<app-resource-settings-stub *ngIf="loading" [typeItem]="typeItem"></app-resource-settings-stub>
