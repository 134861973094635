import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { BorderSettingsControl, CornersControl, MarginControl, ShadowControl } from '@modules/customize';

import { Style, StyleItem, StylesEditComponent, StyleType } from '../styles-edit/styles-edit.component';
import { ElementWrapperStylesControl } from './element-wrapper-styles.control';

@Component({
  selector: 'app-styles-element-wrapper',
  templateUrl: './styles-element-wrapper-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StylesElementWrapperEditComponent implements OnInit, OnDestroy {
  @Input() control: ElementWrapperStylesControl;

  @ViewChild(StylesEditComponent) stylesEditComponent: StylesEditComponent;

  styles: StyleItem[] = [];

  ngOnInit(): void {
    this.updateStyles();
  }

  ngOnDestroy(): void {}

  updateStyles() {
    this.styles = [
      {
        style: {
          label: 'Border',
          icon: 'sides',
          type: StyleType.BorderSettings,
          control: this.control.controls.border_settings,
          isSet$: control => control.isSet$(),
          onAdd: style => style.control.resetDefaults(),
          onRemove: style => style.control.reset()
        } as Style<BorderSettingsControl>
      },
      {
        style: {
          label: 'Corner radius',
          icon: 'corners',
          type: StyleType.Corners,
          control: this.control.controls.border_radius,
          isSet$: control => control.isSet$(),
          onAdd: style => style.control.resetDefaults(),
          onRemove: style => style.control.reset()
        } as Style<CornersControl>
      },
      {
        style: {
          label: 'Shadow',
          icon: 'duplicate_2',
          type: StyleType.Shadow,
          control: this.control.controls.shadow,
          isSet$: control => control.isSet$(),
          onAdd: style => style.control.resetDefaults(),
          onRemove: style => style.control.reset()
        } as Style<ShadowControl>
      },
      {
        style: {
          label: 'Outer spacing',
          icon: 'align_horizontal_center',
          type: StyleType.Margin,
          control: this.control.controls.margin,
          isSet$: control => control.isSet$(),
          onRemove: style => style.control.reset()
        } as Style<MarginControl>
      }
    ];
  }
}
