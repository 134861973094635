import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { merge, Observable } from 'rxjs';
import { debounceTime, delay, filter, map, shareReplay, startWith } from 'rxjs/operators';

import { DynamicComponentArguments } from '@common/dynamic-component';
import { NotificationService } from '@common/notifications';
import {
  modelFieldItemToRawListViewSettingsColumn,
  RawListViewSettingsColumn,
  ViewContext,
  ViewContextElement
} from '@modules/customize';
import { BooleanFieldStyle, CustomSelectItem, FieldsEditConfigurable, Option } from '@modules/field-components';
import {
  BaseField,
  createFormFieldFactory,
  DefaultType,
  FieldType,
  GeographyOutputFormat,
  getFieldComponentsByName,
  getFieldDescriptionByType,
  ValidatorType
} from '@modules/fields';
import { ModelDbField, ModelDescription } from '@modules/models';
import { CurrentEnvironmentStore, Resource } from '@modules/projects';
import { controlValue, Singleton } from '@shared';

import { CustomizeBarContext } from '../../services/customize-bar-context/customize-bar.context';
import { CustomizeBarColumnEditForm } from '../customize-bar-column-edit/customize-bar-column-edit.form';
import { CustomizeBarResourceFieldEditForm } from './customize-bar-resource-field-edit.form';

@Component({
  selector: 'app-customize-bar-resource-field-edit',
  templateUrl: './customize-bar-resource-field-edit.component.html',
  providers: [CustomizeBarResourceFieldEditForm, CustomizeBarColumnEditForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeBarResourceFieldEditComponent implements OnInit, OnDestroy {
  @Input() resource: Resource;
  @Input() modelDescription: ModelDescription;
  @Input() field: ModelDbField;
  @Input() defaults: BaseField;
  @Input() analyticsSource: string;
  @Output() saved = new EventEmitter<ModelDescription>();
  @Output() cancelled = new EventEmitter<void>();

  createField = createFormFieldFactory();
  booleanFieldStyle = BooleanFieldStyle;
  fieldTypes = FieldType;
  defaultTypes = DefaultType;
  loading = false;
  resourceModelItems$: Observable<CustomSelectItem<string>[]>;
  defaultTypeOptions$: Observable<Option<DefaultType>[]>;
  validatorTypes = ValidatorType;
  configurable: FieldsEditConfigurable = {
    name: true,
    field: false,
    required: true,
    editable: true,
    params: true
  };

  field$: Observable<BaseField>;
  viewParamsComponentData: DynamicComponentArguments;
  dataParamsComponentData: DynamicComponentArguments;
  defaultValueParams = {};
  contextNew = new Singleton(() => new ViewContext(this.currentEnvironmentStore));

  constructor(
    @Optional() public context: ViewContext,
    @Optional() public contextElement: ViewContextElement,
    private customizeBarContext: CustomizeBarContext,
    public resourceForm: CustomizeBarResourceFieldEditForm,
    public overrideForm: CustomizeBarColumnEditForm,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private notificationService: NotificationService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.resourceForm.init({
      resource: this.resource,
      modelDescription: this.modelDescription,
      field: this.field,
      defaults: this.defaults
    });

    if (this.isPrimaryKey()) {
      this.resourceForm.controls.default_type.disable();
    }

    this.overrideForm.init({
      field: this.field
        ? {
            ...modelFieldItemToRawListViewSettingsColumn(this.field),
            required: this.field.required,
            placeholder: this.field.placeholder,
            validatorType: this.field.validatorType,
            validatorParams: this.field.validatorParams,
            ...this.defaults
          }
        : {
            editable: true,
            ...this.defaults
          },
      configurable: this.configurable
    });

    this.resourceModelItems$ = this.resourceForm.resourceModelItems$();
    this.defaultTypeOptions$ = this.resourceForm.getDefaultTypeOptions$();

    this.field$ = merge(this.resourceForm.valueChanges, this.overrideForm.valueChanges).pipe(
      debounceTime(200),
      map(() => this.getCurrentField()),
      startWith(this.getCurrentField()),
      shareReplay(1)
    );

    controlValue<FieldType>(this.resourceForm.controls.field)
      .pipe(untilDestroyed(this))
      .subscribe(type => {
        if (type == FieldType.Location) {
          this.overrideForm.controls.params.patchValue({
            ...this.overrideForm.controls.params.value,
            output_format: GeographyOutputFormat.PostgreSQL
          });
        }
      });

    this.resourceForm.controls.field.valueChanges
      .pipe(delay(0), untilDestroyed(this))
      .subscribe(() => this.updateParamsComponentData());

    this.updateParamsComponentData();

    const cleanName = value => value.replace(/[^\w]/g, '_').replace(/^\d/, '_').toLowerCase();

    this.resourceForm.controls.name.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
      if (typeof value == 'string') {
        const cleanedValue = cleanName(value);

        if (cleanedValue != value) {
          this.resourceForm.controls.name.patchValue(cleanedValue);
        }
      }
    });

    this.overrideForm.controls.verbose_name.valueChanges
      .pipe(
        filter(() => !this.resourceForm.controls.name_enabled.value),
        untilDestroyed(this)
      )
      .subscribe(value => {
        const cleanedValue = cleanName(value);
        this.resourceForm.controls.name.patchValue(cleanedValue);
      });

    this.field$.pipe(untilDestroyed(this)).subscribe(field => {
      const classes = field.params && field.params['classes'] ? field.params['classes'] : [];

      this.defaultValueParams = {
        ...field.params,
        classes: ['input_fill', 'select_fill', 'input_segment-bottom', 'select_segment-bottom', ...classes]
      };
      this.cd.markForCheck();
    });
  }

  ngOnDestroy(): void {}

  isPrimaryKey() {
    return this.field && this.modelDescription.primaryKeyField == this.field.name;
  }

  getContextProviders() {
    return [
      {
        provide: ViewContext,
        useFactory: () => {
          if (this.context) {
            return this.context;
          } else {
            return this.contextNew.get();
          }
        },
        deps: []
      }
    ];
  }

  updateParamsComponentData() {
    const item = getFieldDescriptionByType(this.resourceForm.controls.field.value);
    const components = getFieldComponentsByName(item.name);

    if (!components || !components.viewParamsComponent) {
      this.viewParamsComponentData = undefined;
    } else {
      this.viewParamsComponentData = {
        component: components.viewParamsComponent,
        inputs: {
          field: this.field ? modelFieldItemToRawListViewSettingsColumn(this.field) : undefined,
          field$: this.field$,
          configurable: this.configurable,
          control: this.overrideForm.controls.params,
          context: this.context || this.contextNew.get(),
          contextElement: this.contextElement,
          resourceField: true,
          analyticsSource: this.analyticsSource
        },
        providers: this.getContextProviders()
      };
    }

    if (!components || !components.dataParamsComponent) {
      this.dataParamsComponentData = undefined;
    } else {
      this.dataParamsComponentData = {
        component: components.dataParamsComponent,
        inputs: {
          field: this.field ? modelFieldItemToRawListViewSettingsColumn(this.field) : undefined,
          field$: this.field$,
          configurable: this.configurable,
          control: this.overrideForm.controls.params,
          context: this.context || this.contextNew.get(),
          contextElement: this.contextElement,
          resourceField: true,
          analyticsSource: this.analyticsSource
        },
        providers: this.getContextProviders()
      };
    }

    this.cd.detectChanges();
  }

  delete() {
    // this.event.emit({ type: CustomizeBarEditEventType.Deleted });
    // this.close();
  }

  close() {
    this.customizeBarContext.popSettingsComponent();
  }

  getCurrentField(): RawListViewSettingsColumn {
    const field = this.resourceForm.serialize();
    const overrideField = this.overrideForm.submit().field;

    field.applyOverrides(overrideField);

    return modelFieldItemToRawListViewSettingsColumn(field);
  }

  submit() {
    this.loading = true;
    this.cd.markForCheck();

    const overrideField = this.overrideForm.submit().field;

    this.resourceForm
      .submit(overrideField)
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          this.saved.emit(result.modelDescription);
          this.close();

          const field = result.field || overrideField;

          if (this.field) {
            this.notificationService.success(
              'Updated',
              `Column <strong>${field.verboseName}</strong> was successfully updated`
            );
          } else {
            this.notificationService.success(
              'Created',
              `Column <strong>${field.verboseName}</strong> was successfully updated`
            );
          }
        },
        () => {
          this.loading = false;
          this.cd.markForCheck();
        }
      );
  }

  cancel() {
    this.cancelled.emit();
    this.close();
  }
}
