<div class="sidebar__element">
  <app-sidebar-field [label]="'JavaScript code'">
    <div
      class="field field_content-fill-vertical input-icon-wrapper"
      [class.input-icon-wrapper_fill-vertical]="fillVertical"
    >
      <ng-container *ngIf="!isInputOverlayOpened()">
        <app-view-context-token-popover-overlay
          [context]="context"
          [contextElement]="contextElement"
          [contextElementPath]="contextElementPath"
          [contextElementPaths]="contextElementPaths"
          [origin]="insert_token_origin"
          [hideSections]="['functions']"
          (selected)="onJsTokenSelected($event); closeTokenPopover()"
        ></app-view-context-token-popover-overlay>

        <app-auto-field
          [form]="form"
          [label]="false"
          [field]="
            createField({
              name: 'run_javascript',
              field: 'CodeField',
              placeholder: jsPlaceholder,
              params: {
                language: 'javascript',
                autogrow: !fillVertical,
                flexible: fillVertical,
                field: true,
                field_contrast: true,
                field_footer: true,
                wrap: true,
                dark: dark,
                classes: [fill ? 'input_fill' : ''].concat(classes || [])
              }
            })
          "
          #js_field
        ></app-auto-field>

        <div
          class="button-tags"
          [class.button-tags_border]="true"
          [class.button-tags_footer]="true"
          cdkOverlayOrigin
          #insert_token_origin="cdkOverlayOrigin"
        >
          <div
            class="button-tag button-tags__item"
            [class.button-tags__item_fill]="false"
            [class.button-tag_darker]="darker"
            (click)="openTokenPopover()"
          >
            <span class="button-tag__icon button-tag__icon_text">{{ '{' }}..{{ '}' }}</span>
            <span class="button-tag__label">Insert Input</span>
          </div>
        </div>

        <div *ngIf="jsError" class="input-footer">
          <div class="input-footer__content">
            <div class="input-footer__message input-footer__message_error">
              <div class="input-footer__message-title">Result</div>
              <div class="input-footer__message-row">
                {{ jsError }}
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <div
        *ngIf="isInputOverlayOpened()"
        class="input input_disabled"
        [class.input_fill]="fill"
        [class.input_fill-vertical]="fillVertical"
      ></div>

      <!--      <div-->
      <!--        *ngIf="overlayEnabled"-->
      <!--        class="input-expand input-expand_position_top-right input-icon-hover_flex"-->
      <!--        [class.input-expand_active]="isInputOverlayOpened()"-->
      <!--        [class.input-icon-hover]="!isInputOverlayOpened()"-->
      <!--        appInputOverlay-->
      <!--        [appInputOverlayInput]="this"-->
      <!--        (appInputOverlayFinished)="onInputOverlayFinished()"-->
      <!--      >-->
      <!--        <span-->
      <!--          class="input-expand__icon"-->
      <!--          [class.icon-copy]="isInputOverlayOpened()"-->
      <!--          [class.icon-enlarge_expand]="!isInputOverlayOpened()"-->
      <!--        ></span>-->
      <!--        <span class="input-expand__label">Editing in popup</span>-->
      <!--        <span class="input-expand__close icon-close"></span>-->
      <!--      </div>-->
    </div>
  </app-sidebar-field>
</div>
