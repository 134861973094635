/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../../shared/pipes/capitalize/capitalize.pipe";
import * as i3 from "../project-group-dropdown/project-group-dropdown.component.ngfactory";
import * as i4 from "../project-group-dropdown/project-group-dropdown.component";
import * as i5 from "../../services/project-group-edit/project-group-edit.controller";
import * as i6 from "../../../projects/services/project-group/project-group.service";
import * as i7 from "../../../projects/stores/project-group.store";
import * as i8 from "../../../projects/stores/current-project.store";
import * as i9 from "@angular/cdk/overlay";
import * as i10 from "./project-group-control.component";
var styles_ProjectGroupControlComponent = [];
var RenderType_ProjectGroupControlComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ProjectGroupControlComponent, data: {} });
export { RenderType_ProjectGroupControlComponent as RenderType_ProjectGroupControlComponent };
function View_ProjectGroupControlComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [["class", "select__input-icon"]], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpad(2, 1)], function (_ck, _v) { var currVal_0 = "select__input-icon"; var currVal_1 = _ck(_v, 2, 0, ("icon-" + (i0.ɵnov(_v.parent.parent, 3).currentOption.icon || "users_teams"))); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_ProjectGroupControlComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""])), i0.ɵppd(2, 1)], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent.parent, 0), i0.ɵnov(_v.parent.parent, 3).currentOption.name)); _ck(_v, 1, 0, currVal_0); }); }
function View_ProjectGroupControlComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.placeholder; _ck(_v, 1, 0, currVal_0); }); }
function View_ProjectGroupControlComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ProjectGroupControlComponent_2)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 4, "span", [["class", "select__input-label"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ProjectGroupControlComponent_3)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ProjectGroupControlComponent_4)), i0.ɵdid(7, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(8, 0, null, null, 0, "span", [["class", "select__input-arrow"]], null, null, null, null, null))], function (_ck, _v) { var currVal_0 = i0.ɵnov(_v.parent, 3).currentOption; _ck(_v, 2, 0, currVal_0); var currVal_1 = i0.ɵnov(_v.parent, 3).currentOption; _ck(_v, 5, 0, currVal_1); var currVal_2 = !i0.ɵnov(_v.parent, 3).currentOption; _ck(_v, 7, 0, currVal_2); }, null); }
function View_ProjectGroupControlComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "span", [["class", "select__input-label"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "span", [], [[2, "loading-animation", null]], null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "span", [["class", "stub-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["value"]))], null, function (_ck, _v) { var currVal_0 = true; _ck(_v, 2, 0, currVal_0); }); }
export function View_ProjectGroupControlComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i2.CapitalizePipe, []), i0.ɵqud(402653184, 1, { cdkConnectedOverlay: 0 }), (_l()(), i0.ɵeld(2, 0, null, null, 1, "app-project-group-dropdown", [], null, null, null, i3.View_ProjectGroupDropdownComponent_0, i3.RenderType_ProjectGroupDropdownComponent)), i0.ɵdid(3, 4440064, [["dropdown", 4]], 0, i4.ProjectGroupDropdownComponent, [i5.ProjectGroupEditController, i6.ProjectGroupService, i7.ProjectGroupStore, i8.CurrentProjectStore, i0.ChangeDetectorRef], { control: [0, "control"], uidValue: [1, "uidValue"], origin: [2, "origin"], allowNull: [3, "allowNull"], environmentName: [4, "environmentName"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 6, "div", [["class", "select select_theme_jet"]], [[2, "select_fill", null], [2, "select_small", null]], null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 5, "div", [["cdkOverlayOrigin", ""], ["class", "select__input"]], [[2, "select__input_disabled", null], [2, "select__input_opened", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3).open() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(6, 16384, [["dropdownTrigger", 4]], 0, i9.CdkOverlayOrigin, [i0.ElementRef], null, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ProjectGroupControlComponent_1)), i0.ɵdid(8, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ProjectGroupControlComponent_5)), i0.ɵdid(10, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.control; var currVal_1 = _co.uidValue; var currVal_2 = i0.ɵnov(_v, 6); var currVal_3 = _co.allowNull; var currVal_4 = _co.environmentName; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_9 = !i0.ɵnov(_v, 3).isLoading(); _ck(_v, 8, 0, currVal_9); var currVal_10 = i0.ɵnov(_v, 3).isLoading(); _ck(_v, 10, 0, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_5 = _co.fill; var currVal_6 = _co.small; _ck(_v, 4, 0, currVal_5, currVal_6); var currVal_7 = _co.control.disabled; var currVal_8 = i0.ɵnov(_v, 3).isOpened(); _ck(_v, 5, 0, currVal_7, currVal_8); }); }
export function View_ProjectGroupControlComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-project-group-control", [], null, null, null, View_ProjectGroupControlComponent_0, RenderType_ProjectGroupControlComponent)), i0.ɵdid(1, 245760, null, 0, i10.ProjectGroupControlComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProjectGroupControlComponentNgFactory = i0.ɵccf("app-project-group-control", i10.ProjectGroupControlComponent, View_ProjectGroupControlComponent_Host_0, { control: "control", uidValue: "uidValue", placeholder: "placeholder", fill: "fill", small: "small", allowNull: "allowNull", environmentName: "environmentName" }, {}, []);
export { ProjectGroupControlComponentNgFactory as ProjectGroupControlComponentNgFactory };
