import { Overlay } from '@angular/cdk/overlay';
import { CdkPortalOutlet } from '@angular/cdk/portal';
import { AfterViewInit, ChangeDetectorRef, ElementRef, EventEmitter, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { extent, max } from 'd3-array';
import { axisBottom, axisLeft } from 'd3-axis';
import { scaleBand, scaleLinear } from 'd3-scale';
import { pointer, select } from 'd3-selection';
import { stack } from 'd3-shape';
import fromPairs from 'lodash/fromPairs';
import keys from 'lodash/keys';
import * as moment from 'moment';
import { untilDestroyed } from 'ngx-take-until-destroy';
import * as numeral from 'numeral';
import { BehaviorSubject } from 'rxjs';
import { skip } from 'rxjs/operators';
import { applyDatasetsDefaultColors, CHART_COLORS, getDatasetsGroupLookup, getDatasetsGroups, getDatasetsUniqueGroups, getDateFormatByLookup, normalizeDatasetsGroupValues, prepareDataset, syncSortedDatasetsGroups } from '@modules/charts';
import { getThemeVarBorderRadiusValue, ThemeContext, ThemeVar } from '@modules/theme-components';
import { elementSize$, generateAlphanumeric, isSet, TypedChanges } from '@shared';
// TODO: Refactor import
import { getColorHex, getColorHexStr, parseColor } from '../../../colors/utils/colors';
import { DataTooltipController } from '../../services/data-tooltip-controller/data-tooltip.controller';
import { fitXAxisLabelWithVisibility, getYAxisWidth } from '../../utils/d3';
function isDatasetGroupEqual(lhs, rhs) {
    return lhs.datasetIndex == rhs.datasetIndex && lhs.groupIndex == rhs.groupIndex;
}
var BarChart2Component = /** @class */ (function () {
    function BarChart2Component(el, overlay, dataTooltip, themeContext, cd) {
        this.el = el;
        this.overlay = overlay;
        this.dataTooltip = dataTooltip;
        this.themeContext = themeContext;
        this.cd = cd;
        this.datasets = [];
        this.stacked = false;
        this.percentage = false;
        this.defaultColors = CHART_COLORS;
        this.animate = true;
        this.xAxisVisible = true;
        this.yAxisVisible = true;
        this.legend = true;
        this.interactive = true;
        this.datasetBackground = true;
        this.dataClickEnabled = false;
        // @Input() width = 3;
        this.trackItem = false;
        this.theme = false;
        this.itemEnter = new EventEmitter();
        this.itemLeave = new EventEmitter();
        this.dataClick = new EventEmitter();
        this.data = [];
        this.dataGroups = [];
        this.dataTotal = [];
        this.margin = { top: 8, right: 8, bottom: 22, left: 40 };
        this.hoverDatasetGroup$ = new BehaviorSubject(undefined);
        this.hoverLegendDatasetIndex$ = new BehaviorSubject(undefined);
        this.uid = generateAlphanumeric(8);
    }
    BarChart2Component.prototype.getId = function (name) {
        return name + "-" + this.uid;
    };
    BarChart2Component.prototype.ngOnInit = function () { };
    BarChart2Component.prototype.ngOnDestroy = function () { };
    BarChart2Component.prototype.ngOnChanges = function (changes) {
        if (changes.datasets || changes.percentage) {
            this.data = this.datasets.map(function (dataset) { return prepareDataset(dataset); });
            this.dataGroupLookup = getDatasetsGroupLookup(this.data);
            this.dataGroups = getDatasetsUniqueGroups(this.data);
            applyDatasetsDefaultColors(this.data, this.defaultColors);
            syncSortedDatasetsGroups(this.data, this.dataGroups);
            if (this.percentage) {
                normalizeDatasetsGroupValues(this.data, this.dataGroups);
            }
            this.dataTotal = getDatasetsGroups(this.data);
        }
        if (this.svg) {
            this.rerender();
        }
    };
    BarChart2Component.prototype.ngAfterViewInit = function () {
        var _this = this;
        setTimeout(function () {
            _this.init();
            elementSize$(_this.canvasElement.nativeElement)
                .pipe(skip(1), untilDestroyed(_this))
                .subscribe(function () { return _this.onResize(); });
        }, 0);
    };
    BarChart2Component.prototype.init = function () {
        this.initBounds();
        this.initSvg();
        this.initYAxis();
        this.renderYAxis();
        this.fitYAxis();
        this.initXAxis();
        this.renderXAxis();
        this.renderBar();
        this.renderGradients();
        this.initDatasetHover();
    };
    BarChart2Component.prototype.initBounds = function () {
        var width = this.canvasElement.nativeElement.offsetWidth;
        var height = this.canvasElement.nativeElement.offsetHeight;
        this.width = width - this.margin.left - this.margin.right;
        this.height = height - this.margin.top - this.margin.bottom;
    };
    BarChart2Component.prototype.initSvg = function () {
        this.svg = select(this.svgElement.nativeElement)
            .attr('width', '100%')
            .attr('height', '100%')
            .append('g')
            .attr('transform', "translate(" + this.margin.left + "," + this.margin.top + ")");
    };
    BarChart2Component.prototype.initXAxis = function () {
        this.xAxis = this.svg.append('g').attr('class', 'chart-axis chart-axis_x');
    };
    BarChart2Component.prototype.renderXAxis = function () {
        var _this = this;
        var xGroups = this.dataGroups.map(function (d) {
            if (d instanceof moment) {
                var format = getDateFormatByLookup(_this.dataGroupLookup) || 'lll';
                return d.format(format);
            }
            else {
                return String(d);
            }
        });
        var xGroupsDate = this.dataGroups.every(function (item) { return item instanceof moment; });
        this.xGroupScale = scaleBand().domain(xGroups).range([0, this.width]).padding(0.2);
        this.xSubgroupScale = scaleBand()
            .domain(this.data.map(function (item, i) { return i.toString(); }))
            .range([0, this.xGroupScale.bandwidth()])
            .padding(0.05);
        if (!this.xAxisVisible) {
            this.xAxis.selectChildren().remove();
            return;
        }
        var ticks = Math.floor(this.width / 80);
        var axisGenerator = axisBottom(this.xGroupScale).ticks(ticks).tickSize(0).tickPadding(10);
        var axis = this.xAxis.attr('transform', "translate(0," + this.height + ")").call(axisGenerator);
        fitXAxisLabelWithVisibility(axis, this.el.nativeElement);
        this.setAxisClasses(axis);
    };
    BarChart2Component.prototype.initYAxis = function () {
        this.yAxis = this.svg.append('g').attr('class', 'chart-axis chart-axis_y');
    };
    BarChart2Component.prototype.renderYAxis = function () {
        var _this = this;
        if (this.stacked) {
            var stackGenerator = stack()
                .keys(this.data.map(function (item, i) { return i.toString(); }))
                .value(function (item, key) {
                var datasetIndex = parseInt(key, 10);
                return _this.data[datasetIndex].dataset[item].value;
            });
            this.stackSeries = stackGenerator(this.dataGroups.map(function (item, i) { return i; }));
            this.stackTotalY = this.stackSeries.reduce(function (acc, dataset) {
                dataset.forEach(function (item) {
                    acc.push(item[0]);
                    acc.push(item[1]);
                });
                return acc;
            }, []);
        }
        else {
            this.stackSeries = undefined;
            this.stackTotalY = undefined;
        }
        var domain = this.stackSeries ? extent(this.stackTotalY) : extent(this.dataTotal, function (d) { return d.item.value; });
        var domainExpand = 0.05 * Math.abs(domain[1] - domain[0]);
        if (domain[0] !== 0) {
            domain[0] -= domainExpand;
        }
        domain[1] += domainExpand;
        if (isSet(this.min)) {
            domain[0] = this.min;
        }
        if (isSet(this.max)) {
            domain[1] = this.max;
        }
        this.yScale = scaleLinear().domain(domain).range([this.height, 0]);
        if (!this.yAxisVisible) {
            this.yAxis.selectChildren().remove();
            return;
        }
        var ticks = Math.floor(this.height / 50);
        var axisGenerator = axisLeft(this.yScale)
            .ticks(ticks)
            .tickSize(-this.width)
            .tickPadding(10)
            .tickFormat(function (value) {
            var yFormat = _this.percentage ? '0%' : _this.yFormat;
            if (!isSet(yFormat)) {
                return value;
            }
            return numeral(value).format(yFormat);
        });
        var axis = this.yAxis.call(axisGenerator);
        this.setAxisClasses(axis);
    };
    BarChart2Component.prototype.setAxisClasses = function (axis) {
        axis.selectAll('.domain').attr('class', 'chart-axis-domain domain');
        axis.selectAll('.tick').attr('class', 'chart-axis-tick-group tick');
        axis.selectAll('.chart-axis-tick-group line').attr('class', 'chart-axis-tick');
        axis.selectAll('.chart-axis-tick-group text').attr('class', 'chart-axis-label');
    };
    BarChart2Component.prototype.fitYAxis = function () {
        var width = getYAxisWidth(this.yAxis);
        this.margin.left = width + 10;
        this.width = this.canvasElement.nativeElement.offsetWidth - this.margin.left - this.margin.right;
        this.svg.attr('transform', "translate(" + this.margin.left + "," + this.margin.top + ")");
    };
    BarChart2Component.prototype.renderBar = function () {
        var _this = this;
        var minBarHeight = 5;
        var borderRadius = getThemeVarBorderRadiusValue(this.themeContext.options.borderRadius, ThemeVar.BorderRadiusS);
        if (this.stacked) {
            var datasets = this.stackSeries.map(function (dataset, datasetIndex) {
                return {
                    groups: dataset.map(function (item, groupIndex) {
                        return {
                            dataset: _this.data[datasetIndex],
                            datasetIndex: datasetIndex,
                            yFrom: item[0],
                            yTo: item[1],
                            group: _this.data[datasetIndex].dataset[groupIndex].group,
                            groupIndex: groupIndex,
                            value: _this.data[datasetIndex].dataset[groupIndex].value
                        };
                    })
                };
            });
            var innerPadding_1 = 2;
            this.svg
                .selectAll('.chart-bar-dataset')
                .data(datasets)
                .join('g')
                .attr('class', 'chart-bar-dataset')
                .selectAll('.chart-bar')
                .data(function (d) { return d.groups; })
                .join('rect')
                .attr('class', function (d) {
                var classes = [
                    'chart-bar',
                    "chart-bar_index-" + d.datasetIndex,
                    "chart-bar_group-index-" + d.datasetIndex + "-" + d.groupIndex
                ];
                if (_this.dataClickEnabled) {
                    classes.push('chart-bar_clickable');
                }
                return classes.join(' ');
            })
                .attr('rx', borderRadius)
                .attr('ry', borderRadius)
                .attr('x', function (d) {
                var group;
                if (d.group instanceof moment) {
                    var format = getDateFormatByLookup(_this.dataGroupLookup) || 'lll';
                    group = d.group.format(format);
                }
                else {
                    group = String(d.group);
                }
                return _this.xGroupScale(group);
            })
                .attr('y', function (d) { return _this.yScale(d.yTo) + innerPadding_1 * 0.5; })
                .attr('width', this.xGroupScale.bandwidth())
                .attr('height', function (d) { return Math.max(_this.yScale(d.yFrom) - _this.yScale(d.yTo) - innerPadding_1, 0); })
                .attr('fill', function (d) {
                var colorHex = getColorHex(d.dataset.color);
                var clr = parseColor(colorHex, '#000');
                return clr.string();
            })
                .attr('fill', function (d) {
                var barGradient = _this.getId("bar-gradient-" + d.datasetIndex);
                return "url(#" + barGradient + ")";
            })
                .on('mouseenter', function (e, d) {
                return _this.onMouseEnter({
                    datasetIndex: d.datasetIndex,
                    group: d.group,
                    groupIndex: d.groupIndex,
                    value: d.value,
                    event: e
                });
            })
                .on('mousemove', function (e) { return _this.onMouseMove(e); })
                .on('mouseleave', function () { return _this.onMouseLeave(); })
                .on('click', function (e, d) {
                var group = _this.data[d.datasetIndex].dataset[d.groupIndex].group;
                var group2 = _this.data[d.datasetIndex].dataset[d.groupIndex].group2;
                var group3 = _this.data[d.datasetIndex].dataset[d.groupIndex].group3;
                var value = _this.data[d.datasetIndex].dataset[d.groupIndex].value;
                _this.onClick({
                    datasetIndex: d.datasetIndex,
                    groupIndex: d.groupIndex,
                    group: group,
                    group2: group2,
                    group3: group3,
                    value: value,
                    element: e.target
                });
            });
        }
        else {
            var hasNegativeValues_1 = this.dataTotal.some(function (item) { return item.item.value < 0; });
            var groups = this.svg
                .selectAll('.chart-bar-group')
                .data(this.dataGroups.map(function (item, i) { return ({ group: item, index: i }); }))
                .join('g')
                .attr('class', 'chart-bar-group')
                .attr('transform', function (d) {
                var group;
                if (d.group instanceof moment) {
                    var format = getDateFormatByLookup(_this.dataGroupLookup) || 'lll';
                    group = d.group.format(format);
                }
                else {
                    group = String(d.group);
                }
                return "translate(" + _this.xGroupScale(group) + ",0)";
            });
            groups
                .selectAll('.chart-bar')
                .data(function (d) {
                return _this.data.map(function (item, i) {
                    return {
                        dataset: item,
                        datasetIndex: i,
                        value: item.dataset[d.index].value,
                        group: d.group,
                        groupIndex: d.index
                    };
                });
            })
                .join('rect')
                .attr('class', function (d) {
                var classes = [
                    'chart-bar',
                    "chart-bar_index-" + d.datasetIndex,
                    "chart-bar_group-index-" + d.datasetIndex + "-" + d.groupIndex
                ];
                if (_this.dataClickEnabled) {
                    classes.push('chart-bar_clickable');
                }
                return classes.join(' ');
            })
                .attr('rx', borderRadius)
                .attr('ry', borderRadius)
                .attr('x', function (d) { return _this.xSubgroupScale(d.datasetIndex.toString()); })
                .attr('y', function (d) {
                if (hasNegativeValues_1) {
                    var y = _this.yScale(Math.max(d.value, 0));
                    var height = Math.abs(_this.yScale(0) - _this.yScale(d.value));
                    if (d.value >= 0 && height < minBarHeight) {
                        return _this.yScale(0) - minBarHeight;
                    }
                    else {
                        return y;
                    }
                }
                else {
                    var y = _this.yScale(d.value);
                    var height = _this.height - _this.yScale(d.value);
                    if (d.value >= 0 && height < minBarHeight) {
                        return _this.height - minBarHeight;
                    }
                    else {
                        return y;
                    }
                }
            })
                .attr('width', this.xSubgroupScale.bandwidth())
                .attr('height', function (d) {
                if (hasNegativeValues_1) {
                    var height = Math.abs(_this.yScale(0) - _this.yScale(d.value));
                    return Math.max(height, minBarHeight);
                }
                else {
                    var height = _this.height - _this.yScale(d.value);
                    return Math.max(height, minBarHeight);
                }
            })
                .attr('fill', function (d) {
                var colorHex = getColorHex(d.dataset.color);
                var clr = parseColor(colorHex, '#000');
                return clr.string();
            })
                .attr('fill', function (d) {
                var barGradient = _this.getId("bar-gradient-" + d.datasetIndex);
                return "url(#" + barGradient + ")";
            })
                .on('mouseenter', function (e, d) {
                return _this.onMouseEnter({
                    datasetIndex: d.datasetIndex,
                    group: d.group,
                    groupIndex: d.groupIndex,
                    value: d.value,
                    event: e
                });
            })
                .on('mousemove', function (e) { return _this.onMouseMove(e); })
                .on('mouseleave', function () { return _this.onMouseLeave(); })
                .on('click', function (e, d) {
                var group = _this.data[d.datasetIndex].dataset[d.groupIndex].group;
                var group2 = _this.data[d.datasetIndex].dataset[d.groupIndex].group2;
                var group3 = _this.data[d.datasetIndex].dataset[d.groupIndex].group3;
                var value = _this.data[d.datasetIndex].dataset[d.groupIndex].value;
                _this.onClick({
                    datasetIndex: d.datasetIndex,
                    groupIndex: d.groupIndex,
                    group: group,
                    group2: group2,
                    group3: group3,
                    value: value,
                    element: e.target
                });
            });
        }
    };
    BarChart2Component.prototype.renderGradients = function () {
        var _this = this;
        var gradients = this.svg
            .selectAll('.chart-bar-gradient')
            .data(this.data.map(function (item, i) { return ({ dataset: item, index: i }); }))
            .join('linearGradient')
            .attr('id', function (d) { return _this.getId("bar-gradient-" + d.index); })
            .attr('class', 'chart-bar-gradient');
        if (this.stackSeries) {
            gradients
                .attr('gradientUnits', 'objectBoundingBox')
                .attr('x1', '0%')
                .attr('y1', '100%')
                .attr('x2', '0%')
                .attr('y2', '0%');
        }
        else {
            gradients
                .attr('gradientUnits', 'userSpaceOnUse')
                .attr('x1', 0)
                .attr('y1', this.height)
                .attr('x2', 0)
                .attr('y2', function (d) {
                var maxValue = max(_this.data[d.index].dataset, function (item) { return item.value; });
                return _this.yScale(maxValue);
            });
        }
        gradients
            .selectAll('stop')
            .data(function (d) {
            var colorHex = getColorHex(_this.data[d.index].color);
            var clr = parseColor(colorHex, '#000');
            return [
                { offset: '0%', color: clr.lighten(0.1) },
                { offset: '100%', color: clr.darken(0.1) }
            ];
        })
            .join('stop')
            .attr('offset', function (d) { return d.offset; })
            .attr('stop-color', function (d) { return d.color; });
    };
    BarChart2Component.prototype.initDatasetHover = function () {
        var _this = this;
        this.hoverLegendDatasetIndex$.pipe(untilDestroyed(this)).subscribe(function (hoverIndex) {
            _this.data.forEach(function (item, i) {
                var nodes = _this.svg.selectAll(".chart-bar_index-" + i).nodes();
                if (!isSet(hoverIndex) || i === hoverIndex) {
                    nodes.forEach(function (node) { return node.classList.remove('chart-bar_disabled'); });
                }
                else {
                    nodes.forEach(function (node) { return node.classList.add('chart-bar_disabled'); });
                }
            });
        });
        this.hoverDatasetGroup$.pipe(untilDestroyed(this)).subscribe(function (hoverGroup) {
            _this.getAllDatasetGroups().forEach(function (item) {
                var selector = ".chart-bar_group-index-" + item.datasetIndex + "-" + item.groupIndex;
                var nodes = _this.svg.selectAll(selector).nodes();
                if (!isSet(hoverGroup) || isDatasetGroupEqual(item, hoverGroup)) {
                    nodes.forEach(function (node) { return node.classList.remove('chart-bar_disabled'); });
                }
                else {
                    nodes.forEach(function (node) { return node.classList.add('chart-bar_disabled'); });
                }
            });
        });
    };
    BarChart2Component.prototype.onMouseEnter = function (options) {
        var _this = this;
        if (!this.interactive) {
            return;
        }
        if (event.target.classList.contains('chart-bar_hidden')) {
            return;
        }
        this.dataTooltip.close();
        this.hoverDatasetGroup$.next({ datasetIndex: options.datasetIndex, groupIndex: options.groupIndex });
        var group;
        if (options.group instanceof moment) {
            var format = getDateFormatByLookup(this.dataGroupLookup) || 'lll';
            group = options.group.format(format);
        }
        else {
            group = options.group;
        }
        // let group: string;
        //
        // if (options.group instanceof moment) {
        //   // group = (options.group as moment.Moment).toISOString();
        //   const format = getDateFormatByLookup(this.dataGroupLookup) || 'lll';
        //   group = (options.group as moment.Moment).format(format);
        // } else {
        //   group = String(options.group);
        // }
        //
        // let x: number;
        // let y: number;
        //
        // if (this.stackSeries) {
        //   const groupX = this.xGroupScale(options.group);
        //   const yTo = this.stackSeries[options.datasetIndex][options.groupIndex][1];
        //   x = groupX + this.xGroupScale.bandwidth() * 0.5;
        //   y = this.yScale(yTo);
        // } else {
        //   const groupX = this.xGroupScale(options.group);
        //   const subgroupX = this.xSubgroupScale(options.datasetIndex.toString());
        //   x = groupX + subgroupX + this.xSubgroupScale.bandwidth() * 0.5;
        //   y = this.yScale(options.value);
        // }
        var _a = pointer(options.event, this.el.nativeElement), pointerX = _a[0], pointerY = _a[1];
        this.dataTooltip.show({
            group: group,
            datasets: this.data.map(function (dataset, i) {
                var defaultLabel = _this.data.length > 1 ? "Dataset " + (i + 1) : undefined;
                return {
                    value: dataset.dataset[options.groupIndex].value,
                    valueFormat: dataset.format,
                    label: isSet(dataset.name) ? dataset.name : defaultLabel,
                    color: dataset.color
                };
            }),
            datasetActiveIndex: options.datasetIndex,
            valueFormat: this.percentage ? '0%' : this.yFormat,
            theme: this.theme,
            x: pointerX,
            y: pointerY,
            portalOutlet: this.portalOutlet
        });
    };
    BarChart2Component.prototype.onMouseMove = function (e) {
        if (!this.interactive) {
            return;
        }
        var _a = pointer(e, this.el.nativeElement), pointerX = _a[0], pointerY = _a[1];
        this.dataTooltip.move(pointerX, pointerY, true);
    };
    BarChart2Component.prototype.onMouseLeave = function () {
        if (!this.interactive) {
            return;
        }
        this.hoverDatasetGroup$.next(undefined);
        this.dataTooltip.close();
    };
    BarChart2Component.prototype.onClick = function (options) {
        if (!this.dataClickEnabled) {
            return;
        }
        this.dataClick.emit(options);
    };
    Object.defineProperty(BarChart2Component.prototype, "selectedDatasetCount", {
        get: function () {
            return this.selectedDatasetIndexes ? keys(this.selectedDatasetIndexes).length : 0;
        },
        enumerable: true,
        configurable: true
    });
    BarChart2Component.prototype.toggleSelectedDatasetIndex = function (index) {
        var _this = this;
        if (!this.interactive) {
            return;
        }
        if (!this.selectedDatasetIndexes) {
            this.selectedDatasetIndexes = fromPairs(this.data.map(function (item, i) { return [i, true]; }).filter(function (_a) {
                var i = _a[0];
                return i !== index;
            }));
        }
        else if (this.selectedDatasetIndexes[index]) {
            delete this.selectedDatasetIndexes[index];
        }
        else {
            this.selectedDatasetIndexes[index] = true;
            if (this.selectedDatasetCount === this.data.length) {
                this.selectedDatasetIndexes = undefined;
            }
        }
        this.cd.markForCheck();
        this.data.forEach(function (item, i) {
            var nodes = _this.svg.selectAll(".chart-bar_index-" + i).nodes();
            if (!_this.selectedDatasetIndexes || _this.selectedDatasetIndexes[i]) {
                nodes.forEach(function (node) { return node.classList.remove('chart-bar_hidden'); });
            }
            else {
                nodes.forEach(function (node) { return node.classList.add('chart-bar_hidden'); });
            }
        });
    };
    BarChart2Component.prototype.getAllDatasetGroups = function () {
        return this.data.reduce(function (acc, dataset, d) {
            dataset.dataset.forEach(function (group, g) {
                acc.push({ datasetIndex: d, groupIndex: g });
            });
            return acc;
        }, []);
    };
    BarChart2Component.prototype.onLegendDatasetMouseEnter = function (index) {
        if (!this.interactive) {
            return;
        }
        this.hoverLegendDatasetIndex$.next(index);
    };
    BarChart2Component.prototype.onLegendDatasetMouseLeave = function () {
        if (!this.interactive) {
            return;
        }
        this.hoverLegendDatasetIndex$.next(undefined);
    };
    BarChart2Component.prototype.rerender = function () {
        this.initBounds();
        this.renderYAxis();
        this.fitYAxis();
        this.renderXAxis();
        this.renderBar();
        this.renderGradients();
    };
    BarChart2Component.prototype.onResize = function () {
        this.rerender();
    };
    BarChart2Component.prototype.colorDisplay = function (value) {
        return getColorHexStr(value);
    };
    return BarChart2Component;
}());
export { BarChart2Component };
