var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Task } from '../task';
import { TaskCompleteAction, TaskScenario } from '../task-scenario';
var CustomizeMenuTask = /** @class */ (function (_super) {
    __extends(CustomizeMenuTask, _super);
    function CustomizeMenuTask() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CustomizeMenuTask.prototype.init = function () {
        return new TaskScenario([
            {
                link: [],
                items: [
                    {
                        name: 'enable_customize_mode',
                        tip: {
                            text: 'Enable Customize Mode',
                            selector: '#menu-enable-customize-mode',
                            completeAction: TaskCompleteAction.Click
                        }
                    },
                    {
                        name: 'choose_menu_customization',
                        tip: {
                            text: 'Choose Menu customization',
                            selector: '#customize-menu',
                            completeAction: TaskCompleteAction.Click
                        }
                    },
                    // {
                    //   name: 'open_add_menu_item_popup',
                    //   tip: {
                    //     text: 'Open add Menu item popup',
                    //     selector: '#add-menu-item',
                    //     completeAction: TaskCompleteAction.Hover
                    //   }
                    // },
                    // {
                    //   name: 'add_collection_link',
                    //   tip: {
                    //     text: 'Add Collection Link',
                    //     selector: '#add-collection-link',
                    //     completeAction: TaskCompleteAction.Click
                    //   }
                    // },
                    // {
                    //   name: 'open_menu_item_settings',
                    //   tip: {
                    //     text: 'You added menu item, open its settings now',
                    //     selector: '.menu__secondary .menu-secondary__item-edit',
                    //     completeAction: TaskCompleteAction.Click
                    //   }
                    // },
                    // {
                    //   name: 'enter_title',
                    //   tip: {
                    //     text: 'Enter title',
                    //     selector: 'app-model-link-menu-item-edit [id^=title]',
                    //     completeAction: TaskCompleteAction.Input
                    //   }
                    // },
                    // {
                    //   name: 'choose_model',
                    //   tip: {
                    //     text: 'Choose collection',
                    //     selector: 'app-model-link-menu-item-edit [id^=model]'
                    //   }
                    // },
                    // {
                    //   name: 'choose_icon',
                    //   tip: {
                    //     text: 'Choose icon',
                    //     selector: 'app-model-link-menu-item-edit [id^=icon]'
                    //   }
                    // },
                    // {
                    //   name: 'save_menu_item_changes',
                    //   tip: {
                    //     text: 'Save menu item changes',
                    //     selector: 'app-model-link-menu-item-edit [type="submit"]',
                    //     completeAction: TaskCompleteAction.Click
                    //   }
                    // },
                    {
                        name: 'save_menu_changes',
                        tip: {
                            text: 'Customize menu and save',
                            selector: '#save-menu-customization',
                            completeAction: TaskCompleteAction.Click
                        }
                    },
                    {
                        name: 'finished'
                    }
                ]
            }
        ]);
    };
    return CustomizeMenuTask;
}(Task));
export { CustomizeMenuTask };
