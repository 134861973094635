/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../fields/components/auto-field/auto-field.component.ngfactory";
import * as i2 from "../../../../fields/components/auto-field/auto-field.component";
import * as i3 from "@angular/common";
import * as i4 from "../../../../field-components/components/auto-field-stub/auto-field-stub.component.ngfactory";
import * as i5 from "../../../../field-components/components/auto-field-stub/auto-field-stub.component";
import * as i6 from "../../../../customize/data/view-context-element";
import * as i7 from "../../../../customize/data/view-context";
import * as i8 from "./filter-element-item.component";
import * as i9 from "../../../../customize/services/customize/customize.service";
import * as i10 from "../../../../model-queries/stores/model-description.store";
import * as i11 from "../../../../projects/stores/current-project.store";
import * as i12 from "../../custom-page-popup/custom-page-popup.component";
var styles_FilterElementItemComponent = [];
var RenderType_FilterElementItemComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FilterElementItemComponent, data: {} });
export { RenderType_FilterElementItemComponent as RenderType_FilterElementItemComponent };
function View_FilterElementItemComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "app-auto-field", [], null, null, null, i1.View_AutoFieldComponent_0, i1.RenderType_AutoFieldComponent)), i0.ɵdid(1, 770048, null, 0, i2.AutoFieldComponent, [i0.ChangeDetectorRef], { form: [0, "form"], field: [1, "field"], labelStyleGlobal: [2, "labelStyleGlobal"], labelAdditional: [3, "labelAdditional"], labelAdditionalStyleGlobal: [4, "labelAdditionalStyleGlobal"], elementStyles: [5, "elementStyles"], tooltip: [6, "tooltip"], accentColor: [7, "accentColor"] }, null), i0.ɵpod(2, { value: 0, name: 1 }), i0.ɵpod(3, { value: 0, name: 1 }), i0.ɵpod(4, { value: 0, name: 1 }), i0.ɵpad(5, 3), i0.ɵpad(6, 1), i0.ɵpod(7, { valueEquals: 0, options: 1, classes: 2, theme: 3 }), i0.ɵpod(8, { name: 0, label: 1, field: 2, placeholder: 3, params: 4, reset_enabled: 5 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.form; var currVal_1 = _co.createField(_ck(_v, 8, 0, _co.state.formField.name, _co.state.formField.label, _co.fieldTypes.Select, _co.state.formField.placeholder, _ck(_v, 7, 0, _co.booleanValueEquals, _ck(_v, 5, 0, _ck(_v, 2, 0, null, "Not specified"), _ck(_v, 3, 0, false, "No"), _ck(_v, 4, 0, true, "Yes")), _ck(_v, 6, 0, "select_fill"), true), true)); var currVal_2 = "fieldLabelTextStyle"; var currVal_3 = _co.labelAdditional; var currVal_4 = "fieldLabelAdditionalTextStyle"; var currVal_5 = _co.elementStyles; var currVal_6 = _co.tooltip; var currVal_7 = _co.accentColor; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, null); }
function View_FilterElementItemComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-auto-field", [], null, null, null, i1.View_AutoFieldComponent_0, i1.RenderType_AutoFieldComponent)), i0.ɵdid(1, 770048, null, 0, i2.AutoFieldComponent, [i0.ChangeDetectorRef], { form: [0, "form"], field: [1, "field"], readonly: [2, "readonly"], labelStyleGlobal: [3, "labelStyleGlobal"], labelAdditional: [4, "labelAdditional"], labelAdditionalStyleGlobal: [5, "labelAdditionalStyleGlobal"], elementStyles: [6, "elementStyles"], context: [7, "context"], contextElement: [8, "contextElement"], manualMargin: [9, "manualMargin"], tooltip: [10, "tooltip"], accentColor: [11, "accentColor"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.form; var currVal_1 = _co.state.formField; var currVal_2 = false; var currVal_3 = "fieldLabelTextStyle"; var currVal_4 = _co.labelAdditional; var currVal_5 = "fieldLabelAdditionalTextStyle"; var currVal_6 = _co.elementStyles; var currVal_7 = _co.context; var currVal_8 = _co.viewContextElement; var currVal_9 = true; var currVal_10 = _co.tooltip; var currVal_11 = _co.accentColor; _ck(_v, 1, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11]); }, null); }
function View_FilterElementItemComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FilterElementItemComponent_2)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FilterElementItemComponent_3)), i0.ɵdid(4, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.state.formField.field == _co.fieldTypes.Boolean); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.state.formField.field != _co.fieldTypes.Boolean); _ck(_v, 4, 0, currVal_1); }, null); }
function View_FilterElementItemComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "stub-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.state.formField == null) ? null : _co.state.formField.label); _ck(_v, 1, 0, currVal_0); }); }
function View_FilterElementItemComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.state.formField == null) ? null : _co.state.formField.label); _ck(_v, 1, 0, currVal_0); }); }
function View_FilterElementItemComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "field"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "label", [["class", "field__label"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FilterElementItemComponent_5)), i0.ɵdid(3, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FilterElementItemComponent_6)), i0.ɵdid(5, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 1, "app-auto-field-stub", [], null, null, null, i4.View_AutoFieldStubComponent_0, i4.RenderType_AutoFieldStubComponent)), i0.ɵdid(7, 114688, null, 0, i5.AutoFieldStubComponent, [], { field: [0, "field"], readonly: [1, "readonly"], label: [2, "label"], manualMargin: [3, "manualMargin"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.state.formField == null) ? null : _co.state.formField.label); _ck(_v, 3, 0, currVal_0); var currVal_1 = !((_co.state.formField == null) ? null : _co.state.formField.label); _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.state.formField; var currVal_3 = false; var currVal_4 = false; var currVal_5 = true; _ck(_v, 7, 0, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
export function View_FilterElementItemComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_FilterElementItemComponent_1)), i0.ɵdid(1, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FilterElementItemComponent_4)), i0.ɵdid(3, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.loadingValue && _co.state.formField); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.loadingValue; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_FilterElementItemComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-filter-element-item", [], null, null, null, View_FilterElementItemComponent_0, RenderType_FilterElementItemComponent)), i0.ɵprd(131584, null, i6.ViewContextElement, i6.ViewContextElement, [i7.ViewContext]), i0.ɵdid(2, 770048, null, 0, i8.FilterElementItemComponent, [i9.CustomizeService, i10.ModelDescriptionStore, i11.CurrentProjectStore, i6.ViewContextElement, i0.ChangeDetectorRef, [2, i12.CustomPagePopupComponent]], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var FilterElementItemComponentNgFactory = i0.ɵccf("app-filter-element-item", i8.FilterElementItemComponent, View_FilterElementItemComponent_Host_0, { name: "name", field: "field", labelAdditional: "labelAdditional", tooltip: "tooltip", context: "context", parent: "parent", accentColor: "accentColor", elementStyles: "elementStyles" }, {}, []);
export { FilterElementItemComponentNgFactory as FilterElementItemComponentNgFactory };
