var Frame = /** @class */ (function () {
    function Frame(options) {
        if (options === void 0) { options = {}; }
        this.x = 0;
        this.y = 0;
        this.width = 0;
        this.height = 0;
        this.rotation = 0;
        this.patch(options);
    }
    Frame.prototype.deserialize = function (data) {
        this.x = data['x'];
        this.y = data['y'];
        this.width = data['width'];
        this.height = data['height'];
        this.rotation = data['rotation'];
        return this;
    };
    Frame.prototype.serialize = function () {
        return {
            x: this.x,
            y: this.y,
            width: this.width,
            height: this.height,
            rotation: this.rotation
        };
    };
    Frame.prototype.patch = function (options) {
        for (var _i = 0, _a = Object.keys(options); _i < _a.length; _i++) {
            var key = _a[_i];
            if (this.hasOwnProperty(key)) {
                this[key] = options[key];
            }
        }
        return this;
    };
    Object.defineProperty(Frame.prototype, "top", {
        get: function () {
            return this.y;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Frame.prototype, "right", {
        get: function () {
            return this.x + this.width;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Frame.prototype, "bottom", {
        get: function () {
            return this.y + this.height;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Frame.prototype, "left", {
        get: function () {
            return this.x;
        },
        enumerable: true,
        configurable: true
    });
    return Frame;
}());
export { Frame };
