import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  InjectionToken,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  Type,
  ViewChild
} from '@angular/core';
import { ActivationEnd, Router } from '@angular/router';
import cloneDeep from 'lodash/cloneDeep';
import fromPairs from 'lodash/fromPairs';
import isEqual from 'lodash/isEqual';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, merge, Observable, of, ReplaySubject } from 'rxjs';
import { catchError, delayWhen, filter, map, tap } from 'rxjs/operators';

import { DialogService } from '@common/dialogs';
import { DocumentService } from '@core';
import { ActionDescriptionService, ActionStore } from '@modules/action-queries';
import { UserActivityService, UserActivityType } from '@modules/activities';
import { AnalyticsEvent, AnalyticsEventAction, UniversalAnalyticsService } from '@modules/analytics';
import { ServerRequestError } from '@modules/api';
import { CalendarComponent } from '@modules/calendar';
import {
  CustomizeHandler,
  CustomizeService,
  getListLayoutSettingsClass,
  ListLayoutSettings,
  ListViewSettings,
  ViewContext,
  ViewContextElement,
  ViewContextElementFactory,
  ViewContextElementType,
  ViewContextOutput,
  ViewSettingsService,
  ViewSettingsStore,
  ViewSettingsType
} from '@modules/customize';
import {
  CustomizeBarContext,
  CustomizeBarEditEventType,
  CustomizeBarService,
  ProjectPropertyEditController
} from '@modules/customize-bar';
import { ViewSettingsGeneratorService } from '@modules/customize-generators';
import { ViewSettingsQueries } from '@modules/customize-utils';
import { FieldType, getFieldDescriptionByType, Input as FieldInput } from '@modules/fields';
import { Segment } from '@modules/filters';
import { GridComponent } from '@modules/grid';
import { KanbanBoardComponent } from '@modules/kanban-board';
import { LayoutService, ListLayout, listLayouts, ListLayoutType } from '@modules/layouts';
import { ListContextService } from '@modules/list';
import { ListLayoutComponent } from '@modules/list-components';
import { MapComponent } from '@modules/map';
import { MenuSettingsStore } from '@modules/menu';
import { MetaService } from '@modules/meta';
import { ModelDescriptionService, ModelDescriptionStore } from '@modules/model-queries';
import { ModelDescription } from '@modules/models';
import { ViewContextTokenProvider } from '@modules/parameters-components';
import {
  CurrentEnvironmentStore,
  CurrentProjectStore,
  ProjectGroup,
  ProjectGroupService,
  ProjectProperty,
  ProjectPropertyStore,
  ProjectPropertyType,
  ProjectUser,
  ProjectUserService,
  Resource
} from '@modules/projects';
import { RoutingService } from '@modules/routing';
import { TableComponent } from '@modules/table';
import { TimelineComponent } from '@modules/timeline';
import { CurrentUserStore } from '@modules/users';
import { capitalize, getLocationAppPath, getLocationQueryParams } from '@shared';

export const pagePropertiesToken = new InjectionToken<ViewContextElement>('pagePropertiesToken');
export const listPropertiesToken = new InjectionToken<ViewContextElement>('listPropertiesToken');
export const teamPropertiesToken = new InjectionToken<ViewContextElement>('teamPropertiesToken');
export const userPropertiesToken = new InjectionToken<ViewContextElement>('userPropertiesToken');
export const appPropertiesToken = new InjectionToken<ViewContextElement>('appPropertiesToken');
export const devicePropertiesToken = new InjectionToken<ViewContextElement>('appDeviceToken');

export interface ListPageState {
  viewSettings: ListViewSettings;
}

@Component({
  selector: 'app-list',
  templateUrl: 'list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    LayoutService,
    ListContextService,
    CustomizeBarContext,
    ViewContext,
    ViewContextTokenProvider,
    { provide: pagePropertiesToken, useClass: ViewContextElement },
    { provide: listPropertiesToken, useClass: ViewContextElement },
    { provide: teamPropertiesToken, useClass: ViewContextElement },
    { provide: userPropertiesToken, useClass: ViewContextElement },
    { provide: appPropertiesToken, useClass: ViewContextElement },
    { provide: devicePropertiesToken, useClass: ViewContextElement }
  ]
})
export class ListComponent implements OnInit, OnDestroy, OnChanges, CustomizeHandler<ListPageState>, AfterViewInit {
  @Input() uniqueName: string;
  @Input() modelId: string;
  @Input() params: Object;
  @Input() userActivitiesParams: Object;
  @Output() paramsChanged = new EventEmitter<Object>();

  @ViewChild(TableComponent) tableComponent: TableComponent;
  @ViewChild(KanbanBoardComponent) kanbanBoardComponent: KanbanBoardComponent;
  @ViewChild(MapComponent) mapComponent: MapComponent;
  @ViewChild(CalendarComponent) calendarComponent: CalendarComponent;
  @ViewChild(GridComponent) gridComponent: GridComponent;
  @ViewChild(TimelineComponent) timelineComponent: TimelineComponent;

  viewContextInitialized = new ReplaySubject<void>(1);
  loading = true;
  error: ServerRequestError;
  title: string;
  resource: Resource;
  modelDescription: ModelDescription;
  viewSettings: ListViewSettings;
  initialViewSettings: ListViewSettings;
  layoutTypes = ListLayoutType;
  layout: ListLayout;
  layoutIndex: number;
  contextElements: { element: ViewContextElement; layout: ListLayoutSettings }[] = [];
  viewId: string;

  constructor(
    private cd: ChangeDetectorRef,
    private router: Router,
    private routing: RoutingService,
    private viewContextElementFactory: ViewContextElementFactory,
    private injector: Injector,
    public context: ViewContext,
    @Inject(pagePropertiesToken) private viewContextElementPage: ViewContextElement,
    @Inject(listPropertiesToken) public viewContextElementList: ViewContextElement,
    @Inject(teamPropertiesToken) private viewContextElementTeamProperty: ViewContextElement,
    @Inject(userPropertiesToken) private viewContextElementUserProperty: ViewContextElement,
    @Inject(appPropertiesToken) private viewContextElementAppProperty: ViewContextElement,
    @Inject(devicePropertiesToken) private viewContextElementDeviceProperty: ViewContextElement,
    public currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private modelDescriptionService: ModelDescriptionService,
    private modelDescriptionStore: ModelDescriptionStore,
    public customizeService: CustomizeService,
    private viewSettingsService: ViewSettingsService,
    private viewSettingsStore: ViewSettingsStore,
    private dialogService: DialogService,
    private userActivityService: UserActivityService,
    private analyticsService: UniversalAnalyticsService,
    private metaService: MetaService,
    private actionStore: ActionStore,
    private actionDescriptionService: ActionDescriptionService,
    public layoutService: LayoutService,
    private viewSettingsGeneratorService: ViewSettingsGeneratorService,
    protected customizeBarService: CustomizeBarService,
    protected customizeBarContext: CustomizeBarContext,
    protected menuSettingsStore: MenuSettingsStore,
    private viewSettingsQueries: ViewSettingsQueries,
    private projectUserService: ProjectUserService,
    private currentUserStore: CurrentUserStore,
    private projectGroupService: ProjectGroupService,
    private projectPropertyStore: ProjectPropertyStore,
    private projectPropertyEditController: ProjectPropertyEditController,
    private documentService: DocumentService
  ) {}

  ngAfterViewInit(): void {
    this.initViewContextElements();

    combineLatest(
      this.projectUserService
        .get(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName)
        .pipe(catchError(() => of<ProjectUser[]>([]))),
      this.projectGroupService
        .get(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName)
        .pipe(catchError(() => of<ProjectGroup[]>([]))),
      this.currentUserStore.instance$,
      this.currentProjectStore.instance$,
      this.currentEnvironmentStore.instance$,
      this.projectPropertyStore.get()
    )
      .pipe(untilDestroyed(this))
      .subscribe(
        ([usersProject, groupsProject, currentUser, currentProject, currentEnvironment, projectProperties]) => {
          const userProperties = projectProperties
            ? projectProperties.filter(item => item.type == ProjectPropertyType.User)
            : [];
          const groupProperties = projectProperties
            ? projectProperties.filter(item => item.type == ProjectPropertyType.Group)
            : [];

          this.updateContextUserProperties(userProperties);
          this.updateContextTeamProperties(groupProperties);

          const currentProjectUser = usersProject.find(item => item.user && item.user.uid === currentUser.uid);
          if (currentProjectUser) {
            const value = fromPairs(
              userProperties.map(property => [property.uid, currentProjectUser.getPropertyValue(property)])
            );
            this.viewContextElementUserProperty.setOutputValues(value);
          }

          const currentProjectGroup = currentEnvironment.group
            ? groupsProject.find(item => item.uid == currentEnvironment.group.uid)
            : undefined;
          if (currentProjectGroup) {
            const value = fromPairs(
              groupProperties.map(property => [property.uid, currentProjectGroup.getPropertyValue(property)])
            );
            this.viewContextElementTeamProperty.setOutputValues(value);
          }

          this.viewContextElementAppProperty.patchOutputValues({
            name: currentProject.uniqueName,
            env_name: currentEnvironment.uniqueName
          });

          this.viewContextInitialized.next();
        }
      );

    merge(of({}), this.router.events.pipe(filter(item => item instanceof ActivationEnd)))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.viewContextElementAppProperty.patchOutputValues({
          app_path: getLocationAppPath(),
          query_params: getLocationQueryParams()
        });
      });

    this.documentService.viewportSize$.pipe(untilDestroyed(this)).subscribe(value => {
      const type = this.documentService.getViewportType(value.width);

      this.viewContextElementDeviceProperty.patchOutputValues({
        is_desktop: this.documentService.isDesktopViewportType(type),
        is_mobile: this.documentService.isMobileViewportType(type),
        is_phone: this.documentService.isPhoneViewportType(type),
        is_tablet: this.documentService.isTabletViewportType(type),
        screen_width: value.width,
        screen_height: value.height
      });
    });
  }

  ngOnInit(): void {
    this.customizeService.layoutEnabled$.pipe(untilDestroyed(this)).subscribe(enabled => {
      if (enabled) {
        this.customizeService.setHandler(this);
        this.updateCustomizeHandleInfo();
      } else {
        this.customizeService.unsetHandler(this);
      }
    });

    combineLatest(this.layoutService.currentLayout$, this.layoutService.currentLayoutIndex$)
      .pipe(untilDestroyed(this))
      .subscribe(([layout, index]) => {
        this.layout = layout;
        this.layoutIndex = index;
        this.cd.markForCheck();
      });
  }

  ngOnDestroy(): void {
    this.customizeService.layoutCustomization = undefined;
    this.customizeService.unsetHandler(this);
  }

  onBeforeDestroy(): void {
    this.context.clear();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['modelId'] || changes['uniqueName'] || changes['queryParams']) {
      if (this.modelDescription && this.modelId == this.modelDescription.modelId) {
        this.onLoad();
        this.cd.markForCheck();
      } else if (this.uniqueName || this.modelId) {
        this.getSettings();
      }
    }
  }

  getListComponent(): ListLayoutComponent {
    return (
      this.tableComponent ||
      this.kanbanBoardComponent ||
      this.mapComponent ||
      this.calendarComponent ||
      this.gridComponent ||
      this.timelineComponent
    );
  }

  getSettings() {
    const obs = [];

    obs.push(
      this.modelDescriptionStore.getDetail(this.modelId),
      this.uniqueName
        ? this.viewSettingsStore.getDetailFirst(this.uniqueName, ViewSettingsType.List)
        : this.viewSettingsStore.getListViewSettingsFirst(this.modelId).pipe(
            map(instance => {
              const modelDescription = this.modelDescriptionStore.instance.find(item => item.modelId == this.modelId);

              if (instance && modelDescription && instance.view != ViewSettingsType.Custom) {
                instance.layouts = this.viewSettingsGeneratorService.applyModelListDefaults(
                  instance.layouts,
                  this.resource,
                  modelDescription,
                  true
                );
              }

              return instance;
            })
          ),
      this.actionStore.getFirst()
    );

    this.viewSettings = undefined;
    this.updateLayouts();
    this.loading = true;
    this.error = undefined;
    this.cd.markForCheck();

    const result = combineLatest(obs).pipe(
      delayWhen(() => this.viewContextInitialized),
      tap(([modelDescription, viewSettings, actions]) => {
        this.modelDescription = modelDescription;
        this.viewSettings = viewSettings;

        this.setInitialSettings();

        if (this.uniqueName) {
          this.context.objectType = 'custom_page';
          this.context.objectId = this.uniqueName;
        } else if (this.viewSettings) {
          this.context.objectType = ['model', this.viewSettings.model].join('.');
          this.context.objectId = this.viewSettings.uniqueName;
        }

        if (modelDescription) {
          this.resource = this.currentEnvironmentStore.resources.find(
            item => item.uniqueName == this.modelDescription.resource
          );
          this.userActivityService
            .currentProjectCreateModel(
              this.currentProjectStore.instance,
              this.currentEnvironmentStore.instance,
              UserActivityType.ModelList,
              this.modelDescription.modelId,
              {
                resource: this.modelDescription.resource,
                model: this.modelDescription.model
              }
            )
            .subscribe(() => {});
        }

        this.updateTitle();
        this.updateCustomizeHandleInfo();

        this.viewSettings = cloneDeep(this.viewSettings);
        this.initialViewSettings = cloneDeep(this.viewSettings);
        this.viewId = this.viewSettings.uniqueName
          ? [this.currentProjectStore.instance.uniqueName, 'page', this.viewSettings.uniqueName].join('_')
          : [this.currentProjectStore.instance.uniqueName, this.viewSettings.view, this.viewSettings.model].join('_');

        this.viewContextElementPage.unregister();
        this.viewContextElementPage.initGlobal({ uniqueName: 'page', name: 'Page' });
        this.updateContext();

        this.viewContextElementList.unregister();
        this.viewContextElementList.initElement({ uniqueName: 'list', name: 'List', icon: 'table', allowSkip: true });

        this.updateLayouts();

        this.customizeService.layoutCustomization = {
          subtitle: '',
          title: this.modelDescription
            ? 'Edit Collection \n' + capitalize(this.modelDescription.verboseName)
            : 'Edit Page \n' + capitalize(this.viewSettings.name),
          image: 'list',
          clickEvent: this.modelDescription
            ? [
                AnalyticsEvent.Deprecated.CustomizeInterfaceChooseCollectionView,
                { CollectionID: this.modelDescription.model }
              ]
            : undefined,
          description: [
            { icon: 'versions', label: 'Add and edit Collection Components: Kanban, Map, Calendar, Gallery or Table' },
            { icon: 'fileds', label: 'Customize and rearrange fields ' },
            { icon: 'power', label: 'Add custom action buttons' },
            { icon: 'chip', label: 'Create your own custom view' }
          ],
          modelDescription: this.modelDescription
        };
      })
    );

    result
      .pipe(
        untilDestroyed(this),
        catchError(error => {
          this.loading = false;
          this.error = error;
          this.cd.markForCheck();
          console.log(error);
          return of(undefined);
        })
      )
      .subscribe(
        () => {
          this.loading = false;
          this.onLoad();
          this.cd.markForCheck();
        },
        () => {
          this.loading = false;
          this.cd.markForCheck();
        }
      );

    return result;
  }

  updateContext() {
    this.viewContextElementPage.setOutputs(
      this.viewSettings.parameters.map(item => {
        const fieldDescription = getFieldDescriptionByType(item.field);
        return {
          uniqueName: item.name,
          name: item.verboseName || item.name,
          icon: fieldDescription.icon
        };
      })
    );
    this.viewContextElementPage.setOutputValues(this.params);
  }

  setInitialSettings() {
    if (!this.viewSettings) {
      this.viewSettings = new ListViewSettings();
      this.viewSettings.project = this.currentProjectStore.instance.uniqueName;

      if (this.uniqueName) {
        this.viewSettings.uniqueName = this.uniqueName;
      } else if (this.modelDescription) {
        this.viewSettings.resource = this.modelDescription.resource;
        this.viewSettings.model = this.modelDescription.model;
      }
    }

    if (this.modelDescription) {
      this.viewSettings = this.viewSettingsGeneratorService.applyListDefaults(this.viewSettings);
      this.viewSettings.layouts = this.viewSettingsGeneratorService.applyModelListDefaults(
        this.viewSettings.layouts,
        this.resource,
        this.modelDescription,
        true
      );
    }

    this.customizeService.startTrackChanges();
  }

  onLoad() {}

  updateTitle() {
    if (this.modelDescription) {
      this.title = this.viewSettings.name || this.modelDescription.verboseNamePlural;
      this.metaService.set({ title: this.title });
    } else {
      this.title = this.viewSettings.name;
      this.metaService.set({ title: this.title });
    }

    this.cd.markForCheck();
  }

  updateLayouts() {
    const layouts = this.viewSettings ? this.viewSettings.layouts.map(item => item.type) : [];
    this.layoutService.init(layouts, this.viewId);
    this.updateContextElements();
  }

  updateContextElements() {
    this.contextElements
      .filter((item, i) => this.viewSettings && !this.viewSettings.layouts[i])
      .forEach(item => item.element.unregister());
    this.contextElements = this.viewSettings
      ? this.viewSettings.layouts.map((layout, i) => {
          const existing = this.contextElements[i];
          const listLayout = listLayouts.find(item => item.type == layout.type);
          const fieldFilters = listLayout && listLayout.filters;
          const icon = listLayout ? listLayout.icon : undefined;

          if (existing) {
            existing.element.initInfo(
              {
                uniqueName: i.toString(),
                name: listLayout.label,
                icon: icon,
                fieldFilters: fieldFilters,
                getFieldValue: (field, outputs) => {
                  const component = this.getListComponent();
                  const model = component ? component.getAnyModel() : undefined;
                  return model ? model.getAttribute(field) : undefined;
                }
              },
              true
            );
            return existing;
          }

          const element = this.viewContextElementFactory.create(this.injector);
          element.initElement(
            {
              uniqueName: i.toString(),
              name: listLayout.label,
              icon: icon,
              fieldFilters: fieldFilters,
              getFieldValue: (field, outputs) => {
                const component = this.getListComponent();
                const model = component ? component.getAnyModel() : undefined;
                return model ? model.getAttribute(field) : undefined;
              }
            },
            this.viewContextElementList
          );
          return { element: element, layout: layout };
        })
      : [];
    this.cd.markForCheck();
  }

  initViewContextElements() {
    this.viewContextElementTeamProperty.unregister();
    this.viewContextElementTeamProperty.initGlobal({
      uniqueName: 'team_properties',
      name: 'Team Properties',
      action: {
        label: 'Add Property',
        icon: 'plus',
        handler: () => {
          return this.projectPropertyEditController
            .create({
              type: ProjectPropertyType.Group,
              context: this.context,
              analyticsSource: 'component_input'
            })
            .pipe(
              map(item => {
                return {
                  insertToken: ['team_properties', item.property.uid]
                };
              })
            );
        }
      },
      documentation: 'conditional-visibility/properties'
    });

    this.viewContextElementUserProperty.unregister();
    this.viewContextElementUserProperty.initGlobal({
      uniqueName: 'user_properties',
      name: 'User Properties',
      action: {
        label: 'Add Property',
        icon: 'plus',
        handler: () => {
          return this.projectPropertyEditController
            .create({
              type: ProjectPropertyType.User,
              context: this.context,
              analyticsSource: 'component_input'
            })
            .pipe(
              map(item => {
                return {
                  insertToken: ['user_properties', item.property.uid]
                };
              })
            );
        }
      },
      documentation: 'conditional-visibility/properties'
    });

    this.viewContextElementAppProperty.unregister();
    this.viewContextElementAppProperty.initGlobal({ uniqueName: 'app', name: 'Application' });
    this.viewContextElementAppProperty.setOutputs([
      {
        uniqueName: 'name',
        name: 'App name',
        icon: 'home'
      },
      {
        uniqueName: 'env_name',
        name: 'Environment name',
        icon: 'tag'
      },
      {
        uniqueName: 'app_path',
        name: 'App path',
        icon: 'console'
      },
      {
        uniqueName: 'query_params',
        name: 'URL query parameters',
        icon: 'components',
        fieldType: FieldType.JSON
      }
    ]);

    this.viewContextElementDeviceProperty.unregister();
    this.viewContextElementDeviceProperty.initGlobal({ uniqueName: 'device', name: 'Device' });
    this.viewContextElementDeviceProperty.setOutputs([
      {
        uniqueName: 'is_desktop',
        name: 'Is Desktop',
        icon: 'pages'
      },
      {
        uniqueName: 'is_mobile',
        name: 'Is Mobile',
        icon: 'pages'
      },
      {
        uniqueName: 'is_phone',
        name: 'Is Phone',
        icon: 'pages'
      },
      {
        uniqueName: 'is_tablet',
        name: 'Is Tablet',
        icon: 'pages'
      },
      {
        uniqueName: 'screen_width',
        name: 'Screen width',
        icon: 'align_horizontal_fill'
      },
      {
        uniqueName: 'screen_height',
        name: 'Screen height',
        icon: 'align_vertical_fill'
      }
    ]);
  }

  updateContextUserProperties(properties: ProjectProperty[]) {
    const outputs: ViewContextOutput[] = properties.map(item => {
      return {
        uniqueName: item.uid,
        name: item.name,
        icon: item.fieldDescription.icon,
        ...(item.field
          ? {
              fieldType: item.field.field,
              fieldParams: item.field.params
            }
          : {})
      };
    });
    this.viewContextElementUserProperty.setOutputs(outputs);
  }

  updateContextTeamProperties(properties: ProjectProperty[]) {
    const outputs: ViewContextOutput[] = properties.map(item => {
      return {
        uniqueName: item.uid,
        name: item.name,
        icon: item.fieldDescription.icon,
        ...(item.field
          ? {
              fieldType: item.field.field,
              fieldParams: item.field.params
            }
          : {})
      };
    });

    this.viewContextElementTeamProperty.setOutputs(outputs);
  }

  // updateItemLinks() {
  //   this.itemDetailLink = (model: Model): Link => {
  //     if (!this.modelDescription) {
  //       return;
  //     }
  //
  //     if (!this.modelDescription.getDetailEnabled || !this.currentProjectStore.instance.hasModelPermission(this.modelDescription.modelId, 'r')) {
  //       return;
  //     }
  //
  //     return { link: model.getLink() };
  //   };
  //
  //   this.itemCreateLink = (): Link => {
  //     if (!this.modelDescription) {
  //       return;
  //     }
  //
  //     if (!this.modelDescription.createEnabled || !this.currentProjectStore.instance.hasModelPermission(this.modelDescription.modelId, 'w')) {
  //       return;
  //     }
  //
  //     return { link: this.modelDescription.createLink };
  //   };
  //
  //   this.itemUpdateLink = (model: Model): Link => {
  //     if (!this.modelDescription) {
  //       return;
  //     }
  //
  //     if (!this.modelDescription.updateEnabled || !this.currentProjectStore.instance.hasModelPermission(this.modelDescription.modelId, 'w')) {
  //       return;
  //     }
  //
  //     return { link: model.getLink() };
  //   };
  //
  //   this.itemMassUpdateLink = (model: Model[]): Link => {
  //     if (!this.modelDescription) {
  //       return;
  //     }
  //
  //     if (!this.modelDescription.updateEnabled || !this.currentProjectStore.instance.hasModelPermission(this.modelDescription.modelId, 'w')) {
  //       return;
  //     }
  //
  //     return { link: this.modelDescription.massEditLink };
  //   };
  //
  //   this.itemDeleteLink = (model: Model[]): Link => {
  //     if (!this.modelDescription) {
  //       return;
  //     }
  //
  //     if (!this.modelDescription.deleteEnabled || !this.currentProjectStore.instance.hasModelPermission(this.modelDescription.modelId, 'd')) {
  //       return;
  //     }
  //
  //     return { link: this.modelDescription.deleteLink };
  //   };
  // }

  updateCustomizeHandleInfo() {
    this.customizeService.setHandlerInfo(this, {
      breadcrumbs: this.modelDescription ? ['Customizing Collection'] : undefined,
      title: this.modelDescription ? this.modelDescription.verboseName : undefined,
      page: this.viewSettings
    });
  }

  getChangesState(): ListPageState {
    return {
      viewSettings: cloneDeep(this.viewSettings)
    };
  }

  setChangesState(state: ListPageState) {
    this.viewSettings = cloneDeep(state.viewSettings);
    this.cd.markForCheck();
  }

  isChangesStateEqual(lhs: ListPageState, rhs: ListPageState): boolean {
    const compare = ['params'];
    return isEqual(lhs.viewSettings.serialize(compare), rhs.viewSettings.serialize(compare));
  }

  saveChangesState(state: ListPageState): Observable<ListPageState> {
    return this.viewSettingsService
      .create(
        this.currentProjectStore.instance.uniqueName,
        this.currentEnvironmentStore.instance.uniqueName,
        state.viewSettings
      )
      .pipe(
        delayWhen(() => this.viewSettingsStore.getFirst(true)),
        tap(() => {
          if (state.viewSettings) {
            this.initialViewSettings = cloneDeep(state.viewSettings);
          }

          this.analyticsService.sendEvent(AnalyticsEvent.GA.ModelListLayout, AnalyticsEventAction.Updated);
        }),
        map(() => {
          return state;
        })
      );
  }

  reload(): Observable<any> {
    return this.getSettings();
  }

  onSettingsChanged(index: number, settings: ListLayoutSettings) {
    const viewSettings = cloneDeep(this.viewSettings);

    viewSettings.layouts = viewSettings.layouts.map((item, i) => {
      if (i == index) {
        return settings;
      } else {
        return item;
      }
    });

    this.viewSettings = viewSettings;
    this.updateLayouts();
    this.updateContext();
    this.customizeService.markChanged();
    this.cd.markForCheck();
  }

  addLayout() {
    const viewSettings = cloneDeep(this.viewSettings);
    const listLayout = listLayouts.find(item => !viewSettings.layouts.find(i => i.type == item.type)) || listLayouts[0];
    const cls: Type<ListLayoutSettings> = getListLayoutSettingsClass(listLayout.type);
    const instance = new cls();

    instance.titleInput = new FieldInput().deserializeFromStatic('value', listLayout.label);

    viewSettings.layouts = [...viewSettings.layouts, instance];

    this.context.clear(ViewContextElementType.Element);
    this.viewSettings = viewSettings;
    this.updateLayouts();
    this.cd.detectChanges();
    this.layoutService.currentLayoutIndex = viewSettings.layouts.length - 1;
  }

  onViewSettingsChanged(viewSettings: ListViewSettings) {
    this.viewSettings = viewSettings;
    this.updateTitle();
    this.updateLayouts();
    this.updateContext();
    this.customizeService.markChanged();
    this.cd.markForCheck();
  }

  onSegmentCreated(segment: Segment) {
    if (this.modelDescription) {
      const modelDescription = cloneDeep(this.modelDescription);

      modelDescription.segments.push(segment);

      this.modelDescriptionService
        .update(
          this.currentProjectStore.instance.uniqueName,
          this.currentEnvironmentStore.instance.uniqueName,
          modelDescription
        )
        .pipe(tap(() => this.modelDescriptionStore.getFirst(true)))
        .subscribe();

      this.analyticsService.sendSimpleEvent(AnalyticsEvent.Deprecated.CollectionViewCreateSegment, {
        CollectionID: this.modelDescription.model,
        Label: segment.label
      });
    }
  }

  openHandlerSettings() {
    this.customizeViewSettings();
  }

  customizeViewSettings() {
    this.customizeBarService
      .customizePage({ context: this.customizeBarContext, viewSettings: this.viewSettings, viewContext: this.context })
      .pipe(untilDestroyed(this))
      .subscribe(e => {
        if (e.type == CustomizeBarEditEventType.Created || e.type == CustomizeBarEditEventType.Updated) {
          const instance = e.args['result'] as ListViewSettings;

          this.onViewSettingsChanged(instance);
          this.cd.markForCheck();

          this.analyticsService.sendSimpleEvent(AnalyticsEvent.Page.SuccessfullySetUp, {
            PageID: this.viewSettings.uniqueName
          });
        } else if (e.type == CustomizeBarEditEventType.Deleted) {
          this.viewSettingsService
            .delete(
              this.currentProjectStore.instance.uniqueName,
              this.currentEnvironmentStore.instance.uniqueName,
              this.viewSettings
            )
            .pipe(
              delayWhen(() => this.viewSettingsStore.getFirst(true)),
              delayWhen(() => this.menuSettingsStore.getFirst(true)),
              untilDestroyed(this)
            )
            .subscribe(() => {
              this.routing.navigateApp(this.currentProjectStore.instance.homeLink);

              this.analyticsService.sendSimpleEvent(AnalyticsEvent.Page.Deleted, {
                PageID: this.viewSettings.uniqueName
              });
            });
        }
      });
  }
}
