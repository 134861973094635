<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayPanelClass]="['overlay', 'overlay_horizontal_center']"
  [cdkConnectedOverlayPositions]="popoverPositions"
  [cdkConnectedOverlayOrigin]="origin"
  [cdkConnectedOverlayOpen]="dropdownOpened"
  [cdkConnectedOverlayPush]="true"
  [cdkConnectedOverlayGrowAfterOpen]="true"
  [cdkConnectedOverlayHasBackdrop]="false"
  [cdkConnectedOverlayBackdropClass]="'popover2-backdrop'"
  (backdropClick)="setDropdownOpened(false)"
  (detach)="setDropdownOpened(false)"
>
  <div class="popover2 overlay-content overlay-content_animated">
    <div class="popover2__inner">
      <div class="popover2__viewport popover2__viewport_scrollable" [style.max-height.vh]="80">
        <app-fill-selector
          *ngIf="themeService.isDefaultTheme"
          [control]="control.controls.fill"
          [source]="source"
          [themeSelector]="true"
          (blur)="setDropdownOpened(false)"
        ></app-fill-selector>

        <app-fill-selector
          *ngIf="themeService.isDarkTheme"
          [control]="control.controls.fill_dark"
          [source]="source"
          [themeSelector]="true"
          (blur)="setDropdownOpened(false)"
        ></app-fill-selector>
      </div>
    </div>
  </div>
</ng-template>

<div
  class="sidebar-list-item sidebar-list-item_compact sidebar-list-item_clickable"
  [class.sidebar-list-item_contrast]="contrast"
  [class.sidebar-list-item_disabled]="disabled || dropdownOpened"
  (click)="setDropdownOpened(true)"
  cdkOverlayOrigin
  #origin="cdkOverlayOrigin"
>
  <div class="sidebar-list-item__left">
    <div class="sidebar-list-item__color sidebar-list-item__color_size_l sidebar-list-item__color_square">
      <div
        class="sidebar-list-item__color-background"
        [class.sidebar-list-item__color_transparent]="!fillBackground"
        [style.background]="fillBackground"
      ></div>
    </div>
  </div>

  <div class="sidebar-list-item__main">
    <ng-container *ngIf="valueStr | appIsSet">
      <div class="sidebar-list-item__title">{{ valueStr }}</div>
      <div class="sidebar-list-item__description">
        {{ subtitle }}
        <ng-container *ngIf="additional | appIsSet"> {{ additional }}</ng-container>
      </div>
    </ng-container>

    <ng-container *ngIf="!(valueStr | appIsSet)">
      <div class="sidebar-list-item__title sidebar-list-item__title_secondary">
        {{ subtitle }}
        <ng-container *ngIf="additional | appIsSet"> {{ additional }}</ng-container>
      </div>
    </ng-container>
  </div>

  <div *ngIf="removeEnabled" class="sidebar-list-item__right">
    <div
      class="sidebar-list-item__action sidebar-list-item__action_hover-only icon-bin"
      [appTip]="'Remove'"
      [appTipOptions]="{ side: 'top' }"
      (click)="$event.stopPropagation(); remove.emit()"
    ></div>
  </div>
</div>
