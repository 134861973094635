import { Fill } from './fill';

export class FillSettings {
  public fill: Fill;
  public fillDark: Fill;

  constructor(options: Partial<FillSettings> = {}) {
    Object.assign(this, options);
  }

  deserialize(data: Object): this {
    if (data['fill']) {
      this.fill = new Fill().deserialize(data['fill']);
    }

    if (data['fill_dark']) {
      this.fillDark = new Fill().deserialize(data['fill_dark']);
    }

    return this;
  }

  serialize(): Object {
    return {
      fill: this.fill ? this.fill.serialize() : undefined,
      fill_dark: this.fillDark ? this.fillDark.serialize() : undefined
    };
  }
}
