var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { DialogService } from '@common/dialogs';
import { SidebarCollapseContext } from '@modules/sidebar';
import { ConditionWorkflowStepType, WorkflowStepType } from '@modules/workflow';
import { coerceArray } from '@shared';
import { registerCustomizeWorkflowStepComponent } from '../../../../data/customize-workflow-step-components';
import { CustomizeWorkflowStepComponent } from '../base-customize-workflow-step/base-customize-workflow-step.component';
import { ConditionCustomizeWorkflowStepForm } from './condition-customize-workflow-step.form';
var ConditionCustomizeWorkflowStepComponent = /** @class */ (function (_super) {
    __extends(ConditionCustomizeWorkflowStepComponent, _super);
    function ConditionCustomizeWorkflowStepComponent(form, dialogService, cd) {
        var _this = _super.call(this) || this;
        _this.form = form;
        _this.dialogService = dialogService;
        _this.cd = cd;
        _this.conditionTypes = ConditionWorkflowStepType;
        _this.collapseContext = new SidebarCollapseContext();
        _this.elseHovered$ = new BehaviorSubject(false);
        return _this;
    }
    ConditionCustomizeWorkflowStepComponent.prototype.trackByFn = function (i, item) {
        var path = coerceArray(item.controls.condition.controls.path.value);
        if (path.length) {
            return 'field_' + path.join('__');
        }
        else {
            return i;
        }
    };
    ConditionCustomizeWorkflowStepComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.form.init(this.step, { firstInit: false });
        if (this.workflowEditable) {
            this.form.valueChanges
                .pipe(debounceTime(200), map(function () { return _this.form.submit(); }), untilDestroyed(this))
                .subscribe(function (result) {
                _this.submit(result);
            });
        }
    };
    ConditionCustomizeWorkflowStepComponent.prototype.ngOnDestroy = function () { };
    ConditionCustomizeWorkflowStepComponent.prototype.submit = function (value) {
        if (!value) {
            value = this.form.submit();
        }
        this.stepChange.emit(value);
    };
    ConditionCustomizeWorkflowStepComponent.prototype.addItem = function () {
        var control = this.form.controls.items.createControl();
        this.form.controls.items.push(control);
        this.createdItem = control;
        this.cd.markForCheck();
    };
    ConditionCustomizeWorkflowStepComponent.prototype.removeItem = function (control, i) {
        // this.dialogService
        //   .warning({
        //     title: 'Delete Condition',
        //     description: `Are you sure want to delete <strong>Condition ${i + 1}</strong>?`,
        //     style: 'orange'
        //   })
        //   .pipe(untilDestroyed(this))
        //   .subscribe(result => {
        //     if (result) {
        this.form.controls.items.removeControl(control);
        this.cd.markForCheck();
        //   }
        // });
    };
    ConditionCustomizeWorkflowStepComponent.prototype.dragDropItem = function (event) {
        if (event.previousIndex !== event.currentIndex) {
            moveItemInArray(this.form.controls.items.controls, event.previousIndex, event.currentIndex);
            this.form.controls.items.updateValueAndValidity();
            this.cd.markForCheck();
        }
    };
    return ConditionCustomizeWorkflowStepComponent;
}(CustomizeWorkflowStepComponent));
export { ConditionCustomizeWorkflowStepComponent };
registerCustomizeWorkflowStepComponent(WorkflowStepType.Condition, ConditionCustomizeWorkflowStepComponent);
