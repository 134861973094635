var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { getGradientCss, GradientType } from '@modules/colors';
import { isSet } from '@shared';
import { Frame } from './frame';
import { GradientStop } from './gradient-stop';
import { Point } from './point';
var Gradient = /** @class */ (function () {
    function Gradient(options) {
        if (options === void 0) { options = {}; }
        this.type = GradientType.Linear;
        this.stops = [];
        this.aspectRatio = 1;
        Object.assign(this, options);
    }
    Gradient.prototype.deserialize = function (data) {
        if (data['type']) {
            this.type = data['type'];
        }
        if (data['from']) {
            this.from = new Point().deserialize(data['from']);
        }
        if (data['to']) {
            this.to = new Point().deserialize(data['to']);
        }
        if (data['stops']) {
            this.stops = data['stops'].map(function (item) { return new GradientStop().deserialize(item); });
        }
        if (isSet(data['aspect_ratio'])) {
            this.aspectRatio = data['aspect_ratio'];
        }
        return this;
    };
    Gradient.prototype.serialize = function () {
        return {
            type: this.type,
            from: this.from ? this.from.serialize() : undefined,
            to: this.to ? this.to.serialize() : undefined,
            stops: this.stops.map(function (item) { return item.serialize(); }),
            aspect_ratio: this.aspectRatio
        };
    };
    Gradient.prototype.isSet = function () {
        return isSet(this.type) && isSet(this.from) && isSet(this.to) && this.stops.length > 0;
    };
    Gradient.prototype.css = function (options) {
        if (options === void 0) { options = {}; }
        options = __assign({ frame: new Frame({ width: 1, height: 1 }) }, options);
        var stops = this.stops.map(function (item) {
            return {
                stop: item,
                color: item.cssColor()
            };
        });
        return getGradientCss({
            type: this.type,
            from: this.from,
            to: this.to,
            stops: stops,
            aspectRatio: this.aspectRatio,
            frame: options.frame
        });
    };
    return Gradient;
}());
export { Gradient };
