var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { FormArray } from '@angular/forms';
import isEqual from 'lodash/isEqual';
import range from 'lodash/range';
import { map } from 'rxjs/operators';
import { DisplayFieldType, ParameterField } from '@modules/fields';
import { controlValue, isSet } from '@shared';
import { DisplayFieldControl } from './display-field.control';
var DisplayFieldArray = /** @class */ (function (_super) {
    __extends(DisplayFieldArray, _super);
    function DisplayFieldArray(controls, validatorOrOpts, asyncValidator) {
        if (controls === void 0) { controls = []; }
        return _super.call(this, controls, validatorOrOpts, asyncValidator) || this;
    }
    DisplayFieldArray.prototype.patchValue = function (value, options) {
        this.deserialize(value);
    };
    DisplayFieldArray.prototype.setValue = function (value, options) {
        this.deserialize(value);
    };
    Object.defineProperty(DisplayFieldArray.prototype, "value", {
        get: function () {
            return this.serialize();
        },
        set: function (value) { },
        enumerable: true,
        configurable: true
    });
    DisplayFieldArray.prototype.deserialize = function (value) {
        var _this = this;
        value.forEach(function (item, i) {
            var control = _this.controls[i];
            if (control) {
                control.deserialize(item);
            }
            else {
                _this.appendControl(item);
            }
        });
        this.controls.slice(value.length).forEach(function (item) { return _this.removeControl(item); });
    };
    DisplayFieldArray.prototype.serialize = function (reuseInstance) {
        if (reuseInstance === void 0) { reuseInstance = true; }
        return this.controls.map(function (control) { return control.serialize(reuseInstance); }).filter(function (item) { return isSet(item.name); });
    };
    DisplayFieldArray.prototype.setControls = function (controls) {
        var _this = this;
        this.removeControls();
        controls.forEach(function (item) { return _this.push(item); });
    };
    DisplayFieldArray.prototype.removeControls = function () {
        var _this = this;
        range(this.controls.length).forEach(function () { return _this.removeAt(0); });
    };
    DisplayFieldArray.prototype.removeControl = function (control) {
        var newControls = this.controls.filter(function (item) { return !isEqual(item, control); });
        this.setControls(newControls);
    };
    DisplayFieldArray.prototype.createControl = function (item, value) {
        var control = new DisplayFieldControl();
        if (item) {
            control.deserialize(item);
        }
        if (value) {
            control.patchValue(value);
        }
        control.markAsPristine();
        return control;
    };
    DisplayFieldArray.prototype.prependControl = function (item, value) {
        var control = this.createControl(item, value);
        this.insert(0, control);
        return control;
    };
    DisplayFieldArray.prototype.appendControl = function (item, value) {
        var control = this.createControl(item, value);
        this.push(control);
        return control;
    };
    DisplayFieldArray.prototype.getMoveToTopPosition = function (itemForm) {
        var currentIndex = this.controls.indexOf(itemForm);
        var lastVisibleIndex = this.controls
            .map(function (item, i) { return i < currentIndex && item.controls.visible.value; })
            .lastIndexOf(true);
        return lastVisibleIndex != -1 ? lastVisibleIndex + 1 : currentIndex;
    };
    DisplayFieldArray.prototype.moveToTop = function (itemForm) {
        if (!itemForm.controls.visible.value) {
            itemForm.controls.visible.patchValue(true);
        }
        var currentIndex = this.controls.indexOf(itemForm);
        var newIndex = this.getMoveToTopPosition(itemForm);
        moveItemInArray(this.controls, currentIndex, newIndex);
    };
    DisplayFieldArray.prototype.isToggledAll = function () {
        return this.controls
            .filter(function (item) { return !item.instance || item.instance.type == DisplayFieldType.Base; })
            .every(function (item) { return item.controls.visible.value; });
    };
    DisplayFieldArray.prototype.toggleAll = function () {
        var toggledAll = this.isToggledAll();
        this.controls
            .filter(function (item) { return !item.instance || item.instance.type == DisplayFieldType.Base; })
            .forEach(function (item, i) {
            if (toggledAll) {
                item.controls.visible.patchValue(i == 0);
            }
            else {
                item.controls.visible.patchValue(true);
            }
        });
    };
    DisplayFieldArray.prototype.getParameters = function () {
        return this.value.map(function (item) {
            var parameter = new ParameterField();
            parameter.name = item.name;
            parameter.verboseName = item.verboseName;
            parameter.field = item.field;
            parameter.params = item.params;
            parameter.updateFieldDescription();
            return parameter;
        });
    };
    DisplayFieldArray.prototype.getParameters$ = function () {
        var _this = this;
        return controlValue(this).pipe(map(function () { return _this.getParameters(); }));
    };
    return DisplayFieldArray;
}(FormArray));
export { DisplayFieldArray };
