<mat-menu [class]="theme ? 'mat-menu-panel-theme' : undefined" #dropdown="matMenu">
  <ng-template matMenuContent let-column="column" let-filterEdit="filter_edit">
    <button
      *ngIf="filterableColumns[column.name] && filterEdit"
      mat-menu-item
      class="mat-menu-item-odd"
      (click)="filterEdit.open()"
      [disableRipple]="true"
    >
      <span class="icon-filter_2 choose-items-item__dropdown-icon"></span>
      {{ 'Filter by field' | localize }}
    </button>

    <ng-container *ngIf="isSortable(column)">
      <button
        mat-menu-item
        class="mat-menu-item-odd"
        [class.mat-menu-item-active]="isOrderedAsc(column)"
        [disableRipple]="true"
        (click)="setOrdering(column)"
      >
        <span class="choose-items-item__dropdown-icon icon-filter_down"></span>
        <ng-container *ngIf="isNumeric(column)">{{ 'Sort 1 → 9' | localize }}</ng-container>
        <ng-container *ngIf="!isNumeric(column)">{{ 'Sort A → Z' | localize }}</ng-container>
      </button>

      <button
        mat-menu-item
        class="mat-menu-item-odd"
        [class.mat-menu-item-active]="isOrderedDesc(column)"
        [disableRipple]="true"
        (click)="setOrdering(column, true)"
      >
        <span class="choose-items-item__dropdown-icon icon-filter_up"></span>
        <ng-container *ngIf="isNumeric(column)">{{ 'Sort 9 → 1' | localize }}</ng-container>
        <ng-container *ngIf="!isNumeric(column)">{{ 'Sort Z → A' | localize }}</ng-container>
      </button>
    </ng-container>
  </ng-template>
</mat-menu>

<tr class="table__heading-row">
  <ng-container *ngFor="let column of columns; trackBy: trackByFn; let i = index; let first = first">
    <ng-template #column_content>
      <div class="table__heading-column-inner">
        <div class="table__heading-column-main">
          {{ column.verboseName }}
        </div>

        <div class="table__heading-column-fill"></div>

        <div class="table__heading-column-right">
          <div *ngIf="!isOrdered(column)" class="table__heading-action icon-more"></div>
          <div
            *ngIf="isSortable(column) && isOrdered(column)"
            class="table__heading-sorting table__heading-sorting_active"
            [class.icon-filter_down]="!isOrderedDesc(column)"
            [class.icon-filter_up]="isOrderedDesc(column)"
          ></div>
        </div>

        <div
          class="table__heading-column-handle table__heading-column-handle_position_right"
          [class.table__heading-column-handle_active]="resizeColumnIndex === i"
          appResizable
          [appResizableResizeElement]="false"
          [appResizableHandle]="[resizeTypes.Horizontal]"
          [appResizableHandleBodyClass]="'app-resizable-resizing-col-resize'"
          (resizeStarted)="onResizeStarted(i)"
          (resize)="onResize(i, $event)"
          (resizeFinished)="onResizeFinished(i)"
        ></div>
      </div>
    </ng-template>

    <th
      *ngIf="filterableColumns[column.name] || isSortable(column)"
      class="table__heading-column"
      [attr.colspan]="first ? 2 : null"
      [class.table__heading-column_left-padding]="first"
      [class.table__heading-column_active]="trigger.menuOpen"
      [class.table__heading-column_filterable]="isSortable(column)"
      [class.table__heading-column_interactive]="true"
      [class.table__heading-column_resizing]="resizeColumnIndex === i"
      [class.table__heading-column_disable-interaction]="resizeColumnIndex | appIsSet"
      [matMenuTriggerFor]="dropdown"
      [matMenuTriggerData]="{
        column: column,
        filter_edit: filter_edit
      }"
      [attr.data-field]="column.name"
      #trigger="matMenuTrigger"
      cdkOverlayOrigin
      #origin="cdkOverlayOrigin"
      #heading_column
    >
      <app-filter-edit-overlay
        [dataSource]="dataSource"
        [filters]="filters"
        [field]="[column.name]"
        [origin]="origin"
        [triggerManual]="true"
        [context]="context"
        [contextElement]="contextElement"
        [theme]="theme"
        (filtersUpdated)="filtersUpdated.emit($event)"
        #filter_edit="appFilterEditOverlay"
      ></app-filter-edit-overlay>

      <ng-container *ngTemplateOutlet="column_content"></ng-container>
    </th>

    <th
      *ngIf="!filterableColumns[column.name] && !isSortable(column)"
      class="table__heading-column"
      [attr.colspan]="first ? 2 : null"
      [class.table__heading-column_left-padding]="first"
      [class.table__heading-column_resizing]="resizeColumnIndex === i"
      [class.table__heading-column_disable-interaction]="resizeColumnIndex | appIsSet"
      [attr.data-field]="column.name"
      #heading_column
    >
      <ng-container *ngTemplateOutlet="column_content"></ng-container>
    </th>
  </ng-container>

  <th *ngIf="settings.rowActions.length" class="table__heading-column">
    <div class="table__heading-column-inner">{{ 'Actions' | localize }}</div>
  </th>
</tr>
