var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import pickBy from 'lodash/pickBy';
import { isSet } from '@shared';
// TODO: Refactor imports
import { ParameterValue } from '../../fields/data/parameter-value';
export var SSOType;
(function (SSOType) {
    SSOType["SAML2"] = "saml2";
    SSOType["OAuth2"] = "oauth2";
    SSOType["Custom"] = "custom";
    SSOType["JetBridge"] = "jet_bridge";
})(SSOType || (SSOType = {}));
export var SharedCustomSSOType;
(function (SharedCustomSSOType) {
    SharedCustomSSOType["Firebase"] = "firebase";
    SharedCustomSSOType["Supabase"] = "supabase";
})(SharedCustomSSOType || (SharedCustomSSOType = {}));
export function getSSOSettingsTypeLabel(type) {
    if (type == SSOType.OAuth2) {
        return 'OAuth2';
    }
    else if (type == SSOType.SAML2) {
        return 'SAML2';
    }
    else if (type == SSOType.Custom) {
        return 'Custom authentication';
    }
    else if (type == SSOType.JetBridge) {
        return 'Jet Bridge';
    }
}
var SSOSettings = /** @class */ (function () {
    function SSOSettings() {
        this.publicParams = {};
        this.params = {};
        this.active = true;
    }
    SSOSettings.prototype.deserialize = function (data) {
        if (!(this instanceof OAuth2SSOSettings) && data['type'] == SSOType.OAuth2) {
            return new OAuth2SSOSettings().deserialize(data);
        }
        else if (!(this instanceof SAML2SSOSettings) && data['type'] == SSOType.SAML2) {
            return new SAML2SSOSettings().deserialize(data);
        }
        else if (!(this instanceof CustomSSOSettings) && data['type'] == SSOType.Custom) {
            return new CustomSSOSettings().deserialize(data);
        }
        else if (!(this instanceof JetBridgeSSOSettings) && data['type'] == SSOType.JetBridge) {
            return new JetBridgeSSOSettings().deserialize(data);
        }
        this.uid = data['uid'];
        this.domain = data['domain'];
        this.name = data['name'];
        this.type = data['type'];
        this.defaultGroup = data['default_group'];
        this.logoutUrl = data['logout_url'];
        this.ordering = data['ordering'];
        if (isSet(data['active'])) {
            this.active = data['active'];
        }
        if (data['public_params']) {
            this.publicParams = data['public_params'];
        }
        if (data['params']) {
            this.params = data['params'];
        }
        this.image = this.publicParams['image'];
        return this;
    };
    SSOSettings.prototype.serialize = function (fields) {
        this.publicParams['image'] = this.image;
        var data = {
            domain: this.domain,
            name: this.name,
            type: this.type,
            default_group: this.defaultGroup,
            public_params: this.publicParams,
            params: this.params,
            active: this.active,
            ordering: this.ordering
        };
        if (fields) {
            data = pickBy(data, function (v, k) { return fields.includes(k); });
        }
        return data;
    };
    Object.defineProperty(SSOSettings.prototype, "link", {
        get: function () {
            return ['project', 'sso', this.uid];
        },
        enumerable: true,
        configurable: true
    });
    SSOSettings.prototype.getTypeLabel = function () {
        return getSSOSettingsTypeLabel(this.type);
    };
    SSOSettings.prototype.getSecretTokenContext = function () {
        if ([SSOType.OAuth2, SSOType.JetBridge].includes(this.type)) {
            return {
                name: 'access_token',
                label: 'access token'
            };
        }
        else if ([SSOType.Custom].includes(this.type)) {
            return {
                name: 'token',
                label: 'token'
            };
        }
    };
    SSOSettings.prototype.isPaid = function () {
        return isPaidSSOSettings({ type: this.type });
    };
    return SSOSettings;
}());
export { SSOSettings };
var OAuth2SSOSettings = /** @class */ (function (_super) {
    __extends(OAuth2SSOSettings, _super);
    function OAuth2SSOSettings() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    OAuth2SSOSettings.prototype.deserialize = function (data) {
        _super.prototype.deserialize.call(this, data);
        this.workflowsProject = data['workflows_project'];
        this.workflowsEnvironment = data['workflows_environment'];
        this.userDetailsWorkflowData = data['user_details_workflow'];
        // TODO: Workaround for circular import
        // if (data['user_details_workflow']) {
        //   this.userDetailsWorkflow = new Workflow().deserialize(data['user_details_workflow']);
        // }
        return this;
    };
    OAuth2SSOSettings.prototype.serialize = function (fields) {
        var data = {
            workflows_project: this.workflowsProject,
            workflows_environment: this.workflowsEnvironment,
            user_details_workflow: this.userDetailsWorkflow ? this.userDetailsWorkflow.serialize() : null
        };
        if (fields) {
            data = pickBy(data, function (v, k) { return fields.includes(k); });
        }
        return __assign({}, _super.prototype.serialize.call(this, fields), data);
    };
    OAuth2SSOSettings.prototype.isPaid = function () {
        return isPaidSSOSettings({ type: this.type, oauth2Backend: this.params['backend'] });
    };
    return OAuth2SSOSettings;
}(SSOSettings));
export { OAuth2SSOSettings };
var SAML2SSOSettings = /** @class */ (function (_super) {
    __extends(SAML2SSOSettings, _super);
    function SAML2SSOSettings() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SAML2SSOSettings.prototype.deserialize = function (data) {
        _super.prototype.deserialize.call(this, data);
        this.metadata = this.params['metadata'];
        this.entityId = this.params['entity_id'];
        return this;
    };
    SAML2SSOSettings.prototype.serialize = function (fields) {
        var data = _super.prototype.serialize.call(this, fields);
        if (data['params']) {
            delete data['params'];
        }
        data['metadata'] = this.metadataFile;
        data['entity_id'] = this.entityId;
        if (fields) {
            data = pickBy(data, function (v, k) { return fields.includes(k); });
        }
        return data;
    };
    return SAML2SSOSettings;
}(SSOSettings));
export { SAML2SSOSettings };
var CustomSSOSettings = /** @class */ (function (_super) {
    __extends(CustomSSOSettings, _super);
    function CustomSSOSettings() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.parameters = [];
        return _this;
    }
    CustomSSOSettings.prototype.deserialize = function (data) {
        _super.prototype.deserialize.call(this, data);
        this.workflowsProject = data['workflows_project'];
        this.workflowsEnvironment = data['workflows_environment'];
        this.sharedCustomSSO = data['shared_custom_sso'];
        this.authWorkflowData = data['auth_workflow'];
        this.refreshTokenWorkflowData = data['refresh_token_workflow'];
        if (data['parameters']) {
            this.parameters = data['parameters'].map(function (item) { return new ParameterValue().deserialize(item); });
        }
        // TODO: Workaround for circular import
        // if (data['auth_workflow']) {
        //   this.authWorkflow = new Workflow().deserialize(data['auth_workflow']);
        // }
        //
        // if (data['refresh_token_workflow']) {
        //   this.refreshTokenWorkflow = new Workflow().deserialize(data['refresh_token_workflow']);
        // }
        return this;
    };
    CustomSSOSettings.prototype.serialize = function (fields) {
        var data = {
            workflows_project: this.workflowsProject,
            workflows_environment: this.workflowsEnvironment,
            shared_custom_sso: this.sharedCustomSSO,
            parameters: this.parameters.map(function (item) { return item.serialize(); }),
            auth_workflow: this.authWorkflow ? this.authWorkflow.serialize() : null,
            refresh_token_workflow: this.refreshTokenWorkflow ? this.refreshTokenWorkflow.serialize() : null
        };
        if (fields) {
            data = pickBy(data, function (v, k) { return fields.includes(k); });
        }
        return __assign({}, _super.prototype.serialize.call(this, fields), data);
    };
    CustomSSOSettings.prototype.getTypeLabel = function () {
        if (isSet(this.sharedCustomSSO)) {
            return 'Built-in authentication';
        }
        else {
            return _super.prototype.getTypeLabel.call(this);
        }
    };
    CustomSSOSettings.prototype.isPaid = function () {
        return isPaidSSOSettings({ type: this.type, sharedCustomSSO: this.sharedCustomSSO });
    };
    return CustomSSOSettings;
}(SSOSettings));
export { CustomSSOSettings };
var JetBridgeSSOSettings = /** @class */ (function (_super) {
    __extends(JetBridgeSSOSettings, _super);
    function JetBridgeSSOSettings() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    JetBridgeSSOSettings.prototype.deserialize = function (data) {
        _super.prototype.deserialize.call(this, data);
        this.url = this.publicParams['url'];
        return this;
    };
    JetBridgeSSOSettings.prototype.serialize = function (fields) {
        this.publicParams = JSON.stringify({
            url: this.url
        });
        return _super.prototype.serialize.call(this, fields);
    };
    return JetBridgeSSOSettings;
}(SSOSettings));
export { JetBridgeSSOSettings };
export function createSSOSettings(type) {
    if (type == SSOType.SAML2) {
        return new SAML2SSOSettings();
    }
    else if (type == SSOType.JetBridge) {
        return new JetBridgeSSOSettings();
    }
    else if (type == SSOType.Custom) {
        return new CustomSSOSettings();
    }
    else {
        return new SSOSettings();
    }
}
export function isPaidSSOSettings(options) {
    if (isSet(options.sharedCustomSSO) &&
        [SharedCustomSSOType.Firebase, SharedCustomSSOType.Supabase].includes(options.sharedCustomSSO)) {
        return false;
    }
    return true;
}
