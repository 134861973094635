var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Injector } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import isEqual from 'lodash/isEqual';
import range from 'lodash/range';
import { map, tap } from 'rxjs/operators';
import { AppFormGroup, FormUtils } from '@common/form-utils';
import { PopupService } from '@common/popups';
import { ServerRequestError } from '@modules/api';
import { MenuGeneratorService } from '@modules/menu';
import { ProjectSettingsService } from '@modules/project-settings';
import { ProjectTokenService, ResourceType, SecretTokenService } from '@modules/projects';
import { AirtableAuthType, AirtableGeneratorService } from '@modules/resource-generators';
import { AirtableTableViewType, ResourceControllerService } from '@modules/resources';
import { ascComparator, isSet } from '@shared';
import { BaseResourceSettingsForm } from '../base-resource-settings/base-resource-settings.form';
var AirtableTableControl = /** @class */ (function (_super) {
    __extends(AirtableTableControl, _super);
    function AirtableTableControl(table, state) {
        var _this = _super.call(this, {
            view: new FormControl(isSet(state.view) ? state.view : undefined),
            active: new FormControl(isSet(state.active) ? state.active : false)
        }) || this;
        _this.viewOptions = [];
        _this.table = table;
        _this.viewOptions = table.views.map(function (item) {
            var icon;
            if (item.type == AirtableTableViewType.Grid) {
                icon = 'table';
            }
            else if (item.type == AirtableTableViewType.Form) {
                icon = 'bills';
            }
            else if (item.type == AirtableTableViewType.Calendar) {
                icon = 'calendar';
            }
            else if (item.type == AirtableTableViewType.Gallery) {
                icon = 'blocks';
            }
            else if (item.type == AirtableTableViewType.Kanban) {
                icon = 'versions';
            }
            else if (item.type == AirtableTableViewType.Timeline) {
                icon = 'time';
            }
            else if (item.type == AirtableTableViewType.Gantt) {
                icon = 'fileds';
            }
            return {
                value: item.id,
                name: item.name,
                icon: icon
            };
        });
        return _this;
    }
    AirtableTableControl.prototype.setActive = function (value) {
        this.controls.active.patchValue(value);
        this.controls.active.markAsTouched();
    };
    AirtableTableControl.prototype.toggleActive = function () {
        this.setActive(!this.controls.active.value);
    };
    return AirtableTableControl;
}(FormGroup));
export { AirtableTableControl };
export function validateAirtableArray() {
    return function (control) {
        var selected = control.controls.filter(function (item) { return item.controls.active.value; }).length;
        if (!selected) {
            return { local: ['No tables selected'] };
        }
    };
}
var AirtableTableArray = /** @class */ (function (_super) {
    __extends(AirtableTableArray, _super);
    function AirtableTableArray(controls) {
        return _super.call(this, controls, validateAirtableArray()) || this;
    }
    AirtableTableArray.prototype.init = function (tables) {
        var _this = this;
        this.removeControls();
        tables
            .sort(function (lhs, rhs) { return ascComparator(String(lhs.name).toUpperCase(), String(rhs.name).toUpperCase()); })
            .forEach(function (table) {
            var control = _this.appendControl(table);
            if (_this.initialValue) {
                var initialTableValue = _this.initialValue.find(function (item) { return item.id == table.id; });
                control.controls.active.patchValue(!!initialTableValue);
                if (initialTableValue) {
                    control.controls.view.patchValue(initialTableValue.view);
                }
            }
            else {
                control.controls.active.patchValue(true);
            }
        });
        this.updateValueAndValidity();
        this.markAsPristine();
    };
    AirtableTableArray.prototype.setControls = function (controls) {
        var _this = this;
        this.removeControls();
        controls.forEach(function (item) { return _this.push(item); });
    };
    AirtableTableArray.prototype.removeControls = function () {
        var _this = this;
        range(this.controls.length).forEach(function () { return _this.removeAt(0); });
    };
    AirtableTableArray.prototype.removeControl = function (control) {
        var newControls = this.controls.filter(function (item) { return !isEqual(item, control); });
        this.setControls(newControls);
    };
    AirtableTableArray.prototype.createControl = function (table) {
        var control = new AirtableTableControl(table, {
            view: table.views.length ? table.views[0].id : undefined
        });
        control.markAsPristine();
        return control;
    };
    AirtableTableArray.prototype.appendControl = function (table) {
        var control = this.createControl(table);
        this.push(control);
        return control;
    };
    AirtableTableArray.prototype.getActiveControls = function () {
        return this.controls.filter(function (item) { return item.controls.active.value; });
    };
    AirtableTableArray.prototype.isAllActive = function () {
        return this.controls.every(function (item) { return item.controls.active.value; });
    };
    AirtableTableArray.prototype.isAnyActive = function () {
        return this.controls.some(function (item) { return item.controls.active.value; });
    };
    AirtableTableArray.prototype.setAllActive = function (value) {
        this.controls.forEach(function (item) { return item.setActive(value); });
    };
    AirtableTableArray.prototype.toggleAllActive = function () {
        if (this.isAllActive()) {
            this.setAllActive(false);
        }
        else {
            this.setAllActive(true);
        }
    };
    return AirtableTableArray;
}(FormArray));
export { AirtableTableArray };
export function validateAuthControl(type) {
    return function (control) {
        var parent = control.parent;
        if (!parent) {
            return;
        }
        var authType = parent.controls['auth_type'].value;
        if (authType != type) {
            return;
        }
        if (!control.value) {
            return { required: true };
        }
    };
}
var Form = /** @class */ (function (_super) {
    __extends(Form, _super);
    function Form() {
        return _super.call(this, {
            auth_type: new FormControl(AirtableAuthType.OAuth, Validators.required),
            access_token: new FormControl(null, validateAuthControl(AirtableAuthType.OAuth)),
            params: new FormControl(null, validateAuthControl(AirtableAuthType.OAuth)),
            personal_access_token: new FormControl(null, validateAuthControl(AirtableAuthType.PersonalAccessToken)),
            base: new FormControl(null, Validators.required),
            choose_tables: new FormControl(false),
            sync: new FormControl(true),
            tables: new AirtableTableArray([])
        }) || this;
    }
    return Form;
}(AppFormGroup));
export { Form };
var AirtableResourceSettingsForm = /** @class */ (function (_super) {
    __extends(AirtableResourceSettingsForm, _super);
    function AirtableResourceSettingsForm(airtableGeneratorService, resourceControllerService, secretTokenService, formUtils, projectSettingsService, projectTokenService, popupService, menuGeneratorService, injector) {
        var _this = _super.call(this, secretTokenService, formUtils, projectSettingsService, projectTokenService, popupService, menuGeneratorService, injector) || this;
        _this.airtableGeneratorService = airtableGeneratorService;
        _this.resourceControllerService = resourceControllerService;
        _this.secretTokenService = secretTokenService;
        _this.formUtils = formUtils;
        _this.projectSettingsService = projectSettingsService;
        _this.projectTokenService = projectTokenService;
        _this.popupService = popupService;
        _this.menuGeneratorService = menuGeneratorService;
        _this.injector = injector;
        _this.tokenNameOAuth = 'oauth_access_token';
        _this.legacyOptions = false;
        _this.form = new Form();
        return _this;
    }
    AirtableResourceSettingsForm.prototype.initResourceValue = function () {
        var _this = this;
        return this.airtableGeneratorService.getParamsOptions(this.project, this.environment, this.resource).pipe(map(function (result) {
            _this.legacyOptions = result.auth_type == AirtableAuthType.ApiKey;
            _this.form.patchValue({
                auth_type: result.auth_type,
                access_token: result.access_token,
                params: result.token_params,
                personal_access_token: result.personal_access_token,
                base: result.base,
                sync: !!_this.resource.isSynced()
            });
            _this.tables.initialValue = result.tables;
        }));
    };
    Object.defineProperty(AirtableResourceSettingsForm.prototype, "tables", {
        get: function () {
            return this.form.controls['tables'];
        },
        enumerable: true,
        configurable: true
    });
    AirtableResourceSettingsForm.prototype.getOptions = function () {
        return {
            auth_type: this.form.value['auth_type'] || AirtableAuthType.OAuth,
            access_token: this.form.value['access_token'],
            token_params: this.form.value['params'],
            personal_access_token: this.form.value['personal_access_token'],
            base: this.form.value['base'],
            tables: this.tables.getActiveControls().map(function (item) {
                return {
                    id: item.table.id,
                    view: item.controls.view.value
                };
            })
        };
    };
    AirtableResourceSettingsForm.prototype.isOptionsValid = function () {
        return this.airtableGeneratorService.isOptionsValid(this.getOptions());
    };
    AirtableResourceSettingsForm.prototype.isAuthValid = function () {
        if (this.form.controls['auth_type'].value == AirtableAuthType.OAuth) {
            return isSet(this.form.controls['access_token'].value) && isSet(this.form.controls['params'].value);
        }
        else if (this.form.controls['auth_type'].value == AirtableAuthType.PersonalAccessToken) {
            return isSet(this.form.controls['personal_access_token'].value);
        }
        else {
            return false;
        }
    };
    AirtableResourceSettingsForm.prototype.isBaseValid = function () {
        return this.isAuthValid() && isSet(this.form.controls['base'].value);
    };
    AirtableResourceSettingsForm.prototype.discoverTables = function () {
        var _this = this;
        var options = this.getOptions();
        var controller = this.resourceControllerService.get(ResourceType.Airtable);
        var accessToken = options.auth_type == AirtableAuthType.PersonalAccessToken ? options.personal_access_token : options.access_token;
        return controller.getBaseTables({ base: options.base, accessToken: accessToken, key: options.key }).pipe(tap(function (result) {
            if (!result.tables.length) {
                throw new ServerRequestError('Base does not have any valid Tables');
            }
            _this.tables.init(result.tables);
        }));
    };
    AirtableResourceSettingsForm.prototype.getParams = function () {
        var _this = this;
        var options = this.getOptions();
        return this.airtableGeneratorService.generateParams(this.project, this.environment, this.typeItem, options).pipe(map(function (result) {
            return __assign({}, result, { resourceName: _this.resourceForm.value['name'], sync: _this.form.value['sync'], syncModelDescriptions: _this.getParamsSyncModelDescriptions(result) });
        }));
    };
    return AirtableResourceSettingsForm;
}(BaseResourceSettingsForm));
export { AirtableResourceSettingsForm };
