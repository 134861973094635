var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { CdkConnectedOverlay } from '@angular/cdk/overlay';
import { AfterViewInit, ChangeDetectorRef, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivationEnd, Router } from '@angular/router';
import * as Color from 'color';
import clamp from 'lodash/clamp';
import toPairs from 'lodash/toPairs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, merge, of, timer } from 'rxjs';
import { debounce, debounceTime, filter, map, switchMap } from 'rxjs/operators';
import { getColorHex, isLightColor } from '@modules/colors';
import { applyParamInput$ } from '@modules/fields';
import { ImageMenuItem, ImageMenuItemOption, MenuGeneratorService, MenuItemActionService } from '@modules/menu';
import { CurrentProjectStore } from '@modules/projects';
import { CurrentUserStore } from '@modules/users';
import { deployUrl, isSet } from '@shared';
import { BaseMenuItemComponent } from '../base-menu-item/base-menu-item.component';
import { dropdownPositionsHorizontal, dropdownPositionsVertical } from '../menu-item/menu-item.component';
var ImageMenuItemComponent = /** @class */ (function (_super) {
    __extends(ImageMenuItemComponent, _super);
    function ImageMenuItemComponent(currentProjectStore, currentUserStore, menuGeneratorService, menuItemActionService, sanitizer, router, cd) {
        var _this = _super.call(this) || this;
        _this.currentProjectStore = currentProjectStore;
        _this.currentUserStore = currentUserStore;
        _this.menuGeneratorService = menuGeneratorService;
        _this.menuItemActionService = menuItemActionService;
        _this.sanitizer = sanitizer;
        _this.router = router;
        _this.cd = cd;
        _this.imageColorLight = false;
        _this.padding = 5;
        _this.textSize = 22;
        _this.children = [];
        _this.menuItem$ = new BehaviorSubject(undefined);
        _this.menuItemHovered$ = new BehaviorSubject(undefined);
        _this.menuDropdownHovered$ = new BehaviorSubject(false);
        _this.menuChildDropdownsOpened$ = new BehaviorSubject([]);
        _this.dropdownOpened = false;
        _this.popoverPositions = [];
        _this.menuDropdownSubscription = [];
        return _this;
    }
    ImageMenuItemComponent.prototype.trackMenuItemFn = function (i, item) {
        return item.id;
    };
    ImageMenuItemComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.menuItem$.next(this.menuItem);
        this.updateChildren();
        this.updateImage();
        this.imageColorLight = isSet(this.menuItem.imageColor) ? isLightColor(this.menuItem.imageColor) : undefined;
        this.imageStyle = this.getStyleVars(this.menuItem.imageColor);
        this.updateTextSize();
        this.updatePadding();
        this.updateLink();
        this.initMenuDropdown();
        this.menuItem$
            .pipe(switchMap(function (menuItem) {
            if (!menuItem.titleInput || !menuItem.titleInput.isSet()) {
                return of(undefined);
            }
            return applyParamInput$(menuItem.titleInput, {
                context: _this.context,
                defaultValue: ''
            });
        }), untilDestroyed(this))
            .subscribe(function (value) {
            _this.title = value;
            _this.cd.markForCheck();
        });
        this.menuItem$
            .pipe(switchMap(function (menuItem) {
            if (!menuItem.subtitleInput || !menuItem.subtitleInput.isSet()) {
                return of(undefined);
            }
            return applyParamInput$(menuItem.subtitleInput, {
                context: _this.context,
                defaultValue: ''
            });
        }), untilDestroyed(this))
            .subscribe(function (value) {
            _this.subtitle = value;
            _this.cd.markForCheck();
        });
        this.popoverPositions = this.childrenVertical ? dropdownPositionsVertical : dropdownPositionsHorizontal;
        this.cd.markForCheck();
    };
    ImageMenuItemComponent.prototype.ngOnDestroy = function () { };
    ImageMenuItemComponent.prototype.ngOnChanges = function (changes) {
        if (changes.menuItem && !changes.menuItem.firstChange) {
            this.menuItem$.next(this.menuItem);
            this.updateChildren();
            this.updateImage();
            this.imageColorLight = isLightColor(this.menuItem.imageColor);
            this.imageStyle = this.getStyleVars(this.menuItem.imageColor);
            this.updateTextSize();
            this.updatePadding();
            this.updateLink();
            this.initMenuDropdown();
        }
    };
    ImageMenuItemComponent.prototype.ngAfterViewInit = function () {
        this.setPositionObserver();
    };
    ImageMenuItemComponent.prototype.updateChildren = function () {
        this.children = this.menuGeneratorService.cleanMenuItemsAppMode(this.menuItem.children);
        this.cd.markForCheck();
    };
    ImageMenuItemComponent.prototype.updateImage = function () {
        var _this = this;
        if (this.imageSubscription) {
            this.imageSubscription.unsubscribe();
            this.imageSubscription = undefined;
        }
        this.imageSubscription = combineLatest(this.currentProjectStore.get(), this.currentUserStore.get())
            .pipe(switchMap(function (_a) {
            var project = _a[0], user = _a[1];
            var imageOptions = [
                {
                    value: ImageMenuItemOption.UserPhoto,
                    name: 'Current user photo',
                    icon: 'human_being',
                    data: {
                        url: user && isSet(user.photo) ? user.photo : deployUrl('/assets/images/no-photo.svg')
                    }
                },
                {
                    value: ImageMenuItemOption.ProjectLogo,
                    name: 'App logo',
                    icon: 'image',
                    data: {
                        url: project ? project.logo : undefined,
                        initials: project ? project.initials : undefined
                    }
                }
            ];
            if (isSet(_this.menuItem.imageCustom)) {
                return of({
                    url: _this.menuItem.imageCustom
                });
            }
            else if (isSet(_this.menuItem.imageOption)) {
                var logoOption = imageOptions.find(function (item) { return item.value == _this.menuItem.imageOption; });
                if (logoOption) {
                    return of({
                        url: logoOption.data.url,
                        initials: logoOption.data.initials
                    });
                }
            }
            return of(undefined);
        }), untilDestroyed(this))
            .subscribe(function (value) {
            _this.imageUrl = value ? value.url : undefined;
            _this.imageInitials = value ? value.initials : undefined;
            _this.cd.markForCheck();
        });
    };
    ImageMenuItemComponent.prototype.getDarkColor = function (color) {
        if (!isSet(color)) {
            color = 'white';
        }
        try {
            var colorHex = getColorHex(color);
            var clr = Color(colorHex);
            return clr.darken(0.6).string();
        }
        catch (e) { }
    };
    ImageMenuItemComponent.prototype.getStyleVars = function (color) {
        var darkColor = this.getDarkColor(color);
        var vars = {};
        if (darkColor) {
            vars['dark-color'] = darkColor;
        }
        var styles = toPairs(vars)
            .map(function (_a) {
            var k = _a[0], v = _a[1];
            return "--" + k + ": " + v;
        })
            .join(';');
        return this.sanitizer.bypassSecurityTrustStyle(styles);
    };
    ImageMenuItemComponent.prototype.updateTextSize = function () {
        var imageMinSize = 16;
        var imageMaxSize = 50;
        var imageDefaultSize = 32;
        var imageSize = isSet(this.menuItem.imageSize)
            ? clamp(this.menuItem.imageSize, imageMinSize, imageMaxSize)
            : imageDefaultSize;
        var minValue = 10;
        var maxValue = 28;
        var multiplier = (imageSize - imageMinSize) / (imageMaxSize - imageMinSize);
        this.textSize = minValue + (maxValue - minValue) * multiplier;
        this.cd.markForCheck();
    };
    ImageMenuItemComponent.prototype.updatePadding = function () {
        var imageMinSize = 16;
        var imageMaxSize = 50;
        var imageDefaultSize = 32;
        var imageSize = isSet(this.menuItem.imageSize)
            ? clamp(this.menuItem.imageSize, imageMinSize, imageMaxSize)
            : imageDefaultSize;
        var minValue = 3;
        var maxValue = 8;
        var multiplier = (imageSize - imageMinSize) / (imageMaxSize - imageMinSize);
        this.padding = Math.round(minValue + (maxValue - minValue) * multiplier);
        this.cd.markForCheck();
    };
    ImageMenuItemComponent.prototype.updateLink = function () {
        var _this = this;
        this.menuItemActionService
            .getActionExecution(this.menuItem.action, { context: this.context })
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            _this.link = result.link;
            _this.href = result.href;
            _this.queryParams = result.queryParams;
            _this.handler = result.handler;
            _this.cd.markForCheck();
        });
    };
    ImageMenuItemComponent.prototype.isIconImage = function () {
        return (this.primary && !this.horizontal) || (!isSet(this.title) && !isSet(this.subtitle));
    };
    ImageMenuItemComponent.prototype.initMenuDropdown = function () {
        var _this = this;
        this.menuDropdownSubscription.forEach(function (item) { return item.unsubscribe(); });
        this.menuDropdownSubscription = [];
        if (!this.menuItem.children.length) {
            return;
        }
        this.menuDropdownSubscription.push(combineLatest(this.menuItemHovered$.pipe(debounce(function (origin) { return timer(origin ? 0 : 100); })), this.menuDropdownHovered$, this.menuChildDropdownsOpened$.pipe(map(function (values) { return values.length > 0; })))
            .pipe(debounceTime(10), untilDestroyed(this))
            .subscribe(function (_a) {
            var itemOrigin = _a[0], dropdownHovered = _a[1], childDropdownOpened = _a[2];
            if (itemOrigin && !_this.dropdownOpened) {
                _this.openDropdown(itemOrigin);
            }
            else if (!itemOrigin && !dropdownHovered && !childDropdownOpened && _this.dropdownOpened) {
                _this.closeDropdown();
            }
        }));
        this.menuDropdownSubscription.push(merge(this.router.events.pipe(filter(function (item) { return item instanceof ActivationEnd; })))
            .pipe(untilDestroyed(this))
            .subscribe(function () { return _this.closeDropdown(); }));
    };
    ImageMenuItemComponent.prototype.openDropdown = function (origin) {
        this.origin = origin;
        this.dropdownOpened = true;
        this.cd.markForCheck();
        this.dropdownOpen.emit();
    };
    ImageMenuItemComponent.prototype.closeDropdown = function () {
        this.dropdownOpened = false;
        this.cd.markForCheck();
        this.dropdownClose.emit();
    };
    ImageMenuItemComponent.prototype.setPositionObserver = function () {
        var _this = this;
        if (this.popoverPositionsSubscription) {
            this.popoverPositionsSubscription.unsubscribe();
        }
        if (!this.cdkConnectedOverlay) {
            return;
        }
        this.popoverPositionsSubscription = this.cdkConnectedOverlay.positionChange
            .pipe(untilDestroyed(this))
            .subscribe(function (e) {
            var propsEqual = ['offsetX', 'offsetY', 'originX', 'originY', 'overlayX', 'overlayY'];
            var position = _this.popoverPositions.find(function (item) {
                return propsEqual.every(function (prop) { return (item[prop] || undefined) == e.connectionPair[prop]; });
            });
            var otherPosition = _this.popoverPositions.filter(function (item) { return item !== position; });
            if (position) {
                _this.cdkConnectedOverlay.overlayRef.addPanelClass(position.panelClass);
            }
            otherPosition.forEach(function (item) { return _this.cdkConnectedOverlay.overlayRef.removePanelClass(item.panelClass); });
        });
    };
    ImageMenuItemComponent.prototype.onClick = function () {
        if (this.handler) {
            this.handler().pipe(untilDestroyed(this)).subscribe();
        }
    };
    ImageMenuItemComponent.prototype.onMenuChildDropdownOpened = function (menuItem) {
        var newValue = this.menuChildDropdownsOpened$.value.concat([menuItem]);
        this.menuChildDropdownsOpened$.next(newValue);
    };
    ImageMenuItemComponent.prototype.onMenuChildDropdownClosed = function (menuItem) {
        var newValue = this.menuChildDropdownsOpened$.value.filter(function (item) { return item !== menuItem; });
        this.menuChildDropdownsOpened$.next(newValue);
    };
    return ImageMenuItemComponent;
}(BaseMenuItemComponent));
export { ImageMenuItemComponent };
