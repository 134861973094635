import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-list-stub-footer',
  templateUrl: './list-stub-footer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListStubFooterComponent implements OnInit {
  @Input() pagination = true;
  @Input() count = false;
  @Input() animating = false;
  @Input() theme = false;

  constructor() {}

  ngOnInit() {}
}
