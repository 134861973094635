import { Directive, ElementRef, Input, OnChanges, OnDestroy, OnInit, Optional } from '@angular/core';
import toPairs from 'lodash/toPairs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, of } from 'rxjs';

import { TintStyle } from '@modules/actions';
import { AllProjectSettings, ProjectSettingsStore } from '@modules/all-project-settings';
import { ElementWrapperStyles } from '@modules/customize';
import { setBorderRadiusVars, setBorderVars, setShadowVars } from '@modules/theme-components';
import { isSet, TypedChanges } from '@shared';

@Directive({
  selector: '[appElementWrapperStyles]'
})
export class ElementWrapperStylesDirective implements OnInit, OnDestroy, OnChanges {
  @Input('appElementWrapperStyles') customElementStyles: ElementWrapperStyles;
  @Input('appElementWrapperStylesApplyGlobal') applyGlobal = true;
  @Input('appElementWrapperStylesTintColorStyle') tintColorStyle: TintStyle;
  @Input('appElementWrapperStylesElement') forceElement: ElementRef<HTMLElement>;

  tintColorStyle$ = new BehaviorSubject<TintStyle>(undefined);
  globalStyles: ElementWrapperStyles;
  styleVars: string[] = [];

  constructor(@Optional() private projectSettingsStore: ProjectSettingsStore, private el: ElementRef<HTMLElement>) {}

  ngOnInit(): void {
    if (this.applyGlobal) {
      const settings$ = this.projectSettingsStore
        ? this.projectSettingsStore.getAllSettings$()
        : of<AllProjectSettings>(undefined);

      combineLatest(settings$, this.tintColorStyle$)
        .pipe(untilDestroyed(this))
        .subscribe(([settings, tintColorStyle]) => {
          if (!settings) {
            this.globalStyles = undefined;
          } else if (tintColorStyle == TintStyle.Primary) {
            this.globalStyles = settings.actionElementStylesPrimary;
          } else if (tintColorStyle == TintStyle.Default) {
            this.globalStyles = settings.actionElementStylesDefault;
          } else if (tintColorStyle == TintStyle.Transparent) {
            this.globalStyles = settings.actionElementStylesTransparent;
          } else {
            this.globalStyles = undefined;
          }

          this.updateStyles();
        });
    } else {
      this.updateStyles();
    }
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<ElementWrapperStylesDirective>): void {
    if (changes.customElementStyles && !changes.customElementStyles.firstChange) {
      this.updateStyles();
    }

    if (changes.tintColorStyle) {
      this.tintColorStyle$.next(this.tintColorStyle);
    }
  }

  getElement(): ElementRef<HTMLElement> {
    return this.forceElement || this.el;
  }

  updateStyles() {
    let elementStyles: ElementWrapperStyles;
    const vars: Object = {};

    if (this.customElementStyles && this.globalStyles) {
      elementStyles = new ElementWrapperStyles().apply(this.globalStyles).apply(this.customElementStyles);
    } else if (this.customElementStyles || this.globalStyles) {
      elementStyles = this.customElementStyles || this.globalStyles;
    } else {
      elementStyles = undefined;
    }

    if (elementStyles) {
      setBorderVars(vars, elementStyles.borderSettings, key => `element-wrapper-${key}`);
      setBorderRadiusVars(vars, elementStyles.borderRadius, 'element-wrapper-border-radius');
      setShadowVars(vars, elementStyles.shadow, key => `element-wrapper-${key}`);
    }

    const element = this.getElement();

    toPairs(vars).forEach(([k, v]) => element.nativeElement.style.setProperty(`--${k}`, v));
    this.styleVars.filter(k => !isSet(vars[k])).forEach(k => element.nativeElement.style.removeProperty(`--${k}`));
    this.styleVars = toPairs(vars).map(([k]) => k);

    element.nativeElement.setAttribute('data-style-vars', this.styleVars.join(' '));
  }
}
