import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable } from 'rxjs';

import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { CustomizeService } from '@modules/customize';
import { colorSchemaDefaults } from '@modules/customize-shared';
import { FeatureService } from '@modules/features';
import { createFormFieldFactory, NumberFieldType } from '@modules/fields';
import { CurrentProjectStore } from '@modules/projects';
import { RoutingService } from '@modules/routing';
import { ThemeService } from '@modules/theme';

import { ProjectAppearanceContext } from '../project-settings/project-appearance.context';
import { ProjectSettingsUpdateForm } from '../project-settings/project-settings-update.form';

@Component({
  selector: 'app-customize-bar-appearance-edit-general',
  templateUrl: './customize-bar-appearance-edit-general.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeBarAppearanceEditGeneralComponent implements OnInit, OnDestroy {
  @Input() context: ProjectAppearanceContext;
  @Input() settingsForm: ProjectSettingsUpdateForm;

  createField = createFormFieldFactory();
  themes = [
    {
      icon: 'sun',
      label: 'Light',
      value: 'default'
    },
    {
      icon: 'moon',
      label: 'Dark',
      value: 'dark'
    }
  ];
  accentColors = ['teal', 'green', 'red', 'black', 'purple', 'orange'].map(item => {
    return {
      value: item,
      color: item
    };
  });
  accentColorsDark = ['teal', 'green', 'red', 'black', 'purple', 'orange'].map(item => {
    return {
      value: item,
      color: item
    };
  });
  placeholders = colorSchemaDefaults;
  hasChanges$: Observable<boolean>;
  submitLoading = false;
  numberFieldTypes = NumberFieldType;

  constructor(
    private customizeService: CustomizeService,
    public currentProjectStore: CurrentProjectStore,
    public themeService: ThemeService,
    private featureService: FeatureService,
    private routing: RoutingService,
    private analyticsService: UniversalAnalyticsService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.hasChanges$ = this.context.getHasChanges$();
  }

  ngOnDestroy(): void {}

  changeTheme(theme: string): void {
    this.settingsForm.controls.default_theme.patchValue(theme);
  }

  back() {
    const link = this.currentProjectStore.instance.settingsLayoutLink('appearance');
    this.routing.navigateApp(link);
  }

  cancel() {
    this.customizeService.stopTrackChanges();
    this.context.resetSavedState();
    this.customizeService.startTrackChanges();
  }

  submit() {
    if (this.submitLoading) {
      return;
    }

    this.submitLoading = true;
    this.cd.markForCheck();

    this.context
      .submit()
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          this.customizeService.stopTrackChanges();
          this.context.saveCurrentState();
          this.customizeService.startTrackChanges();
          this.submitLoading = false;
          this.cd.markForCheck();

          this.analyticsService.sendSimpleEvent(AnalyticsEvent.Appearance.AppearanceSaved, {
            Section: 'general',
            ProjectSettingsChanges: result.changes.projectSettingsChanges,
            MenuSettingsChanged: result.changes.menuSettings
          });
        },
        () => {
          this.submitLoading = false;
          this.cd.markForCheck();
        }
      );
  }

  onStylesFeatureClick() {
    if (!this.currentProjectStore.instance.features.isStylesEnabled()) {
      this.featureService.showFeatureOverview({
        subtitle: 'Paid Feature',
        title: 'Customize <strong>App Styles</strong>',
        description: `
          You can customize various App styles, including background, text, colors, borders, shadows, and more,
          giving you greater flexibility to design your App to match your brand's aesthetics.
        `
      });
    }
  }
}
