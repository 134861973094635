import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, Observable } from 'rxjs';
import { auditTime, first } from 'rxjs/operators';

import { DynamicComponentArguments } from '@common/dynamic-component';
import { ProjectSettingsStore } from '@modules/all-project-settings';
import { CustomizeBarContext } from '@modules/customize-bar';
import { Option } from '@modules/field-components';
import { FontType } from '@modules/styles';
import { ContainerLayer, FillType, LayerType, TextLayer, TextTruncate } from '@modules/views';
import { controlValue, isSet, parseNumber } from '@shared';

import { registerCustomizeLayerComponent } from '../../../data/customize-layer-components';
import { ViewEditorContext } from '../../../services/view-editor-context/view-editor.context';
import { BorderControl } from '../../controls/border.control';
import { FillControl } from '../../controls/fill.control';
import { ShadowControl } from '../../controls/shadow.control';
import { FillControlComponent } from '../../ui/fill-control/fill-control.component';
import { CustomizeLayerComponent } from '../base/base-customize-layer.component';
import { TextCustomizeLayerForm } from './text-customize-layer.form';

@Component({
  selector: 'app-ellipse-customize-layer',
  templateUrl: './text-customize-layer.component.html',
  providers: [TextCustomizeLayerForm, CustomizeBarContext],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextCustomizeLayerComponent extends CustomizeLayerComponent<TextLayer> implements OnInit, OnDestroy {
  @ViewChildren(FillControlComponent) fillControlComponents = new QueryList<FillControlComponent>();

  containerLayer: ContainerLayer;
  fontTypeOptions$: Observable<Option<FontType>[]>;
  letterSpacingControl = new FormControl(100);
  defaultLineHeight: number;
  fillControlOpened: FillControlComponent;
  settingsComponents: DynamicComponentArguments[] = [];
  textTruncates = TextTruncate;
  fillTypes = FillType;

  typeCompareWith = (o1: FontType, o2: FontType) => {
    if (o1 && o2) {
      return o1.weight == o2.weight && o1.style == o2.style;
    } else {
      return o1 == o2;
    }
  };

  constructor(
    public form: TextCustomizeLayerForm,
    private editorContext: ViewEditorContext,
    private customizeBarContext: CustomizeBarContext,
    private projectSettingsStore: ProjectSettingsStore,
    private cd: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this.fontTypeOptions$ = this.form.controls.font.getFontTypeOptions$();

    const layerContainer = this.editorContext.getLayerContainer(this.layer);

    this.form.init(this.layer, { firstInit: true, container: layerContainer });
    this.trackFormUpdates(this.form, () => this.form.submit());

    combineLatest(this.layerUpdated$, this.editorContext.getLayerContainer$(this.layer))
      .pipe(auditTime(10), untilDestroyed(this))
      .subscribe(([layer, container]) => {
        this.pauseFormUpdates();
        this.form.init(layer, {
          firstInit: false,
          container: container
        });
        this.resumeFormUpdates();
      });

    this.form.containerLayer$.pipe(untilDestroyed(this)).subscribe(layer => {
      this.containerLayer = layer;
      this.cd.markForCheck();
    });

    this.form
      .getDefaultLineHeight$()
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        this.defaultLineHeight = value;
        this.cd.markForCheck();
      });

    controlValue(this.form.controls.letter_spacing)
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        if (isSet(value)) {
          const valueNumber = parseNumber(value, 0);
          const letterSpacing = Math.round(valueNumber * 100);

          this.letterSpacingControl.patchValue(letterSpacing);
        } else {
          this.letterSpacingControl.patchValue(null);
        }
      });

    this.customizeBarContext.settingsComponents$.pipe(untilDestroyed(this)).subscribe(item => {
      this.settingsComponents = item;
      this.cd.markForCheck();
    });
  }

  ngOnDestroy(): void {}

  setLetterSpacing(value: string) {
    if (isSet(value)) {
      const valueNumber = parseNumber(value, 0);
      const letterSpacing = Math.round(valueNumber) / 100;

      this.form.controls.letter_spacing.patchValue(letterSpacing);
    } else {
      this.form.controls.letter_spacing.patchValue(null);
    }
  }

  onFontSelect(value: string) {
    this.projectSettingsStore
      .getAllSettingsFirst$()
      .pipe(first(), untilDestroyed(this))
      .subscribe(projectSettings => {
        const fontTypes = this.form.controls.font.getFontTypeOptionsForValue(value, projectSettings);
        const fontTypeValue: FontType = this.form.controls.font.controls.type.value;
        const fontType = fontTypes.find(item => this.form.controls.font.fontTypeEquals(item.value, fontTypeValue));

        if (!fontType && fontTypes.length) {
          this.form.controls.font.controls.type.patchValue(fontTypes[0].value);
        }
      });
  }

  fillsDragDrop(event: CdkDragDrop<FillControl[]>) {
    if (event.previousIndex !== event.currentIndex) {
      moveItemInArray(this.form.controls.fills.controls, event.previousIndex, event.currentIndex);
      this.form.controls.fills.updateValueAndValidity();
    }
  }

  bordersDragDrop(event: CdkDragDrop<BorderControl[]>) {
    if (event.previousIndex !== event.currentIndex) {
      moveItemInArray(this.form.controls.borders.controls, event.previousIndex, event.currentIndex);
      this.form.controls.borders.updateValueAndValidity();
    }
  }

  shadowsDragDrop(event: CdkDragDrop<ShadowControl[]>) {
    if (event.previousIndex !== event.currentIndex) {
      moveItemInArray(this.form.controls.shadows.controls, event.previousIndex, event.currentIndex);
      this.form.controls.shadows.updateValueAndValidity();
    }
  }

  updateFillControlOpened() {
    this.fillControlOpened = this.fillControlComponents.find(item => item.isColorOpened());
    this.cd.markForCheck();
  }
}

registerCustomizeLayerComponent(LayerType.Text, TextCustomizeLayerComponent);
