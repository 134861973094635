var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { untilDestroyed } from 'ngx-take-until-destroy';
import Quill from 'quill';
import { merge, of } from 'rxjs';
import { delay, first } from 'rxjs/operators';
import { isSet } from '@shared';
var Inline = Quill.import('blots/inline');
export var COLOR_SET_DEFAULT_VALUE_ATTR = 'data-color-default';
export var COLOR_SET_DARK_VALUE_ATTR = 'data-color-dark';
var ColorSetBlot = /** @class */ (function (_super) {
    __extends(ColorSetBlot, _super);
    function ColorSetBlot() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ColorSetBlot.create = function (value) {
        if (!value) {
            return _super.create.call(this, false);
        }
        var node = _super.create.call(this, value);
        if (isSet(value.colorDefault)) {
            node.setAttribute(COLOR_SET_DEFAULT_VALUE_ATTR, value.colorDefault);
        }
        if (isSet(value.colorDark)) {
            node.setAttribute(COLOR_SET_DARK_VALUE_ATTR, value.colorDark);
        }
        return node;
    };
    // Overriding this method, in this particular case,
    // is what causes the Delta returned as content by
    // Quill to have the desired information.
    ColorSetBlot.formats = function (domNode) {
        var colorDefault = domNode.getAttribute(COLOR_SET_DEFAULT_VALUE_ATTR);
        var colorDark = domNode.getAttribute(COLOR_SET_DARK_VALUE_ATTR);
        if (isSet(colorDefault) || isSet(colorDark)) {
            return {
                colorDefault: colorDefault,
                colorDark: colorDark
            };
        }
        else {
            return _super.formats.call(this, domNode);
        }
    };
    ColorSetBlot.prototype.ngOnDestroy = function () { };
    ColorSetBlot.prototype.getQuill = function () {
        var node = this.domNode;
        while (node) {
            var quill = Quill.find(node);
            if (quill instanceof Quill) {
                return quill;
            }
            node = node.parentElement;
        }
    };
    ColorSetBlot.prototype.getOptions = function () {
        var quill = this.getQuill();
        if (quill) {
            var toolbar_1 = quill.getModule('toolbar');
            if (toolbar_1) {
                var options = toolbar_1.options.formatsOptions || {};
                return options['color-set'] || {};
            }
        }
        return {};
    };
    ColorSetBlot.prototype.attach = function () {
        var _this = this;
        _super.prototype.attach.call(this);
        var options = this.getOptions();
        var theme$ = options['theme$'] || of(undefined);
        // Workaround for value update
        theme$ = merge(theme$.pipe(first()), theme$.pipe(delay(0)));
        theme$.pipe(untilDestroyed(this)).subscribe(function (theme) {
            var node = _this.domNode;
            var colorDefault = node.getAttribute(COLOR_SET_DEFAULT_VALUE_ATTR);
            var colorDark = node.getAttribute(COLOR_SET_DARK_VALUE_ATTR);
            var colorEffective = theme == 'dark' ? colorDark : colorDefault;
            if (isSet(colorEffective)) {
                node.setAttribute('style', "--color-value: " + colorEffective);
            }
            else {
                node.removeAttribute('style');
            }
        });
    };
    ColorSetBlot.prototype.detach = function () {
        _super.prototype.detach.call(this);
        this.ngOnDestroy();
    };
    ColorSetBlot.prototype.formats = function () {
        // Returns the formats list this class (this format).
        var formats = _super.prototype.formats.call(this);
        // Inline has the domNode reference, which in this
        // case represents the SPAN, result defined in tagName.
        formats['color-set'] = ColorSetBlot.formats(this.domNode);
        // In the code above, it is as if we are adding this new format.
        return formats;
    };
    return ColorSetBlot;
}(Inline));
ColorSetBlot.tagName = 'SPAN';
ColorSetBlot.blotName = 'color-set';
Quill.register('formats/color-set', ColorSetBlot);
