import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';
import { TintStyle } from '@modules/actions';
import { CHART_COLORS } from '@modules/charts';
import { defaultAccentColor } from '@modules/colors';
import { ActionElementStyles, FieldElementStyles } from '@modules/customize';
import { ThemeService } from '@modules/theme';
import { isSet } from '@shared';
import { ProjectAppearanceForm } from '../project-settings/project-appearance.form';
import { pieChartSampleData } from './pie-chart-sample-data';
var AdminTemplatePageComponent = /** @class */ (function () {
    function AdminTemplatePageComponent(themeService, cd) {
        this.themeService = themeService;
        this.cd = cd;
        this.defaultAccentColor = defaultAccentColor;
        this.stepsProgress = 0;
        this.sampleDatasets = pieChartSampleData;
        this.defaultChartColors = CHART_COLORS;
        this.tableData = {
            headers: ['First name', 'Last name', 'E-mail', 'Company', 'Actions'],
            rows: [
                ['John', 'Doe', 'john.doe@email.com', 'TechCorp', ''],
                ['Anna', 'Smith', 'anna.smith@email.com', 'HealthPlus', ''],
                ['Robert', 'Johnson', 'robert.johnson@email.com', 'FinancePro', ''],
                ['Emily', 'Brown', 'emily.brown@email.com', 'EduTech', '']
            ]
        };
        this.tintStyles = TintStyle;
    }
    AdminTemplatePageComponent.prototype.ngOnInit = function () {
        var _this = this;
        var stepIndex = 1;
        var itemWidth = 1 / 4;
        this.stepsProgress = itemWidth * stepIndex + itemWidth * 0.5;
        combineLatest(this.appearanceForm.controls.auto_colors.getEffectiveValue$(), this.appearanceForm.controls.auto_colors_dark.getEffectiveValue$(), this.themeService.isDarkTheme$)
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var defaultColors = _a[0], defaultColorsDark = _a[1], isDarkTheme = _a[2];
            _this.defaultChartColors = CHART_COLORS.map(function (defaultColor, i) {
                if (isDarkTheme) {
                    return isSet(defaultColorsDark[i]) ? defaultColorsDark[i] : defaultColor;
                }
                else {
                    return isSet(defaultColors[i]) ? defaultColors[i] : defaultColor;
                }
            });
            _this.cd.markForCheck();
        });
    };
    AdminTemplatePageComponent.prototype.ngOnDestroy = function () { };
    return AdminTemplatePageComponent;
}());
export { AdminTemplatePageComponent };
