<div class="popup">
  <app-popup2 [size]="sync && typeItem.name == resourceNames.Firebase ? 'm' : 'sm'">
    <app-popup2-header>
      <app-popup2-title *ngIf="sync">Enable sync mode for {{ object }}</app-popup2-title>
      <app-popup2-title *ngIf="!sync">Use direct connection for {{ object }}</app-popup2-title>
    </app-popup2-header>

    <app-page-block *ngIf="sync">
      <div class="field">
        <div class="button-selector-item button-selector-item_color_orange" [class.button-selector-item_active]="false">
          <app-sync-mode-image class="button-selector-item__image-block" [typeItem]="typeItem"></app-sync-mode-image>
          <div class="button-selector-item__tag background-color_orange_1">
            <span class="button-selector-item__tag-raw">β</span>eta
          </div>
          <div class="text-status-list button-selector-item__list">
            <div class="text-status-list__item text-status-list__item_margin_s">
              <div class="text-status-list__item-left">
                <span class="text-status-list__circle text-status-list__circle_green icon-check_2"></span>
              </div>
              <div class="text-status-list__item-main">
                Allow <strong>SQL queries</strong> with {{ typeItem?.label }}
              </div>
            </div>
            <div class="text-status-list__item text-status-list__item_margin_s">
              <div class="text-status-list__item-left">
                <span class="text-status-list__circle text-status-list__circle_green icon-check_2"></span>
              </div>
              <div class="text-status-list__item-main">
                <strong>Blend and join</strong> data from over 30 data sources
              </div>
            </div>
            <div
              *ngIf="
                [
                  resourceNames.Airtable,
                  resourceNames.SmartSuite,
                  resourceNames.Firebase,
                  resourceNames.GoogleSheets
                ].includes($any(typeItem.name))
              "
              class="text-status-list__item text-status-list__item_margin_s"
            >
              <div class="text-status-list__item-left">
                <span class="text-status-list__circle text-status-list__circle_green icon-check_2"></span>
              </div>
              <div class="text-status-list__item-main">
                Suitable for <strong>large volume data</strong> (100k+ records)
              </div>
            </div>
            <div class="text-status-list__item text-status-list__item_margin_s">
              <div class="text-status-list__item-left">
                <span class="text-status-list__circle text-status-list__circle_green icon-check_2"></span>
              </div>
              <div class="text-status-list__item-main">
                Data is synced to <strong>Jet Tables</strong>
                <ng-container *ngIf="[resourceNames.SmartSuite].includes($any(typeItem.name))">
                  every 15 minutes
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </app-page-block>

    <app-page-block *ngIf="!sync">
      <div class="field">
        <div
          class="button-selector-item button-selector-item_color_teal-blue"
          [class.button-selector-item_active]="false"
        >
          <app-direct-mode-image
            class="button-selector-item__image-block"
            [typeItem]="typeItem"
          ></app-direct-mode-image>
          <!--            <div class="button-selector-item__tag button-selector-item__tag_position_top background-color_blue_1">-->
          <!--              has limitations-->
          <!--            </div>-->
          <div class="text-status-list">
            <div class="text-status-list__item text-status-list__item_margin_s">
              <div class="text-status-list__item-left">
                <span
                  class="text-status-list__circle text-status-list__circle_red text-status-list__circle_size_s icon-close"
                ></span>
              </div>
              <div class="text-status-list__item-main">
                No <strong>SQL queries</strong> support with {{ typeItem?.label }}
              </div>
            </div>
            <div class="text-status-list__item text-status-list__item_margin_s">
              <div class="text-status-list__item-left">
                <span
                  class="text-status-list__circle text-status-list__circle_red text-status-list__circle_size_s icon-close"
                ></span>
              </div>
              <div class="text-status-list__item-main">No <strong>Blend and join</strong> data support</div>
            </div>
            <div
              *ngIf="[resourceNames.Airtable, resourceNames.SmartSuite].includes($any(typeItem.name))"
              class="text-status-list__item text-status-list__item_margin_s"
            >
              <div class="text-status-list__item-left">
                <span
                  class="text-status-list__circle text-status-list__circle_red text-status-list__circle_size_s icon-close"
                ></span>
              </div>
              <div class="text-status-list__item-main">
                Charts not supported
              </div>
            </div>
            <div
              *ngIf="
                [
                  resourceNames.Airtable,
                  resourceNames.SmartSuite,
                  resourceNames.Firebase,
                  resourceNames.GoogleSheets
                ].includes($any(typeItem.name))
              "
              class="text-status-list__item text-status-list__item_margin_s"
            >
              <div class="text-status-list__item-left">
                <span
                  class="text-status-list__circle text-status-list__circle_red text-status-list__circle_size_s icon-close"
                ></span>
              </div>
              <div class="text-status-list__item-main">
                Not suitable for <strong>large volume data</strong> (100k+ records)
              </div>
            </div>
            <div class="text-status-list__item text-status-list__item_margin_s">
              <div class="text-status-list__item-left">
                <span class="text-status-list__circle text-status-list__circle_green icon-check_2"></span>
              </div>
              <div class="text-status-list__item-main">
                No sync delays
              </div>
            </div>
            <div class="text-status-list__item text-status-list__item_margin_s">
              <div class="text-status-list__item-left">
                <span class="text-status-list__circle text-status-list__circle_green icon-check_2"></span>
              </div>
              <div class="text-status-list__item-main"><strong>Direct requests</strong> to {{ typeItem?.label }}</div>
            </div>
          </div>
        </div>
      </div>
    </app-page-block>

    <app-page-block *ngIf="sync && isPublishRequired && hasDraftChanges">
      <app-alert [purple]="true" [title]="'This resource has unpublished changes'" [icon]="'warning_filled'">
        Please note that Sync uses only published resource credentials
      </app-alert>
    </app-page-block>

    <ng-container *ngIf="typeItem.name == resourceNames.Firebase && sync">
      <app-page-block *ngIf="paramsOptionsLoading">
        <div class="field">
          <div class="field__content">
            <div class="text-status-list">
              <div *ngFor="let item of ['grant permissions', 'enable service', 'check']" class="text-status-list__item">
                <div class="text-status-list__item-main">
                  <span [class.loading-animation]="true"
                    ><span class="stub-text">{{ item }}</span></span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </app-page-block>

      <app-page-block *ngIf="!paramsOptionsLoading && paramsOptionsError">
        <div class="field">
          <div class="field__errors">
            <div class="field__error">
              {{ paramsOptionsError }}
            </div>
          </div>
        </div>
      </app-page-block>

      <app-sync-mode-firebase
        *ngIf="!paramsOptionsLoading && !paramsOptionsError && paramsOptions"
        [enableSync]="sync"
        [paramsOptions]="asFirebaseParamsOptions(paramsOptions)"
        [submitLabel]="sync ? 'Enable Sync' : 'Use direct connection'"
        [submitLoader]="loading"
        (submit)="submit($event)"
        (cancel)="close()"
      ></app-sync-mode-firebase>
    </ng-container>

    <app-popup2-footer *ngIf="typeItem.name != resourceNames.Firebase || !sync">
      <app-page-block>
        <app-stack [align]="'right'">
          <button type="button" class="button popup-content__submit-item" [disabled]="loading" (click)="close()">
            Cancel
          </button>

          <button
            type="button"
            class="button button_primary button_shadow popup-content__submit-item"
            [disabled]="loading"
            (click)="submit()"
          >
            <app-loader-small *ngIf="loading" class="button__icon button__icon_left"></app-loader-small>

            <span *ngIf="sync" class="button__label">Enable Sync</span>
            <span *ngIf="!sync" class="button__label">Use direct connection</span>
          </button>
        </app-stack>
      </app-page-block>
    </app-popup2-footer>
  </app-popup2>
</div>
