var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormGroup } from '@angular/forms';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { KanbanBoardListElementStyles, MarginControl } from '@modules/customize';
import { controlValue } from '@shared';
import { TextStyleControl } from '../text-style-edit/text-style.control';
var KanbanBoardListElementStylesControl = /** @class */ (function (_super) {
    __extends(KanbanBoardListElementStylesControl, _super);
    function KanbanBoardListElementStylesControl(injector) {
        var _this = _super.call(this, {
            title_style: TextStyleControl.inject(injector, {
                global: 'regularTitleTextStyle',
                colorAlphaEnabled: true
            }),
            stage_title_style: TextStyleControl.inject(injector, {
                global: 'kanbanStageTitleTextStyle',
                colorAlphaEnabled: true,
                backgroundEnabled: true
            }),
            margin: new MarginControl()
        }) || this;
        _this.injector = injector;
        return _this;
    }
    KanbanBoardListElementStylesControl.prototype.deserialize = function (instance) {
        if (!instance) {
            return;
        }
        this.controls.title_style.deserialize(instance.titleStyle);
        this.controls.stage_title_style.deserialize(instance.stageTitleStyle);
        this.controls.margin.deserialize(instance.margin);
    };
    KanbanBoardListElementStylesControl.prototype.isSet = function () {
        return [this.controls.title_style, this.controls.stage_title_style, this.controls.margin].some(function (control) {
            return control.isSet();
        });
    };
    KanbanBoardListElementStylesControl.prototype.stylesDefaultUpdated$ = function () {
        return combineLatest([
            this.controls.title_style.getStyleDefault$(),
            this.controls.stage_title_style.getStyleDefault$()
        ]).pipe(map(function () { return undefined; }));
    };
    KanbanBoardListElementStylesControl.prototype.serialize = function () {
        if (!this.isSet()) {
            return;
        }
        return new KanbanBoardListElementStyles({
            titleStyle: this.controls.title_style.serialize(false),
            stageTitleStyle: this.controls.stage_title_style.serialize(false),
            margin: this.controls.margin.serialize()
        });
    };
    KanbanBoardListElementStylesControl.prototype.serialize$ = function () {
        var _this = this;
        return controlValue(this, { debounce: 200 }).pipe(map(function () { return _this.serialize(); }));
    };
    return KanbanBoardListElementStylesControl;
}(FormGroup));
export { KanbanBoardListElementStylesControl };
