<app-field-wrapper
  *ngIf="!readonly"
  [formGroup]="form"
  [form]="form"
  [field]="field"
  [readonly]="readonly"
  [requiredAsterisk]="requiredAsterisk"
  [label]="label"
  [labelStyle]="elementStyles?.labelStyle"
  [labelStyleGlobal]="labelStyleGlobal"
  [labelAdditional]="labelAdditional"
  [labelAdditionalStyle]="elementStyles?.labelAdditionalStyle"
  [labelAdditionalStyleGlobal]="labelAdditionalStyleGlobal"
  [errors]="errors"
  [truncate]="truncate"
  [manualMargin]="manualMargin"
  [labelButtons]="labelButtons"
  [tooltip]="tooltip"
  [idToken]="idToken"
  [theme]="field.params['theme']"
>
  <div *ngIf="control">
    <div
      class="input"
      [class.input_error]="(form | appFormFieldErrors: field.name)?.length"
      [class.input_theme]="field.params['theme']"
      [class.input_styles]="field.params['theme']"
      [appFieldElementStyles]="elementStyles"
      [appFieldElementStylesEnabled]="field.params['theme']"
      [ngClass]="field.params['classes']"
    >
      <div
        class="rating rating_interactive"
        (mouseleave)="clearHoverValue()"
        (click)="toggleCurrentValue(hoverValue); setDisplayValue(currentValue)"
      >
        <div *ngFor="let i of maxValue | appRange" class="rating__item">
          <app-star
            [size]="size"
            [activeColor]="field.params['tint']"
            [leftActive]="isRatingValueGte((displayValue | appIsSet) ? displayValue : currentValue, i, true)"
            [rightActive]="isRatingValueGte((displayValue | appIsSet) ? displayValue : currentValue, i, false)"
          ></app-star>

          <div class="rating__item-left" (mouseenter)="setHoverValue(i, true)"></div>
          <div class="rating__item-right" (mouseenter)="setHoverValue(i, false)"></div>
        </div>
      </div>
    </div>
  </div>
</app-field-wrapper>

<app-field-wrapper
  *ngIf="readonly"
  [form]="form"
  [field]="field"
  [readonly]="readonly"
  [requiredAsterisk]="requiredAsterisk"
  [label]="label"
  [labelStyle]="elementStyles?.labelStyle"
  [labelStyleGlobal]="labelStyleGlobal"
  [labelAdditional]="labelAdditional"
  [labelAdditionalStyle]="elementStyles?.labelAdditionalStyle"
  [labelAdditionalStyleGlobal]="labelAdditionalStyleGlobal"
  [errors]="errors"
  [truncate]="truncate"
  [manualMargin]="manualMargin"
  [labelButtons]="labelButtons"
  [tooltip]="tooltip"
  [idToken]="idToken"
  [theme]="field.params['theme']"
>
  <div class="rating">
    <div *ngFor="let i of maxValue | appRange" class="rating__item">
      <app-star
        [activeColor]="field.params['tint']"
        [leftActive]="isRatingValueGte((displayValue | appIsSet) ? displayValue : currentValue, i, true)"
        [rightActive]="isRatingValueGte((displayValue | appIsSet) ? displayValue : currentValue, i, false)"
      ></app-star>
    </div>
  </div>
</app-field-wrapper>
