<ng-template #custom_components>
  <div class="sidebar__heading sidebar__heading_margin_s">
    Custom components
  </div>
  <div class="sidebar__list">
    <app-change-customize-bar-custom-views [analyticsSource]="analyticsSource"></app-change-customize-bar-custom-views>
  </div>
</ng-template>

<div class="sidebar__content">
  <div class="sidebar__input">
    <div class="input-icon-wrapper">
      <span class="input-icon input-icon_toolbar input-icon_left icon-search"></span>
      <span
        *ngIf="search"
        class="input-icon input-icon_toolbar input-icon_interactive input-icon_right icon-close"
        (click)="clearSearch()"
      ></span>
      <input
        class="input input_toolbar input_background input_icon-left sidebar__input-inner"
        [class.input_icon-right]="search"
        type="text"
        [placeholder]="'Search components, integrations...'"
        [appAutofocus]="true"
        [(ngModel)]="search"
        (keyup)="searchUpdated.next()"
      />
    </div>
  </div>

  <div *ngIf="loading" class="sidebar-collapse sidebar-collapse_opened">
    <div class="sidebar__heading">
      <span [class.loading-animation]="true">
        <span class="stub-text">Most Used</span>
      </span>
    </div>
    <div class="sidebar__list">
      <div class="sidebar-list sidebar-list_third">
        <div *ngFor="let item of [1, 2, 3, 4, 5]" class="sidebar-list__item sidebar-list__item_third">
          <div class="sidebar-image-card2">
            <div class="sidebar-image-card2__image-container">
              <div class="sidebar-image-card2__image">
                <span [class.loading-animation]="true">
                  <span class="stub-image stub-image_contrast sidebar-image-card2__image-stub"></span>
                </span>
              </div>
            </div>
            <div class="sidebar-image-card2__title">
              <div class="sidebar-image-card2__line">
                <span [class.loading-animation]="true">
                  <span class="stub-text">Item</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="!loading">
    <ng-container *ngIf="!search && mostUsedFiltered?.length">
      <div class="sidebar__heading">Most Used</div>
      <div class="sidebar__list">
        <div
          class="sidebar-list sidebar-list_third"
          appDropList
          [appDropListConnectedToGroup]="dropListGroups"
          [appDropListData]="mostUsedFiltered"
          [appDropListEnterPredicate]="isDroppable"
          [appDropListSortingDisabled]="true"
          [appDropListCloneItems]="true"
          [appDropListOutsideIndicator]="true"
          [appDropListParams]="{ create_elements: true }"
          (appDropListDragged)="clearSearch()"
        >
          <div
            *ngFor="let item of mostUsedFiltered"
            appDrag
            [appDragData]="item"
            class="sidebar-list__item sidebar-list__item_third"
          >
            <app-element-placeholder-stub
              *appDragPlaceholder
              [elementType]="item.type"
              class="element-placeholder"
            ></app-element-placeholder-stub>

            <app-sidebar-image-card
              [title]="item.title"
              [image]="item.image"
              [icon]="item.icon"
            ></app-sidebar-image-card>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="!(search | appIsSet)">
      <ng-container *ngTemplateOutlet="custom_components"></ng-container>
    </ng-container>

    <ng-container *ngIf="layoutsFiltered?.length">
      <div class="sidebar__heading">Layout</div>
      <div class="sidebar__list">
        <div
          class="sidebar-list sidebar-list_third"
          appDropList
          [appDropListConnectedToGroup]="dropListGroups"
          [appDropListData]="layoutsFiltered"
          [appDropListEnterPredicate]="isDroppable"
          [appDropListSortingDisabled]="true"
          [appDropListCloneItems]="true"
          [appDropListOutsideIndicator]="true"
          [appDropListParams]="{ create_elements: true }"
          (appDropListDragged)="clearSearch()"
        >
          <div
            *ngFor="let item of layoutsFiltered"
            appDrag
            [appDragData]="item"
            class="sidebar-list__item sidebar-list__item_third"
          >
            <app-element-placeholder-stub
              *appDragPlaceholder
              [elementType]="item.type"
              class="element-placeholder"
            ></app-element-placeholder-stub>

            <app-sidebar-image-card
              [title]="item.title"
              [image]="item.image"
              [icon]="item.icon"
            ></app-sidebar-image-card>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="basicFiltered?.length">
      <div class="sidebar__heading">Basic</div>
      <div class="sidebar__list">
        <div
          class="sidebar-list sidebar-list_third"
          appDropList
          [appDropListConnectedToGroup]="dropListGroups"
          [appDropListData]="basicFiltered"
          [appDropListEnterPredicate]="isDroppable"
          [appDropListSortingDisabled]="true"
          [appDropListCloneItems]="true"
          [appDropListOutsideIndicator]="true"
          [appDropListParams]="{ create_elements: true }"
          (appDropListDragged)="clearSearch()"
        >
          <div
            *ngFor="let item of basicFiltered"
            appDrag
            [appDragData]="item"
            class="sidebar-list__item sidebar-list__item_third"
          >
            <app-element-placeholder-stub
              *appDragPlaceholder
              [elementType]="item.type"
              class="element-placeholder"
            ></app-element-placeholder-stub>

            <app-sidebar-image-card
              [title]="item.title"
              [image]="item.image"
              [icon]="item.icon"
            ></app-sidebar-image-card>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="listsFiltered?.length">
      <div class="sidebar__heading">Lists</div>
      <div class="sidebar__list">
        <div
          class="sidebar-list sidebar-list_third"
          appDropList
          [appDropListConnectedToGroup]="dropListGroups"
          [appDropListData]="listsFiltered"
          [appDropListEnterPredicate]="isDroppable"
          [appDropListSortingDisabled]="true"
          [appDropListCloneItems]="true"
          [appDropListOutsideIndicator]="true"
          [appDropListParams]="{ create_elements: true }"
          (appDropListDragged)="clearSearch()"
        >
          <div
            *ngFor="let item of listsFiltered"
            appDrag
            [appDragData]="item"
            class="sidebar-list__item sidebar-list__item_third"
          >
            <app-element-placeholder-stub
              *appDragPlaceholder
              [elementType]="item.type"
              class="element-placeholder"
            ></app-element-placeholder-stub>

            <app-sidebar-image-card
              [title]="item.title"
              [image]="item.image"
              [icon]="item.icon"
            ></app-sidebar-image-card>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="chartsFiltered?.length">
      <div class="sidebar__heading">Charts</div>
      <div class="sidebar__list">
        <div
          class="sidebar-list sidebar-list_third"
          appDropList
          [appDropListConnectedToGroup]="dropListGroups"
          [appDropListData]="chartsFiltered"
          [appDropListEnterPredicate]="isDroppable"
          [appDropListSortingDisabled]="true"
          [appDropListCloneItems]="true"
          [appDropListOutsideIndicator]="true"
          [appDropListParams]="{ create_elements: true }"
          (appDropListDragged)="clearSearch()"
        >
          <div
            *ngFor="let item of chartsFiltered"
            appDrag
            [appDragData]="item"
            class="sidebar-list__item sidebar-list__item_third"
          >
            <app-element-placeholder-stub
              *appDragPlaceholder
              [elementType]="item.type"
              class="element-placeholder"
            ></app-element-placeholder-stub>

            <app-sidebar-image-card
              [title]="item.title"
              [image]="item.image"
              [icon]="item.icon"
            ></app-sidebar-image-card>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="formsFiltered?.length">
      <div class="sidebar__heading">Forms</div>
      <div class="sidebar__list">
        <div
          class="sidebar-list sidebar-list_third"
          appDropList
          [appDropListConnectedToGroup]="dropListGroups"
          [appDropListData]="formsFiltered"
          [appDropListEnterPredicate]="isDroppable"
          [appDropListSortingDisabled]="true"
          [appDropListCloneItems]="true"
          [appDropListOutsideIndicator]="true"
          [appDropListParams]="{ create_elements: true }"
          (appDropListDragged)="clearSearch()"
        >
          <div
            *ngFor="let item of formsFiltered"
            appDrag
            [appDragData]="item"
            class="sidebar-list__item sidebar-list__item_third"
          >
            <app-element-placeholder-stub
              *appDragPlaceholder
              [elementType]="item.type"
              class="element-placeholder"
            ></app-element-placeholder-stub>

            <app-sidebar-image-card
              [title]="item.title"
              [image]="item.image"
              [icon]="item.icon"
            ></app-sidebar-image-card>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="integrationResourcesFiltered?.length">
      <div class="sidebar__heading">
        <div class="sidebar__heading-right">
          <a href="javascript:void(0)" class="sidebar__link" (click)="selectTab.emit(customizeBarTabs.Templates)">
            See All <span class="icon-arrow_forward_2"></span>
          </a>
        </div>
        Integrations
      </div>
      <div class="sidebar__images">
        <div class="sidebar-images">
          <a
            *ngFor="let item of integrationResourcesFiltered | slice: 0:(search ? 5 : 10)"
            href="javascript:void(0)"
            class="sidebar-images__item sidebar-images__item_hoverable"
            [class.sidebar-images__item_disabled]="!item.installed"
            [appTip]="item.typeItem.label"
            [appTipOptions]="{ side: 'top' }"
            (click)="onIntegrationClick(item.typeItem, item.installed)"
          >
            <div
              class="sidebar-images__item-image"
              [style.background-image]="
                '/assets/images/resources/icons/' + item.typeItem.icon + '.svg' | appDeployCssUrl
              "
            ></div>
          </a>
        </div>
        <!--      <div class="sidebar__list">-->
        <!--        <div-->
        <!--          class="sidebar-list sidebar-list_third"-->
        <!--          [appDraggable]="'customizing_source'"-->
        <!--          [appDraggableOptions]="{-->
        <!--            droppable: ['customizing_destination'],-->
        <!--            copy: true,-->
        <!--            createComponent: createComponent(),-->
        <!--            sortable: false-->
        <!--          }"-->
        <!--          (appDraggableDragFinished)="onDragFinished($event)"-->
        <!--        >-->
        <!--          <div-->
        <!--            *ngFor="let item of integrationsFiltered"-->
        <!--            appDraggableItem-->
        <!--            [appDraggableItemData]="{ item: item }"-->
        <!--            class="sidebar-list__item sidebar-list__item_third"-->
        <!--          >-->
        <!--            <app-sidebar-image-card-->
        <!--              [title]="item.title"-->
        <!--              [image]="item.image"-->
        <!--              [icon]="item.icon"-->
        <!--              [indicatorImage]="getItemIcon(item)"-->
        <!--            ></app-sidebar-image-card>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
    </ng-container>

    <ng-container *ngIf="advancedFiltered?.length">
      <div class="sidebar__heading">Advanced</div>
      <div class="sidebar__list">
        <div
          class="sidebar-list sidebar-list_third"
          appDropList
          [appDropListConnectedToGroup]="dropListGroups"
          [appDropListData]="advancedFiltered"
          [appDropListEnterPredicate]="isDroppable"
          [appDropListSortingDisabled]="true"
          [appDropListCloneItems]="true"
          [appDropListOutsideIndicator]="true"
          [appDropListParams]="{ create_elements: true }"
          (appDropListDragged)="clearSearch()"
        >
          <div
            *ngFor="let item of advancedFiltered"
            appDrag
            [appDragData]="item"
            class="sidebar-list__item sidebar-list__item_third"
          >
            <app-element-placeholder-stub
              *appDragPlaceholder
              [elementType]="item.type"
              class="element-placeholder"
            ></app-element-placeholder-stub>

            <app-sidebar-image-card
              [title]="item.title"
              [image]="item.image"
              [icon]="item.icon"
              [purpleGradient]="item.purpleGradient"
              [beta]="item.beta"
            ></app-sidebar-image-card>
          </div>
        </div>
      </div>
    </ng-container>

    <div *ngIf="emptyLabel" class="sidebar__message">
      {{ emptyLabel }}
    </div>

    <ng-container *ngIf="search | appIsSet">
      <ng-container *ngTemplateOutlet="custom_components"></ng-container>
    </ng-container>

    <app-sidebar-section>
      <div class="sidebar__element">
        <button
          type="button"
          class="button button_orange-alternative button_fill button_bigger"
          id="request_component"
          (click)="openChat()"
        >
          <span class="icon button__icon_left icon-components button__icon"></span>
          <span class="button__label">Suggest a Component</span>
          <span class="icon button__icon_right icon-arrow_forward_2 button__icon"></span>
        </button>
        <!--    <div class="sidebar-field__description">-->
        <!--      Let people discover and copy your app. List your app for free, or pick a price and sell it.-->
        <!--    </div>-->
      </div>
    </app-sidebar-section>
  </ng-container>
</div>
