var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import isArray from 'lodash/isArray';
import { of, throwError } from 'rxjs';
import { catchError, map, publishLast, refCount, tap, timeout } from 'rxjs/operators';
import { ActionDescription, ActionType, QueryAction } from '@modules/actions';
import { ServerRequestError } from '@modules/api';
import { DataGroup } from '@modules/charts';
import { MessageName, MessageService } from '@modules/messages';
import { ModelDescription, PAGE_PARAM } from '@modules/models';
import { ProjectApiService } from '@modules/project-api';
import { ActionQuery, QueryType } from '@modules/queries';
import { isSet } from '@shared';
import { ResourceController } from '../../data/resource-controller';
var MessagesAPIResourceController = /** @class */ (function (_super) {
    __extends(MessagesAPIResourceController, _super);
    function MessagesAPIResourceController() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    MessagesAPIResourceController.prototype.init = function () {
        this.http = this.initService(HttpClient);
        this.messageService = this.initService(MessageService);
        this.apiService = this.initService(ProjectApiService);
    };
    MessagesAPIResourceController.prototype.supportedQueryTypes = function (resource, queryClass) {
        return [QueryType.Simple];
    };
    MessagesAPIResourceController.prototype.checkResource = function (typeItem, params) {
        return this.http.get(params['url']).pipe(catchError(function (error) {
            if (error instanceof HttpErrorResponse && error.status) {
                return of(true);
            }
            return throwError(new ServerRequestError({
                non_field_errors: undefined,
                url: ['Messages API is not reachable. Is it running? If so, you might have a CORS configuration issue.']
            }));
        }), publishLast(), refCount());
    };
    MessagesAPIResourceController.prototype.modelDescriptionGet = function (resource, draft) {
        if (draft === void 0) { draft = false; }
        return this.messageService.send(resource, MessageName.GetCustomModelDescriptions).pipe(timeout(5000), map(function (result) {
            if (!result.json || !isArray(result.json)) {
                return [];
            }
            return result.json.map(function (item) {
                var instance = new ModelDescription().deserialize(item);
                instance.resource = resource.uniqueName;
                return instance;
            });
        }), catchError(function () { return of([]); }), publishLast(), refCount());
    };
    MessagesAPIResourceController.prototype.modelGet = function (resource, modelDescription, params, body) {
        var _this = this;
        params = params || {};
        var page = params[PAGE_PARAM] || 1;
        var messagesParams = {
            model: modelDescription.model,
            action: 'list',
            params: params
        };
        return this.messageService.send(resource, MessageName.CustomModel, messagesParams).pipe(map(function (result) {
            if (!result.json) {
                return [];
            }
            return result.json;
        }), map(function (result) {
            var data = result;
            if (data['results'] === undefined) {
                data = {
                    results: data
                };
            }
            else if (isSet(data['num_pages'])) {
                data['has_more'] = page < data['num_pages'];
            }
            return _this.createGetResponse().deserialize(data, modelDescription.model, modelDescription);
        }), tap(function (response) {
            response.results.forEach(function (item) {
                item.deserializeAttributes(modelDescription.dbFields);
            });
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    MessagesAPIResourceController.prototype.modelGroup = function (resource, modelDescription, xColumns, yFunc, yColumn, params) {
        params = __assign({}, (params || {}), { _y_func: yFunc, _y_column: yColumn });
        if (xColumns[0]) {
            params['_x_column'] = xColumns[0].xColumn;
            params['_x_lookup'] = xColumns[0].xLookup;
        }
        var messagesParams = {
            model: modelDescription.model,
            action: 'group',
            params: params
        };
        return this.messageService.send(resource, MessageName.CustomModel, messagesParams).pipe(map(function (result) {
            if (!result.json) {
                return [];
            }
            return result.json;
        }), map(function (result) { return result.map(function (item) { return new DataGroup().deserialize(item); }); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    MessagesAPIResourceController.prototype.actionDescriptionGet = function (resource) {
        return this.messageService.send(resource, MessageName.GetActionList).pipe(timeout(5000), map(function (result) {
            if (!result.json || !isArray(result.json)) {
                return;
            }
            return result.json.map(function (item) {
                var action = new ActionDescription().deserialize(item);
                action.resource = resource.uniqueName;
                action.type = ActionType.Query;
                action.queryAction = new QueryAction();
                action.queryAction.query = new ActionQuery();
                action.queryAction.query.queryType = QueryType.Simple;
                return action;
            });
        }), publishLast(), refCount());
    };
    MessagesAPIResourceController.prototype.actionExecute = function (resource, query, parameters, params, rawErrors) {
        if (parameters === void 0) { parameters = []; }
        if (!query || !query.sqlQuery) {
            return of(undefined);
        }
        var data = {
            name: query.simpleQuery.name
        };
        if (params.model) {
            data['model'] = params.model;
        }
        if (params.id) {
            data['id'] = params.id;
        }
        if (params.ids) {
            data['ids'] = params.ids;
        }
        if (params.inverseIds) {
            data['inverseIds'] = params.inverseIds;
        }
        if (params.actionParams) {
            data['params'] = params.actionParams;
        }
        return this.messageService.send(resource, MessageName.ExecuteAction, data, !rawErrors).pipe(map(function (response) {
            return {
                response: response.response,
                blob: response.blob,
                json: response.json,
                text: response.text
            };
        }), publishLast(), refCount());
    };
    return MessagesAPIResourceController;
}(ResourceController));
export { MessagesAPIResourceController };
