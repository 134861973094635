<ng-container *ngIf="!pageInfo">
  <td class="card-table__column">
    <div class="card-table__column-inner">
      <div class="square-icons">
        <div
          *ngFor="let item of stepsInfo"
          class="square-icon square-icons__item"
          [appTip]="item.labels?.join(' - ')"
          [appTipOptions]="{ side: 'top' }"
        >
          <div
            class="square-icon__inner"
            [class.square-icon__inner_background]="item.icon"
            [ngClass]="item.icon ? 'icon-' + item.icon : null"
            [style.background-image]="item.image ? 'url(' + item.image + ')' : null"
          ></div>
        </div>

        <div *ngIf="stepsMore > 0" class="square-icon square-icons__item">
          <div class="square-icon__inner square-icon__inner_text">+{{ stepsMore }}</div>
        </div>
      </div>
    </div>
  </td>

  <td class="card-table__column">
    <div class="card-table__column-inner">
      {{ item.automation?.name || 'No name' }}
    </div>
  </td>
</ng-container>

<td *ngIf="pageInfo" class="card-table__column" colspan="2">
  <div class="card-table__column-inner">
    <a [appLink]="pageInfo.page.link" class="button button_small" style="margin: 0;">
      <span class="button__label" style="display: flex; align-items: center;">
        {{ pageInfo.page.name || 'Page' }}

        <ng-container *ngIf="pageInfo.element">
          <span class="icon-arrow_forward_3" style="margin: 0 4px;"></span>
          {{ pageInfo.element.label || pageInfo.element.type }}
        </ng-container>
      </span>
    </a>
  </div>
</td>

<td class="card-table__column">
  <div class="card-table__column-inner">
    <div style="margin: -4px 0;">
      <span
        class="status"
        [ngClass]="{
          'color_blue_1 background-color_blue_2': item.status == statuses.Pending,
          'color_yellow_1 background-color_yellow_2': item.status == statuses.Running,
          'color_green_1 background-color_green_2': item.status == statuses.Succeeded,
          'color_red_1 background-color_red_2': item.status == statuses.Failed
        }"
      >
        {{ item.getStatusLabel() }}
      </span>
    </div>
  </div>
</td>

<td class="card-table__column">
  <div class="card-table__column-inner">
    <span class="card-table__grey">
      {{ item.dateAdd | appFormatDate: 'lll' }}
    </span>
  </div>
</td>

<td class="card-table__column">
  <div class="card-table__column-inner card-table__grey">
    <ng-container *ngIf="item.dateRun">
      <span class="card-table__value-icon icon-time"></span>
      {{ item.dateRun | appFormatDate: 'HH:mm:ss'
      }}<ng-container *ngIf="item.dateFinished"> - {{ item.dateFinished | appFormatDate: 'HH:mm:ss' }}</ng-container>
      <ng-container *ngIf="duration"> ({{ duration }})</ng-container>
    </ng-container>
  </div>
</td>

<td class="card-table__column">
  <div class="card-table__column-inner card-table__column-inner_right">
    <div
      class="button-icon2 button-icon2_size_s button-icon2_circle icon-arrow_forward_2"
      [class.button-icon2_hover]="hover"
    ></div>
  </div>
</td>
