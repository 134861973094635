<app-field-wrapper
  [form]="form"
  [field]="createField({ name: 'from', label: element.verboseName, required: element.required })"
  [label]="element.verboseName | appIsSet"
  [labelStyle]="element.labelStyle"
  [labelStyleGlobal]="'fieldLabelTextStyle'"
  [labelAdditional]="element.labelAdditional"
  [labelAdditionalStyle]="element.labelAdditionalStyle"
  [labelAdditionalStyleGlobal]="'fieldLabelAdditionalTextStyle'"
  [manualMargin]="true"
  [tooltip]="element.tooltip"
  [idToken]="idToken"
  [theme]="true"
>
  <div class="input-icon-wrapper">
    <div
      class="input input_container input_fill input_icon-right input_theme"
      cdkOverlayOrigin
      #dp_origin="cdkOverlayOrigin"
    >
      <div class="input-nested input-nested_theme">
        <div class="input-nested__value">
          <input
            class="input-nested__value-input"
            type="text"
            [class.input_error]="fromControl.invalid"
            [appDateValue]="{ format: editFormat, date: true, time: element.time }"
            [formControl]="fromControl"
            [id]="'from' | appUniqueId: idToken"
            [placeholder]="(element.fromPlaceholder | appIsSet) ? element.fromPlaceholder : editFormatPlaceholder"
            autocomplete="off"
            (keydown)="onKeydown($event, dp_from)"
            #dp_from
          />
          <div class="input-nested__value-placeholder">
            <ng-container *ngIf="dp_from.value | appIsSet">
              {{ dp_from.value }}
            </ng-container>

            <ng-container *ngIf="!(dp_from.value | appIsSet)">
              {{ (element.fromPlaceholder | appIsSet) ? element.fromPlaceholder : editFormatPlaceholder }}
            </ng-container>
          </div>
        </div>

        <div class="input-nested__separator">–</div>

        <div class="input-nested__value">
          <input
            class="input-nested__value-input"
            type="text"
            [class.input_error]="toControl.invalid"
            [appDateValue]="{ format: editFormat, date: true, time: element.time }"
            [formControl]="toControl"
            [id]="'to' | appUniqueId: idToken"
            [placeholder]="(element.toPlaceholder | appIsSet) ? element.toPlaceholder : editFormatPlaceholder"
            autocomplete="off"
            (keydown)="onKeydown($event, dp_to)"
            #dp_to
          />
          <div class="input-nested__value-placeholder">
            <ng-container *ngIf="dp_to.value | appIsSet">
              {{ dp_to.value }}
            </ng-container>

            <ng-container *ngIf="!(dp_to.value | appIsSet)">
              {{ (element.toPlaceholder | appIsSet) ? element.toPlaceholder : editFormatPlaceholder }}
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <span
      *ngIf="!element.resetEnabled || (!(fromControl.value | appIsSet) && !(toControl.value | appIsSet))"
      class="input-icon input-icon_right icon-calendar input-icon_theme"
    >
    </span>
    <span
      *ngIf="element.resetEnabled && ((fromControl.value | appIsSet) || (toControl.value | appIsSet))"
      class="input-icon input-icon_interactive input-icon_right icon-close input-icon_theme"
      (click)="resetCurrentValue()"
    ></span>

    <gxd-datepicker
      [input]="dp_from"
      [rangeInput]="dp_to"
      [origin]="dp_origin"
      [options]="{
        theme: 'jet',
        date: true,
        months: 2,
        time: element.time,
        clock12: editFormatClock12,
        format: editFormat,
        datepickerClasses: ['datepicker_theme']
      }"
      (dateChange)="onFromSelected($event)"
    ></gxd-datepicker>

    <gxd-datepicker
      [input]="dp_to"
      [rangeInput]="dp_from"
      [origin]="dp_origin"
      [options]="{
        theme: 'jet',
        date: true,
        months: 2,
        time: element.time,
        clock12: editFormatClock12,
        format: editFormat,
        datepickerClasses: ['datepicker_theme']
      }"
      [defaultDate]="dp_from.value"
      (dateChange)="onToSelected($event)"
    ></gxd-datepicker>
  </div>
</app-field-wrapper>
