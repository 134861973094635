var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormControl, FormGroup } from '@angular/forms';
import { Color } from '@modules/views';
import { isSet } from '@shared';
var ColorControl = /** @class */ (function (_super) {
    __extends(ColorControl, _super);
    function ColorControl(state) {
        if (state === void 0) { state = {}; }
        var _this = _super.call(this, {
            global: new FormControl(isSet(state.global) ? state.global : null),
            red: new FormControl(isSet(state.red) ? state.red : 0),
            green: new FormControl(isSet(state.green) ? state.green : 0),
            blue: new FormControl(isSet(state.blue) ? state.blue : 0),
            alpha: new FormControl(isSet(state.alpha) ? state.alpha : 1)
        }) || this;
        _this.onDisabledChange = function (value) { return undefined; };
        return _this;
    }
    ColorControl.prototype.deserialize = function (value, options) {
        if (options === void 0) { options = {}; }
        this.instance = value;
        this.controls.global.patchValue(value.global, { emitEvent: options.emitEvent });
        this.controls.red.patchValue(value.red, { emitEvent: options.emitEvent });
        this.controls.green.patchValue(value.green, { emitEvent: options.emitEvent });
        this.controls.blue.patchValue(value.blue, { emitEvent: options.emitEvent });
        this.controls.alpha.patchValue(value.alpha, { emitEvent: options.emitEvent });
    };
    ColorControl.prototype.getInstance = function (instance) {
        if (!instance) {
            instance = new Color();
        }
        instance.global = this.controls.global.value;
        instance.red = this.controls.red.value;
        instance.green = this.controls.green.value;
        instance.blue = this.controls.blue.value;
        instance.alpha = this.controls.alpha.value;
        return instance;
    };
    ColorControl.prototype.serialize = function () {
        return this.getInstance(this.instance);
    };
    ColorControl.prototype.registerOnChange = function (fn) {
        this.valueChanges.subscribe(function (value) { return fn(value); });
    };
    ColorControl.prototype.registerOnDisabledChange = function (fn) {
        this.onDisabledChange = fn;
    };
    ColorControl.prototype.enable = function (opts) {
        _super.prototype.enable.call(this, opts);
        this.onDisabledChange(false);
    };
    ColorControl.prototype.disable = function (opts) {
        _super.prototype.disable.call(this, opts);
        this.onDisabledChange(true);
    };
    return ColorControl;
}(FormGroup));
export { ColorControl };
