import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, first, map, switchMap } from 'rxjs/operators';

import { DialogService } from '@common/dialogs';
import { FeatureService } from '@modules/features';
import { CurrentProjectStore } from '@modules/projects';
import { ascComparator, isSet, TypedChanges } from '@shared';

export enum StyleType {
  TextStyle = 'text_style',
  IconSettings = 'icon_settings',
  Fill = 'fill',
  BorderSettings = 'border_settings',
  Corners = 'corners',
  Shadow = 'shadow',
  Margin = 'margin'
}

export interface Style<T extends AbstractControl = AbstractControl> {
  label: string;
  additional?: string;
  icon: string;
  group?: { label: string; icon?: string };
  type: StyleType;
  control: T;
  isSet$: (control: T) => Observable<boolean>;
  isEnabled?: (style: Style<T>) => boolean;
  onAdd?: (style: Style<T>) => void;
  onRemove?: (style: Style<T>) => void;
}

export interface StyleItem {
  label?: string;
  icon?: string;
  style?: Style;
  children?: Style[];
}

export interface EnabledStyleGroup {
  label?: string;
  icon?: string;
  styles: Style[];
  allStyles?: Style[];
}

@Component({
  selector: 'app-styles-edit',
  templateUrl: './styles-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StylesEditComponent implements OnInit, OnDestroy, OnChanges {
  @Input() styles: StyleItem[] = [];
  @Input() showEmpty = false;

  enabledStyles: Style[] = [];
  enabledStylesGroups: EnabledStyleGroup[] = [];
  addedStyle: Style;
  styleTypes = StyleType;

  trackStyleGroupFn(i, item: EnabledStyleGroup) {
    return item.label;
  }

  trackStyleFn(i, item: Style) {
    return item.control;
  }

  constructor(
    public currentProjectStore: CurrentProjectStore,
    private featureService: FeatureService,
    private dialogService: DialogService,
    private zone: NgZone,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<StylesEditComponent>): void {
    if (changes.styles) {
      this.updateEnabledStyles();
    }
  }

  updateEnabledStyles() {
    const styles$: Observable<Style>[] = this.styles
      .reduce<Style[]>((acc, item) => {
        if (item.style) {
          acc.push(item.style);
        } else if (item.children) {
          const group = { label: item.label, icon: item.icon };
          item.children.forEach(child => {
            child.group = group;
          });
          acc.push(...item.children);
        }
        return acc;
      }, [])
      .map(item => {
        return item.isSet$(item.control).pipe(
          map(value => !!value),
          distinctUntilChanged(),
          map(styleIsSet => {
            if (styleIsSet) {
              return item;
            } else if (this.enabledStyles.find(enabledItem => enabledItem.control === item.control)) {
              return item;
            }
          })
        );
      });

    (styles$.length ? combineLatest(...styles$) : of<Style[]>([])).pipe(untilDestroyed(this)).subscribe(result => {
      this.enabledStyles = result
        .filter(item => item)
        .sort((lhs, rhs) => {
          const lhsIndex = this.enabledStyles.findIndex(enabledItem => enabledItem.control === lhs.control);
          const rhsIndex = this.enabledStyles.findIndex(enabledItem => enabledItem.control === rhs.control);

          if (lhsIndex === -1 && rhsIndex === -1) {
            return 0;
          } else if (lhsIndex === -1 && rhsIndex !== -1) {
            return 1;
          } else if (lhsIndex !== -1 && rhsIndex === -1) {
            return -1;
          } else {
            return ascComparator(lhsIndex, rhsIndex);
          }
        });
      this.cd.markForCheck();
      this.updateGroups();
    });
  }

  updateGroups() {
    const groups: { [group: string]: EnabledStyleGroup } = this.enabledStyles.reduce((acc, item) => {
      const key = isSet(item.group) ? item.group.label : '';

      if (!acc[key]) {
        acc[key] = {
          ...(item.group && { label: item.group.label, icon: item.group.icon }),
          styles: []
        };
      }

      acc[key].styles.push(item);

      return acc;
    }, {});

    const groupsOrder: StyleItem[] = this.styles.filter(item => isSet(item.label));
    this.enabledStylesGroups = [{}, ...groupsOrder]
      .map(group => {
        const key = isSet(group.label) ? group.label : '';
        if (!groups[key]) {
          return {
            ...group,
            styles: []
          };
        }

        return groups[key];
      })
      .filter(item => this.showEmpty || !item.label || item.styles.length);

    this.enabledStylesGroups.forEach(group => {
      const styleItem = group.label ? this.styles.find(item => item.label == group.label) : undefined;
      if (styleItem && styleItem.children) {
        group.allStyles = styleItem.children;
      }
    });

    this.cd.markForCheck();
  }

  addStyle(style: Style) {
    this.enabledStyles = [...this.enabledStyles, style];
    this.addedStyle = style;
    this.cd.markForCheck();
    this.updateGroups();

    if (style.onAdd) {
      style.onAdd(style);
    }

    this.zone.onStable.pipe(first(), untilDestroyed(this)).subscribe(() => {
      this.addedStyle = undefined;
    });
  }

  isAddStyleEnabled(style: Style): boolean {
    return !this.enabledStyles.includes(style);
  }

  removeStyle(style: Style) {
    style
      .isSet$(style.control)
      .pipe(
        first(),
        switchMap(styleIsSet => {
          if (!styleIsSet) {
            return of(true);
          }

          return this.dialogService.warning({
            title: 'Remove style',
            description: `Are you sure want to remove style <strong>"${style.label}"</strong>?`,
            style: 'orange'
          });
        }),
        filter(result => result == true),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.removeStyleProcess(style);
      });
  }

  removeStyleProcess(style: Style) {
    this.enabledStyles = this.enabledStyles.filter(item => item !== style);
    this.cd.markForCheck();
    this.updateGroups();

    if (style.onRemove) {
      style.onRemove(style);
    }
  }

  asAny(value: any): any {
    return value;
  }

  showFeatureOverview() {
    this.featureService.showFeatureOverview({
      subtitle: 'Paid Feature',
      title: 'Customize <strong>App Styles</strong>',
      description: `
        You can customize various App styles, including background, text, colors, borders, shadows, and more,
        giving you greater flexibility to design your App to match your brand's aesthetics.
      `
    });
  }
}
