import { LatLngLiteral } from '@agm/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { parse, stringify } from 'wellknown';

import { Domain } from '@modules/domain';
import {
  containsFieldLookup,
  endsWithFieldLookup,
  exactFieldLookup,
  gteFieldLookup,
  gtFieldLookup,
  inFieldLookup,
  isCurrentMonthFieldLookup,
  isCurrentQuarterFieldLookup,
  isCurrentWeekFieldLookup,
  isCurrentYearFieldLookup,
  isEmptyFieldLookup,
  isFutureFieldLookup,
  isLastMonthFieldLookup,
  isLastQuarterFieldLookup,
  isLastWeekFieldLookup,
  isLastXDaysFieldLookup,
  isLastYearFieldLookup,
  isNullFieldLookup,
  isPastFieldLookup,
  isPreviousMonthFieldLookup,
  isPreviousQuarterFieldLookup,
  isPreviousWeekFieldLookup,
  isPreviousXDaysFieldLookup,
  isPreviousYearFieldLookup,
  isTodayFieldLookup,
  isYesterdayFieldLookup,
  jsonContainsFieldLookup,
  lteFieldLookup,
  ltFieldLookup,
  startsWithFieldLookup
} from '@modules/field-lookups';
import { coerceArray, firstSet, isSet, isValidNumber, parseBoolean, parseNumber, parseTime } from '@shared';

import { BaseField } from './base-field';
import { defaultFieldType, FieldType } from './field-type';
import { formatDateTimeField, formatTimeField } from './fields/date-time-field/format';
import { OutputFormat } from './fields/date-time-field/output-format';
import { TimeOutputFormat } from './fields/date-time-field/time-output-format';
import {
  defaultGeographyObjectOutputFormatLatitudeField,
  defaultGeographyObjectOutputFormatLongitudeField,
  GeographyOutputFormat
} from './fields/geography-field/output-format';

import { applyValueFormat } from '../utils/value-format';
import { JsonOutputFormat } from './fields/json-field/json-field';
import { MultipleSelectOutputFormat } from './fields/multiple-select-field/output-format';
import { deserializeFirestoreValue, serializeFirestoreValue } from './firebase';
import { ValueFormat } from './value-format';
import { NumberValueFormat } from './value-format.interface';

export interface FieldDescriptionLookup {
  type: any;
  array?: boolean;
  field?: FieldType;
  fieldParams?: string[];
  extraParams?: Object;
}

export interface FieldDescription {
  jetBridgeTypes: string[];
  name: FieldType;
  label: string;
  category?: FieldDescriptionCategory;
  description?: string;
  icon?: string;
  image?: string;
  public?: boolean;
  lookups: FieldDescriptionLookup[];
  defaultParams?: {};
  forceParams?: {};
  // TODO: refactor for non null fields
  defaultValue: any;
  cleanValue?: (value: any, field?: BaseField) => any;
  deserializeValue?: (value: any, field?: BaseField) => any;
  serializeValue?: (value: any, field?: BaseField) => any;
  emptyValue?: any;
  valueToStr?: (
    value: any,
    options: { field?: BaseField; context?: Object; noTruncate?: boolean; domain?: Domain }
  ) => string | Observable<string>; // TODO: refactor
  valueToStrTableIgnore?: boolean;
  validators?: ValidatorFn[];
}

export enum FieldDescriptionCategory {
  Text = 'text',
  Numeric = 'numeric',
  Choices = 'choices',
  DateTime = 'datetime',
  Relationships = 'relationships',
  Files = 'files',
  Others = 'others'
}

export function getFieldTextValueFormat(field?: BaseField): ValueFormat {
  const fieldParams = field ? field.params : {};
  if (!fieldParams['value_format']) {
    return;
  }

  const result = new ValueFormat().deserialize(fieldParams['value_format']);

  result.numberFormat = undefined;
  result.numberFraction = undefined;
  result.numberFractionSeparator = undefined;
  result.numberThousandsSeparator = undefined;

  return result;
}

export function getFieldNumberValueFormat(field?: BaseField, options: { domain?: Domain } = {}): ValueFormat {
  const fieldParams = field ? field.params : {};
  const result = fieldParams['value_format']
    ? new ValueFormat().deserialize(fieldParams['value_format'])
    : new ValueFormat({ numberFormat: NumberValueFormat.Default });

  if (isSet(fieldParams['thousands_separator'], true)) {
    result.numberThousandsSeparator = fieldParams['thousands_separator'];
  }

  const defaultNumberFractionSeparator = options.domain ? options.domain.numberFractionSeparator : undefined;
  const defaultNumberThousandsSeparator = options.domain ? options.domain.numberThousandsSeparator : undefined;

  if (!isSet(result.numberFractionSeparator) && isSet(defaultNumberFractionSeparator)) {
    result.numberFractionSeparator = defaultNumberFractionSeparator;
  }

  if (!isSet(result.numberThousandsSeparator, true) && isSet(defaultNumberThousandsSeparator, true)) {
    result.numberThousandsSeparator = defaultNumberThousandsSeparator;
  }

  return result;
}

export const fieldDescriptions: FieldDescription[] = [
  {
    jetBridgeTypes: ['BooleanField', 'NullBooleanField'],
    name: FieldType.Boolean,
    label: 'Checkbox',
    category: FieldDescriptionCategory.Choices,
    description: 'On/off checkbox',
    icon: 'check_3',
    image: 'checkbox_field',
    public: true,
    lookups: [
      { type: exactFieldLookup, field: FieldType.Boolean },
      { type: isNullFieldLookup },
      { type: inFieldLookup, array: true, field: FieldType.Boolean }
    ],
    deserializeValue: (value, field?: BaseField) => {
      if (!isSet(value, true)) {
        return value;
      } else if (value === '') {
        return;
      } else if (field && field.params && field.params['type'] == 'integer') {
        return value == '1';
      } else {
        return parseBoolean(value);
      }
    },
    serializeValue: (value, field?: BaseField) => {
      if (value === null || value === undefined) {
        return value;
      } else if (field && field.params && field.params['type'] == 'integer') {
        return value ? '1' : '0';
      } else {
        return parseBoolean(value);
      }
    },
    defaultValue: false
  },
  {
    jetBridgeTypes: ['TranslatableField'],
    name: FieldType.Translatable,
    label: 'Translatable Text',
    lookups: [
      { type: containsFieldLookup, field: FieldType.Text },
      { type: exactFieldLookup, field: FieldType.Text },
      { type: startsWithFieldLookup, field: FieldType.Text },
      { type: endsWithFieldLookup, field: FieldType.Text },
      { type: isNullFieldLookup },
      { type: inFieldLookup, array: true, field: FieldType.Text }
    ],
    defaultValue: ''
  },
  {
    jetBridgeTypes: ['SelectField'],
    name: FieldType.Select,
    label: 'Select',
    category: FieldDescriptionCategory.Choices,
    description: 'Select a single value from a list of options',
    icon: 'select',
    image: 'select_field',
    public: true,
    lookups: [
      {
        type: exactFieldLookup,
        field: FieldType.Select,
        fieldParams: [
          'options_type',
          'options',
          'resource',
          'model',
          'query',
          'sorting_field',
          'sorting_asc',
          'value_field',
          'label_field',
          'parameters',
          'inputs',
          'columns'
        ]
      },
      { type: isNullFieldLookup },
      {
        type: inFieldLookup,
        array: true,
        field: FieldType.Select,
        fieldParams: [
          'options_type',
          'options',
          'resource',
          'model',
          'query',
          'sorting_field',
          'sorting_asc',
          'value_field',
          'label_field',
          'parameters',
          'inputs',
          'columns'
        ]
      }
    ],
    defaultValue: null
  },
  {
    jetBridgeTypes: ['RadioField'],
    name: FieldType.RadioButton,
    label: 'Radio Button',
    category: FieldDescriptionCategory.Choices,
    description: 'Radio button with a list of options',
    icon: 'check_4',
    image: 'radio_button_field',
    public: true,
    lookups: [
      {
        type: exactFieldLookup,
        field: FieldType.RadioButton,
        fieldParams: [
          'output_format',
          'options_type',
          'options',
          'resource',
          'model',
          'query',
          'sorting_field',
          'sorting_asc',
          'value_field',
          'label_field',
          'parameters',
          'inputs',
          'columns'
        ]
      },
      { type: isNullFieldLookup },
      {
        type: inFieldLookup,
        array: true,
        field: FieldType.RadioButton,
        fieldParams: [
          'output_format',
          'options_type',
          'options',
          'resource',
          'model',
          'query',
          'sorting_field',
          'sorting_asc',
          'value_field',
          'label_field',
          'parameters',
          'inputs',
          'columns'
        ]
      }
    ],
    defaultValue: null
  },
  {
    jetBridgeTypes: ['MultipleSelectField'],
    name: FieldType.MultipleSelect,
    label: 'Multiple Select',
    category: FieldDescriptionCategory.Choices,
    description: 'Select multiple values from a list of options',
    icon: 'check',
    image: 'select_multiple_field',
    public: true,
    lookups: [
      {
        type: exactFieldLookup,
        field: FieldType.Select,
        fieldParams: [
          'output_format',
          'options_type',
          'options',
          'resource',
          'query',
          'sorting_field',
          'sorting_asc',
          'value_field',
          'label_field',
          'parameters',
          'inputs',
          'columns'
        ]
      },
      { type: isNullFieldLookup },
      {
        type: inFieldLookup,
        array: true,
        field: FieldType.Select,
        fieldParams: [
          'output_format',
          'options_type',
          'options',
          'resource',
          'query',
          'sorting_field',
          'sorting_asc',
          'value_field',
          'label_field',
          'parameters',
          'inputs',
          'columns'
        ]
      }
    ],
    defaultValue: [],
    cleanValue: (value: any, field?: BaseField) => {
      if (value !== null && value !== undefined) {
        value = coerceArray(value);
      }

      return value;
    },
    deserializeValue: (value, field?: BaseField) => {
      if (field && field.params && field.params['output_format'] == MultipleSelectOutputFormat.String) {
        if (typeof value == 'string') {
          return isSet(value) ? value.split(',').map(item => item.trim()) : [];
        } else {
          return [];
        }
      } else {
        if (typeof value == 'string') {
          try {
            const parsed = JSON.parse(value);

            if (isArray(parsed)) {
              return parsed;
            } else {
              return [parsed];
            }
          } catch (e) {
            return isSet(value) ? value.split(',').map(item => item.trim()) : [];
          }
        } else if (isArray(value)) {
          return value;
        } else if (isSet(value)) {
          return [value];
        } else {
          return [];
        }
      }
    },
    serializeValue: (value, field?: BaseField) => {
      if (field && field.params && field.params['output_format'] == MultipleSelectOutputFormat.String) {
        if (isArray(value)) {
          return value.join(',');
        } else {
          return '';
        }
      } else {
        if (isArray(value)) {
          return value;
        } else {
          return [value];
        }
      }
    },
    valueToStr: value => {
      if (!isSet(value)) {
        return;
      }

      if (isArray(value)) {
        return value.join(', ');
      } else {
        return value;
      }
    }
  },
  {
    jetBridgeTypes: ['ForeignKey', 'OneToOneField'],
    name: FieldType.RelatedModel,
    label: 'Link to record',
    category: FieldDescriptionCategory.Relationships,
    description: 'Display the value of a field from a related record',
    icon: 'model_link',
    image: 'link_to_record',
    public: true,
    lookups: [
      {
        type: exactFieldLookup,
        field: FieldType.RelatedModel,
        fieldParams: [
          'related_model',
          'custom_primary_key',
          'custom_display_field',
          'foreign_key_transformer',
          'sorting_field',
          'sorting_asc',
          'inputs'
        ],
        extraParams: { actions: false }
      },
      { type: isNullFieldLookup },
      { type: isEmptyFieldLookup },
      {
        type: inFieldLookup,
        array: true,
        field: FieldType.RelatedModel,
        fieldParams: [
          'related_model',
          'custom_primary_key',
          'custom_display_field',
          'foreign_key_transformer',
          'sorting_field',
          'sorting_asc',
          'inputs'
        ],
        extraParams: { actions: false }
      }
    ],
    defaultValue: null,
    valueToStr: (value, options = {}) => {
      if (!isSet(value)) {
        return;
      }

      if (options.context && options.context['model']) {
        return options.context['model'].str;
      }

      return value;
    }
  },
  {
    jetBridgeTypes: ['DateTimeField', 'DateField', 'TimestampField'],
    name: FieldType.DateTime,
    label: 'Date & Time',
    category: FieldDescriptionCategory.DateTime,
    description: 'Date or date with time',
    icon: 'calendar',
    image: 'date_field',
    public: true,
    lookups: [
      { type: gteFieldLookup, field: FieldType.DateTime, fieldParams: ['date', 'time'] },
      { type: gtFieldLookup, field: FieldType.DateTime, fieldParams: ['date', 'time'] },
      { type: lteFieldLookup, field: FieldType.DateTime, fieldParams: ['date', 'time'] },
      { type: ltFieldLookup, field: FieldType.DateTime, fieldParams: ['date', 'time'] },
      { type: isPastFieldLookup },
      { type: isFutureFieldLookup },
      { type: isTodayFieldLookup },
      { type: isYesterdayFieldLookup },
      { type: isCurrentWeekFieldLookup },
      { type: isPreviousWeekFieldLookup },
      { type: isLastWeekFieldLookup },
      { type: isCurrentMonthFieldLookup },
      { type: isPreviousMonthFieldLookup },
      { type: isLastMonthFieldLookup },
      { type: isCurrentQuarterFieldLookup },
      { type: isPreviousQuarterFieldLookup },
      { type: isLastQuarterFieldLookup },
      { type: isCurrentYearFieldLookup },
      { type: isPreviousYearFieldLookup },
      { type: isLastYearFieldLookup },
      { type: isLastXDaysFieldLookup, field: FieldType.Number },
      { type: isPreviousXDaysFieldLookup, field: FieldType.Number },
      { type: exactFieldLookup, field: FieldType.DateTime, fieldParams: ['date', 'time'] },
      { type: isEmptyFieldLookup },
      { type: isNullFieldLookup },
      { type: inFieldLookup, array: true, field: FieldType.DateTime, fieldParams: ['date', 'time'] }
    ],
    defaultParams: {
      date: true,
      time: true
    },
    defaultValue: null,
    deserializeValue: (value, field?: BaseField) => {
      if (!isSet(value)) {
        return value;
      }

      const format = field && field.params ? field.params['output_format'] : undefined;
      let dt: moment.Moment;

      if (format == OutputFormat.Timestamp) {
        const seconds = parseInt(value, 10);
        dt = moment.unix(seconds);
      } else if (format == OutputFormat.TimestampMillisecond) {
        const milliseconds = parseInt(value, 10) / 1000;
        dt = moment.unix(milliseconds);
      } else if (format == OutputFormat.SerialNumber) {
        const days = parseFloat(value);
        const result = Math.round((days - 25569) * (24 * 60 * 60));

        if (result < 0) {
          return;
        }

        dt = moment.unix(result);
      } else if (format == OutputFormat.SerialNumberDate) {
        const days = parseFloat(value);
        const result = Math.round((days - 25569) * (24 * 60 * 60));

        if (result < 0) {
          return;
        }

        dt = moment.unix(result).utcOffset(0, false);
      } else if (format == OutputFormat.String) {
        const dateFormat = field && field.params ? field.params['date_format'] : undefined;
        const timeFormat = field && field.params ? field.params['time_format'] : undefined;

        if (dateFormat && timeFormat) {
          dt = moment(value, `${dateFormat} ${timeFormat}`);
        } else if (dateFormat) {
          dt = moment(value, dateFormat);
        } else {
          dt = moment(value);
        }
      } else {
        dt = moment(value);
      }

      if (!dt || !dt.isValid()) {
        return;
      }

      return dt;
    },
    serializeValue: (value, field?: BaseField) => {
      if (!isSet(value)) {
        return value;
      }

      const format = field && field.params ? field.params['output_format'] : undefined;

      if (format == OutputFormat.ISODate) {
        return moment(value).format('YYYY-MM-DD');
      } else if (format == OutputFormat.Timestamp) {
        return moment(value).format('X');
      } else if (format == OutputFormat.TimestampMillisecond) {
        return moment(value).format('x');
      } else if (format == OutputFormat.SerialNumber) {
        return parseFloat(moment(value).format('X')) / (24 * 60 * 60) + 25569;
      } else if (format == OutputFormat.SerialNumberDate) {
        return parseFloat(moment(value).utcOffset(0, true).format('X')) / (24 * 60 * 60) + 25569;
      } else if (format == OutputFormat.String) {
        const dateFormat = field && field.params ? field.params['date_format'] : undefined;
        const timeFormat = field && field.params ? field.params['time_format'] : undefined;

        if (dateFormat && timeFormat) {
          return moment(value).format(`${dateFormat} ${timeFormat}`);
        } else if (dateFormat) {
          return moment(value).format(dateFormat);
        } else {
          return moment(value).format('x');
        }
      } else {
        const date = field && field.params ? field.params['date'] : undefined;
        const time = field && field.params ? field.params['time'] : undefined;

        if (date === true && time === false) {
          return moment(value).format('YYYY-MM-DD');
        } else {
          return moment(value).toISOString(true);
        }
      }
    },
    valueToStr: (value, options = {}) => {
      return formatDateTimeField(value, options.field);
    }
  },
  // {
  //   jetBridgeTypes: ['TimestampField'],
  //   name: FieldType.Timestamp,
  //   label: 'Timestamp',
  //   icon: 'calendar',
  //   public: true,
  //   lookups: [
  //     { type: gteFieldLookup, field: FieldType.Timestamp },
  //     { type: gtFieldLookup, field: FieldType.Timestamp },
  //     { type: lteFieldLookup, field: FieldType.Timestamp },
  //     { type: ltFieldLookup, field: FieldType.Timestamp },
  //     { type: isPastFieldLookup },
  //     { type: isFutureFieldLookup },
  //     { type: isTodayFieldLookup },
  //     { type: isYesterdayFieldLookup },
  //     { type: isLastWeekFieldLookup },
  //     { type: isPreviousWeekFieldLookup },
  //     { type: isLastMonthFieldLookup },
  //     { type: isPreviousMonthFieldLookup },
  //     { type: isLastQuarterFieldLookup },
  //     { type: isPreviousQuarterFieldLookup },
  //     { type: isLastYearFieldLookup },
  //     { type: isPreviousYearFieldLookup },
  //     { type: isLastXDaysFieldLookup, field: FieldType.Number },
  //     { type: isPreviousXDaysFieldLookup, field: FieldType.Number },
  //     { type: exactFieldLookup, field: FieldType.Timestamp },
  //     { type: isNullFieldLookup },
  //     { type: inFieldLookup, array: true, field: FieldType.Timestamp },
  //   ],
  //   defaultParams: {
  //     date: true,
  //     time: true
  //   },
  //   defaultValue: null,
  //   deserializeValue: value => {
  //     console.log('deserializeValue 2', value);
  //     if (!isSet(value)) {
  //       return value;
  //     }
  //     let int = parseInt(value, 10);
  //     if (int >= 100000000000) {
  //       int = int / 1000;
  //     }
  //     return moment.unix(int);
  //   },
  //   serializeValue: value => {
  //     return moment(value).unix();
  //   },
  //   valueToStr: (value, options = {}) => {
  //     return formatDateTimeFieldValue(value, options.field);
  //   }
  // },
  // {
  //   jetBridgeTypes: ['DateField'],
  //   name: FieldType.Date,
  //   label: 'Date',
  //   icon: 'calendar',
  //   public: true,
  //   lookups: [
  //     { type: gteFieldLookup, field: FieldType.Date },
  //     { type: gtFieldLookup, field: FieldType.Date },
  //     { type: lteFieldLookup, field: FieldType.Date },
  //     { type: ltFieldLookup, field: FieldType.Date },
  //     { type: exactFieldLookup, field: FieldType.Date },
  //     { type: isNullFieldLookup },
  //     { type: inFieldLookup, array: true, field: FieldType.Date }
  //   ],
  //   forceParams: {
  //     date: true,
  //     time: false
  //   },
  //   defaultValue: null,
  //   deserializeValue: (value, field?: BaseField) => {
  //     if (!isSet(value)) {
  //       return value;
  //     }
  //     return moment(value);
  //   },
  //   serializeValue: (value, field?: BaseField) => {
  //     const format = field && field.params ? momentOutputFormat[field.params['output_format']] : undefined;
  //
  //     if (format) {
  //       return moment(value).format(format);
  //     } else {
  //       return moment(value).toISOString();
  //     }
  //   },
  //   valueToStr: (value, options = {}) => {
  //     return formatDateTimeFieldValue(value, options.field);
  //   }
  // },
  {
    jetBridgeTypes: ['TimeField'],
    name: FieldType.Time,
    label: 'Time',
    category: FieldDescriptionCategory.DateTime,
    description: 'Time only',
    icon: 'time',
    image: 'time_field',
    public: true,
    lookups: [
      { type: gteFieldLookup, field: FieldType.Time },
      { type: gtFieldLookup, field: FieldType.Time },
      { type: lteFieldLookup, field: FieldType.Time },
      { type: ltFieldLookup, field: FieldType.Time },
      { type: exactFieldLookup, field: FieldType.Time },
      { type: isEmptyFieldLookup },
      { type: isNullFieldLookup },
      { type: inFieldLookup, array: true, field: FieldType.Time }
    ],
    forceParams: {
      date: false,
      time: true
    },
    defaultValue: null,
    deserializeValue: (value, field?: BaseField) => {
      if (!isSet(value)) {
        return value;
      }

      const format = field && field.params ? field.params['output_format'] : undefined;
      let dt: moment.Moment;

      if (format == TimeOutputFormat.Number) {
        const multiplier = parseFloat(value);
        const millisecondsInDay = 24 * 60 * 60 * 1000;
        const milliseconds = millisecondsInDay * multiplier;
        return moment().startOf('day').add(milliseconds, 'milliseconds');
      } else {
        dt = parseTime(value);
      }

      if (!dt || !dt.isValid()) {
        return;
      }

      return dt;
    },
    serializeValue: (value, field?: BaseField) => {
      if (!isSet(value)) {
        return value;
      }

      const format = field && field.params ? field.params['output_format'] : undefined;

      if (format == TimeOutputFormat.Number) {
        const millisecondsInDay = 24 * 60 * 60 * 1000;
        const valueMilliseconds = parseInt(moment(value).format('x'), 10);
        const valueStartOfDayMilliseconds = parseInt(moment(value).startOf('day').format('x'), 10);
        const milliseconds = valueMilliseconds - valueStartOfDayMilliseconds;
        return milliseconds / millisecondsInDay;
      } else {
        return parseTime(value).format('HH:mm:SS');
      }
    },
    valueToStr: (value, options = {}) => {
      return formatTimeField(value, options.field);
    }
  },
  {
    jetBridgeTypes: ['JSONField'],
    name: FieldType.JSON,
    label: 'JSON',
    category: FieldDescriptionCategory.Others,
    description: 'Non-structured data, can be nested',
    icon: 'components',
    image: 'json_field',
    public: true,
    lookups: [
      { type: jsonContainsFieldLookup, field: FieldType.Text },
      { type: isNullFieldLookup },
      { type: exactFieldLookup, field: FieldType.Text },
      { type: inFieldLookup, array: true, field: FieldType.Text }
    ],
    defaultValue: undefined,
    deserializeValue: (value, field?: BaseField) => {
      if (value === undefined || value === null) {
        return value;
      }

      const storeObject: boolean = field && field.params ? field.params['store_object'] : undefined;
      let format: JsonOutputFormat = field && field.params ? field.params['output_format'] : undefined;

      if (!format) {
        if (storeObject !== undefined) {
          format = storeObject ? JsonOutputFormat.Object : JsonOutputFormat.String;
        } else if (typeof value === 'string') {
          try {
            // format = JsonOutputFormat.String;
            return JSON.parse(value);
          } catch (e) {}
        }

        format = JsonOutputFormat.Object;
      }

      if (format == JsonOutputFormat.Object) {
        return value;
      } else if (format == JsonOutputFormat.String) {
        if (isPlainObject(value) || isArray(value)) {
          return value;
        } else {
          try {
            return JSON.parse(value);
          } catch (e) {
            return;
          }
        }
      } else if (format == JsonOutputFormat.Firestore) {
        return deserializeFirestoreValue(value);
      }
    },
    serializeValue: (value, field?: BaseField) => {
      if (value === undefined || value === null) {
        return value;
      }

      const storeObject: boolean = field && field.params ? field.params['store_object'] : undefined;
      let format: JsonOutputFormat = field && field.params ? field.params['output_format'] : undefined;

      if (!format) {
        if (storeObject !== undefined) {
          format = storeObject ? JsonOutputFormat.Object : JsonOutputFormat.String;
        } else {
          format = JsonOutputFormat.Object;
        }
      }

      if (format == JsonOutputFormat.Object) {
        return value;
      } else if (format == JsonOutputFormat.String) {
        return JSON.stringify(value);
      } else if (format == JsonOutputFormat.Firestore) {
        return serializeFirestoreValue(value, field);
      }
    },
    valueToStr: (value, options = {}) => {
      if (!value) {
        return;
      }

      value = JSON.stringify(value);

      if (!options.noTruncate) {
        return value.substr(0, 64);
      } else {
        return value;
      }
    }
  },
  {
    jetBridgeTypes: ['FileField'],
    name: FieldType.File,
    label: 'File',
    category: FieldDescriptionCategory.Files,
    description: 'Field allowing any file attachments',
    icon: 'document',
    image: 'file_field',
    public: true,
    lookups: [],
    defaultValue: ''
  },
  {
    jetBridgeTypes: ['ImageField'],
    name: FieldType.Image,
    label: 'Image',
    category: FieldDescriptionCategory.Files,
    description: 'Field allowing image-only attachments.',
    icon: 'image',
    image: 'image_field',
    public: true,
    lookups: [],
    defaultValue: null
  },
  {
    jetBridgeTypes: ['VideoField'],
    name: FieldType.Video,
    label: 'Video',
    category: FieldDescriptionCategory.Files,
    description: 'Field allowing video-only attachments.',
    icon: 'play_2',
    image: 'video_field',
    public: true,
    lookups: [],
    defaultValue: null
  },
  {
    jetBridgeTypes: ['AudioField'],
    name: FieldType.Audio,
    label: 'Audio',
    category: FieldDescriptionCategory.Files,
    description: 'Field allowing audio-only attachments.',
    icon: 'audio',
    image: 'audio_field',
    public: true,
    lookups: [],
    defaultValue: null
  },
  {
    jetBridgeTypes: ['SqlField'],
    name: FieldType.Sql,
    label: 'Sql',
    lookups: [
      { type: containsFieldLookup, field: FieldType.Text },
      { type: exactFieldLookup, field: FieldType.Text },
      { type: startsWithFieldLookup, field: FieldType.Text },
      { type: endsWithFieldLookup, field: FieldType.Text },
      { type: isNullFieldLookup },
      { type: inFieldLookup, array: true, field: FieldType.Text }
    ],
    defaultValue: ''
  },
  {
    jetBridgeTypes: ['CodeField'],
    name: FieldType.Code,
    label: 'Code',
    icon: 'console',
    lookups: [
      { type: containsFieldLookup, field: FieldType.Text },
      { type: exactFieldLookup, field: FieldType.Text },
      { type: startsWithFieldLookup, field: FieldType.Text },
      { type: endsWithFieldLookup, field: FieldType.Text },
      { type: isNullFieldLookup },
      { type: inFieldLookup, array: true, field: FieldType.Text }
    ],
    defaultValue: ''
  },
  {
    jetBridgeTypes: ['IconField'],
    name: FieldType.Icon,
    label: 'Icon',
    lookups: [
      { type: exactFieldLookup, field: FieldType.Text },
      { type: startsWithFieldLookup, field: FieldType.Text },
      { type: endsWithFieldLookup, field: FieldType.Text },
      { type: inFieldLookup, array: true, field: FieldType.Text }
    ],
    defaultValue: undefined
  },
  {
    jetBridgeTypes: ['ColorField'],
    name: FieldType.Color,
    label: 'Color',
    category: FieldDescriptionCategory.Others,
    description: 'Color form a list or HEX value',
    icon: 'palette',
    image: 'color_field',
    public: true,
    lookups: [
      { type: exactFieldLookup, field: FieldType.Color },
      { type: startsWithFieldLookup, field: FieldType.Color },
      { type: endsWithFieldLookup, field: FieldType.Color },
      { type: inFieldLookup, array: true, field: FieldType.Color }
    ],
    defaultValue: null
  },
  {
    // TODO: Remove unused
    jetBridgeTypes: ['ModelDescriptionField'],
    name: FieldType.ModelDescription,
    label: 'Model Description',
    lookups: [
      { type: exactFieldLookup, field: FieldType.ModelDescription },
      { type: isNullFieldLookup },
      { type: inFieldLookup, array: true, field: FieldType.ModelDescription }
    ],
    defaultValue: null
  },
  {
    jetBridgeTypes: ['ResourceField'],
    name: FieldType.Resource,
    label: 'Resource',
    lookups: [
      { type: exactFieldLookup, field: FieldType.Resource },
      { type: isNullFieldLookup },
      { type: inFieldLookup, array: true, field: FieldType.Resource }
    ],
    defaultValue: null
  },
  {
    jetBridgeTypes: ['CustomViewField'],
    name: FieldType.CustomView,
    label: 'Custom View',
    lookups: [
      { type: exactFieldLookup, field: FieldType.CustomView },
      { type: isNullFieldLookup },
      { type: inFieldLookup, array: true, field: FieldType.CustomView }
    ],
    defaultValue: null
  },
  {
    jetBridgeTypes: ['CustomActionField'],
    name: FieldType.CustomAction,
    label: 'Custom Action',
    lookups: [
      { type: exactFieldLookup, field: FieldType.CustomAction },
      { type: isNullFieldLookup },
      { type: inFieldLookup, array: true, field: FieldType.CustomAction }
    ],
    defaultValue: null
  },
  {
    jetBridgeTypes: ['CharField', 'FixedCharField', 'TextField'],
    name: FieldType.Text,
    label: 'Text',
    category: FieldDescriptionCategory.Text,
    description: 'Text field allowing multiple lines',
    icon: 'text',
    image: 'text_field',
    public: true,
    lookups: [
      { type: containsFieldLookup, field: FieldType.Text },
      { type: exactFieldLookup, field: FieldType.Text },
      { type: startsWithFieldLookup, field: FieldType.Text },
      { type: endsWithFieldLookup, field: FieldType.Text },
      { type: isEmptyFieldLookup },
      { type: isNullFieldLookup },
      { type: inFieldLookup, array: true, field: FieldType.Text }
    ],
    defaultValue: '',
    valueToStr: (value, options = {}) => {
      if (!isSet(value)) {
        return;
      }

      const valueStr = String(value);
      const valueFormat = getFieldTextValueFormat(options.field);

      return applyValueFormat(valueStr, valueFormat);
    }
  },
  {
    jetBridgeTypes: ['RichTextField'],
    name: FieldType.RichText,
    label: 'Rich Text',
    category: FieldDescriptionCategory.Text,
    description: 'Text field that supports formatted text',
    icon: 'richtext',
    image: 'rich_text_field2',
    public: true,
    lookups: [
      { type: containsFieldLookup, field: FieldType.Text },
      { type: exactFieldLookup, field: FieldType.Text },
      { type: startsWithFieldLookup, field: FieldType.Text },
      { type: endsWithFieldLookup, field: FieldType.Text },
      { type: isEmptyFieldLookup },
      { type: isNullFieldLookup },
      { type: inFieldLookup, array: true, field: FieldType.Text }
    ],
    defaultValue: '',
    valueToStr: value => {
      return value;
    }
  },
  {
    jetBridgeTypes: ['PasswordField'],
    name: FieldType.Password,
    label: 'Password',
    category: FieldDescriptionCategory.Others,
    description: 'Field for entering and storing passwords',
    icon: 'key',
    image: 'password_field',
    public: true,
    lookups: [
      { type: containsFieldLookup, field: FieldType.Text },
      { type: exactFieldLookup, field: FieldType.Text },
      { type: startsWithFieldLookup, field: FieldType.Text },
      { type: endsWithFieldLookup, field: FieldType.Text },
      { type: isEmptyFieldLookup },
      { type: isNullFieldLookup },
      { type: inFieldLookup, array: true, field: FieldType.Text }
    ],
    defaultValue: '',
    valueToStr: value => {
      return value;
    }
  },
  {
    jetBridgeTypes: [
      'IntegerField',
      'NumberField',
      'FloatField',
      'DecimalField',
      'DoublePrecisionField',
      'SmallIntegerField',
      'BigIntegerField'
    ],
    name: FieldType.Number,
    label: 'Number',
    category: FieldDescriptionCategory.Numeric,
    description: 'Number, currency, percentage, file size and others',
    icon: 'number',
    image: 'number_field',
    public: true,
    lookups: [
      { type: exactFieldLookup, field: FieldType.Number },
      { type: gteFieldLookup, field: FieldType.Number },
      { type: gtFieldLookup, field: FieldType.Number },
      { type: lteFieldLookup, field: FieldType.Number },
      { type: ltFieldLookup, field: FieldType.Number },
      { type: isNullFieldLookup },
      { type: inFieldLookup, array: true, field: FieldType.Number }
    ],
    defaultValue: null,
    emptyValue: null,
    deserializeValue: value => {
      return parseNumber(value);
    },
    serializeValue: value => {
      return parseNumber(value);
    },
    valueToStr: (value, options = {}) => {
      if (!isSet(value)) {
        return;
      }

      const valueStr = String(value);
      const valueFormat = getFieldNumberValueFormat(options.field, { domain: options.domain });

      return applyValueFormat(valueStr, valueFormat);
    },
    validators: [
      (control: AbstractControl): ValidationErrors => {
        const value = control.value;

        if (isSet(value) && !isValidNumber(value)) {
          return { local: ['Not a valid number'] };
        }
      }
    ]
  },
  {
    jetBridgeTypes: ['RatingField'],
    name: FieldType.Rating,
    label: 'Rating',
    category: FieldDescriptionCategory.Numeric,
    description: 'Rate with a number of stars',
    icon: 'admins',
    image: 'rating_field',
    public: true,
    lookups: [
      { type: exactFieldLookup, field: FieldType.Rating },
      { type: gteFieldLookup, field: FieldType.Rating },
      { type: gtFieldLookup, field: FieldType.Rating },
      { type: lteFieldLookup, field: FieldType.Rating },
      { type: ltFieldLookup, field: FieldType.Rating },
      { type: isNullFieldLookup },
      { type: inFieldLookup, array: true, field: FieldType.Rating }
    ],
    defaultValue: null,
    emptyValue: null,
    deserializeValue: value => {
      return parseNumber(value);
    },
    serializeValue: value => {
      return parseNumber(value);
    },
    valueToStr: (value, options = {}) => {
      if (!isSet(value)) {
        return;
      }

      return String(parseNumber(value));
    },
    validators: [
      (control: AbstractControl): ValidationErrors => {
        const value = control.value;

        if (isSet(value) && !isValidNumber(value)) {
          return { local: ['Not a valid number'] };
        }
      }
    ]
  },
  {
    jetBridgeTypes: ['SliderField'],
    name: FieldType.Slider,
    label: 'Slider',
    category: FieldDescriptionCategory.Numeric,
    description: 'Number in range',
    icon: 'settings',
    image: 'slider_field',
    public: true,
    lookups: [
      { type: exactFieldLookup, field: FieldType.Slider },
      { type: gteFieldLookup, field: FieldType.Slider },
      { type: gtFieldLookup, field: FieldType.Slider },
      { type: lteFieldLookup, field: FieldType.Slider },
      { type: ltFieldLookup, field: FieldType.Slider },
      { type: isNullFieldLookup },
      { type: inFieldLookup, array: true, field: FieldType.Slider }
    ],
    defaultValue: null,
    emptyValue: null,
    deserializeValue: value => {
      return parseNumber(value);
    },
    serializeValue: value => {
      return parseNumber(value);
    },
    valueToStr: (value, options = {}) => {
      if (!isSet(value)) {
        return;
      }

      return String(parseNumber(value));
    },
    validators: [
      (control: AbstractControl): ValidationErrors => {
        const value = control.value;

        if (isSet(value) && !isValidNumber(value)) {
          return { local: ['Not a valid number'] };
        }
      }
    ]
  },
  {
    jetBridgeTypes: ['SignatureField'],
    name: FieldType.Signature,
    label: 'Signature',
    category: FieldDescriptionCategory.Files,
    description: 'Hand-written signature',
    icon: 'pen',
    image: 'signature',
    public: true,
    lookups: [],
    defaultValue: null
  },
  {
    jetBridgeTypes: ['URLField'],
    name: FieldType.URL,
    label: 'URL',
    category: FieldDescriptionCategory.Others,
    description: 'Web URL',
    icon: 'link',
    image: 'url_field',
    public: true,
    lookups: [
      { type: containsFieldLookup, field: FieldType.Text },
      { type: exactFieldLookup, field: FieldType.Text },
      { type: startsWithFieldLookup, field: FieldType.Text },
      { type: endsWithFieldLookup, field: FieldType.Text },
      { type: isEmptyFieldLookup },
      { type: isNullFieldLookup },
      { type: inFieldLookup, array: true, field: FieldType.Text }
    ],
    defaultValue: '',
    valueToStr: value => {
      return value;
    }
  },
  {
    jetBridgeTypes: ['FiltersField'],
    name: FieldType.Filters,
    label: 'Filters',
    lookups: [],
    defaultValue: []
  },
  {
    jetBridgeTypes: ['GeographyField'],
    name: FieldType.Location,
    label: 'Location',
    category: FieldDescriptionCategory.Others,
    description: 'Location specified with latitude and longitude',
    icon: 'pin',
    image: 'location_field',
    public: true,
    lookups: [],
    defaultValue: '',
    deserializeValue: (value, field?: BaseField): LatLngLiteral => {
      if (!isSet(value)) {
        return value;
      }

      const format: GeographyOutputFormat = field && field.params ? field.params['output_format'] : undefined;
      const invertedCoordinates: boolean = field && field.params ? field.params['inverted_coordinates'] : undefined;

      if (format == GeographyOutputFormat.PostgreSQL) {
        let geo: Object;

        try {
          geo = parse(value);
        } catch (e) {}

        if (geo && geo['type'] == 'Point' && geo['coordinates']) {
          return invertedCoordinates
            ? { lat: geo['coordinates'][0], lng: geo['coordinates'][1] }
            : { lat: geo['coordinates'][1], lng: geo['coordinates'][0] };
        }
      } else if (format == GeographyOutputFormat.Object) {
        const latitudeName =
          field && field.params && field.params['object_lat_field']
            ? field.params['object_lat_field']
            : defaultGeographyObjectOutputFormatLatitudeField;
        const longitudeName =
          field && field.params && field.params['object_lng_field']
            ? field.params['object_lng_field']
            : defaultGeographyObjectOutputFormatLongitudeField;
        const latitude = firstSet(value[latitudeName], value['latitude'], value['lat']);
        const longitude = firstSet(value[longitudeName], value['longitude'], value['lng']);

        if (isSet(latitude) && isSet(longitude)) {
          return invertedCoordinates ? { lat: longitude, lng: latitude } : { lat: latitude, lng: longitude };
        }
      } else if (format == GeographyOutputFormat.Array) {
        const latitude = isArray(value) ? value[0] : undefined;
        const longitude = isArray(value) ? value[1] : undefined;

        if (isSet(latitude) && isSet(longitude)) {
          return invertedCoordinates ? { lat: longitude, lng: latitude } : { lat: latitude, lng: longitude };
        }
      }
    },
    serializeValue: (value: LatLngLiteral, field?: BaseField) => {
      if (!isSet(value) || !isSet(value.lat) || !isSet(value.lng)) {
        return;
      }

      const format: GeographyOutputFormat = field && field.params ? field.params['output_format'] : undefined;
      const invertedCoordinates: boolean = field && field.params ? field.params['inverted_coordinates'] : undefined;

      if (format == GeographyOutputFormat.PostgreSQL) {
        const geo = {
          type: 'Point',
          coordinates: invertedCoordinates ? [value.lat, value.lng] : [value.lng, value.lat]
        };

        try {
          return stringify(geo);
        } catch (e) {}
      } else if (format == GeographyOutputFormat.Object) {
        const latitudeName = value['object_lat_field'] || defaultGeographyObjectOutputFormatLatitudeField;
        const longitudeName = value['object_lng_field'] || defaultGeographyObjectOutputFormatLongitudeField;

        return invertedCoordinates
          ? { [latitudeName]: value.lng, [longitudeName]: value.lat }
          : { [latitudeName]: value.lat, [longitudeName]: value.lng };
      } else if (format == GeographyOutputFormat.Array) {
        return invertedCoordinates ? [value.lng, value.lat] : [value.lat, value.lng];
      }
    },
    valueToStr: (value): string => {
      if (!value) {
        return;
      }

      if (isSet(value.lat) && isSet(value.lng)) {
        return [value.lat, value.lng].join(', ');
      }
    }
  }
];

export function getFieldDescriptionByType(type: FieldType): FieldDescription {
  const field = fieldDescriptions.find(item => item.name == type);

  if (!field) {
    return getFieldDescriptionByType(defaultFieldType);
  }

  return field;
}

export function parseFieldType(type: string): FieldType {
  const byName = fieldDescriptions.find(item => item.name == type);

  if (byName) {
    return byName.name;
  }

  const byJetBridgeType = fieldDescriptions.find(item => item.jetBridgeTypes.includes(type));

  if (byJetBridgeType) {
    return byJetBridgeType.name;
  }

  return defaultFieldType;
}
