var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injector } from '@angular/core';
import * as Sentry from '@sentry/browser';
import isEmpty from 'lodash/isEmpty';
import keys from 'lodash/keys';
import toPairs from 'lodash/toPairs';
import values from 'lodash/values';
import { combineLatest, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AdminMode } from '@modules/admin-mode';
import { ApiService, ServerRequestError } from '@modules/api';
import { detectFirestoreJSONFieldStructure, FieldType, GeographyOutputFormat, JsonOutputFormat } from '@modules/fields';
import { MenuGeneratorService } from '@modules/menu';
import { ModelDbField, modelDbFieldToParameterField, ModelDescription, ModelField, ModelFieldType } from '@modules/models';
import { SecretToken, SecretTokenService, SecretTokenType } from '@modules/projects';
import { HttpContentType, HttpMethod, HttpQuery, HttpQueryService, ListModelDescriptionQuery, ModelDescriptionQuery, QueryPagination, QueryType, StorageQuery } from '@modules/queries';
import { FIREBASE_CREATED_TIME, FIREBASE_ITEM_PRIMARY_KEY, FIREBASE_PARENT, FIREBASE_PRIMARY_KEY, FIREBASE_UPDATED_TIME, FirebaseDatabaseType, FirebaseRealTime } from '@modules/resources';
import { Storage } from '@modules/storages';
import { AppError, indentLines, isSet, objectsSortPredicate, trimAll } from '@shared';
import { ResourceGeneratorService } from '../resource-generator/resource-generator.service';
var apiBase = 'https://firestore.googleapis.com/v1/';
var ignoreKeys = [
    FIREBASE_PRIMARY_KEY,
    FIREBASE_ITEM_PRIMARY_KEY,
    FIREBASE_CREATED_TIME,
    FIREBASE_UPDATED_TIME,
    FIREBASE_PARENT
];
var responseTransformerMapItem = function (databasePath) { return "\nfunction mapItem(doc, params) {\n  const model = {\n    '" + FIREBASE_PRIMARY_KEY + "': doc['name'],\n    '" + FIREBASE_ITEM_PRIMARY_KEY + "': doc['name'].split('/').slice(-1)[0]\n  };\n  const types = {\n    'nullValue': () => null,\n    'integerValue': value => parseInt(value, 10),\n    'doubleValue': value => parseFloat(value),\n    'booleanValue': value => !!value,\n    'stringValue': value => String(value),\n    'arrayValue': value => value ? { arrayValue: value } : null,\n    'referenceValue': value => value,\n    'mapValue': value => value ? { mapValue: value } : null,\n    'timestampValue': value => value,\n    'geoPointValue': value => value\n  };\n\n  if (doc['fields']) {\n    const fields = Object.keys(doc['fields']).sort((lhs, rhs) => lhs.localeCompare(rhs));\n    for (const key of fields) {\n      const typeKey = Object.keys(doc['fields'][key])[0];\n      model[key] =  (types[typeKey] || String)(doc['fields'][key][typeKey]);\n    }\n  }\n\n  const parentPath = doc['name'].split('/').slice(0, -2).join('/');\n\n  model['" + FIREBASE_CREATED_TIME + "'] = doc['createTime'];\n  model['" + FIREBASE_UPDATED_TIME + "'] = doc['updateTime'];\n  model['" + FIREBASE_PARENT + "'] = parentPath != \"" + databasePath + "\" ? parentPath : '';\n\n  return model;\n}"; };
var ɵ0 = responseTransformerMapItem;
var responseTransformerGetItems = "\nfunction getItems(data, params) {\n  const items = data.filter(item => item['document']).map(item => mapItem(item['document'], params));\n  const fields = Object.keys(items.reduce((acc, model) => {\n      Object.keys(model).forEach(key => acc[key] = true);\n      return acc;\n    }, {}));\n\n  return {\n    fields: fields,\n    items: items\n  };\n}";
var maxRows = 99999;
var listResponseTransformer = function (databasePath, collectionId) { return responseTransformerMapItem(databasePath) + "\n" + responseTransformerGetItems + "\nvar documents = (data || [])\n  .filter(item => item['document'])\n  .filter(item => {\n    const collectionId = item['document']['name']\n      .substring(" + databasePath.length + " + 1)\n      .split('/')\n      .filter((item, i) => i % 2 == 0)\n      .join('/');\n    return collectionId == " + JSON.stringify(collectionId) + ";\n  });\nvar result = getItems(documents, params);\nreturn result.items;"; };
var ɵ1 = listResponseTransformer;
var listBodyWhere = function (databasePath, collectionId, parent) {
    if (!isSet(parent)) {
        return 'undefined';
    }
    var collectionIdParts = collectionId.split('/');
    var baseCollectionName = collectionIdParts.length > 1 ? collectionIdParts[0] : undefined;
    return "\n{\n    \"compositeFilter\": {\n        \"op\": \"AND\",\n        \"filters\": [\n            {\n                \"fieldFilter\": {\n                    \"field\": {\n                        \"fieldPath\": \"__name__\"\n                    },\n                    \"op\": \"GREATER_THAN_OR_EQUAL\",\n                    \"value\": {\n                        \"referenceValue\": \"" + databasePath + "/" + baseCollectionName + "/!\"\n                    }\n                }\n            },\n            {\n                \"fieldFilter\": {\n                    \"field\": {\n                        \"fieldPath\": \"__name__\"\n                    },\n                    \"op\": \"LESS_THAN_OR_EQUAL\",\n                    \"value\": {\n                        \"referenceValue\": \"" + databasePath + "/" + baseCollectionName + "/~~~~~~~~~~~~~~~~~~~~\"\n                    }\n                }\n            }\n        ]\n    }\n}";
};
var ɵ2 = listBodyWhere;
var listBodyJSON = function (databasePath, collectionId, fields, parent) {
    var collectionIdParts = collectionId.split('/');
    var collectionName = collectionIdParts[collectionIdParts.length - 1];
    var where = listBodyWhere(databasePath, collectionId, parent);
    return "{\n    \"structuredQuery\": {\n        \"from\": [{\n            \"collectionId\": \"" + collectionName + "\",\n            \"allDescendants\": " + (isSet(parent) ? 'true' : 'false') + "\n        }],\n        \"where\": " + trimAll(indentLines(trimAll(where), 8)) + ",\n        \"limit\": " + maxRows + "\n    }\n  }";
};
var ɵ3 = listBodyJSON;
var detailResponseTransformer = function (databasePath) { return responseTransformerGetItems + "\n" + responseTransformerMapItem(databasePath) + "\nvar result = getItems(data, params);\nreturn result.items[0];"; };
var ɵ4 = detailResponseTransformer;
var itemResponseTransformer = function (databasePath) { return responseTransformerMapItem(databasePath) + "\n\nreturn mapItem(data, params);"; };
var ɵ5 = itemResponseTransformer;
var itemBodyTransformer = function (databasePath, firebaseTypes) { return "\nconst dictOps = [\n  { criteria: value => value === null || value === undefined, serialize: () => {\n    return { nullValue: null };\n  } },\n  { criteria: (value, name) => fieldsMeta[name] && fieldsMeta[name].field == 'related_model', serialize: value => {\n    return value ? { referenceValue: value } : { nullValue: null };\n  } },\n  { criteria: value => typeof value == 'number' && value % 1 == 0, serialize: value => {\n    return { integerValue: value };\n  } },\n  { criteria: value => typeof value == 'number' && value % 1 != 0, serialize: value => {\n    return { doubleValue: value };\n  } },\n  { criteria: value => typeof value == 'boolean', serialize: value => {\n    return { booleanValue: value };\n  } },\n];\n\nconst fieldsResult = {};\nconst ignoreKeys = ['" + FIREBASE_PRIMARY_KEY + "', '" + FIREBASE_ITEM_PRIMARY_KEY + "', '" + FIREBASE_CREATED_TIME + "', '" + FIREBASE_UPDATED_TIME + "', '" + FIREBASE_PARENT + "'];\nconst firebaseTypes = JSON.parse(" + JSON.stringify(JSON.stringify(firebaseTypes)) + ");\n\nfor (const key of Object.keys(data)) {\n  if (ignoreKeys.includes(key) || data[key] === undefined) {\n    continue;\n  }\n\n  var value = undefined;\n\n  for (const op of dictOps) {\n    if (op.criteria(data[key], key)) {\n      value = op.serialize(data[key]);\n      break;\n    }\n  }\n\n  if (value === undefined) {\n    const type = firebaseTypes[key] || 'stringValue';\n\n    if (type != 'stringValue' && data[key] === '') {\n      continue;\n    }\n\n    if (type == 'arrayValue' || type == 'mapValue') {\n      value = data[key];\n    } else {\n      value = { [type]: data[key] };\n    }\n  }\n\n  fieldsResult[key] = value;\n}\n\nreturn {\n  fields: fieldsResult\n};"; };
var ɵ6 = itemBodyTransformer;
var errorTransformer = "// add custom transformation here\nif (http.code >= 200 && http.code < 400) {\n  // no error if success code\n  return null;\n} else if (data['error'] && data['error']['message']) {\n  // display error message if any\n  return data['error']['message'];\n} else {\n  // display error without message otherwise\n  return true;\n}";
export function chooseDisplayField(fields) {
    var modelFields = fields.filter(function (field) { return !ignoreKeys.includes(field.name); });
    var priorityFields = [
        FieldType.Text,
        FieldType.URL,
        FieldType.Number,
        FieldType.DateTime,
        FieldType.MultipleSelect,
        FieldType.Select
    ];
    var priorityField = priorityFields.reduce(function (acc, item) {
        if (acc) {
            return acc;
        }
        else {
            return modelFields.find(function (field) { return field.item.field == item; });
        }
    }, undefined);
    if (priorityField) {
        return priorityField.name;
    }
    return FIREBASE_ITEM_PRIMARY_KEY;
}
var FirebaseGeneratorService = /** @class */ (function (_super) {
    __extends(FirebaseGeneratorService, _super);
    function FirebaseGeneratorService(mode, menuGeneratorService, secretTokenService, apiService, injector, http, httpQueryService) {
        var _this = _super.call(this) || this;
        _this.mode = mode;
        _this.menuGeneratorService = menuGeneratorService;
        _this.secretTokenService = secretTokenService;
        _this.apiService = apiService;
        _this.injector = injector;
        _this.http = http;
        _this.httpQueryService = httpQueryService;
        _this.tokenName = 'access_token';
        return _this;
    }
    FirebaseGeneratorService.prototype.getParamsOptions = function (project, environment, resource) {
        var _this = this;
        return this.getServiceToken(project.uniqueName, environment.uniqueName, resource.uniqueName).pipe(switchMap(function (serviceToken) {
            return _this.readServiceToken(serviceToken).pipe(map(function (result) { return [serviceToken, result ? result.projectId : undefined, result ? result.accessToken : undefined]; }));
        }), map(function (_a) {
            var serviceToken = _a[0], projectId = _a[1], accessToken = _a[2];
            return __assign({ serviceToken: serviceToken, projectId: projectId, accessToken: accessToken, databaseOption: resource.params['database_option'] || { type: FirebaseDatabaseType.Firestore } }, (resource.isSynced() && {
                realTime: resource.params['firebase_real_time'] || FirebaseRealTime.Snapshots
            }));
        }));
    };
    FirebaseGeneratorService.prototype.getServiceToken = function (projectName, environmentName, resourceName) {
        return this.secretTokenService
            .getDetail(projectName, environmentName, resourceName, this.tokenName, this.mode == AdminMode.Builder)
            .pipe(map(function (result) {
            if (result.params['service_token']) {
                return JSON.stringify(result.params['service_token']);
            }
            return '';
        }));
    };
    FirebaseGeneratorService.prototype.readServiceToken = function (value) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            // const value = this.form.value['service_token'].trim();
            try {
                var serviceToken = JSON.parse(value.trim());
                var url = _this.apiService.methodURL('resource_auth/');
                var headers = new HttpHeaders();
                var data = {
                    name: 'firebase',
                    service_token: serviceToken
                };
                headers = _this.apiService.setHeadersToken(headers);
                return _this.http.post(url, data, { headers: headers }).pipe(map(function (result) {
                    // this.form.patchValue({
                    //   project_id: result['project_id'],
                    //   access_token: result['token']
                    // });
                    return {
                        projectId: result['project_id'],
                        accessToken: result['token']
                    };
                }
                // () => {
                //   this.form.patchValue({
                //     project_id: '',
                //     access_token: ''
                //   });
                // }
                ));
            }
            catch (e) {
                // this.form.patchValue({
                //   project_id: '',
                //   access_token: ''
                // });
                return of(undefined);
            }
        }));
    };
    FirebaseGeneratorService.prototype.createModelDescriptionFields = function (databasePath, documents, parent) {
        var fields = {};
        var typesMap = {
            booleanValue: { type: FieldType.Boolean },
            integerValue: { type: FieldType.Number },
            doubleValue: { type: FieldType.Number },
            timestampValue: { type: FieldType.DateTime },
            stringValue: { type: FieldType.Text },
            // bytesValue: string,
            referenceValue: {
                type: FieldType.RelatedModel,
                paramsResolver: function (value) {
                    if (!isSet(value)) {
                        return;
                    }
                    var relatedModel = String(value)
                        .substring(databasePath.length + 1)
                        .split('/')
                        .slice(0, -1)
                        .join('/');
                    return {
                        related_model: {
                            model: "{{resource}}." + relatedModel
                        }
                    };
                }
            },
            geoPointValue: {
                type: FieldType.Location,
                paramsResolver: function () {
                    return {
                        output_format: GeographyOutputFormat.Object
                    };
                }
            },
            arrayValue: {
                type: FieldType.JSON,
                paramsResolver: function (value, objectValues) {
                    value = value ? { arrayValue: value } : null;
                    objectValues = objectValues.map(function (item) { return (item ? { arrayValue: item } : null); });
                    var mergedValue = __assign({}, value, objectValues.reduce(function (acc, item) {
                        acc = __assign({}, acc, item);
                        return acc;
                    }, {}));
                    var structure = detectFirestoreJSONFieldStructure(mergedValue, {
                        resource: '{{resource}}',
                        databasePath: databasePath
                    });
                    if (structure) {
                        return { output_format: JsonOutputFormat.Firestore, display_fields: true, structure: structure };
                    }
                    else {
                        return { output_format: JsonOutputFormat.Firestore };
                    }
                },
                paramsResolverPassObjectValues: true
            },
            mapValue: {
                type: FieldType.JSON,
                paramsResolver: function (value, objectValues) {
                    value = value ? { mapValue: value } : null;
                    objectValues = objectValues.map(function (item) { return (item ? { mapValue: item } : null); });
                    var mergedValue = __assign({}, value, objectValues.reduce(function (acc, item) {
                        acc = __assign({}, acc, item);
                        return acc;
                    }, {}));
                    var structure = detectFirestoreJSONFieldStructure(mergedValue, {
                        resource: '{{resource}}',
                        databasePath: databasePath
                    });
                    if (structure) {
                        return { output_format: JsonOutputFormat.Firestore, display_fields: true, structure: structure };
                    }
                    else {
                        return { output_format: JsonOutputFormat.Firestore };
                    }
                },
                paramsResolverPassObjectValues: true
            }
        };
        var typesDefault = { type: FieldType.Text };
        documents.forEach(function (model) {
            if (!model.fields) {
                return;
            }
            toPairs(model.fields).forEach(function (_a) {
                var name = _a[0], valueObject = _a[1];
                var objectKey = keys(valueObject)[0];
                var objectValue = values(valueObject)[0];
                var type = typesMap[objectKey] || typesDefault;
                if (!isSet(objectValue) || fields.hasOwnProperty(name)) {
                    return;
                }
                fields[name] = { type: type.type };
                if (type.paramsResolver) {
                    var objectValues = void 0;
                    if (type.paramsResolverPassObjectValues) {
                        objectValues = documents.reduce(function (acc, item) {
                            var value = item.fields ? item.fields[name] : undefined;
                            if (isSet(value) && keys(value)[0] == objectKey) {
                                acc.push(values(value)[0]);
                            }
                            return acc;
                        }, []);
                    }
                    var params = type.paramsResolver(objectValue, objectValues);
                    if (params) {
                        fields[name].params = params;
                    }
                }
            });
        });
        if (documents.length && documents[0].fields) {
            keys(documents[0].fields).forEach(function (name) {
                if (!fields.hasOwnProperty(name)) {
                    fields[name] = { type: typesDefault.type };
                }
            });
        }
        return [
            { name: FIREBASE_PRIMARY_KEY, typeInfo: { type: FieldType.Text } },
            { name: FIREBASE_ITEM_PRIMARY_KEY, typeInfo: { type: FieldType.Text } }
        ].concat(toPairs(fields)
            .map(function (_a) {
            var name = _a[0], typeInfo = _a[1];
            return ({ name: name, typeInfo: typeInfo });
        })
            .sort(objectsSortPredicate('name')), [
            { name: FIREBASE_CREATED_TIME, typeInfo: { type: FieldType.DateTime } },
            { name: FIREBASE_UPDATED_TIME, typeInfo: { type: FieldType.DateTime } }
        ], (isSet(parent) ? [{ name: FIREBASE_PARENT, typeInfo: { type: FieldType.RelatedModel } }] : [])).map(function (item) {
            var field = new ModelField();
            var dbField = new ModelDbField();
            dbField.name = item.name;
            dbField.field = item.typeInfo.type;
            dbField.editable = true;
            dbField.sortable = true;
            dbField.required = false;
            dbField.filterable = true;
            dbField.updateFieldDescription();
            if (item.typeInfo.params) {
                dbField.params = item.typeInfo.params;
            }
            if (dbField.name == FIREBASE_PRIMARY_KEY) {
                dbField.verboseName = 'document path';
                dbField.editable = false;
                dbField.sortable = false;
                dbField.required = true;
            }
            else if (dbField.name == FIREBASE_ITEM_PRIMARY_KEY) {
                dbField.verboseName = 'document ID';
                dbField.editable = false;
                dbField.sortable = false;
                dbField.required = true;
            }
            else if (dbField.name == FIREBASE_PARENT) {
                dbField.verboseName = 'parent path';
                dbField.editable = false;
                dbField.sortable = false;
                dbField.required = true;
                dbField.params = {
                    related_model: {
                        model: ['{{resource}}', parent].join('.')
                    },
                    custom_primary_key: FIREBASE_PRIMARY_KEY
                };
            }
            else if (dbField.name == FIREBASE_CREATED_TIME) {
                dbField.verboseName = 'created time';
                dbField.editable = false;
                dbField.sortable = true;
                dbField.required = true;
            }
            else if (dbField.name == FIREBASE_UPDATED_TIME) {
                dbField.verboseName = 'updated time';
                dbField.editable = false;
                dbField.sortable = true;
                dbField.required = true;
            }
            field.name = item.name;
            field.type = ModelFieldType.Db;
            field.item = dbField;
            return field;
        });
    };
    FirebaseGeneratorService.prototype.createModelDescription = function (databasePath, collectionId, setUpHeaders, headers) {
        var _this = this;
        var collectionIdParts = collectionId.split('/');
        var baseCollectionName = collectionIdParts.length > 1 ? collectionIdParts[0] : undefined;
        var collectionName = collectionIdParts[collectionIdParts.length - 1];
        var collectionFullName = collectionIdParts.join('_');
        var parentFullName = collectionIdParts.length > 1 ? collectionIdParts.slice(0, -1).join('_') : undefined;
        var url = "" + apiBase + databasePath + ":runQuery?alt=json";
        var data = {
            structuredQuery: {
                from: [
                    {
                        collectionId: collectionName,
                        allDescendants: true
                    }
                ],
                where: isSet(baseCollectionName)
                    ? {
                        compositeFilter: {
                            op: 'AND',
                            filters: [
                                {
                                    fieldFilter: {
                                        field: {
                                            fieldPath: '__name__'
                                        },
                                        op: 'GREATER_THAN_OR_EQUAL',
                                        value: {
                                            referenceValue: databasePath + "/" + baseCollectionName + "/!"
                                        }
                                    }
                                },
                                {
                                    fieldFilter: {
                                        field: {
                                            fieldPath: '__name__'
                                        },
                                        op: 'LESS_THAN_OR_EQUAL',
                                        value: {
                                            referenceValue: databasePath + "/" + baseCollectionName + "/~~~~~~~~~~~~~~~~~~~~"
                                        }
                                    }
                                }
                            ]
                        }
                    }
                    : undefined,
                orderBy: [
                    {
                        field: {
                            fieldPath: '__name__'
                        }
                    }
                ],
                limit: 100
            }
        };
        return this.http
            .post(url, data, { headers: setUpHeaders })
            .pipe(map(function (result) {
            var documents = result.map(function (item) { return item.document; }).filter(function (item) { return item; });
            if (!documents.length) {
                return;
            }
            var firebaseTypes = documents.reduce(function (prev, document) {
                if (document.fields) {
                    toPairs(document.fields).reduce(function (prevInner, _a) {
                        var fieldName = _a[0], field = _a[1];
                        var type = keys(field)[0];
                        if (type != 'nullValue') {
                            prevInner[fieldName] = type;
                        }
                        return prevInner;
                    }, prev);
                }
                return prev;
            }, {});
            var modelDescription = new ModelDescription();
            modelDescription.primaryKeyField = FIREBASE_PRIMARY_KEY;
            modelDescription.project = '{{project}}';
            modelDescription.resource = '{{resource}}';
            modelDescription.model = collectionFullName;
            modelDescription.fields = _this.createModelDescriptionFields(databasePath, documents, parentFullName);
            modelDescription.hidden = false;
            modelDescription.generateVerboseNameIfNeeded();
            modelDescription.displayField = chooseDisplayField(modelDescription.fields);
            var listUrl = apiBase + "{{params.hasOwnProperty('" + FIREBASE_PARENT + "') && params['" + FIREBASE_PARENT + "'] ? params['" + FIREBASE_PARENT + "'] + '/" + collectionName + "' : '" + databasePath + "/" + collectionName + "'}}";
            var detailUrl = apiBase + "{{params.hasOwnProperty('" + FIREBASE_PARENT + "') && params['" + FIREBASE_PARENT + "'] ? params['" + FIREBASE_PARENT + "'] + '/" + collectionName + "/' + params['" + FIREBASE_ITEM_PRIMARY_KEY + "'] : '" + databasePath + "/" + collectionName + "/' + params['" + FIREBASE_ITEM_PRIMARY_KEY + "']}}";
            var queryHeaders = toPairs(headers).map(function (_a) {
                var k = _a[0], v = _a[1];
                return { name: k, value: v };
            });
            var getQuery = new ListModelDescriptionQuery();
            var getHttpQuery = new HttpQuery();
            var parentOptionalParameter = modelDescription.dbFields
                .filter(function (item) { return item.name == FIREBASE_PARENT; })
                .map(function (item) {
                var parameter = modelDbFieldToParameterField(item);
                parameter.required = false;
                return parameter;
            })[0];
            getHttpQuery.method = HttpMethod.POST;
            getHttpQuery.url = "https://content-firestore.googleapis.com/v1beta1/{{params.hasOwnProperty('" + FIREBASE_PARENT + "') && params['" + FIREBASE_PARENT + "'] ? params['" + FIREBASE_PARENT + "'] : '" + databasePath + "'}}:runQuery?alt=json";
            getHttpQuery.headers = queryHeaders;
            getHttpQuery.responseTransformer = listResponseTransformer(databasePath, collectionId);
            getHttpQuery.bodyType = HttpContentType.JSON;
            getHttpQuery.body = listBodyJSON(databasePath, collectionId, modelDescription.dbFields
                .filter(function (item) { return item.filterable; })
                .filter(function (item) { return item.name != FIREBASE_PARENT; })
                .map(function (item) { return item.name; }), parentFullName);
            getHttpQuery.errorTransformer = errorTransformer;
            getQuery.queryType = QueryType.Http;
            getQuery.httpQuery = getHttpQuery;
            getQuery.pagination = QueryPagination.Offset;
            getQuery.frontendFiltering = true;
            getQuery.sorting = true;
            modelDescription.getQuery = getQuery;
            modelDescription.getParameters = (isSet(parentFullName) ? [parentOptionalParameter] : []).slice();
            // modelDescription.getParameters = flatten(
            //   modelDescription.dbFields.map(field => {
            //     const fieldLookups = field.lookups.map(lookup => lookup.type.lookup);
            //
            //     return fieldLookups
            //       .filter(lookup => supportedLookups.includes(lookup))
            //       .map(lookup => {
            //         const parameter = new ParameterField();
            //         const lookupItem = lookups.find(i => i.lookup == lookup);
            //
            //         parameter.name = lookup ? [field.name, lookup].join('__') : field.name;
            //         parameter.verboseName = `${field.verboseName || field.name} ${lookupItem.verboseName}`;
            //         parameter.description = field.description;
            //         parameter.field = field.field;
            //         parameter.required = field.required;
            //         parameter.defaultType = field.defaultType;
            //         parameter.defaultValue = field.defaultValue;
            //         parameter.params = field.params;
            //
            //         return parameter;
            //       });
            //   })
            // );
            // modelDescription.getInputs = flatten(
            //   modelDescription.dbFields
            //     .filter(item => !readOnlyFields.includes(item.name))
            //     .map(field => {
            //       const fieldLookups = field.lookups.map(lookup => lookup.type.lookup);
            //
            //       return fieldLookups
            //         .filter(lookup => supportedLookups.includes(lookup))
            //         .map(lookup => {
            //           const parameter = new Input();
            //
            //           parameter.name = lookup ? [field.name, lookup].join('__') : field.name;
            //           parameter.valueType = InputValueType.Filter;
            //           parameter.filterField = field.name;
            //           parameter.filterLookup = lookup;
            //
            //           return parameter;
            //         });
            //     })
            // );
            // const getDetailQuery = new ModelDescriptionQuery();
            // const getDetailHttpQuery = new HttpQuery();
            //
            // getDetailHttpQuery.method = HttpMethod.POST;
            // getDetailHttpQuery.url = getHttpQuery.url;
            // getDetailHttpQuery.headers = queryHeaders;
            // getDetailHttpQuery.responseTransformer = detailResponseTransformer(databasePath);
            // getDetailHttpQuery.bodyType = getHttpQuery.bodyType;
            // getDetailHttpQuery.body = getHttpQuery.body;
            // getDetailQuery.queryType = QueryType.Http;
            // getDetailQuery.httpQuery = getDetailHttpQuery;
            //
            // modelDescription.getDetailQuery = getDetailQuery;
            // modelDescription.getDetailParameters = [...modelDescription.getParameters];
            var createQuery = new ModelDescriptionQuery();
            var createHttpQuery = new HttpQuery();
            createHttpQuery.method = HttpMethod.POST;
            createHttpQuery.url = listUrl;
            createHttpQuery.headers = queryHeaders;
            createHttpQuery.queryParams = [{ name: 'documentId', value: "{{params." + FIREBASE_ITEM_PRIMARY_KEY + "}}" }];
            createHttpQuery.bodyTransformer = itemBodyTransformer(databasePath, firebaseTypes);
            createHttpQuery.responseTransformer = itemResponseTransformer(databasePath);
            createHttpQuery.errorTransformer = errorTransformer;
            createQuery.queryType = QueryType.Http;
            createQuery.httpQuery = createHttpQuery;
            modelDescription.createQuery = createQuery;
            modelDescription.createParametersUseDefaults = true;
            var updateQuery = new ModelDescriptionQuery();
            var updateHttpQuery = new HttpQuery();
            updateHttpQuery.method = HttpMethod.PATCH;
            updateHttpQuery.url = [
                detailUrl,
                "{{(fields || []).filter(f => !onlyFields || onlyFields.includes(f)).filter(f => !['" + FIREBASE_PRIMARY_KEY + "', '" + FIREBASE_ITEM_PRIMARY_KEY + "', '" + FIREBASE_CREATED_TIME + "', '" + FIREBASE_UPDATED_TIME + "', '" + FIREBASE_PARENT + "'].includes(f)).map(f => 'updateMask.fieldPaths=`' + f + '`').join('&')}}"
            ].join('?');
            updateHttpQuery.headers = queryHeaders;
            updateHttpQuery.bodyTransformer = itemBodyTransformer(databasePath, firebaseTypes);
            updateHttpQuery.responseTransformer = itemResponseTransformer(databasePath);
            updateHttpQuery.errorTransformer = errorTransformer;
            updateQuery.queryType = QueryType.Http;
            updateQuery.httpQuery = updateHttpQuery;
            modelDescription.updateQuery = updateQuery;
            modelDescription.updateParametersUseDefaults = true;
            var deleteQuery = new ModelDescriptionQuery();
            var deleteHttpQuery = new HttpQuery();
            deleteHttpQuery.method = HttpMethod.DELETE;
            deleteHttpQuery.url = detailUrl;
            deleteHttpQuery.headers = queryHeaders;
            deleteHttpQuery.errorTransformer = errorTransformer;
            deleteQuery.queryType = QueryType.Http;
            deleteQuery.httpQuery = deleteHttpQuery;
            modelDescription.deleteQuery = deleteQuery;
            modelDescription.deleteParametersUseDefaults = true;
            return modelDescription;
        }));
    };
    FirebaseGeneratorService.prototype.encodeCollectionPath = function (path) {
        return path
            .split('/')
            .map(function (item) { return encodeURIComponent(item); })
            .join('/');
    };
    FirebaseGeneratorService.prototype.listCollectionIds = function (databasePath, collectionPath, setUpHeaders, collectionIds, pageToken) {
        var _this = this;
        if (collectionIds === void 0) { collectionIds = []; }
        var url = "" + apiBase + databasePath + ":runQuery?alt=json";
        var data = {
            structuredQuery: {
                select: {
                    fields: [
                        {
                            fieldPath: '__name__'
                        }
                    ]
                },
                from: [
                    {
                        allDescendants: true
                    }
                ],
                orderBy: [
                    {
                        field: {
                            fieldPath: '__name__'
                        }
                    }
                ],
                startAt: pageToken
                    ? {
                        values: [{ referenceValue: pageToken }],
                        before: false
                    }
                    : null,
                limit: maxRows
            }
        };
        return this.http
            .post(url, data, { headers: setUpHeaders })
            .pipe(switchMap(function (result) {
            collectionIds = result.reduce(function (acc, item) {
                if (item.document) {
                    var collectionId = item.document.name
                        .replace(databasePath, '')
                        .split('/')
                        .slice(1)
                        .filter(function (_, i) { return i % 2 == 0; })
                        .join('/');
                    if (!acc.includes(collectionId)) {
                        acc.push(collectionId);
                    }
                }
                return acc;
            }, collectionIds);
            var lastDocument = result.length ? result[result.length - 1].document : undefined;
            if (result.length < maxRows || !lastDocument) {
                return of(collectionIds);
            }
            return _this.listCollectionIds(databasePath, collectionPath, setUpHeaders, collectionIds, lastDocument.name);
        }));
    };
    FirebaseGeneratorService.prototype.createModelDescriptions = function (databasePath, collectionPath, setUpHeaders, headers) {
        var _this = this;
        return this.listCollectionIds(databasePath, collectionPath, setUpHeaders).pipe(switchMap(function (collectionIds) {
            if (!collectionIds || !collectionIds.length) {
                return of([]);
            }
            var obs = collectionIds.map(function (collectionId) {
                var collectionIdParts = collectionId.split('/');
                var parentId = collectionIdParts.length > 1 ? collectionIdParts.slice(0, -1).join('/') : undefined;
                if (isSet(parentId) && !collectionIds.includes(parentId)) {
                    return of(undefined);
                }
                return _this.createModelDescription(databasePath, collectionId, setUpHeaders, headers).pipe(catchError(function (e) {
                    Sentry.captureException(e);
                    return of(undefined);
                }));
            });
            return (obs.length ? combineLatest.apply(void 0, obs) : of([])).pipe(map(function (modelDescriptions) {
                return modelDescriptions.filter(function (item) { return item != undefined; });
            }));
        }), catchError(function (e) {
            if (e instanceof HttpErrorResponse && e.error && e.error.error && e.error.error.message) {
                return throwError(new AppError(e.error.error.message));
            }
            else {
                return throwError(e);
            }
        }), this.apiService.catchApiError());
    };
    FirebaseGeneratorService.prototype.createStorages = function (projectId, setUpHeaders) {
        var _this = this;
        var query = new HttpQuery();
        query.url = "https://storage.googleapis.com/storage/v1/b?project=" + projectId;
        query.headers = toPairs(setUpHeaders).map(function (_a) {
            var k = _a[0], v = _a[1];
            return { name: k, value: v };
        });
        return this.httpQueryService.requestBody(query).pipe(map(function (response) {
            if (!response['items'] || !response['items'].length) {
                return [];
            }
            return response['items'].map(function (item, i) {
                var storage = new Storage();
                var bucketId = item['id'];
                var bucketName = item['name'];
                storage.uniqueName = bucketId;
                storage.name = bucketName;
                storage.uploadQuery = new StorageQuery();
                storage.uploadQuery.queryType = QueryType.Http;
                storage.uploadQuery.httpQuery = new HttpQuery();
                storage.uploadQuery.httpQuery.method = HttpMethod.POST;
                storage.uploadQuery.httpQuery.url = "https://storage.googleapis.com/upload/storage/v1/b/" + bucketId + "/o?uploadType=multipart";
                storage.uploadQuery.httpQuery.headers = [{ name: 'Authorization', value: "Bearer {-" + _this.tokenName + "-}" }];
                storage.uploadQuery.httpQuery.bodyType = HttpContentType.FormData;
                storage.uploadQuery.httpQuery.body = [
                    {
                        name: 'metadata',
                        // value: `{
                        //   "name": "{{path}}{{file_name}}",
                        //   "predefinedAcl": "publicRead",
                        //   "mimeType": "{{file_mime_type}}",
                        //   "metadata": { "firebaseStorageDownloadTokens": "{{jet.uuid}}" }
                        // }`,
                        value: "{\n                  \"name\": \"{{path}}{{file_name}}\",\n                  \"predefinedAcl\": \"publicRead\",\n                  \"metadata\": { \"firebaseStorageDownloadTokens\": \"{{jet.uuid}}\" }\n                }",
                        contentType: 'application/json'
                    },
                    {
                        name: 'file',
                        value: '{{file}}'
                    }
                ];
                storage.uploadQuery.httpQuery.responseTransformer = "return 'https://firebasestorage.googleapis.com/v0/b/" + bucketId + "/o/' + encodeURIComponent(data.name) + '?alt=media&token=' + data.metadata.firebaseStorageDownloadTokens;";
                storage.removeQuery = new StorageQuery();
                storage.removeQuery.queryType = QueryType.Http;
                storage.removeQuery.httpQuery = new HttpQuery();
                storage.removeQuery.httpQuery.method = HttpMethod.DELETE;
                storage.removeQuery.httpQuery.url = "https://storage.googleapis.com/storage/v1/b/" + bucketId + "/o/{{encodeURIComponent(path)}}";
                storage.removeQuery.httpQuery.headers = [{ name: 'Authorization', value: "Bearer {-" + _this.tokenName + "-}" }];
                storage.removeQuery.httpQuery.bodyType = HttpContentType.Raw;
                storage.createDirectoryQuery = new StorageQuery();
                storage.createDirectoryQuery.queryType = QueryType.Http;
                storage.createDirectoryQuery.httpQuery = new HttpQuery();
                storage.createDirectoryQuery.httpQuery.method = HttpMethod.POST;
                storage.createDirectoryQuery.httpQuery.url = "https://storage.googleapis.com/upload/storage/v1/b/" + bucketId + "/o";
                storage.createDirectoryQuery.httpQuery.headers = [
                    { name: 'Authorization', value: "Bearer {-" + _this.tokenName + "-}" }
                ];
                storage.createDirectoryQuery.httpQuery.queryParams = [
                    {
                        name: 'uploadType',
                        value: 'media'
                    },
                    {
                        name: 'name',
                        value: '{{path}}/'
                    }
                ];
                storage.getQuery = new StorageQuery();
                storage.getQuery.queryType = QueryType.Http;
                storage.getQuery.httpQuery = new HttpQuery();
                storage.getQuery.httpQuery.method = HttpMethod.GET;
                storage.getQuery.httpQuery.url = "https://storage.googleapis.com/storage/v1/b/" + bucketId + "/o";
                storage.getQuery.httpQuery.headers = [{ name: 'Authorization', value: "Bearer {-" + _this.tokenName + "-}" }];
                // storage.getQuery.httpQuery.bodyType = HttpContentType.JSON;
                storage.getQuery.httpQuery.queryParams = [
                    {
                        name: 'delimiter',
                        value: '/'
                    },
                    {
                        name: 'prefix',
                        value: '{{path}}'
                    }
                ];
                storage.getQuery.httpQuery.responseTransformer = "\nconst folders = (data.prefixes || []).map(item => {\n    return {\n      type: 'folder',\n      path: item\n    };\n});\n\nconst files = (data.items || []).filter(item => !item['name'].endsWith('/')).map(item => {\n    return {\n      type: item['name'].substr(-1) == '/' ? 'folder' : 'file',\n      path: item['name'],\n      url: item.metadata ? 'https://firebasestorage.googleapis.com/v0/b/" + bucketId + "/o/' + encodeURIComponent(item.name) + '?alt=media&token=' + item.metadata.firebaseStorageDownloadTokens : undefined,\n      size: item.size,\n      created: item.timeCreated,\n      updated: item.updated\n    };\n});\n\n\nreturn [...folders, ...files];";
                return storage;
            });
        }));
    };
    FirebaseGeneratorService.prototype.generateResourceParams = function (options) {
        return {
            database_option: options.databaseOption,
            firebase_real_time: options.realTime
        };
    };
    FirebaseGeneratorService.prototype.generateSyncInterval = function (options) {
        if (options.realTime == FirebaseRealTime.Snapshots) {
            return 60 * 24;
        }
        else {
            return 60;
        }
    };
    FirebaseGeneratorService.prototype.generateParams = function (project, environment, typeItem, options) {
        var _this = this;
        var setUpHeaders = {
            Authorization: "Bearer " + options.accessToken
        };
        var databaseParams = options.databaseOption.type == FirebaseDatabaseType.Realtime
            ? this.generateRealtimeParams(project, typeItem, options)
            : this.generateFirestoreParams(project, typeItem, options);
        return combineLatest(databaseParams, this.createStorages(options.projectId, setUpHeaders)).pipe(map(function (_a) {
            var params = _a[0], storages = _a[1];
            var resourceParams = _this.generateResourceParams(options);
            var token = new SecretToken();
            token.name = _this.tokenName;
            token.type = SecretTokenType.Firebase;
            token.value = '';
            try {
                token.params = {
                    service_token: JSON.parse(options.serviceToken)
                };
            }
            catch (e) {
                token.params = {};
            }
            return __assign({}, params, { resourceParams: resourceParams, secretTokens: [token.serialize()], storages: storages.map(function (item) { return item.serialize(); }) });
        }));
    };
    FirebaseGeneratorService.prototype.generateRealtimeParams = function (project, typeItem, options) {
        var setUpHeaders = {
            Authorization: "Bearer " + options.accessToken
        };
        var databaseUrl = options.databaseOption.id + "/.json";
        return this.http.get(databaseUrl, { headers: setUpHeaders }).pipe(map(function (data) {
            if (data === null || isEmpty(data)) {
                throw new AppError('Realtime database does not have any Data');
            }
            return {};
        }), catchError(function (e) {
            if (e instanceof HttpErrorResponse && e.error && e.error.error && e.error.error.message) {
                return throwError(new AppError(e.error.error.message));
            }
            else {
                return throwError(e);
            }
        }), this.apiService.catchApiError());
    };
    FirebaseGeneratorService.prototype.generateFirestoreParams = function (project, typeItem, options) {
        var _this = this;
        var databasePath = "projects/" + options.projectId + "/databases/(default)/documents";
        var setUpHeaders = {
            Authorization: "Bearer " + options.accessToken
        };
        var headers = {
            Authorization: "Bearer {-" + this.tokenName + "-}"
        };
        return this.createModelDescriptions(databasePath, '', setUpHeaders, headers).pipe(map(function (modelDescriptions) {
            if (!modelDescriptions.length) {
                throw new ServerRequestError('Firestore database does not have any Collections');
            }
            var token = new SecretToken();
            token.name = _this.tokenName;
            token.type = SecretTokenType.Firebase;
            token.value = '';
            try {
                token.params = {
                    service_token: JSON.parse(options.serviceToken)
                };
            }
            catch (e) {
                token.params = {};
            }
            return {
                modelDescriptions: modelDescriptions.map(function (item) { return item.serialize(); })
            };
        }));
    };
    return FirebaseGeneratorService;
}(ResourceGeneratorService));
export { FirebaseGeneratorService };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6 };
