/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../fields/components/auto-field/auto-field.component.ngfactory";
import * as i2 from "../../../../fields/components/auto-field/auto-field.component";
import * as i3 from "./model-element-item.component";
import * as i4 from "../../../../action-queries/services/action-controller/action-controller.service";
var styles_ModelElementItemComponent = [];
var RenderType_ModelElementItemComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ModelElementItemComponent, data: {} });
export { RenderType_ModelElementItemComponent as RenderType_ModelElementItemComponent };
export function View_ModelElementItemComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-auto-field", [], null, null, null, i1.View_AutoFieldComponent_0, i1.RenderType_AutoFieldComponent)), i0.ɵdid(1, 770048, null, 0, i2.AutoFieldComponent, [i0.ChangeDetectorRef], { field: [0, "field"], readonly: [1, "readonly"], value: [2, "value"], label: [3, "label"], labelStyleGlobal: [4, "labelStyleGlobal"], labelAdditionalStyleGlobal: [5, "labelAdditionalStyleGlobal"], labelButtons: [6, "labelButtons"] }, null), i0.ɵpod(2, { name: 0, label: 1, field: 2, params: 3 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.createField(_ck(_v, 2, 0, _co.item.name, _co.item.verboseName, _co.item.field, _co.item.params)); var currVal_1 = true; var currVal_2 = _co.resolveItemColumn(_co.item); var currVal_3 = !!_co.item.verboseName; var currVal_4 = "fieldLabelTextStyle"; var currVal_5 = "fieldLabelAdditionalTextStyle"; var currVal_6 = _co.labelButtons; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
export function View_ModelElementItemComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-model-element-item", [], null, null, null, View_ModelElementItemComponent_0, RenderType_ModelElementItemComponent)), i0.ɵdid(1, 770048, null, 0, i3.ModelElementItemComponent, [i4.ActionControllerService, i0.Injector], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ModelElementItemComponentNgFactory = i0.ɵccf("app-model-element-item", i3.ModelElementItemComponent, View_ModelElementItemComponent_Host_0, { item: "item", model: "model", columnActions: "columnActions", context: "context", contextElement: "contextElement" }, {}, []);
export { ModelElementItemComponentNgFactory as ModelElementItemComponentNgFactory };
