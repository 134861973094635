/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./disabled.component";
var styles_DisabledComponent = [];
var RenderType_DisabledComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DisabledComponent, data: {} });
export { RenderType_DisabledComponent as RenderType_DisabledComponent };
export function View_DisabledComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "disabled__inner"]], null, null, null, null, null)), i0.ɵncd(null, 0)], null, null); }
export function View_DisabledComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-disabled", [], [[2, "disabled_active", null], [2, "disabled_transparent", null], [2, "disabled_clickable", null], [2, "disabled", null]], null, null, View_DisabledComponent_0, RenderType_DisabledComponent)), i0.ɵdid(1, 114688, null, 0, i1.DisabledComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).active; var currVal_1 = i0.ɵnov(_v, 1).transparent; var currVal_2 = i0.ɵnov(_v, 1).clickable; var currVal_3 = i0.ɵnov(_v, 1).cls; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
var DisabledComponentNgFactory = i0.ɵccf("app-disabled", i1.DisabledComponent, View_DisabledComponent_Host_0, { active: "active", transparent: "transparent", clickable: "clickable" }, {}, ["*"]);
export { DisabledComponentNgFactory as DisabledComponentNgFactory };
