import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import * as Color from 'color';
import fromPairs from 'lodash/fromPairs';
import isEqual from 'lodash/isEqual';
import isPlainObject from 'lodash/isPlainObject';
import keys from 'lodash/keys';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, fromEvent, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { LocalStorage } from '@core';
import { ServerRequestError } from '@modules/api';
import { getColorHexAStr, getColorHexStr } from '@modules/colors';
import {
  CustomizeService,
  ListDefaultSelection,
  ListGroup,
  TableSettings,
  ViewContext,
  ViewContextElement
} from '@modules/customize';
import { applyBooleanInput, DisplayField, Input as FieldInput } from '@modules/fields';
import { ColumnsModelListStore, EMPTY_OUTPUT, ListItem, listItemEquals, SELECTED_ITEM_OUTPUT } from '@modules/list';
import { Model, ModelDescription, PAGE_PARAM } from '@modules/models';
import { Resource } from '@modules/projects';
import { GetQueryOptions, paramsToGetQueryOptions } from '@modules/resources';
import { isSet, TypedChanges } from '@shared';

import { TableItemComponent } from '../table-item/table-item.component';
import { getListStateColumns, getListStateFetch, TableState } from '../table/table-state';

interface TableGroupState extends TableState {
  groupValue?: any;
  openedInitial?: boolean;
  visibleInput?: FieldInput;
  collapsed?: boolean;
  page?: number;
}

export function getTableGroupStateVisible(state: TableGroupState): Object {
  return {
    visible: state.visibleInput ? state.visibleInput.serialize() : undefined
  };
}

export function getTableGroupStateFetch(state: TableGroupState): Object {
  return {
    ...getListStateFetch(state),
    groupValue: state.groupValue,
    page: state.page
  };
}

export function getTableGroupStateOpenedInitial(state: TableGroupState): Object {
  return {
    openedInitial: state.openedInitial
  };
}

@Component({
  selector: 'app-table-group, [app-table-group]',
  templateUrl: './table-group.component.html',
  providers: [ColumnsModelListStore],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableGroupComponent implements OnInit, OnDestroy, OnChanges {
  @Input() group: ListGroup;
  @Input() groupIndex: number;
  @Input() listState: TableState = {};
  @Input() title: string;
  @Input() resource: Resource;
  @Input() modelDescription: ModelDescription;
  @Input() visibleColumns: DisplayField[];
  @Input() columnsCount = 5;
  @Input() selectedItem: ListItem;
  @Input() checkedItems: { [k: string]: Model } = {};
  @Input() checkedInverse = false;
  @Input() settings: TableSettings;
  @Input() scrollable = false;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() viewId: string;
  @Input() accentColor: string;
  @Input() theme = false;
  @Output() fetchStarted = new EventEmitter<void>();
  @Output() fetchFinished = new EventEmitter<ListItem[]>();
  @Output() modelUpdated = new EventEmitter<Model>();
  @Output() updateSelected = new EventEmitter<ListItem>();
  @Output() selectToggle = new EventEmitter<{ item: ListItem; element: HTMLElement }>();
  @Output() checkedToggle = new EventEmitter<{ component: TableItemComponent; index: number }>();
  @Output() changeColumnValue = new EventEmitter<{
    item: ListItem;
    name: string;
    value: any;
  }>();

  @ViewChild('template') template: TemplateRef<any>;

  itemComponents: TableItemComponent[] = [];
  group$ = new BehaviorSubject<ListGroup>(undefined);
  listState$ = new BehaviorSubject<TableState>({});
  collapsed$ = new BehaviorSubject<boolean>(false);
  page$ = new BehaviorSubject<number>(undefined);
  state: TableGroupState = {};
  prevState: TableGroupState = {};
  isVisible = false;
  isVisibleSubscription: Subscription;
  items: ListItem[];
  loading = true;
  loadingRows = 4;
  error: string;
  fetchSubscription: Subscription;
  hoverSeparator$ = new BehaviorSubject<boolean>(false);
  bodyElement: ElementRef;
  heightBeforeLoading: number;

  constructor(
    public listStore: ColumnsModelListStore,
    public customizeService: CustomizeService,
    private localStorage: LocalStorage,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    const savedCollapsed = this.getSavedCollapsed();
    if (savedCollapsed !== undefined) {
      this.setCollapsed(savedCollapsed, false);
    } else if (this.group && !this.group.openedInitial) {
      this.setCollapsed(true, false);
    }

    combineLatest(this.group$, this.listState$, this.collapsed$, this.page$)
      .pipe(untilDestroyed(this))
      .subscribe(([group, listState, collapsed, page]) => {
        const state: TableGroupState = {
          ...listState,
          groupValue: group ? group.value : undefined,
          openedInitial: group ? group.openedInitial : undefined,
          visibleInput: group ? group.visibleInput : undefined,
          collapsed: listState.groupCollapse ? collapsed : false,
          page: page
        };
        this.prevState = this.state;
        this.state = state;
        this.onStateUpdated(state);
      });

    this.initContextMenu();
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<TableGroupComponent>): void {
    if (changes.group) {
      this.group$.next(this.group);
    }

    if (changes.listState) {
      this.listState$.next(this.listState);
    }
  }

  onItemComponentInit(component: TableItemComponent) {
    this.itemComponents.push(component);
  }

  onItemComponentDestroy(component: TableItemComponent) {
    const index = this.itemComponents.indexOf(component);

    if (index !== -1) {
      this.itemComponents.splice(index, 1);
    }
  }

  getItems(): ListItem[] {
    return this.items;
  }

  setItems(value: ListItem[]) {
    this.items = value;
    this.cd.markForCheck();
  }

  getCount(): number {
    return this.listStore.count;
  }

  onStateUpdated(state: TableGroupState) {
    if (!isEqual(getTableGroupStateVisible(state), getTableGroupStateVisible(this.prevState))) {
      this.initVisibleObserver(state);
    }

    const fetchedUpdated = !isEqual(getTableGroupStateFetch(state), getTableGroupStateFetch(this.prevState));
    const fetchedUpdatedAndOpened = fetchedUpdated && !state.collapsed;
    const openedUpdatedAndNotFetched = this.prevState.collapsed && !state.collapsed && !this.listStore.items;

    if (fetchedUpdated && !fetchedUpdatedAndOpened) {
      this.listStore.reset();
    }

    if (fetchedUpdatedAndOpened || openedUpdatedAndNotFetched) {
      this.fetch(state);
    } else {
      if (!isEqual(getListStateColumns(state), getListStateColumns(this.prevState))) {
        if (this.listStore.dataSource) {
          this.listStore.dataSource.columns = state.dataSource ? state.dataSource.columns : [];
          this.listStore.deserializeModelAttributes();
        }
      }
    }

    if (
      !isEqual(getTableGroupStateOpenedInitial(state), getTableGroupStateOpenedInitial(this.prevState)) &&
      keys(this.prevState).length
    ) {
      this.setCollapsed(!state.openedInitial);
    }
  }

  initVisibleObserver(state: TableGroupState) {
    if (this.isVisibleSubscription) {
      this.isVisibleSubscription.unsubscribe();
    }

    if (!state.visibleInput) {
      this.isVisible = true;
      this.cd.markForCheck();
      return;
    }

    this.isVisible = applyBooleanInput(state.visibleInput, this.context);
    this.cd.markForCheck();

    this.isVisibleSubscription = this.context.outputValues$
      .pipe(debounceTime(10), distinctUntilChanged(), untilDestroyed(this))
      .subscribe(() => {
        this.isVisible = applyBooleanInput(state.visibleInput, this.context);
        this.cd.markForCheck();
      });
  }

  initContextMenu() {
    fromEvent(document, 'contextmenu')
      .pipe(untilDestroyed(this))
      .subscribe(e => {
        if (!this.bodyElement || !this.bodyElement.nativeElement.contains(e.target)) {
          return;
        }

        const checkedItem = this.itemComponents.find(item => {
          return item.el.nativeElement.contains(e.target);
        });

        if (!checkedItem) {
          return;
        }

        e.preventDefault();

        this.itemComponents
          .filter(item => item !== checkedItem && item.checked)
          .forEach(item => {
            item.checked = false;
            item.checkedToggle.emit();
          });

        if (checkedItem.checked) {
          return;
        }

        checkedItem.checked = true;
        checkedItem.checkedToggle.emit();
      });
  }

  getStateQueryOptions(state: TableGroupState, extraParams = {}): GetQueryOptions {
    const page = state.page || 1;
    const queryOptions = paramsToGetQueryOptions({
      ...state.dataSourceParams,
      [PAGE_PARAM]: page,
      ...extraParams
    });

    queryOptions.filters = [
      ...(queryOptions.filters ? queryOptions.filters : []),
      ...(state.filters ? state.filters : [])
    ];
    queryOptions.search = state.search;
    queryOptions.sort = state.sort;

    return queryOptions;
  }

  getBaseStateQueryOptions(): GetQueryOptions {
    return this.getStateQueryOptions(this.state);
  }

  fetch(state: TableGroupState) {
    if (!state.dataSource || !state.dataSource.isConfigured()) {
      return;
    }

    if (this.fetchSubscription) {
      this.fetchSubscription.unsubscribe();
      this.fetchSubscription = undefined;
    }

    this.items = undefined;
    this.loading = true;
    this.error = undefined;
    this.heightBeforeLoading = this.bodyElement
      ? this.bodyElement.nativeElement.getBoundingClientRect().height
      : undefined;
    this.cd.markForCheck();

    this.fetchStarted.emit();

    if (this.settings.defaultSelection && !this.selectedItem) {
      this.contextElement.patchOutputValueMeta(SELECTED_ITEM_OUTPUT, { loading: true });
    }

    if (state.inputsLoading) {
      this.listStore.reset();
      return;
    }

    this.listStore.dataSource = state.dataSource;
    this.listStore.useDataSourceColumns = true;
    this.listStore.staticData = state.dataSourceStaticData;
    this.listStore.queryOptions = this.getStateQueryOptions(state, {
      ...(state.groupValue !== undefined && { [this.settings.groupField]: state.groupValue })
    });
    this.listStore.context = this.context;
    this.listStore.contextElement = this.contextElement;
    this.listStore.perPage = state.settings && state.settings.perPage ? state.settings.perPage : 10;

    if (this.items && this.items.length) {
      this.loadingRows = this.items.length;
    }

    this.listStore.reset(state.page);
    this.items = [];
    this.cd.markForCheck();

    this.fetchSubscription = this.listStore
      .getNext()
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          this.loading = false;
          this.items = result;
          this.cd.markForCheck();

          if (this.settings.defaultSelection && !this.selectedItem) {
            this.contextElement.patchOutputValueMeta(SELECTED_ITEM_OUTPUT, { loading: false });

            const page = state.page || 1;

            if (this.settings.defaultSelection == ListDefaultSelection.First && page == 1 && this.groupIndex == 0) {
              this.updateSelected.emit(this.items ? this.items[0] : undefined);
            }
          }

          this.fetchFinished.emit(this.items);
        },
        error => {
          if (error instanceof ServerRequestError && error.errors.length) {
            this.error = error.errors[0];
          } else if (isPlainObject(error)) {
            this.error = JSON.stringify(error);
          } else if (error.hasOwnProperty('message')) {
            console.error(error);
            this.error = error.message;
          } else {
            console.error(error);
            this.error = error;
          }

          this.loading = false;
          this.cd.markForCheck();

          this.contextElement.setOutputValue(EMPTY_OUTPUT, false, { loading: false, error: true });

          if (this.settings.defaultSelection && !this.selectedItem) {
            this.contextElement.patchOutputValueMeta(SELECTED_ITEM_OUTPUT, { loading: false });
          }
        }
      );
  }

  reloadData() {
    this.fetch(this.state);
  }

  isItemSelected(item: ListItem, index: number) {
    return this.itemEquals(this.selectedItem, item);
  }

  isItemChecked(item: ListItem, index: number) {
    const pk = item.model.primaryKey || `${this.groupIndex}_${index}`;

    return (!this.checkedInverse && this.checkedItems[pk]) || (this.checkedInverse && !this.checkedItems[pk]);
  }

  get isAnyChecked() {
    return (
      (!this.checkedInverse && keys(this.checkedItems).length) ||
      (this.checkedInverse && keys(this.checkedItems).length < this.listStore.count)
    );
  }

  itemEquals(lhs: ListItem, rhs: ListItem) {
    return listItemEquals(lhs, rhs);
  }

  trackByFn(i, item: ListItem) {
    return item.model.primaryKey || i;
  }

  getCheckedAll() {
    if (!this.items) {
      return;
    }

    return fromPairs(
      this.items.map((item, index) => {
        const pk = item.model.primaryKey || `${this.groupIndex}_${index}`;
        return [pk, item.model];
      })
    );
  }

  updateRow(newItem: ListItem) {
    if (!this.items) {
      return;
    }

    this.listStore.items = this.items.map(item => {
      if (item === newItem) {
        return newItem;
      } else {
        return item;
      }
    });
    this.listStore.deserializeModelAttributes();
    this.items = this.listStore.items;
    this.cd.markForCheck();
  }

  setCollapsed(value: boolean, preserve = true) {
    this.collapsed$.next(value);
    this.cd.markForCheck();

    if (preserve) {
      this.saveCollapsed(value);
    }
  }

  toggleCollapsed() {
    if (!this.settings.groupCollapse) {
      return;
    }

    this.setCollapsed(!this.collapsed$.value);
  }

  getCollapsedKey() {
    if (!this.group) {
      return;
    }

    return ['table_group_collapsed', this.viewId, this.group.uid].join('_');
  }

  getSavedCollapsed(): boolean {
    const key = this.getCollapsedKey();
    const dataStr = this.localStorage.get(key);

    if (!isSet(dataStr)) {
      return;
    }

    return dataStr == '1';
  }

  saveCollapsed(value: boolean) {
    const key = this.getCollapsedKey();
    this.localStorage.set(key, value ? '1' : '0');
  }

  setColumnWidth(index: number, width: number, save = false) {
    this.itemComponents.forEach(item => item.setColumnWidth(index, width, save));
  }

  get colspan(): number {
    let result = this.columnsCount + 1;

    if (this.settings.rowActions.length) {
      ++result;
    }

    return result;
  }

  backgroundCustomColor(color: string) {
    const colorHex = getColorHexStr(color);

    try {
      const clr = Color(colorHex).alpha(0.1);
      return getColorHexAStr(clr);
    } catch (e) {
      return null;
    }
  }
}
