import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { isSet } from '../common/common';

export function googlePickerLoad(): Observable<void> {
  return new Observable<void>(observer => {
    const gapi = window['gapi'];

    if (!gapi) {
      observer.error(new Error('Google API loader is not loaded'));
      return;
    }

    gapi.load('picker', () => {
      observer.next();
      observer.complete();
    });

    return () => {};
  });
}

export function googlePickerSelectFileUrl(options: {
  appId: string;
  apiKey: string;
  accessToken: string;
}): Observable<string> {
  return googlePickerLoad().pipe(
    switchMap(() => {
      return new Observable<string>(observer => {
        const google = window['google'];

        if (!google) {
          observer.error(new Error('Google APIs are not loaded'));
          return;
        }

        const view = new google.picker.DocsView(google.picker.ViewId.SPREADSHEETS);

        view.setMode(google.picker.DocsViewMode.LIST);

        const picker = new google.picker.PickerBuilder()
          .enableFeature(google.picker.Feature.NAV_HIDDEN)
          .addView(view)
          .setOAuthToken(options.accessToken)
          .setDeveloperKey(options.apiKey)
          .setCallback(data => {
            if (data[google.picker.Response.ACTION] == google.picker.Action.PICKED) {
              const doc = data[google.picker.Response.DOCUMENTS][0];
              const url = doc[google.picker.Document.URL];

              observer.next(url);
              observer.complete();
            } else if (data[google.picker.Response.ACTION] == google.picker.Action.CANCEL) {
              observer.next(undefined);
              observer.complete();
            } else if (data[google.picker.Response.ACTION] == google.picker.Action.ERROR) {
              observer.error(new Error('Unknown error'));
            }
          })
          .setAppId(options.appId)
          .hideTitleBar()
          .build();

        picker.setVisible(true);

        return () => {
          picker.dispose();
        };
      });
    })
  );
}
