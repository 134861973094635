import {
  Directive,
  ElementRef,
  forwardRef,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Renderer2,
  SimpleChanges
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import Cleave from 'cleave.js';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fromEvent } from 'rxjs';

import { defaultNumberFractionSeparator, defaultNumberThousandsSeparator, NumberFieldType } from '@modules/fields';
import { isSet, KeyboardEventKeyCode, stripStart } from '@shared';

@Directive({
  selector: 'appInputFormat, [appInputFormat]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputFormatDirective),
      multi: true
    }
  ]
})
export class InputFormatDirective implements OnInit, OnChanges, OnDestroy, ControlValueAccessor {
  @Input() appInputFormat: {
    type?: NumberFieldType;
    min?: number;
    max?: number;
    fraction?: number;
    fractionSeparator?: string;
    thousandsSeparator?: string;
    prefix?: string;
    numberKeyboardControl?: boolean;
  } = {};

  cleave: Cleave;
  externalWriteValue = false;
  onChange = (_: any) => {};
  onTouched = () => {};

  @HostListener('blur', []) onBlurEvent = () => {
    this.onTouched();
  };

  constructor(private renderer: Renderer2, private el: ElementRef) {}

  ngOnInit(): void {
    if (this.appInputFormat.numberKeyboardControl) {
      fromEvent<KeyboardEvent>(this.el.nativeElement, 'keydown')
        .pipe(untilDestroyed(this))
        .subscribe(e => {
          if (e.keyCode == KeyboardEventKeyCode.ArrowUp) {
            e.stopPropagation();
            e.preventDefault();

            this.incrementValue(e, true);
          } else if (e.keyCode == KeyboardEventKeyCode.ArrowDown) {
            e.stopPropagation();
            e.preventDefault();

            this.incrementValue(e, false);
          }
        });
    }
  }

  ngOnDestroy(): void {
    this.destroyCleave();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initCleave();
  }

  initCleave() {
    this.destroyCleave();

    const options = {
      numeral: true,
      numeralThousandsGroupStyle: 'thousand',
      numeralDecimalMark: defaultNumberFractionSeparator,
      delimiter: defaultNumberThousandsSeparator,
      prefix: this.appInputFormat.prefix,
      numeralDecimalScale: 2,
      onValueChanged: (e: { target: { value: string; rawValue: string } }) => {
        if (this.externalWriteValue) {
          return;
        }

        const cleanValue = this.cleanValue(e.target.rawValue);
        const cleanRawValue = this.cleanValue(e.target.rawValue, { ignoreBounds: true });

        if (cleanValue && cleanRawValue && cleanValue.valueStr !== cleanRawValue.valueStr) {
          this.cleave.setRawValue(cleanValue.valueStr);
          return;
        }

        this.onChange(cleanValue ? cleanValue.value : null);
      }
    };

    if (this.appInputFormat.type == NumberFieldType.Integer) {
      options.numeralDecimalScale = 0;
    } else if (this.appInputFormat.type == NumberFieldType.Decimal && this.appInputFormat.fraction) {
      options.numeralDecimalScale = this.appInputFormat.fraction;
    }

    if (isSet(this.appInputFormat.fractionSeparator)) {
      options.numeralDecimalMark = this.appInputFormat.fractionSeparator;
    }

    if (isSet(this.appInputFormat.thousandsSeparator, true)) {
      options.delimiter = this.appInputFormat.thousandsSeparator;
    }

    this.cleave = new Cleave(this.el.nativeElement, options);
  }

  init() {
    if (!this.appInputFormat.numberKeyboardControl) {
      return;
    }

    fromEvent<KeyboardEvent>(this.el.nativeElement, 'keydown')
      .pipe(untilDestroyed(this))
      .subscribe(e => {
        if (e.keyCode == KeyboardEventKeyCode.ArrowUp) {
          e.stopPropagation();
          e.preventDefault();

          this.incrementValue(e, true);
        } else if (e.keyCode == KeyboardEventKeyCode.ArrowDown) {
          e.stopPropagation();
          e.preventDefault();

          this.incrementValue(e, false);
        }
      });
  }

  destroyCleave() {
    if (this.cleave) {
      this.cleave.destroy();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  cleanValue(value: any, options: { ignoreBounds?: boolean } = {}): { value: number; valueStr: string } {
    if (!isSet(value)) {
      return null;
    }

    const max = this.appInputFormat.max;
    const min = this.appInputFormat.min;
    const prefix = isSet(this.appInputFormat.prefix) ? this.appInputFormat.prefix : '';

    if (isSet(prefix) && typeof value === 'string') {
      value = stripStart(value, prefix);
    }

    const numberValue = parseFloat(value);

    if (isNaN(numberValue)) {
      return null;
    }

    if (!options.ignoreBounds && isSet(max) && numberValue > max) {
      return {
        value: max,
        valueStr: `${prefix}${max}`
      };
    } else if (!options.ignoreBounds && isSet(min) && numberValue < min) {
      return {
        value: min,
        valueStr: `${prefix}${min}`
      };
    } else {
      return {
        value: numberValue,
        valueStr: `${prefix}${numberValue}`
      };
    }
  }

  writeValue(value: any): void {
    if (!this.cleave) {
      return;
    }

    const cleanValue = this.cleanValue(value);

    if (cleanValue && cleanValue.value === value) {
      this.externalWriteValue = true;
      this.cleave.setRawValue(value);
      this.externalWriteValue = false;
    } else {
      this.cleave.setRawValue(value);
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.renderer.setProperty(this.el.nativeElement, 'disabled', isDisabled);
  }

  incrementValue(e: MouseEvent | KeyboardEvent, add: boolean) {
    if (!this.cleave) {
      return;
    }

    const delta = e.shiftKey ? 10 : 1;
    const value = this.cleanValue(this.cleave.getRawValue());
    const defaultValue = 0;
    const valueOrDefault = value ? value.value : defaultValue;
    const newValue = this.cleanValue(add ? valueOrDefault + delta : valueOrDefault - delta);

    this.cleave.setRawValue(newValue.value);
    this.el.nativeElement.select();
  }
}
