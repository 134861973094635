<ng-container *ngIf="currentViewSettings">
  <ng-container *appCustomizeHandlerHeaderLeft>
    <div class="customize-toolbar__buttons">
      <div
        *ngFor="let item of pageSettingsItems"
        class="customize-toolbar-button"
        [class.customize-toolbar-button_active]="currentPageSettings == item.type"
        (click)="openPageSettings(item.type)"
      >
        <div class="customize-toolbar-button__inner">
          <div
            *ngIf="item.icon"
            class="customize-toolbar-button__icon customize-toolbar-button__icon_left"
            [ngClass]="'icon-' + item.icon"
            [style.font-size.px]="(item.iconSize | appIsSet) ? item.iconSize : null"
          ></div>
          <div class="customize-toolbar-button__label">{{ item.label }}</div>
        </div>

        <div
          *ngIf="item.type == pageSettings.Parameters"
          class="customize-toolbar-button__counter"
          [class.customize-toolbar-button__counter_visible]="
            currentViewSettings.parameters.length > 0 && currentPageSettings != item.type
          "
        >
          {{ currentViewSettings.parameters.length }}
        </div>

        <div
          *ngIf="item.type == pageSettings.Queries"
          class="customize-toolbar-button__counter"
          [class.customize-toolbar-button__counter_visible]="
            currentViewSettings.getActionsCount() > 0 && currentPageSettings != item.type
          "
        >
          {{ currentViewSettings.getActionsCount() }}
        </div>

        <div
          *ngIf="item.type == pageSettings.Popups"
          class="customize-toolbar-button__counter"
          [class.customize-toolbar-button__counter_visible]="
            currentViewSettings.popups.length > 0 &&
            currentPageSettings != item.type &&
            !popups_component.hasOpenedPopup()
          "
        >
          {{ currentViewSettings.popups.length }}
        </div>

        <div
          *ngIf="item.type == pageSettings.Properties"
          class="customize-toolbar-button__counter"
          [class.customize-toolbar-button__counter_visible]="
            propertiesComponent?.propertiesCount > 0 && currentPageSettings != item.type
          "
        >
          {{ propertiesComponent?.propertiesCount }}
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *appCustomizeHandlerHeaderCenter>
    <ng-container *ngIf="!renameLoading && !duplicateLoading && !deleteLoading">
      <app-customize-title
        class="customize-toolbar__editable-title"
        [title]="currentViewSettings.name"
        (updateTitle)="renamePage($event)"
        #customize_title
      ></app-customize-title>

      <ng-container *ngIf="currentViewSettings.uid && !customize_title.isEditing()">
        <mat-menu #dropdown="matMenu">
          <button
            mat-menu-item
            class="mat-menu-item-odd"
            [disabled]="!currentViewSettings"
            [disableRipple]="true"
            (click)="page_edit_overlay.open()"
          >
            <span class="icon-gear choose-items-item__dropdown-icon"></span>
            Customize page
          </button>

          <button mat-menu-item class="mat-menu-item-odd" [disableRipple]="true" (click)="duplicatePage()">
            <span class="icon-duplicate_2 choose-items-item__dropdown-icon"></span>
            Duplicate page
          </button>

          <button
            mat-menu-item
            class="mat-menu-item-odd mat-menu-item-red"
            [disableRipple]="true"
            (click)="deletePage()"
          >
            <span class="icon-bin choose-items-item__dropdown-icon choose-items-item__dropdown-icon_red"></span>
            Delete page
          </button>
        </mat-menu>

        <app-page-edit-overlay
          [viewSettings]="currentViewSettings"
          [origin]="origin"
          [triggerManual]="true"
          (viewSettingsUpdated)="customizeViewSettings($event)"
          #page_edit_overlay="pageEditOverlay"
        ></app-page-edit-overlay>

        <div
          class="customize-toolbar-icon-button icon-more customize-toolbar__editable-title-actions"
          [class.customize-toolbar-icon-button_active]="trigger.menuOpen || page_edit_overlay.isOpened()"
          [matMenuTriggerFor]="dropdown"
          #trigger="matMenuTrigger"
          cdkOverlayOrigin
          #origin="cdkOverlayOrigin"
        ></div>
      </ng-container>
    </ng-container>

    <div *ngIf="renameLoading || duplicateLoading || deleteLoading" class="customize-title">
      <div class="customize-title__text">
        <span [class.loading-animation]="true"><span class="stub-text">Page title</span></span>
      </div>
    </div>
  </ng-container>

  <!--  <app-admin-header-->
  <!--    *ngIf="!(customizeService.enabled$ | async)"-->
  <!--    [collaborationParams]="collaborationParams"-->
  <!--    [userActivitiesParams]="userActivitiesParams"-->
  <!--  ></app-admin-header>-->

  <div
    class="app-page theme-enabled"
    appProjectThemeOptions
    appDropListGroup
    [projectSettings]="allSettings$ | async"
    [forceBackgroundColor2]="currentViewSettings?.backgroundColor2"
    [forceBackgroundColor2Dark]="currentViewSettings?.backgroundColor2Dark"
    [forceBackgroundColor3]="currentViewSettings?.backgroundColor3"
    [forceBackgroundColor3Dark]="currentViewSettings?.backgroundColor3Dark"
    [forceBackgroundColor4]="currentViewSettings?.backgroundColor4"
    [forceBackgroundColor4Dark]="currentViewSettings?.backgroundColor4Dark"
    [forceBackgroundColor5]="currentViewSettings?.backgroundColor5"
    [forceBackgroundColor5Dark]="currentViewSettings?.backgroundColor5Dark"
    [forceTextColor]="currentViewSettings?.textColor"
    [forceTextColorDark]="currentViewSettings?.textColorDark"
    [forceTextColor2]="currentViewSettings?.textColor2"
    [forceTextColor2Dark]="currentViewSettings?.textColor2Dark"
    [forceTextColor3]="currentViewSettings?.textColor3"
    [forceTextColor3Dark]="currentViewSettings?.textColor3Dark"
    [forceBorderColor]="currentViewSettings?.borderColor"
    [forceBorderColorDark]="currentViewSettings?.borderColorDark"
    [forceBorderColor2]="currentViewSettings?.borderColor2"
    [forceBorderColor2Dark]="currentViewSettings?.borderColor2Dark"
    [forceBorderColor3]="currentViewSettings?.borderColor3"
    [forceBorderColor3Dark]="currentViewSettings?.borderColor3Dark"
    [appDropListGroupDisabled]="!!popupsComponent?.openedPopup"
    [class.customizable]="customizeService.layoutEnabled$ | async"
    #main_drop_list_group="appDropListGroup"
  >
    <!--    <div class="section section_top">-->
    <!--      <div class="section__inner">-->
    <!-- <div class="change__header change__header_customization">
          <h1 class="change__title">
            <a
              *ngIf="customizeService.layoutEnabled$ | async"
              href="javascript:void(0)"
              class="button button_icon button_orange button_primary button_extra-small"
              (click)="customizeViewSettings()"
            >
              <span class="button__icon icon-gear"></span>
            </a>
            {{ currentViewSettings.name | appCapitalize }}
          </h1>
        </div> -->

    <!--        <div class="section__body">-->
    <div
      class="app-page__bounds"
      [style.max-width.px]="[currentViewSettings?.maxWidth, (allSettings$ | async)?.maxWidth] | appFirstSet: null"
      [style.padding-top.px]="
        [currentViewSettings?.padding.top, (allSettings$ | async)?.padding?.top] | appFirstSet: null
      "
      [style.padding-right.px]="
        [currentViewSettings?.padding.right, (allSettings$ | async)?.padding?.right] | appFirstSet: null
      "
      [style.padding-bottom.px]="
        [currentViewSettings?.padding.bottom, (allSettings$ | async)?.padding?.bottom] | appFirstSet: null
      "
      [style.padding-left.px]="
        [currentViewSettings?.padding.left, (allSettings$ | async)?.padding?.left] | appFirstSet: null
      "
    >
      <app-root-layout
        *ngIf="currentViewSettings?.elements"
        [elements]="currentViewSettings.elements"
        [context]="context"
        [page]="currentViewSettings"
        [root]="true"
        [templates]="true"
        [active]="!popups_component.openedPopupUid"
      >
      </app-root-layout>
    </div>
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->

    <app-custom-page-popups
      appDropListGroup
      [viewSettings]="currentViewSettings"
      [context]="context"
      [popupsContextElement]="viewContextElementPopups"
      [openedToolbar]="currentPageSettings == pageSettings.Popups"
      (closedToolbar)="closePageSettings(pageSettings.Popups)"
      #popup_drop_list_group="appDropListGroup"
      #popups_component="custom_page_popups"
    ></app-custom-page-popups>

    <ng-container *ngIf="mode == adminModes.Builder">
      <app-custom-page-parameters
        [viewSettings]="currentViewSettings"
        [context]="context"
        [opened]="currentPageSettings == pageSettings.Parameters"
        (viewSettingsUpdated)="updateViewSettingsParameters($event)"
        (closed)="closePageSettings(pageSettings.Parameters)"
      ></app-custom-page-parameters>

      <app-custom-page-queries
        [viewSettings]="currentViewSettings"
        [context]="context"
        [opened]="currentPageSettings == pageSettings.Queries"
        (queriesUpdated)="updateViewSettingsQueries($event)"
        (actionsUpdated)="updateViewSettingsActions($event)"
        (closed)="closePageSettings(pageSettings.Queries)"
      ></app-custom-page-queries>

      <app-custom-page-properties
        [viewSettings]="currentViewSettings"
        [context]="context"
        [opened]="currentPageSettings == pageSettings.Properties"
        (closed)="closePageSettings(pageSettings.Properties)"
      ></app-custom-page-properties>
    </ng-container>
  </div>

  <ng-container *ngIf="customizeService.enabled$ | async">
    <div class="admin__sidebar" (click)="onSidebarClick($event)" #sidebar>
      <app-change-customize-bar
        [title]="currentViewSettings.name | appCapitalize"
        [context]="context"
        [dropListGroups]="[main_drop_list_group, popup_drop_list_group]"
        [customizeDisabled]="!initialViewSettings?.uid"
      ></app-change-customize-bar>
    </div>
  </ng-container>
</ng-container>
