import { Injectable, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import range from 'lodash/range';
import repeat from 'lodash/repeat';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { merge, Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

import { Option } from '@modules/field-components';
import {
  defaultNumberFraction,
  defaultNumberFractionSeparator,
  defaultNumberThousandsSeparator,
  FieldType,
  Input,
  NumberValueFormat,
  ValueFormat
} from '@modules/fields';
import { FieldInputControl } from '@modules/parameters';
import { CurrentProjectStore } from '@modules/projects';
import { controlValue, isSet } from '@shared';

interface ValueFormatControlOptions {
  field?: FieldType;
}

const valueFormatControlDefaultOptions = {
  field: FieldType.Text
};

@Injectable()
export class ValueFormatControl extends FormGroup implements OnDestroy {
  instance: ValueFormat;
  options: ValueFormatControlOptions = valueFormatControlDefaultOptions;

  controls: {
    prefix: FormControl;
    postfix: FormControl;
    number_format: FormControl;
    number_fraction: FormControl;
    number_fraction_separator: FormControl;
    number_thousands_separator: FormControl;
    format_input_enabled: FormControl;
    format_input: FieldInputControl;
  };

  numberFormatOptions: Option<NumberValueFormat>[] = [
    { value: NumberValueFormat.MetricPrefix, name: 'Metric Prefixes (23M, 16K)', icon: 'number' },
    { value: NumberValueFormat.Currency, name: 'Currency ($14)', icon: 'currency' },
    { value: NumberValueFormat.Percentage, name: 'Percentage (45%)', icon: 'percent' },
    { value: NumberValueFormat.Order, name: 'Order (1th, 2nd)', icon: 'filter_down' },
    { value: NumberValueFormat.FileSize, name: 'File size (26MB)', icon: 'save' },
    { value: NumberValueFormat.Exponential, name: 'Exponential (1.24e+7)', icon: 'math' }
  ];

  numberFractionOptions = range(0, 9).map(item => {
    const name = item === 0 ? `1` : `1.${repeat('0', item)}`;
    return {
      value: item,
      name: name
    };
  });

  numberFractionSeparatorOptions = [
    {
      value: '.',
      name: 'Period separator (100.15)'
    },
    {
      value: ',',
      name: 'Comma separator (100,15)'
    }
  ];

  numberThousandsSeparatorOptions = [
    {
      value: '',
      name: 'None (100000000)'
    },
    {
      value: ',',
      name: 'Comma (100,000,000)'
    },
    {
      value: '.',
      name: 'Period (100.000.000)'
    },
    {
      value: ' ',
      name: 'Space (100 000 000)'
    },
    {
      value: "'",
      name: "Apostrophe (100'000'000)"
    }
  ];

  constructor(private currentProjectStore: CurrentProjectStore) {
    super({
      prefix: new FormControl(''),
      postfix: new FormControl(''),
      number_format: new FormControl(undefined),
      number_fraction: new FormControl(defaultNumberFraction),
      number_fraction_separator: new FormControl(defaultNumberFractionSeparator),
      number_thousands_separator: new FormControl(defaultNumberThousandsSeparator),
      format_input_enabled: new FormControl(false),
      format_input: new FieldInputControl({ path: ['value'] })
    });

    const defaultSeparators = this.getDefaultSeparators();

    this.controls.number_fraction_separator.patchValue(defaultSeparators.numberFractionSeparator);
    this.controls.number_thousands_separator.patchValue(defaultSeparators.numberThousandsSeparator);
    this.markAsPristine();

    merge(
      this.controls.number_fraction_separator.valueChanges.pipe(map(() => this.controls.number_fraction_separator)),
      this.controls.number_thousands_separator.valueChanges.pipe(map(() => this.controls.number_thousands_separator))
    )
      .pipe(debounceTime(10), untilDestroyed(this))
      .subscribe(control => {
        const anotherControl = [this.controls.number_fraction_separator, this.controls.number_thousands_separator].find(
          item => item !== control
        );

        if (control.value == ',' && anotherControl.value == control.value) {
          anotherControl.patchValue('.');
        } else if (control.value == '.' && anotherControl.value == control.value) {
          anotherControl.patchValue(',');
        }
      });
  }

  ngOnDestroy(): void {}

  getDefaultSeparators(): { numberFractionSeparator: string; numberThousandsSeparator: string } {
    const domain = this.currentProjectStore.instance ? this.currentProjectStore.instance.domain : undefined;
    const numberFractionSeparator =
      domain && isSet(domain.numberFractionSeparator) ? domain.numberFractionSeparator : defaultNumberFractionSeparator;
    const numberThousandsSeparator =
      domain && isSet(domain.numberThousandsSeparator, true)
        ? domain.numberThousandsSeparator
        : defaultNumberThousandsSeparator;

    return {
      numberFractionSeparator: numberFractionSeparator,
      numberThousandsSeparator: numberThousandsSeparator
    };
  }

  getNumberFormatOption$(): Observable<Option<NumberValueFormat>> {
    return controlValue(this.controls.number_format).pipe(
      map(value => this.numberFormatOptions.find(item => item.value == value))
    );
  }

  deserialize(instance: ValueFormat, options: ValueFormatControlOptions = {}) {
    options = { ...valueFormatControlDefaultOptions, ...options };

    this.instance = instance;
    this.options = options;

    const defaultSeparators = this.getDefaultSeparators();

    if (instance) {
      this.controls.prefix.setValue(instance.prefix);
      this.controls.postfix.setValue(instance.postfix);
      this.controls.number_format.setValue(instance.numberFormat);
      this.controls.number_fraction.setValue(instance.numberFraction || defaultNumberFraction);
      this.controls.number_fraction_separator.setValue(
        isSet(instance.numberFractionSeparator)
          ? instance.numberFractionSeparator
          : defaultSeparators.numberFractionSeparator
      );
      this.controls.number_thousands_separator.setValue(
        isSet(instance.numberThousandsSeparator, true)
          ? instance.numberThousandsSeparator
          : defaultSeparators.numberThousandsSeparator
      );
      this.controls.format_input_enabled.setValue(!!instance.formatInput);
      this.controls.format_input.patchValue(instance.formatInput ? instance.formatInput.serializeWithoutPath() : {});
    } else {
      this.controls.prefix.setValue('');
      this.controls.postfix.setValue('');
      this.controls.number_format.setValue(undefined);
      this.controls.number_fraction.setValue(defaultNumberFraction);
      this.controls.number_fraction_separator.setValue(defaultSeparators.numberFractionSeparator);
      this.controls.number_thousands_separator.setValue(defaultSeparators.numberThousandsSeparator);
      this.controls.format_input_enabled.setValue(false);
    }

    this.markAsPristine();
  }

  setNumberFormat(format: NumberValueFormat) {
    this.controls.number_format.patchValue(format);

    if (format == NumberValueFormat.Currency) {
      this.controls.prefix.patchValue('$');
      this.controls.postfix.patchValue('');
    }
  }

  serialize(): ValueFormat {
    const result = this.instance || new ValueFormat();

    if (this.controls.format_input_enabled.value) {
      result.prefix = undefined;
      result.postfix = undefined;
      result.numberFormat = undefined;
      result.numberFraction = undefined;
      result.numberFractionSeparator = undefined;
      result.numberThousandsSeparator = undefined;
      result.formatInput = this.controls.format_input_enabled.value
        ? new Input().deserialize(this.controls.format_input.value)
        : undefined;
    } else {
      result.prefix = this.controls.prefix.value;
      result.postfix = this.controls.postfix.value;

      if (this.options.field == FieldType.Number) {
        result.numberFormat = this.controls.number_format.value;
        result.numberFraction = this.controls.number_fraction.value;
        result.numberFractionSeparator = this.controls.number_fraction_separator.value;
        result.numberThousandsSeparator = this.controls.number_thousands_separator.value;
      } else {
        result.numberFormat = undefined;
        result.numberFraction = undefined;
        result.numberFractionSeparator = undefined;
        result.numberThousandsSeparator = undefined;
      }

      result.formatInput = null;
    }

    if (!result.isSet()) {
      return;
    }

    return result;
  }
}
