<app-field-wrapper
  *ngIf="!readonly"
  [formGroup]="form"
  [form]="form"
  [field]="field"
  [readonly]="readonly"
  [requiredAsterisk]="requiredAsterisk"
  [label]="label"
  [labelStyle]="elementStyles?.labelStyle"
  [labelStyleGlobal]="labelStyleGlobal"
  [labelAdditional]="labelAdditional"
  [labelAdditionalStyle]="elementStyles?.labelAdditionalStyle"
  [labelAdditionalStyleGlobal]="labelAdditionalStyleGlobal"
  [errors]="errors"
  [truncate]="truncate"
  [manualMargin]="manualMargin"
  [labelButtons]="labelButtons"
  [tooltip]="tooltip"
  [idToken]="idToken"
  [theme]="field.params['theme']"
>
  <div *ngIf="control" class="input-icon-wrapper">
    <input
      *ngIf="widget == widgets.Default"
      class="input"
      [class.input_error]="(form | appFormFieldErrors: field.name)?.length"
      [class.input_wide]="field.params['wide']"
      [class.input_icon-right]="field.resetEnabled"
      [class.input_theme]="field.params['theme']"
      [class.input_styles]="field.params['theme']"
      [appFieldElementStyles]="elementStyles"
      [appFieldElementStylesEnabled]="field.params['theme']"
      [ngClass]="field.params['classes']"
      type="text"
      [appAutofocus]="autofocus"
      [formControl]="control"
      [placeholder]="field.placeholder || ''"
      [id]="field.name | appUniqueId: idToken"
    />

    <textarea
      *ngIf="widget == widgets.Multiline"
      class="input input_textarea"
      [class.input_error]="(form | appFormFieldErrors: field.name)?.length"
      [class.input_wide]="field.params['wide']"
      [class.input_icon-right]="field.resetEnabled"
      [class.input_theme]="field.params['theme']"
      [class.input_styles]="field.params['theme']"
      [style.resize]="(customizeService.layoutEnabled$ | async) ? 'none' : null"
      [appFieldElementStyles]="elementStyles"
      [appFieldElementStylesEnabled]="field.params['theme']"
      [ngClass]="field.params['classes']"
      [appAutofocus]="autofocus"
      [formControl]="control"
      [id]="field.name | appUniqueId: idToken"
      [placeholder]="field.placeholder || ''"
      [attr.rows]="rows"
    >
    </textarea>

    <span
      *ngIf="field.resetEnabled && (control.value | appIsSet)"
      class="input-icon input-icon_interactive input-icon_right icon-close"
      [class.input-icon_small]="inputSmall"
      [class.input-icon_theme]="field.params['theme']"
      (click)="resetCurrentValue()"
    ></span>
  </div>
</app-field-wrapper>

<app-field-wrapper
  *ngIf="readonly"
  [form]="form"
  [field]="field"
  [readonly]="readonly"
  [requiredAsterisk]="requiredAsterisk"
  [label]="label"
  [labelStyle]="elementStyles?.labelStyle"
  [labelStyleGlobal]="labelStyleGlobal"
  [labelAdditional]="labelAdditional"
  [labelAdditionalStyle]="elementStyles?.labelAdditionalStyle"
  [labelAdditionalStyleGlobal]="labelAdditionalStyleGlobal"
  [errors]="errors"
  [truncate]="truncate"
  [manualMargin]="manualMargin"
  [labelButtons]="labelButtons"
  [tooltip]="tooltip"
  [idToken]="idToken"
  [theme]="field.params['theme']"
>
  <span
    [class.text-truncate]="
      !field.params['multiline'] || (field.params['multiline'] && (field.params['rows'] | appIsSet))
    "
    [class.text-truncate_multiline]="field.params['multiline'] && (field.params['rows'] | appIsSet)"
    [style]="multilineStyles"
    [innerHTML]="formatEmpty((displayValue$ | appIsSet) ? (displayValue$ | async | appFormatText) : undefined)"
  ></span>
</app-field-wrapper>
