var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import keys from 'lodash/keys';
import pickBy from 'lodash/pickBy';
import { isSet } from '@shared';
import { TextStyle } from './text-style';
var DateRangeElementStyles = /** @class */ (function () {
    function DateRangeElementStyles(options) {
        if (options === void 0) { options = {}; }
        Object.assign(this, options);
    }
    DateRangeElementStyles.prototype.deserialize = function (data) {
        if (data['label_style']) {
            this.labelStyle = new TextStyle().deserialize(data['label_style']);
        }
        else {
            this.labelStyle = undefined;
        }
        if (data['label_additional_style']) {
            this.labelAdditionalStyle = new TextStyle().deserialize(data['label_additional_style']);
        }
        else {
            this.labelAdditionalStyle = undefined;
        }
        if (data['margin']) {
            this.margin = data['margin'];
        }
        else {
            this.margin = undefined;
        }
        return this;
    };
    DateRangeElementStyles.prototype.serialize = function () {
        return {
            label_style: this.labelStyle ? this.labelStyle.serialize() : undefined,
            label_additional_style: this.labelAdditionalStyle ? this.labelAdditionalStyle.serialize() : undefined,
            margin: this.margin
        };
    };
    DateRangeElementStyles.prototype.apply = function (other) {
        var _this = this;
        var properties = ['labelStyle', 'labelAdditionalStyle', 'margin'];
        properties.forEach(function (property) {
            if (isSet(other[property])) {
                _this[property] = other[property];
            }
        });
        return this;
    };
    return DateRangeElementStyles;
}());
export { DateRangeElementStyles };
export function getDateRangeElementStyles(element) {
    var options = pickBy(__assign({ labelStyle: element.labelStyle, labelAdditionalStyle: element.labelAdditionalStyle }, (keys(element.margin).length && {
        margin: element.margin
    })), function (v) { return isSet(v); });
    if (!keys(options).length) {
        return;
    }
    return new DateRangeElementStyles(options);
}
export function applyDateRangeElementStyles(element, styles) {
    if (!styles) {
        styles = new DateRangeElementStyles();
    }
    element.labelStyle = styles.labelStyle;
    element.labelAdditionalStyle = styles.labelAdditionalStyle;
    element.margin = styles.margin;
}
