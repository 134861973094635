import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { AlertElementItem, AlertStyle, AlertTint, MarginControl } from '@modules/customize';
import { ElementConfigurationService } from '@modules/customize-configuration';
import { Input, Input as FieldInput } from '@modules/fields';
import { FieldInputControl } from '@modules/parameters';
import { controlValid, isSet } from '@shared';

export function validateActions(): AsyncValidatorFn {
  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    const parent = control.parent as CustomizeBarAlertEditForm;

    if (!parent) {
      return of(null);
    }

    if (!control.value || !control.value.length) {
      return of(null);
    }

    return combineLatest(control.value.map(item => parent.elementConfigurationService.isActionConfigured(item))).pipe(
      map(result => {
        if (result.some(configured => !configured)) {
          return { required: true };
        }
      })
    );
  };
}

@Injectable()
export class CustomizeBarAlertEditForm extends FormGroup {
  element: AlertElementItem;

  controls: {
    tint: FormControl;
    color_enabled: FormControl;
    color: FormControl;
    style: FormControl;
    title: FieldInputControl;
    message: FieldInputControl;
    icon: FormControl;
    actions: FormControl;
    visible_input: FieldInputControl;
    margin: MarginControl;
  };

  tintOptions = [
    {
      value: AlertTint.Default,
      color: '#dae0ea'
    },
    {
      value: AlertTint.Info,
      color: '#607fff'
    },
    {
      value: AlertTint.Danger,
      color: '#ff2a2a'
    },
    {
      value: AlertTint.Warning,
      color: '#fac72d'
    },
    {
      value: AlertTint.Success,
      color: '#2cca74'
    },
    {
      value: AlertTint.Special,
      color: '#7640f5'
    }
  ];

  styleOptions = [
    {
      value: AlertStyle.Fill,
      name: 'Alert with fill',
      image: 'alert-style-fill'
    },
    {
      value: AlertStyle.Border,
      name: 'Alert with border',
      image: 'alert-style-border'
    }
  ];

  constructor(public elementConfigurationService: ElementConfigurationService) {
    super({
      tint: new FormControl(AlertTint.Default),
      color_enabled: new FormControl(false),
      color: new FormControl('#7640f5'),
      style: new FormControl(AlertStyle.Fill),
      title: new FieldInputControl({ path: ['value'] }),
      message: new FieldInputControl({ path: ['value'] }),
      icon: new FormControl(null),
      actions: new FormControl([], undefined, validateActions()),
      visible_input: new FieldInputControl({ path: ['value'] }),
      margin: new MarginControl()
    });
  }

  init(element: AlertElementItem, firstInit = false) {
    this.element = element;

    const value = {
      tint: element.tint,
      color_enabled: isSet(element.color),
      color: isSet(element.color) ? element.color : '#7640f5',
      style: isSet(element.style) ? element.style : AlertStyle.Fill,
      title: element.title ? element.title.serializeWithoutPath() : {},
      message: element.message ? element.message.serializeWithoutPath() : {},
      icon: element.icon,
      actions: element.actions,
      visible_input: element.visibleInput ? element.visibleInput.serializeWithoutPath() : {},
      margin: element.margin
    };

    this.patchValue(value, { emitEvent: false });

    if (!firstInit) {
      this.markAsDirty();
    }
  }

  isConfigured(instance: AlertElementItem): boolean {
    return this.elementConfigurationService.isAlertConfigured(instance);
  }

  controlsValid$(controls: AbstractControl[]): Observable<boolean> {
    if (!controls.length) {
      return of(true);
    }

    return combineLatest(controls.map(control => controlValid(control))).pipe(
      map(result => result.every(item => item))
      // debounceTime(60) TODO: Too long wait with debounceTime
    );
  }

  actionsValid$(): Observable<boolean> {
    return this.controlsValid$([this.controls.actions]);
  }

  submit(): AlertElementItem {
    const instance = cloneDeep(this.element) as AlertElementItem;

    instance.tint = this.controls.tint.value;

    if (this.controls.color_enabled.value && isSet(this.controls.color.value)) {
      instance.color = this.controls.color.value;
    } else {
      instance.color = undefined;
    }

    instance.style = this.controls.style.value;
    instance.title = this.controls.title.value ? new FieldInput().deserialize(this.controls.title.value) : undefined;
    instance.message = this.controls.message.value
      ? new FieldInput().deserialize(this.controls.message.value)
      : undefined;
    instance.icon = this.controls.icon.value;
    instance.actions = this.controls.actions.value;
    instance.margin = this.controls.margin.value;

    if (this.controls.visible_input.value) {
      instance.visibleInput = new Input().deserialize(this.controls.visible_input.value);
    } else {
      instance.visibleInput = undefined;
    }

    return instance;
  }
}
