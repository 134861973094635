/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./page-block.component";
import * as i2 from "../../directives/page-block-defaults/page-block-defaults.directive";
var styles_PageBlockComponent = [];
var RenderType_PageBlockComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PageBlockComponent, data: {} });
export { RenderType_PageBlockComponent as RenderType_PageBlockComponent };
export function View_PageBlockComponent_0(_l) { return i0.ɵvid(2, [i0.ɵncd(null, 0)], null, null); }
export function View_PageBlockComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-page-block", [], [[2, "page-block", null], [2, "page-block_title", null], [2, "page-block_fill", null], [2, "page-block_first", null], [2, "page-block_last", null], [2, "page-block_size_m", null], [2, "page-block_margin-size_xxs", null], [2, "page-block_margin-size_xs", null], [2, "page-block_margin-size_s", null], [2, "page-block_margin-size_m", null], [2, "page-block_margin-size_l", null], [2, "page-block_padding-size_none", null], [2, "page-block_padding-size_xxxs", null], [2, "page-block_padding-size_xxs", null], [2, "page-block_padding-size_xs", null], [2, "page-block_padding-size_s", null], [2, "page-block_padding-size_m", null]], null, null, View_PageBlockComponent_0, RenderType_PageBlockComponent)), i0.ɵdid(1, 114688, null, 0, i1.PageBlockComponent, [[2, i2.PageBlockDefaultsDirective]], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).cls; var currVal_1 = i0.ɵnov(_v, 1).title; var currVal_2 = i0.ɵnov(_v, 1).fill; var currVal_3 = i0.ɵnov(_v, 1).first; var currVal_4 = i0.ɵnov(_v, 1).last; var currVal_5 = i0.ɵnov(_v, 1).sizeM; var currVal_6 = i0.ɵnov(_v, 1).marginSizeXXS; var currVal_7 = i0.ɵnov(_v, 1).marginSizeXS; var currVal_8 = i0.ɵnov(_v, 1).marginSizeS; var currVal_9 = i0.ɵnov(_v, 1).marginSizeM; var currVal_10 = i0.ɵnov(_v, 1).marginSizeL; var currVal_11 = i0.ɵnov(_v, 1).paddingSizeNone; var currVal_12 = i0.ɵnov(_v, 1).paddingSizeXXXS; var currVal_13 = i0.ɵnov(_v, 1).paddingSizeXXS; var currVal_14 = i0.ɵnov(_v, 1).paddingSizeXS; var currVal_15 = i0.ɵnov(_v, 1).paddingSizeS; var currVal_16 = i0.ɵnov(_v, 1).paddingSizeM; _ck(_v, 0, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15, currVal_16]); }); }
var PageBlockComponentNgFactory = i0.ɵccf("app-page-block", i1.PageBlockComponent, View_PageBlockComponent_Host_0, { cls: "cls", title: "title", fill: "fill", first: "first", last: "last", size: "size", marginSize: "marginSize", paddingSize: "paddingSize" }, {}, ["*"]);
export { PageBlockComponentNgFactory as PageBlockComponentNgFactory };
