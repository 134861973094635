import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { JET_APP_RESOURCE } from '@modules/projects';
import { isSet } from '@shared';

import { ApiParameter } from '../../../data/api-parameter';
import { ApiParameterType } from '../../../data/api-parameter-type';
import { fieldToApiParameter } from '../../../utils/common';
import { ModelApiBaseQueryComponent } from '../model-api-base-query/model-api-base-query.component';

enum DataTabName {
  Request = 'request',
  Response = 'response'
}

@Component({
  selector: 'app-model-api-update-query',
  templateUrl: './model-api-update-query.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModelApiUpdateQueryComponent extends ModelApiBaseQueryComponent implements OnInit {
  parameters: ApiParameter[] = [];
  dataTabs: { name: DataTabName; label: string }[] = [
    {
      name: DataTabName.Request,
      label: 'Request'
    },
    {
      name: DataTabName.Response,
      label: 'Response'
    }
  ];
  currentDataTabName = DataTabName.Request;
  dataTabNames = DataTabName;

  ngOnInit() {
    super.ngOnInit();
    this.fetchModel();
    this.updateParameters();
    this.updateCurl();
  }

  updateParameters() {
    this.parameters = this.getParameters();
    this.cd.markForCheck();
  }

  updateCurl() {
    this.curl = this.getCurlRequest({
      url: this.getAbsoluteApiUrl(),
      method: 'PATCH',
      data: this.getRequestData()
    });
  }

  onModelFetched() {
    this.updateCurl();
  }

  getParametersAuto(): ApiParameter[] {
    const controller = this.resourceControllerService.get(this.resource.type);
    const modelParameters = controller.getUpdateParametersOrDefaults(this.resource, this.modelDescription);
    return modelParameters.map(item => {
      const type =
        isSet(this.modelDescription.primaryKeyField) && item.name == this.getPrimaryKey()
          ? ApiParameterType.Path
          : ApiParameterType.Body;
      return fieldToApiParameter(item, type);
    });
  }

  getRequestDataStr(): string {
    const data = this.getRequestData();
    return JSON.stringify(data, null, this.indentSize);
  }

  getResponseData(): Object {
    const model = this.model || this.getDefaultModel();
    const modelData = this.serializer ? this.serializer(model) : this.serializeDefault(model);

    return JSON.stringify(modelData, null, this.indentSize);
  }

  getQueryUrl() {
    return `/:${this.getPrimaryKey()}`;
  }

  setCurrentQueryName(value: DataTabName) {
    this.currentDataTabName = value;
    this.cd.markForCheck();
  }
}
