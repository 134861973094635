<ng-container *ngIf="visible || (customizeEnabled$ | async)">
  <div *ngIf="element" class="action-button action-button_manual-margin dropdown-anchor">
    <span
      class="button button_theme button_styles action-button_manual-margin"
      [class.button_primary]="!element.tint && style == tintStyles.Primary"
      [class.button_primary-transparent]="!element.tint && style == tintStyles.Transparent"
      [class.button_icon]="!(verboseName | appIsSet)"
      [class.button_disabled]="disabled"
      [class.button_fill]="element.alignHorizontal == alignHorizontals.Justify"
      [appButtonTintColor]="element.tint || accentColor"
      [appButtonTintColorStyle]="element.style"
      [appActionElementStyles]="undefined"
      [appActionElementStylesTintColorStyle]="element.style"
      #menuTrigger="matMenuTrigger"
      (mouseenter)="buttonHover.next(true)"
      (mouseleave)="buttonHover.next(false)"
      [mat-menu-trigger-for]="matMenuDropdown"
    >
      <span
        *ngIf="element.icon"
        class="button__icon_left button__icon"
        [class.button__icon_left]="verboseName | appIsSet"
        [appIcon]="element.icon"
      ></span>
      <span *ngIf="verboseName | appIsSet" class="button__label">
        {{ verboseName }}
      </span>
    </span>

    <mat-menu #matMenuDropdown="matMenu" [class]="'mat-menu-panel-theme'" [hasBackdrop]="false">
      <ng-template matMenuContent>
        <div (mouseenter)="menuHover.next(true)" (mouseleave)="menuHover.next(false)">
          <app-action-dropdown-element-item
            *ngFor="let item of element.actionItems"
            [item]="item"
            [context]="context"
            [contextElement]="viewContextElement"
            [analyticsSource]="element.analyticsGenericName"
          ></app-action-dropdown-element-item>
        </div>
      </ng-template>
    </mat-menu>

    <span
      *ngIf="element.tooltip | appIsSet"
      class="action-button__help help-icon icon-help"
      [appTip]="element.tooltip"
      [appTipOptions]="{ side: 'top' }"
    ></span>
  </div>
</ng-container>
