/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../shared/pipes/is-set/is-set.pipe";
import * as i2 from "../font-menu-overlay/font-menu-overlay.component.ngfactory";
import * as i3 from "../font-menu-overlay/font-menu-overlay.component";
import * as i4 from "@angular/cdk/overlay";
import * as i5 from "@angular/common";
import * as i6 from "./font-control.component";
var styles_FontControlComponent = [];
var RenderType_FontControlComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FontControlComponent, data: {} });
export { RenderType_FontControlComponent as RenderType_FontControlComponent };
function View_FontControlComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "select__input-label-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.prefix; _ck(_v, 1, 0, currVal_0); }); }
function View_FontControlComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.valueStr; _ck(_v, 1, 0, currVal_0); }); }
function View_FontControlComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.placeholder; _ck(_v, 1, 0, currVal_0); }); }
export function View_FontControlComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i1.IsSetPipe, []), i0.ɵqud(402653184, 1, { cdkConnectedOverlay: 0 }), (_l()(), i0.ɵeld(2, 0, null, null, 1, "app-font-menu-overlay", [], null, [[null, "fontSelect"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("fontSelect" === en)) {
        var pd_0 = (_co.onFontSelect($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_FontMenuOverlayComponent_0, i2.RenderType_FontMenuOverlayComponent)), i0.ɵdid(3, 4964352, [["overlay", 4]], 0, i3.FontMenuOverlayComponent, [i0.ChangeDetectorRef], { value: [0, "value"], origin: [1, "origin"], globalFonts: [2, "globalFonts"] }, { fontSelect: "fontSelect" }), (_l()(), i0.ɵeld(4, 0, null, null, 13, "div", [["class", "select select_theme_jet select__input_opened"]], [[2, "select_fill", null], [2, "select_small", null]], null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 12, "div", [["cdkOverlayOrigin", ""], ["class", "select__input"]], [[2, "select__input_disabled", null], [2, "select__input_opened", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3).open() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(6, 16384, [["overlay_origin", 4]], 0, i4.CdkOverlayOrigin, [i0.ElementRef], null, null), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_FontControlComponent_1)), i0.ɵdid(8, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵppd(9, 1), (_l()(), i0.ɵeld(10, 0, null, null, 6, "span", [["class", "select__input-label"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_FontControlComponent_2)), i0.ɵdid(12, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵppd(13, 1), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_FontControlComponent_3)), i0.ɵdid(15, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵppd(16, 1), (_l()(), i0.ɵeld(17, 0, null, null, 0, "span", [["class", "select__input-arrow"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.control.value; var currVal_1 = i0.ɵnov(_v, 6); var currVal_2 = _co.globalFonts; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2); var currVal_7 = i0.ɵunv(_v, 8, 0, _ck(_v, 9, 0, i0.ɵnov(_v, 0), _co.prefix)); _ck(_v, 8, 0, currVal_7); var currVal_8 = i0.ɵunv(_v, 12, 0, _ck(_v, 13, 0, i0.ɵnov(_v, 0), _co.valueStr)); _ck(_v, 12, 0, currVal_8); var currVal_9 = !i0.ɵunv(_v, 15, 0, _ck(_v, 16, 0, i0.ɵnov(_v, 0), _co.valueStr)); _ck(_v, 15, 0, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.fill; var currVal_4 = _co.small; _ck(_v, 4, 0, currVal_3, currVal_4); var currVal_5 = _co.control.disabled; var currVal_6 = i0.ɵnov(_v, 3).isOpened(); _ck(_v, 5, 0, currVal_5, currVal_6); }); }
export function View_FontControlComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-font-control", [], null, null, null, View_FontControlComponent_0, RenderType_FontControlComponent)), i0.ɵdid(1, 245760, null, 0, i6.FontControlComponent, [i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FontControlComponentNgFactory = i0.ɵccf("app-font-control", i6.FontControlComponent, View_FontControlComponent_Host_0, { control: "control", placeholder: "placeholder", globalFonts: "globalFonts", prefix: "prefix", fill: "fill", small: "small", allowNull: "allowNull" }, { fontSelect: "fontSelect" }, []);
export { FontControlComponentNgFactory as FontControlComponentNgFactory };
