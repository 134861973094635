/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./query-builder-simple.component";
var styles_QueryBuilderSimpleComponent = [];
var RenderType_QueryBuilderSimpleComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_QueryBuilderSimpleComponent, data: {} });
export { RenderType_QueryBuilderSimpleComponent as RenderType_QueryBuilderSimpleComponent };
function View_QueryBuilderSimpleComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "query-builder__main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [["class", "query-builder__main-top"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [["class", "code-editor code-editor_flexible"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "div", [["class", "code-editor__main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 0, "div", [["class", "code-editor__main-code"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 2, "div", [["class", "query-builder__main-bottom"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "div", [["class", "query-builder__toolbar"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 0, "div", [["class", "query-builder__radio query-builder__radio_orange"]], null, null, null, null, null))], null, null); }
export function View_QueryBuilderSimpleComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 17, "div", [["class", "query-builder"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 14, "div", [["class", "query-builder__side"]], null, null, null, null, null)), i0.ɵncd(null, 0), (_l()(), i0.ɵeld(3, 0, null, null, 3, "div", [["class", "query-builder-side__content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 2, "div", [["class", "query-builder__step"]], [[2, "query-builder__step_active", null], [2, "query-builder__step_completed", null], [2, "query-builder__step_disabled", null]], null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "div", [["class", "query-builder__step-content"], ["style", "padding: 0 20px; text-align: center;"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" This action is configured automatically "])), (_l()(), i0.ɵeld(7, 0, null, null, 8, "div", [["class", "query-builder-side__footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 3, "div", [["class", "query-builder-side__footer-left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 2, "button", [["class", "button"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 1, "span", [["class", "button__label"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Cancel"])), (_l()(), i0.ɵeld(12, 0, null, null, 3, "div", [["class", "query-builder-side__footer-right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 2, "button", [["class", "button button_primary button_shadow"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.submit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 1, "span", [["class", "button__label"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Save"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_QueryBuilderSimpleComponent_1)), i0.ɵdid(17, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_3 = false; _ck(_v, 17, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = false; var currVal_1 = false; var currVal_2 = false; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_QueryBuilderSimpleComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-query-builder-simple", [], null, null, null, View_QueryBuilderSimpleComponent_0, RenderType_QueryBuilderSimpleComponent)), i0.ɵdid(1, 114688, null, 0, i2.QueryBuilderSimpleComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var QueryBuilderSimpleComponentNgFactory = i0.ɵccf("app-query-builder-simple", i2.QueryBuilderSimpleComponent, View_QueryBuilderSimpleComponent_Host_0, {}, { saved: "saved", canceled: "canceled" }, ["ng-container[tabs]"]);
export { QueryBuilderSimpleComponentNgFactory as QueryBuilderSimpleComponentNgFactory };
