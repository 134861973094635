<ng-container *ngIf="!colorInputEnabledControl.value">
  <app-color-selector-advanced [formControl]="colorControl">
    <ng-container bottom>
      <div
        class="compact-button-tag compact-button-tag_size_l button-tags__item_fill"
        (click)="toggleColorInputEnabled()"
      >
        <span class="compact-button-tag__icon icon-function"></span>
        <span class="compact-button-tag__label">Formula</span>
      </div>
    </ng-container>
  </app-color-selector-advanced>
</ng-container>

<ng-container *ngIf="colorInputEnabledControl.value">
  <div class="compact-inputs-element">
    <app-input-edit
      [itemForm]="colorInputControl"
      [context]="viewContext"
      [staticValueDisabled]="true"
      [focusedInitial]="colorInputFocused"
      [formulaPlaceholder]="formulaPlaceholder"
      [fill]="true"
      [dark]="true"
      [darker]="true"
    ></app-input-edit>
  </div>

  <div class="compact-inputs-element">
    <div class="compact-button-tag button-tags__item_fill" (click)="toggleColorInputEnabled()">
      <span class="compact-button-tag__icon icon-function"></span>
      <span class="compact-button-tag__label">Disable Formula</span>
    </div>
  </div>
</ng-container>
