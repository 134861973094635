<div *ngIf="(title | appIsSet) || reloadEnabled" class="value-widget__label">
  <span
    class="value-widget__label-text"
    [appTextStyle]="widget.titleStyle"
    [appTextStyleGlobal]="'valueWidgetTitleTextStyle'"
  >
    {{ title }}
  </span>

  <span
    *ngIf="widget.tooltip | appIsSet"
    class="value-widget__label-help help-icon icon-help"
    [appTip]="widget.tooltip"
    [appTipOptions]="{ side: 'top' }"
  ></span>

  <span
    *ngIf="reloadEnabled"
    class="value-widget__label-button icon-repeat"
    [class.value-widget__label-button_right]="title | appIsSet"
    [appTintColor]="accentColor"
    [appTintColorStyle]="tintStyles.Default"
    (click)="reload.emit()"
  ></span>
</div>

<div
  class="value-widget__value"
  [class.value-widget__value_interactive]="valueClickEnabled"
  [title]="(value | appIsSet) ? value : null"
  [appTextStyle]="widget.valueStyle"
  [appTextStyleGlobal]="'valueWidgetValueTextStyle'"
  (click)="valueClick.emit({ element: value_element })"
  #value_element
>
  <div
    *ngIf="icon"
    class="value-widget__value-icon"
    [class.value-widget__value-icon_image]="
      (icon | appIconType) == iconTypes.Emoji || (icon | appIconType) == iconTypes.Image
    "
    [appIcon]="icon"
  ></div>

  <div *ngIf="value | appIsSet" class="value-widget__value-content">
    <span *ngIf="widget.prefix" class="value-widget__small value-widget__small_left">{{ widget.prefix }}</span>
    {{ value | appDomainFormatNumber: widget.format | async }}
    <span *ngIf="widget.postfix" class="value-widget__small value-widget__small_right">{{ widget.postfix }}</span>
  </div>

  <div *ngIf="!(value | appIsSet)" class="value-widget__value-content">
    <span [class.loading-animation]="false"><span class="stub-text">45</span></span>
  </div>
</div>

<div *ngIf="(compareValue | appIsSet) && isNumber" class="value-widget__compare">
  <span
    *ngIf="valueNumber > compareValueNumber || valueNumber < compareValueNumber"
    class="value-widget__compare-item"
    [appTip]="
      (compareValue | appDomainFormatNumber: widget.format | async) +
      ' → ' +
      (value | appDomainFormatNumber: widget.format | async)
    "
    [appTipOptions]="{ side: 'bottom' }"
  >
    <span
      class="value-widget__compare-icon"
      [ngClass]="{
        'icon-arrow_up': valueNumber > compareValueNumber,
        'icon-arrow_down': valueNumber < compareValueNumber,
        'value-widget__compare-icon_positive': valueNumber > compareValueNumber,
        'value-widget__compare-icon_negative': valueNumber < compareValueNumber,
        color_green_1: comparePositive === true,
        color_red_1: comparePositive === false,
        'background-color_green_2': comparePositive === true,
        'background-color_red_2': comparePositive === false
      }"
    ></span>

    <span
      *ngIf="compareValueNumber !== 0"
      class="value-widget__compare-value"
      [ngClass]="{
        color_green_1: comparePositive === true,
        color_red_1: comparePositive === false
      }"
    >
      <ng-container *ngIf="isNumber && valueNumber > compareValueNumber">+</ng-container
      >{{ valueNumber | appRatio: compareValueNumber | appDomainFormatNumber: '0.00%' | async }}
    </span>
  </span>

  <span *ngIf="compareLabel" class="value-widget__compare-label">{{ compareLabel }}</span>
</div>
