import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';

import { TintStyle } from '@modules/actions';
import { CHART_COLORS } from '@modules/charts';
import { defaultAccentColor } from '@modules/colors';
import { ActionElementStyles, FieldElementStyles } from '@modules/customize';
import { TableStubData } from '@modules/table';
import { ThemeService } from '@modules/theme';
import { isSet } from '@shared';

import { ProjectAppearanceForm } from '../project-settings/project-appearance.form';
import { pieChartSampleData } from './pie-chart-sample-data';

@Component({
  selector: 'app-admin-template-page',
  templateUrl: './admin-template-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminTemplatePageComponent implements OnInit, OnDestroy {
  @Input() appearanceForm: ProjectAppearanceForm;
  @Input() fieldElementStyles: FieldElementStyles;
  @Input() actionElementStylesPrimary: ActionElementStyles;
  @Input() actionElementStylesDefault: ActionElementStyles;

  defaultAccentColor = defaultAccentColor;
  stepsProgress = 0;
  sampleDatasets = pieChartSampleData;
  defaultChartColors: string[] = CHART_COLORS;
  tableData: TableStubData = {
    headers: ['First name', 'Last name', 'E-mail', 'Company', 'Actions'],
    rows: [
      ['John', 'Doe', 'john.doe@email.com', 'TechCorp', ''],
      ['Anna', 'Smith', 'anna.smith@email.com', 'HealthPlus', ''],
      ['Robert', 'Johnson', 'robert.johnson@email.com', 'FinancePro', ''],
      ['Emily', 'Brown', 'emily.brown@email.com', 'EduTech', '']
    ]
  };
  tintStyles = TintStyle;

  constructor(public themeService: ThemeService, private cd: ChangeDetectorRef) {}

  ngOnInit() {
    const stepIndex = 1;
    const itemWidth = 1 / 4;

    this.stepsProgress = itemWidth * stepIndex + itemWidth * 0.5;

    combineLatest(
      this.appearanceForm.controls.auto_colors.getEffectiveValue$(),
      this.appearanceForm.controls.auto_colors_dark.getEffectiveValue$(),
      this.themeService.isDarkTheme$
    )
      .pipe(untilDestroyed(this))
      .subscribe(([defaultColors, defaultColorsDark, isDarkTheme]) => {
        this.defaultChartColors = CHART_COLORS.map((defaultColor, i) => {
          if (isDarkTheme) {
            return isSet(defaultColorsDark[i]) ? defaultColorsDark[i] : defaultColor;
          } else {
            return isSet(defaultColors[i]) ? defaultColors[i] : defaultColor;
          }
        });
        this.cd.markForCheck();
      });
  }

  ngOnDestroy(): void {}
}
