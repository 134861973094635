import { CdkConnectedOverlay, ConnectedOverlayPositionChange, ConnectedPosition } from '@angular/cdk/overlay';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Subscription } from 'rxjs';

import { MarginControl } from '@modules/customize';
import { isSet } from '@shared';

import { stylesEditPopoverPositions } from '../styles-edit/styles-edit-popover-positions';

@Component({
  selector: 'app-styles-edit-margin',
  templateUrl: './styles-edit-margin.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StylesEditMarginComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() control: MarginControl;
  @Input() subtitle = 'Spacing';
  @Input() additional: string;
  @Input() icon = 'sides';
  @Input() contrast = false;
  @Input() disabled = false;
  @Input() added = false;
  @Input() removeEnabled = true;
  @Output() remove = new EventEmitter<void>();

  @ViewChild(CdkConnectedOverlay) cdkConnectedOverlay: CdkConnectedOverlay;

  valueStr: string;
  dropdownOpened = false;
  popoverPositions = stylesEditPopoverPositions;
  popoverPositionsSubscription: Subscription;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.control
      .serialize$()
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        const values = value
          ? [value.top, value.right, value.bottom, value.left].map(item => {
              if (item == 0) {
                return '0';
              } else if (isSet(item)) {
                return `${item}px`;
              }
            })
          : undefined;

        if (values && values.some(item => isSet(item))) {
          if (values.slice(1).every(item => item == values[0])) {
            this.valueStr = values[0];
          } else {
            this.valueStr = values
              .map(item => {
                if (isSet(item)) {
                  return item;
                } else {
                  return 'auto';
                }
              })
              .join(' ');
          }
        } else {
          this.valueStr = undefined;
        }

        this.cd.markForCheck();
      });

    if (this.added) {
      this.setDropdownOpened(true);
    }
  }

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    this.setPositionObserver();
  }

  setDropdownOpened(value: boolean) {
    this.dropdownOpened = value;
    this.cd.markForCheck();
  }

  setPositionObserver() {
    if (this.popoverPositionsSubscription) {
      this.popoverPositionsSubscription.unsubscribe();
    }

    if (!this.cdkConnectedOverlay) {
      return;
    }

    this.popoverPositionsSubscription = this.cdkConnectedOverlay.positionChange
      .pipe(untilDestroyed(this))
      .subscribe((e: ConnectedOverlayPositionChange) => {
        const propsEqual = ['offsetX', 'offsetY', 'originX', 'originY', 'overlayX', 'overlayY'];
        const position = this.popoverPositions.find(item =>
          propsEqual.every(prop => (item[prop] || undefined) == e.connectionPair[prop])
        );
        const otherPosition = this.popoverPositions.filter(item => item !== position);

        if (position) {
          this.cdkConnectedOverlay.overlayRef.addPanelClass(position.panelClass);
        }

        otherPosition.forEach(item => this.cdkConnectedOverlay.overlayRef.removePanelClass(item.panelClass));
      });
  }
}
