<div
  class="upload"
  [class.upload_empty]="!preview"
  [class.upload_uploadable]="upload"
  [class.upload_active]="draggingOver"
  [class.upload_disabled]="control?.disabled"
  [class.upload_background]="background"
  [class.upload_thin]="thin"
  [class.upload_theme]="theme"
  (dragover)="onDragOver($event)"
  (dragleave)="onDragLeave($event)"
  (drop)="onDrop($event)"
>
  <div *ngIf="preview" class="upload__preview">
    <ng-content select="ng-container[upload-preview]"></ng-content>
  </div>

  <ng-container *ngIf="upload && !preview && !(uploadProgress | appIsSet)">
    <div class="upload__placeholder">
      <div class="upload__placeholder-icon icon-cloud_upload"></div>

      <div *ngIf="multiple" class="upload__placeholder-label">
        Drop files here or
        <label
          [for]="name + '_upload' | appUniqueId: idToken"
          class="upload__placeholder-button"
          [appTintColor]="accentColor"
          [appTintColorStyle]="tintStyles.Default"
        >
          Choose files
        </label>
      </div>

      <div *ngIf="!multiple" class="upload__placeholder-label">
        Drop file here or
        <label
          [for]="name + '_upload' | appUniqueId: idToken"
          class="upload__placeholder-button"
          [appTintColor]="accentColor"
          [appTintColorStyle]="tintStyles.Default"
        >
          Choose file
        </label>
      </div>
    </div>
  </ng-container>

  <div
    *ngIf="!control?.disabled"
    class="upload__controls"
    [class.upload__controls_outside]="previewInteractive && preview"
  >
    <ng-container *ngIf="upload">
      <input
        class="input input_file"
        type="file"
        [multiple]="multiple"
        [accept]="(accept | appIsSet) ? accept : null"
        (change)="onFileChange($event.target)"
        [id]="name + '_upload' | appUniqueId: idToken"
      />

      <div
        *ngIf="uploadProgress | appIsSet"
        class="input-file-progress input-file-progress_contrast"
        [class.input-file-progress_theme]="theme"
      >
        <div class="input-file-progress__inner" [style.width.%]="uploadProgress * 100"></div>
      </div>

      <ng-container *ngIf="!(uploadProgress | appIsSet)">
        <ng-container *ngIf="preview">
          <div class="upload__buttons">
            <a
              href="javascript:void(0)"
              class="upload__button icon-bin"
              (click)="clearValue.emit()"
              [appTip]="'Clear'"
              [appTipOptions]="{ side: 'top', margin: -4 }"
            ></a>

            <label
              [for]="name + '_upload' | appUniqueId: idToken"
              class="upload__button icon-cloud_upload"
              [class.button_disabled]="disabled"
              [appTip]="'Upload new'"
              [appTipOptions]="{ side: 'top', margin: -4 }"
            >
            </label>

            <ng-content select="ng-container[upload-actions]"></ng-content>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="!upload">
      <div
        class="upload__input-group"
        [class.upload__input-group_focus]="urlFocus$ | async"
        style="margin-right: 10px;"
      >
        <div class="upload__input-group-label">URL</div>
        <input
          class="input upload__input-group-control"
          [class.input_error]="control.invalid"
          type="text"
          (focus)="urlFocus$.next(true)"
          (blur)="urlFocus$.next(false)"
          [appAutofocus]="autofocus"
          [formControl]="control"
          [id]="name | appUniqueId: idToken"
        />
      </div>

      <div *ngIf="preview" class="upload__buttons">
        <ng-content select="ng-container[upload-actions]"></ng-content>
      </div>
    </ng-container>
  </div>

  <label *ngIf="!preview && upload" class="upload__overlay" [for]="name + '_upload' | appUniqueId: idToken"> </label>
</div>

<div *ngIf="uploadError" class="field__errors">
  <div class="field__error">{{ uploadError }}</div>
</div>
