<app-auto-field
  [value]="resolveItemColumn(item)"
  [readonly]="true"
  [label]="!!item.verboseName"
  [field]="createField({ name: item.name, label: item.verboseName, field: item.field, params: item.params })"
  [labelStyleGlobal]="'fieldLabelTextStyle'"
  [labelAdditionalStyleGlobal]="'fieldLabelAdditionalTextStyle'"
  [labelButtons]="labelButtons"
>
</app-auto-field>
