import { ComponentPortal, DomPortalHost } from '@angular/cdk/portal';
import { ApplicationRef, ChangeDetectorRef, ComponentFactoryResolver, ComponentRef, DoCheck, ElementRef, EventEmitter, Injector, OnDestroy, Type, ViewContainerRef } from '@angular/core';
import keys from 'lodash/keys';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable } from 'rxjs';
var DynamicComponent = /** @class */ (function () {
    function DynamicComponent(el, injector, resolver, appRef, vcr) {
        this.el = el;
        this.injector = injector;
        this.resolver = resolver;
        this.appRef = appRef;
        this.vcr = vcr;
        this.subscriptions = [];
    }
    Object.defineProperty(DynamicComponent.prototype, "componentData", {
        set: function (data) {
            this.deinitComponent();
            this.initComponent(data);
        },
        enumerable: true,
        configurable: true
    });
    DynamicComponent.prototype.ngOnDestroy = function () {
        this.deinitComponent();
    };
    DynamicComponent.prototype.ngDoCheck = function () {
        if (this.currentComponent) {
            try {
                var cd = this.currentComponent.injector.get(ChangeDetectorRef);
                cd.markForCheck();
            }
            catch (e) { }
        }
    };
    DynamicComponent.prototype.initComponent = function (data) {
        var injector = Injector.create({
            providers: data.providers || [],
            parent: data.injector || this.injector
        });
        var portalHost = new DomPortalHost(this.el.nativeElement, data.resolver || this.resolver, this.appRef, this.injector);
        var portal = new ComponentPortal(data.component, this.vcr, injector);
        var componentRef = portalHost.attach(portal);
        var component = componentRef.instance;
        var componentName = component.constructor ? component.constructor.name : component.toString();
        if (data.inputs) {
            for (var _i = 0, _a = keys(data.inputs); _i < _a.length; _i++) {
                var key = _a[_i];
                component[key] = data.inputs[key];
            }
        }
        var subscriptions = [];
        if (data.outputs) {
            var _loop_1 = function (key) {
                if (!component.hasOwnProperty(key)) {
                    console.error("No such @Output \"" + key + "\" in \"" + componentName + "\"");
                    return "continue";
                }
                else if (!(component[key] instanceof Observable)) {
                    console.error("\"" + key + "\" is not a valid @Output in " + componentName);
                    return "continue";
                }
                else if (!component[key]) {
                    console.error("\"" + key + "\" @Output is set to " + component[key] + " in " + componentName);
                    return "continue";
                }
                var output = component[key];
                subscriptions.push(output.pipe(untilDestroyed(this_1)).subscribe(function () {
                    var args = [];
                    for (var _i = 0; _i < arguments.length; _i++) {
                        args[_i] = arguments[_i];
                    }
                    data.outputs[key].forEach(function (item) { return item.apply(void 0, args); });
                }));
            };
            var this_1 = this;
            for (var _b = 0, _c = keys(data.outputs); _b < _c.length; _b++) {
                var key = _c[_b];
                _loop_1(key);
            }
        }
        this.currentComponent = componentRef;
        this.subscriptions = subscriptions;
    };
    DynamicComponent.prototype.deinitComponent = function () {
        if (!this.currentComponent) {
            return;
        }
        this.subscriptions.forEach(function (item) { return item.unsubscribe(); });
        this.currentComponent.destroy();
        this.currentComponent = undefined;
    };
    return DynamicComponent;
}());
export { DynamicComponent };
