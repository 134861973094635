import { OnDestroy } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormControl, FormGroup } from '@angular/forms';
import values from 'lodash/values';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, merge, Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, skip } from 'rxjs/operators';

import { ActionService } from '@modules/action-queries';
import { ListDefaultSelection } from '@modules/customize';
import { ElementConfigurationService } from '@modules/customize-configuration';
import { Input, Input as FieldInput, InputValueType } from '@modules/fields';
import { ModelDescriptionStore } from '@modules/model-queries';
import { ModelDescription } from '@modules/models';
import { FieldInputControl } from '@modules/parameters';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { ListModelDescriptionQuery, QueryType } from '@modules/queries';
import { ResourceControllerService } from '@modules/resources';
import { controlValid } from '@shared';

import { ListModelDescriptionDataSourceControl } from '../../model-description-data-source-edit/list-model-description-data-source';

export interface ModelOption {
  queryType: QueryType;
  model?: string;
}

export interface CustomizeListOptions<T> {
  settings: T;
  name?: string;
  nameEditable?: boolean;
  nameCleanValue?: (value: string) => string;
  visibleInput?: Input;
  elementStyles?: any;
  elementStylesEditable?: boolean;
  firstInit?: boolean;
  pageUid?: string;
  elementUid?: string;
}

export interface CustomizeListResult<T> {
  settings: T;
  visibleInput: Input;
  name: string;
  elementStyles?: any;
}

export const validateAction: AsyncValidatorFn = control => {
  const parent = control.parent as CustomizeBarListLayoutSettingsForm<any>;

  if (!parent) {
    return of(null);
  }

  if (!control.value) {
    return of(null);
  }

  return parent.elementConfigurationService.isActionConfigured(control.value).pipe(
    map(configured => {
      if (!configured) {
        return { required: true };
      }
    })
  );
};

export const validateActions: AsyncValidatorFn = control => {
  const parent = control.parent as CustomizeBarListLayoutSettingsForm<any>;

  if (!parent) {
    return of(null);
  }

  if (!control.value || !control.value.length) {
    return of(null);
  }

  return combineLatest(control.value.map(item => parent.elementConfigurationService.isActionConfigured(item))).pipe(
    map(result => {
      if (result.some(configured => !configured)) {
        return { required: true };
      }
    })
  );
};

export abstract class CustomizeBarListLayoutSettingsForm<T> extends FormGroup implements OnDestroy {
  controls: {
    header?: FormControl;
    title?: FieldInputControl;
    data_source?: ListModelDescriptionDataSourceControl;
    search_enabled?: FormControl;
    sorting_field?: FormControl;
    sorting_asc?: FormControl;
    name?: FormControl;
  };

  ignoreSubmitControls: AbstractControl[] = [];
  submitChanges = merge(
    ...values(this.controls)
      .filter(item => !this.ignoreSubmitControls.includes(item))
      .map(item => item.valueChanges)
  );

  settings: T;
  options: CustomizeListOptions<T>;
  pageUid: string;
  elementUid: string;
  queryClass = ListModelDescriptionQuery;
  sortingOptions = [
    { value: true, name: 'Ascending' },
    { value: false, name: 'Descending' }
  ];
  listDefaultSelectionOptions = [
    { value: undefined, name: 'None' },
    { value: ListDefaultSelection.First, name: 'First' }
  ];

  constructor(
    protected currentProjectStore: CurrentProjectStore,
    protected currentEnvironmentStore: CurrentEnvironmentStore,
    protected resourceControllerService: ResourceControllerService,
    protected modelDescriptionStore: ModelDescriptionStore,
    protected actionService: ActionService,
    public elementConfigurationService: ElementConfigurationService,
    controls: {
      [key: string]: AbstractControl;
    }
  ) {
    super(controls);
  }

  ngOnDestroy(): void {}

  abstract init(options: CustomizeListOptions<T>): Observable<void>;

  trackChanges(options: CustomizeListOptions<T>) {
    if (options.nameEditable) {
      this.controls.title.valueChanges.pipe(untilDestroyed(this)).subscribe(inputValue => {
        const input = inputValue ? new FieldInput().deserialize(inputValue) : undefined;

        if (!input || input.valueType != InputValueType.StaticValue) {
          return;
        }

        let name = input.staticValue;

        if (options.nameCleanValue) {
          name = options.nameCleanValue(name);
        }

        this.controls.name.patchValue(name);
      });
    }

    this.controls.data_source
      .getModelDescription$()
      .pipe(
        distinctUntilChanged((lhs, rhs) => {
          const modelId = (item: ModelDescription) => (item ? item.modelId : undefined);
          return modelId(lhs) === modelId(rhs);
        }),
        skip(1),
        untilDestroyed(this)
      )
      .subscribe(modelDescription => this.onModelChange(modelDescription));
  }

  abstract isConfigured(instance: T): Observable<boolean>;

  abstract submit(): CustomizeListResult<T>;

  controlsValid$(controls: string[]): Observable<boolean> {
    return combineLatest(controls.map(item => controlValid(this.controls[item]))).pipe(
      map(result => result.every(item => item)),
      debounceTime(60)
    );
  }

  toggleDefaultSorting() {
    const control = this.controls.sorting_asc;
    control.patchValue(!control.value);
  }

  onModelChange(modelDescription: ModelDescription) {
    let sortingField: string;
    let sortingAsc = true;

    if (modelDescription && modelDescription.defaultOrderBy) {
      if (modelDescription.defaultOrderBy.startsWith('-')) {
        sortingField = modelDescription.defaultOrderBy.slice(1);
        sortingAsc = false;
      } else {
        sortingField = modelDescription.defaultOrderBy;
        sortingAsc = true;
      }
    }

    this.controls.sorting_field.patchValue(sortingField || null);
    this.controls.sorting_asc.patchValue(sortingAsc);

    if (modelDescription && modelDescription.verboseNamePlural) {
      this.controls.title.patchValue({
        value_type: InputValueType.StaticValue,
        static_value: modelDescription.verboseNamePlural
      });
    }
  }

  initPerPageClean(control: FormControl) {
    control.valueChanges
      .pipe(
        filter(val => val == '0'),
        untilDestroyed(this)
      )
      .subscribe(() => control.patchValue(''));
  }
}
