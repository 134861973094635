<app-sidebar-section>
  <div *ngIf="typeFieldEnabled" class="sidebar__element">
    <app-sidebar-field [label]="typeFieldLabel" [required]="typeFieldRequired" [formGroup]="form">
      <app-action-type-field
        [control]="form.controls.type"
        [typesOnly]="options.typesOnly"
        [actionOriginEnabled]="options.originEnabled"
        [placeholder]="'Choose ' + label"
        [orange]="true"
        [fill]="true"
        [classes]="['action-type-select']"
        #type_select
      ></app-action-type-field>
    </app-sidebar-field>
  </div>

  <app-customize-bar-action-edit-type-query
    *ngIf="form.controls.type.value == actionTypes.Query"
    [form]="form"
    [setupOnCreate]="setupOnCreate"
    [showDraftChanges]="showDraftChanges"
    [analyticsSource]="analyticsSource"
  ></app-customize-bar-action-edit-type-query>

  <app-customize-bar-action-edit-type-download
    *ngIf="form.controls.type.value == actionTypes.Download"
    [form]="form"
    [context]="context"
    [contextElement]="contextElement"
    [contextElementPath]="contextElementPath"
    [contextElementPaths]="contextElementPaths"
    [setupOnCreate]="setupOnCreate"
    [showDraftChanges]="showDraftChanges"
    [analyticsSource]="analyticsSource"
  ></app-customize-bar-action-edit-type-download>

  <app-customize-bar-action-edit-type-link
    *ngIf="form.controls.type.value == actionTypes.Link"
    [form]="form"
    [context]="context"
    [contextElement]="contextElement"
    [contextElementPath]="contextElementPath"
    [contextElementPaths]="contextElementPaths"
    [analyticsSource]="analyticsSource"
  ></app-customize-bar-action-edit-type-link>

  <app-customize-bar-action-edit-type-external-link
    *ngIf="form.controls.type.value == actionTypes.ExternalLink"
    [form]="form"
    [context]="context"
    [contextElement]="contextElement"
    [contextElementPath]="contextElementPath"
    [contextElementPaths]="contextElementPaths"
    [analyticsSource]="analyticsSource"
  ></app-customize-bar-action-edit-type-external-link>

  <app-customize-bar-action-edit-type-element-action
    *ngIf="form.controls.type.value == actionTypes.ElementAction"
    [form]="form"
    [context]="context"
    [contextElement]="contextElement"
    [contextElementPath]="contextElementPath"
    [contextElementPaths]="contextElementPaths"
    [analyticsSource]="analyticsSource"
  ></app-customize-bar-action-edit-type-element-action>

  <app-customize-bar-action-edit-type-show-notification
    *ngIf="form.controls.type.value == actionTypes.ShowNotification"
    [form]="form"
    [context]="context"
    [contextElement]="contextElement"
    [contextElementPath]="contextElementPath"
    [contextElementPaths]="contextElementPaths"
    [analyticsSource]="analyticsSource"
  ></app-customize-bar-action-edit-type-show-notification>

  <app-customize-bar-action-edit-type-set-property
    *ngIf="form.controls.type.value == actionTypes.SetProperty"
    [form]="form"
    [context]="context"
    [contextElement]="contextElement"
    [contextElementPath]="contextElementPath"
    [contextElementPaths]="contextElementPaths"
    [analyticsSource]="analyticsSource"
  ></app-customize-bar-action-edit-type-set-property>

  <app-customize-bar-action-edit-type-run-javascript
    *ngIf="form.controls.type.value == actionTypes.RunJavaScript"
    [form]="form"
    [context]="context"
    [contextElement]="contextElement"
    [contextElementPath]="contextElementPath"
    [contextElementPaths]="contextElementPaths"
    [analyticsSource]="analyticsSource"
  ></app-customize-bar-action-edit-type-run-javascript>

  <app-customize-bar-action-edit-type-copy-to-clipboard
    *ngIf="form.controls.type.value == actionTypes.CopyToClipboard"
    [form]="form"
    [context]="context"
    [contextElement]="contextElement"
    [contextElementPath]="contextElementPath"
    [contextElementPaths]="contextElementPaths"
    [analyticsSource]="analyticsSource"
  ></app-customize-bar-action-edit-type-copy-to-clipboard>

  <app-customize-bar-action-edit-type-export
    *ngIf="form.controls.type.value == actionTypes.Export"
    [form]="form"
    [context]="context"
    [contextElement]="contextElement"
    [contextElementPath]="contextElementPath"
    [contextElementPaths]="contextElementPaths"
    [analyticsSource]="analyticsSource"
  ></app-customize-bar-action-edit-type-export>

  <app-customize-bar-action-edit-type-import
    *ngIf="form.controls.type.value == actionTypes.Import"
    [form]="form"
    [analyticsSource]="analyticsSource"
  ></app-customize-bar-action-edit-type-import>

  <app-customize-bar-action-edit-type-open-popup
    *ngIf="form.controls.type.value == actionTypes.OpenPopup"
    [form]="form"
    [context]="context"
    [analyticsSource]="analyticsSource"
    (submit)="submit.emit()"
  ></app-customize-bar-action-edit-type-open-popup>

  <app-customize-bar-action-edit-type-close-popup
    *ngIf="form.controls.type.value == actionTypes.ClosePopup"
    [form]="form"
    [context]="context"
    [analyticsSource]="analyticsSource"
  ></app-customize-bar-action-edit-type-close-popup>

  <app-customize-bar-action-edit-type-open-action-menu
    *ngIf="form.controls.type.value == actionTypes.OpenActionMenu"
    [form]="form"
    [context]="context"
    [contextElement]="contextElement"
    [contextElementPath]="contextElementPath"
    [contextElementPaths]="contextElementPaths"
    [firstInit]="firstInit"
    [analyticsSource]="analyticsSource"
  ></app-customize-bar-action-edit-type-open-action-menu>

  <app-customize-bar-action-edit-type-workflow
    *ngIf="form.controls.type.value == actionTypes.Workflow"
    [form]="form"
    [context]="contextPass ? context : undefined"
    [analyticsSource]="analyticsSource"
  ></app-customize-bar-action-edit-type-workflow>
</app-sidebar-section>
