var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, Injector, OnDestroy, OnInit } from '@angular/core';
import groupBy from 'lodash/groupBy';
import isEqual from 'lodash/isEqual';
import range from 'lodash/range';
import * as moment from 'moment';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { NotificationService } from '@common/notifications';
import { ActionControllerService } from '@modules/action-queries';
import { TintStyle } from '@modules/actions';
import { AggregateFunc, DatasetGroupLookup } from '@modules/charts';
import { DataSourceType } from '@modules/data-sources';
import { gteFieldLookup, lteFieldLookup } from '@modules/field-lookups';
import { FilterItem2 } from '@modules/filters';
import { ColumnsModelListStore } from '@modules/list';
import { ModelService } from '@modules/model-queries';
import { DATE_PARAM, TYPE_PARAM } from '@modules/models';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { ChartWidgetQuery } from '@modules/queries';
import { getQueryOptionsToParams, paramsToGetQueryOptions } from '@modules/resources';
import { CalendarType } from '../../data/calendar-type';
import { CalendarBaseComponent } from '../calendar-base/calendar-base.component';
import { getListStateDayMonthFetch } from '../calendar/calendar-state';
var CalendarDayComponent = /** @class */ (function (_super) {
    __extends(CalendarDayComponent, _super);
    function CalendarDayComponent(modelService, currentProjectStore, currentEnvironmentStore, injector, actionControllerService, listStore, notificationService, cd) {
        var _this = _super.call(this, injector, actionControllerService, listStore, notificationService, cd) || this;
        _this.modelService = modelService;
        _this.currentProjectStore = currentProjectStore;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        _this.hours = [];
        _this.weekDays = [];
        _this.weeks = [];
        _this.monthItems = {};
        _this.monthItemsGroupBy = 'DD.MM.YYYY';
        _this.monthItemsLoading = false;
        _this.tintStyles = TintStyle;
        return _this;
    }
    CalendarDayComponent.prototype.onStateUpdated = function (state, prevState) {
        if (!isEqual(getListStateDayMonthFetch(state), getListStateDayMonthFetch(prevState))) {
            this.updateMonth(state);
        }
    };
    CalendarDayComponent.prototype.fetch = function (state) {
        var queryOptions = paramsToGetQueryOptions(state.dataSourceParams);
        var dateFilters = this.dateFilterFilters(state);
        queryOptions.filters = (queryOptions.filters ? queryOptions.filters : []).concat((state.filters ? state.filters : []), (dateFilters ? dateFilters : []));
        queryOptions.search = state.search;
        queryOptions.sort = state.sort;
        this.listStore.dataSource = state.dataSource;
        this.listStore.useDataSourceColumns = true;
        this.listStore.staticData = state.dataSourceStaticData;
        this.listStore.queryOptions = queryOptions;
        this.listStore.context = this.context;
        this.listStore.contextElement = this.contextElement;
        this.getItems();
        this.updateVisibleColumns(state);
        this.updateWeeks(state);
        this.updateHours(state);
        this.prevDate = state.date;
    };
    Object.defineProperty(CalendarDayComponent.prototype, "now", {
        get: function () {
            return moment();
        },
        enumerable: true,
        configurable: true
    });
    CalendarDayComponent.prototype.updateHours = function (state) {
        var _this = this;
        if (!state.date) {
            this.hours = [];
            this.cd.markForCheck();
            return;
        }
        this.hours = range(0, 24).map(function (hour) {
            var date = _this.date.clone().startOf('date').add(hour, 'hours');
            return {
                date: date,
                key: date.format(_this.itemsGroupBy())
            };
        });
        this.cd.markForCheck();
    };
    CalendarDayComponent.prototype.updateMonth = function (state) {
        this.updateWeeks(state);
        this.updateMonthItems(state);
    };
    CalendarDayComponent.prototype.updateWeeks = function (state) {
        var _this = this;
        if (!state.date) {
            this.weekDays = [];
            this.weeks = [];
            this.cd.markForCheck();
            return;
        }
        var month = state.date.clone().startOf('month');
        var firstDay = month.clone().startOf('isoWeek');
        var lastDay = month.clone().endOf('month').endOf('isoWeek');
        var weeks = Math.ceil(lastDay.diff(firstDay, 'weeks', true));
        this.weekDays = range(0, 6 + 1).map(function (day) {
            var date = firstDay.clone().add(day, 'days');
            return {
                date: date,
                weekend: [6, 0].includes(date.day()),
                today: date.isSame(_this.now, 'day') && date.isSame(_this.now, 'month') && date.isSame(_this.now, 'year')
            };
        });
        this.weeks = range(0, weeks).map(function (week) {
            return range(0, 7).map(function (day) {
                var date = firstDay.clone().add(week, 'weeks').add(day, 'days');
                return {
                    date: date,
                    today: date.isSame(_this.now, 'day') && date.isSame(_this.now, 'month') && date.isSame(_this.now, 'year'),
                    current: date.isSame(state.date, 'day') && date.isSame(state.date, 'month') && date.isSame(state.date, 'year'),
                    currentMonth: date.isSame(month, 'month') && date.isSame(month, 'year'),
                    weekend: [6, 0].includes(date.day()),
                    future: date.isAfter(_this.now)
                };
            });
        });
        this.cd.markForCheck();
    };
    CalendarDayComponent.prototype.updateMonthItems = function (state) {
        var _this = this;
        this.monthItems = {};
        this.cd.detectChanges();
        if (!state.date || !state.dataSource || !state.dataSource.query) {
            return;
        }
        var resource = state.settings.dataSource && state.settings.dataSource.type == DataSourceType.Query
            ? this.currentEnvironmentStore.resources.find(function (item) { return item.uniqueName == state.settings.dataSource.queryResource; })
            : undefined;
        var modelQuery = state.dataSource.query;
        var query = new ChartWidgetQuery();
        query.queryType = modelQuery.queryType;
        query.simpleQuery = modelQuery.simpleQuery;
        query.httpQuery = modelQuery.httpQuery;
        query.sqlQuery = modelQuery.sqlQuery;
        var queryOptions = paramsToGetQueryOptions(state.dataSourceParams);
        var firstDay = state.date.clone().startOf('month');
        var lastDay = state.date.clone().endOf('month');
        queryOptions.filters = (queryOptions.filters ? queryOptions.filters : []).concat((state.filters ? state.filters : []), [
            new FilterItem2({
                field: [state.dateField],
                lookup: gteFieldLookup,
                value: firstDay.toISOString()
            }),
            new FilterItem2({
                field: [state.dateField],
                lookup: lteFieldLookup,
                value: lastDay.toISOString()
            })
        ]);
        var params = getQueryOptionsToParams(queryOptions);
        this.monthItemsLoading = true;
        this.cd.markForCheck();
        this.modelService
            .groupQuery(this.currentProjectStore.instance, this.currentEnvironmentStore.instance, resource, query, [{ xColumn: state.settings.dateField, xLookup: DatasetGroupLookup.DateDay }], AggregateFunc.Count, undefined, state.dataSource.queryParameters, this.applyParams(params))
            .pipe(untilDestroyed(this))
            .subscribe(function (items) {
            _this.monthItems = items ? groupBy(items, function (item) { return moment(item['group']).format(_this.monthItemsGroupBy); }) : {};
            _this.monthItemsLoading = false;
            _this.cd.markForCheck();
        }, function () {
            _this.monthItemsLoading = false;
            _this.cd.markForCheck();
        });
    };
    CalendarDayComponent.prototype.dayParams = function (date) {
        var params = {};
        params[TYPE_PARAM] = CalendarType.Day;
        params[DATE_PARAM] = date.toISOString();
        return this.applyParams(params);
    };
    CalendarDayComponent.prototype.dateFilterFilters = function (state) {
        var firstDay = state.date.clone().startOf('date');
        var lastDay = state.date.clone().endOf('date');
        return [
            new FilterItem2({
                field: [state.dateField],
                lookup: gteFieldLookup,
                value: firstDay.toISOString()
            }),
            new FilterItem2({
                field: [state.dateField],
                lookup: lteFieldLookup,
                value: lastDay.toISOString()
            })
        ];
    };
    CalendarDayComponent.prototype.itemsGroupBy = function () {
        return 'HH';
    };
    return CalendarDayComponent;
}(CalendarBaseComponent));
export { CalendarDayComponent };
