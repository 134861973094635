var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import repeat from 'lodash/repeat';
import { isSet } from '@shared';
// TODO: Refactor imports
import { Input } from '../../../../modules/fields/data/input';
import { MarkdownNode } from './markdown-node';
export var defaultConverters = {
    embed: {},
    inline: {
        italic: function () {
            return ['*', '*'];
        },
        bold: function () {
            return ['**', '**'];
        },
        code: function () {
            return ['`', '`'];
        },
        underline: function () {
            return ['<ins>', '</ins>'];
        },
        strike: function () {
            return ['~~', '~~'];
        },
        link: function (value) {
            return ['[', "](" + value + ")"];
        },
        'color-set': function (value) {
            var color = value ? value.colorDefault : 'inherit';
            return ["<span style=\"color: " + color + "\">", "</span>"];
        }
    },
    block: {}
};
export function getQuillConverters(options) {
    return __assign({}, defaultConverters, { embed: __assign({}, defaultConverters.embed, { 'context-formula': function (insert) {
                var input = new Input();
                if (insert) {
                    input.deserialize(insert);
                }
                var value;
                try {
                    value = options.applyContextFormula(input);
                }
                catch (e) {
                    console.error(e);
                }
                var result = isSet(value) ? value : '';
                return new MarkdownNode({ text: result });
            } }), block: __assign({}, defaultConverters.block, { header: function (value) {
                var open = repeat('#', value) + ' ';
                return new MarkdownNode({ open: open, block: true });
            }, list: function (value, blockGroup) {
                if (value == 'bullet') {
                    var open_1 = "- ";
                    return new MarkdownNode({ open: open_1, block: true });
                }
                else if (value == 'ordered') {
                    var number = blockGroup ? blockGroup.nodes.length : 1;
                    var open_2 = number + ". ";
                    return new MarkdownNode({ open: open_2, block: true });
                }
            }, align: function (value) {
                var open = "<p align=\"" + value.replace(/"/g, '\\"') + "\">";
                var close = "</p>";
                return new MarkdownNode({ open: open, close: close, block: true });
            } }) });
}
