import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import isEqual from 'lodash/isEqual';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, Subject } from 'rxjs';
import { delay, first, startWith } from 'rxjs/operators';

import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { CustomSelectItem, CustomSelectItemButton, Option } from '@modules/field-components';
import { FieldType, getFieldDescriptionByType, Input as FieldInput, InputValueType } from '@modules/fields';
import { FieldInputControl, InputFieldProvider, InputFieldProviderItem } from '@modules/parameters';
import { SidebarCollapseContext } from '@modules/sidebar';
import { ascComparator, isSet } from '@shared';

import { InputsEditForm } from './inputs-edit.form';

const defaultSortInputs = (lhs, rhs) => {
  return -1 * ascComparator(lhs.controls.path.value, rhs.controls.path.value);
};

@Component({
  selector: 'app-inputs-edit',
  templateUrl: './inputs-edit.component.html',
  providers: [InputsEditForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputsEditComponent implements OnInit, OnDestroy {
  @Input() control: AbstractControl;
  @Input() parameterProvider: InputFieldProvider;
  @Input() fieldsControl: AbstractControl;
  @Input() addInputEnabled = false;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() contextElementPath: (string | number)[];
  @Input() contextElementPaths: (string | number)[][];
  @Input() staticValueDisabled = false;
  @Input() sortInputs: (lhs: FieldInputControl, rhs: FieldInputControl) => number = defaultSortInputs;
  @Input() requiredEditable = true;
  @Input() userInput = false;
  @Input() collapse = true;
  @Input() collapseContext = new SidebarCollapseContext();
  @Input() listWrapper = true;
  @Input() displayValueTypes = [InputValueType.Formula];
  @Input() classes: string | string[];
  @Input() analyticsSource: string;
  @Output() addInput = new EventEmitter<void>();

  @ViewChild(MatMenuTrigger) addTrigger: MatMenuTrigger;

  lastAddedForm: FieldInputControl;
  displayAllParameters = false;
  addItems: CustomSelectItem<InputFieldProviderItem>[] = [];
  addMenuClosed = new Subject<void>();

  constructor(
    public form: InputsEditForm,
    private analyticsService: UniversalAnalyticsService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.form.init(this.control, this.parameterProvider, this.fieldsControl, this.context, this.contextElement);

    combineLatest(this.parameterProvider.getItems$(), this.form.updatedFromControl.pipe(startWith(undefined)))
      .pipe(delay(0), untilDestroyed(this))
      .subscribe(([value]) => {
        this.updateItems(value);
        this.updateAddItems(value);
      });
  }

  ngOnDestroy(): void {}

  updateItems(providerItems: InputFieldProviderItem[]) {
    const displayAllParameters = providerItems.every(item => !item.children || !item.children.length);
    const parameters = this.parameterProvider.getItemFields(providerItems);
    const items = this.form.form.controls;
    const addItems = [];
    const removeItems = [];

    if (displayAllParameters) {
      // Add inputs for all parameters if they don't exist
      parameters.forEach((parameter, i) => {
        const defaultValueType = providerItems[i].defaultValueType;
        const existingValue = items.find(item => isEqual(item.controls.path.value, [parameter.name]));

        let valueType: InputValueType;

        if (parameter.required) {
          valueType = this.userInput ? InputValueType.Prompt : defaultValueType;
        }

        if (existingValue) {
          if (!existingValue.controls.value_type.value && valueType) {
            existingValue.controls.value_type.patchValue(valueType);
          }

          if (existingValue.controls.required.value != parameter.required && parameter.required) {
            existingValue.controls.required.patchValue(true);
          }

          return;
        }

        addItems.push(
          this.form.createItem({
            path: [parameter.name],
            value_type: valueType,
            required: parameter.required
          })
        );
      });

      // Remove inputs
      items.forEach(item => {
        const existingParameter = parameters.find(parameter => isEqual([parameter.name], item.controls.path.value));

        if (!existingParameter) {
          // Remove inputs for non existing parameters
          removeItems.push(item);
        } else if (!isSet(item.controls.path.value)) {
          // Remove no name inputs
          removeItems.push(item);
        }
      });
    } else {
      // Add inputs for required parameters if they don't exist
      parameters
        .filter(item => item.required)
        .forEach(parameter => {
          const providerItem = providerItems.find(item => item.field && item.field.name == parameter.name);
          const defaultValueType = providerItem ? providerItem.defaultValueType : undefined;
          const existingValue = items.find(item => isEqual(item.controls.path.value, [parameter.name]));

          let valueType: InputValueType;

          if (parameter.required) {
            valueType = this.userInput ? InputValueType.Prompt : defaultValueType;
          }

          if (existingValue) {
            if (!existingValue.controls.value_type.value && valueType) {
              existingValue.controls.value_type.patchValue(valueType);
            }

            if (existingValue.controls.required.value != parameter.required && parameter.required) {
              existingValue.controls.required.patchValue(true);
            }

            return;
          }

          addItems.push(
            this.form.createItem({
              path: [parameter.name],
              value_type: valueType || '',
              required: parameter.required
            })
          );
        });

      // Remove inputs
      items.forEach(item => {
        const existingParameter = parameters.find(parameter => isEqual([parameter.name], item.controls.path.value));

        if (!existingParameter) {
          // Remove inputs for non existing parameters
          removeItems.push(item);
        } else if (!isSet(item.controls.path.value)) {
          // Remove no name inputs
          removeItems.push(item);
        }
      });
    }

    if (addItems.length) {
      const controls = this.sortInputs ? addItems.sort(this.sortInputs) : addItems;
      this.form.arrayAppend(...controls);
    }

    if (removeItems.length) {
      this.form.arrayRemove(...removeItems);
    }

    this.displayAllParameters = displayAllParameters;
    this.cd.markForCheck();
  }

  updateAddItems(providerItems: InputFieldProviderItem[]) {
    const mapItem = (item: InputFieldProviderItem): CustomSelectItem<InputFieldProviderItem> => {
      if (item.children) {
        return {
          button: {
            label: item.label,
            icon: item.icon
          },
          children: item.children
            .map(i => mapItem(i))
            .sort((lhs, rhs) => {
              const lhsSubtitle = lhs.subtitle ? 1 : 0;
              const rhsSubtitle = rhs.subtitle ? 1 : 0;
              return lhsSubtitle - rhsSubtitle;
            })
        };
      } else if (item.field) {
        return {
          option: {
            value: item,
            name: item.label,
            icon: item.icon
          },
          subtitle: item.field.name.startsWith('exclude__') ? 'Exclude' : undefined
        };
      }
    };

    this.addItems = [
      ...this.filterNotUsed(providerItems).map(item => mapItem(item)),
      ...(this.addInputEnabled
        ? [
            {
              button: {
                name: 'add_input',
                label: 'Create Input',
                icon: 'plus'
              },
              stickyBottom: true,
              orange: true,
              large: true
            }
          ]
        : [])
    ];
    this.cd.markForCheck();
  }

  trackByFn(i, item: FieldInputControl) {
    if (item.controls.path.value) {
      return 'field_' + JSON.stringify(item.controls.path.value);
    } else {
      return i;
    }
  }

  addItem(item: InputFieldProviderItem) {
    // Fix trigger focus after menu close prevents inputs-edit-item focus
    this.addMenuClosed.pipe(first(), delay(0), untilDestroyed(this)).subscribe(() => {
      this.lastAddedForm = this.form.addItem(item);
      this.cd.markForCheck();

      this.analyticsService.sendSimpleEvent(AnalyticsEvent.SetParameter.Added, {
        Name: item.field.name,
        Object: this.analyticsSource
      });
    });
  }

  filterNotUsed(items: InputFieldProviderItem[]): InputFieldProviderItem[] {
    return items.filter(item => {
      if (!item.field) {
        return true;
      }

      if (item.field.required) {
        return false;
      }

      const value = this.control.value as FieldInput[];

      if (!value) {
        return true;
      }

      return !value.find(input => isEqual(input.path, [item.field.name]));
    });
  }

  onAddOptionClick(option: Option<InputFieldProviderItem>) {
    this.addItem(option.value);
  }

  onAddButtonClick(button: CustomSelectItemButton) {
    if (button.name == 'add_input') {
      setTimeout(() => {
        this.addInput.emit();
      }, 60);
    }
  }

  getFieldPlaceholder(field: FieldType) {
    const fieldDescription = getFieldDescriptionByType(field);

    if ([FieldType.Select, FieldType.MultipleSelect, FieldType.RelatedModel, FieldType.Boolean].includes(field)) {
      return 'Choose option';
    } else {
      return fieldDescription.label;
    }
  }

  openAddInput() {
    if (this.addTrigger) {
      this.addTrigger.openMenu();
    }
  }
}
