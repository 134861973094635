<a
  *ngIf="!focus && !active && !(search | appIsSet)"
  href="javascript:void(0)"
  class="button button_icon list__actions-item"
  [class.button_theme]="theme"
  [class.button_styles]="theme"
  [appButtonTintColor]="accentColor"
  [appButtonTintColorStyle]="tintStyles.Default"
  [appActionElementStyles]="undefined"
  [appActionElementStylesTintColorStyle]="tintStyles.Default"
  [appTip]="placeholder"
  [appTipOptions]="{ side: 'top' }"
  (click)="toggleActive()"
>
  <span class="button__icon icon-search"></span>
</a>

<div *ngIf="focus || (!focus && (active || (search | appIsSet)))" class="input-icon-wrapper input-icon-wrapper_inline">
  <span class="input-icon input-icon_toolbar input-icon_left icon-search" [class.input-icon_theme]="theme"></span>
  <input
    type="text"
    class="input input_toolbar input_icon-left input_icon_right"
    [class.input_theme]="theme"
    [class.input_styles]="theme"
    [appFieldElementStyles]="undefined"
    [appFieldElementStylesEnabled]="theme"
    [(ngModel)]="search"
    [placeholder]="placeholder"
    (keyup)="onSearchKey($event)"
    (blur)="onSearchBlur(); toggleActive()"
    [appAutofocus]="true"
  />
  <span
    *ngIf="search | appIsSet"
    class="input-icon input-icon_right input-icon_toolbar input-icon_interactive icon-close"
    [class.input-icon_theme]="theme"
    (click)="clear()"
  >
  </span>
</div>
