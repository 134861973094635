var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import cloneDeep from 'lodash/cloneDeep';
import fromPairs from 'lodash/fromPairs';
import { of } from 'rxjs';
import { applyParamInput, applyParamInput$, applyStaticValueFormat, DisplayField, FieldType, getFieldDescriptionByType } from '@modules/fields';
import { ModelDbField, ModelField, ModelFieldType } from '@modules/models';
import { isSet } from '@shared';
export function modelFieldItemToRawListViewSettingsColumn(field, addVisible) {
    if (addVisible === void 0) { addVisible = true; }
    return __assign({ name: field.name, verboseName: field.verboseName, description: field.description, field: field.field, params: cloneDeep(field.params) }, (field instanceof ModelDbField
        ? {
            editable: field.editable,
            sortable: field.sortable,
            filterable: field.filterable
        }
        : {}), (addVisible ? { visible: true } : {}));
}
export function modelFieldToRawListViewSettingsColumn(field, addVisible) {
    if (addVisible === void 0) { addVisible = true; }
    return __assign({}, modelFieldItemToRawListViewSettingsColumn(field.item, addVisible), { name: field.name });
}
export function modelFieldItemToDisplayField(field, addVisible) {
    if (addVisible === void 0) { addVisible = true; }
    if (field instanceof DisplayField) {
        return field;
    }
    else {
        return new DisplayField().deserialize(__assign({ name: field.name, verboseName: field.verboseName, description: field.description, field: field.field, params: cloneDeep(field.params) }, (field instanceof ModelDbField
            ? {
                editable: field.editable,
                filterable: field.filterable,
                sortable: field.sortable
            }
            : {}), (addVisible ? { visible: true } : {})));
    }
}
export function modelFieldToDisplayField(field, addVisible) {
    if (addVisible === void 0) { addVisible = true; }
    var result = modelFieldItemToDisplayField(field.item, addVisible);
    result.name = field.name;
    return result;
}
export function rawListViewSettingsColumnToModelFieldDbItem(item) {
    var field = new ModelDbField();
    field.name = item.name;
    field.verboseName = item.verboseName;
    field.field = item.field;
    field.params = item.params;
    field.updateFieldDescription();
    return field;
}
export function rawListViewSettingsColumnToModelField(item) {
    var field = new ModelField();
    field.name = item.name;
    field.item = rawListViewSettingsColumnToModelFieldDbItem(item);
    field.type = ModelFieldType.Db;
    return field;
}
export function rawListViewSettingsColumnsToViewContextOutputs(items, modelDescription) {
    return items.slice().sort(function (lhs, rhs) {
        var primaryKeyLhs = modelDescription && modelDescription.primaryKeyField == lhs.name ? 1 : 0;
        var primaryKeyRhs = modelDescription && modelDescription.primaryKeyField == rhs.name ? 1 : 0;
        return (primaryKeyLhs - primaryKeyRhs) * -1;
    })
        .map(function (item) {
        var primaryKey = modelDescription && modelDescription.primaryKeyField == item.name;
        var subtitle = primaryKey ? 'primary key' : undefined;
        var icon;
        if (primaryKey) {
            icon = 'key';
        }
        else {
            var fieldDescription = getFieldDescriptionByType(item.field);
            icon = fieldDescription ? fieldDescription.icon : undefined;
        }
        return {
            uniqueName: item.name,
            name: item.verboseName,
            subtitle: subtitle,
            icon: icon,
            iconOrange: primaryKey,
            fieldType: item.field,
            fieldParams: item.params
        };
    });
}
export function rawListViewSettingsColumnsToDisplayField(item) {
    var result = new DisplayField({
        name: item.name,
        verboseName: item.verboseName,
        description: item.description,
        field: item.field,
        params: cloneDeep(item.params),
        visible: item.visible
    });
    result.updateFieldDescription();
    return result;
}
export function displayFieldsToViewContextOutputs(items, modelDescription) {
    return rawListViewSettingsColumnsToViewContextOutputs(items, modelDescription);
}
export function getDefaultRawListViewSettingsColumnVisible(column, options) {
    if (options === void 0) { options = {}; }
    if (column.field == FieldType.JSON && isSet(options.defaultJsonVisibility)) {
        return options.defaultJsonVisibility;
    }
    else if (options.modelDescription && options.modelDescription.primaryKeyField == column.name) {
        return false;
    }
    else {
        return true;
    }
}
export function applyRawListViewSettingsColumnsDefaultVisible(columns, options) {
    if (options === void 0) { options = {}; }
    var visibleCount = 0;
    return columns.map(function (item) {
        var visible;
        if (item.hasOwnProperty('visible')) {
            visible = item.visible;
        }
        else if (isSet(options.maxVisible) && visibleCount >= options.maxVisible) {
            visible = false;
        }
        else {
            visible = getDefaultRawListViewSettingsColumnVisible(item, {
                modelDescription: options.modelDescription,
                defaultJsonVisibility: options.defaultJsonVisibility
            });
        }
        if (visible) {
            ++visibleCount;
        }
        return __assign({}, item, { visible: visible });
    });
}
export function applyDisplayFieldsDefaultVisible(columns, options) {
    if (options === void 0) { options = {}; }
    var visibleCount = 0;
    columns.forEach(function (item) {
        var visible;
        if (isSet(options.maxVisible) && visibleCount >= options.maxVisible) {
            visible = false;
        }
        else {
            visible = getDefaultRawListViewSettingsColumnVisible(item, {
                modelDescription: options.modelDescription,
                defaultJsonVisibility: options.defaultJsonVisibility
            });
        }
        if (visible) {
            ++visibleCount;
        }
        item.visible = visible;
    });
    return columns;
}
export function getModelAttributesByColumns(model, columns) {
    return fromPairs(columns.map(function (item) {
        return [item.name, model.getAttribute(item.name)];
    }));
}
export function getModelBulkAttributesByColumns(models, columns) {
    return fromPairs(columns.map(function (item) {
        return [item.name, models.map(function (model) { return model.getAttribute(item.name); })];
    }));
}
export function flattenContextTokens(items) {
    return items.reduce(function (acc, item) {
        acc.push(item);
        if (item.children) {
            acc.push.apply(acc, flattenContextTokens(item.children));
        }
        return acc;
    }, []);
}
export function applyValueFormatWithContext$(value, valueFormat, options) {
    if (!valueFormat) {
        return of(value);
    }
    if (valueFormat.formatInput) {
        return applyParamInput$(valueFormat.formatInput, {
            context: options.context,
            contextElement: options.contextElement,
            localContext: {
                value: value
            },
            defaultValue: ''
        });
    }
    else {
        return of(applyStaticValueFormat(value, valueFormat));
    }
}
export function applyValueFormatWithContext(value, valueFormat, options) {
    if (options === void 0) { options = {}; }
    if (!valueFormat) {
        return value;
    }
    if (valueFormat.formatInput) {
        try {
            return applyParamInput(valueFormat.formatInput, {
                context: options.context,
                contextElement: options.contextElement,
                localContext: {
                    value: value
                },
                defaultValue: ''
            });
        }
        catch (e) {
            return '';
        }
    }
    else {
        return applyStaticValueFormat(value, valueFormat);
    }
}
