export class Frame {
  x = 0;
  y = 0;
  width = 0;
  height = 0;
  rotation = 0;

  constructor(options: Partial<Frame> = {}) {
    this.patch(options);
  }

  deserialize(data: Object): this {
    this.x = data['x'];
    this.y = data['y'];
    this.width = data['width'];
    this.height = data['height'];
    this.rotation = data['rotation'];

    return this;
  }

  serialize(): Object {
    return {
      x: this.x,
      y: this.y,
      width: this.width,
      height: this.height,
      rotation: this.rotation
    };
  }

  patch(options: Partial<Frame>): this {
    for (const key of Object.keys(options)) {
      if (this.hasOwnProperty(key)) {
        this[key] = options[key];
      }
    }

    return this;
  }

  get top(): number {
    return this.y;
  }

  get right(): number {
    return this.x + this.width;
  }

  get bottom(): number {
    return this.y + this.height;
  }

  get left(): number {
    return this.x;
  }
}
