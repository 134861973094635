import { ConnectedPosition } from '@angular/cdk/overlay';

export const textEditorOverlayPositions = (originWidth: number, originHeight: number): ConnectedPosition[] => {
  const center = {
    x: originWidth * 0.5,
    y: originHeight * 0.5
  };

  return [
    {
      panelClass: ['overlay_position_bottom-center'],
      originX: 'center',
      overlayX: 'center',
      originY: 'bottom',
      overlayY: 'top',
      offsetY: center.y + 10
    },
    {
      panelClass: ['overlay_position_bottom-left'],
      originX: 'start',
      overlayX: 'start',
      originY: 'bottom',
      overlayY: 'top',
      offsetX: -8,
      offsetY: center.y + 10
    },
    {
      panelClass: ['overlay_position_bottom-right'],
      originX: 'end',
      overlayX: 'end',
      originY: 'bottom',
      overlayY: 'top',
      offsetX: 8,
      offsetY: center.y + 10
    },
    {
      panelClass: ['overlay_position_top-center'],
      originX: 'center',
      overlayX: 'center',
      originY: 'top',
      overlayY: 'bottom',
      offsetY: -(center.y + 10)
    },
    {
      panelClass: ['overlay_position_top-left'],
      originX: 'start',
      overlayX: 'start',
      originY: 'top',
      overlayY: 'bottom',
      offsetX: -8,
      offsetY: -(center.y + 10)
    },
    {
      panelClass: ['overlay_position_top-right'],
      originX: 'end',
      overlayX: 'end',
      originY: 'top',
      overlayY: 'bottom',
      offsetX: 8,
      offsetY: -(center.y + 10)
    },
    {
      panelClass: ['overlay_position_left-center'],
      originX: 'start',
      overlayX: 'end',
      originY: 'center',
      overlayY: 'center',
      offsetX: -(center.x + 10)
    },
    {
      panelClass: ['overlay_position_right-center'],
      originX: 'end',
      overlayX: 'start',
      originY: 'center',
      overlayY: 'center',
      offsetX: center.x + 10
    }
  ];
};
