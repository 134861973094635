import { CdkOverlayOrigin, ConnectedPosition } from '@angular/cdk/overlay';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { Option } from '@modules/field-components';

@Component({
  selector: 'app-compact-select-options',
  templateUrl: './compact-select-options.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompactSelectOptionsComponent implements OnInit, OnDestroy {
  @Input() options: Option[] = [];
  @Input() currentOption: Option;
  @Input() origin: CdkOverlayOrigin;
  @Output() selectOption = new EventEmitter<Option>();

  dropdownOpened = false;
  dropdownPositions: ConnectedPosition[] = [
    { originX: 'start', overlayX: 'start', originY: 'bottom', overlayY: 'top', offsetX: 0, offsetY: 4 },
    { originX: 'center', overlayX: 'center', originY: 'bottom', overlayY: 'top', offsetX: 0, offsetY: 4 },
    { originX: 'end', overlayX: 'end', originY: 'bottom', overlayY: 'top', offsetX: 0, offsetY: 4 },
    { originX: 'start', overlayX: 'start', originY: 'top', overlayY: 'bottom', offsetX: 0, offsetY: -4 },
    { originX: 'center', overlayX: 'center', originY: 'top', overlayY: 'bottom', offsetX: 0, offsetY: -4 },
    { originX: 'end', overlayX: 'end', originY: 'top', overlayY: 'bottom', offsetX: 0, offsetY: -4 },
    { originX: 'end', overlayX: 'start', originY: 'center', overlayY: 'center', offsetX: 4, offsetY: 0 },
    { originX: 'start', overlayX: 'end', originY: 'center', overlayY: 'center', offsetX: -4, offsetY: 0 }
  ];

  constructor(public sanitizer: DomSanitizer, private cd: ChangeDetectorRef) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  setDropdownOpened(value: boolean) {
    this.dropdownOpened = value;
    this.cd.markForCheck();
  }

  toggleDropdownOpened() {
    this.setDropdownOpened(!this.dropdownOpened);
  }
}
