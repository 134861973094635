import { Option } from '@modules/field-components';

export const weightOptions: Option<number>[] = [
  {
    value: 100,
    name: 'Thin'
  },
  {
    value: 200,
    name: 'Extra Light'
  },
  {
    value: 300,
    name: 'Light'
  },
  {
    value: 400,
    name: 'Normal'
  },
  {
    value: 500,
    name: 'Medium'
  },
  {
    value: 600,
    name: 'Semi Bold'
  },
  {
    value: 700,
    name: 'Bold'
  },
  {
    value: 800,
    name: 'Extra Bold'
  },
  {
    value: 900,
    name: 'Black'
  }
];
