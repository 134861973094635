<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayPanelClass]="['overlay', 'overlay_horizontal_center']"
  [cdkConnectedOverlayPositions]="overlayPositions"
  [cdkConnectedOverlayOrigin]="origin"
  [cdkConnectedOverlayOpen]="opened"
  [cdkConnectedOverlayPush]="true"
  [cdkConnectedOverlayGrowAfterOpen]="true"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayBackdropClass]="'popover2-backdrop'"
  (backdropClick)="close.emit()"
  (detach)="close.emit()"
>
  <div class="popover2 overlay-content overlay-content_animated">
    <div class="popover2__inner">
      <div class="popover2__viewport">
        <div class="popover2__content" style="max-width: 300px;">
          <div *ngFor="let group of layoutGroups" class="token-popup__item">
            <div class="token-popup-section">
              <div class="token-popup-section__title">
                <div class="token-popup-section__title-main">{{ group.label }}</div>
              </div>
              <div class="token-popup-section__content">
                <div class="token-popup__item">
                  <a
                    *ngFor="let layout of group.options; let first = first"
                    href="javascript:void(0)"
                    class="token-popup-list-item token-popup-list-item_hoverable token-popup-list-item_clickable"
                    [class.token-popup-list-item_active]="layoutSelected === layout"
                    [style.margin-top.px]="first ? 0 : 5"
                    (click)="selectLayoutOption.emit(layout); close.emit()"
                  >
                    <div class="token-popup-list-item__inner">
                      <div class="token-popup-list-item__line">
                        <div class="token-popup-list-item__left token-popup-list-item__left_margin_l">
                          <div
                            class="token-popup-list-item__image token-popup-list-item__image_border token-popup-list-item__image_size_xl"
                            [style.background-image]="
                              '/assets/images/project-layouts/' + layout.image + '.svg' | appDeployCssUrl
                            "
                          ></div>
                        </div>

                        <div class="token-popup-list-item__main token-popup-list-item__main_wrap">
                          <div class="token-popup-list-item__label">{{ layout.title }}</div>
                          <div class="token-popup-list-item__description">{{ layout.subtitle }}</div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
