import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, merge, Observable, of, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { ActionStore } from '@modules/action-queries';
import { RawListViewSettingsColumn, ViewContext, ViewContextElement } from '@modules/customize';
import { DataSourceType } from '@modules/data-sources';
import { applyParamInput$, Input as FieldInput } from '@modules/fields';
import { ModelDescriptionStore } from '@modules/model-queries';
import { Resource } from '@modules/projects';
import { ActionQuery, ModelDescriptionQuery, Query, QueryType, ValueWidgetQuery } from '@modules/queries';
import { Workflow } from '@modules/workflow';
import { controlValue, isSet } from '@shared';

import { ModelDescriptionDataSourceControl } from '../model-description-data-source-edit/model-description-data-source';

interface DataSourceValue {
  icon?: string;
  image?: string;
  title1?: string;
  title2?: string;
  tag?: string;
}

@Component({
  selector: 'app-model-description-data-source-button',
  templateUrl: './model-description-data-source-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModelDescriptionDataSourceButtonComponent implements OnInit, OnDestroy, OnChanges {
  @Input() title: string;
  @Input() additional: string;
  @Input() required = false;
  @Input() placeholder = 'Connect Data Source';
  @Input() placeholderIcon = 'components';
  @Input() description: string;
  @Input() control: ModelDescriptionDataSourceControl;
  @Input() sortingFieldControl: FormControl;
  @Input() sortingAscControl: FormControl;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() error: string;
  @Input() remove = false;
  @Input() classes: string | string[];
  @Output() editClick = new EventEmitter<MouseEvent>();
  @Output() addInputClick = new EventEmitter<MouseEvent>();
  @Output() editSortingClick = new EventEmitter<MouseEvent>();
  @Output() removeClick = new EventEmitter<void>();

  observersSubscription: Subscription;
  dataSourceValue: DataSourceValue;
  dataSourceValueSubscription: Subscription;
  sortingFieldLabel: string;
  sortingFieldLabelSubscription: Subscription;

  constructor(
    private modelDescriptionStore: ModelDescriptionStore,
    private actionStore: ActionStore,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['control']) {
      this.updateObservers();
    }

    if (changes['control'] || changes['context'] || changes['contextElement']) {
      this.updateDataSourceValue();
    }

    if (changes['control'] || changes['sortingFieldControl']) {
      this.updateSortingFieldLabel();
    }
  }

  updateObservers() {
    if (this.observersSubscription) {
      this.observersSubscription.unsubscribe();
      this.observersSubscription = undefined;
    }

    if (!this.control) {
      return;
    }

    const observeControls: AbstractControl[] = [this.control.controls.query_inputs];

    if (this.sortingFieldControl && this.sortingAscControl) {
      observeControls.push(this.sortingFieldControl, this.sortingAscControl);
    }

    this.observersSubscription = merge(...observeControls.map(item => item.valueChanges))
      .pipe(untilDestroyed(this))
      .subscribe(() => this.cd.markForCheck());
  }

  getDataSourceValue$(): Observable<DataSourceValue> {
    if (!this.control) {
      return of(undefined);
    }

    return combineLatest(
      controlValue<DataSourceType>(this.control.controls.type),
      this.control.getResource$(),
      controlValue<Query>(this.control.controls.query),
      this.control.getInput$(),
      controlValue<Workflow>(this.control.controls.workflow)
    ).pipe(
      switchMap(([type, resource, query, input, workflow]) => {
        if (type == DataSourceType.Query && resource) {
          return this.getDataSourceQueryValue$(resource, query).pipe(
            map(value => {
              return {
                image: resource ? resource.icon : undefined,
                title1: value,
                title2: resource ? resource.name : undefined,
                tag: resource && resource.demo ? 'DEMO' : undefined
              };
            })
          );
        } else if (type == DataSourceType.Input) {
          return this.getDataSourceInputValue$(input).pipe(
            map(value => {
              return {
                icon: 'link',
                title1: 'Specified data',
                title2: value
              };
            })
          );
        } else if (type == DataSourceType.Workflow) {
          const steps = workflow ? workflow.getStepsCount() : 0;
          return of({
            icon: 'workflow',
            title1: 'Load using Workflow',
            title2: steps == 1 ? `${steps} step` : `${steps} steps`
          });
        } else {
          return of(undefined);
        }
      })
    );
  }

  getDataSourceQueryValue$(resource: Resource, query: Query): Observable<string> {
    if (!query) {
      return of(undefined);
    }

    if (query.queryType == QueryType.Simple) {
      const resourceName = resource ? resource.uniqueName : undefined;

      if (query instanceof ModelDescriptionQuery || query instanceof ValueWidgetQuery) {
        const simpleQuery = query.simpleQuery;
        const model = simpleQuery ? simpleQuery.model : undefined;
        const modelId = [resourceName, model].join('.');

        return this.modelDescriptionStore.getDetailFirst(modelId).pipe(
          map(modelDescription => {
            return modelDescription ? modelDescription.verboseNamePlural || modelDescription.model : undefined;
          })
        );
      } else if (query instanceof ActionQuery) {
        const simpleQuery = query.simpleQuery;
        const action = simpleQuery ? simpleQuery.name : undefined;
        const actionId = [resourceName, action].join('.');

        return this.actionStore.getDetailFirst(actionId).pipe(
          map(actionDescription => {
            return actionDescription ? actionDescription.verboseName || actionDescription.name : undefined;
          })
        );
      } else {
        return of(undefined);
      }
    } else if (query.queryType == QueryType.Http) {
      return of('HTTP query');
    } else if (query.queryType == QueryType.SQL) {
      return of('SQL query');
    } else if (query.queryType == QueryType.Object) {
      return of('Database query');
    } else {
      return of(undefined);
    }
  }

  getDataSourceInputValue$(input: FieldInput): Observable<string> {
    if (!input || !input.isSet()) {
      return of(undefined);
    }

    return applyParamInput$(input, {
      context: this.context,
      contextElement: this.contextElement,
      defaultValue: ''
    }).pipe(
      map(result => {
        if (isArray(result)) {
          return 'Array';
        } else if (isObject(result)) {
          return 'Object';
        } else if (isSet(result)) {
          return String(result);
        } else {
          return input.valueTypeStr();
        }
      })
    );
  }

  updateDataSourceValue() {
    if (this.dataSourceValueSubscription) {
      this.dataSourceValueSubscription.unsubscribe();
      this.dataSourceValueSubscription = undefined;
    }

    this.dataSourceValueSubscription = this.getDataSourceValue$()
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        this.dataSourceValue = result;
        this.cd.markForCheck();
      });
  }

  getSortingFieldLabel$(): Observable<string> {
    if (!this.control || !this.sortingFieldControl) {
      return of(undefined);
    }

    return combineLatest(
      controlValue<RawListViewSettingsColumn[]>(this.control.controls.columns),
      controlValue<string>(this.sortingFieldControl)
    ).pipe(
      map(([columns, sortingField]) => {
        const column = columns.find(item => item.name == sortingField);
        return column && isSet(column.verboseName) ? column.verboseName : sortingField;
      })
    );
  }

  updateSortingFieldLabel() {
    if (this.sortingFieldLabelSubscription) {
      this.sortingFieldLabelSubscription.unsubscribe();
      this.sortingFieldLabelSubscription = undefined;
    }

    this.sortingFieldLabelSubscription = this.getSortingFieldLabel$()
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        this.sortingFieldLabel = result;
        this.cd.markForCheck();
      });
  }

  onInputsClick(e: MouseEvent) {
    const inputs = this.control.controls.query_inputs.value;

    if (inputs && !inputs.length) {
      this.addInputClick.emit(e);
    } else {
      this.editClick.emit(e);
    }
  }
}
