import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import keys from 'lodash/keys';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { PopupService } from '@common/popups';
import { TableSettings, ViewContext, ViewContextElement } from '@modules/customize';
import { Model } from '@modules/models';
import { CurrentProjectStore } from '@modules/projects';

@Component({
  selector: 'app-table-actions',
  templateUrl: './table-actions.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableActionsComponent implements OnInit, OnDestroy, OnChanges {
  @Input() listTitle: string;
  @Input() settings: TableSettings;
  @Input() selectedItems: { [k: string]: Model } = {};
  @Input() selectedInverse = false;
  @Input() displayItems = 0;
  @Input() totalItems = 0;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() accentColor: string;
  @Input() theme = false;
  @Output() selectAllRequested = new EventEmitter<void>();
  @Output() deselectRequested = new EventEmitter<void>();
  @Output() reordered = new EventEmitter<void>();
  @ViewChild('root') root: ElementRef;

  selectedItemsLength: number;
  params: Object;
  duplicateQueryParams: Object;
  massChangeQueryParams: Object;
  actionQueryParams: Object;

  constructor(
    public currentProjectStore: CurrentProjectStore,
    // private actionStore: ActionStore,
    private injector: Injector,
    private activatedRoute: ActivatedRoute,
    private popupService: PopupService,
    private cd: ChangeDetectorRef
  ) {}

  createModel(): Model {
    return Injector.create({
      providers: [{ provide: Model, deps: [Injector] }],
      parent: this.injector
    }).get<Model>(Model);
  }

  ngOnInit() {
    this.activatedRoute.queryParams
      .pipe(untilDestroyed(this))
      .subscribe(queryParams => this.updateQueryParams(queryParams));
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.updateQueryParams(this.activatedRoute.snapshot.queryParams);

    if (changes['selectedItems']) {
      this.selectedItemsLength = this.selectedItemKeys.length;
    }
  }

  get selectedMultiple() {
    return (
      (!this.selectedInverse && this.selectedItemsLength > 1) ||
      (this.selectedInverse && this.selectedItemsLength <= this.totalItems - 1)
    );
  }

  get ids() {
    return this.selectedItemKeys.map(item => item).join(',');
  }

  get selectedItemKeys() {
    return keys(this.selectedItems);
  }

  get selectedCountOfTotal() {
    if (this.selectedInverse) {
      return this.totalItems ? this.totalItems - this.selectedItemsLength : undefined;
    } else {
      return this.selectedItemsLength;
    }
  }

  get selectedAllOfTotal() {
    return this.totalItems && this.totalItems == this.selectedCountOfTotal;
  }

  get selectedAll() {
    return this.displayItems && this.displayItems == this.selectedItemsLength;
  }

  updateQueryParams(queryParams: Object) {
    this.params = queryParams;
    this.duplicateQueryParams = this.getDuplicateQueryParams();
    this.massChangeQueryParams = this.getMassChangeQueryParams();
    this.actionQueryParams = this.getActionQueryParams();
    this.cd.markForCheck();
  }

  getDuplicateQueryParams() {
    if (this.selectedInverse || this.selectedItemsLength != 1) {
      return;
    }

    return { ...this.params, _duplicate: this.selectedItemKeys[0] };
  }

  getMassChangeQueryParams() {
    return {
      ...this.params,
      ids: this.ids,
      inverseIds: this.selectedInverse ? this.selectedInverse : undefined
    };
  }

  getActionQueryParams() {
    return { ...this.params, ids: this.ids };
  }

  move() {
    // this.popupService.push({
    //   component: MoveComponent,
    //   popupComponent: ThinDialogPopupComponent,
    //   inputs: {
    //     modelDescription: this.modelDescription,
    //     queryParams: this.queryParams,
    //     ids: this.selectedItemKeys,
    //     inverseIds: this.selectedInverse ? this.selectedInverse : undefined
    //   },
    //   outputs: {
    //     reordered: [() => this.reordered.next()]
    //   },
    //   injector: this.injector
    // });
  }

  onSelectAllClick() {
    if (this.selectedAll) {
      this.deselectRequested.emit();
    } else {
      this.selectAllRequested.emit();
    }
  }
}
