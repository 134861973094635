import { Injector } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MarginControl, ValueWidgetStyles } from '@modules/customize';
import { controlValue } from '@shared';

import { TextStyleControl } from '../text-style-edit/text-style.control';

export class ValueWidgetStylesControl extends FormGroup {
  controls: {
    title_style: TextStyleControl;
    value_style: TextStyleControl;
    margin: MarginControl;
  };

  constructor(private injector: Injector) {
    super({
      title_style: TextStyleControl.inject(injector, {
        global: 'valueWidgetTitleTextStyle',
        colorAlphaEnabled: true
      }),
      value_style: TextStyleControl.inject(injector, {
        global: 'valueWidgetValueTextStyle',
        colorAlphaEnabled: true
      }),
      margin: new MarginControl()
    });
  }

  deserialize(instance?: ValueWidgetStyles) {
    if (!instance) {
      return;
    }

    this.controls.title_style.deserialize(instance.titleStyle);
    this.controls.value_style.deserialize(instance.valueStyle);

    this.controls.margin.deserialize(instance.margin);
  }

  isSet(): boolean {
    return [this.controls.title_style, this.controls.value_style, this.controls.margin].some(control =>
      control.isSet()
    );
  }

  stylesDefaultUpdated$(): Observable<void> {
    return combineLatest([
      this.controls.title_style.getStyleDefault$(),
      this.controls.value_style.getStyleDefault$()
    ]).pipe(map(() => undefined));
  }

  serialize(): ValueWidgetStyles {
    if (!this.isSet()) {
      return;
    }

    return new ValueWidgetStyles({
      titleStyle: this.controls.title_style.serialize(false),
      valueStyle: this.controls.value_style.serialize(false),
      margin: this.controls.margin.serialize()
    });
  }

  serialize$(): Observable<ValueWidgetStyles> {
    return controlValue(this, { debounce: 200 }).pipe(map(() => this.serialize()));
  }
}
