<ng-container *ngIf="!loadingValue && state.formField">
  <app-auto-field
    *ngIf="state.formField.field == fieldTypes.Boolean"
    [form]="form"
    [field]="
      createField({
        name: state.formField.name,
        label: state.formField.label,
        field: fieldTypes.Select,
        placeholder: state.formField.placeholder,
        params: {
          valueEquals: booleanValueEquals,
          options: [
            { value: null, name: 'Not specified' },
            { value: false, name: 'No' },
            { value: true, name: 'Yes' }
          ],
          classes: ['select_fill'],
          theme: true
        },
        reset_enabled: true
      })
    "
    [labelStyleGlobal]="'fieldLabelTextStyle'"
    [labelAdditional]="labelAdditional"
    [labelAdditionalStyleGlobal]="'fieldLabelAdditionalTextStyle'"
    [elementStyles]="elementStyles"
    [tooltip]="tooltip"
    [accentColor]="accentColor"
  ></app-auto-field>

  <app-auto-field
    *ngIf="state.formField.field != fieldTypes.Boolean"
    [form]="form"
    [field]="state.formField"
    [labelStyleGlobal]="'fieldLabelTextStyle'"
    [labelAdditional]="labelAdditional"
    [labelAdditionalStyleGlobal]="'fieldLabelAdditionalTextStyle'"
    [elementStyles]="elementStyles"
    [readonly]="false"
    [manualMargin]="true"
    [context]="context"
    [contextElement]="viewContextElement"
    [tooltip]="tooltip"
    [accentColor]="accentColor"
  >
  </app-auto-field>
</ng-container>

<div *ngIf="loadingValue" class="field">
  <label class="field__label">
    <span *ngIf="state.formField?.label" class="stub-text">
      {{ state.formField?.label }}
    </span>

    <ng-container *ngIf="!state.formField?.label">
      {{ state.formField?.label }}
    </ng-container>
  </label>
  <app-auto-field-stub
    [field]="state.formField"
    [label]="false"
    [readonly]="false"
    [manualMargin]="true"
  ></app-auto-field-stub>
</div>
