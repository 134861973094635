var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Injector } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProjectSettingsStore } from '@modules/all-project-settings';
import { FontStyle, fontTypeOptions, getFontFamilyVariable } from '@modules/styles';
import { fonts, headingFontName, regularFontName } from '@modules/theme';
import { Font } from '@modules/views';
import { controlValue, isSet } from '@shared';
var FontControl = /** @class */ (function (_super) {
    __extends(FontControl, _super);
    function FontControl(projectSettingsStore, state) {
        if (state === void 0) { state = {}; }
        var _this = _super.call(this, {
            family: new FormControl(isSet(state.family) ? state.family : regularFontName),
            type: new FormControl(__assign({ weight: 400, style: FontStyle.Normal }, (isSet(state.weight) && { weight: state.weight }), (isSet(state.style) && { style: state.style }))),
            size: new FormControl(isSet(state.size) ? state.size : 14)
        }) || this;
        _this.projectSettingsStore = projectSettingsStore;
        _this.fontTypeEquals = function (lhs, rhs) {
            if (lhs && rhs) {
                return lhs.weight == rhs.weight && lhs.style == rhs.style;
            }
            else {
                return lhs == rhs;
            }
        };
        return _this;
    }
    FontControl.inject = function (injector, state) {
        if (state === void 0) { state = {}; }
        return Injector.create({
            providers: [
                {
                    provide: FontControl,
                    useFactory: function (projectSettingsStore) {
                        return new FontControl(projectSettingsStore, state);
                    },
                    deps: [ProjectSettingsStore]
                }
            ],
            parent: injector
        }).get(FontControl);
    };
    FontControl.prototype.deserialize = function (value, options) {
        if (options === void 0) { options = {}; }
        this.instance = value;
        if (isSet(value.family)) {
            this.controls.family.patchValue(value.family, { emitEvent: options.emitEvent });
        }
        if (isSet(value.weight)) {
            this.controls.type.patchValue({ weight: value.weight, style: value.style }, { emitEvent: options.emitEvent });
        }
        this.controls.size.patchValue(value.size, { emitEvent: options.emitEvent });
    };
    FontControl.prototype.getFontTypeOptionsForValue = function (fontFamily, projectSettings) {
        var fontFamilyVariable = getFontFamilyVariable(fontFamily);
        if (fontFamilyVariable == regularFontName) {
            fontFamily = projectSettings.fontRegular;
        }
        else if (fontFamilyVariable == headingFontName) {
            fontFamily = projectSettings.fontHeading;
        }
        var font = fonts.find(function (item) { return item.name == fontFamily; });
        return fontTypeOptions.filter(function (item) { return !font || font.weights.includes(item.value.weight); });
    };
    FontControl.prototype.getFontTypeOptions$ = function () {
        var _this = this;
        return combineLatest(controlValue(this.controls.family), this.projectSettingsStore.getAllSettings$()).pipe(map(function (_a) {
            var fontFamily = _a[0], projectSettings = _a[1];
            return _this.getFontTypeOptionsForValue(fontFamily, projectSettings);
        }));
    };
    FontControl.prototype.getInstance = function (instance) {
        if (!instance) {
            instance = new Font();
        }
        instance.family = this.controls.family.value;
        if (this.controls.type.value) {
            instance.weight = this.controls.type.value.weight;
            instance.style = this.controls.type.value.style;
        }
        instance.size = this.controls.size.value;
        return instance;
    };
    FontControl.prototype.serialize = function () {
        return this.getInstance(this.instance);
    };
    return FontControl;
}(FormGroup));
export { FontControl };
