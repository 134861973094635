import { ChangeDetectorRef, EventEmitter, Injector, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { NotificationService } from '@common/notifications';
import { ActionControllerService, ActionService } from '@modules/action-queries';
import { TintStyle, ViewSettingsAction } from '@modules/actions';
import { CustomizeService, ViewContext, ViewContextElement } from '@modules/customize';
import { applyBooleanInput$, applyParamInput$, getInputsValid$ } from '@modules/fields';
import { isSet } from '@shared';
var ListActionsItemComponent = /** @class */ (function () {
    function ListActionsItemComponent(customizeService, actionService, actionControllerService, notificationService, injector, cd) {
        this.customizeService = customizeService;
        this.actionService = actionService;
        this.actionControllerService = actionControllerService;
        this.notificationService = notificationService;
        this.injector = injector;
        this.cd = cd;
        this.pinned = false;
        this.compact = false;
        this.theme = false;
        this.selected = new EventEmitter();
        this.indicatorWrapper = true;
        this.submitLoading = false;
        this.disabled = false;
        this.isVisible = true;
        this.style = TintStyle.Primary;
        this.tintStyles = TintStyle;
    }
    Object.defineProperty(ListActionsItemComponent.prototype, "indicatorWrapperBlock", {
        get: function () {
            return !this.pinned;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListActionsItemComponent.prototype, "hidden", {
        get: function () {
            return !this.isVisible && !this.customizeService.enabled;
        },
        enumerable: true,
        configurable: true
    });
    ListActionsItemComponent.prototype.ngOnInit = function () { };
    ListActionsItemComponent.prototype.ngOnDestroy = function () { };
    ListActionsItemComponent.prototype.ngOnChanges = function (changes) {
        this.initVerboseName();
        this.initStyle();
        this.initDisabled();
        this.updateVisible();
    };
    ListActionsItemComponent.prototype.initVerboseName = function () {
        var _this = this;
        if (this.verboseNameSubscription) {
            this.verboseNameSubscription.unsubscribe();
            this.verboseNameSubscription = undefined;
        }
        if (!this.item || !this.item.verboseNameInput) {
            this.verboseName = undefined;
            this.cd.markForCheck();
            return;
        }
        this.verboseNameSubscription = applyParamInput$(this.item.verboseNameInput, {
            context: this.context,
            contextElement: this.contextElement,
            defaultValue: ''
        })
            .pipe(untilDestroyed(this))
            .subscribe(function (value) {
            _this.verboseName = value;
            _this.cd.markForCheck();
        });
    };
    ListActionsItemComponent.prototype.initStyle = function () {
        this.style = this.item.style ? this.item.style : TintStyle.Primary;
        this.cd.markForCheck();
    };
    ListActionsItemComponent.prototype.getInputsValid$ = function () {
        var _this = this;
        return this.actionService.getActionDescription(this.item).pipe(switchMap(function (actionDescription) {
            if (!actionDescription) {
                return of(true);
            }
            return getInputsValid$(_this.item.inputs, {
                context: _this.context,
                parameters: actionDescription.actionParams
            });
        }));
    };
    ListActionsItemComponent.prototype.getElementDisabled$ = function () {
        return this.item.disabledInput && this.item.disabledInput.isSet()
            ? applyBooleanInput$(this.item.disabledInput, {
                context: this.context,
                contextElement: this.contextElement
            })
            : of(false);
    };
    ListActionsItemComponent.prototype.initDisabled = function () {
        var _this = this;
        if (this.disabledSubscription) {
            this.disabledSubscription.unsubscribe();
            this.disabledSubscription = undefined;
        }
        if (!this.item) {
            this.disabled = false;
            this.cd.markForCheck();
            return;
        }
        combineLatest(this.getInputsValid$(), this.getElementDisabled$())
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var inputsValid = _a[0], inputDisabled = _a[1];
            _this.disabled = inputDisabled || !inputsValid;
            _this.cd.markForCheck();
        });
    };
    ListActionsItemComponent.prototype.updateVisible = function () {
        var _this = this;
        if (this.visibleSubscription) {
            this.visibleSubscription.unsubscribe();
            this.visibleSubscription = undefined;
        }
        if (!this.item.visibleInput) {
            this.isVisible = true;
            this.cd.markForCheck();
            return;
        }
        this.visibleSubscription = applyBooleanInput$(this.item.visibleInput, {
            context: this.context,
            contextElement: this.contextElement
        })
            .pipe(untilDestroyed(this))
            .subscribe(function (visible) {
            _this.isVisible = visible;
            _this.cd.markForCheck();
        });
    };
    ListActionsItemComponent.prototype.actionIcon = function (item) {
        if (item.icon) {
            return item.icon;
        }
        else {
            return 'power';
        }
    };
    ListActionsItemComponent.prototype.executeAction = function (item, element) {
        var _this = this;
        if (this.disabled || this.submitLoading) {
            return;
        }
        this.selected.emit();
        this.submitLoading = true;
        this.cd.markForCheck();
        this.actionControllerService
            .execute(item, {
            context: this.context,
            contextElement: this.contextElement,
            injector: this.injector,
            origin: element
        })
            .subscribe(function () {
            _this.submitLoading = false;
            _this.cd.markForCheck();
        }, function () {
            _this.submitLoading = false;
            _this.cd.markForCheck();
        });
    };
    ListActionsItemComponent.prototype.hasIconElement = function () {
        return this.submitLoading || this.actionIcon(this.item);
    };
    ListActionsItemComponent.prototype.displayTitle = function () {
        if (!isSet(this.verboseName)) {
            return false;
        }
        if (this.compact) {
            return !this.hasIconElement();
        }
        else {
            return true;
        }
    };
    return ListActionsItemComponent;
}());
export { ListActionsItemComponent };
