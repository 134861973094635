var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { AfterViewInit, ChangeDetectorRef, Injector, OnDestroy, OnInit } from '@angular/core';
import { ActivationEnd, Router } from '@angular/router';
import * as Sentry from '@sentry/browser';
import fromPairs from 'lodash/fromPairs';
import uniq from 'lodash/uniq';
import values from 'lodash/values';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, fromEvent, merge, Observable, of } from 'rxjs';
import { catchError, delayWhen, filter, map, publishLast, refCount, skip, switchMap, tap, timeout } from 'rxjs/operators';
import { ScreenRecordService } from '@common/cheat-code';
import { NotificationService } from '@common/notifications';
import { PopupService } from '@common/popups';
import { DocumentService, SessionStorage } from '@core';
import { ActionStore } from '@modules/action-queries';
import { AdminMode } from '@modules/admin-mode';
import { AllProjectSettings, ProjectSettingsStore } from '@modules/all-project-settings';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ApiService } from '@modules/api';
import { TaskQueueStore } from '@modules/collaboration';
import { CustomViewsStore } from '@modules/custom-views';
import { AdminComponentService, Customizable, CustomizeSelection, CustomizeService, CustomizeType, ViewSettingsStore } from '@modules/customize';
import { DashboardStore } from '@modules/dashboard';
import { DemoBannerService } from '@modules/demo';
import { MenuSection, MenuService, MenuSettingsStore } from '@modules/menu';
import { MetaService } from '@modules/meta';
import { MigrationCheckService } from '@modules/migrations';
import { ModelDescriptionStore } from '@modules/model-queries';
import { ProjectApiService } from '@modules/project-api';
import { CurrentEnvironmentStore, CurrentProjectStore, Environment, Project, ProjectGroupStore, ProjectPropertyStorage, ProjectPropertyStore, ProjectPropertyType, ProjectService, ProjectsStore, Resource, ResourceType } from '@modules/projects';
import { CompanyInfoService } from '@modules/projects-components';
import { PublicApiService } from '@modules/public-api';
import { AirtableResourceController, AmazonS3ResourceController, AzureBlobResourceController, FirebaseResourceController, GraphQLResourceControllerService, JetAppResourceController, JetBridgeResourceController, MessagesAPIResourceController, ResourceControllerService, RestApiResourceControllerService, SmartSuiteResourceController, SupabaseStorageResourceController, XanoResourceController } from '@modules/resources';
import { RoutingService } from '@modules/routing';
import { ThemeService } from '@modules/theme';
import { GuideSectionStore, OnboardingService } from '@modules/tutorial';
import { CurrentUserStore } from '@modules/users';
import { VersionService } from '@modules/versions';
import { isSet, KeyboardEventKeyCode, removeClass, toggleClass, trackKeyboardCode } from '@shared';
import { AdminGlobalsService } from './services/admin-globals/admin-globals.service';
export var BODY_CONTENTFUL_RIGHT_BAR_CLASS = 'body_contentful-right-bar';
var AdminComponent = /** @class */ (function () {
    function AdminComponent(projectUniqueName$, environmentUniqueName$, mode$, mode, adminComponentService, currentProjectStore, currentEnvironmentStore, projectsStore, routing, router, cd, injector, customizeService, menuService, demoBannerService, modelDescriptionStore, viewSettingsStore, menuSettingsStore, projectSettingsStore, actionStore, customViewsStore, dashboardStore, guideSectionStore, projectGroupStore, taskQueueStore, projectPropertyStore, onboardingService, companyInfoService, metaService, sessionStorage, notificationService, currentUserStore, resourceControllerService, projectService, themeService, migrationCheckService, analyticsService, projectApiService, apiService, versionService, publicApiService, screenRecordService, popupService, documentService, globalsService) {
        this.projectUniqueName$ = projectUniqueName$;
        this.environmentUniqueName$ = environmentUniqueName$;
        this.mode$ = mode$;
        this.mode = mode;
        this.adminComponentService = adminComponentService;
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.projectsStore = projectsStore;
        this.routing = routing;
        this.router = router;
        this.cd = cd;
        this.injector = injector;
        this.customizeService = customizeService;
        this.menuService = menuService;
        this.demoBannerService = demoBannerService;
        this.modelDescriptionStore = modelDescriptionStore;
        this.viewSettingsStore = viewSettingsStore;
        this.menuSettingsStore = menuSettingsStore;
        this.projectSettingsStore = projectSettingsStore;
        this.actionStore = actionStore;
        this.customViewsStore = customViewsStore;
        this.dashboardStore = dashboardStore;
        this.guideSectionStore = guideSectionStore;
        this.projectGroupStore = projectGroupStore;
        this.taskQueueStore = taskQueueStore;
        this.projectPropertyStore = projectPropertyStore;
        this.onboardingService = onboardingService;
        this.companyInfoService = companyInfoService;
        this.metaService = metaService;
        this.sessionStorage = sessionStorage;
        this.notificationService = notificationService;
        this.currentUserStore = currentUserStore;
        this.resourceControllerService = resourceControllerService;
        this.projectService = projectService;
        this.themeService = themeService;
        this.migrationCheckService = migrationCheckService;
        this.analyticsService = analyticsService;
        this.projectApiService = projectApiService;
        this.apiService = apiService;
        this.versionService = versionService;
        this.publicApiService = publicApiService;
        this.screenRecordService = screenRecordService;
        this.popupService = popupService;
        this.documentService = documentService;
        this.globalsService = globalsService;
        this.state = {};
        this.hasCustomizationPermission = false;
        this.loading = true;
        this.menuSections = MenuSection;
        this.adminModes = AdminMode;
        this.menuCustomizeSelection = {
            subtitle: '',
            title: 'Edit Menu',
            image: 'menu',
            id: 'customize-menu',
            clickEvent: AnalyticsEvent.Deprecated.CustomizeInterfaceChooseNavigationView,
            description: [
                { icon: 'edit', label: 'Change the title and icons of a specific collection' },
                { icon: 'filter_down', label: 'Rearrange the order of collections' },
                { icon: 'section', label: 'Organize collections into groups' }
            ]
        };
        this.recording = false;
        this.externalFonts = [];
        this.isMobile = false;
    }
    AdminComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.backgroundColor$ = this.themeService.backgroundColorEffective$;
        this.resourceControllerService.register(ResourceType.JetApp, JetAppResourceController);
        this.resourceControllerService.register(ResourceType.JetBridge, JetBridgeResourceController);
        this.resourceControllerService.register(ResourceType.MessagesAPI, MessagesAPIResourceController);
        this.resourceControllerService.register(ResourceType.RestAPI, RestApiResourceControllerService);
        this.resourceControllerService.register(ResourceType.GraphQL, GraphQLResourceControllerService);
        this.resourceControllerService.register(ResourceType.AzureBlob, AzureBlobResourceController);
        this.resourceControllerService.register(ResourceType.AmazonS3, AmazonS3ResourceController);
        this.resourceControllerService.register(ResourceType.SupabaseStorage, SupabaseStorageResourceController);
        this.resourceControllerService.register(ResourceType.Airtable, AirtableResourceController);
        this.resourceControllerService.register(ResourceType.SmartSuite, SmartSuiteResourceController);
        this.resourceControllerService.register(ResourceType.Firebase, FirebaseResourceController);
        this.resourceControllerService.register(ResourceType.Xano, XanoResourceController);
        this.analyticsService.logProjectApiServiceErrors(this.projectApiService).pipe(untilDestroyed(this)).subscribe();
        combineLatest(this.projectUniqueName$, this.environmentUniqueName$)
            .pipe(tap(function () {
            if (_this.resourcesApiInfoSubscription) {
                _this.resourcesApiInfoSubscription.unsubscribe();
                _this.resourcesApiInfoSubscription = undefined;
            }
            _this.popupService.clear();
            _this.loading = true;
            _this.cd.markForCheck();
        }), switchMap(function (_a) {
            var projectUniqueName = _a[0], environmentUniqueName = _a[1];
            _this.currentProjectStore.uniqueName = projectUniqueName;
            _this.currentProjectStore.environmentUniqueName = environmentUniqueName;
            _this.currentEnvironmentStore.uniqueName = environmentUniqueName;
            return _this.currentProjectStore.getFirst(true).pipe(tap(function (project) {
                // this.currentEnvironmentStore.uniqueName = environmentUniqueName;
                // this.currentEnvironmentStore.instance = project.environments.find(
                //   item => item.uniqueName == environmentUniqueName
                // );
                // this.currentProjectStore.instance.resources = this.currentProjectStore.instance.resources.filter(
                //   item => item.environment == environmentUniqueName || item.demo
                // );
            }), delayWhen(function (project) {
                return project ? _this.getProjectToken(projectUniqueName, environmentUniqueName) : of(undefined);
            }));
        }), switchMap(function (project) {
            _this.projectSettingsStore.instance = project.projectSettings;
            _this.modelDescriptionStore.reset();
            _this.viewSettingsStore.reset();
            _this.menuSettingsStore.reset();
            _this.actionStore.reset();
            _this.customViewsStore.reset();
            _this.dashboardStore.reset();
            _this.guideSectionStore.reset();
            _this.projectGroupStore.reset();
            _this.taskQueueStore.reset();
            _this.projectPropertyStore.reset();
            window['project'] = project ? project.uniqueName : undefined;
            window['project_environment'] = _this.currentEnvironmentStore.instance
                ? _this.currentEnvironmentStore.instance.uniqueName
                : undefined;
            var environment = _this.currentEnvironmentStore.instance;
            var resources = project && environment ? project.getEnvironmentResources(environment.uniqueName) : [];
            var apiInfo$ = _this.fetchApiInfo(resources);
            var menuSettings$ = _this.menuSettingsStore.getFirst();
            var customViews$ = _this.customViewsStore.getFirst();
            return combineLatest(of(project), apiInfo$, menuSettings$, customViews$);
        }), untilDestroyed(this))
            .subscribe(function (_a) {
            var project = _a[0], apiInfo = _a[1];
            // this.projectsStore.current = project;
            window['legacyModels'] =
                !!project.params['legacy_models'] ||
                    apiInfo.filter(function (item) { return item; }).some(function (item) { return item.versionLessThan('0.6.8'); });
            window['legacy_tokens'] = !!project.params['legacy_tokens'];
            window['version'] = _this.currentEnvironmentStore.instance
                ? _this.currentEnvironmentStore.instance.version
                : undefined;
            window['project_has_parent'] = project ? !!project.parent : false;
            if (project) {
                _this.onProjectLoaded(project, _this.currentEnvironmentStore.instance, _this.projectSettingsStore.getAllSettings());
            }
            _this.loading = false;
            _this.cd.markForCheck();
        }, function (error) {
            _this.error = error;
            _this.loading = false;
            _this.cd.markForCheck();
        });
        this.mode$.pipe(untilDestroyed(this)).subscribe(function (mode) {
            window['mode'] = mode;
            if (mode == AdminMode.Builder) {
                _this.customizeService.enabled = CustomizeType.Layout;
            }
            else if (mode == AdminMode.App) {
                _this.customizeService.enabled = undefined;
            }
        });
        merge(of({}), this.router.events.pipe(filter(function (item) { return item instanceof ActivationEnd; })))
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            _this.menuService.opened = false;
        });
        combineLatest(this.currentProjectStore.instance$, this.projectSettingsStore.getAllSettings$(), this.currentEnvironmentStore.original$, this.currentProjectStore.instance$.pipe(map(function (item) { return (item ? item.isSubscriptionEnded() : false); })), this.currentProjectStore.instance$.pipe(map(function (item) { return (item ? item.isSubscriptionPastDue() : false); })), this.currentProjectStore.instance$.pipe(map(function (item) { return (item ? item.hasProjectBillingPermission() : false); })), this.demoBannerService.enabled$, this.demoBannerService.visible$, this.currentUserStore.original$.pipe(map(function (user) { return user && !user.emailVerified; })), this.customizeService.selection$, 
        // this.customizeService.animating$,
        this.customizeService.enabled$, this.customizeService.layoutEnabled$, this.customizeService.menuEnabled$, this.customizeService.layoutCustomization$, this.menuService.opened$, this.menuService.section$, this.metaService.titlePrimary$, this.currentProjectStore.instance$.pipe(map(function (project) {
            if (!project) {
                return true;
            }
            var iframeKey = "iframe_" + project.uniqueName;
            return !!_this.sessionStorage.get(iframeKey);
        })), combineLatest(this.currentProjectStore.instance$, this.currentUserStore.original$.pipe(map(function (user) { return user && user.isAnonymous(); }))).pipe(map(function (_a) {
            var project = _a[0], isAnonymous = _a[1];
            if (!project) {
                return false;
            }
            return project.isOwner || isAnonymous || project.features.isUsersEnabled();
        })), this.menuService.customizeBarOpened$, this.mode$)
            .pipe(untilDestroyed(this))
            .subscribe(function (state) {
            _this.state = fromPairs([
                'projectCurrent',
                'projectSettings',
                'environmentCurrent',
                'subscriptionEnded',
                'subscriptionPastDue',
                'billingPermission',
                'demoBannerEnabled',
                'demoBannerVisible',
                'verificationBannerVisible',
                'customizeSelection',
                // 'customizeAnimating',
                'customizeEnabled',
                'customizeLayoutEnabled',
                'customizeMenuEnabled',
                'customizeLayoutCustomization',
                'menuOpened',
                // 'menuSlided',
                'menuSection',
                'metaTitlePrimary',
                'iframe',
                'usersFeatureActive',
                'customizeBarOpened',
                'mode'
            ].map(function (item, i) { return [item, state[i]]; }));
            _this.hasCustomizationPermission =
                _this.state.projectCurrent &&
                    _this.state.environmentCurrent &&
                    _this.state.projectCurrent.hasEnvironmentCustomizationPermission(_this.state.environmentCurrent);
            _this.externalFonts = _this.getExternalFonts(_this.state.projectSettings);
            _this.notificationService.topPadding = _this.state.customizeEnabled;
            _this.cd.markForCheck();
            Sentry.setContext('project', _this.state.projectCurrent
                ? {
                    project: _this.state.projectCurrent.uniqueName,
                    environment: _this.state.environmentCurrent ? _this.state.environmentCurrent.uniqueName : undefined,
                    mode: _this.state.mode
                }
                : {});
        });
        this.screenRecordService
            .cheatCode$('jet!screenrecord')
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            if (_this.customizeService.enabled) {
                return;
            }
            _this.recording = !_this.recording;
            _this.cd.markForCheck();
            if (_this.recording) {
                _this.notificationService.warning('Screen recording mode activated!');
            }
            else {
                _this.notificationService.warning('Screen recording mode deactivated!');
            }
        });
        this.themeService.staffEnabled$
            .pipe(filter(function (item) { return item; }), switchMap(function () {
            return trackKeyboardCode([
                KeyboardEventKeyCode.E,
                KeyboardEventKeyCode.R,
                KeyboardEventKeyCode.R,
                KeyboardEventKeyCode.O,
                KeyboardEventKeyCode.R
            ]);
        }), untilDestroyed(this))
            .subscribe(function (entered) {
            if (entered) {
                setTimeout(function () { return _this.triggerException(); });
            }
        });
        this.documentService
            .isMobile$()
            .pipe(untilDestroyed(this))
            .subscribe(function (value) {
            _this.isMobile = value;
            _this.cd.markForCheck();
        });
        this.customizeService
            .getContentfulRightBarHandlers()
            .pipe(untilDestroyed(this))
            .subscribe(function (handlers) {
            toggleClass(document.body, BODY_CONTENTFUL_RIGHT_BAR_CLASS, handlers.length > 0);
        });
    };
    AdminComponent.prototype.ngOnDestroy = function () {
        this.notificationService.topPadding = false;
        this.projectsStore.current = undefined;
        this.customizeService.enabled = undefined;
        Sentry.setContext('project', {});
        removeClass(document.body, BODY_CONTENTFUL_RIGHT_BAR_CLASS);
    };
    AdminComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        fromEvent(window, 'wheel', { passive: false })
            .pipe(untilDestroyed(this))
            .subscribe(function (e) {
            if (_this.customizeService.selection || _this.menuService.opened) {
                e.preventDefault();
            }
        });
        fromEvent(document.body, 'touchmove', { passive: false })
            .pipe(untilDestroyed(this))
            .subscribe(function (e) {
            if (_this.customizeService.selection || _this.menuService.opened) {
                e.preventDefault();
            }
        });
        // merge(this.customizeService.selection$.pipe(skip(1)), this.menuService.opened$.pipe(skip(1)))
        //   .pipe(untilDestroyed(this))
        //   .subscribe(zoomOut => {
        //     if (!this.contentInner) {
        //       return;
        //     }
        //
        //     if (zoomOut) {
        //       const scrollTop = getWindowScrollTop();
        //       const transformOrigin = (scrollTop + window.innerHeight * 0.5) / document.body.offsetHeight;
        //
        //       this.contentInner.nativeElement.style['transform-origin'] = `center ${transformOrigin * 100}%`;
        //     }
        //   });
        // merge(
        //   this.customizeService.selection$.pipe(skip(1)),
        //   this.customizeService.enabled$.pipe(skip(1))
        // )
        //   .pipe(
        //     tap(() => {
        //       this.customizeService.animating = true;
        //     }),
        //     delay(600),
        //     untilDestroyed(this)
        //   )
        //   .subscribe(() => {
        //     this.customizeService.animating = false;
        //   });
    };
    AdminComponent.prototype.fetchApiInfo = function (allResources) {
        var _this = this;
        var resources = allResources.filter(function (item) {
            var controller = _this.resourceControllerService.getForResource(item, true);
            return controller && controller.checkApiInfo && !item.apiInfo;
        });
        if (!resources.length) {
            return of([]);
        }
        var getApiInfoKey = function (resource) {
            return JSON.stringify({ url: resource.params['url'] });
        };
        var groupedByKey = resources.reduce(function (acc, item) {
            var key = getApiInfoKey(item);
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(item);
            return acc;
        }, {});
        return combineLatest(values(groupedByKey).map(function (groupResources) {
            var resource = groupResources[0];
            var controller = _this.resourceControllerService.getForResource(resource, true);
            return controller.getApiInfo(resource).pipe(tap(function (apiInfo) {
                if (apiInfo) {
                    groupResources.forEach(function (item) {
                        item.apiInfo = apiInfo;
                    });
                }
            }), timeout(5000), catchError(function () { return of(undefined); }));
        }));
    };
    AdminComponent.prototype.getProjectToken = function (projectName, environmentName) {
        var _this = this;
        return this.projectApiService.getToken(projectName, environmentName, { mode: this.mode }).pipe(tap(function (result) { return _this.projectApiService.saveToken(result); }), map(function () { return true; }), this.apiService.catchApiError(false), publishLast(), refCount());
    };
    AdminComponent.prototype.getExternalFonts = function (projectSettings) {
        if (!projectSettings) {
            return [];
        }
        return uniq([projectSettings.fontRegular, projectSettings.fontHeading].concat(projectSettings.fontsUsed).filter(function (item) {
            return isSet(item);
        }));
    };
    AdminComponent.prototype.setProjectTheme = function (project, projectSettings) {
        this.themeService.color = project.logoColor;
        if (projectSettings && projectSettings.defaultTheme) {
            this.themeService.setDefaultTheme(projectSettings.defaultTheme);
        }
    };
    AdminComponent.prototype.initGlobalStorage = function (project, environment) {
        if (!project || !environment) {
            this.currentEnvironmentStore.globalStorage = undefined;
            return;
        }
        this.currentEnvironmentStore.globalStorage = Injector.create({
            providers: [
                {
                    provide: ProjectPropertyStorage,
                    useFactory: function (projectPropertyStore) {
                        return new ProjectPropertyStorage(project.uniqueName, environment.uniqueName, projectPropertyStore, ProjectPropertyType.Global);
                    },
                    deps: [ProjectPropertyStore]
                }
            ],
            parent: this.injector
        }).get(ProjectPropertyStorage);
    };
    AdminComponent.prototype.onProjectLoaded = function (project, environment, projectSettings) {
        var _this = this;
        this.projectService.updateProjectLastUsed(project);
        this.setProjectTheme(project, projectSettings);
        this.versionService.checkWrongProjectVersionLoaded(project, environment);
        this.migrationCheckService.checkNotAppliedMigrations(project, environment);
        this.initGlobalStorage(project, environment);
        this.resourcesApiInfoSubscription = this.currentEnvironmentStore.resources$
            .pipe(skip(1), switchMap(function (resources) { return _this.fetchApiInfo(resources); }), untilDestroyed(this))
            .subscribe();
        // TODO: refactor into single place
        setTimeout(function () {
            if (!_this.companyInfoService.showCompanyInfoPopupIfNeeded(project)) {
                _this.onboardingService.showOnboardingPopupIfNeeded(project);
            }
        }, 3000);
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.Project.Opened, {
            Name: project.name,
            DateCreated: project.dateAdd ? project.dateAdd.toISOString() : undefined,
            SubscriptionPlan: project.subscription && project.subscription.plan ? project.subscription.plan.uniqueName : undefined,
            SubscriptionFreeTrial: project.subscription ? !!project.subscription.freeTrial : undefined,
            SubscriptionDateBegin: project.subscription && project.subscription.dateBegin
                ? project.subscription.dateBegin.toISOString()
                : undefined,
            SubscriptionDateCancelled: project.subscription && project.subscription.dateCancelled
                ? project.subscription.dateCancelled.toISOString()
                : undefined
        });
    };
    AdminComponent.prototype.onBackgroundClick = function (e) {
        if (!this.customizeService.selection) {
            return;
        }
        if (!e.target.getAttribute('data-selection-cancel')) {
            return;
        }
        this.disableCustomizeSelection();
    };
    AdminComponent.prototype.disableCustomizeSelection = function () {
        this.customizeService.selection = false;
    };
    AdminComponent.prototype.disableCustomize = function () {
        this.customizeService.enabled = undefined;
    };
    AdminComponent.prototype.onRouteActivate = function (e) {
        var _this = this;
        var project = this.state.projectCurrent;
        var environmentName = this.state.environmentCurrent ? this.state.environmentCurrent.uniqueName : undefined;
        var state = this.router.routerState.snapshot;
        var mode = this.state.mode;
        var allowedUrlPrefixes = [];
        if (project.hasProjectBillingPermission()) {
            allowedUrlPrefixes.push(project.settingsBillingLink);
        }
        else {
            allowedUrlPrefixes.push(project.subscriptionExpiredLink);
        }
        if (project.hasEnvironmentAccessPermission(project.defaultEnvironment)) {
            allowedUrlPrefixes.push(project.settingsUsersLink);
        }
        var urlAllowed = allowedUrlPrefixes.some(function (item) {
            return state.url.startsWith(_this.routing
                .appLink(item, { projectName: project.uniqueName, environmentName: environmentName, modeName: mode })
                .join('/'));
        });
        if (project.isSubscriptionEnded() && !urlAllowed) {
            if (project.hasProjectBillingPermission()) {
                var link = project.settingsBillingLink;
                this.routing.navigateApp(link, { replaceUrl: true }, {
                    projectName: project.uniqueName,
                    environmentName: environmentName,
                    modeName: mode
                });
                this.notificationService.warning('Subscription has expired', 'Choose subscription plan to continue using Jet Admin');
            }
            else {
                var link = project.subscriptionExpiredLink;
                this.routing.navigateApp(link, { replaceUrl: true }, {
                    projectName: project.uniqueName,
                    environmentName: environmentName,
                    modeName: mode
                });
            }
        }
    };
    AdminComponent.prototype.triggerException = function () {
        var a = {
            testProperty: {
                testNestedProperty: 'value'
            }
        };
        delete a['testProperty'];
        console.log(a.testProperty.testNestedProperty);
    };
    AdminComponent = __decorate([
        Customizable,
        __metadata("design:paramtypes", [Observable,
            Observable,
            Observable, String, AdminComponentService,
            CurrentProjectStore,
            CurrentEnvironmentStore,
            ProjectsStore,
            RoutingService,
            Router,
            ChangeDetectorRef,
            Injector,
            CustomizeService,
            MenuService,
            DemoBannerService,
            ModelDescriptionStore,
            ViewSettingsStore,
            MenuSettingsStore,
            ProjectSettingsStore,
            ActionStore,
            CustomViewsStore,
            DashboardStore,
            GuideSectionStore,
            ProjectGroupStore,
            TaskQueueStore,
            ProjectPropertyStore,
            OnboardingService,
            CompanyInfoService,
            MetaService,
            SessionStorage,
            NotificationService,
            CurrentUserStore,
            ResourceControllerService,
            ProjectService,
            ThemeService,
            MigrationCheckService,
            UniversalAnalyticsService,
            ProjectApiService,
            ApiService,
            VersionService,
            PublicApiService,
            ScreenRecordService,
            PopupService,
            DocumentService,
            AdminGlobalsService])
    ], AdminComponent);
    return AdminComponent;
}());
export { AdminComponent };
