var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnDestroy } from '@angular/core';
import fromPairs from 'lodash/fromPairs';
import isArray from 'lodash/isArray';
import isEqual from 'lodash/isEqual';
import { BehaviorSubject, combineLatest, merge, of } from 'rxjs';
import { auditTime, debounceTime, filter, map, pairwise, shareReplay, switchMap } from 'rxjs/operators';
import { CurrentEnvironmentStore } from '@modules/projects';
import { firstSet } from '@shared';
import { ElementItem } from './elements/items/base';
import { ViewContextElementType } from './view-context-element-type';
var ViewContext = /** @class */ (function () {
    function ViewContext(currentEnvironmentStore) {
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.dropListGroups = [];
        this.updateInterval = 60;
        this._elements = new BehaviorSubject([]);
        this._elementsPaused$ = new BehaviorSubject(false);
        this._outputValues = {};
        this._sharedElements$ = this.getElements$();
        this._sharedOutputs$ = this.getOutputs$();
        this._sharedOutputValues$ = this.getOutputValues$();
        // if (!environment.production) {
        //   this.outputs$.pipe(untilDestroyed(this)).subscribe(value => {
        //     window['jet_ctx'] = value;
        //   });
        // }
    }
    ViewContext.prototype.ngOnDestroy = function () { };
    ViewContext.prototype.clear = function (type) {
        if (type) {
            this._elements.next(this._elements.value.filter(function (item) { return item.element.type != type; }));
        }
        else {
            this._elements.next([]);
        }
    };
    ViewContext.prototype.getElements$ = function () {
        var _this = this;
        return merge(this._elements.pipe(filter(function () { return !_this._elementsPaused$.value; })), this._elementsPaused$.pipe(pairwise(), filter(function (_a) {
            var prev = _a[0], current = _a[1];
            return prev && !current;
        }), map(function () { return _this._elements.value; }))).pipe(shareReplay(1));
    };
    Object.defineProperty(ViewContext.prototype, "elements", {
        get: function () {
            return this._elements.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ViewContext.prototype, "elements$", {
        get: function () {
            return this._sharedElements$;
        },
        enumerable: true,
        configurable: true
    });
    ViewContext.prototype.pauseElements = function () {
        if (!this._elementsPaused$.value) {
            this._elementsPaused$.next(true);
        }
    };
    ViewContext.prototype.resumeElements = function () {
        if (this._elementsPaused$.value) {
            this._elementsPaused$.next(false);
        }
    };
    // TODO: Refactor
    ViewContext.prototype.getElementItems = function () {
        return this.elements
            .filter(function (i) { return i.element.type == ViewContextElementType.Element; })
            .map(function (i) {
            var elementItem = new ElementItem();
            elementItem.uid = i.element.uniqueName;
            elementItem.name = i.element.name;
            return elementItem;
        });
    };
    ViewContext.prototype.isRegisteredElement = function (element) {
        return this._elements.value.some(function (item) { return item.element === element; });
    };
    ViewContext.prototype.registerElement = function (element, parent) {
        if (this._elements.value.find(function (item) { return item.element === element; })) {
            return;
        }
        this._elements.next(this._elements.value.concat([
            {
                element: element,
                parent: parent
            }
        ]));
        this.markHasChanges();
    };
    ViewContext.prototype.unregisterElement = function (element) {
        var value = this._elements.value;
        var removeItems = [];
        var removeWithChildren = function (parent) {
            removeItems.push(parent);
            value.filter(function (item) { return item.parent === parent; }).forEach(function (item) { return removeWithChildren(item.element); });
        };
        removeWithChildren(element);
        this._elements.next(value.filter(function (item) { return !removeItems.includes(item.element); }));
        this.markHasChanges();
    };
    ViewContext.prototype.markElementsHasChanges = function () {
        this._elements.next(this._elements.value);
    };
    ViewContext.prototype.markHasChanges = function () {
        this._outputValues = undefined;
    };
    ViewContext.prototype.getElementPath = function (element) {
        if (!element) {
            return;
        }
        var contextElement = this._elements.value.find(function (item) { return item.element === element; });
        var pathReversed = [];
        while (true) {
            if (!contextElement) {
                return;
            }
            pathReversed.push(contextElement.element.uniqueName);
            if (!contextElement.parent) {
                break;
            }
            contextElement = this._elements.value.find(function (item) { return item.element === contextElement.parent; });
        }
        return ['elements'].concat(pathReversed.reverse());
    };
    ViewContext.prototype.getElementChildren = function (parent) {
        return this._elements.value.filter(function (item) { return item.parent === parent; }).map(function (item) { return item.element; });
    };
    ViewContext.prototype.getElement = function (uniqueName) {
        var element = this._elements.value.find(function (item) { return item.element.uniqueName === uniqueName; });
        return element ? element.element : undefined;
    };
    ViewContext.prototype.getOutputTokens$ = function (human, showInternal, showExternal, allowSkip, forPaths) {
        var _this = this;
        if (human === void 0) { human = false; }
        if (showInternal === void 0) { showInternal = false; }
        if (showExternal === void 0) { showExternal = true; }
        if (allowSkip === void 0) { allowSkip = true; }
        var mapOutputs = function (element, items, prefix, insideExternal) {
            if (insideExternal === void 0) { insideExternal = false; }
            var result = items.filter(function (item) {
                var token = prefix.concat([item.uniqueName]);
                return !item.external || showExternal || (forPaths && forPaths.find(function (path) { return isEqual(path, token); }));
            });
            var singleResult = result.length == 1 ? result[0] : undefined;
            if (allowSkip && singleResult && singleResult.allowSkip && singleResult.children) {
                var token = prefix.concat([singleResult.uniqueName]);
                return mapOutputs(element, singleResult.children, token, insideExternal || singleResult.external);
            }
            else {
                return result.map(function (item) {
                    var token = prefix.concat([item.uniqueName]);
                    return {
                        name: firstSet(item.name, item.uniqueName),
                        subtitle: item.subtitle,
                        uniqueName: item.uniqueName,
                        icon: item.icon,
                        iconOrange: item.iconOrange,
                        fieldType: item.fieldType,
                        fieldParams: item.fieldParams,
                        token: token,
                        element: element,
                        documentation: item.documentation,
                        action: item.action,
                        children: item.children
                            ? mapOutputs(element, item.children, token, insideExternal || item.external)
                            : undefined
                    };
                });
            }
        };
        return this.outputs$.pipe(map(function (elements) {
            var iterateElements = function (elementType, prefix, parent, path) {
                if (path === void 0) { path = []; }
                return elements
                    .filter(function (item) { return item.item.element.type == elementType; })
                    .filter(function (item) { return item.item.parent === parent; })
                    .map(function (item) {
                    var itemPathItem = human && elementType == ViewContextElementType.Element && !parent
                        ? item.item.element.name
                        : item.item.element.uniqueName;
                    var fullPath = path.concat([itemPathItem]);
                    var prefixedFullPath = (prefix ? [prefix] : []).concat(path, [itemPathItem]);
                    return {
                        name: item.item.element.name,
                        icon: item.item.element.icon,
                        uniqueName: itemPathItem,
                        element: item.item.element,
                        allowSkip: item.item.element.allowSkip,
                        token: item.item.element.insert ? fullPath : undefined,
                        documentation: item.item.element.documentation,
                        action: item.item.element.action,
                        children: iterateElements(elementType, prefix, item.item.element, fullPath).concat(mapOutputs(item.item.element, item.outputs
                            .filter(function (output) {
                            if (!output.internal) {
                                return true;
                            }
                            return showInternal;
                        })
                            .filter(function (output) {
                            if (!output.byPathOnly) {
                                return true;
                            }
                            if (!forPaths) {
                                return false;
                            }
                            var outputPath = prefixedFullPath.concat([output.uniqueName]);
                            return forPaths.some(function (forPath) { return isEqual(outputPath, forPath); });
                        }), prefixedFullPath))
                    };
                });
            };
            var tokens = iterateElements(ViewContextElementType.Global).concat([
                {
                    name: 'Components',
                    uniqueName: 'elements',
                    children: iterateElements(ViewContextElementType.Element, 'elements').filter(function (item) { return item.children && item.children.length; })
                }
            ]);
            if (_this.model && _this.modelDescription) {
                tokens.push({
                    name: 'Record',
                    uniqueName: 'record',
                    children: _this.modelDescription.dbFields.map(function (item) {
                        return {
                            name: item.verboseName || item.name,
                            uniqueName: item.name,
                            icon: item.fieldDescription ? item.fieldDescription.icon : undefined,
                            fieldType: item.field,
                            fieldParams: item.params,
                            token: ['record', item.name],
                            children: undefined
                        };
                    })
                });
            }
            return tokens;
        }));
    };
    ViewContext.prototype.getActionTokens$ = function (human, showInternal, showExternal) {
        if (human === void 0) { human = false; }
        if (showInternal === void 0) { showInternal = false; }
        if (showExternal === void 0) { showExternal = true; }
        var mapActions = function (items, prefix, insideExternal) {
            if (insideExternal === void 0) { insideExternal = false; }
            return items
                .filter(function (item) { return showExternal || (!showExternal && !item.external && !insideExternal); })
                .map(function (item) {
                var token = prefix.concat([item.uniqueName]);
                return {
                    name: firstSet(item.name, item.uniqueName),
                    uniqueName: item.uniqueName,
                    icon: item.icon,
                    token: token
                };
            });
        };
        return combineLatest(this.elements$.pipe(switchMap(function (items) {
            if (!items.length) {
                return of([]);
            }
            return combineLatest(items.map(function (item) {
                return item.element.actions$.pipe(map(function (actions) {
                    return {
                        item: item,
                        actions: actions
                    };
                }));
            }));
        }))).pipe(map(function (result) {
            var elements = result[0];
            var iterateElements = function (elementType, prefix, parent, path) {
                if (path === void 0) { path = []; }
                return elements
                    .filter(function (item) { return item.item.element.type == elementType; })
                    .filter(function (item) { return item.item.parent === parent; })
                    .map(function (item) {
                    var itemPathItem = human && elementType == ViewContextElementType.Element && !parent
                        ? item.item.element.name
                        : item.item.element.uniqueName;
                    var fullPath = path.concat([itemPathItem]);
                    var prefixedFullPath = (prefix ? [prefix] : []).concat(path, [itemPathItem]);
                    return {
                        name: item.item.element.name,
                        icon: item.item.element.icon,
                        uniqueName: itemPathItem,
                        allowSkip: item.item.element.allowSkip,
                        documentation: item.item.element.documentation,
                        action: item.item.element.action,
                        children: iterateElements(elementType, prefix, item.item.element, fullPath).concat(mapActions(item.actions.filter(function (action) { return !action.internal || showInternal; }), prefixedFullPath))
                    };
                });
            };
            var tokens = iterateElements(ViewContextElementType.Global).concat([
                {
                    name: 'Components',
                    uniqueName: 'elements',
                    children: iterateElements(ViewContextElementType.Element, 'elements').filter(function (item) { return item.children && item.children.length; })
                }
            ]);
            return tokens;
        }));
    };
    ViewContext.prototype.getElementTokens$ = function (element, contextElementPath, contextElementPaths, human) {
        if (human === void 0) { human = false; }
        contextElementPath = contextElementPath || [];
        contextElementPaths = contextElementPaths || [];
        var elementPath = this.getElementPath(element);
        if (!elementPath) {
            return of([]);
        }
        var path = contextElementPath ? elementPath.concat(contextElementPath) : elementPath.slice();
        var forPaths = [contextElementPath].concat(contextElementPaths).map(function (item) {
            return elementPath.concat(item);
        });
        return this.getOutputTokens$(human, true, false, true, forPaths).pipe(map(function (tokens) {
            var currentTokens = tokens;
            var _loop_1 = function (i) {
                var pathElement = path[i];
                var token = currentTokens.find(function (item) { return item.uniqueName == pathElement; });
                if (!token || !token.children) {
                    return { value: [] };
                }
                currentTokens = token.children;
            };
            for (var i = 0; i < path.length; ++i) {
                var state_1 = _loop_1(i);
                if (typeof state_1 === "object")
                    return state_1.value;
            }
            var trimElementPath = function (items) {
                return items.map(function (token) {
                    var trimSize = elementPath.reduce(function (acc, item, i) {
                        if (acc.length == i && item == token.token[i]) {
                            acc.push(item);
                        }
                        return acc;
                    }, []).length;
                    return __assign({}, token, { token: token.token ? token.token.slice(trimSize) : undefined, children: token.children ? trimElementPath(token.children) : undefined });
                });
            };
            return trimElementPath(currentTokens);
        }));
    };
    ViewContext.prototype.tokenPath$ = function (path, element, contextElementPath, contextElementPaths, includeTrailing) {
        if (includeTrailing === void 0) { includeTrailing = false; }
        var getTokenPathFromOptions = function (tokens, options) {
            for (var _i = 0, options_1 = options; _i < options_1.length; _i++) {
                var pathOption = options_1[_i];
                var tokensCurrent = tokens;
                var result = [];
                var trailing = [];
                var _loop_2 = function (i) {
                    var pathItem = pathOption[i];
                    var pathToken = tokensCurrent.find(function (item) { return item.uniqueName == pathItem; });
                    if (!pathToken) {
                        result = undefined;
                        return "break";
                    }
                    result.push(pathToken.name);
                    if (pathToken && pathToken.children) {
                        tokensCurrent = pathToken.children;
                    }
                    else {
                        trailing = pathOption.slice(i + 1);
                        return "break";
                    }
                };
                for (var i = 0; i < pathOption.length; ++i) {
                    var state_2 = _loop_2(i);
                    if (state_2 === "break")
                        break;
                }
                if (!result) {
                    continue;
                }
                return {
                    path: pathOption,
                    result: result,
                    trailing: trailing
                };
            }
        };
        if (!path) {
            return of([]);
        }
        var pathsOptions = [path];
        var elementPath = this.getElementPath(element);
        var forPaths = elementPath
            ? [contextElementPath].concat(contextElementPaths).map(function (item) {
                return elementPath.concat(item);
            })
            : undefined;
        if (elementPath) {
            pathsOptions = [elementPath.concat(path)].concat(pathsOptions);
        }
        return this.getOutputTokens$(false, true, true, false, forPaths).pipe(map(function (tokens) {
            var result = getTokenPathFromOptions(tokens, pathsOptions);
            if (!result) {
                return;
            }
            if (elementPath && isEqual(result.path.slice(0, elementPath.length), elementPath)) {
                return [
                    'Current element'
                ].concat(result.result.slice(elementPath.length), (includeTrailing ? result.trailing : []));
            }
            else {
                return result.result.concat((includeTrailing ? result.trailing : []));
            }
        }));
    };
    ViewContext.prototype.getToken$ = function (path, element, contextElementPath, contextElementPaths) {
        var getTokenPathFromOptions = function (tokens, options) {
            for (var _i = 0, options_2 = options; _i < options_2.length; _i++) {
                var pathOption = options_2[_i];
                var tokensCurrent = tokens;
                // let result = [];
                var result = void 0;
                var _loop_3 = function (i) {
                    var pathItem = pathOption[i];
                    var pathToken = tokensCurrent.find(function (item) { return item.uniqueName == pathItem; });
                    if (!pathToken) {
                        result = undefined;
                        return "break";
                    }
                    // result.push(pathToken.name);
                    if (i == pathOption.length - 1) {
                        result = pathToken;
                    }
                    else if (pathToken && pathToken.children) {
                        tokensCurrent = pathToken.children;
                    }
                    else {
                        result = undefined;
                        return "break";
                        // trailing = pathOption.slice(i + 1);
                        // break;
                    }
                };
                // let trailing = [];
                for (var i = 0; i < pathOption.length; ++i) {
                    var state_3 = _loop_3(i);
                    if (state_3 === "break")
                        break;
                }
                if (!result) {
                    continue;
                }
                return result;
                // if (!result) {
                //   continue;
                // }
                //
                // return {
                //   path: pathOption,
                //   result: result,
                //   trailing: trailing
                // };
            }
        };
        if (!path) {
            return of(undefined);
        }
        var pathsOptions = [path];
        var elementPath = this.getElementPath(element);
        var forPaths = elementPath
            ? [contextElementPath].concat(contextElementPaths).map(function (item) {
                return elementPath.concat(item);
            })
            : undefined;
        if (elementPath) {
            pathsOptions = [elementPath.concat(path)].concat(pathsOptions);
        }
        return this.getOutputTokens$(false, true, true, false, forPaths).pipe(map(function (tokens) {
            return getTokenPathFromOptions(tokens, pathsOptions);
        }));
    };
    ViewContext.prototype.getActionTokenPath$ = function (path, human, showInternal, showExternal) {
        if (human === void 0) { human = false; }
        if (showInternal === void 0) { showInternal = false; }
        if (showExternal === void 0) { showExternal = true; }
        return this.getActionTokens$(human, showInternal, showExternal).pipe(map(function (rootItems) {
            var getNode = function (items, nodePath, currentResult) {
                var node = items.find(function (item) { return item.uniqueName == nodePath[0]; });
                if (node && node.children) {
                    return getNode(node.children, nodePath.slice(1), currentResult.concat([node]));
                }
                if (nodePath.length > 1) {
                    return [];
                }
                return currentResult.concat([node]);
            };
            return getNode(rootItems, path, []);
        }));
    };
    ViewContext.prototype.getElementAction = function (path) {
        if (!path) {
            return;
        }
        var parent;
        var _loop_4 = function (i) {
            if (i == 0 && path[i] == 'elements') {
                return "continue";
            }
            else if (i == path.length - 1) {
                if (!parent) {
                    return { value: void 0 };
                }
                return { value: parent.actions.find(function (item) { return item.uniqueName == path[i]; }) };
            }
            var contextItem = this_1.elements.find(function (item) { return item.element.uniqueName == path[i] && item.parent == parent; });
            if (!contextItem) {
                return { value: void 0 };
            }
            parent = contextItem.element;
        };
        var this_1 = this;
        for (var i = 0; i < path.length; ++i) {
            var state_4 = _loop_4(i);
            if (typeof state_4 === "object")
                return state_4.value;
        }
    };
    ViewContext.prototype.getElementAction$ = function (path) {
        if (!path) {
            return of(undefined);
        }
        return this.elements$.pipe(map(function (elements) {
            var parent;
            var _loop_5 = function (i) {
                if (i == 0 && path[i] == 'elements') {
                    return "continue";
                }
                else if (i == path.length - 1) {
                    if (!parent) {
                        return { value: void 0 };
                    }
                    return { value: parent.actions.find(function (item) { return item.uniqueName == path[i]; }) };
                }
                var contextItem = elements.find(function (item) { return item.element.uniqueName == path[i] && item.parent == parent; });
                if (!contextItem) {
                    return { value: void 0 };
                }
                parent = contextItem.element;
            };
            for (var i = 0; i < path.length; ++i) {
                var state_5 = _loop_5(i);
                if (typeof state_5 === "object")
                    return state_5.value;
            }
        }));
    };
    ViewContext.prototype.elementActionPath$ = function (path) {
        return this.getActionTokenPath$(path).pipe(map(function (result) {
            return result.filter(function (item, i) {
                var prevItem = result[i - 1];
                if (prevItem && prevItem.allowSkip && prevItem.children && prevItem.children.length == 1) {
                    return false;
                }
                return true;
            }, []);
        }));
    };
    ViewContext.prototype.elementOutputValues = function (type) {
        var items = this.elements.filter(function (item) { return item.element.type == type; });
        if (!items.length) {
            return {};
        }
        var iterateElements = function (parent) {
            return fromPairs(items
                .filter(function (item) { return item.parent === parent; })
                .map(function (item) {
                var key = item.element.uniqueName;
                var value = isArray(item.element.outputsValue)
                    ? item.element.outputsValue
                    : __assign({}, iterateElements(item.element), item.element.outputsValue);
                return [key, value];
            }));
        };
        return iterateElements();
    };
    ViewContext.prototype.elementOutputValues$ = function (type) {
        return this.elements$.pipe(debounceTime(100), switchMap(function (items) {
            items = items.filter(function (item) { return item.element.type == type; });
            if (!items.length) {
                return of([]);
            }
            return combineLatest(items.map(function (item) {
                return item.element.outputsValue$.pipe(map(function (value) {
                    return {
                        item: item,
                        value: value
                    };
                }));
            }));
        }), map(function (elements) {
            var iterateElements = function (parent) {
                return fromPairs(elements
                    .filter(function (item) { return item.item.parent === parent; })
                    .map(function (item) {
                    var key = item.item.element.uniqueName;
                    var value = isArray(item.value)
                        ? item.value
                        : __assign({}, iterateElements(item.item.element), item.value);
                    return [key, value];
                }));
            };
            return iterateElements();
        }));
    };
    ViewContext.prototype.getOutputs$ = function () {
        return this.elements$.pipe(switchMap(function (items) {
            if (!items.length) {
                return of([]);
            }
            return combineLatest(items.map(function (item) {
                return item.element.outputs$.pipe(map(function (outputs) {
                    return {
                        item: item,
                        outputs: outputs
                    };
                }));
            }));
        }));
    };
    Object.defineProperty(ViewContext.prototype, "outputs$", {
        get: function () {
            return this._sharedOutputs$;
        },
        enumerable: true,
        configurable: true
    });
    ViewContext.prototype.getOutputValues = function () {
        var globals = this.elementOutputValues(ViewContextElementType.Global);
        var elements = this.elementOutputValues(ViewContextElementType.Element);
        var result = __assign({}, globals, { elements: elements });
        if (this.model) {
            result['record'] = __assign({}, fromPairs(this.modelDescription.dbFields.map(function (item) {
                return [item.name, undefined];
            })), this.model.getAttributes());
        }
        else if (this.modelDescription) {
            result['record'] = undefined;
        }
        return result;
    };
    ViewContext.prototype.getOutputValues$ = function () {
        var _this = this;
        return combineLatest(this.elementOutputValues$(ViewContextElementType.Global), this.elementOutputValues$(ViewContextElementType.Element)).pipe(map(function (_a) {
            var globals = _a[0], elements = _a[1];
            var result = __assign({}, globals, { elements: elements });
            if (_this.model) {
                result['record'] = __assign({}, fromPairs(_this.modelDescription.dbFields.map(function (item) {
                    return [item.name, undefined];
                })), _this.model.getAttributes());
            }
            else if (_this.modelDescription) {
                result['record'] = undefined;
            }
            return result;
        }), 
        // TODO: Think about more optimal context updates
        auditTime(this.updateInterval), 
        // distinctUntilChanged((lhs: Object, rhs: Object) => isEqual(lhs, rhs)),
        shareReplay(1));
    };
    Object.defineProperty(ViewContext.prototype, "outputValues", {
        get: function () {
            if (!this._outputValues) {
                this._outputValues = this.getOutputValues();
            }
            return this._outputValues;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ViewContext.prototype, "outputValues$", {
        get: function () {
            return this._sharedOutputValues$;
        },
        enumerable: true,
        configurable: true
    });
    return ViewContext;
}());
export { ViewContext };
var ListItemViewContext = /** @class */ (function (_super) {
    __extends(ListItemViewContext, _super);
    function ListItemViewContext(currentEnvironmentStore, viewContext) {
        var _this = _super.call(this, currentEnvironmentStore) || this;
        _this.viewContext = viewContext;
        _this.parent = viewContext;
        return _this;
    }
    return ListItemViewContext;
}(ViewContext));
export { ListItemViewContext };
