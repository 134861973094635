<form [formGroup]="form" (ngSubmit)="submit()">
  <app-customize-bar-header
    [title]="
      form.controls.title.value || title || field?.verboseName || field?.name || (titleEditable ? 'field' : 'Title')
    "
    [titleEnabled]="titleEnabled"
    [titleEditable]="titleEditable"
    [titleCleanValue]="getTitleCleanFn()"
    [backEnabled]="backEnabled"
    [backLabel]="backLabel"
    [deleteEnabled]="deleteEnabled"
    [parentLabel]="parentElement?.displayName || parentPopup?.displayName"
    (parentClick)="parentElement?.customize({ event: $event }) || parentPopup?.customize({ event: $event })"
    (deleteClick)="delete()"
    (titleChanged)="onTitleChanged($event)"
  >
  </app-customize-bar-header>

  <div class="sidebar__content">
    <app-sidebar-tabs>
      <app-sidebar-tab [label]="'General'">
        <app-sidebar-section>
          <div *ngIf="parentForm && rootComponent" class="sidebar__element">
            <app-sidebar-field>
              <div
                class="sidebar-list-item sidebar-list-item_orange sidebar-list-item_clickable"
                (click)="editForm($event)"
              >
                <div class="sidebar-list-item__left">
                  <span class="sidebar-list-item__icon icon-cursor"></span>
                </div>
                <div class="sidebar-list-item__main">
                  <div class="sidebar-list-item__title">Connected Form</div>
                  <div class="sidebar-list-item__description">Submit action, Initial data</div>
                </div>
                <div class="sidebar-list-item__right">
                  <span class="sidebar-list-item__action icon-arrow_forward_2"></span>
                </div>
              </div>
            </app-sidebar-field>
          </div>

          <div *ngIf="configurable.field && !configurable.lookup && !configurable.aggregate" class="sidebar__element">
            <app-sidebar-field>
              <app-field-type-field
                [form]="form"
                [label]="false"
                [field]="createField({ name: 'field', params: { classes: ['select_fill'] } })"
              ></app-field-type-field>
            </app-sidebar-field>
          </div>

          <div *ngIf="configurable.lookup" class="sidebar__element">
            <app-sidebar-field [label]="'Display related field'">
              <mat-menu class="mat-menu-panel_wide" #set_lookup_menu="matMenu">
                <ng-template matMenuContent let-trigger="trigger">
                  <div style="min-width: 300px;">
                    <app-select-model-field
                      [modelDescription]="modelDescription"
                      [searchPlaceholder]="'Search field...'"
                      [onlyNestedFields]="true"
                      [relationsSelectEnabled]="false"
                      (selected)="setLookupPath($event); trigger.closeMenu()"
                      (click)="$event.stopPropagation()"
                    ></app-select-model-field>
                  </div>
                </ng-template>
              </mat-menu>

              <div
                class="select select_theme_jet_orange select_fill"
                [matMenuTriggerFor]="set_lookup_menu"
                [matMenuTriggerData]="{ trigger: trigger }"
                #trigger="matMenuTrigger"
              >
                <div class="select__input" [class.select__input_opened]="trigger.menuOpen">
                  <span class="select__input-icon icon-lookup"></span>
                  <span class="select__input-label">
                    <ng-container *ngIf="lookupPath?.length">
                      <ng-container *ngFor="let item of lookupPath; let last = last">
                        {{ item }} <span *ngIf="!last" class="icon-arrow_forward_2"></span>
                      </ng-container>
                    </ng-container>

                    <ng-container *ngIf="!lookupPath?.length">
                      Choose lookup
                    </ng-container>
                  </span>
                  <span class="select__input-arrow"></span>
                </div>
              </div>
            </app-sidebar-field>
          </div>

          <ng-container *ngIf="configurable.aggregate">
            <div class="sidebar__element">
              <app-sidebar-field [label]="'Summarize the values of'">
                <mat-menu class="mat-menu-panel_wide" #set_aggregation_menu="matMenu">
                  <ng-template matMenuContent let-trigger="trigger">
                    <div style="min-width: 300px;">
                      <app-select-model-field
                        [modelDescription]="modelDescription"
                        [searchPlaceholder]="'Search field...'"
                        [onlyNestedFields]="true"
                        [relationsEnabled]="true"
                        [fieldsSelectEnabled]="false"
                        (selected)="setAggregationPath($event); trigger.closeMenu()"
                        (click)="$event.stopPropagation()"
                      ></app-select-model-field>
                    </div>
                  </ng-template>
                </mat-menu>

                <div
                  class="select select_theme_jet_orange select_fill"
                  [matMenuTriggerFor]="set_aggregation_menu"
                  [matMenuTriggerData]="{ trigger: trigger }"
                  #trigger="matMenuTrigger"
                >
                  <div class="select__input" [class.select__input_opened]="trigger.menuOpen">
                    <span class="select__input-icon icon-spiral"></span>
                    <span class="select__input-label">
                      <ng-container *ngIf="aggregatePath?.length">
                        <ng-container *ngFor="let item of aggregatePath; let last = last">
                          {{ item }} <span *ngIf="!last" class="icon-arrow_forward_2"></span>
                        </ng-container>
                      </ng-container>

                      <ng-container *ngIf="!aggregatePath?.length">
                        Choose related collection
                      </ng-container>
                    </span>
                    <span class="select__input-arrow"></span>
                  </div>
                </div>
              </app-sidebar-field>
            </div>

            <div class="sidebar__element">
              <app-sidebar-field [label]="'By calculating'">
                <mat-menu #column_menu="matMenu">
                  <ng-template matMenuContent let-func="func">
                    <button
                      *ngFor="let item of aggregateColumnOptions"
                      mat-menu-item
                      [disableRipple]="true"
                      (click)="
                        form.controls.aggregate_func.patchValue(func);
                        form.controls.aggregate_column.patchValue(item.value)
                      "
                    >
                      <span
                        class="mat-menu-item-icon"
                        [ngClass]="[item.icon ? 'icon-' + item.icon : 'icon-circle']"
                      ></span>
                      {{ item.name }}
                    </button>
                    <div *ngIf="!aggregateColumnOptions.length" class="mat-menu-item-message">
                      No fields
                    </div>
                  </ng-template>
                </mat-menu>

                <mat-menu #func_menu="matMenu">
                  <ng-template matMenuContent>
                    <ng-container *ngFor="let item of form.aggregateFuncOptions">
                      <button
                        *ngIf="item.hasField"
                        mat-menu-item
                        [disableRipple]="true"
                        [matMenuTriggerFor]="column_menu"
                        [matMenuTriggerData]="{ func: item.value }"
                      >
                        {{ item.name }}
                      </button>

                      <button
                        *ngIf="!item.hasField"
                        mat-menu-item
                        [disableRipple]="true"
                        (click)="
                          form.controls.aggregate_func.patchValue(item.value);
                          form.controls.aggregate_column.patchValue(undefined)
                        "
                      >
                        {{ item.name }}
                      </button>
                    </ng-container>
                  </ng-template>
                </mat-menu>

                <div
                  class="select select_theme_jet_orange select_fill"
                  [matMenuTriggerFor]="func_menu"
                  #y_func_trigger="matMenuTrigger"
                >
                  <a
                    href="javascript:void(0)"
                    class="select__input"
                    [class.select__input_opened]="y_func_trigger.menuOpen"
                  >
                    <span class="select__input-label">
                      <ng-container *ngIf="aggregateFuncLabel | appIsSet">
                        {{ aggregateFuncLabel }}
                      </ng-container>

                      <ng-container *ngIf="!(aggregateFuncLabel | appIsSet)">
                        Choose
                      </ng-container>
                    </span>
                    <span class="select__input-arrow"></span>
                  </a>
                </div>
              </app-sidebar-field>
            </div>
          </ng-container>

          <ng-container *ngIf="form.editableField && !configurable.editableOnly">
            <div class="sidebar__element">
              <app-sidebar-field>
                <div class="sidebar-icon-buttons sidebar-icon-buttons_border">
                  <div
                    class="sidebar-icon-button"
                    [class.sidebar-icon-button_active]="form.controls.editable.value"
                    (click)="form.controls.editable.setValue(true)"
                  >
                    <div class="sidebar-icon-button__label">Editable</div>
                  </div>
                  <div
                    class="sidebar-icon-button"
                    [class.sidebar-icon-button_active]="!form.controls.editable.value"
                    (click)="form.controls.editable.setValue(false)"
                  >
                    <div class="sidebar-icon-button__label">View-only</div>
                  </div>
                </div>
              </app-sidebar-field>
            </div>
          </ng-container>

          <div *ngIf="configurable.verboseName" class="sidebar__element">
            <app-sidebar-field [label]="'label'" [additional]="'(optional)'">
              <ng-container *ngIf="configurable.elementStyles" actions>
                <app-text-style-edit-field-action
                  [control]="form.controls.element_styles.controls.label_style"
                ></app-text-style-edit-field-action>
              </ng-container>

              <app-auto-field
                [form]="form"
                [label]="false"
                [field]="createField({ name: 'verbose_name', field: 'CharField', params: { classes: ['input_fill'] } })"
              ></app-auto-field>
            </app-sidebar-field>
          </div>

          <div *ngIf="configurable.value" class="sidebar__element">
            <app-customize-bar-input-edit
              [label]="'Value'"
              [additional]="'(optional)'"
              [documentation]="'inputs'"
              [documentationLabel]="'See how it works'"
              [documentationSource]="object + '_value'"
              [itemForm]="form.controls.value_input"
              [context]="context"
              [contextElement]="contextElement"
              [contextElementPath]="contextElementPath"
              [contextElementPaths]="contextElementPaths"
              [bindComponents]="!!element"
              [element]="element"
              [staticValueField]="form.value['field']"
              [staticValueParams]="form.value['params']"
              [placeholder]="configurable.editable && form.controls.editable.value ? 'Default value' : 'Text'"
              [fill]="true"
              [analyticsSource]="object + '_value'"
            ></app-customize-bar-input-edit>
          </div>
        </app-sidebar-section>

        <div *ngIf="dataParamsComponentData || viewParamsComponentData" class="sidebar-section-border"></div>

        <app-dynamic-component *ngIf="dataParamsComponentData" [componentData]="dataParamsComponentData">
        </app-dynamic-component>
        <app-dynamic-component *ngIf="viewParamsComponentData" [componentData]="viewParamsComponentData">
        </app-dynamic-component>
      </app-sidebar-tab>

      <app-sidebar-tab
        *ngIf="configurable.editable || visibleEditable || disableEditable || configurable.elementStyles"
        [label]="'Display'"
      >
        <app-sidebar-section>
          <div *ngIf="configurable.labelAdditional" class="sidebar__element">
            <app-sidebar-field [label]="'label additional'">
              <ng-container *ngIf="configurable.elementStyles" actions>
                <app-text-style-edit-field-action
                  [control]="form.controls.element_styles.controls.label_additional_style"
                ></app-text-style-edit-field-action>
              </ng-container>

              <app-auto-field
                [form]="form"
                [label]="false"
                [field]="
                  createField({
                    name: 'label_additional',
                    field: 'CharField',
                    placeholder: 'Displayed after field label',
                    reset_enabled: true,
                    params: { classes: ['input_fill'] }
                  })
                "
              ></app-auto-field>
            </app-sidebar-field>
          </div>

          <ng-container *ngIf="configurable.editable && form.controls.editable.value">
            <div class="sidebar__element">
              <app-sidebar-field [label]="'placeholder'">
                <app-auto-field
                  [form]="form"
                  [label]="false"
                  [field]="
                    createField({
                      name: 'placeholder',
                      field: 'CharField',
                      placeholder: 'Field description',
                      reset_enabled: true,
                      params: { classes: ['input_fill'] }
                    })
                  "
                ></app-auto-field>
              </app-sidebar-field>
            </div>

            <ng-container *ngIf="form.editableField">
              <ng-container *ngIf="configurable.required && form.controls.editable.value">
                <div class="sidebar__element">
                  <app-sidebar-field [label]="'required'" [layoutLeft]="true">
                    <app-auto-field
                      [form]="form"
                      [label]="false"
                      [field]="
                        createField({
                          name: 'required',
                          label: 'required',
                          field: 'BooleanField',
                          params: { style: booleanFieldStyle.Toggle, orange: true }
                        })
                      "
                    ></app-auto-field>
                  </app-sidebar-field>
                </div>

                <div class="sidebar__element">
                  <app-sidebar-field
                    *ngIf="form.isResetEnabledSupported()"
                    [label]="'show clear button'"
                    [layoutLeft]="true"
                  >
                    <app-auto-field
                      [form]="form"
                      [label]="false"
                      [field]="
                        createField({
                          name: 'reset_enabled',
                          field: 'BooleanField',
                          params: { style: booleanFieldStyle.Toggle, orange: true }
                        })
                      "
                    ></app-auto-field>
                  </app-sidebar-field>
                </div>

                <div class="sidebar__element">
                  <app-sidebar-field [label]="'Validate'">
                    <app-custom-select
                      [control]="form.controls.validator_type"
                      [items]="form.validatorOptions"
                      [classes]="[
                        'select_fill',
                        form.isValidatorTypeHasOption(form.value['validator_type']) ? 'select_segment-top' : ''
                      ]"
                      [panelClasses]="['mat-menu-panel_sidebar']"
                      [allowSearch]="false"
                    >
                    </app-custom-select>

                    <app-auto-field
                      *ngIf="form.value['validator_type'] === validatorTypes.Length"
                      [form]="form"
                      [label]="false"
                      [field]="
                        createField({
                          name: 'validator_length',
                          field: 'CharField',
                          placeholder: 'Length',
                          params: { classes: ['input_fill', 'input_segment-bottom'] }
                        })
                      "
                    ></app-auto-field>

                    <app-auto-field
                      *ngIf="form.value['validator_type'] === validatorTypes.MaxLength"
                      [form]="form"
                      [label]="false"
                      [field]="
                        createField({
                          name: 'validator_max_length',
                          field: 'CharField',
                          placeholder: 'Max length',
                          params: { classes: ['input_fill', 'input_segment-bottom'] }
                        })
                      "
                    ></app-auto-field>

                    <app-auto-field
                      *ngIf="form.value['validator_type'] === validatorTypes.MinLength"
                      [form]="form"
                      [label]="false"
                      [field]="
                        createField({
                          name: 'validator_min_length',
                          field: 'CharField',
                          placeholder: 'Min length',
                          params: { classes: ['input_fill', 'input_segment-bottom'] }
                        })
                      "
                    ></app-auto-field>

                    <ng-container *ngIf="form.value['validator_type'] === validatorTypes.LengthRange">
                      <div style="display: flex; align-items: flex-start;">
                        <input
                          type="text"
                          [formControl]="form.controls.validator_min_length"
                          class="input input_fill input_segment-bottom-left"
                          placeholder="Min length"
                        />
                        <input
                          type="text"
                          [formControl]="form.controls.validator_max_length"
                          class="input input_fill input_segment-bottom-right"
                          placeholder="Max length"
                        />
                      </div>
                      <app-field-errors [form]="form" [fieldName]="'validator_min_length'"></app-field-errors>
                      <app-field-errors
                        *ngIf="form.controls.validator_min_length.valid"
                        [form]="form"
                        [fieldName]="'validator_max_length'"
                      ></app-field-errors>
                    </ng-container>

                    <ng-container *ngIf="form.value['validator_type'] === validatorTypes.ValueRange">
                      <div style="display: flex; align-items: flex-start;">
                        <input
                          type="text"
                          [formControl]="form.controls.validator_min_value"
                          class="input input_fill input_segment-bottom-left"
                          placeholder="Min value"
                        />
                        <input
                          type="text"
                          [formControl]="form.controls.validator_max_value"
                          class="input input_fill input_segment-bottom-right"
                          placeholder="Max value"
                        />
                      </div>
                      <app-field-errors [form]="form" [fieldName]="'validator_min_value'"></app-field-errors>
                      <app-field-errors
                        *ngIf="form.controls.validator_min_length.valid"
                        [form]="form"
                        [fieldName]="'validator_max_value'"
                      ></app-field-errors>
                    </ng-container>

                    <app-auto-field
                      *ngIf="form.value['validator_type'] === validatorTypes.Custom"
                      [form]="form"
                      [label]="false"
                      [field]="
                        createField({
                          name: 'validator_custom',
                          field: 'CharField',
                          placeholder: 'Regular expression',
                          params: { classes: ['input_fill', 'input_segment-bottom'] }
                        })
                      "
                    ></app-auto-field>

                    <app-input-edit
                      *ngIf="form.value['validator_type'] === validatorTypes.CustomInput"
                      [itemForm]="form.controls.validator_custom_input"
                      [context]="context"
                      [contextElement]="contextElement"
                      [contextElementPath]="contextElementPath"
                      [contextElementPaths]="contextElementPaths"
                      [staticValueDisabled]="true"
                      [formulaPlaceholder]="'value > 3'"
                      [jsPlaceholder]="'return value > 3;'"
                      [fill]="true"
                      [classes]="['code-editor__main-code_field-segment-bottom']"
                      [analyticsSource]="object + '_validator'"
                    ></app-input-edit>
                  </app-sidebar-field>

                  <app-field-errors [form]="form" [fieldName]="'validator_type'"></app-field-errors>
                </div>

                <div *ngIf="form.isValidatorTypeHasError(form.value['validator_type'])" class="sidebar__element">
                  <app-sidebar-field [label]="'Custom validation error'">
                    <app-auto-field
                      [form]="form"
                      [label]="false"
                      [field]="
                        createField({
                          name: 'validator_error',
                          field: 'CharField',
                          placeholder: 'Error message',
                          params: { classes: ['input_fill'] }
                        })
                      "
                    ></app-auto-field>
                  </app-sidebar-field>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>

          <div *ngIf="visibleEditable" class="sidebar__element">
            <app-sidebar-field
              [label]="'Conditional Visibility'"
              [documentation]="'conditional-visibility'"
              [documentationLabel]="'See how it works'"
              [documentationSource]="object + '_visible'"
            >
              <app-input-edit
                [itemForm]="form.controls.visible_input"
                [context]="context"
                [contextElement]="contextElement"
                [contextElementPath]="contextElementPath"
                [contextElementPaths]="contextElementPaths"
                [staticValueDisabled]="true"
                [placeholder]="'Value'"
                [fill]="true"
                [analyticsSource]="object + '_visible'"
              ></app-input-edit>

              <ng-container description>
                Example: elements.field.value >= 10
              </ng-container>
            </app-sidebar-field>
          </div>

          <div *ngIf="disableEditable && form.controls.editable.value" class="sidebar__element">
            <app-sidebar-field [label]="'Conditional Disable'" [documentationSource]="object + '_disable'">
              <app-input-edit
                [itemForm]="form.controls.disable_input"
                [context]="context"
                [contextElement]="contextElement"
                [contextElementPath]="contextElementPath"
                [contextElementPaths]="contextElementPaths"
                [staticValueDisabled]="true"
                [placeholder]="'Value'"
                [fill]="true"
                [analyticsSource]="object + '_disable'"
              ></app-input-edit>

              <ng-container description>
                Example: elements.type.value == 'Refund'
              </ng-container>
            </app-sidebar-field>
          </div>

          <div *ngIf="tooltipEditable" class="sidebar__element">
            <app-sidebar-field [label]="'tooltip'">
              <app-auto-field
                [form]="form"
                [label]="false"
                [field]="
                  createField({
                    name: 'tooltip',
                    field: 'CharField',
                    placeholder: 'Show information for users',
                    reset_enabled: true,
                    params: { classes: ['input_fill'] }
                  })
                "
              ></app-auto-field>
            </app-sidebar-field>
          </div>

          <div *ngIf="configurable.elementStyles" class="sidebar__element">
            <app-styles-field-element
              [control]="form.controls.element_styles"
              [field]="form.controls.field | appControlValue | async"
            ></app-styles-field-element>
          </div>
        </app-sidebar-section>
      </app-sidebar-tab>

      <app-sidebar-tab
        *ngIf="configurable.action || configurable.onChangeActions"
        [label]="'Actions'"
        [error]="(actionsValid$ | async) !== false ? undefined : 'Not configured'"
      >
        <app-sidebar-section>
          <div class="sidebar__list">
            <ng-container *ngIf="configurable.action">
              <div class="sidebar-list__separator">
                <app-sidebar-list-separator
                  [title]="actionsLabels?.title || 'Field actions'"
                ></app-sidebar-list-separator>
              </div>
              <div class="sidebar-list">
                <div class="sidebar-list__item">
                  <app-actions-edit
                    [options]="{
                      actionItemClass: viewSettingsActionClass,
                      nameEditable: true,
                      iconEditable: true,
                      approveEnabled: true,
                      confirmationEnabled: true,
                      completionEditable: true,
                      disabledEditable: true,
                      visibleEditable: true,
                      originEnabled: true
                    }"
                    [control]="form.controls.actions"
                    [context]="context"
                    [contextElement]="contextElement"
                    [contextElementPath]="contextElementPath"
                    [contextElementPaths]="contextElementPaths"
                    [emptyAction]="actionsLabels?.title || 'Field action'"
                    [emptyActionValue]="'Not specified'"
                    [emptyIcon]="'fileds'"
                    [actionLabel]="actionsLabels?.title || 'Field action click'"
                    [object]="object"
                    [firstInit]="firstInit"
                  >
                  </app-actions-edit>
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="configurable.elementActions">
              <ng-container
                *ngIf="
                  [fieldTypes.Select, fieldTypes.MultipleSelect, fieldTypes.RelatedModel].includes(
                    form.controls.field.value
                  )
                "
              >
                <div class="sidebar-list__separator">
                  <app-sidebar-list-separator [title]="'Dropdown top actions'"></app-sidebar-list-separator>
                </div>
                <div class="sidebar-list">
                  <div class="sidebar-list__item">
                    <app-actions-edit
                      [options]="{
                        actionItemClass: viewSettingsActionClass,
                        nameEditable: true,
                        iconEditable: true,
                        colorsEditable: true,
                        approveEnabled: true,
                        confirmationEnabled: true,
                        completionEditable: true,
                        disabledEditable: true,
                        visibleEditable: true,
                        originEnabled: true
                      }"
                      [control]="form.controls.top_actions"
                      [context]="context"
                      [contextElement]="contextElement"
                      [contextElementPaths]="itemContextElementPaths"
                      [emptyAction]="'Top action'"
                      [emptyActionValue]="'Not specified'"
                      [emptyIcon]="'hand'"
                      [actionLabel]="'Top action'"
                      [object]="object"
                      [firstInit]="firstInit"
                    >
                    </app-actions-edit>
                  </div>
                </div>
                <div class="sidebar-list__separator">
                  <app-sidebar-list-separator [title]="'Dropdown bottom actions'"></app-sidebar-list-separator>
                </div>
                <div class="sidebar-list">
                  <div class="sidebar-list__item">
                    <app-actions-edit
                      [options]="{
                        actionItemClass: viewSettingsActionClass,
                        nameEditable: true,
                        iconEditable: true,
                        colorsEditable: true,
                        approveEnabled: true,
                        confirmationEnabled: true,
                        completionEditable: true,
                        disabledEditable: true,
                        visibleEditable: true,
                        originEnabled: true
                      }"
                      [control]="form.controls.bottom_actions"
                      [context]="context"
                      [contextElement]="contextElement"
                      [contextElementPaths]="itemContextElementPaths"
                      [emptyAction]="'Bottom action'"
                      [emptyActionValue]="'Not specified'"
                      [emptyIcon]="'hand'"
                      [actionLabel]="'Bottom action'"
                      [object]="object"
                      [firstInit]="firstInit"
                    >
                    </app-actions-edit>
                  </div>
                </div>
              </ng-container>
            </ng-container>

            <ng-container *ngIf="configurable.onChangeActions">
              <div class="sidebar-list__separator">
                <app-sidebar-list-separator [title]="'When value changes'"></app-sidebar-list-separator>
              </div>
              <div class="sidebar-list">
                <div class="sidebar-list__item">
                  <app-actions-edit
                    [control]="form.controls.on_change_actions"
                    [context]="context"
                    [contextElement]="contextElement"
                    [contextElementPaths]="itemContextElementPaths"
                    [emptyAction]="'Value changes'"
                    [emptyActionValue]="'Not specified'"
                    [emptyIcon]="'console'"
                    [actionLabel]="'Value changes action'"
                    [object]="object"
                    [firstInit]="firstInit"
                  >
                  </app-actions-edit>
                </div>
              </div>
            </ng-container>
          </div>
        </app-sidebar-section>
      </app-sidebar-tab>
    </app-sidebar-tabs>
  </div>

  <app-sidebar-submit
    *ngIf="submitEnabled"
    [saveDisabled]="form.invalid || form.disabled"
    [saveLoading]="submitLoading"
    [saveLabel]="submitLabel"
    [errors]="form | appFormErrors"
    (cancelClick)="cancel()"
  >
  </app-sidebar-submit>

  <ng-container *ngIf="!submitEnabled">
    <app-sidebar-submit
      *ngIf="hasBack"
      [saveEnabled]="false"
      [saveLoading]="submitLoading"
      [saveLabel]="submitLabel"
      [cancelEnabled]="false"
      [backEnabled]="true"
      [errors]="form | appFormErrors"
      (cancelClick)="cancel()"
    >
    </app-sidebar-submit>

    <!--    <app-sidebar-submit-->
    <!--      *ngIf="!hasBack"-->
    <!--      [saveDisabled]="form.invalid || form.disabled"-->
    <!--      [saveLoading]="submitLoading"-->
    <!--      [errors]="form | appFormErrors"-->
    <!--      (cancelClick)="cancel()"-->
    <!--    >-->
    <!--    </app-sidebar-submit>-->
  </ng-container>
</form>
