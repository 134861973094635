import { ChangeDetectorRef, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import toPairs from 'lodash/toPairs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';
import { ProjectSettingsStore } from '@modules/all-project-settings';
import { ThemeService } from '@modules/theme';
import { isSet } from '@shared';
import { MenuSecondary } from '../../components/menu-secondary/menu-secondary.component';
var MenuBlockDropdownStyleDirective = /** @class */ (function () {
    function MenuBlockDropdownStyleDirective(projectSettingsStore, themeService, sanitizer, el, cd) {
        this.projectSettingsStore = projectSettingsStore;
        this.themeService = themeService;
        this.sanitizer = sanitizer;
        this.el = el;
        this.cd = cd;
        this.menuIsUnset = false;
        this.menuIsLight = false;
    }
    Object.defineProperty(MenuBlockDropdownStyleDirective.prototype, "unsetCls", {
        get: function () {
            return this.menuIsUnset;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MenuBlockDropdownStyleDirective.prototype, "lightCls", {
        get: function () {
            return this.menuIsLight === true;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MenuBlockDropdownStyleDirective.prototype, "darkCls", {
        get: function () {
            return this.menuIsLight === false;
        },
        enumerable: true,
        configurable: true
    });
    MenuBlockDropdownStyleDirective.prototype.ngOnInit = function () {
        var _this = this;
        combineLatest(this.projectSettingsStore.getAllSettings$(), this.themeService.isDarkTheme$)
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var projectSettings = _a[0], isDarkTheme = _a[1];
            var backgroundColor = isDarkTheme ? projectSettings.backgroundColor2Dark : projectSettings.backgroundColor2;
            _this.menuIsUnset = !isSet(backgroundColor);
            _this.menuIsLight = MenuSecondary.isLight(backgroundColor);
            _this.menuStyle = _this.getStyleVars(undefined, backgroundColor);
            _this.cd.markForCheck();
        });
    };
    MenuBlockDropdownStyleDirective.prototype.ngOnDestroy = function () { };
    MenuBlockDropdownStyleDirective.prototype.getStyleVars = function (accentColor, backgroundColor) {
        var vars = MenuSecondary.getVars(accentColor, backgroundColor);
        var styles = toPairs(vars)
            .map(function (_a) {
            var k = _a[0], v = _a[1];
            return "--" + k + ": " + v;
        })
            .join(';');
        return this.sanitizer.bypassSecurityTrustStyle(styles);
    };
    return MenuBlockDropdownStyleDirective;
}());
export { MenuBlockDropdownStyleDirective };
