/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../common/tips/directives/tip/tip.directive";
import * as i2 from "@angular/common";
import * as i3 from "../../../../shared/pipes/is-set/is-set.pipe";
import * as i4 from "./input-edit-result.component";
var styles_InputEditResultComponent = [];
var RenderType_InputEditResultComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_InputEditResultComponent, data: {} });
export { RenderType_InputEditResultComponent as RenderType_InputEditResultComponent };
function View_InputEditResultComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "input-footer__indicator input-footer__indicator_error icon-warning_filled"]], [[4, "bottom", "px"]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.inputFocus.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 737280, null, 0, i1.TipDirective, [i0.ElementRef, i0.ComponentFactoryResolver, i0.Injector, i0.ApplicationRef], { content: [0, "content"], optionsRaw: [1, "optionsRaw"] }, null), i0.ɵpod(2, { side: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.errorDescription; var currVal_2 = _ck(_v, 2, 0, "top"); _ck(_v, 1, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.indicatorBottom; _ck(_v, 0, 0, currVal_0); }); }
function View_InputEditResultComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [["class", "input-footer__message-info icon-help"]], null, null, null, null, null)), i0.ɵdid(1, 737280, null, 0, i1.TipDirective, [i0.ElementRef, i0.ComponentFactoryResolver, i0.Injector, i0.ApplicationRef], { content: [0, "content"], optionsRaw: [1, "optionsRaw"] }, null), i0.ɵpod(2, { side: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errorDescription; var currVal_1 = _ck(_v, 2, 0, "top"); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_InputEditResultComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "input-footer__message input-footer__message_error"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "input-footer__message-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Result"])), (_l()(), i0.ɵeld(3, 0, null, null, 3, "div", [["class", "input-footer__message-row input-footer__message-row_wrap"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_InputEditResultComponent_4)), i0.ɵdid(5, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(6, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errorDescription; _ck(_v, 5, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.error; _ck(_v, 6, 0, currVal_1); }); }
function View_InputEditResultComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_InputEditResultComponent_2)), i0.ɵdid(2, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_InputEditResultComponent_3)), i0.ɵdid(4, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.visible; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.visible; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_InputEditResultComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i3.IsSetPipe, []), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_InputEditResultComponent_1)), i0.ɵdid(2, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵppd(3, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i0.ɵnov(_v, 0), _co.error)); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_InputEditResultComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-input-edit-result", [], null, null, null, View_InputEditResultComponent_0, RenderType_InputEditResultComponent)), i0.ɵdid(1, 638976, null, 0, i4.InputEditResultComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InputEditResultComponentNgFactory = i0.ɵccf("app-input-edit-result", i4.InputEditResultComponent, View_InputEditResultComponent_Host_0, { value: "value", error: "error", errorDescription: "errorDescription", focus: "focus", showOnFocus: "showOnFocus", indicatorBottom: "indicatorBottom" }, { inputFocus: "inputFocus" }, []);
export { InputEditResultComponentNgFactory as InputEditResultComponentNgFactory };
