var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, EventEmitter, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import toPairs from 'lodash/toPairs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { map, switchMap, throttleTime } from 'rxjs/operators';
import { FillEditContext } from '@modules/colors-components';
import { Frame } from '@modules/customize';
import { MenuBlockLayout, MenuBlockLayouts, MenuGeneratorService, MenuItemType } from '@modules/menu';
import { MenuContext, MenuPrimary, MenuSecondary } from '@modules/menu-components';
import { ThemeService } from '@modules/theme';
import { CurrentUserStore } from '@modules/users';
import { controlValue, elementResize$, isSet } from '@shared';
import { MenuBlockControl } from '../project-settings/menu-block.control';
import { MenuUpdateForm } from '../project-settings/menu-update.form';
import { ProjectAppearanceForm } from '../project-settings/project-appearance.form';
export var AdminPreviewType;
(function (AdminPreviewType) {
    AdminPreviewType["Default"] = "default";
    AdminPreviewType["Buttons"] = "buttons";
    AdminPreviewType["Fields"] = "fields";
})(AdminPreviewType || (AdminPreviewType = {}));
var AdminTemplateComponent = /** @class */ (function () {
    function AdminTemplateComponent(context, fillEditContext, currentUserStore, menuGeneratorService, themeService, sanitizer, cd) {
        this.context = context;
        this.fillEditContext = fillEditContext;
        this.currentUserStore = currentUserStore;
        this.menuGeneratorService = menuGeneratorService;
        this.themeService = themeService;
        this.sanitizer = sanitizer;
        this.cd = cd;
        this.contentDisabled = false;
        this.preview = AdminPreviewType.Default;
        this.blockControlPreviewHover = new EventEmitter();
        this.blockControlPreviewClick = new EventEmitter();
        this.leftMenuBlocks = [];
        this.topMenuBlocks = [];
        this.topContentMenuBlocks = [];
        this.externalFonts = [];
        this.blockControlHover$ = new BehaviorSubject(undefined);
        this.blockControlActive$ = new BehaviorSubject(undefined);
        this.blockControlPreviewHover$ = new BehaviorSubject(undefined);
        this.previewTypes = AdminPreviewType;
    }
    AdminTemplateComponent.prototype.trackMenuBlockItemFn = function (i, item) {
        return item.control.instance.uid;
    };
    AdminTemplateComponent.prototype.trackMenuItemFn = function (i, item) {
        return item.id;
    };
    AdminTemplateComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.appearanceForm.valueChanges
            .pipe(throttleTime(200), untilDestroyed(this))
            .subscribe(function () { return _this.cd.markForCheck(); });
        controlValue(this.menuForm.controls.blocks, {
            debounce: function () {
                return _this.fillEditContext && _this.fillEditContext.customizingGradient$.value ? 0 : 200;
            }
        })
            .pipe(switchMap(function () {
            return combineLatest(_this.menuForm.controls.blocks.controls.map(function (control) {
                return control
                    .getEnabled$({ context: _this.context })
                    .pipe(map(function (enabled) { return ({ control: control, enabled: enabled }); }));
            })).pipe(map(function (items) { return items.filter(function (item) { return item.enabled; }).map(function (item) { return item.control; }); }));
        }), switchMap(function (menuBlockControls) {
            var backgroundColor$ = combineLatest(_this.themeService.isDarkTheme$, controlValue(_this.appearanceForm.controls.background_color), controlValue(_this.appearanceForm.controls.background_color_dark)).pipe(map(function (_a) {
                var isDarkTheme = _a[0], backgroundColor = _a[1], backgroundColorDark = _a[2];
                return isDarkTheme ? backgroundColorDark : backgroundColor;
            }));
            return combineLatest(of(menuBlockControls), _this.themeService.isDarkTheme$, backgroundColor$);
        }), untilDestroyed(this))
            .subscribe(function (_a) {
            var menuBlockControls = _a[0], isDarkTheme = _a[1], backgroundColor = _a[2];
            var menuBlocks = menuBlockControls.map(function (control) {
                var block = control.serialize();
                var fill = isDarkTheme
                    ? control.controls.fill_settings.controls.fill_dark
                        .getInstance()
                        .css({ frame: new Frame({ width: 320, height: 240 }) })
                    : control.controls.fill_settings.controls.fill
                        .getInstance()
                        .css({ frame: new Frame({ width: 320, height: 240 }) });
                var borderTop;
                var borderRight;
                var borderBottom;
                var borderLeft;
                if (block.borderSettings && block.borderSettings.isSidesSet()) {
                    borderTop =
                        block.borderSettings && block.borderSettings.borderTop
                            ? _this.sanitizer.bypassSecurityTrustStyle(block.borderSettings.borderTop.cssBorder(isDarkTheme))
                            : undefined;
                    borderRight =
                        block.borderSettings && block.borderSettings.borderRight
                            ? _this.sanitizer.bypassSecurityTrustStyle(block.borderSettings.borderRight.cssBorder(isDarkTheme))
                            : undefined;
                    borderBottom =
                        block.borderSettings && block.borderSettings.borderBottom
                            ? _this.sanitizer.bypassSecurityTrustStyle(block.borderSettings.borderBottom.cssBorder(isDarkTheme))
                            : undefined;
                    borderLeft =
                        block.borderSettings && block.borderSettings.borderLeft
                            ? _this.sanitizer.bypassSecurityTrustStyle(block.borderSettings.borderLeft.cssBorder(isDarkTheme))
                            : undefined;
                }
                else {
                    borderTop = borderRight = borderBottom = borderLeft =
                        block.borderSettings && block.borderSettings.border
                            ? _this.sanitizer.bypassSecurityTrustStyle(block.borderSettings.border.cssBorder(isDarkTheme))
                            : undefined;
                }
                var width = control.controls.width.value;
                var height = control.controls.height.value;
                var firstMenuItem = block.getAllItems().filter(function (item) { return [MenuItemType.Simple].includes(item.type); })[0];
                var isLight = MenuPrimary.isLight(fill.accentColor || backgroundColor);
                var horizontal = MenuBlockLayouts.isHorizontal(control.controls.layout.value);
                var primary = MenuBlockLayouts.isPrimary(control.controls.layout.value);
                var style = _this.getMenuStyle(__assign({ horizontal: horizontal, primary: primary, accentColor: fill.accentColor, backgroundColor: backgroundColor, background: fill.background, width: width, height: height }, (control.controls.border_radius.isSet() && {
                    borderRadius: control.controls.border_radius.value
                }), (control.controls.padding.isSet() && {
                    padding: control.controls.padding.value
                })));
                block.startItems = _this.menuGeneratorService.cleanMenuItemsAppMode(block.startItems);
                block.centerItems = _this.menuGeneratorService.cleanMenuItemsAppMode(block.centerItems);
                block.endItems = _this.menuGeneratorService.cleanMenuItemsAppMode(block.endItems);
                return {
                    block: block,
                    control: control,
                    firstMenuItem: firstMenuItem,
                    primary: primary,
                    isLight: isLight,
                    color: fill.accentColor,
                    backgroundColor: backgroundColor,
                    background: _this.sanitizer.bypassSecurityTrustStyle(fill.background),
                    backgroundWidth: fill.width,
                    backgroundHeight: fill.height,
                    backgroundTransform: _this.sanitizer.bypassSecurityTrustStyle(fill.transform),
                    borderTop: borderTop,
                    borderRight: borderRight,
                    borderBottom: borderBottom,
                    borderLeft: borderLeft,
                    borderRadius: control.controls.border_radius.serialize(),
                    style: style
                };
            });
            _this.leftMenuBlocks = menuBlocks.filter(function (item) { return MenuBlockLayouts.isLeft(item.block.layout); });
            _this.topMenuBlocks = menuBlocks.filter(function (item) { return item.block.layout == MenuBlockLayout.TopThin; });
            _this.topContentMenuBlocks = menuBlocks.filter(function (item) { return item.block.layout == MenuBlockLayout.TopContentThin; });
            _this.cd.markForCheck();
        });
        if (this.fillEditContext) {
            this.fillEditContext.customizingGradient$.pipe(untilDestroyed(this)).subscribe(function (value) {
                _this.customizeGradient = value;
                _this.cd.markForCheck();
            });
        }
        combineLatest(controlValue(this.appearanceForm.controls.font_regular), controlValue(this.appearanceForm.controls.font_heading))
            .pipe(untilDestroyed(this))
            .subscribe(function (fonts) {
            _this.externalFonts = fonts.filter(function (item) { return isSet(item); });
            _this.cd.markForCheck();
        });
        combineLatest(this.appearanceForm.controls.action_element_styles_primary.serialize$(), this.appearanceForm.controls.action_element_styles_primary.stylesDefaultUpdated$())
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var elementStyles = _a[0];
            _this.actionElementStylesPrimary = elementStyles;
            _this.cd.markForCheck();
        });
        combineLatest(this.appearanceForm.controls.action_element_styles_default.serialize$(), this.appearanceForm.controls.action_element_styles_default.stylesDefaultUpdated$())
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var elementStyles = _a[0];
            _this.actionElementStylesDefault = elementStyles;
            _this.cd.markForCheck();
        });
        combineLatest(this.appearanceForm.controls.field_element_styles.serialize$(), this.appearanceForm.controls.field_element_styles.stylesDefaultUpdated$())
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var elementStyles = _a[0];
            _this.fieldElementStyles = elementStyles;
            _this.cd.markForCheck();
        });
        this.appearanceForm.controls.element_wrapper_styles
            .serialize$()
            .pipe(untilDestroyed(this))
            .subscribe(function (elementStyles) {
            _this.elementWrapperStyles = elementStyles;
            _this.cd.markForCheck();
        });
        var customizingGradient$ = this.fillEditContext ? this.fillEditContext.customizingGradient$ : of(undefined);
        combineLatest(this.blockControlHover$, this.blockControlActive$, this.blockControlPreviewHover$, customizingGradient$)
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var blockControlHover = _a[0], blockControlActive = _a[1], blockControlPreviewHover = _a[2], customizingGradient = _a[3];
            if (customizingGradient && customizingGradient.source instanceof MenuBlockControl) {
                _this.blockControlFocus = undefined;
            }
            else if (blockControlPreviewHover) {
                _this.blockControlFocus = blockControlPreviewHover;
            }
            else if (blockControlHover && blockControlHover.isVisible()) {
                _this.blockControlFocus = blockControlHover;
            }
            else if (blockControlActive && blockControlActive.isVisible()) {
                _this.blockControlFocus = blockControlActive;
            }
            else {
                _this.blockControlFocus = undefined;
            }
            _this.cd.markForCheck();
        });
        this.blockControlPreviewHover$
            .pipe(untilDestroyed(this))
            .subscribe(function (value) { return _this.blockControlPreviewHover.emit(value); });
        if (this.container) {
            elementResize$(this.container)
                .pipe(untilDestroyed(this))
                .subscribe(function () {
                _this.containerHeight = _this.container ? _this.container.offsetHeight : undefined;
                _this.cd.markForCheck();
            });
        }
    };
    AdminTemplateComponent.prototype.ngOnDestroy = function () { };
    AdminTemplateComponent.prototype.ngOnChanges = function (changes) {
        if (changes.blockControlHover) {
            this.blockControlHover$.next(this.blockControlHover);
        }
        if (changes.blockControlActive) {
            this.blockControlActive$.next(this.blockControlActive);
        }
    };
    AdminTemplateComponent.prototype.getMenuStyle = function (options) {
        if (options === void 0) { options = {}; }
        var vars = options.primary
            ? MenuPrimary.getVars(options.accentColor, options.backgroundColor)
            : MenuSecondary.getVars(options.accentColor, options.backgroundColor);
        var styles = toPairs(vars).map(function (_a) {
            var k = _a[0], v = _a[1];
            return ["--" + k, "" + v];
        });
        if (!options.horizontal && (options.width || options.padding)) {
            var defaultMenuWidth = options.primary ? MenuPrimary.defaultWidth() : MenuSecondary.defaultWidth();
            var width = (options.width || defaultMenuWidth) +
                ((options.padding && options.padding.left) || 0) +
                ((options.padding && options.padding.right) || 0);
            styles.push(['width', width + "px"]);
        }
        if (options.horizontal && (options.height || options.padding)) {
            var defaultMenuHeight = options.primary ? MenuPrimary.defaultHeight() : 0;
            var height = (options.height || defaultMenuHeight) +
                ((options.padding && options.padding.top) || 0) +
                ((options.padding && options.padding.bottom) || 0);
            styles.push(['height', height + "px"]);
        }
        if (options.borderRadius) {
            styles.push(['border-top-left-radius', options.borderRadius + "px"]);
        }
        if (options.borderRadius) {
            styles.push(['border-top-right-radius', options.borderRadius + "px"]);
        }
        if (options.borderRadius) {
            styles.push(['border-bottom-right-radius', options.borderRadius + "px"]);
        }
        if (options.borderRadius) {
            styles.push(['border-bottom-left-radius', options.borderRadius + "px"]);
        }
        if (options.padding && options.padding.top) {
            styles.push(['padding-top', options.padding.top + "px"]);
        }
        if (options.padding && options.padding.right) {
            styles.push(['padding-right', options.padding.right + "px"]);
        }
        if (options.padding && options.padding.bottom) {
            styles.push(['padding-bottom', options.padding.bottom + "px"]);
        }
        if (options.padding && options.padding.left) {
            styles.push(['padding-left', options.padding.left + "px"]);
        }
        return this.sanitizer.bypassSecurityTrustStyle(styles.map(function (_a) {
            var k = _a[0], v = _a[1];
            return k + ": " + v;
        }).join(';'));
    };
    AdminTemplateComponent.prototype.onBlockControlPreviewClick = function (block, e) {
        if (this.customizeGradient) {
            return;
        }
        this.blockControlPreviewClick.next({ control: block.control, event: e });
    };
    AdminTemplateComponent.prototype.asMenuBlockItems = function (value) {
        return value;
    };
    return AdminTemplateComponent;
}());
export { AdminTemplateComponent };
