import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import toPairs from 'lodash/toPairs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, Observable, of } from 'rxjs';
import { debounceTime, first, switchMap } from 'rxjs/operators';

import { NotificationService } from '@common/notifications';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { CustomViewSource } from '@modules/custom-views';
import { cleanElementName, CustomElementItem, ViewContext, ViewContextElement } from '@modules/customize';
import { FieldsEditItem } from '@modules/field-components';
import { createFormFieldFactory, FieldOutput, Input as FieldInput, InputValueType } from '@modules/fields';
import { CurrentProjectStore } from '@modules/projects';
import { QueryBuilderContext, QueryBuilderService } from '@modules/queries-components';
import { ResourceControllerService } from '@modules/resources';
import { CustomViewTemplate, CustomViewTemplateType, View } from '@modules/views';
import { controlValue, isSet } from '@shared';

import { CustomizeBarEditComponent } from '../../data/customize-bar-edit-component';
import { CustomizeBarEditEvent } from '../../data/customize-bar-edit-event';
import { CustomizeBarEditEventType } from '../../data/customize-bar-edit-event-type';
import { CustomizeBarContext } from '../../services/customize-bar-context/customize-bar.context';
import { ElementConfiguration, trackConfigured } from '../../utils/analytics';
import { CustomizeBarCustomEditForm } from './customize-bar-custom-edit.form';

@Component({
  selector: 'app-customize-bar-custom-edit',
  templateUrl: './customize-bar-custom-edit.component.html',
  providers: [CustomizeBarCustomEditForm, QueryBuilderContext],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeBarCustomEditComponent implements OnInit, OnDestroy, CustomizeBarEditComponent {
  @Input() title: string;
  @Input() titleEnabled = true;
  @Input() element: CustomElementItem;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() deleteEnabled = false;
  @Input() titleEditable = false;
  @Input() parentElement: any;
  @Input() parentPopup: any;
  @Input() firstInit = false;
  @Output() event = new EventEmitter<CustomizeBarEditEvent>();

  createField = createFormFieldFactory();
  result: CustomElementItem;
  configurationStarted = false;

  distPreview: string;
  loading = false;
  customViewSources = CustomViewSource;
  customViewTemplateTypes = CustomViewTemplateType;

  titleCleanValue = (() => {
    return (value: string): string => {
      return cleanElementName(value, this.element, this.context.getElementItems());
    };
  })();

  constructor(
    public form: CustomizeBarCustomEditForm,
    public queryContext: QueryBuilderContext,
    private customizeBarContext: CustomizeBarContext,
    private resourceControllerService: ResourceControllerService,
    private queryBuilderService: QueryBuilderService,
    private currentProjectStore: CurrentProjectStore,
    private cd: ChangeDetectorRef,
    private notificationService: NotificationService,
    private analyticsService: UniversalAnalyticsService
  ) {}

  ngOnInit() {
    this.form
      .init(this.element, this.context, this.firstInit)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        const resultObs = this.form.submitChanges.pipe(
          debounceTime(200),
          switchMap(() => this.submit())
        );

        resultObs.pipe(untilDestroyed(this)).subscribe(result => {
          this.result = result;
          this.emitUpdate();
        });

        combineLatest(controlValue(this.form.controls.dist_file), controlValue(this.form.controls.dist))
          .pipe(untilDestroyed(this))
          .subscribe(() => this.updateDistPreview());

        this.form.controls.view.valueChanges.pipe(untilDestroyed(this)).subscribe(() => this.cd.markForCheck());

        resultObs
          .pipe(
            switchMap(result => this.form.isConfigured(result)),
            trackConfigured(),
            first(configuration => configuration == ElementConfiguration.Started),
            untilDestroyed(this)
          )
          .subscribe(() => {
            this.configurationStarted = true;
            this.analyticsService.sendSimpleEvent(AnalyticsEvent.Component.StartedConfiguration, {
              ComponentTypeID: this.element.analyticsName
            });
          });

        if (this.firstInit && !isSet(this.element.customView) && this.element.customViewTemporary) {
          this.save(false);
        }
      });
  }

  ngOnDestroy(): void {}

  close() {
    (this.configurationStarted ? this.form.isConfigured(this.result) : of(false))
      .pipe(untilDestroyed(this))
      .subscribe(configured => {
        if (configured) {
          this.analyticsService.sendSimpleEvent(AnalyticsEvent.Component.SuccessfullyConfigured, {
            ComponentTypeID: this.element.analyticsName
          });
        }

        this.customizeBarContext.popSettingsComponent();
      });
  }

  submit(): Observable<CustomElementItem> {
    return this.form.submit({ submitCustomView: this.form.controls.source.value == CustomViewSource.CustomElement });
  }

  save(close = true) {
    this.submit()
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        this.result = result;
        this.emitUpdate(true);

        if (close) {
          this.close();
        }
      });
  }

  onTitleChanged(title: string) {
    this.title = title;

    this.form
      .submit()
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        this.result = result;
        this.emitUpdate();
      });
  }

  emitUpdate(submit = false) {
    if (this.element) {
      this.event.emit({
        type: CustomizeBarEditEventType.Updated,
        args: { result: this.result, title: this.title, submit: submit }
      });
    } else {
      this.event.emit({
        type: CustomizeBarEditEventType.Created,
        args: { result: this.result, title: this.title, submit: submit }
      });
    }
  }

  cancel() {
    this.event.emit({ type: CustomizeBarEditEventType.Canceled });
    this.close();
  }

  delete() {
    this.event.emit({ type: CustomizeBarEditEventType.Deleted });
    this.close();
  }

  get distFilename() {
    if (!this.distPreview) {
      return;
    }

    return this.distPreview.split('/').pop();
  }

  updateDistPreview() {
    if (this.form.controls.dist_file.value) {
      this.distPreview = this.form.controls.dist_file.value.name;
    } else if (this.form.controls.dist.value instanceof File) {
      this.distPreview = this.form.controls.dist.value.name;
    } else {
      this.distPreview = this.form.controls.dist.value;
    }

    this.cd.markForCheck();
  }

  onFileChange(el: HTMLInputElement) {
    if (!el.files.length) {
      return;
    }

    const file = el.files[0];

    el.value = null;

    this.form.controls.dist_file.patchValue(file);
    this.form.controls.files.patchValue({ js: [], css: [] });
    this.form.markAsDirty();
  }

  serializeOutput(value: FieldsEditItem): FieldOutput {
    const result = new FieldOutput();

    result.name = value.name;
    result.verboseName = value.verboseName;
    result.description = value.description;
    result.field = value.field;
    result.defaultType = value.defaultType;
    result.defaultValue = value.defaultValue;
    result.params = value.params;
    result.updateFieldDescription();

    return result;
  }

  deserializeOutput(value: FieldOutput): FieldsEditItem {
    return {
      name: value.name,
      verboseName: value.verboseName,
      description: value.description,
      field: value.field,
      defaultType: value.defaultType,
      defaultValue: value.defaultValue,
      params: value.params
    };
  }

  onCustomViewChange(view: View) {
    this.form.controls.parameters.patchValue(view.parameters);
  }

  onCustomViewTemplateSelected(template: CustomViewTemplate) {
    const inputs = toPairs(template.view.testParameters).map(([name, value]) => {
      const result = new FieldInput();

      result.path = [name];
      result.valueType = InputValueType.StaticValue;
      result.staticValue = value;

      return result;
    });

    this.form.controls.inputs.patchValue(inputs);
  }
}
