<app-sidebar-collapse
  *ngIf="collapse"
  [title]="title"
  [titleAdditional]="titleAdditional$ | async"
  [titleAdditionalRed]="itemForm.controls.required.value || (parameter$ | async)?.required"
  [titleAdditionalSelectedOption]="itemForm.controls.required.value"
  [titleAdditionalOptions]="(parameter$ | async)?.required ? [] : titleAdditionalOptions"
  [error]="(parameter$ | async)?.required && itemForm.errors?.required ? 'This input is required' : undefined"
  [description]="description"
  [descriptionWrap]="true"
  [block]="true"
  [context]="collapseContext"
  [openedInitial]="openedInitial"
  [openable]="!!(parameter$ | async)"
  [disabled]="!((parameter$ | async)?.required && itemForm.errors?.required) && !(isSet$ | async)"
  [arrow]="!(remove && (parameter$ | async)?.required != true) && !!(parameter$ | async)"
  [classes]="classes"
  (titleAdditionalOptionSelected)="itemForm.controls.required.patchValue($event)"
  #collapse_element
>
  <ng-container right>
    <a
      *ngIf="(remove && (parameter$ | async)?.required != true) || !(parameter$ | async)"
      href="javascript:void(0)"
      class="sidebar-collapse__action icon-bin"
      (click)="$event.stopPropagation(); removeItem()"
    >
    </a>
  </ng-container>

  <app-input-edit
    *ngIf="collapse_element.isOpened() && !!(parameter$ | async)"
    [itemForm]="itemForm"
    [context]="context"
    [contextElement]="contextElement"
    [contextElementPath]="contextElementPath"
    [contextElementPaths]="contextElementPaths"
    [staticValueField]="(parameter$ | async)?.field"
    [staticValueParams]="(parameter$ | async)?.params"
    [staticValueArray]="(parameter$ | async)?.array"
    [filterFields]="filterFields$ | async"
    [userInput]="userInput"
    [focusedInitial]="focusedOnOpen && !collapse_element.titleAdditionalOptionsOpened"
    [placeholder]="placeholder$ | async"
    [formulaPlaceholder]="formulaPlaceholder"
    [jsPlaceholder]="jsPlaceholder"
    [displayValueTypes]="displayValueTypes"
    [fill]="true"
    [analyticsSource]="analyticsSource"
  ></app-input-edit>

  <app-alert *ngIf="providerItemWarning$ | async" [warning]="true" style="margin-top: 10px;">
    <div class="alert__description" [innerHTML]="providerItemWarning$ | async"></div>
  </app-alert>
</app-sidebar-collapse>

<app-sidebar-field *ngIf="!collapse" [label]="title">
  <app-input-edit
    [itemForm]="itemForm"
    [context]="context"
    [contextElement]="contextElement"
    [contextElementPath]="contextElementPath"
    [contextElementPaths]="contextElementPaths"
    [staticValueField]="(parameter$ | async)?.field"
    [staticValueParams]="(parameter$ | async)?.params"
    [filterFields]="filterFields$ | async"
    [userInput]="userInput"
    [displayValueTypes]="displayValueTypes"
    [fill]="true"
    [analyticsSource]="analyticsSource"
  ></app-input-edit>
  <div *ngIf="(parameter$ | async)?.required && itemForm.errors?.required" class="field__errors">
    <div class="field__error">required</div>
  </div>
  <app-alert *ngIf="providerItemWarning$ | async" [warning]="true" style="margin-top: 10px;">
    <span [innerHTML]="providerItemWarning$ | async"></span>
  </app-alert>
</app-sidebar-field>
