import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { NgGxScrollableModule } from 'ng-gxscrollable';
import { NgGxSelectModule } from 'ng-gxselect';

import { LocalizeModule } from '@common/localize';
import { TipsModule } from '@common/tips';
import { ActivitiesComponentsModule } from '@modules/activities-components';
import { AnalyticsModule } from '@modules/analytics';
import { ChartsComponentsModule } from '@modules/charts-components';
import { ColorsComponentsModule } from '@modules/colors-components';
import { ColorsSharedModule } from '@modules/colors-shared';
import { CustomizeComponentsModule } from '@modules/customize-components';
import { CustomizeUiModule } from '@modules/customize-ui';
import { MenuComponentsModule } from '@modules/menu-components';
import { MenuProfileModule } from '@modules/menu-profile';
import { RoutingModule } from '@modules/routing';
import { ShareModule } from '@modules/share';
import { TableModule } from '@modules/table';
import { ThemeComponentsModule } from '@modules/theme-components';
import { TutorialModule } from '@modules/tutorial';
import { SharedModule } from '@shared';
import { UiModule } from '@ui';

import { AdminHeaderCollaborationComponent } from './components/admin-header-collaboration/admin-header-collaboration.component';
import { AdminHeaderDropdownComponent } from './components/admin-header-dropdown/admin-header-dropdown.component';
import { AdminHeaderMenuItemsComponent } from './components/admin-header-menu-items/admin-header-menu-items.component';
import { AdminHeaderMenuComponent } from './components/admin-header-menu/admin-header-menu.component';
import { AdminHeaderComponent } from './components/admin-header/admin-header.component';
import { ProjectUsersPopoverComponent } from './components/project-users-popover/project-users-popover.component';

@NgModule({
  imports: [
    CommonModule,
    RoutingModule,
    OverlayModule,
    NgGxSelectModule,
    FormsModule,
    LocalizeModule,
    ActivitiesComponentsModule,
    TutorialModule,
    MenuProfileModule,
    MatMenuModule,
    SharedModule,
    TableModule,
    CustomizeComponentsModule,
    NgGxScrollableModule,
    MenuComponentsModule,
    ShareModule,
    TipsModule,
    AnalyticsModule,
    UiModule,
    ThemeComponentsModule,
    CustomizeUiModule,
    ChartsComponentsModule,
    ColorsSharedModule,
    ColorsComponentsModule
  ],
  declarations: [
    AdminHeaderComponent,
    ProjectUsersPopoverComponent,
    AdminHeaderMenuComponent,
    AdminHeaderDropdownComponent,
    AdminHeaderCollaborationComponent,
    AdminHeaderMenuItemsComponent
  ],
  exports: [AdminHeaderComponent, AdminHeaderMenuComponent]
})
export class AdminHeaderModule {}
