import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { AppConfigService } from '@core';
import { customOAuth2BackendName, popularSSOProviders, sharedCustomSSO, socialBackends } from '@modules/projects';
import { isPaidSSOSettings, SharedCustomSSOType, SSOType } from '@modules/sso';
import { ascComparator, deployUrl, isSet } from '@shared';

interface SSOOption {
  type: SSOType;
  backend?: string;
  sharedCustomSSO?: SharedCustomSSOType;
  label: string;
  description?: string;
  icon?: string;
  image?: string;
  orange?: boolean;
}

@Component({
  selector: 'app-sso-type-menu',
  templateUrl: './sso-type-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SSOTypeMenuComponent implements OnInit, OnDestroy {
  @Output() ssoSelect = new EventEmitter<{
    name?: string;
    image?: string;
    type: SSOType;
    backend?: string;
    sharedCustomSSO?: SharedCustomSSOType;
  }>();

  searchControl = new FormControl('');
  options: SSOOption[] = [
    {
      type: SSOType.OAuth2,
      backend: customOAuth2BackendName,
      label: 'Custom OAuth 2.0',
      icon: 'plus',
      orange: true
    },
    {
      type: SSOType.SAML2,
      label: 'Custom SAML2',
      icon: 'plus',
      orange: true
    },
    {
      type: SSOType.Custom,
      label: 'Custom authentication',
      icon: 'plus',
      orange: true
    },
    ...[
      ...socialBackends
        .filter(item => item.name != customOAuth2BackendName)
        .map(item => {
          return {
            type: SSOType.OAuth2,
            backend: item.name,
            label: item.label,
            image: item.image
          };
        }),
      ...sharedCustomSSO.map(item => {
        return {
          type: SSOType.Custom,
          sharedCustomSSO: item.type,
          label: item.label,
          image: item.image
        };
      })
    ].sort((lhs, rhs) => ascComparator(lhs.label.toLowerCase(), rhs.label.toLowerCase()))
  ];
  popularOptions = popularSSOProviders.map(item => {
    const isPaid = isPaidSSOSettings({
      type: item.type,
      ...(item.type == SSOType.OAuth2 && {
        oauth2Backend: item.oauth2Backend
      }),
      ...(item.type == SSOType.Custom && {
        sharedCustomSSO: item.sharedCustomSSO
      })
    });
    const result = this.options.find(option => {
      if (item.type == SSOType.OAuth2) {
        return option.backend == item.oauth2Backend;
      } else if (item.type == SSOType.Custom) {
        return option.sharedCustomSSO == item.sharedCustomSSO;
      } else {
        return false;
      }
    });

    return {
      ...result,
      ...(!isPaid && {
        description: '(All plans)'
      })
    };
  });
  filteredOptions: SSOOption[] = [];

  constructor(private appConfigService: AppConfigService, private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.updateFilteredOptions();

    this.searchControl.valueChanges.pipe(untilDestroyed(this)).subscribe(() => this.updateFilteredOptions());
  }

  ngOnDestroy(): void {}

  getFilteredOptions(): SSOOption[] {
    const search = this.searchControl.value.toLowerCase().trim();

    if (!isSet(search)) {
      return this.options;
    }

    return this.options.filter(item => {
      return item.label.toLowerCase().includes(search);
    });
  }

  updateFilteredOptions() {
    this.filteredOptions = this.getFilteredOptions();
    this.cd.markForCheck();
  }

  clearSearch() {
    this.searchControl.patchValue('');
    this.updateFilteredOptions();
  }

  selectOption(option: SSOOption) {
    this.ssoSelect.emit({
      name: option.label,
      image: option.image ? deployUrl(`/assets/images/sso/${option.image}.svg`) : undefined,
      type: option.type,
      backend: option.backend,
      sharedCustomSSO: option.sharedCustomSSO
    });
  }
}
