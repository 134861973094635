import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional
} from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import isEqual from 'lodash/isEqual';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { asyncScheduler, BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { distinctUntilChanged, map, throttleTime } from 'rxjs/operators';

import { CustomizeService, FieldElementStyles, ViewContext, ViewContextElement } from '@modules/customize';
import {
  applyParamInput$,
  createFormFieldFactory,
  EditableFlexField,
  FieldType,
  FormField,
  getFieldDescriptionByType,
  getValidatorByType,
  Input as FieldInput,
  LOADING_VALUE,
  ParameterField
} from '@modules/fields';
import { ModelDescriptionStore } from '@modules/model-queries';
import { getDefaultValue } from '@modules/models';
import { CurrentProjectStore } from '@modules/projects';
import { controlValue, TypedChanges } from '@shared';

import { CustomPagePopupComponent } from '../../custom-page-popup/custom-page-popup.component';

export const VALUE_OUTPUT = 'value';
const STANDALONE_FORM_CONTROL = 'field';

interface ElementState {
  field?: EditableFlexField;
  valueInput?: FieldInput;
  disableInput?: FieldInput;
  name?: string;
  formField?: FormField;
}

function getElementStateInfo(state: ElementState): Object {
  return {
    name: state.name,
    field: state.formField ? state.formField.field : undefined
  };
}

function getElementStateOutputs(state: ElementState): Object {
  return {
    field: state.formField ? state.formField.field : undefined,
    params: state.formField ? state.formField.params : undefined
  };
}

function getElementStateValue(state: ElementState): Object {
  return {
    field: state.formField ? state.formField.field : undefined,
    valueInput: state.valueInput ? state.valueInput.serialize() : undefined
  };
}

function getElementStateDisabled(state: ElementState): Object {
  return {
    disableInput: state.disableInput ? state.disableInput.serialize() : undefined
  };
}

function getElementStateValidators(state: ElementState): Object {
  return state.formField
    ? {
        required: state.formField.required,
        validatorType: state.formField.validatorType,
        validatorParams: state.formField.validatorParams
      }
    : {};
}

@Component({
  selector: 'app-filter-element-item',
  templateUrl: './filter-element-item.component.html',
  providers: [ViewContextElement],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterElementItemComponent implements OnInit, OnDestroy, OnChanges {
  @Input() name: string;
  @Input() field: EditableFlexField;
  @Input() labelAdditional: string;
  @Input() tooltip: string;
  @Input() context: ViewContext;
  @Input() parent: ViewContextElement;
  @Input() accentColor: string;
  @Input() elementStyles: FieldElementStyles;

  field$ = new BehaviorSubject<EditableFlexField>(undefined);
  state: ElementState = {};

  createField = createFormFieldFactory();
  control = new FormControl();
  form = new FormGroup({ [STANDALONE_FORM_CONTROL]: this.control });
  defaultValueSubscription: Subscription;
  disabledSubscription: Subscription;
  updateOutputSubscription: Subscription;
  updateValidatorSubscription: Subscription;
  baseValidator: ValidatorFn[] = [];
  customizeEnabled$: Observable<boolean>;
  loadingValue = false;
  fieldTypes = FieldType;
  booleanValueEquals = (lhs, rhs) => lhs === rhs;

  constructor(
    private customizeService: CustomizeService,
    private modelDescriptionStore: ModelDescriptionStore,
    private currentProjectStore: CurrentProjectStore,
    public viewContextElement: ViewContextElement,
    private cd: ChangeDetectorRef,
    @Optional() private popup: CustomPagePopupComponent
  ) {}

  ngOnInit() {
    this.customizeEnabled$ = this.customizeService.enabled$.pipe(map(item => !!item));

    this.initContext();

    this.fieldOnChange(this.field);
    this.trackChanges();
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<FilterElementItemComponent>): void {
    if (changes.field) {
      this.fieldOnChange(this.field);
    }
  }

  fieldOnChange(value: EditableFlexField) {
    this.field$.next(value);
  }

  trackChanges() {
    this.field$
      .pipe(
        map(field => this.getElementState(field)),
        untilDestroyed(this)
      )
      .subscribe(state => {
        this.onStateUpdated(state);
        this.state = state;
      });
  }

  getElementState(field: EditableFlexField): ElementState {
    const formField = new FormField().deserialize({
      name: STANDALONE_FORM_CONTROL,
      label: this.field.verboseName,
      field: this.field.field,
      required: false,
      reset_enabled: true,
      editable: true,
      placeholder: this.field.placeholder,
      validator_type: this.field.validatorType,
      validator_params: this.field.validatorParams,
      params: { ...this.field.params, classes: ['input_fill', 'select_fill'], theme: true },
      description: this.field.description
    });

    return {
      field: field,
      valueInput: field && field.valueInput,
      name: field.verboseName,
      formField: formField
    };
  }

  onStateUpdated(state: ElementState) {
    if (!isEqual(getElementStateInfo(state), getElementStateInfo(this.state))) {
      this.updateContextInfo(state);
    }

    if (!isEqual(getElementStateOutputs(state), getElementStateOutputs(this.state))) {
      this.updateContextOutputs(state);
      this.updateContextActions(state);
    }

    if (!isEqual(getElementStateValue(state), getElementStateValue(this.state))) {
      this.updateDefaultValue(state);
      this.updateValueOutput();
    }

    if (!isEqual(getElementStateDisabled(state), getElementStateDisabled(this.state))) {
      this.updateDisabled(state);
    }

    if (!isEqual(getElementStateValidators(state), getElementStateValidators(this.state))) {
      this.updateValidators(state);
    }
  }

  updateDefaultValue(state: ElementState) {
    if (this.defaultValueSubscription) {
      this.defaultValueSubscription.unsubscribe();
      this.defaultValueSubscription = undefined;
    }

    let value$: Observable<any>;
    const fieldTypeDefault = null;

    if (state.valueInput) {
      value$ = applyParamInput$<any>(state.valueInput, {
        context: this.context,
        defaultValue: fieldTypeDefault,
        handleLoading: true
      }).pipe(distinctUntilChanged((lhs, rhs) => isEqual(lhs, rhs)));
    } else {
      value$ = of(fieldTypeDefault);
    }

    this.defaultValueSubscription = value$.pipe(untilDestroyed(this)).subscribe(value => {
      this.setControlDefault(value);
    });
  }

  setControlDefault(value?: any) {
    if (value === LOADING_VALUE) {
      this.loadingValue = true;
      this.cd.markForCheck();
    } else {
      this.loadingValue = false;
      this.cd.markForCheck();

      this.control.patchValue(value);
      this.control.markAsPristine();
    }
  }

  updateDisabled(state: ElementState) {
    if (this.disabledSubscription) {
      this.disabledSubscription.unsubscribe();
      this.disabledSubscription = undefined;
    }

    let value$: Observable<boolean>;

    if (state.disableInput) {
      value$ = applyParamInput$<boolean>(state.disableInput, {
        context: this.context,
        defaultValue: false,
        handleLoading: true
      }).pipe(distinctUntilChanged((lhs, rhs) => isEqual(lhs, rhs)));
    } else {
      value$ = of(false);
    }

    this.disabledSubscription = value$.pipe(untilDestroyed(this)).subscribe(disabled => {
      this.setControlDisabled(disabled);
    });
  }

  setControlDisabled(disabled: boolean) {
    if (disabled === LOADING_VALUE) {
      disabled = true;
    }

    if (disabled && !this.control.disabled) {
      this.control.disable();
    } else if (!disabled && this.control.disabled) {
      this.control.enable();
    }

    this.cd.markForCheck();
  }

  updateValidators(state: ElementState): void {
    if (this.updateValidatorSubscription) {
      this.updateValidatorSubscription.unsubscribe();
      this.updateValidatorSubscription = undefined;
    }

    const validators: ValidatorFn[] = [...this.baseValidator];

    if (state.formField) {
      if (state.formField.required) {
        validators.push(Validators.required);
      }

      const validator = getValidatorByType(state.formField.validatorType, state.formField.validatorParams, {
        context: this.context,
        contextElement: this.viewContextElement
      });

      if (validator.func) {
        validators.push(validator.func);
      }

      if (validator.contextDependent && this.context) {
        const control = this.control;
        this.updateValidatorSubscription = this.context.outputValues$
          .pipe(throttleTime(10, asyncScheduler, { leading: true, trailing: true }))
          .pipe(untilDestroyed(this))
          .subscribe(() => control.updateValueAndValidity());
      }
    }

    this.control.setValidators(validators);
    this.control.updateValueAndValidity();
  }

  updateValueOutput() {
    if (this.updateOutputSubscription) {
      this.updateOutputSubscription.unsubscribe();
      this.updateOutputSubscription = undefined;
    }

    const control = this.control;

    this.updateOutputSubscription = controlValue(control)
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        const error = !!control.errors;
        this.viewContextElement.setOutputValue(VALUE_OUTPUT, value, { error: error });
      });
  }

  initContext() {
    const fieldDescription = getFieldDescriptionByType(this.field.field);

    this.viewContextElement.initElement(
      {
        uniqueName: this.name,
        name: this.field.verboseName,
        icon: fieldDescription.icon,
        allowSkip: true
      },
      this.parent
    );
  }

  updateContextInfo(state: ElementState) {
    const fieldDescription = getFieldDescriptionByType(state.formField.field);
    this.viewContextElement.initInfo(
      {
        name: state.name,
        icon: fieldDescription.icon,
        getFieldValue: (field, outputs) => {
          return outputs[VALUE_OUTPUT];
        }
      },
      true
    );
  }

  updateContextOutputs(state: ElementState) {
    const fieldDescription = getFieldDescriptionByType(state.formField.field);
    this.viewContextElement.setOutputs([
      {
        uniqueName: VALUE_OUTPUT,
        name: 'Value',
        icon: fieldDescription.icon,
        fieldType: state.formField.field,
        fieldParams: state.formField.params,
        external: true
      }
    ]);
  }

  updateContextActions(state: ElementState) {
    const valueParameter = new ParameterField();

    valueParameter.name = 'value';
    valueParameter.field = state.formField.field;
    valueParameter.params = { ...state.formField.params };

    this.viewContextElement.setActions([
      {
        uniqueName: 'set_value',
        name: 'Set Value',
        icon: 'edit',
        parameters: [valueParameter],
        handler: params => {
          this.control.setValue(params['value']);
        }
      },
      {
        uniqueName: 'clear_value',
        name: 'Clear Value',
        icon: 'delete',
        parameters: [],
        handler: () => {
          const fieldDescription = getFieldDescriptionByType(state.formField.field);

          this.control.setValue(fieldDescription.defaultValue);
        }
      }
    ]);
  }
}
