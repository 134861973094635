import { Injectable } from '@angular/core';

import { AggregateFunc } from '@modules/charts';
import { modelFieldToDisplayField, WidgetElementItem } from '@modules/customize';
import { ValueWidget } from '@modules/dashboard';
import { DataSourceType, ValueWidgetDataSource } from '@modules/data-sources';
import { Input, InputValueType } from '@modules/fields';
import { ModelDescription, ModelFieldType } from '@modules/models';
import { Resource } from '@modules/projects';
import { QueryType, ValueWidgetQuery } from '@modules/queries';
import { prepareDataSourceColumnForGet } from '@modules/resources';
import { isSet } from '@shared';

@Injectable()
export class ValueWidgetGenerator {
  constructor() {}

  getElement(
    resource: Resource,
    modelDescription: ModelDescription,
    options: {
      func: AggregateFunc;
      column?: string;
      label?: string;
      filters?: { name: string; value: string }[];
      inputs?: Input[];
      uid?: string;
    }
  ): WidgetElementItem {
    const widget = new ValueWidget();
    const query = new ValueWidgetQuery();

    query.queryType = QueryType.Simple;
    query.simpleQuery = new query.simpleQueryClass();
    query.simpleQuery.model = modelDescription.model;

    if (isSet(options.label)) {
      widget.nameInput = new Input().deserializeFromStatic('value', options.label);
    }

    widget.dataSource = new ValueWidgetDataSource();
    widget.dataSource.type = DataSourceType.Query;
    widget.dataSource.queryResource = resource.uniqueName;
    widget.dataSource.query = query;
    widget.dataSource.columns = modelDescription.fields
      .filter(item => item.type == ModelFieldType.Db)
      .map(item => {
        const result = modelFieldToDisplayField(item);

        if (result.name == modelDescription.primaryKeyField) {
          result.visible = false;
        }

        return result;
      })
      .map(item => prepareDataSourceColumnForGet(resource, modelDescription, item));

    widget.dataSource.func = options.func;
    widget.dataSource.column = options.column;

    if (options.inputs) {
      widget.dataSource.queryInputs.push(...options.inputs);
    }

    if (options.filters) {
      widget.dataSource.queryInputs.push(
        ...options.filters.map(item => {
          const input = new Input();

          input.path = [item.name];
          input.valueType = InputValueType.StaticValue;
          input.staticValue = item.value;

          return input;
        })
      );
    }

    const element = new WidgetElementItem();

    if (options.uid) {
      element.uid = options.uid;
    } else {
      element.generateUid();
    }

    element.widget = widget;

    return element;
  }
}
