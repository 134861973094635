import { Injectable } from '@angular/core';
import uniqBy from 'lodash/uniqBy';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ViewSettings, ViewSettingsStore, ViewSettingsType } from '@modules/customize';
import { DashboardStore } from '@modules/dashboard';
import { ModelDescriptionStore } from '@modules/model-queries';
import { ModelDescription } from '@modules/models';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';

import { MenuItem } from '../../data/menu-item';
import { MenuItemActionType } from '../../data/menu-item-action';
import { MenuItemType } from '../../data/menu-item-type';
import { ModelLinkMenuItem } from '../../data/model-link-menu-item';
import { SimpleMenuItem } from '../../data/simple-menu-item';
import { MenuSettingsStore } from '../../stores/menu-settings/menu-settings.store';
import { forEachMenuItems } from '../../utils/menu';

export interface MenuGroupItem {
  title: string;
  icon: string;
  link: any[];
  page?: ViewSettings;
  pageUid?: string;
  model?: string;
}

export interface MenuGroup {
  title: string;
  items: MenuGroupItem[];
  actions?: {
    label: string;
    icon?: string;
    link: any[];
  }[];
  border?: boolean;
  collapsible?: boolean;
  showEmpty?: boolean;
  searchIgnore?: boolean;
  duplicate?: boolean;
  sorted?: boolean;
}

@Injectable()
export class MenuPagesService {
  constructor(
    private dashboardStore: DashboardStore,
    private menuSettingsStore: MenuSettingsStore,
    private modelDescriptionStore: ModelDescriptionStore,
    private viewSettingsStore: ViewSettingsStore,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore
  ) {}

  getGroups(): Observable<MenuGroup[]> {
    return combineLatest(
      // this.dashboardStore.getFirst(),
      this.menuSettingsStore.get().pipe(
        map(menuSettings => {
          const menuItems: MenuItem[] = [];

          if (menuSettings) {
            forEachMenuItems(menuSettings.getAllItems(), item => {
              if (item.type != MenuItemType.Section) {
                menuItems.push(item);
              }
            });
          }

          return menuItems;
        })
      ),
      this.modelDescriptionStore.get(),
      this.viewSettingsStore.get()
    ).pipe(
      map(([menuItems, modelDescriptions, viewSettings]) => {
        const menuLinks = menuItems
          .map<MenuGroupItem>(item => {
            if (item instanceof SimpleMenuItem && item.action && item.action.type == MenuItemActionType.Page) {
              const page = viewSettings.find(i => i.uid == item.action.pageUid);
              if (!page) {
                return;
              }

              return {
                title: item.title,
                icon: item.icon || 'circle',
                link: page ? page.link : undefined,
                page: page,
                pageUid: item.action.pageUid
              };
            } else if (item instanceof ModelLinkMenuItem) {
              const model = new ModelDescription();
              const modelId = item.model.split('.', 2);

              if (modelId.length == 2) {
                [model.resource, model.model] = modelId;
              } else {
                [model.model] = modelId;
              }

              return {
                title: item.title,
                icon: item.icon || 'circle',
                link: model.link
              };
            }
          })
          .filter(item => item);

        const pageLink = viewSettings
          .filter(item => item.uniqueName)
          .map<MenuGroupItem>(item => {
            return {
              title: item.name || item.uniqueName,
              icon: 'document',
              link: item.link,
              page: item,
              pageUid: item.uid
            };
          });

        const result: MenuGroup[] = [
          {
            title: 'Menu pages',
            items: uniqBy(menuLinks, item => {
              return JSON.stringify({ page: item.pageUid, model: item.model });
            }),
            actions: [
              {
                label: 'Customize menu',
                icon: 'gear',
                link: this.currentProjectStore.instance.settingsLayoutLink('appearance', 'menu')
              }
            ],
            border: true,
            collapsible: true,
            showEmpty: true,
            searchIgnore: true,
            duplicate: true,
            sorted: true
          },
          {
            title: 'All Pages',
            items: pageLink
          },
          // {
          //   title: 'Dashboards',
          //   items: dashboards.map(item => {
          //     return {
          //       title: item.name,
          //       icon: 'document',
          //       link: item.link
          //     };
          //   })
          // },
          {
            title: 'Collections',
            items: modelDescriptions
              .map(item => {
                const resource = this.currentEnvironmentStore.resources.find(i => i.uniqueName == item.resource);
                // TODO: Legacy views
                const hasCustomizations = viewSettings.find(
                  viewSetting =>
                    [ViewSettingsType.List, ViewSettingsType.Change].includes(viewSetting.view) &&
                    viewSetting.resource == item.resource &&
                    viewSetting.model == item.model
                );

                if (!resource || (resource && resource.demo) || !hasCustomizations) {
                  return;
                }

                return {
                  title: resource
                    ? `${item.verboseNamePlural || item.model} (${resource.name || resource.uniqueName})`
                    : item.verboseNamePlural || item.model,
                  icon: 'document',
                  link: item.link
                };
              })
              .filter(item => item != undefined)
          }
        ];

        return result.map(group => {
          if (!group.sorted) {
            group.items = group.items.sort((lhs, rhs) => {
              return lhs.title.toLowerCase() < rhs.title.toLowerCase()
                ? -1
                : lhs.title.toLowerCase() == rhs.title.toLowerCase()
                ? 0
                : 1;
            });
          }
          return group;
        });
      })
    );
  }
}
