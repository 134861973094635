var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormControl } from '@angular/forms';
import { controlValue } from '@shared';
var NumberControl = /** @class */ (function (_super) {
    __extends(NumberControl, _super);
    function NumberControl(formState, validatorOrOpts, asyncValidator) {
        var _this = _super.call(this, formState, validatorOrOpts, asyncValidator) || this;
        controlValue(_this).subscribe(function (rawValue) {
            var cleanValue = _this.cleanValue(rawValue);
            if (cleanValue !== rawValue) {
                _this.setValue(cleanValue);
            }
        });
        return _this;
    }
    NumberControl.prototype.cleanValue = function (value) {
        if (value === undefined || value === null) {
            return value;
        }
        if (value === '') {
            return undefined;
        }
        value = parseInt(value, 10);
        if (isNaN(value)) {
            value = 0;
        }
        else if (typeof value !== 'number') {
            value = undefined;
        }
        else if (value < 0) {
            value = 0;
        }
        return value;
    };
    return NumberControl;
}(FormControl));
export { NumberControl };
