var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HttpClient } from '@angular/common/http';
import fromPairs from 'lodash/fromPairs';
import isArray from 'lodash/isArray';
import toPairs from 'lodash/toPairs';
import { map, publishLast, refCount, switchMap, tap } from 'rxjs/operators';
import { ApiService } from '@modules/api';
import { filterItemsFromQueryParams } from '@modules/filter-utils';
import { ORDER_BY_PARAM, PAGE_PARAM, PER_PAGE_PARAM } from '@modules/models';
import { HttpMethod, HttpQuery, HttpQueryService, QueryType } from '@modules/queries';
import { isSet } from '@shared';
import { ResourceController } from '../../data/resource-controller';
import { SOURCE_FIELD_TYPE } from '../airtable-resource-controller/airtable-constants';
import { SMART_SUITE_ACCOUNT_ID_PARAM, SMART_SUITE_APPLICATION_ID_PARAM, SMART_SUITE_SYSTEM_SOLUTION_SLUG, SOURCE_FIELD_PARAMS } from './smart-suite-constants';
import { SmartSuiteApplicationFieldType } from './smart-suite-types';
var SmartSuiteResourceController = /** @class */ (function (_super) {
    __extends(SmartSuiteResourceController, _super);
    function SmartSuiteResourceController() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.tokenName = 'api_key';
        return _this;
    }
    SmartSuiteResourceController.prototype.init = function () {
        this.apiService = this.initService(ApiService);
        this.httpQueryService = this.initService(HttpQueryService);
        this.http = this.initService(HttpClient);
    };
    SmartSuiteResourceController.prototype.supportedQueryTypes = function (resource, queryClass) {
        return [QueryType.Simple];
    };
    SmartSuiteResourceController.prototype.getHeaders = function (options) {
        return [
            { name: 'Authorization', value: "Token " + options.key },
            { name: 'Account-Id', value: options.accountId },
            { name: 'Content-Type', value: 'application/json' }
        ];
    };
    SmartSuiteResourceController.prototype.getSolutions = function (options) {
        var query = new HttpQuery();
        query.method = HttpMethod.GET;
        query.url = "https://app.smartsuite.com/api/v1/solutions/";
        query.headers = this.getHeaders(options);
        query.queryParams = [];
        return this.httpQueryService
            .requestBody(query)
            .pipe(map(function (result) { return result.filter(function (item) { return options.includeHidden || !item.hidden; }); }));
    };
    SmartSuiteResourceController.prototype.getApplications = function (options) {
        var _this = this;
        return this.getSolutions(__assign({}, options, { includeHidden: true })).pipe(switchMap(function (solutions) {
            var systemSolution = solutions.find(function (item) { return item.slug == SMART_SUITE_SYSTEM_SOLUTION_SLUG; });
            var query = new HttpQuery();
            query.method = HttpMethod.GET;
            query.url = "https://app.smartsuite.com/api/v1/applications/";
            query.headers = _this.getHeaders(options);
            query.queryParams = [];
            return _this.httpQueryService.requestBody(query).pipe(map(function (result) {
                return result.filter(function (item) {
                    return [options.solutionId].concat((systemSolution ? [systemSolution.id] : [])).includes(item.solution);
                });
            }));
        }));
    };
    SmartSuiteResourceController.prototype.deserializeModel = function (record, collection) {
        var parseValue = function (value, array) {
            if (isArray(value)) {
                var result = value.filter(function (item) { return isSet(item); });
                return array ? result : result[0];
            }
            else {
                return array ? [value] : value;
            }
        };
        var parseValueKey = function (value, key, array) {
            if (isArray(value)) {
                var result = value
                    .map(function (item) { return (item ? item[key] : null); })
                    .filter(function (item) { return isSet(item); });
                return array ? result : result[0];
            }
            else {
                var result = value ? value[key] : null;
                return array ? [result] : result;
            }
        };
        var fields = toPairs(record).map(function (_a) {
            var name = _a[0], value = _a[1];
            var field = collection.fields.find(function (i) { return i.name == name; });
            var sourceType = field
                ? field.item.params[SOURCE_FIELD_TYPE]
                : undefined;
            if ([SmartSuiteApplicationFieldType.SingleSelectField, SmartSuiteApplicationFieldType.StatusField].includes(sourceType)) {
                value = parseValueKey(value, 'value', false);
            }
            else if ([
                SmartSuiteApplicationFieldType.MultipleSelectField,
                SmartSuiteApplicationFieldType.TagsField,
                SmartSuiteApplicationFieldType.ChecklistField,
                SmartSuiteApplicationFieldType.ColorPickerField
            ].includes(sourceType)) {
                var array = field.item.params && field.item.params[SOURCE_FIELD_PARAMS]
                    ? field.item.params[SOURCE_FIELD_PARAMS]['entries_allowed'] == 'multiple'
                    : false;
                value = parseValueKey(value, 'value', array);
            }
            else if ([
                SmartSuiteApplicationFieldType.LinkedRecordField,
                SmartSuiteApplicationFieldType.UserField,
                SmartSuiteApplicationFieldType.LinkField
            ].includes(sourceType)) {
                var array = field.item.params && field.item.params[SOURCE_FIELD_PARAMS]
                    ? field.item.params[SOURCE_FIELD_PARAMS]['entries_allowed'] == 'multiple'
                    : false;
                value = parseValue(value, array);
            }
            else if (sourceType == SmartSuiteApplicationFieldType.RichTextAreaField) {
                value = parseValueKey(value, 'html', false);
            }
            else if (sourceType == SmartSuiteApplicationFieldType.FullNameField) {
                value = parseValueKey(value, 'sys_root', false);
            }
            else if (sourceType == SmartSuiteApplicationFieldType.IpAddressField) {
                value = parseValueKey(value, 'address', false);
            }
            else if (sourceType == SmartSuiteApplicationFieldType.PhoneField) {
                value = parseValueKey(value, 'sys_title', false);
            }
            return [name, value];
        });
        var missingFields = collection.fields
            .filter(function (item) { return !fields.find(function (_a) {
            var k = _a[0];
            return k == item.name;
        }); })
            .map(function (item) { return [item.name, null]; });
        return __assign({}, fromPairs(fields), fromPairs(missingFields));
    };
    SmartSuiteResourceController.prototype.serializeModel = function (instance, collection, fields) {
        var data = toPairs(instance.serialize(fields));
        // .filter(([k, v]) => ![AIRTABLE_PRIMARY_KEY, AIRTABLE_CREATED_TIME].includes(k))
        // .filter(([k, v]) => {
        //   const field = collection.fields.find((i) => i.uniqueName == k);
        //   return field && field.editable;
        // })
        // .filter(([k, v]) => v !== undefined)
        // .map(([name, value]) => {
        //   const field = collection.fields.find((i) => i.uniqueName == name);
        //   const sourceType: AirtableFieldType = field
        //     ? (field.params[SOURCE_FIELD_TYPE] as AirtableFieldType)
        //     : undefined;
        //
        //   if (sourceType && sourceType == AirtableFieldType.MultipleAttachments) {
        //     if (isSet(value)) {
        //       value = isArray(value)
        //         ? value.filter((item) => item !== null).map((item) => ({ url: item }))
        //         : [{ url: value }];
        //     } else {
        //       value = [];
        //     }
        //   } else if (sourceType && sourceType == AirtableFieldType.MultipleSelects) {
        //     value = isArray(value) ? value : [value];
        //     value = value.filter((item) => item !== null);
        //   } else if (sourceType && sourceType == AirtableFieldType.MultipleRecordLinks) {
        //     value = isArray(value) ? value : [value];
        //     value = value.filter((item) => item !== null);
        //   }
        //
        //   return [name, value];
        // });
        return fromPairs(data);
    };
    SmartSuiteResourceController.prototype.modelGet = function (resource, modelDescription, params, body) {
        var _this = this;
        var options = { resource: resource.uniqueName };
        var accountId = resource.params[SMART_SUITE_ACCOUNT_ID_PARAM];
        var applicationId = modelDescription.params[SMART_SUITE_APPLICATION_ID_PARAM];
        var defaultPageSizeLimit = 100;
        var page = params[PAGE_PARAM];
        var perPage = isSet(params[PER_PAGE_PARAM]) ? Math.min(params[PER_PAGE_PARAM], 100) : defaultPageSizeLimit;
        var queryParams = [];
        if (page) {
            queryParams.push({ name: 'offset', value: "" + (page - 1) * perPage });
        }
        if (perPage) {
            queryParams.push({ name: 'limit', value: "" + perPage });
        }
        var filter = {
            operator: 'and',
            fields: []
        };
        var filterItems = filterItemsFromQueryParams(params);
        filterItems.forEach(function (filterItem) {
            var field = modelDescription.dbFields
                .filter(function (i) { return i.name != modelDescription.primaryKeyField; })
                .find(function (i) { return i.name == filterItem.field; });
            if (!field) {
                return;
            }
            filter.fields.push({
                comparison: 'is',
                field: field.name,
                value: filterItem.value
            });
        });
        var orderField = params[ORDER_BY_PARAM];
        var orderAscending = true;
        if (isSet(orderField) && orderField.startsWith('-')) {
            orderField = orderField.substring(1);
            orderAscending = false;
        }
        var sort = [];
        if (isSet(orderField)) {
            sort.push({
                direction: orderAscending ? 'asc' : 'desc',
                field: orderField
            });
        }
        var query = new HttpQuery();
        query.method = HttpMethod.POST;
        query.url = "https://app.smartsuite.com/api/v1/applications/" + applicationId + "/records/list/";
        query.headers = this.getHeaders({ accountId: accountId, key: "{-" + this.tokenName + "-}" });
        query.queryParams = queryParams;
        query.body = { filter: filter, sort: sort };
        return this.httpQueryService.requestBody(query, options).pipe(map(function (result) {
            var limit = !result.limit ? defaultPageSizeLimit : result.limit;
            var data = {
                results: result.items.map(function (item) { return _this.deserializeModel(item, modelDescription); }),
                has_more: result.offset + limit < result.total,
                offset: result.offset,
                count: result.total,
                per_page: result.limit
            };
            return _this.createGetResponse().deserialize(data, modelDescription.model, undefined);
        }), tap(function (response) {
            response.results.forEach(function (item) {
                item.deserializeAttributes(modelDescription.dbFields);
                item.setUp(modelDescription);
            });
            return response;
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    SmartSuiteResourceController.prototype.modelGetDetail = function (resource, modelDescription, idField, id, params) {
        var _this = this;
        var options = { resource: resource.uniqueName };
        var accountId = resource.params[SMART_SUITE_ACCOUNT_ID_PARAM];
        var applicationId = modelDescription.params[SMART_SUITE_APPLICATION_ID_PARAM];
        var query = new HttpQuery();
        query.method = HttpMethod.GET;
        query.url = "https://app.smartsuite.com/api/v1/applications/" + applicationId + "/records/" + id + "/";
        query.headers = this.getHeaders({ accountId: accountId, key: "{-" + this.tokenName + "-}" });
        return this.httpQueryService.requestBody(query, options).pipe(map(function (result) {
            var data = _this.deserializeModel(result, modelDescription);
            var instance = _this.createModel().deserialize(modelDescription.model, data);
            instance.setUp(modelDescription);
            instance.deserializeAttributes(modelDescription.dbFields);
            return instance;
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    SmartSuiteResourceController.prototype.modelCreate = function (resource, modelDescription, modelInstance, fields) {
        var _this = this;
        var options = { resource: resource.uniqueName };
        var accountId = resource.params[SMART_SUITE_ACCOUNT_ID_PARAM];
        var applicationId = modelDescription.params[SMART_SUITE_APPLICATION_ID_PARAM];
        var query = new HttpQuery();
        query.method = HttpMethod.POST;
        query.url = "https://app.smartsuite.com/api/v1/applications/" + applicationId + "/records/";
        query.headers = this.getHeaders({ accountId: accountId, key: "{-" + this.tokenName + "-}" });
        query.body = this.serializeModel(modelInstance, modelDescription, fields);
        return this.httpQueryService.requestBody(query, options).pipe(map(function (result) {
            var data = _this.deserializeModel(result, modelDescription);
            var instance = _this.createModel().deserialize(modelDescription.model, data);
            instance.setUp(modelDescription);
            instance.deserializeAttributes(modelDescription.dbFields);
            return instance;
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    SmartSuiteResourceController.prototype.modelUpdate = function (resource, modelDescription, modelInstance, fields) {
        var _this = this;
        var options = { resource: resource.uniqueName };
        var accountId = resource.params[SMART_SUITE_ACCOUNT_ID_PARAM];
        var applicationId = modelDescription.params[SMART_SUITE_APPLICATION_ID_PARAM];
        var query = new HttpQuery();
        query.method = HttpMethod.PATCH;
        query.url = "https://app.smartsuite.com/api/v1/applications/" + applicationId + "/records/" + modelInstance.primaryKey + "/";
        query.headers = this.getHeaders({ accountId: accountId, key: "{-" + this.tokenName + "-}" });
        query.body = this.serializeModel(modelInstance, modelDescription, fields);
        return this.httpQueryService.requestBody(query, options).pipe(map(function (result) {
            var data = _this.deserializeModel(result, modelDescription);
            var instance = _this.createModel().deserialize(modelDescription.model, data);
            instance.setUp(modelDescription);
            instance.deserializeAttributes(modelDescription.dbFields);
            return instance;
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    SmartSuiteResourceController.prototype.modelDelete = function (resource, modelDescription, modelInstance) {
        var options = { resource: resource.uniqueName };
        var accountId = resource.params[SMART_SUITE_ACCOUNT_ID_PARAM];
        var applicationId = modelDescription.params[SMART_SUITE_APPLICATION_ID_PARAM];
        var query = new HttpQuery();
        query.method = HttpMethod.DELETE;
        query.url = "https://app.smartsuite.com/api/v1/applications/" + applicationId + "/records/" + modelInstance.primaryKey + "/";
        query.headers = this.getHeaders({ accountId: accountId, key: "{-" + this.tokenName + "-}" });
        return this.httpQueryService
            .requestBody(query, options)
            .pipe(this.apiService.catchApiError(), publishLast(), refCount());
    };
    return SmartSuiteResourceController;
}(ResourceController));
export { SmartSuiteResourceController };
