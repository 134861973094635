/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../fields/components/auto-field/auto-field.component.ngfactory";
import * as i2 from "../../../fields/components/auto-field/auto-field.component";
import * as i3 from "@angular/common";
import * as i4 from "./input-edit-static-value.component";
var styles_InputEditStaticValueComponent = [];
var RenderType_InputEditStaticValueComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_InputEditStaticValueComponent, data: {} });
export { RenderType_InputEditStaticValueComponent as RenderType_InputEditStaticValueComponent };
function View_InputEditStaticValueComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "app-auto-field", [], null, null, null, i1.View_AutoFieldComponent_0, i1.RenderType_AutoFieldComponent)), i0.ɵdid(1, 770048, null, 0, i2.AutoFieldComponent, [i0.ChangeDetectorRef], { form: [0, "form"], field: [1, "field"], label: [2, "label"], autofocus: [3, "autofocus"] }, null), i0.ɵpod(2, { value: 0, name: 1 }), i0.ɵpod(3, { value: 0, name: 1 }), i0.ɵpad(4, 2), i0.ɵpad(5, 1), i0.ɵpod(6, { valueEquals: 0, options: 1, classes: 2 }), i0.ɵpod(7, { name: 0, field: 1, placeholder: 2, params: 3, reset_enabled: 4 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.form; var currVal_1 = _co.createField(_ck(_v, 7, 0, "control", _co.fieldTypes.Select, _co.placeholder, _ck(_v, 6, 0, _co.booleanValueEquals, _ck(_v, 4, 0, _ck(_v, 2, 0, false, "False"), _ck(_v, 3, 0, true, "True")), (_co.fill ? _ck(_v, 5, 0, "select_fill") : i0.ɵEMPTY_ARRAY)), _co.resetEnabled)); var currVal_2 = false; var currVal_3 = _co.focusedInitial; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_InputEditStaticValueComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-auto-field", [], null, null, null, i1.View_AutoFieldComponent_0, i1.RenderType_AutoFieldComponent)), i0.ɵdid(1, 770048, null, 0, i2.AutoFieldComponent, [i0.ChangeDetectorRef], { form: [0, "form"], field: [1, "field"], label: [2, "label"], autofocus: [3, "autofocus"], context: [4, "context"], contextElement: [5, "contextElement"] }, null), i0.ɵpod(2, { name: 0, placeholder: 1, field: 2, params: 3, reset_enabled: 4 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.form; var currVal_1 = _co.createField(_ck(_v, 2, 0, "control", _co.placeholder, _co.staticValueField, _co.staticValueParamsDisplay, _co.resetEnabled)); var currVal_2 = false; var currVal_3 = _co.focusedInitial; var currVal_4 = _co.context; var currVal_5 = _co.contextElement; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
export function View_InputEditStaticValueComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_InputEditStaticValueComponent_1)), i0.ɵdid(1, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_InputEditStaticValueComponent_2)), i0.ɵdid(3, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.staticValueField == _co.fieldTypes.Boolean); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.staticValueField != _co.fieldTypes.Boolean); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_InputEditStaticValueComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-input-edit-static-value", [], null, null, null, View_InputEditStaticValueComponent_0, RenderType_InputEditStaticValueComponent)), i0.ɵdid(1, 638976, null, 0, i4.InputEditStaticValueComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InputEditStaticValueComponentNgFactory = i0.ɵccf("app-input-edit-static-value", i4.InputEditStaticValueComponent, View_InputEditStaticValueComponent_Host_0, { control: "control", staticValueField: "staticValueField", staticValueParams: "staticValueParams", context: "context", contextElement: "contextElement", focusedInitial: "focusedInitial", placeholder: "placeholder", resetEnabled: "resetEnabled", classes: "classes", fill: "fill" }, {}, []);
export { InputEditStaticValueComponentNgFactory as InputEditStaticValueComponentNgFactory };
