<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayPanelClass]="['overlay', 'overlay_horizontal_center']"
  [cdkConnectedOverlayPositions]="popoverPositions"
  [cdkConnectedOverlayOrigin]="origin"
  [cdkConnectedOverlayOpen]="dropdownOpened"
  [cdkConnectedOverlayPush]="true"
  [cdkConnectedOverlayGrowAfterOpen]="true"
  [cdkConnectedOverlayHasBackdrop]="false"
  [cdkConnectedOverlayBackdropClass]="'popover2-backdrop'"
  (backdropClick)="closeDropdown()"
  (detach)="closeDropdown()"
>
  <div
    class="popover2 popover2_theme overlay-content overlay-content_animated"
    (mouseenter)="menuDropdownHovered$.next(true)"
    (mouseleave)="menuDropdownHovered$.next(false)"
  >
    <div class="popover2__inner">
      <div class="popover2__viewport popover2__viewport_scrollable" style="min-width: 220px;">
        <div
          class="popover2__content popover2__content_padding_sm menu2-dropdown menu2-block menu2-block_unset"
          appMenuBlockDropdownStyle
        >
          <app-auto-menu-item
            *ngFor="let item of children; trackBy: trackMenuItemFn"
            [menuItem]="item"
            [menuBlock]="menuBlock"
            [dropdown]="true"
            (dropdownOpen)="onMenuChildDropdownOpened(item)"
            (dropdownClose)="onMenuChildDropdownClosed(item)"
          ></app-auto-menu-item>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template let-active="active" #menu_item>
  <div
    *ngIf="isIconImage()"
    class="menu2-image-item menu2-image-item_icon"
    [class.menu2-image-item_clickable]="link || (href | appIsSet) || handler"
    [style]="imageStyle"
    (mouseenter)="menuItemHovered$.next(icon_image_origin)"
    (mouseleave)="menuItemHovered$.next(undefined)"
    cdkOverlayOrigin
    #icon_image_origin="cdkOverlayOrigin"
  >
    <div class="menu2-image-item__inner">
      <div
        class="menu2-image-item__image"
        [class.menu2-image-item__image_fill]="menuItem.imageFill && imageUrl"
        [class.menu2-image-item__image_light]="imageColorLight === true"
        [class.menu2-image-item__image_dark]="imageColorLight === false"
        [class.menu2-image-item__image_unset]="!(menuItem.imageColor | appIsSet)"
        [style.background-color]="(menuItem.imageColor | appIsColorHex) ? menuItem.imageColor : null"
        [style.width.px]="(menuItem.imageSize | appIsSet) ? menuItem.imageSize : null"
        [style.height.px]="(menuItem.imageSize | appIsSet) ? menuItem.imageSize : null"
        [style.border-radius.%]="(menuItem.imageBorderRadius | appIsSet) ? menuItem.imageBorderRadius * 0.5 : null"
        [style.padding.px]="!menuItem.imageFill ? padding : null"
        [ngClass]="!(menuItem.imageColor | appIsColorHex) ? 'background-color_' + menuItem.imageColor + '_1' : ''"
      >
        <div
          *ngIf="imageUrl"
          class="menu2-image-item__image-inner"
          [style.background-image]="imageUrl ? 'url(' + imageUrl + ')' : ''"
        ></div>

        <div
          *ngIf="!imageUrl"
          class="menu2-image-item__image-inner"
          [style.font-size.px]="textSize"
          [appIcon]="menuItem.imageIcon"
        >
          <ng-container *ngIf="!menuItem.imageIcon && (imageInitials | appIsSet)">{{ imageInitials }}</ng-container>
        </div>
      </div>
    </div>
  </div>

  <div
    *ngIf="!isIconImage()"
    class="menu2-image-item"
    [class.menu2-image-item_clickable]="link || (href | appIsSet) || handler"
    (mouseenter)="menuItemHovered$.next(non_icon_image_origin)"
    (mouseleave)="menuItemHovered$.next(undefined)"
    cdkOverlayOrigin
    #non_icon_image_origin="cdkOverlayOrigin"
  >
    <div class="menu2-image-item__inner">
      <div
        *ngIf="(imageUrl | appIsSet) || (menuItem.imageIcon | appIsSet)"
        class="menu2-image-item__left"
        [style]="imageStyle"
      >
        <div
          class="menu2-image-item__image"
          [class.menu2-image-item__image_fill]="menuItem.imageFill && imageUrl"
          [class.menu2-image-item__image_light]="imageColorLight === true"
          [class.menu2-image-item__image_dark]="imageColorLight === false"
          [class.menu2-image-item__image_unset]="!(menuItem.imageColor | appIsSet)"
          [style.background-color]="(menuItem.imageColor | appIsColorHex) ? menuItem.imageColor : null"
          [style.width.px]="(menuItem.imageSize | appIsSet) ? menuItem.imageSize : null"
          [style.height.px]="(menuItem.imageSize | appIsSet) ? menuItem.imageSize : null"
          [style.border-radius.%]="(menuItem.imageBorderRadius | appIsSet) ? menuItem.imageBorderRadius * 0.5 : null"
          [ngClass]="!(menuItem.imageColor | appIsColorHex) ? 'background-color_' + menuItem.imageColor + '_1' : ''"
        >
          <div
            *ngIf="imageUrl"
            class="menu2-image-item__image-inner"
            [style.background-image]="imageUrl ? 'url(' + imageUrl + ')' : ''"
          ></div>

          <div
            *ngIf="!imageUrl"
            class="menu2-image-item__image-inner"
            [style.font-size.px]="textSize"
            [appIcon]="menuItem.imageIcon"
          >
            <ng-container *ngIf="!menuItem.imageIcon && (imageInitials | appIsSet)">{{ imageInitials }}</ng-container>
          </div>
        </div>
      </div>

      <div
        class="menu2-image-item__main"
        [class.menu2-image-item__main_padding]="!(imageUrl | appIsSet) && !(menuItem.imageIcon | appIsSet)"
      >
        <div *ngIf="title | appIsSet" class="menu2-image-item__row">
          <div class="menu2-image-item__title">
            {{ title }}
          </div>
          <div *ngIf="children.length" class="menu2-image-item__arrow icon-arrow_down_2"></div>
        </div>

        <div *ngIf="subtitle | appIsSet" class="menu2-image-item__row">
          <div class="menu2-image-item__subtitle">
            {{ subtitle }}
          </div>
          <div *ngIf="children.length && !(title | appIsSet)" class="menu2-image-item__arrow icon-arrow_down_2"></div>
        </div>
      </div>

      <div *ngIf="children.length && !(title | appIsSet) && !(subtitle | appIsSet)" class="menu2-image-item__right">
        <div class="menu2-image-item__action icon-arrow_down_2"></div>
      </div>
    </div>
  </div>
</ng-template>

<a
  *ngIf="link"
  [routerLink]="link"
  [queryParams]="queryParams"
  [appIsLinkActiveAppLink]="link"
  [appIsLinkActiveQueryParams]="queryParams"
  #app_is_link_active="appIsLinkActive"
  class="menu2-link menu2-item-wrapper"
  [class.menu2-link_active]="app_is_link_active.active"
  (click)="onClick()"
>
  <ng-container *ngTemplateOutlet="menu_item; context: { active: app_is_link_active.active }"></ng-container>
</a>

<a
  *ngIf="!link && (href | appIsSet)"
  [href]="href"
  [appIsLinkActiveHref]="href"
  #app_is_link_active="appIsLinkActive"
  class="menu2-link menu2-item-wrapper"
  [class.menu2-link_active]="app_is_link_active.active"
  target="_blank"
  (click)="onClick()"
>
  <ng-container *ngTemplateOutlet="menu_item; context: { active: app_is_link_active.active }"></ng-container>
</a>

<span *ngIf="!link && !(href | appIsSet)" class="menu2-item-wrapper" [class.menu2-link]="handler" (click)="onClick()">
  <ng-container *ngTemplateOutlet="menu_item"></ng-container>
</span>
