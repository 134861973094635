import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NavigationStart, Router } from '@angular/router';
import { Power2, TimelineMax } from 'gsap';
import cloneDeep from 'lodash/cloneDeep';
import { SelectComponent } from 'ng-gxselect';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fromEvent, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { NotificationService } from '@common/notifications';
import { BasePopupComponent } from '@common/popups';
import { ActionApproveService } from '@modules/action-queries';
import { ApproveResultState } from '@modules/actions';
import { UserActivity, UserActivityListStore, UserActivityService } from '@modules/activities';
import {
  SendEvent,
  TimelinePopupFooterComponent
} from '@modules/activities-components/components/timeline-popup-footer/timeline-popup-footer.component';
import { ServerRequestError } from '@modules/api';
import { Task, TaskPriority, TaskQueue, TaskQueueStore, TaskService, TaskStatus } from '@modules/collaboration';
import { CustomizeService, ViewContext, ViewSettingsStore } from '@modules/customize';
import { createFormFieldFactory, FieldType } from '@modules/fields';
import { CurrentEnvironmentStore, CurrentProjectStore, ProjectUserSelectSource } from '@modules/projects';
import { CurrentUserStore, User } from '@modules/users';
import { isSet } from '@shared';

@Component({
  selector: 'app-task-popup',
  templateUrl: './task-popup.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [UserActivityListStore, ProjectUserSelectSource]
})
export class TaskPopupComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() itemUid: string;
  @Input() closeObs: Subject<void>;
  @Input() context: ViewContext;
  @Output() updated = new EventEmitter<Task>();
  @Output() closeObsEvent = new EventEmitter<void>();

  @ViewChild('header') header: ElementRef<HTMLElement>;
  @ViewChild('sidePopup') sidePopup: ElementRef<HTMLElement>;
  @ViewChild('comments') comments: ElementRef<HTMLElement>;
  @ViewChild('footer') footerElement: ElementRef<HTMLElement>;
  @ViewChild('sidePopupWrap') sidePopupWrap: ElementRef<HTMLElement>;
  @ViewChild('closeButton') closeButton: ElementRef<HTMLElement>;
  @ViewChild('assigned', { read: SelectComponent }) assigned: SelectComponent;
  @ViewChild('status', { read: SelectComponent }) status: SelectComponent;
  @ViewChild('priority', { read: SelectComponent }) priority: SelectComponent;
  @ViewChild(TimelinePopupFooterComponent) footer: TimelinePopupFooterComponent;

  params: Object = {
    order_by: '-date_add',
    object_type: 'task'
  };
  task: Task;
  relatedObject: { name: string; link: any[]; queryParams?: Object };
  assignedToMe = false;
  approveResultStates = ApproveResultState;
  tlVisible = new TimelineMax();
  taskStatuses: TaskStatus[];
  userOptions: { name: string; value: string }[] = [];
  statusOptions: { name: string; value: string }[] = [];
  priorityOptions = [
    { name: 'Lowest', value: TaskPriority.Lowest },
    { name: 'Low', value: TaskPriority.Low },
    { name: 'Medium', value: TaskPriority.Medium },
    { name: 'High', value: TaskPriority.High },
    { name: 'Highest', value: TaskPriority.Highest }
  ];
  createField = createFormFieldFactory();
  currentQueue: TaskQueue;

  assignedInputVisible = false;
  statusInputVisible = false;
  priorityInputVisible = false;

  taskForm = new FormGroup({
    assigned: new FormControl(null, [Validators.required]),
    status: new FormControl(null, [Validators.required]),
    priority: new FormControl(null, [Validators.required])
  });

  paramsList: { type: FieldType; name: string; value: any }[] = [];

  userActivities: UserActivity[];
  formHeight: number;
  sendMessage = false;

  constructor(
    private userActivityService: UserActivityService,
    private userActivityListStore: UserActivityListStore,
    private taskQueueStore: TaskQueueStore,
    public projectUserSelectSource: ProjectUserSelectSource,
    private router: Router,
    private customizeService: CustomizeService,
    private popupComponent: BasePopupComponent,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private taskService: TaskService,
    private currentUserStore: CurrentUserStore,
    private notificationService: NotificationService,
    private actionApproveService: ActionApproveService,
    private viewSettingsStore: ViewSettingsStore,
    private injector: Injector,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.projectUserSelectSource.allowEmpty = true;
    this.projectUserSelectSource.userValue = true;

    this.taskService
      .getDetail(
        this.currentProjectStore.instance.uniqueName,
        this.currentEnvironmentStore.instance.uniqueName,
        this.itemUid
      )
      .pipe(untilDestroyed(this))
      .subscribe(task => {
        this.task = task;
        this.params = {
          ...this.params,
          object_id: task.uid
        };
        this.getComments();
        this.getQueue();
        this.setForm();
        this.cd.markForCheck();
        this.updateAssigned();
        this.updateRelatedObject();
      });

    if (this.closeObs) {
      this.closeObs.pipe(untilDestroyed(this)).subscribe(() => {
        this.close();
      });
    }
  }

  ngAfterViewInit(): void {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationStart),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.close();
      });

    fromEvent<WheelEvent>(this.sidePopup.nativeElement, 'wheel')
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        const sidePopupHeight = this.sidePopup.nativeElement.offsetHeight;
        const sidePopupScrollHeight = this.sidePopup.nativeElement.scrollHeight;
        const scrollTop = this.sidePopup.nativeElement.scrollTop;
        const scrollMax = sidePopupScrollHeight - sidePopupHeight;
        if (scrollTop >= scrollMax) {
          this.userActivityListStore.getNext();
        }
      });
    this.show();
  }

  trackByFn(index, item: UserActivity) {
    return item.dateAdd;
  }

  setFormBottomPadding(): void {
    if (this.formHeight === undefined) {
      this.formHeight = this.footerElement.nativeElement.offsetHeight;
      this.cd.detectChanges();
    } else if (this.footerElement.nativeElement.offsetHeight !== this.formHeight) {
      this.formHeight = this.footerElement.nativeElement.offsetHeight;
      this.cd.detectChanges();
    }
  }

  getComments(): void {
    this.userActivityListStore.projectName = this.currentProjectStore.instance.uniqueName;
    this.userActivityListStore.environmentName = this.currentEnvironmentStore.instance.uniqueName;
    this.userActivityListStore.params = this.params;
    this.userActivityListStore.reset();
    this.userActivityListStore.getNext();

    this.userActivityListStore.items$.pipe(untilDestroyed(this)).subscribe(value => {
      this.userActivities = value;
      this.cd.markForCheck();
    });

    this.userActivityService
      .subscribeUserActivities(
        this.currentProjectStore.instance.uniqueName,
        this.currentEnvironmentStore.instance.uniqueName,
        this.params['object_type'],
        this.params['object_id']
      )
      .pipe(untilDestroyed(this))
      .subscribe((value: UserActivity) => {
        this.userActivityListStore.prependItems([value]);
        this.cd.markForCheck();
      });
  }

  updateAssigned() {
    this.assignedToMe = this.task && this.task.assigned && this.currentUserStore.instance.uid == this.task.assigned.uid;
    this.cd.detectChanges();
    this.setFormBottomPadding();
  }

  updateRelatedObject() {
    if (!this.task) {
      this.relatedObject = undefined;
      this.cd.markForCheck();
      return;
    }

    if (this.task.objectType == 'custom_page') {
      this.viewSettingsStore
        .getDetailFirst(this.task.objectId)
        .pipe(untilDestroyed(this))
        .subscribe(viewSettings => {
          if (!viewSettings) {
            this.relatedObject = undefined;
            this.cd.markForCheck();
            return;
          }

          this.relatedObject = { name: viewSettings.name, link: viewSettings.link };
          this.cd.markForCheck();
        });
    } else {
      this.relatedObject = undefined;
      this.cd.markForCheck();
    }
  }

  setForm(): void {
    this.taskForm.patchValue({
      assigned: this.task.assigned ? this.task.assigned.uid : null,
      status: this.task.status.uid,
      priority: this.task.priority
    });
  }

  submit(event: SendEvent): void {
    this.sendMessage = true;
    this.cd.markForCheck();

    this.userActivityService
      .createInstance(
        this.currentProjectStore.instance.uniqueName,
        this.currentEnvironmentStore.instance.uniqueName,
        event.activity,
        event.mentions
      )
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.footer.reset();
          this.footer.focus();
          this.cd.markForCheck();
          this.sendMessage = false;
          const commentsPosition =
            this.sidePopup.nativeElement.scrollTop +
            this.comments.nativeElement.getBoundingClientRect().top -
            this.header.nativeElement.getBoundingClientRect().height -
            5;
          this.sidePopup.nativeElement.scrollTo(0, commentsPosition);
        },
        () => {
          this.sendMessage = false;
          this.footer.focus();
          this.cd.markForCheck();
        }
      );
  }

  openSelect(input: string): void {
    if (input === 'assigned') {
      this.assignedInputVisible = true;
    }
    if (input === 'status') {
      this.statusInputVisible = true;
    }
    if (input === 'priority') {
      this.priorityInputVisible = true;
    }
    this.cd.detectChanges();

    setTimeout(() => {
      this[input].optionsComponent.open();
      this[input].optionsComponent.touch.pipe(untilDestroyed(this)).subscribe(() => {
        if (!this[input].optionsComponent.opened) {
          setTimeout(() => {
            if (input === 'assigned') {
              this.assignedInputVisible = false;
            }
            if (input === 'status') {
              this.statusInputVisible = false;
            }
            if (input === 'priority') {
              this.priorityInputVisible = false;
            }
            this.cd.markForCheck();
          }, 600);
        }
      });
    }, 100);
  }

  inputChanged(input: string): void {
    const uid = this.taskForm.value[input];
    const taskClone = cloneDeep(this.task);

    if (input === 'assigned') {
      this.assignedInputVisible = false;
      const user = new User();
      user.uid = uid;
      taskClone[input] = user;
    } else {
      taskClone[input] = null;
    }

    if (input === 'status') {
      this.statusInputVisible = false;
      taskClone[input] = this.taskStatuses.find(status => status.uid === uid);
    }

    if (input === 'priority') {
      this.priorityInputVisible = false;
      taskClone[input] = this.taskForm.value[input];
    }

    this.cd.markForCheck();
    this.taskService
      .update(
        this.currentProjectStore.instance.uniqueName,
        this.currentEnvironmentStore.instance.uniqueName,
        taskClone,
        [input]
      )
      .pipe(untilDestroyed(this))
      .subscribe(task => {
        this.task = task;
        this.updated.emit(this.task);
        this.cd.detectChanges();
        this.updateAssigned();
      });
  }

  getQueue(): void {
    this.taskQueueStore
      .getFirst()
      .pipe(untilDestroyed(this))
      .subscribe(queues => {
        this.currentQueue = queues.find(queue => queue.uid === this.task.queue);
        this.paramsList = this.currentQueue.parameters
          .map(item => {
            return {
              type: item.field,
              name: item.name,
              value: this.task.parameterValues[item.name]
            };
          })
          .filter(item => isSet(item.value));
        this.taskStatuses = this.currentQueue.statuses;
        this.statusOptions = this.currentQueue.statuses.map(status => {
          return {
            name: status.name,
            value: status.uid
          };
        });
      });
  }

  show(): void {
    this.tlVisible
      .fromTo(
        this.sidePopupWrap.nativeElement,
        0.6,
        {
          xPercent: 100
        },
        {
          xPercent: 0,
          ease: Power2.easeOut
        }
      )
      .add(() => {
        this.footer.focus();
      });
  }

  close(): void {
    this.closeObsEvent.emit();
    this.tlVisible
      .clear()
      .set(
        this.closeButton.nativeElement,
        {
          pointerEvents: 'none'
        },
        0
      )
      .to(
        this.sidePopupWrap.nativeElement,
        0.4,
        {
          xPercent: 100,
          ease: Power2.easeOut
        },
        0
      )
      .add(() => {
        this.popupComponent.close();
      });
  }

  approve() {
    this.actionApproveService
      .approve(this.task, { context: this.context, injector: this.injector })
      .pipe(untilDestroyed(this))
      .subscribe(
        task => {
          this.task = task;
          this.cd.markForCheck();
          this.updated.emit(task);
          this.notificationService.success('Approved', 'Action was successfully approved');
        },
        error => {
          if (error instanceof ServerRequestError && error.errors.length) {
            this.notificationService.error('Error', `<strong>Approve</strong> failed: ${error.errors[0]}`);
          } else {
            this.notificationService.error('Error', error);
          }
        }
      );
  }

  reject() {
    this.actionApproveService
      .reject(this.task, { context: this.context, injector: this.injector })
      .pipe(untilDestroyed(this))
      .subscribe(
        task => {
          this.task = task;
          this.cd.markForCheck();
          this.updated.emit(task);
          this.notificationService.success('Rejected', 'Action was successfully rejected');
        },
        error => {
          if (error instanceof ServerRequestError && error.errors.length) {
            this.notificationService.error('Error', `<strong>Reject</strong> failed: ${error.errors[0]}`);
          } else {
            this.notificationService.error('Error', error);
          }
        }
      );
  }

  ngOnDestroy(): void {}
}
