<form [formGroup]="form">
  <div class="query-builder">
    <div class="query-builder__main">
      <!--      <div *ngIf="!('sql-charts' | appFeatureActive | async)">-->
      <!--        <div class="code-editor code-editor_flexible">-->
      <!--          <div class="code-editor__main">-->
      <!--            <app-feature-overview [background]="false" [feature]="'sql-charts'" class="query-builder__overview">-->
      <!--              <div data-title>Visualize Your SQL Queries Data with an <strong>SQL Chart</strong></div>-->
      <!--              <div data-description>-->
      <!--                <ul>-->
      <!--                  <li>Visualize your data graphically with the SQL Online Editor</li>-->
      <!--                  <li>Run more complex queries</li>-->
      <!--                  <li>Display your query results as charts in real time</li>-->
      <!--                  <li>Use any SQL syntax that suits your database type (SQLite, MySQL, Postgres, SQL Server, etc.)</li>-->
      <!--                </ul>-->
      <!--              </div>-->
      <!--            </app-feature-overview>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <ng-container *ngIf="'sql-charts' | appFeatureActive | async">-->
      <div class="query-builder__main-top" style="padding-top: 15px;">
        <div *ngIf="upgradeQuery && !upgradeQueryClosed" class="query-builder__toast">
          <div class="toast">
            <div class="toast__header">
              <div class="toast__header-left">
                <div class="toast__icon icon-warning"></div>
              </div>
              <div class="toast__header-main">
                <div class="toast__title">
                  We have updated SQL query parameters syntax
                  <a [appDocumentation]="'sql-queries/migrate-parameters-v240'" class="toast__link">
                    Learn more
                  </a>
                </div>
              </div>
              <div class="toast__header-right">
                <a href="javascript:void(0)" class="toast__close icon-close" (click)="closeUpgradeQuery()"></a>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="!upgradeQuery && incorrectLike && !incorrectLikeClosed" class="query-builder__toast">
          <div class="toast">
            <div class="toast__header">
              <div class="toast__header-left">
                <div class="toast__icon icon-warning"></div>
              </div>
              <div class="toast__header-main">
                <div class="toast__title">
                  You have used LIKE operator pattern incorrectly. See the correct usage example:
                </div>
              </div>
              <div class="toast__header-right">
                <a href="javascript:void(0)" class="toast__close icon-close" (click)="closeIncorrectLike()"></a>
              </div>
            </div>
            <div class="toast__content">
              <div class="toast__code">LIKE {{ '{{' }}'%'+params.search+'%'{{ '}' }}{{ '}' }}</div>
            </div>
          </div>
        </div>

        <div class="http-query__fill">
          <app-auto-field
            [form]="form"
            [label]="false"
            [field]="
              createField({
                name: 'query',
                label: '',
                field: 'SqlField',
                placeholder: sqlPlaceholder,
                params: { flexible: true, preview: false, table_tokens: false }
              })
            "
            [manualMargin]="true"
            [appTokenInput]="{
              context: context,
              control: form.controls.query,
              origin: button,
              trigger: button
            }"
            (appTokenEvent)="onInputTokensEvent($event)"
            #app_token_input="appTokenInput"
          >
          </app-auto-field>
        </div>

        <div class="http-query__actions">
          <a href="javascript:void(0)" class="http-query__actions-item" #button>
            <span class="http-query__actions-item-icon http-query__actions-item-icon_text">{{ '{' }}..{{ '}' }}</span>
            Insert Input
          </a>

          <app-text-to-sql-overlay
            [origin]="dropdownTrigger"
            (submitQuery)="insertSqlByNaturalText($event)"
            #text_to_sql_overlay="appTextToSqlOverlay"
          ></app-text-to-sql-overlay>

          <a
            href="javascript:void(0)"
            class="http-query__actions-item http-query__actions-item_purple-gradient"
            [class.http-query__actions-item_active]="text_to_sql_overlay.isOpened()"
            [class.http-query__actions-item_disabled]="textToSqlLoading"
            cdkOverlayOrigin
            #dropdownTrigger="cdkOverlayOrigin"
          >
            <span class="http-query__actions-item-icon icon-magic_wand"></span>
            Translate Text to SQL
          </a>

          <a
            href="javascript:void(0)"
            class="http-query__actions-item"
            [class.http-query__actions-item_disabled]="!(form.controls.query.value | appIsSet)"
            (click)="formatCurrentValue()"
          >
            <span class="http-query__actions-item-icon icon-toggle_theme"></span>
            Prettify
          </a>
        </div>

        <div *ngIf="textToSqlLoading" class="query-builder__loader">
          <app-loader [fill]="true"></app-loader>
        </div>
      </div>
      <div class="query-builder__main-bottom">
        <div class="query-builder__toolbar query-builder__toolbar_sticky">
          <div class="query-builder__toolbar-main">
            <div class="query-builder__radio query-builder__radio_orange">
              <a
                *ngIf="parametersControl"
                href="javascript:void(0)"
                class="query-builder__radio-item"
                [class.query-builder__radio-item_active]="resultsSection == resultsSections.Parameters"
                (click)="setResultsSection(resultsSections.Parameters)"
              >
                Inputs
              </a>
            </div>
          </div>
          <div class="query-builder__toolbar-right">
            <div class="query-builder__radio query-builder__radio_orange">
              <a
                href="javascript:void(0)"
                class="query-builder__radio-item"
                [class.query-builder__radio-item_active]="resultsSection == resultsSections.Result"
                (click)="setResultsSection(resultsSections.Result)"
              >
                Result
              </a>
            </div>
            <div class="query-builder__radio query-builder__radio_orange">
              <a
                href="javascript:void(0)"
                class="query-builder__radio-item query-builder__radio-item_icon query-builder__radio-item_primary"
                [class.query-builder__radio-item_active]="resultsSection == resultsSections.Result"
                [appTip]="'Send Request'"
                [appTipOptions]="{ side: 'top' }"
                (click)="setResultsSection(resultsSections.Result); execute()"
              >
                <app-loader-small *ngIf="loading" class="query-builder__radio-item-icon"></app-loader-small>
                <span *ngIf="!loading" class="icon-play_filled query-builder__radio-item-icon"></span>
              </a>
            </div>
          </div>
        </div>
        <div *ngIf="loading" class="query-builder__table">
          <app-query-builder-data-response [animating]="true"></app-query-builder-data-response>
        </div>
        <ng-container *ngIf="!loading">
          <div *ngIf="resultsSection == resultsSections.Parameters && parametersControl">
            <app-query-builder-parameters-edit
              [control]="parametersControl"
              [queryContext]="context"
              [object]="'query_builder'"
            >
            </app-query-builder-parameters-edit>
          </div>

          <ng-container *ngIf="resultsSection == resultsSections.Result">
            <div *ngIf="!error" class="query-builder__table">
              <app-query-builder-data-response [responseBody]="response"></app-query-builder-data-response>
            </div>
            <ng-container *ngIf="error">
              <div class="data-table-tip">
                SQL query failed with error. Please enter correct SQL in the field above.
              </div>
              <pre class="data-table-error">{{ error }}</pre>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
      <!--      </ng-container>-->
    </div>
    <div [formGroup]="form" class="query-builder__right query-builder-side">
      <div class="query-builder-side__header">
        <div class="query-builder-side__header-left">
          <span class="query-builder__title-icon" [style.background-image]="'url(' + resource.icon + ')'"></span>
        </div>
        <div class="query-builder-side__header-main">
          <div class="query-builder__title">Make SQL query</div>
        </div>
        <div class="query-builder-side__header-right">
          <a class="button" target="_blank" [appDocumentation]="'sql-queries'">
            <span class="icon button__icon_left icon-documents button__icon"></span>
            <span class="button__label">Docs</span>
          </a>
        </div>
      </div>

      <ng-content select="ng-containertabs]"></ng-content>
      <div class="query-builder-side__content">
        <app-dynamic-component
          *ngFor="let item of customSectionComponentsTop"
          [componentData]="item"
        ></app-dynamic-component>

        <div class="code-editor__structure">
          <!--          <div class="code-editor__structure-title">Tables</div>-->
          <app-table-tokens [resourceUniqueName]="resource?.uniqueName" (inserted)="app_token_input.insertText($event)">
          </app-table-tokens>
        </div>

        <ng-content select="ng-container[sections-bottom]"></ng-content>

        <app-dynamic-component
          *ngFor="let item of customSectionComponentsBottom"
          [componentData]="item"
        ></app-dynamic-component>
      </div>
      <div class="query-builder-side__footer">
        <div class="query-builder-side__footer-left">
          <button type="button" class="button button_orange" (click)="cancel()">
            <span class="button__label">Cancel</span>
          </button>
        </div>
        <div class="query-builder-side__footer-right">
          <button
            type="button"
            class="button button_primary button_shadow button_orange"
            [disabled]="form.invalid || form.disabled"
            cdkOverlayOrigin
            #save_trigger="cdkOverlayOrigin"
            (mouseenter)="saveHovered.next(true)"
            (mouseleave)="saveHovered.next(false)"
            (click)="submit()"
          >
            <span class="button__label">Save</span>
          </button>

          <ng-template
            cdkConnectedOverlay
            [cdkConnectedOverlayPanelClass]="['overlay_position_top']"
            [cdkConnectedOverlayPositions]="[
              {
                originX: 'center',
                originY: 'top',
                overlayX: 'center',
                overlayY: 'bottom',
                offsetX: 0,
                offsetY: -10
              }
            ]"
            [cdkConnectedOverlayOrigin]="save_trigger"
            [cdkConnectedOverlayOpen]="requireResponseMessageVisible$ | async"
            [cdkConnectedOverlayPush]="true"
            [cdkConnectedOverlayGrowAfterOpen]="true"
          >
            <div
              class="tip-overlay"
              (mouseenter)="requireResponseMessageHovered.next(true)"
              (mouseleave)="requireResponseMessageHovered.next(false)"
            >
              <div class="tip-overlay__title">Send request before save</div>
              <div class="tip-overlay__text">
                <ng-container *ngIf="requestCreate">
                  Request was not sent. You need to get request response to save it.
                </ng-container>
                <ng-container *ngIf="!requestCreate">
                  Request was changed. You need to get updated request response to save it.
                </ng-container>
              </div>
              <div class="tip-overlay__buttons">
                <ng-container *ngIf="isResponseMissing()">
                  <a
                    href="javascript:void(0)"
                    class="tip-overlay__button tip-overlay-button tip-overlay-button_primary"
                    (click)="setResultsSection(resultsSections.Result); execute()"
                  >
                    <app-loader-small *ngIf="loading" class="tip-overlay-button__icon"></app-loader-small>
                    <span *ngIf="!loading" class="tip-overlay-button__icon icon-play_filled"></span>
                    Send Request
                  </a>
                  <a
                    href="javascript:void(0)"
                    class="tip-overlay__button tip-overlay-button"
                    (click)="requireResponseMessageHovered.next(false); saveProcess()"
                    >Save anyway</a
                  >
                </ng-container>
                <ng-container *ngIf="!isResponseMissing()">
                  <a
                    href="javascript:void(0)"
                    class="tip-overlay__button tip-overlay-button"
                    (click)="setResultsSection(resultsSections.Result); execute()"
                  >
                    <app-loader-small *ngIf="loading" class="tip-overlay-button__icon"></app-loader-small>
                    <span *ngIf="!loading" class="tip-overlay-button__icon icon-play_filled"></span>
                    Send Request
                  </a>
                  <a
                    href="javascript:void(0)"
                    class="tip-overlay__button tip-overlay-button tip-overlay-button_primary"
                    (click)="requireResponseMessageHovered.next(false); saveProcess()"
                    >Finish</a
                  >
                </ng-container>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</form>
