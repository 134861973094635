import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { SelectSource } from '@common/select';
import { Option } from '@modules/field-components';
import { coerceArray, controlValue, generateAlphanumeric, isSet, KeyboardEventKeyCode, TypedChanges } from '@shared';

import { DropdownComponent } from '../dropdown/dropdown.component';

export function defaultCompare(o1: any, o2: any): boolean {
  return o1 == o2;
}

const chipsMouseEvent = '_chipsMouseEvent';
const chipsElementMouseEvent = '_chipsElementMouseEvent';

export function markChipsMouseEvent(e: MouseEvent, id: string) {
  e[chipsMouseEvent] = id;
}

export function isChipsMouseEvent(e: MouseEvent, id: string) {
  return e[chipsMouseEvent] == id;
}

export function markChipsElementMouseEvent(e: MouseEvent, id: string) {
  e[chipsElementMouseEvent] = id;
}

export function isChipsElementMouseEvent(e: MouseEvent, id: string) {
  return e[chipsElementMouseEvent] == id;
}

const defaultPlaceholder = 'Add value';

@Component({
  selector: 'app-chips',
  templateUrl: './chips.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChipsComponent implements OnInit, OnDestroy, OnChanges {
  @Input() control: AbstractControl;
  @Input() source: SelectSource;
  @Input() options: Option[] = [];
  @Input() compareWith: (o1: any, o2: any) => boolean = defaultCompare;
  @Input() separator = ',';
  @Input() error = false;
  @Input() array = true;
  @Input() placeholder = defaultPlaceholder;
  @Input() resetEnabled = false;
  @Input() duplicates = false;
  @Input() allowNew = false;
  @Input() circular = false;
  @Input() small = false;
  @Input() theme = false;
  @Input() styles = false;

  @ViewChild('input_element') inputElement: ElementRef;
  @ViewChild(DropdownComponent) dropdownComponent: DropdownComponent;

  id = generateAlphanumeric(8, { letterFirst: true });
  source$ = new BehaviorSubject<SelectSource>(undefined);
  options$ = new BehaviorSubject<Option[]>([]);
  loadingItems = false;
  selectedValues: any[] = [];
  addedOptions: Option[] = [];
  addControl = new FormControl('');

  trackItemFn(i, value: any) {
    return value;
  }

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {
    controlValue(this.control)
      .pipe(
        map(value => (isSet(value) ? coerceArray(value) : [])),
        untilDestroyed(this)
      )
      .subscribe(options => {
        this.selectedValues = options;
        this.cd.markForCheck();
        this.dropdownComponent.updatePositionOnStable();
      });

    controlValue<string>(this.addControl)
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        if (this.allowNew && isSet(this.separator) && value.includes(this.separator)) {
          this.addValueFromControl();
        }
      });
  }

  ngOnDestroy() {}

  ngOnChanges(changes: TypedChanges<ChipsComponent>): void {
    if (changes.source) {
      this.source$.next(this.source);
    }

    if (changes.options) {
      this.options$.next(this.options);
    }

    if (changes.compareWith) {
      this.compareWith = this.compareWith ? this.compareWith : defaultCompare;
    }

    if (changes.placeholder) {
      this.placeholder = isSet(this.placeholder) ? this.placeholder : defaultPlaceholder;
    }
  }

  setValue(newValue: string[]) {
    this.control.patchValue(newValue);
  }

  resetValue() {
    this.setValue([]);
  }

  removeItem(value: any, index: number) {
    const newValue = [...this.selectedValues].filter((item, i) => i != index);
    this.setValue(newValue);

    this.addedOptions = this.addedOptions.filter(item => !this.compareWith(item, value));
    this.cd.markForCheck();
  }

  addValues(...newItems: Option[]) {
    if (!this.duplicates) {
      newItems = newItems.filter(newValueItem => {
        return !this.selectedValues.some(item => this.compareWith(item, newValueItem.value));
      });
    }

    this.addedOptions.push(...newItems);
    this.cd.markForCheck();

    const newValue = [...this.selectedValues, ...newItems.map(item => item.value)];
    this.setValue(newValue);
  }

  addValueFromControl() {
    const addValuesRaw = isSet(this.separator) ? this.addControl.value.split(this.separator) : [this.addControl.value];
    const addValues = addValuesRaw.map(item => item.trim()).filter(item => isSet(item));

    if (!addValues.length) {
      return;
    }

    this.addValues(...addValues.map(item => ({ value: item, name: item })));
    this.addControl.setValue('');
  }

  focusInput() {
    this.inputElement.nativeElement.focus();
  }

  open() {
    if (this.dropdownComponent) {
      this.dropdownComponent.open();
    }
  }

  close() {
    if (this.dropdownComponent) {
      this.dropdownComponent.close();
    }
  }

  markChipsMouseEvent(e: MouseEvent) {
    markChipsMouseEvent(e, this.id);
  }

  markChipsElementMouseEvent(e: MouseEvent) {
    markChipsElementMouseEvent(e, this.id);
  }

  closeOnMouseDown(e: MouseEvent): boolean {
    return !isChipsMouseEvent(e, this.id);
  }

  onClick(e: MouseEvent) {
    if (!isChipsElementMouseEvent(e, this.id)) {
      this.focusInput();
    }
  }

  onKeydown(e: KeyboardEvent) {
    if (this.allowNew && e.keyCode == KeyboardEventKeyCode.Enter) {
      e.preventDefault();
      this.addValueFromControl();
    }
  }
}
