<ng-template #list_items>
  <div
    *ngFor="let itemForm of form.form.controls; trackBy: trackByFn"
    [formGroup]="itemForm"
    [class.sidebar-list__item]="collapse"
    [class.sidebar__element]="!collapse"
  >
    <app-inputs-edit-item
      [itemForm]="itemForm"
      [form]="form"
      [openedInitial]="lastAddedForm === itemForm"
      [focusedOnOpen]="true"
      [placeholder]="'Enter value'"
      [context]="context"
      [contextElement]="contextElement"
      [contextElementPath]="contextElementPath"
      [contextElementPaths]="contextElementPaths"
      [staticValueDisabled]="staticValueDisabled"
      [requiredEditable]="requiredEditable"
      [collapse]="collapse"
      [collapseContext]="collapseContext"
      [remove]="!displayAllParameters"
      [displayRequiredIndicator]="displayAllParameters"
      [userInput]="userInput"
      [warning]="(form.providerItem$(itemForm) | async)?.warning"
      [displayValueTypes]="displayValueTypes"
      [classes]="classes"
      [analyticsSource]="analyticsSource"
    >
    </app-inputs-edit-item>
  </div>

  <ng-container *ngIf="displayAllParameters">
    <div *ngIf="addInputEnabled" class="sidebar-list__buttons">
      <a
        href="javascript:void(0)"
        class="button button_orange-alternative button_small sidebar-list__buttons-item"
        (click)="addInput.emit()"
      >
        <span class="icon icon-plus button__icon button__icon_left"></span>
        <span class="button__label">Add Input</span>
      </a>
    </div>

    <div *ngIf="!form.form.controls.length && !addInputEnabled" class="sidebar__message">
      There are no inputs defined
    </div>
  </ng-container>

  <div *ngIf="!displayAllParameters" class="sidebar-list__buttons">
    <mat-menu #dropdown="matMenu">
      <ng-template matMenuContent>
        <app-custom-select-dropdown
          [items]="addItems"
          [searchEnabled]="true"
          [emptyLabel]="'No inputs'"
          [capitalize]="false"
          (optionClick)="onAddOptionClick($event)"
          (buttonClick)="onAddButtonClick($event)"
        ></app-custom-select-dropdown>
      </ng-template>
    </mat-menu>

    <a
      href="javascript:void(0)"
      class="button button_orange-transparent button_small sidebar-list__buttons-item"
      [matMenuTriggerFor]="dropdown"
      (menuClosed)="addMenuClosed.next()"
    >
      <span class="icon icon-plus button__icon button__icon_left"></span>
      <span class="button__label">Add</span>
    </a>
  </div>
</ng-template>

<div *ngIf="listWrapper" class="sidebar-list">
  <ng-container *ngTemplateOutlet="list_items"></ng-container>
</div>

<ng-container *ngIf="!listWrapper">
  <ng-container *ngTemplateOutlet="list_items"></ng-container>
</ng-container>
