/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../common/tips/directives/tip/tip.directive";
import * as i2 from "@angular/common";
import * as i3 from "../color-picker/color-picker.component.ngfactory";
import * as i4 from "../color-picker/color-picker.component";
import * as i5 from "../../../colors/services/pick-color/pick-color.service";
import * as i6 from "../../../../common/select/components/select/select.component.ngfactory";
import * as i7 from "../../../../common/select/components/select/select.component";
import * as i8 from "@angular/platform-browser";
import * as i9 from "../../../../shared/pipes/is-set/is-set.pipe";
import * as i10 from "./color-selector.component";
var styles_ColorSelectorComponent = [];
var RenderType_ColorSelectorComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ColorSelectorComponent, data: {} });
export { RenderType_ColorSelectorComponent as RenderType_ColorSelectorComponent };
function View_ColorSelectorComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "span", [["class", "color-selector-item__icon color-selector-item__icon_contrast icon-close"]], [[4, "color", null]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.parent.context.$implicit.color ? _co.getContrastColor(_v.parent.context.$implicit.color) : null); _ck(_v, 0, 0, currVal_0); }); }
function View_ColorSelectorComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "span", [["class", "color-selector-item__icon icon-check_2"]], [[4, "color", null]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getContrastColor(_v.parent.context.$implicit.value); _ck(_v, 0, 0, currVal_0); }); }
function View_ColorSelectorComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [["class", "color-selector__item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 8, "a", [["class", "color-selector-item"], ["href", "javascript:void(0)"]], [[2, "color-selector-item_palette", null], [4, "background-color", null], [2, "color-selector-item_active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.select(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 737280, null, 0, i1.TipDirective, [i0.ElementRef, i0.ComponentFactoryResolver, i0.Injector, i0.ApplicationRef], { content: [0, "content"], optionsRaw: [1, "optionsRaw"] }, null), i0.ɵpod(3, { side: 0 }), (_l()(), i0.ɵeld(4, 0, null, null, 1, "span", [["class", "color-selector-item__border"]], [[4, "border-color", null]], null, null, null, null)), i0.ɵppd(5, 1), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ColorSelectorComponent_3)), i0.ɵdid(7, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ColorSelectorComponent_4)), i0.ɵdid(9, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = (!_v.context.$implicit.value ? "Reset color" : undefined); var currVal_4 = _ck(_v, 3, 0, "top"); _ck(_v, 2, 0, currVal_3, currVal_4); var currVal_6 = !_v.context.$implicit.value; _ck(_v, 7, 0, currVal_6); var currVal_7 = (_v.context.$implicit.value && (_co.current == _v.context.$implicit.value)); _ck(_v, 9, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = false; var currVal_1 = _v.context.$implicit.color; var currVal_2 = (_co.current == _v.context.$implicit.value); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_5 = ((!_v.context.$implicit.value && i0.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i0.ɵnov(_v.parent.parent, 0), _co.emptyColor))) ? _co.emptyColor : _v.context.$implicit.color); _ck(_v, 4, 0, currVal_5); }); }
function View_ColorSelectorComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "color-selector__items"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ColorSelectorComponent_2)), i0.ɵdid(2, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.items; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ColorSelectorComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "color-selector__custom"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-color-picker", [], null, null, null, i3.View_ColorPickerComponent_0, i3.RenderType_ColorPickerComponent)), i0.ɵdid(2, 245760, null, 0, i4.ColorPickerComponent, [i0.ElementRef, i5.PickColorService, i0.ChangeDetectorRef], { hexControl: [0, "hexControl"], alphaEnabled: [1, "alphaEnabled"], accentColor: [2, "accentColor"], theme: [3, "theme"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.customColorControl; var currVal_1 = _co.alphaEnabled; var currVal_2 = _co.accentColor; var currVal_3 = _co.theme; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_ColorSelectorComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "color-selector__items"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-select", [], null, null, null, i6.View_SelectComponent_0, i6.RenderType_SelectComponent)), i0.ɵdid(2, 4964352, null, 0, i7.SelectComponent, [i8.DomSanitizer, i0.ChangeDetectorRef], { control: [0, "control"], placeholder: [1, "placeholder"], fill: [2, "fill"], small: [3, "small"], options: [4, "options"], colorEnabled: [5, "colorEnabled"], searchEnabled: [6, "searchEnabled"], searchMinimumLength: [7, "searchMinimumLength"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.customColorControl; var currVal_1 = "Use global color"; var currVal_2 = true; var currVal_3 = true; var currVal_4 = _co.globalOptions; var currVal_5 = true; var currVal_6 = true; var currVal_7 = 1; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, null); }
export function View_ColorSelectorComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i9.IsSetPipe, []), (_l()(), i0.ɵeld(1, 0, null, null, 6, "div", [["class", "color-selector"]], [[4, "width", "px"]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ColorSelectorComponent_1)), i0.ɵdid(3, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ColorSelectorComponent_5)), i0.ɵdid(5, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ColorSelectorComponent_6)), i0.ɵdid(7, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = ((_co.items == null) ? null : _co.items.length); _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.customColors; _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.globalColors; _ck(_v, 7, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.width; _ck(_v, 1, 0, currVal_0); }); }
export function View_ColorSelectorComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-color-selector", [], null, null, null, View_ColorSelectorComponent_0, RenderType_ColorSelectorComponent)), i0.ɵdid(1, 770048, null, 0, i10.ColorSelectorComponent, [i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ColorSelectorComponentNgFactory = i0.ɵccf("app-color-selector", i10.ColorSelectorComponent, View_ColorSelectorComponent_Host_0, { current: "current", colors: "colors", customColors: "customColors", globalColors: "globalColors", allowEmpty: "allowEmpty", emptyColor: "emptyColor", defaultColor: "defaultColor", alphaEnabled: "alphaEnabled", accentColor: "accentColor", theme: "theme", rowSize: "rowSize" }, { selected: "selected", finish: "finish" }, []);
export { ColorSelectorComponentNgFactory as ColorSelectorComponentNgFactory };
