import { localize } from '@common/localize';
import { CustomizeBarItem } from '@modules/change-components';
import {
  AlignHorizontal,
  DateRangeElementItem,
  ElementType,
  FIELD_ELEMENT_VERSION,
  FieldElementItem,
  RangeSliderElementItem
} from '@modules/customize';
import { FieldType, getFieldDescriptionByType, MultipleSelectStyle } from '@modules/fields';
import { isSet } from '@shared';

interface Field {
  field: FieldType;
  label?: string;
  image?: string;
  params?: Object;
  fieldParams?: Object;
}

function mapField(item: Field): CustomizeBarItem {
  const fieldDescription = getFieldDescriptionByType(item.field);

  return {
    title: item.label || fieldDescription.label,
    icon: fieldDescription.icon,
    image: item.image || fieldDescription.image,
    action: `Add ${fieldDescription.label}`,
    type: ElementType.Field,
    defaultParams: {
      settings: {
        field: item.field,
        verboseName: item.label || fieldDescription.label,
        editable: true,
        ...(item.fieldParams || {})
      },
      v: FIELD_ELEMENT_VERSION,
      ...(item.params || {})
    },
    postCreate(element: FieldElementItem): FieldElementItem {
      if (isSet(element.name)) {
        element.settings.verboseName = element.name;
      }

      if (!element.settings.required) {
        element.labelAdditional = `(${localize('optional')})`;
      }

      return element;
    }
  };
}

export const changeCustomizeBarComponentsForms = [
  {
    title: 'Form',
    image: 'form',
    action: 'Add Form',
    type: ElementType.Form,
    defaultParams: {
      title: 'Form'
    }
  },
  {
    title: 'Detail',
    image: 'detail',
    action: 'Add Detail',
    type: ElementType.Model,
    defaultParams: {
      title: 'Detail'
    }
  },
  ...[
    {
      field: FieldType.Text,
      label: 'Text Field',
      params: {},
      fieldParams: {}
    },
    {
      field: FieldType.RichText,
      params: {},
      fieldParams: {}
    },
    {
      field: FieldType.Boolean,
      params: {},
      fieldParams: {}
    },
    {
      field: FieldType.RelatedModel,
      params: {},
      fieldParams: {
        resetEnabled: true
      }
    },
    {
      field: FieldType.Select,
      params: {},
      fieldParams: {
        resetEnabled: true
      }
    },
    {
      field: FieldType.MultipleSelect,
      label: 'Multi-Select',
      params: {},
      fieldParams: {
        resetEnabled: true
      }
    },
    {
      field: FieldType.MultipleSelect,
      label: 'Chips',
      image: 'chips_field',
      params: {},
      fieldParams: {
        resetEnabled: true,
        params: {
          style: MultipleSelectStyle.Chips
        }
      }
    },
    {
      field: FieldType.RadioButton,
      params: {},
      fieldParams: {}
    },
    {
      field: FieldType.DateTime,
      params: {},
      fieldParams: {}
    }
    // {
    //   field: FieldType.Date,
    //   params: {},
    //   fieldParams: {}
    // }
  ].map(item => mapField(item)),
  {
    title: 'Date Range',
    image: 'date_range_field',
    action: 'Add Date Range',
    type: ElementType.DateRange,
    defaultParams: {
      verbose_name: 'Date Range'
    },
    postCreate(element: DateRangeElementItem): DateRangeElementItem {
      if (isSet(element.name)) {
        element.verboseName = element.name;
      }

      if (!element.required) {
        element.labelAdditional = `(${localize('optional')})`;
      }

      return element;
    }
  },
  ...[
    {
      field: FieldType.Time,
      params: {},
      fieldParams: {}
    },
    {
      field: FieldType.File,
      label: 'File Picker',
      params: {},
      fieldParams: {}
    },
    {
      field: FieldType.Image,
      label: 'Image Picker',
      params: {},
      fieldParams: {}
    },
    {
      field: FieldType.Password,
      params: {},
      fieldParams: {}
    },
    {
      field: FieldType.Number,
      params: {},
      fieldParams: {}
    },
    {
      field: FieldType.Rating,
      params: {},
      fieldParams: {}
    },
    {
      field: FieldType.Slider,
      params: {},
      fieldParams: {}
    }
  ].map(item => mapField(item)),
  {
    title: 'Range Slider',
    image: 'range_slider_field',
    action: 'Add Range Slider',
    type: ElementType.RangeSlider,
    defaultParams: {
      verbose_name: 'Range Slider'
    },
    postCreate(element: RangeSliderElementItem): RangeSliderElementItem {
      if (isSet(element.name)) {
        element.verboseName = element.name;
      }

      if (!element.required) {
        element.labelAdditional = `(${localize('optional')})`;
      }

      return element;
    }
  },
  ...[
    {
      field: FieldType.URL,
      params: {},
      fieldParams: {}
    },
    {
      field: FieldType.Location,
      params: {},
      fieldParams: {}
    },
    {
      field: FieldType.Video,
      label: 'Video Picker',
      params: {},
      fieldParams: {}
    },
    {
      field: FieldType.Audio,
      label: 'Audio Picker',
      params: {},
      fieldParams: {}
    },
    {
      field: FieldType.Signature,
      params: {
        width: 400,
        height: 200,
        align_horizontal: AlignHorizontal.Left
      },
      fieldParams: {}
    },
    {
      field: FieldType.Color,
      params: {},
      fieldParams: {}
    },
    {
      field: FieldType.JSON,
      params: {},
      fieldParams: {}
    }
  ].map(item => mapField(item))
];
