var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnChanges, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { createFormFieldFactory, FieldType } from '@modules/fields';
var InputEditStaticValueComponent = /** @class */ (function () {
    function InputEditStaticValueComponent() {
        this.focusedInitial = false;
        this.placeholder = 'Text';
        this.resetEnabled = true;
        this.classes = [];
        this.fill = false;
        this.createField = createFormFieldFactory();
        this.fieldTypes = FieldType;
        this.booleanValueEquals = function (lhs, rhs) { return lhs === rhs; };
    }
    InputEditStaticValueComponent.prototype.ngOnInit = function () {
        // TODO: Temporary solution without changing parent
        this.form = new FormGroup({});
        this.form.controls['control'] = this.control;
    };
    InputEditStaticValueComponent.prototype.ngOnChanges = function (changes) {
        if (changes.staticValueParams || changes.fill || changes.classes) {
            this.staticValueParamsDisplay = this.getStaticValueParams();
        }
    };
    InputEditStaticValueComponent.prototype.getStaticValueParams = function () {
        return __assign({}, this.staticValueParams, { output_format: undefined, classes: (this.fill ? ['select_fill', 'input_fill'] : []).concat(this.classes), background: true });
    };
    return InputEditStaticValueComponent;
}());
export { InputEditStaticValueComponent };
