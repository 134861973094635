import { HttpClient, HttpParams } from '@angular/common/http';
import { ChangeDetectorRef, EventEmitter, NgZone, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import isEqual from 'lodash/isEqual';
import range from 'lodash/range';
import { SelectComponent } from 'ng-gxselect';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, of, throwError } from 'rxjs';
import { catchError, debounceTime, delay, distinctUntilChanged, map, skip, switchMap, take, tap } from 'rxjs/operators';
import { AppConfigService, EmbedScripts, ScriptsService } from '@core';
import { createFormFieldFactory, LOG } from '@modules/fields';
import { controlValue, googlePickerSelectFileUrl, isSet } from '@shared';
import { GoogleSheetsFileControl, parseSheetUrl } from '../google-sheets-resource-settings.form';
var GoogleSheetsResourceSettingsFileComponent = /** @class */ (function () {
    function GoogleSheetsResourceSettingsFileComponent(http, scriptsService, appConfigService, zone, cd) {
        this.http = http;
        this.scriptsService = scriptsService;
        this.appConfigService = appConfigService;
        this.zone = zone;
        this.cd = cd;
        this.delete = false;
        this.deleteDisabled = false;
        this.deleted = new EventEmitter();
        this.updating = new EventEmitter();
        this.createField = createFormFieldFactory();
        this.fileSubscriptions = [];
        this.chooseFileLoading = false;
        this.sheetOptions = [];
        this.fileLoading$ = new BehaviorSubject(false);
        this.sheetLoading$ = new BehaviorSubject(false);
        this.rangeLoading$ = new BehaviorSubject(false);
    }
    GoogleSheetsResourceSettingsFileComponent.prototype.ngOnInit = function () {
        var _this = this;
        combineLatest(this.fileLoading$, this.sheetLoading$, this.rangeLoading$)
            .pipe(debounceTime(100), untilDestroyed(this))
            .subscribe(function (loadings) {
            var loading = loadings.some(function (item) { return item; });
            _this.updating.emit(loading);
        });
    };
    GoogleSheetsResourceSettingsFileComponent.prototype.ngOnDestroy = function () { };
    GoogleSheetsResourceSettingsFileComponent.prototype.ngOnChanges = function (changes) {
        if (changes['form']) {
            this.cd.markForCheck();
            this.updateSheetOptions();
            this.addFileObserver();
            this.openFileDropdownIfNotSelected();
        }
        if (changes['accessToken']) {
            this.updateSheetOptions();
        }
    };
    Object.defineProperty(GoogleSheetsResourceSettingsFileComponent.prototype, "isEditable", {
        get: function () {
            return this.form.controls.file.value && !this.fileLoading$.value && !this.sheetLoading$.value;
        },
        enumerable: true,
        configurable: true
    });
    GoogleSheetsResourceSettingsFileComponent.prototype.openFileDropdownIfNotSelected = function () {
        var _this = this;
        this.zone.onStable.pipe(take(1), delay(100), untilDestroyed(this)).subscribe(function () {
            if (_this.form && !_this.form.controls.file.value && _this.selectComponent) {
                _this.selectComponent.optionsComponent.open();
            }
            if (_this.form && !_this.form.controls.file.value) {
                _this.chooseFile();
            }
        });
    };
    GoogleSheetsResourceSettingsFileComponent.prototype.chooseFile = function () {
        var _this = this;
        if (!isSet(this.accessToken)) {
            this.chooseFileLoading = false;
            this.cd.markForCheck();
            return;
        }
        this.chooseFileLoading = true;
        this.cd.markForCheck();
        this.scriptsService
            .loadSingleton(EmbedScripts.GoogleAPILoader)
            .pipe(switchMap(function () {
            return googlePickerSelectFileUrl({
                appId: _this.appConfigService.googleSheetsAppId,
                apiKey: _this.appConfigService.googleSheetsApiKey,
                accessToken: _this.accessToken
            });
        }), untilDestroyed(this))
            .subscribe(function (url) {
            _this.chooseFileLoading = false;
            _this.cd.detectChanges();
            if (isSet(url)) {
                _this.form.controls.file_url.patchValue(url);
            }
        }, function () {
            _this.chooseFileLoading = false;
            _this.cd.detectChanges();
        });
    };
    GoogleSheetsResourceSettingsFileComponent.prototype.sheetOptionsEquals = function (lhs, rhs) {
        var lhsName = lhs ? lhs.name : undefined;
        var rhsName = rhs ? rhs.name : undefined;
        return lhsName == rhsName;
    };
    GoogleSheetsResourceSettingsFileComponent.prototype.numberToChar = function (number) {
        if (number >= 1 && number <= 26) {
            return String.fromCharCode(96 + number).toUpperCase();
        }
    };
    GoogleSheetsResourceSettingsFileComponent.prototype.indexToLetters = function (index) {
        var _this = this;
        // TODO: Wrong calculations after 676
        var n = index != 0 ? Math.ceil(LOG(index + 1, 26)) : 1;
        return range(n)
            .map(function (r) {
            var l = (Math.floor(index / Math.pow(26, r)) % 26) + 1 - r;
            return _this.numberToChar(l);
        })
            .reverse()
            .join('');
        // const chars = Number(index).toString(26).split('');
        // const result = chars
        //   .map((item, i) => {
        //     return numberToChar(parseInt(item, 26) + 1);
        //   })
        //   .join('');
    };
    GoogleSheetsResourceSettingsFileComponent.prototype.getSheetDefaultRange = function (sheet) {
        var defaultRange;
        for (var _i = 0, _a = sheet.data; _i < _a.length; _i++) {
            var data = _a[_i];
            if (!data.rowData) {
                continue;
            }
            for (var row = 0; row < data.rowData.length; ++row) {
                var rowData = data.rowData[row];
                if (!rowData.values) {
                    continue;
                }
                var indexes = rowData.values.map(function (item, column) {
                    return item.hasOwnProperty('formattedValue') ? column : undefined;
                });
                if (indexes.every(function (item) { return item === undefined; })) {
                    continue;
                }
                var firstColumn = indexes.find(function (item) { return item !== undefined; });
                var lastColumn = indexes
                    .slice()
                    .reverse()
                    .find(function (item) { return item !== undefined; });
                var fromChar = this.indexToLetters(firstColumn);
                var toChar = this.indexToLetters(lastColumn);
                if (row == 0) {
                    defaultRange = fromChar + ":" + toChar;
                }
                else {
                    var maxRows = 9999;
                    defaultRange = "" + fromChar + (row + 1) + ":" + toChar + (row + maxRows);
                }
                break;
            }
        }
        return defaultRange;
    };
    GoogleSheetsResourceSettingsFileComponent.prototype.getSheetDefaults = function (result) {
        var defaultSheet = result.sheets[0];
        var defaultSheetIndex = defaultSheet ? 0 : undefined;
        return {
            sheetIndex: defaultSheetIndex
        };
    };
    GoogleSheetsResourceSettingsFileComponent.prototype.addFileObserver = function () {
        var _this = this;
        if (this.fileSubscriptions.length) {
            this.fileSubscriptions.forEach(function (item) { return item.unsubscribe(); });
        }
        this.fileSubscriptions = [];
        this.fileSubscriptions.push(controlValue(this.form.controls.file_url)
            .pipe(distinctUntilChanged(), skip(1), switchMap(function (fileUrl) { return _this.getFileByUrl(fileUrl); }), untilDestroyed(this))
            .subscribe(function (file) { return _this.form.controls.file.patchValue(file); }));
        this.fileSubscriptions.push(controlValue(this.form.controls.file)
            .pipe(map(function (item) { return (item ? item.id : undefined); }), distinctUntilChanged(), skip(1), delay(0), untilDestroyed(this))
            .subscribe(function () { return _this.updateSheetOptions(true); }));
        this.fileSubscriptions.push(combineLatest(controlValue(this.form.controls.file), controlValue(this.form.controls.sheet))
            .pipe(map(function (_a) {
            var file = _a[0], sheet = _a[1];
            return ({
                file: file ? file.id : undefined,
                sheet: sheet ? sheet.index : undefined
            });
        }), distinctUntilChanged(function (lhs, rhs) { return isEqual(lhs, rhs); }), skip(1), delay(0), untilDestroyed(this))
            .subscribe(function () { return _this.updateSheetRange(true); }));
    };
    GoogleSheetsResourceSettingsFileComponent.prototype.getFileByUrl = function (fileUrl) {
        var _this = this;
        var sheet = parseSheetUrl(fileUrl);
        if (!sheet || !this.accessToken) {
            return of(undefined);
        }
        var url = "https://sheets.googleapis.com/v4/spreadsheets/" + sheet.id;
        var headers = {
            Authorization: "Bearer " + this.accessToken
        };
        this.fileLoading$.next(true);
        this.cd.markForCheck();
        return this.http
            .get(url, { headers: headers })
            .pipe(switchMap(function (result) {
            var sheetName = result.sheets[0].properties.title;
            var updateUrl = "https://sheets.googleapis.com/v4/spreadsheets/" + sheet.id + "/values/'" + encodeURIComponent(sheetName) + "'!A:A:append";
            var updateParams = {
                valueInputOption: 'raw'
            };
            var data = { values: [] };
            return _this.http.post(updateUrl, data, { headers: headers, params: updateParams }).pipe(map(function () { return true; }), catchError(function () { return of(false); }), map(function (canEdit) {
                return {
                    id: sheet.id,
                    name: result.properties.title,
                    capabilities: {
                        canEdit: canEdit
                    }
                };
            }));
        }), tap(function () {
            _this.fileLoading$.next(false);
            _this.cd.markForCheck();
        }), catchError(function (error) {
            _this.fileLoading$.next(false);
            _this.cd.markForCheck();
            return throwError(error);
        }));
    };
    GoogleSheetsResourceSettingsFileComponent.prototype.updateSheetOptions = function (forceSheetUpdate) {
        var _this = this;
        if (forceSheetUpdate === void 0) { forceSheetUpdate = false; }
        if (this.sheetSubscription) {
            this.sheetSubscription.unsubscribe();
        }
        var file = this.form.controls.file.value;
        if (!file || !this.accessToken) {
            this.sheetOptions = [];
            this.cd.markForCheck();
            return;
        }
        var url = "https://sheets.googleapis.com/v4/spreadsheets/" + file.id;
        var headers = {
            Authorization: "Bearer " + this.accessToken
        };
        this.sheetLoading$.next(true);
        this.cd.markForCheck();
        this.sheetSubscription = this.http
            .get(url, { headers: headers })
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            _this.sheetOptions = result.sheets.map(function (item, i) {
                return {
                    name: item.properties.title,
                    value: { name: item.properties.title, index: i, sheetId: item.properties.sheetId }
                };
            });
            _this.sheetSubscription = undefined;
            _this.sheetLoading$.next(false);
            _this.cd.markForCheck();
            var defaults = _this.getSheetDefaults(result);
            if (!_this.form.controls.verbose_name.value || forceSheetUpdate) {
                _this.form.controls.verbose_name.patchValue(result.properties.title);
            }
            if ((!_this.form.controls.sheet.value || forceSheetUpdate) && defaults.sheetIndex !== undefined) {
                _this.form.controls.sheet.patchValue(_this.sheetOptions[defaults.sheetIndex].value);
            }
        }, function () {
            _this.sheetOptions = [];
            _this.sheetSubscription = undefined;
            _this.sheetLoading$.next(false);
            _this.cd.markForCheck();
        });
    };
    GoogleSheetsResourceSettingsFileComponent.prototype.updateSheetRange = function (forceRangeUpdate) {
        var _this = this;
        if (forceRangeUpdate === void 0) { forceRangeUpdate = false; }
        if (this.rangeSubscription) {
            this.rangeSubscription.unsubscribe();
        }
        var file = this.form.controls.file.value;
        var sheetValue = this.form.controls.sheet.value;
        if (!file || !sheetValue || !this.accessToken) {
            return;
        }
        var url = "https://sheets.googleapis.com/v4/spreadsheets/" + file.id;
        var headers = {
            Authorization: "Bearer " + this.accessToken
        };
        var httpParams = new HttpParams({
            fromObject: {
                includeGridData: 'true',
                ranges: "'" + sheetValue.name + "'!A1:Z1000"
            }
        });
        this.rangeLoading$.next(true);
        this.cd.markForCheck();
        this.rangeSubscription = this.http
            .get(url, { headers: headers, params: httpParams })
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            var sheet = sheetValue ? result.sheets[0] : undefined;
            var defaultRange = sheet ? _this.getSheetDefaultRange(sheet) : undefined;
            if ((!_this.form.controls.range.value || forceRangeUpdate) && defaultRange) {
                _this.form.controls.range.patchValue(defaultRange);
            }
            _this.rangeLoading$.next(false);
            _this.cd.markForCheck();
        }, function () {
            _this.rangeSubscription = undefined;
            _this.rangeLoading$.next(false);
            _this.cd.markForCheck();
        });
    };
    return GoogleSheetsResourceSettingsFileComponent;
}());
export { GoogleSheetsResourceSettingsFileComponent };
