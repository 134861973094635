var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector } from '@angular/core';
import { FormControl } from '@angular/forms';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { Input } from '@modules/fields';
import { ImageMenuItem, MenuBlockLayouts } from '@modules/menu';
import { FieldInputControl } from '@modules/parameters';
import { controlValue, isColorHex, isSet } from '@shared';
import { CustomizeBarPagesEditMenuItemArray, CustomizeBarPagesEditMenuItemControl, menuItemControls } from './customize-bar-pages-edit.form';
import { MenuItemActionControl } from './menu-item-action.control';
var CustomizeBarPagesEditImageControl = /** @class */ (function (_super) {
    __extends(CustomizeBarPagesEditImageControl, _super);
    function CustomizeBarPagesEditImageControl(injector) {
        var _this = _super.call(this, {
            image_option: new FormControl(null),
            image_icon: new FormControl(null),
            image_custom: new FormControl(null),
            image_fill: new FormControl(false),
            image_color: new FormControl(''),
            image_color_custom_enabled: new FormControl(false),
            image_color_custom: new FormControl('#2B50ED'),
            image_size: new FormControl(32),
            image_border_radius: new FormControl(50),
            title: new FieldInputControl({ path: ['value'] }),
            subtitle: new FieldInputControl({ path: ['value'] }),
            action: MenuItemActionControl.inject(injector),
            children: CustomizeBarPagesEditMenuItemArray.inject(injector),
            visible_input: new FieldInputControl({ path: ['value'] })
        }) || this;
        _this.injector = injector;
        return _this;
    }
    CustomizeBarPagesEditImageControl.inject = function (injector) {
        return Injector.create({
            providers: [
                {
                    provide: CustomizeBarPagesEditImageControl,
                    useFactory: function () {
                        return new CustomizeBarPagesEditImageControl(injector);
                    },
                    deps: []
                }
            ],
            parent: injector
        }).get(CustomizeBarPagesEditImageControl);
    };
    Object.defineProperty(CustomizeBarPagesEditImageControl.prototype, "children", {
        get: function () {
            return this.controls['children'];
        },
        enumerable: true,
        configurable: true
    });
    CustomizeBarPagesEditImageControl.prototype.getImageColor = function () {
        if (this.controls.image_color_custom_enabled.value) {
            return this.controls.image_color_custom.value;
        }
        else {
            return this.controls.image_color.value;
        }
    };
    CustomizeBarPagesEditImageControl.prototype.getImageColor$ = function () {
        return combineLatest(controlValue(this.controls.image_color_custom_enabled), controlValue(this.controls.image_color_custom), controlValue(this.controls.image_color)).pipe(map(function (_a) {
            var colorCustomEnabled = _a[0], colorCustom = _a[1], color = _a[2];
            if (colorCustomEnabled) {
                return colorCustom;
            }
            else {
                return color;
            }
        }));
    };
    CustomizeBarPagesEditImageControl.prototype.deserialize = function (item) {
        this.menuItem = item;
        this.controls.image_option.patchValue(item.imageOption);
        this.controls.image_icon.patchValue(item.imageIcon);
        this.controls.image_custom.patchValue(item.imageCustom);
        this.controls.image_fill.patchValue(item.imageFill);
        if (isColorHex(item.imageColor)) {
            this.controls.image_color.patchValue('');
            this.controls.image_color_custom_enabled.patchValue(true);
            this.controls.image_color_custom.patchValue(item.imageColor);
        }
        else {
            this.controls.image_color.patchValue(item.imageColor);
            this.controls.image_color_custom_enabled.patchValue(false);
            this.controls.image_color_custom.patchValue('#2B50ED');
        }
        this.controls.image_size.patchValue(item.imageSize);
        this.controls.image_border_radius.patchValue(item.imageBorderRadius);
        this.controls.title.patchValue(item.titleInput ? item.titleInput.serializeWithoutPath() : {});
        this.controls.subtitle.patchValue(item.subtitleInput ? item.subtitleInput.serializeWithoutPath() : {});
        this.controls.action.deserialize(item.action);
        this.children.deserialize(item.children);
        this.controls.visible_input.patchValue(item.visibleInput ? item.visibleInput.serializeWithoutPath() : {});
        this.markAsPristine();
    };
    CustomizeBarPagesEditImageControl.prototype.serialize = function () {
        var instance = new ImageMenuItem().deserialize(this.menuItem.serialize());
        instance.imageOption = this.controls.image_option.value;
        instance.imageIcon = this.controls.image_icon.value;
        instance.imageCustom = this.controls.image_custom.value;
        instance.imageFill = this.controls.image_fill.value;
        instance.imageColor = this.getImageColor();
        instance.imageSize = this.controls.image_size.value;
        instance.imageBorderRadius = this.controls.image_border_radius.value;
        instance.titleInput = this.controls.title.value ? new Input().deserialize(this.controls.title.value) : undefined;
        instance.subtitleInput = this.controls.subtitle.value
            ? new Input().deserialize(this.controls.subtitle.value)
            : undefined;
        instance.action = this.controls.action.serialize();
        instance.children = this.children.serialize();
        instance.visibleInput = this.controls.visible_input.value
            ? new Input().deserialize(this.controls.visible_input.value)
            : undefined;
        return instance;
    };
    CustomizeBarPagesEditImageControl.prototype.cleanValues = function (layout) {
        var imageSize = this.controls.image_size.value;
        var imageMaxSize = MenuBlockLayouts.getImageMaxSize(layout);
        if (isSet(imageSize) && imageSize > imageMaxSize) {
            this.controls.image_size.patchValue(imageMaxSize);
        }
    };
    return CustomizeBarPagesEditImageControl;
}(CustomizeBarPagesEditMenuItemControl));
export { CustomizeBarPagesEditImageControl };
menuItemControls.push({ menuItem: ImageMenuItem, control: CustomizeBarPagesEditImageControl });
