var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { AppConfigService } from '@core';
import { customOAuth2BackendName, popularSSOProviders, sharedCustomSSO, socialBackends } from '@modules/projects';
import { isPaidSSOSettings, SSOType } from '@modules/sso';
import { ascComparator, deployUrl, isSet } from '@shared';
var SSOTypeMenuComponent = /** @class */ (function () {
    function SSOTypeMenuComponent(appConfigService, cd) {
        var _this = this;
        this.appConfigService = appConfigService;
        this.cd = cd;
        this.ssoSelect = new EventEmitter();
        this.searchControl = new FormControl('');
        this.options = [
            {
                type: SSOType.OAuth2,
                backend: customOAuth2BackendName,
                label: 'Custom OAuth 2.0',
                icon: 'plus',
                orange: true
            },
            {
                type: SSOType.SAML2,
                label: 'Custom SAML2',
                icon: 'plus',
                orange: true
            },
            {
                type: SSOType.Custom,
                label: 'Custom authentication',
                icon: 'plus',
                orange: true
            }
        ].concat(socialBackends
            .filter(function (item) { return item.name != customOAuth2BackendName; })
            .map(function (item) {
            return {
                type: SSOType.OAuth2,
                backend: item.name,
                label: item.label,
                image: item.image
            };
        }).concat(sharedCustomSSO.map(function (item) {
            return {
                type: SSOType.Custom,
                sharedCustomSSO: item.type,
                label: item.label,
                image: item.image
            };
        })).sort(function (lhs, rhs) { return ascComparator(lhs.label.toLowerCase(), rhs.label.toLowerCase()); }));
        this.popularOptions = popularSSOProviders.map(function (item) {
            var isPaid = isPaidSSOSettings(__assign({ type: item.type }, (item.type == SSOType.OAuth2 && {
                oauth2Backend: item.oauth2Backend
            }), (item.type == SSOType.Custom && {
                sharedCustomSSO: item.sharedCustomSSO
            })));
            var result = _this.options.find(function (option) {
                if (item.type == SSOType.OAuth2) {
                    return option.backend == item.oauth2Backend;
                }
                else if (item.type == SSOType.Custom) {
                    return option.sharedCustomSSO == item.sharedCustomSSO;
                }
                else {
                    return false;
                }
            });
            return __assign({}, result, (!isPaid && {
                description: '(All plans)'
            }));
        });
        this.filteredOptions = [];
    }
    SSOTypeMenuComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.updateFilteredOptions();
        this.searchControl.valueChanges.pipe(untilDestroyed(this)).subscribe(function () { return _this.updateFilteredOptions(); });
    };
    SSOTypeMenuComponent.prototype.ngOnDestroy = function () { };
    SSOTypeMenuComponent.prototype.getFilteredOptions = function () {
        var search = this.searchControl.value.toLowerCase().trim();
        if (!isSet(search)) {
            return this.options;
        }
        return this.options.filter(function (item) {
            return item.label.toLowerCase().includes(search);
        });
    };
    SSOTypeMenuComponent.prototype.updateFilteredOptions = function () {
        this.filteredOptions = this.getFilteredOptions();
        this.cd.markForCheck();
    };
    SSOTypeMenuComponent.prototype.clearSearch = function () {
        this.searchControl.patchValue('');
        this.updateFilteredOptions();
    };
    SSOTypeMenuComponent.prototype.selectOption = function (option) {
        this.ssoSelect.emit({
            name: option.label,
            image: option.image ? deployUrl("/assets/images/sso/" + option.image + ".svg") : undefined,
            type: option.type,
            backend: option.backend,
            sharedCustomSSO: option.sharedCustomSSO
        });
    };
    return SSOTypeMenuComponent;
}());
export { SSOTypeMenuComponent };
