import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { NgGxScrollableModule } from 'ng-gxscrollable';
import { NgGxSelectModule } from 'ng-gxselect';

import { BannersModule } from '@common/banners';
import { DragDropModule } from '@common/drag-drop';
import { DynamicComponentModule } from '@common/dynamic-component';
import { EditableContentModule } from '@common/editable-content';
import { LocalizeModule } from '@common/localize';
import { PopoverModule } from '@common/popover';
import { TipsModule } from '@common/tips';
import { UniqueIdModule } from '@common/unique-id';
import { AnalyticsModule } from '@modules/analytics';
import { ApiModule } from '@modules/api';
import { ColorsSharedModule } from '@modules/colors-shared';
import { DemoModule } from '@modules/demo';
import { FieldComponentsModule } from '@modules/field-components';
import { FieldsModule } from '@modules/fields';
import { IconsModule } from '@modules/icons';
import { MenuModule } from '@modules/menu';
import { MenuProfileModule } from '@modules/menu-profile';
import { RoutingModule } from '@modules/routing';
import { ThemeModule } from '@modules/theme';
import { ThemeComponentsModule } from '@modules/theme-components';
import { TutorialModule } from '@modules/tutorial';
import { ViewsComponentsModule } from '@modules/views-components';
import { SharedModule } from '@shared';
import { UiModule } from '@ui';

import { AutoMenuItemComponent } from './components/items/auto-menu-item/auto-menu-item.component';
import { ButtonMenuItemComponent } from './components/items/button-menu-item/button-menu-item.component';
import { CustomMenuItemComponent } from './components/items/custom-menu-item/custom-menu-item.component';
import { ImageMenuItemComponent } from './components/items/image-menu-item/image-menu-item.component';
import { MenuItemComponent } from './components/items/menu-item/menu-item.component';
import { ModelLinkMenuItemComponent } from './components/items/model-link-menu-item/model-link-menu-item.component';
import { SectionMenuItemComponent } from './components/items/section-menu-item/section-menu-item.component';
import { SeparatorMenuItemComponent } from './components/items/separator-menu-item/separator-menu-item.component';
import { ShareMenuItemComponent } from './components/items/share-menu-item/share-menu-item.component';
import { SimpleMenuItemComponent } from './components/items/simple-menu-item/simple-menu-item.component';
import { MenuPrimaryStubComponent } from './components/menu-primary-stub/menu-primary-stub.component';
import { MenuPrimaryComponent } from './components/menu-primary/menu-primary.component';
import { MenuSecondaryStubComponent } from './components/menu-secondary-stub/menu-secondary-stub.component';
import { MenuSecondaryComponent } from './components/menu-secondary/menu-secondary.component';
import { MenuWrapperComponent } from './components/menu-wrapper/menu-wrapper.component';
import { ProjectEmptyComponent } from './components/project-empty/project-empty.component';
import { SidebarEmptyComponent } from './components/sidebar-empty/sidebar-empty.component';
import { MenuBlockDropdownStyleDirective } from './directives/menu-block-dropdown-style/menu-block-dropdown-style.directive';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgGxSelectModule,
    NgGxScrollableModule,
    IconsModule,
    ApiModule,
    DragDropModule,
    MatMenuModule,
    OverlayModule,
    RoutingModule,
    SharedModule,
    DynamicComponentModule,
    UniqueIdModule,
    PopoverModule,
    FieldsModule,
    FieldComponentsModule,
    MenuModule,
    ThemeModule,
    DemoModule,
    TipsModule,
    TutorialModule,
    AnalyticsModule,
    MenuProfileModule,
    EditableContentModule,
    RouterModule,
    BannersModule,
    LocalizeModule,
    UiModule,
    ThemeComponentsModule,
    ColorsSharedModule,
    ViewsComponentsModule
  ],
  declarations: [
    MenuWrapperComponent,
    AutoMenuItemComponent,
    SectionMenuItemComponent,
    ModelLinkMenuItemComponent,
    SimpleMenuItemComponent,
    ImageMenuItemComponent,
    ButtonMenuItemComponent,
    SeparatorMenuItemComponent,
    ShareMenuItemComponent,
    CustomMenuItemComponent,
    MenuItemComponent,
    MenuPrimaryComponent,
    MenuSecondaryComponent,
    MenuSecondaryStubComponent,
    MenuPrimaryStubComponent,
    ProjectEmptyComponent,
    SidebarEmptyComponent,
    MenuBlockDropdownStyleDirective
  ],
  exports: [
    MenuWrapperComponent,
    MenuSecondaryStubComponent,
    MenuPrimaryStubComponent,
    ProjectEmptyComponent,
    AutoMenuItemComponent,
    SidebarEmptyComponent
  ],
  entryComponents: [
    SectionMenuItemComponent,
    ModelLinkMenuItemComponent,
    SimpleMenuItemComponent,
    ImageMenuItemComponent,
    ButtonMenuItemComponent,
    SeparatorMenuItemComponent,
    ShareMenuItemComponent,
    CustomMenuItemComponent
  ]
})
export class MenuComponentsModule {}
