import { Directive, HostBinding, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import toPairs from 'lodash/toPairs';

import { BorderSettings, Corners, Margin, Shadow } from '@modules/customize';
import { BorderRadius } from '@modules/theme';

import { ThemeContext } from '../../data/theme-context';
import { ThemeOptions } from '../../data/theme-options';
import { getThemeVars } from '../../utils/common';

@Directive({
  selector: '[appThemeOptions]'
})
export class ThemeOptionsDirective implements OnInit, OnDestroy, OnChanges {
  @Input() applyElementStyle = false;
  @Input() applyGlobalStyle = true;
  @Input() accentColor: string;
  @Input() accentColorDark: string;
  @Input() backgroundColor: string;
  @Input() backgroundColorDark: string;
  @Input() backgroundColor2: string;
  @Input() backgroundColor2Dark: string;
  @Input() backgroundColor3: string;
  @Input() backgroundColor3Dark: string;
  @Input() backgroundColor4: string;
  @Input() backgroundColor4Dark: string;
  @Input() backgroundColor5: string;
  @Input() backgroundColor5Dark: string;
  @Input() textColor: string;
  @Input() textColorDark: string;
  @Input() textColor2: string;
  @Input() textColor2Dark: string;
  @Input() textColor3: string;
  @Input() textColor3Dark: string;
  @Input() borderColor: string;
  @Input() borderColorDark: string;
  @Input() borderColor2: string;
  @Input() borderColor2Dark: string;
  @Input() borderColor3: string;
  @Input() borderColor3Dark: string;
  @Input() borderRadius: BorderRadius;
  @Input() fontRegular: string;
  @Input() fontHeading: string;
  @Input() elementWrapperBorder: BorderSettings;
  @Input() elementWrapperBorderRadius: Corners;
  @Input() elementWrapperShadow: Shadow;
  @Input() elementWrapperMargin: Margin;

  @HostBinding('style') style: SafeStyle;
  @HostBinding('attr.data-style-vars') attrDataStyleVars: string;

  constructor(private themeContext: ThemeContext, private sanitizer: DomSanitizer) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.updateStyle();
  }

  getThemeOptions(): ThemeOptions {
    return {
      accentColor: this.accentColor,
      accentColorDark: this.accentColorDark,
      backgroundColor: this.backgroundColor,
      backgroundColorDark: this.backgroundColorDark,
      backgroundColor2: this.backgroundColor2,
      backgroundColor2Dark: this.backgroundColor2Dark,
      backgroundColor3: this.backgroundColor3,
      backgroundColor3Dark: this.backgroundColor3Dark,
      backgroundColor4: this.backgroundColor4,
      backgroundColor4Dark: this.backgroundColor4Dark,
      backgroundColor5: this.backgroundColor5,
      backgroundColor5Dark: this.backgroundColor5Dark,
      textColor: this.textColor,
      textColorDark: this.textColorDark,
      textColor2: this.textColor2,
      textColor2Dark: this.textColor2Dark,
      textColor3: this.textColor3,
      textColor3Dark: this.textColor3Dark,
      borderColor: this.borderColor,
      borderColorDark: this.borderColorDark,
      borderColor2: this.borderColor2,
      borderColor2Dark: this.borderColor2Dark,
      borderColor3: this.borderColor3,
      borderColor3Dark: this.borderColor3Dark,
      borderRadius: this.borderRadius,
      fontRegular: this.fontRegular,
      fontHeading: this.fontHeading,
      elementWrapperBorder: this.elementWrapperBorder,
      elementWrapperBorderRadius: this.elementWrapperBorderRadius,
      elementWrapperShadow: this.elementWrapperShadow,
      elementWrapperMargin: this.elementWrapperMargin
    };
  }

  updateStyle(): void {
    const options = this.getThemeOptions();

    if (this.applyElementStyle) {
      const vars = getThemeVars(options);
      const styles = toPairs(vars)
        .map(([k, v]) => `--${k}: ${v}`)
        .join(';');

      this.style = this.sanitizer.bypassSecurityTrustStyle(styles);
      this.attrDataStyleVars = toPairs(vars)
        .map(([k]) => k)
        .join(' ');
    } else {
      this.style = undefined;
      this.attrDataStyleVars = undefined;
    }

    this.themeContext.options = options;

    if (this.applyGlobalStyle) {
      this.themeContext.applyContainersStyle();
    } else {
      this.themeContext.clearContainersStyle();
    }
  }
}
