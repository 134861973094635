var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, ElementRef, Injector, OnChanges, OnDestroy, OnInit, QueryList, SimpleChanges } from '@angular/core';
import clone from 'lodash/clone';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import values from 'lodash/values';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { NotificationService } from '@common/notifications';
import { PopoverService } from '@common/popover';
import { PopupService } from '@common/popups';
import { ActionControllerService, patchModel } from '@modules/action-queries';
import { UniversalAnalyticsService } from '@modules/analytics';
import { CustomViewsStore } from '@modules/custom-views';
import { CustomizeService, getModelAttributesByColumns, getModelBulkAttributesByColumns, ListDefaultSelection, rawListViewSettingsColumnsToViewContextOutputs, ViewSettingsService, ViewSettingsStore } from '@modules/customize';
import { CustomizeBarContext, CustomizeBarService } from '@modules/customize-bar';
import { DataSourceType } from '@modules/data-sources';
import { applyParamInput$, applyParamInputs$, DisplayFieldType, FieldType, LOADING_VALUE, NOT_SET_VALUE } from '@modules/fields';
import { EMPTY_FILTER_VALUES } from '@modules/filters';
import { ListLayoutType } from '@modules/layouts';
import { CHECKED_ITEMS_OUTPUT, EMPTY_OUTPUT, HAS_SELECTED_ITEM_OUTPUT, ITEM_OUTPUT, NO_SELECTED_ITEM_OUTPUT, SELECTED_ITEM_OUTPUT } from '@modules/list';
import { ListLayoutComponent } from '@modules/list-components';
import { MenuSettingsStore } from '@modules/menu';
import { ModelDescriptionStore } from '@modules/model-queries';
import { NEXT_PAGE_SCROLL_PARAM, PAGE_PARAM } from '@modules/models';
import { InputService } from '@modules/parameters';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { paramsToGetQueryOptions } from '@modules/resources';
import { RoutingService } from '@modules/routing';
import { isSet } from '@shared';
import { GridGroupComponent } from '../grid-group/grid-group.component';
import { getListStateColumns, getListStateDefaultSelection, getListStateFetch, getListStateFetchNewParams, getListStateSelection } from './grid-state';
var GridComponent = /** @class */ (function (_super) {
    __extends(GridComponent, _super);
    function GridComponent(customizeService, customViewsStore, actionControllerService, notificationService, injector, cd, customizeBarContext, customizeBarService, analyticsService, viewSettingsService, viewSettingsStore, menuSettingsStore, modelDescriptionStore, inputService, routing, currentProjectStore, currentEnvironmentStore, popupService, popoverService) {
        var _this = _super.call(this, injector, cd, customizeBarContext, customizeBarService, analyticsService, viewSettingsService, viewSettingsStore, menuSettingsStore, modelDescriptionStore, inputService, routing, currentProjectStore, currentEnvironmentStore, popupService, popoverService) || this;
        _this.customizeService = customizeService;
        _this.customViewsStore = customViewsStore;
        _this.actionControllerService = actionControllerService;
        _this.notificationService = notificationService;
        _this.groupComponents = new QueryList();
        _this.layout = ListLayoutType.Grid;
        _this.viewportWidth$ = new BehaviorSubject(undefined);
        _this.cardView$ = new BehaviorSubject(undefined);
        _this.visibleColumns = [];
        _this.loading = true;
        _this.configured = true;
        _this.checkedItems = {};
        _this.rowCards = 1;
        _this.cardDefaultHeight = 245;
        return _this;
    }
    GridComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.initContext();
        this.settings$
            .pipe(filter(function (settings) { return !!settings; }), switchMap(function (settings) {
            if (!isSet(settings.cardCustomView)) {
                return of(undefined);
            }
            return _this.customViewsStore.getDetail(settings.cardCustomView);
        }), untilDestroyed(this))
            .subscribe(function (customView) {
            _this.cardView$.next(customView ? customView.view : undefined);
        });
        combineLatest(this.settings$, this.cardView$, this.viewportWidth$)
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var settings = _a[0], cardView = _a[1], viewportWidth = _a[2];
            _this.rowCards = _this.getRowCards(settings, cardView, viewportWidth);
            _this.cardDefaultHeight = cardView ? cardView.frame.height : undefined;
            _this.cd.markForCheck();
        });
    };
    GridComponent.prototype.ngOnDestroy = function () { };
    GridComponent.prototype.ngOnChanges = function (changes) {
        _super.prototype.ngOnChanges.call(this, changes);
    };
    GridComponent.prototype.getListState = function (settings, params, filters, search, sort) {
        var _this = this;
        var page = parseInt(params[PAGE_PARAM], 10) || 1;
        params = cloneDeep(params);
        delete params[NEXT_PAGE_SCROLL_PARAM];
        if (!sort.length && isSet(settings.sortingField)) {
            sort = [{ field: settings.sortingField, desc: !settings.sortingAsc }];
        }
        var staticData$ = settings.dataSource && settings.dataSource.type == DataSourceType.Input && settings.dataSource.input
            ? applyParamInput$(settings.dataSource.input, {
                context: this.context,
                defaultValue: [],
                handleLoading: true,
                ignoreEmpty: true
            }).pipe(distinctUntilChanged(function (lhs, rhs) { return isEqual(lhs, rhs); }))
            : of([]);
        var inputParams$ = settings.dataSource
            ? applyParamInputs$({}, settings.dataSource.queryInputs, {
                context: this.context,
                parameters: settings.dataSource.queryParameters,
                handleLoading: true,
                ignoreEmpty: true,
                emptyValues: EMPTY_FILTER_VALUES
            }).pipe(distinctUntilChanged(function (lhs, rhs) { return isEqual(lhs, rhs); }))
            : of({});
        return combineLatest(staticData$, inputParams$, this.getQueryModelDescription(settings.dataSource)).pipe(map(function (_a) {
            var staticData = _a[0], inputParams = _a[1], modelDescription = _a[2];
            var resource = settings.dataSource
                ? _this.currentEnvironmentStore.resources.find(function (item) { return item.uniqueName == settings.dataSource.queryResource; })
                : undefined;
            return {
                settings: settings,
                dataSource: settings.dataSource,
                dataSourceStaticData: staticData,
                dataSourceParams: __assign({}, inputParams, params),
                userParams: params,
                filters: filters,
                search: search,
                sort: sort,
                groupCollapse: settings.groupCollapse,
                resource: resource,
                modelDescription: modelDescription,
                inputsLoading: [inputParams, staticData].some(function (obj) {
                    return obj == LOADING_VALUE || values(obj).some(function (item) { return item === LOADING_VALUE; });
                }),
                inputsNotSet: [inputParams, staticData].some(function (obj) {
                    return obj == NOT_SET_VALUE || values(obj).some(function (item) { return item === NOT_SET_VALUE; });
                }),
                page: page,
                perPage: settings ? settings.perPage : undefined,
                sortingField: settings ? settings.sortingField : undefined,
                sortingAsc: settings ? settings.sortingAsc : undefined,
                groupField: settings ? settings.groupField : undefined,
                groups: settings ? settings.groups : undefined,
                multipleSelection: settings.multipleSelection,
                defaultSelection: settings ? settings.defaultSelection : undefined
            };
        }));
    };
    GridComponent.prototype.onStateUpdated = function (state) {
        if (!isEqual(getListStateColumns(state), getListStateColumns(this.listState)) ||
            !isEqual(getListStateSelection(state), getListStateSelection(this.listState))) {
            this.updateContextOutputs(state);
        }
        if (!isEqual(getListStateColumns(state), getListStateColumns(this.listState))) {
            this.updateVisibleColumns(state);
        }
        if (!isEqual(getListStateFetch(state), getListStateFetch(this.listState))) {
            var newParams = !isEqual(getListStateFetchNewParams(state), getListStateFetchNewParams(this.listState));
            var paramsNeedUpdate = false;
            if (newParams) {
                this.setSelectedItem(undefined, false);
                this.setChecked([], false);
                this.updateSelectedContext();
                if (this.setPage(1)) {
                    paramsNeedUpdate = true;
                }
            }
            if (!paramsNeedUpdate) {
                this.fetch(state);
            }
        }
        else {
            if (!isEqual(getListStateDefaultSelection(state), getListStateDefaultSelection(this.listState)) &&
                state.defaultSelection == ListDefaultSelection.First) {
                var component = this.groupComponents.toArray()[0];
                var componentItems = component ? component.getItems() : undefined;
                this.setSelectedItem(componentItems ? componentItems[0] : undefined);
            }
        }
    };
    GridComponent.prototype.updateLoading = function () {
        this.loading = this.groupComponents.toArray().some(function (item) { return item.loading; });
        this.cd.markForCheck();
    };
    GridComponent.prototype.getStateQueryOptions = function (state) {
        var queryOptions = paramsToGetQueryOptions(state.dataSourceParams);
        queryOptions.filters = (queryOptions.filters ? queryOptions.filters : []).concat((state.filters ? state.filters : []));
        queryOptions.search = state.search;
        queryOptions.sort = state.sort;
        return queryOptions;
    };
    GridComponent.prototype.fetch = function (state) {
        this.configured = state.dataSource && state.dataSource.isConfigured();
        this.parameters = this.getParameters(state);
        this.inputs = this.getInputs(state);
        this.selectedItem = undefined;
        this.checkedItems = {};
        this.cd.markForCheck();
    };
    GridComponent.prototype.onFetch = function () {
        this.loading = true;
        this.setChecked([]);
        this.cd.markForCheck();
        this.contextElement.setOutputValue(EMPTY_OUTPUT, false, { loading: true });
    };
    GridComponent.prototype.onFetched = function (items) {
        if (this.selectedItem && items) {
            this.checkSelectedModelIsActual(items);
        }
        this.updateLoading();
        if (!this.loading) {
            var isEmpty = this.groupComponents.toArray().every(function (item) {
                var groupItems = item.getItems();
                return groupItems && !groupItems.length;
            });
            this.contextElement.setOutputValue(EMPTY_OUTPUT, isEmpty, { loading: false });
        }
    };
    GridComponent.prototype.reloadData = function () {
        _super.prototype.reloadData.call(this);
        this.groupComponents.forEach(function (item) { return item.reloadData(); });
    };
    GridComponent.prototype.getRowCardMinWidth = function (cardView) {
        if (cardView && cardView.widthResize.enabled && isSet(cardView.widthResize.min)) {
            return cardView.widthResize.min;
        }
        else if (cardView && isSet(cardView.frame.width)) {
            return cardView.frame.width;
        }
        else {
            return 240;
        }
    };
    GridComponent.prototype.getRowCards = function (settings, cardView, viewportWidth) {
        var cardMinWidth = this.getRowCardMinWidth(cardView);
        var rowMaxCards = isSet(viewportWidth) ? Math.max(Math.floor(viewportWidth / cardMinWidth), 1) : 1;
        return settings && isSet(settings.cardsPerRow) && settings.cardsPerRow <= rowMaxCards
            ? settings.cardsPerRow
            : rowMaxCards;
    };
    GridComponent.prototype.trackGroupFn = function (i, item) {
        return item.uid;
    };
    GridComponent.prototype.updateVisibleColumns = function (state) {
        this.visibleColumns = state.dataSource.columns.filter(function (item) { return item.visible; });
        this.cd.markForCheck();
    };
    GridComponent.prototype.initContext = function () {
        var _this = this;
        this.contextElement.setActions([
            {
                uniqueName: 'update_data',
                name: 'Update Data',
                icon: 'repeat',
                parameters: [],
                handler: function () { return _this.reloadData(); }
            },
            {
                uniqueName: 'clear_selected_item',
                name: 'Reset Selected Card',
                icon: 'deselect',
                parameters: [],
                handler: function () {
                    _this.setSelectedItem(undefined);
                    _this.setChecked([]);
                }
            },
            {
                uniqueName: 'clear_filters',
                name: 'Reset Filters',
                icon: 'delete',
                parameters: [],
                handler: function () { return _this.resetFilters(); }
            }
        ]);
    };
    GridComponent.prototype.updateContextOutputs = function (state) {
        var columns = state.dataSource ? state.dataSource.columns : [];
        this.contextElement.setOutputs([
            {
                uniqueName: ITEM_OUTPUT,
                name: 'Current Card',
                icon: 'duplicate_2',
                internal: true,
                byPathOnly: true,
                allowSkip: true,
                children: rawListViewSettingsColumnsToViewContextOutputs(columns.filter(function (item) { return item.type != DisplayFieldType.Computed; }), state.modelDescription)
            },
            {
                uniqueName: SELECTED_ITEM_OUTPUT,
                name: 'Selected Card',
                icon: 'hand',
                children: rawListViewSettingsColumnsToViewContextOutputs(columns, state.modelDescription)
            },
            {
                uniqueName: HAS_SELECTED_ITEM_OUTPUT,
                name: 'Is any Card selected',
                icon: 'select_all',
                fieldType: FieldType.Boolean,
                defaultValue: false
            },
            {
                uniqueName: NO_SELECTED_ITEM_OUTPUT,
                name: 'No Card selected',
                icon: 'deselect',
                fieldType: FieldType.Boolean,
                defaultValue: true
            },
            {
                uniqueName: EMPTY_OUTPUT,
                name: 'Is Empty',
                icon: 'uncheck',
                fieldType: FieldType.Boolean,
                defaultValue: false
            }
        ].concat((state.multipleSelection
            ? [
                {
                    uniqueName: CHECKED_ITEMS_OUTPUT,
                    name: 'Checked Cards',
                    icon: 'check',
                    children: rawListViewSettingsColumnsToViewContextOutputs(columns, state.modelDescription)
                }
            ]
            : [])));
        this.updateSelectedContext();
    };
    GridComponent.prototype.updateSelectedContext = function () {
        var columns = this.settings.dataSource ? this.settings.dataSource.columns : [];
        if (this.selectedItem) {
            this.contextElement.setOutputValue(SELECTED_ITEM_OUTPUT, getModelAttributesByColumns(this.selectedItem.model, columns));
            this.contextElement.setOutputValue(HAS_SELECTED_ITEM_OUTPUT, true);
            this.contextElement.setOutputValue(NO_SELECTED_ITEM_OUTPUT, false);
        }
        else {
            this.contextElement.setOutputValue(SELECTED_ITEM_OUTPUT, undefined);
            this.contextElement.setOutputValue(HAS_SELECTED_ITEM_OUTPUT, false);
            this.contextElement.setOutputValue(NO_SELECTED_ITEM_OUTPUT, true);
        }
        if (this.settings.multipleSelection) {
            var models = values(this.checkedItems);
            this.contextElement.setOutputValue(CHECKED_ITEMS_OUTPUT, getModelBulkAttributesByColumns(models, columns));
        }
        else {
            this.contextElement.setOutputValue(CHECKED_ITEMS_OUTPUT, getModelBulkAttributesByColumns([], columns));
        }
    };
    GridComponent.prototype.isItemSelected = function (item, group, index) {
        if (this.settings.multipleSelection) {
            return this.isItemChecked(item, group, index);
        }
        else {
            return this.itemEquals(this.selectedItem, item);
        }
    };
    GridComponent.prototype.isItemChecked = function (item, group, index) {
        var pk = item.model.primaryKey || "" + index;
        return this.checkedItems[pk];
    };
    GridComponent.prototype.setSelectedItem = function (item, updateContext) {
        if (updateContext === void 0) { updateContext = true; }
        this.selectedItem = item;
        this.cd.markForCheck();
        if (updateContext) {
            this.updateSelectedContext();
        }
    };
    GridComponent.prototype.setChecked = function (value, updateContext) {
        if (updateContext === void 0) { updateContext = true; }
        this.checkedItems = value.reduce(function (acc, item) {
            var pk = item.model.primaryKey;
            acc[pk] = item.model;
            return acc;
        }, {});
        this.cd.markForCheck();
        if (updateContext) {
            this.updateSelectedContext();
        }
    };
    GridComponent.prototype.toggleSelectedItem = function (item, group, index, element, click) {
        if (click === void 0) { click = false; }
        var _a;
        if (this.selectedItem === item) {
            this.setSelectedItem(undefined, false);
        }
        else {
            this.setSelectedItem(item, false);
        }
        if (this.settings.multipleSelection) {
            var pk = item.model.primaryKey || group + "_" + index;
            var checked = this.isItemChecked(item, group, index);
            if (!checked) {
                var checkedItems = clone(this.checkedItems);
                checkedItems[pk] = item.model;
                this.checkedItems = checkedItems;
            }
            else {
                var checkedItems = clone(this.checkedItems);
                delete checkedItems[pk];
                this.checkedItems = checkedItems;
            }
        }
        this.updateSelectedContext();
        if (click && this.settings.cardClickAction && this.selectedItem) {
            this.actionControllerService
                .execute(this.settings.cardClickAction, {
                context: this.contextElement.context,
                contextElement: this.contextElement,
                localContext: (_a = {},
                    _a[ITEM_OUTPUT] = this.selectedItem.model.getAttributes(),
                    _a),
                injector: this.injector,
                origin: element
            })
                .subscribe();
        }
    };
    GridComponent.prototype.onModelUpdated = function (model) {
        if (this.selectedItem && this.selectedItem.model.isSame(model)) {
            this.updateSelectedContext();
        }
        var checkedModels = values(this.checkedItems);
        if (checkedModels.some(function (item) { return item.isSame(model); })) {
            this.updateSelectedContext();
        }
    };
    GridComponent.prototype.onResize = function () {
        var width = this.viewportElement ? this.viewportElement.nativeElement.offsetWidth : undefined;
        this.viewportWidth$.next(width);
    };
    GridComponent.prototype.getAnyModel = function () {
        for (var _i = 0, _a = this.groupComponents.toArray(); _i < _a.length; _i++) {
            var component = _a[_i];
            var items = component.getItems();
            if (items && items.length) {
                return items[0].model;
            }
        }
    };
    GridComponent.prototype.checkSelectedModelIsActual = function (items) {
        var _this = this;
        var actualSelectedItem = items.find(function (item) { return item.model.isSame(_this.selectedItem.model); });
        if (actualSelectedItem &&
            !isEqual(actualSelectedItem.model.getAttributes(), this.selectedItem.model.getAttributes())) {
            this.selectedItem.model = patchModel(this.selectedItem.model, actualSelectedItem.model);
            this.cd.markForCheck();
            this.onModelUpdated(this.selectedItem.model);
        }
    };
    return GridComponent;
}(ListLayoutComponent));
export { GridComponent };
