import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';
import range from 'lodash/range';

import { TypedChanges } from '@shared';

@Component({
  selector: 'app-grid-stub',
  templateUrl: './grid-stub.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridStubComponent implements OnInit, OnChanges {
  @Input() header = true;
  @Input() columns = 5;
  @Input() rows = 4;
  @Input() rowCards = 1;
  @Input() cardHeight = 245;
  @Input() gapHorizontal: number;
  @Input() gapVertical: number;
  @Input() animating = false;
  @Input() scrollable = false;
  @Input() height: number;
  @Input() theme = false;

  rowsRange: number[];

  constructor() {}

  ngOnInit() {
    this.updateRanges();
  }

  ngOnChanges(changes: TypedChanges<GridStubComponent>): void {
    if (changes.rows) {
      this.updateRanges();
    }
  }

  updateRanges() {
    this.rowsRange = range(1, this.rows + 1);
  }
}
