<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayPanelClass]="['overlay', 'overlay_horizontal_center']"
  [cdkConnectedOverlayPositions]="popoverPositions"
  [cdkConnectedOverlayOrigin]="origin"
  [cdkConnectedOverlayOpen]="dropdownOpened"
  [cdkConnectedOverlayPush]="true"
  [cdkConnectedOverlayGrowAfterOpen]="true"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayBackdropClass]="'popover2-backdrop'"
  (backdropClick)="setDropdownOpened(false)"
  (detach)="setDropdownOpened(false)"
>
  <div class="popover2 overlay-content overlay-content_animated">
    <div class="popover2__inner popover2__inner_sidebar">
      <div class="popover2__viewport popover2__viewport_scrollable" [style.max-height.vh]="80">
        <div [style.width.px]="300">
          <div class="sidebar__element sidebar__element_margin_s">
            <div class="button-group">
              <div
                class="button-group__item"
                [class.button-group__item_active]="!control.controls.per_corners.value"
                (click)="control.setPerCorners(false)"
              >
                All corners
              </div>

              <div
                class="button-group__item"
                [class.button-group__item_active]="control.controls.per_corners.value"
                (click)="control.setPerCorners(true)"
              >
                Per corner
              </div>
            </div>
          </div>

          <ng-container *ngIf="!control.controls.per_corners.value">
            <div class="sidebar__element sidebar__element_margin_s">
              <div class="sidebar-cols sidebar-cols_align_center">
                <div class="sidebar-cols__item sidebar-cols__item_fit">
                  <div
                    class="sidebar__element sidebar__element_margin_s sidebar__element_padding_none sidebar__element_first"
                  >
                    <span class="icon-corner_top_left"></span>
                  </div>

                  <div
                    class="sidebar__element sidebar__element_margin_s sidebar__element_padding_none sidebar__element_last"
                  >
                    <span class="icon-corner_bottom_left"></span>
                  </div>
                </div>

                <div class="sidebar-cols__item" [style.max-width.px]="156">
                  <div class="input-group">
                    <input
                      type="text"
                      class="input-group__item input-group__item_first input-group__fill input input_small"
                      [formControl]="control.controls.all"
                      [appInputFormat]="{ type: numberFieldTypes.Integer, min: 0, numberKeyboardControl: true }"
                      [appAutofocus]="added"
                    />

                    <div
                      class="input-group__item input-group__item_last input input_small input_disabled"
                      [style.padding-left.px]="10"
                      [style.padding-right.px]="10"
                    >
                      px
                    </div>
                  </div>
                </div>

                <div class="sidebar-cols__item sidebar-cols__item_fit">
                  <div
                    class="sidebar__element sidebar__element_margin_s sidebar__element_padding_none sidebar__element_first"
                  >
                    <span class="icon-corner_top_right"></span>
                  </div>

                  <div
                    class="sidebar__element sidebar__element_margin_s sidebar__element_padding_none sidebar__element_last"
                  >
                    <span class="icon-corner_bottom_right"></span>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="control.controls.per_corners.value">
            <div class="sidebar__element sidebar__element_margin_s">
              <div class="sidebar-cols sidebar-cols_align_center">
                <div class="sidebar-cols__item sidebar-cols__item_fit">
                  <span class="icon-corner_top_left"></span>
                </div>

                <div class="sidebar-cols__item">
                  <div class="input-group">
                    <input
                      type="text"
                      class="input-group__item input-group__item_first input-group__fill input input_small"
                      [formControl]="control.controls.top_left"
                      [appInputFormat]="{ type: numberFieldTypes.Integer, min: 0, numberKeyboardControl: true }"
                    />

                    <div
                      class="input-group__item input-group__item_last input input_small input_disabled"
                      [style.padding-left.px]="10"
                      [style.padding-right.px]="10"
                    >
                      px
                    </div>
                  </div>
                </div>

                <div class="sidebar-cols__item">
                  <div class="input-group">
                    <input
                      type="text"
                      class="input-group__item input-group__item_first input-group__fill input input_small"
                      [formControl]="control.controls.top_right"
                      [appInputFormat]="{ type: numberFieldTypes.Integer, min: 0, numberKeyboardControl: true }"
                    />

                    <div
                      class="input-group__item input-group__item_last input input_small input_disabled"
                      [style.padding-left.px]="10"
                      [style.padding-right.px]="10"
                    >
                      px
                    </div>
                  </div>
                </div>

                <div class="sidebar-cols__item sidebar-cols__item_fit">
                  <span class="icon-corner_top_right"></span>
                </div>
              </div>
            </div>

            <div class="sidebar__element">
              <div class="sidebar-cols sidebar-cols_align_center">
                <div class="sidebar-cols__item sidebar-cols__item_fit">
                  <span class="icon-corner_bottom_left"></span>
                </div>

                <div class="sidebar-cols__item">
                  <div class="input-group">
                    <input
                      type="text"
                      class="input-group__item input-group__item_first input-group__fill input input_small"
                      [formControl]="control.controls.bottom_left"
                      [appInputFormat]="{ type: numberFieldTypes.Integer, min: 0, numberKeyboardControl: true }"
                    />

                    <div
                      class="input-group__item input-group__item_last input input_small input_disabled"
                      [style.padding-left.px]="10"
                      [style.padding-right.px]="10"
                    >
                      px
                    </div>
                  </div>
                </div>

                <div class="sidebar-cols__item">
                  <div class="input-group">
                    <input
                      type="text"
                      class="input-group__item input-group__item_first input-group__fill input input_small"
                      [formControl]="control.controls.bottom_right"
                      [appInputFormat]="{ type: numberFieldTypes.Integer, min: 0, numberKeyboardControl: true }"
                    />

                    <div
                      class="input-group__item input-group__item_last input input_small input_disabled"
                      [style.padding-left.px]="10"
                      [style.padding-right.px]="10"
                    >
                      px
                    </div>
                  </div>
                </div>

                <div class="sidebar-cols__item sidebar-cols__item_fit">
                  <span class="icon-corner_bottom_right"></span>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<div
  class="sidebar-list-item sidebar-list-item_compact sidebar-list-item_clickable"
  [class.sidebar-list-item_contrast]="contrast"
  [class.sidebar-list-item_disabled]="disabled || dropdownOpened"
  (click)="setDropdownOpened(true)"
  cdkOverlayOrigin
  #origin="cdkOverlayOrigin"
>
  <div class="sidebar-list-item__left">
    <div
      class="sidebar-list-item__image sidebar-list-item__image_size_l"
      [style.background-image]="
        'assets/images/border-radius/border-radius-' + valueBorderRadius + '.svg' | appDeployCssUrl
      "
    ></div>
  </div>

  <div class="sidebar-list-item__main">
    <ng-container *ngIf="valueStr | appIsSet">
      <div class="sidebar-list-item__title">{{ valueStr }}</div>
      <div class="sidebar-list-item__description">
        {{ subtitle }}
        <ng-container *ngIf="additional | appIsSet"> {{ additional }}</ng-container>
      </div>
    </ng-container>

    <ng-container *ngIf="!(valueStr | appIsSet)">
      <div class="sidebar-list-item__title sidebar-list-item__title_secondary">
        {{ subtitle }}
        <ng-container *ngIf="additional | appIsSet"> {{ additional }}</ng-container>
      </div>
    </ng-container>
  </div>

  <div *ngIf="removeEnabled" class="sidebar-list-item__right">
    <div
      class="sidebar-list-item__action sidebar-list-item__action_hover-only icon-bin"
      [appTip]="'Remove'"
      [appTipOptions]="{ side: 'top' }"
      (click)="$event.stopPropagation(); remove.emit()"
    ></div>
  </div>
</div>
