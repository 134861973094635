/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./popup2.component";
var styles_Popup2Component = [];
var RenderType_Popup2Component = i0.ɵcrt({ encapsulation: 2, styles: styles_Popup2Component, data: {} });
export { RenderType_Popup2Component as RenderType_Popup2Component };
export function View_Popup2Component_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "popup2"]], [[2, "popup2_side", null], [2, "popup2_theme", null], [4, "max-width", "px"]], null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵncd(null, 0), (_l()(), i0.ɵeld(3, 0, null, null, 1, "div", [["class", "popup2__footer"]], null, null, null, null, null)), i0.ɵncd(null, 1)], function (_ck, _v) { var _co = _v.component; var currVal_3 = "popup2"; var currVal_4 = ("popup2_size_" + _co.size); _ck(_v, 1, 0, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.side; var currVal_1 = _co.theme; var currVal_2 = _co.maxWidth; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_Popup2Component_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-popup2", [], null, null, null, View_Popup2Component_0, RenderType_Popup2Component)), i0.ɵdid(1, 114688, null, 0, i2.Popup2Component, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var Popup2ComponentNgFactory = i0.ɵccf("app-popup2", i2.Popup2Component, View_Popup2Component_Host_0, { size: "size", side: "side", maxWidth: "maxWidth", theme: "theme" }, {}, ["*", "app-popup2-footer"]);
export { Popup2ComponentNgFactory as Popup2ComponentNgFactory };
