var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { AfterViewInit, ChangeDetectorRef, ElementRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NavigationStart, Router } from '@angular/router';
import { Power2, TimelineMax } from 'gsap';
import isEqual from 'lodash/isEqual';
import values from 'lodash/values';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fromEvent, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { localize } from '@common/localize';
import { BasePopupComponent } from '@common/popups';
import { UserActivityListStore, UserActivityType } from '@modules/activities';
import { CurrentEnvironmentStore, CurrentProjectStore, ProjectUserService } from '@modules/projects';
import { isSet } from '@shared';
var Filters = /** @class */ (function (_super) {
    __extends(Filters, _super);
    function Filters() {
        return _super.call(this, {
            activity_type_in: new FormControl(null),
            user: new FormControl(null)
        }) || this;
    }
    Filters.prototype.isSet = function () {
        return values(this.controls).some(function (item) { return isSet(item.value); });
    };
    return Filters;
}(FormGroup));
var UserActivitiesPopupComponent = /** @class */ (function () {
    function UserActivitiesPopupComponent(router, popupComponent, projectUserService, userActivityListStore, currentProjectStore, currentEnvironmentStore, cd) {
        this.router = router;
        this.popupComponent = popupComponent;
        this.projectUserService = projectUserService;
        this.userActivityListStore = userActivityListStore;
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.cd = cd;
        this.baseParams = {};
        this.closeObsEvent = new EventEmitter();
        this.defaultParams = {};
        this.loading = false;
        this.filters = new Filters();
        this.filtersIsSet = false;
        this.activityTypeOptions = [
            {
                name: localize('All Activity'),
                value: null
            },
            {
                name: localize('Create Record'),
                value: UserActivityType.ModelCreate
            },
            {
                name: localize('Update Record'),
                value: UserActivityType.ModelUpdate
            },
            {
                name: localize('Delete Record'),
                value: UserActivityType.ModelDelete
            }
            // {
            //   name: 'Delete Multiple Records',
            //   value: UserActivityType.ModelMassDelete
            // },
            // {
            //   name: 'View Record',
            //   value: UserActivityType.ModelDetail
            // },
            // {
            //   name: 'View Collection',
            //   value: UserActivityType.ModelList
            // },
            // {
            //   name: 'View Dashboard',
            //   value: UserActivityType.DashboardList
            // }
        ];
        this.activityUserOptions = [];
        this.tl = new TimelineMax();
    }
    UserActivitiesPopupComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.defaultParams = __assign({}, this.baseParams, { order_by: '-date_add', activity_type_in: this.activityTypeOptions
                .filter(function (item) { return item.value; })
                .map(function (item) { return item.value; })
                .join() });
        this.projectUserService
            .get(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName)
            .pipe(untilDestroyed(this))
            .subscribe(function (users) {
            _this.activityUserOptions = [
                {
                    name: localize('All Members'),
                    value: null
                }
            ].concat(users
                .filter(function (user) { return user.user; })
                .map(function (user) {
                return {
                    name: user.user.firstName + ' ' + user.user.lastName,
                    value: user.user.uid
                };
            }));
            _this.cd.markForCheck();
        });
        if (this.closeObs) {
            this.closeObs.pipe(untilDestroyed(this)).subscribe(function () {
                _this.close();
            });
        }
    };
    UserActivitiesPopupComponent.prototype.ngOnDestroy = function () { };
    UserActivitiesPopupComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.router.events
            .pipe(filter(function (event) { return event instanceof NavigationStart; }), untilDestroyed(this))
            .subscribe(function () {
            _this.close();
        });
        fromEvent(this.sidePopup.nativeElement, 'wheel')
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            var sidePopupHeight = _this.sidePopup.nativeElement.offsetHeight;
            var sidePopupScrollHeight = _this.sidePopup.nativeElement.scrollHeight;
            var scrollTop = _this.sidePopup.nativeElement.scrollTop;
            var scrollMax = sidePopupScrollHeight - sidePopupHeight;
            if (scrollTop >= scrollMax) {
                _this.onScrollFinished();
            }
        });
        this.show();
    };
    UserActivitiesPopupComponent.prototype.init = function () {
        this.fetch();
        this.initFilters();
    };
    UserActivitiesPopupComponent.prototype.initFilters = function () {
        var _this = this;
        this.filters.valueChanges.pipe(untilDestroyed(this)).subscribe(function () {
            var params = __assign({}, _this.defaultParams, (isSet(_this.filters.controls.activity_type_in.value)
                ? {
                    activity_type_in: _this.filters.controls.activity_type_in.value
                }
                : undefined), (isSet(_this.filters.controls.user.value)
                ? {
                    user: _this.filters.controls.user.value
                }
                : undefined));
            _this.fetch(params);
            _this.filtersIsSet = _this.filters.isSet();
            _this.cd.markForCheck();
        });
    };
    UserActivitiesPopupComponent.prototype.fetch = function (params) {
        var _this = this;
        var newParams = params || this.defaultParams;
        if (isEqual(newParams, this.userActivityListStore.params)) {
            return;
        }
        this.loading = true;
        this.cd.markForCheck();
        this.userActivityListStore.projectName = this.currentProjectStore.instance.uniqueName;
        this.userActivityListStore.environmentName = this.currentEnvironmentStore.instance.uniqueName;
        this.userActivityListStore.params = newParams;
        this.userActivityListStore.reset();
        this.userActivityListStore
            .getNext()
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            _this.loading = false;
            _this.cd.markForCheck();
        }, function (error) {
            _this.loading = false;
            _this.error = error;
            _this.cd.markForCheck();
        });
    };
    UserActivitiesPopupComponent.prototype.onScrollFinished = function () {
        this.userActivityListStore.getNext();
    };
    UserActivitiesPopupComponent.prototype.show = function () {
        var _this = this;
        this.tl
            .fromTo(this.sidePopupWrap.nativeElement, 0.6, {
            xPercent: 100
        }, {
            xPercent: 0,
            ease: Power2.easeOut
        })
            .add(function () {
            _this.init();
        });
    };
    UserActivitiesPopupComponent.prototype.close = function () {
        var _this = this;
        this.closeObsEvent.emit();
        this.tl
            .clear()
            .set(this.closeButton.nativeElement, {
            pointerEvents: 'none'
        }, 0)
            .to(this.sidePopupWrap.nativeElement, 0.4, {
            xPercent: 100,
            ease: Power2.easeOut
        }, 0)
            .add(function () {
            _this.popupComponent.close();
        });
    };
    return UserActivitiesPopupComponent;
}());
export { UserActivitiesPopupComponent };
