var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import range from 'lodash/range';
import repeat from 'lodash/repeat';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { merge } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { defaultNumberFraction, defaultNumberFractionSeparator, defaultNumberThousandsSeparator, FieldType, Input, NumberValueFormat, ValueFormat } from '@modules/fields';
import { FieldInputControl } from '@modules/parameters';
import { CurrentProjectStore } from '@modules/projects';
import { controlValue, isSet } from '@shared';
var valueFormatControlDefaultOptions = {
    field: FieldType.Text
};
var ValueFormatControl = /** @class */ (function (_super) {
    __extends(ValueFormatControl, _super);
    function ValueFormatControl(currentProjectStore) {
        var _this = _super.call(this, {
            prefix: new FormControl(''),
            postfix: new FormControl(''),
            number_format: new FormControl(undefined),
            number_fraction: new FormControl(defaultNumberFraction),
            number_fraction_separator: new FormControl(defaultNumberFractionSeparator),
            number_thousands_separator: new FormControl(defaultNumberThousandsSeparator),
            format_input_enabled: new FormControl(false),
            format_input: new FieldInputControl({ path: ['value'] })
        }) || this;
        _this.currentProjectStore = currentProjectStore;
        _this.options = valueFormatControlDefaultOptions;
        _this.numberFormatOptions = [
            { value: NumberValueFormat.MetricPrefix, name: 'Metric Prefixes (23M, 16K)', icon: 'number' },
            { value: NumberValueFormat.Currency, name: 'Currency ($14)', icon: 'currency' },
            { value: NumberValueFormat.Percentage, name: 'Percentage (45%)', icon: 'percent' },
            { value: NumberValueFormat.Order, name: 'Order (1th, 2nd)', icon: 'filter_down' },
            { value: NumberValueFormat.FileSize, name: 'File size (26MB)', icon: 'save' },
            { value: NumberValueFormat.Exponential, name: 'Exponential (1.24e+7)', icon: 'math' }
        ];
        _this.numberFractionOptions = range(0, 9).map(function (item) {
            var name = item === 0 ? "1" : "1." + repeat('0', item);
            return {
                value: item,
                name: name
            };
        });
        _this.numberFractionSeparatorOptions = [
            {
                value: '.',
                name: 'Period separator (100.15)'
            },
            {
                value: ',',
                name: 'Comma separator (100,15)'
            }
        ];
        _this.numberThousandsSeparatorOptions = [
            {
                value: '',
                name: 'None (100000000)'
            },
            {
                value: ',',
                name: 'Comma (100,000,000)'
            },
            {
                value: '.',
                name: 'Period (100.000.000)'
            },
            {
                value: ' ',
                name: 'Space (100 000 000)'
            },
            {
                value: "'",
                name: "Apostrophe (100'000'000)"
            }
        ];
        var defaultSeparators = _this.getDefaultSeparators();
        _this.controls.number_fraction_separator.patchValue(defaultSeparators.numberFractionSeparator);
        _this.controls.number_thousands_separator.patchValue(defaultSeparators.numberThousandsSeparator);
        _this.markAsPristine();
        merge(_this.controls.number_fraction_separator.valueChanges.pipe(map(function () { return _this.controls.number_fraction_separator; })), _this.controls.number_thousands_separator.valueChanges.pipe(map(function () { return _this.controls.number_thousands_separator; })))
            .pipe(debounceTime(10), untilDestroyed(_this))
            .subscribe(function (control) {
            var anotherControl = [_this.controls.number_fraction_separator, _this.controls.number_thousands_separator].find(function (item) { return item !== control; });
            if (control.value == ',' && anotherControl.value == control.value) {
                anotherControl.patchValue('.');
            }
            else if (control.value == '.' && anotherControl.value == control.value) {
                anotherControl.patchValue(',');
            }
        });
        return _this;
    }
    ValueFormatControl.prototype.ngOnDestroy = function () { };
    ValueFormatControl.prototype.getDefaultSeparators = function () {
        var domain = this.currentProjectStore.instance ? this.currentProjectStore.instance.domain : undefined;
        var numberFractionSeparator = domain && isSet(domain.numberFractionSeparator) ? domain.numberFractionSeparator : defaultNumberFractionSeparator;
        var numberThousandsSeparator = domain && isSet(domain.numberThousandsSeparator, true)
            ? domain.numberThousandsSeparator
            : defaultNumberThousandsSeparator;
        return {
            numberFractionSeparator: numberFractionSeparator,
            numberThousandsSeparator: numberThousandsSeparator
        };
    };
    ValueFormatControl.prototype.getNumberFormatOption$ = function () {
        var _this = this;
        return controlValue(this.controls.number_format).pipe(map(function (value) { return _this.numberFormatOptions.find(function (item) { return item.value == value; }); }));
    };
    ValueFormatControl.prototype.deserialize = function (instance, options) {
        if (options === void 0) { options = {}; }
        options = __assign({}, valueFormatControlDefaultOptions, options);
        this.instance = instance;
        this.options = options;
        var defaultSeparators = this.getDefaultSeparators();
        if (instance) {
            this.controls.prefix.setValue(instance.prefix);
            this.controls.postfix.setValue(instance.postfix);
            this.controls.number_format.setValue(instance.numberFormat);
            this.controls.number_fraction.setValue(instance.numberFraction || defaultNumberFraction);
            this.controls.number_fraction_separator.setValue(isSet(instance.numberFractionSeparator)
                ? instance.numberFractionSeparator
                : defaultSeparators.numberFractionSeparator);
            this.controls.number_thousands_separator.setValue(isSet(instance.numberThousandsSeparator, true)
                ? instance.numberThousandsSeparator
                : defaultSeparators.numberThousandsSeparator);
            this.controls.format_input_enabled.setValue(!!instance.formatInput);
            this.controls.format_input.patchValue(instance.formatInput ? instance.formatInput.serializeWithoutPath() : {});
        }
        else {
            this.controls.prefix.setValue('');
            this.controls.postfix.setValue('');
            this.controls.number_format.setValue(undefined);
            this.controls.number_fraction.setValue(defaultNumberFraction);
            this.controls.number_fraction_separator.setValue(defaultSeparators.numberFractionSeparator);
            this.controls.number_thousands_separator.setValue(defaultSeparators.numberThousandsSeparator);
            this.controls.format_input_enabled.setValue(false);
        }
        this.markAsPristine();
    };
    ValueFormatControl.prototype.setNumberFormat = function (format) {
        this.controls.number_format.patchValue(format);
        if (format == NumberValueFormat.Currency) {
            this.controls.prefix.patchValue('$');
            this.controls.postfix.patchValue('');
        }
    };
    ValueFormatControl.prototype.serialize = function () {
        var result = this.instance || new ValueFormat();
        if (this.controls.format_input_enabled.value) {
            result.prefix = undefined;
            result.postfix = undefined;
            result.numberFormat = undefined;
            result.numberFraction = undefined;
            result.numberFractionSeparator = undefined;
            result.numberThousandsSeparator = undefined;
            result.formatInput = this.controls.format_input_enabled.value
                ? new Input().deserialize(this.controls.format_input.value)
                : undefined;
        }
        else {
            result.prefix = this.controls.prefix.value;
            result.postfix = this.controls.postfix.value;
            if (this.options.field == FieldType.Number) {
                result.numberFormat = this.controls.number_format.value;
                result.numberFraction = this.controls.number_fraction.value;
                result.numberFractionSeparator = this.controls.number_fraction_separator.value;
                result.numberThousandsSeparator = this.controls.number_thousands_separator.value;
            }
            else {
                result.numberFormat = undefined;
                result.numberFraction = undefined;
                result.numberFractionSeparator = undefined;
                result.numberThousandsSeparator = undefined;
            }
            result.formatInput = null;
        }
        if (!result.isSet()) {
            return;
        }
        return result;
    };
    return ValueFormatControl;
}(FormGroup));
export { ValueFormatControl };
