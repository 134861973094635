import { Injectable, OnDestroy } from '@angular/core';
import { FormControl, ValidatorFn, Validators } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import fromPairs from 'lodash/fromPairs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, from, Observable, of, throwError } from 'rxjs';
import { catchError, delayWhen, map, switchMap, tap } from 'rxjs/operators';
import { slugify } from 'transliteration';

import { AppFormGroup, FormUtils } from '@common/form-utils';
import { ActionStore } from '@modules/action-queries';
import { ServerRequestError } from '@modules/api';
import {
  modelFieldItemToRawListViewSettingsColumn,
  RawListViewSettingsColumn,
  rawListViewSettingsColumnToModelField
} from '@modules/customize';
import { Option } from '@modules/field-components';
import { FieldType, ParameterField } from '@modules/fields';
import { ModelDescriptionService, ModelDescriptionStore } from '@modules/model-queries';
import {
  ModelDbField,
  modelDbFieldToParameterField,
  ModelDescription,
  ModelField,
  ModelFieldType
} from '@modules/models';
import { CurrentEnvironmentStore, CurrentProjectStore, Resource } from '@modules/projects';
import { QueryService } from '@modules/queries';
import { getJetDatabaseType, ResourceController, ResourceControllerService } from '@modules/resources';
import { controlValue, getFilename, isSet } from '@shared';

import { ModelImportEvent } from '../../data/model-import.event';
import { TextEncoding } from '../../data/text-encoding';
import { ImportService } from '../../services/import/import.service';
import { JET_IMPORT_PK } from '../import-data/import-data.component';
import { ImportModelsFieldArray } from './import-models-field.array';

export interface ImportFileContent {
  fields: RawListViewSettingsColumn[];
  fieldsData: Object;
  data: Object[];
}

export interface ImportFileContentResult {
  file: File;
  content?: ImportFileContent;
  error?: string;
}

export function validateVerboseName(): ValidatorFn {
  return control => {
    const parent = control.parent as ImportModelsForm;

    if (!parent || !parent.resource) {
      return;
    }

    if (!control.value) {
      if (parent.modelDescription) {
        return;
      } else {
        return {
          required: true
        };
      }
    }

    if (
      parent.modelDescriptionStore.instance
        .filter(item => item.resource == parent.resource.uniqueName)
        .filter(item => !item.deleted)
        .find(item => {
          if (item.verboseNamePlural && item.verboseNamePlural.toLowerCase() == control.value.toLowerCase()) {
            return true;
          } else if (item.verboseName && item.verboseName.toLowerCase() == control.value.toLowerCase()) {
            return true;
          } else {
            return false;
          }
        })
    ) {
      return {
        local: ['Collection with such Name already exists']
      };
    }
  };
}

export function validateFields(): ValidatorFn {
  return (control: ImportModelsForm) => {
    if (!control.controls.file.value || !control.controls.fields.controls.length) {
      return;
    }

    if (!control.controls.fields.controls.filter(item => item.controls.active.value).length) {
      return {
        local: ['Select at least one field to import']
      };
    }

    if (control.modelDescription) {
      const controller = control.resourceControllerService.get(control.resource.type);
      const modelCreateParameters = controller.getCreateParametersOrDefaults(
        control.resource,
        control.modelDescription
      );
      const missingParameters = modelCreateParameters
        .filter(field => field.required)
        .filter(field => {
          const existingTargetField = control.controls.fields.controls.find(
            item =>
              item.controls.field.controls.name.value == field.name &&
              item.controls.active.value &&
              isSet(item.controls.source.value)
          );

          return existingTargetField === undefined;
        });

      if (missingParameters.length) {
        const fields = missingParameters.map(item => item.verboseName || item.name).join(', ');
        return {
          local_dirty: [`The following required collection fields are not specified: ${fields}`]
        };
      }
    }

    const primaryKey = control.controls.custom_primary_key.value;
    if (
      isSet(primaryKey) &&
      control.controls.fields.controls.find(
        item =>
          item.controls.field.controls.name.value == primaryKey &&
          item.controls.field.controls.field.value !== FieldType.Number
      )
    ) {
      return {
        local: ['Specified primary key type is not number']
      };
    }
  };
}

@Injectable()
export class ImportModelsForm extends AppFormGroup implements OnDestroy {
  resource: Resource;
  modelDescription: ModelDescription;

  controls: {
    verbose_name: FormControl;
    file: FormControl;
    file_encoding: FormControl;
    fields: ImportModelsFieldArray;
    custom_primary_key: FormControl;
  };

  encodingOptions: Option<TextEncoding>[] = [
    {
      value: TextEncoding.UTF8,
      name: 'Unicode (UTF-8)'
    },
    {
      value: TextEncoding.UTF16LE,
      name: 'Unicode (UTF-16LE)'
    },
    {
      value: TextEncoding.Windows1250CentralEuropean,
      name: 'Central European (Windows 1250)'
    },
    {
      value: TextEncoding.Windows1251Cyrillic,
      name: 'Cyrillic (Windows 1251)'
    },
    {
      value: TextEncoding.Windows1252Latin1,
      name: 'Latin 1 (Windows 1252)'
    },
    {
      value: TextEncoding.Windows1253Greek,
      name: 'Greek (Windows 1253)'
    },
    {
      value: TextEncoding.Windows1254Turkish,
      name: 'Turkish (Windows 1254)'
    },
    {
      value: TextEncoding.Windows1255Hebrew,
      name: 'Hebrew (Windows 1255)'
    },
    {
      value: TextEncoding.Windows1256Arabic,
      name: 'Arabic (Windows 1256)'
    },
    {
      value: TextEncoding.Windows1257Baltic,
      name: 'Baltic (Windows 1257)'
    },
    {
      value: TextEncoding.Windows1258Vietnamese,
      name: 'Vietnamese (Windows 1258)'
    },
    {
      value: TextEncoding.Windows874Thai,
      name: 'Thai (Windows 874)'
    },
    {
      value: TextEncoding.SimplifiedChineseGBK,
      name: 'Simplified Chinese GBK'
    },
    {
      value: TextEncoding.TraditionalChineseBig5,
      name: 'Traditional Chinese Big5'
    },
    {
      value: TextEncoding.JapaneseShiftJIS,
      name: 'Japanese Shift-JIS'
    }
  ];

  constructor(
    private modelDescriptionService: ModelDescriptionService,
    public modelDescriptionStore: ModelDescriptionStore,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private queryService: QueryService,
    private formUtils: FormUtils,
    private actionStore: ActionStore,
    private importService: ImportService,
    public resourceControllerService: ResourceControllerService,
    fieldArray: ImportModelsFieldArray
  ) {
    super(
      {
        verbose_name: new FormControl(null, validateVerboseName()),
        file: new FormControl(null, Validators.required),
        file_encoding: new FormControl(TextEncoding.UTF8, Validators.required),
        fields: fieldArray,
        custom_primary_key: new FormControl(null)
      },
      validateFields()
    );

    this.getFileContent$()
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        if (result && !this.modelDescription) {
          this.controls.verbose_name.setValue(getFilename(result.file.name));
        }

        const cleanName = value => {
          if (typeof value === 'string') {
            return value.toLowerCase().trim().replace(/[^\w]/g, '_');
          } else {
            return value;
          }
        };

        const fields = result && result.content ? result.content.fields : [];

        if (this.modelDescription) {
          const controller = this.resourceControllerService.get(this.resource.type);
          const createParameters = controller.getCreateParametersOrDefaults(this.resource, this.modelDescription);

          if (
            !createParameters.find(item => item.name == this.modelDescription.primaryKeyField) &&
            this.modelDescription.primaryKey
          ) {
            const pkParameter = modelDbFieldToParameterField(this.modelDescription.primaryKey);
            pkParameter.name = this.modelDescription.primaryKeyField;
            pkParameter.required = false;
            createParameters.splice(0, 0, pkParameter);
          }

          const controls = createParameters.map(parameter => {
            const control = this.controls.fields.createControl();

            control.controls.field.deserialize(parameter);

            const parameterCleanName = cleanName(parameter.name);
            const sourceParameter =
              parameter.name !== this.modelDescription.primaryKeyField
                ? fields.find(item => cleanName(item.name) == parameterCleanName)
                : undefined;

            if (sourceParameter) {
              control.controls.source.setValue(sourceParameter.name);
            }

            control.markAsPristine();

            return control;
          });

          this.controls.fields.setControls(controls);
        } else {
          const controls = [
            { name: JET_IMPORT_PK, field: FieldType.Number, verboseName: 'id', params: {} },
            ...fields
          ].map(field => {
            const parameter = new ParameterField();

            parameter.field = field.field;
            parameter.name = field.name;
            parameter.verboseName = field.verboseName;
            parameter.params = field.params;
            parameter.updateFieldDescription();

            const control = this.controls.fields.createControl();

            control.controls.field.deserialize(parameter);

            if (field.name != JET_IMPORT_PK) {
              control.controls.source.setValue(field.name);

              if (field.name.toLowerCase() == 'id') {
                control.controls.active.patchValue(false);
              }
            }

            control.markAsPristine();

            return control;
          });

          this.controls.fields.setControls(controls);
        }
      });
  }

  ngOnDestroy(): void {}

  init(resource: Resource, modelDescription: ModelDescription) {
    this.resource = resource;
    this.modelDescription = modelDescription;

    this.controls.fields.init(this.resource, this.modelDescription);
  }

  getFileContent$(): Observable<ImportFileContentResult> {
    return combineLatest(
      controlValue<File>(this.controls.file),
      controlValue<TextEncoding>(this.controls.file_encoding)
    ).pipe(
      switchMap(([file, encoding]) => {
        if (!file) {
          return of(undefined);
        }

        return this.importService.parseFileData(file, { encoding: encoding }).pipe(
          map(results => {
            if (!results) {
              return { file: file, error: 'Failed to parse file' };
            } else if (!results.length) {
              return { file: file, error: 'File has no data' };
            }

            const fields = this.queryService.autoDetectGetFields(results);

            if (!fields) {
              return { file: file, error: 'Failed to detect fields' };
            } else if (!fields.length) {
              return { file: file, error: 'Data has no fields' };
            }

            return {
              file: file,
              content: {
                fields: fields,
                fieldsData: fromPairs(
                  fields.map(field => {
                    const itemWithFieldData = results.find(item => isSet(item[field.name]));
                    const fieldData = itemWithFieldData ? itemWithFieldData[field.name] : undefined;
                    return [field.name, fieldData];
                  })
                ),
                data: results
              }
            };
          }),
          catchError(error => {
            return of({ file: file, error: error });
          })
        );
      })
    );
  }

  createModelDescription(
    controller: ResourceController,
    parameters: ParameterField[],
    primaryKeyField?: string
  ): ModelDescription {
    const defaultPrimaryKey: RawListViewSettingsColumn = {
      name: 'id',
      field: FieldType.Number,
      params: {}
    };

    const instance = new ModelDescription();

    instance.resource = this.resource.uniqueName;
    instance.model = isSet(this.controls.verbose_name.value)
      ? slugify(this.controls.verbose_name.value, { trim: true, separator: '_' }).replace(/_+/g, '_')
      : undefined;
    instance.dbTable = instance.model;
    instance.verboseName = this.controls.verbose_name.value;
    instance.verboseNamePlural = this.controls.verbose_name.value;
    instance.primaryKeyField = isSet(primaryKeyField) ? primaryKeyField : defaultPrimaryKey.name;
    instance.virtual = false;

    if (isSet(primaryKeyField)) {
      parameters = parameters.sort((lhs, rhs) => {
        if (lhs.name == primaryKeyField) {
          return -1;
        } else if (rhs.name == primaryKeyField) {
          return 1;
        } else {
          return parameters.indexOf(lhs) - parameters.indexOf(rhs);
        }
      });
    }

    const instanceFields: ModelField[] = [
      ...(!primaryKeyField ? [rawListViewSettingsColumnToModelField(defaultPrimaryKey)] : []),
      ...parameters.map(item => {
        const dbField = new ModelDbField();
        const jetDatabaseType = getJetDatabaseType(item);

        dbField.name = item.name;
        dbField.verboseName = item.verboseName;
        dbField.field = item.field;
        dbField.params = item.params;
        dbField.updateFieldDescription();

        if (primaryKeyField != item.name) {
          dbField.dbField = jetDatabaseType ? jetDatabaseType.dbColumn : 'CharField';
        }

        const field = new ModelField();

        field.name = item.name;
        field.type = ModelFieldType.Db;
        field.item = dbField;

        return field;
      })
    ].map(field => {
      if (field.item instanceof ModelDbField) {
        field.item.editable = true;
        field.item.null = true;
      }

      return field;
    });

    controller.setUpModelDescriptionBasedOnGetQuery(this.resource, instance, instance.getQuery, instanceFields);

    return instance;
  }

  importModelDescription(): Observable<ModelImportEvent> {
    this.clearServerErrors();

    const file = this.controls.file.value;
    const encoding = this.controls.file_encoding.value;

    return from(this.importService.parseFileData(file, { encoding: encoding })).pipe(
      switchMap(results => {
        const fields = this.controls.fields.controls
          .filter(control => control.controls.active.value)
          .filter(control => control.controls.field.controls.name.value !== JET_IMPORT_PK)
          .map(control => control.controls.field.serialize());
        const primaryKeyField = this.controls.custom_primary_key.value;
        const controller = this.resourceControllerService.get(this.resource.type);
        const instance = this.createModelDescription(controller, fields, primaryKeyField);

        return controller.modelDescriptionCreate(this.resource, instance).pipe(
          switchMap(() => this.modelDescriptionService.getFromResource(this.resource)),
          map(resourceModelDescriptions => resourceModelDescriptions.find(item => item.model == instance.model)),
          tap(modelDescription => {
            if (!modelDescription) {
              throw new ServerRequestError('Failed to get created Collection');
            }
          }),
          switchMap(modelDescription => {
            const overrideModelDescription = cloneDeep(modelDescription);

            if (overrideModelDescription) {
              overrideModelDescription.verboseName = instance.verboseName;
              overrideModelDescription.verboseNamePlural = instance.verboseNamePlural;

              overrideModelDescription.fields = overrideModelDescription.fields.map(item => {
                const overrideModelField = instance.fields.find(i => i.name == item.name);
                const overrideField = overrideModelField
                  ? modelFieldItemToRawListViewSettingsColumn(overrideModelField.item)
                  : undefined;

                if (item.type == ModelFieldType.Db && overrideField) {
                  const newField = cloneDeep(overrideModelField);

                  if (newField.item instanceof ModelDbField) {
                    newField.item.applyOverrides(overrideField);
                  }

                  return newField;
                } else {
                  return item;
                }
              });
            }

            return this.modelDescriptionService.update(
              this.currentProjectStore.instance.uniqueName,
              this.currentEnvironmentStore.instance.uniqueName,
              overrideModelDescription
            );
          }),
          switchMap(() => this.modelDescriptionStore.getDetailFirst(instance.modelId, true)),
          delayWhen(() => this.actionStore.getFirst(true)),
          switchMap(modelDescription => {
            const mappings = this.controls.fields.controls
              .filter(control => control.controls.active.value)
              .map(control => {
                const field = control.controls.field.serialize();

                return {
                  field: field,
                  source: control.controls.source.value
                };
              });

            return this.importService.importRecords(this.resource, modelDescription, results, mappings, {
              ignorePostCreate: true
            });
          })
        );
      }),
      catchError(error => {
        console.error(error);
        this.formUtils.showFormErrors(this, error);
        return throwError(error);
      })
    );
  }

  importRecords(): Observable<ModelImportEvent> {
    this.clearServerErrors();

    const file = this.controls.file.value;
    const encoding = this.controls.file_encoding.value;

    return from(this.importService.parseFileData(file, { encoding: encoding })).pipe(
      switchMap(results => {
        const mappings = this.controls.fields.controls
          .filter(control => control.controls.active.value)
          .map(control => {
            return {
              field: control.controls.field.serialize(),
              source: control.controls.source.value
            };
          });

        return this.importService.importRecords(this.resource, this.modelDescription, results, mappings);
      }),
      catchError(error => {
        console.error(error);
        this.formUtils.showFormErrors(this, error);
        return throwError(error);
      })
    );
  }

  submit(): Observable<ModelImportEvent> {
    if (this.modelDescription) {
      return this.importRecords();
    } else {
      return this.importModelDescription();
    }
  }
}
