import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit
} from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import * as Color from 'color';
import toPairs from 'lodash/toPairs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';

import { AdminMode, ROUTE_ADMIN_MODE$ } from '@modules/admin-mode';
import { AllProjectSettings, ProjectSettingsStore } from '@modules/all-project-settings';
import { AnalyticsEvent, IntercomService } from '@modules/analytics';
import { ApiService } from '@modules/api';
import { AuthService } from '@modules/auth';
import { TaskQueueStore } from '@modules/collaboration';
import { getColorHex, isLightColor } from '@modules/colors';
import { Fill, Frame, routeHasBuilderMode } from '@modules/customize';
import { MenuBlock, MenuGeneratorService, MenuItem } from '@modules/menu';
import {
  CurrentEnvironmentStore,
  CurrentProjectStore,
  Project,
  ProjectService,
  ProjectsStore
} from '@modules/projects';
import { RoutingService } from '@modules/routing';
import { ThemeService } from '@modules/theme';
import { CurrentUserStore } from '@modules/users';
import { elementSize$, isSet, openUrl, TypedChanges } from '@shared';

export namespace MenuPrimary {
  export function isLight(color: string): boolean {
    if (!isSet(color)) {
      return;
    }

    return isLightColor(color);
  }

  export function getLightColor(color: string): string {
    try {
      const colorHex = getColorHex(color);
      const clr = Color(colorHex);
      return clr.lighten(0.2).string();
    } catch (e) {}
  }

  export function getDarkColor(color: string): string {
    try {
      const colorHex = getColorHex(color);
      const clr = Color(colorHex);
      return clr.darken(0.05).string();
    } catch (e) {}
  }

  export function getDark2Color(color: string): string {
    try {
      const colorHex = getColorHex(color);
      const clr = Color(colorHex);
      return clr.darken(0.45).desaturate(0.35).string();
    } catch (e) {}
  }

  export function getDark3Color(color: string): string {
    try {
      const colorHex = getColorHex(color);
      const clr = Color(colorHex);
      return clr.darken(0.7).desaturate(0.25).string();
    } catch (e) {}
  }

  export function getVars(accentColor: string, backgroundColor?: string): Object {
    if (!isSet(accentColor)) {
      accentColor = isSet(backgroundColor) ? backgroundColor : '#f3f5f8';
    }

    const lightColor = getLightColor(accentColor);
    const darkColor = getDarkColor(accentColor);
    const dark2Color = getDark2Color(accentColor);
    const dark3Color = getDark3Color(accentColor);

    return {
      ...(lightColor && { 'light-color': lightColor }),
      ...(darkColor && { 'dark-color': darkColor }),
      ...(dark2Color && { 'dark2-color': dark2Color }),
      ...(dark3Color && { 'dark3-color': dark3Color })
    };
  }

  export function defaultWidth(): number {
    return 70;
  }

  export function defaultHeight(): number {
    return 50;
  }
}

@Component({
  selector: 'app-menu-primary',
  templateUrl: './menu-primary.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuPrimaryComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() block: MenuBlock;
  @Input() horizontal = false;
  @Input() backgroundColor: string;
  @Input() isDarkTheme = false;

  @HostBinding('style') menuStyle: SafeStyle;

  frame$ = new BehaviorSubject<ClientRect>(undefined);
  startItems: MenuItem[] = [];
  centerItems: MenuItem[] = [];
  endItems: MenuItem[] = [];
  projects: Project[];
  currentProject: Project;
  projectSettings: AllProjectSettings;
  demoUser = false;
  hasTaskQueues = false;
  adminModes = AdminMode;
  menuBackground: SafeStyle;
  menuBackgroundWidth: string;
  menuBackgroundHeight: string;
  menuBackgroundTransform: SafeStyle;
  menuIsLight = false;
  menuBorderTop: SafeStyle;
  menuBorderRight: SafeStyle;
  menuBorderBottom: SafeStyle;
  menuBorderLeft: SafeStyle;
  analyticsEvents = AnalyticsEvent;

  trackMenuItemFn(i, item: MenuItem) {
    return item.id;
  }

  constructor(
    private el: ElementRef,
    @Inject(ROUTE_ADMIN_MODE$) public mode$: Observable<AdminMode>,
    public themeService: ThemeService,
    private authService: AuthService,
    private routing: RoutingService,
    public currentProjectStore: CurrentProjectStore,
    public currentEnvironmentStore: CurrentEnvironmentStore,
    public projectSettingsStore: ProjectSettingsStore,
    public projectsStore: ProjectsStore,
    private projectService: ProjectService,
    public currentUserStore: CurrentUserStore,
    private menuGeneratorService: MenuGeneratorService,
    private apiService: ApiService,
    private intercomService: IntercomService,
    private taskQueueStore: TaskQueueStore,
    private sanitizer: DomSanitizer,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    combineLatest(this.currentProjectStore.instance$, this.projectsStore.instance$)
      .pipe(untilDestroyed(this))
      .subscribe(
        ([currentProject, projects]) => {
          this.currentProject = currentProject;

          if (this.currentProject) {
            this.projects = projects ? projects.filter(item => item.uniqueName != this.currentProject.uniqueName) : [];
            this.projects = this.projectService.sortProjectsLastUsed(this.projects);

            if (this.isWhiteLabel) {
              this.projects = this.projects.filter(item => item.isDomain(this.currentProject.domain));
            }
          }

          this.cd.markForCheck();
        },
        () => {
          this.cd.markForCheck();
        }
      );

    this.projectSettingsStore
      .getAllSettings$()
      .pipe(untilDestroyed(this))
      .subscribe(projectSettings => {
        this.projectSettings = projectSettings;
        this.cd.markForCheck();
      });

    this.currentUserStore
      .get()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.demoUser = !!this.apiService.getProjectToken();
        this.cd.detectChanges();
      });

    this.taskQueueStore
      .get()
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        this.hasTaskQueues = result && result.length > 0;
        this.cd.markForCheck();
      });
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<MenuPrimaryComponent>): void {
    if (changes.block) {
      this.updateItems();
    }

    if (changes.block || changes.isDarkTheme || changes.backgroundColor) {
      this.updateBackground();
    }

    if (changes.block || changes.isDarkTheme) {
      this.updateBorder();
    }
  }

  ngAfterViewInit(): void {
    elementSize$(this.el.nativeElement)
      .pipe(untilDestroyed(this))
      .subscribe(size => {
        this.frame$.next(size);
        this.updateBackground();
      });
  }

  updateItems() {
    this.startItems = this.menuGeneratorService.cleanMenuItemsAppMode(this.block.startItems);
    this.centerItems = this.menuGeneratorService.cleanMenuItemsAppMode(this.block.centerItems);
    this.endItems = this.menuGeneratorService.cleanMenuItemsAppMode(this.block.endItems);
    this.cd.markForCheck();
  }

  getBuilderUrl() {
    if (routeHasBuilderMode(this.routing.routerState.snapshot)) {
      return window.location.href.replace('/app/', '/builder/');
    } else {
      const urlTree = this.routing.createUrlTreeApp(this.currentProjectStore.instance.homeLink, undefined, {
        environmentName: this.currentEnvironmentStore.instance.uniqueName,
        modeName: AdminMode.Builder
      });
      return this.routing.serializeUrl(urlTree);
    }
  }

  openBuilderUrl() {
    const url = this.getBuilderUrl();
    openUrl(url, true);
  }

  toggleTheme() {
    this.themeService.toggleTheme();
  }

  logout() {
    this.authService.logout();
    this.routing.navigate(['/login']);
  }

  openChat() {
    this.intercomService.openChat();
  }

  get isWhiteLabel() {
    return this.currentProject && this.currentProject.domain && this.currentProject.domain.whiteLabel;
  }

  get showProjectsDropdown() {
    return !this.isWhiteLabel || (this.isWhiteLabel && this.projects.length > 1);
  }

  updateBackground() {
    const fill = this.getFill();
    if (fill) {
      const frame = new Frame({
        width: 1,
        height: 1,
        ...this.frame$.value
      });
      const css = fill.css({ frame: frame });
      this.menuBackground = this.sanitizer.bypassSecurityTrustStyle(css.background);
      this.menuBackgroundWidth = css.width;
      this.menuBackgroundHeight = css.height;
      this.menuBackgroundTransform = this.sanitizer.bypassSecurityTrustStyle(css.transform);
      this.menuIsLight = MenuPrimary.isLight(css.accentColor || this.backgroundColor);
      this.menuStyle = this.getStyleVars(css.accentColor, this.backgroundColor);
    } else {
      this.menuBackground = undefined;
      this.menuBackgroundWidth = undefined;
      this.menuBackgroundHeight = undefined;
      this.menuBackgroundTransform = undefined;
      this.menuIsLight = MenuPrimary.isLight(this.backgroundColor);
      this.menuStyle = this.getStyleVars(undefined, this.backgroundColor);
    }
  }

  updateBorder() {
    if (this.block.borderSettings && this.block.borderSettings.isSidesSet()) {
      this.menuBorderTop =
        this.block.borderSettings && this.block.borderSettings.borderTop
          ? this.sanitizer.bypassSecurityTrustStyle(this.block.borderSettings.borderTop.cssBorder(this.isDarkTheme))
          : undefined;
      this.menuBorderRight =
        this.block.borderSettings && this.block.borderSettings.borderRight
          ? this.sanitizer.bypassSecurityTrustStyle(this.block.borderSettings.borderRight.cssBorder(this.isDarkTheme))
          : undefined;
      this.menuBorderBottom =
        this.block.borderSettings && this.block.borderSettings.borderBottom
          ? this.sanitizer.bypassSecurityTrustStyle(this.block.borderSettings.borderBottom.cssBorder(this.isDarkTheme))
          : undefined;
      this.menuBorderLeft =
        this.block.borderSettings && this.block.borderSettings.borderLeft
          ? this.sanitizer.bypassSecurityTrustStyle(this.block.borderSettings.borderLeft.cssBorder(this.isDarkTheme))
          : undefined;
    } else {
      this.menuBorderTop = this.menuBorderRight = this.menuBorderBottom = this.menuBorderLeft =
        this.block.borderSettings && this.block.borderSettings.border
          ? this.sanitizer.bypassSecurityTrustStyle(this.block.borderSettings.border.cssBorder(this.isDarkTheme))
          : undefined;
    }
  }

  getFill(): Fill {
    if (!this.block.fillSettings) {
      return;
    }

    if (this.isDarkTheme) {
      return this.block.fillSettings.fillDark;
    } else {
      return this.block.fillSettings.fill;
    }
  }

  getStyleVars(accentColor: string, backgroundColor: string): SafeStyle {
    const vars = MenuPrimary.getVars(accentColor, backgroundColor);
    const styles = toPairs(vars)
      .map(([k, v]) => `--${k}: ${v}`)
      .join(';');

    return this.sanitizer.bypassSecurityTrustStyle(styles);
  }
}
