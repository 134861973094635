var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import * as moment from 'moment';
import { BehaviorSubject, defer, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, map, share, tap } from 'rxjs/operators';
import { AppConfigService, CookieStorage, LocalStorage, SessionStorage } from '@core';
// TODO: Refactor import
import { isSet } from '../../../../shared/utils/common/common';
import { ServerRequestError } from '../../utils/server-request-error/server-request-error';
import { isTokenExpired } from '../../utils/token-options/token-options';
import * as i0 from "@angular/core";
import * as i1 from "../../../../core/services/cookie-storage/cookie.storage";
import * as i2 from "../../../../core/services/local-storage/local.storage";
import * as i3 from "../../../../core/services/session-storage/session.storage";
import * as i4 from "../../../../core/services/app-config/app-config.service";
import * as i5 from "@angular/common/http";
var ApiService = /** @class */ (function () {
    function ApiService(cookieStorage, localStorage, sessionStorage, appConfigService, http) {
        this.cookieStorage = cookieStorage;
        this.localStorage = localStorage;
        this.sessionStorage = sessionStorage;
        this.appConfigService = appConfigService;
        this.http = http;
        this.onError = new Subject();
        this._overrideToken$ = new BehaviorSubject(undefined);
    }
    Object.defineProperty(ApiService.prototype, "apiBaseUrl", {
        get: function () {
            return this.appConfigService.serverBaseUrl + "/api/";
        },
        enumerable: true,
        configurable: true
    });
    ApiService.prototype.methodURL = function (method) {
        return "" + this.apiBaseUrl + method;
    };
    ApiService.prototype.projectMethodURL = function (projectName, method) {
        return this.methodURL("projects/" + projectName + "/" + method);
    };
    ApiService.prototype.environmentMethodURL = function (projectName, environmentName, method) {
        return this.methodURL("projects/" + projectName + "/" + environmentName + "/" + method);
    };
    Object.defineProperty(ApiService.prototype, "apiNodeBaseUrl", {
        get: function () {
            return this.appConfigService.serverNodeBaseUrl + "/";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ApiService.prototype, "apiNodeDirectBaseUrl", {
        get: function () {
            if (!isSet(this.appConfigService.serverNodeDirectBaseUrl)) {
                return;
            }
            return this.appConfigService.serverNodeDirectBaseUrl + "/";
        },
        enumerable: true,
        configurable: true
    });
    ApiService.prototype.nodeMethodURL = function (method, direct) {
        if (direct === void 0) { direct = false; }
        if (direct && isSet(this.apiNodeDirectBaseUrl)) {
            return "" + this.apiNodeDirectBaseUrl + method;
        }
        else {
            return "" + this.apiNodeBaseUrl + method;
        }
    };
    ApiService.prototype.nodeProjectMethodURL = function (projectName, method, direct) {
        if (direct === void 0) { direct = false; }
        return this.nodeMethodURL("projects/" + projectName + "/" + method, direct);
    };
    ApiService.prototype.nodeEnvironmentMethodURL = function (projectName, environmentName, method, direct) {
        if (direct === void 0) { direct = false; }
        return this.nodeMethodURL("projects/" + projectName + "/" + environmentName + "/" + method, direct);
    };
    Object.defineProperty(ApiService.prototype, "apiSyncDataBaseUrl", {
        get: function () {
            return this.appConfigService.dataSyncBaseUrl + "/";
        },
        enumerable: true,
        configurable: true
    });
    ApiService.prototype.syncDataMethodURL = function (method) {
        return "" + this.apiSyncDataBaseUrl + method;
    };
    ApiService.prototype.syncDataProjectMethodURL = function (projectName, method) {
        return this.syncDataMethodURL("projects/" + projectName + "/" + method);
    };
    ApiService.prototype.syncDataEnvironmentMethodURL = function (projectName, environmentName, method) {
        return this.syncDataMethodURL("projects/" + projectName + "/" + environmentName + "/" + method);
    };
    Object.defineProperty(ApiService.prototype, "apiWorkflowsBaseUrl", {
        get: function () {
            return this.appConfigService.workflowsBaseUrl + "/";
        },
        enumerable: true,
        configurable: true
    });
    ApiService.prototype.workflowsMethodURL = function (method) {
        return "" + this.apiWorkflowsBaseUrl + method;
    };
    ApiService.prototype.workflowsProjectMethodURL = function (projectName, method) {
        return this.workflowsMethodURL("projects/" + projectName + "/" + method);
    };
    ApiService.prototype.workflowsEnvironmentMethodURL = function (projectName, environmentName, method) {
        return this.workflowsMethodURL("projects/" + projectName + "/" + environmentName + "/" + method);
    };
    Object.defineProperty(ApiService.prototype, "dataSourcesBaseUrl", {
        get: function () {
            return this.appConfigService.dataSourcesBaseUrl + "/";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ApiService.prototype, "dataSourcesDirectBaseUrl", {
        get: function () {
            if (!isSet(this.appConfigService.dataSourcesDirectBaseUrl)) {
                return;
            }
            return this.appConfigService.dataSourcesDirectBaseUrl + "/";
        },
        enumerable: true,
        configurable: true
    });
    ApiService.prototype.dataSourcesMethodURL = function (method, direct) {
        if (direct === void 0) { direct = false; }
        if (direct && isSet(this.dataSourcesDirectBaseUrl)) {
            return "" + this.dataSourcesDirectBaseUrl + method;
        }
        else {
            return "" + this.dataSourcesBaseUrl + method;
        }
    };
    ApiService.prototype.dataSourcesProjectMethodURL = function (projectName, method, direct) {
        if (direct === void 0) { direct = false; }
        return this.dataSourcesMethodURL("projects/" + projectName + "/" + method, direct);
    };
    ApiService.prototype.dataSourcesEnvironmentMethodURL = function (projectName, environmentName, method, direct) {
        if (direct === void 0) { direct = false; }
        return this.dataSourcesMethodURL("projects/" + projectName + "/" + environmentName + "/" + method, direct);
    };
    Object.defineProperty(ApiService.prototype, "createOAuthTokenUrl", {
        get: function () {
            return this.methodURL('create_oauth_token/');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ApiService.prototype, "createOAuthTokenCompleteUrl", {
        get: function () {
            return this.methodURL('create_oauth_token_complete/');
        },
        enumerable: true,
        configurable: true
    });
    ApiService.prototype.getToken = function (forceOriginal) {
        if (forceOriginal === void 0) { forceOriginal = false; }
        if (this.overrideToken && !forceOriginal) {
            return this.overrideToken.token;
        }
        else if (this.isSessionScope()) {
            return this.sessionStorage.get('token');
        }
        else {
            return this.cookieStorage.get('token');
        }
    };
    ApiService.prototype.getProjectToken = function (forceOriginal) {
        if (forceOriginal === void 0) { forceOriginal = false; }
        if (this.overrideToken && !forceOriginal) {
            return;
        }
        else if (this.isSessionScope()) {
            return this.sessionStorage.get('project_token');
        }
        else {
            return this.cookieStorage.get('project_token');
        }
    };
    ApiService.prototype.getAccessToken = function (forceOriginal) {
        if (forceOriginal === void 0) { forceOriginal = false; }
        if (this.overrideToken && !forceOriginal) {
            return this.overrideToken.accessToken;
        }
        else if (this.isSessionScope()) {
            return this.sessionStorage.get('jet_access_token');
        }
        else {
            return this.localStorage.get('jet_access_token');
        }
    };
    ApiService.prototype.getSSOUid = function () {
        if (this.isSessionScope()) {
            return this.sessionStorage.get('jet_sso');
        }
        else {
            return this.localStorage.get('jet_sso');
        }
    };
    ApiService.prototype.getSocialBackend = function () {
        if (this.isSessionScope()) {
            return this.sessionStorage.get('jet_social');
        }
        else {
            return this.localStorage.get('jet_social');
        }
    };
    ApiService.prototype.getIncognito = function () {
        if (this.isSessionScope()) {
            return this.sessionStorage.get('jet_incognito');
        }
        else {
            return this.localStorage.get('jet_incognito');
        }
    };
    Object.defineProperty(ApiService.prototype, "overrideToken", {
        get: function () {
            return this._overrideToken$.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ApiService.prototype, "overrideToken$", {
        get: function () {
            return this._overrideToken$.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    ApiService.prototype.setOverrideToken = function (value) {
        this._overrideToken$.next(value);
    };
    ApiService.prototype.clearOverrideToken = function () {
        this.setOverrideToken(undefined);
    };
    ApiService.prototype.getAuthorization = function (childProjectName, forceOriginal) {
        if (forceOriginal === void 0) { forceOriginal = false; }
        var accessToken = this.getAccessToken(forceOriginal);
        if (accessToken) {
            return "JWT " + accessToken;
        }
        // TODO: Remove after legacy tokens are restricted
        var token = this.getToken(forceOriginal);
        var projectToken = this.getProjectToken(forceOriginal);
        var tokenValue;
        if (token) {
            tokenValue = "Token " + token;
        }
        else if (projectToken) {
            tokenValue = "ProjectToken " + projectToken;
        }
        if (childProjectName && tokenValue) {
            tokenValue = tokenValue + ";project_child=" + childProjectName;
        }
        if (tokenValue) {
            return tokenValue;
        }
    };
    ApiService.prototype.setHeadersToken = function (headers, childProjectName, forceOriginal) {
        if (forceOriginal === void 0) { forceOriginal = false; }
        var authorization = this.getAuthorization(childProjectName, forceOriginal);
        if (authorization) {
            headers = headers.set('Authorization', authorization);
        }
        return headers;
    };
    ApiService.prototype.refreshToken = function (forceOriginal) {
        var _this = this;
        if (forceOriginal === void 0) { forceOriginal = false; }
        return defer(function () {
            if (_this.refreshObs) {
                return _this.refreshObs;
            }
            var refreshObs = _this.executeRefreshToken(forceOriginal).pipe(share());
            _this.refreshObs = refreshObs;
            refreshObs.subscribe(function () {
                _this.refreshObs = undefined;
            }, function () {
                _this.refreshObs = undefined;
            });
            return refreshObs;
        });
    };
    ApiService.prototype.executeRefreshToken = function (forceOriginal) {
        var _this = this;
        if (forceOriginal === void 0) { forceOriginal = false; }
        var accessToken;
        var accessTokenExpires;
        if (this.overrideToken && !forceOriginal) {
            accessToken = this.overrideToken.accessToken;
            accessTokenExpires = this.overrideToken.accessTokenExpires;
        }
        else if (this.isSessionScope()) {
            var expiresStr = this.sessionStorage.get('jet_access_token_expires');
            accessToken = this.sessionStorage.get('jet_access_token');
            accessTokenExpires = expiresStr ? moment(expiresStr) : undefined;
        }
        else {
            var expiresStr = this.localStorage.get('jet_access_token_expires');
            accessToken = this.localStorage.get('jet_access_token');
            accessTokenExpires = expiresStr ? moment(expiresStr) : undefined;
        }
        if (accessToken && !isTokenExpired(accessTokenExpires)) {
            return of(true);
        }
        var refreshToken;
        var refreshTokenExpires;
        if (this.overrideToken && !forceOriginal) {
            refreshToken = this.overrideToken.refreshToken;
            refreshTokenExpires = this.overrideToken.refreshTokenExpires;
        }
        else if (this.isSessionScope()) {
            var expiresStr = this.sessionStorage.get('jet_refresh_token_expires');
            refreshToken = this.sessionStorage.get('jet_refresh_token');
            refreshTokenExpires = expiresStr ? moment(expiresStr) : undefined;
        }
        else {
            var expiresStr = this.localStorage.get('jet_refresh_token_expires');
            refreshToken = this.localStorage.get('jet_refresh_token');
            refreshTokenExpires = expiresStr ? moment(expiresStr) : undefined;
        }
        if (!refreshToken || !refreshTokenExpires || refreshTokenExpires.diff(moment(), 'minutes') < 1) {
            return of(false);
        }
        var url = this.methodURL('token/refresh/');
        var data = { refresh: refreshToken };
        return this.http.post(url, data).pipe(tap(function (result) {
            var options = {
                accessToken: result['access_token'],
                accessTokenExpires: moment(result['access_token_expires']),
                serverTime: moment(result['server_time'])
            };
            if (result['refresh_token']) {
                options.refreshToken = result['refresh_token'];
            }
            if (result['refresh_token_expires']) {
                options.refreshTokenExpires = moment(result['refresh_token_expires']);
            }
            _this.saveToken(options, forceOriginal);
        }), map(function () { return true; }));
    };
    ApiService.prototype.isUserToken = function () {
        if (this.overrideToken) {
            return this.overrideToken.token != undefined || this.overrideToken.refreshToken != undefined;
        }
        else if (this.isSessionScope()) {
            return this.sessionStorage.get('token') != undefined || this.sessionStorage.get('jet_refresh_token') != undefined;
        }
        else {
            return this.cookieStorage.get('token') != undefined || this.localStorage.get('jet_refresh_token') != undefined;
        }
    };
    ApiService.prototype.isProjectToken = function () {
        if (this.overrideToken) {
            return false;
        }
        else if (this.isSessionScope()) {
            return !this.isUserToken() && this.sessionStorage.get('project_token') != undefined;
        }
        else {
            return !this.isUserToken() && this.cookieStorage.get('project_token') != undefined;
        }
    };
    ApiService.prototype.saveToken = function (options, forceOriginal) {
        if (forceOriginal === void 0) { forceOriginal = false; }
        var getLocalTime = function (date) {
            return moment().add(date.diff(options.serverTime), 'milliseconds').toISOString();
        };
        if (this.overrideToken && !forceOriginal) {
            var newToken = __assign({}, this.overrideToken);
            if (options.token) {
                newToken.token = options.token;
            }
            if (options.accessToken) {
                newToken.accessToken = options.accessToken;
                newToken.accessTokenExpires = moment(getLocalTime(options.accessTokenExpires));
            }
            if (options.refreshToken) {
                newToken.refreshToken = options.refreshToken;
                newToken.refreshTokenExpires = moment(getLocalTime(options.refreshTokenExpires));
            }
            if (options.sso) {
                newToken.sso = options.sso;
            }
            this.setOverrideToken(newToken);
        }
        else if (this.isSessionScope()) {
            if (options.token) {
                this.sessionStorage.set('token', options.token);
            }
            if (options.accessToken) {
                this.sessionStorage.set('jet_access_token', options.accessToken);
                this.sessionStorage.set('jet_access_token_expires', getLocalTime(options.accessTokenExpires));
            }
            if (options.refreshToken) {
                this.sessionStorage.set('jet_refresh_token', options.refreshToken);
                this.sessionStorage.set('jet_refresh_token_expires', getLocalTime(options.refreshTokenExpires));
            }
            if (options.sso) {
                this.sessionStorage.set('jet_sso', options.sso);
            }
            if (options.social) {
                this.sessionStorage.set('jet_social', options.social);
            }
            if (options.incognito) {
                this.sessionStorage.set('jet_incognito', '1');
            }
        }
        else {
            if (options.token) {
                this.cookieStorage.set('token', options.token);
            }
            if (options.accessToken) {
                this.localStorage.set('jet_access_token', options.accessToken);
                this.localStorage.set('jet_access_token_expires', getLocalTime(options.accessTokenExpires));
            }
            if (options.refreshToken) {
                this.localStorage.set('jet_refresh_token', options.refreshToken);
                this.localStorage.set('jet_refresh_token_expires', getLocalTime(options.refreshTokenExpires));
            }
            if (options.sso) {
                this.localStorage.set('jet_sso', options.sso);
            }
            if (options.social) {
                this.localStorage.set('jet_social', options.social);
            }
            if (options.incognito) {
                this.localStorage.set('jet_incognito', '1');
            }
        }
        this.deleteProjectToken();
    };
    ApiService.prototype.deleteToken = function () {
        if (this.overrideToken) {
            this.setOverrideToken({});
        }
        else if (this.isSessionScope()) {
            this.sessionStorage.remove('token');
            this.sessionStorage.remove('jet_access_token');
            this.sessionStorage.remove('jet_access_token_expires');
            this.sessionStorage.remove('jet_refresh_token');
            this.sessionStorage.remove('jet_refresh_token_expires');
            this.sessionStorage.remove('jet_sso');
            this.sessionStorage.remove('jet_social');
            this.sessionStorage.remove('jet_incognito');
        }
        else {
            this.cookieStorage.remove('token');
            this.localStorage.remove('jet_access_token');
            this.localStorage.remove('jet_access_token_expires');
            this.localStorage.remove('jet_refresh_token');
            this.localStorage.remove('jet_refresh_token_expires');
            this.localStorage.remove('jet_sso');
            this.localStorage.remove('jet_social');
            this.localStorage.remove('jet_incognito');
        }
    };
    ApiService.prototype.deleteProjectAccessTokens = function () {
        var _this = this;
        var sessionKeys = this.sessionStorage.keys();
        sessionKeys.forEach(function (item) {
            if (/jet_project_(.+)_access_token/.exec(item) || /jet_project_(.+)_access_token_expires/.exec(item)) {
                _this.sessionStorage.remove(item);
            }
        });
        if (this.isSessionScope()) {
            sessionKeys.forEach(function (item) {
                if (/jet_project_(.+)_refresh_token/.exec(item) || /jet_project_(.+)_refresh_token_expires/.exec(item)) {
                    _this.sessionStorage.remove(item);
                }
            });
        }
        else {
            var localStorageKeys = this.localStorage.keys();
            localStorageKeys.forEach(function (item) {
                if (/jet_project_(.+)_refresh_token/.exec(item) || /jet_project_(.+)_refresh_token_expires/.exec(item)) {
                    _this.localStorage.remove(item);
                }
            });
        }
    };
    ApiService.prototype.saveProjectToken = function (token) {
        var expires = new Date();
        expires.setDate(expires.getDate() + 30);
        if (this.isSessionScope()) {
            this.sessionStorage.set('project_token', token);
        }
        else {
            this.cookieStorage.set('project_token', token);
        }
        this.deleteToken();
    };
    ApiService.prototype.deleteProjectToken = function () {
        if (this.isSessionScope()) {
            this.sessionStorage.remove('project_token');
        }
        else {
            this.cookieStorage.remove('project_token');
        }
    };
    ApiService.prototype.catchApiError = function (processAuthExpire) {
        var _this = this;
        if (processAuthExpire === void 0) { processAuthExpire = true; }
        return catchError(function (error) {
            if (processAuthExpire && error instanceof HttpErrorResponse && error.status == 401) {
                console.error(error);
            }
            var serverError;
            if (error instanceof ServerRequestError) {
                serverError = error;
            }
            else {
                serverError = new ServerRequestError(error);
                if (!(error.status == 401 && serverError.errors[0] == 'Authentication credentials were not provided.')) {
                    _this.onError.next(serverError);
                }
            }
            return throwError(serverError);
        });
    };
    ApiService.prototype.enableSessionScope = function () {
        this.sessionStorage.set('session_scope', '1');
    };
    ApiService.prototype.disableSessionScope = function () {
        this.sessionStorage.remove('session_scope');
    };
    ApiService.prototype.isSessionScope = function () {
        return !!this.sessionStorage.get('session_scope');
    };
    ApiService.ngInjectableDef = i0.defineInjectable({ factory: function ApiService_Factory() { return new ApiService(i0.inject(i1.CookieStorage), i0.inject(i2.LocalStorage), i0.inject(i3.SessionStorage), i0.inject(i4.AppConfigService), i0.inject(i5.HttpClient)); }, token: ApiService, providedIn: "root" });
    return ApiService;
}());
export { ApiService };
