import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ActionElementStylesDirective } from './directives/action-element-styles/action-element-styles.directive';
import { ElementWrapperStylesDirective } from './directives/element-wrapper-styles/element-wrapper-styles.directive';
import { TextStyleDirective } from './directives/text-style/text-style.directive';
import { ActionElementStylesPipe } from './pipes/action-element-styles/action-element-styles.pipe';
import { DomainFormatNumberPipe } from './pipes/domain-format-number/domain-format-number.pipe';
import { ElementWrapperStylesPipe } from './pipes/element-wrapper-styles/element-wrapper-styles.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [
    DomainFormatNumberPipe,
    TextStyleDirective,
    ActionElementStylesDirective,
    ActionElementStylesPipe,
    ElementWrapperStylesDirective,
    ElementWrapperStylesPipe
  ],
  exports: [
    DomainFormatNumberPipe,
    TextStyleDirective,
    ActionElementStylesDirective,
    ActionElementStylesPipe,
    ElementWrapperStylesDirective,
    ElementWrapperStylesPipe
  ]
})
export class CustomizeSharedModule {}
