import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, Observable } from 'rxjs';
import { debounceTime, filter, switchMap } from 'rxjs/operators';

import { DialogService } from '@common/dialogs';
import { NotificationService } from '@common/notifications';
import { UniversalAnalyticsService } from '@modules/analytics';
import { KanbanBoardSettings } from '@modules/customize';
import { ParameterField } from '@modules/fields';
import {
  AFTER_ITEM_OUTPUT,
  BEFORE_ITEM_OUTPUT,
  COLUMN_FROM_OUTPUT,
  COLUMN_OUTPUT,
  COLUMN_TO_OUTPUT,
  ITEM_OUTPUT,
  ORDER_OUTPUT,
  PREV_AFTER_ITEM_OUTPUT,
  PREV_BEFORE_ITEM_OUTPUT,
  PREV_COLUMN_OUTPUT,
  PREV_ORDER_OUTPUT,
  SELECTED_ITEM_OUTPUT
} from '@modules/list';
import { SidebarCollapseContext } from '@modules/sidebar';
import { CustomViewTemplateType } from '@modules/views';
import { isSet } from '@shared';

import { CustomizeBarContext } from '../../../services/customize-bar-context/customize-bar.context';
import { ListModelDescriptionDataSourceControl } from '../../model-description-data-source-edit/list-model-description-data-source';
import { CustomizeBarListLayoutSettingsComponent } from '../customize-bar-list-layout-settings/customize-bar-list-layout-settings.component';
import { CustomizeBarKanbanBoardSettingsDataSourceComponent } from './customize-bar-kanban-board-settings-data-source/customize-bar-kanban-board-settings-data-source.component';
import { CustomizeBarKanbanBoardSettingsForm } from './customize-bar-kanban-board-settings.form';
import { KanbanStageControl } from './kanban-stage.control';

@Component({
  selector: 'app-customize-bar-kanban-board-settings',
  templateUrl: './customize-bar-kanban-board-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ListModelDescriptionDataSourceControl, CustomizeBarKanbanBoardSettingsForm]
})
export class CustomizeBarKanbanBoardSettingsComponent
  extends CustomizeBarListLayoutSettingsComponent<KanbanBoardSettings>
  implements OnInit, OnDestroy {
  itemContextElementPath = [ITEM_OUTPUT];
  selectedItemContextElementPath = [SELECTED_ITEM_OUTPUT];
  itemColumnChangeContextElementPaths = [[ITEM_OUTPUT], [COLUMN_FROM_OUTPUT], [COLUMN_TO_OUTPUT]];
  itemOrderChangeContextElementPaths = [
    [ITEM_OUTPUT],
    [ORDER_OUTPUT],
    [BEFORE_ITEM_OUTPUT],
    [AFTER_ITEM_OUTPUT],
    [COLUMN_OUTPUT],
    [PREV_ORDER_OUTPUT],
    [PREV_BEFORE_ITEM_OUTPUT],
    [PREV_AFTER_ITEM_OUTPUT],
    [PREV_COLUMN_OUTPUT]
  ];
  dataSourceEditComponent = CustomizeBarKanbanBoardSettingsDataSourceComponent;
  collapseContext = new SidebarCollapseContext();
  columnParameters$: Observable<ParameterField[]>;
  stageTitleTextStyleIsDefault: Observable<boolean>;
  actionsRequiredFields = [
    'card_click_action',
    'card_column_change_action',
    'card_order_change_action',
    'actions',
    'model_actions'
  ];
  createdStage: KanbanStageControl;
  addMissingLoading = false;
  customViewTemplateTypes = CustomViewTemplateType;

  constructor(
    public form: CustomizeBarKanbanBoardSettingsForm,
    private notificationService: NotificationService,
    customizeBarContext: CustomizeBarContext,
    dialogService: DialogService,
    analyticsService: UniversalAnalyticsService,
    cd: ChangeDetectorRef
  ) {
    super(form, customizeBarContext, dialogService, analyticsService, cd);
  }

  ngOnInit() {
    super.ngOnInit();
    this.form.context = this.context;
    this.form.contextElement = this.contextElement;
    this.columnParameters$ = this.form.controls.data_source.controls.columns.getParameters$();
    this.stageTitleTextStyleIsDefault = this.form.controls.element_styles.controls.stage_title_style.isDefaultStyle$();

    combineLatest(this.form.controls.data_source.getModelDescription$(), this.form.controls.stage_field.valueChanges)
      .pipe(
        filter(([modelDescription, stage]) => isSet(modelDescription) && isSet(stage)),
        debounceTime(10),
        switchMap(() => this.form.getMissing())
      )
      .subscribe(result => {
        if (result && result.length) {
          this.form.controls.stages.addMissingByValues(result);
        }
      });
  }

  createStage() {
    this.createdStage = this.form.controls.stages.appendControl();
    this.cd.markForCheck();
  }

  removeStage(group: KanbanStageControl, i) {
    // this.dialogService
    //   .warning({
    //     title: 'Delete Stage',
    //     description: `Are you sure want to delete <strong>Stage #${i + 1}</strong>?`
    //   })
    //   .pipe(untilDestroyed(this))
    //   .subscribe(result => {
    //     if (result) {
    // this.form.formArrayRemove('stages', group);
    this.form.controls.stages.removeControl(group);
    this.cd.markForCheck();
    //   }
    // });
  }

  addMissing() {
    this.addMissingLoading = true;
    this.cd.markForCheck();

    this.form
      .getMissing()
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          if (!result) {
            this.notificationService.error('Failed', 'Query failed');
            return;
          }

          if (result.length == 0) {
            this.notificationService.warning('No missing values', 'All found values are already added');
          } else {
            const maxValues = 10;
            let values = result;

            if (result.length > maxValues) {
              values = values.slice(0, maxValues);
              this.notificationService.success(
                'Missing values added',
                `
                Found <strong>${result.length}</strong> missing values,
                added first <strong>${values.length}</strong> of them
              `
              );
            } else {
              this.notificationService.success(
                'Missing values added',
                `
                <strong>${values.length}</strong> values were added
              `
              );
            }

            this.form.controls.stages.addMissingByValues(values);
          }

          this.addMissingLoading = false;
          this.cd.markForCheck();
        },
        () => {
          this.addMissingLoading = false;
          this.cd.markForCheck();
        }
      );
  }

  dragDrop(event: CdkDragDrop<KanbanStageControl[]>) {
    if (event.previousIndex !== event.currentIndex) {
      moveItemInArray(this.form.controls.stages.controls, event.previousIndex, event.currentIndex);
      this.form.controls.stages.updateValueAndValidity();
    }
  }
}
