import { ChangeDetectorRef, EventEmitter, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { TintStyle } from '@modules/actions';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { DataSourceType, ListModelDescriptionDataSource } from '@modules/data-sources';
import { FieldType, getFieldDescriptionByType, Input as FieldInput } from '@modules/fields';
import { FilterItem2 } from '@modules/filters';
import { ModelDescriptionStore } from '@modules/model-queries';
import { traverseModelPath } from '@modules/models';
import { ModelSelectSource } from '@modules/models-list';
import { CurrentEnvironmentStore } from '@modules/projects';
import { ListModelDescriptionQuery, ModelDescriptionQuery, QueryType } from '@modules/queries';
import { ResourceControllerService } from '@modules/resources';
import { isSet } from '@shared';
var FiltersListItemComponent = /** @class */ (function () {
    function FiltersListItemComponent(currentEnvironmentStore, modelDescriptionStore, resourceControllerService, source, cd) {
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.modelDescriptionStore = modelDescriptionStore;
        this.resourceControllerService = resourceControllerService;
        this.source = source;
        this.cd = cd;
        this.filters = [];
        this.bright = false;
        this.theme = false;
        this.filtersUpdated = new EventEmitter();
        this.cls = true;
        this.fieldPath = [];
        this.valueLoading = false;
        this.tintStyles = TintStyle;
    }
    Object.defineProperty(FiltersListItemComponent.prototype, "activeCls", {
        get: function () {
            return false;
        },
        enumerable: true,
        configurable: true
    });
    FiltersListItemComponent.prototype.ngOnInit = function () { };
    FiltersListItemComponent.prototype.ngOnDestroy = function () { };
    FiltersListItemComponent.prototype.ngOnChanges = function (changes) {
        if (changes.item && changes.dataSource) {
            this.updateValueDisplay();
        }
    };
    FiltersListItemComponent.prototype.getRelatedFieldValueDisplay = function (relatedModelDescription, field, value) {
        var listQuery = new ListModelDescriptionQuery();
        var detailQuery = new ModelDescriptionQuery();
        listQuery.queryType = QueryType.Simple;
        listQuery.simpleQuery = new listQuery.simpleQueryClass();
        listQuery.simpleQuery.model = relatedModelDescription.model;
        detailQuery.queryType = QueryType.Simple;
        detailQuery.simpleQuery = new detailQuery.simpleQueryClass();
        detailQuery.simpleQuery.model = relatedModelDescription.model;
        var nameInput = field.params['custom_display_field_input']
            ? new FieldInput().deserialize(field.params['custom_display_field_input'])
            : undefined;
        var subtitleInput = field.params['subtitle_input']
            ? new FieldInput().deserialize(field.params['subtitle_input'])
            : undefined;
        var iconInput = field.params['icon_input'] ? new FieldInput().deserialize(field.params['icon_input']) : undefined;
        var resource = this.currentEnvironmentStore.resources.find(function (item) {
            return item.uniqueName == relatedModelDescription.resource;
        });
        var controller = resource ? this.resourceControllerService.get(resource.type) : undefined;
        var detailQueryParameters = controller
            ? controller.getDetailParametersOrDefaults(resource, relatedModelDescription)
            : [];
        this.source.init({
            resource: relatedModelDescription.resource,
            query: listQuery,
            queryParameters: relatedModelDescription.getParameters,
            detailQuery: detailQuery,
            detailQueryParameters: detailQueryParameters,
            valueField: field.params['custom_primary_key'] || relatedModelDescription.primaryKeyField,
            nameField: field.params['custom_display_field'] || relatedModelDescription.displayField,
            nameInput: nameInput,
            subtitleField: field.params['subtitle_field'],
            subtitleInput: subtitleInput,
            iconField: field.params['icon_field'],
            iconInput: iconInput,
            context: this.context,
            contextElement: this.contextElement
        });
        this.source.reset();
        return this.source.fetchByValue(value).pipe(map(function (option) { return (option ? option.name : value); }));
    };
    FiltersListItemComponent.prototype.getDataSourceParams = function () {
        var _this = this;
        var type = this.dataSource ? this.dataSource.type : undefined;
        var query = type == DataSourceType.Query ? this.dataSource.query : undefined;
        var resource = type == DataSourceType.Query
            ? this.currentEnvironmentStore.resources.find(function (item) { return item.uniqueName == _this.dataSource.queryResource; })
            : undefined;
        var modelId = resource && query && query.queryType == QueryType.Simple && query.simpleQuery
            ? [resource.uniqueName, query.simpleQuery.model].join('.')
            : undefined;
        return {
            type: type,
            query: query,
            resource: resource,
            modelId: modelId
        };
    };
    FiltersListItemComponent.prototype.traverseDataSourcePath$ = function (path) {
        var modelId = this.getDataSourceParams().modelId;
        if (isSet(modelId)) {
            return this.modelDescriptionStore.getFirst().pipe(map(function (modelDescriptions) {
                var modelDescription = isSet(modelId) ? modelDescriptions.find(function (item) { return item.isSame(modelId); }) : undefined;
                var traversePath = traverseModelPath(modelDescription, path, modelDescriptions);
                if (!traversePath || !traversePath.length) {
                    return;
                }
                return {
                    path: traversePath.map(function (item) {
                        return {
                            name: item.name,
                            verboseName: item.verboseName
                        };
                    }),
                    field: traversePath[traversePath.length - 1].field
                };
            }));
        }
        else if (this.dataSource) {
            if (!path || path.length != 1) {
                return of(undefined);
            }
            var field = this.dataSource.columns.find(function (item) { return item.name == path[0]; });
            return of({
                path: [{ name: field.name, verboseName: field.verboseName }],
                field: field
            });
        }
        else {
            return of(undefined);
        }
    };
    FiltersListItemComponent.prototype.updateValueDisplay = function () {
        var _this = this;
        this.valueLoading = false;
        this.cd.markForCheck();
        combineLatest(this.traverseDataSourcePath$(this.item.field), this.modelDescriptionStore.getFirst())
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var path = _a[0], modelDescriptions = _a[1];
            var value = _this.item.value;
            var field = path ? path.field : undefined;
            var fieldDescription = getFieldDescriptionByType(field ? field.field : undefined);
            var lookup = _this.item.lookup
                ? fieldDescription.lookups.find(function (item) { return item.type.lookup == _this.item.lookup.lookup; })
                : undefined;
            var lookupFieldDescription = getFieldDescriptionByType(lookup && lookup.field ? lookup.field : FieldType.Boolean);
            var relatedModelId = field && field.field == FieldType.RelatedModel && field.params && field.params['related_model']
                ? field.params['related_model']['model']
                : undefined;
            var relatedModelDescription = relatedModelId
                ? modelDescriptions.find(function (item) { return item.isSame(relatedModelId); })
                : undefined;
            _this.fieldPath = path ? path.path.map(function (item) { return item.verboseName; }) : [];
            _this.valueFieldDescription = fieldDescription;
            _this.cd.markForCheck();
            if (relatedModelDescription && lookup.field == FieldType.RelatedModel) {
                _this.valueLoading = true;
                _this.cd.markForCheck();
                _this.getRelatedFieldValueDisplay(relatedModelDescription, field, value)
                    .pipe(untilDestroyed(_this))
                    .subscribe(function (result) {
                    _this.valueDisplay = result;
                    _this.valueLoading = false;
                    _this.cd.markForCheck();
                }, function () {
                    _this.valueDisplay = value;
                    _this.valueLoading = false;
                    _this.cd.markForCheck();
                });
            }
            else {
                if (lookupFieldDescription.deserializeValue) {
                    value = lookupFieldDescription.deserializeValue(value, field);
                }
                if (lookupFieldDescription.valueToStr) {
                    value = lookupFieldDescription.valueToStr(value, { field: field });
                }
                _this.valueDisplay = value;
                _this.cd.markForCheck();
            }
        });
    };
    return FiltersListItemComponent;
}());
export { FiltersListItemComponent };
