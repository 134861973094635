import { FormControl, FormGroup } from '@angular/forms';

import { GradientStop } from '@modules/customize';
import { isSet } from '@shared';

export class GradientStopControl extends FormGroup {
  instance: GradientStop;

  controls: {
    position: FormControl;
    color: FormControl;
  };

  constructor(state: Partial<GradientStop> = {}) {
    super({
      position: new FormControl(isSet(state.position) ? state.position : 0),
      color: new FormControl(isSet(state.color) ? state.color : '')
    });
  }

  deserialize(value: GradientStop, options: { emitEvent?: boolean } = {}) {
    this.instance = value;

    this.controls.position.patchValue(value.position, { emitEvent: options.emitEvent });
    this.controls.color.patchValue(value.color, { emitEvent: options.emitEvent });
  }

  getInstance(instance?: GradientStop): GradientStop {
    if (!instance) {
      instance = new GradientStop();
      instance.generateId();
    }

    instance.position = this.controls.position.value;
    instance.color = isSet(this.controls.color.value) ? this.controls.color.value : undefined;

    return instance;
  }

  getId(): string {
    return this.instance ? this.instance.id : undefined;
  }

  serialize(): GradientStop {
    return this.getInstance(this.instance);
  }
}
