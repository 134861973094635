var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, Injector } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { of, timer } from 'rxjs';
import { catchError, finalize, map, switchMap } from 'rxjs/operators';
import { AppFormGroup, FormUtils } from '@common/form-utils';
import { PopupService } from '@common/popups';
import { ServerRequestError } from '@modules/api';
import { MenuGeneratorService } from '@modules/menu';
import { ProjectSettingsService } from '@modules/project-settings';
import { ProjectTokenService, ResourceType, SecretTokenService } from '@modules/projects';
import { XanoGeneratorService } from '@modules/resource-generators';
import { ResourceControllerService } from '@modules/resources';
import { isSet } from '@shared';
import { BaseResourceSettingsForm } from '../base-resource-settings/base-resource-settings.form';
var XanoResourceSettingsForm = /** @class */ (function (_super) {
    __extends(XanoResourceSettingsForm, _super);
    function XanoResourceSettingsForm(xanoGeneratorService, resourceControllerService, cd, secretTokenService, formUtils, projectSettingsService, projectTokenService, popupService, menuGeneratorService, injector) {
        var _this = _super.call(this, secretTokenService, formUtils, projectSettingsService, projectTokenService, popupService, menuGeneratorService, injector) || this;
        _this.xanoGeneratorService = xanoGeneratorService;
        _this.resourceControllerService = resourceControllerService;
        _this.cd = cd;
        _this.secretTokenService = secretTokenService;
        _this.formUtils = formUtils;
        _this.projectSettingsService = projectSettingsService;
        _this.projectTokenService = projectTokenService;
        _this.popupService = popupService;
        _this.menuGeneratorService = menuGeneratorService;
        _this.injector = injector;
        _this.form = new AppFormGroup({
            access_token: new FormControl('', Validators.required, _this.validateAccessToken()),
            domain: new FormControl('', Validators.required),
            workspace_id: new FormControl('', Validators.required),
            data_source: new FormControl(''),
            branch: new FormControl(''),
            sync: new FormControl(true)
        });
        _this.legacyOptions = false;
        _this.controller = _this.resourceControllerService.get(ResourceType.Xano);
        return _this;
    }
    XanoResourceSettingsForm.prototype.validateAccessToken = function () {
        var _this = this;
        return function (control) {
            return timer(200).pipe(switchMap(function () {
                if (!isSet(control.value)) {
                    return of([]);
                }
                return _this.controller.getInstances(control.value);
            }), map(function () { return undefined; }), finalize(function () { return _this.cd.markForCheck(); }), catchError(function (error) {
                var errorMessage = error instanceof ServerRequestError && error.errors.length ? error.errors[0] : error;
                return of({ server: errorMessage ? [errorMessage] : true });
            }));
        };
    };
    XanoResourceSettingsForm.prototype.initResourceValue = function () {
        var _this = this;
        return this.xanoGeneratorService.getParamsOptions(this.project, this.environment, this.resource).pipe(map(function (result) {
            _this.legacyOptions = isSet(result.apiBaseUrl) && !isSet(result.accessToken);
            _this.form.patchValue({
                access_token: result.accessToken,
                domain: result.domain,
                workspace_id: result.workspaceId,
                data_source: result.dataSource,
                branch: result.branch,
                sync: !!_this.resource.isSynced()
            });
        }));
    };
    XanoResourceSettingsForm.prototype.getOptions = function () {
        return {
            accessToken: this.form.value['access_token'],
            domain: this.form.value['domain'],
            workspaceId: this.form.value['workspace_id'],
            dataSource: this.form.value['data_source'],
            branch: this.form.value['branch']
        };
    };
    XanoResourceSettingsForm.prototype.isOptionsValid = function () {
        return this.xanoGeneratorService.isOptionsValid(this.getOptions());
    };
    XanoResourceSettingsForm.prototype.getParams = function () {
        var _this = this;
        return this.xanoGeneratorService
            .generateParams(this.project, this.environment, this.typeItem, this.getOptions())
            .pipe(map(function (result) {
            return __assign({}, result, { resourceName: _this.resourceForm.value['name'], sync: _this.form.value['sync'], syncModelDescriptions: _this.getParamsSyncModelDescriptions(result) });
        }));
    };
    return XanoResourceSettingsForm;
}(BaseResourceSettingsForm));
export { XanoResourceSettingsForm };
