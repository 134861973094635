<div class="widget" appElement [appElementDetectVisible]="true" (appElementFirstVisible)="firstVisible$.next(true)">
  <div class="widget__body">
    <div
      *ngIf="configured && !error && !loading"
      class="value-widget dashboard-item__draggable"
      [class.value-widget_theme]="theme"
    >
      <app-value-widget-counter
        class="value-widget__counter"
        [widget]="widget"
        [value]="value"
        [icon]="widget.icon"
        [compareValue]="compareValue"
        [compareLabel]="widget.compareName"
        [growthNegative]="widget.compareGrowthNegative"
        [reloadEnabled]="widget.showReload"
        [context]="context"
        [accentColor]="accentColor"
        [valueClickEnabled]="!!widget.clickAction"
        (reload)="reloadData()"
        (valueClick)="onClick($event.element)"
      ></app-value-widget-counter>

      <div *ngIf="chartValue" class="value-widget__chart-container">
        <app-line-chart2
          class="value-widget__chart"
          [datasets]="[chartValue]"
          [yFormat]="widget.format"
          [animate]="false"
          [xAxisVisible]="false"
          [yAxisVisible]="false"
          [legend]="false"
          [tooltips]="false"
          [lineWidth]="2"
          (groupHover)="hoverItem$.next($event)"
        ></app-line-chart2>

        <div *ngIf="hoverItem$ | async as hoverItem" class="value-widget__chart-tooltip">
          {{ hoverItem.group }}:&nbsp;{{ hoverItem.value | appDomainFormatNumber: widget.chartDataset.format | async }}
        </div>
      </div>
    </div>

    <ng-container *ngIf="!configured || error || loading">
      <div class="value-widget dashboard-item__draggable">
        <div class="value-widget__counter">
          <div class="value-widget__label">
            <span [class.loading-animation]="loading"><span class="stub-text">Value</span></span>
          </div>

          <div class="value-widget__value">
            <span [class.loading-animation]="loading"><span class="stub-text">45</span></span>
          </div>
        </div>
      </div>

      <div *ngIf="error" class="widget__error">
        <div class="widget-error">
          <div class="widget-error__title">{{ 'Loading failed' | localize }}</div>
          <div class="widget-error__description" [title]="error">{{ error }}</div>
        </div>
      </div>

      <div *ngIf="!error && !configured" class="widget__error">
        <div class="widget-error">
          <div class="widget-error__title">{{ 'Not configured' | localize }}</div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
