import { isSet } from '@shared';

export function getFontFamilyVariable(fontFamily: string): string {
  if (!isSet(fontFamily)) {
    return;
  }

  const variable = fontFamily.match(/^--(.+)$/);
  if (variable) {
    return variable[1];
  }
}
