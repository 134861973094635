import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';

import { IFrameElementItem, MarginControl } from '@modules/customize';
import { ElementConfigurationService } from '@modules/customize-configuration';
import { Input, Input as FieldInput } from '@modules/fields';
import { FieldInputControl } from '@modules/parameters';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';

@Injectable()
export class CustomizeBarIFrameEditForm extends FormGroup {
  element: IFrameElementItem;

  controls: {
    url: FieldInputControl;
    border: FormControl;
    visible_input: FieldInputControl;
    margin: MarginControl;
  };

  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private elementConfigurationService: ElementConfigurationService
  ) {
    super({
      url: new FieldInputControl({ path: ['value'] }, { validators: Validators.required, updateOn: 'blur' }),
      border: new FormControl(false),
      visible_input: new FieldInputControl({ path: ['value'] }),
      margin: new MarginControl()
    });
  }

  init(element: IFrameElementItem, firstInit = false) {
    this.element = element;

    const value = {
      url: element.url ? element.url.serializeWithoutPath() : {},
      border: element.border,
      visible_input: element.visibleInput ? element.visibleInput.serializeWithoutPath() : {},
      margin: element.margin
    };

    this.patchValue(value, { emitEvent: false });

    if (!firstInit) {
      this.markAsDirty();
    }
  }

  isConfigured(instance: IFrameElementItem): boolean {
    return this.elementConfigurationService.isIFrameConfigured(instance);
  }

  submit(): IFrameElementItem {
    const value = this.value;
    const instance = cloneDeep(this.element) as IFrameElementItem;

    instance.url = value['url'] ? new FieldInput().deserialize(value['url']) : undefined;
    instance.border = value['border'];
    instance.margin = value['margin'];

    if (value['visible_input']) {
      instance.visibleInput = new Input().deserialize(value['visible_input']);
    } else {
      instance.visibleInput = undefined;
    }

    return instance;
  }
}
