import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import keys from 'lodash/keys';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fromEvent } from 'rxjs';

@Directive({
  selector: '[appApplyDragPredicate]'
})
export class ApplyDragPredicateDirective implements OnInit, OnDestroy {
  @Input('appApplyDragPredicate') options: { handleSelector: string; dispatchElement: HTMLElement };

  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    fromEvent<MouseEvent>(this.el.nativeElement, 'mousedown')
      .pipe(untilDestroyed(this))
      .subscribe(e => this.applyDragPredicate(e));
  }

  ngOnDestroy(): void {}

  applyDragPredicate(event: MouseEvent) {
    if (event.target instanceof Element && event.target.closest(this.options.handleSelector)) {
      return;
    }

    const newEvent = this.cloneMouseEvent(event);
    event.stopPropagation();
    this.options.dispatchElement.dispatchEvent(newEvent);
  }

  cloneMouseEvent(event: MouseEvent): MouseEvent {
    const newEvent = new MouseEvent(event.type, event);

    for (const key of keys(event)) {
      try {
        newEvent[key] = event[key];
      } catch (e) {}
    }

    return newEvent;
  }
}
