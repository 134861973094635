<div *ngIf="!fillTypesEnabled || fillTypesEnabled.length > 1" class="sidebar__element sidebar__element_margin_s">
  <div class="compact-selector">
    <div
      *ngIf="!fillTypesEnabled || fillTypesEnabled.includes(fillTypes.Color)"
      class="compact-selector__item"
      [class.compact-selector__item_active]="control.controls.type.value == fillTypes.Color"
      (click)="control.controls.type.patchValue(fillTypes.Color)"
    >
      <div class="compact-selector__item-icon compact-selector__item-icon_solid"></div>
    </div>

    <div
      *ngIf="!fillTypesEnabled || fillTypesEnabled.includes(fillTypes.Gradient)"
      class="compact-selector__item"
      [class.compact-selector__item_active]="
        control.controls.type.value == fillTypes.Gradient &&
        control.controls.gradient.controls.type.value == gradientTypes.Linear
      "
      (click)="control.setGradientType(gradientTypes.Linear)"
    >
      <div class="compact-selector__item-icon compact-selector__item-icon_linear-gradient"></div>
    </div>

    <div
      *ngIf="!fillTypesEnabled || fillTypesEnabled.includes(fillTypes.Gradient)"
      class="compact-selector__item"
      [class.compact-selector__item_active]="
        control.controls.type.value == fillTypes.Gradient &&
        control.controls.gradient.controls.type.value == gradientTypes.Radial
      "
      (click)="control.setGradientType(gradientTypes.Radial)"
    >
      <div class="compact-selector__item-icon compact-selector__item-icon_radial-gradient"></div>
    </div>

    <div
      *ngIf="!fillTypesEnabled || fillTypesEnabled.includes(fillTypes.Gradient)"
      class="compact-selector__item"
      [class.compact-selector__item_active]="
        control.controls.type.value == fillTypes.Gradient &&
        control.controls.gradient.controls.type.value == gradientTypes.Angular
      "
      (click)="control.setGradientType(gradientTypes.Angular)"
    >
      <div class="compact-selector__item-icon compact-selector__item-icon_angular-gradient"></div>
    </div>

    <div
      *ngIf="!fillTypesEnabled || fillTypesEnabled.includes(fillTypes.Gradient)"
      class="compact-selector__item"
      [class.compact-selector__item_active]="
        control.controls.type.value == fillTypes.Gradient &&
        control.controls.gradient.controls.type.value == gradientTypes.Diamond
      "
      (click)="control.setGradientType(gradientTypes.Diamond)"
    >
      <div class="compact-selector__item-icon compact-selector__item-icon_diamond"></div>
    </div>

    <div *ngIf="themeSelector" class="compact-selector__fill compact-selector__fill_right">
      <div class="button-group">
        <div
          class="button-group__item"
          [class.button-group__item_active]="themeService.isDefaultTheme"
          (click)="themeService.theme = 'default'"
        >
          <span class="button-group__item-icon icon-sun"></span>
        </div>

        <div
          class="button-group__item"
          [class.button-group__item_active]="themeService.isDarkTheme"
          (click)="themeService.theme = 'dark'"
        >
          <span class="button-group__item-icon icon-moon"></span>
        </div>
      </div>
    </div>
  </div>
</div>

<app-color-selector
  *ngIf="control.controls.type.value == fillTypes.Color"
  [current]="control.controls.color.value"
  [colors]="[]"
  [customColors]="true"
  [globalColors]="true"
  [alphaEnabled]="true"
  (selected)="control.controls.color.patchValue($event)"
></app-color-selector>

<app-gradient-selector
  *ngIf="control.controls.type.value == fillTypes.Gradient"
  [control]="control.controls.gradient"
  [source]="source"
></app-gradient-selector>
