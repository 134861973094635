/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../common/tips/directives/tip/tip.directive";
import * as i2 from "../../../sidebar/components/sidebar-field/sidebar-field.component.ngfactory";
import * as i3 from "../../../sidebar/components/sidebar-field/sidebar-field.component";
import * as i4 from "../../../parameters-components/components/input-edit/input-edit.component.ngfactory";
import * as i5 from "../../../parameters-components/components/input-edit/input-edit.component";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "../../../analytics/services/universal-analytics/universal-analytics.service";
import * as i8 from "@angular/common";
import * as i9 from "../../../../../../node_modules/@angular/material/menu/typings/index.ngfactory";
import * as i10 from "@angular/material/menu";
import * as i11 from "@angular/cdk/overlay";
import * as i12 from "@angular/cdk/bidi";
import * as i13 from "../../../fields/components/auto-field/auto-field.component.ngfactory";
import * as i14 from "../../../fields/components/auto-field/auto-field.component";
import * as i15 from "../../../sidebar/components/sidebar-card/sidebar-card.component.ngfactory";
import * as i16 from "../../../sidebar/components/sidebar-card/sidebar-card.component";
import * as i17 from "./value-format-edit.component";
var styles_ValueFormatEditComponent = [];
var RenderType_ValueFormatEditComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ValueFormatEditComponent, data: {} });
export { RenderType_ValueFormatEditComponent as RenderType_ValueFormatEditComponent };
function View_ValueFormatEditComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [["class", "sidebar__field-action sidebar__field-action_button icon-function"]], [[2, "sidebar__field-action_active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleInput() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 737280, null, 0, i1.TipDirective, [i0.ElementRef, i0.ComponentFactoryResolver, i0.Injector, i0.ApplicationRef], { content: [0, "content"], optionsRaw: [1, "optionsRaw"] }, null), i0.ɵpod(2, { side: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.control.controls.format_input_enabled.value ? "Disable Formula" : "Set up with Formula"); var currVal_2 = _ck(_v, 2, 0, "top"); _ck(_v, 1, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.control.controls.format_input_enabled.value; _ck(_v, 0, 0, currVal_0); }); }
function View_ValueFormatEditComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "sidebar__element"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "app-sidebar-field", [], null, null, null, i2.View_SidebarFieldComponent_0, i2.RenderType_SidebarFieldComponent)), i0.ɵdid(2, 114688, null, 0, i3.SidebarFieldComponent, [], null, null), (_l()(), i0.ɵeld(3, 0, null, 3, 1, "app-input-edit", [], null, null, null, i4.View_InputEditComponent_0, i4.RenderType_InputEditComponent)), i0.ɵdid(4, 770048, null, 0, i5.InputEditComponent, [i6.FocusMonitor, i7.UniversalAnalyticsService, i0.NgZone, i0.ChangeDetectorRef], { itemForm: [0, "itemForm"], context: [1, "context"], contextElement: [2, "contextElement"], contextElementPath: [3, "contextElementPath"], contextElementPaths: [4, "contextElementPaths"], staticValueDisabled: [5, "staticValueDisabled"], focusedInitial: [6, "focusedInitial"], fill: [7, "fill"], analyticsSource: [8, "analyticsSource"] }, null), (_l()(), i0.ɵeld(5, 0, null, 4, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Example: CONCAT('$', value) "]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_0 = _co.control.controls.format_input; var currVal_1 = _co.context; var currVal_2 = _co.contextElement; var currVal_3 = _co.contextElementPath; var currVal_4 = _co.contextElementPaths; var currVal_5 = true; var currVal_6 = _co.inputFocusedInitial; var currVal_7 = true; var currVal_8 = (_co.analyticsSource + "_value_format"); _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }, null); }
function View_ValueFormatEditComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [["class", "choose-items-item__dropdown-icon"]], [[2, "choose-items-item__dropdown-icon_orange", null]], null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i8.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpad(2, 1)], function (_ck, _v) { var currVal_1 = "choose-items-item__dropdown-icon"; var currVal_2 = _ck(_v, 2, 0, ("icon-" + _v.parent.context.$implicit.icon)); _ck(_v, 1, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = false; _ck(_v, 0, 0, currVal_0); }); }
function View_ValueFormatEditComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "button", [["class", "mat-menu-item"], ["mat-menu-item", ""]], [[2, "mat-menu-item-odd", null], [1, "role", 0], [2, "mat-menu-item-highlighted", null], [2, "mat-menu-item-submenu-trigger", null], [1, "tabindex", 0], [1, "aria-disabled", 0], [1, "disabled", 0]], [[null, "click"], [null, "mouseenter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1)._checkDisabled($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i0.ɵnov(_v, 1)._handleMouseEnter() !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (_co.control.setNumberFormat(_v.context.$implicit.value) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i9.View_MatMenuItem_0, i9.RenderType_MatMenuItem)), i0.ɵdid(1, 180224, [[1, 4]], 0, i10.MatMenuItem, [i0.ElementRef, i8.DOCUMENT, i6.FocusMonitor, [2, i10.ɵf24]], { disableRipple: [0, "disableRipple"] }, null), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_ValueFormatEditComponent_7)), i0.ɵdid(3, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(4, 0, [" ", " "]))], function (_ck, _v) { var currVal_7 = true; _ck(_v, 1, 0, currVal_7); var currVal_8 = _v.context.$implicit.icon; _ck(_v, 3, 0, currVal_8); }, function (_ck, _v) { var currVal_0 = true; var currVal_1 = i0.ɵnov(_v, 1).role; var currVal_2 = i0.ɵnov(_v, 1)._highlighted; var currVal_3 = i0.ɵnov(_v, 1)._triggersSubmenu; var currVal_4 = i0.ɵnov(_v, 1)._getTabIndex(); var currVal_5 = i0.ɵnov(_v, 1).disabled.toString(); var currVal_6 = (i0.ɵnov(_v, 1).disabled || null); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_9 = _v.context.$implicit.name; _ck(_v, 4, 0, currVal_9); }); }
function View_ValueFormatEditComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "button", [["class", "mat-menu-item"], ["mat-menu-item", ""]], [[2, "mat-menu-item-odd", null], [1, "role", 0], [2, "mat-menu-item-highlighted", null], [2, "mat-menu-item-submenu-trigger", null], [1, "tabindex", 0], [1, "aria-disabled", 0], [1, "disabled", 0]], [[null, "click"], [null, "mouseenter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1)._checkDisabled($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i0.ɵnov(_v, 1)._handleMouseEnter() !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (_co.control.controls.number_format.patchValue(undefined) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i9.View_MatMenuItem_0, i9.RenderType_MatMenuItem)), i0.ɵdid(1, 180224, [[1, 4]], 0, i10.MatMenuItem, [i0.ElementRef, i8.DOCUMENT, i6.FocusMonitor, [2, i10.ɵf24]], { disableRipple: [0, "disableRipple"] }, null), (_l()(), i0.ɵeld(2, 0, null, 0, 0, "span", [["class", "choose-items-item__dropdown-icon icon-repeat"]], [[2, "choose-items-item__dropdown-icon_orange", null]], null, null, null, null)), (_l()(), i0.ɵted(-1, 0, [" Default "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ValueFormatEditComponent_6)), i0.ɵdid(5, 278528, null, 0, i8.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 3, "button", [["class", "mat-menu-item"], ["mat-menu-item", ""]], [[2, "mat-menu-item-orange", null], [2, "mat-menu-item_bottom-sticky", null], [2, "mat-menu-item-odd", null], [2, "mat-menu-item-border", null], [1, "role", 0], [2, "mat-menu-item-highlighted", null], [2, "mat-menu-item-submenu-trigger", null], [1, "tabindex", 0], [1, "aria-disabled", 0], [1, "disabled", 0]], [[null, "click"], [null, "mouseenter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 7)._checkDisabled($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i0.ɵnov(_v, 7)._handleMouseEnter() !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (_co.setInput(true) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i9.View_MatMenuItem_0, i9.RenderType_MatMenuItem)), i0.ɵdid(7, 180224, [[1, 4]], 0, i10.MatMenuItem, [i0.ElementRef, i8.DOCUMENT, i6.FocusMonitor, [2, i10.ɵf24]], { disableRipple: [0, "disableRipple"] }, null), (_l()(), i0.ɵeld(8, 0, null, 0, 0, "span", [["class", "choose-items-item__dropdown-icon icon-plus"]], [[2, "choose-items-item__dropdown-icon_orange", null]], null, null, null, null)), (_l()(), i0.ɵted(-1, 0, [" Custom with formula "]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = true; _ck(_v, 1, 0, currVal_7); var currVal_9 = _co.control.numberFormatOptions; _ck(_v, 5, 0, currVal_9); var currVal_20 = true; _ck(_v, 7, 0, currVal_20); }, function (_ck, _v) { var currVal_0 = true; var currVal_1 = i0.ɵnov(_v, 1).role; var currVal_2 = i0.ɵnov(_v, 1)._highlighted; var currVal_3 = i0.ɵnov(_v, 1)._triggersSubmenu; var currVal_4 = i0.ɵnov(_v, 1)._getTabIndex(); var currVal_5 = i0.ɵnov(_v, 1).disabled.toString(); var currVal_6 = (i0.ɵnov(_v, 1).disabled || null); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_8 = false; _ck(_v, 2, 0, currVal_8); var currVal_10 = true; var currVal_11 = true; var currVal_12 = true; var currVal_13 = true; var currVal_14 = i0.ɵnov(_v, 7).role; var currVal_15 = i0.ɵnov(_v, 7)._highlighted; var currVal_16 = i0.ɵnov(_v, 7)._triggersSubmenu; var currVal_17 = i0.ɵnov(_v, 7)._getTabIndex(); var currVal_18 = i0.ɵnov(_v, 7).disabled.toString(); var currVal_19 = (i0.ɵnov(_v, 7).disabled || null); _ck(_v, 6, 0, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15, currVal_16, currVal_17, currVal_18, currVal_19); var currVal_21 = true; _ck(_v, 8, 0, currVal_21); }); }
function View_ValueFormatEditComponent_9(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [["class", "select__input-icon"]], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i8.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpad(2, 1)], function (_ck, _v) { var currVal_0 = "select__input-icon"; var currVal_1 = _ck(_v, 2, 0, ("icon-" + _v.parent.context.ngIf.icon)); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_ValueFormatEditComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ValueFormatEditComponent_9)), i0.ɵdid(2, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 1, "span", [["class", "select__input-label"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""]))], function (_ck, _v) { var currVal_0 = _v.context.ngIf.icon; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.context.ngIf.name; _ck(_v, 4, 0, currVal_1); }); }
function View_ValueFormatEditComponent_10(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "select__input-label"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Format options"]))], null, null); }
function View_ValueFormatEditComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 17, "div", [["class", "sidebar__element"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 16, "app-sidebar-field", [], null, null, null, i2.View_SidebarFieldComponent_0, i2.RenderType_SidebarFieldComponent)), i0.ɵdid(2, 114688, null, 0, i3.SidebarFieldComponent, [], null, null), (_l()(), i0.ɵeld(3, 0, null, 3, 6, "mat-menu", [], null, null, null, i9.View_MatMenu_0, i9.RenderType_MatMenu)), i0.ɵprd(6144, null, i10.ɵf24, null, [i10.MatMenu]), i0.ɵdid(5, 1294336, [["dropdown", 4]], 2, i10.MatMenu, [i0.ElementRef, i0.NgZone, i10.MAT_MENU_DEFAULT_OPTIONS], null, null), i0.ɵqud(603979776, 1, { items: 1 }), i0.ɵqud(335544320, 2, { lazyContent: 0 }), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_ValueFormatEditComponent_5)), i0.ɵdid(9, 147456, [[2, 4]], 0, i10.MatMenuContent, [i0.TemplateRef, i0.ComponentFactoryResolver, i0.ApplicationRef, i0.Injector, i0.ViewContainerRef, i8.DOCUMENT], null, null), (_l()(), i0.ɵeld(10, 0, null, 3, 7, "div", [["class", "select select_theme_jet select_fill"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 16777216, null, null, 6, "div", [["aria-haspopup", "true"], ["class", "select__input"]], [[1, "aria-expanded", 0]], [[null, "mousedown"], [null, "keydown"], [null, "click"]], function (_v, en, $event) { var ad = true; if (("mousedown" === en)) {
        var pd_0 = (i0.ɵnov(_v, 12)._handleMousedown($event) !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i0.ɵnov(_v, 12)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (i0.ɵnov(_v, 12)._handleClick($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i0.ɵdid(12, 1196032, null, 0, i10.MatMenuTrigger, [i11.Overlay, i0.ElementRef, i0.ViewContainerRef, i10.MAT_MENU_SCROLL_STRATEGY, [2, i10.MatMenu], [8, null], [2, i12.Directionality], i6.FocusMonitor], { menu: [0, "menu"] }, null), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_ValueFormatEditComponent_8)), i0.ɵdid(14, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), i0.ɵpid(131072, i8.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(0, [["placeholder", 2]], null, 0, null, View_ValueFormatEditComponent_10)), (_l()(), i0.ɵeld(17, 0, null, null, 0, "span", [["class", "select__input-arrow"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); _ck(_v, 5, 0); var currVal_1 = i0.ɵnov(_v, 5); _ck(_v, 12, 0, currVal_1); var currVal_2 = i0.ɵunv(_v, 14, 0, i0.ɵnov(_v, 15).transform(_co.numberFormatOption$)); var currVal_3 = i0.ɵnov(_v, 16); _ck(_v, 14, 0, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = (i0.ɵnov(_v, 12).menuOpen || null); _ck(_v, 11, 0, currVal_0); }); }
function View_ValueFormatEditComponent_12(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [["class", "sidebar-cols__item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 8, "app-sidebar-field", [], null, null, null, i2.View_SidebarFieldComponent_0, i2.RenderType_SidebarFieldComponent)), i0.ɵdid(2, 114688, null, 0, i3.SidebarFieldComponent, [], null, null), (_l()(), i0.ɵeld(3, 0, null, 3, 4, "app-auto-field", [], null, null, null, i13.View_AutoFieldComponent_0, i13.RenderType_AutoFieldComponent)), i0.ɵdid(4, 770048, null, 0, i14.AutoFieldComponent, [i0.ChangeDetectorRef], { form: [0, "form"], field: [1, "field"], label: [2, "label"] }, null), i0.ɵpad(5, 1), i0.ɵpod(6, { classes: 0 }), i0.ɵpod(7, { name: 0, field: 1, required: 2, placeholder: 3, params: 4 }), (_l()(), i0.ɵeld(8, 0, null, 4, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Prefix "]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_0 = _co.control; var currVal_1 = _co.createField(_ck(_v, 7, 0, "prefix", "CharField", false, "e.g. $", _ck(_v, 6, 0, _ck(_v, 5, 0, "input_fill")))); var currVal_2 = false; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_ValueFormatEditComponent_13(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [["class", "sidebar-cols__item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 8, "app-sidebar-field", [], null, null, null, i2.View_SidebarFieldComponent_0, i2.RenderType_SidebarFieldComponent)), i0.ɵdid(2, 114688, null, 0, i3.SidebarFieldComponent, [], null, null), (_l()(), i0.ɵeld(3, 0, null, 3, 4, "app-auto-field", [], null, null, null, i13.View_AutoFieldComponent_0, i13.RenderType_AutoFieldComponent)), i0.ɵdid(4, 770048, null, 0, i14.AutoFieldComponent, [i0.ChangeDetectorRef], { form: [0, "form"], field: [1, "field"], label: [2, "label"] }, null), i0.ɵpad(5, 1), i0.ɵpod(6, { classes: 0 }), i0.ɵpod(7, { name: 0, field: 1, required: 2, placeholder: 3, params: 4 }), (_l()(), i0.ɵeld(8, 0, null, 4, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Postfix "]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_0 = _co.control; var currVal_1 = _co.createField(_ck(_v, 7, 0, "postfix", "CharField", false, "per month", _ck(_v, 6, 0, _ck(_v, 5, 0, "input_fill")))); var currVal_2 = false; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_ValueFormatEditComponent_11(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "sidebar__element"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "sidebar-cols"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ValueFormatEditComponent_12)), i0.ɵdid(3, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ValueFormatEditComponent_13)), i0.ɵdid(5, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.prefixEnabled; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.postfixEnabled; _ck(_v, 5, 0, currVal_1); }, null); }
function View_ValueFormatEditComponent_15(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "app-auto-field", [], null, null, null, i13.View_AutoFieldComponent_0, i13.RenderType_AutoFieldComponent)), i0.ɵdid(1, 770048, null, 0, i14.AutoFieldComponent, [i0.ChangeDetectorRef], { form: [0, "form"], field: [1, "field"], label: [2, "label"] }, null), i0.ɵpad(2, 2), i0.ɵpod(3, { options: 0, classes: 1 }), i0.ɵpod(4, { name: 0, field: 1, params: 2 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.control; var currVal_1 = _co.createField(_ck(_v, 4, 0, "number_fraction_separator", "SelectField", _ck(_v, 3, 0, _co.control.numberFractionSeparatorOptions, _ck(_v, 2, 0, "select_fill", "select_segment-bottom")))); var currVal_2 = false; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_ValueFormatEditComponent_14(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 12, "div", [["class", "sidebar__element"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 11, "app-sidebar-field", [], null, null, null, i2.View_SidebarFieldComponent_0, i2.RenderType_SidebarFieldComponent)), i0.ɵdid(2, 114688, null, 0, i3.SidebarFieldComponent, [], null, null), (_l()(), i0.ɵeld(3, 0, null, 3, 5, "app-auto-field", [], null, null, null, i13.View_AutoFieldComponent_0, i13.RenderType_AutoFieldComponent)), i0.ɵdid(4, 770048, null, 0, i14.AutoFieldComponent, [i0.ChangeDetectorRef], { form: [0, "form"], field: [1, "field"], label: [2, "label"] }, null), i0.ɵpad(5, 2), i0.ɵpad(6, 1), i0.ɵpod(7, { options: 0, classes: 1 }), i0.ɵpod(8, { name: 0, field: 1, params: 2 }), (_l()(), i0.ɵand(16777216, null, 3, 1, null, View_ValueFormatEditComponent_15)), i0.ɵdid(10, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(11, 0, null, 4, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Fractional part "]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_0 = _co.control; var currVal_1 = _co.createField(_ck(_v, 8, 0, "number_fraction", "SelectField", _ck(_v, 7, 0, _co.control.numberFractionOptions, (_co.control.controls.number_fraction.value ? _ck(_v, 5, 0, "select_fill", "select_segment-top") : _ck(_v, 6, 0, "select_fill"))))); var currVal_2 = false; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.control.controls.number_fraction.value; _ck(_v, 10, 0, currVal_3); }, null); }
function View_ValueFormatEditComponent_16(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [["class", "sidebar__element"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 8, "app-sidebar-field", [], null, null, null, i2.View_SidebarFieldComponent_0, i2.RenderType_SidebarFieldComponent)), i0.ɵdid(2, 114688, null, 0, i3.SidebarFieldComponent, [], null, null), (_l()(), i0.ɵeld(3, 0, null, 3, 4, "app-auto-field", [], null, null, null, i13.View_AutoFieldComponent_0, i13.RenderType_AutoFieldComponent)), i0.ɵdid(4, 770048, null, 0, i14.AutoFieldComponent, [i0.ChangeDetectorRef], { form: [0, "form"], field: [1, "field"], label: [2, "label"] }, null), i0.ɵpad(5, 1), i0.ɵpod(6, { options: 0, classes: 1 }), i0.ɵpod(7, { name: 0, field: 1, params: 2 }), (_l()(), i0.ɵeld(8, 0, null, 4, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Thousands separator "]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_0 = _co.control; var currVal_1 = _co.createField(_ck(_v, 7, 0, "number_thousands_separator", "SelectField", _ck(_v, 6, 0, _co.control.numberThousandsSeparatorOptions, _ck(_v, 5, 0, "select_fill")))); var currVal_2 = false; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_ValueFormatEditComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ValueFormatEditComponent_4)), i0.ɵdid(2, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ValueFormatEditComponent_11)), i0.ɵdid(4, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ValueFormatEditComponent_14)), i0.ɵdid(6, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ValueFormatEditComponent_16)), i0.ɵdid(8, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isNumber && _co.numberFormatOptionsEnabled); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.prefixEnabled || _co.postfixEnabled); _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.isNumber; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.isNumber; _ck(_v, 8, 0, currVal_3); }, null); }
export function View_ValueFormatEditComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [["class", "sidebar__element"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 10, "app-sidebar-field", [], null, null, null, i2.View_SidebarFieldComponent_0, i2.RenderType_SidebarFieldComponent)), i0.ɵdid(2, 114688, null, 0, i3.SidebarFieldComponent, [], { label: [0, "label"] }, null), (_l()(), i0.ɵeld(3, 0, null, 0, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ValueFormatEditComponent_1)), i0.ɵdid(5, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(6, 0, null, 3, 5, "app-sidebar-card", [], null, null, null, i15.View_SidebarCardComponent_0, i15.RenderType_SidebarCardComponent)), i0.ɵdid(7, 114688, null, 0, i16.SidebarCardComponent, [], null, null), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_ValueFormatEditComponent_2)), i0.ɵdid(9, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_ValueFormatEditComponent_3)), i0.ɵdid(11, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "display format"; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.formatInputEnabled; _ck(_v, 5, 0, currVal_1); _ck(_v, 7, 0); var currVal_2 = (_co.formatInputEnabled && _co.control.controls.format_input_enabled.value); _ck(_v, 9, 0, currVal_2); var currVal_3 = (!_co.formatInputEnabled || !_co.control.controls.format_input_enabled.value); _ck(_v, 11, 0, currVal_3); }, null); }
export function View_ValueFormatEditComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-value-format-edit", [], null, null, null, View_ValueFormatEditComponent_0, RenderType_ValueFormatEditComponent)), i0.ɵdid(1, 638976, null, 0, i17.ValueFormatEditComponent, [i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ValueFormatEditComponentNgFactory = i0.ɵccf("app-value-format-edit", i17.ValueFormatEditComponent, View_ValueFormatEditComponent_Host_0, { control: "control", field: "field", formatInputEnabled: "formatInputEnabled", prefixEnabled: "prefixEnabled", postfixEnabled: "postfixEnabled", numberFormatOptionsEnabled: "numberFormatOptionsEnabled", context: "context", contextElement: "contextElement", contextElementPath: "contextElementPath", contextElementPaths: "contextElementPaths", analyticsSource: "analyticsSource" }, {}, []);
export { ValueFormatEditComponentNgFactory as ValueFormatEditComponentNgFactory };
