import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { FieldDescriptionLookup } from '@modules/fields';
import { isSet, TypedChanges } from '@shared';

import { ModelOptionSelectedEvent } from '../select-model-field/select-model-field.component';

@Component({
  selector: 'app-select-model-field-lookup',
  templateUrl: './select-model-field-lookup.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectModelFieldLookupComponent implements OnInit, OnDestroy, OnChanges {
  @Input() fieldSelection: ModelOptionSelectedEvent;
  @Input() lookups: FieldDescriptionLookup[] = [];
  @Input() excludeLookupsSupported = false;
  @Output() selectedLookup = new EventEmitter<{ lookup: FieldDescriptionLookup; exclude: boolean }>();
  @Output() back = new EventEmitter<void>();

  searchControl = new FormControl('');
  filteredLookups: FieldDescriptionLookup[] = [];

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.searchControl.valueChanges.pipe(untilDestroyed(this)).subscribe(() => this.updateFilteredLookups());
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<SelectModelFieldLookupComponent>): void {
    if (changes.lookups) {
      this.updateFilteredLookups();
    }
  }

  getFilteredLookups(lookups: FieldDescriptionLookup[]): FieldDescriptionLookup[] {
    const search = this.searchControl.value.toLowerCase().trim();

    if (!isSet(search)) {
      return lookups;
    }

    return lookups.filter(lookup => {
      return [lookup.type.str(undefined, undefined, false)].some(item => {
        return isSet(item) && item.toLowerCase().includes(search);
      });
    });
  }

  updateFilteredLookups() {
    this.filteredLookups = this.getFilteredLookups(this.lookups);
    this.cd.markForCheck();
  }

  clearSearch() {
    this.searchControl.patchValue('');
    this.updateFilteredLookups();
  }
}
