import { Injectable } from '@angular/core';
import flatten from 'lodash/flatten';
import toPairs from 'lodash/toPairs';

import { CustomizeService, ViewSettings } from '@modules/customize';
import { Input as FieldInput, InputValueType } from '@modules/fields';
import { ModelDescription } from '@modules/models';
import { Environment, Project } from '@modules/projects';
import { isSet } from '@shared';

import { ButtonMenuItem } from '../../data/button-menu-item';
import { CustomMenuItem } from '../../data/custom-menu-item';
import { ImageMenuItem } from '../../data/image-menu-item';
import { ImageMenuItemOption } from '../../data/image-menu-item';
import { MenuItem } from '../../data/menu-item';
import { MenuItemAction, MenuItemActionType } from '../../data/menu-item-action';
import { getMenuItemSystemActionTypeInfo } from '../../data/menu-item-action';
import { MenuItemSystemActionType } from '../../data/menu-item-action';
import { MenuItemType } from '../../data/menu-item-type';
import { MenuSettings } from '../../data/menu-settings';
import { MenuBlockLayout } from '../../data/menu-settings';
import { MenuBlock } from '../../data/menu-settings';
import { ModelLinkMenuItem } from '../../data/model-link-menu-item';
import { SectionMenuItem } from '../../data/section-menu-item';
import { SeparatorMenuItem } from '../../data/separator-menu-item';
import { ShareMenuItem } from '../../data/share-menu-item';
import { SimpleMenuItem } from '../../data/simple-menu-item';
import { filterMenuSettings, forEachMenuSettings } from '../../utils/menu';

@Injectable({
  providedIn: 'root'
})
export class MenuGeneratorService {
  constructor(private customizeService: CustomizeService) {}

  guessIcon(model: ModelDescription) {
    if (!model.verboseName) {
      return;
    }

    const iconMatches = {
      pocket: ['billing'],
      documents: ['document', 'log', 'entry', 'content'],
      admins: ['favourite'],
      alarm: ['time'],
      alert: ['alert'],
      attach_clip: ['attachment', 'upload'],
      bar_code: ['coupon', 'promo', 'code'],
      calendar: ['calendar'],
      bills: ['bills'],
      gift: ['blocks'],
      briefcase: ['career'],
      brush: ['painting'],
      camera: ['photo'],
      chart: ['analytic', 'report'],
      chat: ['message', 'conversation'],
      check_3: ['task', 'guide'],
      configure: ['settings'],
      countries: ['countries'],
      diagram: ['chart', 'statistic', 'widget'],
      football_sports: ['sport'],
      gamepad: ['game'],
      incoming_call: ['call'],
      key: ['key', 'password', 'token', 'permission', 'access', 'session', 'auth'],
      leagues: ['leaderboard'],
      letter_template: ['email', 'letter'],
      location: ['place'],
      notification: ['notification'],
      proposed_restaurants: ['dish'],
      power_measure: ['metric', 'dashboard'],
      payments: ['money', 'transaction', 'bank'],
      repeat: ['subscribe', 'subscription'],
      components: ['database', 'migration', 'data'],
      shield: ['security'],
      tag: ['tag'],
      shopping_cart: ['cart', 'order'],
      teams: ['team', 'group'],
      toggle_theme: ['color'],
      users_teams: ['user', 'people'],
      blocks: ['project']
    };

    const match = toPairs(iconMatches).find(([key, value]) => {
      return value.some(str => model.verboseName.toLowerCase().includes(str));
    });

    if (!match) {
      return;
    }

    return match[0] as string;
  }

  generateInitialSettings(project: Project, models: ModelDescription[]): MenuSettings {
    // TODO: refactor default settings
    const settings = new MenuSettings();

    settings.project = project.uniqueName;
    // settings.primaryCenterItems = [
    //   new SystemMenuItem().deserialize({
    //     type: 'system',
    //     params: {
    //       link_type: 'dashboard'
    //     }
    //   }),
    //   new SystemMenuItem().deserialize({
    //     type: 'system',
    //     params: {
    //       link_type: 'site'
    //     }
    //   })
    //   // new SystemMenuItem().deserialize({
    //   //   type: 'system',
    //   //   params: {
    //   //     link_type: 'conversations'
    //   //   }
    //   // }),
    //   // new SystemMenuItem().deserialize({
    //   //   type: 'system',
    //   //   params: {
    //   //     link_type: 'search'
    //   //   }
    //   // })
    // ];

    // settings.secondaryItems = [
    //   new SystemMenuItem().deserialize({
    //     type: MenuItemType.System,
    //     visible: false,
    //     params: {
    //       link_type: 'dashboard'
    //     }
    //   }),
    //   new SectionMenuItem().deserialize({
    //     type: MenuItemType.Section,
    //     params: {
    //       title: 'Collections',
    //       children: models
    //         .filter(item => !item.hidden)
    //         .sort((lhs, rhs) => {
    //           const lhsName = lhs.verboseNamePlural.toLowerCase();
    //           const rhsName = rhs.verboseNamePlural.toLowerCase();
    //
    //           if (lhsName < rhsName) {
    //             return -1;
    //           } else if (lhsName > rhsName) {
    //             return 1;
    //           } else {
    //             return 0;
    //           }
    //         })
    //         .map(model => {
    //           return {
    //             type: MenuItemType.ModelLink,
    //             params: {
    //               model: model.modelId,
    //               icon: this.guessIcon(model)
    //             }
    //           };
    //         })
    //     }
    //   })
    // ];

    return settings;
  }

  // // TODO: Make immutable
  // addMissingSettingsItems(
  //   settings: MenuSettings,
  //   models: ModelDescription[],
  //   viewSettings: ViewSettings[]
  // ): MenuSettings {
  //   if (!models) {
  //     return settings;
  //   }
  //
  //   const getUsedModels = (items: MenuItem[]) => {
  //     return flatten(
  //       items.map(item => {
  //         if (item instanceof ModelLinkMenuItem) {
  //           return item.model;
  //         } else if (item instanceof SectionMenuItem) {
  //           return getUsedModels(item.children);
  //         }
  //       })
  //     ).filter(item => item != undefined);
  //   };
  //
  //   const usedModels = getUsedModels(settings.getAllItems());
  //   const missingModels = models.filter(item => !usedModels.includes(item.modelId));
  //
  //   settings.secondaryStartItems = settings.secondaryStartItems.concat(
  //     missingModels.map(item => {
  //       const menuItem = new ModelLinkMenuItem();
  //
  //       menuItem.generateId();
  //       menuItem.model = item.modelId;
  //       menuItem.icon = this.guessIcon(item);
  //
  //       return menuItem;
  //     })
  //   );
  //
  //   const getUsedPages = (items: MenuItem[]) => {
  //     return flatten(
  //       items.map(item => {
  //         if (item instanceof SimpleMenuItem && item.action && item.action.type == MenuItemActionType.Page) {
  //           return item.action.pageUid;
  //         } else if (item instanceof SectionMenuItem) {
  //           return getUsedPages(item.children);
  //         }
  //       })
  //     ).filter(item => item != undefined);
  //   };
  //
  //   const usedPages = getUsedPages(settings.getAllItems());
  //   const missingPages = viewSettings.filter(item => isSet(item.name)).filter(item => !usedPages.includes(item.uid));
  //
  //   settings.secondaryStartItems = settings.secondaryStartItems.concat(
  //     missingPages.map(item => {
  //       const menuItem = new SimpleMenuItem();
  //
  //       menuItem.generateId();
  //
  //       menuItem.action = new MenuItemAction();
  //       menuItem.action.type = MenuItemActionType.Page;
  //       menuItem.action.pageUid = item.uid;
  //       menuItem.action.pageUniqueName = item.uniqueName;
  //
  //       return menuItem;
  //     })
  //   );
  //
  //   return settings;
  // }

  // TODO: Make immutable
  filterSettingsPermissions(project: Project, environment: Environment, settings: MenuSettings): MenuSettings {
    if (this.customizeService.layoutEnabled) {
      return settings;
    }

    return filterMenuSettings(settings, item => {
      if (item.type == MenuItemType.ModelLink) {
        const modelItem = item as ModelLinkMenuItem;

        if (!modelItem.model) {
          return false;
        }

        return project.hasEnvironmentModelPermission(environment, modelItem.model, 'r');
      } else if (
        (item instanceof SimpleMenuItem || item instanceof ButtonMenuItem) &&
        item.action &&
        item.action.type == MenuItemActionType.Page
      ) {
        if (!item.action.pageUid) {
          return true;
        }

        return project.hasEnvironmentPagePermission(environment, item.action.pageUid, 'r');
      } else if (
        (item instanceof SimpleMenuItem || item instanceof ButtonMenuItem) &&
        item.action &&
        item.action.type == MenuItemActionType.System &&
        item.action.systemType == MenuItemSystemActionType.Users
      ) {
        return project.hasEnvironmentAccessPermission(environment);
      } else {
        return true;
      }
    });
  }

  // TODO: Make immutable
  filterSettingsNonExistent(
    settings: MenuSettings,
    models: ModelDescription[],
    viewSettings: ViewSettings[]
  ): MenuSettings {
    return filterMenuSettings(settings, item => {
      if (item.type == MenuItemType.ModelLink) {
        const modelItem = item as ModelLinkMenuItem;
        return models.find(model => isSet(modelItem.model) && model.isSame(modelItem.model)) != undefined;
      } else if (
        (item instanceof SimpleMenuItem || item instanceof ButtonMenuItem) &&
        item.action &&
        item.action.type == MenuItemActionType.Page &&
        (isSet(item.action.pageUid) || isSet(item.action.pageUniqueName))
      ) {
        return viewSettings.find(viewSetting => item.isForPage(viewSetting)) != undefined;
      } else {
        return true;
      }
    });
  }

  // TODO: Make immutable
  filterSettingsDuplicates(settings: MenuSettings): MenuSettings {
    const modelLinks: { [key: string]: string[] } = {
      primary: [],
      secondary: []
    };

    return filterMenuSettings(settings, (item, menu) => {
      if (item.type == MenuItemType.ModelLink) {
        const modelItem = item as ModelLinkMenuItem;

        if (modelLinks[menu].includes(modelItem.model)) {
          return false;
        }

        modelLinks[menu].push(modelItem.model);

        return true;
      } else {
        return true;
      }
    });
  }

  // TODO: Make immutable
  filterSettingsEmptySections(settings: MenuSettings): MenuSettings {
    return filterMenuSettings(settings, item => {
      if (item.type != MenuItemType.Section) {
        return true;
      }
      const sectionItem = item as SectionMenuItem;
      return sectionItem.children.length > 0;
    });
  }

  cleanExternalMenuItemData(settings: MenuSettings, models: ModelDescription[], viewSettings: ViewSettings[]) {
    forEachMenuSettings(settings, (menuItem, menu) => {
      if (
        (menuItem instanceof SimpleMenuItem || menuItem instanceof ButtonMenuItem) &&
        menuItem.action &&
        menuItem.action.type == MenuItemActionType.Page
      ) {
        const page = viewSettings.find(item => menuItem.isForPage(item));
        if (page) {
          menuItem.title = page.name;

          menuItem.action = new MenuItemAction();
          menuItem.action.type = MenuItemActionType.Page;
          menuItem.action.pageUid = page.uid;
          // Backward compatibility
          menuItem.action.pageUniqueName = page.uniqueName;
        }
      } else if (menuItem instanceof ModelLinkMenuItem) {
        const model = models.find(item => item.isSame(menuItem.model));

        if (model) {
          menuItem.title = model.verboseNamePlural;
        }
      }
    });

    return settings;
  }

  validate(
    project: Project,
    environment: Environment,
    settings: MenuSettings,
    models: ModelDescription[],
    viewSettings: ViewSettings[]
  ) {
    if (!settings) {
      settings = this.generateInitialSettings(project, models);
    } else {
      settings = this.filterSettingsNonExistent(settings, models, viewSettings);
      // settings = this.filterSettingsDuplicates(settings);
      // settings = this.addMissingSettingsItems(settings, models, viewSettings);
    }

    settings = this.filterSettingsPermissions(project, environment, settings);
    settings = this.filterSettingsEmptySections(settings);
    // settings = this.cleanExternalMenuItemData(settings, models, viewSettings);

    return settings;
  }

  filterMenuItemsDuplicateSeparators(menuItems: MenuItem[]) {
    return menuItems.filter((item, i) => {
      const prevItem = menuItems[i - 1];

      if (prevItem && prevItem.type == MenuItemType.Separator && item.type == MenuItemType.Separator) {
        return false;
      }

      return true;
    });
  }

  cleanMenuItemsAppMode(menuItems: MenuItem[]) {
    menuItems = this.filterMenuItemsDuplicateSeparators(menuItems);

    return menuItems;
  }

  createPageMenuItem(page?: ViewSettings): SimpleMenuItem {
    const item = new SimpleMenuItem();

    item.generateId();

    if (page) {
      item.title = page.name;
    }

    item.icon = 'document';

    item.action = new MenuItemAction();
    item.action.type = MenuItemActionType.Page;

    if (page) {
      item.action.pageUid = page.uid;
      item.action.pageUniqueName = page.uniqueName;
    }

    return item;
  }

  createUrlMenuItem(): SimpleMenuItem {
    const item = new SimpleMenuItem();

    item.generateId();
    item.title = 'New Link';
    item.icon = 'external_link';

    item.action = new MenuItemAction();
    item.action.type = MenuItemActionType.URL;

    return item;
  }

  createSystemMenuItem(type: MenuItemSystemActionType): SimpleMenuItem {
    const info = getMenuItemSystemActionTypeInfo(type);

    if (!info) {
      return;
    }

    const item = new SimpleMenuItem();

    item.generateId();
    item.title = info.label;
    item.icon = info.icon;

    item.action = new MenuItemAction();
    item.action.type = MenuItemActionType.System;
    item.action.systemType = type;

    return item;
  }

  // addSystemMenuItem(option: SystemOption, prepend = false) {
  //   const item = this.createSystemMenuItem(option);
  //
  //   this.selectMenuItem.emit({
  //     item: item,
  //     prepend: prepend
  //   });
  // }

  createSectionMenuItem(): SectionMenuItem {
    const item = new SectionMenuItem();

    item.generateId();
    item.title = 'New Section';

    return item;
  }

  createProjectMenuItem(layout?: MenuBlockLayout, options: Partial<ImageMenuItem> = {}): ImageMenuItem {
    const item = new ImageMenuItem();

    item.generateId();
    item.imageOption = ImageMenuItemOption.ProjectLogo;

    if (isSet(options.imageFill)) {
      item.imageFill = options.imageFill;
    }

    if (isSet(options.imageColor)) {
      item.imageColor = options.imageColor;
    }

    item.imageSize = layout == MenuBlockLayout.LeftThin ? 50 : 32;
    item.imageBorderRadius = 50;

    item.action = new MenuItemAction();
    item.action.type = MenuItemActionType.System;
    item.action.systemType = MenuItemSystemActionType.Home;

    return item;
  }

  createUserMenuItem(layout?: MenuBlockLayout): ImageMenuItem {
    const rootItem = new ImageMenuItem();

    rootItem.generateId();
    rootItem.imageOption = ImageMenuItemOption.UserPhoto;
    rootItem.imageFill = true;
    rootItem.imageSize = 30;
    rootItem.imageBorderRadius = 100;

    if (layout == MenuBlockLayout.LeftWide) {
      rootItem.titleInput = new FieldInput();
      rootItem.titleInput.path = ['value'];
      rootItem.titleInput.valueType = InputValueType.Formula;
      rootItem.titleInput.formulaValue = `CONCAT(user.first_name, " ", user.last_name)`;

      rootItem.subtitleInput = new FieldInput();
      rootItem.subtitleInput.path = ['value'];
      rootItem.subtitleInput.valueType = InputValueType.Context;
      rootItem.subtitleInput.contextValue = ['user', 'email'];
    }

    const createSeparator = () => {
      const result = new SeparatorMenuItem();
      result.generateId();
      return result;
    };

    const profileItem = new ImageMenuItem();

    profileItem.generateId();
    profileItem.imageOption = ImageMenuItemOption.UserPhoto;
    profileItem.imageFill = true;
    profileItem.imageSize = 30;
    profileItem.imageBorderRadius = 100;

    profileItem.titleInput = new FieldInput();
    profileItem.titleInput.path = ['value'];
    profileItem.titleInput.valueType = InputValueType.Formula;
    profileItem.titleInput.formulaValue = `CONCAT(user.first_name, " ", user.last_name)`;

    profileItem.subtitleInput = new FieldInput();
    profileItem.subtitleInput.path = ['value'];
    profileItem.subtitleInput.valueType = InputValueType.Context;
    profileItem.subtitleInput.contextValue = ['user', 'email'];

    profileItem.action = new MenuItemAction();
    profileItem.action.type = MenuItemActionType.System;
    profileItem.action.systemType = MenuItemSystemActionType.Profile;

    const usersItem = this.createSystemMenuItem(MenuItemSystemActionType.Users);
    const toggleThemeItem = this.createSystemMenuItem(MenuItemSystemActionType.ToggleTheme);
    const logoutItem = this.createSystemMenuItem(MenuItemSystemActionType.Logout);

    rootItem.children = [profileItem, createSeparator(), usersItem, createSeparator(), toggleThemeItem, logoutItem];

    return rootItem;
  }

  createImageMenuItem(layout?: MenuBlockLayout): ImageMenuItem {
    const item = new ImageMenuItem();

    item.generateId();
    item.imageIcon = 'image';
    item.imageColor = 'teal';
    item.imageSize = layout == MenuBlockLayout.LeftThin ? 50 : 32;
    item.imageBorderRadius = 50;

    if (layout != MenuBlockLayout.LeftThin) {
      item.titleInput = new FieldInput().deserializeFromStatic('value', 'Title');
      item.subtitleInput = new FieldInput().deserializeFromStatic('value', 'Subtitle');
    }

    return item;
  }

  createButtonMenuItem(): ButtonMenuItem {
    const item = new ButtonMenuItem();

    item.generateId();
    item.title = 'New Link';
    item.icon = 'link';

    item.action = new MenuItemAction();
    item.action.type = MenuItemActionType.URL;

    return item;
  }

  createDropdownMenuItem(): SimpleMenuItem {
    const item = new SimpleMenuItem();

    item.generateId();
    item.title = 'Dropdown';
    item.icon = 'fileds';

    const childItem = new SimpleMenuItem();

    childItem.generateId();
    childItem.title = 'New Link';
    childItem.icon = 'link';

    childItem.action = new MenuItemAction();
    childItem.action.type = MenuItemActionType.URL;

    item.children = [childItem];

    return item;
  }

  createSeparatorMenuItem(): SeparatorMenuItem {
    const item = new SeparatorMenuItem();

    item.generateId();

    return item;
  }

  createShareMenuItem(): ShareMenuItem {
    const item = new ShareMenuItem();

    item.generateId();

    return item;
  }

  createCustomMenuItem(): CustomMenuItem {
    const item = new CustomMenuItem();

    item.generateId();
    item.title = 'Custom item';

    return item;
  }

  getDefaultBlockState(): Partial<MenuBlock> {
    const pageItem = this.createPageMenuItem();

    pageItem.title = 'Page link';

    const urlLink = this.createUrlMenuItem();

    return {
      startItems: [pageItem, urlLink]
    };
  }
}
