/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../list-footer/list-footer.component.ngfactory";
import * as i2 from "../list-footer/list-footer.component";
import * as i3 from "@angular/common";
import * as i4 from "./list-store-footer.component";
var styles_ListStoreFooterComponent = [];
var RenderType_ListStoreFooterComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ListStoreFooterComponent, data: {} });
export { RenderType_ListStoreFooterComponent as RenderType_ListStoreFooterComponent };
export function View_ListStoreFooterComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "app-list-footer", [], null, [[null, "pageSelected"], [null, "reload"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("pageSelected" === en)) {
        var pd_0 = (_co.pageSelected.emit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reload" === en)) {
        var pd_1 = (_co.reload.emit() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_ListFooterComponent_0, i1.RenderType_ListFooterComponent)), i0.ɵdid(1, 114688, null, 0, i2.ListFooterComponent, [], { title: [0, "title"], pagination: [1, "pagination"], currentPage: [2, "currentPage"], hasMorePages: [3, "hasMorePages"], lastLoadedPage: [4, "lastLoadedPage"], totalPages: [5, "totalPages"], count: [6, "count"], viewId: [7, "viewId"], accentColor: [8, "accentColor"], theme: [9, "theme"] }, { pageSelected: "pageSelected", reload: "reload" }), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; var currVal_1 = _co.pagination; var currVal_2 = i0.ɵunv(_v, 1, 2, i0.ɵnov(_v, 2).transform(_co.listStore.currentPageImmediate$)); var currVal_3 = i0.ɵunv(_v, 1, 3, i0.ɵnov(_v, 3).transform(_co.listStore.hasMore$)); var currVal_4 = i0.ɵunv(_v, 1, 4, i0.ɵnov(_v, 4).transform(_co.listStore.currentPage$)); var currVal_5 = i0.ɵunv(_v, 1, 5, i0.ɵnov(_v, 5).transform(_co.listStore.totalPages$)); var currVal_6 = i0.ɵunv(_v, 1, 6, i0.ɵnov(_v, 6).transform(_co.listStore.count$)); var currVal_7 = _co.viewId; var currVal_8 = _co.accentColor; var currVal_9 = _co.theme; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }, null); }
export function View_ListStoreFooterComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-list-store-footer", [], null, null, null, View_ListStoreFooterComponent_0, RenderType_ListStoreFooterComponent)), i0.ɵdid(1, 114688, null, 0, i4.ListStoreFooterComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ListStoreFooterComponentNgFactory = i0.ɵccf("app-list-store-footer", i4.ListStoreFooterComponent, View_ListStoreFooterComponent_Host_0, { title: "title", pagination: "pagination", listStore: "listStore", viewId: "viewId", accentColor: "accentColor", theme: "theme" }, { pageSelected: "pageSelected", reload: "reload" }, []);
export { ListStoreFooterComponentNgFactory as ListStoreFooterComponentNgFactory };
