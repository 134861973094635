<app-sidebar-collapse
  [title]="(title | appIsSet) ? title : 'No title'"
  [error]="control.invalid ? 'Tab is not configured' : undefined"
  [block]="true"
  [context]="collapseContext"
  [openedInitial]="openedInitial"
  [arrow]="true"
  [drag]="true"
  [padding]="false"
>
  <ng-container right>
    <a
      *ngIf="removeEnabled"
      href="javascript:void(0)"
      class="sidebar-collapse__action icon-bin"
      (click)="$event.stopPropagation(); remove.emit()"
    >
    </a>
  </ng-container>

  <div class="sidebar__element">
    <app-customize-bar-input-edit
      [label]="'title'"
      [required]="true"
      [itemForm]="control.controls.title"
      [context]="context"
      [contextElement]="contextElement"
      [focusedInitial]="openedInitial"
      [fill]="true"
      [analyticsSource]="analyticsSource + '_title'"
    ></app-customize-bar-input-edit>
  </div>

  <div class="sidebar__element">
    <app-sidebar-field [label]="'icon'">
      <app-auto-field
        [form]="control"
        [label]="false"
        [field]="
          createField({
            name: 'icon',
            label: 'Icon',
            field: 'IconField',
            params: { classes: ['select_fill'], allow_empty: true }
          })
        "
      ></app-auto-field>
    </app-sidebar-field>
  </div>

  <div class="sidebar__element">
    <app-sidebar-field
      [label]="'Conditional Visibility'"
      [documentation]="'conditional-visibility'"
      [documentationLabel]="'See how it works'"
      [documentationSource]="analyticsSource + '_visible'"
    >
      <app-input-edit
        [itemForm]="control.controls.visible_input"
        [context]="context"
        [contextElement]="contextElement"
        [staticValueDisabled]="true"
        [placeholder]="'Value'"
        [fill]="true"
        [analyticsSource]="analyticsSource + '_visible'"
      ></app-input-edit>

      <ng-container description>
        Example: elements.field.value >= 10
      </ng-container>
    </app-sidebar-field>
  </div>

  <div class="sidebar__element">
    <app-sidebar-field [label]="'tooltip'">
      <app-auto-field
        [form]="control"
        [label]="false"
        [field]="
          createField({
            name: 'tooltip',
            field: 'CharField',
            placeholder: 'Show information for users',
            reset_enabled: true,
            params: { classes: ['input_fill'] }
          })
        "
      ></app-auto-field>
    </app-sidebar-field>
  </div>
</app-sidebar-collapse>
