var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Injector } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import pickBy from 'lodash/pickBy';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import { ProjectSettingsStore } from '@modules/all-project-settings';
import { TextStyle } from '@modules/customize';
import { textStyleGlobals } from '@modules/customize-shared';
import { FontStyle, fontTypeOptions, getFontFamilyVariable, TextDecoration, TextTransform } from '@modules/styles';
import { fonts, headingFontName, regularFontName } from '@modules/theme';
import { controlValue, isSet } from '@shared';
var TextStyleControl = /** @class */ (function (_super) {
    __extends(TextStyleControl, _super);
    function TextStyleControl(projectSettingsStore, options) {
        if (options === void 0) { options = {}; }
        var _this = _super.call(this, {
            font_family: new FormControl(),
            font_type: new FormControl({}),
            font_size: new FormControl(),
            color: new FormControl(),
            color_dark: new FormControl(),
            background_color: new FormControl(),
            background_color_dark: new FormControl(),
            letter_spacing: new FormControl(),
            transform: new FormControl(TextTransform.None),
            decoration: new FormControl(TextDecoration.None)
        }) || this;
        _this.projectSettingsStore = projectSettingsStore;
        _this.options = options;
        _this.instanceSet = false;
        _this.transformOptions = [
            {
                value: TextTransform.None,
                icon: 'close',
                name: '',
                subtitle: 'No transform'
            },
            {
                value: TextTransform.Uppercase,
                icon: 'uppercase',
                name: '',
                subtitle: 'Uppercase'
            },
            {
                value: TextTransform.Lowercase,
                icon: 'lowercase',
                name: '',
                subtitle: 'Lowercase'
            },
            {
                value: TextTransform.Capitalize,
                icon: 'capitalize',
                name: '',
                subtitle: 'Capitalize'
            }
        ];
        _this.decorationOptions = [
            {
                value: TextDecoration.None,
                icon: 'close',
                name: '',
                subtitle: 'No decoration'
            },
            {
                value: TextDecoration.Underline,
                icon: 'underline',
                name: '',
                subtitle: 'Underline'
            },
            {
                value: TextDecoration.Strikethrough,
                icon: 'strikethrough',
                name: '',
                subtitle: 'Strikethrough'
            }
        ];
        _this.defaultValues = {
            fontFamily: regularFontName,
            fontWeight: 400,
            fontStyle: FontStyle.Normal,
            fontSize: 14,
            color: '#768191',
            colorDark: '#768191',
            transform: TextTransform.None,
            decoration: TextDecoration.None
        };
        _this.styleDefault$ = new BehaviorSubject(undefined);
        _this.fontTypeEquals = function (lhs, rhs) {
            if (lhs && rhs) {
                return lhs.weight == rhs.weight && lhs.style == rhs.style;
            }
            else {
                return lhs == rhs;
            }
        };
        combineLatest(_this.projectSettingsStore.getAllSettings$(), options.globalParams ? options.globalParams : of({}))
            .pipe(untilDestroyed(_this))
            .subscribe(function (_a) {
            var settings = _a[0], globalParams = _a[1];
            var baseDefaults = {
                fontFamily: regularFontName,
                fontWeight: 400,
                fontStyle: FontStyle.Normal,
                fontSize: 14,
                color: '#768191',
                colorDark: '#768191'
            };
            var textStyleGlobal = isSet(_this.options.global)
                ? textStyleGlobals.find(function (item) { return item.name == _this.options.global; })
                : undefined;
            var defaults = __assign({}, baseDefaults, (textStyleGlobal &&
                pickBy(textStyleGlobal.defaults(__assign({ settings: settings }, globalParams)), function (v) { return isSet(v); })), (_this.options.defaults && pickBy(_this.options.defaults, function (v) { return isSet(v); }))
            // ...(settings && pickBy(settings[options.globalSetting] as TextStyle, v => isSet(v)))
            );
            _this.styleDefault$.next(new TextStyle(defaults));
            if (!_this.instanceSet) {
                _this.deserialize(_this.instance);
            }
        });
        return _this;
    }
    TextStyleControl.inject = function (injector, options) {
        if (options === void 0) { options = {}; }
        return Injector.create({
            providers: [
                {
                    provide: TextStyleControl,
                    useFactory: function (projectSettingsStore) {
                        return new TextStyleControl(projectSettingsStore, options);
                    },
                    deps: [ProjectSettingsStore]
                }
            ],
            parent: injector
        }).get(TextStyleControl);
    };
    TextStyleControl.prototype.ngOnDestroy = function () { };
    TextStyleControl.prototype.deserialize = function (instance) {
        var _this = this;
        this.instance = instance;
        this.instanceSet = false;
        if (this.deserializeSubscription) {
            this.deserializeSubscription.unsubscribe();
            this.deserializeSubscription = undefined;
        }
        this.deserializeSubscription = this.getStyleDefault$()
            .pipe(first(), untilDestroyed(this))
            .subscribe(function (styleDefault) {
            var style = instance || styleDefault;
            _this.controls.font_family.patchValue(style ? style.fontFamily : undefined);
            _this.controls.font_type.patchValue(style && isSet(style.fontWeight) ? { weight: style.fontWeight, style: style.fontStyle } : undefined);
            _this.controls.font_size.patchValue(style ? style.fontSize : undefined);
            _this.controls.color.patchValue(style ? style.color : undefined);
            _this.controls.color_dark.patchValue(style ? style.colorDark : undefined);
            _this.controls.background_color.patchValue(style ? style.backgroundColor : undefined);
            _this.controls.background_color_dark.patchValue(style ? style.backgroundColorDark : undefined);
            _this.controls.letter_spacing.patchValue(style ? style.letterSpacing : undefined);
            _this.controls.transform.patchValue(style ? style.transform : TextTransform.None);
            _this.controls.decoration.patchValue(style ? style.decoration : TextDecoration.None);
            _this.instanceSet = true;
        });
    };
    TextStyleControl.prototype.getFontTypeOptionsForValue = function (fontFamily, projectSettings) {
        var fontFamilyVariable = getFontFamilyVariable(fontFamily);
        if (fontFamilyVariable == regularFontName) {
            fontFamily = projectSettings.fontRegular;
        }
        else if (fontFamilyVariable == headingFontName) {
            fontFamily = projectSettings.fontHeading;
        }
        var font = fonts.find(function (item) { return item.name == fontFamily; });
        return fontTypeOptions.filter(function (item) { return !font || font.weights.includes(item.value.weight); });
    };
    TextStyleControl.prototype.getFontTypeOptions$ = function () {
        var _this = this;
        return combineLatest(controlValue(this.controls.font_family), this.projectSettingsStore.getAllSettings$()).pipe(map(function (_a) {
            var fontFamily = _a[0], projectSettings = _a[1];
            return _this.getFontTypeOptionsForValue(fontFamily, projectSettings);
        }));
    };
    TextStyleControl.prototype.getInstance = function (instance) {
        if (!instance) {
            instance = new TextStyle();
        }
        instance.fontFamily = this.controls.font_family.value;
        var fontType = this.controls.font_type.value;
        if (fontType) {
            instance.fontWeight = fontType.weight;
            instance.fontStyle = fontType.style;
        }
        else {
            instance.fontWeight = undefined;
            instance.fontStyle = undefined;
        }
        instance.fontSize = isSet(this.controls.font_size.value) ? this.controls.font_size.value : undefined;
        instance.color = isSet(this.controls.color.value) ? this.controls.color.value : undefined;
        instance.colorDark = isSet(this.controls.color_dark.value) ? this.controls.color_dark.value : undefined;
        instance.backgroundColor = this.controls.background_color.value;
        instance.backgroundColorDark = this.controls.background_color_dark.value;
        instance.letterSpacing = this.controls.letter_spacing.value;
        instance.transform = this.controls.transform.value;
        instance.decoration = this.controls.decoration.value;
        return instance;
    };
    TextStyleControl.prototype.getStyleDefault = function () {
        return this.styleDefault$.value;
    };
    TextStyleControl.prototype.getStyleDefault$ = function (skipFirst) {
        if (skipFirst === void 0) { skipFirst = true; }
        if (skipFirst) {
            return this.styleDefault$.pipe(filter(function (style) { return !!style; }));
        }
        else {
            return this.styleDefault$.asObservable();
        }
    };
    TextStyleControl.prototype.isDefaultStyle = function () {
        var style = this.serialize();
        var defaultStyle = this.getStyleDefault();
        if (!style) {
            return true;
        }
        if (!defaultStyle) {
            return false;
        }
        return style.equals(defaultStyle);
    };
    TextStyleControl.prototype.isDefaultStyle$ = function () {
        return combineLatest(this.serialize$(), this.getStyleDefault$()).pipe(map(function (_a) {
            var style = _a[0], defaultStyle = _a[1];
            if (!style) {
                return true;
            }
            if (!defaultStyle) {
                return false;
            }
            return style.equals(defaultStyle);
        }));
    };
    TextStyleControl.prototype.isSet = function () {
        return !this.isDefaultStyle();
    };
    TextStyleControl.prototype.isSet$ = function () {
        return this.isDefaultStyle$().pipe(map(function (isDefaultStyle) { return !isDefaultStyle; }));
    };
    TextStyleControl.prototype.resetDefaults = function () {
        this.deserialize(undefined);
    };
    TextStyleControl.prototype.serialize = function (reuseInstance, onlyChanges) {
        if (reuseInstance === void 0) { reuseInstance = true; }
        if (onlyChanges === void 0) { onlyChanges = false; }
        if (!this.instanceSet) {
            return this.instance;
        }
        var style = this.getInstance(reuseInstance ? this.instance : undefined);
        var defaultStyle = this.getStyleDefault();
        if (!defaultStyle) {
            return style;
        }
        var diff = style.getDiff(defaultStyle);
        if (!diff) {
            return;
        }
        if (onlyChanges) {
            Object.assign(style, {
                fontFamily: undefined,
                fontWeight: undefined,
                fontStyle: undefined,
                fontSize: undefined,
                color: undefined,
                colorDark: undefined,
                backgroundColor: undefined,
                backgroundColorDark: undefined,
                letterSpacing: undefined,
                transform: undefined,
                decoration: undefined
            }, diff);
        }
        return style;
    };
    TextStyleControl.prototype.serialize$ = function () {
        var _this = this;
        return controlValue(this, { debounce: 200 }).pipe(map(function () { return _this.serialize(); }));
    };
    return TextStyleControl;
}(FormGroup));
export { TextStyleControl };
