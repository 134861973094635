<app-text-style-edit-overlay
  [control]="control"
  [origin]="origin"
  [openedInitial]="added"
  [popoverPositions]="popoverPositions"
  [resetEnabled]="false"
  #text_style_edit
></app-text-style-edit-overlay>

<div
  class="sidebar-list-item sidebar-list-item_compact sidebar-list-item_clickable"
  [class.sidebar-list-item_contrast]="contrast"
  [class.sidebar-list-item_disabled]="disabled || text_style_edit.isOpened()"
  cdkOverlayOrigin
  #origin="cdkOverlayOrigin"
>
  <div class="sidebar-list-item__left">
    <div
      class="sidebar-list-item__color sidebar-list-item__color_size_l sidebar-list-item__color_square"
      [style.background]="color"
    ></div>
  </div>

  <div class="sidebar-list-item__main">
    <ng-container *ngIf="valueStr | appIsSet">
      <div class="sidebar-list-item__title">{{ valueStr }}</div>
      <div class="sidebar-list-item__description">
        {{ subtitle }}
        <ng-container *ngIf="additional | appIsSet"> {{ additional }}</ng-container>
      </div>
    </ng-container>

    <ng-container *ngIf="!(valueStr | appIsSet)">
      <div class="sidebar-list-item__title sidebar-list-item__title_secondary">
        {{ subtitle }}
        <ng-container *ngIf="additional | appIsSet"> {{ additional }}</ng-container>
      </div>
    </ng-container>
  </div>

  <div *ngIf="removeEnabled" class="sidebar-list-item__right">
    <div
      class="sidebar-list-item__action sidebar-list-item__action_hover-only icon-bin"
      [appTip]="'Remove'"
      [appTipOptions]="{ side: 'top' }"
      (click)="$event.stopPropagation(); remove.emit()"
    ></div>
  </div>
</div>
