<div class="body__content">
  <router-outlet></router-outlet>
  <app-popups></app-popups>
  <div class="app-overlay-container cdk-overlay-container theme-enabled"></div>
  <div class="app-notifications-container theme-enabled">
    <app-notifications></app-notifications>
  </div>
  <app-page-loader></app-page-loader>
</div>
<!--<a *ngIf="chatraService.showed$ | async"-->
<!--href="javascript:void(0)"-->
<!--class="chatra__close"-->
<!--(click)="hideChatra()">-->
<!--<span class="icon icon-close"></span>-->
<!--</a>-->
