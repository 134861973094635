import { ChangeDetectorRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import isEqual from 'lodash/isEqual';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, Subject } from 'rxjs';
import { delay, first, startWith } from 'rxjs/operators';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { FieldType, getFieldDescriptionByType, InputValueType } from '@modules/fields';
import { InputFieldProvider } from '@modules/parameters';
import { SidebarCollapseContext } from '@modules/sidebar';
import { ascComparator, isSet } from '@shared';
import { InputsEditForm } from './inputs-edit.form';
var defaultSortInputs = function (lhs, rhs) {
    return -1 * ascComparator(lhs.controls.path.value, rhs.controls.path.value);
};
var ɵ0 = defaultSortInputs;
var InputsEditComponent = /** @class */ (function () {
    function InputsEditComponent(form, analyticsService, cd) {
        this.form = form;
        this.analyticsService = analyticsService;
        this.cd = cd;
        this.addInputEnabled = false;
        this.staticValueDisabled = false;
        this.sortInputs = defaultSortInputs;
        this.requiredEditable = true;
        this.userInput = false;
        this.collapse = true;
        this.collapseContext = new SidebarCollapseContext();
        this.listWrapper = true;
        this.displayValueTypes = [InputValueType.Formula];
        this.addInput = new EventEmitter();
        this.displayAllParameters = false;
        this.addItems = [];
        this.addMenuClosed = new Subject();
    }
    InputsEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.form.init(this.control, this.parameterProvider, this.fieldsControl, this.context, this.contextElement);
        combineLatest(this.parameterProvider.getItems$(), this.form.updatedFromControl.pipe(startWith(undefined)))
            .pipe(delay(0), untilDestroyed(this))
            .subscribe(function (_a) {
            var value = _a[0];
            _this.updateItems(value);
            _this.updateAddItems(value);
        });
    };
    InputsEditComponent.prototype.ngOnDestroy = function () { };
    InputsEditComponent.prototype.updateItems = function (providerItems) {
        var _this = this;
        var _a, _b;
        var displayAllParameters = providerItems.every(function (item) { return !item.children || !item.children.length; });
        var parameters = this.parameterProvider.getItemFields(providerItems);
        var items = this.form.form.controls;
        var addItems = [];
        var removeItems = [];
        if (displayAllParameters) {
            // Add inputs for all parameters if they don't exist
            parameters.forEach(function (parameter, i) {
                var defaultValueType = providerItems[i].defaultValueType;
                var existingValue = items.find(function (item) { return isEqual(item.controls.path.value, [parameter.name]); });
                var valueType;
                if (parameter.required) {
                    valueType = _this.userInput ? InputValueType.Prompt : defaultValueType;
                }
                if (existingValue) {
                    if (!existingValue.controls.value_type.value && valueType) {
                        existingValue.controls.value_type.patchValue(valueType);
                    }
                    if (existingValue.controls.required.value != parameter.required && parameter.required) {
                        existingValue.controls.required.patchValue(true);
                    }
                    return;
                }
                addItems.push(_this.form.createItem({
                    path: [parameter.name],
                    value_type: valueType,
                    required: parameter.required
                }));
            });
            // Remove inputs
            items.forEach(function (item) {
                var existingParameter = parameters.find(function (parameter) { return isEqual([parameter.name], item.controls.path.value); });
                if (!existingParameter) {
                    // Remove inputs for non existing parameters
                    removeItems.push(item);
                }
                else if (!isSet(item.controls.path.value)) {
                    // Remove no name inputs
                    removeItems.push(item);
                }
            });
        }
        else {
            // Add inputs for required parameters if they don't exist
            parameters
                .filter(function (item) { return item.required; })
                .forEach(function (parameter) {
                var providerItem = providerItems.find(function (item) { return item.field && item.field.name == parameter.name; });
                var defaultValueType = providerItem ? providerItem.defaultValueType : undefined;
                var existingValue = items.find(function (item) { return isEqual(item.controls.path.value, [parameter.name]); });
                var valueType;
                if (parameter.required) {
                    valueType = _this.userInput ? InputValueType.Prompt : defaultValueType;
                }
                if (existingValue) {
                    if (!existingValue.controls.value_type.value && valueType) {
                        existingValue.controls.value_type.patchValue(valueType);
                    }
                    if (existingValue.controls.required.value != parameter.required && parameter.required) {
                        existingValue.controls.required.patchValue(true);
                    }
                    return;
                }
                addItems.push(_this.form.createItem({
                    path: [parameter.name],
                    value_type: valueType || '',
                    required: parameter.required
                }));
            });
            // Remove inputs
            items.forEach(function (item) {
                var existingParameter = parameters.find(function (parameter) { return isEqual([parameter.name], item.controls.path.value); });
                if (!existingParameter) {
                    // Remove inputs for non existing parameters
                    removeItems.push(item);
                }
                else if (!isSet(item.controls.path.value)) {
                    // Remove no name inputs
                    removeItems.push(item);
                }
            });
        }
        if (addItems.length) {
            var controls = this.sortInputs ? addItems.sort(this.sortInputs) : addItems;
            (_a = this.form).arrayAppend.apply(_a, controls);
        }
        if (removeItems.length) {
            (_b = this.form).arrayRemove.apply(_b, removeItems);
        }
        this.displayAllParameters = displayAllParameters;
        this.cd.markForCheck();
    };
    InputsEditComponent.prototype.updateAddItems = function (providerItems) {
        var mapItem = function (item) {
            if (item.children) {
                return {
                    button: {
                        label: item.label,
                        icon: item.icon
                    },
                    children: item.children
                        .map(function (i) { return mapItem(i); })
                        .sort(function (lhs, rhs) {
                        var lhsSubtitle = lhs.subtitle ? 1 : 0;
                        var rhsSubtitle = rhs.subtitle ? 1 : 0;
                        return lhsSubtitle - rhsSubtitle;
                    })
                };
            }
            else if (item.field) {
                return {
                    option: {
                        value: item,
                        name: item.label,
                        icon: item.icon
                    },
                    subtitle: item.field.name.startsWith('exclude__') ? 'Exclude' : undefined
                };
            }
        };
        this.addItems = this.filterNotUsed(providerItems).map(function (item) { return mapItem(item); }).concat((this.addInputEnabled
            ? [
                {
                    button: {
                        name: 'add_input',
                        label: 'Create Input',
                        icon: 'plus'
                    },
                    stickyBottom: true,
                    orange: true,
                    large: true
                }
            ]
            : []));
        this.cd.markForCheck();
    };
    InputsEditComponent.prototype.trackByFn = function (i, item) {
        if (item.controls.path.value) {
            return 'field_' + JSON.stringify(item.controls.path.value);
        }
        else {
            return i;
        }
    };
    InputsEditComponent.prototype.addItem = function (item) {
        var _this = this;
        // Fix trigger focus after menu close prevents inputs-edit-item focus
        this.addMenuClosed.pipe(first(), delay(0), untilDestroyed(this)).subscribe(function () {
            _this.lastAddedForm = _this.form.addItem(item);
            _this.cd.markForCheck();
            _this.analyticsService.sendSimpleEvent(AnalyticsEvent.SetParameter.Added, {
                Name: item.field.name,
                Object: _this.analyticsSource
            });
        });
    };
    InputsEditComponent.prototype.filterNotUsed = function (items) {
        var _this = this;
        return items.filter(function (item) {
            if (!item.field) {
                return true;
            }
            if (item.field.required) {
                return false;
            }
            var value = _this.control.value;
            if (!value) {
                return true;
            }
            return !value.find(function (input) { return isEqual(input.path, [item.field.name]); });
        });
    };
    InputsEditComponent.prototype.onAddOptionClick = function (option) {
        this.addItem(option.value);
    };
    InputsEditComponent.prototype.onAddButtonClick = function (button) {
        var _this = this;
        if (button.name == 'add_input') {
            setTimeout(function () {
                _this.addInput.emit();
            }, 60);
        }
    };
    InputsEditComponent.prototype.getFieldPlaceholder = function (field) {
        var fieldDescription = getFieldDescriptionByType(field);
        if ([FieldType.Select, FieldType.MultipleSelect, FieldType.RelatedModel, FieldType.Boolean].includes(field)) {
            return 'Choose option';
        }
        else {
            return fieldDescription.label;
        }
    };
    InputsEditComponent.prototype.openAddInput = function () {
        if (this.addTrigger) {
            this.addTrigger.openMenu();
        }
    };
    return InputsEditComponent;
}());
export { InputsEditComponent };
export { ɵ0 };
