<ng-container *ngIf="itemForm.controls.path.value?.length">
  <ng-container
    *ngIf="
      (!itemForm.controls.value_type.value || itemForm.controls.value_type.value == inputValueTypes.StaticValue) &&
      !staticValueDisabledClean
    "
  >
    <div *ngIf="staticValueArray" class="input-list">
      <div *ngFor="let item of controlArray.controls; let i = index" class="input-list__item">
        <app-input-edit-static-value
          [control]="item"
          [staticValueField]="staticValueField"
          [staticValueParams]="staticValueParams"
          [context]="context"
          [contextElement]="contextElement"
          [focusedInitial]="(focusedInitial && i === 0 && !addedValueControl) || item === addedValueControl"
          [placeholder]="placeholder"
          [resetEnabled]="resetEnabled"
          [classes]="classes"
          [fill]="fill"
        ></app-input-edit-static-value>

        <div
          *ngIf="controlArray.controls.length > 1"
          class="input-list__item-close icon-close"
          (click)="removeValueControl(i)"
        ></div>
      </div>

      <div class="input-list__actions">
        <div class="input-list__link" (click)="addValueControl()">
          <span class="input-list__link-icon icon-plus"></span>
          <span class="input-list__link-label">Add value</span>
        </div>
      </div>
    </div>

    <ng-container *ngIf="!staticValueArray">
      <app-input-edit-static-value
        [control]="control"
        [staticValueField]="staticValueField"
        [staticValueParams]="staticValueParams"
        [context]="context"
        [contextElement]="contextElement"
        [focusedInitial]="focusedInitial"
        [placeholder]="placeholder"
        [resetEnabled]="resetEnabled"
        [classes]="classes"
        [fill]="fill"
      ></app-input-edit-static-value>
    </ng-container>

    <div *ngIf="displayValueTypesEnabled" class="button-tags" style="padding-top: 4px;">
      <div class="button-tags__label">or insert</div>
      <ng-container *ngFor="let item of displayValueTypes">
        <div
          *ngIf="item == inputValueTypes.Formula"
          class="button-tag button-tags__item"
          [class.button-tag_darker]="darker"
          (click)="switchToFormula()"
        >
          <span class="button-tag__icon icon-function"></span>
          <span class="button-tag__label">Formula</span>
        </div>

        <div
          *ngIf="item == inputValueTypes.Prompt"
          class="button-tag button-tags__item"
          [class.button-tag_darker]="darker"
          (click)="switchToPrompt()"
        >
          <span class="button-tag__icon icon-edit"></span>
          <span class="button-tag__label">Ask user</span>
        </div>

        <div
          *ngIf="item == inputValueTypes.Null"
          class="button-tag button-tags__item"
          [class.button-tag_darker]="darker"
          (click)="switchToNull()"
        >
          <span class="button-tag__icon icon-deselect"></span>
          <span class="button-tag__label">Null</span>
        </div>

        <div
          *ngIf="item == inputValueTypes.EmptyString"
          class="button-tag button-tags__item"
          [class.button-tag_darker]="darker"
          (click)="switchToEmptyString()"
        >
          <span class="button-tag__icon icon-delete"></span>
          <span class="button-tag__label">Empty</span>
        </div>
      </ng-container>
    </div>
  </ng-container>

  <ng-container
    *ngIf="
      itemForm.controls.value_type.value == inputValueTypes.Formula ||
      ((!itemForm.controls.value_type.value || itemForm.controls.value_type.value == inputValueTypes.StaticValue) &&
        staticValueDisabledClean)
    "
  >
    <div class="input-icon-wrapper" [class.input-icon-wrapper_fill-vertical]="fillVertical">
      <ng-container *ngIf="!isInputOverlayOpened()">
        <app-input-edit-formula-value
          [control]="control"
          [context]="context"
          [contextElement]="contextElement"
          [contextElementPath]="contextElementPath"
          [contextElementPaths]="contextElementPaths"
          [focusedInitial]="focusedInitial"
          [placeholder]="formulaPlaceholder"
          [extraSections]="extraSections"
          [fill]="fill"
          [fillVertical]="fillVertical"
          [header]="header"
          [small]="small"
          [darker]="darker"
          [resultShowOnFocus]="resultShowOnFocus"
          (popoverOpened)="onEditorOpened()"
          (switchToDefault)="switchToDefault()"
          (switchToContext)="switchToContext($event)"
          (switchToTextInputs)="switchToTextInputs()"
          (switchToJs)="switchToJs()"
          (switchToEmptyString)="switchToEmptyString()"
          (switchToNull)="switchToNull()"
          (switchToPrompt)="switchToPrompt()"
          (switchToFieldFilter)="switchToFieldFilter($event.field, $event.lookup)"
        ></app-input-edit-formula-value>
      </ng-container>

      <div
        *ngIf="isInputOverlayOpened()"
        class="input input_disabled"
        [class.input_fill]="fill"
        [class.input_fill-vertical]="fillVertical"
      ></div>

      <div
        *ngIf="!overlay"
        class="input-expand input-expand_position_bottom-right input-icon-hover_flex"
        [class.input-expand_active]="isInputOverlayOpened()"
        [class.input-icon-hover]="!isInputOverlayOpened()"
        appInputOverlay
        [appInputOverlayInput]="this"
        (appInputOverlayFinished)="onInputOverlayFinished()"
      >
        <span
          class="input-expand__icon"
          [class.icon-copy]="isInputOverlayOpened()"
          [class.icon-enlarge_expand]="!isInputOverlayOpened()"
        ></span>
        <span class="input-expand__label">Editing in popup</span>
        <span class="input-expand__close icon-close"></span>
      </div>
    </div>

    <ng-container *ngIf="!isInputOverlayOpened()">
      <div *ngIf="!staticValueDisabledClean && displayValueTypesEnabled" class="button-tags" style="padding-top: 4px;">
        <div
          class="button-tag button-tags__item button-tags__item_fill"
          [class.button-tag_darker]="darker"
          (click)="switchToDefault()"
        >
          <span class="button-tag__icon icon-function"></span>
          <span class="button-tag__label">Disable Formula</span>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="itemForm.controls.value_type.value == inputValueTypes.TextInputs">
    <div
      class="field field_content-fill-vertical input-icon-wrapper"
      [class.input-icon-wrapper_fill-vertical]="fillVertical"
    >
      <app-input-edit-text-inputs-value
        *ngIf="!isInputOverlayOpened()"
        [typeControl]="itemForm.controls.text_inputs_type"
        [valueControl]="itemForm.controls.text_inputs_value"
        [context]="context"
        [contextElement]="contextElement"
        [contextElementPath]="contextElementPath"
        [contextElementPaths]="contextElementPaths"
        [staticValueDisabled]="staticValueDisabledClean"
        [displayValueTypesEnabled]="displayValueTypesEnabled"
        [fill]="fill"
        [fillVertical]="fillVertical"
        [header]="header"
        [darker]="darker"
        [analyticsSource]="analyticsSource"
        (switchToDefault)="switchToDefault()"
        (switchToFormula)="switchToFormula()"
      ></app-input-edit-text-inputs-value>

      <div
        *ngIf="isInputOverlayOpened()"
        class="input input_disabled"
        [class.input_fill]="fill"
        [class.input_fill-vertical]="fillVertical"
      ></div>

      <div
        *ngIf="!overlay"
        class="input-expand input-expand_position_after-toolbar-right input-icon-hover_flex"
        [class.input-expand_active]="isInputOverlayOpened()"
        [class.input-icon-hover]="!isInputOverlayOpened()"
        appInputOverlay
        [appInputOverlayInput]="this"
        (appInputOverlayFinished)="onInputOverlayFinished()"
      >
        <span
          class="input-expand__icon"
          [class.icon-copy]="isInputOverlayOpened()"
          [class.icon-enlarge_expand]="!isInputOverlayOpened()"
        ></span>
        <span class="input-expand__label">Editing in popup</span>
        <span class="input-expand__close icon-close"></span>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="itemForm.controls.value_type.value == inputValueTypes.Js">
    <div
      class="field field_content-fill-vertical input-icon-wrapper"
      [class.input-icon-wrapper_fill-vertical]="fillVertical"
    >
      <ng-container *ngIf="!isInputOverlayOpened()">
        <app-input-edit-js-value
          [itemForm]="itemForm"
          [context]="context"
          [contextElement]="contextElement"
          [contextElementPath]="contextElementPath"
          [contextElementPaths]="contextElementPaths"
          [staticValueDisabled]="staticValueDisabledClean"
          [placeholder]="jsPlaceholder"
          [displayValueTypesEnabled]="displayValueTypesEnabled"
          [extraSections]="extraSections"
          [classes]="classes"
          [fill]="fill"
          [fillVertical]="fillVertical"
          [header]="header"
          [dark]="dark"
          [darker]="darker"
          [resultShowOnFocus]="resultShowOnFocus"
          (switchToDefault)="switchToDefault()"
          (switchToFormula)="switchToFormula()"
        ></app-input-edit-js-value>
      </ng-container>

      <div
        *ngIf="isInputOverlayOpened()"
        class="input input_disabled"
        [class.input_fill]="fill"
        [class.input_fill-vertical]="fillVertical"
      ></div>

      <div
        *ngIf="!overlay"
        class="input-expand input-expand_position_top-right input-icon-hover_flex"
        [class.input-expand_active]="isInputOverlayOpened()"
        [class.input-icon-hover]="!isInputOverlayOpened()"
        appInputOverlay
        [appInputOverlayInput]="this"
        (appInputOverlayFinished)="onInputOverlayFinished()"
      >
        <span
          class="input-expand__icon"
          [class.icon-copy]="isInputOverlayOpened()"
          [class.icon-enlarge_expand]="!isInputOverlayOpened()"
        ></span>
        <span class="input-expand__label">Editing in popup</span>
        <span class="input-expand__close icon-close"></span>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="itemForm.controls.value_type.value == inputValueTypes.EmptyString">
    <div class="input input_container" [class.input_fill]="fill" [ngClass]="classes">
      <a
        href="javascript:void(0)"
        class="input-tag input-tag_fill"
        [appTip]="'Choose another data'"
        (click)="switchToFormula()"
      >
        <span class="input-tag__left">
          <span class="input-tag__icon icon-delete"></span>
        </span>
        <span class="input-tag__main">Empty String</span>
        <span class="input-tag__right">
          <span class="input-tag__control input-tag__control_root icon-close"></span>
        </span>
      </a>
    </div>
  </ng-container>

  <ng-container *ngIf="itemForm.controls.value_type.value == inputValueTypes.Null">
    <div class="input input_container" [class.input_fill]="fill" [ngClass]="classes">
      <a
        href="javascript:void(0)"
        class="input-tag input-tag_fill"
        [appTip]="'Choose another data'"
        (click)="switchToFormula()"
      >
        <span class="input-tag__left">
          <span class="input-tag__icon icon-deselect"></span>
        </span>
        <span class="input-tag__main">Null</span>
        <span class="input-tag__right">
          <span class="input-tag__control input-tag__control_root icon-close"></span>
        </span>
      </a>
    </div>
  </ng-container>

  <ng-container *ngIf="itemForm.controls.value_type.value == inputValueTypes.Prompt">
    <div class="input input_container" [class.input_fill]="fill" [ngClass]="classes">
      <a
        href="javascript:void(0)"
        class="input-tag input-tag_fill"
        [appTip]="'Choose another data'"
        (click)="switchToDefault()"
      >
        <span class="input-tag__left">
          <span class="input-tag__icon icon-edit"></span>
        </span>
        <span class="input-tag__main">Ask value from user</span>
        <span class="input-tag__right">
          <span class="input-tag__control input-tag__control_root icon-close"></span>
        </span>
      </a>
    </div>
  </ng-container>

  <ng-container *ngIf="itemForm.controls.value_type.value == inputValueTypes.Context">
    <app-input-edit-context-value
      [valueControl]="itemForm.controls.context_value"
      [context]="context"
      [contextElement]="contextElement"
      [contextElementPath]="contextElementPath"
      [contextElementPaths]="contextElementPaths"
      [classes]="classes"
      [fill]="fill"
      (edit)="switchToFormula()"
      (reset)="switchToFormula(true)"
    ></app-input-edit-context-value>
  </ng-container>

  <ng-container *ngIf="itemForm.controls.value_type.value == inputValueTypes.Filter">
    <div class="input input_container" [class.input_fill]="fill" [ngClass]="classes">
      <a
        href="javascript:void(0)"
        class="input-tag input-tag_fill"
        [appTip]="'Choose another data'"
        (click)="switchToFormula()"
      >
        <span class="input-tag__main">
          <ng-container *ngIf="filterField">
            Filters <span class="icon-arrow_forward_2"></span> {{ filterField.label }}

            <ng-container *ngIf="filterFieldLookup">
              <span class="icon-arrow_forward_2"></span> {{ filterFieldLookup.label }}
            </ng-container>
          </ng-container>
        </span>
        <span class="input-tag__right">
          <span class="input-tag__control input-tag__control_root icon-close"></span>
        </span>
      </a>
    </div>
  </ng-container>
</ng-container>
