import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';

import { ElementItem, ViewContext, ViewContextElement } from '@modules/customize';
import { ModelDescription } from '@modules/models';
import { Resource } from '@modules/projects';

import { DisplayFieldArray } from '../display-fields-edit/display-field.array';
import { DisplayFieldsEditComponent } from '../display-fields-edit/display-fields-edit.component';
import { FieldActionsArray } from '../display-fields-edit/field-actions.array';

@Component({
  selector: 'app-display-fields-edit-section',
  templateUrl: './display-fields-edit-section.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DisplayFieldsEditSectionComponent implements OnInit {
  @Input() title = 'Fields';
  @Input() resetEnabled = false;
  @Input() form: DisplayFieldArray;
  @Input() fieldActionsControl: FieldActionsArray;
  @Input() resource: Resource;
  @Input() modelDescription: ModelDescription;
  @Input() itemName = 'field';
  @Input() componentName = 'component';
  @Input() element: ElementItem;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() contextElementPath: (string | number)[];
  @Input() contextElementPaths: (string | number)[][];
  @Input() visibleEditable = true;
  @Input() customViewEnabled = false;
  @Input() customViewStateSelectedEnabled = false;
  @Input() actionsLabels: {
    title?: string;
    emptyAction?: string;
    actionLabel?: string;
  };
  @Input() firstInit = false;
  @Input() analyticsSource: string;
  @Output() resetClick = new EventEmitter<void>();

  @ViewChild('fieldsComponent', { read: DisplayFieldsEditComponent }) columnsEditComponent: DisplayFieldsEditComponent;

  columnsSearch = false;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {}

  setColumnsSearch(value: boolean) {
    this.columnsSearch = value;
    this.cd.markForCheck();
  }

  toggleSearch() {
    if (this.columnsSearch) {
      this.setColumnsSearch(false);
    } else {
      this.setColumnsSearch(true);
      this.columnsEditComponent.focusSearch();
    }
  }
}
