<div class="sidebar__header2">
  <div class="sidebar-header2">
    <div class="sidebar-header2__top">
      <div class="sidebar-header2__top-main">
        <div class="sidebar-header2-button sidebar-header2__top-item" (click)="closeCustomize.emit()">
          <span class="sidebar-header2-button__icon sidebar-header2-button__icon_left icon-close"></span>
          <span class="sidebar-header2-button__label">Close</span>
        </div>
      </div>
    </div>

    <div class="sidebar-header2__title">Workflow result</div>
  </div>
</div>

<div class="sidebar__content">
  <app-sidebar-section [disabled]="!workflowEditable">
    <div *ngIf="!resultOutputs?.length" class="sidebar__element">
      <app-sidebar-field>
        <div class="sidebar-icon-buttons">
          <div
            *ngFor="let item of form.typeOptions"
            class="sidebar-icon-button"
            [class.sidebar-icon-button_active]="form.controls.type.value == item.value"
            (click)="form.controls.type.patchValue(item.value)"
          >
            <div class="sidebar-icon-button__icon" [ngClass]="['icon-' + item.icon]"></div>
            <div class="sidebar-icon-button__label">{{ item.name }}</div>
          </div>
        </div>
      </app-sidebar-field>
    </div>

    <ng-container *ngIf="resultOutputs?.length">
      <div class="sidebar__list">
        <div class="sidebar-list workflow-result-inputs">
          <app-inputs-edit
            [control]="form.controls.inputs"
            [parameterProvider]="form.inputFieldProvider"
            [context]="context"
            [contextElement]="contextElement"
            [staticValueDisabled]="true"
            [sortInputs]="undefined"
            [requiredEditable]="false"
            [listWrapper]="false"
            [classes]="'workflow-result-inputs-item'"
            [analyticsSource]="analyticsSource"
          ></app-inputs-edit>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="!resultOutputs?.length">
      <ng-container *ngIf="form.controls.type.value == workflowResultTypes.Manual">
        <div *ngIf="form.controls.type.value == workflowResultTypes.Manual" class="sidebar__element">
          <app-sidebar-field>
            <div class="sidebar-icon-buttons sidebar-icon-buttons_border">
              <div
                class="sidebar-icon-button"
                [class.sidebar-icon-button_active]="!form.controls.array.value"
                (click)="form.controls.array.setValue(false)"
              >
                <div class="sidebar-icon-button__label">single record</div>
              </div>
              <div
                class="sidebar-icon-button"
                [class.sidebar-icon-button_active]="form.controls.array.value"
                (click)="form.controls.array.setValue(true)"
              >
                <div class="sidebar-icon-button__label">list of records</div>
              </div>
            </div>
          </app-sidebar-field>
        </div>

        <div *ngIf="!form.controls.array.value" class="sidebar__list">
          <app-parameters-edit-with-inputs
            [control]="form.controls.parameters"
            [inputsControl]="form.controls.inputs"
            [parameterProvider]="form.inputFieldProvider"
            [context]="context"
            [staticValueDisabled]="true"
            [displayValueTypes]="[inputValueTypes.Formula]"
            [configurable]="{
              name: true,
              field: true,
              required: false,
              add: true,
              sortable: true
            }"
            [addLabel]="'Add Output'"
            [addBaseName]="'output'"
            [emptyLabel]="'Output'"
            [classes]="'component-action-inputs-item'"
            [analyticsSource]="analyticsSource"
          >
          </app-parameters-edit-with-inputs>
        </div>

        <div *ngIf="form.controls.array.value" class="sidebar__element">
          <app-sidebar-field>
            <app-input-edit
              [itemForm]="form.controls.array_input"
              [context]="context"
              [staticValueDisabled]="true"
              [placeholder]="'Array'"
              [fill]="true"
            ></app-input-edit>

            <ng-container description>
              Example: steps.af78.result
            </ng-container>
          </app-sidebar-field>

          <div
            *ngIf="
              (form.controls.array_input | appControlValue | async | appFieldInput)?.isSet() &&
              (form.getAutoDetectedParameters$({ context: context, contextElement: contextElement }) | async) as columns
            "
            class="sidebar__element"
            style="padding: 0;"
          >
            <ng-container *ngIf="columns.length">
              <ng-container
                *ngIf="
                  form.isParametersSame$(form.controls.parameters | appControlValue | async, columns);
                  else changed
                "
              >
                <app-alert [secondary]="true" [compact]="true" [center]="true">
                  Data has <strong>{{ columns.length }}</strong>
                  {{ columns.length | i18nPlural: { '=1': 'field', other: 'fields' } }}
                </app-alert>
              </ng-container>

              <ng-template #changed>
                <app-alert [warning]="true" [compact]="true" [center]="true">
                  New data has <strong>{{ columns.length }}</strong>
                  {{ columns.length | i18nPlural: { '=1': 'field', other: 'fields' } }} (<a
                    href="javascript:void(0)"
                    (click)="resetParameters()"
                    >Reload</a
                  >)
                </app-alert>
              </ng-template>
            </ng-container>

            <ng-container *ngIf="!columns.length">
              <app-alert [warning]="true" [compact]="true" [center]="true">
                Data has no fields
              </app-alert>
            </ng-container>
          </div>
        </div>
      </ng-container>

      <div
        *ngIf="!(form.controls.type.value == workflowResultTypes.Manual && !form.controls.array.value)"
        class="sidebar__list"
      >
        <app-sidebar-field *ngIf="outputs.length" [label]="arrayOutput ? 'array of fields' : 'fields'">
          <div class="sidebar-list">
            <div *ngFor="let item of outputs; let i = index" class="sidebar-list__item">
              <div class="sidebar-list-item">
                <div class="sidebar-list-item__left">
                  <span class="sidebar-list-item__icon" [ngClass]="['icon-' + item.fieldDescription.icon]"></span>
                </div>
                <div class="sidebar-list-item__main">
                  <div class="sidebar-list-item__title">
                    {{ item.verboseName || item.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </app-sidebar-field>

        <ng-container *ngIf="!outputs.length">
          <div class="sidebar__message">
            <ng-container *ngIf="form.controls.type.value == workflowResultTypes.Auto">
              There are no Outputs in the last step
            </ng-container>

            <ng-container *ngIf="form.controls.type.value == workflowResultTypes.Manual">
              There are no Outputs found
            </ng-container>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </app-sidebar-section>
</div>
