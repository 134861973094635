var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormControl, FormGroup } from '@angular/forms';
import range from 'lodash/range';
import { getColorHexAStr, GradientType, parseColor } from '@modules/colors';
import { Gradient, GradientStop, Point } from '@modules/customize';
import { isSet } from '@shared';
import { GradientStopArray } from './gradient-stop.array';
import { PointControl } from './point';
var GradientControl = /** @class */ (function (_super) {
    __extends(GradientControl, _super);
    function GradientControl(state) {
        if (state === void 0) { state = {}; }
        var _this = _super.call(this, {
            type: new FormControl(isSet(state.type) ? state.type : GradientType.Linear),
            from: new PointControl(isSet(state.from) ? state.from : { x: 0.5, y: 0 }),
            to: new PointControl(isSet(state.to) ? state.to : { x: 0.5, y: 1 }),
            stops: new GradientStopArray([]),
            aspect_ratio: new FormControl(1)
        }) || this;
        _this.typeOptions = [
            {
                value: GradientType.Linear,
                name: 'Linear'
            },
            {
                value: GradientType.Radial,
                name: 'Radial'
            },
            {
                value: GradientType.Angular,
                name: 'Angular'
            },
            {
                value: GradientType.Diamond,
                name: 'Diamond'
            }
        ];
        return _this;
    }
    GradientControl.prototype.deserialize = function (value, options) {
        if (options === void 0) { options = {}; }
        this.instance = value;
        this.controls.type.patchValue(value ? value.type : GradientType.Linear, { emitEvent: options.emitEvent });
        this.controls.from.deserialize(value ? value.from : new Point({ x: 0.5, y: 0 }));
        this.controls.to.deserialize(value ? value.to : new Point({ x: 0.5, y: 1 }));
        this.controls.stops.deserialize(value ? value.stops : undefined);
        this.controls.aspect_ratio.patchValue(value ? value.aspectRatio : 1, { emitEvent: options.emitEvent });
    };
    GradientControl.prototype.getInstance = function (instance) {
        if (!instance) {
            instance = new Gradient();
        }
        instance.type = this.controls.type.value;
        instance.from = this.controls.from.getInstance(instance.from);
        instance.to = this.controls.to.getInstance(instance.to);
        instance.stops = this.controls.stops.getInstance(instance.stops);
        instance.aspectRatio = this.controls.aspect_ratio.value;
        return instance;
    };
    GradientControl.prototype.isSet = function () {
        return (isSet(this.controls.type.value) &&
            isSet(this.controls.from.value) &&
            isSet(this.controls.to.value) &&
            this.controls.stops.controls.length > 0);
    };
    GradientControl.prototype.serialize = function () {
        return this.getInstance(this.instance);
    };
    GradientControl.prototype.validateStops = function (defaultColor) {
        var _this = this;
        if (this.controls.stops.controls.length < 2) {
            var clr_1 = parseColor(defaultColor, '#000');
            var controls = [
                { alpha: 1, position: 0 },
                { alpha: 0, position: 1 }
            ].map(function (item) {
                var stopColor = getColorHexAStr(clr_1.alpha(item.alpha));
                var gradientStop = new GradientStop({ color: stopColor, position: item.position });
                gradientStop.generateId();
                return _this.controls.stops.createControl(gradientStop);
            });
            this.controls.stops.setControls(controls);
        }
    };
    GradientControl.prototype.invertStops = function () {
        var _this = this;
        range(Math.floor(this.controls.stops.controls.length / 2)).forEach(function (i) {
            var startControl = _this.controls.stops.controls[i];
            var endControl = _this.controls.stops.controls[_this.controls.stops.controls.length - 1 - i];
            var startControlPosition = startControl.controls.position.value;
            var endControlPosition = endControl.controls.position.value;
            startControl.controls.position.patchValue(endControlPosition);
            endControl.controls.position.patchValue(startControlPosition);
        });
    };
    GradientControl.prototype.rotateStops = function () {
        [this.controls.from, this.controls.to].forEach(function (control) {
            var currentX = control.controls.x.value;
            var currentY = control.controls.y.value;
            control.controls.x.patchValue(1 - currentY);
            control.controls.y.patchValue(currentX);
        });
    };
    return GradientControl;
}(FormGroup));
export { GradientControl };
