import { AfterViewInit, ChangeDetectorRef, ElementRef, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import clamp from 'lodash/clamp';
import round from 'lodash/round';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, fromEvent } from 'rxjs';
import { filter } from 'rxjs/operators';
import { GradientType } from '@modules/colors';
import { ViewContext } from '@modules/customize';
import { Color, Frame, GradientStop } from '@modules/views';
import { controlValue, MouseButton, pointsDistance, radToDeg, square } from '@shared';
import { ViewEditorContext } from '../../../services/view-editor-context/view-editor.context';
import { GradientControl } from '../../controls/gradient.control';
var markCustomizeGradientMouseDownProperty = '_markCustomizeGradientMouseDownProperty';
export function markCustomizeGradientMouseDown(mouseDownEvent) {
    mouseDownEvent[markCustomizeGradientMouseDownProperty] = true;
}
export function isCustomizeGradientMouseDown(mouseDownEvent) {
    return !!mouseDownEvent[markCustomizeGradientMouseDownProperty];
}
var GradientPositionComponent = /** @class */ (function () {
    function GradientPositionComponent(editorContext, sanitizer, cd) {
        this.editorContext = editorContext;
        this.sanitizer = sanitizer;
        this.cd = cd;
        this.translate = { x: 0, y: 0 };
        this.fromX = 0;
        this.fromY = 0;
        this.length = 0;
        this.angle = 0;
        this.frame$ = new BehaviorSubject(undefined);
        this.gradientTypes = GradientType;
    }
    GradientPositionComponent.prototype.trackStopControlFn = function (i, item) {
        return item.getId() || "index_" + i;
    };
    GradientPositionComponent.prototype.ngOnInit = function () { };
    GradientPositionComponent.prototype.ngOnDestroy = function () { };
    GradientPositionComponent.prototype.ngOnChanges = function (changes) {
        if (changes.control) {
            this.initControl();
        }
        if (changes.frame) {
            this.frame$.next(this.frame);
        }
    };
    GradientPositionComponent.prototype.ngAfterViewInit = function () {
        fromEvent(this.rootElement.nativeElement, 'mousedown')
            .pipe(filter(function (e) { return e.button == MouseButton.Main; }), untilDestroyed(this))
            .subscribe(function (e) { return markCustomizeGradientMouseDown(e); });
    };
    GradientPositionComponent.prototype.initControl = function () {
        var _this = this;
        if (this.controlSubscription) {
            this.controlSubscription.unsubscribe();
            this.controlSubscription = undefined;
        }
        this.controlSubscription = combineLatest(controlValue(this.control.controls.type), controlValue(this.control.controls.from.controls.x), controlValue(this.control.controls.from.controls.y), controlValue(this.control.controls.to.controls.x), controlValue(this.control.controls.to.controls.y), this.frame$.pipe(filter(function (bounds) { return !!bounds; })))
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var type = _a[0], fromX = _a[1], fromY = _a[2], toX = _a[3], toY = _a[4], frame = _a[5];
            var deltaX = frame ? (fromX - toX) * frame.width : 0;
            var deltaY = frame ? (toY - fromY) * frame.height : 0;
            var angle = _this.getLineAngle(deltaX, deltaY);
            _this.fromX = fromX;
            _this.fromY = fromY;
            _this.length = frame ? Math.sqrt(square(deltaX) + square(deltaY)) : 0;
            _this.transform = angle ? _this.sanitizer.bypassSecurityTrustStyle("rotate(" + angle + "deg)") : undefined;
            _this.cd.markForCheck();
        });
    };
    GradientPositionComponent.prototype.getLineAngle = function (deltaX, deltaY) {
        if (deltaY != 0) {
            var angle = radToDeg(Math.atan(deltaX / deltaY));
            if (deltaX >= 0 && deltaY >= 0) {
                return 90 + angle;
            }
            else if (deltaX >= 0 && deltaY < 0) {
                return 270 + angle;
            }
            else if (deltaX < 0 && deltaY < 0) {
                return 270 + angle;
            }
            else if (deltaX < 0 && deltaY >= 0) {
                return 90 + angle;
            }
        }
        else {
            return deltaX >= 0 ? 180 : 0;
        }
    };
    GradientPositionComponent.prototype.setActiveStop = function (control) {
        this.editorContext.updateCustomizingGradient({
            activeStop: control ? control.getId() : undefined
        });
    };
    GradientPositionComponent.prototype.addStop = function (e) {
        e.stopPropagation();
        var bounds = this.boundsElement.getBoundingClientRect();
        var x = round((e.clientX - bounds.left) / bounds.width, 2);
        var y = round((e.clientY - bounds.top) / bounds.height, 2);
        var position = clamp(this.getStopPositionFromPoint(x, y), 0.01, 0.99);
        var color = new Color({ red: 255, green: 255, blue: 255 });
        var stop = new GradientStop({ position: position, color: color });
        stop.generateId();
        var control = this.control.controls.stops.appendControl(stop);
        this.setActiveStop(control);
    };
    GradientPositionComponent.prototype.getStopPositionFromPoint = function (x, y) {
        x = x * this.frame.width;
        y = y * this.frame.height;
        var from = {
            x: this.control.controls.from.controls.x.value * this.frame.width,
            y: this.control.controls.from.controls.y.value * this.frame.height
        };
        var to = {
            x: this.control.controls.to.controls.x.value * this.frame.width,
            y: this.control.controls.to.controls.y.value * this.frame.height
        };
        if (to.x - from.x == 0) {
            var startDistance = to.y >= from.y ? y - from.y : this.frame.height - y;
            var fromToDistance = Math.abs(to.y - from.y);
            var stopPosition = startDistance / fromToDistance;
            return clamp(round(stopPosition, 2), 0, 1);
        }
        else if (to.y - from.y == 0) {
            var startDistance = to.x >= from.x ? x - from.x : this.frame.width - x;
            var fromToDistance = Math.abs(to.x - from.x);
            var stopPosition = startDistance / fromToDistance;
            return clamp(round(stopPosition, 2), 0, 1);
        }
        else {
            var k = (to.y - from.y) / (to.x - from.x);
            var b = (to.x * from.y - from.x * to.y) / (to.x - from.x);
            var normalB = y + x / k;
            var position = {
                x: ((normalB - b) * k) / (square(k) + 1),
                y: (normalB * square(k) + b) / (square(k) + 1)
            };
            var fromToDistance = Math.sqrt(square(to.x - from.x) + square(to.y - from.y));
            var endDistance = Math.sqrt(square(to.x - position.x) + square(to.y - position.y));
            var startDistance = endDistance <= fromToDistance ? Math.sqrt(square(position.x - from.x) + square(position.y - from.y)) : 0;
            var stopPosition = startDistance / fromToDistance;
            return clamp(round(stopPosition, 2), 0, 1);
        }
    };
    GradientPositionComponent.prototype.getAspectRatioStopPositionFromPoint = function (x, y) {
        x = x * this.frame.width;
        y = y * this.frame.height;
        var from = {
            x: this.control.controls.from.controls.x.value * this.frame.width,
            y: this.control.controls.from.controls.y.value * this.frame.height
        };
        var to = {
            x: this.control.controls.to.controls.x.value * this.frame.width,
            y: this.control.controls.to.controls.y.value * this.frame.height
        };
        var l = pointsDistance(to.x, to.y, from.x, from.y);
        if (to.x - from.x == 0) {
            var d = Math.abs(x - from.x);
            var width = Math.abs(d) / l;
            return round(width, 2);
        }
        else if (to.y - from.y == 0) {
            var d = Math.abs(y - from.y);
            var width = Math.abs(d) / l;
            return round(width, 2);
        }
        else {
            var k = (to.y - from.y) / (to.x - from.x);
            var normalB = from.y + from.x / k;
            var pointB = y - k * x;
            var px = (k * (normalB - pointB)) / (square(k) + 1);
            var py = (normalB * square(k) + pointB) / (square(k) + 1);
            var d = pointsDistance(px, py, from.x, from.y);
            var width = Math.abs(d) / l;
            return round(width, 2);
        }
    };
    GradientPositionComponent.prototype.onStopPositionChange = function (options) {
        this.setActiveStop(options.control);
        if (options.first) {
            this.control.controls.from.patchValue({ x: options.x, y: options.y });
            options.control.controls.position.patchValue(0);
        }
        else if (options.last) {
            this.control.controls.to.patchValue({ x: options.x, y: options.y });
            options.control.controls.position.patchValue(1);
        }
        else {
            var position = clamp(this.getStopPositionFromPoint(options.x, options.y), 0.01, 0.99);
            options.control.controls.position.patchValue(position);
        }
    };
    GradientPositionComponent.prototype.onAspectRatioStopPositionChange = function (x, y) {
        var position = this.getAspectRatioStopPositionFromPoint(x, y);
        this.control.controls.aspect_ratio.patchValue(position);
    };
    return GradientPositionComponent;
}());
export { GradientPositionComponent };
