import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { DisplayField, FieldType, getFieldDescriptionByType } from '@modules/fields';
import { ModelOption, ModelOptionsSource } from '@modules/filters-components';
import { ModelDescriptionStore } from '@modules/model-queries';
import { ModelDescription } from '@modules/models';
import { CurrentEnvironmentStore } from '@modules/projects';
import { ResourceControllerService } from '@modules/resources';
import { controlValue } from '@shared';

import { ListModelDescriptionDataSourceControl } from '../model-description-data-source-edit/list-model-description-data-source';
import { OptionEnabledArray } from './option-enabled.array';
import { OptionEnabledControl } from './option-enabled.control';

interface ModelOptionItem {
  option: ModelOption;
  control: OptionEnabledControl;
  editable: boolean;
}

@Component({
  selector: 'app-option-enabled-edit',
  templateUrl: './option-enabled-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OptionEnabledEditComponent implements OnInit, OnDestroy {
  @Input() control: OptionEnabledArray;
  @Input() dataSourceControl: ListModelDescriptionDataSourceControl;
  @Input() onlyEditableAllowed = false;
  @Input() emptyMessage = 'Nothing found';

  fieldItems: ModelOptionItem[] = [];
  relationItems: ModelOptionItem[] = [];
  sections: {
    title?: string;
    items: ModelOptionItem[];
  }[] = [];

  constructor(
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private modelDescriptionStore: ModelDescriptionStore,
    private modelOptionsSource: ModelOptionsSource,
    private resourceControllerService: ResourceControllerService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    combineLatest(
      this.dataSourceControl.getModelDescription$().pipe(
        switchMap(modelDescription => {
          if (!modelDescription) {
            return of(undefined);
          }

          return this.modelOptionsSource.getOptions$(modelDescription, {
            relationsEnabled: true
          });
        })
      ),
      controlValue<DisplayField[]>(this.dataSourceControl.controls.columns),
      this.dataSourceControl.getModelDescription$(),
      this.modelDescriptionStore.get()
    )
      .pipe(untilDestroyed(this))
      .subscribe(([modelOptions, columns, modelDescription, modelDescriptions]) => {
        if (modelOptions) {
          this.fieldItems = modelOptions
            .filter(item => item.field)
            .map(modelOption => {
              let editable = true;

              if (this.onlyEditableAllowed) {
                if (modelDescription) {
                  const updateParameters = this.getUpdateParameters(modelDescription);
                  const field = modelDescription.dbField(modelOption.name);

                  editable =
                    modelDescription.updateQuery &&
                    modelDescription.updateQuery.isConfigured() &&
                    field &&
                    !!updateParameters.find(item => item.name == modelOption.name);
                } else {
                  editable = false;
                }
              }

              return {
                option: modelOption,
                control: this.control.controls.find(control => control.controls.name.value == modelOption.name),
                editable: editable
              };
            })
            .filter(item => item.control);
          this.relationItems = modelOptions
            .filter(item => item.relation)
            .map(modelOption => {
              const editable = !this.onlyEditableAllowed;

              return {
                option: modelOption,
                control: this.control.controls.find(control => control.controls.name.value == modelOption.name),
                editable: editable
              };
            })
            .filter(item => item.control);
        } else {
          this.fieldItems = columns
            .map(column => {
              const fieldDescription = getFieldDescriptionByType(column.field);
              let relatedModelDescription: ModelDescription;

              // if (column.field == FieldType.RelatedModel && this.relationsEnabled && depth < this.maxRelationsDepth) {
              if (column.field == FieldType.RelatedModel) {
                const relatedModelId = column.params ? column.params['related_model']['model'] : undefined;
                relatedModelDescription = relatedModelId
                  ? modelDescriptions.find(item => item.isSame(relatedModelId))
                  : undefined;
              }

              let editable = true;

              if (this.onlyEditableAllowed) {
                if (modelDescription) {
                  const updateParameters = this.getUpdateParameters(modelDescription);
                  const field = modelDescription.dbField(column.name);

                  editable =
                    modelDescription.updateQuery &&
                    modelDescription.updateQuery.isConfigured() &&
                    field &&
                    !!updateParameters.find(item => item.name == column.name);
                } else {
                  editable = false;
                }
              }

              const modelOption: ModelOption = {
                name: column.name,
                verboseName: column.verboseName || column.name,
                icon: fieldDescription.icon,
                field: column,
                relatedModelDescription: relatedModelDescription
              };

              return {
                option: modelOption,
                control: this.control.controls.find(control => control.controls.name.value == column.name),
                editable: editable
              };
            })
            .filter(item => item.control);
          this.relationItems = [];
        }

        this.sections = [
          {
            items: this.fieldItems
          },
          {
            title: 'relationships',
            items: this.relationItems
          }
        ].filter(item => item.items.length);
        this.cd.markForCheck();
      });
  }

  ngOnDestroy(): void {}

  getUpdateParameters(modelDescription: ModelDescription) {
    const resource = this.currentEnvironmentStore.resources.find(item => {
      return item.uniqueName == modelDescription.resource;
    });
    const controller = resource ? this.resourceControllerService.get(resource.type) : undefined;
    return controller ? controller.getUpdateParametersOrDefaults(resource, modelDescription) : [];
  }

  onClick(item: ModelOptionItem) {
    if (!item.editable) {
      return;
    }

    item.control.controls.enabled.patchValue(!item.control.controls.enabled.value);
  }
}
