import { FocusMonitor } from '@angular/cdk/a11y';
import {
  CdkConnectedOverlay,
  CdkOverlayOrigin,
  ConnectedOverlayPositionChange,
  ConnectedPosition
} from '@angular/cdk/overlay';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, fromEvent, Subscription, timer } from 'rxjs';
import { debounce, debounceTime, map, startWith, take } from 'rxjs/operators';

import { SelectSource } from '@common/select';
import { Option } from '@modules/field-components';
import { generateAlphanumeric, TypedChanges } from '@shared';

import { defaultDropdownOptionsEmptyPlaceholder } from '../dropdown-options/dropdown-options.component';

const dropdownMouseEvent = '_dropdownMouseEvent';

export function markDropdownMouseEvent(e: MouseEvent, id: string) {
  e[dropdownMouseEvent] = id;
}

export function isDropdownMouseEvent(e: MouseEvent, id: string) {
  return e[dropdownMouseEvent] == id;
}

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropdownComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() origin: CdkOverlayOrigin;
  @Input() openOnFocus: HTMLElement;
  @Input() closeOnMouseDown: (e: MouseEvent) => boolean;
  @Input() source: SelectSource;
  @Input() options: Option[] = [];
  @Input() selectedValues: string[] = [];
  @Input() displaySelected = false;
  @Input() compareWith: (o1: any, o2: any) => boolean;
  @Input() emptyPlaceholder: string = defaultDropdownOptionsEmptyPlaceholder();
  @Input() searchEnabled = false;
  @Input() searchAutofocus = true;
  @Input() searchExternalControl: FormControl;
  @Input() searchDebounce = 0;
  @Input() addValueEnabled = false;
  @Input() backdrop = true;
  @Input() closeOnSelect = false;
  @Input() theme = false;
  @Output() valueSelected = new EventEmitter<Option>();
  @Output() addValue = new EventEmitter<any>();

  @ViewChild(CdkConnectedOverlay) overlay: CdkConnectedOverlay;
  @ViewChild('overlay_element') overlayElement: ElementRef;

  id = generateAlphanumeric(8, { letterFirst: true });
  dropdownOpened = false;
  dropdownPositions: ConnectedPosition[] = [
    {
      panelClass: ['overlay_position_bottom-left'],
      originX: 'start',
      overlayX: 'start',
      originY: 'bottom',
      overlayY: 'top',
      offsetX: 0,
      offsetY: 0
    },
    {
      panelClass: ['overlay_position_top-left'],
      originX: 'start',
      overlayX: 'start',
      originY: 'top',
      overlayY: 'bottom',
      offsetX: 0,
      offsetY: 0
    }
  ];
  dropdownPositionsSubscription: Subscription;

  constructor(private focusMonitor: FocusMonitor, private cd: ChangeDetectorRef, private zone: NgZone) {}

  ngOnInit() {
    this.initDropdown();
  }

  ngOnDestroy() {}

  ngOnChanges(changes: TypedChanges<DropdownComponent>): void {}

  ngAfterViewInit(): void {
    this.setPositionObserver();
  }

  initDropdown() {
    combineLatest(
      this.focusMonitor.monitor(this.openOnFocus).pipe(map(origin => !!origin)),
      fromEvent<MouseEvent>(window, 'mousedown').pipe(
        map(e => {
          if (isDropdownMouseEvent(e, this.id)) {
            return true;
          } else if (this.closeOnMouseDown) {
            return !this.closeOnMouseDown(e);
          } else {
            return false;
          }
        }),
        startWith(false)
      )
    )
      .pipe(
        debounceTime(10),
        map(([focused, preserveOpen]) => focused || preserveOpen),
        debounce(opened => timer(opened ? 0 : 10)),
        untilDestroyed(this)
      )
      .subscribe(opened => {
        if (!this.dropdownOpened && opened) {
          this.open();
        } else if (this.dropdownOpened && !opened) {
          this.close();
        }
      });
  }

  setDropdownOpened(value: boolean) {
    this.dropdownOpened = value;
    this.cd.markForCheck();
  }

  open() {
    this.setDropdownOpened(true);
  }

  close() {
    this.setDropdownOpened(false);
  }

  onSelected(option: Option) {
    this.valueSelected.emit(option);

    if (this.closeOnSelect) {
      this.setDropdownOpened(false);
    }
  }

  updatePosition() {
    if (this.overlay && this.overlay.overlayRef) {
      this.overlay.overlayRef.updatePosition();
    }
  }

  updatePositionOnStable() {
    this.zone.onStable
      .pipe(take(1))
      .pipe(untilDestroyed(this))
      .subscribe(() => this.updatePosition());
  }

  setPositionObserver() {
    if (this.dropdownPositionsSubscription) {
      this.dropdownPositionsSubscription.unsubscribe();
    }

    if (!this.overlay) {
      return;
    }

    this.dropdownPositionsSubscription = this.overlay.positionChange
      .pipe(untilDestroyed(this))
      .subscribe((e: ConnectedOverlayPositionChange) => {
        const propsEqual = ['offsetX', 'offsetY', 'originX', 'originY', 'overlayX', 'overlayY'];
        const position = this.dropdownPositions.find(item =>
          propsEqual.every(prop => (item[prop] || undefined) == e.connectionPair[prop])
        );
        const otherPosition = this.dropdownPositions.filter(item => item !== position);

        if (position) {
          this.overlay.overlayRef.addPanelClass(position.panelClass);
        }

        otherPosition.forEach(item => this.overlay.overlayRef.removePanelClass(item.panelClass));
      });
  }

  onContentChanged() {
    this.updatePosition();
  }

  markDropdownMouseEvent(e: MouseEvent) {
    markDropdownMouseEvent(e, this.id);
  }
}
