import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { interval, Subscription } from 'rxjs';

import { LocalStorage } from '@core';
import { TintStyle } from '@modules/actions';

@Component({
  selector: 'app-refresh-data',
  templateUrl: './refresh-data.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RefreshDataComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() viewId: string;
  @Input() accentColor: string;
  @Input() theme = false;
  @Output() updateData = new EventEmitter<void>();

  intervalSec = 10;
  runAutomatically = false;
  timerSubscription: Subscription;
  tintStyles = TintStyle;

  constructor(private storageService: LocalStorage, private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.runAutomatically = this.getSavedRunAutomatically();
    this.cd.markForCheck();

    if (this.runAutomatically) {
      this.startInterval();
    }
  }

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {}

  get storageKey() {
    return ['auto_refresh', this.viewId].join('_');
  }

  getSavedRunAutomatically(): boolean {
    if (!this.viewId) {
      return false;
    }

    return this.storageService.get(this.storageKey) == '1';
  }

  saveRunAutomatically(value: boolean) {
    if (!this.viewId) {
      return;
    }

    this.storageService.set(this.storageKey, value ? '1' : '0');
  }

  toggleRepeatUpdateData() {
    this.runAutomatically = !this.runAutomatically;
    this.cd.markForCheck();

    this.saveRunAutomatically(this.runAutomatically);

    if (this.runAutomatically) {
      this.startInterval();
    } else {
      this.stopInterval();
    }
  }

  startInterval() {
    this.timerSubscription = interval(this.intervalSec * 1000)
      .pipe(untilDestroyed(this))
      .subscribe(() => this.updateData.next());
  }

  stopInterval() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
      this.timerSubscription = undefined;
    }
  }
}
