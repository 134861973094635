export interface ColorSchema {
  backgroundColor?: string;
  backgroundColorDark?: string;
  backgroundColor2?: string;
  backgroundColor2Dark?: string;
  backgroundColor3?: string;
  backgroundColor3Dark?: string;
  backgroundColor4?: string;
  backgroundColor4Dark?: string;
  backgroundColor5?: string;
  backgroundColor5Dark?: string;
  textColor?: string;
  textColorDark?: string;
  textColor2?: string;
  textColor2Dark?: string;
  textColor3?: string;
  textColor3Dark?: string;
  borderColor?: string;
  borderColorDark?: string;
  borderColor2?: string;
  borderColor2Dark?: string;
  borderColor3?: string;
  borderColor3Dark?: string;
  maxWidth?: string;
  paddingTop?: number;
  paddingRight?: number;
  paddingBottom?: number;
  paddingLeft?: number;
}

export const colorSchemaDefaults: ColorSchema = {
  backgroundColor: '#F3F5F8',
  backgroundColorDark: '#1D2329',
  backgroundColor2: '#FFFFFF',
  backgroundColor2Dark: '#343C43',
  backgroundColor3: '#F3F5F8',
  backgroundColor3Dark: '#2C343A',
  backgroundColor4: '#E1E6EA',
  backgroundColor4Dark: '#2C343A',
  backgroundColor5: '#E1E6EA',
  backgroundColor5Dark: '#1D2329',
  textColor: '#1D2329',
  textColorDark: '#FFFFFF',
  textColor2: '#768191',
  textColor2Dark: '#9CA5AF',
  textColor3: '#BAC3D0',
  textColor3Dark: '#5F6B78',
  borderColor: '#E1E6EF',
  borderColorDark: '#3A4249',
  borderColor2: '#ECEFF5',
  borderColor2Dark: '#414B53',
  borderColor3: '#C3C6CC',
  borderColor3Dark: '#676D71'
};
