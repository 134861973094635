import { CdkConnectedOverlay } from '@angular/cdk/overlay';
import { AfterViewInit, ChangeDetectorRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';
import { Border, BorderSettingsControl } from '@modules/customize';
import { createFormFieldFactory, NumberFieldType } from '@modules/fields';
import { ThemeService } from '@modules/theme';
import { firstSet, isSet } from '@shared';
import { stylesEditPopoverPositions } from '../styles-edit/styles-edit-popover-positions';
var Side;
(function (Side) {
    Side["Top"] = "top";
    Side["Right"] = "right";
    Side["Bottom"] = "bottom";
    Side["Left"] = "left";
})(Side || (Side = {}));
var StylesEditBorderComponent = /** @class */ (function () {
    function StylesEditBorderComponent(themeService, sanitizer, cd) {
        this.themeService = themeService;
        this.sanitizer = sanitizer;
        this.cd = cd;
        this.subtitle = 'Border';
        this.contrast = false;
        this.disabled = false;
        this.added = false;
        this.removeEnabled = true;
        this.remove = new EventEmitter();
        this.createField = createFormFieldFactory();
        this.dropdownOpened = false;
        this.side = Side.Top;
        this.sides = Side;
        this.popoverPositions = stylesEditPopoverPositions;
        this.numberFieldTypes = NumberFieldType;
    }
    StylesEditBorderComponent.prototype.ngOnInit = function () {
        var _this = this;
        var defaultBorder = new Border();
        combineLatest(this.control.serialize$(), this.themeService.isDarkTheme$)
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var borderSettings = _a[0], isDarkTheme = _a[1];
            if (borderSettings && borderSettings.isSidesSet()) {
                _this.color = undefined;
                _this.colors = [
                    borderSettings.borderTop,
                    borderSettings.borderRight,
                    borderSettings.borderBottom,
                    borderSettings.borderLeft
                ].map(function (item) {
                    return firstSet(item.cssColor(isDarkTheme), defaultBorder.cssColor(isDarkTheme));
                });
                _this.valueStr = 'Mixed value';
            }
            else if (borderSettings && borderSettings.border) {
                var color = firstSet(borderSettings.border.cssColor(isDarkTheme), defaultBorder.cssColor(isDarkTheme));
                var thickness = firstSet(borderSettings.border.thickness, defaultBorder.thickness, 0);
                var style = firstSet(borderSettings.border.style, defaultBorder.style);
                _this.color = isSet(color) ? _this.sanitizer.bypassSecurityTrustStyle(color) : undefined;
                _this.colors = undefined;
                _this.valueStr = thickness + "px " + style;
            }
            else {
                _this.color = undefined;
                _this.colors = undefined;
                _this.valueStr = undefined;
            }
            _this.cd.markForCheck();
        });
        if (this.added) {
            this.setDropdownOpened(true);
        }
    };
    StylesEditBorderComponent.prototype.ngOnDestroy = function () { };
    StylesEditBorderComponent.prototype.ngAfterViewInit = function () {
        this.setPositionObserver();
    };
    StylesEditBorderComponent.prototype.setDropdownOpened = function (value) {
        this.dropdownOpened = value;
        this.cd.markForCheck();
    };
    StylesEditBorderComponent.prototype.setSide = function (value) {
        this.side = value;
        this.cd.markForCheck();
    };
    StylesEditBorderComponent.prototype.setPositionObserver = function () {
        var _this = this;
        if (this.popoverPositionsSubscription) {
            this.popoverPositionsSubscription.unsubscribe();
        }
        if (!this.cdkConnectedOverlay) {
            return;
        }
        this.popoverPositionsSubscription = this.cdkConnectedOverlay.positionChange
            .pipe(untilDestroyed(this))
            .subscribe(function (e) {
            var propsEqual = ['offsetX', 'offsetY', 'originX', 'originY', 'overlayX', 'overlayY'];
            var position = _this.popoverPositions.find(function (item) {
                return propsEqual.every(function (prop) { return (item[prop] || undefined) == e.connectionPair[prop]; });
            });
            var otherPosition = _this.popoverPositions.filter(function (item) { return item !== position; });
            if (position) {
                _this.cdkConnectedOverlay.overlayRef.addPanelClass(position.panelClass);
            }
            otherPosition.forEach(function (item) { return _this.cdkConnectedOverlay.overlayRef.removePanelClass(item.panelClass); });
        });
    };
    StylesEditBorderComponent.prototype.getBorderControl = function () {
        if (this.control.controls.per_sides.value) {
            if (this.side == Side.Right) {
                return this.control.controls.border_right;
            }
            else if (this.side == Side.Bottom) {
                return this.control.controls.border_bottom;
            }
            else if (this.side == Side.Left) {
                return this.control.controls.border_left;
            }
            else {
                return this.control.controls.border_top;
            }
        }
        else {
            return this.control.controls.border;
        }
    };
    return StylesEditBorderComponent;
}());
export { StylesEditBorderComponent };
