<div class="refresh dropdown-anchor">
  <button
    type="button"
    class="button button_icon button_transparent button_small refresh__button"
    [class.button_theme]="theme"
    [appButtonTintColor]="runAutomatically ? accentColor : undefined"
    [appButtonTintColorStyle]="tintStyles.Transparent"
    [class.button_primary-transparent]="runAutomatically && !accentColor"
    (click)="updateData.next()"
  >
    <span class="button__icon icon-repeat refresh__button-icon"></span>
  </button>

  <div
    *ngIf="viewId"
    class="dropdown dropdown_background dropdown_clip dropdown_position_top-left dropdown_icons dropdown refresh__dropdown"
    [class.dropdown_theme]="theme"
  >
    <div class="dropdown__inner refresh__dropdown-inner">
      <div class="dropdown__item dropdown__item_no-wrap" (click)="toggleRepeatUpdateData()">
        <span class="dropdown__item-icon dropdown__item-icon_flexible" style="margin-top: -2px;">
          <span class="toggle" [class.toggle_active]="runAutomatically" [class.toggle_theme]="theme">
            <span class="toggle__track"></span>
            <span class="toggle__knob"></span>
          </span>
        </span>
        <span class="dropdown__item-label">{{ 'Refresh automatically' | localize }}</span>
      </div>
    </div>
  </div>
</div>
