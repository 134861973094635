import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import isArray from 'lodash/isArray';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, publishLast, refCount, tap, timeout } from 'rxjs/operators';

import { ActionDescription, ActionResponse, ActionType, QueryAction } from '@modules/actions';
import { ServerRequestError } from '@modules/api';
import { AggregateFunc, DataGroup, DatasetGroupLookup } from '@modules/charts';
import { ParameterField } from '@modules/fields';
import { MessageName, MessageService } from '@modules/messages';
import { ModelDescription, PAGE_PARAM } from '@modules/models';
import { ProjectApiService } from '@modules/project-api';
import { Resource, ResourceTypeItem } from '@modules/projects';
import { ActionQuery, QueryType } from '@modules/queries';
import { isSet } from '@shared';

import { ModelResponse } from '../../data/model-response';
import { ActionExecuteParams, ResourceController } from '../../data/resource-controller';

@Injectable()
export class MessagesAPIResourceController extends ResourceController {
  private http: HttpClient;
  private messageService: MessageService;
  private apiService: ProjectApiService;

  init() {
    this.http = this.initService<HttpClient>(HttpClient);
    this.messageService = this.initService<MessageService>(MessageService);
    this.apiService = this.initService<ProjectApiService>(ProjectApiService);
  }

  supportedQueryTypes(resource: ResourceTypeItem, queryClass: any): QueryType[] {
    return [QueryType.Simple];
  }

  checkResource(typeItem: ResourceTypeItem, params: Object): Observable<boolean> {
    return this.http.get(params['url']).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse && error.status) {
          return of(true);
        }

        return throwError(
          new ServerRequestError({
            non_field_errors: undefined,
            url: ['Messages API is not reachable. Is it running? If so, you might have a CORS configuration issue.']
          })
        );
      }),
      publishLast(),
      refCount()
    );
  }

  modelDescriptionGet(resource: Resource, draft = false): Observable<ModelDescription[]> {
    return this.messageService.send(resource, MessageName.GetCustomModelDescriptions).pipe(
      timeout(5000),
      map(result => {
        if (!result.json || !isArray(result.json)) {
          return [];
        }

        return result.json.map(item => {
          const instance = new ModelDescription().deserialize(item);
          instance.resource = resource.uniqueName;
          return instance;
        });
      }),
      catchError(() => of([])),
      publishLast(),
      refCount()
    );
  }

  modelGet(
    resource: Resource,
    modelDescription: ModelDescription,
    params?: {},
    body?: {}
  ): Observable<ModelResponse.GetResponse> {
    params = params || {};

    const page = params[PAGE_PARAM] || 1;
    const messagesParams = {
      model: modelDescription.model,
      action: 'list',
      params: params
    };

    return this.messageService.send(resource, MessageName.CustomModel, messagesParams).pipe(
      map(result => {
        if (!result.json) {
          return [];
        }

        return result.json;
      }),
      map(result => {
        let data = result as Object;

        if (data['results'] === undefined) {
          data = {
            results: data as Object[]
          };
        } else if (isSet(data['num_pages'])) {
          data['has_more'] = page < data['num_pages'];
        }

        return this.createGetResponse().deserialize(data, modelDescription.model, modelDescription);
      }),
      tap(response => {
        response.results.forEach(item => {
          item.deserializeAttributes(modelDescription.dbFields);
        });
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  modelGroup(
    resource: Resource,
    modelDescription: ModelDescription,
    xColumns: { xColumn: string; xLookup?: DatasetGroupLookup }[],
    yFunc: AggregateFunc,
    yColumn: string,
    params?: {}
  ): Observable<DataGroup[]> {
    params = {
      ...(params || {}),
      _y_func: yFunc,
      _y_column: yColumn
    };

    if (xColumns[0]) {
      params['_x_column'] = xColumns[0].xColumn;
      params['_x_lookup'] = xColumns[0].xLookup;
    }

    const messagesParams = {
      model: modelDescription.model,
      action: 'group',
      params: params
    };

    return this.messageService.send(resource, MessageName.CustomModel, messagesParams).pipe(
      map(result => {
        if (!result.json) {
          return [];
        }

        return result.json;
      }),
      map(result => (result as Object[]).map(item => new DataGroup().deserialize(item))),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  actionDescriptionGet(resource: Resource): Observable<ActionDescription[]> {
    return this.messageService.send(resource, MessageName.GetActionList).pipe(
      timeout(5000),
      map(result => {
        if (!result.json || !isArray(result.json)) {
          return;
        }

        return result.json.map(item => {
          const action = new ActionDescription().deserialize(item);

          action.resource = resource.uniqueName;
          action.type = ActionType.Query;
          action.queryAction = new QueryAction();
          action.queryAction.query = new ActionQuery();
          action.queryAction.query.queryType = QueryType.Simple;

          return action;
        });
      }),
      publishLast(),
      refCount()
    );
  }

  actionExecute(
    resource: Resource,
    query: ActionQuery,
    parameters: ParameterField[] = [],
    params?: ActionExecuteParams,
    rawErrors?: boolean
  ): Observable<ActionResponse> {
    if (!query || !query.sqlQuery) {
      return of(undefined);
    }

    const data = {
      name: query.simpleQuery.name
    };

    if (params.model) {
      data['model'] = params.model;
    }

    if (params.id) {
      data['id'] = params.id;
    }

    if (params.ids) {
      data['ids'] = params.ids;
    }

    if (params.inverseIds) {
      data['inverseIds'] = params.inverseIds;
    }

    if (params.actionParams) {
      data['params'] = params.actionParams;
    }

    return this.messageService.send(resource, MessageName.ExecuteAction, data, !rawErrors).pipe(
      map(response => {
        return {
          response: response.response,
          blob: response.blob,
          json: response.json,
          text: response.text
        };
      }),
      publishLast(),
      refCount()
    );
  }
}
