import { CdkConnectedOverlay } from '@angular/cdk/overlay';
import { AfterViewInit, ChangeDetectorRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';
import { getColorHexStr } from '@modules/colors';
import { IconSettings, IconSettingsControl } from '@modules/customize';
import { createFormFieldFactory, NumberFieldType } from '@modules/fields';
import { ThemeService } from '@modules/theme';
import { firstSet, isSet } from '@shared';
import { stylesEditPopoverPositions } from '../styles-edit/styles-edit-popover-positions';
var StylesEditIconComponent = /** @class */ (function () {
    function StylesEditIconComponent(themeService, sanitizer, cd) {
        this.themeService = themeService;
        this.sanitizer = sanitizer;
        this.cd = cd;
        this.subtitle = 'Icon';
        this.contrast = false;
        this.disabled = false;
        this.added = false;
        this.removeEnabled = true;
        this.remove = new EventEmitter();
        this.createField = createFormFieldFactory();
        this.dropdownOpened = false;
        this.popoverPositions = stylesEditPopoverPositions;
        this.numberFieldTypes = NumberFieldType;
    }
    StylesEditIconComponent.prototype.ngOnInit = function () {
        var _this = this;
        var defaultIcon = new IconSettings();
        combineLatest(this.control.serialize$(), this.themeService.isDarkTheme$)
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var icon = _a[0], isDarkTheme = _a[1];
            if (icon) {
                var color = firstSet(icon.cssColor(isDarkTheme), defaultIcon.cssColor(isDarkTheme));
                var size = firstSet(icon.size, defaultIcon.size);
                if (isSet(size) || isSet(color)) {
                    _this.color = isSet(color) ? _this.sanitizer.bypassSecurityTrustStyle(color) : undefined;
                    _this.valueStr = [isSet(size) ? size + "px" : undefined, isSet(color) ? getColorHexStr(color) : undefined]
                        .filter(function (item) { return isSet(item); })
                        .join(' - ');
                }
                else {
                    _this.color = undefined;
                    _this.valueStr = undefined;
                }
            }
            else {
                _this.color = undefined;
                _this.valueStr = undefined;
            }
            _this.cd.markForCheck();
        });
        if (this.added) {
            this.setDropdownOpened(true);
        }
    };
    StylesEditIconComponent.prototype.ngOnDestroy = function () { };
    StylesEditIconComponent.prototype.ngAfterViewInit = function () {
        this.setPositionObserver();
    };
    StylesEditIconComponent.prototype.setDropdownOpened = function (value) {
        this.dropdownOpened = value;
        this.cd.markForCheck();
    };
    StylesEditIconComponent.prototype.setPositionObserver = function () {
        var _this = this;
        if (this.popoverPositionsSubscription) {
            this.popoverPositionsSubscription.unsubscribe();
        }
        if (!this.cdkConnectedOverlay) {
            return;
        }
        this.popoverPositionsSubscription = this.cdkConnectedOverlay.positionChange
            .pipe(untilDestroyed(this))
            .subscribe(function (e) {
            var propsEqual = ['offsetX', 'offsetY', 'originX', 'originY', 'overlayX', 'overlayY'];
            var position = _this.popoverPositions.find(function (item) {
                return propsEqual.every(function (prop) { return (item[prop] || undefined) == e.connectionPair[prop]; });
            });
            var otherPosition = _this.popoverPositions.filter(function (item) { return item !== position; });
            if (position) {
                _this.cdkConnectedOverlay.overlayRef.addPanelClass(position.panelClass);
            }
            otherPosition.forEach(function (item) { return _this.cdkConnectedOverlay.overlayRef.removePanelClass(item.panelClass); });
        });
    };
    return StylesEditIconComponent;
}());
export { StylesEditIconComponent };
