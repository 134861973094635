var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ChartType, singleColorDatasetChartTypes } from '@modules/dashboard';
import { BooleanFieldStyle } from '@modules/field-components';
import { SidebarCollapseContext } from '@modules/sidebar';
import { controlValid } from '@shared';
import { CustomizeBarContext } from '../../../services/customize-bar-context/customize-bar.context';
import { CustomizeBarBaseWidgetEditComponent } from '../customize-bar-base-widget-edit/customize-bar-base-widget-edit.component';
import { ChartWidgetEditFormDatasetControl } from '../customize-bar-chart-widget-edit/chart-widget-edit-dataset.control';
import { CustomizeBarChartWidgetEditDatasetComponent } from '../customize-bar-chart-widget-edit/customize-bar-chart-widget-edit-dataset/customize-bar-chart-widget-edit-dataset.component';
import { CustomizeBarCompareValueWidgetEditDataComponent } from './customize-bar-compare-value-widget-edit-data/customize-bar-compare-value-widget-edit-data.component';
import { CustomizeBarValueWidgetEditDataComponent } from './customize-bar-value-widget-edit-data/customize-bar-value-widget-edit-data.component';
import { CustomizeBarValueWidgetEditForm } from './customize-bar-value-widget-edit.form';
var CustomizeBarValueWidgetEditComponent = /** @class */ (function (_super) {
    __extends(CustomizeBarValueWidgetEditComponent, _super);
    function CustomizeBarValueWidgetEditComponent(cd, form, customizeBarContext, analyticsService) {
        var _this = _super.call(this, form, customizeBarContext, analyticsService) || this;
        _this.cd = cd;
        _this.form = form;
        _this.collapseContext = new SidebarCollapseContext();
        _this.dataConfigured = true;
        _this.booleanFieldStyle = BooleanFieldStyle;
        return _this;
    }
    CustomizeBarValueWidgetEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.valueTextStyleIsDefault = this.form.controls.element_styles.controls.value_style.isDefaultStyle$();
        combineLatest(this.form.controls.data_source.getResource$(), this.form.controls.data_source.getModelDescription$())
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            _this.resource = result[0], _this.modelDescription = result[1];
            _this.cd.markForCheck();
        });
        controlValid(this.form.controls.data_source)
            .pipe(map(function (configured, i) {
            if (configured && i > 0) {
                _this.analyticsService.sendSimpleEvent(AnalyticsEvent.ComponentData.SuccessfullySetUp, {
                    ComponentTypeID: _this.widget.analyticsName
                });
            }
            return configured;
        }), untilDestroyed(this))
            .subscribe(function (configured) {
            _this.dataConfigured = configured;
            _this.cd.markForCheck();
        });
        this.actionsValid$ = this.form.actionsValid$();
        controlValid(this.form.controls.data_source.controls.query_resource)
            .pipe(filter(function (configured, i) { return configured && i > 0; }), untilDestroyed(this))
            .subscribe(function () {
            _this.analyticsService.sendSimpleEvent(AnalyticsEvent.ComponentData.ResourceItemSuccessfullySetUp, {
                ComponentTypeID: _this.widget.analyticsName
            });
        });
        controlValid(this.form.controls.data_source.controls.query_inputs)
            .pipe(filter(function (configured, i) { return configured && i > 0; }), untilDestroyed(this))
            .subscribe(function () {
            _this.analyticsService.sendSimpleEvent(AnalyticsEvent.ComponentData.InputsSuccessfullySetUp, {
                ComponentTypeID: _this.widget.analyticsName
            });
        });
        if (this.setupOnCreate) {
            this.editValueDataSource();
        }
    };
    CustomizeBarValueWidgetEditComponent.prototype.editValueDataSource = function (options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        this.customizeBarContext.appendSettingsComponent({
            component: CustomizeBarValueWidgetEditDataComponent,
            inputs: {
                widget: this.widget,
                control: this.form.controls.data_source,
                chartForm: this.form,
                context: this.context,
                contextElement: this.contextElement,
                addInput: options.addInput
            },
            outputs: {
                event: [
                    function () {
                        _this.customizeBarContext.popSettingsComponent();
                    }
                ]
            }
        });
    };
    CustomizeBarValueWidgetEditComponent.prototype.editCompareValueDataSource = function (options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        this.customizeBarContext.appendSettingsComponent({
            component: CustomizeBarCompareValueWidgetEditDataComponent,
            inputs: {
                widget: this.widget,
                control: this.form.controls.compare_data_source,
                nameControl: this.form.controls.compare_name,
                growthNegativeControl: this.form.controls.compare_growth_negative,
                chartForm: this.form,
                context: this.context,
                contextElement: this.contextElement,
                addInput: options.addInput
            },
            outputs: {
                event: [
                    function () {
                        _this.customizeBarContext.popSettingsComponent();
                    }
                ]
            }
        });
    };
    CustomizeBarValueWidgetEditComponent.prototype.isSingleColorDatasetChart = function () {
        return singleColorDatasetChartTypes.includes(ChartType.Line);
    };
    CustomizeBarValueWidgetEditComponent.prototype.editChartDataset = function (control, options) {
        if (options === void 0) { options = {}; }
        this.customizeBarContext.appendSettingsComponent({
            component: CustomizeBarChartWidgetEditDatasetComponent,
            inputs: {
                widget: this.widget,
                chartType: ChartType.Line,
                form: control,
                defaultTitle: "Value Chart",
                context: this.context,
                colorEnabled: true,
                labelEnabled: false,
                singleColorDataset: this.isSingleColorDatasetChart(),
                firstInit: this.firstInit,
                setupOnCreate: this.setupOnCreate,
                addInput: options.addInput
            },
            outputs: {
                delete: [function (e) { }]
            }
        });
    };
    return CustomizeBarValueWidgetEditComponent;
}(CustomizeBarBaseWidgetEditComponent));
export { CustomizeBarValueWidgetEditComponent };
