import repeat from 'lodash/repeat';

import { isSet } from '@shared';

// TODO: Refactor imports
import { Input } from '../../../../modules/fields/data/input';

import { ColorSetValue } from '../../components/text-editor/quill-color-set';
import { BlockGroup } from './common';
import { MarkdownNode } from './markdown-node';

export interface Converters {
  embed: { [k: string]: (insert: any, attributes: Object, lineNode: MarkdownNode) => MarkdownNode };
  inline: { [k: string]: (value: any) => [string, string] };
  block: { [k: string]: (value: any, blockGroup?: BlockGroup) => MarkdownNode };
}

export const defaultConverters: Converters = {
  embed: {},
  inline: {
    italic: function () {
      return ['*', '*'];
    },
    bold: function () {
      return ['**', '**'];
    },
    code: function () {
      return ['`', '`'];
    },
    underline: function () {
      return ['<ins>', '</ins>'];
    },
    strike: function () {
      return ['~~', '~~'];
    },
    link: function (value) {
      return ['[', `](${value})`];
    },
    'color-set': function (value: ColorSetValue) {
      const color = value ? value.colorDefault : 'inherit';
      return [`<span style="color: ${color}">`, `</span>`];
    }
  },
  block: {}
};

export function getQuillConverters(options: { applyContextFormula: (input: Input) => any }): Converters {
  return {
    ...defaultConverters,
    embed: {
      ...defaultConverters.embed,
      'context-formula': function (insert: any) {
        const input = new Input();

        if (insert) {
          input.deserialize(insert);
        }

        let value: any;

        try {
          value = options.applyContextFormula(input);
        } catch (e) {
          console.error(e);
        }

        const result = isSet(value) ? value : '';
        return new MarkdownNode({ text: result });
      }
    },
    block: {
      ...defaultConverters.block,
      header: function (value: number) {
        const open = repeat('#', value) + ' ';
        return new MarkdownNode({ open: open, block: true });
      },
      list: function (value: string, blockGroup?: BlockGroup) {
        if (value == 'bullet') {
          const open = `- `;
          return new MarkdownNode({ open: open, block: true });
        } else if (value == 'ordered') {
          const number = blockGroup ? blockGroup.nodes.length : 1;
          const open = `${number}. `;
          return new MarkdownNode({ open: open, block: true });
        }
      },
      align: function (value: string) {
        const open = `<p align="${value.replace(/"/g, '\\"')}">`;
        const close = `</p>`;
        return new MarkdownNode({ open: open, close: close, block: true });
      }
    }
  };
}
