import { FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Option } from '@modules/field-components';
import { controlValue, isSet } from '@shared';

import { Border, BorderStyle } from './border';

export class BorderControl extends FormGroup {
  instance: Border;

  controls: {
    color: FormControl;
    color_dark: FormControl;
    thickness: FormControl;
    style: FormControl;
  };

  styleOptions: Option<BorderStyle>[] = [
    {
      value: BorderStyle.Solid,
      name: 'Solid'
    },
    {
      value: BorderStyle.Dashed,
      name: 'Dashed'
    },
    {
      value: BorderStyle.Dotted,
      name: 'Dotted'
    },
    {
      value: BorderStyle.Double,
      name: 'Double'
    },
    {
      value: BorderStyle.Groove,
      name: 'Groove'
    },
    {
      value: BorderStyle.Ridge,
      name: 'Ridge'
    },
    {
      value: BorderStyle.Inset,
      name: 'Inset'
    },
    {
      value: BorderStyle.Outset,
      name: 'Outset'
    }
  ];

  constructor(state: Partial<Border> = {}) {
    super({
      color: new FormControl(isSet(state.color) ? state.color : undefined),
      color_dark: new FormControl(isSet(state.colorDark) ? state.colorDark : undefined),
      thickness: new FormControl(isSet(state.thickness) ? state.thickness : undefined),
      style: new FormControl(isSet(state.style) ? state.style : BorderStyle.Solid)
    });
  }

  deserialize(value?: Border, options: { emitEvent?: boolean } = {}) {
    this.instance = value;

    this.controls.color.patchValue(value ? value.color : undefined, { emitEvent: options.emitEvent });
    this.controls.color_dark.patchValue(value ? value.colorDark : undefined, { emitEvent: options.emitEvent });
    this.controls.thickness.patchValue(value ? value.thickness : undefined, { emitEvent: options.emitEvent });
    this.controls.style.patchValue((value ? value.style : undefined) || BorderStyle.Solid, {
      emitEvent: options.emitEvent
    });
  }

  isSet(): boolean {
    return (
      isSet(this.controls.color.value) || isSet(this.controls.color_dark.value) || isSet(this.controls.thickness.value)
    );
  }

  resetDefaults(options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    this.controls.color.patchValue('#2B50ED', options);
    this.controls.color_dark.patchValue('#2B50ED', options);
    this.controls.thickness.patchValue(1, options);
    this.controls.style.patchValue(BorderStyle.Solid, options);
  }

  reset(options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    this.controls.color.reset(undefined, options);
    this.controls.color_dark.reset(undefined, options);
    this.controls.thickness.reset(undefined, options);
    this.controls.style.reset(BorderStyle.Solid, options);
  }

  getInstance(instance?: Border): Border {
    if (!instance) {
      instance = new Border();
    }

    instance.color = isSet(this.controls.color.value) ? this.controls.color.value : undefined;
    instance.colorDark = isSet(this.controls.color_dark.value) ? this.controls.color_dark.value : undefined;
    instance.thickness = this.controls.thickness.value || 0;
    instance.style = this.controls.style.value || BorderStyle.Solid;

    return instance;
  }

  serialize(): Border {
    return this.getInstance(this.instance);
  }

  serialize$(): Observable<Border> {
    return controlValue(this, { debounce: 200 }).pipe(map(() => this.serialize()));
  }
}
