/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../field-components/pipes/apply-param-input/apply-param-input.pipe";
import * as i2 from "@angular/common";
import * as i3 from "./unknown-widget.component";
var styles_UnknownWidgetComponent = [];
var RenderType_UnknownWidgetComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_UnknownWidgetComponent, data: {} });
export { RenderType_UnknownWidgetComponent as RenderType_UnknownWidgetComponent };
export function View_UnknownWidgetComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i1.ApplyParamInputPipe, []), (_l()(), i0.ɵeld(1, 0, null, null, 6, "div", [["class", "widget"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 4, "div", [["class", "widget__header dashboard-item__draggable handle"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 3, "div", [["class", "widget__title"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, [" ", " "])), i0.ɵppd(5, 1), i0.ɵpid(131072, i2.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵted(-1, null, [" Unknown widget\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 4, 0, i0.ɵnov(_v, 6).transform(i0.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i0.ɵnov(_v, 0), _co.widget.nameInput)))); _ck(_v, 4, 0, currVal_0); }); }
export function View_UnknownWidgetComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-unknown-widget", [], null, null, null, View_UnknownWidgetComponent_0, RenderType_UnknownWidgetComponent)), i0.ɵdid(1, 114688, null, 0, i3.UnknownWidgetComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UnknownWidgetComponentNgFactory = i0.ɵccf("app-unknown-widget", i3.UnknownWidgetComponent, View_UnknownWidgetComponent_Host_0, { dashboard: "dashboard", widget: "widget", context: "context", contextElement: "contextElement", fill: "fill", accentColor: "accentColor", theme: "theme" }, {}, []);
export { UnknownWidgetComponentNgFactory as UnknownWidgetComponentNgFactory };
