var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, ElementRef, EventEmitter, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { debounceTime, map, switchMap } from 'rxjs/operators';
import { localize } from '@common/localize';
import { SelectSource } from '@common/select';
import { controlValue, isSet } from '@shared';
export var defaultDropdownOptionsEmptyPlaceholder = function () { return localize('No options'); };
var DropdownOptionsComponent = /** @class */ (function () {
    function DropdownOptionsComponent(cd) {
        this.cd = cd;
        this.options = [];
        this.selectedValues = [];
        this.displaySelected = false;
        this.emptyPlaceholder = defaultDropdownOptionsEmptyPlaceholder();
        this.searchEnabled = false;
        this.searchAutofocus = true;
        this.searchDebounce = 0;
        this.searchMinimumLength = 1;
        this.addValueEnabled = false;
        this.theme = false;
        this.valueSelected = new EventEmitter();
        this.addValue = new EventEmitter();
        this.rootClick = new EventEmitter();
        this.source$ = new BehaviorSubject(undefined);
        this.options$ = new BehaviorSubject([]);
        this.selectedValues$ = new BehaviorSubject([]);
        this.searchControl = new FormControl('');
        this.searchQuery$ = new BehaviorSubject(undefined);
        this.displayOptions = [];
    }
    DropdownOptionsComponent.prototype.ngOnInit = function () {
        this.initOptions();
        this.initSearch();
    };
    DropdownOptionsComponent.prototype.ngOnDestroy = function () { };
    DropdownOptionsComponent.prototype.ngOnChanges = function (changes) {
        if (changes.source) {
            this.source$.next(this.source);
        }
        if (changes.options) {
            this.options$.next(this.options);
        }
        if (changes.selectedValues) {
            this.selectedValues$.next(this.selectedValues);
        }
        if (changes.emptyPlaceholder) {
            this.emptyPlaceholder = isSet(this.emptyPlaceholder)
                ? this.emptyPlaceholder
                : defaultDropdownOptionsEmptyPlaceholder();
        }
    };
    DropdownOptionsComponent.prototype.initOptions = function () {
        var _this = this;
        var options$ = combineLatest(this.source$, this.options$, this.searchQuery$).pipe(switchMap(function (_a) {
            var source = _a[0], options = _a[1], searchQuery = _a[2];
            if (isSet(searchQuery) && searchQuery.length < _this.searchMinimumLength) {
                return of([]);
            }
            if (source) {
                source.reset();
                source.search(searchQuery);
                return source.options$;
            }
            else {
                options = _this.searchOptions(options, searchQuery);
                return of(options);
            }
        }));
        combineLatest(options$, this.selectedValues$)
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var options = _a[0], selectedValues = _a[1];
            _this.displayOptions = options
                .map(function (option) {
                return __assign({}, option, { selected: selectedValues.some(function (item) { return _this.compareWith(item, option.value); }) });
            })
                .filter(function (option) { return _this.displaySelected || !option.selected; });
            _this.cd.markForCheck();
        });
    };
    DropdownOptionsComponent.prototype.initSearch = function () {
        var _this = this;
        var search$ = controlValue(this.searchControl).pipe(map(function (value) { return (isSet(value) ? String(value).trim() : undefined); }));
        var searchExternal$ = this.searchExternalControl
            ? controlValue(this.searchExternalControl).pipe(map(function (value) { return (isSet(value) ? String(value).trim() : undefined); }))
            : of(undefined);
        combineLatest(search$, searchExternal$)
            .pipe(map(function (_a) {
            var search = _a[0], searchExternal = _a[1];
            if (isSet(search)) {
                return search;
            }
            else if (isSet(searchExternal)) {
                return searchExternal;
            }
        }), debounceTime(this.searchDebounce), untilDestroyed(this))
            .subscribe(function (value) {
            var searchQuery = isSet(value) ? value : undefined;
            _this.searchQuery$.next(searchQuery);
        });
    };
    DropdownOptionsComponent.prototype.searchOptions = function (options, searchQuery) {
        if (isSet(searchQuery)) {
            return this.options.filter(function (item) {
                return isSet(item.name) && String(item.name).toLowerCase().includes(searchQuery.toLowerCase());
            });
        }
        else {
            return options;
        }
    };
    DropdownOptionsComponent.prototype.select = function (option) {
        this.valueSelected.emit(option);
    };
    DropdownOptionsComponent.prototype.onScroll = function () {
        var viewport = this.scrollable.nativeElement;
        var scrollBottom = viewport.scrollTop + viewport.offsetHeight;
        var endDistance = viewport.scrollHeight - scrollBottom;
        if (endDistance <= Math.max(viewport.offsetHeight * 0.5, 200)) {
            this.onScrollFinished();
        }
    };
    DropdownOptionsComponent.prototype.onScrollFinished = function () {
        if (this.source && this.source.isFetchAvailable()) {
            this.source.loadMore();
        }
    };
    return DropdownOptionsComponent;
}());
export { DropdownOptionsComponent };
