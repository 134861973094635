import { Injector } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { FillSettingsControl } from '@modules/colors-components';
import {
  BorderSettings,
  BorderSettingsControl,
  Corners,
  CornersControl,
  FillSettings,
  Margin,
  MarginControl,
  ViewContext
} from '@modules/customize';
import { applyBooleanInput$, Input } from '@modules/fields';
import { MenuBlock, MenuBlockLayout, MenuBlockLayouts, MenuGeneratorService } from '@modules/menu';
import { MenuPrimary, MenuSecondary } from '@modules/menu-components';
import { FieldInputControl } from '@modules/parameters';
import { controlValue, isSet } from '@shared';

import {
  CustomizeBarPagesEditMenuItemArray,
  CustomizeBarPagesEditMenuItemControl,
  getMenuItemControlChildren
} from '../customize-bar-pages-edit/customize-bar-pages-edit.form';
import { CustomizeBarPagesEditImageControl } from '../customize-bar-pages-edit/image-control';

export interface MenuBlockControlOptions {
  enabled?: boolean;
  layout?: MenuBlockLayout;
  start_items?: CustomizeBarPagesEditMenuItemControl[];
  center_items?: CustomizeBarPagesEditMenuItemControl[];
  end_items?: CustomizeBarPagesEditMenuItemControl[];
  fill_settings?: FillSettings;
  border_settings?: BorderSettings;
  width?: number;
  height?: number;
  border_radius?: Corners;
  padding?: Margin;
}

export class MenuBlockControl extends FormGroup {
  instance: MenuBlock;

  controls: {
    enabled: FormControl;
    enabled_input_enabled: FormControl;
    enabled_input: FieldInputControl;
    layout: FormControl;
    start_items: CustomizeBarPagesEditMenuItemArray;
    center_items: CustomizeBarPagesEditMenuItemArray;
    end_items: CustomizeBarPagesEditMenuItemArray;
    fill_settings: FillSettingsControl;
    border_settings: BorderSettingsControl;
    width: FormControl;
    height: FormControl;
    border_radius: CornersControl;
    padding: MarginControl;
  };

  constructor(
    private injector: Injector,
    private menuGeneratorService: MenuGeneratorService,
    state: MenuBlockControlOptions
  ) {
    super({
      enabled: new FormControl(isSet(state.enabled) ? state.enabled : true),
      enabled_input_enabled: new FormControl(false),
      enabled_input: new FieldInputControl({ path: ['value'] }),
      layout: new FormControl(isSet(state.layout) ? state.layout : ''),
      start_items: CustomizeBarPagesEditMenuItemArray.inject(
        injector,
        isSet(state.start_items) ? state.start_items : []
      ),
      center_items: CustomizeBarPagesEditMenuItemArray.inject(
        injector,
        isSet(state.center_items) ? state.center_items : []
      ),
      end_items: CustomizeBarPagesEditMenuItemArray.inject(injector, isSet(state.end_items) ? state.end_items : []),
      fill_settings: new FillSettingsControl(isSet(state.fill_settings) ? state.fill_settings : {}),
      border_settings: new BorderSettingsControl(isSet(state.border_settings) ? state.border_settings : {}),
      width: new FormControl(isSet(state.width) ? state.width : undefined),
      height: new FormControl(isSet(state.height) ? state.height : undefined),
      border_radius: new CornersControl(isSet(state.border_radius) ? state.border_radius : undefined),
      padding: new MarginControl(isSet(state.padding) ? state.padding : undefined)
    });
  }

  static inject(injector: Injector, state: MenuBlockControlOptions): MenuBlockControl {
    return Injector.create({
      providers: [
        {
          provide: MenuBlockControl,
          useFactory: (menuGeneratorService: MenuGeneratorService) => {
            return new MenuBlockControl(injector, menuGeneratorService, state);
          },
          deps: [MenuGeneratorService]
        }
      ],
      parent: injector
    }).get(MenuBlockControl);
  }

  deserialize(instance: MenuBlock) {
    this.instance = instance;

    this.controls.enabled.patchValue(instance.enabled);
    this.controls.enabled_input_enabled.patchValue(instance.enabledInput && instance.enabledInput.isSet());
    this.controls.enabled_input.patchValue(instance.enabledInput ? instance.enabledInput.serializeWithoutPath() : {});
    this.controls.layout.patchValue(instance.layout);
    this.controls.start_items.deserialize(instance.startItems);
    this.controls.center_items.deserialize(instance.centerItems);
    this.controls.end_items.deserialize(instance.endItems);
    this.controls.fill_settings.deserialize(instance.fillSettings);
    this.controls.border_settings.deserialize(instance.borderSettings);
    this.controls.width.patchValue(isSet(instance.width) ? instance.width : undefined);
    this.controls.height.patchValue(isSet(instance.height) ? instance.height : undefined);
    this.controls.border_radius.deserialize(instance.borderRadius);
    this.controls.padding.deserialize(instance.padding);

    this.markAsPristine();
  }

  getEnabled$(options: { context?: ViewContext } = {}) {
    return combineLatest(
      controlValue<boolean>(this.controls.enabled),
      controlValue<boolean>(this.controls.enabled_input_enabled),
      controlValue<Object>(this.controls.enabled_input).pipe(map(() => this.controls.enabled_input.serialize()))
    ).pipe(
      switchMap(([enabled, enabledInputEnabled, enabledInput]) => {
        if (enabledInputEnabled && enabledInput) {
          return applyBooleanInput$(enabledInput, {
            context: options.context
          });
        } else if (enabledInputEnabled && !enabledInput) {
          return of(true);
        } else {
          return of(enabled);
        }
      })
    );
  }

  getWidthEnabled$(): Observable<boolean> {
    return controlValue(this.controls.layout).pipe(map(value => MenuBlockLayouts.isVertical(value)));
  }

  getHeightEnabled$(): Observable<boolean> {
    return controlValue(this.controls.layout).pipe(map(value => MenuBlockLayouts.isHorizontal(value)));
  }

  getDefaultWidth$(): Observable<number> {
    return controlValue(this.controls.layout).pipe(
      map(value => {
        if (MenuBlockLayouts.isPrimary(value)) {
          return MenuPrimary.defaultWidth();
        } else {
          return MenuSecondary.defaultWidth();
        }
      })
    );
  }

  getDefaultHeight$(): Observable<number> {
    return controlValue(this.controls.layout).pipe(
      map(value => {
        if (MenuBlockLayouts.isPrimary(value)) {
          return MenuPrimary.defaultHeight();
        }
      })
    );
  }

  serialize(): MenuBlock {
    if (!this.instance) {
      this.instance = new MenuBlock();
      this.instance.generateUid();
    }

    const result = this.instance.clone();

    result.enabled = this.controls.enabled.value;
    result.enabledInput =
      this.controls.enabled_input_enabled.value && this.controls.enabled_input.value
        ? new Input().deserialize(this.controls.enabled_input.value)
        : undefined;
    result.layout = this.controls.layout.value;
    result.startItems = this.controls.start_items.serialize();
    result.centerItems = this.controls.center_items.serialize();
    result.endItems = this.controls.end_items.serialize();
    result.fillSettings = this.controls.fill_settings.serialize(false);
    result.borderSettings = this.controls.border_settings.serialize(false);
    result.width = this.controls.width.value;
    result.height = this.controls.height.value;
    result.borderRadius = this.controls.border_radius.serialize();
    result.padding = this.controls.padding.serialize();

    return result;
  }

  getTotalItems(): number {
    return [this.controls.start_items, this.controls.center_items, this.controls.end_items].reduce(
      (acc, item) => acc + item.controls.length,
      0
    );
  }

  getTotalItems$(): Observable<number> {
    return combineLatest(
      controlValue(this.controls.start_items),
      controlValue(this.controls.center_items),
      controlValue(this.controls.end_items)
    ).pipe(map(() => this.getTotalItems()));
  }

  getDefaultColor(): string {
    return MenuBlockLayouts.getDefaultColor(this.controls.layout.value);
  }

  getAllItems(): CustomizeBarPagesEditMenuItemControl[] {
    return [this.controls.start_items, this.controls.center_items, this.controls.end_items].reduce<
      CustomizeBarPagesEditMenuItemControl[]
    >((acc, item) => {
      item.controls.forEach(control => {
        acc.push(...getMenuItemControlChildren(control, true));
      });

      return acc;
    }, []);
  }

  setLayout(layout: MenuBlockLayout) {
    this.controls.layout.patchValue(layout);

    this.getAllItems().forEach(control => {
      if (control instanceof CustomizeBarPagesEditImageControl) {
        control.cleanValues(layout);
      }
    });
  }

  applyDefaultState() {
    const state = this.menuGeneratorService.getDefaultBlockState();

    if (state.startItems) {
      this.controls.start_items.deserialize(state.startItems);
    }

    if (state.centerItems) {
      this.controls.center_items.deserialize(state.centerItems);
    }

    if (state.endItems) {
      this.controls.end_items.deserialize(state.endItems);
    }
  }

  isVisible(): boolean {
    return this.controls.enabled.value && this.controls.layout.value;
  }
}
