import { Injector } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { FillSettingsControl } from '@modules/colors-components';
import {
  ActionElementStyles,
  BorderSettingsControl,
  CornersControl,
  IconSettingsControl,
  MarginControl,
  ShadowControl
} from '@modules/customize';
import { ActionTextStyleGlobalParams } from '@modules/customize-shared';
import { controlValue, forceObservable } from '@shared';

import { TextStyleControl } from '../text-style-edit/text-style.control';

export class ActionElementStylesControl extends FormGroup {
  controls: {
    text_style: TextStyleControl;
    icon_settings: IconSettingsControl;
    fill_settings: FillSettingsControl;
    border_settings: BorderSettingsControl;
    border_radius: CornersControl;
    shadow: ShadowControl;
    padding: MarginControl;
    margin: MarginControl;

    hover_text_style: TextStyleControl;
    hover_icon_settings: IconSettingsControl;
    hover_fill_settings: FillSettingsControl;
    hover_border_settings: BorderSettingsControl;
    hover_shadow: ShadowControl;

    active_text_style: TextStyleControl;
    active_icon_settings: IconSettingsControl;
    active_fill_settings: FillSettingsControl;
    active_border_settings: BorderSettingsControl;
    active_shadow: ShadowControl;
  };

  constructor(
    private injector: Injector,
    private options: {
      textStyleGlobalParams?: Partial<ActionTextStyleGlobalParams> | Observable<Partial<ActionTextStyleGlobalParams>>;
    } = {}
  ) {
    super({
      text_style: TextStyleControl.inject<ActionTextStyleGlobalParams>(injector, {
        global: 'buttonTextStyle',
        globalParams: forceObservable(options.textStyleGlobalParams),
        colorAlphaEnabled: true
      }),
      icon_settings: new IconSettingsControl(),
      fill_settings: new FillSettingsControl(),
      border_settings: new BorderSettingsControl(),
      border_radius: new CornersControl(),
      shadow: new ShadowControl(),
      padding: new MarginControl(),
      margin: new MarginControl(),

      hover_text_style: TextStyleControl.inject(injector, {
        global: 'hoverButtonTextStyle',
        globalParams: forceObservable(options.textStyleGlobalParams),
        colorAlphaEnabled: true
      }),
      hover_icon_settings: new IconSettingsControl(),
      hover_fill_settings: new FillSettingsControl(),
      hover_border_settings: new BorderSettingsControl(),
      hover_shadow: new ShadowControl(),

      active_text_style: TextStyleControl.inject(injector, {
        global: 'activeButtonTextStyle',
        globalParams: forceObservable(options.textStyleGlobalParams),
        colorAlphaEnabled: true
      }),
      active_icon_settings: new IconSettingsControl(),
      active_fill_settings: new FillSettingsControl(),
      active_border_settings: new BorderSettingsControl(),
      active_shadow: new ShadowControl()
    });
  }

  deserialize(instance?: ActionElementStyles) {
    this.controls.text_style.deserialize(instance ? instance.textStyle : undefined);
    this.controls.icon_settings.deserialize(instance ? instance.iconSettings : undefined);
    this.controls.fill_settings.deserialize(instance ? instance.fillSettings : undefined);
    this.controls.border_settings.deserialize(instance ? instance.borderSettings : undefined);
    this.controls.border_radius.deserialize(instance ? instance.borderRadius : undefined);
    this.controls.shadow.deserialize(instance ? instance.shadow : undefined);
    this.controls.padding.deserialize(instance ? instance.padding : undefined);
    this.controls.margin.deserialize(instance ? instance.margin : undefined);

    this.controls.hover_text_style.deserialize(instance ? instance.hoverTextStyle : undefined);
    this.controls.hover_icon_settings.deserialize(instance ? instance.hoverIconSettings : undefined);
    this.controls.hover_fill_settings.deserialize(instance ? instance.hoverFillSettings : undefined);
    this.controls.hover_border_settings.deserialize(instance ? instance.hoverBorderSettings : undefined);
    this.controls.hover_shadow.deserialize(instance ? instance.hoverShadow : undefined);

    this.controls.active_text_style.deserialize(instance ? instance.activeTextStyle : undefined);
    this.controls.active_icon_settings.deserialize(instance ? instance.activeIconSettings : undefined);
    this.controls.active_fill_settings.deserialize(instance ? instance.activeFillSettings : undefined);
    this.controls.active_border_settings.deserialize(instance ? instance.activeBorderSettings : undefined);
    this.controls.active_shadow.deserialize(instance ? instance.activeShadow : undefined);
  }

  isSet(): boolean {
    return [
      this.controls.text_style,
      this.controls.icon_settings,
      this.controls.fill_settings,
      this.controls.border_settings,
      this.controls.border_radius,
      this.controls.shadow,
      this.controls.padding,
      this.controls.margin,

      this.controls.hover_text_style,
      this.controls.hover_icon_settings,
      this.controls.hover_fill_settings,
      this.controls.hover_border_settings,
      this.controls.hover_shadow,

      this.controls.active_text_style,
      this.controls.active_icon_settings,
      this.controls.active_fill_settings,
      this.controls.active_border_settings,
      this.controls.active_shadow
    ].some(control => control.isSet());
  }

  stylesDefaultUpdated$(): Observable<void> {
    return combineLatest([
      this.controls.text_style.getStyleDefault$(),
      this.controls.hover_text_style.getStyleDefault$(),
      this.controls.active_text_style.getStyleDefault$()
    ]).pipe(map(() => undefined));
  }

  serialize(): ActionElementStyles {
    if (!this.isSet()) {
      return;
    }

    return new ActionElementStyles({
      textStyle: this.controls.text_style.serialize(false),
      iconSettings: this.controls.icon_settings.serialize(false),
      fillSettings: this.controls.fill_settings.serialize(false),
      borderSettings: this.controls.border_settings.serialize(false),
      borderRadius: this.controls.border_radius.serialize(),
      shadow: this.controls.shadow.serialize(false),
      padding: this.controls.padding.serialize(),
      margin: this.controls.margin.serialize(),

      hoverTextStyle: this.controls.hover_text_style.serialize(false),
      hoverIconSettings: this.controls.hover_icon_settings.serialize(false),
      hoverFillSettings: this.controls.hover_fill_settings.serialize(false),
      hoverBorderSettings: this.controls.hover_border_settings.serialize(false),
      hoverShadow: this.controls.hover_shadow.serialize(false),

      activeTextStyle: this.controls.active_text_style.serialize(false),
      activeIconSettings: this.controls.active_icon_settings.serialize(false),
      activeFillSettings: this.controls.active_fill_settings.serialize(false),
      activeBorderSettings: this.controls.active_border_settings.serialize(false),
      activeShadow: this.controls.active_shadow.serialize(false)
    });
  }

  serialize$(): Observable<ActionElementStyles> {
    return controlValue(this, { debounce: 200 }).pipe(map(() => this.serialize()));
  }
}
