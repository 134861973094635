import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { globalColorOptions } from '@modules/colors-components';
import { Option } from '@modules/field-components';
import { ThemeVar } from '@modules/theme-components';
import { controlValue } from '@shared';

@Component({
  selector: 'app-global-color-control',
  templateUrl: './global-color-control.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GlobalColorControlComponent implements OnInit, OnDestroy {
  @Input() control = new FormControl(undefined);
  @Input() colorDisplay = true;
  @Output() change = new EventEmitter<any>();

  currentGlobalOption: Option<ThemeVar>;
  globalOptions = globalColorOptions;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {
    controlValue(this.control)
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        this.currentGlobalOption = this.globalOptions.find(item => item.value == value);
        this.cd.markForCheck();
      });
  }

  ngOnDestroy(): void {}
}
