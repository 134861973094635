import keys from 'lodash/keys';
import pickBy from 'lodash/pickBy';

import { ChartWidget } from '@modules/dashboard';
import { isSet } from '@shared';

import { Margin } from './elements/items/base';
import { WidgetElementItem } from './elements/items/widget';
import { TextStyle } from './text-style';

export class ChartWidgetStyles {
  titleStyle?: TextStyle;
  margin?: Margin;

  constructor(options: Partial<ChartWidgetStyles> = {}) {
    Object.assign(this, options);
  }

  deserialize(data: Object): this {
    if (data['title_style']) {
      this.titleStyle = new TextStyle().deserialize(data['title_style']);
    } else {
      this.titleStyle = undefined;
    }

    if (data['margin']) {
      this.margin = data['margin'];
    } else {
      this.margin = undefined;
    }

    return this;
  }

  serialize(): Object {
    return {
      title_style: this.titleStyle ? this.titleStyle.serialize() : undefined,
      margin: this.margin
    };
  }

  apply(other: this): this {
    const properties: (keyof ChartWidgetStyles)[] = ['titleStyle', 'margin'];

    properties.forEach(property => {
      if (isSet(other[property])) {
        this[property] = other[property];
      }
    });

    return this;
  }
}

export function getChartWidgetStyles(element: WidgetElementItem, widget: ChartWidget): ChartWidgetStyles {
  const options: Partial<ChartWidgetStyles> = pickBy(
    {
      titleStyle: widget.titleStyle,
      ...(keys(element.margin).length && {
        margin: element.margin
      })
    },
    v => isSet(v)
  );

  if (!keys(options).length) {
    return;
  }

  return new ChartWidgetStyles(options);
}

export function applyChartWidgetStyles(element: WidgetElementItem, widget: ChartWidget, styles?: ChartWidgetStyles) {
  if (!styles) {
    styles = new ChartWidgetStyles();
  }

  widget.titleStyle = styles.titleStyle;
  element.margin = styles.margin;
}
