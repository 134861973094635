import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import last from 'lodash/last';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { DynamicComponentArguments } from '@common/dynamic-component';
import { isSet } from '@shared';

import { PopupService } from '../../services/popup/popup.service';
import { BasePopupComponent } from '../base-popup/base-popup.component';

@Component({
  selector: 'app-popups',
  templateUrl: './popups.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PopupsComponent implements OnInit, OnDestroy {
  items: { data: DynamicComponentArguments; popupData: DynamicComponentArguments }[] = [];
  disablePointerEvents = false;

  constructor(public popupService: PopupService, private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.popupService.items$.pipe(untilDestroyed(this)).subscribe(items => {
      this.items = items.map(item => {
        const instance = this.items.find(i => i.data == item);

        if (instance) {
          return { data: item, popupData: instance.popupData };
        }

        return {
          data: item,
          popupData: {
            component: item.popupComponent || BasePopupComponent,
            inputs: {
              data: item,
              disableClose: item.popupComponentDisableClose,
              closeWithoutConfirm: item.popupComponentCloseWithoutConfirm,
              ...(isSet(item.popupComponentCloseTitle) && { closeTitle: item.popupComponentCloseTitle }),
              ...(isSet(item.popupComponentCloseDescription) && {
                closeDescription: item.popupComponentCloseDescription
              }),
              requestClose: item.popupRequestClose,
              orange: item.popupComponentOrange,
              dark: item.popupComponentDark,
              theme: item.popupComponentTheme
            },
            outputs: {
              closed: [
                data => {
                  if (item.popupClosed) {
                    item.popupClosed(data);
                  }
                }
              ]
            }
          }
        };
      });
      if (this.items.length) {
        this.disablePointerEvents = !!last(this.items).data.disablePointerEvents;
      } else {
        this.disablePointerEvents = false;
      }
      this.cd.markForCheck();
    });
  }

  trackByFn(i, item: any) {
    return item.popupData;
  }

  ngOnDestroy(): void {}
}
