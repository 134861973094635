var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, ElementRef, EventEmitter, OnChanges, OnDestroy, OnInit } from '@angular/core';
import * as Color from 'color';
import isEqual from 'lodash/isEqual';
import isPlainObject from 'lodash/isPlainObject';
import keys from 'lodash/keys';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { LocalStorage } from '@core';
import { ServerRequestError } from '@modules/api';
import { getColorHexAStr, getColorHexStr } from '@modules/colors';
import { CustomizeService, GridSettings, ListDefaultSelection, ListGroup, ViewContext, ViewContextElement } from '@modules/customize';
import { applyBooleanInput } from '@modules/fields';
import { ColumnsModelListStore, EMPTY_OUTPUT, listItemEquals, SELECTED_ITEM_OUTPUT } from '@modules/list';
import { ModelDescription, PAGE_PARAM } from '@modules/models';
import { paramsToGetQueryOptions } from '@modules/resources';
import { View } from '@modules/views';
import { isSet } from '@shared';
import { getListStateColumns, getListStateFetch } from '../grid/grid-state';
export function getGridGroupStateVisible(state) {
    return {
        visible: state.visibleInput ? state.visibleInput.serialize() : undefined
    };
}
export function getGridGroupStateFetch(state) {
    return __assign({}, getListStateFetch(state), { groupValue: state.groupValue, page: state.page });
}
export function getGridGroupStateOpenedInitial(state) {
    return {
        openedInitial: state.openedInitial
    };
}
var GridGroupComponent = /** @class */ (function () {
    function GridGroupComponent(listStore, customizeService, localStorage, cd) {
        this.listStore = listStore;
        this.customizeService = customizeService;
        this.localStorage = localStorage;
        this.cd = cd;
        this.groupLast = false;
        this.listState = {};
        this.rowCards = 1;
        this.checkedItems = {};
        this.scrollable = false;
        this.theme = false;
        this.fetchStarted = new EventEmitter();
        this.fetchFinished = new EventEmitter();
        this.modelUpdated = new EventEmitter();
        this.updateSelected = new EventEmitter();
        this.selectToggle = new EventEmitter();
        this.group$ = new BehaviorSubject(undefined);
        this.listState$ = new BehaviorSubject({});
        this.collapsed$ = new BehaviorSubject(false);
        this.page$ = new BehaviorSubject(undefined);
        this.state = {};
        this.prevState = {};
        this.isVisible = false;
        this.loading = true;
        this.loadingItems = 4;
        this.hoverSeparator$ = new BehaviorSubject(false);
    }
    GridGroupComponent.prototype.ngOnInit = function () {
        var _this = this;
        var savedCollapsed = this.getSavedCollapsed();
        if (savedCollapsed !== undefined) {
            this.setCollapsed(savedCollapsed, false);
        }
        else if (this.group && !this.group.openedInitial) {
            this.setCollapsed(true, false);
        }
        combineLatest(this.group$, this.listState$, this.collapsed$, this.page$)
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var group = _a[0], listState = _a[1], collapsed = _a[2], page = _a[3];
            var state = __assign({}, listState, { groupValue: group ? group.value : undefined, openedInitial: group ? group.openedInitial : undefined, visibleInput: group ? group.visibleInput : undefined, collapsed: listState.groupCollapse ? collapsed : false, page: page });
            _this.prevState = _this.state;
            _this.state = state;
            _this.onStateUpdated(state);
        });
    };
    GridGroupComponent.prototype.ngOnDestroy = function () { };
    GridGroupComponent.prototype.ngOnChanges = function (changes) {
        if (changes.group) {
            this.group$.next(this.group);
        }
        if (changes.listState) {
            this.listState$.next(this.listState);
        }
    };
    GridGroupComponent.prototype.getItems = function () {
        return this.items;
    };
    GridGroupComponent.prototype.setItems = function (value) {
        this.items = value;
        this.cd.markForCheck();
    };
    GridGroupComponent.prototype.getCount = function () {
        return this.listStore.count;
    };
    GridGroupComponent.prototype.onStateUpdated = function (state) {
        if (!isEqual(getGridGroupStateVisible(state), getGridGroupStateVisible(this.prevState))) {
            this.initVisibleObserver(state);
        }
        var fetchedUpdated = !isEqual(getGridGroupStateFetch(state), getGridGroupStateFetch(this.prevState));
        var fetchedUpdatedAndOpened = fetchedUpdated && !state.collapsed;
        var openedUpdatedAndNotFetched = this.prevState.collapsed && !state.collapsed && !this.listStore.items;
        if (fetchedUpdated && !fetchedUpdatedAndOpened) {
            this.listStore.reset();
        }
        if (fetchedUpdatedAndOpened || openedUpdatedAndNotFetched) {
            this.fetch(state);
        }
        else {
            if (!isEqual(getListStateColumns(state), getListStateColumns(this.prevState))) {
                if (this.listStore.dataSource) {
                    this.listStore.dataSource.columns = state.dataSource ? state.dataSource.columns : [];
                    this.listStore.deserializeModelAttributes();
                }
            }
        }
        if (!isEqual(getGridGroupStateOpenedInitial(state), getGridGroupStateOpenedInitial(this.prevState)) &&
            keys(this.prevState).length) {
            this.setCollapsed(!state.openedInitial);
        }
    };
    GridGroupComponent.prototype.initVisibleObserver = function (state) {
        var _this = this;
        if (this.isVisibleSubscription) {
            this.isVisibleSubscription.unsubscribe();
        }
        if (!state.visibleInput) {
            this.isVisible = true;
            this.cd.markForCheck();
            return;
        }
        this.isVisible = applyBooleanInput(state.visibleInput, this.context);
        this.cd.markForCheck();
        this.isVisibleSubscription = this.context.outputValues$
            .pipe(debounceTime(10), distinctUntilChanged(), untilDestroyed(this))
            .subscribe(function () {
            _this.isVisible = applyBooleanInput(state.visibleInput, _this.context);
            _this.cd.markForCheck();
        });
    };
    GridGroupComponent.prototype.getStateQueryOptions = function (state, extraParams) {
        if (extraParams === void 0) { extraParams = {}; }
        var _a;
        var page = state.page || 1;
        var queryOptions = paramsToGetQueryOptions(__assign({}, state.dataSourceParams, (_a = {}, _a[PAGE_PARAM] = page, _a), extraParams));
        queryOptions.filters = (queryOptions.filters ? queryOptions.filters : []).concat((state.filters ? state.filters : []));
        queryOptions.search = state.search;
        queryOptions.sort = state.sort;
        return queryOptions;
    };
    GridGroupComponent.prototype.getBaseStateQueryOptions = function () {
        return this.getStateQueryOptions(this.state);
    };
    GridGroupComponent.prototype.fetch = function (state) {
        var _this = this;
        var _a;
        if (!state.dataSource || !state.dataSource.isConfigured()) {
            return;
        }
        if (this.fetchSubscription) {
            this.fetchSubscription.unsubscribe();
            this.fetchSubscription = undefined;
        }
        this.items = undefined;
        this.loading = true;
        this.error = undefined;
        this.heightBeforeLoading = this.bodyElement
            ? this.bodyElement.nativeElement.getBoundingClientRect().height
            : undefined;
        this.cd.markForCheck();
        this.fetchStarted.emit();
        if (this.settings.defaultSelection && !this.selectedItem) {
            this.contextElement.patchOutputValueMeta(SELECTED_ITEM_OUTPUT, { loading: true });
        }
        if (state.inputsLoading) {
            this.listStore.reset();
            return;
        }
        this.listStore.dataSource = state.dataSource;
        this.listStore.useDataSourceColumns = true;
        this.listStore.staticData = state.dataSourceStaticData;
        this.listStore.queryOptions = this.getStateQueryOptions(state, __assign({}, (state.groupValue !== undefined && (_a = {}, _a[this.settings.groupField] = state.groupValue, _a))));
        this.listStore.context = this.context;
        this.listStore.contextElement = this.contextElement;
        this.listStore.perPage = state.settings && state.settings.perPage ? state.settings.perPage : 10;
        if (this.items && this.items.length) {
            this.loadingItems = this.items.length;
        }
        this.listStore.reset(state.page);
        this.items = [];
        this.cd.markForCheck();
        this.fetchSubscription = this.listStore
            .getNext()
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            _this.loading = false;
            _this.items = result;
            _this.cd.markForCheck();
            if (_this.settings.defaultSelection && !_this.selectedItem) {
                _this.contextElement.patchOutputValueMeta(SELECTED_ITEM_OUTPUT, { loading: false });
                var page = state.page || 1;
                if (_this.settings.defaultSelection == ListDefaultSelection.First && page == 1 && _this.groupIndex == 0) {
                    _this.updateSelected.emit(_this.items ? _this.items[0] : undefined);
                }
            }
            _this.fetchFinished.emit(_this.items);
        }, function (error) {
            if (error instanceof ServerRequestError && error.errors.length) {
                _this.error = error.errors[0];
            }
            else if (isPlainObject(error)) {
                _this.error = JSON.stringify(error);
            }
            else if (error.hasOwnProperty('message')) {
                console.error(error);
                _this.error = error.message;
            }
            else {
                console.error(error);
                _this.error = error;
            }
            _this.loading = false;
            _this.cd.markForCheck();
            _this.contextElement.setOutputValue(EMPTY_OUTPUT, false, { loading: false, error: true });
            if (_this.settings.defaultSelection && !_this.selectedItem) {
                _this.contextElement.patchOutputValueMeta(SELECTED_ITEM_OUTPUT, { loading: false });
            }
        });
    };
    GridGroupComponent.prototype.reloadData = function () {
        this.fetch(this.state);
    };
    GridGroupComponent.prototype.isItemSelected = function (item, index) {
        if (this.settings.multipleSelection) {
            return this.isItemChecked(item, index);
        }
        else {
            return this.itemEquals(this.selectedItem, item);
        }
    };
    GridGroupComponent.prototype.isItemChecked = function (item, index) {
        var pk = item.model.primaryKey || "" + index;
        return this.checkedItems[pk];
    };
    GridGroupComponent.prototype.itemEquals = function (lhs, rhs) {
        return listItemEquals(lhs, rhs);
    };
    GridGroupComponent.prototype.trackByFn = function (i, item) {
        return item.model.primaryKey || i;
    };
    GridGroupComponent.prototype.setCollapsed = function (value, preserve) {
        if (preserve === void 0) { preserve = true; }
        this.collapsed$.next(value);
        this.cd.markForCheck();
        if (preserve) {
            this.saveCollapsed(value);
        }
    };
    GridGroupComponent.prototype.toggleCollapsed = function () {
        if (!this.settings.groupCollapse) {
            return;
        }
        this.setCollapsed(!this.collapsed$.value);
    };
    GridGroupComponent.prototype.getCollapsedKey = function () {
        if (!this.group) {
            return;
        }
        return ['table_group_collapsed', this.viewId, this.group.uid].join('_');
    };
    GridGroupComponent.prototype.getSavedCollapsed = function () {
        var key = this.getCollapsedKey();
        var dataStr = this.localStorage.get(key);
        if (!isSet(dataStr)) {
            return;
        }
        return dataStr == '1';
    };
    GridGroupComponent.prototype.saveCollapsed = function (value) {
        var key = this.getCollapsedKey();
        this.localStorage.set(key, value ? '1' : '0');
    };
    GridGroupComponent.prototype.backgroundCustomColor = function (color) {
        var colorHex = getColorHexStr(color);
        try {
            var clr = Color(colorHex).alpha(0.1);
            return getColorHexAStr(clr);
        }
        catch (e) {
            return null;
        }
    };
    return GridGroupComponent;
}());
export { GridGroupComponent };
