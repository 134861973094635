import {
  AbstractControl,
  AbstractControlOptions,
  AsyncValidatorFn,
  FormControl,
  FormGroup,
  ValidatorFn
} from '@angular/forms';
import isEqual from 'lodash/isEqual';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Input, isInputSet, MarkupType } from '@modules/fields';
import { controlValue } from '@shared';

import { singleTokenFormulaToContextValue } from '../utils/formula';

export function FieldInputRequiredValidator(control: FieldInputControl) {
  if (!isInputSet(control.value)) {
    return { required: true };
  }
}

export interface FieldInputControls {
  path: FormControl;
  lookup: FormControl;
  exclude: FormControl;
  value_type: FormControl;
  static_value: FormControl;
  context_value: FormControl;
  filter_field: FormControl;
  filter_lookup: FormControl;
  formula_value: FormControl;
  text_inputs_type: FormControl;
  text_inputs_value: FormControl;
  js_value: FormControl;
  required: FormControl;
}

export type FieldInputControlsValue = Partial<Record<keyof FieldInputControls, any>>;

export class FieldInputControl extends FormGroup {
  instance: Input;

  controls: FieldInputControls & { [key: string]: AbstractControl };

  constructor(
    formState: FieldInputControlsValue = {},
    validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null
  ) {
    const controls = {
      path: new FormControl([]),
      lookup: new FormControl(undefined),
      exclude: new FormControl(false),
      value_type: new FormControl(null),
      static_value: new FormControl(''),
      context_value: new FormControl(undefined),
      filter_field: new FormControl(''),
      filter_lookup: new FormControl(''),
      formula_value: new FormControl(''),
      text_inputs_type: new FormControl(MarkupType.Text),
      text_inputs_value: new FormControl(''),
      js_value: new FormControl(''),
      required: new FormControl(false)
    };
    super(controls, validatorOrOpts, asyncValidator);
    formState = this.cleanValue(formState);
    this.patchValue(formState, { emitEvent: false });
  }

  deserialize(item: Input) {
    this.instance = item;

    this.controls.path.patchValue(item.path);
    this.controls.lookup.patchValue(item.lookup);
    this.controls.exclude.patchValue(item.exclude);
    this.controls.value_type.patchValue(item.valueType);
    this.controls.static_value.patchValue(item.staticValue);
    this.controls.context_value.patchValue(item.contextValue);
    this.controls.filter_field.patchValue(item.filterField);
    this.controls.filter_lookup.patchValue(item.filterLookup);
    this.controls.formula_value.patchValue(item.formulaValue);
    this.controls.text_inputs_type.patchValue(item.textInputsType);
    this.controls.text_inputs_value.patchValue(item.textInputsValue);
    this.controls.js_value.patchValue(item.jsValue);
    this.controls.required.patchValue(item.required);

    this.markAsPristine();
  }

  serialize(): Input {
    const result = new Input();

    result.path = this.controls.path.value;
    result.lookup = this.controls.lookup.value;
    result.exclude = this.controls.exclude.value;
    result.valueType = this.controls.value_type.value;
    result.staticValue = this.controls.static_value.value;
    result.contextValue = this.controls.context_value.value;
    result.filterField = this.controls.filter_field.value;
    result.filterLookup = this.controls.filter_lookup.value;
    result.formulaValue = this.controls.formula_value.value;
    result.textInputsType = this.controls.text_inputs_type.value;
    result.textInputsValue = this.controls.text_inputs_value.value;
    result.jsValue = this.controls.js_value.value;
    result.required = this.controls.required.value;

    return result;
  }

  patchValue(value: { [p: string]: any }, options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    value = this.cleanValue(value);
    super.patchValue(value, options);
  }

  setValue(value: { [p: string]: any }, options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    value = this.cleanValue(value);
    super.setValue(value, options);
  }

  cleanValue(value: { [p: string]: any }) {
    if (!value) {
      return value;
    }

    // Backward compatibility
    if (typeof value['context_value'] === 'string') {
      value = {
        ...value,
        context_value: singleTokenFormulaToContextValue(value['context_value'])
      };
    }

    return value;
  }

  clearValue(value: Object = {}) {
    this.patchValue({
      path: [],
      value_type: null,
      static_value: '',
      context_value: [],
      filter_field: '',
      filter_lookup: '',
      formula_value: '',
      text_inputs_type: MarkupType.Text,
      text_inputs_value: undefined,
      js_value: '',
      required: false,
      ...value
    });
  }

  isSet$(): Observable<boolean> {
    return controlValue(this).pipe(map(value => isInputSet(value)));
  }

  getInstance$(): Observable<Input> {
    return controlValue(this).pipe(map(() => this.serialize()));
  }

  isEqual(options: { path: string[]; lookup?: string; exclude?: boolean }) {
    return (
      isEqual(this.controls.path.value, options.path) &&
      this.controls.lookup.value == options.lookup &&
      !!this.controls.exclude.value == !!options.exclude
    );
  }

  isName(name: string) {
    return this.isEqual({ path: [name] });
  }

  isPath(path: string[]) {
    return this.isEqual({ path: path });
  }
}
