import pickBy from 'lodash/pickBy';
import { ActionItem, ViewSettingsAction } from '@modules/actions';
import { ListModelDescriptionDataSource } from '@modules/data-sources';
import { Input, ParameterField } from '@modules/fields';
import { ListLayoutType } from '@modules/layouts';
import { ListModelDescriptionQuery } from '@modules/queries';
import { ViewMapping } from '@modules/views';
import { isSet } from '@shared';
import { migrateModelDescriptionDataSource } from '../utils/migration';
import { ListGroup } from './list-group';
import { TextStyle } from './text-style';
var GridSettings = /** @class */ (function () {
    function GridSettings() {
        this.type = ListLayoutType.Grid;
        this.header = true;
        this.cardCustomViewMappings = [];
        this.actions = [];
        this.modelActions = [];
        this.columnActions = [];
        this.customActions = [];
        this.searchEnabled = true;
        this.searchLive = true;
        this.searchParameters = [];
        this.searchInputs = [];
        this.sortingAsc = true;
        this.displayFilters = true;
        this.filterFields = [];
        this.displayFooter = true;
        this.groupCollapse = true;
        this.groupCounter = true;
        this.groups = [];
        this.perPage = 8;
        this.multipleSelection = false;
    }
    GridSettings.prototype.deserialize = function (data) {
        this.cardCustomView = data['card_custom_view'];
        this.searchEnabled = data['search_enabled'];
        this.searchResource = data['search_resource'];
        this.sortingField = data['sorting_field'];
        this.groupField = data['group_field'];
        this.defaultSelection = data['default_selection'];
        this.tooltip = data['tooltip'];
        this.gapHorizontal = data['gap_horizontal'];
        this.gapVertical = data['gap_vertical'];
        if (data['title_input']) {
            this.titleInput = new Input().deserialize(data['title_input']);
        }
        else if (data['title']) {
            // Backward compatibility
            this.titleInput = new Input().deserializeFromStatic('value', data['title']);
        }
        if (data['title_style']) {
            this.titleStyle = new TextStyle().deserialize(data['title_style']);
        }
        else {
            this.titleStyle = undefined;
        }
        if (data['data_source']) {
            this.dataSource = new ListModelDescriptionDataSource().deserialize(data['data_source']);
        }
        else if (data['resource']) {
            // Backward compatibility
            this.dataSource = migrateModelDescriptionDataSource(ListModelDescriptionDataSource, ListModelDescriptionQuery, {
                resource: data['resource'],
                query: data['get_query'],
                parameters: data['parameters'],
                inputs: data['inputs'],
                columns: data['columns']
            });
        }
        if (data['card_custom_view_mappings']) {
            this.cardCustomViewMappings = data['card_custom_view_mappings'].map(function (item) { return new ViewMapping().deserialize(item); });
        }
        if (data['display_filters'] !== undefined) {
            this.displayFilters = data['display_filters'];
        }
        if (data['filter_fields']) {
            this.filterFields = data['filter_fields'].map(function (item) {
                return {
                    name: item['name']
                };
            });
        }
        if (data['display_footer'] !== undefined) {
            this.displayFooter = data['display_footer'];
        }
        if (data['header'] != undefined) {
            this.header = data['header'];
        }
        if (data['search_query']) {
            this.searchQuery = new ListModelDescriptionQuery().deserialize(data['search_query']);
        }
        if (data['search_live'] !== undefined) {
            this.searchLive = data['search_live'];
        }
        if (data['search_parameters']) {
            this.searchParameters = data['search_parameters'].map(function (item) { return new ParameterField().deserialize(item); });
        }
        if (data['search_inputs']) {
            this.searchInputs = data['search_inputs'].map(function (item) { return new Input().deserialize(item); });
        }
        if (data['card_click_action']) {
            this.cardClickAction = new ActionItem().deserialize(data['card_click_action']);
        }
        if (data['actions']) {
            this.actions = data['actions'].map(function (item) { return new ViewSettingsAction().deserialize(item); });
        }
        if (data['model_actions']) {
            this.modelActions = data['model_actions'].map(function (item) { return new ViewSettingsAction().deserialize(item); });
        }
        if (data['column_actions']) {
            this.columnActions = data['column_actions']
                .filter(function (item) { return item['actions']; })
                .map(function (item) {
                return {
                    name: item['name'],
                    actions: item['actions'].map(function (subItem) { return new ViewSettingsAction().deserialize(subItem); })
                };
            });
        }
        else if (data['data_source'] && data['data_source']['columns']) {
            // Backward compatibility
            this.columnActions = data['data_source']['columns']
                .filter(function (item) { return item['action']; })
                .map(function (item) {
                return {
                    name: item['name'],
                    actions: [new ViewSettingsAction().deserialize(item['action'])]
                };
            });
        }
        if (data['custom_actions']) {
            this.customActions = data['custom_actions'].map(function (item) { return ({
                name: item['name'],
                action: new ActionItem().deserialize(item['action'])
            }); });
        }
        if (data['sorting_asc'] != undefined) {
            this.sortingAsc = data['sorting_asc'];
        }
        if (data['group_title_style']) {
            this.groupTitleStyle = new TextStyle().deserialize(data['group_title_style']);
        }
        else {
            this.groupTitleStyle = undefined;
        }
        if (data['group_collapse'] != undefined) {
            this.groupCollapse = data['group_collapse'];
        }
        if (data['group_counter'] != undefined) {
            this.groupCounter = data['group_counter'];
        }
        if (data['groups']) {
            this.groups = data['groups'].map(function (item) { return new ListGroup().deserialize(item); });
        }
        if (data['per_page'] != undefined) {
            this.perPage = data['per_page'];
        }
        if (data['cards_per_row'] != undefined) {
            this.cardsPerRow = data['cards_per_row'];
        }
        if (isSet(data['multiple_selection'])) {
            this.multipleSelection = data['multiple_selection'];
        }
        return this;
    };
    GridSettings.prototype.serialize = function (fields) {
        var data = {
            type: this.type,
            header: this.header,
            title_input: this.titleInput ? this.titleInput.serialize() : null,
            title_style: this.titleStyle ? this.titleStyle.serialize() : undefined,
            data_source: this.dataSource ? this.dataSource.serialize() : undefined,
            card_custom_view: this.cardCustomView,
            card_custom_view_mappings: this.cardCustomViewMappings.map(function (item) { return item.serialize(); }),
            search_enabled: this.searchEnabled,
            search_resource: this.searchResource,
            search_query: this.searchQuery ? this.searchQuery.serialize() : undefined,
            search_live: this.searchLive,
            search_parameters: this.searchParameters.map(function (item) { return item.serialize(); }),
            search_inputs: this.searchInputs.map(function (item) { return item.serialize(); }),
            card_click_action: this.cardClickAction ? this.cardClickAction.serialize() : undefined,
            actions: this.actions.map(function (item) { return item.serialize(); }),
            model_actions: this.modelActions.map(function (item) { return item.serialize(); }),
            column_actions: this.columnActions.map(function (item) {
                return {
                    name: item.name,
                    actions: item.actions.map(function (action) { return action.serialize(); })
                };
            }),
            custom_actions: this.customActions.map(function (item) { return ({ name: item.name, action: item.action.serialize() }); }),
            sorting_field: this.sortingField,
            sorting_asc: this.sortingAsc,
            per_page: this.perPage,
            cards_per_row: this.cardsPerRow,
            multiple_selection: this.multipleSelection,
            default_selection: this.defaultSelection,
            display_filters: this.displayFilters,
            filter_fields: this.filterFields.map(function (item) {
                return {
                    name: item.name
                };
            }),
            display_footer: this.displayFooter,
            group_field: this.groupField,
            group_title_style: this.groupTitleStyle ? this.groupTitleStyle.serialize() : undefined,
            group_collapse: this.groupCollapse,
            group_counter: this.groupCounter,
            groups: this.groups.map(function (item) { return item.serialize(); }),
            tooltip: this.tooltip,
            gap_horizontal: this.gapHorizontal,
            gap_vertical: this.gapVertical
        };
        if (fields) {
            data = pickBy(data, function (v, k) { return fields.includes(k); });
        }
        return data;
    };
    Object.defineProperty(GridSettings.prototype, "analyticsName", {
        get: function () {
            return 'gallery';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GridSettings.prototype, "analyticsGenericName", {
        get: function () {
            if (!this.analyticsName) {
                return;
            }
            return ['component', this.analyticsName].join('_');
        },
        enumerable: true,
        configurable: true
    });
    return GridSettings;
}());
export { GridSettings };
