import { registerElementForType } from '../element-items';
import { ElementType } from '../element-type';
import { ElementItem } from './base';
import { TabsLayoutItem } from './tabs-layout-item';

export enum TabsOrientation {
  Horizontal = 'horizontal',
  Vertical = 'vertical'
}

export enum TabsAppearance {
  Link = 'link',
  Button = 'button'
}

export enum TabsStyle {
  Wrap = 'wrap',
  Background = 'background'
}

export class TabsLayoutElementItem extends ElementItem {
  public type = ElementType.Tabs;
  public items: TabsLayoutItem[] = [];
  public orientation: TabsOrientation = TabsOrientation.Horizontal;
  public appearance: TabsAppearance = TabsAppearance.Link;
  public style: TabsStyle = TabsStyle.Wrap;
  public loadInvisible = false;
  public loadOnShow = false;

  deserialize(data: Object): TabsLayoutElementItem {
    super.deserialize(data);

    if (this.params['items']) {
      this.items = this.params['items'].map(item => new TabsLayoutItem().deserialize(item));
    }

    if (this.params['orientation']) {
      this.orientation = this.params['orientation'];
    }

    if (this.params['appearance']) {
      this.appearance = this.params['appearance'];
    }

    if (this.params['style']) {
      this.style = this.params['style'];
    }

    if (this.params['load_invisible'] != undefined) {
      this.loadInvisible = this.params['load_invisible'];
    }

    if (this.params['load_on_show'] != undefined) {
      this.loadOnShow = this.params['load_on_show'];
    }

    return this;
  }

  serialize(): Object {
    const data = super.serialize();
    data['params']['items'] = this.items.map(item => item.serialize());
    data['params']['orientation'] = this.orientation;
    data['params']['appearance'] = this.appearance;
    data['params']['style'] = this.style;
    data['params']['load_invisible'] = this.loadInvisible;
    data['params']['load_on_show'] = this.loadOnShow;
    return data;
  }

  get analyticsName(): string {
    return 'tabs';
  }

  defaultName() {
    return 'Tabs';
  }

  childrenCount() {
    return this.items.reduce((acc, item) => acc + item.children.length, 0);
  }
}

registerElementForType(ElementType.Tabs, TabsLayoutElementItem);
