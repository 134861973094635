<app-customize-bar-header [title]="'Appearance'" [subtitle]="'App settings'" [backEnabled]="false">
  <ng-container actions>
    <div class="button-group" [style.margin]="'-5px 0'">
      <div
        class="button-group__item"
        [class.button-group__item_active]="themeService.isDefaultTheme"
        (click)="themeService.theme = 'default'"
      >
        <span class="button-group__item-icon icon-sun"></span>
      </div>

      <div
        class="button-group__item"
        [class.button-group__item_active]="themeService.isDarkTheme"
        (click)="themeService.theme = 'dark'"
      >
        <span class="button-group__item-icon icon-moon"></span>
      </div>
    </div>
  </ng-container>
</app-customize-bar-header>

<div class="sidebar__content">
  <app-sidebar-section>
    <app-theme-gallery
      [settingsForm]="context.controls.appearance"
      [menuForm]="context.controls.menu"
      [applyEnabled]="currentProjectStore.instance.features.isStylesEnabled()"
      (applyClick)="onThemeApply($event); onStylesFeatureClick()"
    ></app-theme-gallery>

    <div class="sidebar-list__separator sidebar-list__separator_margin_l sidebar-list__separator_padding_none">
      <div class="sidebar-list-separator3">
        <div class="sidebar-list-separator3__left">
          <div class="sidebar-list-separator3__line"></div>
        </div>

        <div class="sidebar-list-separator3__main">
          <div class="sidebar-list-separator3__title">
            Customize theme
          </div>
        </div>

        <div class="sidebar-list-separator3__right">
          <div class="sidebar-list-separator3__line"></div>
        </div>
      </div>
    </div>

    <div class="sidebar__list">
      <div class="sidebar-list">
        <div class="sidebar-list__item">
          <a
            [appLink]="currentProjectStore.instance.settingsLayoutLink('appearance', 'general')"
            class="sidebar-summary-block"
            [class.sidebar-summary-block_clickable]="true"
            [appClickEvent]="[analyticsEvents.Appearance.SectionClicked, { Section: 'general' }]"
          >
            <div class="sidebar-summary-block__header">
              <div class="sidebar-summary-block__header-main">
                <div class="sidebar-summary-block__header-title">
                  General styles
                </div>
              </div>

              <div class="sidebar-summary-block__header-right">
                <div class="sidebar-summary-block__header-action">
                  <div class="sidebar-summary-block__header-action-label">
                    <ng-container *ngIf="menuBlocks.length">Customize</ng-container>
                    <ng-container *ngIf="!menuBlocks.length">Add menu</ng-container>
                  </div>
                  <div
                    class="sidebar-summary-block__header-action-icon sidebar-summary-block__header-action-icon_right icon-arrow_forward_2"
                  ></div>
                </div>
              </div>
            </div>

            <div class="sidebar-summary-block__content">
              <div class="sidebar-summary-block__row">
                <div class="sidebar-summary-block__row-left">
                  <div
                    class="sidebar-summary-block__layout"
                    [style.background-image]="getWidthImagePath('color_field')"
                  ></div>
                </div>

                <div class="sidebar-summary-block__row-main">
                  <div class="sidebar-summary-block-item">
                    <div class="sidebar-summary-block-item__main">
                      <div class="sidebar-summary-block-item__title sidebar-summary-block-item__element">
                        Colors, fonts, corners, bounds
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>

        <div class="sidebar-list__item">
          <a
            [appLink]="currentProjectStore.instance.settingsLayoutLink('appearance', 'menu')"
            class="sidebar-summary-block"
            [class.sidebar-summary-block_clickable]="true"
            [appClickEvent]="[analyticsEvents.Appearance.SectionClicked, { Section: 'menu' }]"
          >
            <div class="sidebar-summary-block__header">
              <div class="sidebar-summary-block__header-main">
                <div class="sidebar-summary-block__header-title">
                  App menu
                </div>
              </div>

              <div class="sidebar-summary-block__header-right">
                <div class="sidebar-summary-block__header-action">
                  <div class="sidebar-summary-block__header-action-label">
                    <ng-container *ngIf="menuBlocks.length">Customize</ng-container>
                    <ng-container *ngIf="!menuBlocks.length">Add menu</ng-container>
                  </div>
                  <div
                    class="sidebar-summary-block__header-action-icon sidebar-summary-block__header-action-icon_right icon-arrow_forward_2"
                  ></div>
                </div>
              </div>
            </div>

            <div class="sidebar-summary-block__content">
              <div class="sidebar-summary-block__row">
                <div class="sidebar-summary-block__row-left">
                  <div
                    class="sidebar-summary-block__layout"
                    [style.background-image]="
                      '/assets/images/project-layouts/layout-double-menu-left.svg' | appDeployCssUrl
                    "
                  ></div>
                </div>

                <div class="sidebar-summary-block__row-main">
                  <div class="sidebar-summary-block-item">
                    <div class="sidebar-summary-block-item__main">
                      <div
                        *ngIf="menuBlocks.length"
                        class="sidebar-summary-block-item__title sidebar-summary-block-item__element"
                      >
                        <ng-container *ngFor="let item of menuBlocks; let last = last"
                          >{{ item.controls.length }} {{ item.label }} menu<ng-container *ngIf="!last"
                            >,
                          </ng-container></ng-container
                        >
                      </div>

                      <div *ngIf="!menuBlocks.length" class="sidebar-summary-block-item__additional">
                        No menu added
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>

    <div class="sidebar__list">
      <app-sidebar-field [label]="'Component styles'">
        <div class="sidebar-list">
          <div class="sidebar-list__item">
            <a
              [appLink]="currentProjectStore.instance.settingsLayoutLink('appearance', 'buttons')"
              class="sidebar-list-item sidebar-list-item_clickable"
              [appClickEvent]="[analyticsEvents.Appearance.SectionClicked, { Section: 'buttons' }]"
            >
              <div class="sidebar-list-item__left sidebar-list-item__left_large">
                <span
                  class="sidebar-list-item__image sidebar-list-item__image_size_xxl"
                  [style.background-image]="getWidthImagePath('button')"
                ></span>
              </div>

              <div class="sidebar-list-item__main">
                <div class="sidebar-list-item__title">Buttons</div>
                <div class="sidebar-list-item__description">Customize styles</div>
              </div>

              <div class="sidebar-list-item__right">
                <span class="sidebar-list-item__action icon-arrow_forward_2"></span>
              </div>
            </a>
          </div>

          <div class="sidebar-list__item">
            <a
              [appLink]="currentProjectStore.instance.settingsLayoutLink('appearance', 'fields')"
              class="sidebar-list-item sidebar-list-item_clickable"
              [appClickEvent]="[analyticsEvents.Appearance.SectionClicked, { Section: 'fields' }]"
            >
              <div class="sidebar-list-item__left sidebar-list-item__left_large">
                <span
                  class="sidebar-list-item__image sidebar-list-item__image_size_xxl"
                  [style.background-image]="getWidthImagePath('text_field')"
                ></span>
              </div>

              <div class="sidebar-list-item__main">
                <div class="sidebar-list-item__title">Fields</div>
                <div class="sidebar-list-item__description">Customize styles</div>
              </div>

              <div class="sidebar-list-item__right">
                <span class="sidebar-list-item__action icon-arrow_forward_2"></span>
              </div>
            </a>
          </div>

          <div class="sidebar-list__item">
            <a
              [appLink]="currentProjectStore.instance.settingsLayoutLink('appearance', 'cards')"
              class="sidebar-list-item sidebar-list-item_clickable"
              [appClickEvent]="[analyticsEvents.Appearance.SectionClicked, { Section: 'cards' }]"
            >
              <div class="sidebar-list-item__left sidebar-list-item__left_large">
                <span
                  class="sidebar-list-item__image sidebar-list-item__image_size_xxl"
                  [style.background-image]="getWidthImagePath('card_layout')"
                ></span>
              </div>

              <div class="sidebar-list-item__main">
                <div class="sidebar-list-item__title">Cards</div>
                <div class="sidebar-list-item__description">Customize styles</div>
              </div>

              <div class="sidebar-list-item__right">
                <span class="sidebar-list-item__action icon-arrow_forward_2"></span>
              </div>
            </a>
          </div>
        </div>
      </app-sidebar-field>
    </div>
  </app-sidebar-section>
</div>

<app-sidebar-submit
  [saveDisabled]="!(hasChanges$ | async)"
  [saveLoading]="submitLoading"
  [saveLabel]="'Save changes'"
  [cancelDisabled]="!(hasChanges$ | async)"
  [cancelLabel]="'Reset changes'"
  [hidden]="!(hasChanges$ | async)"
  (cancelClick)="cancel()"
  (submitClick)="submit()"
>
</app-sidebar-submit>
