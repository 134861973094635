var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { combineLatest } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged, first, map, skip, switchMap, tap } from 'rxjs/operators';
import { modelFieldToDisplayField, modelFieldToRawListViewSettingsColumn } from '@modules/customize';
import { getFieldDescriptionByType, Input, isRequiredInputsSet } from '@modules/fields';
import { ModelDescriptionStore } from '@modules/model-queries';
import { ModelFieldType } from '@modules/models';
import { FieldInputControl, InputFieldProvider, inputFieldProviderItemsFromModelGet, modelDescriptionHasAutoParameters, parametersToProviderItems } from '@modules/parameters';
import { CurrentEnvironmentStore } from '@modules/projects';
import { ListModelDescriptionQuery, QueryType } from '@modules/queries';
import { prepareDataSourceColumnForGet } from '@modules/resources';
import { ascComparator, controlValue, isSet, splitmax } from '@shared';
export var DefaultForeignKeyPrimaryKeyTransformer = "// add custom transformation here\nreturn value;";
export function validateInputs() {
    return function (control) {
        var parent = control.parent;
        if (!parent) {
            return;
        }
        var fields = parent.inputFieldProvider.getFields();
        var inputs = control.value;
        if (!isRequiredInputsSet(fields, inputs)) {
            return { required: true };
        }
    };
}
var ForeignKeyFieldViewParamsForm = /** @class */ (function (_super) {
    __extends(ForeignKeyFieldViewParamsForm, _super);
    function ForeignKeyFieldViewParamsForm(fb, currentEnvironmentStore, modelDescriptionStore) {
        var _this = _super.call(this, {
            related_resource: new FormControl('', Validators.required),
            related_model: new FormControl(undefined, Validators.required),
            sorting_field: new FormControl(undefined),
            sorting_asc: new FormControl(true),
            custom_primary_key: new FormControl(undefined, Validators.required),
            custom_display_field: new FormControl(undefined, Validators.required),
            custom_display_input_enabled: new FormControl(false),
            custom_display_input: new FieldInputControl({ path: ['value'] }),
            subtitle_field: new FormControl(''),
            subtitle_input_enabled: new FormControl(false),
            subtitle_input: new FieldInputControl({ path: ['value'] }),
            icon_field: new FormControl(''),
            icon_input_enabled: new FormControl(false),
            icon_input: new FieldInputControl({ path: ['value'] }),
            foreign_key_transformer: new FormControl(DefaultForeignKeyPrimaryKeyTransformer),
            create_button: new FormControl(true),
            inputs: new FormControl([], validateInputs())
        }) || this;
        _this.fb = fb;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        _this.modelDescriptionStore = modelDescriptionStore;
        _this.inputFieldProvider = new InputFieldProvider();
        return _this;
    }
    ForeignKeyFieldViewParamsForm.prototype.ngOnDestroy = function () {
        this.inputFieldProvider.clearProvider();
    };
    ForeignKeyFieldViewParamsForm.prototype.initObservers = function () {
        var _this = this;
        this.controls.related_resource.valueChanges.subscribe(function (value) { return _this.onResourceChange(value); });
        this.modelDescription$()
            .pipe(distinctUntilChanged(function (lhs, rhs) {
            var lhsModelId = lhs ? lhs.modelId : undefined;
            var rhsModelId = rhs ? rhs.modelId : undefined;
            return lhsModelId == rhsModelId;
        }), skip(1))
            .subscribe(function (modelDescription) {
            if (modelDescription) {
                var primaryKeyField = void 0;
                if (modelDescription.primaryKeyField) {
                    primaryKeyField = modelDescription.primaryKeyField;
                }
                else if (modelDescription.dbFields.length) {
                    primaryKeyField = modelDescription.dbFields[0].name;
                }
                if (isSet(primaryKeyField)) {
                    _this.controls.custom_primary_key.patchValue(primaryKeyField);
                }
                var displayField = void 0;
                if (modelDescription.displayField) {
                    displayField = modelDescription.displayField;
                }
                else if (modelDescription.dbFields.length) {
                    displayField = modelDescription.dbFields[0].name;
                }
                if (isSet(displayField)) {
                    _this.controls.custom_display_field.patchValue(displayField);
                    _this.controls.custom_display_input_enabled.patchValue(false);
                    _this.controls.custom_display_input.patchValue({});
                }
            }
            _this.onModelParamsChange();
        });
    };
    ForeignKeyFieldViewParamsForm.prototype.init = function (control, context) {
        var _this = this;
        this.control = control;
        this.context = context;
        if (control.value) {
            var relatedModel = control.value['related_model'];
            var inputs = control.value['inputs'] ? control.value['inputs'].map(function (item) { return new Input().deserialize(item); }) : [];
            if (isSet(relatedModel) && isSet(relatedModel['model'])) {
                var defaultResource = this.context && this.context.resource ? this.context && this.context.resource.uniqueName : undefined;
                var _a = relatedModel['model'].includes('.')
                    ? splitmax(relatedModel['model'], '.', 2)
                    : [defaultResource, relatedModel['model']], resourceName = _a[0], modelName = _a[1];
                if (isSet(resourceName) && isSet(modelName)) {
                    this.controls.related_resource.patchValue(resourceName);
                    this.controls.related_model.patchValue(modelName);
                }
            }
            this.controls.sorting_field.patchValue(control.value['sorting_field']);
            this.controls.sorting_asc.patchValue(control.value['sorting_asc']);
            if (isSet(control.value['custom_primary_key'])) {
                this.controls.custom_primary_key.patchValue(control.value['custom_primary_key']);
            }
            if (control.value['custom_display_field_input']) {
                this.controls.custom_display_input_enabled.patchValue(true);
                this.controls.custom_display_input.patchValue(control.value['custom_display_field_input']);
            }
            else {
                if (isSet(control.value['custom_display_field'])) {
                    this.controls.custom_display_field.patchValue(control.value['custom_display_field']);
                }
            }
            this.controls.subtitle_field.patchValue(control.value['subtitle_field']);
            this.controls.subtitle_input_enabled.patchValue(!!control.value['subtitle_input']);
            this.controls.subtitle_input.patchValue(control.value['subtitle_input'] ? control.value['subtitle_input'] : {});
            this.controls.icon_field.patchValue(control.value['icon_field']);
            this.controls.icon_input_enabled.patchValue(!!control.value['icon_input']);
            this.controls.icon_input.patchValue(control.value['icon_input'] ? control.value['icon_input'] : {});
            if (isSet(control.value['create_button'])) {
                this.controls.create_button.patchValue(control.value['create_button']);
            }
            if (control.value['foreign_key_transformer']) {
                this.controls.foreign_key_transformer.patchValue(control.value['foreign_key_transformer']);
            }
            else if (control.value['primary_key_transformer']) {
                this.controls.foreign_key_transformer.patchValue(control.value['primary_key_transformer']);
            }
            this.controls.inputs.patchValue(inputs);
            this.markAsPristine();
        }
        else {
            this.markAsDirty();
        }
        this.valueChanges.pipe(delay(0)).subscribe(function () { return _this.submit(); });
        this.updateInputFieldProvider().subscribe();
        this.initObservers();
    };
    ForeignKeyFieldViewParamsForm.prototype.toggleDefaultSorting = function () {
        var control = this.controls.sorting_asc;
        control.patchValue(!control.value);
    };
    ForeignKeyFieldViewParamsForm.prototype.updateInputFieldProvider = function () {
        var _this = this;
        return combineLatest(this.resource$(), this.modelDescription$()).pipe(first(), map(function (_a) {
            var resource = _a[0], modelDescription = _a[1];
            var parameters = modelDescription ? modelDescription.getParameters : [];
            var query = modelDescription ? modelDescription.getQuery : undefined;
            var columns = modelDescription
                ? modelDescription.fields
                    .filter(function (item) { return item.type == ModelFieldType.Db; })
                    .map(function (item) { return modelFieldToRawListViewSettingsColumn(item); })
                : [];
            return parametersToProviderItems(parameters).concat(inputFieldProviderItemsFromModelGet(resource, modelDescription, query, columns));
        }), tap(function (items) {
            _this.inputFieldProvider.setProvider(items, true);
        }));
    };
    ForeignKeyFieldViewParamsForm.prototype.resource$ = function () {
        var _this = this;
        return controlValue(this.controls.related_resource).pipe(map(function (value) { return _this.currentEnvironmentStore.resources.find(function (item) { return item.uniqueName == value; }); }));
    };
    ForeignKeyFieldViewParamsForm.prototype.modelDescription$ = function () {
        var _this = this;
        return combineLatest(controlValue(this.controls.related_resource), controlValue(this.controls.related_model)).pipe(switchMap(function (_a) {
            var resourceName = _a[0], modelName = _a[1];
            var modelId = resourceName && modelName ? [resourceName, modelName].join('.') : null;
            return _this.modelDescriptionStore.getDetailFirst(modelId);
        }));
    };
    ForeignKeyFieldViewParamsForm.prototype.resourceModelItems$ = function () {
        var _this = this;
        return combineLatest(this.resource$(), this.modelDescriptionStore.get()).pipe(map(function (_a) {
            var resource = _a[0], modelDescriptions = _a[1];
            if (!resource) {
                return [];
            }
            var options = [];
            if (modelDescriptions) {
                options.push.apply(options, modelDescriptions
                    .filter(function (item) { return item.resource == resource.uniqueName; })
                    .filter(function (item) {
                    return !resource.demo ||
                        item.featured ||
                        (_this.controls.related_model.value && _this.controls.related_model.value['model'] == item.model);
                })
                    .sort(function (lhs, rhs) {
                    return ascComparator(String(lhs.verboseNamePlural).toLowerCase(), String(rhs.verboseNamePlural).toLowerCase());
                })
                    .map(function (item) {
                    return {
                        option: {
                            value: item.model,
                            name: item.verboseNamePlural,
                            icon: 'document'
                        }
                    };
                }));
            }
            return options;
        }));
    };
    ForeignKeyFieldViewParamsForm.prototype.columnOptions$ = function () {
        var _this = this;
        return this.modelDescription$().pipe(map(function (modelDescription) {
            if (!modelDescription) {
                return [];
            }
            var resource = _this.currentEnvironmentStore.resources.find(function (item) { return item.uniqueName == modelDescription.resource; });
            if (!resource) {
                return [];
            }
            var columns = modelDescription
                ? modelDescription.fields
                    .map(function (item) { return modelFieldToDisplayField(item, false); })
                    .map(function (item) { return prepareDataSourceColumnForGet(resource, modelDescription, item); })
                : [];
            return columns.map(function (item) {
                var fieldDescription = getFieldDescriptionByType(item.field);
                return {
                    option: {
                        value: item.name,
                        name: item.verboseName || item.name,
                        icon: fieldDescription ? fieldDescription.icon : undefined
                    }
                };
            });
        }));
    };
    ForeignKeyFieldViewParamsForm.prototype.isFieldSortable = function (query, field, resource, modelDescription) {
        if (field.flex) {
            return false;
        }
        if (query) {
            if (modelDescriptionHasAutoParameters(resource, modelDescription)) {
                return true;
            }
            else if (query.queryType == QueryType.Simple) {
                return modelDescription && modelDescription.getQuery && modelDescription.getQuery.isFieldSortable(field);
            }
            else if (query instanceof ListModelDescriptionQuery) {
                return query.isFieldSortable(field);
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    };
    ForeignKeyFieldViewParamsForm.prototype.sortableColumnOptions$ = function () {
        var _this = this;
        return combineLatest(this.resource$(), this.modelDescription$(), this.columnOptions$()).pipe(debounceTime(60), map(function (_a) {
            var resource = _a[0], modelDescription = _a[1];
            var query = modelDescription ? modelDescription.getQuery : undefined;
            var columns = modelDescription
                ? modelDescription.fields
                    .map(function (item) { return modelFieldToDisplayField(item, false); })
                    .map(function (item) { return prepareDataSourceColumnForGet(resource, modelDescription, item); })
                : [];
            return columns
                .filter(function (item) {
                return _this.isFieldSortable(query, item, resource, modelDescription);
            })
                .map(function (item) {
                return {
                    value: item.name,
                    name: item.verboseName || item.name
                };
            });
        }));
    };
    ForeignKeyFieldViewParamsForm.prototype.isPrimaryKeyTransformerChanged = function () {
        return this.controls.foreign_key_transformer.value != DefaultForeignKeyPrimaryKeyTransformer;
    };
    ForeignKeyFieldViewParamsForm.prototype.onResourceChange = function (resourceName) {
        var resource = this.currentEnvironmentStore.resources.find(function (item) { return item.uniqueName == resourceName; });
        var firstModel = this.modelDescriptionStore.instance.find(function (model) {
            if (model.resource != resourceName) {
                return false;
            }
            else if (model.getParameters.filter(function (item) { return item.required; }).length) {
                return false;
            }
            if (resource.demo) {
                return model.featured;
            }
            return true;
        });
        if (firstModel) {
            this.controls.related_model.patchValue(firstModel.model);
        }
    };
    ForeignKeyFieldViewParamsForm.prototype.onModelParamsChange = function () {
        var _this = this;
        setTimeout(function () {
            _this.updateInputFieldProvider().subscribe();
        }, 0);
    };
    ForeignKeyFieldViewParamsForm.prototype.submit = function () {
        var modelId = this.controls.related_resource.value && this.controls.related_model.value
            ? { model: [this.controls.related_resource.value, this.controls.related_model.value].join('.') }
            : null;
        var params = {
            related_model: modelId,
            sorting_field: this.controls.sorting_field.value,
            sorting_asc: this.controls.sorting_asc.value,
            custom_primary_key: this.controls.custom_primary_key.value,
            custom_display_field: !this.controls.custom_display_input_enabled.value
                ? this.controls.custom_display_field.value
                : undefined,
            custom_display_field_input: this.controls.custom_display_input_enabled.value
                ? this.controls.custom_display_input.value
                : undefined,
            subtitle_field: !this.controls.subtitle_input_enabled.value ? this.controls.subtitle_field.value : undefined,
            subtitle_input: this.controls.subtitle_input_enabled.value ? this.controls.subtitle_input.value : undefined,
            icon_field: !this.controls.icon_input_enabled.value ? this.controls.icon_field.value : undefined,
            icon_input: this.controls.icon_input_enabled.value ? this.controls.icon_input.value : undefined,
            create_button: this.controls.create_button.value,
            inputs: this.controls.inputs.value ? this.controls.inputs.value.map(function (item) { return item.serialize(); }) : []
        };
        if (this.isPrimaryKeyTransformerChanged()) {
            params['foreign_key_transformer'] = this.controls.foreign_key_transformer.value;
        }
        this.control.patchValue(__assign({}, this.control.value, params));
    };
    return ForeignKeyFieldViewParamsForm;
}(FormGroup));
export { ForeignKeyFieldViewParamsForm };
