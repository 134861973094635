var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Injector } from '@angular/core';
import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';
import pickBy from 'lodash/pickBy';
import toPairs from 'lodash/toPairs';
import { of, throwError } from 'rxjs';
import { map, publishLast, refCount } from 'rxjs/operators';
import { ServerRequestError } from '@modules/api';
import { ParameterField } from '@modules/fields';
import { Model, ModelDescription, ModelField } from '@modules/models';
import { HttpMethod, HttpQuery, ModelDescriptionQuery, ObjectQuery, ObjectQueryOperation, QueryType } from '@modules/queries';
import { isSet } from '@shared';
import { aggregateGetResponse } from '../../utils/aggregate';
import { isResourceQueryCustom } from '../../utils/common';
import { applyFrontendFiltering, applyFrontendPagination, applyFrontendSorting } from '../../utils/filters';
import { groupGetResponse } from '../../utils/group';
import { RestApiResourceControllerService } from '../rest-api-resource-controller/rest-api-resource-controller.service';
export var OBJECT_QUERY_KEY_NAME = 'jet_id';
export var OBJECT_QUERY_KEY_LABEL = 'object key';
var FirebaseResourceController = /** @class */ (function (_super) {
    __extends(FirebaseResourceController, _super);
    function FirebaseResourceController(injector) {
        var _this = _super.call(this, injector) || this;
        _this.injector = injector;
        _this.tokenName = 'access_token';
        return _this;
    }
    FirebaseResourceController.prototype.init = function () {
        _super.prototype.init.call(this);
    };
    FirebaseResourceController.prototype.supportedQueryTypes = function (resource, queryClass) {
        // return [QueryType.Http, QueryType.Object];
        return [QueryType.Object];
    };
    FirebaseResourceController.prototype.getFirestoreCollectionIds = function (projectId, accessToken) {
        var databasePath = "projects/" + projectId + "/databases/(default)/documents";
        var url = "https://firestore.googleapis.com/v1/" + databasePath + ":listCollectionIds/";
        var headers = { Authorization: "Bearer " + accessToken };
        var data = {
            pageSize: 100
        };
        return this.http
            .post(url, data, { headers: headers })
            .pipe(this.apiService.catchApiError(), publishLast(), refCount());
    };
    FirebaseResourceController.prototype.getRealtimeDatabases = function (projectId, accessToken) {
        var url = "https://firebasedatabase.googleapis.com/v1beta/projects/" + projectId + "/locations/-/instances";
        var headers = { Authorization: "Bearer " + accessToken };
        return this.http.get(url, { headers: headers }).pipe(this.apiService.catchApiError(), publishLast(), refCount());
    };
    FirebaseResourceController.prototype.modelGetQueryObject = function (resource, modelDescription, params, body) {
        var query = modelDescription.getQuery;
        if (!query) {
            return throwError(new ServerRequestError("No query specified for collection " + modelDescription.verboseNamePlural));
        }
        return this.getQueryObject(resource, query, modelDescription.getParameters, params, body, modelDescription.dbFields).pipe(map(function (response) {
            if (!response) {
                return;
            }
            response.results.forEach(function (item) {
                item.setUp(modelDescription);
            });
            return response;
        }));
    };
    FirebaseResourceController.prototype.modelGet = function (resource, modelDescription, params, body) {
        if (!modelDescription.getQuery) {
            return of(undefined);
        }
        params = params || {};
        if (modelDescription.getQuery.queryType == QueryType.Http) {
            return _super.prototype.modelGet.call(this, resource, modelDescription, params, body);
        }
        else if (modelDescription.getQuery.queryType == QueryType.Object) {
            return this.modelGetQueryObject(resource, modelDescription, params, body);
        }
        else {
            return of(undefined);
        }
    };
    FirebaseResourceController.prototype.modelGetDetailQueryObject = function (resource, modelDescription, idField, id, params) {
        var query = modelDescription.getDetailQuery;
        if (!query) {
            return throwError(new ServerRequestError("No query specified for collection " + modelDescription.verboseNamePlural));
        }
        return this.getDetailQueryObject(resource, query, params, modelDescription.dbFields).pipe(map(function (item) {
            if (!item) {
                return;
            }
            item.setUp(modelDescription);
            return item;
        }));
    };
    FirebaseResourceController.prototype.modelGetDetail = function (resource, modelDescription, idField, id, params) {
        params = params || {};
        if (modelDescription.getDetailQuery) {
            if (modelDescription.getDetailQuery.queryType == QueryType.Http) {
                return _super.prototype.modelGetDetail.call(this, resource, modelDescription, idField, id, params);
            }
            else if (modelDescription.getDetailQuery.queryType == QueryType.Object) {
                return this.modelGetDetailQueryObject(resource, modelDescription, idField, id, params);
            }
            else {
                return of(undefined);
            }
        }
        else if (modelDescription.getQuery) {
            if (modelDescription.getQuery.queryType == QueryType.Http) {
                return _super.prototype.modelGetDetail.call(this, resource, modelDescription, idField, id, params);
            }
            else if (modelDescription.getQuery.queryType == QueryType.Object) {
                params = __assign({}, params);
                if (isSet(modelDescription.primaryKeyField)) {
                    params[idField] = id;
                }
                return this.modelGetQueryObject(resource, modelDescription, params).pipe(map(function (result) {
                    if (!result || !result.results.length) {
                        return;
                    }
                    var model = result.results[0];
                    model.setUp(modelDescription);
                    return model;
                }));
            }
            else {
                return of(undefined);
            }
        }
    };
    FirebaseResourceController.prototype.modelCreateQueryObject = function (resource, modelDescription, modelInstance, fields) {
        var query = modelDescription.createQuery;
        if (!query) {
            return throwError(new ServerRequestError("No query specified for collection " + modelDescription.verboseNamePlural));
        }
        return this.createQueryObject(resource, query, modelInstance, modelDescription.dbFields, fields).pipe(map(function (item) {
            if (!item) {
                return;
            }
            item.setUp(modelDescription);
            return item;
        }));
    };
    FirebaseResourceController.prototype.modelCreate = function (resource, modelDescription, modelInstance, fields) {
        if (modelDescription.getQuery.queryType == QueryType.Http) {
            return _super.prototype.modelCreate.call(this, resource, modelDescription, modelInstance, fields);
        }
        else if (modelDescription.getQuery.queryType == QueryType.Object) {
            return this.modelCreateQueryObject(resource, modelDescription, modelInstance, fields);
        }
        else {
            return of(undefined);
        }
    };
    FirebaseResourceController.prototype.modelUpdateQueryObject = function (resource, modelDescription, modelInstance, fields) {
        var query = modelDescription.updateQuery;
        if (!query) {
            return throwError(new ServerRequestError("No query specified for collection " + modelDescription.verboseNamePlural));
        }
        return this.updateQueryObject(resource, query, modelInstance, modelDescription.dbFields, fields).pipe(map(function (item) {
            if (!item) {
                return;
            }
            item.setUp(modelDescription);
            return item;
        }));
    };
    FirebaseResourceController.prototype.modelUpdate = function (resource, modelDescription, modelInstance, fields) {
        if (modelDescription.getQuery.queryType == QueryType.Http) {
            return _super.prototype.modelUpdate.call(this, resource, modelDescription, modelInstance, fields);
        }
        else if (modelDescription.getQuery.queryType == QueryType.Object) {
            return this.modelUpdateQueryObject(resource, modelDescription, modelInstance, fields);
        }
        else {
            return of(undefined);
        }
    };
    FirebaseResourceController.prototype.modelDeleteQueryObject = function (resource, modelDescription, modelInstance) {
        var query = modelDescription.deleteQuery;
        if (!query) {
            return throwError(new ServerRequestError("No query specified for collection " + modelDescription.verboseNamePlural));
        }
        return this.deleteQueryObject(resource, query, modelInstance);
    };
    FirebaseResourceController.prototype.modelDelete = function (resource, modelDescription, modelInstance) {
        if (modelDescription.getQuery.queryType == QueryType.Http) {
            return _super.prototype.modelDelete.call(this, resource, modelDescription, modelInstance);
        }
        else if (modelDescription.getQuery.queryType == QueryType.Object) {
            return this.modelDeleteQueryObject(resource, modelDescription, modelInstance);
        }
        else {
            return of(undefined);
        }
    };
    FirebaseResourceController.prototype.modelGroupQueryObject = function (resource, modelDescription, xColumns, yFunc, yColumn, params) {
        var query = modelDescription.getQuery;
        if (!query) {
            return throwError(new ServerRequestError("No query specified for collection " + modelDescription.verboseNamePlural));
        }
        return this.getGroupQueryObject(resource, query, xColumns, yFunc, yColumn, modelDescription.getParameters, params, modelDescription.dbFields);
    };
    FirebaseResourceController.prototype.modelGroup = function (resource, modelDescription, xColumns, yFunc, yColumn, params) {
        params = params || {};
        if (modelDescription.getQuery.queryType == QueryType.Http) {
            return _super.prototype.modelGroup.call(this, resource, modelDescription, xColumns, yFunc, yColumn, params);
        }
        else if (modelDescription.getQuery.queryType == QueryType.Object) {
            return this.modelGroupQueryObject(resource, modelDescription, xColumns, yFunc, yColumn, params);
        }
        else {
            return of(undefined);
        }
    };
    FirebaseResourceController.prototype.modelAggregateQueryObject = function (resource, modelDescription, yFunc, yColumn, params) {
        var query = modelDescription.getQuery;
        if (!query) {
            return throwError(new ServerRequestError("No query specified for collection " + modelDescription.verboseNamePlural));
        }
        return this.getAggregateQueryObject(resource, query, yFunc, yColumn, modelDescription.getParameters, params, modelDescription.dbFields);
    };
    FirebaseResourceController.prototype.modelAggregate = function (resource, modelDescription, yFunc, yColumn, params) {
        params = params || {};
        if (modelDescription.getQuery.queryType == QueryType.Http) {
            return _super.prototype.modelAggregate.call(this, resource, modelDescription, yFunc, yColumn, params);
        }
        else if (modelDescription.getQuery.queryType == QueryType.Object) {
            return this.modelAggregateQueryObject(resource, modelDescription, yFunc, yColumn, params);
        }
        else {
            return of(undefined);
        }
    };
    FirebaseResourceController.prototype.getQueryObject = function (resource, query, parameters, params, body, columns, paginate) {
        var _this = this;
        if (parameters === void 0) { parameters = []; }
        if (columns === void 0) { columns = []; }
        if (paginate === void 0) { paginate = true; }
        if (!query.objectQuery) {
            return of(undefined);
        }
        var userQuery = isResourceQueryCustom(resource, query.queryType);
        var tokens = this.queryTokensService.mergeTokens(this.queryTokensService.generalTokens(), this.queryTokensService.modelGetTokens(params, parameters, userQuery), this.queryTokensService.paginationTokens(query.pagination, params));
        return this.objectGet(resource, query.objectQuery.query, query.objectQuery.queryOptions).pipe(map(function (result) {
            var resultBody = result;
            // resultBody = this.queryService.applyTransformer(
            //   resultBody,
            //   query.objectQuery.responseTransformer,
            //   query.objectQuery.url,
            //   false,
            //   tokens
            // ) as Object[];
            // resultBody = this.queryService.getPath(resultBody, query.objectQuery.responsePath);
            if (!isArray(resultBody)) {
                resultBody = [resultBody];
            }
            // TODO: Move filtering after deserialization
            // if (query.frontendFiltering && resultBody && isArray(resultBody)) {
            if (resultBody && isArray(resultBody)) {
                resultBody = applyFrontendFiltering(resultBody, params, columns);
            }
            var data = {
                results: resultBody
            };
            var response = _this.createGetResponse().deserialize(data, undefined, undefined);
            var tokensAfterResponse = __assign({}, tokens, { results: data.results });
            response.results.forEach(function (item) {
                item.deserializeAttributes(columns);
            });
            // if (query.frontendFiltering) {
            // if (true) {
            applyFrontendSorting(response, params);
            applyFrontendPagination(response, params, paginate);
            // } else if (
            //   query.pagination == QueryPagination.Page ||
            //   query.pagination == QueryPagination.Offset ||
            //   query.pagination == QueryPagination.Cursor
            // ) {
            //   if (query.paginationHasMoreFunction) {
            //     response.hasMore = this.queryService.applyTransformer(
            //       result,
            //       query.paginationHasMoreFunction,
            //       query.objectQuery.url,
            //       false,
            //       tokensAfterResponse
            //     );
            //   } else {
            //     if (query.paginationHasMorePagesPath.length) {
            //       response.hasMore = this.queryService.getPath(result, query.paginationHasMorePagesPath.join('.'));
            //     } else if (query.paginationHasMoreTotalPagesPath.length) {
            //       const totalPages = this.queryService.getPath(result, query.paginationHasMoreTotalPagesPath.join('.'));
            //       response.hasMore = tokens['paging']['page'] < totalPages;
            //     } else if (query.paginationHasMoreTotalRecordsPath.length) {
            //       const totalRecords = this.queryService.getPath(result, query.paginationHasMoreTotalRecordsPath.join('.'));
            //       const limit = query.paginationPerPage;
            //       let totalPages: number;
            //
            //       if (limit && totalRecords) {
            //         totalPages = Math.ceil(totalRecords / limit);
            //         response.hasMore = tokens['paging']['page'] < totalPages;
            //       }
            //     }
            //   }
            //
            //   if (query.paginationTotalFunction) {
            //     response.count = this.queryService.applyTransformer(
            //       result,
            //       query.paginationTotalFunction,
            //       query.objectQuery.url,
            //       false,
            //       tokensAfterResponse
            //     );
            //   } else if (query.paginationTotalPath && query.paginationTotalPath.length) {
            //     response.count = this.queryService.getPath(result, query.paginationTotalPath.join('.'));
            //   }
            // }
            // if (query.pagination == QueryPagination.Cursor) {
            //   if (query.paginationCursorPrevFunction) {
            //     response.cursorPrev = this.queryService.applyTransformer(
            //       result,
            //       query.paginationCursorPrevFunction,
            //       query.objectQuery.url,
            //       false,
            //       tokensAfterResponse
            //     );
            //   } else if (query.paginationCursorPrevPath) {
            //     response.cursorPrev = this.queryService.getPath(result, query.paginationCursorPrevPath.join('.'));
            //   }
            //
            //   if (query.paginationCursorNextFunction) {
            //     response.cursorNext = this.queryService.applyTransformer(
            //       result,
            //       query.paginationCursorNextFunction,
            //       query.objectQuery.url,
            //       false,
            //       tokensAfterResponse
            //     );
            //   } else if (query.paginationCursorNextPath) {
            //     response.cursorNext = this.queryService.getPath(result, query.paginationCursorNextPath.join('.'));
            //   }
            //
            //   response.hasMore = !!response.cursorNext;
            // }
            return response;
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    FirebaseResourceController.prototype.getDetailQueryObject = function (resource, query, params, columns) {
        var _this = this;
        if (columns === void 0) { columns = []; }
        if (!query.objectQuery) {
            return of(undefined);
        }
        return this.objectQuery(resource, query.objectQuery, params).pipe(map(function (response) {
            if (!response) {
                return;
            }
            var model = _this.createModel().deserialize(undefined, response);
            model.deserializeAttributes(columns);
            return model;
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    FirebaseResourceController.prototype.createQueryObject = function (resource, query, modelInstance, columns, fields) {
        var _this = this;
        if (columns === void 0) { columns = []; }
        var data = modelInstance.serialize(fields);
        return this.objectQuery(resource, query.objectQuery, data).pipe(map(function (result) {
            var model = _this.createModel().deserialize(undefined, __assign({}, data, result));
            model.deserializeAttributes(columns);
            return model;
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    FirebaseResourceController.prototype.updateQueryObject = function (resource, query, modelInstance, columns, fields) {
        var _this = this;
        if (columns === void 0) { columns = []; }
        var data = modelInstance.serialize(fields);
        return this.objectQuery(resource, query.objectQuery, data).pipe(map(function (result) {
            var model = _this.createModel().deserialize(undefined, data);
            model.deserializeAttributes(columns);
            return model;
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    FirebaseResourceController.prototype.deleteQueryObject = function (resource, query, modelInstance) {
        var data = modelInstance.serialize();
        return this.objectQuery(resource, query.objectQuery, data).pipe(this.apiService.catchApiError(), publishLast(), refCount());
    };
    FirebaseResourceController.prototype.getAggregateQueryObject = function (resource, query, yFunc, yColumn, parameters, params, columns) {
        if (parameters === void 0) { parameters = []; }
        if (columns === void 0) { columns = []; }
        return this.getQueryObject(resource, query, parameters, params, undefined, columns, false).pipe(map(function (response) { return aggregateGetResponse(response, yFunc, yColumn); }));
    };
    FirebaseResourceController.prototype.getGroupQueryObject = function (resource, query, xColumns, yFunc, yColumn, parameters, params, columns) {
        if (parameters === void 0) { parameters = []; }
        if (columns === void 0) { columns = []; }
        return this.getQueryObject(resource, query, parameters, params, undefined, columns, false).pipe(map(function (response) { return groupGetResponse(response, xColumns, yFunc, yColumn); }));
    };
    FirebaseResourceController.prototype.objectToArray = function (result) {
        return toPairs(result).map(function (_a) {
            var k = _a[0], v = _a[1];
            var _b, _c;
            if (isPlainObject(v)) {
                return __assign({}, v, (_b = {}, _b[OBJECT_QUERY_KEY_NAME] = k, _b));
            }
            else {
                return _c = {
                        value: v
                    },
                    _c[OBJECT_QUERY_KEY_NAME] = k,
                    _c;
            }
        });
    };
    FirebaseResourceController.prototype.arrayToArray = function (result) {
        return result
            .map(function (v, i) {
            var _a, _b;
            if (v === null) {
                return;
            }
            if (isPlainObject(v)) {
                return __assign({}, v, (_a = {}, _a[OBJECT_QUERY_KEY_NAME] = i, _a));
            }
            else {
                return _b = {
                        value: v
                    },
                    _b[OBJECT_QUERY_KEY_NAME] = i,
                    _b;
            }
        })
            .filter(function (item) { return item; });
    };
    FirebaseResourceController.prototype.objectProcess = function (result, options) {
        if (options === void 0) { options = {}; }
        if (options.objectToArray) {
            if (isArray(result)) {
                return this.arrayToArray(result);
            }
            else if (isPlainObject(result)) {
                return this.objectToArray(result);
            }
            else {
                return result;
            }
        }
        else {
            return result;
        }
    };
    FirebaseResourceController.prototype.objectGet = function (resource, path, options) {
        var _this = this;
        if (path === void 0) { path = []; }
        if (options === void 0) { options = {}; }
        var queryOptions = { resource: resource.uniqueName };
        var query = new HttpQuery();
        var database = resource.params['database_option'];
        path = path || [];
        query.method = HttpMethod.GET;
        query.url = database.id + "/" + path.join('/') + ".json";
        query.headers = [{ name: 'Authorization', value: "Bearer {-" + this.tokenName + "-}" }];
        return this.httpQueryService.requestBody(query, queryOptions).pipe(map(function (result) { return _this.objectProcess(result, options); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    FirebaseResourceController.prototype.objectCreate = function (resource, path, data) {
        var queryOptions = { resource: resource.uniqueName };
        var query = new HttpQuery();
        var database = resource.params['database_option'];
        if (isSet(data[OBJECT_QUERY_KEY_NAME])) {
            query.method = HttpMethod.PUT;
            path = (path || []).concat([data[OBJECT_QUERY_KEY_NAME]]);
        }
        else {
            query.method = HttpMethod.POST;
            path = path || [];
        }
        query.url = database.id + "/" + path.join('/') + ".json";
        query.headers = [{ name: 'Authorization', value: "Bearer {-" + this.tokenName + "-}" }];
        query.body = data = pickBy(data, function (v, k) { return k != OBJECT_QUERY_KEY_NAME; });
        return this.httpQueryService.requestBody(query, queryOptions).pipe(map(function (result) {
            var _a;
            return __assign({}, data, (_a = {}, _a[OBJECT_QUERY_KEY_NAME] = result ? result.name : data[OBJECT_QUERY_KEY_NAME], _a));
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    FirebaseResourceController.prototype.objectUpdate = function (resource, path, data) {
        var queryOptions = { resource: resource.uniqueName };
        var query = new HttpQuery();
        var database = resource.params['database_option'];
        path = (path || []).concat([data[OBJECT_QUERY_KEY_NAME]]);
        query.method = HttpMethod.PATCH;
        query.url = database.id + "/" + path.join('/') + ".json";
        query.headers = [{ name: 'Authorization', value: "Bearer {-" + this.tokenName + "-}" }];
        query.body = pickBy(data, function (v, k) { return k != OBJECT_QUERY_KEY_NAME; });
        return this.httpQueryService.requestBody(query, queryOptions).pipe(map(function () { return data; }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    FirebaseResourceController.prototype.objectDelete = function (resource, path, data) {
        var queryOptions = { resource: resource.uniqueName };
        var query = new HttpQuery();
        var database = resource.params['database_option'];
        path = (path || []).concat([data[OBJECT_QUERY_KEY_NAME]]);
        query.method = HttpMethod.DELETE;
        query.url = database.id + "/" + path.join('/') + ".json";
        query.headers = [{ name: 'Authorization', value: "Bearer {-" + this.tokenName + "-}" }];
        return this.httpQueryService.requestBody(query, queryOptions).pipe(map(function () {
            var _a;
            return _a = {}, _a[OBJECT_QUERY_KEY_NAME] = data[OBJECT_QUERY_KEY_NAME], _a;
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    FirebaseResourceController.prototype.setUpModelDescriptionBasedOnGetQuery = function (resource, modelDescription, getQuery, fields) {
        modelDescription = _super.prototype.setUpModelDescriptionBasedOnGetQuery.call(this, resource, modelDescription, getQuery, fields);
        var getQueryObject = getQuery ? getQuery.objectQuery : undefined;
        if (getQueryObject) {
            var objectQuery = function () {
                var query = new ModelDescriptionQuery();
                query.queryType = QueryType.Object;
                query.objectQuery = new ObjectQuery();
                query.objectQuery.query = getQueryObject.query;
                query.objectQuery.queryOptions = getQueryObject.queryOptions;
                return query;
            };
            var objectKeyParameter = function (required, description) {
                if (required === void 0) { required = true; }
                var result = new ParameterField();
                result.name = OBJECT_QUERY_KEY_NAME;
                result.verboseName = OBJECT_QUERY_KEY_LABEL;
                result.required = required;
                result.protected = true;
                result.description = description;
                if (!required) {
                    result.placeholder = 'Populated automatically if empty';
                }
                result.updateFieldDescription();
                return result;
            };
            var objectFieldParameters = function () {
                return fields.map(function (field) {
                    var result = new ParameterField();
                    result.name = field.item.name;
                    result.field = field.item.field;
                    result.params = field.item.params;
                    result.required = true;
                    result.updateFieldDescription();
                    return result;
                });
            };
            modelDescription.createQuery = objectQuery();
            modelDescription.createQuery.objectQuery.operation = ObjectQueryOperation.Create;
            modelDescription.createParameters = [
                objectKeyParameter(false, 'Create Object with the following key')
            ].concat(objectFieldParameters());
            modelDescription.updateQuery = objectQuery();
            modelDescription.updateQuery.objectQuery.operation = ObjectQueryOperation.Update;
            modelDescription.updateParameters = [
                objectKeyParameter(true, 'Update Object with the following key')
            ].concat(objectFieldParameters());
            modelDescription.deleteQuery = objectQuery();
            modelDescription.deleteQuery.objectQuery.operation = ObjectQueryOperation.Delete;
            modelDescription.deleteParameters = [objectKeyParameter(true, 'Delete Object with the following key')];
            modelDescription.primaryKeyField = OBJECT_QUERY_KEY_NAME;
        }
        return modelDescription;
    };
    return FirebaseResourceController;
}(RestApiResourceControllerService));
export { FirebaseResourceController };
