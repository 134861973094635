import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { FieldType, getFieldDescriptionByType } from '@modules/fields';
import { ModelOptionsSource } from '@modules/filters-components';
import { ModelDescriptionStore } from '@modules/model-queries';
import { CurrentEnvironmentStore } from '@modules/projects';
import { ResourceControllerService } from '@modules/resources';
import { controlValue } from '@shared';
import { ListModelDescriptionDataSourceControl } from '../model-description-data-source-edit/list-model-description-data-source';
import { OptionEnabledArray } from './option-enabled.array';
var OptionEnabledEditComponent = /** @class */ (function () {
    function OptionEnabledEditComponent(currentEnvironmentStore, modelDescriptionStore, modelOptionsSource, resourceControllerService, cd) {
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.modelDescriptionStore = modelDescriptionStore;
        this.modelOptionsSource = modelOptionsSource;
        this.resourceControllerService = resourceControllerService;
        this.cd = cd;
        this.onlyEditableAllowed = false;
        this.emptyMessage = 'Nothing found';
        this.fieldItems = [];
        this.relationItems = [];
        this.sections = [];
    }
    OptionEnabledEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        combineLatest(this.dataSourceControl.getModelDescription$().pipe(switchMap(function (modelDescription) {
            if (!modelDescription) {
                return of(undefined);
            }
            return _this.modelOptionsSource.getOptions$(modelDescription, {
                relationsEnabled: true
            });
        })), controlValue(this.dataSourceControl.controls.columns), this.dataSourceControl.getModelDescription$(), this.modelDescriptionStore.get())
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var modelOptions = _a[0], columns = _a[1], modelDescription = _a[2], modelDescriptions = _a[3];
            if (modelOptions) {
                _this.fieldItems = modelOptions
                    .filter(function (item) { return item.field; })
                    .map(function (modelOption) {
                    var editable = true;
                    if (_this.onlyEditableAllowed) {
                        if (modelDescription) {
                            var updateParameters = _this.getUpdateParameters(modelDescription);
                            var field = modelDescription.dbField(modelOption.name);
                            editable =
                                modelDescription.updateQuery &&
                                    modelDescription.updateQuery.isConfigured() &&
                                    field &&
                                    !!updateParameters.find(function (item) { return item.name == modelOption.name; });
                        }
                        else {
                            editable = false;
                        }
                    }
                    return {
                        option: modelOption,
                        control: _this.control.controls.find(function (control) { return control.controls.name.value == modelOption.name; }),
                        editable: editable
                    };
                })
                    .filter(function (item) { return item.control; });
                _this.relationItems = modelOptions
                    .filter(function (item) { return item.relation; })
                    .map(function (modelOption) {
                    var editable = !_this.onlyEditableAllowed;
                    return {
                        option: modelOption,
                        control: _this.control.controls.find(function (control) { return control.controls.name.value == modelOption.name; }),
                        editable: editable
                    };
                })
                    .filter(function (item) { return item.control; });
            }
            else {
                _this.fieldItems = columns
                    .map(function (column) {
                    var fieldDescription = getFieldDescriptionByType(column.field);
                    var relatedModelDescription;
                    // if (column.field == FieldType.RelatedModel && this.relationsEnabled && depth < this.maxRelationsDepth) {
                    if (column.field == FieldType.RelatedModel) {
                        var relatedModelId_1 = column.params ? column.params['related_model']['model'] : undefined;
                        relatedModelDescription = relatedModelId_1
                            ? modelDescriptions.find(function (item) { return item.isSame(relatedModelId_1); })
                            : undefined;
                    }
                    var editable = true;
                    if (_this.onlyEditableAllowed) {
                        if (modelDescription) {
                            var updateParameters = _this.getUpdateParameters(modelDescription);
                            var field = modelDescription.dbField(column.name);
                            editable =
                                modelDescription.updateQuery &&
                                    modelDescription.updateQuery.isConfigured() &&
                                    field &&
                                    !!updateParameters.find(function (item) { return item.name == column.name; });
                        }
                        else {
                            editable = false;
                        }
                    }
                    var modelOption = {
                        name: column.name,
                        verboseName: column.verboseName || column.name,
                        icon: fieldDescription.icon,
                        field: column,
                        relatedModelDescription: relatedModelDescription
                    };
                    return {
                        option: modelOption,
                        control: _this.control.controls.find(function (control) { return control.controls.name.value == column.name; }),
                        editable: editable
                    };
                })
                    .filter(function (item) { return item.control; });
                _this.relationItems = [];
            }
            _this.sections = [
                {
                    items: _this.fieldItems
                },
                {
                    title: 'relationships',
                    items: _this.relationItems
                }
            ].filter(function (item) { return item.items.length; });
            _this.cd.markForCheck();
        });
    };
    OptionEnabledEditComponent.prototype.ngOnDestroy = function () { };
    OptionEnabledEditComponent.prototype.getUpdateParameters = function (modelDescription) {
        var resource = this.currentEnvironmentStore.resources.find(function (item) {
            return item.uniqueName == modelDescription.resource;
        });
        var controller = resource ? this.resourceControllerService.get(resource.type) : undefined;
        return controller ? controller.getUpdateParametersOrDefaults(resource, modelDescription) : [];
    };
    OptionEnabledEditComponent.prototype.onClick = function (item) {
        if (!item.editable) {
            return;
        }
        item.control.controls.enabled.patchValue(!item.control.controls.enabled.value);
    };
    return OptionEnabledEditComponent;
}());
export { OptionEnabledEditComponent };
