<form [formGroup]="control" (ngSubmit)="submit()">
  <app-sidebar-header
    [title]="control.controls.name.value"
    [titleEditable]="true"
    [backEnabled]="true"
    (back)="back()"
    (titleChanged)="control.controls.name.patchValue($event)"
    [deleteEnabled]="deleteEnabled"
    (deleted)="delete.emit()"
  >
  </app-sidebar-header>

  <div class="sidebar__content">
    <ng-container *ngIf="control.controls.type.value == modelDataTypes.Detail">
      <app-sidebar-section>
        <app-model-description-data-source-edit
          [control]="control.controls.detail_data_source"
          [label]="'choose record'"
          [typeOrange]="true"
          [queryOptionEmptyLabel]="'No collections'"
          [context]="context"
          [resourceClasses]="'component-data-resource'"
          [queryOptionClasses]="'component-data-collection'"
          [analyticsSource]="object"
        ></app-model-description-data-source-edit>
      </app-sidebar-section>

      <app-sidebar-section
        *ngIf="
          (control.controls.detail_data_source.controls.type | appControlValue | async) == dataSourceTypes.Input ||
          (control.controls.detail_data_source.controls.type | appControlValue | async) == dataSourceTypes.Workflow ||
          ((control.controls.detail_data_source.controls.type | appControlValue | async) == dataSourceTypes.Query &&
            control.controls.detail_data_source.controls.query.valid)
        "
        [title]="(control.controls.detail_data_source.getQueryEditable$() | async) ? 'Specify Inputs' : 'Apply Filters'"
        [description]="
          (control.controls.detail_data_source.getQueryEditable$() | async)
            ? 'Manually or use value from other components'
            : 'Static or use value from other components'
        "
      >
        <app-data-source-inputs-edit
          [control]="control.controls.detail_data_source"
          [editable]="control.controls.detail_data_source.getQueryEditable$() | async"
          [context]="context"
          [classes]="'component-data-inputs'"
          [itemClasses]="'component-data-inputs-item'"
          [addClasses]="'component-data-inputs-add'"
          [analyticsSource]="object"
          (addInput)="addQueryInput()"
        ></app-data-source-inputs-edit>
      </app-sidebar-section>
    </ng-container>

    <ng-container *ngIf="control.controls.type.value == modelDataTypes.List">
      <app-sidebar-section>
        <app-model-description-data-source-edit
          [control]="control.controls.list_data_source"
          [label]="'choose collection'"
          [typeOrange]="true"
          [queryOptionEmptyLabel]="'No collections'"
          [context]="context"
          [resourceClasses]="'component-data-resource'"
          [queryOptionClasses]="'component-data-collection'"
          [analyticsSource]="object"
        ></app-model-description-data-source-edit>

        <ng-container *ngIf="(dataConfigured$ | async) && (sortableColumnOptions$ | async) as sortableColumnOptions">
          <div *ngIf="sortableColumnOptions.length" class="sidebar__element">
            <app-sidebar-field [label]="'Sorting'" [classes]="'component-data-sorting'">
              <app-select-field
                [form]="control"
                [label]="false"
                [field]="
                  createField({
                    name: 'sorting_field',
                    field: 'SelectField',
                    params: {
                      options: sortableColumnOptions,
                      allow_empty: true,
                      empty_name: 'Not specified',
                      classes: ['select_fill']
                    }
                  })
                "
              >
              </app-select-field>
              <app-field-errors [form]="control" [fieldName]="'sorting_field'"></app-field-errors>
              <app-field-errors [form]="control" [fieldName]="'sorting_asc'"></app-field-errors>

              <ng-container right>
                <a
                  href="javascript:void(0)"
                  class="button button_bigger button_icon button_orange-alternative button_primary sidebar-field__button"
                  [class.button_disabled]="!control.controls.sorting_field.value"
                  [appTip]="'Change sorting order'"
                  (click)="control.toggleDefaultSorting()"
                >
                  <span
                    class="button__icon"
                    [class.icon-filter_down]="control.controls.sorting_asc.value"
                    [class.icon-filter_up]="!control.controls.sorting_asc.value"
                  ></span>
                </a>
              </ng-container>
            </app-sidebar-field>
          </div>
        </ng-container>

        <div class="sidebar__element">
          <app-sidebar-field [label]="'load maximum records'" [additional]="'(optional)'">
            <app-auto-field
              [form]="control"
              [label]="false"
              [field]="
                createField({
                  name: 'per_page',
                  field: 'IntegerField',
                  placeholder: 'Default is 20',
                  params: { classes: ['input_fill'] }
                })
              "
            ></app-auto-field>
          </app-sidebar-field>
        </div>
      </app-sidebar-section>

      <app-sidebar-section
        *ngIf="
          (control.controls.list_data_source.controls.type | appControlValue | async) == dataSourceTypes.Input ||
          (control.controls.list_data_source.controls.type | appControlValue | async) == dataSourceTypes.Workflow ||
          ((control.controls.list_data_source.controls.type | appControlValue | async) == dataSourceTypes.Query &&
            control.controls.list_data_source.controls.query.valid)
        "
        [title]="(control.controls.list_data_source.getQueryEditable$() | async) ? 'Specify Inputs' : 'Apply Filters'"
        [description]="
          (control.controls.list_data_source.getQueryEditable$() | async)
            ? 'Manually or use value from other components'
            : 'Static or use value from other components'
        "
      >
        <app-data-source-inputs-edit
          [control]="control.controls.list_data_source"
          [fieldsControl]="control.controls.list_data_source.controls.columns"
          [editable]="control.controls.list_data_source.getQueryEditable$() | async"
          [context]="context"
          [classes]="'component-data-inputs'"
          [itemClasses]="'component-data-inputs-item'"
          [addClasses]="'component-data-inputs-add'"
          [analyticsSource]="object"
          (addInput)="addQueryInput()"
        ></app-data-source-inputs-edit>
      </app-sidebar-section>
    </ng-container>
  </div>
</form>
