import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import clamp from 'lodash/clamp';
import isEqual from 'lodash/isEqual';
import moment from 'moment';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fromEvent, Observable, Subject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { PopupDynamicComponentArguments, PopupService } from '@common/popups';
import { Task, TaskListStore, TaskPriority, TaskQueue, TaskQueueStore } from '@modules/collaboration';
import { TaskPopupComponent, TaskQueueEditController } from '@modules/collaboration-components';
import { CustomizeService } from '@modules/customize';
import { GlobalContext } from '@modules/customize-utils';
import { CurrentEnvironmentStore, CurrentProjectStore, ProjectUserSelectSource } from '@modules/projects';
import { getWindowScrollTop, isSet } from '@shared';

@Component({
  selector: 'app-collaboration-tasks',
  templateUrl: './collaboration-tasks.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TaskListStore, ProjectUserSelectSource, GlobalContext]
})
export class CollaborationTasksComponent implements OnInit, OnDestroy {
  baseParams = {
    order_by: '-date_add'
  };
  tasks$: Observable<Task[]>;
  tasksLoading$: Observable<boolean>;
  customizeEnabled$: Observable<boolean>;
  form: FormGroup = new FormGroup({
    assigned: new FormControl(null),
    priority: new FormControl(null),
    date_add_gte: new FormControl(),
    date_add_lte: new FormControl(),
    status: new FormControl(null),
    queue: new FormControl(null)
  });
  formatDate = 'DD.MM.YYYY';

  priorityOptions = [
    { name: 'All Priority', value: null },
    { name: 'Lowest', value: TaskPriority.Lowest },
    { name: 'Low', value: TaskPriority.Low },
    { name: 'Medium', value: TaskPriority.Medium },
    { name: 'High', value: TaskPriority.High },
    { name: 'Highest', value: TaskPriority.Highest }
  ];

  selectedItemIndex = undefined;
  closeTaskPopup: Subject<void>;
  taskPopup: PopupDynamicComponentArguments;
  currentQueue: TaskQueue;

  constructor(
    public context: GlobalContext,
    private taskQueueEditController: TaskQueueEditController,
    private popupService: PopupService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private taskListStore: TaskListStore,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    public projectUserSelectSource: ProjectUserSelectSource,
    private customizeService: CustomizeService,
    private taskQueueStore: TaskQueueStore,
    private injector: Injector,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.tasks$ = this.taskListStore.items$;
    this.tasksLoading$ = this.taskListStore.loading$;
    this.customizeEnabled$ = this.customizeService.enabled$.pipe(map(item => !!item));
    this.projectUserSelectSource.allowEmpty = true;
    this.projectUserSelectSource.emptyName = 'All';
    this.projectUserSelectSource.userValue = true;
    this.getTasks();

    this.activatedRoute.queryParams.pipe(untilDestroyed(this)).subscribe(params => {
      const formValue = this.form.value;
      const patchValue = {};

      ['date_add_gte', 'date_add_lte', 'priority', 'assigned', 'queue', 'status'].forEach(name => {
        if (params[name]) {
          patchValue[name] = params[name];
        } else {
          patchValue[name] = null;
        }
      });

      if (!isEqual(formValue, patchValue)) {
        this.form.patchValue({
          ...patchValue
        });
      }
      this.getTasks(params);
    });

    this.activatedRoute.queryParams
      .pipe(
        switchMap(params => this.taskQueueStore.getDetail(params['queue'])),
        untilDestroyed(this)
      )
      .subscribe(queue => {
        this.currentQueue = queue;
        this.cd.markForCheck();
      });

    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
      const params = {};

      ['date_add_gte', 'date_add_lte', 'priority', 'assigned', 'queue', 'status'].forEach(name => {
        if (!isSet(value[name])) {
          if (isSet(params[name])) {
            delete params[name];
          }
        } else if (name === 'date_add_gte') {
          params[name] = moment(value[name]).startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSSZ');
        } else if (name === 'date_add_lte') {
          params[name] = moment(value[name]).endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSSZ');
        } else {
          params[name] = value[name];
        }
      });

      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: params
      });
    });

    fromEvent(window, 'scroll')
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        const scrollTop = getWindowScrollTop();
        const viewportHeight = window.innerHeight;
        const viewportBottom = scrollTop + viewportHeight;
        const contentHeight = document.body.offsetHeight;

        if (contentHeight - viewportBottom <= clamp(viewportHeight, 100, viewportHeight)) {
          this.onScrollFinished();
        }
      });
  }

  ngOnDestroy(): void {}

  onScrollFinished() {
    this.taskListStore.getNext();
  }

  getTasks(params = {}): void {
    this.taskListStore.projectName = this.currentProjectStore.instance.uniqueName;
    this.taskListStore.environmentName = this.currentEnvironmentStore.instance.uniqueName;
    this.taskListStore.params = {
      ...this.baseParams,
      ...params
    };
    this.taskListStore.reset();
    this.taskListStore.getNext();
  }

  activeToggle(item: Task, index: number): void {
    if (this.closeTaskPopup) {
      this.closeTaskPopup.next();
      this.closeTaskPopup = undefined;
    }

    if (index === this.selectedItemIndex) {
      this.selectedItemIndex = undefined;
      this.cd.markForCheck();
    } else {
      this.createPopup(item, index);
      this.selectedItemIndex = index;
      this.cd.markForCheck();
    }
  }

  updateItem(index: number, item: Task) {
    this.taskListStore.items = this.taskListStore.items.map((original, i) => {
      if (i == index) {
        return item;
      } else {
        return original;
      }
    });
    this.cd.markForCheck();
  }

  createPopup(item: Task, index: number): void {
    const closeTaskPopup = new Subject<void>();

    this.taskPopup = this.popupService.push({
      component: TaskPopupComponent,
      disablePointerEvents: true,
      enableWindowScroll: true,
      inputs: {
        itemUid: item.uid,
        closeObs: closeTaskPopup,
        context: this.context
      },
      outputs: {
        updated: [
          result => {
            this.updateItem(index, result);
          }
        ],
        closeObsEvent: [
          () => {
            this.selectedItemIndex = undefined;

            if (this.closeTaskPopup === closeTaskPopup) {
              this.closeTaskPopup = undefined;
            }

            this.cd.markForCheck();
          }
        ]
      },
      injector: this.injector
    });

    this.closeTaskPopup = closeTaskPopup;
  }

  editQueue(queue: TaskQueue) {
    this.taskQueueEditController
      .editTaskQueue(queue, { analyticsSource: 'collaboration_task_queue' })
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        if (result.deleted) {
          this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: {}
          });
        }
      });
  }
}
