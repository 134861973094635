<ng-template let-active="active" #menu_item>
  <ng-container *ngIf="primary">
    <div
      class="menu2-primary-item"
      [class.menu2-primary-item_icon]="!(title | appIsSet)"
      [class.menu2-primary-item_active]="active || forceActive || dropdownOpened"
      (mouseenter)="menuItemHovered$.next(primary_item_origin)"
      (mouseleave)="menuItemHovered$.next(undefined)"
      cdkOverlayOrigin
      #primary_item_origin="cdkOverlayOrigin"
    >
      <div class="menu2-primary-item__inner">
        <div
          *ngIf="icon | appIsSet"
          class="menu2-primary-item__icon"
          [class.menu2-primary-item__icon_left]="icon | appIsSet"
          [class.menu2-primary-item__icon_image]="
            (icon | appIconType) == iconTypes.Emoji || (icon | appIconType) == iconTypes.Image
          "
          [appIcon]="icon"
        ></div>

        <div *ngIf="title | appIsSet" class="menu2-primary-item__label">
          {{ title }}
        </div>

        <div
          *ngIf="displayChildren.length && (title | appIsSet)"
          class="menu2-primary-item__arrow icon-arrow_down_2"
        ></div>
      </div>
    </div>

    <div *ngIf="(title | appIsSet) && !displayChildren.length" class="menu2-primary-item-tip">
      {{ title }}
    </div>
  </ng-container>

  <div
    *ngIf="!primary && !dropdown"
    class="menu2-secondary-item"
    [class.menu2-secondary-item_icon]="!(title | appIsSet)"
    [class.menu2-secondary-item_active]="active || forceActive || dropdownOpened"
    (mouseenter)="menuItemHovered$.next(secondary_item_origin)"
    (mouseleave)="menuItemHovered$.next(undefined)"
    cdkOverlayOrigin
    #secondary_item_origin="cdkOverlayOrigin"
  >
    <div class="menu2-secondary-item__inner">
      <div
        class="menu2-secondary-item__icon"
        [class.menu2-secondary-item__icon_empty]="!(icon | appIsSet)"
        [class.menu2-secondary-item__icon_image]="
          (icon | appIconType) == iconTypes.Emoji || (icon | appIconType) == iconTypes.Image
        "
        [appIcon]="(icon | appIsSet) ? icon : 'circle'"
      ></div>

      <div *ngIf="title | appIsSet" class="menu2-secondary-item__label">
        {{ title }}
      </div>

      <div
        *ngIf="displayChildren.length && (title | appIsSet)"
        class="menu2-secondary-item__arrow icon-arrow_down_2"
      ></div>
    </div>
  </div>

  <ng-container *ngIf="dropdown">
    <div
      class="menu2-dropdown-item"
      [class.menu2-dropdown-item_icon]="!(title | appIsSet)"
      [class.menu2-dropdown-item_active]="active || forceActive || dropdownOpened"
      (mouseenter)="menuItemHovered$.next(dropdown_item_origin)"
      (mouseleave)="menuItemHovered$.next(undefined)"
      cdkOverlayOrigin
      #dropdown_item_origin="cdkOverlayOrigin"
    >
      <div class="menu2-dropdown-item__inner">
        <div *ngIf="icon | appIsSet" class="menu2-dropdown-item__icon" [appIcon]="icon"></div>

        <div *ngIf="title | appIsSet" class="menu2-dropdown-item__label">
          {{ title }}
        </div>

        <div
          *ngIf="displayChildren.length && (title | appIsSet)"
          class="menu2-dropdown-item__arrow icon-arrow_forward_2"
        ></div>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayPanelClass]="['overlay', 'overlay_horizontal_center']"
  [cdkConnectedOverlayPositions]="popoverPositions"
  [cdkConnectedOverlayOrigin]="origin"
  [cdkConnectedOverlayOpen]="dropdownOpened"
  [cdkConnectedOverlayPush]="true"
  [cdkConnectedOverlayGrowAfterOpen]="true"
  [cdkConnectedOverlayHasBackdrop]="false"
  [cdkConnectedOverlayBackdropClass]="'popover2-backdrop'"
  (backdropClick)="closeDropdown()"
  (detach)="closeDropdown()"
>
  <div
    class="popover2 popover2_theme overlay-content overlay-content_animated"
    (mouseenter)="menuDropdownHovered$.next(true)"
    (mouseleave)="menuDropdownHovered$.next(false)"
  >
    <div class="popover2__inner">
      <div class="popover2__viewport popover2__viewport_scrollable" style="min-width: 220px;">
        <div
          class="popover2__content popover2__content_padding_sm menu2-dropdown menu2-block menu2-block_unset"
          appMenuBlockDropdownStyle
        >
          <app-auto-menu-item
            *ngFor="let item of displayChildren; trackBy: trackMenuItemFn"
            [menuItem]="item"
            [menuBlock]="menuBlock"
            [dropdown]="true"
            (dropdownOpen)="onMenuChildDropdownOpened(item)"
            (dropdownClose)="onMenuChildDropdownClosed(item)"
          ></app-auto-menu-item>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<a
  *ngIf="link"
  [routerLink]="link"
  [queryParams]="queryParams"
  [appIsLinkActiveRouterLink]="link"
  [appIsLinkActiveQueryParams]="queryParams"
  #app_is_link_active="appIsLinkActive"
  class="menu2-link menu2-item-wrapper"
  [class.menu2-link_active]="app_is_link_active.active"
  (appIsLinkActiveActivate)="onActivate()"
  (appIsLinkActiveDeactivate)="onDeactivate()"
  (click)="onClick()"
>
  <ng-container *ngTemplateOutlet="menu_item; context: { active: app_is_link_active.active }"></ng-container>
</a>

<a
  *ngIf="!link && (href | appIsSet)"
  [href]="href"
  [appIsLinkActiveHref]="href"
  #app_is_link_active="appIsLinkActive"
  class="menu2-link menu2-item-wrapper"
  [class.menu2-link_active]="app_is_link_active.active"
  (appIsLinkActiveActivate)="onActivate()"
  (appIsLinkActiveDeactivate)="onDeactivate()"
  target="_blank"
  (click)="onClick()"
>
  <ng-container *ngTemplateOutlet="menu_item; context: { active: app_is_link_active.active }"></ng-container>
</a>

<span *ngIf="!link && !(href | appIsSet)" class="menu2-item-wrapper" [class.menu2-link]="handler" (click)="onClick()">
  <ng-container *ngTemplateOutlet="menu_item"></ng-container>
</span>
