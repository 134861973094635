import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

import { environment } from '@env/environment';

// TODO: Refactor imports
import { parseBoolean } from '../../../shared/utils/types/types';

/**
 * Компонент конфигурации
 */
@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
  public production: boolean;
  public environment: string;
  public version: string;
  public webBaseUrl: string;
  public subdomainsBaseDomain: string;
  public serverBaseUrl: string;
  public serverNodeBaseUrl: string;
  public serverNodeDirectBaseUrl: string;
  public dataSyncBaseUrl: string;
  public workflowsBaseUrl: string;
  public dataSourcesBaseUrl: string;
  public dataSourcesDirectBaseUrl: string;
  public jetBridgeCloudBaseUrl: string;
  public jetBridgeRegions = false;
  public deploymentTypeEnabled = false;
  public centrifugoUrl: string;
  public googleSheetsAppId: string;
  public googleSheetsApiKey: string;
  public airtableApiKey: string;
  public paddleVendorId: number;
  public paddleSandbox = false;
  public socialAuthFacebookEnabled = false;
  public socialAuthGoogleEnabled = false;
  public defaultEmailFrom: string;
  public googleTagManager: string;
  public snapshotsEnabled = false;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    this.production = environment.production;
    this.environment = environment.environment;
    this.version = environment.version;
    this.webBaseUrl = environment.webBaseUrl;
    this.subdomainsBaseDomain = environment.subdomainsBaseDomain;
    this.serverBaseUrl = environment.serverBaseUrl;
    this.serverNodeBaseUrl = environment.serverNodeBaseUrl;
    this.serverNodeDirectBaseUrl = environment.serverNodeDirectBaseUrl;
    this.dataSyncBaseUrl = environment.dataSyncBaseUrl;
    this.workflowsBaseUrl = environment.workflowsBaseUrl;
    this.dataSourcesBaseUrl = environment.dataSourcesBaseUrl;
    this.dataSourcesDirectBaseUrl = environment.dataSourcesDirectBaseUrl;
    this.jetBridgeCloudBaseUrl = environment.jetBridgeCloudBaseUrl;
    this.jetBridgeRegions = parseBoolean(environment.jetBridgeRegions);
    this.deploymentTypeEnabled = parseBoolean(environment.deploymentTypeEnabled);
    this.centrifugoUrl = environment.centrifugoUrl;
    this.googleSheetsAppId = environment.googleSheetsAppId;
    this.googleSheetsApiKey = environment.googleSheetsApiKey;
    this.airtableApiKey = environment.airtableApiKey;
    this.paddleVendorId = parseInt(environment.paddleVendorId, 10);
    this.paddleSandbox = parseBoolean(environment.paddleSandbox);
    this.socialAuthFacebookEnabled = parseBoolean(environment.socialAuthFacebookEnabled);
    this.socialAuthGoogleEnabled = parseBoolean(environment.socialAuthGoogleEnabled);
    this.defaultEmailFrom = environment.defaultEmailFrom || 'no-reply@jetadmin.app';
    this.googleTagManager = environment.googleTagManager;
    this.snapshotsEnabled = parseBoolean(environment.snapshotsEnabled);
    window['environment'] = {
      ...environment
    };
  }

  isPlatformServer() {
    return isPlatformServer(this.platformId);
  }

  getMediaBaseUrl(): string {
    const project = window['project'];
    if (!project) {
      return;
    }

    return `${this.serverBaseUrl}/media/static_files/projects/${project}`;
  }
}
