<form [formGroup]="resourceForm" (ngSubmit)="submit()">
  <app-customize-bar-header [title]="field ? 'Editing ' + field.verboseName : 'Creating Column'">
  </app-customize-bar-header>

  <div class="sidebar__content">
    <app-sidebar-tabs>
      <app-sidebar-tab [label]="'General'">
        <app-sidebar-section>
          <div class="sidebar__element">
            <app-sidebar-field [label]="'type'">
              <ng-container actions>
                <span
                  class="sidebar__field-action sidebar__field-action_button icon-gear_2"
                  [class.sidebar__field-action_active]="resourceForm.controls.db_field_enabled.value"
                  [class.sidebar__field-action_bright-active]="false"
                  [appTip]="'Database specific types'"
                  [appTipOptions]="{ side: 'left' }"
                  (click)="
                    resourceForm.controls.db_field_enabled.setValue(!resourceForm.controls.db_field_enabled.value)
                  "
                ></span>
              </ng-container>

              <app-field-type-field
                *ngIf="resourceForm.controls.db_field_enabled.value"
                [form]="resourceForm"
                [label]="false"
                [field]="createField({ name: 'db_field', params: { classes: ['select_fill'] } })"
                [fieldTypeSections]="resourceForm.dbFieldSections"
              ></app-field-type-field>

              <app-field-type-field
                *ngIf="!resourceForm.controls.db_field_enabled.value"
                [form]="resourceForm"
                [label]="false"
                [field]="createField({ name: 'field', params: { classes: ['select_fill'] } })"
              ></app-field-type-field>
            </app-sidebar-field>
          </div>

          <ng-container *ngIf="resourceForm.controls.field.value == fieldTypes.RelatedModel">
            <div class="sidebar__element">
              <app-sidebar-field [label]="'Related table'">
                <app-custom-select
                  [control]="resourceForm.controls.related_model"
                  [items]="resourceModelItems$ | async"
                  [emptyLabel]="'No collections'"
                  [classes]="['select_fill']"
                  [panelClasses]="['mat-menu-panel_sidebar']"
                >
                </app-custom-select>
                <app-field-errors [form]="resourceForm" [fieldName]="'related_model'"></app-field-errors>
              </app-sidebar-field>
            </div>

            <!--          <div class="sidebar__element">-->
            <!--            <app-sidebar-field [label]="'related field'">-->
            <!--              <app-select-->
            <!--                [control]="form.controls['custom_primary_key']"-->
            <!--                [options]="form.modelDbFieldOptions"-->
            <!--              ></app-select>-->
            <!--              <app-field-errors [form]="form.form" [fieldName]="'custom_primary_key'"></app-field-errors>-->
            <!--            </app-sidebar-field>-->
            <!--          </div>-->
          </ng-container>

          <ng-container *ngIf="!resourceForm.controls.name_enabled.value">
            <div class="sidebar__element">
              <app-sidebar-field [label]="'name'">
                <ng-container actions>
                  <span
                    class="sidebar__field-action sidebar__field-action_button icon-gear_2"
                    [class.sidebar__field-action_active]="resourceForm.controls.name_enabled.value"
                    [class.sidebar__field-action_bright-active]="false"
                    [appTip]="'Database internal name'"
                    [appTipOptions]="{ side: 'left' }"
                    (click)="resourceForm.controls.name_enabled.setValue(!resourceForm.controls.name_enabled.value)"
                  ></span>
                </ng-container>

                <app-auto-field
                  [form]="overrideForm"
                  [label]="false"
                  [autofocus]="true"
                  [field]="
                    createField({
                      name: 'verbose_name',
                      field: 'CharField',
                      placeholder: 'First name, Created, etc.',
                      params: { classes: ['input_fill'] }
                    })
                  "
                ></app-auto-field>
              </app-sidebar-field>
            </div>
          </ng-container>

          <ng-container *ngIf="resourceForm.controls.name_enabled.value">
            <div class="sidebar__element">
              <app-sidebar-field [label]="'name'">
                <ng-container actions>
                  <span
                    class="sidebar__field-action sidebar__field-action_button icon-gear_2"
                    [class.sidebar__field-action_active]="resourceForm.controls.name_enabled.value"
                    [class.sidebar__field-action_bright-active]="false"
                    [appTip]="'Database internal name'"
                    [appTipOptions]="{ side: 'left' }"
                    (click)="resourceForm.controls.name_enabled.setValue(!resourceForm.controls.name_enabled.value)"
                  ></span>
                </ng-container>

                <app-sidebar-card>
                  <div class="sidebar__element">
                    <app-sidebar-field>
                      <app-auto-field
                        [form]="resourceForm"
                        [label]="false"
                        [autofocus]="true"
                        [field]="
                          createField({
                            name: 'name',
                            field: 'CharField',
                            placeholder: 'first_name, date_created, etc.',
                            params: { classes: ['input_fill'] }
                          })
                        "
                      ></app-auto-field>

                      <ng-container description>
                        How field in stored in database
                      </ng-container>
                    </app-sidebar-field>
                  </div>

                  <div class="sidebar__element">
                    <app-sidebar-field>
                      <app-auto-field
                        [form]="overrideForm"
                        [label]="false"
                        [field]="
                          createField({
                            name: 'verbose_name',
                            field: 'CharField',
                            placeholder: 'First name, Created, etc.',
                            params: { classes: ['input_fill'] }
                          })
                        "
                      ></app-auto-field>

                      <ng-container description>
                        How to display field name in interface
                      </ng-container>
                    </app-sidebar-field>
                  </div>
                </app-sidebar-card>
              </app-sidebar-field>
            </div>
          </ng-container>

          <div class="sidebar__element">
            <app-sidebar-field>
              <div class="sidebar-icon-buttons sidebar-icon-buttons_border">
                <div
                  class="sidebar-icon-button"
                  [class.sidebar-icon-button_active]="!resourceForm.controls.null.value"
                  (click)="resourceForm.controls.null.setValue(false)"
                >
                  <div class="sidebar-icon-button__label">Non NULL</div>
                </div>
                <div
                  class="sidebar-icon-button"
                  [class.sidebar-icon-button_active]="resourceForm.controls.null.value"
                  (click)="resourceForm.controls.null.setValue(true)"
                >
                  <div class="sidebar-icon-button__label">Allow NULL</div>
                </div>
              </div>
            </app-sidebar-field>
          </div>

          <div class="sidebar__element">
            <app-sidebar-field [label]="'default value'">
              <app-auto-field
                [form]="resourceForm"
                [label]="false"
                [field]="
                  createField({
                    name: 'default_type',
                    field: 'SelectField',
                    placeholder: 'None',
                    params: {
                      options: defaultTypeOptions$ | async,
                      classes: [
                        'select_fill',
                        resourceForm.controls.default_type.value == defaultTypes.Value ? 'select_segment-top' : ''
                      ]
                    }
                  })
                "
              ></app-auto-field>

              <ng-container
                *ngIf="
                  !isPrimaryKey() &&
                  resourceForm.defaultTypesValueInfo[resourceForm.controls.default_type.value] as valueInfo
                "
              >
                <ng-container
                  *ngIf="valueInfo.fieldType ? resourceForm.controls.field.value : valueInfo.field as fieldType"
                >
                  <app-auto-field
                    *ngIf="fieldType == fieldTypes.Boolean"
                    [form]="resourceForm"
                    [label]="false"
                    [field]="
                      createField({
                        name: 'default_value',
                        field: fieldTypes.Select,
                        placeholder: 'Specify value',
                        params: {
                          options: [
                            { value: false, name: 'False' },
                            { value: true, name: 'True' }
                          ],
                          classes: ['input_fill', 'select_fill', 'input_segment-bottom', 'select_segment-bottom']
                        }
                      })
                    "
                  ></app-auto-field>

                  <app-auto-field
                    *ngIf="fieldType != fieldTypes.Boolean"
                    [form]="resourceForm"
                    [label]="false"
                    [field]="
                      createField({
                        name: 'default_value',
                        field: fieldType,
                        placeholder: 'Specify value',
                        params: defaultValueParams
                      })
                    "
                  ></app-auto-field>
                </ng-container>
              </ng-container>
            </app-sidebar-field>
          </div>

          <div *ngIf="resourceForm.controls.field.value == fieldTypes.Text" class="sidebar__element">
            <app-sidebar-field [label]="'column length'">
              <app-auto-field
                [form]="resourceForm"
                [label]="false"
                [field]="
                  createField({
                    name: 'length',
                    field: 'IntegerField',
                    placeholder: 'i.e. 255',
                    params: { classes: ['input_fill'] }
                  })
                "
              ></app-auto-field>
            </app-sidebar-field>
          </div>
        </app-sidebar-section>
      </app-sidebar-tab>

      <app-sidebar-tab [label]="'Display'" [formGroup]="overrideForm">
        <app-sidebar-section>
          <div class="sidebar__element">
            <app-sidebar-field [label]="'mode'">
              <div class="sidebar-icon-buttons sidebar-icon-buttons_border">
                <div
                  class="sidebar-icon-button"
                  [class.sidebar-icon-button_active]="overrideForm.controls.editable.value"
                  (click)="overrideForm.controls.editable.setValue(true)"
                >
                  <div class="sidebar-icon-button__label">Editable</div>
                </div>
                <div
                  class="sidebar-icon-button"
                  [class.sidebar-icon-button_active]="!overrideForm.controls.editable.value"
                  (click)="overrideForm.controls.editable.setValue(false)"
                >
                  <div class="sidebar-icon-button__label">View-only</div>
                </div>
              </div>
            </app-sidebar-field>
          </div>

          <div *ngIf="dataParamsComponentData || viewParamsComponentData" class="sidebar-section-border"></div>

          <app-dynamic-component *ngIf="dataParamsComponentData" [componentData]="dataParamsComponentData">
          </app-dynamic-component>
          <app-dynamic-component *ngIf="viewParamsComponentData" [componentData]="viewParamsComponentData">
          </app-dynamic-component>

          <div class="sidebar__element">
            <app-sidebar-field [label]="'placeholder'">
              <app-auto-field
                [form]="overrideForm"
                [label]="false"
                [field]="
                  createField({
                    name: 'placeholder',
                    field: 'CharField',
                    placeholder: 'Field description',
                    params: { classes: ['input_fill'] }
                  })
                "
              ></app-auto-field>
            </app-sidebar-field>
          </div>

          <ng-container *ngIf="overrideForm.value['editable']">
            <div class="sidebar__element">
              <app-sidebar-field [label]="'required'" [layoutLeft]="true">
                <app-auto-field
                  [form]="overrideForm"
                  [label]="false"
                  [field]="
                    createField({
                      name: 'required',
                      label: 'required',
                      field: 'BooleanField',
                      params: { style: booleanFieldStyle.Toggle, orange: true }
                    })
                  "
                ></app-auto-field>
              </app-sidebar-field>
            </div>

            <div class="sidebar__element">
              <app-sidebar-field
                *ngIf="overrideForm.isResetEnabledSupported()"
                [label]="'show clear button'"
                [layoutLeft]="true"
              >
                <app-auto-field
                  [form]="overrideForm"
                  [label]="false"
                  [field]="
                    createField({
                      name: 'reset_enabled',
                      field: 'BooleanField',
                      params: { style: booleanFieldStyle.Toggle, orange: true }
                    })
                  "
                ></app-auto-field>
              </app-sidebar-field>
            </div>

            <div class="sidebar__element">
              <app-sidebar-field [label]="'Validate'">
                <app-custom-select
                  [control]="overrideForm.controls.validator_type"
                  [items]="overrideForm.validatorOptions"
                  [classes]="[
                    'select_fill',
                    overrideForm.isValidatorTypeHasOption(overrideForm.value['validator_type'])
                      ? 'select_segment-top'
                      : ''
                  ]"
                  [panelClasses]="['mat-menu-panel_sidebar']"
                  [allowSearch]="false"
                >
                </app-custom-select>

                <app-auto-field
                  *ngIf="overrideForm.value['validator_type'] === validatorTypes.Length"
                  [form]="overrideForm"
                  [label]="false"
                  [field]="
                    createField({
                      name: 'validator_length',
                      field: 'CharField',
                      placeholder: 'Length',
                      params: { classes: ['input_fill', 'input_segment-bottom'] }
                    })
                  "
                ></app-auto-field>

                <app-auto-field
                  *ngIf="overrideForm.value['validator_type'] === validatorTypes.MaxLength"
                  [form]="overrideForm"
                  [label]="false"
                  [field]="
                    createField({
                      name: 'validator_max_length',
                      field: 'CharField',
                      placeholder: 'Max length',
                      params: { classes: ['input_fill', 'input_segment-bottom'] }
                    })
                  "
                ></app-auto-field>

                <app-auto-field
                  *ngIf="overrideForm.value['validator_type'] === validatorTypes.MinLength"
                  [form]="overrideForm"
                  [label]="false"
                  [field]="
                    createField({
                      name: 'validator_min_length',
                      field: 'CharField',
                      placeholder: 'Min length',
                      params: { classes: ['input_fill', 'input_segment-bottom'] }
                    })
                  "
                ></app-auto-field>

                <ng-container *ngIf="overrideForm.value['validator_type'] === validatorTypes.LengthRange">
                  <div style="display: flex; align-items: flex-start;">
                    <input
                      type="text"
                      [formControl]="overrideForm.controls.validator_min_length"
                      class="input input_fill input_segment-bottom-left"
                      placeholder="Min length"
                    />
                    <input
                      type="text"
                      [formControl]="overrideForm.controls.validator_max_length"
                      class="input input_fill input_segment-bottom-right"
                      placeholder="Max length"
                    />
                  </div>
                  <app-field-errors [form]="overrideForm" [fieldName]="'validator_min_length'"></app-field-errors>
                  <app-field-errors
                    *ngIf="overrideForm.controls.validator_min_length.valid"
                    [form]="overrideForm"
                    [fieldName]="'validator_max_length'"
                  ></app-field-errors>
                </ng-container>

                <app-auto-field
                  *ngIf="overrideForm.value['validator_type'] === validatorTypes.Custom"
                  [form]="overrideForm"
                  [label]="false"
                  [field]="
                    createField({
                      name: 'validator_custom',
                      field: 'CharField',
                      placeholder: 'Regular expression',
                      params: { classes: ['input_fill', 'input_segment-bottom'] }
                    })
                  "
                ></app-auto-field>
              </app-sidebar-field>

              <app-input-edit
                *ngIf="overrideForm.value['validator_type'] === validatorTypes.CustomInput"
                [itemForm]="overrideForm.controls.validator_custom_input"
                [context]="context"
                [contextElement]="contextElement"
                [staticValueDisabled]="true"
                [formulaPlaceholder]="'value > 3'"
                [jsPlaceholder]="'return value > 3;'"
                [fill]="true"
                [classes]="['code-editor__main-code_field-segment-bottom']"
                [analyticsSource]="analyticsSource + '_validator'"
              ></app-input-edit>
            </div>

            <div *ngIf="overrideForm.value['validator_type'] === validatorTypes.Custom" class="sidebar__element">
              <app-sidebar-field [label]="'Custom validation error'">
                <app-auto-field
                  [form]="overrideForm"
                  [label]="false"
                  [field]="
                    createField({
                      name: 'validator_error',
                      field: 'CharField',
                      placeholder: 'Error message',
                      params: { classes: ['input_fill'] }
                    })
                  "
                ></app-auto-field>
              </app-sidebar-field>
            </div>
          </ng-container>
        </app-sidebar-section>
      </app-sidebar-tab>
    </app-sidebar-tabs>
  </div>

  <app-sidebar-submit
    [saveLabel]="field ? 'Save' : 'Create'"
    [saveDisabled]="resourceForm.invalid || resourceForm.disabled"
    [saveLoading]="loading"
    [errors]="resourceForm | appFormErrors"
    [description]="submit_description"
    (cancelClick)="cancel()"
  >
  </app-sidebar-submit>

  <ng-template #submit_description>
    Changes to Jet Tables structure are immediate and do not support a discard operation
  </ng-template>
</form>
