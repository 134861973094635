import { isSet } from '../common/common';
import { stripStart } from '../text/text';

export function isAbsoluteUrl(url: string) {
  if (!isSet(url)) {
    return false;
  }

  return ['http:', 'https:', 'ftp;', 'file:', '//'].some(item => String(url).toLowerCase().startsWith(item));
}

export function parseLocationFragment(fragment: string): { key: string; value: string }[] {
  if (!isSet(fragment)) {
    return [];
  }

  fragment = stripStart(fragment, '#');

  return fragment.split('&').map(item => {
    const parts = item.split('=', 2);
    return {
      key: parts[0],
      value: parts[1]
    };
  });
}
