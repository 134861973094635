import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output
} from '@angular/core';
import { AbstractControl } from '@angular/forms';

import { BasePopupComponent } from '@common/popups';
import { ResourceName, ResourceTypeItem } from '@modules/projects';
import { FirebaseParamsOptions } from '@modules/resource-generators';

@Component({
  selector: 'app-choose-sync-mode',
  templateUrl: './choose-sync-mode.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChooseSyncModeComponent implements OnInit, OnDestroy {
  @Input() control: AbstractControl;
  @Input() typeItem: ResourceTypeItem;
  @Input() paramsOptions: Object;
  @Output() chosen = new EventEmitter<{ paramsOptions?: Object }>();

  additionalStep = false;
  resourceNames = ResourceName;

  constructor(@Optional() protected popupComponent: BasePopupComponent, private cd: ChangeDetectorRef) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  submit(options: { paramsOptions?: Object } = {}) {
    this.chosen.emit(options);
    this.close();
  }

  close() {
    if (this.popupComponent) {
      this.popupComponent.close();
    }
  }

  setAdditionalStep(value: boolean) {
    this.additionalStep = value;
    this.cd.markForCheck();
  }

  asFirebaseParamsOptions(options: Object): FirebaseParamsOptions {
    return options as FirebaseParamsOptions;
  }
}
