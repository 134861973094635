import { OnDestroy, OnInit } from '@angular/core';
import { StylesEditComponent, StyleType } from '../styles-edit/styles-edit.component';
import { ActionElementStylesControl } from './action-element-styles.control';
var StylesActionElementEditComponent = /** @class */ (function () {
    function StylesActionElementEditComponent() {
        this.marginEnabled = true;
        this.styles = [];
    }
    StylesActionElementEditComponent.prototype.ngOnInit = function () {
        this.updateStyles();
    };
    StylesActionElementEditComponent.prototype.ngOnDestroy = function () { };
    StylesActionElementEditComponent.prototype.updateStyles = function () {
        this.styles = [
            {
                style: {
                    label: 'Text',
                    icon: 'text',
                    type: StyleType.TextStyle,
                    control: this.control.controls.text_style,
                    isSet$: function (control) { return control.isSet$(); },
                    onRemove: function (style) { return style.control.resetDefaults(); }
                }
            },
            {
                style: {
                    label: 'Icon',
                    icon: 'text',
                    type: StyleType.IconSettings,
                    control: this.control.controls.icon_settings,
                    isSet$: function (control) { return control.isSet$(); },
                    onRemove: function (style) { return style.control.reset(); }
                }
            },
            {
                style: {
                    label: 'Background',
                    icon: 'palette',
                    type: StyleType.Fill,
                    control: this.control.controls.fill_settings,
                    isSet$: function (control) { return control.isSet$(); },
                    onAdd: function (style) { return style.control.resetDefaults(); },
                    onRemove: function (style) { return style.control.reset(); }
                }
            },
            {
                style: {
                    label: 'Border',
                    icon: 'sides',
                    type: StyleType.BorderSettings,
                    control: this.control.controls.border_settings,
                    isSet$: function (control) { return control.isSet$(); },
                    onAdd: function (style) { return style.control.resetDefaults(); },
                    onRemove: function (style) { return style.control.reset(); }
                }
            },
            {
                style: {
                    label: 'Corner radius',
                    icon: 'corners',
                    type: StyleType.Corners,
                    control: this.control.controls.border_radius,
                    isSet$: function (control) { return control.isSet$(); },
                    onAdd: function (style) { return style.control.resetDefaults(); },
                    onRemove: function (style) { return style.control.reset(); }
                }
            },
            {
                style: {
                    label: 'Shadow',
                    icon: 'duplicate_2',
                    type: StyleType.Shadow,
                    control: this.control.controls.shadow,
                    isSet$: function (control) { return control.isSet$(); },
                    onAdd: function (style) { return style.control.resetDefaults(); },
                    onRemove: function (style) { return style.control.reset(); }
                }
            },
            {
                style: {
                    label: 'Inner spacing',
                    icon: 'align_horizontal_fill',
                    type: StyleType.Margin,
                    control: this.control.controls.padding,
                    isSet$: function (control) { return control.isSet$(); },
                    onRemove: function (style) { return style.control.reset(); }
                }
            }
        ].concat((this.marginEnabled
            ? [
                {
                    style: {
                        label: 'Outer spacing',
                        icon: 'align_horizontal_center',
                        type: StyleType.Margin,
                        control: this.control.controls.margin,
                        isSet$: function (control) { return control.isSet$(); },
                        onRemove: function (style) { return style.control.reset(); }
                    }
                }
            ]
            : []), [
            {
                label: 'On hover',
                icon: 'cursor',
                children: [
                    {
                        label: 'Text',
                        additional: '(on hover)',
                        icon: 'text',
                        type: StyleType.TextStyle,
                        control: this.control.controls.hover_text_style,
                        isSet$: function (control) { return control.isSet$(); },
                        onRemove: function (style) { return style.control.resetDefaults(); }
                    },
                    {
                        label: 'Icon',
                        icon: 'text',
                        type: StyleType.IconSettings,
                        control: this.control.controls.hover_icon_settings,
                        isSet$: function (control) { return control.isSet$(); },
                        onRemove: function (style) { return style.control.reset(); }
                    },
                    {
                        label: 'Background',
                        additional: '(on hover)',
                        icon: 'palette',
                        type: StyleType.Fill,
                        control: this.control.controls.hover_fill_settings,
                        isSet$: function (control) { return control.isSet$(); },
                        onAdd: function (style) { return style.control.resetDefaults(); },
                        onRemove: function (style) { return style.control.reset(); }
                    },
                    {
                        label: 'Border',
                        additional: '(on hover)',
                        icon: 'sides',
                        type: StyleType.BorderSettings,
                        control: this.control.controls.hover_border_settings,
                        isSet$: function (control) { return control.isSet$(); },
                        onAdd: function (style) { return style.control.resetDefaults(); },
                        onRemove: function (style) { return style.control.reset(); }
                    },
                    {
                        label: 'Shadow',
                        additional: '(on hover)',
                        icon: 'duplicate_2',
                        type: StyleType.Shadow,
                        control: this.control.controls.hover_shadow,
                        isSet$: function (control) { return control.isSet$(); },
                        onAdd: function (style) { return style.control.resetDefaults(); },
                        onRemove: function (style) { return style.control.reset(); }
                    }
                ]
            },
            {
                label: 'On pressed',
                icon: 'select_all',
                children: [
                    {
                        label: 'Text',
                        additional: '(on pressed)',
                        icon: 'text',
                        type: StyleType.TextStyle,
                        control: this.control.controls.active_text_style,
                        isSet$: function (control) { return control.isSet$(); },
                        onRemove: function (style) { return style.control.resetDefaults(); }
                    },
                    {
                        label: 'Icon',
                        icon: 'text',
                        type: StyleType.IconSettings,
                        control: this.control.controls.active_icon_settings,
                        isSet$: function (control) { return control.isSet$(); },
                        onRemove: function (style) { return style.control.reset(); }
                    },
                    {
                        label: 'Background',
                        additional: '(on pressed)',
                        icon: 'palette',
                        type: StyleType.Fill,
                        control: this.control.controls.active_fill_settings,
                        isSet$: function (control) { return control.isSet$(); },
                        onAdd: function (style) { return style.control.resetDefaults(); },
                        onRemove: function (style) { return style.control.reset(); }
                    },
                    {
                        label: 'Border',
                        additional: '(on pressed)',
                        icon: 'sides',
                        type: StyleType.BorderSettings,
                        control: this.control.controls.active_border_settings,
                        isSet$: function (control) { return control.isSet$(); },
                        onAdd: function (style) { return style.control.resetDefaults(); },
                        onRemove: function (style) { return style.control.reset(); }
                    },
                    {
                        label: 'Shadow',
                        additional: '(on pressed)',
                        icon: 'duplicate_2',
                        type: StyleType.Shadow,
                        control: this.control.controls.active_shadow,
                        isSet$: function (control) { return control.isSet$(); },
                        onAdd: function (style) { return style.control.resetDefaults(); },
                        onRemove: function (style) { return style.control.reset(); }
                    }
                ]
            }
        ]);
    };
    return StylesActionElementEditComponent;
}());
export { StylesActionElementEditComponent };
