/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../text-style-edit-overlay/text-style-edit-overlay.component.ngfactory";
import * as i2 from "../text-style-edit-overlay/text-style-edit-overlay.component";
import * as i3 from "@angular/cdk/overlay";
import * as i4 from "../../../../common/tips/directives/tip/tip.directive";
import * as i5 from "@angular/common";
import * as i6 from "./text-style-edit-field-action.component";
var styles_TextStyleEditFieldActionComponent = [];
var RenderType_TextStyleEditFieldActionComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TextStyleEditFieldActionComponent, data: {} });
export { RenderType_TextStyleEditFieldActionComponent as RenderType_TextStyleEditFieldActionComponent };
export function View_TextStyleEditFieldActionComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-text-style-edit-overlay", [], null, null, null, i1.View_TextStyleEditOverlayComponent_0, i1.RenderType_TextStyleEditOverlayComponent)), i0.ɵdid(1, 4440064, [["text_style_edit", 4]], 0, i2.TextStyleEditOverlayComponent, [i0.ChangeDetectorRef], { control: [0, "control"], origin: [1, "origin"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 4, "span", [["cdkOverlayOrigin", ""], ["class", "sidebar__field-action sidebar__field-action_button icon-text_2"]], [[2, "sidebar__field-action_active", null], [2, "sidebar__field-action_disabled", null]], null, null, null, null)), i0.ɵdid(3, 16384, [["origin", 4]], 0, i3.CdkOverlayOrigin, [i0.ElementRef], null, null), i0.ɵdid(4, 737280, null, 0, i4.TipDirective, [i0.ElementRef, i0.ComponentFactoryResolver, i0.Injector, i0.ApplicationRef], { content: [0, "content"], optionsRaw: [1, "optionsRaw"] }, null), i0.ɵpod(5, { side: 0 }), i0.ɵpid(131072, i5.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.control; var currVal_1 = i0.ɵnov(_v, 3); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_4 = _co.tip; var currVal_5 = _ck(_v, 5, 0, "top"); _ck(_v, 4, 0, currVal_4, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = (i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 6).transform(_co.styleIsDefault)) === false); var currVal_3 = i0.ɵnov(_v, 1).isOpened(); _ck(_v, 2, 0, currVal_2, currVal_3); }); }
export function View_TextStyleEditFieldActionComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-text-style-edit-field-action", [], null, null, null, View_TextStyleEditFieldActionComponent_0, RenderType_TextStyleEditFieldActionComponent)), i0.ɵdid(1, 114688, null, 0, i6.TextStyleEditFieldActionComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TextStyleEditFieldActionComponentNgFactory = i0.ɵccf("app-text-style-edit-field-action", i6.TextStyleEditFieldActionComponent, View_TextStyleEditFieldActionComponent_Host_0, { control: "control", tip: "tip" }, {}, []);
export { TextStyleEditFieldActionComponentNgFactory as TextStyleEditFieldActionComponentNgFactory };
