import { Injector } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AllProjectSettings, ProjectSettingsStore } from '@modules/all-project-settings';
import { Option } from '@modules/field-components';
import { FontStyle, FontType, fontTypeOptions, getFontFamilyVariable } from '@modules/styles';
import { fonts, headingFontName, regularFontName } from '@modules/theme';
import { Font } from '@modules/views';
import { controlValue, isSet } from '@shared';

export class FontControl extends FormGroup {
  instance: Font;

  controls: {
    family: FormControl;
    type: FormControl;
    size: FormControl;
  };

  constructor(private projectSettingsStore: ProjectSettingsStore, state: Partial<Font> = {}) {
    super({
      family: new FormControl(isSet(state.family) ? state.family : regularFontName),
      type: new FormControl({
        weight: 400,
        style: FontStyle.Normal,
        ...(isSet(state.weight) && { weight: state.weight }),
        ...(isSet(state.style) && { style: state.style })
      }),
      size: new FormControl(isSet(state.size) ? state.size : 14)
    });
  }

  static inject(injector: Injector, state: Partial<Font> = {}): FontControl {
    return Injector.create({
      providers: [
        {
          provide: FontControl,
          useFactory: (projectSettingsStore: ProjectSettingsStore) => {
            return new FontControl(projectSettingsStore, state);
          },
          deps: [ProjectSettingsStore]
        }
      ],
      parent: injector
    }).get(FontControl);
  }

  fontTypeEquals = (lhs: FontType, rhs: FontType) => {
    if (lhs && rhs) {
      return lhs.weight == rhs.weight && lhs.style == rhs.style;
    } else {
      return lhs == rhs;
    }
  };

  deserialize(value: Font, options: { emitEvent?: boolean } = {}) {
    this.instance = value;

    if (isSet(value.family)) {
      this.controls.family.patchValue(value.family, { emitEvent: options.emitEvent });
    }

    if (isSet(value.weight)) {
      this.controls.type.patchValue({ weight: value.weight, style: value.style }, { emitEvent: options.emitEvent });
    }

    this.controls.size.patchValue(value.size, { emitEvent: options.emitEvent });
  }

  getFontTypeOptionsForValue(fontFamily: string, projectSettings: AllProjectSettings): Option<FontType>[] {
    const fontFamilyVariable = getFontFamilyVariable(fontFamily);

    if (fontFamilyVariable == regularFontName) {
      fontFamily = projectSettings.fontRegular;
    } else if (fontFamilyVariable == headingFontName) {
      fontFamily = projectSettings.fontHeading;
    }

    const font = fonts.find(item => item.name == fontFamily);

    return fontTypeOptions.filter(item => !font || font.weights.includes(item.value.weight));
  }

  getFontTypeOptions$(): Observable<Option<FontType>[]> {
    return combineLatest(controlValue(this.controls.family), this.projectSettingsStore.getAllSettings$()).pipe(
      map(([fontFamily, projectSettings]) => this.getFontTypeOptionsForValue(fontFamily, projectSettings))
    );
  }

  getInstance(instance?: Font): Font {
    if (!instance) {
      instance = new Font();
    }

    instance.family = this.controls.family.value;

    if (this.controls.type.value) {
      instance.weight = this.controls.type.value.weight;
      instance.style = this.controls.type.value.style;
    }

    instance.size = this.controls.size.value;

    return instance;
  }

  serialize(): Font {
    return this.getInstance(this.instance);
  }
}
