import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Provider
} from '@angular/core';
import defaults from 'lodash/defaults';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable, of } from 'rxjs';
import { debounceTime, first, map, switchMap } from 'rxjs/operators';

import { FormUtils } from '@common/form-utils';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import {
  ActionElementItem,
  cleanElementName,
  SUBMIT_RESULT_OUTPUT,
  ViewContext,
  ViewContextElement
} from '@modules/customize';
import { FeatureService } from '@modules/features';
import { createFormFieldFactory } from '@modules/fields';
import { CurrentProjectStore } from '@modules/projects';
import { SidebarCollapseContext } from '@modules/sidebar';

import { CustomizeBarEditComponent } from '../../data/customize-bar-edit-component';
import { CustomizeBarEditEvent } from '../../data/customize-bar-edit-event';
import { CustomizeBarEditEventType } from '../../data/customize-bar-edit-event-type';
import { CustomizeBarContext } from '../../services/customize-bar-context/customize-bar.context';
import { CUSTOMIZE_ACTION_COMPONENT, CustomizeBarService } from '../../services/customize-bar/customize-bar.service';
import { ElementConfiguration, trackConfigured } from '../../utils/analytics';
import { CustomizeBarActionEditOperationComponent } from './customize-bar-action-edit-operation/customize-bar-action-edit-operation.component';
import {
  CustomizeActionOptions,
  CustomizeActionResult,
  CustomizeBarActionEditForm,
  ModelDescriptionInContext
} from './customize-bar-action-edit.form';
import { CUSTOMIZE_BAR_ACTION_EDIT_FORM_PROVIDER } from './customize-bar-action-edit.provider';

export const CUSTOMIZE_ACTION_COMPONENT_PROVIDER: Provider = {
  provide: CUSTOMIZE_ACTION_COMPONENT,
  useFactory: customizeActionComponentFactory
};

export function customizeActionComponentFactory(): any {
  return CustomizeBarActionEditComponent;
}

@Component({
  selector: 'app-customize-bar-action-edit',
  templateUrl: './customize-bar-action-edit.component.html',
  providers: [...CUSTOMIZE_BAR_ACTION_EDIT_FORM_PROVIDER],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeBarActionEditComponent implements OnInit, OnDestroy, CustomizeBarEditComponent {
  @Input() set options(value: CustomizeActionOptions) {
    this._options = defaults(value, {
      parametersEditable: true
    });
  }
  @Input() label = 'Click action';
  @Input() wrapperEnabled = true;
  @Input() icon: string;
  @Input() titleEnabled = true;
  @Input() titleCleanValue: (value: string) => string;
  @Input() element: ActionElementItem;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() contextElementPath: (string | number)[];
  @Input() contextElementPaths: (string | number)[][];
  @Input() contextPass = true;
  @Input() modelDescriptionInContext: ModelDescriptionInContext;
  @Input() backLabel = 'Back';
  @Input() backPopSettingsComponent = true;
  @Input() deleteEnabled = false;
  @Input() object: string;
  @Input() trackConfigured = false;
  @Input() parentElement: any;
  @Input() parentPopup: any;
  @Input() firstInit = false;
  @Input() setupOnCreate = false;
  @Input() existingForm: CustomizeBarActionEditForm;
  @Output() event = new EventEmitter<CustomizeBarEditEvent>();
  @Output() back = new EventEmitter<void>();

  _options: CustomizeActionOptions = {};
  form: CustomizeBarActionEditForm;
  createField = createFormFieldFactory();
  result: CustomizeActionResult;
  collapseContext = new SidebarCollapseContext();
  actionEditSeparateComponent = false;
  configurationStarted = false;
  operationValid$: Observable<boolean>;
  actionsValid$: Observable<boolean>;
  completionContextElementPaths = [[SUBMIT_RESULT_OUTPUT]];

  constructor(
    private newForm: CustomizeBarActionEditForm,
    private formUtils: FormUtils,
    private customizeBarContext: CustomizeBarContext,
    public currentProjectStore: CurrentProjectStore,
    private cd: ChangeDetectorRef,
    private featureService: FeatureService,
    private customizeBarService: CustomizeBarService,
    private analyticsService: UniversalAnalyticsService
  ) {}

  get options(): CustomizeActionOptions {
    return this._options;
  }

  ngOnInit() {
    if (this.existingForm) {
      this.form = this.existingForm;
    } else {
      this.form = this.newForm;
      this.form.init({ ...this.options, titleCleanValue: this.titleCleanValue, context: this.context }, this.firstInit);
    }

    this.result = {
      action: this.options.actionItem,
      visibleInput: this.options.visibleInput,
      tooltip: this.options.tooltip,
      title: this.options.title
    };
    this.titleCleanValue = this.titleCleanValue || this.getDefaultTitleCleanValue();
    this.actionEditSeparateComponent = [
      this.options.nameEditable,
      this.options.iconEditable,
      this.options.styleEditable,
      this.options.colorsEditable,
      this.options.approveEnabled,
      this.options.visibleEditable,
      this.options.tooltipEditable,
      this.options.completionEditable
    ].some(item => item);

    const resultObs = this.form.valueChangesWithSilent.pipe(
      debounceTime(200),
      map(() => this.form.submit())
    );

    resultObs.pipe(untilDestroyed(this)).subscribe(result => {
      this.result = result;
      this.emitUpdate();
    });

    if (this.trackConfigured) {
      resultObs
        .pipe(
          switchMap(result => this.form.isConfigured(result.action)),
          trackConfigured(),
          first(configuration => configuration == ElementConfiguration.Started),
          untilDestroyed(this)
        )
        .subscribe(() => {
          this.configurationStarted = true;
          this.analyticsService.sendSimpleEvent(AnalyticsEvent.Component.StartedConfiguration, {
            ComponentTypeID: this.object
          });
        });
    }

    this.operationValid$ = this.form.operationValid$();
    this.actionsValid$ = this.form.actionsValid$();

    if (this.setupOnCreate) {
      this.editInSeparateComponent();
    }
  }

  ngOnDestroy(): void {}

  close() {
    (this.configurationStarted ? this.form.isConfigured(this.result.action) : of(false))
      .pipe(untilDestroyed(this))
      .subscribe(configured => {
        if (configured) {
          this.analyticsService.sendSimpleEvent(AnalyticsEvent.Component.SuccessfullyConfigured, {
            ComponentTypeID: this.object
          });
        }

        this.customizeBarContext.popSettingsComponent();
      });
  }

  submit() {
    this.result = this.form.submit();

    this.emitUpdate(true);
    this.close();
  }

  getDefaultTitleCleanValue(): (value: string) => string {
    return (value: string): string => {
      if (this.element) {
        return cleanElementName(value, this.element, this.context.getElementItems());
      } else {
        return value;
      }
    };
  }

  onTitleChanged(title: string) {
    this.form.controls.title.patchValue(title);
  }

  onApprovalOpenedChanged(opened: boolean) {
    if (opened) {
      this.form.controls.approve_enabled.patchValue(true);
      this.form.markAsDirty();
    }
  }

  emitUpdate(submit = false) {
    const args = { result: this.result.action, submit: submit };

    if (this.options.titleEditable) {
      args['title'] = this.result.title;
    }

    if (this.options.visibleEditable) {
      args['visible_input'] = this.result.visibleInput;
    }

    if (this.options.tooltipEditable) {
      args['tooltip'] = this.result.tooltip;
    }

    if (this.options.elementStylesEditable) {
      args['element_styles'] = this.result.elementStyles;
    }

    if (this.options.actionItem) {
      this.event.emit({
        type: CustomizeBarEditEventType.Updated,
        args: args
      });
    } else {
      this.event.emit({
        type: CustomizeBarEditEventType.Created,
        args: args
      });
    }
  }

  cancel() {
    this.event.emit({ type: CustomizeBarEditEventType.Canceled });
    this.close();
  }

  delete() {
    this.event.emit({ type: CustomizeBarEditEventType.Deleted });
    this.close();
  }

  openTaskFeatureOverview() {
    this.featureService.showFeatureOverview({
      subtitle: 'Paid Feature',
      title: 'Collaborate on a project with <strong>Collaboration</strong>',
      description: `
          Assign tasks, leave notes, and write comments on a specific record page. Chat with your teammates directly
          through Jet and make sure everyone is up-to-date.
        `
    });
  }

  editInSeparateComponent() {
    this.customizeBarContext.appendSettingsComponent({
      component: CustomizeBarActionEditOperationComponent,
      inputs: {
        options: {
          ...this.options,
          titleEditable: false
        },
        form: this.form,
        label: this.label,
        context: this.context,
        contextElement: this.contextElement,
        contextElementPath: this.contextElementPath,
        contextElementPaths: this.contextElementPaths,
        contextPass: this.contextPass,
        firstInit: this.firstInit,
        setupOnCreate: this.setupOnCreate,
        object: this.object
      },
      outputs: {
        submitImmediate: [
          () => {
            this.submit();
          }
        ],
        event: [
          () => {
            this.customizeBarContext.popSettingsComponent();
          }
        ]
      }
    });
  }

  onConfirmationClick() {
    if (!this.form.controls.confirmation_enabled.value) {
      this.form.controls.confirmation_enabled.patchValue(true);
    }
  }
}
