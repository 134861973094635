var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Injector, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { of, throwError } from 'rxjs';
import { catchError, delayWhen, map, switchMap } from 'rxjs/operators';
import { FormUtils } from '@common/form-utils';
import { PopupService } from '@common/popups';
import { DEMO_RESOURCES_PROJECT } from '@modules/api';
import { MenuGeneratorService } from '@modules/menu';
import { ProjectSettingsService } from '@modules/project-settings';
import { ProjectTokenService, Resource, SecretTokenService } from '@modules/projects';
import { IsOptionsValidResult } from '@modules/resource-generators';
import { forceObservable, generateAlphanumeric } from '@shared';
var BaseResourceSettingsForm = /** @class */ (function () {
    function BaseResourceSettingsForm(secretTokenService, formUtils, projectSettingsService, projectTokenService, popupService, menuGeneratorService, injector) {
        var _this = this;
        this.secretTokenService = secretTokenService;
        this.formUtils = formUtils;
        this.projectSettingsService = projectSettingsService;
        this.projectTokenService = projectTokenService;
        this.popupService = popupService;
        this.menuGeneratorService = menuGeneratorService;
        this.injector = injector;
        this.resourceNameEditing = false;
        this.optionsHidden = false;
        this.modelDescriptionNameEditing = false;
        this.deploy = '';
        this.resourceForm = new FormGroup({
            name: new FormControl('', [Validators.required, function (control) { return _this.checkResourceName(control); }])
        });
    }
    BaseResourceSettingsForm.prototype.ngOnDestroy = function () { };
    BaseResourceSettingsForm.prototype.checkResourceUniqueName = function (control) {
        var _this = this;
        if (this.project &&
            this.environment &&
            this.project
                .getEnvironmentResources(this.environment.uniqueName)
                .find(function (item) { return item !== _this.resource && item.uniqueName == control.value; })) {
            return {
                local: ['Resource with such Unique Name already exists']
            };
        }
        return null;
    };
    BaseResourceSettingsForm.prototype.checkResourceName = function (control) {
        var _this = this;
        if (this.project &&
            this.environment &&
            this.project
                .getEnvironmentResources(this.environment.uniqueName)
                .filter(function (item) { return !item.demo; })
                .filter(function (item) { return !_this.resource || item.uniqueName != _this.resource.uniqueName; })
                .find(function (item) { return item.name == control.value; })) {
            return {
                local: ['Resource with such Name already exists']
            };
        }
        return null;
    };
    BaseResourceSettingsForm.prototype.generateResourceName = function (project, environment) {
        var resourceNames = project
            .getEnvironmentResources(environment.uniqueName)
            .filter(function (item) { return !item.demo; })
            .reduce(function (acc, item) {
            acc[item.name.toLowerCase()] = item;
            return acc;
        }, {});
        var defaultName = this.typeItem.label;
        var i = 1;
        var newName;
        do {
            newName = i > 1 ? [defaultName, i].join(' ') : defaultName;
            ++i;
        } while (resourceNames.hasOwnProperty(newName.toLowerCase()));
        return newName;
    };
    BaseResourceSettingsForm.prototype.init = function (project, environment, resource, typeItem, resourceNameEditing, optionsHidden, params) {
        if (optionsHidden === void 0) { optionsHidden = false; }
        this.project = project;
        this.environment = environment;
        this.resource = resource;
        this.typeItem = typeItem;
        this.resourceNameEditing = resourceNameEditing;
        this.optionsHidden = optionsHidden;
        this.params = params;
        if (this.resource) {
            this.resourceForm.patchValue({
                unique_name: this.resource.uniqueName,
                name: this.resource.name
            });
        }
        else {
            this.resourceForm.patchValue({
                unique_name: this.typeItem.name,
                name: this.generateResourceName(project, environment)
            });
        }
        this.resourceForm.updateValueAndValidity();
        if (this.optionsHidden) {
            return of(undefined);
        }
        if (this.resource) {
            return this.initResourceValue();
        }
        else {
            return this.initDefaultValue();
        }
    };
    BaseResourceSettingsForm.prototype.initResourceValue = function () {
        return of(undefined);
    };
    BaseResourceSettingsForm.prototype.initDefaultValue = function () {
        return of(undefined);
    };
    BaseResourceSettingsForm.prototype.isResourceFormValid = function () {
        return !this.resourceNameEditing || this.resourceForm.valid;
    };
    BaseResourceSettingsForm.prototype.isValid = function () {
        return this.form.valid && this.isResourceFormValid();
    };
    BaseResourceSettingsForm.prototype.getParamsSyncModelDescriptions = function (result) {
        if (!result.modelDescriptions) {
            return;
        }
        var syncModelDescriptions = result.modelDescriptions.filter(function (item) { return !item['get_parameters'].filter(function (parameter) { return parameter['required']; }).length; });
        if (syncModelDescriptions.length < result.modelDescriptions.length) {
            return syncModelDescriptions.map(function (item) { return item['model']; });
        }
    };
    BaseResourceSettingsForm.prototype.submit = function (paramsOptions) {
        var _this = this;
        this.form.clearServerErrors();
        var isValidObs = this.isOptionsValid ? this.isOptionsValid() : of({});
        return isValidObs.pipe(switchMap(function () { return forceObservable(_this.getParams(paramsOptions)); }), switchMap(function (params) {
            return _this.projectSettingsService
                .applySyncParams({
                project: _this.project,
                environment: _this.environment,
                params: params,
                existingResource: _this.resource,
                typeItem: _this.typeItem
            })
                .pipe(map(function (result) {
                if (result.createdResource) {
                    _this.resource = result.createdResource;
                }
                return result.params;
            }));
        }), switchMap(function (params) {
            var instance = _this.resource ? cloneDeep(_this.resource) : new Resource();
            if (params.resourceName != undefined) {
                instance.name = params.resourceName;
            }
            if (params.resourceToken != undefined) {
                instance.token = params.resourceToken;
            }
            if (_this.project.uniqueName == DEMO_RESOURCES_PROJECT) {
                instance.uniqueName = ['demo', _this.typeItem.name].join('_');
            }
            else if (!_this.resource) {
                instance.uniqueName = [_this.typeItem.name, generateAlphanumeric(4)].join('_');
            }
            instance.type = _this.typeItem.resourceType;
            instance.typeItem = _this.typeItem;
            var saveParams = {
                resourceParams: params.resourceParams,
                modelDescriptions: params.modelDescriptions,
                resourceModelDescriptions: params.resourceModelDescriptions,
                actionDescriptions: params.actionDescriptions,
                secretTokens: params.secretTokens,
                storages: params.storages,
                extraTokens: params.extraTokens,
                sync: params.sync,
                syncModelDescriptions: params.syncModelDescriptions
            };
            return _this.projectSettingsService
                .saveResource(_this.project, _this.environment, instance, !_this.resource, __assign({}, saveParams, { mergeExisting: true, mergeExistingRename: _this.modelDescriptionNameEditing, deleteNonExisting: true }))
                .pipe(delayWhen(function (result) {
                return _this.projectSettingsService.applySyncJob({
                    project: _this.project,
                    environment: _this.environment,
                    resource: result,
                    modelDescriptions: params.syncModelDescriptions,
                    runInterval: params.syncRunInterval || _this.typeItem.syncRunInterval
                });
            }));
        }), catchError(function (error) {
            console.error(error);
            _this.formUtils.showFormErrors(_this.form, error);
            return throwError(error);
        }));
    };
    return BaseResourceSettingsForm;
}());
export { BaseResourceSettingsForm };
