import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgGxScrollableModule } from 'ng-gxscrollable';

import { DialogsModule } from '@common/dialogs';
import { DragDropModule } from '@common/drag-drop';
import { DynamicComponentModule } from '@common/dynamic-component';
import { FixedComponentModule } from '@common/fixed-component';
import { TipsModule } from '@common/tips';
import { AdminHeaderModule } from '@modules/admin-header';
import { AnalyticsModule } from '@modules/analytics';
import { ChartsComponentsModule } from '@modules/charts-components';
import { CustomizeBarModule } from '@modules/customize-bar';
import { DashboardModule } from '@modules/dashboard';
import { DashboardComponentsModule } from '@modules/dashboard-components';
import { FeaturesModule } from '@modules/features';
import { FieldsModule } from '@modules/fields';
import { RoutingModule } from '@modules/routing';
import { SharedModule } from '@shared';

import { DashboardCustomizeBarComponent } from './components/dashboard/dashboard-customize-bar/dashboard-customize-bar.component';
import { DashboardItemComponent } from './components/dashboard/dashboard-item/dashboard-item.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { IndexComponent } from './components/index/index.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DashboardModule,
    NgGxScrollableModule,
    SharedModule,
    FixedComponentModule,
    DynamicComponentModule,
    TipsModule,
    ChartsComponentsModule,
    FieldsModule,
    CustomizeBarModule,
    DialogsModule,
    DragDropModule,
    RoutingModule,
    FeaturesModule,
    AnalyticsModule,
    DashboardComponentsModule,
    AdminHeaderModule
  ],
  declarations: [IndexComponent, DashboardComponent, DashboardItemComponent, DashboardCustomizeBarComponent],
  exports: [IndexComponent]
})
export class DashboardRoutesModule {}
