import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { JET_APP_RESOURCE } from '@modules/projects';

import { ApiParameter } from '../../../data/api-parameter';
import { ApiParameterType } from '../../../data/api-parameter-type';
import { fieldToApiParameter } from '../../../utils/common';
import { ModelApiBaseQueryComponent } from '../model-api-base-query/model-api-base-query.component';

@Component({
  selector: 'app-model-api-get-detail-query',
  templateUrl: './model-api-get-detail-query.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModelApiGetDetailQueryComponent extends ModelApiBaseQueryComponent implements OnInit {
  parameters: ApiParameter[] = [];

  ngOnInit() {
    super.ngOnInit();
    this.fetchModel();
    this.updateParameters();
  }

  updateParameters() {
    this.parameters = this.getParameters();
    this.cd.markForCheck();
  }

  updateCurl() {
    this.curl = this.getCurlRequest({
      url: this.getAbsoluteApiUrl()
    });
  }

  getParametersAuto(): ApiParameter[] {
    const controller = this.resourceControllerService.get(this.resource.type);
    const modelParameters = controller.getDetailParametersOrDefaults(this.resource, this.modelDescription);
    return modelParameters.map(item => {
      const type = this.modelDescription.primaryKeyField ? ApiParameterType.Path : ApiParameterType.Body;
      return fieldToApiParameter(item, type);
    });
  }

  getQueryUrl() {
    return `/:${this.getPrimaryKey()}`;
  }

  getResponseData(): Object {
    const model = this.model || this.getDefaultModel();
    const modelData = this.serializer ? this.serializer(model) : this.serializeDefault(model);

    return JSON.stringify(modelData, null, this.indentSize);
  }
}
