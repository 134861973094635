import * as inflection from 'inflection';
import last from 'lodash/last';
import pickBy from 'lodash/pickBy';

import {
  deserializeDisplayField,
  DisplayField,
  FieldDisplaySettings,
  FieldType,
  FormField,
  Input,
  ParameterField
} from '@modules/fields';
import { Segment } from '@modules/filters';
import { ListModelDescriptionQuery, ModelDescriptionQuery, QueryType } from '@modules/queries';
import {
  ascComparator,
  defaultComparator,
  generateAlphanumeric,
  isSet,
  objectsSortPredicate,
  sortUsingAfterItem,
  splitmax,
  stripStart
} from '@shared';

import { modelDbFieldToParameterField } from '../utils/common/common';
import { fromLegacyModel } from '../utils/legacy/legacy';
import { ModelDbField } from './model-db-field';
import { ModelFlexField } from './model-flex-field';
import { SEGMENT_PARAM } from './params';

export enum RelationDirection {
  OneToMany = 'ONETOMANY',
  ManyToOne = 'MANYTOONE',
  ManyToMany = 'MANYTOMANY'
}

export class ModelRelation {
  public name: string;
  public direction: RelationDirection;
  public localField: string;
  public relatedModel: string;
  public relatedField: string;

  constructor(options: Partial<ModelRelation> = {}) {
    Object.assign(this, options);
  }

  static generateManyToOneName(options: {
    localModel: ModelDescription;
    localField: string;
    relatedModel: string;
    relatedField: string;
  }) {
    let name = [options.localField, 'to', options.relatedModel, options.relatedField].join('__');

    if (options.localModel.dbField(name)) {
      name = name + '_relation';
      console.log(`Already detected column name, using ${name}`);
    }

    return name;
  }

  static generateOneToManyName(options: {
    localModel: ModelDescription;
    localField: string;
    relatedModel: string;
    relatedField: string;
  }) {
    let name = [options.relatedModel, options.relatedField, 'to', options.localField].join('__');

    if (options.localModel.dbField(name)) {
      name = name + '_relation';
      console.log(`Already detected column name, using ${name}`);
    }

    return name;
  }

  deserialize(data: Object): ModelRelation {
    this.name = data['name'];
    this.direction = data['direction'];
    this.localField = data['local_field'];
    this.relatedModel = data['related_model'];
    this.relatedField = data['related_field'];

    return this;
  }
}

export enum ModelFieldType {
  Db = 'db',
  Flex = 'flex',
  Custom = 'custom'
}

export function sortModelDescriptionsList<T = ModelDescription>(
  items: T[],
  getter: (item: T) => ModelDescription
): T[] {
  if (items.length <= 1) {
    return items;
  }

  return sortUsingAfterItem<T>({
    items: items,
    getAfterItem: item => getter(item).orderAfter,
    getItemId: item => getter(item).model,
    defaultSort: (lhs, rhs) => {
      return ascComparator(
        (getter(lhs).verboseNamePlural || getter(lhs).model).toLowerCase(),
        (getter(rhs).verboseNamePlural || getter(rhs).model).toLowerCase()
      );
    }
  });
}

export function sortModelDescriptions(items: ModelDescription[]): ModelDescription[] {
  return sortModelDescriptionsList(items, item => item);
}

export function sortModelFields(items: ModelField[]): ModelField[] {
  if (items.length <= 1) {
    return items;
  }

  return sortUsingAfterItem<ModelField>({
    items: items,
    getAfterItem: item => item.orderAfter,
    getItemId: item => item.name
  });
}

export class ModelField {
  public name: string;
  public item: ModelDbField | ModelFlexField | DisplayField;
  public type: ModelFieldType;
  public orderAfter: string = undefined;
  public visible = true;

  deserialize(data: Object): ModelField {
    this.name = data['name'];
    this.type = data['type'];

    if (data['item']) {
      if (this.type == ModelFieldType.Db) {
        this.item = new ModelDbField().deserialize(data['item']);
      } else if (this.type == ModelFieldType.Flex) {
        this.item = new ModelFlexField().deserialize(data['item']);
      } else if (this.type == ModelFieldType.Custom) {
        this.item = deserializeDisplayField(data['item']);
      }
    }

    if (data['data_source_order_after']) {
      this.orderAfter = data['data_source_order_after'];
    } else {
      this.orderAfter = data['order_after'];
    }

    if (data['data_source_hidden'] !== undefined) {
      this.visible = !data['data_source_hidden'];
    } else if (data['visible'] !== undefined) {
      this.visible = data['visible'];
    }

    return this;
  }

  serialize(): Object {
    return {
      name: this.name,
      type: this.type,
      item: this.item.serialize(),
      order_after: this.orderAfter,
      visible: this.visible
    };
  }

  get verboseName() {
    return this.item.verboseName;
  }

  get formField(): FormField {
    return this.item.formField;
  }

  isDb() {
    return this.type == ModelFieldType.Db;
  }

  get icon() {
    if (this.item instanceof DisplayField) {
      return this.item.icon;
    } else {
      return this.item.fieldDescription ? this.item.fieldDescription.icon : undefined;
    }
  }
}

export class ModelDescription {
  public project: string;
  public resource: string;
  public model: string;
  public verboseName: string;
  public verboseNamePlural: string;
  public dbTable: string;
  public hidden: boolean;
  public orderingField: string;
  public defaultOrderBy: string;
  public displayField: string;
  public primaryKeyField: string;
  public isView = false;
  public fields: ModelField[] = [];
  public defaultFields: ModelField[];
  public segments: Segment[] = [];
  public relations: ModelRelation[] = [];
  public relationOverrides: ModelRelation[] = [];
  public autoVerboseName = false;
  public description: string;
  public queryType: QueryType;
  public getQuery: ListModelDescriptionQuery;
  public getParameters: ParameterField[] = [];
  public getInputs: Input[] = [];
  public searchQuery: ListModelDescriptionQuery;
  public getDetailQuery: ModelDescriptionQuery;
  public getDetailParameters: ParameterField[] = [];
  public getDetailParametersUseDefaults = false;
  public createQuery: ModelDescriptionQuery;
  public createParameters: ParameterField[] = [];
  public createParametersUseDefaults = false;
  public updateQuery: ModelDescriptionQuery;
  public updateParameters: ParameterField[] = [];
  public updateParametersUseDefaults = false;
  public deleteQuery: ModelDescriptionQuery;
  public deleteParameters: ParameterField[] = [];
  public deleteParametersUseDefaults = false;
  public siblingsQuery: ModelDescriptionQuery;
  public aggregateQuery: ModelDescriptionQuery;
  public aggregateParameters: ParameterField[] = [];
  public groupQuery: ModelDescriptionQuery;
  public groupParameters: ParameterField[] = [];
  public fromResource = false;
  public virtual = false;
  public featured = false;
  public demo = false;
  public sync = false;
  public syncFinished = false;
  public syncResource = false;
  public orderAfter: string = undefined;
  public params = {};
  public draft = false;
  public deleted = false;

  static generateModel(): string {
    return generateAlphanumeric(8, { letterFirst: true });
  }

  deserialize(data: Object, schemas = false): ModelDescription {
    const isQueriesStructure = data.hasOwnProperty('get_parameters');

    this.project = data['project'];
    this.resource = data['resource'];
    this.model = fromLegacyModel(data['model']);
    this.dbTable = data['db_table'];
    this.orderingField = data['ordering_field'];
    this.defaultOrderBy = data['default_order_by'];
    this.displayField = data['display_field'];
    this.primaryKeyField = data['primary_key_field'];
    this.description = data['description'];
    this.queryType = data['query_type'];
    this.virtual = data['virtual'];
    this.featured = data['featured'];
    this.demo = data['demo'];

    if (data.hasOwnProperty('is_view')) {
      this.isView = data['is_view'];
    }

    if (data['data_source_name']) {
      this.verboseName = data['data_source_name'];
    } else {
      this.verboseName = data['verbose_name'];
    }

    if (data['data_source_name_plural']) {
      this.verboseNamePlural = data['data_source_name_plural'];
    } else {
      this.verboseNamePlural = data['verbose_name_plural'];
    }

    if (data['data_source_hidden'] !== undefined) {
      this.hidden = data['data_source_hidden'];
    } else {
      this.hidden = data['hidden'];
    }

    this.generateVerboseNameIfNeeded(schemas);

    const dbFields = data['fields'];

    if (dbFields) {
      this.fields = this.fields.concat(
        dbFields.map(item => {
          const field = new ModelField();
          field.type = ModelFieldType.Db;
          field.item = new ModelDbField().deserialize(item);
          field.name = field.item.name;

          if (item['data_source_hidden'] !== undefined) {
            field.visible = !item['data_source_hidden'];
          } else if (item['visible'] !== undefined) {
            field.visible = item['visible'];
          }

          if (item['data_source_order_after']) {
            field.orderAfter = item['data_source_order_after'];
          } else {
            field.orderAfter = item['order_after'];
          }

          return field;
        })
      );
    }

    const flexFields = data['flex_fields'];

    if (flexFields) {
      this.fields = this.fields.concat(
        flexFields.map(item => {
          const field = new ModelField();
          field.type = ModelFieldType.Flex;
          field.item = new ModelFlexField().deserialize(item);
          field.name = field.item.name;

          if (item['data_source_hidden'] !== undefined) {
            field.visible = !item['data_source_hidden'];
          } else if (item['visible'] !== undefined) {
            field.visible = item['visible'];
          }

          if (item['data_source_order_after']) {
            field.orderAfter = item['data_source_order_after'];
          } else {
            field.orderAfter = item['order_after'];
          }

          return field;
        })
      );
    }

    const customFields = data['custom_fields'];

    if (customFields) {
      this.fields = this.fields.concat(
        customFields.map(item => {
          const field = new ModelField();
          field.type = ModelFieldType.Custom;
          field.item = deserializeDisplayField(item);
          field.name = field.item.name;

          if (item['data_source_hidden'] !== undefined) {
            field.visible = !item['data_source_hidden'];
          } else if (item['visible'] !== undefined) {
            field.visible = item['visible'];
          }

          if (item['data_source_order_after']) {
            field.orderAfter = item['data_source_order_after'];
          } else {
            field.orderAfter = item['order_after'];
          }

          return field;
        })
      );
    }

    this.fields
      .filter(item => item.item.field == FieldType.RelatedModel)
      .forEach(field => {
        if (field.item.params && field.item.params['related_model'] && field.item.params['related_model']['model']) {
          ['{{resource}}.', '.'].forEach(prefix => {
            field.item.params['related_model']['model'] = stripStart(
              field.item.params['related_model']['model'],
              prefix
            );
          });
        }
      });

    if (data['default_fields']) {
      this.defaultFields = data['default_fields'].map(item => new ModelField().deserialize(item));
    }

    // if (!this.primaryKeyField) {
    //   let idField, firstField;
    //
    //   if ((idField = this.dbFields.find(item => item.name.toLowerCase() == 'id'))) {
    //     this.primaryKeyField = idField.name;
    //   } else if ((firstField = this.fields[0])) {
    //     this.primaryKeyField = firstField.name;
    //   }
    // }

    if (data['segments']) {
      this.segments = data['segments'].map(item => new Segment().deserialize(item));
    }

    if (data['relations']) {
      this.relations = data['relations'].map(item => new ModelRelation().deserialize(item));
    }

    if (data['relation_overrides']) {
      this.relationOverrides = data['relation_overrides'].map(item => new ModelRelation().deserialize(item));
    }

    if (isQueriesStructure) {
      if (data['get_query']) {
        this.getQuery = new ListModelDescriptionQuery().deserialize(data['get_query']);
      }
    } else {
      this.getQuery = new ListModelDescriptionQuery();
      this.getQuery.queryType = QueryType.Simple;
      this.getQuery.simpleQuery = new this.getQuery.simpleQueryClass();
      this.getQuery.simpleQuery.model = this.model;
    }

    if (data['get_parameters']) {
      this.getParameters = data['get_parameters'].map(item => new ParameterField().deserialize(item));
    }

    if (data['get_inputs']) {
      this.getInputs = data['get_inputs'].map(item => new Input().deserialize(item));
    }

    if (isQueriesStructure) {
      if (data['search_query']) {
        this.searchQuery = new ListModelDescriptionQuery().deserialize(data['search_query']);
      }
    } else {
      this.searchQuery = new ListModelDescriptionQuery();
      this.searchQuery.queryType = QueryType.Simple;
      this.searchQuery.simpleQuery = new this.searchQuery.simpleQueryClass();
      this.searchQuery.simpleQuery.model = this.model;
    }

    if (isQueriesStructure) {
      if (data['get_detail_query']) {
        this.getDetailQuery = new ModelDescriptionQuery().deserialize(data['get_detail_query']);
      }
    } else {
      this.getDetailQuery = new ModelDescriptionQuery();
      this.getDetailQuery.queryType = QueryType.Simple;
      this.getDetailQuery.simpleQuery = new this.getDetailQuery.simpleQueryClass();
      this.getDetailQuery.simpleQuery.model = this.model;
    }

    if (data['get_detail_parameters']) {
      this.getDetailParameters = data['get_detail_parameters'].map(item => new ParameterField().deserialize(item));
    }

    if (data['get_detail_parameters_use_defaults']) {
      this.getDetailParametersUseDefaults = data['get_detail_parameters_use_defaults'];
    }

    if (isQueriesStructure) {
      if (data['create_query']) {
        this.createQuery = new ModelDescriptionQuery().deserialize(data['create_query']);
      }
    } else {
      this.createQuery = new ModelDescriptionQuery();
      this.createQuery.queryType = QueryType.Simple;
      this.createQuery.simpleQuery = new this.createQuery.simpleQueryClass();
      this.createQuery.simpleQuery.model = this.model;
    }

    if (data['create_parameters']) {
      this.createParameters = data['create_parameters'].map(item => new ParameterField().deserialize(item));
    }

    if (data['create_parameters_use_defaults']) {
      this.createParametersUseDefaults = data['create_parameters_use_defaults'];
    }

    if (isQueriesStructure) {
      if (data['update_query']) {
        this.updateQuery = new ModelDescriptionQuery().deserialize(data['update_query']);
      }
    } else {
      this.updateQuery = new ModelDescriptionQuery();
      this.updateQuery.queryType = QueryType.Simple;
      this.updateQuery.simpleQuery = new this.updateQuery.simpleQueryClass();
      this.updateQuery.simpleQuery.model = this.model;
    }

    if (data['update_parameters']) {
      this.updateParameters = data['update_parameters'].map(item => new ParameterField().deserialize(item));
    }

    if (data['update_parameters_use_defaults']) {
      this.updateParametersUseDefaults = data['update_parameters_use_defaults'];
    }

    if (isQueriesStructure) {
      if (data['delete_query']) {
        this.deleteQuery = new ModelDescriptionQuery().deserialize(data['delete_query']);
      }
    } else {
      this.deleteQuery = new ModelDescriptionQuery();
      this.deleteQuery.queryType = QueryType.Simple;
      this.deleteQuery.simpleQuery = new this.deleteQuery.simpleQueryClass();
      this.deleteQuery.simpleQuery.model = this.model;
    }

    if (data['delete_parameters']) {
      this.deleteParameters = data['delete_parameters'].map(item => new ParameterField().deserialize(item));
    }

    if (data['delete_parameters_use_defaults']) {
      this.deleteParametersUseDefaults = data['delete_parameters_use_defaults'];
    }

    if (data['aggregate_query']) {
      this.aggregateQuery = new ModelDescriptionQuery().deserialize(data['aggregate_query']);
    }

    if (data['aggregate_parameters']) {
      this.aggregateParameters = data['aggregate_parameters'].map(item => new ParameterField().deserialize(item));
    }

    if (data['group_query']) {
      this.groupQuery = new ModelDescriptionQuery().deserialize(data['group_query']);
    }

    if (data['group_parameters']) {
      this.groupParameters = data['group_parameters'].map(item => new ParameterField().deserialize(item));
    }

    if (data['params']) {
      this.params = data['params'];
    }

    if (this.orderingField) {
      this.defaultOrderBy = this.orderingField;
    }

    if (data['draft'] !== undefined) {
      this.draft = data['draft'];
    }

    if (data['deleted'] !== undefined) {
      this.deleted = data['deleted'];
    }

    if (data['sync'] !== undefined) {
      this.sync = data['sync'];
    }

    if (data['sync_finished'] !== undefined) {
      this.syncFinished = data['sync_finished'];
    }

    if (data['data_source_order_after'] !== undefined) {
      this.orderAfter = data['data_source_order_after'];
    } else {
      this.orderAfter = data['order_after'];
    }

    return this;
  }

  serialize(fields?: string[]): Object {
    let data: Object = {
      project: this.project,
      resource: this.resource,
      model: this.model,
      verbose_name: this.verboseName,
      verbose_name_plural: this.verboseNamePlural,
      db_table: this.dbTable,
      hidden: this.hidden,
      fields: this.fields
        .filter(item => item.type == ModelFieldType.Db)
        .map(item => {
          return {
            ...item.item.serialize(),
            order_after: item.orderAfter,
            visible: item.visible
          };
        }),
      flex_fields: this.fields
        .filter(item => item.type == ModelFieldType.Flex)
        .map(item => {
          return {
            ...item.item.serialize(),
            order_after: item.orderAfter,
            visible: item.visible
          };
        }),
      custom_fields: this.fields
        .filter(item => item.type == ModelFieldType.Custom)
        .map(item => {
          return {
            ...item.item.serialize(),
            order_after: item.orderAfter,
            visible: item.visible
          };
        }),
      default_fields: this.defaultFields ? this.defaultFields.map(item => item.serialize()) : undefined,
      segments: this.segments.map(item => item.serialize()),
      ordering_field: this.orderingField,
      default_order_by: this.defaultOrderBy,
      display_field: this.displayField,
      primary_key_field: this.primaryKeyField,
      description: this.description,
      query_type: this.queryType,
      get_query: this.getQuery ? this.getQuery.serialize() : undefined,
      get_parameters: this.getParameters.map(item => item.serialize()),
      get_inputs: this.getInputs.map(item => item.serialize()),
      search_query: this.searchQuery ? this.searchQuery.serialize() : undefined,
      get_detail_query: this.getDetailQuery ? this.getDetailQuery.serialize() : undefined,
      get_detail_parameters: this.getDetailParameters.map(item => item.serialize()),
      get_detail_parameters_use_defaults: this.getDetailParametersUseDefaults,
      create_query: this.createQuery ? this.createQuery.serialize() : undefined,
      create_parameters: this.createParameters.map(item => item.serialize()),
      create_parameters_use_defaults: this.createParametersUseDefaults,
      update_query: this.updateQuery ? this.updateQuery.serialize() : undefined,
      update_parameters: this.updateParameters.map(item => item.serialize()),
      update_parameters_use_defaults: this.updateParametersUseDefaults,
      delete_query: this.deleteQuery ? this.deleteQuery.serialize() : undefined,
      delete_parameters: this.deleteParameters.map(item => item.serialize()),
      delete_parameters_use_defaults: this.deleteParametersUseDefaults,
      aggregate_query: this.aggregateQuery ? this.aggregateQuery.serialize() : undefined,
      aggregate_parameters: this.aggregateParameters.map(item => item.serialize()),
      group_query: this.groupQuery ? this.groupQuery.serialize() : undefined,
      group_parameters: this.groupParameters.map(item => item.serialize()),
      virtual: this.virtual,
      featured: this.featured,
      demo: this.demo,
      sync: this.sync,
      sync_finished: this.syncFinished,
      order_after: this.orderAfter,
      params: this.params,
      draft: this.draft,
      deleted: this.deleted
    };
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }

  get getDetailEnabled() {
    return this.getDetailQuery || this.getQuery;
  }

  get createEnabled() {
    return this.createQuery;
  }

  get updateEnabled() {
    return this.updateQuery;
  }

  get deleteEnabled() {
    return this.deleteQuery;
  }

  get siblingsEnabled() {
    return this.siblingsQuery;
  }

  generateVerboseNameIfNeeded(schemas = false) {
    if (this.verboseName == undefined && isSet(this.dbTable)) {
      const dbTable = schemas ? last(splitmax(this.dbTable, '.', 2)) : this.dbTable;
      this.verboseName = dbTable.replace(/_/g, ' ');
      this.autoVerboseName = true;
    } else if (this.verboseName == undefined && this.model) {
      this.verboseName = this.model.replace(/_/g, ' ');
      this.autoVerboseName = true;
    }

    if (this.verboseNamePlural == undefined && this.verboseName) {
      this.verboseNamePlural = inflection.pluralize(this.verboseName);
    }
  }

  get modelId() {
    if (!this.resource) {
      return this.model;
    }
    return [this.resource, this.model].join('.');
  }

  get link() {
    return ['models', this.modelId];
  }

  get createLink() {
    return ['models', this.modelId, 'create'];
  }

  changeLink(primaryKey: string) {
    if (!this.getDetailEnabled) {
      return;
    }
    return ['models', this.modelId, primaryKey];
  }

  get userActivityLink() {
    return ['models', this.modelId, 'user_activities'];
  }

  get systemSettingsLink() {
    return this.systemSettingsTabLink();
  }

  systemSettingsTabLink(tab?: string, subTab?: string) {
    const link = ['resources', this.resource, 'models', this.model];

    if (tab) {
      link.push(tab);

      if (subTab) {
        link.push(subTab);
      }
    }

    return link;
  }

  get deleteLink() {
    return ['models', this.modelId, 'delete'];
  }

  get massEditLink() {
    return ['models', this.modelId, 'mass_edit'];
  }

  get dbFields(): ModelDbField[] {
    return this.fields.filter(item => item.type == ModelFieldType.Db).map(item => item.item) as ModelDbField[];
  }

  get dbDefaultFields(): ModelDbField[] {
    if (!this.defaultFields) {
      return;
    }
    return this.defaultFields.filter(item => item.type == ModelFieldType.Db).map(item => item.item) as ModelDbField[];
  }

  get flexFields(): ModelFlexField[] {
    return this.fields.filter(item => item.type == ModelFieldType.Flex).map(item => item.item) as ModelFlexField[];
  }

  get customFields(): DisplayField[] {
    return this.fields.filter(item => item.type == ModelFieldType.Custom).map(item => item.item) as DisplayField[];
  }

  field(name: string): ModelField {
    return this.fields.find(item => item.name == name);
  }

  dbField(name: string): ModelDbField {
    return this.dbFields.find(item => item.name == name);
  }

  flexField(name: string): ModelFlexField {
    return this.flexFields.find(item => item.name == name);
  }

  customField(name: string): DisplayField {
    return this.customFields.find(item => item.name == name);
  }

  relation(name: string) {
    return this.allRelations().find(item => item.name == name);
  }

  allRelations() {
    return [...this.relations, ...this.relationOverrides];
  }

  get primaryKey() {
    return this.dbField(this.primaryKeyField);
  }

  get displayFields(): ModelField[] {
    return this.fields.filter(item => item.name != this.primaryKeyField);
  }

  get displayDbFields(): ModelDbField[] {
    return this.dbFields.filter(item => item.name != this.primaryKeyField);
  }

  getMinimal() {
    const instance = new ModelDescription();
    instance.resource = this.resource;
    instance.model = this.model;
    return instance;
  }

  isSame(model: string | ModelDescription) {
    if (!model) {
      return false;
    }

    if (model instanceof ModelDescription) {
      return model.resource == this.resource && model.model == this.model;
    } else {
      const modelId = model as string;
      const params = splitmax(modelId, '.', 2);

      return params.length == 2
        ? (!this.resource || this.resource == params[0]) && this.model == params[1]
        : this.model == params[0];
    }
  }

  autoActions() {
    return [
      {
        name: 'create',
        label: 'Create',
        primaryKey: false,
        query: 'createQuery',
        uniqueName: this.autoActionUniqueName('create'),
        modify: true
      },
      {
        name: 'update',
        label: 'Update',
        primaryKey: true,
        query: 'updateQuery',
        uniqueName: this.autoActionUniqueName('update'),
        modify: true
      },
      {
        name: 'delete',
        label: 'Delete',
        primaryKey: true,
        query: 'deleteQuery',
        uniqueName: this.autoActionUniqueName('delete'),
        modify: true
      },
      {
        name: 'get',
        label: 'Get List',
        primaryKey: false,
        query: 'getQuery',
        uniqueName: this.autoActionUniqueName('get'),
        modify: false
      },
      {
        name: 'get_detail',
        label: 'Get One',
        primaryKey: false,
        query: 'getDetailQuery',
        uniqueName: this.autoActionUniqueName('get_detail'),
        modify: false
      }
    ];
  }

  autoActionUniqueName(name: string) {
    return ['', this.model, name].join('__');
  }

  setQuerySettings(name: string, query: ModelDescriptionQuery, parameters: ParameterField[]) {
    if (name == 'get') {
      this.getQuery = query as ListModelDescriptionQuery;
      this.getParameters = parameters;
    } else if (name == 'get_detail') {
      this.getDetailQuery = query;
      this.getDetailParameters = parameters;
    } else if (name == 'create') {
      this.createQuery = query;
      this.createParameters = parameters;
    } else if (name == 'update') {
      this.updateQuery = query;
      this.updateParameters = parameters;
    } else if (name == 'delete') {
      this.deleteQuery = query;
      this.deleteParameters = parameters;
    }
  }

  deleteField(name: string) {
    const fields = sortModelFields(this.fields).filter(item => item.name != name);

    fields.forEach((item, i) => {
      const prevItem = fields[i - 1];
      const prevItemName = prevItem ? prevItem.name : undefined;

      if (item.orderAfter !== prevItemName) {
        item.orderAfter = prevItemName;
      }
    });

    this.fields = fields;
  }

  moveField(name: string, afterName: string) {
    const fields = sortModelFields(this.fields);
    const fieldIndex = fields.findIndex(item => item.name == name);

    if (fieldIndex !== -1) {
      const popped = fields.splice(fieldIndex, 1);
      const afterFieldIndex = isSet(afterName) ? fields.findIndex(item => item.name == afterName) : -1;
      fields.splice(afterFieldIndex != -1 ? afterFieldIndex + 1 : 0, 0, ...popped);
    }

    fields.forEach((item, i) => {
      const prevItem = fields[i - 1];
      const prevItemName = prevItem ? prevItem.name : undefined;

      if (item.orderAfter !== prevItemName) {
        item.orderAfter = prevItemName;
      }
    });
  }

  applyFieldSettings(fieldSettings: FieldDisplaySettings[]) {
    fieldSettings.forEach(item => {
      const field = this.field(item.field);
      if (field) {
        field.visible = item.visible;
      }
    });
  }

  isSynced() {
    return this.sync;
  }

  isSyncedFinished() {
    return this.syncFinished;
  }

  isSyncRunning() {
    return this.isSynced() && !this.isSyncedFinished();
  }
}
