import { Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import range from 'lodash/range';

import { MarginControl, StepsElementItem, StepsItem } from '@modules/customize';
import { ElementConfigurationService } from '@modules/customize-configuration';
import { Input } from '@modules/fields';
import { FieldInputControl } from '@modules/parameters';
import { isSet } from '@shared';

export class CustomizeBarStepsItemControl extends FormGroup {
  instance: StepsItem;

  controls: {
    value: FormControl;
    name: FormControl;
    description: FormControl;
    color: FormControl;
    visible_input: FieldInputControl;
  };

  constructor(state: { value?: any; name?: string; description?: string; color?: string; visibleInput?: Input } = {}) {
    super({
      value: new FormControl(isSet(state.value) ? state.value : ''),
      name: new FormControl(isSet(state.name) ? state.name : '', Validators.required),
      description: new FormControl(isSet(state.description) ? state.description : ''),
      color: new FormControl(isSet(state.color) ? state.color : ''),
      visible_input: new FieldInputControl(
        state && state.visibleInput ? state.visibleInput.serializeWithoutPath() : { path: ['value'] }
      )
    });
  }

  deserialize(instance: StepsItem) {
    this.instance = instance;

    if (instance) {
      this.controls.value.patchValue(instance.value);
      this.controls.name.patchValue(instance.name);
      this.controls.description.patchValue(instance.description);
      this.controls.color.patchValue(instance.color);
      this.controls.visible_input.patchValue(instance.visibleInput ? instance.visibleInput.serializeWithoutPath() : {});
    } else {
      this.controls.value.patchValue('');
      this.controls.name.patchValue('');
      this.controls.description.patchValue('');
      this.controls.color.patchValue('');
    }

    this.markAsPristine();

    return this;
  }

  serialize(): StepsItem {
    const result = cloneDeep(this.instance) || new StepsItem();

    if (!result.uid) {
      result.generateUid();
    }

    result.value = this.controls.value.value;
    result.name = this.controls.name.value;
    result.description = this.controls.description.value;
    result.color = this.controls.color.value;

    if (this.controls.visible_input.value) {
      result.visibleInput = new Input().deserialize(this.controls.visible_input.value);
    } else {
      result.visibleInput = undefined;
    }

    return result;
  }
}

export class CustomizeBarStepsItemArray extends FormArray {
  controls: CustomizeBarStepsItemControl[];

  deserialize(instances: StepsItem[]) {
    const controls = instances.map(item => new CustomizeBarStepsItemControl().deserialize(item));
    this.set(controls);
  }

  serialize(): StepsItem[] {
    return this.controls.map(item => item.serialize());
  }

  set(controls: CustomizeBarStepsItemControl[]) {
    range(this.controls.length).forEach(() => this.removeAt(0));
    controls.forEach(item => this.push(item));
    this.updateValueAndValidity();
  }

  append(control: CustomizeBarStepsItemControl) {
    this.push(control);
    this.updateValueAndValidity();
  }

  remove(control: CustomizeBarStepsItemControl) {
    const index = this.controls.findIndex(item => item === control);

    if (index == -1) {
      return;
    }

    this.removeAt(index);
    this.updateValueAndValidity();
  }
}

@Injectable()
export class CustomizeBarStepsEditForm extends FormGroup {
  element: StepsElementItem;

  controls: {
    name: FormControl;
    items: CustomizeBarStepsItemArray;
    current_item: FieldInputControl;
    visible_input: FieldInputControl;
    margin: MarginControl;
  };

  constructor(private elementConfigurationService: ElementConfigurationService) {
    super({
      url: new FieldInputControl({ path: ['value'] }, { validators: Validators.required, updateOn: 'blur' }),
      name: new FormControl(''),
      items: new CustomizeBarStepsItemArray([]),
      current_item: new FieldInputControl({ path: ['value'] }),
      visible_input: new FieldInputControl({ path: ['value'] }),
      margin: new MarginControl()
    });
  }

  init(element: StepsElementItem, firstInit = false) {
    this.element = element;

    this.controls.name.patchValue(element.name ? element.name : 'Steps');
    this.controls.items.deserialize(element.items);
    this.controls.current_item.patchValue(element.currentItem ? element.currentItem.serializeWithoutPath() : {});
    this.controls.visible_input.patchValue(element.visibleInput ? element.visibleInput.serializeWithoutPath() : {});
    this.controls.margin.patchValue(element.margin);

    if (!firstInit) {
      this.markAsDirty();
    } else {
      this.markAsPristine();
    }
  }

  isConfigured(instance: StepsElementItem): boolean {
    return this.elementConfigurationService.isStepsConfigured(instance);
  }

  submit(): StepsElementItem {
    const instance = cloneDeep(this.element) as StepsElementItem;

    instance.name = this.controls.name.value;
    instance.items = this.controls.items.serialize();
    instance.margin = this.controls.margin.value;

    if (this.controls.current_item.value) {
      instance.currentItem = new Input().deserialize(this.controls.current_item.value);
    } else {
      instance.currentItem = undefined;
    }

    if (this.controls.visible_input.value) {
      instance.visibleInput = new Input().deserialize(this.controls.visible_input.value);
    } else {
      instance.visibleInput = undefined;
    }

    return instance;
  }
}
