var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormControl, FormGroup } from '@angular/forms';
import { map } from 'rxjs/operators';
import { Fill, FillType } from '@modules/customize';
import { controlValue, isSet } from '@shared';
import { GradientControl } from './gradient.control';
var FillControl = /** @class */ (function (_super) {
    __extends(FillControl, _super);
    function FillControl(state) {
        if (state === void 0) { state = {}; }
        return _super.call(this, {
            type: new FormControl(isSet(state.type) ? state.type : FillType.Color),
            color: new FormControl(isSet(state.color) ? state.color : ''),
            gradient: new GradientControl(isSet(state.gradient) ? state.gradient : {})
        }) || this;
    }
    FillControl.prototype.deserialize = function (value, options) {
        if (options === void 0) { options = {}; }
        this.instance = value;
        this.controls.type.patchValue(value ? value.type : FillType.Color, { emitEvent: options.emitEvent });
        this.controls.color.patchValue(value ? value.color : '', { emitEvent: options.emitEvent });
        this.controls.gradient.deserialize(value ? value.gradient : undefined, { emitEvent: options.emitEvent });
    };
    FillControl.prototype.isSet = function () {
        if (this.controls.type.value == FillType.Color) {
            return isSet(this.controls.color.value);
        }
        else if (this.controls.type.value == FillType.Gradient) {
            return this.controls.gradient.isSet();
        }
        else {
            return false;
        }
    };
    FillControl.prototype.resetDefaults = function (options) {
        this.controls.type.patchValue(FillType.Color, options);
        this.controls.color.patchValue('#2B50ED', options);
    };
    FillControl.prototype.reset = function (options) {
        this.controls.type.patchValue(undefined, options);
        this.controls.color.patchValue(undefined, options);
    };
    FillControl.prototype.setColor = function (color) {
        this.patchValue({
            type: FillType.Color,
            color: color
        });
    };
    FillControl.prototype.setGradientType = function (type) {
        var prevType = this.controls.type.value;
        var defaultColor = prevType == FillType.Color ? this.controls.color.value : undefined;
        this.patchValue({
            type: FillType.Gradient,
            gradient: {
                type: type
            }
        });
        this.controls.gradient.validateStops(defaultColor);
    };
    FillControl.prototype.getInstance = function (instance) {
        if (!instance) {
            instance = new Fill();
        }
        instance.type = this.controls.type.value;
        if (instance.type == FillType.Color || instance.color) {
            instance.color = isSet(this.controls.color.value) ? this.controls.color.value : undefined;
        }
        if (instance.type == FillType.Gradient || instance.gradient) {
            instance.gradient = this.controls.gradient.getInstance(instance.gradient);
        }
        return instance;
    };
    FillControl.prototype.serialize = function () {
        return this.getInstance(this.instance);
    };
    FillControl.prototype.serialize$ = function () {
        var _this = this;
        return controlValue(this, { debounce: 200 }).pipe(map(function () { return _this.serialize(); }));
    };
    return FillControl;
}(FormGroup));
export { FillControl };
