import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fromEvent, merge } from 'rxjs';

import { ProjectSettingsStore } from '@modules/all-project-settings';
import { IntercomService } from '@modules/analytics';
import { Font, fonts, headingFontName, regularFontName } from '@modules/theme';
import { ascComparator, elementResize$, isSet } from '@shared';

interface FontOption {
  title: string;
  subtitle?: string;
  group?: string;
  value: string;
  font: Font;
}

@Component({
  selector: 'app-font-menu',
  templateUrl: './font-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FontMenuComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() value: string;
  @Input() globalFonts = false;
  @Output() fontSelect = new EventEmitter<string>();

  @ViewChild('viewport') viewport: ElementRef;
  @ViewChildren('font_item_element') fontItemElements = new QueryList<ElementRef>();

  searchControl = new FormControl('');
  defaultOption = fonts.find(item => item.default);
  options: FontOption[] = [];
  filteredOptions: FontOption[] = [];
  firstVisibleIndex: number;
  firstInvisibleIndex: number;

  trackFont = (() => {
    return (i, item: FontOption) => {
      return item.title;
    };
  })();

  constructor(
    private projectSettingsStore: ProjectSettingsStore,
    private intercomService: IntercomService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.projectSettingsStore
      .getAllSettings$()
      .pipe(untilDestroyed(this))
      .subscribe(projectSettings => {
        const fontRegular: Font =
          projectSettings && isSet(projectSettings.fontRegular)
            ? fonts.find(item => item.name == projectSettings.fontRegular)
            : this.defaultOption;
        const fontHeading: Font =
          projectSettings && isSet(projectSettings.fontHeading)
            ? fonts.find(item => item.name == projectSettings.fontHeading)
            : this.defaultOption;

        this.options = [
          ...(this.globalFonts
            ? [
                {
                  title: 'Regular font',
                  subtitle: fontRegular.name,
                  group: 'globals',
                  value: regularFontName,
                  font: fontRegular
                },
                {
                  title: 'Heading font',
                  subtitle: fontHeading.name,
                  group: 'globals',
                  value: headingFontName,
                  font: fontHeading
                }
              ]
            : []),
          ...fonts
            .sort((lhs, rhs) => ascComparator(lhs.name, rhs.name))
            .map(item => {
              return {
                title: item.name,
                value: item.name,
                font: item
              };
            })
        ];
        this.cd.markForCheck();
        this.updateFilteredOptions();

        this.searchControl.valueChanges.pipe(untilDestroyed(this)).subscribe(() => this.updateFilteredOptions());
      });
  }

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    merge(fromEvent(this.viewport.nativeElement, 'scroll'), elementResize$(this.viewport.nativeElement, false))
      .pipe(untilDestroyed(this))
      .subscribe(() => this.updateVisible());

    this.updateVisible();
  }

  getFilteredOptions(): FontOption[] {
    const search = this.searchControl.value.toLowerCase().trim();

    if (!isSet(search)) {
      return this.options;
    }

    return this.options.filter(item => {
      return item.font.name.toLowerCase().includes(search);
    });
  }

  updateFilteredOptions() {
    this.filteredOptions = this.getFilteredOptions();
    this.cd.markForCheck();
  }

  clearSearch() {
    this.searchControl.patchValue('');
    this.updateFilteredOptions();
  }

  updateVisible() {
    const viewportBounds = this.viewport.nativeElement.getBoundingClientRect();
    const fontItemElements = this.fontItemElements.toArray();
    const firstVisibleIndex = fontItemElements.findIndex(item => {
      const bounds = item.nativeElement.getBoundingClientRect();
      return bounds.top <= viewportBounds.bottom && bounds.bottom >= viewportBounds.top;
    });
    const firstInvisibleIndex =
      firstVisibleIndex !== -1
        ? fontItemElements.findIndex((item, i) => {
            if (i <= firstVisibleIndex) {
              return false;
            }

            const bounds = item.nativeElement.getBoundingClientRect();
            return bounds.top > viewportBounds.bottom;
          })
        : -1;

    this.firstVisibleIndex = firstVisibleIndex !== -1 ? firstVisibleIndex : undefined;
    this.firstInvisibleIndex = firstInvisibleIndex !== -1 ? firstInvisibleIndex : undefined;
    this.cd.markForCheck();
  }

  isOptionVisible(index: number): boolean {
    return (
      isSet(this.firstVisibleIndex) &&
      index >= this.firstVisibleIndex &&
      (!isSet(this.firstInvisibleIndex) || index < this.firstInvisibleIndex)
    );
  }

  openChat() {
    this.intercomService.openChat();
  }
}
