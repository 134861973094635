var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Input } from '@modules/fields';
import { SimpleMenuItem } from '@modules/menu';
import { FieldInputControl } from '@modules/parameters';
import { MenuItemActionControl } from './menu-item-action.control';
import { CustomizeBarPagesEditMenuItemArray, CustomizeBarPagesEditMenuItemControl, menuItemControls } from './customize-bar-pages-edit.form';
var CustomizeBarPagesEditSimpleControl = /** @class */ (function (_super) {
    __extends(CustomizeBarPagesEditSimpleControl, _super);
    function CustomizeBarPagesEditSimpleControl(injector) {
        var _this = _super.call(this, {
            icon: new FormControl(''),
            title: new FormControl('New Page', Validators.required),
            action: MenuItemActionControl.inject(injector),
            children: CustomizeBarPagesEditMenuItemArray.inject(injector),
            visible_input: new FieldInputControl({ path: ['value'] })
        }) || this;
        _this.injector = injector;
        return _this;
    }
    CustomizeBarPagesEditSimpleControl.inject = function (injector) {
        return Injector.create({
            providers: [
                {
                    provide: CustomizeBarPagesEditSimpleControl,
                    useFactory: function () {
                        return new CustomizeBarPagesEditSimpleControl(injector);
                    },
                    deps: []
                }
            ],
            parent: injector
        }).get(CustomizeBarPagesEditSimpleControl);
    };
    Object.defineProperty(CustomizeBarPagesEditSimpleControl.prototype, "children", {
        get: function () {
            return this.controls['children'];
        },
        enumerable: true,
        configurable: true
    });
    CustomizeBarPagesEditSimpleControl.prototype.deserialize = function (item) {
        this.menuItem = item;
        this.controls.icon.patchValue(item.icon);
        this.controls.title.patchValue(item.title);
        this.controls.action.deserialize(item.action);
        this.children.deserialize(item.children);
        this.controls.visible_input.patchValue(item.visibleInput ? item.visibleInput.serializeWithoutPath() : {});
        this.markAsPristine();
    };
    CustomizeBarPagesEditSimpleControl.prototype.serialize = function () {
        var instance = new SimpleMenuItem().deserialize(this.menuItem.serialize());
        instance.icon = this.controls.icon.value;
        instance.title = this.controls.title.value;
        instance.action = this.controls.action.serialize();
        instance.children = this.children.serialize();
        instance.visibleInput = this.controls.visible_input.value
            ? new Input().deserialize(this.controls.visible_input.value)
            : undefined;
        return instance;
    };
    return CustomizeBarPagesEditSimpleControl;
}(CustomizeBarPagesEditMenuItemControl));
export { CustomizeBarPagesEditSimpleControl };
menuItemControls.push({ menuItem: SimpleMenuItem, control: CustomizeBarPagesEditSimpleControl });
