import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';

import { CustomViewSettings, MarginControl } from '@modules/customize';

@Injectable()
export class PageEditForm extends FormGroup {
  controls: {
    background_color: FormControl;
    background_color_dark: FormControl;
    background_color_2: FormControl;
    background_color_2_dark: FormControl;
    background_color_3: FormControl;
    background_color_3_dark: FormControl;
    background_color_4: FormControl;
    background_color_4_dark: FormControl;
    background_color_5: FormControl;
    background_color_5_dark: FormControl;
    text_color: FormControl;
    text_color_dark: FormControl;
    text_color_2: FormControl;
    text_color_2_dark: FormControl;
    text_color_3: FormControl;
    text_color_3_dark: FormControl;
    border_color: FormControl;
    border_color_dark: FormControl;
    border_color_2: FormControl;
    border_color_2_dark: FormControl;
    border_color_3: FormControl;
    border_color_3_dark: FormControl;
    max_width: FormControl;
    padding: MarginControl;
  };

  instance: CustomViewSettings;

  constructor() {
    super({
      background_color: new FormControl(),
      background_color_dark: new FormControl(),
      background_color_2: new FormControl(),
      background_color_2_dark: new FormControl(),
      background_color_3: new FormControl(),
      background_color_3_dark: new FormControl(),
      background_color_4: new FormControl(),
      background_color_4_dark: new FormControl(),
      background_color_5: new FormControl(),
      background_color_5_dark: new FormControl(),
      text_color: new FormControl(),
      text_color_dark: new FormControl(),
      text_color_2: new FormControl(),
      text_color_2_dark: new FormControl(),
      text_color_3: new FormControl(),
      text_color_3_dark: new FormControl(),
      border_color: new FormControl(),
      border_color_dark: new FormControl(),
      border_color_2: new FormControl(),
      border_color_2_dark: new FormControl(),
      border_color_3: new FormControl(),
      border_color_3_dark: new FormControl(),
      max_width: new FormControl(),
      padding: new MarginControl()
    });
  }

  init(instance: CustomViewSettings) {
    this.instance = instance;

    if (instance) {
      this.controls.background_color.patchValue(instance.backgroundColor);
      this.controls.background_color_dark.patchValue(instance.backgroundColorDark);
      this.controls.background_color_2.patchValue(instance.backgroundColor2);
      this.controls.background_color_2_dark.patchValue(instance.backgroundColor2Dark);
      this.controls.background_color_3.patchValue(instance.backgroundColor3);
      this.controls.background_color_3_dark.patchValue(instance.backgroundColor3Dark);
      this.controls.background_color_4.patchValue(instance.backgroundColor4);
      this.controls.background_color_4_dark.patchValue(instance.backgroundColor4Dark);
      this.controls.background_color_5.patchValue(instance.backgroundColor5);
      this.controls.background_color_5_dark.patchValue(instance.backgroundColor5Dark);
      this.controls.text_color.patchValue(instance.textColor);
      this.controls.text_color_dark.patchValue(instance.textColorDark);
      this.controls.text_color_2.patchValue(instance.textColor2);
      this.controls.text_color_2_dark.patchValue(instance.textColor2Dark);
      this.controls.text_color_3.patchValue(instance.textColor3);
      this.controls.text_color_3_dark.patchValue(instance.textColor3Dark);
      this.controls.border_color.patchValue(instance.borderColor);
      this.controls.border_color_dark.patchValue(instance.borderColorDark);
      this.controls.border_color_2.patchValue(instance.borderColor2);
      this.controls.border_color_2_dark.patchValue(instance.borderColor2Dark);
      this.controls.border_color_3.patchValue(instance.borderColor3);
      this.controls.border_color_3_dark.patchValue(instance.borderColor3Dark);
      this.controls.max_width.patchValue(instance.maxWidth);
      this.controls.padding.deserialize(instance.padding);
    }

    this.markAsPristine();
  }

  submit(): CustomViewSettings {
    const instance = cloneDeep(this.instance);

    instance.backgroundColor = this.controls.background_color.value;
    instance.backgroundColorDark = this.controls.background_color_dark.value;
    instance.backgroundColor2 = this.controls.background_color_2.value;
    instance.backgroundColor2Dark = this.controls.background_color_2_dark.value;
    instance.backgroundColor3 = this.controls.background_color_3.value;
    instance.backgroundColor3Dark = this.controls.background_color_3_dark.value;
    instance.backgroundColor4 = this.controls.background_color_4.value;
    instance.backgroundColor4Dark = this.controls.background_color_4_dark.value;
    instance.backgroundColor5 = this.controls.background_color_5.value;
    instance.backgroundColor5Dark = this.controls.background_color_5_dark.value;
    instance.textColor = this.controls.text_color.value;
    instance.textColorDark = this.controls.text_color_dark.value;
    instance.textColor2 = this.controls.text_color_2.value;
    instance.textColor2Dark = this.controls.text_color_2_dark.value;
    instance.textColor3 = this.controls.text_color_3.value;
    instance.textColor3Dark = this.controls.text_color_3_dark.value;
    instance.borderColor = this.controls.border_color.value;
    instance.borderColorDark = this.controls.border_color_dark.value;
    instance.borderColor2 = this.controls.border_color_2.value;
    instance.borderColor2Dark = this.controls.border_color_2_dark.value;
    instance.borderColor3 = this.controls.border_color_3.value;
    instance.borderColor3Dark = this.controls.border_color_3_dark.value;
    instance.maxWidth = this.controls.max_width.value;
    instance.padding = this.controls.padding.value;

    return instance;
  }
}
