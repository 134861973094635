import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, Observable, throwError } from 'rxjs';
import { catchError, delayWhen, filter, map, switchMap } from 'rxjs/operators';

import { copyTextToClipboard } from '@common/code';
import { DialogButtonHotkey, DialogButtonType, DialogService } from '@common/dialogs';
import { NotificationService } from '@common/notifications';
import { BasePopupComponent } from '@common/popups';
import { AppConfigService } from '@core';
import { ActionType } from '@modules/actions';
import { AnalyticsEvent, IntercomService, UniversalAnalyticsService } from '@modules/analytics';
import { ServerRequestError } from '@modules/api';
import { WorkflowEditController, WorkflowSaveEvent } from '@modules/customize-bar';
import { createFormFieldFactory, FieldOutput, FieldType, getFieldDescriptionByType, ImageFit } from '@modules/fields';
import { ProjectSSOItemForm } from '@modules/project-settings-routes';
import {
  CurrentEnvironmentStore,
  CurrentProjectStore,
  customOAuth2BackendName,
  ProjectPropertyStore
} from '@modules/projects';
import { SharedCustomSSOType, SSOSettings, SSOSettingsService, SSOType } from '@modules/sso';
import { Workflow } from '@modules/workflow';
import { capitalize, controlValue, getExtension, getFilenameWithExtension, isSet } from '@shared';

@Component({
  selector: 'app-sso-settings-edit-popup',
  templateUrl: './sso-settings-edit-popup.component.html',
  providers: [ProjectSSOItemForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SSOSettingsEditPopupComponent implements OnInit, OnDestroy {
  @Input() sso: SSOSettings;
  @Input() initialValue: Partial<SSOSettings> = {};
  @Input() analyticsSource: string;
  @Output() created = new EventEmitter<SSOSettings>();
  @Output() updated = new EventEmitter<SSOSettings>();
  @Output() deleted = new EventEmitter<SSOSettings>();

  loading = false;
  deleteLoading = false;
  submitLoading = false;
  ssoTypes = SSOType;
  metadataFilename: string;
  metadataExtension: string;
  metadataDraggingOver = false;
  authWorkflow: Workflow;
  refreshTokenWorkflow: Workflow;
  customOAuth2BackendName = customOAuth2BackendName;
  createField = createFormFieldFactory();
  parametersGroup = new FormGroup({});
  imageFits = ImageFit;
  sharedCustomSSOWithSide = [SharedCustomSSOType.Firebase, SharedCustomSSOType.Supabase];
  sharedCustomSSOTypes = SharedCustomSSOType;

  constructor(
    @Optional() private popupComponent: BasePopupComponent,
    public currentProjectStore: CurrentProjectStore,
    public currentEnvironmentStore: CurrentEnvironmentStore,
    private ssoSettingsService: SSOSettingsService,
    private workflowEditController: WorkflowEditController,
    private projectPropertyStore: ProjectPropertyStore,
    public form: ProjectSSOItemForm,
    public appConfigService: AppConfigService,
    private intercomService: IntercomService,
    private notificationService: NotificationService,
    private dialogService: DialogService,
    private cd: ChangeDetectorRef,
    private analyticsService: UniversalAnalyticsService
  ) {}

  ngOnInit() {
    this.form.init({ instance: this.sso, initialValue: this.initialValue });

    combineLatest(
      controlValue<Workflow>(this.form.controls.custom.controls.auth_workflow),
      controlValue<Workflow>(this.form.controls.custom.controls.refresh_token_workflow)
    )
      .pipe(untilDestroyed(this))
      .subscribe(([authWorkflow, refreshTokenWorkflow]) => {
        this.authWorkflow = authWorkflow;
        this.refreshTokenWorkflow = refreshTokenWorkflow;
        this.cd.markForCheck();
      });

    this.form.controls.custom.controls.parameters
      .getGroup$()
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        this.parametersGroup = value;
        this.cd.markForCheck();
      });

    if (this.sso) {
      this.analyticsService.sendSimpleEvent(AnalyticsEvent.SSO.UpdateStarted, {
        Type: this.sso.type,
        Source: this.analyticsSource
      });
    } else {
      this.analyticsService.sendSimpleEvent(AnalyticsEvent.SSO.AddStarted, {
        Type: this.initialValue.type,
        Source: this.analyticsSource
      });
    }
  }

  ngOnDestroy(): void {}

  get samlRedirectUrl(): string {
    if (!this.sso) {
      return;
    }

    return `${this.appConfigService.serverBaseUrl}/saml2_auth/acs/${this.sso.uid}/`;
  }

  get oauthRedirectUrl(): string {
    return `${this.appConfigService.serverBaseUrl}/complete/${this.form.controls.oauth2.controls.oauth_params.value['backend']}/`;
  }

  get jetBridgeRedirectUrl(): string {
    if (!this.sso) {
      return;
    }

    return `${this.form.controls.jet_bridge.controls.url.value}external_auth/complete/${this.sso.uid}/`;
  }

  copy(text: string, contentLabel?: string) {
    copyTextToClipboard(text)
      .pipe(untilDestroyed(this))
      .subscribe(success => {
        if (!success) {
          return;
        }

        const description = isSet(contentLabel) ? `${capitalize(contentLabel)} was copied to clipboard` : undefined;
        this.notificationService.info('Copied', description);
      });
  }

  updateMetadataPreview() {
    const value = this.form.controls.saml2.controls.metadata.value;
    const url = value instanceof File ? value.name : value;

    this.metadataFilename = getFilenameWithExtension(url);
    this.metadataExtension = getExtension(url);
    this.cd.markForCheck();
  }

  onMetadataFileChange(el: HTMLInputElement) {
    if (!el.files.length) {
      return;
    }

    const file = el.files[0];

    el.value = null;

    this.form.controls.saml2.controls.metadata.patchValue(file);
    this.form.controls.saml2.controls.metadata.markAsDirty();
    this.updateMetadataPreview();
  }

  onMetadataDragOver(e: DragEvent) {
    e.stopPropagation();
    e.preventDefault();

    this.metadataDraggingOver = true;
    this.cd.markForCheck();
  }

  onMetadataDragLeave(e: DragEvent) {
    e.stopPropagation();
    e.preventDefault();

    this.metadataDraggingOver = false;
    this.cd.markForCheck();
  }

  onMetadataDrop(e: DragEvent) {
    e.stopPropagation();
    e.preventDefault();

    if (!e.dataTransfer.files.length) {
      return;
    }

    const file = e.dataTransfer.files[0];

    this.metadataDraggingOver = false;
    this.cd.markForCheck();

    if (file) {
      this.form.controls.saml2.controls.metadata.setValue(file);
      this.form.markAsPristine();
    }
  }

  submit() {
    this.submitLoading = true;
    this.cd.markForCheck();

    this.form
      .submit()
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          if (this.sso) {
            this.updated.emit(result);

            this.notificationService.success('Saved', `<strong>${this.sso.name}</strong> was successfully updated`);

            this.analyticsService.sendSimpleEvent(AnalyticsEvent.SSO.Updated, {
              Type: this.sso.type,
              Source: this.analyticsSource
            });
          } else {
            this.created.emit(result);

            this.notificationService.success(
              'Created',
              `<strong>${this.sso.name}</strong> was successfully added to current App`
            );

            this.analyticsService.sendSimpleEvent(AnalyticsEvent.SSO.Added, {
              Type: this.initialValue.type,
              Source: this.analyticsSource
            });
          }

          this.analyticsService.sendSimpleEvent(AnalyticsEvent.SSO.Saved, {
            Type: this.sso ? this.sso.type : this.initialValue.type,
            Source: this.analyticsSource
          });

          this.close();
        },
        () => {
          this.notificationService.error('Error', 'Saving External authentication failed');

          this.submitLoading = false;
          this.cd.markForCheck();
        }
      );
  }

  close() {
    if (this.popupComponent) {
      this.popupComponent.close();
    }
  }

  cancel() {
    this.close();

    if (this.sso) {
      this.analyticsService.sendSimpleEvent(AnalyticsEvent.SSO.UpdateCancelled, {
        Type: this.sso.type,
        Source: this.analyticsSource
      });
    } else {
      this.analyticsService.sendSimpleEvent(AnalyticsEvent.SSO.AddCancelled, {
        Type: this.initialValue.type,
        Source: this.analyticsSource
      });
    }

    this.analyticsService.sendSimpleEvent(AnalyticsEvent.SSO.Cancelled, {
      Type: this.sso ? this.sso.type : this.initialValue.type,
      Source: this.analyticsSource
    });
  }

  openChat() {
    this.intercomService.openChat();
  }

  customizeWorkflow(options: {
    triggerLabel?: string;
    triggerIcon?: string;
    workflow?: Workflow;
    triggerOutputs?: FieldOutput[];
    resultOutputs?: FieldOutput[];
  }): Observable<WorkflowSaveEvent> {
    let workflow: Workflow;

    if (options.workflow) {
      workflow = cloneDeep(options.workflow);
    } else {
      workflow = new Workflow();
      workflow.generateUid();
    }

    return this.workflowEditController.open({
      create: !this.authWorkflow,
      workflow: workflow,
      workflowRun: workflow.testRun,
      workflowEditable: true,
      parameters: [],
      triggerOutputs: options.triggerOutputs,
      triggerLabel: options.triggerLabel,
      triggerIcon: options.triggerIcon,
      customizeTrigger: false,
      actionTypesEnabled: [
        ActionType.Query
        // ActionType.Link,
        // ActionType.ExternalLink
      ],
      historyEnabled: true,
      resultEnabled: options.resultOutputs && options.resultOutputs.length > 0,
      resultOutputs: options.resultOutputs,
      analyticsSource: [this.analyticsSource, 'auth_workflow'].join('_')
    });
  }

  customizeAuthWorkflow() {
    this.projectPropertyStore
      .getUser()
      .pipe(
        switchMap(projectProperties => {
          const triggerOutputItems: {
            name: string;
            verboseName: string;
            field: FieldType;
            description?: string;
            icon?: string;
          }[] = [
            ...this.form.controls.custom.controls.parameters.controls.map(control => {
              const fieldDescription = getFieldDescriptionByType(control.controls.field.value);

              return {
                name: control.controls.name.value,
                verboseName: control.controls.name.value,
                field: control.controls.field.value,
                params: control.controls.params.value,
                icon: fieldDescription.icon
              };
            }),
            {
              name: 'username',
              verboseName: 'Email',
              field: FieldType.Text,
              icon: 'email'
            },
            {
              name: 'password',
              verboseName: 'Password',
              field: FieldType.Password,
              icon: 'lock_close'
            },
            {
              name: 'sso',
              verboseName: 'SSO ID',
              field: FieldType.Text,
              icon: 'number'
            }
          ];

          const triggerOutputs = triggerOutputItems.map(item => {
            const output = new FieldOutput();

            output.name = item.name;
            output.verboseName = item.verboseName;
            output.field = item.field;
            output.icon = item.icon;
            output.updateFieldDescription();

            return output;
          });

          const resultOutputs = [
            {
              name: 'email',
              verboseName: 'E-mail',
              field: FieldType.Text,
              required: true,
              description: 'Used as unique username',
              icon: 'email'
            },
            {
              name: 'first_name',
              verboseName: 'First name',
              field: FieldType.Text,
              icon: 'user'
            },
            {
              name: 'last_name',
              verboseName: 'Last name',
              field: FieldType.Text,
              icon: 'user'
            },
            {
              name: 'photo',
              verboseName: 'Photo URL',
              field: FieldType.Text,
              icon: 'image'
            },
            {
              name: 'token',
              verboseName: 'Token',
              field: FieldType.Text,
              description: 'External user token which can be used in custom queries',
              icon: 'key'
            },
            {
              name: 'token_expire',
              verboseName: 'Token expiration date',
              field: FieldType.DateTime,
              description: 'Date when token will be expired and must be refreshed',
              icon: 'calendar'
            },
            {
              name: 'extra_data',
              verboseName: 'Extra data',
              field: FieldType.JSON,
              description: 'Additional data which can be used in other queries',
              icon: 'components'
            },
            ...(projectProperties || []).map(item => {
              return {
                name: item.uid,
                verboseName: item.name,
                ...(item.field && {
                  field: item.field.field,
                  params: item.field.params
                }),
                required: false,
                description: undefined,
                icon: item.fieldDescription.icon
              };
            })
          ].map(item => {
            const output = new FieldOutput();

            output.name = item.name;
            output.verboseName = item.verboseName;
            output.description = item.description;
            output.field = item.field;
            output.icon = item.icon;
            output.required = item.required;
            output.updateFieldDescription();

            return output;
          });

          return this.customizeWorkflow({
            triggerLabel: 'When login clicked',
            triggerIcon: 'signin',
            workflow: this.authWorkflow,
            triggerOutputs: triggerOutputs,
            resultOutputs: resultOutputs
          });
        }),
        filter(result => !result.cancelled),
        untilDestroyed(this)
      )
      .subscribe(result => {
        result.workflow.testRun = result.workflowRun;

        this.form.controls.custom.controls.auth_workflow.patchValue(result.workflow);
        this.cd.markForCheck();
      });
  }

  customizeRefreshTokenWorkflow() {
    const triggerOutputItems: {
      name: string;
      verboseName: string;
      field: FieldType;
      description?: string;
      icon?: string;
    }[] = [
      ...this.form.controls.custom.controls.parameters.controls.map(control => {
        const fieldDescription = getFieldDescriptionByType(control.controls.field.value);

        return {
          name: control.controls.name.value,
          verboseName: control.controls.name.value,
          field: control.controls.field.value,
          params: control.controls.params.value,
          icon: fieldDescription.icon
        };
      }),
      {
        name: 'token',
        verboseName: 'Token',
        field: FieldType.Text,
        description: 'External user token which can be used in custom queries',
        icon: 'key'
      },
      {
        name: 'token_expire',
        verboseName: 'Token expiration date',
        field: FieldType.DateTime,
        description: 'Date when token will be expired and must be refreshed',
        icon: 'calendar'
      },
      {
        name: 'extra_data',
        verboseName: 'Extra data',
        field: FieldType.JSON,
        description: 'Additional data which can be used in other queries',
        icon: 'components'
      },
      {
        name: 'email',
        verboseName: 'E-mail',
        field: FieldType.Text,
        description: 'Used as unique username',
        icon: 'email'
      },
      {
        name: 'sso',
        verboseName: 'SSO ID',
        field: FieldType.Text,
        icon: 'number'
      }
    ];

    const triggerOutputs = triggerOutputItems.map(item => {
      const output = new FieldOutput();

      output.name = item.name;
      output.verboseName = item.verboseName;
      output.description = item.description;
      output.field = item.field;
      output.icon = item.icon;
      output.updateFieldDescription();

      return output;
    });

    const resultOutputs = [
      {
        name: 'token',
        verboseName: 'Token',
        field: FieldType.Text,
        description: 'External user token which can be used in custom queries',
        icon: 'key',
        required: true
      },
      {
        name: 'token_expire',
        verboseName: 'Token expiration date',
        field: FieldType.DateTime,
        description: 'Date when token will be expired and must be refreshed',
        icon: 'date',
        required: true
      },
      {
        name: 'extra_data',
        verboseName: 'Extra data',
        field: FieldType.JSON,
        description: 'Additional data which can be used in other queries',
        icon: 'components'
      }
    ].map(item => {
      const output = new FieldOutput();

      output.name = item.name;
      output.verboseName = item.verboseName;
      output.description = item.description;
      output.field = item.field;
      output.icon = item.icon;
      output.required = item.required;
      output.updateFieldDescription();

      return output;
    });

    this.customizeWorkflow({
      triggerLabel: 'When token expired',
      triggerIcon: 'repeat',
      workflow: this.refreshTokenWorkflow,
      triggerOutputs: triggerOutputs,
      resultOutputs: resultOutputs
    })
      .pipe(
        filter(result => !result.cancelled),
        untilDestroyed(this)
      )
      .subscribe(result => {
        result.workflow.testRun = result.workflowRun;

        this.form.controls.custom.controls.refresh_token_workflow.patchValue(result.workflow);
        this.cd.markForCheck();
      });
  }

  deleteRefreshTokenWorkflow() {
    this.dialogService
      .warning({
        title: 'Delete workflow',
        description: `Are you sure want to delete <strong>Refresh token workflow</strong>?`,
        style: 'orange'
      })
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        if (result) {
          this.form.controls.custom.controls.refresh_token_workflow.patchValue(undefined);
          this.cd.markForCheck();
        }
      });
  }

  deleteSSOSettings() {
    this.analyticsService.sendSimpleEvent(AnalyticsEvent.SSO.DeleteStarted, {
      Type: this.sso.type,
      Source: this.analyticsSource
    });

    this.dialogService
      .dialog({
        title: 'Deleting',
        description: `Are you sure want to delete External authentication <strong>${this.sso.name}</strong>?`,
        style: 'orange',
        buttons: [
          {
            name: 'cancel',
            label: 'Cancel',
            type: DialogButtonType.Default,
            hotkey: DialogButtonHotkey.Cancel
          },
          {
            name: 'ok',
            label: 'Delete authentication',
            type: DialogButtonType.Danger,
            hotkey: DialogButtonHotkey.Submit,
            executor: () => {
              this.deleteLoading = true;
              this.cd.markForCheck();

              return this.deleteSSOSettingsProcess();
            }
          }
        ]
      })
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        const submit = result.button == 'ok';

        this.deleteLoading = false;
        this.cd.markForCheck();

        if (submit && result.executorResult) {
          this.deleted.emit(this.sso);
          this.close();
        } else if (!submit) {
          this.analyticsService.sendSimpleEvent(AnalyticsEvent.SSO.DeleteCancelled, {
            Type: this.sso.type,
            Source: this.analyticsSource
          });
        }
      });
  }

  deleteSSOSettingsProcess(): Observable<boolean> {
    return this.ssoSettingsService.delete(this.currentProjectStore.instance.uniqueName, this.sso).pipe(
      delayWhen(() => this.currentProjectStore.getFirst(true)),
      map(() => {
        this.notificationService.success('Deleted', `<strong>${this.sso.name}</strong> was successfully deleted`);

        this.analyticsService.sendSimpleEvent(AnalyticsEvent.SSO.Deleted, {
          Type: this.sso.type,
          Source: this.analyticsSource
        });

        return true;
      }),
      catchError(error => {
        if (error instanceof ServerRequestError && error.errors.length) {
          this.notificationService.error('Delete Failed', error.errors[0]);
        } else {
          this.notificationService.error('Delete Failed', error);
        }

        return throwError(error);
      })
    );
  }
}
