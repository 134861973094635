import { getColorHexStr, getColorVariable } from '@modules/colors';
import { isSet } from '@shared';

export enum BorderStyle {
  Solid = 'solid',
  Dashed = 'dashed',
  Dotted = 'dotted',
  Double = 'Double',
  Groove = 'groove',
  Ridge = 'ridge',
  Inset = 'inset',
  Outset = 'outset'
}

export class Border {
  color: string;
  colorDark: string;
  thickness = 1;
  style: BorderStyle = BorderStyle.Solid;

  constructor(options: Partial<Border> = {}) {
    Object.assign(this, options);
  }

  deserialize(data: Object): this {
    if (isSet(data['thickness'])) {
      this.thickness = data['thickness'];
    }

    if (isSet(data['style'])) {
      this.style = data['style'];
    }

    if (isSet(data['color'])) {
      this.color = data['color'];
    }

    if (isSet(data['color_dark'])) {
      this.colorDark = data['color_dark'];
    }

    return this;
  }

  serialize(): Object {
    return {
      color: this.color,
      color_dark: this.colorDark,
      thickness: this.thickness,
      style: this.style
    };
  }

  isSet(): boolean {
    return (isSet(this.color) || isSet(this.colorDark)) && isSet(this.thickness);
  }

  cssColor(dark: boolean): string {
    const color = dark ? this.colorDark : this.color;

    if (!isSet(color)) {
      return;
    }

    const variable = getColorVariable(color);

    if (isSet(variable)) {
      return `var(--${variable})`;
    }

    return getColorHexStr(color);
  }

  cssBorder(dark: boolean): string {
    const color = this.cssColor(dark);

    if (!isSet(this.thickness) || !isSet(color)) {
      return;
    }

    const result = [`${this.thickness}px`, color, this.style];
    return result.join(' ');
  }
}
