import { Injectable, Injector } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

import { Input } from '@modules/fields';
import { ModelLinkMenuItem } from '@modules/menu';
import { FieldInputControl } from '@modules/parameters';

import {
  CustomizeBarPagesEditMenuItemArray,
  CustomizeBarPagesEditMenuItemControl,
  menuItemControls
} from './customize-bar-pages-edit.form';

@Injectable()
export class CustomizeBarPagesEditModelControl extends CustomizeBarPagesEditMenuItemControl {
  controls: {
    icon: FormControl;
    title: FormControl;
    model: FormControl;
    children: CustomizeBarPagesEditMenuItemArray;
    visible_input: FieldInputControl;
  };

  constructor(private injector: Injector) {
    super({
      icon: new FormControl(''),
      title: new FormControl('New Page', Validators.required),
      model: new FormControl(''),
      children: CustomizeBarPagesEditMenuItemArray.inject(injector),
      visible_input: new FieldInputControl({ path: ['value'] })
    });
  }

  static inject(injector: Injector): CustomizeBarPagesEditModelControl {
    return Injector.create({
      providers: [
        {
          provide: CustomizeBarPagesEditModelControl,
          useFactory: () => {
            return new CustomizeBarPagesEditModelControl(injector);
          },
          deps: []
        }
      ],
      parent: injector
    }).get(CustomizeBarPagesEditModelControl);
  }

  get children() {
    return this.controls['children'] as CustomizeBarPagesEditMenuItemArray;
  }

  deserialize(item: ModelLinkMenuItem) {
    this.menuItem = item;

    this.controls.icon.patchValue(item.icon);
    this.controls.title.patchValue(item.title);
    this.controls.model.patchValue(item.model);
    this.children.deserialize(item.children);
    this.controls.visible_input.patchValue(item.visibleInput ? item.visibleInput.serializeWithoutPath() : {});

    this.markAsPristine();
  }

  serialize(): ModelLinkMenuItem {
    const instance = new ModelLinkMenuItem().deserialize(this.menuItem.serialize());

    instance.icon = this.controls.icon.value;
    instance.title = this.controls.title.value;
    instance.model = this.controls.model.value;
    instance.children = this.children.serialize();
    instance.visibleInput = this.controls.visible_input.value
      ? new Input().deserialize(this.controls.visible_input.value)
      : undefined;

    return instance;
  }
}

menuItemControls.push({ menuItem: ModelLinkMenuItem, control: CustomizeBarPagesEditModelControl });
