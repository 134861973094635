<div *ngIf="loading" class="status-page">
  <div class="status-page__main">
    <div class="status-page__content">
      <h1 class="status-page__title">
        <span [class.loading-animation]="true">
          <span class="stub-text">Join project</span>
        </span>
      </h1>
      <div class="status-page__description">
        <span [class.loading-animation]="true">
          <span class="stub-text">
            User has invited you to join the workspace project. Join now to start collaborating!
          </span>
        </span>
      </div>
    </div>
  </div>
  <div class="status-page__right">
    <div class="status-page__image">
      <div class="status-page__image-inner">
        <span [class.loading-animation]="true">
          <span class="stub-image stub-image_contrast stub-image_square status-page__image-stub"></span>
        </span>
      </div>
    </div>
  </div>
</div>

<div *ngIf="error" class="status-page">
  <div class="status-page__main">
    <div class="status-page__content">
      <h1 class="status-page__title">
        <ng-container *ngIf="notFound">Invite link is wrong</ng-container>
        <ng-container *ngIf="!notFound">Loading failed</ng-container>
      </h1>
      <div class="status-page__description">
        <ng-container *ngIf="notFound">
          Looks like your invitation link has expired or maybe you have followed the wrong link. Please try to ask your
          teammates to invite your again.
        </ng-container>
        <ng-container *ngIf="!notFound">
          Unfortunately we failed to loading information about this invitation
        </ng-container>
      </div>
      <div *ngIf="!isWhiteLabel" class="status-page__actions">
        <a [routerLink]="['/']" class="status-page__button button button_primary button_shadow button_bigger">
          Go to Home page
        </a>
      </div>
    </div>
  </div>
  <div class="status-page__right">
    <div class="status-page__image">
      <div class="status-page__image-inner status-page__image-inner_type_not-found"></div>
    </div>
  </div>
</div>

<div *ngIf="invite" class="status-page">
  <div class="status-page__main">
    <div class="status-page__content">
      <app-project-logo
        class="status-page__logo"
        [color]="invite.project.logoColor ? invite.project.logoColor : ''"
        [logo]="invite.project.logo ? invite.project.logo : ''"
        [initials]="invite.project.initials ? invite.project.initials : ''"
        [fill]="invite.project.logoFill"
      ></app-project-logo>
      <h1 class="status-page__title">
        Join {{ invite.project.name }}<ng-container *ngIf="!isWhiteLabel"> on Jet</ng-container>
      </h1>
      <div class="status-page__description">
        <ng-container *ngIf="invite.inviter">
          <strong>{{ invite.inviter.strFull }} ({{ invite.inviter.email }})</strong> has invited you to join
        </ng-container>
        <ng-container *ngIf="!invite.inviter">
          You were invited to join
        </ng-container>
        <ng-container *ngIf="!isWhiteLabel"> the Jet workspace </ng-container>
        <strong>{{ invite.project.name }}</strong
        >. Join now to start collaborating!
      </div>
      <div class="status-page__actions">
        <a
          href="javascript:void(0)"
          class="status-page__button button button_primary button_shadow button_bigger"
          [class.button_disabled]="submitLoading"
          (click)="accept()"
        >
          Accept
        </a>
        <!--        <a-->
        <!--          href="javascript:void(0)"-->
        <!--          class="status-page__button button button_orange-alternative button_bigger"-->
        <!--          [class.button_disabled]="submitLoading"-->
        <!--          (click)="reject()"-->
        <!--        >-->
        <!--          Reject-->
        <!--        </a>-->
        <a *ngIf="!isWhiteLabel" [routerLink]="['/']" class="status-page__button button button_bigger">
          Go to Home page
        </a>
      </div>
    </div>
  </div>
  <div class="status-page__right">
    <div class="status-page__image">
      <div class="status-page__image-inner status-page__image-inner_type_edit-list"></div>
    </div>
  </div>
</div>
