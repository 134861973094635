import { Injectable, Injector } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

import { Input } from '@modules/fields';
import { SectionMenuItem } from '@modules/menu';
import { FieldInputControl } from '@modules/parameters';

import {
  CustomizeBarPagesEditMenuItemArray,
  CustomizeBarPagesEditMenuItemControl,
  menuItemControls
} from './customize-bar-pages-edit.form';

@Injectable()
export class CustomizeBarPagesEditSectionControl extends CustomizeBarPagesEditMenuItemControl {
  controls: {
    icon: FormControl;
    title: FormControl;
    children: CustomizeBarPagesEditMenuItemArray;
    visible_input: FieldInputControl;
  };

  constructor(private injector: Injector) {
    super({
      icon: new FormControl(''),
      title: new FormControl('New Section', Validators.required),
      children: CustomizeBarPagesEditMenuItemArray.inject(injector),
      visible_input: new FieldInputControl({ path: ['value'] })
    });
  }

  static inject(injector: Injector): CustomizeBarPagesEditSectionControl {
    return Injector.create({
      providers: [
        {
          provide: CustomizeBarPagesEditSectionControl,
          useFactory: () => {
            return new CustomizeBarPagesEditSectionControl(injector);
          },
          deps: []
        }
      ],
      parent: injector
    }).get(CustomizeBarPagesEditSectionControl);
  }

  get children() {
    return this.controls['children'] as CustomizeBarPagesEditMenuItemArray;
  }

  deserialize(item: SectionMenuItem) {
    this.menuItem = item;

    this.controls.icon.patchValue(item.icon);
    this.controls.title.patchValue(item.title);
    this.children.deserialize(item.children);
    this.controls.visible_input.patchValue(item.visibleInput ? item.visibleInput.serializeWithoutPath() : {});

    this.markAsPristine();
  }

  serialize(): SectionMenuItem {
    const instance = new SectionMenuItem().deserialize(this.menuItem.serialize());

    instance.icon = this.controls.icon.value;
    instance.title = this.controls.title.value;
    instance.children = this.children.serialize();
    instance.visibleInput = this.controls.visible_input.value
      ? new Input().deserialize(this.controls.visible_input.value)
      : undefined;

    return instance;
  }
}

menuItemControls.push({ menuItem: SectionMenuItem, control: CustomizeBarPagesEditSectionControl });
