import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnDestroy, OnInit } from '@angular/core';
import groupBy from 'lodash/groupBy';
import isEqual from 'lodash/isEqual';
import range from 'lodash/range';
import * as moment from 'moment';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { NotificationService } from '@common/notifications';
import { ActionControllerService } from '@modules/action-queries';
import { TintStyle } from '@modules/actions';
import { AggregateFunc, DatasetGroupLookup } from '@modules/charts';
import { DataSourceType } from '@modules/data-sources';
import { gteFieldLookup, lteFieldLookup } from '@modules/field-lookups';
import { FilterItem2 } from '@modules/filters';
import { ColumnsModelListStore } from '@modules/list';
import { ModelService } from '@modules/model-queries';
import { DATE_PARAM, TYPE_PARAM } from '@modules/models';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { ChartWidgetQuery } from '@modules/queries';
import { getQueryOptionsToParams, paramsToGetQueryOptions } from '@modules/resources';

import { CalendarType } from '../../data/calendar-type';
import { CalendarBaseComponent } from '../calendar-base/calendar-base.component';
import { DayOfWeek, WeeksDay } from '../calendar-month/calendar-month.component';
import { CalendarState, getListStateDayMonthFetch } from '../calendar/calendar-state';

export interface Hour {
  date: moment.Moment;
  key: string;
}

@Component({
  selector: 'app-calendar-day',
  templateUrl: './calendar-day.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ColumnsModelListStore]
})
export class CalendarDayComponent extends CalendarBaseComponent implements OnInit, OnDestroy {
  hours: Hour[] = [];
  weekDays: DayOfWeek[] = [];
  weeks: WeeksDay[][] = [];
  monthItems = {};
  monthItemsGroupBy = 'DD.MM.YYYY';
  monthItemsLoading = false;
  tintStyles = TintStyle;

  constructor(
    private modelService: ModelService,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    injector: Injector,
    actionControllerService: ActionControllerService,
    listStore: ColumnsModelListStore,
    notificationService: NotificationService,
    cd: ChangeDetectorRef
  ) {
    super(injector, actionControllerService, listStore, notificationService, cd);
  }

  onStateUpdated(state: CalendarState, prevState: CalendarState) {
    if (!isEqual(getListStateDayMonthFetch(state), getListStateDayMonthFetch(prevState))) {
      this.updateMonth(state);
    }
  }

  fetch(state: CalendarState) {
    const queryOptions = paramsToGetQueryOptions(state.dataSourceParams);
    const dateFilters = this.dateFilterFilters(state);

    queryOptions.filters = [
      ...(queryOptions.filters ? queryOptions.filters : []),
      ...(state.filters ? state.filters : []),
      ...(dateFilters ? dateFilters : [])
    ];
    queryOptions.search = state.search;
    queryOptions.sort = state.sort;

    this.listStore.dataSource = state.dataSource;
    this.listStore.useDataSourceColumns = true;
    this.listStore.staticData = state.dataSourceStaticData;
    this.listStore.queryOptions = queryOptions;
    this.listStore.context = this.context;
    this.listStore.contextElement = this.contextElement;

    this.getItems();
    this.updateVisibleColumns(state);
    this.updateWeeks(state);
    this.updateHours(state);

    this.prevDate = state.date;
  }

  get now() {
    return moment();
  }

  updateHours(state: CalendarState) {
    if (!state.date) {
      this.hours = [];
      this.cd.markForCheck();
      return;
    }

    this.hours = range(0, 24).map(hour => {
      const date = this.date.clone().startOf('date').add(hour, 'hours');

      return {
        date: date,
        key: date.format(this.itemsGroupBy())
      };
    });

    this.cd.markForCheck();
  }

  updateMonth(state: CalendarState) {
    this.updateWeeks(state);
    this.updateMonthItems(state);
  }

  updateWeeks(state: CalendarState) {
    if (!state.date) {
      this.weekDays = [];
      this.weeks = [];
      this.cd.markForCheck();
      return;
    }

    const month = state.date.clone().startOf('month');
    const firstDay = month.clone().startOf('isoWeek');
    const lastDay = month.clone().endOf('month').endOf('isoWeek');
    const weeks = Math.ceil(lastDay.diff(firstDay, 'weeks', true));

    this.weekDays = range(0, 6 + 1).map(day => {
      const date = firstDay.clone().add(day, 'days');

      return {
        date: date,
        weekend: [6, 0].includes(date.day()),
        today: date.isSame(this.now, 'day') && date.isSame(this.now, 'month') && date.isSame(this.now, 'year')
      };
    });

    this.weeks = range(0, weeks).map(week => {
      return range(0, 7).map(day => {
        const date = firstDay.clone().add(week, 'weeks').add(day, 'days');

        return {
          date: date,
          today: date.isSame(this.now, 'day') && date.isSame(this.now, 'month') && date.isSame(this.now, 'year'),
          current:
            date.isSame(state.date, 'day') && date.isSame(state.date, 'month') && date.isSame(state.date, 'year'),
          currentMonth: date.isSame(month, 'month') && date.isSame(month, 'year'),
          weekend: [6, 0].includes(date.day()),
          future: date.isAfter(this.now)
        };
      });
    });

    this.cd.markForCheck();
  }

  updateMonthItems(state: CalendarState) {
    this.monthItems = {};
    this.cd.detectChanges();

    if (!state.date || !state.dataSource || !state.dataSource.query) {
      return;
    }

    const resource =
      state.settings.dataSource && state.settings.dataSource.type == DataSourceType.Query
        ? this.currentEnvironmentStore.resources.find(
            item => item.uniqueName == state.settings.dataSource.queryResource
          )
        : undefined;

    const modelQuery = state.dataSource.query;
    const query = new ChartWidgetQuery();

    query.queryType = modelQuery.queryType;
    query.simpleQuery = modelQuery.simpleQuery;
    query.httpQuery = modelQuery.httpQuery;
    query.sqlQuery = modelQuery.sqlQuery;

    const queryOptions = paramsToGetQueryOptions(state.dataSourceParams);
    const firstDay = state.date.clone().startOf('month');
    const lastDay = state.date.clone().endOf('month');

    queryOptions.filters = [
      ...(queryOptions.filters ? queryOptions.filters : []),
      ...(state.filters ? state.filters : []),
      new FilterItem2({
        field: [state.dateField],
        lookup: gteFieldLookup,
        value: firstDay.toISOString()
      }),
      new FilterItem2({
        field: [state.dateField],
        lookup: lteFieldLookup,
        value: lastDay.toISOString()
      })
    ];

    const params = getQueryOptionsToParams(queryOptions);

    this.monthItemsLoading = true;
    this.cd.markForCheck();

    this.modelService
      .groupQuery(
        this.currentProjectStore.instance,
        this.currentEnvironmentStore.instance,
        resource,
        query,
        [{ xColumn: state.settings.dateField, xLookup: DatasetGroupLookup.DateDay }],
        AggregateFunc.Count,
        undefined,
        state.dataSource.queryParameters,
        this.applyParams(params)
      )
      .pipe(untilDestroyed(this))
      .subscribe(
        items => {
          this.monthItems = items ? groupBy(items, item => moment(item['group']).format(this.monthItemsGroupBy)) : {};
          this.monthItemsLoading = false;
          this.cd.markForCheck();
        },
        () => {
          this.monthItemsLoading = false;
          this.cd.markForCheck();
        }
      );
  }

  dayParams(date) {
    const params = {};
    params[TYPE_PARAM] = CalendarType.Day;
    params[DATE_PARAM] = date.toISOString();
    return this.applyParams(params);
  }

  dateFilterFilters(state: CalendarState): FilterItem2[] {
    const firstDay = state.date.clone().startOf('date');
    const lastDay = state.date.clone().endOf('date');

    return [
      new FilterItem2({
        field: [state.dateField],
        lookup: gteFieldLookup,
        value: firstDay.toISOString()
      }),
      new FilterItem2({
        field: [state.dateField],
        lookup: lteFieldLookup,
        value: lastDay.toISOString()
      })
    ];
  }

  itemsGroupBy(): string {
    return 'HH';
  }
}
