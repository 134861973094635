import keys from 'lodash/keys';
import pickBy from 'lodash/pickBy';

import { isSet } from '@shared';

import { Margin } from './elements/items/base';
import { ListElementItem } from './elements/items/list-element';
import { TableSettings } from './table-settings';
import { TextStyle } from './text-style';

export class TableListElementStyles {
  titleStyle?: TextStyle;
  groupTitleStyle?: TextStyle;
  margin?: Margin;

  constructor(options: Partial<TableListElementStyles> = {}) {
    Object.assign(this, options);
  }

  deserialize(data: Object): this {
    if (data['title_style']) {
      this.titleStyle = new TextStyle().deserialize(data['title_style']);
    } else {
      this.titleStyle = undefined;
    }

    if (data['group_title_style']) {
      this.groupTitleStyle = new TextStyle().deserialize(data['group_title_style']);
    } else {
      this.groupTitleStyle = undefined;
    }

    if (data['margin']) {
      this.margin = data['margin'];
    } else {
      this.margin = undefined;
    }

    return this;
  }

  serialize(): Object {
    return {
      title_style: this.titleStyle ? this.titleStyle.serialize() : undefined,
      group_title_style: this.groupTitleStyle ? this.groupTitleStyle.serialize() : undefined,
      margin: this.margin
    };
  }

  apply(other: this): this {
    const properties: (keyof TableListElementStyles)[] = ['titleStyle', 'groupTitleStyle', 'margin'];

    properties.forEach(property => {
      if (isSet(other[property])) {
        this[property] = other[property];
      }
    });

    return this;
  }
}

export function getTableListElementStyles(element: ListElementItem, layout: TableSettings): TableListElementStyles {
  const options: Partial<TableListElementStyles> = pickBy(
    {
      titleStyle: layout.titleStyle,
      groupTitleStyle: layout.groupTitleStyle,
      ...(keys(element.margin).length && {
        margin: element.margin
      })
    },
    v => isSet(v)
  );

  if (!keys(options).length) {
    return;
  }

  return new TableListElementStyles(options);
}

export function applyTableListElementStyles(
  element: ListElementItem,
  layout: TableSettings,
  styles?: TableListElementStyles
) {
  if (!styles) {
    styles = new TableListElementStyles();
  }

  layout.titleStyle = styles.titleStyle;
  layout.groupTitleStyle = styles.groupTitleStyle;
  element.margin = styles.margin;
}
