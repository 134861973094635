var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';
import { FillEditContext } from '@modules/colors-components';
import { AlignHorizontal, FieldElementStyles } from '@modules/customize';
import { FieldElementStylesControl } from '@modules/customize-bar';
import { controlValue } from '@shared';
var StylesPreviewFieldComponent = /** @class */ (function () {
    function StylesPreviewFieldComponent(fillEditContext, cd) {
        this.fillEditContext = fillEditContext;
        this.cd = cd;
        this.fieldTypes = [
            { label: 'Field with value', value: 'Test value' },
            { label: 'Empty field', value: '' }
        ];
        this.alignHorizontals = AlignHorizontal;
    }
    StylesPreviewFieldComponent.prototype.ngOnInit = function () {
        var _this = this;
        combineLatest(controlValue(this.control), this.control.stylesDefaultUpdated$())
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            _this.elementStyles = new FieldElementStyles({
                textStyle: _this.control.controls.text_style.serialize(false),
                placeholderStyle: _this.control.controls.placeholder_style.serialize(false),
                labelStyle: _this.control.controls.label_style.serialize(false),
                labelAdditionalStyle: _this.control.controls.label_additional_style.serialize(false),
                fillSettings: _this.control.controls.fill_settings.serialize(false),
                borderSettings: _this.control.controls.border_settings.serialize(false),
                borderRadius: _this.control.controls.border_radius.serialize(),
                shadow: _this.control.controls.shadow.serialize(false),
                padding: _this.control.controls.padding.serialize(),
                margin: _this.control.controls.margin.serialize()
            });
            _this.hoverElementStyles = new FieldElementStyles(__assign({ textStyle: _this.control.controls.text_style.serialize(false), placeholderStyle: _this.control.controls.placeholder_style.serialize(false), labelStyle: _this.control.controls.label_style.serialize(false), labelAdditionalStyle: _this.control.controls.label_additional_style.serialize(false), fillSettings: _this.control.controls.fill_settings.serialize(false), borderSettings: _this.control.controls.border_settings.serialize(false), borderRadius: _this.control.controls.border_radius.serialize(), shadow: _this.control.controls.shadow.serialize(false), padding: _this.control.controls.padding.serialize(), margin: _this.control.controls.margin.serialize() }, (_this.control.controls.hover_text_style.isSet() && {
                textStyle: _this.control.controls.hover_text_style.serialize(false)
            }), (_this.control.controls.hover_placeholder_style.isSet() && {
                placeholderStyle: _this.control.controls.hover_placeholder_style.serialize(false)
            }), (_this.control.controls.hover_fill_settings.isSet() && {
                fillSettings: _this.control.controls.hover_fill_settings.serialize(false)
            }), (_this.control.controls.hover_border_settings.isSet() && {
                borderSettings: _this.control.controls.hover_border_settings.serialize(false)
            }), (_this.control.controls.hover_shadow.isSet() && {
                shadow: _this.control.controls.hover_shadow.serialize(false)
            })));
            _this.focusElementStyles = new FieldElementStyles(__assign({ textStyle: _this.control.controls.text_style.serialize(false), placeholderStyle: _this.control.controls.placeholder_style.serialize(false), labelStyle: _this.control.controls.label_style.serialize(false), labelAdditionalStyle: _this.control.controls.label_additional_style.serialize(false), fillSettings: _this.control.controls.fill_settings.serialize(false), borderSettings: _this.control.controls.border_settings.serialize(false), borderRadius: _this.control.controls.border_radius.serialize(), shadow: _this.control.controls.shadow.serialize(false), padding: _this.control.controls.padding.serialize(), margin: _this.control.controls.margin.serialize() }, (_this.control.controls.focus_text_style.isSet() && {
                textStyle: _this.control.controls.focus_text_style.serialize(false)
            }), (_this.control.controls.focus_placeholder_style.isSet() && {
                placeholderStyle: _this.control.controls.focus_placeholder_style.serialize(false)
            }), (_this.control.controls.focus_fill_settings.isSet() && {
                fillSettings: _this.control.controls.focus_fill_settings.serialize(false)
            }), (_this.control.controls.focus_border_settings.isSet() && {
                borderSettings: _this.control.controls.focus_border_settings.serialize(false)
            }), (_this.control.controls.focus_shadow.isSet() && {
                shadow: _this.control.controls.focus_shadow.serialize(false)
            })));
            _this.errorElementStyles = new FieldElementStyles(__assign({ textStyle: _this.control.controls.text_style.serialize(false), placeholderStyle: _this.control.controls.placeholder_style.serialize(false), labelStyle: _this.control.controls.label_style.serialize(false), labelAdditionalStyle: _this.control.controls.label_additional_style.serialize(false), fillSettings: _this.control.controls.fill_settings.serialize(false), borderSettings: _this.control.controls.border_settings.serialize(false), borderRadius: _this.control.controls.border_radius.serialize(), shadow: _this.control.controls.shadow.serialize(false), padding: _this.control.controls.padding.serialize(), margin: _this.control.controls.margin.serialize() }, (_this.control.controls.error_text_style.isSet() && {
                textStyle: _this.control.controls.error_text_style.serialize(false)
            }), (_this.control.controls.error_placeholder_style.isSet() && {
                placeholderStyle: _this.control.controls.error_placeholder_style.serialize(false)
            }), (_this.control.controls.error_fill_settings.isSet() && {
                fillSettings: _this.control.controls.error_fill_settings.serialize(false)
            }), (_this.control.controls.error_border_settings.isSet() && {
                borderSettings: _this.control.controls.error_border_settings.serialize(false)
            }), (_this.control.controls.error_shadow.isSet() && {
                shadow: _this.control.controls.error_shadow.serialize(false)
            })));
            _this.cd.markForCheck();
        });
        if (this.fillEditContext) {
            this.fillEditContext.customizingGradient$.pipe(untilDestroyed(this)).subscribe(function (value) {
                _this.customizeGradient = value;
                _this.cd.detectChanges();
            });
        }
    };
    StylesPreviewFieldComponent.prototype.ngOnDestroy = function () { };
    return StylesPreviewFieldComponent;
}());
export { StylesPreviewFieldComponent };
