<div class="popover2 overlay-content overlay-content_animated">
  <div class="popover2__inner popover2__inner_no-margin-collapse" [style.width.px]="295">
    <div class="sidebar__element sidebar__element_margin_s">
      <div class="sidebar-cols">
        <div class="sidebar-cols__item">
          <div class="button-group">
            <div
              class="button-group__item"
              [class.button-group__item_active]="themeService.isDefaultTheme"
              (click)="themeService.theme = 'default'"
            >
              <span class="button-group__item-icon icon-sun"></span>
            </div>

            <div
              class="button-group__item"
              [class.button-group__item_active]="themeService.isDarkTheme"
              (click)="themeService.theme = 'dark'"
            >
              <span class="button-group__item-icon icon-moon"></span>
            </div>
          </div>
        </div>

        <div class="sidebar-cols__item sidebar-cols__item_fit">
          <button type="submit" class="button button_extra-small button_no-margin" (click)="resetCurrentColor()">
            <span class="button__icon button__icon_left icon-close"></span>
            <span class="button__label">Reset</span>
          </button>
        </div>
      </div>
    </div>

    <app-color-picker *ngIf="themeService.isDefaultTheme" [hexControl]="colorDefaultControl"></app-color-picker>

    <app-color-picker *ngIf="themeService.isDarkTheme" [hexControl]="colorDarkControl"></app-color-picker>
  </div>
</div>
