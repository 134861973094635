export interface Icon {
  name: string;
  label: string;
}

export const icons: Icon[] = [
  'admins',
  'after_date_including',
  'after_date',
  'alarm',
  'alert',
  'align_center',
  'align_justify',
  'align_left',
  'align_right',
  'align_horizontal_center',
  'align_horizontal_fill',
  'align_horizontal_left',
  'align_horizontal_right',
  'align_vertical_bottom',
  'align_vertical_center',
  'align_vertical_fill',
  'align_vertical_top',
  'antenna',
  'api',
  'apple',
  'arrow_backward_2',
  'arrow_backward_3',
  'arrow_backward',
  'arrow_backward_turn',
  'arrow_decrease',
  'arrow_down_2',
  'arrow_down_3',
  'arrow_down',
  'arrow_down_turn',
  'arrow_forward_2',
  'arrow_forward_3',
  'arrow_forward',
  'arrow_forward_turn',
  'arrow_increase',
  'arrow_up_2',
  'arrow_up_3',
  'arrow_up',
  'arrow_up_turn',
  'arrows_backward',
  'arrows_down',
  'arrows_forward',
  'arrows_up',
  'attach_clip',
  'audio',
  'bank',
  'bar_code',
  'battery_half_power',
  'battery_low',
  'before_date_including',
  'before_date',
  'bell_filled',
  'bell',
  'bills',
  'bin',
  'blocks',
  'book',
  'bookmakers',
  'briefcase',
  'browser',
  'brush',
  'calendar',
  'camera',
  'canvas',
  'capitalize',
  'chart',
  'chat',
  'check_2',
  'check_3',
  'check_4',
  'check_circle',
  'check',
  'chemistry',
  'cherry',
  'chip',
  'circle',
  'circle_2',
  'circle_filled_2',
  'close',
  'cloud_download',
  'cloud_sync',
  'cloud_upload',
  'cloud',
  'comments',
  'components',
  'components_2',
  'configure',
  'console',
  'contains',
  'contrast',
  'copy',
  'corners',
  'corner_top_left',
  'corner_top_right',
  'corner_bottom_right',
  'corner_bottom_left',
  'countries',
  'croissant',
  'cup_of_coffee',
  'currency',
  'cursor',
  'cut',
  'dash',
  'dashboard',
  'data',
  'delete',
  'deselect',
  'design',
  'diagram_2',
  'diagram',
  'diamond',
  'dishes',
  'document',
  'documents',
  'dot',
  'dots_small',
  'dots',
  'download',
  'dropper',
  'duplicate_2',
  'duplicate',
  'earth_planet',
  'edit',
  'email',
  'ends_with',
  'enlarge_expand',
  'equals',
  'external_link',
  'eye_2',
  'eye_disabled_2',
  'eye_disabled',
  'eye',
  'facebook',
  'feather',
  'fileds',
  'filter_2',
  'filter_down',
  'filter_up',
  'filter',
  'fixed_horizontal',
  'folder',
  'football_sports',
  'function',
  'gamepad',
  'gear_2',
  'gear',
  'gift',
  'glasses',
  'greater_than_or_equals',
  'greater_than',
  'hand',
  'hand_regular',
  'health',
  'heart',
  'help_filled',
  'help',
  'history',
  'home',
  'human_being',
  'idea',
  'image',
  'incoming_call',
  'input',
  'is_null',
  'key',
  'layers_2',
  'layers',
  'leagues',
  'less_than_or_equals',
  'less_than',
  'letter_template',
  'line',
  'link',
  'link_horizontal',
  'linkedin',
  'location',
  'lock_close',
  'lock_open',
  'log_out',
  'longtext',
  'lookup',
  'lowercase',
  'magic_wand',
  'magnet',
  'matches',
  'math',
  'menu',
  'minus_circle',
  'minus',
  'model_link',
  'moon',
  'more',
  'move',
  'multiselect',
  'new_document',
  'news',
  'notification',
  'number',
  'one_of',
  'open_folder',
  'open_site_countries',
  'output',
  'pages',
  'palette',
  'pause_3',
  'payments',
  'pen',
  'percent',
  'phone',
  'pin_2',
  'pin',
  'pizza',
  'play_2',
  'play_3',
  'play_filled',
  'play',
  'plug',
  'plus_circle',
  'plus',
  'pocket',
  'polygon',
  'polygon_filled',
  'posts',
  'power_measure',
  'power',
  'proposed_restaurants',
  'qr_code',
  'ranks',
  'rectangle',
  'rectangle_filled',
  'rectangle_rounded',
  'rectangle_rounded_filled',
  'redo',
  'reflect',
  'repeat',
  'resize_horizontal',
  'resize_vertical',
  'richtext',
  'rocket',
  'save',
  'search',
  'section',
  'select_all',
  'select',
  'sending',
  'server',
  'settings',
  'shield',
  'shopping_bag',
  'shopping_cart_fool',
  'shopping_cart',
  'sides',
  'signin',
  'signup',
  'sort',
  'spiral',
  'square',
  'square_filled',
  'square_rounded',
  'square_rounded_filled',
  'star',
  'star_filled',
  'starts_with',
  'strikethrough',
  'sun',
  'switch',
  'sync_disable',
  'sync',
  'table_dashboard',
  'table',
  'tag',
  'target',
  'taxi',
  'teams',
  'text',
  'text_2',
  'text_height',
  'text_width',
  'time',
  'tips',
  'toggle_theme',
  'transactions',
  'triangle',
  'triangle_filled',
  'twitter',
  'uncheck',
  'underline',
  'undo',
  'upload',
  'uppercase',
  'user',
  'users_teams',
  'variable',
  'versions',
  'warning_filled',
  'warning',
  'whistle',
  'windows',
  'workflow'
].map(item => {
  return {
    name: item,
    label: item
  };
});
