/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../theme-gallery-item/theme-gallery-item.component.ngfactory";
import * as i2 from "../../../theme-components/data/theme-context";
import * as i3 from "../theme-gallery-item/theme-gallery-item.component";
import * as i4 from "../../../theme/services/theme/theme.service";
import * as i5 from "../../../theme-components/components/external-fonts/external-fonts.component.ngfactory";
import * as i6 from "../../../theme-components/components/external-fonts/external-fonts.component";
import * as i7 from "@angular/platform-browser";
import * as i8 from "../../../sidebar/components/sidebar-field/sidebar-field.component.ngfactory";
import * as i9 from "../../../sidebar/components/sidebar-field/sidebar-field.component";
import * as i10 from "@angular/common";
import * as i11 from "./theme-gallery.component";
import * as i12 from "../../../users/stores/current-user.store";
import * as i13 from "../../../projects/stores/current-environment.store";
var styles_ThemeGalleryComponent = [];
var RenderType_ThemeGalleryComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ThemeGalleryComponent, data: {} });
export { RenderType_ThemeGalleryComponent as RenderType_ThemeGalleryComponent };
function View_ThemeGalleryComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, [[2, 0]], null, 2, "app-theme-gallery-item", [["class", "sidebar-gallery__item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.applyThemeTemplate(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_ThemeGalleryItemComponent_0, i1.RenderType_ThemeGalleryItemComponent)), i0.ɵprd(135680, null, i2.ThemeContext, i2.ThemeContext, []), i0.ɵdid(2, 245760, [["item_element", 4]], 0, i3.ThemeGalleryItemComponent, [i4.ThemeService, i0.ChangeDetectorRef], { theme: [0, "theme"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ThemeGalleryComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "sidebar__element"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "button", [["class", "button button_fill button_orange-alternative staff-block"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dumpThemeTemplate() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "span", [["class", "button__icon icon-palette"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "span", [["class", "button__label"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Dump Current theme"]))], null, null); }
export function View_ThemeGalleryComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(402653184, 1, { viewportElement: 0 }), i0.ɵqud(671088640, 2, { itemElements: 1 }), (_l()(), i0.ɵeld(2, 0, null, null, 1, "app-external-fonts", [], null, null, null, i5.View_ExternalFontsComponent_0, i5.RenderType_ExternalFontsComponent)), i0.ɵdid(3, 638976, null, 0, i6.ExternalFontsComponent, [i7.DomSanitizer], { fonts: [0, "fonts"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 14, "div", [["class", "sidebar__element"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 13, "app-sidebar-field", [], null, null, null, i8.View_SidebarFieldComponent_0, i8.RenderType_SidebarFieldComponent)), i0.ɵdid(6, 114688, null, 0, i9.SidebarFieldComponent, [], { label: [0, "label"] }, null), (_l()(), i0.ɵeld(7, 0, null, 1, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 1, "button", [["class", "button button_icon button_extra-small button_background button_segment-left button_no-margin"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.scrollToPrev() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 0, "span", [["class", "button__icon icon-arrow_backward_2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 1, "button", [["class", "button button_icon button_extra-small button_background button_segment-right button_no-margin"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.scrollToNext() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 0, "span", [["class", "button__icon icon-arrow_forward_2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, 3, 6, "div", [["class", "sidebar-gallery"]], null, null, null, null, null)), (_l()(), i0.ɵeld(13, 0, [[1, 0], ["viewport_element", 1]], null, 5, "div", [["class", "sidebar-gallery__viewport"]], null, [[null, "scroll"], [null, "mousewheel"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("scroll" === en)) {
        var pd_0 = (_co.onScroll() !== false);
        ad = (pd_0 && ad);
    } if (("mousewheel" === en)) {
        var pd_1 = (_co.onMouseWheel($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 4, "div", [["class", "sidebar-gallery__items"]], null, null, null, null, null)), (_l()(), i0.ɵeld(15, 0, null, null, 0, "div", [["class", "sidebar-gallery__items-padding"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ThemeGalleryComponent_1)), i0.ɵdid(17, 278528, null, 0, i10.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(18, 0, null, null, 0, "div", [["class", "sidebar-gallery__items-padding"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_ThemeGalleryComponent_2)), i0.ɵdid(20, 16384, null, 0, i10.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i10.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.externalFonts; _ck(_v, 3, 0, currVal_0); var currVal_1 = "Theme gallery"; _ck(_v, 6, 0, currVal_1); var currVal_4 = _co.items; _ck(_v, 17, 0, currVal_4); var tmp_5_0 = null; var currVal_5 = (((tmp_5_0 = i0.ɵunv(_v, 20, 0, i0.ɵnov(_v, 21).transform(_co.currentUserStore.instance$))) == null) ? null : tmp_5_0.isStaff); _ck(_v, 20, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = !_co.isScrollPrevAvailable(); _ck(_v, 8, 0, currVal_2); var currVal_3 = !_co.isScrollNextAvailable(); _ck(_v, 10, 0, currVal_3); }); }
export function View_ThemeGalleryComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-theme-gallery", [], null, null, null, View_ThemeGalleryComponent_0, RenderType_ThemeGalleryComponent)), i0.ɵdid(1, 245760, null, 0, i11.ThemeGalleryComponent, [i12.CurrentUserStore, i13.CurrentEnvironmentStore, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ThemeGalleryComponentNgFactory = i0.ɵccf("app-theme-gallery", i11.ThemeGalleryComponent, View_ThemeGalleryComponent_Host_0, { settingsForm: "settingsForm", menuForm: "menuForm", applyEnabled: "applyEnabled" }, { applyClick: "applyClick" }, []);
export { ThemeGalleryComponentNgFactory as ThemeGalleryComponentNgFactory };
