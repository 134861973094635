import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  CardLayoutElementItem,
  ColumnsLayoutColumnElementItem,
  ColumnsLayoutElementItem,
  CustomViewSettings,
  validateElementNames,
  VALUE_OUTPUT,
  ViewSettings,
  ViewSettingsStore
} from '@modules/customize';
import { Input, InputValueType } from '@modules/fields';
import { ITEM_OUTPUT } from '@modules/list';
import { ModelDescription } from '@modules/models';
import { modelDescriptionHasAutoParameters } from '@modules/parameters';
import { Project, Resource } from '@modules/projects';
import { Template } from '@modules/template';
import { capitalize, isSet } from '@shared';

import { PersistentIdGenerator } from '../../utils/elements';
import { BackGenerator } from '../back-generator/back-generator.service';
import { CreateFormGenerator } from '../create-form-generator/create-form-generator.service';
import { DeleteButtonGenerator } from '../delete-button-generator/delete-button-generator.service';
import { DetailGenerator } from '../detail-generator/detail-generator.service';
import { FilterGenerator } from '../filter-generator/filter-generator.service';
import { GeneratorUtils } from '../generator-utils/generator-utils.service';
import { ListGenerator } from '../list-generator/list-generator.service';
import { UpdateFormGenerator } from '../update-form-generator/update-form-generator.service';
import { ViewSettingsGeneratorService } from '../view-settings-generator/view-settings-generator.service';

export type PagesGenerationResult = {
  page: CustomViewSettings;
  initial?: boolean;
}[];

@Injectable()
export class AdminPanelSeparatePagesGenerator {
  constructor(
    private viewSettingsStore: ViewSettingsStore,
    private viewSettingsGeneratorService: ViewSettingsGeneratorService,
    private createFormGenerator: CreateFormGenerator,
    private updateFormGenerator: UpdateFormGenerator,
    private detailGenerator: DetailGenerator,
    private deleteButtonGenerator: DeleteButtonGenerator,
    private backGenerator: BackGenerator,
    private listGenerator: ListGenerator,
    private filterGenerator: FilterGenerator,
    private generatorUtils: GeneratorUtils
  ) {}

  getListPage(
    project: Project,
    resource: Resource,
    modelDescription: ModelDescription,
    uniqueName: string,
    options: {
      templates: Template[];
      createUniqueName?: string;
      detailUniqueName?: string;
      listFields?: string[];
      idGenerator?: PersistentIdGenerator;
    }
  ): CustomViewSettings {
    const viewSettings = new CustomViewSettings();

    viewSettings.project = project.uniqueName;
    viewSettings.uniqueName = uniqueName;
    viewSettings.name = modelDescription.verboseNamePlural;
    viewSettings.resource = resource.uniqueName;
    viewSettings.model = modelDescription.model;
    viewSettings.configuredElements = 1;
    viewSettings.configuredModelElements = 1;
    viewSettings.newlyCreated = true;

    const listElement = this.listGenerator.getTableElement(resource, modelDescription, uniqueName, {
      templates: options.templates,
      fields: options.listFields,
      createUniqueName: options.createUniqueName,
      detailUniqueName: options.detailUniqueName,
      perPage: 15,
      uid: options.idGenerator ? options.idGenerator.elementId('listElement') : undefined
    });

    const filterElement = this.filterGenerator.getElement(modelDescription, listElement.uid, {
      uid: options.idGenerator ? options.idGenerator.elementId('filterElement') : undefined
    });

    listElement.layouts[0].dataSource.queryInputs = filterElement.elementInputs.map(item => {
      const input = new Input();

      input.path = [item.name];
      input.valueType = InputValueType.Context;
      input.contextValue = ['elements', filterElement.uid, item.name, VALUE_OUTPUT];

      return input;
    });

    viewSettings.elements = [filterElement, listElement];

    validateElementNames(viewSettings.elements);

    return viewSettings;
  }

  getCreatePage(
    project: Project,
    resource: Resource,
    modelDescription: ModelDescription,
    uniqueName: string,
    options: {
      templates: Template[];
      detailUniqueName?: string;
      listUniqueName?: string;
      fields?: string[];
      idGenerator?: PersistentIdGenerator;
    }
  ): CustomViewSettings {
    const viewSettings = new CustomViewSettings();
    const pageName = [modelDescription.verboseName, 'Create'].join(' - ');

    viewSettings.project = project.uniqueName;
    viewSettings.uniqueName = uniqueName;
    viewSettings.name = pageName;
    viewSettings.resource = resource.uniqueName;
    viewSettings.model = modelDescription.model;
    viewSettings.configuredElements = 2;
    viewSettings.configuredActionElements = 1;
    viewSettings.newlyCreated = true;

    const formElement = this.createFormGenerator.getElement(resource, modelDescription, {
      templates: options.templates,
      fields: options.fields,
      detailUniqueName: options.detailUniqueName,
      listUniqueName: options.listUniqueName,
      idGenerator: options.idGenerator,
      idUniqueName: 'formElement'
    });

    const backElement = this.backGenerator.getElement({
      titleStatic: `New ${capitalize(modelDescription.verboseName)}`,
      backStatic: modelDescription.verboseNamePlural,
      backPage: options.listUniqueName,
      uid: options.idGenerator ? options.idGenerator.elementId('backElement') : undefined
    });

    viewSettings.elements = [backElement, formElement];

    validateElementNames(viewSettings.elements);

    return viewSettings;
  }

  getUpdatePage(
    project: Project,
    resource: Resource,
    modelDescription: ModelDescription,
    uniqueName: string,
    options: {
      templates: Template[];
      deleteEnabled?: boolean;
      listUniqueName?: string;
      fields?: string[];
      idGenerator?: PersistentIdGenerator;
    }
  ): CustomViewSettings {
    const viewSettings = new CustomViewSettings();
    const pageName = [modelDescription.verboseName, 'Update'].join(' - ');
    const parameter = this.generatorUtils.getModelPkParameter(modelDescription);

    viewSettings.project = project.uniqueName;
    viewSettings.uniqueName = uniqueName;
    viewSettings.name = pageName;
    viewSettings.resource = resource.uniqueName;
    viewSettings.model = modelDescription.model;
    viewSettings.configuredElements = 2;
    viewSettings.configuredActionElements = 1;
    viewSettings.parameters = [parameter];
    viewSettings.newlyCreated = true;

    const formElement = this.updateFormGenerator.getElement(resource, modelDescription, {
      templates: options.templates,
      pkContextValue: ['page', parameter.name],
      fields: options.fields,
      idGenerator: options.idGenerator,
      idUniqueName: 'formElement'
    });

    const headerColumnsElement = new ColumnsLayoutElementItem();
    const backElementColumn = new ColumnsLayoutColumnElementItem();
    const deleteElementColumn = new ColumnsLayoutColumnElementItem();

    headerColumnsElement.uid = options.idGenerator ? options.idGenerator.elementId('headerColumnsElement') : undefined;
    backElementColumn.uid = options.idGenerator ? options.idGenerator.elementId('backElementColumn') : undefined;
    deleteElementColumn.uid = options.idGenerator ? options.idGenerator.elementId('deleteElementColumn') : undefined;

    const displayField = modelDescription.displayField || modelDescription.primaryKeyField;
    const backElement = this.backGenerator.getElement({
      titleFormula: `GET(elements["${formElement.uid}"].${ITEM_OUTPUT}, "${displayField}", "Loading...")`,
      backStatic: modelDescription.verboseNamePlural,
      backPage: options.listUniqueName,
      uid: options.idGenerator ? options.idGenerator.elementId('backElement') : undefined
    });

    backElementColumn.children = [backElement];
    backElementColumn.weight = 1;

    headerColumnsElement.columns.push(backElementColumn);

    if (options.deleteEnabled) {
      const deleteParameterName = this.generatorUtils.getModelDeleteParameter(resource, modelDescription);
      const deleteActionElement = this.deleteButtonGenerator.getElement(resource, modelDescription, {
        pkContextValue: ['elements', formElement.uid, ITEM_OUTPUT, deleteParameterName],
        listUniqueName: options.listUniqueName,
        uid: options.idGenerator ? options.idGenerator.elementId('deleteActionElement') : undefined
      });

      deleteElementColumn.fit = true;
      deleteElementColumn.children = [deleteActionElement];

      headerColumnsElement.columns.push(deleteElementColumn);
    }

    const headerContainer = new CardLayoutElementItem();

    headerContainer.uid = options.idGenerator ? options.idGenerator.elementId('headerContainer') : undefined;
    headerContainer.children = [headerColumnsElement];

    viewSettings.elements = [headerContainer, formElement];

    validateElementNames(viewSettings.elements);

    return viewSettings;
  }

  getDetailPage(
    project: Project,
    resource: Resource,
    modelDescription: ModelDescription,
    uniqueName: string,
    options: {
      templates: Template[];
      deleteEnabled?: boolean;
      listUniqueName?: string;
      fields?: string[];
      idGenerator?: PersistentIdGenerator;
    }
  ): CustomViewSettings {
    const viewSettings = new CustomViewSettings();
    const pageName = [modelDescription.verboseName, 'Detail'].join(' - ');
    const parameter = this.generatorUtils.getModelPkParameter(modelDescription);

    viewSettings.project = project.uniqueName;
    viewSettings.uniqueName = uniqueName;
    viewSettings.name = pageName;
    viewSettings.resource = resource.uniqueName;
    viewSettings.model = modelDescription.model;
    viewSettings.configuredElements = 2;
    viewSettings.configuredModelElements = 1;
    viewSettings.parameters = [parameter];
    viewSettings.newlyCreated = true;

    const modelElement = this.detailGenerator.getElement(resource, modelDescription, {
      pkContextValue: ['page', parameter.name],
      fields: options.fields,
      uid: options.idGenerator ? options.idGenerator.elementId('modelElement') : undefined
    });

    const headerColumnsElement = new ColumnsLayoutElementItem();
    const backElementColumn = new ColumnsLayoutColumnElementItem();
    const deleteElementColumn = new ColumnsLayoutColumnElementItem();

    headerColumnsElement.uid = options.idGenerator ? options.idGenerator.elementId('headerColumnsElement') : undefined;
    backElementColumn.uid = options.idGenerator ? options.idGenerator.elementId('backElementColumn') : undefined;
    deleteElementColumn.uid = options.idGenerator ? options.idGenerator.elementId('deleteElementColumn') : undefined;

    const displayField = modelDescription.displayField || modelDescription.primaryKeyField;
    const backElement = this.backGenerator.getElement({
      titleFormula: `GET(elements["${modelElement.uid}"].item, "${displayField}", "Loading...")`,
      backStatic: modelDescription.verboseNamePlural,
      backPage: options.listUniqueName,
      uid: options.idGenerator ? options.idGenerator.elementId('backElement') : undefined
    });

    backElementColumn.children = [backElement];
    backElementColumn.weight = 1;

    headerColumnsElement.columns.push(backElementColumn);

    if (options.deleteEnabled) {
      const deleteParameterName = this.generatorUtils.getModelDeleteParameter(resource, modelDescription);
      const deleteActionElement = this.deleteButtonGenerator.getElement(resource, modelDescription, {
        pkContextValue: ['elements', modelElement.uid, ITEM_OUTPUT, deleteParameterName],
        listUniqueName: options.listUniqueName
      });

      deleteElementColumn.fit = true;
      deleteElementColumn.children = [deleteActionElement];

      headerColumnsElement.columns.push(deleteElementColumn);
    }

    const headerContainer = new CardLayoutElementItem();

    headerContainer.uid = options.idGenerator ? options.idGenerator.elementId('headerContainer') : undefined;
    headerContainer.children = [headerColumnsElement];

    viewSettings.elements = [headerContainer, modelElement];

    validateElementNames(viewSettings.elements);

    return viewSettings;
  }

  getPages(
    project: Project,
    resource: Resource,
    modelDescription: ModelDescription,
    updateEnabled: boolean,
    options: {
      listFields?: string[];
      createFields?: string[];
      updateFields?: string[];
      detailFields?: string[];
      idGenerator?: PersistentIdGenerator;
    } = {}
  ): Observable<PagesGenerationResult> {
    const baseListUniqueName = this.generatorUtils.getModelPageUniqueName(resource, modelDescription, 'list');
    const baseCreateUniqueName = this.generatorUtils.getModelPageUniqueName(resource, modelDescription, 'create');
    const baseDetailUniqueName = this.generatorUtils.getModelPageUniqueName(
      resource,
      modelDescription,
      updateEnabled && modelDescription.updateQuery ? 'update' : 'detail'
    );

    return combineLatest(
      this.viewSettingsStore.getDistinctUniqueName(baseListUniqueName),
      this.viewSettingsStore.getDistinctUniqueName(baseCreateUniqueName),
      this.viewSettingsStore.getDistinctUniqueName(baseDetailUniqueName),
      this.generatorUtils.getDefaultComponentTemplates()
    ).pipe(
      map(([listUniqueName, createUniqueName, detailUniqueName, templates]) => {
        const pages: PagesGenerationResult = [];
        const hasDetail = this.generatorUtils.isModelDetailSupported(resource, modelDescription);

        if (modelDescription.getQuery) {
          pages.push({
            page: this.getListPage(project, resource, modelDescription, listUniqueName, {
              templates: templates,
              createUniqueName: updateEnabled && modelDescription.createQuery ? createUniqueName : undefined,
              detailUniqueName: hasDetail ? detailUniqueName : undefined,
              listFields: options.listFields,
              idGenerator: options.idGenerator
            }),
            initial: true
          });
        }

        if (updateEnabled && modelDescription.createQuery) {
          pages.push({
            page: this.getCreatePage(project, resource, modelDescription, createUniqueName, {
              templates: templates,
              detailUniqueName: hasDetail ? detailUniqueName : undefined,
              listUniqueName: listUniqueName,
              fields: options.createFields,
              idGenerator: options.idGenerator
            })
          });
        }

        if (hasDetail) {
          if (updateEnabled && modelDescription.updateQuery) {
            pages.push({
              page: this.getUpdatePage(project, resource, modelDescription, detailUniqueName, {
                templates: templates,
                deleteEnabled: updateEnabled && !!modelDescription.deleteQuery,
                listUniqueName: listUniqueName,
                fields: options.updateFields,
                idGenerator: options.idGenerator
              })
            });
          } else {
            pages.push({
              page: this.getDetailPage(project, resource, modelDescription, detailUniqueName, {
                templates: templates,
                deleteEnabled: updateEnabled && !!modelDescription.deleteQuery,
                listUniqueName: listUniqueName,
                fields: options.detailFields,
                idGenerator: options.idGenerator
              })
            });
          }
        }

        return pages;
      })
    );
  }
}
