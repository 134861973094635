<app-element-wrapper>
  <app-element-wrapper>
    <div class="element element_inline element_padding-h_normal element_padding-v_normal">
      <div class="element__content">
        <div class="page-title page-title_font-size_s">{{ label }}</div>
      </div>
    </div>
  </app-element-wrapper>

  <hr class="hr_theme" />

  <div class="columns">
    <div class="columns__item" [style.flex-grow]="1">
      <app-element-wrapper [alignHorizontal]="alignHorizontals.Left">
        <div class="element element_inline element_padding-h_normal element_padding-v_normal">
          <div class="element__content">
            <div
              class="field"
              [class.field_manual-margin]="false"
              [class.field_fill-vertical]="false"
              [class.field_theme]="true"
            >
              <div class="field__label">
                <span class="field__label-text">
                  Normal
                </span>
              </div>
              <div class="field__content">
                <a
                  href="javascript:void(0)"
                  class="button button_theme button_styles button_no-margin"
                  [class.button_primary]="style == tintStyles.Primary"
                  [class.button_primary-transparent]="style == tintStyles.Transparent"
                  [class.button_disabled]="false"
                  [class.button_icon]="false"
                  [class.button_fill]="false"
                  [appButtonTintColor]="accentColor"
                  [appButtonTintColorStyle]="style"
                  [appActionElementStyles]="elementStyles"
                  [appActionElementStylesTintColorStyle]="style"
                  cdkOverlayOrigin
                  #button_origin="cdkOverlayOrigin"
                >
                  <span class="button__icon icon-cursor" [class.button__icon_left]="true"></span>

                  <span class="button__label">
                    Button
                  </span>
                </a>

                <app-gradient-position
                  *ngIf="customizeGradient && customizeGradient.source === control.controls.fill_settings"
                  [control]="customizeGradient.control"
                  [activeStop]="customizeGradient.activeStop"
                  [origin]="button_origin"
                ></app-gradient-position>
              </div>
            </div>
          </div>
        </div>
      </app-element-wrapper>
    </div>

    <div class="columns__item" [style.flex-grow]="1">
      <app-element-wrapper [alignHorizontal]="alignHorizontals.Left">
        <div class="element element_padding-h_normal element_padding-v_normal">
          <div class="element__content">
            <div
              class="field"
              [class.field_manual-margin]="false"
              [class.field_fill-vertical]="false"
              [class.field_theme]="true"
            >
              <div class="field__label">
                <span class="field__label-text">
                  Hover
                </span>
              </div>
              <div class="field__content">
                <a
                  href="javascript:void(0)"
                  class="button button_theme button_styles button_no-margin"
                  [class.button_primary]="style == tintStyles.Primary"
                  [class.button_primary-transparent]="style == tintStyles.Transparent"
                  [class.button_hover]="true"
                  [class.button_disabled]="false"
                  [class.button_icon]="false"
                  [class.button_fill]="false"
                  [appButtonTintColor]="accentColor"
                  [appButtonTintColorStyle]="style"
                  [appActionElementStyles]="hoverElementStyles"
                  cdkOverlayOrigin
                  #button_hover_origin="cdkOverlayOrigin"
                >
                  <span class="button__icon icon-cursor" [class.button__icon_left]="true"></span>

                  <span class="button__label">
                    Button
                  </span>
                </a>

                <app-gradient-position
                  *ngIf="customizeGradient && customizeGradient.source === control.controls.hover_fill_settings"
                  [control]="customizeGradient.control"
                  [activeStop]="customizeGradient.activeStop"
                  [origin]="button_hover_origin"
                ></app-gradient-position>
              </div>
            </div>
          </div>
        </div>
      </app-element-wrapper>
    </div>

    <div class="columns__item" [style.flex-grow]="1">
      <app-element-wrapper [alignHorizontal]="alignHorizontals.Left">
        <div class="element element_padding-h_normal element_padding-v_normal">
          <div class="element__content">
            <div
              class="field"
              [class.field_manual-margin]="false"
              [class.field_fill-vertical]="false"
              [class.field_theme]="true"
            >
              <div class="field__label">
                <span class="field__label-text">
                  Pressed
                </span>
              </div>
              <div class="field__content">
                <a
                  href="javascript:void(0)"
                  class="button button_theme button_styles button_no-margin"
                  [class.button_primary]="style == tintStyles.Primary"
                  [class.button_primary-transparent]="style == tintStyles.Transparent"
                  [class.button_active]="true"
                  [class.button_disabled]="false"
                  [class.button_icon]="false"
                  [class.button_fill]="false"
                  [appButtonTintColor]="accentColor"
                  [appButtonTintColorStyle]="style"
                  [appActionElementStyles]="activeElementStyles"
                  cdkOverlayOrigin
                  #button_active_origin="cdkOverlayOrigin"
                >
                  <span class="button__icon icon-cursor" [class.button__icon_left]="true"></span>

                  <span class="button__label">
                    Button
                  </span>
                </a>

                <app-gradient-position
                  *ngIf="customizeGradient && customizeGradient.source === control.controls.active_fill_settings"
                  [control]="customizeGradient.control"
                  [activeStop]="customizeGradient.activeStop"
                  [origin]="button_active_origin"
                ></app-gradient-position>
              </div>
            </div>
          </div>
        </div>
      </app-element-wrapper>
    </div>

    <div class="columns__item" [style.flex-grow]="1">
      <app-element-wrapper [alignHorizontal]="alignHorizontals.Left">
        <div class="element element_padding-h_normal element_padding-v_normal">
          <div class="element__content">
            <div
              class="field"
              [class.field_manual-margin]="false"
              [class.field_fill-vertical]="false"
              [class.field_theme]="true"
            >
              <div class="field__label">
                <span class="field__label-text">
                  Disabled
                </span>
              </div>
              <div class="field__content">
                <a
                  href="javascript:void(0)"
                  class="button button_theme button_styles button_no-margin"
                  [class.button_primary]="style == tintStyles.Primary"
                  [class.button_primary-transparent]="style == tintStyles.Transparent"
                  [class.button_disabled]="true"
                  [class.button_icon]="false"
                  [class.button_fill]="false"
                  [appButtonTintColor]="accentColor"
                  [appButtonTintColorStyle]="style"
                  [appActionElementStyles]="elementStyles"
                >
                  <span class="button__icon icon-cursor" [class.button__icon_left]="true"></span>

                  <span class="button__label">
                    Button
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </app-element-wrapper>
    </div>
  </div>
</app-element-wrapper>
