import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-kanban-board-stub-content',
  templateUrl: './kanban-board-stub-content.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KanbanBoardStubContentComponent implements OnInit {
  @Input() items: any[] = [];
  @Input() animating = false;
  @Input() theme = false;

  constructor() {}

  ngOnInit() {}
}
