/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./checkbox.component";
import * as i3 from "@angular/platform-browser";
var styles_CheckboxComponent = [];
var RenderType_CheckboxComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CheckboxComponent, data: {} });
export { RenderType_CheckboxComponent as RenderType_CheckboxComponent };
function View_CheckboxComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [["class", "checkbox__square"]], null, null, null, null, null))], null, null); }
function View_CheckboxComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [["class", "checkbox__dash"]], null, null, null, null, null))], null, null); }
function View_CheckboxComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [["class", "checkbox__tick"]], null, null, null, null, null))], null, null); }
export function View_CheckboxComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "checkbox"]], [[2, "checkbox_checked", null], [2, "checkbox_disabled", null], [2, "checkbox_round", null], [2, "checkbox_theme", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggle() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "div", [["class", "checkbox__background"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CheckboxComponent_1)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CheckboxComponent_2)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CheckboxComponent_3)), i0.ɵdid(7, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.square; _ck(_v, 3, 0, currVal_4); var currVal_5 = (!_co.square && _co.dash); _ck(_v, 5, 0, currVal_5); var currVal_6 = (!_co.square && !_co.dash); _ck(_v, 7, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.displayValue; var currVal_1 = (((_co.control == null) ? null : _co.control.disabled) || _co.disabled); var currVal_2 = _co.round; var currVal_3 = _co.theme; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
export function View_CheckboxComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-checkbox", [], null, null, null, View_CheckboxComponent_0, RenderType_CheckboxComponent)), i0.ɵdid(1, 770048, null, 0, i2.CheckboxComponent, [i3.DomSanitizer, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CheckboxComponentNgFactory = i0.ɵccf("app-checkbox", i2.CheckboxComponent, View_CheckboxComponent_Host_0, { control: "control", value: "value", disabled: "disabled", round: "round", square: "square", dash: "dash", accentColor: "accentColor", theme: "theme" }, {}, []);
export { CheckboxComponentNgFactory as CheckboxComponentNgFactory };
