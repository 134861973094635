import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import toPairs from 'lodash/toPairs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { filter } from 'rxjs/operators';

import { AppDrag } from '@common/drag-drop2';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { CustomizeBarItem } from '@modules/change-components';
import { CustomView, CustomViewSource } from '@modules/custom-views';
import { CustomizeService, ElementType } from '@modules/customize';
import { Input as FieldInput, InputValueType } from '@modules/fields';
import { CustomViewTemplate, CustomViewTemplateCounterStore, Frame, View } from '@modules/views';
import {
  CustomViewTemplatesController,
  ImportFigmaNodeController,
  ImportSketchFileController,
  ViewEditorController
} from '@modules/views-components';

@Component({
  selector: 'app-change-customize-bar-custom-views',
  templateUrl: './change-customize-bar-custom-views.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangeCustomizeBarCustomViewsComponent implements OnInit, OnDestroy {
  @Input() analyticsSource: string;

  templatesApprox: number;

  constructor(
    private viewEditorController: ViewEditorController,
    private customViewTemplateCounterStore: CustomViewTemplateCounterStore,
    private customViewTemplatesController: CustomViewTemplatesController,
    private importFigmaNodeController: ImportFigmaNodeController,
    private importSketchFileController: ImportSketchFileController,
    private customizeService: CustomizeService,
    private cd: ChangeDetectorRef,
    private analyticsService: UniversalAnalyticsService
  ) {}

  ngOnInit() {
    this.customViewTemplateCounterStore
      .getApproxFirst$()
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        this.templatesApprox = value;
        this.cd.markForCheck();
      });
  }

  ngOnDestroy(): void {}

  isDroppable(item: AppDrag<CustomizeBarItem>): boolean {
    return false;
  }

  createdViewItem(view: View, template?: CustomViewTemplate): CustomizeBarItem {
    const customView = new CustomView();

    customView.source = CustomViewSource.View;
    customView.view = view;

    const inputs = template
      ? toPairs(template.view.testParameters).map(([name, value]) => {
          const result = new FieldInput();

          result.path = [name];
          result.valueType = InputValueType.StaticValue;
          result.staticValue = value;

          return result;
        })
      : [];

    return {
      title: view.name,
      image: 'canvas',
      purpleGradient: true,
      action: 'Add Custom',
      type: ElementType.Custom,
      defaultParams: {
        width: view.frame.width + 20 * 2,
        height: view.frame.height + 15 * 2,
        source: CustomViewSource.View,
        custom_view_temporary: customView.serialize(),
        parameters: view.parameters.map(item => item.serialize()),
        inputs: inputs.map(item => item.serialize())
      }
    };
  }

  importFigmaNode() {
    this.analyticsService.sendSimpleEvent(AnalyticsEvent.ViewEditor.ImportFigmaOpened, {
      Source: this.analyticsSource
    });

    this.importFigmaNodeController
      .importNode()
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        if (result.view) {
          this.createView(result.view);

          this.analyticsService.sendSimpleEvent(AnalyticsEvent.ViewEditor.ImportFigmaApplied, {
            Source: this.analyticsSource
          });
        } else if (result.cancelled) {
          this.analyticsService.sendSimpleEvent(AnalyticsEvent.ViewEditor.ImportFigmaCancelled, {
            Source: this.analyticsSource
          });
        }
      });
  }

  importSketchFile() {
    this.analyticsService.sendSimpleEvent(AnalyticsEvent.ViewEditor.ImportSketchOpened, {
      Source: this.analyticsSource
    });

    this.importSketchFileController
      .importFile()
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        if (result.view) {
          this.createView(result.view);

          this.analyticsService.sendSimpleEvent(AnalyticsEvent.ViewEditor.ImportSketchApplied, {
            Source: this.analyticsSource
          });
        } else if (result.cancelled) {
          this.analyticsService.sendSimpleEvent(AnalyticsEvent.ViewEditor.ImportSketchCancelled, {
            Source: this.analyticsSource
          });
        }
      });
  }

  createNewView() {
    const view = new View();

    view.generateId();
    view.name = 'New View';
    view.frame = new Frame({ width: 300, height: 240 });

    this.createView(view);
  }

  createView(view: View) {
    this.viewEditorController
      .open({
        create: true,
        view: view,
        submitLabel: 'Create component',
        analyticsSource: this.analyticsSource
      })
      .pipe(
        filter(result => !result.cancelled),
        untilDestroyed(this)
      )
      .subscribe(result => {
        const item = this.createdViewItem(result.view);
        this.customizeService.createElement(item);
      });
  }

  openCustomViewTemplates() {
    this.customViewTemplatesController
      .chooseTemplate({
        viewCreateEnabled: true,
        analyticsSource: this.analyticsSource
      })
      .pipe(
        filter(result => !result.cancelled),
        untilDestroyed(this)
      )
      .subscribe(result => {
        const item = this.createdViewItem(result.view, result.template);
        this.customizeService.createElement(item);
      });
  }
}
