import * as Color from 'color';
import toPairs from 'lodash/toPairs';

import { getColorHex, parseColor } from '@modules/colors';
import { BorderRadius, fonts, getFontFamily } from '@modules/theme';
import { isSet } from '@shared';

import { ThemeOptions } from '../data/theme-options';
import { setBorderRadiusVars, setBorderVars, setMarginVars, setShadowVars } from './styles';

export enum ThemeVar {
  AccentColor = 'accent-color',
  AccentColorContrast = 'accent-color-contrast',
  AccentColor2 = 'accent-color-2',
  AccentColor3 = 'accent-color-3',
  AccentColorDark = 'accent-color-dark',
  AccentColorDarkContrast = 'accent-color-dark-contrast',
  AccentColor2Dark = 'accent-color-2-dark',
  AccentColor3Dark = 'accent-color-3-dark',
  BackgroundColor = 'background-color',
  BackgroundColorDark = 'background-color-dark',
  BackgroundColor2 = 'background-color-2',
  BackgroundColor2AddDark = 'background-color-2-add-dark',
  BackgroundColor2Dark = 'background-color-2-dark',
  BackgroundColor2DarkAddDark = 'background-color-2-dark-add-dark',
  BackgroundColor3 = 'background-color-3',
  BackgroundColor3Contrast = 'background-color-3-contrast',
  BackgroundColor3Dark = 'background-color-3-dark',
  BackgroundColor3DarkContrast = 'background-color-3-dark-contrast',
  BackgroundColor4 = 'background-color-4',
  BackgroundColor4AddDark = 'background-color-4-add-dark',
  BackgroundColor4AddAlpha = 'background-color-4-add-alpha',
  BackgroundColor4Dark = 'background-color-4-dark',
  BackgroundColor4DarkAddDark = 'background-color-4-dark-add-dark',
  BackgroundColor4DarkAddAlpha = 'background-color-4-dark-add-alpha',
  BackgroundColor5 = 'background-color-5',
  BackgroundColor5Dark = 'background-color-5-dark',
  TextColor = 'text-color',
  TextColorDark = 'text-color-dark',
  TextColor2 = 'text-color-2',
  TextColor2Dark = 'text-color-2-dark',
  TextColor3 = 'text-color-3',
  TextColor3Dark = 'text-color-3-dark',
  BorderColor = 'border-color',
  BorderColorDark = 'border-color-dark',
  BorderColor2 = 'border-color-2',
  BorderColor2Dark = 'border-color-2-dark',
  BorderColor3 = 'border-color-3',
  BorderColor3Dark = 'border-color-3-dark',
  BorderRadiusXXXS = 'border-radius-xxxs',
  BorderRadiusXXS = 'border-radius-xxs',
  BorderRadiusS = 'border-radius-s',
  BorderRadiusM = 'border-radius-m',
  BorderRadiusL = 'border-radius-l',
  BorderRadiusXL = 'border-radius-xl',
  BorderRadiusXXL = 'border-radius-xxl',
  FontRegular = 'regular-font',
  FontHeading = 'heading-font'
}

export const themeVars = [
  ThemeVar.AccentColor,
  ThemeVar.AccentColorContrast,
  ThemeVar.AccentColor2,
  ThemeVar.AccentColor3,
  ThemeVar.AccentColorDark,
  ThemeVar.AccentColorDarkContrast,
  ThemeVar.AccentColor2Dark,
  ThemeVar.AccentColor3Dark,
  ThemeVar.BackgroundColor,
  ThemeVar.BackgroundColorDark,
  ThemeVar.BackgroundColor2,
  ThemeVar.BackgroundColor2AddDark,
  ThemeVar.BackgroundColor2Dark,
  ThemeVar.BackgroundColor2DarkAddDark,
  ThemeVar.BackgroundColor3,
  ThemeVar.BackgroundColor3Contrast,
  ThemeVar.BackgroundColor3Dark,
  ThemeVar.BackgroundColor3DarkContrast,
  ThemeVar.BackgroundColor4,
  ThemeVar.BackgroundColor4AddDark,
  ThemeVar.BackgroundColor4AddAlpha,
  ThemeVar.BackgroundColor4Dark,
  ThemeVar.BackgroundColor4DarkAddDark,
  ThemeVar.BackgroundColor4DarkAddAlpha,
  ThemeVar.BackgroundColor5,
  ThemeVar.BackgroundColor5Dark,
  ThemeVar.TextColor,
  ThemeVar.TextColorDark,
  ThemeVar.TextColor2,
  ThemeVar.TextColor2Dark,
  ThemeVar.TextColor3,
  ThemeVar.TextColor3Dark,
  ThemeVar.BorderColor,
  ThemeVar.BorderColorDark,
  ThemeVar.BorderColor2,
  ThemeVar.BorderColor2Dark,
  ThemeVar.BorderColor3,
  ThemeVar.BorderColor3Dark,
  ThemeVar.BorderRadiusXXXS,
  ThemeVar.BorderRadiusXXS,
  ThemeVar.BorderRadiusS,
  ThemeVar.BorderRadiusM,
  ThemeVar.BorderRadiusL,
  ThemeVar.BorderRadiusXL,
  ThemeVar.BorderRadiusXXL,
  ThemeVar.FontRegular,
  ThemeVar.FontHeading
];

export const themeVarBorderRadiusValues: { [k: string]: { [v: string]: number } } = {
  [BorderRadius.None]: {
    [ThemeVar.BorderRadiusXXXS]: 0,
    [ThemeVar.BorderRadiusXXS]: 0,
    [ThemeVar.BorderRadiusS]: 0,
    [ThemeVar.BorderRadiusM]: 0,
    [ThemeVar.BorderRadiusL]: 0,
    [ThemeVar.BorderRadiusXL]: 0,
    [ThemeVar.BorderRadiusXXL]: 0
  },
  [BorderRadius.S]: {
    [ThemeVar.BorderRadiusXXXS]: 1,
    [ThemeVar.BorderRadiusXXS]: 1,
    [ThemeVar.BorderRadiusS]: 2,
    [ThemeVar.BorderRadiusM]: 3,
    [ThemeVar.BorderRadiusL]: 4,
    [ThemeVar.BorderRadiusXL]: 5,
    [ThemeVar.BorderRadiusXXL]: 6
  },
  [BorderRadius.M]: {
    [ThemeVar.BorderRadiusXXXS]: 1,
    [ThemeVar.BorderRadiusXXS]: 2,
    [ThemeVar.BorderRadiusS]: 4,
    [ThemeVar.BorderRadiusM]: 6,
    [ThemeVar.BorderRadiusL]: 8,
    [ThemeVar.BorderRadiusXL]: 10,
    [ThemeVar.BorderRadiusXXL]: 12
  },
  [BorderRadius.L]: {
    [ThemeVar.BorderRadiusXXXS]: 1,
    [ThemeVar.BorderRadiusXXS]: 4,
    [ThemeVar.BorderRadiusS]: 6,
    [ThemeVar.BorderRadiusM]: 10,
    [ThemeVar.BorderRadiusL]: 12,
    [ThemeVar.BorderRadiusXL]: 14,
    [ThemeVar.BorderRadiusXXL]: 16
  },
  [BorderRadius.XL]: {
    [ThemeVar.BorderRadiusXXXS]: 3,
    [ThemeVar.BorderRadiusXXS]: 6,
    [ThemeVar.BorderRadiusS]: 12,
    [ThemeVar.BorderRadiusM]: 18,
    [ThemeVar.BorderRadiusL]: 20,
    [ThemeVar.BorderRadiusXL]: 22,
    [ThemeVar.BorderRadiusXXL]: 24
  }
};

export function getThemeVars(options: ThemeOptions = {}): { [k in ThemeVar]?: string } {
  const vars: { [k in ThemeVar]?: string } = {};
  const fontRegular = fonts.find(item => item.name == options.fontRegular);
  const fontHeading = fonts.find(item => item.name == options.fontHeading);

  if (isSet(options.accentColor)) {
    const hex = getColorHex(options.accentColor);
    const clr = isSet(hex) ? parseColor(hex) : undefined;

    if (clr) {
      const isDark = clr.contrast(Color('white')) >= 2;

      vars[ThemeVar.AccentColor] = clr.string();
      vars[ThemeVar.AccentColor2] = clr.lighten(0.15).string();
      vars[ThemeVar.AccentColor3] = clr.lighten(0.2).string();
      vars[ThemeVar.AccentColorContrast] = isDark ? '#fff' : clr.darken(0.6).string();
    }
  }

  if (isSet(options.accentColorDark)) {
    const hex = getColorHex(options.accentColorDark);
    const clr = isSet(hex) ? parseColor(hex) : undefined;

    if (clr) {
      const isDark = clr.contrast(Color('white')) >= 2;

      vars[ThemeVar.AccentColorDark] = clr.string();
      vars[ThemeVar.AccentColor2Dark] = clr.lighten(0.15).string();
      vars[ThemeVar.AccentColor3Dark] = clr.lighten(0.2).string();
      vars[ThemeVar.AccentColorDarkContrast] = isDark ? '#fff' : clr.darken(0.6).string();
    }
  }

  if (isSet(options.backgroundColor)) {
    const hex = getColorHex(options.backgroundColor);
    const clr = isSet(hex) ? parseColor(hex) : undefined;

    if (clr) {
      vars[ThemeVar.BackgroundColor] = clr.string();
    }
  }

  if (isSet(options.backgroundColorDark)) {
    const hex = getColorHex(options.backgroundColorDark);
    const clr = isSet(hex) ? parseColor(hex) : undefined;

    if (clr) {
      vars[ThemeVar.BackgroundColorDark] = clr.string();
    }
  }

  if (isSet(options.backgroundColor2)) {
    const hex = getColorHex(options.backgroundColor2);
    const clr = isSet(hex) ? parseColor(hex) : undefined;

    if (clr) {
      vars[ThemeVar.BackgroundColor2] = clr.string();
      vars[ThemeVar.BackgroundColor2AddDark] = clr.darken(0.05).string();
    }
  }

  if (isSet(options.backgroundColor2Dark)) {
    const hex = getColorHex(options.backgroundColor2Dark);
    const clr = isSet(hex) ? parseColor(hex) : undefined;

    if (clr) {
      vars[ThemeVar.BackgroundColor2Dark] = clr.string();
      vars[ThemeVar.BackgroundColor2DarkAddDark] = clr.darken(0.05).string();
    }
  }

  if (isSet(options.backgroundColor3)) {
    const hex = getColorHex(options.backgroundColor3);
    const clr = isSet(hex) ? parseColor(hex) : undefined;

    if (clr) {
      vars[ThemeVar.BackgroundColor3] = clr.string();
    }
  }

  if (isSet(options.backgroundColor3) && isSet(options.backgroundColor2) && isSet(options.backgroundColor4)) {
    const hex3 = getColorHex(options.backgroundColor3);
    const hex2 = getColorHex(options.backgroundColor2);
    const hex4 = getColorHex(options.backgroundColor4);
    const clr3 = isSet(hex3) ? parseColor(hex3) : undefined;
    const clr2 = isSet(hex2) ? parseColor(hex2) : undefined;
    const clr4 = isSet(hex4) ? parseColor(hex4) : undefined;

    if (clr3 && clr2 && clr4) {
      if (clr3.contrast(clr4) > clr3.contrast(clr2)) {
        vars[ThemeVar.BackgroundColor3Contrast] = clr4.string();
      } else {
        vars[ThemeVar.BackgroundColor3Contrast] = clr2.string();
      }
    }
  }

  if (isSet(options.backgroundColor3Dark)) {
    const hex = getColorHex(options.backgroundColor3Dark);
    const clr = isSet(hex) ? parseColor(hex) : undefined;

    if (clr) {
      vars[ThemeVar.BackgroundColor3Dark] = clr.string();
    }
  }

  if (
    isSet(options.backgroundColor3Dark) &&
    isSet(options.backgroundColor2Dark) &&
    isSet(options.backgroundColor4Dark)
  ) {
    const hex3 = getColorHex(options.backgroundColor3Dark);
    const hex2 = getColorHex(options.backgroundColor2Dark);
    const hex4 = getColorHex(options.backgroundColor4Dark);
    const clr3 = isSet(hex3) ? parseColor(hex3) : undefined;
    const clr2 = isSet(hex2) ? parseColor(hex2) : undefined;
    const clr4 = isSet(hex4) ? parseColor(hex4) : undefined;

    if (clr3 && clr2 && clr4) {
      if (clr3.contrast(clr4) > clr3.contrast(clr2)) {
        vars[ThemeVar.BackgroundColor3DarkContrast] = clr4.string();
      } else {
        vars[ThemeVar.BackgroundColor3DarkContrast] = clr2.string();
      }
    }
  }

  if (isSet(options.backgroundColor4)) {
    const hex = getColorHex(options.backgroundColor4);
    const clr = isSet(hex) ? parseColor(hex) : undefined;

    if (clr) {
      vars[ThemeVar.BackgroundColor4] = clr.string();
      vars[ThemeVar.BackgroundColor4AddDark] = clr.darken(0.05).string();
      vars[ThemeVar.BackgroundColor4AddAlpha] = clr.fade(0.5).string();
    }
  }

  if (isSet(options.backgroundColor4Dark)) {
    const hex = getColorHex(options.backgroundColor4Dark);
    const clr = isSet(hex) ? parseColor(hex) : undefined;

    if (clr) {
      vars[ThemeVar.BackgroundColor4Dark] = clr.string();
      vars[ThemeVar.BackgroundColor4DarkAddDark] = clr.darken(0.05).string();
      vars[ThemeVar.BackgroundColor4DarkAddAlpha] = clr.fade(0.5).string();
    }
  }

  if (isSet(options.backgroundColor5)) {
    const hex = getColorHex(options.backgroundColor5);
    const clr = isSet(hex) ? parseColor(hex) : undefined;

    if (clr) {
      vars[ThemeVar.BackgroundColor5] = clr.string();
    }
  }

  if (isSet(options.backgroundColor5Dark)) {
    const hex = getColorHex(options.backgroundColor5Dark);
    const clr = isSet(hex) ? parseColor(hex) : undefined;

    if (clr) {
      vars[ThemeVar.BackgroundColor5Dark] = clr.string();
    }
  }

  if (isSet(options.textColor)) {
    const hex = getColorHex(options.textColor);
    const clr = isSet(hex) ? parseColor(hex) : undefined;

    if (clr) {
      vars[ThemeVar.TextColor] = clr.string();
    }
  }

  if (isSet(options.textColorDark)) {
    const hex = getColorHex(options.textColorDark);
    const clr = isSet(hex) ? parseColor(hex) : undefined;

    if (clr) {
      vars[ThemeVar.TextColorDark] = clr.string();
    }
  }

  if (isSet(options.textColor2)) {
    const hex = getColorHex(options.textColor2);
    const clr = isSet(hex) ? parseColor(hex) : undefined;

    if (clr) {
      vars[ThemeVar.TextColor2] = clr.string();
    }
  }

  if (isSet(options.textColor2Dark)) {
    const hex = getColorHex(options.textColor2Dark);
    const clr = isSet(hex) ? parseColor(hex) : undefined;

    if (clr) {
      vars[ThemeVar.TextColor2Dark] = clr.string();
    }
  }

  if (isSet(options.textColor3)) {
    const hex = getColorHex(options.textColor3);
    const clr = isSet(hex) ? parseColor(hex) : undefined;

    if (clr) {
      vars[ThemeVar.TextColor3] = clr.string();
    }
  }

  if (isSet(options.textColor3Dark)) {
    const hex = getColorHex(options.textColor3Dark);
    const clr = isSet(hex) ? parseColor(hex) : undefined;

    if (clr) {
      vars[ThemeVar.TextColor3Dark] = clr.string();
    }
  }

  if (isSet(options.borderColor)) {
    const hex = getColorHex(options.borderColor);
    const clr = isSet(hex) ? parseColor(hex) : undefined;

    if (clr) {
      vars[ThemeVar.BorderColor] = clr.string();
    }
  }

  if (isSet(options.borderColorDark)) {
    const hex = getColorHex(options.borderColorDark);
    const clr = isSet(hex) ? parseColor(hex) : undefined;

    if (clr) {
      vars[ThemeVar.BorderColorDark] = clr.string();
    }
  }

  if (isSet(options.borderColor2)) {
    const hex = getColorHex(options.borderColor2);
    const clr = isSet(hex) ? parseColor(hex) : undefined;

    if (clr) {
      vars[ThemeVar.BorderColor2] = clr.string();
    }
  }

  if (isSet(options.borderColor2Dark)) {
    const hex = getColorHex(options.borderColor2Dark);
    const clr = isSet(hex) ? parseColor(hex) : undefined;

    if (clr) {
      vars[ThemeVar.BorderColor2Dark] = clr.string();
    }
  }

  if (isSet(options.borderColor3)) {
    const hex = getColorHex(options.borderColor3);
    const clr = isSet(hex) ? parseColor(hex) : undefined;

    if (clr) {
      vars[ThemeVar.BorderColor3] = clr.string();
    }
  }

  if (isSet(options.borderColor3Dark)) {
    const hex = getColorHex(options.borderColor3Dark);
    const clr = isSet(hex) ? parseColor(hex) : undefined;

    if (clr) {
      vars[ThemeVar.BorderColor3Dark] = clr.string();
    }
  }

  if (isSet(options.borderRadius)) {
    toPairs(themeVarBorderRadiusValues[options.borderRadius]).forEach(([k, v]) => {
      vars[k] = v > 0 ? `${v}px` : v;
    });
  }

  if (fontRegular && !fontRegular.default) {
    vars[ThemeVar.FontRegular] = getFontFamily(fontRegular);
  }

  if (fontHeading && !fontHeading.default) {
    vars[ThemeVar.FontHeading] = getFontFamily(fontHeading);
  }

  setBorderVars(vars, options.elementWrapperBorder, key => `element-wrapper-${key}`);
  setBorderRadiusVars(vars, options.elementWrapperBorderRadius, 'element-wrapper-border-radius');
  setShadowVars(vars, options.elementWrapperShadow, key => `element-wrapper-${key}`);
  setMarginVars(vars, options.elementWrapperMargin, key => `element-wrapper-margin-${key}`);

  return vars;
}

export function getThemeVarBorderRadiusValue(borderRadius: BorderRadius, variable: ThemeVar) {
  borderRadius = borderRadius || BorderRadius.M;

  if (themeVarBorderRadiusValues[borderRadius] && isSet(themeVarBorderRadiusValues[borderRadius][variable])) {
    return themeVarBorderRadiusValues[borderRadius][variable];
  } else {
    return 0;
  }
}
