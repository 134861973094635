<div
  class="list"
  [class.list_scrollable]="scrollable"
  [class.list_flexible]="!scrollable"
  [class.list_theme]="theme"
  appElement
  [appElementDetectVisible]="{ intervalCheck: 600 }"
  (appElementFirstVisible)="firstVisible$.next(true)"
>
  <div class="list__header" [class.list__header_active]="settings.header">
    <app-list-header
      *ngIf="settings.header"
      [noBottomMargin]="true"
      [params]="params"
      [filtersEnabled]="settings.displayFilters"
      [filters]="listState.filters"
      [filterFields]="settings.filterFields"
      [hideParams]="hideParams"
      [pageSettings]="pageSettings"
      [settings]="settings"
      [dataSource]="listState.dataSource"
      [inputs]="inputs"
      [parameters]="parameters"
      [moreLink]="moreLink"
      [focus]="focus"
      [searchEnabled]="settings.searchEnabled || settings.searchQuery != undefined"
      [search]="listState.search"
      [searchLive]="settings.searchLive"
      [createSegmentAllowed]="createSegmentAllowed"
      [viewId]="viewId"
      [context]="context"
      [contextElement]="contextElement"
      [accentColor]="accentColor"
      [theme]="theme"
      (paramsChanged)="paramsChanged.emit($event)"
      (filtersUpdated)="onFiltersUpdated($event)"
      (searchUpdated)="onSearchUpdated($event)"
      (layoutAdd)="layoutAdd.emit($event)"
      (layoutCustomize)="layoutCustomize.emit($event)"
      (segmentCreated)="segmentCreated.emit($event)"
      (updateData)="reloadData()"
      (customizeModelDescription)="customizeModelDescription()"
      (customizeViewSettings)="customizeViewSettings()"
    >
    </app-list-header>
  </div>

  <div class="list__content list__content_border">
    <div class="list__viewport list__viewport_scrollable">
      <div
        class="calendar list__component"
        [class.calendar_theme]="theme"
        appElement
        [appElementDetectVisible]="true"
        (appElementFirstVisible)="firstVisible$.next(true)"
      >
        <app-error *ngIf="!configured" [title]="'Not configured'" [fill]="scrollable" [compact]="true" [theme]="theme">
          Unfortunately, this component is not configured yet
        </app-error>

        <ng-container *ngIf="configured && listState.date">
          <app-calendar-header
            [type]="listState.type"
            [date]="listState.date"
            [params]="params"
            (paramsChanged)="paramsChanged.emit($event)"
          >
          </app-calendar-header>

          <app-calendar-day
            *ngIf="listState.type == calendarTypes.Day"
            class="calendar__content"
            [title]="title"
            [date]="listState.date"
            [params]="params"
            [context]="context"
            [listState]="listState"
            [modelDescription]="listState.modelDescription"
            [settings]="settings"
            [contextElement]="contextElement"
            [accentColor]="accentColor"
            [theme]="theme"
            (queryParamsChanged)="paramsChanged.emit($event)"
          >
          </app-calendar-day>

          <app-calendar-week
            *ngIf="listState.type == calendarTypes.Week"
            class="calendar__content"
            [title]="title"
            [date]="listState.date"
            [params]="params"
            [context]="context"
            [listState]="listState"
            [modelDescription]="listState.modelDescription"
            [settings]="settings"
            [contextElement]="contextElement"
            [accentColor]="accentColor"
            [theme]="theme"
            (queryParamsChanged)="paramsChanged.emit($event)"
          >
          </app-calendar-week>

          <app-calendar-month
            *ngIf="listState.type == calendarTypes.Month"
            class="calendar__content"
            [title]="title"
            [date]="listState.date"
            [params]="params"
            [context]="context"
            [listState]="listState"
            [modelDescription]="listState.modelDescription"
            [settings]="settings"
            [contextElement]="contextElement"
            [accentColor]="accentColor"
            [theme]="theme"
            (queryParamsChanged)="paramsChanged.emit($event)"
          >
          </app-calendar-month>

          <app-calendar-year
            *ngIf="listState.type == calendarTypes.Year"
            class="calendar__content"
            [date]="listState.date"
            [params]="params"
            [listState]="listState"
            [settings]="settings"
            (queryParamsChanged)="paramsChanged.emit($event)"
          >
          </app-calendar-year>
        </ng-container>
      </div>
    </div>
  </div>
</div>
