<app-popup2 [size]="(selectedTemplate?.modelDescriptions || [])[0]?.fields.length > 6 ? 'xxl' : 'l'">
  <app-popup2-close *ngIf="closeEnabled" [outside]="true" (click)="cancel()"></app-popup2-close>

  <div class="choose-template choose-template_flexible" [class.choose-template_popup]="false">
    <div class="choose-template__header">
      <div *ngIf="resource.icon" class="choose-template__header-left">
        <div class="choose-template__header-icon" [style.background-image]="'url(' + resource.icon + ')'"></div>
      </div>

      <div class="choose-template__header-main">
        <div class="choose-template__header-title">New Table</div>
        <div class="choose-template__header-subtitle">
          Create a new Table, use one of our Templates or Import from File
        </div>
      </div>

      <div class="choose-template__header-right">
        <button
          *ngIf="selectedTemplate"
          type="button"
          class="button button_primary"
          [disabled]="submitLoading"
          (click)="createModelDescription(selectedTemplate.modelDescriptions[0], selectedTemplate.models)"
        >
          <app-loader-small *ngIf="submitLoading" class="button__icon button__icon_left"></app-loader-small>
          <span *ngIf="!submitLoading" class="button__icon button__icon_left icon-plus"></span>
          <span class="button__label">Create</span>
        </button>

        <button
          *ngIf="!selectedTemplate && defaultModelDescriptionTemplate"
          type="button"
          class="button button_primary"
          [disabled]="submitLoading"
          (click)="
            createModelDescription(
              defaultModelDescriptionTemplate.modelDescription,
              defaultModelDescriptionTemplate.models
            )
          "
        >
          <app-loader-small *ngIf="submitLoading" class="button__icon button__icon_left"></app-loader-small>
          <span *ngIf="!submitLoading" class="button__icon button__icon_left icon-plus"></span>
          <span class="button__label">Create</span>
        </button>
      </div>
    </div>

    <div class="choose-template__content">
      <div class="choose-template__content-navigation">
        <div class="choose-template__content-navigation-inner">
          <div class="choose-template__content-navigation-list">
            <div class="choose-template-list">
              <ng-container *ngIf="!loading">
                <div class="choose-template-list__item">
                  <div
                    class="choose-template-list-item choose-template-list-item_interactive"
                    [class.choose-template-list-item_active]="!selectedTemplate"
                    (click)="setSelectedTemplate(undefined)"
                  >
                    <div class="choose-template-list-item__left">
                      <span class="choose-template-list-item__icon icon-plus"></span>
                    </div>
                    <div class="choose-template-list-item__main">
                      <div class="choose-template-list-item__title">New Table</div>
                    </div>
                  </div>
                </div>

                <ng-container *ngIf="modelDescriptionTemplates.length">
                  <div class="choose-template-list__separator">
                    <app-page-separator>or use template</app-page-separator>
                  </div>

                  <div *ngFor="let item of modelDescriptionTemplates; let i = index" class="choose-template-list__item">
                    <div
                      class="choose-template-list-item choose-template-list-item_interactive"
                      [class.choose-template-list-item_active]="selectedTemplate === item"
                      (click)="setSelectedTemplate(item)"
                    >
                      <div *ngIf="item.icon" class="choose-template-list-item__left">
                        <span class="choose-template-list-item__icon" [ngClass]="'icon-' + item.icon"></span>
                      </div>
                      <div class="choose-template-list-item__main">
                        <div class="choose-template-list-item__title">{{ item.name }}</div>
                        <div *ngIf="item.subtitle" class="choose-template-list-item__subtitle">{{ item.subtitle }}</div>
                      </div>
                    </div>
                  </div>
                </ng-container>

                <ng-container *ngIf="importEnabled">
                  <div class="choose-template-list__separator">
                    <app-page-separator>or</app-page-separator>
                  </div>

                  <div class="choose-template-list__item">
                    <div
                      class="choose-template-list-item choose-template-list-item_center choose-template-list-item_contrast choose-template-list-item_interactive"
                      (click)="importModels()"
                    >
                      <div class="choose-template-list-item__left">
                        <span class="choose-template-list-item__icon icon-cloud_upload"></span>
                      </div>
                      <div class="choose-template-list-item__main">
                        <div class="choose-template-list-item__title">Import from File</div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </ng-container>

              <ng-container *ngIf="loading">
                <div
                  *ngFor="
                    let item of ['long item', 'longasdads item', 'longasdads item', 'medium', 'long item', 'item']
                  "
                  class="choose-template-list__item"
                >
                  <div class="choose-template-list-item">
                    <div class="choose-template-list-item__main">
                      <span [class.loading-animation]="true">
                        <span class="stub-text">{{ item }}</span>
                      </span>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>

      <div
        class="choose-template__content-main choose-template__content-main_background choose-template__content-main_padding choose-template__content-main_scrollable"
        #viewport
      >
        <div *ngIf="selectedTemplate || defaultModelDescriptionTemplate" class="choose-template__detail-table-header">
          <div
            class="choose-template-title"
            [class.choose-template-title_active]="nameEditing"
            (click)="setNameEditing(true)"
          >
            <input
              *ngIf="nameEditing"
              class="choose-template-title__control"
              [(ngModel)]="name"
              [appAutofocus]="true"
              appKeyEvent
              (enterUp)="input.blur()"
              (blur)="finishNameEditing()"
              #input
            />
            <div *ngIf="!nameEditing" class="choose-template-title__control">{{ name }}</div>
            <div *ngIf="!nameEditing" class="choose-template-title__icon icon-pen"></div>
          </div>
        </div>

        <div class="choose-template__detail-table">
          <app-project-resource-data-templates-table
            *ngIf="selectedTemplate"
            [modelDescription]="selectedTemplate.modelDescriptions[0]"
            [data]="selectedTemplate.models"
          ></app-project-resource-data-templates-table>

          <app-project-resource-data-templates-table
            *ngIf="!selectedTemplate && defaultModelDescriptionTemplate"
            [modelDescription]="defaultModelDescriptionTemplate.modelDescription"
            [data]="defaultModelDescriptionTemplate.models"
          ></app-project-resource-data-templates-table>
        </div>
      </div>
    </div>
  </div>
</app-popup2>
