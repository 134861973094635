import { CdkConnectedOverlay, ConnectedOverlayPositionChange, ConnectedPosition } from '@angular/cdk/overlay';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject } from 'rxjs';

import { SimplePopupComponent } from '@common/dialog-popup';
import { PopupService } from '@common/popups';
import { ImageFit } from '@modules/fields';
import { ProjectApiService } from '@modules/project-api';
import { Resource } from '@modules/projects';
import { getImageSize } from '@shared';

import { ImageFieldType } from '../../image-field/image-field.types';
import { LightboxComponent } from '../../image-field/lightbox/lightbox.component';

@Component({
  selector: 'app-image-preview',
  templateUrl: './image-preview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImagePreviewComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() value: string;
  @Input() url: string;
  @Input() storageResource: Resource;
  @Input() noBackground = false;
  @Input() background = false;
  @Input() fit: string;
  @Input() type: ImageFieldType;
  @Input() resize = false;
  @Input() lightBox = false;
  @Input() preview = false;
  @Input() compact = false;
  @Input() theme = false;

  @ViewChild('preview_img') previewImg: ElementRef;
  @ViewChild(CdkConnectedOverlay) cdkConnectedOverlay: CdkConnectedOverlay;

  imageWidth: number;
  imageHeight: number;
  previewVisible$ = new BehaviorSubject<boolean>(false);
  popoverPositions: ConnectedPosition[] = [
    {
      panelClass: ['overlay_position_center-top'],
      originX: 'center',
      overlayX: 'start',
      originY: 'top',
      overlayY: 'bottom',
      offsetX: 0,
      offsetY: -10,
      weight: 4
    },
    {
      panelClass: ['overlay_position_center-bottom'],
      originX: 'center',
      overlayX: 'start',
      originY: 'bottom',
      overlayY: 'top',
      offsetX: 0,
      offsetY: 10,
      weight: 3
    },
    {
      panelClass: ['overlay_position_right-center'],
      originX: 'end',
      overlayX: 'start',
      originY: 'center',
      overlayY: 'top',
      offsetX: 10,
      offsetY: 0,
      weight: 2
    },
    {
      panelClass: ['overlay_position_left-center'],
      originX: 'start',
      overlayX: 'end',
      originY: 'top',
      overlayY: 'top',
      offsetX: -10,
      offsetY: 0,
      weight: 1
    }
  ];
  imageFits = ImageFit;

  constructor(
    private popupService: PopupService,
    private domSanitizer: DomSanitizer,
    private apiService: ProjectApiService,
    private cd: ChangeDetectorRef,
    private injector: Injector
  ) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['value']) {
      getImageSize(this.value)
        .pipe(untilDestroyed(this))
        .subscribe(
          size => {
            this.imageWidth = size.width;
            this.imageHeight = size.height;
          },
          () => {
            this.imageWidth = undefined;
            this.imageHeight = undefined;
          }
        );
    }
  }

  ngAfterViewInit(): void {
    this.setPositionObserver();
  }

  openLightbox(event: MouseEvent, force = false) {
    if (!this.url) {
      return;
    }

    if (!this.lightBox && !force) {
      return;
    }

    event.stopPropagation();

    this.popupService.push({
      component: LightboxComponent,
      popupComponent: SimplePopupComponent,
      inputs: {
        url: this.url,
        origin: this.previewImg.nativeElement
      },
      injector: this.injector
    });
  }

  get previewBackgroundImage() {
    if (!this.url) {
      return;
    }

    let img = this.url;
    // const apiInfo = this.getApiInfo();
    const apiInfo = this.storageResource ? this.storageResource.apiInfo : undefined;

    if (this.resize && apiInfo && apiInfo.isCompatibleJetBridge({ jetBridge: '0.0.6', jetDjango: '0.7.7' })) {
      img = `${this.apiService.methodURLForProjectResource(
        'image_resize/',
        this.storageResource
      )}?path=${encodeURIComponent(this.value)}`;
    }

    return this.domSanitizer.bypassSecurityTrustStyle(`url('${img}')`);
  }

  isLargerPreviewAvailable(): boolean {
    return (
      this.imageWidth &&
      this.imageHeight &&
      (this.imageWidth > this.previewImg.nativeElement.offsetWidth ||
        this.imageHeight > this.previewImg.nativeElement.offsetHeight) &&
      (this.previewImg.nativeElement.offsetWidth < 320 || this.previewImg.nativeElement.offsetHeight < 240)
    );
  }

  showPreview() {
    if (this.preview && this.isLargerPreviewAvailable()) {
      this.previewVisible$.next(true);
    }
  }

  hidePreview() {
    this.previewVisible$.next(false);
  }

  setPositionObserver() {
    if (!this.cdkConnectedOverlay) {
      return;
    }

    this.cdkConnectedOverlay.positionChange
      .pipe(untilDestroyed(this))
      .subscribe((e: ConnectedOverlayPositionChange) => {
        const propsEqual = ['offsetX', 'offsetY', 'originX', 'originY', 'overlayX', 'overlayY'];
        const position = this.popoverPositions.find(item =>
          propsEqual.every(prop => (item[prop] || undefined) == e.connectionPair[prop])
        );
        const otherPosition = this.popoverPositions.filter(item => item !== position);

        if (position) {
          this.cdkConnectedOverlay.overlayRef.addPanelClass(position.panelClass);
        }

        otherPosition.forEach(item => this.cdkConnectedOverlay.overlayRef.removePanelClass(item.panelClass));
      });
  }
}
