import { Routes, UrlSegment } from '@angular/router';
import fromPairs from 'lodash/fromPairs';

import { NoOpenPopupsGuard } from '@common/dialog-popup';
import { OnBeforeDestroyGuard } from '@common/lifecycle';
import { UserActivitiesComponent } from '@modules/activities-routes';
import { AdminMode } from '@modules/admin-mode';
import { IsAuthenticatedGuard, SetExternalTokenGuard } from '@modules/auth';
import { ChangeRouteComponent } from '@modules/change-routes';
import {
  CollaborationComponent,
  CollaborationMessagesComponent,
  CollaborationTasksComponent,
  ConversationsComponent
} from '@modules/collaboration-routes';
import { UnsavedChangesGuard, WaitUnsavedChangesGuard } from '@modules/customize';
import { PageComponent, TemplatesComponent, TemplatesItemComponent } from '@modules/customize-routes';
import { IndexComponent } from '@modules/dashboard-routes';
import { DeleteComponent } from '@modules/delete';
import { DemoComponent } from '@modules/demo';
import { DomainResolver } from '@modules/domain';
import { ProjectSettingsComponent, SignUpBuilderComponent } from '@modules/layout-routes';
import { ListRouteComponent } from '@modules/list-routes';
import { MassEditComponent } from '@modules/mass-edit-routes';
import { MenuCustomSection } from '@modules/menu';
import { ProjectPermissionGuard } from '@modules/projects';
import { ApplyTemplateComponent } from '@modules/template-routes';
import { AutomationRunsComponent, AutomationsComponent } from '@modules/workflow-routes';

import { AdminComponent } from './admin.component';
import { DefaultComponent } from './components/default/default.component';
import { NotAllowedComponent } from './components/not-allowed/not-allowed.component';
import { OpenSlackPopupComponent } from './components/open-slack-popup/open-slack-popup.component';
import { RedirectComponent } from './components/redirect/redirect.component';
import { DefaultEnvironmentRedirectGuard } from './guards/default-environment-redirect.guard';

export function projectSettingsMatcher(url: UrlSegment[]) {
  if (url.length >= 1 && url[0].path == 'layout') {
    const posParams = fromPairs(url.slice(1).map((item, i) => [`path${i + 1}`, item]));
    return {
      consumed: url,
      posParams: posParams
    };
  } else {
    return null;
  }
}

export const childrenRoutes: Routes = [
  {
    path: '',
    component: DefaultComponent,
    canDeactivate: [NoOpenPopupsGuard, WaitUnsavedChangesGuard]
  },
  {
    path: 'open_slack_popup',
    component: OpenSlackPopupComponent,
    canDeactivate: [NoOpenPopupsGuard, WaitUnsavedChangesGuard]
  },
  {
    path: 'not-allowed',
    component: NotAllowedComponent
  },
  {
    path: 'dashboards/:id',
    component: IndexComponent,
    canDeactivate: [NoOpenPopupsGuard, WaitUnsavedChangesGuard, OnBeforeDestroyGuard],
    data: { section: MenuCustomSection.Pages }
  },
  {
    path: 'dashboards',
    component: IndexComponent,
    canDeactivate: [NoOpenPopupsGuard, WaitUnsavedChangesGuard, OnBeforeDestroyGuard],
    data: { section: MenuCustomSection.Pages }
  },
  {
    path: 'models/:model/create',
    component: ChangeRouteComponent,
    canDeactivate: [NoOpenPopupsGuard, WaitUnsavedChangesGuard, UnsavedChangesGuard, OnBeforeDestroyGuard],
    data: {
      projectPermission: {
        model: {
          param: 'model'
        }
      },
      section: MenuCustomSection.Pages
    }
  },
  {
    path: 'models/:model/delete',
    component: DeleteComponent,
    canDeactivate: [NoOpenPopupsGuard, WaitUnsavedChangesGuard],
    data: {
      projectPermission: {
        model: {
          param: 'model'
        }
      },
      section: MenuCustomSection.Pages
    }
  },
  {
    path: 'models/:model/mass_edit',
    component: MassEditComponent,
    canDeactivate: [NoOpenPopupsGuard, WaitUnsavedChangesGuard],
    data: {
      projectPermission: {
        model: {
          param: 'model'
        }
      },
      section: MenuCustomSection.Pages
    }
  },
  {
    path: 'models/:model/user_activities',
    component: UserActivitiesComponent,
    canDeactivate: [NoOpenPopupsGuard],
    data: {
      filter: 'model_description',
      projectPermission: {
        model: {
          param: 'model'
        }
      },
      section: MenuCustomSection.Pages
    }
  },
  {
    path: 'models/:model/:id',
    component: ChangeRouteComponent,
    canDeactivate: [NoOpenPopupsGuard, WaitUnsavedChangesGuard, UnsavedChangesGuard, OnBeforeDestroyGuard],
    data: {
      projectPermission: {
        model: {
          param: 'model'
        }
      },
      section: MenuCustomSection.Pages
    }
  },
  {
    path: 'models/:model/:id/user_activities',
    component: UserActivitiesComponent,
    canDeactivate: [NoOpenPopupsGuard],
    data: { filter: 'model', section: MenuCustomSection.Pages }
  },
  {
    path: 'models/:model',
    component: ListRouteComponent,
    canActivate: [ProjectPermissionGuard],
    canDeactivate: [NoOpenPopupsGuard, WaitUnsavedChangesGuard, OnBeforeDestroyGuard],
    data: {
      projectPermission: {
        model: {
          param: 'model'
        }
      },
      section: MenuCustomSection.Pages
    }
  },
  {
    path: 'templates',
    component: TemplatesComponent,
    canDeactivate: [NoOpenPopupsGuard, WaitUnsavedChangesGuard],
    data: { section: MenuCustomSection.Pages }
  },
  {
    path: 'create_template',
    component: TemplatesItemComponent,
    canDeactivate: [NoOpenPopupsGuard, WaitUnsavedChangesGuard],
    data: { section: MenuCustomSection.Pages }
  },
  {
    path: 'templates/:id',
    component: TemplatesItemComponent,
    canDeactivate: [NoOpenPopupsGuard, WaitUnsavedChangesGuard],
    data: { section: MenuCustomSection.Pages }
  },
  {
    path: 'new-page',
    component: PageComponent,
    canDeactivate: [NoOpenPopupsGuard, WaitUnsavedChangesGuard, OnBeforeDestroyGuard],
    data: { section: MenuCustomSection.Pages }
  },
  {
    path: 'page/:page',
    component: PageComponent,
    canDeactivate: [NoOpenPopupsGuard, WaitUnsavedChangesGuard, OnBeforeDestroyGuard],
    data: { section: MenuCustomSection.Pages }
  },
  {
    path: 'conversations',
    component: ConversationsComponent,
    canDeactivate: [NoOpenPopupsGuard, WaitUnsavedChangesGuard],
    data: { section: MenuCustomSection.Collaboration }
  },
  {
    path: 'search',
    component: DemoComponent,
    canDeactivate: [NoOpenPopupsGuard, WaitUnsavedChangesGuard]
  },
  {
    path: 'user_activities',
    component: UserActivitiesComponent,
    canDeactivate: [NoOpenPopupsGuard],
    data: { section: MenuCustomSection.Collaboration }
  },
  {
    matcher: projectSettingsMatcher,
    component: ProjectSettingsComponent,
    canDeactivate: [NoOpenPopupsGuard, WaitUnsavedChangesGuard, UnsavedChangesGuard],
    data: { section: MenuCustomSection.Layout }
  },
  {
    path: 'signup/:tab',
    component: SignUpBuilderComponent,
    canDeactivate: [NoOpenPopupsGuard, WaitUnsavedChangesGuard],
    data: { section: MenuCustomSection.SignUp }
  },
  {
    path: 'signup',
    component: SignUpBuilderComponent,
    canDeactivate: [NoOpenPopupsGuard, WaitUnsavedChangesGuard],
    data: { section: MenuCustomSection.SignUp }
  },
  {
    path: 'automations/runs',
    component: AutomationRunsComponent,
    canDeactivate: [NoOpenPopupsGuard, WaitUnsavedChangesGuard],
    data: { section: MenuCustomSection.Automations }
  },
  {
    path: 'automations',
    component: AutomationsComponent,
    canDeactivate: [NoOpenPopupsGuard, WaitUnsavedChangesGuard],
    data: { section: MenuCustomSection.Automations }
  },
  {
    path: 'collaboration',
    component: CollaborationComponent,
    data: { section: MenuCustomSection.Collaboration },
    canDeactivate: [NoOpenPopupsGuard],
    children: [
      {
        path: 'messages',
        component: CollaborationMessagesComponent,
        canDeactivate: [NoOpenPopupsGuard, WaitUnsavedChangesGuard]
      },
      {
        path: 'tasks',
        component: CollaborationTasksComponent,
        canDeactivate: [NoOpenPopupsGuard, WaitUnsavedChangesGuard]
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'messages'
      }
    ]
  },
  {
    path: 'templates/apply/:id',
    component: ApplyTemplateComponent,
    canDeactivate: [NoOpenPopupsGuard]
  },
  {
    path: 'project',
    loadChildren: '@modules/project-settings-routes#ProjectSettingsRoutesModule'
  },
  {
    path: 'resources',
    loadChildren: '@modules/project-resource-components#ProjectResourceComponentsModule'
  },
  {
    path: '',
    loadChildren: '@modules/custom-views-routes#CustomViewsRoutesModule'
  },
  {
    path: 'guide',
    loadChildren: '@modules/tutorial-routes#TutorialRoutesModule'
  },
  {
    path: 'messages',
    loadChildren: '@modules/messages-routes#MessagesRoutesModule'
  }
];

export const routes: Routes = [
  {
    path: ':version/app/:project/:environment',
    data: {
      mode: AdminMode.App
    },
    component: AdminComponent,
    canActivate: [SetExternalTokenGuard, IsAuthenticatedGuard],
    resolve: {
      domain: DomainResolver
    },
    children: childrenRoutes
  },
  {
    path: ':version/app/:project',
    canActivateChild: [DefaultEnvironmentRedirectGuard],
    data: {
      routeWithVersion: true
    },
    children: [
      {
        path: '**',
        component: RedirectComponent
      }
    ]
  },
  {
    path: 'app/:project/:environment',
    data: {
      mode: AdminMode.App
    },
    component: AdminComponent,
    canActivate: [SetExternalTokenGuard, IsAuthenticatedGuard],
    resolve: {
      domain: DomainResolver
    },
    children: childrenRoutes
  },
  {
    path: 'app/:project',
    canActivateChild: [DefaultEnvironmentRedirectGuard],
    data: {
      routeWithVersion: false
    },
    children: [
      {
        path: '**',
        component: RedirectComponent
      }
    ]
  },
  {
    path: 'app',
    redirectTo: 'projects',
    pathMatch: 'full'
  },
  {
    path: ':version/builder/:project/:environment',
    data: {
      mode: AdminMode.Builder
    },
    component: AdminComponent,
    canActivate: [SetExternalTokenGuard, IsAuthenticatedGuard],
    resolve: {
      domain: DomainResolver
    },
    children: childrenRoutes
  },
  {
    path: ':version/builder/:project',
    canActivateChild: [DefaultEnvironmentRedirectGuard],
    data: {
      routeWithVersion: true
    },
    children: [
      {
        path: '**',
        component: RedirectComponent
      }
    ]
  },
  {
    path: 'builder/:project/:environment',
    data: {
      mode: AdminMode.Builder
    },
    component: AdminComponent,
    canActivate: [SetExternalTokenGuard, IsAuthenticatedGuard],
    resolve: {
      domain: DomainResolver
    },
    children: childrenRoutes
  },
  {
    path: 'builder/:project',
    canActivateChild: [DefaultEnvironmentRedirectGuard],
    data: {
      routeWithVersion: false
    },
    children: [
      {
        path: '**',
        component: RedirectComponent
      }
    ]
  },
  {
    path: 'builder',
    redirectTo: 'projects',
    pathMatch: 'full'
  }
];
