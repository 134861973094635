import { AbstractControlOptions, AsyncValidatorFn, FormArray, ValidatorFn } from '@angular/forms';
import range from 'lodash/range';

import { isSet } from '@shared';

import { Input } from '../../fields/data/input';
import { FieldInputControl, FieldInputControlsValue } from './field-input.control';

export class FieldInputArray extends FormArray {
  controls: FieldInputControl[];
  userInputEnabled = false;

  constructor(
    controls: FieldInputControl[] = [],
    validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null
  ) {
    super(controls, validatorOrOpts, asyncValidator);
  }

  patchValue(value: Input[], options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    this.deserialize(value);
  }

  setValue(value: any[], options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    this.deserialize(value);
  }

  get value(): Input[] {
    return this.serialize();
  }

  set value(value: Input[]) {}

  deserialize(value: Input[]) {
    value.forEach((item, i) => {
      const control = this.controls[i];

      if (control) {
        control.deserialize(item);
      } else {
        this.appendControl(item);
      }
    });

    this.controls.slice(value.length).forEach(item => this.removeControl(item));
  }

  serialize(): Input[] {
    return this.controls.map(control => control.serialize()).filter(item => isSet(item.path) && item.path.length);
  }

  setControls(controls: FieldInputControl[]) {
    this.removeControls();
    controls.forEach(item => this.push(item));
  }

  removeControls() {
    range(this.controls.length).forEach(() => this.removeAt(0));
  }

  removeControl(control: FieldInputControl) {
    const index = this.controls.findIndex(item => item === control);
    this.removeAt(index);
  }

  createControl(item?: Input, value?: FieldInputControlsValue): FieldInputControl {
    const control = new FieldInputControl();

    if (item) {
      control.deserialize(item);
    }

    if (value) {
      control.patchValue(value);
      control.markAsPristine();
    }

    return control;
  }

  prependControl(item?: Input, value?: FieldInputControlsValue): FieldInputControl {
    const control = this.createControl(item, value);
    this.insert(0, control);
    return control;
  }

  appendControl(item?: Input, value?: FieldInputControlsValue): FieldInputControl {
    const control = this.createControl(item, value);
    this.push(control);
    return control;
  }
}
