var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Input } from '@modules/fields';
import { FieldInputControl } from '@modules/parameters';
import { TextDecoration, TextTransform } from '@modules/styles';
import { AlignHorizontal, AlignVertical, BlendingMode, TextTruncate } from '@modules/views';
import { controlValue, isSet, parseNumber } from '@shared';
import { ViewEditorContext } from '../../../services/view-editor-context/view-editor.context';
import { BorderArray } from '../../controls/border.array';
import { ConstraintsControl } from '../../controls/constraints.control';
import { FillArray } from '../../controls/fill.array';
import { FontControl } from '../../controls/font.control';
import { FrameControl } from '../../controls/frame.control';
import { LayerInteractionArray } from '../../controls/layer-interaction.array';
import { ShadowArray } from '../../controls/shadow.array';
import { blendingModeOptions } from '../base/blending-mode-options';
export var TextBounds;
(function (TextBounds) {
    TextBounds["FluidWidth"] = "fluid_width";
    TextBounds["FluidHeight"] = "fluid_height";
    TextBounds["Fixed"] = "fixed";
})(TextBounds || (TextBounds = {}));
export function deserializeTextBounds(widthFluid, heightFluid) {
    if (!widthFluid && !heightFluid) {
        return TextBounds.Fixed;
    }
    else if (widthFluid) {
        return TextBounds.FluidWidth;
    }
    else if (!widthFluid && heightFluid) {
        return TextBounds.FluidHeight;
    }
}
export function serializeTextBounds(bounds) {
    if (bounds == TextBounds.Fixed) {
        return { widthFluid: false, heightFluid: false };
    }
    else if (bounds == TextBounds.FluidWidth) {
        return { widthFluid: true, heightFluid: false };
    }
    else if (bounds == TextBounds.FluidHeight) {
        return { widthFluid: false, heightFluid: true };
    }
}
var TextCustomizeLayerForm = /** @class */ (function (_super) {
    __extends(TextCustomizeLayerForm, _super);
    function TextCustomizeLayerForm(editorContext, injector) {
        var _this = _super.call(this, {
            frame: new FrameControl(),
            absolute_layout: new FormControl(false),
            constrain_proportion: new FormControl(undefined),
            reflect_horizontal: new FormControl(false),
            reflect_vertical: new FormControl(false),
            constraints: new ConstraintsControl(),
            opacity: new FormControl(1),
            blending_mode: new FormControl(BlendingMode.Normal),
            font: FontControl.inject(injector),
            fills: new FillArray([]),
            borders: new BorderArray([]),
            shadows: new ShadowArray([]),
            line_height: new FormControl(),
            letter_spacing: new FormControl(),
            align_horizontal: new FormControl(),
            align_vertical: new FormControl(),
            transform: new FormControl(),
            decoration: new FormControl(),
            text_bounds: new FormControl(),
            truncate: new FormControl(),
            truncate_max_lines: new FormControl(),
            visible_input: new FieldInputControl({ path: ['value'] }),
            visible_input_enabled: new FormControl(false),
            interactions: new LayerInteractionArray([])
        }) || this;
        _this.editorContext = editorContext;
        _this.injector = injector;
        _this.instance$ = new BehaviorSubject(undefined);
        _this.containerLayer$ = _this.instance$.pipe(switchMap(function (instance) {
            return instance ? _this.editorContext.getContainerLayer$(instance) : of(undefined);
        }));
        _this.blendingModeOptions = blendingModeOptions;
        _this.alignHorizontalOptions = [
            {
                value: AlignHorizontal.Left,
                icon: 'align_left',
                name: '',
                subtitle: 'Left'
            },
            {
                value: AlignHorizontal.Center,
                icon: 'align_center',
                name: '',
                subtitle: 'Center'
            },
            {
                value: AlignHorizontal.Right,
                icon: 'align_right',
                name: '',
                subtitle: 'Right'
            },
            {
                value: AlignHorizontal.Justify,
                icon: 'align_justify',
                name: '',
                subtitle: 'Justify'
            }
        ];
        _this.alignVerticalOptions = [
            {
                value: AlignVertical.Top,
                icon: 'align_vertical_top',
                name: '',
                subtitle: 'Top'
            },
            {
                value: AlignVertical.Center,
                icon: 'align_vertical_center',
                name: '',
                subtitle: 'Center'
            },
            {
                value: AlignVertical.Bottom,
                icon: 'align_vertical_bottom',
                name: '',
                subtitle: 'Bottom'
            }
        ];
        _this.transformOptions = [
            {
                value: undefined,
                icon: 'close',
                name: '',
                subtitle: 'No transform'
            },
            {
                value: TextTransform.Uppercase,
                icon: 'uppercase',
                name: '',
                subtitle: 'Uppercase'
            },
            {
                value: TextTransform.Lowercase,
                icon: 'lowercase',
                name: '',
                subtitle: 'Lowercase'
            },
            {
                value: TextTransform.Capitalize,
                icon: 'capitalize',
                name: '',
                subtitle: 'Capitalize'
            }
        ];
        _this.decorationOptions = [
            {
                value: undefined,
                icon: 'close',
                name: '',
                subtitle: 'No decoration'
            },
            {
                value: TextDecoration.Underline,
                icon: 'underline',
                name: '',
                subtitle: 'Underline'
            },
            {
                value: TextDecoration.Strikethrough,
                icon: 'strikethrough',
                name: '',
                subtitle: 'Strikethrough'
            }
        ];
        _this.textBoundsOptions = [
            {
                value: TextBounds.FluidWidth,
                icon: 'resize_horizontal',
                name: '',
                subtitle: 'Auto width'
            },
            {
                value: TextBounds.FluidHeight,
                icon: 'resize_vertical',
                name: '',
                subtitle: 'Auto height'
            },
            {
                value: TextBounds.Fixed,
                icon: 'rectangle',
                name: '',
                subtitle: 'Fixed size'
            }
        ];
        _this.textTruncateOptions = [
            {
                value: undefined,
                icon: 'close',
                name: '',
                subtitle: 'No truncate'
            },
            {
                value: TextTruncate.SingleLine,
                icon: 'starts_with',
                name: '',
                subtitle: 'Truncate to single line'
            },
            {
                value: TextTruncate.Custom,
                icon: 'gear_2',
                name: '',
                subtitle: 'Truncate custom'
            }
        ];
        combineLatest(_this.containerLayer$, controlValue(_this.controls.absolute_layout))
            .pipe(untilDestroyed(_this))
            .subscribe(function (_a) {
            var containerLayer = _a[0], absoluteLayout = _a[1];
            var disabled = containerLayer && !!containerLayer.flexLayout && !absoluteLayout;
            _this.controls.frame.setPositionDisabled(disabled);
        });
        return _this;
    }
    TextCustomizeLayerForm.prototype.ngOnDestroy = function () { };
    TextCustomizeLayerForm.prototype.init = function (instance, options) {
        if (options === void 0) { options = {}; }
        this.instance = instance;
        this.instance$.next(instance);
        this.controls.frame.deserialize(instance.frame, {
            translate: options.container ? options.container.options.translate : undefined
        });
        this.controls.absolute_layout.patchValue(instance.absoluteLayout);
        this.controls.constrain_proportion.patchValue(instance.constrainProportion);
        this.controls.reflect_horizontal.patchValue(instance.reflectHorizontal);
        this.controls.reflect_vertical.patchValue(instance.reflectVertical);
        if (instance.constraints) {
            this.controls.constraints.deserialize(instance.constraints);
        }
        this.controls.opacity.patchValue(instance.opacity);
        this.controls.blending_mode.patchValue(instance.blendingMode);
        if (instance.font) {
            this.controls.font.deserialize(instance.font);
        }
        this.controls.fills.deserialize(instance.fills);
        this.controls.borders.deserialize(instance.borders);
        this.controls.shadows.deserialize(instance.shadows);
        this.controls.line_height.patchValue(instance.lineHeight);
        this.controls.letter_spacing.patchValue(instance.letterSpacing);
        this.controls.align_horizontal.patchValue(instance.alignHorizontal);
        this.controls.align_vertical.patchValue(instance.alignVertical);
        this.controls.transform.patchValue(instance.transform);
        this.controls.decoration.patchValue(instance.decoration);
        this.controls.text_bounds.patchValue(deserializeTextBounds(instance.widthFluid, instance.heightFluid));
        this.controls.truncate.patchValue(instance.truncate);
        this.controls.truncate_max_lines.patchValue(instance.truncateMaxLines);
        this.controls.visible_input.patchValue(instance.visibleInput ? instance.visibleInput.serializeWithoutPath() : {});
        this.controls.visible_input_enabled.patchValue(instance.visibleInput && instance.visibleInput.isSet());
        this.controls.interactions.deserialize(instance.interactions);
        if (this.visibleInputEnabledInitial === undefined) {
            this.visibleInputEnabledInitial = !!this.controls.visible_input_enabled.value;
        }
        if (!options.firstInit) {
            this.markAsDirty();
        }
    };
    TextCustomizeLayerForm.prototype.updateWidthProportional = function () {
        this.controls.frame.updateWidthProportional(this.controls.constrain_proportion.value);
    };
    TextCustomizeLayerForm.prototype.updateHeightProportional = function () {
        this.controls.frame.updateHeightProportional(this.controls.constrain_proportion.value);
    };
    TextCustomizeLayerForm.prototype.toggleConstrainProportion = function () {
        if (this.controls.constrain_proportion.value) {
            this.controls.constrain_proportion.patchValue(undefined);
        }
        else {
            this.controls.constrain_proportion.patchValue(this.controls.frame.getProportion());
        }
    };
    TextCustomizeLayerForm.prototype.getDefaultLineHeight$ = function () {
        return controlValue(this.controls.font.controls.size).pipe(map(function (size) {
            if (isSet(size)) {
                var sizeNumber = parseNumber(size);
                if (isSet(sizeNumber)) {
                    return Math.round(sizeNumber * 1.45);
                }
            }
            else {
                return Math.round(14 * 1.45);
            }
        }));
    };
    TextCustomizeLayerForm.prototype.submit = function () {
        var instance = this.instance;
        var _a = serializeTextBounds(this.controls.text_bounds.value), widthFluid = _a.widthFluid, heightFluid = _a.heightFluid;
        instance.frame = this.controls.frame.serialize();
        instance.widthFluid = widthFluid;
        instance.heightFluid = heightFluid;
        instance.absoluteLayout = this.controls.absolute_layout.value;
        instance.constrainProportion = this.controls.constrain_proportion.value;
        instance.reflectHorizontal = this.controls.reflect_horizontal.value;
        instance.reflectVertical = this.controls.reflect_vertical.value;
        instance.constraints = this.controls.constraints.serialize();
        instance.opacity = this.controls.opacity.value;
        instance.blendingMode = this.controls.blending_mode.value;
        instance.font = this.controls.font.serialize();
        instance.fills = this.controls.fills.serialize();
        instance.borders = this.controls.borders.serialize();
        instance.shadows = this.controls.shadows.serialize();
        instance.lineHeight = this.controls.line_height.value;
        instance.letterSpacing = this.controls.letter_spacing.value;
        instance.alignHorizontal = this.controls.align_horizontal.value;
        instance.alignVertical = this.controls.align_vertical.value;
        instance.transform = this.controls.transform.value;
        instance.decoration = this.controls.decoration.value;
        instance.truncate = this.controls.truncate.value;
        instance.truncateMaxLines = this.controls.truncate_max_lines.value;
        instance.visibleInput =
            this.controls.visible_input_enabled.value && this.controls.visible_input.value
                ? new Input().deserialize(this.controls.visible_input.value)
                : undefined;
        instance.interactions = this.controls.interactions.serialize();
        return instance;
    };
    return TextCustomizeLayerForm;
}(FormGroup));
export { TextCustomizeLayerForm };
