/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../fields/components/auto-field/auto-field.component.ngfactory";
import * as i2 from "../../../fields/components/auto-field/auto-field.component";
import * as i3 from "../../../field-components/components/auto-field-stub/auto-field-stub.component.ngfactory";
import * as i4 from "../../../field-components/components/auto-field-stub/auto-field-stub.component";
import * as i5 from "@angular/common";
import * as i6 from "../../../customize/data/view-context-element";
import * as i7 from "../../../customize/data/view-context";
import * as i8 from "./auto-field-element.component";
import * as i9 from "../../../customize/services/customize/customize.service";
import * as i10 from "../../../model-queries/stores/model-description.store";
import * as i11 from "../../../projects/stores/current-project.store";
import * as i12 from "../../../action-queries/services/action-controller/action-controller.service";
import * as i13 from "../custom-page-popup/custom-page-popup.component";
var styles_AutoFieldElementComponent = [];
var RenderType_AutoFieldElementComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AutoFieldElementComponent, data: {} });
export { RenderType_AutoFieldElementComponent as RenderType_AutoFieldElementComponent };
function View_AutoFieldElementComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-auto-field", [], null, null, null, i1.View_AutoFieldComponent_0, i1.RenderType_AutoFieldComponent)), i0.ɵdid(1, 770048, null, 0, i2.AutoFieldComponent, [i0.ChangeDetectorRef], { form: [0, "form"], field: [1, "field"], readonly: [2, "readonly"], labelStyleGlobal: [3, "labelStyleGlobal"], labelAdditional: [4, "labelAdditional"], labelAdditionalStyleGlobal: [5, "labelAdditionalStyleGlobal"], elementStyles: [6, "elementStyles"], context: [7, "context"], fieldContextElement: [8, "fieldContextElement"], manualMargin: [9, "manualMargin"], elementActions: [10, "elementActions"], tooltip: [11, "tooltip"], accentColor: [12, "accentColor"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.form; var currVal_1 = _co.state.field; var currVal_2 = _co.state.readonly; var currVal_3 = "fieldLabelTextStyle"; var currVal_4 = _co.element.labelAdditional; var currVal_5 = "fieldLabelAdditionalTextStyle"; var currVal_6 = _co.elementStyles; var currVal_7 = _co.context; var currVal_8 = _co.viewContextElement; var currVal_9 = true; var currVal_10 = _co.element.elementActions; var currVal_11 = _co.state.tooltip; var currVal_12 = _co.accentColor; _ck(_v, 1, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12]); }, null); }
function View_AutoFieldElementComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-auto-field-stub", [], null, null, null, i3.View_AutoFieldStubComponent_0, i3.RenderType_AutoFieldStubComponent)), i0.ɵdid(1, 114688, null, 0, i4.AutoFieldStubComponent, [], { field: [0, "field"], readonly: [1, "readonly"], labelStyleGlobal: [2, "labelStyleGlobal"], labelAdditional: [3, "labelAdditional"], labelAdditionalStyleGlobal: [4, "labelAdditionalStyleGlobal"], elementStyles: [5, "elementStyles"], manualMargin: [6, "manualMargin"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.state.field; var currVal_1 = _co.state.readonly; var currVal_2 = "fieldLabelTextStyle"; var currVal_3 = _co.element.labelAdditional; var currVal_4 = "fieldLabelAdditionalTextStyle"; var currVal_5 = _co.elementStyles; var currVal_6 = true; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
function View_AutoFieldElementComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AutoFieldElementComponent_2)), i0.ɵdid(2, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AutoFieldElementComponent_3)), i0.ɵdid(4, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.loadingValue && _co.state.field); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.loadingValue; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_AutoFieldElementComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 2, null, View_AutoFieldElementComponent_1)), i0.ɵdid(1, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i5.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.visible || i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.customizeEnabled$))); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AutoFieldElementComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-auto-field-element", [], null, null, null, View_AutoFieldElementComponent_0, RenderType_AutoFieldElementComponent)), i0.ɵprd(131584, null, i6.ViewContextElement, i6.ViewContextElement, [i7.ViewContext]), i0.ɵdid(2, 770048, null, 0, i8.AutoFieldElementComponent, [i9.CustomizeService, i10.ModelDescriptionStore, i11.CurrentProjectStore, i12.ActionControllerService, i6.ViewContextElement, i0.Injector, i0.ChangeDetectorRef, [2, i13.CustomPagePopupComponent]], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var AutoFieldElementComponentNgFactory = i0.ɵccf("app-auto-field-element", i8.AutoFieldElementComponent, View_AutoFieldElementComponent_Host_0, { element: "element", elementActive: "elementActive", context: "context", visible: "visible", actionClicked: "actionClicked", accentColor: "accentColor" }, { updated: "updated", replaceRequested: "replaceRequested", deleteRequested: "deleteRequested" }, []);
export { AutoFieldElementComponentNgFactory as AutoFieldElementComponentNgFactory };
