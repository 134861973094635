import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import clamp from 'lodash/clamp';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { merge, of, ReplaySubject, Subject, timer } from 'rxjs';
import { delay, filter, map, switchMap, tap } from 'rxjs/operators';

import { ThemeService } from '@modules/theme';
import { ThemeContext } from '@modules/theme-components';
import { isSet, toggleClass } from '@shared';

import { ThemeTemplate } from '../theme-gallery/theme-template';

@Component({
  selector: 'app-theme-gallery-item',
  templateUrl: './theme-gallery-item.component.html',
  providers: [ThemeContext],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ThemeGalleryItemComponent implements OnInit, OnDestroy {
  @Input() theme: ThemeTemplate;

  @ViewChild('image') image: ElementRef<HTMLElement>;
  @ViewChild('segment') segment: ElementRef<HTMLElement>;

  isDarkTheme = false;
  mouseEnter$ = new Subject<void>();
  mouseMove$ = new ReplaySubject<MouseEvent>(1);
  mouseLeave$ = new Subject<void>();

  constructor(private themeService: ThemeService, private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.themeService.isDarkTheme$
      .pipe(
        tap(value => {
          this.isDarkTheme = value;
          this.cd.markForCheck();

          this.setAnimate(false);
        }),
        delay(200),
        tap(() => {
          this.setAnimate(true);
        }),
        untilDestroyed(this)
      )
      .subscribe();

    merge(this.mouseEnter$.pipe(map(() => true)), this.mouseLeave$.pipe(map(() => false)))
      .pipe(
        switchMap(enter => {
          if (enter) {
            this.setAnimate(true);

            return timer(400).pipe(
              switchMap(() => {
                return merge(
                  this.mouseMove$,
                  timer(200).pipe(
                    tap(() => this.setAnimate(false)),
                    filter(() => false)
                  )
                );
              })
            );
          } else {
            this.setAnimate(true);
            return of(undefined);
          }
        }),
        untilDestroyed(this)
      )
      .subscribe(event => {
        if (event) {
          this.onMouseMove(event);
        } else {
          this.onMouseLeave();
        }
      });
  }

  ngOnDestroy(): void {}

  setSliceX(x?: number) {
    if (!this.segment) {
      return;
    }

    if (isSet(x)) {
      this.segment.nativeElement.style.setProperty('--slice-x', `${x}px`);
    } else {
      this.segment.nativeElement.style.removeProperty('--slice-x');
    }
  }

  setAnimate(animate: boolean) {
    if (!this.segment) {
      return;
    }

    toggleClass(this.segment.nativeElement, 'sidebar-gallery-item__image-segment_animate', animate);
  }

  onMouseMove(e: MouseEvent) {
    if (!this.image) {
      return;
    }

    const bounds = this.image.nativeElement.getBoundingClientRect();
    const percentage = clamp((e.clientX - bounds.left) / bounds.width, 0, 1);

    this.setSliceX(66 * percentage);
  }

  onMouseLeave() {
    this.setSliceX(undefined);
  }
}
