<div class="list-footer">
  <div class="list-footer__main">
    <div *ngIf="pagination" class="list__pagination">
      <div class="pagination" [class.pagination_theme]="theme">
        <span class="pagination__control" [class.pagination__control_disabled]="true">
          <span [class.loading-animation]="animating"><span class="stub-text"><</span></span>
        </span>

        <div class="pagination__pages">
          <span
            *ngFor="let selected of [true, false, false]"
            class="pagination__pages-item"
            [class.pagination__pages-item_loading]="true"
            [class.pagination__pages-item_selected]="selected"
          >
            <span [class.loading-animation]="animating"><span class="stub-text">1</span></span>
          </span>
        </div>

        <span class="pagination__control" [class.pagination__control_disabled]="true">
          <span [class.loading-animation]="animating"><span class="stub-text">></span></span>
        </span>
      </div>
    </div>
  </div>
  <div class="list-footer__right">
    <div *ngIf="count" class="list__status list-footer__item">
      <span [class.loading-animation]="animating"><span class="stub-text">items</span></span>
    </div>
  </div>
</div>
