import { FieldLookup, lookups, serializeFieldParamName } from '@modules/field-lookups';

export class FilterItem2 {
  field: string[];
  lookup: FieldLookup;
  value: any;
  exclude = false;

  constructor(options: Partial<FilterItem2> = {}) {
    Object.assign(this, options);
  }

  getName(): string {
    return serializeFieldParamName(this.field.join('__'), this.lookup ? this.lookup.lookup : undefined, this.exclude);
  }

  deserialize(data: Object): FilterItem2 {
    this.field = data['field'];
    this.lookup = data['lookup'] ? lookups.find(item => item.lookup == data['lookup']) : undefined;
    this.value = data['value'];
    this.exclude = data['exclude'];

    return this;
  }

  serialize() {
    return {
      field: this.field,
      lookup: this.lookup ? this.lookup.lookup : undefined,
      value: this.value,
      exclude: this.exclude
    };
  }
}
