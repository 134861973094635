import { ChangeDetectorRef, EventEmitter, NgZone, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, of } from 'rxjs';
import { distinctUntilChanged, filter, first, map, switchMap } from 'rxjs/operators';
import { DialogService } from '@common/dialogs';
import { preventDragMouseDownEvent } from '@common/drag-drop2';
import { NumberFieldType } from '@modules/fields';
import { MenuContext } from '@modules/menu-components';
import { CurrentProjectStore } from '@modules/projects';
import { ThemeService } from '@modules/theme';
import { CurrentUserStore } from '@modules/users';
import { ascComparator, controlValue, isSet } from '@shared';
import { MenuBlockControl } from '../project-settings/menu-block.control';
var MenuBlockEditComponent = /** @class */ (function () {
    function MenuBlockEditComponent(currentUserStore, currentProjectStore, context, themeService, dialogService, sanitizer, zone, cd) {
        this.currentUserStore = currentUserStore;
        this.currentProjectStore = currentProjectStore;
        this.context = context;
        this.themeService = themeService;
        this.dialogService = dialogService;
        this.sanitizer = sanitizer;
        this.zone = zone;
        this.cd = cd;
        this.title = 'Menu';
        this.layoutGroups = [];
        this.active = false;
        this.disabled = false;
        this.edit = new EventEmitter();
        this.remove = new EventEmitter();
        this.enabledInputToggled = false;
        this.totalItems = 0;
        this.itemsPluralMap = { '=1': '# item', '=0': 'no items', other: '# items' };
        this.layoutOpened = false;
        this.widthEnabled = false;
        this.heightEnabled = false;
        this.styles = [];
        this.enabledStyles = [];
        this.numberFieldTypes = NumberFieldType;
    }
    MenuBlockEditComponent.prototype.trackStyleFn = function (i, item) {
        return item.control;
    };
    MenuBlockEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        controlValue(this.control.controls.layout)
            .pipe(untilDestroyed(this))
            .subscribe(function (layout) {
            _this.layoutSelected = _this.layoutGroups
                .reduce(function (acc, item) {
                acc.push.apply(acc, item.options);
                return acc;
            }, [])
                .find(function (item) { return item.layout == layout; });
            _this.cd.markForCheck();
        });
        this.control
            .getTotalItems$()
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            _this.totalItems = _this.control.getTotalItems();
            _this.cd.markForCheck();
        });
        combineLatest(this.control.getWidthEnabled$(), this.control.getHeightEnabled$(), this.control.getDefaultWidth$(), this.control.getDefaultHeight$())
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var widthEnabled = _a[0], heightEnabled = _a[1], defaultWidth = _a[2], defaultHeight = _a[3];
            _this.widthEnabled = widthEnabled;
            _this.heightEnabled = heightEnabled;
            _this.defaultWidth = defaultWidth;
            _this.defaultHeight = defaultHeight;
            _this.cd.markForCheck();
        });
        this.updateStyles();
    };
    MenuBlockEditComponent.prototype.ngOnDestroy = function () { };
    MenuBlockEditComponent.prototype.updateStyles = function () {
        var _this = this;
        this.styles = [
            {
                label: 'Width',
                icon: 'resize_horizontal',
                control: this.control.controls.width,
                isSet$: function (control) { return controlValue(control).pipe(map(function (value) { return isSet(value); })); },
                isEnabled: function () { return _this.widthEnabled; },
                onRemove: function (style) { return style.control.reset(); }
            },
            {
                label: 'Height',
                icon: 'resize_vertical',
                control: this.control.controls.height,
                isSet$: function (control) { return controlValue(control).pipe(map(function (value) { return isSet(value); })); },
                isEnabled: function () { return _this.heightEnabled; },
                onRemove: function (style) { return style.control.reset(); }
            },
            {
                label: 'Background',
                icon: 'palette',
                control: this.control.controls.fill_settings,
                isSet$: function (control) { return control.isSet$(); },
                onAdd: function (style) { return style.control.resetDefaults(); },
                onRemove: function (style) { return style.control.reset(); }
            },
            {
                label: 'Border',
                icon: 'sides',
                control: this.control.controls.border_settings,
                isSet$: function (control) { return control.isSet$(); },
                onAdd: function (style) { return style.control.resetDefaults(); },
                onRemove: function (style) { return style.control.reset(); }
            },
            {
                label: 'Corners',
                icon: 'corners',
                control: this.control.controls.border_radius,
                isSet$: function (control) { return control.isSet$(); },
                onRemove: function (style) { return style.control.reset(); }
            },
            {
                label: 'Outer spacing',
                icon: 'align_horizontal_center',
                control: this.control.controls.padding,
                isSet$: function (control) { return control.isSet$(); },
                onRemove: function (style) { return style.control.reset(); }
            }
        ];
        var styles$ = this.styles.map(function (item) {
            return item.isSet$(item.control).pipe(map(function (value) { return !!value; }), distinctUntilChanged(), map(function (styleIsSet) {
                if (styleIsSet) {
                    return item;
                }
                else if (_this.enabledStyles.find(function (enabledItem) { return enabledItem.label == item.label; })) {
                    return item;
                }
            }));
        });
        (styles$.length ? combineLatest.apply(void 0, styles$) : of([])).pipe(untilDestroyed(this)).subscribe(function (result) {
            _this.enabledStyles = result
                .filter(function (item) { return item; })
                .sort(function (lhs, rhs) {
                var lhsIndex = _this.enabledStyles.findIndex(function (enabledItem) { return enabledItem.label == lhs.label; });
                var rhsIndex = _this.enabledStyles.findIndex(function (enabledItem) { return enabledItem.label == rhs.label; });
                if (lhsIndex === -1 && rhsIndex === -1) {
                    return 0;
                }
                else if (lhsIndex === -1 && rhsIndex !== -1) {
                    return 1;
                }
                else if (lhsIndex !== -1 && rhsIndex === -1) {
                    return -1;
                }
                else {
                    return ascComparator(lhsIndex, rhsIndex);
                }
            });
            _this.cd.markForCheck();
        });
    };
    MenuBlockEditComponent.prototype.addStyle = function (style) {
        var _this = this;
        this.enabledStyles = this.enabledStyles.concat([style]);
        this.addedStyle = style;
        this.cd.markForCheck();
        if (style.onAdd) {
            style.onAdd(style);
        }
        this.zone.onStable.pipe(first(), untilDestroyed(this)).subscribe(function () {
            _this.addedStyle = undefined;
        });
    };
    MenuBlockEditComponent.prototype.isAddStyleEnabled = function (style) {
        return !this.enabledStyles.includes(style);
    };
    MenuBlockEditComponent.prototype.removeStyle = function (style) {
        var _this = this;
        style
            .isSet$(style.control)
            .pipe(first(), switchMap(function (styleIsSet) {
            if (!styleIsSet) {
                return of(true);
            }
            return _this.dialogService.warning({
                title: 'Remove style',
                description: "Are you sure want to remove style <strong>\"" + style.label + "\"</strong>?",
                style: 'orange'
            });
        }), filter(function (result) { return result == true; }), untilDestroyed(this))
            .subscribe(function () {
            _this.removeStyleProcess(style);
        });
    };
    MenuBlockEditComponent.prototype.removeStyleProcess = function (style) {
        this.enabledStyles = this.enabledStyles.filter(function (item) { return item !== style; });
        this.cd.markForCheck();
        if (style.onRemove) {
            style.onRemove(style);
        }
    };
    MenuBlockEditComponent.prototype.selectLayoutOption = function (option) {
        var newMenu = !this.control.controls.layout.value;
        this.control.setLayout(option.layout);
        if (isSet(option.color) &&
            !isSet(this.control.controls.fill_settings.controls.fill.isSet()) &&
            !isSet(this.control.controls.fill_settings.controls.fill_dark.isSet())) {
            this.control.controls.fill_settings.controls.fill.setColor(option.color);
            this.control.controls.fill_settings.controls.fill_dark.setColor(option.color);
        }
        this.control.controls.width.patchValue(undefined);
        this.control.controls.height.patchValue(undefined);
        if (newMenu) {
            this.control.controls.enabled.patchValue(true);
            this.control.applyDefaultState();
        }
    };
    MenuBlockEditComponent.prototype.setLayoutOpened = function (value) {
        this.layoutOpened = value;
        this.cd.markForCheck();
    };
    MenuBlockEditComponent.prototype.enable = function () {
        if (this.control.controls.layout.value) {
            this.control.controls.enabled.patchValue(true);
        }
        else {
            this.setLayoutOpened(true);
        }
    };
    MenuBlockEditComponent.prototype.disable = function () {
        this.control.controls.enabled.patchValue(false);
    };
    MenuBlockEditComponent.prototype.toggleEnabledInput = function () {
        if (this.control.controls.enabled_input_enabled.value) {
            this.control.controls.enabled_input_enabled.patchValue(false);
            this.enabledInputToggled = false;
        }
        else {
            this.control.controls.enabled_input_enabled.patchValue(true);
            this.enabledInputToggled = true;
        }
    };
    MenuBlockEditComponent.prototype.toggleEnabled = function () {
        if (this.control.controls.enabled.value) {
            this.disable();
        }
        else {
            this.enable();
        }
    };
    MenuBlockEditComponent.prototype.onClick = function (event) {
        if (this.control.controls.enabled.value) {
            return;
        }
        this.enable();
        event.stopPropagation();
    };
    MenuBlockEditComponent.prototype.onStylesMousedown = function (e) {
        preventDragMouseDownEvent(e);
    };
    MenuBlockEditComponent.prototype.confirmRemove = function () {
        var _this = this;
        this.dialogService
            .warning({
            title: 'Deleting',
            description: "Are you sure want to delete " + this.title + " menu?"
        })
            .pipe(filter(function (result) { return result == true; }), untilDestroyed(this))
            .subscribe(function () { return _this.remove.emit(); });
    };
    return MenuBlockEditComponent;
}());
export { MenuBlockEditComponent };
