import values from 'lodash/values';

import { ActionElementStyles, ElementWrapperStyles, FieldElementStyles } from '@modules/customize';
import {
  ActionElementStylesControl,
  ElementWrapperStylesControl,
  FieldElementStylesControl
} from '@modules/customize-bar';
import { MenuBlock } from '@modules/menu';
import { isSet } from '@shared';

import { MenuBlockControl } from '../project-settings/menu-block.control';

export function serializeActionElementStyles(control: ActionElementStylesControl): Partial<ActionElementStyles> {
  if (!control.isSet()) {
    return;
  }

  const styles: Partial<ActionElementStyles> = {};
  const controls = control.controls;

  styles.textStyle = controls.text_style.serialize(false, true);
  styles.iconSettings = controls.icon_settings.serialize(false);
  styles.fillSettings = controls.fill_settings.serialize(false);
  styles.borderSettings = controls.border_settings.serialize(false);
  styles.borderRadius = controls.border_radius.serialize();
  styles.shadow = controls.shadow.serialize(false);
  styles.padding = controls.padding.serialize();
  styles.margin = controls.margin.serialize();

  styles.hoverTextStyle = controls.hover_text_style.serialize(false, true);
  styles.hoverIconSettings = controls.hover_icon_settings.serialize(false);
  styles.hoverFillSettings = controls.hover_fill_settings.serialize(false);
  styles.hoverBorderSettings = controls.hover_border_settings.serialize(false);
  styles.hoverShadow = controls.hover_shadow.serialize(false);

  styles.activeTextStyle = controls.active_text_style.serialize(false, true);
  styles.activeIconSettings = controls.active_icon_settings.serialize(false);
  styles.activeFillSettings = controls.active_fill_settings.serialize(false);
  styles.activeBorderSettings = controls.active_border_settings.serialize(false);
  styles.activeShadow = controls.active_shadow.serialize(false);

  if (!values(styles).some(item => isSet(item))) {
    return;
  }

  return styles;
}

export function serializeFieldElementStyles(control: FieldElementStylesControl): Partial<FieldElementStyles> {
  if (!control.isSet()) {
    return;
  }

  const styles: Partial<FieldElementStyles> = {};
  const controls = control.controls;

  styles.textStyle = controls.text_style.serialize(false, true);
  styles.placeholderStyle = controls.placeholder_style.serialize(false, true);
  styles.labelStyle = controls.label_style.serialize(false, true);
  styles.labelAdditionalStyle = controls.label_additional_style.serialize(false, true);
  styles.fillSettings = controls.fill_settings.serialize(false);
  styles.borderSettings = controls.border_settings.serialize(false);
  styles.borderRadius = controls.border_radius.serialize();
  styles.shadow = controls.shadow.serialize(false);
  styles.padding = controls.padding.serialize();
  styles.margin = controls.margin.serialize();

  styles.hoverTextStyle = controls.hover_text_style.serialize(false, true);
  styles.hoverPlaceholderStyle = controls.hover_placeholder_style.serialize(false, true);
  styles.hoverFillSettings = controls.hover_fill_settings.serialize(false);
  styles.hoverBorderSettings = controls.hover_border_settings.serialize(false);
  styles.hoverShadow = controls.hover_shadow.serialize(false);

  styles.focusTextStyle = controls.focus_text_style.serialize(false, true);
  styles.focusPlaceholderStyle = controls.focus_placeholder_style.serialize(false, true);
  styles.focusFillSettings = controls.focus_fill_settings.serialize(false);
  styles.focusBorderSettings = controls.focus_border_settings.serialize(false);
  styles.focusShadow = controls.focus_shadow.serialize(false);

  styles.errorTextStyle = controls.error_text_style.serialize(false, true);
  styles.errorPlaceholderStyle = controls.error_placeholder_style.serialize(false, true);
  styles.errorFillSettings = controls.error_fill_settings.serialize(false);
  styles.errorBorderSettings = controls.error_border_settings.serialize(false);
  styles.errorShadow = controls.error_shadow.serialize(false);

  if (!values(styles).some(item => isSet(item))) {
    return;
  }

  return styles;
}

export function serializeElementWrapperStyles(control: ElementWrapperStylesControl): Partial<ElementWrapperStyles> {
  if (!control.isSet()) {
    return;
  }

  const styles: Partial<ElementWrapperStyles> = {};
  const controls = control.controls;

  styles.borderSettings = controls.border_settings.serialize(false);
  styles.borderRadius = controls.border_radius.serialize();
  styles.shadow = controls.shadow.serialize(false);
  styles.margin = controls.margin.serialize();

  if (!values(styles).some(item => isSet(item))) {
    return;
  }

  return styles;
}

export function serializeMenuBlock(control: MenuBlockControl): Partial<MenuBlock> {
  if (!control.controls.enabled.value || control.controls.enabled_input_enabled.value) {
    return;
  }

  const styles: Partial<MenuBlock> = {};
  const controls = control.controls;

  styles.layout = controls.layout.value;
  styles.fillSettings = controls.fill_settings.serialize(false);
  styles.borderSettings = controls.border_settings.serialize(false);
  styles.width = controls.width.value;
  styles.height = controls.height.value;
  styles.borderRadius = controls.border_radius.serialize();
  styles.padding = controls.padding.serialize();

  if (!values(styles).some(item => isSet(item))) {
    return;
  }

  return styles;
}
