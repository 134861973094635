<app-view-context-token-popover-overlay
  [context]="context"
  [contextElement]="contextElement"
  [contextElementPath]="contextElementPath"
  [contextElementPaths]="contextElementPaths"
  [origin]="popoverOrigin || insert_token_origin"
  [forceOpened]="popoverForcedOpened"
  [extraSections]="extraSections"
  [hideSections]="['functions']"
  (selected)="onJsTokenSelected($event); closeTokenPopover()"
></app-view-context-token-popover-overlay>

<app-auto-field
  [form]="itemForm"
  [label]="false"
  [field]="
    createField({
      name: 'js_value',
      field: 'CodeField',
      placeholder: placeholder,
      params: {
        language: 'javascript',
        autogrow: !fillVertical,
        flexible: fillVertical,
        field: true,
        field_contrast: true,
        field_header: header,
        field_footer: true,
        wrap: true,
        dark: dark,
        classes: [fill ? 'input_fill' : ''].concat(classes || [])
      }
    })
  "
  #js_field
></app-auto-field>

<div
  class="button-tags"
  [class.button-tags_border]="true"
  [class.button-tags_footer]="true"
  cdkOverlayOrigin
  #insert_token_origin="cdkOverlayOrigin"
>
  <div
    *ngIf="popoverForcedOpened === undefined"
    class="button-tag button-tags__item"
    [class.button-tags__item_fill]="false"
    [class.button-tag_darker]="darker"
    (click)="openTokenPopover()"
  >
    <span class="button-tag__icon button-tag__icon_text">{{ '{' }}..{{ '}' }}</span>
    <span class="button-tag__label">Insert Input</span>
  </div>

  <ng-container *ngIf="displayValueTypesEnabled">
    <div
      *ngIf="staticValueDisabled"
      class="button-tag button-tags__item"
      [class.button-tags__item_fill]="false"
      [class.button-tag_darker]="darker"
      (click)="switchToFormula.emit()"
    >
      <span class="button-tag__icon icon-console"></span>
      <span class="button-tag__label">Disable JavaScript</span>
    </div>

    <div
      *ngIf="!staticValueDisabled"
      class="button-tag button-tags__item"
      [class.button-tags__item_fill]="false"
      [class.button-tag_darker]="darker"
      (click)="switchToDefault.emit()"
    >
      <span class="button-tag__icon icon-console"></span>
      <span class="button-tag__label">Disable JavaScript</span>
    </div>
  </ng-container>
</div>

<div *ngIf="!inputValueLoading && inputSet" class="input-footer" [class.input-footer_overlay]="true">
  <div class="input-footer__content">
    <app-input-edit-result
      [value]="inputValue"
      [error]="inputError"
      [errorDescription]="inputErrorDescription"
      [focus]="focused"
      [showOnFocus]="resultShowOnFocus"
      [indicatorBottom]="28"
      (inputFocus)="focus()"
    ></app-input-edit-result>
  </div>
</div>
