<form [formGroup]="form" (ngSubmit)="submit()">
  <app-customize-bar-header
    [title]="form.controls.name.value || (titleEditable ? 'chart' : 'Chart')"
    [titleEnabled]="titleEnabled"
    [titleEditable]="titleEditable"
    [titleCleanValue]="titleCleanValue"
    [backLabel]="'All components'"
    [deleteEnabled]="deleteEnabled"
    [parentLabel]="parentElement?.displayName || parentPopup?.displayName"
    (parentClick)="parentElement?.customize({ event: $event }) || parentPopup?.customize({ event: $event })"
    (deleteClick)="delete()"
    (titleChanged)="onTitleChanged($event)"
  >
  </app-customize-bar-header>

  <div class="sidebar__content">
    <app-sidebar-tabs>
      <app-sidebar-tab [label]="'General'">
        <app-sidebar-section>
          <div class="sidebar__element">
            <app-sidebar-field [label]="'chart type'">
              <ng-template let-image="image" let-name="name" let-data="data" #input_template>
                <span *ngIf="image" class="select__input-icon" [ngClass]="['icon-' + image]"></span>
                <span class="select__input-label">{{ name }}</span>
                <span class="select__input-arrow"></span>
              </ng-template>

              <ng-template let-image="image" let-name="name" let-data="data" #options_template>
                <span *ngIf="image" class="options__item-icon" [ngClass]="['icon-' + image]"></span>
                <span class="options__item-label">{{ name }}</span>
              </ng-template>

              <gxs-select
                [placeholder]="'Choose Field type'"
                [formControlName]="'chart_type'"
                [inputTemplate]="input_template"
                [optionTemplate]="options_template"
                [options]="{
                  theme: 'jet_orange',
                  search: true,
                  searchMinimumLength: 1,
                  searchDebounce: 0,
                  classes: ['select_fill'],
                  labels: {} | localizeSelect
                }"
              >
                <gxs-option
                  *ngFor="let option of form.chartTypeOptions"
                  [name]="option.name"
                  [value]="option.value"
                  [image]="option.image"
                  [data]="option.data"
                >
                </gxs-option>
              </gxs-select>
            </app-sidebar-field>
          </div>

          <div *ngIf="form.percentageChartTypes.includes(form.controls.chart_type.value)" class="sidebar__element">
            <app-sidebar-field>
              <div class="sidebar-icon-buttons sidebar-icon-buttons_border">
                <div
                  class="sidebar-icon-button"
                  [class.sidebar-icon-button_active]="!form.controls.percentage.value"
                  (click)="form.controls.percentage.setValue(false)"
                >
                  <div class="sidebar-icon-button__label">Show as is</div>
                </div>
                <div
                  class="sidebar-icon-button"
                  [class.sidebar-icon-button_active]="form.controls.percentage.value"
                  (click)="form.controls.percentage.setValue(true)"
                >
                  <div class="sidebar-icon-button__label">Show 100% bars</div>
                </div>
              </div>
            </app-sidebar-field>
          </div>

          <div *ngIf="form.smoothChartTypes.includes(form.controls.chart_type.value)" class="sidebar__element">
            <app-sidebar-field>
              <div class="sidebar-icon-buttons sidebar-icon-buttons_border">
                <div
                  class="sidebar-icon-button"
                  [class.sidebar-icon-button_active]="!form.controls.smooth.value"
                  (click)="form.controls.smooth.setValue(false)"
                >
                  <div class="sidebar-icon-button__label">Straight lines</div>
                </div>
                <div
                  class="sidebar-icon-button"
                  [class.sidebar-icon-button_active]="form.controls.smooth.value"
                  (click)="form.controls.smooth.setValue(true)"
                >
                  <div class="sidebar-icon-button__label">Smooth lines</div>
                </div>
              </div>
            </app-sidebar-field>
          </div>

          <div class="sidebar__element">
            <app-sidebar-field [label]="'datasets'" [required]="true">
              <!--              <div class="sidebar-icon-buttons" style="margin-bottom: 10px;">-->
              <!--                <div-->
              <!--                  class="sidebar-icon-button"-->
              <!--                  [class.sidebar-icon-button_active]="!form.controls.dataset_column_enabled.value"-->
              <!--                  (click)="form.controls.dataset_column_enabled.patchValue(false)"-->
              <!--                >-->
              <!--                  <div class="sidebar-icon-button__icon icon-chart"></div>-->
              <!--                  <div class="sidebar-icon-button__label">Specify each dataset</div>-->
              <!--                </div>-->
              <!--                <div-->
              <!--                  class="sidebar-icon-button"-->
              <!--                  [class.sidebar-icon-button_active]="form.controls.dataset_column_enabled.value"-->
              <!--                  (click)="form.controls.dataset_column_enabled.patchValue(true)"-->
              <!--                >-->
              <!--                  <div class="sidebar-icon-button__icon icon-cloud_download"></div>-->
              <!--                  <div class="sidebar-icon-button__label">Datasets by field</div>-->
              <!--                </div>-->
              <!--              </div>-->

              <div cdkDropList [cdkDropListLockAxis]="'y'" class="sidebar-list" (cdkDropListDropped)="dragDrop($event)">
                <div
                  *ngFor="
                    let datasetControl of (form.controls.dataset_column.value | appIsSet)
                      ? form.controls.datasets.controls.slice(0, 1)
                      : form.controls.datasets.controls;
                    let i = index
                  "
                  cdkDrag
                  class="sidebar-list__item"
                >
                  <div
                    class="sidebar-list-item sidebar-list-item_complex sidebar-list-item_clickable component-data-button"
                    [class.sidebar-list-item_error]="datasetControl.invalid"
                    [appTip]="datasetControl.invalid ? 'Not configured' : undefined"
                    (click)="editDataset(datasetControl, i)"
                  >
                    <div *ngIf="form.controls.dataset_column.value | appIsSet" class="sidebar-list-item__header">
                      Breakdown by <strong>{{ form.controls.dataset_column.value }}</strong>
                    </div>

                    <div class="sidebar-list-item__row">
                      <div class="sidebar-list-item__drag"></div>
                      <div class="sidebar-list-item__left">
                        <!--                <img *ngIf="(form.resource$(itemForm.controls.resource) | async)?.icon" class="sidebar-list-item__icon" [src]="(form.resource$(itemForm.controls.resource) | async)?.icon">-->
                        <!--                <span *ngIf="!(form.resource$(itemForm.controls.resource) | async)?.icon" class="sidebar-list-item__icon icon-chart"></span>-->

                        <span
                          *ngIf="
                            !datasetsColors[i] ||
                            (form.controls.dataset_column.value | appIsSet) ||
                            !form.isSingleColorDataset()
                          "
                          class="sidebar-list-item__color sidebar-list-item__color_palette"
                        >
                        </span>
                        <span
                          *ngIf="
                            datasetsColors[i] &&
                            !(form.controls.dataset_column.value | appIsSet) &&
                            form.isSingleColorDataset()
                          "
                          class="sidebar-list-item__color"
                          [ngClass]="
                            !(datasetsColors[i] | appIsColorHex) ? 'background-color_' + datasetsColors[i] + '_1' : ''
                          "
                          [style.background-color]="(datasetsColors[i] | appIsColorHex) ? datasetsColors[i] : null"
                        >
                        </span>
                      </div>
                      <div class="sidebar-list-item__main">
                        <div class="sidebar-list-item__title">{{ getDatasetTitle(datasetControl, i) }}</div>
                        <div
                          *ngIf="datasetControl.controls.data_source.getResource$() | async as resource"
                          class="sidebar-list-item__description"
                        >
                          {{ resource.name }}
                          {{ datasetControl.controls.data_source.controls.query.value?.queryTypeStr }}
                        </div>
                      </div>
                      <div class="sidebar-list-item__right">
                        <a
                          *ngIf="!(form.controls.dataset_column.value | appIsSet)"
                          href="javascript:void(0)"
                          class="sidebar-list-item__action icon-duplicate_2"
                          [appTip]="'Duplicate'"
                          [appTipOptions]="{ side: 'top' }"
                          (click)="$event.stopPropagation(); form.controls.datasets.copy(datasetControl)"
                        ></a>
                        <a
                          href="javascript:void(0)"
                          [appTip]="'Delete'"
                          [appTipOptions]="{ side: 'top' }"
                          class="sidebar-list-item__action icon-bin"
                          (click)="$event.stopPropagation(); form.controls.datasets.remove(datasetControl)"
                        ></a>
                      </div>
                    </div>
                  </div>
                </div>

                <div *ngIf="!form.controls.datasets.controls.length" class="sidebar-list__item">
                  <div
                    class="sidebar-list-item sidebar-list-item_clickable sidebar-list-item_disabled component-data-button"
                    (click)="addDataset()"
                  >
                    <div class="sidebar-list-item__drag"></div>
                    <div class="sidebar-list-item__left">
                      <span class="sidebar-list-item__color sidebar-list-item__color_palette"></span>
                    </div>
                    <div class="sidebar-list-item__main">
                      <div class="sidebar-list-item__title">Dataset</div>
                    </div>
                  </div>
                </div>
                <div class="sidebar-list__buttons">
                  <a
                    href="javascript:void(0)"
                    class="button button_orange-transparent button_small sidebar-list__buttons-item"
                    [class.button_disabled]="
                      (form.controls.dataset_column.value | appIsSet) && form.controls.datasets.controls.length
                    "
                    (click)="addDataset()"
                  >
                    <span class="icon icon-plus button__icon button__icon_left"></span>
                    <span class="button__label">Add Dataset</span>
                  </a>

                  <span
                    *ngIf="(form.controls.dataset_column.value | appIsSet) && form.controls.datasets.controls.length"
                    class="sidebar-list__buttons-item"
                    style="display: inline-block; vertical-align: middle;"
                  >
                    <span
                      class="help-icon icon-help"
                      [appTip]="'Datasets are added automatically when using Breakdown by field'"
                      [appTipOptions]="{ side: 'top' }"
                    ></span>
                  </span>
                </div>
              </div>
            </app-sidebar-field>
          </div>
        </app-sidebar-section>

        <app-related-model-components
          *ngIf="modelDescription"
          [modelDescription]="modelDescription"
          [context]="context"
          [collapseContext]="collapseContext"
        ></app-related-model-components>
      </app-sidebar-tab>

      <app-sidebar-tab [label]="'Display'">
        <app-sidebar-section>
          <div class="sidebar__element">
            <app-customize-bar-input-edit
              [label]="'title'"
              [required]="true"
              [itemForm]="form.controls.title"
              [textStyleControl]="form.controls.element_styles.controls.title_style"
              [textStyleTip]="'Customize Title style'"
              [context]="context"
              [contextElement]="contextElement"
              [fill]="true"
              [analyticsSource]="widget.analyticsGenericName + '_name'"
            ></app-customize-bar-input-edit>
          </div>

          <div *ngIf="form.yFormatChartTypes.includes(form.controls.chart_type.value)" class="sidebar__element">
            <app-sidebar-field [label]="'format Y axis'">
              <app-auto-field
                [form]="form"
                [label]="false"
                [field]="
                  createField({
                    name: 'y_format',
                    field: 'CharField',
                    required: false,
                    placeholder: 'Format value, e.g. 0,0.0000',
                    params: { classes: ['input_fill'] }
                  })
                "
              ></app-auto-field>
              <ng-container description>
                Numbers formatting supported <a href="http://numeraljs.com/#format" target="_blank">Learn More</a>
              </ng-container>
            </app-sidebar-field>
          </div>

          <div *ngIf="form.minMaxChartTypes.includes(form.controls.chart_type.value)" class="sidebar__element">
            <div class="sidebar-cols">
              <div class="sidebar-cols__item">
                <app-sidebar-field [label]="'minimum Y'">
                  <app-auto-field
                    [form]="form"
                    [label]="false"
                    [field]="
                      createField({
                        name: 'min',
                        field: 'NumberField',
                        required: false,
                        params: { classes: ['input_fill'] }
                      })
                    "
                  ></app-auto-field>
                </app-sidebar-field>
              </div>

              <div class="sidebar-cols__item">
                <app-sidebar-field [label]="'maximum Y'">
                  <app-auto-field
                    [form]="form"
                    [label]="false"
                    [field]="
                      createField({
                        name: 'max',
                        field: 'NumberField',
                        required: false,
                        params: { classes: ['input_fill'] }
                      })
                    "
                  ></app-auto-field>
                </app-sidebar-field>
              </div>
            </div>
          </div>

          <div class="sidebar__element">
            <app-sidebar-field [label]="'show grouping by date selector'" [layoutLeft]="true">
              <app-auto-field
                *ngIf="xDateLookup"
                [form]="form"
                [label]="false"
                [field]="
                  createField({
                    name: 'custom_query_x_column',
                    field: 'BooleanField',
                    params: { style: booleanFieldStyle.Toggle, orange: true }
                  })
                "
              ></app-auto-field>
              <app-toggle
                *ngIf="!xDateLookup"
                [selected]="false"
                [orange]="true"
                [disabled]="true"
                [appTip]="'All datasets should have grouping by Date'"
              ></app-toggle>
            </app-sidebar-field>
          </div>

          <div class="sidebar__element">
            <app-sidebar-field [label]="'show date range selector'" [layoutLeft]="true">
              <app-auto-field
                *ngIf="xDateLookup"
                [form]="form"
                [label]="false"
                [field]="
                  createField({
                    name: 'custom_date_range',
                    field: 'BooleanField',
                    params: { style: booleanFieldStyle.Toggle, orange: true }
                  })
                "
              ></app-auto-field>
              <app-toggle
                *ngIf="!xDateLookup"
                [selected]="false"
                [orange]="true"
                [disabled]="true"
                [appTip]="'All datasets should have grouping by Date'"
              ></app-toggle>
            </app-sidebar-field>
          </div>

          <div class="sidebar__element">
            <app-sidebar-field [label]="'show reload button'" [layoutLeft]="true">
              <app-auto-field
                [form]="form"
                [label]="false"
                [field]="
                  createField({
                    name: 'show_reload',
                    field: 'BooleanField',
                    params: { style: booleanFieldStyle.Toggle, orange: true }
                  })
                "
              ></app-auto-field>
            </app-sidebar-field>
          </div>

          <div class="sidebar__element">
            <app-sidebar-field [label]="'show legend'" [layoutLeft]="true">
              <app-auto-field
                [form]="form"
                [label]="false"
                [field]="
                  createField({
                    name: 'legend',
                    field: 'BooleanField',
                    params: { style: booleanFieldStyle.Toggle, orange: true }
                  })
                "
              ></app-auto-field>
            </app-sidebar-field>
          </div>

          <div *ngIf="visibleEditable" class="sidebar__element">
            <app-sidebar-field
              [label]="'Conditional Visibility'"
              [documentation]="'conditional-visibility'"
              [documentationLabel]="'See how it works'"
              [documentationSource]="widget.analyticsGenericName + '_visible'"
            >
              <app-input-edit
                [itemForm]="form.controls.visible_input"
                [context]="context"
                [staticValueDisabled]="true"
                [placeholder]="'Value'"
                [fill]="true"
                [analyticsSource]="widget.analyticsGenericName + '_visible'"
              ></app-input-edit>

              <ng-container description>
                Example: elements.field.value >= 10
              </ng-container>
            </app-sidebar-field>
          </div>

          <div class="sidebar__element">
            <app-sidebar-field [label]="'tooltip'">
              <app-auto-field
                [form]="form"
                [label]="false"
                [field]="
                  createField({
                    name: 'tooltip',
                    field: 'CharField',
                    placeholder: 'Show information for users',
                    reset_enabled: true,
                    params: { classes: ['input_fill'] }
                  })
                "
              ></app-auto-field>
            </app-sidebar-field>
          </div>

          <div *ngIf="elementStylesEditable" class="sidebar__element">
            <app-styles-chart-widget [control]="form.controls.element_styles"></app-styles-chart-widget>
          </div>
        </app-sidebar-section>
      </app-sidebar-tab>

      <app-sidebar-tab [label]="'Actions'" [error]="(actionsValid$ | async) !== false ? undefined : 'Not configured'">
        <app-sidebar-section>
          <div class="sidebar__list">
            <div class="sidebar-list__separator">
              <app-sidebar-list-separator [title]="'When chart clicked'"></app-sidebar-list-separator>
            </div>
            <div class="sidebar-list">
              <div class="sidebar-list__item">
                <app-action-single-edit
                  [options]="{ originEnabled: true }"
                  [title]="'Chart click'"
                  [control]="form.controls.click_action"
                  [context]="context"
                  [contextElement]="contextElement"
                  [contextElementPaths]="itemContextElementPaths"
                  [defaultSubtitle]="'Not specified'"
                  [remove]="form.controls.click_action.value"
                  [icon]="'cursor'"
                  [object]="element.analyticsGenericName"
                  [firstInit]="firstInit"
                >
                </app-action-single-edit>
              </div>
            </div>
          </div>
        </app-sidebar-section>
      </app-sidebar-tab>
    </app-sidebar-tabs>
  </div>
</form>
