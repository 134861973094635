import { Option } from '@modules/field-components';

export interface Font {
  name: string;
  googleFontsName?: string;
  default?: boolean;
  weights?: number[];
  cyrillic?: boolean;
  fallback?: string;
}

export const defaultFontName = 'Fira Sans';
export const regularFontName = '--regular-font';
export const headingFontName = '--heading-font';

export const globalFontOptions: Option<string>[] = [
  {
    name: 'Regular font',
    value: regularFontName
  },
  {
    name: 'Heading font',
    value: headingFontName
  }
];

export const fonts: Font[] = [
  {
    name: 'ABeeZee',
    googleFontsName: 'ABeeZee',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Abel',
    googleFontsName: 'Abel',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Abhaya Libre',
    googleFontsName: 'Abhaya Libre',
    weights: [400, 500, 600, 700, 800],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Abril Fatface',
    googleFontsName: 'Abril Fatface',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Aclonica',
    googleFontsName: 'Aclonica',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Acme',
    googleFontsName: 'Acme',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Actor',
    googleFontsName: 'Actor',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Adamina',
    googleFontsName: 'Adamina',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Advent Pro',
    googleFontsName: 'Advent Pro',
    weights: [100, 200, 300, 400, 500, 600, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Aguafina Script',
    googleFontsName: 'Aguafina Script',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Akaya Kanadaka',
    googleFontsName: 'Akaya Kanadaka',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Akaya Telivigala',
    googleFontsName: 'Akaya Telivigala',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Akronim',
    googleFontsName: 'Akronim',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Aladin',
    googleFontsName: 'Aladin',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Alata',
    googleFontsName: 'Alata',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Alatsi',
    googleFontsName: 'Alatsi',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Aldrich',
    googleFontsName: 'Aldrich',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Alef',
    googleFontsName: 'Alef',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Alegreya',
    googleFontsName: 'Alegreya',
    weights: [400, 500, 600, 700, 800, 900],
    cyrillic: true,
    fallback: 'serif'
  },
  {
    name: 'Alegreya SC',
    googleFontsName: 'Alegreya SC',
    weights: [400, 500, 700, 800, 900],
    cyrillic: true,
    fallback: 'serif'
  },
  {
    name: 'Alegreya Sans',
    googleFontsName: 'Alegreya Sans',
    weights: [100, 300, 400, 500, 700, 800, 900],
    cyrillic: true,
    fallback: 'sans-serif'
  },
  {
    name: 'Alegreya Sans SC',
    googleFontsName: 'Alegreya Sans SC',
    weights: [100, 300, 400, 500, 700, 800, 900],
    cyrillic: true,
    fallback: 'sans-serif'
  },
  {
    name: 'Aleo',
    googleFontsName: 'Aleo',
    weights: [300, 400, 700],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Alex Brush',
    googleFontsName: 'Alex Brush',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Alfa Slab One',
    googleFontsName: 'Alfa Slab One',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Alice',
    googleFontsName: 'Alice',
    weights: [400],
    cyrillic: true,
    fallback: 'serif'
  },
  {
    name: 'Alike',
    googleFontsName: 'Alike',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Alike Angular',
    googleFontsName: 'Alike Angular',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Allan',
    googleFontsName: 'Allan',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Allerta',
    googleFontsName: 'Allerta',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Allerta Stencil',
    googleFontsName: 'Allerta Stencil',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Allison',
    googleFontsName: 'Allison',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Allura',
    googleFontsName: 'Allura',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Almarai',
    googleFontsName: 'Almarai',
    weights: [300, 400, 700, 800],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Almendra',
    googleFontsName: 'Almendra',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Almendra Display',
    googleFontsName: 'Almendra Display',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Almendra SC',
    googleFontsName: 'Almendra SC',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Alumni Sans',
    googleFontsName: 'Alumni Sans',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: true,
    fallback: 'sans-serif'
  },
  {
    name: 'Amarante',
    googleFontsName: 'Amarante',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Amaranth',
    googleFontsName: 'Amaranth',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Amatic SC',
    googleFontsName: 'Amatic SC',
    weights: [400, 700],
    cyrillic: true,
    fallback: 'handwriting'
  },
  {
    name: 'Amethysta',
    googleFontsName: 'Amethysta',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Amiko',
    googleFontsName: 'Amiko',
    weights: [400, 600, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Amiri',
    googleFontsName: 'Amiri',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Amita',
    googleFontsName: 'Amita',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Anaheim',
    googleFontsName: 'Anaheim',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Andada Pro',
    googleFontsName: 'Andada Pro',
    weights: [400, 500, 600, 700, 800],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Andika',
    googleFontsName: 'Andika',
    weights: [400],
    cyrillic: true,
    fallback: 'sans-serif'
  },
  {
    name: 'Andika New Basic',
    googleFontsName: 'Andika New Basic',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Angkor',
    googleFontsName: 'Angkor',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Annie Use Your Telescope',
    googleFontsName: 'Annie Use Your Telescope',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Anonymous Pro',
    googleFontsName: 'Anonymous Pro',
    weights: [400, 700],
    cyrillic: true,
    fallback: 'monospace'
  },
  {
    name: 'Antic',
    googleFontsName: 'Antic',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Antic Didone',
    googleFontsName: 'Antic Didone',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Antic Slab',
    googleFontsName: 'Antic Slab',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Anton',
    googleFontsName: 'Anton',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Antonio',
    googleFontsName: 'Antonio',
    weights: [100, 200, 300, 400, 500, 600, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Arapey',
    googleFontsName: 'Arapey',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Arbutus',
    googleFontsName: 'Arbutus',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Arbutus Slab',
    googleFontsName: 'Arbutus Slab',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Architects Daughter',
    googleFontsName: 'Architects Daughter',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Archivo',
    googleFontsName: 'Archivo',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Archivo Black',
    googleFontsName: 'Archivo Black',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Archivo Narrow',
    googleFontsName: 'Archivo Narrow',
    weights: [400, 500, 600, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Are You Serious',
    googleFontsName: 'Are You Serious',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Aref Ruqaa',
    googleFontsName: 'Aref Ruqaa',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Arima Madurai',
    googleFontsName: 'Arima Madurai',
    weights: [100, 200, 300, 400, 500, 700, 800, 900],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Arimo',
    googleFontsName: 'Arimo',
    weights: [400, 500, 600, 700],
    cyrillic: true,
    fallback: 'sans-serif'
  },
  {
    name: 'Arizonia',
    googleFontsName: 'Arizonia',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Armata',
    googleFontsName: 'Armata',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Arsenal',
    googleFontsName: 'Arsenal',
    weights: [400, 700],
    cyrillic: true,
    fallback: 'sans-serif'
  },
  {
    name: 'Artifika',
    googleFontsName: 'Artifika',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Arvo',
    googleFontsName: 'Arvo',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Arya',
    googleFontsName: 'Arya',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Asap',
    googleFontsName: 'Asap',
    weights: [400, 500, 600, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Asap Condensed',
    googleFontsName: 'Asap Condensed',
    weights: [400, 500, 600, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Asar',
    googleFontsName: 'Asar',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Asset',
    googleFontsName: 'Asset',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Assistant',
    googleFontsName: 'Assistant',
    weights: [200, 300, 400, 500, 600, 700, 800],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Astloch',
    googleFontsName: 'Astloch',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Asul',
    googleFontsName: 'Asul',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Athiti',
    googleFontsName: 'Athiti',
    weights: [200, 300, 400, 500, 600, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Atkinson Hyperlegible',
    googleFontsName: 'Atkinson Hyperlegible',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Atma',
    googleFontsName: 'Atma',
    weights: [300, 400, 500, 600, 700],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Atomic Age',
    googleFontsName: 'Atomic Age',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Aubrey',
    googleFontsName: 'Aubrey',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Audiowide',
    googleFontsName: 'Audiowide',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Autour One',
    googleFontsName: 'Autour One',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Average',
    googleFontsName: 'Average',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Average Sans',
    googleFontsName: 'Average Sans',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Averia Gruesa Libre',
    googleFontsName: 'Averia Gruesa Libre',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Averia Libre',
    googleFontsName: 'Averia Libre',
    weights: [300, 400, 700],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Averia Sans Libre',
    googleFontsName: 'Averia Sans Libre',
    weights: [300, 400, 700],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Averia Serif Libre',
    googleFontsName: 'Averia Serif Libre',
    weights: [300, 400, 700],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Azeret Mono',
    googleFontsName: 'Azeret Mono',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'monospace'
  },
  {
    name: 'B612',
    googleFontsName: 'B612',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'B612 Mono',
    googleFontsName: 'B612 Mono',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'monospace'
  },
  {
    name: 'Bad Script',
    googleFontsName: 'Bad Script',
    weights: [400],
    cyrillic: true,
    fallback: 'handwriting'
  },
  {
    name: 'Bahiana',
    googleFontsName: 'Bahiana',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Bahianita',
    googleFontsName: 'Bahianita',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Bai Jamjuree',
    googleFontsName: 'Bai Jamjuree',
    weights: [200, 300, 400, 500, 600, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Ballet',
    googleFontsName: 'Ballet',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Baloo 2',
    googleFontsName: 'Baloo 2',
    weights: [400, 500, 600, 700, 800],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Baloo Bhai 2',
    googleFontsName: 'Baloo Bhai 2',
    weights: [400, 500, 600, 700, 800],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Baloo Bhaina 2',
    googleFontsName: 'Baloo Bhaina 2',
    weights: [400, 500, 600, 700, 800],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Baloo Chettan 2',
    googleFontsName: 'Baloo Chettan 2',
    weights: [400, 500, 600, 700, 800],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Baloo Da 2',
    googleFontsName: 'Baloo Da 2',
    weights: [400, 500, 600, 700, 800],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Baloo Paaji 2',
    googleFontsName: 'Baloo Paaji 2',
    weights: [400, 500, 600, 700, 800],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Baloo Tamma 2',
    googleFontsName: 'Baloo Tamma 2',
    weights: [400, 500, 600, 700, 800],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Baloo Tammudu 2',
    googleFontsName: 'Baloo Tammudu 2',
    weights: [400, 500, 600, 700, 800],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Baloo Thambi 2',
    googleFontsName: 'Baloo Thambi 2',
    weights: [400, 500, 600, 700, 800],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Balsamiq Sans',
    googleFontsName: 'Balsamiq Sans',
    weights: [400, 700],
    cyrillic: true,
    fallback: 'display'
  },
  {
    name: 'Balthazar',
    googleFontsName: 'Balthazar',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Bangers',
    googleFontsName: 'Bangers',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Barlow',
    googleFontsName: 'Barlow',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Barlow Condensed',
    googleFontsName: 'Barlow Condensed',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Barlow Semi Condensed',
    googleFontsName: 'Barlow Semi Condensed',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Barriecito',
    googleFontsName: 'Barriecito',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Barrio',
    googleFontsName: 'Barrio',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Basic',
    googleFontsName: 'Basic',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Baskervville',
    googleFontsName: 'Baskervville',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Battambang',
    googleFontsName: 'Battambang',
    weights: [100, 300, 400, 700, 900],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Baumans',
    googleFontsName: 'Baumans',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Bayon',
    googleFontsName: 'Bayon',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Be Vietnam',
    googleFontsName: 'Be Vietnam',
    weights: [100, 300, 400, 500, 600, 700, 800],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Be Vietnam Pro',
    googleFontsName: 'Be Vietnam Pro',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Bebas Neue',
    googleFontsName: 'Bebas Neue',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Belgrano',
    googleFontsName: 'Belgrano',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Bellefair',
    googleFontsName: 'Bellefair',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Belleza',
    googleFontsName: 'Belleza',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Bellota',
    googleFontsName: 'Bellota',
    weights: [300, 400, 700],
    cyrillic: true,
    fallback: 'display'
  },
  {
    name: 'Bellota Text',
    googleFontsName: 'Bellota Text',
    weights: [300, 400, 700],
    cyrillic: true,
    fallback: 'display'
  },
  {
    name: 'BenchNine',
    googleFontsName: 'BenchNine',
    weights: [300, 400, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Benne',
    googleFontsName: 'Benne',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Bentham',
    googleFontsName: 'Bentham',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Berkshire Swash',
    googleFontsName: 'Berkshire Swash',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Besley',
    googleFontsName: 'Besley',
    weights: [400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Beth Ellen',
    googleFontsName: 'Beth Ellen',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Bevan',
    googleFontsName: 'Bevan',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Big Shoulders Display',
    googleFontsName: 'Big Shoulders Display',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Big Shoulders Inline Display',
    googleFontsName: 'Big Shoulders Inline Display',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Big Shoulders Inline Text',
    googleFontsName: 'Big Shoulders Inline Text',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Big Shoulders Stencil Display',
    googleFontsName: 'Big Shoulders Stencil Display',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Big Shoulders Stencil Text',
    googleFontsName: 'Big Shoulders Stencil Text',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Big Shoulders Text',
    googleFontsName: 'Big Shoulders Text',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Bigelow Rules',
    googleFontsName: 'Bigelow Rules',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Bigshot One',
    googleFontsName: 'Bigshot One',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Bilbo',
    googleFontsName: 'Bilbo',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Bilbo Swash Caps',
    googleFontsName: 'Bilbo Swash Caps',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'BioRhyme',
    googleFontsName: 'BioRhyme',
    weights: [200, 300, 400, 700, 800],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'BioRhyme Expanded',
    googleFontsName: 'BioRhyme Expanded',
    weights: [200, 300, 400, 700, 800],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Birthstone',
    googleFontsName: 'Birthstone',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Birthstone Bounce',
    googleFontsName: 'Birthstone Bounce',
    weights: [400, 500],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Biryani',
    googleFontsName: 'Biryani',
    weights: [200, 300, 400, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Bitter',
    googleFontsName: 'Bitter',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: true,
    fallback: 'serif'
  },
  {
    name: 'Black And White Picture',
    googleFontsName: 'Black And White Picture',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Black Han Sans',
    googleFontsName: 'Black Han Sans',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Black Ops One',
    googleFontsName: 'Black Ops One',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Blinker',
    googleFontsName: 'Blinker',
    weights: [100, 200, 300, 400, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Bodoni Moda',
    googleFontsName: 'Bodoni Moda',
    weights: [400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Bokor',
    googleFontsName: 'Bokor',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Bona Nova',
    googleFontsName: 'Bona Nova',
    weights: [400, 700],
    cyrillic: true,
    fallback: 'serif'
  },
  {
    name: 'Bonbon',
    googleFontsName: 'Bonbon',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Bonheur Royale',
    googleFontsName: 'Bonheur Royale',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Boogaloo',
    googleFontsName: 'Boogaloo',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Bowlby One',
    googleFontsName: 'Bowlby One',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Bowlby One SC',
    googleFontsName: 'Bowlby One SC',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Brawler',
    googleFontsName: 'Brawler',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Bree Serif',
    googleFontsName: 'Bree Serif',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Brygada 1918',
    googleFontsName: 'Brygada 1918',
    weights: [400, 500, 600, 700],
    cyrillic: true,
    fallback: 'serif'
  },
  {
    name: 'Bubblegum Sans',
    googleFontsName: 'Bubblegum Sans',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Bubbler One',
    googleFontsName: 'Bubbler One',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Buda',
    googleFontsName: 'Buda',
    weights: [300],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Buenard',
    googleFontsName: 'Buenard',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Bungee',
    googleFontsName: 'Bungee',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Bungee Hairline',
    googleFontsName: 'Bungee Hairline',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Bungee Inline',
    googleFontsName: 'Bungee Inline',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Bungee Outline',
    googleFontsName: 'Bungee Outline',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Bungee Shade',
    googleFontsName: 'Bungee Shade',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Butcherman',
    googleFontsName: 'Butcherman',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Butterfly Kids',
    googleFontsName: 'Butterfly Kids',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Cabin',
    googleFontsName: 'Cabin',
    weights: [400, 500, 600, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Cabin Condensed',
    googleFontsName: 'Cabin Condensed',
    weights: [400, 500, 600, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Cabin Sketch',
    googleFontsName: 'Cabin Sketch',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Caesar Dressing',
    googleFontsName: 'Caesar Dressing',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Cagliostro',
    googleFontsName: 'Cagliostro',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Cairo',
    googleFontsName: 'Cairo',
    weights: [200, 300, 400, 600, 700, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Caladea',
    googleFontsName: 'Caladea',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Calistoga',
    googleFontsName: 'Calistoga',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Calligraffitti',
    googleFontsName: 'Calligraffitti',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Cambay',
    googleFontsName: 'Cambay',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Cambo',
    googleFontsName: 'Cambo',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Candal',
    googleFontsName: 'Candal',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Cantarell',
    googleFontsName: 'Cantarell',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Cantata One',
    googleFontsName: 'Cantata One',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Cantora One',
    googleFontsName: 'Cantora One',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Capriola',
    googleFontsName: 'Capriola',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Caramel',
    googleFontsName: 'Caramel',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Carattere',
    googleFontsName: 'Carattere',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Cardo',
    googleFontsName: 'Cardo',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Carme',
    googleFontsName: 'Carme',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Carrois Gothic',
    googleFontsName: 'Carrois Gothic',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Carrois Gothic SC',
    googleFontsName: 'Carrois Gothic SC',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Carter One',
    googleFontsName: 'Carter One',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Castoro',
    googleFontsName: 'Castoro',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Catamaran',
    googleFontsName: 'Catamaran',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Caudex',
    googleFontsName: 'Caudex',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Caveat',
    googleFontsName: 'Caveat',
    weights: [400, 500, 600, 700],
    cyrillic: true,
    fallback: 'handwriting'
  },
  {
    name: 'Caveat Brush',
    googleFontsName: 'Caveat Brush',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Cedarville Cursive',
    googleFontsName: 'Cedarville Cursive',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Ceviche One',
    googleFontsName: 'Ceviche One',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Chakra Petch',
    googleFontsName: 'Chakra Petch',
    weights: [300, 400, 500, 600, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Changa',
    googleFontsName: 'Changa',
    weights: [200, 300, 400, 500, 600, 700, 800],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Changa One',
    googleFontsName: 'Changa One',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Chango',
    googleFontsName: 'Chango',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Charm',
    googleFontsName: 'Charm',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Charmonman',
    googleFontsName: 'Charmonman',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Chathura',
    googleFontsName: 'Chathura',
    weights: [100, 300, 400, 700, 800],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Chau Philomene One',
    googleFontsName: 'Chau Philomene One',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Chela One',
    googleFontsName: 'Chela One',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Chelsea Market',
    googleFontsName: 'Chelsea Market',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Chenla',
    googleFontsName: 'Chenla',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Cherish',
    googleFontsName: 'Cherish',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Cherry Cream Soda',
    googleFontsName: 'Cherry Cream Soda',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Cherry Swash',
    googleFontsName: 'Cherry Swash',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Chewy',
    googleFontsName: 'Chewy',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Chicle',
    googleFontsName: 'Chicle',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Chilanka',
    googleFontsName: 'Chilanka',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Chivo',
    googleFontsName: 'Chivo',
    weights: [300, 400, 700, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Chonburi',
    googleFontsName: 'Chonburi',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Cinzel',
    googleFontsName: 'Cinzel',
    weights: [400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Cinzel Decorative',
    googleFontsName: 'Cinzel Decorative',
    weights: [400, 700, 900],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Clicker Script',
    googleFontsName: 'Clicker Script',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Coda',
    googleFontsName: 'Coda',
    weights: [400, 800],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Coda Caption',
    googleFontsName: 'Coda Caption',
    weights: [800],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Codystar',
    googleFontsName: 'Codystar',
    weights: [300, 400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Coiny',
    googleFontsName: 'Coiny',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Combo',
    googleFontsName: 'Combo',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Comfortaa',
    googleFontsName: 'Comfortaa',
    weights: [300, 400, 500, 600, 700],
    cyrillic: true,
    fallback: 'display'
  },
  {
    name: 'Comic Neue',
    googleFontsName: 'Comic Neue',
    weights: [300, 400, 700],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Coming Soon',
    googleFontsName: 'Coming Soon',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Commissioner',
    googleFontsName: 'Commissioner',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: true,
    fallback: 'sans-serif'
  },
  {
    name: 'Concert One',
    googleFontsName: 'Concert One',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Condiment',
    googleFontsName: 'Condiment',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Content',
    googleFontsName: 'Content',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Contrail One',
    googleFontsName: 'Contrail One',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Convergence',
    googleFontsName: 'Convergence',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Cookie',
    googleFontsName: 'Cookie',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Copse',
    googleFontsName: 'Copse',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Corben',
    googleFontsName: 'Corben',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Cormorant',
    googleFontsName: 'Cormorant',
    weights: [300, 400, 500, 600, 700],
    cyrillic: true,
    fallback: 'serif'
  },
  {
    name: 'Cormorant Garamond',
    googleFontsName: 'Cormorant Garamond',
    weights: [300, 400, 500, 600, 700],
    cyrillic: true,
    fallback: 'serif'
  },
  {
    name: 'Cormorant Infant',
    googleFontsName: 'Cormorant Infant',
    weights: [300, 400, 500, 600, 700],
    cyrillic: true,
    fallback: 'serif'
  },
  {
    name: 'Cormorant SC',
    googleFontsName: 'Cormorant SC',
    weights: [300, 400, 500, 600, 700],
    cyrillic: true,
    fallback: 'serif'
  },
  {
    name: 'Cormorant Unicase',
    googleFontsName: 'Cormorant Unicase',
    weights: [300, 400, 500, 600, 700],
    cyrillic: true,
    fallback: 'serif'
  },
  {
    name: 'Cormorant Upright',
    googleFontsName: 'Cormorant Upright',
    weights: [300, 400, 500, 600, 700],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Courgette',
    googleFontsName: 'Courgette',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Courier Prime',
    googleFontsName: 'Courier Prime',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'monospace'
  },
  {
    name: 'Cousine',
    googleFontsName: 'Cousine',
    weights: [400, 700],
    cyrillic: true,
    fallback: 'monospace'
  },
  {
    name: 'Coustard',
    googleFontsName: 'Coustard',
    weights: [400, 900],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Covered By Your Grace',
    googleFontsName: 'Covered By Your Grace',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Crafty Girls',
    googleFontsName: 'Crafty Girls',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Creepster',
    googleFontsName: 'Creepster',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Crete Round',
    googleFontsName: 'Crete Round',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Crimson Pro',
    googleFontsName: 'Crimson Pro',
    weights: [200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Crimson Text',
    googleFontsName: 'Crimson Text',
    weights: [400, 600, 700],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Croissant One',
    googleFontsName: 'Croissant One',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Crushed',
    googleFontsName: 'Crushed',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Cuprum',
    googleFontsName: 'Cuprum',
    weights: [400, 500, 600, 700],
    cyrillic: true,
    fallback: 'sans-serif'
  },
  {
    name: 'Cute Font',
    googleFontsName: 'Cute Font',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Cutive',
    googleFontsName: 'Cutive',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Cutive Mono',
    googleFontsName: 'Cutive Mono',
    weights: [400],
    cyrillic: false,
    fallback: 'monospace'
  },
  {
    name: 'DM Mono',
    googleFontsName: 'DM Mono',
    weights: [300, 400, 500],
    cyrillic: false,
    fallback: 'monospace'
  },
  {
    name: 'DM Sans',
    googleFontsName: 'DM Sans',
    weights: [400, 500, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'DM Serif Display',
    googleFontsName: 'DM Serif Display',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'DM Serif Text',
    googleFontsName: 'DM Serif Text',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Damion',
    googleFontsName: 'Damion',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Dancing Script',
    googleFontsName: 'Dancing Script',
    weights: [400, 500, 600, 700],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Dangrek',
    googleFontsName: 'Dangrek',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Darker Grotesque',
    googleFontsName: 'Darker Grotesque',
    weights: [300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'David Libre',
    googleFontsName: 'David Libre',
    weights: [400, 500, 700],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Dawning of a New Day',
    googleFontsName: 'Dawning of a New Day',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Days One',
    googleFontsName: 'Days One',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Dekko',
    googleFontsName: 'Dekko',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Dela Gothic One',
    googleFontsName: 'Dela Gothic One',
    weights: [400],
    cyrillic: true,
    fallback: 'display'
  },
  {
    name: 'Delius',
    googleFontsName: 'Delius',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Delius Swash Caps',
    googleFontsName: 'Delius Swash Caps',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Delius Unicase',
    googleFontsName: 'Delius Unicase',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Della Respira',
    googleFontsName: 'Della Respira',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Denk One',
    googleFontsName: 'Denk One',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Devonshire',
    googleFontsName: 'Devonshire',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Dhurjati',
    googleFontsName: 'Dhurjati',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Didact Gothic',
    googleFontsName: 'Didact Gothic',
    weights: [400],
    cyrillic: true,
    fallback: 'sans-serif'
  },
  {
    name: 'Diplomata',
    googleFontsName: 'Diplomata',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Diplomata SC',
    googleFontsName: 'Diplomata SC',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Do Hyeon',
    googleFontsName: 'Do Hyeon',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Dokdo',
    googleFontsName: 'Dokdo',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Domine',
    googleFontsName: 'Domine',
    weights: [400, 500, 600, 700],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Donegal One',
    googleFontsName: 'Donegal One',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Doppio One',
    googleFontsName: 'Doppio One',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Dorsa',
    googleFontsName: 'Dorsa',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Dosis',
    googleFontsName: 'Dosis',
    weights: [200, 300, 400, 500, 600, 700, 800],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'DotGothic16',
    googleFontsName: 'DotGothic16',
    weights: [400],
    cyrillic: true,
    fallback: 'sans-serif'
  },
  {
    name: 'Dr Sugiyama',
    googleFontsName: 'Dr Sugiyama',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Duru Sans',
    googleFontsName: 'Duru Sans',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Dynalight',
    googleFontsName: 'Dynalight',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'EB Garamond',
    googleFontsName: 'EB Garamond',
    weights: [400, 500, 600, 700, 800],
    cyrillic: true,
    fallback: 'serif'
  },
  {
    name: 'Eagle Lake',
    googleFontsName: 'Eagle Lake',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'East Sea Dokdo',
    googleFontsName: 'East Sea Dokdo',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Eater',
    googleFontsName: 'Eater',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Economica',
    googleFontsName: 'Economica',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Eczar',
    googleFontsName: 'Eczar',
    weights: [400, 500, 600, 700, 800],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'El Messiri',
    googleFontsName: 'El Messiri',
    weights: [400, 500, 600, 700],
    cyrillic: true,
    fallback: 'sans-serif'
  },
  {
    name: 'Electrolize',
    googleFontsName: 'Electrolize',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Elsie',
    googleFontsName: 'Elsie',
    weights: [400, 900],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Elsie Swash Caps',
    googleFontsName: 'Elsie Swash Caps',
    weights: [400, 900],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Emblema One',
    googleFontsName: 'Emblema One',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Emilys Candy',
    googleFontsName: 'Emilys Candy',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Encode Sans',
    googleFontsName: 'Encode Sans',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Encode Sans Condensed',
    googleFontsName: 'Encode Sans Condensed',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Encode Sans Expanded',
    googleFontsName: 'Encode Sans Expanded',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Encode Sans SC',
    googleFontsName: 'Encode Sans SC',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Encode Sans Semi Condensed',
    googleFontsName: 'Encode Sans Semi Condensed',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Encode Sans Semi Expanded',
    googleFontsName: 'Encode Sans Semi Expanded',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Engagement',
    googleFontsName: 'Engagement',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Englebert',
    googleFontsName: 'Englebert',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Enriqueta',
    googleFontsName: 'Enriqueta',
    weights: [400, 500, 600, 700],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Ephesis',
    googleFontsName: 'Ephesis',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Epilogue',
    googleFontsName: 'Epilogue',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Erica One',
    googleFontsName: 'Erica One',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Esteban',
    googleFontsName: 'Esteban',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Euphoria Script',
    googleFontsName: 'Euphoria Script',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Ewert',
    googleFontsName: 'Ewert',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Exo',
    googleFontsName: 'Exo',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Exo 2',
    googleFontsName: 'Exo 2',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: true,
    fallback: 'sans-serif'
  },
  {
    name: 'Expletus Sans',
    googleFontsName: 'Expletus Sans',
    weights: [400, 500, 600, 700],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Explora',
    googleFontsName: 'Explora',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Fahkwang',
    googleFontsName: 'Fahkwang',
    weights: [200, 300, 400, 500, 600, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Fanwood Text',
    googleFontsName: 'Fanwood Text',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Farro',
    googleFontsName: 'Farro',
    weights: [300, 400, 500, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Farsan',
    googleFontsName: 'Farsan',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Fascinate',
    googleFontsName: 'Fascinate',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Fascinate Inline',
    googleFontsName: 'Fascinate Inline',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Faster One',
    googleFontsName: 'Faster One',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Fasthand',
    googleFontsName: 'Fasthand',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Fauna One',
    googleFontsName: 'Fauna One',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Faustina',
    googleFontsName: 'Faustina',
    weights: [400, 500, 600, 700],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Federant',
    googleFontsName: 'Federant',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Federo',
    googleFontsName: 'Federo',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Felipa',
    googleFontsName: 'Felipa',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Fenix',
    googleFontsName: 'Fenix',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Festive',
    googleFontsName: 'Festive',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Finger Paint',
    googleFontsName: 'Finger Paint',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Fira Code',
    googleFontsName: 'Fira Code',
    weights: [300, 400, 500, 600, 700],
    cyrillic: true,
    fallback: 'monospace'
  },
  {
    name: 'Fira Mono',
    googleFontsName: 'Fira Mono',
    weights: [400, 500, 700],
    cyrillic: true,
    fallback: 'monospace'
  },
  {
    name: 'Fira Sans',
    googleFontsName: 'Fira Sans',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: true,
    fallback: 'sans-serif'
  },
  {
    name: 'Fira Sans Condensed',
    googleFontsName: 'Fira Sans Condensed',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: true,
    fallback: 'sans-serif'
  },
  {
    name: 'Fira Sans Extra Condensed',
    googleFontsName: 'Fira Sans Extra Condensed',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: true,
    fallback: 'sans-serif'
  },
  {
    name: 'Fjalla One',
    googleFontsName: 'Fjalla One',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Fjord One',
    googleFontsName: 'Fjord One',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Flamenco',
    googleFontsName: 'Flamenco',
    weights: [300, 400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Flavors',
    googleFontsName: 'Flavors',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Fleur De Leah',
    googleFontsName: 'Fleur De Leah',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Fondamento',
    googleFontsName: 'Fondamento',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Fontdiner Swanky',
    googleFontsName: 'Fontdiner Swanky',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Forum',
    googleFontsName: 'Forum',
    weights: [400],
    cyrillic: true,
    fallback: 'display'
  },
  {
    name: 'Francois One',
    googleFontsName: 'Francois One',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Frank Ruhl Libre',
    googleFontsName: 'Frank Ruhl Libre',
    weights: [300, 400, 500, 700, 900],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Fraunces',
    googleFontsName: 'Fraunces',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Freckle Face',
    googleFontsName: 'Freckle Face',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Fredericka the Great',
    googleFontsName: 'Fredericka the Great',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Fredoka One',
    googleFontsName: 'Fredoka One',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Freehand',
    googleFontsName: 'Freehand',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Fresca',
    googleFontsName: 'Fresca',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Frijole',
    googleFontsName: 'Frijole',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Fruktur',
    googleFontsName: 'Fruktur',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Fugaz One',
    googleFontsName: 'Fugaz One',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Fuggles',
    googleFontsName: 'Fuggles',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'GFS Didot',
    googleFontsName: 'GFS Didot',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'GFS Neohellenic',
    googleFontsName: 'GFS Neohellenic',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Gabriela',
    googleFontsName: 'Gabriela',
    weights: [400],
    cyrillic: true,
    fallback: 'serif'
  },
  {
    name: 'Gaegu',
    googleFontsName: 'Gaegu',
    weights: [300, 400, 700],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Gafata',
    googleFontsName: 'Gafata',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Galada',
    googleFontsName: 'Galada',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Galdeano',
    googleFontsName: 'Galdeano',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Galindo',
    googleFontsName: 'Galindo',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Gamja Flower',
    googleFontsName: 'Gamja Flower',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Gayathri',
    googleFontsName: 'Gayathri',
    weights: [100, 400, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Gelasio',
    googleFontsName: 'Gelasio',
    weights: [400, 500, 600, 700],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Gemunu Libre',
    googleFontsName: 'Gemunu Libre',
    weights: [200, 300, 400, 500, 600, 700, 800],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Gentium Basic',
    googleFontsName: 'Gentium Basic',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Gentium Book Basic',
    googleFontsName: 'Gentium Book Basic',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Geo',
    googleFontsName: 'Geo',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Georama',
    googleFontsName: 'Georama',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Geostar',
    googleFontsName: 'Geostar',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Geostar Fill',
    googleFontsName: 'Geostar Fill',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Germania One',
    googleFontsName: 'Germania One',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Gideon Roman',
    googleFontsName: 'Gideon Roman',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Gidugu',
    googleFontsName: 'Gidugu',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Gilda Display',
    googleFontsName: 'Gilda Display',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Girassol',
    googleFontsName: 'Girassol',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Give You Glory',
    googleFontsName: 'Give You Glory',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Glass Antiqua',
    googleFontsName: 'Glass Antiqua',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Glegoo',
    googleFontsName: 'Glegoo',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Gloria Hallelujah',
    googleFontsName: 'Gloria Hallelujah',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Glory',
    googleFontsName: 'Glory',
    weights: [100, 200, 300, 400, 500, 600, 700, 800],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Gluten',
    googleFontsName: 'Gluten',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Goblin One',
    googleFontsName: 'Goblin One',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Gochi Hand',
    googleFontsName: 'Gochi Hand',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Goldman',
    googleFontsName: 'Goldman',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Gorditas',
    googleFontsName: 'Gorditas',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Gothic A1',
    googleFontsName: 'Gothic A1',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Gotu',
    googleFontsName: 'Gotu',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Goudy Bookletter 1911',
    googleFontsName: 'Goudy Bookletter 1911',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Gowun Batang',
    googleFontsName: 'Gowun Batang',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Gowun Dodum',
    googleFontsName: 'Gowun Dodum',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Graduate',
    googleFontsName: 'Graduate',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Grand Hotel',
    googleFontsName: 'Grand Hotel',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Grandstander',
    googleFontsName: 'Grandstander',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Gravitas One',
    googleFontsName: 'Gravitas One',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Great Vibes',
    googleFontsName: 'Great Vibes',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Grechen Fuemen',
    googleFontsName: 'Grechen Fuemen',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Grenze',
    googleFontsName: 'Grenze',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Grenze Gotisch',
    googleFontsName: 'Grenze Gotisch',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Grey Qo',
    googleFontsName: 'Grey Qo',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Griffy',
    googleFontsName: 'Griffy',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Gruppo',
    googleFontsName: 'Gruppo',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Gudea',
    googleFontsName: 'Gudea',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Gugi',
    googleFontsName: 'Gugi',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Gupter',
    googleFontsName: 'Gupter',
    weights: [400, 500, 700],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Gurajada',
    googleFontsName: 'Gurajada',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Habibi',
    googleFontsName: 'Habibi',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Hachi Maru Pop',
    googleFontsName: 'Hachi Maru Pop',
    weights: [400],
    cyrillic: true,
    fallback: 'handwriting'
  },
  {
    name: 'Hahmlet',
    googleFontsName: 'Hahmlet',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Halant',
    googleFontsName: 'Halant',
    weights: [300, 400, 500, 600, 700],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Hammersmith One',
    googleFontsName: 'Hammersmith One',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Hanalei',
    googleFontsName: 'Hanalei',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Hanalei Fill',
    googleFontsName: 'Hanalei Fill',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Handlee',
    googleFontsName: 'Handlee',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Hanuman',
    googleFontsName: 'Hanuman',
    weights: [100, 300, 400, 700, 900],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Happy Monkey',
    googleFontsName: 'Happy Monkey',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Harmattan',
    googleFontsName: 'Harmattan',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Headland One',
    googleFontsName: 'Headland One',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Heebo',
    googleFontsName: 'Heebo',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Henny Penny',
    googleFontsName: 'Henny Penny',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Hepta Slab',
    googleFontsName: 'Hepta Slab',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Herr Von Muellerhoff',
    googleFontsName: 'Herr Von Muellerhoff',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Hi Melody',
    googleFontsName: 'Hi Melody',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Hina Mincho',
    googleFontsName: 'Hina Mincho',
    weights: [400],
    cyrillic: true,
    fallback: 'serif'
  },
  {
    name: 'Hind',
    googleFontsName: 'Hind',
    weights: [300, 400, 500, 600, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Hind Guntur',
    googleFontsName: 'Hind Guntur',
    weights: [300, 400, 500, 600, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Hind Madurai',
    googleFontsName: 'Hind Madurai',
    weights: [300, 400, 500, 600, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Hind Siliguri',
    googleFontsName: 'Hind Siliguri',
    weights: [300, 400, 500, 600, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Hind Vadodara',
    googleFontsName: 'Hind Vadodara',
    weights: [300, 400, 500, 600, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Holtwood One SC',
    googleFontsName: 'Holtwood One SC',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Homemade Apple',
    googleFontsName: 'Homemade Apple',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Homenaje',
    googleFontsName: 'Homenaje',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'IBM Plex Mono',
    googleFontsName: 'IBM Plex Mono',
    weights: [100, 200, 300, 400, 500, 600, 700],
    cyrillic: true,
    fallback: 'monospace'
  },
  {
    name: 'IBM Plex Sans',
    googleFontsName: 'IBM Plex Sans',
    weights: [100, 200, 300, 400, 500, 600, 700],
    cyrillic: true,
    fallback: 'sans-serif'
  },
  {
    name: 'IBM Plex Sans Arabic',
    googleFontsName: 'IBM Plex Sans Arabic',
    weights: [100, 200, 300, 400, 500, 600, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'IBM Plex Sans Condensed',
    googleFontsName: 'IBM Plex Sans Condensed',
    weights: [100, 200, 300, 400, 500, 600, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'IBM Plex Sans Devanagari',
    googleFontsName: 'IBM Plex Sans Devanagari',
    weights: [100, 200, 300, 400, 500, 600, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'IBM Plex Sans Hebrew',
    googleFontsName: 'IBM Plex Sans Hebrew',
    weights: [100, 200, 300, 400, 500, 600, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'IBM Plex Sans KR',
    googleFontsName: 'IBM Plex Sans KR',
    weights: [100, 200, 300, 400, 500, 600, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'IBM Plex Sans Thai',
    googleFontsName: 'IBM Plex Sans Thai',
    weights: [100, 200, 300, 400, 500, 600, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'IBM Plex Sans Thai Looped',
    googleFontsName: 'IBM Plex Sans Thai Looped',
    weights: [100, 200, 300, 400, 500, 600, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'IBM Plex Serif',
    googleFontsName: 'IBM Plex Serif',
    weights: [100, 200, 300, 400, 500, 600, 700],
    cyrillic: true,
    fallback: 'serif'
  },
  {
    name: 'IM Fell DW Pica',
    googleFontsName: 'IM Fell DW Pica',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'IM Fell DW Pica SC',
    googleFontsName: 'IM Fell DW Pica SC',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'IM Fell Double Pica',
    googleFontsName: 'IM Fell Double Pica',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'IM Fell Double Pica SC',
    googleFontsName: 'IM Fell Double Pica SC',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'IM Fell English',
    googleFontsName: 'IM Fell English',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'IM Fell English SC',
    googleFontsName: 'IM Fell English SC',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'IM Fell French Canon',
    googleFontsName: 'IM Fell French Canon',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'IM Fell French Canon SC',
    googleFontsName: 'IM Fell French Canon SC',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'IM Fell Great Primer',
    googleFontsName: 'IM Fell Great Primer',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'IM Fell Great Primer SC',
    googleFontsName: 'IM Fell Great Primer SC',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Ibarra Real Nova',
    googleFontsName: 'Ibarra Real Nova',
    weights: [400, 500, 600, 700],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Iceberg',
    googleFontsName: 'Iceberg',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Iceland',
    googleFontsName: 'Iceland',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Imbue',
    googleFontsName: 'Imbue',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Imprima',
    googleFontsName: 'Imprima',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Inconsolata',
    googleFontsName: 'Inconsolata',
    weights: [200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'monospace'
  },
  {
    name: 'Inder',
    googleFontsName: 'Inder',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Indie Flower',
    googleFontsName: 'Indie Flower',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Inika',
    googleFontsName: 'Inika',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Inknut Antiqua',
    googleFontsName: 'Inknut Antiqua',
    weights: [300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Inria Sans',
    googleFontsName: 'Inria Sans',
    weights: [300, 400, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Inria Serif',
    googleFontsName: 'Inria Serif',
    weights: [300, 400, 700],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Inter',
    googleFontsName: 'Inter',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: true,
    fallback: 'sans-serif'
  },
  {
    name: 'Irish Grover',
    googleFontsName: 'Irish Grover',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Istok Web',
    googleFontsName: 'Istok Web',
    weights: [400, 700],
    cyrillic: true,
    fallback: 'sans-serif'
  },
  {
    name: 'Italiana',
    googleFontsName: 'Italiana',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Italianno',
    googleFontsName: 'Italianno',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Itim',
    googleFontsName: 'Itim',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Jacques Francois',
    googleFontsName: 'Jacques Francois',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Jacques Francois Shadow',
    googleFontsName: 'Jacques Francois Shadow',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Jaldi',
    googleFontsName: 'Jaldi',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'JetBrains Mono',
    googleFontsName: 'JetBrains Mono',
    weights: [100, 200, 300, 400, 500, 600, 700, 800],
    cyrillic: true,
    fallback: 'monospace'
  },
  {
    name: 'Jim Nightshade',
    googleFontsName: 'Jim Nightshade',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Jockey One',
    googleFontsName: 'Jockey One',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Jolly Lodger',
    googleFontsName: 'Jolly Lodger',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Jomhuria',
    googleFontsName: 'Jomhuria',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Jomolhari',
    googleFontsName: 'Jomolhari',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Josefin Sans',
    googleFontsName: 'Josefin Sans',
    weights: [100, 200, 300, 400, 500, 600, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Josefin Slab',
    googleFontsName: 'Josefin Slab',
    weights: [100, 200, 300, 400, 500, 600, 700],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Jost',
    googleFontsName: 'Jost',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: true,
    fallback: 'sans-serif'
  },
  {
    name: 'Joti One',
    googleFontsName: 'Joti One',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Jua',
    googleFontsName: 'Jua',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Judson',
    googleFontsName: 'Judson',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Julee',
    googleFontsName: 'Julee',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Julius Sans One',
    googleFontsName: 'Julius Sans One',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Junge',
    googleFontsName: 'Junge',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Jura',
    googleFontsName: 'Jura',
    weights: [300, 400, 500, 600, 700],
    cyrillic: true,
    fallback: 'sans-serif'
  },
  {
    name: 'Just Another Hand',
    googleFontsName: 'Just Another Hand',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Just Me Again Down Here',
    googleFontsName: 'Just Me Again Down Here',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'K2D',
    googleFontsName: 'K2D',
    weights: [100, 200, 300, 400, 500, 600, 700, 800],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Kadwa',
    googleFontsName: 'Kadwa',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Kaisei Decol',
    googleFontsName: 'Kaisei Decol',
    weights: [400, 500, 700],
    cyrillic: true,
    fallback: 'serif'
  },
  {
    name: 'Kaisei HarunoUmi',
    googleFontsName: 'Kaisei HarunoUmi',
    weights: [400, 500, 700],
    cyrillic: true,
    fallback: 'serif'
  },
  {
    name: 'Kaisei Opti',
    googleFontsName: 'Kaisei Opti',
    weights: [400, 500, 700],
    cyrillic: true,
    fallback: 'serif'
  },
  {
    name: 'Kaisei Tokumin',
    googleFontsName: 'Kaisei Tokumin',
    weights: [400, 500, 700, 800],
    cyrillic: true,
    fallback: 'serif'
  },
  {
    name: 'Kalam',
    googleFontsName: 'Kalam',
    weights: [300, 400, 700],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Kameron',
    googleFontsName: 'Kameron',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Kanit',
    googleFontsName: 'Kanit',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Kantumruy',
    googleFontsName: 'Kantumruy',
    weights: [300, 400, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Karantina',
    googleFontsName: 'Karantina',
    weights: [300, 400, 700],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Karla',
    googleFontsName: 'Karla',
    weights: [200, 300, 400, 500, 600, 700, 800],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Karma',
    googleFontsName: 'Karma',
    weights: [300, 400, 500, 600, 700],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Katibeh',
    googleFontsName: 'Katibeh',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Kaushan Script',
    googleFontsName: 'Kaushan Script',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Kavivanar',
    googleFontsName: 'Kavivanar',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Kavoon',
    googleFontsName: 'Kavoon',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Kdam Thmor',
    googleFontsName: 'Kdam Thmor',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Keania One',
    googleFontsName: 'Keania One',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Kelly Slab',
    googleFontsName: 'Kelly Slab',
    weights: [400],
    cyrillic: true,
    fallback: 'display'
  },
  {
    name: 'Kenia',
    googleFontsName: 'Kenia',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Khand',
    googleFontsName: 'Khand',
    weights: [300, 400, 500, 600, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Khmer',
    googleFontsName: 'Khmer',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Khula',
    googleFontsName: 'Khula',
    weights: [300, 400, 600, 700, 800],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Kirang Haerang',
    googleFontsName: 'Kirang Haerang',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Kite One',
    googleFontsName: 'Kite One',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Kiwi Maru',
    googleFontsName: 'Kiwi Maru',
    weights: [300, 400, 500],
    cyrillic: true,
    fallback: 'serif'
  },
  {
    name: 'Klee One',
    googleFontsName: 'Klee One',
    weights: [400, 600],
    cyrillic: true,
    fallback: 'handwriting'
  },
  {
    name: 'Knewave',
    googleFontsName: 'Knewave',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'KoHo',
    googleFontsName: 'KoHo',
    weights: [200, 300, 400, 500, 600, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Kodchasan',
    googleFontsName: 'Kodchasan',
    weights: [200, 300, 400, 500, 600, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Koh Santepheap',
    googleFontsName: 'Koh Santepheap',
    weights: [100, 300, 400, 700, 900],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Kosugi',
    googleFontsName: 'Kosugi',
    weights: [400],
    cyrillic: true,
    fallback: 'sans-serif'
  },
  {
    name: 'Kosugi Maru',
    googleFontsName: 'Kosugi Maru',
    weights: [400],
    cyrillic: true,
    fallback: 'sans-serif'
  },
  {
    name: 'Kotta One',
    googleFontsName: 'Kotta One',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Koulen',
    googleFontsName: 'Koulen',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Kranky',
    googleFontsName: 'Kranky',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Kreon',
    googleFontsName: 'Kreon',
    weights: [300, 400, 500, 600, 700],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Kristi',
    googleFontsName: 'Kristi',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Krona One',
    googleFontsName: 'Krona One',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Krub',
    googleFontsName: 'Krub',
    weights: [200, 300, 400, 500, 600, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Kufam',
    googleFontsName: 'Kufam',
    weights: [400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Kulim Park',
    googleFontsName: 'Kulim Park',
    weights: [200, 300, 400, 600, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Kumar One',
    googleFontsName: 'Kumar One',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Kumar One Outline',
    googleFontsName: 'Kumar One Outline',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Kumbh Sans',
    googleFontsName: 'Kumbh Sans',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Kurale',
    googleFontsName: 'Kurale',
    weights: [400],
    cyrillic: true,
    fallback: 'serif'
  },
  {
    name: 'La Belle Aurore',
    googleFontsName: 'La Belle Aurore',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Lacquer',
    googleFontsName: 'Lacquer',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Laila',
    googleFontsName: 'Laila',
    weights: [300, 400, 500, 600, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Lakki Reddy',
    googleFontsName: 'Lakki Reddy',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Lalezar',
    googleFontsName: 'Lalezar',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Lancelot',
    googleFontsName: 'Lancelot',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Langar',
    googleFontsName: 'Langar',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Lateef',
    googleFontsName: 'Lateef',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Lato',
    googleFontsName: 'Lato',
    weights: [100, 300, 400, 700, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'League Script',
    googleFontsName: 'League Script',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Leckerli One',
    googleFontsName: 'Leckerli One',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Ledger',
    googleFontsName: 'Ledger',
    weights: [400],
    cyrillic: true,
    fallback: 'serif'
  },
  {
    name: 'Lekton',
    googleFontsName: 'Lekton',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Lemon',
    googleFontsName: 'Lemon',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Lemonada',
    googleFontsName: 'Lemonada',
    weights: [300, 400, 500, 600, 700],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Lexend',
    googleFontsName: 'Lexend',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Lexend Deca',
    googleFontsName: 'Lexend Deca',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Lexend Exa',
    googleFontsName: 'Lexend Exa',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Lexend Giga',
    googleFontsName: 'Lexend Giga',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Lexend Mega',
    googleFontsName: 'Lexend Mega',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Lexend Peta',
    googleFontsName: 'Lexend Peta',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Lexend Tera',
    googleFontsName: 'Lexend Tera',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Lexend Zetta',
    googleFontsName: 'Lexend Zetta',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Libre Barcode 128',
    googleFontsName: 'Libre Barcode 128',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Libre Barcode 128 Text',
    googleFontsName: 'Libre Barcode 128 Text',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Libre Barcode 39',
    googleFontsName: 'Libre Barcode 39',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Libre Barcode 39 Extended',
    googleFontsName: 'Libre Barcode 39 Extended',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Libre Barcode 39 Extended Text',
    googleFontsName: 'Libre Barcode 39 Extended Text',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Libre Barcode 39 Text',
    googleFontsName: 'Libre Barcode 39 Text',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Libre Barcode EAN13 Text',
    googleFontsName: 'Libre Barcode EAN13 Text',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Libre Baskerville',
    googleFontsName: 'Libre Baskerville',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Libre Caslon Display',
    googleFontsName: 'Libre Caslon Display',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Libre Caslon Text',
    googleFontsName: 'Libre Caslon Text',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Libre Franklin',
    googleFontsName: 'Libre Franklin',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Life Savers',
    googleFontsName: 'Life Savers',
    weights: [400, 700, 800],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Lilita One',
    googleFontsName: 'Lilita One',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Lily Script One',
    googleFontsName: 'Lily Script One',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Limelight',
    googleFontsName: 'Limelight',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Linden Hill',
    googleFontsName: 'Linden Hill',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Literata',
    googleFontsName: 'Literata',
    weights: [200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: true,
    fallback: 'serif'
  },
  {
    name: 'Liu Jian Mao Cao',
    googleFontsName: 'Liu Jian Mao Cao',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Livvic',
    googleFontsName: 'Livvic',
    weights: [100, 200, 300, 400, 500, 600, 700, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Lobster',
    googleFontsName: 'Lobster',
    weights: [400],
    cyrillic: true,
    fallback: 'display'
  },
  {
    name: 'Lobster Two',
    googleFontsName: 'Lobster Two',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Londrina Outline',
    googleFontsName: 'Londrina Outline',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Londrina Shadow',
    googleFontsName: 'Londrina Shadow',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Londrina Sketch',
    googleFontsName: 'Londrina Sketch',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Londrina Solid',
    googleFontsName: 'Londrina Solid',
    weights: [100, 300, 400, 900],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Long Cang',
    googleFontsName: 'Long Cang',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Lora',
    googleFontsName: 'Lora',
    weights: [400, 500, 600, 700],
    cyrillic: true,
    fallback: 'serif'
  },
  {
    name: 'Love Ya Like A Sister',
    googleFontsName: 'Love Ya Like A Sister',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Loved by the King',
    googleFontsName: 'Loved by the King',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Lovers Quarrel',
    googleFontsName: 'Lovers Quarrel',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Luckiest Guy',
    googleFontsName: 'Luckiest Guy',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Lusitana',
    googleFontsName: 'Lusitana',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Lustria',
    googleFontsName: 'Lustria',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'M PLUS 1p',
    googleFontsName: 'M PLUS 1p',
    weights: [100, 300, 400, 500, 700, 800, 900],
    cyrillic: true,
    fallback: 'sans-serif'
  },
  {
    name: 'M PLUS Rounded 1c',
    googleFontsName: 'M PLUS Rounded 1c',
    weights: [100, 300, 400, 500, 700, 800, 900],
    cyrillic: true,
    fallback: 'sans-serif'
  },
  {
    name: 'Ma Shan Zheng',
    googleFontsName: 'Ma Shan Zheng',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Macondo',
    googleFontsName: 'Macondo',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Macondo Swash Caps',
    googleFontsName: 'Macondo Swash Caps',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Mada',
    googleFontsName: 'Mada',
    weights: [200, 300, 400, 500, 600, 700, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Magra',
    googleFontsName: 'Magra',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Maiden Orange',
    googleFontsName: 'Maiden Orange',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Maitree',
    googleFontsName: 'Maitree',
    weights: [200, 300, 400, 500, 600, 700],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Major Mono Display',
    googleFontsName: 'Major Mono Display',
    weights: [400],
    cyrillic: false,
    fallback: 'monospace'
  },
  {
    name: 'Mako',
    googleFontsName: 'Mako',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Mali',
    googleFontsName: 'Mali',
    weights: [200, 300, 400, 500, 600, 700],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Mallanna',
    googleFontsName: 'Mallanna',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Mandali',
    googleFontsName: 'Mandali',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Manjari',
    googleFontsName: 'Manjari',
    weights: [100, 400, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Manrope',
    googleFontsName: 'Manrope',
    weights: [200, 300, 400, 500, 600, 700, 800],
    cyrillic: true,
    fallback: 'sans-serif'
  },
  {
    name: 'Mansalva',
    googleFontsName: 'Mansalva',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Manuale',
    googleFontsName: 'Manuale',
    weights: [300, 400, 500, 600, 700, 800],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Marcellus',
    googleFontsName: 'Marcellus',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Marcellus SC',
    googleFontsName: 'Marcellus SC',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Marck Script',
    googleFontsName: 'Marck Script',
    weights: [400],
    cyrillic: true,
    fallback: 'handwriting'
  },
  {
    name: 'Margarine',
    googleFontsName: 'Margarine',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Markazi Text',
    googleFontsName: 'Markazi Text',
    weights: [400, 500, 600, 700],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Marko One',
    googleFontsName: 'Marko One',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Marmelad',
    googleFontsName: 'Marmelad',
    weights: [400],
    cyrillic: true,
    fallback: 'sans-serif'
  },
  {
    name: 'Martel',
    googleFontsName: 'Martel',
    weights: [200, 300, 400, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Martel Sans',
    googleFontsName: 'Martel Sans',
    weights: [200, 300, 400, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Marvel',
    googleFontsName: 'Marvel',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Mate',
    googleFontsName: 'Mate',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Mate SC',
    googleFontsName: 'Mate SC',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Material Icons',
    googleFontsName: 'Material Icons',
    weights: [400],
    cyrillic: false,
    fallback: 'icon'
  },
  {
    name: 'Maven Pro',
    googleFontsName: 'Maven Pro',
    weights: [400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'McLaren',
    googleFontsName: 'McLaren',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Meddon',
    googleFontsName: 'Meddon',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'MedievalSharp',
    googleFontsName: 'MedievalSharp',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Medula One',
    googleFontsName: 'Medula One',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Meera Inimai',
    googleFontsName: 'Meera Inimai',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Megrim',
    googleFontsName: 'Megrim',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Meie Script',
    googleFontsName: 'Meie Script',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Merienda',
    googleFontsName: 'Merienda',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Merienda One',
    googleFontsName: 'Merienda One',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Merriweather',
    googleFontsName: 'Merriweather',
    weights: [300, 400, 700, 900],
    cyrillic: true,
    fallback: 'serif'
  },
  {
    name: 'Merriweather Sans',
    googleFontsName: 'Merriweather Sans',
    weights: [300, 400, 500, 600, 700, 800],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Metal',
    googleFontsName: 'Metal',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Metal Mania',
    googleFontsName: 'Metal Mania',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Metamorphous',
    googleFontsName: 'Metamorphous',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Metrophobic',
    googleFontsName: 'Metrophobic',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Michroma',
    googleFontsName: 'Michroma',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Milonga',
    googleFontsName: 'Milonga',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Miltonian',
    googleFontsName: 'Miltonian',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Miltonian Tattoo',
    googleFontsName: 'Miltonian Tattoo',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Mina',
    googleFontsName: 'Mina',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Miniver',
    googleFontsName: 'Miniver',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Miriam Libre',
    googleFontsName: 'Miriam Libre',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Mirza',
    googleFontsName: 'Mirza',
    weights: [400, 500, 600, 700],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Miss Fajardose',
    googleFontsName: 'Miss Fajardose',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Mitr',
    googleFontsName: 'Mitr',
    weights: [200, 300, 400, 500, 600, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Modak',
    googleFontsName: 'Modak',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Modern Antiqua',
    googleFontsName: 'Modern Antiqua',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Mogra',
    googleFontsName: 'Mogra',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Molengo',
    googleFontsName: 'Molengo',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Monda',
    googleFontsName: 'Monda',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Monofett',
    googleFontsName: 'Monofett',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Monoton',
    googleFontsName: 'Monoton',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Monsieur La Doulaise',
    googleFontsName: 'Monsieur La Doulaise',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Montaga',
    googleFontsName: 'Montaga',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'MonteCarlo',
    googleFontsName: 'MonteCarlo',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Montez',
    googleFontsName: 'Montez',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Montserrat',
    googleFontsName: 'Montserrat',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: true,
    fallback: 'sans-serif'
  },
  {
    name: 'Montserrat Alternates',
    googleFontsName: 'Montserrat Alternates',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: true,
    fallback: 'sans-serif'
  },
  {
    name: 'Montserrat Subrayada',
    googleFontsName: 'Montserrat Subrayada',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Moul',
    googleFontsName: 'Moul',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Moulpali',
    googleFontsName: 'Moulpali',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Mountains of Christmas',
    googleFontsName: 'Mountains of Christmas',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Mouse Memoirs',
    googleFontsName: 'Mouse Memoirs',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Mr Bedfort',
    googleFontsName: 'Mr Bedfort',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Mr Dafoe',
    googleFontsName: 'Mr Dafoe',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Mr De Haviland',
    googleFontsName: 'Mr De Haviland',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Mrs Saint Delafield',
    googleFontsName: 'Mrs Saint Delafield',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Mrs Sheppards',
    googleFontsName: 'Mrs Sheppards',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Mukta',
    googleFontsName: 'Mukta',
    weights: [200, 300, 400, 500, 600, 700, 800],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Mukta Mahee',
    googleFontsName: 'Mukta Mahee',
    weights: [200, 300, 400, 500, 600, 700, 800],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Mukta Malar',
    googleFontsName: 'Mukta Malar',
    weights: [200, 300, 400, 500, 600, 700, 800],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Mukta Vaani',
    googleFontsName: 'Mukta Vaani',
    weights: [200, 300, 400, 500, 600, 700, 800],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Mulish',
    googleFontsName: 'Mulish',
    weights: [200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: true,
    fallback: 'sans-serif'
  },
  {
    name: 'MuseoModerno',
    googleFontsName: 'MuseoModerno',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Mystery Quest',
    googleFontsName: 'Mystery Quest',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'NTR',
    googleFontsName: 'NTR',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Nanum Brush Script',
    googleFontsName: 'Nanum Brush Script',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Nanum Gothic',
    googleFontsName: 'Nanum Gothic',
    weights: [400, 700, 800],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Nanum Gothic Coding',
    googleFontsName: 'Nanum Gothic Coding',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'monospace'
  },
  {
    name: 'Nanum Myeongjo',
    googleFontsName: 'Nanum Myeongjo',
    weights: [400, 700, 800],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Nanum Pen Script',
    googleFontsName: 'Nanum Pen Script',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Nerko One',
    googleFontsName: 'Nerko One',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Neucha',
    googleFontsName: 'Neucha',
    weights: [400],
    cyrillic: true,
    fallback: 'handwriting'
  },
  {
    name: 'Neuton',
    googleFontsName: 'Neuton',
    weights: [200, 300, 400, 700, 800],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'New Rocker',
    googleFontsName: 'New Rocker',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'New Tegomin',
    googleFontsName: 'New Tegomin',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'News Cycle',
    googleFontsName: 'News Cycle',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Newsreader',
    googleFontsName: 'Newsreader',
    weights: [200, 300, 400, 500, 600, 700, 800],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Niconne',
    googleFontsName: 'Niconne',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Niramit',
    googleFontsName: 'Niramit',
    weights: [200, 300, 400, 500, 600, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Nixie One',
    googleFontsName: 'Nixie One',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Nobile',
    googleFontsName: 'Nobile',
    weights: [400, 500, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Nokora',
    googleFontsName: 'Nokora',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Norican',
    googleFontsName: 'Norican',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Nosifer',
    googleFontsName: 'Nosifer',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Notable',
    googleFontsName: 'Notable',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Nothing You Could Do',
    googleFontsName: 'Nothing You Could Do',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Noticia Text',
    googleFontsName: 'Noticia Text',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Noto Kufi Arabic',
    googleFontsName: 'Noto Kufi Arabic',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Music',
    googleFontsName: 'Noto Music',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Naskh Arabic',
    googleFontsName: 'Noto Naskh Arabic',
    weights: [400, 500, 600, 700],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Noto Nastaliq Urdu',
    googleFontsName: 'Noto Nastaliq Urdu',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Noto Rashi Hebrew',
    googleFontsName: 'Noto Rashi Hebrew',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Noto Sans',
    googleFontsName: 'Noto Sans',
    weights: [400, 700],
    cyrillic: true,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Adlam',
    googleFontsName: 'Noto Sans Adlam',
    weights: [400, 500, 600, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Adlam Unjoined',
    googleFontsName: 'Noto Sans Adlam Unjoined',
    weights: [400, 500, 600, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Anatolian Hieroglyphs',
    googleFontsName: 'Noto Sans Anatolian Hieroglyphs',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Arabic',
    googleFontsName: 'Noto Sans Arabic',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Armenian',
    googleFontsName: 'Noto Sans Armenian',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Avestan',
    googleFontsName: 'Noto Sans Avestan',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Balinese',
    googleFontsName: 'Noto Sans Balinese',
    weights: [400, 500, 600, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Bamum',
    googleFontsName: 'Noto Sans Bamum',
    weights: [400, 500, 600, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Bassa Vah',
    googleFontsName: 'Noto Sans Bassa Vah',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Batak',
    googleFontsName: 'Noto Sans Batak',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Bengali',
    googleFontsName: 'Noto Sans Bengali',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Bhaiksuki',
    googleFontsName: 'Noto Sans Bhaiksuki',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Brahmi',
    googleFontsName: 'Noto Sans Brahmi',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Buginese',
    googleFontsName: 'Noto Sans Buginese',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Buhid',
    googleFontsName: 'Noto Sans Buhid',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Canadian Aboriginal',
    googleFontsName: 'Noto Sans Canadian Aboriginal',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Carian',
    googleFontsName: 'Noto Sans Carian',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Caucasian Albanian',
    googleFontsName: 'Noto Sans Caucasian Albanian',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Chakma',
    googleFontsName: 'Noto Sans Chakma',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Cham',
    googleFontsName: 'Noto Sans Cham',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Cherokee',
    googleFontsName: 'Noto Sans Cherokee',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Coptic',
    googleFontsName: 'Noto Sans Coptic',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Cuneiform',
    googleFontsName: 'Noto Sans Cuneiform',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Cypriot',
    googleFontsName: 'Noto Sans Cypriot',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Deseret',
    googleFontsName: 'Noto Sans Deseret',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Devanagari',
    googleFontsName: 'Noto Sans Devanagari',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Display',
    googleFontsName: 'Noto Sans Display',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: true,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Duployan',
    googleFontsName: 'Noto Sans Duployan',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Egyptian Hieroglyphs',
    googleFontsName: 'Noto Sans Egyptian Hieroglyphs',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Elbasan',
    googleFontsName: 'Noto Sans Elbasan',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Elymaic',
    googleFontsName: 'Noto Sans Elymaic',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Georgian',
    googleFontsName: 'Noto Sans Georgian',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Glagolitic',
    googleFontsName: 'Noto Sans Glagolitic',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Gothic',
    googleFontsName: 'Noto Sans Gothic',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Grantha',
    googleFontsName: 'Noto Sans Grantha',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Gujarati',
    googleFontsName: 'Noto Sans Gujarati',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Gunjala Gondi',
    googleFontsName: 'Noto Sans Gunjala Gondi',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Gurmukhi',
    googleFontsName: 'Noto Sans Gurmukhi',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans HK',
    googleFontsName: 'Noto Sans HK',
    weights: [100, 300, 400, 500, 700, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Hanifi Rohingya',
    googleFontsName: 'Noto Sans Hanifi Rohingya',
    weights: [400, 500, 600, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Hanunoo',
    googleFontsName: 'Noto Sans Hanunoo',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Hatran',
    googleFontsName: 'Noto Sans Hatran',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Hebrew',
    googleFontsName: 'Noto Sans Hebrew',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Imperial Aramaic',
    googleFontsName: 'Noto Sans Imperial Aramaic',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Indic Siyaq Numbers',
    googleFontsName: 'Noto Sans Indic Siyaq Numbers',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Inscriptional Pahlavi',
    googleFontsName: 'Noto Sans Inscriptional Pahlavi',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Inscriptional Parthian',
    googleFontsName: 'Noto Sans Inscriptional Parthian',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans JP',
    googleFontsName: 'Noto Sans JP',
    weights: [100, 300, 400, 500, 700, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Javanese',
    googleFontsName: 'Noto Sans Javanese',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans KR',
    googleFontsName: 'Noto Sans KR',
    weights: [100, 300, 400, 500, 700, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Kaithi',
    googleFontsName: 'Noto Sans Kaithi',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Kannada',
    googleFontsName: 'Noto Sans Kannada',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Kayah Li',
    googleFontsName: 'Noto Sans Kayah Li',
    weights: [400, 500, 600, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Kharoshthi',
    googleFontsName: 'Noto Sans Kharoshthi',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Khmer',
    googleFontsName: 'Noto Sans Khmer',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Khojki',
    googleFontsName: 'Noto Sans Khojki',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Khudawadi',
    googleFontsName: 'Noto Sans Khudawadi',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Lao',
    googleFontsName: 'Noto Sans Lao',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Lepcha',
    googleFontsName: 'Noto Sans Lepcha',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Limbu',
    googleFontsName: 'Noto Sans Limbu',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Linear A',
    googleFontsName: 'Noto Sans Linear A',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Linear B',
    googleFontsName: 'Noto Sans Linear B',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Lisu',
    googleFontsName: 'Noto Sans Lisu',
    weights: [400, 500, 600, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Lycian',
    googleFontsName: 'Noto Sans Lycian',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Lydian',
    googleFontsName: 'Noto Sans Lydian',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Mahajani',
    googleFontsName: 'Noto Sans Mahajani',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Malayalam',
    googleFontsName: 'Noto Sans Malayalam',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Mandaic',
    googleFontsName: 'Noto Sans Mandaic',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Manichaean',
    googleFontsName: 'Noto Sans Manichaean',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Marchen',
    googleFontsName: 'Noto Sans Marchen',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Masaram Gondi',
    googleFontsName: 'Noto Sans Masaram Gondi',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Math',
    googleFontsName: 'Noto Sans Math',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Mayan Numerals',
    googleFontsName: 'Noto Sans Mayan Numerals',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Medefaidrin',
    googleFontsName: 'Noto Sans Medefaidrin',
    weights: [400, 500, 600, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Meroitic',
    googleFontsName: 'Noto Sans Meroitic',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Miao',
    googleFontsName: 'Noto Sans Miao',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Modi',
    googleFontsName: 'Noto Sans Modi',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Mongolian',
    googleFontsName: 'Noto Sans Mongolian',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Mono',
    googleFontsName: 'Noto Sans Mono',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: true,
    fallback: 'monospace'
  },
  {
    name: 'Noto Sans Mro',
    googleFontsName: 'Noto Sans Mro',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Multani',
    googleFontsName: 'Noto Sans Multani',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Myanmar',
    googleFontsName: 'Noto Sans Myanmar',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans N Ko',
    googleFontsName: 'Noto Sans N Ko',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Nabataean',
    googleFontsName: 'Noto Sans Nabataean',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans New Tai Lue',
    googleFontsName: 'Noto Sans New Tai Lue',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Newa',
    googleFontsName: 'Noto Sans Newa',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Nushu',
    googleFontsName: 'Noto Sans Nushu',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Ogham',
    googleFontsName: 'Noto Sans Ogham',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Ol Chiki',
    googleFontsName: 'Noto Sans Ol Chiki',
    weights: [400, 500, 600, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Old Hungarian',
    googleFontsName: 'Noto Sans Old Hungarian',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Old Italic',
    googleFontsName: 'Noto Sans Old Italic',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Old North Arabian',
    googleFontsName: 'Noto Sans Old North Arabian',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Old Permic',
    googleFontsName: 'Noto Sans Old Permic',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Old Persian',
    googleFontsName: 'Noto Sans Old Persian',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Old Sogdian',
    googleFontsName: 'Noto Sans Old Sogdian',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Old South Arabian',
    googleFontsName: 'Noto Sans Old South Arabian',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Old Turkic',
    googleFontsName: 'Noto Sans Old Turkic',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Oriya',
    googleFontsName: 'Noto Sans Oriya',
    weights: [100, 400, 700, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Osage',
    googleFontsName: 'Noto Sans Osage',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Osmanya',
    googleFontsName: 'Noto Sans Osmanya',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Pahawh Hmong',
    googleFontsName: 'Noto Sans Pahawh Hmong',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Palmyrene',
    googleFontsName: 'Noto Sans Palmyrene',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Pau Cin Hau',
    googleFontsName: 'Noto Sans Pau Cin Hau',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Phags Pa',
    googleFontsName: 'Noto Sans Phags Pa',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Phoenician',
    googleFontsName: 'Noto Sans Phoenician',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Psalter Pahlavi',
    googleFontsName: 'Noto Sans Psalter Pahlavi',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Rejang',
    googleFontsName: 'Noto Sans Rejang',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Runic',
    googleFontsName: 'Noto Sans Runic',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans SC',
    googleFontsName: 'Noto Sans SC',
    weights: [100, 300, 400, 500, 700, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Samaritan',
    googleFontsName: 'Noto Sans Samaritan',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Saurashtra',
    googleFontsName: 'Noto Sans Saurashtra',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Sharada',
    googleFontsName: 'Noto Sans Sharada',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Shavian',
    googleFontsName: 'Noto Sans Shavian',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Siddham',
    googleFontsName: 'Noto Sans Siddham',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Sinhala',
    googleFontsName: 'Noto Sans Sinhala',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Sogdian',
    googleFontsName: 'Noto Sans Sogdian',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Sora Sompeng',
    googleFontsName: 'Noto Sans Sora Sompeng',
    weights: [400, 500, 600, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Soyombo',
    googleFontsName: 'Noto Sans Soyombo',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Sundanese',
    googleFontsName: 'Noto Sans Sundanese',
    weights: [400, 500, 600, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Syloti Nagri',
    googleFontsName: 'Noto Sans Syloti Nagri',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Symbols',
    googleFontsName: 'Noto Sans Symbols',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Symbols 2',
    googleFontsName: 'Noto Sans Symbols 2',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Syriac',
    googleFontsName: 'Noto Sans Syriac',
    weights: [100, 400, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans TC',
    googleFontsName: 'Noto Sans TC',
    weights: [100, 300, 400, 500, 700, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Tagalog',
    googleFontsName: 'Noto Sans Tagalog',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Tagbanwa',
    googleFontsName: 'Noto Sans Tagbanwa',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Tai Le',
    googleFontsName: 'Noto Sans Tai Le',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Tai Tham',
    googleFontsName: 'Noto Sans Tai Tham',
    weights: [400, 500, 600, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Tai Viet',
    googleFontsName: 'Noto Sans Tai Viet',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Takri',
    googleFontsName: 'Noto Sans Takri',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Tamil',
    googleFontsName: 'Noto Sans Tamil',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Tamil Supplement',
    googleFontsName: 'Noto Sans Tamil Supplement',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Telugu',
    googleFontsName: 'Noto Sans Telugu',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Thaana',
    googleFontsName: 'Noto Sans Thaana',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Thai',
    googleFontsName: 'Noto Sans Thai',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Thai Looped',
    googleFontsName: 'Noto Sans Thai Looped',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Tifinagh',
    googleFontsName: 'Noto Sans Tifinagh',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Tirhuta',
    googleFontsName: 'Noto Sans Tirhuta',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Ugaritic',
    googleFontsName: 'Noto Sans Ugaritic',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Vai',
    googleFontsName: 'Noto Sans Vai',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Wancho',
    googleFontsName: 'Noto Sans Wancho',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Warang Citi',
    googleFontsName: 'Noto Sans Warang Citi',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Yi',
    googleFontsName: 'Noto Sans Yi',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Sans Zanabazar Square',
    googleFontsName: 'Noto Sans Zanabazar Square',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Noto Serif',
    googleFontsName: 'Noto Serif',
    weights: [400, 700],
    cyrillic: true,
    fallback: 'serif'
  },
  {
    name: 'Noto Serif Ahom',
    googleFontsName: 'Noto Serif Ahom',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Noto Serif Armenian',
    googleFontsName: 'Noto Serif Armenian',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Noto Serif Balinese',
    googleFontsName: 'Noto Serif Balinese',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Noto Serif Bengali',
    googleFontsName: 'Noto Serif Bengali',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Noto Serif Devanagari',
    googleFontsName: 'Noto Serif Devanagari',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Noto Serif Display',
    googleFontsName: 'Noto Serif Display',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: true,
    fallback: 'serif'
  },
  {
    name: 'Noto Serif Dogra',
    googleFontsName: 'Noto Serif Dogra',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Noto Serif Ethiopic',
    googleFontsName: 'Noto Serif Ethiopic',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Noto Serif Georgian',
    googleFontsName: 'Noto Serif Georgian',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Noto Serif Grantha',
    googleFontsName: 'Noto Serif Grantha',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Noto Serif Gujarati',
    googleFontsName: 'Noto Serif Gujarati',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Noto Serif Gurmukhi',
    googleFontsName: 'Noto Serif Gurmukhi',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Noto Serif Hebrew',
    googleFontsName: 'Noto Serif Hebrew',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Noto Serif JP',
    googleFontsName: 'Noto Serif JP',
    weights: [200, 300, 400, 500, 600, 700, 900],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Noto Serif KR',
    googleFontsName: 'Noto Serif KR',
    weights: [200, 300, 400, 500, 600, 700, 900],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Noto Serif Kannada',
    googleFontsName: 'Noto Serif Kannada',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Noto Serif Khmer',
    googleFontsName: 'Noto Serif Khmer',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Noto Serif Lao',
    googleFontsName: 'Noto Serif Lao',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Noto Serif Malayalam',
    googleFontsName: 'Noto Serif Malayalam',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Noto Serif Myanmar',
    googleFontsName: 'Noto Serif Myanmar',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Noto Serif Nyiakeng Puachue Hmong',
    googleFontsName: 'Noto Serif Nyiakeng Puachue Hmong',
    weights: [400, 500, 600, 700],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Noto Serif SC',
    googleFontsName: 'Noto Serif SC',
    weights: [200, 300, 400, 500, 600, 700, 900],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Noto Serif Sinhala',
    googleFontsName: 'Noto Serif Sinhala',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Noto Serif TC',
    googleFontsName: 'Noto Serif TC',
    weights: [200, 300, 400, 500, 600, 700, 900],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Noto Serif Tamil',
    googleFontsName: 'Noto Serif Tamil',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Noto Serif Tangut',
    googleFontsName: 'Noto Serif Tangut',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Noto Serif Telugu',
    googleFontsName: 'Noto Serif Telugu',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Noto Serif Thai',
    googleFontsName: 'Noto Serif Thai',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Noto Serif Tibetan',
    googleFontsName: 'Noto Serif Tibetan',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Noto Serif Yezidi',
    googleFontsName: 'Noto Serif Yezidi',
    weights: [400, 500, 600, 700],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Noto Traditional Nushu',
    googleFontsName: 'Noto Traditional Nushu',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Nova Cut',
    googleFontsName: 'Nova Cut',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Nova Flat',
    googleFontsName: 'Nova Flat',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Nova Mono',
    googleFontsName: 'Nova Mono',
    weights: [400],
    cyrillic: false,
    fallback: 'monospace'
  },
  {
    name: 'Nova Oval',
    googleFontsName: 'Nova Oval',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Nova Round',
    googleFontsName: 'Nova Round',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Nova Script',
    googleFontsName: 'Nova Script',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Nova Slim',
    googleFontsName: 'Nova Slim',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Nova Square',
    googleFontsName: 'Nova Square',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Numans',
    googleFontsName: 'Numans',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Nunito',
    googleFontsName: 'Nunito',
    weights: [200, 300, 400, 600, 700, 800, 900],
    cyrillic: true,
    fallback: 'sans-serif'
  },
  {
    name: 'Nunito Sans',
    googleFontsName: 'Nunito Sans',
    weights: [200, 300, 400, 600, 700, 800, 900],
    cyrillic: true,
    fallback: 'sans-serif'
  },
  {
    name: 'Odibee Sans',
    googleFontsName: 'Odibee Sans',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Odor Mean Chey',
    googleFontsName: 'Odor Mean Chey',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Offside',
    googleFontsName: 'Offside',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Oi',
    googleFontsName: 'Oi',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Old Standard TT',
    googleFontsName: 'Old Standard TT',
    weights: [400, 700],
    cyrillic: true,
    fallback: 'serif'
  },
  {
    name: 'Oldenburg',
    googleFontsName: 'Oldenburg',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Oleo Script',
    googleFontsName: 'Oleo Script',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Oleo Script Swash Caps',
    googleFontsName: 'Oleo Script Swash Caps',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Open Sans',
    googleFontsName: 'Open Sans',
    weights: [300, 400, 500, 600, 700, 800],
    cyrillic: true,
    fallback: 'sans-serif'
  },
  {
    name: 'Open Sans Condensed',
    googleFontsName: 'Open Sans Condensed',
    weights: [300, 700],
    cyrillic: true,
    fallback: 'sans-serif'
  },
  {
    name: 'Oranienbaum',
    googleFontsName: 'Oranienbaum',
    weights: [400],
    cyrillic: true,
    fallback: 'serif'
  },
  {
    name: 'Orbitron',
    googleFontsName: 'Orbitron',
    weights: [400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Oregano',
    googleFontsName: 'Oregano',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Orelega One',
    googleFontsName: 'Orelega One',
    weights: [400],
    cyrillic: true,
    fallback: 'display'
  },
  {
    name: 'Orienta',
    googleFontsName: 'Orienta',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Original Surfer',
    googleFontsName: 'Original Surfer',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Oswald',
    googleFontsName: 'Oswald',
    weights: [200, 300, 400, 500, 600, 700],
    cyrillic: true,
    fallback: 'sans-serif'
  },
  {
    name: 'Otomanopee One',
    googleFontsName: 'Otomanopee One',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Over the Rainbow',
    googleFontsName: 'Over the Rainbow',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Overlock',
    googleFontsName: 'Overlock',
    weights: [400, 700, 900],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Overlock SC',
    googleFontsName: 'Overlock SC',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Overpass',
    googleFontsName: 'Overpass',
    weights: [100, 200, 300, 400, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Overpass Mono',
    googleFontsName: 'Overpass Mono',
    weights: [300, 400, 600, 700],
    cyrillic: false,
    fallback: 'monospace'
  },
  {
    name: 'Ovo',
    googleFontsName: 'Ovo',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Oxanium',
    googleFontsName: 'Oxanium',
    weights: [200, 300, 400, 500, 600, 700, 800],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Oxygen',
    googleFontsName: 'Oxygen',
    weights: [300, 400, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Oxygen Mono',
    googleFontsName: 'Oxygen Mono',
    weights: [400],
    cyrillic: false,
    fallback: 'monospace'
  },
  {
    name: 'PT Mono',
    googleFontsName: 'PT Mono',
    weights: [400],
    cyrillic: true,
    fallback: 'monospace'
  },
  {
    name: 'PT Sans',
    googleFontsName: 'PT Sans',
    weights: [400, 700],
    cyrillic: true,
    fallback: 'sans-serif'
  },
  {
    name: 'PT Sans Caption',
    googleFontsName: 'PT Sans Caption',
    weights: [400, 700],
    cyrillic: true,
    fallback: 'sans-serif'
  },
  {
    name: 'PT Sans Narrow',
    googleFontsName: 'PT Sans Narrow',
    weights: [400, 700],
    cyrillic: true,
    fallback: 'sans-serif'
  },
  {
    name: 'PT Serif',
    googleFontsName: 'PT Serif',
    weights: [400, 700],
    cyrillic: true,
    fallback: 'serif'
  },
  {
    name: 'PT Serif Caption',
    googleFontsName: 'PT Serif Caption',
    weights: [400],
    cyrillic: true,
    fallback: 'serif'
  },
  {
    name: 'Pacifico',
    googleFontsName: 'Pacifico',
    weights: [400],
    cyrillic: true,
    fallback: 'handwriting'
  },
  {
    name: 'Padauk',
    googleFontsName: 'Padauk',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Palanquin',
    googleFontsName: 'Palanquin',
    weights: [100, 200, 300, 400, 500, 600, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Palanquin Dark',
    googleFontsName: 'Palanquin Dark',
    weights: [400, 500, 600, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Palette Mosaic',
    googleFontsName: 'Palette Mosaic',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Pangolin',
    googleFontsName: 'Pangolin',
    weights: [400],
    cyrillic: true,
    fallback: 'handwriting'
  },
  {
    name: 'Paprika',
    googleFontsName: 'Paprika',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Parisienne',
    googleFontsName: 'Parisienne',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Passero One',
    googleFontsName: 'Passero One',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Passion One',
    googleFontsName: 'Passion One',
    weights: [400, 700, 900],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Pathway Gothic One',
    googleFontsName: 'Pathway Gothic One',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Patrick Hand',
    googleFontsName: 'Patrick Hand',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Patrick Hand SC',
    googleFontsName: 'Patrick Hand SC',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Pattaya',
    googleFontsName: 'Pattaya',
    weights: [400],
    cyrillic: true,
    fallback: 'sans-serif'
  },
  {
    name: 'Patua One',
    googleFontsName: 'Patua One',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Pavanam',
    googleFontsName: 'Pavanam',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Paytone One',
    googleFontsName: 'Paytone One',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Peddana',
    googleFontsName: 'Peddana',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Peralta',
    googleFontsName: 'Peralta',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Permanent Marker',
    googleFontsName: 'Permanent Marker',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Petit Formal Script',
    googleFontsName: 'Petit Formal Script',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Petrona',
    googleFontsName: 'Petrona',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Philosopher',
    googleFontsName: 'Philosopher',
    weights: [400, 700],
    cyrillic: true,
    fallback: 'sans-serif'
  },
  {
    name: 'Piazzolla',
    googleFontsName: 'Piazzolla',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: true,
    fallback: 'serif'
  },
  {
    name: 'Piedra',
    googleFontsName: 'Piedra',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Pinyon Script',
    googleFontsName: 'Pinyon Script',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Pirata One',
    googleFontsName: 'Pirata One',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Plaster',
    googleFontsName: 'Plaster',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Play',
    googleFontsName: 'Play',
    weights: [400, 700],
    cyrillic: true,
    fallback: 'sans-serif'
  },
  {
    name: 'Playball',
    googleFontsName: 'Playball',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Playfair Display',
    googleFontsName: 'Playfair Display',
    weights: [400, 500, 600, 700, 800, 900],
    cyrillic: true,
    fallback: 'serif'
  },
  {
    name: 'Playfair Display SC',
    googleFontsName: 'Playfair Display SC',
    weights: [400, 700, 900],
    cyrillic: true,
    fallback: 'serif'
  },
  {
    name: 'Podkova',
    googleFontsName: 'Podkova',
    weights: [400, 500, 600, 700, 800],
    cyrillic: true,
    fallback: 'serif'
  },
  {
    name: 'Poiret One',
    googleFontsName: 'Poiret One',
    weights: [400],
    cyrillic: true,
    fallback: 'display'
  },
  {
    name: 'Poller One',
    googleFontsName: 'Poller One',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Poly',
    googleFontsName: 'Poly',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Pompiere',
    googleFontsName: 'Pompiere',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Pontano Sans',
    googleFontsName: 'Pontano Sans',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Poor Story',
    googleFontsName: 'Poor Story',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Poppins',
    googleFontsName: 'Poppins',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Port Lligat Sans',
    googleFontsName: 'Port Lligat Sans',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Port Lligat Slab',
    googleFontsName: 'Port Lligat Slab',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Potta One',
    googleFontsName: 'Potta One',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Pragati Narrow',
    googleFontsName: 'Pragati Narrow',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Prata',
    googleFontsName: 'Prata',
    weights: [400],
    cyrillic: true,
    fallback: 'serif'
  },
  {
    name: 'Preahvihear',
    googleFontsName: 'Preahvihear',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Press Start 2P',
    googleFontsName: 'Press Start 2P',
    weights: [400],
    cyrillic: true,
    fallback: 'display'
  },
  {
    name: 'Pridi',
    googleFontsName: 'Pridi',
    weights: [200, 300, 400, 500, 600, 700],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Princess Sofia',
    googleFontsName: 'Princess Sofia',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Prociono',
    googleFontsName: 'Prociono',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Prompt',
    googleFontsName: 'Prompt',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Prosto One',
    googleFontsName: 'Prosto One',
    weights: [400],
    cyrillic: true,
    fallback: 'display'
  },
  {
    name: 'Proza Libre',
    googleFontsName: 'Proza Libre',
    weights: [400, 500, 600, 700, 800],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Public Sans',
    googleFontsName: 'Public Sans',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Puritan',
    googleFontsName: 'Puritan',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Purple Purse',
    googleFontsName: 'Purple Purse',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Qahiri',
    googleFontsName: 'Qahiri',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Quando',
    googleFontsName: 'Quando',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Quantico',
    googleFontsName: 'Quantico',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Quattrocento',
    googleFontsName: 'Quattrocento',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Quattrocento Sans',
    googleFontsName: 'Quattrocento Sans',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Questrial',
    googleFontsName: 'Questrial',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Quicksand',
    googleFontsName: 'Quicksand',
    weights: [300, 400, 500, 600, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Quintessential',
    googleFontsName: 'Quintessential',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Qwigley',
    googleFontsName: 'Qwigley',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Racing Sans One',
    googleFontsName: 'Racing Sans One',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Radley',
    googleFontsName: 'Radley',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Rajdhani',
    googleFontsName: 'Rajdhani',
    weights: [300, 400, 500, 600, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Rakkas',
    googleFontsName: 'Rakkas',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Raleway',
    googleFontsName: 'Raleway',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: true,
    fallback: 'sans-serif'
  },
  {
    name: 'Raleway Dots',
    googleFontsName: 'Raleway Dots',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Ramabhadra',
    googleFontsName: 'Ramabhadra',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Ramaraja',
    googleFontsName: 'Ramaraja',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Rambla',
    googleFontsName: 'Rambla',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Rammetto One',
    googleFontsName: 'Rammetto One',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Rampart One',
    googleFontsName: 'Rampart One',
    weights: [400],
    cyrillic: true,
    fallback: 'display'
  },
  {
    name: 'Ranchers',
    googleFontsName: 'Ranchers',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Rancho',
    googleFontsName: 'Rancho',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Ranga',
    googleFontsName: 'Ranga',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Rasa',
    googleFontsName: 'Rasa',
    weights: [300, 400, 500, 600, 700],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Rationale',
    googleFontsName: 'Rationale',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Ravi Prakash',
    googleFontsName: 'Ravi Prakash',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Recursive',
    googleFontsName: 'Recursive',
    weights: [300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Red Hat Display',
    googleFontsName: 'Red Hat Display',
    weights: [300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Red Hat Text',
    googleFontsName: 'Red Hat Text',
    weights: [300, 400, 500, 600, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Red Rose',
    googleFontsName: 'Red Rose',
    weights: [300, 400, 500, 600, 700],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Redressed',
    googleFontsName: 'Redressed',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Reem Kufi',
    googleFontsName: 'Reem Kufi',
    weights: [400, 500, 600, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Reenie Beanie',
    googleFontsName: 'Reenie Beanie',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Reggae One',
    googleFontsName: 'Reggae One',
    weights: [400],
    cyrillic: true,
    fallback: 'display'
  },
  {
    name: 'Revalia',
    googleFontsName: 'Revalia',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Rhodium Libre',
    googleFontsName: 'Rhodium Libre',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Ribeye',
    googleFontsName: 'Ribeye',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Ribeye Marrow',
    googleFontsName: 'Ribeye Marrow',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Righteous',
    googleFontsName: 'Righteous',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Risque',
    googleFontsName: 'Risque',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Roboto',
    googleFontsName: 'Roboto',
    weights: [100, 300, 400, 500, 700, 900],
    cyrillic: true,
    fallback: 'sans-serif'
  },
  {
    name: 'Roboto Condensed',
    googleFontsName: 'Roboto Condensed',
    weights: [300, 400, 700],
    cyrillic: true,
    fallback: 'sans-serif'
  },
  {
    name: 'Roboto Mono',
    googleFontsName: 'Roboto Mono',
    weights: [100, 200, 300, 400, 500, 600, 700],
    cyrillic: true,
    fallback: 'monospace'
  },
  {
    name: 'Roboto Slab',
    googleFontsName: 'Roboto Slab',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: true,
    fallback: 'serif'
  },
  {
    name: 'Rochester',
    googleFontsName: 'Rochester',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Rock Salt',
    googleFontsName: 'Rock Salt',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'RocknRoll One',
    googleFontsName: 'RocknRoll One',
    weights: [400],
    cyrillic: true,
    fallback: 'sans-serif'
  },
  {
    name: 'Rokkitt',
    googleFontsName: 'Rokkitt',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Romanesco',
    googleFontsName: 'Romanesco',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Ropa Sans',
    googleFontsName: 'Ropa Sans',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Rosario',
    googleFontsName: 'Rosario',
    weights: [300, 400, 500, 600, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Rosarivo',
    googleFontsName: 'Rosarivo',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Rouge Script',
    googleFontsName: 'Rouge Script',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Rowdies',
    googleFontsName: 'Rowdies',
    weights: [300, 400, 700],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Rozha One',
    googleFontsName: 'Rozha One',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Rubik',
    googleFontsName: 'Rubik',
    weights: [300, 400, 500, 600, 700, 800, 900],
    cyrillic: true,
    fallback: 'sans-serif'
  },
  {
    name: 'Rubik Beastly',
    googleFontsName: 'Rubik Beastly',
    weights: [400],
    cyrillic: true,
    fallback: 'display'
  },
  {
    name: 'Rubik Mono One',
    googleFontsName: 'Rubik Mono One',
    weights: [400],
    cyrillic: true,
    fallback: 'sans-serif'
  },
  {
    name: 'Ruda',
    googleFontsName: 'Ruda',
    weights: [400, 500, 600, 700, 800, 900],
    cyrillic: true,
    fallback: 'sans-serif'
  },
  {
    name: 'Rufina',
    googleFontsName: 'Rufina',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Ruge Boogie',
    googleFontsName: 'Ruge Boogie',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Ruluko',
    googleFontsName: 'Ruluko',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Rum Raisin',
    googleFontsName: 'Rum Raisin',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Ruslan Display',
    googleFontsName: 'Ruslan Display',
    weights: [400],
    cyrillic: true,
    fallback: 'display'
  },
  {
    name: 'Russo One',
    googleFontsName: 'Russo One',
    weights: [400],
    cyrillic: true,
    fallback: 'sans-serif'
  },
  {
    name: 'Ruthie',
    googleFontsName: 'Ruthie',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Rye',
    googleFontsName: 'Rye',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'STIX Two Text',
    googleFontsName: 'STIX Two Text',
    weights: [400, 500, 600, 700],
    cyrillic: true,
    fallback: 'serif'
  },
  {
    name: 'Sacramento',
    googleFontsName: 'Sacramento',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Sahitya',
    googleFontsName: 'Sahitya',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Sail',
    googleFontsName: 'Sail',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Saira',
    googleFontsName: 'Saira',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Saira Condensed',
    googleFontsName: 'Saira Condensed',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Saira Extra Condensed',
    googleFontsName: 'Saira Extra Condensed',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Saira Semi Condensed',
    googleFontsName: 'Saira Semi Condensed',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Saira Stencil One',
    googleFontsName: 'Saira Stencil One',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Salsa',
    googleFontsName: 'Salsa',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Sanchez',
    googleFontsName: 'Sanchez',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Sancreek',
    googleFontsName: 'Sancreek',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Sansita',
    googleFontsName: 'Sansita',
    weights: [400, 700, 800, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Sansita Swashed',
    googleFontsName: 'Sansita Swashed',
    weights: [300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Sarabun',
    googleFontsName: 'Sarabun',
    weights: [100, 200, 300, 400, 500, 600, 700, 800],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Sarala',
    googleFontsName: 'Sarala',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Sarina',
    googleFontsName: 'Sarina',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Sarpanch',
    googleFontsName: 'Sarpanch',
    weights: [400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Satisfy',
    googleFontsName: 'Satisfy',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Sawarabi Gothic',
    googleFontsName: 'Sawarabi Gothic',
    weights: [400],
    cyrillic: true,
    fallback: 'sans-serif'
  },
  {
    name: 'Sawarabi Mincho',
    googleFontsName: 'Sawarabi Mincho',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Scada',
    googleFontsName: 'Scada',
    weights: [400, 700],
    cyrillic: true,
    fallback: 'sans-serif'
  },
  {
    name: 'Scheherazade',
    googleFontsName: 'Scheherazade',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Scheherazade New',
    googleFontsName: 'Scheherazade New',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Schoolbell',
    googleFontsName: 'Schoolbell',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Scope One',
    googleFontsName: 'Scope One',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Seaweed Script',
    googleFontsName: 'Seaweed Script',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Secular One',
    googleFontsName: 'Secular One',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Sedgwick Ave',
    googleFontsName: 'Sedgwick Ave',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Sedgwick Ave Display',
    googleFontsName: 'Sedgwick Ave Display',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Sen',
    googleFontsName: 'Sen',
    weights: [400, 700, 800],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Sevillana',
    googleFontsName: 'Sevillana',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Seymour One',
    googleFontsName: 'Seymour One',
    weights: [400],
    cyrillic: true,
    fallback: 'sans-serif'
  },
  {
    name: 'Shadows Into Light',
    googleFontsName: 'Shadows Into Light',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Shadows Into Light Two',
    googleFontsName: 'Shadows Into Light Two',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Shanti',
    googleFontsName: 'Shanti',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Share',
    googleFontsName: 'Share',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Share Tech',
    googleFontsName: 'Share Tech',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Share Tech Mono',
    googleFontsName: 'Share Tech Mono',
    weights: [400],
    cyrillic: false,
    fallback: 'monospace'
  },
  {
    name: 'Shippori Mincho',
    googleFontsName: 'Shippori Mincho',
    weights: [400, 500, 600, 700, 800],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Shippori Mincho B1',
    googleFontsName: 'Shippori Mincho B1',
    weights: [400, 500, 600, 700, 800],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Shojumaru',
    googleFontsName: 'Shojumaru',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Short Stack',
    googleFontsName: 'Short Stack',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Shrikhand',
    googleFontsName: 'Shrikhand',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Siemreap',
    googleFontsName: 'Siemreap',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Sigmar One',
    googleFontsName: 'Sigmar One',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Signika',
    googleFontsName: 'Signika',
    weights: [300, 400, 500, 600, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Signika Negative',
    googleFontsName: 'Signika Negative',
    weights: [300, 400, 600, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Simonetta',
    googleFontsName: 'Simonetta',
    weights: [400, 900],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Single Day',
    googleFontsName: 'Single Day',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Sintony',
    googleFontsName: 'Sintony',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Sirin Stencil',
    googleFontsName: 'Sirin Stencil',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Six Caps',
    googleFontsName: 'Six Caps',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Skranji',
    googleFontsName: 'Skranji',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Slabo 13px',
    googleFontsName: 'Slabo 13px',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Slabo 27px',
    googleFontsName: 'Slabo 27px',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Slackey',
    googleFontsName: 'Slackey',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Smokum',
    googleFontsName: 'Smokum',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Smythe',
    googleFontsName: 'Smythe',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Sniglet',
    googleFontsName: 'Sniglet',
    weights: [400, 800],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Snippet',
    googleFontsName: 'Snippet',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Snowburst One',
    googleFontsName: 'Snowburst One',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Sofadi One',
    googleFontsName: 'Sofadi One',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Sofia',
    googleFontsName: 'Sofia',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Solway',
    googleFontsName: 'Solway',
    weights: [300, 400, 500, 700, 800],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Song Myung',
    googleFontsName: 'Song Myung',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Sonsie One',
    googleFontsName: 'Sonsie One',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Sora',
    googleFontsName: 'Sora',
    weights: [100, 200, 300, 400, 500, 600, 700, 800],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Sorts Mill Goudy',
    googleFontsName: 'Sorts Mill Goudy',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Source Code Pro',
    googleFontsName: 'Source Code Pro',
    weights: [200, 300, 400, 500, 600, 700, 900],
    cyrillic: true,
    fallback: 'monospace'
  },
  {
    name: 'Source Sans Pro',
    googleFontsName: 'Source Sans Pro',
    weights: [200, 300, 400, 600, 700, 900],
    cyrillic: true,
    fallback: 'sans-serif'
  },
  {
    name: 'Source Serif Pro',
    googleFontsName: 'Source Serif Pro',
    weights: [200, 300, 400, 600, 700, 900],
    cyrillic: true,
    fallback: 'serif'
  },
  {
    name: 'Space Grotesk',
    googleFontsName: 'Space Grotesk',
    weights: [300, 400, 500, 600, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Space Mono',
    googleFontsName: 'Space Mono',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'monospace'
  },
  {
    name: 'Spartan',
    googleFontsName: 'Spartan',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Special Elite',
    googleFontsName: 'Special Elite',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Spectral',
    googleFontsName: 'Spectral',
    weights: [200, 300, 400, 500, 600, 700, 800],
    cyrillic: true,
    fallback: 'serif'
  },
  {
    name: 'Spectral SC',
    googleFontsName: 'Spectral SC',
    weights: [200, 300, 400, 500, 600, 700, 800],
    cyrillic: true,
    fallback: 'serif'
  },
  {
    name: 'Spicy Rice',
    googleFontsName: 'Spicy Rice',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Spinnaker',
    googleFontsName: 'Spinnaker',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Spirax',
    googleFontsName: 'Spirax',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Squada One',
    googleFontsName: 'Squada One',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Sree Krushnadevaraya',
    googleFontsName: 'Sree Krushnadevaraya',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Sriracha',
    googleFontsName: 'Sriracha',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Srisakdi',
    googleFontsName: 'Srisakdi',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Staatliches',
    googleFontsName: 'Staatliches',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Stalemate',
    googleFontsName: 'Stalemate',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Stalinist One',
    googleFontsName: 'Stalinist One',
    weights: [400],
    cyrillic: true,
    fallback: 'display'
  },
  {
    name: 'Stardos Stencil',
    googleFontsName: 'Stardos Stencil',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Stick',
    googleFontsName: 'Stick',
    weights: [400],
    cyrillic: true,
    fallback: 'sans-serif'
  },
  {
    name: 'Stick No Bills',
    googleFontsName: 'Stick No Bills',
    weights: [200, 300, 400, 500, 600, 700, 800],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Stint Ultra Condensed',
    googleFontsName: 'Stint Ultra Condensed',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Stint Ultra Expanded',
    googleFontsName: 'Stint Ultra Expanded',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Stoke',
    googleFontsName: 'Stoke',
    weights: [300, 400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Strait',
    googleFontsName: 'Strait',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Style Script',
    googleFontsName: 'Style Script',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Stylish',
    googleFontsName: 'Stylish',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Sue Ellen Francisco',
    googleFontsName: 'Sue Ellen Francisco',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Suez One',
    googleFontsName: 'Suez One',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Sulphur Point',
    googleFontsName: 'Sulphur Point',
    weights: [300, 400, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Sumana',
    googleFontsName: 'Sumana',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Sunflower',
    googleFontsName: 'Sunflower',
    weights: [300, 500, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Sunshiney',
    googleFontsName: 'Sunshiney',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Supermercado One',
    googleFontsName: 'Supermercado One',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Sura',
    googleFontsName: 'Sura',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Suranna',
    googleFontsName: 'Suranna',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Suravaram',
    googleFontsName: 'Suravaram',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Suwannaphum',
    googleFontsName: 'Suwannaphum',
    weights: [100, 300, 400, 700, 900],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Swanky and Moo Moo',
    googleFontsName: 'Swanky and Moo Moo',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Syncopate',
    googleFontsName: 'Syncopate',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Syne',
    googleFontsName: 'Syne',
    weights: [400, 500, 600, 700, 800],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Syne Mono',
    googleFontsName: 'Syne Mono',
    weights: [400],
    cyrillic: false,
    fallback: 'monospace'
  },
  {
    name: 'Syne Tactile',
    googleFontsName: 'Syne Tactile',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Tajawal',
    googleFontsName: 'Tajawal',
    weights: [200, 300, 400, 500, 700, 800, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Tangerine',
    googleFontsName: 'Tangerine',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Taprom',
    googleFontsName: 'Taprom',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Tauri',
    googleFontsName: 'Tauri',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Taviraj',
    googleFontsName: 'Taviraj',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Teko',
    googleFontsName: 'Teko',
    weights: [300, 400, 500, 600, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Telex',
    googleFontsName: 'Telex',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Tenali Ramakrishna',
    googleFontsName: 'Tenali Ramakrishna',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Tenor Sans',
    googleFontsName: 'Tenor Sans',
    weights: [400],
    cyrillic: true,
    fallback: 'sans-serif'
  },
  {
    name: 'Text Me One',
    googleFontsName: 'Text Me One',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Texturina',
    googleFontsName: 'Texturina',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Thasadith',
    googleFontsName: 'Thasadith',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'The Girl Next Door',
    googleFontsName: 'The Girl Next Door',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Tienne',
    googleFontsName: 'Tienne',
    weights: [400, 700, 900],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Tillana',
    googleFontsName: 'Tillana',
    weights: [400, 500, 600, 700, 800],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Timmana',
    googleFontsName: 'Timmana',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Tinos',
    googleFontsName: 'Tinos',
    weights: [400, 700],
    cyrillic: true,
    fallback: 'serif'
  },
  {
    name: 'Titan One',
    googleFontsName: 'Titan One',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Titillium Web',
    googleFontsName: 'Titillium Web',
    weights: [200, 300, 400, 600, 700, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Tomorrow',
    googleFontsName: 'Tomorrow',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Tourney',
    googleFontsName: 'Tourney',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Trade Winds',
    googleFontsName: 'Trade Winds',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Train One',
    googleFontsName: 'Train One',
    weights: [400],
    cyrillic: true,
    fallback: 'display'
  },
  {
    name: 'Trirong',
    googleFontsName: 'Trirong',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Trispace',
    googleFontsName: 'Trispace',
    weights: [100, 200, 300, 400, 500, 600, 700, 800],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Trocchi',
    googleFontsName: 'Trocchi',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Trochut',
    googleFontsName: 'Trochut',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Truculenta',
    googleFontsName: 'Truculenta',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Trykker',
    googleFontsName: 'Trykker',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Tulpen One',
    googleFontsName: 'Tulpen One',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Turret Road',
    googleFontsName: 'Turret Road',
    weights: [200, 300, 400, 500, 700, 800],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Ubuntu',
    googleFontsName: 'Ubuntu',
    weights: [300, 400, 500, 700],
    cyrillic: true,
    fallback: 'sans-serif'
  },
  {
    name: 'Ubuntu Condensed',
    googleFontsName: 'Ubuntu Condensed',
    weights: [400],
    cyrillic: true,
    fallback: 'sans-serif'
  },
  {
    name: 'Ubuntu Mono',
    googleFontsName: 'Ubuntu Mono',
    weights: [400, 700],
    cyrillic: true,
    fallback: 'monospace'
  },
  {
    name: 'Uchen',
    googleFontsName: 'Uchen',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Ultra',
    googleFontsName: 'Ultra',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Unbounded',
    googleFontsName: 'Unbounded',
    weights: [200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'cursive'
  },
  {
    name: 'Uncial Antiqua',
    googleFontsName: 'Uncial Antiqua',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Underdog',
    googleFontsName: 'Underdog',
    weights: [400],
    cyrillic: true,
    fallback: 'display'
  },
  {
    name: 'Unica One',
    googleFontsName: 'Unica One',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'UnifrakturCook',
    googleFontsName: 'UnifrakturCook',
    weights: [700],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'UnifrakturMaguntia',
    googleFontsName: 'UnifrakturMaguntia',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Unkempt',
    googleFontsName: 'Unkempt',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Unlock',
    googleFontsName: 'Unlock',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Unna',
    googleFontsName: 'Unna',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Urbanist',
    googleFontsName: 'Urbanist',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'VT323',
    googleFontsName: 'VT323',
    weights: [400],
    cyrillic: false,
    fallback: 'monospace'
  },
  {
    name: 'Vampiro One',
    googleFontsName: 'Vampiro One',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Varela',
    googleFontsName: 'Varela',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Varela Round',
    googleFontsName: 'Varela Round',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Varta',
    googleFontsName: 'Varta',
    weights: [300, 400, 500, 600, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Vast Shadow',
    googleFontsName: 'Vast Shadow',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Vesper Libre',
    googleFontsName: 'Vesper Libre',
    weights: [400, 500, 700, 900],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Viaoda Libre',
    googleFontsName: 'Viaoda Libre',
    weights: [400],
    cyrillic: true,
    fallback: 'display'
  },
  {
    name: 'Vibes',
    googleFontsName: 'Vibes',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Vibur',
    googleFontsName: 'Vibur',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Vidaloka',
    googleFontsName: 'Vidaloka',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Viga',
    googleFontsName: 'Viga',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Voces',
    googleFontsName: 'Voces',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Volkhov',
    googleFontsName: 'Volkhov',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Vollkorn',
    googleFontsName: 'Vollkorn',
    weights: [400, 500, 600, 700, 800, 900],
    cyrillic: true,
    fallback: 'serif'
  },
  {
    name: 'Vollkorn SC',
    googleFontsName: 'Vollkorn SC',
    weights: [400, 600, 700, 900],
    cyrillic: true,
    fallback: 'serif'
  },
  {
    name: 'Voltaire',
    googleFontsName: 'Voltaire',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Waiting for the Sunrise',
    googleFontsName: 'Waiting for the Sunrise',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Wallpoet',
    googleFontsName: 'Wallpoet',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Walter Turncoat',
    googleFontsName: 'Walter Turncoat',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Warnes',
    googleFontsName: 'Warnes',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Wellfleet',
    googleFontsName: 'Wellfleet',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Wendy One',
    googleFontsName: 'Wendy One',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'WindSong',
    googleFontsName: 'WindSong',
    weights: [400, 500],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Wire One',
    googleFontsName: 'Wire One',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Work Sans',
    googleFontsName: 'Work Sans',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Xanh Mono',
    googleFontsName: 'Xanh Mono',
    weights: [400],
    cyrillic: false,
    fallback: 'monospace'
  },
  {
    name: 'Yaldevi',
    googleFontsName: 'Yaldevi',
    weights: [200, 300, 400, 500, 600, 700],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Yanone Kaffeesatz',
    googleFontsName: 'Yanone Kaffeesatz',
    weights: [200, 300, 400, 500, 600, 700],
    cyrillic: true,
    fallback: 'sans-serif'
  },
  {
    name: 'Yantramanav',
    googleFontsName: 'Yantramanav',
    weights: [100, 300, 400, 500, 700, 900],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'Yatra One',
    googleFontsName: 'Yatra One',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Yellowtail',
    googleFontsName: 'Yellowtail',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Yeon Sung',
    googleFontsName: 'Yeon Sung',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Yeseva One',
    googleFontsName: 'Yeseva One',
    weights: [400],
    cyrillic: true,
    fallback: 'display'
  },
  {
    name: 'Yesteryear',
    googleFontsName: 'Yesteryear',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Yomogi',
    googleFontsName: 'Yomogi',
    weights: [400],
    cyrillic: true,
    fallback: 'handwriting'
  },
  {
    name: 'Yrsa',
    googleFontsName: 'Yrsa',
    weights: [300, 400, 500, 600, 700],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Yusei Magic',
    googleFontsName: 'Yusei Magic',
    weights: [400],
    cyrillic: false,
    fallback: 'sans-serif'
  },
  {
    name: 'ZCOOL KuaiLe',
    googleFontsName: 'ZCOOL KuaiLe',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'ZCOOL QingKe HuangYou',
    googleFontsName: 'ZCOOL QingKe HuangYou',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'ZCOOL XiaoWei',
    googleFontsName: 'ZCOOL XiaoWei',
    weights: [400],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Zen Antique',
    googleFontsName: 'Zen Antique',
    weights: [400],
    cyrillic: true,
    fallback: 'serif'
  },
  {
    name: 'Zen Antique Soft',
    googleFontsName: 'Zen Antique Soft',
    weights: [400],
    cyrillic: true,
    fallback: 'serif'
  },
  {
    name: 'Zen Dots',
    googleFontsName: 'Zen Dots',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Zen Kaku Gothic Antique',
    googleFontsName: 'Zen Kaku Gothic Antique',
    weights: [300, 400, 500, 700, 900],
    cyrillic: true,
    fallback: 'sans-serif'
  },
  {
    name: 'Zen Kaku Gothic New',
    googleFontsName: 'Zen Kaku Gothic New',
    weights: [300, 400, 500, 700, 900],
    cyrillic: true,
    fallback: 'sans-serif'
  },
  {
    name: 'Zen Kurenaido',
    googleFontsName: 'Zen Kurenaido',
    weights: [400],
    cyrillic: true,
    fallback: 'sans-serif'
  },
  {
    name: 'Zen Loop',
    googleFontsName: 'Zen Loop',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Zen Maru Gothic',
    googleFontsName: 'Zen Maru Gothic',
    weights: [300, 400, 500, 700, 900],
    cyrillic: true,
    fallback: 'sans-serif'
  },
  {
    name: 'Zen Old Mincho',
    googleFontsName: 'Zen Old Mincho',
    weights: [400, 700, 900],
    cyrillic: true,
    fallback: 'serif'
  },
  {
    name: 'Zen Tokyo Zoo',
    googleFontsName: 'Zen Tokyo Zoo',
    weights: [400],
    cyrillic: false,
    fallback: 'display'
  },
  {
    name: 'Zeyada',
    googleFontsName: 'Zeyada',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Zhi Mang Xing',
    googleFontsName: 'Zhi Mang Xing',
    weights: [400],
    cyrillic: false,
    fallback: 'handwriting'
  },
  {
    name: 'Zilla Slab',
    googleFontsName: 'Zilla Slab',
    weights: [300, 400, 500, 600, 700],
    cyrillic: false,
    fallback: 'serif'
  },
  {
    name: 'Zilla Slab Highlight',
    googleFontsName: 'Zilla Slab Highlight',
    weights: [400, 700],
    cyrillic: false,
    fallback: 'display'
  }
].map(item => {
  return {
    ...item,
    default: item.name == defaultFontName
  };
});
