import { Injectable, OnDestroy, Optional } from '@angular/core';
import isEqual from 'lodash/isEqual';
import { BehaviorSubject } from 'rxjs';

import { BasePopupComponent, PopupService } from '@common/popups';

import { GradientControl } from '../forms/gradient.control';

export interface CustomizeGradient {
  source: any;
  control: GradientControl;
  activeStop?: string;
}

@Injectable()
export class FillEditContext implements OnDestroy {
  public customizingGradient$ = new BehaviorSubject<CustomizeGradient>(undefined);

  constructor(private popupService: PopupService, @Optional() private popupComponent: BasePopupComponent) {}

  ngOnDestroy(): void {}

  startCustomizingGradient(value: CustomizeGradient) {
    this.customizingGradient$.next(value);
  }

  updateCustomizingGradient(value: Partial<CustomizeGradient>) {
    if (!this.customizingGradient$.value) {
      return;
    }

    const currentValue = this.customizingGradient$.value;
    const newValue = { ...currentValue, ...value };

    if (isEqual(currentValue, newValue)) {
      return;
    }

    this.customizingGradient$.next(newValue);
  }

  finishCustomizingGradient() {
    if (!this.customizingGradient$.value) {
      return;
    }

    this.customizingGradient$.next(undefined);
  }
}
