<form [formGroup]="form" (ngSubmit)="submit()">
  <app-customize-bar-header
    [title]="form.controls.name.value || (titleEditable ? 'steps' : 'Steps')"
    [titleEnabled]="titleEnabled"
    [titleEditable]="titleEditable"
    [titleCleanValue]="titleCleanValue"
    [backLabel]="'All components'"
    [deleteEnabled]="deleteEnabled"
    [parentLabel]="parentElement?.displayName || parentPopup?.displayName"
    (parentClick)="parentElement?.customize({ event: $event }) || parentPopup?.customize({ event: $event })"
    (deleteClick)="delete()"
    (titleChanged)="onTitleChanged($event)"
  >
  </app-customize-bar-header>

  <div class="sidebar__content">
    <app-sidebar-tabs>
      <app-sidebar-tab [label]="'General'">
        <app-sidebar-section>
          <div class="sidebar__list">
            <div
              cdkDropList
              [cdkDropListLockAxis]="'y'"
              class="sidebar-list"
              (cdkDropListDropped)="dragDropOption($event)"
              #list_element
            >
              <div
                *ngFor="let control of form.controls.items.controls; let i = index"
                [formGroup]="control"
                cdkDrag
                class="sidebar-list__item"
              >
                <app-sidebar-collapse
                  [title]="(control.controls.name.value | appIsSet) ? control.controls.name.value : 'No label'"
                  [error]="control.invalid ? 'Step is not configured' : undefined"
                  [description]="
                    control.controls.value.value != control.controls.name.value
                      ? control.controls.value.value
                      : undefined
                  "
                  [descriptionWrap]="true"
                  [block]="true"
                  [context]="collapseContext"
                  [openedInitial]="control === createdStep"
                  [arrow]="true"
                  [drag]="true"
                  [padding]="false"
                  [appApplyDragPredicate]="{
                    handleSelector: '.sidebar-collapse__header',
                    dispatchElement: list_element
                  }"
                >
                  <ng-container right>
                    <a
                      href="javascript:void(0)"
                      class="sidebar-collapse__action icon-bin"
                      (click)="$event.stopPropagation(); form.controls.items.remove(control)"
                    >
                    </a>
                  </ng-container>

                  <div style="padding: 10px 0;">
                    <div class="sidebar__element sidebar__element_field-right sidebar__element_margin_xxs">
                      <app-sidebar-field [label]="'title'" [layoutRight]="true">
                        <app-auto-field
                          [form]="control"
                          [label]="false"
                          [autofocus]="true"
                          [field]="
                            createField({
                              name: 'name',
                              field: 'CharField',
                              params: { classes: ['input_fill'] }
                            })
                          "
                        ></app-auto-field>
                      </app-sidebar-field>
                    </div>

                    <div class="sidebar__element sidebar__element_field-right sidebar__element_margin_xxs">
                      <app-sidebar-field [label]="'subtitle'" [layoutRight]="true">
                        <app-auto-field
                          [form]="control"
                          [label]="false"
                          [field]="
                            createField({
                              name: 'description',
                              field: 'CharField',
                              params: { classes: ['input_fill'] }
                            })
                          "
                        ></app-auto-field>
                      </app-sidebar-field>
                    </div>

                    <div class="sidebar__element sidebar__element_field-right sidebar__element_margin_xxs">
                      <app-sidebar-field [label]="'value'" [layoutRight]="true">
                        <app-auto-field
                          [form]="control"
                          [label]="false"
                          [field]="
                            createField({
                              name: 'value',
                              field: 'CharField',
                              params: { classes: ['input_fill'] }
                            })
                          "
                        ></app-auto-field>
                      </app-sidebar-field>
                    </div>

                    <div class="sidebar__element sidebar__element_field-right sidebar__element_margin_xxs">
                      <app-sidebar-field [label]="'color'" [layoutRight]="true">
                        <app-auto-field
                          [form]="control"
                          [label]="false"
                          [field]="
                            createField({
                              name: 'color',
                              field: 'ColorField',
                              params: {
                                classes: ['select_fill'],
                                allow_empty: true,
                                empty_accent_color: true,
                                custom_colors: true
                              }
                            })
                          "
                        ></app-auto-field>
                      </app-sidebar-field>
                    </div>

                    <div class="sidebar__element">
                      <app-sidebar-field
                        [label]="'Conditional Visibility'"
                        [documentation]="'conditional-visibility'"
                        [documentationLabel]="'See how it works'"
                        [documentationSource]="element.analyticsGenericName + '_item'"
                      >
                        <app-input-edit
                          [itemForm]="control.controls.visible_input"
                          [context]="context"
                          [contextElement]="contextElement"
                          [staticValueDisabled]="true"
                          [placeholder]="'Value'"
                          [fill]="true"
                          [analyticsSource]="element.analyticsGenericName + '_item'"
                        ></app-input-edit>

                        <ng-container description>
                          Example: elements.field.value >= 10
                        </ng-container>
                      </app-sidebar-field>
                    </div>
                  </div>
                </app-sidebar-collapse>
              </div>

              <div *ngIf="!form.controls.items.controls.length" class="sidebar-list__item">
                <div
                  class="sidebar-list-item sidebar-list-item_clickable sidebar-list-item_disabled component-data-button"
                  (click)="addStep()"
                >
                  <div class="sidebar-list-item__drag"></div>
                  <div class="sidebar-list-item__main">
                    <div class="sidebar-list-item__title">Step</div>
                  </div>
                </div>
              </div>

              <div class="sidebar-list__buttons">
                <a
                  href="javascript:void(0)"
                  class="button button_orange-transparent button_small sidebar-list__buttons-item"
                  (click)="addStep()"
                >
                  <span class="icon icon-plus button__icon button__icon_left"></span>
                  <span class="button__label">Add a Step</span>
                </a>

                <app-field-errors
                  *ngIf="!form.controls.items.controls.length"
                  [form]="form"
                  [fieldName]="'items'"
                ></app-field-errors>
              </div>
            </div>
          </div>

          <div class="sidebar__element">
            <app-customize-bar-input-edit
              [label]="'current step'"
              [itemForm]="form.controls.current_item"
              [context]="context"
              [staticValueField]="fieldTypes.Select"
              [staticValueParams]="{ options: itemOptions, reset_enabled: false }"
              [fill]="true"
              [analyticsSource]="element.analyticsGenericName + '_current_step'"
            >
            </app-customize-bar-input-edit>
          </div>
        </app-sidebar-section>
      </app-sidebar-tab>

      <app-sidebar-tab [label]="'Display'">
        <app-sidebar-section>
          <div class="sidebar__element">
            <app-sidebar-field
              [label]="'Conditional Visibility'"
              [documentation]="'conditional-visibility'"
              [documentationLabel]="'See how it works'"
              [documentationSource]="element.analyticsGenericName + '_visible'"
            >
              <app-input-edit
                [itemForm]="form.controls.visible_input"
                [context]="context"
                [staticValueDisabled]="true"
                [placeholder]="'Value'"
                [fill]="true"
                [analyticsSource]="element.analyticsGenericName + '_visible'"
              ></app-input-edit>

              <ng-container description>
                Example: elements.field.value >= 10
              </ng-container>
            </app-sidebar-field>
          </div>

          <div class="sidebar__element">
            <app-sidebar-field [label]="'Spacing'">
              <app-margin-control [control]="form.controls.margin"></app-margin-control>
            </app-sidebar-field>
          </div>
        </app-sidebar-section>
      </app-sidebar-tab>
    </app-sidebar-tabs>
  </div>

  <!--  <app-sidebar-submit-->
  <!--    [saveDisabled]="form.invalid || form.disabled"-->
  <!--    [errors]="form | appFormErrors"-->
  <!--    (cancelClick)="cancel()"-->
  <!--    (deleteClick)="delete()"-->
  <!--  >-->
  <!--  </app-sidebar-submit>-->
</form>
