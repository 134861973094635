var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import keys from 'lodash/keys';
import pickBy from 'lodash/pickBy';
import { isSet } from '@shared';
import { BorderSettings } from './border-settings';
import { Shadow } from './shadow';
var ElementWrapperStyles = /** @class */ (function () {
    function ElementWrapperStyles(options) {
        if (options === void 0) { options = {}; }
        Object.assign(this, options);
    }
    ElementWrapperStyles.prototype.deserialize = function (data) {
        if (data['border_settings']) {
            this.borderSettings = new BorderSettings().deserialize(data['border_settings']);
        }
        else {
            this.borderSettings = undefined;
        }
        if (data['border_radius']) {
            this.borderRadius = data['border_radius'];
        }
        else {
            this.borderRadius = undefined;
        }
        if (data['shadow']) {
            this.shadow = new Shadow().deserialize(data['shadow']);
        }
        else {
            this.shadow = undefined;
        }
        if (data['margin']) {
            this.margin = data['margin'];
        }
        else {
            this.margin = undefined;
        }
        return this;
    };
    ElementWrapperStyles.prototype.serialize = function () {
        return {
            border_settings: this.borderSettings ? this.borderSettings.serialize() : undefined,
            border_radius: this.borderRadius,
            shadow: this.shadow ? this.shadow.serialize() : undefined,
            margin: this.margin
        };
    };
    ElementWrapperStyles.prototype.apply = function (other) {
        var _this = this;
        var properties = ['borderSettings', 'borderRadius', 'shadow', 'margin'];
        properties.forEach(function (property) {
            if (other && isSet(other[property])) {
                _this[property] = other[property];
            }
        });
        return this;
    };
    return ElementWrapperStyles;
}());
export { ElementWrapperStyles };
export function getElementWrapperStyles(element) {
    var options = pickBy(__assign({ borderSettings: element.borderSettings, borderRadius: element.borderRadius, shadow: element.shadow }, (keys(element.margin).length && {
        margin: element.margin
    })), function (v) { return isSet(v); });
    if (!keys(options).length) {
        return;
    }
    return new ElementWrapperStyles(options);
}
export function applyElementWrapperStyles(element, styles) {
    if (!styles) {
        styles = new ElementWrapperStyles();
    }
    element.borderSettings = styles.borderSettings;
    element.borderRadius = styles.borderRadius;
    element.shadow = styles.shadow;
    element.margin = styles.margin;
}
