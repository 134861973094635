/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../common/tips/directives/tip/tip.directive";
import * as i2 from "@angular/forms";
import * as i3 from "../../../../shared/components/autofocus/autofocus.directive";
import * as i4 from "../../../../core/services/app-config/app-config.service";
import * as i5 from "../../../../shared/directives/form-control-selector/form-control-selector.directive";
import * as i6 from "@angular/common";
import * as i7 from "../../../../common/localize/pipes/localize/localize.pipe";
import * as i8 from "../../../../shared/pipes/capitalize/capitalize.pipe";
import * as i9 from "../../../../shared/pipes/is-set/is-set.pipe";
import * as i10 from "./select-model-field-lookup.component";
var styles_SelectModelFieldLookupComponent = [];
var RenderType_SelectModelFieldLookupComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SelectModelFieldLookupComponent, data: {} });
export { RenderType_SelectModelFieldLookupComponent as RenderType_SelectModelFieldLookupComponent };
function View_SelectModelFieldLookupComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "])), i0.ɵppd(2, 1), i0.ɵpad(3, 1), i0.ɵppd(4, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, _ck(_v, 4, 0, i0.ɵnov(_v.parent, 0), "Back to {0} fields", _ck(_v, 3, 0, i0.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent, 1), _co.fieldSelection.modelDescription.verboseName))))); _ck(_v, 1, 0, currVal_0); }); }
function View_SelectModelFieldLookupComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "])), i0.ɵppd(2, 1)], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent, 0), "Back")); _ck(_v, 1, 0, currVal_0); }); }
function View_SelectModelFieldLookupComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "span", [["class", "token-popup-search__close icon-close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clearSearch() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 737280, null, 0, i1.TipDirective, [i0.ElementRef, i0.ComponentFactoryResolver, i0.Injector, i0.ApplicationRef], { content: [0, "content"], optionsRaw: [1, "optionsRaw"] }, null), i0.ɵppd(2, 1), i0.ɵpod(3, { side: 0 })], function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent.parent, 0), "Clear value")); var currVal_1 = _ck(_v, 3, 0, "top"); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_SelectModelFieldLookupComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [["class", "token-popup-search"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 7, "input", [["class", "token-popup-search__input"], ["type", "text"]], [[8, "placeholder", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 2).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 2)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 2)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 16384, null, 0, i2.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DefaultValueAccessor]), i0.ɵdid(4, 540672, null, 0, i2.FormControlDirective, [[8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR], [2, i2.ɵangular_packages_forms_forms_k]], { form: [0, "form"] }, null), i0.ɵprd(2048, null, i2.NgControl, null, [i2.FormControlDirective]), i0.ɵdid(6, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), i0.ɵdid(7, 540672, null, 0, i3.AutofocusDirective, [i0.ElementRef, i4.AppConfigService], { appAutofocus: [0, "appAutofocus"] }, null), i0.ɵdid(8, 81920, null, 0, i5.FormControlSelectorDirective, [i0.ElementRef, [2, i2.FormControlDirective], [2, i2.FormControlName]], null, null), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_SelectModelFieldLookupComponent_4)), i0.ɵdid(10, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵppd(11, 1)], function (_ck, _v) { var _co = _v.component; var currVal_8 = _co.searchControl; _ck(_v, 4, 0, currVal_8); var currVal_9 = true; _ck(_v, 7, 0, currVal_9); _ck(_v, 8, 0); var currVal_10 = i0.ɵunv(_v, 10, 0, _ck(_v, 11, 0, i0.ɵnov(_v.parent, 2), _co.searchControl.value)); _ck(_v, 10, 0, currVal_10); }, function (_ck, _v) { var currVal_0 = "Filter by..."; var currVal_1 = i0.ɵnov(_v, 6).ngClassUntouched; var currVal_2 = i0.ɵnov(_v, 6).ngClassTouched; var currVal_3 = i0.ɵnov(_v, 6).ngClassPristine; var currVal_4 = i0.ɵnov(_v, 6).ngClassDirty; var currVal_5 = i0.ɵnov(_v, 6).ngClassValid; var currVal_6 = i0.ɵnov(_v, 6).ngClassInvalid; var currVal_7 = i0.ɵnov(_v, 6).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
function View_SelectModelFieldLookupComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "token-popup-section"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "div", [["class", "token-popup-section__content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 4, "div", [["class", "token-popup-list-item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 3, "div", [["class", "token-popup-list-item__inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 2, "div", [["class", "token-popup-list-item__tip"]], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, [" ", " "])), i0.ɵppd(6, 1)], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i0.ɵnov(_v.parent.parent, 0), "Nothing found")); _ck(_v, 5, 0, currVal_0); }); }
function View_SelectModelFieldLookupComponent_11(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "token-popup-list-item__icon"]], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i6.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null)], function (_ck, _v) { var currVal_0 = "token-popup-list-item__icon"; var currVal_1 = ("icon-" + _v.parent.parent.parent.context.$implicit.type.icon); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_SelectModelFieldLookupComponent_12(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "token-popup-list-item__icon"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.parent.parent.context.$implicit.type.symbol; _ck(_v, 1, 0, currVal_0); }); }
function View_SelectModelFieldLookupComponent_10(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "span", [["class", "token-popup-list-item__left"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SelectModelFieldLookupComponent_11)), i0.ɵdid(2, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SelectModelFieldLookupComponent_12)), i0.ɵdid(4, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.type.icon; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_v.parent.parent.context.$implicit.type.icon; _ck(_v, 4, 0, currVal_1); }, null); }
function View_SelectModelFieldLookupComponent_9(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "token-popup-list-item token-popup-list-item_hoverable token-popup-list-item_clickable"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectedLookup.emit({ lookup: _v.parent.context.$implicit, exclude: _v.context.$implicit }) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "div", [["class", "token-popup-list-item__inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 4, "div", [["class", "token-popup-list-item__line"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SelectModelFieldLookupComponent_10)), i0.ɵdid(4, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 1, "span", [["class", "token-popup-list-item__main token-popup-list-item__main_wrap"]], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, [" ", " "]))], function (_ck, _v) { var currVal_0 = (((_v.parent.context.$implicit.type == null) ? null : _v.parent.context.$implicit.type.icon) || _v.parent.context.$implicit.type.symbol); _ck(_v, 4, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.parent.context.$implicit.type.str(undefined, undefined, _v.context.$implicit); _ck(_v, 6, 0, currVal_1); }); }
function View_SelectModelFieldLookupComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 3, null, View_SelectModelFieldLookupComponent_9)), i0.ɵdid(2, 278528, null, 0, i6.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i0.ɵpad(3, 2), i0.ɵpad(4, 1), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.excludeLookupsSupported ? _ck(_v, 3, 0, false, true) : _ck(_v, 4, 0, false)); _ck(_v, 2, 0, currVal_0); }, null); }
function View_SelectModelFieldLookupComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "div", [["class", "token-popup-section"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "token-popup-section__title"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 3, "div", [["class", "token-popup-section__title-main"]], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, [" ", " "])), i0.ɵpad(4, 1), i0.ɵppd(5, 2), (_l()(), i0.ɵeld(6, 0, null, null, 2, "div", [["class", "token-popup-section__content"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SelectModelFieldLookupComponent_8)), i0.ɵdid(8, 278528, null, 0, i6.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.filteredLookups; _ck(_v, 8, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 3, 0, _ck(_v, 5, 0, i0.ɵnov(_v.parent.parent, 0), "{0} lookups", _ck(_v, 4, 0, _co.fieldSelection.verboseName))); _ck(_v, 3, 0, currVal_0); }); }
function View_SelectModelFieldLookupComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "token-popup__item"]], [[4, "margin-top", "px"]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SelectModelFieldLookupComponent_6)), i0.ɵdid(2, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SelectModelFieldLookupComponent_7)), i0.ɵdid(4, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = !_co.filteredLookups.length; _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.filteredLookups.length; _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = (true ? 10 : null); _ck(_v, 0, 0, currVal_0); }); }
export function View_SelectModelFieldLookupComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i7.LocalizePipe, []), i0.ɵpid(0, i8.CapitalizePipe, []), i0.ɵpid(0, i9.IsSetPipe, []), (_l()(), i0.ɵeld(3, 0, null, null, 11, "div", [["class", "token-popup__item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 8, "a", [["class", "token-popup-list-item token-popup-list-item_orange"], ["href", "javascript:void(0)"], ["style", "margin-bottom: 4px;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.back.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 7, "div", [["class", "token-popup-list-item__inner token-popup-list-item__line"], ["style", "padding: 7px 4px;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "span", [["class", "token-popup-list-item__left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 0, "span", [["class", "icon-arrow_backward_2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 4, "span", [["class", "token-popup-list-item__main"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SelectModelFieldLookupComponent_1)), i0.ɵdid(10, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SelectModelFieldLookupComponent_2)), i0.ɵdid(12, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SelectModelFieldLookupComponent_3)), i0.ɵdid(14, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SelectModelFieldLookupComponent_5)), i0.ɵdid(16, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.fieldSelection.modelDescription; _ck(_v, 10, 0, currVal_0); var currVal_1 = !_co.fieldSelection.modelDescription; _ck(_v, 12, 0, currVal_1); var currVal_2 = _co.lookups.length; _ck(_v, 14, 0, currVal_2); var currVal_3 = _co.lookups.length; _ck(_v, 16, 0, currVal_3); }, null); }
export function View_SelectModelFieldLookupComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-select-model-field-lookup", [], null, null, null, View_SelectModelFieldLookupComponent_0, RenderType_SelectModelFieldLookupComponent)), i0.ɵdid(1, 770048, null, 0, i10.SelectModelFieldLookupComponent, [i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SelectModelFieldLookupComponentNgFactory = i0.ɵccf("app-select-model-field-lookup", i10.SelectModelFieldLookupComponent, View_SelectModelFieldLookupComponent_Host_0, { fieldSelection: "fieldSelection", lookups: "lookups", excludeLookupsSupported: "excludeLookupsSupported" }, { selectedLookup: "selectedLookup", back: "back" }, []);
export { SelectModelFieldLookupComponentNgFactory as SelectModelFieldLookupComponentNgFactory };
