var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormControl, FormGroup } from '@angular/forms';
import { map } from 'rxjs/operators';
import { controlValue, isSet } from '@shared';
import { NumberControl } from './number.control';
var CornersControl = /** @class */ (function (_super) {
    __extends(CornersControl, _super);
    function CornersControl(value) {
        if (value === void 0) { value = {}; }
        var _this = _super.call(this, {
            per_corners: new FormControl([value.topLeft, value.topRight, value.bottomRight, value.bottomLeft].some(function (item) { return isSet(item); })),
            all: new NumberControl(value.all),
            top_left: new NumberControl(value.topLeft),
            top_right: new NumberControl(value.topRight),
            bottom_right: new NumberControl(value.bottomRight),
            bottom_left: new NumberControl(value.bottomLeft)
        }) || this;
        _this.cornersControls = [
            _this.controls.top_left,
            _this.controls.top_right,
            _this.controls.bottom_right,
            _this.controls.bottom_left
        ];
        _this.cornerControls = [_this.controls.all].concat(_this.cornersControls);
        return _this;
    }
    CornersControl.prototype.deserialize = function (value) {
        if (value) {
            this.patchValue({
                per_corners: [value.topLeft, value.topRight, value.bottomRight, value.bottomLeft].some(function (item) { return isSet(item); }),
                all: value.all,
                top_left: value.topLeft,
                top_right: value.topRight,
                bottom_right: value.bottomRight,
                bottom_left: value.bottomLeft
            });
        }
        else {
            this.patchValue({
                per_corners: false,
                all: undefined,
                top_left: undefined,
                top_right: undefined,
                bottom_right: undefined,
                bottom_left: undefined
            });
        }
    };
    CornersControl.prototype.isSet = function () {
        return this.cornerControls.some(function (item) { return isSet(item.value); });
    };
    CornersControl.prototype.isSet$ = function () {
        var _this = this;
        return controlValue(this).pipe(map(function () { return _this.isSet(); }));
    };
    CornersControl.prototype.setPerCorners = function (value) {
        var _this = this;
        if (this.controls.per_corners.value == value) {
            return;
        }
        this.controls.per_corners.patchValue(value);
        if (value && isSet(this.controls.all.value) && this.cornersControls.every(function (item) { return !isSet(item.value); })) {
            this.cornersControls.forEach(function (control) { return control.patchValue(_this.controls.all.value); });
        }
        else if (!value && isSet(this.cornersControls[0].value) && !isSet(this.controls.all.value)) {
            this.controls.all.patchValue(this.cornersControls[0].value);
        }
    };
    CornersControl.prototype.reset = function (options) {
        this.cornerControls.forEach(function (item) {
            item.reset(options);
        });
    };
    CornersControl.prototype.resetDefaults = function (options) {
        this.controls.per_corners.patchValue(false, options);
        this.controls.all.patchValue(16, options);
    };
    CornersControl.prototype.serialize = function () {
        if (!this.isSet()) {
            return;
        }
        if (this.controls.per_corners.value) {
            return {
                topLeft: this.controls.top_left.value || 0,
                topRight: this.controls.top_right.value || 0,
                bottomRight: this.controls.bottom_right.value || 0,
                bottomLeft: this.controls.bottom_left.value || 0
            };
        }
        else {
            return {
                all: this.controls.all.value || 0
            };
        }
    };
    CornersControl.prototype.serialize$ = function () {
        var _this = this;
        return controlValue(this, { debounce: 200 }).pipe(map(function () { return _this.serialize(); }));
    };
    return CornersControl;
}(FormGroup));
export { CornersControl };
