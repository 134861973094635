import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';

import { localize } from '@common/localize';
import { NotificationService } from '@common/notifications';
import { AppConfigService } from '@core';
import { GoogleAnalyticsService, UniversalAnalyticsService } from '@modules/analytics';
import { ApiService } from '@modules/api';
import { AuthService } from '@modules/auth';
import { MetaService } from '@modules/meta';
import {
  Field,
  ProjectInviteService,
  ProjectPublicInviteService,
  ProjectsStore,
  PublicSettings
} from '@modules/projects';
import { RoutingService } from '@modules/routing';
import { CurrentUserStore, User } from '@modules/users';

import { LoginBaseComponent } from '../login/login-base.component';
import { LoginForm } from '../login/login.form';
import { RegisterForm } from './register.form';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  providers: [LoginForm, RegisterForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RegisterComponent extends LoginBaseComponent implements OnInit, OnDestroy {
  submitLoading = false;
  domainLoading = false;
  customFields: Field[] = [];

  constructor(
    public form: RegisterForm,
    loginForm: LoginForm,
    routing: RoutingService,
    currentUserStore: CurrentUserStore,
    activatedRoute: ActivatedRoute,
    analyticsService: UniversalAnalyticsService,
    googleAnalyticsService: GoogleAnalyticsService,
    appConfigService: AppConfigService,
    authService: AuthService,
    notificationService: NotificationService,
    metaService: MetaService,
    projectsStore: ProjectsStore,
    apiService: ApiService,
    projectInviteService: ProjectInviteService,
    projectPublicInviteService: ProjectPublicInviteService,
    cd: ChangeDetectorRef
  ) {
    super(
      loginForm,
      routing,
      currentUserStore,
      activatedRoute,
      analyticsService,
      googleAnalyticsService,
      appConfigService,
      authService,
      notificationService,
      metaService,
      projectsStore,
      apiService,
      projectInviteService,
      projectPublicInviteService,
      cd
    );
  }

  ngOnInit() {
    super.ngOnInit();
    this.metaService.set({ title: localize('Create new account') });

    this.domainLoading = true;
    this.cd.markForCheck();

    combineLatest(this.activatedRoute.params, this.activatedRoute.data, this.domain$)
      .pipe(untilDestroyed(this))
      .subscribe(
        ([params, data, domain]) => {
          const publicSettings = data['public_settings'] as PublicSettings;
          const customFields = publicSettings ? publicSettings.signUpFields : [];

          this.domainLoading = false;
          this.form.init(customFields);
          this.customFields = customFields;
          this.cd.markForCheck();

          if (!params['project'] && domain && domain.defaultProject && !data['ignoreSelfSignOn']) {
            this.routing.navigate(['/register', domain.defaultProject], { queryParamsHandling: 'merge' });
          } else if (domain && domain.ssoSettings.length == 1 && domain.disableEmailSignIn) {
            const result = this.ssoLogin(domain.ssoSettings[0]);
            if (result.externalRedirect) {
              this.domainLoading = true;
              this.cd.markForCheck();
            }
          } else if (domain && domain.disableEmailSignIn) {
            if (domain && domain.defaultProject && !data['ignoreSelfSignOn']) {
              this.routing.navigate(['/login', domain.defaultProject], { queryParamsHandling: 'merge' });
            } else {
              this.routing.navigate(['/login'], { queryParamsHandling: 'merge' });
            }
          }
        },
        () => {
          this.domainLoading = false;
          this.cd.markForCheck();
        }
      );
  }

  onInviteLoaded() {
    if (this.invite) {
      this.form.patchValue({ email: this.invite.getEmail() });
    }
  }

  submit() {
    if (this.form.invalid) {
      return;
    }

    this.form.markAsSubmit();

    const projectName = this.activatedRoute.snapshot.params['project'];
    const transferProjects = this.activatedRoute.snapshot.queryParams['transfer_projects'];

    this.submitLoading = true;
    this.cd.markForCheck();

    this.form
      .register(projectName, transferProjects ? transferProjects.split(',') : undefined)
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          this.onRegister(result.user, result.selfSignOn);
        },
        () => {
          this.submitLoading = false;
          this.cd.markForCheck();
        }
      );
  }

  onRegister(user: User, selfSignOn: boolean) {
    this.onLogin({ userCreated: true, selfSignOn: selfSignOn });
  }

  get signInLink() {
    const projectName = this.activatedRoute.snapshot.params['project'];
    const ignoreSelfSignOn = this.activatedRoute.snapshot.data['ignoreSelfSignOn'];

    if (projectName) {
      return ['/login', projectName];
    } else if (ignoreSelfSignOn) {
      return ['/user', 'login'];
    } else {
      return ['/login'];
    }
  }

  cleanErrorForm() {
    this.form.setErrors({});
  }
}
