<div
  class="list"
  [class.list_scrollable]="scrollable"
  [class.list_flexible]="!scrollable"
  [class.list_theme]="theme"
  appElement
  [appElementDetectVisible]="{ intervalCheck: 600 }"
  (appElementFirstVisible)="firstVisible$.next(true)"
>
  <div class="list__header" [class.list__header_active]="settings.header">
    <app-list-header
      *ngIf="settings.header"
      [noBottomMargin]="true"
      [params]="params"
      [filtersEnabled]="settings.displayFilters"
      [filters]="listState.filters"
      [filterFields]="settings.filterFields"
      [hideParams]="hideParams"
      [pageSettings]="pageSettings"
      [settings]="settings"
      [dataSource]="listState.dataSource"
      [inputs]="inputs"
      [parameters]="parameters"
      [moreLink]="moreLink"
      [focus]="focus"
      [searchEnabled]="settings.searchEnabled || settings.searchQuery != undefined"
      [search]="listState.search"
      [searchLive]="settings.searchLive"
      [createSegmentAllowed]="createSegmentAllowed"
      [viewId]="viewId"
      [context]="context"
      [contextElement]="contextElement"
      [accentColor]="accentColor"
      [theme]="theme"
      (paramsChanged)="paramsChanged.emit($event)"
      (filtersUpdated)="onFiltersUpdated($event)"
      (searchUpdated)="onSearchUpdated($event)"
      (layoutAdd)="layoutAdd.emit($event)"
      (layoutCustomize)="layoutCustomize.emit($event)"
      (segmentCreated)="segmentCreated.emit($event)"
      (updateData)="reloadData()"
      (customizeModelDescription)="customizeModelDescription()"
      (customizeViewSettings)="customizeViewSettings()"
    >
    </app-list-header>
  </div>

  <div class="list__content">
    <div class="list__viewport" #scrollable_element>
      <div class="list__component list__map" #container>
        <app-error *ngIf="!configured" [title]="'Not configured'" [fill]="true" [compact]="true" [theme]="theme">
          Unfortunately, this component is not configured yet
        </app-error>

        <ng-container *ngIf="configured">
          <div class="list__map-inner">
            <agm-map
              [latitude]="latitude"
              [longitude]="longitude"
              [zoom]="viewportZoom"
              [backgroundColor]="'transparent'"
              [scrollwheel]="scrollable"
              [styles]="mapStyles"
              (boundsChange)="boundsChanged$.next($event)"
            >
              <agm-marker-cluster [styles]="markersClusterStyles">
                <app-map-marker
                  *ngFor="let item of markers; trackBy: trackByFn; let i = index"
                  [settings]="settings"
                  [item]="item"
                  [modelDescription]="listState.modelDescription"
                  [windowOpen]="isInfoWindowOpen(i)"
                  [visibleColumns]="visibleColumns"
                  [context]="context"
                  [contextElement]="contextElement"
                  [accentColor]="accentColor"
                  [theme]="theme"
                  (modelUpdated)="onModelUpdated($event)"
                  (openWindow)="openWindow(i)"
                  (closeWindow)="closeWindow(i)"
                ></app-map-marker>
              </agm-marker-cluster>
            </agm-map>

            <div *ngIf="listStore.loading$ | async" class="list__map-loader">
              <app-loader [fill]="true"></app-loader>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
