import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { TextStyleControl } from '../text-style-edit/text-style.control';

@Component({
  selector: 'app-text-style-edit-field-action',
  templateUrl: './text-style-edit-field-action.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextStyleEditFieldActionComponent implements OnInit {
  @Input() control: TextStyleControl;
  @Input() tip = 'Customize Text style';

  styleIsDefault: Observable<boolean>;

  constructor() {}

  ngOnInit() {
    this.styleIsDefault = this.control.isDefaultStyle$();
  }
}
