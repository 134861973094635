<div
  class="model-card"
  [class.model-card_vertical]="vertical"
  [class.model-card_compact]="compact"
  [class.model-card_theme]="theme"
>
  <div *ngFor="let column of visibleColumns; trackBy: trackByFn; let first = first; let last = last">
    <div
      *ngIf="column.field == fieldTypes.Image"
      class="model-card__image"
      [class.model-card__image_first]="first"
      [class.model-card__image_last]="last"
    >
      <app-image-field
        [field]="createField(column)"
        [readonly]="true"
        [value]="item.model.getAttribute(column.name)"
        [label]="false"
        [context]="contextElement?.context"
      >
      </app-image-field>
    </div>

    <app-model-card-item
      *ngIf="column.field != fieldTypes.Image"
      [item]="column"
      [model]="item.model"
      [columns]="columns"
      [valueStr]="getValueStr(column)"
      [label]="labels"
      [columnActions]="columnActions"
      [context]="context"
      [contextElement]="contextElement"
    ></app-model-card-item>
  </div>
</div>
