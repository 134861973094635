var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, NgZone, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, of } from 'rxjs';
import { distinctUntilChanged, filter, first, map, switchMap } from 'rxjs/operators';
import { DialogService } from '@common/dialogs';
import { FeatureService } from '@modules/features';
import { CurrentProjectStore } from '@modules/projects';
import { ascComparator, isSet } from '@shared';
export var StyleType;
(function (StyleType) {
    StyleType["TextStyle"] = "text_style";
    StyleType["IconSettings"] = "icon_settings";
    StyleType["Fill"] = "fill";
    StyleType["BorderSettings"] = "border_settings";
    StyleType["Corners"] = "corners";
    StyleType["Shadow"] = "shadow";
    StyleType["Margin"] = "margin";
})(StyleType || (StyleType = {}));
var StylesEditComponent = /** @class */ (function () {
    function StylesEditComponent(currentProjectStore, featureService, dialogService, zone, cd) {
        this.currentProjectStore = currentProjectStore;
        this.featureService = featureService;
        this.dialogService = dialogService;
        this.zone = zone;
        this.cd = cd;
        this.styles = [];
        this.showEmpty = false;
        this.enabledStyles = [];
        this.enabledStylesGroups = [];
        this.styleTypes = StyleType;
    }
    StylesEditComponent.prototype.trackStyleGroupFn = function (i, item) {
        return item.label;
    };
    StylesEditComponent.prototype.trackStyleFn = function (i, item) {
        return item.control;
    };
    StylesEditComponent.prototype.ngOnInit = function () { };
    StylesEditComponent.prototype.ngOnDestroy = function () { };
    StylesEditComponent.prototype.ngOnChanges = function (changes) {
        if (changes.styles) {
            this.updateEnabledStyles();
        }
    };
    StylesEditComponent.prototype.updateEnabledStyles = function () {
        var _this = this;
        var styles$ = this.styles
            .reduce(function (acc, item) {
            if (item.style) {
                acc.push(item.style);
            }
            else if (item.children) {
                var group_1 = { label: item.label, icon: item.icon };
                item.children.forEach(function (child) {
                    child.group = group_1;
                });
                acc.push.apply(acc, item.children);
            }
            return acc;
        }, [])
            .map(function (item) {
            return item.isSet$(item.control).pipe(map(function (value) { return !!value; }), distinctUntilChanged(), map(function (styleIsSet) {
                if (styleIsSet) {
                    return item;
                }
                else if (_this.enabledStyles.find(function (enabledItem) { return enabledItem.control === item.control; })) {
                    return item;
                }
            }));
        });
        (styles$.length ? combineLatest.apply(void 0, styles$) : of([])).pipe(untilDestroyed(this)).subscribe(function (result) {
            _this.enabledStyles = result
                .filter(function (item) { return item; })
                .sort(function (lhs, rhs) {
                var lhsIndex = _this.enabledStyles.findIndex(function (enabledItem) { return enabledItem.control === lhs.control; });
                var rhsIndex = _this.enabledStyles.findIndex(function (enabledItem) { return enabledItem.control === rhs.control; });
                if (lhsIndex === -1 && rhsIndex === -1) {
                    return 0;
                }
                else if (lhsIndex === -1 && rhsIndex !== -1) {
                    return 1;
                }
                else if (lhsIndex !== -1 && rhsIndex === -1) {
                    return -1;
                }
                else {
                    return ascComparator(lhsIndex, rhsIndex);
                }
            });
            _this.cd.markForCheck();
            _this.updateGroups();
        });
    };
    StylesEditComponent.prototype.updateGroups = function () {
        var _this = this;
        var groups = this.enabledStyles.reduce(function (acc, item) {
            var key = isSet(item.group) ? item.group.label : '';
            if (!acc[key]) {
                acc[key] = __assign({}, (item.group && { label: item.group.label, icon: item.group.icon }), { styles: [] });
            }
            acc[key].styles.push(item);
            return acc;
        }, {});
        var groupsOrder = this.styles.filter(function (item) { return isSet(item.label); });
        this.enabledStylesGroups = [{}].concat(groupsOrder).map(function (group) {
            var key = isSet(group.label) ? group.label : '';
            if (!groups[key]) {
                return __assign({}, group, { styles: [] });
            }
            return groups[key];
        })
            .filter(function (item) { return _this.showEmpty || !item.label || item.styles.length; });
        this.enabledStylesGroups.forEach(function (group) {
            var styleItem = group.label ? _this.styles.find(function (item) { return item.label == group.label; }) : undefined;
            if (styleItem && styleItem.children) {
                group.allStyles = styleItem.children;
            }
        });
        this.cd.markForCheck();
    };
    StylesEditComponent.prototype.addStyle = function (style) {
        var _this = this;
        this.enabledStyles = this.enabledStyles.concat([style]);
        this.addedStyle = style;
        this.cd.markForCheck();
        this.updateGroups();
        if (style.onAdd) {
            style.onAdd(style);
        }
        this.zone.onStable.pipe(first(), untilDestroyed(this)).subscribe(function () {
            _this.addedStyle = undefined;
        });
    };
    StylesEditComponent.prototype.isAddStyleEnabled = function (style) {
        return !this.enabledStyles.includes(style);
    };
    StylesEditComponent.prototype.removeStyle = function (style) {
        var _this = this;
        style
            .isSet$(style.control)
            .pipe(first(), switchMap(function (styleIsSet) {
            if (!styleIsSet) {
                return of(true);
            }
            return _this.dialogService.warning({
                title: 'Remove style',
                description: "Are you sure want to remove style <strong>\"" + style.label + "\"</strong>?",
                style: 'orange'
            });
        }), filter(function (result) { return result == true; }), untilDestroyed(this))
            .subscribe(function () {
            _this.removeStyleProcess(style);
        });
    };
    StylesEditComponent.prototype.removeStyleProcess = function (style) {
        this.enabledStyles = this.enabledStyles.filter(function (item) { return item !== style; });
        this.cd.markForCheck();
        this.updateGroups();
        if (style.onRemove) {
            style.onRemove(style);
        }
    };
    StylesEditComponent.prototype.asAny = function (value) {
        return value;
    };
    StylesEditComponent.prototype.showFeatureOverview = function () {
        this.featureService.showFeatureOverview({
            subtitle: 'Paid Feature',
            title: 'Customize <strong>App Styles</strong>',
            description: "\n        You can customize various App styles, including background, text, colors, borders, shadows, and more,\n        giving you greater flexibility to design your App to match your brand's aesthetics.\n      "
        });
    };
    return StylesEditComponent;
}());
export { StylesEditComponent };
