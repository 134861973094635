<div class="calendar-day">
  <div class="calendar-day__table-wrap">
    <div class="calendar-day-table__timeline"></div>
    <table class="calendar-day-table">
      <tr class="calendar-day-table__tr">
        <th class="calendar-day-table__th">
          <span class="calendar-day-table__arrow"></span>
          <div *ngIf="true" class="calendar-day-table__timeline-time"></div>
        </th>
        <th class="calendar-day-table__th">
          <div class="calendar-day-table__header">
            <p class="calendar-day-table__week-day">{{ date.format('dddd') }}</p>
            <p *ngIf="listStore.count$ | async" class="calendar-day-table__count-orders">
              {{ listStore.count$ | async }} {{ title }}
            </p>
          </div>
        </th>
      </tr>
      <tr *ngFor="let hour of hours; let h = index" class="calendar-day-table__tr">
        <td class="calendar-day-table__td">
          <span *ngIf="h > 0" class="calendar-day-table__time">{{ hour.date.format('LT') }}</span>
        </td>
        <td class="calendar-day-table__td">
          <div *ngIf="items[hour.key] as items" class="calendar-day-table__cards">
            <app-calendar-item
              *ngFor="let item of items; let i = index"
              class="calendar-day-table__cards-item"
              [item]="item"
              [listState]="listState"
              [modelDescription]="modelDescription"
              [settings]="settings"
              [visibleColumns]="visibleColumns"
              [context]="context"
              [contextElement]="contextElement"
              [selected]="isItemSelected(item, i)"
              [theme]="theme"
              (select)="toggleSelectedItem(item, i, $event.element, true)"
              (modelUpdated)="onModelUpdated($event)"
            ></app-calendar-item>
          </div>
        </td>
      </tr>
    </table>
  </div>
  <div class="calendar-aside">
    <div class="calendar-aside__item" [class.calendar-aside__item_disabled]="monthItemsLoading">
      <div class="calendar-month-small-header">
        {{ date.format('MMMM, YYYY') }}
      </div>
      <table class="calendar-month-small">
        <tr class="calendar-month-small__tr">
          <th *ngFor="let day of weekDays" class="calendar-month-small__th">{{ day.date.format('dd') }}</th>
        </tr>
        <tr *ngFor="let week of weeks" class="calendar-month-small__tr">
          <td *ngFor="let day of week; let i = index" class="calendar-month-small__td">
            <div
              (click)="setParams(dayParams(day.date))"
              class="calendar-month-small__date-number"
              [class.calendar-month-small__date-number--highlighted]="day.today"
              [class.calendar-month-small__date-number--active]="day.current"
              [class.calendar-month-small__date-number--disabled]="!day.currentMonth"
              [class.calendar-month-small__date-number--future]="false"
              [appTintColor]="'accentColor' | appThemeColorHexOption: true | async"
              [appTintColorStyle]="tintStyles.Primary"
            >
              {{ day.date.format('D') }}
              <div *ngIf="monthItems[day.date.format(monthItemsGroupBy)]" class="calendar-month-small__status">
                <span class="calendar-month-small__status-item calendar-month-small__status-item--blue"></span>
              </div>
            </div>
          </td>
        </tr>
      </table>
    </div>
    <!--<div class="calendar-aside__item">-->
    <!--<div class="calendar-aside__item-header">-->
    <!--<p class="calendar-aside__item-caption">Popular <span class="calendar-aside__item-caption-span">Goods</span>-->
    <!--</p>-->
    <!--<button class="calendar-aside__item-menu" type="button"><span class="calendar-aside__item-menu-point"></span><span class="calendar-aside__item-menu-point"></span><span class="calendar-aside__item-menu-point"></span></button>-->
    <!--</div>-->
    <!--<ul class="calendar-popular-list">-->
    <!--<li class="calendar-popular-list__item"><a class="calendar-popular-list__link" href="#">-->
    <!--<figure class="calendar-popular-list__img"></figure>-->
    <!--<div class="calendar-popular-list__text">-->
    <!--<p class="calendar-popular-list__name">iPhone 8</p>-->
    <!--<p class="calendar-popular-list__count">21</p>-->
    <!--</div></a></li>-->
    <!--<li class="calendar-popular-list__item"><a class="calendar-popular-list__link" href="#">-->
    <!--<figure class="calendar-popular-list__img"></figure>-->
    <!--<div class="calendar-popular-list__text">-->
    <!--<p class="calendar-popular-list__name">Apple Homepod</p>-->
    <!--<p class="calendar-popular-list__count">19</p>-->
    <!--</div></a></li>-->
    <!--<li class="calendar-popular-list__item"><a class="calendar-popular-list__link" href="#">-->
    <!--<figure class="calendar-popular-list__img"></figure>-->
    <!--<div class="calendar-popular-list__text">-->
    <!--<p class="calendar-popular-list__name">Mac Pro</p>-->
    <!--<p class="calendar-popular-list__count">17</p>-->
    <!--</div></a></li>-->
    <!--<li class="calendar-popular-list__item"><a class="calendar-popular-list__link" href="#">-->
    <!--<figure class="calendar-popular-list__img"></figure>-->
    <!--<div class="calendar-popular-list__text">-->
    <!--<p class="calendar-popular-list__name">iMac Pro</p>-->
    <!--<p class="calendar-popular-list__count">5</p>-->
    <!--</div></a></li>-->
    <!--</ul>-->
    <!--</div>-->
    <!--<div class="calendar-aside__item">-->
    <!--<div class="calendar-aside__item-header">-->
    <!--<p class="calendar-aside__item-caption">Delivery <span class="calendar-aside__item-caption-span">Goods</span>-->
    <!--</p>-->
    <!--<button class="calendar-aside__item-menu" type="button"><span class="calendar-aside__item-menu-point"></span><span class="calendar-aside__item-menu-point"></span><span class="calendar-aside__item-menu-point"></span></button>-->
    <!--</div>-->
    <!--<ul class="calendar-popular-list">-->
    <!--<li class="calendar-popular-list__item"><a class="calendar-popular-list__link" href="#">-->
    <!--<figure class="calendar-popular-list__img"></figure>-->
    <!--<div class="calendar-popular-list__text">-->
    <!--<p class="calendar-popular-list__name">iPhone 8</p>-->
    <!--<p class="calendar-popular-list__count">21</p>-->
    <!--</div></a></li>-->
    <!--<li class="calendar-popular-list__item"><a class="calendar-popular-list__link" href="#">-->
    <!--<figure class="calendar-popular-list__img"></figure>-->
    <!--<div class="calendar-popular-list__text">-->
    <!--<p class="calendar-popular-list__name">Apple Homepod</p>-->
    <!--<p class="calendar-popular-list__count">19</p>-->
    <!--</div></a></li>-->
    <!--<li class="calendar-popular-list__item"><a class="calendar-popular-list__link" href="#">-->
    <!--<figure class="calendar-popular-list__img"></figure>-->
    <!--<div class="calendar-popular-list__text">-->
    <!--<p class="calendar-popular-list__name">Mac Pro</p>-->
    <!--<p class="calendar-popular-list__count">17</p>-->
    <!--</div></a></li>-->
    <!--<li class="calendar-popular-list__item"><a class="calendar-popular-list__link" href="#">-->
    <!--<figure class="calendar-popular-list__img"></figure>-->
    <!--<div class="calendar-popular-list__text">-->
    <!--<p class="calendar-popular-list__name">iMac Pro</p>-->
    <!--<p class="calendar-popular-list__count">5</p>-->
    <!--</div></a></li>-->
    <!--</ul>-->
    <!--</div>-->
  </div>
</div>

<div *ngIf="loading" class="calendar-loader">
  <div class="calendar-loader__background"></div>
  <div class="calendar-loader__content">
    <app-loader [fill]="true"></app-loader>
  </div>
</div>
