var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { TextDecoration, TextTransform } from '@modules/styles';
import { Border } from '../border';
import { Color } from '../color';
import { Fill } from '../fill';
import { Font } from '../font';
import { Layer } from '../layer';
import { LayerType } from '../layer-type';
import { registerLayerType } from '../layer-types';
import { Shadow } from '../shadow';
export var AlignHorizontal;
(function (AlignHorizontal) {
    AlignHorizontal["Justify"] = "justify";
    AlignHorizontal["Left"] = "left";
    AlignHorizontal["Center"] = "center";
    AlignHorizontal["Right"] = "right";
})(AlignHorizontal || (AlignHorizontal = {}));
export var AlignVertical;
(function (AlignVertical) {
    AlignVertical["Top"] = "top";
    AlignVertical["Center"] = "center";
    AlignVertical["Bottom"] = "bottom";
})(AlignVertical || (AlignVertical = {}));
export var TextTruncate;
(function (TextTruncate) {
    TextTruncate["SingleLine"] = "single_line";
    TextTruncate["Custom"] = "custom";
})(TextTruncate || (TextTruncate = {}));
var TextLayer = /** @class */ (function (_super) {
    __extends(TextLayer, _super);
    function TextLayer(options) {
        if (options === void 0) { options = {}; }
        var _this = _super.call(this, options) || this;
        _this.type = LayerType.Text;
        _this.icon = 'text_2';
        _this.defaultName = 'text';
        _this.fills = [];
        _this.borders = [];
        _this.shadows = [];
        _this.alignHorizontal = AlignHorizontal.Left;
        _this.alignVertical = AlignVertical.Top;
        return _this;
    }
    TextLayer.prototype.deserialize = function (data) {
        _super.prototype.deserialize.call(this, data);
        this.lineHeight = data['line_height'];
        this.letterSpacing = data['letter_spacing'];
        this.alignHorizontal = data['align_horizontal'];
        this.alignVertical = data['align_vertical'];
        this.transform = data['transform'];
        this.decoration = data['decoration'];
        this.truncate = data['truncate'];
        this.truncateMaxLines = data['truncate_max_lines'];
        if (data['width_fluid']) {
            this.widthFluid = data['width_fluid'];
        }
        if (data['height_fluid']) {
            this.heightFluid = data['height_fluid'];
        }
        if (data['quill_delta']) {
            this.quillDelta = data['quill_delta'];
        }
        if (data['font']) {
            this.font = new Font().deserialize(data['font']);
        }
        if (data['fills']) {
            this.fills = data['fills'].map(function (item) { return new Fill().deserialize(item); });
        }
        else if (data['color']) {
            // Backward compatibility
            var fill = new Fill();
            fill.generateId();
            fill.color = new Color().deserialize(data['color']);
            this.fills = [fill];
        }
        if (data['borders']) {
            this.borders = data['borders'].map(function (item) { return new Border().deserialize(item); });
        }
        else if (data['border']) {
            // Backward compatibility
            this.borders = [new Border().deserialize(data['border'])];
        }
        if (data['shadows']) {
            this.shadows = data['shadows'].map(function (item) { return new Shadow().deserialize(item); });
        }
        return this;
    };
    TextLayer.prototype.serialize = function () {
        return __assign({}, _super.prototype.serialize.call(this), { width_fluid: this.widthFluid, height_fluid: this.heightFluid, quill_delta: this.quillDelta, font: this.font ? this.font.serialize() : undefined, fills: this.fills.map(function (item) { return item.serialize(); }), borders: this.borders.map(function (item) { return item.serialize(); }), shadows: this.shadows.map(function (item) { return item.serialize(); }), line_height: this.lineHeight, letter_spacing: this.letterSpacing, align_horizontal: this.alignHorizontal, align_vertical: this.alignVertical, transform: this.transform, decoration: this.decoration, truncate: this.truncate, truncate_max_lines: this.truncateMaxLines });
    };
    TextLayer.prototype.setText = function (text) {
        this.quillDelta = { ops: [{ insert: text }] };
    };
    TextLayer.prototype.cssTextAlign = function () {
        if (this.alignHorizontal == AlignHorizontal.Left) {
            return 'left';
        }
        else if (this.alignHorizontal == AlignHorizontal.Center) {
            return 'center';
        }
        else if (this.alignHorizontal == AlignHorizontal.Right) {
            return 'right';
        }
        else if (this.alignHorizontal == AlignHorizontal.Justify) {
            return 'justify';
        }
    };
    TextLayer.prototype.cssTextTransform = function () {
        if (this.transform == TextTransform.Lowercase) {
            return 'lowercase';
        }
        else if (this.transform == TextTransform.Uppercase) {
            return 'uppercase';
        }
        else if (this.transform == TextTransform.Capitalize) {
            return 'capitalize';
        }
    };
    TextLayer.prototype.cssTextDecoration = function () {
        if (this.decoration == TextDecoration.Underline) {
            return 'underline';
        }
        else if (this.decoration == TextDecoration.Strikethrough) {
            return 'line-through';
        }
    };
    return TextLayer;
}(Layer));
export { TextLayer };
registerLayerType(LayerType.Text, TextLayer);
