var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { FormControl, FormGroup } from '@angular/forms';
import isEqual from 'lodash/isEqual';
import { map } from 'rxjs/operators';
import { Input, isInputSet, MarkupType } from '@modules/fields';
import { controlValue } from '@shared';
import { singleTokenFormulaToContextValue } from '../utils/formula';
export function FieldInputRequiredValidator(control) {
    if (!isInputSet(control.value)) {
        return { required: true };
    }
}
var FieldInputControl = /** @class */ (function (_super) {
    __extends(FieldInputControl, _super);
    function FieldInputControl(formState, validatorOrOpts, asyncValidator) {
        if (formState === void 0) { formState = {}; }
        var _this = this;
        var controls = {
            path: new FormControl([]),
            lookup: new FormControl(undefined),
            exclude: new FormControl(false),
            value_type: new FormControl(null),
            static_value: new FormControl(''),
            context_value: new FormControl(undefined),
            filter_field: new FormControl(''),
            filter_lookup: new FormControl(''),
            formula_value: new FormControl(''),
            text_inputs_type: new FormControl(MarkupType.Text),
            text_inputs_value: new FormControl(''),
            js_value: new FormControl(''),
            required: new FormControl(false)
        };
        _this = _super.call(this, controls, validatorOrOpts, asyncValidator) || this;
        formState = _this.cleanValue(formState);
        _this.patchValue(formState, { emitEvent: false });
        return _this;
    }
    FieldInputControl.prototype.deserialize = function (item) {
        this.instance = item;
        this.controls.path.patchValue(item.path);
        this.controls.lookup.patchValue(item.lookup);
        this.controls.exclude.patchValue(item.exclude);
        this.controls.value_type.patchValue(item.valueType);
        this.controls.static_value.patchValue(item.staticValue);
        this.controls.context_value.patchValue(item.contextValue);
        this.controls.filter_field.patchValue(item.filterField);
        this.controls.filter_lookup.patchValue(item.filterLookup);
        this.controls.formula_value.patchValue(item.formulaValue);
        this.controls.text_inputs_type.patchValue(item.textInputsType);
        this.controls.text_inputs_value.patchValue(item.textInputsValue);
        this.controls.js_value.patchValue(item.jsValue);
        this.controls.required.patchValue(item.required);
        this.markAsPristine();
    };
    FieldInputControl.prototype.serialize = function () {
        var result = new Input();
        result.path = this.controls.path.value;
        result.lookup = this.controls.lookup.value;
        result.exclude = this.controls.exclude.value;
        result.valueType = this.controls.value_type.value;
        result.staticValue = this.controls.static_value.value;
        result.contextValue = this.controls.context_value.value;
        result.filterField = this.controls.filter_field.value;
        result.filterLookup = this.controls.filter_lookup.value;
        result.formulaValue = this.controls.formula_value.value;
        result.textInputsType = this.controls.text_inputs_type.value;
        result.textInputsValue = this.controls.text_inputs_value.value;
        result.jsValue = this.controls.js_value.value;
        result.required = this.controls.required.value;
        return result;
    };
    FieldInputControl.prototype.patchValue = function (value, options) {
        value = this.cleanValue(value);
        _super.prototype.patchValue.call(this, value, options);
    };
    FieldInputControl.prototype.setValue = function (value, options) {
        value = this.cleanValue(value);
        _super.prototype.setValue.call(this, value, options);
    };
    FieldInputControl.prototype.cleanValue = function (value) {
        if (!value) {
            return value;
        }
        // Backward compatibility
        if (typeof value['context_value'] === 'string') {
            value = __assign({}, value, { context_value: singleTokenFormulaToContextValue(value['context_value']) });
        }
        return value;
    };
    FieldInputControl.prototype.clearValue = function (value) {
        if (value === void 0) { value = {}; }
        this.patchValue(__assign({ path: [], value_type: null, static_value: '', context_value: [], filter_field: '', filter_lookup: '', formula_value: '', text_inputs_type: MarkupType.Text, text_inputs_value: undefined, js_value: '', required: false }, value));
    };
    FieldInputControl.prototype.isSet$ = function () {
        return controlValue(this).pipe(map(function (value) { return isInputSet(value); }));
    };
    FieldInputControl.prototype.getInstance$ = function () {
        var _this = this;
        return controlValue(this).pipe(map(function () { return _this.serialize(); }));
    };
    FieldInputControl.prototype.isEqual = function (options) {
        return (isEqual(this.controls.path.value, options.path) &&
            this.controls.lookup.value == options.lookup &&
            !!this.controls.exclude.value == !!options.exclude);
    };
    FieldInputControl.prototype.isName = function (name) {
        return this.isEqual({ path: [name] });
    };
    FieldInputControl.prototype.isPath = function (path) {
        return this.isEqual({ path: path });
    };
    return FieldInputControl;
}(FormGroup));
export { FieldInputControl };
