<div
  class="text-layer"
  (mouseenter)="onMouseEnter()"
  (mouseleave)="onMouseLeave()"
  (mouseup)="onMouseUp()"
  (click)="onMouseClick($event)"
  (dblclick)="startEditing()"
>
  <div
    class="text-layer__content"
    [class.text-layer__content_vertical-align_top]="layer.alignVertical == alignVertical.Top"
    [class.text-layer__content_vertical-align_center]="layer.alignVertical == alignVertical.Center"
    [class.text-layer__content_vertical-align_bottom]="layer.alignVertical == alignVertical.Bottom"
    [style.cursor]="layer.getCursor() || null"
    #layer_element
  >
    <app-layer-interaction
      *ngFor="let item of layer.interactions"
      [interaction]="item"
      [element]="layer_element"
      [context]="viewContext"
      [contextElement]="contextElement"
      [analyticsSource]="analyticsSource"
    ></app-layer-interaction>

    <div
      class="text-layer__text"
      [class.text-layer__text_disable-selecton]="customizeEnabled || layer.hasDisableTextSelectionInteraction()"
      [class.text-layer__text_editing]="customizing$ | async"
      [class.text-layer__text_align_left]="layer.alignHorizontal == alignHorizontal.Left"
      [class.text-layer__text_align_center]="layer.alignHorizontal == alignHorizontal.Center"
      [class.text-layer__text_align_right]="layer.alignHorizontal == alignHorizontal.Right"
      [class.text-layer__text_align_justify]="layer.alignHorizontal == alignHorizontal.Justify"
      [class.text-layer__text_truncate]="layer.truncate == textTruncates.SingleLine"
      [class.text-layer__text_truncate-multi]="layer.truncate == textTruncates.Custom"
      [style.font-family]="fontFamily || null"
      [style.font-weight]="layer.font?.weight || null"
      [style.font-size.px]="layer.font?.size || null"
      [style.font-style]="fontStyle || null"
      [style.color]="color"
      [style.opacity]="layer.opacity"
      [style.-webkit-text-stroke]="textStroke"
      [style.-webkit-line-clamp]="
        layer.truncate == textTruncates.Custom && (layer.truncateMaxLines | appIsSet) ? layer.truncateMaxLines : null
      "
      [style.line-height.px]="(layer.lineHeight | appIsSet) ? layer.lineHeight : null"
      [style.letter-spacing.em]="(layer.letterSpacing | appIsSet) ? layer.letterSpacing : null"
      [style.text-shadow]="textShadow"
      [style.text-transform]="textTransform"
      [style.text-decoration]="textDecoration"
      #text_element
    >
      <div
        *ngIf="!editorContext"
        class="ql-container ql-snow ql-text-paragraphs ql-text-paragraphs_inherit-lines ql-disabled"
        [style.user-select]="layer.hasDisableTextSelectionInteraction() ? null : 'text'"
        [innerHTML]="html$ | async"
      >
        <div class="ql-editor" [innerHTML]="html$ | async"></div>
      </div>

      <app-text-editor
        *ngIf="editorContext"
        [value]="layer.quillDelta"
        [editable]="(customizing$ | async) && editing"
        [textParagraphs]="true"
        [textParagraphsInheritLines]="true"
        [toolbarElement]="toolbarElement"
        [toolbarHeadersEnabled]="false"
        [toolbarAlignEnabled]="false"
        [context]="viewContext"
        [schema]="(themeService.isDarkTheme$ | async) ? 'light' : 'dark'"
        (changed)="saveQuillDelta($event)"
        (mousedown)="onTextEditorMouseDown($event)"
        (escapeClick)="onTextEditorEscapeClick()"
      ></app-text-editor>
    </div>
  </div>

  <ng-container *ngIf="editorContext">
    <ng-container *appViewEditorViewportOverlay="{ frame: layer.frame, translate: translate }">
      <app-view-editor-bounds
        *ngIf="editorContext"
        class="text-layer__bounds"
        [class.text-layer__bounds_hover]="(hover$ | async) && (!editing || editingOnCreate)"
        [class.text-layer__bounds_active]="
          (customizing$ | async) && !(editorContext.movingLayer$ | async) && (!editing || editingOnCreate)
        "
        [frame]="layer.frame"
        [translate]="translate"
        [snapFrame]="true"
        [snapFrameContainer]="container"
        [snapFrameExceptLayers]="[layer]"
        [constrainProportions]="!!layer.constrainProportion"
        [active]="customizing$ | async"
        [resizeHorizontal]="(customizing$ | async) && !(customizingMultiple$ | async)"
        [resizeVertical]="(customizing$ | async) && !(customizingMultiple$ | async)"
        (resizeStarted)="editorContext.resizingLayer$.next(layer)"
        (resizeFinished)="editorContext.resizingLayer$.next(undefined)"
        (updateFrame)="onFrameUpdate($event)"
      ></app-view-editor-bounds>

      <div
        *ngIf="(customizing$ | async) && !(editorContext.movingLayer$ | async) && editing"
        class="text-layer__toolbar ql-mini"
        (click)="onToolbarClick($event)"
        #toolbar_element
      ></div>
    </ng-container>
  </ng-container>
</div>
