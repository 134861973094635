import pickBy from 'lodash/pickBy';

export enum ProjectSettingsName {
  DefaultTheme = 'default_theme',
  Layout = 'layout',
  AccentColor = 'global_accent_color',
  AccentColorDark = 'global_accent_color_dark',
  BackgroundColor = 'background_color',
  BackgroundColorDark = 'background_color_dark',
  BackgroundColor2 = 'background_color_2',
  BackgroundColor2Dark = 'background_color_2_dark',
  BackgroundColor3 = 'background_color_3',
  BackgroundColor3Dark = 'background_color_3_dark',
  BackgroundColor4 = 'background_color_4',
  BackgroundColor4Dark = 'background_color_4_dark',
  BackgroundColor5 = 'background_color_5',
  BackgroundColor5Dark = 'background_color_5_dark',
  TextColor = 'text_color',
  TextColorDark = 'text_color_dark',
  TextColor2 = 'text_color_2',
  TextColor2Dark = 'text_color_2_dark',
  TextColor3 = 'text_color_3',
  TextColor3Dark = 'text_color_3_dark',
  BorderColor = 'border_color',
  BorderColorDark = 'border_color_dark',
  BorderColor2 = 'border_color_2',
  BorderColor2Dark = 'border_color_2_dark',
  BorderColor3 = 'border_color_3',
  BorderColor3Dark = 'border_color_3_dark',
  BorderRadius = 'border_radius',
  AutoColors = 'auto_colors',
  AutoColorsDark = 'auto_colors_dark',
  MaxWidth = 'max_width',
  Padding = 'padding',
  FontRegular = 'font_regular',
  FontHeading = 'font_heading',
  FontsUsed = 'fonts_used',
  AppendScripts = 'append_scripts',
  AppendStyles = 'append_styles',
  CollaborationEnabled = 'collaboration_enabled',
  ActivityLogEnabled = 'activity_log_enabled',
  SignUpFields = 'sign_up_fields',
  Home = 'home',
  Crisp = 'crisp',
  FacebookPixel = 'facebook_pixel',
  Drift = 'drift',
  GoogleAnalytics = 'google_analytics',
  GoogleTagManager = 'google_tag_manager',
  Hubspot = 'hubspot',
  Intercom = 'intercom',
  Hotjar = 'hotjar',
  // RegularTitleTextStyle = 'regular_title_text_style',
  // FieldLabelTextStyle = 'field_label_text_style',
  // FieldLabelAdditionalTextStyle = 'field_label_additional_text_style',
  // ListGroupTitleTextStyle = 'list_group_title_text_style',
  // TableGroupTitleTextStyle = 'table_group_title_text_style',
  // KanbanStageTitleTextStyle = 'kanban_stage_title_text_style',
  // ValueWidgetTitleTextStyle = 'value_widget_title_text_style',
  // ValueWidgetValueTextStyle = 'value_widget_value_text_style',
  // CollapseTitleTextStyle = 'collapse_title_text_style',
  ActionElementStylesPrimary = 'action_element_styles_primary',
  ActionElementStylesDefault = 'action_element_styles_default',
  ActionElementStylesTransparent = 'action_element_styles_transparent',
  FieldElementStyles = 'field_element_styles',
  ElementWrapperStyles = 'element_wrapper_styles'
}

export class ProjectSettings {
  public environment: string;
  public name: ProjectSettingsName;
  public value = {};
  public draft = false;
  public deleted = false;
  public dateAdd: string;

  constructor(options: Partial<ProjectSettings> = {}) {
    Object.assign(this, options);
  }

  deserialize(data: Object): ProjectSettings {
    this.environment = data['environment'];
    this.name = data['name'];
    this.value = data['value'];
    this.draft = data['draft'];
    this.deleted = data['deleted'];
    this.dateAdd = data['date_add'];

    return this;
  }

  serialize(fields?: string[]): Object {
    let data: Object = {
      name: this.name,
      value: this.value,
      draft: this.draft,
      deleted: this.deleted
    };
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }

  getLabel(): string {
    if (this.name == ProjectSettingsName.DefaultTheme) {
      return 'Default theme';
    } else if (this.name == ProjectSettingsName.Layout) {
      return 'Layout';
    } else if (this.name == ProjectSettingsName.AccentColor) {
      return 'Accent color';
    } else if (this.name == ProjectSettingsName.AccentColorDark) {
      return 'Accent color (dark)';
    } else if (this.name == ProjectSettingsName.BackgroundColor) {
      return 'Background color';
    } else if (this.name == ProjectSettingsName.BackgroundColorDark) {
      return 'Background color (dark)';
    } else if (this.name == ProjectSettingsName.BackgroundColor2) {
      return 'Surfaces color';
    } else if (this.name == ProjectSettingsName.BackgroundColor2Dark) {
      return 'Surfaces color (dark)';
    } else if (this.name == ProjectSettingsName.BackgroundColor3) {
      return 'Surfaces color 2';
    } else if (this.name == ProjectSettingsName.BackgroundColor3Dark) {
      return 'Surfaces color 2 (dark)';
    } else if (this.name == ProjectSettingsName.BackgroundColor4) {
      return 'Surfaces color 3';
    } else if (this.name == ProjectSettingsName.BackgroundColor4Dark) {
      return 'Surfaces color 3 (dark)';
    } else if (this.name == ProjectSettingsName.BackgroundColor5) {
      return 'Surfaces color 4';
    } else if (this.name == ProjectSettingsName.BackgroundColor5Dark) {
      return 'Surfaces color 4 (dark)';
    } else if (this.name == ProjectSettingsName.TextColor) {
      return 'Text color headings';
    } else if (this.name == ProjectSettingsName.TextColorDark) {
      return 'Text color headings (dark)';
    } else if (this.name == ProjectSettingsName.TextColor2) {
      return 'Text color regular';
    } else if (this.name == ProjectSettingsName.TextColor2Dark) {
      return 'Text color regular (dark)';
    } else if (this.name == ProjectSettingsName.TextColor3) {
      return 'Text color secondary';
    } else if (this.name == ProjectSettingsName.TextColor3Dark) {
      return 'Text color secondary (dark)';
    } else if (this.name == ProjectSettingsName.BorderColor) {
      return 'Border color secondary';
    } else if (this.name == ProjectSettingsName.BorderColorDark) {
      return 'Border color secondary (dark)';
    } else if (this.name == ProjectSettingsName.BorderColor2) {
      return 'Border color primary';
    } else if (this.name == ProjectSettingsName.BorderColor2Dark) {
      return 'Border color primary (dark)';
    } else if (this.name == ProjectSettingsName.BorderColor3) {
      return 'Border color bright';
    } else if (this.name == ProjectSettingsName.BorderColor3Dark) {
      return 'Border color bright (dark)';
    } else if (this.name == ProjectSettingsName.BorderRadius) {
      return 'Border radius';
    } else if (this.name == ProjectSettingsName.AutoColors) {
      return 'Auto-colors';
    } else if (this.name == ProjectSettingsName.AutoColorsDark) {
      return 'Auto-colors (dark)';
    } else if (this.name == ProjectSettingsName.MaxWidth) {
      return 'Pages maximum width';
    } else if (this.name == ProjectSettingsName.Padding) {
      return 'Pages spacing';
    } else if (this.name == ProjectSettingsName.FontRegular) {
      return 'Regular font';
    } else if (this.name == ProjectSettingsName.FontHeading) {
      return 'Heading font';
    } else if (this.name == ProjectSettingsName.FontsUsed) {
      return 'Fonts used';
    } else if (this.name == ProjectSettingsName.AppendScripts) {
      return 'Global JS';
    } else if (this.name == ProjectSettingsName.AppendStyles) {
      return 'Global CSS';
    } else if (this.name == ProjectSettingsName.CollaborationEnabled) {
      return 'Collaboration feature';
    } else if (this.name == ProjectSettingsName.ActivityLogEnabled) {
      return 'Activity log feature';
    } else if (this.name == ProjectSettingsName.SignUpFields) {
      return 'Sign Up fields';
    } else if (this.name == ProjectSettingsName.Home) {
      return 'Home page';
    } else if (this.name == ProjectSettingsName.Crisp) {
      return 'Crisp';
    } else if (this.name == ProjectSettingsName.Drift) {
      return 'Drift Chat';
    } else if (this.name == ProjectSettingsName.FacebookPixel) {
      return 'Facebook Pixel';
    } else if (this.name == ProjectSettingsName.GoogleAnalytics) {
      return 'Google Analytics';
    } else if (this.name == ProjectSettingsName.GoogleTagManager) {
      return 'Google Tag Manager';
    } else if (this.name == ProjectSettingsName.Hotjar) {
      return 'Hotjar';
    } else if (this.name == ProjectSettingsName.Hubspot) {
      return 'HubSpot';
    } else if (this.name == ProjectSettingsName.Intercom) {
      return 'Intercom';
      // } else if (this.name == ProjectSettingsName.RegularTitleTextStyle) {
      //   return 'Regular title text style';
      // } else if (this.name == ProjectSettingsName.FieldLabelTextStyle) {
      //   return 'Field label text style';
      // } else if (this.name == ProjectSettingsName.FieldLabelAdditionalTextStyle) {
      //   return 'Field additional label text style';
      // } else if (this.name == ProjectSettingsName.ListGroupTitleTextStyle) {
      //   return 'List group title text style';
      // } else if (this.name == ProjectSettingsName.TableGroupTitleTextStyle) {
      //   return 'Table group title text style';
      // } else if (this.name == ProjectSettingsName.KanbanStageTitleTextStyle) {
      //   return 'Kanban stage title text style';
      // } else if (this.name == ProjectSettingsName.ValueWidgetTitleTextStyle) {
      //   return 'Single value text style';
      // } else if (this.name == ProjectSettingsName.ValueWidgetValueTextStyle) {
      //   return 'Single value label text style';
      // } else if (this.name == ProjectSettingsName.CollapseTitleTextStyle) {
      //   return 'Collapse title text style';
    } else if (this.name == ProjectSettingsName.ActionElementStylesPrimary) {
      return 'Buttons styles (primary)';
    } else if (this.name == ProjectSettingsName.ActionElementStylesDefault) {
      return 'Buttons styles (default)';
    } else if (this.name == ProjectSettingsName.ActionElementStylesTransparent) {
      return 'Buttons styles (transparent)';
    } else if (this.name == ProjectSettingsName.FieldElementStyles) {
      return 'Fields styles';
    }
  }
}
