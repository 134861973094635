import { Injectable } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';

import { ElementItem } from '@modules/customize';
import { ParameterField } from '@modules/fields';
import { ModelDescription } from '@modules/models';
import { modelDescriptionHasAutoParameters } from '@modules/parameters';
import { Resource } from '@modules/projects';
import { ResourceControllerService } from '@modules/resources';
import { defaultComponentTemplateName, Template, TemplateService, TemplateType } from '@modules/template';
import { isSet } from '@shared';

@Injectable()
export class GeneratorUtils {
  constructor(private templateService: TemplateService, private resourceControllerService: ResourceControllerService) {}

  getDefaultComponentTemplates() {
    return this.templateService.get({ type: TemplateType.DefaultComponent });
  }

  applyElementTemplate(item: ElementItem, templates: Template[]): ElementItem {
    if (!templates) {
      return item;
    }

    const template = templates.find(i => i.uniqueName == defaultComponentTemplateName(item));

    if (!template || !template.element) {
      return item;
    }

    const templateElement = cloneDeep(template.element);

    templateElement.uid = item.uid;
    templateElement.name = item.name;
    templateElement.visibleInput = item.visibleInput;

    item.deserialize(templateElement.serialize());

    return item;
  }

  getModelPageUniqueName(resource: Resource, modelDescription: ModelDescription, name?: string): string {
    return [resource.typeItem.name, modelDescription.model, ...(name ? [name] : [])].join('_');
  }

  getModelDeleteParameter(resource: Resource, modelDescription: ModelDescription): string {
    const controller = this.resourceControllerService.get(resource.type);
    const deleteParameters = controller.getDeleteParametersOrDefaults(resource, modelDescription);
    return deleteParameters.slice(0, 1).map(item => item.name)[0];
  }

  getModelPkParameter(modelDescription: ModelDescription): ParameterField {
    const parameter = new ParameterField();

    parameter.name = 'ID';
    parameter.verboseName = modelDescription.primaryKey.verboseName;
    parameter.field = modelDescription.primaryKey.field;
    parameter.params = modelDescription.primaryKey.params;
    parameter.updateFieldDescription();

    return parameter;
  }

  isModelDetailSupported(resource: Resource, modelDescription: ModelDescription) {
    const hasDetailQuery =
      modelDescription.getDetailQuery ||
      (modelDescription.getQuery &&
        (modelDescription.getQuery.frontendFiltering || modelDescriptionHasAutoParameters(resource, modelDescription)));
    return hasDetailQuery && isSet(modelDescription.primaryKey);
  }

  fieldsSort(fields: string[], lhsName: string, rhsName: string) {
    const lhsIndex = fields.indexOf(lhsName);
    const rhsIndex = fields.indexOf(rhsName);

    if (lhsIndex !== -1 && rhsIndex !== -1) {
      return lhsIndex - rhsIndex;
    } else if (lhsIndex === -1 && rhsIndex !== -1) {
      return 1;
    } else if (lhsIndex !== -1 && rhsIndex === -1) {
      return -1;
    } else if (lhsIndex === -1 && rhsIndex === -1) {
      return 0;
    }
  }
}
