<app-field-wrapper
  *ngIf="!readonly"
  [formGroup]="form"
  [form]="form"
  [field]="field"
  [readonly]="readonly"
  [requiredAsterisk]="requiredAsterisk"
  [label]="label"
  [labelStyle]="elementStyles?.labelStyle"
  [labelStyleGlobal]="labelStyleGlobal"
  [labelAdditional]="labelAdditional"
  [labelAdditionalStyle]="elementStyles?.labelAdditionalStyle"
  [labelAdditionalStyleGlobal]="labelAdditionalStyleGlobal"
  [errors]="errors"
  [truncate]="truncate"
  [manualMargin]="manualMargin"
  [labelButtons]="labelButtons"
  [tooltip]="tooltip"
  [idToken]="idToken"
  [theme]="field.params['theme']"
>
  <ng-template let-trigger="trigger" let-margin="margin" #color_selector>
    <ng-template
      (backdropClick)="openEditPopover(false)"
      (detach)="openEditPopover(false)"
      [cdkConnectedOverlayBackdropClass]="'popover2-backdrop'"
      [cdkConnectedOverlayGrowAfterOpen]="true"
      [cdkConnectedOverlayHasBackdrop]="true"
      [cdkConnectedOverlayOpen]="editPopoverOpened"
      [cdkConnectedOverlayOrigin]="trigger"
      [cdkConnectedOverlayPanelClass]="['overlay', 'overlay_horizontal_center']"
      [cdkConnectedOverlayPositions]="[
        { originX: 'start', overlayX: 'start', originY: 'bottom', overlayY: 'top', offsetX: -8, offsetY: -8 + margin },
        { originX: 'center', overlayX: 'center', originY: 'bottom', overlayY: 'top', offsetX: 0, offsetY: -8 + margin },
        { originX: 'end', overlayX: 'end', originY: 'bottom', overlayY: 'top', offsetX: 8, offsetY: -8 + margin },
        { originX: 'start', overlayX: 'start', originY: 'top', overlayY: 'bottom', offsetX: -8, offsetY: 8 - margin },
        { originX: 'center', overlayX: 'center', originY: 'top', overlayY: 'bottom', offsetX: 0, offsetY: 8 - margin },
        { originX: 'end', overlayX: 'end', originY: 'top', overlayY: 'bottom', offsetX: 8, offsetY: 8 - margin },
        { originX: 'end', overlayX: 'start', originY: 'center', overlayY: 'center', offsetX: margin, offsetY: 0 },
        { originX: 'start', overlayX: 'end', originY: 'center', overlayY: 'center', offsetX: 0 - margin, offsetY: 0 }
      ]"
      [cdkConnectedOverlayPush]="true"
      cdkConnectedOverlay
    >
      <div class="popover2 overlay-content overlay-content_animated" [class.popover2_theme]="field.params['theme']">
        <div class="popover2__inner">
          <app-color-selector
            [current]="currentValue"
            [colors]="field.params['colors']"
            [customColors]="field.params['custom_colors'] != false"
            [globalColors]="field.params['global_colors']"
            [allowEmpty]="allowEmpty"
            [emptyColor]="
              emptyAccentColor
                ? ('accentColor' | appThemeColorHexOption: true | async) || defaultAccentColor
                : emptyColor
            "
            [defaultColor]="field.params['default_color']"
            [alphaEnabled]="field.params['alpha_enabled']"
            [accentColor]="'accentColor' | appThemeColorHexOption: true | async"
            [theme]="field.params['theme']"
            (selected)="setColor($event)"
            (finish)="openEditPopover(false)"
          ></app-color-selector>
        </div>
      </div>
    </ng-template>
  </ng-template>

  <ng-container *ngIf="!field.params['minimal']">
    <div
      class="select select_theme_jet"
      [ngClass]="field.params['classes']"
      [class.select_theme]="field.params['theme']"
    >
      <a
        href="javascript:void(0)"
        class="select__input"
        [class.select__input_opened]="editPopoverOpened"
        [class.select__input_disabled]="control?.disabled"
        [id]="field.name | appUniqueId: idToken"
        cdkOverlayOrigin
        #field_trigger="cdkOverlayOrigin"
        (click)="openEditPopover(true)"
      >
        <span
          class="select__input-icon-color"
          [class.select__input-icon-color_palette]="!currentValue && !hasEmptyColor"
          [style.background]="sanitizer.bypassSecurityTrustStyle(currentValueDisplay?.color)"
        ></span>
        <span
          *ngIf="field.params['prefix'] | appIsSet"
          class="select__input-label-title"
          [class.select__input-label-title_bright]="field.params['prefix_bright']"
        >
          {{ field.params['prefix'] }}
        </span>
        <span class="select__input-label">
          <ng-container *ngIf="currentValue">
            {{ currentValueDisplay?.label || currentValue }}
          </ng-container>

          <ng-container *ngIf="!currentValue && field.placeholder">
            {{ field.placeholder }}
          </ng-container>

          <ng-container *ngIf="!currentValue && !field.placeholder">
            <ng-container *ngIf="hasEmptyColor">Default color</ng-container>
            <ng-container *ngIf="!hasEmptyColor">not specified</ng-container>
          </ng-container>
        </span>
        <span
          *ngIf="currentValue && allowEmpty"
          class="select__input-reset icon-close"
          (click)="$event.stopPropagation(); setColor(undefined)"
        ></span>
        <span *ngIf="!currentValue || !allowEmpty" class="select__input-arrow"></span>
      </a>
    </div>

    <ng-container *ngTemplateOutlet="color_selector; context: { trigger: field_trigger, margin: 0 }"></ng-container>
  </ng-container>

  <ng-container *ngIf="field.params['minimal']">
    <a
      href="javascript:void(0)"
      class="color-button"
      [class.color-button_palette]="!currentValue && !hasEmptyColor"
      [style.background]="sanitizer.bypassSecurityTrustStyle(currentValueDisplay?.color)"
      (click)="openEditPopover(true)"
      [id]="field.name | appUniqueId: idToken"
      cdkOverlayOrigin
      #button_trigger="cdkOverlayOrigin"
    >
    </a>

    <ng-container *ngTemplateOutlet="color_selector; context: { trigger: button_trigger, margin: 8 }"></ng-container>
  </ng-container>
</app-field-wrapper>

<app-field-wrapper
  *ngIf="readonly"
  [form]="form"
  [field]="field"
  [readonly]="readonly"
  [requiredAsterisk]="requiredAsterisk"
  [label]="label"
  [labelStyle]="elementStyles?.labelStyle"
  [labelStyleGlobal]="labelStyleGlobal"
  [labelAdditional]="labelAdditional"
  [labelAdditionalStyle]="elementStyles?.labelAdditionalStyle"
  [labelAdditionalStyleGlobal]="labelAdditionalStyleGlobal"
  [errors]="errors"
  [truncate]="truncate"
  [manualMargin]="manualMargin"
  [labelButtons]="labelButtons"
  [tooltip]="tooltip"
  [idToken]="idToken"
  [theme]="field.params['theme']"
>
  <ng-container *ngIf="!field.params['minimal']">
    <span
      class="field__button-color"
      [class.field__button-color_empty]="!currentValue"
      [style.background]="sanitizer.bypassSecurityTrustStyle(currentValueDisplay?.color)"
    ></span>
    <span class="field__button-label">
      <ng-container *ngIf="currentValue">
        {{ currentValueDisplay?.label || currentValue }}
      </ng-container>

      <ng-container *ngIf="!currentValue">
        <ng-container *ngIf="hasEmptyColor">Default color</ng-container>
        <ng-container *ngIf="!hasEmptyColor">not specified</ng-container>
      </ng-container>
    </span>
  </ng-container>

  <span
    *ngIf="field.params['minimal']"
    class="color-button"
    [style.background]="sanitizer.bypassSecurityTrustStyle(currentValueDisplay?.color)"
  >
  </span>
</app-field-wrapper>
