var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { AllProjectSettings } from '@modules/all-project-settings';
import { isSet } from '@shared';
import { ThemeOptionsDirective } from '../theme-options/theme-options.directive';
var ProjectThemeOptionsDirective = /** @class */ (function (_super) {
    __extends(ProjectThemeOptionsDirective, _super);
    function ProjectThemeOptionsDirective() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ProjectThemeOptionsDirective.prototype.getThemeOptions = function () {
        if (this.projectSettings) {
            return __assign({ accentColor: this.projectSettings.accentColor, accentColorDark: this.projectSettings.accentColorDark, backgroundColor: this.projectSettings.backgroundColor, backgroundColorDark: this.projectSettings.backgroundColorDark, backgroundColor2: isSet(this.backgroundColor2) ? this.backgroundColor2 : this.projectSettings.backgroundColor2, backgroundColor2Dark: isSet(this.backgroundColor2Dark)
                    ? this.backgroundColor2Dark
                    : this.projectSettings.backgroundColor2Dark, backgroundColor3: isSet(this.backgroundColor3) ? this.backgroundColor3 : this.projectSettings.backgroundColor3, backgroundColor3Dark: isSet(this.backgroundColor3Dark)
                    ? this.backgroundColor3Dark
                    : this.projectSettings.backgroundColor3Dark, backgroundColor4: isSet(this.backgroundColor4) ? this.backgroundColor4 : this.projectSettings.backgroundColor4, backgroundColor4Dark: isSet(this.backgroundColor4Dark)
                    ? this.backgroundColor4Dark
                    : this.projectSettings.backgroundColor4Dark, backgroundColor5: isSet(this.backgroundColor5) ? this.backgroundColor5 : this.projectSettings.backgroundColor5, backgroundColor5Dark: isSet(this.backgroundColor5Dark)
                    ? this.backgroundColor5Dark
                    : this.projectSettings.backgroundColor5Dark, textColor: isSet(this.textColor) ? this.textColor : this.projectSettings.textColor, textColorDark: isSet(this.textColorDark) ? this.textColorDark : this.projectSettings.textColorDark, textColor2: isSet(this.textColor2) ? this.textColor2 : this.projectSettings.textColor2, textColor2Dark: isSet(this.textColor2Dark) ? this.textColor2Dark : this.projectSettings.textColor2Dark, textColor3: isSet(this.textColor3) ? this.textColor3 : this.projectSettings.textColor3, textColor3Dark: isSet(this.textColor3Dark) ? this.textColor3Dark : this.projectSettings.textColor3Dark, borderColor: isSet(this.borderColor) ? this.borderColor : this.projectSettings.borderColor, borderColorDark: isSet(this.borderColorDark) ? this.borderColorDark : this.projectSettings.borderColorDark, borderColor2: isSet(this.borderColor2) ? this.borderColor2 : this.projectSettings.borderColor2, borderColor2Dark: isSet(this.borderColor2Dark) ? this.borderColor2Dark : this.projectSettings.borderColor2Dark, borderColor3: isSet(this.borderColor3) ? this.borderColor3 : this.projectSettings.borderColor3, borderColor3Dark: isSet(this.borderColor3Dark) ? this.borderColor3Dark : this.projectSettings.borderColor3Dark, borderRadius: this.projectSettings.borderRadius, fontRegular: this.projectSettings.fontRegular, fontHeading: this.projectSettings.fontHeading }, (this.projectSettings.elementWrapperStyles && {
                elementWrapperBorder: this.projectSettings.elementWrapperStyles.borderSettings,
                elementWrapperBorderRadius: this.projectSettings.elementWrapperStyles.borderRadius,
                elementWrapperShadow: this.projectSettings.elementWrapperStyles.shadow,
                elementWrapperMargin: this.projectSettings.elementWrapperStyles.margin
            }));
        }
        else {
            return _super.prototype.getThemeOptions.call(this);
        }
    };
    return ProjectThemeOptionsDirective;
}(ThemeOptionsDirective));
export { ProjectThemeOptionsDirective };
