import { Pipe, PipeTransform } from '@angular/core';

import { ViewSettingsAction } from '@modules/actions';
import {
  ActionElementItem,
  ActionElementStyles,
  FormSubmitElementItem,
  getActionElementStyles
} from '@modules/customize';

@Pipe({
  name: 'appActionElementStyles',
  pure: false
})
export class ActionElementStylesPipe implements PipeTransform {
  transform(element: ActionElementItem | ViewSettingsAction | FormSubmitElementItem): ActionElementStyles {
    return getActionElementStyles(element);
  }
}
