var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, ElementRef, OnDestroy, OnInit, QueryList } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import isEqual from 'lodash/isEqual';
import toPairs from 'lodash/toPairs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { DynamicComponent } from '@common/dynamic-component';
import { AppConfigService } from '@core';
import { ProjectSettingsStore } from '@modules/all-project-settings';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { CustomizeService } from '@modules/customize';
import { CustomizeBarContext } from '@modules/customize-bar';
import { MenuSection, MenuService, MenuSettingsStore } from '@modules/menu';
import { MetaService } from '@modules/meta';
import { CurrentEnvironmentStore, CurrentProjectStore, hasEnvironmentPermission, ProjectPermissions } from '@modules/projects';
import { RoutingService } from '@modules/routing';
import { isSet, parseNumber, scrollTo } from '@shared';
import { AdminPreviewType } from '../admin-template/admin-template.component';
import { CustomizeBarAppearanceEditButtonsComponent } from '../customize-bar-appearance-edit-buttons/customize-bar-appearance-edit-buttons.component';
import { CustomizeBarAppearanceEditElementWrappersComponent } from '../customize-bar-appearance-edit-element-wrappers/customize-bar-appearance-edit-element-wrappers.component';
import { CustomizeBarAppearanceEditFieldsComponent } from '../customize-bar-appearance-edit-fields/customize-bar-appearance-edit-fields.component';
import { CustomizeBarAppearanceEditGeneralComponent } from '../customize-bar-appearance-edit-general/customize-bar-appearance-edit-general.component';
import { CustomizeBarAppearanceEditComponent } from '../customize-bar-appearance-edit/customize-bar-appearance-edit.component';
import { CustomizeBarCustomCodeEditComponent } from '../customize-bar-custom-code-edit/customize-bar-custom-code-edit.component';
import { CustomizeBarEmailsEditComponent } from '../customize-bar-emails-edit/customize-bar-emails-edit.component';
import { CustomizeBarGeneralEditComponent } from '../customize-bar-general-edit/customize-bar-general-edit.component';
import { CustomizeBarIntegrationsEditComponent } from '../customize-bar-integrations-edit/customize-bar-integrations-edit.component';
import { CustomizeBarLanguageRegionEditComponent } from '../customize-bar-language-region-edit/customize-bar-language-region-edit.component';
import { CustomizeBarMenuEditComponent } from '../customize-bar-menu-edit/customize-bar-menu-edit.component';
import { CustomizeBarPagesEditComponent } from '../customize-bar-pages-edit/customize-bar-pages-edit.component';
import { CustomizeBarSharingEditComponent } from '../customize-bar-sharing-edit/customize-bar-sharing-edit.component';
import { ProjectDomainUpdateForm } from '../sign-up-builder/project-domain-update.form';
import { ProjectSignUpForm } from '../sign-up-builder/project-sign-up.form';
import { ProjectAppearanceContext } from './project-appearance.context';
import { ProjectSettingsUpdateForm } from './project-settings-update.form';
import { ProjectUpdateForm } from './project-update.form';
var DisplayComponent;
(function (DisplayComponent) {
    DisplayComponent["Default"] = "default";
})(DisplayComponent || (DisplayComponent = {}));
var ProjectSettingsComponent = /** @class */ (function () {
    function ProjectSettingsComponent(currentEnvironmentStore, activatedRoute, projectForm, settingsForm, appearanceContext, projectSignUpForm, projectDomainUpdateForm, menuService, customizeBarContext, currentProjectStore, projectSettingsStore, customizeService, menuSettingsStore, routing, appConfigService, metaService, analyticsService, cd) {
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.activatedRoute = activatedRoute;
        this.projectForm = projectForm;
        this.settingsForm = settingsForm;
        this.appearanceContext = appearanceContext;
        this.projectSignUpForm = projectSignUpForm;
        this.projectDomainUpdateForm = projectDomainUpdateForm;
        this.menuService = menuService;
        this.customizeBarContext = customizeBarContext;
        this.currentProjectStore = currentProjectStore;
        this.projectSettingsStore = projectSettingsStore;
        this.customizeService = customizeService;
        this.menuSettingsStore = menuSettingsStore;
        this.routing = routing;
        this.appConfigService = appConfigService;
        this.metaService = metaService;
        this.analyticsService = analyticsService;
        this.cd = cd;
        this.dynamicComponents = new QueryList();
        this.menuItems = [
            {
                path: 'general',
                title: 'General',
                subtitle: 'App name, URL and logo',
                icon: 'gear_2',
                component: CustomizeBarGeneralEditComponent,
                inputs: {
                    projectForm: this.projectForm,
                    settingsForm: this.settingsForm
                }
            },
            {
                path: 'appearance',
                title: 'Appearance',
                subtitle: 'Layout, colors, fonts',
                icon: 'palette',
                component: CustomizeBarAppearanceEditComponent,
                inputs: {
                    context: this.appearanceContext
                },
                children: [
                    {
                        path: 'general',
                        component: CustomizeBarAppearanceEditGeneralComponent,
                        inputs: {
                            context: this.appearanceContext,
                            settingsForm: this.settingsForm
                        }
                    },
                    {
                        path: 'menu',
                        component: CustomizeBarMenuEditComponent,
                        inputs: {
                            context: this.appearanceContext
                        },
                        children: [
                            {
                                path: ':uid',
                                component: CustomizeBarPagesEditComponent,
                                inputs: {
                                    context: this.appearanceContext
                                }
                            }
                        ]
                    },
                    {
                        path: 'buttons',
                        component: CustomizeBarAppearanceEditButtonsComponent,
                        inputs: {
                            context: this.appearanceContext
                        },
                        adminPreview: AdminPreviewType.Buttons
                    },
                    {
                        path: 'fields',
                        component: CustomizeBarAppearanceEditFieldsComponent,
                        inputs: {
                            context: this.appearanceContext
                        },
                        adminPreview: AdminPreviewType.Fields
                    },
                    {
                        path: 'cards',
                        component: CustomizeBarAppearanceEditElementWrappersComponent,
                        inputs: {
                            context: this.appearanceContext
                        }
                    }
                ]
            },
            // {
            //   path: 'menu',
            //   title: 'Menu',
            //   subtitle: 'Pages navigation',
            //   icon: 'fileds',
            //   component: CustomizeBarMenuEditComponent,
            //   inputs: {
            //     context: this.appearanceContext
            //   },
            //   children: [
            //     {
            //       path: 'items',
            //       component: CustomizeBarPagesEditComponent,
            //       inputs: {
            //         context: this.appearanceContext
            //       }
            //     }
            //   ]
            // },
            // {
            //   type: 'domain',
            //   title: 'Custom Domain',
            //   subtitle: 'Host app under your domain',
            //   icon: 'external_link',
            //   component: CustomizeBarDomainEditComponent,
            //   inputs: {
            //     projectForm: this.projectForm
            //   }
            // },
            {
                path: 'language_region',
                title: 'Language & Region',
                subtitle: 'Localization, timezone and formats',
                icon: 'earth_planet',
                component: CustomizeBarLanguageRegionEditComponent,
                inputs: {
                    projectDomainUpdateForm: this.projectDomainUpdateForm
                }
            },
            {
                path: 'emails',
                title: 'Emails',
                subtitle: 'Settings, templates',
                icon: 'email',
                component: CustomizeBarEmailsEditComponent,
                inputs: {
                    projectSignUpForm: this.projectSignUpForm,
                    projectDomainUpdateForm: this.projectDomainUpdateForm
                }
            },
            // {
            //   path: 'features',
            //   title: 'Features',
            //   subtitle: 'Advanced functionality',
            //   icon: 'switch',
            //   component: CustomizeBarFeaturesEditComponent,
            //   inputs: {
            //     settingsForm: this.settingsForm
            //   }
            // },
            {
                path: 'sharing',
                title: 'Sharing',
                subtitle: 'Meta info for URL sharing',
                icon: 'redo',
                component: CustomizeBarSharingEditComponent,
                inputs: {
                    projectForm: this.projectForm,
                    projectDomainUpdateForm: this.projectDomainUpdateForm
                }
            },
            {
                path: 'integrations',
                title: 'Integrations',
                subtitle: '3rd party services',
                icon: 'chip',
                component: CustomizeBarIntegrationsEditComponent,
                inputs: {
                    settingsForm: this.settingsForm
                }
            },
            {
                path: 'custom_code',
                title: 'Scripts & Styles',
                subtitle: 'Set global JS and CSS',
                icon: 'console',
                component: CustomizeBarCustomCodeEditComponent,
                inputs: {
                    settingsForm: this.settingsForm
                }
            }
        ];
        this.selectedMenuItemPath = [];
        this.displayComponent = DisplayComponent.Default;
        this.displayComponents = DisplayComponent;
        this.adminPreview = AdminPreviewType.Default;
        this.settingsComponents = [];
        this.loading = false;
    }
    ProjectSettingsComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (!hasEnvironmentPermission(this.currentEnvironmentStore.instance, ProjectPermissions.ProjectCustomization)) {
            this.routing.navigateApp(['not-allowed'], { skipLocationChange: true });
            return;
        }
        this.menuService.section = MenuSection.None;
        this.loading = true;
        this.cd.markForCheck();
        this.activatedRoute.params.pipe(untilDestroyed(this)).subscribe(function (params) {
            var path = toPairs(params).reduce(function (acc, _a) {
                var k = _a[0], v = _a[1];
                var match = k.match(/path(\d+)/);
                if (match) {
                    var index = parseNumber(match[1]);
                    if (index !== null) {
                        acc[index - 1] = v;
                    }
                }
                return acc;
            }, []);
            if (path.length) {
                _this.setSelectedMenuItemPath(path);
            }
            else {
                var link = _this.currentProjectStore.instance.settingsLayoutLink(_this.menuItems[0].path);
                _this.routing.navigateApp(link, { replaceUrl: true });
            }
        });
        this.customizeService.setHandler(this);
        this.customizeService.setHandlerInfo(this, {
            breadcrumbs: [],
            inAppDisabled: true
        });
        this.customizeBarContext.settingsComponents$.pipe(untilDestroyed(this)).subscribe(function (item) {
            _this.settingsComponents = item;
            _this.cd.detectChanges();
            _this.scrollToTop();
        });
        this.projectDomainUpdateForm
            .getInstance$()
            .pipe(untilDestroyed(this))
            .subscribe(function (domain) {
            _this.domain = domain;
            _this.cd.markForCheck();
        });
        combineLatest(this.currentProjectStore.getFirst(), this.projectSettingsStore.getAllSettingsFirst$())
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var project = _a[0], projectSettings = _a[1];
            var webBaseUrl = project && project.domain ? "https://" + project.domain.actualDomain : _this.appConfigService.webBaseUrl;
            _this.project = project;
            _this.domain = project ? project.domain : undefined;
            _this.projectHome = [webBaseUrl, 'app', project.uniqueName].join('/');
            _this.loading = false;
            _this.cd.markForCheck();
            _this.projectForm.init(project);
            _this.settingsForm.init(projectSettings);
            _this.projectSignUpForm.init(project);
            _this.projectForm.valueChanges.pipe(debounceTime(200), untilDestroyed(_this)).subscribe(function () {
                _this.projectForm.submit().subscribe(function () { return _this.onSave(); });
            });
            _this.settingsForm.valueChanges.pipe(debounceTime(200), untilDestroyed(_this)).subscribe(function () {
                _this.settingsForm.submit().subscribe(function () { return _this.onSave(); });
            });
            _this.projectSignUpForm.valueChanges.pipe(debounceTime(200), untilDestroyed(_this)).subscribe(function () {
                _this.projectSignUpForm.submit().subscribe(function () { return _this.onSave(); });
            });
        }, function () {
            _this.loading = false;
            _this.cd.markForCheck();
        });
        this.currentProjectStore
            .get()
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            var webBaseUrl = result && result.domain ? "https://" + result.domain.actualDomain : _this.appConfigService.webBaseUrl;
            _this.project = result;
            _this.domain = result ? result.domain : undefined;
            _this.projectHome = [webBaseUrl, 'app', result.uniqueName].join('/');
            _this.cd.markForCheck();
            _this.projectForm.project = _this.project;
            _this.projectSignUpForm.project = _this.project;
            _this.initDomainForm();
        });
    };
    ProjectSettingsComponent.prototype.ngOnDestroy = function () {
        this.menuService.section = MenuSection.Default;
        this.customizeService.unsetHandler(this);
    };
    ProjectSettingsComponent.prototype.initDomainForm = function () {
        var _this = this;
        if (this.domainFormSubscription) {
            this.domainFormSubscription.unsubscribe();
            this.domainFormSubscription = undefined;
        }
        this.projectDomainUpdateForm.init(this.domain);
        this.domainFormSubscription = this.projectDomainUpdateForm.valueChanges
            .pipe(debounceTime(200), untilDestroyed(this))
            .subscribe(function () {
            _this.projectDomainUpdateForm.submit().subscribe(function () { return _this.onSave(); });
        });
    };
    ProjectSettingsComponent.prototype.setSelectedMenuItemPath = function (path) {
        if (isEqual(this.selectedMenuItemPath, path)) {
            return;
        }
        this.selectedMenuItemPath = path;
        this.cd.markForCheck();
        var components = [];
        var displayComponent;
        var adminPreview;
        var iterate = function (array, index) {
            var _a;
            var menuItem;
            var pathInput;
            for (var _i = 0, array_1 = array; _i < array_1.length; _i++) {
                var item = array_1[_i];
                var variablePath = item.path.match(/^:(\w+)$/);
                if (variablePath) {
                    menuItem = item;
                    pathInput = variablePath[1];
                    break;
                }
                else if (item.path == path[index]) {
                    menuItem = item;
                    break;
                }
            }
            if (!menuItem) {
                return;
            }
            if (menuItem.component) {
                components.push({
                    component: menuItem.component,
                    inputs: __assign({}, menuItem.inputs, (isSet(pathInput) && (_a = {},
                        _a[pathInput] = path[index],
                        _a)))
                });
            }
            if (menuItem.displayComponent) {
                displayComponent = menuItem.displayComponent;
            }
            if (menuItem.adminPreview) {
                adminPreview = menuItem.adminPreview;
            }
            if (menuItem.children && isSet(path[index + 1])) {
                iterate(menuItem.children, index + 1);
            }
        };
        iterate(this.menuItems, 0);
        this.displayComponent = displayComponent ? displayComponent : DisplayComponent.Default;
        this.adminPreview = adminPreview ? adminPreview : AdminPreviewType.Default;
        this.cd.markForCheck();
        var currentComponents = this.customizeBarContext.settingsComponents;
        var newComponents = components.map(function (component, i) {
            var currentComponent = currentComponents[i] ? currentComponents[i].component : undefined;
            if (currentComponent !== component.component) {
                return {
                    component: component.component,
                    inputs: component.inputs || {}
                };
            }
            else {
                return currentComponents[i];
            }
        });
        this.customizeBarContext.setSettingsComponents(newComponents);
    };
    ProjectSettingsComponent.prototype.hasUnsavedChanges = function (currentState, nextState) {
        var currentComponent = this.dynamicComponents.first && this.dynamicComponents.first.currentComponent
            ? this.dynamicComponents.first.currentComponent.instance
            : undefined;
        if (currentComponent instanceof CustomizeBarAppearanceEditComponent) {
            var getCurrentComponentRoute_1 = function (route) {
                if (route.component === ProjectSettingsComponent) {
                    return route;
                }
                else if (route.firstChild) {
                    return getCurrentComponentRoute_1(route.firstChild);
                }
            };
            var nextRouteSameComponent = nextState ? getCurrentComponentRoute_1(nextState.root) : undefined;
            if (!nextRouteSameComponent || nextRouteSameComponent.params['path1'] !== 'appearance') {
                return currentComponent.context.getHasChanges();
            }
        }
    };
    ProjectSettingsComponent.prototype.scrollToTop = function (animated) {
        if (animated === void 0) { animated = true; }
        if (!this.root) {
            return;
        }
        var duration = animated && this.root.nativeElement.scrollTop > 0 ? 0.4 : 0;
        scrollTo(this.root.nativeElement, 0, duration);
    };
    ProjectSettingsComponent.prototype.onBlockControlPreviewClick = function (block) {
        var uid = block.instance ? block.instance.uid : undefined;
        if (isSet(uid)) {
            var link = this.currentProjectStore.instance.settingsLayoutLink('appearance', 'menu', uid);
            this.routing.navigateApp(link);
        }
    };
    ProjectSettingsComponent.prototype.onMenuItemClicked = function (name) {
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.AppSettings.MenuItemClicked, {
            Name: name
        });
    };
    ProjectSettingsComponent.prototype.onSave = function () {
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.Project.BuilderChange, {
            Type: 'app_settings'
        });
    };
    // TODO: Fix empty handler
    ProjectSettingsComponent.prototype.closePopup = function (uid) { };
    return ProjectSettingsComponent;
}());
export { ProjectSettingsComponent };
