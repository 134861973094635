<form [formGroup]="form" (ngSubmit)="submit()">
  <app-customize-bar-header
    [title]="form.controls.title.value || (titleEditable ? 'card' : 'Card')"
    [titleEnabled]="titleEnabled"
    [titleEditable]="titleEditable"
    [titleCleanValue]="titleCleanValue"
    [backLabel]="'All components'"
    [deleteEnabled]="deleteEnabled"
    [parentLabel]="parentElement?.displayName || parentPopup?.displayName"
    (parentClick)="parentElement?.customize({ event: $event }) || parentPopup?.customize({ event: $event })"
    (deleteClick)="delete()"
    (titleChanged)="onTitleChanged($event)"
  >
  </app-customize-bar-header>

  <div class="sidebar__content">
    <app-sidebar-tabs>
      <app-sidebar-tab [label]="'General'">
        <div class="sidebar__element">
          <app-sidebar-field
            [label]="'Conditional Visibility'"
            [documentation]="'conditional-visibility'"
            [documentationLabel]="'See how it works'"
            [documentationSource]="element.analyticsGenericName + '_visible'"
          >
            <app-input-edit
              [itemForm]="form.controls.visible_input"
              [context]="context"
              [staticValueDisabled]="true"
              [placeholder]="'Value'"
              [fill]="true"
              [analyticsSource]="element.analyticsGenericName + '_visible'"
            ></app-input-edit>

            <ng-container description>
              Example: elements.field.value >= 10
            </ng-container>
          </app-sidebar-field>
        </div>

        <div class="sidebar__element">
          <app-sidebar-field [label]="'load content when hidden'" [layoutLeft]="true">
            <app-auto-field
              [form]="form"
              [label]="false"
              [field]="
                createField({
                  name: 'load_invisible',
                  field: 'BooleanField',
                  params: { style: booleanFieldStyle.Toggle, orange: true }
                })
              "
            ></app-auto-field>

            <ng-container description>This can decrease performance</ng-container>
          </app-sidebar-field>
        </div>
      </app-sidebar-tab>

      <app-sidebar-tab [label]="'Display'">
        <div class="sidebar__element">
          <app-styles-element-wrapper [control]="form.controls.element_styles"></app-styles-element-wrapper>
        </div>
      </app-sidebar-tab>
    </app-sidebar-tabs>
  </div>

  <!--  <app-sidebar-submit-->
  <!--    [saveDisabled]="form.form.invalid || form.form.disabled"-->
  <!--    [errors]="form.form | appFormErrors"-->
  <!--    (cancelClick)="cancel()"-->
  <!--    (deleteClick)="delete()"-->
  <!--  >-->
  <!--  </app-sidebar-submit>-->
</form>
