import { OnDestroy } from '@angular/core';
import toPairs from 'lodash/toPairs';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { getColorHexStr } from '@modules/colors';
import { isSet } from '@shared';
import { getThemeVars, themeVars } from '../utils/common';
var ThemeContext = /** @class */ (function () {
    function ThemeContext() {
        this._options = new BehaviorSubject({});
    }
    ThemeContext.prototype.ngOnDestroy = function () {
        this.clearContainersStyle();
    };
    Object.defineProperty(ThemeContext.prototype, "options", {
        get: function () {
            return this._options.value;
        },
        set: function (value) {
            this._options.next(value);
        },
        enumerable: true,
        configurable: true
    });
    ThemeContext.prototype.getOption = function (value) {
        return this.options[value];
    };
    ThemeContext.prototype.getOptions$ = function () {
        return this._options.asObservable();
    };
    ThemeContext.prototype.getOption$ = function (value) {
        return this.getOptions$().pipe(map(function (item) { return item[value]; }));
    };
    ThemeContext.prototype.getColorHex = function (value) {
        var color = this.getOption(value);
        if (!isSet(color)) {
            return;
        }
        return getColorHexStr(color);
    };
    ThemeContext.prototype.getColorHex$ = function (value) {
        return this.getOption$(value).pipe(map(function (color) {
            if (!isSet(color)) {
                return;
            }
            return getColorHexStr(color);
        }));
    };
    ThemeContext.prototype.applyContainersStyle = function () {
        var vars = getThemeVars(this.options);
        toPairs(vars).forEach(function (_a) {
            var k = _a[0], v = _a[1];
            var property = "--" + k;
            if (isSet(v)) {
                document.body.style.setProperty(property, String(v));
            }
            else {
                document.body.style.removeProperty(property);
            }
        });
        var attrDataStyleVars = toPairs(vars)
            .map(function (_a) {
            var k = _a[0];
            return k;
        })
            .join(' ');
        document.body.setAttribute('data-style-vars', attrDataStyleVars);
    };
    ThemeContext.prototype.clearContainersStyle = function () {
        themeVars.forEach(function (k) {
            var property = "--" + k;
            document.body.style.removeProperty(property);
        });
        document.body.removeAttribute('data-style-vars');
    };
    return ThemeContext;
}());
export { ThemeContext };
