<div
  class="element"
  [class.element_hover]="hover"
  [class.element_active]="customizingElement || resizingElement"
  [class.element_container]="true"
  [class.element_customizable]="customizing"
  [class.element_resizing]="resizingElement"
  [class.element_disable]="false"
  [style.width]="width ? width + 'px' : '100%'"
  [class.handle]="false"
  [class.handle_disable]="false"
  (mouseenter)="onHover(true)"
  (mouseleave)="onHover(false)"
  (mousedown)="onMouseDown($event)"
  appResizable
  (resizeStarted)="onResizeStarted()"
  (resizeFinished)="onResize($event)"
>
  <div
    class="element__toolbar"
    [class.element__toolbar_inside]="popup.isSide()"
    (click)="customize({ event: $event }); markElementEvent($event)"
  >
    <div class="customize-toolbar-item">
      <div class="customize-selector" [class.customize-selector_active]="customizingElement">
        <div class="customize-selector__handle">
          <span class="customize-selector__label-drag icon-dots_small"></span>
          <span class="customize-selector__label">{{ popup.name || 'Modal' }}</span>
        </div>

        <div class="customize-selector__separator"></div>
        <div class="customize-selector__controls">
          <a
            href="javascript:void(0)"
            class="customize-selector-control"
            tabindex="-1"
            [appTip]="'Customize'"
            [appTipOptions]="{ side: 'top' }"
            (click)="customize({ event: $event, highlight: true }); markElementEvent($event)"
          >
            <span class="customize-selector-control__icon icon-gear"></span>
          </a>

          <a
            href="javascript:void(0)"
            class="customize-selector-control"
            tabindex="-1"
            [appTip]="'Duplicate'"
            [appTipOptions]="{ side: 'top' }"
            (click)="duplicatePopup.emit()"
          >
            <span class="customize-selector-control__icon icon-duplicate_2"></span>
          </a>

          <ng-template
            [cdkConnectedOverlayBackdropClass]="'popover2-backdrop'"
            [cdkConnectedOverlayOpen]="movePagesOpened"
            [cdkConnectedOverlayOrigin]="move_pages_trigger"
            [cdkConnectedOverlayPanelClass]="['overlay', 'overlay_horizontal_center', 'theme_dark']"
            [cdkConnectedOverlayPositions]="movePagesPositions"
            [cdkConnectedOverlayPush]="true"
            [cdkConnectedOverlayGrowAfterOpen]="true"
            [cdkConnectedOverlayHasBackdrop]="true"
            (backdropClick)="setMovePagesOpened(false)"
            (detach)="setMovePagesOpened(false)"
            cdkConnectedOverlay
          >
            <app-choose-page-dropdown
              class="customize-selector-control__dropdown overlay-content overlay-content_animated"
              [currentPage]="viewSettings"
              [currentPageAllowed]="false"
              (chosen)="movePopupToPage.emit($event); setMovePagesOpened(false)"
            ></app-choose-page-dropdown>
          </ng-template>

          <a
            href="javascript:void(0)"
            class="customize-selector-control"
            tabindex="-1"
            [appTip]="'Move to another Page'"
            [appTipOptions]="{ side: 'top' }"
            (click)="setMovePagesOpened(true)"
            cdkOverlayOrigin
            #move_pages_trigger="cdkOverlayOrigin"
          >
            <span class="customize-selector-control__icon icon-redo"></span>
          </a>

          <a
            href="javascript:void(0)"
            class="customize-selector-control"
            tabindex="-1"
            [appTip]="'Delete'"
            [appTipOptions]="{ side: 'top' }"
            (click)="deletePopup.emit()"
          >
            <span class="customize-selector-control__icon icon-bin"></span>
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="element__content">
    <div
      class="custom-page-popup custom-page-popup_theme"
      [class.custom-page-popup_style_background]="popup.isBackground()"
      [class.custom-page-popup_style_side]="popup.isSide()"
      [class.custom-page-popup_style_origin]="popup.isOrigin()"
      [class.custom-page-popup_top-padding]="customizing && (title | appIsSet) && popup.isSide()"
    >
      <div
        class="custom-page-popup__header"
        [class.custom-page-popup__header_overlay]="!(title | appIsSet) && !popup.isSide()"
        [class.custom-page-popup__header_right]="!(title | appIsSet) && popup.isSide()"
        [class.custom-page-popup__header_small]="popup.isOrigin()"
      >
        <div *ngIf="title | appIsSet" class="custom-page-popup__header-main">
          <div class="custom-page-popup__title">{{ title }}</div>
        </div>
        <div
          *ngIf="closeEnabled && popup.closeButton"
          class="custom-page-popup__header-right"
          [class.custom-page-popup__header-right_inside]="popup.isOrigin()"
        >
          <span
            class="custom-page-popup__close icon-close"
            [class.custom-page-popup__close_background]="!(title | appIsSet) && !popup.isSide() && !popup.isOrigin()"
            (click)="$event.stopPropagation(); close()"
          ></span>
        </div>
      </div>
      <div class="custom-page-popup__content">
        <div
          [class.element-wrapper]="!popup.isBackground()"
          [class.element-wrapper_wrap]="!popup.isBackground()"
          [class.element-wrapper_popup]="!popup.isBackground()"
        >
          <app-root-layout
            *ngIf="popup.elements"
            [elements]="popup.elements"
            [context]="context"
            [page]="viewSettings"
            [popup]="popup"
            [templates]="false"
            [fill]="false"
            [active]="visible"
          >
          </app-root-layout>
        </div>
      </div>
    </div>
  </div>

  <span (click)="customize({ event: $event }); markElementEvent($event)">
    <span
      class="element__border"
      [class.element__border_large-border-radius]="!popup.isSide()"
      [class.element__border_no-border-radius]="popup.isSide()"
      [class.element__border_error]="false"
      [class.element__border_always]="false"
    ></span>

    <ng-container *ngIf="!popup.isSide()">
      <span
        class="element__bound element__bound_position_left"
        [appResizableHandle]="[resizeTypes.Horizontal]"
        [appResizableHandleSpeedHorizontal]="2"
        [appResizableHandleReverseHorizontal]="true"
        [appResizableHandleStepHorizontal]="resizeStepSize"
        [appResizableHandleMinSize]="minSize"
        [appResizableHandleCheckHorizontalBounds]="false"
      ></span>

      <span
        class="element__bound element__bound_position_right"
        [appResizableHandle]="[resizeTypes.Horizontal]"
        [appResizableHandleSpeedHorizontal]="2"
        [appResizableHandleStepHorizontal]="resizeStepSize"
        [appResizableHandleMinSize]="minSize"
        [appResizableHandleCheckHorizontalBounds]="false"
      ></span>

      <span
        class="element__handle element__handle_position_left"
        [appResizableHandle]="[resizeTypes.Horizontal]"
        [appResizableHandleSpeedHorizontal]="2"
        [appResizableHandleReverseHorizontal]="true"
        [appResizableHandleStepHorizontal]="resizeStepSize"
        [appResizableHandleMinSize]="minSize"
        [appResizableHandleCheckHorizontalBounds]="false"
      ></span>

      <span
        class="element__handle element__handle_position_right"
        [appResizableHandle]="[resizeTypes.Horizontal]"
        [appResizableHandleSpeedHorizontal]="2"
        [appResizableHandleReverseHorizontal]="false"
        [appResizableHandleStepHorizontal]="resizeStepSize"
        [appResizableHandleMinSize]="minSize"
        [appResizableHandleCheckHorizontalBounds]="false"
      ></span>
    </ng-container>

    <ng-container *ngIf="popup.isSide()">
      <span
        class="element__bound element__bound_position_left"
        [appResizableHandle]="[resizeTypes.Horizontal]"
        [appResizableHandleReverseHorizontal]="true"
        [appResizableHandleStepHorizontal]="resizeStepSize"
        [appResizableHandleMinSize]="minSize"
        [appResizableHandleCheckHorizontalBounds]="false"
      ></span>

      <span
        class="element__handle element__handle_position_left"
        [appResizableHandle]="[resizeTypes.Horizontal]"
        [appResizableHandleReverseHorizontal]="true"
        [appResizableHandleStepHorizontal]="resizeStepSize"
        [appResizableHandleMinSize]="minSize"
        [appResizableHandleCheckHorizontalBounds]="false"
      ></span>
    </ng-container>

    <!--    <span-->
    <!--      class="element__square element__square_type_horizontal element__square_active element__square_position_top-left"-->
    <!--      [appResizableHandle]="[resizeTypes.Horizontal]"-->
    <!--      [appResizableHandleSpeedHorizontal]="2"-->
    <!--      [appResizableHandleReverseHorizontal]="true"-->
    <!--      [appResizableHandleMinSize]="minSize"-->
    <!--    >-->
    <!--      <span class="element__square-inner"></span>-->
    <!--    </span>-->
    <!--    <span-->
    <!--      class="element__square element__square_type_horizontal element__square_active element__square_position_top-right"-->
    <!--      [appResizableHandle]="[resizeTypes.Horizontal]"-->
    <!--      [appResizableHandleSpeedHorizontal]="2"-->
    <!--      [appResizableHandleMinSize]="minSize"-->
    <!--    >-->
    <!--      <span class="element__square-inner"></span>-->
    <!--    </span>-->
    <!--    <span-->
    <!--      class="element__square element__square_type_horizontal element__square_active element__square_position_bottom-left"-->
    <!--      [appResizableHandle]="[resizeTypes.Horizontal]"-->
    <!--      [appResizableHandleSpeedHorizontal]="2"-->
    <!--      [appResizableHandleReverseHorizontal]="true"-->
    <!--      [appResizableHandleMinSize]="minSize"-->
    <!--    >-->
    <!--      <span class="element__square-inner"></span>-->
    <!--    </span>-->
    <!--    <span-->
    <!--      class="element__square element__square_type_horizontal element__square_active element__square_position_bottom-right"-->
    <!--      [appResizableHandle]="[resizeTypes.Horizontal]"-->
    <!--      [appResizableHandleSpeedHorizontal]="2"-->
    <!--      [appResizableHandleMinSize]="minSize"-->
    <!--    >-->
    <!--      <span class="element__square-inner"></span>-->
    <!--    </span>-->
  </span>
</div>
