var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Task } from '../task';
import { TaskScenario } from '../task-scenario';
var InviteUserTask = /** @class */ (function (_super) {
    __extends(InviteUserTask, _super);
    function InviteUserTask() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    InviteUserTask.prototype.init = function () {
        return new TaskScenario([
            {
                link: ['project', 'users'],
                items: [
                    {
                        name: 'click_invite_user'
                    }
                ]
            },
            {
                link: ['project', 'users', 'create'],
                items: [
                    {
                        name: 'choose_user'
                    },
                    {
                        name: 'choose_group'
                    },
                    {
                        name: 'send_invite'
                    },
                    {
                        name: 'finished'
                    }
                ]
            }
        ]);
    };
    return InviteUserTask;
}(Task));
export { InviteUserTask };
