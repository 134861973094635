import { ChangeDetectorRef, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as Color from 'color';
import clamp from 'lodash/clamp';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';
import { TintStyle } from '@modules/actions';
import { getColorHexAStr, getColorVariable, parseColor, PickColorService } from '@modules/colors';
import { NumberFieldType } from '@modules/fields';
import { controlValue, deployUrl, isSet, KeyboardEventKeyCode, parseNumber } from '@shared';
var ColorPickerComponent = /** @class */ (function () {
    function ColorPickerComponent(el, pickColorService, cd) {
        this.el = el;
        this.pickColorService = pickColorService;
        this.cd = cd;
        this.alphaEnabled = false;
        this.alphaDefault = 100;
        this.theme = false;
        this.hexControlInternal = new FormControl();
        this.hexControlInternalUpdate = false;
        this.hueControl = new FormControl(0);
        this.saturationControl = new FormControl(100);
        this.luminosityControl = new FormControl(50);
        this.alphaControl = new FormControl(this.alphaDefault);
        // hexControl = new FormControl('#2B50ED');
        this.hueBackground = 'linear-gradient(to right, #FF0000 0%, #FF7D00 12.38%, #FFFF00 19.52%, #00FF00 35.71%, #00FFFF 51.43%, #0000FF 65.72%, #FF00FF 83.33%, #FF0000 100%)';
        this.tintStyles = TintStyle;
        this.numberFieldTypes = NumberFieldType;
    }
    ColorPickerComponent.prototype.ngOnInit = function () {
        this.initHex();
    };
    ColorPickerComponent.prototype.ngOnDestroy = function () { };
    ColorPickerComponent.prototype.initHex = function () {
        var _this = this;
        this.hexControlInternal.valueChanges.pipe(untilDestroyed(this)).subscribe(function (value) {
            if (isSet(value)) {
                if (_this.hexControlInternalUpdate) {
                    _this.hexControlInternalUpdate = false;
                    return;
                }
                var cleanValue = value.toUpperCase().replace(/[^0-9A-F]/g, '');
                if (cleanValue[0] != '#') {
                    cleanValue = '#' + cleanValue;
                }
                cleanValue = cleanValue.substring(0, 9);
                if (cleanValue != value) {
                    _this.hexControlInternal.patchValue(cleanValue);
                    _this.cd.markForCheck();
                }
                else {
                    _this.hexControl.patchValue(cleanValue);
                }
            }
        });
        combineLatest(controlValue(this.hueControl), controlValue(this.saturationControl), controlValue(this.luminosityControl))
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var hueRaw = _a[0], saturationRaw = _a[1], luminosityRaw = _a[2];
            var colorful = saturationRaw != 0 && luminosityRaw != 0 && luminosityRaw != 100;
            var hue = hueRaw || 0;
            var saturation = clamp(saturationRaw || 0, 20, 100);
            var luminosity = clamp(luminosityRaw || 0, 20, 80);
            var saturationL = saturationRaw > 0 ? saturation : 0;
            _this.hueThumbBorder = colorful ? Color({ h: hue, s: 100, l: 30 }).hex() : '#768191';
            _this.saturationBackground = "linear-gradient(to right, hsl(" + hue + ", 0%, " + luminosity + "%) 0%, hsl(" + hue + ", 100%, " + luminosity + "%) 100%)";
            _this.luminosityBackground = "linear-gradient(to right, hsl(" + hue + ", " + saturationL + "%, 0%) 0%, hsl(" + hue + ", " + saturationL + "%, 50%) 50%, hsl(" + hue + ", " + saturation + "%, 100%) 100%)";
            _this.alphaBackground = [
                "linear-gradient(to right, hsl(" + hue + ", " + saturation + "%, " + luminosity + "%, 0) 0%, hsl(" + hue + ", " + saturation + "%, " + luminosity + "%, 1) 100%)",
                "#fff center center / 10px url(" + deployUrl('/assets/images/transparent.svg') + ")"
            ].join(', ');
            _this.cd.markForCheck();
        });
        controlValue(this.hexControl)
            .pipe(untilDestroyed(this))
            .subscribe(function (value) {
            if (isSet(value)) {
                var globalClr = _this.getGlobalColor(value);
                try {
                    var clr = globalClr || Color(value);
                    var hsl = clr.hsl();
                    var _a = hsl.color, hue = _a[0], saturation = _a[1], luminosity = _a[2];
                    var alpha = clr.alpha();
                    _this.hexControlInternalUpdate = true;
                    _this.hexControlInternal.patchValue(getColorHexAStr(clr));
                    _this.hueControl.patchValue(hue);
                    _this.saturationControl.patchValue(saturation);
                    _this.luminosityControl.patchValue(luminosity);
                    _this.alphaControl.patchValue(alpha * 100);
                }
                catch (e) { }
            }
            else {
                _this.hexControlInternalUpdate = true;
                _this.hexControlInternal.patchValue(undefined);
                _this.hueControl.patchValue(0);
                _this.saturationControl.patchValue(100);
                _this.luminosityControl.patchValue(50);
                _this.alphaControl.patchValue(_this.alphaDefault);
            }
        });
    };
    ColorPickerComponent.prototype.getGlobalColor = function (global) {
        var variable = getColorVariable(global);
        if (!isSet(variable)) {
            return;
        }
        var globalColor = getComputedStyle(this.el.nativeElement).getPropertyValue("--" + variable);
        return parseColor(globalColor);
    };
    ColorPickerComponent.prototype.getCurrentColor = function () {
        return Color({
            h: this.hueControl.value,
            s: this.saturationControl.value,
            l: this.luminosityControl.value,
            alpha: this.alphaControl.value / 100
        });
    };
    ColorPickerComponent.prototype.setHue = function (value) {
        var clr = this.getCurrentColor().hue(value);
        this.hexControl.patchValue(getColorHexAStr(clr));
    };
    ColorPickerComponent.prototype.setSaturation = function (value) {
        var clr = this.getCurrentColor().saturationl(value);
        this.hexControl.patchValue(getColorHexAStr(clr));
    };
    ColorPickerComponent.prototype.setLuminosity = function (value) {
        var clr = this.getCurrentColor().lightness(value);
        this.hexControl.patchValue(getColorHexAStr(clr));
    };
    ColorPickerComponent.prototype.setAlpha = function (value) {
        var clr = this.getCurrentColor().alpha(value / 100);
        this.hexControl.patchValue(getColorHexAStr(clr));
    };
    ColorPickerComponent.prototype.updateCurrentValue = function () {
        var clr = this.getCurrentColor();
        this.hexControl.patchValue(getColorHexAStr(clr));
    };
    ColorPickerComponent.prototype.pickColor = function () {
        var _this = this;
        this.pickColorService
            .pick()
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            if (isSet(result)) {
                _this.hexControl.patchValue(result.toUpperCase());
            }
        }, function () { });
    };
    ColorPickerComponent.prototype.onControlKey = function (e, control, input) {
        if (e.keyCode == KeyboardEventKeyCode.ArrowUp) {
            e.stopPropagation();
            e.preventDefault();
            this.increment(e, control, input, true);
        }
        else if (e.keyCode == KeyboardEventKeyCode.ArrowDown) {
            e.stopPropagation();
            e.preventDefault();
            this.increment(e, control, input, false);
        }
    };
    ColorPickerComponent.prototype.increment = function (e, control, input, add) {
        var delta = this.getDelta(e);
        var value = control.value;
        var numberDefault = 0;
        var valueNumber = isSet(value) ? parseNumber(value, numberDefault) : numberDefault;
        var newValue = add ? valueNumber + delta : valueNumber - delta;
        control.patchValue(newValue);
        this.updateCurrentValue();
        setTimeout(function () { return input.select(); }, 0);
    };
    ColorPickerComponent.prototype.getDelta = function (e) {
        if (e.shiftKey) {
            return 10;
        }
        else {
            return 1;
        }
    };
    return ColorPickerComponent;
}());
export { ColorPickerComponent };
