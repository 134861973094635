import { moveItemInArray } from '@angular/cdk/drag-drop';
import { AbstractControlOptions, AsyncValidatorFn, FormArray, ValidatorFn } from '@angular/forms';
import isEqual from 'lodash/isEqual';
import range from 'lodash/range';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DisplayField, DisplayFieldType, ParameterField } from '@modules/fields';
import { controlValue, isSet } from '@shared';

import { DisplayFieldControl, DisplayFieldControls } from './display-field.control';

export class DisplayFieldArray extends FormArray {
  controls: DisplayFieldControl[];

  constructor(
    controls: DisplayFieldControl[] = [],
    validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null
  ) {
    super(controls, validatorOrOpts, asyncValidator);
  }

  patchValue(value: DisplayField[], options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    this.deserialize(value);
  }

  setValue(value: any[], options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    this.deserialize(value);
  }

  get value(): DisplayField[] {
    return this.serialize();
  }

  set value(value: DisplayField[]) {}

  deserialize(value: DisplayField[]) {
    value.forEach((item, i) => {
      const control = this.controls[i];

      if (control) {
        control.deserialize(item);
      } else {
        this.appendControl(item);
      }
    });

    this.controls.slice(value.length).forEach(item => this.removeControl(item));
  }

  serialize(reuseInstance = true): DisplayField[] {
    return this.controls.map(control => control.serialize(reuseInstance)).filter(item => isSet(item.name));
  }

  setControls(controls: DisplayFieldControl[]) {
    this.removeControls();
    controls.forEach(item => this.push(item));
  }

  removeControls() {
    range(this.controls.length).forEach(() => this.removeAt(0));
  }

  removeControl(control: DisplayFieldControl) {
    const newControls = this.controls.filter(item => !isEqual(item, control));
    this.setControls(newControls);
  }

  createControl(item?: DisplayField, value?: Partial<DisplayFieldControls>): DisplayFieldControl {
    const control = new DisplayFieldControl();

    if (item) {
      control.deserialize(item);
    }

    if (value) {
      control.patchValue(value);
    }

    control.markAsPristine();

    return control;
  }

  prependControl(item?: DisplayField, value?: Partial<DisplayFieldControls>): DisplayFieldControl {
    const control = this.createControl(item, value);
    this.insert(0, control);
    return control;
  }

  appendControl(item?: DisplayField, value?: Partial<DisplayFieldControls>): DisplayFieldControl {
    const control = this.createControl(item, value);
    this.push(control);
    return control;
  }

  getMoveToTopPosition(itemForm: DisplayFieldControl) {
    const currentIndex = this.controls.indexOf(itemForm);
    const lastVisibleIndex = this.controls
      .map((item, i) => i < currentIndex && item.controls.visible.value)
      .lastIndexOf(true);
    return lastVisibleIndex != -1 ? lastVisibleIndex + 1 : currentIndex;
  }

  moveToTop(itemForm: DisplayFieldControl) {
    if (!itemForm.controls.visible.value) {
      itemForm.controls.visible.patchValue(true);
    }

    const currentIndex = this.controls.indexOf(itemForm);
    const newIndex = this.getMoveToTopPosition(itemForm);

    moveItemInArray(this.controls, currentIndex, newIndex);
  }

  isToggledAll(): boolean {
    return this.controls
      .filter(item => !item.instance || item.instance.type == DisplayFieldType.Base)
      .every(item => item.controls.visible.value);
  }

  toggleAll() {
    const toggledAll = this.isToggledAll();
    this.controls
      .filter(item => !item.instance || item.instance.type == DisplayFieldType.Base)
      .forEach((item, i) => {
        if (toggledAll) {
          item.controls.visible.patchValue(i == 0);
        } else {
          item.controls.visible.patchValue(true);
        }
      });
  }

  getParameters(): ParameterField[] {
    return this.value.map(item => {
      const parameter = new ParameterField();

      parameter.name = item.name;
      parameter.verboseName = item.verboseName;
      parameter.field = item.field;
      parameter.params = item.params;
      parameter.updateFieldDescription();

      return parameter;
    });
  }

  getParameters$(): Observable<ParameterField[]> {
    return controlValue(this).pipe(map(() => this.getParameters()));
  }
}
