<div
  *ngIf="isVisible || (customizeService.enabled$ | async)"
  class="kanban-board-column"
  [class.kanban-board-column_theme]="theme"
>
  <div class="kanban-board-column__header">
    <div class="kanban-board-column__header-main">
      <div
        class="kanban-board-column__title"
        [appTextStyle]="settings.stageTitleStyle"
        [appTextStyleGlobal]="'kanbanStageTitleTextStyle'"
        [appTextStyleColor]="stage.color"
        [appTextStyleBackgroundColor]="(stage.color | appIsSet) ? backgroundCustomColor(stage.color) : null"
      >
        <ng-container *ngIf="stage.name | appIsSet">{{ stage.name }}</ng-container>
        <ng-container *ngIf="!(stage.name | appIsSet) && (stage.value | appIsSet)">{{ stage.value }}</ng-container>
        <ng-container *ngIf="!(stage.name | appIsSet) && !(stage.value | appIsSet)">---</ng-container>
      </div>
    </div>

    <div *ngIf="listStore.items !== undefined" class="kanban-board-column__header-right">
      <span class="kanban-board-column__tip">
        {{ listStore.count$ | async }}
      </span>

      <span
        class="kanban-board-column__action icon-repeat"
        [class.kanban-board-column__action_disabled]="loading"
        (click)="reloadData()"
      ></span>
    </div>
  </div>
  <div class="kanban-board-column__scrollable" xsScrollable>
    <div *ngIf="loading" class="kanban-board-column__content">
      <app-kanban-board-stub-content
        [items]="[1, 2, 3]"
        [animating]="true"
        [theme]="theme"
      ></app-kanban-board-stub-content>
    </div>

    <div *ngIf="!loading && error" class="kanban-board-column__message">
      {{ error }}
    </div>

    <ng-container *ngIf="!loading && !error && items">
      <div
        cdkDropList
        [cdkDropListData]="items"
        [cdkDropListConnectedTo]="droppable"
        [id]="uniqueName"
        (cdkDropListEntered)="listEntered($event)"
        (cdkDropListDropped)="listDropped($event)"
        class="kanban-board-column__content"
        [style.margin-top.px]="(settings.gapVertical | appIsSet) ? -settings.gapVertical * 0.5 + 8 : null"
        [style.margin-bottom.px]="(settings.gapVertical | appIsSet) ? -settings.gapVertical * 0.5 + 8 : null"
      >
        <div
          *ngFor="let item of items; let i = index; trackBy: trackItemFn"
          cdkDrag
          [cdkDragData]="item"
          (cdkDragStarted)="listDragStarted($event)"
          class="kanban-board-column__item"
          [style.padding-left.px]="(settings.gapHorizontal | appIsSet) ? settings.gapHorizontal : null"
          [style.padding-right.px]="(settings.gapHorizontal | appIsSet) ? settings.gapHorizontal : null"
          [style.padding-top.px]="(settings.gapVertical | appIsSet) ? settings.gapVertical * 0.5 : null"
          [style.padding-bottom.px]="(settings.gapVertical | appIsSet) ? settings.gapVertical * 0.5 : null"
        >
          <app-kanban-board-item
            [item]="item"
            [modelDescription]="modelDescription"
            [settings]="settings"
            [dataSource]="dataSource"
            [visibleColumns]="visibleColumns"
            [draggable]="true"
            [context]="context"
            [contextElement]="contextElement"
            [selected]="isItemSelected(item, i)"
            [accentColor]="accentColor"
            [theme]="theme"
            (select)="toggleSelectedItem(item, i)"
            (modelUpdated)="modelUpdated.next($event)"
          >
          </app-kanban-board-item>
        </div>

        <app-kanban-board-stub-content
          *ngIf="loadingMore"
          [items]="[1]"
          [animating]="true"
          [theme]="theme"
        ></app-kanban-board-stub-content>
      </div>

      <div *ngIf="!items.length" class="kanban-board-column__message kanban-board-column__message_column-empty">
        {{ 'Nothing found' | localize }}
      </div>

      <div *ngIf="!loadingMore && (listStore.hasMore$ | async)" class="kanban-board-column__footer">
        <a href="javascript:void(0)" class="kanban-board-column__link" (click)="fetchMore()">
          Load more
        </a>
      </div>
    </ng-container>
  </div>
</div>

<span
  *ngIf="!isVisible && (customizeService.enabled$ | async)"
  class="element__indicator icon-eye"
  [appTip]="'Component is hidden'"
></span>
