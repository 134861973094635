import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';

import { Input } from '@modules/fields';
import { FieldInputControl } from '@modules/parameters';
import { ConditionWorkflowStepItem } from '@modules/workflow';

@Injectable()
export class ConditionCustomizeWorkflowStepItemControl extends FormGroup {
  instance: ConditionWorkflowStepItem;

  controls: {
    label: FormControl;
    condition: FieldInputControl;
  };

  constructor() {
    super({
      label: new FormControl(''),
      condition: new FieldInputControl({ path: ['value'] })
    });
  }

  init(instance: ConditionWorkflowStepItem, options: { firstInit?: boolean } = {}) {
    this.instance = instance;

    if (instance) {
      this.controls.label.patchValue(instance.label);
      this.controls.condition.patchValue(instance.condition ? instance.condition.serializeWithoutPath() : {});
    }

    if (!options.firstInit) {
      this.markAsDirty();
    }
  }

  submit(): ConditionWorkflowStepItem {
    const instance = this.instance
      ? (cloneDeep(this.instance) as ConditionWorkflowStepItem)
      : new ConditionWorkflowStepItem();

    if (!instance.uid) {
      instance.generateUid();
    }

    instance.label = this.controls.label.value;

    if (this.controls.condition.value) {
      instance.condition = new Input().deserialize(this.controls.condition.value);
    } else {
      instance.condition = undefined;
    }

    return instance;
  }
}
