import { Injectable, Injector } from '@angular/core';
import { FormArray, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import isEqual from 'lodash/isEqual';
import range from 'lodash/range';
import values from 'lodash/values';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { AppFormGroup, FormUtils } from '@common/form-utils';
import { PopupService } from '@common/popups';
import { ServerRequestError } from '@modules/api';
import { Option } from '@modules/field-components';
import { MenuGeneratorService } from '@modules/menu';
import { ProjectSettingsService } from '@modules/project-settings';
import { ProjectTokenService, ResourceType, SecretTokenService } from '@modules/projects';
import {
  AirtableAuthType,
  AirtableGeneratorService,
  AirtableParamsOptions,
  AirtableParamsOptionsTable,
  IsOptionsValidResult
} from '@modules/resource-generators';
import {
  AirtableResourceController,
  AirtableTableResponse,
  AirtableTablesResponse,
  AirtableTableViewType,
  ResourceControllerService,
  ResourceParamsResult
} from '@modules/resources';
import { ascComparator, isSet } from '@shared';

import { BaseResourceSettingsForm } from '../base-resource-settings/base-resource-settings.form';

export class AirtableTableControl extends FormGroup {
  table: AirtableTableResponse;
  viewOptions: Option[] = [];

  controls: {
    name: FormControl;
    view: FormControl;
    active: FormControl;
  };

  constructor(
    table: AirtableTableResponse,
    state: {
      view?: string;
      active?: boolean;
    }
  ) {
    super({
      view: new FormControl(isSet(state.view) ? state.view : undefined),
      active: new FormControl(isSet(state.active) ? state.active : false)
    });

    this.table = table;
    this.viewOptions = table.views.map(item => {
      let icon: string;

      if (item.type == AirtableTableViewType.Grid) {
        icon = 'table';
      } else if (item.type == AirtableTableViewType.Form) {
        icon = 'bills';
      } else if (item.type == AirtableTableViewType.Calendar) {
        icon = 'calendar';
      } else if (item.type == AirtableTableViewType.Gallery) {
        icon = 'blocks';
      } else if (item.type == AirtableTableViewType.Kanban) {
        icon = 'versions';
      } else if (item.type == AirtableTableViewType.Timeline) {
        icon = 'time';
      } else if (item.type == AirtableTableViewType.Gantt) {
        icon = 'fileds';
      }

      return {
        value: item.id,
        name: item.name,
        icon: icon
      };
    });
  }

  setActive(value: boolean) {
    this.controls.active.patchValue(value);
    this.controls.active.markAsTouched();
  }

  toggleActive() {
    this.setActive(!this.controls.active.value);
  }
}

export function validateAirtableArray(): ValidatorFn {
  return (control: AirtableTableArray): { [key: string]: any } | null => {
    const selected = control.controls.filter(item => item.controls.active.value).length;

    if (!selected) {
      return { local: ['No tables selected'] };
    }
  };
}

export class AirtableTableArray extends FormArray {
  controls: AirtableTableControl[];

  initialValue?: AirtableParamsOptionsTable[];

  constructor(controls: AirtableTableControl[]) {
    super(controls, validateAirtableArray());
  }

  init(tables: AirtableTableResponse[]) {
    this.removeControls();

    tables
      .sort((lhs, rhs) => ascComparator(String(lhs.name).toUpperCase(), String(rhs.name).toUpperCase()))
      .forEach(table => {
        const control = this.appendControl(table);

        if (this.initialValue) {
          const initialTableValue = this.initialValue.find(item => item.id == table.id);
          control.controls.active.patchValue(!!initialTableValue);

          if (initialTableValue) {
            control.controls.view.patchValue(initialTableValue.view);
          }
        } else {
          control.controls.active.patchValue(true);
        }
      });

    this.updateValueAndValidity();
    this.markAsPristine();
  }

  setControls(controls: AirtableTableControl[]) {
    this.removeControls();
    controls.forEach(item => this.push(item));
  }

  removeControls() {
    range(this.controls.length).forEach(() => this.removeAt(0));
  }

  removeControl(control: AirtableTableControl) {
    const newControls = this.controls.filter(item => !isEqual(item, control));
    this.setControls(newControls);
  }

  createControl(table: AirtableTableResponse): AirtableTableControl {
    const control = new AirtableTableControl(table, {
      view: table.views.length ? table.views[0].id : undefined
    });
    control.markAsPristine();
    return control;
  }

  appendControl(table: AirtableTableResponse): AirtableTableControl {
    const control = this.createControl(table);
    this.push(control);
    return control;
  }

  getActiveControls(): AirtableTableControl[] {
    return this.controls.filter(item => item.controls.active.value);
  }

  isAllActive(): boolean {
    return this.controls.every(item => item.controls.active.value);
  }

  isAnyActive(): boolean {
    return this.controls.some(item => item.controls.active.value);
  }

  setAllActive(value: boolean) {
    this.controls.forEach(item => item.setActive(value));
  }

  toggleAllActive() {
    if (this.isAllActive()) {
      this.setAllActive(false);
    } else {
      this.setAllActive(true);
    }
  }
}

export function validateAuthControl(type: AirtableAuthType): ValidatorFn {
  return control => {
    const parent = control.parent as Form;

    if (!parent) {
      return;
    }

    const authType = parent.controls['auth_type'].value as AirtableAuthType;
    if (authType != type) {
      return;
    }

    if (!control.value) {
      return { required: true };
    }
  };
}

export class Form extends AppFormGroup {
  constructor() {
    super({
      auth_type: new FormControl(AirtableAuthType.OAuth, Validators.required),
      access_token: new FormControl(null, validateAuthControl(AirtableAuthType.OAuth)),
      params: new FormControl(null, validateAuthControl(AirtableAuthType.OAuth)),
      personal_access_token: new FormControl(null, validateAuthControl(AirtableAuthType.PersonalAccessToken)),
      base: new FormControl(null, Validators.required),
      choose_tables: new FormControl(false),
      sync: new FormControl(true),
      tables: new AirtableTableArray([])
    });
  }
}

@Injectable()
export class AirtableResourceSettingsForm extends BaseResourceSettingsForm<AirtableParamsOptions> {
  tokenNameOAuth = 'oauth_access_token';
  legacyOptions = false;

  form = new Form();

  constructor(
    private airtableGeneratorService: AirtableGeneratorService,
    private resourceControllerService: ResourceControllerService,
    protected secretTokenService: SecretTokenService,
    protected formUtils: FormUtils,
    protected projectSettingsService: ProjectSettingsService,
    protected projectTokenService: ProjectTokenService,
    protected popupService: PopupService,
    protected menuGeneratorService: MenuGeneratorService,
    protected injector: Injector
  ) {
    super(
      secretTokenService,
      formUtils,
      projectSettingsService,
      projectTokenService,
      popupService,
      menuGeneratorService,
      injector
    );
  }

  initResourceValue(): Observable<void> {
    return this.airtableGeneratorService.getParamsOptions(this.project, this.environment, this.resource).pipe(
      map(result => {
        this.legacyOptions = result.auth_type == AirtableAuthType.ApiKey;

        this.form.patchValue({
          auth_type: result.auth_type,
          access_token: result.access_token,
          params: result.token_params,
          personal_access_token: result.personal_access_token,
          base: result.base,
          sync: !!this.resource.isSynced()
        });

        this.tables.initialValue = result.tables;
      })
    );
  }

  get tables(): AirtableTableArray {
    return this.form.controls['tables'] as AirtableTableArray;
  }

  getOptions(): AirtableParamsOptions {
    return {
      auth_type: this.form.value['auth_type'] || AirtableAuthType.OAuth,
      access_token: this.form.value['access_token'],
      token_params: this.form.value['params'],
      personal_access_token: this.form.value['personal_access_token'],
      base: this.form.value['base'],
      tables: this.tables.getActiveControls().map(item => {
        return {
          id: item.table.id,
          view: item.controls.view.value
        };
      })
    };
  }

  isOptionsValid(): Observable<IsOptionsValidResult> {
    return this.airtableGeneratorService.isOptionsValid(this.getOptions());
  }

  isAuthValid(): boolean {
    if (this.form.controls['auth_type'].value == AirtableAuthType.OAuth) {
      return isSet(this.form.controls['access_token'].value) && isSet(this.form.controls['params'].value);
    } else if (this.form.controls['auth_type'].value == AirtableAuthType.PersonalAccessToken) {
      return isSet(this.form.controls['personal_access_token'].value);
    } else {
      return false;
    }
  }

  isBaseValid(): boolean {
    return this.isAuthValid() && isSet(this.form.controls['base'].value);
  }

  discoverTables(): Observable<AirtableTablesResponse> {
    const options = this.getOptions();
    const controller = this.resourceControllerService.get<AirtableResourceController>(ResourceType.Airtable);
    const accessToken =
      options.auth_type == AirtableAuthType.PersonalAccessToken ? options.personal_access_token : options.access_token;

    return controller.getBaseTables({ base: options.base, accessToken: accessToken, key: options.key }).pipe(
      tap(result => {
        if (!result.tables.length) {
          throw new ServerRequestError('Base does not have any valid Tables');
        }

        this.tables.init(result.tables);
      })
    );
  }

  getParams(): ResourceParamsResult | Observable<ResourceParamsResult> {
    const options = this.getOptions();
    return this.airtableGeneratorService.generateParams(this.project, this.environment, this.typeItem, options).pipe(
      map(result => {
        return {
          ...result,
          resourceName: this.resourceForm.value['name'],
          sync: this.form.value['sync'],
          syncModelDescriptions: this.getParamsSyncModelDescriptions(result)
        };
      })
    );
  }
}
