var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';
import { localize } from '@common/localize';
import { NotificationService } from '@common/notifications';
import { AppConfigService } from '@core';
import { GoogleAnalyticsService, UniversalAnalyticsService } from '@modules/analytics';
import { ApiService } from '@modules/api';
import { AuthService } from '@modules/auth';
import { MetaService } from '@modules/meta';
import { ProjectInviteService, ProjectPublicInviteService, ProjectsStore } from '@modules/projects';
import { RoutingService } from '@modules/routing';
import { CurrentUserStore } from '@modules/users';
import { LoginBaseComponent } from './login-base.component';
import { LoginForm } from './login.form';
var LoginComponent = /** @class */ (function (_super) {
    __extends(LoginComponent, _super);
    function LoginComponent(loginForm, routing, currentUserStore, activatedRoute, analyticsService, googleAnalyticsService, appConfigService, authService, notificationService, metaService, projectsStore, apiService, projectInviteService, projectPublicInviteService, cd) {
        var _this = _super.call(this, loginForm, routing, currentUserStore, activatedRoute, analyticsService, googleAnalyticsService, appConfigService, authService, notificationService, metaService, projectsStore, apiService, projectInviteService, projectPublicInviteService, cd) || this;
        _this.loginForm = loginForm;
        _this.routing = routing;
        _this.currentUserStore = currentUserStore;
        _this.activatedRoute = activatedRoute;
        _this.analyticsService = analyticsService;
        _this.googleAnalyticsService = googleAnalyticsService;
        _this.appConfigService = appConfigService;
        _this.authService = authService;
        _this.notificationService = notificationService;
        _this.metaService = metaService;
        _this.projectsStore = projectsStore;
        _this.apiService = apiService;
        _this.projectInviteService = projectInviteService;
        _this.projectPublicInviteService = projectPublicInviteService;
        _this.cd = cd;
        _this.domainLoading = false;
        _this.title = 'Good morning';
        _this.title = _this.getTitle();
        return _this;
    }
    LoginComponent.prototype.getTitle = function () {
        var hour = new Date().getHours();
        if (hour >= 0 && hour < 12) {
            return localize('Good morning');
        }
        else if (hour >= 12 && hour <= 17) {
            return localize('Good afternoon');
        }
        else {
            return localize('Good evening');
        }
    };
    LoginComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.metaService.set({ title: localize('Login') });
        this.domainLoading = true;
        this.cd.markForCheck();
        combineLatest(this.activatedRoute.params, this.activatedRoute.data, this.domain$)
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var params = _a[0], data = _a[1], domain = _a[2];
            _this.domainLoading = false;
            _this.cd.markForCheck();
            if (!params['project'] && domain && domain.defaultProject && !data['ignoreSelfSignOn']) {
                _this.routing.navigate(['/login', domain.defaultProject], { queryParamsHandling: 'merge' });
            }
            else if (domain && domain.ssoSettings.length == 1 && domain.disableEmailSignIn) {
                var result = _this.ssoLogin(domain.ssoSettings[0]);
                if (result.externalRedirect) {
                    _this.domainLoading = true;
                    _this.cd.markForCheck();
                }
            }
        }, function () {
            _this.domainLoading = false;
            _this.cd.markForCheck();
        });
    };
    LoginComponent.prototype.onInviteLoaded = function () {
        if (this.invite) {
            this.loginForm.form.patchValue({ username: this.invite.getEmail() });
        }
    };
    LoginComponent.prototype.submitJetAuth = function () {
        var _this = this;
        this.loginForm.markAsSubmit(true);
        var projectName = this.activatedRoute.snapshot.params['project'];
        var transferProjects = this.activatedRoute.snapshot.queryParams['transfer_projects'];
        this.submitLoading = true;
        this.cd.markForCheck();
        this.loginForm
            .submitJetAuth(projectName, transferProjects ? transferProjects.split(',') : undefined)
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            _this.onLogin();
        }, function () {
            _this.submitLoading = false;
            _this.cd.markForCheck();
        });
    };
    LoginComponent.prototype.submitSSOAuth = function (sso) {
        var _this = this;
        this.loginForm.markAsSubmit(true);
        var projectName = this.activatedRoute.snapshot.params['project'];
        var transferProjects = this.activatedRoute.snapshot.queryParams['transfer_projects'];
        this.submitLoading = true;
        this.cd.markForCheck();
        this.loginForm
            .submitSSOAuth(sso, projectName, transferProjects ? transferProjects.split(',') : undefined)
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            _this.onLogin();
        }, function () {
            _this.submitLoading = false;
            _this.cd.markForCheck();
        });
    };
    Object.defineProperty(LoginComponent.prototype, "signUpLink", {
        get: function () {
            var projectName = this.activatedRoute.snapshot.params['project'];
            var ignoreSelfSignOn = this.activatedRoute.snapshot.data['ignoreSelfSignOn'];
            if (projectName) {
                return ['/register', projectName];
            }
            else if (ignoreSelfSignOn) {
                return ['/user', 'register'];
            }
            else {
                return ['/register'];
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoginComponent.prototype, "restoreLink", {
        get: function () {
            var ignoreSelfSignOn = this.activatedRoute.snapshot.data['ignoreSelfSignOn'];
            if (ignoreSelfSignOn) {
                return ['/user', 'restore'];
            }
            else {
                return ['/restore'];
            }
        },
        enumerable: true,
        configurable: true
    });
    LoginComponent.prototype.cleanErrorForm = function () {
        this.loginForm.form.setErrors({});
    };
    return LoginComponent;
}(LoginBaseComponent));
export { LoginComponent };
