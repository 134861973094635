import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AggregateFunc, DatasetGroupLookup } from '@modules/charts';
import {
  ColumnsLayoutColumnElementItem,
  ColumnsLayoutElementItem,
  CustomViewSettings,
  FilterStyle,
  validateElementNames,
  VALUE_OUTPUT,
  ViewSettingsStore
} from '@modules/customize';
import { ChartType } from '@modules/dashboard';
import { Option } from '@modules/field-components';
import { FieldType, Input, InputValueType, OptionsType } from '@modules/fields';
import { ModelDescription } from '@modules/models';
import { Project, Resource } from '@modules/projects';
import { Template } from '@modules/template';
import { capitalize, isSet } from '@shared';

import { PersistentIdGenerator } from '../../utils/elements';
import { BackGenerator } from '../back-generator/back-generator.service';
import { ChartWidgetGenerator } from '../chart-widget-generator/chart-widget-generator.service';
import { CreateFormGenerator } from '../create-form-generator/create-form-generator.service';
import { DeleteButtonGenerator } from '../delete-button-generator/delete-button-generator.service';
import { DetailGenerator } from '../detail-generator/detail-generator.service';
import { FilterGenerator } from '../filter-generator/filter-generator.service';
import { GeneratorUtils } from '../generator-utils/generator-utils.service';
import { ListGenerator } from '../list-generator/list-generator.service';
import { UpdateFormGenerator } from '../update-form-generator/update-form-generator.service';
import { ValueWidgetGenerator } from '../value-widget-generator/value-widget-generator.service';
import { ViewSettingsGeneratorService } from '../view-settings-generator/view-settings-generator.service';

@Injectable()
export class DashboardGenerator {
  constructor(
    private viewSettingsStore: ViewSettingsStore,
    private viewSettingsGeneratorService: ViewSettingsGeneratorService,
    private createFormGenerator: CreateFormGenerator,
    private updateFormGenerator: UpdateFormGenerator,
    private detailGenerator: DetailGenerator,
    private deleteButtonGenerator: DeleteButtonGenerator,
    private backGenerator: BackGenerator,
    private listGenerator: ListGenerator,
    private filterGenerator: FilterGenerator,
    private valueWidgetGenerator: ValueWidgetGenerator,
    private chartWidgetGenerator: ChartWidgetGenerator,
    private generatorUtils: GeneratorUtils
  ) {}

  getModelPage(
    project: Project,
    resource: Resource,
    modelDescription: ModelDescription,
    uniqueName: string,
    options: {
      templates: Template[];
      listFields?: string[];
      // detailFields?: string[];
      idGenerator?: PersistentIdGenerator;
    }
  ): CustomViewSettings {
    const { segments, segmentOptions, dateField } = this.getModelDataSegments(modelDescription);
    const viewSettings = new CustomViewSettings();

    viewSettings.project = project.uniqueName;
    viewSettings.uniqueName = uniqueName;
    viewSettings.name = modelDescription.verboseNamePlural;
    viewSettings.resource = resource.uniqueName;
    viewSettings.model = modelDescription.model;
    viewSettings.configuredElements = 1;
    viewSettings.configuredModelElements = 1;
    viewSettings.newlyCreated = true;

    const row1Element = new ColumnsLayoutElementItem();

    row1Element.uid = options.idGenerator ? options.idGenerator.elementId('row1Element') : undefined;

    const valueWidgetSettings: { label?: string; filters?: { name: string; value: any }[] }[] = [
      {},
      ...[0, 1].map(categoryIndex => {
        const categoryOption = segmentOptions[categoryIndex];

        if (categoryOption) {
          return {
            label: categoryOption.label,
            filters: [{ name: categoryOption.field, value: categoryOption.value }]
          };
        } else {
          return {};
        }
      })
    ];

    valueWidgetSettings.forEach((settings, i) => {
      const element = this.valueWidgetGenerator.getElement(resource, modelDescription, {
        func: AggregateFunc.Count,
        label: isSet(settings.label) ? settings.label : 'Total number',
        filters: settings.filters,
        uid: options.idGenerator ? options.idGenerator.elementId(`row1Column${i + 1}Widget`) : undefined
      });

      const column = new ColumnsLayoutColumnElementItem();

      column.uid = options.idGenerator ? options.idGenerator.elementId(`row1Column${i + 1}`) : undefined;
      column.children = [element];
      column.weight = 1;

      row1Element.columns.push(column);
    });

    const row2Element = new ColumnsLayoutElementItem();

    row2Element.uid = options.idGenerator ? options.idGenerator.elementId('row2Element') : undefined;

    if (segments[0]) {
      const element = this.chartWidgetGenerator.getElement(resource, modelDescription, {
        yFunc: AggregateFunc.Count,
        xColumn: segments[0].field,
        label: [capitalize(modelDescription.verboseNamePlural), 'by', capitalize(segments[0].fieldLabel)].join(' '),
        chartType: segments[0].options.length > 6 ? ChartType.Bar : ChartType.Doughnut,
        uid: options.idGenerator ? options.idGenerator.elementId('row2Column1Widget') : undefined
      });

      element.height = 280;

      const column = new ColumnsLayoutColumnElementItem();

      column.uid = options.idGenerator ? options.idGenerator.elementId(`row2Column1`) : undefined;
      column.children = [element];
      column.weight = 1;

      row2Element.columns.push(column);
    }

    if (dateField) {
      const element = this.chartWidgetGenerator.getElement(resource, modelDescription, {
        yFunc: AggregateFunc.Count,
        xColumn: dateField.name,
        xLookup: DatasetGroupLookup.DateMonth,
        label: [capitalize(modelDescription.verboseNamePlural), 'by', capitalize(dateField.verboseName)].join(' '),
        chartType: ChartType.Bar,
        uid: options.idGenerator ? options.idGenerator.elementId('row2Column2Widget') : undefined
      });

      element.height = 280;

      const column = new ColumnsLayoutColumnElementItem();

      column.uid = options.idGenerator ? options.idGenerator.elementId(`row1column2`) : undefined;
      column.children = [element];
      column.weight = 1;

      row2Element.columns.push(column);
    } else if (!dateField && segments[1]) {
      const element = this.chartWidgetGenerator.getElement(resource, modelDescription, {
        yFunc: AggregateFunc.Count,
        xColumn: segments[1].field,
        label: [capitalize(modelDescription.verboseNamePlural), 'by', capitalize(segments[1].fieldLabel)].join(' '),
        chartType: segments[1].options.length > 6 ? ChartType.Bar : ChartType.Doughnut,
        uid: options.idGenerator ? options.idGenerator.elementId('row2Column2Widget') : undefined
      });

      element.height = 280;

      const column = new ColumnsLayoutColumnElementItem();

      column.uid = options.idGenerator ? options.idGenerator.elementId(`row2Column2`) : undefined;
      column.children = [element];
      column.weight = 1;

      row2Element.columns.push(column);
    }

    const listElement = this.listGenerator.getTableElement(resource, modelDescription, uniqueName, {
      templates: options.templates,
      fields: options.listFields,
      sortingField: dateField ? dateField.name : undefined,
      sortingAsc: dateField ? false : undefined,
      uid: options.idGenerator ? options.idGenerator.elementId('listElement') : undefined
    });

    const filterElement = this.filterGenerator.getElement(modelDescription, listElement.uid, {
      style: FilterStyle.Wrap,
      uid: options.idGenerator ? options.idGenerator.elementId('filterElement') : undefined
    });

    listElement.layouts[0].dataSource.queryInputs = filterElement.elementInputs.map(item => {
      const input = new Input();

      input.path = [item.name];
      input.valueType = InputValueType.Context;
      input.contextValue = ['elements', filterElement.uid, item.name, VALUE_OUTPUT];

      return input;
    });

    viewSettings.elements = [
      row1Element,
      ...(row2Element.columns.length ? [row2Element] : []),
      filterElement,
      listElement
    ];

    validateElementNames(viewSettings.elements);

    return viewSettings;
  }

  getModelDataSegments(modelDescription: ModelDescription) {
    const selectFields = modelDescription.dbFields.filter(item => {
      return (
        item.field == FieldType.Select &&
        item.params['options_type'] == OptionsType.Static &&
        item.params['options'] &&
        item.params['options'].length
      );
    });
    const booleanFields = modelDescription.dbFields.filter(item => item.field == FieldType.Boolean);
    const dateField = modelDescription.dbFields.find(item => item.field == FieldType.DateTime);
    const segments: {
      field: string;
      fieldLabel: string;
      options: {
        value: any;
        label: string;
      }[];
    }[] = [];

    selectFields.slice(0, 2).forEach(field => {
      const selectOptions: Option[] = field.params['options'];

      segments.push({
        field: field.name,
        fieldLabel: field.verboseName || field.name,
        options: selectOptions.map(item => {
          return {
            value: item.value,
            label: [capitalize(field.verboseName), 'is', item.name].join(' ')
          };
        })
      });
    });

    booleanFields.slice(0, 2).forEach(field => {
      segments.push({
        field: field.name,
        fieldLabel: field.verboseName || field.name,
        options: [
          {
            value: true,
            label: ['Is', capitalize(field.verboseName)].join(' ')
          }
        ]
      });
    });

    booleanFields.slice(0, 2).forEach(field => {
      segments.push({
        field: field.name,
        fieldLabel: field.verboseName || field.name,
        options: [
          {
            value: false,
            label: ['Is not', capitalize(field.verboseName)].join(' ')
          }
        ]
      });
    });

    const segmentOptions: {
      field: string;
      value: any;
      label: string;
    }[] = segments.reduce((acc, item) => {
      item.options.forEach(option => {
        acc.push({
          field: item.field,
          value: option.value,
          label: option.label
        });
      });

      return acc;
    }, []);

    return {
      segments: segments,
      segmentOptions: segmentOptions,
      dateField: dateField
    };
  }

  getPage(
    project: Project,
    resource: Resource,
    modelDescription: ModelDescription,
    options: {
      listFields?: string[];
      // createFields?: string[];
      // updateFields?: string[];
      // detailFields?: string[];
      idGenerator?: PersistentIdGenerator;
    } = {}
  ): Observable<CustomViewSettings> {
    const baseUniqueName = this.generatorUtils.getModelPageUniqueName(resource, modelDescription);

    return combineLatest(
      this.viewSettingsStore.getDistinctUniqueName(baseUniqueName),
      this.generatorUtils.getDefaultComponentTemplates()
    ).pipe(
      map(([uniqueName, templates]) => {
        return this.getModelPage(project, resource, modelDescription, uniqueName, {
          templates: templates,
          listFields: options.listFields,
          // detailFields: options.detailFields,
          idGenerator: options.idGenerator
        });
      })
    );
  }
}
