import { Injectable } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn } from '@angular/forms';

import { ViewContext } from '@modules/customize';
import { imageFieldCrops, ImageFieldType, imageFieldTypes } from '@modules/field-components';
import { imageFieldFits, ImageOutputFormat, Input, Input as FieldInput } from '@modules/fields';
import { FieldInputControl } from '@modules/parameters';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { isSet } from '@shared';

@Injectable()
export class ImageFieldViewParamsForm {
  form: FormGroup;
  control: AbstractControl;
  outputFormatOptions = [
    { value: ImageOutputFormat.URL, name: 'Specify URL' },
    { value: ImageOutputFormat.Storage, name: 'Save to Storage' }
  ];
  typeOptions = imageFieldTypes;
  typeElementOptions = imageFieldTypes.filter(item =>
    [ImageFieldType.Rectangular, ImageFieldType.Circular].includes(item.value)
  );
  fitOptions = imageFieldFits;
  imageFieldCrops = imageFieldCrops;

  constructor(
    private fb: FormBuilder,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore
  ) {
    this.form = this.fb.group({
      output_format: new FormControl(ImageOutputFormat.Storage),
      storage: new FormControl(undefined),
      path: new FieldInputControl({ path: ['value'] }),
      type: new FormControl(imageFieldTypes[0].value),
      fit: new FormControl(imageFieldFits[0].value),
      crop_format: new FormControl(imageFieldCrops[0].value),
      resize: new FormControl(false),
      width: new FormControl(''),
      height: new FormControl(''),
      show_preview: new FormControl(false),
      lightbox: new FormControl(false),
      editor: new FormControl(true),
      crop_format_custom: new FormControl('', [this.validateCropFormat()])
    });
  }

  validateCropFormat(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value) {
        return;
      }

      const match1 = String(control.value).match(/^(\d+\:\d+)$/);
      if (control.value && !match1) {
        return { local: ['Crop format: x:x'] };
      }
    };
  }

  init(control: AbstractControl, options: { context?: ViewContext } = {}) {
    this.control = control;

    const params = control.value || {};
    const storage = this.currentProjectStore.instance.getStorage(
      this.currentEnvironmentStore.instance.uniqueName,
      params['storage_resource'],
      params['storage_name'],
      { defaultFirst: true, contextResource: options.context ? options.context.resource : undefined }
    );
    const value = {
      storage: storage ? { resource: storage.resource.uniqueName, name: storage.storage.uniqueName } : undefined
    };

    if (control.value) {
      // Backward compatibility
      if (params['external']) {
        value['output_format'] = ImageOutputFormat.URL;
      } else {
        value['output_format'] = params['output_format'] || ImageOutputFormat.Storage;
      }

      // Backward compatibility
      if (typeof params['path'] == 'string') {
        value['path'] = new Input().deserializeFromStatic('value', params['path']).serializeWithoutPath();
      } else if (params['path']) {
        value['path'] = new Input().deserialize(params['path']).serializeWithoutPath();
      }

      value['type'] = params['type'];
      value['fit'] = params['fit'];
      value['resize'] = params['resize'];
      value['width'] = params['width'];
      value['height'] = params['height'];
      value['show_preview'] = params['show_preview'];
      value['lightbox'] = params['lightbox'];
      value['editor'] = isSet(params['editor']) ? params['editor'] : true;
      value['crop_format'] = params['crop_format'];
      value['crop_format_custom'] = params['crop_format_custom'];
    }

    this.form.patchValue(value, { emitEvent: false });
    this.form.valueChanges.subscribe(() => this.submit());
  }

  submit() {
    const value = this.form.value;
    const params = {
      output_format: value['output_format'],
      path: value['path'] ? new FieldInput().deserialize(value['path']).serialize() : undefined,
      type: value['type'],
      fit: value['fit'],
      resize: value['resize'],
      width: value['width'],
      height: value['height'],
      show_preview: value['show_preview'],
      lightbox: value['lightbox'],
      editor: value['editor'],
      crop_format: value['crop_format'],
      crop_format_custom: value['crop_format_custom']
    };

    if (value['storage']) {
      params['storage_resource'] = value['storage']['resource'];
      params['storage_name'] = value['storage']['name'];
    }

    this.control.patchValue({
      ...this.control.value,
      ...params
    });
  }
}
