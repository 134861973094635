<ng-container *ngIf="error | appIsSet">
  <div
    *ngIf="!visible"
    class="input-footer__indicator input-footer__indicator_error icon-warning_filled"
    [style.bottom.px]="indicatorBottom"
    [appTip]="errorDescription"
    [appTipOptions]="{ side: 'top' }"
    (click)="inputFocus.emit()"
  ></div>

  <div *ngIf="visible" class="input-footer__message input-footer__message_error">
    <div class="input-footer__message-title">Result</div>
    <div class="input-footer__message-row input-footer__message-row_wrap">
      <span
        *ngIf="errorDescription"
        class="input-footer__message-info icon-help"
        [appTip]="errorDescription"
        [appTipOptions]="{ side: 'top' }"
      ></span>

      {{ error }}
    </div>
  </div>
</ng-container>

<!--<div *ngIf="!(error | appIsSet) && visible" class="input-footer__message input-footer__message">-->
<!--  <div class="input-footer__message-title">Result</div>-->
<!--  <div class="input-footer__message-row input-footer__message-row_wrap" [title]="valueStr">-->
<!--    <span *ngIf="valueType" class="input-footer__message-type">{{ valueType }}</span>-->
<!--    {{ valueStr }}-->
<!--  </div>-->
<!--</div>-->
