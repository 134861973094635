import { Border } from './border';

export class BorderSettings {
  public border: Border;
  public borderTop: Border;
  public borderRight: Border;
  public borderBottom: Border;
  public borderLeft: Border;

  constructor(options: Partial<BorderSettings> = {}) {
    Object.assign(this, options);
  }

  deserialize(data: Object): this {
    if (data['border']) {
      this.border = new Border().deserialize(data['border']);
    }

    if (data['border_top']) {
      this.borderTop = new Border().deserialize(data['border_top']);
    }

    if (data['border_right']) {
      this.borderRight = new Border().deserialize(data['border_right']);
    }

    if (data['border_bottom']) {
      this.borderBottom = new Border().deserialize(data['border_bottom']);
    }

    if (data['border_left']) {
      this.borderLeft = new Border().deserialize(data['border_left']);
    }

    return this;
  }

  isSidesSet(): boolean {
    return [this.borderTop, this.borderRight, this.borderBottom, this.borderLeft].some(item => item && item.isSet());
  }

  serialize(): Object {
    return {
      border: this.border ? this.border.serialize() : undefined,
      border_top: this.borderTop ? this.borderTop.serialize() : undefined,
      border_right: this.borderRight ? this.borderRight.serialize() : undefined,
      border_bottom: this.borderBottom ? this.borderBottom.serialize() : undefined,
      border_left: this.borderLeft ? this.borderLeft.serialize() : undefined
    };
  }
}
