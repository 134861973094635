var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormArray, FormControl } from '@angular/forms';
import isEqual from 'lodash/isEqual';
import range from 'lodash/range';
import { map } from 'rxjs/operators';
import { controlValue, isSet } from '@shared';
var ColorSetArray = /** @class */ (function (_super) {
    __extends(ColorSetArray, _super);
    function ColorSetArray(defaultColors) {
        if (defaultColors === void 0) { defaultColors = []; }
        var _this = _super.call(this, []) || this;
        _this.defaultColors = defaultColors;
        _this.defaultColors = _this.defaultColors.map(function (item) { return item.toUpperCase(); });
        _this.deserialize(defaultColors);
        return _this;
    }
    ColorSetArray.prototype.deserialize = function (value) {
        var _this = this;
        if (value === void 0) { value = []; }
        this.defaultColors.forEach(function (defaultColor, i) {
            var control = _this.controls[i] || _this.appendControl();
            var itemValue = isSet(value[i]) && value[i] != defaultColor ? value[i] : undefined;
            control.patchValue(itemValue);
            control.markAsPristine();
        });
        this.controls.slice(this.defaultColors.length).forEach(function (item) { return _this.removeControl(item); });
    };
    ColorSetArray.prototype.serialize = function () {
        if (isEqual(this.value, this.defaultColors) || !this.value.length || this.value.every(function (item) { return !isSet(item); })) {
            return;
        }
        return this.value;
    };
    ColorSetArray.prototype.setControls = function (controls) {
        var _this = this;
        this.removeControls();
        controls.forEach(function (item) { return _this.push(item); });
    };
    ColorSetArray.prototype.removeControls = function () {
        var _this = this;
        range(this.controls.length).forEach(function () { return _this.removeAt(0); });
    };
    ColorSetArray.prototype.removeControl = function (control) {
        var newControls = this.controls.filter(function (item) { return item !== control; });
        this.setControls(newControls);
    };
    ColorSetArray.prototype.appendControl = function (value) {
        var control = new FormControl(value);
        this.push(control);
        return control;
    };
    ColorSetArray.prototype.getEffectiveValue = function () {
        var _this = this;
        return this.defaultColors.map(function (defaultColor, i) {
            return isSet(_this.value[i]) ? _this.value[i] : defaultColor;
        });
    };
    ColorSetArray.prototype.getEffectiveValue$ = function () {
        var _this = this;
        return controlValue(this).pipe(map(function () {
            return _this.getEffectiveValue();
        }));
    };
    return ColorSetArray;
}(FormArray));
export { ColorSetArray };
