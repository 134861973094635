import keys from 'lodash/keys';
import pickBy from 'lodash/pickBy';

import { isSet } from '@shared';

import { Margin } from './elements/items/base';
import { RangeSliderElementItem } from './elements/items/range-slider';
import { TextStyle } from './text-style';

export class RangeSliderElementStyles {
  labelStyle?: TextStyle;
  labelAdditionalStyle?: TextStyle;
  margin?: Margin;

  constructor(options: Partial<RangeSliderElementStyles> = {}) {
    Object.assign(this, options);
  }

  deserialize(data: Object): this {
    if (data['label_style']) {
      this.labelStyle = new TextStyle().deserialize(data['label_style']);
    } else {
      this.labelStyle = undefined;
    }

    if (data['label_additional_style']) {
      this.labelAdditionalStyle = new TextStyle().deserialize(data['label_additional_style']);
    } else {
      this.labelAdditionalStyle = undefined;
    }

    if (data['margin']) {
      this.margin = data['margin'];
    } else {
      this.margin = undefined;
    }

    return this;
  }

  serialize(): Object {
    return {
      label_style: this.labelStyle ? this.labelStyle.serialize() : undefined,
      label_additional_style: this.labelAdditionalStyle ? this.labelAdditionalStyle.serialize() : undefined,
      margin: this.margin
    };
  }

  apply(other: this): this {
    const properties: (keyof RangeSliderElementStyles)[] = ['labelStyle', 'labelAdditionalStyle', 'margin'];

    properties.forEach(property => {
      if (isSet(other[property])) {
        this[property] = other[property];
      }
    });

    return this;
  }
}

export function getRangeSliderElementStyles(element: RangeSliderElementItem): RangeSliderElementStyles {
  const options: Partial<RangeSliderElementStyles> = pickBy(
    {
      labelStyle: element.labelStyle,
      labelAdditionalStyle: element.labelAdditionalStyle,
      ...(keys(element.margin).length && {
        margin: element.margin
      })
    },
    v => isSet(v)
  );

  if (!keys(options).length) {
    return;
  }

  return new RangeSliderElementStyles(options);
}

export function applyRangeSliderElementStyles(element: RangeSliderElementItem, styles?: RangeSliderElementStyles) {
  if (!styles) {
    styles = new RangeSliderElementStyles();
  }

  element.labelStyle = styles.labelStyle;
  element.labelAdditionalStyle = styles.labelAdditionalStyle;
  element.margin = styles.margin;
}
