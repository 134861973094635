var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Injector } from '@angular/core';
import flatten from 'lodash/flatten';
import isArray from 'lodash/isArray';
import keys from 'lodash/keys';
import { combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { SelectSource } from '@common/select';
import { ITEM_OUTPUT } from '@modules/customize';
import { DataSourceType, ListModelDescriptionDataSource } from '@modules/data-sources';
import { ModelDescriptionDataSourceService } from '@modules/data-sources-queries';
import { exactFieldLookup, inFieldLookup } from '@modules/field-lookups';
import { applyParamInput, applyParamInput$, ComputedDisplayField, DisplayFieldType } from '@modules/fields';
import { FilterItem2 } from '@modules/filters';
import { ModelDescriptionStore, ModelService, ModelUtilsService, ReducedModelService } from '@modules/model-queries';
import { getDefaultValue, ORDER_BY_PARAM, PER_PAGE_PARAM, SEARCH_PARAM } from '@modules/models';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { QueryType } from '@modules/queries';
import { paramsToGetQueryOptions, ResourceControllerService } from '@modules/resources';
import { EMPTY, isSet } from '@shared';
import { ModelListStore } from './model-list.store';
var ModelSelectSource = /** @class */ (function (_super) {
    __extends(ModelSelectSource, _super);
    function ModelSelectSource(modelService, reducedModelService, modelDescriptionStore, currentProjectStore, currentEnvironmentStore, injector, modelUtilsService) {
        var _this = _super.call(this) || this;
        _this.modelService = modelService;
        _this.reducedModelService = reducedModelService;
        _this.modelDescriptionStore = modelDescriptionStore;
        _this.currentProjectStore = currentProjectStore;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        _this.injector = injector;
        _this.modelUtilsService = modelUtilsService;
        _this.initialized = false;
        _this.queryParameters = [];
        _this.detailQueryParameters = [];
        _this.params = {};
        _this.multiple = false;
        _this.listStore = _this.createModelListStore();
        return _this;
    }
    ModelSelectSource.prototype.createModelListStore = function () {
        return Injector.create({
            providers: [
                {
                    provide: ModelListStore,
                    deps: [
                        ModelService,
                        CurrentProjectStore,
                        CurrentEnvironmentStore,
                        ModelDescriptionStore,
                        ModelDescriptionDataSourceService,
                        ResourceControllerService
                    ]
                }
            ],
            parent: this.injector
        }).get(ModelListStore);
    };
    ModelSelectSource.prototype.init = function (params) {
        var _a;
        this.listStore.dataSource = new ListModelDescriptionDataSource();
        this.listStore.dataSource.type = DataSourceType.Query;
        this.listStore.queryOptions = {};
        if (params.resource) {
            this.resource = this.listStore.dataSource.queryResource = params.resource;
        }
        if (params.query) {
            this.query = this.listStore.dataSource.query = params.query;
        }
        if (params.queryParameters) {
            this.queryParameters = this.listStore.dataSource.queryParameters = params.queryParameters;
        }
        if (params.detailQuery) {
            this.detailQuery = params.detailQuery;
        }
        if (params.detailQueryParameters) {
            this.detailQueryParameters = params.detailQueryParameters;
        }
        if (params.columns) {
            this.columns = this.listStore.dataSource.columns = params.columns;
        }
        var listStoreParams = __assign({}, params.params, (isSet(params.sortingField) && (_a = {},
            _a[ORDER_BY_PARAM] = isSet(params.sortingAsc) && !params.sortingAsc ? "-" + params.sortingField : params.sortingField,
            _a)));
        if (keys(listStoreParams)) {
            this.params = this.listStore.params = listStoreParams;
            this.listStore.queryOptions = paramsToGetQueryOptions(listStoreParams);
            if (listStoreParams[PER_PAGE_PARAM]) {
                this.listStore.perPage = listStoreParams[PER_PAGE_PARAM];
            }
        }
        if (isSet(params.valueField)) {
            this.valueField = params.valueField;
        }
        else {
            this.valueField = 'id';
        }
        if (isSet(params.nameField)) {
            this.nameField = params.nameField;
        }
        if (isSet(params.nameInput)) {
            this.nameInput = params.nameInput;
        }
        if (isSet(params.subtitleField)) {
            this.subtitleField = params.subtitleField;
        }
        if (isSet(params.subtitleInput)) {
            this.subtitleInput = params.subtitleInput;
        }
        if (isSet(params.iconField)) {
            this.iconField = params.iconField;
        }
        if (isSet(params.iconInput)) {
            this.iconInput = params.iconInput;
        }
        if (isSet(params.colorField)) {
            this.colorField = params.colorField;
        }
        if (isSet(params.colorInput)) {
            this.colorInput = params.colorInput;
        }
        if (params.multiple) {
            this.multiple = params.multiple;
        }
        if (params.context) {
            this.context = params.context;
        }
        if (params.contextElement) {
            this.contextElement = params.contextElement;
        }
        this.initialized = true;
    };
    ModelSelectSource.prototype.hasName = function () {
        return isSet(this.nameField) || isSet(this.nameInput);
    };
    ModelSelectSource.prototype.deserializeModelAttributes = function (items) {
        var _this = this;
        if (!items) {
            return;
        }
        return items.map(function (item) {
            // item.deserializeAttributes(
            //   this.dataSource.columns.filter(column => column.type != DisplayFieldType.Computed)
            // );
            if (_this.columns) {
                _this.columns
                    .filter(function (column) { return column instanceof ComputedDisplayField; })
                    .forEach(function (column) {
                    var value = _this.resolveFlexItemValue(column, item);
                    item.setAttribute(column.name, value);
                });
            }
            return item;
        });
    };
    ModelSelectSource.prototype.resolveFlexItemValue = function (column, model) {
        var _a;
        if (column.valueInput) {
            try {
                var value = applyParamInput(column.valueInput, {
                    context: this.context,
                    contextElement: this.contextElement,
                    localContext: (_a = {},
                        _a[ITEM_OUTPUT] = model.getAttributes(),
                        _a)
                    // field: { field: column.field, params: column.params }
                });
                if (value !== EMPTY) {
                    return value;
                }
            }
            catch (e) { }
        }
        return getDefaultValue(column);
    };
    ModelSelectSource.prototype.fetch = function (searchQuery) {
        var _this = this;
        var params = __assign({}, this.params);
        if (isSet(searchQuery)) {
            params[SEARCH_PARAM] = searchQuery;
        }
        this.listStore.params = params;
        if (this.listStore.queryOptions) {
            this.listStore.queryOptions.search = searchQuery;
        }
        return this.listStore.getNext().pipe(map(function (result) {
            if (!result) {
                return;
            }
            return _this.deserializeModelAttributes(result);
        }), switchMap(function (result) {
            if (!result || !result.length) {
                return of([]);
            }
            return combineLatest.apply(void 0, result.map(function (item) {
                var value;
                var name$;
                var subtitle$;
                var icon$;
                var color$;
                if (isSet(_this.valueField)) {
                    value = item.hasAttribute(_this.valueField)
                        ? item.getAttribute(_this.valueField)
                        : item.getRawAttribute(_this.valueField);
                }
                else if (item.modelDescription) {
                    value = item.primaryKey;
                }
                if (_this.nameInput) {
                    name$ = applyParamInput$(_this.nameInput, {
                        localContext: {
                            item: item.getAttributes()
                        }
                    });
                }
                else if (isSet(_this.nameField)) {
                    name$ = of(item.hasAttribute(_this.nameField)
                        ? item.getAttribute(_this.nameField)
                        : item.getRawAttribute(_this.nameField));
                }
                else if (item.modelDescription) {
                    name$ = _this.modelUtilsService.str(item);
                }
                else {
                    name$ = of(undefined);
                }
                if (_this.subtitleInput) {
                    subtitle$ = applyParamInput$(_this.subtitleInput, {
                        localContext: {
                            item: item.getAttributes()
                        }
                    });
                }
                else if (isSet(_this.subtitleField)) {
                    subtitle$ = of(item.hasAttribute(_this.subtitleField)
                        ? item.getAttribute(_this.subtitleField)
                        : item.getRawAttribute(_this.subtitleField));
                }
                else {
                    subtitle$ = of(undefined);
                }
                if (_this.iconInput) {
                    icon$ = applyParamInput$(_this.iconInput, {
                        localContext: {
                            item: item.getAttributes()
                        }
                    });
                }
                else if (isSet(_this.iconField)) {
                    icon$ = of(item.hasAttribute(_this.iconField)
                        ? item.getAttribute(_this.iconField)
                        : item.getRawAttribute(_this.iconField));
                }
                else {
                    icon$ = of(undefined);
                }
                if (_this.colorInput) {
                    color$ = applyParamInput$(_this.colorInput, {
                        localContext: {
                            item: item.getAttributes()
                        }
                    });
                }
                else if (isSet(_this.colorField)) {
                    color$ = of(item.hasAttribute(_this.colorField)
                        ? item.getAttribute(_this.colorField)
                        : item.getRawAttribute(_this.colorField));
                }
                else {
                    color$ = of(undefined);
                }
                return combineLatest(name$, subtitle$, icon$, color$).pipe(map(function (_a) {
                    var name = _a[0], subtitle = _a[1], icon = _a[2], color = _a[3];
                    if (_this.multiple && isArray(value)) {
                        return value.map(function (valueItem) {
                            if (isSet(_this.valueField) && !_this.nameInput && _this.valueField == _this.nameField) {
                                return _this.getOption({
                                    value: valueItem,
                                    name: valueItem,
                                    icon: icon,
                                    subtitle: subtitle,
                                    color: color
                                });
                            }
                            else {
                                return _this.getOption({
                                    value: valueItem,
                                    name: name,
                                    icon: icon,
                                    subtitle: subtitle,
                                    color: color
                                });
                            }
                        });
                    }
                    else {
                        return [
                            _this.getOption({
                                value: value,
                                name: name,
                                icon: icon,
                                subtitle: subtitle,
                                color: color
                            })
                        ];
                    }
                }));
            })).pipe(map(function (options) {
                return options.reduce(function (acc, item) {
                    acc.push.apply(acc, item);
                    return acc;
                }, []);
            }));
        }));
    };
    ModelSelectSource.prototype.getOption = function (options) {
        return {
            value: options.value,
            name: options.name,
            image: options.icon,
            data: {
                subtitle: options.subtitle,
                color: options.color
            }
        };
    };
    ModelSelectSource.prototype.fetchByValue = function (value) {
        var _this = this;
        if (!value) {
            return of(this.multiple ? [] : undefined);
        }
        else if (isArray(value) && !value.length) {
            return of(this.multiple ? [] : undefined);
        }
        var arrayValues = this.multiple && isArray(value) ? value : [value];
        var resource = this.currentEnvironmentStore.resources.find(function (item) { return item.uniqueName == _this.resource; });
        var obs;
        if (this.detailQuery) {
            obs = combineLatest(arrayValues.map(function (item) {
                var filterItem = new FilterItem2({
                    field: [_this.valueField],
                    lookup: exactFieldLookup,
                    value: item
                });
                var queryOptions = paramsToGetQueryOptions(_this.params);
                queryOptions.filters = (queryOptions.filters || []).concat([filterItem]);
                return _this.modelService.getDetailQueryAdv(_this.currentProjectStore.instance, _this.currentEnvironmentStore.instance, resource, _this.detailQuery, _this.detailQueryParameters, queryOptions, _this.columns);
            }));
        }
        else {
            var modelId_1 = this.query && this.query.queryType == QueryType.Simple && this.query.simpleQuery
                ? [this.resource, this.query.simpleQuery.model].join('.')
                : undefined;
            if (modelId_1) {
                obs = combineLatest(arrayValues.map(function (arrayValue) {
                    var _a;
                    return _this.reducedModelService.get(modelId_1, (_a = {},
                        _a[_this.valueField] = arrayValue,
                        _a), _this.params);
                })).pipe(map(function (result) { return flatten(result); }));
            }
            else {
                var filterItem = this.multiple
                    ? new FilterItem2({
                        field: [this.valueField],
                        lookup: inFieldLookup,
                        value: arrayValues
                    })
                    : new FilterItem2({
                        field: [this.valueField],
                        lookup: exactFieldLookup,
                        value: value
                    });
                var queryOptions = paramsToGetQueryOptions(this.params);
                queryOptions.filters = (queryOptions.filters || []).concat([filterItem]);
                obs = this.modelService
                    .getQueryAdv(this.currentProjectStore.instance, this.currentEnvironmentStore.instance, resource, this.query, this.queryParameters, queryOptions, (this.columns || []).filter(function (item) { return item.type != DisplayFieldType.Computed; }))
                    .pipe(map(function (result) {
                    if (!result) {
                        return [];
                    }
                    return result.results;
                }));
            }
            obs = obs.pipe(map(function (results) {
                return results.filter(function (item) {
                    var itemField = item.modelDescription && !_this.valueField ? item.primaryKey : _this.valueField;
                    var itemFieldValue = isSet(itemField) ? item.getAttribute(itemField) : undefined;
                    return !!arrayValues.find(function (i) {
                        if (_this.multiple && isArray(itemFieldValue)) {
                            return itemFieldValue.includes(i);
                        }
                        else {
                            return i == itemFieldValue;
                        }
                    });
                });
            }));
        }
        return obs.pipe(map(function (result) {
            if (!result) {
                return;
            }
            return _this.deserializeModelAttributes(result);
        }), switchMap(function (items) {
            if (!items) {
                return of(arrayValues.map(function (v) { return ({
                    value: v,
                    name: v
                }); }));
            }
            if (!items.length) {
                return of([]);
            }
            return combineLatest(items.map(function (item) {
                var itemValue;
                var name$;
                var subtitle$;
                var icon$;
                var color$;
                if (isSet(_this.valueField)) {
                    itemValue = item.hasAttribute(_this.valueField)
                        ? item.getAttribute(_this.valueField)
                        : item.getRawAttribute(_this.valueField);
                }
                else if (item.modelDescription) {
                    itemValue = item.primaryKey;
                }
                if (_this.nameInput) {
                    name$ = applyParamInput$(_this.nameInput, {
                        localContext: {
                            item: item.getAttributes()
                        }
                    });
                }
                else if (isSet(_this.nameField)) {
                    name$ = of(item.hasAttribute(_this.nameField)
                        ? item.getAttribute(_this.nameField)
                        : item.getRawAttribute(_this.nameField));
                }
                else if (item.modelDescription) {
                    name$ = _this.modelUtilsService.str(item);
                }
                else {
                    name$ = of(undefined);
                }
                if (_this.subtitleInput) {
                    subtitle$ = applyParamInput$(_this.subtitleInput, {
                        localContext: {
                            item: item.getAttributes()
                        }
                    });
                }
                else if (isSet(_this.subtitleField)) {
                    subtitle$ = of(item.hasAttribute(_this.subtitleField)
                        ? item.getAttribute(_this.subtitleField)
                        : item.getRawAttribute(_this.subtitleField));
                }
                else {
                    subtitle$ = of(undefined);
                }
                if (_this.iconInput) {
                    icon$ = applyParamInput$(_this.iconInput, {
                        localContext: {
                            item: item.getAttributes()
                        }
                    });
                }
                else if (isSet(_this.iconField)) {
                    icon$ = of(item.hasAttribute(_this.iconField)
                        ? item.getAttribute(_this.iconField)
                        : item.getRawAttribute(_this.iconField));
                }
                else {
                    icon$ = of(undefined);
                }
                if (_this.colorInput) {
                    color$ = applyParamInput$(_this.colorInput, {
                        localContext: {
                            item: item.getAttributes()
                        }
                    });
                }
                else if (isSet(_this.colorField)) {
                    color$ = of(item.hasAttribute(_this.colorField)
                        ? item.getAttribute(_this.colorField)
                        : item.getRawAttribute(_this.colorField));
                }
                else {
                    color$ = of(undefined);
                }
                return combineLatest(name$, subtitle$, icon$, color$).pipe(map(function (_a) {
                    var name = _a[0], subtitle = _a[1], icon = _a[2], color = _a[3];
                    if (_this.multiple && isArray(itemValue)) {
                        return itemValue.map(function (valueItem) {
                            if (isSet(_this.valueField) && !_this.nameInput && _this.valueField == _this.nameField) {
                                return _this.getOption({
                                    value: valueItem,
                                    name: valueItem,
                                    icon: icon,
                                    subtitle: subtitle,
                                    color: color
                                });
                            }
                            else {
                                return _this.getOption({
                                    value: valueItem,
                                    name: name,
                                    icon: icon,
                                    subtitle: subtitle,
                                    color: color
                                });
                            }
                        });
                    }
                    else {
                        return [
                            _this.getOption({
                                value: itemValue,
                                name: name,
                                icon: icon,
                                subtitle: subtitle,
                                color: color
                            })
                        ];
                    }
                }));
            })).pipe(map(function (options) {
                return options.reduce(function (acc, item) {
                    acc.push.apply(acc, item);
                    return acc;
                }, []);
            }), map(function (options) {
                return arrayValues
                    .map(function (arrayValue) { return options.find(function (item) { return item.value == arrayValue; }); })
                    .filter(function (option) { return option; });
            }));
        }), map(function (options) {
            if (_this.multiple) {
                return options;
            }
            else {
                return options[0];
            }
        }));
    };
    ModelSelectSource.prototype.isFetchAvailable = function () {
        return this.listStore.hasMore;
    };
    ModelSelectSource.prototype.resetPagination = function () {
        this.listStore.reset();
    };
    ModelSelectSource.prototype.setStaticOptions = function (options) { };
    return ModelSelectSource;
}(SelectSource));
export { ModelSelectSource };
