import { ChangeDetectorRef, EventEmitter, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { isSet } from '@shared';
var PaginationComponent = /** @class */ (function () {
    function PaginationComponent(cd) {
        this.cd = cd;
        this.hasMore = false;
        this.theme = false;
        this.pageSelected = new EventEmitter();
        this.bordersSiblings = 1;
        this.currentSiblings = 2;
        this.items = [];
    }
    PaginationComponent.prototype.ngOnInit = function () { };
    PaginationComponent.prototype.ngOnChanges = function (changes) {
        this.updatePages();
    };
    PaginationComponent.prototype.updatePages = function () {
        var items = [];
        var lastVisible = this.total || this.lastLoaded || this.current;
        var lastPage = 0;
        var addPage = function (page) {
            if (page < 1) {
                return;
            }
            if (page > lastVisible) {
                return;
            }
            if (page <= lastPage) {
                return;
            }
            items.push({ type: 'page', page: page });
            lastPage = page;
        };
        for (var i = 1; i <= 1 + this.bordersSiblings; ++i) {
            addPage(i);
        }
        if (lastPage < this.current - this.currentSiblings - 1) {
            items.push({ type: 'separator' });
        }
        for (var i = this.current - this.currentSiblings; i <= this.current + this.currentSiblings; ++i) {
            addPage(i);
        }
        if (lastPage < lastVisible - this.currentSiblings) {
            items.push({ type: 'separator' });
        }
        for (var i = lastVisible - this.bordersSiblings; i <= lastVisible; ++i) {
            addPage(i);
        }
        this.items = items;
        this.cd.markForCheck();
    };
    Object.defineProperty(PaginationComponent.prototype, "prevPage", {
        get: function () {
            if (this.current <= 1) {
                return;
            }
            return this.current - 1;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaginationComponent.prototype, "nextPage", {
        get: function () {
            if (!this.hasMore) {
                return;
            }
            return this.current + 1;
        },
        enumerable: true,
        configurable: true
    });
    PaginationComponent.prototype.trackByFn = function (_, item) {
        return item.page;
    };
    PaginationComponent.prototype.openPage = function (page) {
        if (!isSet(page)) {
            return;
        }
        this.pageSelected.emit(page);
        // const params = clone(this.params);
        //
        // params[PAGE_PARAM] = page;
        // delete params[NEXT_PAGE_SCROLL_PARAM];
        //
        // this.paramsChanged.emit(params);
    };
    return PaginationComponent;
}());
export { PaginationComponent };
