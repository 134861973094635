import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import clamp from 'lodash/clamp';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, merge, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { ViewContext } from '@modules/customize';
import { Color, FrameTranslate, GradientStop, Layer, View } from '@modules/views';
import { ascComparator, controlValue, deployUrl, isSet, KeyboardEventKeyCode } from '@shared';

import { ViewEditorContext } from '../../../services/view-editor-context/view-editor.context';
import { GradientStopControl } from '../../controls/gradient-stop.control';
import { GradientControl } from '../../controls/gradient.control';

@Component({
  selector: 'app-gradient-selector',
  templateUrl: './gradient-selector.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GradientSelectorComponent implements OnInit, OnDestroy {
  @Input() control: GradientControl;
  @Input() view: View;
  @Input() layer: Layer;
  @Input() viewContext: ViewContext;

  @ViewChild('gradient_stop_track') gradientStopTrack: ElementRef;

  activeStopId: string;
  trackBackgroundSafe: SafeStyle;
  overlayClick = false;

  trackStopControlFn(i, item: GradientStopControl) {
    return item.getId() || `index_${i}`;
  }

  constructor(
    private editorContext: ViewEditorContext,
    private sanitizer: DomSanitizer,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    const frame = this.getFrame();
    if (frame) {
      this.editorContext.startCustomizingGradient({
        frame: frame,
        control: this.control
      });
    }

    if (this.view) {
      this.editorContext
        .viewChanged$()
        .pipe(untilDestroyed(this))
        .subscribe(event => {
          const viewFrame = new FrameTranslate({ frame: event.view.frame });
          this.editorContext.updateCustomizingGradient({ frame: viewFrame });
        });
    } else if (this.layer) {
      merge(
        this.editorContext.layerChanged$().pipe(
          filter(event => event.layer.isSame(this.layer)),
          map(event => event.layer)
        ),
        this.editorContext.globalLayersChange$().pipe(map(() => this.layer))
      )
        .pipe(
          switchMap(layer => {
            return this.editorContext.getLayerContainer$(layer).pipe(
              map(container => {
                return new FrameTranslate({
                  frame: layer.frame,
                  translate: container ? container.options.translate : undefined
                });
              })
            );
          }),
          untilDestroyed(this)
        )
        .subscribe(layerFrame => {
          this.editorContext.updateCustomizingGradient({ frame: layerFrame });
        });
    }

    this.editorContext.customizingGradient$.pipe(untilDestroyed(this)).subscribe(value => {
      this.activeStopId = value ? value.activeStop : undefined;
      this.cd.markForCheck();
    });

    combineLatest(this.editorContext.customizingGradient$, controlValue(this.control.controls.stops))
      .pipe(untilDestroyed(this))
      .subscribe(([customizingGradient]) => {
        const activeStop =
          customizingGradient && isSet(customizingGradient.activeStop)
            ? this.control.controls.stops.controls.find(item => item.getId() == customizingGradient.activeStop)
            : undefined;
        const firstStop = this.control.controls.stops.controls[0];

        if (!activeStop && firstStop) {
          this.setCurrentStopControl(firstStop);
        } else if (!activeStop && customizingGradient && customizingGradient.activeStop) {
          this.setCurrentStopControl(undefined);
        }
      });

    controlValue(this.control.controls.stops)
      .pipe(
        switchMap(() => {
          if (!this.control.controls.stops.controls.length) {
            return of([]);
          }

          return combineLatest(
            this.control.controls.stops.controls
              .sort((lhs, rhs) => ascComparator(lhs.controls.position.value, rhs.controls.position.value))
              .map(control => {
                return control.getColor$({ context: this.viewContext }).pipe(
                  map(color => {
                    const position = `${control.controls.position.value * 100}%`;
                    return `${color} ${position}`;
                  })
                );
              })
          );
        }),
        untilDestroyed(this)
      )
      .subscribe(stopsCss => {
        const backgrounds = [];

        if (stopsCss.length) {
          backgrounds.push(`linear-gradient(to right, ${stopsCss.join(', ')})`);
        }

        backgrounds.push(`#fff center center / 10px url(${deployUrl('/assets/images/transparent.svg')})`);

        this.trackBackgroundSafe = this.sanitizer.bypassSecurityTrustStyle(backgrounds.join(', '));
        this.cd.markForCheck();
      });

    this.editorContext
      .trackKeydown()
      .pipe(
        filter(e => e.keyCode == KeyboardEventKeyCode.Backspace),
        untilDestroyed(this)
      )
      .subscribe(e => {
        e.stopPropagation();

        const index = this.control.controls.stops.controls.findIndex(item => item.getId() === this.activeStopId);

        if (this.control.controls.stops.controls.length > 2 && !!this.control.controls.stops.controls[index]) {
          const newCurrentStop = index > 0 ? this.control.controls.stops.controls[index - 1] : undefined;

          this.control.controls.stops.removeAt(index);
          this.setCurrentStopControl(newCurrentStop);
        }
      });
  }

  ngOnDestroy(): void {
    this.editorContext.finishCustomizingGradient();
  }

  getFrame(): FrameTranslate {
    if (this.view) {
      return new FrameTranslate({
        frame: this.view.frame
      });
    } else if (this.layer) {
      const container = this.editorContext.getLayerContainer(this.layer);
      return new FrameTranslate({
        frame: this.layer.frame,
        translate: container ? container.options.translate : undefined
      });
    }
  }

  setCurrentStopControl(control?: GradientStopControl) {
    this.editorContext.updateCustomizingGradient({
      activeStop: control ? control.getId() : undefined
    });
  }

  createGradientStop(event: MouseEvent) {
    const bounds = this.gradientStopTrack.nativeElement.getBoundingClientRect();
    const position = clamp((event.clientX - bounds.left) / bounds.width, 0, 1);
    const color = new Color({ red: 255, green: 255, blue: 255 });
    const stop = new GradientStop({ position: position, color: color });

    stop.generateId();

    const control = this.control.controls.stops.appendControl(stop);
    this.setCurrentStopControl(control);
  }
}
