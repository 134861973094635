import { getColorHexStr, getColorVariable } from '@modules/colors';
import { isSet } from '@shared';

export class Shadow {
  color: string;
  colorDark: string;
  offsetX = 0;
  offsetY = 0;
  blurRadius = 4;
  spreadRadius = 0;

  constructor(options: Partial<Shadow> = {}) {
    Object.assign(this, options);
  }

  deserialize(data: Object): this {
    this.color = data['color'];
    this.colorDark = data['color_dark'];
    this.offsetX = data['offset_x'];
    this.offsetY = data['offset_y'];
    this.blurRadius = data['blur_radius'];
    this.spreadRadius = data['spread_radius'];

    return this;
  }

  serialize(): Object {
    return {
      color: this.color,
      color_dark: this.colorDark,
      offset_x: this.offsetX,
      offset_y: this.offsetY,
      blur_radius: this.blurRadius,
      spread_radius: this.spreadRadius
    };
  }

  cssColor(dark: boolean): string {
    const color = dark ? this.colorDark : this.color;

    if (!isSet(color)) {
      return;
    }

    const variable = getColorVariable(color);

    if (isSet(variable)) {
      return `var(--${variable})`;
    }

    return getColorHexStr(color);
  }

  cssBoxShadow(
    options: {
      dark?: boolean;
      inside?: boolean;
    } = {}
  ): string {
    if (!this.blurRadius && !this.spreadRadius) {
      return;
    }

    const color = this.cssColor(options.dark);
    if (!isSet(color)) {
      return;
    }

    const result = [];

    if (options.inside) {
      result.push('inset');
    }

    result.push(
      this.offsetX ? `${this.offsetX || 0}px` : '0',
      this.offsetY ? `${this.offsetY || 0}px` : '0',
      this.blurRadius ? `${this.blurRadius || 0}px` : '0',
      this.spreadRadius ? `${this.spreadRadius || 0}px` : '0',
      color
    );

    return result.join(' ');
  }
}
