/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../../shared/pipes/is-set/is-set.pipe";
import * as i3 from "@angular/cdk/portal";
import * as i4 from "../../services/data-tooltip-controller/data-tooltip.controller";
import * as i5 from "./pie-chart2.component";
import * as i6 from "@angular/cdk/overlay";
var styles_PieChart2Component = [];
var RenderType_PieChart2Component = i0.ɵcrt({ encapsulation: 2, styles: styles_PieChart2Component, data: {} });
export { RenderType_PieChart2Component as RenderType_PieChart2Component };
function View_PieChart2Component_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.name; _ck(_v, 1, 0, currVal_0); }); }
function View_PieChart2Component_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["Dataset ", ""]))], null, function (_ck, _v) { var currVal_0 = (_v.parent.parent.context.index + 1); _ck(_v, 1, 0, currVal_0); }); }
function View_PieChart2Component_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "chart-legend__title"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_PieChart2Component_4)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵppd(3, 1), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_PieChart2Component_5)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵppd(6, 1)], function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i0.ɵnov(_v.parent.parent.parent, 0), _v.parent.context.$implicit.name)); _ck(_v, 2, 0, currVal_0); var currVal_1 = !i0.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i0.ɵnov(_v.parent.parent.parent, 0), _v.parent.context.$implicit.name)); _ck(_v, 5, 0, currVal_1); }, null); }
function View_PieChart2Component_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "chart-legend__item"]], [[2, "chart-legend__item_interactive", null], [2, "chart-legend__item_disabled", null]], [[null, "click"], [null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleSelectedDatasetGroup({ datasetIndex: _v.parent.context.index, groupIndex: _v.context.index }) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (_co.onLegendDatasetGroupMouseEnter({ datasetIndex: _v.parent.context.index, groupIndex: _v.context.index }) !== false);
        ad = (pd_1 && ad);
    } if (("mouseleave" === en)) {
        var pd_2 = (_co.onLegendDatasetGroupMouseLeave() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i0.ɵppd(1, 1), i0.ɵpod(2, { datasetIndex: 0, groupIndex: 1 }), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "chart-legend__item-color"]], [[2, "chart-legend__item-color_no-color", null], [4, "background-color", null]], null, null, null, null)), i0.ɵppd(4, 1), i0.ɵpod(5, { datasetIndex: 0, groupIndex: 1 }), (_l()(), i0.ɵeld(6, 0, null, null, 1, "div", [["class", "chart-legend__item-label"]], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.interactive; var currVal_1 = (i0.ɵunv(_v, 0, 1, _ck(_v, 1, 0, i0.ɵnov(_v.parent.parent.parent, 0), _co.selectedDatasetGroups)) && !_co.isSelectedDatasetGroup(_ck(_v, 2, 0, _v.parent.context.index, _v.context.index))); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = !_co.colorDisplay(0, _v.context.index); var currVal_3 = ((i0.ɵunv(_v, 3, 1, _ck(_v, 4, 0, i0.ɵnov(_v.parent.parent.parent, 0), _co.selectedDatasetGroups)) && !_co.isSelectedDatasetGroup(_ck(_v, 5, 0, _v.parent.context.index, _v.context.index))) ? null : _co.colorDisplay(0, _v.context.index)); _ck(_v, 3, 0, currVal_2, currVal_3); var currVal_4 = _co.groupDisplay(_v.context.$implicit.group); _ck(_v, 7, 0, currVal_4); }); }
function View_PieChart2Component_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "chart-legend chart-legend_vertical"]], [[2, "chart-legend_theme", null]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PieChart2Component_3)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PieChart2Component_6)), i0.ɵdid(4, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.data.length > 1); _ck(_v, 2, 0, currVal_1); var currVal_2 = _v.context.$implicit.dataset; _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.theme; _ck(_v, 0, 0, currVal_0); }); }
function View_PieChart2Component_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "chart__legend"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PieChart2Component_2)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data; _ck(_v, 2, 0, currVal_0); }, null); }
function View_PieChart2Component_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(0, null, null, 0))], null, null); }
export function View_PieChart2Component_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i2.IsSetPipe, []), i0.ɵqud(402653184, 1, { canvasElement: 0 }), i0.ɵqud(402653184, 2, { svgElement: 0 }), i0.ɵqud(402653184, 3, { tooltipContainerElement: 0 }), i0.ɵqud(402653184, 4, { portalOutlet: 0 }), (_l()(), i0.ɵeld(5, 0, null, null, 8, "div", [["class", "chart chart_horizontal"]], [[2, "chart_multiple", null]], null, null, null, null)), i0.ɵppd(6, 1), (_l()(), i0.ɵeld(7, 0, [[1, 0], ["canvas", 1]], null, 1, "div", [["class", "chart__canvas"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, [[2, 0], ["svg", 1]], null, 0, ":svg:svg", [["height", "100%"], ["width", "100%"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PieChart2Component_1)), i0.ɵdid(10, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(11, 0, [[3, 0], ["tooltip_container", 1]], null, 2, "div", [["class", "chart__tooltips"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PieChart2Component_7)), i0.ɵdid(13, 212992, [[4, 4]], 0, i3.CdkPortalOutlet, [i0.ComponentFactoryResolver, i0.ViewContainerRef], { portal: [0, "portal"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.legend; _ck(_v, 10, 0, currVal_1); var currVal_2 = null; _ck(_v, 13, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i0.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i0.ɵnov(_v, 0), _co.selectedDatasetGroups)) ? (_co.selectedDatasetGroups.length > 1) : (_co.data.length > 1)); _ck(_v, 5, 0, currVal_0); }); }
export function View_PieChart2Component_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-pie-chart2", [], null, null, null, View_PieChart2Component_0, RenderType_PieChart2Component)), i0.ɵprd(131584, null, i4.DataTooltipController, i4.DataTooltipController, [i0.Injector]), i0.ɵdid(2, 4964352, null, 0, i5.PieChart2Component, [i0.ElementRef, i6.Overlay, i4.DataTooltipController, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var PieChart2ComponentNgFactory = i0.ɵccf("app-pie-chart2", i5.PieChart2Component, View_PieChart2Component_Host_0, { datasets: "datasets", doughnut: "doughnut", yFormat: "yFormat", min: "min", max: "max", defaultColors: "defaultColors", animate: "animate", legend: "legend", gradient: "gradient", interactive: "interactive", datasetBackground: "datasetBackground", dataClickEnabled: "dataClickEnabled", trackItem: "trackItem", theme: "theme" }, { itemEnter: "itemEnter", itemLeave: "itemLeave", dataClick: "dataClick" }, []);
export { PieChart2ComponentNgFactory as PieChart2ComponentNgFactory };
