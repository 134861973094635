/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@angular/router";
import * as i3 from "./error.component";
var styles_ErrorComponent = [];
var RenderType_ErrorComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ErrorComponent, data: {} });
export { RenderType_ErrorComponent as RenderType_ErrorComponent };
function View_ErrorComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "error__title"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 1, 0, currVal_0); }); }
function View_ErrorComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "error__description"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.description; _ck(_v, 1, 0, currVal_0); }); }
function View_ErrorComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "button__icon button__icon_left"]], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "button__icon button__icon_left"; var currVal_1 = ("icon-" + _co.buttonIcon); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_ErrorComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "a", [["class", "button"]], [[2, "button_primary", null], [2, "button_disabled", null], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.buttonClicked.emit() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i1.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ErrorComponent_5)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 1, "span", [["class", "button__label"]], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.buttonLink; _ck(_v, 1, 0, currVal_4); var currVal_5 = _co.buttonIcon; _ck(_v, 3, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.buttonPrimary; var currVal_1 = _co.buttonDisabled; var currVal_2 = i0.ɵnov(_v, 1).target; var currVal_3 = i0.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_6 = _co.button; _ck(_v, 5, 0, currVal_6); }); }
function View_ErrorComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "button__icon button__icon_left"]], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "button__icon button__icon_left"; var currVal_1 = ("icon-" + _co.buttonIcon); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_ErrorComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "a", [["class", "button"], ["href", "javascript:void(0)"]], [[2, "button_primary", null], [2, "button_disabled", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.buttonClicked.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ErrorComponent_7)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 1, "span", [["class", "button__label"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.buttonIcon; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.buttonPrimary; var currVal_1 = _co.buttonDisabled; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = _co.button; _ck(_v, 4, 0, currVal_3); }); }
function View_ErrorComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "error__button"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ErrorComponent_4)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ErrorComponent_6)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.buttonLink; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.buttonLink; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_ErrorComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [["class", "error"]], [[2, "error_fill", null], [2, "error_compact", null], [2, "error_theme", null]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 9, "div", [["class", "error__inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "div", [["class", "error__icon"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ErrorComponent_1)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ErrorComponent_2)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(7, 0, null, null, 1, "div", [["class", "error__description"]], null, null, null, null, null)), i0.ɵncd(null, 0), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ErrorComponent_3)), i0.ɵdid(10, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.title; _ck(_v, 4, 0, currVal_3); var currVal_4 = _co.description; _ck(_v, 6, 0, currVal_4); var currVal_5 = _co.button; _ck(_v, 10, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.fill; var currVal_1 = _co.compact; var currVal_2 = _co.theme; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_ErrorComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-error", [], null, null, null, View_ErrorComponent_0, RenderType_ErrorComponent)), i0.ɵdid(1, 114688, null, 0, i3.ErrorComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ErrorComponentNgFactory = i0.ɵccf("app-error", i3.ErrorComponent, View_ErrorComponent_Host_0, { title: "title", description: "description", fill: "fill", compact: "compact", theme: "theme", button: "button", buttonPrimary: "buttonPrimary", buttonIcon: "buttonIcon", buttonDisabled: "buttonDisabled", buttonLink: "buttonLink" }, { buttonClicked: "buttonClicked" }, ["*"]);
export { ErrorComponentNgFactory as ErrorComponentNgFactory };
