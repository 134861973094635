import { Injectable, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BarCodeElementItem, BarCodeFormat, MarginControl } from '@modules/customize';
import { ElementConfigurationService } from '@modules/customize-configuration';
import { Option } from '@modules/field-components';
import { Input, Input as FieldInput, InputValueType } from '@modules/fields';
import { FieldInputControl } from '@modules/parameters';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { controlValue } from '@shared';

@Injectable()
export class CustomizeBarBarCodeEditForm extends FormGroup implements OnDestroy {
  element: BarCodeElementItem;

  controls: {
    format: FormControl;
    value: FieldInputControl;
    fill_color: FormControl;
    background_color: FormControl;
    display_text: FormControl;
    visible_input: FieldInputControl;
    margin: MarginControl;
  };

  formatOptions: Option<string>[] = [
    { value: BarCodeFormat.CODE128, name: 'CODE128 auto', subtitle: 'Example 1234' },
    { value: BarCodeFormat.CODE128A, name: 'CODE128 A', subtitle: 'EXAMPLE1234' },
    { value: BarCodeFormat.CODE128B, name: 'CODE128 B', subtitle: 'Example1234' },
    { value: BarCodeFormat.CODE128C, name: 'CODE128 C', subtitle: '12345678' },
    { value: BarCodeFormat.EAN13, name: 'EAN13', subtitle: '1234567890128' },
    { value: BarCodeFormat.EAN8, name: 'EAN8', subtitle: '12345670' },
    { value: BarCodeFormat.EAN5, name: 'EAN5', subtitle: '12340' },
    { value: BarCodeFormat.EAN2, name: 'EAN2', subtitle: '10' },
    { value: BarCodeFormat.UPC, name: 'UPC', subtitle: '123456789999' },
    { value: BarCodeFormat.CODE39, name: 'CODE39', subtitle: 'EXAMPLE TEXT' },
    { value: BarCodeFormat.ITF14, name: 'ITF14', subtitle: '10012345000017' },
    { value: BarCodeFormat.ITF, name: 'ITF', subtitle: '123456' },
    { value: BarCodeFormat.MSI, name: 'MSI', subtitle: '123456' },
    { value: BarCodeFormat.MSI10, name: 'MSI10', subtitle: '123456' },
    { value: BarCodeFormat.MSI11, name: 'MSI11', subtitle: '123456' },
    { value: BarCodeFormat.MSI1010, name: 'MSI1010', subtitle: '123456' },
    { value: BarCodeFormat.MSI1110, name: 'MSI1110', subtitle: '123456' },
    { value: BarCodeFormat.Pharmacode, name: 'Pharmacode', subtitle: '1234' },
    { value: BarCodeFormat.Codabar, name: 'Codabar', subtitle: '1234567890' }
  ];

  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private elementConfigurationService: ElementConfigurationService
  ) {
    super({
      format: new FormControl(BarCodeFormat.CODE128),
      value: new FieldInputControl({ path: ['value'] }, Validators.required),
      fill_color: new FormControl(''),
      background_color: new FormControl('white'),
      display_text: new FormControl(true),
      visible_input: new FieldInputControl({ path: ['value'] }),
      margin: new MarginControl()
    });
  }

  ngOnDestroy(): void {}

  init(element: BarCodeElementItem, firstInit = false) {
    this.element = element;

    const value = {
      format: element.format || BarCodeFormat.CODE128,
      value: element.value ? element.value.serializeWithoutPath() : {},
      fill_color: element.fillColor,
      background_color: element.backgroundColor,
      display_text: element.displayText,
      visible_input: element.visibleInput ? element.visibleInput.serializeWithoutPath() : {},
      margin: element.margin
    };

    this.patchValue(value, { emitEvent: false });

    this.controls.format.valueChanges.pipe(untilDestroyed(this)).subscribe(formatValue => {
      const format = this.formatOptions.find(item => item.value == formatValue);
      if (format) {
        this.controls.value.controls.value_type.patchValue(InputValueType.StaticValue);
        this.controls.value.controls.static_value.patchValue(format.subtitle);
      }
    });

    if (!firstInit) {
      this.markAsDirty();
    }
  }

  getSelectedFormat$(): Observable<Option> {
    return controlValue(this.controls.format).pipe(map(value => this.formatOptions.find(item => item.value == value)));
  }

  isConfigured(instance: BarCodeElementItem): boolean {
    return this.elementConfigurationService.isBarCodeConfigured(instance);
  }

  submit(): BarCodeElementItem {
    const value = this.value;
    const instance = cloneDeep(this.element) as BarCodeElementItem;

    instance.format = value['format'];
    instance.value = value['value'] ? new FieldInput().deserialize(value['value']) : undefined;
    instance.fillColor = value['fill_color'];
    instance.backgroundColor = value['background_color'];
    instance.displayText = value['display_text'];
    instance.margin = value['margin'];

    if (value['visible_input']) {
      instance.visibleInput = new Input().deserialize(value['visible_input']);
    } else {
      instance.visibleInput = undefined;
    }

    return instance;
  }
}
