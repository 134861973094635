<td
  class="table__column table__column_left-padding"
  [class.table__column_clickable]="checkEnabled"
  (click)="toggleChecked()"
>
  <span
    *ngIf="checkEnabled"
    class="table-checkbox table__checkbox"
    [class.table-checkbox_checked]="checked"
    [class.table-checkbox_theme]="theme"
  ></span>
</td>

<ng-container *ngFor="let column of columnListItems; trackBy: trackByFn; let i = index">
  <td
    *ngIf="settings.editingEnabled && isEditableColumn(column)"
    app-table-editable-cell
    [class.table__column_compact]="column.column.field == fieldTypes.Image"
    [class.table__column_no-trunc]="column.column.field == fieldTypes.Text && column.column.params['multiline']"
    [style.min-width.px]="column.width"
    [style.max-width.px]="column.width"
    [fieldName]="column.column.name"
    [fieldType]="column.column.field"
    [fieldParams]="column.column.params"
    [value]="row.model.getAttribute(column.column.name)"
    [valueStr]="column.listItem.str"
    [selectedRow]="selected"
    [selectedCell]="undefined"
    [editingEnabled]="true"
    [disableSelectOnEdit]="true"
    [context]="context"
    [contextElement]="contextElement"
    [attr.data-field]="column.column.name"
    (changeValue)="changeColumnValue.emit({ name: column.column.name, value: $event })"
    (selectCell)="onClick($event.event)"
    #column_element
  ></td>

  <td
    *ngIf="!settings.editingEnabled || !isEditableColumn(column)"
    class="table__column"
    [class.table__column_clickable]="true"
    [class.table__column_compact]="column.column.field == fieldTypes.Image"
    [class.table__column_no-trunc]="column.column.field == fieldTypes.Text && column.column.params['multiline']"
    [style.min-width.px]="column.width"
    [style.max-width.px]="column.width"
    [attr.data-field]="column.column.name"
    app-table-item-column
    [row]="row"
    [column]="column"
    [model]="row.model"
    [modelDescription]="modelDescription"
    [settings]="settings"
    [selected]="selected"
    [context]="context"
    [contextElement]="contextElement"
    [theme]="theme"
    (click)="onClick($event)"
    #column_element
  ></td>
</ng-container>

<td
  *ngIf="settings.rowActions.length"
  class="table__column"
  [class.table__column_compact]="true"
  [class.table__column_clickable]="true"
  [class.table__column_no-trunc]="true"
  (click)="onClick($event)"
>
  <div class="table__column-actions">
    <app-table-item-action
      *ngFor="let item of settings.rowActions"
      class="table__column-action"
      [row]="row"
      [item]="item"
      [context]="context"
      [contextElement]="contextElement"
      [accentColor]="accentColor"
      [theme]="theme"
      (click)="$event.stopPropagation()"
    ></app-table-item-action>
  </div>
</td>
