var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Injector, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import * as moment from 'moment';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, of } from 'rxjs';
import { distinctUntilChanged, map, skip } from 'rxjs/operators';
import { localize } from '@common/localize';
import { applyDateRangeElementStyles, getDateRangeElementStyles, MarginControl } from '@modules/customize';
import { ElementConfigurationService } from '@modules/customize-configuration';
import { DateFormat, defaultDateFormat, defaultTimeFormat, Input, Input as FieldInput, momentDateFormats, momentTimeFormats, OutputFormat, TimeFormat } from '@modules/fields';
import { FieldInputControl } from '@modules/parameters';
import { controlValid, controlValue, isSet } from '@shared';
import { DateRangeElementStylesControl } from '../styles-date-range-element-edit/date-range-element-styles.control';
export function validateActions() {
    return function (control) {
        var parent = control.parent;
        if (!parent) {
            return of(null);
        }
        if (!control.value || !control.value.length) {
            return of(null);
        }
        return combineLatest(control.value.map(function (item) { return parent.elementConfigurationService.isActionConfigured(item); })).pipe(map(function (result) {
            if (result.some(function (configured) { return !configured; })) {
                return { required: true };
            }
        }));
    };
}
var CustomizeBarDateRangeEditForm = /** @class */ (function (_super) {
    __extends(CustomizeBarDateRangeEditForm, _super);
    function CustomizeBarDateRangeEditForm(elementConfigurationService, injector) {
        var _this = _super.call(this, {
            name: new FormControl(''),
            verbose_name: new FormControl(''),
            label_additional: new FormControl(''),
            from: new FieldInputControl({ path: ['value'] }),
            to: new FieldInputControl({ path: ['value'] }),
            output_format: new FormControl(OutputFormat.ISO),
            time: new FormControl(true),
            date_format: new FormControl(defaultDateFormat),
            date_format_custom: new FormControl(momentDateFormats[DateFormat.US]),
            time_format: new FormControl(defaultTimeFormat),
            time_format_custom: new FormControl(momentTimeFormats[TimeFormat.HourMinuteSecond24]),
            required: new FormControl(false),
            from_placeholder: new FormControl(''),
            to_placeholder: new FormControl(''),
            reset_enabled: new FormControl(false),
            disable_input: new FieldInputControl({ path: ['value'] }),
            tooltip: new FormControl(''),
            visible_input: new FieldInputControl({ path: ['value'] }),
            margin: new MarginControl(),
            on_change_actions: new FormControl([], undefined, validateActions()),
            element_styles: new DateRangeElementStylesControl(injector)
        }) || this;
        _this.elementConfigurationService = elementConfigurationService;
        _this.injector = injector;
        _this.outputFormat = [
            { value: OutputFormat.ISO, name: 'ISO 8601' },
            { value: OutputFormat.ISODate, name: 'ISO 8601 date only' },
            { value: OutputFormat.Timestamp, name: 'Unix Timestamp' },
            { value: OutputFormat.TimestampMillisecond, name: 'Unix Millisecond Timestamp' },
            { value: OutputFormat.SerialNumber, name: 'Google Sheets' },
            { value: OutputFormat.SerialNumberDate, name: 'Google Sheets date only' },
            { value: OutputFormat.String, name: 'Formatted string' }
        ];
        _this.dateFormatOptions = [
            { value: DateFormat.Local, name: "Local (" + moment().format(momentDateFormats[DateFormat.Local]) + ")" },
            { value: DateFormat.Friendly, name: "Friendly (" + moment().format(momentDateFormats[DateFormat.Friendly]) + ")" },
            { value: DateFormat.US, name: "US (" + moment().format(momentDateFormats[DateFormat.US]) + ")" },
            { value: DateFormat.European, name: "European (" + moment().format(momentDateFormats[DateFormat.European]) + ")" },
            { value: DateFormat.ISO, name: "ISO (" + moment().format(momentDateFormats[DateFormat.ISO]) + ")" },
            { value: DateFormat.Custom, name: 'Custom' }
        ];
        _this.dateFormatEditOptions = _this.dateFormatOptions.filter(function (item) { return item.value !== DateFormat.Friendly; });
        _this.timeFormatOptions = [
            {
                value: TimeFormat.HourMinute24,
                name: "24 hour (" + moment().format(momentTimeFormats[TimeFormat.HourMinute24]) + ")"
            },
            {
                value: TimeFormat.HourMinuteSecond24,
                name: "24 hour with seconds (" + moment().format(momentTimeFormats[TimeFormat.HourMinuteSecond24]) + ")"
            },
            {
                value: TimeFormat.HourMinute12,
                name: "12 hour (" + moment().format(momentTimeFormats[TimeFormat.HourMinute12]) + ")"
            },
            {
                value: TimeFormat.HourMinuteSecond12,
                name: "12 hour with seconds (" + moment().format(momentTimeFormats[TimeFormat.HourMinuteSecond12]) + ")"
            },
            {
                value: TimeFormat.Custom,
                name: 'Custom'
            }
        ];
        return _this;
    }
    CustomizeBarDateRangeEditForm.prototype.ngOnDestroy = function () { };
    CustomizeBarDateRangeEditForm.prototype.init = function (element, firstInit) {
        var _this = this;
        if (firstInit === void 0) { firstInit = false; }
        this.element = element;
        var value = __assign({ name: element.name ? element.name : 'Date Range', verbose_name: element.verboseName, label_additional: element.labelAdditional, from: element.from ? element.from.serializeWithoutPath() : {}, to: element.to ? element.to.serializeWithoutPath() : {}, time: element.time }, (isSet(element.dateFormat && momentDateFormats[element.dateFormat])
            ? { date_format: element.dateFormat }
            : { date_format: DateFormat.Custom, date_format_custom: element.dateFormat }), (isSet(element.timeFormat && momentTimeFormats[element.timeFormat])
            ? { time_format: element.timeFormat }
            : { time_format: DateFormat.Custom, time_format_custom: element.timeFormat }), { required: element.required, reset_enabled: element.resetEnabled, from_placeholder: element.fromPlaceholder, to_placeholder: element.toPlaceholder, disable_input: element.disableInput ? element.disableInput.serializeWithoutPath() : {}, tooltip: element.tooltip, visible_input: element.visibleInput ? element.visibleInput.serializeWithoutPath() : {}, on_change_actions: element.onChangeActions });
        this.patchValue(value, { emitEvent: false });
        var elementStyles = getDateRangeElementStyles(element);
        this.controls.element_styles.deserialize(elementStyles);
        if (!firstInit) {
            this.markAsDirty();
        }
        controlValue(this.controls.required)
            .pipe(distinctUntilChanged(), skip(1), untilDestroyed(this))
            .pipe(untilDestroyed(this))
            .subscribe(function (required) {
            var optionalLabel = "(" + localize('optional') + ")";
            if (!required && !isSet(_this.controls.label_additional.value)) {
                _this.controls.label_additional.patchValue(optionalLabel);
            }
            else if (required && _this.controls.label_additional.value == optionalLabel) {
                _this.controls.label_additional.patchValue('');
            }
        });
    };
    CustomizeBarDateRangeEditForm.prototype.isConfigured = function (instance) {
        return this.elementConfigurationService.isDateRangeConfigured(instance);
    };
    CustomizeBarDateRangeEditForm.prototype.controlsValid$ = function (controls) {
        if (!controls.length) {
            return of(true);
        }
        return combineLatest(controls.map(function (control) { return controlValid(control); })).pipe(map(function (result) { return result.every(function (item) { return item; }); })
        // debounceTime(60) TODO: Too long wait with debounceTime
        );
    };
    CustomizeBarDateRangeEditForm.prototype.actionsValid$ = function () {
        return this.controlsValid$([this.controls.on_change_actions]);
    };
    CustomizeBarDateRangeEditForm.prototype.submit = function () {
        var instance = cloneDeep(this.element);
        instance.name = this.controls.name.value;
        instance.verboseName = this.controls.verbose_name.value;
        instance.labelAdditional = this.controls.label_additional.value;
        instance.from = this.controls.from.value ? new FieldInput().deserialize(this.controls.from.value) : undefined;
        instance.to = this.controls.to.value ? new FieldInput().deserialize(this.controls.to.value) : undefined;
        instance.time = this.controls.time.value;
        instance.dateFormat =
            this.controls.date_format.value == DateFormat.Custom && isSet(this.controls.date_format_custom.value)
                ? this.controls.date_format_custom.value
                : this.controls.date_format.value;
        instance.timeFormat =
            this.controls.time_format.value == DateFormat.Custom && isSet(this.controls.time_format_custom.value)
                ? this.controls.time_format_custom.value
                : this.controls.time_format.value;
        instance.required = this.controls.required.value;
        instance.fromPlaceholder = this.controls.from_placeholder.value;
        instance.toPlaceholder = this.controls.to_placeholder.value;
        instance.resetEnabled = this.controls.reset_enabled.value;
        instance.disableInput = this.controls.disable_input.value
            ? new FieldInput().deserialize(this.controls.disable_input.value)
            : undefined;
        instance.tooltip = this.controls.tooltip.value;
        if (this.controls.visible_input.value) {
            instance.visibleInput = new Input().deserialize(this.controls.visible_input.value);
        }
        else {
            instance.visibleInput = undefined;
        }
        instance.onChangeActions = this.controls.on_change_actions.value;
        var elementStyles = this.controls.element_styles.serialize();
        applyDateRangeElementStyles(instance, elementStyles);
        return instance;
    };
    return CustomizeBarDateRangeEditForm;
}(FormGroup));
export { CustomizeBarDateRangeEditForm };
