import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, publishLast, refCount } from 'rxjs/operators';

import { AdminMode, ROUTE_ADMIN_MODE } from '@modules/admin-mode';
import { ServerRequestError } from '@modules/api';
import { FieldType, OptionsType } from '@modules/fields';
import { ModelDbField, ModelDescription, ModelField, ModelFieldType } from '@modules/models';
import {
  Environment,
  Project,
  Resource,
  ResourceType,
  ResourceTypeItem,
  SecretToken,
  SecretTokenService,
  SecretTokenType
} from '@modules/projects';
import { HTTP_QUERY_KEY_AUTH_NAME } from '@modules/projects-components/data/http-query-auth';
import {
  ListModelDescriptionQuery,
  ModelDescriptionQuery,
  ModelDescriptionSimpleQuery,
  QueryType,
  StorageQuery
} from '@modules/queries';
import {
  IXanoField,
  IXanoTableSchema,
  ResourceControllerService,
  ResourceParamsResult,
  XANO_COLLECTION_TABLE_ID_NAME,
  XANO_PRIMARY_KEY_FIELD_NAME,
  XanoFieldType,
  XanoResourceController
} from '@modules/resources';
import { Storage } from '@modules/storages';
import { isSet } from '@shared';

import { IsOptionsValidResult, ResourceGeneratorService } from '../resource-generator/resource-generator.service';
import { XANO_STORAGE_NAME } from './xano-constants';
import { XanoLegacyGeneratorService } from './xano-legacy-generator.service';
import { XanoParamsOptions } from './xano-types';

@Injectable()
export class XanoGeneratorService extends ResourceGeneratorService<XanoParamsOptions> {
  controller: XanoResourceController;

  constructor(
    @Inject(ROUTE_ADMIN_MODE) private mode: AdminMode,
    private resourceControllerService: ResourceControllerService,
    private secretTokenService: SecretTokenService,
    private legacyGenerator: XanoLegacyGeneratorService
  ) {
    super();
    this.controller = this.resourceControllerService.get<XanoResourceController>(ResourceType.Xano);
  }

  isOptionsValid(options: XanoParamsOptions): Observable<IsOptionsValidResult> {
    return this.controller.getWorkspace(options.accessToken, options.domain, options.workspaceId).pipe(
      map(result => {
        return {
          accountName: result.name
        };
      }),
      catchError(error => {
        const errorMessage =
          error instanceof ServerRequestError &&
          error.response instanceof HttpErrorResponse &&
          error.status >= 400 &&
          error.status < 500 &&
          error.response.error &&
          error.response.error['message']
            ? error.response.error['message']
            : undefined;

        if (errorMessage) {
          error = new ServerRequestError(errorMessage);
        }

        return throwError(error);
      }),
      publishLast(),
      refCount()
    );
  }

  getParamsOptions(project: Project, environment: Environment, resource: Resource): Observable<XanoParamsOptions> {
    return this.secretTokenService
      .getDetail(
        project.uniqueName,
        environment.uniqueName,
        resource.uniqueName,
        HTTP_QUERY_KEY_AUTH_NAME,
        this.mode == AdminMode.Builder
      )
      .pipe(
        map(result => {
          const domain = resource.params['domain'];
          const workspaceId = resource.params['workspace_id'];
          const dataSource = resource.params['data_source'];
          const branch = resource.params['branch'];

          if (!isSet(domain) && !isSet(workspaceId)) {
            // Backward compatibility
            return {
              apiBaseUrl: resource.params['api_base_url'],
              authHeader: result.value
            };
          } else {
            return {
              accessToken: result.value,
              domain: domain,
              workspaceId: workspaceId,
              dataSource: dataSource,
              branch: branch
            };
          }
        })
      );
  }

  fetchApi(
    options: XanoParamsOptions
  ): Observable<{
    modelDescriptions: ModelDescription[];
    storage?: Storage;
  }> {
    if (!isSet(options.domain) && !isSet(options.workspaceId)) {
      // Backward compatibility
      return this.legacyGenerator.fetchApi(options);
    }

    return this.controller
      .getTableSchemas(options.accessToken, options.domain, options.workspaceId, options.dataSource, options.branch)
      .pipe(
        map(tableSchemas => {
          const modelDescriptions = tableSchemas.map(tableSchema => {
            const modelDescription = new ModelDescription();

            modelDescription.project = '{{project}}';
            modelDescription.resource = '{{resource}}';
            modelDescription.model = tableSchema.table.name;
            modelDescription.verboseName = modelDescription.model;
            modelDescription.verboseNamePlural = modelDescription.model;
            modelDescription.primaryKeyField = XANO_PRIMARY_KEY_FIELD_NAME;
            modelDescription.params = { [XANO_COLLECTION_TABLE_ID_NAME]: tableSchema.table.id };

            modelDescription.getQuery = this.generateModelDescriptionGet(modelDescription);
            modelDescription.fields = tableSchema.fields.map(field => {
              const { fieldType, params } = this.detectCollectionSchemaField(field, tableSchemas);

              const result = new ModelField();
              const dbField = new ModelDbField();

              dbField.name = field.name;
              dbField.required = false;
              dbField.null = field.nullable;
              dbField.editable = field.name != XANO_PRIMARY_KEY_FIELD_NAME;
              dbField.filterable = true;
              dbField.field = fieldType;
              dbField.params = params;
              dbField.updateFieldDescription();

              result.name = dbField.name;
              result.type = ModelFieldType.Db;
              result.item = dbField;

              return result;
            });

            modelDescription.getDetailQuery = this.generateModelDescriptionGetDetail(modelDescription);
            modelDescription.getDetailParametersUseDefaults = true;

            modelDescription.createQuery = this.generateModelDescriptionCreate(modelDescription);
            modelDescription.createParametersUseDefaults = true;

            modelDescription.updateQuery = this.generateModelDescriptionUpdate(modelDescription);
            modelDescription.updateParametersUseDefaults = true;

            modelDescription.deleteQuery = this.generateModelDescriptionDelete(modelDescription);
            modelDescription.deleteParametersUseDefaults = true;

            return modelDescription;
          });

          const storage = this.createStorage();

          return {
            modelDescriptions: modelDescriptions,
            storage: storage
          };
        })
      );
  }

  private generateModelDescriptionGet(modelDescription: ModelDescription): ListModelDescriptionQuery {
    const getSimpleQuery = new ModelDescriptionSimpleQuery();
    getSimpleQuery.model = modelDescription.model;

    const getQuery = new ListModelDescriptionQuery();

    getQuery.queryType = QueryType.Simple;
    getQuery.simpleQuery = getSimpleQuery;

    return getQuery;
  }

  private generateModelDescriptionGetDetail(modelDescription: ModelDescription): ModelDescriptionQuery {
    const getDetailSimpleQuery = new ModelDescriptionSimpleQuery();
    getDetailSimpleQuery.model = modelDescription.model;

    const getDetailQuery = new ModelDescriptionQuery();

    getDetailQuery.queryType = QueryType.Simple;
    getDetailQuery.simpleQuery = getDetailSimpleQuery;

    return getDetailQuery;
  }

  private generateModelDescriptionCreate(modelDescription: ModelDescription): ModelDescriptionQuery {
    const createSimpleQuery = new ModelDescriptionSimpleQuery();
    createSimpleQuery.model = modelDescription.model;

    const createQuery = new ModelDescriptionQuery();

    createQuery.queryType = QueryType.Simple;
    createQuery.simpleQuery = createSimpleQuery;

    return createQuery;
  }

  private generateModelDescriptionUpdate(modelDescription: ModelDescription): ModelDescriptionQuery {
    const updateSimpleQuery = new ModelDescriptionSimpleQuery();
    updateSimpleQuery.model = modelDescription.model;

    const updateQuery = new ModelDescriptionQuery();

    updateQuery.queryType = QueryType.Simple;
    updateQuery.simpleQuery = updateSimpleQuery;

    return updateQuery;
  }

  private generateModelDescriptionDelete(modelDescription: ModelDescription): ModelDescriptionQuery {
    const deleteSimpleQuery = new ModelDescriptionSimpleQuery();
    deleteSimpleQuery.model = modelDescription.model;

    const deleteQuery = new ModelDescriptionQuery();

    deleteQuery.queryType = QueryType.Simple;
    deleteQuery.simpleQuery = deleteSimpleQuery;

    return deleteQuery;
  }

  private detectCollectionSchemaField(
    field: IXanoField,
    tableSchemas: IXanoTableSchema[]
  ): {
    fieldType: FieldType;
    params?: Record<string, unknown> | undefined;
  } {
    let fieldType: FieldType;
    let params: Record<string, unknown> | undefined;

    if (field.tableref_id !== undefined) {
      const relatedTableSchema = tableSchemas.find(item => item.table.id == field.tableref_id);
      fieldType = FieldType.RelatedModel;
      params = {
        ...(relatedTableSchema && {
          related_model: {
            model: relatedTableSchema.table.name
          }
        })
      };
    } else if (field.type == XanoFieldType.Password) {
      fieldType = FieldType.Password;
    } else if (field.type == XanoFieldType.Int) {
      fieldType = FieldType.Number;
      params = {
        value_format: {
          number_fraction: 0
        }
      };
    } else if (field.type == XanoFieldType.Decimal) {
      fieldType = FieldType.Number;
    } else if (field.type == XanoFieldType.Bool) {
      fieldType = FieldType.Boolean;
    } else if (field.type == XanoFieldType.Enum) {
      fieldType = FieldType.Select;
      params = {
        options_type: OptionsType.Static,
        ...(field.values && {
          options: field.values.map(item => {
            return {
              value: item,
              name: item
            };
          })
        })
      };
    } else if (field.type == XanoFieldType.Timestamp) {
      fieldType = FieldType.DateTime;
      params = {
        date: true,
        time: true
      };
    } else if (field.type == XanoFieldType.Date) {
      fieldType = FieldType.DateTime;
      params = {
        date: true,
        time: false
      };
    } else if (field.type == XanoFieldType.Attachment) {
      fieldType = FieldType.File;
      params = {
        storage_resource: '{{resource}}',
        storage_name: XANO_STORAGE_NAME,
        multiple: field.style == 'list'
      };
    } else if (field.type == XanoFieldType.Image) {
      fieldType = FieldType.Image;
      params = {
        storage_resource: '{{resource}}',
        storage_name: XANO_STORAGE_NAME,
        multiple: field.style == 'list'
      };
    } else if (field.type == XanoFieldType.Audio) {
      fieldType = FieldType.Audio;
      params = {
        storage_resource: '{{resource}}',
        storage_name: XANO_STORAGE_NAME,
        multiple: field.style == 'list'
      };
    } else if (field.type == XanoFieldType.Video) {
      fieldType = FieldType.Video;
      params = {
        storage_resource: '{{resource}}',
        storage_name: XANO_STORAGE_NAME,
        multiple: field.style == 'list'
      };
    } else if (field.type == XanoFieldType.Object) {
      fieldType = FieldType.JSON;
    } else if (field.type == XanoFieldType.JSON) {
      fieldType = FieldType.JSON;
    } else if (field.type == XanoFieldType.GeoPoint) {
      fieldType = FieldType.Location;
    } else {
      fieldType = FieldType.Text;
    }

    return { fieldType, params };
  }

  createStorage(): Storage {
    const storage = new Storage();

    storage.uniqueName = XANO_STORAGE_NAME;
    storage.name = 'Files';

    storage.uploadQuery = new StorageQuery();
    storage.uploadQuery.queryType = QueryType.Simple;
    storage.uploadQuery.simpleQuery = new storage.uploadQuery.simpleQueryClass();

    storage.getQuery = new StorageQuery();
    storage.getQuery.queryType = QueryType.Simple;
    storage.getQuery.simpleQuery = new storage.getQuery.simpleQueryClass();

    storage.removeQuery = new StorageQuery();
    storage.removeQuery.queryType = QueryType.Simple;
    storage.removeQuery.simpleQuery = new storage.removeQuery.simpleQueryClass();

    return storage;
  }

  generateParams(
    project: Project,
    environment: Environment,
    typeItem: ResourceTypeItem,
    options: XanoParamsOptions
  ): Observable<ResourceParamsResult> {
    return this.fetchApi(options).pipe(
      map(result => {
        if (!result.modelDescriptions.length) {
          if (!isSet(options.domain) && !isSet(options.workspaceId)) {
            // Backward compatibility
            throw new ServerRequestError('No CRUD API endpoints found');
          } else {
            throw new ServerRequestError('No tables found');
          }
        }

        const resourceParams = {
          domain: options.domain,
          workspace_id: options.workspaceId,
          data_source: options.dataSource,
          branch: options.branch
        };
        const token = new SecretToken();

        token.name = HTTP_QUERY_KEY_AUTH_NAME;
        token.type = SecretTokenType.Static;
        token.value = options.accessToken;

        return {
          resourceParams: resourceParams,
          modelDescriptions: result.modelDescriptions.map(item => item.serialize()),
          secretTokens: [token.serialize()],
          storages: result.storage ? [result.storage.serialize()] : []
        };
      })
    );
  }
}
