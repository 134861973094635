import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import isArray from 'lodash/isArray';
import truncate from 'lodash/truncate';

import { isSet, TypedChanges } from '@shared';

@Component({
  selector: 'app-input-edit-result',
  templateUrl: './input-edit-result.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputEditResultComponent implements OnInit, OnChanges {
  @Input() value?: any;
  @Input() error: string;
  @Input() errorDescription: string;
  @Input() focus = false;
  @Input() showOnFocus = true;
  @Input() indicatorBottom = 0;
  @Output() inputFocus = new EventEmitter<void>();

  valueType: string;
  valueStr: string;
  visible = false;

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: TypedChanges<InputEditResultComponent>): void {
    if (changes.value) {
      this.valueType = this.getValueType();
      this.valueStr = this.getValueStr();
    }

    if (changes.focus || changes.showOnFocus) {
      this.visible = this.focus || !this.showOnFocus;
    }
  }

  getValueType(): string {
    if (!isSet(this.value, true)) {
      return undefined;
    }

    if (isArray(this.value)) {
      return 'array';
    } else {
      return typeof this.value;
    }
  }

  getValueStr(): string {
    let result: string;

    if (this.value === undefined) {
      result = 'undefined';
    } else {
      result = JSON.stringify(this.value);
    }

    return truncate(result, { length: 160 });
  }
}
