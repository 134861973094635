var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnDestroy } from '@angular/core';
import { FormArray } from '@angular/forms';
import fromPairs from 'lodash/fromPairs';
import isEqual from 'lodash/isEqual';
import range from 'lodash/range';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, of, Subject } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { getFieldDescriptionByType, Input, InputValueType, isInputSet, parseFilterName } from '@modules/fields';
import { FieldInputControl, FieldInputRequiredValidator } from '@modules/parameters';
import { controlValue, isSet } from '@shared';
var InputsArray = /** @class */ (function (_super) {
    __extends(InputsArray, _super);
    function InputsArray() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return InputsArray;
}(FormArray));
var InputsEditForm = /** @class */ (function () {
    function InputsEditForm() {
        this.form = new InputsArray([]);
        this.updatedFromControl = new Subject();
    }
    InputsEditForm.prototype.init = function (control, parameterProvider, fieldsControl, context, contextElement) {
        var _this = this;
        this.control = control;
        this.parameterProvider = parameterProvider;
        this.fieldsControl = fieldsControl;
        this.context = context;
        this.contextElement = contextElement;
        this.control.valueChanges.pipe(debounceTime(10), untilDestroyed(this)).subscribe(function () {
            if (_this.updateValueFromControl()) {
                _this.updatedFromControl.next();
            }
        });
        this.updateValueFromControl();
        this.form.valueChanges.pipe(debounceTime(60)).subscribe(function (value) { return _this.updateValueToControl(value); });
    };
    InputsEditForm.prototype.ngOnDestroy = function () { };
    InputsEditForm.prototype.serializeValueItem = function (item) {
        var input = new Input();
        var field = this.parameterProvider.getFields().find(function (i) { return isEqual([i.name], item.path); });
        if (!field) {
            return;
        }
        input.path = item.path;
        input.lookup = item.lookup;
        input.exclude = item.exclude;
        if (!field.field) {
            input.valueType = InputValueType.StaticValue;
            input.staticValue = true;
        }
        else {
            input.valueType = item.value_type;
            input.staticValue = item.static_value;
            if (input.valueType == InputValueType.StaticValue && field) {
                var fieldDescription = getFieldDescriptionByType(field.field);
                if (fieldDescription.serializeValue) {
                    input.staticValue = fieldDescription.serializeValue(input.staticValue, __assign({}, field, { params: __assign({}, field.params, { output_format: undefined }) }));
                }
            }
            input.contextValue = item.context_value;
            input.filterField = item.filter_field;
            input.filterLookup = item.filter_lookup;
            input.formulaValue = item.formula_value;
            input.textInputsType = item.text_inputs_type;
            input.textInputsValue = item.text_inputs_value;
            input.jsValue = item.js_value;
            input.required = item.required;
        }
        return input;
    };
    InputsEditForm.prototype.serializeValue = function (value) {
        var _this = this;
        return value
            .filter(function (item) { return item.path && item.path.length; })
            .map(function (item) { return _this.serializeValueItem(item); })
            .filter(function (item) { return item; });
    };
    InputsEditForm.prototype.deserializeValue = function (value) {
        return value.map(function (item) {
            return {
                path: item.path,
                lookup: item.lookup,
                exclude: item.exclude,
                value_type: item.valueType,
                static_value: item.staticValue,
                context_value: item.contextValue,
                filter_field: item.filterField,
                filter_lookup: item.filterLookup,
                formula_value: item.formulaValue,
                text_inputs_type: item.textInputsType,
                text_inputs_value: item.textInputsValue,
                js_value: item.jsValue,
                required: item.required
            };
        });
    };
    InputsEditForm.prototype.updateValueFromControl = function () {
        var _this = this;
        var value = this.control.value;
        // TODO: Add value equals check
        var createItems = this.parameterProvider
            .getFields()
            .filter(function (item) { return item.required === true; })
            .filter(function (field) { return value.find(function (item) { return isEqual(item.path, [field.name]); }) == undefined; })
            .map(function (field) {
            return {
                path: [field.name],
                field: field.field
            };
        }).concat(this.deserializeValue(value).map(function (item) {
            var field = _this.parameterProvider.getFields().find(function (i) { return isEqual([i.name], item.path); });
            return __assign({ field: field ? field.field : null }, item);
        }));
        if (isEqual(createItems, this.form.value)) {
            return false;
        }
        this.arrayUpdate(createItems);
        return true;
    };
    InputsEditForm.prototype.updateValueToControl = function (value) {
        var currentValue = this.control.value;
        var controlValueObj = this.deserializeValue(currentValue);
        if (isEqual(value, controlValueObj)) {
            return;
        }
        this.control.patchValue(this.serializeValue(value));
    };
    InputsEditForm.prototype.parameter$ = function (form) {
        var _this = this;
        return combineLatest(controlValue(form.controls.path), controlValue(form.controls.lookup), controlValue(form.controls.exclude), this.parameterProvider.getFields$()).pipe(map(function (_a) {
            var path = _a[0], lookup = _a[1], exclude = _a[2], fields = _a[3];
            if (!path) {
                return;
            }
            if (_this.parameterProvider.lookupsEnabled) {
                var prefix = exclude ? 'exclude' : '';
                var name_1 = [prefix].concat(path, [lookup]).filter(function (str, i) { return isSet(str) || i == 1; }).join('__');
                return fields.find(function (item) { return item.name == name_1; });
            }
            else {
                return fields.find(function (item) { return isEqual([item.name], path); });
            }
        }));
    };
    InputsEditForm.prototype.providerItem$ = function (form) {
        var _this = this;
        return combineLatest(controlValue(form.controls.path), controlValue(form.controls.lookup), controlValue(form.controls.exclude), this.parameterProvider.getItems$()).pipe(map(function (_a) {
            var path = _a[0], lookup = _a[1], exclude = _a[2], values = _a[3];
            if (!path) {
                return;
            }
            if (_this.parameterProvider.lookupsEnabled) {
                var prefix = exclude ? 'exclude' : '';
                var name_2 = [prefix].concat(path, [lookup]).filter(function (str, i) { return isSet(str) || i == 1; }).join('__');
                return values.find(function (item) { return item.field && item.field.name == name_2; });
            }
            else {
                return values.find(function (item) { return item.field && isEqual([item.field.name], path); });
            }
        }));
    };
    InputsEditForm.prototype.value$ = function (form) {
        return controlValue(form);
    };
    InputsEditForm.prototype.isSet$ = function (form) {
        return this.value$(form).pipe(map(function (value) { return isInputSet(value); }));
    };
    InputsEditForm.prototype.filterFields$ = function () {
        if (!this.fieldsControl) {
            return of([]);
        }
        return controlValue(this.fieldsControl).pipe(map(function (fields) {
            return fields.map(function (item) {
                var fieldDescription = getFieldDescriptionByType(item.field);
                return {
                    name: item.name,
                    label: item.verboseName || item.name,
                    lookups: fieldDescription
                        ? fieldDescription.lookups.map(function (lookup) {
                            return {
                                name: lookup.type.lookup,
                                label: lookup.type.verboseName || lookup.type.name
                            };
                        })
                        : []
                };
            });
        }));
    };
    InputsEditForm.prototype.createItem = function (value) {
        return new FieldInputControl(value, FieldInputRequiredValidator);
    };
    InputsEditForm.prototype.addItem = function (item) {
        var form;
        if (this.parameterProvider.lookupsEnabled) {
            var _a = parseFilterName(item.field.name), field = _a.field, lookup = _a.lookup, exclude = _a.exclude;
            form = this.createItem({ path: [field], lookup: lookup, exclude: exclude });
        }
        else {
            form = this.createItem({ path: [item.field.name] });
        }
        this.arrayAppend(form);
        return form;
    };
    InputsEditForm.prototype.isItemPristine = function (item) {
        return item.controls.value_type.value == InputValueType.StaticValue && item.controls.static_value.value === '';
    };
    InputsEditForm.prototype.groupTrackBy = function (item) {
        return JSON.stringify(item.path);
    };
    InputsEditForm.prototype.arraySet = function (groups) {
        var _this = this;
        range(this.form.controls.length).forEach(function () { return _this.form.removeAt(0); });
        groups.forEach(function (item) { return _this.form.push(item); });
        this.form.updateValueAndValidity();
    };
    InputsEditForm.prototype.arrayUpdate = function (value) {
        var _this = this;
        var existingControls = fromPairs(this.form.controls.map(function (item) { return [_this.groupTrackBy(item.value), item]; }));
        var preserveControls = [];
        value
            .sort(function (lhs, rhs) {
            var isInputSetLhs = isInputSet(lhs) ? 1 : 0;
            var isInputSetRhs = isInputSet(rhs) ? 1 : 0;
            var fields = _this.parameterProvider.getFields();
            var parameterIndexLhs = fields.findIndex(function (item) { return isEqual([item.name], lhs.path); });
            var parameterIndexRhs = fields.findIndex(function (item) { return isEqual([item.name], rhs.path); });
            var parameterLhs = parameterIndexLhs != -1 ? fields[parameterIndexLhs] : undefined;
            var parameterRhs = parameterIndexRhs != -1 ? fields[parameterIndexRhs] : undefined;
            var requiredLhs = parameterLhs && parameterLhs.required ? 1 : 0;
            var requiredRhs = parameterRhs && parameterRhs.required ? 1 : 0;
            return ((requiredLhs - requiredRhs) * 10 * -1 +
                (isInputSetLhs - isInputSetRhs) * -1 +
                (parameterIndexLhs - parameterIndexRhs) / 10);
        })
            .forEach(function (item) {
            var id = _this.groupTrackBy(item);
            var control = existingControls[id];
            if (control) {
                control.patchValue(item);
            }
            else {
                control = _this.createItem(item);
                _this.form.push(control);
            }
            preserveControls.push(control);
        });
        this.form.controls
            .map(function (item, i) { return [item, i]; })
            .filter(function (_a) {
            var item = _a[0], index = _a[1];
            return !preserveControls.includes(item);
        })
            .reverse()
            .forEach(function (_a) {
            var item = _a[0], index = _a[1];
            _this.form.removeAt(index);
        });
        this.form.updateValueAndValidity();
    };
    InputsEditForm.prototype.arrayAppend = function () {
        var _this = this;
        var groups = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            groups[_i] = arguments[_i];
        }
        groups.forEach(function (item) { return _this.form.push(item); });
        this.form.updateValueAndValidity();
    };
    InputsEditForm.prototype.arrayRemove = function () {
        var _this = this;
        var groups = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            groups[_i] = arguments[_i];
        }
        groups.forEach(function (item) {
            var index = _this.form.controls.findIndex(function (i) { return i === item; });
            if (index == -1) {
                return;
            }
            _this.form.removeAt(index);
        });
        this.form.updateValueAndValidity();
    };
    return InputsEditForm;
}());
export { InputsEditForm };
