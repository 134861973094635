import { ChangeDetectionStrategy, Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as Color from 'color';

import { isSet } from '@shared';

import { ColorControl, ColorControlValue } from '../../controls/color.control';

@Component({
  selector: 'app-hex-control',
  templateUrl: './hex-control.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => HexControlComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HexControlComponent implements OnInit, ControlValueAccessor {
  @Input() formControl: ColorControl;

  valueControl = new FormControl(100);

  onChange = (value: ColorControlValue) => undefined;
  onTouched = () => undefined;

  constructor() {}

  ngOnInit() {}

  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(value: ColorControlValue): void {
    const red = Math.round(value.red * 255);
    const green = Math.round(value.green * 255);
    const blue = Math.round(value.blue * 255);
    const clr = Color({ r: red, g: green, b: blue });

    this.valueControl.patchValue(clr.hex().slice(1).toUpperCase());
  }

  cleanHex(value: string): string {
    if (!isSet(value)) {
      return;
    }

    return value
      .toUpperCase()
      .replace(/[^0-9A-F]/g, '')
      .substring(0, 6);
  }

  updateValue(value: string) {
    const cleanValue = this.cleanHex(value);

    if (isSet(cleanValue) && cleanValue.length > 0) {
      try {
        const clr = Color('#' + cleanValue);
        this.submitColor(clr);
      } catch (e) {
        const clr = Color('#000');
        this.submitColor(clr);
      }
    }
  }

  submitColor(clr: Color) {
    const rgb = clr.rgb();
    const [red, green, blue] = rgb.color;
    const value: ColorControlValue = {
      global: null,
      red: red / 255,
      green: green / 255,
      blue: blue / 255,
      alpha: this.formControl.controls.alpha.value
    };

    this.onChange(value);
  }
}
