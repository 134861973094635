/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../dynamic-component/components/dynamic/dynamic.component.ngfactory";
import * as i2 from "../../../dynamic-component/components/dynamic/dynamic.component";
import * as i3 from "./thin-dialog-popup.component";
import * as i4 from "../../../dialogs/services/dialog/dialog.service";
import * as i5 from "../../../popups/services/popup/popup.service";
var styles_ThinDialogPopupComponent = [];
var RenderType_ThinDialogPopupComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ThinDialogPopupComponent, data: {} });
export { RenderType_ThinDialogPopupComponent as RenderType_ThinDialogPopupComponent };
export function View_ThinDialogPopupComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(402653184, 1, { root: 0 }), i0.ɵqud(402653184, 2, { background: 0 }), (_l()(), i0.ɵeld(2, 0, [[2, 0], ["background", 1]], null, 0, "div", [["class", "popup-background"]], [[2, "popup-background_opaque", null]], null, null, null, null)), (_l()(), i0.ɵeld(3, 0, [["container", 1]], null, 3, "div", [["class", "popup-container"]], null, [[null, "mousedown"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mousedown" === en)) {
        var pd_0 = (_co.onContainerMouseDown($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.onContainerMouseClick($event, i0.ɵnov(_v, 3)) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(4, 0, [[1, 0], ["root", 1]], null, 2, "div", [["class", "popup"]], [[2, "popup_wide", null], [2, "popup_shadow", null], [2, "popup_theme", null]], null, null, null, null)), (_l()(), i0.ɵeld(5, 16777216, null, null, 1, "app-dynamic-component", [], null, null, null, i1.View_DynamicComponent_0, i1.RenderType_DynamicComponent)), i0.ɵdid(6, 442368, null, 0, i2.DynamicComponent, [i0.ElementRef, i0.Injector, i0.ComponentFactoryResolver, i0.ApplicationRef, i0.ViewContainerRef], { componentData: [0, "componentData"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.data; _ck(_v, 6, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.opaque; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.wide; var currVal_2 = _co.opaque; var currVal_3 = _co.theme; _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3); }); }
export function View_ThinDialogPopupComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-thin-dialog-popup", [], [[2, "theme_dark", null]], null, null, View_ThinDialogPopupComponent_0, RenderType_ThinDialogPopupComponent)), i0.ɵdid(1, 4440064, null, 0, i3.ThinDialogPopupComponent, [i4.DialogService, i0.ElementRef, i5.PopupService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).dark; _ck(_v, 0, 0, currVal_0); }); }
var ThinDialogPopupComponentNgFactory = i0.ɵccf("app-thin-dialog-popup", i3.ThinDialogPopupComponent, View_ThinDialogPopupComponent_Host_0, { data: "data", disableClose: "disableClose", closeWithoutConfirm: "closeWithoutConfirm", closeTitle: "closeTitle", closeDescription: "closeDescription", requestClose: "requestClose", orange: "orange", dark: "dark", theme: "theme" }, { closed: "closed" }, []);
export { ThinDialogPopupComponentNgFactory as ThinDialogPopupComponentNgFactory };
