import { ChangeDetectorRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable, of } from 'rxjs';
import { debounceTime, filter, first, map, switchMap } from 'rxjs/operators';

import { DialogService } from '@common/dialogs';
import { ViewSettingsAction } from '@modules/actions';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import {
  cleanElementName,
  ElementItem,
  ListLayoutSettings,
  Margin,
  ViewContext,
  ViewContextElement
} from '@modules/customize';
import { BooleanFieldStyle } from '@modules/field-components';
import { createFormFieldFactory, Input as FieldInput } from '@modules/fields';
import { ModelDescription } from '@modules/models';
import { Resource } from '@modules/projects';
import { controlValid } from '@shared';

import { CustomizeBarEditComponent } from '../../../data/customize-bar-edit-component';
import { CustomizeBarEditEvent } from '../../../data/customize-bar-edit-event';
import { CustomizeBarEditEventType } from '../../../data/customize-bar-edit-event-type';
import { CustomizeBarContext } from '../../../services/customize-bar-context/customize-bar.context';
import { ElementConfiguration, trackConfigured } from '../../../utils/analytics';
import { CustomizeBarListLayoutSettingsForm, CustomizeListResult } from './customize-bar-list-layout-settings.form';

export abstract class CustomizeBarListLayoutSettingsComponent<T extends ListLayoutSettings>
  implements OnInit, OnDestroy, CustomizeBarEditComponent {
  @Input() name: string;
  @Input() nameEnabled = true;
  @Input() nameEditable = false;
  @Input() backEnabled: boolean;
  @Input() parentElement: any;
  @Input() parentPopup: any;
  @Input() settings: T;
  @Input() element: ElementItem;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() contextElementPath: (string | number)[];
  @Input() contextElementPaths: (string | number)[][];
  @Input() visibleInput: FieldInput;
  @Input() visibleEditable: boolean;
  @Input() elementStyles: any;
  @Input() elementStylesEditable = false;
  @Input() submitEnabled = true;
  @Input() deleteEnabled = false;
  @Input() firstInit = false;
  @Input() setupOnCreate = false;
  @Output() event = new EventEmitter<CustomizeBarEditEvent>();

  createField = createFormFieldFactory();
  dataSourceEditComponent: any;
  viewSettingsActionClass = ViewSettingsAction;
  booleanFieldStyle = BooleanFieldStyle;
  result: CustomizeListResult<T>;
  configurationStarted = false;
  actionsRequiredFields: string[] = [];
  queryConfigured$: Observable<boolean>;
  dataValid$: Observable<boolean>;
  actionsValid$: Observable<boolean>;
  // TODO: Make Selected/Checked items external, but use visibleContextElementPaths for exclusion (header)
  visibleContextElementPath = ['NON_EXISTENT'];

  resource: Resource;
  modelDescription: ModelDescription;

  titleCleanValue = (() => {
    return (value: string): string => {
      return cleanElementName(value, this.element, this.context.getElementItems());
    };
  })();

  constructor(
    public form: CustomizeBarListLayoutSettingsForm<T>,
    protected customizeBarContext: CustomizeBarContext,
    protected dialogService: DialogService,
    protected analyticsService: UniversalAnalyticsService,
    protected cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    const pageUid = this.context && this.context.viewSettings ? this.context.viewSettings.uid : undefined;
    const elementUid = this.element ? this.element.uid : undefined;

    this.form
      .init({
        settings: this.settings,
        name: this.name,
        nameEditable: this.nameEditable,
        nameCleanValue: this.titleCleanValue,
        visibleInput: this.visibleInput,
        elementStyles: this.elementStyles,
        elementStylesEditable: this.elementStylesEditable,
        firstInit: this.firstInit,
        pageUid: pageUid,
        elementUid: elementUid
      })
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        const resultObs = this.form.submitChanges.pipe(
          debounceTime(200),
          map(() => this.form.submit())
        );

        resultObs.pipe(untilDestroyed(this)).subscribe(result => {
          this.result = result;
          this.emitUpdate();
        });

        resultObs
          .pipe(
            switchMap(result => this.form.isConfigured(result.settings)),
            trackConfigured(),
            first(configuration => configuration == ElementConfiguration.Started),
            untilDestroyed(this)
          )
          .subscribe(() => {
            this.configurationStarted = true;
            this.analyticsService.sendSimpleEvent(AnalyticsEvent.Component.StartedConfiguration, {
              ComponentTypeID: this.settings.analyticsName,
              ResourceType: this.resource ? this.resource.typeItem.name : undefined,
              ResourceDemo: this.resource ? this.resource.demo : false
            });
          });

        this.form.controls.data_source
          .getResource$()
          .pipe(untilDestroyed(this))
          .subscribe(resource => {
            this.resource = resource;
          });

        this.form.controls.data_source
          .getModelDescription$()
          .pipe(untilDestroyed(this))
          .subscribe(modelDescription => {
            this.modelDescription = modelDescription;
          });

        this.queryConfigured$ = this.form.controls.data_source.getQueryConfigured$();
        this.dataValid$ = controlValid(this.form.controls.data_source);
        this.actionsValid$ = this.form.controlsValid$(this.actionsRequiredFields);

        this.dataValid$
          .pipe(
            map((configured, i) => {
              if (configured && i > 0) {
                this.analyticsService.sendSimpleEvent(AnalyticsEvent.ComponentData.SuccessfullySetUp, {
                  ComponentTypeID: this.settings.analyticsName
                });
              }

              return configured;
            }),
            untilDestroyed(this)
          )
          .subscribe();

        controlValid(this.form.controls.data_source.controls.query_resource)
          .pipe(
            filter((configured, i) => configured && i > 0),
            untilDestroyed(this)
          )
          .subscribe(() => {
            this.analyticsService.sendSimpleEvent(AnalyticsEvent.ComponentData.ResourceItemSuccessfullySetUp, {
              ComponentTypeID: this.settings.analyticsName
            });
          });

        controlValid(this.form.controls.data_source.controls.query_inputs)
          .pipe(
            filter((configured, i) => configured && i > 0),
            untilDestroyed(this)
          )
          .subscribe(() => {
            this.analyticsService.sendSimpleEvent(AnalyticsEvent.ComponentData.InputsSuccessfullySetUp, {
              ComponentTypeID: this.settings.analyticsName
            });
          });

        if (this.setupOnCreate) {
          this.editDataSource();
        }
      });
  }

  ngOnDestroy(): void {}

  resetColumns() {
    this.dialogService
      .warning({
        title: `Are you sure want to reset component columns?`,
        description: `
          The component columns will be reset to their default values.
        `,
        style: 'orange'
      })
      .pipe(
        filter(result => !!result),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.form.controls.data_source.resetInputColumns({
          context: this.context,
          contextElement: this.contextElement,
          markAsDirty: true
        });
      });
  }

  editDataSource(options: { addInput?: boolean; editSorting?: boolean } = {}) {
    if (!this.dataSourceEditComponent) {
      return;
    }

    this.customizeBarContext.appendSettingsComponent({
      component: this.dataSourceEditComponent,
      inputs: {
        settings: this.settings,
        element: this.element,
        title: this.form.controls.name.value,
        form: this.form,
        context: this.context,
        contextElement: this.contextElement,
        contextElementPath: this.visibleContextElementPath,
        setupOnCreate: this.setupOnCreate,
        addInput: options.addInput,
        editSorting: options.editSorting,
        object: this.settings.analyticsGenericName
      },
      outputs: {
        event: [
          () => {
            this.customizeBarContext.popSettingsComponent();
          }
        ]
      }
    });
  }

  close() {
    (this.configurationStarted ? this.form.isConfigured(this.result.settings) : of(false))
      .pipe(untilDestroyed(this))
      .subscribe(configured => {
        if (configured) {
          this.analyticsService.sendSimpleEvent(AnalyticsEvent.Component.SuccessfullyConfigured, {
            ComponentTypeID: this.settings.analyticsName,
            ResourceType: this.resource ? this.resource.typeItem.name : undefined,
            ResourceDemo: this.resource ? this.resource.demo : false
          });
        }

        this.customizeBarContext.popSettingsComponent();
      });
  }

  submit() {
    this.result = this.form.submit();
    this.emitUpdate(true);
    this.close();
  }

  onTitleChanged(title: string) {
    this.form.controls.name.patchValue(title);
    this.result = this.form.submit();
    this.emitUpdate();
  }

  emitUpdate(submit = false) {
    const args = { result: this.result.settings, submit: submit };
    if (this.visibleEditable) {
      args['visible_input'] = this.result.visibleInput;
    }
    if (this.nameEditable) {
      args['name'] = this.result.name;
    }
    if (this.elementStylesEditable) {
      args['element_styles'] = this.result.elementStyles;
    }
    // TODO: Add args interface
    this.event.emit({
      type: CustomizeBarEditEventType.Updated,
      args: args
    });
  }

  cancel() {
    this.event.emit({ type: CustomizeBarEditEventType.Canceled });
    this.close();
  }

  delete() {
    this.event.emit({ type: CustomizeBarEditEventType.Deleted });
    this.close();
  }
}
