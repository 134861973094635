import { Injectable, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { Option } from '@modules/field-components';
import { Input } from '@modules/fields';
import { FieldInputControl } from '@modules/parameters';
import { BlendingMode, ContainerLayer, ElementLayer } from '@modules/views';
import { controlValue } from '@shared';

import { ViewEditorContainer, ViewEditorContext } from '../../../services/view-editor-context/view-editor.context';
import { ConstraintsControl } from '../../controls/constraints.control';
import { FrameControl } from '../../controls/frame.control';
import { LayerInteractionArray } from '../../controls/layer-interaction.array';
import { blendingModeOptions } from '../base/blending-mode-options';

@Injectable()
export class ElementCustomizeLayerForm extends FormGroup implements OnDestroy {
  instance: ElementLayer;
  instance$ = new BehaviorSubject<ElementLayer>(undefined);
  containerLayer$ = this.instance$.pipe(
    switchMap(instance => {
      return instance ? this.editorContext.getContainerLayer$(instance) : of<ContainerLayer>(undefined);
    })
  );
  visibleInputEnabledInitial: boolean;

  controls: {
    frame: FrameControl;
    absolute_layout: FormControl;
    constrain_proportion: FormControl;
    reflect_horizontal: FormControl;
    reflect_vertical: FormControl;
    constraints: ConstraintsControl;
    opacity: FormControl;
    blending_mode: FormControl;
    visible_input: FieldInputControl;
    visible_input_enabled: FormControl;
    interactions: LayerInteractionArray;
  };

  sizeOptions: Option<boolean>[] = [
    {
      value: false,
      name: 'Fixed size',
      nameShort: 'Fixed'
    },
    {
      value: true,
      name: 'Hug content',
      nameShort: 'Hug'
    }
  ];

  blendingModeOptions = blendingModeOptions;

  constructor(private editorContext: ViewEditorContext) {
    super({
      frame: new FrameControl(),
      absolute_layout: new FormControl(false),
      constrain_proportion: new FormControl(undefined),
      reflect_horizontal: new FormControl(false),
      reflect_vertical: new FormControl(false),
      constraints: new ConstraintsControl(),
      opacity: new FormControl(1),
      blending_mode: new FormControl(BlendingMode.Normal),
      visible_input: new FieldInputControl({ path: ['value'] }),
      visible_input_enabled: new FormControl(false),
      interactions: new LayerInteractionArray([])
    });

    combineLatest(this.containerLayer$, controlValue(this.controls.absolute_layout))
      .pipe(untilDestroyed(this))
      .subscribe(([containerLayer, absoluteLayout]) => {
        const disabled = containerLayer && !!containerLayer.flexLayout && !absoluteLayout;
        this.controls.frame.setPositionDisabled(disabled);
      });
  }

  ngOnDestroy(): void {}

  init(
    instance: ElementLayer,
    options: {
      firstInit?: boolean;
      container?: ViewEditorContainer;
    } = {}
  ) {
    this.instance = instance;
    this.instance$.next(instance);

    this.controls.frame.deserialize(instance.frame, {
      translate: options.container ? options.container.options.translate : undefined
    });
    this.controls.absolute_layout.patchValue(instance.absoluteLayout);
    this.controls.constrain_proportion.patchValue(instance.constrainProportion);
    this.controls.reflect_horizontal.patchValue(instance.reflectHorizontal);
    this.controls.reflect_vertical.patchValue(instance.reflectVertical);

    if (instance.constraints) {
      this.controls.constraints.deserialize(instance.constraints);
    }

    this.controls.opacity.patchValue(instance.opacity);
    this.controls.blending_mode.patchValue(instance.blendingMode);
    this.controls.visible_input.patchValue(instance.visibleInput ? instance.visibleInput.serializeWithoutPath() : {});
    this.controls.visible_input_enabled.patchValue(instance.visibleInput && instance.visibleInput.isSet());
    this.controls.interactions.deserialize(instance.interactions);

    if (this.visibleInputEnabledInitial === undefined) {
      this.visibleInputEnabledInitial = !!this.controls.visible_input_enabled.value;
    }

    if (!options.firstInit) {
      this.markAsDirty();
    }
  }

  updateWidthProportional() {
    this.controls.frame.updateWidthProportional(this.controls.constrain_proportion.value);
  }

  updateHeightProportional() {
    this.controls.frame.updateHeightProportional(this.controls.constrain_proportion.value);
  }

  toggleConstrainProportion() {
    if (this.controls.constrain_proportion.value) {
      this.controls.constrain_proportion.patchValue(undefined);
    } else {
      this.controls.constrain_proportion.patchValue(this.controls.frame.getProportion());
    }
  }

  submit(): ElementLayer {
    const instance = this.instance;

    instance.frame = this.controls.frame.serialize();
    instance.absoluteLayout = this.controls.absolute_layout.value;
    instance.constrainProportion = this.controls.constrain_proportion.value;
    instance.reflectHorizontal = this.controls.reflect_horizontal.value;
    instance.reflectVertical = this.controls.reflect_vertical.value;
    instance.constraints = this.controls.constraints.serialize();
    instance.opacity = this.controls.opacity.value;
    instance.blendingMode = this.controls.blending_mode.value;
    instance.visibleInput =
      this.controls.visible_input_enabled.value && this.controls.visible_input.value
        ? new Input().deserialize(this.controls.visible_input.value)
        : undefined;
    instance.interactions = this.controls.interactions.serialize();

    return instance;
  }
}
