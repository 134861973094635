/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../common/tips/directives/tip/tip.directive";
import * as i2 from "@angular/common";
import * as i3 from "./signature.component";
var styles_SignatureComponent = [];
var RenderType_SignatureComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SignatureComponent, data: {} });
export { RenderType_SignatureComponent as RenderType_SignatureComponent };
function View_SignatureComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "signature__controls"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [["class", "signature__button signature__button_icon signature__control"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.reset() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "span", [["class", "signature__button-icon icon-repeat"]], null, null, null, null, null)), i0.ɵdid(3, 737280, null, 0, i1.TipDirective, [i0.ElementRef, i0.ComponentFactoryResolver, i0.Injector, i0.ApplicationRef], { content: [0, "content"], optionsRaw: [1, "optionsRaw"] }, null), i0.ɵpod(4, { side: 0 })], function (_ck, _v) { var currVal_0 = "Clear signature"; var currVal_1 = _ck(_v, 4, 0, "top"); _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
export function View_SignatureComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(402653184, 1, { canvasElement: 0 }), (_l()(), i0.ɵeld(1, 0, null, null, 7, "div", [["class", "signature"]], [[2, "signature_fill-height", null], [2, "signature_theme", null]], null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 3, "div", [["class", "signature__content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, [[1, 0], ["canvas", 1]], null, 0, "canvas", [["class", "signature__canvas"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SignatureComponent_1)), i0.ɵdid(5, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 2, "div", [["class", "signature__description"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 0, "span", [["class", "signature__description-icon icon-pen"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Sign using your finger or pointer "]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = !_co.empty; _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.fillHeight; var currVal_1 = _co.theme; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_SignatureComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-signature", [], null, null, null, View_SignatureComponent_0, RenderType_SignatureComponent)), i0.ɵdid(1, 4964352, null, 0, i3.SignatureComponent, [i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SignatureComponentNgFactory = i0.ɵccf("app-signature", i3.SignatureComponent, View_SignatureComponent_Host_0, { value: "value", fillHeight: "fillHeight", theme: "theme" }, { valueChange: "valueChange" }, []);
export { SignatureComponentNgFactory as SignatureComponentNgFactory };
