<ng-template #list_items>
  <div
    *ngFor="let itemControl of control.controls; trackBy: trackByFn"
    [formGroup]="itemControl"
    [class.sidebar-list__item]="collapse"
    [class.sidebar__element]="!collapse"
  >
    <app-field-inputs-edit-item
      [itemForm]="itemControl"
      [array]="control"
      [dataSource]="dataSource"
      [parameterProvider]="parameterProvider"
      [fieldsControl]="fieldsControl"
      [openedInitial]="lastAddedControl === itemControl"
      [focusedOnOpen]="true"
      [context]="context"
      [contextElement]="contextElement"
      [contextElementPath]="contextElementPath"
      [contextElementPaths]="contextElementPaths"
      [collapse]="collapse"
      [collapseContext]="collapseContext"
      [remove]="!displayAllParameters"
      [displayRequiredIndicator]="displayAllParameters"
      [userInput]="userInput"
      [displayValueTypes]="displayValueTypes"
      [classes]="classes"
      [analyticsSource]="analyticsSource"
    >
    </app-field-inputs-edit-item>
  </div>

  <ng-container *ngIf="displayAllParameters">
    <div *ngIf="addInputEnabled" class="sidebar-list__buttons">
      <a
        href="javascript:void(0)"
        class="button button_orange-alternative button_small sidebar-list__buttons-item"
        (click)="addInput.emit()"
      >
        <span class="icon icon-plus button__icon button__icon_left"></span>
        <span class="button__label">Add Input</span>
      </a>
    </div>

    <div *ngIf="!control.controls.length && !addInputEnabled" class="sidebar__message">
      There are no inputs defined
    </div>
  </ng-container>

  <div *ngIf="!displayAllParameters" class="sidebar-list__buttons">
    <app-field-inputs-add-overlay
      [dataSource]="dataSource"
      [origin]="addFilterOrigin"
      [nestedFieldsEnabled]="nestedFieldsEnabled"
      [relationsEnabled]="relationsEnabled"
      [optionsFilter]="optionsFilter"
      (added)="onFilterAdded($event)"
    >
    </app-field-inputs-add-overlay>

    <a
      href="javascript:void(0)"
      class="button button_orange-transparent button_small sidebar-list__buttons-item"
      [ngClass]="addClasses"
      cdkOverlayOrigin
      #addFilterOrigin="cdkOverlayOrigin"
    >
      <span class="icon icon-plus button__icon button__icon_left"></span>
      <span class="button__label">Add</span>
    </a>
  </div>
</ng-template>

<div *ngIf="listWrapper" class="sidebar-list">
  <ng-container *ngTemplateOutlet="list_items"></ng-container>
</div>

<ng-container *ngIf="!listWrapper">
  <ng-container *ngTemplateOutlet="list_items"></ng-container>
</ng-container>
