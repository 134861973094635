<app-customize-bar-header
  [title]="'General'"
  [subtitle]="'Appearance'"
  [backEnabled]="true"
  [backPopSettingsComponent]="false"
  (backClick)="back()"
>
  <ng-container actions>
    <div class="button-group" [style.margin]="'-5px 0'">
      <div
        class="button-group__item"
        [class.button-group__item_active]="themeService.isDefaultTheme"
        (click)="themeService.theme = 'default'"
      >
        <span class="button-group__item-icon icon-sun"></span>
      </div>

      <div
        class="button-group__item"
        [class.button-group__item_active]="themeService.isDarkTheme"
        (click)="themeService.theme = 'dark'"
      >
        <span class="button-group__item-icon icon-moon"></span>
      </div>
    </div>
  </ng-container>
</app-customize-bar-header>

<div class="sidebar__content">
  <app-sidebar-section>
    <div class="sidebar__element">
      <app-sidebar-field [label]="'Accent Color'">
        <app-color-options
          *ngIf="themeService.isDefaultTheme"
          [resetEnabled]="true"
          [resetBackgroundColor]="'bright-blue'"
          [resetBorderColor]="'bright-blue'"
          [colors]="accentColors"
          [colorControl]="context.controls.appearance.controls.accent_color"
          [customColorEnabledControl]="context.controls.appearance.controls.accent_color_custom_enabled"
          [customColorControl]="context.controls.appearance.controls.accent_color_custom"
        ></app-color-options>

        <app-color-options
          *ngIf="themeService.isDarkTheme"
          [resetEnabled]="true"
          [resetBackgroundColor]="'bright-blue'"
          [resetBorderColor]="'bright-blue'"
          [colors]="accentColorsDark"
          [colorControl]="context.controls.appearance.controls.accent_color_dark"
          [customColorEnabledControl]="context.controls.appearance.controls.accent_color_dark_custom_enabled"
          [customColorControl]="context.controls.appearance.controls.accent_color_dark_custom"
        ></app-color-options>
      </app-sidebar-field>
    </div>

    <div class="sidebar__element">
      <app-sidebar-field [label]="'Pages bounds'">
        <ng-container actions>
          <div
            class="tag-label tag-label_help"
            [style.margin-left.px]="4"
            [appTip]="'Same for Light and Dark'"
            [appTipOptions]="{ side: 'top' }"
          >
            <div class="tag-label__icon icon-sun"></div>
            <div class="tag-label__text">+</div>
            <div class="tag-label__icon icon-moon"></div>
          </div>
        </ng-container>

        <app-disabled
          [active]="!currentProjectStore.instance.features.isStylesEnabled()"
          [clickable]="true"
          (click)="onStylesFeatureClick()"
        >
          <div class="sidebar-summary-block">
            <div class="sidebar-summary-block__content sidebar-summary-block__content_compact">
              <div class="sidebar-summary-block__item">
                <div class="sidebar-summary-block-item sidebar-summary-block-item_padding_xs-right">
                  <div class="sidebar-summary-block-item__left">
                    <div
                      class="sidebar-summary-block-item__icon icon-resize_horizontal sidebar-summary-block-item__element"
                    ></div>
                    <div
                      class="sidebar-summary-block-item__title sidebar-summary-block-item__element sidebar-summary-block-item__element_margin_l"
                    >
                      Max Width
                    </div>
                  </div>

                  <div class="sidebar-summary-block-item__main sidebar-summary-block-item__main_right">
                    <div class="sidebar-summary-block-item__input sidebar-summary-block-item__input_label">
                      <input
                        type="text"
                        class="sidebar-summary-block-item__input-control"
                        [formControl]="context.controls.appearance.controls.max_width"
                        [appInputFormat]="{ type: numberFieldTypes.Integer, min: 0 }"
                        [style.width.px]="126"
                      />

                      <div class="sidebar-summary-block-item__input-label">
                        px
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="sidebar-summary-block__item">
                <app-styles-edit-margin
                  [control]="context.controls.appearance.controls.padding"
                  [contrast]="true"
                  [removeEnabled]="false"
                ></app-styles-edit-margin>
              </div>
            </div>
          </div>
        </app-disabled>
      </app-sidebar-field>
    </div>

    <div class="sidebar__element">
      <app-sidebar-field [label]="'Backgrounds'">
        <app-disabled
          [active]="!currentProjectStore.instance.features.isStylesEnabled()"
          [clickable]="true"
          (click)="onStylesFeatureClick()"
        >
          <app-sidebar-card>
            <div class="sidebar__element sidebar__element_margin_s">
              <app-sidebar-field>
                <app-auto-field
                  *ngIf="themeService.isDefaultTheme"
                  [field]="
                    createField({
                      name: 'background_color',
                      field: 'ColorField',
                      params: {
                        classes: ['select_small', 'select_fill'],
                        allow_empty: true,
                        empty_color: placeholders.backgroundColor,
                        default_color: placeholders.backgroundColor,
                        custom_colors: true,
                        prefix: 'Background'
                      }
                    })
                  "
                  [form]="context.controls.appearance"
                  [label]="false"
                ></app-auto-field>

                <app-auto-field
                  *ngIf="themeService.isDarkTheme"
                  [field]="
                    createField({
                      name: 'background_color_dark',
                      field: 'ColorField',
                      params: {
                        classes: ['select_small', 'select_fill'],
                        allow_empty: true,
                        empty_color: placeholders.backgroundColorDark,
                        default_color: placeholders.backgroundColorDark,
                        custom_colors: true,
                        prefix: 'Background'
                      }
                    })
                  "
                  [form]="context.controls.appearance"
                  [label]="false"
                ></app-auto-field>

                <ng-container description>
                  Pages background
                </ng-container>
              </app-sidebar-field>
            </div>

            <div class="sidebar__element sidebar__element_margin_s">
              <app-sidebar-field>
                <app-auto-field
                  *ngIf="themeService.isDefaultTheme"
                  [field]="
                    createField({
                      name: 'background_color_2',
                      field: 'ColorField',
                      params: {
                        classes: ['select_small', 'select_fill'],
                        allow_empty: true,
                        empty_color: placeholders.backgroundColor2,
                        default_color: placeholders.backgroundColor2,
                        custom_colors: true,
                        prefix: 'Surfaces 1'
                      }
                    })
                  "
                  [form]="context.controls.appearance"
                  [label]="false"
                ></app-auto-field>

                <app-auto-field
                  *ngIf="themeService.isDarkTheme"
                  [field]="
                    createField({
                      name: 'background_color_2_dark',
                      field: 'ColorField',
                      params: {
                        classes: ['select_small', 'select_fill'],
                        allow_empty: true,
                        empty_color: placeholders.backgroundColor2Dark,
                        default_color: placeholders.backgroundColor2Dark,
                        custom_colors: true,
                        prefix: 'Surfaces 1'
                      }
                    })
                  "
                  [form]="context.controls.appearance"
                  [label]="false"
                ></app-auto-field>

                <ng-container description>
                  Cards and popups background
                </ng-container>
              </app-sidebar-field>
            </div>

            <div class="sidebar__element sidebar__element_margin_s">
              <app-sidebar-field>
                <app-auto-field
                  *ngIf="themeService.isDefaultTheme"
                  [field]="
                    createField({
                      name: 'background_color_3',
                      field: 'ColorField',
                      params: {
                        classes: ['select_small', 'select_fill'],
                        allow_empty: true,
                        empty_color: placeholders.backgroundColor3,
                        default_color: placeholders.backgroundColor3,
                        custom_colors: true,
                        alpha_enabled: true,
                        prefix: 'Surfaces 2'
                      }
                    })
                  "
                  [form]="context.controls.appearance"
                  [label]="false"
                ></app-auto-field>

                <app-auto-field
                  *ngIf="themeService.isDarkTheme"
                  [field]="
                    createField({
                      name: 'background_color_3_dark',
                      field: 'ColorField',
                      params: {
                        classes: ['select_small', 'select_fill'],
                        allow_empty: true,
                        empty_color: placeholders.backgroundColor3Dark,
                        default_color: placeholders.backgroundColor3Dark,
                        custom_colors: true,
                        alpha_enabled: true,
                        prefix: 'Surfaces 2'
                      }
                    })
                  "
                  [form]="context.controls.appearance"
                  [label]="false"
                ></app-auto-field>

                <ng-container description>
                  Fields and inputs background
                </ng-container>
              </app-sidebar-field>
            </div>

            <div class="sidebar__element sidebar__element_margin_s">
              <app-sidebar-field>
                <app-auto-field
                  *ngIf="themeService.isDefaultTheme"
                  [field]="
                    createField({
                      name: 'background_color_4',
                      field: 'ColorField',
                      params: {
                        classes: ['select_small', 'select_fill'],
                        allow_empty: true,
                        empty_color: placeholders.backgroundColor4,
                        default_color: placeholders.backgroundColor4,
                        custom_colors: true,
                        alpha_enabled: true,
                        prefix: 'Surfaces 3'
                      }
                    })
                  "
                  [form]="context.controls.appearance"
                  [label]="false"
                ></app-auto-field>

                <app-auto-field
                  *ngIf="themeService.isDarkTheme"
                  [field]="
                    createField({
                      name: 'background_color_4_dark',
                      field: 'ColorField',
                      params: {
                        classes: ['select_small', 'select_fill'],
                        allow_empty: true,
                        empty_color: placeholders.backgroundColor4Dark,
                        default_color: placeholders.backgroundColor4Dark,
                        custom_colors: true,
                        alpha_enabled: true,
                        prefix: 'Surfaces 3'
                      }
                    })
                  "
                  [form]="context.controls.appearance"
                  [label]="false"
                ></app-auto-field>

                <ng-container description>
                  List cards, collapse headers, file upload
                </ng-container>
              </app-sidebar-field>
            </div>

            <div class="sidebar__element sidebar__element_margin_s">
              <app-sidebar-field>
                <app-auto-field
                  *ngIf="themeService.isDefaultTheme"
                  [field]="
                    createField({
                      name: 'background_color_5',
                      field: 'ColorField',
                      params: {
                        classes: ['select_small', 'select_fill'],
                        allow_empty: true,
                        empty_color: placeholders.backgroundColor5,
                        default_color: placeholders.backgroundColor5,
                        custom_colors: true,
                        alpha_enabled: true,
                        prefix: 'Surfaces 4'
                      }
                    })
                  "
                  [form]="context.controls.appearance"
                  [label]="false"
                ></app-auto-field>

                <app-auto-field
                  *ngIf="themeService.isDarkTheme"
                  [field]="
                    createField({
                      name: 'background_color_5_dark',
                      field: 'ColorField',
                      params: {
                        classes: ['select_small', 'select_fill'],
                        allow_empty: true,
                        empty_color: placeholders.backgroundColor5Dark,
                        default_color: placeholders.backgroundColor5Dark,
                        custom_colors: true,
                        alpha_enabled: true,
                        prefix: 'Surfaces 4'
                      }
                    })
                  "
                  [form]="context.controls.appearance"
                  [label]="false"
                ></app-auto-field>

                <ng-container description>
                  Component build-in buttons background
                </ng-container>
              </app-sidebar-field>
            </div>
          </app-sidebar-card>
        </app-disabled>
      </app-sidebar-field>
    </div>

    <div class="sidebar__element">
      <app-sidebar-field [label]="'Fonts'">
        <ng-container actions>
          <div
            class="tag-label tag-label_help"
            [style.margin-left.px]="4"
            [appTip]="'Same for Light and Dark'"
            [appTipOptions]="{ side: 'top' }"
          >
            <div class="tag-label__icon icon-sun"></div>
            <div class="tag-label__text">+</div>
            <div class="tag-label__icon icon-moon"></div>
          </div>
        </ng-container>

        <div class="sidebar__element-segment">
          <app-font-control
            [control]="context.controls.appearance.controls.font_regular"
            [prefix]="'Regular'"
            [small]="true"
            [fill]="true"
          ></app-font-control>
        </div>

        <div class="sidebar__element-segment">
          <app-font-control
            [control]="context.controls.appearance.controls.font_heading"
            [prefix]="'Heading'"
            [small]="true"
            [fill]="true"
          ></app-font-control>
        </div>
      </app-sidebar-field>
    </div>

    <div class="sidebar__element">
      <app-sidebar-field [label]="'Text colors'">
        <app-disabled
          [active]="!currentProjectStore.instance.features.isStylesEnabled()"
          [clickable]="true"
          (click)="onStylesFeatureClick()"
        >
          <app-sidebar-card>
            <div class="sidebar__element sidebar__element_margin_s">
              <app-sidebar-field>
                <app-auto-field
                  *ngIf="themeService.isDefaultTheme"
                  [field]="
                    createField({
                      name: 'text_color',
                      field: 'ColorField',
                      params: {
                        classes: ['select_small', 'select_fill'],
                        allow_empty: true,
                        empty_color: placeholders.textColor,
                        default_color: placeholders.textColor,
                        custom_colors: true,
                        alpha_enabled: true,
                        prefix: 'Headings'
                      }
                    })
                  "
                  [form]="context.controls.appearance"
                  [label]="false"
                ></app-auto-field>

                <app-auto-field
                  *ngIf="themeService.isDarkTheme"
                  [field]="
                    createField({
                      name: 'text_color_dark',
                      field: 'ColorField',
                      params: {
                        classes: ['select_small', 'select_fill'],
                        allow_empty: true,
                        empty_color: placeholders.textColorDark,
                        default_color: placeholders.textColorDark,
                        custom_colors: true,
                        alpha_enabled: true,
                        prefix: 'Headings'
                      }
                    })
                  "
                  [form]="context.controls.appearance"
                  [label]="false"
                ></app-auto-field>
              </app-sidebar-field>
            </div>

            <div class="sidebar__element sidebar__element_margin_s">
              <app-sidebar-field>
                <app-auto-field
                  *ngIf="themeService.isDefaultTheme"
                  [field]="
                    createField({
                      name: 'text_color_2',
                      field: 'ColorField',
                      params: {
                        classes: ['select_small', 'select_fill'],
                        allow_empty: true,
                        empty_color: placeholders.textColor2,
                        default_color: placeholders.textColor2,
                        custom_colors: true,
                        alpha_enabled: true,
                        prefix: 'Regular'
                      }
                    })
                  "
                  [form]="context.controls.appearance"
                  [label]="false"
                ></app-auto-field>

                <app-auto-field
                  *ngIf="themeService.isDarkTheme"
                  [field]="
                    createField({
                      name: 'text_color_2_dark',
                      field: 'ColorField',
                      params: {
                        classes: ['select_small', 'select_fill'],
                        allow_empty: true,
                        empty_color: placeholders.textColor2Dark,
                        default_color: placeholders.textColor2Dark,
                        custom_colors: true,
                        alpha_enabled: true,
                        prefix: 'Regular'
                      }
                    })
                  "
                  [form]="context.controls.appearance"
                  [label]="false"
                ></app-auto-field>
              </app-sidebar-field>
            </div>

            <div class="sidebar__element sidebar__element_margin_s">
              <app-sidebar-field>
                <app-auto-field
                  *ngIf="themeService.isDefaultTheme"
                  [field]="
                    createField({
                      name: 'text_color_3',
                      field: 'ColorField',
                      params: {
                        classes: ['select_small', 'select_fill'],
                        allow_empty: true,
                        empty_color: placeholders.textColor3,
                        default_color: placeholders.textColor3,
                        custom_colors: true,
                        alpha_enabled: true,
                        prefix: 'Secondary'
                      }
                    })
                  "
                  [form]="context.controls.appearance"
                  [label]="false"
                ></app-auto-field>

                <app-auto-field
                  *ngIf="themeService.isDarkTheme"
                  [field]="
                    createField({
                      name: 'text_color_3_dark',
                      field: 'ColorField',
                      params: {
                        classes: ['select_small', 'select_fill'],
                        allow_empty: true,
                        empty_color: placeholders.textColor3Dark,
                        default_color: placeholders.textColor3Dark,
                        custom_colors: true,
                        alpha_enabled: true,
                        prefix: 'Secondary'
                      }
                    })
                  "
                  [form]="context.controls.appearance"
                  [label]="false"
                ></app-auto-field>
              </app-sidebar-field>
            </div>
          </app-sidebar-card>
        </app-disabled>
      </app-sidebar-field>
    </div>

    <div class="sidebar__element">
      <app-sidebar-field [label]="'Corner radius'">
        <ng-container actions>
          <div
            class="tag-label tag-label_help"
            [style.margin-left.px]="4"
            [appTip]="'Same for Light and Dark'"
            [appTipOptions]="{ side: 'top' }"
          >
            <div class="tag-label__icon icon-sun"></div>
            <div class="tag-label__text">+</div>
            <div class="tag-label__icon icon-moon"></div>
          </div>
        </ng-container>

        <app-disabled
          [active]="!currentProjectStore.instance.features.isStylesEnabled()"
          [clickable]="true"
          (click)="onStylesFeatureClick()"
        >
          <div class="sidebar-icon-buttons sidebar-icon-buttons_background sidebar-icon-buttons_border">
            <div
              *ngFor="let item of context.controls.appearance.borderRadiusOptions"
              class="sidebar-icon-button sidebar-icon-button_compact"
              [class.sidebar-icon-button_active]="
                context.controls.appearance.controls.border_radius.value == item.value
              "
              (click)="context.controls.appearance.controls.border_radius.setValue(item.value)"
            >
              <div
                class="sidebar-icon-button__image"
                [style.background-image]="
                  '/assets/images/border-radius/border-radius-' + item.value + '.svg' | appDeployCssUrl
                "
              ></div>
            </div>
          </div>
        </app-disabled>
      </app-sidebar-field>
    </div>

    <div class="sidebar__element">
      <app-sidebar-field [label]="'Border colors'">
        <app-disabled
          [active]="!currentProjectStore.instance.features.isStylesEnabled()"
          [clickable]="true"
          (click)="onStylesFeatureClick()"
        >
          <app-sidebar-card>
            <div class="sidebar__element sidebar__element_margin_s">
              <app-sidebar-field>
                <app-auto-field
                  *ngIf="themeService.isDefaultTheme"
                  [field]="
                    createField({
                      name: 'border_color_2',
                      field: 'ColorField',
                      params: {
                        classes: ['select_small', 'select_fill'],
                        allow_empty: true,
                        empty_color: placeholders.borderColor2,
                        default_color: placeholders.borderColor2,
                        custom_colors: true,
                        alpha_enabled: true,
                        prefix: 'Primary'
                      }
                    })
                  "
                  [form]="context.controls.appearance"
                  [label]="false"
                ></app-auto-field>

                <app-auto-field
                  *ngIf="themeService.isDarkTheme"
                  [field]="
                    createField({
                      name: 'border_color_2_dark',
                      field: 'ColorField',
                      params: {
                        classes: ['select_small', 'select_fill'],
                        allow_empty: true,
                        empty_color: placeholders.borderColor2Dark,
                        default_color: placeholders.borderColor2Dark,
                        custom_colors: true,
                        alpha_enabled: true,
                        prefix: 'Primary'
                      }
                    })
                  "
                  [form]="context.controls.appearance"
                  [label]="false"
                ></app-auto-field>

                <ng-container description>
                  Regular component borders
                </ng-container>
              </app-sidebar-field>
            </div>

            <div class="sidebar__element sidebar__element_margin_s">
              <app-sidebar-field>
                <app-auto-field
                  *ngIf="themeService.isDefaultTheme"
                  [field]="
                    createField({
                      name: 'border_color',
                      field: 'ColorField',
                      params: {
                        classes: ['select_small', 'select_fill'],
                        allow_empty: true,
                        empty_color: placeholders.borderColor,
                        default_color: placeholders.borderColor,
                        custom_colors: true,
                        alpha_enabled: true,
                        prefix: 'Secondary'
                      }
                    })
                  "
                  [form]="context.controls.appearance"
                  [label]="false"
                ></app-auto-field>

                <app-auto-field
                  *ngIf="themeService.isDarkTheme"
                  [field]="
                    createField({
                      name: 'border_color_dark',
                      field: 'ColorField',
                      params: {
                        classes: ['select_small', 'select_fill'],
                        allow_empty: true,
                        empty_color: placeholders.borderColorDark,
                        default_color: placeholders.borderColorDark,
                        custom_colors: true,
                        alpha_enabled: true,
                        prefix: 'Secondary'
                      }
                    })
                  "
                  [form]="context.controls.appearance"
                  [label]="false"
                ></app-auto-field>

                <ng-container description>
                  Input borders
                </ng-container>
              </app-sidebar-field>
            </div>

            <div class="sidebar__element sidebar__element_margin_s">
              <app-sidebar-field>
                <app-auto-field
                  *ngIf="themeService.isDefaultTheme"
                  [field]="
                    createField({
                      name: 'border_color_3',
                      field: 'ColorField',
                      params: {
                        classes: ['select_small', 'select_fill'],
                        allow_empty: true,
                        empty_color: placeholders.borderColor3,
                        default_color: placeholders.borderColor3,
                        custom_colors: true,
                        alpha_enabled: true,
                        prefix: 'Bright'
                      }
                    })
                  "
                  [form]="context.controls.appearance"
                  [label]="false"
                ></app-auto-field>

                <app-auto-field
                  *ngIf="themeService.isDarkTheme"
                  [field]="
                    createField({
                      name: 'border_color_3_dark',
                      field: 'ColorField',
                      params: {
                        classes: ['select_small', 'select_fill'],
                        allow_empty: true,
                        empty_color: placeholders.borderColor3Dark,
                        default_color: placeholders.borderColor3Dark,
                        custom_colors: true,
                        alpha_enabled: true,
                        prefix: 'Bright'
                      }
                    })
                  "
                  [form]="context.controls.appearance"
                  [label]="false"
                ></app-auto-field>

                <ng-container description>
                  Focus borders
                </ng-container>
              </app-sidebar-field>
            </div>
          </app-sidebar-card>
        </app-disabled>
      </app-sidebar-field>
    </div>

    <div class="sidebar__element">
      <app-sidebar-field [label]="'Default theme'">
        <ng-container actions>
          <span class="sidebar-field__label-help">
            <span
              class="help-icon help-icon_clickable icon-help"
              [appTip]="
                'Default theme is used when opening App for the first time. Each user can switch theme after load if there is a corresponding button in Menu.'
              "
              [appTipOptions]="{ side: 'top' }"
            ></span>
          </span>
        </ng-container>

        <div class="sidebar-image-toggle sidebar-image-toggle_cols_3" style="margin-top: 2px;">
          <div *ngFor="let theme of ['default', 'dark']" class="sidebar-image-toggle__col">
            <a
              href="javascript:void(0)"
              class="sidebar-image-toggle__item"
              [ngClass]="'sidebar-image-toggle__item-' + theme"
              [class.sidebar-image-toggle__item_active]="theme === settingsForm.controls.default_theme.value"
              (click)="changeTheme(theme)"
            >
              <div
                class="sidebar-image-toggle__item-inner"
                [style.padding-top.%]="(110 / 195) * 100"
                [style.background-image]="'/assets/images/theme-' + theme + '.svg' | appDeployCssUrl"
              ></div>
            </a>
          </div>
        </div>
      </app-sidebar-field>
    </div>

    <div class="sidebar__element">
      <app-sidebar-field [label]="'Auto-colors'" [subtitle]="'Used for chart datasets and tags'">
        <app-disabled
          [active]="!currentProjectStore.instance.features.isStylesEnabled()"
          [clickable]="true"
          (click)="onStylesFeatureClick()"
        >
          <app-sidebar-card>
            <div
              *ngIf="themeService.isDefaultTheme && context.controls.appearance.controls.auto_colors as colorsControl"
              class="sidebar__element sidebar__element_margin_s"
            >
              <div
                *ngFor="let row of colorsControl.controls.length / 2 | appRange"
                class="sidebar__element sidebar__element_margin_xs sidebar__element_padding_none"
              >
                <div class="sidebar-cols">
                  <div *ngFor="let col of [0, 1]" class="sidebar-cols__item" [style.width.%]="50">
                    <app-sidebar-field>
                      <app-auto-field
                        *ngIf="colorsControl.controls[row * 2 + col]"
                        [field]="
                          createField({
                            name: row * 2 + col,
                            field: 'ColorField',
                            placeholder: colorsControl.defaultColors[row * 2 + col],
                            params: {
                              classes: ['select_small', 'select_fill'],
                              allow_empty: true,
                              empty_color: colorsControl.defaultColors[row * 2 + col],
                              default_color: colorsControl.defaultColors[row * 2 + col],
                              custom_colors: true
                            }
                          })
                        "
                        [form]="colorsControl"
                        [label]="false"
                      ></app-auto-field>
                    </app-sidebar-field>
                  </div>
                </div>
              </div>
            </div>

            <div
              *ngIf="themeService.isDarkTheme && context.controls.appearance.controls.auto_colors_dark as colorsControl"
              class="sidebar__element sidebar__element_margin_s"
            >
              <div
                *ngFor="let row of colorsControl.controls.length / 2 | appRange"
                class="sidebar__element sidebar__element_margin_xs sidebar__element_padding_none"
              >
                <div class="sidebar-cols">
                  <div *ngFor="let col of [0, 1]" class="sidebar-cols__item" [style.width.%]="50">
                    <app-sidebar-field>
                      <app-auto-field
                        *ngIf="colorsControl.controls[row * 2 + col]"
                        [field]="
                          createField({
                            name: row * 2 + col,
                            field: 'ColorField',
                            placeholder: colorsControl.defaultColors[row * 2 + col],
                            params: {
                              classes: ['select_small', 'select_fill'],
                              allow_empty: true,
                              empty_color: colorsControl.defaultColors[row * 2 + col],
                              default_color: colorsControl.defaultColors[row * 2 + col],
                              custom_colors: true
                            }
                          })
                        "
                        [form]="colorsControl"
                        [label]="false"
                      ></app-auto-field>
                    </app-sidebar-field>
                  </div>
                </div>
              </div>
            </div>
          </app-sidebar-card>
        </app-disabled>
      </app-sidebar-field>
    </div>
  </app-sidebar-section>
</div>

<app-sidebar-submit
  [saveDisabled]="!(hasChanges$ | async)"
  [saveLoading]="submitLoading"
  [saveLabel]="'Save changes'"
  [cancelDisabled]="!(hasChanges$ | async)"
  [cancelLabel]="'Reset changes'"
  [hidden]="!(hasChanges$ | async)"
  (cancelClick)="cancel()"
  (submitClick)="submit()"
>
</app-sidebar-submit>
