import { CdkConnectedOverlay } from '@angular/cdk/overlay';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { fonts, globalFontOptions } from '@modules/theme';
import { controlValue, isSet } from '@shared';

import { Option } from '../../data/option';

const fontOptions: Option<string>[] = [
  ...globalFontOptions,
  ...fonts.map(item => {
    return {
      name: item.name,
      value: item.name
    };
  })
];

@Component({
  selector: 'app-font-control',
  templateUrl: './font-control.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FontControlComponent implements OnInit, OnDestroy {
  @Input() control: AbstractControl;
  @Input() placeholder = 'Choose Font';
  @Input() globalFonts = false;
  @Input() prefix: string;
  @Input() fill = false;
  @Input() small = false;
  @Input() allowNull = false;
  @Output() fontSelect = new EventEmitter<string>();

  @ViewChild(CdkConnectedOverlay) cdkConnectedOverlay: CdkConnectedOverlay;

  valueStr: string;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {
    controlValue(this.control)
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        const option = isSet(value) ? fontOptions.find(item => item.value == value) : undefined;
        this.valueStr = option ? option.name : undefined;
        this.cd.markForCheck();
      });
  }

  ngOnDestroy(): void {}

  onFontSelect(value: string) {
    this.control.patchValue(value);
    this.fontSelect.emit(value);
  }
}
