<app-auto-field
  *ngIf="staticValueField == fieldTypes.Boolean"
  [form]="form"
  [label]="false"
  [field]="
    createField({
      name: 'control',
      field: fieldTypes.Select,
      placeholder: placeholder,
      params: {
        valueEquals: booleanValueEquals,
        options: [
          { value: false, name: 'False' },
          { value: true, name: 'True' }
        ],
        classes: fill ? ['select_fill'] : []
      },
      reset_enabled: resetEnabled
    })
  "
  [autofocus]="focusedInitial"
></app-auto-field>

<app-auto-field
  *ngIf="staticValueField != fieldTypes.Boolean"
  [form]="form"
  [label]="false"
  [field]="
    createField({
      name: 'control',
      placeholder: placeholder,
      field: staticValueField,
      params: staticValueParamsDisplay,
      reset_enabled: resetEnabled
    })
  "
  [context]="context"
  [contextElement]="contextElement"
  [autofocus]="focusedInitial"
>
</app-auto-field>
