var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';
import { auditTime } from 'rxjs/operators';
import { ViewContextElement } from '@modules/customize';
import { BooleanFieldStyle } from '@modules/field-components';
import { createFormFieldFactory, FieldType, registerFieldViewParamsComponent } from '@modules/fields';
import { ITEM_OUTPUT } from '@modules/list';
import { FieldParamsComponent } from '../../field-params/field-params.component';
import { ForeignKeyFieldViewParamsForm } from './foreign-key-field-view-params.form';
export var optionsElementName = '__jet_options_element__';
var ForeignKeyFieldViewParamsComponent = /** @class */ (function (_super) {
    __extends(ForeignKeyFieldViewParamsComponent, _super);
    function ForeignKeyFieldViewParamsComponent(form, columnContextElement, cd) {
        var _this = _super.call(this) || this;
        _this.form = form;
        _this.columnContextElement = columnContextElement;
        _this.cd = cd;
        _this.createField = createFormFieldFactory();
        _this.primaryKeyTransformerVisible = false;
        _this.booleanFieldStyle = BooleanFieldStyle;
        _this.columnContextElementPath = [ITEM_OUTPUT];
        _this.editable = false;
        return _this;
    }
    ForeignKeyFieldViewParamsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.form.init(this.control, this.context);
        this.columnContextElement.initElement({
            uniqueName: optionsElementName,
            name: 'Fields'
        });
        if (this.field$) {
            this.field$.pipe(untilDestroyed(this)).subscribe(function (field) {
                _this.editable = (!_this.configurable || _this.configurable.editable) && field && field['editable'];
                _this.cd.markForCheck();
            });
        }
        combineLatest(this.form.resourceModelItems$(), this.form.modelDescription$(), this.form.columnOptions$(), this.form.sortableColumnOptions$())
            .pipe(auditTime(1000 / 60), untilDestroyed(this))
            .subscribe(function (result) {
            _this.resourceModelItems = result[0], _this.modelDescription = result[1], _this.columnOptions = result[2], _this.sortableColumnOptions = result[3];
            _this.cd.markForCheck();
            _this.columnContextElement.setOutputs([
                {
                    uniqueName: ITEM_OUTPUT,
                    name: 'Fields',
                    internal: true,
                    children: _this.columnOptions.map(function (item) {
                        return {
                            uniqueName: item.option.value,
                            name: item.option.name,
                            icon: item.option.icon
                        };
                    })
                }
            ]);
        });
    };
    ForeignKeyFieldViewParamsComponent.prototype.ngOnDestroy = function () { };
    ForeignKeyFieldViewParamsComponent.prototype.togglePrimaryKeyTransformerVisible = function () {
        this.primaryKeyTransformerVisible = !this.primaryKeyTransformerVisible;
        this.cd.markForCheck();
    };
    return ForeignKeyFieldViewParamsComponent;
}(FieldParamsComponent));
export { ForeignKeyFieldViewParamsComponent };
registerFieldViewParamsComponent(FieldType.RelatedModel, ForeignKeyFieldViewParamsComponent);
