import pickBy from 'lodash/pickBy';
import { ActionItem } from '@modules/actions';
import { ParameterField } from '@modules/fields';
import { fromLegacyModel } from '@modules/models';
import { resourceTypeItems } from '@modules/projects';
import { isSet, splitmax } from '@shared';
import { ModelData } from '../model-data';
export var ViewSettingsType;
(function (ViewSettingsType) {
    ViewSettingsType["List"] = "list";
    ViewSettingsType["Change"] = "change";
    ViewSettingsType["Action"] = "action";
    ViewSettingsType["Dashboard"] = "dashboard";
    ViewSettingsType["Custom"] = "custom";
})(ViewSettingsType || (ViewSettingsType = {}));
export function isSameViewSettings(current, model) {
    if (!model) {
        return false;
    }
    if (isSet(current.uid)) {
        if (model instanceof ViewSettings) {
            return model.uid == current.uid;
        }
        else {
            return current.uid == model;
        }
    }
    else if (isSet(current.resource) && isSet(current.model)) {
        if (model instanceof ViewSettings) {
            return model.resource == current.resource && model.model == current.model;
        }
        else {
            var modelId = model;
            var params = splitmax(modelId, '.', 2);
            return params.length == 2
                ? (!current.resource || current.resource == params[0]) && current.model == params[1]
                : fromLegacyModel(current.model) == params[0];
        }
    }
}
var ViewSettings = /** @class */ (function () {
    function ViewSettings() {
        this.parameters = [];
        this.queries = [];
        this.openActions = [];
        this.padding = {};
        this.params = {};
        this.draft = false;
        this.deleted = false;
        this.usedResources = [];
        this.configuredElements = 0;
        this.configuredModelElements = 0;
        this.configuredActionElements = 0;
        this.newlyCreated = false;
    }
    // TODO: Remove ModelDescription
    ViewSettings.prototype.deserialize = function (data) {
        this.uid = data['uid'];
        this.project = data['project'];
        this.view = data['view'];
        this.uniqueName = data['unique_name'];
        this.name = data['name'];
        this.resource = data['resource'];
        this.model = fromLegacyModel(data['model']);
        this.params = JSON.parse(data['params'] || '{}');
        if (this.params['parameters']) {
            this.parameters = this.params['parameters'].map(function (item) { return new ParameterField().deserialize(item); });
        }
        if (this.params['queries']) {
            this.queries = this.params['queries'].map(function (item) { return new ModelData().deserialize(item); });
        }
        if (this.params['open_actions']) {
            this.openActions = this.params['open_actions'].map(function (item, i) {
                var result = new ActionItem().deserialize(item);
                // Backward compatibility
                if (!isSet(result.name)) {
                    result.name = "Action " + (i + 1);
                }
                return result;
            });
        }
        if (isSet(this.params['background_color'])) {
            this.backgroundColor = this.params['background_color'];
        }
        if (isSet(this.params['background_color_dark'])) {
            this.backgroundColorDark = this.params['background_color_dark'];
        }
        if (isSet(this.params['background_color_2'])) {
            this.backgroundColor2 = this.params['background_color_2'];
        }
        if (isSet(this.params['background_color_2_dark'])) {
            this.backgroundColor2Dark = this.params['background_color_2_dark'];
        }
        if (isSet(this.params['background_color_3'])) {
            this.backgroundColor3 = this.params['background_color_3'];
        }
        if (isSet(this.params['background_color_3_dark'])) {
            this.backgroundColor3Dark = this.params['background_color_3_dark'];
        }
        if (isSet(this.params['background_color_4'])) {
            this.backgroundColor4 = this.params['background_color_4'];
        }
        if (isSet(this.params['background_color_4_dark'])) {
            this.backgroundColor4Dark = this.params['background_color_4_dark'];
        }
        if (isSet(this.params['background_color_5'])) {
            this.backgroundColor5 = this.params['background_color_5'];
        }
        if (isSet(this.params['background_color_5_dark'])) {
            this.backgroundColor5Dark = this.params['background_color_5_dark'];
        }
        if (isSet(this.params['text_color'])) {
            this.textColor = this.params['text_color'];
        }
        if (isSet(this.params['text_color_dark'])) {
            this.textColorDark = this.params['text_color_dark'];
        }
        if (isSet(this.params['text_color_2'])) {
            this.textColor2 = this.params['text_color_2'];
        }
        if (isSet(this.params['text_color_2_dark'])) {
            this.textColor2Dark = this.params['text_color_2_dark'];
        }
        if (isSet(this.params['text_color_3'])) {
            this.textColor3 = this.params['text_color_3'];
        }
        if (isSet(this.params['text_color_3_dark'])) {
            this.textColor3Dark = this.params['text_color_3_dark'];
        }
        if (isSet(this.params['border_color'])) {
            this.borderColor = this.params['border_color'];
        }
        if (isSet(this.params['border_color_dark'])) {
            this.borderColorDark = this.params['border_color_dark'];
        }
        if (isSet(this.params['border_color_2'])) {
            this.borderColor2 = this.params['border_color_2'];
        }
        if (isSet(this.params['border_color_2_dark'])) {
            this.borderColor2Dark = this.params['border_color_2_dark'];
        }
        if (isSet(this.params['border_color_3'])) {
            this.borderColor3 = this.params['border_color_3'];
        }
        if (isSet(this.params['border_color_3_dark'])) {
            this.borderColor3Dark = this.params['border_color_3_dark'];
        }
        if (isSet(this.params['max_width'])) {
            this.maxWidth = this.params['max_width'];
        }
        if (this.params['padding']) {
            this.padding = this.params['padding'];
        }
        if (this.params['source_template']) {
            this.sourceTemplate = this.params['source_template'];
        }
        if (this.params['template_instance_id']) {
            this.templateInstanceId = this.params['template_instance_id'];
        }
        if (this.params['used_resources']) {
            this.usedResources = this.params['used_resources']
                .map(function (item) {
                return {
                    type: item['type'],
                    typeItem: resourceTypeItems.find(function (i) { return i.name == item['type_item']; }),
                    name: item['name']
                };
            })
                .filter(function (item) { return item.typeItem != undefined; });
        }
        if (this.params['configured_elements'] !== undefined) {
            this.configuredElements = this.params['configured_elements'];
        }
        if (this.params['configured_model_elements'] !== undefined) {
            this.configuredModelElements = this.params['configured_model_elements'];
        }
        if (this.params['configured_action_elements'] !== undefined) {
            this.configuredActionElements = this.params['configured_action_elements'];
        }
        if (data['draft'] !== undefined) {
            this.draft = data['draft'];
        }
        if (data['deleted'] !== undefined) {
            this.deleted = data['deleted'];
        }
        return this;
    };
    ViewSettings.prototype.serialize = function (fields) {
        this.params['queries'] = this.queries.map(function (item) { return item.serialize(); });
        this.params['open_actions'] = this.openActions.map(function (item) { return item.serialize(); });
        this.params['background_color'] = this.backgroundColor;
        this.params['background_color_dark'] = this.backgroundColorDark;
        this.params['background_color_2'] = this.backgroundColor2;
        this.params['background_color_2_dark'] = this.backgroundColor2Dark;
        this.params['background_color_3'] = this.backgroundColor3;
        this.params['background_color_3_dark'] = this.backgroundColor3Dark;
        this.params['background_color_4'] = this.backgroundColor4;
        this.params['background_color_4_dark'] = this.backgroundColor4Dark;
        this.params['background_color_5'] = this.backgroundColor5;
        this.params['background_color_5_dark'] = this.backgroundColor5Dark;
        this.params['text_color'] = this.textColor;
        this.params['text_color_dark'] = this.textColorDark;
        this.params['text_color_2'] = this.textColor2;
        this.params['text_color_2_dark'] = this.textColor2Dark;
        this.params['text_color_3'] = this.textColor3;
        this.params['text_color_3_dark'] = this.textColor3Dark;
        this.params['border_color'] = this.borderColor;
        this.params['border_color_dark'] = this.borderColorDark;
        this.params['border_color_2'] = this.borderColor2;
        this.params['border_color_2_dark'] = this.borderColor2Dark;
        this.params['border_color_3'] = this.borderColor3;
        this.params['border_color_3_dark'] = this.borderColor3Dark;
        this.params['max_width'] = this.maxWidth;
        this.params['padding'] = this.padding;
        this.params['source_template'] = this.sourceTemplate;
        this.params['template_instance_id'] = this.templateInstanceId;
        this.params['used_resources'] = this.usedResources.map(function (item) {
            return {
                type: item.type,
                type_item: item.typeItem.name,
                name: item.name
            };
        });
        this.params['configured_elements'] = this.configuredElements;
        this.params['configured_model_elements'] = this.configuredModelElements;
        this.params['configured_action_elements'] = this.configuredActionElements;
        var data = {
            uid: this.uid,
            project: this.project,
            view: this.view,
            name: this.name,
            unique_name: this.uniqueName,
            resource: this.resource,
            model: this.model,
            parameters: this.parameters.map(function (item) { return item.serialize(); }),
            params: JSON.stringify(this.params),
            draft: this.draft,
            deleted: this.deleted
        };
        if (fields) {
            data = pickBy(data, function (v, k) { return fields.includes(k); });
        }
        return data;
    };
    ViewSettings.prototype.isSame = function (model) {
        return isSameViewSettings(this, model);
    };
    Object.defineProperty(ViewSettings.prototype, "link", {
        get: function () {
            if (isSet(this.uniqueName)) {
                return ['page', this.uniqueName];
            }
            else if (isSet(this.resource) && isSet(this.model)) {
                return ['models', this.resource + "." + this.model];
            }
        },
        enumerable: true,
        configurable: true
    });
    ViewSettings.prototype.getActionsCount = function () {
        return this.queries.length + this.openActions.length;
    };
    return ViewSettings;
}());
export { ViewSettings };
