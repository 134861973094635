import * as moment from 'moment';

import { TokenOptions } from '../../services/api/api.service';

export function serializeTokenOptions(token: TokenOptions): Object {
  return {
    token: token.token,
    access_token: token.accessToken,
    access_token_expires: token.accessTokenExpires ? token.accessTokenExpires.toISOString() : undefined,
    refresh_token: token.refreshToken,
    refresh_token_expires: token.refreshTokenExpires ? token.refreshTokenExpires.toISOString() : undefined,
    server_time: token.serverTime ? token.serverTime.toISOString() : undefined,
    sso: token.sso,
    incognito: token.incognito
  };
}

export function deserializeTokenOptions(data: Object): TokenOptions {
  return {
    token: data['token'],
    accessToken: data['access_token'],
    accessTokenExpires: data['access_token_expires'] ? moment(data['access_token_expires']) : undefined,
    refreshToken: data['refresh_token'],
    refreshTokenExpires: data['refresh_token_expires'] ? moment(data['refresh_token_expires']) : undefined,
    serverTime: data['server_time'] ? moment(data['server_time']) : undefined,
    sso: data['sso'],
    incognito: data['incognito']
  };
}

export function isTokenExpired(expires: moment.Moment): boolean {
  return !expires || expires.diff(moment(), 'seconds') < 30;
}
