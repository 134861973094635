<app-external-fonts [fonts]="externalFonts"></app-external-fonts>

<div class="sidebar__element">
  <app-sidebar-field [label]="'Theme gallery'">
    <ng-container actions-right>
      <button
        type="button"
        class="button button_icon button_extra-small button_background button_segment-left button_no-margin"
        [disabled]="!isScrollPrevAvailable()"
        (click)="scrollToPrev()"
      >
        <span class="button__icon icon-arrow_backward_2"></span>
      </button>

      <button
        type="button"
        class="button button_icon button_extra-small button_background button_segment-right button_no-margin"
        [disabled]="!isScrollNextAvailable()"
        (click)="scrollToNext()"
      >
        <span class="button__icon icon-arrow_forward_2"></span>
      </button>
    </ng-container>

    <div class="sidebar-gallery">
      <div
        class="sidebar-gallery__viewport"
        (scroll)="onScroll()"
        (mousewheel)="onMouseWheel($event)"
        #viewport_element
      >
        <div class="sidebar-gallery__items">
          <div class="sidebar-gallery__items-padding"></div>

          <app-theme-gallery-item
            *ngFor="let theme of items"
            class="sidebar-gallery__item"
            [theme]="theme"
            (click)="applyThemeTemplate(theme)"
            #item_element
          ></app-theme-gallery-item>

          <div class="sidebar-gallery__items-padding"></div>
        </div>
      </div>
    </div>
  </app-sidebar-field>
</div>

<div *ngIf="(currentUserStore.instance$ | async)?.isStaff" class="sidebar__element">
  <button type="button" class="button button_fill button_orange-alternative staff-block" (click)="dumpThemeTemplate()">
    <span class="button__icon icon-palette"></span>
    <span class="button__label">Dump Current theme</span>
  </button>
</div>
