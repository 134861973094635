import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  Optional
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';

import { TintStyle } from '@modules/actions';
import { CustomizeGradient, FillEditContext } from '@modules/colors-components';
import { ActionElementStyles, AlignHorizontal } from '@modules/customize';
import { ActionElementStylesControl } from '@modules/customize-bar';
import { controlValue } from '@shared';

@Component({
  selector: 'app-styles-preview-button-style',
  templateUrl: './styles-preview-button-style.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StylesPreviewButtonStyleComponent implements OnInit, OnDestroy {
  @Input() style: TintStyle;
  @Input() control: ActionElementStylesControl;
  @Input() label = 'Button';
  @Input() accentColor: string;
  @Input() horizontal = false;

  elementStyles: ActionElementStyles;
  hoverElementStyles: ActionElementStyles;
  activeElementStyles: ActionElementStyles;
  customizeGradient: CustomizeGradient;
  alignHorizontals = AlignHorizontal;
  tintStyles = TintStyle;

  constructor(@Optional() private fillEditContext: FillEditContext, private cd: ChangeDetectorRef) {}

  ngOnInit() {
    combineLatest(controlValue(this.control), this.control.stylesDefaultUpdated$())
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.elementStyles = new ActionElementStyles({
          textStyle: this.control.controls.text_style.serialize(false),
          iconSettings: this.control.controls.icon_settings.serialize(false),
          fillSettings: this.control.controls.fill_settings.serialize(false),
          borderSettings: this.control.controls.border_settings.serialize(false),
          borderRadius: this.control.controls.border_radius.serialize(),
          shadow: this.control.controls.shadow.serialize(false),
          padding: this.control.controls.padding.serialize(),
          margin: this.control.controls.margin.serialize()
        });
        this.hoverElementStyles = new ActionElementStyles({
          textStyle: this.control.controls.text_style.serialize(false),
          iconSettings: this.control.controls.icon_settings.serialize(false),
          fillSettings: this.control.controls.fill_settings.serialize(false),
          borderSettings: this.control.controls.border_settings.serialize(false),
          borderRadius: this.control.controls.border_radius.serialize(),
          shadow: this.control.controls.shadow.serialize(false),
          padding: this.control.controls.padding.serialize(),
          margin: this.control.controls.margin.serialize(),

          ...(this.control.controls.hover_text_style.isSet() && {
            textStyle: this.control.controls.hover_text_style.serialize(false)
          }),
          ...(this.control.controls.hover_icon_settings.isSet() && {
            iconSettings: this.control.controls.hover_icon_settings.serialize(false)
          }),
          ...(this.control.controls.hover_fill_settings.isSet() && {
            fillSettings: this.control.controls.hover_fill_settings.serialize(false)
          }),
          ...(this.control.controls.hover_border_settings.isSet() && {
            borderSettings: this.control.controls.hover_border_settings.serialize(false)
          }),
          ...(this.control.controls.hover_shadow.isSet() && {
            shadow: this.control.controls.hover_shadow.serialize(false)
          })
        });
        this.activeElementStyles = new ActionElementStyles({
          textStyle: this.control.controls.text_style.serialize(false),
          iconSettings: this.control.controls.icon_settings.serialize(false),
          fillSettings: this.control.controls.fill_settings.serialize(false),
          borderSettings: this.control.controls.border_settings.serialize(false),
          borderRadius: this.control.controls.border_radius.serialize(),
          shadow: this.control.controls.shadow.serialize(false),
          padding: this.control.controls.padding.serialize(),
          margin: this.control.controls.margin.serialize(),

          ...(this.control.controls.active_text_style.isSet() && {
            textStyle: this.control.controls.active_text_style.serialize(false)
          }),
          ...(this.control.controls.active_icon_settings.isSet() && {
            iconSettings: this.control.controls.active_icon_settings.serialize(false)
          }),
          ...(this.control.controls.active_fill_settings.isSet() && {
            fillSettings: this.control.controls.active_fill_settings.serialize(false)
          }),
          ...(this.control.controls.active_border_settings.isSet() && {
            borderSettings: this.control.controls.active_border_settings.serialize(false)
          }),
          ...(this.control.controls.active_shadow.isSet() && {
            shadow: this.control.controls.active_shadow.serialize(false)
          })
        });
        this.cd.markForCheck();
      });

    if (this.fillEditContext) {
      this.fillEditContext.customizingGradient$.pipe(untilDestroyed(this)).subscribe(value => {
        this.customizeGradient = value;
        this.cd.detectChanges();
      });
    }
  }

  ngOnDestroy(): void {}
}
