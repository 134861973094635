import { Pipe, PipeTransform } from '@angular/core';

import { isSet } from '../../utils/common/common';

@Pipe({
  name: 'appFirstSet'
})
export class FirstSetPipe implements PipeTransform {
  transform(values: any[], defaultValue?: any): any {
    if (!isSet(values, true)) {
      return defaultValue;
    }

    for (const value of values) {
      if (isSet(value, true)) {
        return value;
      }
    }

    return defaultValue;
  }
}
