/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../grid-stub-item/grid-stub-item.component.ngfactory";
import * as i2 from "../grid-stub-item/grid-stub-item.component";
import * as i3 from "../../../../shared/pipes/is-set/is-set.pipe";
import * as i4 from "@angular/common";
import * as i5 from "./grid-stub.component";
var styles_GridStubComponent = [];
var RenderType_GridStubComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_GridStubComponent, data: {} });
export { RenderType_GridStubComponent as RenderType_GridStubComponent };
function View_GridStubComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-grid-stub-item", [], [[2, "grid__item", null], [2, "grid__item_snap", null], [2, "grid__item_snap-margin", null], [1, "data-card-width", 0], [4, "padding-left", "px"], [4, "padding-right", "px"], [4, "padding-top", "px"], [4, "padding-bottom", "px"]], null, null, i1.View_GridStubItemComponent_0, i1.RenderType_GridStubItemComponent)), i0.ɵdid(1, 638976, null, 0, i2.GridStubItemComponent, [i0.ElementRef], { index: [0, "index"], columns: [1, "columns"], rowCards: [2, "rowCards"], height: [3, "height"], gapHorizontal: [4, "gapHorizontal"], gapVertical: [5, "gapVertical"], animating: [6, "animating"], theme: [7, "theme"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_8 = _v.context.index; var currVal_9 = _co.columns; var currVal_10 = _co.rowCards; var currVal_11 = _co.cardHeight; var currVal_12 = _co.gapHorizontal; var currVal_13 = _co.gapVertical; var currVal_14 = _co.animating; var currVal_15 = _co.theme; _ck(_v, 1, 0, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).cls; var currVal_1 = i0.ɵnov(_v, 1).snapCls; var currVal_2 = i0.ɵnov(_v, 1).snapMarginCls; var currVal_3 = i0.ɵnov(_v, 1).dataGridCards; var currVal_4 = i0.ɵnov(_v, 1).paddingLeftPx; var currVal_5 = i0.ɵnov(_v, 1).paddingRightPx; var currVal_6 = i0.ɵnov(_v, 1).paddingTopPx; var currVal_7 = i0.ɵnov(_v, 1).paddingBottomPx; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
export function View_GridStubComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i3.IsSetPipe, []), (_l()(), i0.ɵeld(1, 0, null, null, 6, "div", [["class", "grid"], ["style", "overflow: hidden;"]], [[4, "margin-left", "px"], [4, "margin-right", "px"], [4, "margin-top", "px"], [4, "margin-bottom", "px"], [4, "height", "px"]], null, null, null, null)), i0.ɵppd(2, 1), i0.ɵppd(3, 1), i0.ɵppd(4, 1), i0.ɵppd(5, 1), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_GridStubComponent_1)), i0.ɵdid(7, 278528, null, 0, i4.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_5 = _co.rowsRange; _ck(_v, 7, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i0.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i0.ɵnov(_v, 0), _co.gapHorizontal)) ? ((0 - _co.gapHorizontal) * 0.5) : null); var currVal_1 = (i0.ɵunv(_v, 1, 1, _ck(_v, 3, 0, i0.ɵnov(_v, 0), _co.gapHorizontal)) ? ((0 - _co.gapHorizontal) * 0.5) : null); var currVal_2 = (i0.ɵunv(_v, 1, 2, _ck(_v, 4, 0, i0.ɵnov(_v, 0), _co.gapVertical)) ? ((0 - _co.gapVertical) * 0.5) : null); var currVal_3 = (i0.ɵunv(_v, 1, 3, _ck(_v, 5, 0, i0.ɵnov(_v, 0), _co.gapVertical)) ? ((0 - _co.gapVertical) * 0.5) : null); var currVal_4 = _co.height; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }); }
export function View_GridStubComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-grid-stub", [], null, null, null, View_GridStubComponent_0, RenderType_GridStubComponent)), i0.ɵdid(1, 638976, null, 0, i5.GridStubComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GridStubComponentNgFactory = i0.ɵccf("app-grid-stub", i5.GridStubComponent, View_GridStubComponent_Host_0, { header: "header", columns: "columns", rows: "rows", rowCards: "rowCards", cardHeight: "cardHeight", gapHorizontal: "gapHorizontal", gapVertical: "gapVertical", animating: "animating", scrollable: "scrollable", height: "height", theme: "theme" }, {}, []);
export { GridStubComponentNgFactory as GridStubComponentNgFactory };
