import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, first, map, switchMap } from 'rxjs/operators';

import { DialogService } from '@common/dialogs';
import { preventDragMouseDownEvent } from '@common/drag-drop2';
import { FillSettingsControl } from '@modules/colors-components';
import { BorderSettingsControl, CornersControl, MarginControl } from '@modules/customize';
import { NumberFieldType } from '@modules/fields';
import { MenuContext } from '@modules/menu-components';
import { CurrentProjectStore } from '@modules/projects';
import { ThemeService } from '@modules/theme';
import { CurrentUserStore } from '@modules/users';
import { ascComparator, controlValue, isSet } from '@shared';

import { LayoutGroup, LayoutOption } from '../menu-block-layout-overlay/menu-block-layout-overlay.component';
import { MenuBlockControl } from '../project-settings/menu-block.control';

interface Style<T extends AbstractControl = AbstractControl> {
  label: string;
  icon: string;
  control: T;
  isSet$: (control: T) => Observable<boolean>;
  isEnabled?: (style: Style<T>) => boolean;
  onAdd?: (style: Style<T>) => void;
  onRemove?: (style: Style<T>) => void;
}

@Component({
  selector: 'app-menu-block-edit',
  templateUrl: './menu-block-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuBlockEditComponent implements OnInit, OnDestroy {
  @Input() title = 'Menu';
  @Input() control: MenuBlockControl;
  @Input() layoutGroups: LayoutGroup[] = [];
  @Input() active = false;
  @Input() disabled = false;
  @Output() edit = new EventEmitter<void>();
  @Output() remove = new EventEmitter<void>();

  enabledInputToggled = false;
  totalItems = 0;
  itemsPluralMap = { '=1': '# item', '=0': 'no items', other: '# items' };
  layoutOpened = false;
  layoutSelected: LayoutOption;
  widthEnabled = false;
  heightEnabled = false;
  defaultWidth: number;
  defaultHeight: number;
  styles: Style[] = [];
  enabledStyles: Style[] = [];
  addedStyle: Style;
  numberFieldTypes = NumberFieldType;

  trackStyleFn(i, item: Style) {
    return item.control;
  }

  constructor(
    public currentUserStore: CurrentUserStore,
    public currentProjectStore: CurrentProjectStore,
    public context: MenuContext,
    public themeService: ThemeService,
    private dialogService: DialogService,
    private sanitizer: DomSanitizer,
    private zone: NgZone,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    controlValue(this.control.controls.layout)
      .pipe(untilDestroyed(this))
      .subscribe(layout => {
        this.layoutSelected = this.layoutGroups
          .reduce<LayoutOption[]>((acc, item) => {
            acc.push(...item.options);
            return acc;
          }, [])
          .find(item => item.layout == layout);
        this.cd.markForCheck();
      });

    this.control
      .getTotalItems$()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.totalItems = this.control.getTotalItems();
        this.cd.markForCheck();
      });

    combineLatest(
      this.control.getWidthEnabled$(),
      this.control.getHeightEnabled$(),
      this.control.getDefaultWidth$(),
      this.control.getDefaultHeight$()
    )
      .pipe(untilDestroyed(this))
      .subscribe(([widthEnabled, heightEnabled, defaultWidth, defaultHeight]) => {
        this.widthEnabled = widthEnabled;
        this.heightEnabled = heightEnabled;
        this.defaultWidth = defaultWidth;
        this.defaultHeight = defaultHeight;
        this.cd.markForCheck();
      });

    this.updateStyles();
  }

  ngOnDestroy(): void {}

  updateStyles() {
    this.styles = [
      {
        label: 'Width',
        icon: 'resize_horizontal',
        control: this.control.controls.width,
        isSet$: control => controlValue(control).pipe(map(value => isSet(value))),
        isEnabled: () => this.widthEnabled,
        onRemove: style => style.control.reset()
      },
      {
        label: 'Height',
        icon: 'resize_vertical',
        control: this.control.controls.height,
        isSet$: control => controlValue(control).pipe(map(value => isSet(value))),
        isEnabled: () => this.heightEnabled,
        onRemove: style => style.control.reset()
      },
      {
        label: 'Background',
        icon: 'palette',
        control: this.control.controls.fill_settings,
        isSet$: control => control.isSet$(),
        onAdd: style => style.control.resetDefaults(),
        onRemove: style => style.control.reset()
      } as Style<FillSettingsControl>,
      {
        label: 'Border',
        icon: 'sides',
        control: this.control.controls.border_settings,
        isSet$: control => control.isSet$(),
        onAdd: style => style.control.resetDefaults(),
        onRemove: style => style.control.reset()
      } as Style<BorderSettingsControl>,
      {
        label: 'Corners',
        icon: 'corners',
        control: this.control.controls.border_radius,
        isSet$: control => control.isSet$(),
        onRemove: style => style.control.reset()
      } as Style<CornersControl>,
      {
        label: 'Outer spacing',
        icon: 'align_horizontal_center',
        control: this.control.controls.padding,
        isSet$: control => control.isSet$(),
        onRemove: style => style.control.reset()
      } as Style<MarginControl>
    ];

    const styles$: Observable<Style>[] = this.styles.map(item => {
      return item.isSet$(item.control).pipe(
        map(value => !!value),
        distinctUntilChanged(),
        map(styleIsSet => {
          if (styleIsSet) {
            return item;
          } else if (this.enabledStyles.find(enabledItem => enabledItem.label == item.label)) {
            return item;
          }
        })
      );
    });

    (styles$.length ? combineLatest(...styles$) : of<Style[]>([])).pipe(untilDestroyed(this)).subscribe(result => {
      this.enabledStyles = result
        .filter(item => item)
        .sort((lhs, rhs) => {
          const lhsIndex = this.enabledStyles.findIndex(enabledItem => enabledItem.label == lhs.label);
          const rhsIndex = this.enabledStyles.findIndex(enabledItem => enabledItem.label == rhs.label);

          if (lhsIndex === -1 && rhsIndex === -1) {
            return 0;
          } else if (lhsIndex === -1 && rhsIndex !== -1) {
            return 1;
          } else if (lhsIndex !== -1 && rhsIndex === -1) {
            return -1;
          } else {
            return ascComparator(lhsIndex, rhsIndex);
          }
        });
      this.cd.markForCheck();
    });
  }

  addStyle(style: Style) {
    this.enabledStyles = [...this.enabledStyles, style];
    this.addedStyle = style;
    this.cd.markForCheck();

    if (style.onAdd) {
      style.onAdd(style);
    }

    this.zone.onStable.pipe(first(), untilDestroyed(this)).subscribe(() => {
      this.addedStyle = undefined;
    });
  }

  isAddStyleEnabled(style: Style) {
    return !this.enabledStyles.includes(style);
  }

  removeStyle(style: Style) {
    style
      .isSet$(style.control)
      .pipe(
        first(),
        switchMap(styleIsSet => {
          if (!styleIsSet) {
            return of(true);
          }

          return this.dialogService.warning({
            title: 'Remove style',
            description: `Are you sure want to remove style <strong>"${style.label}"</strong>?`,
            style: 'orange'
          });
        }),
        filter(result => result == true),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.removeStyleProcess(style);
      });
  }

  removeStyleProcess(style: Style) {
    this.enabledStyles = this.enabledStyles.filter(item => item !== style);
    this.cd.markForCheck();

    if (style.onRemove) {
      style.onRemove(style);
    }
  }

  selectLayoutOption(option: LayoutOption) {
    const newMenu = !this.control.controls.layout.value;

    this.control.setLayout(option.layout);

    if (
      isSet(option.color) &&
      !isSet(this.control.controls.fill_settings.controls.fill.isSet()) &&
      !isSet(this.control.controls.fill_settings.controls.fill_dark.isSet())
    ) {
      this.control.controls.fill_settings.controls.fill.setColor(option.color);
      this.control.controls.fill_settings.controls.fill_dark.setColor(option.color);
    }

    this.control.controls.width.patchValue(undefined);
    this.control.controls.height.patchValue(undefined);

    if (newMenu) {
      this.control.controls.enabled.patchValue(true);
      this.control.applyDefaultState();
    }
  }

  setLayoutOpened(value: boolean) {
    this.layoutOpened = value;
    this.cd.markForCheck();
  }

  enable() {
    if (this.control.controls.layout.value) {
      this.control.controls.enabled.patchValue(true);
    } else {
      this.setLayoutOpened(true);
    }
  }

  disable() {
    this.control.controls.enabled.patchValue(false);
  }

  toggleEnabledInput() {
    if (this.control.controls.enabled_input_enabled.value) {
      this.control.controls.enabled_input_enabled.patchValue(false);
      this.enabledInputToggled = false;
    } else {
      this.control.controls.enabled_input_enabled.patchValue(true);
      this.enabledInputToggled = true;
    }
  }

  toggleEnabled() {
    if (this.control.controls.enabled.value) {
      this.disable();
    } else {
      this.enable();
    }
  }

  onClick(event: MouseEvent) {
    if (this.control.controls.enabled.value) {
      return;
    }

    this.enable();
    event.stopPropagation();
  }

  onStylesMousedown(e: MouseEvent) {
    preventDragMouseDownEvent(e);
  }

  confirmRemove() {
    this.dialogService
      .warning({
        title: 'Deleting',
        description: `Are you sure want to delete ${this.title} menu?`
      })
      .pipe(
        filter(result => result == true),
        untilDestroyed(this)
      )
      .subscribe(() => this.remove.emit());
  }
}
