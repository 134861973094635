import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject } from 'rxjs';

import { AppConfigService } from '@core';
import { Domain, getActualDomain } from '@modules/domain';
import { ProjectDomainController } from '@modules/domain-components';
import { createFormFieldFactory, NumberFieldType } from '@modules/fields';
import { ProjectUniqueNameController } from '@modules/layout-components';
import { CurrentProjectStore, Project } from '@modules/projects';
import { ThemeService } from '@modules/theme';

import { ProjectSettingsUpdateForm } from '../project-settings/project-settings-update.form';
import { ProjectUpdateForm } from '../project-settings/project-update.form';

@Component({
  selector: 'app-customize-bar-general-edit',
  templateUrl: './customize-bar-general-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeBarGeneralEditComponent implements OnInit, OnDestroy {
  @Input() projectForm: ProjectUpdateForm;
  @Input() settingsForm: ProjectSettingsUpdateForm;

  appBaseUrl: string;
  appProjectUrl: string;
  appUrlHover = new BehaviorSubject<boolean>(false);
  domain: Domain;
  createField = createFormFieldFactory();
  numberFieldTypes = NumberFieldType;

  constructor(
    public currentProjectStore: CurrentProjectStore,
    public themeService: ThemeService,
    private appConfigService: AppConfigService,
    private projectUniqueNameController: ProjectUniqueNameController,
    private projectDomainController: ProjectDomainController,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.currentProjectStore
      .get()
      .pipe(untilDestroyed(this))
      .subscribe(project => {
        this.appBaseUrl = this.getAppBaseUrl(project, project.uniqueName);
        this.appProjectUrl = project.uniqueName;
        this.domain = project.domain;
        this.cd.markForCheck();
      });
  }

  ngOnDestroy(): void {}

  getAppBaseUrl(project: Project, uniqueName: string): string {
    if (project.domain && !project.domain.custom) {
      const webBaseUrl = getActualDomain(uniqueName, false);
      return `${webBaseUrl}/app/`;
    } else {
      let webBaseHost = '';

      try {
        webBaseHost = new URL(this.appConfigService.webBaseUrl).host;
      } catch (e) {}

      const webBaseUrl = project.domain ? project.domain.actualDomain : webBaseHost;
      return `${webBaseUrl}/app/`;
    }
  }

  editUniqueName() {
    this.projectUniqueNameController.edit().pipe(untilDestroyed(this)).subscribe();
  }

  editDomain() {
    this.projectDomainController
      .edit({ analyticsSource: 'layout_builder_domain' })
      .pipe(untilDestroyed(this))
      .subscribe();
  }

  changeTheme(theme: string): void {
    this.settingsForm.controls.default_theme.patchValue(theme);
    this.themeService.theme = theme;
  }
}
