<div
  class="sidebar-summary-block sidebar-summary-block_border"
  [class.sidebar-summary-block_clickable]="true"
  [class.sidebar-summary-block_disabled]="true"
  (click)="setLayoutOpened(true)"
>
  <div class="sidebar-summary-block__header">
    <div class="sidebar-summary-block__header-main">
      <div class="sidebar-summary-block__header-action sidebar-summary-block__header-action_global">
        <div
          class="sidebar-summary-block__header-action-icon sidebar-summary-block__header-action-icon_left icon-plus"
        ></div>
        <div class="sidebar-summary-block__header-action-label">Add menu</div>
      </div>
    </div>
  </div>

  <div class="sidebar-summary-block__content">
    <app-menu-block-layout-overlay
      [layoutGroups]="layoutGroups"
      [opened]="layoutOpened"
      [origin]="layout_trigger"
      (selectLayoutOption)="add.emit($event)"
      (close)="setLayoutOpened(false)"
    ></app-menu-block-layout-overlay>

    <div class="sidebar-summary-block__row">
      <div class="sidebar-summary-block__row-left">
        <div
          class="sidebar-summary-block__layout"
          [class.sidebar-summary-block__layout_disabled]="true"
          [style.background-image]="'/assets/images/project-layouts/layout-double-menu-left.svg' | appDeployCssUrl"
        ></div>
      </div>

      <div class="sidebar-summary-block__row-main">
        <div
          class="sidebar-summary-block-item"
          [class.sidebar-summary-block-item_disabled]="true"
          cdkOverlayOrigin
          #layout_trigger="cdkOverlayOrigin"
        >
          <div class="sidebar-summary-block-item__main">
            <div class="sidebar-summary-block-item__title sidebar-summary-block-item__element">
              Size & Position
            </div>
          </div>

          <div class="sidebar-summary-block-item__right">
            <div class="sidebar-summary-block-item__action icon-gear"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="sidebar-summary-block-item" [class.sidebar-summary-block-item_disabled]="true">
      <div class="sidebar-summary-block-item__left">
        <div class="sidebar-summary-block-item__icon icon-fileds"></div>
      </div>

      <div class="sidebar-summary-block-item__main">
        <div class="sidebar-summary-block-item__title sidebar-summary-block-item__element">
          Menu items
        </div>
        <div class="sidebar-summary-block-item__additional sidebar-summary-block-item__element">
          0 items
        </div>
      </div>

      <div class="sidebar-summary-block-item__right">
        <div class="sidebar-summary-block-item__arrow icon-arrow_forward_2"></div>
      </div>
    </div>

    <div class="sidebar-summary-block-item" [class.sidebar-summary-block-item_disabled]="true">
      <div class="sidebar-summary-block-item__left">
        <div
          class="sidebar-summary-block-item__color"
          [class.sidebar-summary-block-item__color_transparent]="true"
        ></div>
      </div>

      <div class="sidebar-summary-block-item__main">
        <div class="sidebar-summary-block-item__title">Color</div>
      </div>

      <div class="sidebar-summary-block-item__right">
        <div class="sidebar-summary-block-item__action icon-gear"></div>
      </div>
    </div>
  </div>
</div>
