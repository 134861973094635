<div *ngFor="let item of items" class="kanban-board-column__item">
  <div class="kanban-board-item" [class.kanban-board-item_theme]="theme">
    <div class="model-card" [class.model-card_theme]="theme" [class.model-card_vertical]="true">
      <div>
        <div *ngFor="let item of [1, 2]" class="model-card__item">
          <label class="model-card__item-label">
            <span [class.loading-animation]="animating"><span class="stub-text">Field</span></span>
          </label>
          <div class="model-card__item-value">
            <span [class.loading-animation]="animating"><span class="stub-text">Value</span></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
