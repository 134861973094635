/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../common/drag-drop2/directives/drag/drag.token";
import * as i2 from "../../../../common/drag-drop2/directives/drag/drag.directive";
import * as i3 from "../menu-block-edit/menu-block-edit.component.ngfactory";
import * as i4 from "../menu-block-edit/menu-block-edit.component";
import * as i5 from "../../../users/stores/current-user.store";
import * as i6 from "../../../projects/stores/current-project.store";
import * as i7 from "../../../menu-components/data/menu-context";
import * as i8 from "../../../theme/services/theme/theme.service";
import * as i9 from "../../../../common/dialogs/services/dialog/dialog.service";
import * as i10 from "@angular/platform-browser";
import * as i11 from "../menu-block-add/menu-block-add.component.ngfactory";
import * as i12 from "../menu-block-add/menu-block-add.component";
import * as i13 from "../../../sidebar/components/sidebar-header/sidebar-header.component.ngfactory";
import * as i14 from "../../../sidebar/components/sidebar-header/sidebar-header.component";
import * as i15 from "../../../sidebar/components/sidebar-section/sidebar-section.component.ngfactory";
import * as i16 from "../../../sidebar/components/sidebar-section/sidebar-section.component";
import * as i17 from "../../../../common/drag-drop2/directives/drop-list/drop-list.directive";
import * as i18 from "../../../../common/drag-drop2/directives/drop-list-group/drop-list-group.directive";
import * as i19 from "../../../../common/drag-drop2/services/drop-list/drop-list.service";
import * as i20 from "../../../../common/drag-drop2/services/scroll/scroll.service";
import * as i21 from "@angular/common";
import * as i22 from "../../../sidebar/components/sidebar-submit/sidebar-submit.component.ngfactory";
import * as i23 from "../../../sidebar/components/sidebar-submit/sidebar-submit.component";
import * as i24 from "../../../customize/services/customize/customize.service";
import * as i25 from "./customize-bar-menu-edit.component";
import * as i26 from "../../../routing/services/routing/routing.service";
import * as i27 from "../../../analytics/services/universal-analytics/universal-analytics.service";
var styles_CustomizeBarMenuEditComponent = [];
var RenderType_CustomizeBarMenuEditComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CustomizeBarMenuEditComponent, data: {} });
export { RenderType_CustomizeBarMenuEditComponent as RenderType_CustomizeBarMenuEditComponent };
function View_CustomizeBarMenuEditComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 6, "div", [["appDrag", ""], ["class", "sidebar__element"]], null, [[null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = (_co.blockControlHover$.next(_v.context.$implicit.control) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (_co.blockControlHover$.next(undefined) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵprd(6144, null, i1.APP_DRAG, null, [i2.AppDrag]), i0.ɵdid(2, 212992, [[1, 4]], 2, i2.AppDrag, [i0.ElementRef, i0.ViewContainerRef], { data: [0, "data"] }, null), i0.ɵqud(335544320, 2, { placeholderTemplate: 0 }), i0.ɵqud(335544320, 3, { previewTemplate: 0 }), (_l()(), i0.ɵeld(5, 0, null, null, 1, "app-menu-block-edit", [], null, [[null, "remove"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("remove" === en)) {
        var pd_0 = (_co.context.controls.menu.controls.blocks.removeControl(_v.context.$implicit.control) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MenuBlockEditComponent_0, i3.RenderType_MenuBlockEditComponent)), i0.ɵdid(6, 245760, null, 0, i4.MenuBlockEditComponent, [i5.CurrentUserStore, i6.CurrentProjectStore, i7.MenuContext, i8.ThemeService, i9.DialogService, i10.DomSanitizer, i0.NgZone, i0.ChangeDetectorRef], { title: [0, "title"], control: [1, "control"], layoutGroups: [2, "layoutGroups"], active: [3, "active"], disabled: [4, "disabled"] }, { remove: "remove" }), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.title; var currVal_2 = _v.context.$implicit.control; var currVal_3 = _co.layoutGroups; var currVal_4 = (_co.blockControlPreviewHover$.value === _v.context.$implicit.control); var currVal_5 = (_co.blockControlPreviewHover$.value && (_co.blockControlPreviewHover$.value !== _v.context.$implicit.control)); _ck(_v, 6, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
function View_CustomizeBarMenuEditComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "sidebar__element"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-menu-block-add", [], null, [[null, "add"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("add" === en)) {
        var pd_0 = (_co.addMenuBlock($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i11.View_MenuBlockAddComponent_0, i11.RenderType_MenuBlockAddComponent)), i0.ɵdid(2, 245760, null, 0, i12.MenuBlockAddComponent, [i0.ChangeDetectorRef], { layoutGroups: [0, "layoutGroups"] }, { add: "add" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.layoutGroups; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_CustomizeBarMenuEditComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "app-sidebar-header", [], null, [[null, "back"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("back" === en)) {
        var pd_0 = (_co.back() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i13.View_SidebarHeaderComponent_0, i13.RenderType_SidebarHeaderComponent)), i0.ɵdid(1, 114688, null, 0, i14.SidebarHeaderComponent, [], { title: [0, "title"] }, { back: "back" }), (_l()(), i0.ɵeld(2, 0, null, 0, 5, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 4, "div", [["class", "button-group"]], [[4, "margin", null]], null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "div", [["class", "button-group__item"]], [[2, "button-group__item_active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.themeService.theme = "default") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 0, "span", [["class", "button-group__item-icon icon-sun"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "div", [["class", "button-group__item"]], [[2, "button-group__item_active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.themeService.theme = "dark") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 0, "span", [["class", "button-group__item-icon icon-moon"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 9, "div", [["class", "sidebar__content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 8, "app-sidebar-section", [], null, null, null, i15.View_SidebarSectionComponent_0, i15.RenderType_SidebarSectionComponent)), i0.ɵdid(10, 114688, null, 0, i16.SidebarSectionComponent, [], null, null), (_l()(), i0.ɵeld(11, 0, null, 3, 4, "div", [["appDropList", ""]], null, [[null, "appDropListDropped"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("appDropListDropped" === en)) {
        var pd_0 = (_co.dragDrop($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(12, 4931584, null, 1, i17.AppDropList, [i0.ElementRef, [2, i18.AppDropListGroup], i0.ChangeDetectorRef, i19.DropListService, i20.ScrollService], { lockAxis: [0, "lockAxis"], data: [1, "data"] }, { dropped: "appDropListDropped" }), i0.ɵqud(603979776, 1, { draggables: 1 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomizeBarMenuEditComponent_1)), i0.ɵdid(15, 278528, null, 0, i21.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null), (_l()(), i0.ɵand(16777216, null, 3, 1, null, View_CustomizeBarMenuEditComponent_2)), i0.ɵdid(17, 16384, null, 0, i21.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(18, 0, null, null, 4, "app-sidebar-submit", [], null, [[null, "cancelClick"], [null, "submitClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("cancelClick" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } if (("submitClick" === en)) {
        var pd_1 = (_co.submit() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i22.View_SidebarSubmitComponent_0, i22.RenderType_SidebarSubmitComponent)), i0.ɵdid(19, 770048, null, 0, i23.SidebarSubmitComponent, [i24.CustomizeService], { saveDisabled: [0, "saveDisabled"], saveLoading: [1, "saveLoading"], saveLabel: [2, "saveLabel"], cancelDisabled: [3, "cancelDisabled"], cancelLabel: [4, "cancelLabel"], hidden: [5, "hidden"] }, { cancelClick: "cancelClick", submitClick: "submitClick" }), i0.ɵpid(131072, i21.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i21.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i21.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Menu customization"; _ck(_v, 1, 0, currVal_0); _ck(_v, 10, 0); var currVal_4 = _co.dragAxis.Y; var currVal_5 = _co.context.controls.menu.controls.blocks.controls; _ck(_v, 12, 0, currVal_4, currVal_5); var currVal_6 = _co.blockItems; var currVal_7 = _co.trackMenuBlockItemFn; _ck(_v, 15, 0, currVal_6, currVal_7); var currVal_8 = (_co.blockItems.length < _co.context.controls.menu.controls.blocks.controlsFixedLength); _ck(_v, 17, 0, currVal_8); var currVal_9 = !i0.ɵunv(_v, 19, 0, i0.ɵnov(_v, 20).transform(_co.hasChanges$)); var currVal_10 = _co.submitLoading; var currVal_11 = "Save changes"; var currVal_12 = !i0.ɵunv(_v, 19, 3, i0.ɵnov(_v, 21).transform(_co.hasChanges$)); var currVal_13 = "Reset changes"; var currVal_14 = !i0.ɵunv(_v, 19, 5, i0.ɵnov(_v, 22).transform(_co.hasChanges$)); _ck(_v, 19, 0, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = "-5px 0"; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.themeService.isDefaultTheme; _ck(_v, 4, 0, currVal_2); var currVal_3 = _co.themeService.isDarkTheme; _ck(_v, 6, 0, currVal_3); }); }
export function View_CustomizeBarMenuEditComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-customize-bar-menu-edit", [], null, null, null, View_CustomizeBarMenuEditComponent_0, RenderType_CustomizeBarMenuEditComponent)), i0.ɵdid(1, 245760, null, 0, i25.CustomizeBarMenuEditComponent, [i6.CurrentProjectStore, i24.CustomizeService, i8.ThemeService, i26.RoutingService, i27.UniversalAnalyticsService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomizeBarMenuEditComponentNgFactory = i0.ɵccf("app-customize-bar-menu-edit", i25.CustomizeBarMenuEditComponent, View_CustomizeBarMenuEditComponent_Host_0, { context: "context", blockControlPreviewHover$: "blockControlPreviewHover$" }, { blockControlHover: "blockControlHover" }, []);
export { CustomizeBarMenuEditComponentNgFactory as CustomizeBarMenuEditComponentNgFactory };
