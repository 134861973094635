var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, ElementRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { colorHexToStr, colors, defaultAccentColor, getColorVariable } from '@modules/colors';
import { globalColorOptions } from '@modules/colors-components';
import { FieldType, registerFieldComponent } from '@modules/fields';
import { ThemeService } from '@modules/theme';
import { isSet } from '@shared';
import { FieldComponent } from '../field/field.component';
var ColorFieldComponent = /** @class */ (function (_super) {
    __extends(ColorFieldComponent, _super);
    function ColorFieldComponent(el, themeService, sanitizer, cd) {
        var _this = _super.call(this) || this;
        _this.el = el;
        _this.themeService = themeService;
        _this.sanitizer = sanitizer;
        _this.cd = cd;
        _this.editPopoverOpened = false;
        _this.defaultAccentColor = defaultAccentColor;
        return _this;
    }
    Object.defineProperty(ColorFieldComponent.prototype, "allowEmpty", {
        get: function () {
            return this.field && this.field.params ? !!this.field.params['allow_empty'] : false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ColorFieldComponent.prototype, "emptyColor", {
        get: function () {
            return this.field && this.field.params ? this.field.params['empty_color'] : undefined;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ColorFieldComponent.prototype, "emptyAccentColor", {
        get: function () {
            return this.field && this.field.params ? !!this.field.params['empty_accent_color'] : false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ColorFieldComponent.prototype, "hasEmptyColor", {
        get: function () {
            return isSet(this.emptyColor) || isSet(this.emptyAccentColor);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ColorFieldComponent.prototype, "currentValueDisplay", {
        get: function () {
            var value = this.currentValue;
            if (!isSet(value) && this.emptyAccentColor) {
                var globalProperty = this.themeService && this.themeService.isDarkTheme ? '--accent-color-dark' : '--accent-color';
                var globalColor = getComputedStyle(this.el.nativeElement).getPropertyValue(globalProperty);
                return {
                    label: 'Accent color',
                    color: globalColor || defaultAccentColor
                };
            }
            else if (!isSet(value) && isSet(this.emptyColor)) {
                return {
                    label: this.emptyColor,
                    color: this.emptyColor
                };
            }
            else if (!isSet(value)) {
                return;
            }
            var variable = getColorVariable(value);
            if (variable) {
                var option = globalColorOptions.find(function (item) { return item.value == variable; });
                return {
                    label: option ? option.name : value,
                    color: "var(--" + variable + ")"
                };
            }
            if (value.startsWith('#')) {
                return {
                    label: value,
                    color: value
                };
            }
            var predefinedColor = colors.find(function (item) { return item.name == value; });
            if (predefinedColor) {
                return {
                    label: predefinedColor.label,
                    color: colorHexToStr(predefinedColor.hex)
                };
            }
        },
        enumerable: true,
        configurable: true
    });
    ColorFieldComponent.prototype.openEditPopover = function (value) {
        if (this.control && this.control.disabled) {
            return;
        }
        this.editPopoverOpened = value;
        this.cd.markForCheck();
    };
    ColorFieldComponent.prototype.setColor = function (color) {
        this.control.patchValue(color);
    };
    return ColorFieldComponent;
}(FieldComponent));
export { ColorFieldComponent };
registerFieldComponent(FieldType.Color, ColorFieldComponent);
