var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import fromPairs from 'lodash/fromPairs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, from, of, throwError } from 'rxjs';
import { catchError, delayWhen, map, switchMap, tap } from 'rxjs/operators';
import { slugify } from 'transliteration';
import { AppFormGroup, FormUtils } from '@common/form-utils';
import { ActionStore } from '@modules/action-queries';
import { ServerRequestError } from '@modules/api';
import { modelFieldItemToRawListViewSettingsColumn, rawListViewSettingsColumnToModelField } from '@modules/customize';
import { FieldType, ParameterField } from '@modules/fields';
import { ModelDescriptionService, ModelDescriptionStore } from '@modules/model-queries';
import { ModelDbField, modelDbFieldToParameterField, ModelDescription, ModelField, ModelFieldType } from '@modules/models';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { QueryService } from '@modules/queries';
import { getJetDatabaseType, ResourceControllerService } from '@modules/resources';
import { controlValue, getFilename, isSet } from '@shared';
import { TextEncoding } from '../../data/text-encoding';
import { ImportService } from '../../services/import/import.service';
import { JET_IMPORT_PK } from '../import-data/import-data.component';
import { ImportModelsFieldArray } from './import-models-field.array';
export function validateVerboseName() {
    return function (control) {
        var parent = control.parent;
        if (!parent || !parent.resource) {
            return;
        }
        if (!control.value) {
            if (parent.modelDescription) {
                return;
            }
            else {
                return {
                    required: true
                };
            }
        }
        if (parent.modelDescriptionStore.instance
            .filter(function (item) { return item.resource == parent.resource.uniqueName; })
            .filter(function (item) { return !item.deleted; })
            .find(function (item) {
            if (item.verboseNamePlural && item.verboseNamePlural.toLowerCase() == control.value.toLowerCase()) {
                return true;
            }
            else if (item.verboseName && item.verboseName.toLowerCase() == control.value.toLowerCase()) {
                return true;
            }
            else {
                return false;
            }
        })) {
            return {
                local: ['Collection with such Name already exists']
            };
        }
    };
}
export function validateFields() {
    return function (control) {
        if (!control.controls.file.value || !control.controls.fields.controls.length) {
            return;
        }
        if (!control.controls.fields.controls.filter(function (item) { return item.controls.active.value; }).length) {
            return {
                local: ['Select at least one field to import']
            };
        }
        if (control.modelDescription) {
            var controller = control.resourceControllerService.get(control.resource.type);
            var modelCreateParameters = controller.getCreateParametersOrDefaults(control.resource, control.modelDescription);
            var missingParameters = modelCreateParameters
                .filter(function (field) { return field.required; })
                .filter(function (field) {
                var existingTargetField = control.controls.fields.controls.find(function (item) {
                    return item.controls.field.controls.name.value == field.name &&
                        item.controls.active.value &&
                        isSet(item.controls.source.value);
                });
                return existingTargetField === undefined;
            });
            if (missingParameters.length) {
                var fields = missingParameters.map(function (item) { return item.verboseName || item.name; }).join(', ');
                return {
                    local_dirty: ["The following required collection fields are not specified: " + fields]
                };
            }
        }
        var primaryKey = control.controls.custom_primary_key.value;
        if (isSet(primaryKey) &&
            control.controls.fields.controls.find(function (item) {
                return item.controls.field.controls.name.value == primaryKey &&
                    item.controls.field.controls.field.value !== FieldType.Number;
            })) {
            return {
                local: ['Specified primary key type is not number']
            };
        }
    };
}
var ImportModelsForm = /** @class */ (function (_super) {
    __extends(ImportModelsForm, _super);
    function ImportModelsForm(modelDescriptionService, modelDescriptionStore, currentProjectStore, currentEnvironmentStore, queryService, formUtils, actionStore, importService, resourceControllerService, fieldArray) {
        var _this = _super.call(this, {
            verbose_name: new FormControl(null, validateVerboseName()),
            file: new FormControl(null, Validators.required),
            file_encoding: new FormControl(TextEncoding.UTF8, Validators.required),
            fields: fieldArray,
            custom_primary_key: new FormControl(null)
        }, validateFields()) || this;
        _this.modelDescriptionService = modelDescriptionService;
        _this.modelDescriptionStore = modelDescriptionStore;
        _this.currentProjectStore = currentProjectStore;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        _this.queryService = queryService;
        _this.formUtils = formUtils;
        _this.actionStore = actionStore;
        _this.importService = importService;
        _this.resourceControllerService = resourceControllerService;
        _this.encodingOptions = [
            {
                value: TextEncoding.UTF8,
                name: 'Unicode (UTF-8)'
            },
            {
                value: TextEncoding.UTF16LE,
                name: 'Unicode (UTF-16LE)'
            },
            {
                value: TextEncoding.Windows1250CentralEuropean,
                name: 'Central European (Windows 1250)'
            },
            {
                value: TextEncoding.Windows1251Cyrillic,
                name: 'Cyrillic (Windows 1251)'
            },
            {
                value: TextEncoding.Windows1252Latin1,
                name: 'Latin 1 (Windows 1252)'
            },
            {
                value: TextEncoding.Windows1253Greek,
                name: 'Greek (Windows 1253)'
            },
            {
                value: TextEncoding.Windows1254Turkish,
                name: 'Turkish (Windows 1254)'
            },
            {
                value: TextEncoding.Windows1255Hebrew,
                name: 'Hebrew (Windows 1255)'
            },
            {
                value: TextEncoding.Windows1256Arabic,
                name: 'Arabic (Windows 1256)'
            },
            {
                value: TextEncoding.Windows1257Baltic,
                name: 'Baltic (Windows 1257)'
            },
            {
                value: TextEncoding.Windows1258Vietnamese,
                name: 'Vietnamese (Windows 1258)'
            },
            {
                value: TextEncoding.Windows874Thai,
                name: 'Thai (Windows 874)'
            },
            {
                value: TextEncoding.SimplifiedChineseGBK,
                name: 'Simplified Chinese GBK'
            },
            {
                value: TextEncoding.TraditionalChineseBig5,
                name: 'Traditional Chinese Big5'
            },
            {
                value: TextEncoding.JapaneseShiftJIS,
                name: 'Japanese Shift-JIS'
            }
        ];
        _this.getFileContent$()
            .pipe(untilDestroyed(_this))
            .subscribe(function (result) {
            if (result && !_this.modelDescription) {
                _this.controls.verbose_name.setValue(getFilename(result.file.name));
            }
            var cleanName = function (value) {
                if (typeof value === 'string') {
                    return value.toLowerCase().trim().replace(/[^\w]/g, '_');
                }
                else {
                    return value;
                }
            };
            var fields = result && result.content ? result.content.fields : [];
            if (_this.modelDescription) {
                var controller = _this.resourceControllerService.get(_this.resource.type);
                var createParameters = controller.getCreateParametersOrDefaults(_this.resource, _this.modelDescription);
                if (!createParameters.find(function (item) { return item.name == _this.modelDescription.primaryKeyField; }) &&
                    _this.modelDescription.primaryKey) {
                    var pkParameter = modelDbFieldToParameterField(_this.modelDescription.primaryKey);
                    pkParameter.name = _this.modelDescription.primaryKeyField;
                    pkParameter.required = false;
                    createParameters.splice(0, 0, pkParameter);
                }
                var controls = createParameters.map(function (parameter) {
                    var control = _this.controls.fields.createControl();
                    control.controls.field.deserialize(parameter);
                    var parameterCleanName = cleanName(parameter.name);
                    var sourceParameter = parameter.name !== _this.modelDescription.primaryKeyField
                        ? fields.find(function (item) { return cleanName(item.name) == parameterCleanName; })
                        : undefined;
                    if (sourceParameter) {
                        control.controls.source.setValue(sourceParameter.name);
                    }
                    control.markAsPristine();
                    return control;
                });
                _this.controls.fields.setControls(controls);
            }
            else {
                var controls = [
                    { name: JET_IMPORT_PK, field: FieldType.Number, verboseName: 'id', params: {} }
                ].concat(fields).map(function (field) {
                    var parameter = new ParameterField();
                    parameter.field = field.field;
                    parameter.name = field.name;
                    parameter.verboseName = field.verboseName;
                    parameter.params = field.params;
                    parameter.updateFieldDescription();
                    var control = _this.controls.fields.createControl();
                    control.controls.field.deserialize(parameter);
                    if (field.name != JET_IMPORT_PK) {
                        control.controls.source.setValue(field.name);
                        if (field.name.toLowerCase() == 'id') {
                            control.controls.active.patchValue(false);
                        }
                    }
                    control.markAsPristine();
                    return control;
                });
                _this.controls.fields.setControls(controls);
            }
        });
        return _this;
    }
    ImportModelsForm.prototype.ngOnDestroy = function () { };
    ImportModelsForm.prototype.init = function (resource, modelDescription) {
        this.resource = resource;
        this.modelDescription = modelDescription;
        this.controls.fields.init(this.resource, this.modelDescription);
    };
    ImportModelsForm.prototype.getFileContent$ = function () {
        var _this = this;
        return combineLatest(controlValue(this.controls.file), controlValue(this.controls.file_encoding)).pipe(switchMap(function (_a) {
            var file = _a[0], encoding = _a[1];
            if (!file) {
                return of(undefined);
            }
            return _this.importService.parseFileData(file, { encoding: encoding }).pipe(map(function (results) {
                if (!results) {
                    return { file: file, error: 'Failed to parse file' };
                }
                else if (!results.length) {
                    return { file: file, error: 'File has no data' };
                }
                var fields = _this.queryService.autoDetectGetFields(results);
                if (!fields) {
                    return { file: file, error: 'Failed to detect fields' };
                }
                else if (!fields.length) {
                    return { file: file, error: 'Data has no fields' };
                }
                return {
                    file: file,
                    content: {
                        fields: fields,
                        fieldsData: fromPairs(fields.map(function (field) {
                            var itemWithFieldData = results.find(function (item) { return isSet(item[field.name]); });
                            var fieldData = itemWithFieldData ? itemWithFieldData[field.name] : undefined;
                            return [field.name, fieldData];
                        })),
                        data: results
                    }
                };
            }), catchError(function (error) {
                return of({ file: file, error: error });
            }));
        }));
    };
    ImportModelsForm.prototype.createModelDescription = function (controller, parameters, primaryKeyField) {
        var defaultPrimaryKey = {
            name: 'id',
            field: FieldType.Number,
            params: {}
        };
        var instance = new ModelDescription();
        instance.resource = this.resource.uniqueName;
        instance.model = isSet(this.controls.verbose_name.value)
            ? slugify(this.controls.verbose_name.value, { trim: true, separator: '_' }).replace(/_+/g, '_')
            : undefined;
        instance.dbTable = instance.model;
        instance.verboseName = this.controls.verbose_name.value;
        instance.verboseNamePlural = this.controls.verbose_name.value;
        instance.primaryKeyField = isSet(primaryKeyField) ? primaryKeyField : defaultPrimaryKey.name;
        instance.virtual = false;
        if (isSet(primaryKeyField)) {
            parameters = parameters.sort(function (lhs, rhs) {
                if (lhs.name == primaryKeyField) {
                    return -1;
                }
                else if (rhs.name == primaryKeyField) {
                    return 1;
                }
                else {
                    return parameters.indexOf(lhs) - parameters.indexOf(rhs);
                }
            });
        }
        var instanceFields = (!primaryKeyField ? [rawListViewSettingsColumnToModelField(defaultPrimaryKey)] : []).concat(parameters.map(function (item) {
            var dbField = new ModelDbField();
            var jetDatabaseType = getJetDatabaseType(item);
            dbField.name = item.name;
            dbField.verboseName = item.verboseName;
            dbField.field = item.field;
            dbField.params = item.params;
            dbField.updateFieldDescription();
            if (primaryKeyField != item.name) {
                dbField.dbField = jetDatabaseType ? jetDatabaseType.dbColumn : 'CharField';
            }
            var field = new ModelField();
            field.name = item.name;
            field.type = ModelFieldType.Db;
            field.item = dbField;
            return field;
        })).map(function (field) {
            if (field.item instanceof ModelDbField) {
                field.item.editable = true;
                field.item.null = true;
            }
            return field;
        });
        controller.setUpModelDescriptionBasedOnGetQuery(this.resource, instance, instance.getQuery, instanceFields);
        return instance;
    };
    ImportModelsForm.prototype.importModelDescription = function () {
        var _this = this;
        this.clearServerErrors();
        var file = this.controls.file.value;
        var encoding = this.controls.file_encoding.value;
        return from(this.importService.parseFileData(file, { encoding: encoding })).pipe(switchMap(function (results) {
            var fields = _this.controls.fields.controls
                .filter(function (control) { return control.controls.active.value; })
                .filter(function (control) { return control.controls.field.controls.name.value !== JET_IMPORT_PK; })
                .map(function (control) { return control.controls.field.serialize(); });
            var primaryKeyField = _this.controls.custom_primary_key.value;
            var controller = _this.resourceControllerService.get(_this.resource.type);
            var instance = _this.createModelDescription(controller, fields, primaryKeyField);
            return controller.modelDescriptionCreate(_this.resource, instance).pipe(switchMap(function () { return _this.modelDescriptionService.getFromResource(_this.resource); }), map(function (resourceModelDescriptions) { return resourceModelDescriptions.find(function (item) { return item.model == instance.model; }); }), tap(function (modelDescription) {
                if (!modelDescription) {
                    throw new ServerRequestError('Failed to get created Collection');
                }
            }), switchMap(function (modelDescription) {
                var overrideModelDescription = cloneDeep(modelDescription);
                if (overrideModelDescription) {
                    overrideModelDescription.verboseName = instance.verboseName;
                    overrideModelDescription.verboseNamePlural = instance.verboseNamePlural;
                    overrideModelDescription.fields = overrideModelDescription.fields.map(function (item) {
                        var overrideModelField = instance.fields.find(function (i) { return i.name == item.name; });
                        var overrideField = overrideModelField
                            ? modelFieldItemToRawListViewSettingsColumn(overrideModelField.item)
                            : undefined;
                        if (item.type == ModelFieldType.Db && overrideField) {
                            var newField = cloneDeep(overrideModelField);
                            if (newField.item instanceof ModelDbField) {
                                newField.item.applyOverrides(overrideField);
                            }
                            return newField;
                        }
                        else {
                            return item;
                        }
                    });
                }
                return _this.modelDescriptionService.update(_this.currentProjectStore.instance.uniqueName, _this.currentEnvironmentStore.instance.uniqueName, overrideModelDescription);
            }), switchMap(function () { return _this.modelDescriptionStore.getDetailFirst(instance.modelId, true); }), delayWhen(function () { return _this.actionStore.getFirst(true); }), switchMap(function (modelDescription) {
                var mappings = _this.controls.fields.controls
                    .filter(function (control) { return control.controls.active.value; })
                    .map(function (control) {
                    var field = control.controls.field.serialize();
                    return {
                        field: field,
                        source: control.controls.source.value
                    };
                });
                return _this.importService.importRecords(_this.resource, modelDescription, results, mappings, {
                    ignorePostCreate: true
                });
            }));
        }), catchError(function (error) {
            console.error(error);
            _this.formUtils.showFormErrors(_this, error);
            return throwError(error);
        }));
    };
    ImportModelsForm.prototype.importRecords = function () {
        var _this = this;
        this.clearServerErrors();
        var file = this.controls.file.value;
        var encoding = this.controls.file_encoding.value;
        return from(this.importService.parseFileData(file, { encoding: encoding })).pipe(switchMap(function (results) {
            var mappings = _this.controls.fields.controls
                .filter(function (control) { return control.controls.active.value; })
                .map(function (control) {
                return {
                    field: control.controls.field.serialize(),
                    source: control.controls.source.value
                };
            });
            return _this.importService.importRecords(_this.resource, _this.modelDescription, results, mappings);
        }), catchError(function (error) {
            console.error(error);
            _this.formUtils.showFormErrors(_this, error);
            return throwError(error);
        }));
    };
    ImportModelsForm.prototype.submit = function () {
        if (this.modelDescription) {
            return this.importRecords();
        }
        else {
            return this.importModelDescription();
        }
    };
    return ImportModelsForm;
}(AppFormGroup));
export { ImportModelsForm };
