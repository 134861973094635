import { HttpMethod } from '@modules/queries';

export enum XanoFieldType {
  Text = 'text',
  Email = 'email',
  Password = 'password',
  Int = 'int',
  Decimal = 'decimal',
  Bool = 'bool',
  Enum = 'enum',
  Timestamp = 'timestamp',
  Date = 'date',
  Attachment = 'attachment',
  Image = 'image',
  Audio = 'audio',
  Video = 'video',
  Object = 'object',
  JSON = 'json',
  GeoPoint = 'geo_point',
  GeoMultipoint = 'geo_multipoint',
  GeoPolygon = 'geo_polygon',
  GeoMultipolygon = 'geo_multipolygon',
  GeoLinestring = 'geo_linestring',
  GeoMultilinestring = 'geo_multilinestring'
}

export interface IXanoInstance {
  id: number;
  name: string;
  display: string;
  xano_domain: string;
  meta_api: string;
  meta_swagger: string;
}

export interface IXanoWorkspace {
  id: number;
  name: string;
  description: string;
}

export interface IXanoDataSource {
  color: string;
  label: string;
}

export interface IXanoBranch {
  created_at: string;
  label: string;
  live: boolean;
  backup: boolean;
}

export interface IXanoTable {
  id: number;
  created_at: string;
  updated_at: string;
  name: string;
  description: string;
  guid: string;
  auth: boolean;
}

export interface IXanoTableResponse {
  items: IXanoTable[];
  curPage: number;
  nextPage: number | null;
  prevPage: number | null;
}

export interface IXanoField<T = unknown> {
  name: string;
  type: XanoFieldType;
  description: string;
  nullable: boolean;
  default: T | 'now';
  required: boolean;
  access: 'public' | 'private';
  style: 'single' | 'list';
  tableref_id?: number;
  values?: T[];
  children?: IXanoField[];
  validators?: {
    trim?: boolean;
  };
}

export interface IXanoTableSchema {
  table: IXanoTable;
  fields: IXanoField[];
}

export interface IXanoGetPagingResponse {
  itemsReceived: number;
  curPage: number;
  nextPage: number | null;
  prevPage: number | null;
  offset: number;
  itemsTotal: number;
  pageTotal: number;
  items: Record<string, unknown>[];
}

export interface IXanoFile {
  path: string;
  name: string;
  type: string;
  size: number;
  mime: string;
}

export interface IXanoFileResponse extends IXanoFile {
  id: number;
  created_at: string;
  meta?: Object;
}

export interface IXanoFilePagingResponse {
  curPage: number;
  nextPage: number | null;
  prevPage: number | null;
  items: IXanoFileResponse[];
}

export interface XanoStorageParams {
  upload?: {
    method: HttpMethod;
    file_parameter: string;
  };
}
