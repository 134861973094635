var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnDestroy } from '@angular/core';
import isEqual from 'lodash/isEqual';
import { BehaviorSubject } from 'rxjs';
import { BasePopupComponent, PopupService } from '@common/popups';
var FillEditContext = /** @class */ (function () {
    function FillEditContext(popupService, popupComponent) {
        this.popupService = popupService;
        this.popupComponent = popupComponent;
        this.customizingGradient$ = new BehaviorSubject(undefined);
    }
    FillEditContext.prototype.ngOnDestroy = function () { };
    FillEditContext.prototype.startCustomizingGradient = function (value) {
        this.customizingGradient$.next(value);
    };
    FillEditContext.prototype.updateCustomizingGradient = function (value) {
        if (!this.customizingGradient$.value) {
            return;
        }
        var currentValue = this.customizingGradient$.value;
        var newValue = __assign({}, currentValue, value);
        if (isEqual(currentValue, newValue)) {
            return;
        }
        this.customizingGradient$.next(newValue);
    };
    FillEditContext.prototype.finishCustomizingGradient = function () {
        if (!this.customizingGradient$.value) {
            return;
        }
        this.customizingGradient$.next(undefined);
    };
    return FillEditContext;
}());
export { FillEditContext };
