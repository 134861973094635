var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HttpClient, HttpEventType } from '@angular/common/http';
import fromPairs from 'lodash/fromPairs';
import toPairs from 'lodash/toPairs';
import { concat, of } from 'rxjs';
import { delay, map, publishLast, refCount, switchMap, toArray } from 'rxjs/operators';
import { ApiService } from '@modules/api';
import { modelFieldToRawListViewSettingsColumn } from '@modules/customize';
import { FieldType, fileFieldTypes } from '@modules/fields';
import { ModelFieldType } from '@modules/models';
import { HTTP_QUERY_KEY_AUTH_NAME } from '@modules/projects-components/data/http-query-auth';
import { HttpContentType, HttpMethod, HttpQuery, HttpQueryService, QueryType } from '@modules/queries';
import { StorageObject, StorageObjectsResponse, StorageObjectType } from '@modules/storages';
import { AppError, coerceArray, getExtension, getExtensionMime, getFilenameWithExtension, isSet } from '@shared';
import { ResourceController } from '../../data/resource-controller';
import { applyFrontendFiltering, applyFrontendPagination, applyFrontendSorting } from '../../utils/filters';
import { XANO_COLLECTION_TABLE_ID_NAME, XANO_PRIMARY_KEY_FIELD_NAME } from './xano-constants';
import { XanoLegacyResourceController } from './xano-legacy.resource-controller';
import { IXanoBranch, IXanoDataSource, IXanoField, IXanoFile, IXanoFilePagingResponse, IXanoFileResponse, IXanoGetPagingResponse, IXanoInstance, IXanoTableResponse, IXanoTableSchema, IXanoWorkspace } from './xano-types';
var XanoResourceController = /** @class */ (function (_super) {
    __extends(XanoResourceController, _super);
    function XanoResourceController() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    XanoResourceController.prototype.init = function () {
        this.apiService = this.initService(ApiService);
        this.httpQueryService = this.initService(HttpQueryService);
        this.http = this.initService(HttpClient);
        this.legacyController = this.initService(XanoLegacyResourceController);
    };
    XanoResourceController.prototype.supportedQueryTypes = function (resource, queryClass) {
        return [QueryType.Simple];
    };
    XanoResourceController.prototype.getFileInfo = function (urlValue) {
        var fileExtension = getExtension(urlValue) || 'file';
        var fileMime = getExtensionMime(fileExtension);
        var fileMimeType = isSet(fileMime) ? fileMime.split('/')[0] : undefined;
        var fileType;
        if (fileExtension == 'svg') {
            fileType = 'file';
        }
        else if (['image', 'audio', 'video'].includes(fileMimeType)) {
            fileType = fileMimeType;
        }
        else {
            fileType = 'file';
        }
        return {
            fileExtension: fileExtension,
            fileMime: fileMime,
            fileType: fileType
        };
    };
    XanoResourceController.prototype.serializeFile = function (urlValue) {
        var url;
        try {
            url = new URL(urlValue);
        }
        catch (e) { }
        var fileName = getFilenameWithExtension(urlValue);
        var _a = this.getFileInfo(urlValue), fileMime = _a.fileMime, fileType = _a.fileType;
        var fileSize = 0;
        return {
            path: url ? url.pathname : urlValue,
            name: fileName,
            type: fileType,
            size: fileSize,
            mime: fileMime
        };
    };
    XanoResourceController.prototype.serializeModel = function (instance, modelDescription, fields) {
        var _this = this;
        var data = toPairs(instance.serialize(fields))
            .filter(function (_a) {
            var k = _a[0], v = _a[1];
            var field = modelDescription.dbFields.find(function (i) { return i.name == k; });
            return field && field.editable;
        })
            .map(function (_a) {
            var name = _a[0], value = _a[1];
            var field = modelDescription.dbFields.find(function (i) { return i.name == name; });
            if (fileFieldTypes.includes(field.field)) {
                if (field.params && field.params['multiple']) {
                    value = coerceArray(value).map(function (item) { return _this.serializeFile(item); });
                }
                else {
                    value = _this.serializeFile(value);
                }
            }
            return [name, value];
        });
        return fromPairs(data);
    };
    XanoResourceController.prototype.generateUrl = function (apiBaseUrl, endpoints) {
        if (Array.isArray(endpoints)) {
            return [apiBaseUrl].concat(endpoints).join('/');
        }
        else {
            return [apiBaseUrl, endpoints].join('/');
        }
    };
    XanoResourceController.prototype.getUrl = function (domain, path) {
        return "https://" + domain + "/api:meta/" + path;
    };
    XanoResourceController.prototype.getWorkspaceUrl = function (domain, workspaceId, path) {
        return this.getUrl(domain, "workspace/" + workspaceId + "/" + path);
    };
    XanoResourceController.prototype.getInstances = function (accessToken) {
        var url = 'https://app.xano.com/api:meta/instance';
        var headers = { Authorization: "Bearer " + accessToken };
        return this.http
            .get(url, { headers: headers })
            .pipe(this.apiService.catchApiError(), publishLast(), refCount());
    };
    XanoResourceController.prototype.getWorkspaces = function (accessToken, domain) {
        var url = this.getUrl(domain, 'workspace');
        var headers = { Authorization: "Bearer " + accessToken };
        return this.http
            .get(url, { headers: headers })
            .pipe(this.apiService.catchApiError(), publishLast(), refCount());
    };
    XanoResourceController.prototype.getWorkspace = function (accessToken, domain, workspaceId) {
        var url = this.getUrl(domain, "workspace/" + workspaceId);
        var headers = { Authorization: "Bearer " + accessToken };
        return this.http
            .get(url, { headers: headers })
            .pipe(this.apiService.catchApiError(), publishLast(), refCount());
    };
    XanoResourceController.prototype.getDataSources = function (accessToken, domain, workspaceId) {
        var url = this.getUrl(domain, "workspace/" + workspaceId + "/datasource");
        var headers = { Authorization: "Bearer " + accessToken };
        return this.http
            .get(url, { headers: headers })
            .pipe(this.apiService.catchApiError(), publishLast(), refCount());
    };
    XanoResourceController.prototype.getBranches = function (accessToken, domain, workspaceId) {
        var url = this.getUrl(domain, "workspace/" + workspaceId + "/branch");
        var headers = { Authorization: "Bearer " + accessToken };
        return this.http
            .get(url, { headers: headers })
            .pipe(this.apiService.catchApiError(), publishLast(), refCount());
    };
    XanoResourceController.prototype.getTableSchemas = function (accessToken, domain, workspaceId, dataSource, branch) {
        var _this = this;
        var tablesUrl = this.getWorkspaceUrl(domain, workspaceId, 'table');
        var headers = __assign({ Authorization: "Bearer " + accessToken }, (isSet(dataSource) && {
            'X-Data-Source': dataSource
        }), (isSet(branch) && {
            'X-Branch': branch
        }));
        var getNextTableResponse = function (page) {
            var params = { page: String(page) };
            return _this.http
                .get(tablesUrl, { headers: headers, params: params })
                .pipe(delay(2000), switchMap(function (tableResponse) {
                var obs$ = tableResponse.items.map(function (table) {
                    var schemaUrl = _this.getWorkspaceUrl(domain, workspaceId, "table/" + table.id + "/schema");
                    return _this.http
                        .get(schemaUrl, { headers: headers })
                        .pipe(map(function (fieldsResponse) {
                        return {
                            table: table,
                            fields: fieldsResponse
                        };
                    }), delay(2000));
                });
                return concat.apply(void 0, obs$).pipe(toArray(), switchMap(function (result) {
                    if (isSet(tableResponse.nextPage)) {
                        return getNextTableResponse(tableResponse.nextPage).pipe(map(function (nextResult) { return result.concat(nextResult); }));
                    }
                    else {
                        return of(result);
                    }
                }));
            }));
        };
        return getNextTableResponse(1).pipe(this.apiService.catchApiError(), publishLast(), refCount());
    };
    XanoResourceController.prototype.transformSingleItemResponse = function (data, fields) {
        fields.forEach(function (field) {
            if (fileFieldTypes.includes(field.item.field) && data[field.name]) {
                if (field.item.params && field.item.params['multiple']) {
                    data[field.name] = coerceArray(data[field.name]).map(function (item) { return item['url']; });
                }
                else {
                    data[field.name] = data[field.name]['url'];
                }
            }
            else if (field.item.field === FieldType.RelatedModel && data[field.name] === 0) {
                data[field.name] = null;
            }
        });
        return data;
    };
    XanoResourceController.prototype.transformArrayItemsResponse = function (data, fields) {
        for (var _i = 0, data_1 = data; _i < data_1.length; _i++) {
            var responseItem = data_1[_i];
            this.transformSingleItemResponse(responseItem, fields);
        }
        return data;
    };
    XanoResourceController.prototype.getTableIdFromCollectionParam = function (modelDescription) {
        var tableId = modelDescription.params[XANO_COLLECTION_TABLE_ID_NAME];
        if (tableId === undefined) {
            throw new AppError("Table ID was not found in collection: " + modelDescription.model);
        }
        return tableId;
    };
    XanoResourceController.prototype.getHeaders = function (dataSource, branch) {
        return [
            {
                name: 'Authorization',
                value: "Bearer {-" + HTTP_QUERY_KEY_AUTH_NAME + "-}"
            }
        ].concat((isSet(dataSource)
            ? [
                {
                    name: 'X-Data-Source',
                    value: dataSource
                }
            ]
            : []), (isSet(branch)
            ? [
                {
                    name: 'X-Branch',
                    value: branch
                }
            ]
            : []));
    };
    XanoResourceController.prototype.modelGet = function (resource, modelDescription, params, body) {
        var _this = this;
        var domain = resource.params['domain'];
        var workspaceId = resource.params['workspace_id'];
        var dataSource = resource.params['data_source'];
        var branch = resource.params['branch'];
        if (!isSet(domain) && !isSet(workspaceId)) {
            // Backward compatibility
            return this.legacyController.modelGet(resource, modelDescription, params, body);
        }
        var options = { resource: resource.uniqueName };
        var tableId = this.getTableIdFromCollectionParam(modelDescription);
        var url = this.getWorkspaceUrl(domain, workspaceId, "table/" + tableId + "/content");
        var queryParams = [{ name: 'per_page', value: '10000' }];
        var query = new HttpQuery();
        query.method = HttpMethod.GET;
        query.url = url;
        query.queryParams = queryParams;
        query.headers = this.getHeaders(dataSource, branch);
        return this.httpQueryService.requestBody(query, options).pipe(map(function (response) {
            var responseItems = _this.transformArrayItemsResponse(response.items, modelDescription.fields);
            var columns = modelDescription.fields
                .filter(function (item) { return item.type == ModelFieldType.Db; })
                .map(function (item) { return modelFieldToRawListViewSettingsColumn(item); });
            responseItems = applyFrontendFiltering(responseItems, params, columns);
            var responseData = {
                results: responseItems,
                count: response.itemsTotal
            };
            var result = _this.createGetResponse().deserialize(responseData, modelDescription.model, undefined);
            result.results.forEach(function (item) {
                item.deserializeAttributes(modelDescription.dbFields);
            });
            applyFrontendSorting(result, params);
            applyFrontendPagination(result, params, true);
            return result;
        }), map(function (response) {
            if (!response) {
                return;
            }
            response.results.forEach(function (item) {
                item.setUp(modelDescription);
            });
            return response;
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    XanoResourceController.prototype.modelGetDetail = function (resource, modelDescription, idField, id, params) {
        var _this = this;
        var _a;
        var domain = resource.params['domain'];
        var workspaceId = resource.params['workspace_id'];
        var dataSource = resource.params['data_source'];
        var branch = resource.params['branch'];
        if (!isSet(domain) && !isSet(workspaceId)) {
            // Backward compatibility
            return this.legacyController.modelGetDetail(resource, modelDescription, idField, id, params);
        }
        var options = { resource: resource.uniqueName };
        var tableId = this.getTableIdFromCollectionParam(modelDescription);
        var url = this.getWorkspaceUrl(domain, workspaceId, "table/" + tableId + "/content/search");
        var data = {
            search: (_a = {},
                _a[XANO_PRIMARY_KEY_FIELD_NAME] = id,
                _a)
        };
        var query = new HttpQuery();
        query.method = HttpMethod.POST;
        query.url = url;
        query.headers = this.getHeaders(dataSource, branch);
        query.body = data;
        return this.httpQueryService.requestBody(query, options).pipe(map(function (result) {
            if (!result.items.length) {
                return;
            }
            var item = _this.transformSingleItemResponse(result.items[0], modelDescription.fields);
            var model = _this.createModel().deserialize(undefined, item);
            model.setUp(modelDescription);
            model.deserializeAttributes(modelDescription.dbFields);
            return model;
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    XanoResourceController.prototype.modelCreate = function (resource, modelDescription, modelInstance, fields) {
        var _this = this;
        var domain = resource.params['domain'];
        var workspaceId = resource.params['workspace_id'];
        var dataSource = resource.params['data_source'];
        var branch = resource.params['branch'];
        if (!isSet(domain) && !isSet(workspaceId)) {
            // Backward compatibility
            return this.legacyController.modelCreate(resource, modelDescription, modelInstance, fields);
        }
        var options = { resource: resource.uniqueName };
        var tableId = this.getTableIdFromCollectionParam(modelDescription);
        var url = this.getWorkspaceUrl(domain, workspaceId, "table/" + tableId + "/content");
        var query = new HttpQuery();
        query.method = HttpMethod.POST;
        query.url = url;
        query.headers = this.getHeaders(dataSource, branch);
        query.body = this.serializeModel(modelInstance, modelDescription, fields);
        return this.httpQueryService.requestBody(query, options).pipe(map(function (result) {
            if (!result) {
                return;
            }
            result = _this.transformSingleItemResponse(result, modelDescription.fields);
            var model = _this.createModel().deserialize(undefined, result);
            model.setUp(modelDescription);
            model.deserializeAttributes(modelDescription.dbFields);
            return model;
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    XanoResourceController.prototype.modelUpdate = function (resource, modelDescription, modelInstance, fields) {
        var _this = this;
        var domain = resource.params['domain'];
        var workspaceId = resource.params['workspace_id'];
        var dataSource = resource.params['data_source'];
        var branch = resource.params['branch'];
        if (!isSet(domain) && !isSet(workspaceId)) {
            // Backward compatibility
            return this.legacyController.modelUpdate(resource, modelDescription, modelInstance, fields);
        }
        var id = modelInstance.initialPrimaryKey;
        // if (id === undefined) {
        //   return throwError(new MissedIdParamError(XANO_PRIMARY_KEY_FIELD_NAME));
        // }
        var options = { resource: resource.uniqueName };
        var tableId = this.getTableIdFromCollectionParam(modelDescription);
        var url = this.getWorkspaceUrl(domain, workspaceId, "table/" + tableId + "/content/" + id);
        var query = new HttpQuery();
        query.method = HttpMethod.PUT;
        query.url = url;
        query.headers = this.getHeaders(dataSource, branch);
        query.body = this.serializeModel(modelInstance, modelDescription, fields);
        return this.httpQueryService.requestBody(query, options).pipe(map(function (result) {
            if (!result) {
                return;
            }
            result = _this.transformSingleItemResponse(result, modelDescription.fields);
            var instance = _this.createModel().deserialize(modelDescription.model, result);
            instance.setUp(modelDescription);
            instance.deserializeAttributes(modelDescription.dbFields);
            return instance;
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    XanoResourceController.prototype.modelDelete = function (resource, modelDescription, modelInstance) {
        var domain = resource.params['domain'];
        var workspaceId = resource.params['workspace_id'];
        var dataSource = resource.params['data_source'];
        var branch = resource.params['branch'];
        if (!isSet(domain) && !isSet(workspaceId)) {
            // Backward compatibility
            return this.legacyController.modelDelete(resource, modelDescription, modelInstance);
        }
        var id = modelInstance.initialPrimaryKey;
        // if (id === undefined) {
        //   return throwError(new MissedIdParamError(XANO_PRIMARY_KEY_FIELD_NAME));
        // }
        var options = { resource: resource.uniqueName };
        var tableId = this.getTableIdFromCollectionParam(modelDescription);
        var url = this.getWorkspaceUrl(domain, workspaceId, "table/" + tableId + "/content/" + id);
        var query = new HttpQuery();
        query.method = HttpMethod.DELETE;
        query.url = url;
        query.headers = this.getHeaders(dataSource, branch);
        return this.httpQueryService
            .requestBody(query, options)
            .pipe(this.apiService.catchApiError(), publishLast(), refCount());
    };
    XanoResourceController.prototype.uploadFile = function (resource, storage, query, file, path, fileName) {
        var domain = resource.params['domain'];
        var workspaceId = resource.params['workspace_id'];
        var dataSource = resource.params['data_source'];
        var branch = resource.params['branch'];
        if (!isSet(domain) && !isSet(workspaceId)) {
            // Backward compatibility
            return this.legacyController.uploadFile(resource, storage, query, file, path, fileName);
        }
        var apiBaseUrl = "https://" + domain;
        var httpQuery = new HttpQuery();
        var httpOptions = { resource: resource.uniqueName, tokens: { file: file } };
        var fileInfo = this.getFileInfo(file.name);
        httpQuery.method = HttpMethod.POST;
        httpQuery.url = this.getWorkspaceUrl(domain, workspaceId, 'file');
        httpQuery.headers = this.getHeaders(dataSource, branch);
        httpQuery.bodyType = HttpContentType.FormData;
        httpQuery.body = [{ name: 'content', value: '{{file}}' }];
        if (fileInfo.fileType != 'file') {
            httpQuery.body.push({ name: 'type', value: fileInfo.fileType });
        }
        return this.httpQueryService.executeRequest(httpQuery, httpOptions).pipe(map(function (event) {
            if (event.type == HttpEventType.Response) {
                var mediaBaseUrl = new URL(apiBaseUrl).origin;
                return {
                    result: {
                        uploadedPath: event.body.path,
                        uploadedUrl: mediaBaseUrl + event.body.path,
                        response: event
                    },
                    state: {
                        downloadProgress: 1,
                        uploadProgress: 1
                    }
                };
            }
            else if (event.type == HttpEventType.UploadProgress) {
                return {
                    state: {
                        uploadProgress: event.loaded / event.total,
                        downloadProgress: 0,
                        uploadLoaded: event.loaded,
                        uploadTotal: event.total
                    }
                };
            }
            else if (event.type == HttpEventType.DownloadProgress) {
                return {
                    state: {
                        uploadProgress: 1,
                        downloadProgress: event.loaded / event.total,
                        downloadLoaded: event.loaded,
                        downloadTotal: event.total
                    }
                };
            }
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    XanoResourceController.prototype.getStorageObjects = function (resource, storage, query, path) {
        var domain = resource.params['domain'];
        var workspaceId = resource.params['workspace_id'];
        var dataSource = resource.params['data_source'];
        var branch = resource.params['branch'];
        if (!isSet(domain) && !isSet(workspaceId)) {
            // Backward compatibility
            return of(new StorageObjectsResponse());
        }
        var apiBaseUrl = "https://" + domain;
        var httpQuery = new HttpQuery();
        var httpOptions = { resource: resource.uniqueName };
        httpQuery.method = HttpMethod.GET;
        httpQuery.url = this.getWorkspaceUrl(domain, workspaceId, 'file');
        httpQuery.headers = this.getHeaders(dataSource, branch);
        return this.httpQueryService.requestBody(httpQuery, httpOptions).pipe(map(function (result) {
            var response = new StorageObjectsResponse();
            response.objects = result.items.map(function (item) {
                var mediaBaseUrl = new URL(apiBaseUrl).origin;
                var mediaUrl = mediaBaseUrl + item.path;
                return new StorageObject({
                    path: item.path,
                    url: mediaUrl,
                    type: StorageObjectType.File,
                    size: item.size,
                    created: item.created_at
                });
            });
            return response;
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    XanoResourceController.prototype.deleteStorageObject = function (resource, storage, query, path) {
        var _this = this;
        var domain = resource.params['domain'];
        var workspaceId = resource.params['workspace_id'];
        var dataSource = resource.params['data_source'];
        var branch = resource.params['branch'];
        if (!isSet(domain) && !isSet(workspaceId)) {
            // Backward compatibility
            return of(false);
        }
        var httpOptions = { resource: resource.uniqueName };
        var getQuery = new HttpQuery();
        var fileName = path.split('/').slice(-1)[0];
        getQuery.method = HttpMethod.GET;
        getQuery.url = this.getWorkspaceUrl(domain, workspaceId, 'file');
        getQuery.headers = this.getHeaders(dataSource, branch);
        getQuery.queryParams = [{ name: 'search', value: fileName }];
        return this.httpQueryService.requestBody(getQuery, httpOptions).pipe(switchMap(function (result) {
            var file = result.items.find(function (item) { return item.path == path; });
            if (!file) {
                throw new AppError('File not found');
            }
            var deleteQuery = new HttpQuery();
            deleteQuery.method = HttpMethod.DELETE;
            deleteQuery.url = _this.getWorkspaceUrl(domain, workspaceId, "file/" + file.id);
            deleteQuery.headers = _this.getHeaders(dataSource, branch);
            return _this.httpQueryService.requestBody(deleteQuery, httpOptions);
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    return XanoResourceController;
}(ResourceController));
export { XanoResourceController };
