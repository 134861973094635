/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../pagination/pagination.component.ngfactory";
import * as i2 from "../pagination/pagination.component";
import * as i3 from "@angular/common";
import * as i4 from "../refresh-data/refresh-data.component.ngfactory";
import * as i5 from "../refresh-data/refresh-data.component";
import * as i6 from "../../../../core/services/local-storage/local.storage";
import * as i7 from "../../../../shared/pipes/is-set/is-set.pipe";
import * as i8 from "../../../../common/localize/pipes/localize/localize.pipe";
import * as i9 from "./list-footer.component";
var styles_ListFooterComponent = [];
var RenderType_ListFooterComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ListFooterComponent, data: {} });
export { RenderType_ListFooterComponent as RenderType_ListFooterComponent };
function View_ListFooterComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "list__pagination"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-pagination", [], null, [[null, "pageSelected"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("pageSelected" === en)) {
        var pd_0 = (_co.pageSelected.emit($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_PaginationComponent_0, i1.RenderType_PaginationComponent)), i0.ɵdid(2, 638976, null, 0, i2.PaginationComponent, [i0.ChangeDetectorRef], { current: [0, "current"], hasMore: [1, "hasMore"], lastLoaded: [2, "lastLoaded"], total: [3, "total"], theme: [4, "theme"] }, { pageSelected: "pageSelected" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentPage; var currVal_1 = _co.hasMorePages; var currVal_2 = _co.lastLoadedPage; var currVal_3 = _co.totalPages; var currVal_4 = _co.theme; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
function View_ListFooterComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u2248"]))], null, null); }
function View_ListFooterComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", " ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.count; var currVal_1 = _co.title; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_ListFooterComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""])), i0.ɵpad(2, 1), i0.ɵppd(3, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, _ck(_v, 3, 0, i0.ɵnov(_v.parent.parent, 1), "{0} items", _ck(_v, 2, 0, _co.count))); _ck(_v, 1, 0, currVal_0); }); }
function View_ListFooterComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "list__status list-footer__item"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ListFooterComponent_3)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ListFooterComponent_4)), i0.ɵdid(4, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ListFooterComponent_5)), i0.ɵdid(6, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.count >= 10000); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.title; _ck(_v, 4, 0, currVal_1); var currVal_2 = !_co.title; _ck(_v, 6, 0, currVal_2); }, null); }
function View_ListFooterComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-refresh-data", [["class", "list__refresh list-footer__item"]], null, [[null, "updateData"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("updateData" === en)) {
        var pd_0 = (_co.reload.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_RefreshDataComponent_0, i4.RenderType_RefreshDataComponent)), i0.ɵdid(1, 4440064, null, 0, i5.RefreshDataComponent, [i6.LocalStorage, i0.ChangeDetectorRef], { viewId: [0, "viewId"], accentColor: [1, "accentColor"], theme: [2, "theme"] }, { updateData: "updateData" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.viewId; var currVal_1 = _co.accentColor; var currVal_2 = _co.theme; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_ListFooterComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i7.IsSetPipe, []), i0.ɵpid(0, i8.LocalizePipe, []), (_l()(), i0.ɵeld(2, 0, null, null, 9, "div", [["class", "list-footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "list-footer__main"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ListFooterComponent_1)), i0.ɵdid(5, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 5, "div", [["class", "list-footer__right"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_ListFooterComponent_2)), i0.ɵdid(8, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵppd(9, 1), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ListFooterComponent_6)), i0.ɵdid(11, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.pagination; _ck(_v, 5, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 8, 0, _ck(_v, 9, 0, i0.ɵnov(_v, 0), _co.count)); _ck(_v, 8, 0, currVal_1); var currVal_2 = _co.reloadEnabled; _ck(_v, 11, 0, currVal_2); }, null); }
export function View_ListFooterComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-list-footer", [], null, null, null, View_ListFooterComponent_0, RenderType_ListFooterComponent)), i0.ɵdid(1, 114688, null, 0, i9.ListFooterComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ListFooterComponentNgFactory = i0.ɵccf("app-list-footer", i9.ListFooterComponent, View_ListFooterComponent_Host_0, { title: "title", pagination: "pagination", currentPage: "currentPage", hasMorePages: "hasMorePages", lastLoadedPage: "lastLoadedPage", totalPages: "totalPages", count: "count", reloadEnabled: "reloadEnabled", viewId: "viewId", accentColor: "accentColor", theme: "theme" }, { pageSelected: "pageSelected", reload: "reload" }, []);
export { ListFooterComponentNgFactory as ListFooterComponentNgFactory };
