var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { FocusMonitor } from '@angular/cdk/a11y';
import { ChangeDetectorRef, NgZone, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { merge } from 'rxjs';
import { debounceTime, take } from 'rxjs/operators';
import { ResizeType } from '@common/resizable';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ViewContext, ViewContextElement } from '@modules/customize';
// Refactor imports
import { createFormFieldFactory, FieldType, getJavaScriptSyntaxError, InputValueType, ValueArray } from '@modules/fields';
import { contextToFormulaValue, FieldInputControl, singleTokenFormulaToContextValue, transformFormulaElementAccessors } from '@modules/parameters';
import { controlValue, isSet } from '@shared';
import { InputOverlayDirective } from '../../directives/input-overlay/input-overlay.directive';
import { getFormulaStateAnalyticsParams } from '../../utils/analytics';
import { InputEditFormulaValueComponent } from '../input-edit-formula-value/input-edit-formula-value.component';
import { InputEditJsValueComponent } from '../input-edit-js-value/input-edit-js-value.component';
import { ViewContextTokenPopoverOverlayComponent } from '../view-context-token-popover-overlay/view-context-token-popover-overlay.component';
import { InputTokenType } from './input-token';
var InputEditComponent = /** @class */ (function () {
    function InputEditComponent(focusMonitor, analyticsService, zone, cd) {
        this.focusMonitor = focusMonitor;
        this.analyticsService = analyticsService;
        this.zone = zone;
        this.cd = cd;
        this.staticValueArray = false;
        this.staticValueDisabled = false;
        this.filterFields = [];
        this.userInput = false;
        this.focusedInitial = false;
        this.placeholder = 'Text';
        this.formulaPlaceholder = 'Formula';
        this.jsPlaceholder = 'return 2 * 3;';
        this.resetEnabled = true;
        this.displayValueTypesEnabled = true;
        this.displayValueTypes = [InputValueType.Formula];
        this.overlay = false;
        this.builtInItemsEnabled = true;
        this.classes = [];
        this.fill = false;
        this.fillVertical = false;
        this.header = false;
        this.small = false;
        this.resultShowOnFocus = true;
        this.dark = false;
        this.darker = false;
        this.createField = createFormFieldFactory();
        this.staticValueDisabledClean = false;
        this.extraSections = [];
        this.inputValueTypes = InputValueType;
        this.fieldTypes = FieldType;
        this.resizeTypes = ResizeType;
        this.startedFormulaEditing = false;
        this.booleanValueEquals = function (lhs, rhs) { return lhs === rhs; };
    }
    Object.defineProperty(InputEditComponent.prototype, "effectiveControl", {
        get: function () {
            return this.staticValueArray ? this.controlArray : this.control;
        },
        enumerable: true,
        configurable: true
    });
    InputEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.control = new FormControl(undefined, { updateOn: this.itemForm ? this.itemForm.updateOn : undefined });
        this.controlArray = new ValueArray({ updateOn: this.itemForm ? this.itemForm.updateOn : undefined });
        controlValue(this.itemForm)
            .pipe(untilDestroyed(this))
            .subscribe(function (value) {
            if (value['value_type'] == InputValueType.Filter) {
                _this.updateFilterField();
            }
        });
        this.setInitialValue();
        this.updateExtraSections();
        merge(this.control.valueChanges, this.controlArray.valueChanges)
            .pipe(untilDestroyed(this))
            .subscribe(function (value) {
            if (_this.itemForm.controls.value_type.value == InputValueType.StaticValue && value === '') {
                if (isSet(_this.itemForm.controls.value_type.value)) {
                    _this.itemForm.controls.value_type.patchValue('');
                    _this.itemForm.controls.static_value.patchValue('');
                }
            }
            else if ((!_this.itemForm.controls.value_type.value ||
                _this.itemForm.controls.value_type.value == InputValueType.StaticValue) &&
                !_this.staticValueDisabledClean) {
                if (_this.itemForm.controls.value_type.value != InputValueType.StaticValue) {
                    _this.itemForm.controls.value_type.patchValue(InputValueType.StaticValue);
                }
                _this.itemForm.controls.static_value.patchValue(value);
            }
            else if (_this.itemForm.controls.value_type.value == InputValueType.Formula || _this.staticValueDisabledClean) {
                if (_this.itemForm.controls.value_type.value != InputValueType.Formula) {
                    _this.itemForm.controls.value_type.patchValue(InputValueType.Formula);
                }
                var toExternalValue = transformFormulaElementAccessors(value, _this.context, false);
                _this.itemForm.controls.formula_value.patchValue(toExternalValue);
            }
            _this.itemForm.markAsDirty();
        });
        this.itemForm.valueChanges.pipe(debounceTime(200), untilDestroyed(this)).subscribe(function (value) {
            _this.updateInputErrors();
            _this.onInteracting();
            _this.analyticsService.sendSimpleEvent(AnalyticsEvent.Formula.Updated, {
                ValueType: _this.itemForm.controls.value_type.value,
                Source: _this.analyticsSource
            });
        });
    };
    InputEditComponent.prototype.ngOnDestroy = function () {
        if (this.startedFormulaEditing) {
            this.analyticsService.sendSimpleEvent(AnalyticsEvent.Formula.FinishedToSetUp, __assign({}, getFormulaStateAnalyticsParams(this.itemForm, this.context, this.initialValue), { Source: this.analyticsSource }));
        }
    };
    InputEditComponent.prototype.ngOnChanges = function (changes) {
        if (changes.staticValueField || changes.staticValueDisabled) {
            if ([FieldType.JSON, FieldType.Location].includes(this.staticValueField)) {
                this.staticValueDisabledClean = true;
            }
            else {
                this.staticValueDisabledClean = this.staticValueDisabled;
            }
        }
        if ([changes.builtInItemsEnabled, changes.userInput, changes.contextElement, changes.filterFields].some(function (item) { return item && !item.firstChange; })) {
            this.updateExtraSections();
        }
    };
    InputEditComponent.prototype.addValueControl = function () {
        this.addedValueControl = this.controlArray.pushNew();
    };
    InputEditComponent.prototype.removeValueControl = function (index) {
        this.controlArray.removeAt(index);
    };
    InputEditComponent.prototype.clearInputErrors = function () {
        this.jsError = undefined;
        this.cd.markForCheck();
    };
    InputEditComponent.prototype.updateInputErrors = function () {
        var value = this.itemForm.value;
        this.clearInputErrors();
        if (value['value_type'] == InputValueType.Js) {
            if (isSet(value['js_value'])) {
                this.jsError = getJavaScriptSyntaxError(value['js_value']);
                this.cd.markForCheck();
            }
        }
    };
    InputEditComponent.prototype.updateFilterField = function () {
        var _this = this;
        this.filterField = this.filterFields.find(function (item) { return item.name == _this.itemForm.controls.filter_field.value; });
        this.filterFieldLookup = this.filterField
            ? this.filterField.lookups.find(function (item) { return item.name === _this.itemForm.controls.filter_lookup.value; })
            : undefined;
        this.cd.markForCheck();
    };
    InputEditComponent.prototype.getFilterTokenItems = function () {
        if (!this.contextElement || !this.contextElement.fieldFilters) {
            return;
        }
        return {
            name: 'Current Component Filters',
            data: {
                type: InputTokenType.Filter
            },
            items: this.filterFields.map(function (field) {
                return {
                    name: field.label,
                    items: field.lookups.map(function (lookup) {
                        return {
                            name: lookup.label,
                            data: {
                                field: field.name,
                                lookup: lookup.name
                            }
                        };
                    })
                };
            })
        };
    };
    InputEditComponent.prototype.setInitialValue = function () {
        var formValue = this.itemForm.value;
        if (!formValue['value_type'] || formValue['value_type'] == InputValueType.StaticValue) {
            this.effectiveControl.patchValue(formValue['static_value'], { emitEvent: false });
        }
        else if (formValue['value_type'] == InputValueType.Formula) {
            var fromExternalValue = transformFormulaElementAccessors(formValue['formula_value'], this.context, true);
            this.effectiveControl.patchValue(fromExternalValue, { emitEvent: false });
        }
        else if (formValue['value_type'] == InputValueType.Filter) {
            this.updateFilterField();
        }
        this.initialValue = formValue;
        this.itemForm.markAsPristine();
        this.updateInputErrors();
    };
    InputEditComponent.prototype.updateExtraSections = function () {
        var extraSections = [];
        if (this.builtInItemsEnabled) {
            var builtInItems = [
                {
                    path: ['built_in', InputTokenType.TextInputs],
                    item: {
                        token: [InputTokenType.TextInputs],
                        label: 'Text with Inputs',
                        icon: 'variable',
                        tip: 'Apply format and Insert inputs in Text, HTML or Markdown',
                        iconOrange: true,
                        orange: true
                    }
                },
                {
                    path: ['built_in', InputTokenType.Js],
                    item: {
                        token: [InputTokenType.Js],
                        label: 'JavaScript',
                        icon: 'console',
                        tip: 'Use JavaScript',
                        iconOrange: true,
                        orange: true
                    }
                }
            ].concat((this.userInput
                ? [
                    {
                        path: ['built_in', InputTokenType.Prompt],
                        item: {
                            token: [InputTokenType.Prompt],
                            label: 'Ask user',
                            icon: 'edit',
                            tip: 'Ask user to enter value in popup window'
                        }
                    }
                ]
                : []), [
                {
                    path: ['built_in', InputTokenType.EmptyString],
                    item: {
                        token: [InputTokenType.EmptyString],
                        label: 'Empty String',
                        icon: 'delete',
                        tip: 'Send empty string'
                    }
                },
                {
                    path: ['built_in', InputTokenType.Null],
                    item: {
                        token: [InputTokenType.Null],
                        label: 'Null',
                        icon: 'deselect',
                        tip: 'Send null value'
                    }
                }
            ]);
            extraSections.push({
                name: 'built_in',
                pinned: true,
                horizontal: true,
                items: builtInItems
            });
        }
        var filterItems = this.getFilterTokenItems();
        if (filterItems) {
            extraSections.push({
                label: filterItems.name,
                icon: 'filter',
                items: filterItems.items.reduce(function (acc, fieldItem) {
                    fieldItem.items.forEach(function (lookupItem) {
                        acc.push({
                            path: ['filter', fieldItem.name, lookupItem.name],
                            item: {
                                token: [InputTokenType.Filter],
                                label: [fieldItem.name, lookupItem.name].join(' '),
                                data: lookupItem.data
                            }
                        });
                    }, []);
                    return acc;
                }, [])
            });
        }
        this.extraSections = extraSections;
        this.cd.markForCheck();
    };
    InputEditComponent.prototype.onEditorOpened = function () {
        this.onInteracting();
    };
    InputEditComponent.prototype.onInteracting = function () {
        if (!this.startedFormulaEditing) {
            this.startedFormulaEditing = true;
            this.analyticsService.sendSimpleEvent(AnalyticsEvent.Formula.StartedToSetUp, {
                ValueType: this.itemForm.controls.value_type.value,
                Source: this.analyticsSource
            });
        }
    };
    InputEditComponent.prototype.switchToFormula = function (empty) {
        if (empty === void 0) { empty = false; }
        var currentValueType = this.itemForm.controls.value_type.value;
        var contextValue = this.itemForm.controls.context_value.value;
        var staticValue = this.itemForm.controls.static_value.value;
        this.itemForm.controls.value_type.patchValue(InputValueType.Formula);
        if (empty) {
            this.control.patchValue('');
        }
        else if (currentValueType == InputValueType.Context && isSet(contextValue)) {
            var formulaValue = contextToFormulaValue(contextValue);
            formulaValue = transformFormulaElementAccessors(formulaValue, this.context, true);
            this.control.patchValue(formulaValue);
        }
        else if (currentValueType == InputValueType.StaticValue && isSet(staticValue)) {
            this.control.patchValue(JSON.stringify(staticValue));
        }
        this.focusValueOnStable();
    };
    InputEditComponent.prototype.focusValue = function () {
        if (this.formulaValueComponent) {
            this.formulaValueComponent.focus();
        }
        if (this.jsValueComponent) {
            this.jsValueComponent.focus();
        }
    };
    InputEditComponent.prototype.focusValueOnStable = function () {
        var _this = this;
        this.zone.onStable
            .pipe(take(1))
            .pipe(untilDestroyed(this))
            .subscribe(function () { return _this.focusValue(); });
    };
    InputEditComponent.prototype.switchToJs = function () {
        this.itemForm.controls.value_type.patchValue(InputValueType.Js);
        this.itemForm.markAsDirty();
    };
    InputEditComponent.prototype.switchToTextInputs = function () {
        this.itemForm.controls.value_type.patchValue(InputValueType.TextInputs);
        this.itemForm.markAsDirty();
    };
    InputEditComponent.prototype.switchToEmptyString = function () {
        this.itemForm.controls.value_type.patchValue(InputValueType.EmptyString);
        this.itemForm.markAsDirty();
    };
    InputEditComponent.prototype.switchToNull = function () {
        this.itemForm.controls.value_type.patchValue(InputValueType.Null);
        this.itemForm.markAsDirty();
    };
    InputEditComponent.prototype.switchToPrompt = function () {
        this.itemForm.controls.value_type.patchValue(InputValueType.Prompt);
        this.itemForm.markAsDirty();
    };
    InputEditComponent.prototype.switchToContext = function (token) {
        var contextValue = singleTokenFormulaToContextValue(token);
        this.itemForm.controls.value_type.patchValue(InputValueType.Context);
        this.itemForm.controls.context_value.patchValue(contextValue);
        this.itemForm.markAsDirty();
    };
    InputEditComponent.prototype.switchToFieldFilter = function (field, lookup) {
        this.itemForm.controls.value_type.patchValue(InputValueType.Filter);
        this.itemForm.controls.filter_field.patchValue(field);
        this.itemForm.controls.filter_lookup.patchValue(lookup);
        this.itemForm.markAsDirty();
    };
    InputEditComponent.prototype.switchToDefault = function () {
        if (this.staticValueDisabledClean) {
            this.itemForm.controls.value_type.patchValue(InputValueType.Formula);
            this.itemForm.markAsDirty();
            this.control.patchValue('');
            this.focusValueOnStable();
        }
        else {
            this.itemForm.controls.value_type.patchValue('');
            this.itemForm.markAsDirty();
            this.effectiveControl.patchValue(this.itemForm.controls.static_value.value);
        }
    };
    InputEditComponent.prototype.isInputOverlayOpened = function () {
        if (this.overlay || !this.inputOverlayDirective) {
            return false;
        }
        return this.inputOverlayDirective.isOpened();
    };
    InputEditComponent.prototype.onInputOverlayFinished = function () {
        this.setInitialValue();
    };
    return InputEditComponent;
}());
export { InputEditComponent };
