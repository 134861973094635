<div
  class="chart chart_horizontal"
  [class.chart_multiple]="(selectedDatasetGroups | appIsSet) ? selectedDatasetGroups.length > 1 : data.length > 1"
>
  <div class="chart__canvas" #canvas>
    <svg width="100%" height="100%" #svg></svg>
  </div>

  <div *ngIf="legend" class="chart__legend">
    <div
      *ngFor="let dataset of data; let d = index"
      class="chart-legend chart-legend_vertical"
      [class.chart-legend_theme]="theme"
    >
      <div *ngIf="data.length > 1" class="chart-legend__title">
        <ng-container *ngIf="dataset.name | appIsSet">{{ dataset.name }}</ng-container>
        <ng-container *ngIf="!(dataset.name | appIsSet)">Dataset {{ d + 1 }}</ng-container>
      </div>

      <div
        *ngFor="let item of dataset.dataset; let g = index"
        class="chart-legend__item"
        [class.chart-legend__item_interactive]="interactive"
        [class.chart-legend__item_disabled]="
          (selectedDatasetGroups | appIsSet) && !isSelectedDatasetGroup({ datasetIndex: d, groupIndex: g })
        "
        (click)="toggleSelectedDatasetGroup({ datasetIndex: d, groupIndex: g })"
        (mouseenter)="onLegendDatasetGroupMouseEnter({ datasetIndex: d, groupIndex: g })"
        (mouseleave)="onLegendDatasetGroupMouseLeave()"
      >
        <div
          class="chart-legend__item-color"
          [class.chart-legend__item-color_no-color]="!colorDisplay(0, g)"
          [style.background-color]="
            (selectedDatasetGroups | appIsSet) && !isSelectedDatasetGroup({ datasetIndex: d, groupIndex: g })
              ? null
              : colorDisplay(0, g)
          "
        ></div>
        <div class="chart-legend__item-label">
          {{ groupDisplay(item.group) }}
        </div>
      </div>
    </div>
  </div>

  <div class="chart__tooltips" #tooltip_container>
    <ng-template [cdkPortalOutlet]="null"></ng-template>
  </div>
</div>
