import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { delay, filter, first, switchMap, take } from 'rxjs/operators';

import { ActionType } from '@modules/actions';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { HttpResultsSection, SqlResultsSection } from '@modules/queries-components';

import { ActionTypeFieldComponent } from '../../action-type-field/action-type-field.component';
import { CustomizeActionOptions, CustomizeBarActionEditForm } from '../customize-bar-action-edit.form';
import { CustomizeBarActionEditTypeDownloadComponent } from './customize-bar-action-edit-type-download/customize-bar-action-edit-type-download.component';
import { CustomizeBarActionEditTypeExportComponent } from './customize-bar-action-edit-type-export/customize-bar-action-edit-type-export.component';
import { CustomizeBarActionEditTypeQueryComponent } from './customize-bar-action-edit-type-query/customize-bar-action-edit-type-query.component';
import { CustomizeBarActionEditTypeWorkflowComponent } from './customize-bar-action-edit-type-workflow/customize-bar-action-edit-type-workflow.component';

@Component({
  selector: 'app-customize-bar-action-edit-type',
  templateUrl: './customize-bar-action-edit-type.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeBarActionEditTypeComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() options: CustomizeActionOptions = {};
  @Input() form: CustomizeBarActionEditForm;
  @Input() label: string;
  @Input() typeFieldEnabled = true;
  @Input() typeFieldLabel: string;
  @Input() typeFieldRequired = false;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() contextElementPath: (string | number)[];
  @Input() contextElementPaths: (string | number)[][];
  @Input() contextPass = true;
  @Input() firstInit = false;
  @Input() setupOnCreate = false;
  @Input() showDraftChanges = false;
  @Input() analyticsSource: string;
  @Output() submit = new EventEmitter<void>();

  @ViewChild('type_select') typeSelect: ActionTypeFieldComponent;
  @ViewChild(CustomizeBarActionEditTypeQueryComponent) queryComponent: CustomizeBarActionEditTypeQueryComponent;
  @ViewChild(CustomizeBarActionEditTypeDownloadComponent)
  downloadComponent: CustomizeBarActionEditTypeDownloadComponent;
  @ViewChild(CustomizeBarActionEditTypeExportComponent) exportComponent: CustomizeBarActionEditTypeExportComponent;
  @ViewChild(CustomizeBarActionEditTypeWorkflowComponent)
  workflowComponent: CustomizeBarActionEditTypeWorkflowComponent;

  actionTypes = ActionType;

  constructor(private zone: NgZone) {}

  ngOnInit() {
    this.form.controls.type.valueChanges
      .pipe(
        filter(type => type == ActionType.Workflow && !this.form.controls.workflow.value),
        switchMap(() => this.zone.onStable.pipe(first())),
        untilDestroyed(this)
      )
      .subscribe(() => {
        if (this.workflowComponent) {
          this.workflowComponent.edit();
        }
      });
  }

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    this.zone.onStable.pipe(take(1), delay(100), untilDestroyed(this)).subscribe(() => {
      if (this.typeSelect && !this.form.controls.type.value) {
        this.typeSelect.open();
      }
    });
  }

  addQueryInput() {
    if (this.queryComponent) {
      this.queryComponent.editQuery({
        initialHttpResultsSection: HttpResultsSection.Parameters,
        initialSqlResultsSection: SqlResultsSection.Parameters
      });
    } else if (this.downloadComponent) {
      this.downloadComponent.editQuery({
        initialHttpResultsSection: HttpResultsSection.Parameters,
        initialSqlResultsSection: SqlResultsSection.Parameters
      });
    } else if (this.exportComponent) {
      this.exportComponent.addQueryInput();
    } else if (this.workflowComponent) {
      this.workflowComponent.edit({ customizeTrigger: true });
    }
  }
}
