<div
  *ngIf="selectedItemsLength"
  class="table-actions inverse"
  [class.table-actions_theme]="theme"
  [class.table-actions_styles]="theme"
>
  <app-checkbox
    class="table-actions__checkbox"
    [value]="true"
    [square]="selectedAll"
    [dash]="!selectedAll"
    [theme]="theme"
    (click)="onSelectAllClick()"
  ></app-checkbox>

  <div class="table-actions__label">
    {{ 'Selected' | localize
    }}<ng-container *ngIf="selectedItemsLength | appIsSet"> ({{ selectedItemsLength }})</ng-container>
  </div>

  <div class="table-actions__content">
    <ng-container *ngFor="let item of settings.modelActions">
      <app-table-actions-item
        class="table-actions__item"
        [action]="item"
        [selectedItems]="selectedItems"
        [context]="context"
        [contextElement]="contextElement"
        [accentColor]="accentColor"
        [theme]="theme"
      >
      </app-table-actions-item>
    </ng-container>
  </div>
</div>
