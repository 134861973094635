import { CdkOverlayOrigin, ConnectedPosition } from '@angular/cdk/overlay';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { MenuBlockLayout } from '@modules/menu';

export interface LayoutOption {
  layout: MenuBlockLayout;
  image: string;
  title: string;
  subtitle: string;
  color?: string;
}

export interface LayoutGroup {
  label: string;
  options: LayoutOption[];
}

@Component({
  selector: 'app-menu-block-layout-overlay',
  templateUrl: './menu-block-layout-overlay.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuBlockLayoutOverlayComponent implements OnInit {
  @Input() layoutGroups: LayoutGroup[] = [];
  @Input() layoutSelected: LayoutOption;
  @Input() opened = false;
  @Input() origin: CdkOverlayOrigin;
  @Output() selectLayoutOption = new EventEmitter<LayoutOption>();
  @Output() close = new EventEmitter<void>();

  overlayPositions: ConnectedPosition[] = [
    { originX: 'start', overlayX: 'start', originY: 'bottom', overlayY: 'top', offsetX: -8, offsetY: -8 },
    { originX: 'center', overlayX: 'center', originY: 'bottom', overlayY: 'top', offsetX: 0, offsetY: -8 },
    { originX: 'end', overlayX: 'end', originY: 'bottom', overlayY: 'top', offsetX: 8, offsetY: -8 },
    { originX: 'start', overlayX: 'start', originY: 'top', overlayY: 'bottom', offsetX: -8, offsetY: 8 },
    { originX: 'center', overlayX: 'center', originY: 'top', overlayY: 'bottom', offsetX: 0, offsetY: 8 },
    { originX: 'end', overlayX: 'end', originY: 'top', overlayY: 'bottom', offsetX: 8, offsetY: 8 },
    { originX: 'end', overlayX: 'start', originY: 'center', overlayY: 'center', offsetX: 0, offsetY: 0 },
    { originX: 'start', overlayX: 'end', originY: 'center', overlayY: 'center', offsetX: 0, offsetY: 0 }
  ];

  constructor() {}

  ngOnInit() {}
}
