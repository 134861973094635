import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { first, switchMap } from 'rxjs/operators';

import { ViewContext, ViewSettings, ViewSettingsStore } from '@modules/customize';
import { ProjectPropertyEditController } from '@modules/customize-bar';
import { createFormFieldFactory } from '@modules/fields';
import {
  getMenuItemSystemActionTypeInfo,
  MenuItemActionService,
  MenuItemActionType,
  MenuItemSystemActionType,
  menuItemSystemActionTypes
} from '@modules/menu';
import { ProjectProperty, ProjectPropertyStore, ProjectPropertyType } from '@modules/projects';
import { ascComparator, controlValue, isSet } from '@shared';

import { MenuItemActionControl } from '../customize-bar-pages-edit/menu-item-action.control';

export interface SystemOption {
  type: MenuItemSystemActionType;
  label: string;
  actionLabel: string;
  icon: string;
}

@Component({
  selector: 'app-edit-menu-item-action',
  templateUrl: './edit-menu-item-action.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditMenuItemActionComponent implements OnInit, OnDestroy {
  @Input() label: string;
  @Input() control: MenuItemActionControl;
  @Input() context: ViewContext;
  @Input() analyticsSource: string;
  @Output() selectPage = new EventEmitter<{ page: ViewSettings; finished: boolean }>();
  @Output() selectSystem = new EventEmitter<SystemOption>();

  createField = createFormFieldFactory();
  actionValueDisplay: { label: string; icon: string };
  systemOptions: SystemOption[] = [];
  pages: ViewSettings[] = [];
  selectedPage: ViewSettings;
  propertyGroups: { type: ProjectPropertyType; label: string; properties: ProjectProperty[]; addLabel: string }[] = [];
  currentProperty: ProjectProperty;
  actionTypes = MenuItemActionType;

  constructor(
    private menuItemActionService: MenuItemActionService,
    private viewSettingsStore: ViewSettingsStore,
    private projectPropertyStore: ProjectPropertyStore,
    private projectPropertyEditController: ProjectPropertyEditController,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.systemOptions = menuItemSystemActionTypes
      .map(systemType => {
        const info = getMenuItemSystemActionTypeInfo(systemType);

        if (!info) {
          return;
        }

        return {
          type: systemType,
          label: info.label,
          actionLabel: info.actionLabel,
          icon: info.icon
        };
      })
      .filter(item => item !== undefined);
    this.cd.markForCheck();

    this.viewSettingsStore
      .get()
      .pipe(untilDestroyed(this))
      .subscribe(viewSettings => {
        this.pages = viewSettings
          .filter(item => item.uniqueName)
          .sort((lhs, rhs) => {
            const lhsName = isSet(lhs.name) ? String(lhs.name).toLowerCase() : '';
            const rhsName = isSet(rhs.name) ? String(rhs.name).toLowerCase() : '';
            return ascComparator(lhsName, rhsName);
          });
        this.cd.markForCheck();
      });

    this.menuItemActionService
      .getActionValueDisplay$(this.control, { excludeUrl: true })
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        this.actionValueDisplay = value ? { label: value.label, icon: value.icon } : undefined;
        this.cd.markForCheck();
      });

    this.control
      .getPageUid$()
      .pipe(
        switchMap(pageUid => this.viewSettingsStore.getDetailByUid<ViewSettings>(pageUid)),
        untilDestroyed(this)
      )
      .subscribe(viewSettings => {
        this.selectedPage = viewSettings;
        this.cd.markForCheck();
      });

    this.control.updateInputFieldProvider().pipe(untilDestroyed(this)).subscribe();

    this.projectPropertyStore
      .get()
      .pipe(untilDestroyed(this))
      .subscribe(properties => {
        const groups = [
          {
            type: ProjectPropertyType.Global,
            label: 'Globals',
            addLabel: 'Add Global variable'
          },
          {
            type: ProjectPropertyType.User,
            label: 'Current user',
            addLabel: 'Add User property'
          },
          {
            type: ProjectPropertyType.Group,
            label: 'Current team',
            addLabel: 'Add Team property'
          }
        ];

        this.propertyGroups = groups.map(group => {
          return {
            ...group,
            properties: properties.filter(item => item.type == group.type)
          };
        });
        this.cd.markForCheck();
      });

    controlValue(this.control.controls.set_property_uid)
      .pipe(
        switchMap(uid => this.projectPropertyStore.getDetail(uid)),
        untilDestroyed(this)
      )
      .subscribe(value => {
        this.currentProperty = value;
        this.cd.markForCheck();
      });
  }

  ngOnDestroy(): void {
    this.control.inputFieldProvider.clearProvider();
  }

  setPageValue(page: ViewSettings) {
    this.control.setPageValue(page);

    this.control.inputFieldProvider
      .getFields$()
      .pipe(first(), untilDestroyed(this))
      .subscribe(items => {
        this.selectPage.emit({ page: page, finished: !items.length });
      });
  }

  setPropertyValue(property: ProjectProperty) {
    this.control.setPropertyValue(property);
  }

  addProperty(type: ProjectPropertyType) {
    this.projectPropertyEditController
      .create({
        type: type,
        defaultName: 'variable',
        defaultValueEnabled: true,
        context: this.context,
        analyticsSource: this.analyticsSource
      })
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        this.control.setPropertyValue(result.property);
      });
  }
}
