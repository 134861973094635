<ng-container *ngIf="enableSync">
  <app-page-block [style.max-width.px]="640">
    <div class="field">
      <label *ngIf="label" class="field__label">
        {{ label }}
      </label>

      <div class="field__content">
        <div class="button-selector">
          <div class="button-selector__item">
            <div
              class="button-selector-item button-selector-item_interactive"
              [class.button-selector-item_active]="firebaseRealTimeControl.value == realTimes.Disabled"
              (click)="firebaseRealTimeControl.patchValue(realTimes.Disabled)"
            >
              <div class="button-selector-item__title">Disabled</div>
              <div class="button-selector-item__subtitle">Sync with interval only</div>
            </div>
          </div>

          <div class="button-selector__item">
            <div
              class="button-selector-item button-selector-item_interactive"
              [class.button-selector-item_active]="firebaseRealTimeControl.value == realTimes.Functions"
              (click)="firebaseRealTimeControl.patchValue(realTimes.Functions)"
            >
              <div class="button-selector-item__title">Using Functions</div>
              <div class="button-selector-item__subtitle">Requires Blaze plan</div>
              <div class="button-selector-item__tag background-color_blue_1">recommended</div>
            </div>
          </div>

          <div class="button-selector__item">
            <div
              class="button-selector-item button-selector-item_interactive"
              [class.button-selector-item_active]="firebaseRealTimeControl.value == realTimes.Snapshots"
              (click)="firebaseRealTimeControl.patchValue(realTimes.Snapshots)"
            >
              <div class="button-selector-item__title">Using Snapshots</div>
              <div class="button-selector-item__subtitle">Extensive reads and no sub-collections sync</div>
              <div class="button-selector-item__tag background-color_orange_1">deprecated</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </app-page-block>

  <app-page-block *ngIf="firebaseRealTimeControl.value == realTimes.Functions">
    <div *ngIf="firebaseRequirementsLoading" class="field">
      <label class="field__label">Follow the actions below:</label>
      <div class="field__content">
        <div class="text-status-list">
          <div *ngFor="let item of ['grant permissions', 'enable service', 'check']" class="text-status-list__item">
            <div class="text-status-list__item-main">
              <span [class.loading-animation]="true"
                ><span class="stub-text">{{ item }}</span></span
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="!firebaseRequirementsLoading && firebaseRequirements" class="field">
      <label class="field__label">
        Follow the actions below:
        <a
          href="javascript:void(0)"
          class="field__label-link"
          style="margin-left: 4px;"
          (click)="updateFirebaseRequirements()"
        >
          <span class="field__label-link-icon icon-repeat"></span>
          Reload
        </a>
      </label>

      <div class="field__content">
        <div class="text-status-list">
          <div *ngFor="let item of firebaseRequirements" class="text-status-list__item">
            <div class="text-status-list__item-left text-status-list__item-left_vertical-align_top">
              <span *ngIf="!(item.status | appIsSet: true)" class="text-status-list__circle icon-help"></span>
              <span
                *ngIf="item.status === true"
                class="text-status-list__circle text-status-list__circle_green icon-check_2"
              ></span>
              <span
                *ngIf="item.status === false"
                class="text-status-list__circle text-status-list__circle_red text-status-list__circle_size_s icon-close"
              ></span>
            </div>

            <div class="text-status-list__item-main">
              <div class="text-status-list__item-title">
                {{ item.title }}
                <ng-container *ngIf="item.link">
                  –
                  <a [href]="item.link" class="text-status-list__item-link" target="_blank">
                    <span
                      class="text-status-list__item-link-icon text-status-list__item-link-icon_left icon-external_link"
                    ></span>
                    <span class="text-status-list__item-link-label">Open settings</span>
                  </a>
                </ng-container>
              </div>

              <div *ngIf="item.description | appIsSet" class="text-status-list__item-description">
                {{ item.description }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="!firebaseRequirementsLoading && firebaseRequirementsError" class="field">
      <div class="field__errors">
        <div class="field__error">
          {{ firebaseRequirementsError }}
        </div>
      </div>
    </div>
  </app-page-block>
</ng-container>

<app-popup2-footer>
  <app-page-block>
    <app-stack [align]="'right'">
      <button type="button" class="button popup-content__submit-item" (click)="cancel.emit()">
        {{ cancelLabel }}
      </button>

      <button
        *ngIf="disabled"
        type="button"
        class="button button_primary button_shadow popup-content__submit-item"
        (click)="updateSubmitDisabled()"
      >
        <span class="button__label">Check configuration</span>
      </button>

      <button
        *ngIf="!disabled"
        type="button"
        class="button button_primary button_shadow popup-content__submit-item"
        [disabled]="disabled || loading || submitLoader"
        (click)="submitResult()"
      >
        <app-loader-small *ngIf="submitLoader" class="button__icon button__icon_left"></app-loader-small>
        <span class="button__label">{{ submitLabel }}</span>
      </button>
    </app-stack>
  </app-page-block>
</app-popup2-footer>
