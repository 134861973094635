import { AfterViewInit, ChangeDetectorRef, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, fromEvent, of } from 'rxjs';
import { catchError, distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';
import { ProjectSettingsStore } from '@modules/all-project-settings';
import { applyBooleanInput$ } from '@modules/fields';
import { MenuBlockLayout, MenuBlockLayouts, MenuService, MenuSettingsStore } from '@modules/menu';
import { ViewContextTokenProvider } from '@modules/parameters-components';
import { ProjectGroupStore } from '@modules/projects';
import { ThemeService } from '@modules/theme';
import { getWindowScrollTop } from '@shared';
import { MenuContext } from '../../data/menu-context';
import { MenuPrimary, MenuPrimaryComponent } from '../menu-primary/menu-primary.component';
import { MenuSecondary } from '../menu-secondary/menu-secondary.component';
var markMenuClickProperty = '_markMenuClickProperty';
function markMenuClick(clickEvent) {
    clickEvent[markMenuClickProperty] = true;
}
function isMenuClick(clickEvent) {
    return !!clickEvent[markMenuClickProperty];
}
var ɵ0 = function (menuContext, projectGroupStore) {
    return new ViewContextTokenProvider(menuContext, projectGroupStore);
};
var MenuWrapperComponent = /** @class */ (function () {
    function MenuWrapperComponent(context, menuSettingsStore, projectSettingsStore, menuService, themeService, cd) {
        this.context = context;
        this.menuSettingsStore = menuSettingsStore;
        this.projectSettingsStore = projectSettingsStore;
        this.menuService = menuService;
        this.themeService = themeService;
        this.cd = cd;
        this.desktopMenu = false;
        this.mobileMenu = false;
        this.mobileOpened = false;
        this.scrolled = false;
        this.menuBlockLayouts = MenuBlockLayouts;
    }
    MenuWrapperComponent.prototype.trackMenuBlockFn = function (i, item) {
        return item.uid;
    };
    MenuWrapperComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.menuSettingsStore
            .get()
            .pipe(catchError(function () { return of(undefined); }), switchMap(function (menuSettings) {
            if (!menuSettings) {
                return of({ menuSettings: undefined, blocks: undefined });
            }
            var $blocks = menuSettings.blocks.map(function (block) {
                if (block.enabledInput) {
                    return applyBooleanInput$(block.enabledInput, {
                        context: _this.context
                    }).pipe(map(function (enabled) { return ({ block: block, enabled: enabled }); }));
                }
                else {
                    return of({ block: block, enabled: block.enabled });
                }
            });
            if (!$blocks.length) {
                return of({ menuSettings: menuSettings, blocks: [] });
            }
            return combineLatest($blocks).pipe(map(function (blocks) {
                return {
                    menuSettings: menuSettings,
                    blocks: blocks.filter(function (item) { return item.enabled; }).map(function (item) { return item.block; })
                };
            }));
        }), untilDestroyed(this))
            .subscribe(function (value) {
            _this.menuSettings = value.menuSettings;
            var menuBlocks = _this.menuSettings ? value.blocks : undefined;
            _this.leftMenuBlocks = menuBlocks ? menuBlocks.filter(function (item) { return MenuBlockLayouts.isLeft(item.layout); }) : undefined;
            _this.topMenuBlocks = menuBlocks ? menuBlocks.filter(function (item) { return item.layout == MenuBlockLayout.TopThin; }) : undefined;
            _this.topContentMenuBlocks = menuBlocks
                ? menuBlocks.filter(function (item) { return item.layout == MenuBlockLayout.TopContentThin; })
                : undefined;
            _this.mobileMenuBlocks = menuBlocks ? _this.getMobileMenuBlocks(menuBlocks) : undefined;
            _this.cd.markForCheck();
        });
        this.allSettings$ = this.projectSettingsStore.getAllSettings$();
    };
    MenuWrapperComponent.prototype.ngOnDestroy = function () { };
    MenuWrapperComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        fromEvent(window, 'scroll', { passive: true })
            .pipe(map(function () { return getWindowScrollTop(); }))
            .pipe(startWith(getWindowScrollTop()), map(function () { return getWindowScrollTop() > 0; }), distinctUntilChanged(), untilDestroyed(this))
            .subscribe(function (scrolled) {
            _this.scrolled = scrolled;
            _this.cd.markForCheck();
        });
    };
    MenuWrapperComponent.prototype.asMenuBlocks = function (value) {
        return value;
    };
    MenuWrapperComponent.prototype.getMobileMenuBlocks = function (desktopBlocks) {
        var wideBlock = desktopBlocks.find(function (item) { return item.layout == MenuBlockLayout.LeftWide; }) || desktopBlocks[0];
        return desktopBlocks.slice(0, 2).map(function (desktopBlock) {
            var mobileBlock = desktopBlock.clone();
            mobileBlock.layout = desktopBlock === wideBlock ? MenuBlockLayout.LeftWide : MenuBlockLayout.LeftThin;
            return mobileBlock;
        });
    };
    MenuWrapperComponent.prototype.outerWidth = function (block) {
        var primary = MenuBlockLayouts.isPrimary(block.layout);
        var horizontal = MenuBlockLayouts.isHorizontal(block.layout);
        if (!horizontal && (block.width || block.padding)) {
            var defaultMenuWidth = primary ? MenuPrimary.defaultWidth() : MenuSecondary.defaultWidth();
            return ((block.width || defaultMenuWidth) +
                ((block.padding && block.padding.left) || 0) +
                ((block.padding && block.padding.right) || 0));
        }
    };
    MenuWrapperComponent.prototype.outerHeight = function (block) {
        var primary = MenuBlockLayouts.isPrimary(block.layout);
        var horizontal = MenuBlockLayouts.isHorizontal(block.layout);
        if (horizontal && (block.height || block.padding)) {
            var defaultMenuHeight = primary ? MenuPrimary.defaultHeight() : 0;
            return ((block.height || defaultMenuHeight) +
                ((block.padding && block.padding.top) || 0) +
                ((block.padding && block.padding.bottom) || 0));
        }
    };
    MenuWrapperComponent.prototype.toggleMenuOpened = function () {
        this.menuService.toggleOpened();
    };
    MenuWrapperComponent.prototype.onMobileMenuBackgroundClick = function (e) {
        if (!isMenuClick(e)) {
            this.menuService.opened = false;
        }
    };
    MenuWrapperComponent.prototype.onMobileMenuClick = function (e) {
        markMenuClick(e);
    };
    return MenuWrapperComponent;
}());
export { MenuWrapperComponent };
export { ɵ0 };
