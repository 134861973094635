<div
  class="compact-select"
  [class.compact-select_focus]="options_component.dropdownOpened"
  [style.width.px]="widthPx"
  cdkOverlayOrigin
  #origin="cdkOverlayOrigin"
  (click)="options_component.setDropdownOpened(true)"
>
  <div *ngIf="colorDisplay && (currentOption?.color | appIsSet)" class="compact-select__left">
    <div
      class="compact-select__icon"
      [style.background]="
        (currentOption.color | appIsSet) ? sanitizer.bypassSecurityTrustStyle(currentOption.color) : null
      "
    ></div>
  </div>

  <div class="compact-select__main">
    <div *ngIf="currentOption" class="compact-select__value">
      {{ currentOption.name }}
    </div>

    <div *ngIf="!currentOption && (placeholder | appIsSet)" class="compact-select__placeholder">
      {{ placeholder }}
    </div>
  </div>

  <div class="compact-select__right">
    <div class="compact-select__arrow icon-arrow_down_2"></div>
  </div>
</div>

<app-compact-select-options
  [options]="options"
  [currentOption]="currentOption"
  [origin]="origin"
  (selectOption)="updateValue($event.value)"
  #options_component
></app-compact-select-options>
