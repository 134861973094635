import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { merge } from 'rxjs';
import { delay } from 'rxjs/operators';

import {
  auth0BackendName,
  azureADTenantBackendName,
  azureADV2TenantBackendName,
  cognitoBackendName,
  customOAuth2BackendName,
  keycloakBackendName,
  socialBackends
} from '@modules/projects';
import { isSet } from '@shared';

@Injectable()
export class OAuth2ParamsForm {
  control: AbstractControl;

  form = new FormGroup({
    backend: new FormControl('', Validators.required),
    key: new FormControl('', Validators.required),
    secret: new FormControl('', Validators.required),
    scope: new FormControl(''),
    scope_separator: new FormControl(' '),
    access_token: new FormControl('')
  });

  cognitoOAuth2Form = new FormGroup({
    pool_domain: new FormControl('', Validators.required)
  });

  azureADTenantOAuth2Form = new FormGroup({
    tenant_id: new FormControl('', Validators.required)
  });

  auth0OAuth2Form = new FormGroup({
    domain: new FormControl('', Validators.required),
    audience: new FormControl('')
  });

  keycloakOAuth2Form = new FormGroup({
    authorization_url: new FormControl('', Validators.required),
    access_token_url: new FormControl('', Validators.required),
    public_key: new FormControl('')
  });

  customOAuth2Form = new FormGroup({
    authorization_url: new FormControl('', Validators.required),
    access_token_url: new FormControl('', Validators.required)
  });

  backendOptions = socialBackends.map(item => {
    return {
      value: item.name,
      name: item.label
    };
  });

  init(control: AbstractControl) {
    this.control = control;
    this.updateValueFromControl();

    merge(
      this.form.valueChanges,
      this.cognitoOAuth2Form.valueChanges,
      this.azureADTenantOAuth2Form.valueChanges,
      this.auth0OAuth2Form.valueChanges,
      this.keycloakOAuth2Form.valueChanges,
      this.customOAuth2Form.valueChanges
    )
      .pipe(delay(0))
      .subscribe(() => this.updateValueToControl());
  }

  updateValueFromControl() {
    const value = this.control.value;

    if (value) {
      this.form.patchValue(
        {
          backend: value['backend'],
          key: value['key'],
          secret: value['secret'],
          scope: value['scope'] ? value['scope'].join(',') : '',
          scope_separator: value['scope_separator'] || ' '
        },
        { emitEvent: false }
      );

      this.cognitoOAuth2Form.patchValue(
        {
          pool_domain: value['pool_domain']
        },
        { emitEvent: false }
      );

      this.azureADTenantOAuth2Form.patchValue(
        {
          tenant_id: value['tenant_id']
        },
        { emitEvent: false }
      );

      this.auth0OAuth2Form.patchValue(
        {
          domain: value['domain'],
          audience: value['audience']
        },
        { emitEvent: false }
      );

      this.keycloakOAuth2Form.patchValue(
        {
          authorization_url: value['authorization_url'],
          access_token_url: value['access_token_url'],
          public_key: value['public_key']
        },
        { emitEvent: false }
      );

      this.customOAuth2Form.patchValue(
        {
          authorization_url: value['authorization_url'],
          access_token_url: value['access_token_url']
        },
        { emitEvent: false }
      );
    }
  }

  isValid() {
    const backend = this.form.value['backend'];

    if (backend == cognitoBackendName) {
      return this.form.valid && this.cognitoOAuth2Form.valid;
    } else if (backend == azureADTenantBackendName || backend == azureADV2TenantBackendName) {
      return this.form.valid && this.azureADTenantOAuth2Form.valid;
    } else if (backend == auth0BackendName) {
      return this.form.valid && this.auth0OAuth2Form.valid;
    } else if (backend == keycloakBackendName) {
      return this.form.valid && this.keycloakOAuth2Form.valid;
    } else if (backend == customOAuth2BackendName) {
      return this.form.valid && this.customOAuth2Form.valid;
    } else {
      return this.form.valid;
    }
  }

  updateValueToControl() {
    const value = this.form.value;
    const cognitoOAuth2Value = this.cognitoOAuth2Form.value;
    const azureADTenantOAuth2Value = this.azureADTenantOAuth2Form.value;
    const auth0OAuth2Value = this.auth0OAuth2Form.value;
    const keycloakOAuth2Value = this.keycloakOAuth2Form.value;
    const customOAuth2Value = this.customOAuth2Form.value;

    const scope = (value['scope'] as string)
      .split(',')
      .map(item => item.trim())
      .filter(item => isSet(item));
    const result = {
      backend: value['backend'],
      key: value['key'],
      secret: value['secret'],
      scope: scope,
      scope_separator: value['scope_separator']
    };

    if (value['backend'] == cognitoBackendName) {
      result['pool_domain'] = cognitoOAuth2Value['pool_domain'];
    } else if (value['backend'] == azureADTenantBackendName || value['backend'] == azureADV2TenantBackendName) {
      result['tenant_id'] = azureADTenantOAuth2Value['tenant_id'];
    } else if (value['backend'] == auth0BackendName) {
      result['domain'] = auth0OAuth2Value['domain'];
      result['audience'] = auth0OAuth2Value['audience'];
    } else if (value['backend'] == keycloakBackendName) {
      result['authorization_url'] = keycloakOAuth2Value['authorization_url'];
      result['access_token_url'] = keycloakOAuth2Value['access_token_url'];
      result['public_key'] = keycloakOAuth2Value['public_key'];
    } else if (value['backend'] == customOAuth2BackendName) {
      result['authorization_url'] = customOAuth2Value['authorization_url'];
      result['access_token_url'] = customOAuth2Value['access_token_url'];
    }

    this.control.patchValue(result);
  }
}
