import {
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChange,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import toPairs from 'lodash/toPairs';
import values from 'lodash/values';
import { Observable } from 'rxjs';

import { DynamicComponent, DynamicComponentArguments } from '@common/dynamic-component';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { Dashboard, Widget, WidgetType } from '@modules/dashboard';
import { TypedChanges } from '@shared';

import { ChartWidgetComponent } from '../chart-widget/chart-widget.component';
import { UnknownWidgetComponent } from '../unknown-widget/unknown-widget.component';
import { ValueWidgetComponent } from '../value-widget/value-widget.component';

import { WidgetComponent } from '../widget/widget.component';

@Component({
  selector: 'app-auto-widget',
  templateUrl: './auto-widget.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AutoWidgetComponent implements OnDestroy, OnChanges {
  @Input() dashboard: Dashboard;
  @Input() widget: Widget;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() fill = true;
  @Input() accentColor: string;
  @Input() theme = false;
  @ViewChild(DynamicComponent) dynamicComponent: DynamicComponent;

  mapping = [
    { type: WidgetType.Chart, component: ChartWidgetComponent },
    { type: WidgetType.Value, component: ValueWidgetComponent }
  ];
  defaultMappingComponent = UnknownWidgetComponent;
  componentData: DynamicComponentArguments<WidgetComponent>;

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<AutoWidgetComponent>): void {
    if (changes.widget) {
      const prevType = changes.widget.previousValue ? changes.widget.previousValue.type : undefined;
      const currentType = changes.widget.currentValue ? changes.widget.currentValue.type : undefined;

      if (prevType != currentType) {
        this.initWidget();
      } else {
        this.updateWidget();
      }
    } else if (changes.accentColor) {
      this.updateWidget();
    }
  }

  initWidget() {
    const mapping = this.mapping.find(item => item.type == this.widget.type);
    const component = mapping ? mapping.component : this.defaultMappingComponent;

    this.componentData = {
      component: component,
      inputs: {
        dashboard: this.dashboard,
        widget: this.widget,
        context: this.context,
        contextElement: this.contextElement,
        fill: this.fill,
        accentColor: this.accentColor,
        theme: this.theme
      }
    };
  }

  updateWidget() {
    if (
      !this.dynamicComponent ||
      !this.dynamicComponent.currentComponent ||
      !this.dynamicComponent.currentComponent.instance
    ) {
      return;
    }

    const ref = this.dynamicComponent.currentComponent as ComponentRef<WidgetComponent>;
    const changes: SimpleChanges = {};
    const inputs = {
      dashboard: this.dashboard,
      widget: this.widget,
      context: this.context,
      contextElement: this.contextElement,
      fill: this.fill,
      accentColor: this.accentColor
    };

    toPairs(inputs).forEach(([name, value]) => {
      if (ref.instance[name] === value) {
        return;
      }

      changes[name] = new SimpleChange(ref.instance[name], value, false);
      ref.instance[name] = value;
    });

    if (values(changes).length) {
      ref.changeDetectorRef.markForCheck();

      if (ref.instance['ngOnChanges']) {
        ref.instance['ngOnChanges'](changes);
      }
    }
  }

  getData$<T = any>(): Observable<T> {
    if (this.dynamicComponent && this.dynamicComponent.currentComponent.instance.getData$) {
      return this.dynamicComponent.currentComponent.instance.getData$();
    }
  }

  reloadData() {
    if (this.dynamicComponent && this.dynamicComponent.currentComponent.instance.reloadData) {
      this.dynamicComponent.currentComponent.instance.reloadData();
    }
  }
}
