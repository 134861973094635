import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';
import range from 'lodash/range';

import { TypedChanges } from '@shared';

import { TableStubData } from '../table-stub/table-stub-data';

@Component({
  selector: 'app-table-stub-content-header, [app-table-stub-content-header]',
  templateUrl: './table-stub-content-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableStubContentHeaderComponent implements OnInit, OnChanges {
  @Input() columns = 5;
  @Input() data: TableStubData;
  @Input() textStub = true;
  @Input() animating = false;
  @Input() scrollable = false;

  displayColumns: string[] = [];

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: TypedChanges<TableStubContentHeaderComponent>): void {
    if (changes.data || changes.columns) {
      if (this.data) {
        this.displayColumns = this.data.headers;
      } else if (this.scrollable) {
        this.displayColumns = range(8).map(() => 'Column');
      } else {
        this.displayColumns = range(1, this.columns + 1).map(() => 'Column');
      }
    }
  }
}
