var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import pickBy from 'lodash/pickBy';
import { Input } from '@modules/fields';
import { TextStyle } from '../../text-style';
import { getElementByType, registerElementForType } from '../element-items';
import { ElementType } from '../element-type';
import { ElementItem } from './base';
export var CollapseIndicatorPosition;
(function (CollapseIndicatorPosition) {
    CollapseIndicatorPosition["None"] = "none";
    CollapseIndicatorPosition["Right"] = "right";
    CollapseIndicatorPosition["Left"] = "left";
})(CollapseIndicatorPosition || (CollapseIndicatorPosition = {}));
var CollapseElementItem = /** @class */ (function (_super) {
    __extends(CollapseElementItem, _super);
    function CollapseElementItem() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.type = ElementType.Collapse;
        _this.children = [];
        _this.collapseIndicatorPosition = CollapseIndicatorPosition.Right;
        return _this;
    }
    CollapseElementItem.prototype.deserialize = function (data) {
        _super.prototype.deserialize.call(this, data);
        this.width = this.params['width'];
        this.icon = this.params['icon'];
        this.tooltip = this.params['tooltip'];
        if (this.params['title_input']) {
            this.titleInput = new Input().deserialize(this.params['title_input']);
        }
        if (this.params['title_style']) {
            this.titleStyle = new TextStyle().deserialize(this.params['title_style']);
        }
        else {
            this.titleStyle = undefined;
        }
        if (this.params['children']) {
            this.children = this.params['children']
                .map(function (item) {
                var element = getElementByType(item['type']);
                if (!element) {
                    console.error("Unsupported element type: " + item['type']);
                    return;
                }
                return new element().deserialize(item);
            })
                .filter(function (item) { return item != undefined; });
        }
        if (this.params['opened_input']) {
            this.openedInput = new Input().deserialize(this.params['opened_input']);
        }
        if (this.params['collapse_indicator_position']) {
            this.collapseIndicatorPosition = this.params['collapse_indicator_position'];
        }
        return this;
    };
    CollapseElementItem.prototype.serialize = function (fields) {
        this.params = {
            width: this.width,
            title_input: this.titleInput ? this.titleInput.serialize() : null,
            title_style: this.titleStyle ? this.titleStyle.serialize() : undefined,
            icon: this.icon,
            children: this.children.map(function (item) { return item.serialize(); }),
            opened_input: this.openedInput ? this.openedInput.serialize() : null,
            visible_input: this.visibleInput ? this.visibleInput.serialize() : null,
            tooltip: this.tooltip,
            collapse_indicator_position: this.collapseIndicatorPosition
        };
        var data = _super.prototype.serialize.call(this);
        if (fields) {
            data = pickBy(data, function (v, k) { return fields.includes(k); });
        }
        return data;
    };
    Object.defineProperty(CollapseElementItem.prototype, "analyticsName", {
        get: function () {
            return 'collapse';
        },
        enumerable: true,
        configurable: true
    });
    CollapseElementItem.prototype.defaultName = function () {
        return 'Collapse';
    };
    CollapseElementItem.prototype.childrenCount = function () {
        return this.children.length;
    };
    return CollapseElementItem;
}(ElementItem));
export { CollapseElementItem };
registerElementForType(ElementType.Collapse, CollapseElementItem);
