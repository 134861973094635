/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./dynamic.component";
var styles_DynamicComponent = [];
var RenderType_DynamicComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DynamicComponent, data: {} });
export { RenderType_DynamicComponent as RenderType_DynamicComponent };
export function View_DynamicComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_DynamicComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 1, "app-dynamic-component", [], null, null, null, View_DynamicComponent_0, RenderType_DynamicComponent)), i0.ɵdid(1, 442368, null, 0, i1.DynamicComponent, [i0.ElementRef, i0.Injector, i0.ComponentFactoryResolver, i0.ApplicationRef, i0.ViewContainerRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DynamicComponentNgFactory = i0.ɵccf("app-dynamic-component", i1.DynamicComponent, View_DynamicComponent_Host_0, { componentData: "componentData" }, {}, []);
export { DynamicComponentNgFactory as DynamicComponentNgFactory };
