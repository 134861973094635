<form (ngSubmit)="submit()">
  <app-customize-bar-header [title]="'Single Value'" [subtitle]="'Data'"></app-customize-bar-header>

  <div class="sidebar__content">
    <app-sidebar-tabs>
      <app-sidebar-tab [label]="'General'">
        <app-sidebar-section>
          <app-value-widget-data-source-edit
            [control]="control"
            [label]="'choose collection'"
            [typeOrange]="true"
            [queryOptionEmptyLabel]="'No collections'"
            [context]="context"
            [contextElement]="contextElement"
            [resourceClasses]="'component-data-resource'"
            [queryOptionClasses]="'component-data-collection'"
            [analyticsSource]="widget.analyticsGenericName"
          ></app-value-widget-data-source-edit>
        </app-sidebar-section>

        <app-sidebar-section
          *ngIf="
            (control.controls.type | appControlValue | async) == dataSourceTypes.Input ||
            (control.controls.type | appControlValue | async) == dataSourceTypes.Workflow ||
            ((control.controls.type | appControlValue | async) == dataSourceTypes.Query && control.controls.query.valid)
          "
          [title]="(control.getQueryEditable$() | async) ? 'Specify Inputs' : 'Apply Filters'"
          [description]="
            (control.getQueryEditable$() | async)
              ? 'Manually or use value from other components'
              : 'Static or use value from other components'
          "
        >
          <app-data-source-inputs-edit
            [control]="control"
            [editable]="control.getQueryEditable$() | async"
            [nestedFieldsEnabled]="false"
            [relationsEnabled]="false"
            [context]="context"
            [contextElement]="contextElement"
            [classes]="'component-data-inputs'"
            [itemClasses]="'component-data-inputs-item'"
            [addClasses]="'component-data-inputs-add'"
            [analyticsSource]="widget.analyticsGenericName"
            (addInput)="addQueryInput()"
          ></app-data-source-inputs-edit>
        </app-sidebar-section>

        <app-sidebar-submit
          [saveEnabled]="false"
          [cancelEnabled]="false"
          [backEnabled]="true"
          [errors]="chartForm | appFormErrors"
          (cancelClick)="cancel()"
        >
        </app-sidebar-submit>
      </app-sidebar-tab>

      <app-sidebar-tab [label]="'Display'">
        <app-sidebar-section>
          <div class="sidebar__element">
            <app-sidebar-field [label]="'compare label'" [additional]="'(optional)'">
              <app-auto-field
                [form]="nameControl.parent"
                [label]="false"
                [field]="
                  createField({
                    name: 'compare_name',
                    field: 'CharField',
                    placeholder: 'e.g. from last month',
                    params: { classes: ['input_fill'] }
                  })
                "
              ></app-auto-field>
            </app-sidebar-field>
          </div>

          <div class="sidebar__element">
            <app-sidebar-field [label]="'Positive values are bad'" [layoutLeft]="true">
              <app-auto-field
                [form]="growthNegativeControl.parent"
                [label]="false"
                [field]="
                  createField({
                    name: 'compare_growth_negative',
                    field: 'BooleanField',
                    params: { style: booleanFieldStyle.Toggle, orange: true }
                  })
                "
              ></app-auto-field>
            </app-sidebar-field>
          </div>
        </app-sidebar-section>
      </app-sidebar-tab>
    </app-sidebar-tabs>
  </div>
</form>
