import { AfterViewInit, ChangeDetectorRef, EventEmitter, NgZone, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable } from 'rxjs';
import { debounceTime, delay, filter, map, switchMap, take } from 'rxjs/operators';
import { copyTextToClipboard } from '@common/code';
import { NotificationService } from '@common/notifications';
import { SelectComponent } from '@common/select';
import { ActionService } from '@modules/action-queries';
import { ActionDescription, ActionItem, ActionType, QueryAction } from '@modules/actions';
import { ApiService } from '@modules/api';
import { ViewContextElement } from '@modules/customize';
import { createFormFieldFactory, OptionsType } from '@modules/fields';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { ActionQuery, QueryType } from '@modules/queries';
import { SidebarCollapseContext } from '@modules/sidebar';
import { Automation, AutomationService, AutomationTrigger, AutomationTriggerType, ModelAutomationTrigger, TimetableType, WebhookAutomationTrigger } from '@modules/workflow';
import { controlValue, isSet } from '@shared';
import { CustomizeBarContext } from '../../../services/customize-bar-context/customize-bar.context';
import { CustomizeWorkflowTriggerForm } from './customize-workflow-trigger.form';
var CustomizeWorkflowTriggerComponent = /** @class */ (function () {
    function CustomizeWorkflowTriggerComponent(form, currentProjectStore, currentEnvironmentStore, automationService, actionService, customizeBarContext, notificationService, apiService, zone, cd) {
        this.form = form;
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.automationService = automationService;
        this.actionService = actionService;
        this.customizeBarContext = customizeBarContext;
        this.notificationService = notificationService;
        this.apiService = apiService;
        this.zone = zone;
        this.cd = cd;
        this.triggerEditable = false;
        this.workflowEditable = false;
        this.parametersEnabled = false;
        this.workflowChange = new EventEmitter();
        this.closeCustomize = new EventEmitter();
        this.createField = createFormFieldFactory();
        this.triggerTypes = AutomationTriggerType;
        this.timetableTypes = TimetableType;
        this.optionsTypes = OptionsType;
        this.actionItems = [];
        this.webhookLoading = true;
        this.collapseContext = new SidebarCollapseContext();
        this.parametersValue = {};
        this.outputs = [];
        this.arrayOutput = false;
        this.formIgnoreUpdate = false;
        this.trackOutput = (function () {
            return function (i, item) {
                return isSet(item.name) ? item.name : "index_" + i;
            };
        })();
        this.exactValueEquals = function (lhs, rhs) { return lhs === rhs; };
    }
    CustomizeWorkflowTriggerComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.form.init({
            triggerEditable: this.triggerEditable,
            trigger: this.trigger,
            testParameters: this.testParameters,
            parameters: this.parameters
        });
        this.form.valueChanges
            .pipe(filter(function () {
            if (_this.formIgnoreUpdate) {
                _this.formIgnoreUpdate = false;
                return false;
            }
            return true;
        }), debounceTime(200), map(function () { return _this.form.submit(); }), untilDestroyed(this))
            .subscribe(function (result) {
            _this.trigger = result.trigger;
            _this.updateContextOutputs();
            _this.submit(result);
        });
        this.updateContextOutputs();
        this.contextElement.outputsValue$.pipe(untilDestroyed(this)).subscribe(function (value) {
            _this.parametersValue = value;
            _this.cd.markForCheck();
        });
        this.form
            .triggerModelActionItems$()
            .pipe(untilDestroyed(this))
            .subscribe(function (items) {
            _this.actionItems = items;
            _this.cd.markForCheck();
        });
        controlValue(this.form.controls.trigger_type)
            .pipe(filter(function (item) { return item == AutomationTriggerType.Webhook; }), switchMap(function (value) {
            return _this.automationService.getAutomationTriggerWebhook(_this.currentProjectStore.instance, _this.currentEnvironmentStore.instance, _this.automation);
        }), untilDestroyed(this))
            .subscribe(function (webhook) {
            _this.setWebhook(webhook);
            if (_this.form.controls.trigger_webhook_token.value != webhook.token) {
                _this.form.controls.trigger_webhook_token.patchValue(webhook.token);
            }
        });
        if (this.webhookUpdated$) {
            this.webhookUpdated$.pipe(untilDestroyed(this)).subscribe(function (result) {
                _this.formIgnoreUpdate = true;
                _this.setWebhook(result);
                _this.contextElement.setOutputValues(result.testData || {});
                _this.form.controls.test_parameters.patchValue(result.testData || {});
            });
        }
    };
    CustomizeWorkflowTriggerComponent.prototype.ngOnDestroy = function () { };
    CustomizeWorkflowTriggerComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.zone.onStable.pipe(take(1), delay(100), untilDestroyed(this)).subscribe(function () {
            if (_this.typeSelect && !_this.form.controls.trigger_type.value) {
                _this.typeSelect.open();
            }
        });
    };
    Object.defineProperty(CustomizeWorkflowTriggerComponent.prototype, "settingsTabEnabled", {
        get: function () {
            return this.triggerEditable || this.outputs.length > 0 || this.parametersEnabled;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CustomizeWorkflowTriggerComponent.prototype, "inputsTabEnabled", {
        get: function () {
            return isSet(this.triggerData);
        },
        enumerable: true,
        configurable: true
    });
    CustomizeWorkflowTriggerComponent.prototype.copyWebhookUrl = function () {
        var _this = this;
        copyTextToClipboard(this.webhookUrl)
            .pipe(untilDestroyed(this))
            .subscribe(function (success) {
            if (!success) {
                return;
            }
            _this.notificationService.info('Copied', 'Webhook URL was copied to clipboard');
        });
    };
    CustomizeWorkflowTriggerComponent.prototype.reinitializeStructure = function () {
        var _this = this;
        this.webhookLoading = true;
        this.cd.markForCheck();
        this.automationService
            .automationTriggerWebhookReinitializeStructure(this.currentProjectStore.instance, this.currentEnvironmentStore.instance, this.webhook)
            .pipe(untilDestroyed(this))
            .subscribe(function (webhook) {
            _this.setWebhook(webhook);
            _this.notificationService.info('Listening for the data', 'We are listening for the data to determine data structure from request.');
        });
    };
    CustomizeWorkflowTriggerComponent.prototype.setWebhook = function (webhook) {
        var hookPath = webhook ? webhook.getRunPath() : undefined;
        this.webhook = webhook;
        this.webhookUrl = webhook ? this.apiService.workflowsMethodURL(hookPath) : undefined;
        this.webhookLoading = false;
        this.cd.markForCheck();
        this.updateContextOutputs();
    };
    CustomizeWorkflowTriggerComponent.prototype.updateContextOutputs = function () {
        var _this = this;
        if (this.triggerOutputs) {
            this.outputs = this.triggerOutputs.slice();
            this.arrayOutput = false;
            this.cd.markForCheck();
        }
        else if (this.triggerEditable) {
            if (this.trigger instanceof ModelAutomationTrigger) {
                var actionDescription = new ActionDescription();
                actionDescription.resource = this.trigger.resource;
                actionDescription.type = ActionType.Query;
                actionDescription.model = this.trigger.model;
                actionDescription.modelAction = this.trigger.action;
                actionDescription.queryAction = new QueryAction();
                actionDescription.queryAction.query = new ActionQuery();
                actionDescription.queryAction.query.queryType = QueryType.Simple;
                actionDescription.queryAction.query.simpleQuery = new actionDescription.queryAction.query.simpleQueryClass();
                actionDescription.queryAction.query.simpleQuery.name = "__" + this.trigger.model + "__" + this.trigger.action;
                var action = new ActionItem();
                action.actionDescription = actionDescription;
                this.actionService
                    .getActionOutputs(action)
                    .pipe(untilDestroyed(this))
                    .subscribe(function (result) {
                    _this.outputs = result.outputs || [];
                    _this.arrayOutput = isSet(result.arrayOutput) ? result.arrayOutput : false;
                    _this.cd.markForCheck();
                });
            }
            else if (this.trigger instanceof WebhookAutomationTrigger) {
                this.outputs = this.webhook && isSet(this.webhook.dataStructure) ? this.webhook.dataStructure : [];
                this.arrayOutput =
                    this.webhook && isSet(this.webhook.dataStructureArray) ? this.webhook.dataStructureArray : false;
                this.cd.markForCheck();
            }
        }
        else {
            this.outputs = [];
            this.arrayOutput = false;
            this.cd.markForCheck();
        }
    };
    CustomizeWorkflowTriggerComponent.prototype.updateParameterValue = function (name, value) {
        this.contextElement.setOutputValue(name, value);
        this.form.controls.test_parameters.patchValue(this.contextElement.outputsValue);
    };
    CustomizeWorkflowTriggerComponent.prototype.submit = function (value) {
        if (!value) {
            value = this.form.submit();
        }
        this.workflowChange.emit(value);
    };
    CustomizeWorkflowTriggerComponent.prototype.close = function () {
        this.customizeBarContext.popSettingsComponent();
    };
    return CustomizeWorkflowTriggerComponent;
}());
export { CustomizeWorkflowTriggerComponent };
