import pickBy from 'lodash/pickBy';

import { ActionItem, ViewSettingsAction } from '@modules/actions';
import { ListModelDescriptionDataSource } from '@modules/data-sources';
import { Input, ParameterField } from '@modules/fields';
import { ListLayoutType } from '@modules/layouts';
import { ListModelDescriptionQuery, ModelDescriptionQuery } from '@modules/queries';

import { migrateModelDescriptionDataSource } from '../utils/migration';
import { FieldActions } from './field-actions';
import { FieldName } from './field-name';
import { ListDefaultSelection } from './list-default-selection';
import { ListGroup } from './list-group';
import { ListLayoutSettings } from './list-layout-settings';
import { TextStyle } from './text-style';

export class TableSettings implements ListLayoutSettings {
  type = ListLayoutType.Table;
  header = true;
  titleInput: Input;
  titleStyle: TextStyle;
  dataSource: ListModelDescriptionDataSource;
  actions: ViewSettingsAction[] = [];
  rowClickAction: ActionItem;
  rowActions: ViewSettingsAction[] = [];
  modelActions: ViewSettingsAction[] = [];
  columnActions: FieldActions[] = [];
  searchEnabled = true;
  searchResource: string;
  searchQuery: ListModelDescriptionQuery;
  searchLive = true;
  searchParameters: ParameterField[] = [];
  searchInputs: Input[] = [];
  updateResource: string;
  updateQuery: ModelDescriptionQuery;
  sortingField: string;
  sortingAsc = true;
  editingEnabled = false;
  editingFields: FieldName[] = [];
  displayFilters = true;
  filterFields: FieldName[] = [];
  displayFooter = true;
  groupField: string;
  groupTitleStyle: TextStyle;
  groupCollapse = true;
  groupCounter = true;
  groups: ListGroup[] = [];
  perPage = 10;
  defaultSelection: ListDefaultSelection;
  tooltip: string;

  deserialize(data: Object): TableSettings {
    this.searchEnabled = data['search_enabled'];
    this.searchResource = data['search_resource'];
    this.updateResource = data['update_resource'];
    this.sortingField = data['sorting_field'];
    this.groupField = data['group_field'];
    this.defaultSelection = data['default_selection'];
    this.tooltip = data['tooltip'];

    if (data['title_input']) {
      this.titleInput = new Input().deserialize(data['title_input']);
    } else if (data['title']) {
      // Backward compatibility
      this.titleInput = new Input().deserializeFromStatic('value', data['title']);
    }

    if (data['title_style']) {
      this.titleStyle = new TextStyle().deserialize(data['title_style']);
    } else {
      this.titleStyle = undefined;
    }

    if (data['data_source']) {
      this.dataSource = new ListModelDescriptionDataSource().deserialize(data['data_source']);
    } else if (data['resource']) {
      // Backward compatibility
      this.dataSource = migrateModelDescriptionDataSource(ListModelDescriptionDataSource, ListModelDescriptionQuery, {
        resource: data['resource'],
        query: data['get_query'],
        parameters: data['parameters'],
        inputs: data['inputs'],
        columns: data['columns']
      });
    }

    if (data['display_filters'] !== undefined) {
      this.displayFilters = data['display_filters'];
    }

    if (data['filter_fields']) {
      this.filterFields = data['filter_fields'].map(item => {
        return {
          name: item['name']
        };
      });
    }

    if (data['display_footer'] !== undefined) {
      this.displayFooter = data['display_footer'];
    }

    if (data['header'] != undefined) {
      this.header = data['header'];
    }

    if (data['search_query']) {
      this.searchQuery = new ListModelDescriptionQuery().deserialize(data['search_query']);
    }

    if (data['search_live'] !== undefined) {
      this.searchLive = data['search_live'];
    }

    if (data['search_parameters']) {
      this.searchParameters = data['search_parameters'].map(item => new ParameterField().deserialize(item));
    }

    if (data['search_inputs']) {
      this.searchInputs = data['search_inputs'].map(item => new Input().deserialize(item));
    }

    if (data['update_query']) {
      this.updateQuery = new ModelDescriptionQuery().deserialize(data['update_query']);
    }

    if (data['row_click_action']) {
      this.rowClickAction = new ActionItem().deserialize(data['row_click_action']);
    }

    if (data['actions']) {
      this.actions = data['actions'].map(item => new ViewSettingsAction().deserialize(item));
    }

    if (data['row_actions']) {
      this.rowActions = data['row_actions'].map(item => new ViewSettingsAction().deserialize(item));
    }

    if (data['model_actions']) {
      this.modelActions = data['model_actions'].map(item => new ViewSettingsAction().deserialize(item));
    }

    if (data['column_actions']) {
      this.columnActions = data['column_actions']
        .filter(item => item['actions'])
        .map(item => {
          return {
            name: item['name'],
            actions: item['actions'].map(subItem => new ViewSettingsAction().deserialize(subItem))
          };
        });
    } else if (data['data_source'] && data['data_source']['columns']) {
      // Backward compatibility
      this.columnActions = data['data_source']['columns']
        .filter(item => item['action'])
        .map(item => {
          return {
            name: item['name'],
            actions: [new ViewSettingsAction().deserialize(item['action'])]
          };
        });
    }

    if (data['sorting_asc'] != undefined) {
      this.sortingAsc = data['sorting_asc'];
    }

    if (data['editing_enabled'] != undefined) {
      this.editingEnabled = data['editing_enabled'];
    }

    if (data['editing_fields']) {
      this.editingFields = data['editing_fields'].map(item => {
        return {
          name: item['name']
        };
      });
    }

    if (data['group_title_style']) {
      this.groupTitleStyle = new TextStyle().deserialize(data['group_title_style']);
    } else {
      this.groupTitleStyle = undefined;
    }

    if (data['group_collapse'] != undefined) {
      this.groupCollapse = data['group_collapse'];
    }

    if (data['group_counter'] != undefined) {
      this.groupCounter = data['group_counter'];
    }

    if (data['groups']) {
      this.groups = data['groups'].map(item => new ListGroup().deserialize(item));
    }

    if (data['per_page'] != undefined) {
      this.perPage = data['per_page'];
    }

    return this;
  }

  serialize(fields?: string[]): Object {
    let data: Object = {
      type: this.type,
      header: this.header,
      title_input: this.titleInput ? this.titleInput.serialize() : null,
      title_style: this.titleStyle ? this.titleStyle.serialize() : undefined,
      data_source: this.dataSource ? this.dataSource.serialize() : undefined,
      search_enabled: this.searchEnabled,
      search_resource: this.searchResource,
      search_query: this.searchQuery ? this.searchQuery.serialize() : undefined,
      search_live: this.searchLive,
      search_parameters: this.searchParameters.map(item => item.serialize()),
      search_inputs: this.searchInputs.map(item => item.serialize()),
      update_resource: this.updateResource,
      update_query: this.updateQuery ? this.updateQuery.serialize() : undefined,
      row_click_action: this.rowClickAction ? this.rowClickAction.serialize() : undefined,
      actions: this.actions.map(item => item.serialize()),
      row_actions: this.rowActions.map(item => item.serialize()),
      model_actions: this.modelActions.map(item => item.serialize()),
      column_actions: this.columnActions.map(item => {
        return {
          name: item.name,
          actions: item.actions.map(action => action.serialize())
        };
      }),
      sorting_field: this.sortingField,
      sorting_asc: this.sortingAsc,
      editing_enabled: this.editingEnabled,
      editing_fields: this.editingFields.map(item => {
        return {
          name: item.name
        };
      }),
      per_page: this.perPage,
      default_selection: this.defaultSelection,
      display_filters: this.displayFilters,
      filter_fields: this.filterFields.map(item => {
        return {
          name: item.name
        };
      }),
      display_footer: this.displayFooter,
      group_field: this.groupField,
      group_title_style: this.groupTitleStyle ? this.groupTitleStyle.serialize() : undefined,
      group_collapse: this.groupCollapse,
      group_counter: this.groupCounter,
      groups: this.groups.map(item => item.serialize()),
      tooltip: this.tooltip
    };
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }

  get analyticsName(): string {
    return 'table';
  }

  get analyticsGenericName(): string {
    if (!this.analyticsName) {
      return;
    }
    return ['component', this.analyticsName].join('_');
  }
}
