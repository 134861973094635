import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { Font, getFontFamily, getFontUrl } from '@modules/theme';
import { isSet, TypedChanges } from '@shared';

@Component({
  selector: 'app-font-menu-item',
  templateUrl: './font-menu-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FontMenuItemComponent implements OnChanges {
  @Input() font: Font;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() active = false;
  @Input() visible = true;

  fontFamily: string;
  googleFontsUrl: string;
  fontUrl: SafeResourceUrl;
  titleStub: string;
  visibleOrWasVisible = false;
  fontLoaded = false;

  constructor(private sanitizer: DomSanitizer, private cd: ChangeDetectorRef) {}

  ngOnChanges(changes: TypedChanges<FontMenuItemComponent>): void {
    if (changes.font) {
      this.fontFamily = getFontFamily(this.font);
      this.googleFontsUrl = this.getGoogleFontsUrl();
      this.fontUrl = this.getFontUrl();
      this.titleStub = this.getFontNameStub();
      this.fontLoaded = !isSet(this.fontUrl);
    }

    if (changes.visible && this.visible) {
      this.visibleOrWasVisible = true;
    }
  }

  getGoogleFontsUrl(): string {
    if (!isSet(this.font.googleFontsName)) {
      return;
    }

    return 'https://fonts.google.com/specimen/' + this.font.googleFontsName.replace(/\s/g, '+');
  }

  getFontUrl() {
    const fontUrl = getFontUrl(this.font, this.font.name);
    return fontUrl ? this.sanitizer.bypassSecurityTrustResourceUrl(fontUrl) : undefined;
  }

  getFontNameStub(): string {
    return this.title
      .split(' ')
      .slice(0, 2)
      .map(item => item.substr(0, 8))
      .join(' ');
  }

  onFontLoaded() {
    this.fontLoaded = true;
    this.cd.markForCheck();
  }
}
