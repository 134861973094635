import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable, of } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';

import { ProjectSettingsStore } from '@modules/all-project-settings';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { CustomizeHandler, CustomizeService } from '@modules/customize';
import { FeatureService } from '@modules/features';
import { MenuBlockLayouts } from '@modules/menu';
import { CurrentProjectStore } from '@modules/projects';
import { ThemeService } from '@modules/theme';
import { controlValue, deployUrl } from '@shared';

import { MenuBlockControl } from '../project-settings/menu-block.control';
import { AppearanceState, ProjectAppearanceContext } from '../project-settings/project-appearance.context';
import { ThemeTemplate } from '../theme-gallery/theme-template';

@Component({
  selector: 'app-customize-bar-appearance-edit',
  templateUrl: './customize-bar-appearance-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeBarAppearanceEditComponent implements OnInit, OnDestroy, CustomizeHandler<AppearanceState> {
  @Input() context: ProjectAppearanceContext;

  loading = true;
  menuBlocks: { label: string; controls: MenuBlockControl[] }[] = [];
  analyticsEvents = AnalyticsEvent;

  stateIsSetting = false;
  hasChanges$: Observable<boolean>;
  submitLoading = false;

  constructor(
    public currentProjectStore: CurrentProjectStore,
    public themeService: ThemeService,
    private projectSettingsStore: ProjectSettingsStore,
    private customizeService: CustomizeService,
    private featureService: FeatureService,
    private analyticsService: UniversalAnalyticsService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.customizeService.setHandler(this);
    this.customizeService.setHandlerInfo(this, {
      breadcrumbs: [],
      inAppDisabled: true
    });

    this.hasChanges$ = this.context.getHasChanges$();

    this.projectSettingsStore
      .getAllSettingsFirst$()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.loading = false;
        this.cd.markForCheck();

        this.context.init();

        this.context.valueChanges
          .pipe(
            filter(() => !this.stateIsSetting),
            debounceTime(200),
            untilDestroyed(this)
          )
          .subscribe(() => this.customizeService.markChanged());

        this.customizeService.startTrackChanges();
      });

    controlValue(this.context.controls.menu.controls.blocks, { debounce: 60 })
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        const menuBlocks = this.context.controls.menu.controls.blocks.controls.filter(
          item => item.controls.enabled.value
        );
        this.menuBlocks = [
          {
            label: 'left',
            controls: menuBlocks.filter(item => MenuBlockLayouts.isLeft(item.controls.layout.value))
          },
          {
            label: 'top',
            controls: menuBlocks.filter(item => MenuBlockLayouts.isTop(item.controls.layout.value))
          }
        ].filter(item => item.controls.length);
        this.cd.markForCheck();
      });
  }

  ngOnDestroy(): void {
    this.customizeService.unsetHandler(this);

    if (this.context.getHasChanges()) {
      this.context.resetSavedState();
    }
  }

  getChangesState(): AppearanceState {
    return this.context.getState();
  }

  setChangesState(state: AppearanceState) {
    this.stateIsSetting = true;
    this.context.setState(state);
    this.stateIsSetting = false;
  }

  isChangesStateEqual(lhs: AppearanceState, rhs: AppearanceState): boolean {
    return this.context.isStateEqual(lhs, rhs);
  }

  saveChangesState(state: AppearanceState): Observable<AppearanceState> {
    return of(state);
  }

  getWidthImagePath(image: string): string {
    if (this.themeService.theme == 'dark') {
      return `url(${deployUrl('/assets/images/widgets/' + image + '_dark.svg')})`;
    } else {
      return `url(${deployUrl('/assets/images/widgets/' + image + '.svg')})`;
    }
  }

  cancel() {
    this.customizeService.stopTrackChanges();
    this.context.resetSavedState();
    this.customizeService.startTrackChanges();
  }

  submit() {
    if (this.submitLoading) {
      return;
    }

    this.submitLoading = true;
    this.cd.markForCheck();

    this.context
      .submit()
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          this.customizeService.stopTrackChanges();
          this.context.saveCurrentState();
          this.customizeService.startTrackChanges();
          this.submitLoading = false;
          this.cd.markForCheck();

          this.analyticsService.sendSimpleEvent(AnalyticsEvent.Appearance.AppearanceSaved, {
            Section: 'root',
            ProjectSettingsChanges: result.changes.projectSettingsChanges,
            MenuSettingsChanged: result.changes.menuSettings
          });
        },
        () => {
          this.submitLoading = false;
          this.cd.markForCheck();
        }
      );
  }

  onThemeApply(theme: ThemeTemplate) {
    this.analyticsService.sendSimpleEvent(AnalyticsEvent.Appearance.ThemeSelected, {
      Name: theme.name,
      Dark: this.themeService.isDarkTheme
    });
  }

  onStylesFeatureClick() {
    if (!this.currentProjectStore.instance.features.isStylesEnabled()) {
      this.featureService.showFeatureOverview({
        subtitle: 'Paid Feature',
        title: 'Customize <strong>App Styles</strong>',
        description: `
          You can customize various App styles, including background, text, colors, borders, shadows, and more,
          giving you greater flexibility to design your App to match your brand's aesthetics.
        `
      });
    }
  }
}
