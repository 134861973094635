<form [formGroup]="form" (ngSubmit)="submit()">
  <app-customize-bar-header
    [title]="form.controls.title.value || (titleEditable ? 'accordion' : 'Accordion')"
    [titleEnabled]="titleEnabled"
    [titleEditable]="titleEditable"
    [titleCleanValue]="titleCleanValue"
    [backLabel]="'All components'"
    [deleteEnabled]="deleteEnabled"
    [parentLabel]="parentElement?.displayName || parentPopup?.displayName"
    (parentClick)="parentElement?.customize({ event: $event }) || parentPopup?.customize({ event: $event })"
    (deleteClick)="delete()"
    (titleChanged)="onTitleChanged($event)"
  >
  </app-customize-bar-header>

  <div class="sidebar__content">
    <app-sidebar-tabs>
      <app-sidebar-tab [label]="'General'">
        <div class="sidebar__list">
          <div
            cdkDropList
            [cdkDropListLockAxis]="'y'"
            class="sidebar-list"
            (cdkDropListDropped)="dragDrop($event)"
            #list_element
          >
            <div
              *ngFor="let control of form.controls.items.controls; let i = index"
              [formGroup]="control"
              cdkDrag
              class="sidebar-list__item"
            >
              <app-customize-bar-accordion-edit-item
                [control]="control"
                [context]="context"
                [contextElement]="contextElement"
                [collapseContext]="collapseContext"
                [openedInitial]="control === createdItem"
                [removeEnabled]="form.controls.items.controls.length > 1"
                [analyticsSource]="element.analyticsGenericName + '_item'"
                [appApplyDragPredicate]="{ handleSelector: '.sidebar-collapse__header', dispatchElement: list_element }"
                (remove)="removeItem(control, i)"
              ></app-customize-bar-accordion-edit-item>
            </div>

            <div *ngIf="!form.controls.items.controls.length" class="sidebar-list__item">
              <div class="sidebar-list-item sidebar-list-item_clickable sidebar-list-item_disabled" (click)="addItem()">
                <div class="sidebar-list-item__drag"></div>
                <div class="sidebar-list-item__main">
                  <div class="sidebar-list-item__title">Section</div>
                  <div class="sidebar-list-item__description">Not specified</div>
                </div>
              </div>
            </div>

            <div class="sidebar-list__buttons">
              <a
                href="javascript:void(0)"
                class="button button_orange-transparent button_small sidebar-list__buttons-item"
                (click)="addItem()"
              >
                <span class="icon icon-plus button__icon button__icon_left"></span>
                <span class="button__label">Add Section</span>
              </a>

              <app-field-errors
                *ngIf="!form.controls.items?.length"
                [form]="form"
                [fieldName]="'items'"
              ></app-field-errors>
            </div>
          </div>
        </div>

        <div class="sidebar__element">
          <app-sidebar-field [label]="'Allow opened sections'">
            <div class="sidebar-icon-buttons sidebar-icon-buttons_border">
              <div
                class="sidebar-icon-button"
                [class.sidebar-icon-button_active]="!form.controls.multiple_opened.value"
                (click)="form.controls.multiple_opened.setValue(false)"
              >
                <div class="sidebar-icon-button__label">Single</div>
              </div>

              <div
                class="sidebar-icon-button"
                [class.sidebar-icon-button_active]="form.controls.multiple_opened.value"
                (click)="form.controls.multiple_opened.setValue(true)"
              >
                <div class="sidebar-icon-button__label">Multiple</div>
              </div>
            </div>
          </app-sidebar-field>
        </div>

        <div class="sidebar__element">
          <app-sidebar-field [label]="'opened/closed indicator'">
            <div class="sidebar-icon-buttons sidebar-icon-buttons_border">
              <div
                *ngFor="let item of form.collapseIndicatorPositionOptions"
                class="sidebar-icon-button"
                [class.sidebar-icon-button_active]="form.controls.collapse_indicator_position.value == item.value"
                (click)="form.controls.collapse_indicator_position.setValue(item.value)"
              >
                <div class="sidebar-icon-button__label">{{ item.name }}</div>
              </div>
            </div>
          </app-sidebar-field>
        </div>
      </app-sidebar-tab>

      <app-sidebar-tab [label]="'Display'">
        <div class="sidebar__element">
          <app-sidebar-field
            [label]="'Conditional Visibility'"
            [documentation]="'conditional-visibility'"
            [documentationLabel]="'See how it works'"
            [documentationSource]="element.analyticsGenericName + '_visible'"
          >
            <app-input-edit
              [itemForm]="form.controls.visible_input"
              [context]="context"
              [staticValueDisabled]="true"
              [placeholder]="'Value'"
              [fill]="true"
              [analyticsSource]="element.analyticsGenericName + '_visible'"
            ></app-input-edit>

            <ng-container description>
              Example: elements.field.value >= 10
            </ng-container>
          </app-sidebar-field>
        </div>

        <div class="sidebar__element">
          <app-styles-accordion-element
            [control]="form"
            [context]="context"
            [contextElement]="contextElement"
          ></app-styles-accordion-element>
        </div>
      </app-sidebar-tab>
    </app-sidebar-tabs>
  </div>

  <!--  <app-sidebar-submit-->
  <!--    [saveDisabled]="form.form.invalid || form.form.disabled"-->
  <!--    [errors]="form.form | appFormErrors"-->
  <!--    (cancelClick)="cancel()"-->
  <!--    (deleteClick)="delete()"-->
  <!--  >-->
  <!--  </app-sidebar-submit>-->
</form>
