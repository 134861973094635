<div
  class="checkbox"
  [class.checkbox_checked]="displayValue"
  [class.checkbox_disabled]="control?.disabled || disabled"
  [class.checkbox_round]="round"
  [class.checkbox_theme]="theme"
  (click)="toggle()"
>
  <div class="checkbox__background"></div>
  <div *ngIf="square" class="checkbox__square"></div>
  <div *ngIf="!square && dash" class="checkbox__dash"></div>
  <div *ngIf="!square && !dash" class="checkbox__tick"></div>
</div>
