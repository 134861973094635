var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Task } from '../task';
import { TaskCompleteAction, TaskScenario } from '../task-scenario';
var CreateGroupTask = /** @class */ (function (_super) {
    __extends(CreateGroupTask, _super);
    function CreateGroupTask() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CreateGroupTask.prototype.init = function () {
        return new TaskScenario([
            {
                link: ['project', 'teams'],
                items: [
                    {
                        name: 'click_create_group',
                        tip: {
                            text: 'Create a new Team with particular permissions',
                            selector: '#create-group-page-button',
                            completeAction: TaskCompleteAction.Click
                        }
                    }
                ]
            },
            {
                link: ['project', 'teams', 'create'],
                items: [
                    {
                        name: 'enter_name',
                        tip: {
                            text: 'Enter Team name',
                            selector: '[id^=name]',
                            completeAction: TaskCompleteAction.Input
                        }
                    },
                    {
                        name: 'set_permissions',
                        tip: {
                            text: 'Set some Project and Collection permissions for new Team',
                            selector: '#group-permissions'
                        }
                    },
                    {
                        name: 'create_group',
                        tip: {
                            text: 'Create new Team',
                            selector: '[type="submit"]',
                            completeAction: TaskCompleteAction.Click
                        }
                    }
                ]
            },
            {
                link: ['project', 'teams'],
                noAutoRedirect: true,
                items: [
                    {
                        name: 'go_to_users',
                        tip: {
                            text: 'Go to Users',
                            selector: '#project-menu-users',
                            completeAction: TaskCompleteAction.Click
                        }
                    }
                ]
            },
            {
                link: ['project', 'users'],
                items: [
                    {
                        name: 'select_user',
                        tip: {
                            text: 'Select user to assign new Team',
                            selector: '#project-users'
                        }
                    }
                ]
            },
            {
                link: ['project', 'users', '\\d+'],
                noAutoRedirect: true,
                items: [
                    {
                        name: 'select_group',
                        tip: {
                            text: 'Choose newly created Team',
                            selector: '#user-group-selector'
                        }
                    },
                    {
                        name: 'save_user',
                        tip: {
                            text: 'Save user',
                            selector: '[type="submit"]',
                            completeAction: TaskCompleteAction.Click
                        }
                    },
                    {
                        name: 'finished'
                    }
                ]
            }
        ]);
    };
    return CreateGroupTask;
}(Task));
export { CreateGroupTask };
