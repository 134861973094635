var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, EventEmitter, Injector, OnDestroy, OnInit } from '@angular/core';
import clone from 'lodash/clone';
import groupBy from 'lodash/groupBy';
import values from 'lodash/values';
import * as moment from 'moment';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { CalendarSettings, getModelAttributesByColumns, getModelBulkAttributesByColumns, ViewContext, ViewContextElement } from '@modules/customize';
import { CHECKED_ITEMS_OUTPUT, EMPTY_OUTPUT, HAS_SELECTED_ITEM_OUTPUT, ITEM_OUTPUT, listItemEquals, NO_SELECTED_ITEM_OUTPUT, SELECTED_ITEM_OUTPUT } from '@modules/list';
import { ModelDescription } from '@modules/models';
var CalendarBaseComponent = /** @class */ (function () {
    function CalendarBaseComponent(injector, actionControllerService, listStore, notificationService, cd) {
        this.injector = injector;
        this.actionControllerService = actionControllerService;
        this.listStore = listStore;
        this.notificationService = notificationService;
        this.cd = cd;
        this.theme = false;
        this.queryParamsChanged = new EventEmitter();
        this.loading = false;
        this.items = {};
        this.checkedItems = {};
        this.visibleColumns = [];
    }
    CalendarBaseComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.listStore.items$.pipe(untilDestroyed(this)).subscribe(function (items) {
            _this.items = items
                ? groupBy(items, function (item) {
                    return moment(item.model.getAttribute(_this.listState.settings.dateField)).format(_this.itemsGroupBy());
                })
                : {};
            _this.cd.markForCheck();
        });
        this.fetch(this.listState);
        this.onStateUpdated(this.listState, {});
    };
    CalendarBaseComponent.prototype.ngOnDestroy = function () { };
    CalendarBaseComponent.prototype.updateVisibleColumns = function (state) {
        this.visibleColumns = state.dataSource.columns.filter(function (item) { return item.visible; });
        this.cd.markForCheck();
    };
    CalendarBaseComponent.prototype.updateStoreColumns = function (state) {
        if (this.listStore.dataSource) {
            this.listStore.dataSource.columns = state.dataSource ? state.dataSource.columns : [];
            this.listStore.deserializeModelAttributes();
        }
    };
    CalendarBaseComponent.prototype.onStateUpdated = function (state, prevState) { };
    CalendarBaseComponent.prototype.groupItems = function (items) {
        var _this = this;
        this.items = items
            ? groupBy(items, function (item) { return moment(item.getAttribute(_this.listState.settings.dateField)).format(_this.itemsGroupBy()); })
            : {};
    };
    CalendarBaseComponent.prototype.resetItems = function () {
        if (this.fetchSubscription) {
            this.fetchSubscription.unsubscribe();
            this.fetchSubscription = undefined;
        }
        this.items = {};
        this.loading = false;
        this.cd.markForCheck();
        this.listStore.reset();
    };
    CalendarBaseComponent.prototype.getItems = function () {
        var _this = this;
        this.resetItems();
        // if (!this.selectedItem) {
        //   this.contextElement.patchOutputValueMeta(SELECTED_ITEM_OUTPUT, { loading: true });
        // }
        this.loading = true;
        this.cd.markForCheck();
        this.fetchSubscription = this.listStore.getAll().subscribe(function (result) {
            _this.loading = false;
            _this.cd.markForCheck();
            _this.contextElement.setOutputValue(EMPTY_OUTPUT, result ? !result.length : false, { loading: false });
            // if (!this.selectedItem) {
            //   this.contextElement.patchOutputValueMeta(SELECTED_ITEM_OUTPUT, { loading: false });
            // }
        }, function () {
            _this.loading = false;
            _this.cd.markForCheck();
            _this.contextElement.setOutputValue(EMPTY_OUTPUT, false, { loading: false, error: true });
            // if (!this.selectedItem) {
            //   this.contextElement.patchOutputValueMeta(SELECTED_ITEM_OUTPUT, { loading: false });
            // }
        });
    };
    CalendarBaseComponent.prototype.applyParams = function (params) {
        if (params === void 0) { params = {}; }
        //   return {
        //     ...applyParamInputs(this.params, this.settings.inputs, {
        //       context: this.contextElement.context,
        //       contextElement: this.contextElement,
        //       parameters: this.settings.parameters
        //     }),
        //     ...params
        //   };
        return params;
    };
    CalendarBaseComponent.prototype.setParams = function (params) {
        // this.queryParamsChanged.emit(this.applyParams(params));
        this.queryParamsChanged.emit(__assign({}, this.params, params));
    };
    CalendarBaseComponent.prototype.updateSelectedContext = function () {
        var columns = this.settings.dataSource ? this.settings.dataSource.columns : [];
        if (this.selectedItem) {
            this.contextElement.setOutputValue(SELECTED_ITEM_OUTPUT, getModelAttributesByColumns(this.selectedItem.model, columns));
            this.contextElement.setOutputValue(HAS_SELECTED_ITEM_OUTPUT, true);
            this.contextElement.setOutputValue(NO_SELECTED_ITEM_OUTPUT, false);
        }
        else {
            this.contextElement.setOutputValue(SELECTED_ITEM_OUTPUT, undefined);
            this.contextElement.setOutputValue(HAS_SELECTED_ITEM_OUTPUT, false);
            this.contextElement.setOutputValue(NO_SELECTED_ITEM_OUTPUT, true);
        }
        if (this.settings.multipleSelection) {
            var models = values(this.checkedItems);
            this.contextElement.setOutputValue(CHECKED_ITEMS_OUTPUT, getModelBulkAttributesByColumns(models, columns));
        }
        else {
            this.contextElement.setOutputValue(CHECKED_ITEMS_OUTPUT, getModelBulkAttributesByColumns([], columns));
        }
    };
    CalendarBaseComponent.prototype.isItemSelected = function (item, index) {
        if (this.settings.multipleSelection) {
            return this.isItemChecked(item, index);
        }
        else {
            return this.itemEquals(this.selectedItem, item);
        }
    };
    CalendarBaseComponent.prototype.isItemChecked = function (item, index) {
        var pk = item.model.primaryKey || "" + index;
        return this.checkedItems[pk];
    };
    CalendarBaseComponent.prototype.setSelectedItem = function (item, updateContext) {
        if (updateContext === void 0) { updateContext = true; }
        this.selectedItem = item;
        this.cd.markForCheck();
        if (updateContext) {
            this.updateSelectedContext();
        }
    };
    CalendarBaseComponent.prototype.setChecked = function (value, updateContext) {
        if (updateContext === void 0) { updateContext = true; }
        this.checkedItems = value.reduce(function (acc, item) {
            var pk = item.model.primaryKey;
            acc[pk] = item.model;
            return acc;
        }, {});
        this.cd.markForCheck();
        if (updateContext) {
            this.updateSelectedContext();
        }
    };
    CalendarBaseComponent.prototype.toggleSelectedItem = function (item, index, element, click) {
        if (click === void 0) { click = false; }
        var _a;
        if (this.selectedItem === item) {
            this.setSelectedItem(undefined, false);
        }
        else {
            this.setSelectedItem(item, false);
        }
        if (this.settings.multipleSelection) {
            var pk = item.model.primaryKey || index;
            var checked = this.isItemChecked(item, index);
            if (!checked) {
                var checkedItems = clone(this.checkedItems);
                checkedItems[pk] = item.model;
                this.checkedItems = checkedItems;
            }
            else {
                var checkedItems = clone(this.checkedItems);
                delete checkedItems[pk];
                this.checkedItems = checkedItems;
            }
        }
        this.updateSelectedContext();
        if (click && this.settings.cardClickAction && this.selectedItem) {
            this.actionControllerService
                .execute(this.settings.cardClickAction, {
                context: this.contextElement.context,
                contextElement: this.contextElement,
                localContext: (_a = {},
                    _a[ITEM_OUTPUT] = this.selectedItem.model.getAttributes(),
                    _a),
                injector: this.injector,
                origin: element
            })
                .subscribe();
        }
    };
    CalendarBaseComponent.prototype.onModelUpdated = function (model) {
        if (this.selectedItem && this.selectedItem.model.isSame(model)) {
            this.updateSelectedContext();
        }
        var checkedModels = values(this.checkedItems);
        if (checkedModels.some(function (item) { return item.isSame(model); })) {
            this.updateSelectedContext();
        }
    };
    CalendarBaseComponent.prototype.itemEquals = function (lhs, rhs) {
        return listItemEquals(lhs, rhs);
    };
    CalendarBaseComponent.prototype.getAnyModel = function () {
        if (!this.listStore.items || !this.listStore.items.length) {
            return;
        }
        return this.listStore.items[0].model;
    };
    return CalendarBaseComponent;
}());
export { CalendarBaseComponent };
