<div *ngIf="visible || (customizeEnabled$ | async)" class="text-block text-block_no-margin-collapse">
  <div *ngIf="(mode$ | async) == adminModes.App" class="ql-container ql-snow ql-text-paragraphs ql-theme">
    <div class="ql-editor" [innerHTML]="html$ | async"></div>
  </div>

  <app-text-editor
    *ngIf="(mode$ | async) == adminModes.Builder"
    [value]="element.quillDelta"
    [context]="context"
    [editable]="isEditable"
    [textParagraphs]="true"
    [toolbarElement]="toolbarElement"
    [toolbarTextColorEnabled]="true"
    [toolbarWrap]="false"
    [schema]="(themeService.isDarkTheme$ | async) ? 'light' : 'dark'"
    [theme]="true"
    [analyticsSource]="element.analyticsGenericName"
    (changed)="save($event)"
  ></app-text-editor>
</div>
