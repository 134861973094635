var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Injector } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { catchError, debounceTime, delayWhen, map, switchMap, tap } from 'rxjs/operators';
import { NotificationService } from '@common/notifications';
import { ActionService } from '@modules/action-queries';
import { ServerRequestError } from '@modules/api';
import { AggregateFunc, DatasetGroupLookup } from '@modules/charts';
import { CustomView, CustomViewService, CustomViewsStore, CustomViewType } from '@modules/custom-views';
import { ListDefaultSelection } from '@modules/customize';
import { ElementConfigurationService } from '@modules/customize-configuration';
import { WidgetQueryService } from '@modules/dashboard-queries';
import { DataSourceType } from '@modules/data-sources';
import { applyParamInputs, Input, Input as FieldInput } from '@modules/fields';
import { ModelOptionsSource } from '@modules/filters-components';
import { ModelDescriptionStore, ModelService } from '@modules/model-queries';
import { FieldInputControl, modelDescriptionHasAutoParameters } from '@modules/parameters';
import { CurrentEnvironmentStore, CurrentProjectStore, ResourceType } from '@modules/projects';
import { ChartWidgetQuery, QueryType } from '@modules/queries';
import { ResourceControllerService } from '@modules/resources';
import { controlValue, generateAlphanumeric, isSet } from '@shared';
import { ActionOutputFormGroup } from '../../../forms/action-output.form-group';
import { FieldActionsArray } from '../../display-fields-edit/field-actions.array';
import { ListModelDescriptionDataSourceControl } from '../../model-description-data-source-edit/list-model-description-data-source';
import { OptionEnabledArray } from '../../option-enabled-edit/option-enabled.array';
import { KanbanBoardListElementStylesControl } from '../../styles-kanban-board-list-element-edit/kanban-board-list-element-styles.control';
import { CustomizeBarListLayoutSettingsForm, validateAction, validateActions } from '../customize-bar-list-layout-settings/customize-bar-list-layout-settings.form';
import { KanbanStageArray } from './kanban-stage.array';
var CustomizeBarKanbanBoardSettingsForm = /** @class */ (function (_super) {
    __extends(CustomizeBarKanbanBoardSettingsForm, _super);
    function CustomizeBarKanbanBoardSettingsForm(widgetQueryService, modelService, modelOptionsSource, customViewService, customViewsStore, notificationService, injector, currentProjectStore, currentEnvironmentStore, resourceControllerService, modelDescriptionStore, actionService, elementConfigurationService, dataSourceControl) {
        var _this = _super.call(this, currentProjectStore, currentEnvironmentStore, resourceControllerService, modelDescriptionStore, actionService, elementConfigurationService, {
            header: new FormControl(true),
            title: new FieldInputControl({ path: ['value'] }),
            data_source: dataSourceControl,
            card_view_unique_name: new FormControl(null),
            card_view: new FormControl(null),
            card_view_mappings: new FormControl([]),
            search_enabled: new FormControl(true),
            search_live: new FormControl(true),
            stage_field: new FormControl('', Validators.required),
            stages: new KanbanStageArray([]),
            ordering_field: new FormControl(''),
            card_click_action: new FormControl(undefined, undefined, validateAction),
            card_column_change_action: new FormControl(undefined, undefined, validateAction),
            card_order_change_action: new FormControl(undefined, undefined, validateAction),
            actions: new FormControl([], undefined, validateActions),
            model_actions: new FormControl([], undefined, validateActions),
            columns_actions: new FieldActionsArray([]),
            custom_actions: new ActionOutputFormGroup(elementConfigurationService),
            sorting_field: new FormControl(undefined),
            sorting_asc: new FormControl(true),
            per_page: new FormControl(undefined),
            multiple_selection: new FormControl(false),
            first_item_default_selection: new FormControl(false),
            display_filters: new FormControl(true),
            filter_fields: new OptionEnabledArray([]),
            visible_input: new FieldInputControl({ path: ['value'] }),
            name: new FormControl(''),
            tooltip: new FormControl(''),
            gap_horizontal: new FormControl(null),
            gap_vertical: new FormControl(null),
            comments_enabled: new FormControl(false),
            comments_object_type: new FieldInputControl({ path: ['value'] }),
            comments_object_id: new FieldInputControl({ path: ['value'] }),
            element_styles: new KanbanBoardListElementStylesControl(injector)
        }) || this;
        _this.widgetQueryService = widgetQueryService;
        _this.modelService = modelService;
        _this.modelOptionsSource = modelOptionsSource;
        _this.customViewService = customViewService;
        _this.customViewsStore = customViewsStore;
        _this.notificationService = notificationService;
        _this.injector = injector;
        _this.ignoreSubmitControls = [_this.controls.card_view];
        _this.submitLoading$ = new BehaviorSubject(false);
        dataSourceControl.setRequired(true);
        return _this;
    }
    CustomizeBarKanbanBoardSettingsForm.prototype.init = function (options) {
        var _this = this;
        this.settings = options.settings;
        this.pageUid = options.pageUid;
        this.elementUid = options.elementUid;
        this.options = options;
        var customView$ = isSet(options.settings.cardCustomView)
            ? this.customViewsStore.getDetailFirst(options.settings.cardCustomView)
            : of(undefined);
        return customView$.pipe(tap(function (customView) {
            var value = {
                header: options.settings.header,
                title: options.settings.titleInput ? options.settings.titleInput.serializeWithoutPath() : {},
                card_view_unique_name: options.settings.cardCustomView,
                card_view: customView ? customView.view : null,
                card_view_mappings: options.settings.cardCustomViewMappings,
                search_enabled: options.settings.searchEnabled,
                search_live: options.settings.searchLive,
                stage_field: options.settings.stageField,
                // ordering_field: modelDescription.orderingField,
                ordering_field: undefined,
                card_click_action: options.settings.cardClickAction,
                card_column_change_action: options.settings.cardColumnChangeAction,
                card_order_change_action: options.settings.cardOrderChangeAction,
                actions: options.settings.actions,
                model_actions: options.settings.modelActions,
                columns_actions: options.settings.columnActions,
                sorting_field: options.settings.sortingField || null,
                sorting_asc: options.settings.sortingAsc,
                per_page: options.settings.perPage,
                multiple_selection: options.settings.multipleSelection,
                first_item_default_selection: options.settings.defaultSelection == ListDefaultSelection.First,
                display_filters: options.settings.displayFilters,
                filter_fields: options.settings.filterFields.map(function (item) {
                    return {
                        name: item.name,
                        enabled: true
                    };
                }),
                visible_input: options.visibleInput ? options.visibleInput.serializeWithoutPath() : {},
                tooltip: options.settings.tooltip,
                gap_horizontal: options.settings.gapHorizontal,
                gap_vertical: options.settings.gapVertical,
                comments_enabled: options.settings.commentsEnabled,
                comments_object_type: options.settings.commentsObjectType
                    ? options.settings.commentsObjectType.serializeWithoutPath()
                    : {},
                comments_object_id: options.settings.commentsObjectId
                    ? options.settings.commentsObjectId.serializeWithoutPath()
                    : {}
            };
            if (options.nameEditable) {
                value['name'] = options.name;
            }
            _this.patchValue(value, { emitEvent: false });
            _this.controls.data_source.deserialize(options.settings.dataSource);
            _this.controls.custom_actions.deserialize(customView && customView.view ? customView.view.actions : [], options.settings.customActions);
            if (options.elementStylesEditable && options.elementStyles) {
                _this.controls.element_styles.deserialize(options.elementStyles);
            }
            if (options.settings.stages) {
                _this.controls.stages.deserialize(options.settings.stages);
            }
            else {
                _this.controls.stages.setControls([_this.controls.stages.createControl()]);
            }
            _this.trackChanges(options);
            if (!options.firstInit) {
                _this.markAsDirty();
            }
        }));
    };
    CustomizeBarKanbanBoardSettingsForm.prototype.trackChanges = function (options) {
        var _this = this;
        _super.prototype.trackChanges.call(this, options);
        combineLatest(controlValue(this.controls.data_source.controls.columns), this.controls.data_source.getModelDescription$(), this.controls.data_source.getModelDescription$().pipe(switchMap(function (modelDescription) {
            if (!modelDescription) {
                return of([]);
            }
            return _this.modelOptionsSource.getOptions$(modelDescription, {
                relationsEnabled: true
            });
        })))
            .pipe(debounceTime(60), untilDestroyed(this))
            .subscribe(function (_a) {
            var columns = _a[0], modelDescription = _a[1], modelOptions = _a[2];
            var columnNames = modelDescription ? modelOptions.map(function (item) { return item.name; }) : columns.map(function (item) { return item.name; });
            var modelId = modelDescription ? modelDescription.modelId : null;
            var filterFieldsSourceChanged = _this.controls.filter_fields.isSourceSet()
                ? !_this.controls.filter_fields.isSource(modelId)
                : false;
            _this.controls.filter_fields.syncControls(columnNames, { source: modelId });
            if (_this.controls.display_filters.value && _this.controls.filter_fields.isAllDisabled()) {
                _this.controls.filter_fields.enableDefault();
            }
            else if (_this.controls.display_filters.value && filterFieldsSourceChanged) {
                _this.controls.filter_fields.enableDefault();
            }
        });
        this.controls.card_view.valueChanges
            .pipe(debounceTime(60), switchMap(function (value) {
            _this.submitLoading$.next(true);
            return _this.submitCardView(_this.controls.card_view_unique_name.value, value);
        }), tap(function (uniqueName) {
            _this.controls.card_view_unique_name.patchValue(uniqueName);
            _this.submitLoading$.next(false);
        }), catchError(function (error) {
            _this.submitLoading$.next(false);
            if (error instanceof ServerRequestError && error.errors.length) {
                _this.notificationService.error('Error', error.errors[0]);
            }
            else {
                _this.notificationService.error('Error', error);
            }
            return of(undefined);
        }), untilDestroyed(this))
            .subscribe();
        this.controls.stage_field.valueChanges.subscribe(function () { return _this.controls.stages.removeControls(); });
    };
    CustomizeBarKanbanBoardSettingsForm.prototype.isGetMissingEnabled = function () {
        if (this.controls.data_source.controls.type.value != DataSourceType.Query) {
            return false;
        }
        var query = this.controls.data_source.controls.query.value;
        if (!query) {
            return false;
        }
        else if (query.queryType == QueryType.Simple && query.simpleQuery) {
            var resourceName_1 = this.controls.data_source.controls.query_resource.value;
            var resource = this.currentEnvironmentStore.resources.find(function (item) { return item.uniqueName == resourceName_1; });
            var modelDescription = this.modelDescriptionStore.instance
                ? this.modelDescriptionStore.instance.find(function (item) { return item.model == query.simpleQuery.model; })
                : undefined;
            if (!resource || !modelDescription) {
                return false;
            }
            if (resource.type == ResourceType.Airtable) {
                return resource.isSynced(modelDescription.model) || modelDescription.isSynced();
            }
            else {
                return modelDescriptionHasAutoParameters(resource, modelDescription);
            }
        }
        else if (query.queryType == QueryType.Http) {
            return query.frontendFiltering;
        }
        else if (query.queryType == QueryType.SQL) {
            return true;
        }
        else if (query.queryType == QueryType.Object) {
            return true;
        }
        else {
            return false;
        }
    };
    CustomizeBarKanbanBoardSettingsForm.prototype.getMissing = function () {
        var _this = this;
        if (!this.controls.stage_field.value) {
            return of([]);
        }
        if (!this.isGetMissingEnabled()) {
            return of([]);
        }
        var resourceName = this.controls.data_source.controls.query_resource.value;
        var resource = this.currentEnvironmentStore.resources.find(function (item) { return item.uniqueName == resourceName; });
        var modelQuery = this.controls.data_source.controls.query.value;
        var query = new ChartWidgetQuery();
        var parameters = this.controls.data_source.controls.query_parameters.value;
        var columns = this.controls.data_source.controls.columns.value;
        var xColumns = [{ xColumn: this.controls.stage_field.value, xLookup: DatasetGroupLookup.Plain }];
        query.queryType = modelQuery.queryType;
        query.simpleQuery = modelQuery.simpleQuery;
        query.httpQuery = modelQuery.httpQuery;
        query.sqlQuery = modelQuery.sqlQuery;
        var params = applyParamInputs({}, this.controls.data_source.controls.query_inputs.value, {
            context: this.context,
            contextElement: this.contextElement,
            parameters: parameters
        });
        return this.widgetQueryService
            .groupQuery(resource, query, xColumns, AggregateFunc.Count, undefined, parameters, params, columns)
            .pipe(map(function (result) {
            if (!result) {
                return;
            }
            var existingValues = _this.controls.stages.controls.map(function (item) { return item.controls.value.value; });
            var values = result.map(function (item) { return item.group; });
            return values.filter(function (item) { return existingValues.find(function (i) { return i == item; }) == undefined; }).slice(0, 10);
        }), catchError(function () { return of(undefined); }));
    };
    CustomizeBarKanbanBoardSettingsForm.prototype.getCardView = function () {
        return this.controls.card_view.value;
    };
    CustomizeBarKanbanBoardSettingsForm.prototype.isConfigured = function (instance) {
        return this.elementConfigurationService.isListKanbanConfigured(instance, { restrictDemo: true });
    };
    CustomizeBarKanbanBoardSettingsForm.prototype.onModelChange = function (modelDescription) {
        _super.prototype.onModelChange.call(this, modelDescription);
        this.controls.stage_field.patchValue('');
    };
    CustomizeBarKanbanBoardSettingsForm.prototype.submitCardView = function (uniqueName, view) {
        var _this = this;
        if (!view) {
            return of(undefined);
        }
        var customView$ = isSet(uniqueName) ? this.customViewsStore.getDetailFirst(uniqueName) : of(undefined);
        return customView$.pipe(switchMap(function (customView) {
            if (customView) {
                var instance = cloneDeep(customView);
                var fields = ['unique_name', 'view_type', 'view', 'params'];
                instance.view = view;
                instance.pageUid = _this.pageUid;
                instance.elementUid = _this.elementUid;
                return _this.customViewService.update(_this.currentProjectStore.instance.uniqueName, _this.currentEnvironmentStore.instance.uniqueName, instance, { draft: true, fields: fields });
            }
            else {
                var instance = new CustomView();
                var fields = ['unique_name', 'view_type', 'view', 'params'];
                instance.uniqueName =
                    isSet(_this.pageUid) && isSet(_this.elementUid)
                        ? [CustomViewType.ListItem, _this.pageUid, _this.elementUid].join('.')
                        : [CustomViewType.ListItem, generateAlphanumeric(8, { letterFirst: true })].join('.');
                instance.viewType = CustomViewType.ListItem;
                instance.view = view;
                instance.pageUid = _this.pageUid;
                instance.elementUid = _this.elementUid;
                return _this.customViewService.create(_this.currentProjectStore.instance.uniqueName, _this.currentEnvironmentStore.instance.uniqueName, instance, { draft: true, fields: fields });
            }
        }), delayWhen(function () { return _this.customViewsStore.getFirst(true); }), map(function (result) { return result.uniqueName; }));
    };
    CustomizeBarKanbanBoardSettingsForm.prototype.submit = function () {
        var instance = cloneDeep(this.settings);
        instance.titleInput = this.controls.title ? new FieldInput().deserialize(this.controls.title.value) : undefined;
        instance.dataSource = this.controls.data_source.serialize();
        instance.cardCustomView = this.controls.card_view_unique_name.value;
        instance.cardCustomViewMappings = this.controls.card_view_mappings.value;
        instance.searchEnabled = this.controls.search_enabled.value;
        instance.searchLive = this.controls.search_live.value;
        instance.stageField = this.controls.stage_field.value;
        instance.stages = this.controls.stages.serialize();
        if (this.controls.card_click_action.value) {
            instance.cardClickAction = this.controls.card_click_action.value;
        }
        else {
            instance.cardClickAction = undefined;
        }
        if (this.controls.card_column_change_action.value) {
            instance.cardColumnChangeAction = this.controls.card_column_change_action.value;
        }
        else {
            instance.cardColumnChangeAction = undefined;
        }
        if (this.controls.card_order_change_action.value) {
            instance.cardOrderChangeAction = this.controls.card_order_change_action.value;
        }
        else {
            instance.cardOrderChangeAction = undefined;
        }
        instance.actions = this.controls.actions.value;
        instance.modelActions = this.controls.model_actions.value;
        instance.columnActions = this.controls.columns_actions.value;
        instance.customActions = this.controls.custom_actions.serialize();
        if (isSet(this.controls.sorting_field.value)) {
            instance.sortingField = this.controls.sorting_field.value;
        }
        else {
            instance.sortingField = undefined;
        }
        instance.sortingAsc = this.controls.sorting_asc.value;
        instance.perPage = this.controls.per_page.value;
        instance.multipleSelection = this.controls.multiple_selection.value;
        instance.defaultSelection = this.controls.first_item_default_selection.value
            ? ListDefaultSelection.First
            : undefined;
        instance.filterFields = this.controls.filter_fields.value
            .filter(function (item) { return item.enabled; })
            .map(function (item) {
            return {
                name: item.name
            };
        });
        instance.displayFilters = instance.filterFields.length && this.controls.display_filters.value;
        instance.header =
            (instance.titleInput && instance.titleInput.isSet()) || (instance.actions && instance.actions.length > 0);
        instance.tooltip = isSet(this.controls.tooltip.value) ? this.controls.tooltip.value.trim() : undefined;
        instance.gapHorizontal = this.controls.gap_horizontal.value;
        instance.gapVertical = this.controls.gap_vertical.value;
        instance.commentsEnabled = this.controls.comments_enabled.value;
        instance.commentsObjectType = this.controls.comments_object_type.value
            ? new FieldInput().deserialize(this.controls.comments_object_type.value)
            : undefined;
        instance.commentsObjectId = this.controls.comments_object_id.value
            ? new FieldInput().deserialize(this.controls.comments_object_id.value)
            : undefined;
        return __assign({ settings: instance, visibleInput: this.controls.visible_input.value
                ? new Input().deserialize(this.controls.visible_input.value)
                : undefined, name: this.controls.name.value }, (this.options.elementStylesEditable && {
            elementStyles: this.controls.element_styles.serialize()
        }));
    };
    return CustomizeBarKanbanBoardSettingsForm;
}(CustomizeBarListLayoutSettingsForm));
export { CustomizeBarKanbanBoardSettingsForm };
