import { Injectable, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable, of, throwError } from 'rxjs';
import { catchError, delay, delayWhen, map, switchMap, tap } from 'rxjs/operators';
import { slugify } from 'transliteration';

import { FormUtils } from '@common/form-utils';
import { ActionStore } from '@modules/action-queries';
import { CustomSelectItem, FieldTypeSection, Option } from '@modules/field-components';
import { BaseField, DefaultType, EditableField, FieldType, getFieldDescriptionByType } from '@modules/fields';
import { ModelDescriptionService, ModelDescriptionStore } from '@modules/model-queries';
import { ModelDbField, ModelDescription, ModelField, ModelFieldType, sortModelFields } from '@modules/models';
import { CurrentEnvironmentStore, CurrentProjectStore, Resource } from '@modules/projects';
import { getJetDatabaseType, ResourceControllerService } from '@modules/resources';
import { ascComparator, controlValue, isSet } from '@shared';

export const validateRelatedModel: ValidatorFn = control => {
  if (!control.parent) {
    return;
  }

  const type = control.parent.controls['field'].value;

  if (type != FieldType.RelatedModel) {
    return;
  }

  if (!isSet(control.value)) {
    return { required: true };
  }
};

@Injectable()
export class CustomizeBarResourceFieldEditForm extends FormGroup implements OnDestroy {
  controls: {
    name: FormControl;
    name_enabled: FormControl;
    field: FormControl;
    db_field_enabled: FormControl;
    db_field: FormControl;
    null: FormControl;
    default_type: FormControl;
    default_value: FormControl;
    length: FormControl;
    related_model: FormControl;
    custom_primary_key: FormControl;
  };

  resource: Resource;
  modelDescription: ModelDescription;
  field: ModelDbField;

  dbFieldSections: FieldTypeSection[] = [
    {
      label: 'Text',
      icon: 'text',
      children: [
        {
          value: 'CharField',
          name: 'Varchar'
        },
        {
          value: 'FixedCharField',
          name: 'Char'
        },
        {
          value: 'TextField',
          name: 'Text'
        }
      ]
    },
    {
      label: 'Numeric',
      icon: 'number',
      children: [
        {
          value: 'IntegerField',
          name: 'Integer'
        },
        {
          value: 'BigIntegerField',
          name: 'Big Integer'
        },
        {
          value: 'SmallIntegerField',
          name: 'Small Integer'
        },
        {
          value: 'FloatField',
          name: 'Float'
        },
        {
          value: 'NumberField',
          name: 'Numeric'
        },
        {
          value: 'DecimalField',
          name: 'Decimal'
        },
        {
          value: 'DoublePrecisionField',
          name: 'Double Precision'
        }
      ]
    },
    {
      label: 'Choices',
      icon: 'select',
      children: [
        {
          value: 'BooleanField',
          name: 'Boolean'
        }
      ]
    },
    {
      label: 'Date & Time',
      icon: 'calendar',
      children: [
        {
          value: 'DateField',
          name: 'Date'
        },
        {
          value: 'DateTimeField',
          name: 'DateTime'
        },
        {
          value: 'TimestampField',
          name: 'Timestamp'
        }
      ]
    },
    {
      label: 'Other',
      icon: 'components',
      children: [
        {
          value: 'JSONField',
          name: 'JSON'
        },
        {
          value: 'GeometryField',
          name: 'Geometry'
        },
        {
          value: 'GeographyField',
          name: 'Geography'
        }
      ]
    }
  ];

  allDefaultTypeOptions = [
    { value: null, name: 'None' },
    { value: DefaultType.NULL, name: 'NULL' },
    { value: DefaultType.Value, name: 'Fixed value' },
    { value: DefaultType.DatetimeNow, name: 'Current time' },
    { value: DefaultType.Sequence, name: 'Sequence (1, 2, 3, ...)' }
    // { value: DefaultType.AutoIncrement, name: 'Auto Increment (1, 2, 3, ...)' },
    // { value: DefaultType.UUID, name: 'Random UUID' }
  ];
  defaultTypesValueInfo: { [k: string]: { fieldType?: boolean; field?: FieldType } } = {
    [DefaultType.Value]: {
      fieldType: true
    },
    [DefaultType.Sequence]: {
      field: FieldType.Text
    }
  };

  constructor(
    private resourceControllerService: ResourceControllerService,
    private modelDescriptionService: ModelDescriptionService,
    private modelDescriptionStore: ModelDescriptionStore,
    private actionStore: ActionStore,
    private formUtils: FormUtils,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore
  ) {
    super({
      name: new FormControl('', Validators.required),
      name_enabled: new FormControl(false),
      field: new FormControl(FieldType.Text, Validators.required),
      db_field_enabled: new FormControl(false),
      db_field: new FormControl('CharField'),
      null: new FormControl(true),
      default_type: new FormControl(DefaultType.NULL),
      default_value: new FormControl(null),
      length: new FormControl(null),
      related_model: new FormControl(null, validateRelatedModel),
      custom_primary_key: new FormControl(null)
    });

    this.controls.field.valueChanges.pipe(delay(0), untilDestroyed(this)).subscribe(() => {
      this.controls.related_model.updateValueAndValidity();
    });

    controlValue(this.controls.null)
      .pipe(delay(0), untilDestroyed(this))
      .subscribe(allowNull => {
        if (!allowNull && this.controls.default_type.value == DefaultType.NULL) {
          this.controls.default_type.patchValue(null);
        } else if (allowNull && this.controls.default_type.value === null) {
          this.controls.default_type.patchValue(DefaultType.NULL);
        }
      });
  }

  ngOnDestroy(): void {}

  init(options: {
    resource: Resource;
    modelDescription: ModelDescription;
    field?: ModelDbField;
    defaults?: BaseField;
  }) {
    this.resource = options.resource;
    this.modelDescription = options.modelDescription;
    this.field = options.field;

    if (options.field) {
      const params = options.field.params;
      const defaultType =
        !isSet(options.field.defaultType) && options.field.null ? DefaultType.NULL : options.field.defaultType;

      this.controls.name.patchValue(options.field.name);
      this.controls.field.patchValue(options.field.field);
      this.controls.db_field.patchValue(options.field.dbField);
      this.controls.null.patchValue(options.field.null || null);
      this.controls.default_type.patchValue(defaultType);
      this.controls.default_value.patchValue(options.field.defaultValue);

      if (params) {
        this.controls.length.patchValue(params['length']);
        this.controls.related_model.patchValue(params['related_model'] ? params['related_model']['model'] : undefined);
        this.controls.custom_primary_key.patchValue(params['custom_primary_key']);
      }
    }

    this.controls.field.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
      if (!this.controls.db_field_enabled.value) {
        const jetDatabaseType = getJetDatabaseType({ field: value });
        this.controls.db_field.patchValue(jetDatabaseType ? jetDatabaseType.dbColumn : 'CharField');
      }
    });

    if (options.defaults && isSet(options.defaults.field)) {
      this.controls.field.patchValue(options.defaults.field);
    }
  }

  resourceModelItems$(): Observable<CustomSelectItem<string>[]> {
    return this.modelDescriptionStore.get().pipe(
      map(modelDescriptions => {
        const options: CustomSelectItem<string>[] = [];

        if (modelDescriptions) {
          options.push(
            ...modelDescriptions
              .filter(item => item.resource == this.resource.uniqueName && !item.virtual)
              .filter(
                item =>
                  !this.resource.demo ||
                  item.featured ||
                  (this.controls.related_model.value && this.controls.related_model.value['model'] == item.model)
              )
              .sort((lhs, rhs) => {
                return ascComparator(
                  String(lhs.verboseNamePlural).toLowerCase(),
                  String(rhs.verboseNamePlural).toLowerCase()
                );
              })
              .map(item => {
                return {
                  option: {
                    value: item.model,
                    name: item.verboseNamePlural,
                    icon: 'document'
                  }
                };
              })
          );
        }

        return options;
      })
    );
  }

  getDefaultTypeOptions$(): Observable<Option<DefaultType>[]> {
    return controlValue(this.controls.null).pipe(
      map(allowNull => this.allDefaultTypeOptions.filter(item => allowNull || item.value !== DefaultType.NULL))
    );
  }

  serialize(): ModelDbField {
    const instance = cloneDeep(this.field) || new ModelDbField();

    instance.name = isSet(this.controls.name.value)
      ? slugify(this.controls.name.value, { trim: true, separator: '_' }).replace(/_+/g, '_')
      : undefined;
    instance.field = this.controls.field.value;
    instance.dbField = this.controls.db_field.value;
    instance.null = this.controls.null.value;
    instance.updateFieldDescription();

    if (isSet(this.controls.default_type.value)) {
      instance.defaultType = this.controls.default_type.value;
    } else {
      instance.defaultType = undefined;
    }

    const valueInfo = this.defaultTypesValueInfo[instance.defaultType];
    if (valueInfo && valueInfo.fieldType) {
      const fieldDescription = getFieldDescriptionByType(instance.field);
      instance.defaultValue = isSet(this.controls.default_value.value)
        ? this.controls.default_value.value
        : fieldDescription.defaultValue;
    } else if (valueInfo && valueInfo.field) {
      const fieldDescription = getFieldDescriptionByType(valueInfo.field);
      instance.defaultValue = isSet(this.controls.default_value.value)
        ? this.controls.default_value.value
        : fieldDescription.defaultValue;
    } else {
      instance.defaultValue = undefined;
    }

    instance.params = {
      ...(isSet(this.controls.length.value) ? { length: this.controls.length.value } : {}),
      ...(isSet(this.controls.related_model.value)
        ? { related_model: { model: this.controls.related_model.value } }
        : {}),
      ...(isSet(this.controls.custom_primary_key.value) ? { length: this.controls.custom_primary_key.value } : {})
    };

    return instance;
  }

  createField(
    field: ModelDbField,
    overrideField?: BaseField
  ): Observable<{
    field: ModelDbField;
    modelDescription: ModelDescription;
  }> {
    const controller = this.resourceControllerService.get(this.resource.type);

    return controller.modelDescriptionFieldCreate(this.resource, this.modelDescription, field).pipe(
      switchMap(() => {
        if (!overrideField) {
          return of(this.modelDescription);
        }

        const overrideModelDescription = cloneDeep(
          this.modelDescriptionStore.instance.find(item => item.modelId == this.modelDescription.modelId)
        );

        if (overrideModelDescription) {
          const newField = new ModelField();
          const sortedFields = sortModelFields(this.modelDescription.fields);
          const lastField = sortedFields[sortedFields.length - 1];

          newField.name = field.name;
          newField.type = ModelFieldType.Db;
          newField.item = cloneDeep(field);
          newField.orderAfter = lastField ? lastField.name : undefined;

          if (newField.item instanceof ModelDbField) {
            newField.item.applyOverrides(overrideField);
            newField.item.autoFillVerboseName();
          }

          overrideModelDescription.fields.push(newField);
        }

        return this.modelDescriptionService.update(
          this.currentProjectStore.instance.uniqueName,
          this.currentEnvironmentStore.instance.uniqueName,
          overrideModelDescription,
          false
        );
      }),
      tap(result => this.modelDescriptionStore.updateItem(result)),
      delayWhen(result => this.actionStore.syncAutoActions(result)),
      map(result => {
        return {
          field: result.dbField(field.name),
          modelDescription: result
        };
      })
    );
  }

  updateField(
    name: string,
    field: ModelDbField,
    overrideField?: BaseField
  ): Observable<{
    field: ModelDbField;
    modelDescription: ModelDescription;
  }> {
    const controller = this.resourceControllerService.get(this.resource.type);

    return controller.modelDescriptionFieldUpdate(this.resource, this.modelDescription, name, field).pipe(
      switchMap(() => {
        if (!overrideField) {
          return of(this.modelDescription);
        }

        const overrideModelDescription = cloneDeep(this.modelDescription);

        if (overrideModelDescription) {
          overrideModelDescription.fields = overrideModelDescription.fields.map(item => {
            if (item.name == name && item.type == ModelFieldType.Db && overrideField) {
              const newField = new ModelField();
              const existingField = this.modelDescription.field(name);
              const sortedFields = sortModelFields(this.modelDescription.fields);
              const lastField = sortedFields[sortedFields.length - 1];

              newField.name = field.name;
              newField.type = ModelFieldType.Db;
              newField.item = cloneDeep(field);

              if (existingField) {
                newField.orderAfter = existingField.orderAfter;
              } else if (lastField) {
                newField.orderAfter = lastField.name;
              } else {
                newField.orderAfter = undefined;
              }

              if (newField.item instanceof ModelDbField) {
                newField.item.applyOverrides(overrideField);
              }

              return newField;
            } else {
              return item;
            }
          });
        }

        return this.modelDescriptionService.update(
          this.currentProjectStore.instance.uniqueName,
          this.currentEnvironmentStore.instance.uniqueName,
          overrideModelDescription,
          false
        );
      }),
      tap(result => this.modelDescriptionStore.updateItem(result)),
      delayWhen(result => this.actionStore.syncAutoActions(result)),
      map(result => {
        return {
          field: result.dbField(field.name),
          modelDescription: result
        };
      })
    );
  }

  submit(
    overrideField?: EditableField
  ): Observable<{
    field: ModelDbField;
    modelDescription: ModelDescription;
  }> {
    const instance = this.serialize();
    const result$ = this.field
      ? this.updateField(this.field.name, instance, overrideField)
      : this.createField(instance, overrideField);

    return result$.pipe(
      catchError(error => {
        console.error(error);
        this.formUtils.showFormErrors(this, error);
        return throwError(error);
      })
    );
  }
}
