var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { combineLatest, of, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { FormUtils } from '@common/form-utils';
import { MenuSettings } from '@modules/menu';
import { MenuCustomizeService } from '@modules/menu-utils';
import { MenuBlockArray } from './menu-block.array';
var MenuUpdateForm = /** @class */ (function (_super) {
    __extends(MenuUpdateForm, _super);
    function MenuUpdateForm(formUtils, menuCustomizeService, injector) {
        var _this = _super.call(this, {
            blocks: MenuBlockArray.inject(injector, [])
        }) || this;
        _this.formUtils = formUtils;
        _this.menuCustomizeService = menuCustomizeService;
        _this.injector = injector;
        return _this;
    }
    MenuUpdateForm.inject = function (injector) {
        return Injector.create({
            providers: [
                {
                    provide: MenuUpdateForm,
                    useFactory: function (formUtils, menuCustomizeService) {
                        return new MenuUpdateForm(formUtils, menuCustomizeService, injector);
                    },
                    deps: [FormUtils, MenuCustomizeService]
                }
            ],
            parent: injector
        }).get(MenuUpdateForm);
    };
    MenuUpdateForm.prototype.init = function (menuSettings) {
        this.menuSettings = menuSettings;
        this.controls.blocks.deserialize(menuSettings.blocks);
        this.markAsPristine();
    };
    MenuUpdateForm.prototype.getInstance = function () {
        var instance = this.menuSettings ? this.menuSettings.clone() : new MenuSettings();
        instance.blocks = this.controls.blocks.serialize();
        return instance;
    };
    MenuUpdateForm.prototype.getChanges = function (lhs, rhs) {
        var menuChanges$ = this.menuCustomizeService.getChanges(lhs, rhs);
        return combineLatest(menuChanges$).pipe(map(function (_a) {
            var menuChanges = _a[0];
            var menuChanged = [
                menuChanges.menuSettings,
                menuChanges.changedModels,
                menuChanges.changedDashboards,
                menuChanges.deletedDashboards,
                menuChanges.changedPages
            ].some(function (item) { return item.hasChanges; });
            return {
                menuChanged: menuChanged,
                menuChanges: menuChanges
            };
        }));
    };
    MenuUpdateForm.prototype.submit = function () {
        var _this = this;
        var instance = this.getInstance();
        return this.getChanges(this.menuSettings, instance).pipe(switchMap(function (changes) {
            return changes.menuChanged ? _this.menuCustomizeService.saveChanges(changes.menuChanges) : of({});
        }), map(function () { return instance; }), tap(function (result) { return (_this.menuSettings = result); }), catchError(function (error) {
            _this.formUtils.showFormErrors(_this, error);
            return throwError(error);
        }));
    };
    return MenuUpdateForm;
}(FormGroup));
export { MenuUpdateForm };
