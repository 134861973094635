import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { DialogService } from '@common/dialogs';
import { ExportDataType } from '@modules/actions';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { createFormFieldFactory, FieldType } from '@modules/fields';
import { QueryType } from '@modules/queries';
import { HttpResultsSection, SqlResultsSection } from '@modules/queries-components';
import { controlValue } from '@shared';
import { DisplayFieldsEditComponent } from '../../../display-fields-edit/display-fields-edit.component';
import { ListModelDescriptionDataSourceControl } from '../../../model-description-data-source-edit/list-model-description-data-source';
import { ModelDescriptionDataSourceControl } from '../../../model-description-data-source-edit/model-description-data-source';
import { ModelDescriptionDataSourceEditComponent } from '../../../model-description-data-source-edit/model-description-data-source-edit.component';
import { CustomizeBarActionEditForm } from '../../customize-bar-action-edit.form';
var CustomizeBarActionEditTypeExportComponent = /** @class */ (function () {
    function CustomizeBarActionEditTypeExportComponent(dialogService, cd) {
        this.dialogService = dialogService;
        this.cd = cd;
        this.createField = createFormFieldFactory();
        this.exportDataTypes = ExportDataType;
        this.fieldTypes = FieldType;
        this.queryTypes = QueryType;
    }
    CustomizeBarActionEditTypeExportComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.columnsVisible$ = combineLatest(controlValue(this.form.controls.export_data_type), this.form.controls.export_data_source.getQueryConfigured$()).pipe(map(function (_a) {
            var exportDataType = _a[0], queryConfigured = _a[1];
            if (exportDataType == ExportDataType.DataSource) {
                return queryConfigured;
            }
            else if (exportDataType == ExportDataType.CurrentComponent) {
                return true;
            }
            else {
                return false;
            }
        }));
        this.sortableColumnOptions$ = controlValue(this.form.controls.export_data_type).pipe(switchMap(function (exportDataType) {
            if (exportDataType == ExportDataType.CurrentComponent &&
                _this.form.options.dataSourceControl instanceof ListModelDescriptionDataSourceControl) {
                return _this.form.options.dataSourceControl.getSortableColumnOptions$();
            }
            else if (exportDataType == ExportDataType.DataSource) {
                return _this.form.controls.export_data_source.getSortableColumnOptions$();
            }
            else {
                return of([]);
            }
        }));
        controlValue(this.form.controls.export_data_type)
            .pipe(switchMap(function (exportDataType) {
            if (exportDataType == ExportDataType.CurrentComponent &&
                _this.form.options.dataSourceControl instanceof ModelDescriptionDataSourceControl) {
                return _this.form.options.dataSourceControl.getModelDescription$();
            }
            else if (exportDataType == ExportDataType.DataSource) {
                return _this.form.controls.export_data_source.getModelDescription$();
            }
            else {
                return of(undefined);
            }
        }), untilDestroyed(this))
            .subscribe(function (modelDescription) {
            _this.modelDescription = modelDescription;
            _this.cd.markForCheck();
        });
    };
    CustomizeBarActionEditTypeExportComponent.prototype.ngOnDestroy = function () { };
    CustomizeBarActionEditTypeExportComponent.prototype.setExportDataType = function (type) {
        if (this.form.controls.export_data_type.value == type) {
            return;
        }
        this.form.controls.export_data_type.setValue(type);
        this.resetColumnsProcess();
    };
    CustomizeBarActionEditTypeExportComponent.prototype.resetColumns = function () {
        var _this = this;
        this.dialogService
            .warning({
            title: "Are you sure want to reset export fields?",
            description: "\n          The export fields will be reset to their default values.\n        ",
            style: 'orange'
        })
            .pipe(filter(function (result) { return !!result; }), untilDestroyed(this))
            .subscribe(function () { return _this.resetColumnsProcess(); });
    };
    CustomizeBarActionEditTypeExportComponent.prototype.resetColumnsProcess = function () {
        this.form.resetExportColumns({
            context: this.context,
            contextElement: this.contextElement,
            markAsDirty: true
        });
    };
    CustomizeBarActionEditTypeExportComponent.prototype.addQueryInput = function () {
        if (this.dataSourceEditComponent) {
            this.dataSourceEditComponent.editQuery({
                initialHttpResultsSection: HttpResultsSection.Parameters,
                initialSqlResultsSection: SqlResultsSection.Parameters
            });
        }
    };
    return CustomizeBarActionEditTypeExportComponent;
}());
export { CustomizeBarActionEditTypeExportComponent };
