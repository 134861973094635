import { Directive, ElementRef, HostBinding, Input, OnChanges, OnDestroy, OnInit, Optional } from '@angular/core';
import toPairs from 'lodash/toPairs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, of } from 'rxjs';

import { TintStyle } from '@modules/actions';
import { AllProjectSettings, ProjectSettingsStore } from '@modules/all-project-settings';
import { ActionElementStyles } from '@modules/customize';
import {
  setBorderRadiusVars,
  setBorderVars,
  setFillVars,
  setIconSettingsVars,
  setMarginVars,
  setShadowVars,
  setTextStyle
} from '@modules/theme-components';
import { isSet, TypedChanges } from '@shared';

@Directive({
  selector: '[appActionElementStyles]'
})
export class ActionElementStylesDirective implements OnInit, OnDestroy, OnChanges {
  @Input('appActionElementStyles') customElementStyles: ActionElementStyles;
  @Input('appActionElementStylesApplyGlobal') applyGlobal = true;
  @Input('appActionElementStylesTintColorStyle') tintColorStyle: TintStyle;

  @HostBinding('attr.data-style-vars') attrDataStyleVars: string;

  tintColorStyle$ = new BehaviorSubject<TintStyle>(undefined);
  globalStyles: ActionElementStyles;
  styleVars: string[] = [];

  constructor(@Optional() private projectSettingsStore: ProjectSettingsStore, private el: ElementRef) {}

  ngOnInit(): void {
    if (this.applyGlobal) {
      const settings$ = this.projectSettingsStore
        ? this.projectSettingsStore.getAllSettings$()
        : of<AllProjectSettings>(undefined);

      combineLatest(settings$, this.tintColorStyle$)
        .pipe(untilDestroyed(this))
        .subscribe(([settings, tintColorStyle]) => {
          if (!settings) {
            this.globalStyles = undefined;
          } else if (tintColorStyle == TintStyle.Primary) {
            this.globalStyles = settings.actionElementStylesPrimary;
          } else if (tintColorStyle == TintStyle.Default) {
            this.globalStyles = settings.actionElementStylesDefault;
          } else if (tintColorStyle == TintStyle.Transparent) {
            this.globalStyles = settings.actionElementStylesTransparent;
          } else {
            this.globalStyles = undefined;
          }

          this.updateStyles();
        });
    } else {
      this.updateStyles();
    }
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<ActionElementStylesDirective>): void {
    if (changes.customElementStyles && !changes.customElementStyles.firstChange) {
      this.updateStyles();
    }

    if (changes.tintColorStyle) {
      this.tintColorStyle$.next(this.tintColorStyle);
    }
  }

  updateStyles() {
    let elementStyles: ActionElementStyles;
    const vars: Object = {};

    if (this.customElementStyles && this.globalStyles) {
      elementStyles = new ActionElementStyles().apply(this.globalStyles).apply(this.customElementStyles);
    } else if (this.customElementStyles || this.globalStyles) {
      elementStyles = this.customElementStyles || this.globalStyles;
    } else {
      elementStyles = undefined;
    }

    if (elementStyles) {
      setTextStyle(vars, elementStyles.textStyle, key => `button-text-${key}`);
      setIconSettingsVars(vars, elementStyles.iconSettings, key => `button-icon-${key}`);
      setFillVars(vars, elementStyles.fillSettings, key => `button-${key}`);
      setBorderVars(vars, elementStyles.borderSettings, key => `button-${key}`);
      setBorderRadiusVars(vars, elementStyles.borderRadius, 'button-border-radius');
      setShadowVars(vars, elementStyles.shadow, key => `button-${key}`);
      setMarginVars(vars, elementStyles.padding, key => `button-padding-${key}`);
      setMarginVars(vars, elementStyles.padding, key => `button-padding-${key}-small`, {
        horizontalMultiplier: 0.8,
        verticalMultiplier: 0.825
      });

      setTextStyle(vars, elementStyles.hoverTextStyle, key => `button-hover-text-${key}`);
      setIconSettingsVars(vars, elementStyles.hoverIconSettings, key => `button-hover-icon-${key}`);
      setFillVars(vars, elementStyles.hoverFillSettings, key => `button-hover-${key}`);
      setBorderVars(vars, elementStyles.hoverBorderSettings, key => `button-hover-${key}`);
      setShadowVars(vars, elementStyles.hoverShadow, key => `button-hover-${key}`);

      setTextStyle(vars, elementStyles.activeTextStyle, key => `button-active-text-${key}`);
      setIconSettingsVars(vars, elementStyles.activeIconSettings, key => `button-active-icon-${key}`);
      setFillVars(vars, elementStyles.activeFillSettings, key => `button-active-${key}`);
      setBorderVars(vars, elementStyles.activeBorderSettings, key => `button-active-${key}`);
      setShadowVars(vars, elementStyles.activeShadow, key => `button-active-${key}`);
    }

    toPairs(vars).forEach(([k, v]) => this.el.nativeElement.style.setProperty(`--${k}`, v));
    this.styleVars.filter(k => !isSet(vars[k])).forEach(k => this.el.nativeElement.style.removeProperty(`--${k}`));
    this.styleVars = toPairs(vars).map(([k]) => k);

    this.attrDataStyleVars = this.styleVars.join(' ');
  }
}
