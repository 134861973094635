import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';

import { FieldInputControl } from '@modules/parameters';
import { ForkWorkflowStepItem } from '@modules/workflow';

@Injectable()
export class ForkCustomizeWorkflowStepItemControl extends FormGroup {
  instance: ForkWorkflowStepItem;

  controls: {
    condition: FieldInputControl;
  };

  constructor() {
    super({
      condition: new FieldInputControl({ path: ['value'] })
    });
  }

  init(instance: ForkWorkflowStepItem, options: { firstInit?: boolean } = {}) {
    this.instance = instance;

    if (!options.firstInit) {
      this.markAsDirty();
    }
  }

  submit(): ForkWorkflowStepItem {
    const instance = this.instance ? (cloneDeep(this.instance) as ForkWorkflowStepItem) : new ForkWorkflowStepItem();

    if (!instance.uid) {
      instance.generateUid();
    }

    return instance;
  }
}
