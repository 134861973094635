<link *ngIf="fontUrl && visibleOrWasVisible" [href]="fontUrl" rel="stylesheet" (load)="onFontLoaded()" />

<a
  href="javascript:void(0)"
  class="token-popup-list-item token-popup-list-item_hoverable token-popup-list-item_clickable"
  [class.token-popup-list-item_orange]="false"
  [class.token-popup-list-item_active]="active"
>
  <div class="token-popup-list-item__inner">
    <div class="token-popup-list-item__line">
      <span
        *ngIf="fontLoaded"
        class="token-popup-list-item__main token-popup-list-item__main_wrap"
        [style.font-size.px]="16"
        [style.font-family]="fontFamily"
        [style.line-height]="1"
      >
        {{ title }}
      </span>
      <span
        *ngIf="!fontLoaded"
        class="token-popup-list-item__main token-popup-list-item__main_wrap"
        [style.font-size.px]="16"
        [style.line-height]="1"
      >
        <span class="stub-text">{{ titleStub }}</span>
      </span>

      <span class="token-popup-list-item__right">
        <a
          *ngIf="googleFontsUrl | appIsSet"
          [href]="googleFontsUrl"
          class="token-popup-list-item__hover token-popup-list-item__icon-action icon-external_link"
          target="_blank"
          [appTip]="'See on Google Fonts'"
          [appTipOptions]="{ side: 'left' }"
          (click)="$event.stopPropagation()"
        ></a>
      </span>
    </div>

    <div *ngIf="subtitle | appIsSet" class="token-popup-list-item__line">
      <div class="token-popup-list-item__description">{{ subtitle }}</div>
    </div>
  </div>
</a>
