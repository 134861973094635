<div class="sidebar__element sidebar__element_margin_s">
  <app-sidebar-field [label]="'color'">
    <ng-container actions-right>
      <div class="button-group" [style.margin]="'-5px 0'">
        <div
          class="button-group__item"
          [class.button-group__item_active]="themeService.isDefaultTheme"
          (click)="themeService.theme = 'default'"
        >
          <span class="button-group__item-icon icon-sun"></span>
        </div>

        <div
          class="button-group__item"
          [class.button-group__item_active]="themeService.isDarkTheme"
          (click)="themeService.theme = 'dark'"
        >
          <span class="button-group__item-icon icon-moon"></span>
        </div>
      </div>
    </ng-container>

    <app-auto-field
      *ngIf="themeService.isDefaultTheme"
      [field]="
        createField({
          name: 'color',
          field: 'ColorField',
          params: {
            classes: ['select_fill'],
            allow_empty: true,
            empty_color: defaultPlaceholders.color,
            custom_colors: true,
            global_colors: true,
            alpha_enabled: control.options.colorAlphaEnabled
          }
        })
      "
      [form]="control"
      [label]="false"
    ></app-auto-field>

    <app-auto-field
      *ngIf="themeService.isDarkTheme"
      [field]="
        createField({
          name: 'color_dark',
          field: 'ColorField',
          params: {
            classes: ['select_fill'],
            allow_empty: true,
            empty_color: defaultPlaceholders.colorDark,
            custom_colors: true,
            global_colors: true,
            alpha_enabled: control.options.colorAlphaEnabled
          }
        })
      "
      [form]="control"
      [label]="false"
    ></app-auto-field>
  </app-sidebar-field>
</div>

<div *ngIf="control.options.backgroundEnabled" class="sidebar__element sidebar__element_margin_s">
  <app-sidebar-field [label]="'background'">
    <ng-container actions-right>
      <div class="button-group" [style.margin]="'-5px 0'">
        <div
          class="button-group__item"
          [class.button-group__item_active]="themeService.isDefaultTheme"
          (click)="themeService.theme = 'default'"
        >
          <span class="button-group__item-icon icon-sun"></span>
        </div>

        <div
          class="button-group__item"
          [class.button-group__item_active]="themeService.isDarkTheme"
          (click)="themeService.theme = 'dark'"
        >
          <span class="button-group__item-icon icon-moon"></span>
        </div>
      </div>
    </ng-container>

    <app-auto-field
      *ngIf="themeService.isDefaultTheme"
      [field]="
        createField({
          name: 'background_color',
          field: 'ColorField',
          placeholder: 'No background color',
          params: {
            classes: ['select_fill'],
            allow_empty: true,
            empty_color: '',
            custom_colors: true,
            alpha_enabled: true
          }
        })
      "
      [form]="control"
      [label]="false"
    ></app-auto-field>

    <app-auto-field
      *ngIf="themeService.isDarkTheme"
      [field]="
        createField({
          name: 'background_color_dark',
          field: 'ColorField',
          placeholder: 'No background color',
          params: {
            classes: ['select_fill'],
            allow_empty: true,
            empty_color: '',
            custom_colors: true,
            alpha_enabled: true
          }
        })
      "
      [form]="control"
      [label]="false"
    ></app-auto-field>
  </app-sidebar-field>
</div>

<div class="sidebar__element sidebar__element_margin_s">
  <app-sidebar-field [label]="'Font'">
    <div class="sidebar__element-segment">
      <app-font-control
        [control]="control.controls.font_family"
        [placeholder]="defaultPlaceholders.fontFamily"
        [globalFonts]="true"
        [fill]="true"
        (fontSelect)="onFontSelect($event)"
      ></app-font-control>
    </div>

    <div class="sidebar__element-segment">
      <div class="sidebar-cols sidebar-cols_margin_s">
        <div class="sidebar-cols__item" [style.flex-grow]="3">
          <app-auto-field
            [form]="control"
            [label]="false"
            [field]="
              createField({
                name: 'font_type',
                field: 'SelectField',
                placeholder: defaultPlaceholders.fontType,
                params: {
                  options: fontTypeOptions$ | async,
                  classes: ['select_fill'],
                  valueEquals: control.fontTypeEquals
                }
              })
            "
          ></app-auto-field>
        </div>

        <div class="sidebar-cols__item">
          <input
            type="text"
            class="input input_fill"
            [formControl]="control.controls.font_size"
            [placeholder]="defaultPlaceholders.fontSize"
            [appInputFormat]="{ min: 1, numberKeyboardControl: true }"
          />
        </div>
      </div>
    </div>
  </app-sidebar-field>
</div>

<div class="sidebar__element sidebar__element_margin_s">
  <div class="sidebar-cols sidebar-cols_margin_s">
    <div class="sidebar-cols__item">
      <app-sidebar-field [label]="'Letter spacing'">
        <div class="input-group">
          <div
            class="input-group__item input-group__item_first input input_small icon-text_width"
            [style.padding-left.px]="10"
            [style.padding-right.px]="10"
          ></div>

          <input
            type="text"
            class="input-group__item input-group__item_middle input-group__fill input input_small"
            [formControl]="control.controls.letter_spacing"
            [placeholder]="defaultPlaceholders.letterSpacing"
            [appInputFormat]="{ type: numberFieldTypes.Integer, min: 0, numberKeyboardControl: true }"
          />

          <div
            class="input-group__item input-group__item_last input input_small input_disabled"
            [style.padding-left.px]="10"
            [style.padding-right.px]="10"
          >
            px
          </div>
        </div>
      </app-sidebar-field>
    </div>

    <div class="sidebar-cols__item"></div>
  </div>
</div>

<div class="sidebar__element sidebar__element_margin_s">
  <app-sidebar-field [label]="'Transform & Decoration'">
    <div class="sidebar-cols">
      <div class="sidebar-cols__item sidebar-cols__item_fit">
        <button
          *ngFor="let item of control.transformOptions; let first = first; let last = last"
          class="button button_extra-small button_icon button_no-margin"
          [class.button_segment-left]="first && !last"
          [class.button_segment-right]="!first && last"
          [class.button_segment-middle]="!first && !last"
          [class.button_background]="control.controls.transform.value !== item.value"
          [class.button_grey]="control.controls.transform.value === item.value"
          [class.button_disabled]="control.controls.transform.value === item.value"
          [appTip]="item.subtitle"
          [appTipOptions]="{ side: 'top' }"
          (click)="control.controls.transform.patchValue(item.value)"
        >
          <span class="button__icon" [ngClass]="'icon-' + item.icon"></span>
        </button>
      </div>

      <div class="sidebar-cols__item sidebar-cols__item_fit">
        <button
          *ngFor="let item of control.decorationOptions; let first = first; let last = last"
          class="button button_extra-small button_icon button_no-margin"
          [class.button_segment-left]="first && !last"
          [class.button_segment-right]="!first && last"
          [class.button_segment-middle]="!first && !last"
          [class.button_background]="control.controls.decoration.value !== item.value"
          [class.button_grey]="control.controls.decoration.value === item.value"
          [class.button_disabled]="control.controls.decoration.value === item.value"
          [appTip]="item.subtitle"
          [appTipOptions]="{ side: 'top' }"
          (click)="control.controls.decoration.patchValue(item.value)"
        >
          <span class="button__icon" [ngClass]="'icon-' + item.icon"></span>
        </button>
      </div>
    </div>
  </app-sidebar-field>
</div>

<div *ngIf="resetEnabled" class="sidebar__element sidebar__element_margin_s">
  <div class="sidebar-cols">
    <!--    <div class="sidebar-cols__item">-->
    <!--      <button-->
    <!--        *ngIf="control.options.globalSetting"-->
    <!--        class="button button_orange-border button_small button_fill"-->
    <!--        [disabled]="isStyleDefault || loadingDefault || loadingDefaultSave"-->
    <!--        (click)="saveAsDefaults()"-->
    <!--      >-->
    <!--        <app-loader-small *ngIf="loadingDefaultSave" class="button__icon button__icon_left"></app-loader-small>-->
    <!--        <span *ngIf="!loadingDefaultSave" class="button__icon button__icon_left icon-save"></span>-->
    <!--        <span class="button__label">Save as default</span>-->
    <!--      </button>-->
    <!--    </div>-->

    <div class="sidebar-cols__item">
      <button
        class="button button_orange-border button_small button_fill"
        [disabled]="isStyleDefault || loadingDefault || loadingDefaultSave"
        (click)="resetToDefaults()"
      >
        <span class="button__icon button__icon_left icon-arrow_backward_turn"></span>
        <span class="button__label">Reset to defaults</span>
      </button>
    </div>

    <div class="sidebar-cols__item"></div>
  </div>
</div>
