var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import * as Color from 'color';
import toPairs from 'lodash/toPairs';
import { TintStyle } from '@modules/actions';
import { getColorHex, getColorHexAStr, parseColor } from '@modules/colors';
import { isSet } from '@shared';
export function getTintColors(color, style) {
    var hex = getColorHex(color);
    var clr = isSet(hex) ? parseColor(hex) : undefined;
    if (clr) {
        var isDark = clr.contrast(Color('white')) >= 2;
        if (style == TintStyle.Primary) {
            return {
                backgroundColor: clr,
                backgroundHoverColor: clr.darken(0.2),
                backgroundActiveColor: clr.darken(0.5),
                textColor: isDark ? Color('#fff') : clr.darken(0.6),
                textHoverColor: isDark ? Color('#fff') : clr.darken(0.6),
                textActiveColor: isDark ? Color('#fff') : clr.darken(0.6)
            };
        }
        else if (style == TintStyle.Transparent) {
            return {
                backgroundHoverColor: clr.darken(0.2).alpha(0.2),
                backgroundActiveColor: clr.darken(0.5).alpha(0.2),
                textColor: clr.darken(0.3),
                textHoverColor: clr.darken(0.3),
                textActiveColor: clr.darken(0.3)
            };
        }
        else {
            return {
                backgroundColor: clr.alpha(0.1),
                backgroundHoverColor: clr.darken(0.2).alpha(0.2),
                backgroundActiveColor: clr.darken(0.5).alpha(0.2),
                textColor: clr.darken(0.3),
                textHoverColor: clr.darken(0.3),
                textActiveColor: clr.darken(0.3)
            };
        }
    }
}
var TintColorDirective = /** @class */ (function () {
    function TintColorDirective(el) {
        this.el = el;
        this.styleVars = [];
    }
    TintColorDirective.prototype.ngOnChanges = function (changes) {
        var _this = this;
        var vars = {};
        var colors = getTintColors(this.appTintColor, this.appTintColorStyle);
        if (colors) {
            Object.assign(vars, __assign({}, (colors.backgroundColor && {
                'tint-background-color': getColorHexAStr(colors.backgroundColor)
            }), (colors.backgroundHoverColor && {
                'tint-background-hover-color': getColorHexAStr(colors.backgroundHoverColor)
            }), (colors.backgroundActiveColor && {
                'tint-background-active-color': getColorHexAStr(colors.backgroundActiveColor)
            }), (colors.textColor && {
                'tint-text-color': getColorHexAStr(colors.textColor)
            }), (colors.textHoverColor && {
                'tint-text-hover-color': getColorHexAStr(colors.textHoverColor)
            }), (colors.textActiveColor && {
                'tint-text-active-color': getColorHexAStr(colors.textActiveColor)
            })));
        }
        toPairs(vars).forEach(function (_a) {
            var k = _a[0], v = _a[1];
            return _this.el.nativeElement.style.setProperty("--" + k, v);
        });
        this.styleVars.filter(function (k) { return !isSet(vars[k]); }).forEach(function (k) { return _this.el.nativeElement.style.removeProperty("--" + k); });
        this.styleVars = toPairs(vars).map(function (_a) {
            var k = _a[0];
            return k;
        });
    };
    return TintColorDirective;
}());
export { TintColorDirective };
