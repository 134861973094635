<app-external-fonts [fonts]="externalFonts"></app-external-fonts>

<app-layout
  *ngIf="!domainLoading"
  class="theme-enabled"
  appDomainThemeOptions
  [domain]="domain"
  [layout]="domain?.signUpLayout || defaultLayout"
  [backgroundImage]="domain?.signUpBackgroundImage"
  [backgroundColor]="domain?.signUpBackgroundColor"
  [fillScreen]="fillScreen"
  [preview]="preview"
>
  <app-page-block [marginSize]="'l'" [paddingSize]="'none'">
    <app-page-header
      *ngIf="inviteLoading || publicInviteLoading"
      [marginSize]="'l'"
      [first]="layout.startsWith('poster_')"
    >
      <app-page-title [align]="'center'" [strong]="true">
        <span [class.loading-animation]="true"><span class="stub-text">Get Started</span></span>
      </app-page-title>
    </app-page-header>

    <ng-container *ngIf="!inviteLoading && !publicInviteLoading">
      <ng-template #project_logo>
        <app-project-logo
          *ngIf="isWhiteLabel && domain && (domain.logo || domain.initials)"
          [color]="domain.logoColor ? domain.logoColor : ''"
          [logo]="domain.logo ? domain.logo : ''"
          [initials]="domain.initials ? domain.initials : ''"
        ></app-project-logo>

        <app-project-logo
          *ngIf="!isWhiteLabel"
          [logo]="'/assets/images/logo.svg' | appDeployUrl"
          [size]="'sm'"
        ></app-project-logo>
      </ng-template>

      <app-page-header
        *ngIf="!invite && !publicInvite && headerHasContent"
        [marginSize]="'l'"
        [first]="layout.startsWith('poster_')"
      >
        <app-page-logo [align]="'center'">
          <ng-container *ngTemplateOutlet="project_logo"></ng-container>
        </app-page-logo>

        <app-page-title *ngIf="domain?.signUpTitle" [align]="'center'" [strong]="true">
          {{ domain.signUpTitle }}
        </app-page-title>

        <app-page-description *ngIf="domain?.signUpDescription" [align]="'center'">
          {{ domain.signUpDescription }}
        </app-page-description>

        <app-page-title *ngIf="!domain" [align]="'center'" [strong]="true">
          <ng-template [appTr]="{}">Get Started <span class="auth-form__accent">Free today</span></ng-template>
        </app-page-title>

        <app-page-description *ngIf="!domain" [align]="'center'">
          {{
            "No card required. You'll get all the advanced functionality for free during the 14 day PRO trial."
              | localize
          }}
        </app-page-description>
      </app-page-header>

      <app-page-header *ngIf="invite" [marginSize]="'l'" [first]="layout.startsWith('poster_')">
        <app-page-logo [align]="'center'">
          <ng-container *ngTemplateOutlet="project_logo"></ng-container>
        </app-page-logo>

        <app-page-title [align]="'center'" [strong]="true">
          Join <span class="auth-form__accent">{{ invite.project.name }}</span>
          <ng-container *ngIf="!isWhiteLabel"> on Jet</ng-container>
        </app-page-title>

        <div class="auth-form__banner">
          <ng-container *ngIf="invite.inviter">
            <strong>{{ invite.inviter.strFull }} ({{ invite.inviter.email }})</strong> has invited you to join
          </ng-container>
          <ng-container *ngIf="!invite.inviter">
            You were invited to join
          </ng-container>
          <ng-container *ngIf="!isWhiteLabel"> the Jet workspace </ng-container>
          <strong>{{ invite.project.name }}</strong
          >.
        </div>
      </app-page-header>

      <app-page-header *ngIf="publicInvite" [marginSize]="'l'" [first]="layout.startsWith('poster_')">
        <app-page-logo [align]="'center'">
          <ng-container *ngTemplateOutlet="project_logo"></ng-container>
        </app-page-logo>

        <app-page-title [align]="'center'" [strong]="true">
          Join <span class="auth-form__accent">{{ publicInvite.project.name }}</span>
          <ng-container *ngIf="!isWhiteLabel"> on Jet</ng-container>
        </app-page-title>

        <div class="auth-form__banner">
          You are joining
          <ng-container *ngIf="!isWhiteLabel"> the Jet workspace </ng-container>
          <strong>{{ publicInvite.project.name }}</strong
          >.
        </div>
      </app-page-header>
    </ng-container>

    <app-page-block *ngIf="(form | appFormErrors).length">
      <div class="error-notification">
        <div class="error-notification__background"></div>
        <div class="error-notification__content">
          <a href="javascript:void(0)" (click)="cleanErrorForm.emit()" class="error-notification__close icon-close"></a>
          <div class="error-notification__title">{{ 'Unable to Sign Up' | localize }}</div>
          <div *ngFor="let error of form | appFormErrors" class="error-notification__description">
            {{ error }}
          </div>
        </div>
      </div>
    </app-page-block>

    <app-page-block *ngIf="isGoogleSignEnabled || isFacebookSignEnabled">
      <app-page-columns [innerPaddingSize]="'xs'">
        <app-page-column *ngIf="isGoogleSignEnabled">
          <div class="button2" (click)="socialLogin.emit('google-oauth2')">
            <div class="button2__icon button2__icon_left button2__icon_image_google"></div>
            <div class="button2__label">Google</div>
          </div>
        </app-page-column>

        <app-page-column *ngIf="isFacebookSignEnabled">
          <div class="button2" (click)="socialLogin.emit('facebook')">
            <div class="button2__icon button2__icon_left button2__icon_image_facebook"></div>
            <div class="button2__label">Facebook</div>
          </div>
        </app-page-column>
      </app-page-columns>
    </app-page-block>

    <ng-container *ngIf="domain?.ssoSettings.length">
      <app-page-block *ngFor="let item of domain.ssoSettings">
        <div class="button2" (click)="startSSOLogin(item)">
          <div
            *ngIf="item.image"
            class="button2__icon button2__icon_left"
            [style.background-image]="'url(' + item.image + ')'"
          ></div>
          <div *ngIf="!item.image" class="button2__icon button2__icon_left icon-earth_planet"></div>
          <div class="button2__label">
            <ng-template [appTr]="{ provider: item.name }" let-ctx>Sign in with {{ ctx['provider'] }}</ng-template>
          </div>
        </div>
      </app-page-block>
    </ng-container>

    <app-page-block *ngIf="isSignInWithEnabled">
      <app-page-separator>{{ 'or' | localize }}</app-page-separator>
    </app-page-block>

    <form *ngIf="form" [formGroup]="form" (ngSubmit)="submit.emit()">
      <app-page-block>
        <app-text-control
          [control]="form.controls.first_name"
          [iconLeft]="'user'"
          [placeholder]="'Enter First Name' | localize"
          [autofocus]="true"
          [autocomplete]="'given-name'"
        ></app-text-control>
      </app-page-block>

      <app-page-block>
        <app-text-control
          [control]="form.controls.last_name"
          [iconLeft]="'user'"
          [placeholder]="'Enter Last Name (optional)' | localize"
          [autocomplete]="'family-name'"
        ></app-text-control>
      </app-page-block>

      <app-page-block>
        <app-text-control
          [control]="form.controls.email"
          [iconLeft]="'email'"
          [placeholder]="'Enter Email' | localize"
          [autocomplete]="'email'"
        ></app-text-control>
      </app-page-block>

      <app-page-block>
        <app-page-columns [innerPaddingSize]="'xs'">
          <app-page-column>
            <app-password-control
              [control]="form.controls.password"
              [iconLeft]="'lock_close'"
              [placeholder]="'Password' | localize"
              [autocomplete]="'new-password'"
            ></app-password-control>
          </app-page-column>

          <app-page-column>
            <app-password-control
              [control]="form.controls.password_repeat"
              [iconLeft]="'lock_close'"
              [placeholder]="'Repeat Password' | localize"
              [autocomplete]="'new-password'"
            ></app-password-control>
          </app-page-column>
        </app-page-columns>
      </app-page-block>

      <app-page-block *ngFor="let item of customFields">
        <app-register-template-field [form]="form.controls.custom_fields" [field]="item"></app-register-template-field>
      </app-page-block>

      <app-page-block>
        <button
          type="submit"
          class="button button_bigger button_shadow button_fill button_no-margin"
          [class.button_primary]="!domain?.signUpAccentColor"
          [appButtonTintColor]="domain?.signUpAccentColor"
          [appButtonTintColorStyle]="tintStyles.Primary"
          [disabled]="form.invalid || submitLoading"
          (click)="submit.emit()"
        >
          <app-loader-small *ngIf="submitLoading" class="button__icon"></app-loader-small>
          <span *ngIf="!submitLoading" class="button__label">{{ 'Create your free account' | localize }}</span>
        </button>
      </app-page-block>
    </form>

    <ng-container *ngIf="!form">
      <app-page-block>
        <app-text-control [iconLeft]="'user'" [placeholder]="'Enter First Name' | localize"></app-text-control>
      </app-page-block>

      <app-page-block>
        <app-text-control
          [iconLeft]="'user'"
          [placeholder]="'Enter Last Name (optional)' | localize"
        ></app-text-control>
      </app-page-block>

      <app-page-block>
        <app-text-control [iconLeft]="'email'" [placeholder]="'Enter Email' | localize"></app-text-control>
      </app-page-block>

      <app-page-block>
        <app-page-columns [innerPaddingSize]="'xs'">
          <app-page-column>
            <app-text-control [iconLeft]="'lock_close'" [placeholder]="'Password' | localize"></app-text-control>
          </app-page-column>

          <app-page-column>
            <app-text-control [iconLeft]="'lock_close'" [placeholder]="'Repeat Password' | localize"></app-text-control>
          </app-page-column>
        </app-page-columns>
      </app-page-block>

      <app-page-block *ngFor="let item of customFields">
        <app-register-template-field [field]="item"></app-register-template-field>
      </app-page-block>

      <app-page-block>
        <button
          type="button"
          class="button button_bigger button_shadow button_fill button_no-margin"
          [class.button_primary]="!domain?.signUpAccentColor"
          [appButtonTintColor]="domain?.signUpAccentColor"
          [appButtonTintColorStyle]="tintStyles.Primary"
          [disabled]="submitLoading"
          (click)="submit.emit()"
        >
          <app-loader-small *ngIf="submitLoading" class="button__icon"></app-loader-small>
          <span *ngIf="!submitLoading" class="button__label">{{ 'Create your free account' | localize }}</span>
        </button>
      </app-page-block>
    </ng-container>

    <app-page-block *ngIf="!domain" style="margin-bottom: -10px;">
      <div class="auth-form__text">
        {{ 'Creating an account means you’re okay with our' | localize }} <br />
        <a href="https://www.jetadmin.io/terms-of-service" class="auth-form__link" target="_blank">{{
          'Terms of Service' | localize
        }}</a>
        {{ 'and' | localize }}
        <a href="https://www.jetadmin.io/privacy-policy" class="auth-form__link" target="_blank">{{
          'Privacy Policy' | localize
        }}</a>
      </div>
    </app-page-block>

    <app-page-block *ngIf="(domain && domain?.termsOfService) || domain?.privacyPolicy" style="margin-bottom: -10px;">
      <div class="auth-form__text">
        {{ 'Creating an account means you’re okay with our' | localize }} <br />
        <a *ngIf="domain?.termsOfService" [href]="domain?.termsOfService" class="auth-form__link" target="_blank">
          {{ 'Terms of Service' | localize }}
        </a>
        <ng-container *ngIf="domain?.termsOfService && domain?.privacyPolicy"> and </ng-container>
        <a *ngIf="domain?.privacyPolicy" [href]="domain?.privacyPolicy" class="auth-form__link" target="_blank">
          {{ 'Privacy Policy' | localize }}
        </a>
      </div>
    </app-page-block>
  </app-page-block>

  <ng-container *ngIf="layout == 'form'" data-layout-footer>
    <a
      *ngIf="!domain?.disableEmailSignIn || invite"
      [routerLink]="signInLink"
      queryParamsHandling="preserve"
      class="link-block form-layout__footer-item"
    >
      {{ 'Already have an account' | localize }}
    </a>
  </ng-container>

  <app-page-block *ngIf="layout != 'form'" [marginSize]="'l'" data-layout-footer>
    <a
      *ngIf="!domain?.disableEmailSignIn || invite"
      [routerLink]="signInLink"
      queryParamsHandling="preserve"
      class="link-block"
    >
      {{ 'Already have an account' | localize }}
    </a>
  </app-page-block>
</app-layout>

<app-custom-code
  *ngIf="domain"
  [appendScripts]="domain.appendScripts"
  [appendStyles]="domain.appendStyles"
></app-custom-code>
