import { getColorHexStr, getColorVariable } from '@modules/colors';
import { isSet } from '@shared';

export class IconSettings {
  public color: string;
  public colorDark: string;
  public size: number;

  constructor(options: Partial<IconSettings> = {}) {
    Object.assign(this, options);
  }

  deserialize(data: Object): this {
    this.color = data['color'];
    this.colorDark = data['color_dark'];
    this.size = data['size'];

    return this;
  }

  serialize(): Object {
    return {
      color: this.color,
      color_dark: this.colorDark,
      size: this.size
    };
  }

  cssColor(dark: boolean): string {
    const color = dark ? this.colorDark : this.color;

    if (!isSet(color)) {
      return;
    }

    const variable = getColorVariable(color);

    if (isSet(variable)) {
      return `var(--${variable})`;
    }

    return getColorHexStr(color);
  }
}
