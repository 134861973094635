<form [formGroup]="form" (ngSubmit)="submit()">
  <app-customize-bar-header
    [title]="form.controls.name.value || (titleEditable ? 'date range' : 'Date Range')"
    [titleEnabled]="titleEnabled"
    [titleEditable]="titleEditable"
    [titleCleanValue]="titleCleanValue"
    [backLabel]="'All components'"
    [deleteEnabled]="deleteEnabled"
    [parentLabel]="parentElement?.displayName || parentPopup?.displayName"
    (parentClick)="parentElement?.customize({ event: $event }) || parentPopup?.customize({ event: $event })"
    (deleteClick)="delete()"
    (titleChanged)="onTitleChanged($event)"
  >
  </app-customize-bar-header>

  <div class="sidebar__content">
    <app-sidebar-tabs>
      <app-sidebar-tab [label]="'General'">
        <div class="sidebar__element">
          <app-sidebar-field [label]="'label'" [additional]="'(optional)'">
            <ng-container actions>
              <app-text-style-edit-field-action
                [control]="form.controls.element_styles.controls.label_style"
              ></app-text-style-edit-field-action>
            </ng-container>

            <app-auto-field
              [form]="form"
              [label]="false"
              [field]="createField({ name: 'verbose_name', field: 'CharField', params: { classes: ['input_fill'] } })"
            ></app-auto-field>
          </app-sidebar-field>
        </div>

        <div class="sidebar__element">
          <app-customize-bar-input-edit
            [label]="'From'"
            [itemForm]="form.controls.from"
            [context]="context"
            [staticValueField]="fieldTypes.DateTime"
            [placeholder]="'From'"
            [fill]="true"
            [analyticsSource]="element.analyticsGenericName + '_from'"
          ></app-customize-bar-input-edit>
        </div>

        <div class="sidebar__element">
          <app-customize-bar-input-edit
            [label]="'To'"
            [itemForm]="form.controls.to"
            [context]="context"
            [staticValueField]="fieldTypes.DateTime"
            [placeholder]="'To'"
            [fill]="true"
            [analyticsSource]="element.analyticsGenericName + '_to'"
          ></app-customize-bar-input-edit>
        </div>

        <div class="sidebar__element">
          <app-sidebar-field [label]="'date format'">
            <app-auto-field
              [form]="form"
              [label]="false"
              [field]="
                createField({
                  name: 'date_format',
                  field: 'SelectField',
                  params: {
                    options: form.dateFormatEditOptions,
                    classes: [
                      'select_fill',
                      form.controls.date_format.value == dateFormats.Custom ? 'select_segment-top' : ''
                    ]
                  }
                })
              "
            ></app-auto-field>

            <app-auto-field
              *ngIf="form.controls.date_format.value == dateFormats.Custom"
              [form]="form"
              [label]="false"
              [field]="
                createField({
                  name: 'date_format_custom',
                  field: 'CharField',
                  params: { classes: ['input_fill', 'input_segment-bottom'] }
                })
              "
            ></app-auto-field>
          </app-sidebar-field>
        </div>

        <div class="sidebar__element">
          <app-sidebar-field [label]="'Include time'" [layoutLeft]="true">
            <app-auto-field
              [form]="form"
              [label]="false"
              [field]="
                createField({
                  name: 'time',
                  field: 'BooleanField',
                  params: { style: booleanFieldStyle.Toggle, orange: true }
                })
              "
            ></app-auto-field>
          </app-sidebar-field>

          <app-sidebar-field *ngIf="form.controls.time.value" style="display: block; margin-top: 10px;">
            <app-auto-field
              [form]="form"
              [label]="false"
              [field]="
                createField({
                  name: 'time_format',
                  field: 'SelectField',
                  params: {
                    options: form.timeFormatOptions,
                    classes: [
                      'select_fill',
                      form.controls.time_format.value == timeFormats.Custom ? 'select_segment-top' : ''
                    ]
                  }
                })
              "
            ></app-auto-field>

            <app-auto-field
              *ngIf="form.controls.time_format.value == timeFormats.Custom"
              [form]="form"
              [label]="false"
              [field]="
                createField({
                  name: 'time_format_custom',
                  field: 'CharField',
                  params: { classes: ['input_fill', 'input_segment-bottom'] }
                })
              "
            ></app-auto-field>
          </app-sidebar-field>
        </div>
      </app-sidebar-tab>

      <app-sidebar-tab [label]="'Display'">
        <div class="sidebar__element">
          <app-sidebar-field [label]="'label additional'">
            <ng-container actions>
              <app-text-style-edit-field-action
                [control]="form.controls.element_styles.controls.label_additional_style"
              ></app-text-style-edit-field-action>
            </ng-container>

            <app-auto-field
              [form]="form"
              [label]="false"
              [field]="
                createField({
                  name: 'label_additional',
                  field: 'CharField',
                  placeholder: 'Displayed after field label',
                  reset_enabled: true,
                  params: { classes: ['input_fill'] }
                })
              "
            ></app-auto-field>
          </app-sidebar-field>
        </div>

        <div class="sidebar__element">
          <app-sidebar-field [label]="'from placeholder'">
            <app-auto-field
              [form]="form"
              [label]="false"
              [field]="
                createField({
                  name: 'from_placeholder',
                  field: 'CharField',
                  placeholder: 'From description',
                  reset_enabled: true,
                  params: { classes: ['input_fill'] }
                })
              "
            ></app-auto-field>
          </app-sidebar-field>
        </div>

        <div class="sidebar__element">
          <app-sidebar-field [label]="'to placeholder'">
            <app-auto-field
              [form]="form"
              [label]="false"
              [field]="
                createField({
                  name: 'to_placeholder',
                  field: 'CharField',
                  placeholder: 'To description',
                  reset_enabled: true,
                  params: { classes: ['input_fill'] }
                })
              "
            ></app-auto-field>
          </app-sidebar-field>
        </div>

        <div class="sidebar__element">
          <app-sidebar-field [label]="'required'" [layoutLeft]="true">
            <app-auto-field
              [form]="form"
              [label]="false"
              [field]="
                createField({
                  name: 'required',
                  label: 'required',
                  field: 'BooleanField',
                  params: { style: booleanFieldStyle.Toggle, orange: true }
                })
              "
            ></app-auto-field>
          </app-sidebar-field>
        </div>

        <div class="sidebar__element">
          <app-sidebar-field [label]="'show clear button'" [layoutLeft]="true">
            <app-auto-field
              [form]="form"
              [label]="false"
              [field]="
                createField({
                  name: 'reset_enabled',
                  field: 'BooleanField',
                  params: { style: booleanFieldStyle.Toggle, orange: true }
                })
              "
            ></app-auto-field>
          </app-sidebar-field>
        </div>

        <div class="sidebar__element">
          <app-sidebar-field
            [label]="'Conditional Visibility'"
            [documentation]="'conditional-visibility'"
            [documentationLabel]="'See how it works'"
            [documentationSource]="element.analyticsGenericName + '_visible'"
          >
            <app-input-edit
              [itemForm]="form.controls.visible_input"
              [context]="context"
              [staticValueDisabled]="true"
              [placeholder]="'Value'"
              [fill]="true"
              [analyticsSource]="element.analyticsGenericName + '_visible'"
            ></app-input-edit>

            <ng-container description>
              Example: elements.field.value >= 10
            </ng-container>
          </app-sidebar-field>
        </div>

        <div class="sidebar__element">
          <app-sidebar-field
            [label]="'Conditional Disable'"
            [documentationSource]="element.analyticsGenericName + '_disable'"
          >
            <app-input-edit
              [itemForm]="form.controls.disable_input"
              [context]="context"
              [staticValueDisabled]="true"
              [placeholder]="'Value'"
              [fill]="true"
              [analyticsSource]="element.analyticsGenericName + '_disable'"
            ></app-input-edit>

            <ng-container description>
              Example: elements.type.value == 'Refund'
            </ng-container>
          </app-sidebar-field>
        </div>

        <div class="sidebar__element">
          <app-sidebar-field [label]="'tooltip'">
            <app-auto-field
              [form]="form"
              [label]="false"
              [field]="
                createField({
                  name: 'tooltip',
                  field: 'CharField',
                  placeholder: 'Show information for users',
                  reset_enabled: true,
                  params: { classes: ['input_fill'] }
                })
              "
            ></app-auto-field>
          </app-sidebar-field>
        </div>

        <div class="sidebar__element">
          <app-styles-date-range-element [control]="form.controls.element_styles"></app-styles-date-range-element>
        </div>
      </app-sidebar-tab>

      <app-sidebar-tab [label]="'Actions'" [error]="(actionsValid$ | async) !== false ? undefined : 'Not configured'">
        <app-sidebar-section>
          <div class="sidebar__list">
            <div class="sidebar-list__separator">
              <app-sidebar-list-separator [title]="'When value changes'"></app-sidebar-list-separator>
            </div>
            <div class="sidebar-list">
              <div class="sidebar-list__item">
                <app-actions-edit
                  [control]="form.controls.on_change_actions"
                  [context]="context"
                  [contextElement]="contextElement"
                  [contextElementPaths]="itemContextElementPaths"
                  [emptyAction]="'Value changes'"
                  [emptyActionValue]="'Not specified'"
                  [emptyIcon]="'console'"
                  [actionLabel]="'Value changes action'"
                  [object]="element.analyticsGenericName"
                  [firstInit]="firstInit"
                >
                </app-actions-edit>
              </div>
            </div>
          </div>
        </app-sidebar-section>
      </app-sidebar-tab>
    </app-sidebar-tabs>
  </div>
</form>
