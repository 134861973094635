import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Optional } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { colorHexToStr, colors, defaultAccentColor, getColorVariable } from '@modules/colors';
import { globalColorOptions } from '@modules/colors-components';
import { FieldType, registerFieldComponent } from '@modules/fields';
import { ThemeService } from '@modules/theme';
import { isSet } from '@shared';

import { FieldComponent } from '../field/field.component';

@Component({
  selector: 'app-color-field',
  templateUrl: 'color-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColorFieldComponent extends FieldComponent {
  editPopoverOpened = false;
  defaultAccentColor = defaultAccentColor;

  constructor(
    private el: ElementRef,
    @Optional() private themeService: ThemeService,
    public sanitizer: DomSanitizer,
    private cd: ChangeDetectorRef
  ) {
    super();
  }

  get allowEmpty(): boolean {
    return this.field && this.field.params ? !!this.field.params['allow_empty'] : false;
  }

  get emptyColor(): string {
    return this.field && this.field.params ? this.field.params['empty_color'] : undefined;
  }

  get emptyAccentColor(): boolean {
    return this.field && this.field.params ? !!this.field.params['empty_accent_color'] : false;
  }

  get hasEmptyColor(): boolean {
    return isSet(this.emptyColor) || isSet(this.emptyAccentColor);
  }

  get currentValueDisplay(): { label: string; color: string } {
    const value = this.currentValue as string;

    if (!isSet(value) && this.emptyAccentColor) {
      const globalProperty =
        this.themeService && this.themeService.isDarkTheme ? '--accent-color-dark' : '--accent-color';
      const globalColor = getComputedStyle(this.el.nativeElement).getPropertyValue(globalProperty);

      return {
        label: 'Accent color',
        color: globalColor || defaultAccentColor
      };
    } else if (!isSet(value) && isSet(this.emptyColor)) {
      return {
        label: this.emptyColor,
        color: this.emptyColor
      };
    } else if (!isSet(value)) {
      return;
    }

    const variable = getColorVariable(value);
    if (variable) {
      const option = globalColorOptions.find(item => item.value == variable);
      return {
        label: option ? option.name : value,
        color: `var(--${variable})`
      };
    }

    if (value.startsWith('#')) {
      return {
        label: value,
        color: value
      };
    }

    const predefinedColor = colors.find(item => item.name == value);
    if (predefinedColor) {
      return {
        label: predefinedColor.label,
        color: colorHexToStr(predefinedColor.hex)
      };
    }
  }

  openEditPopover(value) {
    if (this.control && this.control.disabled) {
      return;
    }

    this.editPopoverOpened = value;
    this.cd.markForCheck();
  }

  setColor(color: string) {
    this.control.patchValue(color);
  }
}

registerFieldComponent(FieldType.Color, ColorFieldComponent);
