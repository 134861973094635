<div class="custom-page-popup custom-page-popup_style_background">
  <div class="custom-page-popup__header" [class.custom-page-popup__header_overlay]="false">
    <div class="custom-page-popup__header-main">
      <div class="custom-page-popup__title">
        <ng-container *ngIf="!group">{{ 'Adding Team' | localize }}</ng-container>
        <ng-container *ngIf="group">
          <ng-container *ngIf="group.protected">
            {{ formGroupChange.controls.name.value }}
          </ng-container>
          <ng-container *ngIf="!group.protected">
            <app-editable-content
              [value]="formGroupChange.controls.name.value"
              (valueChange)="onGroupNameChange($event)"
              [pen]="true"
              [inline]="true"
              [multiline]="false"
            ></app-editable-content>
            <app-field-errors [form]="formGroupChange" [fieldName]="'name'"></app-field-errors>
          </ng-container>
        </ng-container>
      </div>
      <div class="custom-page-popup__description">
        <ng-container *ngIf="group?.getDescription()">
          {{ group.getDescription() }}
        </ng-container>
        <ng-container *ngIf="!group?.getDescription()">
          {{ 'Keep control over who has access to current App' | localize }}
        </ng-container>
      </div>
    </div>
    <div class="custom-page-popup__header-right">
      <div class="custom-page-popup__close icon-close" (click)="back()"></div>
    </div>
  </div>
  <div class="custom-page-popup__content">
    <app-page-block *ngIf="!group" [paddingSize]="'s'">
      <div class="custom-page-popup__section">
        <app-page-block>
          <app-auto-field
            [form]="formGroupChange"
            [autofocus]="true"
            [field]="
              createField({
                name: 'name',
                label: 'Enter Team Name' | localize,
                field: 'CharField',
                required: true,
                placeholder: 'Type the team name, i.e: Support, Sales, Marketing, etc.' | localize,
                params: { classes: ['input_fill'] }
              })
            "
          >
          </app-auto-field>
        </app-page-block>
      </div>
    </app-page-block>

    <app-page-block [paddingSize]="'s'">
      <div class="custom-page-popup__section">
        <div class="tabs2" style="padding: 0 10px;">
          <div class="tabs2-navigation">
            <a
              *ngFor="let tab of tabItems"
              href="javascript:void(0)"
              class="tabs2-navigation__item"
              [class.tabs2-navigation__item_active]="tab.name == activeTabName"
              (click)="setActiveTabName(tab.name)"
            >
              {{ tab.title }}
            </a>
          </div>
        </div>

        <hr />

        <div [style.overflow]="'auto'" [style.max-height]="group ? 'calc(100vh - 342px)' : 'calc(100vh - 468px)'">
          <ng-container *ngIf="activeTabName == tabs.Members">
            <app-page-header>
              <app-page-title>{{ 'Members' | localize }}</app-page-title>
              <app-page-description>
                {{ 'Add a new member to this team.' | localize }}
              </app-page-description>
            </app-page-header>

            <ng-container *ngIf="group">
              <ng-container *ngIf="!loadingUsers">
                <form *ngIf="formUser.form" [formGroup]="formUser.form" (ngSubmit)="submitUser()">
                  <app-page-block [fill]="true" [size]="'m'" [marginSize]="'l'" [first]="true">
                    <app-page-block>
                      <app-page-heading [size]="'s'">
                        {{ 'Invite new member' | localize }}
                      </app-page-heading>
                    </app-page-block>

                    <app-page-block>
                      <div class="share-form__header">
                        <div class="share-form__field">
                          <input
                            class="input share-form__input share-form__input_without-select"
                            type="text"
                            formControlName="user_email"
                            [placeholder]="'Invite someone...' | localize"
                            [appTutorialTip]="{
                              tip: 'Enter user Email',
                              task: 'invite_user',
                              page: 1,
                              pageItem: 'choose_user',
                              action: 'input',
                              formGroup: formUser.form
                            }"
                          />
                        </div>
                        <button
                          type="submit"
                          class="button button_primary button_bigger share-form__button"
                          [disabled]="formUser.form.invalid || formUser.form.disabled || !group"
                          [appTutorialTip]="{
                            tip: 'Send Invite',
                            task: 'invite_user',
                            page: 1,
                            pageItem: 'send_invite',
                            action: 'none'
                          }"
                        >
                          {{ 'Send Invite' | localize }}
                        </button>
                      </div>
                    </app-page-block>
                  </app-page-block>
                </form>

                <ng-container *ngIf="invites?.length">
                  <app-page-block>
                    <app-page-heading>
                      {{ 'Pending Invites' | localize }} <span class="page-heading__dim">({{ invites.length }})</span>
                    </app-page-heading>
                  </app-page-block>

                  <app-page-block [fill]="true">
                    <table class="table table_compact">
                      <thead>
                        <tr class="table__heading-row">
                          <th class="table__heading-column table__heading-column_left-padding" colspan="2">
                            {{ 'User' | localize }}
                          </th>
                          <th class="table__heading-column">
                            {{ 'Date Added' | localize }}
                          </th>
                          <th class="table__heading-column table__column_right"></th>
                          <th class="table__column"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          *ngFor="let item of invites; let i = index"
                          app-project-invite-item
                          [item]="item"
                          [group]="false"
                          class="table__row"
                          (deleted)="getUsers()"
                        ></tr>
                      </tbody>
                    </table>
                  </app-page-block>
                </ng-container>

                <ng-container *ngIf="users?.length">
                  <app-page-block *ngIf="invites?.length">
                    <app-page-heading>
                      {{ 'Active Members' | localize }} <span class="list__sign">({{ users?.length }})</span>
                    </app-page-heading>
                  </app-page-block>

                  <app-page-block [fill]="true">
                    <table class="table table_compact">
                      <thead>
                        <tr class="table__heading-row">
                          <th class="table__heading-column table__heading-column_left-padding" colspan="2">
                            {{ 'User' | localize }}
                          </th>
                          <th class="table__heading-column">
                            {{ 'Date Added' | localize }}
                          </th>
                          <th class="table__heading-column table__column_right"></th>
                          <th class="table__column"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          *ngFor="let item of users; let i = index"
                          app-project-users-item
                          [item]="item"
                          [group]="false"
                          class="table__row"
                          (deleted)="getUsers()"
                        ></tr>
                      </tbody>
                    </table>
                  </app-page-block>
                </ng-container>

                <app-page-block *ngIf="!users?.length && !invites?.length">
                  {{ 'No users in this team' | localize }}
                </app-page-block>
              </ng-container>

              <app-page-block *ngIf="loadingUsers" [fill]="true">
                <table class="table table_compact">
                  <thead>
                    <tr class="table__heading-row">
                      <th class="table__heading-column table__heading-column_left-padding" colspan="2">
                        <span [class.loading-animation]="true">
                          <span class="stub-text">User</span>
                        </span>
                      </th>
                      <th class="table__heading-column">
                        <span [class.loading-animation]="true">
                          <span class="stub-text">Team</span>
                        </span>
                      </th>
                      <th class="table__heading-column">
                        <span [class.loading-animation]="true">
                          <span class="stub-text">Date Added</span>
                        </span>
                      </th>
                      <th class="table__heading-column table__column_right"></th>
                      <th class="table__column"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of [1, 2, 3, 4]" app-project-users-item-stub class="table__row"></tr>
                  </tbody>
                </table>
              </app-page-block>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="activeTabName == tabs.PagePermissions">
            <app-page-block>
              <div class="list">
                <div class="list__content list__content_border">
                  <table class="table table_compact" [class.table_disabled]="group?.protected">
                    <thead>
                      <tr class="table__heading-row">
                        <th class="table__heading-column table__heading-column_left-padding" colspan="2">
                          {{ 'Page name' | localize }}
                        </th>
                        <th class="table__heading-column">
                          {{ 'Access' | localize }} (<a
                            href="javascript:void(0)"
                            class="link"
                            (click)="setPagePermissionsFullAccess()"
                            >{{ 'Full Access' | localize }}</a
                          >,
                          <a href="javascript:void(0)" class="link" (click)="setPagePermissionsReadOnly()">{{
                            'Read Only' | localize
                          }}</a
                          >,
                          <a href="javascript:void(0)" class="link" (click)="setPagePermissionsNoAccess()">{{
                            'No Access' | localize
                          }}</a
                          >)
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        *ngFor="let item of formGroupChange.controls.page_permissions.controls; let i = index"
                        class="table__row"
                      >
                        <td class="table__column table__column_left-padding"></td>
                        <td class="table__column">
                          <div class="table__column-inner">
                            {{ item.data.page.name | appCapitalize }} ({{ item.data.page.uniqueName }})
                          </div>
                        </td>
                        <td class="table__column">
                          <div class="table__column-inner">
                            <app-project-permission-actions
                              [control]="item.controls.actions"
                              [small]="true"
                              #page_permission_actions
                            ></app-project-permission-actions>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </app-page-block>
          </ng-container>

          <ng-container *ngIf="activeTabName == tabs.ProjectPermissions">
            <!--  <app-section>-->
            <!--    <app-page-block>-->
            <!--      <div [class.page-disabled]="group?.protected">-->
            <!--        <app-auto-field-->
            <!--          [form]="formGroupChange"-->
            <!--          [field]="createField({ name: 'super_group', label: 'Full Access', field: 'BooleanField' })"-->
            <!--        >-->
            <!--        </app-auto-field>-->
            <!--      </div>-->
            <!--    </app-page-block>-->
            <!--  </app-section>-->

            <app-page-block>
              <div class="list">
                <div class="list__content list__content_border">
                  <table class="table table_compact" [class.table_disabled]="group?.protected">
                    <thead>
                      <tr class="table__heading-row">
                        <th class="table__heading-column table__heading-column_left-padding" colspan="2">
                          {{ 'Name' | localize }}
                        </th>
                        <th class="table__heading-column" width="90" style="text-align: center;">
                          <div>{{ 'Active' | localize }}</div>
                        </th>
                        <th class="table__heading-column"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="table__row">
                        <td class="table__column table__column_left-padding"></td>
                        <td class="table__column table__column_accent">
                          <div class="table__column-inner">
                            <span class="icon-gear_2 table__icon"></span>
                            {{ 'Settings' | localize }}
                          </div>
                        </td>
                        <td class="table__column" style="text-align: center;">
                          <div class="table__column-inner">
                            <input
                              class="checkbox checkbox_round"
                              type="checkbox"
                              [formControl]="
                                formGroupChange.controls.permissions.controls.project_settings.controls.enabled
                              "
                            />
                          </div>
                        </td>
                        <td class="table__column"></td>
                      </tr>
                      <tr class="table__row">
                        <td class="table__column table__column_left-padding"></td>
                        <td class="table__column table__column_accent">
                          <div class="table__column-inner">
                            <span class="icon icon-payments table__icon"></span>
                            {{ 'Billing' | localize }}
                          </div>
                        </td>
                        <td class="table__column" style="text-align: center;">
                          <div class="table__column-inner">
                            <input
                              type="checkbox"
                              class="checkbox checkbox_round"
                              [formControl]="
                                formGroupChange.controls.permissions.controls.project_billing.controls.enabled
                              "
                            />
                          </div>
                        </td>
                        <td class="table__column"></td>
                      </tr>
                      <tr class="table__row">
                        <td class="table__column table__column_left-padding"></td>
                        <td class="table__column table__column_accent">
                          <div class="table__column-inner">
                            <span class="icon icon-users_teams table__icon"></span>
                            {{ 'Members and Teams' | localize }}
                          </div>
                        </td>
                        <td class="table__column" style="text-align: center;">
                          <div class="table__column-inner">
                            <input
                              type="checkbox"
                              class="checkbox checkbox_round"
                              [formControl]="
                                formGroupChange.controls.permissions.controls.project_access.controls.enabled
                              "
                            />
                          </div>
                        </td>
                        <td class="table__column"></td>
                      </tr>
                      <tr class="table__row">
                        <td class="table__column table__column_left-padding"></td>
                        <td class="table__column table__column_accent">
                          <div class="table__column-inner">
                            <span class="icon icon-brush table__icon"></span>
                            {{ 'App Builder' | localize }}
                          </div>
                        </td>
                        <td class="table__column" style="text-align: center;">
                          <div class="table__column-inner">
                            <input
                              type="checkbox"
                              class="checkbox checkbox_round"
                              [formControl]="
                                formGroupChange.controls.permissions.controls.project_customization.controls.enabled
                              "
                            />
                          </div>
                        </td>
                        <td class="table__column"></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </app-page-block>

            <ng-container *ngIf="false">
              <app-page-block [marginSize]="'s'">
                <app-page-heading [size]="'s'">
                  {{ 'Collection Permissions' | localize }}
                </app-page-heading>
                <app-page-description>
                  {{ "Grant permissions to app's collection" | localize }}
                </app-page-description>
              </app-page-block>

              <app-page-block>
                <div class="list">
                  <div class="list__content list__content_border">
                    <table class="table table_compact" [class.table_disabled]="group?.protected">
                      <thead>
                        <tr class="table__heading-row">
                          <th class="table__heading-column table__heading-column_left-padding" colspan="2">
                            {{ 'Collection' | localize }}
                          </th>
                          <th class="table__heading-column" width="90" style="text-align: center;">
                            <a
                              href="javascript:void(0)"
                              (click)="formGroupChange.controls.model_permissions.toggleEveryControlAllActions()"
                              >{{ 'All' | localize }}</a
                            >
                          </th>
                          <th class="table__heading-column" width="90" style="text-align: center;">
                            <a
                              href="javascript:void(0)"
                              (click)="formGroupChange.controls.model_permissions.toggleEveryControlAction('read')"
                              >{{ 'Read' | localize }}</a
                            >
                          </th>
                          <th class="table__heading-column" width="90" style="text-align: center;">
                            <a
                              href="javascript:void(0)"
                              (click)="formGroupChange.controls.model_permissions.toggleEveryControlAction('write')"
                              >{{ 'Write' | localize }}</a
                            >
                          </th>
                          <th class="table__heading-column" width="90" style="text-align: center;">
                            <a
                              href="javascript:void(0)"
                              (click)="formGroupChange.controls.model_permissions.toggleEveryControlAction('delete')"
                              >{{ 'Delete' | localize }}</a
                            >
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          *ngFor="let item of formGroupChange.controls.model_permissions.controls; let i = index"
                          class="table__row"
                        >
                          <td class="table__column table__column_left-padding"></td>
                          <td class="table__column">
                            <div class="table__column-inner">
                              {{ item.data.modelDescription.verboseNamePlural | appCapitalize }} ({{
                                item.data.modelDescription.model
                              }})
                            </div>
                          </td>
                          <td class="table__column" style="text-align: center;">
                            <div class="table__column-inner">
                              <input
                                type="checkbox"
                                class="checkbox checkbox_round"
                                [checked]="item.controls.actions.isSelectedAll()"
                                (change)="item.controls.actions.toggleAll()"
                              />
                            </div>
                          </td>
                          <td class="table__column" style="text-align: center;">
                            <div class="table__column-inner">
                              <input
                                type="checkbox"
                                class="checkbox checkbox_round"
                                [formControl]="item.controls.actions.controls.read"
                              />
                            </div>
                          </td>
                          <td class="table__column" style="text-align: center;">
                            <div class="table__column-inner">
                              <input
                                type="checkbox"
                                class="checkbox checkbox_round"
                                [formControl]="item.controls.actions.controls.write"
                              />
                            </div>
                          </td>
                          <td class="table__column" style="text-align: center;">
                            <div class="table__column-inner">
                              <input
                                type="checkbox"
                                class="checkbox checkbox_round"
                                [formControl]="item.controls.actions.controls.delete"
                              />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </app-page-block>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="activeTabName == tabs.Properties">
            <app-project-property-change
              [control]="formGroupChange.controls.properties"
              [description]="'Limit access to your data by team properties' | localize"
              [analyticsSource]="'team'"
            >
            </app-project-property-change>
          </ng-container>
        </div>
      </div>
    </app-page-block>

    <app-page-block>
      <app-stack [align]="'right'">
        <ng-container *ngIf="group && !group.protected">
          <a
            *ngIf="deleteEnabled"
            href="javascript:void(0)"
            class="button button_danger popup2__footer-item"
            [class.button_disabled]="loading"
            (click)="requestDelete()"
          >
            {{ 'Delete' | localize }}
          </a>
          <span class="popup2__footer-spacing"></span>
        </ng-container>

        <a
          href="javascript:void(0)"
          class="button popup2__footer-item"
          [class.button_disabled]="loading"
          [appButtonTintColor]="'accentColor' | appThemeColorHexOption: true | async"
          [appButtonTintColorStyle]="tintStyles.Transparent"
          (click)="back()"
        >
          {{ 'Cancel' | localize }}
        </a>

        <button
          type="button"
          class="button button_primary popup2__footer-item"
          [class.button_disabled]="formGroupChange.invalid || formGroupChange.pending || loading"
          [appButtonTintColor]="'accentColor' | appThemeColorHexOption: true | async"
          [appButtonTintColorStyle]="tintStyles.Primary"
          (click)="submit()"
        >
          <app-loader-small *ngIf="loading" class="button__icon button__icon_left"></app-loader-small>
          <span class="button__label">
            <ng-container *ngIf="group">{{ 'Save Team' | localize }}</ng-container>
            <ng-container *ngIf="!group">{{ 'Create Team' | localize }}</ng-container>
          </span>
        </button>
      </app-stack>
    </app-page-block>
  </div>
</div>
