<ng-container
  *ngIf="
    form.controls.export_data_type.value == exportDataTypes.DataSource &&
    (form.controls.export_data_source.inputFieldProvider.getItems$() | async)?.length
  "
>
  <div class="sidebar-section-border"></div>

  <app-sidebar-section
    [title]="(form.controls.export_data_source.getQueryEditable$() | async) ? 'Specify Inputs' : 'Apply Filters'"
    [description]="
      (form.controls.export_data_source.getQueryEditable$() | async)
        ? 'Manually or use value from other components'
        : 'Static or use value from other components'
    "
    [border]="true"
  >
    <app-data-source-inputs-edit
      [control]="form.controls.export_data_source"
      [editable]="form.controls.export_data_source.getQueryEditable$() | async"
      [context]="context"
      [contextElement]="contextElement"
      [contextElementPath]="contextElementPath"
      [contextElementPaths]="contextElementPaths"
      [userInput]="userInput"
      [classes]="'component-action-inputs'"
      [itemClasses]="'component-action-inputs-item'"
      [analyticsSource]="analyticsSource"
      (addInput)="addQueryInput.emit()"
    ></app-data-source-inputs-edit>
  </app-sidebar-section>
</ng-container>
