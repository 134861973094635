import { CdkConnectedOverlay, ConnectedOverlayPositionChange, ConnectedPosition } from '@angular/cdk/overlay';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, Subscription } from 'rxjs';

import { getDateTimeFieldMomentFormat } from '@modules/fields';
import { controlValue, isSet } from '@shared';

enum Mode {
  Since = 'since',
  Before = 'before',
  Between = 'Between'
}

@Component({
  selector: 'app-widget-date-selector',
  templateUrl: './widget-date-selector.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WidgetDateSelectorComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() date = false;
  @Input() time = false;
  @Input() xDateFromControl = new FormControl();
  @Input() xDateToControl = new FormControl();
  @Input() theme = false;

  @ViewChild(CdkConnectedOverlay) cdkConnectedOverlay: CdkConnectedOverlay;

  opened = false;
  mode = Mode.Since;
  modes = Mode;
  valueLabel: string;
  betweenModeFrom = true;
  inputWidth: number;
  editFormat: string;
  popoverPositions: ConnectedPosition[] = [
    {
      panelClass: ['overlay_position_top-right'],
      originX: 'end',
      overlayX: 'end',
      originY: 'top',
      overlayY: 'bottom',
      offsetX: 8,
      offsetY: 0,
      weight: 4
    },
    {
      panelClass: ['overlay_position_bottom-right'],
      originX: 'end',
      overlayX: 'start',
      originY: 'top',
      overlayY: 'top',
      offsetX: -4,
      offsetY: -8 - 60 + 4,
      weight: 3
    },
    {
      panelClass: ['overlay_position_top-right'],
      originX: 'end',
      overlayX: 'start',
      originY: 'bottom',
      overlayY: 'bottom',
      offsetX: -4,
      offsetY: 8 + 20,
      weight: 2
    },
    {
      panelClass: ['overlay_position_bottom-right'],
      originX: 'end',
      overlayX: 'end',
      originY: 'bottom',
      overlayY: 'top',
      offsetX: 8,
      offsetY: 0,
      weight: 1
    }
  ];
  popoverPositionsSubscription: Subscription;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {
    combineLatest(controlValue(this.xDateFromControl), controlValue(this.xDateToControl))
      .pipe(untilDestroyed(this))
      .subscribe(([fromValue, toValue]) => {
        this.valueLabel = this.getValueLabel(fromValue, toValue);
        this.cd.markForCheck();
      });
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['date'] || changes['time']) {
      this.editFormat = getDateTimeFieldMomentFormat({ params: { date: this.date, time: this.time } }, true);
      this.inputWidth = this.date && this.time ? 130 : 110;
    }
  }

  ngAfterViewInit(): void {
    this.setPositionObserver();
  }

  getValueLabel(fromValue: string, toValue: string): string {
    const fromDate = isSet(fromValue) ? moment(fromValue) : undefined;
    const fromDateValid = fromDate ? fromDate.isValid() : false;
    const toDate = isSet(toValue) ? moment(toValue) : undefined;
    const toDateValid = toDate ? toDate.isValid() : false;

    if (fromDateValid && toDateValid) {
      return `${fromDate.format('ll')} - ${toDate.format('ll')}`;
    } else if (fromDateValid) {
      return `Since ${fromDate.format('ll')}`;
    } else if (toDateValid) {
      return `Before ${toDate.format('ll')}`;
    }
  }

  setOpened(value: boolean) {
    this.opened = value;
    this.cd.markForCheck();
  }

  setBetweenModeFrom(value: boolean) {
    this.betweenModeFrom = value;
    this.cd.markForCheck();
  }

  onPopoverContentChanged() {
    this.cdkConnectedOverlay.overlayRef.updatePosition();
  }

  setMode(value: Mode) {
    this.mode = value;
    this.cd.markForCheck();

    if (value === Mode.Since) {
      this.xDateToControl.patchValue(null);
    } else if (value === Mode.Before) {
      this.xDateFromControl.patchValue(null);
    } else if (value === Mode.Between) {
      this.betweenModeFrom = true;
      this.cd.markForCheck();
    }
  }

  setPositionObserver() {
    if (this.popoverPositionsSubscription) {
      this.popoverPositionsSubscription.unsubscribe();
    }

    if (!this.cdkConnectedOverlay) {
      return;
    }

    this.popoverPositionsSubscription = this.cdkConnectedOverlay.positionChange
      .pipe(untilDestroyed(this))
      .subscribe((e: ConnectedOverlayPositionChange) => {
        const propsEqual = ['offsetX', 'offsetY', 'originX', 'originY', 'overlayX', 'overlayY'];
        const position = this.popoverPositions.find(item =>
          propsEqual.every(prop => (item[prop] || undefined) == e.connectionPair[prop])
        );
        const otherPosition = this.popoverPositions.filter(item => item !== position);

        if (position) {
          this.cdkConnectedOverlay.overlayRef.addPanelClass(position.panelClass);
        }

        otherPosition.forEach(item => this.cdkConnectedOverlay.overlayRef.removePanelClass(item.panelClass));
      });
  }
}
