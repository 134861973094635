var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { ProjectSettingsStore } from '@modules/all-project-settings';
import { MenuSettingsStore } from '@modules/menu';
import { MenuUpdateForm } from './menu-update.form';
import { ProjectAppearanceForm } from './project-appearance.form';
var ProjectAppearanceContext = /** @class */ (function (_super) {
    __extends(ProjectAppearanceContext, _super);
    function ProjectAppearanceContext(injector, projectSettingsStore, menuSettingsStore) {
        var _this = _super.call(this, {
            appearance: ProjectAppearanceForm.inject(injector),
            menu: MenuUpdateForm.inject(injector)
        }) || this;
        _this.injector = injector;
        _this.projectSettingsStore = projectSettingsStore;
        _this.menuSettingsStore = menuSettingsStore;
        _this.initSubscriptions = [];
        _this.savedState$ = new BehaviorSubject(undefined);
        _this.hasChanges$ = new BehaviorSubject({ projectSettings: false, menuSettings: false });
        _this.init();
        return _this;
    }
    ProjectAppearanceContext.prototype.init = function () {
        var _this = this;
        this.initSubscriptions.forEach(function (item) { return item.unsubscribe(); });
        this.initSubscriptions = [];
        var subscriptions = [];
        subscriptions.push(combineLatest(this.projectSettingsStore.getAllSettingsFirst$(), this.menuSettingsStore.getFirst())
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var projectSettings = _a[0], menuSettings = _a[1];
            _this.controls.appearance.init(projectSettings);
            _this.controls.menu.init(menuSettings);
            _this.savedState$.next(_this.getState());
            _this.hasChanges$.next({ projectSettings: false, menuSettings: false });
            subscriptions.push(_this.valueChanges.pipe(debounceTime(200), untilDestroyed(_this)).subscribe(function () {
                var savedState = _this.savedState$.value;
                if (savedState) {
                    var currentState = _this.getState();
                    var hasChanges = _this.getStateChanges(currentState, savedState);
                    _this.hasChanges$.next(hasChanges);
                }
                else {
                    _this.hasChanges$.next({ projectSettings: false, menuSettings: false });
                }
            }));
        }));
        this.initSubscriptions = subscriptions;
    };
    ProjectAppearanceContext.prototype.ngOnDestroy = function () { };
    ProjectAppearanceContext.prototype.getState = function () {
        return { projectSettings: this.controls.appearance.getInstance(), menuSettings: this.controls.menu.getInstance() };
    };
    ProjectAppearanceContext.prototype.getHasChanges = function () {
        var changes = this.hasChanges$.value;
        return changes.projectSettings || changes.menuSettings;
    };
    ProjectAppearanceContext.prototype.getHasChanges$ = function () {
        return this.hasChanges$.pipe(map(function (changes) { return changes.projectSettings || changes.menuSettings; }));
    };
    ProjectAppearanceContext.prototype.getStateProjectSettingsChanges = function (lhs, rhs) {
        var names = this.controls.appearance.getSerializeSettingNames();
        return lhs.settingsTypes.reduce(function (acc, item) {
            if (names.includes(item.name)) {
                var properties = item.properties.sort();
                if (properties.some(function (property) { return !isEqual(lhs[property], rhs[property]); }) && !acc.includes(item.name)) {
                    acc.push(item.name);
                }
            }
            return acc;
        }, []);
    };
    ProjectAppearanceContext.prototype.isStateMenuEqual = function (lhs, rhs) {
        return isEqual(lhs.blocks.map(function (item) { return item.serialize(); }), rhs.blocks.map(function (item) { return item.serialize(); }));
    };
    ProjectAppearanceContext.prototype.getStateChanges = function (lhs, rhs) {
        var projectSettingsChanges = this.getStateProjectSettingsChanges(lhs.projectSettings, rhs.projectSettings);
        return {
            projectSettings: projectSettingsChanges.length > 0,
            projectSettingsChanges: projectSettingsChanges,
            menuSettings: !this.isStateMenuEqual(lhs.menuSettings, rhs.menuSettings)
        };
    };
    ProjectAppearanceContext.prototype.isStateEqual = function (lhs, rhs) {
        var changes = this.getStateChanges(lhs, rhs);
        return !changes.projectSettings && !changes.menuSettings;
    };
    ProjectAppearanceContext.prototype.setState = function (state, save) {
        if (save === void 0) { save = false; }
        this.controls.appearance.init(cloneDeep(state.projectSettings));
        this.controls.menu.init(cloneDeep(state.menuSettings));
        if (save) {
            this.savedState$.next(state);
            this.hasChanges$.next({ projectSettings: false, menuSettings: false });
        }
    };
    ProjectAppearanceContext.prototype.resetSavedState = function () {
        var savedState = this.savedState$.value;
        if (!savedState) {
            return;
        }
        this.setState(savedState, true);
    };
    ProjectAppearanceContext.prototype.saveCurrentState = function () {
        var state = this.getState();
        this.setState(state, true);
    };
    ProjectAppearanceContext.prototype.submit = function () {
        var savedState = this.savedState$.value;
        var hasChanges$ = this.hasChanges$.value;
        var obs$ = [];
        if (hasChanges$.projectSettings) {
            obs$.push(this.controls.appearance.submit());
        }
        if (hasChanges$.menuSettings) {
            obs$.push(this.controls.menu.submit());
        }
        if (!obs$.length) {
            return of({
                state: savedState,
                changes: hasChanges$
            });
        }
        return combineLatest(obs$).pipe(map(function () {
            return {
                state: savedState,
                changes: hasChanges$
            };
        }));
    };
    return ProjectAppearanceContext;
}(FormGroup));
export { ProjectAppearanceContext };
