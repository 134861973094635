import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

import { DialogService } from '@common/dialogs';
import { SidebarCollapseContext } from '@modules/sidebar';
import { ConditionWorkflowStep, ConditionWorkflowStepType, WorkflowStepType } from '@modules/workflow';
import { coerceArray, isSet } from '@shared';

import { registerCustomizeWorkflowStepComponent } from '../../../../data/customize-workflow-step-components';
import { CustomizeWorkflowStepComponent } from '../base-customize-workflow-step/base-customize-workflow-step.component';
import { ConditionCustomizeWorkflowStepItemControl } from './condition-customize-workflow-step-item.control';
import { ConditionCustomizeWorkflowStepForm } from './condition-customize-workflow-step.form';

@Component({
  selector: 'app-condition-customize-workflow-step',
  templateUrl: './condition-customize-workflow-step.component.html',
  providers: [ConditionCustomizeWorkflowStepForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConditionCustomizeWorkflowStepComponent extends CustomizeWorkflowStepComponent<ConditionWorkflowStep>
  implements OnInit, OnDestroy {
  conditionTypes = ConditionWorkflowStepType;
  collapseContext = new SidebarCollapseContext();
  createdItem: ConditionCustomizeWorkflowStepItemControl;
  elseHovered$ = new BehaviorSubject<boolean>(false);

  trackByFn(i, item: ConditionCustomizeWorkflowStepItemControl) {
    const path = coerceArray(item.controls.condition.controls.path.value);

    if (path.length) {
      return 'field_' + path.join('__');
    } else {
      return i;
    }
  }

  constructor(
    public form: ConditionCustomizeWorkflowStepForm,
    private dialogService: DialogService,
    private cd: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this.form.init(this.step, { firstInit: false });

    if (this.workflowEditable) {
      this.form.valueChanges
        .pipe(
          debounceTime(200),
          map(() => this.form.submit()),
          untilDestroyed(this)
        )
        .subscribe(result => {
          this.submit(result);
        });
    }
  }

  ngOnDestroy(): void {}

  submit(value?: ConditionWorkflowStep) {
    if (!value) {
      value = this.form.submit();
    }

    this.stepChange.emit(value);
  }

  addItem() {
    const control = this.form.controls.items.createControl();
    this.form.controls.items.push(control);
    this.createdItem = control;
    this.cd.markForCheck();
  }

  removeItem(control: ConditionCustomizeWorkflowStepItemControl, i: number) {
    // this.dialogService
    //   .warning({
    //     title: 'Delete Condition',
    //     description: `Are you sure want to delete <strong>Condition ${i + 1}</strong>?`,
    //     style: 'orange'
    //   })
    //   .pipe(untilDestroyed(this))
    //   .subscribe(result => {
    //     if (result) {
    this.form.controls.items.removeControl(control);
    this.cd.markForCheck();
    //   }
    // });
  }

  dragDropItem(event: CdkDragDrop<ConditionCustomizeWorkflowStepItemControl[]>) {
    if (event.previousIndex !== event.currentIndex) {
      moveItemInArray(this.form.controls.items.controls, event.previousIndex, event.currentIndex);
      this.form.controls.items.updateValueAndValidity();
      this.cd.markForCheck();
    }
  }
}

registerCustomizeWorkflowStepComponent(WorkflowStepType.Condition, ConditionCustomizeWorkflowStepComponent);
