import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Renderer2
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import Plyr from 'plyr';
import { Subscription } from 'rxjs';

import { elementResize$, isSet, toggleClass, TypedChanges } from '@shared';

@Component({
  selector: 'app-audio-player',
  templateUrl: './audio-player.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AudioPlayerComponent implements OnInit, OnDestroy, OnChanges {
  @Input() src: string;

  playerElement: HTMLAudioElement;
  playerSubscription: Subscription;
  player: any;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    this.initPlayer();
  }

  ngOnDestroy(): void {
    if (this.player) {
      this.player.destroy();
    }
  }

  ngOnChanges(changes: TypedChanges<AudioPlayerComponent>): void {
    if (changes.src && this.player) {
      this.updateSource();
    }
  }

  initPlayer() {
    // Manual DOM element creation is required because Plyr wraps media DOM element
    const playerElement = this.el.nativeElement.querySelector('audio');

    if (playerElement) {
      this.playerElement = playerElement;
    } else {
      this.playerElement = this.renderer.createElement('audio');
      this.playerElement.controls = true;

      this.renderer.appendChild(this.el.nativeElement, this.playerElement);
    }

    this.player = new Plyr(this.playerElement);
    this.updateSource();

    if (this.playerSubscription) {
      this.playerSubscription.unsubscribe();
      this.playerSubscription = undefined;
    }

    const plyrElement = this.el.nativeElement.querySelector('.plyr');
    if (plyrElement) {
      this.playerSubscription = elementResize$(plyrElement)
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          const width = plyrElement.offsetWidth;
          const height = plyrElement.offsetHeight;

          toggleClass(plyrElement, 'plyr--size--350', width <= 350);
          toggleClass(plyrElement, 'plyr--size--280', width <= 280);
          toggleClass(plyrElement, 'plyr--size--230', width <= 230);
          toggleClass(plyrElement, 'plyr--size--165', width <= 165);
          toggleClass(plyrElement, 'plyr--size--140', width <= 140);
          toggleClass(plyrElement, 'plyr--height--100', height <= 100);
          toggleClass(plyrElement, 'plyr--height--260', height <= 260);
        });
    }
  }

  updateSource() {
    this.player.source = {
      type: 'audio',
      sources: isSet(this.src)
        ? [
            {
              src: this.src,
              type: 'audio/mp3'
            }
          ]
        : []
    };
  }
}
