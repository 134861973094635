<div
  class="sidebar-summary-block sidebar-summary-block_border"
  [class.sidebar-summary-block_active]="active"
  [class.sidebar-summary-block_disabled]="disabled && control.controls.layout.value"
  [class.sidebar-summary-block_draggable]="control.controls.layout.value"
  [class.sidebar-summary-block_clickable]="!control.controls.enabled.value"
  (click)="onClick($event)"
>
  <div class="sidebar-summary-block__header">
    <div class="sidebar-summary-block__header-left">
      <div class="sidebar-summary-block__header-drag icon-dots_small"></div>
    </div>

    <div class="sidebar-summary-block__header-main">
      <div
        class="sidebar-summary-block__header-title"
        [class.sidebar-summary-block__header-title_disabled]="!control.controls.layout.value"
      >
        {{ title }}
      </div>
    </div>

    <div class="sidebar-summary-block__header-right">
      <app-toggle
        *ngIf="!control.controls.enabled_input_enabled.value"
        class="sidebar-summary-block__header-right-item"
        [label]="'Enabled'"
        [labelPosition]="'right'"
        [orange]="true"
        [selected]="control.controls.enabled.value"
        (click)="$event.stopPropagation(); toggleEnabled()"
      ></app-toggle>

      <span
        class="sidebar-summary-block__button icon-function sidebar-summary-block__header-right-item"
        [class.sidebar-summary-block__button_active]="control.controls.enabled_input_enabled.value"
        [appTip]="!control.controls.enabled_input_enabled.value ? 'Conditional Enabled' : 'Disable Conditional Enabled'"
        [appTipOptions]="{ side: 'left' }"
        (click)="toggleEnabledInput()"
      ></span>
    </div>
  </div>

  <div class="sidebar-summary-block__content">
    <div
      *ngIf="control.controls.enabled_input_enabled.value"
      class="sidebar-summary-block-item sidebar-summary-block-item_interactive sidebar-summary-block-item_padding_s sidebar-summary-block__item"
      (mousedown)="$event.stopPropagation()"
    >
      <div class="sidebar-summary-block-item__main">
        <div [style.width.%]="100">
          <div class="sidebar-summary-block-item__heading">Conditional Enabled:</div>
          <app-input-edit
            [itemForm]="control.controls.enabled_input"
            [context]="context"
            [staticValueDisabled]="true"
            [focusedInitial]="enabledInputToggled"
            [formulaPlaceholder]="''"
            [fill]="true"
            [small]="true"
          ></app-input-edit>
        </div>
      </div>
    </div>

    <app-menu-block-layout-overlay
      [layoutGroups]="layoutGroups"
      [layoutSelected]="layoutSelected"
      [opened]="layoutOpened"
      [origin]="layout_trigger"
      (selectLayoutOption)="selectLayoutOption($event)"
      (close)="setLayoutOpened(false)"
    ></app-menu-block-layout-overlay>

    <div class="sidebar-summary-block__row">
      <div class="sidebar-summary-block__row-left">
        <div
          class="sidebar-summary-block__layout"
          [class.sidebar-summary-block__layout_clickable]="true"
          [class.sidebar-summary-block__layout_disabled]="!control.controls.enabled.value"
          [style.background-image]="
            '/assets/images/project-layouts/' +
              (layoutSelected ? layoutSelected.image : 'layout-double-menu-left') +
              '.svg' | appDeployCssUrl
          "
          (click)="setLayoutOpened(true)"
        ></div>
      </div>

      <div class="sidebar-summary-block__row-main">
        <div
          class="sidebar-summary-block-item sidebar-summary-block__item"
          [class.sidebar-summary-block-item_clickable]="true"
          [class.sidebar-summary-block-item_disabled]="!control.controls.enabled.value"
          cdkOverlayOrigin
          #layout_trigger="cdkOverlayOrigin"
          (click)="setLayoutOpened(true)"
        >
          <div class="sidebar-summary-block-item__main">
            <div class="sidebar-summary-block-item__title sidebar-summary-block-item__element">
              Size & Position
            </div>
          </div>

          <div class="sidebar-summary-block-item__right">
            <div class="sidebar-summary-block-item__action icon-gear"></div>
          </div>
        </div>
      </div>
    </div>

    <a
      [appLink]="currentProjectStore.instance.settingsLayoutLink('appearance', 'menu', control.instance.uid)"
      class="sidebar-summary-block-item sidebar-summary-block__item"
      [class.sidebar-summary-block-item_clickable]="true"
      [class.sidebar-summary-block-item_disabled]="!control.controls.enabled.value"
      (click)="edit.emit()"
    >
      <div class="sidebar-summary-block-item__left">
        <div class="sidebar-summary-block-item__icon icon-fileds"></div>
      </div>

      <div class="sidebar-summary-block-item__main">
        <div class="sidebar-summary-block-item__title sidebar-summary-block-item__element">
          Menu items
        </div>
        <div class="sidebar-summary-block-item__additional sidebar-summary-block-item__element">
          {{ totalItems | i18nPlural: itemsPluralMap }}
        </div>
      </div>

      <div class="sidebar-summary-block-item__right">
        <div class="sidebar-summary-block-item__arrow icon-arrow_forward_2"></div>
      </div>
    </a>

    <div
      class="sidebar-summary-block__section"
      [class.sidebar-summary-block__section_disabled]="!control.controls.enabled.value"
    >
      <div
        class="sidebar-summary-block__section-header sidebar-summary-block__section-header_clickable"
        [class.sidebar-summary-block__section-header_active]="trigger.menuOpen"
        [matMenuTriggerFor]="dropdown"
        #trigger="matMenuTrigger"
      >
        <div class="sidebar-summary-block__section-header-left"></div>

        <div class="sidebar-summary-block__section-header-main">
          <div class="sidebar-summary-block__section-header-title">Styles</div>
        </div>

        <div class="sidebar-summary-block__section-header-right">
          <div class="sidebar-summary-block__section-header-button icon-plus"></div>
        </div>
      </div>

      <mat-menu #dropdown="matMenu">
        <ng-container *ngFor="let item of styles">
          <button
            *ngIf="!item.isEnabled || item.isEnabled(item)"
            mat-menu-item
            class="mat-menu-item-odd"
            [disabled]="!isAddStyleEnabled(item)"
            [disableRipple]="true"
            (click)="addStyle(item)"
          >
            <span class="choose-items-item__dropdown-icon" [ngClass]="'icon-' + item.icon"></span>
            {{ item.label }}
          </button>
        </ng-container>
      </mat-menu>

      <div
        *ngIf="enabledStyles.length"
        class="sidebar-summary-block__section-content"
        (mousedown)="onStylesMousedown($event)"
      >
        <ng-container *ngFor="let item of enabledStyles; trackBy: trackStyleFn">
          <div *ngIf="item.control == control.controls.width && widthEnabled" class="sidebar-summary-block__item">
            <div
              class="sidebar-summary-block-item sidebar-summary-block-item_padding_xs-right"
              [class.sidebar-summary-block-item_disabled]="!control.controls.enabled.value"
            >
              <div class="sidebar-summary-block-item__left">
                <div
                  class="sidebar-summary-block-item__icon icon-resize_horizontal sidebar-summary-block-item__element"
                ></div>
                <div
                  class="sidebar-summary-block-item__title sidebar-summary-block-item__element sidebar-summary-block-item__element_margin_l"
                >
                  Width
                </div>
              </div>

              <div class="sidebar-summary-block-item__main sidebar-summary-block-item__main_right">
                <div class="sidebar-summary-block-item__input sidebar-summary-block-item__input_label">
                  <input
                    type="text"
                    class="sidebar-summary-block-item__input-control"
                    [formControl]="control.controls.width"
                    [placeholder]="defaultWidth || null"
                    [appInputFormat]="{ type: numberFieldTypes.Integer, min: 0 }"
                    [appAutofocus]="item.control === addedStyle?.control"
                    [style.width.px]="126"
                  />

                  <div class="sidebar-summary-block-item__input-label">
                    px
                  </div>
                </div>
              </div>

              <div class="sidebar-summary-block__item-actions">
                <div
                  class="sidebar-summary-block__item-remove icon-close"
                  (click)="$event.stopPropagation(); removeStyle(item)"
                ></div>
              </div>
            </div>
          </div>

          <div *ngIf="item.control == control.controls.height && heightEnabled" class="sidebar-summary-block__item">
            <div
              class="sidebar-summary-block-item sidebar-summary-block-item_padding_xs-right"
              [class.sidebar-summary-block-item_disabled]="!control.controls.enabled.value"
            >
              <div class="sidebar-summary-block-item__left">
                <div
                  class="sidebar-summary-block-item__icon icon-resize_vertical sidebar-summary-block-item__element"
                ></div>

                <div
                  class="sidebar-summary-block-item__title sidebar-summary-block-item__element sidebar-summary-block-item__element_margin_l"
                >
                  Height
                </div>
              </div>

              <div class="sidebar-summary-block-item__main sidebar-summary-block-item__main_right">
                <div class="sidebar-summary-block-item__input sidebar-summary-block-item__input_label">
                  <input
                    type="text"
                    class="sidebar-summary-block-item__input-control"
                    [formControl]="control.controls.height"
                    [placeholder]="defaultHeight || null"
                    [appInputFormat]="{ type: numberFieldTypes.Integer, min: 0 }"
                    [appAutofocus]="item.control === addedStyle?.control"
                    [style.width.px]="126"
                  />

                  <div class="sidebar-summary-block-item__input-label">
                    px
                  </div>
                </div>
              </div>

              <div class="sidebar-summary-block__item-actions">
                <div
                  class="sidebar-summary-block__item-remove icon-close"
                  (click)="$event.stopPropagation(); removeStyle(item)"
                ></div>
              </div>
            </div>
          </div>

          <div *ngIf="item.control == control.controls.fill_settings" class="sidebar-summary-block__item">
            <app-styles-edit-fill
              [control]="control.controls.fill_settings"
              [contrast]="true"
              [disabled]="!control.controls.enabled.value"
              [added]="item.control === addedStyle?.control"
              [removeEnabled]="false"
              [source]="control"
              (remove)="removeStyle(item)"
            ></app-styles-edit-fill>

            <div class="sidebar-summary-block__item-actions">
              <div
                class="sidebar-summary-block__item-remove icon-close"
                (click)="$event.stopPropagation(); removeStyle(item)"
              ></div>
            </div>
          </div>

          <div *ngIf="item.control == control.controls.border_settings" class="sidebar-summary-block__item">
            <app-styles-edit-border
              [control]="control.controls.border_settings"
              [contrast]="true"
              [disabled]="!control.controls.enabled.value"
              [added]="item.control === addedStyle?.control"
              [removeEnabled]="false"
              (remove)="removeStyle(item)"
            ></app-styles-edit-border>

            <div class="sidebar-summary-block__item-actions">
              <div
                class="sidebar-summary-block__item-remove icon-close"
                (click)="$event.stopPropagation(); removeStyle(item)"
              ></div>
            </div>
          </div>

          <div *ngIf="item.control == control.controls.border_radius" class="sidebar-summary-block__item">
            <app-styles-edit-border-radius
              [control]="control.controls.border_radius"
              [subtitle]="item.label"
              [contrast]="true"
              [disabled]="!control.controls.enabled.value"
              [added]="item.control === addedStyle?.control"
              [removeEnabled]="false"
              (remove)="removeStyle(item)"
            ></app-styles-edit-border-radius>

            <div class="sidebar-summary-block__item-actions">
              <div
                class="sidebar-summary-block__item-remove icon-close"
                (click)="$event.stopPropagation(); removeStyle(item)"
              ></div>
            </div>
          </div>

          <div *ngIf="item.control == control.controls.padding" class="sidebar-summary-block__item">
            <app-styles-edit-margin
              [control]="control.controls.padding"
              [subtitle]="item.label"
              [icon]="item.icon"
              [contrast]="true"
              [disabled]="!control.controls.enabled.value"
              [added]="item.control === addedStyle?.control"
              [removeEnabled]="false"
              (remove)="removeStyle(item)"
            ></app-styles-edit-margin>

            <div class="sidebar-summary-block__item-actions">
              <div
                class="sidebar-summary-block__item-remove icon-close"
                (click)="$event.stopPropagation(); removeStyle(item)"
              ></div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>

    <div
      *ngIf="(currentUserStore.instance$ | async)?.isStaff"
      class="sidebar-summary-block-item staff-block staff-block_background"
      [class.sidebar-summary-block-item_clickable]="true"
      [class.sidebar-summary-block-item_disabled]="!control.controls.enabled.value"
      (click)="confirmRemove()"
    >
      <div class="sidebar-summary-block-item__left">
        <div class="sidebar-summary-block-item__icon icon-bin"></div>
      </div>

      <div class="sidebar-summary-block-item__main">
        <div class="sidebar-summary-block-item__title">Delete menu</div>
      </div>
    </div>
  </div>
</div>
