import { ElementRef, OnChanges, OnDestroy, OnInit } from '@angular/core';
import toPairs from 'lodash/toPairs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { TintStyle } from '@modules/actions';
import { ProjectSettingsStore } from '@modules/all-project-settings';
import { ElementWrapperStyles } from '@modules/customize';
import { setBorderRadiusVars, setBorderVars, setShadowVars } from '@modules/theme-components';
import { isSet } from '@shared';
var ElementWrapperStylesDirective = /** @class */ (function () {
    function ElementWrapperStylesDirective(projectSettingsStore, el) {
        this.projectSettingsStore = projectSettingsStore;
        this.el = el;
        this.applyGlobal = true;
        this.tintColorStyle$ = new BehaviorSubject(undefined);
        this.styleVars = [];
    }
    ElementWrapperStylesDirective.prototype.ngOnInit = function () {
        var _this = this;
        if (this.applyGlobal) {
            var settings$ = this.projectSettingsStore
                ? this.projectSettingsStore.getAllSettings$()
                : of(undefined);
            combineLatest(settings$, this.tintColorStyle$)
                .pipe(untilDestroyed(this))
                .subscribe(function (_a) {
                var settings = _a[0], tintColorStyle = _a[1];
                if (!settings) {
                    _this.globalStyles = undefined;
                }
                else if (tintColorStyle == TintStyle.Primary) {
                    _this.globalStyles = settings.actionElementStylesPrimary;
                }
                else if (tintColorStyle == TintStyle.Default) {
                    _this.globalStyles = settings.actionElementStylesDefault;
                }
                else if (tintColorStyle == TintStyle.Transparent) {
                    _this.globalStyles = settings.actionElementStylesTransparent;
                }
                else {
                    _this.globalStyles = undefined;
                }
                _this.updateStyles();
            });
        }
        else {
            this.updateStyles();
        }
    };
    ElementWrapperStylesDirective.prototype.ngOnDestroy = function () { };
    ElementWrapperStylesDirective.prototype.ngOnChanges = function (changes) {
        if (changes.customElementStyles && !changes.customElementStyles.firstChange) {
            this.updateStyles();
        }
        if (changes.tintColorStyle) {
            this.tintColorStyle$.next(this.tintColorStyle);
        }
    };
    ElementWrapperStylesDirective.prototype.getElement = function () {
        return this.forceElement || this.el;
    };
    ElementWrapperStylesDirective.prototype.updateStyles = function () {
        var elementStyles;
        var vars = {};
        if (this.customElementStyles && this.globalStyles) {
            elementStyles = new ElementWrapperStyles().apply(this.globalStyles).apply(this.customElementStyles);
        }
        else if (this.customElementStyles || this.globalStyles) {
            elementStyles = this.customElementStyles || this.globalStyles;
        }
        else {
            elementStyles = undefined;
        }
        if (elementStyles) {
            setBorderVars(vars, elementStyles.borderSettings, function (key) { return "element-wrapper-" + key; });
            setBorderRadiusVars(vars, elementStyles.borderRadius, 'element-wrapper-border-radius');
            setShadowVars(vars, elementStyles.shadow, function (key) { return "element-wrapper-" + key; });
        }
        var element = this.getElement();
        toPairs(vars).forEach(function (_a) {
            var k = _a[0], v = _a[1];
            return element.nativeElement.style.setProperty("--" + k, v);
        });
        this.styleVars.filter(function (k) { return !isSet(vars[k]); }).forEach(function (k) { return element.nativeElement.style.removeProperty("--" + k); });
        this.styleVars = toPairs(vars).map(function (_a) {
            var k = _a[0];
            return k;
        });
        element.nativeElement.setAttribute('data-style-vars', this.styleVars.join(' '));
    };
    return ElementWrapperStylesDirective;
}());
export { ElementWrapperStylesDirective };
