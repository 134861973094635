import pickBy from 'lodash/pickBy';

import { ActionItem } from '@modules/actions';
import { ParameterField } from '@modules/fields';
import { fromLegacyModel } from '@modules/models';
import { ResourceType, ResourceTypeItem, resourceTypeItems } from '@modules/projects';
import { isSet, splitmax } from '@shared';

import { Margin } from '../elements/items/base';
import { ModelData } from '../model-data';

export enum ViewSettingsType {
  List = 'list',
  Change = 'change',
  Action = 'action',
  Dashboard = 'dashboard',
  Custom = 'custom'
}

export function isSameViewSettings(current: ViewSettings, model: string | ViewSettings) {
  if (!model) {
    return false;
  }

  if (isSet(current.uid)) {
    if (model instanceof ViewSettings) {
      return model.uid == current.uid;
    } else {
      return current.uid == model;
    }
  } else if (isSet(current.resource) && isSet(current.model)) {
    if (model instanceof ViewSettings) {
      return model.resource == current.resource && model.model == current.model;
    } else {
      const modelId = model as string;
      const params = splitmax(modelId, '.', 2);

      return params.length == 2
        ? (!current.resource || current.resource == params[0]) && current.model == params[1]
        : fromLegacyModel(current.model) == params[0];
    }
  }
}

export class ViewSettings {
  public uid: string;
  public project: string;
  public view: ViewSettingsType;
  public uniqueName: string;
  public name: string;
  public resource: string;
  public model: string;
  public parameters: ParameterField[] = [];
  public queries: ModelData[] = [];
  public openActions: ActionItem[] = [];
  public backgroundColor: string;
  public backgroundColorDark: string;
  public backgroundColor2: string;
  public backgroundColor2Dark: string;
  public backgroundColor3: string;
  public backgroundColor3Dark: string;
  public backgroundColor4: string;
  public backgroundColor4Dark: string;
  public backgroundColor5: string;
  public backgroundColor5Dark: string;
  public textColor: string;
  public textColorDark: string;
  public textColor2: string;
  public textColor2Dark: string;
  public textColor3: string;
  public textColor3Dark: string;
  public borderColor: string;
  public borderColorDark: string;
  public borderColor2: string;
  public borderColor2Dark: string;
  public borderColor3: string;
  public borderColor3Dark: string;
  public maxWidth: number;
  public padding: Margin = {};
  public params = {};
  public draft = false;
  public deleted = false;
  public sourceTemplate: number;
  public templateInstanceId: string;
  public usedResources: { type: ResourceType; typeItem: ResourceTypeItem; name: string }[] = [];
  public configuredElements = 0;
  public configuredModelElements = 0;
  public configuredActionElements = 0;
  public newlyCreated = false;

  // TODO: Remove ModelDescription
  deserialize(data: Object) {
    this.uid = data['uid'];
    this.project = data['project'];
    this.view = data['view'];
    this.uniqueName = data['unique_name'];
    this.name = data['name'];
    this.resource = data['resource'];
    this.model = fromLegacyModel(data['model']);
    this.params = JSON.parse(data['params'] || '{}');

    if (this.params['parameters']) {
      this.parameters = this.params['parameters'].map(item => new ParameterField().deserialize(item));
    }

    if (this.params['queries']) {
      this.queries = this.params['queries'].map(item => new ModelData().deserialize(item));
    }

    if (this.params['open_actions']) {
      this.openActions = this.params['open_actions'].map((item, i) => {
        const result = new ActionItem().deserialize(item);

        // Backward compatibility
        if (!isSet(result.name)) {
          result.name = `Action ${i + 1}`;
        }

        return result;
      });
    }

    if (isSet(this.params['background_color'])) {
      this.backgroundColor = this.params['background_color'];
    }

    if (isSet(this.params['background_color_dark'])) {
      this.backgroundColorDark = this.params['background_color_dark'];
    }

    if (isSet(this.params['background_color_2'])) {
      this.backgroundColor2 = this.params['background_color_2'];
    }

    if (isSet(this.params['background_color_2_dark'])) {
      this.backgroundColor2Dark = this.params['background_color_2_dark'];
    }

    if (isSet(this.params['background_color_3'])) {
      this.backgroundColor3 = this.params['background_color_3'];
    }

    if (isSet(this.params['background_color_3_dark'])) {
      this.backgroundColor3Dark = this.params['background_color_3_dark'];
    }

    if (isSet(this.params['background_color_4'])) {
      this.backgroundColor4 = this.params['background_color_4'];
    }

    if (isSet(this.params['background_color_4_dark'])) {
      this.backgroundColor4Dark = this.params['background_color_4_dark'];
    }

    if (isSet(this.params['background_color_5'])) {
      this.backgroundColor5 = this.params['background_color_5'];
    }

    if (isSet(this.params['background_color_5_dark'])) {
      this.backgroundColor5Dark = this.params['background_color_5_dark'];
    }

    if (isSet(this.params['text_color'])) {
      this.textColor = this.params['text_color'];
    }

    if (isSet(this.params['text_color_dark'])) {
      this.textColorDark = this.params['text_color_dark'];
    }

    if (isSet(this.params['text_color_2'])) {
      this.textColor2 = this.params['text_color_2'];
    }

    if (isSet(this.params['text_color_2_dark'])) {
      this.textColor2Dark = this.params['text_color_2_dark'];
    }

    if (isSet(this.params['text_color_3'])) {
      this.textColor3 = this.params['text_color_3'];
    }

    if (isSet(this.params['text_color_3_dark'])) {
      this.textColor3Dark = this.params['text_color_3_dark'];
    }

    if (isSet(this.params['border_color'])) {
      this.borderColor = this.params['border_color'];
    }

    if (isSet(this.params['border_color_dark'])) {
      this.borderColorDark = this.params['border_color_dark'];
    }

    if (isSet(this.params['border_color_2'])) {
      this.borderColor2 = this.params['border_color_2'];
    }

    if (isSet(this.params['border_color_2_dark'])) {
      this.borderColor2Dark = this.params['border_color_2_dark'];
    }

    if (isSet(this.params['border_color_3'])) {
      this.borderColor3 = this.params['border_color_3'];
    }

    if (isSet(this.params['border_color_3_dark'])) {
      this.borderColor3Dark = this.params['border_color_3_dark'];
    }

    if (isSet(this.params['max_width'])) {
      this.maxWidth = this.params['max_width'];
    }

    if (this.params['padding']) {
      this.padding = this.params['padding'];
    }

    if (this.params['source_template']) {
      this.sourceTemplate = this.params['source_template'];
    }

    if (this.params['template_instance_id']) {
      this.templateInstanceId = this.params['template_instance_id'];
    }

    if (this.params['used_resources']) {
      this.usedResources = this.params['used_resources']
        .map(item => {
          return {
            type: item['type'],
            typeItem: resourceTypeItems.find(i => i.name == item['type_item']),
            name: item['name']
          };
        })
        .filter(item => item.typeItem != undefined);
    }

    if (this.params['configured_elements'] !== undefined) {
      this.configuredElements = this.params['configured_elements'];
    }

    if (this.params['configured_model_elements'] !== undefined) {
      this.configuredModelElements = this.params['configured_model_elements'];
    }

    if (this.params['configured_action_elements'] !== undefined) {
      this.configuredActionElements = this.params['configured_action_elements'];
    }

    if (data['draft'] !== undefined) {
      this.draft = data['draft'];
    }

    if (data['deleted'] !== undefined) {
      this.deleted = data['deleted'];
    }

    return this;
  }

  serialize(fields?: string[]): Object {
    this.params['queries'] = this.queries.map(item => item.serialize());
    this.params['open_actions'] = this.openActions.map(item => item.serialize());
    this.params['background_color'] = this.backgroundColor;
    this.params['background_color_dark'] = this.backgroundColorDark;
    this.params['background_color_2'] = this.backgroundColor2;
    this.params['background_color_2_dark'] = this.backgroundColor2Dark;
    this.params['background_color_3'] = this.backgroundColor3;
    this.params['background_color_3_dark'] = this.backgroundColor3Dark;
    this.params['background_color_4'] = this.backgroundColor4;
    this.params['background_color_4_dark'] = this.backgroundColor4Dark;
    this.params['background_color_5'] = this.backgroundColor5;
    this.params['background_color_5_dark'] = this.backgroundColor5Dark;
    this.params['text_color'] = this.textColor;
    this.params['text_color_dark'] = this.textColorDark;
    this.params['text_color_2'] = this.textColor2;
    this.params['text_color_2_dark'] = this.textColor2Dark;
    this.params['text_color_3'] = this.textColor3;
    this.params['text_color_3_dark'] = this.textColor3Dark;
    this.params['border_color'] = this.borderColor;
    this.params['border_color_dark'] = this.borderColorDark;
    this.params['border_color_2'] = this.borderColor2;
    this.params['border_color_2_dark'] = this.borderColor2Dark;
    this.params['border_color_3'] = this.borderColor3;
    this.params['border_color_3_dark'] = this.borderColor3Dark;
    this.params['max_width'] = this.maxWidth;
    this.params['padding'] = this.padding;
    this.params['source_template'] = this.sourceTemplate;
    this.params['template_instance_id'] = this.templateInstanceId;
    this.params['used_resources'] = this.usedResources.map(item => {
      return {
        type: item.type,
        type_item: item.typeItem.name,
        name: item.name
      };
    });
    this.params['configured_elements'] = this.configuredElements;
    this.params['configured_model_elements'] = this.configuredModelElements;
    this.params['configured_action_elements'] = this.configuredActionElements;

    let data: Object = {
      uid: this.uid,
      project: this.project,
      view: this.view,
      name: this.name,
      unique_name: this.uniqueName,
      resource: this.resource,
      model: this.model,
      parameters: this.parameters.map(item => item.serialize()),
      params: JSON.stringify(this.params),
      draft: this.draft,
      deleted: this.deleted
    };
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }

  isSame(model: string | ViewSettings) {
    return isSameViewSettings(this, model);
  }

  get link() {
    if (isSet(this.uniqueName)) {
      return ['page', this.uniqueName];
    } else if (isSet(this.resource) && isSet(this.model)) {
      return ['models', `${this.resource}.${this.model}`];
    }
  }

  getActionsCount(): number {
    return this.queries.length + this.openActions.length;
  }
}
