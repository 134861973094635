var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormControl, FormGroup } from '@angular/forms';
import { AccordionItem } from '@modules/customize';
import { Input, Input as FieldInput } from '@modules/fields';
import { FieldInputControl } from '@modules/parameters';
import { isSet } from '@shared';
import { TextStyleControl } from '../text-style-edit/text-style.control';
var AccordionItemControl = /** @class */ (function (_super) {
    __extends(AccordionItemControl, _super);
    function AccordionItemControl(injector, value) {
        var _this = _super.call(this, {
            title: new FieldInputControl(value && value.titleInput ? value.titleInput.serialize() : { path: ['value'] }),
            title_style: TextStyleControl.inject(injector, {
                global: 'collapseTitleTextStyle',
                colorAlphaEnabled: true
            }),
            icon: new FormControl(value && isSet(value.icon) ? value.icon : undefined),
            opened_input: new FieldInputControl({ path: ['value'] }),
            visible_input: new FieldInputControl(value && value.visibleInput ? value.visibleInput.serialize() : { path: ['value'] }),
            tooltip: new FormControl(value && isSet(value.tooltip) ? value.tooltip : '')
        }) || this;
        _this.injector = injector;
        return _this;
    }
    AccordionItemControl.inject = function (injector, value) {
        return new AccordionItemControl(injector, value);
    };
    AccordionItemControl.prototype.deserialize = function (value) {
        this.instance = value;
        this.controls.title.patchValue(value.titleInput ? value.titleInput.serializeWithoutPath() : {});
        this.controls.title_style.deserialize(value.titleStyle);
        this.controls.icon.patchValue(value.icon);
        this.controls.opened_input.patchValue(value.openedInput ? value.openedInput.serializeWithoutPath() : {});
        this.controls.visible_input.patchValue(value.visibleInput ? value.visibleInput.serializeWithoutPath() : {});
        this.controls.tooltip.patchValue(value.tooltip);
    };
    AccordionItemControl.prototype.serialize = function () {
        if (!this.instance) {
            this.instance = new AccordionItem();
            this.instance.generateUid();
        }
        var result = this.instance;
        result.titleInput = this.controls.title.value ? new FieldInput().deserialize(this.controls.title.value) : undefined;
        result.titleStyle = this.controls.title_style.serialize();
        result.icon = this.controls.icon.value;
        if (this.controls.opened_input.value) {
            result.openedInput = new Input().deserialize(this.controls.opened_input.value);
        }
        else {
            result.openedInput = undefined;
        }
        if (this.controls.visible_input.value) {
            result.visibleInput = new Input().deserialize(this.controls.visible_input.value);
        }
        else {
            result.visibleInput = undefined;
        }
        result.tooltip = isSet(this.controls.tooltip.value) ? this.controls.tooltip.value.trim() : undefined;
        return result;
    };
    return AccordionItemControl;
}(FormGroup));
export { AccordionItemControl };
