/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../routing/directives/app-link/app-link.directive";
import * as i2 from "../../../routing/services/routing/routing.service";
import * as i3 from "@angular/router";
import * as i4 from "@angular/common";
import * as i5 from "./feature-overview.component";
import * as i6 from "../../../projects/stores/current-project.store";
var styles_FeatureOverviewComponent = [];
var RenderType_FeatureOverviewComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FeatureOverviewComponent, data: {} });
export { RenderType_FeatureOverviewComponent as RenderType_FeatureOverviewComponent };
function View_FeatureOverviewComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [["class", "feature-overview__background"]], null, null, null, null, null))], null, null); }
function View_FeatureOverviewComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "feature-overview__buttons"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "a", [["class", "button button_primary button_shadow"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.buttonClicked.emit() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 737280, null, 0, i1.AppLinkDirective, [i2.RoutingService, i3.Router, i3.ActivatedRoute, i4.LocationStrategy], { appLink: [0, "appLink"], appLinkMode: [1, "appLinkMode"] }, null), i0.ɵpid(131072, i4.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵted(-1, null, [" Upgrade Plan "]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform(_co.currentProjectStore.instance$)).settingsBillingLink; var currVal_3 = _co.adminModes.Builder; _ck(_v, 2, 0, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).target; var currVal_1 = i0.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_FeatureOverviewComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "feature-overview__image"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "feature-overview__image-width"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 3, "div", [["class", "feature-overview__image-height"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "feature-overview__image-inner"]], null, null, null, null, null)), i0.ɵdid(4, 278528, null, 0, i4.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpad(5, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "feature-overview__image-inner"; var currVal_1 = _ck(_v, 5, 0, ("feature-overview__image-inner_type_" + _co.feature)); _ck(_v, 4, 0, currVal_0, currVal_1); }, null); }
export function View_FeatureOverviewComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 15, "div", [["class", "feature-overview"]], [[2, "feature-overview_fill", null]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FeatureOverviewComponent_1)), i0.ɵdid(2, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 12, "div", [["class", "feature-overview__content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 11, "div", [["class", "feature-overview__info"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 8, "div", [["class", "feature-overview__info-inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "div", [["class", "feature-overview__subtitle"]], null, null, null, null, null)), i0.ɵncd(null, 0), (_l()(), i0.ɵeld(8, 0, null, null, 1, "div", [["class", "feature-overview__title"]], null, null, null, null, null)), i0.ɵncd(null, 1), (_l()(), i0.ɵeld(10, 0, null, null, 1, "div", [["class", "feature-overview__description"]], null, null, null, null, null)), i0.ɵncd(null, 2), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FeatureOverviewComponent_2)), i0.ɵdid(13, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FeatureOverviewComponent_3)), i0.ɵdid(15, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.background; _ck(_v, 2, 0, currVal_1); var currVal_2 = (_co.button && _co.currentProjectStore); _ck(_v, 13, 0, currVal_2); var currVal_3 = _co.feature; _ck(_v, 15, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.fill; _ck(_v, 0, 0, currVal_0); }); }
export function View_FeatureOverviewComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-feature-overview", [], null, null, null, View_FeatureOverviewComponent_0, RenderType_FeatureOverviewComponent)), i0.ɵdid(1, 245760, null, 0, i5.FeatureOverviewComponent, [[2, i6.CurrentProjectStore]], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FeatureOverviewComponentNgFactory = i0.ɵccf("app-feature-overview", i5.FeatureOverviewComponent, View_FeatureOverviewComponent_Host_0, { fill: "fill", background: "background", feature: "feature", button: "button" }, { buttonClicked: "buttonClicked" }, ["[data-subtitle]", "[data-title]", "[data-description]"]);
export { FeatureOverviewComponentNgFactory as FeatureOverviewComponentNgFactory };
