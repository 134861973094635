import pickBy from 'lodash/pickBy';
import values from 'lodash/values';

import { getColorHexStr, getColorVariable } from '@modules/colors';
import { FontStyle, getFontFamilyVariable, TextDecoration, TextTransform } from '@modules/styles';
import { isSet } from '@shared';

export class TextStyle {
  public fontFamily: string;
  public fontWeight: number;
  public fontStyle: FontStyle;
  public fontSize: number;
  public color: string;
  public colorDark: string;
  public backgroundColor: string;
  public backgroundColorDark: string;
  public letterSpacing: number;
  public transform: TextTransform = TextTransform.None;
  public decoration: TextDecoration = TextDecoration.None;

  constructor(options: Partial<TextStyle> = {}) {
    Object.assign(this, options);
  }

  deserialize(data: Object): this {
    this.fontFamily = data['font_family'];
    this.fontWeight = data['font_weight'];
    this.fontStyle = data['font_style'];
    this.fontSize = data['font_size'];
    this.color = data['color'];
    this.colorDark = data['color_dark'];
    this.backgroundColor = data['background_color'];
    this.backgroundColorDark = data['background_color_dark'];
    this.letterSpacing = data['letter_spacing'];

    if (isSet(data['transform'])) {
      this.transform = data['transform'];
    }

    if (isSet(data['decoration'])) {
      this.decoration = data['decoration'];
    }

    return this;
  }

  serialize(): Object {
    return {
      font_family: this.fontFamily,
      font_weight: this.fontWeight,
      font_style: this.fontStyle,
      font_size: this.fontSize,
      color: this.color,
      color_dark: this.colorDark,
      background_color: this.backgroundColor,
      background_color_dark: this.backgroundColorDark,
      letter_spacing: this.letterSpacing,
      transform: this.transform,
      decoration: this.decoration
    };
  }

  getDiff(another: this): Partial<TextStyle> {
    const result: Partial<TextStyle> = {};
    const fields: (keyof TextStyle)[] = [
      'fontFamily',
      'fontWeight',
      'fontStyle',
      'fontSize',
      'color',
      'colorDark',
      'backgroundColor',
      'backgroundColorDark',
      'letterSpacing',
      'transform',
      'decoration'
    ];

    fields.forEach(field => {
      if (this[field] != another[field]) {
        result[field] = this[field];
      }
    });

    if (!values(result).length) {
      return;
    }

    return result;
  }

  equals(another: this): boolean {
    return !this.getDiff(another);
  }

  apply(value: Partial<TextStyle>) {
    value = pickBy(value, v => isSet(v));
    Object.assign(this, value);
  }

  cssColor(dark: boolean): string {
    const color = dark ? this.colorDark : this.color;

    if (!isSet(color)) {
      return;
    }

    const variable = getColorVariable(color);

    if (isSet(variable)) {
      return `var(--${variable})`;
    }

    return getColorHexStr(color);
  }

  cssBackgroundColor(dark: boolean): string {
    const color = dark ? this.backgroundColorDark : this.backgroundColor;

    if (!isSet(color)) {
      return;
    }

    const variable = getColorVariable(color);

    if (isSet(variable)) {
      return `var(--${variable})`;
    }

    return getColorHexStr(color);
  }

  cssFont(options: { sizeMultiplier?: number } = {}): string {
    const result = [];

    if (isSet(this.fontStyle)) {
      result.push(this.cssFontStyle());
    }

    if (isSet(this.fontWeight)) {
      result.push(this.fontWeight);
    }

    if (isSet(this.fontSize)) {
      const fontSize = this.fontSize * (isSet(options.sizeMultiplier) ? options.sizeMultiplier : 1);
      result.push(`${fontSize}px`);
    }

    if (isSet(this.fontFamily)) {
      const variable = getFontFamilyVariable(this.fontFamily);

      if (isSet(variable)) {
        result.push(`var(--${variable})`);
      } else {
        result.push(`"${this.fontFamily}"`);
      }
    }

    if (!result.length) {
      return;
    }

    return result.join(' ');
  }

  cssFontStyle(): string {
    if (this.fontStyle == FontStyle.Normal) {
      return 'normal';
    } else if (this.fontStyle == FontStyle.Italic) {
      return 'italic';
    }
  }

  cssTextTransform(): string {
    if (this.transform == TextTransform.None) {
      return 'none';
    } else if (this.transform == TextTransform.Lowercase) {
      return 'lowercase';
    } else if (this.transform == TextTransform.Uppercase) {
      return 'uppercase';
    } else if (this.transform == TextTransform.Capitalize) {
      return 'capitalize';
    }
  }

  cssTextDecoration(): string {
    if (this.decoration == TextDecoration.None) {
      return 'none';
    } else if (this.decoration == TextDecoration.Underline) {
      return 'underline';
    } else if (this.decoration == TextDecoration.Strikethrough) {
      return 'line-through';
    }
  }
}
