import { ChangeDetectorRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { timer } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ViewContext } from '@modules/customize';
import { PersistentIdGenerator } from '@modules/customize-generators';
import { ModelDescription } from '@modules/models';
import { CurrentEnvironmentStore } from '@modules/projects';
import { PageTemplatesGeneratorService, PageTemplateType, PageVariantType } from '@modules/template-generators';
import { controlValue } from '@shared';
import { PageTemplatesItemSettingsForm } from './page-templates-item-settings.form';
var PageTemplatesItemSettingsComponent = /** @class */ (function () {
    function PageTemplatesItemSettingsComponent(form, currentEnvironmentStore, pageTemplatesGeneratorService, 
    // private sanitizer: DomSanitizer,
    context, analyticsService, cd) {
        this.form = form;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.pageTemplatesGeneratorService = pageTemplatesGeneratorService;
        this.context = context;
        this.analyticsService = analyticsService;
        this.cd = cd;
        this.cancelEnabled = false;
        this.createdStartPage = new EventEmitter();
        this.backToTypeClick = new EventEmitter();
        this.backToModelClick = new EventEmitter();
        this.cancelClick = new EventEmitter();
        this.loadingSubmit = false;
        this.steps = [];
        this.currentStepIndex = 0;
        this.initialStep = true;
        this.previewLoading = true;
        this.previewInit = true;
        this.otherPages = [];
        // previewStyle: SafeStyle;
        this.previewFit = false;
    }
    PageTemplatesItemSettingsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.currentEnvironmentStore.resources$.pipe(untilDestroyed(this)).subscribe(function (resources) {
            _this.resource = resources.find(function (item) { return item.uniqueName == _this.modelDescription.resource; });
            _this.cd.markForCheck();
        });
        this.form.init(this.template, this.templateVariant, this.resource, this.modelDescription);
        var idGenerator = new PersistentIdGenerator();
        controlValue(this.form)
            .pipe(debounceTime(60), switchMap(function () { return _this.form.createTemplatePages(idGenerator); }), untilDestroyed(this))
            .subscribe(function (result) {
            _this.startPage = result ? result.startPage : undefined;
            _this.otherPages = result ? result.pages.filter(function (item) { return item !== _this.startPage; }) : [];
            _this.context.viewSettings = _this.startPage;
            _this.steps = result ? _this.getSteps() : [];
            // this.cd.markForCheck();
            _this.cd.markForCheck();
            if (_this.previewLoading) {
                _this.previewLoading = false;
                _this.cd.detectChanges();
                timer(600)
                    .pipe(untilDestroyed(_this))
                    .subscribe(function () {
                    // this.setCurrentStepIndex(0);
                    _this.previewInit = false;
                    _this.cd.markForCheck();
                });
                _this.onStepOpened();
            }
        });
    };
    PageTemplatesItemSettingsComponent.prototype.ngOnDestroy = function () { };
    PageTemplatesItemSettingsComponent.prototype.getSteps = function () {
        var steps = [];
        // if (this.template.type == PageTemplateType.AdminPanelEdit || this.template.type == PageTemplateType.AdminPanelView) {
        // let previewFocus: PreviewFocus;
        //
        // if (this.templateVariant == PageVariantType.SinglePage) {
        //   previewFocus = {
        //     page: this.startPage,
        //     left: true
        //   };
        // } else if (this.templateVariant == PageVariantType.Popups) {
        //   previewFocus = {
        //     page: this.startPage
        //     // popup: this.startPage.popups[0]
        //   };
        // } else if (this.templateVariant == PageVariantType.SeparatePages) {
        //   previewFocus = {
        //     page: this.startPage
        //   };
        // }
        // steps.push({
        //   label: 'Table fields',
        //   fieldsControl: this.form.controls.listFields,
        //   previewFocus: previewFocus
        // });
        if (this.template.type == PageTemplateType.AdminPanelView) {
            if (this.templateVariant == PageVariantType.SinglePage) {
                steps.push({
                    label: 'Table fields',
                    fieldsControl: this.form.controls.listFields,
                    previewFocus: {
                        page: this.startPage,
                        left: true
                    }
                });
                steps.push({
                    label: 'Detail fields',
                    fieldsControl: this.form.controls.detailFields,
                    previewFocus: {
                        page: this.startPage,
                        right: true
                    }
                });
            }
            else if (this.templateVariant == PageVariantType.Popups) {
                steps.push({
                    label: 'Table fields',
                    fieldsControl: this.form.controls.listFields,
                    previewFocus: {
                        page: this.startPage
                    }
                });
                steps.push({
                    label: 'Detail fields',
                    fieldsControl: this.form.controls.detailFields,
                    previewFocus: {
                        page: this.startPage,
                        popup: this.startPage.popups[0]
                    }
                });
            }
            else if (this.templateVariant == PageVariantType.SeparatePages) {
                steps.push({
                    label: 'Table fields',
                    fieldsControl: this.form.controls.listFields,
                    previewFocus: {
                        page: this.startPage
                    }
                });
                steps.push({
                    label: 'Detail page',
                    fieldsControl: this.form.controls.detailFields,
                    previewFocus: {
                        page: this.otherPages[0]
                    }
                });
            }
        }
        else if (this.template.type == PageTemplateType.AdminPanelEdit) {
            if (this.templateVariant == PageVariantType.SinglePage) {
                steps.push({
                    label: 'Table fields',
                    fieldsControl: this.form.controls.listFields,
                    previewFocus: {
                        page: this.startPage,
                        left: true
                    }
                });
                steps.push({
                    label: 'Form fields',
                    fieldsControl: this.form.controls.updateFields,
                    previewFocus: {
                        page: this.startPage,
                        right: true
                    }
                });
            }
            else if (this.templateVariant == PageVariantType.Popups) {
                steps.push({
                    label: 'Table fields',
                    fieldsControl: this.form.controls.listFields,
                    previewFocus: {
                        page: this.startPage
                    }
                });
                steps.push({
                    label: 'Form fields',
                    fieldsControl: this.form.controls.updateFields,
                    previewFocus: {
                        page: this.startPage,
                        popup: this.startPage.popups[0]
                    }
                });
            }
            else if (this.templateVariant == PageVariantType.SeparatePages) {
                steps.push({
                    label: 'Table fields',
                    fieldsControl: this.form.controls.listFields,
                    previewFocus: {
                        page: this.startPage
                    }
                });
                steps.push({
                    label: 'Create page',
                    fieldsControl: this.form.controls.createFields,
                    previewFocus: {
                        page: this.otherPages[0]
                    }
                });
                steps.push({
                    label: 'Update page',
                    fieldsControl: this.form.controls.updateFields,
                    previewFocus: {
                        page: this.otherPages[1]
                    }
                });
            }
        }
        else if (this.template.type == PageTemplateType.CreateForm) {
            steps.push({
                label: 'Create fields',
                fieldsControl: this.form.controls.createFields,
                previewFocus: {
                    page: this.startPage
                }
            });
        }
        else if (this.template.type == PageTemplateType.Dashboard) {
            steps.push({
                label: 'Table fields',
                fieldsControl: this.form.controls.listFields,
                previewFocus: {
                    page: this.startPage
                }
            });
        }
        return steps;
    };
    Object.defineProperty(PageTemplatesItemSettingsComponent.prototype, "currentStep", {
        get: function () {
            return this.steps[this.currentStepIndex];
        },
        enumerable: true,
        configurable: true
    });
    PageTemplatesItemSettingsComponent.prototype.setCurrentStepIndex = function (index) {
        if (this.currentStepIndex === index) {
            return;
        }
        this.currentStepIndex = index;
        // this.previewStyle =
        //   this.currentStep && this.currentStep.previewStyle
        //     ? this.sanitizer.bypassSecurityTrustStyle(this.currentStep.previewStyle)
        //     : undefined;
        // this.previewFit = false;
        this.cd.markForCheck();
        this.onStepOpened();
    };
    PageTemplatesItemSettingsComponent.prototype.prevStep = function () {
        this.setCurrentStepIndex(this.currentStepIndex - 1);
    };
    PageTemplatesItemSettingsComponent.prototype.nextStep = function () {
        var _this = this;
        this.onStepFinished();
        if (this.currentStepIndex == this.steps.length - 1) {
            this.submit();
            return;
        }
        var currentStep = this.currentStep;
        // const firstStep = this.currentStepIndex === 0;
        this.setCurrentStepIndex(this.currentStepIndex + 1);
        if (this.initialStep && currentStep.fieldsControl === this.form.controls.listFields) {
            this.initialStep = false;
            this.steps
                .slice(1)
                .map(function (item) { return item.fieldsControl; })
                .filter(function (item) { return !!item; })
                .forEach(function (array) {
                array.controls.forEach(function (control) {
                    var listControl = _this.form.controls.listFields.controls.find(function (item) { return item.instance.name == control.instance.name; });
                    if (listControl && control.controls.enabled.value !== listControl.controls.enabled.value) {
                        control.controls.enabled.patchValue(listControl.controls.enabled.value);
                    }
                });
            });
        }
    };
    PageTemplatesItemSettingsComponent.prototype.onStepOpened = function () {
        var _this = this;
        if (this.currentStep) {
            var resource = this.modelDescription
                ? this.currentEnvironmentStore.resources.find(function (item) { return item.uniqueName == _this.modelDescription.resource; })
                : undefined;
            this.analyticsService.sendSimpleEvent(AnalyticsEvent.PageTemplates.StartedCustomizeFields, {
                Fields: this.currentStep.label,
                Template: this.template ? this.template.type : undefined,
                TemplateVariant: this.templateVariant,
                ResourceType: resource ? resource.typeItem.name : undefined,
                Resource: resource ? resource.uniqueName : undefined,
                Source: this.analyticsSource
            });
        }
    };
    PageTemplatesItemSettingsComponent.prototype.onStepFinished = function () {
        var _this = this;
        if (this.currentStep) {
            var resource = this.modelDescription
                ? this.currentEnvironmentStore.resources.find(function (item) { return item.uniqueName == _this.modelDescription.resource; })
                : undefined;
            this.analyticsService.sendSimpleEvent(AnalyticsEvent.PageTemplates.FinishedCustomizeFields, {
                Fields: this.currentStep.label,
                Template: this.template ? this.template.type : undefined,
                TemplateVariant: this.templateVariant,
                ResourceType: resource ? resource.typeItem.name : undefined,
                Resource: resource ? resource.uniqueName : undefined,
                Source: this.analyticsSource
            });
        }
    };
    PageTemplatesItemSettingsComponent.prototype.togglePreviewFit = function () {
        this.previewFit = !this.previewFit;
        this.cd.markForCheck();
    };
    PageTemplatesItemSettingsComponent.prototype.submit = function () {
        var _this = this;
        if (this.loadingSubmit) {
            return;
        }
        this.loadingSubmit = true;
        this.cd.markForCheck();
        this.form
            .submit()
            .pipe(untilDestroyed(this))
            .subscribe(function (startPage) {
            if (startPage) {
                _this.createdStartPage.emit(startPage);
            }
            else {
                _this.loadingSubmit = false;
                _this.cd.markForCheck();
            }
        }, function () {
            _this.loadingSubmit = false;
            _this.cd.markForCheck();
        });
    };
    return PageTemplatesItemSettingsComponent;
}());
export { PageTemplatesItemSettingsComponent };
