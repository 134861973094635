import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit
} from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import * as Color from 'color';
import toPairs from 'lodash/toPairs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject } from 'rxjs';

import { getColorHex, isLightColor } from '@modules/colors';
import { Fill, Frame } from '@modules/customize';
import { MenuBlock, MenuGeneratorService, MenuItem } from '@modules/menu';
import { elementSize$, isSet, TypedChanges } from '@shared';

export namespace MenuSecondary {
  export function isLight(color: string): boolean {
    if (!isSet(color)) {
      return;
    }

    return isLightColor(color);
  }

  export function getLightColor(color: string): string {
    try {
      const colorHex = getColorHex(color);
      const clr = Color(colorHex);
      return clr.lighten(0.2).string();
    } catch (e) {}
  }

  export function getDarkColor(color: string): string {
    try {
      const colorHex = getColorHex(color);
      const clr = Color(colorHex);
      return clr.darken(0.05).string();
    } catch (e) {}
  }

  export function getDark2Color(color: string): string {
    try {
      const colorHex = getColorHex(color);
      const clr = Color(colorHex);
      return clr.darken(0.45).desaturate(0.35).string();
    } catch (e) {}
  }

  export function getDark3Color(color: string): string {
    try {
      const colorHex = getColorHex(color);
      const clr = Color(colorHex);
      return clr.darken(0.7).desaturate(0.25).string();
    } catch (e) {}
  }

  export function getVars(accentColor: string, backgroundColor?: string): Object {
    if (!isSet(accentColor)) {
      accentColor = isSet(backgroundColor) ? backgroundColor : '#f3f5f8';
    }

    const lightColor = getLightColor(accentColor);
    const darkColor = getDarkColor(accentColor);
    const dark2Color = getDark2Color(accentColor);
    const dark3Color = getDark3Color(accentColor);

    return {
      ...(lightColor && { 'light-color': lightColor }),
      ...(darkColor && { 'dark-color': darkColor }),
      ...(dark2Color && { 'dark2-color': dark2Color }),
      ...(dark3Color && { 'dark3-color': dark3Color })
    };
  }

  export function defaultWidth(): number {
    return 250;
  }
}

@Component({
  selector: 'app-menu-secondary',
  templateUrl: './menu-secondary.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuSecondaryComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() block: MenuBlock;
  @Input() horizontal = false;
  @Input() backgroundColor: string;
  @Input() isDarkTheme = false;

  @HostBinding('style') menuStyle: SafeStyle;

  frame$ = new BehaviorSubject<ClientRect>(undefined);
  startItems: MenuItem[] = [];
  centerItems: MenuItem[] = [];
  endItems: MenuItem[] = [];
  menuBackground: SafeStyle;
  menuBackgroundWidth?: string;
  menuBackgroundHeight?: string;
  menuBackgroundTransform?: SafeStyle;
  menuIsLight: boolean;
  menuBorderTop: SafeStyle;
  menuBorderRight: SafeStyle;
  menuBorderBottom: SafeStyle;
  menuBorderLeft: SafeStyle;

  trackMenuItemFn(i, item: MenuItem) {
    return item.id;
  }

  constructor(
    private el: ElementRef,
    private menuGeneratorService: MenuGeneratorService,
    private sanitizer: DomSanitizer,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<MenuSecondaryComponent>): void {
    if (changes.block) {
      this.updateItems();
    }

    if (changes.block || changes.isDarkTheme || changes.backgroundColor) {
      this.updateBackground();
    }

    if (changes.block || changes.isDarkTheme) {
      this.updateBorder();
    }
  }

  ngAfterViewInit(): void {
    elementSize$(this.el.nativeElement)
      .pipe(untilDestroyed(this))
      .subscribe(size => {
        this.frame$.next(size);
        this.updateBackground();
      });
  }

  updateItems() {
    this.startItems = this.menuGeneratorService.cleanMenuItemsAppMode(this.block.startItems);
    this.centerItems = this.menuGeneratorService.cleanMenuItemsAppMode(this.block.centerItems);
    this.endItems = this.menuGeneratorService.cleanMenuItemsAppMode(this.block.endItems);
    this.cd.markForCheck();
  }

  updateBackground() {
    const fill = this.getFill();
    if (fill) {
      const css = fill.css({ frame: new Frame({ width: 320, height: 240 }) });
      this.menuBackground = this.sanitizer.bypassSecurityTrustStyle(css.background);
      this.menuBackgroundWidth = css.width;
      this.menuBackgroundHeight = css.height;
      this.menuBackgroundTransform = this.sanitizer.bypassSecurityTrustStyle(css.transform);
      this.menuIsLight = MenuSecondary.isLight(css.accentColor || this.backgroundColor);
      this.menuStyle = this.getStyleVars(css.accentColor, this.backgroundColor);
    } else {
      this.menuBackground = undefined;
      this.menuBackgroundWidth = undefined;
      this.menuBackgroundHeight = undefined;
      this.menuBackgroundTransform = undefined;
      this.menuIsLight = MenuSecondary.isLight(this.backgroundColor);
      this.menuStyle = this.getStyleVars(undefined, this.backgroundColor);
    }
  }

  updateBorder() {
    if (this.block.borderSettings && this.block.borderSettings.isSidesSet()) {
      this.menuBorderTop =
        this.block.borderSettings && this.block.borderSettings.borderTop
          ? this.sanitizer.bypassSecurityTrustStyle(this.block.borderSettings.borderTop.cssBorder(this.isDarkTheme))
          : undefined;
      this.menuBorderRight =
        this.block.borderSettings && this.block.borderSettings.borderRight
          ? this.sanitizer.bypassSecurityTrustStyle(this.block.borderSettings.borderRight.cssBorder(this.isDarkTheme))
          : undefined;
      this.menuBorderBottom =
        this.block.borderSettings && this.block.borderSettings.borderBottom
          ? this.sanitizer.bypassSecurityTrustStyle(this.block.borderSettings.borderBottom.cssBorder(this.isDarkTheme))
          : undefined;
      this.menuBorderLeft =
        this.block.borderSettings && this.block.borderSettings.borderLeft
          ? this.sanitizer.bypassSecurityTrustStyle(this.block.borderSettings.borderLeft.cssBorder(this.isDarkTheme))
          : undefined;
    } else {
      this.menuBorderTop = this.menuBorderRight = this.menuBorderBottom = this.menuBorderLeft =
        this.block.borderSettings && this.block.borderSettings.border
          ? this.sanitizer.bypassSecurityTrustStyle(this.block.borderSettings.border.cssBorder(this.isDarkTheme))
          : undefined;
    }
  }

  getFill(): Fill {
    if (!this.block.fillSettings) {
      return;
    }

    if (this.isDarkTheme) {
      return this.block.fillSettings.fillDark;
    } else {
      return this.block.fillSettings.fill;
    }
  }

  getStyleVars(accentColor: string, backgroundColor: string): SafeStyle {
    const vars = MenuSecondary.getVars(accentColor, backgroundColor);
    const styles = toPairs(vars)
      .map(([k, v]) => `--${k}: ${v}`)
      .join(';');

    return this.sanitizer.bypassSecurityTrustStyle(styles);
  }
}
