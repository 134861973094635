<app-customize-bar-header [title]="'Authentication'" [subtitle]="'Sign In & Sign Up'" [backEnabled]="false">
</app-customize-bar-header>

<div class="sidebar__content">
  <div *ngIf="!domain" class="sidebar__element">
    <app-alert [title]="'Domain is not set up'" [secondary]="true">
      <div>To customize sign up page you should connect custom domain to current App</div>
      <div style="margin-top: 4px;">
        <a href="javascript:void(0)" (click)="editDomain()">Connect domain</a>
      </div>
    </app-alert>
  </div>

  <div
    *ngIf="currentEnvironmentStore.instance.uniqueName != currentProjectStore.instance.defaultEnvironment?.uniqueName"
    class="sidebar__element"
  >
    <app-alert
      [title]="'Please open ' + currentProjectStore.instance.defaultEnvironment?.name + ' environment'"
      [warning]="true"
    >
      <div>Authentication configuration is App specific so you can't configure it for current environment only</div>
      <div style="margin-top: 4px;">
        <a
          [appLink]="currentProjectStore.instance.settingsSignUpLink('auth')"
          [appLinkEnvironment]="currentProjectStore.instance.defaultEnvironment?.uniqueName"
        >
          Go to {{ currentProjectStore.instance.defaultEnvironment?.name }}
        </a>
      </div>
    </app-alert>
  </div>

  <app-sidebar-section
    *ngIf="currentEnvironmentStore.instance.uniqueName == currentProjectStore.instance.defaultEnvironment?.uniqueName"
    [interactive]="!domain"
    [disabled]="!domain"
    (click)="onDomainRequiredClick()"
  >
    <div class="sidebar__list">
      <div class="sidebar-list__separator">
        <app-sidebar-list-separator [title]="'Built-in'"></app-sidebar-list-separator>
      </div>

      <div class="sidebar-list">
        <ng-container *ngIf="!isWhiteLabel">
          <div class="sidebar-list__item">
            <div
              class="sidebar-list-item sidebar-list-item_clickable"
              [class.sidebar-list-item_draggable]="false"
              [class.sidebar-list-item_disabled]="false"
              (click)="
                $event.stopPropagation();
                projectDomainUpdateForm.controls.disable_google_sign_in.patchValue(
                  !projectDomainUpdateForm.controls.disable_google_sign_in.value
                )
              "
            >
              <div class="sidebar-list-item__left">
                <div
                  class="sidebar-list-item__image sidebar-list-item__image_size_xl"
                  [style.background-image]="'/assets/images/sso/google.svg' | appDeployCssUrl"
                ></div>
              </div>
              <div class="sidebar-list-item__main">
                <div class="sidebar-list-item__title">Sign in with Google</div>
                <div class="sidebar-list-item__description">Jet Admin app</div>
              </div>
              <div class="sidebar-list-item__right">
                <app-toggle
                  [orange]="true"
                  [selected]="!projectDomainUpdateForm.controls.disable_google_sign_in.value"
                  (click)="
                    $event.stopPropagation();
                    projectDomainUpdateForm.controls.disable_google_sign_in.patchValue(
                      !projectDomainUpdateForm.controls.disable_google_sign_in.value
                    )
                  "
                ></app-toggle>
              </div>
            </div>
          </div>

          <div class="sidebar-list__item">
            <div
              class="sidebar-list-item sidebar-list-item_clickable"
              [class.sidebar-list-item_draggable]="false"
              [class.sidebar-list-item_disabled]="false"
              (click)="
                $event.stopPropagation();
                projectDomainUpdateForm.controls.disable_facebook_sign_in.patchValue(
                  !projectDomainUpdateForm.controls.disable_facebook_sign_in.value
                )
              "
            >
              <div class="sidebar-list-item__left">
                <div
                  class="sidebar-list-item__image sidebar-list-item__image_size_xl"
                  [style.background-image]="'/assets/images/sso/facebook.svg' | appDeployCssUrl"
                ></div>
              </div>
              <div class="sidebar-list-item__main">
                <div class="sidebar-list-item__title">Sign in with Facebook</div>
                <div class="sidebar-list-item__description">Jet Admin app</div>
              </div>
              <div class="sidebar-list-item__right">
                <app-toggle
                  [orange]="true"
                  [selected]="!projectDomainUpdateForm.controls.disable_facebook_sign_in.value"
                  (click)="
                    $event.stopPropagation();
                    projectDomainUpdateForm.controls.disable_facebook_sign_in.patchValue(
                      !projectDomainUpdateForm.controls.disable_facebook_sign_in.value
                    )
                  "
                ></app-toggle>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="isWhiteLabel">
          <ng-template #whitelabel_google_sign_in_disabled>
            Jet Admin branded <strong>Google Sign in</strong> button is disabled because of
            <strong>White-Label</strong> option enabled. If you need white-labeled <strong>Google Sign in</strong> you
            should add it as <strong>External Authentication</strong> below.
          </ng-template>

          <div class="sidebar-list__item">
            <div
              class="sidebar-list-item"
              [class.sidebar-list-item_draggable]="false"
              [class.sidebar-list-item_disabled]="true"
            >
              <div class="sidebar-list-item__left">
                <div
                  class="sidebar-list-item__image sidebar-list-item__image_size_xl"
                  [style.background-image]="'/assets/images/sso/google.svg' | appDeployCssUrl"
                ></div>
              </div>
              <div class="sidebar-list-item__main">
                <div class="sidebar-list-item__title">Sign in with Google</div>
                <div class="sidebar-list-item__description">
                  Disabled by white-label
                  <span
                    class="help-icon icon-help"
                    [appTip]="whitelabel_google_sign_in_disabled"
                    [appTipOptions]="{ side: 'top' }"
                  ></span>
                </div>
              </div>
              <div class="sidebar-list-item__right">
                <app-toggle [orange]="true" [selected]="false"></app-toggle>
              </div>
            </div>
          </div>

          <ng-template #whitelabel_facebook_sign_in_disabled>
            Jet Admin branded <strong>Facebook Sign in</strong> button is disabled because of
            <strong>White-Label</strong> option enabled. If you need white-labeled <strong>Facebook Sign in</strong> you
            should add it as <strong>External Authentication</strong> below.
          </ng-template>

          <div class="sidebar-list__item">
            <div
              class="sidebar-list-item"
              [class.sidebar-list-item_draggable]="false"
              [class.sidebar-list-item_disabled]="true"
            >
              <div class="sidebar-list-item__left">
                <div
                  class="sidebar-list-item__image sidebar-list-item__image_size_xl"
                  [style.background-image]="'/assets/images/sso/facebook.svg' | appDeployCssUrl"
                ></div>
              </div>
              <div class="sidebar-list-item__main">
                <div class="sidebar-list-item__title">Sign in with Facebook</div>
                <div class="sidebar-list-item__description">
                  Disabled by white-label
                  <span
                    class="help-icon icon-help"
                    [appTip]="whitelabel_facebook_sign_in_disabled"
                    [appTipOptions]="{ side: 'top' }"
                  ></span>
                </div>
              </div>
              <div class="sidebar-list-item__right">
                <app-toggle [orange]="true" [selected]="false"></app-toggle>
              </div>
            </div>
          </div>
        </ng-container>

        <div class="sidebar-list__item">
          <div
            class="sidebar-list-item sidebar-list-item_clickable"
            [class.sidebar-list-item_draggable]="true"
            [class.sidebar-list-item_disabled]="false"
            (click)="
              $event.stopPropagation();
              projectDomainUpdateForm.controls.disable_email_sign_in.patchValue(
                !projectDomainUpdateForm.controls.disable_email_sign_in.value
              )
            "
          >
            <div class="sidebar-list-item__left">
              <div
                class="sidebar-list-item__image sidebar-list-item__image_size_xl"
                [style.background-image]="'/assets/images/logo.png' | appDeployCssUrl"
              ></div>
            </div>
            <div class="sidebar-list-item__main">
              <div class="sidebar-list-item__title">Sign in with Email</div>
              <div class="sidebar-list-item__description">This App users</div>
            </div>
            <div class="sidebar-list-item__right">
              <app-toggle
                [orange]="true"
                [selected]="!projectDomainUpdateForm.controls.disable_email_sign_in.value"
                (click)="
                  $event.stopPropagation();
                  projectDomainUpdateForm.controls.disable_email_sign_in.patchValue(
                    !projectDomainUpdateForm.controls.disable_email_sign_in.value
                  )
                "
              ></app-toggle>
            </div>
          </div>
        </div>
      </div>

      <div class="sidebar-list__separator">
        <app-sidebar-list-separator [title]="'External Authentication'"></app-sidebar-list-separator>
      </div>

      <div *ngIf="ssoLoading" class="sidebar-list">
        <div *ngFor="let item of [1, 2]" cdkDrag class="sidebar-list__item">
          <div class="sidebar-list-item">
            <div class="sidebar-list-item__left">
              <span class="sidebar-list-item__icon icon-earth_planet"></span>
            </div>
            <div class="sidebar-list-item__main">
              <div class="sidebar-list-item__title">
                <span [class.loading-animation]="true"><span class="stub-text">name</span></span>
              </div>
              <div class="sidebar-list-item__description">
                <span [class.loading-animation]="true"><span class="stub-text">type</span></span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        *ngIf="!ssoLoading && sso"
        cdkDropList
        [cdkDropListData]="sso"
        [cdkDropListLockAxis]="'y'"
        class="sidebar-list"
        (cdkDropListDropped)="dragDrop($event)"
      >
        <div *ngFor="let item of sso" cdkDrag [cdkDragData]="item" class="sidebar-list__item">
          <div
            class="sidebar-list-item sidebar-list-item_clickable"
            [class.sidebar-list-item_draggable]="false"
            [class.sidebar-list-item_disabled]="false"
            (click)="editSSOSettings(item)"
          >
            <div class="sidebar-list-item__drag"></div>
            <div class="sidebar-list-item__left">
              <div
                *ngIf="item.image"
                class="sidebar-list-item__image sidebar-list-item__image_size_xl"
                [style.background-image]="'url(' + item.image + ')'"
              ></div>
              <span *ngIf="!item.image" class="sidebar-list-item__icon icon-earth_planet"></span>
            </div>
            <div class="sidebar-list-item__main">
              <div class="sidebar-list-item__title">{{ item.name || 'no name' }}</div>
              <div class="sidebar-list-item__description">{{ item.getTypeLabel() || 'Type not specified' }}</div>
            </div>
            <div class="sidebar-list-item__right">
              <app-toggle
                [orange]="true"
                [selected]="item.active"
                (click)="$event.stopPropagation(); toggleSSOSettingsActive(item)"
              ></app-toggle>
            </div>
          </div>
        </div>

        <div *ngIf="!sso.length" cdkDrag class="sidebar-list__item">
          <app-sso-type-menu-overlay
            *ngIf="!ssoCreateLoading"
            [origin]="add_item_origin"
            (ssoSelect)="
              createSSOSettings({
                name: $event.name,
                image: $event.image,
                type: $event.type,
                params: { backend: $event.backend, shared_custom_sso: $event.sharedCustomSSO }
              })
            "
          ></app-sso-type-menu-overlay>

          <div
            class="sidebar-list-item"
            [class.sidebar-list-item_clickable]="!ssoCreateLoading"
            [class.sidebar-list-item_disabled]="true"
            cdkOverlayOrigin
            #add_item_origin="cdkOverlayOrigin"
          >
            <div class="sidebar-list-item__left">
              <span class="sidebar-list-item__icon icon-earth_planet"></span>
            </div>
            <div class="sidebar-list-item__main">
              <div class="sidebar-list-item__title">New External Auth</div>
              <div class="sidebar-list-item__description">Not specified</div>
            </div>
            <div class="sidebar-list-item__right sidebar-list-item__right_padding_l">
              <div class="circle-image-series">
                <div
                  *ngFor="let item of popularSSOProviders"
                  class="circle-image-series__item"
                  [style.background-image]="'/assets/images/sso/' + item.image + '.svg' | appDeployCssUrl"
                ></div>
              </div>
            </div>
          </div>
        </div>

        <div class="sidebar-list__buttons">
          <app-sso-type-menu-overlay
            *ngIf="!ssoCreateLoading"
            [origin]="add_button_origin"
            (ssoSelect)="
              createSSOSettings({
                name: $event.name,
                image: $event.image,
                type: $event.type,
                params: { backend: $event.backend, shared_custom_sso: $event.sharedCustomSSO }
              })
            "
          ></app-sso-type-menu-overlay>

          <a
            href="javascript:void(0)"
            class="button button_orange-transparent button_small sidebar-list__buttons-item"
            [class.button_disabled]="ssoCreateLoading"
            cdkOverlayOrigin
            #add_button_origin="cdkOverlayOrigin"
          >
            <app-loader-small *ngIf="ssoCreateLoading" class="button__icon button__icon_left"></app-loader-small>
            <span *ngIf="!ssoCreateLoading" class="icon icon-plus button__icon button__icon_left"></span>
            <span class="button__label">Add External Authentication</span>
          </a>

          <a
            [appDocumentation]="'sso'"
            [appDocumentationLeft]="true"
            [appDocumentationSource]="analyticsSource"
            class="button button_orange-alternative button_small button_icon sidebar-list__buttons-item"
            [appTip]="'See how it works'"
            [appTipOptions]="{ side: 'top' }"
            (click)="$event.stopPropagation()"
          >
            <div class="button__icon icon-help"></div>
          </a>
        </div>
      </div>
    </div>
  </app-sidebar-section>
</div>
