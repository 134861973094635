import { Directive, HostBinding, Input } from '@angular/core';

import { TintStyle } from '@modules/actions';

import { TintColorDirective } from '../tint-color/tint-color.directive';

@Directive({
  selector: '[appButtonTintColor]'
})
export class ButtonTintColorDirective extends TintColorDirective {
  @Input('appButtonTintColor') appTintColor: string;
  @Input('appButtonTintColorStyle') appTintColorStyle: TintStyle;

  @HostBinding('class.button_tint-color') get cls() {
    return this.styleVars.length;
  }
}
