<div class="sidebar__list" [ngClass]="classes">
  <app-field-inputs-edit
    [control]="control.controls.query_inputs"
    [dataSource]="dataSource"
    [parameterProvider]="control.inputFieldProvider"
    [fieldsControl]="fieldsControl"
    [addInputEnabled]="editable"
    [nestedFieldsEnabled]="nestedFieldsEnabled"
    [relationsEnabled]="relationsEnabled"
    [context]="context"
    [contextElement]="contextElement"
    [contextElementPath]="contextElementPath"
    [contextElementPaths]="contextElementPaths"
    [userInput]="userInput"
    [classes]="itemClasses"
    [addClasses]="addClasses"
    [analyticsSource]="analyticsSource"
    (addInput)="addInput.emit()"
  >
  </app-field-inputs-edit>
</div>
