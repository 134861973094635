<app-field-wrapper
  *ngIf="!readonly"
  [formGroup]="form"
  [form]="form"
  [field]="field"
  [readonly]="readonly"
  [requiredAsterisk]="requiredAsterisk"
  [label]="label"
  [labelStyle]="elementStyles?.labelStyle"
  [labelStyleGlobal]="labelStyleGlobal"
  [labelAdditional]="labelAdditional"
  [labelAdditionalStyle]="elementStyles?.labelAdditionalStyle"
  [labelAdditionalStyleGlobal]="labelAdditionalStyleGlobal"
  [errors]="errors"
  [truncate]="truncate"
  [manualMargin]="manualMargin"
  [labelButtons]="labelButtons"
  [tooltip]="tooltip"
  [idToken]="idToken"
  [theme]="field.params['theme']"
>
  <div class="code-editor" [class.code-editor_flexible]="field.params['flexible']">
    <div class="code-editor__main">
      <div
        class="code-editor__main-code"
        [class.code-editor__main-code_side]="field.params['table_tokens'] !== false && tables?.length"
      >
        <div
          *ngIf="field.placeholder && !(form.value[field.name] | appIsSet)"
          class="code-editor__placeholder"
          [innerHTML]="field.placeholder | appFormatText"
        ></div>

        <div
          ace-editor
          [mode]="'sql'"
          [theme]="theme"
          [text]="form.value[field.name]"
          [options]="{
            maxLines: 1000,
            printMargin: false,
            enableBasicAutocompletion: true,
            enableSnippets: true,
            enableLiveAutocompletion: true,
            tabSize: 2,
            useSoftTabs: true
          }"
          (textChanged)="setCurrentValue($event)"
          class="code-editor__editor"
          [id]="field.name | appUniqueId: idToken"
        ></div>
        <div *ngIf="field.params['preview'] !== false" class="code-editor__main-code-submit">
          <button type="button" class="button button_primary button_small" (click)="executeSql()">
            <span class="button__label">Execute</span>
          </button>
        </div>
      </div>
      <div *ngIf="field.params['table_tokens'] !== false && tables?.length" class="code-editor__main-side">
        <div class="code-editor__structure">
          <app-table-tokens [resourceUniqueName]="field.params['resource']" (inserted)="editorInsert($event)">
          </app-table-tokens>
        </div>
      </div>
    </div>

    <div *ngIf="field.params['preview'] !== false" class="code-editor__results">
      <div class="code-editor__results-inner">
        <div class="data-table">
          <table *ngIf="sqlResult" class="data-table__table">
            <thead *ngIf="sqlResult.columns">
              <tr class="data-table__header-row">
                <th
                  *ngFor="let column of sqlResult.columns; let first = first"
                  class="data-table__header-column"
                  [class.data-table__header-column_primary]="first"
                >
                  {{ column }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of sqlResult.data.slice(0, 10)" class="data-table__row">
                <td
                  *ngFor="let column of row; let first = first"
                  class="data-table__column"
                  [class.data-table__column_primary]="first"
                >
                  {{ column }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div *ngIf="!sqlResult">
          <div *ngIf="!sqlError" class="data-table-tip">
            No data to display. Please enter correct SQL in the field above.
          </div>
          <ng-container *ngIf="sqlError">
            <div class="data-table-tip">
              SQL query failed with error. Please enter correct SQL in the field above.
            </div>
            <pre class="data-table-error">
                  {{ sqlError }}
              </pre
            >
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</app-field-wrapper>
<app-field-wrapper
  *ngIf="readonly"
  [form]="form"
  [field]="field"
  [readonly]="readonly"
  [requiredAsterisk]="requiredAsterisk"
  [label]="label"
  [labelStyle]="elementStyles?.labelStyle"
  [labelStyleGlobal]="labelStyleGlobal"
  [labelAdditional]="labelAdditional"
  [labelAdditionalStyle]="elementStyles?.labelAdditionalStyle"
  [labelAdditionalStyleGlobal]="labelAdditionalStyleGlobal"
  [errors]="errors"
  [truncate]="truncate"
  [manualMargin]="manualMargin"
  [labelButtons]="labelButtons"
  [tooltip]="tooltip"
  [idToken]="idToken"
  [theme]="field.params['theme']"
>
  {{ formatEmpty(currentValue) }}
</app-field-wrapper>
