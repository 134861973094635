/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../sidebar/components/sidebar-section/sidebar-section.component.ngfactory";
import * as i2 from "../../../../../sidebar/components/sidebar-section/sidebar-section.component";
import * as i3 from "@angular/common";
import * as i4 from "../../../data-source-inputs-edit/data-source-inputs-edit.component.ngfactory";
import * as i5 from "../../../data-source-inputs-edit/data-source-inputs-edit.component";
import * as i6 from "./customize-bar-action-edit-parameters-export.component";
var styles_CustomizeBarActionEditParametersExportComponent = [];
var RenderType_CustomizeBarActionEditParametersExportComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CustomizeBarActionEditParametersExportComponent, data: {} });
export { RenderType_CustomizeBarActionEditParametersExportComponent as RenderType_CustomizeBarActionEditParametersExportComponent };
function View_CustomizeBarActionEditParametersExportComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "div", [["class", "sidebar-section-border"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 6, "app-sidebar-section", [], null, null, null, i1.View_SidebarSectionComponent_0, i1.RenderType_SidebarSectionComponent)), i0.ɵdid(3, 114688, null, 0, i2.SidebarSectionComponent, [], { title: [0, "title"], description: [1, "description"], border: [2, "border"] }, null), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(6, 0, null, 3, 2, "app-data-source-inputs-edit", [], null, [[null, "addInput"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("addInput" === en)) {
        var pd_0 = (_co.addQueryInput.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_DataSourceInputsEditComponent_0, i4.RenderType_DataSourceInputsEditComponent)), i0.ɵdid(7, 245760, null, 0, i5.DataSourceInputsEditComponent, [i0.ChangeDetectorRef], { control: [0, "control"], context: [1, "context"], editable: [2, "editable"], contextElement: [3, "contextElement"], contextElementPath: [4, "contextElementPath"], contextElementPaths: [5, "contextElementPaths"], userInput: [6, "userInput"], classes: [7, "classes"], itemClasses: [8, "itemClasses"], analyticsSource: [9, "analyticsSource"] }, { addInput: "addInput" }), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = (i0.ɵunv(_v, 3, 0, i0.ɵnov(_v, 4).transform(_co.form.controls.export_data_source.getQueryEditable$())) ? "Specify Inputs" : "Apply Filters"); var currVal_1 = (i0.ɵunv(_v, 3, 1, i0.ɵnov(_v, 5).transform(_co.form.controls.export_data_source.getQueryEditable$())) ? "Manually or use value from other components" : "Static or use value from other components"); var currVal_2 = true; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.form.controls.export_data_source; var currVal_4 = _co.context; var currVal_5 = i0.ɵunv(_v, 7, 2, i0.ɵnov(_v, 8).transform(_co.form.controls.export_data_source.getQueryEditable$())); var currVal_6 = _co.contextElement; var currVal_7 = _co.contextElementPath; var currVal_8 = _co.contextElementPaths; var currVal_9 = _co.userInput; var currVal_10 = "component-action-inputs"; var currVal_11 = "component-action-inputs-item"; var currVal_12 = _co.analyticsSource; _ck(_v, 7, 0, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12); }, null); }
export function View_CustomizeBarActionEditParametersExportComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 2, null, View_CustomizeBarActionEditParametersExportComponent_1)), i0.ɵdid(1, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var tmp_0_0 = null; var currVal_0 = ((_co.form.controls.export_data_type.value == _co.exportDataTypes.DataSource) && (((tmp_0_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.form.controls.export_data_source.inputFieldProvider.getItems$()))) == null) ? null : tmp_0_0.length)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CustomizeBarActionEditParametersExportComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-customize-bar-action-edit-parameters-export", [], null, null, null, View_CustomizeBarActionEditParametersExportComponent_0, RenderType_CustomizeBarActionEditParametersExportComponent)), i0.ɵdid(1, 114688, null, 0, i6.CustomizeBarActionEditParametersExportComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomizeBarActionEditParametersExportComponentNgFactory = i0.ɵccf("app-customize-bar-action-edit-parameters-export", i6.CustomizeBarActionEditParametersExportComponent, View_CustomizeBarActionEditParametersExportComponent_Host_0, { form: "form", context: "context", contextElement: "contextElement", contextElementPath: "contextElementPath", contextElementPaths: "contextElementPaths", userInput: "userInput", analyticsSource: "analyticsSource" }, { addQueryInput: "addQueryInput" }, []);
export { CustomizeBarActionEditParametersExportComponentNgFactory as CustomizeBarActionEditParametersExportComponentNgFactory };
