var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Injector, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, concat, defer, of } from 'rxjs';
import { catchError, filter, first, map, switchMap } from 'rxjs/operators';
import { DialogService } from '@common/dialogs';
import { PopupService } from '@common/popups';
import { ActionService, WorkflowExecuteEventType } from '@modules/action-queries';
import { HomeType, ProjectSettingsStore } from '@modules/all-project-settings';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { DEMO_RESOURCES_PROJECT, ServerRequestError } from '@modules/api';
import { CustomizeService, ViewSettingsStore } from '@modules/customize';
import { GlobalContext } from '@modules/customize-utils';
import { ButtonMenuItem, findMenuItem, MenuItemActionType, MenuPagesService, MenuSettingsStore, ModelLinkMenuItem, SimpleMenuItem } from '@modules/menu';
import { ModelDescriptionStore } from '@modules/model-queries';
import { NavigationService } from '@modules/navigation';
import { CurrentEnvironmentStore, CurrentProjectStore, HomeTriggerOutput, ProjectDeployment } from '@modules/projects';
import { PROJECT_CREATE_TEMPLATE_ITEM } from '@modules/projects-routes';
import { RoutingService } from '@modules/routing';
import { TemplateService } from '@modules/template';
import { ChooseTemplateSection, ChooseTemplateService, TemplateApplyController, TemplateItemType } from '@modules/template-components';
import { WorkflowBackendRunService } from '@modules/workflow';
import { ascComparator, isSet } from '@shared';
var DefaultComponent = /** @class */ (function () {
    function DefaultComponent(context, modelDescriptionStore, viewSettingsStore, currentProjectStore, currentEnvironmentStore, projectSettingsStore, actionService, workflowBackendRunService, menuSettingsStore, activatedRoute, dialogService, navigationService, customizeService, routing, templateService, chooseTemplateService, templateApplyController, menuPagesService, popupService, analyticsService, injector) {
        this.context = context;
        this.modelDescriptionStore = modelDescriptionStore;
        this.viewSettingsStore = viewSettingsStore;
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.projectSettingsStore = projectSettingsStore;
        this.actionService = actionService;
        this.workflowBackendRunService = workflowBackendRunService;
        this.menuSettingsStore = menuSettingsStore;
        this.activatedRoute = activatedRoute;
        this.dialogService = dialogService;
        this.navigationService = navigationService;
        this.customizeService = customizeService;
        this.routing = routing;
        this.templateService = templateService;
        this.chooseTemplateService = chooseTemplateService;
        this.templateApplyController = templateApplyController;
        this.menuPagesService = menuPagesService;
        this.popupService = popupService;
        this.analyticsService = analyticsService;
        this.injector = injector;
        this.loading = false;
        this.openedOnboarding = false;
    }
    DefaultComponent.prototype.ngOnInit = function () {
        var _this = this;
        var templateItem = this.popProjectCreateTemplateItem();
        if (templateItem) {
            var templateObs = templateItem.template
                ? this.templateService.getDetail(String(templateItem.template))
                : of(undefined);
            templateObs.pipe(untilDestroyed(this)).subscribe(function (template) { return _this.applyTemplate(templateItem, template); });
        }
        else {
            this.redirectIfNeeded()
                .pipe(untilDestroyed(this))
                .subscribe(function (redirected) {
                if (!redirected && _this.customizeService.enabled && !_this.openedOnboarding) {
                    _this.openedOnboarding = true;
                    _this.openOnboarding();
                }
            });
        }
        // this.activatedRoute.queryParams.pipe(untilDestroyed(this)).subscribe(params => {
        //   const apiBaseUrl = this.detectApiBaseUrl(params['install_referrer']);
        //
        //   if (apiBaseUrl) {
        //     this.dialogService
        //       .dialog({
        //         title: 'Finish installation',
        //         description: `
        //         Use the following API URL to finish Django installation:<br>
        //         <strong>${apiBaseUrl}</strong>
        //     `,
        //         buttons: [
        //           {
        //             name: 'copy',
        //             label: 'Copy API URL',
        //             type: DialogButtonType.Default
        //           },
        //           {
        //             name: 'ok',
        //             label: 'OK',
        //             type: DialogButtonType.Primary
        //           }
        //         ]
        //       })
        //       .subscribe(result => {
        //         if (result.button == 'copy') {
        //           copyTextToClipboard(apiBaseUrl);
        //         }
        //       });
        //   }
        // });
    };
    DefaultComponent.prototype.ngOnDestroy = function () {
        this.closeOnboardingPopup();
    };
    DefaultComponent.prototype.popProjectCreateTemplateItem = function () {
        var _a;
        var route = this.activatedRoute.snapshot;
        if (!route.queryParams.hasOwnProperty(PROJECT_CREATE_TEMPLATE_ITEM)) {
            return;
        }
        var templateItemData = route.queryParams[PROJECT_CREATE_TEMPLATE_ITEM];
        this.routing.navigateRoute(route, {
            queryParams: __assign({}, route.queryParams, (_a = {}, _a[PROJECT_CREATE_TEMPLATE_ITEM] = undefined, _a)),
            replaceUrl: true
        });
        if (templateItemData) {
            try {
                return JSON.parse(templateItemData);
            }
            catch (e) { }
        }
    };
    DefaultComponent.prototype.applyTemplate = function (templateItem, template) {
        var _this = this;
        if (templateItem.type == TemplateItemType.AdminPanel && !templateItem.resource) {
            if (this.customizeService.enabled && !this.openedOnboarding) {
                this.openedOnboarding = true;
                this.openOnboarding({ type: TemplateItemType.AdminPanel });
            }
        }
        else {
            this.templateApplyController
                .applyTemplateProcess(this.currentProjectStore.instance, this.currentEnvironmentStore.instance, this.injector, {
                type: templateItem.type,
                resourceType: templateItem.resource,
                template: template,
                resourceNameEditing: false,
                useDemoResources: true,
                analyticsSource: 'onboarding'
            })
                .pipe(untilDestroyed(this))
                .subscribe(function (result) {
                if (result.link) {
                    _this.routing.navigateApp(result.link, { replaceUrl: true });
                }
                else if (result.cancelled) {
                    _this.openOnboarding();
                }
            });
        }
    };
    DefaultComponent.prototype.redirectIfNeeded = function () {
        if (this.customizeService.enabled) {
            return this.builderRedirectIfNeeded();
        }
        else if (!this.customizeService.enabled) {
            return this.appRedirectIfNeeded();
        }
        else {
            return of(false);
        }
    };
    DefaultComponent.prototype.pageRedirect = function (pageUid) {
        var _this = this;
        if (!isSet(pageUid)) {
            return of(false);
        }
        return this.viewSettingsStore.getDetailByUidFirst(pageUid).pipe(map(function (viewSettings) {
            if (!viewSettings) {
                return false;
            }
            _this.routing.navigateApp(viewSettings.link, { replaceUrl: true });
            return true;
        }));
    };
    DefaultComponent.prototype.workflowRedirect = function (workflow) {
        var _this = this;
        var _a;
        if (!isSet(workflow)) {
            return of(false);
        }
        var source = this.activatedRoute.snapshot.queryParams['src'];
        var sourceAcceptInvite = source == HomeTriggerOutput.AcceptInvite;
        var sourceRegister = source == HomeTriggerOutput.Register;
        var params = (_a = {},
            _a[HomeTriggerOutput.AcceptInvite] = sourceAcceptInvite,
            _a[HomeTriggerOutput.Register] = sourceRegister,
            _a[HomeTriggerOutput.Login] = !sourceAcceptInvite && !sourceRegister,
            _a);
        var dateRun = moment();
        return this.actionService
            .executeWorkflow(workflow, params, { context: this.context, replaceUrl: true, injector: this.injector })
            .pipe(filter(function (event) { return event.type == WorkflowExecuteEventType.WorkflowFinished; }), map(function (event) {
            var dateFinished = moment();
            _this.workflowBackendRunService
                .createForWorkflow(_this.currentProjectStore.instance.uniqueName, _this.currentEnvironmentStore.instance.uniqueName, workflow, event.success, event.run, dateRun, dateFinished, params)
                .subscribe();
            if (event.error) {
                throw new ServerRequestError(event.error);
            }
            return true;
        }));
    };
    DefaultComponent.prototype.firstCustomizablePageRedirect = function () {
        var _this = this;
        return this.menuPagesService.getGroups().pipe(first(), map(function (groups) {
            var nonEmptyGroup = groups.find(function (item) { return item.items.length > 0; });
            if (nonEmptyGroup) {
                var link = nonEmptyGroup.items[0].link;
                _this.routing.navigateApp(link, { replaceUrl: true });
                return true;
            }
            var project = _this.currentProjectStore.instance;
            var firstResource = _this.currentEnvironmentStore.resources
                .filter(function (item) { return !item.typeItem.protected; })
                .filter(function (item) { return !item.demo || (item.demo && project.uniqueName == DEMO_RESOURCES_PROJECT); })
                .sort(function (lhs, rhs) { return ascComparator(lhs.name.toLowerCase(), rhs.name.toLowerCase()); })[0];
            if (firstResource) {
                _this.routing.navigateApp(firstResource.link, { replaceUrl: true });
                return true;
            }
            return false;
        }));
    };
    DefaultComponent.prototype.firstMenuItemRedirect = function () {
        var _this = this;
        return combineLatest(this.menuSettingsStore.getFirst(), this.modelDescriptionStore.getFirst(), this.viewSettingsStore.getFirst()).pipe(map(function (_a) {
            var settings = _a[0], modelDescriptions = _a[1], viewSettings = _a[2];
            var link;
            findMenuItem(settings.getAllItems(), function (item) {
                if (item instanceof ModelLinkMenuItem) {
                    var modelDescription = modelDescriptions ? modelDescriptions.find(function (i) { return i.isSame(item.model); }) : undefined;
                    if (modelDescription) {
                        link = modelDescription.link;
                        return true;
                    }
                }
                else if ((item instanceof SimpleMenuItem || item instanceof ButtonMenuItem) &&
                    item.action &&
                    item.action.type == MenuItemActionType.Page) {
                    var page = viewSettings ? viewSettings.find(function (i) { return i.isSame(item.action.pageUid); }) : undefined;
                    if (page) {
                        link = page.link;
                        return true;
                    }
                }
                return false;
            });
            if (!link) {
                return false;
            }
            _this.routing.navigateApp(link, { replaceUrl: true });
            return true;
        }));
    };
    DefaultComponent.prototype.newPageRedirect = function () {
        this.routing.navigateApp(this.currentProjectStore.instance.newPageLink, { replaceUrl: true });
        return of(true);
    };
    DefaultComponent.prototype.appRedirectIfNeeded = function () {
        var _this = this;
        return this.projectSettingsStore.getAllSettingsFirst$().pipe(switchMap(function (projectSettings) {
            var methods = [];
            if (projectSettings.homeType == HomeType.Page) {
                methods.push(defer(function () { return _this.pageRedirect(projectSettings.homePageUid); }));
            }
            else if (projectSettings.homeType == HomeType.Workflow) {
                methods.push(defer(function () { return _this.workflowRedirect(projectSettings.homeWorkflow); }));
            }
            methods.push(defer(function () { return _this.firstMenuItemRedirect(); }));
            methods.push(defer(function () { return _this.newPageRedirect(); }));
            return concat.apply(void 0, methods.map(function (item) { return item.pipe(catchError(function () { return of(true); })); })).pipe(first(function (redirected) { return redirected; }));
        }));
    };
    DefaultComponent.prototype.builderRedirectIfNeeded = function () {
        var project = this.currentProjectStore.instance;
        if (project && project.deployment == ProjectDeployment.OnPremise) {
            this.routing.navigateApp(project.onPremiseLink, { replaceUrl: true });
            return of(true);
        }
        else {
            return this.firstCustomizablePageRedirect();
        }
    };
    DefaultComponent.prototype.closeOnboardingPopup = function () {
        if (this.onboardingPopup) {
            this.popupService.remove(this.onboardingPopup);
            this.popupService = undefined;
        }
    };
    DefaultComponent.prototype.openOnboarding = function (options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        this.closeOnboardingPopup();
        this.chooseTemplateService
            .chooseTemplate(this.injector, __assign({ chooseSection: false, title: 'Start creating your first App', initialSection: ChooseTemplateSection.Resources, newPage: true, analyticsSource: 'onboarding', analyticsInnerSource: 'onboarding' }, options))
            .subscribe(function (templateResult) {
            _this.onboardingPopup = undefined;
            _this.customizeService.requestPublish();
            _this.redirectIfNeeded().subscribe();
            if (templateResult.resource) {
                _this.analyticsService.sendSimpleEvent(AnalyticsEvent.ProjectCreate.ResourceCreated, {
                    ResourceID: templateResult.resource.typeItem.name,
                    ResourceType: templateResult.resource.typeItem.resourceType,
                    ResourceHasAutoCollections: templateResult.resource.typeItem.hasAutoCollections
                });
            }
            else if (templateResult.template && templateResult.template.type == TemplateItemType.AdminPanel) {
                _this.analyticsService.sendSimpleEvent(AnalyticsEvent.ProjectCreate.AdminPanelTemplateApplied, {
                    TemplateID: templateResult.template.name,
                    ResourceType: templateResult.template.resource ? templateResult.template.resource.name : undefined
                });
            }
            else if (templateResult.template) {
                _this.analyticsService.sendSimpleEvent(AnalyticsEvent.ProjectCreate.TemplateApplied, {
                    TemplateID: templateResult.template.name
                });
            }
            _this.analyticsService.sendSimpleEvent(AnalyticsEvent.ProjectCreate.StartBuildingApp, __assign({}, (templateResult.template ? { TemplateID: templateResult.template.name } : {}), (templateResult.resource
                ? {
                    ResourceID: templateResult.resource.typeItem.name,
                    ResourceType: templateResult.resource.typeItem.resourceType,
                    ResourceHasAutoCollections: templateResult.resource.typeItem.hasAutoCollections
                }
                : {}), (templateResult.blankApp ? { BlankApp: true } : {})));
        });
        this.onboardingPopup = this.popupService.last();
    };
    return DefaultComponent;
}());
export { DefaultComponent };
