import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { FillSettingsControl } from '@modules/colors-components';
import { BorderSettingsControl, CornersControl, MarginControl, ShadowControl } from '@modules/customize';
import { FieldType } from '@modules/fields';
import { TypedChanges } from '@shared';

import { Style, StyleItem, StylesEditComponent, StyleType } from '../styles-edit/styles-edit.component';
import { TextStyleControl } from '../text-style-edit/text-style.control';
import { FieldElementStylesControl } from './field-element-styles.control';

@Component({
  selector: 'app-styles-field-element',
  templateUrl: './styles-field-element-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StylesFieldElementEditComponent implements OnInit, OnDestroy, OnChanges {
  @Input() control: FieldElementStylesControl;
  @Input() field: FieldType = FieldType.Text;

  @ViewChild(StylesEditComponent) stylesEditComponent: StylesEditComponent;

  styles: StyleItem[] = [];

  ngOnInit() {
    this.updateStyles();
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<StylesFieldElementEditComponent>): void {
    if (changes.field && !changes.field.firstChange) {
      this.updateStyles();
    }
  }

  updateStyles() {
    const inputStyles = ![
      FieldType.Boolean,
      FieldType.RadioButton,
      FieldType.RichText,
      FieldType.File,
      FieldType.Image,
      FieldType.Audio,
      FieldType.Video,
      FieldType.Slider,
      FieldType.Signature,
      FieldType.JSON
    ].includes(this.field);
    const inputTextStyles = inputStyles && ![FieldType.Rating].includes(this.field);

    this.styles = [
      ...(inputTextStyles
        ? [
            {
              style: {
                label: 'Text',
                icon: 'text',
                type: StyleType.TextStyle,
                control: this.control.controls.text_style,
                isSet$: control => control.isSet$(),
                onRemove: style => style.control.resetDefaults()
              } as Style<TextStyleControl>
            },
            {
              style: {
                label: 'Placeholder',
                icon: 'text',
                type: StyleType.TextStyle,
                control: this.control.controls.placeholder_style,
                isSet$: control => control.isSet$(),
                onRemove: style => style.control.resetDefaults()
              } as Style<TextStyleControl>
            }
          ]
        : []),
      {
        style: {
          label: 'Label',
          icon: 'text',
          type: StyleType.TextStyle,
          control: this.control.controls.label_style,
          isSet$: control => control.isSet$(),
          onRemove: style => style.control.resetDefaults()
        } as Style<TextStyleControl>
      },
      {
        style: {
          label: 'Label additional',
          icon: 'text',
          type: StyleType.TextStyle,
          control: this.control.controls.label_additional_style,
          isSet$: control => control.isSet$(),
          onRemove: style => style.control.resetDefaults()
        } as Style<TextStyleControl>
      },
      ...(inputStyles
        ? [
            {
              style: {
                label: 'Background',
                icon: 'palette',
                type: StyleType.Fill,
                control: this.control.controls.fill_settings,
                isSet$: control => control.isSet$(),
                onAdd: style => style.control.resetDefaults(),
                onRemove: style => style.control.reset()
              } as Style<FillSettingsControl>
            },
            {
              style: {
                label: 'Border',
                icon: 'sides',
                type: StyleType.BorderSettings,
                control: this.control.controls.border_settings,
                isSet$: control => control.isSet$(),
                onAdd: style => style.control.resetDefaults(),
                onRemove: style => style.control.reset()
              } as Style<BorderSettingsControl>
            },
            {
              style: {
                label: 'Corner radius',
                icon: 'corners',
                type: StyleType.Corners,
                control: this.control.controls.border_radius,
                isSet$: control => control.isSet$(),
                onAdd: style => style.control.resetDefaults(),
                onRemove: style => style.control.reset()
              } as Style<CornersControl>
            },
            {
              style: {
                label: 'Shadow',
                icon: 'duplicate_2',
                type: StyleType.Shadow,
                control: this.control.controls.shadow,
                isSet$: control => control.isSet$(),
                onAdd: style => style.control.resetDefaults(),
                onRemove: style => style.control.reset()
              } as Style<ShadowControl>
            },
            {
              style: {
                label: 'Inner spacing',
                icon: 'align_horizontal_fill',
                type: StyleType.Margin,
                control: this.control.controls.padding,
                isSet$: control => control.isSet$(),
                onRemove: style => style.control.reset()
              } as Style<MarginControl>
            }
          ]
        : []),
      {
        style: {
          label: 'Outer spacing',
          icon: 'align_horizontal_center',
          type: StyleType.Margin,
          control: this.control.controls.margin,
          isSet$: control => control.isSet$(),
          onRemove: style => style.control.reset()
        } as Style<MarginControl>
      },
      ...(inputStyles
        ? [
            {
              label: 'On hover',
              icon: 'cursor',
              children: [
                ...(inputTextStyles
                  ? [
                      {
                        label: 'Text',
                        additional: '(on hover)',
                        icon: 'text',
                        type: StyleType.TextStyle,
                        control: this.control.controls.hover_text_style,
                        isSet$: control => control.isSet$(),
                        onRemove: style => style.control.resetDefaults()
                      } as Style<TextStyleControl>,
                      {
                        label: 'Placeholder',
                        additional: '(on hover)',
                        icon: 'text',
                        type: StyleType.TextStyle,
                        control: this.control.controls.hover_placeholder_style,
                        isSet$: control => control.isSet$(),
                        onRemove: style => style.control.resetDefaults()
                      } as Style<TextStyleControl>
                    ]
                  : []),
                {
                  label: 'Background',
                  additional: '(on hover)',
                  icon: 'palette',
                  type: StyleType.Fill,
                  control: this.control.controls.hover_fill_settings,
                  isSet$: control => control.isSet$(),
                  onAdd: style => style.control.resetDefaults(),
                  onRemove: style => style.control.reset()
                } as Style<FillSettingsControl>,
                {
                  label: 'Border',
                  additional: '(on hover)',
                  icon: 'sides',
                  type: StyleType.BorderSettings,
                  control: this.control.controls.hover_border_settings,
                  isSet$: control => control.isSet$(),
                  onAdd: style => style.control.resetDefaults(),
                  onRemove: style => style.control.reset()
                } as Style<BorderSettingsControl>,
                {
                  label: 'Shadow',
                  additional: '(on hover)',
                  icon: 'duplicate_2',
                  type: StyleType.Shadow,
                  control: this.control.controls.hover_shadow,
                  isSet$: control => control.isSet$(),
                  onAdd: style => style.control.resetDefaults(),
                  onRemove: style => style.control.reset()
                } as Style<ShadowControl>
              ]
            },
            {
              label: 'On focus',
              icon: 'select_all',
              children: [
                ...(inputTextStyles
                  ? [
                      {
                        label: 'Text',
                        additional: '(on focus)',
                        icon: 'text',
                        type: StyleType.TextStyle,
                        control: this.control.controls.focus_text_style,
                        isSet$: control => control.isSet$(),
                        onRemove: style => style.control.resetDefaults()
                      } as Style<TextStyleControl>,
                      {
                        label: 'Placeholder',
                        additional: '(on focus)',
                        icon: 'text',
                        type: StyleType.TextStyle,
                        control: this.control.controls.focus_placeholder_style,
                        isSet$: control => control.isSet$(),
                        onRemove: style => style.control.resetDefaults()
                      } as Style<TextStyleControl>
                    ]
                  : []),
                {
                  label: 'Background',
                  additional: '(on focus)',
                  icon: 'palette',
                  type: StyleType.Fill,
                  control: this.control.controls.focus_fill_settings,
                  isSet$: control => control.isSet$(),
                  onAdd: style => style.control.resetDefaults(),
                  onRemove: style => style.control.reset()
                } as Style<FillSettingsControl>,
                {
                  label: 'Border',
                  additional: '(on focus)',
                  icon: 'sides',
                  type: StyleType.BorderSettings,
                  control: this.control.controls.focus_border_settings,
                  isSet$: control => control.isSet$(),
                  onAdd: style => style.control.resetDefaults(),
                  onRemove: style => style.control.reset()
                } as Style<BorderSettingsControl>,
                {
                  label: 'Shadow',
                  additional: '(on focus)',
                  icon: 'duplicate_2',
                  type: StyleType.Shadow,
                  control: this.control.controls.focus_shadow,
                  isSet$: control => control.isSet$(),
                  onAdd: style => style.control.resetDefaults(),
                  onRemove: style => style.control.reset()
                } as Style<ShadowControl>
              ]
            },
            {
              label: 'On error',
              icon: 'alert',
              children: [
                ...(inputTextStyles
                  ? [
                      {
                        label: 'Text',
                        additional: '(on error)',
                        icon: 'text',
                        type: StyleType.TextStyle,
                        control: this.control.controls.error_text_style,
                        isSet$: control => control.isSet$(),
                        onRemove: style => style.control.resetDefaults()
                      } as Style<TextStyleControl>,
                      {
                        label: 'Placeholder',
                        additional: '(on error)',
                        icon: 'text',
                        type: StyleType.TextStyle,
                        control: this.control.controls.error_placeholder_style,
                        isSet$: control => control.isSet$(),
                        onRemove: style => style.control.resetDefaults()
                      } as Style<TextStyleControl>
                    ]
                  : []),
                {
                  label: 'Background',
                  additional: '(on error)',
                  icon: 'palette',
                  type: StyleType.Fill,
                  control: this.control.controls.error_fill_settings,
                  isSet$: control => control.isSet$(),
                  onAdd: style => style.control.resetDefaults(),
                  onRemove: style => style.control.reset()
                } as Style<FillSettingsControl>,
                {
                  label: 'Border',
                  additional: '(on error)',
                  icon: 'sides',
                  type: StyleType.BorderSettings,
                  control: this.control.controls.error_border_settings,
                  isSet$: control => control.isSet$(),
                  onAdd: style => style.control.resetDefaults(),
                  onRemove: style => style.control.reset()
                } as Style<BorderSettingsControl>,
                {
                  label: 'Shadow',
                  additional: '(on error)',
                  icon: 'duplicate_2',
                  type: StyleType.Shadow,
                  control: this.control.controls.error_shadow,
                  isSet$: control => control.isSet$(),
                  onAdd: style => style.control.resetDefaults(),
                  onRemove: style => style.control.reset()
                } as Style<ShadowControl>
              ]
            }
          ]
        : [])
    ];
  }
}
