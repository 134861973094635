import { OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { combineLatest, of } from 'rxjs';
import { debounceTime, first, map, tap } from 'rxjs/operators';
import { Approve } from '@modules/actions';
import { TaskQueueStore } from '@modules/collaboration';
import { ElementConfigurationService } from '@modules/customize-configuration';
import { Input, isRequiredInputsSet } from '@modules/fields';
import { FieldInputControl, FieldInputRequiredValidator, InputFieldProvider, parametersToProviderItems } from '@modules/parameters';
import { controlValid, controlValue } from '@shared';
var CustomizeBarApproveEditForm = /** @class */ (function () {
    function CustomizeBarApproveEditForm(taskQueueStore, elementConfigurationService) {
        var _this = this;
        this.taskQueueStore = taskQueueStore;
        this.elementConfigurationService = elementConfigurationService;
        this.form = new FormGroup({
            task_queue: new FormControl(null, Validators.required),
            task_name: new FieldInputControl({ path: ['value'] }, FieldInputRequiredValidator),
            task_inputs: new FormControl([], this.validateInputs()),
            task_create_status: new FormControl('', Validators.required),
            task_approve_status: new FormControl(''),
            task_reject_status: new FormControl(''),
            task_assignee: new FormControl(null),
            on_task_create_actions: new FormControl([], undefined, this.validateActions()),
            on_reject_actions: new FormControl([], undefined, this.validateActions())
        });
        this.inputFieldProvider = new InputFieldProvider();
        this.form.controls['task_queue'].valueChanges.subscribe(function () {
            _this.onQueueChange();
        });
    }
    CustomizeBarApproveEditForm.prototype.ngOnDestroy = function () {
        this.inputFieldProvider.clearProvider();
    };
    CustomizeBarApproveEditForm.prototype.validateInputs = function () {
        var _this = this;
        return function (control) {
            if (!_this.form) {
                return;
            }
            var fields = _this.inputFieldProvider.getFields();
            var inputs = control.value;
            if (!isRequiredInputsSet(fields, inputs)) {
                return { required: true };
            }
        };
    };
    CustomizeBarApproveEditForm.prototype.validateActions = function () {
        var _this = this;
        return function (control) {
            if (!_this.form) {
                return of(null);
            }
            if (!control.value || !control.value.length) {
                return of(null);
            }
            return combineLatest(control.value.map(function (item) { return _this.elementConfigurationService.isActionConfigured(item); })).pipe(map(function (result) {
                if (result.some(function (configured) { return !configured; })) {
                    return { required: true };
                }
            }));
        };
    };
    CustomizeBarApproveEditForm.prototype.init = function (control, context, contextElement, firstInit) {
        var _this = this;
        if (firstInit === void 0) { firstInit = false; }
        this.control = control;
        this.context = context;
        this.contextElement = contextElement;
        var value = control.value;
        var queueObs = value ? this.taskQueueStore.getDetailFirst(value.taskQueue) : of(undefined);
        return queueObs.pipe(map(function (queue) {
            if (value) {
                var mapInputToValue = function (item) {
                    return item
                        ? {
                            path: item.path,
                            value_type: item.valueType,
                            static_value: item.staticValue,
                            context_value: item.contextValue,
                            filter_field: item.filterField,
                            filter_lookup: item.filterLookup,
                            formula_value: item.formulaValue
                        }
                        : {};
                };
                _this.form.patchValue({
                    task_queue: queue,
                    task_name: mapInputToValue(value.taskName),
                    task_inputs: value.taskInputs,
                    task_assignee: value.taskAssignee,
                    task_create_status: value.taskCreateStatus,
                    task_approve_status: value.taskApproveStatus,
                    task_reject_status: value.taskRejectStatus,
                    on_task_create_actions: value.onTaskCreateActions,
                    on_reject_actions: value.onRejectActions
                }, { emitEvent: false });
                _this.updateInputFieldProvider().subscribe();
            }
            if (!firstInit) {
                _this.form.markAsDirty();
            }
        }));
    };
    CustomizeBarApproveEditForm.prototype.updateInputFieldProvider = function () {
        var _this = this;
        return combineLatest(controlValue(this.form.controls['task_queue'])).pipe(first(), map(function (_a) {
            var queue = _a[0];
            return queue ? parametersToProviderItems(queue.parameters) : [];
        }), tap(function (items) {
            _this.inputFieldProvider.setProvider(items);
        }));
    };
    CustomizeBarApproveEditForm.prototype.controlsValid$ = function (controls) {
        var _this = this;
        return combineLatest(controls.map(function (item) { return controlValid(_this.form.controls[item]); })).pipe(map(function (result) { return result.every(function (item) { return item; }); }), debounceTime(60));
    };
    CustomizeBarApproveEditForm.prototype.onQueueChange = function () {
        var _this = this;
        setTimeout(function () {
            _this.updateInputFieldProvider().subscribe();
        }, 0);
    };
    CustomizeBarApproveEditForm.prototype.selectQueue = function (queue) {
        this.form.patchValue({
            task_queue: queue
        });
        this.form.markAsDirty();
    };
    CustomizeBarApproveEditForm.prototype.submit = function () {
        var mapValueToInput = function (item) {
            var result = new Input();
            result.path = item['path'];
            result.valueType = item['value_type'];
            result.staticValue = item['static_value'];
            result.contextValue = item['context_value'];
            result.filterField = item['filter_field'];
            result.filterLookup = item['filter_lookup'];
            result.formulaValue = item['formula_value'];
            return result;
        };
        var value = this.form.value;
        var instance = this.control.value || new Approve();
        instance.taskName = mapValueToInput(value['task_name']);
        instance.taskInputs = value['task_inputs'];
        instance.taskQueue = value['task_queue'] ? value['task_queue'].uid : undefined;
        instance.taskAssignee = value['task_assignee'] || undefined;
        instance.taskCreateStatus = value['task_create_status'] || undefined;
        instance.taskApproveStatus = value['task_approve_status'] || undefined;
        instance.taskRejectStatus = value['task_reject_status'] || undefined;
        instance.onTaskCreateActions = value['on_task_create_actions'] || [];
        instance.onRejectActions = value['on_reject_actions'] || [];
        return instance;
    };
    return CustomizeBarApproveEditForm;
}());
export { CustomizeBarApproveEditForm };
