<form [formGroup]="form">
  <div class="query-builder">
    <div class="query-builder__main">
      <!--      <div *ngIf="!('sql-charts' | appFeatureActive | async)">-->
      <!--        <div class="code-editor code-editor_flexible">-->
      <!--          <div class="code-editor__main">-->
      <!--            <app-feature-overview [background]="false" [feature]="'sql-charts'" class="query-builder__overview">-->
      <!--              <div data-title>Visualize Your SQL Queries Data with an <strong>SQL Chart</strong></div>-->
      <!--              <div data-description>-->
      <!--                <ul>-->
      <!--                  <li>Visualize your data graphically with the SQL Online Editor</li>-->
      <!--                  <li>Run more complex queries</li>-->
      <!--                  <li>Display your query results as charts in real time</li>-->
      <!--                  <li>Use any SQL syntax that suits your database type (SQLite, MySQL, Postgres, SQL Server, etc.)</li>-->
      <!--                </ul>-->
      <!--              </div>-->
      <!--            </app-feature-overview>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <ng-container *ngIf="'sql-charts' | appFeatureActive | async">-->
      <div class="query-builder__main-header">
        <app-page-block [paddingSize]="'none'" [marginSize]="'s'">
          <div *ngIf="!options.forceOperation" class="field field_manual-margin">
            <label class="field__label field__label_bright">
              <span class="icon-power field__label-required" style="margin-right: 4px; font-size: 1.2em;"></span>
              Choose operation
            </label>
            <app-select
              [control]="form.controls.operation"
              [options]="form.operationOptions"
              [placeholder]="'Choose operation'"
              [fill]="false"
            >
            </app-select>
          </div>

          <div class="field field_manual-margin">
            <label
              *ngIf="form.controls.operation.value == objectQueryOperations.Get"
              class="field__label field__label_bright"
            >
              <span class="icon-upload field__label-required" style="margin-right: 4px; font-size: 1.2em;"></span>
              Select a collection to retrieve data:
            </label>
            <label
              *ngIf="form.controls.operation.value == objectQueryOperations.Create"
              class="field__label field__label_bright"
            >
              <span class="icon-download field__label-required" style="margin-right: 4px; font-size: 1.2em;"></span>
              Choose where to create:
            </label>
            <label
              *ngIf="form.controls.operation.value == objectQueryOperations.Update"
              class="field__label field__label_bright"
            >
              <span class="icon-download field__label-required" style="margin-right: 4px; font-size: 1.2em;"></span>
              Choose parent object:
            </label>
            <label
              *ngIf="form.controls.operation.value == objectQueryOperations.Delete"
              class="field__label field__label_bright"
            >
              <span class="icon-download field__label-required" style="margin-right: 4px; font-size: 1.2em;"></span>
              Choose parent object:
            </label>
          </div>
        </app-page-block>
      </div>
      <div class="query-builder__main-top" style="padding: 30px;">
        <ng-container *ngIf="!form.controls.operation.value || objectLoading">
          <div *ngFor="let item of [1, 2, 3, 4]; let first = first; let last = last" class="structure-item">
            <div *ngIf="!first" class="structure-item__dash">
              <span class="structure-item__dash-vertical"></span>
              <span class="structure-item__dash-horizontal"></span>
            </div>
            <div class="structure-item__content">
              <div class="sidebar-list-item sidebar-list-item_compact">
                <div class="sidebar-list-item__left">
                  <span class="button button_icon button_orange button_tiny button_disabled" style="margin: 0;"> </span>
                </div>
                <div class="sidebar-list-item__main">
                  <div class="sidebar-list-item__title">
                    <span [class.loading-animation]="objectLoading">
                      <span class="stub-text">Object key</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <app-query-builder-object-structure
          *ngIf="form.controls.operation.value && !objectLoading"
          [object]="object"
          [pathControl]="form.controls.query"
        ></app-query-builder-object-structure>
      </div>
      <div class="query-builder__main-bottom">
        <div class="query-builder__toolbar query-builder__toolbar_sticky">
          <div class="query-builder__toolbar-main">
            <div class="query-builder__radio query-builder__radio_orange">
              <a
                *ngIf="form.controls.operation.value == objectQueryOperations.Get"
                href="javascript:void(0)"
                class="query-builder__radio-item"
                [class.query-builder__radio-item_active]="resultsSection == resultsSections.Result"
                (click)="setResultsSection(resultsSections.Result)"
              >
                View Data
              </a>
              <a
                *ngIf="form.controls.operation.value != objectQueryOperations.Get"
                href="javascript:void(0)"
                class="query-builder__radio-item"
                [class.query-builder__radio-item_active]="resultsSection == resultsSections.Parameters"
                (click)="setResultsSection(resultsSections.Parameters)"
              >
                Send Data
              </a>
            </div>
          </div>
          <div class="query-builder__toolbar-right">
            <div
              *ngIf="form.controls.operation.value != objectQueryOperations.Get && parametersControl"
              class="query-builder__radio query-builder__radio_orange"
            >
              <a
                href="javascript:void(0)"
                class="query-builder__radio-item"
                [class.query-builder__radio-item_active]="resultsSection == resultsSections.Result"
                (click)="setResultsSection(resultsSections.Result)"
              >
                View Data
              </a>
            </div>
            <div class="query-builder__radio query-builder__radio_orange">
              <a
                href="javascript:void(0)"
                class="query-builder__radio-item query-builder__radio-item_icon"
                [class.query-builder__radio-item_active]="resultsSection == resultsSections.Result"
                [appTip]="'Test Request'"
                [appTipOptions]="{ side: 'top' }"
                (click)="execute()"
              >
                <app-loader-small *ngIf="loading" class="query-builder__radio-item-icon"></app-loader-small>
                <span *ngIf="!loading" class="icon-play_filled query-builder__radio-item-icon"></span>
              </a>
            </div>
          </div>
        </div>
        <div *ngIf="loading" class="query-builder__table">
          <app-query-builder-data-response [animating]="true"></app-query-builder-data-response>
        </div>
        <ng-container *ngIf="!loading">
          <div *ngIf="resultsSection == resultsSections.Parameters && parametersControl">
            <!--            <div *ngIf="[objectQueryOperations.Update, objectQueryOperations.Delete].includes(form.controls.operation.value)" class="field field_manual-margin">-->
            <!--              <label class="field__label">Record Key:</label>-->
            <!--              <input class="input" type="text" formControlName="id" />-->
            <!--            </div>-->

            <app-query-builder-parameters-edit
              [control]="parametersControl"
              [queryContext]="context"
              [object]="'query_builder'"
            >
            </app-query-builder-parameters-edit>
          </div>

          <ng-container *ngIf="resultsSection == resultsSections.Result">
            <div class="query-builder__toolbar">
              <div class="query-builder__toolbar-main">
                <strong *ngIf="form.controls.query">
                  {{ form.controls.query.value[form.controls.query.value.length - 1] }}
                </strong>

                <ng-container *ngIf="response && !response_component.error">
                  &nbsp;
                  <ng-container *ngIf="response_component.columns !== undefined">
                    ({{ response_component.rows?.length || 0 }} rows,
                    {{ response_component.columns?.length || 0 }} columns)
                  </ng-container>
                  <ng-container
                    *ngIf="response_component.columns === undefined && response_component.rows !== undefined"
                  >
                    ({{ response_component.rows?.length || 0 }} items)
                  </ng-container>
                </ng-container>
                <!--                  Last updated 21.5.2020, 2:33 PM-->
              </div>
              <div class="query-builder__toolbar-right">
                <app-select
                  [control]="form.controls.object_to_array"
                  [options]="[
                    { value: false, name: 'No transformation' },
                    { value: true, name: 'Transform to Array' }
                  ]"
                  [placeholder]="'Transform'"
                  [fill]="true"
                >
                </app-select>
                <!--                <app-auto-field-->
                <!--                  [form]="form.form"-->
                <!--                  [label]="false"-->
                <!--                  [field]="-->
                <!--                    createField({-->
                <!--                      name: 'response_type',-->
                <!--                      field: 'SelectField',-->
                <!--                      params: { options: form.responseTypeOptions, classes: ['select_fill'] }-->
                <!--                    })-->
                <!--                  "-->
                <!--                ></app-auto-field>-->

                <!--                <ng-container *ngIf="form.controls.response_type.value == httpResponseTypes.JSON">-->
                <!--                  <app-query-builder-response-transform-->
                <!--                    [paths]="[{ name: 'response_path', label: 'Top Level', chooseLabel: 'Top Level' }]"-->
                <!--                    [response]="responseBody"-->
                <!--                    [pathValues]="{ response_path: form.controls.response_path.value }"-->
                <!--                    [pathEnabled]="form.controls.response_transformer_enabled.value ? undefined : 'response_path'"-->
                <!--                    (selectedPath)="onResponseTransformPathSelected($event); response_transform.close()"-->
                <!--                    (selectedJS)="onResponseTransformJsSelected(); response_transform.close()"-->
                <!--                    #response_transform-->
                <!--                  >-->
                <!--                  </app-query-builder-response-transform>-->
                <!--                </ng-container>-->
              </div>
            </div>

            <div class="query-builder__table">
              <app-query-builder-data-response
                [responseBody]="response"
                #response_component
              ></app-query-builder-data-response>
            </div>
            <!--            <ng-container *ngIf="error">-->
            <!--              <div class="data-table-tip">-->
            <!--                SQL query failed with error. Please enter correct SQL in the field above.-->
            <!--              </div>-->
            <!--              <pre class="data-table-error">-->
            <!--                  {{ error }}-->
            <!--                </pre-->
            <!--              >-->
            <!--            </ng-container>-->
          </ng-container>
        </ng-container>
      </div>
      <!--      </ng-container>-->
    </div>

    <div
      class="query-builder__submit query-builder-submit"
      [class.query-builder__submit_header-center]="options.forceOperation"
      [class.query-builder__submit_header-top]="!options.forceOperation"
      [class.query-builder__submit_floating]="false"
    >
      <div class="query-builder-submit__item">
        <button type="button" class="button button_orange" (click)="cancel()">
          <span class="button__label">Cancel</span>
        </button>
      </div>
      <div class="query-builder-submit__item">
        <button
          type="button"
          class="button button_primary button_shadow button_orange"
          [disabled]="form.invalid || form.disabled"
          cdkOverlayOrigin
          #save_trigger="cdkOverlayOrigin"
          (mouseenter)="saveHovered.next(true)"
          (mouseleave)="saveHovered.next(false)"
          (click)="submit()"
        >
          <span class="button__label">Save</span>
        </button>

        <ng-template
          cdkConnectedOverlay
          [cdkConnectedOverlayPanelClass]="['overlay_position_top']"
          [cdkConnectedOverlayPositions]="[
            {
              originX: 'center',
              originY: 'top',
              overlayX: 'center',
              overlayY: 'bottom',
              offsetX: 0,
              offsetY: -10
            }
          ]"
          [cdkConnectedOverlayOrigin]="save_trigger"
          [cdkConnectedOverlayOpen]="requireResponseMessageVisible$ | async"
          [cdkConnectedOverlayPush]="true"
          [cdkConnectedOverlayGrowAfterOpen]="true"
        >
          <div
            class="tip-overlay"
            (mouseenter)="requireResponseMessageHovered.next(true)"
            (mouseleave)="requireResponseMessageHovered.next(false)"
          >
            <div class="tip-overlay__title">Test request before save</div>
            <div class="tip-overlay__text">
              <ng-container *ngIf="requestCreate">
                Request was not sent. You need to test request response to save it.
              </ng-container>
              <ng-container *ngIf="!requestCreate">
                Request was changed. You need to test updated request response to save it.
              </ng-container>
            </div>
            <div class="tip-overlay__buttons">
              <ng-container *ngIf="isResponseMissing()">
                <a
                  href="javascript:void(0)"
                  class="tip-overlay__button tip-overlay-button"
                  (click)="setResultsSection(resultsSections.Result); executeDisplay()"
                >
                  <app-loader-small *ngIf="loading" class="tip-overlay-button__icon"></app-loader-small>
                  <span *ngIf="!loading" class="tip-overlay-button__icon icon-play_filled"></span>
                  Test Request
                </a>
                <a
                  href="javascript:void(0)"
                  class="tip-overlay__button tip-overlay-button"
                  (click)="requireResponseMessageHovered.next(false); saveProcess()"
                  >Save anyway</a
                >
              </ng-container>
              <ng-container *ngIf="!isResponseMissing()">
                <a
                  href="javascript:void(0)"
                  class="tip-overlay__button tip-overlay-button"
                  (click)="setResultsSection(resultsSections.Result); executeDisplay()"
                >
                  <app-loader-small *ngIf="loading" class="tip-overlay-button__icon"></app-loader-small>
                  <span *ngIf="!loading" class="tip-overlay-button__icon icon-play_filled"></span>
                  Test Request
                </a>
                <a
                  href="javascript:void(0)"
                  class="tip-overlay__button tip-overlay-button tip-overlay-button_primary"
                  (click)="requireResponseMessageHovered.next(false); saveProcess()"
                  >Finish</a
                >
              </ng-container>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</form>
