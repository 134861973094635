import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { DialogService } from '@common/dialogs';
import { ExportDataType } from '@modules/actions';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { Option } from '@modules/field-components';
import { createFormFieldFactory, FieldType } from '@modules/fields';
import { ModelDescription } from '@modules/models';
import { QueryType } from '@modules/queries';
import { HttpResultsSection, SqlResultsSection } from '@modules/queries-components';
import { controlValue } from '@shared';

import { DisplayFieldsEditComponent } from '../../../display-fields-edit/display-fields-edit.component';
import { ListModelDescriptionDataSourceControl } from '../../../model-description-data-source-edit/list-model-description-data-source';
import { ModelDescriptionDataSourceControl } from '../../../model-description-data-source-edit/model-description-data-source';
import { ModelDescriptionDataSourceEditComponent } from '../../../model-description-data-source-edit/model-description-data-source-edit.component';
import { CustomizeBarActionEditForm } from '../../customize-bar-action-edit.form';

@Component({
  selector: 'app-customize-bar-action-edit-type-export',
  templateUrl: './customize-bar-action-edit-type-export.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeBarActionEditTypeExportComponent implements OnInit, OnDestroy {
  @Input() form: CustomizeBarActionEditForm;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() contextElementPath: (string | number)[];
  @Input() contextElementPaths: (string | number)[][];
  @Input() analyticsSource: string;

  @ViewChild(ModelDescriptionDataSourceEditComponent) dataSourceEditComponent: ModelDescriptionDataSourceEditComponent;
  @ViewChild('fieldsComponent', { read: DisplayFieldsEditComponent }) columnsEditComponent: DisplayFieldsEditComponent;

  createField = createFormFieldFactory();
  columnsVisible$: Observable<boolean>;
  sortableColumnOptions$: Observable<Option<string>[]>;
  modelDescription: ModelDescription;
  exportDataTypes = ExportDataType;
  fieldTypes = FieldType;
  queryTypes = QueryType;

  constructor(private dialogService: DialogService, private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.columnsVisible$ = combineLatest(
      controlValue<ExportDataType>(this.form.controls.export_data_type),
      this.form.controls.export_data_source.getQueryConfigured$()
    ).pipe(
      map(([exportDataType, queryConfigured]) => {
        if (exportDataType == ExportDataType.DataSource) {
          return queryConfigured;
        } else if (exportDataType == ExportDataType.CurrentComponent) {
          return true;
        } else {
          return false;
        }
      })
    );

    this.sortableColumnOptions$ = controlValue<ExportDataType>(this.form.controls.export_data_type).pipe(
      switchMap(exportDataType => {
        if (
          exportDataType == ExportDataType.CurrentComponent &&
          this.form.options.dataSourceControl instanceof ListModelDescriptionDataSourceControl
        ) {
          return this.form.options.dataSourceControl.getSortableColumnOptions$();
        } else if (exportDataType == ExportDataType.DataSource) {
          return this.form.controls.export_data_source.getSortableColumnOptions$();
        } else {
          return of([]);
        }
      })
    );

    controlValue<ExportDataType>(this.form.controls.export_data_type)
      .pipe(
        switchMap(exportDataType => {
          if (
            exportDataType == ExportDataType.CurrentComponent &&
            this.form.options.dataSourceControl instanceof ModelDescriptionDataSourceControl
          ) {
            return this.form.options.dataSourceControl.getModelDescription$();
          } else if (exportDataType == ExportDataType.DataSource) {
            return this.form.controls.export_data_source.getModelDescription$();
          } else {
            return of(undefined);
          }
        }),
        untilDestroyed(this)
      )
      .subscribe(modelDescription => {
        this.modelDescription = modelDescription;
        this.cd.markForCheck();
      });
  }

  ngOnDestroy(): void {}

  setExportDataType(type: ExportDataType) {
    if (this.form.controls.export_data_type.value == type) {
      return;
    }

    this.form.controls.export_data_type.setValue(type);
    this.resetColumnsProcess();
  }

  resetColumns() {
    this.dialogService
      .warning({
        title: `Are you sure want to reset export fields?`,
        description: `
          The export fields will be reset to their default values.
        `,
        style: 'orange'
      })
      .pipe(
        filter(result => !!result),
        untilDestroyed(this)
      )
      .subscribe(() => this.resetColumnsProcess());
  }

  resetColumnsProcess() {
    this.form.resetExportColumns({
      context: this.context,
      contextElement: this.contextElement,
      markAsDirty: true
    });
  }

  addQueryInput() {
    if (this.dataSourceEditComponent) {
      this.dataSourceEditComponent.editQuery({
        initialHttpResultsSection: HttpResultsSection.Parameters,
        initialSqlResultsSection: SqlResultsSection.Parameters
      });
    }
  }
}
