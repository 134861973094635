<div class="custom-page-popup__header">
  <div class="custom-page-popup__header-main">
    <div class="custom-page-popup__title">
      Version history

      <div
        *ngIf="currentEnvironmentStore.instance$ | async as environment"
        class="custom-page-popup__title-tag"
        [ngClass]="['color_' + environment.color + '_1', 'background-color_' + environment.color + '_2']"
      >
        {{ environment.name }}
      </div>
    </div>
  </div>
  <div class="custom-page-popup__header-right">
    <div class="custom-page-popup__close icon-close" (click)="close()"></div>
  </div>
</div>

<ng-template let-size="size" #table_rows_stub_template>
  <tr *ngFor="let item of [1, 2, 3, 4, 5, 6, 7, 8].slice(0, size || 8)" class="card-table__row">
    <td class="card-table__column">
      <div class="card-table__column-inner">
        <div class="timeline2__dot"></div>

        <div class="card-table__small card-table__column-item">
          <span class="stub-text">
            <span [class.loading-animation]="true">current date</span>
          </span>
        </div>

        <div class="card-table__tag card-table__column-item">
          <span class="card-table__tag-label">
            <span class="stub-text">
              <span [class.loading-animation]="true">xxxxx</span>
            </span>
          </span>
        </div>
      </div>
    </td>

    <td class="card-table__column">
      <div class="card-table__column-inner"></div>
    </td>
  </tr>
</ng-template>

<ng-template #table_stub_template>
  <div class="timeline2">
    <div class="timeline2__left">
      <div class="timeline2__line"></div>
    </div>

    <div class="timeline2__main">
      <table class="card-table card-table_compact">
        <tbody>
          <ng-container *ngTemplateOutlet="table_rows_stub_template"></ng-container>
        </tbody>
      </table>
    </div>
  </div>
</ng-template>

<app-page-block *ngIf="loading" [paddingSize]="'s'">
  <ng-container *ngTemplateOutlet="table_stub_template"></ng-container>
</app-page-block>

<app-page-block *ngIf="!loading && error" [paddingSize]="'s'">
  <div class="table__empty">
    <app-error [title]="'Loading failed' | localize">
      {{ error }}
    </app-error>
  </div>
</app-page-block>

<!--<div *ngIf="!loading && !error && !items?.length" class="table__empty">-->
<!--  <app-error [title]="'Nothing found' | localize">-->
<!--    Unfortunately, no Snapshots yet-->
<!--  </app-error>-->
<!--</div>-->

<ng-container *ngIf="!loading && !error">
  <app-page-block [paddingSize]="'s'">
    <div class="timeline2">
      <div class="timeline2__left">
        <div class="timeline2__line"></div>
      </div>

      <div class="timeline2__main">
        <table class="card-table card-table_compact">
          <tbody>
            <tr
              *ngIf="createAllowed"
              class="card-table__row card-table__row_contrast card-table__row_placeholder card-table__row_margin_l"
            >
              <td class="card-table__column">
                <div class="card-table__column-inner">
                  <div class="timeline2__dot timeline2__dot_active timeline2__dot_icon icon-plus"></div>

                  <span class="card-table__strong card-table__column-item" style="margin-left: 10px;"
                    >Current version</span
                  >

                  <div
                    class="card-table__tag card-table__tag_input card-table__tag_interactive card-table__column-item"
                    [class.card-table__tag_active]="editable_content.editing"
                    [appTip]="!(createName | appIsSet) && !editable_content.editing ? 'Add version name' : null"
                    [appTipOptions]="{ side: 'top' }"
                    (click)="editable_content.startEditing()"
                  >
                    <span class="card-table__tag-label">
                      <app-editable-content
                        [value]="createName"
                        [emptyValue]="'Auto-name'"
                        [placeholder]="'v1.0.0'"
                        [inline]="true"
                        [multiline]="false"
                        [activeTextColor]="false"
                        [outline]="false"
                        [cursorPointer]="true"
                        (valueChange)="setCreateName($event)"
                        (click)="$event.stopPropagation()"
                        #editable_content
                      >
                      </app-editable-content>
                    </span>
                    <span class="card-table__tag-icon card-table__tag-icon_right icon-pen"></span>
                  </div>
                </div>
              </td>

              <td class="card-table__column">
                <div class="card-table__column-inner card-table__column-inner_right">
                  <button
                    type="button"
                    class="button button_primary button_small button_orange"
                    [disabled]="createLoading"
                    (click)="createSnapshot()"
                  >
                    <app-loader-small *ngIf="createLoading" class="button__icon button__icon_left"></app-loader-small>
                    <span *ngIf="!createLoading" class="button__icon button__icon_left icon-camera"></span>
                    <span class="button__label">Create snapshot</span>
                  </button>
                </div>
              </td>
            </tr>

            <tr
              *ngFor="let item of items"
              app-snapshot-popup-item
              [item]="item"
              (updated)="onItemUpdated(item, $event)"
              (applied)="onItemApplied()"
              class="card-table__row"
            ></tr>
          </tbody>
        </table>
      </div>
    </div>
  </app-page-block>

  <app-page-block [paddingSize]="'s'">
    <app-list-footer
      class="card-table-footer"
      [title]="'snapshots'"
      [currentPage]="snapshotStore.currentPage$ | async"
      [hasMorePages]="snapshotStore.hasMore$ | async"
      [totalPages]="snapshotStore.totalPages$ | async"
      (pageSelected)="loadPage($event)"
      (reload)="loadPage(snapshotStore.currentPage)"
    >
    </app-list-footer>
  </app-page-block>
</ng-container>
