import { Injectable, Injector } from '@angular/core';
import keys from 'lodash/keys';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { ModelDescriptionDataSourceService } from '@modules/data-sources-queries';
import {
  applyParamInput,
  applyParamInput$,
  ComputedDisplayField,
  DisplayField,
  DisplayFieldType,
  FieldType,
  getFieldDescriptionByType,
  Input,
  transformFieldForeignKey
} from '@modules/fields';
import { MessageService } from '@modules/messages';
import { ModelDescriptionStore, ModelService, ModelUtilsService, ReducedModelService } from '@modules/model-queries';
import { getDefaultValue, Model, ModelDescription, NO_PAGINATION_PARAM } from '@modules/models';
import { ModelListStore } from '@modules/models-list';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { QueryService } from '@modules/queries';
import { ResourceControllerService } from '@modules/resources';
import { EMPTY, isSet, ListStoreFetchResponse } from '@shared';

import { ListItem } from '../data/list-item';
import { ListItemColumn } from '../data/list-item-column';
import { ITEM_OUTPUT } from '../data/outputs';

@Injectable()
export class ColumnsModelListStore extends ModelListStore<ListItem> {
  ignoreErrors = true;

  constructor(
    private modelUtilsService: ModelUtilsService,
    private reducedModelService: ReducedModelService,
    private queryService: QueryService,
    private messageService: MessageService,
    private injector: Injector,
    service: ModelService,
    currentProjectStore: CurrentProjectStore,
    currentEnvironmentStore: CurrentEnvironmentStore,
    modelDescriptionStore: ModelDescriptionStore,
    modelDescriptionDataSourceService: ModelDescriptionDataSourceService,
    resourceControllerService: ResourceControllerService
  ) {
    super(
      service,
      currentProjectStore,
      currentEnvironmentStore,
      modelDescriptionStore,
      modelDescriptionDataSourceService,
      resourceControllerService
    );
  }

  deserializeModelAttributes() {
    if (!this.items) {
      return;
    }

    this.items = this.items.map((item: ListItem) => {
      item.model.deserializeAttributes(
        this.dataSource.columns.filter(column => column.type != DisplayFieldType.Computed)
      );
      this.dataSource.columns
        .filter(column => column instanceof ComputedDisplayField)
        .forEach((column: ComputedDisplayField) => {
          const value = this.resolveFlexItemValue(column, item.model);
          item.model.setAttribute(column.name, value);
        });

      return item;
    });
  }

  fetchPage(page: number, next: boolean): Observable<ListStoreFetchResponse<ListItem>> {
    const cache = {};

    return this.fetchModelPage(page, next).pipe(
      switchMap<ListStoreFetchResponse<Model>, ListStoreFetchResponse<ListItem>>(response => {
        if (!response || !response.items.length) {
          return of(response);
        }

        return combineLatest(
          ...response.items.map(item => {
            return this.resolveItemColumns(item, cache).pipe(
              map(columns => {
                return {
                  columns: columns,
                  model: item
                };
              })
            );
          })
        ).pipe(
          map(items => {
            const newResponse = response as ListStoreFetchResponse<any>;
            newResponse.items = items;
            return newResponse;
          })
        );
      })
    );
  }

  resolveItemColumns(model: Model, cache: Object = {}): Observable<ListItemColumn[]> {
    let columns = this.dataSource.columns;

    if (!columns || !columns.length) {
      columns = keys(model.getAttributes()).map(item => {
        const result = new DisplayField({
          name: item,
          visible: true,
          params: {}
        });
        result.updateFieldDescription();
        return result;
      });
    }

    if (!columns.length) {
      return of([]);
    }

    return combineLatest(
      ...columns.map(item => {
        // if (!item.visible) {
        //   return of({
        //     column: item.name,
        //     value: undefined
        //   });
        // }

        const result = this.resolveItemColumn(model, item, cache);

        if (result instanceof Observable) {
          if (this.ignoreErrors) {
            return result.pipe(
              catchError(e => {
                console.log(e);
                return of({
                  column: item.name,
                  value: undefined
                });
              })
            ) as Observable<ListItemColumn>;
          } else {
            return result;
          }
        } else {
          return of(result);
        }
      })
    );
  }

  // cacheColumnParam$(cache: Object, column: RawListViewSettingsColumn, name: string, obs: Observable<any>): Observable<any> {
  //   const key = [column.name, name].join('_');
  //
  //   if (!cache[key]) {
  //     cache[key] = obs.pipe(share());
  //   }
  //
  //   return cache[key];
  // }

  resolveItemColumn(model: Model, column: DisplayField, cache: Object): Observable<ListItemColumn> | ListItemColumn {
    if (column instanceof ComputedDisplayField) {
      const value = this.resolveFlexItemValue(column, model);
      model.setAttribute(column.name, value);
      return this.getItemColumn(model, value, column, cache);
    } else {
      return this.getItemColumn(model, model.getAttribute(column.name), column, cache);
    }
  }

  resolveFlexItemValue(column: ComputedDisplayField, model: Model) {
    if (column.valueInput) {
      try {
        const value = applyParamInput(column.valueInput, {
          context: this.context,
          contextElement: this.contextElement,
          localContext: {
            [ITEM_OUTPUT]: model.getAttributes()
          }
          // field: { field: column.field, params: column.params }
        });

        if (value !== EMPTY) {
          return value;
        }
      } catch (e) {}
    }

    return getDefaultValue(column);
  }

  createModel(): Model {
    return Injector.create({
      providers: [{ provide: Model, deps: [Injector] }],
      parent: this.injector
    }).get<Model>(Model);
  }

  getItemColumn(
    model: Model,
    value: any,
    column: DisplayField,
    cache: Object
  ): Observable<ListItemColumn> | ListItemColumn {
    //   const field = this.modelDescription.field(column.name);
    //
    //   if (field.type == ModelFieldType.Db) {
    const fieldDescription = getFieldDescriptionByType(column.field);
    let str: string | Observable<string>;
    const fieldRelatedModel =
      column.params && column.params['related_model']
        ? new ModelDescription().deserialize(column.params['related_model'])
        : undefined;

    if (column.field == FieldType.RelatedModel && fieldRelatedModel && isSet(value)) {
      const resource = this.currentEnvironmentStore.resources.find(
        item => item.uniqueName == this.dataSource.queryResource
      );
      const controller = resource ? this.resourceControllerService.get(resource.type) : undefined;
      const setUp$ = controller && controller.checkApiInfo ? controller.checkApiInfo(resource) : of(undefined);

      return setUp$.pipe(
        switchMap(() => this.modelDescriptionStore.getDetailFirst(fieldRelatedModel.model)),
        switchMap(relatedModelDescription => {
          if (!relatedModelDescription) {
            return of({
              column: column.name
            });
          }

          const idField = column.params['custom_primary_key'] || relatedModelDescription.primaryKeyField;
          const nameField = column.params['custom_display_field'] || relatedModelDescription.displayField;

          let relatedValues: Object;

          if (column.params['custom_display_field_input']) {
            relatedValues = relatedModelDescription.dbFields.reduce<Object>((acc, field) => {
              const relatedValue = model.getRelationValue({ path: [column.name], field: field.name });
              if (relatedValue !== EMPTY) {
                acc = acc || {};
                acc[field.name] = model.getRelationValue({ path: [column.name], field: field.name });
              }
              return acc;
            }, undefined);
          } else if (isSet(nameField)) {
            const relatedValue = model.getRelationValue({ path: [column.name], field: nameField });
            if (relatedValue !== EMPTY) {
              relatedValues = { [nameField]: relatedValue };
            }
          } else {
            return of({
              column: column.name
            });
          }

          let obs$: Observable<Model>;

          if (relatedValues) {
            const relatedModel = this.createModel();

            relatedModel.deserialize(relatedModelDescription.model, { ...relatedValues, [idField]: value });
            relatedModel.setUp(relatedModelDescription);
            relatedModel.deserializeAttributes(relatedModelDescription.dbFields);

            obs$ = of(relatedModel);
          } else {
            let commonParams: Object;
            const apiInfo = resource ? resource.apiInfo : undefined;
            const idValue = transformFieldForeignKey(column.params, value);

            if (apiInfo && apiInfo.isCompatibleJetBridge({ jetBridge: '0.5.9', jetDjango: '0.8.4' })) {
              commonParams = { [NO_PAGINATION_PARAM]: 1 };
            }

            obs$ = this.reducedModelService.getDetail(fieldRelatedModel.model, idField, idValue, {}, commonParams);
          }

          return obs$.pipe(
            map(relatedModel => {
              if (!fieldDescription.valueToStrTableIgnore && relatedModel) {
                const nameInput = column.params['custom_display_field_input']
                  ? new Input().deserialize(column.params['custom_display_field_input'])
                  : undefined;

                if (nameInput) {
                  str = applyParamInput$(nameInput, {
                    localContext: {
                      item: relatedModel.getAttributes()
                    },
                    defaultValue: null
                  });
                } else if (isSet(nameField)) {
                  str = of(
                    relatedModel.hasAttribute(nameField)
                      ? relatedModel.getAttribute(nameField)
                      : relatedModel.getRawAttribute(nameField)
                  );
                } else if (relatedModelDescription) {
                  str = this.modelUtilsService.str(relatedModel);
                } else {
                  str = of(undefined);
                }
              }

              return {
                column: column.name,
                // value: this.deserializeItemColumn(column, value),
                // formField: field.formField,
                // context: {
                //   model: model,
                //   modelDescription: model.modelDescription,
                //   related_model: relatedModel
                // },
                relatedModel: relatedModel,
                relatedModelDescription: relatedModelDescription,
                str: str
              };
            })
          );
        })
      );
    } else {
      return {
        column: column.name
        // value: this.deserializeItemColumn(column, value)
      };
    }
    //     } else if (field.item.field == 'SelectField' && field.item.params['options_type'] == OptionsType.MessagesAPI) {
    //       const params = {
    //         field: field.name,
    //         model: model.model
    //       };
    //
    //       return this.cacheColumnParam$(
    //         cache,
    //         column,
    //         'messages_api_options',
    //         this.messageService.send(undefined, MessageName.GetFieldOptions, params)
    //           .pipe(map(result => {
    //             if (!result.json || !isArray(result.json)) {
    //               return;
    //             }
    //
    //             return result.json.map(item => {
    //               return {
    //                 name: item['name'],
    //                 value: this.deserializeItemColumn(column, item['value']),
    //                 color: item['color']
    //               };
    //             });
    //           }))
    //       ).pipe(map(options => {
    //         return {
    //           link: model.getLink(),
    //           value: value: this.deserializeItemColumn(column, model.getAttribute(field.name)),
    //           formField: field.formField,
    //           context: {
    //             model: model,
    //             modelDescription: model.modelDescription,
    //             messages_api_options: options
    //           },
    //           str: str
    //         };
    //       }));
    //     } else {
    //       if (fieldDescription.valueToStr && !fieldDescription.valueToStrTableIgnore) {
    //         const value = fieldDescription.valueToStr(model.getAttribute(field.name), { field: field.formField });
    //
    //         if (value !== undefined) {
    //           str = value;
    //         }
    //       }
    //
    //       return {
    //         link: model.getLink(),
    //         value: value: this.deserializeItemColumn(column, model.getAttribute(field.name)),
    //         formField: field.formField,
    //         context: {
    //           model: model,
    //           modelDescription: model.modelDescription
    //         },
    //         str: str
    //       };
    //     }
    //   } else if (field.type == ModelFieldType.Flex) {
    //     return this.flexFieldService.resolveCurrentField(this.modelDescription, model, field).pipe(
    //       map(value => {
    //         return {
    //           link: model.getLink(),
    //           value: this.deserializeItemColumn(column, value),
    //           formField: field.item.formField,
    //           context: {
    //             model: model,
    //             modelDescription: model.modelDescription
    //           }
    //         };
    //       }),
    //     );
    //   }
  }
}
