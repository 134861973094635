import { Injectable, Injector } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { combineLatest, Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { FormUtils } from '@common/form-utils';
import { MenuSettings } from '@modules/menu';
import { MenuCustomizeService, MenuSettingsChanges } from '@modules/menu-utils';

import { MenuBlockArray } from './menu-block.array';

@Injectable()
export class MenuUpdateForm extends FormGroup {
  menuSettings: MenuSettings;

  controls: {
    blocks: MenuBlockArray;
  };

  constructor(
    private formUtils: FormUtils,
    private menuCustomizeService: MenuCustomizeService,
    private injector: Injector
  ) {
    super({
      blocks: MenuBlockArray.inject(injector, [])
    });
  }

  static inject(injector: Injector): MenuUpdateForm {
    return Injector.create({
      providers: [
        {
          provide: MenuUpdateForm,
          useFactory: (formUtils: FormUtils, menuCustomizeService: MenuCustomizeService) => {
            return new MenuUpdateForm(formUtils, menuCustomizeService, injector);
          },
          deps: [FormUtils, MenuCustomizeService]
        }
      ],
      parent: injector
    }).get(MenuUpdateForm);
  }

  init(menuSettings: MenuSettings) {
    this.menuSettings = menuSettings;

    this.controls.blocks.deserialize(menuSettings.blocks);

    this.markAsPristine();
  }

  getInstance(): MenuSettings {
    const instance = this.menuSettings ? this.menuSettings.clone() : new MenuSettings();

    instance.blocks = this.controls.blocks.serialize();

    return instance;
  }

  getChanges(
    lhs: MenuSettings,
    rhs: MenuSettings
  ): Observable<{
    menuChanged: boolean;
    menuChanges: MenuSettingsChanges;
  }> {
    const menuChanges$ = this.menuCustomizeService.getChanges(lhs, rhs);

    return combineLatest(menuChanges$).pipe(
      map(([menuChanges]) => {
        const menuChanged = [
          menuChanges.menuSettings,
          menuChanges.changedModels,
          menuChanges.changedDashboards,
          menuChanges.deletedDashboards,
          menuChanges.changedPages
        ].some(item => item.hasChanges);

        return {
          menuChanged: menuChanged,
          menuChanges: menuChanges
        };
      })
    );
  }

  submit(): Observable<MenuSettings> {
    const instance = this.getInstance();

    return this.getChanges(this.menuSettings, instance).pipe(
      switchMap(changes => {
        return changes.menuChanged ? this.menuCustomizeService.saveChanges(changes.menuChanges) : of({});
      }),
      map(() => instance),
      tap(result => (this.menuSettings = result)),
      catchError(error => {
        this.formUtils.showFormErrors(this, error);
        return throwError(error);
      })
    );
  }
}
