import { Overlay } from '@angular/cdk/overlay';
import { CdkPortalOutlet } from '@angular/cdk/portal';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import * as Color from 'color';
import { extent, least, max, min } from 'd3-array';
import { axisBottom, axisLeft } from 'd3-axis';
import { ScaleLinear, scaleLinear, ScaleTime, scaleTime } from 'd3-scale';
import { pointer, select, Selection } from 'd3-selection';
import fromPairs from 'lodash/fromPairs';
import keys from 'lodash/keys';
import * as moment from 'moment';
import { untilDestroyed } from 'ngx-take-until-destroy';
import * as numeral from 'numeral';
import { BehaviorSubject, combineLatest, fromEvent, merge } from 'rxjs';
import { map, skip } from 'rxjs/operators';

import {
  applyDatasetsDefaultColors,
  CHART_COLORS,
  DataGroup,
  Dataset,
  datasetGroupDateLookups,
  DatasetGroupLookup,
  DataTotalItem,
  getDatasetsGroupLookup,
  getDatasetsGroups,
  getDatasetsUniqueGroups,
  getDateFormatByLookup,
  prepareDataset
} from '@modules/charts';
import { elementResize$, elementSize$, generateAlphanumeric, isSet, TypedChanges } from '@shared';

// TODO: Refactor import
import { getColorHex, getColorHexStr, parseColor } from '../../../colors/utils/colors';

import { DataClickEvent } from '../../data/events';
import { DataTooltipController } from '../../services/data-tooltip-controller/data-tooltip.controller';
import { fitXAxisLabelWithVisibility, getYAxisWidth } from '../../utils/d3';

@Component({
  selector: 'app-scatter-chart2',
  templateUrl: './scatter-chart2.component.html',
  providers: [DataTooltipController],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScatterChart2Component implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() datasets: Dataset[] = [];
  @Input() bubbles = false;
  @Input() yFormat: string;
  @Input() min: number;
  @Input() max: number;
  @Input() defaultColors = CHART_COLORS;
  @Input() animate = true;
  @Input() xAxisVisible = true;
  @Input() yAxisVisible = true;
  @Input() legend = true;
  @Input() interactive = true;
  @Input() tooltips = true;
  @Input() dataClickEnabled = false;
  @Input() theme = false;
  @Output() dataClick = new EventEmitter<DataClickEvent>();

  @ViewChild('canvas') canvasElement: ElementRef;
  @ViewChild('svg') svgElement: ElementRef;
  @ViewChild('tooltip_container') tooltipContainerElement: ElementRef;
  @ViewChild(CdkPortalOutlet) portalOutlet: CdkPortalOutlet;

  data: Dataset<number, number | moment.Moment>[] = [];
  dataGroups: (number | moment.Moment)[] = [];
  dataGroupLookup?: DatasetGroupLookup;
  dataTotal: DataTotalItem[] = [];
  margin = { top: 8, right: 8, bottom: 8, left: 8 };
  width: number;
  height: number;
  svg: Selection<SVGGElement, {}, any, {}>;
  xScaleTime: ScaleTime<number, number>;
  xScaleLinear: ScaleLinear<number, number>;
  xAxis: Selection<SVGGElement, {}, any, {}>;
  yScale: ScaleLinear<number, number>;
  yAxis: Selection<SVGGElement, {}, any, {}>;
  interactiveRect: Selection<SVGRectElement, {}, any, {}>;
  circleHover: Selection<SVGCircleElement, {}, any, {}>;
  hoverDatasetGroup$ = new BehaviorSubject<{ datasetIndex: number; groupIndex: number }>(undefined);
  hoverLegendDatasetIndex$ = new BehaviorSubject<number>(undefined);
  selectedDatasetIndexes: { [k: number]: boolean };
  uid = generateAlphanumeric(8);

  get xScale(): ScaleTime<number, number> | ScaleLinear<number, number> {
    return this.xScaleTime || this.xScaleLinear;
  }

  getId(name: string): string {
    return `${name}-${this.uid}`;
  }

  constructor(
    private el: ElementRef,
    private overlay: Overlay,
    private dataTooltip: DataTooltipController,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<ScatterChart2Component>): void {
    if (changes.datasets) {
      this.data = this.datasets
        .map(dataset => prepareDataset(dataset))
        .map(dataset => {
          return {
            ...dataset,
            dataset: dataset.dataset.reduce((acc, item) => {
              if (this.bubbles) {
                const groupNumber = parseFloat(item.group as string);
                const group2Number = parseFloat(item.group2);

                if (!isNaN(groupNumber) && !isNaN(group2Number)) {
                  const result = new DataGroup();

                  result.group = groupNumber;
                  result.group2 = group2Number;
                  result.value = item.value;

                  acc.push(result);
                }
              } else {
                const groupNumber = parseFloat(item.group as string);

                if (!isNaN(groupNumber)) {
                  const result = new DataGroup();

                  result.group = groupNumber;
                  result.group2 = item.group2;
                  result.value = item.value;

                  acc.push(result);
                }
              }
              return acc;
            }, [])
          };
        });
      this.dataGroupLookup = getDatasetsGroupLookup(this.data);
      this.dataGroups = getDatasetsUniqueGroups(this.data);

      applyDatasetsDefaultColors(this.data, this.defaultColors);

      this.dataTotal = getDatasetsGroups(this.data);
    }

    if (this.svg) {
      this.rerender();
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.init();

      elementSize$(this.canvasElement.nativeElement)
        .pipe(skip(1), untilDestroyed(this))
        .subscribe(() => this.onResize());
    }, 0);
  }

  init() {
    this.initBounds();
    this.initSvg();
    this.initYAxis();
    this.renderYAxis();
    this.fitYAxis();
    this.initXAxis();
    this.renderXAxis();
    this.renderScatter();
    this.renderGradients();
    this.initDatasetHover();
    this.initInteractive();
    this.renderInteractive();
    this.initEvents();
  }

  initBounds() {
    const width = this.canvasElement.nativeElement.offsetWidth;
    const height = this.canvasElement.nativeElement.offsetHeight;

    this.margin = { top: 8, right: 8, bottom: this.xAxisVisible ? 22 : 8, left: this.yAxisVisible ? 40 : 8 };
    this.width = width - this.margin.left - this.margin.right;
    this.height = height - this.margin.top - this.margin.bottom;
  }

  initSvg() {
    this.svg = select(this.svgElement.nativeElement)
      .attr('width', '100%')
      .attr('height', '100%')
      .append('g')
      .attr('transform', `translate(${this.margin.left},${this.margin.top})`);
  }

  initXAxis() {
    this.xAxis = this.svg.append('g').attr('class', 'chart-axis chart-axis_x');
  }

  renderXAxis() {
    if (datasetGroupDateLookups.includes(this.dataGroupLookup)) {
      this.xScaleTime = scaleTime()
        .domain(extent(this.dataGroups, d => (d as moment.Moment).valueOf()))
        .range([0, this.width]);
    } else {
      this.xScaleLinear = scaleLinear()
        .domain(extent(this.dataGroups, d => d as number))
        .range([0, this.width]);
    }

    if (!this.xAxisVisible) {
      this.xAxis.selectChildren().remove();
      return;
    }

    const ticks = Math.floor(this.width / 80);
    const axisGenerator = axisBottom(this.xScale).ticks(ticks).tickSize(-this.height).tickPadding(10);

    const axis = this.xAxis.attr('transform', `translate(0,${this.height})`).call(axisGenerator);

    fitXAxisLabelWithVisibility(axis, this.el.nativeElement);

    this.setAxisClasses(axis);
  }

  initYAxis() {
    this.yAxis = this.svg.append('g').attr('class', 'chart-axis chart-axis_y');
  }

  renderYAxis() {
    const domain = this.bubbles
      ? extent(this.dataTotal, d => d.item.group2)
      : extent(this.dataTotal, d => d.item.value);
    const domainExpand = 0.05 * Math.abs(domain[1] - domain[0]);

    if (domain[0] !== 0) {
      domain[0] -= domainExpand;
    }

    domain[1] += domainExpand;

    if (isSet(this.min)) {
      domain[0] = this.min;
    }

    if (isSet(this.max)) {
      domain[1] = this.max;
    }

    this.yScale = scaleLinear().domain(domain).range([this.height, 0]);

    if (!this.yAxisVisible) {
      this.yAxis.selectChildren().remove();
      return;
    }

    const ticks = Math.floor(this.height / 50);
    const axisGenerator = axisLeft(this.yScale)
      .ticks(ticks)
      .tickSize(-this.width)
      .tickPadding(10)
      .tickFormat(value => {
        if (!isSet(this.yFormat)) {
          return value;
        }

        return numeral(value).format(this.yFormat);
      });

    const axis = this.yAxis.call(axisGenerator);

    this.setAxisClasses(axis);
  }

  setAxisClasses(axis: Selection<SVGElement, any, any, any>) {
    axis.selectAll('.domain').attr('class', 'chart-axis-domain domain');
    axis.selectAll('.tick').attr('class', 'chart-axis-tick-group tick');
    axis.selectAll('.chart-axis-tick-group line').attr('class', 'chart-axis-tick');
    axis.selectAll('.chart-axis-tick-group text').attr('class', 'chart-axis-label');
  }

  fitYAxis() {
    const width = this.yAxis ? getYAxisWidth(this.yAxis) : 0;

    this.margin.left = width + 10;
    this.width = this.canvasElement.nativeElement.offsetWidth - this.margin.left - this.margin.right;
    this.svg.attr('transform', `translate(${this.margin.left},${this.margin.top})`);
  }

  renderScatter() {
    if (this.bubbles) {
      const valueNumbers = this.dataTotal.map(item => item.item.value);
      const radiusMin = 4;
      const radiusMax = 15;
      const radiusAmp = radiusMax - radiusMin;
      const valuesMin = valueNumbers.length ? Math.min(...valueNumbers) : 0;
      const valuesMax = valueNumbers.length ? Math.max(...valueNumbers) : radiusMax;
      const valuesAmp = valuesMax - valuesMin;

      this.svg
        .selectAll('.chart-circles')
        .data(this.data)
        .join('g')
        .attr('class', (d, i) => `chart-circles chart-circles_index-${i}`)
        .selectAll('.chart-circle')
        .data((d, datasetIndex) => {
          return d.dataset.map(group => {
            let x: number;
            const y = this.yScale(group.group2);
            const r = valuesAmp != 0 ? radiusMin + ((group.value - valuesMin) / valuesAmp) * radiusAmp : radiusMin;

            if (this.xScaleTime) {
              x = this.xScaleTime(group.group as moment.Moment);
            } else if (this.xScaleLinear) {
              x = this.xScaleLinear(group.group as number);
            }

            return {
              x: x,
              y: y,
              r: r,
              datasetIndex: datasetIndex
            };
          });
        })
        .join('circle')
        .attr('class', (d, i) => `chart-circle chart-circle_index-${d.datasetIndex}-${i}`)
        .attr('fill', d => {
          const lineGradient = this.getId(`line-gradient-${d.datasetIndex}`);
          return `url(#${lineGradient})`;
        })
        .attr('cx', d => d.x)
        .attr('cy', d => d.y)
        .attr('r', d => d.r);
    } else {
      this.svg
        .selectAll('.chart-circles')
        .data(this.data)
        .join('g')
        .attr('class', (d, i) => `chart-circles chart-circles_index-${i}`)
        .selectAll('.chart-circle')
        .data((d, datasetIndex) => {
          return d.dataset.map(group => {
            let x: number;

            if (this.xScaleTime) {
              x = this.xScaleTime(group.group as moment.Moment);
            } else if (this.xScaleLinear) {
              x = this.xScaleLinear(group.group as number);
            }

            return {
              x: x,
              y: this.yScale(group.value),
              datasetIndex: datasetIndex
            };
          });
        })
        .join('circle')
        .attr('class', (d, i) => `chart-circle chart-circle_fixed chart-circle_index-${d.datasetIndex}-${i}`)
        .attr('r', 5)
        .attr('fill', d => {
          const lineGradient = this.getId(`line-gradient-${d.datasetIndex}`);
          return `url(#${lineGradient})`;
        })
        .attr('cx', d => d.x)
        .attr('cy', d => d.y);
    }
  }

  renderGradients() {
    this.svg
      .selectAll('.chart-line-gradient')
      .data(this.data.map((item, i) => ({ dataset: item, index: i })))
      .join('linearGradient')
      .attr('id', d => this.getId(`line-gradient-${d.index}`))
      .attr('class', 'chart-line-gradient')
      .attr('gradientUnits', 'userSpaceOnUse')
      .attr('x1', '0%')
      .attr('y1', d => {
        const minValue = min(d.dataset.dataset, item => item.value);
        return this.yScale(minValue);
      })
      .attr('x2', '0%')
      .attr('y2', d => {
        const maxValue = max(d.dataset.dataset, item => item.value);
        return this.yScale(maxValue);
      })
      .selectAll('stop')
      .data(d => {
        const colorHex = getColorHex(this.data[d.index].color);
        const clr = parseColor(colorHex, '#000');
        return [
          { offset: '0%', color: clr.darken(0.2) },
          { offset: '100%', color: clr.lighten(0.2) }
        ];
      })
      .join('stop')
      .attr('offset', d => d.offset)
      .attr('stop-color', d => d.color);
  }

  initDatasetHover() {
    this.hoverLegendDatasetIndex$.pipe(untilDestroyed(this)).subscribe(hoverIndex => {
      this.data.forEach((item, i) => {
        const nodes = this.svg.selectAll<SVGRectElement, any>(`.chart-circles_index-${i}`).nodes();
        if (!isSet(hoverIndex) || i === hoverIndex) {
          nodes.forEach(node => node.classList.remove('chart-circles_disabled'));
        } else {
          nodes.forEach(node => node.classList.add('chart-circles_disabled'));
        }
      });
    });

    this.hoverDatasetGroup$.pipe(untilDestroyed(this)).subscribe(hoverDatasetGroup => {
      const nodes = this.svg.selectAll<SVGRectElement, any>(`.chart-circle`).nodes();
      const activeClass = hoverDatasetGroup
        ? `chart-circle_index-${hoverDatasetGroup.datasetIndex}-${hoverDatasetGroup.groupIndex}`
        : undefined;

      nodes.forEach(node => {
        if (activeClass && node.classList.contains(activeClass)) {
          node.classList.add('chart-circle_active');

          if (!this.bubbles) {
            select(node).attr('r', 7);
          }
        } else {
          node.classList.remove('chart-circle_active');

          if (!this.bubbles) {
            select(node).attr('r', 5);
          }
        }

        if (!activeClass || node.classList.contains(activeClass)) {
          node.classList.remove('chart-circle_disabled');
        } else {
          node.classList.add('chart-circle_disabled');
        }
      });

      this.updateInteractiveClasses(!!hoverDatasetGroup);
    });
  }

  initInteractive() {
    this.interactiveRect = this.svg.append('rect').attr('class', 'chart-interactive');
  }

  renderInteractive() {
    this.interactiveRect.attr('width', this.width).attr('height', this.height);
  }

  updateInteractiveClasses(hoverGroup: boolean) {
    if (!this.interactiveRect) {
      return;
    }

    const nodes = this.interactiveRect.nodes();
    const activeClass = 'chart-interactive_clickable';

    if (this.dataClickEnabled && hoverGroup) {
      nodes.forEach(node => {
        if (!node.classList.contains(activeClass)) {
          node.classList.add(activeClass);
        }
      });
    } else {
      nodes.forEach(node => {
        if (node.classList.contains(activeClass)) {
          node.classList.remove(activeClass);
        }
      });
    }
  }

  initEvents() {
    merge(
      fromEvent<MouseEvent>(this.interactiveRect.node(), 'mouseenter').pipe(map(e => ({ hover: true, e: e }))),
      fromEvent<MouseEvent>(this.interactiveRect.node(), 'mouseleave').pipe(map(() => ({ hover: false, e: undefined })))
    )
      .pipe(untilDestroyed(this))
      .subscribe(chart => {
        if (chart.hover) {
          this.onMouseEnter(chart.e);
        } else {
          this.onMouseLeave();
        }
      });

    fromEvent(this.interactiveRect.node(), 'mousemove')
      .pipe(untilDestroyed(this))
      .subscribe(e => this.onMouseMove(e));

    fromEvent<PointerEvent>(this.interactiveRect.node(), 'click')
      .pipe(untilDestroyed(this))
      .subscribe(e => {
        const hoverDatasetGroup = this.hoverDatasetGroup$.value;
        if (hoverDatasetGroup) {
          const group = this.data[hoverDatasetGroup.datasetIndex].dataset[hoverDatasetGroup.groupIndex].group;
          const group2 = this.data[hoverDatasetGroup.datasetIndex].dataset[hoverDatasetGroup.groupIndex].group2;
          const group3 = this.data[hoverDatasetGroup.datasetIndex].dataset[hoverDatasetGroup.groupIndex].group3;
          const value = this.data[hoverDatasetGroup.datasetIndex].dataset[hoverDatasetGroup.groupIndex].value;

          this.onClick({
            datasetIndex: hoverDatasetGroup.datasetIndex,
            groupIndex: hoverDatasetGroup.groupIndex,
            group: group,
            group2: group2,
            group3: group3,
            value: value,
            position: {
              x: e.clientX,
              y: e.clientY
            }
          });
        }
      });
  }

  formatNumberValue(value: number, format?: string): string {
    format = format || this.yFormat;

    if (!isSet(format)) {
      return String(value);
    }

    return numeral(value).format(format);
  }

  formatCoords(coords: number[], format?: string): string {
    const result = coords.map(item => this.formatNumberValue(item, format)).join(', ');
    return `(${result})`;
  }

  showDatasetTooltip(closestDataset: DataTotalItem & { x: number; y: number }, reuse = false) {
    let group: string;

    if (closestDataset.item.group instanceof moment) {
      const format = getDateFormatByLookup(this.dataGroupLookup) || 'lll';
      group = (closestDataset.item.group as moment.Moment).format(format);
    } else {
      group = String(closestDataset.item.group);
    }

    const defaultLabel = this.data.length > 1 ? `Dataset ${closestDataset.datasetIndex + 1}` : undefined;

    this.dataTooltip.show({
      datasets: [
        {
          value: this.bubbles
            ? this.formatCoords(
                [group, closestDataset.item.group2, closestDataset.item.value],
                closestDataset.dataset.format
              )
            : this.formatCoords([group, closestDataset.item.value], closestDataset.dataset.format),
          valueFormat: closestDataset.dataset.format,
          label: isSet(closestDataset.dataset.name) ? closestDataset.dataset.name : defaultLabel,
          color: closestDataset.dataset.color
        }
      ],
      valueFormat: this.yFormat,
      colorCircle: true,
      theme: this.theme,
      x: this.margin.left + closestDataset.x,
      y: this.margin.top + closestDataset.y,
      portalOutlet: this.portalOutlet,
      reuse: reuse
    });
  }

  onMouseEnter(e) {
    if (!this.interactive) {
      return;
    }

    this.dataTooltip.close();

    const closestDataset = this.getClosestDataset(e);

    if (closestDataset) {
      this.circleHover = this.svg
        .insert('circle', '.chart-circles')
        .attr('class', 'chart-circle-hover')
        .attr('r', 12)
        .attr('cx', closestDataset.x)
        .attr('cy', closestDataset.y);

      this.hoverDatasetGroup$.next({ datasetIndex: closestDataset.datasetIndex, groupIndex: closestDataset.itemIndex });

      if (this.tooltips) {
        this.showDatasetTooltip(closestDataset);
      }
    }
  }

  getClosestDataset(e: MouseEvent): DataTotalItem & { x: number; y: number } {
    const [pointerX, pointerY] = pointer(e);
    const hoverRange = this.bubbles ? 15 : 10;
    const dataTotal = this.dataTotal
      .filter(item => !this.selectedDatasetIndexes || this.selectedDatasetIndexes[item.datasetIndex])
      .map(item => {
        let distance = 0;
        const itemY = this.bubbles ? this.yScale(item.item.group2) : this.yScale(item.item.value);

        if (this.xScaleTime) {
          const itemX = this.xScaleTime(item.item.group as moment.Moment);
          distance = Math.hypot(itemX - pointerX, itemY - pointerY);
        } else if (this.xScaleLinear) {
          const itemX = this.xScaleLinear(item.item.group as number);
          distance = Math.hypot(itemX - pointerX, itemY - pointerY);
        }

        return {
          ...item,
          distance: distance
        };
      })
      .filter(item => item.distance <= hoverRange);

    const dataClosest = least(dataTotal, item => item.distance);

    if (!dataClosest) {
      return;
    }

    let x: number;
    const y = this.bubbles ? this.yScale(dataClosest.item.group2) : this.yScale(dataClosest.item.value);

    if (this.xScaleTime) {
      x = this.xScaleTime(dataClosest.item.group as moment.Moment);
    } else if (this.xScaleLinear) {
      x = this.xScaleLinear(dataClosest.item.group as number);
    }

    return {
      ...dataClosest,
      x: x,
      y: y
    };
  }

  onMouseMove(e) {
    if (!this.interactive) {
      return;
    }

    const closestDataset = this.getClosestDataset(e);

    if (closestDataset) {
      if (this.circleHover) {
        this.circleHover.attr('cx', closestDataset.x).attr('cy', closestDataset.y);
      } else {
        this.circleHover = this.svg
          .insert('circle', '.chart-circles')
          .attr('class', 'chart-circle-hover')
          .attr('r', 12)
          .attr('cx', closestDataset.x)
          .attr('cy', closestDataset.y);
      }

      this.hoverDatasetGroup$.next({ datasetIndex: closestDataset.datasetIndex, groupIndex: closestDataset.itemIndex });

      if (this.tooltips) {
        this.showDatasetTooltip(closestDataset, true);
      }
    } else {
      this.dataTooltip.close();

      this.hoverDatasetGroup$.next(undefined);

      if (this.circleHover) {
        this.circleHover.remove();
        this.circleHover = undefined;
      }
    }
  }

  onMouseLeave() {
    if (!this.interactive) {
      return;
    }

    if (this.circleHover) {
      this.circleHover.remove();
      this.circleHover = undefined;
    }

    this.hoverDatasetGroup$.next(undefined);

    this.dataTooltip.close();
  }

  onClick(options: DataClickEvent) {
    if (!this.dataClickEnabled) {
      return;
    }

    this.dataClick.emit(options);
  }

  get selectedDatasetCount(): number {
    return this.selectedDatasetIndexes ? keys(this.selectedDatasetIndexes).length : 0;
  }

  toggleSelectedDatasetIndex(index: number) {
    if (!this.interactive) {
      return;
    }

    if (!this.selectedDatasetIndexes) {
      this.selectedDatasetIndexes = fromPairs(this.data.map((item, i) => [i, true]).filter(([i]) => i !== index));
    } else if (this.selectedDatasetIndexes[index]) {
      delete this.selectedDatasetIndexes[index];
    } else {
      this.selectedDatasetIndexes[index] = true;

      if (this.selectedDatasetCount === this.data.length) {
        this.selectedDatasetIndexes = undefined;
      }
    }

    this.cd.markForCheck();

    this.data.forEach((item, i) => {
      const nodes = this.svg.selectAll<SVGRectElement, any>(`.chart-circles_index-${i}`).nodes();

      if (!this.selectedDatasetIndexes || this.selectedDatasetIndexes[i]) {
        nodes.forEach(node => node.classList.remove('chart-circles_hidden'));
      } else {
        nodes.forEach(node => node.classList.add('chart-circles_hidden'));
      }
    });
  }

  onLegendDatasetMouseEnter(index: number) {
    if (!this.interactive) {
      return;
    }

    this.hoverLegendDatasetIndex$.next(index);
  }

  onLegendDatasetMouseLeave() {
    if (!this.interactive) {
      return;
    }

    this.hoverLegendDatasetIndex$.next(undefined);
  }

  rerender() {
    this.initBounds();
    this.renderYAxis();
    this.fitYAxis();
    this.renderXAxis();
    this.renderScatter();
    this.renderGradients();
    this.renderInteractive();
  }

  onResize() {
    this.rerender();
  }

  colorDisplay(value: string): string {
    return getColorHexStr(value);
  }
}
