import { CdkConnectedOverlay } from '@angular/cdk/overlay';
import { AfterViewInit, ChangeDetectorRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';
import { colors, getColorVariable, GradientType } from '@modules/colors';
import { FillSettingsControl, globalColorOptions } from '@modules/colors-components';
import { FillType, Frame } from '@modules/customize';
import { ThemeService } from '@modules/theme';
import { isSet } from '@shared';
import { stylesEditPopoverPositions } from '../styles-edit/styles-edit-popover-positions';
var StylesEditFillComponent = /** @class */ (function () {
    function StylesEditFillComponent(themeService, sanitizer, cd) {
        this.themeService = themeService;
        this.sanitizer = sanitizer;
        this.cd = cd;
        this.subtitle = 'Background';
        this.contrast = false;
        this.disabled = false;
        this.added = false;
        this.removeEnabled = true;
        this.remove = new EventEmitter();
        this.dropdownOpened = false;
        this.popoverPositions = stylesEditPopoverPositions;
    }
    StylesEditFillComponent.prototype.ngOnInit = function () {
        var _this = this;
        combineLatest(this.control.serialize$(), this.themeService.isDarkTheme$)
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var fillSettings = _a[0], isDarkTheme = _a[1];
            if (fillSettings) {
                var fill = isDarkTheme ? fillSettings.fillDark : fillSettings.fill;
                var background = fill.css({ frame: new Frame({ width: 16, height: 16 }) }).background;
                if (fill.type == FillType.Color) {
                    _this.valueStr = _this.getColorDisplay(fill);
                }
                else if (fill.type == FillType.Gradient && fill.gradient) {
                    if (fill.gradient.type == GradientType.Linear) {
                        _this.valueStr = 'Linear gradient';
                    }
                    else if (fill.gradient.type == GradientType.Radial) {
                        _this.valueStr = 'Radial gradient';
                    }
                    else if (fill.gradient.type == GradientType.Angular) {
                        _this.valueStr = 'Angular gradient';
                    }
                    else if (fill.gradient.type == GradientType.Diamond) {
                        _this.valueStr = 'Diamond gradient';
                    }
                    else {
                        _this.valueStr = undefined;
                    }
                }
                else {
                    _this.valueStr = undefined;
                }
                _this.fillBackground = background ? _this.sanitizer.bypassSecurityTrustStyle(background) : undefined;
            }
            else {
                _this.valueStr = undefined;
                _this.fillBackground = undefined;
            }
            _this.cd.markForCheck();
        });
        if (this.added) {
            this.setDropdownOpened(true);
        }
    };
    StylesEditFillComponent.prototype.ngOnDestroy = function () { };
    StylesEditFillComponent.prototype.ngAfterViewInit = function () {
        this.setPositionObserver();
    };
    StylesEditFillComponent.prototype.getColorDisplay = function (fill) {
        if (!isSet(fill.color)) {
            return;
        }
        var variable = getColorVariable(fill.color);
        if (variable) {
            var option = globalColorOptions.find(function (item) { return item.value == variable; });
            return option ? option.name : fill.color;
        }
        if (fill.color.startsWith('#')) {
            return fill.color;
        }
        var predefinedColor = colors.find(function (item) { return item.name == fill.color; });
        if (predefinedColor) {
            return predefinedColor.label;
        }
    };
    StylesEditFillComponent.prototype.setDropdownOpened = function (value) {
        this.dropdownOpened = value;
        this.cd.markForCheck();
    };
    StylesEditFillComponent.prototype.setPositionObserver = function () {
        var _this = this;
        if (this.popoverPositionsSubscription) {
            this.popoverPositionsSubscription.unsubscribe();
        }
        if (!this.cdkConnectedOverlay) {
            return;
        }
        this.popoverPositionsSubscription = this.cdkConnectedOverlay.positionChange
            .pipe(untilDestroyed(this))
            .subscribe(function (e) {
            var propsEqual = ['offsetX', 'offsetY', 'originX', 'originY', 'overlayX', 'overlayY'];
            var position = _this.popoverPositions.find(function (item) {
                return propsEqual.every(function (prop) { return (item[prop] || undefined) == e.connectionPair[prop]; });
            });
            var otherPosition = _this.popoverPositions.filter(function (item) { return item !== position; });
            if (position) {
                _this.cdkConnectedOverlay.overlayRef.addPanelClass(position.panelClass);
            }
            otherPosition.forEach(function (item) { return _this.cdkConnectedOverlay.overlayRef.removePanelClass(item.panelClass); });
        });
    };
    return StylesEditFillComponent;
}());
export { StylesEditFillComponent };
