import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import pickBy from 'lodash/pickBy';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { debounceTime } from 'rxjs/operators';

import { ProjectSettingsStore } from '@modules/all-project-settings';
import { CustomViewSettings } from '@modules/customize';
import { ColorSchema, colorSchemaDefaults } from '@modules/customize-shared';
import { createFormFieldFactory, NumberFieldType } from '@modules/fields';
import { CurrentProjectStore } from '@modules/projects';
import { ThemeService } from '@modules/theme';
import { isSet } from '@shared';

import { PageEditForm } from './page-edit.form';

@Component({
  selector: 'app-page-edit',
  templateUrl: './page-edit.component.html',
  providers: [PageEditForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageEditComponent implements OnInit, OnDestroy {
  @Input() viewSettings: CustomViewSettings;
  @Output() viewSettingsUpdated = new EventEmitter<CustomViewSettings>();

  createField = createFormFieldFactory();
  placeholders: ColorSchema = {};
  numberFieldTypes = NumberFieldType;

  constructor(
    public form: PageEditForm,
    public currentProjectStore: CurrentProjectStore,
    public themeService: ThemeService,
    private projectSettingsStore: ProjectSettingsStore,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.form.init(this.viewSettings);

    this.form.valueChanges.pipe(debounceTime(200), untilDestroyed(this)).subscribe(() => {
      const result = this.form.submit();
      this.viewSettingsUpdated.emit(result);
    });

    this.projectSettingsStore
      .getAllSettings$()
      .pipe(untilDestroyed(this))
      .subscribe(settings => {
        this.placeholders = {
          ...colorSchemaDefaults,
          maxWidth: '',
          paddingTop: 0,
          paddingRight: 0,
          paddingBottom: 0,
          paddingLeft: 0,
          ...(settings &&
            pickBy(
              {
                backgroundColor: settings.backgroundColor,
                backgroundColorDark: settings.backgroundColorDark,
                backgroundColor2: settings.backgroundColor2,
                backgroundColor2Dark: settings.backgroundColor2Dark,
                backgroundColor3: settings.backgroundColor3,
                backgroundColor3Dark: settings.backgroundColor3Dark,
                backgroundColor4: settings.backgroundColor4,
                backgroundColor4Dark: settings.backgroundColor4Dark,
                backgroundColor5: settings.backgroundColor5,
                backgroundColor5Dark: settings.backgroundColor5Dark,
                textColor: settings.textColor,
                textColorDark: settings.textColorDark,
                textColor2: settings.textColor2,
                textColor2Dark: settings.textColor2Dark,
                textColor3: settings.textColor3,
                textColor3Dark: settings.textColor3Dark,
                borderColor: settings.borderColor,
                borderColorDark: settings.borderColorDark,
                borderColor2: settings.borderColor2,
                borderColor2Dark: settings.borderColor2Dark,
                borderColor3: settings.borderColor3,
                borderColor3Dark: settings.borderColor3Dark,
                maxWidth: isSet(settings.maxWidth, true) ? String(settings.maxWidth) : undefined,
                paddingTop: settings.padding.top,
                paddingRight: settings.padding.right,
                paddingBottom: settings.padding.bottom,
                paddingLeft: settings.padding.left
              },
              v => isSet(v, true)
            ))
        };
        this.cd.markForCheck();
      });
  }

  ngOnDestroy(): void {}
}
