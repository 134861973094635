var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector } from '@angular/core';
import { Input } from '@modules/fields';
import { ShareMenuItem } from '@modules/menu';
import { FieldInputControl } from '@modules/parameters';
import { CustomizeBarPagesEditMenuItemControl, menuItemControls } from './customize-bar-pages-edit.form';
var CustomizeBarPagesEditShareControl = /** @class */ (function (_super) {
    __extends(CustomizeBarPagesEditShareControl, _super);
    function CustomizeBarPagesEditShareControl(injector) {
        var _this = _super.call(this, {
            visible_input: new FieldInputControl({ path: ['value'] })
        }) || this;
        _this.injector = injector;
        return _this;
    }
    CustomizeBarPagesEditShareControl.inject = function (injector) {
        return Injector.create({
            providers: [
                {
                    provide: CustomizeBarPagesEditShareControl,
                    useFactory: function () {
                        return new CustomizeBarPagesEditShareControl(injector);
                    },
                    deps: []
                }
            ],
            parent: injector
        }).get(CustomizeBarPagesEditShareControl);
    };
    CustomizeBarPagesEditShareControl.prototype.deserialize = function (item) {
        this.menuItem = item;
        this.controls.visible_input.patchValue(item.visibleInput ? item.visibleInput.serializeWithoutPath() : {});
        this.markAsPristine();
    };
    CustomizeBarPagesEditShareControl.prototype.serialize = function () {
        var instance = new ShareMenuItem().deserialize(this.menuItem.serialize());
        instance.visibleInput = this.controls.visible_input.value
            ? new Input().deserialize(this.controls.visible_input.value)
            : undefined;
        return instance;
    };
    return CustomizeBarPagesEditShareControl;
}(CustomizeBarPagesEditMenuItemControl));
export { CustomizeBarPagesEditShareControl };
menuItemControls.push({ menuItem: ShareMenuItem, control: CustomizeBarPagesEditShareControl });
