<app-sidebar-header [title]="'Menu customization'" (back)="back()">
  <ng-container actions>
    <div class="button-group" [style.margin]="'-5px 0'">
      <div
        class="button-group__item"
        [class.button-group__item_active]="themeService.isDefaultTheme"
        (click)="themeService.theme = 'default'"
      >
        <span class="button-group__item-icon icon-sun"></span>
      </div>

      <div
        class="button-group__item"
        [class.button-group__item_active]="themeService.isDarkTheme"
        (click)="themeService.theme = 'dark'"
      >
        <span class="button-group__item-icon icon-moon"></span>
      </div>
    </div>
  </ng-container>
</app-sidebar-header>

<div class="sidebar__content">
  <app-sidebar-section>
    <div
      appDropList
      [appDropListData]="context.controls.menu.controls.blocks.controls"
      [appDropListLockAxis]="dragAxis.Y"
      (appDropListDropped)="dragDrop($event)"
    >
      <div
        *ngFor="let item of blockItems; trackBy: trackMenuBlockItemFn"
        class="sidebar__element"
        appDrag
        [appDragData]="item"
        (mouseenter)="blockControlHover$.next(item.control)"
        (mouseleave)="blockControlHover$.next(undefined)"
      >
        <app-menu-block-edit
          [title]="item.title"
          [control]="item.control"
          [layoutGroups]="layoutGroups"
          [active]="blockControlPreviewHover$.value === item.control"
          [disabled]="blockControlPreviewHover$.value && blockControlPreviewHover$.value !== item.control"
          (remove)="context.controls.menu.controls.blocks.removeControl(item.control)"
        ></app-menu-block-edit>
      </div>
    </div>

    <div *ngIf="blockItems.length < context.controls.menu.controls.blocks.controlsFixedLength" class="sidebar__element">
      <app-menu-block-add [layoutGroups]="layoutGroups" (add)="addMenuBlock($event)"></app-menu-block-add>
    </div>
  </app-sidebar-section>
</div>

<app-sidebar-submit
  [saveDisabled]="!(hasChanges$ | async)"
  [saveLoading]="submitLoading"
  [saveLabel]="'Save changes'"
  [cancelDisabled]="!(hasChanges$ | async)"
  [cancelLabel]="'Reset changes'"
  [hidden]="!(hasChanges$ | async)"
  (cancelClick)="cancel()"
  (submitClick)="submit()"
>
</app-sidebar-submit>
