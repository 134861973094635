<app-styles-preview-button-style
  [style]="tintStyles.Primary"
  [control]="stylesControlPrimary"
  [label]="'Primary style'"
  [accentColor]="accentColor"
  [horizontal]="true"
></app-styles-preview-button-style>

<app-styles-preview-button-style
  [style]="tintStyles.Default"
  [control]="stylesControlDefault"
  [label]="'Default style'"
  [accentColor]="accentColor"
  [horizontal]="true"
></app-styles-preview-button-style>

<app-styles-preview-button-style
  [style]="tintStyles.Transparent"
  [control]="stylesControlTransparent"
  [label]="'Transparent style'"
  [accentColor]="accentColor"
  [horizontal]="true"
></app-styles-preview-button-style>
