import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  Optional
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fromEvent, Subscription } from 'rxjs';

import { NotificationService } from '@common/notifications';
import { BasePopupComponent } from '@common/popups';
import { AppConfigService } from '@core';
import { AdminMode, ROUTE_ADMIN_MODE } from '@modules/admin-mode';
import { IntercomService, UniversalAnalyticsService } from '@modules/analytics';
import { ApiService } from '@modules/api';
import { CurrentProjectStore, ProjectTokenService, ResourceName, SecretToken, socialBackends } from '@modules/projects';
import { HttpMethod, submitForm } from '@modules/queries';
import { controlValue, isSet } from '@shared';

import { registerResourceSettingsComponent } from '../../../data/resource-settings-components';
import { ChooseSyncController } from '../../../services/choose-sync/choose-sync.controller';
import { BaseResourceSettingsComponent } from '../base-resource-settings/base-resource-settings.component';
import { GoogleSheetsResourceSettingsForm } from './google-sheets-resource-settings.form';

@Component({
  selector: 'app-google-sheets-resource-settings',
  templateUrl: './google-sheets-resource-settings.component.html',
  providers: [GoogleSheetsResourceSettingsForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GoogleSheetsResourceSettingsComponent extends BaseResourceSettingsComponent implements OnInit, OnDestroy {
  account: string;
  accountSubscription: Subscription;
  accountLoading = false;
  accountPermissionsError = false;
  filesEditing = false;

  constructor(
    @Inject(ROUTE_ADMIN_MODE) private mode: AdminMode,
    private http: HttpClient,
    private apiService: ApiService,
    private appConfigService: AppConfigService,
    private currentProjectStore: CurrentProjectStore,
    private chooseSyncController: ChooseSyncController,
    private notificationService: NotificationService,
    public form: GoogleSheetsResourceSettingsForm,
    @Optional() popupComponent: BasePopupComponent,
    projectTokenService: ProjectTokenService,
    intercomService: IntercomService,
    analyticsService: UniversalAnalyticsService,
    cd: ChangeDetectorRef
  ) {
    super(form, popupComponent, projectTokenService, intercomService, analyticsService, cd);
  }

  ngOnInit(): void {
    super.ngOnInit();

    fromEvent<MessageEvent>(window, 'message')
      .pipe(untilDestroyed(this))
      .subscribe(message => {
        if (message.data && message.data['type'] == 'oauth_response') {
          const data = message.data['params'];

          if (data['params'] && data['params']['scope']) {
            const requiredScopes = [
              'openid',
              'https://www.googleapis.com/auth/userinfo.email',
              'https://www.googleapis.com/auth/userinfo.profile',
              'https://www.googleapis.com/auth/spreadsheets'
            ];

            if (!requiredScopes.every(item => data['params']['scope'].includes(item))) {
              this.accountPermissionsError = true;
              this.cd.markForCheck();

              return;
            }
          }

          this.accountPermissionsError = false;
          this.cd.markForCheck();

          const secretToken = new SecretToken().deserialize(data);
          this.form.form.patchValue({
            access_token: secretToken.value,
            params: secretToken.params
          });

          if (this.form.isResourceFormValid()) {
            this.setFilesEditing(true);
          }
        }
      });

    controlValue(this.form.form.controls['access_token'])
      .pipe(untilDestroyed(this))
      .subscribe(accessToken => this.updateAccount(accessToken));
  }

  ngOnDestroy(): void {}

  updateAccount(accessToken: string) {
    if (this.accountSubscription) {
      this.accountSubscription.unsubscribe();
      this.accountSubscription = undefined;
    }

    if (!isSet(accessToken)) {
      this.account = undefined;
      this.accountLoading = false;
      this.cd.markForCheck();
      return;
    }

    const url = 'https://www.googleapis.com/drive/v3/about';
    const headers = {
      Authorization: `Bearer ${accessToken}`
    };
    const params = {
      fields: 'user'
    };

    this.accountLoading = true;
    this.cd.markForCheck();

    if (this.accountSubscription) {
      this.accountSubscription.unsubscribe();
    }

    this.accountSubscription = this.http
      .get(url, { headers: headers, params: params })
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          this.account = result['user']['emailAddress'];
          this.accountSubscription = undefined;
          this.accountLoading = false;
          this.cd.markForCheck();
        },
        () => {
          this.account = undefined;
          this.accountSubscription = undefined;
          this.accountLoading = false;
          this.cd.markForCheck();
        }
      );
  }

  getOAuthToken() {
    this.accountPermissionsError = false;
    this.cd.markForCheck();

    this.apiService
      .refreshToken()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        const params = { USE_BACKEND_SHARED_PARAMS: 'googlesheets' };
        const redirect = this.redirectUrl();
        const backend = socialBackends.find(item => item.name == 'google-oauth2');
        const data = [
          {
            name: 'name',
            value: this.form.tokenName
          },
          {
            name: 'backend',
            value: backend ? backend.path : ''
          },
          {
            name: 'params',
            value: JSON.stringify(params)
          },
          {
            name: 'access_token',
            value: this.apiService.getAccessToken()
          },
          {
            name: 'redirect_uri',
            value: redirect
          }
        ];

        if (this.mode == AdminMode.Builder) {
          data.push({
            name: 'draft',
            value: '1'
          });
        }

        const w = open('', '', 'height=800,width=600');
        if (!w) {
          this.notificationService.error(
            'Failed to open popup',
            'Your browser has blocked opening a new window. Please check your browser settings to allow opening pop-ups'
          );
          return;
        }

        submitForm(HttpMethod.POST, this.apiService.createOAuthTokenUrl, data, w.document);
      });
  }

  redirectUrl() {
    return `${window.location.origin}/oauth_response`;
  }

  setFilesEditing(value: boolean) {
    this.filesEditing = value;
    this.cd.markForCheck();

    if (value && !this.form.form.controls.files.controls.length) {
      this.form.itemsAppend(this.form.createItem());
    }
  }

  chooseSync() {
    this.chooseSyncController
      .chooseSyncMode(this.form.form.controls['sync'], { typeItem: this.typeItem })
      .pipe(untilDestroyed(this))
      .subscribe(() => this.submit());
  }
}

registerResourceSettingsComponent(ResourceName.GoogleSheets, GoogleSheetsResourceSettingsComponent);
