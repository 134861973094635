import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import isEqual from 'lodash/isEqual';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';
import { debounceTime, delay, startWith } from 'rxjs/operators';

import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { ParametersConfigurable } from '@modules/field-components';
import { FieldType, InputValueType, ParameterArray, ParameterControl } from '@modules/fields';
import { FieldInputControl, InputFieldProvider, InputFieldProviderItem } from '@modules/parameters';
import { SidebarCollapseContext } from '@modules/sidebar';
import { isSet, objectsSortPredicate } from '@shared';

import { InputsEditForm } from '../inputs-edit/inputs-edit.form';

@Component({
  selector: 'app-parameters-edit-with-inputs',
  templateUrl: './parameters-edit-with-inputs.component.html',
  providers: [InputsEditForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ParametersEditWithInputsComponent implements OnInit, OnDestroy {
  @Input() control: ParameterArray;
  @Input() inputsControl: AbstractControl;
  @Input() parameterProvider: InputFieldProvider;
  @Input() fieldsControl: AbstractControl;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() contextElementPath: (string | number)[];
  @Input() contextElementPaths: (string | number)[][];
  @Input() staticValueDisabled = false;
  @Input() userInput = false;
  @Input() displayValueTypes = [InputValueType.Formula];
  @Input() placeholder = 'Text';
  @Input() formulaPlaceholder = 'Formula';
  @Input() jsPlaceholder = 'return 2 * 3;';
  @Input() configurable: ParametersConfigurable = {};
  @Input() addLabel = 'Add Parameter';
  @Input() addBaseName = 'param';
  @Input() emptyLabel = 'Parameter';
  @Input() classes: string | string[];
  @Input() analyticsSource: string;

  lastAddedForm: ParameterControl;
  collapseContext = new SidebarCollapseContext();
  sendParametersAnalytics = true;

  constructor(
    public inputsForm: InputsEditForm,
    private analyticsService: UniversalAnalyticsService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.control.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      this.cd.markForCheck();
    });

    this.initInputs();

    if (this.sendParametersAnalytics) {
      this.control.valueChanges.pipe(debounceTime(1000), untilDestroyed(this)).subscribe(value => {
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.Parameter.SuccessfullySetUp, {
          Object: this.analyticsSource
        });
      });
    }
  }

  ngOnDestroy(): void {}

  getDistinctName(baseName: string, template = (n, i) => `${n}_${i}`, startIndex = 1) {
    const names = this.control.controls.map(item => {
      const value = item.controls.name.value;
      return isSet(value) ? value : '';
    });
    let name: string;
    let index = startIndex;

    do {
      name = template(baseName, index);
      ++index;
    } while (names.find(item => item.toLowerCase() == name.toLowerCase()));

    return name;
  }

  addItem() {
    const name = this.getDistinctName(this.addBaseName);
    this.lastAddedForm = this.control.appendControl(undefined, { name: name, field: FieldType.Text });

    const form = this.inputsForm.createItem({
      path: [name],
      value_type: InputValueType.Formula
    });
    this.inputsForm.arrayAppend(form);

    if (this.sendParametersAnalytics) {
      this.analyticsService.sendSimpleEvent(AnalyticsEvent.Parameter.Added, {
        Object: this.analyticsSource
      });
    }
  }

  removeItem(control: ParameterControl) {
    this.control.removeControl(control);

    if (this.sendParametersAnalytics) {
      this.analyticsService.sendSimpleEvent(AnalyticsEvent.Parameter.Deleted, {
        Object: this.analyticsSource
      });
    }
  }

  getItemForm(name: string): FieldInputControl {
    return this.inputsForm.form.controls.find(item => isEqual(item.controls.path.value, [name]));
  }

  initInputs() {
    if (!this.inputsControl) {
      return;
    }

    this.inputsForm.init(
      this.inputsControl,
      this.parameterProvider,
      this.fieldsControl,
      this.context,
      this.contextElement
    );

    combineLatest(this.parameterProvider.getItems$(), this.inputsForm.updatedFromControl.pipe(startWith(undefined)))
      .pipe(delay(0), untilDestroyed(this))
      .subscribe(([value]) => {
        this.updateInputItems(value);
      });
  }

  updateInputItems(providerItems: InputFieldProviderItem[]) {
    const displayAllParameters = providerItems.every(item => !item.children || !item.children.length);
    const parameters = this.parameterProvider.getItemFields(providerItems);
    const items = this.inputsForm.form.controls;
    const addItems = [];
    const removeItems = [];

    if (displayAllParameters) {
      // Add inputs for all parameters if they don't exist
      parameters.forEach((parameter, i) => {
        const defaultValueType = providerItems[i].defaultValueType;
        const existingValue = items.find(item => isEqual(item.controls.path.value, [parameter.name]));

        let valueType: InputValueType;

        if (parameter.required) {
          valueType = this.userInput ? InputValueType.Prompt : defaultValueType;
        }

        if (existingValue) {
          if (!existingValue.controls.value_type.value && valueType) {
            existingValue.controls.value_type.patchValue(valueType);
          }

          if (existingValue.controls.required.value != parameter.required) {
            existingValue.controls.required.patchValue(parameter.required);
          }

          return;
        }

        addItems.push(
          this.inputsForm.createItem({
            path: [parameter.name],
            value_type: valueType,
            required: parameter.required
          })
        );
      });

      // Remove inputs
      items.forEach(item => {
        const existingParameter = parameters.find(parameter => isEqual([parameter.name], item.controls.path.value));

        if (!existingParameter) {
          // Remove inputs for non existing parameters
          removeItems.push(item);
        } else if (!isSet(item.controls.path.value)) {
          // Remove no name inputs
          removeItems.push(item);
        }
      });
    } else {
      // Add inputs for required parameters if they don't exist
      parameters
        .filter(item => item.required)
        .forEach(parameter => {
          const providerItem = providerItems.find(item => item.field && item.field.name == parameter.name);
          const defaultValueType = providerItem ? providerItem.defaultValueType : undefined;
          const existingValue = items.find(item => isEqual(item.controls.path.value, [parameter.name]));

          let valueType: InputValueType;

          if (parameter.required) {
            valueType = this.userInput ? InputValueType.Prompt : defaultValueType;
          }

          if (existingValue) {
            if (!existingValue.controls.value_type.value && valueType) {
              existingValue.controls.value_type.patchValue(valueType);
            }

            if (existingValue.controls.required.value != parameter.required) {
              existingValue.controls.required.patchValue(parameter.required);
            }

            return;
          }

          addItems.push(
            this.inputsForm.createItem({
              path: [parameter.name],
              value_type: valueType || '',
              required: parameter.required
            })
          );
        });

      // Remove inputs
      items.forEach(item => {
        const existingParameter = parameters.find(parameter => isEqual([parameter.name], item.controls.path.value));

        if (!existingParameter) {
          // Remove inputs for non existing parameters
          removeItems.push(item);
        } else if (!isSet(item.controls.path.value)) {
          // Remove no name inputs
          removeItems.push(item);
        }
      });
    }

    if (addItems.length) {
      this.inputsForm.arrayAppend(...addItems.sort(objectsSortPredicate('-value.path')));
    }

    if (removeItems.length) {
      this.inputsForm.arrayRemove(...removeItems);
    }
  }

  dragDrop(event: CdkDragDrop<FormControl[]>) {
    if (event.previousIndex !== event.currentIndex) {
      moveItemInArray(this.control.controls, event.previousIndex, event.currentIndex);
      this.control.updateValueAndValidity();
    }
  }
}
