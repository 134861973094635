/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./action-menu-item/action-menu-item.component.ngfactory";
import * as i2 from "./action-menu-item/action-menu-item.component";
import * as i3 from "../../../action-queries/services/action/action.service";
import * as i4 from "../../../action-queries/services/action-controller/action-controller.service";
import * as i5 from "../../../customize/services/customize/customize.service";
import * as i6 from "../../../../common/notifications/services/notification/notification.service";
import * as i7 from "@angular/common";
import * as i8 from "./action-menu.component";
import * as i9 from "@angular/cdk/overlay";
var styles_ActionMenuComponent = [];
var RenderType_ActionMenuComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ActionMenuComponent, data: {} });
export { RenderType_ActionMenuComponent as RenderType_ActionMenuComponent };
function View_ActionMenuComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-action-menu-item", [], null, [[null, "selected"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selected" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_ActionMenuItemComponent_0, i1.RenderType_ActionMenuItemComponent)), i0.ɵdid(1, 770048, null, 0, i2.ActionMenuItemComponent, [i3.ActionService, i4.ActionControllerService, i5.CustomizeService, i6.NotificationService, i0.Injector, i0.ChangeDetectorRef], { item: [0, "item"], context: [1, "context"], contextElement: [2, "contextElement"], localContext: [3, "localContext"], analyticsSource: [4, "analyticsSource"] }, { selected: "selected" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.context; var currVal_2 = _co.contextElement; var currVal_3 = _co.localContext; var currVal_4 = _co.analyticsSource; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_ActionMenuComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "popover2 overlay-content overlay-content_animated popover2_theme"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "popover2__inner popover2__inner_clip mat-menu-panel-theme"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 3, "div", [["class", "mat-menu"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "mat-menu-content"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ActionMenuComponent_1)), i0.ɵdid(5, 278528, null, 0, i7.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.actions; _ck(_v, 5, 0, currVal_0); }, null); }
export function View_ActionMenuComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-action-menu", [], null, null, null, View_ActionMenuComponent_0, RenderType_ActionMenuComponent)), i0.ɵdid(1, 245760, null, 0, i8.ActionMenuComponent, [i9.OverlayRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ActionMenuComponentNgFactory = i0.ɵccf("app-action-menu", i8.ActionMenuComponent, View_ActionMenuComponent_Host_0, { actions: "actions", context: "context", contextElement: "contextElement", localContext: "localContext", analyticsSource: "analyticsSource" }, {}, []);
export { ActionMenuComponentNgFactory as ActionMenuComponentNgFactory };
