<ng-container *ngIf="visible || (customizeEnabled$ | async)">
  <app-auto-field
    *ngIf="!loadingValue && state.field"
    [form]="form"
    [field]="state.field"
    [readonly]="state.readonly"
    [labelStyleGlobal]="'fieldLabelTextStyle'"
    [labelAdditional]="element.labelAdditional"
    [labelAdditionalStyleGlobal]="'fieldLabelAdditionalTextStyle'"
    [elementStyles]="elementStyles"
    [manualMargin]="true"
    [context]="context"
    [fieldContextElement]="viewContextElement"
    [tooltip]="state.tooltip"
    [accentColor]="accentColor"
    [elementActions]="element.elementActions"
  >
  </app-auto-field>

  <app-auto-field-stub
    *ngIf="loadingValue"
    [field]="state.field"
    [labelStyleGlobal]="'fieldLabelTextStyle'"
    [labelAdditional]="element.labelAdditional"
    [labelAdditionalStyleGlobal]="'fieldLabelAdditionalTextStyle'"
    [elementStyles]="elementStyles"
    [readonly]="state.readonly"
    [manualMargin]="true"
  ></app-auto-field-stub>
</ng-container>
