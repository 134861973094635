import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-file-preview',
  templateUrl: './file-preview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilePreviewComponent implements OnInit {
  @Input() previewFilename: string;
  @Input() previewExtension: string;
  @Input() previewSize: number;
  @Input() additional: string;
  @Input() compact = false;
  @Input() theme = false;

  constructor() {}

  ngOnInit() {}
}
