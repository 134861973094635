import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Subscription } from 'rxjs';

import { controlValue, TypedChanges } from '@shared';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckboxComponent implements OnInit, OnDestroy, OnChanges {
  @Input() control: AbstractControl;
  @Input() value = false;
  @Input() disabled = false;
  @Input() round = false;
  @Input() square = false;
  @Input() dash = false;
  @Input() accentColor: string;
  @Input() theme = false;

  displayValue = false;
  displayValueSubscription: Subscription;
  accentColorSafe: SafeStyle;

  constructor(private sanitizer: DomSanitizer, private cd: ChangeDetectorRef) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<CheckboxComponent>): void {
    if (changes.accentColor) {
      this.accentColorSafe = this.sanitizer.bypassSecurityTrustStyle(this.accentColor);
    }

    if (changes.control || changes.value) {
      this.initDisplayValue();
    }
  }

  initDisplayValue() {
    if (this.displayValueSubscription) {
      this.displayValueSubscription.unsubscribe();
      this.displayValueSubscription = undefined;
    }

    if (this.control) {
      this.displayValueSubscription = controlValue(this.control)
        .pipe(untilDestroyed(this))
        .subscribe(value => {
          this.displayValue = value;
          this.cd.markForCheck();
        });
    } else {
      this.displayValue = this.value;
      this.cd.markForCheck();
    }
  }

  toggle() {
    if (this.control && this.control.disabled) {
      return;
    }

    if (this.control) {
      this.control.patchValue(!this.displayValue);
    }
  }
}
