import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable } from 'rxjs';

import { ActionService, isModelUpdateEventMatch, patchModel } from '@modules/action-queries';
import { FieldActions, ViewContext, ViewContextElement } from '@modules/customize';
import { createFormFieldFactory, DisplayField, FieldType } from '@modules/fields';
import { ListItem } from '@modules/list';
import { Model, ModelDescription } from '@modules/models';

@Component({
  selector: 'app-model-card',
  templateUrl: './model-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModelCardComponent implements OnInit, OnDestroy {
  @Input() item: ListItem;
  @Input() modelDescription: ModelDescription;
  @Input() visibleColumns: DisplayField[] = [];
  @Input() columns: DisplayField[] = [];
  @Input() columnActions: FieldActions[] = [];
  @Input() labels = true;
  @Input() vertical = false;
  @Input() compact = false;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() theme = false;
  @Output() modelUpdated = new EventEmitter<Model>();

  createField = createFormFieldFactory();
  fieldTypes = FieldType;

  constructor(private actionService: ActionService, private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.actionService.modelUpdated$.pipe(untilDestroyed(this)).subscribe(e => {
      if (isModelUpdateEventMatch(e, this.modelDescription, this.item.model)) {
        this.item.model = patchModel(this.item.model, e.model);
        this.cd.markForCheck();
        this.modelUpdated.next(this.item.model);
      }
    });
  }

  ngOnDestroy(): void {}

  trackByFn(i, item: DisplayField) {
    return item ? item.name : i;
  }

  getValueStr(column: DisplayField): string | Observable<string> {
    const listItemColumn = this.item.columns.find(item => item.column == column.name);
    return listItemColumn ? listItemColumn.str : undefined;
  }
}
