<div class="sidebar__element">
  <a
    [appLink]="currentProjectStore.instance.settingsLayoutLink('appearance')"
    class="sidebar-list-item sidebar-list-item_clickable"
  >
    <div class="sidebar-list-item__left">
      <span class="sidebar-list-item__icon icon-palette"></span>
    </div>
    <div class="sidebar-list-item__main">
      <div class="sidebar-list-item__title">App appearance</div>
      <div class="sidebar-list-item__description">Apply settings for all pages</div>
    </div>
    <div class="sidebar-list-item__right">
      <span class="sidebar-list-item__action icon-arrow_forward_2"></span>
    </div>
  </a>
</div>

<div class="sidebar-list__separator">
  <app-sidebar-list-separator [title]="'or customize per page'"></app-sidebar-list-separator>
</div>

<div class="sidebar__element">
  <app-sidebar-field [label]="'Bounds'">
    <div class="sidebar-summary-block">
      <div class="sidebar-summary-block__content sidebar-summary-block__content_compact">
        <div class="sidebar-summary-block__item">
          <div class="sidebar-summary-block-item sidebar-summary-block-item_padding_xs-right">
            <div class="sidebar-summary-block-item__left">
              <div
                class="sidebar-summary-block-item__icon icon-resize_horizontal sidebar-summary-block-item__element"
              ></div>
              <div
                class="sidebar-summary-block-item__title sidebar-summary-block-item__element sidebar-summary-block-item__element_margin_l"
              >
                Max Width
              </div>
            </div>

            <div class="sidebar-summary-block-item__main sidebar-summary-block-item__main_right">
              <div class="sidebar-summary-block-item__input sidebar-summary-block-item__input_label">
                <input
                  type="text"
                  class="sidebar-summary-block-item__input-control"
                  [formControl]="form.controls.max_width"
                  [appInputFormat]="{ type: numberFieldTypes.Integer, min: 0 }"
                  [style.width.px]="126"
                />

                <div class="sidebar-summary-block-item__input-label">
                  px
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="sidebar-summary-block__item">
          <app-styles-edit-margin
            [control]="form.controls.padding"
            [contrast]="true"
            [removeEnabled]="false"
          ></app-styles-edit-margin>
        </div>
      </div>
    </div>
  </app-sidebar-field>
</div>

<div class="sidebar__element">
  <app-sidebar-field [label]="'Backgrounds'">
    <ng-container actions-right>
      <div class="button-group" [style.margin]="'-5px 0'">
        <div
          class="button-group__item"
          [class.button-group__item_active]="themeService.isDefaultTheme"
          (click)="themeService.theme = 'default'"
        >
          <span class="button-group__item-icon icon-sun"></span>
        </div>

        <div
          class="button-group__item"
          [class.button-group__item_active]="themeService.isDarkTheme"
          (click)="themeService.theme = 'dark'"
        >
          <span class="button-group__item-icon icon-moon"></span>
        </div>
      </div>
    </ng-container>

    <app-sidebar-card>
      <div class="sidebar__element sidebar__element_margin_s">
        <app-sidebar-field>
          <app-auto-field
            *ngIf="themeService.isDefaultTheme"
            [field]="
              createField({
                name: 'background_color',
                field: 'ColorField',
                params: {
                  classes: ['select_small', 'select_fill'],
                  allow_empty: true,
                  empty_color: placeholders.backgroundColor,
                  default_color: placeholders.backgroundColor,
                  custom_colors: true,
                  prefix: 'Background'
                }
              })
            "
            [form]="form"
            [label]="false"
          ></app-auto-field>

          <app-auto-field
            *ngIf="themeService.isDarkTheme"
            [field]="
              createField({
                name: 'background_color_dark',
                field: 'ColorField',
                params: {
                  classes: ['select_small', 'select_fill'],
                  allow_empty: true,
                  empty_color: placeholders.backgroundColorDark,
                  default_color: placeholders.backgroundColorDark,
                  custom_colors: true,
                  prefix: 'Background'
                }
              })
            "
            [form]="form"
            [label]="false"
          ></app-auto-field>

          <ng-container description>
            Pages background
          </ng-container>
        </app-sidebar-field>
      </div>

      <div class="sidebar__element sidebar__element_margin_s">
        <app-sidebar-field>
          <app-auto-field
            *ngIf="themeService.isDefaultTheme"
            [field]="
              createField({
                name: 'background_color_2',
                field: 'ColorField',
                params: {
                  classes: ['select_small', 'select_fill'],
                  allow_empty: true,
                  empty_color: placeholders.backgroundColor2,
                  default_color: placeholders.backgroundColor2,
                  custom_colors: true,
                  prefix: 'Surfaces 1'
                }
              })
            "
            [form]="form"
            [label]="false"
          ></app-auto-field>

          <app-auto-field
            *ngIf="themeService.isDarkTheme"
            [field]="
              createField({
                name: 'background_color_2_dark',
                field: 'ColorField',
                params: {
                  classes: ['select_small', 'select_fill'],
                  allow_empty: true,
                  empty_color: placeholders.backgroundColor2Dark,
                  default_color: placeholders.backgroundColor2Dark,
                  custom_colors: true,
                  prefix: 'Surfaces 1'
                }
              })
            "
            [form]="form"
            [label]="false"
          ></app-auto-field>

          <ng-container description>
            Cards and popups background
          </ng-container>
        </app-sidebar-field>
      </div>

      <div class="sidebar__element sidebar__element_margin_s">
        <app-sidebar-field>
          <app-auto-field
            *ngIf="themeService.isDefaultTheme"
            [field]="
              createField({
                name: 'background_color_3',
                field: 'ColorField',
                params: {
                  classes: ['select_small', 'select_fill'],
                  allow_empty: true,
                  empty_color: placeholders.backgroundColor3,
                  default_color: placeholders.backgroundColor3,
                  custom_colors: true,
                  alpha_enabled: true,
                  prefix: 'Surfaces 2'
                }
              })
            "
            [form]="form"
            [label]="false"
          ></app-auto-field>

          <app-auto-field
            *ngIf="themeService.isDarkTheme"
            [field]="
              createField({
                name: 'background_color_3_dark',
                field: 'ColorField',
                params: {
                  classes: ['select_small', 'select_fill'],
                  allow_empty: true,
                  empty_color: placeholders.backgroundColor3Dark,
                  default_color: placeholders.backgroundColor3Dark,
                  custom_colors: true,
                  alpha_enabled: true,
                  prefix: 'Surfaces 2'
                }
              })
            "
            [form]="form"
            [label]="false"
          ></app-auto-field>

          <ng-container description>
            Fields and inputs background
          </ng-container>
        </app-sidebar-field>
      </div>

      <div class="sidebar__element sidebar__element_margin_s">
        <app-sidebar-field>
          <app-auto-field
            *ngIf="themeService.isDefaultTheme"
            [field]="
              createField({
                name: 'background_color_4',
                field: 'ColorField',
                params: {
                  classes: ['select_small', 'select_fill'],
                  allow_empty: true,
                  empty_color: placeholders.backgroundColor4,
                  default_color: placeholders.backgroundColor4,
                  custom_colors: true,
                  alpha_enabled: true,
                  prefix: 'Surfaces 3'
                }
              })
            "
            [form]="form"
            [label]="false"
          ></app-auto-field>

          <app-auto-field
            *ngIf="themeService.isDarkTheme"
            [field]="
              createField({
                name: 'background_color_4_dark',
                field: 'ColorField',
                params: {
                  classes: ['select_small', 'select_fill'],
                  allow_empty: true,
                  empty_color: placeholders.backgroundColor4Dark,
                  default_color: placeholders.backgroundColor4Dark,
                  custom_colors: true,
                  alpha_enabled: true,
                  prefix: 'Surfaces 3'
                }
              })
            "
            [form]="form"
            [label]="false"
          ></app-auto-field>

          <ng-container description>
            List cards, collapse headers, file upload
          </ng-container>
        </app-sidebar-field>
      </div>

      <div class="sidebar__element sidebar__element_margin_s">
        <app-sidebar-field>
          <app-auto-field
            *ngIf="themeService.isDefaultTheme"
            [field]="
              createField({
                name: 'background_color_5',
                field: 'ColorField',
                params: {
                  classes: ['select_small', 'select_fill'],
                  allow_empty: true,
                  empty_color: placeholders.backgroundColor5,
                  default_color: placeholders.backgroundColor5,
                  custom_colors: true,
                  alpha_enabled: true,
                  prefix: 'Surfaces 4'
                }
              })
            "
            [form]="form"
            [label]="false"
          ></app-auto-field>

          <app-auto-field
            *ngIf="themeService.isDarkTheme"
            [field]="
              createField({
                name: 'background_color_5_dark',
                field: 'ColorField',
                params: {
                  classes: ['select_small', 'select_fill'],
                  allow_empty: true,
                  empty_color: placeholders.backgroundColor5Dark,
                  default_color: placeholders.backgroundColor5Dark,
                  custom_colors: true,
                  alpha_enabled: true,
                  prefix: 'Surfaces 4'
                }
              })
            "
            [form]="form"
            [label]="false"
          ></app-auto-field>

          <ng-container description>
            Component build-in buttons background
          </ng-container>
        </app-sidebar-field>
      </div>
    </app-sidebar-card>
  </app-sidebar-field>
</div>

<div class="sidebar__element">
  <app-sidebar-field [label]="'Text colors'">
    <ng-container actions-right>
      <div class="button-group" [style.margin]="'-5px 0'">
        <div
          class="button-group__item"
          [class.button-group__item_active]="themeService.isDefaultTheme"
          (click)="themeService.theme = 'default'"
        >
          <span class="button-group__item-icon icon-sun"></span>
        </div>

        <div
          class="button-group__item"
          [class.button-group__item_active]="themeService.isDarkTheme"
          (click)="themeService.theme = 'dark'"
        >
          <span class="button-group__item-icon icon-moon"></span>
        </div>
      </div>
    </ng-container>

    <app-sidebar-card>
      <div class="sidebar__element sidebar__element_margin_s">
        <app-sidebar-field>
          <app-auto-field
            *ngIf="themeService.isDefaultTheme"
            [field]="
              createField({
                name: 'text_color',
                field: 'ColorField',
                params: {
                  classes: ['select_small', 'select_fill'],
                  allow_empty: true,
                  empty_color: placeholders.textColor,
                  default_color: placeholders.textColor,
                  custom_colors: true,
                  alpha_enabled: true,
                  prefix: 'Headings'
                }
              })
            "
            [form]="form"
            [label]="false"
          ></app-auto-field>

          <app-auto-field
            *ngIf="themeService.isDarkTheme"
            [field]="
              createField({
                name: 'text_color_dark',
                field: 'ColorField',
                params: {
                  classes: ['select_small', 'select_fill'],
                  allow_empty: true,
                  empty_color: placeholders.textColorDark,
                  default_color: placeholders.textColorDark,
                  custom_colors: true,
                  alpha_enabled: true,
                  prefix: 'Headings'
                }
              })
            "
            [form]="form"
            [label]="false"
          ></app-auto-field>
        </app-sidebar-field>
      </div>

      <div class="sidebar__element sidebar__element_margin_s">
        <app-sidebar-field>
          <app-auto-field
            *ngIf="themeService.isDefaultTheme"
            [field]="
              createField({
                name: 'text_color_2',
                field: 'ColorField',
                params: {
                  classes: ['select_small', 'select_fill'],
                  allow_empty: true,
                  empty_color: placeholders.textColor2,
                  default_color: placeholders.textColor2,
                  custom_colors: true,
                  alpha_enabled: true,
                  prefix: 'Regular'
                }
              })
            "
            [form]="form"
            [label]="false"
          ></app-auto-field>

          <app-auto-field
            *ngIf="themeService.isDarkTheme"
            [field]="
              createField({
                name: 'text_color_2_dark',
                field: 'ColorField',
                params: {
                  classes: ['select_small', 'select_fill'],
                  allow_empty: true,
                  empty_color: placeholders.textColor2Dark,
                  default_color: placeholders.textColor2Dark,
                  custom_colors: true,
                  alpha_enabled: true,
                  prefix: 'Regular'
                }
              })
            "
            [form]="form"
            [label]="false"
          ></app-auto-field>
        </app-sidebar-field>
      </div>

      <div class="sidebar__element sidebar__element_margin_s">
        <app-sidebar-field>
          <app-auto-field
            *ngIf="themeService.isDefaultTheme"
            [field]="
              createField({
                name: 'text_color_3',
                field: 'ColorField',
                params: {
                  classes: ['select_small', 'select_fill'],
                  allow_empty: true,
                  empty_color: placeholders.textColor3,
                  default_color: placeholders.textColor3,
                  custom_colors: true,
                  alpha_enabled: true,
                  prefix: 'Secondary'
                }
              })
            "
            [form]="form"
            [label]="false"
          ></app-auto-field>

          <app-auto-field
            *ngIf="themeService.isDarkTheme"
            [field]="
              createField({
                name: 'text_color_3_dark',
                field: 'ColorField',
                params: {
                  classes: ['select_small', 'select_fill'],
                  allow_empty: true,
                  empty_color: placeholders.textColor3Dark,
                  default_color: placeholders.textColor3Dark,
                  custom_colors: true,
                  alpha_enabled: true,
                  prefix: 'Secondary'
                }
              })
            "
            [form]="form"
            [label]="false"
          ></app-auto-field>
        </app-sidebar-field>
      </div>
    </app-sidebar-card>
  </app-sidebar-field>
</div>

<div class="sidebar__element">
  <app-sidebar-field [label]="'Border colors'">
    <ng-container actions-right>
      <div class="button-group" [style.margin]="'-5px 0'">
        <div
          class="button-group__item"
          [class.button-group__item_active]="themeService.isDefaultTheme"
          (click)="themeService.theme = 'default'"
        >
          <span class="button-group__item-icon icon-sun"></span>
        </div>

        <div
          class="button-group__item"
          [class.button-group__item_active]="themeService.isDarkTheme"
          (click)="themeService.theme = 'dark'"
        >
          <span class="button-group__item-icon icon-moon"></span>
        </div>
      </div>
    </ng-container>

    <app-sidebar-card>
      <div class="sidebar__element sidebar__element_margin_s">
        <app-sidebar-field>
          <app-auto-field
            *ngIf="themeService.isDefaultTheme"
            [field]="
              createField({
                name: 'border_color_2',
                field: 'ColorField',
                params: {
                  classes: ['select_small', 'select_fill'],
                  allow_empty: true,
                  empty_color: placeholders.borderColor2,
                  default_color: placeholders.borderColor2,
                  custom_colors: true,
                  alpha_enabled: true,
                  prefix: 'Primary'
                }
              })
            "
            [form]="form"
            [label]="false"
          ></app-auto-field>

          <app-auto-field
            *ngIf="themeService.isDarkTheme"
            [field]="
              createField({
                name: 'border_color_2_dark',
                field: 'ColorField',
                params: {
                  classes: ['select_small', 'select_fill'],
                  allow_empty: true,
                  empty_color: placeholders.borderColor2Dark,
                  default_color: placeholders.borderColor2Dark,
                  custom_colors: true,
                  alpha_enabled: true,
                  prefix: 'Primary'
                }
              })
            "
            [form]="form"
            [label]="false"
          ></app-auto-field>

          <ng-container description>
            Regular component borders
          </ng-container>
        </app-sidebar-field>
      </div>

      <div class="sidebar__element sidebar__element_margin_s">
        <app-sidebar-field>
          <app-auto-field
            *ngIf="themeService.isDefaultTheme"
            [field]="
              createField({
                name: 'border_color',
                field: 'ColorField',
                params: {
                  classes: ['select_small', 'select_fill'],
                  allow_empty: true,
                  empty_color: placeholders.borderColor,
                  default_color: placeholders.borderColor,
                  custom_colors: true,
                  alpha_enabled: true,
                  prefix: 'Secondary'
                }
              })
            "
            [form]="form"
            [label]="false"
          ></app-auto-field>

          <app-auto-field
            *ngIf="themeService.isDarkTheme"
            [field]="
              createField({
                name: 'border_color_dark',
                field: 'ColorField',
                params: {
                  classes: ['select_small', 'select_fill'],
                  allow_empty: true,
                  empty_color: placeholders.borderColorDark,
                  default_color: placeholders.borderColorDark,
                  custom_colors: true,
                  alpha_enabled: true,
                  prefix: 'Secondary'
                }
              })
            "
            [form]="form"
            [label]="false"
          ></app-auto-field>

          <ng-container description>
            Input borders
          </ng-container>
        </app-sidebar-field>
      </div>

      <div class="sidebar__element sidebar__element_margin_s">
        <app-sidebar-field>
          <app-auto-field
            *ngIf="themeService.isDefaultTheme"
            [field]="
              createField({
                name: 'border_color_3',
                field: 'ColorField',
                params: {
                  classes: ['select_small', 'select_fill'],
                  allow_empty: true,
                  empty_color: placeholders.borderColor3,
                  default_color: placeholders.borderColor3,
                  custom_colors: true,
                  alpha_enabled: true,
                  prefix: 'Bright'
                }
              })
            "
            [form]="form"
            [label]="false"
          ></app-auto-field>

          <app-auto-field
            *ngIf="themeService.isDarkTheme"
            [field]="
              createField({
                name: 'border_color_3_dark',
                field: 'ColorField',
                params: {
                  classes: ['select_small', 'select_fill'],
                  allow_empty: true,
                  empty_color: placeholders.borderColor3Dark,
                  default_color: placeholders.borderColor3Dark,
                  custom_colors: true,
                  alpha_enabled: true,
                  prefix: 'Bright'
                }
              })
            "
            [form]="form"
            [label]="false"
          ></app-auto-field>

          <ng-container description>
            Focus borders
          </ng-container>
        </app-sidebar-field>
      </div>
    </app-sidebar-card>
  </app-sidebar-field>
</div>
