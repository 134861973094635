import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';
import { first } from 'rxjs/operators';
import { ProjectSettingsStore } from '@modules/all-project-settings';
import { createFormFieldFactory, NumberFieldType } from '@modules/fields';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { getFontFamilyVariable } from '@modules/styles';
import { globalFontOptions, ThemeService } from '@modules/theme';
import { isSet } from '@shared';
import { TextStyleControl } from './text-style.control';
var TextStyleEditComponent = /** @class */ (function () {
    function TextStyleEditComponent(currentProjectStore, currentEnvironmentStore, projectSettingsStore, themeService, cd) {
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.projectSettingsStore = projectSettingsStore;
        this.themeService = themeService;
        this.cd = cd;
        this.resetEnabled = true;
        this.createField = createFormFieldFactory();
        this.loadingDefault = true;
        this.loadingDefaultSave = false;
        this.isStyleDefault = false;
        this.defaultPlaceholders = {
            color: undefined,
            colorDark: undefined,
            fontFamily: '',
            fontType: '',
            fontSize: '',
            letterSpacing: ''
        };
        this.numberFieldTypes = NumberFieldType;
    }
    TextStyleEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.fontTypeOptions$ = this.control.getFontTypeOptions$();
        this.control
            .isDefaultStyle$()
            .pipe(untilDestroyed(this))
            .subscribe(function (value) {
            _this.isStyleDefault = value;
            _this.loadingDefault = false;
            _this.cd.markForCheck();
        });
        combineLatest(this.control.getStyleDefault$(false), this.projectSettingsStore.getAllSettingsFirst$())
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var value = _a[0], projectSettings = _a[1];
            var defaultStyle = value || _this.control.defaultValues;
            var defaultFontFamilyVariable = getFontFamilyVariable(defaultStyle.fontFamily);
            var defaultFontFamilyGlobal = isSet(defaultFontFamilyVariable)
                ? globalFontOptions.find(function (item) { return item.value == "--" + defaultFontFamilyVariable; })
                : undefined;
            var defaultFontName = defaultFontFamilyGlobal ? defaultFontFamilyGlobal.name : defaultStyle.fontFamily;
            var defaultFontTypes = _this.control.getFontTypeOptionsForValue(defaultFontName, projectSettings);
            var defaultFontTypeValue = { weight: defaultStyle.fontWeight, style: defaultStyle.fontStyle };
            var defaultFontType = defaultFontTypes.find(function (item) {
                return _this.control.fontTypeEquals(item.value, defaultFontTypeValue);
            });
            _this.defaultPlaceholders = {
                color: defaultStyle.color || undefined,
                colorDark: defaultStyle.colorDark || undefined,
                fontFamily: defaultFontName || '',
                fontType: defaultFontType ? defaultFontType.name : '',
                fontSize: String(defaultStyle.fontSize || 12),
                letterSpacing: String(defaultStyle.letterSpacing || 0)
            };
        });
    };
    TextStyleEditComponent.prototype.ngOnDestroy = function () { };
    // saveAsDefaults() {
    //   const style = this.control.getInstance();
    //   const updateProjectSettings = new AllProjectSettings({ [this.control.options.globalSetting]: style });
    //   const settingName = updateProjectSettings.settingsTypes.find(item => {
    //     return item.properties.includes(this.control.options.globalSetting);
    //   });
    //
    //   if (!settingName) {
    //     return;
    //   }
    //
    //   this.loadingDefaultSave = true;
    //   this.cd.markForCheck();
    //
    //   this.projectSettingsService
    //     .create(
    //       this.currentProjectStore.instance.uniqueName,
    //       this.currentEnvironmentStore.instance.uniqueName,
    //       updateProjectSettings.serialize([settingName.name])[0]
    //     )
    //     .pipe(
    //       tap(result => this.projectSettingsStore.updateOrAddItem(result)),
    //       untilDestroyed(this)
    //     )
    //     .subscribe(
    //       () => {
    //         this.control.resetDefaults();
    //
    //         this.loadingDefaultSave = false;
    //         this.cd.markForCheck();
    //       },
    //       () => {
    //         this.loadingDefaultSave = false;
    //         this.cd.markForCheck();
    //       }
    //     );
    // }
    TextStyleEditComponent.prototype.resetToDefaults = function () {
        this.control.resetDefaults();
    };
    TextStyleEditComponent.prototype.onFontSelect = function (value) {
        var _this = this;
        this.projectSettingsStore
            .getAllSettingsFirst$()
            .pipe(first(), untilDestroyed(this))
            .subscribe(function (projectSettings) {
            var fontTypes = _this.control.getFontTypeOptionsForValue(value, projectSettings);
            var fontTypeValue = _this.control.controls.font_type.value;
            var fontType = fontTypes.find(function (item) { return _this.control.fontTypeEquals(item.value, fontTypeValue); });
            if (!fontType && fontTypes.length) {
                _this.control.controls.font_type.patchValue(fontTypes[0].value);
            }
        });
    };
    return TextStyleEditComponent;
}());
export { TextStyleEditComponent };
