<div class="list" [class.list_scrollable]="scrollable" [class.list_theme]="theme">
  <div class="list__header list__header_active inactive" #header_element>
    <div class="list-header">
      <div class="list-header__row">
        <div class="list-header__main">
          <h1 class="list__title">
            <ng-container *ngIf="title">
              {{ title }}
            </ng-container>

            <span *ngIf="!title" [class.loading-animation]="animating">
              <span [class.stub-text]="textStub">
                Model Description
              </span>
            </span>
          </h1>
        </div>
        <div class="list-header__right list__actions">
          <div class="input-icon-wrapper input-icon-wrapper_inline">
            <span
              class="input-icon input-icon_toolbar input-icon_left icon-search"
              [class.input-icon_theme]="theme"
            ></span>
            <input
              class="input input_stub input_toolbar input_icon-left"
              [class.input_theme]="theme"
              [class.input_styles]="theme"
              [class.loading-animation]="animating"
              type="text"
              value="Search records..."
              [appFieldElementStyles]="fieldElementStyles"
              [appFieldElementStylesApplyGlobal]="stylesApplyGlobal"
            />
          </div>

          <span
            class="button button_styles list__actions-item"
            [class.button_theme]="theme"
            [class.button_orange]="orange"
            [appButtonTintColor]="'accentColor' | appThemeColorHexOption: true | async"
            [appButtonTintColorStyle]="tintStyles.Default"
            [appActionElementStyles]="actionElementStylesDefault"
            [appActionElementStylesApplyGlobal]="stylesApplyGlobal"
            [appActionElementStylesTintColorStyle]="tintStyles.Default"
          >
            <span class="button__label">
              <span [class.loading-animation]="animating"><span [class.stub-text]="textStub">Filter</span></span>
            </span>
          </span>
          <span
            class="button button_primary button_styles list__actions-item"
            [class.button_orange]="orange"
            [class.button_theme]="theme"
            [appButtonTintColor]="'accentColor' | appThemeColorHexOption: true | async"
            [appButtonTintColorStyle]="tintStyles.Primary"
            [appActionElementStyles]="actionElementStylesPrimary"
            [appActionElementStylesApplyGlobal]="stylesApplyGlobal"
            [appActionElementStylesTintColorStyle]="tintStyles.Primary"
          >
            <span class="button__label">
              <span [class.loading-animation]="animating"><span [class.stub-text]="textStub">Create</span></span>
            </span>
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="list__content list__content_border list__viewport list__viewport_scrollable">
    <app-table-stub-content
      [data]="data"
      [rowActions]="rowActions"
      [rowActionsButtons]="rowActionsButtons"
      [selection]="selection"
      [textStub]="textStub"
      [animating]="animating"
      [scrollable]="scrollable"
      [theme]="theme"
      [actionElementStylesPrimary]="actionElementStylesPrimary"
      [actionElementStylesDefault]="actionElementStylesDefault"
      [stylesApplyGlobal]="stylesApplyGlobal"
    ></app-table-stub-content>
  </div>
</div>
