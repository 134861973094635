import { Injector } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { FillSettingsControl } from '@modules/colors-components';
import {
  BorderSettingsControl,
  CornersControl,
  FieldElementStyles,
  MarginControl,
  ShadowControl
} from '@modules/customize';
import { controlValue } from '@shared';

import { TextStyleControl } from '../text-style-edit/text-style.control';

export class FieldElementStylesControl extends FormGroup {
  controls: {
    text_style: TextStyleControl;
    placeholder_style: TextStyleControl;
    label_style: TextStyleControl;
    label_additional_style: TextStyleControl;
    fill_settings: FillSettingsControl;
    border_settings: BorderSettingsControl;
    border_radius: CornersControl;
    shadow: ShadowControl;
    padding: MarginControl;
    margin: MarginControl;

    hover_text_style: TextStyleControl;
    hover_placeholder_style: TextStyleControl;
    hover_fill_settings: FillSettingsControl;
    hover_border_settings: BorderSettingsControl;
    hover_shadow: ShadowControl;

    focus_text_style: TextStyleControl;
    focus_placeholder_style: TextStyleControl;
    focus_fill_settings: FillSettingsControl;
    focus_border_settings: BorderSettingsControl;
    focus_shadow: ShadowControl;

    error_text_style: TextStyleControl;
    error_placeholder_style: TextStyleControl;
    error_fill_settings: FillSettingsControl;
    error_border_settings: BorderSettingsControl;
    error_shadow: ShadowControl;
  };

  constructor(private injector: Injector) {
    super({
      text_style: TextStyleControl.inject(injector, {
        global: 'fieldTextStyle',
        colorAlphaEnabled: true
      }),
      placeholder_style: TextStyleControl.inject(injector, {
        global: 'fieldPlaceholderTextStyle',
        colorAlphaEnabled: true
      }),
      label_style: TextStyleControl.inject(injector, {
        global: 'fieldLabelTextStyle',
        colorAlphaEnabled: true
      }),
      label_additional_style: TextStyleControl.inject(injector, {
        global: 'fieldLabelAdditionalTextStyle',
        colorAlphaEnabled: true
      }),
      fill_settings: new FillSettingsControl(),
      border_settings: new BorderSettingsControl(),
      border_radius: new CornersControl(),
      shadow: new ShadowControl(),
      padding: new MarginControl(),
      margin: new MarginControl(),

      hover_text_style: TextStyleControl.inject(injector, {
        global: 'hoverFieldTextStyle',
        colorAlphaEnabled: true
      }),
      hover_placeholder_style: TextStyleControl.inject(injector, {
        global: 'hoverFieldPlaceholderTextStyle',
        colorAlphaEnabled: true
      }),
      hover_fill_settings: new FillSettingsControl(),
      hover_border_settings: new BorderSettingsControl(),
      hover_shadow: new ShadowControl(),

      focus_text_style: TextStyleControl.inject(injector, {
        global: 'focusFieldTextStyle',
        colorAlphaEnabled: true
      }),
      focus_placeholder_style: TextStyleControl.inject(injector, {
        global: 'focusFieldPlaceholderTextStyle',
        colorAlphaEnabled: true
      }),
      focus_fill_settings: new FillSettingsControl(),
      focus_border_settings: new BorderSettingsControl(),
      focus_shadow: new ShadowControl(),

      error_text_style: TextStyleControl.inject(injector, {
        global: 'errorFieldTextStyle',
        colorAlphaEnabled: true
      }),
      error_placeholder_style: TextStyleControl.inject(injector, {
        global: 'errorFieldPlaceholderTextStyle',
        colorAlphaEnabled: true
      }),
      error_fill_settings: new FillSettingsControl(),
      error_border_settings: new BorderSettingsControl(),
      error_shadow: new ShadowControl()
    });
  }

  deserialize(instance?: FieldElementStyles) {
    this.controls.text_style.deserialize(instance ? instance.textStyle : undefined);
    this.controls.placeholder_style.deserialize(instance ? instance.placeholderStyle : undefined);
    this.controls.label_style.deserialize(instance ? instance.labelStyle : undefined);
    this.controls.label_additional_style.deserialize(instance ? instance.labelAdditionalStyle : undefined);
    this.controls.fill_settings.deserialize(instance ? instance.fillSettings : undefined);
    this.controls.border_settings.deserialize(instance ? instance.borderSettings : undefined);
    this.controls.border_radius.deserialize(instance ? instance.borderRadius : undefined);
    this.controls.shadow.deserialize(instance ? instance.shadow : undefined);
    this.controls.padding.deserialize(instance ? instance.padding : undefined);
    this.controls.margin.deserialize(instance ? instance.margin : undefined);

    this.controls.hover_text_style.deserialize(instance ? instance.hoverTextStyle : undefined);
    this.controls.hover_placeholder_style.deserialize(instance ? instance.hoverPlaceholderStyle : undefined);
    this.controls.hover_fill_settings.deserialize(instance ? instance.hoverFillSettings : undefined);
    this.controls.hover_border_settings.deserialize(instance ? instance.hoverBorderSettings : undefined);
    this.controls.hover_shadow.deserialize(instance ? instance.hoverShadow : undefined);

    this.controls.focus_text_style.deserialize(instance ? instance.focusTextStyle : undefined);
    this.controls.focus_placeholder_style.deserialize(instance ? instance.focusPlaceholderStyle : undefined);
    this.controls.focus_fill_settings.deserialize(instance ? instance.focusFillSettings : undefined);
    this.controls.focus_border_settings.deserialize(instance ? instance.focusBorderSettings : undefined);
    this.controls.focus_shadow.deserialize(instance ? instance.focusShadow : undefined);

    this.controls.error_text_style.deserialize(instance ? instance.errorTextStyle : undefined);
    this.controls.error_placeholder_style.deserialize(instance ? instance.errorPlaceholderStyle : undefined);
    this.controls.error_fill_settings.deserialize(instance ? instance.errorFillSettings : undefined);
    this.controls.error_border_settings.deserialize(instance ? instance.errorBorderSettings : undefined);
    this.controls.error_shadow.deserialize(instance ? instance.errorShadow : undefined);
  }

  isSet(): boolean {
    return [
      this.controls.text_style,
      this.controls.placeholder_style,
      this.controls.label_style,
      this.controls.label_additional_style,
      this.controls.fill_settings,
      this.controls.border_settings,
      this.controls.border_radius,
      this.controls.shadow,
      this.controls.padding,
      this.controls.margin,

      this.controls.hover_text_style,
      this.controls.hover_placeholder_style,
      this.controls.hover_fill_settings,
      this.controls.hover_border_settings,
      this.controls.hover_shadow,

      this.controls.focus_text_style,
      this.controls.focus_placeholder_style,
      this.controls.focus_fill_settings,
      this.controls.focus_border_settings,
      this.controls.focus_shadow,

      this.controls.error_text_style,
      this.controls.error_placeholder_style,
      this.controls.error_fill_settings,
      this.controls.error_border_settings,
      this.controls.error_shadow
    ].some(control => control.isSet());
  }

  stylesDefaultUpdated$(): Observable<void> {
    return combineLatest([
      this.controls.text_style.getStyleDefault$(),
      this.controls.placeholder_style.getStyleDefault$(),
      this.controls.label_style.getStyleDefault$(),
      this.controls.label_additional_style.getStyleDefault$(),
      this.controls.hover_text_style.getStyleDefault$(),
      this.controls.hover_placeholder_style.getStyleDefault$(),
      this.controls.focus_text_style.getStyleDefault$(),
      this.controls.focus_placeholder_style.getStyleDefault$(),
      this.controls.error_text_style.getStyleDefault$(),
      this.controls.error_placeholder_style.getStyleDefault$()
    ]).pipe(map(() => undefined));
  }

  serialize(): FieldElementStyles {
    if (!this.isSet()) {
      return;
    }

    return new FieldElementStyles({
      textStyle: this.controls.text_style.serialize(false),
      placeholderStyle: this.controls.placeholder_style.serialize(false),
      labelStyle: this.controls.label_style.serialize(false),
      labelAdditionalStyle: this.controls.label_additional_style.serialize(false),
      fillSettings: this.controls.fill_settings.serialize(false),
      borderSettings: this.controls.border_settings.serialize(false),
      borderRadius: this.controls.border_radius.serialize(),
      shadow: this.controls.shadow.serialize(false),
      padding: this.controls.padding.serialize(),
      margin: this.controls.margin.serialize(),

      hoverTextStyle: this.controls.hover_text_style.serialize(false),
      hoverPlaceholderStyle: this.controls.hover_placeholder_style.serialize(false),
      hoverFillSettings: this.controls.hover_fill_settings.serialize(false),
      hoverBorderSettings: this.controls.hover_border_settings.serialize(false),
      hoverShadow: this.controls.hover_shadow.serialize(false),

      focusTextStyle: this.controls.focus_text_style.serialize(false),
      focusPlaceholderStyle: this.controls.focus_placeholder_style.serialize(false),
      focusFillSettings: this.controls.focus_fill_settings.serialize(false),
      focusBorderSettings: this.controls.focus_border_settings.serialize(false),
      focusShadow: this.controls.focus_shadow.serialize(false),

      errorTextStyle: this.controls.error_text_style.serialize(false),
      errorPlaceholderStyle: this.controls.error_placeholder_style.serialize(false),
      errorFillSettings: this.controls.error_fill_settings.serialize(false),
      errorBorderSettings: this.controls.error_border_settings.serialize(false),
      errorShadow: this.controls.error_shadow.serialize(false)
    });
  }

  serialize$(): Observable<FieldElementStyles> {
    return controlValue(this, { debounce: 200 }).pipe(map(() => this.serialize()));
  }
}
