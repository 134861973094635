var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormControl, FormGroup } from '@angular/forms';
import { ListGroup } from '@modules/customize';
import { Input } from '@modules/fields';
import { FieldInputControl } from '@modules/parameters';
import { isSet } from '@shared';
var ListGroupControl = /** @class */ (function (_super) {
    __extends(ListGroupControl, _super);
    function ListGroupControl(value) {
        return _super.call(this, {
            value: new FormControl(value && isSet(value.value) ? value.value : ''),
            name: new FormControl(value && isSet(value.name) ? value.name : ''),
            color: new FormControl(value && isSet(value.color) ? value.color : ''),
            opened_initial: new FormControl(value && isSet(value.openedInitial) ? value.openedInitial : true),
            visible_input: new FieldInputControl(value && value.visibleInput ? value.visibleInput.serialize() : { path: ['value'] })
        }) || this;
    }
    ListGroupControl.prototype.deserialize = function (value) {
        this.instance = value;
        this.controls.value.patchValue(value.value);
        this.controls.name.patchValue(value.name);
        this.controls.color.patchValue(value.color);
        this.controls.opened_initial.patchValue(value.openedInitial);
        this.controls.visible_input.patchValue(value.visibleInput ? value.visibleInput.serializeWithoutPath() : {});
    };
    ListGroupControl.prototype.serialize = function () {
        if (!this.instance) {
            this.instance = new ListGroup();
            this.instance.generateUid();
        }
        var result = this.instance;
        result.value = this.controls.value.value;
        result.name = this.controls.name.value;
        result.color = this.controls.color.value;
        result.openedInitial = this.controls.opened_initial.value;
        if (this.controls.visible_input.value) {
            result.visibleInput = new Input().deserialize(this.controls.visible_input.value);
        }
        else {
            result.visibleInput = undefined;
        }
        return result;
    };
    return ListGroupControl;
}(FormGroup));
export { ListGroupControl };
