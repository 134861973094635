var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { AfterViewChecked, ChangeDetectorRef, ElementRef, Injector, OnChanges, OnDestroy, OnInit, QueryList, SimpleChanges } from '@angular/core';
import * as Color from 'color';
import isArray from 'lodash/isArray';
import isEqual from 'lodash/isEqual';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, of } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { SelectSegment } from '@common/select';
import { ElementActionsPosition, ViewContext } from '@modules/customize';
import { applyParamInputs, deserializeDisplayField, FieldType, Input, MultipleSelectStyle, OptionsType, ParameterField, registerFieldComponent } from '@modules/fields';
import { ModelDescriptionStore, ModelService, ModelUtilsService, ReducedModelService } from '@modules/model-queries';
import { ModelSelectSource } from '@modules/models-list';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { ListModelDescriptionQuery, ModelDescriptionQuery, QueryType } from '@modules/queries';
import { ResourceControllerService } from '@modules/resources';
import { coerceArray, isSet } from '@shared';
import { optionFromGxOption } from '../../utils/select';
import { FieldComponent } from '../field/field.component';
var MultipleSelectFieldComponent = /** @class */ (function (_super) {
    __extends(MultipleSelectFieldComponent, _super);
    function MultipleSelectFieldComponent(currentEnvironmentStore, modelDescriptionStore, resourceControllerService, injector, cd) {
        var _this = _super.call(this) || this;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        _this.modelDescriptionStore = modelDescriptionStore;
        _this.resourceControllerService = resourceControllerService;
        _this.injector = injector;
        _this.cd = cd;
        _this.listItemElements = new QueryList();
        _this.firstVisible = false;
        _this.params = {
            readonly: false,
            parameters: [],
            inputs: [],
            options: []
        };
        _this.staticOptions$ = new BehaviorSubject([]);
        _this.selectedOptions$ = new BehaviorSubject([]);
        _this.selectedOptions = [];
        _this.selectedOptionsLoading = false;
        _this.topActions = [];
        _this.bottomActions = [];
        _this.styles = MultipleSelectStyle;
        if (_this.modelDescriptionStore) {
            _this.modelSelectSource = _this.createModelSelectSource();
        }
        return _this;
    }
    MultipleSelectFieldComponent.prototype.createModelSelectSource = function () {
        return Injector.create({
            providers: [
                {
                    provide: ModelSelectSource,
                    deps: [
                        ModelService,
                        ReducedModelService,
                        ModelDescriptionStore,
                        CurrentProjectStore,
                        CurrentEnvironmentStore,
                        Injector,
                        ModelUtilsService
                    ]
                }
            ],
            parent: this.injector
        }).get(ModelSelectSource);
    };
    MultipleSelectFieldComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.checkChanges();
        this.selectedOptions$.pipe(untilDestroyed(this)).subscribe(function (value) {
            _this.selectedOptions = value;
            _this.cd.detectChanges();
        });
    };
    MultipleSelectFieldComponent.prototype.ngOnDestroy = function () { };
    MultipleSelectFieldComponent.prototype.ngOnChanges = function (changes) {
        if (changes['field']) {
            this.checkChanges();
        }
    };
    MultipleSelectFieldComponent.prototype.ngAfterViewChecked = function () {
        if (this.firstVisible && this.listElement) {
            var clipListItems = this.getClipListItems();
            if (this.clipListItems !== clipListItems) {
                this.clipListItems = clipListItems;
                this.cd.detectChanges();
            }
        }
    };
    MultipleSelectFieldComponent.prototype.getClipListItems = function () {
        var _this = this;
        if (this.listElement.nativeElement.scrollWidth > this.listElement.nativeElement.clientWidth) {
            var total_1 = 0;
            var clipListItems_1;
            this.listItemElements.forEach(function (item, i) {
                if (clipListItems_1 !== undefined) {
                    return;
                }
                var margin = i > 0 ? 2 * 2 : 0;
                var itemWidth = margin + item.nativeElement.offsetWidth;
                var counterWidth = margin + 40;
                var checkWidth = i == _this.listItemElements.length - 1 ? total_1 + itemWidth : total_1 + itemWidth + counterWidth;
                if (i == 0 || checkWidth <= _this.listElement.nativeElement.clientWidth) {
                    total_1 += itemWidth;
                }
                else {
                    clipListItems_1 = i;
                    return false;
                }
            });
            return clipListItems_1;
        }
    };
    MultipleSelectFieldComponent.prototype.onFirstVisible = function () {
        this.firstVisible = true;
        this.checkChanges();
    };
    MultipleSelectFieldComponent.prototype.checkChanges = function () {
        var _this = this;
        if (!this.firstVisible) {
            return;
        }
        var prevParams = this.params;
        var optionsType = this.field.params['options_type'] || OptionsType.Static;
        var query = this.field.params['query']
            ? new ListModelDescriptionQuery().deserialize(this.field.params['query'])
            : undefined;
        var parameters = this.field.params['parameters']
            ? this.field.params['parameters'].map(function (item) { return new ParameterField().deserialize(item); })
            : [];
        var inputs = this.field.params['inputs']
            ? this.field.params['inputs'].map(function (item) { return new Input().deserialize(item); })
            : [];
        var params = __assign({ readonly: this.readonly, optionsType: optionsType }, (optionsType == OptionsType.Static && {
            options: this.field.params['options'] || []
        }), (optionsType == OptionsType.Query && {
            resource: this.field.params['resource'],
            query: query,
            columns: this.field.params['columns']
                ? this.field.params['columns'].map(function (item) { return deserializeDisplayField(item); })
                : undefined,
            parameters: parameters,
            inputs: inputs,
            sortingField: this.field.params['sorting_field'],
            sortingAsc: this.field.params['sorting_asc'],
            valueField: this.field.params['value_field'],
            labelField: this.field.params['label_field'],
            labelInput: this.field.params['label_field_input']
                ? new Input().deserialize(this.field.params['label_field_input'])
                : undefined,
            subtitleField: this.field.params['subtitle_field'],
            subtitleInput: this.field.params['subtitle_input']
                ? new Input().deserialize(this.field.params['subtitle_input'])
                : undefined,
            iconField: this.field.params['icon_field'],
            iconInput: this.field.params['icon_input']
                ? new Input().deserialize(this.field.params['icon_input'])
                : undefined,
            colorField: this.field.params['color_field'],
            colorInput: this.field.params['color_input']
                ? new Input().deserialize(this.field.params['color_input'])
                : undefined
        }));
        if (this.updateOptionsSubscription) {
            this.updateOptionsSubscription.unsubscribe();
        }
        this.updateOptionsSubscription = this.applyParamsDefaults(params)
            .pipe(filter(function (newParams) { return !isEqual(newParams, prevParams); }), switchMap(function (newParams) {
            _this.params = newParams;
            if (optionsType == OptionsType.Query && _this.context instanceof ViewContext) {
                return _this.context.outputValues$.pipe(debounceTime(10), map(function () { return applyParamInputs({}, inputs, { context: _this.context, parameters: parameters }); }));
            }
            else {
                return of(undefined);
            }
        }), distinctUntilChanged(function (lhs, rhs) { return isEqual(lhs, rhs); }), untilDestroyed(this))
            .subscribe(function (contextParams) {
            _this.init(contextParams);
            _this.updateActions();
        });
    };
    MultipleSelectFieldComponent.prototype.init = function (contextParams) {
        var _this = this;
        if (this.params.optionsType == OptionsType.Static) {
            this.source = undefined;
            this.staticOptions$.next(this.params.options);
            // TODO: Workaround for options/source not passed
            this.cd.detectChanges();
        }
        else if (this.params.optionsType == OptionsType.Query) {
            if (this.modelSelectSource) {
                this.modelSelectSource.init({
                    resource: this.params.resource,
                    query: this.params.query,
                    queryParameters: this.params.parameters || [],
                    detailQuery: this.params.detailQuery,
                    detailQueryParameters: this.params.detailParameters || [],
                    columns: this.params.columns,
                    valueField: this.params.valueField,
                    nameField: this.params.labelField,
                    nameInput: this.params.labelInput,
                    subtitleField: this.params.subtitleField,
                    subtitleInput: this.params.subtitleInput,
                    iconField: this.params.iconField,
                    iconInput: this.params.iconInput,
                    colorField: this.params.colorField,
                    colorInput: this.params.colorInput,
                    params: contextParams,
                    sortingField: this.params.sortingField,
                    sortingAsc: this.params.sortingAsc,
                    multiple: true,
                    context: this.context,
                    contextElement: this.contextElement
                });
                this.modelSelectSource.reset();
            }
            this.source = this.modelSelectSource;
            this.staticOptions$.next([]);
            this.cd.markForCheck();
        }
        if (this.updateSelectedOptionsOnChangeSubscription) {
            this.updateSelectedOptionsOnChangeSubscription.unsubscribe();
            this.updateSelectedOptionsOnChangeSubscription = undefined;
        }
        if (this.control) {
            this.updateSelectedOptionsOnChangeSubscription = this.control.valueChanges
                .pipe(delay(0), untilDestroyed(this))
                .subscribe(function () { return _this.updateValueOption(); });
        }
        this.updateValueOption();
    };
    MultipleSelectFieldComponent.prototype.updateValueOption = function () {
        var _this = this;
        if (this.updateSelectedOptionsSubscription) {
            this.updateSelectedOptionsSubscription.unsubscribe();
            this.updateSelectedOptionsSubscription = undefined;
        }
        if (!this.params.readonly) {
            return;
        }
        var currentValue = coerceArray(this.currentValue);
        if (this.params.optionsType == OptionsType.Static) {
            var option = this.staticOptions$.value.filter(function (item) { return currentValue.find(function (i) { return i == item.value; }); });
            if (!option && isSet(this.currentValue)) {
                option = [{ name: this.currentValue, value: this.currentValue }];
            }
            this.selectedOptions$.next(option);
            this.selectedOptionsLoading = false;
            this.cd.markForCheck();
        }
        else if (this.params.optionsType == OptionsType.Query && currentValue.length) {
            if (this.modelSelectSource) {
                this.selectedOptionsLoading = true;
                this.cd.markForCheck();
                this.modelSelectSource
                    .fetchByValue(this.currentValue)
                    .pipe(map(function (options) {
                    if (!options) {
                        return [];
                    }
                    var optionsArray = isArray(options) ? options : [options];
                    return optionsArray.map(function (option) { return optionFromGxOption(option); });
                }), untilDestroyed(this))
                    .subscribe(function (options) {
                    _this.selectedOptions$.next(options);
                    _this.selectedOptionsLoading = false;
                    _this.cd.markForCheck();
                }, function () {
                    _this.selectedOptionsLoading = false;
                    _this.cd.markForCheck();
                });
            }
        }
        else {
            this.selectedOptions$.next([]);
            this.selectedOptionsLoading = false;
            this.cd.markForCheck();
        }
    };
    MultipleSelectFieldComponent.prototype.applyParamsDefaults = function (params) {
        var _this = this;
        if (!params.query || params.query.queryType != QueryType.Simple || !this.modelDescriptionStore) {
            return of(params);
        }
        var modelId = [params.resource, params.query.simpleQuery.model].join('.');
        return this.modelDescriptionStore.getDetailFirst(modelId).pipe(map(function (modelDescription) {
            if (!modelDescription) {
                return params;
            }
            if (modelDescription.getParameters && params.query.queryType == QueryType.Simple) {
                params.parameters = modelDescription.getParameters;
            }
            if (modelDescription.getDetailQuery) {
                var detailQuery = new ModelDescriptionQuery();
                detailQuery.queryType = QueryType.Simple;
                detailQuery.simpleQuery = new detailQuery.simpleQueryClass();
                detailQuery.simpleQuery.model = modelDescription.model;
                var resource = _this.currentEnvironmentStore
                    ? _this.currentEnvironmentStore.resources.find(function (item) {
                        return item.uniqueName == modelDescription.resource;
                    })
                    : undefined;
                var controller = _this.resourceControllerService && resource ? _this.resourceControllerService.get(resource.type) : undefined;
                var modelParameters = controller
                    ? controller.getDetailParametersOrDefaults(resource, modelDescription)
                    : [];
                params.detailQuery = detailQuery;
                params.detailParameters = modelParameters;
            }
            return params;
        }));
    };
    MultipleSelectFieldComponent.prototype.updateActions = function () {
        var topActions = this.elementActions.find(function (item) { return item.position == ElementActionsPosition.Top; });
        var bottomActions = this.elementActions.find(function (item) { return item.position == ElementActionsPosition.Bottom; });
        this.topActions = topActions ? topActions.actions : [];
        this.bottomActions = bottomActions ? bottomActions.actions : [];
        this.cd.markForCheck();
    };
    Object.defineProperty(MultipleSelectFieldComponent.prototype, "selectFill", {
        // TODO: Move to separate params option
        get: function () {
            return this.field.params['classes'] && this.field.params['classes'].indexOf('select_fill') != -1;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MultipleSelectFieldComponent.prototype, "selectSegment", {
        // TODO: Move to separate params option
        get: function () {
            if (!this.field.params['classes']) {
                return;
            }
            if (this.field.params['classes'].indexOf('select_segment-top') != -1) {
                return SelectSegment.Top;
            }
            else if (this.field.params['classes'].indexOf('select_segment') != -1) {
                return SelectSegment.Middle;
            }
            else if (this.field.params['classes'].indexOf('select_segment-bottom') != -1) {
                return SelectSegment.Bottom;
            }
        },
        enumerable: true,
        configurable: true
    });
    MultipleSelectFieldComponent.prototype.backgroundCustomColor = function (color) {
        try {
            var clr = Color(color);
            return clr.alpha(0.1).string();
        }
        catch (e) {
            return null;
        }
    };
    MultipleSelectFieldComponent.prototype.asActions = function (value) {
        return value;
    };
    MultipleSelectFieldComponent.prototype.asClosableComponent = function (value) {
        return value;
    };
    return MultipleSelectFieldComponent;
}(FieldComponent));
export { MultipleSelectFieldComponent };
registerFieldComponent(FieldType.MultipleSelect, MultipleSelectFieldComponent);
