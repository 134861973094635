<div #sidePopupWrap class="side-popup side-popup_theme timeline-popup timeline-popup_theme">
  <div
    #sidePopup
    class="side-popup__inner side-popup__inner_timeline"
    [style.padding-bottom.px]="formHeight"
    xsScrollable
    [xsScrollableOptions]="{ stopPropagationAlways: true }"
  >
    <div #header class="side-popup__header">
      <div class="side-popup__header-inner">
        <div class="side-popup__header-top">
          <h2 class="side-popup__title">{{ 'Timeline' | localize }}</h2>

          <form class="timeline-popup__filter-form" [formGroup]="formFilter">
            <a
              href="javascript:void(0)"
              class="button button_icon button_theme"
              [appButtonTintColor]="'accentColor' | appThemeColorHexOption: true | async"
              [appButtonTintColorStyle]="tintStyles.Primary"
              style="margin: 0;"
              (click)="popoverToggle()"
            >
              <span class="icon icon-filter_2 button__icon"></span>
            </a>
            <div
              class="timeline-popup__filter-form-popover"
              [class.timeline-popup__filter-form-popover_show]="showPopover"
            >
              <div class="timeline-popup__filter-form-popover-header">
                <span class="timeline-popup__filter-form-popover-title">
                  {{ 'Filter' | localize }}
                </span>
                <span
                  #closeButton
                  (click)="popoverToggle()"
                  class="timeline-popup__filter-form-popover-close icon-close"
                ></span>
              </div>
              <div class="timeline-popup__filter-form-popover-inner">
                <label [class.timeline-popup__filter-form-popover-label_active]="formFilter.controls.messages.value">
                  <app-checkbox
                    [control]="formFilter.controls.messages"
                    [accentColor]="'accentColor' | appThemeColorHexOption: true | async"
                    [theme]="true"
                  ></app-checkbox>
                  <span class="timeline-popup__filter-form-popover-text">{{ 'Messages' | localize }}</span>
                </label>
                <label [class.timeline-popup__filter-form-popover-label_active]="formFilter.controls.activity.value">
                  <app-checkbox
                    [control]="formFilter.controls.activity"
                    [accentColor]="'accentColor' | appThemeColorHexOption: true | async"
                    [theme]="true"
                  ></app-checkbox>
                  <span class="timeline-popup__filter-form-popover-text">{{ 'Activity' | localize }}</span>
                </label>
              </div>
            </div>
          </form>
          <span (click)="close()" class="side-popup__close icon-close"></span>
        </div>
      </div>
    </div>
    <ng-container *ngIf="items !== undefined">
      <ng-container *ngFor="let item of items; trackBy: trackByFn">
        <ng-container *ngIf="item.firstDayActivity">
          <div
            class="timeline-popup__date-separator"
            #separator
            [style.top]="header.getBoundingClientRect().height + 'px'"
          >
            <div class="timeline-popup__date-separator-bg"></div>
            <div class="timeline-popup__date-separator-line"></div>
            <div class="timeline-popup__date-separator-date">{{ item.item.dateAdd | appTimeAgo: true }}</div>
          </div>
        </ng-container>
        <app-message-user-activity
          *ngIf="item.item.isMessageActivityType()"
          [item]="item.item"
        ></app-message-user-activity>
        <app-action-user-activity
          *ngIf="item.item.isActionActivityType()"
          [item]="item.item"
        ></app-action-user-activity>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="items === undefined">
      <div
        class="message-user-activity message-user-activity_theme"
        *ngFor="let item of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]"
      >
        <div class="message-user-activity__inner">
          <div class="message-user-activity__right">
            <span class="message-user-activity__author">
              <span [class.loading-animation]="!showAnimation">
                <span class="stub-text">aaaa aaaaaaa</span>
              </span>
            </span>
            <p class="message-user-activity__message">
              <span [class.loading-animation]="!showAnimation">
                <span class="stub-text">aaaa aaaaa aaaaaaaa</span>
              </span>
            </p>
            <span class="message-user-activity__date">
              <span [class.loading-animation]="!showAnimation">
                <span class="stub-text">aa aa aaaa aa aa</span>
              </span>
            </span>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="side-popup__footer side-popup__footer_timeline" #footer>
    <app-timeline-popup-footer
      [params]="params"
      [disabled]="sendMessage"
      (send)="submit($event)"
      (messageChange)="setFormBottomPadding()"
    ></app-timeline-popup-footer>
  </div>

  <div *ngIf="items?.length === 0" class="side-popup__error">
    <app-error [title]="'Start chatting with your team' | localize" [theme]="true">
      {{ 'Take a message about current page to keep track of important info.' | localize }}
      <br />
      <br />
      <button *ngIf="hasFilters()" (click)="resetFilter()" class="button button_primary button_bigger">
        {{ 'Reset Filters' | localize }}
      </button>
    </app-error>
  </div>
</div>
