var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnDestroy } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import isArray from 'lodash/isArray';
import range from 'lodash/range';
import values from 'lodash/values';
import { combineLatest, of } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged, first, map, skip, switchMap, tap } from 'rxjs/operators';
import { AggregateFunc } from '@modules/charts';
import { AUTO_OPTION_COLORS } from '@modules/colors';
import { modelFieldToDisplayField } from '@modules/customize';
import { deserializeDisplayField, getFieldDescriptionByType, Input, isRequiredInputsSet, MultipleSelectStyle, OptionsType, ParameterArray, ParameterField } from '@modules/fields';
import { ModelDescriptionStore, ModelService } from '@modules/model-queries';
import { FieldInputControl, InputFieldProvider, inputFieldProviderItemsFromModelGet, modelDescriptionHasAutoParameters, parametersToProviderItems } from '@modules/parameters';
import { CurrentEnvironmentStore, CurrentProjectStore, getResourceTypeItemRequestName, ResourceName, ResourceType, resourceTypeItems } from '@modules/projects';
import { getResourceAddModelComponents } from '@modules/projects-components';
import { editableQueryTypes, ListModelDescriptionQuery, ModelDescriptionQuery, QueryType } from '@modules/queries';
import { isResourceCustom, prepareDataSourceColumnForGet, ResourceControllerService, RestAPIResourceParams } from '@modules/resources';
import { ascComparator, controlValue, getCircleIndex, isSet, objectsSortPredicate } from '@shared';
export function validateOptions() {
    return function (control) {
        var parent = control.parent;
        if (!parent) {
            return;
        }
        var optionsType = parent.controls.options_type.value;
        if (optionsType != OptionsType.Static) {
            return;
        }
        if (!isSet(control.value) ||
            !isArray(control.value) ||
            !control.value.length ||
            control.value.some(function (item) { return !isSet(item.name); })) {
            return { required: true };
        }
    };
}
export function validateResource() {
    return function (control) {
        var parent = control.parent;
        if (!parent) {
            return;
        }
        var optionsType = parent.controls.options_type.value;
        if (optionsType != OptionsType.Query) {
            return;
        }
        if (!isSet(control.value)) {
            return { required: true };
        }
    };
}
export function validateQuery() {
    return function (control) {
        var parent = control.parent;
        if (!parent) {
            return;
        }
        var optionsType = parent.controls.options_type.value;
        if (optionsType != OptionsType.Query) {
            return;
        }
        var query = control.value;
        if (!query || !query.isConfigured()) {
            return { required: true };
        }
    };
}
export function validateResponseField() {
    return function (control) {
        var parent = control.parent;
        if (!parent) {
            return;
        }
        var optionsType = parent.controls.options_type.value;
        if (optionsType != OptionsType.Query) {
            return;
        }
        if (!isSet(control.value)) {
            return { required: true };
        }
    };
}
export function validateInputs() {
    return function (control) {
        var parent = control.parent;
        if (!parent) {
            return;
        }
        var optionsType = parent.controls.options_type.value;
        if (optionsType != OptionsType.Query) {
            return;
        }
        var fields = parent.inputFieldProvider.getFields();
        var inputs = control.value;
        if (!isRequiredInputsSet(fields, inputs)) {
            return { required: true };
        }
    };
}
var SelectFieldViewParamsForm = /** @class */ (function (_super) {
    __extends(SelectFieldViewParamsForm, _super);
    function SelectFieldViewParamsForm(modelService, modelDescriptionStore, resourceControllerService, currentProjectStore, currentEnvironmentStore) {
        var _this = _super.call(this, {
            style: new FormControl(MultipleSelectStyle.Select),
            circular: new FormControl(false),
            create_enabled: new FormControl(false),
            options_type: new FormControl(OptionsType.Static, Validators.required),
            options: new FormArray([], validateOptions()),
            resource: new FormControl('', validateResource()),
            model: new FormControl(undefined),
            query: new FormControl(undefined, validateQuery()),
            sorting_field: new FormControl(undefined),
            sorting_asc: new FormControl(true),
            value_field: new FormControl('', validateResponseField()),
            label_field: new FormControl('', validateResponseField()),
            label_input_enabled: new FormControl(false),
            label_input: new FieldInputControl({ path: ['value'] }),
            subtitle_field: new FormControl('', validateResponseField()),
            subtitle_input_enabled: new FormControl(false),
            subtitle_input: new FieldInputControl({ path: ['value'] }),
            icon_field: new FormControl(''),
            icon_input_enabled: new FormControl(false),
            icon_input: new FieldInputControl({ path: ['value'] }),
            color_field: new FormControl(''),
            color_input_enabled: new FormControl(false),
            color_input: new FieldInputControl({ path: ['value'] }),
            parameters: new ParameterArray([]),
            inputs: new FormControl([], validateInputs()),
            columns: new FormControl([])
        }) || this;
        _this.modelService = modelService;
        _this.modelDescriptionStore = modelDescriptionStore;
        _this.resourceControllerService = resourceControllerService;
        _this.currentProjectStore = currentProjectStore;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        _this.queryClass = ListModelDescriptionQuery;
        _this.inputFieldProvider = new InputFieldProvider();
        _this.styleOptions = [
            {
                style: MultipleSelectStyle.Select,
                circular: false,
                image: 'select'
            },
            {
                style: MultipleSelectStyle.Chips,
                circular: false,
                image: 'chips-rounded'
            },
            {
                style: MultipleSelectStyle.Chips,
                circular: true,
                image: 'chips-circular'
            }
        ];
        _this.optionsTypeOptions = [
            { value: OptionsType.Static, name: 'Specify options', icon: 'fileds' },
            { value: OptionsType.Query, name: 'Load options', icon: 'cloud_download' }
        ];
        return _this;
    }
    SelectFieldViewParamsForm.prototype.ngOnDestroy = function () {
        this.inputFieldProvider.clearProvider();
    };
    SelectFieldViewParamsForm.prototype.initObservers = function () {
        var _this = this;
        controlValue(this.controls.resource)
            .pipe(distinctUntilChanged(), skip(1))
            .subscribe(function (value) { return _this.onResourceChange(value); });
        combineLatest(controlValue(this.controls.resource), this.controls.model.valueChanges)
            .pipe(debounceTime(0))
            .subscribe(function (_a) {
            var resourceName = _a[0], model = _a[1];
            return _this.onModelChange(resourceName, model);
        });
        this.controls.parameters.valueChanges.subscribe(function (value) { return _this.onParametersChange(); });
        this.controls.query.valueChanges.subscribe(function (value) { return _this.onQueryChange(); });
        this.controls.options_type.valueChanges.pipe(delay(0)).subscribe(function () {
            _this.controls.options.updateValueAndValidity();
            _this.controls.resource.updateValueAndValidity();
            _this.controls.query.updateValueAndValidity();
            _this.controls.value_field.updateValueAndValidity();
            _this.controls.label_field.updateValueAndValidity();
            _this.controls.inputs.updateValueAndValidity();
        });
        this.inputFieldProvider.getFields$().subscribe(function () {
            _this.controls.inputs.updateValueAndValidity();
        });
        this.valueChanges.subscribe(function () { return _this.submit(); });
    };
    SelectFieldViewParamsForm.prototype.init = function (control, context) {
        var _this = this;
        this.control = control;
        this.context = context;
        if (control.value) {
            if (control.value['options']) {
                this.formArraySet('options', control.value['options'].map(function (item) { return _this.createOption(); }));
            }
            else {
                this.formArraySet('options', [this.createOption()]);
            }
            var query = control.value['query'] ? new this.queryClass().deserialize(control.value['query']) : undefined;
            var model = query
                ? {
                    queryType: query.queryType,
                    model: query.queryType == QueryType.Simple ? query.simpleQuery.model : undefined
                }
                : undefined;
            var parameters = control.value['parameters']
                ? control.value['parameters'].map(function (item) { return new ParameterField().deserialize(item); })
                : [];
            var inputs = control.value['inputs'] ? control.value['inputs'].map(function (item) { return new Input().deserialize(item); }) : [];
            this.controls.style.patchValue(control.value['style'] || MultipleSelectStyle.Select);
            this.controls.circular.patchValue(!!control.value['circular']);
            this.controls.create_enabled.patchValue(!!control.value['create_enabled']);
            this.controls.options_type.patchValue(control.value['options_type'] || OptionsType.Static);
            this.controls.options.patchValue(control.value['options'] || []);
            this.controls.resource.patchValue(control.value['resource']);
            this.controls.model.patchValue(model);
            this.controls.query.patchValue(query);
            this.controls.sorting_field.patchValue(control.value['sorting_field']);
            this.controls.sorting_asc.patchValue(control.value['sorting_asc']);
            this.controls.value_field.patchValue(control.value['value_field']);
            this.controls.label_field.patchValue(control.value['label_field']);
            this.controls.label_input_enabled.patchValue(!!control.value['label_field_input']);
            this.controls.label_input.patchValue(control.value['label_field_input'] ? control.value['label_field_input'] : {});
            this.controls.subtitle_field.patchValue(control.value['subtitle_field']);
            this.controls.subtitle_input_enabled.patchValue(!!control.value['subtitle_input']);
            this.controls.subtitle_input.patchValue(control.value['subtitle_input'] ? control.value['subtitle_input'] : {});
            this.controls.icon_field.patchValue(control.value['icon_field']);
            this.controls.icon_input_enabled.patchValue(!!control.value['icon_input']);
            this.controls.icon_input.patchValue(control.value['icon_input'] ? control.value['icon_input'] : {});
            this.controls.color_field.patchValue(control.value['color_field']);
            this.controls.color_input_enabled.patchValue(!!control.value['color_input']);
            this.controls.color_input.patchValue(control.value['color_input'] ? control.value['color_input'] : {});
            this.controls.parameters.patchValue(parameters);
            this.controls.inputs.patchValue(inputs);
            this.controls.columns.patchValue((control.value['columns'] || []).map(function (item) { return deserializeDisplayField(item); }));
            this.markAsPristine();
        }
        else {
            this.markAsDirty();
        }
        this.updateInputFieldProvider().subscribe();
        this.initObservers();
    };
    SelectFieldViewParamsForm.prototype.formArrayControls = function (name) {
        return this.controls[name].controls;
    };
    SelectFieldViewParamsForm.prototype.formArraySet = function (name, groups) {
        var array = this.controls[name];
        range(array.controls.length).forEach(function () { return array.removeAt(0); });
        groups.forEach(function (item) { return array.push(item); });
        return array;
    };
    SelectFieldViewParamsForm.prototype.formArrayAppend = function (name, group) {
        this.controls[name].push(group);
    };
    SelectFieldViewParamsForm.prototype.formArrayRemove = function (name, group) {
        var array = this.controls[name];
        var index = array.controls.findIndex(function (item) { return item === group; });
        array.removeAt(index);
        return array;
    };
    SelectFieldViewParamsForm.prototype.getAutoColor = function (used, index) {
        if (used === void 0) { used = []; }
        if (index === void 0) { index = 0; }
        return AUTO_OPTION_COLORS.find(function (item) { return !used.includes(item); }) || getCircleIndex(AUTO_OPTION_COLORS, index);
    };
    SelectFieldViewParamsForm.prototype.createOption = function () {
        var colors = this.controls.options.value.map(function (item) { return item['color']; });
        var autoColor = this.getAutoColor(colors);
        var group = new FormGroup({
            color: new FormControl(autoColor || ''),
            name: new FormControl('', Validators.required),
            value: new FormControl(''),
            subtitle: new FormControl(''),
            icon: new FormControl('')
        });
        group.markAsDirty();
        return group;
    };
    SelectFieldViewParamsForm.prototype.removeEmptyOptions = function () {
        var array = this.controls.options;
        while (true) {
            var group = array.controls.find(function (item) {
                return values(item.controls).every(function (control) { return !isSet(control.value); });
            });
            if (!group) {
                break;
            }
            this.formArrayRemove('options', group);
        }
    };
    SelectFieldViewParamsForm.prototype.getMissingAvailable = function () {
        return this.context && this.context['modelDescription'] != undefined && this.context['field'] != undefined;
    };
    SelectFieldViewParamsForm.prototype.getMissing = function () {
        var _this = this;
        if (!this.getMissingAvailable()) {
            return of([]);
        }
        var modelDescription = this.context['modelDescription'];
        var field = this.context['field'];
        return this.modelService
            .group(this.currentProjectStore.instance, this.currentEnvironmentStore.instance, modelDescription.modelId, [{ xColumn: field.name }], AggregateFunc.Count, modelDescription.primaryKeyField)
            .pipe(map(function (result) {
            var existingValues = _this.value['options'].map(function (item) { return item['value']; });
            return result.map(function (item) { return item.group; }).filter(function (item) { return existingValues.find(function (i) { return i == item; }) == undefined; });
        }));
    };
    SelectFieldViewParamsForm.prototype.addMissing = function (newValues) {
        var _this = this;
        this.removeEmptyOptions();
        newValues
            .filter(function (value) { return value !== undefined; })
            .forEach(function (value) {
            var option = _this.createOption();
            option.patchValue({ value: value });
            _this.formArrayAppend('options', option);
        });
    };
    SelectFieldViewParamsForm.prototype.modelValueEquals = function (lhs, rhs) {
        var lhsQueryType = lhs ? lhs.queryType : undefined;
        var lhsModelDescription = lhs && lhs.model ? lhs.model : undefined;
        var rhsQueryType = rhs ? rhs.queryType : undefined;
        var rhsModelDescription = rhs && rhs.model ? rhs.model : undefined;
        return lhsQueryType == rhsQueryType && lhsModelDescription == rhsModelDescription;
    };
    SelectFieldViewParamsForm.prototype.setModelQuery = function (model) {
        var query = new ModelDescriptionQuery();
        query.queryType = model ? model.queryType : undefined;
        if (query.queryType == QueryType.Simple) {
            if (!query.simpleQuery) {
                query.simpleQuery = new query.simpleQueryClass();
            }
            query.simpleQuery.model = model.model;
        }
        this.controls.query.patchValue(query);
        this.controls.parameters.patchValue([]);
        this.controls.inputs.patchValue([]);
    };
    SelectFieldViewParamsForm.prototype.setModelParams = function (resourceName, model) {
        var _this = this;
        var resource = this.currentEnvironmentStore.resources.find(function (item) { return item.uniqueName == resourceName; });
        var modelId = resource && model ? [resource.uniqueName, model.model].join('.') : undefined;
        this.modelDescriptionStore.getDetailFirst(modelId).subscribe(function (modelDescription) {
            var parameters = modelDescription ? modelDescription.getParameters : [];
            var columns = modelDescription
                ? modelDescription.fields
                    .map(function (item) { return modelFieldToDisplayField(item, false); })
                    .map(function (item) { return prepareDataSourceColumnForGet(resource, modelDescription, item); })
                : [];
            _this.controls.parameters.patchValue(parameters);
            _this.controls.columns.patchValue(columns);
            if (modelDescription) {
                if (modelDescription.primaryKeyField) {
                    _this.controls.value_field.patchValue(modelDescription.primaryKeyField);
                }
                var nameField = modelDescription.dbFields
                    .map(function (item) { return ({ name: item.name, pk: modelDescription.primaryKeyField == item.name }); })
                    .sort(objectsSortPredicate('pk'))[0];
                if (nameField) {
                    _this.controls.label_field.patchValue(nameField.name);
                }
            }
        });
    };
    SelectFieldViewParamsForm.prototype.toggleDefaultSorting = function () {
        var control = this.controls.sorting_asc;
        control.patchValue(!control.value);
    };
    SelectFieldViewParamsForm.prototype.updateInputFieldProvider = function () {
        var _this = this;
        return combineLatest(this.resource$(this.controls.resource), this.modelDescription$(this.controls.resource, this.controls.model), controlValue(this.controls.parameters), controlValue(this.controls.query), controlValue(this.controls.columns)).pipe(first(), map(function (_a) {
            var resource = _a[0], modelDescription = _a[1], parameters = _a[2], query = _a[3], columns = _a[4];
            return parametersToProviderItems(parameters).concat(inputFieldProviderItemsFromModelGet(resource, modelDescription, query, columns));
        }), tap(function (items) {
            _this.inputFieldProvider.setProvider(items, true);
        }));
    };
    SelectFieldViewParamsForm.prototype.resource$ = function (control) {
        var _this = this;
        return controlValue(control).pipe(map(function (value) { return _this.currentEnvironmentStore.resources.find(function (item) { return item.uniqueName == value; }); }));
    };
    SelectFieldViewParamsForm.prototype.modelDescription$ = function (resourceControl, modelControl) {
        var _this = this;
        return combineLatest(controlValue(resourceControl), controlValue(modelControl)).pipe(switchMap(function (_a) {
            var resource = _a[0], model = _a[1];
            if (!resource || !model || !model.model) {
                return of(undefined);
            }
            var modelId = [resource, model.model].join('.');
            return _this.modelDescriptionStore.getDetailFirst(modelId);
        }));
    };
    SelectFieldViewParamsForm.prototype.resourceBaseHttpQuery$ = function (control) {
        return this.resource$(control).pipe(map(function (resource) {
            if (!resource) {
                return undefined;
            }
            var resourceParams = resource.parseParams(RestAPIResourceParams);
            return resourceParams.baseHttpQuery;
        }));
    };
    SelectFieldViewParamsForm.prototype.getCustomModelOption = function (name, queryType) {
        var option = new ModelDescriptionQuery();
        option.queryType = queryType;
        return {
            value: option,
            name: name,
            icon: 'plus'
        };
    };
    SelectFieldViewParamsForm.prototype.getCustomModelOptions = function (resource) {
        var options = [];
        if (isResourceCustom(resource)) {
            var controller = this.resourceControllerService.get(resource.type);
            var queryTypes_1 = controller
                ? controller.supportedQueryTypes(resource.typeItem, ModelDescriptionQuery)
                : undefined;
            var queryType = editableQueryTypes.find(function (item) { return queryTypes_1.includes(item); });
            if (queryType) {
                var option = this.getCustomModelOption("Make " + getResourceTypeItemRequestName(resource.typeItem), queryType);
                options.push(option);
            }
        }
        if (resource.type == ResourceType.JetBridge || resource.isSynced() || resource.hasCollectionSync()) {
            var controller = this.resourceControllerService.get(ResourceType.JetBridge);
            var typeItem = resource.isSynced() || resource.hasCollectionSync()
                ? resourceTypeItems.find(function (item) { return item.name == ResourceName.PostgreSQL; })
                : resource.typeItem;
            var queryTypes_2 = controller ? controller.supportedQueryTypes(typeItem, ModelDescriptionQuery) : undefined;
            var queryType = editableQueryTypes.find(function (item) { return queryTypes_2.includes(item); });
            if (queryType) {
                var option = this.getCustomModelOption("Make " + getResourceTypeItemRequestName(typeItem), queryType);
                options.push(option);
            }
        }
        return options;
    };
    SelectFieldViewParamsForm.prototype.resourceModelItems$ = function (control) {
        var _this = this;
        return combineLatest(this.resource$(control), this.modelDescriptionStore.get()).pipe(map(function (_a) {
            var resource = _a[0], modelDescriptions = _a[1];
            if (!resource) {
                return [];
            }
            var options = [];
            if (modelDescriptions) {
                options.push.apply(options, modelDescriptions
                    .filter(function (item) { return item.resource == resource.uniqueName; })
                    .filter(function (item) {
                    return !resource.demo ||
                        item.featured ||
                        (_this.controls.model.value && _this.controls.model.value['model'] == item.model);
                })
                    .sort(function (lhs, rhs) {
                    return ascComparator(String(lhs.verboseNamePlural).toLowerCase(), String(rhs.verboseNamePlural).toLowerCase());
                })
                    .map(function (item) {
                    return {
                        option: {
                            value: { queryType: QueryType.Simple, model: item.model },
                            name: item.verboseNamePlural,
                            icon: 'document'
                        }
                    };
                }));
            }
            var addModelComponents = !resource.demo ? getResourceAddModelComponents(resource.typeItem.name) : [];
            options.push.apply(options, addModelComponents.map(function (item) {
                return {
                    button: {
                        name: 'add_model',
                        label: item.label,
                        icon: item.icon,
                        data: {
                            addModelComponent: item
                        }
                    },
                    stickyBottom: true,
                    orange: true,
                    large: true
                };
            }));
            options.push.apply(options, _this.getCustomModelOptions(resource).map(function (item) {
                return {
                    option: item,
                    valueIcon: null,
                    stickyBottom: true,
                    orange: true,
                    large: true
                };
            }));
            return options;
        }));
    };
    SelectFieldViewParamsForm.prototype.modelOption$ = function (modelControl) {
        return controlValue(modelControl);
    };
    SelectFieldViewParamsForm.prototype.columnOptions$ = function (columnsControl) {
        return controlValue(columnsControl).pipe(map(function (columnsValue) {
            if (!columnsValue) {
                return [];
            }
            return columnsValue.map(function (item) {
                var fieldDescription = getFieldDescriptionByType(item.field);
                return {
                    option: {
                        value: item.name,
                        name: item.verboseName || item.name,
                        icon: fieldDescription ? fieldDescription.icon : undefined
                    }
                };
            });
        }));
    };
    SelectFieldViewParamsForm.prototype.isFieldSortable = function (query, field, resource, modelDescription) {
        if (field.flex) {
            return false;
        }
        if (query) {
            if (modelDescriptionHasAutoParameters(resource, modelDescription)) {
                return true;
            }
            else if (query.queryType == QueryType.Simple) {
                return modelDescription && modelDescription.getQuery && modelDescription.getQuery.isFieldSortable(field);
            }
            else if (query instanceof ListModelDescriptionQuery) {
                return query.isFieldSortable(field);
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    };
    SelectFieldViewParamsForm.prototype.sortableColumnOptions$ = function () {
        var _this = this;
        return combineLatest(this.resource$(this.controls.resource), this.modelDescription$(this.controls.resource, this.controls.model), controlValue(this.controls.query), controlValue(this.controls.columns)).pipe(debounceTime(60), map(function (_a) {
            var resource = _a[0], modelDescription = _a[1], getQuery = _a[2], columns = _a[3];
            if (!columns) {
                return [];
            }
            return columns
                .filter(function (item) {
                return _this.isFieldSortable(getQuery, item, resource, modelDescription);
            })
                .map(function (item) {
                return {
                    value: item.name,
                    name: item.verboseName || item.name
                };
            });
        }));
    };
    SelectFieldViewParamsForm.prototype.onResourceChange = function (resourceName) {
        var resource = this.currentEnvironmentStore.resources.find(function (item) { return item.uniqueName == resourceName; });
        var modelOption;
        var firstModel = this.modelDescriptionStore.instance.find(function (model) {
            if (model.resource != resourceName) {
                return false;
            }
            else if (model.getParameters.filter(function (item) { return item.required; }).length) {
                return false;
            }
            if (resource.demo) {
                return model.featured;
            }
            return true;
        });
        if (firstModel) {
            modelOption = {
                queryType: QueryType.Simple,
                model: firstModel.model
            };
        }
        else if (resource) {
            var customOption = this.getCustomModelOptions(resource)[0];
            if (customOption) {
                modelOption = customOption.value;
            }
        }
        this.controls.model.patchValue(modelOption);
        this.controls.inputs.patchValue([]);
    };
    SelectFieldViewParamsForm.prototype.onModelChange = function (resourceName, model) {
        this.setModelQuery(model);
        this.setModelParams(resourceName, model);
        this.onModelParamsChange();
    };
    SelectFieldViewParamsForm.prototype.onParametersChange = function () {
        this.onModelParamsChange();
    };
    SelectFieldViewParamsForm.prototype.onQueryChange = function () {
        this.onModelParamsChange();
    };
    // onColumnsChange() {
    //   this.onModelParamsChange();
    // }
    SelectFieldViewParamsForm.prototype.onModelParamsChange = function () {
        var _this = this;
        setTimeout(function () {
            _this.updateInputFieldProvider().subscribe();
        }, 0);
    };
    SelectFieldViewParamsForm.prototype.serialize = function () {
        var result = {
            style: this.controls.style.value,
            circular: this.controls.circular.value,
            create_enabled: this.controls.create_enabled.value,
            options_type: this.controls.options_type.value,
            options: this.controls.options.value,
            resource: this.controls.resource.value,
            query: this.controls.query.value ? this.controls.query.value.serialize() : undefined,
            sorting_field: this.controls.sorting_field.value,
            sorting_asc: this.controls.sorting_asc.value,
            value_field: this.controls.value_field.value,
            label_field: !this.controls.label_input_enabled.value ? this.controls.label_field.value : undefined,
            label_field_input: this.controls.label_input_enabled.value ? this.controls.label_input.value : undefined,
            subtitle_field: !this.controls.subtitle_input_enabled.value ? this.controls.subtitle_field.value : undefined,
            subtitle_input: this.controls.subtitle_input_enabled.value ? this.controls.subtitle_input.value : undefined,
            icon_field: !this.controls.icon_input_enabled.value ? this.controls.icon_field.value : undefined,
            icon_input: this.controls.icon_input_enabled.value ? this.controls.icon_input.value : undefined,
            color_field: !this.controls.color_input_enabled.value ? this.controls.color_field.value : undefined,
            color_input: this.controls.color_input_enabled.value ? this.controls.color_input.value : undefined,
            parameters: this.controls.parameters.value ? this.controls.parameters.value.map(function (item) { return item.serialize(); }) : [],
            inputs: this.controls.inputs.value ? this.controls.inputs.value.map(function (item) { return item.serialize(); }) : [],
            columns: this.controls.columns.value ? this.controls.columns.value.map(function (item) { return item.serialize(); }) : []
        };
        if (result.query && result.query.simpleQuery) {
            result.query.simpleQuery.model = this.controls.model.value ? this.controls.model.value['model'] : undefined;
        }
        return result;
    };
    SelectFieldViewParamsForm.prototype.submit = function () {
        this.control.patchValue(__assign({}, this.control.value, this.serialize()));
    };
    return SelectFieldViewParamsForm;
}(FormGroup));
export { SelectFieldViewParamsForm };
