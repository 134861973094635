import emojis from 'emoji-datasource';
import * as EmojiConvertor from 'emoji-js';

import { getFilenameWithExtension, isAbsoluteUrl, isSet } from '@shared';

import { EmojiData } from '../data/emoji-data';
import { IconData } from '../data/icon-data';
import { IconType } from '../data/icon-type';

export function parseEmojiColon(icon: string): string {
  const emoji = icon.match(/^:(.+):$/);
  if (!emoji) {
    return;
  }

  return emoji[1];
}

export function getIconData(icon: string, options: { mediaBaseUrl: string }): IconData {
  if (!isSet(icon)) {
    return;
  }

  if (typeof icon !== 'string') {
    return;
  }

  const emoji = parseEmojiColon(icon);
  if (isSet(emoji)) {
    return { type: IconType.Emoji, emoji: emoji };
  }

  if (icon.startsWith('/')) {
    const url = `${options.mediaBaseUrl}/icons${icon}`;
    return { type: IconType.Image, image: url, imageFileName: getFilenameWithExtension(icon) };
  }

  if (isAbsoluteUrl(icon)) {
    return { type: IconType.Image, image: icon, imageFileName: getFilenameWithExtension(icon) };
  }

  return { type: IconType.Icon, icon: icon };
}

export function getEmojiData(shortName: string): EmojiData {
  return (emojis as EmojiData[]).find(item => item.short_name == shortName);
}

export function getEmojiConverter(options: { sprite?: boolean } = {}): EmojiConvertor {
  const convertor = new EmojiConvertor();

  convertor.wrap_native = false;
  convertor.use_sheet = !!options.sprite;
  convertor.img_sets.apple.path = '/assets/emoji-data/img-apple-64/';
  convertor.img_sets.apple.sheet = '/assets/emoji-data/sheet_apple_64.png';

  return convertor;
}
