<form *ngIf="!loading" [formGroup]="form.form">
  <div class="popup-content resource-popup">
    <div
      *ngIf="environment"
      class="resource-popup__tag"
      [ngClass]="['color_' + environment.color + '_1', 'background-color_' + environment.color + '_2']"
    >
      {{ environment.name }}
    </div>
    <div class="resource-popup__image">
      <div
        class="resource-popup__image-inner"
        [style.background-image]="'/assets/images/resources/' + typeItem.image + '-color.svg' | appDeployCssUrl"
      ></div>
    </div>
    <div class="resource-popup__title">
      Allow Jet Admin to access <br />
      <strong [style.color]="typeItem.color">{{ typeItem.label }}</strong>
    </div>
    <div class="resource-popup__description">
      Enter your credentials to create internal tools on top of your data.
    </div>

    <div class="resource-popup__content">
      <app-page-block *ngIf="resourceNameEditing">
        <app-auto-field
          [form]="form.resourceForm"
          [field]="
            createField({
              name: 'name',
              label: 'Resource Name',
              field: 'CharField',
              description: 'This is how you and your team will see it',
              required: true,
              params: { classes: ['input_fill'] }
            })
          "
        ></app-auto-field>
      </app-page-block>

      <app-page-block>
        <ng-template #description>
          You can obtain it here <a href="https://portal.azure.com/" target="_blank">https://portal.azure.com/</a>
        </ng-template>
        <app-auto-field
          [form]="form.form"
          [field]="
            createField({
              name: 'account',
              label: 'Storage account',
              field: 'CharField',
              description: description,
              placeholder: defaultPlaceholder,
              required: true,
              params: { classes: ['input_fill'] }
            })
          "
          [autofocus]="true"
        ></app-auto-field>
      </app-page-block>

      <app-page-block>
        <app-auto-field
          [form]="form.form"
          [field]="
            createField({
              name: 'sas',
              label: 'Shared access signature (SAS)',
              field: 'CharField',
              placeholder: defaultPlaceholder,
              required: true,
              params: { classes: ['input_fill'] }
            })
          "
        ></app-auto-field>
      </app-page-block>

      <app-page-block>
        <app-alert [title]="'CORS configuration needed'" [warning]="true">
          <p>
            You should also update CORS settings at
            <a href="https://portal.azure.com/" target="_blank">https://portal.azure.com/</a> to allow
            <strong>app.jetadmin.io</strong> to make requests to Azure API. Check documentation to learn how to do it.
          </p>
        </app-alert>
      </app-page-block>

      <!--      <app-page-block>-->
      <!--        <a-->
      <!--          [href]="'integrations/azure-blob-storage'"-->
      <!--          class="button button_small"-->
      <!--          style="margin: 0;"-->
      <!--          target="_blank"-->
      <!--        >-->
      <!--          Read documentation-->
      <!--        </a>-->
      <!--      </app-page-block>-->

      <app-page-block *ngIf="(form.form | appFormErrors).length">
        <app-resource-settings-submit-error [errors]="form.form | appFormErrors"></app-resource-settings-submit-error>
      </app-page-block>
    </div>

    <div class="popup-content__submit">
      <button
        *ngIf="resourceDelete && resource"
        type="button"
        class="button button_danger popup-content__delete"
        (click)="requestDelete()"
      >
        Delete
      </button>
      <button type="button" class="button popup-content__submit-item" (click)="cancel()">
        Cancel
      </button>
      <button
        type="button"
        class="button button_primary button_shadow popup-content__submit-item"
        [class.button_disabled]="!form.isValid() || loadingSubmit"
        (click)="submit()"
      >
        <app-loader-small *ngIf="loadingSubmit" class="button__icon button__icon_left"></app-loader-small>
        <ng-container *ngIf="resource">Update resource</ng-container>
        <ng-container *ngIf="!resource">Add resource</ng-container>
      </button>
    </div>
  </div>
</form>

<app-resource-settings-stub *ngIf="loading" [typeItem]="typeItem"></app-resource-settings-stub>
