import { Injectable } from '@angular/core';

import { ModelElementItem, modelFieldToDisplayField } from '@modules/customize';
import { DataSourceType, ModelDescriptionDataSource } from '@modules/data-sources';
import { Input, InputValueType } from '@modules/fields';
import { ModelDescription, ModelFieldType } from '@modules/models';
import { Resource } from '@modules/projects';
import { ModelDescriptionQuery, QueryType } from '@modules/queries';
import { prepareDataSourceColumnForGet } from '@modules/resources';

import { GeneratorUtils } from '../generator-utils/generator-utils.service';

@Injectable()
export class DetailGenerator {
  constructor(private generatorUtils: GeneratorUtils) {}

  getElement(
    resource: Resource,
    modelDescription: ModelDescription,
    options: {
      pkContextValue: string[];
      fields?: string[];
      uid?: string;
    }
  ): ModelElementItem {
    const modelElement = new ModelElementItem();

    if (options.uid) {
      modelElement.uid = options.uid;
    } else {
      modelElement.generateUid();
    }

    const getQuery = new ModelDescriptionQuery();

    getQuery.queryType = QueryType.Simple;
    getQuery.simpleQuery = new getQuery.simpleQueryClass();
    getQuery.simpleQuery.model = modelDescription.model;

    const input = new Input();

    input.path = [modelDescription.primaryKeyField];
    input.valueType = InputValueType.Context;
    input.contextValue = options.pkContextValue;

    modelElement.dataSource = new ModelDescriptionDataSource();
    modelElement.dataSource.type = DataSourceType.Query;
    modelElement.dataSource.queryResource = resource.uniqueName;
    modelElement.dataSource.query = getQuery;
    modelElement.dataSource.queryInputs = [input];
    modelElement.dataSource.columns = modelDescription.fields
      .filter(item => item.type == ModelFieldType.Db)
      .map(item => {
        const result = modelFieldToDisplayField(item);

        if (result.name == modelDescription.primaryKeyField) {
          result.visible = false;
        }

        return result;
      })
      .map(item => prepareDataSourceColumnForGet(resource, modelDescription, item));

    if (options.fields) {
      modelElement.dataSource.columns = modelElement.dataSource.columns
        .filter(item => options.fields.includes(item.name))
        .sort((lhs, rhs) => this.generatorUtils.fieldsSort(options.fields, lhs.name, rhs.name));
    }

    return modelElement;
  }
}
