var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ThemeVar } from '@modules/theme-components';
export var globalColorOptions = [
    {
        value: ThemeVar.AccentColor,
        name: 'Accent color',
        subtitle: 'Light theme'
    },
    {
        value: ThemeVar.AccentColorContrast,
        name: 'Accent color (contrast)',
        subtitle: 'Light theme'
    },
    {
        value: ThemeVar.BackgroundColor,
        name: 'Background color',
        subtitle: 'Light theme'
    },
    {
        value: ThemeVar.BackgroundColor2,
        name: 'Surfaces 1 color',
        subtitle: 'Light theme'
    },
    {
        value: ThemeVar.BackgroundColor3,
        name: 'Surfaces 2 color',
        subtitle: 'Light theme'
    },
    {
        value: ThemeVar.BackgroundColor4,
        name: 'Surfaces 3 color',
        subtitle: 'Light theme'
    },
    {
        value: ThemeVar.BackgroundColor5,
        name: 'Surfaces 4 color',
        subtitle: 'Light theme'
    },
    {
        value: ThemeVar.TextColor,
        name: 'Text color Headings',
        subtitle: 'Light theme'
    },
    {
        value: ThemeVar.TextColor2,
        name: 'Text color Regular',
        subtitle: 'Light theme'
    },
    {
        value: ThemeVar.TextColor3,
        name: 'Text color Secondary',
        subtitle: 'Light theme'
    },
    {
        value: ThemeVar.BorderColor,
        name: 'Border color Secondary',
        subtitle: 'Light theme'
    },
    {
        value: ThemeVar.BorderColor2,
        name: 'Border color Primary',
        subtitle: 'Light theme'
    },
    {
        value: ThemeVar.BorderColor3,
        name: 'Border color Bright',
        subtitle: 'Light theme'
    },
    {
        value: ThemeVar.AccentColorDark,
        name: 'Accent color',
        subtitle: 'Dark theme'
    },
    {
        value: ThemeVar.AccentColorDarkContrast,
        name: 'Accent color (contrast)',
        subtitle: 'Dark theme'
    },
    {
        value: ThemeVar.BackgroundColorDark,
        name: 'Background color',
        subtitle: 'Dark theme'
    },
    {
        value: ThemeVar.BackgroundColor2Dark,
        name: 'Surfaces 1 color',
        subtitle: 'Dark theme'
    },
    {
        value: ThemeVar.BackgroundColor3Dark,
        name: 'Surfaces 2 color',
        subtitle: 'Dark theme'
    },
    {
        value: ThemeVar.BackgroundColor4Dark,
        name: 'Surfaces 3 color',
        subtitle: 'Dark theme'
    },
    {
        value: ThemeVar.BackgroundColor5Dark,
        name: 'Surfaces 4 color',
        subtitle: 'Dark theme'
    },
    {
        value: ThemeVar.TextColorDark,
        name: 'Text Headings',
        subtitle: 'Dark theme'
    },
    {
        value: ThemeVar.TextColor2Dark,
        name: 'Text Regular',
        subtitle: 'Dark theme'
    },
    {
        value: ThemeVar.TextColor3Dark,
        name: 'Text Secondary',
        subtitle: 'Dark theme'
    },
    {
        value: ThemeVar.BorderColorDark,
        name: 'Border Secondary',
        subtitle: 'Dark theme'
    },
    {
        value: ThemeVar.BorderColor2Dark,
        name: 'Border Primary',
        subtitle: 'Dark theme'
    },
    {
        value: ThemeVar.BorderColor3Dark,
        name: 'Border Bright',
        subtitle: 'Dark theme'
    }
].map(function (item) {
    return __assign({}, item, { color: "var(--" + item.value + ")" });
});
