var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import isEqual from 'lodash/isEqual';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, of, throwError } from 'rxjs';
import { catchError, filter, first, map, switchMap, tap } from 'rxjs/operators';
import { FormUtils } from '@common/form-utils';
import { TintStyle } from '@modules/actions';
import { AllProjectSettings, ProjectSettingsStore } from '@modules/all-project-settings';
import { CHART_COLORS } from '@modules/charts';
import { MarginControl } from '@modules/customize';
import { ActionElementStylesControl, ElementWrapperStylesControl, FieldElementStylesControl } from '@modules/customize-bar';
import { CurrentEnvironmentStore, CurrentProjectStore, ProjectPropertyStore, ProjectPropertyType, ProjectSettingsName, ProjectSettingsService } from '@modules/projects';
import { BorderRadius, defaultFontName, projectThemes } from '@modules/theme';
import { controlValue, isColorHex, isSet, setControlEnabled } from '@shared';
import { ColorSetArray } from './color-set.array';
import { SignUpFieldArray } from './sign-up-field.array';
var ProjectSettingsUpdateForm = /** @class */ (function (_super) {
    __extends(ProjectSettingsUpdateForm, _super);
    function ProjectSettingsUpdateForm(currentProjectStore, currentEnvironmentStore, projectSettingsService, projectSettingsStore, projectPropertyStore, formUtils, injector) {
        var _this = _super.call(this, {
            accent_color: new FormControl(''),
            accent_color_custom_enabled: new FormControl(false),
            accent_color_custom: new FormControl('#2B50ED'),
            accent_color_dark: new FormControl(''),
            accent_color_dark_custom_enabled: new FormControl(false),
            accent_color_dark_custom: new FormControl('#2B50ED'),
            background_color: new FormControl(),
            background_color_dark: new FormControl(),
            background_color_2: new FormControl(),
            background_color_2_dark: new FormControl(),
            background_color_3: new FormControl(),
            background_color_3_dark: new FormControl(),
            background_color_4: new FormControl(),
            background_color_4_dark: new FormControl(),
            background_color_5: new FormControl(),
            background_color_5_dark: new FormControl(),
            text_color: new FormControl(),
            text_color_dark: new FormControl(),
            text_color_2: new FormControl(),
            text_color_2_dark: new FormControl(),
            text_color_3: new FormControl(),
            text_color_3_dark: new FormControl(),
            border_color: new FormControl(),
            border_color_dark: new FormControl(),
            border_color_2: new FormControl(),
            border_color_2_dark: new FormControl(),
            border_color_3: new FormControl(),
            border_color_3_dark: new FormControl(),
            border_radius: new FormControl(BorderRadius.M),
            auto_colors: new ColorSetArray(CHART_COLORS),
            auto_colors_dark: new ColorSetArray(CHART_COLORS),
            max_width: new FormControl(),
            padding: new MarginControl(),
            font_regular: new FormControl(defaultFontName),
            font_heading: new FormControl(defaultFontName),
            action_element_styles_primary: new ActionElementStylesControl(injector, {
                textStyleGlobalParams: { tint: TintStyle.Primary }
            }),
            action_element_styles_default: new ActionElementStylesControl(injector, {
                textStyleGlobalParams: { tint: TintStyle.Default }
            }),
            action_element_styles_transparent: new ActionElementStylesControl(injector, {
                textStyleGlobalParams: { tint: TintStyle.Transparent }
            }),
            field_element_styles: new FieldElementStylesControl(injector),
            element_wrapper_styles: new ElementWrapperStylesControl(),
            collaboration_enabled: new FormControl(false),
            activity_log_enabled: new FormControl(false),
            default_theme: new FormControl(null),
            append_scripts: new FormControl(''),
            append_styles: new FormControl(''),
            sign_up_fields: new SignUpFieldArray([])
        }) || this;
        _this.currentProjectStore = currentProjectStore;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        _this.projectSettingsService = projectSettingsService;
        _this.projectSettingsStore = projectSettingsStore;
        _this.projectPropertyStore = projectPropertyStore;
        _this.formUtils = formUtils;
        _this.injector = injector;
        _this.borderRadiusOptions = [
            { value: BorderRadius.None, name: 'None' },
            { value: BorderRadius.S, name: 'Small' },
            { value: BorderRadius.M, name: 'Normal' },
            { value: BorderRadius.L, name: 'Big' },
            { value: BorderRadius.XL, name: 'Large' }
        ];
        _this.currentProjectStore
            .get()
            .pipe(untilDestroyed(_this))
            .subscribe(function (instance) {
            var enabled = instance && instance.features.isStylesEnabled();
            setControlEnabled(_this.controls.max_width, enabled);
        });
        return _this;
    }
    ProjectSettingsUpdateForm.prototype.ngOnDestroy = function () { };
    ProjectSettingsUpdateForm.prototype.init = function (projectSettings) {
        this.projectSettings = projectSettings;
        if (projectSettings) {
            if (isColorHex(projectSettings.accentColor)) {
                this.controls.accent_color.patchValue('');
                this.controls.accent_color_custom_enabled.patchValue(true);
                this.controls.accent_color_custom.patchValue(projectSettings.accentColor);
            }
            else {
                this.controls.accent_color.patchValue(isSet(projectSettings.accentColor) ? projectSettings.accentColor : '');
                this.controls.accent_color_custom_enabled.patchValue(false);
                this.controls.accent_color_custom.patchValue('#2B50ED');
            }
            if (isColorHex(projectSettings.accentColorDark)) {
                this.controls.accent_color_dark.patchValue('');
                this.controls.accent_color_dark_custom_enabled.patchValue(true);
                this.controls.accent_color_dark_custom.patchValue(projectSettings.accentColorDark);
            }
            else {
                this.controls.accent_color_dark.patchValue(isSet(projectSettings.accentColorDark) ? projectSettings.accentColorDark : '');
                this.controls.accent_color_dark_custom_enabled.patchValue(false);
                this.controls.accent_color_dark_custom.patchValue('#2B50ED');
            }
            this.controls.background_color.patchValue(projectSettings.backgroundColor);
            this.controls.background_color_dark.patchValue(projectSettings.backgroundColorDark);
            this.controls.background_color_2.patchValue(projectSettings.backgroundColor2);
            this.controls.background_color_2_dark.patchValue(projectSettings.backgroundColor2Dark);
            this.controls.background_color_3.patchValue(projectSettings.backgroundColor3);
            this.controls.background_color_3_dark.patchValue(projectSettings.backgroundColor3Dark);
            this.controls.background_color_4.patchValue(projectSettings.backgroundColor4);
            this.controls.background_color_4_dark.patchValue(projectSettings.backgroundColor4Dark);
            this.controls.background_color_5.patchValue(projectSettings.backgroundColor5);
            this.controls.background_color_5_dark.patchValue(projectSettings.backgroundColor5Dark);
            this.controls.text_color.patchValue(projectSettings.textColor);
            this.controls.text_color_dark.patchValue(projectSettings.textColorDark);
            this.controls.text_color_2.patchValue(projectSettings.textColor2);
            this.controls.text_color_2_dark.patchValue(projectSettings.textColor2Dark);
            this.controls.text_color_3.patchValue(projectSettings.textColor3);
            this.controls.text_color_3_dark.patchValue(projectSettings.textColor3Dark);
            this.controls.border_color.patchValue(projectSettings.borderColor);
            this.controls.border_color_dark.patchValue(projectSettings.borderColorDark);
            this.controls.border_color_2.patchValue(projectSettings.borderColor2);
            this.controls.border_color_2_dark.patchValue(projectSettings.borderColor2Dark);
            this.controls.border_color_3.patchValue(projectSettings.borderColor3);
            this.controls.border_color_3_dark.patchValue(projectSettings.borderColor3Dark);
            this.controls.border_radius.patchValue(projectSettings.borderRadius || BorderRadius.M);
            this.controls.auto_colors.deserialize(projectSettings.autoColors);
            this.controls.auto_colors_dark.deserialize(projectSettings.autoColorsDark);
            this.controls.max_width.patchValue(projectSettings.maxWidth);
            this.controls.padding.deserialize(projectSettings.padding);
            if (isSet(projectSettings.fontRegular)) {
                this.controls.font_regular.patchValue(projectSettings.fontRegular);
            }
            if (isSet(projectSettings.fontHeading)) {
                this.controls.font_heading.patchValue(projectSettings.fontHeading);
            }
            if (projectSettings.actionElementStylesPrimary) {
                this.controls.action_element_styles_primary.deserialize(projectSettings.actionElementStylesPrimary);
            }
            if (projectSettings.actionElementStylesDefault) {
                this.controls.action_element_styles_default.deserialize(projectSettings.actionElementStylesDefault);
            }
            if (projectSettings.actionElementStylesTransparent) {
                this.controls.action_element_styles_transparent.deserialize(projectSettings.actionElementStylesTransparent);
            }
            if (projectSettings.fieldElementStyles) {
                this.controls.field_element_styles.deserialize(projectSettings.fieldElementStyles);
            }
            if (projectSettings.elementWrapperStyles) {
                this.controls.element_wrapper_styles.deserialize(projectSettings.elementWrapperStyles);
            }
            this.controls.collaboration_enabled.patchValue(projectSettings.collaborationEnabled);
            this.controls.activity_log_enabled.patchValue(projectSettings.activityLogEnabled);
            this.controls.default_theme.patchValue(projectSettings.defaultTheme ? projectSettings.defaultTheme : projectThemes[0]);
            this.controls.append_scripts.patchValue(projectSettings.appendScripts);
            this.controls.append_styles.patchValue(projectSettings.appendStyles);
            this.controls.sign_up_fields.deserialize(projectSettings.signUpFields);
        }
        this.markAsPristine();
    };
    ProjectSettingsUpdateForm.prototype.getAccentColor = function () {
        if (this.controls.accent_color_custom_enabled.value) {
            return this.controls.accent_color_custom.value;
        }
        else {
            return this.controls.accent_color.value;
        }
    };
    ProjectSettingsUpdateForm.prototype.getAccentColorDark = function () {
        if (this.controls.accent_color_dark_custom_enabled.value) {
            return this.controls.accent_color_dark_custom.value;
        }
        else {
            return this.controls.accent_color_dark.value;
        }
    };
    ProjectSettingsUpdateForm.prototype.getInstance$ = function () {
        var _this = this;
        return combineLatest(controlValue(this), this.projectPropertyStore
            .get()
            .pipe(map(function (properties) { return properties.filter(function (item) { return item.type == ProjectPropertyType.User; }); }))).pipe(map(function (_a) {
            var _ = _a[0], properties = _a[1];
            var instance = new AllProjectSettings();
            instance.accentColor = _this.getAccentColor();
            instance.accentColorDark = _this.getAccentColorDark();
            instance.backgroundColor = _this.controls.background_color.value;
            instance.backgroundColorDark = _this.controls.background_color_dark.value;
            instance.backgroundColor2 = _this.controls.background_color_2.value;
            instance.backgroundColor2Dark = _this.controls.background_color_2_dark.value;
            instance.backgroundColor3 = _this.controls.background_color_3.value;
            instance.backgroundColor3Dark = _this.controls.background_color_3_dark.value;
            instance.backgroundColor4 = _this.controls.background_color_4.value;
            instance.backgroundColor4Dark = _this.controls.background_color_4_dark.value;
            instance.backgroundColor5 = _this.controls.background_color_5.value;
            instance.backgroundColor5Dark = _this.controls.background_color_5_dark.value;
            instance.textColor = _this.controls.text_color.value;
            instance.textColorDark = _this.controls.text_color_dark.value;
            instance.textColor2 = _this.controls.text_color_2.value;
            instance.textColor2Dark = _this.controls.text_color_2_dark.value;
            instance.textColor3 = _this.controls.text_color_3.value;
            instance.textColor3Dark = _this.controls.text_color_3_dark.value;
            instance.borderColor = _this.controls.border_color.value;
            instance.borderColorDark = _this.controls.border_color_dark.value;
            instance.borderColor2 = _this.controls.border_color_2.value;
            instance.borderColor2Dark = _this.controls.border_color_2_dark.value;
            instance.borderColor3 = _this.controls.border_color_3.value;
            instance.borderColor3Dark = _this.controls.border_color_3_dark.value;
            instance.borderRadius = _this.controls.border_radius.value;
            instance.autoColors = _this.controls.auto_colors.serialize();
            instance.autoColorsDark = _this.controls.auto_colors_dark.serialize();
            instance.maxWidth = _this.controls.max_width.value;
            instance.padding = _this.controls.padding.value;
            instance.collaborationEnabled = _this.controls.collaboration_enabled.value;
            instance.activityLogEnabled = _this.controls.activity_log_enabled.value;
            instance.defaultTheme = _this.controls.default_theme.value;
            instance.appendScripts = isSet(_this.controls.append_scripts.value)
                ? _this.controls.append_scripts.value
                : undefined;
            instance.appendStyles = isSet(_this.controls.append_styles.value)
                ? _this.controls.append_styles.value
                : undefined;
            if (_this.controls.font_regular.value != defaultFontName) {
                instance.fontRegular = _this.controls.font_regular.value;
            }
            else {
                instance.fontRegular = undefined;
            }
            if (_this.controls.font_heading.value != defaultFontName) {
                instance.fontHeading = _this.controls.font_heading.value;
            }
            else {
                instance.fontHeading = undefined;
            }
            instance.actionElementStylesPrimary = _this.controls.action_element_styles_primary.serialize();
            instance.actionElementStylesDefault = _this.controls.action_element_styles_default.serialize();
            instance.actionElementStylesTransparent = _this.controls.action_element_styles_transparent.serialize();
            instance.fieldElementStyles = _this.controls.field_element_styles.serialize();
            instance.elementWrapperStyles = _this.controls.element_wrapper_styles.serialize();
            instance.signUpFields = _this.controls.sign_up_fields
                .serialize()
                .filter(function (field) { return properties.some(function (item) { return item.uid == field.property; }); });
            return instance;
        }));
    };
    ProjectSettingsUpdateForm.prototype.getInstanceFirst = function () {
        return this.getInstance$().pipe(first());
    };
    ProjectSettingsUpdateForm.prototype.submit = function () {
        var _this = this;
        return this.getInstanceFirst().pipe(switchMap(function (allProjectSettings) {
            if (!allProjectSettings) {
                return of(undefined);
            }
            var submitSettings = [
                ProjectSettingsName.AccentColor,
                ProjectSettingsName.AccentColorDark,
                ProjectSettingsName.BackgroundColor,
                ProjectSettingsName.BackgroundColorDark,
                ProjectSettingsName.BackgroundColor2,
                ProjectSettingsName.BackgroundColor2Dark,
                ProjectSettingsName.BackgroundColor3,
                ProjectSettingsName.BackgroundColor3Dark,
                ProjectSettingsName.BackgroundColor4,
                ProjectSettingsName.BackgroundColor4Dark,
                ProjectSettingsName.BackgroundColor5,
                ProjectSettingsName.BackgroundColor5Dark,
                ProjectSettingsName.TextColor,
                ProjectSettingsName.TextColorDark,
                ProjectSettingsName.TextColor2,
                ProjectSettingsName.TextColor2Dark,
                ProjectSettingsName.TextColor3,
                ProjectSettingsName.TextColor3Dark,
                ProjectSettingsName.BorderColor,
                ProjectSettingsName.BorderColorDark,
                ProjectSettingsName.BorderColor2,
                ProjectSettingsName.BorderColor2Dark,
                ProjectSettingsName.BorderColor3,
                ProjectSettingsName.BorderColor3Dark,
                ProjectSettingsName.BorderRadius,
                ProjectSettingsName.AutoColors,
                ProjectSettingsName.AutoColorsDark,
                ProjectSettingsName.MaxWidth,
                ProjectSettingsName.Padding,
                ProjectSettingsName.DefaultTheme,
                ProjectSettingsName.FontRegular,
                ProjectSettingsName.FontHeading,
                ProjectSettingsName.ActionElementStylesPrimary,
                ProjectSettingsName.ActionElementStylesDefault,
                ProjectSettingsName.ActionElementStylesTransparent,
                ProjectSettingsName.FieldElementStyles,
                ProjectSettingsName.ElementWrapperStyles,
                ProjectSettingsName.AppendScripts,
                ProjectSettingsName.AppendStyles,
                ProjectSettingsName.CollaborationEnabled,
                ProjectSettingsName.ActivityLogEnabled,
                ProjectSettingsName.SignUpFields
            ];
            var prevProjectSettings = _this.projectSettings.serialize(submitSettings);
            var newProjectSettings = allProjectSettings.serialize(submitSettings);
            var updateProjectSettings = newProjectSettings
                .filter(function (newItem) {
                var prevItem = prevProjectSettings.find(function (item) { return item.name == newItem.name; });
                return prevItem ? !isEqual(newItem.value, prevItem.value) : true;
            })
                .map(function (item) {
                if (!isSet(item.value)) {
                    item.deleted = true;
                }
                return item;
            });
            if (!updateProjectSettings.length) {
                return of(undefined);
            }
            return _this.projectSettingsService.createBulk(_this.currentProjectStore.instance.uniqueName, _this.currentEnvironmentStore.instance.uniqueName, updateProjectSettings);
        }), filter(function (item) { return item; }), switchMap(function () { return _this.projectSettingsStore.getFirst(true); }), map(function (result) { return new AllProjectSettings().deserialize(result); }), tap(function (result) {
            _this.projectSettings = result;
        }), catchError(function (error) {
            _this.formUtils.showFormErrors(_this, error);
            return throwError(error);
        }));
    };
    return ProjectSettingsUpdateForm;
}(FormGroup));
export { ProjectSettingsUpdateForm };
