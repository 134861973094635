import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';

import { localize } from '@common/localize';
import { NotificationService } from '@common/notifications';
import { AppConfigService } from '@core';
import { GoogleAnalyticsService, UniversalAnalyticsService } from '@modules/analytics';
import { ApiService } from '@modules/api';
import { AuthService } from '@modules/auth';
import { MetaService } from '@modules/meta';
import { ProjectInviteService, ProjectPublicInviteService, ProjectsStore } from '@modules/projects';
import { RoutingService } from '@modules/routing';
import { SSOSettings } from '@modules/sso';
import { CurrentUserStore } from '@modules/users';

import { LoginBaseComponent } from './login-base.component';
import { LoginForm } from './login.form';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  providers: [LoginForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginComponent extends LoginBaseComponent implements OnInit, OnDestroy {
  domainLoading = false;
  title = 'Good morning';

  constructor(
    public loginForm: LoginForm,
    protected routing: RoutingService,
    protected currentUserStore: CurrentUserStore,
    protected activatedRoute: ActivatedRoute,
    protected analyticsService: UniversalAnalyticsService,
    protected googleAnalyticsService: GoogleAnalyticsService,
    public appConfigService: AppConfigService,
    protected authService: AuthService,
    protected notificationService: NotificationService,
    protected metaService: MetaService,
    protected projectsStore: ProjectsStore,
    protected apiService: ApiService,
    protected projectInviteService: ProjectInviteService,
    protected projectPublicInviteService: ProjectPublicInviteService,
    protected cd: ChangeDetectorRef
  ) {
    super(
      loginForm,
      routing,
      currentUserStore,
      activatedRoute,
      analyticsService,
      googleAnalyticsService,
      appConfigService,
      authService,
      notificationService,
      metaService,
      projectsStore,
      apiService,
      projectInviteService,
      projectPublicInviteService,
      cd
    );

    this.title = this.getTitle();
  }

  getTitle(): string {
    const hour = new Date().getHours();
    if (hour >= 0 && hour < 12) {
      return localize('Good morning');
    } else if (hour >= 12 && hour <= 17) {
      return localize('Good afternoon');
    } else {
      return localize('Good evening');
    }
  }

  ngOnInit() {
    super.ngOnInit();
    this.metaService.set({ title: localize('Login') });

    this.domainLoading = true;
    this.cd.markForCheck();

    combineLatest(this.activatedRoute.params, this.activatedRoute.data, this.domain$)
      .pipe(untilDestroyed(this))
      .subscribe(
        ([params, data, domain]) => {
          this.domainLoading = false;
          this.cd.markForCheck();

          if (!params['project'] && domain && domain.defaultProject && !data['ignoreSelfSignOn']) {
            this.routing.navigate(['/login', domain.defaultProject], { queryParamsHandling: 'merge' });
          } else if (domain && domain.ssoSettings.length == 1 && domain.disableEmailSignIn) {
            const result = this.ssoLogin(domain.ssoSettings[0]);
            if (result.externalRedirect) {
              this.domainLoading = true;
              this.cd.markForCheck();
            }
          }
        },
        () => {
          this.domainLoading = false;
          this.cd.markForCheck();
        }
      );
  }

  onInviteLoaded() {
    if (this.invite) {
      this.loginForm.form.patchValue({ username: this.invite.getEmail() });
    }
  }

  submitJetAuth() {
    this.loginForm.markAsSubmit(true);

    const projectName = this.activatedRoute.snapshot.params['project'];
    const transferProjects = this.activatedRoute.snapshot.queryParams['transfer_projects'];

    this.submitLoading = true;
    this.cd.markForCheck();

    this.loginForm
      .submitJetAuth(projectName, transferProjects ? transferProjects.split(',') : undefined)
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.onLogin();
        },
        () => {
          this.submitLoading = false;
          this.cd.markForCheck();
        }
      );
  }

  submitSSOAuth(sso: SSOSettings) {
    this.loginForm.markAsSubmit(true);

    const projectName = this.activatedRoute.snapshot.params['project'];
    const transferProjects = this.activatedRoute.snapshot.queryParams['transfer_projects'];

    this.submitLoading = true;
    this.cd.markForCheck();

    this.loginForm
      .submitSSOAuth(sso, projectName, transferProjects ? transferProjects.split(',') : undefined)
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.onLogin();
        },
        () => {
          this.submitLoading = false;
          this.cd.markForCheck();
        }
      );
  }

  get signUpLink() {
    const projectName = this.activatedRoute.snapshot.params['project'];
    const ignoreSelfSignOn = this.activatedRoute.snapshot.data['ignoreSelfSignOn'];

    if (projectName) {
      return ['/register', projectName];
    } else if (ignoreSelfSignOn) {
      return ['/user', 'register'];
    } else {
      return ['/register'];
    }
  }

  get restoreLink() {
    const ignoreSelfSignOn = this.activatedRoute.snapshot.data['ignoreSelfSignOn'];

    if (ignoreSelfSignOn) {
      return ['/user', 'restore'];
    } else {
      return ['/restore'];
    }
  }

  cleanErrorForm() {
    this.loginForm.form.setErrors({});
  }
}
