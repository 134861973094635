<form *ngIf="!loading" [formGroup]="form.form">
  <div class="popup-content resource-popup resource-popup_side">
    <app-popup2-side>
      <div
        *ngIf="environment"
        class="resource-popup__tag"
        [ngClass]="['color_' + environment.color + '_1', 'background-color_' + environment.color + '_2']"
      >
        {{ environment.name }}
      </div>
      <div class="resource-popup__image">
        <div
          class="resource-popup__image-inner"
          [style.background-image]="'/assets/images/resources/' + typeItem.image + '-color.svg' | appDeployCssUrl"
        ></div>
      </div>
      <div class="resource-popup__title">
        Allow Jet Admin to access <br />
        <strong [style.color]="typeItem.color">{{ typeItem.label }}</strong>
      </div>
      <div class="resource-popup__description">
        Enter your Xano API Key to create internal tools on top of your user data.
      </div>

      <div class="resource-popup__content">
        <app-page-block *ngIf="resourceNameEditing">
          <app-auto-field
            [form]="form.resourceForm"
            [field]="
              createField({
                name: 'name',
                label: 'Name',
                field: 'CharField',
                description: 'This is how you and your team will see it',
                required: true,
                params: { classes: ['input_fill'] }
              })
            "
          ></app-auto-field>
        </app-page-block>

        <app-page-block *ngIf="form.legacyOptions">
          <app-alert [title]="'Credentials you are using are now deprecated'" [warning]="true">
            <div>
              Please specify new Xano credentials below to update your resource integration. We recommend to download
              App backup before moving to new credentials.
            </div>
          </app-alert>
        </app-page-block>

        <app-page-block>
          <ng-template #description>
            You can obtain it here
            <a href="https://app.xano.com/admin/account" target="_blank">https://app.xano.com/admin/account</a>.
          </ng-template>

          <app-auto-field
            [form]="form.form"
            [field]="
              createField({
                name: 'access_token',
                label: 'Access token',
                field: 'CharField',
                description: description,
                placeholder: 'eyXYZciOiJSUzI1NiJ9.eyXYZW5vIjp7ImRibyI6Im...',
                required: true,
                params: { classes: ['input_fill'] }
              })
            "
            [autofocus]="true"
          ></app-auto-field>
        </app-page-block>

        <app-page-block>
          <div class="field">
            <div class="field__label">
              Instance
            </div>
            <div class="field__value">
              <ng-container *ngIf="form.form.controls['access_token'].valid && !loadingDomains">
                <app-select
                  [control]="form.form.controls['domain']"
                  [options]="domainOptions"
                  [fill]="true"
                ></app-select>
                <app-field-errors [form]="form.form" [fieldName]="'domain'"></app-field-errors>
              </ng-container>

              <div
                *ngIf="!form.form.controls['access_token'].valid || loadingDomains"
                class="input input_fill input_disabled"
              >
                <span [class.loading-animation]="loadingDomains"><span class="stub-text">Instance</span></span>
              </div>
            </div>
            <div *ngIf="form.form.controls['domain'].value" class="field__description field__description_small">
              <a [href]="'https://' + form.form.controls['domain'].value" target="_blank">
                Open in new tab
              </a>
            </div>
          </div>
        </app-page-block>

        <app-page-block>
          <div class="field">
            <div class="field__label">
              Workspace
            </div>
            <div class="field__value">
              <ng-container
                *ngIf="
                  form.form.controls['access_token'].valid && form.form.controls['domain'].valid && !loadingWorkspaces
                "
              >
                <app-select
                  [control]="form.form.controls['workspace_id']"
                  [options]="workspaceOptions"
                  [fill]="true"
                ></app-select>
                <app-field-errors [form]="form.form" [fieldName]="'workspace_id'"></app-field-errors>
              </ng-container>

              <div
                *ngIf="
                  !form.form.controls['access_token'].valid || !form.form.controls['domain'].valid || loadingWorkspaces
                "
                class="input input_fill input_disabled"
              >
                <span [class.loading-animation]="loadingWorkspaces"><span class="stub-text">Workspace</span></span>
              </div>
            </div>
          </div>
        </app-page-block>

        <app-page-block>
          <div class="field">
            <div class="field__label">
              <span class="sidebar-field__label-title">Data source</span>
              <span class="sidebar-field__label-additional">(optional)</span>
            </div>
            <div class="field__value">
              <ng-container
                *ngIf="
                  form.form.controls['access_token'].valid &&
                  form.form.controls['domain'].valid &&
                  form.form.controls['workspace_id'].valid &&
                  !loadingDataSources
                "
              >
                <app-select
                  [control]="form.form.controls['data_source']"
                  [options]="dataSourceOptions"
                  [fill]="true"
                ></app-select>
                <app-field-errors [form]="form.form" [fieldName]="'data_source'"></app-field-errors>
              </ng-container>

              <div
                *ngIf="
                  !form.form.controls['access_token'].valid ||
                  !form.form.controls['domain'].valid ||
                  !form.form.controls['workspace_id'].valid ||
                  loadingDataSources
                "
                class="input input_fill input_disabled"
              >
                <span [class.loading-animation]="loadingDataSources"><span class="stub-text">DataSource</span></span>
              </div>
            </div>
          </div>
        </app-page-block>

        <app-page-block>
          <div class="field">
            <div class="field__label">
              <span class="sidebar-field__label-title">Branch</span>
              <span class="sidebar-field__label-additional">(optional)</span>
            </div>
            <div class="field__value">
              <ng-container
                *ngIf="
                  form.form.controls['access_token'].valid &&
                  form.form.controls['domain'].valid &&
                  form.form.controls['workspace_id'].valid &&
                  !loadingDataSources
                "
              >
                <app-select
                  [control]="form.form.controls['branch']"
                  [options]="branchOptions"
                  [fill]="true"
                ></app-select>
                <app-field-errors [form]="form.form" [fieldName]="'branch'"></app-field-errors>
              </ng-container>

              <div
                *ngIf="
                  !form.form.controls['access_token'].valid ||
                  !form.form.controls['domain'].valid ||
                  !form.form.controls['workspace_id'].valid ||
                  loadingDataSources
                "
                class="input input_fill input_disabled"
              >
                <span [class.loading-animation]="loadingBranches"><span class="stub-text">Branch</span></span>
              </div>
            </div>
          </div>
        </app-page-block>

        <app-page-block>
          <a [appDocumentation]="'integrations/xano'" class="button button_small" style="margin: 0;">
            Read documentation
          </a>
        </app-page-block>

        <app-page-block *ngIf="(form.form | appFormErrors).length">
          <app-resource-settings-submit-error [errors]="form.form | appFormErrors"></app-resource-settings-submit-error>
        </app-page-block>
      </div>

      <div class="popup-content__submit">
        <button
          *ngIf="resourceDelete && resource"
          type="button"
          class="button button_danger popup-content__delete"
          (click)="requestDelete()"
        >
          Delete
        </button>
        <button type="button" class="button popup-content__submit-item" (click)="cancel()">
          Cancel
        </button>
        <button
          type="button"
          class="button button_primary button_shadow popup-content__submit-item"
          [class.button_disabled]="!form.isValid() || loadingSubmit"
          (click)="resource ? submit() : chooseSync()"
        >
          <app-loader-small *ngIf="loadingSubmit" class="button__icon button__icon_left"></app-loader-small>
          <ng-container *ngIf="resource">Update resource</ng-container>
          <ng-container *ngIf="!resource">Add resource</ng-container>
        </button>
      </div>

      <ng-container data-side>
        <p class="resource-popup__banner-description">
          Create Access Token in Xano to connect to your database. Xano > Account > Manage Access Tokens > New Access
          Token > Create
        </p>
        <video
          class="resource-popup__banner-video resource-popup__banner-video_zoom"
          [src]="'/assets/video/xano-key.mp4' | appDeployUrl"
          autoplay
          loop
          muted
          [appLightbox]="'/assets/video/xano-key.mp4' | appDeployUrl"
          [appLightboxVideo]="true"
          [appLightboxOrigin]="self"
          #self
        ></video>
      </ng-container>
    </app-popup2-side>
  </div>
</form>

<app-resource-settings-stub *ngIf="loading" [typeItem]="typeItem"></app-resource-settings-stub>
