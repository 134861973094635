import { TintStyle } from '@modules/actions';
import { AllProjectSettings } from '@modules/all-project-settings';
import { fadeColorStr, getColorHexAStr } from '@modules/colors';
import { getTintColors } from '@modules/colors-shared';
import { TextStyle, TextStyleGlobalName, TextStyleGlobalType } from '@modules/customize';
import { TextTransform } from '@modules/styles';
import { headingFontName, regularFontName } from '@modules/theme';
import { isSet } from '@shared';

export type TextStyleDefaults = Partial<TextStyle>;

export interface TextStyleGlobalParams {
  settings: AllProjectSettings;
  [k: string]: unknown;
}

export interface ActionTextStyleGlobalParams extends TextStyleGlobalParams {
  tint?: TintStyle;
}

export interface TextStyleGlobal<P extends TextStyleGlobalParams = TextStyleGlobalParams> {
  name: TextStyleGlobalName;
  defaults: (params: P) => TextStyleDefaults;
}

export const textStyleGlobals: TextStyleGlobal[] = [
  {
    name: TextStyleGlobalType.regularTitleTextStyle,
    defaults: ({ settings }) => {
      return {
        color: settings && isSet(settings.textColor) ? settings.textColor : '#1D2329',
        colorDark: settings && isSet(settings.textColorDark) ? settings.textColorDark : '#FFFFFF',
        fontFamily: headingFontName,
        fontSize: 18,
        fontWeight: 400
      };
    }
  },
  {
    name: TextStyleGlobalType.listGroupTitleTextStyle,
    defaults: ({ settings }) => {
      return {
        color: settings && isSet(settings.textColor2) ? settings.textColor2 : '#768191',
        colorDark: settings && isSet(settings.textColor2Dark) ? settings.textColor2Dark : '#9CA5AF',
        backgroundColor: '#0000000D',
        backgroundColorDark: '#FFFFFF0D',
        fontFamily: regularFontName,
        fontSize: 10,
        fontWeight: 500,
        transform: TextTransform.Uppercase
      };
    }
  },
  {
    name: TextStyleGlobalType.tableGroupTitleTextStyle,
    defaults: ({ settings }) => {
      return {
        color: settings && isSet(settings.textColor2) ? settings.textColor2 : '#768191',
        colorDark: settings && isSet(settings.textColor2Dark) ? settings.textColor2Dark : '#9CA5AF',
        backgroundColor: '#0000000D',
        backgroundColorDark: '#FFFFFF0D',
        fontFamily: regularFontName,
        fontSize: 10,
        fontWeight: 500,
        transform: TextTransform.Uppercase
      };
    }
  },
  {
    name: TextStyleGlobalType.kanbanStageTitleTextStyle,
    defaults: ({ settings }) => {
      return {
        color: settings && isSet(settings.textColor2) ? settings.textColor2 : '#768191',
        colorDark: settings && isSet(settings.textColor2Dark) ? settings.textColor2Dark : '#9CA5AF',
        backgroundColor: '#0000000D',
        backgroundColorDark: '#FFFFFF0D',
        fontFamily: regularFontName,
        fontSize: 10,
        fontWeight: 500,
        transform: TextTransform.Uppercase
      };
    }
  },
  {
    name: TextStyleGlobalType.fieldLabelTextStyle,
    defaults: ({ settings }) => {
      return {
        color: settings && isSet(settings.textColor2) ? settings.textColor2 : '#768191',
        colorDark: settings && isSet(settings.textColor2Dark) ? settings.textColor2Dark : '#9CA5AF',
        fontFamily: regularFontName,
        fontSize: 11,
        fontWeight: 500,
        transform: TextTransform.Uppercase
      };
    }
  },
  {
    name: TextStyleGlobalType.fieldLabelAdditionalTextStyle,
    defaults: ({ settings }) => {
      return {
        color: settings && isSet(settings.textColor3) ? settings.textColor3 : '#BAC3D0',
        colorDark: settings && isSet(settings.textColor3Dark) ? settings.textColor3Dark : '#5F6B78',
        fontFamily: regularFontName,
        fontSize: 11,
        fontWeight: 500,
        transform: TextTransform.Uppercase
      };
    }
  },
  {
    name: TextStyleGlobalType.valueWidgetTitleTextStyle,
    defaults: ({ settings }) => {
      return {
        color: settings && isSet(settings.textColor2) ? settings.textColor2 : '#768191',
        colorDark: settings && isSet(settings.textColor2Dark) ? settings.textColor2Dark : '#616A75',
        fontFamily: settings && isSet(settings.fontRegular) ? settings.fontRegular : undefined,
        fontSize: 14,
        fontWeight: 400
      };
    }
  },
  {
    name: TextStyleGlobalType.valueWidgetValueTextStyle,
    defaults: ({ settings }) => {
      return {
        color: settings && isSet(settings.textColor) ? settings.textColor : '#1E1F22',
        colorDark: settings && isSet(settings.textColorDark) ? settings.textColorDark : '#FFFFFF',
        fontFamily: regularFontName,
        fontSize: 30
      };
    }
  },
  {
    name: TextStyleGlobalType.collapseTitleTextStyle,
    defaults: ({ settings }) => {
      return {
        color: settings && isSet(settings.textColor2) ? settings.textColor2 : '#768191',
        colorDark: settings && isSet(settings.textColor2Dark) ? settings.textColor2Dark : '#9CA5AF',
        fontFamily: regularFontName,
        fontSize: 14,
        fontWeight: 500
      };
    }
  },
  ...([
    {
      name: TextStyleGlobalType.buttonTextStyle,
      defaults: ({ settings, tint }) => {
        const colors = getTintColors(settings.accentColor || '#2B50ED', tint);
        const colorsDark = getTintColors(settings.accentColorDark || '#2B50ED', tint);

        return {
          ...(colors &&
            colors.textColor && {
              color: getColorHexAStr(colors.textColor)
            }),
          ...(colorsDark &&
            colorsDark.textColor && {
              colorDark: getColorHexAStr(colorsDark.textColor)
            }),
          fontFamily: regularFontName,
          fontSize: 12,
          fontWeight: 500
        };
      }
    },
    {
      name: TextStyleGlobalType.hoverButtonTextStyle,
      defaults: ({ settings, tint }) => {
        const colors = getTintColors(settings.accentColor || '#2B50ED', tint);
        const colorsDark = getTintColors(settings.accentColorDark || '#2B50ED', tint);

        return {
          ...(colors &&
            colors.textHoverColor && {
              color: getColorHexAStr(colors.textHoverColor)
            }),
          ...(colorsDark &&
            colorsDark.textHoverColor && {
              colorDark: getColorHexAStr(colorsDark.textHoverColor)
            }),
          fontFamily: regularFontName,
          fontSize: 12,
          fontWeight: 500
        };
      }
    },
    {
      name: TextStyleGlobalType.activeButtonTextStyle,
      defaults: ({ settings, tint }) => {
        const colors = getTintColors(settings.accentColor || '#2B50ED', tint);
        const colorsDark = getTintColors(settings.accentColorDark || '#2B50ED', tint);

        return {
          ...(colors &&
            colors.textActiveColor && {
              color: getColorHexAStr(colors.textActiveColor)
            }),
          ...(colorsDark &&
            colorsDark.textActiveColor && {
              colorDark: getColorHexAStr(colorsDark.textActiveColor)
            }),
          fontFamily: regularFontName,
          fontSize: 12,
          fontWeight: 500
        };
      }
    }
  ] as TextStyleGlobal<ActionTextStyleGlobalParams>[]),
  ...[
    TextStyleGlobalType.fieldTextStyle,
    TextStyleGlobalType.hoverFieldTextStyle,
    TextStyleGlobalType.focusFieldTextStyle,
    TextStyleGlobalType.errorFieldTextStyle
  ].map(item => {
    return {
      name: item,
      defaults: ({ settings }) => {
        return {
          color: settings && isSet(settings.textColor2) ? settings.textColor2 : '#768191',
          colorDark: settings && isSet(settings.textColor2Dark) ? settings.textColor2Dark : '#9CA5AF',
          fontFamily: regularFontName,
          fontSize: 14,
          fontWeight: 400
        };
      }
    };
  }),
  ...[
    TextStyleGlobalType.fieldPlaceholderTextStyle,
    TextStyleGlobalType.hoverFieldPlaceholderTextStyle,
    TextStyleGlobalType.focusFieldPlaceholderTextStyle,
    TextStyleGlobalType.errorFieldPlaceholderTextStyle
  ].map(item => {
    return {
      name: item,
      defaults: ({ settings }) => {
        return {
          color: settings && isSet(settings.textColor2) ? fadeColorStr(settings.textColor2, 0.5) : '#76819180',
          colorDark:
            settings && isSet(settings.textColor2Dark) ? fadeColorStr(settings.textColor2Dark, 0.5) : '#9CA5AF80',
          fontFamily: regularFontName,
          fontSize: 14,
          fontWeight: 400
        };
      }
    };
  })
];
