import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional
} from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import isEqual from 'lodash/isEqual';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, skip, tap } from 'rxjs/operators';

import { UniqueIdToken } from '@common/unique-id';
import { ActionControllerService } from '@modules/action-queries';
import {
  CustomizeService,
  ElementType,
  FROM_OUTPUT,
  RangeSliderElementItem,
  registerElementComponent,
  TO_OUTPUT,
  ViewContextElement
} from '@modules/customize';
import { BaseElementComponent } from '@modules/customize-elements';
import { applyParamInput$, createFormFieldFactory, FieldType, LOADING_VALUE, ParameterField } from '@modules/fields';
import { controlValue, isSet, setControlDisabled, TypedChanges } from '@shared';

import { CustomPagePopupComponent } from '../custom-page-popup/custom-page-popup.component';

@Component({
  selector: 'app-range-slider-element',
  templateUrl: './range-slider-element.component.html',
  providers: [ViewContextElement],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RangeSliderElementComponent extends BaseElementComponent implements OnInit, OnDestroy, OnChanges {
  @Input() element: RangeSliderElementItem;

  idToken = new UniqueIdToken();
  createField = createFormFieldFactory();
  customizeEnabled$: Observable<boolean>;
  contextSubscription: Subscription;
  fromControl = new FormControl(null);
  toControl = new FormControl(null);
  form = new FormGroup({ from: this.fromControl, to: this.toControl });
  defaultMinValue = 1;
  defaultMaxValue = 10;
  defaultStepSize = 1;
  minValue = this.defaultMinValue;
  minValueLabel: string;
  maxValue = this.defaultMaxValue;
  maxValueLabel: string;
  normalValue: number;
  normalValueLabel: string;
  stepSize = this.defaultStepSize;

  constructor(
    private customizeService: CustomizeService,
    public viewContextElement: ViewContextElement,
    private actionControllerService: ActionControllerService,
    private injector: Injector,
    private cd: ChangeDetectorRef,
    @Optional() private popup: CustomPagePopupComponent
  ) {
    super();
  }

  ngOnInit() {
    this.customizeEnabled$ = this.customizeService.enabled$.pipe(map(item => !!item));
    this.updateParams();
    this.initContext();
    this.updateContextOutputs();
    this.updateContextActions();
    this.initContextObserver();

    controlValue<number>(this.fromControl)
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        const error = !!this.fromControl.errors;
        this.viewContextElement.setOutputValue(FROM_OUTPUT, value, { error: error });
      });

    controlValue<number>(this.toControl)
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        const error = !!this.toControl.errors;
        this.viewContextElement.setOutputValue(TO_OUTPUT, value, { error: error });
      });

    combineLatest(
      controlValue<number>(this.fromControl, { debounce: 200 }),
      controlValue<number>(this.toControl, { debounce: 200 })
    )
      .pipe(
        distinctUntilChanged((lhs, rhs) => isEqual(lhs, rhs)),
        skip(1),
        untilDestroyed(this)
      )
      .subscribe(([from, to]) => {
        this.element.onChangeActions.forEach(action => {
          this.actionControllerService
            .execute(action, {
              context: this.context,
              contextElement: this.viewContextElement,
              localContext: {
                [FROM_OUTPUT]: from,
                [TO_OUTPUT]: to
              },
              injector: this.injector
            })
            .subscribe();
        });
      });
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<RangeSliderElementComponent>): void {
    if (changes.element) {
      this.updateParams();
      this.initContextObserver();
    }

    if (changes.element && !changes.element.firstChange) {
      this.viewContextElement.initInfo({ name: this.element.name, element: this.element }, true);
    }
  }

  updateParams() {
    const validators: ValidatorFn[] = this.element.required ? [Validators.required] : [];

    this.fromControl.setValidators(validators);
    this.fromControl.updateValueAndValidity();

    this.toControl.setValidators(validators);
    this.toControl.updateValueAndValidity();
  }

  initContext() {
    this.viewContextElement.initElement({
      uniqueName: this.element.uid,
      name: this.element.name,
      icon: 'settings',
      allowSkip: true,
      element: this.element,
      popup: this.popup ? this.popup.popup : undefined
    });
  }

  updateContextOutputs() {
    this.viewContextElement.setOutputs([
      {
        uniqueName: FROM_OUTPUT,
        name: 'From',
        icon: 'align_horizontal_left',
        fieldType: FieldType.Number,
        external: true
      },
      {
        uniqueName: TO_OUTPUT,
        name: 'To',
        icon: 'align_horizontal_right',
        fieldType: FieldType.Number,
        external: true
      }
    ]);
  }

  updateContextActions() {
    const valueParameter = new ParameterField();

    valueParameter.name = 'value';
    valueParameter.field = FieldType.Number;

    this.viewContextElement.setActions([
      {
        uniqueName: 'set_from_value',
        name: 'Set From value',
        icon: 'edit',
        parameters: [valueParameter],
        handler: params => {
          this.fromControl.setValue(params['value']);
        }
      },
      {
        uniqueName: 'set_to_value',
        name: 'Set To value',
        icon: 'edit',
        parameters: [valueParameter],
        handler: params => {
          this.toControl.setValue(params['value']);
        }
      }
    ]);
  }

  initContextObserver() {
    if (this.contextSubscription) {
      this.contextSubscription.unsubscribe();
    }

    const from$ = this.element.from
      ? applyParamInput$(this.element.from, {
          context: this.context,
          defaultValue: null
        }).pipe(distinctUntilChanged())
      : of(null);
    const to$ = this.element.to
      ? applyParamInput$(this.element.to, {
          context: this.context,
          defaultValue: null
        }).pipe(distinctUntilChanged())
      : of(null);
    const minValue$ = this.element.minValueInput
      ? applyParamInput$(this.element.minValueInput, {
          context: this.context,
          defaultValue: null
        }).pipe(distinctUntilChanged())
      : of(null);
    const maxValue$ = this.element.maxValueInput
      ? applyParamInput$(this.element.maxValueInput, {
          context: this.context,
          defaultValue: null
        }).pipe(distinctUntilChanged())
      : of(null);
    const stepSize$ = this.element.stepSizeInput
      ? applyParamInput$(this.element.stepSizeInput, {
          context: this.context,
          defaultValue: null
        }).pipe(distinctUntilChanged())
      : of(null);
    const disabled$ = this.element.disableInput
      ? applyParamInput$<boolean>(this.element.disableInput, {
          context: this.context,
          defaultValue: false,
          handleLoading: true
        }).pipe(
          distinctUntilChanged(),
          map(disabled => {
            if (disabled === LOADING_VALUE) {
              return true;
            } else {
              return disabled;
            }
          })
        )
      : of(false);

    this.contextSubscription = combineLatest(
      from$.pipe(
        filter(value => isSet(value) && this.fromControl.value != value),
        tap(value => this.fromControl.patchValue(value))
      ),
      to$.pipe(
        filter(value => isSet(value) && this.toControl.value != value),
        tap(value => this.toControl.patchValue(value))
      ),
      minValue$.pipe(
        tap(value => {
          this.minValue = isSet(value) ? value : this.defaultMinValue;
          this.cd.markForCheck();
        })
      ),
      maxValue$.pipe(
        tap(value => {
          this.maxValue = isSet(value) ? value : this.defaultMaxValue;
          this.cd.markForCheck();
        })
      ),
      stepSize$.pipe(
        tap(value => {
          this.stepSize = isSet(value) ? value : this.defaultStepSize;
          this.cd.markForCheck();
        })
      ),
      disabled$.pipe(
        tap(disabled => {
          setControlDisabled(this.fromControl, disabled);
          setControlDisabled(this.toControl, disabled);
        })
      )
    )
      .pipe(untilDestroyed(this))
      .subscribe();
  }
}

registerElementComponent({
  type: ElementType.RangeSlider,
  component: RangeSliderElementComponent,
  label: 'Range Slider',
  actions: []
});
