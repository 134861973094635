import {
  CdkConnectedOverlay,
  CdkOverlayOrigin,
  ConnectedOverlayPositionChange,
  ConnectedPosition
} from '@angular/cdk/overlay';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fromEvent, Subscription } from 'rxjs';

import { TextStyleControl } from '../text-style-edit/text-style.control';
import { textStyleEditOverlayPositions } from './text-style-edit-overlay-positions';

@Component({
  selector: 'app-text-style-edit-overlay',
  templateUrl: './text-style-edit-overlay.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextStyleEditOverlayComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() control: TextStyleControl;
  @Input() origin: CdkOverlayOrigin;
  @Input() trigger: HTMLElement;
  @Input() triggerManual = false;
  @Input() openedInitial = false;
  @Input() popoverPositions: ConnectedPosition[] = textStyleEditOverlayPositions;
  @Input() resetEnabled = true;

  @ViewChild(CdkConnectedOverlay) cdkConnectedOverlay: CdkConnectedOverlay;

  dropdownOpened = false;
  popoverPositionsSubscription: Subscription;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {
    if (!this.triggerManual) {
      let trigger: HTMLElement;

      if (this.trigger) {
        trigger = this.trigger;
      } else if (this.origin) {
        trigger = this.origin.elementRef.nativeElement;
      }

      if (trigger) {
        fromEvent(trigger, 'click')
          .pipe(untilDestroyed(this))
          .subscribe(() => {
            this.open();
          });
      }
    }

    if (this.openedInitial) {
      this.dropdownOpened = true;
    }
  }

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    this.setPositionObserver();
  }

  setDropdownOpened(value: boolean) {
    this.dropdownOpened = value;
    this.cd.markForCheck();
  }

  public isOpened(): boolean {
    return this.dropdownOpened;
  }

  public open() {
    this.setDropdownOpened(true);
  }

  public close() {
    this.setDropdownOpened(false);
  }

  setPositionObserver() {
    if (this.popoverPositionsSubscription) {
      this.popoverPositionsSubscription.unsubscribe();
    }

    if (!this.cdkConnectedOverlay) {
      return;
    }

    this.popoverPositionsSubscription = this.cdkConnectedOverlay.positionChange
      .pipe(untilDestroyed(this))
      .subscribe((e: ConnectedOverlayPositionChange) => {
        const propsEqual = ['offsetX', 'offsetY', 'originX', 'originY', 'overlayX', 'overlayY'];
        const position = this.popoverPositions.find(item =>
          propsEqual.every(prop => (item[prop] || undefined) == e.connectionPair[prop])
        );
        const otherPosition = this.popoverPositions.filter(item => item !== position);

        if (position) {
          this.cdkConnectedOverlay.overlayRef.addPanelClass(position.panelClass);
        }

        otherPosition.forEach(item => this.cdkConnectedOverlay.overlayRef.removePanelClass(item.panelClass));
      });
  }
}
