import { Pipe, PipeTransform } from '@angular/core';

import { CardLayoutElementItem, ElementWrapperStyles, getElementWrapperStyles } from '@modules/customize';

@Pipe({
  name: 'appElementWrapperStyles',
  pure: false
})
export class ElementWrapperStylesPipe implements PipeTransform {
  transform(element: CardLayoutElementItem): ElementWrapperStyles {
    return getElementWrapperStyles(element);
  }
}
