<div class="inline-input" [class.inline-input_edit]="editorActive && (editable$ | async)">
  <ng-container *ngIf="editable$ | async">
    <div class="inline-input__content">
      <span
        class="inline-input-content"
        (click)="activateEditor()"
        cdkOverlayOrigin
        #content_trigger="cdkOverlayOrigin"
        (mouseenter)="contentHover$.next(true)"
        (mouseleave)="contentHover$.next(false)"
      >
        <!--        <ng-container *ngIf="!editorActive">-->
        <!--          <span class="inline-input-content__icon inline-input-content__icon_normal icon-function"></span>-->
        <!--          <span class="inline-input-content__icon inline-input-content__icon_hover icon-pen"></span>-->
        <!--        </ng-container>-->
        <!--        <span *ngIf="editorActive" class="inline-input-content__icon icon-pen"></span>-->

        <span
          class="inline-input-content__main"
          [innerHTML]="
            itemForm
              | appControlValue
              | appThrottleTime: 200:{ leading: true, trailing: true }
              | async
              | appFieldInput
              | appApplyParamInput: { context: context, defaultValue: '' }
              | async
          "
        ></span>
      </span>
    </div>

    <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayPanelClass]="['overlay']"
      [cdkConnectedOverlayPositions]="editorPositions"
      [cdkConnectedOverlayOrigin]="content_trigger"
      [cdkConnectedOverlayOpen]="(editorOpened$ | async) || editorActive"
      [cdkConnectedOverlayPush]="true"
      [cdkConnectedOverlayGrowAfterOpen]="true"
      [cdkConnectedOverlayHasBackdrop]="true"
      [cdkConnectedOverlayBackdropClass]="'popover2-backdrop2'"
      (attach)="onEditorOpened()"
      (backdropClick)="deactivateEditor()"
      (detach)="deactivateEditor()"
      #editor_overlay
    >
      <div
        (cdkObserveContent)="onEditorOverlayContentChanged()"
        (mouseenter)="editorHover$.next(true)"
        (mouseleave)="editorHover$.next(false)"
        (click)="activateEditor()"
        #input_popover
      >
        <div *ngIf="itemForm.controls.path.value?.length" class="inline-input__editor">
          <ng-container
            *ngIf="!itemForm.controls.value_type.value || itemForm.controls.value_type.value == inputValueTypes.Formula"
          >
            <div class="inline-input-editor" cdkOverlayOrigin #input_origin="cdkOverlayOrigin">
              <div class="input-icon-wrapper">
                <app-input-edit-formula-value
                  [control]="control"
                  [context]="context"
                  [contextElement]="contextElement"
                  [contextElementPath]="contextElementPath"
                  [contextElementPaths]="contextElementPaths"
                  [contextValueEnabled]="false"
                  [focusedInitial]="focusedInitial"
                  [placeholder]="placeholder"
                  [displayJsType]="true"
                  [fill]="true"
                  [fillVertical]="false"
                  [inline]="true"
                  [resultShowOnFocus]="false"
                  [popoverOrigin]="input_origin"
                  [popoverForcedOpened]="editorActive"
                  (popoverOpened)="onEditorOpened()"
                  (switchToTextInputs)="switchToTextInputs()"
                  (switchToJs)="switchToJs()"
                ></app-input-edit-formula-value>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="itemForm.controls.value_type.value == inputValueTypes.Js">
            <div class="inline-input-editor" cdkOverlayOrigin #input_origin="cdkOverlayOrigin">
              <app-input-edit-js-value
                [itemForm]="itemForm"
                [context]="context"
                [contextElement]="contextElement"
                [contextElementPath]="contextElementPath"
                [contextElementPaths]="contextElementPaths"
                [staticValueDisabled]="true"
                [placeholder]="jsPlaceholder"
                [displayValueTypesEnabled]="true"
                [extraSections]="[]"
                [classes]="classes"
                [fill]="true"
                [fillVertical]="false"
                [inline]="true"
                [resultShowOnFocus]="false"
                [popoverOrigin]="input_origin"
                [popoverForcedOpened]="editorActive"
                (switchToFormula)="switchToFormula()"
              ></app-input-edit-js-value>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-template>
  </ng-container>

  <ng-container *ngIf="!(editable$ | async)">{{
    itemForm
      | appControlValue
      | appThrottleTime: 200:{ leading: true, trailing: true }
      | async
      | appFieldInput
      | appApplyParamInput: { context: context, defaultValue: '' }
      | async
  }}</ng-container>
</div>
