import keys from 'lodash/keys';
import pickBy from 'lodash/pickBy';

import { ViewSettingsAction } from '@modules/actions';
import { isSet } from '@shared';

import { BorderSettings } from './border-settings';
import { Corners } from './corners';
import { ActionElementItem } from './elements/items/action';
import { Margin } from './elements/items/base';
import { FormSubmitElementItem } from './elements/items/form-submit-element';
import { FillSettings } from './fill-settings';
import { IconSettings } from './icon-settings';
import { Shadow } from './shadow';
import { TextStyle } from './text-style';

export class ActionElementStyles {
  textStyle?: TextStyle;
  iconSettings?: IconSettings;
  fillSettings?: FillSettings;
  borderSettings?: BorderSettings;
  borderRadius?: Corners;
  shadow?: Shadow;
  padding?: Margin;
  margin?: Margin;

  hoverTextStyle?: TextStyle;
  hoverIconSettings?: IconSettings;
  hoverFillSettings?: FillSettings;
  hoverBorderSettings?: BorderSettings;
  hoverShadow?: Shadow;

  activeTextStyle?: TextStyle;
  activeIconSettings?: IconSettings;
  activeFillSettings?: FillSettings;
  activeBorderSettings?: BorderSettings;
  activeShadow?: Shadow;

  constructor(options: Partial<ActionElementStyles> = {}) {
    Object.assign(this, options);
  }

  deserialize(data: Object): this {
    if (data['text_style']) {
      this.textStyle = new TextStyle().deserialize(data['text_style']);
    } else {
      this.textStyle = undefined;
    }

    if (data['icon_settings']) {
      this.iconSettings = new IconSettings().deserialize(data['icon_settings']);
    } else {
      this.iconSettings = undefined;
    }

    if (data['fill_settings']) {
      this.fillSettings = new FillSettings().deserialize(data['fill_settings']);
    } else {
      this.fillSettings = undefined;
    }

    if (data['border_settings']) {
      this.borderSettings = new BorderSettings().deserialize(data['border_settings']);
    } else {
      this.borderSettings = undefined;
    }

    if (data['border_radius']) {
      this.borderRadius = data['border_radius'];
    } else {
      this.borderRadius = undefined;
    }

    if (data['shadow']) {
      this.shadow = new Shadow().deserialize(data['shadow']);
    } else {
      this.shadow = undefined;
    }

    if (data['padding']) {
      this.padding = data['padding'];
    } else {
      this.padding = undefined;
    }

    if (data['margin']) {
      this.margin = data['margin'];
    } else {
      this.margin = undefined;
    }

    if (data['hover_text_style']) {
      this.hoverTextStyle = new TextStyle().deserialize(data['hover_text_style']);
    } else {
      this.hoverTextStyle = undefined;
    }

    if (data['hover_icon_settings']) {
      this.hoverIconSettings = new IconSettings().deserialize(data['hover_icon_settings']);
    } else {
      this.hoverIconSettings = undefined;
    }

    if (data['hover_fill_settings']) {
      this.hoverFillSettings = new FillSettings().deserialize(data['hover_fill_settings']);
    } else {
      this.hoverFillSettings = undefined;
    }

    if (data['hover_border_settings']) {
      this.hoverBorderSettings = new BorderSettings().deserialize(data['hover_border_settings']);
    } else {
      this.hoverBorderSettings = undefined;
    }

    if (data['hover_shadow']) {
      this.hoverShadow = new Shadow().deserialize(data['hover_shadow']);
    } else {
      this.hoverShadow = undefined;
    }

    if (data['active_text_style']) {
      this.activeTextStyle = new TextStyle().deserialize(data['active_text_style']);
    } else {
      this.activeTextStyle = undefined;
    }

    if (data['active_icon_settings']) {
      this.activeIconSettings = new IconSettings().deserialize(data['active_icon_settings']);
    } else {
      this.activeIconSettings = undefined;
    }

    if (data['active_fill_settings']) {
      this.activeFillSettings = new FillSettings().deserialize(data['active_fill_settings']);
    } else {
      this.activeFillSettings = undefined;
    }

    if (data['active_border_settings']) {
      this.activeBorderSettings = new BorderSettings().deserialize(data['active_border_settings']);
    } else {
      this.activeBorderSettings = undefined;
    }

    if (data['active_shadow']) {
      this.activeShadow = new Shadow().deserialize(data['active_shadow']);
    } else {
      this.activeShadow = undefined;
    }

    return this;
  }

  serialize(): Object {
    return {
      text_style: this.textStyle ? this.textStyle.serialize() : undefined,
      icon_settings: this.iconSettings ? this.iconSettings.serialize() : undefined,
      fill_settings: this.fillSettings ? this.fillSettings.serialize() : undefined,
      border_settings: this.borderSettings ? this.borderSettings.serialize() : undefined,
      border_radius: this.borderRadius,
      shadow: this.shadow ? this.shadow.serialize() : undefined,
      padding: this.padding,

      hover_text_style: this.hoverTextStyle ? this.hoverTextStyle.serialize() : undefined,
      hover_icon_settings: this.hoverIconSettings ? this.hoverIconSettings.serialize() : undefined,
      hover_fill_settings: this.hoverFillSettings ? this.hoverFillSettings.serialize() : undefined,
      hover_border_settings: this.hoverBorderSettings ? this.hoverBorderSettings.serialize() : undefined,
      hover_shadow: this.hoverShadow ? this.hoverShadow.serialize() : undefined,

      active_text_style: this.activeTextStyle ? this.activeTextStyle.serialize() : undefined,
      active_icon_settings: this.activeIconSettings ? this.activeIconSettings.serialize() : undefined,
      active_fill_settings: this.activeFillSettings ? this.activeFillSettings.serialize() : undefined,
      active_border_settings: this.activeBorderSettings ? this.activeBorderSettings.serialize() : undefined,
      active_shadow: this.activeShadow ? this.activeShadow.serialize() : undefined
    };
  }

  apply(other?: Partial<this>): this {
    const properties = [
      'textStyle',
      'iconSettings',
      'fillSettings',
      'borderSettings',
      'borderRadius',
      'shadow',
      'padding',
      'margin',

      'hoverTextStyle',
      'hoverIconSettings',
      'hoverFillSettings',
      'hoverBorderSettings',
      'hoverShadow',

      'activeTextStyle',
      'activeIconSettings',
      'activeFillSettings',
      'activeBorderSettings',
      'activeShadow'
    ];

    properties.forEach(property => {
      if (other && isSet(other[property])) {
        this[property] = other[property];
      }
    });

    return this;
  }
}

export function getActionElementStyles(
  element: ActionElementItem | ViewSettingsAction | FormSubmitElementItem
): ActionElementStyles {
  const options: Partial<ActionElementStyles> = pickBy(
    {
      textStyle: element.textStyle,
      iconSettings: element.iconSettings,
      fillSettings: element.fillSettings,
      borderSettings: element.borderSettings,
      borderRadius: element.borderRadius,
      shadow: element.shadow,
      padding: element.padding,
      ...((element instanceof ActionElementItem || element instanceof FormSubmitElementItem) &&
        keys(element.margin).length && {
          margin: element.margin
        }),

      hoverTextStyle: element.hoverTextStyle,
      hoverIconSettings: element.hoverIconSettings,
      hoverFillSettings: element.hoverFillSettings,
      hoverBorderSettings: element.hoverBorderSettings,
      hoverShadow: element.hoverShadow,

      activeTextStyle: element.activeTextStyle,
      activeIconSettings: element.activeIconSettings,
      activeFillSettings: element.activeFillSettings,
      activeBorderSettings: element.activeBorderSettings,
      activeShadow: element.activeShadow
    },
    v => isSet(v)
  );

  if (!keys(options).length) {
    return;
  }

  return new ActionElementStyles(options);
}

export function applyActionElementStyles(
  element: ActionElementItem | ViewSettingsAction | FormSubmitElementItem,
  styles?: ActionElementStyles
) {
  if (!styles) {
    styles = new ActionElementStyles();
  }

  element.textStyle = styles.textStyle;
  element.iconSettings = styles.iconSettings;
  element.fillSettings = styles.fillSettings;
  element.borderSettings = styles.borderSettings;
  element.borderRadius = styles.borderRadius;
  element.shadow = styles.shadow;
  element.padding = styles.padding;

  if (element instanceof ActionElementItem || element instanceof FormSubmitElementItem) {
    element.margin = styles.margin;
  }

  element.hoverTextStyle = styles.hoverTextStyle;
  element.hoverIconSettings = styles.hoverIconSettings;
  element.hoverFillSettings = styles.hoverFillSettings;
  element.hoverBorderSettings = styles.hoverBorderSettings;
  element.hoverShadow = styles.hoverShadow;

  element.activeTextStyle = styles.activeTextStyle;
  element.activeIconSettings = styles.activeIconSettings;
  element.activeFillSettings = styles.activeFillSettings;
  element.activeBorderSettings = styles.activeBorderSettings;
  element.activeShadow = styles.activeShadow;
}
