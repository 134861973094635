import { FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { controlValue, isSet } from '@shared';

import { BorderSettings } from './border-settings';
import { BorderControl } from './border.control';

export class BorderSettingsControl extends FormGroup {
  instance: BorderSettings;

  controls: {
    per_sides: FormControl;
    border: BorderControl;
    border_top: BorderControl;
    border_right: BorderControl;
    border_bottom: BorderControl;
    border_left: BorderControl;
  };

  borderControls: BorderControl[] = [
    this.controls.border,
    this.controls.border_top,
    this.controls.border_right,
    this.controls.border_bottom,
    this.controls.border_left
  ];

  constructor(state: Partial<BorderSettings> = {}) {
    super({
      per_sides: new FormControl(
        [state.borderTop, state.borderRight, state.borderBottom, state.borderLeft].some(item => isSet(item))
      ),
      border: new BorderControl(isSet(state.border) ? state.border : undefined),
      border_top: new BorderControl(isSet(state.borderTop) ? state.borderTop : undefined),
      border_right: new BorderControl(isSet(state.borderRight) ? state.borderRight : undefined),
      border_bottom: new BorderControl(isSet(state.borderBottom) ? state.borderBottom : undefined),
      border_left: new BorderControl(isSet(state.borderLeft) ? state.borderLeft : undefined)
    });
  }

  deserialize(value?: BorderSettings, options: { emitEvent?: boolean } = {}) {
    this.instance = value;

    this.controls.per_sides.patchValue(value ? value.isSidesSet() : false, { emitEvent: options.emitEvent });
    this.controls.border.deserialize(value ? value.border : undefined, { emitEvent: options.emitEvent });
    this.controls.border_top.deserialize(value ? value.borderTop : undefined, { emitEvent: options.emitEvent });
    this.controls.border_right.deserialize(value ? value.borderRight : undefined, { emitEvent: options.emitEvent });
    this.controls.border_bottom.deserialize(value ? value.borderBottom : undefined, { emitEvent: options.emitEvent });
    this.controls.border_left.deserialize(value ? value.borderLeft : undefined, { emitEvent: options.emitEvent });
  }

  isSet(): boolean {
    return this.borderControls.some(item => item.isSet());
  }

  isSet$(): Observable<boolean> {
    return controlValue(this).pipe(map(() => this.isSet()));
  }

  resetDefaults(options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    this.borderControls.forEach(item => {
      item.resetDefaults(options);
    });
  }

  reset(options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    this.borderControls.forEach(item => {
      item.reset(options);
    });
  }

  getInstance(instance?: BorderSettings): BorderSettings {
    if (!instance) {
      instance = new BorderSettings();
    }

    if (this.controls.per_sides.value) {
      instance.border = undefined;
      instance.borderTop = this.controls.border_top.getInstance(instance.borderTop);
      instance.borderRight = this.controls.border_right.getInstance(instance.borderRight);
      instance.borderBottom = this.controls.border_bottom.getInstance(instance.borderBottom);
      instance.borderLeft = this.controls.border_left.getInstance(instance.borderLeft);
    } else {
      instance.border = this.controls.border.getInstance(instance.border);
      instance.borderTop = undefined;
      instance.borderRight = undefined;
      instance.borderBottom = undefined;
      instance.borderLeft = undefined;
    }

    return instance;
  }

  serialize(reuseInstance = true): BorderSettings {
    if (!this.isSet()) {
      return;
    }

    return this.getInstance(reuseInstance ? this.instance : undefined);
  }

  serialize$(): Observable<BorderSettings> {
    return controlValue(this, { debounce: 200 }).pipe(map(() => this.serialize()));
  }
}
