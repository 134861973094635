import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren
} from '@angular/core';
import fromPairs from 'lodash/fromPairs';

import { ResizeEvent, ResizeType } from '@common/resizable';
import { UniversalAnalyticsService } from '@modules/analytics';
import { TableSettings, ViewContext, ViewContextElement } from '@modules/customize';
import { DataSourceType, ListModelDescriptionDataSource } from '@modules/data-sources';
import {
  DisplayField,
  DisplayFieldType,
  FieldType,
  Input as FieldInput,
  InputValueType,
  ParameterField
} from '@modules/fields';
import { FilterItem2, Sort } from '@modules/filters';
import { modelDescriptionHasAutoParameters } from '@modules/parameters';
import { QueryType } from '@modules/queries';

import { TableState } from '../table/table-state';

@Component({
  selector: 'app-table-header, [app-table-header]',
  templateUrl: './table-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableHeaderComponent implements OnInit, OnDestroy, OnChanges {
  @Input() state: TableState;
  @Input() params: Object;
  @Input() filters: FilterItem2[] = [];
  @Input() sort: Sort[] = [];
  @Input() settings: TableSettings;
  @Input() dataSource: ListModelDescriptionDataSource;
  @Input() inputs: FieldInput[] = [];
  @Input() parameters: ParameterField[] = [];
  @Input() customizing = false;
  @Input() columns: DisplayField[] = [];
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() theme = false;
  @Output() paramsChanged = new EventEmitter<Object>();
  @Output() filtersUpdated = new EventEmitter<FilterItem2[]>();
  @Output() sortChanged = new EventEmitter<Sort[]>();
  @Output() settingsChanged = new EventEmitter<TableSettings>();
  @Output() columnResize = new EventEmitter<{ index: number; width: number }>();
  @Output() columnResizeFinished = new EventEmitter<{ index: number; width: number }>();

  @ViewChildren('heading_column') headingColumns = new QueryList<ElementRef>();

  filterableColumns = {};
  resizeTypes = ResizeType;
  resizeColumnIndex: number;
  resizeColumnInitialWidth: number;
  resizeColumnWidth: number;

  constructor(private analyticsService: UniversalAnalyticsService, private cd: ChangeDetectorRef) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['inputs'] || changes['dataSource']) {
      this.updateFilterableColumns();
    }
  }

  updateFilterableColumns() {
    this.filterableColumns = fromPairs(
      this.inputs
        .filter(item => item.valueType == InputValueType.Filter)
        .map(input => {
          if (!this.dataSource) {
            return;
          }
          return this.dataSource.columns.find(item => item.name == input.filterField);
        })
        .filter(item => item != undefined)
        .map(item => [item.name, item])
    );
    this.cd.markForCheck();
  }

  setOrdering(field: DisplayField, desc = false) {
    if (field == undefined) {
      this.sortChanged.emit([]);
    } else {
      this.sortChanged.emit([{ field: field.name, desc: desc }]);
    }
  }

  toggleOrdering(field: DisplayField) {
    if (field == undefined) {
      this.sortChanged.emit([]);
    } else if (this.isOrderedAsc(field)) {
      this.sortChanged.emit([{ field: field.name, desc: true }]);
    } else if (this.isOrderedDesc(field)) {
      this.sortChanged.emit([{ field: field.name, desc: false }]);
    } else {
      this.sortChanged.emit([{ field: field.name, desc: false }]);
    }
  }

  isNumeric(field: DisplayField) {
    return [FieldType.Number, FieldType.DateTime, FieldType.Time].includes(field.field);
  }

  isSortable(field: DisplayField) {
    if (!this.dataSource) {
      return false;
    }

    if (field.type != DisplayFieldType.Base) {
      return false;
    }

    if (this.dataSource.type == DataSourceType.Query && this.dataSource.query) {
      if (modelDescriptionHasAutoParameters(this.state.resource, this.state.modelDescription)) {
        return true;
      } else if (this.dataSource.query.queryType == QueryType.Simple) {
        return (
          this.state.modelDescription &&
          this.state.modelDescription.getQuery &&
          this.state.modelDescription.getQuery.isFieldSortable(field)
        );
      } else {
        return this.dataSource.query.isFieldSortable(field);
      }
    } else if (this.dataSource.type == DataSourceType.Input) {
      return true;
    } else {
      return false;
    }
  }

  isOrderedAsc(field: DisplayField) {
    return this.sort.find(item => item.field == field.name && !item.desc);
  }

  isOrderedDesc(field: DisplayField) {
    return this.sort.find(item => item.field == field.name && item.desc);
  }

  isOrdered(field: DisplayField) {
    return this.isOrderedAsc(field) || this.isOrderedDesc(field);
  }

  trackByFn(i, item: DisplayField) {
    return item.name || i;
  }

  copySize(header: TableHeaderComponent) {
    const copyColumns = header.headingColumns.toArray();
    this.headingColumns.forEach((item, i) => {
      const headingColumn = copyColumns[i];

      if (!headingColumn) {
        return;
      }

      item.nativeElement.style.width = `${headingColumn.nativeElement.offsetWidth}px`;
      item.nativeElement.style['min-width'] = item.nativeElement.style.width;
    });
  }

  onResizeStarted(index: number) {
    const element = this.headingColumns.toArray()[index].nativeElement;

    this.resizeColumnIndex = index;
    this.resizeColumnInitialWidth = element.offsetWidth;
    this.resizeColumnWidth = this.resizeColumnInitialWidth;
    this.cd.markForCheck();
  }

  onResize(index: number, event: ResizeEvent) {
    this.resizeColumnWidth = this.resizeColumnInitialWidth + event.widthDelta;
    this.columnResize.emit({ index: index, width: this.resizeColumnWidth });
  }

  onResizeFinished(index: number) {
    const element = this.headingColumns.toArray()[index].nativeElement;

    this.resizeColumnIndex = undefined;
    this.resizeColumnInitialWidth = undefined;
    this.cd.markForCheck();
    this.columnResizeFinished.emit({ index: index, width: element.offsetWidth });
  }
}
