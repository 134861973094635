var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Injector } from '@angular/core';
import isArray from 'lodash/isArray';
import { of } from 'rxjs';
import { delay, filter, map } from 'rxjs/operators';
import { ActionService, WorkflowExecuteEventType } from '@modules/action-queries';
import { ServerRequestError } from '@modules/api';
import { DataSourceType } from '@modules/data-sources';
import { DisplayFieldType } from '@modules/fields';
import { ModelService } from '@modules/model-queries';
import { Model } from '@modules/models';
import { applyFrontendFiltering, applyFrontendPagination, applyFrontendSorting, getQueryOptionsToParams, ModelResponse, paramsToGetQueryOptions } from '@modules/resources';
var ModelDescriptionDataSourceService = /** @class */ (function () {
    function ModelDescriptionDataSourceService(modelService, actionService, injector) {
        this.modelService = modelService;
        this.actionService = actionService;
        this.injector = injector;
    }
    ModelDescriptionDataSourceService.prototype.createModel = function () {
        return Injector.create({
            providers: [{ provide: Model, deps: [Injector] }],
            parent: this.injector
        }).get(Model);
    };
    ModelDescriptionDataSourceService.prototype.createGetResponse = function () {
        return Injector.create({
            providers: [{ provide: ModelResponse.GetResponse, deps: [Injector] }],
            parent: this.injector
        }).get(ModelResponse.GetResponse);
    };
    ModelDescriptionDataSourceService.prototype.get = function (options) {
        var queryOptions = options.params ? paramsToGetQueryOptions(options.params) : {};
        return this.getAdv(__assign({}, options, { queryOptions: queryOptions }));
    };
    ModelDescriptionDataSourceService.prototype.getAdv = function (options) {
        var _this = this;
        var params = getQueryOptionsToParams(options.queryOptions);
        if (options.dataSource.type == DataSourceType.Query) {
            var resource = options.project
                .getEnvironmentResources(options.environment.uniqueName)
                .find(function (item) { return item.uniqueName == options.dataSource.queryResource; });
            return this.modelService.getQueryAdv(options.project, options.environment, resource, options.dataSource.query, options.dataSource.queryParameters, options.queryOptions, (options.dataSource.columns || []).filter(function (item) { return item.type != DisplayFieldType.Computed; }));
        }
        else if (options.dataSource.type == DataSourceType.Input) {
            var result = isArray(options.staticData) ? options.staticData : [options.staticData];
            result = applyFrontendFiltering(result, params, options.dataSource.columns);
            var data = {
                results: result,
                count: result.length
            };
            var response = this.createGetResponse().deserialize(data, undefined, undefined);
            response.results.forEach(function (item) {
                item.deserializeAttributes(options.dataSource.columns);
            });
            applyFrontendSorting(response, params);
            applyFrontendPagination(response, params, true);
            // TODO: No delay breaks pages
            return of(response).pipe(delay(0));
        }
        else if (options.dataSource.type == DataSourceType.Workflow) {
            return this.actionService
                .executeWorkflow(options.dataSource.workflow, params, {
                context: options.context,
                contextElement: options.contextElement,
                localContext: options.localContext
            })
                .pipe(filter(function (event) { return event.type == WorkflowExecuteEventType.WorkflowFinished; }), map(function (event) {
                if (event.error) {
                    throw new ServerRequestError(event.error);
                }
                return event.result;
            }), map(function (workflowResult) {
                var result = isArray(workflowResult) ? workflowResult : [workflowResult];
                result = applyFrontendFiltering(result, params, options.dataSource.columns);
                var data = {
                    results: result,
                    count: result.length
                };
                var response = _this.createGetResponse().deserialize(data, undefined, undefined);
                response.results.forEach(function (item) {
                    item.deserializeAttributes(options.dataSource.columns);
                });
                applyFrontendSorting(response, params);
                applyFrontendPagination(response, params, true);
                return response;
            }));
        }
        else {
            return of(undefined);
        }
    };
    ModelDescriptionDataSourceService.prototype.getDetail = function (options) {
        var queryOptions = options.params ? paramsToGetQueryOptions(options.params) : {};
        return this.getDetailAdv(__assign({}, options, { queryOptions: queryOptions }));
    };
    ModelDescriptionDataSourceService.prototype.getDetailAdv = function (options) {
        var _this = this;
        var params = getQueryOptionsToParams(options.queryOptions);
        if (options.dataSource.type == DataSourceType.Query) {
            var resource = options.project
                .getEnvironmentResources(options.environment.uniqueName)
                .find(function (item) { return item.uniqueName == options.dataSource.queryResource; });
            return this.modelService.getDetailQueryAdv(options.project, options.environment, resource, options.dataSource.query, options.dataSource.queryParameters, options.queryOptions, options.dataSource.columns || []);
        }
        else if (options.dataSource.type == DataSourceType.Input) {
            var result = isArray(options.staticData) ? options.staticData : [options.staticData];
            result = applyFrontendFiltering(result, params, options.dataSource.columns);
            var object = result[0];
            var model = this.createModel().deserialize(undefined, object);
            model.deserializeAttributes(options.dataSource.columns);
            // TODO: No delay breaks pages
            return of(model).pipe(delay(0));
        }
        else if (options.dataSource.type == DataSourceType.Workflow) {
            return this.actionService
                .executeWorkflow(options.dataSource.workflow, params, {
                context: options.context,
                contextElement: options.contextElement,
                localContext: options.localContext
            })
                .pipe(filter(function (event) { return event.type == WorkflowExecuteEventType.WorkflowFinished; }), map(function (event) {
                if (event.error) {
                    throw new ServerRequestError(event.error);
                }
                return event.result;
            }), map(function (workflowResult) {
                var result = isArray(workflowResult) ? workflowResult : [workflowResult];
                result = applyFrontendFiltering(result, params, options.dataSource.columns);
                var object = result[0];
                var model = _this.createModel().deserialize(undefined, object);
                model.deserializeAttributes(options.dataSource.columns);
                return model;
            }));
        }
        else {
            return of(undefined);
        }
    };
    return ModelDescriptionDataSourceService;
}());
export { ModelDescriptionDataSourceService };
