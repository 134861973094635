import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Injector,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Type,
  ViewChild
} from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { delay, filter, first, switchMap, take } from 'rxjs/operators';

import { NotificationService } from '@common/notifications';
import { SelectComponent } from '@common/select';
import { ActionService } from '@modules/action-queries';
import { modelFieldToDisplayField, ViewContext, ViewContextElement } from '@modules/customize';
import { DataSourceType } from '@modules/data-sources';
import { CustomSelectItem, CustomSelectItemButton, Option } from '@modules/field-components';
import {
  BaseField,
  createFormFieldFactory,
  DisplayField,
  FieldType,
  getFieldDescriptionByType,
  ParameterField
} from '@modules/fields';
import { ModelEditController, ModelQueryEditController } from '@modules/model-components';
import { ModelDescription, ModelFieldType } from '@modules/models';
import { ViewContextTokenProvider } from '@modules/parameters-components';
import { ResourceAddModelComponentItem, ResourceModelEditController } from '@modules/projects-components';
import {
  ListModelDescriptionQuery,
  ModelDescriptionQuery,
  ObjectQueryOperation,
  Query,
  QueryType
} from '@modules/queries';
import {
  HttpResultsSection,
  QueryBuilderContext,
  QueryBuilderSaveEvent,
  QueryBuilderService,
  SqlResultsSection
} from '@modules/queries-components';
import { RoutingService } from '@modules/routing';
import { Token } from '@modules/tokens';
import { Workflow } from '@modules/workflow';
import { controlValue, TypedChanges } from '@shared';

import { WorkflowEditController } from '../../services/workflow-edit-controller/workflow-edit.controller';
import { ModelDescriptionDataSourceControl } from './model-description-data-source';

@Component({
  selector: 'app-model-description-data-source-edit',
  templateUrl: './model-description-data-source-edit.component.html',
  providers: [QueryBuilderContext],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModelDescriptionDataSourceEditComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() control: ModelDescriptionDataSourceControl;
  @Input() label: string;
  @Input() typeOptions: Option<DataSourceType>[];
  @Input() typeIconButton = false;
  @Input() typeOrange = false;
  @Input() queryOptionEmptyLabel: string;
  @Input() search = false;
  @Input() extraTokens: BaseField[] = [];
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() contextElementPath: (string | number)[];
  @Input() contextElementPaths: (string | number)[][];
  @Input() resourceClasses: string | string[];
  @Input() queryOptionClasses: string | string[];
  @Input() analyticsSource: string;

  @ViewChild('type_select', { read: SelectComponent }) typeSelect: SelectComponent;

  createField = createFormFieldFactory();
  resourceFieldParams: Object;
  search$ = new BehaviorSubject<boolean>(false);
  extraTokens$ = new BehaviorSubject<BaseField[]>([]);
  reloadAllowed$: Observable<boolean>;
  reloadLoading = false;
  queryOptions$: Observable<CustomSelectItem<Query>[]>;
  queryEditable$: Observable<boolean>;
  queryConfigured$: Observable<boolean>;
  modelDescriptionCustom$: Observable<ModelDescription>;
  autoDetectedColumns$: Observable<DisplayField[]>;
  dataSourceTypes = DataSourceType;

  workflow: Workflow;
  parameters: ParameterField[] = [];

  constructor(
    private queryContext: QueryBuilderContext,
    private resourceModelEditController: ResourceModelEditController,
    private queryBuilderService: QueryBuilderService,
    private modelEditController: ModelEditController,
    private modelQueryEditController: ModelQueryEditController,
    private workflowEditController: WorkflowEditController,
    private contextTokenProvider: ViewContextTokenProvider,
    private actionService: ActionService,
    private notificationService: NotificationService,
    private routing: RoutingService,
    private injector: Injector,
    private zone: NgZone,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.resourceFieldParams = { classes: ['select_fill'], ...this.control.resourceFieldParams };
    this.reloadAllowed$ = this.control.getResourceReloadAllowed$();
    this.queryOptions$ = this.control.getQueryOptions$();
    this.queryEditable$ = this.control.getQueryEditable$();
    this.queryConfigured$ = this.control.getQueryConfigured$();
    this.modelDescriptionCustom$ = this.control.getModelDescriptionCustom$();
    this.autoDetectedColumns$ = this.control.getAutoDetectedColumns$(this.control.isListQuery(), {
      context: this.context,
      contextElement: this.contextElement
    });

    this.queryContext.parametersControl = this.control.controls.query_parameters;

    combineLatest(this.search$, controlValue(this.control.controls.query_parameters), this.extraTokens$)
      .pipe(untilDestroyed(this))
      .subscribe(([search, parameters, extraTokens]) => {
        this.updateTokens({
          search: search,
          parameters: parameters,
          extraTokens: extraTokens
        });
      });

    this.control.queryDefaultSet.pipe(untilDestroyed(this)).subscribe(query => {
      if (query.queryType != QueryType.Simple) {
        this.editCustomQuery();
      }
    });

    combineLatest(
      controlValue<Workflow>(this.control.controls.workflow),
      controlValue<ParameterField[]>(this.control.controls.query_parameters)
    )
      .pipe(untilDestroyed(this))
      .subscribe(([workflow, parameters]) => {
        this.workflow = workflow;
        this.parameters = parameters;
        this.cd.markForCheck();
      });

    this.control.controls.type.valueChanges.pipe(untilDestroyed(this)).subscribe((type: DataSourceType) => {
      if (type == DataSourceType.Workflow && !this.control.controls.workflow.value) {
        this.editWorkflow();
      }
    });

    this.autoDetectedColumns$.pipe(untilDestroyed(this)).subscribe(() => this.cd.markForCheck());
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<ModelDescriptionDataSourceEditComponent>): void {
    if (changes.search) {
      this.search$.next(this.search);
    }

    if (changes.extraTokens) {
      this.extraTokens$.next(this.extraTokens);
    }
  }

  ngAfterViewInit(): void {
    this.zone.onStable.pipe(take(1), delay(100), untilDestroyed(this)).subscribe(() => {
      if (this.typeSelect && !this.control.controls.type.value) {
        this.typeSelect.open();
      }
    });
  }

  updateTokens(options: { search?: boolean; parameters?: ParameterField[]; extraTokens?: BaseField[] }) {
    const tokens: Token[] = [];
    const componentTokens: Token[] = [];

    if (options.search) {
      componentTokens.push({
        name: 'search',
        field: FieldType.Text,
        label: 'Search query',
        icon: 'search'
      });
    }

    if (options.extraTokens && options.extraTokens.length) {
      componentTokens.push(
        ...options.extraTokens.map(item => {
          const fieldDescription = getFieldDescriptionByType(FieldType.Number);
          return {
            name: item.name,
            field: item.field,
            label: item.verboseName,
            icon: fieldDescription.icon
          };
        })
      );
    }

    if (componentTokens.length) {
      tokens.push({
        name: undefined,
        label: 'Component parameters',
        children: componentTokens
      });
    }

    tokens.push({
      name: undefined,
      label: 'Inputs',
      data: {
        parameter_tokens: true
      },
      children: options.parameters
        .filter(item => item.name)
        .map(item => {
          const fieldDescription = getFieldDescriptionByType(item.field);

          return {
            name: ['params', item.name].join('.'),
            field: item.field,
            params: item.params,
            label: item.verboseName || item.name,
            icon: fieldDescription.icon
          };
        })
    });

    this.queryContext.tokens = tokens;
  }

  onQueryOptionOptionClick(option: Option<ModelDescriptionQuery>) {
    if (option.value.queryType != QueryType.Simple) {
      this.editCustomQuery();
    }
  }

  onQueryOptionButtonClick(button: CustomSelectItemButton) {
    if (button.name == 'add_model') {
      this.addModel(button.data['addModelComponent']);
    }
  }

  addModel(addModelComponent: ResourceAddModelComponentItem) {
    combineLatest([this.control.getResource$()])
      .pipe(
        first(),
        switchMap(([resource]) => {
          return this.resourceModelEditController.addModel(resource, addModelComponent, {
            source: this.analyticsSource
          });
        }),
        untilDestroyed(this)
      )
      .subscribe(result => {
        if (result.link) {
          this.routing.navigateApp(result.link);
        } else {
          const value = this.control.getQueryOptionValue(result.modelDescription);
          this.control.controls.query.setValue(value);
          this.control.controls.query.markAsDirty();
        }
      });
  }

  editCustomQuery(
    options: { initialHttpResultsSection?: HttpResultsSection; initialSqlResultsSection?: SqlResultsSection } = {}
  ) {
    const query = this.control.controls.query.value;

    combineLatest([this.control.getResource$(), this.control.getResourceBaseHttpQuery$()])
      .pipe(
        first(),
        filter(([resource, resourceBaseHttpQuery]) => resource),
        switchMap(([resource, resourceBaseHttpQuery]) => {
          const queryCls = (this.control.getInstanceCls() as any).queryCls;

          return this.queryBuilderService.edit({
            injector: this.injector,
            queryClass: queryCls,
            queryTypes: [query.queryType],
            resource: resource,
            resourceType: resource.type,
            context: this.queryContext,
            requireResponse: true,
            query: query,
            parametersControl: this.queryContext.parametersControl,
            httpOptions: {
              baseQuery: resourceBaseHttpQuery,
              initialResultsSection: options.initialHttpResultsSection
            },
            sqlOptions: {
              initialResultsSection: options.initialSqlResultsSection
            },
            objectOptions: {
              forceOperation: ObjectQueryOperation.Get
            },
            source: this.analyticsSource
          });
        }),
        untilDestroyed(this)
      )
      .subscribe(e => {
        if (e.saved) {
          this.onCustomQuerySaved(e.saved);
        }
      });
  }

  onCustomQuerySaved(event: QueryBuilderSaveEvent) {
    const query = event.query as ModelDescriptionQuery;

    this.control.controls.query.setValue(query);
    this.control.controls.query.markAsDirty();

    this.control.setAutoDetectColumns(query, this.control.isListQuery(), { merge: true, markAsDirty: true });
  }

  editSavedQuery(
    modelDescription: ModelDescription,
    options: { initialHttpResultsSection?: HttpResultsSection; initialSqlResultsSection?: SqlResultsSection } = {}
  ) {
    combineLatest([
      this.control.getResource$(),
      this.control.getResourceBaseHttpQuery$(),
      this.control.getCustomQueryType$()
    ])
      .pipe(
        first(),
        switchMap(([resource, resourceBaseHttpQuery, queryType]) => {
          let query: ModelDescriptionQuery;
          let queryClass: Type<ModelDescriptionQuery>;
          let parameters: ParameterField[];

          if (this.control.isListQuery()) {
            query = modelDescription.getQuery;
            queryClass = ListModelDescriptionQuery;
            parameters = modelDescription.getParameters;
          } else {
            if (modelDescription.getDetailQuery) {
              query = modelDescription.getDetailQuery;
              queryClass = ModelDescriptionQuery;
              parameters = modelDescription.getDetailParameters;
            } else {
              query = modelDescription.getQuery;
              queryClass = ListModelDescriptionQuery;
              parameters = modelDescription.getParameters;
            }
          }

          if (!query) {
            query = new queryClass();
            query.queryType = queryType;
          }

          // TODO: Temporary solution for model parameters
          this.queryContext.parametersControl.setValue(parameters);

          return this.modelQueryEditController.editGetQuery({
            resource: resource,
            modelDescription: modelDescription,
            query: query,
            queryClass: queryClass,
            queryContext: this.queryContext,
            httpOptions: {
              baseQuery: resourceBaseHttpQuery,
              initialResultsSection: options.initialHttpResultsSection
            },
            sqlOptions: {
              initialResultsSection: options.initialSqlResultsSection
            },
            parametersControl: this.queryContext.parametersControl,
            analyticsSource: this.analyticsSource
          });
        }),
        untilDestroyed(this)
      )
      .subscribe(model => {
        // TODO: Temporary solution for model parameters
        this.queryContext.parametersControl.setValue([]);

        const columns = model.fields
          .filter(item => item.type == ModelFieldType.Db)
          .map(item => modelFieldToDisplayField(item));

        this.control.mergeColumns(columns, { markAsDirty: true });
        this.control.setQueryUpdated({ markAsDirty: true });
      });
  }

  editQuery(
    options: { initialHttpResultsSection?: HttpResultsSection; initialSqlResultsSection?: SqlResultsSection } = {}
  ) {
    combineLatest(this.queryEditable$, this.modelDescriptionCustom$)
      .pipe(first(), untilDestroyed(this))
      .subscribe(([queryEditable, modelDescription]) => {
        if (this.control.controls.type.value == DataSourceType.Workflow) {
          this.editWorkflow({ customizeTrigger: true });
        } else if (queryEditable) {
          this.editCustomQuery(options);
        } else if (modelDescription) {
          this.editSavedQuery(modelDescription, options);
        }
      });
  }

  createSavedQuery() {
    combineLatest([this.control.getResource$(), this.control.getResourceBaseHttpQuery$()])
      .pipe(
        first(),
        switchMap(([resource]) => {
          return this.modelEditController.create(resource, {
            query: this.control.controls.query.value,
            parameters: this.control.controls.query_parameters.value,
            columns: this.control.controls.columns.value
          });
        }),
        untilDestroyed(this)
      )
      .subscribe(result => {
        const value = this.control.getQueryOptionValue(result);
        this.control.controls.query.setValue(value);
        this.control.controls.query.markAsDirty();
      });
  }

  resetColumns() {
    this.control.resetInputColumns({
      context: this.context,
      contextElement: this.contextElement,
      markAsDirty: true
    });
  }

  reload() {
    this.reloadLoading = true;
    this.cd.markForCheck();

    this.control
      .reloadResource()
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          this.reloadLoading = false;
          this.cd.markForCheck();

          if (result) {
            this.notificationService.success('Synchronized', 'Resource was successfully updated');
          } else {
            this.notificationService.error('Synchronized not available', 'No synchronization mechanics found');
          }
        },
        e => {
          console.error(e);
          this.notificationService.error('Synchronized Failed', 'Resource structure update failed');
        }
      );
  }

  editWorkflow(options: { customizeTrigger?: boolean } = {}) {
    let workflow: Workflow;
    const parameters: ParameterField[] = cloneDeep(this.parameters);

    if (this.workflow) {
      workflow = cloneDeep(this.workflow);
    } else {
      workflow = new Workflow();
      workflow.generateUid();
    }

    this.workflowEditController
      .open({
        create: !this.workflow,
        workflow: workflow,
        workflowRun: workflow.testRun,
        workflowEditable: true,
        parametersEnabled: true,
        parameters: parameters,
        context: this.context,
        contextTokenProvider: this.contextTokenProvider,
        triggerLabel: 'Load data',
        customizeTrigger: options.customizeTrigger,
        historyEnabled: true,
        resultEnabled: true,
        analyticsSource: ['component', this.analyticsSource].join('_')
      })
      .pipe(
        filter(result => !result.cancelled),
        untilDestroyed(this)
      )
      .subscribe(result => {
        result.workflow.testRun = result.workflowRun;

        this.control.controls.workflow.patchValue(result.workflow);
        this.control.controls.query_parameters.patchValue(result.parameters);
        this.cd.markForCheck();
      });
  }
}
