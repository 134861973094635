var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import pickBy from 'lodash/pickBy';
import { localize } from '@common/localize';
import { ActionItem } from '@modules/actions';
import { Input } from '@modules/fields';
import { isSet } from '@shared';
import { TextStyle } from '../../text-style';
import { registerElementForType } from '../element-items';
import { ElementType } from '../element-type';
import { ElementItem } from './base';
var RangeSliderElementItem = /** @class */ (function (_super) {
    __extends(RangeSliderElementItem, _super);
    function RangeSliderElementItem() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.type = ElementType.RangeSlider;
        _this.required = false;
        _this.onChangeActions = [];
        return _this;
    }
    RangeSliderElementItem.prototype.deserialize = function (data) {
        _super.prototype.deserialize.call(this, data);
        this.width = this.params['width'];
        this.verboseName = this.params['verbose_name'];
        this.required = this.params['required'];
        this.tint = this.params['tint'];
        this.tooltip = this.params['tooltip'];
        if (this.params['label_style']) {
            this.labelStyle = new TextStyle().deserialize(this.params['label_style']);
        }
        else {
            this.labelStyle = undefined;
        }
        if (this.params['label_additional'] === undefined && this.required) {
            // Backward compatibility
            this.labelAdditional = "(" + localize('optional') + ")";
        }
        else {
            this.labelAdditional = this.params['label_additional'];
        }
        if (this.params['label_additional_style']) {
            this.labelAdditionalStyle = new TextStyle().deserialize(this.params['label_additional_style']);
        }
        else {
            this.labelAdditionalStyle = undefined;
        }
        if (this.params['from']) {
            this.from = new Input().deserialize(this.params['from']);
        }
        if (this.params['to']) {
            this.to = new Input().deserialize(this.params['to']);
        }
        if (this.params['min_value_input']) {
            this.minValueInput = new Input().deserialize(this.params['min_value_input']);
        }
        else if (isSet(this.params['min_value'])) {
            // Backward compatibility
            this.minValueInput = new Input().deserializeFromStatic('value', this.params['min_value']);
        }
        if (this.params['max_value_input']) {
            this.maxValueInput = new Input().deserialize(this.params['max_value_input']);
        }
        else if (isSet(this.params['max_value'])) {
            // Backward compatibility
            this.maxValueInput = new Input().deserializeFromStatic('value', this.params['max_value']);
        }
        if (this.params['step_size_input']) {
            this.stepSizeInput = new Input().deserialize(this.params['step_size_input']);
        }
        else if (isSet(this.params['step_size'])) {
            // Backward compatibility
            this.stepSizeInput = new Input().deserializeFromStatic('value', this.params['step_size']);
        }
        if (this.params['disable_input']) {
            this.disableInput = new Input().deserialize(this.params['disable_input']);
        }
        if (this.params['on_change_actions']) {
            this.onChangeActions = this.params['on_change_actions'].map(function (item) { return new ActionItem().deserialize(item); });
        }
        return this;
    };
    RangeSliderElementItem.prototype.serialize = function (fields) {
        this.params = {
            width: this.width,
            verbose_name: this.verboseName,
            label_style: this.labelStyle ? this.labelStyle.serialize() : undefined,
            label_additional: this.labelAdditional,
            label_additional_style: this.labelAdditionalStyle ? this.labelAdditionalStyle.serialize() : undefined,
            from: this.from ? this.from.serialize() : null,
            to: this.to ? this.to.serialize() : null,
            min_value_input: this.minValueInput ? this.minValueInput.serialize() : null,
            max_value_input: this.maxValueInput ? this.maxValueInput.serialize() : null,
            step_size_input: this.stepSizeInput ? this.stepSizeInput.serialize() : null,
            required: this.required,
            disable_input: this.disableInput ? this.disableInput.serialize() : null,
            tint: this.tint,
            tooltip: this.tooltip,
            on_change_actions: this.onChangeActions.map(function (item) { return item.serialize(); })
        };
        var data = _super.prototype.serialize.call(this);
        if (fields) {
            data = pickBy(data, function (v, k) { return fields.includes(k); });
        }
        return data;
    };
    Object.defineProperty(RangeSliderElementItem.prototype, "typeStr", {
        get: function () {
            return 'range';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RangeSliderElementItem.prototype, "analyticsName", {
        get: function () {
            return 'slider_range';
        },
        enumerable: true,
        configurable: true
    });
    RangeSliderElementItem.prototype.defaultName = function () {
        return 'Range';
    };
    return RangeSliderElementItem;
}(ElementItem));
export { RangeSliderElementItem };
registerElementForType(ElementType.RangeSlider, RangeSliderElementItem);
