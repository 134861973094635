import { AfterViewInit, ChangeDetectorRef, ElementRef, EventEmitter, NgZone, OnDestroy, OnInit } from '@angular/core';
import { TweenMax } from 'gsap';
import clamp from 'lodash/clamp';
import cloneDeep from 'lodash/cloneDeep';
import round from 'lodash/round';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, fromEvent, of } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { PickColorService } from '@modules/colors';
import { ViewContext } from '@modules/customize';
import { View } from '@modules/views';
import { MouseButton } from '@shared';
import { ViewEditorContext, ViewEditorGuideSymbol } from '../../services/view-editor-context/view-editor.context';
import { ViewEditorMultipleLayers } from '../../services/view-editor-multiple-layers/view-editor-multiple-layers';
import { isViewLayerClick } from '../auto-layer/auto-layer.component';
import { isViewBoundsMouseDown } from '../layers/base/layer-resizable.directive';
import { isCustomizeGradientMouseDown } from '../ui/gradient-position/gradient-position.component';
import { isViewViewClick } from '../view-editor-view/view-editor-view.component';
var ViewEditorViewportComponent = /** @class */ (function () {
    function ViewEditorViewportComponent(editorContext, multipleLayers, pickColorService, zone, cd) {
        this.editorContext = editorContext;
        this.multipleLayers = multipleLayers;
        this.pickColorService = pickColorService;
        this.zone = zone;
        this.cd = cd;
        this.componentLabel = 'component';
        this.stateSelectedEnabled = false;
        this.templatesEnabled = true;
        this.dark = false;
        this.importFigmaNode = new EventEmitter();
        this.importSketchFile = new EventEmitter();
        this.navigate = false;
        this.navigating = false;
        this.interactionMouseDown = false;
        this.guideSymbols = ViewEditorGuideSymbol;
    }
    ViewEditorViewportComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.pickingColor$ = this.pickColorService.isPicking$();
        combineLatest(this.editorContext.viewportX$, this.editorContext.viewportY$, this.editorContext.viewportScale$)
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var x = _a[0], y = _a[1], scale = _a[2];
            TweenMax.set(_this.canvasScaleElement.nativeElement, {
                scale: scale,
                fontSize: 10 / scale,
                roundProps: { fontSize: 0.01 }
            });
            TweenMax.set(_this.canvasElement.nativeElement, {
                x: -x,
                y: -y,
                xPercent: -50,
                yPercent: -50
            });
            TweenMax.set(_this.overlayCanvasElement.nativeElement, {
                x: -x * scale,
                y: -y * scale,
                xPercent: -50,
                yPercent: -50,
                fontSize: 10 * scale,
                roundProps: { fontSize: 0.01 }
            });
        });
        this.multipleLayers.frame$.pipe(untilDestroyed(this)).subscribe(function (frame) {
            _this.multipleLayersFrame = frame;
            _this.cd.markForCheck();
        });
        this.editorContext.customizingGradient$.pipe(untilDestroyed(this)).subscribe(function (value) {
            _this.customizeGradient = value;
            _this.cd.markForCheck();
        });
        this.editorContext.navigateMode$
            .pipe(switchMap(function (navigate) {
            _this.navigate = navigate;
            _this.cd.markForCheck();
            if (navigate) {
                return fromEvent(_this.viewportElement.nativeElement, 'mousedown').pipe(filter(function (e) { return e.button == MouseButton.Main; }));
            }
            else {
                return of(undefined);
            }
        }), untilDestroyed(this))
            .subscribe(function (downEvent) {
            if (!downEvent) {
                return;
            }
            var subscriptions = [];
            var originalX = _this.editorContext.viewportX$.value;
            var originalY = _this.editorContext.viewportY$.value;
            _this.navigating = true;
            _this.cd.markForCheck();
            subscriptions.push(fromEvent(document, 'mousemove')
                .pipe(untilDestroyed(_this))
                .subscribe(function (moveEvent) {
                moveEvent.preventDefault();
                var scale = _this.editorContext.viewportScale$.value;
                var deltaX = (moveEvent.clientX - downEvent.clientX) / scale;
                var deltaY = (moveEvent.clientY - downEvent.clientY) / scale;
                var x = round(originalX - deltaX, 2);
                var y = round(originalY - deltaY, 2);
                _this.editorContext.viewportX$.next(x);
                _this.editorContext.viewportY$.next(y);
            }));
            subscriptions.push(fromEvent(document, 'mouseup')
                .pipe(filter(function (e) { return e.button == MouseButton.Main; }), untilDestroyed(_this))
                .subscribe(function (upEvent) {
                upEvent.preventDefault();
                subscriptions.forEach(function (item) { return item.unsubscribe(); });
                _this.navigating = false;
                _this.cd.markForCheck();
            }));
        });
    };
    ViewEditorViewportComponent.prototype.ngOnDestroy = function () { };
    ViewEditorViewportComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.zone.runOutsideAngular(function () {
            fromEvent(_this.viewportElement.nativeElement, 'mousewheel')
                .pipe(untilDestroyed(_this))
                .subscribe(function (e) { return _this.onMouseWheel(e); });
        });
    };
    ViewEditorViewportComponent.prototype.onMouseWheel = function (event) {
        if (event.metaKey || event.ctrlKey) {
            event.preventDefault();
            event.stopPropagation();
            var currentScale = this.editorContext.viewportScale$.value;
            var scaleSpeed = currentScale * 0.005;
            var scale = clamp(currentScale + event.deltaY * scaleSpeed * -1, this.editorContext.viewportScaleMin, this.editorContext.viewportScaleMax);
            var bounds = this.viewportElement.nativeElement.getBoundingClientRect();
            var deltaXMultiplier = (event.clientX - (bounds.left + bounds.width * 0.5)) / (bounds.width * 0.5);
            var deltaYMultiplier = (event.clientY - (bounds.top + bounds.height * 0.5)) / (bounds.height * 0.5);
            var x = this.editorContext.viewportX$.value;
            var y = this.editorContext.viewportY$.value;
            var newX = x + (bounds.width / currentScale - bounds.width / scale) * 0.5 * deltaXMultiplier;
            var newY = y + (bounds.height / currentScale - bounds.height / scale) * 0.5 * deltaYMultiplier;
            this.editorContext.viewportX$.next(newX);
            this.editorContext.viewportY$.next(newY);
            this.editorContext.viewportScale$.next(scale);
        }
        else {
            event.preventDefault();
            event.stopPropagation();
            var currentScale = this.editorContext.viewportScale$.value;
            var x = round(this.editorContext.viewportX$.value + event.deltaX / currentScale, 2);
            var y = round(this.editorContext.viewportY$.value + event.deltaY / currentScale, 2);
            this.editorContext.viewportX$.next(x);
            this.editorContext.viewportY$.next(y);
        }
    };
    ViewEditorViewportComponent.prototype.onMouseDown = function (e) {
        this.interactionMouseDown =
            this.editorContext.isCreateTool() ||
                isViewBoundsMouseDown(e) ||
                isCustomizeGradientMouseDown(e) ||
                this.editorContext.navigateMode$.value;
    };
    ViewEditorViewportComponent.prototype.onClick = function (event) {
        if (!isViewLayerClick(event) && !isViewViewClick(event) && !this.interactionMouseDown) {
            this.editorContext.resetCustomizingLayers();
            this.editorContext.resetCustomizeView();
        }
    };
    ViewEditorViewportComponent.prototype.onMultipleLayersResizeStarted = function () {
        this.originalMultipleLayers = cloneDeep(this.editorContext.customizingLayers$.value);
        this.originalMultipleLayersFrame = this.multipleLayers.frame$.value;
        this.editorContext.resizingLayer$.next(this.originalMultipleLayers[0]);
    };
    ViewEditorViewportComponent.prototype.onMultipleLayersResizeFinished = function () {
        this.originalMultipleLayers = undefined;
        this.originalMultipleLayersFrame = undefined;
        this.editorContext.resizingLayer$.next(undefined);
    };
    ViewEditorViewportComponent.prototype.onMultipleLayersResize = function (frame) {
        this.multipleLayers.patchFrame(frame, {
            originalFrame: this.originalMultipleLayersFrame,
            originalLayers: this.originalMultipleLayers
        });
    };
    return ViewEditorViewportComponent;
}());
export { ViewEditorViewportComponent };
