import { CdkConnectedOverlay } from '@angular/cdk/overlay';
import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl } from '@angular/forms';

import { localize } from '@common/localize';

@Component({
  selector: 'app-project-group-control',
  templateUrl: './project-group-control.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectGroupControlComponent implements OnInit, OnDestroy {
  @Input() control: AbstractControl;
  @Input() uidValue = false;
  @Input() placeholder = localize('Choose Team');
  @Input() fill = false;
  @Input() small = false;
  @Input() allowNull = false;
  @Input() environmentName: string;

  @ViewChild(CdkConnectedOverlay) cdkConnectedOverlay: CdkConnectedOverlay;

  constructor() {}

  ngOnInit() {}

  ngOnDestroy(): void {}
}
