import { Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import range from 'lodash/range';

import { Option } from '@modules/field-components';
import { Input, InputValueType, isInputSet, ParameterField } from '@modules/fields';
import { contextToFormulaValue, FieldInputControl } from '@modules/parameters';
import { View, ViewMapping } from '@modules/views';
import { EMPTY, isSet } from '@shared';

export function validateMapParametersSourceControl(): ValidatorFn {
  return (control: CustomViewMapParametersSourceControl): { [key: string]: any } | null => {
    if (!control.isSet()) {
      return { local: ['Bindings not set'] };
    }
  };
}

export interface CustomViewMapParametersSourceControlValue {
  source_parameter: string;
  source_parameter_input_enabled: boolean;
  source_parameter_input: Object;
}

export class CustomViewMapParametersSourceControl extends FormGroup {
  instance: ViewMapping;

  controls: {
    source_parameter: FormControl;
    source_parameter_input: FieldInputControl;
    source_parameter_input_enabled: FormControl;
  };

  constructor(public target: ParameterField, state: Partial<ViewMapping> = {}) {
    super(
      {
        source_parameter: new FormControl(isSet(state.sourceParameter) ? !!state.sourceParameter : undefined),
        source_parameter_input: new FieldInputControl({ path: ['value'] }),
        source_parameter_input_enabled: new FormControl(
          isSet(state.sourceParameterInput) ? !!state.sourceParameterInput : false
        )
      },
      [validateMapParametersSourceControl()]
    );
  }

  patchValue(
    value: Partial<CustomViewMapParametersSourceControlValue>,
    options?: { onlySelf?: boolean; emitEvent?: boolean }
  ) {
    super.patchValue(value, options);
  }

  deserialize(value: ViewMapping, options: { emitEvent?: boolean } = {}) {
    this.instance = value;

    this.controls.source_parameter.patchValue(isSet(value.sourceParameter) ? value.sourceParameter : EMPTY, {
      emitEvent: options.emitEvent
    });
    this.controls.source_parameter_input.patchValue(
      value.sourceParameterInput ? value.sourceParameterInput.serializeWithoutPath() : {},
      {
        emitEvent: options.emitEvent
      }
    );
    this.controls.source_parameter_input_enabled.patchValue(!!value.sourceParameterInput, {
      emitEvent: options.emitEvent
    });

    this.markAsPristine();
  }

  isSet(): boolean {
    return this.controls.source_parameter_input_enabled.value
      ? isInputSet(this.controls.source_parameter_input.value)
      : isSet(this.controls.source_parameter.value);
  }

  isSourceParameterInputDefaultValueType(): boolean {
    const valueType = this.controls.source_parameter_input.controls.value_type.value;
    return !valueType || [InputValueType.Context, InputValueType.Formula].includes(valueType);
  }

  getSourceParameterInput(): Input {
    return this.controls.source_parameter_input.value
      ? new Input().deserialize(this.controls.source_parameter_input.value)
      : undefined;
  }

  toggleSourceParameterInput() {
    const sourceParameter = this.controls.source_parameter.value;

    this.controls.source_parameter_input_enabled.patchValue(!this.controls.source_parameter_input_enabled.value);

    if (isSet(sourceParameter) && !isInputSet(this.controls.source_parameter_input.value)) {
      const formulaValue = contextToFormulaValue(['item', sourceParameter]);
      this.controls.source_parameter_input.controls.value_type.patchValue(InputValueType.Formula);
      this.controls.source_parameter_input.controls.formula_value.patchValue(formulaValue);
    }
  }

  serialize(): ViewMapping {
    const instance = this.instance ? cloneDeep(this.instance) : new ViewMapping();

    instance.targetParameter = this.target.name;

    if (this.controls.source_parameter_input_enabled.value) {
      instance.sourceParameter = this.controls.source_parameter.value;
      instance.sourceParameterInput = this.getSourceParameterInput();
    } else {
      instance.sourceParameter =
        this.controls.source_parameter.value !== EMPTY ? this.controls.source_parameter.value : undefined;
      instance.sourceParameterInput = undefined;
    }

    return instance;
  }
}

@Injectable()
export class CustomViewMapParametersForm extends FormArray {
  sourceParameters: ParameterField[];
  view: View;

  controls: CustomViewMapParametersSourceControl[];

  sourceOptions: Option[] = [];

  constructor() {
    super([]);
  }

  init(options: { sourceParameters: ParameterField[]; view: View; mappings?: ViewMapping[] }) {
    this.sourceParameters = options.sourceParameters;
    this.view = options.view;

    this.sourceOptions = [
      {
        value: EMPTY,
        name: 'Empty value',
        icon: 'delete',
        subtitle: 'Use empty value'
      },
      ...options.sourceParameters.map(item => {
        return {
          value: item.name,
          name: item.verboseName || item.name,
          icon: item.fieldDescription.icon,
          subtitle: item.fieldDescription.label
        };
      })
    ];

    this.deserialize(this.view.parameters, options.mappings);

    this.markAsPristine();
  }

  deserialize(parameters: ParameterField[], mappings?: ViewMapping[]) {
    parameters.forEach((parameter, i) => {
      let control = this.controls[i];

      if (control) {
        control.target = parameter;
      } else {
        control = this.appendControl(parameter);
      }

      const mapping = mappings ? mappings.find(item => item.targetParameter == parameter.name) : undefined;
      if (mapping) {
        control.deserialize(mapping);
      }
    });

    this.controls.slice(parameters.length).forEach(item => this.removeControl(item));
  }

  setControls(controls: CustomViewMapParametersSourceControl[]) {
    this.removeControls();
    controls.forEach(item => this.push(item));
  }

  removeControls() {
    range(this.controls.length).forEach(() => this.removeAt(0));
  }

  removeControl(control: CustomViewMapParametersSourceControl) {
    const newControls = this.controls.filter(item => !isEqual(item, control));
    this.setControls(newControls);
  }

  appendControl(item: ParameterField, value?: ViewMapping): CustomViewMapParametersSourceControl {
    const control = new CustomViewMapParametersSourceControl(item, value);
    this.push(control);
    return control;
  }

  setDefaultEmpty() {
    this.controls
      .filter(control => !control.isSet())
      .forEach(control => {
        control.patchValue({
          source_parameter_input_enabled: false,
          source_parameter: EMPTY
        });
      });
  }

  submit(): ViewMapping[] {
    return this.controls.map(item => item.serialize());
  }
}
