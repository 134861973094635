<!--<app-editable-content-->
<!--  *ngIf="(customizeEnabled$ | async) && activeItemIndex === i"-->
<!--  [(value)]="tab.title"-->
<!--  [emptyValue]="'No title'"-->
<!--  [inline]="true"-->
<!--  [multiline]="false"-->
<!--  [immediateUpdate]="true"-->
<!--  (valueChange)="customizeService.markChanged()"-->
<!--&gt;</app-editable-content>-->

<div *ngIf="tab.icon" class="tabs2-navigation__item-icon tabs2-navigation__item-icon_left" [appIcon]="tab.icon"></div>

<ng-container *ngIf="title | appIsSet">{{ title }}</ng-container>
<ng-container *ngIf="!(title | appIsSet)">No title</ng-container>

<span
  *ngIf="tab.tooltip | appIsSet"
  class="tabs2-navigation__item-help help-icon icon-help_filled"
  [appTip]="tab.tooltip"
  [appTipOptions]="{ side: 'top' }"
></span>

<span
  *ngIf="!isVisible && (customizeService.enabled$ | async)"
  class="tabs2-navigation__item-indicator icon-eye"
  [appTip]="'Tab is hidden'"
></span>

<!--<span class="tabs2-navigation__item-delete icon-bin" (click)="$event.stopPropagation(); deleteTab(i)"></span>-->
