<app-popup2 [size]="'xl'">
  <app-popup2-close [outside]="true" (click)="close()"></app-popup2-close>

  <div class="custom-page-popup custom-page-popup_style_background custom-page-popup_border-radius_s">
    <div class="custom-page-popups-sides">
      <div class="custom-page-popups-sides__item custom-page-popups-sides__item_fill">
        <div class="custom-page-popup__header">
          <div class="custom-page-popup__header-main">
            <div class="custom-page-popup__title">Bind data to custom component</div>
            <div class="custom-page-popup__description">
              Because component data and custom component parameters can be different you should bind component data to
              custom component parameters below.
            </div>
          </div>
        </div>

        <div class="custom-page-popup__content">
          <app-page-block>
            <table class="card-table card-table_compact">
              <thead>
                <tr class="card-table__head-row">
                  <th class="card-table__head-column" [style.padding-left.px]="25">
                    Data fields (<a href="javascript:void(0)" class="link" (click)="form.setDefaultEmpty()"
                      >Use empty by default</a
                    >)
                  </th>
                  <th class="card-table__head-column"></th>
                  <th class="card-table__head-column" [style.padding-left.px]="45">Custom component</th>
                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let control of form.controls" class="card-table__row">
                  <td class="card-table__column" [style.width.%]="40">
                    <div class="card-table__column-inner">
                      <ng-container *ngIf="!control.controls.source_parameter_input_enabled.value">
                        <app-select
                          [control]="control.controls.source_parameter"
                          [placeholder]="'Choose field'"
                          [options]="form.sourceOptions"
                          [fill]="true"
                          [small]="true"
                          [resetEnabled]="true"
                          [style.width.px]="240"
                        ></app-select>

                        <div
                          class="button button_small button_icon button_orange"
                          [class.button_primary]="control.controls.source_parameter_input_enabled.value"
                          [appTip]="
                            control.controls.source_parameter_input_enabled.value
                              ? 'Disable Formula'
                              : 'Set up with Formula'
                          "
                          [appTipOptions]="{ side: 'top' }"
                          (click)="toggleSourceParameterInput(control)"
                        >
                          <div class="button__icon icon-function"></div>
                        </div>
                      </ng-container>

                      <div *ngIf="control.controls.source_parameter_input_enabled.value" [style.width.%]="100">
                        <app-input-edit
                          [itemForm]="control.controls.source_parameter_input"
                          [context]="context"
                          [contextElement]="contextElement"
                          [contextElementPath]="contextElementPath"
                          [contextElementPaths]="contextElementPaths"
                          [staticValueDisabled]="true"
                          [focusedInitial]="control === controlSourceParameterInputEnabled"
                          [formulaPlaceholder]="'Bind with Formula'"
                          [fill]="true"
                        ></app-input-edit>

                        <div
                          *ngIf="control.isSourceParameterInputDefaultValueType()"
                          class="button-tags"
                          style="padding-top: 4px;"
                        >
                          <div
                            class="button-tag button-tags__item button-tags__item_fill"
                            (click)="control.controls.source_parameter_input_enabled.patchValue(false)"
                          >
                            <span class="button-tag__icon icon-function"></span>
                            <span class="button-tag__label">Disable Formula</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>

                  <td class="card-table__column" [style.width.px]="40">
                    <div class="card-table__column-inner">
                      <span class="card-table__grey icon-arrow_forward" [style.font-size.px]="20"></span>
                    </div>
                  </td>

                  <td class="card-table__column">
                    <div class="card-table__column-inner">
                      <div class="table-field table-field_grow">
                        <div class="table-field__icon" [ngClass]="'icon-' + control.target.fieldDescription.icon"></div>
                        <div class="table-field__info">
                          <div class="table-field__title">
                            {{ control.target.verboseName || control.target.name }}
                          </div>
                          <div class="table-field__subtitle">
                            <span
                              *ngIf="
                                (testParameters[control.target.name] | appIsSet) &&
                                !(testSetParameters[control.target.name] | appIsSet)
                              "
                              class="table-field__subtitle-tag"
                            >
                              DEMO
                            </span>

                            <ng-container *ngIf="testParameters[control.target.name] | appIsSet">
                              {{ testParameters[control.target.name] }}
                            </ng-container>

                            <ng-container *ngIf="!(testParameters[control.target.name] | appIsSet)">
                              {{ control.target.fieldDescription.label }}
                            </ng-container>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </app-page-block>
        </div>
      </div>
      <div
        class="custom-page-popups-sides__item custom-page-popups-sides__item_bright component-preview"
        [style.width.px]="500"
      >
        <div
          *ngIf="view"
          class="component-preview__bounds"
          [style.width.px]="view.frame.width"
          [style.height.px]="view.frame.height"
          [style.transform]="viewScale ? 'scale(' + viewScale + ')' : null"
        >
          <app-custom-element-view
            class="component-preview__inner"
            [view]="view"
            [params]="testParameters"
          ></app-custom-element-view>
        </div>

        <div class="component-preview__footer">
          <div class="component-preview__footer-inner">
            <button
              type="button"
              class="button button_primary button_shadow button_orange"
              [disabled]="form.invalid"
              (click)="submit()"
            >
              <span class="button__label">Use selected binding</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-popup2>
