import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import fromPairs from 'lodash/fromPairs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { CustomViewsStore } from '@modules/custom-views';
import { CalendarSettings, getModelAttributesByColumns, ViewContext, ViewContextElement } from '@modules/customize';
import { applyParamInput$, DisplayField, ParameterField } from '@modules/fields';
import { ITEM_OUTPUT, ListItem } from '@modules/list';
import { Model, ModelDescription } from '@modules/models';
import { View } from '@modules/views';
import { EMPTY, isSet, TypedChanges } from '@shared';

import { CalendarState } from '../calendar/calendar-state';

@Component({
  selector: 'app-calendar-item',
  templateUrl: './calendar-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CalendarItemComponent implements OnInit, OnDestroy, OnChanges {
  @Input() item: ListItem;
  @Input() listState: CalendarState;
  @Input() modelDescription: ModelDescription;
  @Input() settings: CalendarSettings;
  @Input() visibleColumns: DisplayField[] = [];
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() selected = false;
  @Input() theme = false;
  @Output() select = new EventEmitter<{ item: ListItem; element: HTMLElement }>();
  @Output() modelUpdated = new EventEmitter<Model>();

  settings$ = new BehaviorSubject<CalendarSettings>(undefined);
  view: View;
  viewParameters: ParameterField[] = [];
  viewParams$: Observable<Object> = of({});
  localContext: Object = {};

  constructor(private customViewsStore: CustomViewsStore, private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.settings$
      .pipe(
        filter(settings => !!settings),
        switchMap(settings => {
          if (!isSet(settings.cardCustomView)) {
            return of(undefined);
          }

          return this.customViewsStore.getDetail(settings.cardCustomView);
        }),
        untilDestroyed(this)
      )
      .subscribe(customView => {
        this.view = customView ? customView.view : undefined;
        this.cd.markForCheck();
        this.updateViewParameters();
        this.updateViewParams();
      });
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<CalendarItemComponent>): void {
    if (changes.item) {
      this.localContext = {
        [ITEM_OUTPUT]: this.item.model.getAttributes()
      };
    }

    if (changes.settings) {
      this.settings$.next(this.settings);
      this.updateViewParameters();
    }

    if (changes.settings || changes.item) {
      this.updateViewParams();
    }
  }

  onClick(e: MouseEvent, element: HTMLElement) {
    this.contextElement.context.clickEvent = e;
    this.select.emit({ item: this.item, element: element });
  }

  updateViewParameters() {
    if (!this.view) {
      this.viewParameters = [];
      this.cd.markForCheck();
      return;
    }

    const columns = this.settings.dataSource ? this.settings.dataSource.columns : [];

    this.viewParameters = columns.map(item => {
      const parameter = new ParameterField();

      parameter.name = item.name;
      parameter.verboseName = item.verboseName;
      parameter.field = item.field;
      parameter.params = item.params;

      return parameter;
    });
    this.cd.markForCheck();
  }

  updateViewParams() {
    if (!this.view) {
      this.viewParams$ = of({});
      this.cd.markForCheck();
      return;
    }

    const columns = this.settings.dataSource ? this.settings.dataSource.columns : [];
    const modelParams = getModelAttributesByColumns(this.item.model, columns);

    this.viewParams$ = combineLatest(
      this.settings.cardCustomViewMappings.map(mapping => {
        if (mapping.sourceParameterInput) {
          return applyParamInput$(mapping.sourceParameterInput, {
            context: this.context,
            contextElement: this.contextElement,
            localContext: {
              [ITEM_OUTPUT]: modelParams
            }
          }).pipe(
            map(value => {
              return {
                name: mapping.targetParameter,
                value: value !== EMPTY ? value : undefined
              };
            })
          );
        } else {
          const value = modelParams[mapping.sourceParameter];
          return of({
            name: mapping.targetParameter,
            value: value
          });
        }
      })
    ).pipe(
      map(values => {
        return fromPairs(values.map(item => [item.name, item.value]));
      })
    );
    this.cd.markForCheck();
  }
}
