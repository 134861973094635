var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { map, skip, switchMap } from 'rxjs/operators';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { DataSourceType } from '@modules/data-sources';
import { BooleanFieldStyle } from '@modules/field-components';
import { serializeFieldParamName } from '@modules/field-lookups';
import { createFormFieldFactory, FieldType, getFieldDescriptionByType, InputValueType } from '@modules/fields';
import { ModelDescriptionStore } from '@modules/model-queries';
import { traverseModelPath } from '@modules/models';
import { FieldInputArray, FieldInputControl, flattenInputFieldProviderItems, getInputRepresentation, InputFieldProvider } from '@modules/parameters';
import { CurrentEnvironmentStore } from '@modules/projects';
import { QueryType } from '@modules/queries';
import { SidebarCollapseContext } from '@modules/sidebar';
import { coerceArray, controlValue, forceObservable, isSet } from '@shared';
// TODO: Refactor import
import { DisplayFieldArray } from '@modules/customize-bar/components/display-fields-edit/display-field.array';
var FieldInputsEditItemComponent = /** @class */ (function () {
    function FieldInputsEditItemComponent(currentEnvironmentStore, modelDescriptionStore, analyticsService, cd) {
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.modelDescriptionStore = modelDescriptionStore;
        this.analyticsService = analyticsService;
        this.cd = cd;
        this.openedInitial = false;
        this.focusedOnOpen = false;
        this.formulaPlaceholder = 'Formula';
        this.jsPlaceholder = 'return 2 * 3;';
        this.collapse = true;
        this.remove = false;
        this.displayRequiredIndicator = false;
        this.userInput = false;
        this.displayValueTypes = [InputValueType.Formula];
        this.createField = createFormFieldFactory();
        this.titleAdditionalOptions = [
            {
                value: true,
                name: 'Required to load data',
                subtitle: 'Component <u>will not display data</u> if this parameter is empty string, NULL or is not set'
            },
            {
                value: false,
                name: 'Optional to load data',
                subtitle: 'Component <u>will ignore this parameter</u> if it is empty string, NULL or is not set'
            }
        ];
        this.booleanFieldStyle = BooleanFieldStyle;
        this.dataSource$ = new BehaviorSubject(undefined);
    }
    FieldInputsEditItemComponent.prototype.ngOnInit = function () { };
    FieldInputsEditItemComponent.prototype.ngOnDestroy = function () { };
    FieldInputsEditItemComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (changes.dataSource) {
            this.dataSource$.next(this.dataSource);
        }
        if (changes.itemForm) {
            this.instance$ = this.itemForm.getInstance$();
            this.isSet$ = this.itemForm.isSet$();
            this.parameter$ = this.getParameter$();
            this.placeholder$ = this.parameter$.pipe(map(function (parameter) { return _this.getFieldPlaceholder(parameter); }));
            this.providerItemWarning$ = this.getProviderItem$().pipe(map(function (item) { return (item ? item.warning : undefined); }));
        }
        if (changes.fieldsControl) {
            this.filterFields$ = this.getFilterFields$();
        }
        if (changes.itemForm) {
            this.initItemGroup();
        }
    };
    FieldInputsEditItemComponent.prototype.initItemGroup = function () {
        var _this = this;
        if (this.infoSubscription) {
            this.infoSubscription.unsubscribe();
        }
        if (this.analyticsSubscription) {
            this.analyticsSubscription.unsubscribe();
        }
        if (!this.itemForm) {
            return;
        }
        this.infoSubscription = combineLatest(
        // Title
        this.parameter$.pipe(map(function (parameter) { return _this.getTitle(parameter); })), 
        // Description
        combineLatest(this.parameter$, this.instance$).pipe(switchMap(function (_a) {
            var parameter = _a[0], input = _a[1];
            return _this.getDescription(parameter, input);
        })))
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var title = _a[0], description = _a[1];
            _this.title = title;
            _this.description = description;
            _this.cd.markForCheck();
        });
        this.analyticsSubscription = this.instance$.pipe(skip(1), untilDestroyed(this)).subscribe(function (input) {
            if (!input) {
                return;
            }
            if ((input.valueType == InputValueType.StaticValue && input.staticValue) ||
                (input.valueType == InputValueType.Filter && input.filterField && input.filterLookup) ||
                (input.valueType == InputValueType.Context && input.contextValue) ||
                (input.valueType == InputValueType.Formula && input.formulaValue) ||
                (input.valueType == InputValueType.TextInputs && input.textInputsValue && input.textInputsValue.ops.length)) {
                _this.analyticsService.sendSimpleEvent(AnalyticsEvent.SetParameter.SuccessfullySetUp, {
                    Object: _this.analyticsSource,
                    Type: input.valueType
                });
            }
        });
        this.titleAdditional$ = combineLatest(controlValue(this.itemForm.controls.required), this.parameter$).pipe(map(function (_a) {
            var inputRequired = _a[0], parameter = _a[1];
            if (inputRequired || (parameter && parameter.required)) {
                return 'required';
            }
            else {
                return 'optional';
            }
        }));
    };
    FieldInputsEditItemComponent.prototype.getParameter$ = function () {
        var _this = this;
        return combineLatest(controlValue(this.itemForm.controls.path), controlValue(this.itemForm.controls.path).pipe(switchMap(function (path) { return _this.traverseDataSourcePath$(path); })), controlValue(this.itemForm.controls.lookup), controlValue(this.itemForm.controls.exclude), this.parameterProvider.getFields$()).pipe(map(function (_a) {
            var path = _a[0], field = _a[1], lookupName = _a[2], exclude = _a[3], parameterFields = _a[4];
            var prefix = exclude ? 'exclude' : '';
            var name = [prefix].concat(path, [lookupName ? lookupName : '']).filter(function (str, i) { return isSet(str) || i == 1; })
                .join('__');
            var parameterField = path && path.length == 1 ? parameterFields.find(function (item) { return item.name == name; }) : undefined;
            if (parameterField) {
                return parameterField;
            }
            if (!field) {
                return;
            }
            var fieldDescription = getFieldDescriptionByType(field.field);
            var lookup = fieldDescription
                ? fieldDescription.lookups.find(function (item) { return item.type.lookup == lookupName; })
                : undefined;
            var verboseName = [prefix, field.verboseName || field.name, lookup ? lookup.type.verboseName : '']
                .filter(function (str, i) { return isSet(str) || i == 1; })
                .join(' ');
            var fieldType = lookup ? lookup.field : undefined;
            if (!fieldType || fieldType == FieldType.Boolean) {
                return {
                    name: name,
                    verboseName: verboseName,
                    required: false,
                    field: FieldType.Select,
                    params: {
                        valueEquals: function (lhs, rhs) { return lhs === rhs; },
                        options: [
                            { value: undefined, name: 'Disable filter' },
                            { value: true, name: 'Apply filter' }
                        ],
                        classes: ['select_fill']
                    },
                    array: lookup ? lookup.array : false
                };
            }
            else {
                var fieldParams = lookup && lookup.fieldParams
                    ? lookup.fieldParams.reduce(function (acc, item) {
                        acc[item] = field.params[item];
                        return acc;
                    }, {})
                    : {};
                var params = __assign({}, fieldParams, (lookup && lookup.extraParams));
                return {
                    name: name,
                    verboseName: verboseName,
                    required: false,
                    field: fieldType,
                    params: params,
                    array: lookup ? lookup.array : false
                };
            }
        }));
    };
    FieldInputsEditItemComponent.prototype.getProviderItem$ = function () {
        return combineLatest(controlValue(this.itemForm.controls.path), controlValue(this.itemForm.controls.lookup), controlValue(this.itemForm.controls.exclude), this.parameterProvider.getItems$().pipe(map(function (providerItems) { return flattenInputFieldProviderItems(providerItems); }))).pipe(map(function (_a) {
            var path = _a[0], lookup = _a[1], exclude = _a[2], providerItems = _a[3];
            if (!isSet(path) || path.length != -1) {
                return;
            }
            var name = serializeFieldParamName(path.join('__'), lookup, exclude);
            return providerItems.find(function (item) { return item.field && item.field.name == name; });
        }));
    };
    FieldInputsEditItemComponent.prototype.getDataSourceParams = function () {
        var _this = this;
        var type = this.dataSource ? this.dataSource.type : undefined;
        var query = type == DataSourceType.Query ? this.dataSource.query : undefined;
        var resource = type == DataSourceType.Query
            ? this.currentEnvironmentStore.resources.find(function (item) { return item.uniqueName == _this.dataSource.queryResource; })
            : undefined;
        var modelId = resource && query && query.queryType == QueryType.Simple && query.simpleQuery
            ? [resource.uniqueName, query.simpleQuery.model].join('.')
            : undefined;
        return {
            type: type,
            query: query,
            resource: resource,
            modelId: modelId
        };
    };
    FieldInputsEditItemComponent.prototype.traverseDataSourcePath$ = function (path) {
        var modelId = this.getDataSourceParams().modelId;
        if (isSet(modelId)) {
            return this.modelDescriptionStore.getFirst().pipe(map(function (modelDescriptions) {
                var modelDescription = isSet(modelId) ? modelDescriptions.find(function (item) { return item.isSame(modelId); }) : undefined;
                var traversePath = traverseModelPath(modelDescription, path, modelDescriptions);
                if (!traversePath || !traversePath.length) {
                    return;
                }
                return traversePath[traversePath.length - 1].field;
            }));
        }
        else if (this.dataSource) {
            if (!path || path.length != 1) {
                return of(undefined);
            }
            var parameter = this.dataSource.queryParameters.find(function (item) { return item.name == path[0]; });
            if (parameter) {
                return of(parameter);
            }
            var column = this.dataSource.columns.find(function (item) { return item.name == path[0]; });
            if (column) {
                return of(column);
            }
            return of(undefined);
        }
        else {
            return of(undefined);
        }
    };
    FieldInputsEditItemComponent.prototype.getTitle = function (parameter) {
        var result = [];
        if (parameter) {
            result.push(parameter.verboseName || parameter.name);
            if (parameter.description) {
                result.push(parameter.description);
            }
        }
        else {
            var path = this.itemForm.controls.path.value ? coerceArray(this.itemForm.controls.path.value) : [];
            if (path.length) {
                if (this.itemForm.controls.exclude.value) {
                    result.push('exclude');
                }
                result.push(path[path.length - 1]);
                if (isSet(this.itemForm.controls.lookup.value)) {
                    result.push(this.itemForm.controls.lookup.value);
                }
            }
        }
        return result.length ? result.join(' ') : undefined;
    };
    FieldInputsEditItemComponent.prototype.getInputRepresentation = function (input, parameter) {
        var _this = this;
        return this.filterFields$.pipe(switchMap(function (filterFields) {
            var result = getInputRepresentation(input, {
                context: _this.context,
                contextElement: _this.contextElement,
                contextElementPath: _this.contextElementPath,
                contextElementPaths: _this.contextElementPaths,
                filterFields: filterFields
            });
            return forceObservable(result);
        }), map(function (result) {
            return isSet(result) ? result : parameter.description;
        }));
    };
    FieldInputsEditItemComponent.prototype.getDescription = function (parameter, input) {
        if (!parameter) {
            return of('Not existing');
        }
        else if (!parameter.field) {
            return of(null);
        }
        if (!input) {
            return of(null);
        }
        return this.getInputRepresentation(input, parameter);
    };
    FieldInputsEditItemComponent.prototype.getFieldPlaceholder = function (parameter) {
        if (!parameter) {
            return;
        }
        var fieldDescription = getFieldDescriptionByType(parameter.field);
        if ([FieldType.Select, FieldType.MultipleSelect, FieldType.RelatedModel, FieldType.Boolean].includes(parameter.field)) {
            return 'Choose option';
        }
        else {
            return fieldDescription.label;
        }
    };
    FieldInputsEditItemComponent.prototype.getFilterFields$ = function () {
        if (!this.fieldsControl) {
            return of([]);
        }
        return controlValue(this.fieldsControl).pipe(map(function (fields) {
            return fields.map(function (item) {
                var fieldDescription = getFieldDescriptionByType(item.field);
                return {
                    name: item.name,
                    label: item.verboseName || item.name,
                    lookups: fieldDescription
                        ? fieldDescription.lookups.map(function (lookup) {
                            return {
                                name: lookup.type.lookup,
                                label: lookup.type.verboseName || lookup.type.name
                            };
                        })
                        : []
                };
            });
        }));
    };
    FieldInputsEditItemComponent.prototype.removeItem = function () {
        this.array.removeControl(this.itemForm);
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.SetParameter.Deleted, {
            Object: this.analyticsSource
        });
    };
    return FieldInputsEditItemComponent;
}());
export { FieldInputsEditItemComponent };
