<div
  class="sidebar-collapse"
  [class.sidebar-collapse_openable]="true"
  [class.sidebar-collapse_block]="true"
  [class.sidebar-collapse_opened]="opened"
  [class.sidebar-collapse_disabled]="false"
>
  <div class="sidebar-collapse__header" [class.sidebar-collapse__header_error]="false" (click)="toggleOpened()">
    <div class="sidebar-collapse__header-left">
      <div class="sidebar-collapse__header-drag"></div>

      <span
        *ngIf="!effectiveColor"
        class="sidebar-list-item__color sidebar-list-item__color_palette"
        style="margin: 0 0 0 8px;"
      >
      </span>
      <span
        *ngIf="effectiveColor"
        class="sidebar-list-item__color"
        style="margin: 0 0 0 8px;"
        [ngClass]="!(effectiveColor | appIsColorHex) ? 'background-color_' + effectiveColor + '_1' : ''"
        [style.background-color]="(effectiveColor | appIsColorHex) ? effectiveColor : null"
      >
      </span>
    </div>

    <div class="sidebar-collapse__header-main">
      <div class="sidebar-collapse__header-title">
        {{ (group.controls.name.value | appIsSet) ? group.controls.name.value : group.controls.value.value }}
      </div>

      <div
        *ngIf="
          (group.controls.name.value | appIsSet) && group.controls.name.value != group.controls.value.value && !opened
        "
        class="sidebar-collapse__header-description"
        [class.sidebar-collapse__header-description_wrap]="true"
      >
        {{ group.controls.value.value }}
      </div>
    </div>

    <div *ngIf="group.controls.removable.value" class="sidebar-collapse__header-right">
      <a
        href="javascript:void(0)"
        class="sidebar-list-item__action icon-bin"
        (click)="$event.stopPropagation(); remove.emit()"
      >
      </a>
    </div>
  </div>
  <div *ngIf="opened" class="sidebar-collapse__content">
    <div style="padding: 10px 0;">
      <div class="sidebar__element sidebar__element_field-right sidebar__element_margin_xxs">
        <app-sidebar-field [label]="'value'" [layoutRight]="true">
          <app-auto-field
            [form]="group"
            [label]="false"
            [autofocus]="true"
            [field]="
              createField({
                name: 'value',
                field: 'CharField',
                params: { classes: ['input_fill'] }
              })
            "
          ></app-auto-field>
        </app-sidebar-field>
      </div>

      <div class="sidebar__element sidebar__element_field-right sidebar__element_margin_xxs">
        <app-sidebar-field [label]="'label'" [layoutRight]="true">
          <app-auto-field
            [form]="group"
            [label]="false"
            [field]="
              createField({
                name: 'name',
                field: 'CharField',
                params: { classes: ['input_fill'] }
              })
            "
          ></app-auto-field>
        </app-sidebar-field>
      </div>

      <div class="sidebar__element sidebar__element_field-right sidebar__element_margin_xxs">
        <app-sidebar-field [label]="'color'" [layoutRight]="true">
          <app-auto-field
            [form]="group"
            [label]="false"
            [field]="
              createField({
                name: 'color',
                field: 'ColorField',
                params: {
                  classes: ['select_fill'],
                  allow_empty: true,
                  empty_color: defaultColor,
                  default_color: defaultColor,
                  colors: defaultColors,
                  custom_colors: true
                }
              })
            "
          ></app-auto-field>
        </app-sidebar-field>
      </div>
    </div>
  </div>
</div>
