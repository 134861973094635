/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/cdk/observers";
import * as i2 from "../view-context-token-popover/view-context-token-popover.component.ngfactory";
import * as i3 from "../view-context-token-popover/view-context-token-popover.component";
import * as i4 from "../../services/view-context-token-provider/view-context-token-provider";
import * as i5 from "@angular/cdk/overlay";
import * as i6 from "@angular/cdk/bidi";
import * as i7 from "./view-context-token-popover-overlay.component";
import * as i8 from "@angular/cdk/a11y";
var styles_ViewContextTokenPopoverOverlayComponent = [];
var RenderType_ViewContextTokenPopoverOverlayComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ViewContextTokenPopoverOverlayComponent, data: {} });
export { RenderType_ViewContextTokenPopoverOverlayComponent as RenderType_ViewContextTokenPopoverOverlayComponent };
function View_ViewContextTokenPopoverOverlayComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [], null, [[null, "cdkObserveContent"], [null, "mousedown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("cdkObserveContent" === en)) {
        var pd_0 = (_co.onTokenPopoverContentChanged() !== false);
        ad = (pd_0 && ad);
    } if (("mousedown" === en)) {
        var pd_1 = (_co.markTokenPopoverMouseEvent($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 1196032, null, 0, i1.CdkObserveContent, [i1.ContentObserver, i0.ElementRef, i0.NgZone], null, { event: "cdkObserveContent" }), (_l()(), i0.ɵeld(2, 0, null, null, 1, "app-view-context-token-popover", [], null, [[null, "selected"], [null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selected" === en)) {
        var pd_0 = (_co.selected.emit($event) !== false);
        ad = (pd_0 && ad);
    } if (("close" === en)) {
        var pd_1 = (_co.close() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_ViewContextTokenPopoverComponent_0, i2.RenderType_ViewContextTokenPopoverComponent)), i0.ɵdid(3, 770048, null, 0, i3.ViewContextTokenPopoverComponent, [i4.ViewContextTokenProvider, i0.ChangeDetectorRef], { context: [0, "context"], contextElement: [1, "contextElement"], contextElementPath: [2, "contextElementPath"], contextElementPaths: [3, "contextElementPaths"], id: [4, "id"], maxHeight: [5, "maxHeight"], extraSections: [6, "extraSections"], hideSections: [7, "hideSections"], search: [8, "search"], selectFunction: [9, "selectFunction"] }, { selected: "selected", close: "close" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.context; var currVal_1 = _co.contextElement; var currVal_2 = _co.contextElementPath; var currVal_3 = _co.contextElementPaths; var currVal_4 = _co.id; var currVal_5 = _co.maxHeight; var currVal_6 = _co.extraSections; var currVal_7 = _co.hideSections; var currVal_8 = _co.search; var currVal_9 = _co.selectFunction; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }, null); }
export function View_ViewContextTokenPopoverOverlayComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(402653184, 1, { tokenPopoverOverlay: 0 }), (_l()(), i0.ɵand(16777216, [["token_popover_overlay", 2]], null, 2, null, View_ViewContextTokenPopoverOverlayComponent_1)), i0.ɵdid(2, 671744, [[1, 4]], 0, i5.CdkConnectedOverlay, [i5.Overlay, i0.TemplateRef, i0.ViewContainerRef, i5.ɵc, [2, i6.Directionality]], { origin: [0, "origin"], positions: [1, "positions"], panelClass: [2, "panelClass"], open: [3, "open"], growAfterOpen: [4, "growAfterOpen"], push: [5, "push"] }, null), i0.ɵpad(3, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.origin; var currVal_1 = _co.popoverPositions; var currVal_2 = _ck(_v, 3, 0, "overlay"); var currVal_3 = _co.tokenPopoverOpened; var currVal_4 = true; var currVal_5 = true; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
export function View_ViewContextTokenPopoverOverlayComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-view-context-token-popover-overlay", [], null, null, null, View_ViewContextTokenPopoverOverlayComponent_0, RenderType_ViewContextTokenPopoverOverlayComponent)), i0.ɵdid(1, 770048, null, 0, i7.ViewContextTokenPopoverOverlayComponent, [i8.FocusMonitor, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ViewContextTokenPopoverOverlayComponentNgFactory = i0.ɵccf("app-view-context-token-popover-overlay", i7.ViewContextTokenPopoverOverlayComponent, View_ViewContextTokenPopoverOverlayComponent_Host_0, { context: "context", contextElement: "contextElement", contextElementPath: "contextElementPath", contextElementPaths: "contextElementPaths", origin: "origin", openOnFocus: "openOnFocus", forceOpened: "forceOpened", maxHeight: "maxHeight", extraSections: "extraSections", hideSections: "hideSections", search: "search", selectFunction: "selectFunction" }, { selected: "selected", opened: "opened", closed: "closed" }, []);
export { ViewContextTokenPopoverOverlayComponentNgFactory as ViewContextTokenPopoverOverlayComponentNgFactory };
