import { FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { controlValue, isSet } from '@shared';

import { Corners } from './corners';
import { NumberControl } from './number.control';

export class CornersControl extends FormGroup {
  controls: {
    per_corners: FormControl;
    all: NumberControl;
    top_left: NumberControl;
    top_right: NumberControl;
    bottom_right: NumberControl;
    bottom_left: NumberControl;
  };

  cornersControls: NumberControl[] = [
    this.controls.top_left,
    this.controls.top_right,
    this.controls.bottom_right,
    this.controls.bottom_left
  ];

  cornerControls: NumberControl[] = [this.controls.all, ...this.cornersControls];

  constructor(value: Corners = {}) {
    super({
      per_corners: new FormControl(
        [value.topLeft, value.topRight, value.bottomRight, value.bottomLeft].some(item => isSet(item))
      ),
      all: new NumberControl(value.all),
      top_left: new NumberControl(value.topLeft),
      top_right: new NumberControl(value.topRight),
      bottom_right: new NumberControl(value.bottomRight),
      bottom_left: new NumberControl(value.bottomLeft)
    });
  }

  deserialize(value?: Corners) {
    if (value) {
      this.patchValue({
        per_corners: [value.topLeft, value.topRight, value.bottomRight, value.bottomLeft].some(item => isSet(item)),
        all: value.all,
        top_left: value.topLeft,
        top_right: value.topRight,
        bottom_right: value.bottomRight,
        bottom_left: value.bottomLeft
      });
    } else {
      this.patchValue({
        per_corners: false,
        all: undefined,
        top_left: undefined,
        top_right: undefined,
        bottom_right: undefined,
        bottom_left: undefined
      });
    }
  }

  isSet(): boolean {
    return this.cornerControls.some(item => isSet(item.value));
  }

  isSet$(): Observable<boolean> {
    return controlValue(this).pipe(map(() => this.isSet()));
  }

  setPerCorners(value: boolean) {
    if (this.controls.per_corners.value == value) {
      return;
    }

    this.controls.per_corners.patchValue(value);

    if (value && isSet(this.controls.all.value) && this.cornersControls.every(item => !isSet(item.value))) {
      this.cornersControls.forEach(control => control.patchValue(this.controls.all.value));
    } else if (!value && isSet(this.cornersControls[0].value) && !isSet(this.controls.all.value)) {
      this.controls.all.patchValue(this.cornersControls[0].value);
    }
  }

  reset(options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    this.cornerControls.forEach(item => {
      item.reset(options);
    });
  }

  resetDefaults(options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    this.controls.per_corners.patchValue(false, options);
    this.controls.all.patchValue(16, options);
  }

  serialize(): Corners {
    if (!this.isSet()) {
      return;
    }

    if (this.controls.per_corners.value) {
      return {
        topLeft: this.controls.top_left.value || 0,
        topRight: this.controls.top_right.value || 0,
        bottomRight: this.controls.bottom_right.value || 0,
        bottomLeft: this.controls.bottom_left.value || 0
      };
    } else {
      return {
        all: this.controls.all.value || 0
      };
    }
  }

  serialize$(): Observable<Corners> {
    return controlValue(this, { debounce: 200 }).pipe(map(() => this.serialize()));
  }
}
