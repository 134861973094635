import { Injectable, OnDestroy } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import isArray from 'lodash/isArray';
import range from 'lodash/range';
import values from 'lodash/values';
import { combineLatest, Observable, of } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged, first, map, skip, switchMap, tap } from 'rxjs/operators';

import { AggregateFunc } from '@modules/charts';
import { AUTO_OPTION_COLORS } from '@modules/colors';
import { modelFieldToDisplayField, RawListViewSettingsColumn } from '@modules/customize';
import { CustomSelectItem, Option } from '@modules/field-components';
import {
  BaseField,
  deserializeDisplayField,
  getFieldDescriptionByType,
  Input,
  isRequiredInputsSet,
  MultipleSelectStyle,
  OptionsType,
  ParameterArray,
  ParameterField
} from '@modules/fields';
import { ModelDescriptionStore, ModelService } from '@modules/model-queries';
import { ModelDescription } from '@modules/models';
import {
  FieldInputControl,
  InputFieldProvider,
  InputFieldProviderItem,
  inputFieldProviderItemsFromModelGet,
  modelDescriptionHasAutoParameters,
  parametersToProviderItems
} from '@modules/parameters';
import {
  CurrentEnvironmentStore,
  CurrentProjectStore,
  getResourceTypeItemRequestName,
  Resource,
  ResourceName,
  ResourceType,
  resourceTypeItems
} from '@modules/projects';
import { getResourceAddModelComponents } from '@modules/projects-components';
import {
  editableQueryTypes,
  HttpQuery,
  ListModelDescriptionQuery,
  ModelDescriptionQuery,
  QueryType
} from '@modules/queries';
import {
  isResourceCustom,
  prepareDataSourceColumnForGet,
  ResourceControllerService,
  RestAPIResourceParams
} from '@modules/resources';
import { ascComparator, controlValue, getCircleIndex, isSet, objectsSortPredicate } from '@shared';

export interface ModelOption {
  queryType: QueryType;
  model?: string;
}

export function validateOptions(): ValidatorFn {
  return control => {
    const parent = control.parent as SelectFieldViewParamsForm;

    if (!parent) {
      return;
    }

    const optionsType = parent.controls.options_type.value as OptionsType;

    if (optionsType != OptionsType.Static) {
      return;
    }

    if (
      !isSet(control.value) ||
      !isArray(control.value) ||
      !control.value.length ||
      control.value.some(item => !isSet(item.name))
    ) {
      return { required: true };
    }
  };
}

export function validateResource(): ValidatorFn {
  return control => {
    const parent = control.parent as SelectFieldViewParamsForm;

    if (!parent) {
      return;
    }

    const optionsType = parent.controls.options_type.value as OptionsType;

    if (optionsType != OptionsType.Query) {
      return;
    }

    if (!isSet(control.value)) {
      return { required: true };
    }
  };
}

export function validateQuery(): ValidatorFn {
  return control => {
    const parent = control.parent as SelectFieldViewParamsForm;

    if (!parent) {
      return;
    }

    const optionsType = parent.controls.options_type.value as OptionsType;

    if (optionsType != OptionsType.Query) {
      return;
    }

    const query = control.value as ModelDescriptionQuery;

    if (!query || !query.isConfigured()) {
      return { required: true };
    }
  };
}

export function validateResponseField(): ValidatorFn {
  return control => {
    const parent = control.parent as SelectFieldViewParamsForm;

    if (!parent) {
      return;
    }

    const optionsType = parent.controls.options_type.value as OptionsType;

    if (optionsType != OptionsType.Query) {
      return;
    }

    if (!isSet(control.value)) {
      return { required: true };
    }
  };
}

export function validateInputs(): ValidatorFn {
  return control => {
    const parent = control.parent as SelectFieldViewParamsForm;

    if (!parent) {
      return;
    }

    const optionsType = parent.controls.options_type.value as OptionsType;

    if (optionsType != OptionsType.Query) {
      return;
    }

    const fields = parent.inputFieldProvider.getFields();
    const inputs: Input[] = control.value;

    if (!isRequiredInputsSet(fields, inputs)) {
      return { required: true };
    }
  };
}

@Injectable()
export class SelectFieldViewParamsForm extends FormGroup implements OnDestroy {
  queryClass = ListModelDescriptionQuery;
  controls: {
    style: FormControl;
    circular: FormControl;
    create_enabled: FormControl;
    options_type: FormControl;
    options: FormArray;
    resource: FormControl;
    model: FormControl;
    query: FormControl;
    sorting_field: FormControl;
    sorting_asc: FormControl;
    value_field: FormControl;
    label_field: FormControl;
    label_input_enabled: FormControl;
    label_input: FieldInputControl;
    subtitle_field: FormControl;
    subtitle_input_enabled: FormControl;
    subtitle_input: FieldInputControl;
    icon_field: FormControl;
    icon_input_enabled: FormControl;
    icon_input: FieldInputControl;
    color_field: FormControl;
    color_input_enabled: FormControl;
    color_input: FieldInputControl;
    parameters: ParameterArray;
    inputs: FormControl;
    columns: FormControl;
  };
  inputFieldProvider = new InputFieldProvider();
  control: AbstractControl;
  context: Object;

  styleOptions = [
    {
      style: MultipleSelectStyle.Select,
      circular: false,
      image: 'select'
    },
    {
      style: MultipleSelectStyle.Chips,
      circular: false,
      image: 'chips-rounded'
    },
    {
      style: MultipleSelectStyle.Chips,
      circular: true,
      image: 'chips-circular'
    }
  ];

  optionsTypeOptions: Option<OptionsType>[] = [
    { value: OptionsType.Static, name: 'Specify options', icon: 'fileds' },
    { value: OptionsType.Query, name: 'Load options', icon: 'cloud_download' }
  ];

  constructor(
    private modelService: ModelService,
    private modelDescriptionStore: ModelDescriptionStore,
    private resourceControllerService: ResourceControllerService,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore
  ) {
    super({
      style: new FormControl(MultipleSelectStyle.Select),
      circular: new FormControl(false),
      create_enabled: new FormControl(false),
      options_type: new FormControl(OptionsType.Static, Validators.required),
      options: new FormArray([], validateOptions()),
      resource: new FormControl('', validateResource()),
      model: new FormControl(undefined),
      query: new FormControl(undefined, validateQuery()),
      sorting_field: new FormControl(undefined),
      sorting_asc: new FormControl(true),
      value_field: new FormControl('', validateResponseField()),
      label_field: new FormControl('', validateResponseField()),
      label_input_enabled: new FormControl(false),
      label_input: new FieldInputControl({ path: ['value'] }),
      subtitle_field: new FormControl('', validateResponseField()),
      subtitle_input_enabled: new FormControl(false),
      subtitle_input: new FieldInputControl({ path: ['value'] }),
      icon_field: new FormControl(''),
      icon_input_enabled: new FormControl(false),
      icon_input: new FieldInputControl({ path: ['value'] }),
      color_field: new FormControl(''),
      color_input_enabled: new FormControl(false),
      color_input: new FieldInputControl({ path: ['value'] }),
      parameters: new ParameterArray([]),
      inputs: new FormControl([], validateInputs()),
      columns: new FormControl([])
    });
  }

  ngOnDestroy(): void {
    this.inputFieldProvider.clearProvider();
  }

  initObservers() {
    controlValue(this.controls.resource)
      .pipe(distinctUntilChanged(), skip(1))
      .subscribe(value => this.onResourceChange(value));

    combineLatest(controlValue<string>(this.controls.resource), this.controls.model.valueChanges)
      .pipe(debounceTime(0))
      .subscribe(([resourceName, model]) => this.onModelChange(resourceName, model));

    this.controls.parameters.valueChanges.subscribe(value => this.onParametersChange());

    this.controls.query.valueChanges.subscribe(value => this.onQueryChange());

    this.controls.options_type.valueChanges.pipe(delay(0)).subscribe(() => {
      this.controls.options.updateValueAndValidity();
      this.controls.resource.updateValueAndValidity();
      this.controls.query.updateValueAndValidity();
      this.controls.value_field.updateValueAndValidity();
      this.controls.label_field.updateValueAndValidity();
      this.controls.inputs.updateValueAndValidity();
    });
    this.inputFieldProvider.getFields$().subscribe(() => {
      this.controls.inputs.updateValueAndValidity();
    });

    this.valueChanges.subscribe(() => this.submit());
  }

  init(control: AbstractControl, context: Object) {
    this.control = control;
    this.context = context;

    if (control.value) {
      if (control.value['options']) {
        this.formArraySet(
          'options',
          control.value['options'].map(item => this.createOption())
        );
      } else {
        this.formArraySet('options', [this.createOption()]);
      }

      const query = control.value['query'] ? new this.queryClass().deserialize(control.value['query']) : undefined;
      const model = query
        ? {
            queryType: query.queryType,
            model: query.queryType == QueryType.Simple ? query.simpleQuery.model : undefined
          }
        : undefined;

      const parameters = control.value['parameters']
        ? control.value['parameters'].map(item => new ParameterField().deserialize(item))
        : [];
      const inputs = control.value['inputs'] ? control.value['inputs'].map(item => new Input().deserialize(item)) : [];

      this.controls.style.patchValue(control.value['style'] || MultipleSelectStyle.Select);
      this.controls.circular.patchValue(!!control.value['circular']);
      this.controls.create_enabled.patchValue(!!control.value['create_enabled']);
      this.controls.options_type.patchValue(control.value['options_type'] || OptionsType.Static);
      this.controls.options.patchValue(control.value['options'] || []);
      this.controls.resource.patchValue(control.value['resource']);
      this.controls.model.patchValue(model);
      this.controls.query.patchValue(query);
      this.controls.sorting_field.patchValue(control.value['sorting_field']);
      this.controls.sorting_asc.patchValue(control.value['sorting_asc']);
      this.controls.value_field.patchValue(control.value['value_field']);

      this.controls.label_field.patchValue(control.value['label_field']);
      this.controls.label_input_enabled.patchValue(!!control.value['label_field_input']);
      this.controls.label_input.patchValue(
        control.value['label_field_input'] ? control.value['label_field_input'] : {}
      );

      this.controls.subtitle_field.patchValue(control.value['subtitle_field']);
      this.controls.subtitle_input_enabled.patchValue(!!control.value['subtitle_input']);
      this.controls.subtitle_input.patchValue(control.value['subtitle_input'] ? control.value['subtitle_input'] : {});

      this.controls.icon_field.patchValue(control.value['icon_field']);
      this.controls.icon_input_enabled.patchValue(!!control.value['icon_input']);
      this.controls.icon_input.patchValue(control.value['icon_input'] ? control.value['icon_input'] : {});

      this.controls.color_field.patchValue(control.value['color_field']);
      this.controls.color_input_enabled.patchValue(!!control.value['color_input']);
      this.controls.color_input.patchValue(control.value['color_input'] ? control.value['color_input'] : {});

      this.controls.parameters.patchValue(parameters);
      this.controls.inputs.patchValue(inputs);
      this.controls.columns.patchValue((control.value['columns'] || []).map(item => deserializeDisplayField(item)));

      this.markAsPristine();
    } else {
      this.markAsDirty();
    }

    this.updateInputFieldProvider().subscribe();

    this.initObservers();
  }

  formArrayControls(name) {
    return (this.controls[name] as FormArray).controls;
  }

  formArraySet(name, groups) {
    const array = this.controls[name] as FormArray;

    range(array.controls.length).forEach(() => array.removeAt(0));
    groups.forEach(item => array.push(item));

    return array;
  }

  formArrayAppend(name, group) {
    (this.controls[name] as FormArray).push(group);
  }

  formArrayRemove(name, group) {
    const array = this.controls[name] as FormArray;
    const index = array.controls.findIndex(item => item === group);

    array.removeAt(index);

    return array;
  }

  getAutoColor(used: string[] = [], index = 0) {
    return AUTO_OPTION_COLORS.find(item => !used.includes(item)) || getCircleIndex(AUTO_OPTION_COLORS, index);
  }

  createOption() {
    const colors = this.controls.options.value.map(item => item['color']);
    const autoColor = this.getAutoColor(colors);
    const group = new FormGroup({
      color: new FormControl(autoColor || ''),
      name: new FormControl('', Validators.required),
      value: new FormControl(''),
      subtitle: new FormControl(''),
      icon: new FormControl('')
    });
    group.markAsDirty();
    return group;
  }

  removeEmptyOptions() {
    const array = this.controls.options as FormArray;

    while (true) {
      const group = array.controls.find((item: FormGroup) => {
        return values(item.controls).every(control => !isSet(control.value));
      });

      if (!group) {
        break;
      }

      this.formArrayRemove('options', group);
    }
  }

  getMissingAvailable() {
    return this.context && this.context['modelDescription'] != undefined && this.context['field'] != undefined;
  }

  getMissing(): Observable<any[]> {
    if (!this.getMissingAvailable()) {
      return of([]);
    }

    const modelDescription: ModelDescription = this.context['modelDescription'];
    const field: BaseField = this.context['field'];

    return this.modelService
      .group(
        this.currentProjectStore.instance,
        this.currentEnvironmentStore.instance,
        modelDescription.modelId,
        [{ xColumn: field.name }],
        AggregateFunc.Count,
        modelDescription.primaryKeyField
      )
      .pipe(
        map(result => {
          const existingValues = this.value['options'].map(item => item['value']);
          return result.map(item => item.group).filter(item => existingValues.find(i => i == item) == undefined);
        })
      );
  }

  addMissing(newValues: any[]) {
    this.removeEmptyOptions();

    newValues
      .filter(value => value !== undefined)
      .forEach(value => {
        const option = this.createOption();
        option.patchValue({ value: value });
        this.formArrayAppend('options', option);
      });
  }

  modelValueEquals(lhs: ModelOption, rhs: ModelOption) {
    const lhsQueryType = lhs ? lhs.queryType : undefined;
    const lhsModelDescription = lhs && lhs.model ? lhs.model : undefined;
    const rhsQueryType = rhs ? rhs.queryType : undefined;
    const rhsModelDescription = rhs && rhs.model ? rhs.model : undefined;

    return lhsQueryType == rhsQueryType && lhsModelDescription == rhsModelDescription;
  }

  setModelQuery(model: ModelOption) {
    const query = new ModelDescriptionQuery();

    query.queryType = model ? model.queryType : undefined;

    if (query.queryType == QueryType.Simple) {
      if (!query.simpleQuery) {
        query.simpleQuery = new query.simpleQueryClass();
      }

      query.simpleQuery.model = model.model;
    }

    this.controls.query.patchValue(query);
    this.controls.parameters.patchValue([]);
    this.controls.inputs.patchValue([]);
  }

  setModelParams(resourceName: string, model: ModelOption) {
    const resource = this.currentEnvironmentStore.resources.find(item => item.uniqueName == resourceName);
    const modelId = resource && model ? [resource.uniqueName, model.model].join('.') : undefined;

    this.modelDescriptionStore.getDetailFirst(modelId).subscribe(modelDescription => {
      const parameters = modelDescription ? modelDescription.getParameters : [];
      const columns = modelDescription
        ? modelDescription.fields
            .map(item => modelFieldToDisplayField(item, false))
            .map(item => prepareDataSourceColumnForGet(resource, modelDescription, item))
        : [];

      this.controls.parameters.patchValue(parameters);
      this.controls.columns.patchValue(columns);

      if (modelDescription) {
        if (modelDescription.primaryKeyField) {
          this.controls.value_field.patchValue(modelDescription.primaryKeyField);
        }

        const nameField = modelDescription.dbFields
          .map(item => ({ name: item.name, pk: modelDescription.primaryKeyField == item.name }))
          .sort(objectsSortPredicate('pk'))[0];
        if (nameField) {
          this.controls.label_field.patchValue(nameField.name);
        }
      }
    });
  }

  toggleDefaultSorting() {
    const control = this.controls.sorting_asc;
    control.patchValue(!control.value);
  }

  updateInputFieldProvider() {
    return combineLatest(
      this.resource$(this.controls.resource),
      this.modelDescription$(this.controls.resource, this.controls.model),
      controlValue<ParameterField[]>(this.controls.parameters),
      controlValue<ListModelDescriptionQuery>(this.controls.query),
      controlValue<RawListViewSettingsColumn[]>(this.controls.columns)
    ).pipe(
      first(),
      map(([resource, modelDescription, parameters, query, columns]): InputFieldProviderItem[] => {
        return [
          ...parametersToProviderItems(parameters),
          ...inputFieldProviderItemsFromModelGet(resource, modelDescription, query, columns)
        ];
      }),
      tap(items => {
        this.inputFieldProvider.setProvider(items, true);
      })
    );
  }

  resource$(control: AbstractControl): Observable<Resource> {
    return controlValue<string>(control).pipe(
      map(value => this.currentEnvironmentStore.resources.find(item => item.uniqueName == value))
    );
  }

  modelDescription$(resourceControl: AbstractControl, modelControl: AbstractControl): Observable<ModelDescription> {
    return combineLatest(controlValue<string>(resourceControl), controlValue<{ model: string }>(modelControl)).pipe(
      switchMap(([resource, model]) => {
        if (!resource || !model || !model.model) {
          return of(undefined);
        }

        const modelId = [resource, model.model].join('.');
        return this.modelDescriptionStore.getDetailFirst(modelId);
      })
    );
  }

  resourceBaseHttpQuery$(control: AbstractControl): Observable<HttpQuery> {
    return this.resource$(control).pipe(
      map(resource => {
        if (!resource) {
          return undefined;
        }

        const resourceParams = resource.parseParams<RestAPIResourceParams>(RestAPIResourceParams);
        return resourceParams.baseHttpQuery;
      })
    );
  }

  getCustomModelOption(name: string, queryType: QueryType): Option<ModelOption> {
    const option = new ModelDescriptionQuery();

    option.queryType = queryType;

    return {
      value: option,
      name: name,
      icon: 'plus'
    };
  }

  getCustomModelOptions(resource: Resource): Option<ModelOption>[] {
    const options: Option<ModelOption>[] = [];

    if (isResourceCustom(resource)) {
      const controller = this.resourceControllerService.get(resource.type);
      const queryTypes = controller
        ? controller.supportedQueryTypes(resource.typeItem, ModelDescriptionQuery)
        : undefined;
      const queryType = editableQueryTypes.find(item => queryTypes.includes(item));

      if (queryType) {
        const option = this.getCustomModelOption(
          `Make ${getResourceTypeItemRequestName(resource.typeItem)}`,
          queryType
        );
        options.push(option);
      }
    }

    if (resource.type == ResourceType.JetBridge || resource.isSynced() || resource.hasCollectionSync()) {
      const controller = this.resourceControllerService.get(ResourceType.JetBridge);
      const typeItem =
        resource.isSynced() || resource.hasCollectionSync()
          ? resourceTypeItems.find(item => item.name == ResourceName.PostgreSQL)
          : resource.typeItem;
      const queryTypes = controller ? controller.supportedQueryTypes(typeItem, ModelDescriptionQuery) : undefined;
      const queryType = editableQueryTypes.find(item => queryTypes.includes(item));

      if (queryType) {
        const option = this.getCustomModelOption(`Make ${getResourceTypeItemRequestName(typeItem)}`, queryType);
        options.push(option);
      }
    }

    return options;
  }

  resourceModelItems$(control: AbstractControl): Observable<CustomSelectItem<ModelOption>[]> {
    return combineLatest(this.resource$(control), this.modelDescriptionStore.get()).pipe(
      map(([resource, modelDescriptions]) => {
        if (!resource) {
          return [];
        }

        const options: CustomSelectItem<ModelOption>[] = [];

        if (modelDescriptions) {
          options.push(
            ...modelDescriptions
              .filter(item => item.resource == resource.uniqueName)
              .filter(
                item =>
                  !resource.demo ||
                  item.featured ||
                  (this.controls.model.value && this.controls.model.value['model'] == item.model)
              )
              .sort((lhs, rhs) => {
                return ascComparator(
                  String(lhs.verboseNamePlural).toLowerCase(),
                  String(rhs.verboseNamePlural).toLowerCase()
                );
              })
              .map(item => {
                return {
                  option: {
                    value: { queryType: QueryType.Simple, model: item.model },
                    name: item.verboseNamePlural,
                    icon: 'document'
                  }
                };
              })
          );
        }

        const addModelComponents = !resource.demo ? getResourceAddModelComponents(resource.typeItem.name) : [];

        options.push(
          ...addModelComponents.map(item => {
            return {
              button: {
                name: 'add_model',
                label: item.label,
                icon: item.icon,
                data: {
                  addModelComponent: item
                }
              },
              stickyBottom: true,
              orange: true,
              large: true
            };
          })
        );

        options.push(
          ...this.getCustomModelOptions(resource).map(item => {
            return {
              option: item,
              valueIcon: null,
              stickyBottom: true,
              orange: true,
              large: true
            };
          })
        );

        return options;
      })
    );
  }

  modelOption$(modelControl: AbstractControl): Observable<ModelOption> {
    return controlValue(modelControl);
  }

  columnOptions$(columnsControl: AbstractControl): Observable<CustomSelectItem<string>[]> {
    return controlValue<RawListViewSettingsColumn[]>(columnsControl).pipe(
      map(columnsValue => {
        if (!columnsValue) {
          return [];
        }

        return columnsValue.map(item => {
          const fieldDescription = getFieldDescriptionByType(item.field);
          return {
            option: {
              value: item.name,
              name: item.verboseName || item.name,
              icon: fieldDescription ? fieldDescription.icon : undefined
            }
          };
        });
      })
    );
  }

  isFieldSortable(
    query: ListModelDescriptionQuery,
    field: RawListViewSettingsColumn,
    resource?: Resource,
    modelDescription?: ModelDescription
  ) {
    if (field.flex) {
      return false;
    }

    if (query) {
      if (modelDescriptionHasAutoParameters(resource, modelDescription)) {
        return true;
      } else if (query.queryType == QueryType.Simple) {
        return modelDescription && modelDescription.getQuery && modelDescription.getQuery.isFieldSortable(field);
      } else if (query instanceof ListModelDescriptionQuery) {
        return query.isFieldSortable(field);
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  sortableColumnOptions$(): Observable<Option<string>[]> {
    return combineLatest(
      this.resource$(this.controls.resource),
      this.modelDescription$(this.controls.resource, this.controls.model),
      controlValue<ListModelDescriptionQuery>(this.controls.query),
      controlValue<RawListViewSettingsColumn[]>(this.controls.columns)
    ).pipe(
      debounceTime(60),
      map(([resource, modelDescription, getQuery, columns]) => {
        if (!columns) {
          return [];
        }

        return columns
          .filter(item => {
            return this.isFieldSortable(getQuery, item, resource, modelDescription);
          })
          .map(item => {
            return {
              value: item.name,
              name: item.verboseName || item.name
            };
          });
      })
    );
  }

  onResourceChange(resourceName: string) {
    const resource = this.currentEnvironmentStore.resources.find(item => item.uniqueName == resourceName);
    let modelOption: ModelOption;
    const firstModel = this.modelDescriptionStore.instance.find(model => {
      if (model.resource != resourceName) {
        return false;
      } else if (model.getParameters.filter(item => item.required).length) {
        return false;
      }

      if (resource.demo) {
        return model.featured;
      }

      return true;
    });

    if (firstModel) {
      modelOption = {
        queryType: QueryType.Simple,
        model: firstModel.model
      };
    } else if (resource) {
      const customOption = this.getCustomModelOptions(resource)[0];

      if (customOption) {
        modelOption = customOption.value;
      }
    }

    this.controls.model.patchValue(modelOption);
    this.controls.inputs.patchValue([]);
  }

  onModelChange(resourceName: string, model: ModelOption) {
    this.setModelQuery(model);
    this.setModelParams(resourceName, model);

    this.onModelParamsChange();
  }

  onParametersChange() {
    this.onModelParamsChange();
  }

  onQueryChange() {
    this.onModelParamsChange();
  }

  // onColumnsChange() {
  //   this.onModelParamsChange();
  // }

  onModelParamsChange() {
    setTimeout(() => {
      this.updateInputFieldProvider().subscribe();
    }, 0);
  }

  serialize() {
    const result = {
      style: this.controls.style.value,
      circular: this.controls.circular.value,
      create_enabled: this.controls.create_enabled.value,
      options_type: this.controls.options_type.value,
      options: this.controls.options.value,
      resource: this.controls.resource.value,
      query: this.controls.query.value ? this.controls.query.value.serialize() : undefined,
      sorting_field: this.controls.sorting_field.value,
      sorting_asc: this.controls.sorting_asc.value,
      value_field: this.controls.value_field.value,
      label_field: !this.controls.label_input_enabled.value ? this.controls.label_field.value : undefined,
      label_field_input: this.controls.label_input_enabled.value ? this.controls.label_input.value : undefined,
      subtitle_field: !this.controls.subtitle_input_enabled.value ? this.controls.subtitle_field.value : undefined,
      subtitle_input: this.controls.subtitle_input_enabled.value ? this.controls.subtitle_input.value : undefined,
      icon_field: !this.controls.icon_input_enabled.value ? this.controls.icon_field.value : undefined,
      icon_input: this.controls.icon_input_enabled.value ? this.controls.icon_input.value : undefined,
      color_field: !this.controls.color_input_enabled.value ? this.controls.color_field.value : undefined,
      color_input: this.controls.color_input_enabled.value ? this.controls.color_input.value : undefined,
      parameters: this.controls.parameters.value ? this.controls.parameters.value.map(item => item.serialize()) : [],
      inputs: this.controls.inputs.value ? this.controls.inputs.value.map(item => item.serialize()) : [],
      columns: this.controls.columns.value ? this.controls.columns.value.map(item => item.serialize()) : []
    };

    if (result.query && result.query.simpleQuery) {
      result.query.simpleQuery.model = this.controls.model.value ? this.controls.model.value['model'] : undefined;
    }

    return result;
  }

  submit() {
    this.control.patchValue({
      ...this.control.value,
      ...this.serialize()
    });
  }
}
