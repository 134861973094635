import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { MarginControl, NumberControl } from '@modules/customize';
import { isSet, KeyboardEventKeyCode } from '@shared';

enum Side {
  Top = 'top',
  Right = 'right',
  Bottom = 'bottom',
  Left = 'left'
}

@Component({
  selector: 'app-margin-control',
  templateUrl: './margin-control.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MarginControlComponent implements OnInit {
  @Input() control: MarginControl;
  @Input() focus = false;

  focused: Side;
  sides = Side;

  constructor() {}

  ngOnInit() {}

  setFocused(focused: Side) {
    this.focused = focused;
  }

  processArrows(event: KeyboardEvent, control: NumberControl, input: HTMLInputElement) {
    const charCode = event.which ? event.which : event.keyCode;
    const delta = event.shiftKey ? 10 : 1;

    if (charCode == KeyboardEventKeyCode.ArrowUp) {
      const cleanValue = control.cleanValue(control.value);
      control.setValue(isSet(cleanValue) ? cleanValue + delta : delta);
      event.preventDefault();

      setTimeout(() => input.select(), 0);

      return false;
    } else if (charCode == KeyboardEventKeyCode.ArrowDown) {
      const cleanValue = control.cleanValue(control.value);
      control.setValue(isSet(cleanValue) ? cleanValue - delta : 0);

      setTimeout(() => input.select(), 0);

      return false;
    }
  }

  validateKey(event: KeyboardEvent) {
    const charCode = event.which ? event.which : event.keyCode;

    if (charCode >= KeyboardEventKeyCode.Number0 && charCode <= KeyboardEventKeyCode.Number9) {
      return true;
    } else {
      return false;
    }
  }

  selectValue(event: FocusEvent) {
    (event.target as HTMLInputElement).select();
  }
}
