import keys from 'lodash/keys';
import pickBy from 'lodash/pickBy';

import { isSet } from '@shared';

import { BorderSettings } from './border-settings';
import { Corners } from './corners';
import { Margin } from './elements/items/base';
import { FieldElementItem } from './elements/items/field';
import { FilterElementInput } from './elements/items/filter';
import { FillSettings } from './fill-settings';
import { Shadow } from './shadow';
import { TextStyle } from './text-style';

export class FieldElementStyles {
  textStyle?: TextStyle;
  placeholderStyle?: TextStyle;
  labelStyle?: TextStyle;
  labelAdditionalStyle?: TextStyle;
  fillSettings?: FillSettings;
  borderSettings?: BorderSettings;
  borderRadius?: Corners;
  shadow?: Shadow;
  padding?: Margin;
  margin?: Margin;

  hoverTextStyle?: TextStyle;
  hoverPlaceholderStyle?: TextStyle;
  hoverFillSettings?: FillSettings;
  hoverBorderSettings?: BorderSettings;
  hoverShadow?: Shadow;

  focusTextStyle?: TextStyle;
  focusPlaceholderStyle?: TextStyle;
  focusFillSettings?: FillSettings;
  focusBorderSettings?: BorderSettings;
  focusShadow?: Shadow;

  errorTextStyle?: TextStyle;
  errorPlaceholderStyle?: TextStyle;
  errorFillSettings?: FillSettings;
  errorBorderSettings?: BorderSettings;
  errorShadow?: Shadow;

  constructor(options: Partial<FieldElementStyles> = {}) {
    Object.assign(this, options);
  }

  deserialize(data: Object): this {
    if (data['text_style']) {
      this.textStyle = new TextStyle().deserialize(data['text_style']);
    } else {
      this.textStyle = undefined;
    }

    if (data['placeholder_style']) {
      this.placeholderStyle = new TextStyle().deserialize(data['placeholder_style']);
    } else {
      this.placeholderStyle = undefined;
    }

    if (data['label_style']) {
      this.labelStyle = new TextStyle().deserialize(data['label_style']);
    } else {
      this.labelStyle = undefined;
    }

    if (data['label_additional_style']) {
      this.labelAdditionalStyle = new TextStyle().deserialize(data['label_additional_style']);
    } else {
      this.labelAdditionalStyle = undefined;
    }

    if (data['fill_settings']) {
      this.fillSettings = new FillSettings().deserialize(data['fill_settings']);
    } else {
      this.fillSettings = undefined;
    }

    if (data['border_settings']) {
      this.borderSettings = new BorderSettings().deserialize(data['border_settings']);
    } else {
      this.borderSettings = undefined;
    }

    if (data['border_radius']) {
      this.borderRadius = data['border_radius'];
    } else {
      this.borderRadius = undefined;
    }

    if (data['shadow']) {
      this.shadow = new Shadow().deserialize(data['shadow']);
    } else {
      this.shadow = undefined;
    }

    if (data['padding']) {
      this.padding = data['padding'];
    } else {
      this.padding = undefined;
    }

    if (data['margin']) {
      this.margin = data['margin'];
    } else {
      this.margin = undefined;
    }

    if (data['hover_text_style']) {
      this.hoverTextStyle = new TextStyle().deserialize(data['hover_text_style']);
    } else {
      this.hoverTextStyle = undefined;
    }

    if (data['hover_placeholder_style']) {
      this.hoverPlaceholderStyle = new TextStyle().deserialize(data['hover_placeholder_style']);
    } else {
      this.hoverPlaceholderStyle = undefined;
    }

    if (data['hover_fill_settings']) {
      this.hoverFillSettings = new FillSettings().deserialize(data['hover_fill_settings']);
    } else {
      this.hoverFillSettings = undefined;
    }

    if (data['hover_border_settings']) {
      this.hoverBorderSettings = new BorderSettings().deserialize(data['hover_border_settings']);
    } else {
      this.hoverBorderSettings = undefined;
    }

    if (data['hover_shadow']) {
      this.hoverShadow = new Shadow().deserialize(data['hover_shadow']);
    } else {
      this.hoverShadow = undefined;
    }

    if (data['focus_text_style']) {
      this.focusTextStyle = new TextStyle().deserialize(data['focus_text_style']);
    } else {
      this.focusTextStyle = undefined;
    }

    if (data['focus_placeholder_style']) {
      this.focusPlaceholderStyle = new TextStyle().deserialize(data['focus_placeholder_style']);
    } else {
      this.focusPlaceholderStyle = undefined;
    }

    if (data['focus_fill_settings']) {
      this.focusFillSettings = new FillSettings().deserialize(data['focus_fill_settings']);
    } else {
      this.focusFillSettings = undefined;
    }

    if (data['focus_border_settings']) {
      this.focusBorderSettings = new BorderSettings().deserialize(data['focus_border_settings']);
    } else {
      this.focusBorderSettings = undefined;
    }

    if (data['focus_shadow']) {
      this.focusShadow = new Shadow().deserialize(data['focus_shadow']);
    } else {
      this.focusShadow = undefined;
    }

    if (data['error_text_style']) {
      this.errorTextStyle = new TextStyle().deserialize(data['error_text_style']);
    } else {
      this.errorTextStyle = undefined;
    }

    if (data['error_placeholder_style']) {
      this.errorPlaceholderStyle = new TextStyle().deserialize(data['error_placeholder_style']);
    } else {
      this.errorPlaceholderStyle = undefined;
    }

    if (data['error_fill_settings']) {
      this.errorFillSettings = new FillSettings().deserialize(data['error_fill_settings']);
    } else {
      this.errorFillSettings = undefined;
    }

    if (data['error_border_settings']) {
      this.errorBorderSettings = new BorderSettings().deserialize(data['error_border_settings']);
    } else {
      this.errorBorderSettings = undefined;
    }

    if (data['error_shadow']) {
      this.errorShadow = new Shadow().deserialize(data['error_shadow']);
    } else {
      this.errorShadow = undefined;
    }

    return this;
  }

  serialize(): Object {
    return {
      text_style: this.textStyle ? this.textStyle.serialize() : undefined,
      placeholder_style: this.placeholderStyle ? this.placeholderStyle.serialize() : undefined,
      label_style: this.labelStyle ? this.labelStyle.serialize() : undefined,
      label_additional_style: this.labelAdditionalStyle ? this.labelAdditionalStyle.serialize() : undefined,
      fill_settings: this.fillSettings ? this.fillSettings.serialize() : undefined,
      border_settings: this.borderSettings ? this.borderSettings.serialize() : undefined,
      border_radius: this.borderRadius,
      shadow: this.shadow ? this.shadow.serialize() : undefined,
      padding: this.padding,
      margin: this.margin,

      hover_text_style: this.hoverTextStyle ? this.hoverTextStyle.serialize() : undefined,
      hover_placeholder_style: this.hoverPlaceholderStyle ? this.hoverPlaceholderStyle.serialize() : undefined,
      hover_fill_settings: this.hoverFillSettings ? this.hoverFillSettings.serialize() : undefined,
      hover_border_settings: this.hoverBorderSettings ? this.hoverBorderSettings.serialize() : undefined,
      hover_shadow: this.hoverShadow ? this.hoverShadow.serialize() : undefined,

      focus_text_style: this.focusTextStyle ? this.focusTextStyle.serialize() : undefined,
      focus_placeholder_style: this.focusPlaceholderStyle ? this.focusPlaceholderStyle.serialize() : undefined,
      focus_fill_settings: this.focusFillSettings ? this.focusFillSettings.serialize() : undefined,
      focus_border_settings: this.focusBorderSettings ? this.focusBorderSettings.serialize() : undefined,
      focus_shadow: this.focusShadow ? this.focusShadow.serialize() : undefined,

      error_text_style: this.errorTextStyle ? this.errorTextStyle.serialize() : undefined,
      error_placeholder_style: this.errorPlaceholderStyle ? this.errorPlaceholderStyle.serialize() : undefined,
      error_fill_settings: this.errorFillSettings ? this.errorFillSettings.serialize() : undefined,
      error_border_settings: this.errorBorderSettings ? this.errorBorderSettings.serialize() : undefined,
      error_shadow: this.errorShadow ? this.errorShadow.serialize() : undefined
    };
  }

  apply(other?: Partial<this>): this {
    const properties = [
      'textStyle',
      'placeholderStyle',
      'labelStyle',
      'labelAdditionalStyle',
      'fillSettings',
      'borderSettings',
      'borderRadius',
      'shadow',
      'padding',
      'margin',

      'hoverTextStyle',
      'hoverPlaceholderStyle',
      'hoverFillSettings',
      'hoverBorderSettings',
      'hoverShadow',

      'focusTextStyle',
      'focusPlaceholderStyle',
      'focusFillSettings',
      'focusBorderSettings',
      'focusShadow',

      'errorTextStyle',
      'errorPlaceholderStyle',
      'errorFillSettings',
      'errorBorderSettings',
      'errorShadow'
    ];

    properties.forEach(property => {
      if (other && isSet(other[property])) {
        this[property] = other[property];
      }
    });

    return this;
  }
}

export function getFieldElementStyles(element: FieldElementItem | FilterElementInput): FieldElementStyles {
  const options: Partial<FieldElementStyles> = pickBy(
    {
      textStyle: element.textStyle,
      placeholderStyle: element.placeholderStyle,
      labelStyle: element.labelStyle,
      labelAdditionalStyle: element.labelAdditionalStyle,
      fillSettings: element.fillSettings,
      borderSettings: element.borderSettings,
      borderRadius: element.borderRadius,
      shadow: element.shadow,
      padding: element.padding,
      ...(keys(element.margin).length && {
        margin: element.margin
      }),

      hoverTextStyle: element.hoverTextStyle,
      hoverPlaceholderStyle: element.hoverPlaceholderStyle,
      hoverFillSettings: element.hoverFillSettings,
      hoverBorderSettings: element.hoverBorderSettings,
      hoverShadow: element.hoverShadow,

      focusTextStyle: element.focusTextStyle,
      focusPlaceholderStyle: element.focusPlaceholderStyle,
      focusFillSettings: element.focusFillSettings,
      focusBorderSettings: element.focusBorderSettings,
      focusShadow: element.focusShadow,

      errorTextStyle: element.errorTextStyle,
      errorPlaceholderStyle: element.errorPlaceholderStyle,
      errorFillSettings: element.errorFillSettings,
      errorBorderSettings: element.errorBorderSettings,
      errorShadow: element.errorShadow
    },
    v => isSet(v)
  );

  if (!keys(options).length) {
    return;
  }

  return new FieldElementStyles(options);
}

export function applyFieldElementStyles(element: FieldElementItem | FilterElementInput, styles?: FieldElementStyles) {
  if (!styles) {
    styles = new FieldElementStyles();
  }

  element.textStyle = styles.textStyle;
  element.placeholderStyle = styles.placeholderStyle;
  element.labelStyle = styles.labelStyle;
  element.labelAdditionalStyle = styles.labelAdditionalStyle;
  element.fillSettings = styles.fillSettings;
  element.borderSettings = styles.borderSettings;
  element.borderRadius = styles.borderRadius;
  element.shadow = styles.shadow;
  element.padding = styles.padding;
  element.margin = styles.margin;

  element.hoverTextStyle = styles.hoverTextStyle;
  element.hoverPlaceholderStyle = styles.hoverPlaceholderStyle;
  element.hoverFillSettings = styles.hoverFillSettings;
  element.hoverBorderSettings = styles.hoverBorderSettings;
  element.hoverShadow = styles.hoverShadow;

  element.focusTextStyle = styles.focusTextStyle;
  element.focusPlaceholderStyle = styles.focusPlaceholderStyle;
  element.focusFillSettings = styles.focusFillSettings;
  element.focusBorderSettings = styles.focusBorderSettings;
  element.focusShadow = styles.focusShadow;

  element.errorTextStyle = styles.errorTextStyle;
  element.errorPlaceholderStyle = styles.errorPlaceholderStyle;
  element.errorFillSettings = styles.errorFillSettings;
  element.errorBorderSettings = styles.errorBorderSettings;
  element.errorShadow = styles.errorShadow;
}
