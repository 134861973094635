<div
  class="compact-inputs-row"
  [class.compact-inputs-row_yellow]="control.controls.enabled_input | appControlValue | async | appIsFieldInputSet"
  [class.compact-inputs-row_disabled]="!control.controls.enabled.value"
>
  <div class="compact-inputs-row__item">
    <app-fill-color-button
      [control]="control"
      [viewContext]="viewContext"
      [view]="view"
      [layer]="layer"
      [fillTypesEnabled]="fillTypesEnabled"
      (openedChange)="colorOpenedChange.emit()"
      #color_button
    ></app-fill-color-button>
  </div>

  <ng-container *ngIf="control.controls.type.value == fillTypes.Color">
    <ng-container
      *ngIf="!control.controls.color_input_enabled.value && !(control.controls.color.controls.global.value | appIsSet)"
    >
      <div class="compact-inputs-row__item compact-inputs-row__item_fill">
        <app-hex-control [formControl]="control.controls.color"></app-hex-control>
      </div>

      <div class="compact-inputs-row__item">
        <app-alpha-control [formControl]="control.controls.color.controls.alpha"></app-alpha-control>
      </div>
    </ng-container>

    <div
      *ngIf="control.controls.color.controls.global.value | appIsSet"
      class="compact-inputs-row__item compact-inputs-row__item_fill"
    >
      <app-global-color-control
        [control]="control.controls.color.controls.global"
        [colorDisplay]="false"
      ></app-global-color-control>
    </div>

    <ng-container *ngIf="control.controls.color_input_enabled.value">
      <div class="compact-inputs-row__item compact-inputs-row__item_fill">
        <div class="compact-input compact-input_clickable" (click)="color_button.open()">
          <div class="compact-input__left">
            <span class="compact-input__icon icon-function"></span>
          </div>

          <div class="compact-input__control">
            <ng-container *ngIf="colorInputRepresentation | appIsSet">{{ colorInputRepresentation }}</ng-container>
            <ng-container *ngIf="!(colorInputRepresentation | appIsSet)">Not specified</ng-container>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="control.controls.type.value == fillTypes.Gradient">
    <div class="compact-inputs-row__item compact-inputs-row__item_fill">
      <app-compact-select
        [formControl]="control.controls.gradient.controls.type"
        [options]="control.controls.gradient.typeOptions"
      ></app-compact-select>
    </div>

    <div class="compact-inputs-row__item">
      <app-alpha-control [formControl]="control.controls.opacity"></app-alpha-control>
    </div>
  </ng-container>

  <ng-container *ngIf="control.controls.type.value == fillTypes.Image">
    <div class="compact-inputs-row__item compact-inputs-row__item_fill">
      <app-compact-select
        [formControl]="control.controls.image_fill.controls.type"
        [options]="control.controls.image_fill.typeOptions"
      ></app-compact-select>
    </div>

    <div class="compact-inputs-row__item">
      <app-alpha-control [formControl]="control.controls.opacity"></app-alpha-control>
    </div>
  </ng-container>

  <ng-container *ngIf="control.controls.type.value == fillTypes.Icon">
    <div class="compact-inputs-row__item compact-inputs-row__item_fill">
      <app-compact-input
        [formControl]="control.controls.icon_fill.controls.size"
        [postfix]="'px'"
        [placeholder]="'auto size'"
        [number]="true"
        [numberOptions]="{ min: 1 }"
        [numberDefault]="14"
      ></app-compact-input>
    </div>

    <div class="compact-inputs-row__item">
      <app-alpha-control [formControl]="control.controls.opacity"></app-alpha-control>
    </div>
  </ng-container>

  <div class="compact-inputs-row__item">
    <div
      class="compact-button compact-button_primary icon-eye"
      [class.icon-eye_2]="control.controls.enabled.value"
      [class.icon-eye_disabled_2]="!control.controls.enabled.value"
      (click)="control.controls.enabled.patchValue(!control.controls.enabled.value)"
    ></div>
  </div>

  <div class="compact-inputs-row__item">
    <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayPanelClass]="['overlay', 'overlay_horizontal_center']"
      [cdkConnectedOverlayPositions]="settingsPositions"
      [cdkConnectedOverlayOrigin]="trigger"
      [cdkConnectedOverlayOpen]="settingsOpened"
      [cdkConnectedOverlayPush]="true"
      [cdkConnectedOverlayGrowAfterOpen]="true"
      [cdkConnectedOverlayHasBackdrop]="true"
      [cdkConnectedOverlayBackdropClass]="'popover2-backdrop'"
      (backdropClick)="setSettingsOpened(false)"
      (detach)="setSettingsOpened(false)"
    >
      <div class="compact-inputs-overlay overlay-content overlay-content_animated theme_dark">
        <div class="compact-inputs-element">
          <div class="compact-heading">
            Conditional Fill
          </div>

          <app-input-edit
            [itemForm]="control.controls.enabled_input"
            [context]="viewContext"
            [staticValueDisabled]="true"
            [placeholder]="'Value'"
            [fill]="true"
            [dark]="true"
            [darker]="true"
          ></app-input-edit>
        </div>

        <div class="compact-inputs-element">
          <div class="compact-inputs-row compact-inputs-row_align_right">
            <div class="compact-inputs-row__item">
              <div class="button button_small button_orange-border button_no-margin" (click)="remove.emit()">
                <span class="button__icon button__icon_left icon-bin"></span>
                <span class="button__label">Delete fill</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>

    <div
      class="compact-button compact-button_primary icon-more"
      cdkOverlayOrigin
      #trigger="cdkOverlayOrigin"
      (click)="setSettingsOpened(true)"
    ></div>
  </div>
</div>
