import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { TintStyle } from '@modules/actions';
import { ActionElementStylesControl } from '@modules/customize-bar';

@Component({
  selector: 'app-styles-preview-button',
  templateUrl: './styles-preview-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StylesPreviewButtonComponent implements OnInit {
  @Input() stylesControlPrimary: ActionElementStylesControl;
  @Input() stylesControlDefault: ActionElementStylesControl;
  @Input() stylesControlTransparent: ActionElementStylesControl;
  @Input() accentColor: string;

  tintStyles = TintStyle;

  constructor() {}

  ngOnInit() {}
}
