/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../dynamic-component/components/dynamic/dynamic.component.ngfactory";
import * as i2 from "../../../dynamic-component/components/dynamic/dynamic.component";
import * as i3 from "./base-popup.component";
import * as i4 from "../../services/popup/popup.service";
var styles_BasePopupComponent = [];
var RenderType_BasePopupComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_BasePopupComponent, data: {} });
export { RenderType_BasePopupComponent as RenderType_BasePopupComponent };
export function View_BasePopupComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 16777216, null, null, 1, "app-dynamic-component", [], null, null, null, i1.View_DynamicComponent_0, i1.RenderType_DynamicComponent)), i0.ɵdid(1, 442368, null, 0, i2.DynamicComponent, [i0.ElementRef, i0.Injector, i0.ComponentFactoryResolver, i0.ApplicationRef, i0.ViewContainerRef], { componentData: [0, "componentData"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_BasePopupComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-base-popup", [], [[2, "theme_dark", null]], null, null, View_BasePopupComponent_0, RenderType_BasePopupComponent)), i0.ɵdid(1, 245760, null, 0, i3.BasePopupComponent, [i0.ElementRef, i4.PopupService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).dark; _ck(_v, 0, 0, currVal_0); }); }
var BasePopupComponentNgFactory = i0.ɵccf("app-base-popup", i3.BasePopupComponent, View_BasePopupComponent_Host_0, { data: "data", disableClose: "disableClose", closeWithoutConfirm: "closeWithoutConfirm", closeTitle: "closeTitle", closeDescription: "closeDescription", requestClose: "requestClose", orange: "orange", dark: "dark", theme: "theme" }, { closed: "closed" }, []);
export { BasePopupComponentNgFactory as BasePopupComponentNgFactory };
