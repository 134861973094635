import { DatasetGroupLookup } from '@modules/charts';

export const pieChartSampleData = [
  {
    name: undefined,
    color: undefined,
    format: undefined,
    groupLookup: DatasetGroupLookup.Auto,
    dataset: [
      { group: 'Segment A', value: 500 },
      { group: 'Segment B', value: 450 },
      { group: 'Segment C', value: 450 },
      { group: 'Segment D', value: 420 },
      { group: 'Segment E', value: 400 },
      { group: 'Segment F', value: 320 },
      { group: 'Segment G', value: 320 },
      { group: 'Segment H', value: 250 },
      { group: 'Segment I', value: 240 },
      { group: 'Segment J', value: 235 },
      { group: 'Segment K', value: 210 },
      { group: 'Segment L', value: 200 }
    ]
  }
];
