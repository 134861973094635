var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormArray, FormControl } from '@angular/forms';
import isArray from 'lodash/isArray';
import range from 'lodash/range';
var ValueArray = /** @class */ (function (_super) {
    __extends(ValueArray, _super);
    function ValueArray(childOpts) {
        var _this = _super.call(this, []) || this;
        _this.childOpts = childOpts;
        return _this;
    }
    ValueArray.prototype.pushNew = function () {
        var control = new FormControl(undefined, this.childOpts);
        this.push(control);
        return control;
    };
    ValueArray.prototype.removeLast = function () {
        this.removeAt(this.controls.length - 1);
    };
    ValueArray.prototype.prepareControls = function (value) {
        var _this = this;
        var addControls = value.length > this.controls.length ? value.length - this.controls.length : 0;
        var removeControls = value.length < this.controls.length ? this.controls.length - value.length : 0;
        range(addControls).forEach(function () { return _this.pushNew(); });
        range(removeControls).forEach(function () { return _this.removeLast(); });
    };
    ValueArray.prototype.cleanValue = function (value) {
        if (!isArray(value)) {
            value = [value];
        }
        return value;
    };
    ValueArray.prototype.patchValue = function (value, options) {
        value = this.cleanValue(value);
        this.prepareControls(value);
        _super.prototype.patchValue.call(this, value, options);
    };
    ValueArray.prototype.setValue = function (value, options) {
        value = this.cleanValue(value);
        this.prepareControls(value);
        _super.prototype.setValue.call(this, value, options);
    };
    Object.defineProperty(ValueArray.prototype, "value", {
        get: function () {
            return this.controls.map(function (item) { return item.value; });
        },
        set: function (value) { },
        enumerable: true,
        configurable: true
    });
    return ValueArray;
}(FormArray));
export { ValueArray };
