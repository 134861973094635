import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { BaseField, createFormFieldFactory, FieldType, registerFieldViewParamsComponent } from '@modules/fields';

import { FieldParamsComponent } from '../../field-params/field-params.component';
import { ValueFormatControl } from '../../value-format-edit/value-format.control';
import { NumberFieldViewParamsForm } from './number-field-view-params.form';

@Component({
  selector: 'app-number-field-view-params',
  templateUrl: './number-field-view-params.component.html',
  providers: [ValueFormatControl, NumberFieldViewParamsForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NumberFieldViewParamsComponent extends FieldParamsComponent implements OnInit, OnDestroy {
  createField = createFormFieldFactory();
  editable = false;
  fieldTypes = FieldType;

  constructor(public form: NumberFieldViewParamsForm, private cd: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    if (this.field$) {
      this.field$.pipe(untilDestroyed(this)).subscribe(field => {
        this.initForm(field);
        this.editable = this.getEditable(field);
        this.cd.markForCheck();
      });
    } else {
      this.initForm(this.field);
    }
  }

  ngOnDestroy(): void {}

  initForm(field?: BaseField) {
    this.form.init(this.control, {
      editable: this.getEditable(field)
    });
  }

  getEditable(field?: BaseField): boolean {
    return (!this.configurable || this.configurable.editable) && field && field['editable'];
  }
}

registerFieldViewParamsComponent(FieldType.Number, NumberFieldViewParamsComponent);
