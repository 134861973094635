import { Injector } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DateRangeElementStyles, MarginControl } from '@modules/customize';
import { controlValue } from '@shared';

import { TextStyleControl } from '../text-style-edit/text-style.control';

export class DateRangeElementStylesControl extends FormGroup {
  controls: {
    label_style: TextStyleControl;
    label_additional_style: TextStyleControl;
    margin: MarginControl;
  };

  constructor(private injector: Injector) {
    super({
      label_style: TextStyleControl.inject(injector, {
        global: 'fieldLabelTextStyle',
        colorAlphaEnabled: true
      }),
      label_additional_style: TextStyleControl.inject(injector, {
        global: 'fieldLabelAdditionalTextStyle',
        colorAlphaEnabled: true
      }),
      margin: new MarginControl()
    });
  }

  deserialize(instance?: DateRangeElementStyles) {
    if (!instance) {
      return;
    }

    this.controls.label_style.deserialize(instance.labelStyle);
    this.controls.label_additional_style.deserialize(instance.labelAdditionalStyle);

    this.controls.margin.deserialize(instance.margin);
  }

  isSet(): boolean {
    return [this.controls.label_style, this.controls.label_additional_style, this.controls.margin].some(control =>
      control.isSet()
    );
  }

  stylesDefaultUpdated$(): Observable<void> {
    return combineLatest([
      this.controls.label_style.getStyleDefault$(),
      this.controls.label_additional_style.getStyleDefault$()
    ]).pipe(map(() => undefined));
  }

  serialize(): DateRangeElementStyles {
    if (!this.isSet()) {
      return;
    }

    return new DateRangeElementStyles({
      labelStyle: this.controls.label_style.serialize(false),
      labelAdditionalStyle: this.controls.label_additional_style.serialize(false),
      margin: this.controls.margin.serialize()
    });
  }

  serialize$(): Observable<DateRangeElementStyles> {
    return controlValue(this, { debounce: 200 }).pipe(map(() => this.serialize()));
  }
}
