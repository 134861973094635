import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { MarginControl } from '@modules/customize';

import { Style, StyleItem, StylesEditComponent, StyleType } from '../styles-edit/styles-edit.component';
import { TextStyleControl } from '../text-style-edit/text-style.control';
import { RangeSliderElementStylesControl } from './range-slider-element-styles.control';

@Component({
  selector: 'app-styles-range-slider-element',
  templateUrl: './styles-range-slider-element-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StylesRangeSliderElementEditComponent implements OnInit, OnDestroy {
  @Input() control: RangeSliderElementStylesControl;

  @ViewChild(StylesEditComponent) stylesEditComponent: StylesEditComponent;

  styles: StyleItem[] = [];

  ngOnInit(): void {
    this.updateStyles();
  }

  ngOnDestroy(): void {}

  updateStyles() {
    this.styles = [
      {
        style: {
          label: 'Label',
          icon: 'text',
          type: StyleType.TextStyle,
          control: this.control.controls.label_style,
          isSet$: control => control.isSet$(),
          onRemove: style => style.control.resetDefaults()
        } as Style<TextStyleControl>
      },
      {
        style: {
          label: 'Label additional',
          icon: 'text',
          type: StyleType.TextStyle,
          control: this.control.controls.label_additional_style,
          isSet$: control => control.isSet$(),
          onRemove: style => style.control.resetDefaults()
        } as Style<TextStyleControl>
      },
      {
        style: {
          label: 'Outer spacing',
          icon: 'align_horizontal_center',
          type: StyleType.Margin,
          control: this.control.controls.margin,
          isSet$: control => control.isSet$(),
          onRemove: style => style.control.reset()
        } as Style<MarginControl>
      }
    ];
  }
}
