var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import keys from 'lodash/keys';
import { combineLatest, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, skip } from 'rxjs/operators';
import { ActionItem } from '@modules/actions';
import { editorJsOutputDataToQuillDelta, FieldElementItem, FormStyle, FormSubmitElementItem, generateElementName, getContextElementNames, ITEM_OUTPUT, MarginControl, TextElementItem, VALUE_OUTPUT } from '@modules/customize';
import { ElementConfigurationService } from '@modules/customize-configuration';
import { DataSourceType } from '@modules/data-sources';
import { fieldsEditItemFromParameterField } from '@modules/field-components';
import { Input, Input as FieldInput, InputValueType } from '@modules/fields';
import { ModelDescriptionStore } from '@modules/model-queries';
import { FieldInputControl, InputFieldProvider } from '@modules/parameters';
import { ModelDescriptionQuery, QueryType } from '@modules/queries';
import { controlValid, isSet } from '@shared';
import { CustomizeBarActionEditForm } from '../customize-bar-action-edit/customize-bar-action-edit.form';
import { ModelDescriptionDataSourceControl } from '../model-description-data-source-edit/model-description-data-source';
export var validateColumns = function (control) {
    if (!control.value || !control.value.length) {
        return { required: true };
    }
};
export var validateAction = function (control) {
    var parent = control.parent;
    if (!parent) {
        return of(null);
    }
    return parent.elementConfigurationService.isActionConfigured(control.submit().action).pipe(map(function (configured) {
        if (!configured) {
            return { required: true };
        }
    }));
};
var CustomizeBarFormEditForm = /** @class */ (function (_super) {
    __extends(CustomizeBarFormEditForm, _super);
    function CustomizeBarFormEditForm(modelDescriptionStore, elementConfigurationService, getDataSourceControl, submitActionForm) {
        var _this = _super.call(this, {
            generated: new FormControl(false),
            children: new FormControl(undefined),
            name: new FormControl(''),
            get_enabled: new FormControl(false),
            get_data_source: getDataSourceControl,
            submit_action: submitActionForm,
            columns: new FormControl([]),
            style: new FormControl(FormStyle.Wrap),
            visible_input: new FieldInputControl({ path: ['value'] }),
            margin: new MarginControl(),
            load_invisible: new FormControl(false)
        }) || this;
        _this.modelDescriptionStore = modelDescriptionStore;
        _this.elementConfigurationService = elementConfigurationService;
        _this.inputFieldProvider = new InputFieldProvider();
        _this.styleOptions = [
            {
                value: FormStyle.Wrap,
                name: 'Wrap',
                image: 'forms-style-wrap'
            },
            {
                value: FormStyle.Background,
                name: 'Background',
                image: 'forms-style-background'
            }
        ];
        return _this;
    }
    CustomizeBarFormEditForm.prototype.ngOnDestroy = function () {
        this.inputFieldProvider.clearProvider();
    };
    CustomizeBarFormEditForm.prototype.init = function (element, context, firstInit) {
        var _this = this;
        if (firstInit === void 0) { firstInit = false; }
        this.element = element;
        this.context = context;
        var value = {
            generated: element.generated,
            name: element.name ? element.name : 'Form',
            get_enabled: !!element.getDataSource,
            columns: element.columns,
            style: element.style || FormStyle.Wrap,
            visible_input: element.visibleInput ? element.visibleInput.serializeWithoutPath() : {},
            margin: element.margin,
            load_invisible: element.loadInvisible
        };
        this.patchValue(value, { emitEvent: false });
        this.controls.get_data_source.deserialize(element.getDataSource ? element.getDataSource : undefined);
        this.controls.submit_action.init({
            actionItem: element.submitAction,
            actionLabel: 'Form submit',
            actionItemClass: ActionItem,
            // modelDescriptionInContext: modelDescriptionInContext,
            approveEnabled: true,
            confirmationEnabled: true,
            completionEditable: true,
            context: this.context
        }, firstInit);
        if (!firstInit) {
            this.markAsDirty();
        }
        this.controls.submit_action
            .getActionDescription$()
            .pipe(distinctUntilChanged(function (lhs, rhs) {
            var lhsId = lhs ? lhs.id : undefined;
            var rhsId = rhs ? rhs.id : undefined;
            return lhsId === rhsId;
        }), skip(1))
            .subscribe(function (actionDescription) {
            if (actionDescription &&
                isSet(actionDescription.model) &&
                ['update', 'delete'].includes(actionDescription.modelAction)) {
                var query = new ModelDescriptionQuery();
                query.queryType = QueryType.Simple;
                query.simpleQuery = new query.simpleQueryClass();
                query.simpleQuery.model = actionDescription.model;
                _this.controls.get_enabled.patchValue(true);
                _this.controls.get_data_source.controls.type.patchValue(DataSourceType.Query);
                _this.controls.get_data_source.controls.query_resource.patchValue(actionDescription.resource);
                _this.controls.get_data_source.controls.query.patchValue(query);
            }
            else {
                _this.controls.get_enabled.patchValue(false);
                _this.controls.get_data_source.controls.query_resource.patchValue(null);
                _this.controls.get_data_source.controls.query.patchValue(null);
            }
        });
    };
    CustomizeBarFormEditForm.prototype.enableGetQuery = function () {
        if (this.controls.get_enabled.value) {
            return;
        }
        this.controls.get_enabled.patchValue(true);
        var actionResourceName = this.controls.submit_action.controls.resource.value;
        var actionOption = this.controls.submit_action.controls.action.value;
        var actionModelDescription = this.modelDescriptionStore.instance
            .filter(function (item) { return item.resource == actionResourceName; })
            .find(function (item) { return actionOption && item.autoActions().some(function (action) { return action.uniqueName == actionOption.action; }); });
        if (actionResourceName) {
            this.controls.get_data_source.controls.query_resource.patchValue(actionResourceName);
        }
        if (actionModelDescription) {
            var query = new ModelDescriptionQuery();
            query.queryType = QueryType.Simple;
            query.simpleQuery = new query.simpleQueryClass();
            query.simpleQuery.model = actionModelDescription.model;
            this.controls.get_data_source.controls.query.patchValue(query);
        }
    };
    CustomizeBarFormEditForm.prototype.getFormParameters = function () {
        var actionOption = this.controls.submit_action.controls.action.value;
        var actionResourceName = this.controls.submit_action.controls.resource.value;
        var actionQueryType = actionOption ? actionOption.queryType : undefined;
        var actionQueryName = actionOption ? actionOption.action : undefined;
        var actionParameters = this.controls.submit_action.controls.action_params.value;
        var getDataSource = this.controls.get_data_source.serialize();
        var actionUpdateModelDescription = this.modelDescriptionStore.instance
            .filter(function (item) { return item.resource == actionResourceName; })
            .find(function (item) { return item.autoActionUniqueName('update') == actionQueryName; });
        var updateModelAction = getDataSource &&
            getDataSource.queryResource == actionResourceName &&
            actionQueryType == QueryType.Simple &&
            getDataSource.query &&
            getDataSource.query.queryType == QueryType.Simple &&
            getDataSource.query.simpleQuery &&
            actionUpdateModelDescription &&
            actionUpdateModelDescription.model == getDataSource.query.simpleQuery.model;
        return actionParameters.map(function (parameter) {
            var getQueryInput = updateModelAction && getDataSource.queryInputs.find(function (item) { return item.isName(parameter.name) && item.isSet(); });
            return {
                parameter: parameter,
                enabled: !getQueryInput,
                getQueryInput: !!getQueryInput
            };
        });
    };
    CustomizeBarFormEditForm.prototype.generateForm = function () {
        var _this = this;
        var names = getContextElementNames(this.context);
        var children = [];
        var titleElement = new TextElementItem();
        titleElement.generateUid();
        titleElement.name = generateElementName(titleElement, names);
        titleElement.quillDelta = editorJsOutputDataToQuillDelta({
            blocks: [
                {
                    type: 'header',
                    data: {
                        text: 'Form',
                        level: 1
                    }
                }
            ]
        });
        children.push(titleElement);
        var formParameters = this.getFormParameters();
        var fieldElements = formParameters
            .filter(function (item) { return item.enabled; })
            .map(function (item) {
            var element = new FieldElementItem();
            element.generateUid();
            element.settings = __assign({}, fieldsEditItemFromParameterField(item.parameter), { verboseName: item.parameter.verboseName || item.parameter.name, editable: true });
            element.updateFormField();
            element.name = generateElementName(element, names);
            if (_this.controls.get_enabled.value &&
                _this.controls.get_data_source.controls.columns.value.find(function (i) { return i.name == item.parameter.name; })) {
                var input = new Input();
                input.path = ['value'];
                input.valueType = InputValueType.Context;
                input.contextValue = ['elements', _this.element.uid, ITEM_OUTPUT, item.parameter.name];
                element.settings.valueInput = input;
            }
            return element;
        });
        children.push.apply(children, fieldElements);
        var submitElement = new FormSubmitElementItem();
        submitElement.generateUid();
        submitElement.verboseNameInput = this.controls.submit_action.controls.verbose_name.value
            ? new FieldInput().deserialize(this.controls.submit_action.controls.verbose_name.value)
            : undefined;
        children.push(submitElement);
        var submitInputs = [];
        submitInputs.push.apply(submitInputs, formParameters
            .filter(function (item) { return item.getQueryInput; })
            .map(function (item) {
            var input = new Input();
            input.path = [item.parameter.name];
            input.valueType = InputValueType.Context;
            input.contextValue = [ITEM_OUTPUT, item.parameter.name];
            return input;
        }));
        submitInputs.push.apply(submitInputs, fieldElements.map(function (element) {
            var input = new Input();
            input.path = [element.settings.name];
            input.valueType = InputValueType.Context;
            input.contextValue = ['elements', element.uid, VALUE_OUTPUT];
            return input;
        }));
        this.controls.generated.patchValue(true);
        this.controls.submit_action.controls.inputs.patchValue(submitInputs);
        this.controls.children.patchValue(children);
    };
    CustomizeBarFormEditForm.prototype.controlsValid$ = function (form, controls) {
        return combineLatest(controls.map(function (item) { return controlValid(form.controls[item]); })).pipe(map(function (result) { return result.every(function (item) { return item; }); }), debounceTime(60));
    };
    CustomizeBarFormEditForm.prototype.submitActionOperationValid$ = function () {
        return this.controlsValid$(this.controls.submit_action, keys(this.controls.submit_action.controls).filter(function (key) { return key != 'inputs'; }));
    };
    CustomizeBarFormEditForm.prototype.submitActionValid$ = function () {
        return this.controlsValid$(this.controls.submit_action, keys(this.controls.submit_action.controls));
    };
    CustomizeBarFormEditForm.prototype.resetGetQuery = function () {
        this.controls.get_enabled.patchValue(false);
        this.controls.get_data_source.reset();
        this.markAsDirty();
    };
    CustomizeBarFormEditForm.prototype.isConfigured = function (instance) {
        return this.elementConfigurationService.isFormConfigured(instance, { restrictDemo: true });
    };
    CustomizeBarFormEditForm.prototype.submit = function () {
        var value = this.value;
        var instance = cloneDeep(this.element);
        instance.generated = value['generated'];
        instance.name = value['name'];
        instance.getDataSource = this.controls.get_enabled.value ? this.controls.get_data_source.serialize() : undefined;
        instance.columns = value['columns'];
        instance.style = value['style'];
        var action = this.controls.submit_action.submit().action;
        if (action) {
            instance.submitAction = action;
        }
        else {
            instance.submitAction = undefined;
        }
        if (value['visible_input']) {
            instance.visibleInput = new Input().deserialize(value['visible_input']);
        }
        else {
            instance.visibleInput = undefined;
        }
        instance.loadInvisible = value['load_invisible'];
        instance.margin = value['margin'];
        if (value['children']) {
            instance.children = value['children'];
            this.controls.children.patchValue(null, { emitEvent: false });
        }
        return instance;
    };
    return CustomizeBarFormEditForm;
}(FormGroup));
export { CustomizeBarFormEditForm };
