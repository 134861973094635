var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { isSet } from '@shared';
import { ApiParameterType } from '../../../data/api-parameter-type';
import { fieldToApiParameter } from '../../../utils/common';
import { ModelApiBaseQueryComponent } from '../model-api-base-query/model-api-base-query.component';
var DataTabName;
(function (DataTabName) {
    DataTabName["Request"] = "request";
    DataTabName["Response"] = "response";
})(DataTabName || (DataTabName = {}));
var ModelApiUpdateQueryComponent = /** @class */ (function (_super) {
    __extends(ModelApiUpdateQueryComponent, _super);
    function ModelApiUpdateQueryComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.parameters = [];
        _this.dataTabs = [
            {
                name: DataTabName.Request,
                label: 'Request'
            },
            {
                name: DataTabName.Response,
                label: 'Response'
            }
        ];
        _this.currentDataTabName = DataTabName.Request;
        _this.dataTabNames = DataTabName;
        return _this;
    }
    ModelApiUpdateQueryComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        this.fetchModel();
        this.updateParameters();
        this.updateCurl();
    };
    ModelApiUpdateQueryComponent.prototype.updateParameters = function () {
        this.parameters = this.getParameters();
        this.cd.markForCheck();
    };
    ModelApiUpdateQueryComponent.prototype.updateCurl = function () {
        this.curl = this.getCurlRequest({
            url: this.getAbsoluteApiUrl(),
            method: 'PATCH',
            data: this.getRequestData()
        });
    };
    ModelApiUpdateQueryComponent.prototype.onModelFetched = function () {
        this.updateCurl();
    };
    ModelApiUpdateQueryComponent.prototype.getParametersAuto = function () {
        var _this = this;
        var controller = this.resourceControllerService.get(this.resource.type);
        var modelParameters = controller.getUpdateParametersOrDefaults(this.resource, this.modelDescription);
        return modelParameters.map(function (item) {
            var type = isSet(_this.modelDescription.primaryKeyField) && item.name == _this.getPrimaryKey()
                ? ApiParameterType.Path
                : ApiParameterType.Body;
            return fieldToApiParameter(item, type);
        });
    };
    ModelApiUpdateQueryComponent.prototype.getRequestDataStr = function () {
        var data = this.getRequestData();
        return JSON.stringify(data, null, this.indentSize);
    };
    ModelApiUpdateQueryComponent.prototype.getResponseData = function () {
        var model = this.model || this.getDefaultModel();
        var modelData = this.serializer ? this.serializer(model) : this.serializeDefault(model);
        return JSON.stringify(modelData, null, this.indentSize);
    };
    ModelApiUpdateQueryComponent.prototype.getQueryUrl = function () {
        return "/:" + this.getPrimaryKey();
    };
    ModelApiUpdateQueryComponent.prototype.setCurrentQueryName = function (value) {
        this.currentDataTabName = value;
        this.cd.markForCheck();
    };
    return ModelApiUpdateQueryComponent;
}(ModelApiBaseQueryComponent));
export { ModelApiUpdateQueryComponent };
