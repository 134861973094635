import { OnDestroy } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import Quill from 'quill';
import { merge, Observable, of } from 'rxjs';
import { delay, first } from 'rxjs/operators';

import { isSet } from '@shared';

const Inline = Quill.import('blots/inline');

export const COLOR_SET_DEFAULT_VALUE_ATTR = 'data-color-default';
export const COLOR_SET_DARK_VALUE_ATTR = 'data-color-dark';

export interface ColorSetValue {
  colorDefault?: string;
  colorDark?: string;
}

class ColorSetBlot extends Inline implements OnDestroy {
  static create(value: ColorSetValue) {
    if (!value) {
      return super.create(false);
    }

    const node = super.create(value);

    if (isSet(value.colorDefault)) {
      node.setAttribute(COLOR_SET_DEFAULT_VALUE_ATTR, value.colorDefault);
    }

    if (isSet(value.colorDark)) {
      node.setAttribute(COLOR_SET_DARK_VALUE_ATTR, value.colorDark);
    }

    return node;
  }

  // Overriding this method, in this particular case,
  // is what causes the Delta returned as content by
  // Quill to have the desired information.
  static formats(domNode): ColorSetValue {
    const colorDefault = domNode.getAttribute(COLOR_SET_DEFAULT_VALUE_ATTR);
    const colorDark = domNode.getAttribute(COLOR_SET_DARK_VALUE_ATTR);

    if (isSet(colorDefault) || isSet(colorDark)) {
      return {
        colorDefault: colorDefault,
        colorDark: colorDark
      };
    } else {
      return super.formats(domNode);
    }
  }

  ngOnDestroy(): void {}

  getQuill() {
    let node = this.domNode;

    while (node) {
      const quill = Quill.find(node);
      if (quill instanceof Quill) {
        return quill;
      }

      node = node.parentElement;
    }
  }

  getOptions() {
    const quill = this.getQuill();
    if (quill) {
      const toolbar = quill.getModule('toolbar');
      if (toolbar) {
        const options = toolbar.options.formatsOptions || {};
        return options['color-set'] || {};
      }
    }

    return {};
  }

  attach(): void {
    super.attach();

    const options = this.getOptions();
    let theme$: Observable<string> = options['theme$'] || of(undefined);

    // Workaround for value update
    theme$ = merge(theme$.pipe(first()), theme$.pipe(delay(0)));

    theme$.pipe(untilDestroyed(this)).subscribe(theme => {
      const node = this.domNode as HTMLElement;
      const colorDefault = node.getAttribute(COLOR_SET_DEFAULT_VALUE_ATTR);
      const colorDark = node.getAttribute(COLOR_SET_DARK_VALUE_ATTR);
      const colorEffective = theme == 'dark' ? colorDark : colorDefault;

      if (isSet(colorEffective)) {
        node.setAttribute('style', `--color-value: ${colorEffective}`);
      } else {
        node.removeAttribute('style');
      }
    });
  }

  detach(): void {
    super.detach();
    this.ngOnDestroy();
  }

  formats() {
    // Returns the formats list this class (this format).
    const formats = super.formats();

    // Inline has the domNode reference, which in this
    // case represents the SPAN, result defined in tagName.
    formats['color-set'] = ColorSetBlot.formats(this.domNode);

    // In the code above, it is as if we are adding this new format.
    return formats;
  }
}

ColorSetBlot.tagName = 'SPAN';
ColorSetBlot.blotName = 'color-set';
Quill.register('formats/color-set', ColorSetBlot);
