import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  InjectionToken,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  QueryList,
  SimpleChanges,
  ViewChildren
} from '@angular/core';
import { ActivatedRoute, ActivationEnd, Router } from '@angular/router';
import clone from 'lodash/clone';
import cloneDeep from 'lodash/cloneDeep';
import fromPairs from 'lodash/fromPairs';
import isEqual from 'lodash/isEqual';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, merge, Observable, of, ReplaySubject } from 'rxjs';
import { catchError, delayWhen, filter, map, switchMap, tap } from 'rxjs/operators';

import { DialogService } from '@common/dialogs';
import { AppDropListGroup } from '@common/drag-drop2';
import { NotificationService } from '@common/notifications';
import { DocumentService } from '@core';
import { ActionDescriptionService, ActionStore } from '@modules/action-queries';
import { ActionDescription, ActionItem, ActionType, LinkAction, SegueType, ViewSettingsAction } from '@modules/actions';
import { UserActivityService, UserActivityType } from '@modules/activities';
import { AnalyticsEvent, AnalyticsEventAction, UniversalAnalyticsService } from '@modules/analytics';
import { ServerRequestError } from '@modules/api';
import { ChangeContext, ChangeForm, ChangeStateService } from '@modules/change';
import {
  BackElementItem,
  CardLayoutElementItem,
  ChangeViewSettings,
  CustomizeHandler,
  CustomizeService,
  ElementType,
  FieldElementItem,
  filterElementItems,
  validateElementNames,
  ViewContext,
  ViewContextElement,
  ViewContextElementType,
  ViewContextOutput,
  ViewSettingsService,
  ViewSettingsStore,
  ViewSettingsType
} from '@modules/customize';
import {
  CustomizeBarContext,
  CustomizeBarEditEventType,
  CustomizeBarService,
  ProjectPropertyEditController
} from '@modules/customize-bar';
import { ElementConfigurationService } from '@modules/customize-configuration';
import { AutoElementComponent } from '@modules/customize-elements';
import { FieldType, Input as FieldInput, InputValueType } from '@modules/fields';
import { MenuSettingsStore } from '@modules/menu';
import { MetaService } from '@modules/meta';
import { ModelDescriptionStore, ModelService, ModelUtilsService } from '@modules/model-queries';
import { Model, ModelDescription } from '@modules/models';
import { ViewContextTokenProvider } from '@modules/parameters-components';
import {
  CurrentEnvironmentStore,
  CurrentProjectStore,
  ProjectGroup,
  ProjectGroupService,
  ProjectProperty,
  ProjectPropertyStore,
  ProjectPropertyType,
  ProjectUser,
  ProjectUserService
} from '@modules/projects';
import { RoutingService } from '@modules/routing';
import { CurrentUserStore } from '@modules/users';
import { capitalize, getLocationAppPath, getLocationQueryParams, scrollTo } from '@shared';

export const teamPropertiesToken = new InjectionToken<ViewContextElement>('teamPropertiesToken');
export const userPropertiesToken = new InjectionToken<ViewContextElement>('userPropertiesToken');
export const appPropertiesToken = new InjectionToken<ViewContextElement>('appPropertiesToken');
export const devicePropertiesToken = new InjectionToken<ViewContextElement>('appDeviceToken');

export interface ChangePageState {
  viewSettings: ChangeViewSettings;
  actions: ActionDescription[];
}

@Component({
  selector: 'app-change',
  templateUrl: 'change.component.html',
  providers: [
    ChangeForm,
    ChangeStateService,
    ChangeContext,
    CustomizeBarContext,
    ViewContext,
    ViewContextTokenProvider,
    { provide: teamPropertiesToken, useClass: ViewContextElement },
    { provide: userPropertiesToken, useClass: ViewContextElement },
    { provide: appPropertiesToken, useClass: ViewContextElement },
    { provide: devicePropertiesToken, useClass: ViewContextElement }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangeComponent implements OnInit, OnDestroy, OnChanges, CustomizeHandler<ChangePageState> {
  @Input() uniqueName: string;
  @Input() modelId: string;
  @Input() id: string;
  @Input() params: Object;

  @ViewChildren(AutoElementComponent) children = new QueryList<AutoElementComponent>();
  @ViewChildren(AppDropListGroup) dropListGroups = new QueryList<AppDropListGroup>();

  loading = true;
  submitLoading = false;
  error: ServerRequestError;
  modelDescription: ModelDescription;
  model: Model;
  duplicateModel: Model;
  viewSettings: ChangeViewSettings;
  initialViewSettings: ChangeViewSettings;
  changeViewSettings: ChangeViewSettings;
  items = [];
  saveLoading = false;
  viewContextInitialized = new ReplaySubject<void>(1);

  constructor(
    private modelDescriptionStore: ModelDescriptionStore,
    private modelService: ModelService,
    protected context: ViewContext,
    public currentProjectStore: CurrentProjectStore,
    public currentEnvironmentStore: CurrentEnvironmentStore,
    private cd: ChangeDetectorRef,
    private router: Router,
    private routing: RoutingService,
    private activatedRoute: ActivatedRoute,
    private viewSettingsService: ViewSettingsService,
    private viewSettingsStore: ViewSettingsStore,
    public customizeService: CustomizeService,
    private dialogService: DialogService,
    private notificationService: NotificationService,
    private userActivityService: UserActivityService,
    private analyticsService: UniversalAnalyticsService,
    private changeStateService: ChangeStateService,
    private metaService: MetaService,
    private actionStore: ActionStore,
    private modelUtilsService: ModelUtilsService,
    public form: ChangeForm,
    private actionDescriptionService: ActionDescriptionService,
    private customizeBarService: CustomizeBarService,
    private customizeBarContext: CustomizeBarContext,
    private menuSettingsStore: MenuSettingsStore,
    @Inject(teamPropertiesToken) private viewContextElementTeamProperty: ViewContextElement,
    @Inject(userPropertiesToken) private viewContextElementUserProperty: ViewContextElement,
    @Inject(appPropertiesToken) private viewContextElementAppProperty: ViewContextElement,
    @Inject(devicePropertiesToken) private viewContextElementDeviceProperty: ViewContextElement,
    private projectUserService: ProjectUserService,
    private elementConfigurationService: ElementConfigurationService,
    private documentService: DocumentService,
    private currentUserStore: CurrentUserStore,
    private projectGroupService: ProjectGroupService,
    private projectPropertyStore: ProjectPropertyStore,
    private projectPropertyEditController: ProjectPropertyEditController
  ) {}

  ngOnInit(): void {
    this.customizeService.setHandler(this);
    this.updateCustomizeHandleInfo();

    this.initViewContextElements();

    combineLatest(
      this.projectUserService
        .get(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName)
        .pipe(catchError(() => of<ProjectUser[]>([]))),
      this.projectGroupService
        .get(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName)
        .pipe(catchError(() => of<ProjectGroup[]>([]))),
      this.currentUserStore.instance$,
      this.currentProjectStore.instance$,
      this.currentEnvironmentStore.instance$,
      this.projectPropertyStore.get()
    )
      .pipe(untilDestroyed(this))
      .subscribe(
        ([usersProject, groupsProject, currentUser, currentProject, currentEnvironment, projectProperties]) => {
          const userProperties = projectProperties
            ? projectProperties.filter(item => item.type == ProjectPropertyType.User)
            : [];
          const groupProperties = projectProperties
            ? projectProperties.filter(item => item.type == ProjectPropertyType.Group)
            : [];

          this.updateContextUserProperties(userProperties);
          this.updateContextTeamProperties(groupProperties);

          const currentProjectUser = usersProject.find(item => item.user && item.user.uid === currentUser.uid);
          if (currentProjectUser) {
            const value = fromPairs(
              userProperties.map(property => [property.uid, currentProjectUser.getPropertyValue(property)])
            );
            this.viewContextElementUserProperty.setOutputValues(value);
          }

          const currentProjectGroup = currentEnvironment.group
            ? groupsProject.find(item => item.uid == currentEnvironment.group.uid)
            : undefined;
          if (currentProjectGroup) {
            const value = fromPairs(
              groupProperties.map(property => [property.uid, currentProjectGroup.getPropertyValue(property)])
            );
            this.viewContextElementTeamProperty.setOutputValues(value);
          }

          this.viewContextElementAppProperty.patchOutputValues({
            name: currentProject.uniqueName,
            env_name: currentEnvironment.uniqueName
          });

          this.viewContextInitialized.next();
        }
      );

    merge(of({}), this.router.events.pipe(filter(item => item instanceof ActivationEnd)))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.viewContextElementAppProperty.patchOutputValues({
          app_path: getLocationAppPath(),
          query_params: getLocationQueryParams()
        });
      });

    this.documentService.viewportSize$.pipe(untilDestroyed(this)).subscribe(value => {
      const type = this.documentService.getViewportType(value.width);

      this.viewContextElementDeviceProperty.patchOutputValues({
        is_desktop: this.documentService.isDesktopViewportType(type),
        is_mobile: this.documentService.isMobileViewportType(type),
        is_phone: this.documentService.isPhoneViewportType(type),
        is_tablet: this.documentService.isTabletViewportType(type),
        screen_width: value.width,
        screen_height: value.height
      });
    });

    merge(of(this.dropListGroups.toArray()), this.dropListGroups.changes.pipe(map(() => this.dropListGroups.toArray())))
      .pipe(untilDestroyed(this))
      .subscribe(groups => {
        this.context.dropListGroups = groups;
      });
  }

  ngOnDestroy(): void {
    if (this.form.form) {
      this.changeStateService.unregisterForm(this.form.form);
    }

    this.customizeService.layoutCustomization = undefined;
    this.customizeService.unsetHandler(this);
  }

  onBeforeDestroy() {
    this.context.clear();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['modelId'] || changes['id'] || changes['uniqueName'] || changes['queryParams']) {
      if (this.modelId || this.uniqueName) {
        this.getData();
      }
    }
  }

  getData() {
    let obs: Observable<any>[] = [];
    const modelChanged = !((this.modelDescription ? this.modelDescription.modelId : undefined) == this.modelId);
    const idChanged = !((this.model ? this.model.primaryKey : undefined) == this.id);

    if (this.viewSettings && !modelChanged) {
      obs.push(of(this.modelDescription));
      obs.push(this.actionStore.getFirst());
    } else {
      obs.push(this.modelDescriptionStore.getDetailFirst(this.modelId));
      obs.push(this.actionStore.getFirst());
    }

    this.loading = true;
    this.error = undefined;
    this.viewSettings = undefined;
    this.context.clear(ViewContextElementType.Element);
    this.cd.detectChanges();

    const result = combineLatest(...obs).pipe(
      switchMap(([modelDescription, actions]) => {
        obs = [];

        if (this.uniqueName) {
          obs.push(this.viewSettingsStore.getDetailFirst(this.uniqueName, ViewSettingsType.Change));
        } else {
          obs.push(this.viewSettingsStore.getChangeViewSettingsFirst(this.modelId));
        }

        if (this.id && (modelChanged || idChanged)) {
          obs.push(
            this.modelService.getDetail(
              this.currentProjectStore.instance,
              this.currentEnvironmentStore.instance,
              this.modelId,
              modelDescription.primaryKeyField,
              this.id,
              this.params
            )
          );
          scrollTo(document.getElementsByClassName('admin__content')[0], 0); // TODO: refactor scrollTo
        } else if (this.id && !modelChanged && !idChanged) {
          obs.push(of(this.model));
        } else if (!this.id) {
          obs.push(of(undefined));
          scrollTo(document.getElementsByClassName('admin__content')[0], 0);
        } else {
          obs.push(of(undefined));
        }

        if (this.params['_duplicate']) {
          obs.push(
            this.modelService.getDetail(
              this.currentProjectStore.instance,
              this.currentEnvironmentStore.instance,
              this.modelId,
              modelDescription.primaryKeyField,
              this.params['_duplicate']
            )
          );
        } else {
          obs.push(of(undefined));
        }

        return combineLatest(...obs).pipe(
          map(([viewSettings, model, duplicateModel]) => [
            modelDescription,
            viewSettings,
            actions,
            model,
            duplicateModel
          ])
        );
      }),
      delayWhen(() => this.viewContextInitialized),
      untilDestroyed(this),
      tap(([modelDescription, viewSettings, actions, model, duplicateModel]) => {
        this.modelDescription = modelDescription;
        this.viewSettings = viewSettings;

        if (this.uniqueName) {
          this.context.objectType = 'custom_page';
          this.context.objectId = this.uniqueName;
        }

        if (!this.modelDescription) {
          return;
        }

        this.setInitialSettings();
        this.updateCustomizeHandleInfo();

        this.initialViewSettings = cloneDeep(this.viewSettings);
        this.changeViewSettings = cloneDeep(this.viewSettings);

        if (this.viewSettings && !this.uniqueName) {
          this.context.objectType = ['model', this.viewSettings.model].join('.');
          this.context.objectId = this.id;
        }

        // if (this.viewSettings) {
        //   this.viewSettings.actions = this.viewSettings.actions
        //     .map(item => {
        //       const action = actions.find(i => i.isSame(item.actionDescription));
        //
        //       if (!action) {
        //         return;
        //       }
        //
        //       item.verboseName = action.verboseName;
        //       item.icon = action.icon;
        //       return item;
        //     })
        //     .filter(item => item != undefined)
        //     .sort((lhs, rhs) => lhs.order - rhs.order);
        // }

        let initial: Object;

        if (duplicateModel) {
          initial = duplicateModel.serialize(modelDescription.dbFields.map(item => item.name));
          initial[modelDescription.primaryKeyField] = undefined;
        }

        if (this.activatedRoute.snapshot.queryParams['_initials']) {
          initial = JSON.parse(this.activatedRoute.snapshot.queryParams['_initials']);
        }

        if (this.form.form) {
          this.changeStateService.unregisterForm(this.form.form);
        }

        this.model = model;
        this.duplicateModel = duplicateModel;

        ((this.model ? this.modelUtilsService.str(this.model) : of('Create')) as Observable<string>)
          .pipe(untilDestroyed(this))
          .subscribe(str => {
            this.metaService.set({ title: [str, this.modelDescription.verboseNamePlural] });
          });

        this.form.initialize(this.modelDescription, this.model, initial);
        // this.context.init(this.form.form, this.modelDescription, this.model, []);
        this.context.modelDescription = this.modelDescription;
        this.context.model = this.model;
        this.context.form = this.form.form;
        this.customizeService.layoutCustomization = {
          subtitle: '',
          title: 'Edit Record \n' + capitalize(this.modelDescription.verboseName),
          image: 'change',
          clickEvent: [
            AnalyticsEvent.Deprecated.CustomizeInterfaceChooseRecordView,
            {
              CollectionID: this.modelDescription.model,
              RecordID: this.model ? this.model.primaryKey : undefined
            }
          ],
          description: [
            { icon: 'blocks', label: 'Build your Record View using layouts and components' },
            {
              icon: 'fileds',
              label: 'Edit and organize your data in the Record View: add Fields and Related Collections'
            },
            { icon: 'diagram', label: 'Create charts and track KPIs on a specific Record' },
            { icon: 'power', label: 'Add custom action buttons' }
          ],
          modelDescription: this.modelDescription
        };
        this.cd.markForCheck();

        this.changeStateService.registerForm(this.form.form);

        if (this.model) {
          this.userActivityService
            .currentProjectCreateModelInstance(
              this.currentProjectStore.instance,
              this.currentEnvironmentStore.instance,
              UserActivityType.ModelDetail,
              this.modelDescription.modelId,
              this.model.primaryKey,
              undefined,
              {
                resource: this.modelDescription.resource,
                model: this.modelDescription.model,
                id: this.model.primaryKey
              }
            )
            .subscribe(() => {});
        }
      })
    );

    result
      .pipe(
        untilDestroyed(this),
        catchError(error => {
          this.loading = false;
          this.error = error;
          this.cd.markForCheck();
          console.log(error);
          return of(undefined);
        })
      )
      .subscribe(
        () => {
          this.loading = false;
          this.cd.markForCheck();
        },
        () => {
          this.loading = false;
          this.cd.markForCheck();
        }
      );

    return result;
  }

  setInitialSettings() {
    if (!this.viewSettings) {
      this.viewSettings = new ChangeViewSettings();
      this.viewSettings.project = this.currentProjectStore.instance.uniqueName;

      if (this.uniqueName) {
        this.viewSettings.uniqueName = this.uniqueName;
      } else if (this.modelDescription) {
        this.viewSettings.resource = this.modelDescription.resource;
        this.viewSettings.model = this.modelDescription.model;
      }
    }

    if (!this.viewSettings.elements || !this.viewSettings.elements.length) {
      if (this.modelDescription) {
        const displayField = this.modelDescription.displayField || this.modelDescription.primaryKeyField;
        const backElement = new BackElementItem();

        backElement.type = ElementType.Back;

        backElement.titleInput = new FieldInput();
        backElement.titleInput.path = ['value'];
        backElement.titleInput.valueType = InputValueType.Formula;
        backElement.titleInput.formulaValue = `IF(record, GET(record, "${displayField}"), "Create")`;

        backElement.previousPageAction = new ActionItem();
        backElement.previousPageAction.actionDescription = new ActionDescription();
        backElement.previousPageAction.actionDescription.type = ActionType.Link;
        backElement.previousPageAction.actionDescription.linkAction = new LinkAction();
        backElement.previousPageAction.actionDescription.linkAction.type = SegueType.PreviousPage;

        this.viewSettings.elements = [
          backElement,
          new CardLayoutElementItem().deserialize({
            type: ElementType.Card,
            params: {
              children: this.modelDescription.displayFields.map(field => {
                return new FieldElementItem().deserialize({
                  type: ElementType.Field,
                  params: {
                    field: field.formField.serialize()
                  }
                });
              })
            }
          })
        ];
        validateElementNames(this.viewSettings.elements);
      }
    }

    this.customizeService.startTrackChanges();
  }

  hasChanges() {
    return [
      this.form.hasChanges()
      // ...this.context.relatedModelsList.map(item => item.hasChanges()),
      // ...this.context.relations.map(item => item.hasChanges())
    ].some(item => item == true);
  }

  submit() {
    if (this.form.form.invalid || this.form.form.disabled) {
      return;
    }

    const forms = ([
      this.form
      // ...this.context.relatedModelsList.map(item => item.form)
    ] as ChangeForm[])
      .map(form => {
        return {
          modelDescription: form.modelDescription,
          model: form.model,
          changes: form.getChanges(),
          form: form
        };
      })
      .filter(form => form.changes.length > 0);

    const fields = filterElementItems<FieldElementItem>(
      this.changeViewSettings.elements,
      item => item instanceof FieldElementItem
    )
      .map(item => item.settings.name)
      .filter(item => item != undefined);

    const obs = [
      ...forms.map(item => item.form.submit(fields))
      // ...this.context.relations.map(item => item.submit())
    ].filter(item => item != undefined);

    if (!obs.length) {
      this.notificationService.info('Nothing changed', `You haven't made any changes`);

      return;
    }

    this.submitLoading = true;
    this.cd.markForCheck();

    combineLatest(obs)
      .pipe(untilDestroyed(this))
      .subscribe(
        results => {
          this.submitLoading = false;
          this.cd.markForCheck();

          if (!this.model) {
            const model = results[0];

            this.analyticsService.sendEvent(AnalyticsEvent.GA.Model, AnalyticsEventAction.Created);

            this.routing.navigateApp(model.getLink(), { queryParams: this.listParams });

            this.notificationService.success(
              'Created',
              `<strong>${this.modelDescription.verboseName}</strong> was successfully created`
            );
          } else {
            this.analyticsService.sendEvent(AnalyticsEvent.GA.Model, AnalyticsEventAction.Updated);

            this.form.form.markAsPristine();
            this.cd.markForCheck();

            this.notificationService.success(
              'Saved',
              `<strong>${this.modelDescription.verboseName}</strong> was successfully updated`
            );
          }
        },
        error => {
          this.submitLoading = false;
          this.cd.markForCheck();

          if (error instanceof ServerRequestError && error.nonFieldErrors.length) {
            this.notificationService.error(
              'Error',
              `Saving <strong>${this.modelDescription.verboseName}</strong> failed: ${error.nonFieldErrors[0]}`
            );
          } else {
            this.notificationService.error(
              'Error',
              `Saving <strong>${this.modelDescription.verboseName}</strong> failed`
            );
          }
        }
      );
  }

  deleteModel() {
    this.dialogService
      .warning({
        title: 'Deleting',
        description: `Are you sure want to delete this <strong>${this.modelDescription.verboseName}</strong>?`
      })
      .pipe(
        filter(result => result == true),
        switchMap(() =>
          this.modelService.delete(
            this.currentProjectStore.instance,
            this.currentEnvironmentStore.instance,
            this.modelDescription.modelId,
            this.model
          )
        ),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.analyticsService.sendEvent(AnalyticsEvent.GA.Model, AnalyticsEventAction.Deleted);

        this.routing.navigateApp(this.modelDescription.link, { queryParams: this.listParams });
      });
  }

  get listParams() {
    const params = clone(this.activatedRoute.snapshot.queryParams);

    if (params['_duplicate'] != undefined) {
      delete params['_duplicate'];
    }

    return params;
  }

  get changed() {
    const pristine = this.form.form.pristine;
    // && this.context.relations.every(relation => relation.items.every(item => item.form.form.pristine));

    return !pristine;
  }

  updateCustomizeHandleInfo() {
    this.customizeService.setHandlerInfo(this, {
      breadcrumbs: ['Customizing Record'],
      title: this.modelDescription ? this.modelDescription.verboseName : undefined,
      page: this.viewSettings
    });
  }

  getChangesState(): ChangePageState {
    const instance = this.viewSettings;
    const actions = this.viewSettings.actions
      .filter(item => item.sharedActionDescription)
      .filter(item => {
        const initial = this.initialViewSettings.actions.find(i => i.actionDescription == item.actionDescription);
        const mapAction = (action: ViewSettingsAction) => {
          return {
            verboseName: action.verboseNameInput ? action.verboseNameInput.staticValue : undefined,
            icon: action.icon
          };
        };

        return !initial || !isEqual(mapAction(item), mapAction(initial));
      })
      .map(item => {
        const action = this.actionStore.instance.find(i => i.isSame(item.actionDescription));
        action.verboseName = item.verboseNameInput ? item.verboseNameInput.staticValue : undefined;
        action.icon = item.icon;
        return action;
      });

    return {
      viewSettings: cloneDeep(instance),
      actions: cloneDeep(actions)
    };
  }

  setChangesState(state: ChangePageState) {
    this.viewSettings = cloneDeep(state.viewSettings);
    this.cd.markForCheck();
  }

  isChangesStateEqual(lhs: ChangePageState, rhs: ChangePageState): boolean {
    const compare = ['params'];
    return (
      isEqual(lhs.viewSettings.serialize(compare), rhs.viewSettings.serialize(compare)) &&
      isEqual(
        lhs.actions.map(item => item.serialize()),
        rhs.actions.map(item => item.serialize())
      )
    );
  }

  saveChangesState(state: ChangePageState): Observable<ChangePageState> {
    return combineLatest(
      this.viewSettingsService.create(
        this.currentProjectStore.instance.uniqueName,
        this.currentEnvironmentStore.instance.uniqueName,
        state.viewSettings
      ),
      ...state.actions.map(action =>
        this.actionDescriptionService.create(
          this.currentProjectStore.instance.uniqueName,
          this.currentEnvironmentStore.instance.uniqueName,
          action
        )
      )
    ).pipe(
      switchMap(() => this.viewSettingsStore.getFirst(true)),
      tap(() => {
        if (state.viewSettings) {
          this.initialViewSettings = cloneDeep(state.viewSettings);
        }

        this.analyticsService.sendEvent(AnalyticsEvent.GA.ModelDetailLayout, AnalyticsEventAction.Updated);
      }),
      switchMap(() => this.actionStore.getFirst(true)),
      map(() => {
        return state;
      })
    );
  }

  reload(): Observable<any> {
    this.viewSettings = undefined;

    return this.getData();
  }

  onViewSettingsUpdated(viewSettings: ChangeViewSettings) {
    this.viewSettings = viewSettings;
    this.customizeService.markChanged();
    this.cd.markForCheck();
  }

  customizeViewSettings() {
    this.customizeBarService
      .customizePage({ context: this.customizeBarContext, viewSettings: this.viewSettings, viewContext: this.context })
      .pipe(untilDestroyed(this))
      .subscribe(e => {
        if (e.type == CustomizeBarEditEventType.Created || e.type == CustomizeBarEditEventType.Updated) {
          const instance = e.args['result'] as ChangeViewSettings;

          this.onViewSettingsUpdated(instance);
          this.cd.markForCheck();

          this.analyticsService.sendSimpleEvent(AnalyticsEvent.Page.SuccessfullySetUp, {
            PageID: this.viewSettings.uniqueName
          });
        } else if (e.type == CustomizeBarEditEventType.Deleted) {
          this.viewSettingsService
            .delete(
              this.currentProjectStore.instance.uniqueName,
              this.currentEnvironmentStore.instance.uniqueName,
              this.viewSettings
            )
            .pipe(
              delayWhen(() => this.viewSettingsStore.getFirst(true)),
              delayWhen(() => this.menuSettingsStore.getFirst(true)),
              untilDestroyed(this)
            )
            .subscribe(() => {
              this.routing.navigateApp(this.currentProjectStore.instance.homeLink);

              this.analyticsService.sendSimpleEvent(AnalyticsEvent.Page.Deleted, {
                PageID: this.viewSettings.uniqueName
              });
            });
        }
      });
  }

  openHandlerSettings() {
    this.customizeViewSettings();
  }

  initViewContextElements() {
    this.viewContextElementTeamProperty.unregister();
    this.viewContextElementTeamProperty.initGlobal({
      uniqueName: 'team_properties',
      name: 'Team Properties',
      action: {
        label: 'Add Property',
        icon: 'plus',
        handler: () => {
          return this.projectPropertyEditController
            .create({
              type: ProjectPropertyType.Group,
              context: this.context,
              analyticsSource: 'component_input'
            })
            .pipe(
              map(item => {
                return {
                  insertToken: ['team_properties', item.property.uid]
                };
              })
            );
        }
      },
      documentation: 'conditional-visibility/properties'
    });

    this.viewContextElementUserProperty.unregister();
    this.viewContextElementUserProperty.initGlobal({
      uniqueName: 'user_properties',
      name: 'User Properties',
      action: {
        label: 'Add Property',
        icon: 'plus',
        handler: () => {
          return this.projectPropertyEditController
            .create({
              type: ProjectPropertyType.User,
              context: this.context,
              analyticsSource: 'component_input'
            })
            .pipe(
              map(item => {
                return {
                  insertToken: ['user_properties', item.property.uid]
                };
              })
            );
        }
      },
      documentation: 'conditional-visibility/properties'
    });

    this.viewContextElementAppProperty.unregister();
    this.viewContextElementAppProperty.initGlobal({ uniqueName: 'app', name: 'Application' });
    this.viewContextElementAppProperty.setOutputs([
      {
        uniqueName: 'name',
        name: 'App name',
        icon: 'home'
      },
      {
        uniqueName: 'env_name',
        name: 'Environment name',
        icon: 'tag'
      },
      {
        uniqueName: 'app_path',
        name: 'App path',
        icon: 'console'
      },
      {
        uniqueName: 'query_params',
        name: 'URL query parameters',
        icon: 'components',
        fieldType: FieldType.JSON
      }
    ]);

    this.viewContextElementDeviceProperty.unregister();
    this.viewContextElementDeviceProperty.initGlobal({ uniqueName: 'device', name: 'Device' });
    this.viewContextElementDeviceProperty.setOutputs([
      {
        uniqueName: 'is_desktop',
        name: 'Is Desktop',
        icon: 'pages'
      },
      {
        uniqueName: 'is_mobile',
        name: 'Is Mobile',
        icon: 'pages'
      },
      {
        uniqueName: 'is_phone',
        name: 'Is Phone',
        icon: 'pages'
      },
      {
        uniqueName: 'is_tablet',
        name: 'Is Tablet',
        icon: 'pages'
      },
      {
        uniqueName: 'screen_width',
        name: 'Screen width',
        icon: 'align_horizontal_fill'
      },
      {
        uniqueName: 'screen_height',
        name: 'Screen height',
        icon: 'align_vertical_fill'
      }
    ]);
  }

  updateContextUserProperties(properties: ProjectProperty[]) {
    const outputs: ViewContextOutput[] = properties.map(item => {
      return {
        uniqueName: item.uid,
        name: item.name,
        icon: item.fieldDescription.icon,
        ...(item.field
          ? {
              fieldType: item.field.field,
              fieldParams: item.field.params
            }
          : {})
      };
    });
    this.viewContextElementUserProperty.setOutputs(outputs);
  }

  updateContextTeamProperties(properties: ProjectProperty[]) {
    const outputs: ViewContextOutput[] = properties.map(item => {
      return {
        uniqueName: item.uid,
        name: item.name,
        icon: item.fieldDescription.icon,
        ...(item.field
          ? {
              fieldType: item.field.field,
              fieldParams: item.field.params
            }
          : {})
      };
    });

    this.viewContextElementTeamProperty.setOutputs(outputs);
  }
}
