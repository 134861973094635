var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormControl, FormGroup } from '@angular/forms';
import { combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { applyParamInput$, Input } from '@modules/fields';
import { FieldInputControl } from '@modules/parameters';
import { GradientStop } from '@modules/views';
import { controlValue, isSet } from '@shared';
import { ColorControl } from './color.control';
var GradientStopControl = /** @class */ (function (_super) {
    __extends(GradientStopControl, _super);
    function GradientStopControl(state) {
        if (state === void 0) { state = {}; }
        return _super.call(this, {
            position: new FormControl(isSet(state.position) ? state.position : 0),
            color: new ColorControl(isSet(state.color) ? state.color : {}),
            color_input_enabled: new FormControl(isSet(state.colorInput) ? !!state.colorInput : false),
            color_input: new FieldInputControl({ path: ['value'] })
        }) || this;
    }
    GradientStopControl.prototype.deserialize = function (value, options) {
        if (options === void 0) { options = {}; }
        this.instance = value;
        this.controls.position.patchValue(value.position, { emitEvent: options.emitEvent });
        if (value.color) {
            this.controls.color.deserialize(value.color, { emitEvent: options.emitEvent });
        }
        this.controls.color_input_enabled.patchValue(!!value.colorInput, { emitEvent: options.emitEvent });
        this.controls.color_input.patchValue(value.colorInput ? value.colorInput.serializeWithoutPath() : {}, {
            emitEvent: options.emitEvent
        });
    };
    GradientStopControl.prototype.getColor$ = function (options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        return combineLatest(controlValue(this.controls.color).pipe(map(function () { return _this.controls.color.serialize(); })), controlValue(this.controls.color_input_enabled), controlValue(this.controls.color_input).pipe(map(function () { return _this.controls.color_input.serialize(); }))).pipe(switchMap(function (_a) {
            var color = _a[0], colorInputEnabled = _a[1], colorInput = _a[2];
            if (colorInputEnabled && colorInput) {
                return applyParamInput$(colorInput, {
                    context: options.context,
                    contextElement: options.contextElement,
                    localContext: options.localContext,
                    defaultValue: ''
                });
            }
            else if (!colorInputEnabled && color) {
                return of(color.css());
            }
        }));
    };
    GradientStopControl.prototype.getInstance = function (instance) {
        if (!instance) {
            instance = new GradientStop();
            instance.generateId();
        }
        instance.position = this.controls.position.value;
        if (this.controls.color_input_enabled.value) {
            instance.color = this.controls.color.getInstance(instance.color);
            instance.colorInput = this.controls.color_input.value
                ? new Input().deserialize(this.controls.color_input.value)
                : undefined;
        }
        else {
            instance.color = this.controls.color.getInstance(instance.color);
            instance.colorInput = undefined;
        }
        return instance;
    };
    GradientStopControl.prototype.getId = function () {
        return this.instance ? this.instance.id : undefined;
    };
    GradientStopControl.prototype.serialize = function () {
        return this.getInstance(this.instance);
    };
    return GradientStopControl;
}(FormGroup));
export { GradientStopControl };
