import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as inflection from 'inflection';
import cloneDeep from 'lodash/cloneDeep';
import flatten from 'lodash/flatten';
import isEqual from 'lodash/isEqual';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, delayWhen, map, publishLast, refCount, switchMap, tap } from 'rxjs/operators';

import { AppConfigService } from '@core';
import { ApiService } from '@modules/api';
import { FieldType } from '@modules/fields';
import {
  ModelDbField,
  ModelDescription,
  ModelField,
  ModelFieldType,
  ModelRelation,
  processLegacyModelDescriptions,
  RelationDirection
} from '@modules/models';
import { Environment, Project, Resource, ResourceName, ResourceType } from '@modules/projects';
import {
  FIREBASE_ITEM_PRIMARY_KEY,
  FIREBASE_PARENT,
  JetBridgeResourceController,
  ResourceControllerService
} from '@modules/resources';
import { ascComparator, isSet } from '@shared';

function createManyToOneRelation(
  localResource: Resource,
  localModel: ModelDescription,
  localField: ModelDbField,
  relatedResource: Resource,
  relatedModel: ModelDescription
) {
  const defaultRelatedField =
    relatedResource.typeItem.name == ResourceName.Firebase ? FIREBASE_ITEM_PRIMARY_KEY : relatedModel.primaryKeyField;
  const relatedField = localField.params['custom_primary_key'] || defaultRelatedField;
  const relatedModelId = relatedModel.resource == localModel.resource ? relatedModel.model : relatedModel.modelId;
  const name = ModelRelation.generateManyToOneName({
    localModel: localModel,
    localField: localField.name,
    relatedModel: relatedModelId,
    relatedField: relatedField
  });

  return new ModelRelation({
    name: name,
    direction: RelationDirection.ManyToOne,
    localField: localField.name,
    relatedModel: relatedModelId,
    relatedField: relatedField
  });
}

function createOneToManyRelation(
  localResource: Resource,
  localModel: ModelDescription,
  relatedResource: Resource,
  relatedModel: ModelDescription,
  relatedField: ModelDbField
) {
  const defaultLocalField =
    localResource.typeItem.name == ResourceName.Firebase ? FIREBASE_ITEM_PRIMARY_KEY : localModel.primaryKeyField;
  const localField = relatedField.params['custom_primary_key'] || defaultLocalField;
  const relatedModelId = relatedModel.resource == localModel.resource ? relatedModel.model : relatedModel.modelId;
  const name = ModelRelation.generateOneToManyName({
    localModel: localModel,
    localField: localField,
    relatedModel: relatedModelId,
    relatedField: relatedField.name
  });

  return new ModelRelation({
    name: name,
    direction: RelationDirection.OneToMany,
    localField: localField,
    relatedModel: relatedModelId,
    relatedField: relatedField.name
  });
}

@Injectable()
export class ModelDescriptionService {
  constructor(
    private http: HttpClient,
    private resourceControllerService: ResourceControllerService,
    private apiService: ApiService,
    private appConfigService: AppConfigService
  ) {}

  getFromResource(resource: Resource, draft = false): Observable<ModelDescription[]> {
    const controller = this.resourceControllerService.getForResource(resource, true);

    if (!controller || !controller.modelDescriptionGet) {
      return of(undefined);
    }

    return controller.modelDescriptionGet(resource, draft);
  }

  getFromResources(project: Project, environment: Environment, draft = false): Observable<ModelDescription[]> {
    const resources = project.getEnvironmentResources(environment.uniqueName);

    if (!resources.length) {
      return of([]);
    }

    return combineLatest(
      ...resources
        .map(resource => this.getFromResource(resource, draft))
        .map(item => item.pipe(catchError(() => of([]))))
    ).pipe(
      map(result => {
        const projectResult: ModelDescription[] = flatten(result.filter(item => item != undefined));

        projectResult.forEach(projectModel => {
          projectModel.project = project.uniqueName;
          projectModel.fromResource = true;
        });

        return projectResult;
      })
    );
  }

  getOverride(project: Project, environment: Environment, draft = false): Observable<ModelDescription[]> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.environmentMethodURL(
          project.uniqueName,
          environment.uniqueName,
          'model_descriptions/'
        );
        let headers = new HttpHeaders();
        const params = {
          ...(draft && { draft: '1' }),
          v: this.appConfigService.version
        };

        headers = this.apiService.setHeadersToken(headers);

        return this.http.get<Object[]>(url, { headers: headers, params: params });
      }),
      map(result =>
        result.map(item => {
          const params = typeof item['params'] === 'string' ? JSON.parse(item['params']) : item['params'];
          return {
            resource: item['resource'],
            model: item['model'],
            date_add: item['date_add'],
            ...params,
            draft: item['draft'],
            deleted: item['deleted']
          };
        })
      ),
      map(result => {
        return processLegacyModelDescriptions(result);
      }),
      map(result => {
        return result.map(item => new ModelDescription().deserialize(item));
      }),
      map(result => {
        return result.map(instance => {
          instance.project = project.uniqueName;

          instance.fields
            .filter(field => {
              return (
                field.item.field == FieldType.RelatedModel &&
                field.item.params['related_model'] &&
                isSet(field.item.params['related_model']['model']) &&
                !field.item.params['related_model']['model'].includes('.')
              );
            })
            .forEach(field => {
              field.item.params['related_model']['model'] = [
                instance.resource,
                field.item.params['related_model']['model']
              ].join('.');
            });

          return instance;
        });
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  get(project: Project, environment: Environment, draft = false): Observable<ModelDescription[]> {
    return combineLatest(
      this.getFromResources(project, environment, draft),
      this.getOverride(project, environment, draft)
    ).pipe(
      map(([originalResult, overrideResult]) => {
        originalResult = originalResult.filter(item => item.model);
        overrideResult = overrideResult.filter(item => item.model);

        // Filter getFromResources with synced-only ModelDescriptions
        originalResult = originalResult.filter(originalModel => {
          const resource = project
            .getEnvironmentResources(environment.uniqueName)
            .find(item => item.uniqueName == originalModel.resource);

          if (resource.isCollectionSyncSupported(originalModel)) {
            const overrideModel = overrideResult.find(overrideItem => overrideItem.isSame(originalModel));

            return resource.isSynced(originalModel.model) || (overrideModel && overrideModel.isSynced());
          } else {
            return true;
          }
        });

        // Remove dbTable from overrides
        overrideResult
          .filter(item => isSet(item.dbTable))
          .forEach(item => {
            item.dbTable = undefined;
          });

        // TODO: refactor
        const overrideResultMissing = originalResult.filter(
          item => overrideResult.find(overrideItem => overrideItem.isSame(item)) == undefined
        );

        if (overrideResultMissing.length != 0) {
          const prefixedCount = originalResult.filter(originalModel => {
            return originalModel.dbTable && originalModel.dbTable.split('_', 2).length > 1;
          }).length;
          const prefixed =
            (originalResult.length < 10 && prefixedCount == originalResult.length) ||
            (originalResult.length >= 10 + 3 + 1 && prefixedCount >= originalResult.length - 3);

          overrideResult = overrideResult.concat(
            overrideResultMissing
              .filter(originalModel => prefixed && originalModel.autoVerboseName && originalModel.dbTable)
              .map(originalModel => {
                const modelDescription = new ModelDescription();
                const parts = originalModel.dbTable.split('_');

                modelDescription.resource = originalModel.resource;
                modelDescription.model = originalModel.model;
                modelDescription.verboseName = parts.length > 1 ? parts.slice(1).join(' ') : parts[0];
                modelDescription.verboseNamePlural = inflection.pluralize(modelDescription.verboseName);

                delete modelDescription['dbTable'];
                delete modelDescription['hidden'];
                delete modelDescription['orderingField'];
                delete modelDescription['defaultOrderBy'];
                delete modelDescription['displayField'];
                delete modelDescription['primaryKeyField'];

                return modelDescription;
              })
          );
        }

        overrideResult.forEach(overrideModel => {
          const originalModel = originalResult.find(item => item.isSame(overrideModel));

          if (!originalModel) {
            originalResult.push(overrideModel);
            return;
          }

          const resource = project.getEnvironmentResource(environment.uniqueName, originalModel.resource);
          const controller = resource ? this.resourceControllerService.getForResource(resource, true) : undefined;
          const originalManagedStructure = controller instanceof JetBridgeResourceController;

          [
            // 'dbTable',
            'verboseName',
            'verboseNamePlural',
            'hidden',
            'orderingField',
            'defaultOrderBy',
            'displayField',
            ...(originalManagedStructure ? [] : ['primaryKeyField']),
            'description',
            'queryType',
            'getQuery',
            'getParameters',
            'getDetailQuery',
            'getDetailParameters',
            'createQuery',
            'updateQuery',
            'deleteQuery',
            'featured',
            'demo',
            'sync',
            'syncFinished',
            'orderAfter',
            'params',
            'draft'
          ]
            .filter(item => originalModel.hasOwnProperty(item) && overrideModel[item] !== undefined)
            .forEach(item => (originalModel[item] = overrideModel[item]));

          const mergeDbFields = (overrideFields: ModelDbField[], originalFields: ModelDbField[]) => {
            overrideFields.forEach(overrideField => {
              const originalField = originalFields.find(item => item.name == overrideField.name);

              if (!originalField) {
                return;
              }

              [
                'verboseName',
                'description',
                'field',
                'dbField',
                'required',
                'editable',
                'filterable',
                'sortable',
                'defaultType',
                'defaultValue',
                'placeholder',
                'validatorType',
                'validatorParams'
              ]
                .filter(item => originalField.hasOwnProperty(item) && overrideField[item] !== undefined)
                .forEach(item => (originalField[item] = overrideField[item]));

              if (originalField.hasOwnProperty('params') && overrideField['params'] !== undefined) {
                originalField['params'] = {
                  ...originalField['params'],
                  ...overrideField['params']
                };
              }

              originalField.updateFieldDescription();
            });
          };

          mergeDbFields(overrideModel.dbFields, originalModel.dbFields);

          if (originalModel.defaultFields) {
            mergeDbFields(overrideModel.dbDefaultFields, originalModel.dbDefaultFields);
          } else {
            originalModel.defaultFields = cloneDeep(overrideModel.defaultFields);
          }

          if (!originalModel.getDetailParametersUseDefaults || resource.type == ResourceType.Firebase) {
            originalModel.getDetailParameters = cloneDeep(overrideModel.getDetailParameters);
            originalModel.getDetailParametersUseDefaults = overrideModel.getDetailParametersUseDefaults;
          }

          if (!originalModel.createParametersUseDefaults || resource.type == ResourceType.Firebase) {
            originalModel.createParameters = cloneDeep(overrideModel.createParameters);
            originalModel.createParametersUseDefaults = overrideModel.createParametersUseDefaults;
          }

          if (!originalModel.updateParametersUseDefaults || resource.type == ResourceType.Firebase) {
            originalModel.updateParameters = cloneDeep(overrideModel.updateParameters);
            originalModel.updateParametersUseDefaults = overrideModel.updateParametersUseDefaults;
          }

          if (!originalModel.deleteParametersUseDefaults || resource.type == ResourceType.Firebase) {
            originalModel.deleteParameters = cloneDeep(overrideModel.deleteParameters);
            originalModel.deleteParametersUseDefaults = overrideModel.deleteParametersUseDefaults;
          }

          overrideModel.flexFields.forEach(overrideField => {
            const originalField = originalModel.flexFields.find(item => item.name == overrideField.name);

            if (originalField) {
              ['verboseName', 'field', 'query', 'code', 'params']
                .filter(item => originalField.hasOwnProperty(item) && overrideField[item] !== undefined)
                .forEach(item => (originalField[item] = overrideField[item]));

              originalField.updateFieldDescription();
            } else {
              const field = new ModelField();

              field.type = ModelFieldType.Flex;
              field.item = overrideField;
              field.name = field.item.name;

              originalModel.fields.push(field);
            }
          });

          overrideModel.customFields.forEach(overrideField => {
            const originalField = originalModel.customFields.find(item => item.name == overrideField.name);

            if (originalField) {
              ['type', 'verboseName', 'description', 'field', 'params', 'visible', 'flex', 'valueInput', 'path']
                .filter(item => originalField.hasOwnProperty(item) && overrideField[item] !== undefined)
                .forEach(item => (originalField[item] = overrideField[item]));

              originalField.updateFieldDescription();
            } else {
              const field = new ModelField();

              field.type = ModelFieldType.Custom;
              field.item = overrideField;
              field.name = field.item.name;

              originalModel.fields.push(field);
            }
          });

          overrideModel.fields.forEach(overrideField => {
            const originalField = originalModel.fields.find(item => item.name == overrideField.name);

            if (originalField) {
              ['orderAfter', 'visible']
                .filter(item => originalField.hasOwnProperty(item) && overrideField[item] !== undefined)
                .forEach(item => (originalField[item] = overrideField[item]));
            }
          });

          overrideModel.segments.forEach(overrideSegment => {
            const originalSegment = originalModel.segments.find(item => item.label == overrideSegment.label);

            if (originalSegment) {
              ['label', 'filterItems', 'visible']
                .filter(item => originalSegment.hasOwnProperty(item) && overrideSegment[item] !== undefined)
                .forEach(item => (originalSegment[item] = overrideSegment[item]));
            } else {
              originalModel.segments.push(overrideSegment);
            }
          });

          // overrideModel.relations.forEach(overrideRelation => {
          //   const originalRelation = originalModel.relations.find(item => item.name == overrideRelation.name);
          //
          //   if (!originalRelation) {
          //     return;
          //   }
          //
          //   ['verboseName', 'field']
          //     .filter(item => originalRelation.hasOwnProperty(item) && overrideRelation.hasOwnProperty(item))
          //     .forEach(item => originalRelation[item] = overrideRelation[item]);
          // });

          // originalModel.relations = overrideModel.relations;
        });

        // TODO: Workaround for hardcoded firebase get parameters
        originalResult.forEach(originalModel => {
          const resource = project.getEnvironmentResource(environment.uniqueName, originalModel.resource);
          if (resource && resource.typeItem.name == ResourceName.Firebase) {
            const parentParameter = originalModel.getParameters.find(item => item.name == FIREBASE_PARENT);
            if (parentParameter) {
              const field = originalModel.dbField(FIREBASE_PARENT);
              parentParameter.field = field.field;
              parentParameter.params = field.params;
            }
          }
        });

        return originalResult;
      }),
      delayWhen((modelDescriptions: ModelDescription[]) => {
        const resources = project
          .getEnvironmentResources(environment.uniqueName)
          .filter(item => {
            const controller = this.resourceControllerService.getForResource(item, true);
            return controller instanceof JetBridgeResourceController && controller.setModelDescriptionRelationOverrides;
          })
          .filter(resource => {
            return (
              resource &&
              resource.apiInfo &&
              resource.apiInfo.isCompatibleJetBridge({ jetBridge: '1.2.9', jetDjango: '1.4.4' })
            );
          });

        const relationsByModel: {
          [k: string]: { modelDescription: ModelDescription; relations: ModelRelation[] };
        } = {};

        resources.forEach(resource => {
          const resourceModelDescriptions = modelDescriptions
            .filter(item => item.resource == resource.uniqueName)
            .filter(item => item.fromResource);

          resourceModelDescriptions.forEach(modelDescription => {
            modelDescription.dbFields
              .filter(item => item.field == FieldType.RelatedModel)
              .forEach(field => {
                if (field.params['related_model'] && isSet(field.params['related_model']['model'])) {
                  const relatedModelId = field.params['related_model']['model'].includes('.')
                    ? field.params['related_model']['model']
                    : [modelDescription.resource, field.params['related_model']['model']].join('.');
                  const relatedModel = modelDescriptions.find(item => item.isSame(relatedModelId));
                  const relatedResource = relatedModel
                    ? resources.find(item => item.uniqueName == relatedModel.resource)
                    : undefined;

                  if (
                    relatedResource &&
                    relatedModel &&
                    resource.isSameDatabase(modelDescription, relatedResource, relatedModel)
                  ) {
                    const relation = createManyToOneRelation(
                      resource,
                      modelDescription,
                      field,
                      relatedResource,
                      relatedModel
                    );

                    if (!relationsByModel[modelDescription.modelId]) {
                      relationsByModel[modelDescription.modelId] = {
                        modelDescription: modelDescription,
                        relations: []
                      };
                    }

                    relationsByModel[modelDescription.modelId].relations.push(relation);

                    const backRelation = createOneToManyRelation(
                      relatedResource,
                      relatedModel,
                      resource,
                      modelDescription,
                      field
                    );

                    if (!relationsByModel[relatedModel.modelId]) {
                      relationsByModel[relatedModel.modelId] = {
                        modelDescription: relatedModel,
                        relations: []
                      };
                    }

                    relationsByModel[relatedModel.modelId].relations.push(backRelation);
                  }
                }
              });
          });
        });

        const setModelRelations$ = resources
          .map(resource => {
            const resourceModelDescriptions = modelDescriptions
              .filter(item => item.resource == resource.uniqueName)
              .filter(item => item.fromResource);
            const setModelRelations: { modelDescription: ModelDescription; relations: ModelRelation[] }[] = [];

            resourceModelDescriptions.forEach(modelDescription => {
              const existingRelations = modelDescription.relations.sort((lhs, rhs) =>
                ascComparator(lhs.name, rhs.name)
              );
              const actualRelations = relationsByModel[modelDescription.modelId]
                ? relationsByModel[modelDescription.modelId].relations.sort((lhs, rhs) =>
                    ascComparator(lhs.name, rhs.name)
                  )
                : [];
              const relationOverrides = actualRelations.filter(
                relation => !existingRelations.find(item => item.name == relation.name)
              );

              if (!isEqual(relationOverrides, modelDescription.relationOverrides)) {
                setModelRelations.push({
                  modelDescription: modelDescription,
                  relations: relationOverrides
                });
              }
            });

            if (setModelRelations.length) {
              const controller = this.resourceControllerService.getForResource(resource, true);
              return controller.setModelDescriptionRelationOverrides(resource, setModelRelations, draft).pipe(
                tap(() => {
                  setModelRelations.forEach(item => {
                    item.modelDescription.relationOverrides = item.relations;
                  });
                }),
                catchError(() => of([]))
              );
            }
          })
          .filter(item => item);

        if (!setModelRelations$.length) {
          return of([]);
        }

        return combineLatest(...setModelRelations$);
      }),
      // map((modelDescriptions: ModelDescription[]) => {
      //   modelDescriptions.forEach(modelDescription => {
      //     // const resource = this.currentProjectStore.instance.resources.find(
      //     //   item => item.uniqueName == modelDescription.resource
      //     // );
      //     const resource = project
      //       .getEnvironmentResources(environment)
      //       .find(item => item.uniqueName == modelDescription.resource);
      //     const controller = resource ? this.resourceControllerService.get(resource.type) : undefined;
      //     const others = modelDescriptions.filter(item => item !== modelDescription);
      //
      //     modelDescription.relations = [];
      //
      //     const relations = flatten(
      //       others.map(other => {
      //         return other.dbFields
      //           .filter(field => {
      //             return (
      //               field.field == FieldType.RelatedModel &&
      //               field.params['related_model'] &&
      //               modelDescription.isSame(fromLegacyModel(field.params['related_model']['model']))
      //             );
      //           })
      //           .map(field => {
      //             const instance = new ModelRelation();
      //
      //             instance.name = `auto_${other.dbTable}`;
      //             instance.verboseName = `${other.verboseNamePlural} - ${field.name}`;
      //             instance.relatedModel = other.getMinimal();
      //             instance.path = [
      //               {
      //                 prevModel: modelDescription.modelId,
      //                 prevModelField: field.params['custom_primary_key'] || modelDescription.primaryKeyField,
      //                 model: other.modelId,
      //                 modelField: field.name
      //               }
      //             ];
      //             instance.auto = true;
      //
      //             return instance;
      //           });
      //       })
      //     );
      //
      //     modelDescription.relations = modelDescription.relations.concat(relations);
      //
      //     if (controller && controller.relationFilter) {
      //       const m2mRelations = flatten(
      //         others.map(other => {
      //           return flatten(
      //             other.dbFields
      //               .filter(field => {
      //                 return (
      //                   field.field == FieldType.RelatedModel &&
      //                   field.params['related_model'] &&
      //                   modelDescription.isSame(fromLegacyModel(field.params['related_model']['model']))
      //                 );
      //               })
      //               .map(() => {
      //                 return others
      //                   .filter(otherNested => {
      //                     if (otherNested.isSame(other)) {
      //                       return false;
      //                     }
      //
      //                     return (
      //                       other.dbFields.find(fieldM2M => {
      //                         return (
      //                           fieldM2M.field == FieldType.RelatedModel &&
      //                           fieldM2M.params['related_model'] &&
      //                           otherNested.isSame(fromLegacyModel(fieldM2M.params['related_model']['model']))
      //                         );
      //                       }) != undefined
      //                     );
      //                   })
      //                   .map(otherNested => {
      //                     const instance = new ModelRelation();
      //                     const otherToPrimaryField = other.dbFields.find(field => {
      //                       return (
      //                         field.field == FieldType.RelatedModel &&
      //                         field.params['related_model'] &&
      //                         modelDescription.isSame(fromLegacyModel(field.params['related_model']['model']))
      //                       );
      //                     });
      //                     const otherToNestedField = other.dbFields.find(field => {
      //                       return (
      //                         field.field == FieldType.RelatedModel &&
      //                         field.params['related_model'] &&
      //                         otherNested.isSame(fromLegacyModel(field.params['related_model']['model']))
      //                       );
      //                     });
      //
      //                     instance.name = `auto_m2m_${otherNested.dbTable}_${other.dbTable}`;
      //                     instance.verboseName = `${otherNested.verboseNamePlural} M2M via ${other.verboseNamePlural}`;
      //                     instance.relatedModel = otherNested.getMinimal();
      //                     instance.path = [
      //                       {
      //                         prevModel: modelDescription.modelId,
      //                         prevModelField:
      //                           otherToPrimaryField.params['custom_primary_key'] || modelDescription.primaryKeyField,
      //                         model: other.modelId,
      //                         modelField: otherToPrimaryField.name
      //                       },
      //                       {
      //                         prevModel: other.modelId,
      //                         prevModelField: otherToNestedField.name,
      //                         model: otherNested.modelId,
      //                         modelField: otherToNestedField.params['custom_primary_key'] || otherNested.primaryKeyField
      //                       }
      //                     ];
      //                     instance.auto = true;
      //
      //                     return instance;
      //                   });
      //               })
      //           );
      //         })
      //       );
      //
      //       modelDescription.relations = modelDescription.relations.concat(m2mRelations);
      //     }
      //   });
      //
      //   return modelDescriptions;
      // }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  createBulk(
    projectName: string,
    environmentName: string,
    modelDescriptions: ModelDescription[],
    draft = true
  ): Observable<ModelDescription[]> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.environmentMethodURL(projectName, environmentName, 'model_descriptions/');
        let headers = new HttpHeaders();
        const httpParams = {
          ...(draft && { draft: '1' }),
          v: this.appConfigService.version
        };
        const data = modelDescriptions.map(item => {
          const params = item.serialize();

          delete params['project'];
          delete params['model'];

          return {
            project: item.project,
            resource: item.resource,
            model: item.model,
            params: params,
            draft: item.draft,
            deleted: item.deleted
          };
        });

        headers = this.apiService.setHeadersToken(headers);

        return this.http.post<Object[]>(url, data, { headers: headers, params: httpParams });
      }),
      map(result =>
        result.map(item => {
          const params = typeof item['params'] === 'string' ? JSON.parse(item['params']) : item['params'];
          return {
            resource: item['resource'],
            model: item['model'],
            date_add: item['date_add'],
            ...params,
            draft: item['draft'],
            deleted: item['deleted']
          };
        })
      ),
      map(result => result.map(item => new ModelDescription().deserialize(item))),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  create(
    projectName: string,
    environmentName: string,
    instance: ModelDescription,
    draft = true
  ): Observable<ModelDescription> {
    return this.createBulk(projectName, environmentName, [instance], draft).pipe(map(result => result[0]));
  }

  update(
    projectName: string,
    environmentName: string,
    instance: ModelDescription,
    draft = true
  ): Observable<ModelDescription> {
    return this.createBulk(projectName, environmentName, [instance], draft).pipe(map(result => result[0]));
    // return this.apiService.refreshToken().pipe(
    //   switchMap(() => {
    //     const url = this.apiService.environmentMethodURL(projectName, environmentName, 'model_descriptions/');
    //     const httpParams = { v: this.appConfigService.version };
    //     let headers = new HttpHeaders();
    //
    //     headers = this.apiService.setHeadersToken(headers);
    //
    //     const params = modelDescription.serialize();
    //
    //     delete params['project'];
    //     delete params['model'];
    //
    //     const data = {
    //       project: modelDescription.project,
    //       resource: modelDescription.resource,
    //       model: modelDescription.model,
    //       params: params
    //     };
    //
    //     return this.http.post(url, data, { headers: headers, params: httpParams });
    //   }),
    //   map(result => {
    //     const params = typeof result['params'] === 'string' ? JSON.parse(result['params']) : result['params'];
    //     return {
    //       resource: result['resource'],
    //       model: result['model'],
    //       date_add: result['date_add'],
    //       ...params
    //     };
    //   }),
    //   map(result => new ModelDescription().deserialize(result)),
    //   this.apiService.catchApiError(),
    //   publishLast(),
    //   refCount()
    // );
  }

  delete(projectName: string, environmentName: string, instance: ModelDescription): Observable<boolean> {
    instance = cloneDeep(instance);
    instance.deleted = true;
    return this.createBulk(projectName, environmentName, [instance]).pipe(map(() => true));
    // return this.apiService.refreshToken().pipe(
    //   switchMap(() => {
    //     const url = this.apiService.environmentMethodURL(
    //       projectName,
    //       environmentName,
    //       `model_descriptions/${modelDescription.resource}/${modelDescription.model}/`
    //     );
    //     const httpParams = { v: this.appConfigService.version };
    //     let headers = new HttpHeaders();
    //
    //     headers = this.apiService.setHeadersToken(headers);
    //
    //     return this.http.delete(url, { headers: headers, params: httpParams });
    //   }),
    //   map(() => true),
    //   this.apiService.catchApiError(),
    //   publishLast(),
    //   refCount()
    // );
  }
}
