<app-list-footer
  [title]="title"
  [pagination]="pagination"
  [currentPage]="listStore.currentPageImmediate$ | async"
  [hasMorePages]="listStore.hasMore$ | async"
  [lastLoadedPage]="listStore.currentPage$ | async"
  [totalPages]="listStore.totalPages$ | async"
  [count]="listStore.count$ | async"
  [viewId]="viewId"
  [accentColor]="accentColor"
  [theme]="theme"
  (pageSelected)="pageSelected.emit($event)"
  (reload)="reload.emit()"
>
</app-list-footer>
