/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
import * as i3 from "./not-found.component";
var styles_NotFoundComponent = [];
var RenderType_NotFoundComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NotFoundComponent, data: {} });
export { RenderType_NotFoundComponent as RenderType_NotFoundComponent };
function View_NotFoundComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "a", [["class", "status-page__button button button_primary button_shadow button_bigger"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i0.ɵpad(2, 1), (_l()(), i0.ɵted(-1, null, ["Go to Home page"]))], function (_ck, _v) { var currVal_2 = _ck(_v, 2, 0, "/"); _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).target; var currVal_1 = i0.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_NotFoundComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 12, "div", [["class", "status-page"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 8, "div", [["class", "status-page__main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 7, "div", [["class", "status-page__content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "h1", [["class", "status-page__title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Page not found"])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "div", [["class", "status-page__description"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Looks like there is nothing here. Maybe you have mistyped the URL or this page does not longer exist. Please go the the home page to find what you was looking for. "])), (_l()(), i0.ɵeld(7, 0, null, null, 2, "div", [["class", "status-page__actions"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NotFoundComponent_1)), i0.ɵdid(9, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(10, 0, null, null, 2, "div", [["class", "status-page__right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 1, "div", [["class", "status-page__image"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 0, "div", [["class", "status-page__image-inner status-page__image-inner_type_not-found"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isWhiteLabel; _ck(_v, 9, 0, currVal_0); }, null); }
export function View_NotFoundComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_NotFoundComponent_0, RenderType_NotFoundComponent)), i0.ɵdid(1, 245760, null, 0, i3.NotFoundComponent, [i1.ActivatedRoute, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NotFoundComponentNgFactory = i0.ɵccf("ng-component", i3.NotFoundComponent, View_NotFoundComponent_Host_0, {}, {}, []);
export { NotFoundComponentNgFactory as NotFoundComponentNgFactory };
