<form (ngSubmit)="continueSetupOrSubmit()">
  <app-popup2 [size]="!dnsRecordsAdded && !(domain.emailConfigFrom | appIsSet) ? 'ml' : 's'">
    <ng-container *ngIf="!dnsRecordsAdded && !(domain.emailConfigFrom | appIsSet)">
      <app-popup2-header>
        <app-popup2-title>Add CNAME records</app-popup2-title>
        <app-popup2-description>
          Add the following configuration records in your domain DNS settings to send emails using your domain
        </app-popup2-description>
      </app-popup2-header>

      <app-page-block>
        <div *ngIf="dnsRecordsLoading || dnsRecords" class="table-border">
          <table class="table table_grid table_compact">
            <thead>
              <tr class="table__heading-row">
                <th class="table__heading-column">
                  <div class="table__heading-column-inner">
                    <div class="table__heading-column-main">type</div>
                  </div>
                </th>

                <th class="table__heading-column">
                  <div class="table__heading-column-inner">
                    <div class="table__heading-column-main">name (host/domain)</div>
                  </div>
                </th>

                <th class="table__heading-column">
                  <div class="table__heading-column-inner">
                    <div class="table__heading-column-main">destination (target/value)</div>
                  </div>
                </th>
              </tr>
            </thead>

            <tbody *ngIf="dnsRecords && !dnsRecordsLoading">
              <tr *ngFor="let item of dnsRecords" class="table__row">
                <td class="table__column table__column_no-trunc table__column_compact">
                  <div class="table__column-inner">CNAME</div>
                </td>

                <td class="table__column table__column_no-trunc table__column_compact">
                  <div class="table__column-inner">
                    <a
                      href="javascript:void(0)"
                      class="link"
                      style="display: block; margin-bottom: 4px;"
                      (click)="copy(item.name, 'CNAME record name')"
                    >
                      <span class="icon-duplicate"></span> Copy
                    </a>

                    <span class="break-all">{{ item.name }}</span>
                  </div>
                </td>

                <td class="table__column table__column_no-trunc table__column_compact">
                  <div class="table__column-inner">
                    <a
                      href="javascript:void(0)"
                      class="link"
                      style="display: block; margin-bottom: 4px;"
                      (click)="copy(item.value, 'CNAME record destination')"
                    >
                      <span class="icon-duplicate"></span> Copy
                    </a>

                    <span class="break-all">{{ item.value }}</span>
                  </div>
                </td>
              </tr>
            </tbody>

            <tbody *ngIf="dnsRecordsLoading">
              <tr *ngFor="let item of [1, 2, 3]" class="table__row">
                <td class="table__column table__column_no-trunc table__column_compact">
                  <div class="table__column-inner">
                    <span [class.loading-animation]="true"><span class="stub-text">CNAME</span></span>
                  </div>
                </td>

                <td class="table__column table__column_no-trunc table__column_compact">
                  <div class="table__column-inner">
                    <span [class.loading-animation]="true"><span class="stub-text">Name</span></span>
                  </div>
                </td>

                <td class="table__column table__column_no-trunc table__column_compact">
                  <div class="table__column-inner">
                    <span [class.loading-animation]="true"><span class="stub-text">Value</span></span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </app-page-block>

      <app-popup2-footer>
        <app-page-block>
          <app-stack [align]="'right'">
            <a href="javascript:void(0)" class="button popup2__footer-item" (click)="close()">Cancel</a>

            <button
              type="button"
              class="button button_primary button_shadow popup2__footer-item"
              [disabled]="dnsRecordsLoading"
              (click)="continueSetup()"
            >
              <span class="button__label">I've added 3 CNAME records</span>
            </button>
          </app-stack>
        </app-page-block>
      </app-popup2-footer>
    </ng-container>

    <ng-container *ngIf="dnsRecordsAdded && !(domain.emailConfigFrom | appIsSet)">
      <app-popup2-header>
        <app-popup2-title>Set Email address</app-popup2-title>
        <app-popup2-description>
          This address will be used for all transactional Emails and messages send through App built-ins
          <span class="icon-arrow_forward_2"></span> Send email
        </app-popup2-description>
      </app-popup2-header>

      <app-page-block [marginSize]="'s'">
        <div class="field">
          <label class="field__label field__label_bright">From Email address</label>
          <div class="input input_fill input_template">
            <input
              class="input__template-value input__template-value_fill"
              [formControl]="form.controls.email_name"
              placeholder="no-reply"
              type="text"
              [appAutofocus]="true"
            />
            <div class="input__template-postfix input__template-postfix_bright">@{{ domain.domain }}</div>
          </div>
          <div class="field__description">Default is {{ appConfigService.defaultEmailFrom }}</div>
          <app-field-errors [form]="form" [fieldName]="'email_from'"></app-field-errors>
        </div>
      </app-page-block>

      <app-page-block>
        <app-form-errors [form]="form" [manualMargin]="true"></app-form-errors>
      </app-page-block>

      <app-popup2-footer>
        <app-page-block>
          <app-stack [align]="'right'">
            <a href="javascript:void(0)" class="button popup2__footer-item" (click)="backToDNSRecords()">Back</a>

            <button
              type="submit"
              class="button button_primary button_shadow popup2__footer-item"
              [class.button_disabled]="form.invalid || submitLoading"
            >
              <app-loader-small *ngIf="submitLoading" class="button__icon button__icon_left"></app-loader-small>
              <span class="button__label">Change Email address</span>
            </button>
          </app-stack>
        </app-page-block>
      </app-popup2-footer>
    </ng-container>

    <ng-container *ngIf="domain.emailConfigFrom | appIsSet">
      <app-popup2-header>
        <app-popup2-title>Set Email address</app-popup2-title>
        <app-popup2-description>
          This address will be used for all transactional Emails and messages send through App built-ins
          <span class="icon-arrow_forward_2"></span> Send email
        </app-popup2-description>
      </app-popup2-header>

      <app-page-block [marginSize]="'s'">
        <div class="field">
          <label class="field__label field__label_bright">From Email address</label>
          <input class="input input_fill" type="text" [value]="domain.emailConfigFrom" disabled />
          <div class="field__description">Default is {{ appConfigService.defaultEmailFrom }}</div>
        </div>
      </app-page-block>

      <app-popup2-footer>
        <app-page-block>
          <app-stack [align]="'right'">
            <a href="javascript:void(0)" class="button popup2__footer-item" (click)="close()">Cancel</a>

            <a
              href="javascript:void(0)"
              class="button button_danger popup2__footer-item"
              [class.button_disabled]="submitLoading"
              (click)="submit()"
            >
              <app-loader-small *ngIf="submitLoading" class="button__icon button__icon_left"></app-loader-small>
              Disconnect email
            </a>
          </app-stack>
        </app-page-block>
      </app-popup2-footer>
    </ng-container>
  </app-popup2>
</form>
