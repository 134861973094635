var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, EventEmitter, Injector, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import clone from 'lodash/clone';
import keys from 'lodash/keys';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { delayWhen, first, switchMap } from 'rxjs/operators';
import { ListViewSettings, ViewContext, ViewContextElement } from '@modules/customize';
import { CustomizeBarEditEventType } from '@modules/customize-bar';
import { AggregateDisplayField, DisplayFieldType, FieldType, LookupDisplayField, OptionsType } from '@modules/fields';
import { listItemEquals } from '@modules/list';
import { NEXT_PAGE_SCROLL_PARAM, PAGE_PARAM } from '@modules/models';
import { Resource } from '@modules/projects';
import { QueryType } from '@modules/queries';
import { ascComparator } from '@shared';
export function serializeDataSourceColumns(columns) {
    return columns
        .filter(function (item) {
        return [DisplayFieldType.Base, DisplayFieldType.Computed, DisplayFieldType.Lookup, DisplayFieldType.Aggregate].includes(item.type);
    })
        .map(function (item) {
        return __assign({ name: item.name }, (item.field == FieldType.RelatedModel && item.params
            ? {
                custom_primary_key: item.params['custom_primary_key'],
                custom_display_field: item.params['custom_display_field'],
                custom_display_field_input: item.params['custom_display_field_input']
            }
            : {}), ([FieldType.Select, FieldType.MultipleSelect, FieldType.RadioButton].includes(item.field) &&
            item.params &&
            item.params['options_type'] == OptionsType.Query
            ? {
                value_field: item.params['value_field'],
                label_field: item.params['label_field'],
                label_field_input: item.params['label_field_input']
            }
            : {}), (item instanceof LookupDisplayField
            ? {
                path: item.path
            }
            : {}), (item instanceof AggregateDisplayField
            ? {
                path: item.path,
                func: item.func,
                column: item.column
            }
            : {}));
    })
        .sort(function (lhs, rhs) { return ascComparator(lhs.name, rhs.name); });
}
var ListLayoutComponent = /** @class */ (function () {
    function ListLayoutComponent(injector, cd, customizeBarContext, customizeBarService, analyticsService, viewSettingsService, viewSettingsStore, menuSettingsStore, modelDescriptionStore, inputService, routing, currentProjectStore, currentEnvironmentStore, popupService, popoverService) {
        this.injector = injector;
        this.cd = cd;
        this.customizeBarContext = customizeBarContext;
        this.customizeBarService = customizeBarService;
        this.analyticsService = analyticsService;
        this.viewSettingsService = viewSettingsService;
        this.viewSettingsStore = viewSettingsStore;
        this.menuSettingsStore = menuSettingsStore;
        this.modelDescriptionStore = modelDescriptionStore;
        this.inputService = inputService;
        this.routing = routing;
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.popupService = popupService;
        this.popoverService = popoverService;
        this.hideParams = [];
        this.scrollable = false;
        this.focus = false;
        this.createSegmentAllowed = false;
        this.theme = false;
        this.paramsChanged = new EventEmitter();
        this.settingsChanged = new EventEmitter();
        this.layoutCustomize = new EventEmitter();
        this.layoutAdd = new EventEmitter();
        this.pageSettingsChanged = new EventEmitter();
        this.segmentCreated = new EventEmitter();
        this.firstVisible$ = new BehaviorSubject(false);
        this.settings$ = new BehaviorSubject(undefined);
        this.params$ = new BehaviorSubject(undefined);
        this.filters$ = new BehaviorSubject([]);
        this.search$ = new BehaviorSubject(undefined);
        this.sort$ = new BehaviorSubject([]);
        this.listState = {};
        this.parameters = [];
        this.inputs = [];
    }
    ListLayoutComponent.prototype.ngOnInit = function () {
        this.trackChanges();
    };
    ListLayoutComponent.prototype.ngOnChanges = function (changes) {
        if (changes['settings']) {
            this.settings$.next(this.settings);
        }
        if (changes['params']) {
            this.params$.next(this.params);
        }
    };
    ListLayoutComponent.prototype.ngOnDestroy = function () { };
    ListLayoutComponent.prototype.trackChanges = function () {
        var _this = this;
        this.firstVisible$
            .pipe(first(function (value) { return value; }), switchMap(function () { return combineLatest(_this.settings$, _this.params$, _this.filters$, _this.search$, _this.sort$); }), switchMap(function (_a) {
            var settings = _a[0], params = _a[1], filters = _a[2], search = _a[3], sort = _a[4];
            return _this.getListState(settings, params, filters, search, sort);
        }), untilDestroyed(this))
            .subscribe(function (state) {
            var initialState = !keys(_this.listState).length;
            _this.onStateUpdated(state);
            _this.listState = state;
            // TODO: Workaround for safari not triggering cd on firstVisible
            if (initialState) {
                _this.cd.detectChanges();
            }
        });
    };
    ListLayoutComponent.prototype.getQueryOptions = function () {
        return this.getStateQueryOptions(this.listState);
    };
    ListLayoutComponent.prototype.getParameters = function (state) {
        if (!state.dataSource || !state.dataSource.type) {
            return [];
        }
        var resource = this.currentEnvironmentStore.resources.find(function (item) { return item.uniqueName == state.dataSource.queryResource; });
        return state.dataSource.queryParameters.concat(this.inputService.parametersFromModelGet(resource, state.dataSource.query, state.dataSource.columns, state.dataSource.type));
    };
    ListLayoutComponent.prototype.getInputs = function (state) {
        if (!state.dataSource || !state.dataSource.type) {
            return [];
        }
        var resource = this.currentEnvironmentStore.resources.find(function (item) { return item.uniqueName == state.dataSource.queryResource; });
        return state.dataSource.queryInputs.concat(this.inputService.inputsFromModelGet(resource, state.dataSource.query, state.dataSource.columns, state.dataSource.type));
    };
    ListLayoutComponent.prototype.getQueryModelDescription = function (dataSource) {
        if (!dataSource ||
            !dataSource.query ||
            dataSource.query.queryType != QueryType.Simple ||
            !dataSource.query.simpleQuery) {
            return of(undefined);
        }
        var modelId = [dataSource.queryResource, dataSource.query.simpleQuery.model].join('.');
        return this.modelDescriptionStore.getDetailFirst(modelId);
    };
    ListLayoutComponent.prototype.reloadData = function () {
        this.fetch(this.listState);
    };
    ListLayoutComponent.prototype.resetFilters = function () {
        this.filters$.next([]);
        this.search$.next(undefined);
    };
    ListLayoutComponent.prototype.setPage = function (page) {
        var currentPage = parseInt(this.params[PAGE_PARAM], 10) || 1;
        if (currentPage == page) {
            return false;
        }
        var params = clone(this.params);
        params[PAGE_PARAM] = page;
        delete params[NEXT_PAGE_SCROLL_PARAM];
        this.paramsChanged.emit(params);
        return true;
    };
    ListLayoutComponent.prototype.onPageSelected = function (page) {
        this.setPage(page);
    };
    ListLayoutComponent.prototype.onFiltersUpdated = function (filters) {
        this.filters$.next(filters);
    };
    ListLayoutComponent.prototype.onSearchUpdated = function (search) {
        this.search$.next(search);
    };
    ListLayoutComponent.prototype.onSortUpdated = function (sort) {
        this.sort$.next(sort);
    };
    ListLayoutComponent.prototype.customizeModelDescription = function () {
        if (!this.listState.modelDescription) {
            return;
        }
        // this.customizeBarService
        //   .customizeModelDescription(this.customizeBarContext, this.listState.modelDescription)
        //   .pipe(untilDestroyed(this))
        //   .subscribe(e => {});
    };
    ListLayoutComponent.prototype.customizeViewSettings = function () {
        var _this = this;
        this.customizeBarService
            .customizePage({ context: this.customizeBarContext, viewSettings: this.pageSettings, viewContext: this.context })
            .pipe(untilDestroyed(this))
            .subscribe(function (e) {
            if (e.type == CustomizeBarEditEventType.Created || e.type == CustomizeBarEditEventType.Updated) {
                var instance = e.args['result'];
                _this.pageSettingsChanged.emit(instance);
                _this.cd.markForCheck();
            }
            else if (e.type == CustomizeBarEditEventType.Deleted) {
                _this.viewSettingsService
                    .delete(_this.currentProjectStore.instance.uniqueName, _this.currentEnvironmentStore.instance.uniqueName, _this.pageSettings)
                    .pipe(delayWhen(function () { return _this.viewSettingsStore.getFirst(true); }), delayWhen(function () { return _this.menuSettingsStore.getFirst(true); }), untilDestroyed(_this))
                    .subscribe(function () {
                    _this.routing.navigateApp(_this.currentProjectStore.instance.homeLink);
                });
            }
        });
    };
    ListLayoutComponent.prototype.itemEquals = function (lhs, rhs) {
        return listItemEquals(lhs, rhs);
    };
    return ListLayoutComponent;
}());
export { ListLayoutComponent };
