import { Input } from '@angular/core';
import { Observable } from 'rxjs';

import { ViewContext, ViewContextElement } from '@modules/customize';
import { Dashboard, Widget } from '@modules/dashboard';

export abstract class WidgetComponent {
  @Input() dashboard: Dashboard;
  @Input() widget: Widget;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() fill = true;
  @Input() accentColor: string;
  @Input() theme = false;

  getData$?(): Observable<any>;
  reloadData?();
}
