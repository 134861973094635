import { ConnectedPosition } from '@angular/cdk/overlay';

export const textStyleEditOverlayPositions: ConnectedPosition[] = [
  {
    panelClass: ['overlay_position_bottom-left'],
    originX: 'start',
    overlayX: 'start',
    originY: 'bottom',
    overlayY: 'top',
    offsetX: -8 - 16,
    offsetY: -8 + 8
  },
  {
    panelClass: ['overlay_position_bottom-right'],
    originX: 'end',
    overlayX: 'end',
    originY: 'bottom',
    overlayY: 'top',
    offsetX: 8 + 16,
    offsetY: -8 + 8
  },
  {
    panelClass: ['overlay_position_top-left'],
    originX: 'start',
    overlayX: 'start',
    originY: 'top',
    overlayY: 'bottom',
    offsetX: -8 - 16,
    offsetY: 8 - 8
  },
  {
    panelClass: ['overlay_position_top-right'],
    originX: 'end',
    overlayX: 'end',
    originY: 'top',
    overlayY: 'bottom',
    offsetX: 8 + 16,
    offsetY: 8 - 8
  },
  {
    panelClass: ['overlay_position_left-center'],
    originX: 'start',
    overlayX: 'end',
    originY: 'center',
    overlayY: 'center',
    offsetX: -8
  },
  {
    panelClass: ['overlay_position_right-center'],
    originX: 'end',
    overlayX: 'start',
    originY: 'center',
    overlayY: 'center',
    offsetX: 8
  }
];
