/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../shared/pipes/is-set/is-set.pipe";
import * as i2 from "@angular/cdk/overlay";
import * as i3 from "@angular/common";
import * as i4 from "../compact-select-options/compact-select-options.component.ngfactory";
import * as i5 from "../compact-select-options/compact-select-options.component";
import * as i6 from "@angular/platform-browser";
import * as i7 from "@angular/forms";
import * as i8 from "./compact-select.component";
var styles_CompactSelectComponent = [];
var RenderType_CompactSelectComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CompactSelectComponent, data: {} });
export { RenderType_CompactSelectComponent as RenderType_CompactSelectComponent };
function View_CompactSelectComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "compact-select__left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "compact-select__icon"]], [[4, "background", null]], null, null, null, null)), i0.ɵppd(2, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i0.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent, 0), _co.currentOption.color)) ? _co.sanitizer.bypassSecurityTrustStyle(_co.currentOption.color) : null); _ck(_v, 1, 0, currVal_0); }); }
function View_CompactSelectComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "compact-select__value"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentOption.name; _ck(_v, 1, 0, currVal_0); }); }
function View_CompactSelectComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "compact-select__placeholder"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.placeholder; _ck(_v, 1, 0, currVal_0); }); }
export function View_CompactSelectComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i1.IsSetPipe, []), (_l()(), i0.ɵeld(1, 0, null, null, 12, "div", [["cdkOverlayOrigin", ""], ["class", "compact-select"]], [[2, "compact-select_focus", null], [4, "width", "px"]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 15).setDropdownOpened(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 16384, [["origin", 4]], 0, i2.CdkOverlayOrigin, [i0.ElementRef], null, null), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_CompactSelectComponent_1)), i0.ɵdid(4, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵppd(5, 1), (_l()(), i0.ɵeld(6, 0, null, null, 5, "div", [["class", "compact-select__main"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CompactSelectComponent_2)), i0.ɵdid(8, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_CompactSelectComponent_3)), i0.ɵdid(10, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵppd(11, 1), (_l()(), i0.ɵeld(12, 0, null, null, 1, "div", [["class", "compact-select__right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 0, "div", [["class", "compact-select__arrow icon-arrow_down_2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 1, "app-compact-select-options", [], null, [[null, "selectOption"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectOption" === en)) {
        var pd_0 = (_co.updateValue($event.value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_CompactSelectOptionsComponent_0, i4.RenderType_CompactSelectOptionsComponent)), i0.ɵdid(15, 245760, [["options_component", 4]], 0, i5.CompactSelectOptionsComponent, [i6.DomSanitizer, i0.ChangeDetectorRef], { options: [0, "options"], currentOption: [1, "currentOption"], origin: [2, "origin"] }, { selectOption: "selectOption" })], function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.colorDisplay && i0.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i0.ɵnov(_v, 0), ((_co.currentOption == null) ? null : _co.currentOption.color)))); _ck(_v, 4, 0, currVal_2); var currVal_3 = _co.currentOption; _ck(_v, 8, 0, currVal_3); var currVal_4 = (!_co.currentOption && i0.ɵunv(_v, 10, 0, _ck(_v, 11, 0, i0.ɵnov(_v, 0), _co.placeholder))); _ck(_v, 10, 0, currVal_4); var currVal_5 = _co.options; var currVal_6 = _co.currentOption; var currVal_7 = i0.ɵnov(_v, 2); _ck(_v, 15, 0, currVal_5, currVal_6, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 15).dropdownOpened; var currVal_1 = _co.widthPx; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_CompactSelectComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-compact-select", [], null, null, null, View_CompactSelectComponent_0, RenderType_CompactSelectComponent)), i0.ɵprd(5120, null, i7.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i8.CompactSelectComponent]), i0.ɵdid(2, 770048, null, 0, i8.CompactSelectComponent, [i6.DomSanitizer, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var CompactSelectComponentNgFactory = i0.ɵccf("app-compact-select", i8.CompactSelectComponent, View_CompactSelectComponent_Host_0, { formControl: "formControl", options: "options", placeholder: "placeholder", widthPx: "widthPx", compareWith: "compareWith", colorDisplay: "colorDisplay" }, { change: "change" }, []);
export { CompactSelectComponentNgFactory as CompactSelectComponentNgFactory };
